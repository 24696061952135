import { AceUIIcon, BannerState } from '@ace/shared';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { TimerService } from '../../Utilities';
import { aceColorLookup } from '../Utils/colorHelper';

type Constructor<T = {}> = new (...args: any[]) => T;

@Component({
    selector: 'ws-ace-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class AceBannerComponent implements OnInit, AfterViewInit {
    constructor(private readonly _timerService: TimerService) {
    }

    @Input() enableDismiss: boolean;
    @Input() suppressDefaultIcon: boolean;
    @Input() content: string | Constructor<any>;
    // because "title" is a reserved attribute
    @Input() header: string;
    /**
     * (optional) The icon to display on this element.
     */
    @Input() icon: AceUIIcon;

    @HostBinding('attr.state')
    @Input() state: BannerState;

    /** full width block banner */
    @HostBinding('attr.block')
    @Input() block: boolean;

    /** Event will be raised when banner is dismissed */
    @Output() dismissed?: EventEmitter<void> = new EventEmitter();

    @ViewChild('contentComponent', {read: ViewContainerRef}) contentComponent: ViewContainerRef;

    displayIcon: AceUIIcon;
    closing: boolean;
    isNarrow: boolean;
    contentIsComponent: boolean;
    closed: boolean;

    ngOnInit() {
        const colorModel = aceColorLookup.get(this.state || BannerState.Info)!;
        this.displayIcon = this.icon || (!this.suppressDefaultIcon && colorModel.icon);
        this.isNarrow = !this.block;

        if (!(typeof this.content === 'string')) {
            this.contentIsComponent = true;
        }
    }

    ngAfterViewInit() {
        // Check if a component was passed in as the content
        if (!(typeof this.content === 'string')) {
            this.contentIsComponent = true;
            this.contentComponent.clear();
            this.contentComponent.createComponent(this.content);
        }
    }

    async handleClose(): Promise<void> {
        if (this.closing || this.closed) {
            return;
        }
        this.closing = true;
        // Wait for the css transition to finish
        await this._timerService.whenDoneTransitioning(300);
        this.closed = true;
        this.dismissed.emit();
    }
}
