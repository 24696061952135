import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '../form.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { Constants } from '../../../constants.new';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';

@Component({
    selector: 'form-switches',
    templateUrl: './formSwitches.component.html'
})
export class FormSwitchesComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _formService: FormService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _toastsManager: ToastrService,
        private readonly _constants: Constants,
        private _messageModalService: MessageModalService) { }

    isPrimary: UntypedFormControl = new UntypedFormControl(false);
    isInDevelopment: UntypedFormControl = new UntypedFormControl(false);

    initializedPrimary: boolean;
    initializedDevelopment: boolean;

    formTypes = this._constants.FormTypes;
    selectedFormType: Compliance.NameValuePair<Compliance.FormTypeEnum> = null;
    selectedFormIsSupplementalAppeal: boolean;

    private destroy$: Subject<void> = new Subject<void>();

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    ngOnInit(): void {
        this._updateIsPrimary();

        this._mutexService.lockChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // initial load
            this._updateIsPrimary();
            this._updateIsInDevelopment();
        });

        this._formService.formRevision$.pipe(takeUntil(this.destroy$)).subscribe((formRevision) => {
            // initial load
            this._updateIsPrimary();
            this._updateIsInDevelopment();
            if (formRevision) {
                this.selectedFormType = _.find(this.formTypes, x => x.value as number === formRevision.formTypeId);
                this.selectedFormIsSupplementalAppeal = this.selectedFormType.value === Compliance.FormTypeEnum.Appeal && !!formRevision.parentFormRevisionId;
            }
        });

        this._formService.formRevisionYear$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // year changed
            this._updateIsPrimary();
            this._updateIsInDevelopment();
        });

        this._formService.isPrimary$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // primary changed
            this._updateIsPrimary();
        });

        this._formService.isInDevelopment$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // in development changed
            this._updateIsInDevelopment();
        });

        this._formService.anyAssessorMappingsCertified$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // assessor certifications changed
            this._updateIsInDevelopment();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async onIsPrimaryChange(isPrimary: boolean): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            await this._formService.updateIsPrimary(isPrimary);
            this._toastsManager.success(`Form set as ${isPrimary ? '' : 'not '}primary for ${this._formService.taxYear}`);
        }
        catch(e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }

            if (e2.error.message) {
                try {
                    busyRef.hide();
                    await this._messageModalService.confirm(
                        e2.error.message,
                        'Confirm Update');
                    await this._formService.updateIsPrimary(isPrimary, true);
                } catch (e3) {
                    this.isPrimary.setValue(!isPrimary);
                    return Promise.resolve();
                }
            }
        }
        finally {
            this._updateIsPrimary();
            busyRef.hide();
        }
    }

    async onIsInDevelopmentChange(isInDevelopment: boolean): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            await this._formService.updateIsInDevelopment(isInDevelopment);
            this._toastsManager.success(`Form set as ${isInDevelopment ? '' : 'not '}in development for ${this._formService.taxYear}`);
        }
        finally {
            this._updateIsInDevelopment();
            busyRef.hide();
        }
    }

    private _updateIsPrimary(): void {
        const isPrimary = this._formService.isPrimary;
        if (isPrimary !== null && isPrimary !== undefined) {
            this.isPrimary.setValue(isPrimary);
            this.initializedPrimary = true;
        }
        this._setCanEdit();
    }

    private _updateIsInDevelopment(): void {
        const isInDevelopment = this._formService.isInDevelopment;
        if (isInDevelopment !== null && isInDevelopment !== undefined) {
            this.isInDevelopment.setValue(isInDevelopment);
            this.initializedDevelopment = true;
        }
        this._setCanEdit();
    }

    private _setCanEdit(): void {
        if (!this.canEnterEditMode || !this.canEdit) {
            this.isPrimary.disable();
            this.isInDevelopment.disable();
        } else {
            this.isPrimary.enable();
            this.isInDevelopment.enable();
        }
    }

}
