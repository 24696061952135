<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Site Characteristics"
                  subHeaderLabel="Select characteristics to be displayed on tax bill transmittal"
                  [cancelLabel]="(isEditMode) ? 'Cancel' : 'Close'"
                  [suppressKeyboardEvents]="true"
                  auxButtonLabel="Clear All Fields"
                  [hideAuxButton]="!isEditMode"
                  (auxButton)="removeAllSelectedDescriptors()"
                  (save)="save()"
                  (cancel)="cancel()"
                  [hideSave]="!isEditMode">
    <div class="app-ui-view ws-flex-container-vertical ws-flex-auto ws-stretch">
        <table *ngIf="isInitialized">
            <tr *ngFor="let item of selectedDescriptors; trackBy:trackByIndex; let i = index">
                <td>Characteristic {{numToWord(i + 1)}}</td>
                <td>
                    <site-descriptor-selector
                        [descriptors]="siteDescriptors"
                        [descriptorId]="item"
                        [selectedDescriptors]="selectedDescriptors"
                        [disabled]="!isEditMode || i !== 0 && !item && !selectedDescriptors[i - 1]"
                        (descriptorChange)="onDescriptorChange($event, i)"></site-descriptor-selector>
                </td>
            </tr>
        </table>
    </div>
</ws-modal-wrapper>
