import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppealFormItem, AppealFormOverrideModel, AppealFormSettingLookupOption, AppealFormSettings } from './appeal.application.model';
import AppealsDownloadRequest = Core.AppealsDownloadRequest;
import AppealFormReference = Compliance.AppealFormReference;

@Injectable()
export class AppealApplicationRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly API_URL = '/api/appeal/';
    private readonly APPEAL_APP_URL = '/api/appeal/appealApplication/';

    cacheForms(appealReferences: AppealFormReference[]): Observable<void> {
        return this.httpPost(`${this.API_URL}applicationformcache`, appealReferences);
    }

    createNewApplication(appealId: number): Observable<number> {
        return this.httpPost(`${this.API_URL}${appealId}/appealApplication`, null);
    }

    createNewApplicationBulk(taskIds: number[]): Observable<Compliance.CreateAppealApplicationFormBulkResult[]> {
        return this.httpPost(`${this.API_URL}appealApplicationBulk`, taskIds);
    }

    getMultiAccountFormTemplate(multiAccountAppealFormReference: AppealFormReference): Observable<Blob> {
        return this.httpPost(`${this.APPEAL_APP_URL}multiAccount/formSearch/`, multiAccountAppealFormReference, {
            responseType: 'blob' as 'json'
        });
    }

    getFormTemplate(reference: AppealFormReference): Observable<Blob> {
        return this.httpPost(`${this.API_URL}appealApplication/form`, reference,{
            responseType: 'blob' as 'json'
        });
    }

    getMultiAccountFormDefaultValues(multiAccountAppealFormReference: AppealFormReference): Observable<Compliance.ReturnFormResultModel> {
        return this.httpPost(`${this.APPEAL_APP_URL}multiAccount/dataSearch/`, multiAccountAppealFormReference);
    }

    getFormDefaultValues(reference: AppealFormReference): Observable<Compliance.ReturnFormResultModel> {
        return this.httpPost(`${this.API_URL}appealApplication/data`, reference);
    }

    getMultiAccountFormOverrides(reference: AppealFormReference): Observable<AppealFormOverrideModel[]> {
        return this.httpPost(`${this.APPEAL_APP_URL}multiAccount/overrideSearch`, reference);
    }

    getFormOverrides(reference: AppealFormReference): Observable<Compliance.AppealFormOverrideModel[]> {
        return this.httpPost(`${this.API_URL}appealApplication/overrides`, reference);
    }

    correctApplicationFormRevisions(appealIds: number[]): Observable<void> {
        return this.httpPost(`${this.API_URL}correctApplicationFormRevisions`, appealIds);
    }

    createUpdateOverride(override: Compliance.AppealFormOverrideRequestModel): Observable<AppealFormOverrideModel[]> {
        return this.httpPost(`${this.API_URL}appealApplication/overrides/set`, override);
    }

    deleteOverride(override: Compliance.AppealFormOverrideRequestModel): Observable<void> {
        return this.httpPost(`${this.API_URL}appealApplication/overrides/remove`, override);
    }

    searchByTaskIds(taskIds: number[]): Observable<Compliance.AppealApplicationTaskSearchResult[]> {
        return this.httpPost(`${this.APPEAL_APP_URL}tasksearch`, taskIds);
    }

    getAppealList(appealIds: number[]): Observable<AppealFormItem[]> {
        // this is the source of truth for the UI, the appealsId is for all appeals
        // generally discovered from a list of tasks (task ids)
        // regardless of multi-account or not, it could probably be better named batchAppealIds
        // but this would take API changes too.
        return this.httpPost(`${this.API_URL}applicationFormDataSearch`, appealIds);
    }

    getAvailableSupplementForms(appealIds: number[]): Observable<Compliance.AppealSupplementalFormAssociation[]> {
        return this.httpPost(`${this.API_URL}appealApplication/available-supplemental-forms`, appealIds);
    }

    getAvailableMainForms(appealIds: number[]): Observable<Compliance.AppealMainFormAssociation[]> {
        return this.httpPost(`${this.API_URL}appealApplication/available-main-forms`, appealIds);
    }

    changeSupplementalFormAssociations(request: Compliance.AppealSupplementalFormsAssociationChangeRequest): Observable<Compliance.AppealSupplementFormAssociatedChangeResponse> {
        return this.httpPost(`${this.API_URL}appealApplication/supplemental-form-association`, request);
    }

    changeMainFormAssociations(request: Compliance.AppealMainFormAssociationChangeRequest): Observable<Compliance.AppealSupplementFormAssociatedChangeResponse> {
        return this.httpPost(`${this.API_URL}appealApplication/main-form-association`, request);
    }

    generateOutputFile(appealIds: number[]): Observable<number> {
        return this.httpPost(`${this.APPEAL_APP_URL}generate`, appealIds);
    }

    getOutputFile(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        return this.httpGet(`${this.APPEAL_APP_URL}get/${longRunningProcessId}`, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    getApplicationFormLookups(): Observable<AppealFormSettingLookupOption[]> {
        return this.httpGet(`${this.API_URL}applicationFormSettingsLookups`);
    }

    getSettings(appealIds: number[]): Observable<AppealFormSettings> {
        return this.httpPost(`${this.API_URL}FetchAppealFormRevisionSettings`, appealIds);
    }

    saveSettings(payload: any): Observable<any> {
        return this.httpPost(`${this.API_URL}AppealFormRevisionSettings`, payload);
    }

    checkIfSignatureMayBeApplied(payload: any): Observable<any> {
        return this.httpPost(`${this.API_URL}CheckIfSignatureMayBeApplied`, payload);
    }

    completeAppealTasks(appealIds: number[]): Observable<void> {
        return this.httpPost(`${this.API_URL}completePrepareApplication`, appealIds);
    }

    setTopAppeal(request: Compliance.AppealFormSetTopAppealRequestModel): Observable<void> {
        return this.httpPut(`${this.APPEAL_APP_URL}topAppeal`, request);
    }

    download(request: AppealsDownloadRequest): Observable<HttpResponse<Blob>> {
        // justThisAppealRevisionIdInTheBatch is optional
        return this.httpPost(`${this.APPEAL_APP_URL}Download`,
            request,
            {
                observe: 'response',
                responseType: 'blob' as 'json'
            });
    }

    flattenAndDownload(request: AppealsDownloadRequest): Observable<HttpResponse<Blob>> {
        request.flatten = true;
        return this.httpPost(`${this.APPEAL_APP_URL}FlattenAndDownload`, request, {
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }
}
