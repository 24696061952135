import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UtilitiesService } from 'src/app/UI-Lib/Utilities/Utilities.Service.upgrade';

@Component({
    selector: 'entity-import-editor-estimated-action',
    template: `<i *ngIf="showControl"
                  [ngClass]="{'fa-question':isNotEvaluated(), 'fa-pencil':isUpdate(), 'fa-plus':isInsert(), 'fa-ban':isNoChange(), 'deleted':isEntityDeleted(), 'fa-trash':isDelete()}"
                  [title]="getTitle()" class="fa"></i>`,
    styles: ['.deleted {color:#ffc2b3}']
})
export class EntityImportEditorEstimatedActionCellRendererComponent implements ICellRendererAngularComp {
    gridRow: Compliance.ImportGridRowGridModel;

    agInit(params: ICellRendererParams): void {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    isNotEvaluated(): boolean {
        return (!this.gridRow || !(this.gridRow.actualAction || this.gridRow.estimatedAction));
    }

    isUpdate(): boolean {
        return (this.gridRow && (this.gridRow.actualAction === 'update' || this.gridRow.estimatedAction === 'update'));
    }

    isInsert(): boolean {
        return (this.gridRow && (this.gridRow.actualAction === 'insert' || this.gridRow.estimatedAction === 'insert'));
    }

    isNoChange(): boolean {
        return (this.gridRow && (this.gridRow.actualAction === 'no change' || this.gridRow.estimatedAction === 'no change'));
    }

    isEntityDeleted(): boolean {
        return this.gridRow && this.gridRow.actualAction && this.gridRow.actualAction.endsWith('_Deleted');
    }

    isDelete(): boolean {
        return (this.gridRow && (this.gridRow.actualAction === 'delete' || this.gridRow.estimatedAction === 'delete'));
    }

    getTitle() {
        let title: string = '';

        if (this.isNotEvaluated()) {
            title = 'Not evaluated';
        } else if (this.isUpdate()) {
            title =  'Update';
        } else if (this.isInsert()) {
            title = 'Insert';
        } else if (this.isNoChange()) {
            title = 'No change';
        } else if (this.isDelete()) {
            title = 'Delete';
        }

        if (title != '' && this.isEntityDeleted()){
            title += ' (Has been deleted by user)';
        }

        return title;
    }

    get showControl(): boolean{
        return this.gridRow && UtilitiesService.isNumeric(this.gridRow.rowIndex);
    }
}
