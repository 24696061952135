import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FormRepository } from '../../Repositories/form.repository';
import { StateService } from '../../../Common/States/States.Service';
import { StateSummary } from '../../../Common/States/state.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../../../constants.new';
import { FormTypeEnum } from '../Models/enums';
import { AppealLevel } from '../../../Annual-Details/Appeals/appeal.model';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { YearPickerMixin } from '../../../UI-Lib/Mixins';
import * as _ from 'lodash';
import { ElectronicFilingRepository } from '../../Repositories'
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { FORM_HELP } from '../form.help';

export enum FormType {
    Form = 0,
    FormRevision = 1,
    SupplementalForm = 2
}

export enum FormMode {
    Create = 0,
    Edit = 1
}

export interface FormDetailsParams {
    type: FormType;
    mode: FormMode;
    stateId: number;
    formId?: number;
    formTypeId: Compliance.FormTypeEnum;
    formName?: string;
    formDescription?: string;
    revisionId?: number;
    revisionName?: string;
    revisionDescription?: string;
    supplementalFormName?: string;
    supplementalFormDescription?: string;
    taxYear?: number;
    canEdit: boolean;
    appealLevelId?: number;
    appealLevels?: AppealLevel[];
    allFormRevisions?: Compliance.FormRevisionModel[];
    priorFormRevisionId?: number;
    revisionStampLocationId: number;
    stampLocations: Compliance.FormRevisionStampLocationModel[];
    supportsNBVReporting?: boolean;
    supportsEstFMVReporting?: boolean;
    electronicFilingTypeId?:  Compliance.ElectronicFilingTypeEnum;
    isFormRevisionInLockedBatch: boolean;
}

export interface FormDetailsResult {
    newFormRevision: Compliance.FormRevisionModel;
}

@Component({
    selector: 'form-details',
    templateUrl: './formDetails.component.html'
})
export class FormDetailsComponent extends YearPickerMixin() implements OnInit, IWeissmanModalComponent<FormDetailsParams, FormDetailsResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _toastsManager: ToastrService,
        private readonly _stateService: StateService,
        private readonly _formRepository: FormRepository,
        private readonly _electronicFilingRepository: ElectronicFilingRepository,
        private readonly _helpService: HelpService,
        private readonly _constants: Constants) {
        super();
    }

    params: FormDetailsParams;
    result: FormDetailsResult;

    FormTypeEnum = FormTypeEnum;
    selectedStateId: number;
    formTypes = this._constants.FormTypes;
    formType = FormType;
    formMode = FormMode;
    states: StateSummary[];
    electronicFilingTypes: Compliance.ElectronicFilingTypeModel[];
    doesStateHaveElectronicFilingType: boolean;
    formTitle: string;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    initialFormRevisionNameIfEdit: string;
    priorFormRevisions: Compliance.FormRevisionModel[];

    get modalTitle(): string {
        const modeText = (this.params.mode === FormMode.Create) ? 'New ' : '';
        return `${modeText}${this.formTitle}`;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FORM_HELP);

        const busyMsgLoad = 'load';
        this.busyIndicatorMessageManager.add(`Loading`, busyMsgLoad);

        // Convert to boolean in case of null
        this.params.supportsNBVReporting = !!this.params.supportsNBVReporting;
        this.params.supportsEstFMVReporting = !!this.params.supportsEstFMVReporting;

        try {
            if (this.params.stateId) {
                this.selectedStateId = this.params.stateId;
            }
            switch (this.params.type) {
                case FormType.Form:
                    this.formTitle = 'Form';
                    break;
                case FormType.FormRevision:
                    this.formTitle = 'Revision';
                    break;
                case FormType.SupplementalForm:
                    this.formTitle = 'Supplemental Form';
                    break;
            }

            if (this.params.mode === FormMode.Edit && this.params.type === FormType.FormRevision) {
                this.initialFormRevisionNameIfEdit = this.params.revisionName;
            }

            if (this.params.mode === FormMode.Edit && this.params.type === FormType.SupplementalForm) {
                this.initialFormRevisionNameIfEdit = this.params.supplementalFormName;
            }

            this.priorFormRevisions = _.filter(this.params.allFormRevisions, x => x.formRevisionId !== this.params.revisionId);

            this.states = await this._stateService.getSummary();

            this.electronicFilingTypes = await lastValueFrom(this._electronicFilingRepository.getElectronicFilingTypes(this.params.stateId));
            this.doesStateHaveElectronicFilingType = !!this.electronicFilingTypes?.length;
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgLoad);
        }
    }

    formTypesEqual(a: Compliance.FormTypeEnum, b: FormTypeEnum): boolean {
        return (a as number) === (b as number);
    }

    getElectronicFilingTypeHelpContentId(){
        if(!this.doesStateHaveElectronicFilingType){
            return 'form.electronic-filing-type-state';
        }

        if(this.params.isFormRevisionInLockedBatch){
            return 'form.electronic-filing-type-locked-batch';
        }

        return '';
    }

    async save(): Promise<void> {
        const busyMsgSave = 'save';
        this.busyIndicatorMessageManager.add(`Saving ${this.formTitle}`, busyMsgSave);

        try {
            //todo: move validation to reactive forms
            switch (this.params.mode) {
                case FormMode.Create:
                    switch (this.params.type) {
                        case FormType.Form:
                            if (!this.params.formName) {
                                this._toastsManager.error('Form Name is required.');
                                return;
                            }

                            if (!this.params.formTypeId) {
                                this._toastsManager.error('Form Type is required.');
                                return;
                            }

                            if (!this.params.taxYear) {
                                this._toastsManager.error('Tax Year is required.');
                                return;
                            }

                            if (!this.params.revisionName) {
                                this._toastsManager.error('Revision Name is required.');
                                return;
                            }

                            if (!this.params.revisionStampLocationId) {
                                this._toastsManager.error('Stamp Location is required.');
                                return;
                            }

                            if (this.params.formTypeId === Compliance.FormTypeEnum.Appeal && !this.params.appealLevelId) {
                                this._toastsManager.error('Appeal Level is required.');
                                return;
                            }

                            const formCreateModel: Compliance.FormCreateModel = {
                                stateId: this.params.stateId,
                                taxYear: this.params.taxYear,
                                appealLevelId: this.params.appealLevelId,
                                name: this.params.formName,
                                description: this.params.formDescription,
                                formTypeId: this.params.formTypeId,
                                revisionName: this.params.revisionName,
                                revisionDescription: this.params.revisionDescription,
                                revisionPriorFormRevisionId: this.params.priorFormRevisionId,
                                revisionStampLocationId: this.params.revisionStampLocationId,
                                supportsNBVReporting: this.params.supportsNBVReporting,
                                supportsEstFMVReporting: this.params.supportsEstFMVReporting,
                                electronicFilingTypeId: this.params.electronicFilingTypeId
                            };

                            const form = await lastValueFrom(this._formRepository.createForm(formCreateModel));

                            this.result = {
                                newFormRevision: form.formRevisions[0]
                            };

                            this._bsModalRef.hide();
                            break;
                        case FormType.FormRevision:
                            if (!this.params.revisionName) {
                                this._toastsManager.error('Revision Name is required.');
                                return;
                            }

                            if (!this.params.taxYear) {
                                this._toastsManager.error('Tax Year is required.');
                                return;
                            }

                            if (!this.params.revisionStampLocationId) {
                                this._toastsManager.error('Stamp Location is required.');
                                return;
                            }

                            if (this.params.formTypeId === Compliance.FormTypeEnum.Appeal && !this.params.appealLevelId) {
                                this._toastsManager.error('Appeal Level is required.');
                                return;
                            }

                            const revisionCreateModel: Compliance.FormRevisionCreateModel = {
                                formId: this.params.formId,
                                name: this.params.revisionName,
                                description: this.params.revisionDescription,
                                taxYear: this.params.taxYear,
                                appealLevelId: this.params.appealLevelId,
                                priorFormRevisionId: this.params.priorFormRevisionId,
                                formRevisionStampLocationId: this.params.revisionStampLocationId,
                                supportsNBVReporting: this.params.supportsNBVReporting,
                                supportsEstFMVReporting: this.params.supportsEstFMVReporting,
                                electronicFilingTypeId: this.params.electronicFilingTypeId
                            };

                            const revision = await lastValueFrom(this._formRepository.createRevisionSupplementalForm(revisionCreateModel));

                            this.result = {
                                newFormRevision: revision
                            };

                            this._bsModalRef.hide();
                            break;
                        case FormType.SupplementalForm:
                            if (!this.params.supplementalFormName) {
                                this._toastsManager.error('Form Name is required.');
                                return;
                            }

                            if (!this.params.taxYear) {
                                this._toastsManager.error('Tax Year is required.');
                                return;
                            }

                            if (!this.params.revisionStampLocationId) {
                                this._toastsManager.error('Stamp Location is required.');
                                return;
                            }

                            const supplementalCreateModel: Compliance.FormRevisionCreateModel = {
                                formId: this.params.formId,
                                parentRevisionId: this.params.revisionId,
                                name: this.params.supplementalFormName,
                                description: this.params.supplementalFormDescription,
                                taxYear: this.params.taxYear,
                                priorFormRevisionId: this.params.priorFormRevisionId,
                                appealLevelId: this.params.appealLevelId,
                                formRevisionStampLocationId: this.params.revisionStampLocationId,
                                supportsNBVReporting: this.params.supportsNBVReporting,
                                supportsEstFMVReporting: this.params.supportsEstFMVReporting,
                                electronicFilingTypeId: this.params.electronicFilingTypeId
                            };

                            const supplement = await lastValueFrom(this._formRepository.createRevisionSupplementalForm(supplementalCreateModel));

                            this.result = {
                                newFormRevision: supplement
                            };

                            this._bsModalRef.hide();
                            break;
                    }
                    break;
                case FormMode.Edit:
                    switch (this.params.type) {
                        case FormType.Form:
                            if (!this.params.formName) {
                                this._toastsManager.error('Form Name is required.');
                                return;
                            }

                            const formUpdateModel: Compliance.FormUpdateModel = {
                                name: this.params.formName,
                                description: this.params.formDescription
                            };

                            await lastValueFrom(this._formRepository.updateForm(this.params.formId, formUpdateModel));

                            this.result = {
                                newFormRevision: null
                            };

                            this._bsModalRef.hide();
                            break;
                        case FormType.FormRevision:
                            if (!this.params.revisionName) {
                                this._toastsManager.error('Revision Name is required.');
                                return;
                            }

                            if (!this.params.revisionStampLocationId) {
                                this._toastsManager.error('Stamp Location is required.');
                                return;
                            }

                            const revisionUpdateModel: Compliance.FormRevisionUpdateModel = {
                                name: this.params.revisionName,
                                description: this.params.revisionDescription,
                                priorFormRevisionId: this.params.priorFormRevisionId,
                                formRevisionStampLocationId: this.params.revisionStampLocationId,
                                supportsNBVReporting: this.params.supportsNBVReporting,
                                supportsEstFMVReporting: this.params.supportsEstFMVReporting,
                                electronicFilingTypeId: this.params.electronicFilingTypeId
                            };

                            await lastValueFrom(this._formRepository.updateFormRevision(this.params.revisionId, revisionUpdateModel, true));

                            this.result = {
                                newFormRevision: null
                            };

                            this._bsModalRef.hide();

                            break;
                        case FormType.SupplementalForm:
                            if (!this.params.supplementalFormName) {
                                this._toastsManager.error('Form Name is required.');
                                return;
                            }

                            if (!this.params.revisionStampLocationId) {
                                this._toastsManager.error('Stamp Location is required.');
                                return;
                            }

                            const supplementalUpdateModel: Compliance.FormRevisionUpdateModel = {
                                name: this.params.supplementalFormName,
                                description: this.params.supplementalFormDescription,
                                priorFormRevisionId: this.params.priorFormRevisionId,
                                formRevisionStampLocationId: this.params.revisionStampLocationId,
                                supportsNBVReporting: this.params.supportsNBVReporting,
                                supportsEstFMVReporting: this.params.supportsEstFMVReporting,
                                electronicFilingTypeId: this.params.electronicFilingTypeId
                            };

                            await lastValueFrom(this._formRepository.updateFormRevision(this.params.revisionId, supplementalUpdateModel, true));

                            this.result = {
                                newFormRevision: null
                            };

                            this._bsModalRef.hide();
                            break;
                    }
                    break;
            }
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgSave);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
