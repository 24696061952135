<div [style.display]="dataLoading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
    <i class="fa fa-spinner fa-pulse fa-5x"></i>
</div>
<div class="container" [hidden]="dataLoading">
    <div class="single-column-layout">
        <h1>Roles</h1>
        <table class="roles table table-striped table-hover table-condensed">
            <tr>
                <th>Role Description</th>
                <th class="text-center" *ngFor="let entityType of entityTypes">
                    {{entityType}}
                </th>
            </tr>
            <ng-container *ngFor="let role of roles; let i=index">
                <tr>
                    <td>{{ role.roleDesc || '' }}</td>
                    <td *ngFor="let entityType of entityTypes" class="text-center">
                        <input type="checkbox" [disabled]="true" [(ngModel)]="role[entityType]">
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
