import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'return-comparison-modal',
    template: `
        <ws-modal-wrapper headerLabel="Confirm" acceptLabel="Reset" (save)="submitReset()" (cancel)="cancel()">
            <span>Are you sure you wish to reset returns using non-default forms?</span>
        </ws-modal-wrapper>
    `
})
export class ReturnCompareConfirmationModalComponent implements IWeissmanModalComponent<void, boolean> {
    constructor(private readonly _bsModalRef: BsModalRef) { }

    params: void;
    result: boolean;
    
    /**
     * Submit the reset request
     * */
    submitReset() {
        this.result = true;
        this._bsModalRef.hide();
    }

    /**
     * Cancel the modal
     * */
    cancel(): void {
        this._bsModalRef.hide();
    }
}
