import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LinkedSitesListComponent, LinkedSitesListParams } from '../Linked-Sites-List/linkedSitesList.component';
import { WeissmanModalService } from '../.../../../../WeissmanModalService';
import { LinkedSitesReportingComponent } from '../Linked-Sites-Reporting/linkedSitesReporting.component';
import * as _ from 'lodash';
import { LinkedSitesReportingParams } from '../Linked-Sites-Reporting/linkedSitesReporting.component';
import { LinkedSitesReportingResult } from '../Linked-Sites-Reporting/linkedSitesReporting.component';
import { Site } from 'src/app/Entity/Site/Site.Model';

@Component({
    selector: 'linked-sites-edit',
    templateUrl: './linkedSitesEdit.component.html'
})
export class LinkedSitesEditComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService) {
    }

    @Input() site: Site;
    @Input() isDisabled: boolean;

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async edit(): Promise<void> {
        try {

            const params: LinkedSitesListParams = {
                companyId: this.site.companyID,
                siteId: this.site.siteID,
                sites: _.map(this.site.linkedSites, _.clone),
                masterSiteId: this.site.masterSiteId,
                editMode: true
            };

            if (!params.sites.length) {
                const bsReportingModalRef = await this._wsModalService.show(() => {
                    return this._bsModalService.show(LinkedSitesReportingComponent,
                        {
                            class: 'modal-md',
                            initialState: {
                                params: {
                                    siteId: this.site.siteID,
                                    companyId: this.site.companyID
                                } as LinkedSitesReportingParams
                            },
                            ignoreBackdropClick: true
                        });
                }).whenClosed;

                const reportingResult: LinkedSitesReportingResult = (bsReportingModalRef.content as LinkedSitesReportingComponent).result;
                if (!reportingResult && reportingResult.site && reportingResult.masterSite) {
                    return Promise.resolve();
                }

                reportingResult.site.masterSiteId = reportingResult.masterSite.siteId;
                params.masterSiteId = reportingResult.masterSite.siteId;
                params.sites.push(reportingResult.site);

                if (reportingResult.masterSite.siteId !== reportingResult.site.siteId) {
                    params.sites.push(reportingResult.masterSite);
                }
            }

            const bsModalSitesRef = await this._wsModalService.show(() => {
                return this._bsModalService.show(LinkedSitesListComponent,
                    {
                        class: 'modal-lg',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

            const sitesResult = (bsModalSitesRef.content as LinkedSitesListComponent).result;

            if (!sitesResult) {
                return Promise.resolve();
            }

            this.site.masterSiteId = sitesResult.masterSiteId;
            this.site.linkedSites = sitesResult.sites;

        } catch (e) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }
}
