import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { TeamPickerComponent } from './teamPicker.component';
import { TeamsPageComponent } from './teams.page.component';
import { TeamAssignmentPanelComponent } from './Assignment/teamAssignmentPanel.component';
import { TeamService } from './team.service';

@NgModule({
    imports: [ WeissmanSharedModule ],
    declarations: [
        TeamsPageComponent,
        TeamPickerComponent,
        TeamAssignmentPanelComponent
    ],
    exports: [
        TeamsPageComponent,
        TeamPickerComponent
    ],
    providers: [TeamService]
})
export class TeamModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('teamsPage', TeamsPageComponent);
        hybridAdapterUtility.downgradeNg2Component('teamAssignmentPanel', TeamAssignmentPanelComponent);
        hybridAdapterUtility.downgradeNg2Provider('teamsService', TeamService);
    }
}
