import { Injectable } from '@angular/core';

export interface FilingRowModel extends Compliance.FilingModel {
    changed: boolean;
    deleted: boolean;
    added: boolean;
    originalValue: Compliance.FilingModel;
}

@Injectable()
export class FilingBatchDetailsService {
    private _filings: FilingRowModel[];

    get currentFilings(): FilingRowModel[] {
        return this._filings;
    }

    set currentFilings(filings: FilingRowModel[]) {
        this._filings = filings;
    }
}
