import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AccountService, Settings } from '../User/account.service';
import { UserRepository } from '../Core-Repositories';
import { UserSettingPayload } from '../Core-Repositories/user.repository';
import * as _ from 'lodash';

@Injectable(
    { providedIn: 'root' }
)
export class UserSettingsService {
    constructor(
        private readonly _accountService: AccountService,
        private readonly _userRepository: UserRepository
    ) {}

    getSettingById(id: number): Settings {
        return this._accountService.userData.settings.find(x => x.userSettingID === id);
    }

    getSettings(): Settings[] {
        return _.cloneDeep(this._accountService.userData.settings);
    }

    getSettingsByGroup(groupId: number) {
        return this._accountService.userData.settings.filter(x => x.groupId === groupId);
    }

    async getSettingsByGroupAndUserId(groupId: number, userId: number): Promise<Settings[]> {
        const result = await lastValueFrom(this._userRepository.getSettingsByGroupAndUserId(groupId, userId));
        return result.map(x => this._mapResponseToSetting(x));
    }

    getAdvancedCriteriaSettings(): Settings[] {
        return this._accountService.userData.settings.filter(x => x.groupId === 10);
    }

    async save(setting: Settings): Promise<Settings> {
        const foundSettingIndex = this._accountService.userData.settings.findIndex(x => x.id === setting.id);

        const result = await lastValueFrom(this._userRepository.saveUserSettings(this._mapSettingToPayload(setting)));

        const newSetting = this._mapResponseToSetting(result);

        if (foundSettingIndex < 0) {
            this._accountService.userData.settings.push(newSetting);
        } else {
            this._accountService.userData.settings[foundSettingIndex] = newSetting;
        }

        return newSetting;
    }

    private _mapResponseToSetting(setting: any): Settings {
        return {
            id: setting.userSettingID,
            name: setting.settingName,
            value: JSON.parse(setting.settingValue),
            groupId: setting.userSettingGroupID,
            groupName: setting.settingGroupName,
            folderId: setting.userSettingFolderID,
            folderName: setting.settingFolderName,
        };
    }

    private _mapSettingToPayload(setting: Settings): UserSettingPayload {
        const settingToReturn: UserSettingPayload = {
            folderID: setting.folderId,
            groupID: setting.groupId,
            value: setting.value,
            name: setting.name,
            groupName: setting.groupName,
            folderName: setting.folderName
        };

        if (setting.id) {
            settingToReturn.settingID = setting.id;
        }

        if (setting.userId) {
            settingToReturn.userID = setting.userId;
        }

        return settingToReturn;
    }
}
