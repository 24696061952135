import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { AceAuthOidcWrapperService } from '../../IAM/aceAuthOidcWrapper.service';
import { AppStateService } from '../../Layout/appStateService';
import { LoginFromSSOModel, NavigationService } from '../../Layout/navigation.service';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { TimerService, UtilitiesService } from '../../UI-Lib/Utilities';
import { AccountService, GrantTypes, ILoginData } from '../../User/account.service';
import { AuthStorageService } from '../../User/authStorage.service';
import { PasswordChangeData } from '../New-Password/newPassword.component';
import { UserService } from '../user.service';

enum LoginPageModesEnum {
    'login' = 1,
    'getToken' = 2,
    'useToken' = 3,
    'selectSSODomain' = 4,
    'awaitingSSO' = 5,
    'homeRealmDiscovery' = 6,
    'migratePassword' = 7
}

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private readonly _featureFlagsService: FeatureFlagsService,
                private readonly _accountService: AccountService,
                private readonly _appStateService: AppStateService,
                private readonly _authStorageService: AuthStorageService,
                private readonly _aceAuthOidcWrapperService: AceAuthOidcWrapperService,
                private readonly _userService: UserService,
                private readonly _navigationService: NavigationService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _elRef: ElementRef,
                private readonly _toastr: ToastrService,
                private readonly _timer: TimerService,
                private readonly _utils: UtilitiesService,
                private readonly _router: UIRouter) {
    }

    showLogin: boolean;

    userName: FormControl = new FormControl('', [Validators.required]);
    password: FormControl = new FormControl('', [Validators.required]);
    usernameOrEmail: FormControl = new FormControl('', [Validators.required]);
    loginDomain: FormControl = new FormControl('', [Validators.required]);

    currentMode: number;
    warning: string = '';
    error: string = '';
    alreadyUsed: string = '';
    passwordsValid: boolean;
    passwordObj: PasswordChangeData = {
        current: '',
        'new': '',
        confirm: ''
    };
    loginPageModesEnum = LoginPageModesEnum;

    isIAMMigration: boolean;
    isSSOEnabled: boolean;
    rememberSSODomain: boolean;
    rememberSSOEmail: boolean;
    showSpinner: boolean;
    showGetNewTokenButton: boolean;

    passwordResetEmailHasBeenSentMessage: string;

    private _destroy$: Subject<void> = new Subject();

    private readonly LAST_SSO_DOMAIN_KEY = 'lastSSODomain';
    private readonly SAVE_SSO_EMAIL_KEY = 'rememberSSOEmail';

    ngOnInit() {
        this.error = '';
        this.passwordResetEmailHasBeenSentMessage = undefined;
        this.showSpinner = false;
        this.showGetNewTokenButton = false;
        this.rememberSSODomain = localStorage['rememberSSODomain'] !== 'false';
        this.rememberSSOEmail = !!localStorage[this.SAVE_SSO_EMAIL_KEY];
        this.warning = this._accountService.initialMessage;

        let appState = null;
        this._appStateService.appState$.pipe(takeUntil(this._destroy$)).subscribe(newAppState => {
            appState = newAppState;
        });

        if (appState === this._appStateService.appStates.LoggedIn) {
            // Redirect to home (and don't leave the login route in router history)
            this._router.stateService.go('home', null, { location: 'replace' });
            return;
        } else {
            this._authStorageService.clearAuthData();
        }

        this._userService.isSSOIntegrationEnabled().then((isEnabled) => {
            this.isSSOEnabled = isEnabled;
        });

        this._navigationService.loginFromSSO$.pipe(takeUntil(this._destroy$))
            .subscribe(async (data: LoginFromSSOModel) => {
                this.currentMode = this.isIAMMigration ? LoginPageModesEnum.homeRealmDiscovery : LoginPageModesEnum.selectSSODomain;
                if (data.message) {
                    this.error = data.message;
                } else {
                    await this.loginWithSSO(data.grantType, data.request);
                }
            });

        if (this._router.globals.params.tokenHash) {
            this.currentMode = LoginPageModesEnum.useToken;
        } else if (this._accountService.homeRealmDiscoveryStarted) {
            this.currentMode = LoginPageModesEnum.homeRealmDiscovery;
            if (this.rememberSSOEmail) {
                this.userName.setValue(localStorage[this.SAVE_SSO_EMAIL_KEY]);
            }
        } else {
            this.currentMode = LoginPageModesEnum.login;

            if (localStorage[this.LAST_SSO_DOMAIN_KEY]) {
                this.loginDomain.setValue(localStorage[this.LAST_SSO_DOMAIN_KEY]);
                this.currentMode = LoginPageModesEnum.selectSSODomain;
            }
        }

        // When IAM is on, never use old login modes. It shouldn't be possible for this to happen anyway, but if one is missed this
        // is a fallback to ensure it redirects to HRD.
        if (this._featureFlagsService.featureFlags.enableIAMLogin
            && (this.currentMode === LoginPageModesEnum.selectSSODomain || this.currentMode === LoginPageModesEnum.login)) {
            this.currentMode = LoginPageModesEnum.homeRealmDiscovery;
        }

        this.showLogin = true;
    }

    ngAfterViewInit(): void {
        this.setFocus();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async login(): Promise<void> {
        this.showLogin = false;

        const errorHandler = response => {
            console.log('Error on login', response);
            this.showLogin = true;
            this.password.setValue('');
            if (response && response.error && response.error.error_description) {
                this.error = response.error.error_description;
                this.setFocus();
            } else {
                this.error = 'An unexpected error has occurred';
                this.setFocus();
                throw response;
            }
        };

        await this.checkForExistingRefreshToken();
        const loginData = { username: this.userName.value, password: this.password.value };

        if (this.isIAMMigration) {
            try {
                const migrateResult = await this._accountService.loginMigration(this.userName.value, GrantTypes.password, loginData);
                if (migrateResult.alreadyMigrated) {
                    this.startAuthOidcLogin(this.userName.value);
                } else {
                    window.location.href = migrateResult.inviteUrl;
                }
            } catch (err) {
                errorHandler(err);
            }

            return;
        }

        try {
            const didSucceed = await this._accountService.login(GrantTypes.password, loginData);
            this.showSpinner = false;

            if (!didSucceed) {
                return;
            }

            delete localStorage[this.LAST_SSO_DOMAIN_KEY];

            if (this.currentMode === LoginPageModesEnum.useToken) {
                this._router.stateService.go('home');
            } else {
                this._navigationService.postLogin();
            }

        } catch (err) {
            errorHandler(err);
        }
    }

    async checkForExistingRefreshToken(): Promise<void> {
        if (this._accountService.checkForExistingRefreshToken()) {
            const result = await this._messageBoxService.open({
                message: 'You are already logged in on another tab or window. Continue with your existing session?',
                buttons: MessageBoxButtons.YesNo
            });
            if (result !== MessageBoxResult.No) {
                // Refresh the page and let default handling catch the refresh token
                (window.location as any).reload(true);
            }
        }
    }

    async loginWithSSO(grantType: GrantTypes, ssoRequest: string | ILoginData): Promise<void> {
        this.showSpinner = true;

        const errorHandler = response => {
            this.showSpinner = false;
            if (response && response.error && response.error.error_description) {
                this.error = response.error.error_description;
            } else {
                this.error = 'An unexpected error has occurred';
                throw response;
            }
        };

        if (this.isIAMMigration) {
            try {
                const migrateResult = await this._accountService.loginMigration(this.userName.value, grantType, ssoRequest);
                if (migrateResult.alreadyMigrated) {
                    this.startAuthOidcLogin(this.userName.value);
                } else {
                    window.location.href = migrateResult.inviteUrl;
                }
            } catch (err) {
                errorHandler(err);
            }
        }

        try {
            await this._accountService.login(grantType, ssoRequest);
            if (this.rememberSSODomain) {
                localStorage[this.LAST_SSO_DOMAIN_KEY] = this.loginDomain.value;
            } else {
                delete localStorage[this.LAST_SSO_DOMAIN_KEY];
            }
            this._navigationService.postLogin();
        } catch (err) {
            errorHandler(err);
        }
    }

    async resetPassword(): Promise<void> {
        this.showSpinner = true;
        this.showGetNewTokenButton = false;
        this.error = '';
        const result = await this._userService.resetPasswordUsingToken(this._router.globals.params.tokenHash, this.passwordObj.new);
        try {
            // console.log('resetPasswordUsingToken - api response:', result);
            // console.log('this.passwordObj:', this.passwordObj);
            if (result.error == '') {
                this.userName.setValue(result.userName);
                this.password.setValue(this.passwordObj.new);
                await this.login();
            } else {
                this.error = result.error;
                this._toastr.error(this.error);
                this.showSpinner = false;

                if (result.showResetPaswordFormAgain) {
                    this.passwordObj.new = null;
                    this.passwordObj.confirm = null;
                    this.currentMode = LoginPageModesEnum.useToken;
                    this.showGetNewTokenButton = false;
                } else
                    this.showGetNewTokenButton = true;
            }
        } catch (error) {
            console.log('login page controller - error while resetting password:', error);
            this.error = error.data.error_description;
            this.setFocus();
        }
    }

    async sendPasswordResetToken(): Promise<void> {
        this.showSpinner = true;
        this.passwordResetEmailHasBeenSentMessage = undefined;
        try {
            const result = await this._userService.sendPasswordResetToken(this.usernameOrEmail.value);
            console.log('sendPasswordResetToken  api call result', result);
            this.showSpinner = false;

            if (result != '') {
                this.passwordResetEmailHasBeenSentMessage = result;
                this._toastr.error(result);
            } else	// no errors
                this.passwordResetEmailHasBeenSentMessage = 'Check your inbox for a password reset email.';
        } catch (error) {
            console.log('sendPasswordResetToken - error:', error);
        }
    }

    async submitHomeRealmDiscovery(): Promise<void> {
        this.isIAMMigration = true;
        this.showSpinner = true;

        if (this.rememberSSOEmail) {
            localStorage[this.SAVE_SSO_EMAIL_KEY] = this.userName.value;
        } else {
            delete localStorage[this.SAVE_SSO_EMAIL_KEY];
        }
        try {
            const initialData = await this._accountService.getIAMInitialLoginData(this.userName.value);
            this._accountService.handleInitialRoute();
            if (initialData.migrationSSOExceptionEndpoint) {
                await this.initializeSSOLogin(initialData.migrationSSOExceptionEndpoint);
                this.isIAMMigration = false;
            } else if (!initialData.needsMigration) {
                this.startAuthOidcLogin(this.userName.value);
            } else if (initialData.ssoDomain) {
                this.loginDomain.setValue(initialData.ssoDomain);
                await this.initializeSSOLogin();
            } else {
                this.showSpinner = false;
                this.currentMode = LoginPageModesEnum.migratePassword;
            }
        } catch (error) {
            this.showSpinner = false;
            console.error(error);
            this.error = 'An unexpected error has ocurred';
        }
    }

    startAuthOidcLogin(userName: string): void {
        this._aceAuthOidcWrapperService.authElement$.pipe(takeUntil(this._destroy$))
            .subscribe(el => {
                const redirectUrl = this._navigationService.getInitialUrl() || window.location.href;
                console.log('redirectUrl', redirectUrl);
                try {
                    el.authenticate({
                        redirectUrl: redirectUrl,
                        email: userName,
                        forceLogin: true
                    });
                } catch (err) {
                    this.showSpinner = false;
                    throw err;
                }
            });
    }

    setFocus() {
        this._utils.focusOnElement('input[name=username]');
    }

    switchToSSOMode() {
        this.currentMode = LoginPageModesEnum.selectSSODomain;
        this.showSpinner = false;
        this.error = '';
        this._utils.focusOnElement('input[name=loginDomain]');
    }

// If overrideEndpoint is set that's what will be redirected to, otherwise get the appropriate endpoint for this.loginDomain
    async initializeSSOLogin(overrideEndpoint?: System.Uri | string): Promise<void> {
        await this.checkForExistingRefreshToken();
        this.showSpinner = true;
        this.error = '';

        let endpoint = overrideEndpoint;
        if (!overrideEndpoint) {
            endpoint = await this._userService.getSSOIntegrationEndpoint(this.loginDomain.value);
        }

        this.showSpinner = false;
        if (endpoint === null) {
            this.error = 'Domain not recognized';
        } else {
            endpoint = `${endpoint + ((endpoint as string).indexOf('?') >= 0 ? '&' : '?')}nocache=${+new Date()}`;
            const popupWindow = window.open(endpoint, 'ssowindow', 'toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=yes,width=800,height=600');
            if (!popupWindow) {
                this.error = 'Error opening pop-up. Please ensure pop-ups are enabled for this site';
            } else {
                this.currentMode = LoginPageModesEnum.awaitingSSO;
            }
        }
    }

    cancelSelectSSODomain(): void {
        this.currentMode = this.isIAMMigration ? LoginPageModesEnum.homeRealmDiscovery : LoginPageModesEnum.login;
        this.error = '';
        this.showSpinner = false;
        this.setFocus();
    }

    setRememberSSODomain(): void {
        if (this.rememberSSODomain) {
            delete localStorage['rememberSSODomain'];
        } else {
            localStorage['rememberSSODomain'] = 'false';
        }
    }

    goToLogin(): void {
        this._router.stateService.go('login');
    }
}
