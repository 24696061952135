import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LinkedSitesComponent } from './Linked-Sites/linkedSites.component';
import { LinkedSitesEditComponent } from './Linked-Sites/Linked-Sites-Edit/linkedSitesEdit.component';
import { LinkedSitesListComponent } from './Linked-Sites/Linked-Sites-List/linkedSitesList.component';
import { LinkedSitesListGridActionCellRendererComponent } from './Linked-Sites/Linked-Sites-List/agGridActionCellRenderer.component';
import { ComplianceSiteService } from './site.service';
import { LinkedSitesAddComponent } from './Linked-Sites/Linked-Sites-Add/linkedSitesAdd.component';
import { LinkedSitesReportingComponent } from './Linked-Sites/Linked-Sites-Reporting/linkedSitesReporting.component';
import { LinkedSitesListGridNameCellRendererComponent } from './Linked-Sites/Linked-Sites-List/agGridNameCellRenderer.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            LinkedSitesListGridActionCellRendererComponent,
            LinkedSitesListGridNameCellRendererComponent
        ]),
        TypeaheadModule
    ],
    declarations: [
        LinkedSitesComponent,
        LinkedSitesEditComponent,
        LinkedSitesListComponent,
        LinkedSitesListGridActionCellRendererComponent,
        LinkedSitesAddComponent,
        LinkedSitesReportingComponent,
        LinkedSitesListGridNameCellRendererComponent
    ],
    providers: [
        ComplianceSiteService
    ],
    exports: [
        LinkedSitesComponent,
        LinkedSitesEditComponent,
        LinkedSitesListComponent,
        LinkedSitesAddComponent,
        LinkedSitesReportingComponent
    ]
})
export class ComplianceSiteModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('linkedSites', LinkedSitesComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedSitesEdit', LinkedSitesEditComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedSitesList', LinkedSitesListComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedSitesAdd', LinkedSitesAddComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedSitesReporting', LinkedSitesReportingComponent);
    }
}
