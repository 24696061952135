<div *ngIf="item" class="parcel-tile" (click)="navigateToParcel.emit(item.parcelID)">
    <div class="well">

        <img *ngIf="item.activityStatusID !== 2" [src]="item.activityStatusID === 0 ? '/images/inactive.jpg' : '/images/active-pending.jpg'" alt="Inactive" class="img-responsive parcel-tile-inactive">
        <div class="parcel-tile-info">
            <parcel-icon [parcel]="item"
                         size="large"
                         [includeIcons]="true"
                         [ppReturnPreparationAllowed]="ppReturnPreparationAllowed"
                         [stateAllowConsolidating]="stateAllowConsolidating">
            </parcel-icon>
            <div class="parcel-tile-acct-num">
                <span [ngClass]="{ 'hide-year': (item.acctNum && item.acctNum.length >= 20) }">{{item.acctNum}}</span>
            </div>
            <div class="parcel-tile-year" *ngIf="item.annualYear">
                <span class="year-display">{{item.annualYear}}</span>
            </div>
        </div>
        <div [ngClass]="{ 'invisible': !item.description }" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{item.description || 'dummy'}}</div>
        <div class="parcel-tile-data-wrapper">
            <table class="parcel-tile-data" *ngIf="item.totalFMV != null || item.isExternalReportingParcel === true">
                <tr class="main-row" [ngClass]="{ 'not-actual': ((item.status == null || item.status === 0) && item.consolidatingTypeId !== 2) }" *ngIf="item.isExternalReportingParcel !== true">
                    <td style="position: relative;">
                        <strong>Total FMV:</strong>
                        <span class="fa-stack" *ngIf="!item.isFmvInclude" title="FMV excluded from site analysis" style="position: absolute; bottom: -7px;">
                            <i class="fa fa-stack-1x" style="font-weight: bold;">Σ</i>
                            <i class="fa fa-ban fa-stack-2x text-danger fa-flip-horizontal"></i>
                        </span>
                    </td>
                    <td>{{(item.consolidatingTypeId !== 2 ? (item.totalFMV | number: '1.0-0') : 'N/A')}}</td>
                    <td>
                        <span *ngIf="item.fmvChange !== 0" style="white-space: nowrap;" [ngClass]="{ 'increase': item.fmvChange > 0, 'decrease': item.fmvChange < 0 }">
                            <i *ngIf="item.fmvChange !== 0" class="fa" [ngClass]="{ 'fa-arrow-up': item.fmvChange > 0, 'fa-arrow-down': item.fmvChange < 0 }"></i> {{item.fmvChange | number}}
                        </span>
                    </td>
                </tr>
                <tr [ngClass]="{'not-actual': item.status === 0}" *ngIf="item.isExternalReportingParcel !== true">
                    <td colspan="3">
                        <div class="components-wrapper">
                            <table class="components float-end" *ngIf="item.components && item.components.length">
                                <tr *ngFor="let component of item.components">
                                    <td><strong>{{component.componentName}}:</strong></td>
                                    <td>{{component.fairMarketValue | number}}</td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
                <tr class="main-row" [ngClass]="{ 'tax-is-estimated': item.taxIsEstimated != false && item.consolidatingTypeId !== 2 }" *ngIf="item.isExternalReportingParcel !== true">
                    <td><strong>Taxes:</strong></td>
                    <td *ngIf="item.consolidatingTypeId !== 2">${{item.totalTaxes | number: '1.2'}}</td>
                    <td *ngIf="item.consolidatingTypeId === 2">N/A</td>
                    <td>
                        <span *ngIf="item.taxPercentDiff && item.taxPercentDiff !== 0" style="white-space: nowrap;" [ngClass]="{ 'increase': item.taxPercentDiff > 0, 'decrease': item.taxPercentDiff < 0 }">
                            <i class="fa" [ngClass]="{ 'fa-arrow-up': item.taxPercentDiff > 0, 'fa-arrow-down': item.taxPercentDiff < 0 }"></i> {{item.taxPercentDiff | percent: '1.3'}}
                        </span>
                    </td>
                </tr>
                <tr *ngIf="item.isExternalReportingParcel === true">
                    <td style="white-space: normal; text-align: left;">This site's assets are reported on a parcel under another site. Click here to navigate to the reporting parcel.</td>
                </tr>
            </table>
        </div>
    </div>
</div>
