import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterCheckbox } from './assetAdvancedDetails.component';

@Component({
    selector: 'advanced-detail-checkbox',
    templateUrl: './assetAdvancedDetailCheckbox.component.html'
})
export class AssetAdvancedDetailCheckboxComponent {
    @Input() options: Array<FilterCheckbox>;

    @Output() changed: EventEmitter<FilterCheckbox> = new EventEmitter();

    /**
     * Apply the change from clicking the label of the checkbox
     * @param check
     */
    changedFromClick(check: FilterCheckbox): void {
        check.formControl.setValue(!check.formControl.value);
        this.changed.emit(check);
    }
}
