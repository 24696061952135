(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdStateDropdown', sdStateDropdown);

	sdStateDropdown.$inject = ['StatesService'];

	function sdStateDropdown(statesService) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			templateUrl: 'app/Common/States/State-Dropdown/_stateDropdown.html',
			restrict: 'E',
			controller: StateDropdownController,
			controllerAs: 'vm',
			scope: {
				stateId: '=',
				elementType: '@',
				labelStyle: '=',
				hasBlank: '=',
				disabled: '=',
				isOptional: '=',
				useFullName: '=',
				includeAll: '=',
				changeFn: '&',
				filterByCountryId: '='
			},
			bindToController: true
		};

		return directive;

		function StateDropdownController() {
			var vm = this;

			vm.states = [];

			vm.getName = getName;
			vm.assignValue = assignValue

			activate();

			function activate() {
				loadStates();
			}

			function loadStates() {
				statesService.getSummary()
					.then(function (result) {
						result = _
							.chain(result)
							.filter(function(state) {
                                return vm.filterByCountryId ? state.countryID === vm.filterByCountryId : true;
							})
							.map(function(state) {
								state.name = vm.useFullName ? state.fullName : state.abbr;
								return state;
							})
							.sortBy(['name'])
							.value();

						if(vm.includeAll) {
							var all = {
								stateID: 999,
								name: 'All'
							}
							result.unshift(all);
						}
						if (vm.hasBlank) {
							var blankState = {
								stateID: null,
								name: ''
							};
							result.unshift(blankState);
						}

						vm.states = result;
					})
			}

			function getName(id) {
				var state = _.find(vm.states, {
					stateID: id
				});

				if (state) {
					return state.name;
				} else {
					return null;
				}
			}

			function assignValue(data) {
				vm.stateId = data;
			}
		}
	}
})();
