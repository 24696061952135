import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'file-viewer',
    template: `
        <pdf-viewer *ngIf="file.type === 'application/pdf'" [fileUrl]="fileUrl"></pdf-viewer>
        <pre *ngIf="text" class="preview-window">{{text}}</pre>
        <img *ngIf="isImage" [attr.src]="fileUrl" class="img-responsive preview-window-image" alt="Image File Missing" />
    `,
    styles: [`
        .preview-window {
            white-space: pre-wrap;
            word-break: break-word;
        }
        .preview-window-image {
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
    `]
})
export class FileViewerComponent implements OnInit {
    @Input() file: File;
    @ViewChild('elemRoot') elemRoot: ElementRef;
    fileUrl: string;
    text: string | ArrayBuffer;
    isImage: boolean = false;

    ngOnInit(): void {
        if (this.file.type === 'text/plain') {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                this.text = fileReader.result;
            };

            fileReader.readAsText(this.file);
        } else if (this.file.type.split('/')[0] === 'image') {
            const reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.fileUrl = reader.result as string;
                this.isImage = true;
            };
        } else {
            const blob = new Blob([this.file], { type: this.file.type });
            this.fileUrl = URL.createObjectURL(blob);
        }
    }
}
