export class AttachmentModalData {
    entityType: string;
    parentId: number;
    parentType: string;
    entityName: string;
    entityDescription: string;
    belowParcelEntity: AttachmentModalEntityData;
    entityData: AttachmentModalEntityData;
    year: number;
    disableYears: boolean;
    singleYear: boolean;
    readOnly: boolean = false;
    startOnPhotos: boolean;
    parentTypeId?: number;
    disableCategories?: boolean;
    attachmentCategoryId?: Core.AttachmentCategories;
    attachmentTypeId?: Core.AttachmentTypes;
    showEmailAttachmentButton?: boolean;
    selectedAttachmentId?: System.Guid;
}

export class AttachmentModalEntityData {
    typeId: number;
    id: number;
    name: string;
}
