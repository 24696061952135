import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const RETURN_PAGE_HELP: HelpContent[] = [
    {
        helpContentId: 'return-page.merged',
        tooltipText: 'This parcel is part of a consolidated form.'
    },
    {
        helpContentId: 'return-page.form-setup',
        tooltipText: 'View the form setup in a new tab.'
    },
    {
        helpContentId: 'return-page.view-depreciation-table',
        tooltipText: 'View depreciation table'
    },
    {
        helpContentId: 'return-page.view-index-table',
        tooltipText: 'View index table'
    }
];
