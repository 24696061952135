import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from 'src/app/Compliance/AgGrid';
import { PaymentBatchService } from '../../paymentBatch.service';

export interface PaymentBatchPaymentsDataSourceParams {
    paymentBatchId: number;
}

export class PaymentBatchPaymentsAgGridDataSource extends AgGridDataSourceBase<PaymentBatchPaymentsDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _paymentBatchService: PaymentBatchService,
        protected _dataSourceParamsFn: () => PaymentBatchPaymentsDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Core.PaymentBatchDetailsEntryPropertyEnum>[] = [
        { name: 'paymentStatus', value: Core.PaymentBatchDetailsEntryPropertyEnum.PaymentStatus },
        { name: 'collector', value: Core.PaymentBatchDetailsEntryPropertyEnum.Collector },
        { name: 'parcelAcctNum', value: Core.PaymentBatchDetailsEntryPropertyEnum.ParcelAcctNum },
        { name: 'installmentName', value: Core.PaymentBatchDetailsEntryPropertyEnum.InstallmentName },
        { name: 'paymentAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.PaymentAmount },
        { name: 'basePaymentAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.BasePaymentAmount },
        { name: 'discountAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.DiscountAmount },
        { name: 'penaltyAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.PenaltyAmount },
        { name: 'interestAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.InterestAmount },
        { name: 'exemptionAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.ExemptionAmount },
        { name: 'dueDate', value: Core.PaymentBatchDetailsEntryPropertyEnum.DueDate },
        { name: 'checkNo', value: Core.PaymentBatchDetailsEntryPropertyEnum.CheckNo },
        { name: 'checkDate', value: Core.PaymentBatchDetailsEntryPropertyEnum.CheckDate },
        { name: 'postDate', value: Core.PaymentBatchDetailsEntryPropertyEnum.PostDate },
        { name: 'transmittalPackageNum', value: Core.PaymentBatchDetailsEntryPropertyEnum.TransmittalPackageNum },
        { name: 'billAmount', value: Core.PaymentBatchDetailsEntryPropertyEnum.BillAmount },
        { name: 'state', value: Core.PaymentBatchDetailsEntryPropertyEnum.State },
        { name: 'companyName', value: Core.PaymentBatchDetailsEntryPropertyEnum.CompanyName },
        { name: 'siteName', value: Core.PaymentBatchDetailsEntryPropertyEnum.SiteName },
        { name: 'paymentType', value: Core.PaymentBatchDetailsEntryPropertyEnum.PaymentType },
        { name: 'taxYear', value: Core.PaymentBatchDetailsEntryPropertyEnum.TaxYear },
        { name: 'assessorName', value: Core.PaymentBatchDetailsEntryPropertyEnum.AssessorName},
        { name: 'sitePayRes', value: Core.PaymentBatchDetailsEntryPropertyEnum.SitePayRes},
        { name: 'sitePaymentResp', value: Core.PaymentBatchDetailsEntryPropertyEnum.SitePaymentResp},
        { name: 'parcelPayRes', value: Core.PaymentBatchDetailsEntryPropertyEnum.ParcelPayRes},
        { name: 'parcelPaymentResp', value: Core.PaymentBatchDetailsEntryPropertyEnum.ParcelPaymentResp}
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.PaymentBatchDetailsSearchModel = this._getSearchModel(startIndex, endIndex);

        return await this._paymentBatchService.getPaymentBatchPaymentsRowIds(searchParams);
    }

    getSearchParamsWithoutPagination() : Core.PaymentBatchDetailsSearchModel{
        this.refreshDataSourceParams();
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            paymentBatchId: this._dataSourceParams.paymentBatchId
        };
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Core.PaymentBatchPaymentTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selection = selectedRowsModel;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const { totals } = await this._paymentBatchService.getPaymentBatchPaymentsList(searchParams);

        return totals;
    }

    protected canGetRows(): boolean {
        return true;
        // return (this._dataSourceParams.instanceId !== null);
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.PaymentBatchDetailsSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            paymentBatchId: this._dataSourceParams.paymentBatchId
        };

        const result = await this._paymentBatchService.getPaymentBatchPaymentsList(searchParams);

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }


    private _getSearchModel(startIndex?: number, endIndex?: number): Core.PaymentBatchDetailsSearchModel {
        return {
            pagination: {
                skip: (startIndex) ? startIndex : 0,
                take: (endIndex) ? endIndex - startIndex + 1 : 0
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            paymentBatchId: this._dataSourceParams.paymentBatchId
        };
    }
}
