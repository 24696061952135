import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportDetail } from '../report.manager.model';
import { Constants } from '../../../constants.new';

@Component({
    selector: 'further-limit-panel-site',
    template: `
        <div class="col-md-4 px-3 float-start">
            <div class="form-inline">
                <input type="radio" [(ngModel)]="report.criteria.yearChoice" value="latest" (change)="yearChoiceChanged()">
                <span>Latest assessed tax year plus </span>

                <select [(ngModel)]="report.criteria.numberOfYearsToInclude" class="form-select-sm" [disabled]="report.criteria.yearChoice === 'years'">
                    <option *ngFor="let selectedYear of numYearsArr" [ngValue]="selectedYear.n" >{{ selectedYear.s }}</option>
                </select>

                <span>prior tax years</span>
            </div>
            <div class="form-inline" style="margin-top: 10px;">
                <input type="radio" [(ngModel)]="report.criteria.yearChoice" value="years" (change)="yearChoiceChanged()">
                <span>Tax years </span>
                <select [(ngModel)]="taxYearBeginUI" (change)="yearChangedValidation()" class="form-select-sm" [disabled]="report.criteria.yearChoice === 'latest'">
                    <option *ngFor="let year of availableYears" [ngValue]="year">{{ year }}</option>
                </select>
                <span> to </span>
                <select [(ngModel)]="report.criteria.taxYearEnd" [disabled]="!taxYearBeginUI || report.criteria.yearChoice === 'latest'" class="form-select-sm report-manager-year-list">
                    <option *ngFor="let year of availableYears" [disabled]="year < taxYearBeginUI" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4 px-3 float-start">
            <div class="form-inline">
                <div class="form-group">
                    <label style="vertical-align: top; margin-top: 5px;">Property Type: </label>
                    <select multiple [(ngModel)]="report.criteria.propertyTypeIDs" class="form-select-sm">
                        <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeId">{{ propType.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-4 px-3 float-start" *ngIf="hasRyanPrivatePermission">
            <div style="display: inline-block; vertical-align: top;">
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeDisclaimer"></ws-switch> Include Disclaimer
                </label>
            </div>
        </div>
    `
})
export class FurtherLimitPanelSiteComponent implements OnInit {
    @Input() report: ReportDetail;
    @Input() availableYears: number[];
    @Input() hasRyanPrivatePermission: boolean;
    taxYearBeginUI: number;
    propertyTypes: any[];

    numYearsArr = [
        { n: 0, s: '0' },
        { n: 1, s: '1' },
        { n: 2, s: '2' },
        { n: 3, s: '3' },
        { n: -1, s: 'All' },
      ];

    constructor(private Constants: Constants) { }

    ngOnInit() {
        const currentYear: number = new Date().getFullYear();
        this.report.criteria.yearChoice = this.report.criteria.yearChoice || 'latest';
        this.report.criteria.numberOfYearsToInclude = this.report.criteria.numberOfYearsToInclude === undefined ? 2 : this.report.criteria.numberOfYearsToInclude;
        this.report.criteria.taxYearBegin = this.report.criteria.taxYearBegin === undefined ? 0 : this.report.criteria.taxYearBegin;
        this.taxYearBeginUI = this.report.criteria.taxYearBegin || currentYear - 2;
        this.report.criteria.taxYearEnd = this.report.criteria.taxYearEnd || currentYear;

        this.propertyTypes = _.map(this.Constants.PropertyTypeNames, (name: string, id: number) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.report.criteria.propertyTypeIDs = this.report.criteria.propertyTypeIDs || [1, 2, 3, 4];
        if ([null, undefined].indexOf(this.report.criteria.includeDisclaimer) !== -1) {
            this.report.criteria.includeDisclaimer = true;
        }
    }

    yearChoiceChanged(): void {
        if(this.report.criteria.yearChoice === 'latest') {
            this.report.criteria.taxYearBegin = 0;
        } else {
            this.report.criteria.taxYearBegin = this.taxYearBeginUI;
        }
    }

    yearChangedValidation(): void {
        this.report.criteria.taxYearBegin = this.taxYearBeginUI;

        if (this.report.criteria.taxYearBegin > this.report.criteria.taxYearEnd) {
            this.report.criteria.taxYearEnd = null;
        }
    }
}
