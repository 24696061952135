import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { AssessorCollectorService } from '../../assessor.collector.service';
import { NavigationService } from '../../../Layout/navigation.service';
import { AddressDetailsModalLaunchService } from '../../../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { ToastrService } from 'ngx-toastr';
import { Assessor, Municipality } from '../../assessor.model';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../../Comments/comments.service';
import { EntityAddress } from '../../../Common/Address/Address-View-Edit/address.model';
import * as _ from 'lodash';
import { EntityTypeIds } from '../../../constants.new';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { Address } from '../../../Common/Models/common.model';

@Component({
    selector: 'assessor-info',
    templateUrl: './assessor.info.component.html',
    styles: [`
        .assessor-info td {
            vertical-align: middle;
        }
    `]
})
export class AssessorInfoComponent implements OnInit {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _restrictService: RestrictService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _navigationService: NavigationService,
        private readonly _addressDetailsModalLaunchService: AddressDetailsModalLaunchService,
        private readonly _toastr: ToastrService,
    ) { }

    @Input() assessor: Assessor;
    @Output() assessorSaved: EventEmitter<Assessor> = new EventEmitter<Assessor>();

    municipalities: Municipality[] = [];
    editMode: boolean = false;
    hasWritePermission: boolean = false;
    allowTaxingDistricts: boolean = false;

    private _originalAssessor: Assessor;

    get attachmentsModel(): AttachmentModalData {
        if (!this.assessor) {
            return null;
        }

        return {
            entityType: 'ASSESSOR',
            entityName: this.assessor.abbr,
            entityData: {
                typeId: Core.EntityTypes.Assessor,
                id: this.assessor.assessorID,
                name: this.assessor.abbr
            },
            readOnly: !this.hasWritePermission
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.assessor) {
            return null;
        }

        return {
            entityID: this.assessor.assessorID,
            entityTypeID: Core.EntityTypes.Assessor,
            description: `Assessor: ${this.assessor.abbr}`,
            canEdit: this.hasWritePermission
        } as CommentModalData;
    }

    get nonDeletedEntityAddresses(): EntityAddress[] {
        return _.reject(this.assessor.entityAddresses, { efAction: 'delete' });
    }

    get defaultAddress(): Address {
        const entityAddress = _.find(this.assessor.entityAddresses, 'isDefault');

        return entityAddress?.address as unknown as Address;
    }

    async ngOnInit(): Promise<void> {
        this.hasWritePermission = this._restrictService.isInRole(Roles.ASSESSOREDIT);
        this.allowTaxingDistricts = this.assessor.stateID === 16;
    }

    async save(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            this.assessor = await this._assessorCollectorService.saveAssessor(this.assessor);
            this.assessorSaved.emit(this.assessor);

            this.editMode = false;
            this._navigationService.disableNavWarning();
        } finally {
            busyRef.hide();
        }
    }

    async edit(): Promise<void> {
        if (!this.municipalities.length) {
            this.municipalities = await this._assessorCollectorService.getAssessorMunicipalities();
        }

        this._originalAssessor = _.cloneDeep(this.assessor);
        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
    }

    cancel(): void {
        this.assessor = this._originalAssessor;
        this.editMode = false;
        this._navigationService.disableNavWarning();
    }

    async launchAddressDetailsModal(): Promise<void> {
        try {
            const result = await this._addressDetailsModalLaunchService.openAddressDetailsModal(
                this.assessor.assessorID,
                EntityTypeIds.ASSESSOR,
                _.cloneDeep(this.assessor.entityAddresses),
                this.editMode,
                false,
                true
            );

            if (this.editMode) {
                this.assessor.entityAddresses = result;
            }
        } catch (err) {
            this._toastr.error(err.error.message);
        }
    }

    updateMunicipality(): void {
        const m = _.find(this.municipalities, { municipalityID: this.assessor.municipalityID });

        if (m) {
            this.assessor.municipality = m;
            this.assessor.municipalityAbbr = m.abbr;
            this.assessor.municipalityName = m.name;
        }
    }
}
