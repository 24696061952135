<ws-modal-wrapper [headerLabel]="modalTitle"
                  (save)="save()"
                  (cancel)="cancel()"
                  [useAceStyles]="true">
    <div class="row">
        <div class="col-md-10">
            <div class="radio" style="display: inline-block;">
                <label>
                    <input type="radio"
                           name="display-type-filter"
                           [(ngModel)]="selectedDisplayType"
                           [value]="displayTypes.FILTER"> Filter
                </label>
            </div>
            <div class="radio" style="display: inline-block; margin-left: 20px;">
                <label>
                    <input type="radio"
                           name="display-type-overview"
                           [(ngModel)]="selectedDisplayType"
                           [value]="displayTypes.OVERVIEW"
                           [disabled]="thatAreCompleted()"> Overview
                </label>
            </div>
            <div class="radio" style="display: inline-block; margin-left: 20px;">
                <label>
                    <input type="radio"
                           name="display-type-detailed"
                           [(ngModel)]="selectedDisplayType"
                           [value]="displayTypes.DETAILED"> Detailed List
                </label>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
