import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { FormYearCopyListComponent } from '../Form-Year-Copy-List/formYearCopyList.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'form-year-copy',
    templateUrl: './formYearCopy.component.html'
})
export class FormYearCopyComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastsManager: ToastrService
    ) { }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async copyTaxYear(): Promise<void> {
        const result = await this._modalService.showAsync(FormYearCopyListComponent, null, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.prompt('Any existing mappings on the target form revision year will be overwritten with the following mappings from this form revision year: Assessors, Schedules, Factor Tables, Asset Class Mappings, Reports. Are you sure you wish to copy the tax year?', 'Confirm');
        } catch (e) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Copying tax year' });

        try {
            await this._formService.copyTo(result);

            this._toastsManager.success('Tax year successfully copied.');
        } finally {
            busyRef.hide();
        }
    }
}
