import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail, SiteStatus } from '../report.manager.model';
import * as _ from 'lodash';
import { Constants } from '../../../constants.new';
import { ReportManagerService } from '../report.manager.service';

@Component({
    selector: 'further-limit-panel-maar',
    template: `
        <div class="ws-flex-container-horizontal ws-stretch-width ws-flex-justify-content-space-between ws-flex-align-items-baseline">
            <div class="form-inline ws-flex-grow">
                <label class="text-nowrap">Tax Year: </label>
                <select [(ngModel)]="report.criteria.taxYear" class="form-select-sm" style="margin-left: 10px;">
                    <option *ngFor="let year of availableYears" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
            <div class="form-inline ws-flex-grow">
                <label class="ws-vertical-align-top text-nowrap" style="margin-top: 5px;">Property Type: </label>
                <select multiple [(ngModel)]="report.criteria.propertyTypeIDs" class="form-select-sm" style="width: auto; margin-left: 10px;">
                    <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeId">{{ propType.name }}</option>
                </select>
            </div>
            <div class="form-inline ws-flex-grow" style="margin-top: 10px;">
                <label class="text-nowrap">Site Status: </label>
                <select class="form-select-sm" [(ngModel)]="report.criteria.siteStatus" style="margin-left: 10px;">
                    <option *ngFor="let status of siteStatuses" [ngValue]="status.id">{{ status.name }}</option>
                </select>
            </div>
        </div>
    `
})
export class FurtherLimitPanelMaarComponent implements OnInit {
    @Input() report: ReportDetail;
    @Input() availableYears: number[];
    propertyTypes: any[];
    siteStatuses: SiteStatus[];

    constructor(private Constants: Constants, private reportManagerService: ReportManagerService) { }

    ngOnInit() {

        this.propertyTypes = _.map(this.Constants.PropertyTypeNames, (name: string, id: string) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.report.criteria.propertyTypeIDs = this.report.criteria.propertyTypeIDs || [1, 2, 3, 4];

		// todo - get all options, this is just for mvp
        this.siteStatuses = this.reportManagerService.getSiteStatusOptionsExceptInactive();

        this.report.criteria.siteStatus = this.report.criteria.siteStatus || this.siteStatuses[0].id;
        this.report.criteria.taxYear = this.report.criteria.taxYear || new Date().getFullYear() + 1;
    }
}
