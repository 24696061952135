import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';

export interface ICellRendererParamsForDropdowns extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: ICellRendererParamsForDropdowns) => boolean;
    isDisabled: (params: ICellRendererParamsForDropdowns) => boolean;
    dropdownItems: any[];
    name: string;
    valueField: string;
    allowNull: boolean;
    originalValue: any;
    displayGetter: (dropdownItem: any) => string;
    change: (params: ICellRendererParamsForDropdowns) => void;
}

@Component({
    selector: 'supplemental-information-cell-renderer',
    template: `<div #outerDiv class="drop-down-on-hover-cell" [ngClass]="isHovering ? 'mouseover' : ''" (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <div class="read-only-label" [ngClass]="{'value-inherited': isInherited(), 'value-override': isOverride(), 'grid-cell-readonly': params.isDisabled(params)}">{{ getDisplayValue() }}</div>
        <div class="edit-control" *ngIf="params.data">
            <select [name]="params.name" [ngModel]="params.data[params.colDef.field]" (ngModelChange)="onModelChange($event)">
                <option [ngValue]="null" *ngIf="params.allowNull"></option>
                <option *ngFor="let d of params.dropdownItems" [ngValue]="d[params.valueField]">{{params.displayGetter(d)}}</option>
            </select>
        </div>
    </div>`
})
export class DropdownCellRenderer implements ICellRendererAngularComp {
    params: ICellRendererParamsForDropdowns;
    editMode: boolean = false;
    isHovering: boolean;
    @ViewChild('outerDiv', { read: ElementRef, static: true }) outerDiv: ElementRef;

    agInit(params: ICellRendererParamsForDropdowns): void {
        this.params = params;
        this.editMode = true;
    }

    ngOnDestroy(): void {
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChange(value: any) {
        this.params.originalValue = this.params.data[this.params.colDef.field];
        this.params.data[this.params.colDef.field] = value;
        this.params.change(this.params);
    }

    mouseover($event) {
        this.isHovering = this.editMode && this.params.canEdit(this.params);
    }

    mouseout($event) {
        if (this.editMode && this.params.canEdit(this.params) && $event.relatedTarget) {
            // protect against clicks on inner children
            let parent = $event.relatedTarget.parentElement;
            while (parent) {
                if (parent === this.outerDiv.nativeElement) {
                    return;
                }
                parent = parent.parentElement;
            }
            this.isHovering = false;
        }
    }

    getDisplayValue(): string {
        if (!this.params.data) {
            return null;
        }
        const dropdownItem = this.params.dropdownItems.find(x => x[this.params.valueField] === this.params.data[this.params.colDef.field]);
        return (dropdownItem && this.params.displayGetter(dropdownItem)) || null;
    }

    isInherited(): boolean {
        if (!this.params.data) {
            return false;
        }
        if (this.params.originalValue && (this.params.originalValue !== this.params.data[this.params.colDef.field])) {
            return false;
        }        
        if (this.params.data[this.params.colDef.field + 'Source']) {
            const sourceType = this.params.data[this.params.colDef.field + 'Source'];
            if (sourceType === Compliance.SupplementalInformationValueSource.ParentCompanyDefault || 
                (sourceType !== Compliance.SupplementalInformationValueSource.CompanyStateSpecific && 
                this.params.data['state'] !== 'Default')) {
                return true;
            }
        }
        return false;
    }

    isOverride(): boolean {
        if (this.params.data && !this.params.data.stateId) {
            return false;
        }
        if (this.params.originalValue && (this.params.originalValue !== this.params.data[this.params.colDef.field])) {
            return true;
        } else {
            return (!this.isInherited());
        }
    }
}
