import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AceAuthOidcWrapper } from '../IAM/aceAuthOidcWrapper.component';

@NgModule({
    declarations: [
        AceAuthOidcWrapper
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        AceAuthOidcWrapper
    ]
})
export class WebComponentWrapperModule {
}