(function () {
    "use strict";
    /* The upgrade from AngularJS 1.5 to 1.6 changed how controller functions are
     * bound. Since the majority of Weissman was written in Angular 1.4, our
     * conventions are somewhat out of date. The weissNg1Wrap functions are shims
     * that allowed a minimally-invasive fix to allow the upgrade to continue, but
     * it requires a bit of boilerplate. Generally, it is expected that collector
     * declarations will work one of two ways. The first approach looks like this: 

    angular.module('weissmanApp')
        .controller('myController', ['dep1', function (dep1) {
            var vm = this;
        }
    ]);

     * And the second approach looks like this:
     
	angular.module('weissmanApp')
		.controller('myController', Controller);

    Controller.$inject = ['dep1'];

    function Controller(dep1) {
        var vm = this;
    }

     * In both cases, our application code generally expects bindings to happen
     * before the controller function is called, and that no longer happens in
     * AngularJS 1.6+. The weissNg1Wrap function exists to correct the first form of
     * controller declaration above and the weissNg1WrapFunc funtion exists for the
     * second form. The above first example would now look like:

    angular.module('weissmanApp')
        .controller('myController', ['dep1', weissNg1Wrap(function (dep1) {
            var vm = this;
        })
    ]);

     * And the second example would look like:

	angular.module('weissmanApp')
		.controller('myController', Controller);

    Controller.$inject = ['dep1'];

    function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(dep1) {
        var vm = this;
    }

     * Note that ideally, in the future we'll write controllers following the newer
     * AngularJS conventions instead of leaning on these shims; this was just to
     * allow the upgrade to continue without a massive refactor.
     */
    window.weissNg1Wrap = function (controller) {
        return function () {
            var args = arguments;
            this.$onInit = function () {
                controller.apply(this, args);
            };
        };
    };

    window.weissNg1WrapFunc = function (init, args, _this) {
        _this.$onInit = function () {
            init.apply(this, args);
        };
    };
}());
