<div class="container">
    <div class="single-column-layout">
        <h1>Manage Consulting Engagements</h1>
        <div class="text-center" style="margin-top: 50px; text-align: center;" *ngIf="isLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div class="text-center" style="margin-top: 50px; text-align: center;" *ngIf="engagements.length === 0 && !isLoading">
            There are currently no consulting engagements
        </div>
        <table class="table table-hover" *ngIf="!isLoading && engagements.length > 0">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let engagement of engagements">
                    <td>
                        {{engagement.name}}
                    </td>
                    <td width="200px">
                        <span>
                            <button *ngIf="engagement.accepted === false" (click)='openEngagementModal(engagement)' class="btn small-button flat-button primary-button">Accept</button>
                        </span>
                        <span>
                            <button *ngIf="engagement.accepted === false" (click)='rejectInvitation(engagement)' class="btn small-button flat-button primary-button">Reject Invite</button>
                            <button *ngIf="engagement.accepted" (click)='cancelEngagement(engagement)' class="btn small-button flat-button primary-button">Cancel Engagement</button>
                            <button *ngIf="engagement.accepted" (click)='openEngagementModal(engagement)' class="btn small-button flat-button primary-button">Edit</button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>  
