import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentModalData } from 'src/app/Attachment/attachment.modal.model';
import { CommentModalData } from 'src/app/Comments/comments.service';

export interface ICellRendererParamsForPaymentBatchPaymentsGridAction extends ICellRendererParams {
    canEdit: (params: ICellRendererParamsForPaymentBatchPaymentsGridAction) => boolean;
    edit: (params: ICellRendererParamsForPaymentBatchPaymentsGridAction) => void;
    delete: (params: ICellRendererParamsForPaymentBatchPaymentsGridAction) => void;
    canDelete: (params: ICellRendererParamsForPaymentBatchPaymentsGridAction) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <ng-container *ngIf="!params.data?.selectedTotalsRow">
        <span class="icon-indicator" *ngIf="row && row.hasComments">
            <i class="fa fa-commenting fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="row && !row.hasComments">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="row && row.hasAttachments">
            <i class="fa fa-files-o fa-fw"></i>
        </span>
        <entity-comments *ngIf="row"
            [(hasComments)]="row && row.hasComments"
            buttonClass="flat-button primary-button icon-button grid-action-button"
            [modalData]="commentModalData"
            helpTooltip
            helpContentId="app.comments"
            position="bottom">
        </entity-comments>
        <entity-attachments *ngIf="row" [(hasAttachments)]="row && row.hasAttachments"
            buttonClass="flat-button primary-button icon-button grid-action-button"
            [modalData]="attachmentModalData"
            helpTooltip
            helpContentId="app.attachments"
            position="bottom">
        </entity-attachments>
        <button type="button"
            *ngIf="row && params.canDelete(params)"
            class="btn flat-button icon-button warning-button grid-action-button"
            (click)="onDeleteClicked()"
            helpTooltip
            helpContentId="app.delete"
            position="bottom">
            <i class="fa fa-trash"></i>
        </button>
    </ng-container>
`
})
export class PaymentBatchPaymentsGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForPaymentBatchPaymentsGridAction;
    row: Core.PaymentBatchDetailsEntryModel;
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;

    agInit(params: ICellRendererParamsForPaymentBatchPaymentsGridAction): void {
        this.params = params;
        this.row = params.data as Core.PaymentBatchDetailsEntryModel;

        if (!this.row || params.data?.selectedTotalsRow) {
            return;
        }
        this.attachmentModalData = {
            entityType: 'Tax Bill',
            parentId: this.row.parcelId,
            parentType: 'Parcel',
            entityName: this.row.parcelAcctNum,
            entityDescription: `${this.row.taxYear} - ${this.row.collector} Bill`,
            year: this.row.taxYear,
            belowParcelEntity: {
                id: this.row.billId,
                typeId: Core.EntityTypes.Bill,
                name: 'Bill'
            },
            readOnly: !this.params.canEdit(params)
        } as AttachmentModalData;

        this.commentModalData = {
            entityID: this.row.billId,
            entityTypeID: Core.EntityTypes.Bill,
            disableCommentTypeSelection: true,
            parcelAcctNum: this.row.parcelAcctNum,
            canEdit: this.params.canEdit(params),
            parcelID: this.row.parcelId,
            description: `${this.row.taxYear} - ${this.row.collector} Bill`,
            year: this.row.taxYear.toString()
        } as CommentModalData;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }


    // descoped for now
    // onEditClicked(): void {
    //     this.params.edit(this.params);
    // }

    onDeleteClicked(): void {
        this.params.delete(this.params);
    }
}
