import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GeneralReturnSettingLookupEnum, ReturnSettingsEntityTypeEnum, ReturnSettingsLookupType, ReturnSettingsSettingTypeEnum, ReturnSettingsViewModelEnum } from '../../../Models/enums';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'return-settings-name-attribute',
    templateUrl: './returnSettingsNameAttribute.component.html'
})
export class ReturnSettingsNameAttributeComponent implements OnInit, OnChanges{

    constructor(private readonly _toastsManager: ToastrService) {
    }

    @Input() lookupOptions: Compliance.GeneralReturnSettingLookupModel[];
    @Input() isTopLevelCompany: boolean;
    @Input() currentLookupId: number;
    @Input() parentLookupId: number;
    @Input() isReadonly: boolean;
    @Input() viewMode: ReturnSettingsViewModelEnum;
    @Input() currentValue: string;
    @Input() parentValue: string;
    @Input() currentSettings: Compliance.GeneralReturnSettingModel;
    @Input() parentSettings: Compliance.GeneralReturnSettingModel;
    @Input() lookupType: ReturnSettingsLookupType;
    @Input() settingType: ReturnSettingsSettingTypeEnum;
    @Input() entityType: ReturnSettingsEntityTypeEnum;
    @Input() contactName: string;
    @Input() optionSettingNameId?: GeneralReturnSettingLookupEnum;
    @Input() optionBlankId?: GeneralReturnSettingLookupEnum;

    @Output() changed: EventEmitter<number> = new EventEmitter<number>();

    ViewModelEnum = ReturnSettingsViewModelEnum;
    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    options: Compliance.GeneralReturnSettingLookupModel[];

    ngOnInit(): void {
        this.options = this.lookupOptions.map(i => ({... i}));
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    private _hasChanges: boolean;

    get lookupHasOverride(): boolean{
        return !this.isTopLevelCompany && this.currentLookupId && this.currentLookupId !== this.parentLookupId;
    }

    get lookupId(): string {
        const result = (this.isTopLevelCompany || this.lookupHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return) ? this.currentLookupId : this.parentLookupId;
        return (result || 0).toLocaleString();
    }

    set lookupId(value: string) {
        if (+value === this.parentLookupId && this.entityType !== ReturnSettingsEntityTypeEnum.Return && !this.isTopLevelCompany){
            value = null;
        }

        this._hasChanges = true;
        this.currentLookupId = value ? +value : null;

        this.changed.emit(this.currentLookupId);
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    get lookupValue(): string{
        return (this.isTopLevelCompany || this.lookupHasOverride || this.parentLookupId === this.optionSettingNameId) ? this.currentValue : this.parentValue;
    }

    selectionChanged(selectedValue: number) {
        if (+selectedValue === Compliance.GeneralReturnSettingLookupEnum.SiteSiteCharacteristicDBA) {
            this._toastsManager.success('Site will be used when DBA is not available');
        }
    }
}
