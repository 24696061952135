import { Injectable } from '@angular/core';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { KeyTransmittalPublicData } from './external-api.models';

@Injectable(
    { providedIn: 'root' }
)
export class ExternalAPIService {
    constructor(private http: SDHttpService) { }

    getKeyTransmittalPublicData(requestCode: string): Promise<KeyTransmittalPublicData> {
        return this.http.get(`/api/ExternalAPIConfig/KeyTransmittalRequest/${  requestCode  }/PublicData`, {
            handleCode: 404
        });
    }

    getPrivateKey(requestCode: string): Promise<string> {
        return this.http.get(`/api/ExternalApiConfig/KeyTransmittalRequest/${  requestCode  }/PrivateKey`, {
            handleCode: 404
        });
    }
}
