import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { EntityType } from '../../constants.new';
import { TaxRateService } from '../../Tax-Rate/tax.rate.service';
import { TaxAuthority, TaxRateArea } from './tax.rates.panel.model';
import { TaxAuthorityPayload, TaxRateAreaPayload } from '../../Tax-Rate/tax.rate.model';

export interface AddTaxEntityModalParams {
    assessorId?: number;
    collectorId: number;
    entityTypeId: number;
    defaultAnnualRateIncrease?: number;
}

@Component({
    selector: 'add-tax-entity-modal',
    template: `
        <ws-modal-wrapper [headerLabel]="modalTitle"
                          acceptLabel="Add"
                          [disableSave]="!taxEntity.name"
                          (cancel)="closeModal()"
                          (save)="add()">
            <loading-spinner [show]="loading" [size]="4"></loading-spinner>
            <div *ngIf="!loading" class="d-flex align-items-center">
                <label class="mb-0 me-2">Name:</label>
                <input #name type="text" class="form-control" maxlength="100" placeholder="Name"
                       [(ngModel)]="taxEntity.name" (keyup.enter)="add()">
            </div>
        </ws-modal-wrapper>
    `
})
export class AddTaxEntityModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<AddTaxEntityModalParams, number> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _taxRateService: TaxRateService) {
    }

    @ViewChild('name') name: ElementRef;

    params: AddTaxEntityModalParams;
    result: number;

    taxEntity: TaxRateArea | TaxAuthority;
    modalTitle: string;
    loading: boolean = false;

    ngOnInit(): void {
        if (this.params.entityTypeId == EntityType.TaxAuthority) {
            this.modalTitle = 'Add New Tax Authority';
            this.taxEntity = new TaxAuthority();
            this.taxEntity.defaultAnnualRateIncrease = this.params.defaultAnnualRateIncrease;
        } else {
            this.modalTitle = 'Add New Tax Rate Area';
            this.taxEntity = new TaxRateArea();
            this.taxEntity.assessorId = this.params.assessorId;
        }

        this.taxEntity.collectorId = this.params.collectorId;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.name.nativeElement.focus();
        });
    }

    closeModal(): void {
        console.log('closeModal');
        this._bsModalRef.hide();
    }

    async add(): Promise<void> {
        if (this.loading) {
            return;
        }

        let id: number;
        this.loading = true;

        try {
            if (this.params.entityTypeId == EntityType.TaxAuthority) {
                this.taxEntity = await this._taxRateService.createTaxAuthority(this.taxEntity as TaxAuthorityPayload);
                id = this.taxEntity.taxAuthorityId;
            } else {
                this.taxEntity = await this._taxRateService.createTaxRateArea(this.taxEntity as TaxRateAreaPayload);
                id = this.taxEntity.taxRateAreaId;
            }

            this.result = id;
            this.closeModal();
        } finally {
            this.loading = false;
        }
    }
}
