import { HelpContent } from '../../../../UI-Lib/Help-Tooltip';

export const FILING_DETAILS_HELP: HelpContent[] = [
    {
        helpContentId: 'filing-details.prior-return',
        tooltipText: 'The prior return is used to calculate additions, transfers and disposals.  It is also used to determine which forms and reports to use as well as copying asset schedule mappings and form overrides.',
        hasIcon: true
    },
    {
        helpContentId: 'filing-details.amendment',
        tooltipText: 'When checked, change detection logic will use asset\'s change status analysis against prior return\'s prior return.',
        hasIcon: true
    }
];
