<div>
    <div *ngIf="currentMode === loginPageModesEnum.login">
        <loading-spinner [show]="!showLogin" [size]="5" [hasOverlay]="true" [darkOverlay]="false"></loading-spinner>
        <div *ngIf="showLogin" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
            <h2>Login</h2>
            <div class="login-panel">
                <div class="alert alert-danger" *ngIf="error">{{ error }}</div>
                <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>
                <form name="form" role="form">
                    <div class="mb-4">
                        <label for="username">Username</label>
                        <input type="text"
                               name="username"
                               id="username"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': userName.dirty && userName.errors?.required }"
                               [formControl]="userName"
                               required/>
                        <span *ngIf="userName.dirty && userName.errors?.required" class="help-block">
                            Username is required
                        </span>
                    </div>
                    <div class="mb-4">
                        <label for="password">Password</label>
                        <input type="password"
                               name="password"
                               id="password"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': password.dirty && password.errors?.required }"
                               [formControl]="password"
                               required
                               autocomplete="off"/>
                        <span *ngIf="password.dirty && password.errors?.required" class="help-block">
                            Password is required
                        </span>
                    </div>

                    <div class="mb-4">
                        <span class="anchor-style"
                              (click)="currentMode = loginPageModesEnum.getToken; error = '';">
                            Forgot password?
                        </span>
                    </div>

                    <div class="mb-4" *ngIf="isSSOEnabled">
						<span class="anchor-style" (click)="switchToSSOMode()">
							Login using SSO
						</span>
                    </div>

                    <div class="form-actions float-end mt-2">
                        <button type="button"
                                class="btn flat-button primary-button"
                                [disabled]="password.invalid || userName.invalid"
                                (click)="login()">
                            Login
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </div>

    <div *ngIf="currentMode === loginPageModesEnum.getToken" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
        <h2>Password recovery</h2>

        <div class="login-panel">
            <div class="alert alert-danger " *ngIf="error">{{ error }}</div>
            <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>

            <div *ngIf="passwordResetEmailHasBeenSentMessage">
                <div class="mb-4 help-block">
                    <strong><h3>{{ passwordResetEmailHasBeenSentMessage }}</h3></strong>
                </div>
                <div class="mb-4" style="margin-top:30px;">
                    <button type="button"
                            (click)='currentMode = loginPageModesEnum.login; error = ""; '
                            class="btn flat-button primary-button">
                        Go back to login page
                    </button>
                </div>
            </div>

            <loading-spinner [show]="showSpinner" [size]="5"></loading-spinner>

            <form name="form-password-recovery-get-url" role="form"
                  *ngIf="!(showSpinner || passwordResetEmailHasBeenSentMessage)">
                <div class="mb-4">
                    <label>Username or email</label>
                    <input type="text" name="usernameOrEmail" id="usernameOrEmail" class="form-control"
                           [formControl]="usernameOrEmail" required/>
                </div>

                <div class="form-actions float-start" style="margin-top:10px;">
                    <button type="button" (click)='currentMode = loginPageModesEnum.login'
                            class="btn flat-button primary-button">Cancel
                    </button>
                </div>


                <div class="form-actions float-end" style="margin-top:10px;">
                    <button type="submit"
                            class="btn flat-button primary-button"
                            (click)="sendPasswordResetToken()"
                            [disabled]="usernameOrEmail.invalid">
                        Get password recovery URL
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>

    <div *ngIf="currentMode === loginPageModesEnum.useToken" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
        <h2>Password reset</h2>
        <div class="login-panel">
            <div class="alert alert-danger" *ngIf="error">{{ error }}</div>
            <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>

            <div class="mb-4" *ngIf="showGetNewTokenButton">
                <button type="button"
                        (click)='currentMode = loginPageModesEnum.getToken; error = ""; '
                        class="btn flat-button primary-button">Get a new link
                </button>
            </div>

            <loading-spinner [show]="showSpinner" [size]="5"></loading-spinner>
            <form name="passwordResetForm"
                  class="form-horizontal"
                  role="form"
                  *ngIf="!(showSpinner || showGetNewTokenButton)">

                <new-password [showCurrent]="false"
                              [(passwords)]="passwordObj"
                              [(passwordsValid)]="passwordsValid"
                              [alreadyUsed]="alreadyUsed">
                </new-password>

                <div class="form-actions float-start" style="margin-top:10px;">
                    <button type="button"
                            (click)="goToLogin()"
                            class="btn flat-button primary-button">
                        Cancel
                    </button>
                </div>

                <div class="form-actions float-end mt-2">
                    <button type="submit"
                            class="btn flat-button primary-button"
                            (click)="resetPassword()"
                            [disabled]="!passwordsValid">Reset Password
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>

    <div *ngIf="currentMode === loginPageModesEnum.selectSSODomain" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
        <h2>Login Using SSO</h2>

        <div class="login-panel">
            <div class="alert alert-danger " *ngIf="error">{{ error }}</div>
            <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>

            <loading-spinner [show]="showSpinner" [size]="5"></loading-spinner>

            <form name="form-select-sso-domain" role="form" *ngIf="!showSpinner">
                <div class="mb-4">
                    <label>Select your login domain</label>
                    <input type="text"
                           name="loginDomain"
                           id="loginDomain"
                           class="form-control"
                           spellcheck="false"
                           [formControl]="loginDomain"
                           required/>
                </div>

                <div class="form-actions mt-2">
                    <label>
                        <input type="checkbox"
                               style="vertical-align:middle"
                               [(ngModel)]="rememberSSODomain"
                               (change)="setRememberSSODomain()"/>
                        <span style="font-weight:normal;vertical-align:middle;margin-left:5px;margin-top:4px;display:inline-block;">
							Remember my selection
						</span>
                    </label>
                </div>

                <div>
                    <span>For example, if your email address is john.doe@company.com, company.com is the domain</span>
                </div>

                <div class="form-actions float-start mt-2">
                    <button type="button"
                            (click)='cancelSelectSSODomain()'
                            class="btn flat-button primary-button">
                        Cancel
                    </button>
                </div>

                <div class="form-actions float-end mt-2">
                    <button class="btn flat-button primary-button"
                            (click)="initializeSSOLogin()"
                            [disabled]="loginDomain.invalid">
                        Login
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>
    <div *ngIf="currentMode === loginPageModesEnum.awaitingSSO" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
        <h2>Login Using SSO</h2>
        <div class="login-panel">
            <form name="form-awaiting-sso" role="form">
                <div>
                    <span>Login with your company's provider using the pop-up</span>
                    <button type="button"
                            class="btn flat-button small-button primary-button"
                            (click)="currentMode = loginPageModesEnum.selectSSODomain">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>

    <div *ngIf="currentMode === loginPageModesEnum.homeRealmDiscovery"
         class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
        <h2>Login To PropertyPoint</h2>

        <div class="login-panel">
            <div class="alert alert-danger " *ngIf="error">{{ error }}</div>
            <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>

            <loading-spinner [show]="showSpinner" [size]="5"></loading-spinner>

            <form name="form-home-realm-discovery" role="form"
                  *ngIf="!showSpinner">
                <div class="mb-4">
                    <label>Email Address</label>
                    <input type="text"
                           name="username"
                           id="username"
                           class="form-control"
                           spellcheck="false"
                           [formControl]="userName"
                           required/>
                </div>

                <label>
                    <input type="checkbox" class="ws-vertical-align-middle" [(ngModel)]="rememberSSOEmail"/>
                    <span class="d-inline-block ws-vertical-align-middle font-weight-normal">
                        Remember my email
                    </span>
                </label>

                <div class="form-actions float-end mt-2">
                    <button type="submit"
                            class="btn flat-button primary-button"
                            [disabled]="userName.invalid"
                            (click)="submitHomeRealmDiscovery()">
                        Login
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>

    <div *ngIf="currentMode === loginPageModesEnum.migratePassword">
        <loading-spinner [show]="!showLogin" [size]="5" [hasOverlay]="true" [darkOverlay]="false"></loading-spinner>
        <div *ngIf="showLogin" class="col-md-6 offset-sm-3 col-xl-4 offset-lg-4">
            <h2>Login To PropertyPoint</h2>

            <div class="login-panel">
                <div class="alert alert-danger " *ngIf="error">{{ error }}</div>
                <div class="alert alert-warning" *ngIf="warning">{{ warning }}</div>

                <!-- <sd-loading-spinner ng-show="showSpinner"></sd-loading-spinner> -->

                <form *ngIf="!showSpinner" name="form-login-migrate" role="form">
                    <div class="mb-4">
                        <label for="password">Password</label>
                        <input type="password"
                               name="password"
                               id="password"
                               class="form-control"
                               [ngClass]="{ 'is-invalid': password.dirty && password.errors?.required }"
                               [formControl]="password"
                               required
                               autocomplete="off"/>
                        <span class="help-block" *ngIf="password.dirty && password.errors?.required">Password is required</span>
                    </div>

                    <div class="form-actions float-start mt-2">
                        <button type="button"
                                (click)="currentMode = loginPageModesEnum.homeRealmDiscovery"
                                class="btn flat-button primary-button">
                            Cancel
                        </button>
                    </div>
                    <div class="form-actions float-end mt-2">
                        <button type="submit"
                                class="btn flat-button primary-button"
                                (click)="login()"
                                [disabled]="password.dirty && password.errors?.required">
                            Login
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </form>
            </div>
        </div>
    </div>
</div>
