<loading-spinner [show]="isLoading" [hasOverlay]="true" [size]="5"></loading-spinner>
<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Client Services Command Center" *ngIf="!isEmbededMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Client Services Command Center
            </h4>
            <div class="ws-section__header__actions__item">
                <button type="button" class="flat-button small-button primary-button" (click)="refresh()" [disabled]="refreshingGrid" title="Refresh"><i class="fa fa-refresh"></i></button>
                <button type="button" class="flat-button small-button secondary-button" *ngIf="!isMaximized" (click)="toggleMaximize(true)">
                    <i class="fa fa-arrows-alt" title="Expand"></i>
                </button>
                <button type="button" class="flat-button small-button secondary-button" *ngIf="isMaximized" (click)="toggleMaximize(false)">
                    <i class="fa fa-compress" title="Collapse"></i>
                </button>
            </div>
        </div>
        <div class="ws-section__filters" [class.remove-panel]="isMaximized">
            <div style="float:right">
                <span style="margin-right: 10px; font-weight: bold;">
                    Exceptions only:
                </span>
                <ws-switch
                    [(ngModel)]="exceptionsOnly">
                </ws-switch>
            </div>
        </div>
    </div>
    <div class="row" [class.remove-panel]="isMaximized">
        <div class="col-lg-12">
            <report-manager-select-entities
                    [report]="report"
                    [hideTopLevelCompanyPicker] = "true"
                    [entitySelection]="entitySelection"
                    (entitySelectionChanged)="debouncedEntitySelectionChanged($event)"
                    (excludeInactiveChanged)="debouncedEntitySelectionChanged($event)"
                    [dataLoaded] = "!refreshingGrid"
                    >
                  </report-manager-select-entities>
             </div>

     </div>
    <div class="ws-flex-auto">
        Responsibilities
        <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        *ngIf="showGrid"
                        (gridReady)="onAgGridReady($event)"
                        [companyId]="entityTree.subsidiaryCompany.entityId"
                        [gridId]="gridId"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        [isBulkDeleteVisible$]="isRemoveExceptionsVisible$"
                        [exportOptions]="exportOptions"
                        [defaultSizeToFit]="false"
                        (bulkUpdate)="bulkUpdate()"
                        (bulkDelete)="removeExceptions()">
    </ws-ag-grid-angular>
</div>
