import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';

export class AssetSummary {
    constructor(
        model: Compliance.AssetModel,
        displayPipe: WeissmanKeyValueDisplayPipe, idx: number) {
        this._displayPipe = displayPipe;
        this._setModel(model, idx);
    }

    private _model: Compliance.AssetModel;
    private _overrides: Compliance.ReportingAssetOverrideModel;
    private readonly _displayPipe: WeissmanKeyValueDisplayPipe;
    private _rowIndex: number;
    private _transientReportedCost: number = 0;
    private _isLocked: boolean = false;

    private _setModel(model: Compliance.AssetModel, idx: number): void {
        this._model = model;
        this._overrides = (model && model.reportingAssetOverride) || {
            reportingAssetId: (model && model.reportingAsset && model.reportingAsset.reportingAssetId) || 0,
            reportingAssetOverrideId: (model && model.reportingAssetOverride && model.reportingAssetOverride.reportingAssetOverrideId) || 0,
            rowVersion: (model && model.reportingAssetOverride && model.reportingAssetOverride.rowVersion) || null,
            isGLAccountIdBlank: false
        };
        this._isLocked = model.reportingAsset.isLocked;
        this._rowIndex = idx;
    }

    get isPrimary(): boolean {
        return !(this._model.reportingAsset && this._model.reportingAsset && !this._model.reportingAsset.isPrimary);
    }

    get alternativeCostDescriptorId(): number {
        return this._model.alternativeCostDescriptorId;
    }

    // asset number
    get sourceAssetNumber(): string {
        return this._model && this._model.assetNumber;
    }

    get reportedAssetNumber(): string {
        return this.reportedAssetNumberHasOverride ? this._overrides.assetNumber : this.sourceAssetNumber;
    }

    set reportedAssetNumber(value: string) {
        this._overrides.assetNumber = value;
    }

    get reportedAssetNumberHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.assetNumber) === -1;
    }

    get reportedAssetNumberHasOverrideWithBlank(): boolean {
        return this.reportedAssetNumberHasOverride && this.reportedAssetNumber.trim() === '';
    }

    get reportedAssetId(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.reportingAssetId;
    }

    // rolled up field that takes into account both alternative costs and cost adjustments
    get cost(): number {
        return this._model ? this._model.cost : null;
    }

    get acqDate(): Date {
        return this._model ? this._model.acqDate : null;
    }

    // acquisition date
    get sourceAcqDate(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.acqDate;
    }

    get reportedAcqDate(): Date {
        return this.reportedAcqDateHasOverride ? this._overrides.acqDate : this.sourceAcqDate;
    }

    set reportedAcqDate(value: Date) {
        this._overrides.acqDate = value;
    }

    get reportedAcqDateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.acqDate) === -1;
    }

    // description
    get sourceDescription(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.description;
    }

    get reportedDescription(): string {
        return this._overrides.description === '' ? this._overrides.description : (this._overrides.description || this.sourceDescription);
    }

    set reportedDescription(value: string) {
        this._overrides.description = value;
    }

    get reportedDescriptionHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.description) === -1;
    }

    get reportedDescriptionHasOverrideWithBlank(): boolean {
        return this.reportedDescriptionHasOverride && this.reportedDescription.trim() === '';
    }

    // company code
    get companyId(): number {
        return this._model && this._model.companyId;
    }

    // company code
    get companyCode(): string {
        return this._model && this._model.companyCode;
    }

    // company name
    get companyName(): string {
        return this._model && this._model.companyName;
    }

    // company calculated
    get isCompanyCalculated(): boolean {
        return this._model && this._model.sourceAsset && this._model.companyName !== this._model.sourceAsset.sourceCompanyName;
        //return true;
    }

    // asset verification reason
    get assetVerificationReason(): string {
        return this._model && this._model.assetVerificationReason;
    }

    // asset ownership type
    get assetOwnershipType(): string {
        return this._model && this._model.assetOwnershipType;
    }

    // asset ownership type id
    get assetOwnershipTypeId(): number {
        return this._model && this._model.assetOwnershipTypeId;
    }

    set assetOwnershipTypeId(value: number) {
        this._model.assetOwnershipTypeId = value;
    }

    // is leasing, in either a from or to capacity
    get isLeasedAsset(): boolean {
        return this.isLeasedFromOther || this.isLeasedToOther;
    }

    // asset is leased to another entity
    get isLeasedToOther(): boolean {
        if (this._model && this._model.assetOwnershipTypeId == 1) {
            return true;
        } else {
            return false;
        }
    }

    // asset is leased from another entity
    get isLeasedFromOther(): boolean {
        if (this._model && this._model.assetOwnershipTypeId == -1) {
            return true;
        } else {
            return false;
        }
    }

    // assessor name
    get assessorName(): string {
        return this._model && this._model.reportingAsset && ((this._model.reportingAsset.assessorName || ' ') + ' ' + (this._model.reportingAsset.municipalityName || ' '));
    }

    // state
    get state(): string {
        return this._model && this._model.reportingAsset && (this._model.reportingAsset.parcelState ? this._model.reportingAsset.parcelState : this._model.reportingAsset.siteState)
    }

    // source state
    get sourceState(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.state;
    }

    // cost
    get sourceCost(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.cost;
    }

    get sourceAdjustedCost(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.sourceAdjustedCost;
    }

    get reportedCost(): number {
        return this.reportedCostHasOverride ? this._overrides.cost : this.sourceCost;
    }

    set reportedCost(value: number) {
        this._overrides.cost = value;
    }

    get reportedCostHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.cost) === -1;
    }

    reportedColumnHasOverride(columnName: string): boolean {
        return [null, undefined].indexOf(this._overrides[ columnName.toLowerCase() ]) === -1;
    }

    get reportedCostHasOverrideWithBlank(): boolean {
        return this.reportedCostHasOverride && isNaN(this.reportedCost);
    }

    get sourcePriorCost(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.priorCost;
    }

    get priorCost(): number {
        return this.priorCostHasOverride ? this._overrides.priorCost : this.sourcePriorCost;
    }

    get priorCostHasOverride(): boolean {
        return this._overrides && this._model.sourceAsset && this._overrides.priorCost !== this._model.sourceAsset.priorCost;
    }

    get sourceCostChange(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.costChange;
    }

    get costChange(): number {
        return this.costChangeHasOverride ? this._overrides.costChange : this.sourceCostChange;
    }

    get costChangeHasOverride(): boolean {
        return this._overrides && this._model.sourceAsset && this._overrides.costChange !== this._model.sourceAsset.costChange;
    }

    // gl account
    get sourceGlAccount(): Compliance.GLAccountModel {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.glAccount;
    }

    get sourceGlAccountDisplay(): string {
        return this.sourceGlAccount ? this._displayPipe.transform(this.sourceGlAccount, this.sourceGlAccount.accountNumber, this.sourceGlAccount.accountName) : '';
    }

    get sourceGlAccountNumber(): string {
        return this.sourceGlAccount ? this.sourceGlAccount.accountNumber : '';
    }

    get sourceGlAccountClassificationId(): number {
        return this.sourceGlAccount && this.sourceGlAccount.assetClassificationId
    }

    get reportedGlAccount(): Compliance.GLAccountModel {
        return this.reportedGlAccountHasOverride ? this._overrides.glAccount : this.sourceGlAccount;
    }

    set reportedGlAccount(value: Compliance.GLAccountModel) {
        this._overrides.glAccount = value;
    }

    set isReportedGlAccountBlank(value: boolean) {
        this._overrides.isGLAccountIdBlank = value;
    }

    get reportedGlAccountDisplay(): string {
        return this.reportedGlAccount ? this._displayPipe.transform(this.reportedGlAccount, this.reportedGlAccount.accountNumber, this.reportedGlAccount.accountName) : '';
    }

    get reportedGlAccountNumber(): string {
        return this.reportedGlAccount ? this.reportedGlAccount.accountNumber : '';
    }

    get reportedGlAccountHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.glAccount) === -1 || (this.sourceGlAccount && this._overrides.isGLAccountIdBlank);
    }

    get reportedGlAccountClassificationId(): number {
        return this.reportedGlAccount && this.reportedGlAccount.assetClassificationId
    }

    // site
    get sourceSite(): Core.SiteModel {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.site;
    }

    get sourceSiteDisplay(): string {
        return this.sourceSite ? this._displayPipe.transform(this.sourceSite, this.sourceSite.property, this.sourceSite.name) : '';
    }

    get sourceSiteName(): string {
        return this.sourceSite ? this.sourceSite.name : '';
    }

    get sourceSiteNumber(): string {
        return this.sourceSite ? this.sourceSite.property : '';
    }

    get reportedSite(): Core.SiteModel {
        return this.reportedSiteHasOverride ? this._overrides.site : this.sourceSite;
    }

    set reportedSite(value: Core.SiteModel) {
        this._overrides.site = value;
    }

    get reportedSiteId(): number {
        return this.reportedSite && this.reportedSite.siteID;
    }

    get reportedSiteDisplay(): string {
        return this.reportedSite ? this._displayPipe.transform(this.reportedSite, this.reportedSite.property, this.reportedSite.name) : '';
    }

    get reportedSiteName(): string {
        return this.reportedSite ? this.reportedSite.name : '';
    }

    get reportedSiteNumber(): string {
        return this.reportedSite ? this.reportedSite.property : '';
    }

    get reportedSiteHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.site) === -1;
    }

    get sourceSiteReportingParcelId(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.sourceSiteReportingParcelId;
    }

    get reportedParcelId(): number {
        return this._model.reportingAsset.assetReportingParcelId ? this._model.reportingAsset.assetReportingParcelId : this._model.reportingAsset.siteReportingParcelId;
    }

    set reportedParcelId(value: number) {
        this._model.reportingAsset.assetReportingParcelId = value;
    }

    get reportedParcelName(): string {
        return this._model.reportingAsset.assetReportingParcelAcctNum ? this._model.reportingAsset.assetReportingParcelAcctNum : this.siteReportingParcelName;
    }

    set reportedParcelName(value: string) {
        this._model.reportingAsset.assetReportingParcelAcctNum = value;
    }

    get reportedParcelHasOverride(): boolean {
        return this._model.reportingAsset.assetReportingParcelId && (this._model.reportingAsset.assetReportingParcelId !== this._model.reportingAsset.siteReportingParcelId);
    }

    // parcel
    get siteReportedParcelId(): number {
        return this._model.reportingAsset.siteReportingParcelId;
    }

    get parcelDisplay(): string {
        return this._model.reportingAsset.assetReportingParcelAcctNum ? this._model.reportingAsset.assetReportingParcelAcctNum : this.siteReportingParcelName;
    }

    get parcelHasOverride(): boolean {
        return this._model && this._model.reportingAsset && [null, undefined].indexOf(this._model.reportingAsset.assetReportingParcelId) === -1;
    }

    // site parcel
    get siteReportingParcelName(): string {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.siteReportingParcelAcctNum;
    }

    // disposed date
    get sourceDisposedDate(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.disposedDate;
    }

    get reportedDisposedDate(): Date {
        return this.reportedDisposedDateHasOverride ? this._overrides.disposedDate : this.sourceDisposedDate;
    }

    set reportedDisposedDate(value: Date) {
        this._overrides.disposedDate = value;
    }

    get reportedDisposedDateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.disposedDate) === -1;
    }

    get rowIndex(): number {
        return this._rowIndex;
    }

    // effective date
    get effectiveDate(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.effectiveDate;
    }

    // asset classification
    get reportedAssetClassificationDisplay(): string {
        return this._model.reportingAsset.assetClassification && this._model.reportingAsset.assetClassification.name;
    }

    // asset classification
    get reportedAssetClassificationId(): number {
        return this._model.reportingAsset.assetClassification && this._model.reportingAsset.assetClassification.assetClassificationId;
    }

    set reportedAssetClassificationId(value: number) {
        this._model.reportingAsset.assetClassification = {
            assetClassificationId: value,
            name: '',
            allowPerpetual: false,
            childClassifications: [],
            isInventory: false
        };
    }

    get assetClassificationHasOverride(): boolean {
        const assetClassificationId = this.reportedGlAccountClassificationId || this.sourceGlAccountClassificationId;
        return (this._model.reportingAsset.assetClassification &&
            this._model.reportingAsset.assetClassification.assetClassificationId !== assetClassificationId)
            || (assetClassificationId && !this._model.reportingAsset.assetClassification);
    }

    get assetId(): number {
        return this._model && this._model && this._model.assetId;
    }

    get reportingAssetId(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.reportingAssetId;
    }

    // is perpetual
    get reportingIsPerpetual(): boolean {
        let result: boolean = null;
        if (this._model.reportingAsset.assetClassification && this._model.reportingAsset.assetClassification.allowPerpetual ||
            this._model.reportingAssetOverride && this._model.reportingAssetOverride.glAccount && this._model.reportingAssetOverride.glAccount.allowPerpetual) {
            result = this._model.reportingAsset.isPerpetual;
        }

        return result;
    }

    set reportingIsPerpetual(isPerpetual: boolean) {
        this._model.reportingAsset.isPerpetual = isPerpetual;
    }

    // is user created
    get isUserCreated(): boolean {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.isUserCreated;
    }

    // has imported source (for user created asset)
    get hasImportedSource(): boolean {
        return this._model && this._model.hasImportedSource;
    }

    get areSplitAllocationsValid(): boolean {
        return this._model && this._model.areSplitAllocationsValid;
    }

    get splitAllocationPercentage(): number {
        return this._model && this._model.splitAllocationPercentage;
    }

    updateModel(model: Compliance.AssetModel): void {
        this._setModel(model, this.rowIndex);
    }

    getModel(): Compliance.AssetModel {
        return this._model;
    }

    getOverrides(): Compliance.ReportingAssetOverrideModel {
        return this._overrides;
    }

    // LeaseClientId
    get sourceLeaseClientId(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseClientId;
    }

    get reportedLeaseClientId(): string {
        return this.isOverride(this._overrides.leaseClientId) ? this._overrides.leaseClientId : this.sourceLeaseClientId;
    }

    set reportedLeaseClientId(value: string) {
        this._overrides.leaseClientId = value;
    }

    get reportedLeaseClientIdHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseClientId) === -1;
    }

    // LeaseLocationName
    get sourceLeaseLocationName(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseLocationName;
    }

    get reportedLeaseLocationName(): string {
        return this.isOverride(this._overrides.leaseLocationName) ? this._overrides.leaseLocationName : this.sourceLeaseLocationName;
    }

    set reportedLeaseLocationName(value: string) {
        this._overrides.leaseLocationName = value;
    }

    get reportedLeaseLocationNameHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseLocationName) === -1;
    }

    // LeaseAddress
    get sourceLeaseAddress(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseAddress;
    }

    get reportedLeaseAddress(): string {
        return this.isOverride(this._overrides.leaseAddress) ? this._overrides.leaseAddress : this.sourceLeaseAddress;
    }

    set reportedLeaseAddress(value: string) {
        this._overrides.leaseAddress = value;
    }

    get reportedLeaseAddressHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseAddress) === -1;
    }

    // LeaseCity
    get sourceLeaseCity(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseCity;
    }

    get reportedLeaseCity(): string {
        return this.isOverride(this._overrides.leaseCity) ? this._overrides.leaseCity : this.sourceLeaseCity;
    }

    set reportedLeaseCity(value: string) {
        this._overrides.leaseCity = value;
    }

    get reportedLeaseCityHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseCity) === -1;
    }

    // LeaseState
    get sourceLeaseState(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseState;
    }

    get reportedLeaseState(): string {
        return this.isOverride(this._overrides.leaseState) ? this._overrides.leaseState : this.sourceLeaseState;
    }

    set reportedLeaseState(value: string) {
        this._overrides.leaseState = value;
    }

    get reportedLeaseStateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseState) === -1;
    }

    // LeaseZip
    get sourceLeaseZip(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseZip;
    }

    get reportedLeaseZip(): string {
        return this.isOverride(this._overrides.leaseZip) ? this._overrides.leaseZip : this.sourceLeaseZip;
    }

    set reportedLeaseZip(value: string) {
        this._overrides.leaseZip = value;
    }

    get reportedLeaseZipHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseZip) === -1;
    }

    // LeaseTermInMonths
    get sourceLeaseTermInMonths(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseTermInMonths;
    }

    get reportedLeaseTermInMonths(): number {
        return this.isOverride(this._overrides.leaseTermInMonths) ? this._overrides.leaseTermInMonths : this.sourceLeaseTermInMonths;
    }

    set reportedLeaseTermInMonths(value: number) {
        this._overrides.leaseTermInMonths = value;
    }

    get reportedLeaseTermInMonthsHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseTermInMonths) === -1;
    }

    // LeaseMonthlyPayment
    get sourceLeaseMonthlyPayment(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseMonthlyPayment;
    }

    get reportedLeaseMonthlyPayment(): number {
        return this.isOverride(this._overrides.leaseMonthlyPayment) ? this._overrides.leaseMonthlyPayment : this.sourceLeaseMonthlyPayment;
    }

    set reportedLeaseMonthlyPayment(value: number) {
        this._overrides.leaseMonthlyPayment = value;
    }

    get reportedLeaseMonthlyPaymentHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseMonthlyPayment) === -1;
    }

    // LeaseTypeId
    get sourceLeaseTypeId(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseTypeId;
    }

    get reportedLeaseTypeId(): string {
        return this.isOverride(this._overrides.leaseTypeId) ? this._overrides.leaseTypeId : this.sourceLeaseTypeId;
    }

    set reportedLeaseTypeId(value: string) {
        this._overrides.leaseTypeId = value;
    }

    get reportedLeaseTypeIdHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseTypeId) === -1;
    }

    // LeaseAssetId
    get sourceLeaseAssetId(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseAssetId;
    }

    get reportedLeaseAssetId(): string {
        return this.isOverride(this._overrides.leaseAssetId) ? this._overrides.leaseAssetId : this.sourceLeaseAssetId;
    }

    set reportedLeaseAssetId(value: string) {
        this._overrides.leaseAssetId = value;
    }

    get reportedLeaseAssetIdHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseAssetId) === -1;
    }

    // LeaseNumber
    get sourceLeaseNumber(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseNumber;
    }

    get reportedLeaseNumber(): string {
        return this.isOverride(this._overrides.leaseNumber) ? this._overrides.leaseNumber : this.sourceLeaseNumber;
    }

    set reportedLeaseNumber(value: string) {
        this._overrides.leaseNumber = value;
    }

    get reportedLeaseNumberHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseNumber) === -1;
    }

    // LeaseBillingName
    get sourceLeaseBillingName(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseBillingName;
    }

    get reportedLeaseBillingName(): string {
        return this.isOverride(this._overrides.leaseBillingName) ? this._overrides.leaseBillingName : this.sourceLeaseBillingName;
    }

    set reportedLeaseBillingName(value: string) {
        this._overrides.leaseBillingName = value;
    }

    get reportedLeaseBillingNameHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseBillingName) === -1;
    }


    // LeaseBillingAddress
    get sourceLeaseBillingAddress(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseBillingAddress;
    }

    get reportedLeaseBillingAddress(): string {
        return this.isOverride(this._overrides.leaseBillingAddress) ? this._overrides.leaseBillingAddress : this.sourceLeaseBillingAddress;
    }

    set reportedLeaseBillingAddress(value: string) {
        this._overrides.leaseBillingAddress = value;
    }

    get reportedLeaseBillingAddressHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseBillingAddress) === -1;
    }

    // LeaseBillingCity
    get sourceLeaseBillingCity(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseBillingCity;
    }

    get reportedLeaseBillingCity(): string {
        return this.isOverride(this._overrides.leaseBillingCity) ? this._overrides.leaseBillingCity : this.sourceLeaseBillingCity;
    }

    set reportedLeaseBillingCity(value: string) {
        this._overrides.leaseBillingCity = value;
    }

    get reportedLeaseBillingCityHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseBillingCity) === -1;
    }

    // LeaseBillingState
    get sourceLeaseBillingState(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseBillingState;
    }

    get reportedLeaseBillingState(): string {
        return this.isOverride(this._overrides.leaseBillingState) ? this._overrides.leaseBillingState : this.sourceLeaseBillingState;
    }

    set reportedLeaseBillingState(value: string) {
        this._overrides.leaseBillingState = value;
    }

    get reportedLeaseBillingStateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseBillingState) === -1;
    }

    // LeaseBillingZip
    get sourceLeaseBillingZip(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseBillingZip;
    }

    get reportedLeaseBillingZip(): string {
        return this.isOverride(this._overrides.leaseBillingZip) ? this._overrides.leaseBillingZip : this.sourceLeaseBillingZip;
    }

    set reportedLeaseBillingZip(value: string) {
        this._overrides.leaseBillingZip = value;
    }

    get reportedLeaseBillingZipHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseBillingZip) === -1;
    }

    // LeaseStartDate
    get sourceLeaseStartDate(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseStartDate;
    }

    get reportedLeaseStartDate(): Date {
        return this.isOverride(this._overrides.leaseStartDate) ? this._overrides.leaseStartDate : this.sourceLeaseStartDate;
    }

    set reportedLeaseStartDate(value: Date) {
        this._overrides.leaseStartDate = value;
    }

    get reportedLeaseStartDateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseStartDate) === -1;
    }

    // LeaseEndDate
    get sourceLeaseEndDate(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.leaseEndDate;
    }

    get reportedLeaseEndDate(): Date {
        return this.isOverride(this._overrides.leaseEndDate) ? this._overrides.leaseEndDate : this.sourceLeaseEndDate;
    }

    set reportedLeaseEndDate(value: Date) {
        this._overrides.leaseEndDate = value;
    }

    get reportedLeaseEndDateHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.leaseEndDate) === -1;
    }

    // T1
    get sourceT1(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t1;
    }

    get reportedT1(): string {
        return this.isOverride(this._overrides.t1) ? this._overrides.t1 : this.sourceT1;
    }

    set reportedT1(value: string) {
        this._overrides.t1 = value;
    }

    // T2
    get sourceT2(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t2;
    }

    get reportedT2(): string {
        return this.isOverride(this._overrides.t2) ? this._overrides.t2 : this.sourceT2;
    }

    set reportedT2(value: string) {
        this._overrides.t2 = value;
    }

    // T3
    get sourceT3(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t3;
    }

    get reportedT3(): string {
        return this.isOverride(this._overrides.t3) ? this._overrides.t3 : this.sourceT3;
    }

    set reportedT3(value: string) {
        this._overrides.t3 = value;
    }

    // T4
    get sourceT4(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t4;
    }

    get reportedT4(): string {
        return this.isOverride(this._overrides.t4) ? this._overrides.t4 : this.sourceT4;
    }

    set reportedT4(value: string) {
        this._overrides.t4 = value;
    }

    // T5
    get sourceT5(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t5;
    }

    get reportedT5(): string {
        return this.isOverride(this._overrides.t5) ? this._overrides.t5 : this.sourceT5;
    }

    set reportedT5(value: string) {
        this._overrides.t5 = value;
    }

    // T6
    get sourceT6(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t6;
    }

    get reportedT6(): string {
        return this.isOverride(this._overrides.t6) ? this._overrides.t6 : this.sourceT6;
    }

    set reportedT6(value: string) {
        this._overrides.t6 = value;
    }

    // T7
    get sourceT7(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t7;
    }

    get reportedT7(): string {
        return this.isOverride(this._overrides.t7) ? this._overrides.t7 : this.sourceT7;
    }

    set reportedT7(value: string) {
        this._overrides.t7 = value;
    }

    // T8
    get sourceT8(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t8;
    }

    get reportedT8(): string {
        return this.isOverride(this._overrides.t8) ? this._overrides.t8 : this.sourceT8;
    }

    set reportedT8(value: string) {
        this._overrides.t8 = value;
    }

    // T9
    get sourceT9(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t9;
    }

    get reportedT9(): string {
        return this.isOverride(this._overrides.t9) ? this._overrides.t9 : this.sourceT9;
    }

    set reportedT9(value: string) {
        this._overrides.t9 = value;
    }

    // T10
    get sourceT10(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t10;
    }

    get reportedT10(): string {
        return this.isOverride(this._overrides.t10) ? this._overrides.t10 : this.sourceT10;
    }

    set reportedT10(value: string) {
        this._overrides.t10 = value;
    }

    // T11
    get sourceT11(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t11;
    }

    get reportedT11(): string {
        return this.isOverride(this._overrides.t11) ? this._overrides.t11 : this.sourceT11;
    }

    set reportedT11(value: string) {
        this._overrides.t11 = value;
    }

    // T12
    get sourceT12(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t12;
    }

    get reportedT12(): string {
        return this.isOverride(this._overrides.t12) ? this._overrides.t12 : this.sourceT12;
    }

    set reportedT12(value: string) {
        this._overrides.t12 = value;
    }

    // T13
    get sourceT13(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t13;
    }

    get reportedT13(): string {
        return this.isOverride(this._overrides.t13) ? this._overrides.t13 : this.sourceT13;
    }

    set reportedT13(value: string) {
        this._overrides.t13 = value;
    }

    // T14
    get sourceT14(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t14;
    }

    get reportedT14(): string {
        return this.isOverride(this._overrides.t14) ? this._overrides.t14 : this.sourceT14;
    }

    set reportedT14(value: string) {
        this._overrides.t14 = value;
    }

    // T15
    get sourceT15(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t15;
    }

    get reportedT15(): string {
        return this.isOverride(this._overrides.t15) ? this._overrides.t15 : this.sourceT15;
    }

    set reportedT15(value: string) {
        this._overrides.t15 = value;
    }

    // T6
    get sourceT16(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t16;
    }

    get reportedT16(): string {
        return this.isOverride(this._overrides.t16) ? this._overrides.t16 : this.sourceT16;
    }

    set reportedT16(value: string) {
        this._overrides.t16 = value;
    }

    // T7
    get sourceT17(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t17;
    }

    get reportedT17(): string {
        return this.isOverride(this._overrides.t17) ? this._overrides.t17 : this.sourceT17;
    }

    set reportedT17(value: string) {
        this._overrides.t17 = value;
    }

    // T8
    get sourceT18(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t18;
    }

    get reportedT18(): string {
        return this.isOverride(this._overrides.t18) ? this._overrides.t18 : this.sourceT18;
    }

    set reportedT18(value: string) {
        this._overrides.t18 = value;
    }

    // T9
    get sourceT19(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t19;
    }

    get reportedT19(): string {
        return this.isOverride(this._overrides.t19) ? this._overrides.t19 : this.sourceT19;
    }

    set reportedT19(value: string) {
        this._overrides.t19 = value;
    }

    // T20
    get sourceT20(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t20;
    }

    get reportedT20(): string {
        return this.isOverride(this._overrides.t20) ? this._overrides.t20 : this.sourceT20;
    }

    set reportedT20(value: string) {
        this._overrides.t20 = value;
    }

    // T21
    get sourceT21(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t21;
    }

    get reportedT21(): string {
        return this.isOverride(this._overrides.t21) ? this._overrides.t21 : this.sourceT21;
    }

    set reportedT21(value: string) {
        this._overrides.t21 = value;
    }

    // T22
    get sourceT22(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t22;
    }

    get reportedT22(): string {
        return this.isOverride(this._overrides.t22) ? this._overrides.t22 : this.sourceT22;
    }

    set reportedT22(value: string) {
        this._overrides.t22 = value;
    }

    // T23
    get sourceT23(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t23;
    }

    get reportedT23(): string {
        return this.isOverride(this._overrides.t23) ? this._overrides.t23 : this.sourceT23;
    }

    set reportedT23(value: string) {
        this._overrides.t23 = value;
    }

    // T24
    get sourceT24(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t24;
    }

    get reportedT24(): string {
        return this.isOverride(this._overrides.t24) ? this._overrides.t24 : this.sourceT24;
    }

    set reportedT24(value: string) {
        this._overrides.t24 = value;
    }

    // T25
    get sourceT25(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t25;
    }

    get reportedT25(): string {
        return this.isOverride(this._overrides.t25) ? this._overrides.t25 : this.sourceT25;
    }

    set reportedT25(value: string) {
        this._overrides.t25 = value;
    }

    // T26
    get sourceT26(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t26;
    }

    get reportedT26(): string {
        return this.isOverride(this._overrides.t26) ? this._overrides.t26 : this.sourceT26;
    }

    set reportedT26(value: string) {
        this._overrides.t26 = value;
    }

    // T27
    get sourceT27(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t27;
    }

    get reportedT27(): string {
        return this.isOverride(this._overrides.t27) ? this._overrides.t27 : this.sourceT27;
    }

    set reportedT27(value: string) {
        this._overrides.t27 = value;
    }

    // T28
    get sourceT28(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t28;
    }

    get reportedT28(): string {
        return this.isOverride(this._overrides.t28) ? this._overrides.t28 : this.sourceT28;
    }

    set reportedT28(value: string) {
        this._overrides.t28 = value;
    }

    // T29
    get sourceT29(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t29;
    }

    get reportedT29(): string {
        return this.isOverride(this._overrides.t29) ? this._overrides.t29 : this.sourceT29;
    }

    set reportedT29(value: string) {
        this._overrides.t29 = value;
    }

    // T30
    get sourceT30(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.t30;
    }

    get reportedT30(): string {
        return this.isOverride(this._overrides.t30) ? this._overrides.t30 : this.sourceT30;
    }

    set reportedT30(value: string) {
        this._overrides.t30 = value;
    }

    // N1
    get sourceN1(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n1;
    }

    get reportedN1(): number {
        return this.isOverride(this._overrides.n1) ? this._overrides.n1 : this.sourceN1;
    }

    set reportedN1(value: number) {
        this._overrides.n1 = value;
    }

    // N2
    get sourceN2(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n2;
    }

    get reportedN2(): number {
        return this.isOverride(this._overrides.n2) ? this._overrides.n2 : this.sourceN2;
    }

    set reportedN2(value: number) {
        this._overrides.n2 = value;
    }

    // N3
    get sourceN3(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n3;
    }

    get reportedN3(): number {
        return this.isOverride(this._overrides.n3) ? this._overrides.n3 : this.sourceN3;
    }

    set reportedN3(value: number) {
        this._overrides.n3 = value;
    }

    // N4
    get sourceN4(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n4;
    }

    get reportedN4(): number {
        return this.isOverride(this._overrides.n4) ? this._overrides.n4 : this.sourceN4;
    }

    set reportedN4(value: number) {
        this._overrides.n4 = value;
    }

    // N5
    get sourceN5(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n5;
    }

    get reportedN5(): number {
        return this.isOverride(this._overrides.n5) ? this._overrides.n5 : this.sourceN5;
    }

    set reportedN5(value: number) {
        this._overrides.n5 = value;
    }

    // N6
    get sourceN6(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n6;
    }

    get reportedN6(): number {
        return this.isOverride(this._overrides.n6) ? this._overrides.n6 : this.sourceN6;
    }

    set reportedN6(value: number) {
        this._overrides.n6 = value;
    }

    // N7
    get sourceN7(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n7;
    }

    get reportedN7(): number {
        return this.isOverride(this._overrides.n7) ? this._overrides.n7 : this.sourceN7;
    }

    set reportedN7(value: number) {
        this._overrides.n7 = value;
    }

    // N8
    get sourceN8(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n8;
    }

    get reportedN8(): number {
        return this.isOverride(this._overrides.n8) ? this._overrides.n8 : this.sourceN8;
    }

    set reportedN8(value: number) {
        this._overrides.n8 = value;
    }

    // N9
    get sourceN9(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n9;
    }

    get reportedN9(): number {
        return this.isOverride(this._overrides.n9) ? this._overrides.n9 : this.sourceN9;
    }

    set reportedN9(value: number) {
        this._overrides.n9 = value;
    }

    // N10
    get sourceN10(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.n10;
    }

    get reportedN10(): number {
        return this.isOverride(this._overrides.n10) ? this._overrides.n10 : this.sourceN10;
    }

    set reportedN10(value: number) {
        this._overrides.n10 = value;
    }

    slotColumnHasOverride(columnName: string): boolean {
        return this.isOverride(this._overrides[columnName.toLocaleLowerCase()]);
    }

    slotColumnHasOverrideWithBlank(columnName: string): boolean {
        return this.isOverride(this._overrides[columnName.toLocaleLowerCase()]) && typeof this._overrides[columnName.toLocaleLowerCase()] === 'string' && this._overrides[columnName.toLocaleLowerCase()].trim() === '';
    }

    // D1
    get sourceD1(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d1;
    }

    get reportedD1(): Date {
        return this.isOverride(this._overrides.d1) ? this._overrides.d1 : this.sourceD1;
    }

    set reportedD1(value: Date) {
        this._overrides.d1 = value;
    }

    // D2
    get sourceD2(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d2;
    }

    get reportedD2(): Date {
        return this.isOverride(this._overrides.d2) ? this._overrides.d2 : this.sourceD2;
    }

    set reportedD2(value: Date) {
        this._overrides.d2 = value;
    }

    // D3
    get sourceD3(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d3;
    }

    get reportedD3(): Date {
        return this.isOverride(this._overrides.d3) ? this._overrides.d3 : this.sourceD3;
    }

    set reportedD3(value: Date) {
        this._overrides.d3 = value;
    }

    // D4
    get sourceD4(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d4;
    }

    get reportedD4(): Date {
        return this.isOverride(this._overrides.d4) ? this._overrides.d4 : this.sourceD4;
    }

    set reportedD4(value: Date) {
        this._overrides.d4 = value;
    }

    // D5
    get sourceD5(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d5;
    }

    get reportedD5(): Date {
        return this.isOverride(this._overrides.d5) ? this._overrides.d5 : this.sourceD5;
    }

    set reportedD5(value: Date) {
        this._overrides.d5 = value;
    }

    // D6
    get sourceD6(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d6;
    }

    get reportedD6(): Date {
        return this.isOverride(this._overrides.d6) ? this._overrides.d6 : this.sourceD6;
    }

    set reportedD6(value: Date) {
        this._overrides.d6 = value;
    }

    // D7
    get sourceD7(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d7;
    }

    get reportedD7(): Date {
        return this.isOverride(this._overrides.d7) ? this._overrides.d7 : this.sourceD7;
    }

    set reportedD7(value: Date) {
        this._overrides.d7 = value;
    }

    // D8
    get sourceD8(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d8;
    }

    get reportedD8(): Date {
        return this.isOverride(this._overrides.d8) ? this._overrides.d8 : this.sourceD8;
    }

    set reportedD8(value: Date) {
        this._overrides.d8 = value;
    }

    // D9
    get sourceD9(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d9;
    }

    get reportedD9(): Date {
        return this.isOverride(this._overrides.d9) ? this._overrides.d9 : this.sourceD9;
    }

    set reportedD9(value: Date) {
        this._overrides.d9 = value;
    }

    // D10
    get sourceD10(): Date {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.d10;
    }

    get reportedD10(): Date {
        return this.isOverride(this._overrides.d10) ? this._overrides.d10 : this.sourceD10;
    }

    set reportedD10(value: Date) {
        this._overrides.d10 = value;
    }

    get reportedCostAdjustmentAmount1(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount1;
    }

    get reportedCostAdjustmentAmount2(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount2;
    }

    get reportedCostAdjustmentAmount3(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount3;
    }

    get reportedCostAdjustmentAmount4(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount4;
    }

    get reportedCostAdjustmentAmount5(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount5;
    }

    get reportedCostAdjustmentAmount6(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount6;
    }

    get reportedCostAdjustmentAmount7(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount7;
    }

    get reportedCostAdjustmentAmount8(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount8;
    }

    get reportedCostAdjustmentAmount9(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount9;
    }

    get reportedCostAdjustmentAmount10(): number {
        return this._model && this._model.reportingAsset && this._model.reportingAsset.costAdjustmentAmount10;
    }

    isOverride(value: any) {
        return [null, undefined].indexOf(value) === -1;
    }

    hasCostAdjustment(): boolean {
        return !!(this.reportedCostAdjustmentAmount1 ||
            this.reportedCostAdjustmentAmount2 ||
            this.reportedCostAdjustmentAmount3 ||
            this.reportedCostAdjustmentAmount4 ||
            this.reportedCostAdjustmentAmount5 ||
            this.reportedCostAdjustmentAmount6 ||
            this.reportedCostAdjustmentAmount7 ||
            this.reportedCostAdjustmentAmount8 ||
            this.reportedCostAdjustmentAmount9 ||
            this.reportedCostAdjustmentAmount10);
    }

    get sourceAssetId(): number {
        return this._model.sourceAsset.sourceAssetId;
    }

    // special versions of cost to bind to but only affecting real reported cost in limited situations
    get transientReportedCost(): number {
        return this.reportedCostHasOverride ? this._overrides.cost : this._transientReportedCost;
    }

    set transientReportedCost(value: number) {
        this._transientReportedCost = value;
    }

    set transientReportedN1(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN1(): number {
        return this.reportedColumnHasOverride('N1') ? this._overrides.n1 : this._transientReportedCost;
    }

    set transientReportedN2(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN2(): number {
        return this.reportedColumnHasOverride('N2') ? this._overrides.n2 : this._transientReportedCost;
    }

    set transientReportedN3(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN3(): number {
        return this.reportedColumnHasOverride('N3') ? this._overrides.n3 : this._transientReportedCost;
    }

    set transientReportedN4(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN4(): number {
        return this.reportedColumnHasOverride('N4') ? this._overrides.n4 : this._transientReportedCost;
    }

    set transientReportedN5(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN5(): number {
        return this.reportedColumnHasOverride('N5') ? this._overrides.n5 : this._transientReportedCost;
    }

    set transientReportedN6(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN6(): number {
        return this.reportedColumnHasOverride('N6') ? this._overrides.n6 : this._transientReportedCost;
    }

    set transientReportedN7(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN7(): number {
        return this.reportedColumnHasOverride('N7') ? this._overrides.n7 : this._transientReportedCost;
    }

    set transientReportedN8(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN8(): number {
        return this.reportedColumnHasOverride('N8') ? this._overrides.n8 : this._transientReportedCost;
    }

    set transientReportedN9(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN9(): number {
        return this.reportedColumnHasOverride('N9') ? this._overrides.n9 : this._transientReportedCost;
    }

    set transientReportedN10(value: number) {
        this._transientReportedCost = value;
    }

    get transientReportedN10(): number {
        return this.reportedColumnHasOverride('N10') ? this._overrides.n10 : this._transientReportedCost;
    }

    get changeStatus(): any {
        return this._model.changeStatus;
    }

    get sourceOldAssetNumber(): string {
        return this._model && this._model.oldAssetNumber;
    }

    get reportedOldAssetNumber(): string {
        return this.reportedOldAssetNumberHasOverride || this.reportedOldAssetNumberHasOverrideWithBlank
            ? this._model.reportingAsset.oldAssetNumber
            : this.sourceOldAssetNumber;
    }

    set reportedOldAssetNumber(value: string) {
        this._model.reportingAsset.oldAssetNumber = value;
    }

    get reportedOldAssetNumberHasOverride(): boolean {
        return this.sourceOldAssetNumber !== this._model.reportingAsset.oldAssetNumber;
    }

    get reportedOldAssetNumberHasOverrideWithBlank(): boolean {
        return this.reportedOldAssetNumberHasOverride && !this._model.reportingAsset.oldAssetNumber;
    }

    get sourceInventoryJan(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryJan;
    }

    get reportedInventoryJan(): number {
        return this.isOverride(this._overrides.inventoryJan) ? this._overrides.inventoryJan : this.sourceInventoryJan;
    }

    set reportedInventoryJan(value: number) {
        this._overrides.inventoryJan = value;
    }

    get reportedInventoryJanHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryJan);
    }

    get sourceInventoryFeb(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryFeb;
    }

    get reportedInventoryFeb(): number {
        return this.isOverride(this._overrides.inventoryFeb) ? this._overrides.inventoryFeb : this.sourceInventoryFeb;
    }

    set reportedInventoryFeb(value: number) {
        this._overrides.inventoryFeb = value;
    }

    get reportedInventoryFebHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryFeb);
    }

    get sourceInventoryMar(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryMar;
    }

    get reportedInventoryMar(): number {
        return this.isOverride(this._overrides.inventoryMar) ? this._overrides.inventoryMar : this.sourceInventoryMar;
    }

    set reportedInventoryMar(value: number) {
        this._overrides.inventoryMar = value;
    }

    get reportedInventoryMarHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryMar);
    }

    get sourceInventoryApr(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryApr;
    }

    get reportedInventoryApr(): number {
        return this.isOverride(this._overrides.inventoryApr) ? this._overrides.inventoryApr : this.sourceInventoryApr;
    }

    set reportedInventoryApr(value: number) {
        this._overrides.inventoryApr = value;
    }

    get reportedInventoryAprHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryApr);
    }

    get sourceInventoryMay(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryMay;
    }

    get reportedInventoryMay(): number {
        return this.isOverride(this._overrides.inventoryMay) ? this._overrides.inventoryMay : this.sourceInventoryMay;
    }

    set reportedInventoryMay(value: number) {
        this._overrides.inventoryMay = value;
    }

    get reportedInventoryMayHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryMay);
    }

    get sourceInventoryJun(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryJun;
    }

    get reportedInventoryJun(): number {
        return this.isOverride(this._overrides.inventoryJun) ? this._overrides.inventoryJun : this.sourceInventoryJun;
    }

    set reportedInventoryJun(value: number) {
        this._overrides.inventoryJun = value;
    }

    get reportedInventoryJunHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryJun);
    }

    get sourceInventoryJul(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryJul;
    }

    get reportedInventoryJul(): number {
        return this.isOverride(this._overrides.inventoryJul) ? this._overrides.inventoryJul : this.sourceInventoryJul;
    }

    set reportedInventoryJul(value: number) {
        this._overrides.inventoryJul = value;
    }

    get reportedInventoryJulHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryJul);
    }

    get sourceInventoryAug(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryAug;
    }

    get reportedInventoryAug(): number {
        return this.isOverride(this._overrides.inventoryAug) ? this._overrides.inventoryAug : this.sourceInventoryAug;
    }

    set reportedInventoryAug(value: number) {
        this._overrides.inventoryAug = value;
    }

    get reportedInventoryAugHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryAug);
    }

    get sourceInventorySep(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventorySep;
    }

    get reportedInventorySep(): number {
        return this.isOverride(this._overrides.inventorySep) ? this._overrides.inventorySep : this.sourceInventorySep;
    }

    set reportedInventorySep(value: number) {
        this._overrides.inventorySep = value;
    }

    get reportedInventorySepHasOverride(): boolean {
        return this.isOverride(this._overrides.inventorySep);
    }

    get sourceInventoryOct(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryOct;
    }

    get reportedInventoryOct(): number {
        return this.isOverride(this._overrides.inventoryOct) ? this._overrides.inventoryOct : this.sourceInventoryOct;
    }

    set reportedInventoryOct(value: number) {
        this._overrides.inventoryOct = value;
    }

    get reportedInventoryOctHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryOct);
    }

    get sourceInventoryNov(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryNov;
    }

    get reportedInventoryNov(): number {
        return this.isOverride(this._overrides.inventoryNov) ? this._overrides.inventoryNov : this.sourceInventoryNov;
    }

    set reportedInventoryNov(value: number) {
        this._overrides.inventoryNov = value;
    }

    get reportedInventoryNovHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryNov);
    }

    get sourceInventoryDec(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryDec;
    }

    get reportedInventoryDec(): number {
        return this.isOverride(this._overrides.inventoryDec) ? this._overrides.inventoryDec : this.sourceInventoryDec;
    }

    set reportedInventoryDec(value: number) {
        this._overrides.inventoryDec = value;
    }

    get reportedInventoryDecHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryDec);
    }

    get sourceInventoryAggregatedString(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryAggregatedString;
    }

    get reportedInventoryAggregatedString(): string {
        return this._model && (this._overrides.inventoryAggregatedString || this._model.sourceAsset.inventoryAggregatedString);
    }

    get sourceInventoryAvr(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.inventoryAvr;
    }

    get reportedInventoryAvr(): number {
        let result: number;

        if (this.isOverride(this._overrides.inventoryAvr)) {
            result = this._overrides.inventoryAvr;
        } else if (!this.sourceInventoryAvr) {
            result = this._getInventorySupplyAggregateInfo(this.reportedInventoryJan, this.reportedInventoryFeb,
                this.reportedInventoryMar, this.reportedInventoryApr, this.reportedInventoryMay,
                this.reportedInventoryJun, this.reportedInventoryJul, this.reportedInventoryAug,
                this.reportedInventorySep, this.reportedInventoryOct, this.reportedInventoryNov,
                this.reportedInventoryDec);
        } else { result = this.sourceInventoryAvr; }

        return result;
    }

    set reportedInventoryAvr(value: number) {
        this._overrides.inventoryAvr = value;
    }

    get reportedInventoryAvrHasOverride(): boolean {
        return this.isOverride(this._overrides.inventoryAvr);
    }

    get reportedInventoryAvrCalculated(): number {
        return this._overrides.inventoryAvrCalculated;
    }

    get reportedInventoryAvrIsCalculated(): boolean {
        return !this.reportedInventoryAvrHasOverride && !!this.reportedInventoryAvrCalculated && this.reportedInventoryAvr !== this.sourceInventoryAvr;
    }

    get sourceNetBookValue(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.netBookValue;
    }

    get reportedNetBookValue(): number {
        return this.isOverride(this._overrides.netBookValue) ? this._overrides.netBookValue : this.sourceNetBookValue;
    }

    set reportedNetBookValue(value: number) {
        this._overrides.netBookValue = value;
    }

    get reportedNetBookValueHasOverride(): boolean {
        return this.isOverride(this._overrides.netBookValue);
    }

    get alternativeAcqDateDescriptorName(): string {
        return this._model && this._model.alternativeAcqDateDescriptorName;
    }

    get alternativeCostDescriptorName(): string {
        return this._model && this._model.alternativeCostDescriptorName;
    }

    get sourceCompanyId(): number {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.sourceCompanyId;
    }

    get sourceCompanyName(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.sourceCompanyName;
    }

    get sourceCompanyCode(): string {
        return this._model && this._model.sourceAsset && this._model.sourceAsset.sourceCompanyCode;
    }

    get reportedIsAnchored(): boolean {
        return this._model && this._model.reportingAsset.isAnchored;
    }

    set reportedIsAnchored(value: boolean) {
        this._model.reportingAsset.isAnchored = value;
    }

    get reportedDoNotPotentiallyDispose(): boolean {
        return this._model && this._model.reportingAsset.doNotPotentiallyDispose;
    }

    set reportedDoNotPotentiallyDispose(value: boolean) {
        this._model.reportingAsset.doNotPotentiallyDispose = value;
    }

    get reportedIsLocked(): boolean {
        return this._model && this._model.reportingAsset.isLocked;
    }

    set reportedIsLocked(value: boolean) {
        this._model.reportingAsset.isLocked = value;

        if (!value) {
            this._isLocked = value;
        }
    }

    get isLocked(): boolean {
        return this._isLocked;
    }

    get lastReturnDescription(): string {
        return this._model.lastReturnDescription;
    }

    get lastReturnId(): number {
        return this._model.lastReturnId;
    }

    get siteAddress(): string {
        return this._model.siteAddress;
    }

    get reportedSiteAddressHasOverride(): boolean {
        return [null, undefined].indexOf(this._overrides.reportingSiteAddress) === -1;
    }

    get sourceSiteAddress(): string {
        return this._model.sourceAsset.sourceSiteAddress;
    }

    get assessorId(): number {
        return this._model.reportingAsset.assessorId;
    }

    private _getInventorySupplyAggregateInfo(jan: number, feb: number, mar: number, apr: number, may: number, jun: number,
                                             jul: number, aug: number, sep: number, oct: number, nov: number, dec: number): number {
        let monthWithValue = 0;
        let totalMonthsValue = 0;

        function populateMonthInfo(monthValue: number | string) {
            if (typeof(monthValue) === 'string'){
                monthValue = +(monthValue || '').replace(new RegExp(',', 'g'), '');
            }

            if (monthValue || monthValue === 0) {
                totalMonthsValue += monthValue;
                monthWithValue++;
            }
        }

        populateMonthInfo.call(this, jan);
        populateMonthInfo.call(this, feb);
        populateMonthInfo.call(this, mar);
        populateMonthInfo.call(this, apr);
        populateMonthInfo.call(this, may);
        populateMonthInfo.call(this, jun);
        populateMonthInfo.call(this, jul);
        populateMonthInfo.call(this, aug);
        populateMonthInfo.call(this, sep);
        populateMonthInfo.call(this, oct);
        populateMonthInfo.call(this, nov);
        populateMonthInfo.call(this, dec);

        const average = totalMonthsValue / monthWithValue;
        return monthWithValue != 0 && !isNaN(average) ? average : null;
    }
}
