import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormRepository } from '../../Repositories/form.repository';
import * as _ from 'lodash';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent, CsvExportParams } from 'ag-grid-community';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { lastValueFrom } from 'rxjs';

interface FormFieldListModel extends Compliance.ReturnFormFieldInfoModel {
    parameterList: string;
    ruleList: string;
}

@Component({
    selector: 'form-field-list',
    templateUrl: './formFieldList.component.html',
    styleUrls: ['./formFieldList.component.scss']
})
export class FormFieldListComponent implements OnInit, IWeissmanModalComponent<void, void> {
    constructor(
        private readonly _formRepository: FormRepository,
        private readonly _bsModalRef: BsModalRef,
        private readonly _restrictService: RestrictService) {
    }

    private _gridApi: GridApi;
    private _fields: FormFieldListModel[];

    params: void;
    result: void;
    canExport: boolean = false;
    isBusy: boolean = false;

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .build();

    async ngOnInit(): Promise<void> {
        this.canExport = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW) && this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        this.isBusy = true;

        try {
            const result = await lastValueFrom<Compliance.ReturnFormFieldInfoModel[]>(this._formRepository.getDataSourceFieldInfo());
            this._fields = _.map(result,
                item => {
                    const field = item as FormFieldListModel;
                    if (field.parameters) {
                        field.parameterList = _.join(field.parameters, ', ');
                    }
                    if (field.rules) {
                        field.ruleList = _.join(field.rules, ' > ');
                    }
                    return field;
                });
            this._setRowData();
        } finally {
            this.isBusy = false;
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
             {
                headerName: 'Name',
                field: 'name',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth,
                tooltipField: 'name'
            },
            {
                headerName: 'Full Name',
                field: 'fullName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'fullName'
            },
            {
                headerName: 'Category',
                field: 'category',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth,
                tooltipField: 'dataSourceName'
            },
            {
                headerName: 'Max Length',
                field: 'maxLength',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Parameters',
                field: 'parameterList',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'parameterList'
            },
            {
                headerName: 'Rules',
                field: 'ruleList',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'ruleList'
            },
            {
                headerName: 'Description',
                field: 'description',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'description'
            },
            {
                headerName: 'Sample Data',
                field: 'sampleData',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'sampleData'
            },
            {
                headerName: 'Transferable',
                field: 'isTransferable',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                tooltipField: 'isTransferable'
            }
        ];

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    export(): void {
        const params = {
            fileName: 'FormFields.csv'
        } as CsvExportParams;
        this._gridApi.exportDataAsCsv(params);
    }

    private _setRowData() {
        if (!(this._gridApi && this._fields)) {
            return;
        }

        this._gridApi.setRowData(this._fields);
        this._gridApi.sizeColumnsToFit();
    }
}
