import { Output, Component, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProcessingService } from './processing.service.upgrade';

declare const _: any;

@Component({
    selector: 'change-due-date-modal',
    templateUrl: './change-due-date.modal.component.html'
})
export class ChangeDueDateModal implements OnInit {
    loading: boolean;
    dueDateText: string;
    dueDateTextOptions: object;
    dateOnly: boolean;
    dueDate: Date;
    taskIDs: number[];
    searchTimestamp: Date;
    saveResolve: (result: any) => void;
    saveReject: () => void;
    parentCallsService: boolean;
    @Output('changeDueDateInitialized') setShow: EventEmitter<(taskIDs: number[],
        optionID: number, searchTimestamp: Date, parentCallsService: boolean) => Promise<any>>;
    @ViewChild('changeDueDateModal', { static: true }) changeDueDateModal: ModalDirective;

    constructor(private processingService: ProcessingService) {
        this.setShow = new EventEmitter<(taskIDs: number[], optionID: number) => Promise<any>>();
        this.dueDateTextOptions = {
            9: 'Appeal Deadline',
            10: 'Appeal Deadline',
            11: 'Submit Evidence Date',
            12: 'Informal Hearing Date',
            13: 'Formal Hearing Date',
            14: 'Payment Due Date',
            15: 'Intake Item Due Date',
            16: 'Filing Due Date',
            25: 'Invoice Due Date',
            35: 'Confirm Hearing Date'
        };
        this.parentCallsService = false;
    }

    ngOnInit(): void {
        this.parentCallsService = false;
        // HACK: An AngularJS directive needs to show this modal, and I couldn't find a good
        // hook to get that done, so we'll use an event emitter to send a reference back to this
        // component's show method
        this.setShow.emit((taskIDs, optionID, searchTimestamp, parentCallsService) => {
            return this.show(taskIDs, optionID, searchTimestamp, parentCallsService);
        });
    }

    show(taskIDs: number[], optionID: number, searchTimestamp: Date, parentCallsService?: boolean): Promise<any> {
        this.parentCallsService = parentCallsService;
        this.dueDate = null;
        this.dueDateText = this.dueDateTextOptions[optionID] || "Due Date";
        this.dateOnly = optionID != 11 && optionID != 12 && optionID != 13;
        this.taskIDs = taskIDs;
        this.searchTimestamp = searchTimestamp;
        let savePromise = new Promise<any>((resolve, reject) => {
            this.saveResolve = resolve;
            this.saveReject = reject;
        });

        this.changeDueDateModal.show();

        return savePromise;
    }

    showModalInit(): void {
        this.loading = false;
    }

    async save(): Promise<void> {
        if (this.parentCallsService) {
            this.changeDueDateModal.hide();
            this.saveResolve(() => {
                return this.processingService.changeDueDate(this.taskIDs, this.dueDate, this.searchTimestamp);
            });
        }
        else {
            this.loading = true;

            try {
                const result = await this.processingService.changeDueDate(this.taskIDs, this.dueDate, this.searchTimestamp)
                this.changeDueDateModal.hide();
                this.saveResolve(result);
            } finally {
                this.loading = false;
            }
        }
    }

    cancel(): void {
        this.saveReject();
        this.changeDueDateModal.hide();
    }
}