<div class="return-settings-general">
    <div class="card panel-flat-color primary-panel flex-panel">
        <div class="panel-flat-header ws-flex-container-horizontal">
            <h3 class="ws-truncate ws-flex-auto">
                General Return Settings
            </h3>
            <div class="ws-flex-none header-button-container">
                <button *ngIf="!editMode && canEdit"
                        title="Edit"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        [disabled]="!canEnterEditMode"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button title="Save"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        [disabled]="!hasChanges"
                        (click)="save()">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button title="Cancel"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
            </div>
        </div>
        <div class="panel-flat-body">
            <div class="mb-3 row" *ngIf="showReportNBVInsteadOfFactoredCost"
                 title="When enabled and forms support it, Assets NBV will be used to calculate the Reported value.">
                <div class="col-md-2 row-header">Report Net Book Value instead of factored cost:</div>
                <div class="col-md-3">
                    <ws-switch name="reportNBVInsteadOfFactoredCost"
                               [formControl]="reportNBVInsteadOfFactoredCostControl"
                               (selectedOptionChanged)="reportNBVInsteadOfFactoredCostChanged($event)">
                    </ws-switch>
                </div>
            </div>
            <div class="mb-3 row" *ngIf="showReturnCostReportingDisplay">
                <div class="col-md-2 row-header">Form Cost Display:</div>
                <div class="col-md-3">
                    <select name="returnCostReportingDisplay"
                            class="form-select form-select-sm"
                            [formControl]="returnCostReportingDisplayControl"
                            (ngModelChange)="returnCostReportingDisplayChanged($event)">
                        <option value="1">Cost</option>
                        <option value="2">Est. FMV</option>
                        <option value="3">Both</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
