import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BusyIndicatorMessageManager } from 'src/app/Busy-Indicator';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
@Component({
    selector: 'check-import-modal',
    template: `
        <ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

        <ws-modal-wrapper headerLabel="Import Checks"
                        (save)="returnFile()"
                        (cancel)="cancel()"
                        acceptLabel="Import"
                        [disableSave]="!file">
            <div class="ws-flex-container-vertical ws-stretch">
            <ws-file-picker acceptTypes=".xls,.xlsx" (picked)="filesPicked($event)"></ws-file-picker>
            </div>
        </ws-modal-wrapper>
    `
})


export class CheckImportModalComponent implements IWeissmanModalComponent<number, File> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _toastr: ToastrService
    ) { }

    params: number;
    result: File;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    file: File;

    filesPicked(files: File[]) {
        if(files.length) {
            this.file = files[0];
        }

        const { name } = files[0];
        const index = name.lastIndexOf('.');
        if (index !== 0) {
            const fileExtension = name.substr(index + 1).toLowerCase();
            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                this.file = files[0];
            } else {
                this._toastr.error('Only .xlx or .xlsx file formats are allowed.');
                this.file = undefined;
            }
        }
    }

    async returnFile() {
        this.result = this.file;
        this._bsModalRef.hide();
    }

    cancel() {
        this._bsModalRef.hide();
    }

}