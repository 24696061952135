import { Injectable } from '@angular/core';
import { SDHttpService } from '../Routing/sd-http.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomEmailTemplateModalComponent } from './custom-email-template-modal.component';
import {PreviewEmailTemplateModalComponent} from './preview-email-template-modal.component';
import * as _ from 'lodash';
import {PackageEmailTemplate} from '../../Processing/Transmittal/transmittal.models';

@Injectable(
    { providedIn: 'root' }
)
export class LaunchEmailTemplateModalService {
    constructor(private http: SDHttpService,
                private modalService: BsModalService) { }

    customTemplateModalRef: BsModalRef;

    openCustomTemplateModal(onCloseFn, templateSubject, templateBody, packageEmailTemplates) {
        const filteredPackageEmailTemplates: PackageEmailTemplate[] =
            _.filter(packageEmailTemplates, function(item: PackageEmailTemplate) { return item.name != 'Custom'; });

        const initialState = {
            template: {
                packageEmailCustomTemplateSubject: templateSubject,
                packageEmailCustomTemplateBody: templateBody
            },
            packageEmailTemplates: filteredPackageEmailTemplates,
            onClose: onCloseFn
        };

        this.modalService.show(CustomEmailTemplateModalComponent, { initialState, class: 'modal-lg' });
    }

    openPreviewTemplateModal(template) {
        const initialState = {
            template: template
        };

        this.modalService.show(PreviewEmailTemplateModalComponent, { initialState, class: 'modal-lg' });
    }
}
