import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'entity-import-editing-step',
    templateUrl: './editingStep.component.html'
})
export class EntityImportEditingStepComponent {
    @Input() importFileModel: Compliance.ImportFileModel;
    @Input() selectedRowsInfo$: BehaviorSubject<Compliance.ImportSelectedRowsInfoModel>;
    @Input() busyRefId: string;
}
