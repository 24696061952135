<div class="input-group" aria-label="Select Assessor">
    <input id="selectAssessor"
           type="text"
           placeholder="Filter Assessors..."
           class="form-control exclude-modal-styling"
           [ngClass]="{ 'overridden': isOverridden }"
           autocomplete="off"
           (blur)="onAssessorBlur()"
           [(ngModel)]="assessorFilter"
           container="import-bulk-update"
           [typeahead]="assessors$"
           typeaheadOptionField="name"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onAssessorSelected($event)"
           [typeaheadOptionsLimit]="1000"
           [disabled]="isDisabled"/>
</div>
