import { Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllocationDetailDragAndDropService } from './allocationDetailDragAndDrop.service';
import { AssessmentSummaryInfo } from '../assessmentSummaryInfo';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface DragAndDropCellRendererParams extends ICellRendererParams {
    allocationDetailsDropped: (allocationDetailIds: number[], summary: AssessmentSummaryInfo) => void;
}

@Component({
    selector: 'drag-and-drop-cell-renderer',
    template:   `
        <div *ngIf="isServiceActive && !summary.isLocked && !isTotalRow"
             #dragulaEl
             [attr.dragula-bag-name]="dragulaBagName"
             [attr.dragula-container-type]="dragulaContainerType"
             [dragula]="dragulaBagName">
            <div class="dragging-overlay" [ngClass]="{'dragging-overlay--over': draggingOver}">
                <div class="title">{{ summary.isTaxAuthority ? summary.taxAuthorityName : summary.title }}</div>
            </div>
        </div>
    `,
    host: { class: 'ws-stretch' }
})
export class AllocationDetailDragAndDropCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    constructor(
        private _allocationDetailDragAndDropService: AllocationDetailDragAndDropService
    ) {}

    @ViewChild('dragulaEl') dragulaElRef: ElementRef;

    params: DragAndDropCellRendererParams;
    summary: AssessmentSummaryInfo;

    isServiceActive: boolean;
    isTotalRow: boolean;

    dragulaContainerType: string;
    dragulaBagName: string;

    dragging: boolean = false;
    draggingOver: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    agInit(params: DragAndDropCellRendererParams): void {
        this.dragulaContainerType = this._allocationDetailDragAndDropService.CONTAINER_TYPE_TARGET;
        this.dragulaBagName = this._allocationDetailDragAndDropService.BAG_NAME;

        this.params = params;
        this.summary = params.data as AssessmentSummaryInfo;
        this.isTotalRow = this.summary.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total;

        this._allocationDetailDragAndDropService.dragging$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.dragging = x);

        this._allocationDetailDragAndDropService.draggingOver$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.draggingOver = this.dragulaElRef && (this.dragulaElRef.nativeElement === x));

        this._allocationDetailDragAndDropService.dropped$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => {
                if (!this.dragulaElRef) { return; }
                const droppedOnMe = this.dragulaElRef.nativeElement === x.element;
                if (droppedOnMe) {
                    this.params.allocationDetailsDropped(x.draggedAllocationDetailIds, this.summary);
                }
            });

        this._allocationDetailDragAndDropService.active$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isServiceActive = x);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
