<div class="mega-dropdown-menu tax-menu ws-flex-container-vertical">
    <div class="ws-flex-container-horizontal ws-stretch-width">
        <ul class="ws-stretch-width">
            <ng-container *ngFor="let item of menuItems">
                <li>
                    <a [href]="item.href" target="_blank" (click)="triggerAmplitudeEvent(item.amplitudeProperty)">
                        {{item.name}}&nbsp;<i class="fa fa-external-link"></i>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="logout">
        <a anchor-route [sref]="logout.anchor" (click)="triggerAmplitudeEvent(logout.amplitudeProperty)">
            {{logout.name}}
        </a>
    </div>
    <i class="pointer"></i>
</div>
