import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ResponsibleEntityRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getList(companyId: number, model: Core.ResponsibleEntitySearchModel): Observable<Compliance.QueryResultModel<Core.ResponsibleEntityModel>> {
        return this.httpPost(`api/responsibleEntity/company/${companyId}`, model);
    }

    getRowIds(companyId: number, searchParams: Core.ResponsibleEntitySearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`api/responsibleEntity/company/${companyId}/rowIds`, searchParams);
    }

    startExport(companyId: number, model: Core.ResponsibleEntityExportModel): Observable<number> {
        return this.httpPost(`api/responsibleEntity/company/${companyId}/export`, model);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`api/responsibleEntity/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getTypes(): Observable<Core.ResponsibleEntityTypeModel[]> {
        return this.httpGet(`api/responsibleEntity/types`);
    }

    update(companyId: number, model: Core.ResponsibleEntityUpdateModel): Observable<Core.ResponsibleEntityModel> {
        return this.httpPut(`api/responsibleEntity/company/${companyId}/update`, model);
    }

    create(companyId: number, model: Core.ResponsibleEntityCreateModel): Observable<Core.ResponsibleEntityModel> {
        return this.httpPost(`api/responsibleEntity/company/${companyId}/create`, model);
    }
}
