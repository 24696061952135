import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AgGridModule } from 'ag-grid-angular'
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer } from '../AgGrid/MultiSelectTracker'
import { AssetClassSelectorComponent } from './Asset-Class-Selector/assetClassSelector.component';
import { AssetClassSelectorModule } from './Asset-Class-Selector/assetClassSelector.module';
import { AssetListComponent } from './Asset-List/assetList.component';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component'
import { AssetListGridActionCellRendererComponent } from './Asset-List/agGridActionCellRenderer.component';
import { CostAdjustmentHelpComponent } from './Asset-Details/costAdjustmentHelp.component';
import { AssetInfoService } from './Asset-Details/Asset-Info/assetInfo.service';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AssetClassPickerComponent } from './Asset-Class-Selector/assetClassPicker.component';
import { AssetReturnFilingStatusCellRendererComponent } from './Asset-Details/Return-Filings/agGridStatusCellRenderer.component';
import { AssetBulkUpdateActionComponent } from './Asset-List/Asset-Bulk-Update/assetBulkUpdateAction.component';
import { AssetDescriptorViewComponent } from './Asset-Descriptors/assetDescriptorView.component';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { AssetDescriptorEditComponent } from './Asset-Descriptors/assetDescriptorEdit.component';
import { ComplianceParcelModule } from '../Parcel/parcel.module';
import { AssetLienDateDetailsComponent } from './Asset-Lien-Date-Details/assetLienDateDetails.component';
import { AssetLimitComponent } from './Asset-List/Asset-Limit/assetLimit.component';
import { AssetLimitDetailsComponent } from './Asset-List/Asset-Limit-Details/assetLimitDetails.component';
import { AssetAdvancedComponent } from './Asset-List/Asset-Advanced/assetAdvanced.component';
import { AssetAdvancedDetailsComponent } from './Asset-List/Asset-Advanced-Details/assetAdvancedDetails.component';
import { AssetAdvancedDetailCheckboxComponent } from './Asset-List/Asset-Advanced-Details/assetAdvancedDetailCheckbox.component';
import { AssetChangeIconCellRendererComponent } from './Asset-List/agGridChangeIconCellRenderer.component';
import { AssetChangeIconComponent } from './Asset-List/Asset-Change-Icon/assetChangeIcon.component';
import { AssetDetailsModule } from './Asset-Details/assetDetails.module';
import { AssetBulkUpdateComponent } from './Asset-List/Asset-Bulk-Update/assetBulkUpdate.component';
import { AssetLienDateModule } from './Asset-Lien-Date/assetLienDate.module';
import { CommentsModule } from '../../Comments/comments.module';
import { AssetImportProgressComponent } from './Asset-List/Asset-Import-Progress/assetImportProgress.component';
import { AssetImportProgressService } from './Asset-List/Asset-Import-Progress/assetImportProgress.service';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TypeaheadModule,
        TooltipModule,
        BsDropdownModule,
        WeissmanSharedModule,
        SharedPipesModule,
        CurrencyMaskModule,
        CommentsModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            // asset list
            AssetListGridActionCellRendererComponent,
            // asset return filings list
            AssetReturnFilingStatusCellRendererComponent
        ]),
        ComplianceParcelModule,
        AssetDetailsModule,
        AssetLienDateModule,
        AssetClassSelectorModule
    ],
    declarations: [
        AssetAdvancedComponent,
        AssetAdvancedDetailsComponent,
        AssetAdvancedDetailCheckboxComponent,
        AssetListGridActionCellRendererComponent,
        AssetListComponent,
        CostAdjustmentHelpComponent,
        AssetBulkUpdateComponent,
        AssetClassPickerComponent,
        AssetBulkUpdateActionComponent,
        AssetDescriptorViewComponent,
        AssetDescriptorEditComponent,
        AssetLienDateDetailsComponent,
        AssetLimitComponent,
        AssetLimitDetailsComponent,
        AssetChangeIconComponent,
        AssetChangeIconCellRendererComponent,
        AssetImportProgressComponent
    ],
    providers: [
        AssetInfoService,
        AssetImportProgressService
    ],
    exports: [
        AssetLienDateDetailsComponent,
        AssetBulkUpdateActionComponent,
        AssetListComponent
    ]
})
export class AssetModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('assetList', AssetListComponent);
    }
}
