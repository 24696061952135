import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormService } from '../form.service';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'form-year-new-modal',
    templateUrl: './formYearNewModal.component.html',
    styleUrls: ['./formYearNewModal.component.scss']
})
export class FormYearNewModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, number> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formService: FormService) {
    }

    params: void;
    result: number;

    taxYear: number;

    get minTaxYear(): number {
        return this._formService.minFormRevisionYear.taxYear;
    }

    get maxTaxYear(): number {
        return this._formService.maxFormRevisionYear.taxYear;
    }

    get priorTaxYear(): number {
        return this.minTaxYear - 1;
    }

    get nextTaxYear(): number {
        return this.maxTaxYear + 1;
    }

    get isTaxYearIndeterminate(): boolean {
        return this.minTaxYear === this.maxTaxYear;
    }

    get isFormTypeAppeal(): boolean {
        return this._formService.isFormTypeAppeal;
    }

    get isFormTypeCompliance(): boolean {
        return this._formService.isFormTypeCompliance;
    }

    ngOnInit(): void {
        if (!this._formService.taxYear) {
            this._bsModalRef.hide();
            return;
        }

        // if the tax year can't be determined, default to next
        if(this.isTaxYearIndeterminate) {
            this.taxYear = this.nextTaxYear;
        }

        // if the current year is the minimum, create prior tax year
        if (this._formService.taxYear === this.minTaxYear) {
            this.taxYear = this.priorTaxYear;
        }

        // if the current year is the maximum, create next tax year
        if (this._formService.taxYear === this.maxTaxYear) {
            this.taxYear = this.nextTaxYear;
        }
    }

    ngOnDestroy(): void {
    }

    async save(): Promise<void> {
        this.result = this.taxYear;

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
