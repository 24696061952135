import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash'
import { TypeaheadOrder } from 'ngx-bootstrap/typeahead';

@Component({
    selector: "accruals-descriptor-selector",
    template: `
        <input type="text"
            [(ngModel)]="descriptorName"
            [typeahead]="inUseDescriptors"
            [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="10"
            (typeaheadOnSelect)="descriptorSelected($event.item)"
            [typeaheadMinLength]="0"
            typeaheadOptionField="descriptorName"
            typeaheadGroupField="categoryName"
            [placeholder]="placeholder"
            [typeaheadOptionsLimit]="200"
            [disabled]="disabled"
            [typeaheadOrderBy]="sortConfig"
            class="form-control">
        `
})
export class AccrualsDescriptorSelectorComponent implements OnChanges {
    @Input() inUseDescriptors: Core.CompanyAccrualDescriptorModel[];
    @Input() selectedDescriptorId: number;
    @Input() placeholder: string = '';
    @Input() disabled: boolean;
    @Output() onDescriptorSelected: EventEmitter<Core.CompanyAccrualDescriptorModel> = new EventEmitter<Core.CompanyAccrualDescriptorModel>();
    @Output() selectedDescriptorIdChange: EventEmitter<number> = new EventEmitter<number>();

    descriptorName: string = '';
    sortConfig: TypeaheadOrder = {
        direction: 'asc',
        field: 'categoryName'
    };

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.selectedDescriptorId) {
            if(changes.selectedDescriptorId.currentValue) {
                const selectedDescriptor = _.find(this.inUseDescriptors || [], {descriptorId: this.selectedDescriptorId});
                this.descriptorName = selectedDescriptor ? selectedDescriptor.descriptorName : '';
            } else {
                this.descriptorName = '';
            }
        }
    }

    descriptorSelected(descriptor: Core.CompanyAccrualDescriptorModel) {
        if(!this.selectedDescriptorId) {
            this.descriptorName = '';
        }

        this.onDescriptorSelected.emit(descriptor);
        this.selectedDescriptorIdChange.emit(descriptor.descriptorId)
    }
}
