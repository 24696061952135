<div class="card panel-flat-color flex-panel secondary-panel">
    <div class="panel-flat-header" style="margin-bottom: 0;">
        <div class="header-icon"><i class="fa fa-columns"></i></div>
        <h3>Output</h3>
    </div>
    <div class="panel-flat-body">
        <p class="text-muted lead text-center add-text fs-2" *ngIf="!outputColumns.length" style="margin-top: 75px;">
            <em>Add Output Columns...</em>
        </p>
        <div class="criteria-list ws-stretch-height">
            <ul class="list-group as-output-list"
                ws-dnd-list
                [dndList]="outputColumns"
                [dndListItemIdGetter]="getColumnUniqueId">
                <li class="list-group-item"
                    *ngFor="let column of outputColumns; let index = index"
                    (mouseenter)="column.showClose = true"
                    (mouseleave)="column.showClose = false"
                    ws-dnd-draggable
                    [dndDraggable]="column"
                    (dndMoved)="columnMoved()"
                    dndEffectAllowed="move">
                    <span class="float-start as-output-name">{{column.categoryName}} - {{column.displayName}}</span>
                    <i class="fa fa-close float-end" *ngIf="column.showClose" (click)="removeOutput(column)"></i>
                    <span class="clearfix"></span>
                </li>
            </ul>
        </div>
    </div>
    <div class="panel-flat-footer">
        <div class="row ws-flex-container-vertical ws-flex-align-items-center px-15">
            <column-picker class="ws-stretch-width"
                           (columnsSelected)="addOutputs($event)"
                           [existingColumns]="$any(outputColumns)"
                           [disableColumnsAfterLoad]="true"
                           cpMode="smart">
            </column-picker>
            <div class="checkbox text-end mb-0 ws-flex-align-self-right" *ngIf="hasRyanPrivatePermission && areBatchFields()">
                <label>
                    <input type="checkbox"
                           [(ngModel)]="groupByBatches"
                           [disabled]="loadSaveSettings"
                           (ngModelChange)="persistSetting('click-group-by-batches')">
                    <span>Group by batches (when possible)</span>
                </label>
            </div>
        </div>
    </div>
</div>
