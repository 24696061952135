<div class="has-feedback" style="margin-bottom: 0;">
    <input type="text"
           [(ngModel)]="companyFilter"
           placeholder="Choose Company"
           class="form-control"
           container="body"
           [typeahead]="companies$"
           (blur)="onBlur()"
           typeaheadOptionField="companyName"
           (typeaheadLoading)="typeaheadLoading($event)"
           (typeaheadNoResults)="onNoResults($event)"
           (typeaheadOnSelect)="companySelected($event)"
           [typeaheadMinLength]="2"
           [typeaheadScrollable]="true"
           [typeaheadWaitMs]="500"
           [typeaheadOptionsLimit]="1000"
           (change)="checkForBlank()"/>
    <loading-spinner [show]="loadingCompanies" class="form-control-feedback" [size]="1" [marginTop]="0"></loading-spinner>
</div>
