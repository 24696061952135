import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BatchDocument } from './document.intake.component';
import { BaseRepository } from '../../Common/Data/base.repository';

const baseUrl = '/api/documentintake';

@Injectable()
export class DocumentIntakeService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    sendBatchDocuments(options: BatchDocument, files: File[]): Promise<any> {
        const formData = new FormData();

        files.forEach(file => formData.append('file', file));
        formData.append('intakeInfo', encodeURIComponent(JSON.stringify(options)));
        const params = {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        };

        return lastValueFrom(this.httpPost(`${baseUrl}/batch`, formData, params));
    }
}
