import { Injectable } from '@angular/core';
import { IExpandableComponentContainer } from '../../UI-Lib/Expandable-Component/expandableComponentContainer.model';
import { IExpandableComponent } from '../../UI-Lib/Expandable-Component/expandableComponent.model';
import { Site, SiteSummary } from './Site.Model';
import { ParcelList, ParcelListControlRow } from './Models/parcelList';
import { ParcelService } from '../Parcel/parcel.service.upgrade';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SiteRepository } from '../../Core-Repositories';

@Injectable()
export class SiteService implements IExpandableComponentContainer {
    constructor(
        private readonly _siteRepository: SiteRepository,
        private readonly _parcelService: ParcelService
    ) { }

    private _site: Site;
    private _latestTaxYearLabel: string = 'Latest';
    private _currentTaxYear: number = 0;
    private _expandedComponent: IExpandableComponent = null;
    private _parcelCount: number;
    private _stateId: number;

    private _parcelListSubject: BehaviorSubject<ParcelList> = new BehaviorSubject(null);
    parcelList$: Observable<ParcelList> = this._parcelListSubject.asObservable();

    private _siteSubject: BehaviorSubject<Site> = new BehaviorSubject(null);
    site$: Observable<Site> = this._siteSubject.asObservable();

    set site(site: Site) {
        this._site = site;
        this._siteSubject.next(site);
    }

    get site(): Site { return this._site; }

    memoryStorage = {
        siteClassSecondaryID: null,
        stateID: null
    }

    // AngularJS downgrade does not provide this as an instance service so a reset is required when leaving the page
    reset(): void {
        this.site = null;
        this._parcelListSubject.next(null);
    }

    setParcelCount(newCount: number): void {
        this._parcelCount = newCount;
    }

    getParcelCount(): number {
        if (!this._parcelCount) {
            this._parcelCount = this._parcelService.parcelCount;
        }
        return this._parcelCount;
    }

    setExpandedComponent(component: IExpandableComponent): void {
        this._expandedComponent = component;
    }

    isComponentExpanded(component: IExpandableComponent): boolean {
        return this._expandedComponent === component;
    }

    isExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() === componentName);
    }

    isOtherComponentExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() !== componentName);
    }

    post(parcel: Core.ParcelModel): Promise<Core.ParcelTileDTO> {
        return lastValueFrom(this._siteRepository.post(parcel));
    }

    async update(site, companyId): Promise<Site> {
        this.site = await lastValueFrom(this._siteRepository.update(site, companyId));
        return this.site;
    }

    getReportingParcelBySiteId(siteId: number): Promise<Core.ParcelModel> {
        return lastValueFrom(this._siteRepository.getReportingParcelBySiteId(siteId));
    };

    async getNavigationInfo(siteId: number): Promise<any> {
        return await lastValueFrom(this._siteRepository.getNavigationInfo(siteId));
    }

    hasParcels(): boolean {
        // If it hasn't been set, assume it has them. Prevents changing site state value when parcels are unknown
        return this._parcelCount > 0 || this._parcelCount === undefined;
    }

    async updateParcelList(year?: number | string): Promise<void> {
        if (year) {
            this._currentTaxYear = (year === this._latestTaxYearLabel || !year) ? 0 : +year;
        }
        const parcels = await this._parcelService.getParcelsInfoForSingleYear(this.site.siteID, this._currentTaxYear);
        const parcelList = new ParcelList((parcels) ? parcels as Core.ParcelTileDTO[] : []);

        if (this._currentTaxYear === 0) {
            this._parcelCount = parcels.length;
        }

        // Check and set the reporting parcel
        const result = await this._setReportingParcel(parcelList);
        this._parcelListSubject.next(result);
        return;
    }

    private async _setReportingParcel(parcelList: ParcelList): Promise<ParcelList> {
        // Need to ensure site exists before dereferencing.
        // If updateParcelList() is invoked, and the parcelListPanel is destroyed
        // (due to reload) before this method is called, site can be null.
        if (this.site && this.site.ppReturnPreparationAllowed) {
            const reportingParcel = await this.getReportingParcelBySiteId(this.site.siteID);
            if (reportingParcel) {
                const currentReportingParcel = parcelList.tiles.find(parcel => parcel.parcelID === reportingParcel.parcelID);
                if (currentReportingParcel) {
                    currentReportingParcel.isReportingParcel = true;
                    currentReportingParcel.originalIsReportingParcel = true;
                } else if (this.site.siteID !== reportingParcel.siteID) {
                    const externalReportingParcel = this._getParcelControlRow(reportingParcel);
                    parcelList.tiles.push(externalReportingParcel);
                    parcelList.tiles.sort((i1, i2) => {
                        return i1.acctNum.localeCompare(i2.acctNum);
                    });
                }
            }
        }

        return parcelList;
    }

    private _getParcelControlRow(reportingParcel: Core.ParcelModel): ParcelListControlRow {
        return {
            acctNum: reportingParcel.acctNum,
            activityStatusID: reportingParcel.activityStatusID,
            address: null,
            altAcctNum: null,
            altParcel2: null,
            annualYear: null,
            assesseeName: null,
            assessorAbbr: null,
            calcProjected: null,
            companyID: null,
            components: null,
            description: reportingParcel.description,
            fmvChange: null,
            isFmvInclude: false,
            isLinked: false,
            isMasterParcel: false,
            parcelID: reportingParcel.parcelID,
            propTypeAbbr: 'PP',
            propertyTypeId: reportingParcel.propertyTypeID,
            siteID: reportingParcel.siteID,
            status: null,
            taxIsEstimated: false,
            taxPercentDiff: null,
            totalFMV: null,
            totalTaxes: null,
            isExternalReportingParcel: true,
            isReportingParcel: true,
            originalIsReportingParcel: true,
            consolidatedParcelId: null,
            originalConsolidatedParcelId: null,
            consolidatingTypeId: null,
            originalConsolidatingTypeId: null,
            isTotalRow: false,
            returnCount: 1
        } as ParcelListControlRow;
    }

    get(): Site {
        return this._site;
    }

    setStateId(newId: number): void {
        this._stateId = newId;
    }

    getStateId(): number {
        return this._stateId;
    }

    async load(siteId: number, companyId: number, noCache?: boolean, navigationRequest?: boolean): Promise<Site> {
        const site = await lastValueFrom(this._siteRepository.loadSite(siteId, companyId, navigationRequest));

        if (!noCache) {
            this._site = site;
        }

        return site;
    }

    getCompanyId(siteId: number): Promise<number> {
        return lastValueFrom(this._siteRepository.getCompanyId(siteId))
    }

    getSiteName(siteId: number): Promise<string> {
        return lastValueFrom(this._siteRepository.getSiteName(siteId))
    }

    loadByCompanyId(companyId: number): Promise<Site[]> {
        return lastValueFrom(this._siteRepository.loadByCompanyId(companyId));
    }

    getParcelIdsBySiteId(siteId: number): Promise<any> {
        return lastValueFrom(this._siteRepository.getParcelIdsBySiteId(siteId));
    }

    updateSite(site: Site, companyId: number): Promise<any> {
        return lastValueFrom(this._siteRepository.updateSite(site, companyId));
    }

    deleteSite(siteId: number): Promise<any> {
        return lastValueFrom(this._siteRepository.deleteSite(siteId));
    }

    add(site: Site): Promise<any> {
        return lastValueFrom(this._siteRepository.addSite(site));
    }

    getParcelsBySiteId(siteId: number): Promise<any[]> {
        return lastValueFrom(this._siteRepository.getParcelsForSiteId(siteId));
    }

    getSiteListByCompanyId(companyId: number, excludeInactive: boolean): Promise<SiteSummary[]> {
        return lastValueFrom(this._siteRepository.getSiteListByCompanyId(companyId, excludeInactive));
    }

    searchSites(companyId: number, searchModel: Core.SiteSearchModel): Promise<Core.SiteModel[]> {
        return lastValueFrom(this._siteRepository.searchSites(companyId, searchModel));
    }
}
