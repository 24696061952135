import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { GLAccountRepository } from '../../Repositories';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';

export class GLAccountListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        private _gridApi: GridApi,
        private _glAccountRepository: GLAccountRepository,
        private _companyId: number
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.GLAccountPropertyEnum>[] = [
        { name: 'accountName', value: Compliance.GLAccountPropertyEnum.AccountName },
        { name: 'accountNumber', value: Compliance.GLAccountPropertyEnum.AccountNumber },
        { name: 'description', value: Compliance.GLAccountPropertyEnum.Description },
        { name: 'assetClassification', value: Compliance.GLAccountPropertyEnum.AssetClassification },
        { name: 'accountTypeName', value: Compliance.GLAccountPropertyEnum.AccountType },
        { name: 'foundInAssets', value: Compliance.GLAccountPropertyEnum.FoundInAssets },
        { name: 'incomeStatementCategoryName', value: Compliance.GLAccountPropertyEnum.IncomeStatementCategoryName },
        { name: 'foundInAccruals', value: Compliance.GLAccountPropertyEnum.FoundInAccruals },
        { name: 'foundInIncomeStatements', value: Compliance.GLAccountPropertyEnum.FoundInIncomeStatements },
        { name: 'createDate', value: Compliance.GLAccountPropertyEnum.CreateDate },
        { name: 'validatedBy', value: Compliance.GLAccountPropertyEnum.ValidatedBy },
        { name: 'validatedDate', value: Compliance.GLAccountPropertyEnum.ValidatedDate }
    ];

    protected canGetRows(): boolean {
        return !!this._companyId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.SearchModel<Compliance.GLAccountPropertyEnum> = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._glAccountRepository.getList(this._companyId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.SearchModel<Compliance.GLAccountPropertyEnum> = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        return await lastValueFrom(this._glAccountRepository.getRowIds(this._companyId, searchParams));
    }

    getSearchModelWithoutPagination(): Core.SearchModel<Compliance.GLAccountPropertyEnum> {
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };
    }
}
