import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { YearColumnType } from './agGridForecastBudgetYearCellRender.component';
import { Decimal } from 'decimal.js';

export interface ForecastBudgetYearCellEditorParams extends ICellEditorParams {
    onValueBlur: (params: ForecastBudgetYearCellEditorParams, value: number) => Promise<void>;
    year: number;
    yearColumnType: number;
}

@Component({
    selector: 'forecast-budget-year-cell-edit',
    template: `
    <div *ngIf="params.data">
        <input *ngIf="params.yearColumnType === YearColumnType.Fmv || params.yearColumnType === YearColumnType.Fmv_A" style="text-align: right;" #inputBox type="number" [(ngModel)]="value">
        <input *ngIf="params.yearColumnType === YearColumnType.FmvChange || params.yearColumnType === YearColumnType.FmvChange_A" style="text-align: right;" #inputBox type="text" [(ngModel)]="value">
    </div>
`
})
export class ForecastBudgetYearCellEditorComponent implements ICellEditorAngularComp {
    @ViewChild('inputBox') inputBox: ElementRef;

    params: ForecastBudgetYearCellEditorParams;
    value: string | number;
    originalValue: string | number;
    YearColumnType = YearColumnType;

    agInit(params: ForecastBudgetYearCellEditorParams): void {
        this.params = params;

        this.value = this.originalValue = this.getEditableValue();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    afterGuiAttached(): void {
        if (this.inputBox) {
            this.inputBox.nativeElement.focus();
        }
    }

    getValue(): number | string {
        return this.value;
    }

    getEditableValue(): number | string {
        const assessment = _.find(this.params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: this.params.year});

        switch(this.params.yearColumnType) {
            case YearColumnType.Fmv:
                return assessment.totalFMV;
            case YearColumnType.FmvChange:
                return new Decimal(assessment.yoyPctChg).times(100).toFixed(3);
            case YearColumnType.Fmv_A:
                return assessment.altFMV;
            case YearColumnType.FmvChange_A:
                return new Decimal(assessment.altYoyPctChg).times(100).toFixed(3);
        }
    }

    isCancelAfterEnd() {
        if(this._isInvalidNumber(this.value) || this.value === this.originalValue) {
            return true;
        }

        if(this.params.yearColumnType == YearColumnType.FmvChange || this.params.yearColumnType == YearColumnType.FmvChange_A) {
            this.params.onValueBlur(this.params, new Decimal(this.value).dividedBy(100).toNumber());
        } else {
            this.params.onValueBlur(this.params, this.value as number);
        }

        return true;

    }

    private _isInvalidNumber(value: string | number): boolean {
        return value === null || value === undefined || value === '' || value === '.';
    }
}
