import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { FormRepository } from '../../Repositories';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'export-form-list',
    templateUrl: './exportFormList.component.html'
    }
)
export class ExportFormListComponent implements IWeissmanModalComponent<void, number>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formRepository: FormRepository) {
    }

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    isInitialized: boolean = false;
    fileTypes: Compliance.NameValuePair<Compliance.FormTypeEnum>[] = [
        { name: 'All', value: 0 },
        { name: 'Appeals', value: Compliance.FormTypeEnum.Appeal },
        { name: 'Compliance', value: Compliance.FormTypeEnum.Compliance }
    ];
    statuses: Compliance.NameValuePair<Compliance.ExportFormStatusEnum>[] = [
        { name: 'All', value: Compliance.ExportFormStatusEnum.All },
        { name: 'Certified', value: Compliance.ExportFormStatusEnum.Certified },
        { name: 'Not-Certified', value: Compliance.ExportFormStatusEnum.NotCertified }
    ];
    taxYears: number[] = [];
    status: Compliance.ExportFormStatusEnum = Compliance.ExportFormStatusEnum.All;
    taxYear: number;
    params: void;
    result: number;
    fileType: Compliance.FormTypeEnum = 0;

    ngOnInit(): void {
        this.taxYear = new Date().getFullYear();

        for (let i = 2; i >= -2; i--) {
            this.taxYears.push(this.taxYear + i);
        }

        this.isInitialized = true;
    }

    async export(): Promise<void> {
        const message = "Exporting Form List";
        this.busyIndicatorMessageManager.add(message, message);

        try{
            const model: Compliance.ExportFormListModel = {
                taxYear: this.taxYear,
                status: this.status,
                formType: this.fileType
            };

            this.result = await lastValueFrom(this._formRepository.exportFormList(model));

            this._bsModalRef.hide();
            return Promise.resolve();
        }
        finally {
            this.busyIndicatorMessageManager.remove(message);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
