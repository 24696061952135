import { Component, Input, OnInit } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { SDHttpService } from "../Routing/sd-http.service";
import { UserTeamMember } from "../Models/common.model";
import { InstanceRights, RestrictService, Roles } from '../Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from "../Routing/upgrade-navigation-handler.service";
import { Constants, RyanInstanceId } from '../../constants.new';
import { InstanceRepository } from "../../Entity/Instance/instance.repository";
import { UserInstanceService } from '../../User/userInstance.service';

export class EntityInProgressIntakeData {
    entityID: number;
    entityTypID: number;
    intakeItemID: number;
    batchNumber: string;
    itemNumber: string;
    exceptionStatusID: number;
    duplicateNumber: number;
    assignedUser: UserTeamMember;
}

@Component({
    selector: 'entity-in-progress-indicator',
    template: `
        <i [hidden]="!hasPermission" class="fa fa-exclamation-triangle fa-lg intake-indicator"
            [popover]="indicatorPopover"
            container="body"
            triggers="mouseenter"
            (onShown)="onIndicatorHover()"
            [outsideClick]="true"
            #pop="bs-popover"></i>

        <ng-template #indicatorPopover>
            <div (mouseleave)="pop.hide()">
                <i *ngIf="!intakeItems.length" class="fa fa-spin fa-spinner fa-lg"></i>
                <div *ngIf="intakeItems.length">
                    <div style="padding:0.5em 0" *ngFor="let item of intakeItems">
                        <span *ngIf="!isDocumentProcessing">
                            <a [href]="getProcessingUrl(item.intakeItemID)">
                                {{item.batchNumber}}#{{item.itemNumber}}<span *ngIf="item.duplicateNumber">_{{item.duplicateNumber}}</span>
                            </a>
                        </span>
                        <span *ngIf="isDocumentProcessing">
                            {{item.batchNumber}}#{{item.itemNumber}}<span *ngIf="item.duplicateNumber">_{{item.duplicateNumber}}</span>
                        </span>
                        <span> - {{item.assignedUser.lastName}}, {{item.assignedUser.firstName}}</span>
                        <span style="color:red" *ngIf="item.exceptionStatusID">{{constants.ProcessingExceptionStatusText[item.exceptionStatusID]}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    `
})
export class EntityInProgressIndicatorComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityTypeId: number;
    @Input() isDocumentProcessing: boolean = false;

    hasPermission: boolean = false;
    intakeItems: EntityInProgressIntakeData[] = [];

    constructor(
        private readonly _http: SDHttpService,
        private readonly _restrictService: RestrictService,
        private readonly _navigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _instanceRepository: InstanceRepository,
        public constants: Constants,
        private readonly _userInstanceService: UserInstanceService) {
     }

     async ngOnInit() {
        const instanceId = await lastValueFrom(this._instanceRepository.getEntityIdPairInstanceId(this.entityTypeId, this.entityId));
        this.hasPermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, instanceId) ||
            this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, instanceId);

        if (!this.hasPermission && instanceId !== RyanInstanceId && this._userInstanceService.isCurrentInstanceRyan() &&
            this._restrictService.isInRole(Roles.LICENSEDDOCUMENTSERVICES)) {
            this.hasPermission = true;
        }
     }

    onIndicatorHover(): void {
        let config = {
            params: {
                entityID: this.entityId,
                entityTypeID: this.entityTypeId
            }
        };

        this._http.get('/api/processing/document/entityInProgressIntakeData', config)
            .then((result: EntityInProgressIntakeData[]) => {
                this.intakeItems = result;
            });
    }

    getProcessingUrl(intakeItemID: number): string {
        let parcelId = this._navigationServiceHandler.getQuerystringParam('parcelId');

        return this._navigationServiceHandler.getHrefFromState('documentProcessing', { parcelID: parcelId, intakeItemID: intakeItemID});
    }
}
