<carousel [(activeSlide)]="currentTransmittalIndex" [interval]="0" [noWrap]="true" class="transmittal-details" (activeSlideChange)="slideChanged($event)" [showIndicators]="false">
  <slide *ngFor="let transmittal of selectedPackage.paymentTransmittals; let slideIndex = index">
    <div style="width: 90%; margin: auto;" *ngIf="slideIndex === currentTransmittalIndex">
      <div class="text-center" style="margin-top: 15px;">
        <button class="flat-button primary-button float-start" (click)="backClickedUI()">
          <i class="fa fa-arrow-left"></i>{{isFromPaymentBatch ? 'Batch to Payment Batch' : 'Back to Summary' }}</button>
        <h1 style="display: inline-block; margin: 0;">
            Package #: {{ selectedPackage.transmittalType }}
            <span *ngIf="selectedPackage.completedDateTime">-</span>
            <span *ngIf="!selectedPackage.completedDateTime">Draft</span>
            {{ selectedPackage.packageNumber }}
        </h1>
        <div class="float-end">
          <label>Transmittal:</label>
          <select class="form-select form-select-sm" style="width: auto; display: inline-block; margin-left: 10px;" [(ngModel)]="selectedTransmittalID" (ngModelChange)="goToTransmittal()">
            <option *ngFor="let transmittal of selectedPackage.paymentTransmittals" [value]="transmittal.paymentTransmittalID">{{transmittal.title}}</option>
          </select>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="row" style="margin-top: 30px;">
        <div class="col-md-6 offset-md-1 offset-lg-2 col-lg-5 col-xl-4">
          <label>Collector</label>
          <div class="transmittal-address data-text-field" style="display: flex; justify-content: space-between;">
            <address>
              {{transmittal.collectorName}}
              <br> {{transmittal.collectorAddress.address1}}
              <br> {{transmittal.collectorAddress.address2}}
              <br *ngIf="transmittal.collectorAddress.address2"> {{transmittal.collectorAddress.city}}<span *ngIf="transmittal.collectorAddress.city">,</span> {{transmittal.collectorAddress.abbr}} {{transmittal.collectorAddress.zip}}
            </address>
            <button class="flat-button primary-button icon-button" *ngIf="collectorAddresses && collectorAddresses.length > 1" (click)="editCollectorAddress()">
              <i class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 col-xl-4">
          <label>Responsible Party</label>
          <div class="transmittal-address">
            <address *ngIf="recipients[RecipientType.ResponsibleParty] && recipients[RecipientType.ResponsibleParty][0]">
              {{recipients[RecipientType.ResponsibleParty][0].companyName}}
              <br> {{recipients[RecipientType.ResponsibleParty][0].fullName}}
              <br> {{recipients[RecipientType.ResponsibleParty][0].title}}
              <div *ngIf="recipients[RecipientType.ResponsibleParty][0].address">
                {{recipients[RecipientType.ResponsibleParty][0].address.address1}}
                <br> {{recipients[RecipientType.ResponsibleParty][0].address.address2}}
                <br *ngIf="recipients[RecipientType.ResponsibleParty][0].address.address2"> {{recipients[RecipientType.ResponsibleParty][0].address.city}}<span *ngIf="recipients[RecipientType.ResponsibleParty][0].address.city">,</span> {{recipients[RecipientType.ResponsibleParty][0].address.abbr}} {{recipients[RecipientType.ResponsibleParty][0].address.zip}}
              </div>
            </address>
          </div>
          <div style="margin-top: 8px;">
            <span *ngIf="recipients[RecipientType.Cc]">
              <label>Cc:</label>
              <span *ngFor="let cc of recipients[RecipientType.Cc]; let i = index;">{{cc.fullName}}<span *ngIf="i !== (recipients[RecipientType.Cc].length - 1)">; </span></span>
            </span>
            <span *ngIf="recipients[RecipientType.Bcc]">
              <label>Bcc:</label>
              <span *ngFor="let bcc of recipients[RecipientType.Bcc]; let i = index;">{{bcc.fullName}}<span *ngIf="i !== (recipients[RecipientType.Bcc].length - 1)">; </span></span>
            </span>
          </div>
        </div>
      </div>

      <div class="transmittal-details-items"
            [style.height]="isModal ? 'auto' : '400px'"
            [style.max-height]="isModal ? '400px;' : 'none'">
        <table class="table">
          <thead>
            <tr>
              <th>Site Name</th>
              <th>Parcel # / Collector #</th>
              <th>Property Type</th>
              <th class="text-end">Amount</th>
              <th>Intake Item</th>
              <th class="text-center">Bill Image</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of transmittal.paymentTransmittalItems">
              <td>{{item.siteName}}</td>
              <td>{{item.acctNum}}<span *ngIf="item.collectorAcctNum"> / {{item.collectorAcctNum}}</span></td>
              <td>{{Constants.PropertyTypeNames[item.propertyType]}}</td>
              <td class="text-end" style="width: 1px;">{{item.amount | currency:'USD'}}</td>
              <td>
                  {{item.intakeBatchNumber}}<span *ngIf="item.intakeItemNumber">#{{item.intakeItemNumber}}</span>
              </td>
              <td class="text-center">
                  <div class="btn-group" *ngIf="item.attachmentID" dropdown>
                      <button type="button" class="flat-button small-button secondary-button" (click)="previewBill(item)" style="margin: 0">Pop-out</button><!--
                      --><button type="button" dropdownToggle class="flat-button small-button secondary-button dropdown-toggle dropdown-toggle-split" style="margin: 0">
                        <span class="caret"></span>
                      </button>
                      <ul *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu">
                        <li role="menuitem">
                          <a class="dropdown-item btn btn-link btn-sm" (click)="downloadBill(item)">
                            Download File
                          </a>
                        </li>
                      </ul>
                    </div>
              </td>
              <td>
                <i *ngIf="transmittal.paymentTransmittalItems.length > 1 && !readOnly && !selectedPackage.completedDateTime && !selectedPackage.lockedForOverrideEmail" class="fa fa-close" (click)="removeItem(transmittal, item)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3">
            <label>Note:</label>
            <textarea
                class="form-control"
                rows="3"
                maxlength="120"
                [(ngModel)]="transmittal.note"
                [disabled]="readOnly || !!selectedPackage.completedDateTime || !!selectedPackage.lockedForOverrideEmail"
                (keydown)="preventCarouselFromInterceptingSpace($event)"></textarea>
          </div>
        </div>
        <div class="col-md-6">
          <table class="transmittal-data">
            <tr>
              <td>Total Amount:</td>
              <td>{{getTotalAmount(transmittal.paymentTransmittalItems) | currency:'USD'}}</td>
            </tr>
            <tr>
              <td>Payment Due:</td>
              <td>
                <weissman-datetime [(inputDate)]="transmittal.dueDate" [readOnly]="true" [dateOnly]="true">
                </weissman-datetime>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </slide>
</carousel>
