import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class ImportSpecificationRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getListByUser(): Observable<Compliance.ImportFileSpecificationModel[]> {
        return this.httpGet(`/api/importSpecification/user`);
    }

    getListByCompany(companyId: number): Observable<Compliance.ImportFileSpecificationModel[]> {
        return this.httpGet(`/api/importSpecification/company/${companyId}`);
    }

    getListShared(): Observable<Compliance.ImportFileSpecificationModel[]> {
        return this.httpGet(`/api/importSpecification/shared`);
    }

    createOrUpdateFromImport(updateModel: Compliance.ImportFileSpecificationImportUpdateModel): Observable<Compliance.ImportFileSpecificationModel> {
        return this.httpPut(`/api/importSpecification/import/${updateModel.importFileId}`, updateModel);
    }

    update(updateModel: Compliance.ImportFileSpecificationUpdateModel): Observable<Compliance.ImportFileSpecificationModel> {
        return this.httpPut(`/api/importSpecification/${updateModel.importFileSpecificationId}`, updateModel);
    }

    getList(searchModel: Compliance.ImportFileSpecificationSearchModel): Observable<Compliance.QueryResultModel<Compliance.ImportFileSpecificationModel>> {
        return this.httpPost(`/api/importSpecification`, searchModel);
    }

    assignStaticField(importId: number, fieldId: string, value: string, clarificationValues: Compliance.NameValuePair<string>[]): Observable<Compliance.ImportFileSpecificationImportFieldModel> {
        const data: Compliance.ImportFileSpecificationImportFieldModel = {
            importFileId: importId,
            importFieldId: fieldId,
            importFileSpecificationFieldId: 0,
            value: value,
            isStatic: true,
            clarificationValues: clarificationValues
        };
        return this.httpPost(`/api/importSpecification/field`, data);
    }

    assignField(importId: number, fieldId: string, headerIndex: number, clarificationValues: Compliance.NameValuePair<string>[]): Observable<Compliance.ImportFileSpecificationImportFieldModel> {
        const data: Compliance.ImportFileSpecificationImportFieldModel = {
            importFileId: importId,
            importFieldId: fieldId,
            importFileSpecificationFieldId: 0,
            value: headerIndex.toString(),
            isStatic: false,
            clarificationValues: clarificationValues
        };
        return this.httpPost(`/api/importSpecification/field`, data);
    }

    unAssignField(specificationFieldId: number): Observable<void> {
        return this.httpDelete(`/api/importSpecification/field/${specificationFieldId}`);
    }

    get(specificationId: number): Observable<Compliance.ImportFileSpecificationModel> {
        return this.httpGet(`/api/importSpecification/${specificationId}`);
    }

    getDynamicFieldBehaviorOptions(): Observable<Compliance.ImportDynamicFieldOptionModel[]> {
        return this.httpGet(`/api/importSpecification/getDynamicFieldBehaviorOptions`);
    }

    updateImportPropertyCharacteristicBehavior(importId: number, optionId: Compliance.ImportDynamicFieldOptionEnum): Observable<void> {
        const model = {
            importId: importId,
            optionId: optionId
        } as Compliance.ImportPropertyCharacteristicBehaviorUpdateModel;

        return this.httpPost(`/api/importSpecification/updateImportPropertyCharacteristicBehavior`, model);
    }

    updateImportFieldPropertyCharacteristicBehavior(model: Compliance.ImportFieldPropertyCharacteristicBehaviorUpdateModel): Observable<System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[]> {
        return this.httpPost(`/api/importSpecification/updateImportFieldPropertyCharacteristicBehavior`, model);
    }
}
