export enum AssetDescriptorFieldTypes {
    Text = Core.DescriptorFieldTypes.Text as number,
    Number = Core.DescriptorFieldTypes.Number as number,
    Date = Core.DescriptorFieldTypes.Date as number,
    YesNo = Core.DescriptorFieldTypes.YesNo as number,
    Currency = Core.DescriptorFieldTypes.Currency as number,
    Picklist = Core.DescriptorFieldTypes.Picklist as number
}

export enum LienDateTypes {
    Prior = Compliance.AssetLienDateTypeEnum.Prior as number,
    Exact = Compliance.AssetLienDateTypeEnum.Exact as number
}
