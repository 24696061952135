import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridColumns, AgGridDropdownCellEditor, AgGridFilterParams, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { DropdownCellEditorParams } from '../../../Compliance/AgGrid/CellEditors/agGridDropdownCellEditor.component';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../Busy-Indicator';
import { CompanySitePermissionsAgGridDataSource, CompanySitePermissionsDataSourceParams } from './companySitePermissionsAgGridDataSource';
import { PermissionService } from '../../permission.service';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import * as _ from 'lodash';
export interface CompanySitePermissionsParams {
    companyId: number;
    userId?: System.Guid;
    groupId?: number;
    editMode: boolean;
    saveRequest: Core.PermissionSaveRequest;
}

enum RightsDisplay {
    View = 'View',
    Edit = 'Edit',
    Hide = 'Hide'
}

@Component({
    selector: 'company-site-permissions',
    templateUrl: './companySitePermissions.component.html'
})
export class CompanySitePermissionsComponent implements IWeissmanModalComponent<CompanySitePermissionsParams, Core.PermissionSaveRequest> {
    constructor(
        private readonly _permissionService: PermissionService,
        private readonly _modalRef: BsModalRef,
        private _busyIndicatorService: BusyIndicatorService
    ) { }

    params: CompanySitePermissionsParams;
    result: Core.PermissionSaveRequest;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            singleClickEdit: true
        })
        .withContext(this)
        .withInfiniteScroll()
        .withLoadingOverlay()
        .withColumnResize()
        // .withFloatingFilter() Hiding for now until Access Level filtering works on the API
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    private _gridApi: GridApi;
    private _gridDataSource: CompanySitePermissionsAgGridDataSource;

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'State',
                field: 'siteStateAbbr',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.stateAbbreviationColumnWidth
            },
            {
                headerName: 'Property #',
                field: 'siteProperty',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Name',
                field: 'entityName',
                width: AgGridColumns.textColumnLargeWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Access Level',
                field: 'rightsDropdown',
                sortable: false,
                headerTooltip: 'Sort is not available for this column.',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: () => this.params.editMode,
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => [
                        { name: RightsDisplay.View, value: RightsDisplay.View },
                        { name: RightsDisplay.Edit, value: RightsDisplay.Edit },
                        { name: RightsDisplay.Hide, value: RightsDisplay.Hide }
                    ],
                    cellChanged: (params: DropdownCellEditorParams, newValue: string) => {
                        const permission = params.data as Core.PermissionModel;
                        permission.readAllowed = newValue !== RightsDisplay.Hide;
                        permission.writeAllowed = newValue === RightsDisplay.Edit;

                        const alreadyUpdatedIdx = _.findIndex(this.params.saveRequest.updates, { entityID: permission.entityID });
                        const alreadyDeletedIdx = _.findIndex(this.params.saveRequest.adds, { entityID: permission.entityID });
                        const alreadyAddedIdx = _.findIndex(this.params.saveRequest.deletes, { entityID: permission.entityID });

                        if(alreadyUpdatedIdx >= 0) {
                            if(newValue === RightsDisplay.Hide) {
                                _.pullAt(this.params.saveRequest.updates, alreadyUpdatedIdx);
                                this.params.saveRequest.deletes.push(permission);
                            } else {
                                this.params.saveRequest.updates[alreadyUpdatedIdx] = permission;
                            }
                        } else if (alreadyDeletedIdx >= 0) {
                            if(newValue !== RightsDisplay.Hide) {
                                _.pullAt(this.params.saveRequest.deletes, alreadyDeletedIdx);
                                this.params.saveRequest.updates.push(permission);
                            }
                        } else if (alreadyAddedIdx >= 0) {
                            if(newValue === RightsDisplay.Hide) {
                                _.pullAt(this.params.saveRequest.adds, alreadyAddedIdx);
                            } else {
                                this.params.saveRequest.adds[alreadyAddedIdx] = permission;
                            }
                        } else {
                            // permission has not been edited yet

                            if(permission.permissionID) {
                                if(newValue === RightsDisplay.Hide) {
                                    this.params.saveRequest.deletes.push(permission);
                                } else {
                                    this.params.saveRequest.updates.push(permission);
                                }
                            } else {
                                this.params.saveRequest.adds.push(permission);
                            }
                        }

                    }
                },
                valueGetter: this._getDropownPermission.bind(this)
            },
        ];

        const defaultSortModel = [
            {
                colId: 'siteStateAbbr',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    save(): void {
        this.result = this.params.saveRequest;
        this._modalRef.hide();
    }

    cancel(): void {
        this._modalRef.hide();
    }

    private _getDropownPermission(params: ValueFormatterParams): string {
        const dbPermission = params.data as Core.PermissionModel;

        if(!dbPermission) {
            return '';
        }

        const inMemoryPermissions = _.flatten([this.params.saveRequest.adds, this.params.saveRequest.updates, this.params.saveRequest.deletes]);
        const p = _.find(inMemoryPermissions, { entityID: dbPermission.entityID }) || dbPermission;

        if (p.writeAllowed) {
            return RightsDisplay.Edit;
        } else if (p.readAllowed) {
            return RightsDisplay.View;
        } else {
            return RightsDisplay.Hide;
        }
    }

    private _setDataSource(): void {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): CompanySitePermissionsDataSourceParams => {
            return {
                companyId: this.params.companyId,
                groupId: this.params.groupId,
                userId: this.params.userId
            };
        };

        this._gridDataSource = new CompanySitePermissionsAgGridDataSource(this._gridApi, this._permissionService, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
    }
}
