import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsFilter', pure: false })
export class WeissmanFilterPipe implements PipeTransform {
    transform(items: any[], filter: (item: any) => boolean): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter(item => filter(item));
    }
}

// Match the functionality of AngularJS filter pipe to make upgrading easier
@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: { [key: string]: any }): any {
        if (!items || !filter) {
            return items;
        }
        const keysToCheck = Object.keys(filter);
        return items.filter(item => keysToCheck.every(x => item[x] === filter[x]));
    }
}
