<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Property Characteristics - Null Values"
                  acceptLabel="Save"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="false">
    <div>
        <table>
            <tr>
                <td>Set All:</td>
                <td style="padding-left: 10px;">
                    <ws-select
                        valueProperty="optionId"
                        labelProperty="name"
                        [options]="availableOptions"
                        [ngModel]="getValue(0)"
                        (ngModelChange)="setValue(0, $event)"
                    ></ws-select>
                </td>
            </tr>
            <tr *ngFor="let field of fields">
                <td>{{field.name}}:</td>
                <td style="padding-left: 10px;">
                    <ws-select
                        valueProperty="optionId"
                        labelProperty="name"
                        [options]="availableOptions"
                        [ngModel]="getValue(field.importFieldId)"
                        (ngModelChange)="setValue(field.importFieldId, $event)"></ws-select>
                </td>
            </tr>
        </table>
    </div>
</ws-modal-wrapper>
