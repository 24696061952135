import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface LinkedParcelsListGridNameCellRendererParams extends ICellRendererParams {
    getName: (params: LinkedParcelsListGridNameCellRendererParams) => string;
    getLink: (params: LinkedParcelsListGridNameCellRendererParams) => string;
    isMaster: (params: LinkedParcelsListGridNameCellRendererParams) => boolean;
    canNavigate: boolean;
}

@Component({
    selector: 'linked-parcels-list-grid-name-cell',
    template: `
    <span *ngIf="params.isMaster(params)"
        class="icon-master"
        helpTooltip
        helpContentId="linked-parcels.master-parcel"
        position="bottom">
        <i class="fa fa-star"></i>
    </span>
    <span *ngIf="!params.canNavigate">
        {{ params.getName(params) }}
    </span>
    <a *ngIf="params.canNavigate"
        class="grid-link-cell"
        [href]="params.getLink(params)"
        target="_blank"
        rel="noopener noreferrer"
        helpTooltip
        helpContentId="app.view-parcel"
        position="bottom">
    {{ params.getName(params) }}
    </a>
    `
})
export class LinkedParcelsListGridNameCellRendererComponent implements ICellRendererAngularComp {
    params: LinkedParcelsListGridNameCellRendererParams;

    agInit(params: LinkedParcelsListGridNameCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
