import { Injectable } from "@angular/core";
import { BaseRepository } from "../Common/Data/base.repository";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Assessor, AssessorActiveParcelCount } from '../Assessor-Collector/assessor.model';
import { AssessorMunicipality } from '../Assessor-Collector/List/addAssessorModal.component';
import { CollectorAssociation } from '../Assessor-Collector/Assessor/Assessor-Available-Collectors/assessor.available.collectors.component';
import { Collector } from '../Assessor-Collector/collector.model';

@Injectable()
export class AssessorCollectorRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly API_URL = '/api/';
    private readonly COMPANY_COLLECTOR_URL = this.API_URL + 'collector/parcel/';
    private readonly EXTRACT_URL = this.API_URL + 'Reporting/';
    private readonly BLOB_OPTIONS = {
        responseType: 'blob'
    };

    getNavigationInfo(entityType: string, entityId: number): Observable<any> {
        return this.httpGet(this.API_URL + entityType + "/" + entityId + '/Navigation');
    }

    getCompanyCollectorsByParcel(parcelId: number): Observable<any> {
        return this.httpGet(this.COMPANY_COLLECTOR_URL + parcelId);
    }

    getAssessorExtract(): Observable<Blob> {
        return this.httpGet(this.EXTRACT_URL + 'assessorlistextract', this.BLOB_OPTIONS);
    }

    getCollectorExtract(): Observable<Blob> {
        return this.httpGet(this.EXTRACT_URL + 'collectorlistextract', this.BLOB_OPTIONS);
    }

    getCollector(collectorId: number): Observable<Weissman.Model.Collectors.Collector> {
        return this.httpGet(this.API_URL + `collector/${collectorId}`);
    }

    getAssessor(assessorId: number): Observable<Assessor> {
        return this.httpGet(this.API_URL + `assessor/${assessorId}`);
    }

    getUpdatedAssessorCollector(entity: string, entityId: number, extraParam?: string): Observable<any> {
        return this.httpGet(`${this.API_URL}${entity}/${entityId}${extraParam ? extraParam : ''}`);
    }

    getCollectorAssociations(assessorId: number): Observable<CollectorAssociation[]> {
        const params = { assessorId };

        return this.httpGet(`${this.API_URL}collectorassociation`, { params });
    }

    getStateCollectorList(stateId: number): Observable<any[]> {
        return this.httpGet(`${this.API_URL}collector/state/${stateId}`);
    }

    getAssessorCollectorList(stateId: number, assessorId: number): Observable<any[]> {
        return this.httpGet(`${this.API_URL}collector/state/${stateId}/assessor/${assessorId}`);
    }

    getCompanyCollectorList(companyId: number): Observable<any> {
        return this.httpGet(`${this.API_URL}collector/company/${companyId}`);
    }

    saveAssociations(assessorId: number, associations: CollectorAssociation[]): Observable<void> {
        return this.httpPut(`${this.API_URL}collectorassociation/assessor/${assessorId}`, associations);
    }

    saveAssessorCollector(entityType: string, entity: any): Observable<any> {
        return this.httpPut(`${this.API_URL}${entityType}`, entity);
    }

    getStateAssessorList(stateId: number): Observable<Assessor[]> {
        return this.httpGet(`${this.API_URL}assessor/state/${stateId}`);
    }

    getAssessorActiveParcelCounts(countForStateID: number): Observable<AssessorActiveParcelCount[]> {
        return this.httpGet(`${this.API_URL}assessor/state/${countForStateID}/parcelCount`);
    }

    getAssessorMunicipalities(): Observable<AssessorMunicipality[]> {
        return this.httpGet(`${this.API_URL}municipality`);
    }

    createAssessor(assessor: Assessor): Observable<Assessor> {
        return this.httpPost(`${this.API_URL}assessor`, assessor);
    }

    createCollector(collector: Collector): Observable<Collector> {
        return this.httpPost(`${this.API_URL}collector`, collector);
    }

    saveCollectorProtocols(collectorProtocols: Weissman.Model.Collectors.Collector): Observable<any> {
        return this.httpPatch(`${this.API_URL}collector/CollectorProtocols`, collectorProtocols);
    }
}
