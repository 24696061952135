import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators';
import { MultiPartResponseService } from '../../Common/Download/MultiPartResponseService';

@Injectable()
export class ReturnFormRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _multiPartResponseService: MultiPartResponseService
    ) { super(httpClient) }

    getFormTemplate(filingBatchId: number, requestModel: Compliance.ReturnFormRequestModel): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/form`, requestModel, options);
    }

    getFormData(filingBatchId: number, requestModel: Compliance.ReturnFormRequestModel): Observable<Compliance.ReturnFormResultModel> {
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/data`, requestModel);
    }

    getFormAndData(filingBatchId: number, requestModel: Compliance.ReturnFormRequestModel): Observable<{
        form: Blob;
        data: Compliance.ReturnFormResultModel;
    }> {
        return this.httpPost<HttpResponse<Blob>, Compliance.ReturnFormRequestModel>(
            `/api/returnform/filingbatch/${filingBatchId}/formAndData`,
            requestModel, {
                observe: 'response',
                responseType: 'blob'
            }
        ).pipe(mergeMap(async response => {
            const processedResponse = this._multiPartResponseService.ReadResponse(response);
            if (!processedResponse.isMultiPartResponse) {
                throw new Error('Unable to parse response (expected multi-part)');
            }
            const resultData = await processedResponse.reader.readAll();
            if (resultData.binary.length !== 1) {
                throw new Error('Unable to parse response (expected exactly one binary part)');
            }
            if (resultData.text.length !== 1) {
                throw new Error('Unable to parse response (expected exactly one binary part)');
            }
            return {
                form: resultData.binary[0],
                data: JSON.parse(resultData.text[0]) as Compliance.ReturnFormResultModel
            };
        }));
    }

    startDownloadForm(filingBatchId: number, requestModel: Compliance.ReturnFormRequestModel): Observable<number> {
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/downloadForm`, requestModel);
    }

    getDownloadForm(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/returnform/downloadForm/${longRunningProcessId}`, options);
    }

    updateFormFieldOverrides(filingBatchId: number, returnFormOverrideRequestOverride: Compliance.ReturnFormOverridesRequestModel): Observable<Compliance.ReturnFormOverrideModel[]> {
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/override/update`, returnFormOverrideRequestOverride);
    }

    deleteFormFieldOverrides(filingBatchId: number, returnFormOverrideRequestOverride: Compliance.ReturnFormOverrideRequestModel): Observable<void> {
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/override/delete`, returnFormOverrideRequestOverride);
    }

    getFormFieldOverrides(filingBatchId: number, searchModel: Compliance.ReturnFormOverrideSearchModel): Observable<Compliance.QueryResultModel<Compliance.ReturnFormOverrideModel>> {
        return this.httpPost(`/api/returnform/filingbatch/${filingBatchId}/override`, searchModel);
    }

    getFormOverrides(filingBatchId: number): Observable<Compliance.ReturnFormRevisionModel[]> {
        return this.httpGet(`/api/returnform/filingbatch/${filingBatchId}/override`);
    }

    getOutput(returnIds: number[]): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpPost(`/api/returnform/output`, returnIds, options);
    }
}
