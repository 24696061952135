import { NgModule } from '@angular/core';
import { AppealApplicationComponent } from './appealApplication.component';
import { AppealApplicationListComponent } from './Appeal-Application-List/appealApplicationList.component';
import { AppealApplicationService } from './appeal.application.service';
import { AppealApplicationViewer } from './PDF-Viewer/appealApplicationViewer.component';
import { AppealApplicationSettingsComponent } from './Appeal-Application-Settings/appeal.application.settings.component';
import { AppealApplicationSetTopModalComponent } from './Set-Top-Modal/appealApplicationSetTopModal.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DownloadAppealFormComponent } from './Appeal-Application-List/Download-Appeal-Form/downloadAppealForm.component';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { AppealApplicationRepository } from '../Core-Repositories';
import { AdditionalAccountTooltipComponent } from './Appeal-Application-List/Additional-Account-Tooltip/additionalAccountTooltip.component';
import { EditAppealSupplementAssociationsComponent } from "./Appeal-Application-List/Edit-Appeal-Associations/editAppealSupplementAssociations.component";
import { EditAppealMainAssociationsComponent } from "./Appeal-Application-List/Edit-Appeal-Associations/editAppealMainAssociations.component";

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule,
        TabsModule,
        ScrollingModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [
        AppealApplicationComponent,
        AppealApplicationListComponent,
        AppealApplicationViewer,
        AppealApplicationSettingsComponent,
        AppealApplicationSetTopModalComponent,
        DownloadAppealFormComponent,
        EditAppealSupplementAssociationsComponent,
        AdditionalAccountTooltipComponent,
        EditAppealMainAssociationsComponent
    ],
    providers: [
        AppealApplicationService,
        AppealApplicationRepository
    ],
    exports: [AppealApplicationComponent]
})
export class AppealApplicationModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('appealApplication', AppealApplicationComponent);
        hybridAdapterUtility.downgradeNg2Provider('appealApplicationService', AppealApplicationService);
    }
}
