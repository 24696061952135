<ws-modal-wrapper [headerLabel]="('Bulk Update ' + selectedCount + ' Records')"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  auxButtonLabel="Override All Fields"
                  [disableAuxButton]="activeTab !== assetBulkUpdateTabEnum.AssetFields"
                  (auxButton)="overrideAllFields()"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid || !isBulkMetadataLoaded || isSaving">
    <div>
        <ul class="nav nav-tabs">
            <li class="uib-tab tab-item" [ngClass]="{'active' : activeTab === assetBulkUpdateTabEnum.AssetFields}"><a class="nav-link" (click)="changeActiveTab(assetBulkUpdateTabEnum.AssetFields)">Update Asset Fields</a></li>
            <li class="uib-tab tab-item" [ngClass]="{'active' : activeTab === assetBulkUpdateTabEnum.Splits}"><a class="nav-link" (click)="changeActiveTab(assetBulkUpdateTabEnum.Splits)">Create and Update Asset Splits</a></li>
        </ul>
    </div>
    <div style="overflow-y: auto; max-height: calc(750px - 180px); height: calc(750px - 180px)" #bulkUpdateFieldContainer>
        <form class="ws-stretch-height">
            <div class="table-responsive no-overflow-y" *ngIf="activeTab === assetBulkUpdateTabEnum.AssetFields">
                <table class="table table-striped table-condensed table-borderless-body">
                    <tbody>
                        <tr>
                            <td>Manual Asset Verification</td>
                            <td>
                                <asset-bulk-update-action [verifyField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.assetVerificationAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                                    <label>Date:</label>
                                    <asset-lien-date id="assetLienDate"
                                                     [companyId]="params.companyId"
                                                     [useSavedLienDate]="true"
                                                     [allowNonStandardLienDate]="false"
                                                     (dateChange)="onVerificationDateChanged($event)"
                                                     [isDisabled]="isDisabled(reportingAssetBulkUpdateModel.assetVerificationAction) || isSaving">
                                    </asset-lien-date>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                                    <label>Reason:</label>
                                    <select name="assetVerificationReason"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.assetVerificationReason"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.assetVerificationAction) || isSaving"
                                            class="form-select form-select-sm asset-verification-reason">
                                        <option *ngFor="let assetVerificationReason of assetVerificationReasons" [ngValue]="assetVerificationReason">{{ assetVerificationReason }}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Ownership Type</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.ownershipTypeAction"
                                                          [disabled]="isLoading || isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <loading-spinner [show]="isLoading" [hasOverlay]="true"></loading-spinner>
                                    <select name="ownershipType"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.ownershipTypeId"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.ownershipTypeAction) || isSaving"
                                            (ngModelChange)="ownershipTypeChanged($event)"
                                            class="form-select form-select-sm">
                                        <option *ngFor="let ownershipType of ownershipTypes" [ngValue]="ownershipType.assetOwnershipTypeId">{{ ownershipType.description }}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Lease Type</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.leaseTypeAction"
                                                          [disabled]="isLeaseTypeFieldDisabled() || isSaving"
                                                          #leaseTypeActionField>
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <select name="leaseType"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.leaseTypeId"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.leaseTypeAction) || isLeaseTypeFieldDisabled() || isSaving"
                                            class="form-select form-select-sm">
                                        <option *ngFor="let leaseType of leaseTypes" [ngValue]="leaseType.leaseTypeId">{{ leaseType.leaseTypeId }}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Classification</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="false"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.classificationIdAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <asset-class-selector [(assetClassificationId)]="reportingAssetBulkUpdateModel.classificationId"
                                                          [isDisabled]="isDisabled(reportingAssetBulkUpdateModel.classificationIdAction) || isSaving">
                                    </asset-class-selector>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let descriptor of acqDateAssetDescriptors">
                            <td>{{descriptor.descriptor.name}}</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <asset-descriptor-edit [name]="descriptor.descriptor.name"
                                                           [descriptor]="descriptor"
                                                           [(ngModel)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()]"
                                                           [isDisabled]="isDisabled(reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']) || isSaving">
                                    </asset-descriptor-edit>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="acqDateAssetDescriptors && acqDateAssetDescriptors.length > 0">
                            <td>Alternative Acquisition Date Field</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorIdAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <select name="alternativeAcqDate"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorId"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorIdAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="undefined"></option>
                                        <option [ngValue]="null">Acquisition Date</option>
                                        <option *ngFor="let descriptor of acqDateAssetDescriptors" [value]="descriptor.companyAssetDescriptorMappingId">
                                            {{descriptor.descriptor.name}}
                                        </option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Acquisition Date</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.acqDateAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <weissman-datetime class="float-start"
                                                   [dateOnly]="true"
                                                   [(inputDate)]="reportingAssetBulkUpdateModel.acqDate"
                                                   [isDisabled]="isDisabled(reportingAssetBulkUpdateModel.acqDateAction) || isSaving">
                                </weissman-datetime>
                            </td>
                        </tr>
                        <tr>
                            <td>Disposed Date</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.disposedDateAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <weissman-datetime class="float-start"
                                                   [dateOnly]="true"
                                                   [(inputDate)]="reportingAssetBulkUpdateModel.disposedDate"
                                                   [isDisabled]="isDisabled(reportingAssetBulkUpdateModel.disposedDateAction) || isSaving">
                                </weissman-datetime>
                            </td>
                        </tr>
                        <tr>
                            <td>Do Not Potentially Dispose</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.doNotPotentiallyDisposeAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <select name="doNotPotentiallyDispose"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.doNotPotentiallyDispose"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.doNotPotentiallyDisposeAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="null"></option>
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="false"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.descriptionAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <input name="description"
                                       type="text"
                                       autocomplete="off"
                                       class="form-control"
                                       [(ngModel)]="reportingAssetBulkUpdateModel.description"
                                       maxLength="200"
                                       [disabled]="isDisabled(reportingAssetBulkUpdateModel.descriptionAction) || isSaving" />
                            </td>
                        </tr>
                        <tr *ngFor="let descriptor of costAssetDescriptors">
                            <td>{{descriptor.descriptor.name}}</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <asset-descriptor-edit [name]="descriptor.descriptor.name"
                                                           [descriptor]="descriptor"
                                                           [(ngModel)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()]"
                                                           [isDisabled]="isDisabled(reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']) || isSaving">
                                    </asset-descriptor-edit>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="costAssetDescriptors && costAssetDescriptors.length > 0">
                            <td>Alternative Cost Field</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.alternativeCostDescriptorIdAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <select name="alternativeCost"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.alternativeCostDescriptorId"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.alternativeCostDescriptorIdAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="undefined"></option>
                                        <option [ngValue]="null">Cost</option>
                                        <option *ngFor="let descriptor of costAssetDescriptors" [value]="descriptor.companyAssetDescriptorMappingId">
                                            {{descriptor.descriptor.name}}
                                        </option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Cost</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.costAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <ws-currency-input name="cost"
                                                   [(ngModel)]="reportingAssetBulkUpdateModel.cost"
                                                   [disabled]="isDisabled(reportingAssetBulkUpdateModel.costAction) || isSaving">
                                </ws-currency-input>
                            </td>
                        </tr>
                        <tr>
                            <td>Net Book Value</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.netBookValueAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <ws-currency-input name="netBookValue"
                                                   [(ngModel)]="reportingAssetBulkUpdateModel.netBookValue"
                                                   [disabled]="isDisabled(reportingAssetBulkUpdateModel.netBookValueAction) || isSaving">
                                </ws-currency-input>
                            </td>
                        </tr>
                        <tr>
                            <td>GL Account</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.glAccountIdAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <input name="glAccount"
                                       type="text"
                                       placeholder="Filter GL Accounts..."
                                       class="form-control"
                                       autocomplete="off"
                                       [(ngModel)]="glAccountFilter"
                                       (blur)="onGlAccountBlur()"
                                       container="asset-bulk-update-modal"
                                       [typeahead]="glAccounts$"
                                       (typeaheadNoResults)="onGlAccountNoResults($event)"
                                       typeaheadOptionField="displayName"
                                       [typeaheadMinLength]="0"
                                       [typeaheadOptionsLimit]="100"
                                       [typeaheadScrollable]="true"
                                       (typeaheadOnSelect)="onGlAccountSelected($event)"
                                       [disabled]="isDisabled(reportingAssetBulkUpdateModel.glAccountIdAction) || isSaving" />
                        </tr>
                        <tr>
                            <td>Site</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.siteIdAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input name="site"
                                           type="text"
                                           placeholder="Filter Sites..."
                                           class="form-control"
                                           autocomplete="off"
                                           [(ngModel)]="siteFilter"
                                           (blur)="onSiteBlur()"
                                           container="asset-bulk-update-modal"
                                           [typeahead]="sites$"
                                           (typeaheadNoResults)="onSiteNoResults($event)"
                                           [typeaheadWaitMs]="750"
                                           typeaheadOptionField="displayName"
                                           [typeaheadOptionsLimit]="100"
                                           [typeaheadScrollable]="true"
                                           (typeaheadLoading)="onSiteLoadingChange($event)"
                                           (typeaheadOnSelect)="onSiteSelected($event)"
                                           [disabled]="isDisabled(reportingAssetBulkUpdateModel.siteIdAction) || isSaving" />
                                    <span class="input-group-text" [style.visibility]="siteIsLoading ? 'visible' : 'hidden'">
                                        <i class="fa fa-spin fa-spinner"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Is Anchored</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.isAnchoredAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <select name="isAnchored"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.isAnchored"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.isAnchoredAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="null"></option>
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Parcel</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.reportingParcelIdAction"
                                                          [disabled]="isLoading || isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <loading-spinner [show]="isLoading" [hasOverlay]="true"></loading-spinner>
                                    <parcel-selector [companyId]="params.companyId"
                                                     [(reportingParcelId)]="reportingAssetBulkUpdateModel.reportingParcelId"
                                                     [siteIds]="siteIds"
                                                     [isDisabled]="isDisabled(reportingAssetBulkUpdateModel.reportingParcelIdAction) || isSaving"
                                                     [getParcels]="getParcels"
                                                     optionFlagText="Show only parcels under site"></parcel-selector>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span helpTooltip
                                      [helpContentId]="perpetualHelpContentId"
                                      position="right">Is Perpetual</span>
                            </td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.isPerpetualAction"
                                                          [disabled]="isLoading || perpetualAllowedAssetsCount === 0 || isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <loading-spinner [show]="isLoading" [hasOverlay]="true"></loading-spinner>
                                    <select name="isPerpetual"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.isPerpetual"
                                            [disabled]="perpetualAllowedAssetsCount === 0 || isDisabled(reportingAssetBulkUpdateModel.isPerpetualAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="null"></option>
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Is Locked</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.isLockedAction"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <select name="isLocked"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.isLocked"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.isLockedAction) || isSaving"
                                            class="form-select form-select-sm" (ngModelChange)="isLockedChanged($event)">
                                        <option [ngValue]="null"></option>
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="unlockAssets">
                            <td>Remove overrides</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel.isLockedRemoveOverridesAction"
                                                          [disabled]="isSaving || unlockAssets">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div class="ws-position-relative">
                                    <select name="isLockedRemoveOverrides"
                                            [(ngModel)]="reportingAssetBulkUpdateModel.isLockedRemoveOverrides"
                                            [disabled]="isDisabled(reportingAssetBulkUpdateModel.isLockedRemoveOverridesAction) || isSaving"
                                            class="form-select form-select-sm">
                                        <option [ngValue]="true">Yes</option>
                                        <option [ngValue]="false">No</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let descriptor of assetDescriptors">
                            <td>{{descriptor.descriptor.name}}</td>
                            <td>
                                <asset-bulk-update-action [requiredField]="descriptor.descriptor.fieldType === 2 ? false : true"
                                                          [fieldHasOverrides]="true"
                                                          [(fieldAction)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']"
                                                          [disabled]="isSaving">
                                </asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <asset-descriptor-edit [name]="descriptor.descriptor.name"
                                                           [descriptor]="descriptor"
                                                           [(ngModel)]="reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()]"
                                                           [isDisabled]="isDisabled(reportingAssetBulkUpdateModel[descriptor.columnName.toLowerCase()+'Action']) || isSaving">
                                    </asset-descriptor-edit>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="ws-position-relative">
                    <loading-spinner [show]="isLoading" [size]="2"></loading-spinner>
                    <h3 *ngIf="stateCostAdjustments.length > 0">Cost Adjustments By State</h3>
                    <table *ngFor="let stateCostAdjustment of stateCostAdjustments" class="table table-striped table-condensed table-borderless-body">
                        <thead>
                            <tr>
                                <th colspan="3">
                                    <span helpTooltip
                                          [contentComponent]="stateCostAdjustment.customHelpRenderer"
                                          [contentComponentValue]="stateCostAdjustment.extendedCostAdjustmentTypes">{{stateCostAdjustment.state}}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let costAdjustment of stateCostAdjustment.costAdjustments">
                                <td>{{costAdjustment.costAdjustment}}</td>
                                <td>
                                    <asset-bulk-update-action [requiredField]="true"
                                                              (fieldActionChange)="onCostAdjustmentActionChange($event, stateCostAdjustment.stateId, costAdjustment.costAdjustmentTypeId)"
                                                              [disabled]="isSaving">
                                    </asset-bulk-update-action>
                                </td>
                                <td>
                                    <div>
                                        <div class="adjustment-input-holder">
                                            <div class="input-group">
                                                <div class="input-group-text">$</div>
                                                <input type="number"
                                                       [name]="'costAdjustmentAmount' + [stateCostAdjustment.stateId] + [costAdjustment.costAdjustmentTypeId]"
                                                       class="form-control"
                                                       [ngClass]="'costAdjustmentAmount' + [stateCostAdjustment.stateId] + [costAdjustment.costAdjustmentTypeId]"
                                                       [disabled]="isCostInputDisabled(stateCostAdjustment.stateId, costAdjustment.costAdjustmentTypeId, false) || isSaving"
                                                       (input)="updateCostAdjustmentAmount(stateCostAdjustment.stateId, costAdjustment.costAdjustmentTypeId, $event)">
                                            </div>
                                        </div>
                                        <div class="adjustment-input-holder">
                                            <div class="input-group">
                                                <div class="input-group-text">%</div>
                                                <input type="number"
                                                       [name]="'costAdjustmentPercent' + [stateCostAdjustment.stateId] + [costAdjustment.costAdjustmentTypeId]"
                                                       class="form-control"
                                                       [ngClass]="'costAdjustmentPercent' + [stateCostAdjustment.stateId] + [costAdjustment.costAdjustmentTypeId]"
                                                       [disabled]="isCostInputDisabled(stateCostAdjustment.stateId, costAdjustment.costAdjustmentTypeId, true) || isSaving"
                                                       (input)="updateCostAdjustmentPercent(stateCostAdjustment.stateId, costAdjustment.costAdjustmentTypeId, $event)">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-responsive ws-position-relative" *ngIf="activeTab === assetBulkUpdateTabEnum.Splits">
                <loading-spinner [show]="isLoading" [size]="2" [hasOverlay]="true"></loading-spinner>
                <table class="table table-striped table-condensed table-borderless-body">
                    <thead>
                        <tr>
                            <th colspan="2">Create New Asset Split<span *ngIf="!isCreateNewSplitAllowed" helpTooltip helpContentId="asset-bulk-update.createNewSplitDisabled" position="right"></span></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Split Cost Percentage</td>
                        <td>
                            <div class="adjustment-input-holder">
                                <div class="input-group">
                                    <div class="input-group-text">%</div>
                                    <input type="number"
                                           name="createSplitCostPercentage"
                                           [(ngModel)]="reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage"
                                           class="form-control"
                                           decimalNumber
                                           [decimalPrecision]="5"
                                           [disabled]="!isCreateNewSplitAllowed || isSaving">
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Split Asset Classification</td>
                        <td>
                            <div>
                                <asset-class-selector [(assetClassificationId)]="reportingAssetBulkUpdateModel.splitClassificationId"
                                                      [isDisabled]="!isCreateNewSplitAllowed || isSaving" [isRequiredField]="false"></asset-class-selector>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Split Description Append Text</td>
                        <td>
                            <input name="descriptionAppendText"
                                   [(ngModel)]="reportingAssetBulkUpdateModel.splitDescriptionAppendText"
                                   type="text"
                                   autocomplete="off"
                                   class="form-control"
                                   maxLength="50"
                                   [disabled]="!isCreateNewSplitAllowed || isSaving" />
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-condensed table-borderless-body">
                    <thead>
                        <tr>
                            <th colspan="2">Update Existing Asset Split<span *ngIf="!isUpdateSplitAllowed" helpTooltip helpContentId="asset-bulk-update.updateSplitDisabled" position="right"></span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Split Cost Percentage</td>
                            <td>
                                <div class="adjustment-input-holder">
                                    <div class="input-group">
                                        <div class="input-group-text">%</div>
                                        <input type="number"
                                               name="updateSplitCostPercentage"
                                               [(ngModel)]="reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage"
                                               class="form-control"
                                               decimalNumber
                                               [decimalPrecision]="5"
                                               [disabled]="!isUpdateSplitAllowed || isSaving">
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <p *ngIf="validationMessage" class="text-danger">{{validationMessage}}</p>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
