import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class EntityService {

    private LATEST: string = 'Latest';

    private _currentSiteId: number;

    private _parcelListDisplayStateSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _parcelListTaxYearSubject: BehaviorSubject<string> = new BehaviorSubject(this.LATEST);
    private _parcelListScrollIndex: BehaviorSubject<number> = new BehaviorSubject(null);

    parcelListDisplayState$: Observable<boolean> = this._parcelListDisplayStateSubject.asObservable();
    parcelListTaxYear$: Observable<string> = this._parcelListTaxYearSubject.asObservable();
    parcelListScrollIndex$: Observable<number> = this._parcelListScrollIndex.asObservable();

    set currentSiteId(id: number) { this._currentSiteId = id; }

    get currentSiteId(): number { return this._currentSiteId; }

    get editGroup(): string {
        return 'site';
    }

    reset(): void {
        this._parcelListDisplayStateSubject.next(false);
        this._parcelListTaxYearSubject.next(this.LATEST);
        this._parcelListScrollIndex.next(null);
    }

    setParcelListScrollIndex(index: number): void {
        this._parcelListScrollIndex.next(index);
    }

    changeParcelListDisplayState(toggleValue: boolean): void {
        if (this._parcelListDisplayStateSubject.getValue() !== toggleValue) {
            this._parcelListDisplayStateSubject.next(toggleValue);
        }
    }

    changeParcelListTaxYear(taxYear: string): void {
        if (this._parcelListTaxYearSubject.getValue() !== taxYear) {
            this._parcelListTaxYearSubject.next(taxYear);
        }
    }
}
