import { Observable ,  Subject ,  ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { Injectable } from "@angular/core";

@Injectable()
export class AnnualDetailsNavigationEventService {
    private _subjectAssessment = new Subject<AssessmentNavigationInput>();
    private _subjectAppeal = new Subject<AppealNavigationInput>();
    private _subjectTax = new Subject<TaxNavigationInput>();
    private _subjectRefund = new Subject<TaxNavigationInput>();
    private _subjectFiling = new Subject<FilingNavigationInput>();
    private _refreshData = new Subject<void>();
    private _refreshAnnualYearData = new Subject<void>();
    private _goToGridData = new Subject<void>();
    private _yearsData = new ReplaySubject<number[]>(1);
    private _onEventReset = new Subject<void>();
    private _refreshAccrualDetailsLink = new Subject<boolean>();

    resetAnnualDetails(): void {
        this._onEventReset.next();
    }

    goToAssessment(input: AssessmentNavigationInput): void {
        this._subjectAssessment.next(input);
    }

    goToAppeal(input: AppealNavigationInput): void {
        this._subjectAppeal.next(input);
    }

    goToTax(input: TaxNavigationInput): void {
        this._subjectTax.next(input);
    }

    goToRefund(input: TaxNavigationInput): void {
        this._subjectRefund.next(input);
    }

    goToFiling(input: FilingNavigationInput): void {
        this._subjectFiling.next(input);
    }

    refreshActivityData(): void {
        this._refreshData.next();
    }

    refreshAnnualYear(): void {
        this._refreshAnnualYearData.next();
    }

    goToGrid(): void {
        this._goToGridData.next();
    }

    setYears(years: number[]): void {
        this._yearsData.next(years);
    }

    refreshAccrualDetailsLink( allPaymentsExcludedFromAccrual: boolean ): void {
        this._refreshAccrualDetailsLink.next(allPaymentsExcludedFromAccrual);
    }

    get assessmentEvent$(): Observable<AssessmentNavigationInput> {
        return this._subjectAssessment.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get appealEvent$(): Observable<AppealNavigationInput> {
        return this._subjectAppeal.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get taxEvent$(): Observable<TaxNavigationInput> {
        return this._subjectTax.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get refundEvent$(): Observable<TaxNavigationInput> {
        return this._subjectRefund.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get filingEvent$(): Observable<FilingNavigationInput> {
        return this._subjectFiling.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get refreshEvent$(): Observable<void> {
        return this._refreshData.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get refreshAnnualYearEvent$(): Observable<void> {
        return this._refreshAnnualYearData.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get goToGridEvent$(): Observable<void> {
        return this._goToGridData.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get yearsChangedEvent$(): Observable<number[]> {
        return this._yearsData.asObservable().pipe(takeUntil(this._onEventReset));
    }

    get refreshAccrualDetailsLinkEvent$(): Observable<boolean> {
        return this._refreshAccrualDetailsLink.asObservable().pipe(takeUntil(this._onEventReset));
    }
}

export class AssessmentNavigationInput {
    annualYearID: number;
    annualAssessmentID: number;

    constructor(annualYearID: number, annualAssessmentID: number) {
        this.annualYearID = annualYearID;
        this.annualAssessmentID = annualAssessmentID;
    }
}

export class AppealNavigationInput {
    annualYearID: number;
    annualAssessmentID: number;
    appealID: number;

    constructor(annualYearID: number, annualAssessmentID: number, appealID: number) {
        this.annualYearID = annualYearID;
        this.annualAssessmentID = annualAssessmentID;
        this.appealID = appealID;
    }
}

export class TaxNavigationInput {
    annualYearID: number;
    taxID: number;

    constructor(annualYearID: number, taxID: number) {
        this.annualYearID = annualYearID;
        this.taxID = taxID;
    }
}

export class FilingNavigationInput {
    annualYearID: number;
    filingId: number;

    constructor(annualYearID: number, filingId: number) {
        this.annualYearID = annualYearID;
        this.filingId = filingId;
    }
}