import { Injectable} from '@angular/core';
import { ReturnPartServiceBase } from '../../Models/returnPartServiceBase';
import { UserSettingsService } from '../../../../Account/userSettings.service';
import * as _ from 'lodash';

export interface ReturnOutputServiceSharedState {
    returnOutputModel: Compliance.ReturnOutputModel;
 }

@Injectable()
export class ReturnOutputService extends ReturnPartServiceBase {
    constructor(
        private readonly _userSettingsService: UserSettingsService) {
         super();
    }

    private readonly _outputSettingName: string = 'Compliance-Return-Output-Settings';

    private _sharedState: ReturnOutputServiceSharedState = {
        returnOutputModel: null
    } as ReturnOutputServiceSharedState;

    get sharedState(): ReturnOutputServiceSharedState { return this._sharedState; }

    initialize(): void {
        this._sharedState = {
            returnOutputModel: null
        } as ReturnOutputServiceSharedState;
    }

    async loadSharedState(filingBatchId: number): Promise<void> {
        let returnOutputModel = {
            filingBatchId: filingBatchId,
            packageIds: [],
            includeFilingControlReport: false,
            includeUSPSCertificateOfMailingLog: true,
            includeUSPSCertificateOfMailingIndividual: true,
            includeEnvelopeInserts: true,
            includePackageCoverSheets: false,
            includeReturns: true,
            organizeForMailing: true,
            embedExcelReportsInPdf: false
        } as Compliance.ReturnOutputModel;

        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.ReturnPackageDownloadSettings);
        const setting = _.find(settings, (x: any) => x.name === this._outputSettingName);

        if (setting) {
            returnOutputModel = _.extend(returnOutputModel, setting.value, { filingBatchId });
        }

        if (this._sharedState && this._sharedState.returnOutputModel && this._sharedState.returnOutputModel.filingBatchId === filingBatchId) {
            returnOutputModel.packageIds = this._sharedState.returnOutputModel.packageIds;
        }

        this._sharedState.returnOutputModel = returnOutputModel;
    }

    async saveSharedState(): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.ReturnPackageDownloadSettings);
        let setting = _.find(settings, (x: any) => x.name === this._outputSettingName);

        if (!setting) {
            setting = {
                id: 0,
                name: this._outputSettingName,
                value: null,
                groupId: Core.UserSettingGroup.ReturnPackageDownloadSettings,
                folderId: Core.UserSettingFolder.Default
            };
        }

        setting.value = _.extend(
            {},
            this._sharedState.returnOutputModel,
            { packageIds: [], filingBatchId: null });

        await this._userSettingsService.save(setting);
    }
}
