<ws-modal-wrapper [headerLabel]="modalTitle"
                  (save)="save()"
                  (cancel)="cancel()"
                  [useAceStyles]="true">
    <label>Name</label>
    <div class="d-flex" *ngIf="report.variantName">
        <span class="pe-2 text-nowrap">{{report.reportName}} -</span>
        <input type="text"
               [(ngModel)]="report.variantName"
               maxlength="100"
               class="form-control form-inline">
    </div>
</ws-modal-wrapper>
