<div class="ws-section ws-flex-none">
    <div class="ws-section__header">
        <h4 class="ws-section__header__title">
            Import Data
            <ag-grid-row-count [gridOptions]="gridOptions" [gridTracker]="gridTracker"></ag-grid-row-count>
        </h4>
        <div class="ws-section__header__actions">
            <div class="ws-section__header__actions__item">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="showValidRowsOnly" (change)="showValidRowsOnlyChange()"><small>Show Valid Rows Only</small>
                    </label>
                </div>
            </div>
            <div class="ws-section__header__actions__item">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="showTransferredRows" (change)="showTransferredRowsChange()"><small>Include Transferred Rows</small>
                    </label>
                </div>
            </div>
            <dynamic-field-behavior
                [importFileId]="importFileModel.importFileId"
                [isReadOnly]="true"
                *ngIf="showDynamicFieldConfiguration"
                class="ws-section__header__actions__item"></dynamic-field-behavior>
            <div class="ws-section__header__actions__item">
                <div><small>View:</small></div>
                <div dropdown container="body" placement="bottom right">
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                        {{ selectedGridColumnFilter }}
                        <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.mappedColumns)">{{ gridColumnFilters.mappedColumns }}</a>
                        </li>
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.allColumns)">{{ gridColumnFilters.allColumns }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-section__description small">Review data and make any necessary changes below.  Rows in gray represent data that has been successfully transferred.</div>
</div>
<ws-ag-grid-angular [gridOptions]="gridOptions"
                    (gridReady)="onAgGridReady($event)"
                    [gridId]="gridId"
                    [defaultSizeToFit]="false"
                    [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                    [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                    (bulkUpdate)="bulkUpdate()"
                    [showConfigureLayout]="false"
                    [showResizeColumns]="false"
                    (bulkDelete)="bulkDelete()"
                    [exportOptions]="exportOptions">
</ws-ag-grid-angular>
