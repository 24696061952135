<ws-modal-wrapper headerLabel="Finalize Returns" (save)="submit()" (cancel)="cancel()" [disableSave]="finalizeOptionsForm?.invalid">
    <div class="ws-flex-container-vertical ws-stretch return-finalize-modal">
        <form [formGroup]="finalizeOptionsForm">
            <div class="ws-flex-container-vertical">
                <label>Related Parcel PP Return Filings</label>
                <div class="ws-flex-container-vertical form-fields-container">
                    <label class="ws-flex-none small ws-font-weight-normal" for="attachReturns">
                        <input type="checkbox" id="attachReturns" formControlName="attachReturns"> Attach returns
                    </label>
                    <div class="ws-flex-auto">
                        <label class="ws-flex-none small ws-font-weight-normal" for="embedExcelReportsInPdf">
                            <input type="checkbox" id="embedExcelReportsInPdf" formControlName="embedExcelReportsInPdf"> Embed Excel reports in PDF returns
                        </label>
                    </div>
                </div>
            </div>
            <div class="ws-flex-container-vertical">
                <label>Related Parcel Assessments</label>
                <div class="ws-flex-container-vertical form-fields-container">
                    <label class="ws-flex-none small ws-font-weight-normal" for="syncTargetValue">
                        Sync target value:
                        <ws-select formControlName="syncTargetValue" [options]="syncOptions" labelProperty="value" valueProperty="key"></ws-select>
                    </label>
                    <label class="ws-flex-none small ws-font-weight-normal" for="syncEstimatedFMV">
                        Sync estimated FMV:
                        <ws-select formControlName="syncEstimatedFMV" [options]="syncOptions" labelProperty="value" valueProperty="key"></ws-select>
                    </label>
                </div>
            </div>
            <div class="ws-flex-container-vertical">
                <label>Filing Batch</label>
                <div class="ws-flex-container-vertical form-fields-container">
                    <label class="ws-flex-none small ws-font-weight-normal" for="createNextYear">
                        <input type="checkbox" id="createNextYear" [formControl]="createNextYear"> Create batch for {{nextYear}} returns
                    </label>
                    <div class="ws-flex-container-vertical form-fields-container" formGroupName="nextYearBatchModel">

                        <div class="mb-3" [ngClass]="{ 'has-error': filingBatchGroup.controls.description.touched && filingBatchGroup.controls.description.errors }">
                            <label class="ws-flex-none small ws-font-weight-normal control-label" for="description">Description: </label>
                            <input type="text" class="form-control" id="description" placeholder="Name" formControlName="description">
                            <div *ngIf="filingBatchGroup.controls.description.touched && filingBatchGroup.controls.description.errors" class="error-feedback">
                                <div class="text-danger small">Please provide a filing batch description.</div>
                            </div>
                        </div>

                        <div class="mb-3" [ngClass]="{ 'has-error': filingBatchGroup.controls.dueDate.errors }">
                            <label for="dueDate" class="ws-flex-none small ws-font-weight-normal  control-label">
                                Due Date: <span class="form-control-static"></span>
                                <weissman-datetime [dateOnly]="true"
                                                   [isDisabled]="filingBatchGroup.disabled"
                                                   [inputDate]="filingBatchGroup.controls.dueDate.value"
                                                   (inputDateChange)="onDueDateChanged($event)">
                                </weissman-datetime>
                            </label>
                            <div *ngIf="filingBatchGroup.controls.dueDate.errors?.required" class="error-feedback">
                                <div class="text-danger small">Please provide a due date.</div>
                            </div>
                        </div>

                        <label class="ws-flex-none small ws-font-weight-normal" for="createFilingsAsNecessary">
                            <input type="checkbox" id="createFilingsAsNecessary" formControlName="createFilingsAsNecessary"> Create {{nextYear}} filings as necessary
                        </label>

                    </div>
                </div>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
