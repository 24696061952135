import { ColDef, IGetRowsParams } from 'ag-grid-community';
import { AgGridFilterParams } from '../AgGrid/agGridFilterParams';
import { AgGridColumns } from '../AgGrid/agGridColumns';
import { EntityPicker } from './entityPicker';
import { AgGridDataSourceResult } from '../AgGrid';

export class ParcelPicker extends EntityPicker<Compliance.ReportingParcelModel> {
    constructor(
        private reportingParcelSearchModel: Compliance.ReportingParcelSearchModel,
        private _siteIds: number[],
        private _masterSiteIds: number[],
        private _selectedReportingParcelSiteId: number,
        private _getParcels: (searchParams: any) => Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>>,
        optionFlagText: string) {
        super(reportingParcelSearchModel);
        this.title = 'Parcels';
        this.idRowDataField = 'parcelId';
        this.siteIds = _siteIds;
        this.masterSiteIds = _masterSiteIds;
        this.optionFlag = true;
        this.optionFlagText = optionFlagText;
    }

    protected readonly propertyMap = [
        { name: 'companyName', value: Compliance.ReportingParcelPropertyEnum.CompanyName },
        { name: 'parcelAcctNumWithStatus', value: Compliance.ReportingParcelPropertyEnum.ParcelAcctNumWithStatus },
        { name: 'siteName', value: Compliance.ReportingParcelPropertyEnum.SiteName },
        { name: 'state', value: Compliance.ReportingParcelPropertyEnum.State }
    ];

    searchModel: Compliance.ReportingParcelSearchModel;
    siteIds: number[];
    masterSiteIds: number[];

    getColDefs(): ColDef[] {
        return [
            this.singleSelectColDef,
            {
                headerName: 'Company',
                field: 'companyName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'State',
                field: 'state',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Site',
                field: 'siteName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Parcel Number',
                field: 'parcelAcctNumWithStatus',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            }
        ]
    }

    optionFlagChange(): void {
        this.dataSource.refresh();
    }

    protected async getRowsInternal(params: IGetRowsParams, paginatedSearchModel: any): Promise<AgGridDataSourceResult> {
        if(this.optionFlag && this.siteIds) {
            this.reportingParcelSearchModel.includeSiteIds = this.siteIds.concat(this.masterSiteIds.filter(x => x != null));
        } else {
            this.reportingParcelSearchModel.includeSiteIds = [];
        }

        var result = await this._getParcels(Object.assign(paginatedSearchModel, this.reportingParcelSearchModel));

        return {
            rows: result.data.map(this.addSelectedFlag),
            totalRows: result.data.length
        } as AgGridDataSourceResult;
    }

    public getModalClass() {
        return 'modal-lg';
    }
}
