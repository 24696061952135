<div class="panel-flat-header" style="cursor: pointer;" (click)="expandOrCollapse()">
    <panel-chevron [showBody]="showBody"></panel-chevron>
    <h3>Company Property Characteristics Overrides</h3>
</div>
<div class="card-body" *ngIf="showBody && !initialGet && !addingDescriptor">
    <property-characteristics-picker style="display: inline-block; width: 400px;" *ngIf="hasWritePermission" [entityId]="companyId" entityName="company"
        (descriptorsSelected)="descriptorsSelected($event)" [disabled]="addingDescriptor" [descriptorsToExclude]="[]">
    </property-characteristics-picker>
    <table class="table table-striped table-hover descriptor-overrides">
        <tr>
            <th>Category</th>
            <th>Characteristic Name</th>
            <th>Site Usage</th>
            <th>Parcel Usage</th>
            <th [hidden]="hideComplianceFeatures">Asset Usage</th>
            <th class="text-center">Comments</th>
            <th></th>
        </tr>
        <tbody>
            <tr *ngFor="let descriptorOverride of descriptorOverrides; let i=index;" (mouseenter)="descriptorOverride.isHovered = true" (mouseleave)="descriptorOverride.isHovered = false">
                <td>
                    {{descriptorOverride.categoryName}}
                </td>
                <td>
                    {{descriptorOverride.descriptorName}}
                </td>
                <td>
                    <span *ngIf="!isEditMode(descriptorOverride)" [ngClass]="{'red': isOverridden(descriptorOverride, 'siteUsageID')}">
                        {{getDescriptorUsage(descriptorOverride.descriptorOverride.siteUsageID)}}
                    </span>
                    <select *ngIf="isEditMode(descriptorOverride)" [(ngModel)]="descriptorOverrideToEdit.descriptorOverride.siteUsageID" class="form-select form-select-sm" style="width: auto;">
                        <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                    </select>
                </td>
                <td>
                    <span *ngIf="!isEditMode(descriptorOverride)" [ngClass]="{'red': isOverridden(descriptorOverride, 'parcelUsageID')}">
                        {{getDescriptorUsage(descriptorOverride.descriptorOverride.parcelUsageID)}}
                    </span>
                    <select *ngIf="isEditMode(descriptorOverride)" [(ngModel)]="descriptorOverrideToEdit.descriptorOverride.parcelUsageID" class="form-select form-select-sm" style="width: auto;">
                        <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                    </select>
                </td>
                <td [hidden]="hideComplianceFeatures" style="display: flex">
                    <span *ngIf="!isEditMode(descriptorOverride)" [ngClass]="{'red': isOverridden(descriptorOverride, 'assetUsageID')}">
                        {{getAssetDescriptorUsage(descriptorOverride.descriptorOverride.assetUsageID)}}
                    </span>
                    <select *ngIf="isEditMode(descriptorOverride)" [(ngModel)]="descriptorOverrideToEdit.descriptorOverride.assetUsageID" class="form-select form-select-sm" style="width: auto;">
                        <option *ngFor="let usage of getAssetUsages(descriptorOverride.canAssetUsageBeRemoved)" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                    </select>
                    <div [class.invisible]="!isLoadingAssetUsage[descriptorOverride.descriptorDTO.descriptorID]" style="margin-left: 10px;">
                        <i class="fa fa-spinner fa-pulse"></i>
                    </div>
                </td>
                <td class="text-center">
                    <i *ngIf="descriptorOverride.descriptorDTO.notes" class="fa fa-commenting" [popover]="descriptorOverride.descriptorDTO.notes" triggers="mouseenter:mouseleave"></i>
                </td>
                <td style="white-space: nowrap;">
                    <div class="crud-buttons">
                        <div *ngIf="isEditMode(descriptorOverride)">
                            <button type="button" [disabled]="serverActionSingle[i]" (click)="saveDescriptorOverride(i)"
                                class="btn flat-button icon-button primary-button">
                                <i class="fa fa-save"></i>
                            </button>
                            <button type="button" [disabled]="serverActionSingle[i]" (click)="cancelEdit()"
                                style="margin-left: 5px;"
                                class="btn flat-button icon-button warning-button">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                        <div *ngIf="hasWritePermission && !isEditMode(descriptorOverride)" [class.invisible]="!descriptorOverride.isHovered || descriptorOverrideToEdit">
                            <button type="button"
                                [disabled]="serverActionSingle[i] || isLoadingAssetUsage[descriptorOverride.descriptorDTO.descriptorID]"
                                class="btn flat-button icon-button primary-button"
                                (click)="editDescriptorOverride(descriptorOverride)">
                                    <i class="fa fa-pencil"></i>
                            </button>
                            <button type="button"
                                style="margin-left: 5px;"
                                [disabled]="!descriptorOverride.canAssetUsageBeRemoved || serverActionSingle[i] || isLoadingAssetUsage[descriptorOverride.descriptorDTO.descriptorID]"
                                class="btn flat-button icon-button warning-button"
                                (click)="deleteDescriptorOverride(descriptorOverride, i)">
                                    <i class="fa fa-trash"></i>
                            </button>
                        </div>
                        <div [class.invisible]="!serverActionSingle[i]" style="margin-left: 10px;">
                            <i class="fa fa-spinner fa-pulse"></i>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="card-body text-center" *ngIf="initialGet || addingDescriptor" style="margin-top: 50px; margin-bottom: 50px;">
    <i class="fa fa-spinner fa-pulse fa-5x"></i>
</div>
