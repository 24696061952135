import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IButtonGroupInput } from '../../../UI-Lib/ACE/Button-Group/buttonGroup.component';
import { SearchCategoryType, UserSaveService } from '../userSave.service';

@Component({
    selector: 'save-page',
    templateUrl: './savePage.component.html',
    styleUrls: ['./savePage.component.scss']
})
export class SavePageComponent implements OnInit {
    constructor(private readonly _userSaveService: UserSaveService,
                private readonly _router: UIRouter) {
    }

    @Input() pageTitle: string;
    @Input() newButtonLabel: string;
    @Input() tabSetConfig: IButtonGroupInput;

    @Output() newButtonClicked: EventEmitter<void> = new EventEmitter<void>();

    private _destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._userSaveService.selectedUserSavePage$.pipe(takeUntil(this._destroy$))
            .subscribe((userSavePage) => {
                if (!userSavePage) {
                    return;
                }

                if (!userSavePage.currentSelectedFilter) {
                    userSavePage.currentSelectedFilter = SearchCategoryType.System;
                    const current = this._router.globals.current;
                    this._router.stateService.transitionTo(`${current.name}.${userSavePage.currentSelectedFilter}`);
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
