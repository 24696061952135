import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ColDef,
    ColumnApi,
    GridApi,
    GridReadyEvent
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../Compliance/AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../Compliance/AgGrid/ToolPanel/models';
import { ContactModalOrigin } from '../../constants.new';
import { ContactRoleService } from '../../Role/contact.role.service';
import { UtilitiesService } from '../../UI-Lib/Utilities';
import { UserInstanceService } from '../../User/userInstance.service';
import { ContactModalService } from '../contactModal.service';
import { ContactListSearchModel, ContactsUpgradeService } from '../contacts.service.upgrade';
import { ContactListAgGridDataSource } from './agGridDataSource';

@Component({
    selector: 'contact-list-page',
    templateUrl: './contactListPage.component.html',
    styleUrls: ['./contactListPage.component.scss']
})
export class ContactListPageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _userInstanceService: UserInstanceService,
        private readonly _contactService: ContactsUpgradeService,
        private readonly _contactModalService: ContactModalService,
        private readonly _upgradeNavigationService: UpgradeNavigationServiceHandler,
        private readonly _roleService: ContactRoleService,
        private readonly _utils: UtilitiesService
    ) {}

    @Input() dialogMode: boolean = false;
    @Input() dialogParameters: any;

    header: string = 'Contacts';
    entityInfo: any;
    recipientMode: boolean = false;
    notAllowedRoles = []; //Dima: list of roles, which needs to be removed from the drop down list.E.g. for entity contacts, we can select only one Tax Bill Recipient
    excludeInactive: boolean = false;

    gridId: System.Guid = 'c0899ab1-6634-4a4c-b9cc-f148cb781959';
    columnApi: ColumnApi;
    gridOptions = new AgGridOptionsBuilder({
        getRowNodeId: (data) => data.contactID.toString(),
    })
        .withColumnPinning()
        .withFloatingFilter()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();

            const exportModel: Core.ContactsExportModel = {
                columnsToReturn: columnsToReturn,
                searchModel: searchModel
            };
            const longRunningProcessId = await this._contactService.exportList(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: Compliance.LongRunningProcessTypeEnum.ExportContacts };
        },
        getDisabled: () => !this.canGetRows,
        canCancel: true
    };

    private _gridApi: GridApi;
    private _gridDataSource: ContactListAgGridDataSource;
    private _destroy$: Subject<void> = new Subject();

    get canGetRows(): boolean {
        return !this._gridDataSource?.isRefreshing && this._contactService.canGetRows;
    }

    ngOnInit() {
        this.recipientMode = this.dialogMode && this.dialogParameters.recipientMode;
        if (this.recipientMode) {
            this.header = '';
        }

        this.excludeInactive = !this._userInstanceService.isCurrentInstanceSystem();

        const searchModel: ContactListSearchModel = {
            entityId: null,
            entityType: null,
            excludeInactive: this.excludeInactive,
            instanceId: null
        }

        if (this.dialogMode) {
            this.entityInfo = this.dialogParameters.entityInfo;

            searchModel.entityType = this.entityInfo.type;
            searchModel.entityId = this.entityInfo.id;

            if (!this.recipientMode) {
                // this.gridTracker.clear();
                this.notAllowedRoles = this.dialogParameters.notAllowedRoles;
                this.header = 'Associate Contact to ' //+ this._utils.getEntityName(this.entityInfo.id); This always will return nothing
            }
        } else {
            searchModel.instanceId = this._userInstanceService.getSelectedInstance().instanceId;
        }

        this._contactService.currentSearchModel = searchModel;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent) {
        this._gridApi = event.api;

        // this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._setColumnDefinitions();

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    refresh(): void {
        this._contactService.currentSearchModel.excludeInactive = this.excludeInactive;
        this._refreshDataSource();
    }

    private _setColumnDefinitions(): void {
        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsSmartSearch,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as Core.ContactLongDTO;
                    if (!model) {
                        return '';
                    }

                    return `${model.lastName}, ${model.firstName}`;
                },
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Core.ContactLongDTO;
                        if (!model) {
                            return;
                        }

                        this._openContactDialog(model.contactID);
                    }
                }
            },
            {
                headerName: 'Title',
                field: 'title',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Phone',
                field: 'phone',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Mobile',
                field: 'mobilePhone',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Has User',
                field: 'hasUser',
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) =>  {
                    const model = params.data as Core.ContactLongDTO;
                    if (!model) {
                        return '';
                    }

                    return model.hasUser ? 'Yes' : 'No';
                }
            },
            {
                headerName: 'Email',
                field: 'email',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'entityName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    newWindow: true,
                    isHelpDisabled: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const entry = params.data as Core.ContactLongDTO;
                        if (!entry || entry.entityID === null) {
                            return '';
                        }
                        return `#/company/${entry.entityID}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const entry = params.data as Core.ContactLongDTO;
                        return !entry || entry.entityID === null;
                    }
                } as AgGridLinkCellRendererParams
            }
        ];

        if (this.dialogMode) {
            // TODO implement selection when the other old contact lists have been converted
            // columns.unshift({
            //     colId: 'grid-column-multiselect',
            //     headerName: '',
            //     field: 'assessorId',
            //     width: AgGridColumns.selectionColumnWidth,
            //     suppressSizeToFit: true,
            //     suppressAutoSize: true,
            //     suppressColumnsToolPanel: true,
            //     editable: false,
            //     pinned: 'left',
            //     lockPinned: true,
            //     lockVisible: true,
            //     lockPosition: true,
            //     suppressMovable: true,
            //     headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
            //     headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            //     cellRendererFramework: AgGridMultiSelectedCellRenderer,
            //     cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            // } as ColDef);
        }

        this._gridApi.setColumnDefs(columns);
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        // this.gridTracker.clear();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        // this.gridTracker.clear();

        this._gridDataSource = new ContactListAgGridDataSource(this._gridApi, this._contactService);

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _openContactDialog(contactId: number): Promise<void> {
        const result = await this._contactModalService.openContactDialog(contactId, ContactModalOrigin.ContactsList);
        if (result) {
            const rowNode = this._gridApi.getRowNode(contactId.toString());
            rowNode.data = { ...rowNode.data, ...result };
            this._gridApi.redrawRows({ rowNodes: [rowNode] });
        }
    }
}
