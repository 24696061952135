import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../Compliance/AgGrid';
import { ContactsUpgradeService } from '../contacts.service.upgrade';

export class ContactListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _contactService: ContactsUpgradeService
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Core.ContactModelPropertyEnum>[] = [
        { name: 'name', value: Core.ContactModelPropertyEnum.ContactName },
        { name: 'title', value: Core.ContactModelPropertyEnum.Title },
        { name: 'phone', value: Core.ContactModelPropertyEnum.Phone },
        { name: 'entityName', value: Core.ContactModelPropertyEnum.CompanyName },
        { name: 'hasUser', value: Core.ContactModelPropertyEnum.HasUser },
        { name: 'email', value: Core.ContactModelPropertyEnum.Email }
    ];

    protected canGetRows(): boolean {
        return this._contactService.canGetRows;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const model = this._contactService.currentSearchModel;

        const searchParams: Core.ContactSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityId: model?.entityId,
            entityType: model?.entityType,
            excludeInactive: model?.excludeInactive,
            instanceId: model?.instanceId
        };

        let result;

        if (this._contactService.dialogMode) {
            //TODO implement this once the other old contact lists have been converted
            if (model.entityType === 'assessor' || model.entityType === 'state' || model.entityType === 'collector') {
                // result = await this._contactService.searchRyan(searchText, this.pageNumber, this.pageSize, this.excludeInactive);
            } else {
                // result = await this._contactService.searchScoped(this.entityInfo.id, this.entityInfo.type, searchText, this.pageNumber, this.pageSize, this.excludeInactive);
            }
        } else {
            result = await this._contactService.getList(searchParams);
        }

        if (!result) {
            return null;
        }

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    getSearchParamsWithoutPagination(): Core.ContactSearchModel {
        const model = this._contactService.currentSearchModel;

        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityId: model?.entityId,
            entityType: model?.entityType,
            excludeInactive: model?.excludeInactive,
            instanceId: model?.instanceId
        };
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const model = this._contactService.currentSearchModel;

        const searchParams: Core.ContactSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityId: model?.entityId,
            entityType: model?.entityType,
            excludeInactive: model?.excludeInactive,
            instanceId: model?.instanceId
        };

        return await this._contactService.getRowIds(searchParams);
    }
}
