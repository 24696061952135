<ws-modal-wrapper headerLabel="New Valuation Template"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="templateForm.invalid"
                  [tabindex]="3">
    <ng-container *ngIf="templateForm">
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
        <form [formGroup]="templateForm">
            <div class="mb-4">
                <label>Template Name:</label>
                <input type="text"
                       formControlName="name"
                       class="form-control"
                       placeholder="Enter Template Name"
                       tabindex="1"
                       maxlength="100">
                <p *ngIf="templateForm?.controls?.taxYear?.errors && !templateForm.controls.taxYear.pristine" class="help-block">
                    Template name is required
                </p>
            </div>

            <div class="mb-4">
                <label>Description:</label>
                <input type="text"
                       formControlName="description"
                       class="form-control"
                       placeholder="Enter Description"
                       maxlength="250"
                       tabindex="2">
            </div>
        </form>
    </ng-container>
</ws-modal-wrapper>
