(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('RenameSearchModalController', RenameSearchModalController);

	RenameSearchModalController.$inject = ['$uibModalInstance', 'advancedSearchService', '$rootScope', 'search', 'searchType'];

	function RenameSearchModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, advancedSearchService, $rootScope, search, searchType) {
		var vm = this;
		vm.search = search;
		vm.searchType = searchType;

		vm.save = function() {
			advancedSearchService.patchSearch(vm.search, searchType)
			.then(function(result) {
			    vm.search.rowVersion = result.rowVersion;
				$rootScope.$broadcast('updateAdvancedSearch', vm.search);
				$uibModalInstance.dismiss();
			});
		}

		vm.cancel = function() {
			$uibModalInstance.dismiss();
		}

		activate();

		function activate() {}
	}
})();
