import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription,  BehaviorSubject } from 'rxjs';
import { FormRevisionScheduleRowModel } from './formScheduleList.component';

export interface FormScheduleListGridReportDropdownCellRendererParams extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: FormScheduleListGridReportDropdownCellRendererParams) => boolean;
    onValueChanged: (params: FormScheduleListGridReportDropdownCellRendererParams, value: Core.SavedSearchModel) => void;
    reports$: BehaviorSubject<Core.SavedSearchModel[]>;
}

@Component({
    selector: 'form-schedule-list-grid-report-dropdown',
    template: `
    <div #outerDiv class="edit-on-hover-cell" [ngClass]="isHovering ? 'mouseover' : ''" (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <div class="read-only-label">
            <span *ngIf="schedule && schedule.savedSearch" [title]="schedule.savedSearch.searchName + (schedule.savedSearch.variantName ? ' - ' + schedule.savedSearch.variantName : '')">
            {{ schedule.savedSearch.searchName }}{{ schedule.savedSearch.variantName ? ' - ' + schedule.savedSearch.variantName : '' }}
            </span>
        </div>
        <div class="edit-control" *ngIf="editMode && params.canEdit(params)">
            <select [ngModel]="schedule.savedSearch" (ngModelChange)="onModelChange($event)">
                <option [ngValue]="null"></option>
                <option *ngFor="let r of reports" [ngValue]="r">{{ r.searchName }}{{ r.variantName ? ' - ' + r.variantName : '' }}</option>
            </select>
        </div>
    </div>`,
    styleUrls: ['./../agGridCellStyles.scss']
})
export class FormScheduleListGridReportDropdownCellRendererComponent implements ICellRendererAngularComp {
    private _editModeSub: Subscription;
    private _reportsSub: Subscription;

    params: FormScheduleListGridReportDropdownCellRendererParams;
    schedule: FormRevisionScheduleRowModel;
    editMode: boolean = false;
    isHovering: boolean = false;
    reports: Core.SavedSearchModel[];

    @ViewChild('outerDiv', { read: ElementRef, static: true }) outerDiv: ElementRef;

    agInit(params: FormScheduleListGridReportDropdownCellRendererParams): void {
        this.params = params;
        this.schedule = params.data as FormRevisionScheduleRowModel;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
        this._reportsSub = this.params.reports$.subscribe(x => {
            this.reports = x;
        });
    }

    ngOnDestroy(): void {
        this._editModeSub && this._editModeSub.unsubscribe();
        this._reportsSub && this._reportsSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChange(value: Core.SavedSearchModel) {
        this.params.onValueChanged(this.params, value);
    }

    mouseover($event) {
        this.isHovering = this.editMode && this.params.canEdit(this.params);
    }

    mouseout($event) {
        if (this.isHovering && $event.relatedTarget) {
            // protect against clicks on inner children
            let parent = $event.relatedTarget.parentElement;
            while (parent) {
                if (parent === this.outerDiv.nativeElement) {
                    return;
                }
                parent = parent.parentElement;
            }
            this.isHovering = false;
        }
    }
}
