// This is in /Common, but is currently only used by document processing. 
// It is not general enough for actual quick search

(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdQuickResults', sdQuickResults);

	// sdQuickResults.$inject = ['dependencies'];

	function sdQuickResults() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			restrict: 'E',
			templateUrl: 'app/Common/Quick-Search/quickSearchResults.html',
			scope: {
				criteria: '=',
				resultTypes: '=',
				showCriteria: '='
			}
		};

		return directive;
	}

	Controller.$inject = ['processingService', '$rootScope', 'toastr', '$timeout'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(processingService, $rootScope, toastr, $timeout) {
		var vm = this;

		vm.itemsPerPage = 30;
		vm.results = [];
		vm.resultType = angular.copy(vm.criteria.resultType)

		vm.getResults = getResults;
		vm.resultClicked = resultClicked;

		activate();

		function activate() {
			loadExtraFilters();
			getResults();
		}

		async function getResults() {
			vm.loading = true;

			try {
				var pageNumber = vm.results ? Math.ceil(vm.results.length / vm.itemsPerPage) + 1 : 1;
	
				const resultsObj = await  processingService.search(pageNumber, vm.itemsPerPage, vm.criteria, vm.resultTypes)
				
				if (resultsObj.results && resultsObj.results[0]?.error)
				{
				if (resultsObj.results[0].error == 'too many rows') {
					toastr.error('Please narrow your search criteria', 'Too many results!');
				}
				else if (resultsObj.results[0].error == 'Execution Timeout Expired') {
					toastr.error('Please narrow your search criteria', 'Search has taken too long!');
		    	}
				    vm.showCriteria = true;
			        return;
			   }
	
				vm.results = vm.results.concat(resultsObj.results);
				
				if (vm.results.length === 1) {
					$timeout(function () {
						resultClicked(vm.results[0]);
					}, 1000)
				}
			} finally {
				vm.loading = false;
			}
		}

		function loadExtraFilters() {
			var fieldsToReject = ['parcelPropType', 'parcelAcctNum', 'taxYear'];

			vm.extraFilters = _.chain(vm.criteria.activeFilters)
				.filter('text')
				.reject('default')
				.reject(function (filter) {
					return _.includes(fieldsToReject, filter.field);
				})
				.value();
		}

		function resultClicked(result) {
			vm.criteria.executeSearch = false;

			var type = _.find(vm.resultTypes, { type: vm.resultType });
			var resultId = result.entityID ? result.entityID : result[vm.resultType + 'ID'];

			var entityDetails = {
				entityTypeId: type.id,
				entityId: resultId
			};

			$rootScope.$broadcast('processingQuickSearchClicked', entityDetails);
		}

	}
})();
