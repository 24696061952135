import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'
import { UserSettingsService } from '../../Account/userSettings.service';

@Injectable(
    { providedIn: 'root' }
)
export class IncomeStatementRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) { super(httpClient) }

    getIncomeStatementList(companyId: number, searchParams: Core.IncomeStatementLineSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Core.IncomeStatementLineSearchModel, Core.IncomeStatementLineSearchTotalsModel>> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/rows`, searchParams);
    }

    getIncomeStatementEndDates(companyId: number, siteId?: number): Observable<Date[]> {
        let url = `/api/incomeStatementLine/company/${companyId}/endDates`;

        if(siteId) {
            url += `?siteId=${siteId}`;
        }

        return this.httpGet(url);
    }

    // getReportingAssetList(companyId: number, searchParams: Compliance.AssetSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>> {
    //     return this.httpPost(`/api/asset/company/${companyId}/ReportingAssets`, searchParams);
    // }

    startIncomeStatementLineExport(companyId: number, exportModel: Compliance.IncomeStatementLineExportModel): Observable<number> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/incomeStatementLine/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getIncomeStatementRowIds(companyId: number, searchParams: Core.IncomeStatementLineSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/rowids`, searchParams);
    }

    deleteItem(incomeStatementLineId: number): Observable<void> {
        return this.httpDelete(`api/incomeStatementLine/${incomeStatementLineId}`);
    }

    // get(assetId: number, lienDate: Date, includeTotals: boolean = false, includeChangeDates: boolean = false, includeAssetDescriptors: boolean = false):
    //     Observable<{assetDetailModel : Compliance.AssetDetailModel, assetSearchTotalsModel : Compliance.AssetSearchTotalsModel, changeDates: Date[],
    //         minLienDate?: Date, assetDescriptors?: Compliance.CompanyAssetDescriptorMappingModel[]}> {

    //     const lienDateParam = `lienDate=${lienDate.getUTCMonth() + 1}/${lienDate.getUTCDate()}/${lienDate.getUTCFullYear()}`;
    //     const includeTotalsParam = `includeTotals=${includeTotals ? "true": "false"}`;
    //     const includeChangeDatesParam = `includeChangeDates=${includeChangeDates ? "true" : "false"}`;
    //     const includeAssetDescriptorsParam = `includeAssetDescriptors=${includeAssetDescriptors ? "true" : "false"}`;

    //     return this.httpGet(`/api/asset/${assetId}?${lienDateParam}&${includeTotalsParam}&${includeChangeDatesParam}&${includeAssetDescriptorsParam}`)
    //     .pipe(
    //         map((y: {item1: Compliance.AssetDetailModel, item2: Compliance.AssetSearchTotalsModel, item3: Date[], item4?: Date,
    //             item5?: Compliance.CompanyAssetDescriptorMappingModel[]}) =>
    //             ({assetDetailModel: y.item1, assetSearchTotalsModel: y.item2, changeDates: y.item3, minLienDate: y.item4, assetDescriptors: y.item5})));
    // }


    // update(assetId: number, updateModel: Compliance.AssetUpdateModel): Observable<void> {
    //     return this.httpPut(`/api/asset/${assetId}`, updateModel);
    // }

    // startBulkUpdate(companyId: number, updateModel: Compliance.ReportingAssetBulkUpdateModel): Observable<number> {
    //     return this.httpPut(`/api/asset/company/${companyId}/startBulkUpdate/`, updateModel);
    // }

    startBulkDelete(companyId: number, model: Compliance.IncomeStatementLineBulkDeleteModel): Observable<number> {
        return this.httpPut(`/api/incomestatementline/company/${companyId}/startBulkDelete/`, model);
    }
}
