<div class="container">
    <div class="single-column-layout">
        <h1>Agents</h1>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Address</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let agent of agents" (mouseenter)="agent.hover = true" (mouseleave)="agent.hover = false">
                    <td>
                        <span *ngIf="!isEditMode(agent)">{{agent.name}}</span>
                        <input id="agentName" *ngIf="isEditMode(agent)" type="text" maxlength="50" placeholder="Name" class="form-control" [(ngModel)]="agentInEdit.name" required>
                    </td>
                    <td>
                        <span *ngIf="!isEditMode(agent)">{{agent.company}}</span>
                        <input *ngIf="isEditMode(agent)" type="text" maxlength="50" placeholder="Company" class="form-control" [(ngModel)]="agentInEdit.company">
                    </td>
                    <td [style.width]="isEditMode(agent) ? '300px' : 'initial'">
                        <address-view-edit [address]="isEditMode(agent) ? agentInEdit.address : agent.address" [isEditMode]="isEditMode(agent)"></address-view-edit>
                    </td>
                    <td style="width: 70px;">
                        <div *ngIf="hasEditPermission" class="agent-action-buttons">
                            <div *ngIf="!agentInEdit" [class.invisible]="!agent.hover">
                                <button class="flat-button icon-button" (click)="goToEditMode(agent)"><i class="fa fa-pencil"></i></button>
                                <button class="flat-button icon-button warning-button" (click)="confirmDeleteAgent(agent)"><i class="fa fa-trash"></i></button>
                            </div>
                            <div *ngIf="isEditMode(agent)">
                                <button class="flat-button icon-button" (click)="saveAgent()" [disabled]="!agentInEdit.name"><i class="fa fa-save"></i></button>
                                <button class="flat-button icon-button warning-button" (click)="cancelEdit()"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="!agentInEdit && hasEditPermission" style="text-align: right;">
            <button class="flat-button primary-button" (click)="addNew()">Add New</button>
        </div>
    </div>
</div>