import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail, SiteReportCriteria } from '../report.manager.model';

@Component({
    selector: 'format-output-panel-site',
    template: `
        <div class="panel-flat-body">
            <div class="row">
                <div class="col col-md-4">
                    <div>
                        <label class="select-label">Include Top Level and/or Subsidiary Company Name:</label>
                        <select [(ngModel)]="report.criteria.siteFormatOutputOptions.includeCompanyNameDetails" class="form-select-sm intput-sm">
                            <option [ngValue]="0">None</option>
                            <option [ngValue]="1">Top Level Only</option>
                            <option [ngValue]="2">Subsidiary Only</option>
                            <option [ngValue]="3">Top Level And Subsidiary</option>
                        </select>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="report.criteria.siteFormatOutputOptions.includeFmvChart"> Include FMV Chart
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="report.criteria.siteFormatOutputOptions.includeSiteAppealComments"> Include Site Appeal Comments
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="report.criteria.siteFormatOutputOptions.includeParcelAssessmentGrid"> Include Parcel Annual Details
                        </label>
                    </div>
                    <div class="checkbox" [class.disabled]="!report.criteria.siteFormatOutputOptions.includeParcelAssessmentGrid">
                        <label>
                            <input type="checkbox" [(ngModel)]="report.criteria.siteFormatOutputOptions.includePinnedParcelComment" [disabled]="!report.criteria.siteFormatOutputOptions.includeParcelAssessmentGrid"> Include Pinned Parcel Comment
                        </label>
                    </div>
                    <div style="margin-top: 10px;">
                        <report-order-by [(orderBy)]="report.criteria.orderBy" [isTotal]="false"></report-order-by>
                    </div>
                </div>
                <div class="col col-md-6 offset-md-2">
                    <report-detail-options [(detailReportOptions)]="report.criteria.detailReportOptions"></report-detail-options>
                </div>
            </div>
        </div>
    `
})
export class FormatOutputPanelSiteComponent implements OnInit {
    @Input() report: ReportDetail;

    ngOnInit() {
        this.report.criteria.orderBy = this.report.criteria.orderBy || [];
        this.report.criteria.siteFormatOutputOptions = this.report.criteria.siteFormatOutputOptions || new SiteReportCriteria();
    }
}
