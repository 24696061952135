import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class QueryInfoService {
    constructor() { }
    
    private _refreshSubject: Subject<void> = new Subject();
    
    get refresh$(): Observable<void> { return this._refreshSubject.asObservable(); }
    
    async refresh(): Promise<void> {
        this._refreshSubject.next();
    }
}
