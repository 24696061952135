import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../Compliance/AgGrid';
import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { ResponsibleEntityRepository } from '../responsbileEntity.repository';
import { lastValueFrom } from 'rxjs';

export interface ResponsibleEntityDataSourceParams {
    companyId: number;
}

export class ResponsibleEntityAgGridDataSource extends AgGridDataSourceBase<ResponsibleEntityDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _responsibleEntityRepository: ResponsibleEntityRepository,
        protected _dataSourceParamsFn: () => ResponsibleEntityDataSourceParams) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Core.ResponsibleEntityPropertyEnum>[] = [
        { name: 'name', value: Core.ResponsibleEntityPropertyEnum.Name },
        { name: 'responsibleEntityType', value: Core.ResponsibleEntityPropertyEnum.Type },
        { name: 'number', value: Core.ResponsibleEntityPropertyEnum.Number },
        { name: 'address1', value: Core.ResponsibleEntityPropertyEnum.Address1 },
        { name: 'address2', value: Core.ResponsibleEntityPropertyEnum.Address2 },
        { name: 'city', value: Core.ResponsibleEntityPropertyEnum.City },
        { name: 'state', value: Core.ResponsibleEntityPropertyEnum.State },
        { name: 'zip', value: Core.ResponsibleEntityPropertyEnum.Zip },
        { name: 'contact', value: Core.ResponsibleEntityPropertyEnum.Contact },
        { name: 'phone', value: Core.ResponsibleEntityPropertyEnum.Phone },
        { name: 'email', value: Core.ResponsibleEntityPropertyEnum.Email },
        { name: 'companyCode', value: Core.ResponsibleEntityPropertyEnum.CompanyCode },
        { name: 'companyName', value: Core.ResponsibleEntityPropertyEnum.CompanyName },
        { name: 'allocationDebitAccount', value: Core.ResponsibleEntityPropertyEnum.AllocationDebitAccount },
        { name: 'allocationCreditAccount', value: Core.ResponsibleEntityPropertyEnum.AllocationCreditAccount },
        { name: 'allocationReceivableAccount', value: Core.ResponsibleEntityPropertyEnum.AllocationReceivableAccount },
        { name: 'allocationsExport', value: Core.ResponsibleEntityPropertyEnum.AllocationsExport },
        { name: 'accrualsExport', value: Core.ResponsibleEntityPropertyEnum.AccrualsExport },
    ];


    protected canGetRows(): boolean {
        return !!this._dataSourceParams.companyId;
    }

    async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.ResponsibleEntitySearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._responsibleEntityRepository.getList(this._dataSourceParams.companyId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.pagination = { skip: startIndex, take: endIndex - startIndex + 1} as Core.PaginationModel;

        return await lastValueFrom(this._responsibleEntityRepository.getRowIds(this._dataSourceParams.companyId, searchParams));
    }

    getSearchParamsWithoutPagination(): Core.ResponsibleEntitySearchModel {
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        } as Core.ResponsibleEntitySearchModel;
    }
}
