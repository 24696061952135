import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { PaymentBatchService } from '../paymentBatch.service';
import { sortBy } from 'lodash';
import { BusyIndicatorService } from 'src/app/Busy-Indicator';

export interface AddPaymentsToBatchModalParams {
    topLevelCompanyId: number;
    taskIds: number[]
}

@Component({
    selector: 'payment-batch-add-payments-to-batch-modal',
    template: `
        <ws-modal-wrapper headerLabel="Pending Payment Batches for Company"
            (save)="save()"
            (cancel)="cancel()"
            [disableSave]="isSaving || !paymentBatchIdToAddTo">

                <table class="table table-condensed table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Batch Number</th>
                            <th>Batch Type</th>
                            <th class="text-right"># Payments</th>
                            <th class="text-right">Total Payment Amount</th>
                            <th class="text-right">Due Date</th>
                            <th>Status</th>
                            <th>Company</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let batch of paymentBatches">
                            <td>
                                <input type="radio" [(ngModel)]="paymentBatchIdToAddTo" [value]="batch.paymentBatchId">
                            </td>
                            <td>{{batch.batchNo}}</td>
                            <td>{{batch.batchTypeName}}</td>
                            <td class="text-right">{{batch.paymentCount}}</td>
                            <td class="text-right">{{batch.paymentAmount | currency}}</td>
                            <td class="text-right">{{batch.earliestDueDate | weissmanDateFormat}}</td>
                            <td>{{batch.batchStatus}}</td>
                            <td>{{batch.company}}</td>
                            <td>{{batch.description}}</td>
                        </tr>
                    </tbody>
                </table>

        </ws-modal-wrapper>

    `
})

export class AddPaymentsToBatchModalComponent implements OnInit, IWeissmanModalComponent<AddPaymentsToBatchModalParams, () => Promise<Core.BulkOperationResult[]>> {
    constructor(private readonly _bsModalRef: BsModalRef,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _paymentBatchService: PaymentBatchService) { }

    params: AddPaymentsToBatchModalParams;
    result: () => Promise<Core.BulkOperationResult[]>;

    isSaving: boolean = false;
    paymentBatches: Core.PaymentBatchModel[];
    paymentBatchIdToAddTo: number;

    async ngOnInit() {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading batches...' });

        try {
            this.paymentBatches = await this._paymentBatchService.getAvailablePaymentBatchesForAddPayments(this.params.topLevelCompanyId, this.params.taskIds);
        } finally {
            busyRef.hide();
        }
    }

    async save() {
        this.result = () => {
            return this._paymentBatchService.addPaymentsToBatchByTaskId(this.paymentBatchIdToAddTo, this.params.taskIds);
        };
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}