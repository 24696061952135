import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RowNode, GridOptions } from 'ag-grid-community';
import { IHeaderParams, ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';

// there is a problem with how Json.NET is deserializing this integer value and for some reason cannot use the largest signed int value
const MAX_INT: number = 2000000000;

/**
 * An object used to keep track of a range selection. The ID could be the index of a row in the grid or the actual row ID if the Grid is pre-sorted.
 * As far as this construct goes, it assumes you're always dealing with numbers and it only tracks a from/to value.
 */
interface RangeSelection {
    from: number;
    to: number;
}

/**
 * Parameters for the AgGrid multi-select Header and Cell (renderer) components.
 */
export interface AgGridMultiSelectRendererParams extends IHeaderParams, ICellRendererParams {
    tracker: AgGridMultiSelectTracker,
}

/**
 * Parameters for the AgGrid multi-select Header renderer component.
 */
export interface AgGridMultiSelectHeaderRendererParams extends AgGridMultiSelectRendererParams {
    isCellRendererDisabledFn?: (value: number, data: any) => boolean;
    updateAllRowValues?: (params: AgGridMultiSelectHeaderRendererParams, checked: boolean) => void;
}

/**
 * Parameters for the AgGrid multi-select Cell renderer component.
 */
export interface AgGridMultiSelectCellRendererParams extends AgGridMultiSelectRendererParams {
    isCellRendererHiddenFn?: (value: number, data: any) => boolean;
    isCellRendererDisabledFn?: (value: number, data: any) => boolean;
    updateRowValue?: (params: AgGridMultiSelectCellRendererParams, checked: boolean) => void;
    tooltipText?: (params: AgGridMultiSelectCellRendererParams) => string;
}

export class AgGridMultiSelectTracker {
    constructor(
        private _gridOptions: GridOptions,
        private _getRowIdsFn: (startId: number, endId: number) => Promise<Compliance.QueryResultModel<number>>) {
    }

    private _selectedRowsModel: Compliance.SelectedRowsModel = {
        selectAllRows: false,
        selectedRows: []
    };

    private _selectedRowsSubject = new Subject<Compliance.SelectedRowsModel>();

    // the id of the node in the grid where the click event is originating from
    private _clickedNodeId: number = null;

    // when a range selection API is fired then that information is stored in this array
    // the array is cleared after the API call has completed
    // used to ensure that rows that have a pending range selection cannot be interacted with while that selection is still pending
    private _pendingRangeSelections: RangeSelection[] = [];

    // keeps track of what was the action performed on the node. this is used when selecting a range:
    // option 1: you "check" the node, it will check everything in the range
    // option 2: you "uncheck" the node, it will uncheck everything in the range.
    // note:
    // for a range, the node that will be checked will be the first node defining the range
    // after the action (selection or deselection) is completed, the node you clicked on - the last in the range - will be stored here
    // therefore becoming the first in the definition of a new range (if the user were to follow that with another shift-click)
    private _clickedNodeChecked: boolean = false;

    // keep track if the results in the grid are being filtered
    private _gridHasFilters: boolean = this._isGridFiltered();

    // (when the results in the grid are being filtered) keep track of the header checkbox (select all)
    private _gridHasFiltersAndSelectAllChecked: boolean = false;

    // Flags when the range is being selected so the UI can be notified when it is finished
    private _isSelectingRange: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isSelectingRange$: Observable<boolean> = this._isSelectingRange.asObservable();

    /**
     * An Observable that notifies when the selected rows changes.
     */
    get selectedRows$(): Observable<Compliance.SelectedRowsModel> {
        return this._selectedRowsSubject.asObservable();
    }

    get isSelectingRange$(): Observable<boolean> {
        return this._isSelectingRange$;
    }

    /**
     * Retrieves the model that is tracking selected rows. The model tracks if rows are selected as a group or individually.
     * If all rows are selected then the individual tracking operates in exclusion mode (all selected but exclude these individual rows).
     * If all rows are not selected then the individual tracking operates in inclusion mode (not all selected but include these individual rows).
     */
    getSelectedRowsModel(): Compliance.SelectedRowsModel {
        return Object.assign({}, this._selectedRowsModel);
    }

    /**
     * Retrieves the row IDs for the selected rows model.
     */
    async getSelectedRowIds(): Promise<number[]> {
        // option 1: you're in exclude mode, everything has been selected except for the individual rows being tracked
        // option 2: you're in include mode, all individual tracked rows are the selected ones
        if (this._selectedRowsModel.selectAllRows) {
            // get all rows in this case and exclude the individual selected rows to get the IDs
            const rowIdsResponse = await this._getRowIdsFn(0, MAX_INT);
            const selectedRowIds = rowIdsResponse.data.filter(x => !this._selectedRowsModel.selectedRows.includes(x));
            return selectedRowIds;
        } else {
            // return the selected rows
            return this._selectedRowsModel.selectedRows;
        }
    }

    /**
     * Sets the row model that the tracker is using for the grid that it is associated with.
     * @param model The new row model.
     */
    setSelectedRowsModel(model: Compliance.SelectedRowsModel) {
        this._selectedRowsModel = Object.assign({}, model);

        // refresh filters now that we have a new model, can't call onGridFilterChanged because it will clear the new model
        this._clickedNodeId = null;
        this._gridHasFilters = this._isGridFiltered();
        this._gridHasFiltersAndSelectAllChecked = this._gridHasFilters && this._selectedRowsModel.selectAllRows && this._selectedRowsModel.selectedRows.length === 0;
    }

    /**
     * Checks to see if the grid is displaying any rows as a result of an applied filter.
     */
    hasFilteredRows(): boolean {
        return this._gridHasFilters;
    }

    /**
     * Checks to see if the grid has selected all rows that are being displayed as a result of an applied filter.
     */
    hasSelectedAllFilteredRows(): boolean {
        return this._gridHasFilters && this._gridHasFiltersAndSelectAllChecked;
    }

    /**
     * Checks to see if the grid has selected all rows, with our without filter.
     */
    isSelectAllChecked(): boolean {
        return this._selectedRowsModel.selectAllRows || (this._gridHasFilters && this._gridHasFiltersAndSelectAllChecked);
    }

    /**
     * Are all the rows disabled
     */
    isAllRowsDisabled(): boolean {
        let allRowsCount: number;
        if (this._gridOptions.rowModelType === 'infinite') {
            allRowsCount = this._gridOptions.api.isMaxRowFound() ? this._gridOptions.api.getInfiniteRowCount() : 0;
        } else {
            allRowsCount = this._gridOptions.api.getDisplayedRowCount();
        }

        // If the grid is infinite scroll we can't determine if all rows are disabled
        if (allRowsCount > this._gridOptions.paginationPageSize) {
            return false;
        } else if (allRowsCount === 0) {
            return true;
        }

        // If the grid doesn't have an assigned disable function, all rows will always be enabled
        const columnDef = this._gridOptions.columnApi.getColumn('grid-column-multiselect').getColDef();
        if (!columnDef.cellRendererParams.isCellRendererDisabledFn) {
            return false;
        }

        let disabled = true;
        this._gridOptions.api.forEachNode((node: RowNode) => {
            if (disabled && node.data) {
                disabled = columnDef.cellRendererParams.isCellRendererDisabledFn(node.data.id, node.data);
            }
        });

        return disabled;
    }

    /**
     * Checks to see if the tracker has any rows that are selected.
     */
    hasSelectedRows(): boolean {
        // option 1: all rows are selected so it has selected rows
        // option 2: not all rows are selected but there are individually selected rows so it has selected rows
        return !!(this._selectedRowsModel.selectAllRows || (!this._selectedRowsModel.selectAllRows && this._selectedRowsModel.selectedRows.length));
    }

    /**
     * It retrieves the count of selected rows in the grid.
     */
    getSelectedRowsCount(): number {
        // if everything is selected then we're keeping track of the deselected rows in the selectedRows collection
        // option 1: everything is selected and nothing is deselected so "count = total - length" and length is zero
        // option 2: everything is selected and some rows are deselected so "count = total - length" and length is greater than zero
        if (this._selectedRowsModel.selectAllRows) {
            return this.getTotalRowsCount() - this._selectedRowsModel.selectedRows.length;
        }
        return this._selectedRowsModel.selectedRows.length;
    }

    /**
     * It retrieves the count of all rows in the grid.
     */
    getTotalRowsCount(): number {
        if (this._gridOptions.rowModelType === 'virtual') {
            return this._gridOptions.api.isMaxRowFound() ? this._gridOptions.api.getInfiniteRowCount() : 0;
        } else {
            return this._gridOptions.api.getDisplayedRowCount();
        }
    }

    hasAnythingSelected(): boolean {
        if (this.getSelectedRowsModel().selectAllRows) {
            return (this._selectedRowsModel.selectedRows.length < this.getTotalRowsCount());
        } else {
            return (this.getSelectedRowsCount() > 0);
        }
    }

    /**
     * Checks if the specified row is selected.
     * @param rowId The unique row identifier.
     */
    isRowSelected(rowId: any): boolean {
        // a row is selected if:
        // option 1: everything is selected and this row is not excluded
        // option 2: not everything is selected and this row is included
        const isSelected = _.some(this._selectedRowsModel.selectedRows, x => x === rowId);
        return (this._selectedRowsModel.selectAllRows && !isSelected) || (!this._selectedRowsModel.selectAllRows && isSelected);
    }

    /**
     * Checks to see if the specified row is part of a range selection that has an API call which has not completed yet.
     * @param rowId The unique row identifier.
     */
    isRowPendingRangeSelection(rowId: number, rowNode: RowNode): boolean {
        const nodeId = this._getRowNodeId(rowId, rowNode);
        const rangeSelectionIndex = this._pendingRangeSelections.findIndex(x => x.from <= nodeId && x.to >= nodeId);
        return rangeSelectionIndex !== -1;
    }

    /**
     * Checks to see if any rows in the grid are pending a range selection.
     */
    isGridPendingRangeSelection(): boolean {
        return this._pendingRangeSelections.length > 0;
    }

    /**
     * Notifies that the grid filters have changed. Every time filters change, the tracker resets the "all checked" status and the node index it's tracking for Shift clicks.
     */
    onGridFilterChanged(): void {
        // when grid filter is changed, clear selections so there aren't rows selected which aren't visible with the current filter
        this.clear();

        this._clickedNodeId = null;
        this._gridHasFilters = this._isGridFiltered();
        this._gridHasFiltersAndSelectAllChecked = this._gridHasFilters && this._selectedRowsModel.selectAllRows && this._selectedRowsModel.selectedRows.length === 0;
    }

    /**
     * Notifies that the grid sort order has changed. Every time sort changes the tracker resets the node index it's tracking for Shift clicks.
     */
    onGridSortChanged(): void {
        this._clickedNodeId = null;
    }

    /**
     * Toggles the selection of a row.
     * If the user is holding the Shift key during this toggle, all result between the current row and previously clicked row will be toggled.
     * @param rowId The unique row identifier.
     * @param rowNode The grid node for that row.
     * @param shiftClicked A value indicating if the Shift key is clicked during this toggle event.
     */
    async toggleRowChecked(rowId: number, rowNode: RowNode, shiftClicked: boolean): Promise<void> {
        // using an index of NULL as the initial value, if they shift click the first node in the grid, ignore it and treat it as a click
        if (shiftClicked && this._clickedNodeId !== null) {
            const lastClickedNodeId = this._clickedNodeId;
            const currentClickedNodeId = this._getRowNodeId(rowId, rowNode);
            // follow the action they took on the clicked node; if they checked it, check the entire range, otherwise uncheck the range
            await this._selectRange(currentClickedNodeId, lastClickedNodeId, this._clickedNodeChecked);
            this._selectedRowsSubject.next(this._selectedRowsModel);
            this._gridOptions.api.redrawRows();
        } else {
            // look for the row in the include/exclude list and add/remove to the list
            // option 1: the row is not there, add it to the list (checked)
            // option 2: the row is there, remove it from the list (uncheck)
            const index = this._selectedRowsModel.selectedRows.indexOf(rowId);
            if (index === -1) {
                this._selectedRowsModel.selectedRows.push(rowId);
            } else {
                this._selectedRowsModel.selectedRows.splice(index, 1);
            }
            this._selectedRowsSubject.next(this._selectedRowsModel);
            this._gridOptions.api.redrawRows({ rowNodes: [rowNode] });
        }

        // record the index and the action (checked/unchecked) of where the click happened. the node was "checked" if:
        // option 1: is included in the list of individual rows and not all rows are selected
        // option 2: is not included in the list of individual rows and all rows are selected
        this._clickedNodeId = this._getRowNodeId(rowId, rowNode);
        this._clickedNodeChecked = (this._selectedRowsModel.selectedRows.indexOf(rowId) !== -1 && !this._selectedRowsModel.selectAllRows) ||
            (this._selectedRowsModel.selectedRows.indexOf(rowId) === -1 && this._selectedRowsModel.selectAllRows);

        // if the grid could have been filtered and the "select all" checkbox was checked; reset it
        this._gridHasFiltersAndSelectAllChecked = false;
    }

    /**
     * Toggles the selection of all rows.
     * When filters are applied, it will select all within the results that the filter returns.
     */
    async toggleAllChecked(): Promise<void> {
        // reset the click node index; they have to start over and click on a node in order to support Shift click
        this._clickedNodeId = null;

        if (this._gridHasFilters) {
            // the grid has filters:
            // option 1: we have currently selected all the results; get rows in the entire filter range and exclude them
            // option 2: we have currently selected none of the results; get rows in the entire filter range and include them
            const selecting: boolean = !this._gridHasFiltersAndSelectAllChecked;
            this._gridHasFiltersAndSelectAllChecked = !this._gridHasFiltersAndSelectAllChecked;

            if ((!this._selectedRowsModel.selectAllRows && !selecting)) {
                // If we are only deselecting all, do so and skip the range selection
                this._selectedRowsModel.selectedRows = [];
            } else {
                await this._selectRange(0, MAX_INT, selecting);
            }
        } else {
            // grid does not have filters. check which mode we're in and do the opposite:
            // option 1: we are in exclude mode; select all and clear out individual row tracking list
            // option 2: we are not in exclude mode; select all rows and clear out the individual row tracking list
            this._gridHasFiltersAndSelectAllChecked = false;
            if (this._selectedRowsModel.selectAllRows && this._selectedRowsModel.selectedRows.length) {
                this._selectedRowsModel.selectAllRows = true;
                this._selectedRowsModel.selectedRows = [];
            } else {
                this._selectedRowsModel.selectAllRows = !this._selectedRowsModel.selectAllRows;
                this._selectedRowsModel.selectedRows = [];
            }
        }

        this._selectedRowsSubject.next(this._selectedRowsModel);
        this._gridOptions.api.redrawRows();
    }

    /**
     * Clears any selection tracking returning it to the original state where nothing is selected.
     */
    clear(notify: boolean = true): void {
        this._pendingRangeSelections = [];
        this._selectedRowsModel = {
            selectAllRows: false,
            selectedRows: []
        };

        this._gridHasFiltersAndSelectAllChecked = false;

        if (this._gridOptions && this._gridOptions.api) {
            this._gridOptions.api.refreshHeader();
        }

        if (notify) {
            this._selectedRowsSubject.next(this._selectedRowsModel);
        }
    }

    /**
     * This function will fetch all the IDs between two indices defining a range and select/deselect that range of IDs.
     * @param aIndex The ID of the first row in the selection range.
     * @param bIndex The ID of the last (second) row in the selection range.
     * @param selectRange Mark the IDs in that range as selected.
     */
    private async _selectRange(aIndex: number, bIndex: number, selectRange: boolean): Promise<void> {
        this._isSelectingRange.next(true);

        // take them in either order and sort them
        const startIndex = aIndex > bIndex ? bIndex : aIndex;
        const endIndex = aIndex > bIndex ? aIndex : bIndex;

        let rowsResponse: Compliance.QueryResultModel<number>;

        const rangeSelection: RangeSelection = { from: startIndex, to: endIndex };
        this._pendingRangeSelections.push(rangeSelection);

        try {
            rowsResponse = await this._getRowIdsFn(startIndex, endIndex);
        } finally {
            const index = this._pendingRangeSelections.indexOf(rangeSelection);
            this._pendingRangeSelections.splice(index, 1);
        }

        // we are going to select or deselect, no matter what that action is we must check the mode the grid is in as well
        // if selecting range
        // option 1: we are in include mode so the IDs need to be added to the collection of individually tracked rows
        // option 2: we are in exclude mode, so those IDs need to be removed from the collection of individually tracked rows
        //           by excluding them we are automatically marking them as selected as we're in select all (exclude) mode
        // if deselecting range, the same as above but reversed

        // as far as the collection of individually tracked rows and how it gets modified, the following applies
        // you want to add the collection only if:
        //   option 1: you are in include mode (selectAllRows = false) and are trying to select the range of IDs
        //   option 2: you are in exclude mode (selectAllRows = true) and are trying to deselect the range of IDs
        // you want to remove from the collection only if:
        //   option 1: you are in exclude mode (selectAllRows = true) and are trying to select the range of IDs
        //   option 2: you are in include mode (selectAllRows = false) and are trying to deselect the range of IDs
        if ((!this._selectedRowsModel.selectAllRows && selectRange) || (this._selectedRowsModel.selectAllRows && !selectRange)) {
            // add to the individually tracked row Ids (using a Set data type to enforce unique IDs)
            const idSet = new Set(this._selectedRowsModel.selectedRows.concat(rowsResponse.data));
            this._selectedRowsModel.selectedRows = Array.from(idSet);
            this._isSelectingRange.next(false);
            return;
        }

        if ((this._selectedRowsModel.selectAllRows && selectRange) || (!this._selectedRowsModel.selectAllRows && !selectRange)) {
            // remove from the individually tracked row Ids (using a Set data type to enforce unique IDs)
            const idsToRemove = new Set(rowsResponse.data);
            const selectedRowsWithoutIds = this._selectedRowsModel.selectedRows.filter(x => !idsToRemove.has(x));
            this._selectedRowsModel.selectedRows = selectedRowsWithoutIds;
            this._isSelectingRange.next(false);
            return;
        }

        // all options should be covered
        console.warn('Could not determine how to multi-select a range in the grid.');
    }

    /**
     * Checks if the grid has any active filters applied.
     */
    private _isGridFiltered(): boolean {
        return this._gridOptions.api.isAnyFilterPresent();
    }

    /**
     * Retrieves the identifier of a row node given the ID of a row (user specified) and a row node (grid specific).
     * Takes into account how the tracker is setup to identify range selection (via grid node index) or if a pre-sort is implied and the row ID should be used.
     */
    private _getRowNodeId(rowId: number, rowNode: RowNode): number {
        return rowNode.rowIndex;
    }
}
