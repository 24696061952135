<div class="p-5">
    <div *ngFor="let feed of feeds" class="form-check">
        <input class="form-check-input"
               type="checkbox"
               [(ngModel)]="feed.checked"
               [disabled]="isDisabled(feed.type)"
               [id]="feed.type">
        <label class="form-check-label"
               [for]="feed.type">
            {{feed.label}}
        </label>
    </div>

    <div class="mt-5">
        <button type="button" 
            class="flat-button primary-button" 
            (click)="download()"
            [disabled]="isDownloadDisabled">
                Download Selected Items
        </button>
    </div>
</div>