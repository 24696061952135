import { Component, Input, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { AnchorSREF } from '../UI-Lib/Directives/Anchor-Route/anchorRoute.directive';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs/';

export interface ItemsByLetter {
    letter: string;
    items: {
        name: string;
        badge?: number;
        sref: AnchorSREF
    } []
}

@Component({
    selector: 'alphabetized-list',
    template: `
        <tabset #letterTabs type="pills">
            <tab *ngFor="let letter of itemsByLetters"
                [heading]="letter.letter"
                [disabled]="!letter.items.length"
                customClass="larger-letters"
                (selectTab)="onSelect($event)">
                    <ul class="list-group">
                        <a anchor-route
                           [sref]="item.sref"
                           *ngFor="let item of letter.items"
                           class="list-group-item list-group-item-action text-decoration-none">
                            {{item.name}}
                            <span class="badge bg-secondary float-end">{{item.badge}}</span>
                        </a>
                    </ul>
            </tab>
        </tabset>
    `,
    styles: [`
        :host {
            display: flex;
            justify-content: center;
        }

        .list-group {
            max-width: 400px;
            margin: auto;
            margin-top: 20px;
            font-size: 150%;
        }

        .badge {
            font-size: 100%;
        }
    `]
})
export class AlphabetizedListComponent implements OnInit, AfterViewInit {
    @Input() itemsByLetters: ItemsByLetter[];
    @Input() startingLetter: string;
    @Output() letterSelected: EventEmitter<string> = new EventEmitter();
    @ViewChild('letterTabs', {static: false}) letterTabs: TabsetComponent;

    private readonly _alphabet = '#ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    ngOnInit() {
        this.itemsByLetters = _.map(this._alphabet, letter => {
            let itemsByLetter = _.find(this.itemsByLetters, {letter});
            itemsByLetter = itemsByLetter || { letter,  items: [] };

            itemsByLetter.items = _.sortBy(itemsByLetter.items, 'name');

            return itemsByLetter;
        });
    }

    ngAfterViewInit() {
        const tabIndex = this.startingLetter
            ? this._alphabet.indexOf(this.startingLetter)
            : _.findIndex(this.itemsByLetters, x => x.items.length);

        this.letterTabs.tabs[tabIndex].active = true;
    }

    onSelect(data: TabDirective) {
        this.letterSelected.emit(data.heading);
    }

}

