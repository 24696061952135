import {FilingTypeSummary} from '../Compliance/compliance.model';

export class AnnualGridYearsInfo{
    years: AnnualDetailYear[];
    annualYearIDs: number[];
    totalYears: number;
}

export class AnnualDetailYear {
    annualYearID: number;
    annualYear1: number;
    totalTaxes: number;
    effectiveRate: number;
    baseRate: number;
    taxIsEstimated: boolean;
    targetValue: number;
    totalNumberofYears: number;
    calcProjected: boolean;
    collapsedAppealStatus: string;
    collapsedSavings: string;
    totalAppealCountForYear: number;
    annualGridDetails: AnnualDetailAssessment[];
    complianceFilingTypeSummaries: FilingTypeSummary[];
    latestBudgetAmount: number;
    latestBudgetId: number;
    recalculatedStatus: any;
    recalculatedSavings: any;
    expanded: boolean;
    collapsedIsUnderAppeal: boolean;
    rowVersion: any;
}

//This isn't great, but this is to drive the add year dropdown, which was done a bit hacky in the old solution
//ideally we should type the selcted and options
export class AnnualDetailYearList {
    constructor(years: AnnualDetailYear[]) {
        this.years = years;
    }

    years: AnnualDetailYear[];
    selected: any;
    options: any;
}

export class AnnualDetailAssessment {
    annualAssessmentID: number;
    status: number;
    revisionDesc: string;
    revisionNum: number;
    appealStatus: string;
    savings: string;
    annualGridComponents: AnnualDetailComponent[];
    isUnderAppeal: boolean;
    rowVersion: any;
}

export class AnnualDetailComponent {
    constructor(componentName: string, fairMarketValue: number, assessmentComponentTypeID: number) {
        this.componentName = componentName;
        this.fairMarketValue = fairMarketValue;
        this.assessmentComponentTypeID = assessmentComponentTypeID;
    }

    componentName: string;
    fairMarketValue: number;
    sequence: number;
    assessmentClassComponentID: number;
    assessmentComponentTypeID: number;
    efAction?: string;
}

export class AddYearModel {
    constructor(newYear: number) {
        this.year = newYear;
    }

    year: number;
}




