import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface TotalFMVRendererParams extends ICellRendererParams {
    activityStatusId: (params: TotalFMVRendererParams) => number;
    isFmvInclude: (params: TotalFMVRendererParams) => boolean;
    getTotal: (params: TotalFMVRendererParams) => string;
}


@Component({
    selector: 'total-fmv-renderer',
    template: `<img *ngIf="activityStatusId === 0" src="/images/inactive.jpg" alt="Inactive" class="table-inactive">
               <img *ngIf="activityStatusId === 1" src="/images/active-pending.jpg" alt="Inactive" class="table-inactive">
			   <span class="fa-stack" *ngIf="!isFmvInclude" title="FMV excluded from site analysis">
				   <i class="fa fa-stack-1x" style="font-weight: bold;">Σ</i>
				   <i class="fa fa-ban fa-stack-2x text-danger fa-flip-horizontal"></i>
			   </span>
			   {{total}}`
})
export class TotalFMVRendererComponent implements ICellRendererAngularComp {

    params: TotalFMVRendererParams;

    agInit(params: TotalFMVRendererParams): void {
        this.params = params;
    }

    get activityStatusId(): number {
        return this.params.activityStatusId(this.params);
    }

    get isFmvInclude(): boolean {
        return this.params.isFmvInclude(this.params);
    }

    get total(): string {
        return this.params.getTotal(this.params);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
