(function () {
	'use strict';

	var VIEW_TEMPLATE_URL = 'app/Permission/User-Groups/_nameInfo.html';
	var CONTROLLER_NAME = 'sd.User.Group.NameInfo.Controller';

	angular
		.module('weissmanApp')
		.directive('sdUserGroupsNameInfo', sdUserGroupsNameInfo);

	sdUserGroupsNameInfo.$inject = [];

	function sdUserGroupsNameInfo() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			templateUrl: VIEW_TEMPLATE_URL,
			controller: CONTROLLER_NAME,
			controllerAs: 'vm',
			replace: true,
			link: link,
			restrict: 'E',
			scope: {
				userGroup: '=',
				editMode: '=',
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}
})();
