<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-truncate ws-flex-auto">
            Packages
            <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
        </h3>
    </div>
    <div class="panel-flat-body">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
