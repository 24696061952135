import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForProgressCell extends ICellRendererParams {
    resume: (params: ICellRendererParamsForProgressCell) => void;
}

@Component({
    selector: 'entity-import-list-grid-progress',
    template: '<entity-import-progress [importFile]="importFile" [showStepName]="false" (click)="params.resume(params)"></entity-import-progress>'
})
export class EntityImportListGridProgressCellRendererComponent implements ICellRendererAngularComp {
    importFile: Compliance.ImportFileModel;
    params: ICellRendererParamsForProgressCell;

    agInit(params: ICellRendererParamsForProgressCell): void {
        this.importFile = params.data as Compliance.ImportFileModel;
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
