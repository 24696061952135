import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../../Compliance/AgGrid';
import { ErrorLogListAgGridDataSource } from './agGridDataSource';
import { WeissmanDateTimeFormatPipe } from '../../../UI-Lib/Pipes';
import { Subscription } from 'rxjs';
import { ErrorLogService } from '../errorLog.service';
import { ErrorLogRepository } from '../errorLog.repository';
import { ErrorLogListGridActionCellRendererComponent, ErrorLogListGridActionCellRendererParams } from './agGridActionCellRenderer.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { ErrorLogDetailsComponent, ErrorLogDetailsParams } from '../Error-Log-Details/errorLogDetails.component';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';

@Component({
    selector: 'error-log-list',
    templateUrl: './errorLogList.component.html',
    styleUrls: ['./errorLogList.component.scss']
})
export class ErrorLogListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe,
        private readonly _errorLogRepository: ErrorLogRepository,
        private readonly _errorLogService: ErrorLogService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _restrictService: RestrictService,
        private readonly _routerService: UpgradeNavigationServiceHandler) {
    }

    private _gridApi: GridApi;
    private _gridDataSource: ErrorLogListAgGridDataSource;
    private _refreshSub: Subscription;

    gridId: System.Guid = '0735BCF2-E246-4F49-8CDB-AAFF1C9C46A0';
    isInitialized: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withColumnPinning()
        .buildDefault(this);
    agGridReady: boolean;

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    ngOnInit(): void {
        if (!(this._restrictService.isInRole(Roles.ADMINOPERATIONSVIEW) || this._restrictService.isInRole(Roles.ADMINOPERATIONSEDIT))) {
            this.navigateToUnauthorized();
            return;
        }

        this._refreshSub = this._errorLogService.refresh$.subscribe(() => {
            this._refreshDataSource();
        });

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._refreshSub && this._refreshSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'GUID',
                field: 'guid',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'ID',
                field: 'id',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell',
                hide: true
            },
            {
                headerName: 'User',
                field: 'contactName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Host Name',
                field: 'machineName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Message',
                field: 'message',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Route',
                field: 'route',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'HTTP Method',
                field: 'httpMethod',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Date',
                field: 'date',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true) : ''
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: ErrorLogListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this._viewDetails.bind(this)
                } as ErrorLogListGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'date',
                sort: 'desc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
        this.agGridReady = true;
    }

    async refresh(): Promise<void> {
        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            await this._errorLogService.refresh();
        } finally {
            busyRef.hide();
        }
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    private async _viewDetails(params: ErrorLogListGridActionCellRendererParams): Promise<void> {
        const errorLog = params.data as Core.ErrorLogModel;
        if (!errorLog) {
            return;
        }

        const detailParams: ErrorLogDetailsParams = {
            errorLogId: errorLog.id,
            errorLogGuid: null
        };

        await this._modalService.showAsync(ErrorLogDetailsComponent, detailParams, 'modal-xl');
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this._gridDataSource = new ErrorLogListAgGridDataSource(this._gridApi, this._errorLogRepository);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }
}
