import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AttachmentFormComponent } from './Form/attachmentForm.component';
import { FileViewerComponent } from './Viewer/file.viewer.component';
import { AttachmentService } from './attachment.service';
import { BillImageModalComponent } from './Bill-Image-Modal/billImageModal.component';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { AttachmentModalComponent } from './Modal/attachmentModal.component';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        BusyIndicatorModule
    ],
    declarations: [
        AttachmentFormComponent,
        FileViewerComponent,
        BillImageModalComponent,
        AttachmentModalComponent
    ],
    exports: [
        AttachmentFormComponent,
        FileViewerComponent
    ],
    providers: [
        AttachmentService
    ]
})
export class AttachmentModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('sdAttachmentForm', AttachmentFormComponent);
        hybridAdapterUtility.downgradeNg2Provider('sdAttachmentService', AttachmentService);
    }
}
