import { ButtonSize, ButtonState, ButtonStyle } from '@ace/shared';
import { Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { IButtonGroupInput } from '../../UI-Lib/ACE/Button-Group/buttonGroup.component';
import { SearchCategoryType } from '../User-Save/userSave.service';

@Component({
    selector: 'saved-smart',
    templateUrl: './savedSmart.component.html',
    styleUrls: ['./savedSmart.component.scss']
})
export class SavedSmartComponent {
    constructor(private readonly _router: UIRouter,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService) {
    }

    tabSetConfig: IButtonGroupInput = {
        buttons: [
            {
                id: SearchCategoryType.System,
                content: 'SYSTEM SMARTS',
                routerOutlet: 'savedSmart.system',
                size: ButtonSize.Small
            },
            {
                id: SearchCategoryType.User,
                content: 'MY SAVED SMARTS',
                routerOutlet: 'savedSmart.user',
                size: ButtonSize.Small
            },
            {
                id: SearchCategoryType.Favorite,
                content: 'FAVORITES',
                routerOutlet: 'savedSmart.favorite',
                size: ButtonSize.Small
            }
        ],
        singleSelect: {
            activeStyle: ButtonStyle.Primary,
            inactiveStyle: ButtonStyle.Secondary,
            activeState: ButtonState.Info,
            inactiveState: ButtonState.Info
        }
    };

    newSmart(): void {
        this._advancedSearchPersistenceService.clearSearchData();
        this._productAnalyticsService.logEvent('click-explore-SMART');
        this._router.stateService.transitionTo('search');
    }
}
