<label style="padding-left: 100px;" (click)="reflowChart()" *ngIf="activityMode==='table'">
    {{config.tableTitle}}
</label>
<div class="float-end" *ngIf="config && config.xAxis.categories.length">
    <label>
        <input type="radio" [(ngModel)]="activityMode" value="graph" (click)="chartTypeSelected('graph')"> Graph
    </label>
    <label style="padding-left: 20px;">
        <input type="radio" [(ngModel)]="activityMode" value="table" (click)="chartTypeSelected('table')"> Table
    </label>
</div>
<div [hidden]="activityMode != 'graph' || (config && !config.xAxis.categories.length)" class="portfolio-analysis-chart" #chart style="height: 100%; width: 100%;"></div>
<div *ngIf="config && !config.xAxis.categories.length" style="display: flex; justify-content: center; height: 100%; align-items: center; font-size: 1vmax;"><em>No Data</em></div>
<div [hidden]="activityMode != 'table'" *ngIf="config" style="height: 89%; width: 100%; overflow-y: auto;">

	<table class="table  table-bordered" style="text-align: right">
		<thead>
			<tr>
				<th style="text-align: center">Tax Year</th>
				<th style="text-align: center"
					*ngFor="let seriesItem of sortByPropType(config.series)"
					[hidden]="config.chartType !='totalFMV'">
					{{seriesItem.name}}
				</th>

				<th *ngIf="showColumn(TotalValueNames.TOTALFMV, ['totalFMV', 'totalFMVPerSqFt', 'totalFMVPerUnit'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.TOTALFMV)}}
				</th>
				<th *ngIf="showColumn(TotalValueNames.FMVPERSQFT, ['totalFMVPerSqFt', 'totalFMVPerUnit'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.FMVPERSQFT)}}
				</th>
				<th *ngIf="showColumn(TotalValueNames.FMVPERUNIT, ['totalFMVPerSqFt', 'totalFMVPerUnit'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.FMVPERUNIT)}}
				</th>

				<th *ngIf="showColumn(TotalValueNames.LANDFMV, ['landFMVPerSqFt', 'landFMVPerAcre'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.LANDFMV)}}
				</th>
				<th *ngIf="showColumn(TotalValueNames.LANDFMVPERSQFT, ['landFMVPerSqFt', 'landFMVPerAcre'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.LANDFMVPERSQFT)}}
				</th>
				<th *ngIf="showColumn(TotalValueNames.LANDFMVPERACRE, ['landFMVPerSqFt', 'landFMVPerAcre'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.LANDFMVPERACRE)}}
				</th>

				<th *ngIf="showColumn(TotalValueNames.TOTALTAX, ['totalTaxesUngrouped'])" style="text-align: center">
					{{getColumnTitle(TotalValueNames.TOTALTAX)}}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let xAxisCategory of reverseIt(config.xAxis.categories)">
				<td style="text-align: center">{{xAxisCategory}}</td>

				<td *ngFor="let seriesItem of sortByPropType(config.series)"
					[ngClass]="{'not-actual': false}"
					[hidden]="config.chartType !='totalFMV'">
					<span *ngIf="getCellValue(seriesItem, xAxisCategory)">{{getCellValue(seriesItem, xAxisCategory)| currency:'USD':'':'1.0-0'}}</span>
				</td>

				<td *ngIf="showColumn(TotalValueNames.TOTALFMV, ['totalFMV', 'totalFMVPerSqFt', 'totalFMVPerUnit'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.TOTALFMV) | currency:'USD':'':getPrecision(TotalValueNames.TOTALFMV)}}
				</td>
				<td *ngIf="showColumn(TotalValueNames.FMVPERSQFT, ['totalFMVPerSqFt', 'totalFMVPerUnit'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.FMVPERSQFT) | currency:'USD':'symbol':getPrecision(TotalValueNames.FMVPERSQFT)}}
				</td>
				<td *ngIf="showColumn(TotalValueNames.FMVPERUNIT, ['totalFMVPerSqFt', 'totalFMVPerUnit'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.FMVPERUNIT) | currency:'USD':'symbol':getPrecision(TotalValueNames.FMVPERUNIT)}}
				</td>

				<td *ngIf="showColumn(TotalValueNames.LANDFMV, ['landFMVPerSqFt', 'landFMVPerAcre'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.LANDFMV) | currency:'USD':'':getPrecision(TotalValueNames.LANDFMV)}}
				</td>
				<td *ngIf="showColumn(TotalValueNames.LANDFMVPERSQFT, ['landFMVPerSqFt', 'landFMVPerAcre'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.LANDFMVPERSQFT) | currency:'USD':'symbol':getPrecision(TotalValueNames.LANDFMVPERSQFT)}}
				</td>
				<td *ngIf="showColumn(TotalValueNames.LANDFMVPERACRE, ['landFMVPerSqFt', 'landFMVPerAcre'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.LANDFMVPERACRE) | currency:'USD':'symbol':getPrecision(TotalValueNames.LANDFMVPERACRE)}}
				</td>

				<td *ngIf="showColumn(TotalValueNames.TOTALTAX, ['totalTaxesUngrouped'])" [ngClass]="{'not-actual': false}">
					{{getTotalValueForYear(xAxisCategory, TotalValueNames.TOTALTAX) | currency:'USD':'symbol':getPrecision(TotalValueNames.TOTALTAX)}}
				</td>
			</tr>
		</tbody>
	</table>

	<div>
		<div class="float-end">
			<span class="anchor-style" [hidden]="config.loadedAllYears || (config.xAxis.categories.length >= data.taxYears.length)" (click)="showAllYearsClicked.emit()">Show All Years</span>
		</div>
	</div>
</div>
