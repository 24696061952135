import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { SharedPipesModule } from '../UI-Lib/Pipes/shared-pipes.module';
import { ReportManagerComponent } from './Manager/report.manager.component';
import { ReportManagerModalComponent } from './Manager/reportManagerModal.component';
import { ReportManagerModalService } from './Manager/reportManagerModal.service';
import { ReportManagerSelectEntitiesComponent } from './Manager/select.entities.component';
import { SaveReportModalComponent } from './Modals/save-report.component';
import { ReportManagerService } from './Manager/report.manager.service';
import { ReportManagerSelectEntitiesService } from './Manager/select.entities.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FurtherLimitPanelPsrComponent } from './Manager/Further-Limit-Panel/further.limit.panel.psr.component';
import { FurtherLimitPanelBudgetComponent } from './Manager/Further-Limit-Panel/further.limit.panel.budget.component';
import { FormatOutputPanelBudgetComponent } from './Manager/Format-Output-Panel/format.output.panel.budget.component';
import { FormatOutputPanelPsrComponent } from './Manager/Format-Output-Panel/format.output.panel.psr.component';
import { FurtherLimitPanelMaarComponent } from './Manager/Further-Limit-Panel/further.limit.panel.maar.component';
import { FurtherLimitPanelProgressStatusComponent } from './Manager/Further-Limit-Panel/further.limit.panel.progress.status.component';
import { FurtherLimitPanelSiteComponent } from './Manager/Further-Limit-Panel/further.limit.panel.site.component';
import { ReportOrderByComponent } from './Manager/Format-Output-Panel/Order-By/reportOrderBy.component';
import { FormatOutputPanelSiteComponent } from './Manager/Format-Output-Panel/format.output.panel.site.component';
import { ReportDetailOptionsComponent } from './Manager/Format-Output-Panel/report.detail.options.component';
import { FurtherLimitPanelSchedulesSummaryComponent } from './Manager/Further-Limit-Panel/further.limit.panel.schedules.summary.component';
import { FormatOutputPanelSchedulesSummaryComponent } from './Manager/Format-Output-Panel/format.output.panel.schedules.summary.component';
import { FurtherLimitPanelAssetDetailComponent } from './Manager/Further-Limit-Panel/further.limit.panel.asset.detail.component';
import { ReturnModule } from '../Compliance/Return/return.module';
import { FormatOutputPanelAssetDetailComponent } from './Manager/Format-Output-Panel/format.output.panel.asset.detail.component';
import { FurtherLimitPanelUserActivityComponent } from './Manager/Further-Limit-Panel/further.limit.panel.user.activity.component';
import { FormatOutputPanelUserActivityComponent } from './Manager/Format-Output-Panel/format.output.panel.user.activity.component';
import { ReportingService } from './reporting.service';
import { FurtherLimitPanelBudgetVarianceComponent } from './Manager/Further-Limit-Panel/further.limit.panel.budget.variance.component';
import { FormatOutputPanelBudgetVarianceComponent } from './Manager/Format-Output-Panel/format.output.panel.budget.variance.component';
import {
    FurtherLimitPanelSupportInstanceEscalationActivityComponent
} from './Manager/Further-Limit-Panel/further.limit.panel.support.instance.escalation.activity.component';
import {
    FormatOutputPanelSupportInstanceEscalationActivityComponent
} from './Manager/Format-Output-Panel/format.output.panel.support.instance.escalation.activity.component';

//const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BrowserAnimationsModule,
        TabsModule,
        ModalModule,
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        ReturnModule
    ],
    declarations: [
        ReportManagerComponent,
        SaveReportModalComponent,
        ReportManagerSelectEntitiesComponent,
        FurtherLimitPanelPsrComponent,
        FurtherLimitPanelBudgetComponent,
        FurtherLimitPanelMaarComponent,
        FurtherLimitPanelProgressStatusComponent,
        FormatOutputPanelPsrComponent,
        FormatOutputPanelBudgetComponent,
        FurtherLimitPanelSiteComponent,
        ReportOrderByComponent,
        FormatOutputPanelSiteComponent,
        ReportDetailOptionsComponent,
        ReportManagerModalComponent,
        FurtherLimitPanelSchedulesSummaryComponent,
        FormatOutputPanelSchedulesSummaryComponent,
        FurtherLimitPanelAssetDetailComponent,
        FormatOutputPanelAssetDetailComponent,
        FurtherLimitPanelUserActivityComponent,
        FormatOutputPanelUserActivityComponent,
        FurtherLimitPanelBudgetVarianceComponent,
        FormatOutputPanelBudgetVarianceComponent,
        FurtherLimitPanelSupportInstanceEscalationActivityComponent,
        FormatOutputPanelSupportInstanceEscalationActivityComponent
    ],
    providers: [ReportManagerService, ReportManagerModalService, ReportManagerSelectEntitiesService, ReportingService],
    exports: [ReportManagerSelectEntitiesComponent]
})
export class ReportingModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        //Entry components must have only two words, for example: parcelActivity not parcelActivitySummary
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        hybridAdapterUtility.downgradeNg2Component('reportManager', ReportManagerComponent);

        hybridAdapterUtility.downgradeNg2Provider('reportingService', ReportingService);
    }
}
