import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { WsAgGridWrapperModule } from '../../Compliance/AgGrid/AgGridWrapper/wsAgGridWrapper.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { UILibModule } from '../../UI-Lib/UILib.module';
import { ActivityService } from './activity.service';
import {
    ActivityStatusCellRendererComponent
} from './Parcel-Filter/Activity-Status-Cell-Renderer/activityStatusCellRenderer.component';
import { ParcelFilterModalComponent } from './Parcel-Filter/parcelFilterModal.component';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UILibModule,
        WsAgGridWrapperModule,
        AgGridExtensionsModule
    ],
    declarations: [
        ParcelFilterModalComponent,
        ActivityStatusCellRendererComponent
    ],
    exports: [ActivityStatusCellRendererComponent]
})
export class ActivityModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Provider('sd.Activity.Service', ActivityService);
    }
}
