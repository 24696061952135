import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import ScheduleGroupTypeEnum = Compliance.ScheduleGroupTypeEnum;

export interface ReturnAssetFilter {
    name: string;
    statuses: Compliance.ReturnAssetPriorReturnStatusEnum[];
    isDeduction: boolean;
    targetNonReportableSchedule?: boolean;
    targetReportableSchedule?: boolean;
    scheduleGroupType?: Compliance.ScheduleGroupTypeEnum
}

@Component({
    selector: 'return-asset-status-selector',
    templateUrl: './returnAssetStatusSelector.component.html',
    styleUrls: ['./returnAssetSelect.component.css'],
})
export class ReturnAssetStatusSelectorComponent implements OnInit {
    allAssetStatusFilter: ReturnAssetFilter = {
        name: 'All Assets at Lien Date',
        statuses: [],
        isDeduction: false
    };

    changedAssetStatusFilter: ReturnAssetFilter = {
        name: 'Changed',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.Changed],
        isDeduction: false
    };

    notChangedAssetStatusFilter: ReturnAssetFilter = {
        name: 'Not Changed',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged],
        isDeduction: false
    };

    newAssetStatusFilter: ReturnAssetFilter = {
        name: 'Acquisitions',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.New],
        isDeduction: false
    };

    transferInAssetStatusFilter: ReturnAssetFilter = {
        name: 'Transfers In',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn],
        isDeduction: false
    };

    newOrTransferInAssetStatusFilter: ReturnAssetFilter = {
        name: 'Acquisitions or Transfers In',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.New, Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn],
        isDeduction: false
    };

    changedToReportedFilter: ReturnAssetFilter = {
        name: 'Changed to Reported',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleNonReportableToReportable],
        isDeduction: false
    };

    changedCostIncreasedAssetStatusFilter: ReturnAssetFilter = {
        name: 'Cost Increases',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased],
        isDeduction: false
    };

    allAdditionsAssetStatusFilter: ReturnAssetFilter = {
        name: 'Total Additions',
        statuses: [
            Compliance.ReturnAssetPriorReturnStatusEnum.New,
            Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn,
            Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased,
            Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleNonReportableToReportable
        ],
        isDeduction: false
    };

    disposedAssetStatusFilter: ReturnAssetFilter = {
        name: 'Disposals',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.Retired],
        isDeduction: true
    };

    transferOutAssetStatusFilter: ReturnAssetFilter = {
        name: 'Transfers Out',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut],
        isDeduction: true
    };

    disposedOrTransferOutAssetStatusFilter: ReturnAssetFilter = {
        name: 'Disposals or Transfers Out',
        statuses: [
            Compliance.ReturnAssetPriorReturnStatusEnum.Retired,
            Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut],
        isDeduction: true
    };

    changedCostDecreasedAssetStatusFilter: ReturnAssetFilter = {
        name: 'Cost Decreases',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostDecreased],
        isDeduction: true,
        scheduleGroupType: ScheduleGroupTypeEnum.Unrestricted
    };

    changedToNotReportedFilter: ReturnAssetFilter = {
        name: 'Changed to Not Reported',
        statuses: [Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleReportableToNonReportable],
        isDeduction: true,
        targetReportableSchedule: false,
        targetNonReportableSchedule: true
    };

    allDeductionsAssetStatusFilter: ReturnAssetFilter = {
        name: 'Total Deductions',
        statuses: [
            Compliance.ReturnAssetPriorReturnStatusEnum.Retired,
            Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut,
            Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostDecreased,
            Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleReportableToNonReportable
        ],
        isDeduction: true
    };

    assetAdditionsStatusFilter: ReturnAssetFilter[] = [
        this.newAssetStatusFilter,
        this.transferInAssetStatusFilter,
        this.newOrTransferInAssetStatusFilter,
        this.changedCostIncreasedAssetStatusFilter,
        this.changedToReportedFilter,
        this.allAdditionsAssetStatusFilter
    ];

    assetDeductionsStatusFilter: ReturnAssetFilter[] = [
        this.disposedAssetStatusFilter,
        this.transferOutAssetStatusFilter,
        this.disposedOrTransferOutAssetStatusFilter,
        this.changedCostDecreasedAssetStatusFilter,
        this.changedToNotReportedFilter,
        this.allDeductionsAssetStatusFilter
    ];

    assetStatusFilters: ReturnAssetFilter[] = [
        this.allAssetStatusFilter,
        this.changedAssetStatusFilter,
        this.notChangedAssetStatusFilter
    ].concat(this.assetAdditionsStatusFilter).concat(this.assetDeductionsStatusFilter);

    @Input() isDisabled: boolean = false;
    @Input() selectedValue: Compliance.ReturnAssetPriorReturnStatusEnum[];
    @Input() selectDeductions: boolean = false;

    @Input() reportDataSource: string = null;

    @Input() allowFilteringItemsBasedOnDataSource: boolean = false;

    @Output() selectedValueChange: EventEmitter<ReturnAssetFilter> = new EventEmitter<ReturnAssetFilter>();

    @ViewChild('selectedAssetStatusFilterEl', { static: true }) selectedAssetStatusFilterEl: ElementRef;

    selectedFilterValue: ReturnAssetFilter = this.assetStatusFilters[0];

    disableDeductionsDisposalsOrTransferOuts: boolean = false;
    disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions: boolean = false;

    ngOnInit(): void {

        if (!this.selectedValue) {
            this.selectedFilterValue = this.assetStatusFilters[0];
            this.selectedValueChange.emit(this.selectedFilterValue);
        } else {
            this.selectedFilterValue = this.assetStatusFilters.find(item => _.isEqual(item.statuses, this.selectedValue))
        }

        this.setOptionsToSuppress();
    }

    onSelectedAssetStatusFilterChange(filter: ReturnAssetFilter): void {
        // when select deductions in disabled prevent selecting the item (but emit event so modal can be displayed)
        if (!filter.isDeduction) {
            this.selectedFilterValue = filter;
        } else {
            if (this.selectDeductions) {
                this.selectedFilterValue = filter;
            }

            // select previously selected filter in the UI
            this.selectedAssetStatusFilterEl.nativeElement.selectedIndex = this.assetStatusFilters.indexOf(this.selectedFilterValue);
        }

        this.selectedValueChange.emit(filter);
    }

    private setOptionsToSuppress()
    {
        const additionalAssetDetails = 'AdditionalAssetDetails';
        const disposedAssetDetails = 'DisposedAssetDetails';
        const assetDetails = 'AssetDetails';
        const nonReportableAssetDetails = 'NonReportableAssetDetails';
        const assetSummary = 'AssetSummary';

        this.disableDeductionsDisposalsOrTransferOuts =
            this.reportDataSource == additionalAssetDetails ||
            this.reportDataSource == assetDetails ||
            this.reportDataSource == nonReportableAssetDetails ||
            this.reportDataSource == assetSummary;

        this.disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions =
            this.reportDataSource == disposedAssetDetails;
    }
}
