import {Component} from '@angular/core';
import { DashboardDataService } from '../Dashboard/dashboard.data.service';

@Component({
    selector: 'landing-selector',
    template: `
        <landing *ngIf="!showDashboard"></landing>
        <dashboard *ngIf="showDashboard"></dashboard>
    `,
    styleUrls: ['./landing.selector.component.scss']
})
export class LandingSelectorComponent {
    showDashboard: boolean;

    constructor(private dashboardDataService: DashboardDataService) {
        this.showDashboard = dashboardDataService.getShowDashBoardUserSetting();
    }
}
