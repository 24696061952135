import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

export interface TransferConfirmParams {
    evaluatedInsertCount?: number,
    evaluatedUpdateCount?: number,
    evaluatedDeleteCount?: number,
    evaluatedNoChangeCount?: number,
    effectiveDate?: Date;
    assetOwnershipType?: string;
    accrualAdjustmentAmountValueType?: string;
    importContentType: Compliance.ImportContentTypeIdEnum;
}

@Component({
    selector: 'transfer-confirm',
    templateUrl: './transferConfirm.component.html'
})
export class TransferConfirmComponent implements OnInit, IWeissmanModalComponent<TransferConfirmParams, boolean> {
    constructor(private readonly _bsModalRef: BsModalRef){
    }

    params: TransferConfirmParams;
    result: boolean;

    ngOnInit(): void {
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    ok(): void {
        this.result = true;
        this._bsModalRef.hide();
    }

    get isAssetsImport(): boolean {
        return this.params.importContentType === Compliance.ImportContentTypeIdEnum.Assets;
    }
}
