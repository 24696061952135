import { Component, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { Agent } from './agents.model';
import { AgentService } from './agents.service';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { RestrictService, InstanceRights } from '../Common/Permissions/restrict.service';
import { TimerService } from '../UI-Lib/Utilities/timer.service';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: 'agents-page',
    templateUrl: './agents.page.component.html',
    styleUrls: ['./agents.page.component.scss']
})
export class AgentsPageComponent implements OnInit {
    agents: Agent[];
    agentInEdit: Agent;
    hasEditPermission: boolean = false;

    constructor(
        private readonly _renderer2: Renderer2,
        private readonly _agentService: AgentService,
        private readonly _toastr: ToastrService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _restrictService: RestrictService,
        private readonly _timer: TimerService
    ) {
        this.hasEditPermission = _restrictService.hasInstanceRight(InstanceRights.AGENTINFORMATIONEDIT);
    }

    ngOnInit() {
        this._getAgents();
    }

    private async _getAgents(): Promise<void> {
        this.agents = await this._agentService.getAgents();
    }

    goToEditMode(agent: Agent): void {
        this.agentInEdit = _.cloneDeep(agent);
    }

    isEditMode(agent: Agent): boolean {
        return this.agentInEdit && this.agentInEdit.agentID == agent.agentID;
    }

    addNew(): void {
        this.agents.push(new Agent());
        this.agentInEdit = new Agent();

        this._timer.setTimeout(() => {
            this._selectNameField();
        });
    }

    cancelEdit(): void {
        this.agentInEdit = undefined;
        this.agents = _.filter(this.agents, 'agentID');
    }

    async saveAgent(): Promise<void> {
        if(this.agentInEdit.address.email && !emailRegex.test(this.agentInEdit.address.email.toLowerCase())) {
            this._toastr.error('Email Invalid!');

            return;
        }

        if(this.agentInEdit.agentID) {
            const savedAgent: Agent = await this._agentService.updateAgent(this.agentInEdit);
            const i: number = _.findIndex(this.agents, {agentID: this.agentInEdit.agentID});

            this.agents[i] = savedAgent;
        } else {
            const newAgent: Agent = await this._agentService.createAgent(this.agentInEdit);

            this.agents.push(newAgent);
        }

        this.cancelEdit();
    }

    private _selectNameField(): void {
        const onElement = this._renderer2.selectRootElement('#agentName');
        onElement.focus();
    }

    async confirmDeleteAgent(agent: Agent): Promise<void> {
        const result: number = await this._messageBoxService.open({
            message: `Are you sure you want to delete ${agent.name}?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (result === MessageBoxResult.OK) {
             this._agentService.deleteAgent(agent.agentID).then(() => {
                _.remove(this.agents, agent);
             });


        }
    }
}
