<!--<div *ngIf="!isAttachmentPopup">-->
<!--    <temp-banner></temp-banner>-->
<!--</div>-->

<ace-auth-oidc-wrapper
    *ngIf="aceOidcWrapperEnabled">
</ace-auth-oidc-wrapper>

<logout-message *ngIf="appState === AppStates.LoggedOut"></logout-message>

<nub *ngIf="appState === AppStates.LoggedIn"></nub>
