import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { TaxRateCommandCenterService } from './tax.rate.command.center.service';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../Compliance/AgGrid';
import { CommandCenterSearchModel, CommandCenterPropertyEnum } from '../tax.rate.model';
import { EntityType } from '../../constants.new';

export interface TaxRateDataSourceParams {
    entityType: number;
    stateId: number;
    assessorId: number;
    collectorId: number;
    onlyPendingQC: boolean;
    taxYearBegin: number;
    taxYearEnd: number;
}

export class TaxRateCommandCenterAgGridDataSource extends AgGridDataSourceBase<TaxRateDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _taxRateCommandCenterService: TaxRateCommandCenterService,
        protected _dataSourceParamsFn: () => TaxRateDataSourceParams
    ) { super(_gridApi); }

    // All of the xxxx_1 names are from switching to Tax Rate Areas. Ag Grid considers these
    // to be separate fields, even though they're named the same. Probably a good thing, but
    // this is kind of hacky
    private readonly _propertyMap: Compliance.NameValuePair<CommandCenterPropertyEnum>[] = [
        { name: 'name', value: CommandCenterPropertyEnum.Name },
        { name: 'name_1', value: CommandCenterPropertyEnum.Name },
        { name: 'code', value: CommandCenterPropertyEnum.Code },
        { name: 'code_1', value: CommandCenterPropertyEnum.Code },
        { name: 'disabled', value: CommandCenterPropertyEnum.Disabled },
        { name: 'disabled_1', value: CommandCenterPropertyEnum.Disabled },
        { name: 'address', value: CommandCenterPropertyEnum.Address },
        { name: 'address_1', value: CommandCenterPropertyEnum.Address },
        { name: 'assessorName', value: CommandCenterPropertyEnum.AssessorName },
        { name: 'assessorName_1', value: CommandCenterPropertyEnum.AssessorName },
        { name: 'collectorName', value: CommandCenterPropertyEnum.CollectorName },
        { name: 'collectorName_1', value: CommandCenterPropertyEnum.CollectorName },
        { name: 'hasComments', value: CommandCenterPropertyEnum.HasComments },
        { name: 'hasAttachments', value: CommandCenterPropertyEnum.HasAttachment },
        { name: 'ptxId', value: CommandCenterPropertyEnum.PtxId },
        { name: 'categoryName', value: CommandCenterPropertyEnum.TaxAuthorityCategory },
        { name: 'taxableAssessmentType', value: CommandCenterPropertyEnum.TaxableAssessmentType },
        { name: 'obtainInAdvance', value: CommandCenterPropertyEnum.ObtainInAdvance },
        { name: 'defaultAnnualRateIncrease', value: CommandCenterPropertyEnum.DefaultAnnualRateIncrease },
        { name: 'certificationDate', value: CommandCenterPropertyEnum.CertificationDate },
        { name: 'qcRequestTimeUtc', value: CommandCenterPropertyEnum.QcRequestTimeUtc },
        { name: 'qcRequestUserFullName', value: CommandCenterPropertyEnum.QcRequestUserFullName }
    ];

    protected canGetRows(): boolean {
        return this._dataSourceParams.stateId !== undefined;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<any> {
        const searchParams: CommandCenterSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityType: this._dataSourceParams.entityType,
            stateId: this._dataSourceParams.stateId || null,
            assessorId: this._dataSourceParams.assessorId || null,
            collectorId: this._dataSourceParams.collectorId || null,
            // yearBegin: 0,
            // yearEnd: 0,
            onlyPendingQC: this._dataSourceParams.onlyPendingQC,
            // includeTotals: false,
            // includeOnlyTotals: false,
            // selectedRows: {
            //     selectAllRows: false,
            //     selectedRows: []
            // },
            // selectedRowProperty: null,
            // overrideFilterColumns: [],
            // advancedAssetFilters: null,
            // range: {
            //     startId: 0,
            //     endId: null
            // }

        };

        const result =  await this._taxRateCommandCenterService.getList(searchParams);
        const data =  this._dataSourceParams.entityType == EntityType.TaxAuthority ? result.taxAuthorities : result.taxRateAreas;

        if(this._dataSourceParams.entityType == EntityType.TaxRateArea) {
            this._taxRateCommandCenterService.allTaxAuthorities = result.taxAuthorities;
        }

        const dataSourceResult: AgGridDataSourceResult = {
            rows: data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: new Date()
        };

        return dataSourceResult;
    }

    getSearchModelWithoutPagination(){
        const searchParams: any = {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityType: this._dataSourceParams.entityType,
            stateId: this._dataSourceParams.stateId || null,
            assessorId: this._dataSourceParams.assessorId || null,
            collectorId: this._dataSourceParams.collectorId || null,
            yearBegin: this._dataSourceParams.taxYearBegin,
            yearEnd: this._dataSourceParams.taxYearEnd,
            onlyPendingQC: this._dataSourceParams.onlyPendingQC,
        };
        return searchParams;
    }

    getSearchModelForBulkUpdate(){
        const searchParams: CommandCenterSearchModel = {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            entityType: EntityType.TaxAuthority,
            stateId: this._dataSourceParams.stateId || null,
            assessorId: this._dataSourceParams.assessorId || null,
            collectorId: this._dataSourceParams.collectorId || null,
            // yearBegin: 0,
            // yearEnd: 0,
            onlyPendingQC: this._dataSourceParams.onlyPendingQC,
        };
        return searchParams;
    }
}
