
import {empty as observableEmpty,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CachedRepository } from '../../Common/Data/cached.repository';
import { HttpClient } from '@angular/common/http';
import { DataCache } from '../../Common/Data/dataCache';

const  cacheAreaName = 'AssetClassifications';

@Injectable()
export class AssetClassificationRepository extends CachedRepository<Compliance.AssetClassificationModel> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
        this.dataCache = new DataCache(httpClient, cacheAreaName);
    }

    getAssetClassifications(): Observable<Compliance.AssetClassificationModel[]> {
        return this.getCached<Compliance.AssetClassificationModel[]>(`/api/assetclassification`);
    }
    
    getAssetClassificationById(id: number): Observable<Compliance.AssetClassificationModel> {
        if (id == null) {
            return observableEmpty();
        }
        return this.getCached<Compliance.AssetClassificationModel[]>(`/api/assetclassification`).pipe(map(result => {
            const classification = this._findClassificationById(id, result);
            return classification;
        }));
    }

    private _findClassificationById(id: number, classifications: Compliance.AssetClassificationModel[]): Compliance.AssetClassificationModel {
        if (classifications.length === 0) {
            return null;
        }
        
        const foundClassification = classifications.find(ac => ac.assetClassificationId === id);
        if (foundClassification) {
            return foundClassification;
        }
        
        let childClassifications = [];
        classifications.forEach(ac => {
            if (ac.childClassifications) {
                childClassifications = childClassifications.concat(ac.childClassifications);
            }
        });
        return this._findClassificationById(id, childClassifications);
    }
}
