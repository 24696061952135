import { Component, OnInit } from '@angular/core';
import { GridApi, GridReadyEvent, ColDef, GridOptions, RowNode } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormService } from '../form.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { AgGridCheckboxCellRendererComponent, ICellRendererParamsForAgGridCheckbox } from '../../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

export interface FormAssessorAddressPickerParams {
    formRevisionYearAssessorId: number;
}

@Component({
    selector: 'form-assessor-address-picker',
    templateUrl: './formAssessorAddressPicker.component.html',
    styleUrls: ['./formAssessorAddressPicker.component.scss']
})
export class FormAssessorAddressPickerComponent implements OnInit, IWeissmanModalComponent<FormAssessorAddressPickerParams, boolean> {
    constructor(
        private readonly _formService: FormService,
        private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _addressId: number;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && params.data.isSelected
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    params: FormAssessorAddressPickerParams;
    result: boolean;

    assessorAddresses: Compliance.FormRevisionYearAssessorAddressModel[];
    fryaAddress: Compliance.FormRevisionYearAssessorAddressModel = null;
    selectedAssessorAddress: Compliance.FormRevisionYearAssessorAddressModel = null;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    get addressChanged(): boolean {
        return this.selectedAssessorAddress && this.selectedAssessorAddress.addressID !== this._addressId;
    }

    async ngOnInit(): Promise<void> {
        const addresses = await this._formService.getAssessorAddresses(this.params.formRevisionYearAssessorId);

        this.assessorAddresses = _.filter(addresses, x => !x.isFormRevisionYearAssessor);
        this.fryaAddress = _.find(addresses, x => x.isFormRevisionYearAssessor);
        this.selectedAssessorAddress = _.find(addresses, x => x.isSelected);

        this._addressId = this.selectedAssessorAddress && this.selectedAssessorAddress.addressID;
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'isSelected',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                suppressAutoSize: true,
                resizable: false,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: this._onSelectEdited.bind(this),
                    isVisible: (params) => {
                        const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                        return assessorAddress && !assessorAddress.isFormRevisionYearAssessor;
                    },
                    canEdit: (params) => {
                        const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                        return assessorAddress && !assessorAddress.isFormRevisionYearAssessor;
                    },
                    canEnterEditMode: () => true
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'Address',
                field: 'address',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
                valueFormatter: (params) => {
                    const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                    if (!assessorAddress) {
                        return '';
                    }

                    let combined = '';
                    if (assessorAddress.address1) {
                        combined += assessorAddress.address1;
                    }

                    if (assessorAddress.address2) {
                        combined += ` ${assessorAddress.address2}`;
                    }

                    return combined;
                },
                filterValueGetter: (params) => {
                    const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                    if (!assessorAddress) {
                        return '';
                    }

                    let combined = '';
                    if (assessorAddress.address1) {
                        combined += assessorAddress.address1;
                    }

                    if (assessorAddress.address2) {
                        combined += ` ${assessorAddress.address2}`;
                    }

                    return combined;
                }
            },
            {
                headerName: 'City',
                field: 'city',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
            },
            {
                headerName: 'State',
                field: 'state',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
                valueFormatter: (params) => {
                    const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                    if (!(assessorAddress && assessorAddress.state)) {
                        return '';
                    }
                    return assessorAddress.state.abbr;
                },
                filterValueGetter: (params) => {
                    const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
                    if (!(assessorAddress && assessorAddress.state)) {
                        return '';
                    }
                    return assessorAddress.state.abbr;
                }
            },
            {
                headerName: 'Zip',
                field: 'zip',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
            },
            {
                headerName: 'Correspondence',
                field: 'correspondenceType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
            },
            {
                headerName: 'Remark',
                field: 'remark',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMovable: true,
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        if (!this.selectedAssessorAddress) {
            return;
        }
        
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            if (this.addressChanged) {
                await this._formService.updateAssessorAddress(
                    this.params.formRevisionYearAssessorId,
                    this.selectedAssessorAddress.addressID);

                this.result = true;
            }

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.assessorAddresses)) {
            return;
        }

        this._gridApi.setRowData(this.assessorAddresses);
        this._gridApi.setPinnedTopRowData([ this.fryaAddress ]);
        this._gridApi.sizeColumnsToFit();
    }

    private _onSelectEdited(params: ICellRendererParamsForAgGridCheckbox, newValue: boolean): void {
        const assessorAddress = params.data as Compliance.FormRevisionYearAssessorAddressModel;
        if (!assessorAddress) {
            return;
        }

        const update: RowNode[] = [];

        // on select, ensure all other rows are de-selected
        if (newValue) {
            this._gridApi.forEachNode((node: RowNode) => {
                const address = node.data as Compliance.FormRevisionYearAssessorAddressModel;

                if (address && address !== assessorAddress && address.isSelected) {
                    address.isSelected = false;
                    update.push(node);
                }
            });
        }

        assessorAddress.isSelected = newValue;
        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.selectedAssessorAddress = newValue ? assessorAddress : null;
    }
}
