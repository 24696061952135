import { Injectable } from '@angular/core';
import { CompanyRepository } from '../../Compliance/Repositories';
import { lastValueFrom } from 'rxjs';
import { AddYearConfig, UpdateCompanySettingConfig } from '../../Compliance/Repositories/company.repository';
import {ToastrService} from 'ngx-toastr';

export interface CompanyModel extends Weissman.Model.Domain.Company {
    parentId: number;
}

@Injectable()
export class CompanyService {
    constructor(private readonly _companyRepository: CompanyRepository,
                private readonly _toastrService: ToastrService) {}

    private _company: CompanyModel;

    async load(companyId: number, noCache: boolean, navigationRequest: any): Promise<any> {
        const config = {
            navigationRequest: !!navigationRequest
        };

        const fetchedCompany = await lastValueFrom(this._companyRepository.loadCompany(companyId, config));

        // The below needs to be refactored, we shouldn't be doing in-line viewmodels
        // this has caused multiple bugs on the site as we add new things
        this._updateParentId(fetchedCompany);
        if (!noCache) {
            this._company = fetchedCompany;
        }
        return fetchedCompany;
    }

    async loadCompanyParent(companyId: number, topLevelCompanyId: number): Promise<any> {
        const response = await lastValueFrom(this._companyRepository.loadCompanyParent(companyId, topLevelCompanyId));
        // A bit of a hack; if there's no row version on the parent company, we know this was a stubbed-in
        // company and the user doesn't have permission for it. We need to know that so we can disable the
        // link to the top-level company.
        if (response.rowVersion === null) {
            response.noPermission = true;
        }
        return response;
    }

    getCompanyName(companyId: number): Promise<string> {
        return lastValueFrom(this._companyRepository.getCompanyName(companyId));
    }

    getSubsidiaries(topLevelCompanyId: number, excludeInactive: boolean = false): Promise<Core.NameIdPair[]> {
        return lastValueFrom(this._companyRepository.getSubsidiaries(topLevelCompanyId, excludeInactive));
    }

    async update(company: any): Promise<any> {
        try {
            this._company = await lastValueFrom(this._companyRepository.updateCompany(company));
        } catch (e) {
            this._toastrService.error(e?.error?.message ?? e.message);
        }

        return this._company;
    }

    isCompanyNameInUseAlready(name: string, topLevelCompanyId: number, companyId: number): Promise<boolean> {
        const model: Core.CompanyNameLookupDTO = {
            companyName: name,
            companyId: companyId,
            topLevelCompanyId: topLevelCompanyId
        };

        return lastValueFrom(this._companyRepository.isCompanyNameInUse(model));
    }

    isCompanyCodeInUseAlready(companyCode, topLevelCompanyID: number, companyID: number, ppReturnPreparationAllowed: boolean): Promise<boolean> {
        if(ppReturnPreparationAllowed && (!companyCode || 0 === companyCode.length))
        {
            return Promise.resolve(false);
        }

        const config: Core.CompanyCodeLookupDTO = {
            companyCode: companyCode,
            topLevelCompanyID: topLevelCompanyID,
            companyID: companyID
        };
        return lastValueFrom(this._companyRepository.isCompanyCodeInUseAlready(config));
    }

    search(criteria: any, pageSize: number, pageNumber: number, topLevelOnly: boolean = false, favoriteOnly: boolean = false,
           activeOnly?: boolean, instanceIdFilter?: number, clientInstanceId?: number, useExplicitCrossInstanceListForPermCheck?: boolean): Promise<any> {
        const config : any = {
            params: {
                search: criteria,
                pageSize: pageSize,
                pageNumber: pageNumber,
                topLevelOnly: topLevelOnly,
                favoriteOnly: favoriteOnly,
                activeOnly: activeOnly || false
            }
        };

        if(instanceIdFilter) {
            config.params.instanceIdFilter = instanceIdFilter;
        }

        if (clientInstanceId) {
            config.params.clientInstanceId = clientInstanceId;
        }

        if (useExplicitCrossInstanceListForPermCheck) {
            config.params.useExplicitCrossInstanceListForPermCheck = useExplicitCrossInstanceListForPermCheck;
        }

        return lastValueFrom(this._companyRepository.search(config));
    }

    get(): any {
        this._updateParentId(this._company);
        return this._company;
    }

    // TODO This is used in some AngularJS code and is passed to the dom. Once things are upgraded this should be changed to a getter.
    isTopLevel = (): boolean => {
        return !this._company?.parentId;
    };

    create(company: any): Promise<any> {
        return lastValueFrom(this._companyRepository.create(company));
    }

    getStatesWithSites(companyId: number, activeOnly: boolean, includeSubsidiaries: boolean): Promise<string[]> {
        return lastValueFrom(this._companyRepository.getStatesWithSites(companyId, activeOnly, includeSubsidiaries));
    }

    getByUserPermission(userId: number, isCache: boolean = false): Promise<any> {
        return lastValueFrom(this._companyRepository.getByUserPermission(userId, isCache));
    }

    setIsCompanyFavorite(companyId: number, isFavorite: boolean): Promise<void> {
        return lastValueFrom(this._companyRepository.setIsCompanyFavorite(companyId, isFavorite));
    }

    updateCompanySetting(companyId: number, vendorCodeSetting, invoiceAppealSavings, fiscalYearStart, syncDXPFlag, budgetsEnabled, budgetBasis,
                                  accrualsEnabled: boolean, defaultAccrualAdjustmentMethodId: number, useTopLevelCompanyCOA, accrualEconUnitTypeId: number, accrualEconUnitDescriptorId: number, accrualFileTypeId: number,
                                  accrualDescriptors, excludeFromAccruals: boolean, accrualExportPropertyIdentificationId: number, accrualExportPropIdentDescriptorId: number,
                         requireClientApprovalFlag: boolean, enableAllocations: boolean): Promise<any> {

        const config: UpdateCompanySettingConfig = {
            useTopLevelCompanyVendorCodeMapping: vendorCodeSetting,
            invoiceAppealSavings: invoiceAppealSavings,
            fiscalYearStart: fiscalYearStart,
            syncDXP: syncDXPFlag,
            budgetsEnabled: budgetsEnabled,
            budgetBasis: budgetBasis,
            accrualsEnabled: accrualsEnabled,
            defaultAccrualAdjustmentMethodId: defaultAccrualAdjustmentMethodId,
            useTopLevelCompanyCOA: useTopLevelCompanyCOA,
            accrualEconUnitTypeId: accrualEconUnitTypeId,
            accrualEconUnitDescriptorId: accrualEconUnitDescriptorId,
            accrualFileTypeId: accrualFileTypeId,
            excludeFromAccruals: excludeFromAccruals,
            accrualDescriptors: accrualDescriptors,
            accrualExportPropertyIdentificationId,
            accrualExportPropIdentDescriptorId,
            requireClientApproval: requireClientApprovalFlag,
            enableAllocations: enableAllocations
        };

        return lastValueFrom(this._companyRepository.updateCompanySetting(companyId, config));
    }

    getCompanyVendorCodesCount(companyId: number): Promise<number> {
        return lastValueFrom(this._companyRepository.getCompanyVendorCodesCount(companyId));
    }

    addYear(companyId: number, year: number, stateIds?: number[]): Promise<Core.CompanyAddYearReturnDTO> {
        const config: AddYearConfig = {
            companyId: companyId,
            annualYear: year,
            stateIds
        };

        return lastValueFrom(this._companyRepository.addYear(config));
    }

    getCompanyYears(companyId: number): Promise<number[]> {
        return lastValueFrom(this._companyRepository.getCompanyYears(companyId));
    }

    ppReturnPreparationAllowed(): boolean {
        return this._company && this._company.ppReturnPreparationAllowed;
    }

    deleteCompany(id: number): Promise<void> {
        return lastValueFrom(this._companyRepository.deleteCompany(id));
    }

    getAddYearProcessStatus(longRunningProcessId: number): Promise<Core.CompanyAddYearReturnDTO> {
        return lastValueFrom(this._companyRepository.getAddYearProcessStatus(longRunningProcessId));
    }

    checkAccrualsEnabled(): Promise<boolean> {
        return lastValueFrom(this._companyRepository.checkAccrualsEnabled());
    }

    checkForLongRunningProcesses(companyId: number): Promise<Core.CompanyAddYearReturnDTO[]> {
        return lastValueFrom(this._companyRepository.checkForLongRunningProcesses(companyId));
    }

    getCompanyFiscalYearStart(companyId: number): Promise<string> {
        return lastValueFrom(this._companyRepository.getCompanyFiscalYearStart(companyId));
    }

    getTopCompanyForEntity(entityTypeId: number, entityId: number): Promise<Core.CompanyRecordDTO> {
        return lastValueFrom(this._companyRepository.getTopCompanyForEntity(entityTypeId, entityId));
    }

    checkOngoingAction(companyId: number): Promise<Compliance.LongRunningProcessStatusChangeModel> {
        return lastValueFrom(this._companyRepository.checkOngoingAction(companyId));
    }

    getEconomicUnitTypes(): Promise<Core.GetEconomicUnitTypesResult> {
        return lastValueFrom(this._companyRepository.getEconomicUnitTypes());
    }

    async getInUseCharacteristics(topLevelCompanyId: number, activeOnly: boolean): Promise<Core.CompanyAccrualDescriptorModel[]> {
        const result = await lastValueFrom(this._companyRepository.getInUseCharacteristics(topLevelCompanyId, activeOnly));
        return result.inUseCharacteristics;
    }

    async getAllowedAccrualFileTypes(topLevelCompanyId: number): Promise<Core.AllowedAccrualFileTypesResultModel> {
        return await lastValueFrom(this._companyRepository.getAllowedAccrualFileTypes(topLevelCompanyId));
    }

    private _updateParentId(fetchedCompany): void {
        fetchedCompany.parentId = fetchedCompany.topLevelCompanyId !== fetchedCompany.companyID
            ? fetchedCompany.topLevelCompanyId
            : null;
    }

    getInfoForCompliance(companyId: number) : Promise<Core.CompanyModel> {
        return lastValueFrom(this._companyRepository.getInfoForCompliance(companyId));
    }
}
