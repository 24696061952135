import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

// TODO export from back end
export interface SiteClassPrimary {
    siteClassPrimaryID: number;
    siteClassPrimaryDesc: string;
}

@Injectable(
    { providedIn: 'root' }
)
export class SiteClassRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getPrimaries(): Observable<SiteClassPrimary[]> {
        return this.httpGet('/api/siteclassprimary');
    };
}
