import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SupplementalInformationRepository extends BaseRepository {
    constructor(httpClient:HttpClient) {super(httpClient)}

    getList(companyId:number):Observable<Compliance.SupplementalInformationModel[]> {
        return this.httpGet(`/api/supplemental/company/${companyId}`);
    }

    update(companyId:Number, updateModel:Compliance.SupplementalInformationModel[]): Observable<Compliance.SupplementalInformationModel[]> {
        return this.httpPost(`/api/supplemental/company/${companyId}`, updateModel);
    }

    getOptions(): Observable<Compliance.SupplementalInformationLookupModel[]> {
        return this.httpGet(`/api/supplemental/options`);
    }
}
