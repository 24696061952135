import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { AttachmentModalData } from '../attachment.modal.model';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AttachmentModalComponent } from '../Modal/attachmentModal.component';

@Component({
    selector: 'entity-attachments',
    templateUrl: './entity-attachments.component.html'
})
export class EntityAttachmentsComponent {
    constructor(private readonly _modalService: WeissmanModalService,
        private readonly _productAnalyticsService: ProductAnalyticsService) { }

    @Input() hasAttachments: boolean;
    @Input() buttonClass: string;
    @Input() modalData: AttachmentModalData;
    @Input() disabled: boolean;
    @Input() isNestedModal: boolean;
    @Input() forImages: boolean;
    @Input() analyticsCategory: string;

    @Output() hasAttachmentsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() hasImagesChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    get icon(): string {
        if (this.forImages) {
            return 'fa-file-image-o';
        } else {
            return this.hasAttachments ? 'fa-files-o' : 'fa-paperclip';
        }
    }

    async showAttachmentsDialog(): Promise<void> {
        if (this.analyticsCategory) {
            this._productAnalyticsService.logEvent(`click-${this.analyticsCategory}-attachment`);
        }

        const result = await this._modalService.showAsync(AttachmentModalComponent, this.modalData, 'modal-xl modal-no-max-width');

        if(result?.count >= 0) {
            this.hasAttachments = result.count > 0;
            this.hasAttachmentsChange.emit(this.hasAttachments);
            if (this.hasImagesChange) {
                this.hasImagesChange.emit(result.hasImages);
            }
        }
    }
}
