import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const FILING_BATCH_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'filing-batch-list.completed',
        tooltipText: 'Include filing batches where all tasks have been completed.',
        hasIcon: true
    },
    {
        helpContentId: 'filing-batch-list.new-filing-batch',
        tooltipText: 'New filing batch'
    },
    {
        helpContentId: 'filing-batch-list.reconciliation-report',
        tooltipText: 'Export reconciliation report'
    },
    {
        helpContentId: 'filing-batch-list.reconciliation-report-select',
        tooltipText: 'Select locked or completed batches to export reconciliation report'
    }
];
