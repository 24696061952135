import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { ReturnAssetRepository, ReturnRepository } from '../../../Repositories';
import { ReturnService } from '../../return.service';
import { ReturnPartServiceBase } from '../../Models/returnPartServiceBase';

import * as _ from 'lodash';

export enum AssessorFactorsEnum {
    SpecificAssessor = 0,
    AnyAssessor = -1,
    AssessorsUsingDefaultFactors = -2
}

export interface AssessorFactorsFilter {
    assessorFactor: AssessorFactorsEnum;
    // a collection of IDs for all assessors that fall under the specified assessor factors filter
    assessorIds: number[];
}

export interface ScheduleAndFactorFilter {
    showOnlyNotAssignedToASchedule: boolean;
    showOnlyAssignedToNonReportableSchedule: boolean;
    showOnlyAssignedToReportableSchedule: boolean;
    depreciationFactorId: number;
    scheduleAge: number;
    formRevisionScheduleIds: number[];
    userSelectedEmptySchedule?: boolean;
}

export interface ReturnAssetsServiceSharedState {
    formRevisionId?: number;
    assetStatusesFilter: Compliance.ReturnAssetPriorReturnStatusEnum[];
    assessorFactorsFilter: AssessorFactorsFilter;
    scheduleAndFactorFilter: ScheduleAndFactorFilter;
    assetDetailsUpdatedTimestamp: number;
    assetMappingsUpdatedTimestamp: number;
    hideEmptySchedulesFilter: boolean;
    separateFactorTablesFilter: boolean;
}

@Injectable()
export class ReturnAssetsService extends ReturnPartServiceBase {
    constructor(
        private _returnAssetRepository: ReturnAssetRepository,
        private _returnRepository: ReturnRepository,
        private _returnService: ReturnService
    ) { super(); }

    private _sharedState: ReturnAssetsServiceSharedState = {
        assetStatusesFilter: [],
        assessorFactorsFilter: {
            assessorFactor: AssessorFactorsEnum.AnyAssessor,
            assessorIds: []
        },
        scheduleAndFactorFilter: {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        },
        assetDetailsUpdatedTimestamp: Date.now(),
        assetMappingsUpdatedTimestamp: Date.now(),
        hideEmptySchedulesFilter: true,
        separateFactorTablesFilter: false
    };
    private _hasResetNoDataFilterApplied: boolean;

    private _assetDetailsUpdatedSubject: Subject<void> = new Subject();
    private _assetMappingsUpdatedSubject: Subject<void> = new Subject();
    private _requestUpdatedSchedules: Subject<void> = new Subject();
    private _assetStatusesFilterSubject: Subject<Compliance.ReturnAssetPriorReturnStatusEnum[]> = new Subject();
    private _assessorFactorsFilterSubject: Subject<AssessorFactorsFilter> = new Subject();
    private _scheduleAndFactorFilterSubject: Subject<ScheduleAndFactorFilter> = new BehaviorSubject(this._sharedState.scheduleAndFactorFilter);

    get sharedState(): ReturnAssetsServiceSharedState { return this._sharedState; }
    get hasResetNoDataFilterApplied(): boolean { return this._hasResetNoDataFilterApplied; }

    assetDetailsUpdated$: Observable<void> = this._assetDetailsUpdatedSubject.asObservable();
    assetMappingsUpdated$: Observable<void> = this._assetMappingsUpdatedSubject.asObservable();
    requestUpdatedSchedules$: Observable<void> = this._requestUpdatedSchedules.asObservable();
    assetStatusesFilter$: Observable<Compliance.ReturnAssetPriorReturnStatusEnum[]> = this._assetStatusesFilterSubject.asObservable();
    assessorFactorsFilter$: Observable<AssessorFactorsFilter> = this._assessorFactorsFilterSubject.asObservable();
    scheduleAndFactorFilter$: Observable<ScheduleAndFactorFilter> = this._scheduleAndFactorFilterSubject.asObservable();

    initialize(): void {
        this._sharedState = {
            assetStatusesFilter: [],
            assessorFactorsFilter: {
                assessorFactor: AssessorFactorsEnum.AnyAssessor,
                assessorIds: []
            },
            scheduleAndFactorFilter: {
                showOnlyNotAssignedToASchedule: false,
                showOnlyAssignedToNonReportableSchedule: false,
                showOnlyAssignedToReportableSchedule: false,
                formRevisionScheduleIds: [],
                depreciationFactorId: null,
                scheduleAge: null
            },
            assetDetailsUpdatedTimestamp: Date.now(),
            assetMappingsUpdatedTimestamp: Date.now(),
            hideEmptySchedulesFilter: true,
            separateFactorTablesFilter: false
        };
    }

    notifyServiceActivationChange(isActive: boolean, state?: ReturnAssetsServiceSharedState): void {
        if (state) {
            this._sharedState = _.cloneDeep(state);
        }
        super.notifyServiceActivationChange(isActive);
    }

    requestUpdatedSchedules(): void {
        this._requestUpdatedSchedules.next();
    }

    setAssessorFactorsFilter(assessorFactor: AssessorFactorsEnum, assessorIds: number[]): void {
        this._sharedState.assessorFactorsFilter = {
            assessorFactor: assessorFactor,
            assessorIds: assessorIds
        };
        this._assessorFactorsFilterSubject.next(this._sharedState.assessorFactorsFilter);
    }

    setScheduleAndFactorFilter(scheduleFactorFilter: ScheduleAndFactorFilter): void {
        this._sharedState.scheduleAndFactorFilter = scheduleFactorFilter;

        this._hasResetNoDataFilterApplied = !scheduleFactorFilter.userSelectedEmptySchedule
                                            && (scheduleFactorFilter.showOnlyNotAssignedToASchedule
                                            || (scheduleFactorFilter.formRevisionScheduleIds.length && !(scheduleFactorFilter.showOnlyAssignedToReportableSchedule || scheduleFactorFilter.showOnlyAssignedToNonReportableSchedule))
                                            || !!scheduleFactorFilter.depreciationFactorId
                                            || !!scheduleFactorFilter.scheduleAge);
        this._scheduleAndFactorFilterSubject.next(this._sharedState.scheduleAndFactorFilter);
    }

    setAssetStatusesFilter(assetStatusesFilter: Compliance.ReturnAssetPriorReturnStatusEnum[]): void {
        this._sharedState.assetStatusesFilter = assetStatusesFilter;
        this._assetStatusesFilterSubject.next(undefined);
    }

    setHideEmptySchedulesFilter(value: boolean): void {
        this._sharedState.hideEmptySchedulesFilter = value;
    }

    setSeparateFactorTablesFilter(value: boolean): void {
        this._sharedState.separateFactorTablesFilter = value;
    }

    async getScheduleDetails(forAllFormRevisions: boolean = false): Promise<Compliance.ReturnAssetScheduleDetailsAssignedItemModel[]> {
        const searchModel: Compliance.ReturnAssetScheduleDetailsSearchModel = {
            assessorFilter: this._getAssessorFilterForSelectedAssessorFactors(),
            formRevisionIds: forAllFormRevisions ? this._returnService.getUniqueAssociatedReturnFormRevisions().map(x => x.formRevisionId) : [this._returnService.sharedState.formRevisionId],
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            formScheduleIds: [],
            formFactorTableId: 0,
            priorReturnStatuses: this._sharedState.assetStatusesFilter,
            aggregationLevel: Compliance.ScheduleDetailsAggregationLevelEnum.FactorTable,
            includeMergedParcels: false
        };

        // form revision selection is required
        if ((this._returnService.sharedState.formRevisionId || forAllFormRevisions) && searchModel.parcelIds.length) {
            return await lastValueFrom(this._returnAssetRepository.getScheduleDetailsByFilingBatch(this._returnService.filingBatchId, searchModel));
        } else {
            return [] as Compliance.ReturnAssetScheduleDetailsAssignedItemModel[];
        }
    }

    async getUnassignedScheduleDetails(): Promise<Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[]> {
        const searchModel: Compliance.ReturnAssetScheduleDetailsSearchModel = {
            assessorFilter: this._getAssessorFilterForSelectedAssessorFactors(),
            formRevisionIds: [this._returnService.sharedState.formRevisionId],
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            formScheduleIds: [],
            formFactorTableId: 0,
            priorReturnStatuses: this._sharedState.assetStatusesFilter,
            aggregationLevel: Compliance.ScheduleDetailsAggregationLevelEnum.Schedule,
            includeMergedParcels: false
        };

        // form revision selection is required
        if (this._returnService.sharedState.formRevisionId && searchModel.parcelIds.length) {
            return await lastValueFrom(this._returnAssetRepository.getUnassignedScheduleDetailsByFilingBatch(this._returnService.filingBatchId, searchModel));
        } else {
            return [] as Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[];
        }
    }

    async exportAssets(exportModel: Compliance.ReturnAssetExportModel) : Promise<number>{
        return await lastValueFrom(this._returnAssetRepository.exportReturnAssets(this._returnService.filingBatchId, exportModel));
    }

    getAssetExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        if (this._returnService.sharedState.formRevisionId) {
            return this._returnAssetRepository.getReturnAssetsExport(this._returnService.filingBatchId, longRunningProcessId);
        }
    }

    async updateAssetMappings(updateModel: Compliance.ReturnAssetUpdateModel): Promise<void> {
        await lastValueFrom(this._returnAssetRepository.updateByFilingBatch(this._returnService.filingBatchId, updateModel));
        this._sharedState.assetMappingsUpdatedTimestamp = Date.now();
        this._assetMappingsUpdatedSubject.next();
        return Promise.resolve();
    }

    async notifyAssetDetailsUpdated(reportingAssetIds = [], recalculateWorkingSet: boolean = true): Promise<void> {
        if (recalculateWorkingSet) {
            await lastValueFrom(this._returnRepository.updateWorkingSet(this._returnService.filingBatchId, reportingAssetIds));
        }
        this._sharedState.assetDetailsUpdatedTimestamp = Date.now();
        this._assetDetailsUpdatedSubject.next();
        return Promise.resolve();
    }

    async updateWorkingSet(reportingAssetIds = [], recalculateWorkingSet: boolean = true): Promise<void> {
        if (recalculateWorkingSet) {
            await lastValueFrom(this._returnRepository.updateWorkingSet(this._returnService.filingBatchId, reportingAssetIds));
        }
        return Promise.resolve();
    }

    async getAssessors(reportingAssetIds: number[]): Promise<Compliance.FormRevisionAssessorModel[]> {
        return await lastValueFrom(this._returnAssetRepository.getAssessorsByFilingBatch(this._returnService.filingBatchId, {
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId) || [],
            formRevisionId: this._returnService.sharedState.formRevisionId,
            reportingAssetIds: reportingAssetIds
        } as Compliance.ReturnAssetAssessorModel));
    }

    async getWorkingSetAssessors(reportingAssetIds: number[]): Promise<Compliance.FormRevisionAssessorModel[]> {
        return await lastValueFrom(this._returnAssetRepository.getWorkingSetAssessorsByFilingBatch(this._returnService.filingBatchId, {
            formRevisionId: this._returnService.sharedState.formRevisionId,
            reportingAssetIds: reportingAssetIds
        } as Compliance.ReturnAssetAssessorModel));
    }

    clearScheduleAndFactorFilter(): void {
        const scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        this.setScheduleAndFactorFilter(scheduleAndFactorFilter);
    }

    getAssessorsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetAssessorModel): Promise<Compliance.FormRevisionAssessorModel[]> {
        return lastValueFrom(this._returnAssetRepository.getAssessorsByFilingBatch(filingBatchId, searchModel));
    }

    /**
     * Based on the Assessor Factors selection it will return the correct assessor filter.
     * If a specific assessor is specified it will return the ID of that assessor. If not, it will return the value * of the assessor factor filter.
     */
    private _getAssessorFilterForSelectedAssessorFactors(): number {
        switch (this._sharedState.assessorFactorsFilter.assessorFactor) {
            case AssessorFactorsEnum.AnyAssessor: return AssessorFactorsEnum.AnyAssessor;
            case AssessorFactorsEnum.AssessorsUsingDefaultFactors: return AssessorFactorsEnum.AssessorsUsingDefaultFactors;
            case AssessorFactorsEnum.SpecificAssessor: return this._sharedState.assessorFactorsFilter.assessorIds[0];
            default: throw new Error('ReturnAssetsService._getAssessorFilterForSelectedAssessorFactors - Unexpected parameters.');
        }
    }
}
