import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackBarComponent } from './snackBar.component';
import { SnackComponent } from './Snack/snack.component';
import { SnackMessageComponent } from './SnackMessage/snackMessage.component';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { HelpModule } from '../../UI-Lib/Help-Tooltip/help.module';
import { SnackBarService } from "./snackBar.service";

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        HelpModule
    ],
    declarations: [
        SnackBarComponent,
        SnackComponent,
        SnackMessageComponent
    ],
    exports: [
        SnackBarComponent,
        SnackMessageComponent
    ]
})
export class SnackBarModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('snackBar', SnackBarComponent);
        hybridAdapterUtility.downgradeNg2Component('snackMessage', SnackMessageComponent);
        hybridAdapterUtility.downgradeNg2Provider('snackBarService', SnackBarService);
    }
}
