import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gl-account-bulk-update-action',
    templateUrl: './glAccountBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class GLAccountBulkUpdateActionComponent implements OnInit{
    constructor(
    ){
        // this.possibleActions = [
        //     { name: 'No Change', value: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange },
        //     { name: 'Change To', value: Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo },
        //     { name: 'Set to Blank', value: Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank }
        // ];
    }

    @Input() fieldAction: Compliance.GLAccountBulkUpdateFieldActionEnum;
    @Input() requiredField: boolean;
    @Input() disabled: boolean;
    @Input() possibleActions: Compliance.NameValuePair<Compliance.GLAccountBulkUpdateFieldActionEnum>[] = [];

    @Output() fieldActionChange = new EventEmitter<Compliance.GLAccountBulkUpdateFieldActionEnum>();

    selectedAction: Compliance.GLAccountBulkUpdateFieldActionEnum = null;

    ngOnInit(): void {
        if (this.requiredField){
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank);
        }
        this.selectedAction = this.fieldAction;
    }

    fieldActionChanged(action: Compliance.GLAccountBulkUpdateFieldActionEnum) : void{
        this.selectedAction = action;
        this.fieldActionChange.next(action);
    }

    isSelected(action: Compliance.ReportingAssetBulkUpdateFieldActionEnum): boolean{
        return action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange;
    }
}
