import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../../../Busy-Indicator';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { AgGridMultiSelectTracker } from '../../../../AgGrid/MultiSelectTracker/agGridMultiSelectTracker';
import { lastValueFrom, Subscription } from 'rxjs';
import { AgGridColumns } from '../../../../AgGrid/agGridColumns';
import { AgGridMultiSelectedHeaderRenderer } from '../../../../AgGrid/MultiSelectTracker/agGridMultiSelectHeaderRenderer.component';
import { AgGridMultiSelectedCellRenderer } from '../../../../AgGrid/MultiSelectTracker/agGridMultiSelectCellRenderer.component';
import { AgGridMultiSelectRendererParams } from '../../../../AgGrid/MultiSelectTracker/agGridMultiSelectTracker';
import { AgGridFilterParams } from '../../../../AgGrid/agGridFilterParams';
import { AgGridOptionsBuilder } from '../../../../AgGrid/agGridOptionsBuilder';
import { ReturnRepository } from '../../../../../Compliance/Repositories';
import { ReturnService } from '../../../return.service';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';

@Component({
    templateUrl: './returnSettingsReportAdd.component.html',
    selector: 'return-settings-report-add'
})
export class ReturnSettingsReportAddComponent implements OnInit, OnDestroy, IWeissmanModalComponent<Compliance.FilingBatchReportModel[], Compliance.FilingBatchReportModel[]> {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _returnRepository: ReturnRepository) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _availableReports: Compliance.SavedSearchModelSelectable[];

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.SavedSearchModelSelectable).savedSearchID)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    get filingBatchId(): number {
        return this._returnService.sharedState ? this._returnService.filingBatchId : null;
    }

    params: Compliance.FilingBatchReportModel[];
    result: Compliance.FilingBatchReportModel[];

    rowsSelected: boolean = false;
    allReports: Core.SavedSearchModel[];
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    async ngOnInit(): Promise<void> {
        this.allReports = await lastValueFrom(this._returnRepository.getReports());
        const result = _.filter(this.allReports, (ar) => !_.some(this.params, (fb) => { return ar.savedSearchID === fb.savedSearchId; }));
        this._availableReports = _.map(result, (r) => { return { ...r, isSelected: false } as Compliance.SavedSearchModelSelectable });

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'savedSearchID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Name',
                field: 'searchName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._returnService.getSavedSearchReportName(savedSearch);
                },
                filterValueGetter: params => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._returnService.getSavedSearchReportName(savedSearch);
                }
            },
            {
                headerName: 'Type',
                field: 'isSystem',
                width: AgGridColumns.textColumnSmallWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._returnService.getSavedSearchReportType(savedSearch);
                },
                filterValueGetter: params => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._returnService.getSavedSearchReportType(savedSearch);
                }
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            const rowIds = await this.gridTracker.getSelectedRowIds();
            this.result = _.map(_.filter(this.allReports, (x) => _.some(rowIds, (y) => x.savedSearchID === y)), (x) => {
                return {
                    filingBatchReportId: 0,
                    filingBatchId: this.filingBatchId,
                    savedSearchId: x.savedSearchID,
                    savedSearchCategoryId: x.savedSearchCategoryID,
                    name: x.searchName,
                    variantName: x.variantName,
                    isSystem: x.isSystemSearch
                } as Compliance.FilingBatchReportModel;
            });
            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!this._gridApi && this._availableReports) {
            return;
        }

        this._gridApi.setRowData(this._availableReports);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const savedSearch = x.data as Core.SavedSearchModel;
                return savedSearch && savedSearch.savedSearchID;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
