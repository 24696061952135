import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForCompanyPermissionsGridAction extends ICellRendererParams {
    launchSitesModal: (params: ICellRendererParamsForCompanyPermissionsGridAction) => Promise<void>;
    canLaunchSitesModal: (params: ICellRendererParamsForCompanyPermissionsGridAction) => boolean;
    editMode: (params: ICellRendererParamsForCompanyPermissionsGridAction) => boolean;
    canRemove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => boolean;
    remove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => void;
    canUndoRemove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => boolean;
    undoRemove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button [style.visibility]="params.canLaunchSitesModal(params) ? 'visible' : 'hidden'" type="button" class="btn flat-button primary-button icon-button grid-action-button" helpTooltip [helpContentId]="helpContentId" (click)="params.launchSitesModal(params)">
        <i *ngIf="params.editMode(params)" class="fa fa-pencil"></i>
        <i *ngIf="!params.editMode(params)" class="fa fa-eye"></i>
    </button>
    <button *ngIf="params.canRemove(params)" class="btn flat-button icon-button warning-button grid-action-button" title="Remove" (click)="params.remove(params)">
        <i class="fa fa-trash"></i>
    </button>
    <button *ngIf="params.canUndoRemove(params)" class="btn flat-button icon-button success-button grid-action-button" title="Undo Remove" (click)="params.undoRemove(params)">
        <i class="fa fa-undo"></i>
    </button>
    `
})
export class CompanyPermissionsGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForCompanyPermissionsGridAction;
    helpContentId: string;

    agInit(params: ICellRendererParamsForCompanyPermissionsGridAction): void {
        this.params = params;
        this.helpContentId = this.params.editMode(this.params) ? 'user-group-detail.edit-site-permissions' : 'user-group-detail.view-site-permissions'
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
