import { HelpContent } from '../UI-Lib/Help-Tooltip';

export const APPEAL_APPLICATION_HELP: HelpContent[] = [
    {
        helpContentId: 'appeal-application.download-form',
        tooltipText: 'Download Form'
    },
    {
        helpContentId: 'appeal-application.edit-form',
        tooltipText: 'Edit supplemental form associations.'
    },
    {
        helpContentId: 'appeal-application.edit-main-form',
        tooltipText: 'Edit main form associations.'
    },
    {
        helpContentId: 'appeal-application.edit-supplement-form',
        tooltipText: 'Edit supplemental form associations.'
    },
    {
        helpContentId: 'appeal-application.preview-form',
        tooltipText: 'Preview form'
    },
    {
        helpContentId: 'appeal-application.preview-form-has-override',
        tooltipText: 'Preview form (contains overrides).'
    },
];
