import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

enum WeissmanPanelViewEnum {
    Display = 'DISPLAY',
    Edit = 'EDIT'
}

@Component({
    selector: 'weissman-panel',
    templateUrl: './weissmanPanel.component.html'
})
export class WeissmanPanelComponent implements OnInit, OnDestroy {
    constructor(private _toastsManager: ToastrService) { }

    private _setDefaultParameters(): void {
        if (this.canExpand === undefined) this.canExpand = true;
        if (this.canCollapse === undefined) this.canCollapse = true;
        if (this.isExpanded === undefined) this.isExpanded = true;
        if (this.supportsMultipleViews === undefined) this.supportsMultipleViews = false;
        if (this.view === undefined) this.view = WeissmanPanelViewEnum.Display;
    }

    private async _canNavigateTo(deactivatePromise: Promise<boolean>, activatePromise: Promise<boolean>): Promise<boolean> {
        try {
            const canDeactivate = await deactivatePromise;
            if (!canDeactivate) return Promise.resolve(false);
        } catch(ex) {
            return Promise.resolve(false);
        }
        try {
            const canActivate = await activatePromise;
            if (!canActivate) return Promise.resolve(false);
        } catch(ex) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    @Input() panelId: string;
    @Input() panelTitle: string;
    @Input() panelClass: string;
    @Input() panelIconClass: string;

    @Input() canExpand: boolean;
    @Input() canCollapse: boolean;
    @Input() isExpanded: boolean;

    @Input() supportsMultipleViews: boolean;
    @Input() view: WeissmanPanelViewEnum;

    @Input() canActivateDisplayView: () => Promise<boolean>;
    @Input() canActivateEditView: () => Promise<boolean>;
    @Input() canDeactivateDisplayView: () => Promise<boolean>;
    @Input() canDeactivateEditView: () => Promise<boolean>;

    @Input() onEdit: () => Promise<boolean>;
    @Input() onCancel: () => Promise<boolean>;
    @Input() onSave: () => Promise<boolean>;

    @Output() onExpandedToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnInit(): void {
        this._setDefaultParameters();
    }

    ngOnDestroy(): void { }

    toggleIsExpanded(): void {
        this.isExpanded = !this.isExpanded;
        this.onExpandedToggle.emit(this.isExpanded);
    }

    async edit(): Promise<boolean> {
        try {
            const actionCheck = this.onEdit ? this.onEdit() : Promise.resolve(true);
            const actionCheckResult = await actionCheck;
            if (!actionCheckResult) return Promise.resolve(false);
        } catch(ex) {
            return Promise.resolve(false);
        }

        const canNavigateTo = await this._canNavigateTo(
            this.canDeactivateDisplayView ? this.canDeactivateDisplayView() : Promise.resolve(true),
            this.canActivateEditView ? this.canActivateEditView() : Promise.resolve(true)
        );

        if (canNavigateTo) {
            this.view = WeissmanPanelViewEnum.Edit;
        }
    }

    async cancel(): Promise<boolean> {
        try {
            const actionCheck = this.onCancel ? this.onCancel() : Promise.resolve(true);
            const actionCheckResult = await actionCheck;
            if (!actionCheckResult) return Promise.resolve(false);
        } catch(ex) {
            return Promise.resolve(false);
        }

        const canNavigateTo = await this._canNavigateTo(
            this.canDeactivateEditView ? this.canDeactivateEditView() : Promise.resolve(true),
            this.canActivateDisplayView ? this.canActivateDisplayView() : Promise.resolve(true)
        );

        if (canNavigateTo) {
            this.view = WeissmanPanelViewEnum.Display;
        }
    }

    async save(): Promise<boolean> {
        try {
            const actionCheck = this.onSave ? this.onSave() : Promise.resolve(true);
            const actionCheckResult = await actionCheck;
            if (!actionCheckResult) return Promise.resolve(false);
        } catch(ex) {
            return Promise.resolve(false);
        }

        const canNavigateTo = await this._canNavigateTo(
            this.canDeactivateEditView ? this.canDeactivateEditView() : Promise.resolve(true),
            this.canActivateDisplayView ? this.canActivateDisplayView() : Promise.resolve(true),
        );
        if (canNavigateTo) {
            this.view = WeissmanPanelViewEnum.Display;
        }
    }
}