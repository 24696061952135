<div *ngIf="address">
    <address *ngIf="!isEditMode" [style.margin-bottom]="removeBottomMargin ? 0 : 'initial'">
        {{address.address1}} <br>
        {{address.address2}} <br *ngIf="address.address2">
        {{address.city}}<span *ngIf="address.city">, </span> <state-dropdown [stateId]="address.stateID" [stateAbbr]="address.abbr" [readOnly]="true"></state-dropdown> {{address.zip}} <br>
        <span *ngIf="address.phone"><abbr title="Phone">P:</abbr> {{address.phone}}</span> <span *ngIf="address.fax" style="margin-left: 5px;"><abbr title="Fax">F:</abbr> {{address.fax}}</span> <br *ngIf="address.phone || address.fax">
        <a href="mailto:{{address.email}}" style="word-break: break-word;">{{address.email}}</a>
    </address>

    <div *ngIf="isEditMode" style="display: flex; flex-direction: column; font-size: 0;"> <!-- Hack to remove whitespace between inputs -->
        <div><input type="text" class="form-control" [ngClass]="{'input-sm': isSmall}" maxlength="50" placeholder="Address 1" [(ngModel)]="address.address1"></div>
        <div><input type="text" class="form-control" [ngClass]="{'input-sm': isSmall}" maxlength="50" placeholder="Address 2" [(ngModel)]="address.address2"></div>
        <div style="display: flex;">
            <input type="text" class="form-control address-inline" [ngClass]="{'input-sm': isSmall}" maxlength="50" placeholder="City" [(ngModel)]="address.city">
            <state-dropdown [(stateId)]="address.stateID" [(stateAbbr)]="address.abbr" [isSmall]="isSmall" [disabled]="readOnlyState"></state-dropdown>
            <input type="text" class="form-control address-inline" [ngClass]="{'input-sm': isSmall}" maxlength="10" placeholder="Zip" [(ngModel)]="address.zip">
        </div>
        <div *ngIf="!shouldRestrictExtended">
            <div style="display: flex;">
                <div class="input-group" style="width: 50%;">
                    <span class="input-group-text">T</span>
                    <input type="tel" class="form-control" [ngClass]="{'input-sm': isSmall}" maxlength="18" placeholder="Phone" [(ngModel)]="address.phone">
                </div>
                <div class="input-group" style="width: 50%;">
                    <span class="input-group-text">F</span>
                    <input type="tel" class="form-control" [ngClass]="{'input-sm': isSmall}" maxlength="18" placeholder="Fax" [(ngModel)]="address.fax">
                </div>
            </div>
            <div class="input-group">
                <span class="input-group-text" style="padding: 6px 9px;">@</span>
                <input type="email" class="form-control" [ngClass]="{'input-sm': isSmall}" maxlength="100"  placeholder="email" [(ngModel)]="address.email">
            </div>
        </div>
    </div>

    <div *ngIf="shouldIncludeDetails">
        <a href>Address Details ()</a>
    </div>
</div>
