<div class="card panel-flat-color secondary-panel" *ngIf="hasViewPermission || hasEditPermission">
	<div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !isEditMode}">
		<panel-chevron [showBody]="showBody"></panel-chevron>
		<h3>Contract Terms</h3>

		<div class="float-end header-button-container" (click)="$event.stopPropagation()">
			<div *ngIf="showBody" style="display: inline-block; vertical-align: middle; margin-top: -10px; margin-right: 30px;">
				<span style="margin-right: 5px;">Tax Year:</span>
				<!-- Do not change the order of ngModelChange and ngModel! It will change functionality for some weird reason (that I'm using to my advantage) -->
				<select (ngModelChange)="yearChanged()" [(ngModel)]="selectedYear" class="form-select form-select-sm" style="display: inline-block; width: auto;">
					<option value="0">All</option>
					<option *ngFor="let year of (useTopLevelCompanyTerms ? topLevelYears : subsidiaryYears)" [value]="year">{{year}}</option>
				</select>
			</div>
			<button class="btn flat-button icon-button primary-button" *ngIf="!isEditMode && showBody && hasEditPermission" (click)="goToEditMode()">
				<i class="fa fa-pencil"></i>
			</button>
			<div *ngIf="isEditMode && showBody" style="display: inline-block; vertical-align: middle;">
				<button class="btn flat-button icon-button success-button" (click)="savePanel()" [disabled]="isLoading">
					<i class="fa fa-save"></i>
				</button>
				<button class="btn flat-button icon-button warning-button" (click)="cancelEdit()">
					<i class="fa fa-close"></i>
				</button>
			</div>
		</div>
	</div>
	<div class="card-body" *ngIf="showBody" style="padding-top: 0;">
		<div class="text-center" style="margin-top: 50px;" *ngIf="isLoading">
			<i class="fa fa-spinner fa-pulse fa-5x"></i>
		</div>
		<div *ngIf="!isLoading">
            <div *ngIf="!isTopLevel || isEditMode" class="checkbox text-end" [class.disabled]="!isEditMode" style="margin-top: 0;">
                <span *ngIf="isEditMode && (isTopLevel || !useTopLevelCompanyTerms)" class="anchor-style" (click)="configureFeeEscalator()">
                    <span *ngIf="allData.feeEscalator">
                        Annual Fixed Fee Escalator: {{allData.feeEscalator * 100 | number: '1.2' }}%
                    </span>
                    <span *ngIf="!allData.feeEscalator">
                        Configure Annual Fixed Fee Escalator
                    </span>
                </span>
				<label *ngIf="!isTopLevel" style="margin-left: 1em;">
					<input type="checkbox" [(ngModel)]="useTopLevelCompanyTerms" [disabled]="!isEditMode" (change)="useTopLevelCompanyChanged()"> Use Top Level Company Terms
				</label>
			</div>
			<table class="table table-condensed contract-terms">
				<thead>
					<tr>
						<th>Service</th>
						<th>Property Type</th>
						<th class="text-center">Tax Year</th>
						<th>Terms</th>
						<th></th>
						<th></th>
						<th class="text-center">Engagement #</th>
						<th></th>
						<th class="text-center">Site Default</th>
						<th></th>
					</tr>
				</thead>
				<tbody *ngIf="!isEditMode || useTopLevelCompanyTerms" class="contract-terms-view">
					<tr *ngFor="let term of (useTopLevelCompanyTerms ? topLevelCompanyContractTerms : currentContractTerms); let i = index;">
						<td>{{term.contractServiceTypeName}}</td>
						<td>{{term.propertyTypeName}}</td>
						<td class="text-center">{{term.taxYear}}</td>
						<td>
							<term-rates-and-fees [term]="term"></term-rates-and-fees>
						</td>
						<td></td>
						<td class="text-center">
							<button
                                    *ngIf="term.remarks"
                                    class="flat-button icon-button primary button"
                                    (click)="showComments(term, true)"
                                    style="margin-left:0.5em;"
                                    [title]="term.remarks">
                                <i class="fa fa-commenting"></i>
                            </button>
						</td>
						<td class="text-center">{{term.engagement}}</td>
						<td></td>
						<td class="text-center">
							<div class="checkbox" style="margin: 0;">
								<label>
									<input type="checkbox" [(ngModel)]="term.siteDefault" disabled>
								</label>
							</div>
						</td>
						<td></td>
					</tr>
				</tbody>
				<tbody *ngIf="termsAreEditable()" class="contract-terms-edit">
					<ng-container *ngFor="let term of currentContractTerms; let i=index;">
						<tr class="delete-hover" *ngIf="term.efAction !== 'delete'">
							<td>
								<select [(ngModel)]="term.contractServiceTypeId" class="form-select form-select-sm" [disabled]="!!term.contractTermDetailId" (ngModelChange)="serviceTypeChanged($event, term)">
									<option *ngFor="let service of services" [ngValue]="service.id">{{service.name}}</option>
								</select>
							</td>
							<td>
								<select [(ngModel)]="term.propertyTypeId" class="form-select form-select-sm" [disabled]="term.contractServiceTypeId != ContractServiceType.Appeal || !!term.contractTermDetailId">
									<option [ngValue]="null">All</option>
									<option *ngFor="let type of propertyTypes" [ngValue]="type.propertyTypeId">{{type.name}}</option>
								</select>
							</td>
							<td>
								<select [(ngModel)]="term.taxYear" class="form-select form-select-sm mx-auto" [disabled]="!!term.contractTermDetailId" style="width: auto;">
									<option *ngFor="let year of recentYears" [ngValue]="year">{{year}}</option>
								</select>
							</td>
							<td style="width: 1%; white-space: nowrap;">
								<term-rates-and-fees [term]="term"></term-rates-and-fees>
							</td>
							<td style="width: 1px;">
								<choose-term-popover [selectedTerm]="term"></choose-term-popover>
							</td>
							<td class="text-center">
								<button class="flat-button icon-button primary button" (click)="showComments(term, false)">
									<i *ngIf="term.remarks" class="fa fa-commenting"></i>
									<i *ngIf="!term.remarks" class="fa fa-comment-o"></i>
								</button>
							</td>
							<td class="text-center" style="width: 1px;">
								<span>{{term.engagement}}</span>
							</td>
							<td style="width: 1px;">
								<button class="flat-button icon-button primary button" (click)="lookupEngagement(term)" tooltip="Select Engagement Number">
									<i class="fa fa-binoculars"></i>
								</button>
							</td>
							<td class="text-center">
								<div class="checkbox" style="margin: 0;">
									<label>
										<input type="checkbox" [(ngModel)]="term.siteDefault" [disabled]="term.contractServiceTypeId == ContractServiceType.Misc || term.contractServiceTypeId == ContractServiceType.Hourly">
									</label>
								</div>
							</td>
							<td>
								<i class="fa fa-close invisible-hover" aria-label="Remove Term" aria-hidden="true" (click)="removeTerm(term, i)"></i>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
			<button *ngIf="termsAreEditable()" class="flat-button" (click)="addTerm()">Add Term</button>
			<button *ngIf="showRollForwardYearButton()" class="flat-button" style="margin-left:10px;"
			 (click)="rollForwardYear()">Roll Forward Year</button>
		</div>
	</div>
</div>

<div class="modal fade" bsModal #addTermYearModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
 aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Choose Year</h4>
				<button type="button" class="btn-close" aria-label="Close" (click)="addTermYearModal.hide()"></button>
			</div>
			<div class="modal-body">
				<div class="row" style="margin-top: 1em;">
					<div>
						<ws-select [(ngModel)]="yearToAdd"
                                   *ngIf="yearsToAdd"
                                   [options]="yearsToAdd"
                                   [canSearch]="false"
                                   disabledProperty="disabled"
                                   labelProperty="year"
                                   valueProperty="year"
                                   style="width: 200px;">
						</ws-select>
						<div class="checkbox">
							<label>
								<input type="checkbox" [(ngModel)]="rollForwardSiteOverrides"> Roll Forward Site Overrides
							</label>
                        </div>
                        <div *ngIf="showFeeEscalator" class="bg-warning" style="padding: 0.5em;">
                            NOTE: A {{allData.feeEscalator * 100 | number: '1.2-2'}}% annual fixed fee escalator will be applied to the following services:
                            <ul style="margin: 1em 0 0 0">
                                <li *ngFor="let serviceType of allData.escalatorServiceTypes">
                                    {{getContractServiceTypename(serviceType)}}
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn flat-button primary-button" [disabled]="addingYear" (click)="addYear()">OK</button>
				<button class="btn flat-button warning-button" [disabled]="addingYear" (click)="addTermYearModal.hide()">Cancel</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" bsModal #configureFeeEscalatorModal="bs-modal">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Configure Fee Escalator</h4>
				<button type="button" class="btn-close" aria-label="Close" (click)="configureFeeEscalatorModal.hide()"></button>
			</div>
            <div class="modal-body exclude-modal-styling" style="margin: 1em;">
                <div>
                    <div class="row" style="margin-top: 1em;">
                        Apply Fixed Fee Escalator to:
                    </div>
                    <div class="row" style="margin-left:1em;margin-bottom:1em;">
                        <div class="col-md-4" *ngFor="let service of services">
                            <label class="checkbox">
                                <input type="checkbox" [ngModel]="serviceTypeSelected(service.id)" (ngModelChange)="checkedServiceType(service.id, $event)" />
                                {{ service.fullDisplayName || service.name }}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <label>
                            Fee Escalator:
							<input type="text"
								[(ngModel)]="newFeeEscalator"
								class="form-control"
								currencyMask
								[options]="{ allowNegative: false, precision: 2, prefix: '', suffix: '%' }"
								style="display:inline-block;width:5em;" />
                        </label>
                    </div>
                </div>
			</div>
			<div class="modal-footer">
				<button class="btn flat-button primary-button" [disabled]="savingFeeEscalator" (click)="saveFeeEscalator()">OK</button>
				<button class="btn flat-button warning-button" [disabled]="savingFeeEscalator" (click)="configureFeeEscalatorModal.hide()">Cancel</button>
			</div>
		</div>
	</div>
</div>

