import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { Constants, EntityType } from '../../constants.new';
import { TaxRatesPanelService } from './tax.rates.panel.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { Subscription } from 'rxjs';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { TaxAuthority, TaxRateArea } from './tax.rates.panel.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddTaxEntityModalComponent, AddTaxEntityModalParams } from './add.tax.entity.modal.component';
import { RestrictService } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { TaxRateGridComponent } from '../../Tax-Rate/tax.rate.grid.component';

@Component({
    selector: 'tax-rates-panel',
    templateUrl: './tax.rates.panel.component.html',
    styleUrls: ['./tax.rates.panel.component.scss']
})
export class TaxRatesPanelComponent extends TaxRateGridComponent implements OnInit, OnDestroy {
    constructor(private readonly _constants: Constants,
        public taxRatesPanelService: TaxRatesPanelService,
        private modalService: BsModalService,
        private readonly _weissmanModalService: WeissmanModalService,
        private restrictService: RestrictService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler) {
            super(taxRatesPanelService, restrictService, modalService);
        }

    @Input() collectorId: number;
    @Input() assessorId: number;
    @Input() annualRateIncrease: number;
    @Output() maximizeToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    showBody: boolean = false;
    showDisabled: boolean = false;
    isMaximized: boolean = false;

    gridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        rowClassRules: {
            'disabled': params => params.data && params.data.disabled
        },
        onRowClicked: (params) => {
            if (!params.data) {
                return;
            }

            if (this.selectedGridSourceOption == 'areas') {
                this.launchTaxRateAreaModal(params.data.taxRateAreaId, false);
            } else {
                this.launchTaxAuthorityModal(params.data.taxAuthorityId, false);
            }
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withSort()
        .withColumnResize()
        .withTextSelection()
        .withVerticalScrollBar()
        .build();

    private _taxRatesPanelDataSub: Subscription;

    ngOnInit() {
        this._taxRatesPanelDataSub = this.taxRatesPanelService.taxRatesPanelData$.subscribe(() => {
            // initial load
            this._setRowData();
        });
    }

    ngOnDestroy() {
        this._taxRatesPanelDataSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api;

        this.gridApi.setColumnDefs(this.getGridColumns());
        console.log('onready');
        this._setRowData();
    }

    selectedGridSourceChanged(): void {
        this.gridApi.setRowData([]);
        this.gridApi.setColumnDefs(this.getGridColumns());
        this._setRowData();
    }

    toggleMaximize(isMaximized: boolean) {
        this.isMaximized = isMaximized;
        this.maximizeToggled.emit(this.isMaximized);
    }

    expandOrCollapse(): void {
        this.showBody = !this.showBody;

        if(this.showBody) {
            this.loadPanel();
        }
    }

    showDisabledChanged(): void {
        this._setRowData();
    }

    async addTaxRateArea(): Promise<void> {
        const modalParams: AddTaxEntityModalParams = {
            assessorId: this.assessorId,
            collectorId: this.collectorId,
            entityTypeId: EntityType.TaxRateArea
        };

        const taxRateAreaId = await this._weissmanModalService.showAsync(AddTaxEntityModalComponent, modalParams, 'modal-md');

        if (taxRateAreaId) {
            this.launchTaxRateAreaModal(taxRateAreaId, true);
        }
    }

    async addTaxAuthority(): Promise<void> {
        const modalParams: AddTaxEntityModalParams = {
            collectorId: this.collectorId,
            entityTypeId: EntityType.TaxAuthority,
            defaultAnnualRateIncrease: this.annualRateIncrease
        };

        const taxAuthorityId = await this._weissmanModalService.showAsync(AddTaxEntityModalComponent, modalParams, 'modal-md');

        if(taxAuthorityId) {
            this.launchTaxAuthorityModal(taxAuthorityId, true);
        }
    }

    navigateToCommandCenter(): void {
        this._upgradeNavigationServiceHandler.go('taxRateCommandCenter', {});
    }

    protected loadPanel(): void {
        if(this.collectorId) {
            this.taxRatesPanelService.loadTaxRatesByCollector(this.collectorId);
            this.selectedGridSourceOption = 'authorities';
        } else if(this.assessorId) {
            this.taxRatesPanelService.loadTaxRatesByAssessor(this.assessorId);
            this.selectedGridSourceOption = 'areas';
        }
    }

    protected getSharedColumns(): ColDef[] {
        return [{
            headerName: 'Code',
            field: 'code',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth
        }];
    }

    protected getAuthorityColumns(): ColDef[] {
        return [{
            headerName: 'Authority Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth
        }, {
            headerName: 'Category',
            field: 'taxAuthorityCategoryId',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            valueGetter: (params) => {
                const taxAuthority: TaxAuthority = params.data;

                if(!taxAuthority || taxAuthority.taxAuthorityCategoryId == null) {
                    return '';
                }
                return this._constants.TaxAuthorityCategories[taxAuthority.taxAuthorityCategoryId].displayName;
            }
        }, {
            headerName: 'Taxable Assessment',
            field: 'taxableAssessmentTypeId',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            valueGetter: (params) => {
                const taxAuthority: TaxAuthority = params.data;

                if(!taxAuthority || taxAuthority.taxableAssessmentTypeId == null) {
                    return '';
                }
                return this._constants.TaxableAssessmentTypes[taxAuthority.taxableAssessmentTypeId].displayName;
            }
        }];
    }

    protected getAreaColumns(): ColDef[] {
        return [{
            headerName: 'Rate Area Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth
        }];
    }

    private _setRowData(): void {
        if (!(this.gridApi && this.taxRatesPanelService.isInitialized)) {
            return;
        }

        this.gridApi.setRowData(this._getRowData());
        this.gridApi.sizeColumnsToFit();
    }

    private _getRowData(): TaxRateArea[] | TaxAuthority[] {
        if(this.selectedGridSourceOption == 'areas') {
            if(this.showDisabled) {
                return this.taxRatesPanelService.allTaxRateAreas;
            } else {
                return this.taxRatesPanelService.enabledTaxRateAreas;
            }
        } else {
            if(this.showDisabled) {
                return this.taxRatesPanelService.allTaxAuthorities;
            } else {
                return this.taxRatesPanelService.enabledTaxAuthorities;
            }
        }
    }
}
