export enum ReturnSettingsViewModelEnum {
    ReturnValues,
    Settings
}

export enum GeneralReturnSettingLookupTypeEnum {
    EntityName = Compliance.GeneralReturnSettingLookupTypeEnum.EntityName as number,
    ContactAddress = Compliance.GeneralReturnSettingLookupTypeEnum.ContactAddress as number,
    EntityAddress = Compliance.GeneralReturnSettingLookupTypeEnum.EntityAddress as number,
    ContactPhone = Compliance.GeneralReturnSettingLookupTypeEnum.ContactPhone as number
}

export enum GeneralReturnSettingStateEnum {
    Nominal = Compliance.GeneralReturnSettingStateEnum.Nominal as number,
    VariesBetweenParcels = Compliance.GeneralReturnSettingStateEnum.VariesBetweenParcels as number,
    DifferentForTopCompany = Compliance.GeneralReturnSettingStateEnum.DifferentForTopCompany as number
}

export enum ReturnSettingsLookupType {
    Name,
    Address,
    Phone
}

export enum ReturnSettingsSettingTypeEnum {
    OwnerContact = Compliance.ReturnSettingsSettingTypeEnum.OwnerContact as number,
    AccountingContact = Compliance.ReturnSettingsSettingTypeEnum.AccountingContact as number,
    PreparerContact = Compliance.ReturnSettingsSettingTypeEnum.PreparerContact as number,
    SignerContact = Compliance.ReturnSettingsSettingTypeEnum.SignerContact as number,
    Entity = Compliance.ReturnSettingsSettingTypeEnum.Entity as number
}

export enum ReturnSettingsEntityTypeEnum {
    Company = Compliance.ReturnSettingsEntityTypeEnum.Company as number,
    Parcel = Compliance.ReturnSettingsEntityTypeEnum.Parcel as number,
    Return = Compliance.ReturnSettingsEntityTypeEnum.Return as number
}

export enum GeneralReturnSettingLookupEnum {
    EntityNameTopLevelCompany = Compliance.GeneralReturnSettingLookupEnum.EntityNameTopLevelCompany as number,
    EntityNameCompany = Compliance.GeneralReturnSettingLookupEnum.EntityNameCompany as number,
    EntityNameSite = Compliance.GeneralReturnSettingLookupEnum.EntityNameSite as number,
    EntityNameParcelAssessee = Compliance.GeneralReturnSettingLookupEnum.EntityNameParcelAssessee as number,
    ContactAddressSettingName = Compliance.GeneralReturnSettingLookupEnum.ContactAddressSettingName as number,
    ContactAddressTopLevelCompany = Compliance.GeneralReturnSettingLookupEnum.ContactAddressTopLevelCompany as number,
    ContactAddressCompany = Compliance.GeneralReturnSettingLookupEnum.ContactAddressCompany as number,
    ContactAddressSite = Compliance.GeneralReturnSettingLookupEnum.ContactAddressSite as number,
    ContactAddressParcel = Compliance.GeneralReturnSettingLookupEnum.ContactAddressParcel as number,
    ContactAddressBlank = Compliance.GeneralReturnSettingLookupEnum.ContactAddressBlank as number,
    EntityAddressTopLevelCompany = Compliance.GeneralReturnSettingLookupEnum.EntityAddressTopLevelCompany as number,
    EntityAddressCompany = Compliance.GeneralReturnSettingLookupEnum.EntityAddressCompany as number,
    EntityAddressSite = Compliance.GeneralReturnSettingLookupEnum.EntityAddressSite as number,
    EntityAddressParcel = Compliance.GeneralReturnSettingLookupEnum.EntityAddressParcel as number,
    EntityAddressBlank = Compliance.GeneralReturnSettingLookupEnum.EntityAddressBlank as number,
    ContactPhoneSettingName = Compliance.GeneralReturnSettingLookupEnum.ContactPhoneSettingName as number,
    ContactPhoneBlank = Compliance.GeneralReturnSettingLookupEnum.ContactPhoneBlank as number,
    ContactAddressCompanyAlternateAddress = Compliance.GeneralReturnSettingLookupEnum.ContactAddressCompanyAlternateAddress as number,
    EntityAddressCompanyAlternateAddress = Compliance.GeneralReturnSettingLookupEnum.EntityAddressCompanyAlternateAddress as number,
    ContactAddressAgent = Compliance.GeneralReturnSettingLookupEnum.ContactAddressAgent as number,
    EntityAddressAgent = Compliance.GeneralReturnSettingLookupEnum.EntityAddressAgent as number,
    ContactPhoneAgent = Compliance.GeneralReturnSettingLookupEnum.ContactPhoneAgent as number
}

export enum TaskTypes {
    PPBatchReturnFileReturns = Core.TaskTypes.PPBatchReturnFileReturns as number,
    PPBatchReturnLoadData = Core.TaskTypes.PPBatchReturnLoadData as number,
    PPBatchReturnPrepareReturns = Core.TaskTypes.PPBatchReturnPrepareReturns as number,
    PPBatchReturnDigitiallySignReturns = Core.TaskTypes.PPBatchReturnDigitiallySignReturns as number,
    PPBatchReturnReviewReturns = Core.TaskTypes.PPBatchReturnReviewReturns as number,
    PPBatchReturnApproveReturns = Core.TaskTypes.PPBatchReturnApproveReturns as number,
    PPBatchReturnFinalizeReturns = Core.TaskTypes.PPBatchReturnFinalizeReturns as number,
    PPReturnFiling = Core.TaskTypes.PPReturnFiling as number
}

export enum TaskProcessingActions {
    RescindTask = Core.TaskProcessingActions.RescindTask as number,
    ReassignTask = Core.TaskProcessingActions.ReassignTask as number
}

export enum ReturnSignActions {
    Sign = 1,
    Skip = 2
}
