(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdTeamPicker', sdTeamPicker);

	// sdTeamPicker.$inject = [];

	function sdTeamPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Team/_teamPicker.html',
			scope: {
				teamId: '=',
				team: '=?',
				closeFn: '&',
				clickFn: '&',
				pickerSm: '=?',
				appendToElement: '=?',
				isReassign: '=?'
			}
		};
		return directive;

		function link(scope, element, attrs) {

		}
	}

	Controller.$inject = ['teamsService', '$timeout'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(teamsService, $timeout) {
		var vm = this;

		vm.teams = [];
		vm.selectedTeam = {};
		vm.team = vm.team || {};

		vm.formatInput = formatInput;
		vm.teamSelected = teamSelected;
		vm.filterTeam = filterTeam;
		vm.changed = changed;
		vm.revertLabel = revertLabel;

		activate();

		function activate() {
			teamsService.getAllTeams(false)
				.then(function (result) {
					vm.teams = result;

					vm.selectedTeam = _.find(vm.teams, { teamID: vm.teamId }) || {};
				})
		}

		function formatInput(model) {
			if (_.isEmpty(model)) {
				return;
			}

			return model.name;
		}

		function teamSelected($item) {
			vm.teamId = $item.teamID;
			vm.team = {
				teamID: $item.teamID,
				name: $item.name
			}

			$timeout(vm.closeFn);
		}

		function filterTeam(val) {
			var teams = _.filter(vm.teams, function (team) {
				return _.includes(team.name.toLowerCase(), val.toLowerCase());
			})

			return teams
		}

		function changed() {
			if (!vm.selectedTeam) {
				vm.teamId = null;
				vm.team = {};
			}
		}

		function revertLabel() {
			vm.selectedTeam = _.find(vm.teams, { teamID: vm.teamId }) || {};
		}
	}

})();
