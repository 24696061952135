<div class="modal-header">
    <h4 class="modal-title">Create Invoice</h4>
    <span class="float-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </span>
</div>
<div class="modal-body" style="padding: 35px 15px 5px 15px;">
    <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading">
        <div class="mb-4 row">
            <label class="col-md-3 text-end">Tax Year:</label>
            <span class="col-md-9">
                <select [(ngModel)]="year">
                    <option *ngFor="let item of years">{{item}}</option>
                </select>
            </span>
        </div>
        <div class="mb-4 row">
            <label class="col-md-3 text-end">Service:</label>
            <span class="col-md-9">
                <select [(ngModel)]="contractServiceTypeId">
                    <option></option>
                    <option *ngFor="let contractServiceType of contractServiceTypes; trackBy:identify" [ngValue]="contractServiceType.id">{{contractServiceType.name}}</option>
                </select>
            </span>
            <div class="col-md-3 text-end"
                 style="color:red;"
                 *ngIf="validationMessages.contractServiceType">
                {{validationMessages.contractServiceType}}
            </div>
        </div>
        <div class="mb-4 row" *ngIf="contractServiceTypeId === ContractServiceType.Appeal">
            <label class="col-md-3 text-end">Property Type:</label>
            <span class="col-md-9">
                <select [(ngModel)]="propertyTypeId">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let propertyType of propertyTypes; trackBy:identify" [ngValue]="propertyType.id">{{propertyType.name}}</option>
                </select>
            </span>
            <div class="col-md-3 text-end"
                 style="color:red;"
                 *ngIf="validationMessages.propertyType">
                {{validationMessages.propertyType}}
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="ws-button-row" style="justify-content: end;">
        <button class="flat-button primary-button" (click)="createInvoice()">Create</button>
        <button class="flat-button warning-button" (click)="closeModal()">Cancel</button>
    </div>
</div>
