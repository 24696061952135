import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HotkeyService } from '../../../UI-Lib/HotKeys/hotKeys.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { DecimalPipe } from '@angular/common';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../Busy-Indicator';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import {
    MessageBoxButtons,
    MessageBoxOptions,
    MessageBoxResult,
    MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { SiteRepository } from '../../../Core-Repositories';
import { ParcelService } from '../../../Entity/Parcel/parcel.service.upgrade';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { IncomeStatementAgGridDataSource, IncomeStatementDataSourceParams } from './agGridDataSource';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../Compliance/AgGrid/ToolPanel/models';
import * as _ from 'lodash';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { takeUntil } from 'rxjs/operators';
import { IncomeStatementRepository } from '../incomeStatement.repository';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import {
    DeleteConfirmationComponent,
    DeleteConfirmationComponentParams
} from '../../../Common/Delete-Confirmation/deleteConfirmation.component';
import {
    AgGridActionCellRendererComponent,
    AgGridActionCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridActionCellRenderer.component';
import { GLAccountService } from '../../../Compliance/GL-Account/glAccount.service';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

@Component({
    selector: 'income-statement-list',
    templateUrl: './incomeStatementList.component.html',
    styleUrls: ['./incomeStatementList.component.scss'],
    providers: [HotkeyService]
})
export class IncomeStatementListComponent {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _incomeStatementRepository: IncomeStatementRepository,
        private readonly _companyService: CompanyService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _siteService: SiteRepository,
        private readonly _parcelService: ParcelService,
        private readonly _messageModalService: MessageModalService,
        private readonly _glAccountService: GLAccountService
    ) { }

    @Input() companyIdFilter: number;
    @Input() siteIdFilter: number;
    @Input() parcelIdFilter: number;
    @Input() isEmbededMode: boolean = false;
    @Input() onLoading: boolean;
    @Output() onLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    breadcrumbs: BreadCrumb[] = [];
    incomeStatementGridTotalsRow: any;
    incomeStatementGridSelectedTotalsRow: any;
    selectedTotalsLoading: boolean = true;
    companyId: number;
    newCompanyId: number;
    companyName: string;
    canEdit: boolean = false;
    canViewRyanPrivateItems: boolean = false;
    isInitialized: boolean = false;
    importFiles: Compliance.ImportFileInfoModel[] = null;
    gridId: System.Guid = 'D705A9AA-2ACE-4831-82FE-09A712A451B7';
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    totalsLoading: any;
    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();
    filterHref: string;
    siteName: string;
    parcelName: string;
    selectedEndDate: Date;
    endDates: Date[];

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Core.IncomeStatementLineModel).incomeStatementLineId),
                'totals-row': (params) => params.data && params.data.totalsRow,
                'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
            }
        })
        .withColumnPinning()
        .buildDefault(this);

    gridTracker: AgGridMultiSelectTracker;

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();

            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0){
                searchParams.selectedRows.selectAllRows = true;
            }
            const exportModel: Compliance.IncomeStatementLineExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                selectedRows: searchParams.selectedRows
            };

            const lrp$ = this._incomeStatementRepository.startIncomeStatementLineExport(this.companyId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.IncomeStatementLine };
        },
        canCancel: true,
        showFileFormatSelection: false
    };

    private _gridApi: GridApi;
    private _gridDataSource: IncomeStatementAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject();

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    async ngOnInit(): Promise<void> {
        if (this.companyIdFilter) {
            this.companyId = this.companyIdFilter;
        } else {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        }

        if (!this.isEmbededMode && typeof this.siteIdFilter === 'undefined') {
            this.siteIdFilter = parseInt(this._routerService.getQuerystringParam('siteId'));
        }

        if (!this.isEmbededMode && typeof this.parcelIdFilter === 'undefined') {
            this.parcelIdFilter = parseInt(this._routerService.getQuerystringParam('parcelId'));
        }

        this.newCompanyId = this.companyId;

        let busyRef: BusyIndicatorRef;

        if (this.isEmbededMode) {
            this.onLoading = true;
            this.onLoadingChange.emit(true);

            if(typeof this.siteIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('siteIncomeStatement', { companyId: this.companyId,  siteId: this.siteIdFilter });
            }
            else if(typeof this.parcelIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('incomeStatementParcelFilter', { companyId: this.companyId,  parcelId: this.parcelIdFilter });
            }
        } else {
            busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        }

        try {
            this.canEdit = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);
            this.canViewRyanPrivateItems = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

            let sitePromise: Promise<void>;

            if(!this.isEmbededMode && this.siteIdFilter) {
                sitePromise = lastValueFrom(this._siteService.getSiteName(this.siteIdFilter)).then(result => {
                    this.siteName = result;
                });
            }

            let parcelPromise: Promise<void>;
            let parcelSiteId: number;

            if(!this.isEmbededMode && this.parcelIdFilter) {
                parcelPromise = this._parcelService.getView(this.parcelIdFilter, false).then(result => {
                    this.parcelName = result.parcel.acctNum;
                    parcelSiteId = result.siteId;
                });
            }

            let companyInfo: Core.CompanyModel;

            const companyPromise = this._companyService.load(this.companyId, true, false);
            companyPromise.then((company) => {
                this.companyName = company.companyName;
                companyInfo = company;
            });

            await Promise.all([
                companyPromise,
                sitePromise,
                parcelPromise
            ]);

            this.breadcrumbs.push({
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });

            if (this.siteName) {
                this.breadcrumbs.push({
                    name: this.siteName,
                    target: 'site',
                    options: { companyId: this.companyId, siteId: this.siteIdFilter }
                });
            }

            if (this.parcelName) {
                this.breadcrumbs.push({
                    name: this.parcelName,
                    target: 'parcel',
                    options: { companyId: this.companyId, siteId: parcelSiteId, parcelId: this.parcelIdFilter }
                });
            }

            await this._initEndDates();

            this.isInitialized = true;
        } finally {
            if(this.isEmbededMode) {
                this.onLoading = false;
                this.onLoadingChange.emit(false);
            }
            else {
                busyRef.hide();
            }
        }
        this._glAccountService.start(this.companyId);
        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._setGridColumns();

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            const isBulkUpdateVisible = this.canEdit && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this.isBulkDeleteVisible$.next(isBulkUpdateVisible);
            await this._reloadTotals();
        });

        // if(!this.isEmbededMode && this.parcelIdFilter) {
        //     const parcelFilterModel = {
        //         [Core.IncomeStatementLinePropertyEnum.ParcelName]: {
        //             "filterType": "text",
        //             "filterConditionType": 0,
        //             "filterValues": [
        //                 {
        //                     "filterValue": this.parcelName,
        //                     "filterType": {
        //                         "displayKey": "equals",
        //                         "displayName": "Equals",
        //                         "hideFilterInput": false
        //                     }
        //                 }
        //             ]
        //         }
        //     };

        //     this.gridOptions.floatingFilter = true;
        //     this._gridApi.setFilterModel(parcelFilterModel);
        // }

        this._setDataSource();
    }

    onEndDateChange(): void {
        this._refreshDataSource();
    }

    // onSelectedImportFileChanged(importFileId: number): void {
    //     this.selectedImportFileId = importFileId;
    //     this._refreshDataSource();
    // }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    // async bulkUpdate(hotkeyFocus?: string): Promise<void> {
    //     const reportingAssetIds: number[] = [];
    //     this._gridApi.forEachNode(x => {reportingAssetIds.push(x.data.reportingAssetId)});

    //     const params: AssetBulkUpdateParams = {
    //         companyId: this.companyId,
    //         lienDate: this.lienDate && this.lienDate,
    //         reportingAssetIds: reportingAssetIds,
    //         selection: this.gridTracker.getSelectedRowsModel(),
    //         selectedCount: this.gridTracker.getSelectedRowsCount(),
    //         assetListLastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
    //         hotkeyFocus: hotkeyFocus
    //     };

    //     const result = await this._modalService.showAsync(AssetBulkUpdateComponent, params, 'modal-lg');
    //     if (!result) {
    //         return Promise.resolve();
    //     }

    //     await this._refreshRows(result);

    //     return Promise.resolve();
    // }

    async refresh(): Promise<void> {
        await this._initEndDates();
        this._refreshDataSource();
    }

    async addIncomeStatementLine(): Promise<void> {
        // const params: AssetDetailsParams = {
        //     reportingAssetId: null,
        //     assetId: null,
        //     lienDate: this.lienDate && this.lienDate.date,
        //     canEdit: this.canEdit,
        //     createNewAsset: true,
        //     topLevelCompanyId: this.companyId,
        //     newAssetCompanyId: this.newCompanyId || this.companyId,
        //     assetDescriptors: this.assetDescriptors,
        //     canViewRyanPrivateItems: this.canViewRyanPrivateItems
        // };

        // const result = await this._modalService.showAsync(AssetDetailsComponent, params, 'modal-xl');

        // if (!result) {
        //     return Promise.resolve();
        // }

        // if (result.hasChanges) {
        //     this.newCompanyId = result.newAssetCompanyId;
        //     this._refreshDataSource();
        // }

        // return Promise.resolve();
    }

    // getListViewHelpContentId(viewName: string): string {
    //     switch(viewName) {
    //         case 'RentRoll Values':
    //             return 'asset-list.reporting-values-view';
    //         case 'IncomeStatement Values':
    //             return 'asset-list.imported-values-view';
    //         default:
    //             return '';
    //     }
    // }

    async bulkDelete(): Promise<void> {
        const confirmOptions = {
            message: 'Are you sure you want to delete selected line items?',
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {

            const params: DeleteConfirmationComponentParams = {
                item: 'Income Statement Line Items',
                message: 'Are you sure you want to delete selected line items? If you choose to proceed, all information related to line items will be removed and cannot be recovered.'
            };

            const result = await this._modalService.showAsync(DeleteConfirmationComponent, params, 'modal-md');

            if (result) {
                this._showBusyIndicator('Bulk Delete', 'Deleting line items', null, false, false);

                const model: Compliance.IncomeStatementLineBulkDeleteModel = {
                    lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
                    selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                    selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
                    siteId: this.siteIdFilter,
                    endDate: this.selectedEndDate
                };

                try {
                    const longRunningProcessId = await lastValueFrom(this._incomeStatementRepository.startBulkDelete(this.companyId, model));
                    await this._busyRef.setLongRunningProcessId(longRunningProcessId);
                }
                catch (e) {
                    await this._hideBusyIndicator();
                    return Promise.reject(e);
                }
            }
        }

        return Promise.resolve();
    }

    handleTotalsUpdate = (totals: Core.IncomeStatementLineSearchTotalsModel, selectedTotals: boolean = false, isLoading: boolean = false) => {
        const model = {
            //cost: totals.totalCost,
            //sourceCost: totals.totalSourceCost,
        };
        const incomeStatementRow: any = {
            model: model,
            getModel: () => { return model; },
            displayPipe: this._keyValueDisplayPipe,
            index: 0,
            balance: totals && totals.totalBalance,
            glAccountIncomeStatementCategoryName: 'TOTAL'
        };

        if (isLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                incomeStatementRow.glAccountIncomeStatementCategoryName = msg;
                this._gridApi.setPinnedBottomRowData([incomeStatementRow]);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        if (selectedTotals) {
            incomeStatementRow.selectedTotalsRow = true;
            incomeStatementRow.glAccountIncomeStatementCategoryName ='SELECTED';
            this.incomeStatementGridSelectedTotalsRow = incomeStatementRow;
        } else {
            incomeStatementRow.totalsRow = true;
            this.incomeStatementGridTotalsRow = incomeStatementRow;
        }
        const totalRows = [this.incomeStatementGridTotalsRow];
        if (this.gridTracker.hasAnythingSelected()) {
            totalRows.push(this.incomeStatementGridSelectedTotalsRow);
        }
        setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    // private async _refreshImportFiles(): Promise<void> {
    //     const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

    //     try {
    //         const importContentTypes = [Compliance.ImportContentTypeIdEnum.Assets, Compliance.ImportContentTypeIdEnum.AssetUpdatesOnly];
    //         const result = await this._entityImportRepository.getByCompanyTransferred(this.companyId, importContentTypes).toPromise();
    //         this.importFiles = result.data;

    //         let importFile = _.find(this.importFiles, x => x.importFileId === this.selectedImportFileId);

    //         if (!importFile) {
    //             importFile = _.first(this.importFiles);
    //         }

    //         this.selectedImportFileId = importFile && importFile.importFileId;
    //     } finally {
    //         busyRef.hide();
    //     }
    // }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    // private async _viewAssetDetails(params: ICellRendererParamsForIncomeStatementGridAction): Promise<void> {
        // const assetSummary = params.data as Core.IncomeStatementLineModel;

        // const inputParams: AssetDetailsParams = {
        //     reportingAssetId: assetSummary.reportingAssetId,
        //     assetId: assetSummary.assetId,
        //     lienDate: this.lienDate.date,
        //     canEdit: this.canEdit,
        //     createNewAsset: false,
        //     topLevelCompanyId: this.companyId,
        //     assetDescriptors: this.assetDescriptors,
        //     newAssetCompanyId: assetSummary.companyId,
        //     canViewRyanPrivateItems: this.canViewRyanPrivateItems
        // };

        // const result = await this._modalService.showAsync(AssetDetailsComponent, inputParams, 'modal-xl');

        // if (!(result && result.hasChanges)) {
        //     return Promise.resolve();
        // }

        // if (result.savedModels && result.savedModels.length > 0) {
        //     const allNodesOnPage: RowNode[] = [];
        //     this._gridApi.forEachNode(x => {
        //         const gridRow = x.data as Core.IncomeStatementLineModel;
        //         if (gridRow.reportedAssetId) {
        //             const row = result.savedModels.find(y => y.reportingAsset.reportingAssetId == gridRow.reportedAssetId);
        //             if (row) {
        //                 gridRow.updateModel(row);
        //             }
        //             allNodesOnPage.push(x);
        //         }
        //     });
        //     this._gridApi.redrawRows({rowNodes: allNodesOnPage});
        //     this.handleTotalsUpdate(result.totalsModel);
        // }

        // return Promise.resolve();
    // }

    // private async _refreshRows(rowData: Compliance.QueryResultWithTotalsModel<Core.IncomeStatementLineModel, Core.IncomeStatementLineSearchTotalsModel>): Promise<void> {
    //     this.gridTracker.clear(false);

    //     this.isBulkUpdateVisible$.next(false);
    //     this.isBulkDeleteVisible$.next(false);

    //     this._gridDataSource.clearTotals();

    //     const rowNodes: RowNode[] = [];
    //     this._gridApi.forEachNode(x => {
    //         const gridRow = x.data as Core.IncomeStatementLineModel;
    //         if (gridRow.incomeStatementLineId) {
    //             const row = rowData.data.find(y => y.reportingAsset.reportingAssetId === gridRow.reportedAssetId);
    //             if (row) {
    //                 gridRow.updateModel(row);
    //                 rowNodes.push(x);
    //             }
    //         }
    //     });

    //     this._gridApi.redrawRows({ rowNodes: rowNodes });
    //     this.handleTotalsUpdate(rowData.totals);

    //     this._gridDataSource.lastModifiedTimestamp = rowData.lastModifiedTimestamp;
    // }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        this._gridDataSource.clearTotals();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        const dataSourceParams = (): IncomeStatementDataSourceParams => {
            return {
                companyId: this.companyId,
                siteId: this.siteIdFilter,
                endDate: this.selectedEndDate
            };
        };

        this._gridDataSource = new IncomeStatementAgGridDataSource(
            this._gridApi,
            this._incomeStatementRepository,
            dataSourceParams,
            this.handleTotalsUpdate
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _setGridColumns(): void {
        this._gridApi.setColumnDefs([]);
        const columns = this._getGridColumnsBySource();
        this._gridApi.setColumnDefs(columns);
        this._gridApi.setFilterModel(null);
    }

    private _getGridColumnsBySource(): ColDef[] {
        const columns: ColDef[] = [];

        const actionsColDef: ColDef = {
            colId: 'grid-column-actions',
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(1),
            minWidth: AgGridColumns.getActionColumnWidth(1),
            maxWidth: AgGridColumns.getActionColumnWidth(1),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            pinned: 'right',
            lockVisible: true,
            sortable: false,
            cellRendererFramework: AgGridActionCellRendererComponent,
            cellRendererParams: {
                buttonConfigs: [
                    {
                        iconClass: 'fa-trash',
                        onClick: this._deleteItem.bind(this),
                        isShown: this._canDelete.bind(this),
                        buttonClass: 'warning-button',
                        helpContentId: 'app.delete'
                    }
                ]
            } as AgGridActionCellRendererParams
        };

        columns.push(
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'incomeStatementLineId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountName}`,
                headerName: 'G/L Account',
                // toolPanelClass: 'Source Values',
                field: 'glAccountName',
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.CompanyName}`,
                headerName: 'Company',
                // toolPanelClass: 'Source Values',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.TopLevelCompanyName}`,
                headerName: 'Top Level Company',
                // toolPanelClass: 'Source Values',
                field: 'topLevelCompanyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.SiteName}`,
                headerName: 'Site',
                // toolPanelClass: 'Source Values',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const lineItem = params.data as Core.IncomeStatementLineModel;
                        // if (! lineItem && lineItem.sourceSite)) {
                        if (! lineItem ) {
                            return '';
                        }
                        return `#/site/${lineItem.siteId}`;
                    }
                } as AgGridLinkCellRendererParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.SiteNumber}`,
                headerName: 'Site Number',
                field: 'siteNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const lineItem = params.data as Core.IncomeStatementLineModel;
                        // if (! lineItem && lineItem.sourceSite)) {
                        if (! lineItem ) {
                            return '';
                        }
                        return `#/site/${lineItem.siteId}`;
                    }
                } as AgGridLinkCellRendererParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountNumber}`,
                headerName: 'G/L Account Number',
                // toolPanelClass: 'Source Values',
                field: 'glAccountNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountDescription}`,
                headerName: 'G/L Account Description',
                // toolPanelClass: 'Source Values',
                field: 'glAccountDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryName}`,
                headerName: 'Category',
                // toolPanelClass: 'Asset',
                field: 'glAccountIncomeStatementCategoryName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryTypeName}`,
                headerName: 'Account Type',
                // toolPanelClass: 'Asset',
                field: 'glAccountIncomeStatementCategoryTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.Balance}`,
                headerName: 'Balance',
                // toolPanelClass: 'Source Values',
                field: 'balance',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell'
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.AccountHierarchyDescription}`,
                headerName: 'Account Hierarchy Description',
                // toolPanelClass: 'Source Values',
                field: 'accountHierarchyDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            actionsColDef
        );

        return columns;
    }

    private async _deleteItem(params: AgGridActionCellRendererParams): Promise<boolean> {
        const item = params.data as Core.IncomeStatementLineModel;

        if(!item) {
             return;
        }

        try {
            await this._messageModalService.confirm(
                `Are you sure you wish to delete ${item.glAccountName}?`,
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await lastValueFrom(this._incomeStatementRepository.deleteItem(item.incomeStatementLineId));
            await this._initEndDates();
            this.refresh();
            return Promise.resolve(true);
        } finally {
            busyRef.hide();
        }

    }

    private _canDelete(params: AgGridActionCellRendererParams): boolean {
        return this.canEdit;
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        this.selectedTotalsLoading = true;

        try {
            const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            this.selectedTotalsLoading = false;
            this.handleTotalsUpdate(result, true);
        }
        finally {
            this.selectedTotalsLoading = false;
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                await this._initEndDates();
                this.refresh();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private async _initEndDates() {
        this.endDates = await lastValueFrom(this._incomeStatementRepository.getIncomeStatementEndDates(this.companyId, this.siteIdFilter));

        if(!_.includes(this.endDates, this.selectedEndDate)) {
            this.selectedEndDate = _.maxBy(this.endDates, x => new Date(x));
        }
    }
}
