<ws-modal-wrapper headerLabel="Control Message"
                  (save)="getControlMessage()"
                  (cancel)="cancel()"
                  [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="controlMessage"
                           helpTooltip
                           helpContentId="long-running-process-control-modal.message"
                           position="bottom">
                        Message
                    </label>
                    <ws-select id="controlMessage"
                               [returnEntireOption]="false"
                               [options]="controlMessageTypes"
                               formControlName="controlMessage"
                               labelProperty="name"
                               valueProperty="longRunningProcessControlMessageType">
                    </ws-select>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="serviceHost"
                           helpTooltip
                           helpContentId="long-running-process-control-modal.serviceHost"
                           position="bottom">
                        Host
                    </label>
                    <ws-select id="serviceHost"
                               [returnEntireOption]="true"
                               [isPrimitive]="true"
                               [options]="serviceHosts"
                               formControlName="serviceHost">
                    </ws-select>
                </div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
