<idp-confidence-panel *ngIf="showOcrResults && hasIdpFeature && billVM"
                      [currentDocument]="intakeDocumentInfo"
                      [billDetail]="billVM"
                      (idpAccepted)="idpAccepted($event)"
                      [billAmountDoesntAddUp]="billAmountDoesntAddUp"
                      style="width: 500px">
</idp-confidence-panel>
<div *ngIf="displayEditPanel" class="card panel-flat-color primary-panel document-processing-entity-edit-panel">
    <div class="panel-flat-header">
        <h3>Entity Edit</h3>
    </div>
    <div *ngIf="associatedEntityChanged && !verifiedChangedEntity"
         class="panel-flat-body"
         style="padding: 20px; overflow: initial;">
        <p>
            The entity this document is associated with has changed since the association
            was made. You will not be able to complete this task until the entity is
            reloaded.
        </p>
        <button type="button" class="btn flat-button secondary-button" (click)="verifyChangedEntity()">OK</button>
    </div>
    <div *ngIf="!associatedEntityChanged || verifiedChangedEntity"
         class="panel-flat-body"
         style="padding: 20px; overflow: initial;">
        <!-- Check for attachmentModalData.entityName since it loads from the entity tree, whereas the rest
            loads from the entity edit panel, and both must be loaded for this to work -->
        <div class="float-end" *ngIf="genericEntityVM && genericEntityVM.attachmentModalData.entityName">
            <entity-attachments [(hasAttachments)]="genericEntityVM.hasAttachments"
                                buttonClass="icon-button"
                                [modalData]="genericEntityVM.attachmentModalData">
            </entity-attachments>
            <entity-comments [(hasComments)]="genericEntityVM.hasComments"
                             buttonClass="icon-button"
                             [modalData]="genericEntityVM.commentModalData">
            </entity-comments>
        </div>
        <div *ngIf="genericEntityVM" class="mb-3" style="margin-top:2em;">
            <label>Year</label>
            <select class="form-select form-select-sm" [(ngModel)]="year">
                <option value=""></option>
                <option *ngFor="let y of years">{{y}}</option>
            </select>
        </div>
        <appeal [viewModel]="appealVM"
                [editState]="editState"
                [taskReadonly]="true"
                [hideDelete]="true"
                [isDocumentProcessing]="true">
        </appeal>
        <compliance
                [viewModel]="complianceVM"
                [editState]="editState"
                [companyId]="companyId"
                [taskReadonly]="true"
                [hideDelete]="true"
                [isDocumentProcessing]="true">
        </compliance>
        <bills [viewModel]="billVM"
               [editState]="editState"
               [showExtraFields]="showExtraBillFields"
               [stateId]="stateId"
               [isDocumentProcessing]="true"
               [processingParcelTaxSetup]="parcelChanges && parcelChanges.newTaxSetup"
               [firstEncounterSavedCallback]="wrappedFirstEncounterSavedCallback"
               [hasProcessingChanges]="dataEntryChanged"
               (RevisionChanged)="billRevisionChanged($event)">
        </bills>
        <refund [viewModel]="refundVM"
                [editState]="editState"
                [isDocumentProcessing]="true"></refund>
        <assessment [viewModel]="assessmentVM"
                    [editState]="editState"
                    [taskReadonly]="true"
                    [compactMode]="true"
                    [isDocumentProcessing]="true">
        </assessment>
        <div class="entity-edit-footer mb-3">
            <label>Attachment Description</label>
            <textarea class="form-control" [(ngModel)]="attachmentDescription"></textarea>
            <div *ngIf="isReadyTaskAnticipated" class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="completeReadyTask" (change)="onCompleteReadyTaskChange()" /> At completion of document processing mark the {{entityTypeName}}'s {{readyTaskName}} task COMPLETE.
                </label>
            </div>
            <div *ngIf="isReadyTaskReviewBill" class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="useDocumentAsBillImage" /> Use document as bill image
                </label>
            </div>
        </div>
    </div>
</div>
