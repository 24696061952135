import { ExtendedModelBase } from '../../Models/extendedModelBase';

export class ExtendedContactModel extends ExtendedModelBase<Core.ContactModel>  {
    constructor(model: Core.ContactModel) {
        super(model);
    }

    get displayName(): string {
        return `${this.model.firstName} ${this.model.lastName}`;
    }
}

export class ExtendedContactShortModel extends ExtendedModelBase<Core.ContactShortModel>  {
    constructor(model: Core.ContactShortModel) {
        super(model);
    }

    get displayName(): string {
        return `${this.model.firstName} ${this.model.lastName}`;
    }
}

export class ExtendedUserTeamModel extends ExtendedModelBase<Core.UserTeamModel>  {
    constructor(model: Core.UserTeamModel) {
        super(model);
    }

    get displayName(): string {
        return `${this.model.firstName} ${this.model.lastName} ${this.model.teamName ? ` (${this.model.teamName})`: ''}`;
    }
}
