import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { FormService } from '../form.service';
import { Subscription } from 'rxjs';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../AgGrid';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams } from '../../AgGrid/MultiSelectTracker';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'form-factor-table-add',
    templateUrl: './formFactorTableAdd.component.html',
    styleUrls: ['formFactorTableAdd.component.scss']
})
export class FormFactorTableAddComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, Compliance.FactorTableListItemModel[]> {
    constructor(
        private readonly _formService: FormService,
        private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _availableFactorTables: Compliance.FactorTableListItemModelSelectable[] = null;

    params: void;
    result: Compliance.FactorTableListItemModel[];

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FactorTableListItemModelSelectable).factorTableId)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    rowsSelected: boolean = false;

    ngOnInit(): void {
        const result = _.filter(this._formService.allFactorTables, (aft) => !_.some(this._formService.factorTables, (fft) => { return aft.factorTableId === fft.factorTableId; }));
        this._availableFactorTables = _.map(result, (fft) => { return { ...fft, isSelected: false } as Compliance.FactorTableListItemModelSelectable; });
        this._setRowData();
    }

    ngOnDestroy(): void {
        if (this._selectedRowsSub) {
            this._selectedRowsSub.unsubscribe();
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'factorTableId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const factorTable = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTable) {
                        return '';
                    }
                    return factorTable.assessorName ? factorTable.assessorName : factorTable.state;
                },
                filterValueGetter: params => {
                    const factorTable = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTable) {
                        return '';
                    }
                    return factorTable.assessorName ? factorTable.assessorName : factorTable.state;
                },
                cellClass: params => {
                    const factorTable = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTable) {
                        return '';
                    }
                    return factorTable.assessorName ? '' : 'assessor-state';
                }
            },
            {
                headerName: 'Name',
                field: 'name',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Life',
                type: 'numericColumn',
                field: 'life',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Type',
                field: 'tableType',
                filterValueGetter: x => {
                    return (x.data) ? this._formService.getFactorTableTypeName(x.data.tableType) : '';
                },
                valueFormatter: x => {
                    return (x.data) ? this._formService.getFactorTableTypeName(x.data.tableType) : '';
                },
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        const selectedRows = await this.gridTracker.getSelectedRowIds();
        const nodes = [];
        this._gridApi.forEachNode(x => {
            const ft = x.data as Compliance.FactorTableListItemModel;
            if (selectedRows.includes(ft.factorTableId)) {
                nodes.push(ft);
            }
        });
        this.result = nodes;
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        this._gridApi.setRowData(this._availableFactorTables);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map(x => {
                const factorTable = x.data as Compliance.FactorTableListItemModel;
                return factorTable && factorTable.factorTableId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
