import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { DashboardComponent } from './dashboard.component';
import { DashboardDataService } from './dashboard.data.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonModule } from '@angular/common';
import { UserWidgetComponent } from './Widgets/user.widget.component';
import { AppealSavingsWidgetComponent } from './Widgets/appeal.savings.widget.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GridsterModule } from 'angular-gridster2';
import { QuickSearchButtonWidgetComponent } from './Widgets/quick.search.button.widget.component';
import { StatesButtonWidgetComponent } from './Widgets/states.button.widget.component';
import { CalendarWidgetComponent } from './Widgets/Calendar-Widget/calendar.widget.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ShortcutWidgetSettingsComponent } from './Widgets/Shortcuts-Widget/shortcut.widget.settings.component';
import { ShortcutWidgetComponent } from './Widgets/Shortcuts-Widget/shortcut.widget.component';
import { FormsModule } from '@angular/forms';
import { WidgetComponentRegistryService } from './widget.component.registry.service';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { PortfolioAnalysisWidgetComponent } from './Widgets/Portfolio-Analysis-Widget/portfolio.analysis.widget.component';
import { PortfolioAnalysisChartTableComponent } from './Widgets/Portfolio-Analysis-Widget/portfolio.analysis.chart.table.component';
import { MyTasksWidgetComponent } from './Widgets/My-Tasks-Widget/my.tasks.widget.component';
import { MyTasksWidgetSettingsComponent } from './Widgets/My-Tasks-Widget/my.tasks.widget.settings.component';
import { CalendarWidgetSettingsComponent } from './Widgets/Calendar-Widget/calendar.widget.settings.component';
@NgModule({
    imports: [
        CommonModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        GridsterModule,
        WeissmanSharedModule,
        FormsModule
    ],
    declarations: [
        DashboardComponent,
        UserWidgetComponent,
        AppealSavingsWidgetComponent,
        QuickSearchButtonWidgetComponent,
        StatesButtonWidgetComponent,
        CalendarWidgetComponent,
        ShortcutWidgetComponent,
        ShortcutWidgetSettingsComponent,
        PortfolioAnalysisWidgetComponent,
        PortfolioAnalysisChartTableComponent,
        MyTasksWidgetComponent,
        MyTasksWidgetSettingsComponent,
        CalendarWidgetSettingsComponent
    ],
    providers: [DashboardDataService, WidgetComponentRegistryService],
    exports: [DashboardComponent]
})
export class DashboardModule {
    static setupModule(): void {
        let hybridAdapterUtility = new HybridAdapterUtility();

        //Entry components must have only two words, for example: parcelActivity not parcelActivitySummary
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        hybridAdapterUtility.downgradeNg2Component('dashboard', DashboardComponent);
        hybridAdapterUtility.downgradeNg2Provider('dashboardDataService', DashboardDataService)
    }
}
