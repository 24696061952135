<div [hidden]="bulkUpdateInProgress" class="ws-flex-container-vertical ws-flex-auto">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">Tax Rate Command Center</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div><small>View:</small></div>
                    <div dropdown container="body" placement="bottom right">
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                            {{ selectedGridSourceOption === 'authorities' ? 'Tax Authorities' : 'Tax Rate Areas' }}
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem">
                                <a class="pointer" (click)="gridSourceSelected('authorities')">Tax Authorities</a>
                            </li>
                            <li role="menuitem">
                                <a class="pointer" (click)="gridSourceSelected('areas')">Tax Rate Areas</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="flat-button small-button primary-button" (click)="refresh()" title="Refresh"><i class="fa fa-refresh"></i></button>
                    <button type="button" class="flat-button small-button secondary-button" *ngIf="!isMaximized" (click)="toggleMaximize(true)">
                        <i class="fa fa-arrows-alt" title="Expand"></i>
                    </button>
                    <button type="button" class="flat-button small-button secondary-button" *ngIf="isMaximized" (click)="toggleMaximize(false)">
                        <i class="fa fa-compress" title="Collapse"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <label class="ws-flex-none">State:</label>
                    <state-dropdown [(stateId)]="selectedStateId" (stateIdChange)="stateSelected()" [includeAll]="true" [isSmall]="true" [abbrOnly]="false"></state-dropdown>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" style="flex: 2 1 auto;">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <label class="ws-flex-none">Assessor:</label>
                    <ws-select
                        [(ngModel)]="selectedAssessorId"
                        (selectedOptionChanged)="assessorSelected()"
                        [disabled]="!selectedStateId || assessorsCollectorsLoading"
                        [options]="stateAssessors"
                        placeholder="All"
                        [canDeselect]="true"
                        deselectLabel="All"
                        labelProperty="name"
                        valueProperty="value">
                    </ws-select>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" style="flex: 2 1 auto;">
                <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                    <label class="ws-flex-none">Collector:</label>
                    <ws-select [(ngModel)]="selectedCollectorId"
                               (selectedOptionChanged)="collectorSelected()"
                               [disabled]="!selectedStateId || assessorsCollectorsLoading"
                               [options]="(assessorCollectors.length ? assessorCollectors : stateCollectors)"
                               placeholder="All"
                               [canDeselect]="true"
                               deselectLabel="All"
                               labelProperty="name"
                               valueProperty="value">
                    </ws-select>
                </div>
            </div>
            <div class="ws-section__filters__filter-group">
                <show-tax-year [(taxYearBegin)]="taxRateCommandCenterService.taxYearBegin" [(taxYearsPrior)]="taxRateCommandCenterService.taxYearsPrior" (showTaxYearChanged)="showTaxYearChangedCC()"></show-tax-year>
            </div>
            <div class="ws-section__filters__filter-group" [class.invisible]="selectedGridSourceOption === 'areas'">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="onlyPendingQC" (change)="onlyPendingQCChanged()"> Only Pending QC
                    </label>
                </div>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="'E73E3CB3-2AD9-43F5-9DA1-645272848A80'"
                        [exportOptions]="exportOptions"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        (bulkUpdate)="bulkUpdate()"> 
    </ws-ag-grid-angular>
    <div class="ws-flex-container-horizontal ws-stretch ws-overflow-auto" style="justify-content: space-around;" [class.remove-panel]="isMaximized">
        <div class="card panel-flat-color secondary-panel flex-panel" style="padding-bottom: 0; width: initial; margin: 15px 15px 15px 0;">
            <div class="panel-flat-header" style="margin-bottom: 0;">
                <h3>System-wide Tax Authority Rates Pending QC</h3>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical" style="justify-content: center; align-items: center;">
                <div style="position: absolute; top: 10px; right: 10px;">
                    <i [class.invisible]="!qcCountsUpdating" class="fa fa-spinner fa-pulse fa-2x" style="position: absolute; top: 3px; right: 80px;"></i>
                    <button class="flat-button small-button primary-button" (click)="refreshQcCounts()">Refresh</button>
                </div>
                <div style="width: 50%; max-height: 75%; overflow-y: auto;">
                    <table class="table table-hover table-condensed rates-pending-qc">
                        <thead>
                            <tr>
                                <th>State</th>
                                <th style="text-align: center;">Tax Authorities</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let state of pendingQcStateCounts">
                                <td (click)="filterGridByStateAndQc(state.item1)">{{state.item2}}</td>
                                <td style="text-align: center;" (click)="filterGridByStateAndQc(state.item1)">
                                    <span class="badge">{{state.item3}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold; padding: 8px;">Total</td>
                                <td style="text-align: center; border-top: 1px solid black; padding: 8px;" (click)="filterGridByStateAndQc(null)">
                                    <span class="badge">{{qcCountTotal}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="list-group" style="width: 40%;">
                    <button type="button" *ngFor="let state of pendingQcStateCounts" class="list-group-item text-left" style="width:100%;" (click)="filterGridByStateAndQc(state.item1)">
                      <span class="badge" style="background-color: #647A78;">{{state.item3}}</span>
                      {{state.item2}}
                    </button>
                    <button type="button" class="list-group-item text-left" style="width:100%;" (click)="filterGridByStateAndQc(null)">
                      <span class="badge" style="background-color: #647A78;">{{qcCountTotal}}</span>
                      Total
                    </button>
                </div> -->
            </div>
        </div>
        <div class="card panel-flat-color secondary-panel flex-panel" style="padding-bottom: 0; width: initial; margin: 15px 0 15px 15px;">
            <div class="panel-flat-header" style="margin-bottom: 0;">
                <h3>Import Tax Rate Information</h3>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical" style="justify-content: space-evenly; align-items: center;">
                <begin-new-import label="Import Tax Authorities" [canCloseProgressModal]="true" [isDisabled]="!hasTaxRatePermission && !hasDataImportPermission"></begin-new-import>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!bulkUpdateInProgress" class="container">
    <div [hidden]="bulkUpdateCanceled">
        <h3 *ngIf="!isBulkUpdateDone()" class="text-center">Bulk Updating Tax Authorities</h3>
        <h3 *ngIf="isBulkUpdateDone()" class="text-center">Done</h3>
        <progressbar class="active" [class.progress-striped]="bulkUpdateProgress.currentIndex < bulkUpdateProgress.selections.length"
                     [max]="bulkUpdateProgress.selections.length"
                     [value]="bulkUpdateProgress.currentIndex"
                     type="info"></progressbar>
        <h3 *ngIf="!isBulkUpdateDone()">{{getCurrentTABulkUpdateIdentity()}}</h3>
    </div>
    <div [hidden]="!bulkUpdateCanceled">
        <h3 class="text-center">Canceled</h3>
    </div>
    <div [hidden]="!showBulkUpdateRecalcSummary" style="margin-top: 10px; font-size: 16px">
        <table>
            <tr>
                <td style="text-align: right; font-weight: bold; padding: 8px;">
                    <label>Bill Clusters Recalculated:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.billClusterCount}}</td>
            </tr>
            <tr>
                <td style="text-align: right; font-weight: bold; padding: 8px;">
                    <label>Bills Recalculated:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.billCount}}</td>
            </tr>
            <tr>
                <td style="text-align: right; font-weight: bold; padding: 8px;">
                    <label>Payments Recalculated:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.paymentCount}}</td>
            </tr>
        </table>
    </div>
    <div class="text-center" style="margin-top: 20px;">
        <button *ngIf="bulkUpdateCanceled || isBulkUpdateDone()" class="flat-button" (click)="endBulkUpdate()">OK</button>
        <button *ngIf="!bulkUpdateCanceled && !isBulkUpdateDone()" class="flat-button warning-button" (click)="cancelBulkUpdate()">Cancel</button>
    </div>
</div>
