import { OnInit, Component, HostListener } from '@angular/core';
import { ProgressIndicator } from '../../Models/progressIndicator.model';
import { SnackSize, SnackBarService } from '../snackBar.service';

@Component({
    selector: 'snack-message',
    template: `
        <div *ngIf="processes.length" (click)="open()" class="snack-message">
            <div *ngIf="processing" class="snack-status-icon">
                <span class="fa-layers">
                    <i class="fa fa-spinner fa-pulse fa-lg spinner-icon"></i>
                    <span class="fa-layers-counter">{{processing}}</span>
                </span>
            </div>
            <div *ngIf="complete" class="snack-status-icon complete">
                <span class="fa-layers">
                    <span class="fa-layer-bg"></span>
                    <i class="fa fa-check-circle complete-icon"></i>
                    <span class="fa-layers-counter">{{complete}}</span>
                </span>
            </div>
        </div>
      `,
    styleUrls: ['./snackMessage.component.scss']
})
export class SnackMessageComponent implements OnInit {
    constructor(private readonly _snackBarService: SnackBarService) { }

    size: SnackSize;
    processes: ProgressIndicator[] = [];
    complete: number = 0;
    processing: number;

    @HostListener('mouseenter') onMouseEnter() {
        if (this.size === SnackSize.Hidden) {
            this._snackBarService.showMiniSnackBar();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.size === SnackSize.Small) {
            this._snackBarService.hideMiniSnackBar();
        }
    }

    ngOnInit(): void {
        this._snackBarService.displayState.subscribe(size => this.size = size);
        this._snackBarService.longRunningProcesses.subscribe(processes => {
            this.processes = processes;
            this.complete = processes.reduce((acc: number, p: ProgressIndicator) => (p.isComplete) ? (acc += 1) : acc, 0);
            this.processing = processes.length - this.complete;
        });
    }

    open(): void {
        this._snackBarService.showSnackBar();
    }

}
