<div class="card panel-flat-color secondary-panel small-panel" style="margin-top: 24px;">
    <div class="panel-flat-header small-header" style="margin-bottom: 0;">
        <div class="header-icon"><i class="fa fa-info-circle"></i></div>
        <h3>Invoice Details</h3>
        <div *ngIf="!currentInvoice.isAdHoc" class="float-end header-button-container">
            <button type="button"
                    class="btn flat-button icon-button primary-button"
                    (click)="refreshCurrentInvoice()"
                    *ngIf="editMode">
                <i class="fa fa-refresh" style="color: white;"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body">
        <table class="table table-borderless invoice-table">
            <thead *ngIf="!currentInvoice.isAdHoc">
                <tr>
                    <th>Parcel Acct Num</th>
                    <th>Appeal Level</th>
                    <th>Tax Year</th>
                    <th>Revision</th>
                    <th style="text-align: right;">Previous FMV</th>
                    <th *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button invisible">
                                <i class="fa fa-undo"></i>
                        </button>
                    </th>
                    <th style="text-align: right;">Invoice FMV</th>
                    <th *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button invisible">
                                <i class="fa fa-undo"></i>
                        </button>
                    </th>
                    <th style="text-align: right;">FMV Change</th>
                    <th style="text-align: right;">Tax Rate</th>
                    <th *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button invisible">
                                <i class="fa fa-undo"></i>
                        </button>
                    </th>
                    <th style="text-align: right;">Savings</th>
                    <th *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button invisible">
                                <i class="fa fa-undo"></i>
                        </button>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            <!-- APPEALS -->
                <tr *ngFor="let appeal of currentInvoice.appeals; let i = index" class="delete-hover">
                    <td><a style="cursor:pointer;" (click)="navigateToParcel(appeal)">{{appeal.parcelAcctNum}}</a></td>
                    <td>{{appeal.appealLevelAbbr}}</td>
                    <td>{{appeal.annualYear}}</td>
                    <td>{{appeal.revisionDesc}}</td>
                    <td style="text-align: right;">{{appeal.previousFMV | number: '1.0-0'}}</td>
                    <td *ngIf="editMode"></td>
                    <td style="text-align: right;">{{appeal.invoiceFMV | number: '1.0-0'}}</td>
                    <td *ngIf="editMode"></td>
                    <td style="text-align: right;">
                        {{appeal.previousFMV - appeal.invoiceFMV | number: '1.0-0'}}
                    </td>
                    <td style="text-align: right;">{{appeal.taxRate | percent:'1.6-6'}}</td>
                    <td *ngIf="editMode"></td>
                    <td style="text-align: right;">
                        <span [style.color]="appeal.savingsOverridden ? 'red' : 'black'">
                            ${{appeal.savings | number:'1.2-2'}}
                        </span>
                    </td>
                    <td *ngIf="editMode"></td>
                    <td>
                        <span style="color: red; float:right; cursor: pointer; width: 14px;"
                              *ngIf="editMode"
                              [style.display]="currentInvoice.appeals.length > 1 ? 'block' : 'none'">
                            <i class="fa fa-remove"
                               (click)="removeAppeal(i)"
                               [class.hidden-hover]="currentInvoice.appeals.length > 1"></i>
                        </span>
                    </td>
                </tr>
                <tr style="height: 50px;" *ngIf="!currentInvoice.isAdHoc"> 
                    <td colspan="4">
                        <div style="display: flex; justify-content: end;">
                            <div class="form-check">
                                <input type="checkbox"
                                        id="include-other"
                                       class="form-check-input"
                                       [disabled]="!editMode"
                                       [(ngModel)]="currentInvoice.includeOtherParcels"
                                       (change)="includeOtherParcelChange()" /> 
                                <label style="width: inherit; height: inherit;" class="form-check-label" for="include-other">Other Site <span>{{ constants.PropertyTypeNames[currentInvoice.propertyTypeID] }}</span> Parcels</label>
                            </div>
                        </div>
                    </td>
                    <td style="vertical-align: middle;text-align: right">
                        <span *ngIf="currentInvoice.includeOtherParcels" style="text-align: right;">{{currentInvoice.otherParcelFMV | number: '1.0-0'}}</span>
                        <span *ngIf="!currentInvoice.includeOtherParcels" style="text-align: right;">-</span>
                    </td>
                    <td *ngIf="editMode"></td>
                    <td style="vertical-align: middle;text-align: right">
                        <span *ngIf="currentInvoice.includeOtherParcels" style="text-align: right;">{{currentInvoice.otherParcelFMV | number: '1.0-0'}}</span>
                        <span *ngIf="!currentInvoice.includeOtherParcels" style="text-align: right;">-</span>
                    </td>
                    <td *ngIf="editMode"></td>
                    <td colspan="3"></td>
                    <td colspan="2" *ngIf="editMode"></td>
                    <td></td>
                </tr>

                <!--TOTALS-->
                <tr class="single-column" *ngIf="!currentInvoice.isAdHoc">
                    <td colspan="4">
                        <span style="float: right; font-weight: bold">Total: </span>
                    </td>
                    <td class="overline" style="text-align: right;">
                        <input type="text" class="form-control"
                               [style.color]="currentInvoice.previousFMVStatus"
                               style="width: 100%"
                               [(ngModel)]="currentInvoice.previousFMV"
                               *ngIf="editMode"
                               (blur)="calculatedFieldChanged(CalculatedFields.PreviousFMV)"
                               currencyMask
                               [options]="{ prefix: '', precision: '0', allowNegative: false }" />
                        <span *ngIf="!editMode" [style.color]="currentInvoice.previousFMVStatus">
                            {{currentInvoice.previousFMV | number: '1.0-0'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="overline override-button">
                         <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.previousFMVStatus === 'red'"
                            (click)="revertPreviousFMV(true)">
                                <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td class="overline" style="text-align: right;">
                        <input type="text" class="form-control"
                               [style.color]="currentInvoice.invoiceFMVStatus"
                               style="width: 100%"
                               [(ngModel)]="currentInvoice.invoiceFMV"
                               *ngIf="editMode"
                               (blur)="calculatedFieldChanged(CalculatedFields.InvoiceFMV)"
                               currencyMask
                               [options]="{ prefix: '', precision: '0', allowNegative: false }" />
                        <span *ngIf="!editMode" [style.color]="currentInvoice.invoiceFMVStatus">
                            {{currentInvoice.invoiceFMV | number: '1.0-0' }}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="overline override-button">
                         <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.invoiceFMVStatus === 'red'"
                            (click)="revertInvoiceFMV(true)">
                                <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td class="overline" [style.color]="currentInvoice.fmvChangeStatus" style="text-align: right;">{{currentInvoice.fmvChange | number: '1.0-0'}}</td>
                    <td class="overline" style="text-align: right;">
                        <input type="text" class="form-control"
                               [(ngModel)]="currentInvoice.displayTaxRate"
                               style="width: 100%"
                               [style.color]="currentInvoice.taxRateStatus"
                               (blur)="calculatedFieldChanged(CalculatedFields.TaxRate)"
                               *ngIf="editMode" />
                        <span *ngIf="!editMode" [style.color]="currentInvoice.taxRateStatus">
                            {{currentInvoice.displayTaxRate | number: '1.6-6'}}%
                        </span>
                    </td>
                    <td *ngIf="editMode" class="overline override-button">
                         <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.taxRateStatus === 'red'"
                            (click)="revertTaxRate(true)">
                                <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td class="overline" style="text-align: right;">
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="currentInvoice.savings"
                               style="width: 100%"
                               *ngIf="editMode"
                               (blur)="calculatedFieldChanged(CalculatedFields.Savings)"
                               currencyMask
                               [options]="{ allowNegative: false, precision: '2' }"
                               [style.color]="currentInvoice.savingsStatus" />
                        <span *ngIf="!editMode" [style.color]="currentInvoice.savingsStatus" >
                            ${{currentInvoice.savings | number: '1.2-2'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="overline override-button">
                         <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.savingsStatus === 'red'"
                            (click)="revertSavings()">
                                <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td class="overline"></td>
                </tr>
                <tr class="single-column">
                    <td colspan="6">
                        <span style="font-weight: bold; margin-right: 10px;">Notes on Invoice:</span>
                        <input type="checkbox"
                               [disabled]="!editMode"
                               [(ngModel)]="currentInvoice.automaticallyUpdateNotes"
                               (change)="automatedNoteChange()" />
                        <span>Automatically Update</span>
                    </td>
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span *ngIf="!currentInvoice.isAdHoc">Contingency %: </span>
                        <!--The page jumps around a bit between edit and read mode. I think this could be fixed better by either
                            not using tables for layout or trying to space form inputs out differently, but as a quick-fix for
                            ad hoc invoices where it's particularly bad, just put an intentional 20em space for this two-column
                            part of the table-->
                        <span *ngIf="currentInvoice.isAdHoc"
                              style="display:inline-block;width:20em;">
                        </span>
                    </td>
                    <td style="text-align: right;">
                        <input type="text" class="form-control"
                               *ngIf="editMode && !currentInvoice.isAdHoc"
                               style="width: 100%; text-align: right;"
                               [(ngModel)]="currentInvoice.displayContingencyPct"
                               [style.color]="currentInvoice.contingencyPctStatus"
                               currencyMask
                               [options]="{ allowNegative: false, precision: 2, prefix: '', suffix: '%' }"
                               (blur)="calculatedFieldChanged(CalculatedFields.ContingencyPct)" />
                        <span  *ngIf="!editMode && !currentInvoice.isAdHoc" [style.color]="currentInvoice.contingencyPctStatus">
                            {{currentInvoice.contingencyPct | percent: '1.2-2'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.contingencyPctStatus === 'red'"
                            (click)="revertContingencyPct()">
                            <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td></td>
                </tr>
                <tr class="single-column">
                    <td colspan="5" rowspan="5">
                        <textarea class="form-control"
                                  style="width: 100%; height: 146px; min-width:20em;"
                                  [disabled]="!editMode || currentInvoice.automaticallyUpdateNotes"
                                  [style.color]="currentInvoice.automaticallyUpdateNotes ? 'black' : 'red'"
                                  [(ngModel)]="currentInvoice.notes"></textarea>
                    </td>
                    <td rowspan="5"></td>
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span *ngIf="!currentInvoice.isAdHoc">Contingency Cap:</span>
                    </td>
                    <td style="text-align: right;">
                        <input tyle="text"
                               class="form-control"
                               style="width: 100%; text-align: right;"
                               [(ngModel)]="currentInvoice.contingencyCap"
                               [style.color]="currentInvoice.contingencyCapStatus"
                               *ngIf="editMode && !currentInvoice.isAdHoc"
                               (blur)="calculatedFieldChanged(CalculatedFields.ContingencyCap)"
                               currencyMask
                               [options]="{ allowNegative: false }" />
                        <span *ngIf="!editMode && !currentInvoice.isAdHoc" [style.color]="currentInvoice.contingencyCapStatus">
                            {{currentInvoice.contingencyCap | currency:'USD'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.contingencyCapStatus === 'red'"
                            (click)="revertContingencyCap()">
                            <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td></td>
                </tr>
                <tr class="single-column">
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span *ngIf="!currentInvoice.isAdHoc">Contingency Fee Amount:</span>
                    </td>
                    <td style="text-align: right;">
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="currentInvoice.feeAmount"
                               style="width: 100%; text-align: right;"
                               *ngIf="editMode && !currentInvoice.isAdHoc"
                               [style.color]="currentInvoice.feeAmountStatus"
                               currencyMask
                               [options]="{ allowNegative: false }"
                               (blur)="calculatedFieldChanged(CalculatedFields.FeeAmount)" />
                        <span *ngIf="!editMode && !currentInvoice.isAdHoc" [style.color]="currentInvoice.feeAmountStatus">
                            {{currentInvoice.feeAmount | currency:'USD'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.feeAmountStatus === 'red'"
                            (click)="revertFeeAmount()">
                            <i class="fa fa-undo"></i>
                        </button>
                    </td>
                    <td></td>
                </tr>
                <tr class="single-column">
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span>Fixed Fee Amount:</span>
                    </td>
                    <td style="text-align: right;">
                        <input type="text"
                               class="form-control"
                               [(ngModel)]="currentInvoice.fixedFee"
                               style="width: 100%; text-align: right;"
                               *ngIf="editMode"
                               [style.color]="currentInvoice.fixedFeeStatus"
                               currencyMask
                               [options]="{ allowNegative: false }"
                               (blur)="calculatedFieldChanged(CalculatedFields.FixedFee)" />
                        <span *ngIf="!editMode" [style.color]="currentInvoice.fixedFeeStatus">
                            {{currentInvoice.fixedFee | currency:'USD'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="override-button">
                        <button type="button"
                            class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.fixedFeeStatus === 'red'"
                            (click)="revertFixedFee()">
                            <i class="fa fa-undo"></i>
                        </button>
                    </td>
                </tr>
                <tr class="single-column">
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span>Expense Amount:</span>
                    </td>
                    <td style="text-align: right;">
                        <input tyle="text"
                               class="form-control"
                               [(ngModel)]="currentInvoice.expenseAmount"
                               style="width: 100%; text-align: right;"
                               *ngIf="editMode"
                               currencyMask
                               [options]="{ allowNegative: false }"
                               (blur)="calculatedFieldChanged(CalculatedFields.ExpenseAmount)"/>
                        <span *ngIf="!editMode">{{currentInvoice.expenseAmount | currency:'USD'}}</span>
                    </td>
                    <td *ngIf="editMode" class="override-button">
                        <!-- <button type="button"
                                class="flat-button small-button warning-button"
                            *ngIf="editMode && currentInvoice.contingencyPctStatus == 'red'"
                            (click)="revertContingencyPct()">
                            <i class="fa fa-undo"></i>
                        </button> -->
                    </td>
                    <td></td>
                </tr>
                <tr class="single-column">
                    <td colspan="3" *ngIf="editMode"></td>
                    <td colspan="2" style="text-align: right;">
                        <span>Invoice Amount:</span>
                    </td>
                    <td class="overline" style="text-align: right;">
                        <span [style.color]="currentInvoice.invoiceAmountStatus">
                            {{currentInvoice.invoiceAmount | currency:'USD'}}
                        </span>
                    </td>
                    <td *ngIf="editMode" class="override-button"></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
