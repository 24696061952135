import { Output, Directive, ElementRef, EventEmitter, HostListener } from '@angular/core'

/**
 * Detects if a click has occured outside the element it is placed on
 *
 * Example usage:
 * <div [class.active]="dropdownActive" clickOutside (onClickOutside)="closeDropdown()">...content</div>
 *
 * Clicking outside the div will call the closeDropdown() method to change state of the dropdownActive boolean
 */
@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {}

    @Output() onClickOutside: EventEmitter<void> = new EventEmitter();

    @HostListener('document:mousedown', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.onClickOutside.emit();
        }
    }

}
