<div class="document-intake">
    <div class="page-header">
        <h1 class="page-title">Document Intake</h1>
    </div>
    <div *ngIf="batchDocument" style="display: flex; flex-direction: column;">
        <div style="display: flex;">
            <div class="card panel-flat-color primary-panel" style="width: 500px; margin-bottom: 0;">
                <div class="panel-flat-header" style="margin-bottom: 0;">
                    <div class="header-icon"><i class="fa fa-info"></i></div>
                    <h3>Document Information</h3>
                </div>
                <div class="panel-flat-body">
                    <i *ngIf="uploading || !showForm" style="position: absolute; left:43%; top: 41%;" class="fa fa-spinner fa-pulse fa-5x"></i>
                    <div [class.invisible]="uploading || !showForm">
                        <div class="form-inline text-center" style="margin-bottom: 20px;">
                            <label style="margin-right: 8px;">
                                <input type="radio" [value]="true" [(ngModel)]="batchDocument.splitDocument"> Single Document (Split)
                            </label>
                            <label>
                                <input type="radio" [value]="false" [(ngModel)]="batchDocument.splitDocument"> Multiple Documents
                            </label>
                        </div>
                        <table class="doc-info-panel">
                            <tr *ngIf="batchDocument.splitDocument">
                                <td>Exclude the first</td>
                                <td>
                                    <input type="text" class="form-control" style="display: inline-block; width: 40px;" [(ngModel)]="batchDocument.excludeFirstXPages"
                                        (blur)="resetExclusionInput()" currencyMask [options]="{ allowNegative: false, precision: 0, prefix: ''}" (ngModelChange)="pageExclusionChanged()" /> <span>pages</span>
                                </td>
                            </tr>
                            <tr *ngIf="batchDocument.splitDocument">
                                <td>Pages Per Item</td>
                                <td>
                                    <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="batchDocument.pagesPerItem" required [disabled]="invalidPageCount">
                                        <option *ngFor="let item of pagesPerItemList" [ngValue]="item">{{item}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngIf="shouldDisplayAssignToRyan">
                                <td>Assign to Ryan</td>
                                <td>
                                    <input type="checkbox" class="form-check-input" [ngModel]="batchDocument.assignToRyan" (change)="assignToRyanChanged($event)">
                                </td>
                            </tr>
                            <tr *ngIf="enableIntakeToLicensedInstance">
                                <td>Intake to Licensed Instance</td>
                                <td>
                                    <input type="checkbox" class="form-check-input" [(ngModel)]="intakeToLicensedInstance" (change)="intakeToLicensedInstanceChanged($event)">
                                </td>
                            </tr>
                            <tr *ngIf="enableIntakeToLicensedInstance && intakeToLicensedInstance">
                                <td>Client Instance:</td>
                                <td>
                                    <input [(ngModel)]="selectedInstanceName"
                                           [typeahead]="licensedClients"
                                           class="form-control"
                                           typeaheadOptionField="instanceName"
                                           [typeaheadOptionsLimit]="100"
                                           [typeaheadMinLength]="2"
                                           [typeaheadScrollable]="true"
                                           (typeaheadOnSelect)="selectInstance($event.item)"
                                           (mouseup)="select($event.target)"
                                           (ngModelChange)="changed()"
                                           (typeaheadOnBlur)="onBlur()"
                                           [disabled]="!licensedClients.length">
                                </td>
                            </tr>
                            <tr>
                                <td>Ultimately Resp:</td>
                                <td>
                                    <label *ngIf="batchDocument.assignToRyan">Consultant - Ryan</label>
                                    <user-team-picker *ngIf="!batchDocument.assignToRyan" [parentUsers]="documentIntakeUsers" [(user)]="batchDocument.ultimatelyResponsible" [showDocumentIntakeUserTeamsOnly]="true" (userChange)="ultimatelyResponsibleChosen($event)" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
                                </td>
                            </tr>
                            <tr>
                                <td>Assigned Entry:</td>
                                <td>
                                    <label *ngIf="batchDocument.assignToRyan">Consultant - Ryan</label>
                                    <user-team-picker *ngIf="!batchDocument.assignToRyan" [parentUsers]="documentIntakeUsers" [(user)]="batchDocument.assignedEntry" [showDocumentIntakeUserTeamsOnly]="true" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
                                </td>
                            </tr>
                            <tr>
                                <td>Assigned QC:</td>
                                <td>
                                    <label *ngIf="batchDocument.assignToRyan">Consultant - Ryan</label>
                                    <user-team-picker *ngIf="!batchDocument.assignToRyan" [parentUsers]="documentIntakeUsers" [(user)]="batchDocument.assignedQC" [showDocumentIntakeUserTeamsOnly]="true"></user-team-picker>
                                </td>
                            </tr>
                            <tr>
                                <td>Contact:</td>
                                <td>
                                    <label *ngIf="batchDocument.assignToRyan || enableIntakeToLicensedInstance && intakeToLicensedInstance">Client Contact</label>
                                    <user-team-picker *ngIf="!batchDocument.assignToRyan && (!enableIntakeToLicensedInstance || !intakeToLicensedInstance)" [parentUsers]="documentIntakeUsers" [(user)]="batchDocument.contact" [showDocumentIntakeUserTeamsOnly]="true" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
                                </td>
                            </tr>
                            <tr>
                                <td>Document Type:</td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            [(ngModel)]="batchDocument.documentTypeID"
                                            required
                                            [class.ng-touched]="saveAttempted">
                                        <option *ngFor="let item of documentTypeList" [ngValue]="item.attachmentTypeID">{{item.attachmentTypeName}}</option>
                                    </select>
                                </td>
                            </tr>
                            <!--Only show if document type is Tax Bill-->
                            <tr *ngIf="featureFlagsService.featureFlags.enableIdp && batchDocument.documentTypeID === 9 && instanceHasIdpEnabled">
                                <td>Perform OCR:</td>
                                <td>
                                    <input type="checkbox" class="form-check-input" [(ngModel)]="batchDocument.performOcr">
                                </td>
                            </tr>
                            <tr>
                                <td>Earliest Due Date:</td>
                                <td>
                                    <weissman-datetime  [(inputDate)]="batchDocument.earliestDueDate" [required]="true" [dateOnly]="true" [setAsTouched]="saveAttempted"></weissman-datetime>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <hr />
                                </td>
                            </tr>
                            <tr>
                                <td>State:</td>
                                <td>
                                    <state-dropdown
                                        [(stateId)]="batchDocument.stateID"
                                        [includeBlank]="true"
                                        [disabled]="!!batchDocument.assessorID || !!batchDocument.collectorID"
                                        (stateIdChange)="stateChanged()"
                                        [abbrOnly]="false">
                                    </state-dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td>Assessor:</td>
                                <td>
                                    <select class="form-select form-select-sm" [disabled]="!batchDocument.stateID" (change)="getCollectorList()" [(ngModel)]="batchDocument.assessorID">
                                        <option [ngValue]="undefined"></option>
                                        <option *ngFor="let assessor of assessorList" [ngValue]="assessor.assessorID">{{assessor.name}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Collector:</td>
                                <td>
                                    <select class="form-select form-select-sm" [disabled]="!batchDocument.stateID || collectorsLoading" [(ngModel)]="batchDocument.collectorID">
                                        <option [ngValue]="undefined"></option>
                                        <option *ngFor="let collector of collectorList" [ngValue]="collector.collectorID">{{collector.abbr}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Company:</td>
                                <td>
                                    <company-picker [company]="company" (companySelected)="companySelected($event)" [clientInstanceId]="clientInstanceId"></company-picker>
                                </td>
                            </tr>
                            <tr>
                                <td>Tax Year:</td>
                                <td>
                                    <select class="form-select form-select-sm" [(ngModel)]="batchDocument.taxYear">
                                        <option [ngValue]="undefined"></option>
                                        <option *ngFor="let taxYear of taxYearList" [ngValue]="taxYear">{{taxYear}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;">Comment:</td>
                                <td>
                                    <textarea class="form-control" rows="2" [(ngModel)]="batchDocument.commentBody"></textarea>
                                </td>
                            </tr>
                        </table>

                        <hr />

                        <div style="display: flex; justify-content: space-between; padding: 15px;">
                            <button type="button" class="flat-button secondary-button small-button" (click)="initializeForm()">Clear Form</button>
                            <button type="button" class="flat-button primary-button" (click)="uploadClicked()" [disabled]="isUploadDisabled()">Upload Documents</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex: 1; display: flex;">
                <div *ngIf="!(batchDocument.splitDocument && singleFile)" style="flex: 1; display: flex; flex-direction: column; align-items: center;">
                    <ws-file-picker style="width: 500px;" [acceptTypes]="batchDocument.splitDocument ? '.pdf' : ''" (picked)="filesPicked($event)" [hideFileNames]="true" [multiple]="true"></ws-file-picker>
                    <table *ngIf="!batchDocument.splitDocument" class="table" style="width: 600px; margin-top: 25px;">
                        <tbody>
                            <tr *ngFor="let file of multiFiles; let i=index;">
                                <td class="pointer" (click)="viewMultiFile(file, multiFileViewer)">{{file.name}}</td>
                                <td>
                                    <button type="button" class="flat-button icon-button warning-button" (click)="removeMultiFile(i)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <file-viewer *ngIf="batchDocument.splitDocument && singleFile" style="flex: 1; padding-left: 50px;" [file]="singleFile"></file-viewer>
            </div>
        </div>
        <div style="display: flex; justify-content: flex-end;" [class.invisible]="!(batchDocument.splitDocument && singleFile)">
            <button class="flat-button small-button primary-button" (click)="removeSingleDocument()">Remove</button>
        </div>
    </div>
</div>

<ng-template #multiFileViewer>
    <div style="padding: 50px;" [style.height]="selectedMultiFile.type === 'application/pdf' ? '700px' : 'initial'">
        <file-viewer [file]="selectedMultiFile"></file-viewer>
    </div>
</ng-template>
