import { HelpContent } from '../../../../../UI-Lib/Help-Tooltip';

export const RETURN_PREVIEW_FORM_VIEWER_HELP: HelpContent[] = [
    {
        helpContentId: 'return-preview-form-viewer.show-field-locations',
        tooltipText: 'Shows where the data came from for each field populated by the system.'
    },
    {
        helpContentId: 'return-preview-form-viewer.apply-override',
        tooltipText: 'Determines if overrides are applied only to this form or to all forms in the list.'
    },
    {
        helpContentId: 'return-preview-form-viewer.existing-override',
        tooltipText: 'Overrides the existing override'
    }
];
