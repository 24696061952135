<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header">
        <h3>Billing Scenarios</h3>
        <div class="checkbox show-disabled">
            <label>
                <input type="checkbox" [(ngModel)]="showDisabled">Show disabled Billing Scenarios
            </label>
        </div>
        <div class="float-end header-button-container">
            <div class="ws-display-inline-block">
                <div class="date-selector ws-flex-container-horizontal ws-flex-align-items-center">
                    <span>Tax Year:</span>
                    <ws-select [options]="taxYears"
                               [isPrimitive]="true"
                               size="sm"
                               [ngModel]="selectedYear"
                               (ngModelChange)="yearChanged($event)">
                    </ws-select>
                </div>
            </div>
            <div class="ws-display-inline-block" helpTooltip helpContentId="collector.add-billing-scenario">
                <button class="flat-button icon-button fa fa-plus-circle" (click)="openScenario()" *ngIf="!viewOnly"></button>
            </div>
        </div>
    </div>
    <div class="panel-flat-body">
        <div *ngIf="showYearWarning" class="payment-warning">
            <i class="fa fa-warning"></i>
            <span>Displayed payment due dates use current due date values and may not be accurate to the values used historically</span>
        </div>
        <div class="scenarios">
            <ng-container *ngFor="let scenario of collector.collectorBillScenarios">
                <div class="scenario clickable"
                   *ngIf="showDisabled || scenario.enabled"
                   (click)="openScenario(scenario)">
                    <div class="scenario-title ws-flex-container-horizontal ws-flex-justify-content-space-between ws-flex-align-items-center">
                        <h4 class="ws-font-weight-bold ws-no-margin">
                            <span *ngIf="scenario.name!==null">{{scenario.name}}</span>
                            <span *ngIf="scenario.name===null">Scenario</span>
                        </h4>
                        <div *ngIf="scenario.isDefault" class="default-scenario">
                            <h4 class="ws-no-margin">Default</h4>
                        </div>
                    </div>
                    <div class="bills ws-flex-container-vertical ws-stretch">
                        <div *ngFor="let bill of scenario.collectorBills; let first = first" class="bill ws-flex-container-horizontal">
                            <div>
                                <div class="">{{bill.name}}</div>
                                <div class="" style="margin-top: 10px;">Payment Option(s):</div>
                            </div>
                            <div class="options ws-flex-container-vertical">
                                <div *ngFor="let option of bill.collectorPaymentOptions | sortBy: 'asc': 'option.isDefault'; let first = first">
                                    <div *ngIf="!first" class="or-break ws-flex-container-horizontal ws-stretch-width ws-flex-align-items-center">
                                        <div class="break-line"></div>
                                        <span>or</span>
                                        <div class="break-line"></div>
                                    </div>
                                    <div class="option ws-flex-container-horizontal">
                                        <div class="col-md-3 ws-flex-container-horizontal">
                                            <div *ngIf="option.isDefault && bill.collectorPaymentOptions.length > 1" class="default-opt">Default</div>
                                            <span class="option-name">{{(option.name !== null ? option.name : 'Payment option')}}:</span>
                                        </div>
                                        <div class="option-details col-md-9">
                                            <table class="payments ws-stretch-width">
                                                <ng-container *ngFor="let payment of option.collectorPayments | sortBy: 'asc': paymentOrder; let paymentIndex = index">
                                                    <tr class="payment" [ngClass]="{ 'even': paymentIndex % 2 }">
                                                        <td>
                                                            <span>{{(payment.name !== null ? payment.name : 'Payment')}}</span>
                                                        </td>
                                                        <td class="text-align-right">
                                                            <span>due</span>
                                                        </td>
                                                        <td class="payment-date text-align-right">
                                                            <span>{{payment.dueMonthDay}}/{{toWords(payment.dueYearID)}}</span>
                                                        </td>
                                                        <td class="tax-period">
                                                            <span>Tax Period:</span>
                                                        </td>
                                                        <td class="payment-date text-align-right">
                                                            <span>{{payment.accrualBeginDate}}/{{toWords(payment.accrualBeginYearID)}}</span>
                                                        </td>
                                                        <td class="text-aligh-center">
                                                            <span>to</span>
                                                        </td>
                                                        <td>
                                                            <span>{{payment.accrualEndDate}}/{{toWords(payment.accrualEndYearID)}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="payment.discountPercent > 0" class="payment" [ngClass]="{ 'even': paymentIndex % 2 }">
                                                        <td colspan="3"></td>
                                                        <td class="tax-period">
                                                            <span>Discount:</span>
                                                        </td>
                                                        <td colspan="3" class="text-aligh-center">{{(payment.discountPercent * 100)}}%</td>
                                                    </tr>
                                                </ng-container>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
