import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../user.service';

export interface PasswordChangeData {
    current: string;
    'new': string;
    confirm: string;
}

@Component({
    selector: 'new-password',
    templateUrl: './newPassword.component.html'
})
export class NewPasswordComponent implements OnInit, OnDestroy {
    constructor(private readonly _userService: UserService,
                private readonly _fb: FormBuilder) {
    }

    @Input() showCurrent: boolean;
    @Input() passwordIncorrect: boolean;
    @Input() alreadyUsed: string;
    @Input() passwords: PasswordChangeData;
    @Input() passwordsValid: boolean;

    @Output() passwordsChange: EventEmitter<PasswordChangeData> = new EventEmitter();
    @Output() passwordsValidChange: EventEmitter<boolean> = new EventEmitter();

    form: FormGroup;
    passwordRegEx: RegExp;
    username = '';

    private _destroy$: Subject<void> = new Subject();

    doesNotMatchUsername = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return this.username && control.value === this.username ? { 'sdDoesNotMatch': true } : null;
        };
    };

    confirmMustMatchNew = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return control.value !== this.newPassword?.value ? { 'sdMustMatch': true } : null;
        };
    };

    isNotAlreadyUsed = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return this.alreadyUsed ? { 'alreadyUsed': true } : null;
        };
    };

    currentPasswordValid = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            return this.passwordIncorrect ? { 'incorrect': true } : null;
        };
    };

    get current(): FormControl {
        return this.form?.get('current') as FormControl<string>;
    }

    get newPassword(): FormControl {
        return this.form?.get('new') as FormControl<string>;
    }

    get confirm(): FormControl {
        return this.form?.get('confirm') as FormControl<string>;
    }

    ngOnInit(): void {
        this.passwordRegEx = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

        this.form = this._fb.group({
            current: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.pattern(this.passwordRegEx),
                this.currentPasswordValid()
            ]],
            new: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.pattern(this.passwordRegEx),
                this.doesNotMatchUsername(),
                this.isNotAlreadyUsed()
            ]],
            confirm: ['', [
                Validators.required,
                this.confirmMustMatchNew()
            ]]
        });

        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
            this.passwordsChange.emit(this.form.getRawValue());
            this.passwordsValidChange.emit(this.form.valid);
        });

        const user = this._userService.getUser();
        if (user) {
            this.username = user.username;
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
