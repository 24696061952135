import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RevenueShare } from '../invoice.model';

export interface ICellRendererParamsForRevenueShareGridAction extends ICellRendererParams {
    canDelete: (params: ICellRendererParamsForRevenueShareGridAction) => boolean;
    delete: (params: ICellRendererParamsForRevenueShareGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button *ngIf="params && params.canDelete(params)" type="button" class="btn flat-button icon-button warning-button grid-action-button" title="Delete" (click)="onDeleteClicked()">
            <i class="fa fa-trash"></i>
        </button>`
})
export class RevenueShareGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForRevenueShareGridAction;
    revenueShare: RevenueShare;
    canDelete: boolean;

    agInit(params: ICellRendererParamsForRevenueShareGridAction): void {
        this.params = params;
        this.revenueShare = params.data as RevenueShare;
        if (!this.revenueShare) {
            return;
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onDeleteClicked(): void {
        this.params.delete(this.params);
    }
}
