import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReturnService } from "../../return.service";

import * as _ from 'lodash';

@Component({
    selector: 'return-settings-tab',
    templateUrl: './returnSettingsTab.component.html'
})
export class ReturnSettingsTabComponent implements OnInit, OnDestroy {
    constructor(private _returnService: ReturnService) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    get showGeneralReturnSettings(): boolean {
        return this._returnService.filingBatch &&
            (!!_.find(this._returnService.getAssociatedReturnFormRevisions(), i => i.supportsNBVReporting || i.supportsEstFMVReporting) ||
                this._returnService.isReturnInReadOnlyMode && (this._returnService.filingBatch.reportNBVInsteadOfFactoredCost ||
                    this._returnService.filingBatch.returnCostReportingDisplayId !== Compliance.ReturnCostReportingDisplayEnum.Both));
    }
}
