'use strict';

/**
 * @ngdoc directive
 * @name weissmanApp.directive:searchResultParcel
 * @description
 * # searchResultParcel
 */
angular.module('weissmanApp')
	.directive('searchResultParcel', ['$compile', 'sd.Property.Type.Service', function ($compile, propertyTypeService) {
		return {
			templateUrl: 'app/Search/Quick/_quickResultParcel.html',
			restrict: 'E',
			scope: {
				result: '=',
				childEntity: '=?'
			},
			link: function postLink(scope, element, attrs) {
				var propertyTypes;
				propertyTypeService.get().then(function (types) {
					propertyTypes = types;

					scope.getPropType = function (id) {
						if (!id) {
							return;
						}
						return _.find(propertyTypes, {
							propertyTypeID: id
						}).propTypeAbbr;
					}
				});

				scope.acctNums = _.chain(scope.result)
					.pickBy(function (val, key) {
						var keyArr = key.split('.');
						return keyArr[0] === 'parcelAccountNumber' && val && keyArr[1][0] !== '!';
					})
					.map(function (val, key) {
						console.log(key)
						return {
							label: key.substring(key.indexOf('.') + 1),
							number: val
						}
					})
					.value();
			}
		};

		//function getPropertyTypes() {
		//	return $http.get('/api/propertytype/').then(function (result) {
		//		return result.data;
		//	})
		//}
	}]);
