import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const LONG_RUNNING_PROCESS_CONTROL_MODAL_HELP: HelpContent[] = [
    {
        helpContentId: 'long-running-process-control-modal.message',
        tooltipText: 'Message to send to the long-running process host',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-control-modal.serviceHost',
        tooltipText: 'Long-running process host to send the message to',
        hasIcon: true
    }
];
