import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {CommonBulkUpdateFieldActionEnum} from '../../../constants.new';

@Component({
    selector: 'tax-authority-bulk-update-action',
    templateUrl: './tax.authority.bulk.update.action.component.html',
    styles: ['select {width:120px}']
})
export class TaxAuthorityBulkUpdateActionComponent implements OnInit {
    constructor() {
        this.possibleActions = [
            { name: 'No Change', value: CommonBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: CommonBulkUpdateFieldActionEnum.ChangeTo },
            { name: 'Set to Blank', value: CommonBulkUpdateFieldActionEnum.SetToBlank }
        ];
    }

    @Input() fieldAction: CommonBulkUpdateFieldActionEnum;
    @Output() fieldActionChange = new EventEmitter<CommonBulkUpdateFieldActionEnum>();
    @Input() requiredField: boolean;
    @Input() disabled: boolean;
    
    possibleActions: Compliance.NameValuePair<CommonBulkUpdateFieldActionEnum>[];
    selectedAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;

    ngOnInit(): void {
        if (this.requiredField) {
            this.possibleActions = this.possibleActions.filter(i => i.value !== CommonBulkUpdateFieldActionEnum.SetToBlank);
        }
    }

    fieldActionChanged(action: CommonBulkUpdateFieldActionEnum) : void {
        this.selectedAction = action;
        this.fieldActionChange.next(action);
    }

    isSelected(action: CommonBulkUpdateFieldActionEnum): boolean {
        return action === CommonBulkUpdateFieldActionEnum.NoChange;
    }
}