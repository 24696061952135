import { Injectable} from '@angular/core';
import { WeissmanKeyValueDisplayPipe } from '../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';

@Injectable()
export class ComplianceSiteService  {
    constructor(private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe) { }

    getLinkedSiteName(linkedSite: Core.LinkedSiteModel) {
        return linkedSite.siteProperty
            ? this._keyValueDisplayPipe.transform(linkedSite, linkedSite.siteProperty, linkedSite.siteName)
            : linkedSite.siteName;
    }
}
