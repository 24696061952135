(function() {
	'use strict';

	/**
     * @ngdoc service
     * @name weissman.ContactRoleAssociation
     * @description
     * # ContactRoleAssociations
     * Factory in the weissman.
     */
	angular
        .module('weissmanApp')
        .factory('ContactRoleAssociationService',ContactRoleAssociationService);

	ContactRoleAssociationService.$inject=['sd.Http.Service', '$uibModal', '$rootScope'];
	function ContactRoleAssociationService(sdHttp, $uibModal, $rootScope) {
		var baseUrl='/api/ContactRoleAssociation/';

		var service={
			add: addContactRoleAssociation,
			'delete': deleteContactRoleAssociation,
            openContactAssociationModal: openContactAssociationModal
		};

		return service;

		function addContactRoleAssociation(data) {
			return sdHttp.post(baseUrl,data);
		}

		function deleteContactRoleAssociation(id) {
			var url=baseUrl+id;
			return sdHttp.delete(url)
		}

        async function openContactAssociationModal(params) {
            const modalScope = $rootScope.$new();
            modalScope.dialogParameters = params;

            const modalConfig = {
                templateUrl: 'app/Contact/List/_contactList.html',
                controller: 'sd.Contacts.List.Controller',
                size: 'lg',
                windowClass: 'associate-contact-modal show',
				backdropClass: 'show',
                controllerAs: 'vm',
                scope: modalScope,
            };

            const modalInstance = $uibModal.open(modalConfig);

            return await modalInstance.result;
        }
	}
})();
