import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BudgetTaxYearMissingSyncData} from './forecasting.budgeting.model';

@Component({
    selector: 'budget-taxyear-sync-budgets',
    templateUrl: './budget.taxyear.sync.budgets.component.html',
    styleUrls: ['./forecasting.budgeting.page.scss']
})
export class BudgetTaxYearSyncBudgetsComponent {
    @Input() missingSyncData: BudgetTaxYearMissingSyncData;
    @Input() lrUpdateInProgress: boolean = false;
    @Input() editMode: boolean;
    @Input() isBudgetFrozen: boolean;
    @Output() syncClick = new EventEmitter<void>();

    syncBudgets() : void {
        this.syncClick.next();
    }

    isSyncBudgetsAllowed() : boolean {
        return !this.lrUpdateInProgress &&
               this.missingSyncData.numOutOfSyncBudgets > 0 &&
               !this.isBudgetFrozen;
    }
}