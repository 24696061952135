import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentModalData } from 'src/app/Attachment/attachment.modal.model';
import { CommentModalData } from 'src/app/Comments/comments.service';

export interface ICellRendererParamsForPaymentBatchListGridAction extends ICellRendererParams {
    canEdit: boolean;
    viewDetails: (params: ICellRendererParamsForPaymentBatchListGridAction) => void;
    delete: (params: ICellRendererParamsForPaymentBatchListGridAction) => void;
    canDelete: (params: ICellRendererParamsForPaymentBatchListGridAction) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button type="button"
            *ngIf="paymentBatch"
            helpTooltip
            helpContentId="app.view"
            position="bottom"
            class="btn flat-button primary-button icon-button grid-action-button"
            (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>
        <span class="icon-indicator" *ngIf="paymentBatch">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="paymentBatch && paymentBatch.hasComments">
            <i class="fa fa-commenting"></i>
        </span>
        <span class="icon-indicator" *ngIf="paymentBatch && !paymentBatch.hasComments">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="paymentBatch && paymentBatch.hasAttachments">
            <i class="fa fa-files-o"></i>
        </span>
        <entity-comments *ngIf="paymentBatch"
            [(hasComments)]="paymentBatch && paymentBatch.hasComments"
            buttonClass="flat-button primary-button icon-button grid-action-button"
            [modalData]="commentModalData"
            helpTooltip
            helpContentId="app.comments"
            position="bottom">
        </entity-comments>
        <entity-attachments *ngIf="paymentBatch" [(hasAttachments)]="paymentBatch && paymentBatch.hasAttachments"
            buttonClass="flat-button primary-button icon-button grid-action-button"
            [modalData]="attachmentModalData"
            helpTooltip
            helpContentId="app.attachments"
            position="bottom">
        </entity-attachments>
        <button type="button"
            *ngIf="paymentBatch && params.canDelete(params)"
            class="btn flat-button icon-button warning-button grid-action-button"
            (click)="onDeleteClicked()"
            helpTooltip
            helpContentId="app.delete"
            position="bottom">
            <i class="fa fa-trash"></i>
        </button>
`
})
export class PaymentBatchListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForPaymentBatchListGridAction;
    paymentBatch: Core.PaymentBatchModel;
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;

    agInit(params: ICellRendererParamsForPaymentBatchListGridAction): void {
        this.params = params;
        this.paymentBatch = params.data as Core.PaymentBatchModel;

        if (!this.paymentBatch) {
            return;
        }
        this.attachmentModalData = {
            entityType: 'PaymentBatch', //todo: make this a constant generated typescript value
            entityName: this.paymentBatch.description,
            disableYears: false,
            singleYear: false,
            entityData: {
                typeId: Core.EntityTypes.PaymentBatch,
                id: this.paymentBatch.paymentBatchId,
                name: this.paymentBatch.description
            },
            readOnly: !this.params.canEdit
        } as AttachmentModalData;

        this.commentModalData = {
            entityID: this.paymentBatch.paymentBatchId,
            entityTypeID: Core.EntityTypes.PaymentBatch,
            entityName: this.paymentBatch.description,
            parcelAcctNum: null,
            canEdit: this.params.canEdit,
            description: null,
            parcelID: null,
        } as CommentModalData;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this.params.viewDetails(this.params);
    }

    onDeleteClicked(): void {
        this.params.delete(this.params);
    }
}
