<ws-modal-wrapper headerLabel="Apply Applicable Digital Signatures"
                  [hideFooter]="true"
                  (cancel)="cancel()">
    <ag-grid-row-count [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
    <ws-ag-grid-angular wrapperClass="app-ui-view return-sign-modal-list"
                        [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="gridId">
    </ws-ag-grid-angular>
    <div class="modal-footer">
        <div class="ws-button-row-warning">
            <warning-message [message]="wetSignatureOverrideWarning" [show]="!!wetSignatureOverrideWarning"></warning-message>
        </div>
        <div class="ws-button-row">
            <button class="btn flat-button primary-button" (click)="save()" [disabled]="!isValid">OK</button>
            <button class="btn flat-button warning-button" helpTooltip
                    helpContentId="return-sign.skip-button"
                    position="bottom"
                    (click)="skip()">
                Skip
            </button>
            <button class="btn flat-button warning-button" (click)="cancel()">Cancel</button>
        </div>
    </div>
</ws-modal-wrapper>
