<ng-container *ngIf="show">
    <div class="offcanvas-backdrop fade" [class.show]="!closed"></div>
</ng-container>
<div class="offcanvas offcanvas-start" [class.show]="!closed" [class.hiding]="hiding">
    <div class="sidenav d-flex flex-column align-items-stretch h-100" clickOutside (onClickOutside)="close()">
        <div class="sidenav-header">
            <ws-ace-button buttonStyle="text"
                           icon="times"
                           (buttonClick)="close()">
            </ws-ace-button>
        </div>
        <div class="scrollable flex-grow-1 d-flex flex-column align-items-stretch">
            <ace-navbar [actions]="navActions"
                        [currentUrl]="currentUrl"
                        (activated)="handleActionTriggered($any($event))"
                        class="h-100 scrollable">
            </ace-navbar>
        </div>
        <div class="sidenav-footer">
            <ace-footer [narrow]="true"
                        [portalTitle]="branding.footerPortalTitle"
                        [portalHref]="branding.footerPortalHref"
                        [text]="branding.footerText"
                        (actionTriggered)="handleActionTriggered($any($event))">
            </ace-footer>
        </div>
    </div>
</div>
