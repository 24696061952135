(function () {
	'use strict';

	var VIEW_TEMPLATE_URL = 'app/Permission/Instance/_panel.html';
	var CONTROLLER_NAME = 'sd.Instance.Permission.Panel.Controller';

	angular
		.module('weissmanApp')
		.directive('sdInstancePermissionPanel', sdInstancePermissionPanel);

	sdInstancePermissionPanel.$inject = [];

	function sdInstancePermissionPanel() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			templateUrl: VIEW_TEMPLATE_URL,
			controller: CONTROLLER_NAME,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			scope: {
				editMode: '=',
				assignedPermissions: '=',
				userSetup: '=',
				availablePermissions:"=",
				permissionsLookup:"=",
				instanceId: '=',
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}
})();
