import { Injectable } from "@angular/core";
import { SearchFilters } from "../../../Search/Advanced/advancedSearchPersistence.service";

// import methods from fp library to utilize flow transducer
import map from "lodash/fp/map";
import reject from "lodash/fp/reject";
import * as _ from 'lodash';

@Injectable(
    { providedIn: 'root' }
)
export class ColumnFilterService {

    prepareFilters(filters: SearchFilters[]) {
        return _.flow([
            map(x => ({ ...x, ...{ columnId: x.columnId || x.id || x.advancedSearchFieldID }})),
            reject(x => x.columnId === 9002 || x.columnId === 1430),
            map(x => {
                const filter = { columnId: x.columnId, or: x.or };
                filter.or = _.flow([
                        reject(or => or.operator.name !== 'blank' && or.operator.name !== 'notBlank' && or.operator.name !== 'isNA' && (!_.isDate(or.value) && (!or.value || !or.value.trim()))),
                        map(or => ({ ...or, ...{ operator: or.operator.name }}))
                    ])(filter.or);

                return filter;
            }),
            reject(x => !x.or.length)
        ])(filters);
    }
}
