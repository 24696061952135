(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('taskAssignmentReadyPanel', taskAssignmentReadyPanel);

	taskAssignmentReadyPanel.$inject = [];

	function taskAssignmentReadyPanel() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			templateUrl: 'app/Task/ActionView/Filters/_taskAssignmentReadyPanel.html',
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			scope: {
				masterFilter: '=',
				filterColumns: '=',
				api: '=',
				taskTypes: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	Controller.$inject = [
		'actionViewService',
		'searchOperators',
		'SD.Restrict.Service',
		'$scope',
		'advancedSearchService',
		'sd.Account.User.Settings.Service',
		'sd.Account.User.Service',
		'actionViewPersistenceService',
		'sd.Authentication.Service',
		'InstanceRights',
		'userInstanceService',
        'TaskTypes',
        'productAnalyticsService'
	]

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(
		actionViewService,
		searchOperators,
		restrictService,
		$scope,
		advancedSearchService,
		userSettingsService,
		userService,
		actionViewPersistenceService,
		authenticationService,
		InstanceRights,
		userInstanceService,
        TaskTypes,
        productAnalyticsService) {

		var vm = this;
		var taskTypeColumns = [];
		var allFields;
		var customTaskTypes = [];
		var resolveTasksAndFieldsDeferred;

		vm.taskTypes = [];
		_.assign(vm, actionViewService.getTasksAssignmentReadyOptions());

		vm.taskTypeChanged = taskTypeChanged;

		vm.api = {
			populateColumns: taskTypeChanged,
			setOutputs: setOutputs,
			refreshData: activate,
			checkActionViewDefaultOutput: checkActionViewDefaultOutput,
			resolveDefaultOutput: resolveDefaultOutput,
            prepareCriteriaFromLoad: prepareCriteriaFromLoad,
            taskTypeChanged: taskTypeChanged
		};

		activate();

		function activate() {
            //Dima: this call is to ensure that the user has permissions to execute the action view.
            // See issue [WK-3318 'No permission to view this page' warning is only displayed on 2nd try] for additional details
            /*var actionViewId = parseInt($stateParams.actionViewId);
            if (actionViewId){
                actionViewService.getCustomActionViewById(actionViewId);
			}*/

			var userInstances = userInstanceService.getUserInstanceMembership();
			vm.hasPermission = _.some(userInstances, x => restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, x.instanceId));

			if (!vm.hasPermission) {
				authenticationService.getUserInfo()
					.then(function(userData) {
						vm.userInfo = userData;

						if(!vm.userInfo.isTeamMemberAndAssignable) {
							vm.masterFilter.ShowChoice = vm.showOptions['allTasks'].id
							vm.masterFilter.WhereChoice = undefined;
						}
					});
			}

            resolveTasksAndFieldsDeferred = actionViewService.getTaskTypes()
				.then(function (taskTypeResult) {
					console.log('taskTypeResult', taskTypeResult);

                    if (userInstanceService.isSingleInstanceSelected() &&
                        !(userInstanceService.isCurrentInstanceRyan() || userInstanceService.isCurrentInstanceSystem())) {
                        const invoiceAppealSavingsTaskIndex = taskTypeResult.taskTypes.indexOf(x => x.taskTypeID === TaskTypes.INVOICEAPPEALSAVINGS);
                        taskTypeResult.taskTypes.splice(invoiceAppealSavingsTaskIndex, 1);
                    }

					taskTypeColumns = taskTypeResult.columns;

					return advancedSearchService.getAllFields()
						.then(function (fieldsResult) {
							allFields = fieldsResult;

							var userSetting = userSettingsService.getSettingsByGroup(12)[0];

							if (userSetting) {
								customTaskTypes = angular.fromJson(userSetting.value);
							}

							vm.taskTypes = actionViewService.sortTaskTypes(taskTypeResult.taskTypes);

							//Temporary fix, removing ID 1 aka PP Compliance, since there's no viabel tasks
							//vm.taskTypes = _.reject(vm.taskTypes, { 'taskSeriesTypeID': 1});

							if (vm.masterFilter.taskTypeID !== undefined && _.isEmpty(actionViewPersistenceService.masterFilter)) {
								prepareCriteriaFromLoad(vm.filterColumns);

								var taskType = _.find(vm.taskTypes, function (taskTypeItem) {
									return (taskTypeItem.taskTypeID === vm.masterFilter.taskTypeID) && (taskTypeItem.taskSeriesTypeID === vm.masterFilter.taskSeriesTypeID);
								});

								vm.taskType = taskType || vm.taskTypes[0];
							} else if (_.isEmpty(actionViewPersistenceService.masterFilter)) {
								vm.taskType = vm.taskTypes[0];

								taskTypeChanged();
							} else {
								var taskType = _.find(vm.taskTypes, function (taskTypeItem) {
									return (taskTypeItem.taskTypeID === vm.masterFilter.taskTypeID) && (taskTypeItem.taskSeriesTypeID === vm.masterFilter.taskSeriesTypeID);
								});

								vm.taskType = taskType || vm.taskTypes[0];
							}
						})

				});

            //Assigning this to the API so we can drilldown outside the normal flow of the page, e.g. drilldown from dashboard
            vm.api.resolveTasksAndFieldsDeferred = resolveTasksAndFieldsDeferred;
		}

		vm.showOptionChanged = function () {
			if (vm.masterFilter.ShowChoice === vm.showOptions.allTasks.id) {
				vm.masterFilter.WhereChoice = undefined
			} else {
				vm.masterFilter.WhereChoice = vm.masterFilter.WhereChoice || 1;
			}
            productAnalyticsService.logEvent('click-action-view-task-option', { taskAssignment: Object.values(vm.showOptions).find(x => x.id === vm.masterFilter.ShowChoice)?.display });
		}

        vm.whereOptionChanged = function () {
            productAnalyticsService.logEvent('click-action-view-task-assignment-type', {
                taskAssignmentType: vm.whereOptions.find(x => x.id === vm.masterFilter.WhereChoice)?.display
            });
        }

		vm.thatAreChanged = function() {
			if(vm.masterFilter.ThatAreChoice !== 4) {
				vm.masterFilter.CompletedDateFrom = null;
				vm.masterFilter.CompletedDateTo = null;
			}
            productAnalyticsService.logEvent('click-action-view-task-status', {
                taskAssignmentStatus: vm.thatAreOptions.find(x => x.id === vm.masterFilter.ThatAreChoice)?.display
            });
		}

		vm.canViewWhere = function (option) {
			if (option.id === 5) {
				return vm.taskType && (vm.taskType.taskSeriesTypeID === 1 || vm.taskType.taskSeriesTypeID === 13);
			}

			return vm.hasPermission || option.id === 1;
		}

		vm.canViewInclude = function (option) {
			if (vm.hasPermission) {
				return true;
			}

			return option.id === 1 || option.id === 2;
		}


		$scope.$watch('vm.masterFilter.ThatAreChoice', function () {
			if (vm.masterFilter.ThatAreChoice !== 3) {
				vm.masterFilter.CalculateReadyAndNotReady = false;
			}
		}, true);

		function taskTypeChanged() {
		    var taskTypeIdChanged = vm.masterFilter.taskTypeID !== vm.taskType.taskTypeID;
            vm.masterFilter.taskTypeID = vm.taskType.taskTypeID;

			if(vm.masterFilter.taskSeriesTypeID !== vm.taskType.taskSeriesTypeID || vm.masterFilter.output === 'default') {
			    var oldFilter = angular.copy(vm.filterColumns);

				vm.masterFilter.output='default';
				vm.masterFilter.taskSeriesTypeID = vm.taskType.taskSeriesTypeID;
				_.assign(vm.masterFilter, setOutputs(vm.masterFilter.taskSeriesTypeID, vm.masterFilter.taskTypeID));
				vm.filterColumns = angular.copy(vm.masterFilter.EntityOutputColumns);
                vm.filterColumns = _.filter(vm.filterColumns, function (column) {
                    var columnId = column.columnId || column.advancedSearchFieldID || column.id;
                    // We need to remove the document image preview column because it can't be filtered on
                    return columnId !== 9002 && columnId !== 1430;
                });


                //If old filter value exists, we want to persist it if we can
				vm.filterColumns = _.map(vm.filterColumns, function(newFilter) {
				    var matchingFilter = _.find(oldFilter, {columnId: newFilter.columnId});

				    if(matchingFilter) {
				        newFilter.or = matchingFilter.or;
                    }

                    return newFilter;
                });
			} else if (taskTypeIdChanged){
                _.assign(vm.masterFilter, setOutputs(vm.masterFilter.taskSeriesTypeID, vm.masterFilter.taskTypeID));
            }
		}

        vm.logTaskTypeEvent = function() {
            productAnalyticsService.logEvent('select-action-view-task-type', {
                actionViewTask: vm.taskTypes.find(x => x.taskTypeID === vm.masterFilter.taskTypeID && x.taskSeriesTypeID === vm.masterFilter.taskSeriesTypeID)?.name
            });
        }

        function loadColumns(taskSeriesTypeID, taskTypeID) {
            var currentTaskType = _.find(vm.taskTypes, function (taskType) {
                return taskType.taskSeriesTypeID === taskSeriesTypeID && taskType.taskTypeID === taskTypeID;
            });

            var customTaskType = _.find(customTaskTypes, function (taskType) {
                return taskType.taskSeriesTypeID === currentTaskType?.taskSeriesTypeID && taskType.taskTypeID === currentTaskType?.taskTypeID;
            });

			var separatedColumns = customTaskType ? prepCustom(customTaskType.columns) : prepSystem(currentTaskType?.columnIds);
			separatedColumns.EntityOutputColumns = _.map(separatedColumns.EntityOutputColumns, col => {
				col.fromTaskType = true;
				return col;
			})

            separatedColumns.taskTypeName = currentTaskType?.name;
            return separatedColumns;
        }

        function setOutputs(taskSeriesTypeID, taskTypeID) {
		    if(vm.taskTypes) {
                return loadColumns(taskSeriesTypeID, taskTypeID);
            } else {
                resolveTasksAndFieldsDeferred.then(function() {
                    return loadColumns(taskSeriesTypeID, taskTypeID);
                })
            }
		}

		function prepCustom(columns) {
			var separatedColumns = _.chain(angular.copy(columns))
				.map(columnFromAllFields)
				.groupBy(columnType)
				.value();

			separatedColumns.CustomOutput = true;

			return separatedColumns;
		}

		function columnFromAllFields(column) {
			var columnId = column.columnId || column.advancedSearchFieldID || column.id;

			var foundColumn = _.find(allFields, function (field) {
				return field.advancedSearchFieldID === columnId;
			});

			var newColumn = angular.copy(foundColumn);

			newColumn.resultColumnName = newColumn.resultsColumnName;
			newColumn.operators = searchOperators.getOperatorArray(newColumn.operators);

			if(newColumn.advancedSearchFieldID === 101 || newColumn.advancedSearchFieldID === 109) {
				newColumn.or = [searchOperators.getCompanyOr(newColumn.operators)];
			} else {
				newColumn.or = [searchOperators.getOr(newColumn.operators)];
			}

			newColumn.sequence = column.sequence;

			return newColumn;
		}

		function prepSystem(ids) {
			var separatedColumns = _.chain(ids)
				.map(function (id, index) {
					var column = angular.copy(_.find(taskTypeColumns, { columnId: id }));

					column.operators = searchOperators.getOperatorArray(column.operators)

                    if(id === 101 || id === 109) {
                        column.or = [searchOperators.getCompanyOr(column.operators)];
                    } else {
                        column.or = [searchOperators.getOr(column.operators)];
                    }
					column.sequence = index;

					return column;
				})
				.groupBy(columnType)
				.value();

			separatedColumns.CustomOutput = false;

			return separatedColumns;
		}

		function columnType(column) {
			var columnId = column.columnId || column.advancedSearchFieldID || column.id;

			if (columnId > 9999 && columnId < 11000) {
				return 'TaskOutputColumns';
			} else {
				return 'EntityOutputColumns'
			}
		}

		function prepareCriteriaFromLoad(criteria) {
			criteria = angular.fromJson(criteria);

			vm.filterColumns = _.map(criteria, function (filter) {
				const column = angular.copy(_.find(allFields, { advancedSearchFieldID: (filter.columnId || filter.advancedSearchFieldID || filter.id) }));
				column.operators = searchOperators.getOperatorArray(column.operators);
				column.or = _.map(filter.or, function (or) {
					return {
						value: or.value,
						operator: _.find(column.operators, { name: or.operator?.name || or.operator })
					}
				});

				return column;
			});

			var keys = ['ShowUserTeams', 'ShowTeams', 'IncludeUserTeams', 'IncludeTeams'];

			angular.forEach(keys, function (key) {
				vm.masterFilter[key] = vm.masterFilter[key] || [{}];
			})

			if (vm.masterFilter.EntityOutputColumns[0].sequence === undefined) {
				vm.masterFilter.EntityOutputColumns = angular.copy(vm.filterColumns);
			}
			vm.masterFilter.TaskOutputColumns = _.map(vm.masterFilter.TaskOutputColumns, columnFromAllFields)
		}

		function fuzzyFieldCompare(field1, field2) {
		    var id1 = field1.columnId || field1.advancedSearchFieldID || field1.id;
		    var id2 = field2.columnId || field2.advancedSearchFieldID || field2.id;
		    return id1 === id2;
		}

		function checkActionViewDefaultOutput(filterColumnUpdate) {
		    /*if(filterColumnUpdate) {
                vm.filterColumns = filterColumnUpdate;
            }*/

		    return resolveTasksAndFieldsDeferred.then(function () {
		        if (vm.masterFilter.output === "default") {
		            var defaultMasterFilter = setOutputs(vm.masterFilter.taskSeriesTypeID, vm.masterFilter.taskTypeID);

		            var missingEntityOutputColumns = _.filter(defaultMasterFilter.EntityOutputColumns, function (defaultEntityOutputColumn) {
		                return !_.some(vm.masterFilter.EntityOutputColumns, function (currentOutputColumn) {
		                    return fuzzyFieldCompare(currentOutputColumn, defaultEntityOutputColumn);
		                });
		            });

		            _.forEach(missingEntityOutputColumns, function (missingOutputColumn) {
		                console.log(["Adding missing column", _.cloneDeep(missingOutputColumn)]);
		                // WK-5548 - There's at least one circumstance where id is needed and all we have is advancedSearchFieldID
		                // TODO: Refactor the AV nightmare
		                if (missingOutputColumn.advancedSearchFieldID && !missingOutputColumn.id) {
		                    missingOutputColumn.id = missingOutputColumn.advancedSearchFieldID;
		                }

		                vm.masterFilter.EntityOutputColumns.push(missingOutputColumn);
		                vm.filterColumns.push(missingOutputColumn);
		            });

		            var extraEntityOutputColumns = _.filter(vm.masterFilter.EntityOutputColumns, function (currentOutputColumn) {
		                return !_.some(defaultMasterFilter.EntityOutputColumns, function (defaultEntityOutputColumn) {
		                    return fuzzyFieldCompare(defaultEntityOutputColumn, currentOutputColumn);
		                });
		            });

		            _.forEach(extraEntityOutputColumns, function (extraEntityOutputColumn) {
		                // Check for a filter on this extra property before removing it
		                var hasNonEmptyExistingFilter = false;
		                var existingFilters = _.filter(vm.filterColumns, function (filterColumn) {
		                    return fuzzyFieldCompare(filterColumn, extraEntityOutputColumn);
		                });
		                if (existingFilters.length > 0) {
		                    // (don't worry if there's more than one; it shouldn't happen, and if it does other things more important than this will break)
                            var existingFilter = existingFilters[0]
                            var blankFilterForThisColumn = columnFromAllFields(extraEntityOutputColumn);
                            if (!_.isEqual(blankFilterForThisColumn.or, existingFilter.or)) {
                                console.log(["An extra column exists but has a filter", _.cloneDeep(existingFilter)]);
                                hasNonEmptyExistingFilter = true;
                            }
                            if (!hasNonEmptyExistingFilter) {
                                console.log(["Removing extra column", _.cloneDeep(extraEntityOutputColumn)]);
                                _.remove(vm.masterFilter.EntityOutputColumns, function (entityOutputColumn) {
                                    return fuzzyFieldCompare(entityOutputColumn, extraEntityOutputColumn);
                                });
                                _.remove(vm.filterColumns, function (filterColumn) {
                                    return fuzzyFieldCompare(filterColumn, extraEntityOutputColumn);
                                });
                            }
		                }
		            });

		            if (missingEntityOutputColumns.length > 0 || extraEntityOutputColumns.length > 0) {
		                vm.masterFilter.CustomOutput = defaultMasterFilter.CustomOutput;
		            }
		        }

                vm.filterColumns = _.filter(vm.filterColumns, function (column) {
                    var columnId = column.columnId || column.advancedSearchFieldID || column.id;
                    // We need to remove the document image preview column because it can't be filtered on
                    return columnId !== 9002 && columnId !== 1430;
                });

		        return {
		            masterFilter: vm.masterFilter,
		            filterColumns: vm.filterColumns
		        };
            });
		}

		function resolveDefaultOutput(searchFilter) {
		    return resolveTasksAndFieldsDeferred.then(function () {
		        if (searchFilter.output === "default") {
		            var defaultMasterFilter = setOutputs(searchFilter.taskSeriesTypeID, searchFilter.taskTypeID);

		            var missingEntityOutputColumns = _.filter(defaultMasterFilter.EntityOutputColumns, function (defaultEntityOutputColumn) {
		                return !_.some(searchFilter.EntityOutputColumns, function (currentOutputColumn) {
		                    return fuzzyFieldCompare(currentOutputColumn, defaultEntityOutputColumn);
		                });
		            });

		            _.forEach(missingEntityOutputColumns, function (missingOutputColumn) {
		                console.log(["Adding missing column to saved search", _.cloneDeep(missingOutputColumn)]);
		                searchFilter.EntityOutputColumns.push({
		                    id: missingOutputColumn.advancedSearchFieldID,
		                    sequence: missingOutputColumn.sequence,
		                    displayName: missingOutputColumn.displayName,
		                    depthValue: missingOutputColumn.depthValue,
                            resultColumnName: missingOutputColumn.resultColumnName
		                });
		            });

		            var extraEntityOutputColumns = _.filter(searchFilter.EntityOutputColumns, function (currentOutputColumn) {
		                return !_.some(defaultMasterFilter.EntityOutputColumns, function (defaultEntityOutputColumn) {
		                    return fuzzyFieldCompare(defaultEntityOutputColumn, currentOutputColumn);
		                });
		            });

		            _.forEach(extraEntityOutputColumns, function (extraEntityOutputColumn) {
		                // Check for a filter on this extra property before removing it
		                var existingFilter = _.filter(searchFilter.filters, function (filter) {
		                    return fuzzyFieldCompare(filter, extraEntityOutputColumn);
		                });

		                if (existingFilter.length > 0) {
                            console.log(["An extra column exists but has a filter for this saved search", _.cloneDeep(existingFilter)]);
		                }
		                else {
                            console.log(["Removing extra column for this saved search", _.cloneDeep(extraEntityOutputColumn)]);
                            _.remove(searchFilter.EntityOutputColumns, function (entityOutputColumn) {
                                return fuzzyFieldCompare(entityOutputColumn, extraEntityOutputColumn);
                            });
		                }
		            });

		            if (missingEntityOutputColumns.length > 0) {
		                searchFilter.CustomOutput = defaultMasterFilter.CustomOutput;
		            }
		        }

		        return searchFilter;
		    });
		}
	}
})();
