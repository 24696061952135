<ws-busy-indicator *ngIf="serverAction"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Add Site" [disableSave]="addSiteForm.invalid" (save)="save()" (cancel)="cancel()" [suppressKeyboardEvents]="true">
    <form [formGroup]="addSiteForm">
        <div class="mb-4" [class.has-error]="addSiteForm.controls.name.invalid && addSiteForm.controls.name.touched">
            <label>Site Name</label>
            <input #siteName type="text" formControlName="name" maxlength="100" class="form-control" required>
        </div>
        <div class="mb-4" [class.has-error]="addSiteForm.controls.siteNumber.invalid && addSiteForm.controls.siteNumber.touched">
            <label>Site Number</label>
            <input type="text" formControlName="siteNumber" maxlength="100" class="form-control">
        </div>
        <div class="mb-4">
            <label>State:</label>
            <state-dropdown formControlName="stateID" [abbrOnly]="false"></state-dropdown>
        </div>
        <div class="mb-4">
            <label>Site Class:</label>
            <select class="form-select form-select-sm" formControlName="siteClassSecondaryID">
                <option *ngFor="let option of siteClasses" [ngValue]="option.siteClassSecondaryID">{{option.fullName}}</option>
            </select>
        </div>
    </form>
</ws-modal-wrapper>
