
<ng-template #scheduleSelectorTemplate let-model="item" let-index="index">
    <span *ngIf="model.extraPath" class="scheduleSelectorPath">{{ model.extraPath }}</span>{{ model.name }} {{ model.description?.length ? '(' + model.description + ')' : '' }} <span *ngIf="model.depreciationTableId">{{ model.depreciationTableName}} ({{ model.depreciationTableLife }} year life)</span>
</ng-template>

<div class="input-group" aria-label="Select Schedule">
    <input id="selectSchedule"
        type="text"
        placeholder="Filter Schedules..."
        class="form-control exclude-modal-styling"
        [ngClass]="{ 'overridden': isOverridden }"
        autocomplete="off"
        (blur)="onScheduleBlur()"
        [(ngModel)]="scheduleFilter"
        [typeahead]="schedules$"
        typeaheadOptionField="name"
        container="body"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        (typeaheadOnSelect)="onScheduleSelected($event)"
        [typeaheadOptionsLimit]="1000"
        [typeaheadItemTemplate]="scheduleSelectorTemplate"
        [disabled]="isDisabled || !isInitialized"/>
</div>
