import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnService } from '../return.service';
import { IReturnPartComponent, ReturnPartServiceBase } from '../Models/returnPartServiceBase';
import { ReturnFormRevisionListItem, ReturnUpdateLogicService } from '../returnUpdateLogic.service';

@Component({
    selector: 'return-form-selector',
    templateUrl: './returnFormSelector.component.html'
})
export class ReturnFormSelectorComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor (private readonly _returnService: ReturnService,
                 private readonly _returnUpdateLogicService: ReturnUpdateLogicService) { }

    @Input() returnPartService: ReturnPartServiceBase;
    @Input() allowPrimaryForReturn: boolean;

    returnFormRevisions: ReturnFormRevisionListItem[] = [];
    selectedReturnFormRevision: ReturnFormRevisionListItem = null;

    private _returnsSub: Subscription;

    ngOnInit(): void {
        this.returnPartService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this.returnPartService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnsSub = this._returnUpdateLogicService.returnFormRevisions$.subscribe((returnFormRevisions) => {
            if (returnFormRevisions) {
                this._refreshReturnFormRevisions(returnFormRevisions);
            }
        });
    }

    onReturnPartServiceDeactivated(): void {
        this._returnsSub && this._returnsSub.unsubscribe();
    }

    async onSelectedReturnFormRevisionChange(returnFormRevision: Compliance.ReturnFormRevisionModel): Promise<void> {
        this.selectedReturnFormRevision = returnFormRevision;
        this._returnService.setFormRevisionId(this.selectedReturnFormRevision && this.selectedReturnFormRevision.formRevisionId);
        await this._returnUpdateLogicService.returnFormRevisionChanged();
    }

    private _refreshReturnFormRevisions(formRevisions: ReturnFormRevisionListItem[]) {
        this.returnFormRevisions = formRevisions || [];

        // If there is only one form revision, select it
        if (!this._returnService.sharedState.formRevisionId && this._returnService.getUniqueAssociatedReturnFormRevisions().length === 1) {
            this._returnService.setFirstFormRevisionId(true);
        }

        // auto-select the form revision if one is selected
        this.selectedReturnFormRevision = this.returnFormRevisions.find(x => x.formRevisionId === this._returnService.sharedState.formRevisionId) || null;

        this._returnService.setFormRevisionId(
            this.selectedReturnFormRevision
            ? this.selectedReturnFormRevision.formRevisionId
            : null);
    }
}
