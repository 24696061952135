import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { ReportManagerService } from '../../../../Reporting/Manager/report.manager.service';
import { UserSaveService } from '../../userSave.service';

export interface reportRenameModalParams {
    report: Core.ReportListDTO;
    reportType: Core.SearchCategoryType;
}

@Component({
    selector: 'report-rename',
    templateUrl: './reportRename.component.html'
})
export class ReportRenameComponent implements IWeissmanModalComponent<reportRenameModalParams, void>, OnInit {
    constructor(private readonly _reportManagerService: ReportManagerService,
                private readonly _userSaveService: UserSaveService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: reportRenameModalParams;
    result: void;

    modalTitle: string;
    report: Core.ReportListDTO;
    reportType: Core.SearchCategoryType;

    ngOnInit(): void {
        this.report = this.params.report;
        this.reportType = this.params.reportType;
        const variant = this.report.variantName ? ` - ${this.report.variantName}` : '';
        this.modalTitle = `Rename "${this.report.reportName}${variant}" report`;
    }

    async save() {
        await this._reportManagerService.patchReport(this.report, this.reportType);
        await this._userSaveService.refreshCurrentList();
        this.cancel();
    }

    cancel() {
        this._bsModalRef.hide();
    }
}
