<ws-busy-indicator *ngIf="isBusy" [zIndexOverride]="1039"></ws-busy-indicator>
<table *ngIf="data" class="table table-striped table-condensed table-borderless-body">
    <thead>
        <tr>
            <th width="20%"></th>
            <th width="20%" class="ws-text-align-right">Total</th>
            <th width="20%" class="ws-text-align-right">Reported</th>
            <th width="20%" class="ws-text-align-right">Not Reported</th>
            <th width="20%" class="ws-text-align-right">Not Assigned</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="data.existingOverridesTrueUp.cost > 0 || data.existingDeductionsTrueUp.cost > 0">
            <td>Prior Return Cost Forward</td>
            <td class="ws-text-align-right">{{ data.priorReturnCostForward.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturnCostForward.reportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturnCostForward.nonReportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturnCostForward.unassignedCost | currency: 'USD' : 'symbol' }}</td>
        </tr>
        <tr *ngIf="data.existingOverridesTrueUp.cost > 0">
            <td>Existing Overrides True Up</td>
            <td class="ws-text-align-right">{{ data.existingOverridesTrueUp.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingOverridesTrueUp.reportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingOverridesTrueUp.nonReportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingOverridesTrueUp.unassignedCost | currency: 'USD' : 'symbol' }}</td>
        </tr>
        <tr *ngIf="data.existingDeductionsTrueUp.cost > 0">
            <td>Existing Deductions True Up</td>
            <td class="ws-text-align-right">{{ data.existingDeductionsTrueUp.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingDeductionsTrueUp.reportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingDeductionsTrueUp.nonReportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.existingDeductionsTrueUp.unassignedCost | currency: 'USD' : 'symbol' }}</td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Prior Return Cost<span *ngIf="data.existingOverridesTrueUp.cost > 0 || data.existingDeductionsTrueUp.cost > 0"> Plus True Ups</span></td>
            <td class="ws-text-align-right">{{ data.priorReturn.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturn.reportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturn.nonReportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.priorReturn.unassignedCost | currency: 'USD' : 'symbol' }}</td>
        </tr>
        <tr>
            <td colspan="4">&nbsp;</td>
        </tr>
        <tr>
            <td>Acquisitions</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, false, true, false, false, false)">{{ data.additions.acquisitions.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, true, true, false, false, false)">{{ data.additions.acquisitions.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(true, false, true, false, false, false)">{{ data.additions.acquisitions.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAssetsAssignedToNoSchedule(true, false, false)">{{ data.additions.acquisitions.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Transfers In</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, false, false, true, false, false)">{{ data.additions.transferIns.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, true, false, true, false, false)">{{ data.additions.transferIns.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(true, false, false, true, false, false)">{{ data.additions.transferIns.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAssetsAssignedToNoSchedule(false, true, false)">{{ data.additions.transferIns.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Cost Increases</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, false, false, false, true, false)">{{ data.additions.costChanges.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, true, false, false, true, false)">{{ data.additions.costChanges.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(true, false, false, false, true, false)">{{ data.additions.costChanges.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAssetsAssignedToNoSchedule(false, false, true)">{{ data.additions.costChanges.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Changed to Reported</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, false, false, false, false, true)">{{ data.additions.reportabilityChanges.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAdditions(false, true, false, false, false, true)">{{ data.additions.reportabilityChanges.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <span class="ws-text-align-center">-</span>
            </td>
            <td class="ws-text-align-right">
                <span class="ws-text-align-center">-</span>
            </td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Total Additions <i class="fa fa-info-circle small" title="Total may not equal the sum of the above because an asset may fall under multiple categories and this total only counts each asset once as an addition."></i></td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAdditions(false, false, true, true, true, true)">{{ data.additions.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAdditions(false, true, true, true, true, true)">{{ data.additions.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAdditions(true, false, true, true, true, true)">{{ data.additions.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAssetsAssignedToNoSchedule(true, true, true)">{{ data.additions.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td colspan="4">&nbsp;</td>
        </tr>
        <tr>
            <td>Disposals</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Disposals', true, true, true, false, false, false)">{{ data.deductions.disposals.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Disposals (Reported)', true, false, true, false, false, false)">{{ data.deductions.disposals.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Disposals (Not Reported)', false, true, true, false, false, false)">{{ data.deductions.disposals.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Disposals (Not Assigned)', false, false, true, false, false, false)">{{ data.deductions.disposals.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Transfers Out</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Transfers Out', true, true, false, true, false, false)">{{ data.deductions.transferOuts.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Transfers Out (Reported)', true, false, false, true, false, false)">{{ data.deductions.transferOuts.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Transfers Out (Not Reported)', false, true, false, true, false, false)">{{ data.deductions.transferOuts.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Transfers Out (Not Assigned)', false, false, false, true, false, false)">{{ data.deductions.transferOuts.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Cost Decreases</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Cost Decreases', true, true, false, false, true, false)">{{ data.deductions.costChanges.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Cost Decreases (Reported)', true, false, false, false, true, false)">{{ data.deductions.costChanges.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Cost Decreases (Not Reported)', false, true, false, false, true, false)">{{ data.deductions.costChanges.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Cost Decreases (Not Assigned)', false, false, false, false, true, false)">{{ data.deductions.costChanges.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr>
            <td>Changed to Not Reported</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Changed to Not Reported', false, true, false, false, false, true)">{{ data.deductions.reportabilityChanges.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <span class="ws-text-align-center">-</span>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToDeductions('Changed to Not Reported', false, true, false, false, false, true)">{{ data.deductions.reportabilityChanges.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <span class="ws-text-align-center">-</span>
            </td>
        </tr>
        <tr *ngIf="data.deductions.suppressedAssets.cost > 0">
            <td>Suppressed Assets</td>
            <td class="ws-text-align-right">
                <button [disabled]="true" class="btn btn-link ws-no-padding ws-vertical-align-top">{{ data.deductions.suppressedAssets.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="true" class="btn btn-link ws-no-padding ws-vertical-align-top">{{ data.deductions.suppressedAssets.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="true" class="btn btn-link ws-no-padding ws-vertical-align-top">{{ data.deductions.suppressedAssets.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="true" class="btn btn-link ws-no-padding ws-vertical-align-top">{{ data.deductions.suppressedAssets.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Total Deductions <i class="fa fa-info-circle small" title="Total may not equal the sum of the above because an asset may fall under multiple categories and this total only counts each asset once as a deduction."></i></td>
            <td class="ws-text-align-right">{{ data.deductions.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.deductions.reportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.deductions.nonReportableCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.deductions.unassignedCost | currency: 'USD' : 'symbol' }}</td>
        </tr>
        <tr>
            <td colspan="4">&nbsp;</td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Current Return Cost</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAssets()">{{ data.currentReturn.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAssetsAssignedToReportableSchedule()">{{ data.currentReturn.reportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAssetsAssignedToNonReportableSchedule()">{{ data.currentReturn.nonReportableCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToAssetsAssignedToNoSchedule()">{{ data.currentReturn.unassignedCost | currency: 'USD' : 'symbol' }}</button>
            </td>
        </tr>
        <tr *ngIf="data.notAssignedToAScheduleCost">
            <td>Not Assigned to a Schedule</td>
            <td class="ws-text-align-right">
                <button class="btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToAssetsAssignedToNoSchedule()">{{ data.notAssignedToAScheduleCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td colspan="4">&nbsp;</td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Prior Rendered Value</td>
            <td class="ws-text-align-right">{{ data.priorReportedValue | currency: 'USD' : 'symbol' }}</td>
            <td></td>
            <td></td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Current Rendered Value</td>
            <td class="ws-text-align-right">{{ data.currentReportedValue | currency: 'USD' : 'symbol' }}</td>
            <td></td>
            <td></td>
        </tr>
        <tr class="ws-font-weight-bold" *ngIf="showEstimatedFMV">
            <td>Est. FMV</td>
            <td class="ws-text-align-right">{{ data.currentEstimatedFMV | currency: 'USD' : 'symbol' }}</td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
</table>
