import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ReturnService } from '../../../return.service';
import * as _ from 'lodash';
import { ReturnPreviewService } from '../returnPreview.service';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';
import { SnackBarService } from "../../../../../Busy-Indicator";

export interface ReturnPreviewFormDownloadParams {
    returnId: number;
    returnFormRevisions: Compliance.ReturnFormRevisionModel[];
    returnFormRevisionReports: Compliance.ReturnFormRevisionReportModel[]
}

@Component({
    selector: 'return-preview-form-download',
    templateUrl: './returnPreviewFormDownload.component.html'
})
export class ReturnPreviewFormDownloadComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ReturnPreviewFormDownloadParams, void> {
    constructor(
        private _bsModalRef: BsModalRef,
        private _returnService: ReturnService,
        private _returnPreviewService: ReturnPreviewService,
        private _snackBarService: SnackBarService
    ) {}

    params: ReturnPreviewFormDownloadParams;
    result: void;

    returnFormRevisions: Compliance.ReturnFormRevisionModel[] = [];
    selectedReturnFormRevision: Compliance.ReturnFormRevisionModel;
    returnFormRevisionReports: Compliance.ReturnFormRevisionReportModel[] = []
    selectedReturnFormRevisionReport: Compliance.ReturnFormRevisionReportModel;
    isBusy: boolean = false;

    ngOnInit(): void {
        this.returnFormRevisions = this.params.returnFormRevisions;
        this.selectedReturnFormRevision = this.returnFormRevisions[0];
        
        this._refreshReports();
    }

    ngOnDestroy(): void { }

    getReportName(report: Compliance.ReturnFormRevisionReportModel): string {
        return this._returnService.getReturnReportName(report);
    }

    getReturnFormRevisionDisplayName(returnFormRevision: Compliance.ReturnFormRevisionModel): string {
        return this._returnService.getReturnFormRevisionDisplayName(returnFormRevision);
    }

    async onSelectedReturnFormRevisionChange(): Promise<void> {
        await this._refreshReports();
    }

    async downloadForm(): Promise<void> {
        this.isBusy = true;

        try {
            const lrpId = await this._returnPreviewService.startDownloadForm(
                this.selectedReturnFormRevision.returnId,
                this.selectedReturnFormRevision.formRevisionId,
                null);
            this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.ReturnFormDownload);
        } finally {
            this.isBusy = false;
        }

        this._bsModalRef.hide();
    }

    async downloadReport(): Promise<void> {
        this.isBusy = true;

        try {
            const lrpId = await this._returnPreviewService.startDownloadReport(
                this.selectedReturnFormRevision.returnId,
                this.selectedReturnFormRevision.formRevisionId,
                this.selectedReturnFormRevisionReport.savedSearchId);
            this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.ReturnReportDownload);
        } finally {
            this.isBusy = false;
        }

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private async _refreshReports(): Promise<void> {
        const reports: Compliance.ReturnFormRevisionReportModel[] = [];
        if (this.selectedReturnFormRevision) {
            this.params.returnFormRevisionReports.forEach(x => {
                if (x.returnFormRevisionId === this.selectedReturnFormRevision.returnFormRevisionId) {
                    reports.push(x);
                }
            })
        }

        this.returnFormRevisionReports = reports;
        this.selectedReturnFormRevisionReport = this.returnFormRevisionReports[0];
    }
}
