import { NgModule } from '@angular/core';
import { AssetClassSelectorModule } from '../Compliance/Asset/Asset-Class-Selector/assetClassSelector.module';
import { TaxRateAreaComponent } from './Tax-Rate-Area/tax.rate.area.component';
import { TaxAuthorityComponent } from './Tax-Authority/tax.authority.component';
import { AddTaxEntityModalComponent } from '../Assessor-Collector/Tax-Rates/add.tax.entity.modal.component';
import { AddTaxYearModalComponent } from './Tax-Authority/add.tax.year.modal.component';
import { TaxRateCommandCenterComponent } from './Command-Center/tax.rate.command.center.component';
import { ShowTaxYearComponent } from './show.tax.year.component';
import { TaxAuthorityBulkUpdateComponent } from './Command-Center/Tax-Authority-Bulk-Update/tax.authority.bulk.update.component';
import { TaxAuthorityBulkUpdateActionComponent } from './Command-Center/Tax-Authority-Bulk-Update/tax.authority.bulk.update.action.component';
import { TaxRateCommandCenterCommentsCellRendererComponent } from './Command-Center/agGridCommentsCellRenderer.component';
import { TaxRateCommandCenterAttachmentsCellRendererComponent } from './Command-Center/agGridAttachmentsCellRenderer.component';
import { TaxRateQcByCellRendererComponent } from './agGridQcByCellRenderer.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';

@NgModule({
    imports: [
        WeissmanSharedModule,
        AssetClassSelectorModule
    ],
    declarations: [
        TaxRateAreaComponent,
        TaxAuthorityComponent,
        AddTaxEntityModalComponent,
        AddTaxYearModalComponent,
        TaxRateCommandCenterComponent,
        ShowTaxYearComponent,
        TaxAuthorityBulkUpdateComponent,
        TaxAuthorityBulkUpdateActionComponent,
        TaxRateCommandCenterCommentsCellRendererComponent,
        TaxRateCommandCenterAttachmentsCellRendererComponent,
        TaxRateQcByCellRendererComponent
    ],
    exports: [
        TaxRateAreaComponent,
        TaxAuthorityComponent,
        TaxRateCommandCenterComponent,
        ShowTaxYearComponent
    ]
})
export class TaxRateModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('taxRateArea', TaxRateAreaComponent);
        hybridAdapterUtility.downgradeNg2Component('taxAuthority', TaxAuthorityComponent);
        hybridAdapterUtility.downgradeNg2Component('taxRateCommandCenter', TaxRateCommandCenterComponent);
    }
}
