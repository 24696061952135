import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserRepository } from '../../Core-Repositories';

interface CachedPermission {
    endpoint: string;
    promise: Promise<Core.PermissionModel[]>;
}

@Injectable(
    {providedIn: 'root'}
)
export class EntityPermissionService {
    constructor(private readonly _userRepository: UserRepository) {}

    private _cachedPermissions: {[name: string]: CachedPermission} = {};

    registerEntityPermission(entityTypeID: number, entityID: number, apiEndpoint: string) {
        const cacheName = `${entityTypeID.toString()}:${entityID.toString()}`;
        const cache = this._cachedPermissions[cacheName];
        if (!cache) {
            this._cachedPermissions[cacheName] = { endpoint: apiEndpoint, promise: null };
        }
    }

    async getEntityPermission(entityTypeID: number, entityID: number, apiEndpoint: string): Promise<Core.PermissionModel[]> {
        const cacheName = `${entityTypeID.toString()}:${entityID.toString()}`;
        let cache = this._cachedPermissions[cacheName];

        if(!cache) {
            this.registerEntityPermission(entityTypeID, entityID, apiEndpoint);
            cache = this._cachedPermissions[cacheName];
        }

        if (cache.promise === null) {
            cache.promise = lastValueFrom(this._userRepository.getEntityPermission(cache.endpoint));
        }

        return await cache.promise;
    }
}
