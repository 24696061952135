import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const LONG_RUNNING_PROCESS_SYNTHETIC_MODAL_HELP: HelpContent[] = [
    {
        helpContentId: 'long-running-process-synthetic-modal.queue-type',
        tooltipText: 'The queue type to use which controls how many processes can run concurrently.',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-synthetic-modal.number-processes',
        tooltipText: 'The number of long-running synthetic processes to start.',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-synthetic-modal.duration',
        tooltipText: 'The number of milliseconds the long-running process will wait before completing (progress will be reported every second if applicable).',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-synthetic-modal.wait',
        tooltipText: 'The number of milliseconds to wait between executing each long-running process.',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-synthetic-modal.exception',
        tooltipText: 'When checked, the long-running process will throw an exception to simulate something going wrong.',
        hasIcon: true
    },
    {
        helpContentId: 'long-running-process-synthetic-modal.publish-progress',
        tooltipText: 'When checked, the process will publish progress change messages to the real-time messaging service to simulate work being completed.',
        hasIcon: true
    }
];
