import {GridApi, IGetRowsParams} from 'ag-grid-community';
import {EntityImportRepository} from '../../../../Repositories';
import {AgGridDataSourceBase, AgGridDataSourceResult} from '../../../../AgGrid';
import { lastValueFrom } from 'rxjs';

export interface EntityImportEditorDataSourceParams {
    importFileModel: Compliance.ImportFileModel;
    validationSummaryFilters: Compliance.ImportFileValidationErrorModel[];
    showTransferredRows: boolean;
    showValidRowsOnly: boolean;
    sortingColumns: Compliance.NameValuePair<string>[];
}

export class EntityImportEditorAgGridDataSource extends AgGridDataSourceBase<EntityImportEditorDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _entityImportRepository: EntityImportRepository,
        protected _dataSourceParamsFn: () => EntityImportEditorDataSourceParams,
        private _rowsLoaded:(totalRows: number) => void,
        private _setTotalValidCount:(totalValidCount: number) => void,
        private _totalsUpdateCallback: (totals: Compliance.ImportGridTotalRow, selectedTotals: boolean) => void,
        private _notProcessedUpdatesCallback: (updates: Compliance.ImportGridNotValidatedUpdateModel[], totalNotValidatedUpdates: number) => void
    ) {
        super(_gridApi);
        this.refreshDataSourceParams();
    }

    private _lastFilterUsedInTotals: string;
    lastFetchedTotals: Compliance.ImportGridTotalRow;

    gridParams: IGetRowsParams;

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this.gridParams = params;

        const fields = this._dataSourceParams.importFileModel.importContentType.importFields
            .filter(f => this._dataSourceParams.importFileModel.assignedFields.map(af => af.importFieldId).includes(f.importFieldId));

        const columnFilters = this.columnFilters(params);
        const searchModel = this.getSearchModelWithoutPagination(columnFilters, params);

        searchModel.pagination = {skip: params.startRow, take: params.endRow - params.startRow} as Core.PaginationModel;

        const searchParamsString = JSON.stringify(searchModel);
        const fetchTotals: boolean = this._dataSourceParams.importFileModel.importContentType.supportsTotals &&
            (!this.lastFetchedTotals || this._lastFilterUsedInTotals != searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchModel.includeTotals = fetchTotals;

        const result = await lastValueFrom(this._entityImportRepository.getImportRows(this._dataSourceParams.importFileModel.importFileId, searchModel));

        this._notProcessedUpdatesCallback(result.notValidatedUpdates, result.totalNotValidatedUpdates);

        const rows = result.data;
        const gridRows = rows.map((i) => {
            const flatRowObj = {
                validationStatus: {},
                originalValues: {},
                hasError: false,
                rowIndex: i.rowIndex
            } as Compliance.ImportGridRowGridModel;

            this._setupFields(fields, flatRowObj, i);

            i.validationErrors.forEach(ve => {
                const assignedField = this._dataSourceParams.importFileModel.assignedFields.find(af => af.importFileSpecificationFieldId === ve.importFileSpecificationFieldId);

                if (!ve.isWarning) {
                    flatRowObj.hasError = true;
                }

                if (assignedField) {
                    const field = fields.find(f => f.importFieldId === assignedField.importFieldId);
                    flatRowObj.validationStatus[field.displayName] = ve.isWarning
                        ? (flatRowObj.validationStatus[field.displayName] || 'cell-validation-warning')
                        : 'cell-validation-error';
                }
            });
            flatRowObj.actualAction = i.actualAction;
            flatRowObj.estimatedAction = i.estimatedAction;
            flatRowObj.rowIndex = i.rowIndex;
            return flatRowObj;
        });

        if (rows.length) {
            params.context = {}
        }

        if (!columnFilters.length) {
            this._setTotalValidCount(result.totalValidRows);
        }
        this._rowsLoaded(result.totalRows);

        if (fetchTotals) {
            this.lastFetchedTotals = result.totals;
            this._totalsUpdateCallback(this.lastFetchedTotals, false);
        }

        this._notProcessedUpdatesCallback(result.notValidatedUpdates, result.totalNotValidatedUpdates);

        return {
            rows: gridRows,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    private getSearchModelWithoutPagination(columnFilters: Core.FilterModel<string>[], params: IGetRowsParams) {
        return {
            validationErrors: this._dataSourceParams.validationSummaryFilters,
            columnFilters: columnFilters,
            showTransferredRows: this._dataSourceParams.showTransferredRows,
            showValidRowsOnly: this._dataSourceParams.showValidRowsOnly,
            estimatedActionFilter: this.estimatedActionFilter(params),
            sortColumns: this.getSortColumns(this._dataSourceParams.sortingColumns),
            includeTotals: false,
            includeOnlyTotals: false
        } as Compliance.ImportGridSearchModel;
    }

    async forceRefreshOfTotalValidRows(showTransferredRows: boolean, showValidRowsOnly: boolean) {
        const searchModel: Compliance.ImportGridSearchModel = {
            pagination: {skip: 0, take: 1} as Core.PaginationModel,
            validationErrors: [],
            columnFilters: [],
            showTransferredRows: showTransferredRows,
            showValidRowsOnly: showValidRowsOnly,
            estimatedActionFilter: null,
            sortColumns: [],
            includeTotals: false,
            includeOnlyTotals: false
        }
        const result = await lastValueFrom(this._entityImportRepository.getImportRows(this._dataSourceParams.importFileModel.importFileId, searchModel));

        this._setTotalValidCount(result.totalValidRows);
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const columnFilters = this.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams);
        const estimatedActionFilter = this.estimatedActionFilter({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams);
        const searchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            } as Core.PaginationModel,
            validationErrors: this._dataSourceParams.validationSummaryFilters,
            columnFilters: columnFilters,
            showTransferredRows: this._dataSourceParams.showTransferredRows,
            estimatedActionFilter: estimatedActionFilter,
            sortColumns: this.getSortColumns(this._dataSourceParams.sortingColumns),
            includeTotals: false,
            includeOnlyTotals: false
        } as Compliance.ImportGridSearchModel;

        return await lastValueFrom(this._entityImportRepository.getImportSelectedRowIds(this._dataSourceParams.importFileModel.importFileId, searchModel));
    }

    columnFilters(params: IGetRowsParams): Core.FilterModel<string>[] {
        const filters: Core.FilterModel<string>[] = [];
        if (params && params.filterModel) {
            for (const prop in params.filterModel) {
                // ignore any custom filter that have nothing to do with the import file columns
                if (params.filterModel.hasOwnProperty(prop) && ['estimatedAction'].indexOf(prop) === -1) {

                    // looking for the ID of the header of the import file that was imported
                    // if the column has been mapped the name will match the field name otherwise it will be the header name
                    const contentTypeField = this._dataSourceParams.importFileModel.importContentType.importFields.find(i => i.displayName === prop);

                    const assignedField = contentTypeField && this._dataSourceParams.importFileModel.assignedFields.find(i => i.importFieldId === contentTypeField.importFieldId);

                    const header = (contentTypeField && assignedField) ?
                        this._dataSourceParams.importFileModel.importFileHeaders.find(i => i.importFileHeaderId.toString() === assignedField.value) :
                        this._dataSourceParams.importFileModel.importFileHeaders.find(i => i.index.toString() === prop);

                    filters.push({
                        filterProperty: header.index.toString(),
                        filterConditionType: params.filterModel[prop] && params.filterModel[prop].filterConditionType,
                        filterValues: params.filterModel[prop] && params.filterModel[prop].filterValues.map(x => {
                            return {
                                filterType: this.getFilterType(x.filterType.displayKey),
                                filterValue: this.getFilterValue(x),
                                filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                            }
                        })
                    });
                }
            }
        }
        return filters;
    }

    estimatedActionFilter(params: IGetRowsParams): string {
        if (params && params.filterModel && params.filterModel['estimatedAction']) {
            return params.filterModel['estimatedAction'].filterValues[0].filterValue;
        }
        return null;
    }

    async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.ImportGridTotalRow> {
        const searchModel = this.getSearchModelWithoutPagination(this.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams), this.gridParams);
        searchModel.selectAllRows = selectedRowsModel.selectAllRows;
        searchModel.selectedRows = selectedRowsModel.selectedRows;
        searchModel.includeTotals = true;
        searchModel.includeOnlyTotals = true;

        const result = await lastValueFrom(this._entityImportRepository.getImportRows(this._dataSourceParams.importFileModel.importFileId, searchModel));
        return result.totals;
    }

    refresh(): void {
        this._lastFilterUsedInTotals = null;

        super.refresh();
    }

    private _setupFields(fields, rowObj, gridRow) {
        gridRow.fields.forEach(gr => {
            const importFieldHeader = this._dataSourceParams.importFileModel.importFileHeaders.find(ifh => gr.fieldIndex === ifh.index);
            const assignedField = this._dataSourceParams.importFileModel.assignedFields.find(af => af.value === `${importFieldHeader.importFileHeaderId}` && !af.isStatic);
            const field = assignedField && fields.find(f => f.importFieldId === assignedField.importFieldId);
            const key = field ? field.displayName : importFieldHeader.index;
            rowObj[key] = gr.currentValue === null ? gr.originalValue : gr.currentValue;
            rowObj.originalValues[key] = gr.originalValue;
        });

        this._dataSourceParams.importFileModel.assignedFields.forEach(af => {
            const field = fields.find(f => f.importFieldId === af.importFieldId);
            if (af.isStatic) {
                rowObj[field.displayName] = af.value;
            }
        });
    }
}
