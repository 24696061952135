import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    templateUrl: './leaseTypeSelector.component.html',
    selector: 'lease-type-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: LeaseTypeSelectorComponent,
        multi: true
    }]
})
export class LeaseTypeSelectorComponent implements ControlValueAccessor {

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() values: Compliance.LeaseTypeModel[] = [];
    @Input() isOverridden: boolean = false;

    @Output() changed = new EventEmitter<string>();

    value: string;
    disabled: boolean;
    filter: string = '';

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(leaseType: string): void {
        this.value = leaseType;
    }

    select(value: string): void {
        this.value = value;
        this.changed.emit(value);
    }
}
