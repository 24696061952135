import { GridApi } from 'ag-grid-community';
import { BaseExpandableComponent } from './baseExpandableComponent';
import { IExpandableComponentContainer } from './expandableComponentContainer.model';

export class BaseExpandableAgGridComponent extends BaseExpandableComponent {
    protected constructor(
        expandableComponentContainer: IExpandableComponentContainer,
        name: string) {
        super(expandableComponentContainer, name);
    }

    private _api: GridApi;

    expandComponent() {
        super.expandComponent();
        if (this._api) {
            setTimeout(() => {
                this._api.sizeColumnsToFit();
            }, 500);
        }
    }

    collapseComponent(): void {
        super.collapseComponent();
        if (this._api) {
            setTimeout(() => {
                this._api.sizeColumnsToFit();
            }, 500);
        }
    }

    setGridApi(gridApi: GridApi) {
        this._api = gridApi;
    }
}
