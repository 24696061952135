<div *ngIf="parentSite">
    <div *ngIf="!editMode">
        <label *ngIf="!hasReportingParcel">
            This site ({{parentSite.name}}) does not have a reporting parcel set.
        </label>
        <label *ngIf="isAnotherParcelReportingForParentSite">
            <a href="#/parcel/{{parentSite.reportingParcelId}}">{{parentSite.reportingParcelAcctNum}}</a> is the reporting parcel for this site ({{parentSite.name}}).
        </label>
        <label *ngIf="isReportingParcelForParentSite">
            This parcel reports assets for its site ({{parentSite.name}}).
        </label>
    </div>
    <div *ngIf="editMode && !isAnotherParcelReportingForParentSite && !isPartOfAMasterSite">
        <label for="reportingParcelForParentSite">
            <input id="reportingParcelForParentSite" type="checkbox" [(ngModel)]="isReportingParcelForParentSite" *ngIf="editMode"/> This parcel reports assets for its site ({{parentSite.name}})
        </label>
    </div>
    <div *ngIf="editMode && isAnotherParcelReportingForParentSite && !isPartOfAMasterSite">
        <label for="reportingParcelForParentSite">
            <input id="reportingParcelForParentSite" type="checkbox" [(ngModel)]="isReportingParcelForParentSite" *ngIf="editMode"/> Make this parcel report assets for its site ({{parentSite.name}}) - currently <a href="#/parcel/{{parentSite.reportingParcelId}}">{{parentSite.reportingParcelAcctNum}}</a> is the reporting parcel.
        </label>
    </div>
    <div *ngIf="editMode && isPartOfAMasterSite">
        <label>The site of this parcel is linked to another site, and the reporting parcel comes from the master site.  It can't be changed here.</label>
    </div>
    <div *ngIf="showSiteList">
        <h4>Sites reported by this parcel:</h4>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
