import { Component } from '@angular/core';
import { GridReadyEvent, GridApi, ColDef, GridOptions } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormOverridesListAgGridDataSource, FormOverridesListDataSourceParams } from './agGridDataSource';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../../AgGrid';
import { ReturnPreviewService } from '../returnPreview.service';
import { ReturnService } from '../../../return.service';
import { AgGridLinkCellRenderer } from '../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridLinkCellRendererParams } from '../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { ReturnPreviewFormOverridesListActionCellRendererParams, ReturnPreviewFormOverridesListActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';

export interface ReturnPreviewFormOverrideListParams {
    formRevisionScheduleId: number;
    filingBatchId: number;
    returnIds: number[];
    isReadOnly: boolean;
}

export interface ReturnPreviewFormOverrideListResult {
    deletedOverrides: Compliance.ReturnFormOverrideDetailModel[];
    clickedOverride: Compliance.ReturnFormOverrideDetailModel;
}

@Component({
    selector: 'return-preview-form-override-list',
    templateUrl: './returnPreviewFormOverrideList.component.html'
})
export class ReturnPreviewFormOverrideListComponent implements IWeissmanModalComponent<ReturnPreviewFormOverrideListParams, ReturnPreviewFormOverrideListResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _returnPreviewService: ReturnPreviewService,
        private readonly _returnService: ReturnService) { }

    private _gridApi: GridApi;
    private _gridDataSource: FormOverridesListAgGridDataSource;

    params: ReturnPreviewFormOverrideListParams;
    result: ReturnPreviewFormOverrideListResult = {
        deletedOverrides: [],
        clickedOverride: null
    };

    gridTotalsRow: any;

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withInfiniteScroll()
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withContext(this)
        .build();

    cancel(): void {
        this._bsModalRef.hide();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        const actionButtonCount = this.params.isReadOnly ? 1 : 2;

        const columns: ColDef[] = [
            {
                headerName: 'Assessor',
                field: 'parcelAssessorName',
                lockVisible: true,
                lockPosition: true,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const override = params.data as Compliance.ReturnFormOverrideDetailModel;
                        if (!(override && override.parcelAssessorId)) {
                            return '';
                        }
                        return `#/assessor/${override.parcelAssessorId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        return false;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                lockVisible: true,
                lockPosition: true,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const override = params.data as Compliance.ReturnFormOverrideDetailModel;
                        if (!(override && override.parcelId)) {
                            return '';
                        }
                        return `#/parcel/${override.parcelId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        return false;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Form',
                field: 'formRevisionName',
                lockVisible: true,
                lockPosition: true,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Field',
                field: 'fieldNameDisplay',
                lockVisible: true,
                lockPosition: true,
                width: AgGridColumns.textColumnWidth,
                sortable: false
            },
            {
                headerName: 'Override Value',
                field: 'value',
                lockVisible: true,
                lockPosition: true,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: '',
                field: 'returnId',
                lockVisible: true,
                lockPosition: true,
                sortable: false,
                width: AgGridColumns.getActionColumnWidth(actionButtonCount),
                minWidth: AgGridColumns.getActionColumnWidth(actionButtonCount),
                maxWidth: AgGridColumns.getActionColumnWidth(actionButtonCount),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                pinned: 'right',
                cellRendererFramework: ReturnPreviewFormOverridesListActionCellRendererComponent,
                cellRendererParams: {
                    viewReturn: this._viewReturn.bind(this),
                    deleteOverride: this._deleteOverride.bind(this),
                    isReadOnly: this.params.isReadOnly
                } as ReturnPreviewFormOverridesListActionCellRendererParams
            }
        ];

        this._gridApi.setColumnDefs(columns);

        this._gridApi.setSortModel({
            colId: 'formRevisionName',
            sort: 'asc'
        });

        this._setDataSource();
        this._gridApi.sizeColumnsToFit();
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): FormOverridesListDataSourceParams => {
            return {
                filingBatchId: this.params.filingBatchId,
                returnIds: this._returnService.sharedState.returns.map(r => r.returnId)
            }
        }

        this._gridDataSource = new FormOverridesListAgGridDataSource(
            this._gridApi,
            this._returnPreviewService,
            dataSourceParams
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _viewReturn(params: AgGridLinkCellRendererParams): Promise<void> {
        this.result.clickedOverride = params.data as Compliance.ReturnFormOverrideDetailModel;
        this._bsModalRef.hide();
    }

    private async _deleteOverride(params: ReturnPreviewFormOverridesListActionCellRendererParams): Promise<void> {
        const data = params.data as Compliance.ReturnFormOverrideDetailModel;

        const returnMergedParcelIds: Compliance.ReturnMergedParcelIdModel = {
            returnId: data.returnId,
            mergedParcelId: (this._returnService.isReturnConsolidated(data.returnId)) ? data.parcelId : null
        };

        const returnFormOverrideDeleteRequestModel: Compliance.ReturnFormOverrideRequestModel = {
            formRevisionId: data.formRevisionId,
            fieldName: data.fieldName,
            returnMergedParcelIds: [returnMergedParcelIds],
            value: null,
            rowVersion: data.rowVersion
        };

        await this._returnPreviewService.deleteFormFieldOverrides(returnFormOverrideDeleteRequestModel);

        this.result.deletedOverrides.push(data);
        this._refreshDataSource();
    }
}
