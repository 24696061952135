(function () {
	'use strict';

	/**
	 * @ngdoc directive
	 * @name weissman.directive:Activity Table
	 * @description
	 * # Activity Table
	 */

	angular
		.module('weissmanApp')
		.directive('sdActivityTable', sdActivityTableDirective);

	sdActivityTableDirective.$inject = [
		'$filter',
		'PropCharNames',
		'sd.Activity.Service',
		'ActivityPanelModes'
	];

	function sdActivityTableDirective($filter, PropCharNames, activityService, ActivityPanelModes) {

		var directive = {
			link: link,
			restrict: 'E',
			templateUrl: 'app/Common/Activity/SD.Activity.Table.Directive.Template.html',
			scope: {
				config: '=',
				originalConfig: '=',
				parcelId: '='
			},
		};
		return directive;

		function link(scope, element) {

			//if (scope.config) {
			//	console.log('');
			//	console.log('');
			//	console.log('loading table  - scope.config.chartType = ', scope.config.chartType);
			//	console.log('');
			//	console.log('');
			//}
			scope.ActivityPanelModes = ActivityPanelModes;

			scope.$watch("config", function (newValue, oldValue) {
				//This gets called when data changes.
				console.log('sdActivityTable - config changed! ', newValue)
				scope.showingAllYears = newValue.loadedAllYears;
				//scope.lastConfig = newValue;
				//scope.graphConfig = activityService.createGraphConfig(newValue, scope.chartType);
			});


			scope.showingAllYears = false;

			scope.PropCharNames = PropCharNames;

			scope.getCellValue = function (seriesItem, year, symbol, fractionSize) {
				var xAxisCategoryIndex = scope.config.xAxis.categories.indexOf(year);
				var val = seriesItem.data[xAxisCategoryIndex].y;

				return val || val === 0 ? $filter('currency')(val, symbol, fractionSize): '';
			}

			scope.isProjected = function (seriesItem, year) {
				var xAxisCategoryIndex = scope.config.xAxis.categories.indexOf(year);
				return seriesItem.data[xAxisCategoryIndex].isProjected;
			}

			scope.getTotalForSingleYear = function (year) {
				var result = 0;
				var xAxisCategoryIndex = scope.config.xAxis.categories.indexOf(year);
				_.forEach(scope.config.series, function (item, key) {
					if (item.data[xAxisCategoryIndex].originalValue)
						result = new Decimal(result).plus(item.data[xAxisCategoryIndex].originalValue).toNumber();
				})

				//console.log('getTotalForSingleYear - ', year, result);

				return result;
			}

			scope.isProjectedFmvForSingleYear = function (year) {
				var result = false;
				var xAxisCategoryIndex = scope.config.xAxis.categories.indexOf(year);
				_.forEach(scope.config.series, function (item, key) {
					result = result || item.data[xAxisCategoryIndex].isProjected;
				})

				return result;
			}


			scope.getTotalFmvByPropValueForSingleYear = function (year, propCharName) {
				var totalFmv = scope.getTotalForSingleYear(year);

				if (propCharName == PropCharNames.SQFT &&
                    (scope.config.chartType == ActivityPanelModes.landFMVPerSqFt || scope.config.chartType == ActivityPanelModes.landFMVPerAcre)) {
					// special case, instead of sqft attribute we will use acreage /43560
					//
					var i = _.findIndex(scope.config.propertyCharacteristics, function (o) { return o.label == PropCharNames.ACREAGE });
					if (i >= 0) {
						return new Decimal(totalFmv).dividedBy(parseFloat(scope.config.propertyCharacteristics[i].value)).dividedBy(43560).toNumber();
					}
				}
				else {
					var i = _.findIndex(scope.config.propertyCharacteristics, function (o) { return o.label == propCharName });
					if (i >= 0) {
						return new Decimal(totalFmv).dividedBy(parseFloat(scope.config.propertyCharacteristics[i].value)).toNumber();
					}
				}
				return -1;
			}


			scope.getTotalColumnTitle = function () {
				switch (scope.config.chartType) {
					case ActivityPanelModes.totalFMV:
					case ActivityPanelModes.totalFMVPerSqFt:
					case ActivityPanelModes.totalFMVPerUnit:
						return 'Total FMV';
					case ActivityPanelModes.landFMVPerSqFt:
					case ActivityPanelModes.landFMVPerAcre:
						return 'Land FMV'
					case ActivityPanelModes.totalTaxes:
						return 'Total Tax'

				}
			}


			scope.showColumn = function (propCharName, arrayOfAllowedModes) {
				if (arrayOfAllowedModes.indexOf(scope.config.chartType) < 0)
					return false;

				if (propCharName === '')
					return true;

				var i = _.findIndex(scope.config.propertyCharacteristics, function (o) { return o.label == propCharName });

				// do we have this prop char at all?
				if (i < 0)
					return false;

				// do we have any value?
				if (scope.config.propertyCharacteristics[i].value == 0)
					return false;

				switch (propCharName) {
					case PropCharNames.ACREAGE:
					case PropCharNames.UNITS:
						return true;
					case PropCharNames.SQFT:

						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
								return true;
							case ActivityPanelModes.totalFMV:
								return false;
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								// only if acreage is not here or acreage < 10
								var k = _.findIndex(scope.config.propertyCharacteristics, function (o) { return o.label == PropCharNames.ACREAGE });
								if (k < 0)
									return true;
								return (scope.config.propertyCharacteristics[k].value < 10)
						}
				}

				// some othre property name - just hide
				return false;
			}

			scope.getColumnTitle = function (propCharName) {
				switch (propCharName) {

					case PropCharNames.SQFT:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
								return '$/SqFt';
							case ActivityPanelModes.totalFMV:
								return 'error!';
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'Land $/SqFt';
						}
						break;


					case PropCharNames.UNITS:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
								return '$/Unit';
							case ActivityPanelModes.totalFMV:
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'error!';
						}
						break;

					case PropCharNames.ACREAGE:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
							case ActivityPanelModes.totalFMV:
								return 'error!';
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'Land $/Acre';
						}
						break;
				}
			}

			scope.getFooterTitle = function (propCharName) {
				switch (propCharName) {

					case PropCharNames.SQFT:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
								return 'Imps. SqFt';
							case ActivityPanelModes.totalFMV:
								return 'error!';
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'Land SqFt';
						}
						break;


					case PropCharNames.UNITS:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
								return 'Units';
							case ActivityPanelModes.totalFMV:
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'error!';
						}
						break;

					case PropCharNames.ACREAGE:
						switch (scope.config.chartType) {
							case ActivityPanelModes.totalFMVPerSqFt:
							case ActivityPanelModes.totalFMVPerUnit:
							case ActivityPanelModes.totalFMV:
								return 'error!';
							case ActivityPanelModes.landFMVPerSqFt:
							case ActivityPanelModes.landFMVPerAcre:
								return 'Land Acreage';
						}
						break;
				}
			}

			scope.getPropCharValue = function (propCharName) {
			    //This was added to handle a special case. The whole panel was written assuming the values would be the rap property
                //characteristic values, but this assumption was wrong. Imps Sq Ft !== Land Sq Ft, one is a calculated value. So this
                //catches that condition and runs the calculation.
			    if(propCharName === PropCharNames.SQFT &&
                        (scope.config.chartType === ActivityPanelModes.landFMVPerSqFt ||
                        scope.config.chartType === ActivityPanelModes.landFMVPerAcre)) {
			        return calculateLandSqFt();
                }
				var propChar = _.find(scope.config.propertyCharacteristics, { 'label': propCharName });
				return propChar.value;
			}

			function calculateLandSqFt() {
			    //From Grant, calculation is  Land Acreage * 43,560)
                var landAcreage = _.find(scope.config.propertyCharacteristics, {'label': PropCharNames.ACREAGE});
			    return parseFloat(landAcreage.value) * 43560;
            }

			scope.loadAllYears = function () {
				// we want to call api and update scope.config
				//

				if (scope.parcelId) {
					activityService.getDataByParcelAllYears(scope.config.siteId, scope.parcelId)
						.then(formatData)
				} else {
					activityService.getDataForAllYears(scope.config.siteId, scope.config.parcelFilterList)
						.then(formatData);
				}
			}

			function formatData(result) {
				console.log('table directive - activityService.getDataForAllYears - result', result);

				// convert received data to the right format
				// we don't want to replace

				// uodate original config
				scope.originalConfig.annualData = result.annualData;
				scope.originalConfig.propertyCharacteristics = result.propertyCharacteristics;

				var convertedConfig = activityService.createGraphConfig(result, scope.config.chartType);

				scope.config.series = convertedConfig.series;
				scope.config.xAxis = convertedConfig.xAxis;
				scope.config.useLatestActualized = scope.originalConfig.useLatestActualized;
				scope.config.loadedAllYears = true;
				scope.config.availableYears = scope.originalConfig.availableYears;
				scope.originalConfig.loadedAllYears = true;

				//console.log('table directive - converted scope.config ', scope.config);
				scope.showingAllYears = true;

			}

			scope.getPrecision = function (fieldName) {

				if (fieldName == ActivityPanelModes.totalFMV && scope.config.chartType != ActivityPanelModes.totalTaxes)
					return 0;

				switch (scope.config.chartType) {
					case ActivityPanelModes.totalFMV:
						return 0;
					case ActivityPanelModes.totalFMVPerSqFt:
					case ActivityPanelModes.totalFMVPerUnit:
					case ActivityPanelModes.landFMVPerSqFt:
					case ActivityPanelModes.landFMVPerSqFt:
					case ActivityPanelModes.landFMVPerAcre:
						return 2;
					case ActivityPanelModes.totalTaxes:
					case "recentAppeals":
						return 2;
				}
				return 0;
			}

			scope.getAppealCellValue = function (year, fieldName) {

				var annualYearIndex = scope.config.xAxis.categories.indexOf(year);
				var fieldValue = scope.config.series[1].data[annualYearIndex][fieldName];

				if (!fieldValue)
					return '';

				// is this a number? if so, format and return it
				//
				if (isFinite(fieldValue)) {

					if (fieldName == 'savings')
						return $filter('currency')(fieldValue, '$', 0)
					else
						return $filter('currency')(fieldValue, '', 0)
				}
				return fieldValue;
			}


		}
	};
})();
