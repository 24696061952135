import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ReportManagerModalComponent, ReportManagerModalInputParameters } from './reportManagerModal.component';

/**
 * Created as a standalone service to provide a reusable method for launching the asset detail component modal window.
 * This service needs to know about the asset details component.
 */
@Injectable()
export class ReportManagerModalService {
    constructor(
        private _bsModalService: BsModalService,
        private _wsModalService: WeissmanModalService
    ) {}

    /**
     * Shows the asset detail modal window and returns a promise.
     * The promise resolves (with the asset details component's output parameters) when the modal closes
     * @param params Initial parameters for the asset details modal component.
     */
    showForFormRevision(params: ReportManagerModalInputParameters): Promise<Compliance.FormRevisionReportModel> {
        return new Promise((resolve, reject) => {
            const showModalFn = (): BsModalRef => {
                return this._bsModalService.show(ReportManagerModalComponent, {
                    class: 'modal-xl',
                    initialState: {
                        params: params
                    },
                    ignoreBackdropClick: true
                });
            }

            this._wsModalService.show(showModalFn.bind(this)).whenClosed.then(bsModalRef => {
                const result = (bsModalRef.content as ReportManagerModalComponent).savedReport;
                    resolve(result);
                },
                reject);
        });
    }

    showForFilingBatch(params: ReportManagerModalInputParameters): Promise<Compliance.FilingBatchReportModel> {
        return new Promise((resolve, reject) => {
            const showModalFn = (): BsModalRef => {
                return this._bsModalService.show(ReportManagerModalComponent, {
                    class: 'modal-xl',
                    initialState: {
                        params: params
                    },
                    ignoreBackdropClick: true
                });
            }

            this._wsModalService.show(showModalFn.bind(this)).whenClosed.then(bsModalRef => {
                const result = (bsModalRef.content as ReportManagerModalComponent).savedReport;
                    resolve(result);
                },
                reject);
        });
    }
}
