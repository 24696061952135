import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BaseRepository } from "../../Common/Data/base.repository";

@Injectable()
export class FormLibraryRepository extends BaseRepository{
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getFormRevisions(): Observable<Compliance.FormRevisionForLibraryModel[]> {
        return this.httpGet('/api/formLibrary/formRevisions');
    }

    export(model: Compliance.FormLibraryExportModel): Observable<number> {
        return this.httpPut('/api/formLibrary/export', model);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/formLibrary/export/${longRunningProcessId}`, options);
    }
}
