<input [(ngModel)]="selectedUserName"
       [typeahead]="users"
       class="form-control"
       [placeholder]="placeholder"
       typeaheadOptionField="label"
       [typeaheadOptionsLimit]="100"
       [typeaheadMinLength]="2"
       [typeaheadScrollable]="true"
       (typeaheadOnSelect)="selectItem($event.item)"
       (mouseup)="select($event.target)"
       (ngModelChange)="changed()"
       (typeaheadOnBlur)="onBlur()"
       [class.ng-touched]="showInvalidState"
       [disabled]="disabled || !users.length"
       [required]="required">
