import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { AnnualBudgetViewModel, AnnualBudgetService } from './budget.details.service';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { AssessmentComponentTypeEnum } from '../../constants.new'

@Component({
    selector: 'annual-budget',
    templateUrl: './budget.details.component.html',
    styleUrls:['./budget.details.component.scss']
})
export class AnnualBudgetComponent implements OnChanges {
    @Input() viewModel: AnnualBudgetViewModel;
    @Input() currentYear?: AnnualDetailYear;
    @Input() editState?: AnnualDetailEditState;

    availableBudgets: Core.AvailableAnnualBudget[] = [];
    selectedBudget: Core.AvailableAnnualBudget = {
		annualBudgetId: 0,
        budgetName: '',
        budgetDate: new Date(),
		companyBudgetId: 0,
		isFrozen: false
	};
    standardAssessmentComponents: Core.BudgetAssessmentComponentDTO[] = [];
    alternativeAssessment: Core.BudgetAssessmentComponentDTO;
    budgetAssessmentTotals: {av: number, fmv: number} = {
        av: null,
        fmv: null
    };
    totalPaymentAmount: number;
    selectedBudgetTaxesView: 'summary' | 'detail' = 'summary';
    paymentDueDates: {date: Date, total: number}[];

    availableCompanyBudgets: Core.AvailableCompanyBudget[] = [];
    filteredCompanyBudgets: Core.AvailableCompanyBudget[] = [];
    overlappingCompanyBudgets: Core.AvailableCompanyBudget[] = [];
    nonOverlappingCompanyBudgets: Core.AvailableCompanyBudget[] = [];
    selectedBudgetToAdd: Core.AvailableCompanyBudget;
    addCompanyBudgetId: number;

    constructor(private annualBudgetService: AnnualBudgetService,
        private toastr: ToastrService,
        private messageBoxService: MessageBoxService){}

    async ngOnChanges() {
        this.viewModelChanged();
    }

    async viewModelChanged(): Promise<void> {
        if(this.viewModel) {
            this.viewModel.hasWritePermission = this.editState.editMode;

            const availableBudgets = await this.annualBudgetService.getAvailableBudgets(this.currentYear.annualYear1, this.viewModel.parcelID);
            this.availableBudgets = _.orderBy(availableBudgets, ['budgetDate', 'budgetName'], ['desc', 'asc']);
            // Get Company Budgets available for the TL Company parent of Parcel.
            this.availableCompanyBudgets = await this.annualBudgetService.getAvailableCompanyBudgets(this.viewModel.parcelID);
            // Remove Budgets from Available Company Budgets that the Parcel already has an AnnualBudget instance for.
            this.filteredCompanyBudgets = _.reject(this.availableCompanyBudgets, (cb) => {
                return cb.isFrozen || _.some(this.availableBudgets, {companyBudgetId: cb.companyBudgetId});
            });
            // Remove Budgets where the Year doesn't overlap with the Fiscal Period
            //     OR Budgets where the next Year doesn't overlap with the Fiscal Period (to support
            //        prior year payments for Cash basis).
            let budgetOverlaps = (cb) : boolean => {
                if ( cb.fiscalPeriodBegin.getUTCFullYear() === this.currentYear.annualYear1 ||
                     cb.fiscalPeriodEnd.getUTCFullYear() === this.currentYear.annualYear1 )
                {
                    return true;
                }
                if ( (cb.fiscalPeriodBegin.getUTCFullYear() === this.currentYear.annualYear1 + 1 ||
                      cb.fiscalPeriodEnd.getUTCFullYear() === this.currentYear.annualYear1 + 1) )
                {
                    return true;
                }
                return false;
            };
            this.overlappingCompanyBudgets = _.reject(this.filteredCompanyBudgets, (cb) => {
                return !budgetOverlaps(cb);
            });
            this.nonOverlappingCompanyBudgets = _.reject(this.filteredCompanyBudgets, (cb) => {
                return budgetOverlaps(cb);
            });

            if(!this.viewModel.model) {
                return;
            }

            this.editState.showEditControls = true;

            this.selectedBudget = _.find(this.availableBudgets, {annualBudgetId: this.viewModel.model.annualBudgetId});
            // If only one available Company Budget, pre-select it for Add, for convenience.
            this.selectedBudgetToAdd = (this.filteredCompanyBudgets.length === 1 ? this.filteredCompanyBudgets[0] : undefined);
            if ( this.overlappingCompanyBudgets.length === 1 ) {
                this.selectedBudgetToAdd = this.overlappingCompanyBudgets[0];
            }
            else if ( this.filteredCompanyBudgets.length === 1 ) {
                this.selectedBudgetToAdd = this.filteredCompanyBudgets[0];
            }

            console.log(this.viewModel);
            this.standardAssessmentComponents = _.chain(this.viewModel.model.assessmentComponents)
                .filter((item) => {
                    return item.assessmentComponentTypeID !== AssessmentComponentTypeEnum.Cap;
                })
                .sortBy(['sequence'])
                .value();
            this.alternativeAssessment = _.find(this.viewModel.model.assessmentComponents, (item) => {
                return item.assessmentComponentTypeID === AssessmentComponentTypeEnum.Cap;
            });
            this.budgetAssessmentTotals.av = _.reduce(this.standardAssessmentComponents, (sum, component) => sum + component.assessedValue, 0);
            this.budgetAssessmentTotals.fmv = _.reduce(this.standardAssessmentComponents, (sum, component) => sum + this.getFairMarketValue(component), 0);

            this.paymentDueDates = _.chain(this.viewModel.model.taxCollectors)
                .flatMap('taxPayments')
                .uniqBy(payment => payment.dueDate.getTime())
                .map('dueDate')
                .sortBy()
                .map(dueDate => {
                    const total = _.reduce(this.viewModel.model.taxCollectors, (sum, collector) => {
                        const payment = _.find(collector.taxPayments, payment => moment(payment.dueDate).isSame(dueDate));
                        return payment ? sum + payment.paymentAmount : sum;
                    }, 0);

                    return {date: dueDate, total: total}
                })
                .value();

            //this.viewModel.model.taxCollectors = _.map(this.viewModel.model.taxCollectors, collector => {
            //    collector.totalPayment = _.reduce(collector.taxPayments, (sum, payment) => sum + payment.paymentAmount, 0);
            //    return collector;
            //});

            this.totalPaymentAmount = _.reduce(this.viewModel.model.taxCollectors, (sum, collector) => sum + collector.totalPayment, 0);
            this.viewModel.updateModalData();
        } else {
            this.editState.showEditControls = false;
        }
    }

    getCollectorPaymentAmount(taxPayments: Core.BudgetTaxPaymentDTO[], dueDate: Date): number{
        const payment = _.find(taxPayments, payment => moment(payment.dueDate).isSame(dueDate));
        return payment ? payment.paymentAmount : null;
    }

    getFairMarketValue(component: Core.BudgetAssessmentComponentDTO): number {
        if ( component.fairMarketValue ) {
            return Number(component.fairMarketValue);
        }
        if ( !component.ratio || component.ratio === 0 ) {
            return 0;
        }

        return new Decimal(component.assessedValue).dividedBy(component.ratio).toNumber();
    }

    async selectedBudgetChanged(): Promise<void> {
        this.editState.entityLoading = true;
        const viewModel = await this.annualBudgetService.getAnnualBudgetViewModelByID(this.viewModel.annualYearModel, this.selectedBudget.annualBudgetId, this.viewModel.parcelID, this.viewModel.parcelAcctNum);

        _.assign(this.viewModel, viewModel);
        this.viewModelChanged();

        this.editState.entityLoading = false;
    }

    async deleteBudget(): Promise<void> {
        const result = await this.messageBoxService.open({
            message: 'Are you sure you want to delete this budget?',
            buttons: MessageBoxButtons.OKCancel
        })

        if (result === MessageBoxResult.Cancel) {
            return;
        }

        const deleteSuccess = await this.tryDelete();

        if(deleteSuccess) {
            this.editState.setEditMode(false);

            this.availableBudgets = await this.annualBudgetService.getAvailableBudgets(this.currentYear.annualYear1, this.viewModel.parcelID);

            if(this.availableBudgets.length) {
                this.selectedBudget = this.availableBudgets[0];

                this.viewModel.model = await this.annualBudgetService.getAnnualBudget(this.selectedBudget.annualBudgetId);

                this.viewModelChanged();

                this.viewModel.resetEdit();
                this.editState.refreshGrid = true;
            } else {
                this.viewModel.model = undefined;

                this.viewModelChanged();
                this.editState.refreshGrid = true;
            }
        }
    }

    private async tryDelete(): Promise<boolean> {
        this.editState.entityLoading = true;
        try {
            await this.annualBudgetService.deleteAnnualBudget(this.viewModel.model.annualBudgetId);
            return Promise.resolve(true)
        }
        catch(e) {
            console.warn(e);
            return Promise.resolve(false);
        }
        finally {
            this.editState.entityLoading = false
        }

    }

    async addNewBudget(): Promise<void> {
        if ( !this.selectedBudgetToAdd )
        {
            return Promise.resolve();
        }
        this.editState.entityLoading = true;

        this.viewModel.model = await this.annualBudgetService.addAnnualBudget(this.viewModel.parcelID, this.currentYear.annualYear1, this.selectedBudgetToAdd.companyBudgetId);
        await this.viewModelChanged();

        this.selectedBudgetToAdd = undefined;
        this.viewModel.resetEdit();
        this.editState.setEditMode(true);
        this.editState.refreshGrid = true;

        this.editState.entityLoading = false;
    }
}
