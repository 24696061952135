import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AceIconComponent } from './icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AceIconComponent],
    exports: [AceIconComponent]
    })
export class AceIconModule {
}
