import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

export class JurisdictionSpecialist {
    assessorID: number;
    jurisdictionSpecialist: Weissman.Model.Workflow.JurisdictionSpecialist
    propertyTypeID: number;
    propertyTypeXStateHasChildExceptions: boolean;
    siteClassExceptions: Weissman.Model.Workflow.JurisdictionSpecialist[];
    stateID: number;
}

export interface StateAssessorException extends Weissman.Model.Workflow.JurisdictionSpecialist {
    assessor: Weissman.Model.Domain.SiteAssessorDTO;
    hasSiteClassExceptions: boolean;
}

@Injectable()
export class ProtocolService {
    private readonly _baseUrl = 'api/jurisdictionSpecialist';

    constructor(private readonly _http: HttpClient) {}

    getByState(stateId: number): Promise<JurisdictionSpecialist[]> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/state/${stateId}`)) as Promise<JurisdictionSpecialist[]>
    }

    getByAssessor(assessorId: number): Promise<JurisdictionSpecialist[]> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/assessor/${assessorId}`)) as Promise<JurisdictionSpecialist[]>
    }

    getExceptionsByStateXPropertyType(stateId: number, propertyTypeId: number): Promise<StateAssessorException[]> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/state/${stateId}/ExceptionsByPropertyType/${propertyTypeId}`)) as Promise<StateAssessorException[]>;
    }

    saveStateSpecialists(specialists: Weissman.Model.Workflow.JurisdictionSpecialist[]): Promise<any> {
        return lastValueFrom(this._http.put(`${this._baseUrl}`, specialists)) as Promise<any>
    }

    saveAssessorSpecialists(specialists: Weissman.Model.Workflow.JurisdictionSpecialist[], assessorId: number): Promise<any> {
        const updatedModel = {
            jurisdictionSpecialists: specialists
        }
        return lastValueFrom(this._http.post(`${this._baseUrl}/assessorProtocols/${assessorId}`, updatedModel)) as Promise<any>
    }
}
