import { Component, OnInit, OnDestroy, ElementRef, ChangeDetectorRef, Renderer2, NgZone } from '@angular/core';
import { ReturnService } from '../../../return.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../../Assets/returnAssets.service';
import { ReturnPreviewService, ReturnFormModel, ReturnPreviewServiceSharedState, FormChanges } from '../returnPreview.service';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { FieldOverride, PDFViewerBase } from '../../../../../Common/PDFViewer/pdfViewerBase';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { RETURN_PREVIEW_FORM_VIEWER_HELP } from './returnPreviewFormViewer.component.help';
import { HelpService } from '../../../../../UI-Lib/Help-Tooltip';
import { ReturnPreviewFormOverrideListResult } from '../Form-Overrides-List/returnPreviewFormOverrideList.component';
import { RestrictService, Roles } from '../../../../../Common/Permissions/restrict.service';
import { ProductAnalyticsService } from "../../../../../Common/Amplitude/productAnalytics.service";
import { ToastrService } from "ngx-toastr";
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import * as _ from 'lodash';
import { ScriptLoaderService } from 'src/app/UI-Lib/Utilities/scriptLoader.service';
import { Constants } from 'src/app/constants.new';

const PDF_SELECTOR = '#return-output';

@Component({
    selector: 'return-preview-form-viewer',
    templateUrl: './returnPreviewFormViewer.component.html',
    styleUrls: ['./returnPreviewFormViewer.component.scss']
})
export class ReturnPreviewFormViewerComponent extends PDFViewerBase implements OnInit, OnDestroy, IReturnPartComponent {
    // The form preview uses pdfjs, and extends a base class in order to share a lot of logic with the Core application's page. A very large method
    // "loadPreviewForForm" contains a lot of the basic logic, in terms of setting up fields and lookups and UI components for overrides. The pdf viewer
    // cannot create all the pages at once, without risking crashing chrome by using too much memory. The amount already set may be high, but was done to
    // help the non-compliance portion cope with larger documents - forms are inherently not as long. The value is set in MAX_RENDERED_PAGES

    // Scrolling to unrendered pages works by placeholders in the dom and using a timer to check if it has become visible to the user (meaning it should
    // cause the next pagination to happen). What is missing from this - and not yet needed - is that some of the logic to set up the annotations and
    // lookups in the UI happens on the original render. To expand this to handle scrolling a longer doc and coping with changes to fields,
    // some logic would need extracting from that into a place that could be called when each new area is rendered.

    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnPreviewService: ReturnPreviewService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _elementRef: ElementRef,
        private readonly _helpService: HelpService,
        private readonly _cdRef: ChangeDetectorRef,
        private readonly _restrictService: RestrictService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _toastr: ToastrService,
        public _zone: NgZone,
        public _renderer: Renderer2,
        public _modalService: WeissmanModalService,
        _scriptLoaderService: ScriptLoaderService,
        _constants: Constants
    ) {
        super(PDF_SELECTOR, _modalService, _scriptLoaderService, _constants, _renderer, _zone);
    }

    isBusy: boolean = false;
    isPageLoading: boolean = false;
    isError: boolean = false;
    hasTooManyRows: boolean = false;
    showFieldLocation: boolean = false;
    applyEditsToAll: boolean = false;
    returnForm: ReturnFormModel;
    canEditComplianceSetups: boolean;
    formChanges: FormChanges;

    protected readonly _PDF_OUTPUT_HOLDER_QUERY_SELECTOR: string = '#return-output';

    private _localReturnPreviewServiceSharedState: ReturnPreviewServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;

    private _returnFormSub: Subscription;
    private _returnReadOnlyFlagSub: Subscription;
    private _returnFormOverrideListSub: Subscription;
    private _isReportEmpty: boolean = false;
    private _destroy$: Subject<void> = new Subject();

    get isExcelReport(): boolean {
        return this.returnForm && this.returnForm.isExcel;
    }

    get isReportEmpty(): boolean {
        return this.returnForm && this._isReportEmpty;
    }

    get canPreview(): boolean {
        return this.returnForm && (!this.returnForm.isExcel) && (!this._isReportEmpty) && (!this.isError);
    }

    get canShowFieldLocations(): boolean {
        return this._returnService.processStatus === Compliance.FilingBatchProcessStatusEnum.Started
            || (this._returnService.processStatus === Compliance.FilingBatchProcessStatusEnum.Locked && this._returnPreviewService.isFormInReviewMode());
    }

    ngOnInit(): void {
        this._helpService.setContent(RETURN_PREVIEW_FORM_VIEWER_HELP);
        this._returnPreviewService.fieldOverrideHolder = this.overrideHolder;

        this.canEditComplianceSetups = this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);

        this._returnPreviewService.subscribeToServiceActivationCycle(this);
        this._returnService.tasks$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            if (!this._returnService.isLongRunningProcessActive) {
                this._refreshPreview(true);
            }
        });

        this._busyIndicatorService.indicatorActive$.pipe(takeUntil(this._destroy$))
            .subscribe(active => this.isPageLoading = active);
    }

    ngOnDestroy(): void {
        this._returnPreviewService.unsubscribeFromServiceActivationCycle(this);
        this._destroy$.next();
        this._destroy$.complete();

        this.disposePdfDocument();
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this.isReadOnly = this._returnService.isReturnInReadOnlyMode || !this._returnService.canEditCompany;

        await this._refreshPreview();

        this._returnReadOnlyFlagSub = this._returnService.isReturnInReadOnlyMode$.subscribe(async (x) => {
            await this._handleReturnReadOnlyFlagChange(x);
        });

        this._returnFormSub = this._returnPreviewService.returnForm$.subscribe(() => {
            this._refreshPreview();
        });

        this._returnFormOverrideListSub = this._returnPreviewService.returnFormOverrideList$.subscribe(async (x) => {
            await this._returnFormOverridesListChanged(x);
        });
    }

    onReturnPartServiceDeactivated(): void {
        this._returnFormSub && this._returnFormSub.unsubscribe();
        this._returnReadOnlyFlagSub && this._returnReadOnlyFlagSub.unsubscribe();
        this._returnFormOverrideListSub && this._returnFormOverrideListSub.unsubscribe();
    }

    refresh(): void {
        this._loadPreview();
    }

    onApplyEditsToAllChange(value: boolean): void {
        this.applyEditsToAll = value;
    }

    setShowFieldLocation(showFieldLocation: boolean): void {
        this.showFieldLocation = showFieldLocation;
        if (showFieldLocation) {
            this._loadPreview(false);
        }
    }

    private async _returnFormOverridesListChanged(result: ReturnPreviewFormOverrideListResult): Promise<void> {
        // if this page has a deleted override, just reload, could optimize later by finding UI element(s)
        let thisPageNeedsRefreshForDeletes = false;
        if (result.deletedOverrides && this.returnForm) {
            if (result.deletedOverrides.find((overrideDetail: Compliance.ReturnFormOverrideDetailModel) => {
                return overrideDetail.formRevisionId === this.returnForm.formRevisionId && overrideDetail.returnId === this.returnForm.returnId;
            }) !== undefined) {
                thisPageNeedsRefreshForDeletes = true;
            }
        }

        if (result.clickedOverride) {
            // check if we're already on the return, if so just scroll to it
            const target = result.clickedOverride;
            if (this.returnForm && this.returnForm.formRevisionId === target.formRevisionId
                && this.returnForm.returnId === target.returnId
                && this.returnForm.mergeParcelId === target.parcelId) {
                if (thisPageNeedsRefreshForDeletes) {
                    await this._loadPreview(); // refresh for deletions
                }
                const elem = this.overrideHolder.overrides.find(override => override.fieldName === target.fieldName);
                if (elem && elem.formElements && elem.formElements.length > 0) {
                    this.scrollToElement(elem.formElements[0]);
                }
            } else {
                this._returnPreviewService.openPreviewByOverrides(result.clickedOverride);
            }
        } else {
            if (thisPageNeedsRefreshForDeletes) {
                // no navigation but still refresh the preview for deletions;
                this._loadPreview();
            }
        }
    }

    async download(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Downloading' });

        try {
            await (this.returnForm.reportId ?
                this._returnPreviewService.startDownloadReport(
                    this.returnForm.returnId,
                    this.returnForm.formRevisionId,
                    this.returnForm.reportId) :
                this._returnPreviewService.startDownloadForm(
                    this.returnForm.returnId,
                    this.returnForm.formRevisionId,
                    this.returnForm.mergeParcelId));
        }
        finally {
            busyRef.hide();
        }
    }

    removeUnsavedOverrides(): void {
        this.formChanges.overrides.forEach(x => {
            this._deleteOverrideMarker(x);
            x.formElements.forEach(y => y.classList.remove(this.MODIFIED_FIELD_CSS_CLASS));
            const saved = this.overrideHolder.getSaved(x.fieldName);
            if (saved) {
                this._createOverrideMarker(x);
            }
        });

        this.formChanges.overrides = [];
        this._loadPreview(false);
    }

    async submitPdfChanges(): Promise<void> {
        try {
            this.formChanges.returnMergedParcelIds = this._getReturnAndParcelIdsForEdits();
            const result = await this._returnPreviewService.updateFormFieldOverrides(this.formChanges);
            for (const x of this.formChanges.overrides) {
                (x.formElements as any[]).forEach(element => {
                    element.classList.add(this.MODIFIED_FIELD_CSS_CLASS);
                });

                this._createOverrideUI(x);
                this._deleteInfoMarker(x);
                this.overrideHolder.renumberOverrides();

                const persistedResult = result.find(override => override.returnId === this.returnForm.returnId
                                                                && override.fieldName === x.fieldName);
                if (persistedResult) {
                    this.overrideHolder.saveOverride(persistedResult, x.fieldName);
                }
            }

            await this._loadPreview(false);
        } finally {
            this._productAnalyticsService.logEvent('click-unsaved-overrides-save-changes', {});
            this.isBusy = false;
        }
    }

    async onPdfFieldChange(event): Promise<void> {
        if (!event.target) {
            return;
        }
        const id = event.target.parentElement.getAttribute('data-annotation-id');
        const fieldData = this._idFieldMap[id];

        //todo: is there an annotation or configuration to do this automatically?
        // set checkbox field value based on checked state
        if (event.target.type === 'checkbox') {
            event.target.value = event.target.checked ? 'On' : 'Off';
        }

        const newValue = event.target.value;

        const oldFormatOverride = this._oldFormatOverrides[fieldData.fieldName];
        if (oldFormatOverride) {
            await this._deleteOverride(oldFormatOverride.override, oldFormatOverride.rowVersion);
        }

        fieldData.elements.forEach(element => (element as any).value = newValue);

        const override = this.formChanges.overrides.find(x => x.fieldName === fieldData.fieldName);

        if (override) {
            override.overrideValue = newValue;
            this.formChanges.overrides = [ ...this.formChanges.overrides ];
        } else {
            const override = {
                fieldName: fieldData.fieldName,
                overrideValue: newValue,
                formElements: fieldData.elements,
                rowVersion: this.overrideHolder.getSaved(fieldData.fieldName) ? this.overrideHolder.getSaved(fieldData.fieldName).rowVersion : null
            }
            this.formChanges.overrides = [ ...this.formChanges.overrides, override ];
            this._deleteOverrideMarker(override);
            this._createOverrideMarker(override, false);
            override.formElements.forEach(x => this._renderer.addClass(x, this.MODIFIED_FIELD_CSS_CLASS));
        }
    }

    private async _refreshPreview(force: boolean = false): Promise<void> {
        const returnPreviewServiceSharedState: ReturnPreviewServiceSharedState = this._returnPreviewService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnForm = this._localReturnPreviewServiceSharedState && this._localReturnPreviewServiceSharedState.returnForm;
        const sharedReturnForm = returnPreviewServiceSharedState.returnForm;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if the input parameters have changed
        if (force ||
            !_.isEqual(localReturnForm, sharedReturnForm) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)
        ) {
            await this._loadPreview();
        }
    }

    private async _loadPreview(reloadTemplate: boolean = true): Promise<void> {
        // persist local state for future checks
        this._persistState();

        if (reloadTemplate) {
            this.disposePdfDocument();
        }

        this.returnForm = this._localReturnPreviewServiceSharedState.returnForm;
        this._isReportEmpty = false;
        this.isError = false;
        this.hasTooManyRows = false;

        if ((!this.returnForm) || this.returnForm.isExcel) {
            return;
        }

        this.formChanges = {
            formRevisionId: this.returnForm.formRevisionId,
            returnMergedParcelIds: this._getReturnAndParcelIdsForEdits(),
            overrides: []
        }

        this.isBusy = true;

        try {
            // figure out if loading report or form
            if (this.returnForm.reportId) {
                const report = await this._returnPreviewService.getReturnFormReport(this.returnForm.returnId, this.returnForm.formRevisionId, this.returnForm.reportId);
                if (report) {
                    await this._zone.runOutsideAngular(async () => await this._loadPreviewForReport(report));
                } else {
                    this._isReportEmpty = true;
                }
            } else {
                if (reloadTemplate) {
                    const { form, data } = await this._returnPreviewService.getFormTemplateAndData(this.canShowFieldLocations && this.showFieldLocation);
                    await this._zone.runOutsideAngular(async () => await this._loadPreviewForForm(form, data, data && data.formRevisionOverrides, this._returnPreviewService.isFormInReviewMode()));
                } else {
                    const data = await this._returnPreviewService.getFormData(this.canShowFieldLocations && this.showFieldLocation);
                    await this._loadFormData(data, data && data.formRevisionOverrides, this._returnPreviewService.isFormInReviewMode());
                }
            }
        }
        catch (err) {
            if (err.status === 413) {
                this.hasTooManyRows = true;
            }
            this.isError = true;
            if (err.error?.message) {
                this._toastr.error(err.error.message);
            } else {
                throw err;
            }
        }
        finally {
            this.isBusy = false;
            this._cdRef.detectChanges();
        }
    }

    private _persistState(): void {
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        this._localReturnPreviewServiceSharedState = this._returnPreviewService.getSharedStateClone();
    }

    private async _loadPreviewForReport(blob: Blob): Promise<void> {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            this.loadPdfDocument({
                data: fileReader.result
            }).then(() => {
                this.updateNextPageVisibility();
            });
        };
        fileReader.readAsArrayBuffer(blob);
    }

    private _getReturnAndParcelIdsForEdits(): Compliance.ReturnMergedParcelIdModel[] {
        if (this.applyEditsToAll) {
            // Map the associated form revision IDs to the return ID
            const formRevisions = this._returnService.getAssociatedReturnFormRevisions()
                .reduce((acc, x) => {
                    if (!acc.has(x.returnId)) {
                        acc.set(x.returnId, new Set());
                    }
                    acc.get(x.returnId).add(x.formRevisionId);
                    return acc;
                }, new Map<number, Set<number>>());
            // Return any returns that have a form revision ID that matches the selected ID
            return this._returnService.sharedState.returns
                .filter(x => formRevisions.has(x.returnId) && formRevisions.get(x.returnId).has(this.returnForm.formRevisionId))
                .map(x => ({
                        mergedParcelId: this._returnService.isReturnConsolidated(x.returnId) ? x.parcelId : null,
                        returnId: x.returnId
                    }));
      } else {
            return [
                {
                  mergedParcelId: (this._returnService.isReturnConsolidated(this.returnForm.returnId)) ? this.returnForm.mergeParcelId : null,
                  returnId: this.returnForm.returnId
                }
            ];
      }
    }

    protected async _deleteOverride(override: FieldOverride, rowVersion: number[]): Promise<void> {
        this.isBusy = true;

        try {
            const oldFormatShortName = Object.keys(this._oldFormatOverrides).find(shortName => this._oldFormatOverrides[shortName].override === override);
            if (oldFormatShortName) {
                delete this._oldFormatOverrides[oldFormatShortName];
            }

            const overrideModel: Compliance.ReturnFormOverrideRequestModel = {
                formRevisionId: this.returnForm.formRevisionId,
                fieldName: override.fieldName,
                value: null,
                returnMergedParcelIds: this._getReturnAndParcelIdsForEdits(),
                rowVersion: rowVersion
            };

            await this._returnPreviewService.deleteFormFieldOverrides(overrideModel);

            (override.formElements as any[]).forEach(formElement => {
                formElement.disabled = false;
                formElement.classList.remove(this.MODIFIED_FIELD_CSS_CLASS);
            });

            this._deleteOverrideUI(override);

            await this._loadPreview(false);
        } finally {
            this.isBusy = false;
        }
    }

    protected _postFormLoadAction() {
        const overrideToScrollTo = this._returnPreviewService.sharedState.navigateToOverride;
        if (overrideToScrollTo) {
            const elem = this.overrideHolder.overrides.find(override => override.fieldName === overrideToScrollTo.fieldName);
            if (elem && elem.formElements && elem.formElements.length > 0) {
                this.scrollToElement(elem.formElements[0]);
            }
        }
    }

    protected getElementRef(): ElementRef {
        return this._elementRef;
    }

    protected isShowingAnnotations(): boolean {
        return !(this.returnForm && this.returnForm.reportId) && this._returnPreviewService.isFormDataNeededOnPdf();
    }

    private async _handleReturnReadOnlyFlagChange(isReadOnly: boolean): Promise<void> {
        this.isReadOnly = isReadOnly || !this._returnService.canEditCompany;
        this._updateInputFieldReadOnlyAttributes();
    }

    protected resolveAccountIndex(override: Compliance.FormOverrideModel): number {
        return 0;
    }
}
