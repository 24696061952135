import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TaxRateArea } from './tax.rates.panel.model';
export interface ICellRendererParamsForTaxRatesPanelTaxAuthorities extends ICellRendererParams {
    rate: (taxRateArea: TaxRateArea, year: number) => string;
    taxRateAuthorities: (taxRateArea: TaxRateArea, year: number) => {name: string, rate: number}[];
    year: number;
}

@Component({
    selector: 'tax-authorities-cell',
    template: `
    <span [popover]="taxAuthorities" triggers="mouseenter:mouseleave" container="body" placement="left">{{rate}}</span>

    <ng-template #taxAuthorities>
        <table class="tax-authorities-popover">
            <tr>
                <th>Authority Name</th>
                <th>Rate</th>
            </tr>
            <tr *ngFor="let authority of authorities">
                <td>{{authority.name}}</td>
                <td>{{authority.rate | percent:"1.6-6"}}</td>
            </tr>
        </table>
    </ng-template>
    `
})
export class TaxRatesPanelTaxAuthoritiesCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForTaxRatesPanelTaxAuthorities;
    rate: string;
    authorities: {name: string, rate: number}[];

    agInit(params: ICellRendererParamsForTaxRatesPanelTaxAuthorities): void {
        this.params = params;

        const taxRateArea: TaxRateArea = params.data;
        const year: number = params.year;

        this.rate = params.rate(taxRateArea, year);
        this.authorities = params.taxRateAuthorities(taxRateArea, year);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
