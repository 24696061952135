(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdUserTeamPicker', sdUserTeamPicker);

	// sdUserTeamPicker.$inject = [];

	function sdUserTeamPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Team/User-Team-Picker/_userTeamPicker.html',
			scope: {
				userId: '=',
				teamId: '=',
				team: '=?',
				assignee: '=?',
				closeFn: '&',
				clickFn: '&',
				pickerSm: '=?',
				appendToBody: '=?',
				isReassign: '=?',
				required: '=?',
				showClearButton: '=?',
				name: '=?',
				disabled: '=?',
				valueSetFromOutside: '=?',
				includeCompanyRepresentative: '=?',
				restrictToRyanInstance: '=',
				entityIdScope: '=',
				entityTypeScope:'=',
				instanceIdScope:'=',
				includeInactiveContacts: '=?',
				entityIdsMultiselectScope: '=?',
			}
		};
		return directive;

		function link(scope, element, attrs, vm) {
		    // In this case we have a required attribute with no value, which means it is required
		    if (attrs.required === "") {
		        vm.required = true;
			}
		}
	}

	Controller.$inject = ['teamsService', '$timeout', '$scope'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(teamsService, $timeout, $scope) {
		var vm = this;

		vm.users = [];
		vm.selectedUser = {};
		vm.team = vm.team || {};
		vm.assignee = vm.assignee || {};
		vm.formatInput = formatInput;
		vm.userSelected = userSelected;
		vm.filterUserTeam = filterUserTeam;
		vm.orderFn = orderFn;
		vm.changed = changed;
		vm.revertLabel = revertLabel;
		vm.clearData = clearData;

		$scope.$watch('vm.valueSetFromOutside', function (newValue, oldValue, scope) {
			//console.log("vm.valueSetFromOutside has been changed!", newValue, oldValue);
			if (newValue){
				vm.selectedUser = newValue;
				userSelected(newValue);
			}
		}, true);

		activate();

		function activate() {
			getAssignableUsers();
		}

		function getAssignableUsers() {
			//call endpoint that is locked down to Ryan contacts only
			if(vm.restrictToRyanInstance === true) {
				teamsService.getAllAssignableRyanUsers(vm.includeCompanyRepresentative, vm.entityIdScope, vm.entityTypeScope)
				.then(function (result) {
					vm.users = result;

					let foundUser = _.find(vm.users, { userID: vm.userId, teamID: vm.teamId });
					patchUsersWithAssignedUserIfMissing(foundUser);
					vm.selectedUser = foundUser || vm.assignee;
				})			
			}
			//get contacts for 
			else {
				teamsService.getAllAssignableUsers(vm.isReassign, vm.includeCompanyRepresentative, vm.entityIdScope, vm.entityTypeScope, vm.instanceIdScope, vm.includeInactiveContacts, vm.entityIdsMultiselectScope)
				.then(function (result) {
					vm.users = result;

					let foundUser = _.find(vm.users, { userID: vm.userId, teamID: vm.teamId });
					patchUsersWithAssignedUserIfMissing(foundUser);

					vm.selectedUser = foundUser || vm.assignee;					
				})			
			}
		}

		//insert current assigned user if missing - cross instance assignments (consulting engagements)
		function patchUsersWithAssignedUserIfMissing(foundUser) {
			if (!foundUser && Object.keys(vm.assignee).length !== 0) {
				vm.assignee.teamName = vm.team.name;
				vm.users.push(vm.assignee);
			}
		}

		function formatInput(model) {
			if (_.isEmpty(model)) {
				return;
			}

			return model.lastName + ', ' + model.firstName + '  (' + model.teamName + ')';
		}

		function userSelected($item) {
			vm.userId = $item.userID;
			vm.teamId = $item.teamID;
			vm.assignee = _.pick($item, 'userID', 'firstName', 'lastName');
			vm.team = {
				teamID: $item.teamID,
				name: $item.teamName
			}

			$timeout(vm.closeFn);
		}

		function filterUserTeam(val) {
			return _.filter(vm.users, function (user) {
				return _.includes(user.lastName.toLowerCase() + ', ' + user.firstName.toLowerCase() + ' (' + user.teamName.toLowerCase() + ')', val.toLowerCase());
			})
		}

		function orderFn(user) {
			return user.lastName + user.firstName + user.teamName;
		}

		function changed() {
			if (!vm.selectedUser) {
				vm.userId = null;
				vm.teamId = null;
				vm.assignee = {};
				vm.team = {};
			}
		}

		function clearData() {
			vm.selectedUser = null;
			vm.userId = null;
			vm.teamId = null;
			vm.assignee = {};
			vm.team = {};
		}

		function revertLabel() {
			vm.selectedUser = _.find(vm.users, { userID: vm.userId, teamID: vm.teamId }) || {};
		}
	}

})();
