(function () {
    'use strict';

    angular.module('weissmanApp')
        .controller('MessageBoxController', messageBoxController);

    messageBoxController.$inject = ['$uibModalInstance', 'MessageBoxButtons', 'MessageBoxResult', 'options', '$document', '$timeout'];

    function messageBoxController () { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, MessageBoxButtons, MessageBoxResult, options, $document, $timeout) {
        var vm = this;

        activate();

        function activate() {
            vm.title = options.title;
            vm.message = options.message;
            vm.hasOK = false;
            vm.hasYes = false;
            vm.hasNo = false;
            vm.hasCancel = false;
            vm.hasContinue = false;

            switch (options.buttons || MessageBoxButtons.OKCancel) {
                case MessageBoxButtons.YesNoCancel:
                    vm.hasCancel = true;
                    vm.hasYes = true;
                    vm.hasNo = true;
                    break;
                case MessageBoxButtons.YesNo:
                    vm.hasYes = true;
                    vm.hasNo = true;
                    break;
                case MessageBoxButtons.OKCancel:
                    vm.hasCancel = true;
                    vm.hasOK = true;

                    if (options.focusButton === MessageBoxResult.Cancel){
                      $timeout(function(){
                        $document.find('button:contains("Cancel")').focus();
                      });
                    }

                    break;
                case MessageBoxButtons.OK:
                    vm.hasOK = true;
                    break;
                case MessageBoxButtons.Continue:
                    vm.hasContinue = true;
                    break;
                default:
                    throw new Error('Unrecognized button constant: ' + buttons);
            }
        }

        vm.ok = function () {
            $uibModalInstance.close(MessageBoxResult.OK);
        };

        vm.yes = function () {
            $uibModalInstance.close(MessageBoxResult.Yes);
        };

        vm.no = function () {
            $uibModalInstance.close(MessageBoxResult.No);
        };

        vm.cancel = function () {
            $uibModalInstance.close(MessageBoxResult.Cancel);
        };
    }
}());
