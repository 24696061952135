import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseExpandableComponent } from '../../../../UI-Lib/Expandable-Component/baseExpandableComponent';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import { WeissmanMutexService } from '../../../../Compliance/WeissmanMutexService';
import { WeissmanModalService } from '../../../../Compliance/WeissmanModalService';
import { ValuationTemplateService } from '../../valuationTemplate.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UploadComponent, ValuationTemplateUploadModalParams } from './Template-Upload/upload.component';
import { WeissmanDateTimeFormatPipe } from '../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';


@Component({
    selector: 'valuation-template-upload',
    templateUrl: './valuationTemplateUpload.component.html'
})
export class ValuationTemplateUploadComponent extends BaseExpandableComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe) {
        super(_valuationTemplateService, 'valuation-template-upload');
    }

    canEdit: boolean;
    canEnterEditMode: boolean;
    template: Core.ValuationTemplateModel;
    fileName: string;
    fileChangedBy: string;
    fileChangeDate : Date;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._valuationTemplateService.template$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => {
                this.template = x;
                this.fileName = x.fileName;
                this.canEdit = !!this.template.siteClassPrimaries.length;
                this.fileChangedBy = x.fileChangedByUserName;
                this.fileChangeDate = x.fileChangeDate;
                this.canEnterEditMode = x.isInDevelopment;
            });

        this._mutexService.lockChanged$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.canEnterEditMode = !x[this._valuationTemplateService.editGroup] && this.template.isInDevelopment);
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async upload(): Promise<void> {
        if (this.template.hasFile) {
            try {
                await this._messageModalService.confirm(
                    'Are you sure you wish to overwrite the valuation template?',
                    'Confirm Overwrite'
                );
            } catch (e) {
                return;
            }
        }

        const params = {
            valuationTemplateId: this.template.valuationTemplateId,
            siteClassPrimaries: this.template.siteClassPrimaries
        } as ValuationTemplateUploadModalParams;

        await this._modalService.showAsync(UploadComponent, params, 'modal-md');
    }

    async download(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Downloading' });
        try {
            await this._valuationTemplateService.downloadTemplateFile(this.template.valuationTemplateId);
        } finally {
            busyRef.hide();
        }
    }

    async delete(): Promise<void> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the valuation template?', 'Confirm Delete');
        }
        catch (e) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await this._valuationTemplateService.deleteTemplateFile(this.template.valuationTemplateId);
        } finally {
            await busyRef.hide();
        }
    }
}
