import { Component } from '@angular/core';
import { HelpContentComponent } from '../../../../UI-Lib/Help-Tooltip';

export interface AssessorAddressTooltipComponentParams {
    address: Core.AddressModel;
}

@Component({
    selector: 'assessor-address-tooltip',
    template: `
        <em *ngIf="!params.address">No Address Selected</em>
        <address-view-edit [address]="params.address" [isEditMode]="false"></address-view-edit>
    `
})
export class AssessorAddressTooltipComponent implements HelpContentComponent<AssessorAddressTooltipComponentParams>{
    params: AssessorAddressTooltipComponentParams;

    helpInit(params: AssessorAddressTooltipComponentParams): void {
        this.params = params;
    }
}
