<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Task Exception"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!selectedOption
                    || (selectedOption.action === TaskProcessingActions.ReassignTask && !selectedOption.assignedUserId)">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-flex-container-vertical ws-flex-auto" *ngIf="options.length">
            <label>Action:</label>
            <div *ngFor="let option of options" class="ws-flex-container-vertical">
                <div class="ws-flex-auto ws-vertical-align-middle">
                    <label [for]="'option' + option.action + option.taskId" class="ws-font-weight-normal" helpTooltip [tooltipText]="option.title" position="bottom">
                        <input [id]="'option' + option.action + option.taskId" type="radio" name="exceptionOptions" [value]="option" [(ngModel)]="selectedOption" (ngModelChange)="selectedOptionChanged($event)" [disabled]="!option.isAuthorized" />
                        {{ option.title }}
                    </label>
                </div>
                <div class="ws-flex-auto" style="padding-bottom: 10px;" *ngIf="selectedOption === option && selectedOption?.action === TaskProcessingActions.ReassignTask">
                    <user-team-picker
                        [userId]="option.assignedUserId"
                        [teamId]="option.assignedTeamId"
                        (userChange)="userChanged($event, option)"
                        [required]="true">
                    </user-team-picker>
                </div>
            </div>
            <label>Comment:</label>
            <textarea ref-textarea [(ngModel)]="comment" rows="4"></textarea>
        </div>

        <div class="ws-flex-container-vertical ws-flex-auto" *ngIf="!options.length">
            No options available
        </div>
    </div>
</ws-modal-wrapper>
