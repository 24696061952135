<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header d-flex">
        <h3 class="ws-flex-auto ws-truncate">Form Template [All Tax Years - All Assessors]</h3>
        <div class="ws-flex-none header-button-container">
            <button type="button"
                    class="btn flat-button icon-button primary-button"
                    (click)="upload()"
                    *ngIf="canEdit"
                    [disabled]="!canEnterEditMode"
                    helpTooltip
                    helpContentId="app.upload"
                    position="bottom">
                <i class="fa fa-upload fa-align"></i>
            </button>
            <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="!isExpanded" (click)="expandComponent()">
                <i class="fa fa-arrows-alt"
                   helpTooltip
                   helpContentId="app.expand"
                   position="bottom"></i>
            </button>
            <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="isExpanded" (click)="collapseComponent()">
                <i class="fa fa-compress"
                   helpTooltip
                   helpContentId="app.collapse"
                   position="bottom"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body">
        <div class="no-data" *ngIf="formRevision && !formRevision.hasTemplate">
            The form doesn't have a template
        </div>
        <table class="table table-condensed table-hover table-borderless" *ngIf="formRevision && formRevision.hasTemplate">
            <tbody>
                <tr>
                    <td>
                        <span>
                            <i *ngIf="formRevision.isFormConsolidated"
                               class="consolidated-icon fa fa-sitemap"
                               helpTooltip
                               helpContentId="form.is-form-consolidated">
                            </i>
                            {{ formRevision.templateSafeFileName }}
                        </span>
                        <span *ngIf="formRevision.isPlaceholderTemplate"> (Placeholder)</span>
                        <span *ngIf="canEdit && (!formRevision.isPlaceholderTemplate || formRevision.templateChangeDate)">(Last changed by {{formRevision.templateChangedBy}} on {{formRevision.templateChangeDate | date: 'MM/dd/yyyy h:mm a'}})</span>
                        <a class="btn btn-link" helpTooltip tooltipText="The template has validation errors" position="bottom" *ngIf="hasErrors" (click)="showValidations()">
                            <i class="fa fa-warning template-validation-errors"></i>
                        </a>
                    </td>
                    <td>
                        <div class="float-end">
                            <button *ngIf="canEdit && !formRevision.isPlaceholderTemplate" class="btn flat-button icon-button secondary-button" helpTooltip helpContentId="app.edit" position="bottom" (click)="edit()" [disabled]="!canEnterEditMode">
                                <i class="fa fa-pencil fa-align"></i>
                            </button>
                            <button class="btn flat-button icon-button secondary-button" helpTooltip helpContentId="app.download" position="bottom" (click)="download()" [disabled]="!canEnterEditMode">
                                <i class="fa fa-download fa-align"></i>
                            </button>
                            <button class="btn flat-button icon-button secondary-button" helpTooltip helpContentId="app.validate" position="bottom" (click)="validate()" [disabled]="!canEnterEditMode">
                                <i class="fa fa-bug fa-align"></i>
                            </button>
                            <button *ngIf="canEdit" class="btn flat-button icon-button warning-button" helpTooltip helpContentId="app.delete" position="bottom" (click)="delete()" [disabled]="(!canEnterEditMode) || formRevision.isPlaceholderTemplate">
                                <i class="fa fa-trash fa-align"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
