import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

export interface LockedAssessorAssignments {
    depreciationTableId:number;
    indexTableId:number;
}
export interface AssessorTablePickerParams {
    reportingAssetIds: number[];
    assessors: Compliance.FormRevisionAssessorModel[];
    defaultAssessor: Compliance.FormRevisionAssessorModel;
    defaultFactorTables: Compliance.AssessorFactorTableListItemModel[];
    assessorFactorTables: Compliance.AssessorFactorTableModel[];
    lockedAssessorAssignments: {[assessorId:number]: LockedAssessorAssignments};
}

export interface AssessorTablePickerResult {
    selection: { [assessorId: number ]: {
        depreciationTableId: number;
        indexTableId: number;
    }}
}

@Component({
    selector: 'asset-class-picker-modal',
    templateUrl: './assessorTablePicker.component.html',

})
export class AssessorTablePickerComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AssessorTablePickerParams, AssessorTablePickerResult> {
    constructor(
        private _modalRef: BsModalRef,
        private _formBuilder: UntypedFormBuilder
    ) { }

    private _assessorFactorTables: { [assessorId: number]: Compliance.AssessorFactorTableListItemModel[] } = {};
    private _destroy$: Subject<void> = new Subject();

    params: AssessorTablePickerParams;
    result: AssessorTablePickerResult;

    isBusy: boolean;
    form: UntypedFormGroup;

    assessors: Compliance.FormRevisionAssessorModel[] = [];
    defaultAssessor: Compliance.FormRevisionAssessorModel; // the nominal default for getting tables
    defaultAssessors: Compliance.FormRevisionAssessorModel[] = []; // assessors using default tables

    defaultDepreciationTables: Compliance.AssessorFactorTableListItemModel[] = [];
    defaultIndexTables: Compliance.AssessorFactorTableListItemModel[] = [];
    defaultDepreciationUsedTables: Compliance.AssessorFactorTableListItemModel[] = [];
    defaultIndexUsedTables: Compliance.AssessorFactorTableListItemModel[] = [];

    assessorDepreciationTables: { [assessorId: number]: Compliance.AssessorFactorTableListItemModel[] } = {};
    assessorIndexTables: { [assessorId: number]: Compliance.AssessorFactorTableListItemModel[] } = {};
    assessorDepreciationUsedTables: { [assessorId: number]: Compliance.AssessorFactorTableListItemModel[] } = {};
    assessorIndexUsedTables: { [assessorId: number]: Compliance.AssessorFactorTableListItemModel[] } = {};

    defaultFactorTables: Compliance.FormFactorTableModel[];

    assessorDepreciationControls = {};
    assessorIndexControls = {};
    defaultDepreciationControl: any;
    defaultIndexControl: any;

    showDefaultAssessor: boolean = false;
    showAllAvailableFactors: UntypedFormControl;

    async ngOnInit(): Promise<void> {
        this.isBusy = true;
        try {
            this.defaultAssessor = this.params.defaultAssessor;

            const assessors = this.params.assessors;
            this.assessors = assessors.filter(x => {
                const hasCompanyAssessorFactorTable = this.params.assessorFactorTables.filter((item: Compliance.AssessorFactorTableModel) => item.assessorId == x.assessorId && item.factorTables.length > 0).length > 0;

                return x.formRevisionYearAssessorId && (!x.isUsingDefaultTables || hasCompanyAssessorFactorTable);
            });

            // also need default assessors
            this.defaultAssessors = assessors.filter(x => x.formRevisionYearAssessorId && x.isUsingDefaultTables && !this.params.lockedAssessorAssignments[x.assessorId]);
            if (this.defaultAssessors.length > 0) {
                this.showDefaultAssessor = true;
            }

            this.defaultDepreciationTables = this.params.defaultFactorTables.filter((item: Compliance.AssessorFactorTableListItemModel) => item.tableType === Compliance.FactorTableTypeEnum.Depreciation);
            this.defaultDepreciationUsedTables = this.params.defaultFactorTables.filter((item: Compliance.AssessorFactorTableListItemModel) => item.tableType === Compliance.FactorTableTypeEnum.Depreciation && item.isUsed);
            this.defaultIndexTables = this.params.defaultFactorTables.filter((item: Compliance.AssessorFactorTableListItemModel) => item.tableType === Compliance.FactorTableTypeEnum.Index);
            this.defaultIndexUsedTables = this.params.defaultFactorTables.filter((item: Compliance.AssessorFactorTableListItemModel) => item.tableType === Compliance.FactorTableTypeEnum.Index && item.isUsed);

            this.assessors.forEach((item) => {
                this._assessorFactorTables[item.assessorId] = _.flatMap(_.filter(this.params.assessorFactorTables, x => !x.assessorId || x.assessorId === item.assessorId), x => x.factorTables);
            });

            // separate the depreciation tables and the index tables
            this.assessors.forEach(assessor => {
                this.assessorDepreciationTables[assessor.assessorId] =
                    this._assessorFactorTables[assessor.assessorId]
                        .filter(table => table.tableType === Compliance.FactorTableTypeEnum.Depreciation)
                        .concat(this.defaultDepreciationTables.filter(table => table.companyId === null && table.companyName === null));
                this.assessorDepreciationUsedTables[assessor.assessorId] = this._assessorFactorTables[assessor.assessorId].filter(table => table.tableType === Compliance.FactorTableTypeEnum.Depreciation && table.isUsed);
                this.assessorIndexTables[assessor.assessorId] =
                    this._assessorFactorTables[assessor.assessorId].filter(table => table.tableType ===
                        Compliance.FactorTableTypeEnum.Index);
                this.assessorIndexUsedTables[assessor.assessorId] =
                    this._assessorFactorTables[assessor.assessorId].filter(
                        table => table.tableType === Compliance.FactorTableTypeEnum.Index && table.isUsed);
            });

            let defaultDepreciationValue = null;
            const defaultFactorTable = this.params.defaultFactorTables.find((dft: Compliance.AssessorFactorTableListItemModel) => dft.isDefaultForSchedule);
            if (defaultFactorTable) {
                if (defaultFactorTable.isLockedForSchedule) {
                    defaultDepreciationValue = { value: defaultFactorTable.factorTableId, disabled: true }
                } else {
                    defaultDepreciationValue = defaultFactorTable.factorTableId;
                }
            }

            const formGroupMap = {};

            this.defaultDepreciationControl = this._formBuilder.control(defaultDepreciationValue, [Validators.required]);
            this.defaultIndexControl = this._formBuilder.control(null);

            // create form controls and define form
            this.showAllAvailableFactors = this._formBuilder.control(true);
            formGroupMap['showAvailable'] = this.showAllAvailableFactors;
            const depControls = this.assessors.map(x => {
                let controlValue = null;

                const assessorDepreciationTables = this.assessorDepreciationTables[x.assessorId];
                if (assessorDepreciationTables) {
                    const defaultTable: Compliance.AssessorFactorTableListItemModel =
                        assessorDepreciationTables.find(adt => adt.isDefaultForSchedule);
                    if (defaultTable) {
                        if (defaultTable.isLockedForSchedule) {
                            controlValue = { value: defaultTable.factorTableId, disabled: true };
                        } else {
                            controlValue = defaultTable.factorTableId;
                        }
                    }
                }

                const control = this._formBuilder.control(controlValue, [Validators.required]);
                this.assessorDepreciationControls[x.assessorId] = control;
                formGroupMap[`dep${x.assessorId}`] = control;
                return control;
            });

            this.addUnlistedOptionsToShortlist();

            [this.showAllAvailableFactors].concat(depControls);

            this.assessors.map(x => {
                const control = this._formBuilder.control(null);
                this.assessorIndexControls[x.assessorId] = control;
                formGroupMap[`index${x.assessorId}`] = control;
                return control;
            });

            if (this.showDefaultAssessor) {
                formGroupMap['defDep'] = this.defaultDepreciationControl;
                formGroupMap['defIndex'] = this.defaultIndexControl;
            }

            this.form = this._formBuilder.group(formGroupMap);

            this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(x => this._updateDropdownState(x));
            this._updateDropdownState(this.form.value);
        }
        finally {
            this.isBusy = false;
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    submit(): void {
        this.result = {
            selection: this.params.lockedAssessorAssignments || {}
        };

        this.assessors.forEach(x => {
            this.result.selection[x.assessorId] = {
                depreciationTableId: this.assessorDepreciationControls[x.assessorId].value,
                indexTableId: this.assessorIndexControls[x.assessorId].value
            }
        });

        if (this.showDefaultAssessor) {
            this.defaultAssessors.forEach(x => {
                if (this.defaultDepreciationControl.value || this.defaultIndexControl.value) {
                    this.result.selection[x.assessorId] = {
                        depreciationTableId: this.defaultDepreciationControl.value,
                        indexTableId: this.defaultIndexControl.value
                    }
                }
            });
        }

        this._modalRef.hide();
    }

    optionIdentify(index, factorTable): number {
        return factorTable.factorTableId;
    }

    compareFn(obj1, obj2): boolean {
        return obj1 === obj2;
    }

    switchFactorList(event: any): void {
        this.addUnlistedOptionsToShortlist();
    }

    addUnlistedOptionsToShortlist(): void {
        const defaultControl: UntypedFormControl = this.defaultDepreciationControl;
        if (this.showDefaultAssessor && defaultControl.value) {
            if (!this.defaultDepreciationUsedTables.find((table: Compliance.AssessorFactorTableListItemModel) => table.factorTableId === defaultControl.value)) {
                // add to table
                const table = this.defaultDepreciationTables.find((table: Compliance.AssessorFactorTableListItemModel) => table.factorTableId === defaultControl.value);
                if (table) {
                    this.defaultDepreciationUsedTables.push(table);
                }
            }
        }
        this.assessors.forEach((assessor: Compliance.FormRevisionAssessorModel) => {
            const control: UntypedFormControl = this.assessorDepreciationControls[assessor.assessorId];
            if (control.value) {
                if (!this.assessorDepreciationUsedTables[assessor.assessorId].find(
                    (table: Compliance.AssessorFactorTableListItemModel) => table.factorTableId === control.value)) {
                    // add to table
                    const table = this.assessorDepreciationTables[assessor.assessorId].find(
                        (table: Compliance.AssessorFactorTableListItemModel) => table.factorTableId === control.value);
                    if (table) {
                        this.assessorDepreciationUsedTables[assessor.assessorId].push(table);
                    }
                }
            }
        });
    }

    cancel(): void {
        this._modalRef.hide();
    }

    private _updateDropdownState(formControlValues: any): void {
        if (this.assessors.some(y => formControlValues[`index${y.assessorId}`] || formControlValues[`dep${y.assessorId}`])) {
            this._toggleDefaultOptions(false);
        } else if (formControlValues.defDep || formControlValues.defIndex) {
            this._toggleAssessorOptions(false);
        } else {
            this._toggleDefaultOptions(true);
            this._toggleAssessorOptions(true);
        }
    }

    private _toggleAssessorOptions(enable: boolean): void {
        this.assessors.forEach(x => {
            const dep = this.form.get(`dep${x.assessorId}`);
            const index = this.form.get(`index${x.assessorId}`)
            if (enable) {
                if (dep.disabled) {
                    dep.enable();
                }
                if (index.disabled) {
                    index.enable();
                }
            } else {
                if (dep.enabled) {
                    dep.disable();
                }
                if (index.enabled) {
                    index.disable();
                }
            }
        })
    }

    private _toggleDefaultOptions(enable: boolean): void {
        const defDep = this.form.get(`defDep`);
        const defIndex = this.form.get('defIndex')

        if (defDep) {
            if (enable) {
                if (defDep.disabled) {
                    defDep.enable();
                }
                if (defIndex.disabled) {
                    defIndex.enable();
                }
            } else {
                if (defDep.enabled) {
                    defDep.disable();
                }
                if (defIndex.enabled) {
                    defIndex.disable();
                }
            }
        }
    }
}
