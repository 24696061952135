import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { FormService } from '../form.service';
import { Subscription } from 'rxjs';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { AgGridColumns } from '../../AgGrid/agGridColumns';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams } from '../../AgGrid/MultiSelectTracker';
import { AgGridFilterParams } from '../../AgGrid/agGridFilterParams';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'form-report-add',
    templateUrl: './formReportAdd.component.html',
    styleUrls: ['./formReportAdd.component.scss']
})
export class FormReportAddComponent implements OnInit, OnDestroy, IWeissmanModalComponent<Compliance.FormRevisionReportModel[], Compliance.FormRevisionReportModel[]> {
    constructor(
        private readonly _formService: FormService,
        private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _availableReports: Compliance.SavedSearchModelSelectable[] = null;

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.SavedSearchModelSelectable).savedSearchID)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    params: Compliance.FormRevisionReportModel[];
    result: Compliance.FormRevisionReportModel[];

    rowsSelected: boolean = false;

    ngOnInit(): void {
        const reports = _.filter(this._formService.allReports, (ar) => !_.some(this.params, (fr) => { return ar.savedSearchID === fr.savedSearchId; }));
        this._availableReports = _.map(reports, (r) => { return { ...r, isSelected: false } as Compliance.SavedSearchModelSelectable; });

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'savedSearchID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Name',
                field: 'searchName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._formService.getSavedSearchReportName(savedSearch);
                },
                filterValueGetter: params => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._formService.getSavedSearchReportName(savedSearch);
                }
            },
            {
                headerName: 'Type',
                field: 'isSystem',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._formService.getSavedSearchReportType(savedSearch);
                },
                filterValueGetter: params => {
                    const savedSearch = params.data as Core.SavedSearchModel;
                    return this._formService.getSavedSearchReportType(savedSearch);
                }
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        const rowIds = await this.gridTracker.getSelectedRowIds();
        this.result = _.map(_.filter(this._formService.allReports, (x) => _.some(rowIds, (y) => x.savedSearchID === y)), (x) => {
            return {
                formRevisionReportId: 0,
                formRevisionId: this._formService.formRevisionId,
                isFrequentlyUsed: true,
                isIncluded: false,
                savedSearchId: x.savedSearchID,
                savedSearchCategoryId: x.savedSearchCategoryID,
                sequence: x.sequence,
                name: x.searchName,
                variantName: x.variantName,
                isSystem: x.isSystemSearch,
                transferId: null
                } as Compliance.FormRevisionReportModel;
            });
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        this._gridApi.setRowData(this._availableReports);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const savedSearch = x.data as Core.SavedSearchModel;
                return savedSearch && savedSearch.savedSearchID;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
