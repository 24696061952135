import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

interface FormErrorTitles {
    modalTitle: string;
    label: string;
    warning: string;
}

interface ValidateFormError {
    assessor: string;
    forms: string[];
    showForms: boolean;
    descriptor: string;
}

interface ValidateFormAggregate {
    label: string;
    warning: string;
    type: Compliance.ReturnValidateFormResultEnum;
    errors: ValidateFormError[];
}

export interface ReturnFormsErrorResult {
    errorType: Compliance.ReturnValidateFormResultEnum;
    accepted: boolean;
}

@Component({
    selector: 'return-forms-error',
    templateUrl: './returnFormsError.component.html',
    styleUrls: ['./returnFormsError.component.scss']
})
export class ReturnFormsErrorComponent implements OnInit, IWeissmanModalComponent<Compliance.ReturnValidateFormsResultModel, ReturnFormsErrorResult> {
    constructor(private readonly _bsModalRef: BsModalRef) { }

    params: Compliance.ReturnValidateFormsResultModel;
    result: ReturnFormsErrorResult;
    modalTitle: string;
    showCancel: boolean;

    aggregate: ValidateFormAggregate;

    private errorType: Compliance.ReturnValidateFormResultEnum;
    private _titlesByErrorType: { [key in Compliance.ReturnValidateFormResultEnum]?: FormErrorTitles } = {
        [Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified]: {
            modalTitle: 'One or more forms have not been certified.',
            label: 'The following forms have not been certified',
            warning: 'By clicking OK you acknowledge that these forms are not yet certified and subject to change. Reported filing values may be impacted.'
        },
        [Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable]: {
            modalTitle: 'One or more forms are not available.',
            label: 'Forms are not available for',
            warning: 'Remove filings or try again when the forms are available.'
        }
    }

    ngOnInit(): void {
        const errorTypes = [ ...new Set(this.params.formErrors.map(p => p.errorType)) ];

        if (errorTypes.length > 1) {
            this.errorType = errorTypes.includes(Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable)
                ? Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable
                : Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified;
        } else {
            this.errorType = errorTypes[0];
        }
        this.showCancel = this.errorType === Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified;

        const titles = this._titlesByErrorType[this.errorType];
        const map = this.params.formErrors.reduce((acc, p) => {
            const groupingValue = this._getErrorGroupingValue(p);
            const displayValue = this._getErrorDisplayValue(p);
            acc[groupingValue] = new Set((acc[groupingValue])
                ? [...acc[groupingValue], displayValue]
                : [displayValue]);
            return acc;
        }, {});

        this.aggregate = {
                label: titles.label,
                warning: titles.warning,
                type: this.errorType,
                errors: Object.keys(map).map((key) => ({
                    assessor: key,
                    forms: [...map[key]],
                    showForms: false,
                    descriptor: this._getListDescriptor(map[key].size)
                }))
        };

        this.modalTitle = this._titlesByErrorType[this.errorType].modalTitle;
    };

    /**
     * Toggle the forms associated with the assessor
     * @param error
     */
    toggleParcels(error: ValidateFormError): void {
        error.showForms = !error.showForms;
    }

    /**
     * Accept and close the modal
     */
    accept(): void {
        this.result = {
            errorType: this.errorType,
            accepted: true
        };
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _getErrorGroupingValue(model: Compliance.ReturnValidateFormResultModel) {
        switch (this.errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return (model.formRevisionName) ? model.formRevisionName : 'Form not available';
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
                return model.assessorName;
        }
    }

    private _getErrorDisplayValue(model: Compliance.ReturnValidateFormResultModel) {
        switch (this.errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return model.assessorName;
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
                return (model.formRevisionName) ? model.formRevisionName : 'Form not available';
        }
    }

    private _getListDescriptor(length: number): string {
        switch (this.errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return `${length} Assessors Not Certified`;
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
                return `${length} + ${(length > 1 ? ' Forms' : ' Form')}`;
        }
    }
}
