import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { CommentModalData } from '../../Comments/comments.service';

export interface ResponsibleEntityListGridActionCellRendererComponent extends ICellRendererParams{
    viewDetails: (params: ResponsibleEntityListGridActionCellRendererComponent) => void;
    canEdit: boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button class="btn flat-button primary-button icon-button grid-action-button" helpTooltip helpContentId="app.view" position="bottom" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>
        <span class="icon-indicator" *ngIf="entity">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="entity && entity.hasComments">
            <i class="fa fa-commenting"></i>
        </span>
        <span class="icon-indicator" *ngIf="entity && !entity.hasComments">
            <i class="fa fa-fw"></i>
        </span>
        <entity-comments [(hasComments)]="entity && entity.hasComments"
                         buttonClass="btn flat-button primary-button icon-button grid-action-button"
                         [modalData]="commentModalData"
                         helpTooltip
                         helpContentId="app.comments"
                         position="bottom">
        </entity-comments>
        `
})
export class ResponsibleEntityListGridActionCellRendererComponent implements ICellRendererAngularComp {
    entity: Core.ResponsibleEntityModel;
    commentModalData: CommentModalData;

    private _params: ResponsibleEntityListGridActionCellRendererComponent;

    agInit(params: ResponsibleEntityListGridActionCellRendererComponent): void {
        this._params = params;
        this.entity = params.data as Core.ResponsibleEntityModel;

        if (!this.entity) {
            return;
        }

        this.commentModalData = {
            entityID: this.entity.responsibleEntityId,
            entityTypeID: Core.EntityTypes.ResponsibleEntity,
            entityName: this.entity.name,
            parcelAcctNum: null,
            canEdit: this._params.canEdit,
            description: null,
            parcelID: null,
        } as CommentModalData;
    }

    refresh(params: any): boolean {
        return false;
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
