import { Component } from '@angular/core';
import { FilterUIModel } from "../Filters/filterUI.model";
import { AgGridFloatingFilterBase, WSFloatingFilterParams } from "./agGridFloatingFilterBase";

@Component({
    selector: 'text-floating-filter-component',
    template: `<input *ngIf="!(type === 'blank' || type === 'notBlank')"
                      type="text"
                      class="ag-floating-filter-input"
                      data-show-value="true"
                      data-popup-enabled="true"
                      [readOnly]="conditionApplied"
                      [(ngModel)]="value"
                      (ngModelChange)="valueChange(false)"/>
              <div *ngIf="type === 'blank' || type === 'notBlank'">
                  <span class="reset-value clickable"
                       (click)="clearFilter()">
                   <i class="fa fa-times"></i>
                  </span>
                  <span>{{type === 'blank' ? 'Blank' : 'Not Blank'}}</span>
              </div>
    `,
    styleUrls: ['./agGridFloatingFilter.scss'],
    host: {'class': 'ag-input-wrapper'}
})
export class AgGridTextFloatingFilterComponent extends AgGridFloatingFilterBase<WSFloatingFilterParams> {
    constructor() {
        super();
    }

    onParentModelChanged(parentModel: FilterUIModel): void {
        this.value = '';
        this.type = this._params.filterParams['defaultOption'];
        if (parentModel && parentModel.filterConditionType) {
            this.value = parentModel.filterValues.map(x => (x.filterType.displayKey === 'blank' || x.filterType.displayKey === 'notBlank') ? `${x.filterType.displayName}` : `${x.filterType.displayName} "${x.filterValue}"`)
                .join(parentModel.filterConditionType === Core.FilterConditionTypeEnum.And ? ' AND ' : ' OR ');
            this.conditionApplied = true;
        } else {
            this.value = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterValue;
            this.type = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterType.displayKey;
            this.conditionApplied = false;
        }
    };

}
