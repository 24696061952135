import {ICellEditorAngularComp} from "ag-grid-angular";
import {ICellEditorParams} from "ag-grid-community";
import {Component, ViewChild} from "@angular/core";
import { IncomeStatementCategorySelectorComponent } from '../../../../../../Valuation/Income-Statement/Income-Statement-Category-Selector/incomeStatementCategorySelector.component';

export interface EntityImportEditorIncomeStatementCategorySelectorCellEditorParams extends ICellEditorParams {
    getGlAccountTypeId(params: EntityImportEditorIncomeStatementCategorySelectorCellEditorParams): Promise<number>;
}

@Component({
    selector: 'entity-import-editor-income-statement-category-selector',
    template: `
            <income-statement-category-selector #incomeStatementCategorySelector
                [isRequiredField]="false" 
                [incomeStatementCategoryName]="incomeStatementCategoryName"
                [glAccountTypeId]="glAccountTypeId"
                [isDisabled]="!glAccountTypeId"
                (incomeStatementCategoryChange)="onIncomeStatementCategoryChanged($event)">
            </income-statement-category-selector>
            `
})
export class EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent implements ICellEditorAngularComp {
    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.incomeStatementCategoryName;
    }

    isCancelAfterEnd(): boolean {
        if (this.incomeStatementCategorySelector.incomeStatementCategoryName && !this.incomeStatementCategorySelector.incomeStatementCategoryFilter){
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    incomeStatementCategoryName: string;
    glAccountTypeId: number;

    @ViewChild("incomeStatementCategorySelector") incomeStatementCategorySelector: IncomeStatementCategorySelectorComponent;

    private _params: EntityImportEditorIncomeStatementCategorySelectorCellEditorParams;

    async agInit(params: EntityImportEditorIncomeStatementCategorySelectorCellEditorParams): Promise<void> {
        this._params = params;
        this.incomeStatementCategoryName = params.value;
        this.glAccountTypeId = await params.getGlAccountTypeId(params);
    }

    onIncomeStatementCategoryChanged(incomeStatementCategory: Core.IncomeStatementCategoryModel) {
        this.incomeStatementCategoryName = incomeStatementCategory ? incomeStatementCategory.name : null;
        this._params.node.setDataValue(this._params.column.getColId(), incomeStatementCategory ? incomeStatementCategory.name : null);
    }
}
