import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    SecurityContext,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { QuillModules } from 'ngx-quill';
import { ContentChange } from 'ngx-quill/lib/quill-editor.component';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { AppealRecommendationRepository } from '../appealRecommendation.repository';
import { lastValueFrom, Observable, of, Subscriber } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HelpContentComponentConfig, HelpService } from '../../UI-Lib/Help-Tooltip';
import { APPEAL_RECOMMENDATION_HELP } from './appealRecommendationTemplate.component.help';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap } from 'rxjs/operators';
import { DownloadFileService } from '../../Common/Download/downloadFileService';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { CommentsModalService } from '../../Comments/commentsModal.service';
import { CommentModalData } from '../../Comments/comments.service';
import { CommentTypeNames, EntityTypeIds } from '../../constants.new';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from '../../UI-Lib/Utilities';
import {
    AppealRecommendationTemplateLastSentHelpComponent,
    AppealRecommendationTemplateLastSentHelpComponentParams
} from './appealRecommendationTemplateLastSentHelp.component';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AttachmentModalComponent } from '../../Attachment/Modal/attachmentModal.component';
import { CommentDateTimeFormat } from '../../UI-Lib/Pipes';
import { UserSettingsService } from '../../Account/userSettings.service';
import {
    AppealRecommendationTemplateDetailsComponent,
    AppealRecommendationTemplateDetailsParams,
} from './Appeal-Recommendation-Template-Details/appealRecommendationTemplateDetails.component';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import AttachmentTypes = Core.AttachmentTypes;
import AttachmentCategories = Core.AttachmentCategories;

declare const $: any;

enum PanelView {
    Options,
    Attachments
}

enum AnalyticsEvent {
    ClickShowAcquisitionInfo,
    ClickShowYearBuilt,
    ClickShowAppealDeadlines,
    ClickShowAssessments,
    ClickShowMetrics,
    ClickShowInitialValue,
    ClickShowTargetValue,
    ClickShowLatestValue,
    ClickShowTaxDetails,
    ClickShowSavings,
    ClickEmbedChart,
    ClickAttachChart
}

export class TemplateFieldDTO {
    name: string;
    validInSubject: boolean;
}

export interface VisibilityModel {
    name: string;
    isDefault: boolean;
    isShared: boolean;
    description: string;
}

export interface AppealRecommendationEmailTemplateListItemModel extends Core.AppealRecommendationEmailTemplateModel {
    canEdit: boolean;
    visibilityItem: VisibilityModel;
}

@Component({
    templateUrl: './appealRecommendationTemplate.component.html',
    selector: 'appeal-recommendation-template',
    styleUrls: ['./appealRecommendationTemplate.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppealRecommendationTemplateComponent implements OnInit {

    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _appealRecommendationRepository: AppealRecommendationRepository,
        private readonly _sanitizer: DomSanitizer,
        private readonly _helpService: HelpService,
        private readonly _downloadFileService: DownloadFileService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _commentsModalService: CommentsModalService,
        private readonly _toastsManager: ToastrService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _modalService: WeissmanModalService,
        private readonly _commentDateTimePipe: CommentDateTimeFormat,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _cdRef: ChangeDetectorRef,
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _restrictService: RestrictService,
        private readonly _messageModalService: MessageModalService,
    ) {
    }

    @ViewChild('optionsPanel1') set optionsPanel1(p: ElementRef) {
        this._optionsPanel1 = p;
        this._setConsistentPanelWidth();
    }
    @ViewChild('optionsPanel2') set optionsPanel2(p: ElementRef) {
        this._optionsPanel2 = p;
        this._setConsistentPanelWidth();
    }

    panelSize = null;
    taskIds: number[];
    instanceId: number;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    editorInstance: QuillModules;
    emailTemplateSubjectFields: TemplateFieldDTO[];
    emailTemplateBodyFields: TemplateFieldDTO[];
    analyticsEvent = AnalyticsEvent;
    editing: boolean = false;
    initialized: boolean = false;
    selectedView: PanelView = PanelView.Options;
    sites: Core.AppealRecommendationSiteInfoModel[];
    appealRecommendationInfo: Core.AppealRecommendationTemplateModel;
    emailBody: SafeHtml;
    toFilter: string = '';
    ccFilter: string ='';
    lastSent: string;
    attachments: Core.AppealRecommendationAttachmentModel[] = [];
    canIncludePDFAsAttachment: boolean = false;
    savedSettings: Core.AppealRecommendationTemplateOptionsModel = {
        includeSiteComment: true,
        showPriorYear: true,
        showYearBuilt: false,
        showAcquisitionInfo: true,
        showAppealDeadlines: true,
        showAssessments: Core.PsrShowAssessmentsEnum.FMV,
        showMetrics: Core.PsrShowMetrics.MostApplicableBasedOnSiteClass,
        showInitialValue: Core.PsrShowValueEnum.YesNoMetrics,
        showTargetValue: Core.PsrShowValueEnum.YesNoMetrics,
        showLatestValue: Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange,
        showTaxDetails: Core.PsrShowTaxDetails.None,
        showSavings: Core.PsrShowSavings.No,
        embedInEmail: true,
        attachChart: Core.AppealRecommendationTemplateAttachChartOptionEnum.No
    };
    sentInfoTooltip: HelpContentComponentConfig<AppealRecommendationTemplateLastSentHelpComponent, AppealRecommendationTemplateLastSentHelpComponentParams> = {
        component: AppealRecommendationTemplateLastSentHelpComponent,
        componentParams: {
            sentInfo: null,
            totalParcelsCount: -1
        }
    };
    showAssessmentOptions: Compliance.NameValuePair<Core.PsrShowAssessmentsEnum>[] = [
        { name: 'FMV', value: Core.PsrShowAssessmentsEnum.FMV },
        { name: 'Alternate', value: Core.PsrShowAssessmentsEnum.Alternate },
        { name: 'FMV and Alternate', value: Core.PsrShowAssessmentsEnum.FMVAndAlternate }];
    showMetricOptions: Compliance.NameValuePair<Core.PsrShowMetrics>[] = [
        { name: 'None', value: Core.PsrShowMetrics.None },
        { name: 'Most applicable based on site class', value: Core.PsrShowMetrics.MostApplicableBasedOnSiteClass },
        { name: 'All present', value: Core.PsrShowMetrics.AllPresent },
    ];
    showInitialValueOptions: Compliance.NameValuePair<Core.PsrShowValueEnum>[] = [
        { name: 'No', value: Core.PsrShowValueEnum.No },
        { name: 'Yes (Incl. Metrics)', value: Core.PsrShowValueEnum.YesInclMetrics },
        { name: 'Yes (No Metrics)', value: Core.PsrShowValueEnum.YesNoMetrics },
    ];
    showTargetValueOptions: Compliance.NameValuePair<Core.PsrShowValueEnum>[] = [
        { name: 'No', value: Core.PsrShowValueEnum.No },
        { name: 'Yes (Incl. Metrics)', value: Core.PsrShowValueEnum.YesInclMetrics },
        { name: 'Yes (No Metrics)', value: Core.PsrShowValueEnum.YesNoMetrics },
    ];
    showLatestValueOptions: Compliance.NameValuePair<Core.PsrShowLatestValueEnum>[] = [
        { name: 'Yes (Incl. Metrics and YOY % Change)', value: Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange },
        { name: 'Yes (Incl. Metrics)', value: Core.PsrShowLatestValueEnum.YesInclMetrics },
        { name: 'Yes (Incl. YOY % Change )', value: Core.PsrShowLatestValueEnum.YesInclYOYChange },
        { name: 'Yes (No Metrics or YOY % Change)', value: Core.PsrShowLatestValueEnum.YesNoMetricsOrYOYChange },
    ];
    showTaxDetailsOptions: Compliance.NameValuePair<Core.PsrShowTaxDetails>[] = [
        { name: 'None', value: Core.PsrShowTaxDetails.None },
        { name: 'Latest Taxes (Incl. Metrics)', value: Core.PsrShowTaxDetails.LatestTaxesInclMetrics },
        { name: 'Latest Taxes (No Metrics)', value: Core.PsrShowTaxDetails.LatestTaxesNoMetrics },
        { name: 'All Taxes (Incl. Metrics)', value: Core.PsrShowTaxDetails.AllTaxesInclMetrics },
        { name: 'All Taxes (No Metrics)', value: Core.PsrShowTaxDetails.AllTaxesNoMetrics },
    ];
    showSavingOptions: Compliance.NameValuePair<Core.PsrShowSavings>[] = [
        { name: 'Received and Projected', value: Core.PsrShowSavings.ReceivedAndProjected },
        { name: 'Received Only', value: Core.PsrShowSavings.ReceivedOnly },
        { name: 'Projected Only', value: Core.PsrShowSavings.ProjectedOnly },
        { name: 'No', value: Core.PsrShowSavings.No },
    ];
    embedChartOptions: Compliance.NameValuePair<boolean>[] = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ];
    attachChartOptions: any[] = [
        { name: 'No', value: Core.AppealRecommendationTemplateAttachChartOptionEnum.No, isDisabled: false, title: '' },
        { name: 'PDF', value: Core.AppealRecommendationTemplateAttachChartOptionEnum.PDF, isDisabled: false, title: '' },
        { name: 'Excel', value: Core.AppealRecommendationTemplateAttachChartOptionEnum.Excel, isDisabled: false, title: '' }
    ];
    selectedTemplate: AppealRecommendationEmailTemplateListItemModel;
    templates: AppealRecommendationEmailTemplateListItemModel[];
    enableAppealRecommendationEmailTemplate: boolean = false;

    to$: Observable<Core.AppealRecipientModel[]> = new Observable((observer: Subscriber<string>) => observer.next(this.toFilter))
        .pipe(mergeMap((token) => this._filterRecipients(token)));

    cc$: Observable<Core.AppealRecipientModel[]> = new Observable((observer: Subscriber<string>) => observer.next(this.ccFilter))
        .pipe(mergeMap((token) => this._filterRecipients(token)));

    protected readonly PanelView = PanelView;
    private _optionsPanel1: ElementRef;
    private _optionsPanel2: ElementRef;
    private _availableRecipients: Core.AppealRecipientModel[];
    private _appealRecommendationInfo: Core.AppealRecommendationTemplateModel;
    private _savedSettings: Core.AppealRecommendationTemplateOptionsModel;
    private _initialAppealRecommendationInfo: Core.AppealRecommendationTemplateModel;
    private _settingName: string = 'Selected-Options';
    private _savedUserSettings: any;
    private _attachments: Core.AppealRecommendationAttachmentModel[] = [];
    private _siteDivisorDescriptors: System.Collections.Generic.KeyValuePair<number, number>[] = [];
    private _selectedTemplateId: number;
    private _hasEditSystemSearchPermission: boolean;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(APPEAL_RECOMMENDATION_HELP);
        this.enableAppealRecommendationEmailTemplate = this._featureFlagService.featureFlags.enableAppealRecommendationEmailTemplate;
        this._hasEditSystemSearchPermission = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);

        try {
            if (this.enableAppealRecommendationEmailTemplate) {
                const templates = await lastValueFrom(this._appealRecommendationRepository.getTemplatesAsync(this.taskIds));

                this.templates = templates
                    .map(x => this._convertToListItem(x));
                this._sortSavedTemplates();
            }

            await this._loadSettings();

            const model: Core.AppealRecommendationTemplateRefreshModel = {
                taskIds: this.taskIds,
                emailSubjectTemplate: null,
                emailBodyTemplate: null,
                showPriorYear: this.savedSettings.showPriorYear,
                embedInEmail: this.savedSettings.embedInEmail,
                includeSiteComment: this.savedSettings.includeSiteComment,
                recipientFirstName: '',
                showAssessments: this.savedSettings.showAssessments,
                showMetrics: this.savedSettings.showMetrics,
                showInitialValue: this.savedSettings.showInitialValue,
                showTargetValue: this.savedSettings.showTargetValue,
                showLatestValue: this.savedSettings.showLatestValue,
                showTaxDetails: this.savedSettings.showTaxDetails,
                showSavings: this.savedSettings.showSavings,
                showYearBuilt: this.savedSettings.showYearBuilt,
                showAcquisitionInfo: this.savedSettings.showAcquisitionInfo,
                showAppealDeadlines: this.savedSettings.showAppealDeadlines,
                attachChart: this.savedSettings.attachChart
            };

            if (this.enableAppealRecommendationEmailTemplate) {
                model.emailSubjectTemplate = this.selectedTemplate.emailSubjectTemplate;
                model.emailBodyTemplate = this.selectedTemplate.emailBodyTemplate;
            }

            const requests = await Promise.all([
                lastValueFrom(this._appealRecommendationRepository.getTemplate(model)),
                lastValueFrom(this._appealRecommendationRepository.getFields()),
                lastValueFrom(this._appealRecommendationRepository.getAvailableRecipients(this.taskIds))
            ]);

            this.appealRecommendationInfo = requests[0];
            this._initialAppealRecommendationInfo = {... this.appealRecommendationInfo};
            this._siteDivisorDescriptors = this.appealRecommendationInfo.siteDivisors;

            let fields = requests[1];

            if (this.appealRecommendationInfo.hasMultipleSites) {
                fields = fields.filter(x => x.name !== 'CompanyName' && x.name !== 'SiteName');
            }

            if (this.appealRecommendationInfo.hasMultipleAssessors) {
                fields = fields.filter(x => x.name !== 'Jurisdiction' && x.name !== 'AppealDeadline');
            }

            this.emailTemplateSubjectFields = fields.filter(x => x.validInSubject);
            this.emailTemplateBodyFields = fields;

            this._availableRecipients = requests[2];

            this.sites = this.appealRecommendationInfo.sites
                .sort((x, y) => x.siteName.localeCompare(y.siteName));

            this.emailBody = this._sanitizer.sanitize(SecurityContext.HTML, this.appealRecommendationInfo.emailBody);

            this.toFilter = this.appealRecommendationInfo.to.name;

            if (this.appealRecommendationInfo.sentInfo.length !== 0) {
                const lastSentInfo = this.appealRecommendationInfo.sentInfo
                    .sort((a, b) => b.sentDate.getTime() - a.sentDate.getTime())
                    .at(0);

                if (lastSentInfo) {
                    this.lastSent = `${lastSentInfo.userLastName}, ${lastSentInfo.userFirstName} ${this._commentDateTimePipe.transform(lastSentInfo.sentDate)}`;
                }
                this.sentInfoTooltip.componentParams.sentInfo = this.appealRecommendationInfo.sentInfo;
                this.sentInfoTooltip.componentParams.totalParcelsCount = this.appealRecommendationInfo.parcelsCount;
            }

            this.settingChanged();
        } finally {
            this.initialized = true;
        }
    }

    async close(): Promise<void> {
        let hasNotSavedChanges: boolean = JSON.stringify(this._initialAppealRecommendationInfo.emailSubjectTemplate) !== JSON.stringify(this.appealRecommendationInfo.emailSubjectTemplate) ||
            JSON.stringify(this._initialAppealRecommendationInfo.emailBodyTemplate) !== JSON.stringify(this.appealRecommendationInfo.emailBodyTemplate);

        if (!hasNotSavedChanges && this.enableAppealRecommendationEmailTemplate) {
            hasNotSavedChanges = this.savedSettings.showPriorYear !== this.selectedTemplate.showPriorYear ||
                this.savedSettings.showYearBuilt !== this.selectedTemplate.showYearBuilt ||
                this.savedSettings.showAcquisitionInfo !== this.selectedTemplate.showAcquisitionInfo ||
                this.savedSettings.showAppealDeadlines !== this.selectedTemplate.showAppealDeadlines ||
                this.savedSettings.showAssessments !== this.selectedTemplate.showAssessments ||
                this.savedSettings.showMetrics !== this.selectedTemplate.showMetrics ||
                this.savedSettings.showInitialValue !== this.selectedTemplate.showInitialValue ||
                this.savedSettings.showTargetValue !== this.selectedTemplate.showTargetValue ||
                this.savedSettings.showLatestValue !== this.selectedTemplate.showLatestValue ||
                this.savedSettings.showTaxDetails !== this.selectedTemplate.showTaxDetails ||
                this.savedSettings.showSavings !== this.selectedTemplate.showSavings ||
                this.savedSettings.attachChart !== this.selectedTemplate.attachChart ||
                this.savedSettings.embedInEmail !== this.selectedTemplate.embedInEmail;
        }

        if (hasNotSavedChanges) {
            if ((await this._messageBoxService.open({
                message: 'Changes have not been saved to a template and will be lost. Are you sure you would like to close before saving to a template, downloading or sending?',
                buttons: MessageBoxButtons.YesNo
            })) === MessageBoxResult.Yes) {
                this._bsModalRef.hide();
            }
        } else {
            this._bsModalRef.hide();
        }
    }

    async refresh(): Promise<void> {
        const busyMsgId = 'refreshing';
        this.busyIndicatorMessageManager.add('Refreshing', busyMsgId);

        try {
            const model: Core.AppealRecommendationTemplateRefreshModel = {
                taskIds: this.taskIds,
                emailSubjectTemplate: this.appealRecommendationInfo.emailSubjectTemplate,
                emailBodyTemplate: this.appealRecommendationInfo.emailBodyTemplate,
                showPriorYear: this.savedSettings.showPriorYear,
                embedInEmail: this.savedSettings.embedInEmail,
                includeSiteComment: this.savedSettings.includeSiteComment,
                recipientFirstName: this.appealRecommendationInfo.to.name.split(' ')[0],
                showAssessments: this.savedSettings.showAssessments,
                showMetrics: this.savedSettings.showMetrics,
                showInitialValue: this.savedSettings.showInitialValue,
                showTargetValue: this.savedSettings.showTargetValue,
                showLatestValue: this.savedSettings.showLatestValue,
                showTaxDetails: this.savedSettings.showTaxDetails,
                showSavings: this.savedSettings.showSavings,
                showYearBuilt: this.savedSettings.showYearBuilt,
                showAcquisitionInfo: this.savedSettings.showAcquisitionInfo,
                showAppealDeadlines: this.savedSettings.showAppealDeadlines,
                attachChart: this.savedSettings.attachChart
            };

            const result = await lastValueFrom(this._appealRecommendationRepository.refresh(model));

            this.appealRecommendationInfo.emailSubject = result.emailSubject;
            this.appealRecommendationInfo.emailBody = result.emailBody;
            this.emailBody = this._sanitizer.sanitize(SecurityContext.HTML, this.appealRecommendationInfo.emailBody);
            this._siteDivisorDescriptors = result.siteDivisors;
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    edit() {
        this._appealRecommendationInfo = {... this.appealRecommendationInfo};
        this._savedSettings = {... this.savedSettings};
        this._attachments = this.attachments.slice(0);
        this.editing = true;
        this._productAnalyticsService.logEvent('edit-modal');

        if (this.enableAppealRecommendationEmailTemplate) {
            this._selectedTemplateId = this.selectedTemplate.appealRecommendationEmailTemplateId;
        }
    }

    changeTab(tab: PanelView): void {
        this.selectedView = tab;
        this._productAnalyticsService.logEvent('click-settings-panel-tab', { settingsTab: tab === PanelView.Options ? 'Options' : 'Attachments' });
    }

    addBindingCreated(quillInstance: QuillModules): void {
        this.editorInstance = quillInstance;
    }

    insertFieldInSubject(field: TemplateFieldDTO): void {
        const templateSubject = $('#template-subject');
        const cursorPosStart = templateSubject.prop('selectionStart');
        const cursorPosEnd = templateSubject.prop('selectionEnd');
        const v = templateSubject.val();
        const textBefore = v.substring(0, cursorPosStart);
        const textAfter = v.substring(cursorPosEnd, v.length);
        const fieldName = `{{ ${  field.name  } }}`;

        templateSubject.val(textBefore + fieldName + textAfter);
        this.appealRecommendationInfo.emailSubjectTemplate = templateSubject.val();

        templateSubject.focus();

        // Analytics
        this._productAnalyticsService.logEvent('edit-subject-line', { subjectField: field.name });
    }

    insertFieldInBody(field: TemplateFieldDTO): void {
        const cursorPosition: number = this.editorInstance.getSelection(true).index;
        const fieldName: string = `{{ ${  field.name  } }}`;

        this.editorInstance.insertText(cursorPosition, fieldName);
        this._timer.setTimeout(() => this.editorInstance.setSelection(cursorPosition + fieldName.length), 1);

        // Analytics
        this._productAnalyticsService.logEvent('insert-field-body', { bodyField: field.name });
    }

    // Inserting field does not trigger binding change event, so we need to manually update the model
    contentChange(change: ContentChange): void {
        this.appealRecommendationInfo.emailBodyTemplate = change.html;
    }

    bodyBlur(): void {
        if (this._initialAppealRecommendationInfo.emailBodyTemplate !== this.appealRecommendationInfo.emailBodyTemplate) {
            this._productAnalyticsService.logEvent('insert-text-body');
        }
    }

    async downloadEmail(): Promise<void> {
        this._productAnalyticsService.logEvent('download-email');

        const busyMsgId = 'downloading';
        this.busyIndicatorMessageManager.add('Generating the .eml file', busyMsgId);

        try {
            const model: Core.AppealRecommendationEmailModel = {
                emailSubject: this.appealRecommendationInfo.emailSubject,
                emailBody: this.appealRecommendationInfo.emailBody,
                toId: this.appealRecommendationInfo.to.value,
                ccId: this.appealRecommendationInfo.cc ? this.appealRecommendationInfo.cc.value : null,
                taskIds: this.taskIds,
                options: this.savedSettings,
                attachments: this.attachments
            };

            const response = await lastValueFrom(this._appealRecommendationRepository.download(model));
            await this._downloadFileService.downloadResponse(response);

            this._initialAppealRecommendationInfo = this.appealRecommendationInfo;

            await this._saveSettings();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    async sendEmail(): Promise<void> {
        this._productAnalyticsService.logEvent('send-email');

        let recipients = this.appealRecommendationInfo.to.name;

        if (this.appealRecommendationInfo.cc) {
            recipients += ` and ${this.appealRecommendationInfo.cc.name}`;
        }

        if ((await this._messageBoxService.open({
            message: `An appeal recommendation email will be sent to ${recipients}. Are you sure?`,
            buttons: MessageBoxButtons.YesNo
        })) === MessageBoxResult.Yes) {
            const busyMsgId = 'sending';
            this.busyIndicatorMessageManager.add('Sending email', busyMsgId);

            try {
                const model: Core.AppealRecommendationEmailModel = {
                    emailSubject: this.appealRecommendationInfo.emailSubject,
                    emailBody: this.appealRecommendationInfo.emailBody,
                    toId: this.appealRecommendationInfo.to.value,
                    ccId: this.appealRecommendationInfo.cc ? this.appealRecommendationInfo.cc.value : null,
                    taskIds: this.taskIds,
                    options: this.savedSettings,
                    attachments: this.attachments
                };

                await this._appealRecommendationRepository.send(model);

                await this._saveSettings();

                this._bsModalRef.hide();
                this._toastsManager.success('Your email has been sent');
            } finally {
                this.busyIndicatorMessageManager.remove(busyMsgId);
            }
        }

    }

    getCheckboxHelpContentId(): string {
        return this.editing
            ? null
            : 'appeal-recommendation.checkbox.disabled';
    }

    onToBlur(): void {
        this._selectTo(this.toFilter);
    }

    onToSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const userInfo = match.item as Core.AppealRecipientModel;
            this.toFilter = userInfo.name;
            this.appealRecommendationInfo.to = {
                name: userInfo.name,
                value: userInfo.contactId
            };

            // Analytics
            this._productAnalyticsService.logEvent('edit-recipient-one', { recipientRoleOne: userInfo.role });
        }
    }

    onCCBlur(): void {
        this._selectCC(this.ccFilter);
    }

    onCCSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const userInfo = match.item as Core.AppealRecipientModel;
            this.ccFilter = userInfo.name;
            this.appealRecommendationInfo.cc = {
                name: userInfo.name,
                value: userInfo.contactId
            };

            // Analytics
            this._productAnalyticsService.logEvent('edit-recipient-two', { recipientRoleTwo: userInfo.role });
        }
    }

    async save(refreshTemplate: boolean = true): Promise<void> {
        await this.refresh();
        this.editing = false;
        this._productAnalyticsService.logEvent('save-changes');
    }

    cancel(): void {
        this.appealRecommendationInfo = this._appealRecommendationInfo;
        this.savedSettings = this._savedSettings;

        if (this.enableAppealRecommendationEmailTemplate) {
            this.selectedTemplate = this.templates.find(x => x.appealRecommendationEmailTemplateId === this._selectedTemplateId);
            if (!this.selectedTemplate) {
                this.selectedTemplate = this.templates[0];
            }
        }

        this.toFilter = this.appealRecommendationInfo.to.name;
        this.ccFilter = null;
        this.editing = false;
        this.attachments = this._attachments;
        this._productAnalyticsService.logEvent('cancel-changes');
    }

    async commentSiteChanged(siteId: number): Promise<void> {
        this._productAnalyticsService.logEvent('edit-site-comment');
        const modalData: CommentModalData = {
            canEdit: true,
            commentTypeID: CommentTypeNames['Appeal Recommendation'],
            entityTypeID: EntityTypeIds.SITE,
            entityID: siteId,
            description: `Site: ${this.sites.find(x => x.siteId === siteId).siteName}`,
            year: this.appealRecommendationInfo.taxYear.toString(),
            disableYearSelection: true,
            disableCommentTypeSelection: true
        };

        await this._commentsModalService.openCommentModal(modalData);
    }

    async attachmentSiteChanged(siteId: number): Promise<void> {
        this._productAnalyticsService.logEvent('click-include-site-attachments');

        const siteName = this.sites.find(x => x.siteId === siteId).siteName;

        const existingAttachment = this.attachments.find(x => x.siteId === siteId);

        const modalData: AttachmentModalData = {
            entityType: 'Site',
            parentId: null,
            parentType: null,
            entityName: siteName,
            entityDescription: null,
            belowParcelEntity: null,
            entityData: {
                typeId: EntityTypeIds.SITE,
                id: siteId,
                name: siteName
            },
            year: this.appealRecommendationInfo.taxYear,
            disableYears: true,
            singleYear: true,
            readOnly: false,
            startOnPhotos: false,
            disableCategories: true,
            attachmentCategoryId: AttachmentCategories.AppealDocs,
            attachmentTypeId: AttachmentTypes.Recommendation,
            showEmailAttachmentButton: true,
            selectedAttachmentId: existingAttachment && existingAttachment.attachmentId
        };

        const result = await this._modalService.showAsync(AttachmentModalComponent, modalData, 'modal-xl modal-no-max-width');

        if (result.selectedAttachmentId !== (existingAttachment && existingAttachment.attachmentId)) {
            const attachmentName = `${siteName}_${result.selectedAttachmentFileName}`;

            if (existingAttachment) {
                existingAttachment.attachmentId = result.selectedAttachmentId;
                existingAttachment.attachmentName = attachmentName;
            } else {
                this.attachments.push({
                    siteId: siteId,
                    attachmentId: result.selectedAttachmentId,
                    attachmentName: attachmentName
                });

                this.attachments.sort((x, y) => x.attachmentName.localeCompare(y.attachmentName));
            }
        }
    }

    get canSave(): boolean {
        return !!this.appealRecommendationInfo.to &&
            (this.appealRecommendationInfo.emailSubjectTemplate || '').trim().length !== 0;
    }

    emitAnalyticsEvent(eventName: string, eventProperties: any = null): void {
        this._productAnalyticsService.logEvent(eventName, eventProperties);
    }

    removeAttachment(attachment: Core.AppealRecommendationAttachmentModel) {
        const index = this.attachments.indexOf(attachment);
        this.attachments.splice(index, 1);
        this._productAnalyticsService.logEvent('click-remove-site-attachments');
    }

    getCanIncludeAsAttachmentHelpContentId(): string {
        return this.canIncludePDFAsAttachment
            ? this.getCheckboxHelpContentId()
            : 'appeal-recommendation.checkbox.selections-exceed-printable-area-width';
    }

    settingChanged(analyticsEvent?: AnalyticsEvent): void {
        this._checkIfExceedsPrintableArea();

        if (analyticsEvent !== undefined) {
            switch (analyticsEvent) {
                case AnalyticsEvent.ClickShowAcquisitionInfo:
                    this._productAnalyticsService.logEvent('click-show-acquisition-info', { showAcquisitionInfo: this.savedSettings.showAcquisitionInfo });
                    break;
                case AnalyticsEvent.ClickShowYearBuilt:
                    this._productAnalyticsService.logEvent('click-show-year-built', { showYearBuilt: this.savedSettings.showYearBuilt });
                    break;
                case AnalyticsEvent.ClickShowAppealDeadlines:
                    this._productAnalyticsService.logEvent('click-show-appeal-deadlines', { showAppealDeadlines: this.savedSettings.showAppealDeadlines });
                    break;
                case AnalyticsEvent.ClickShowAssessments:
                    this._productAnalyticsService.logEvent('click-show-assessments', {
                        assessmentOptions: this.showAssessmentOptions.find(x => x.value === this.savedSettings.showAssessments)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowMetrics:
                    this._productAnalyticsService.logEvent('click-show-metrics', {
                        metricsOptions: this.showMetricOptions.find(x => x.value === this.savedSettings.showMetrics)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowInitialValue:
                    this._productAnalyticsService.logEvent('click-show-initial-value', {
                        initialValueOptions: this.showInitialValueOptions.find(x => x.value === this.savedSettings.showInitialValue)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowTargetValue:
                    this._productAnalyticsService.logEvent('click-show-target-value', {
                        targetValueOptions: this.showTargetValueOptions.find(x => x.value === this.savedSettings.showTargetValue)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowLatestValue:
                    this._productAnalyticsService.logEvent('click-show-latest-value', {
                        latestValueOptions: this.showLatestValueOptions.find(x => x.value === this.savedSettings.showLatestValue)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowTaxDetails:
                    this._productAnalyticsService.logEvent('click-show-tax-details', {
                        taxDetailsOptions: this.showTaxDetailsOptions.find(x => x.value === this.savedSettings.showTaxDetails)?.name
                    });
                    break;
                case AnalyticsEvent.ClickShowSavings:
                    this._productAnalyticsService.logEvent('click-show-savings', {
                        savingsOptions: this.showSavingOptions.find(x => x.value === this.savedSettings.showSavings)?.name
                    });
                    break;
                case AnalyticsEvent.ClickEmbedChart:
                    this._productAnalyticsService.logEvent('embed-in-email', {
                        embedInEmail: this.savedSettings.embedInEmail
                    });
                    break;
                case AnalyticsEvent.ClickAttachChart:
                    this._productAnalyticsService.logEvent('include-as-attachment', {
                        includeInAttachment: this.savedSettings.attachChart.toString().toLowerCase()
                    });
                    break;
            }
        }
    }

    get editHelpContentId(): string {
        if (!this.selectedTemplate) {
            return 'appeal-recommendation-template-specification.no-template';
        }

        if (this.selectedTemplate.isDefault) {
            return 'appeal-recommendation-template-specification.edit-default';
        }

        if (this.selectedTemplate.isShared && !this._hasEditSystemSearchPermission) {
            return 'app.save-disabled';
        }

        return 'app.save';
    }

    get copyHelpContentId(): string {
        if (!this.selectedTemplate) {
            return 'appeal-recommendation-template-specification.no-template';
        }

        return 'appeal-recommendation-template-specification.copy';
    }

    get deleteHelpContentId(): string {
        if (!this.selectedTemplate) {
            return 'appeal-recommendation-template-specification.no-template';
        }

        if (this.selectedTemplate.isDefault) {
            return 'appeal-recommendation-template-specification.delete-default';
        }

        if (this.selectedTemplate.isShared && !this._hasEditSystemSearchPermission) {
            return 'app.delete-disabled';
        }

        return 'app.delete';
    }

    async editTemplate(copy: boolean): Promise<void> {
        const params: AppealRecommendationTemplateDetailsParams = {
            emailSubjectTemplate: this.appealRecommendationInfo.emailSubjectTemplate,
            emailBodyTemplate: this.appealRecommendationInfo.emailBodyTemplate,
            options: this.savedSettings,
            taskIds: this.taskIds,
            existingTemplates: this.templates,
            selectedTemplate: copy ? null : this.selectedTemplate
        };

        const result: Core.AppealRecommendationEmailTemplateModel = await this._modalService.showAsync(AppealRecommendationTemplateDetailsComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        if (copy) {
            this._productAnalyticsService.logEvent('update-custom-template');
            const savedTemplate = this._convertToListItem(result);

            this.templates.push(savedTemplate);
            this.selectedTemplate = savedTemplate;
        } else {
            this._productAnalyticsService.logEvent('save-custom-template');
            const updatedTemplateIndex = this.templates.findIndex(x => x.appealRecommendationEmailTemplateId === result.appealRecommendationEmailTemplateId);
            const updatedTemplate = {...this.templates[updatedTemplateIndex], ...result};
            this.templates[updatedTemplateIndex] = updatedTemplate;
            this.selectedTemplate = updatedTemplate;
        }

        this._initialAppealRecommendationInfo.emailSubjectTemplate = this.selectedTemplate.emailSubjectTemplate;
        this._initialAppealRecommendationInfo.emailBodyTemplate = this.selectedTemplate.emailBodyTemplate;

        this._sortSavedTemplates();
        await this.save();
    }

    async deleteTemplate(): Promise<void> {
        try {
            await this._messageModalService.confirm(
                `Template will be permanently deleted. Are you sure you wish to delete the template "${this.selectedTemplate.name}"?`,
                'Confirm Delete'
            );
        } catch (e) {
            return Promise.resolve();
        }

        const busyMsgId = 'deletingTemplate';
        this.busyIndicatorMessageManager.add('Deleting template', busyMsgId);

        const templateId = this.selectedTemplate.appealRecommendationEmailTemplateId;
        try {
            await lastValueFrom(this._appealRecommendationRepository.deleteTemplate(templateId));
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        this.selectedTemplate = this.templates[0];
        this._selectedTemplateId = this.selectedTemplate.appealRecommendationEmailTemplateId;
        await this.onTemplateChanged();
        const deletedTemplateIndex = this.templates.findIndex(x => x.appealRecommendationEmailTemplateId === templateId);
        this.templates.splice(deletedTemplateIndex, 1);
        this._productAnalyticsService.logEvent('delete-custom-template');
        this.editing = false;
    }

    async onTemplateChanged(userInitiated?: boolean): Promise<void> {
        if (userInitiated) {
            this._productAnalyticsService.logEvent('select-email-template');
        }
        if (this.appealRecommendationInfo) {
            this.appealRecommendationInfo.emailSubjectTemplate = this.selectedTemplate.emailSubjectTemplate;
            this.appealRecommendationInfo.emailBodyTemplate = this.selectedTemplate.emailBodyTemplate;
        }

        this.savedSettings.includeSiteComment = this.selectedTemplate.includeSiteComment;
        this.savedSettings.showPriorYear = this.selectedTemplate.showPriorYear;
        this.savedSettings.showYearBuilt = this.selectedTemplate.showYearBuilt;
        this.savedSettings.showAcquisitionInfo = this.selectedTemplate.showAcquisitionInfo;
        this.savedSettings.showAppealDeadlines = this.selectedTemplate.showAppealDeadlines;
        this.savedSettings.showAssessments = this.selectedTemplate.showAssessments;
        this.savedSettings.showMetrics = this.selectedTemplate.showMetrics;
        this.savedSettings.showInitialValue = this.selectedTemplate.showInitialValue;
        this.savedSettings.showTargetValue = this.selectedTemplate.showTargetValue;
        this.savedSettings.showLatestValue = this.selectedTemplate.showLatestValue;
        this.savedSettings.showTaxDetails = this.selectedTemplate.showTaxDetails;
        this.savedSettings.showSavings = this.selectedTemplate.showSavings;
        this.savedSettings.embedInEmail = this.selectedTemplate.embedInEmail;
        this.savedSettings.attachChart = this.selectedTemplate.attachChart;

        if (this.initialized) {
            await this.refresh();

            this._initialAppealRecommendationInfo = {...this.appealRecommendationInfo};
        }

        this._checkIfExceedsPrintableArea();
    }

    get canEditDelete(): boolean {
        return !this.selectedTemplate.isDefault && (!this.selectedTemplate.isShared ||
            this.selectedTemplate.isShared && this._hasEditSystemSearchPermission);
    }

    private _canIncludePDFAsAttachment(): boolean {
        const number = this._calculateNumberOfGridColumns();
        return number <= 11;
    }

    private _selectTo(filter: string): void {
        const userInfos = this._availableRecipients.filter(a => a.name === filter);

        if (userInfos && userInfos.length == 1) {
            this.toFilter = filter;
        } else {
            this.toFilter = null;
            this.appealRecommendationInfo.to = null;
        }
    }

    private _selectCC(filter: string): void {
        const userInfos = this._availableRecipients.filter(a => a.name === filter);

        if (userInfos && userInfos.length == 1) {
            this.ccFilter = filter;
        } else {
            this.ccFilter = null;
            this.appealRecommendationInfo.cc = null;
        }
    }

    private _filterRecipients(filter: string): Observable<Core.AppealRecipientModel[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }

        return of(
            this._availableRecipients
                .filter(x => x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                .sort((x, y) => x.name > y.name ? 1 : -1)
        );
    }

    private async _loadSettings() : Promise<void> {
        const settingsGroup = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AppealRecommendationTemplate);
        this._savedUserSettings = settingsGroup.find(x => x.name === this._settingName);

        if (this._savedUserSettings && this._savedUserSettings.value) {
            const savedTemplateId = +this._savedUserSettings.value;

            if (this.enableAppealRecommendationEmailTemplate) {
                if (!isNaN(savedTemplateId)) {
                    const savedTemplate = this.templates.find(x => x.appealRecommendationEmailTemplateId === savedTemplateId);

                    this.selectedTemplate = savedTemplate || this.templates[0];
                } else {
                    this.selectedTemplate = this.templates[0];
                }
                await this.onTemplateChanged();
            } else {
                this.savedSettings.showPriorYear = this._savedUserSettings.value.showPriorYear;
                this.savedSettings.showYearBuilt = this._savedUserSettings.value.showYearBuilt;
                this.savedSettings.showAcquisitionInfo = this._savedUserSettings.value.showAcquisitionInfo;
                this.savedSettings.showAppealDeadlines = this._savedUserSettings.value.showAppealDeadlines;
                this.savedSettings.showAssessments = this._savedUserSettings.value.showAssessments;
                this.savedSettings.showMetrics = this._savedUserSettings.value.showMetrics;
                this.savedSettings.showInitialValue = this._savedUserSettings.value.showInitialValue;
                this.savedSettings.showTargetValue = this._savedUserSettings.value.showTargetValue;
                this.savedSettings.showLatestValue = this._savedUserSettings.value.showLatestValue;
                this.savedSettings.showTaxDetails = this._savedUserSettings.value.showTaxDetails;
                this.savedSettings.showSavings = this._savedUserSettings.value.showSavings;
                this.savedSettings.embedInEmail = this._savedUserSettings.value.embedInEmail;
                this.savedSettings.attachChart = this._savedUserSettings.value.attachChart;
            }
        } else if (this.enableAppealRecommendationEmailTemplate) {
            this.selectedTemplate = this.templates[0];
            await this.onTemplateChanged();
        }

        this._checkIfExceedsPrintableArea();
    }

    private async _saveSettings() {
        if (!this._savedUserSettings) {
            this._savedUserSettings = {
                id: 0,
                name: this._settingName,
                groupId: Core.UserSettingGroup.AppealRecommendationTemplate,
                folderId: Core.UserSettingFolder.Default,
                value: {}
            };
        }

        this._savedUserSettings.value = this.enableAppealRecommendationEmailTemplate
            ? this.selectedTemplate.appealRecommendationEmailTemplateId
            : this.savedSettings;

        this._savedUserSettings = await this._userSettingsService.save(this._savedUserSettings);
    }

    private _setConsistentPanelWidth(): void {
        if (this._optionsPanel1?.nativeElement && this._optionsPanel2?.nativeElement) {
            const p1 = this._optionsPanel1.nativeElement.getBoundingClientRect();
            const p2 = this._optionsPanel2.nativeElement.getBoundingClientRect();
            this.panelSize = {
                width: `${p1.width > p2.width ? p1.width : p2.width}px`,
                height: `${p1.height > p2.height ? p1.height : p2.height}px`
            };
            this._cdRef.detectChanges();
        }
    }

    private _calculateNumberOfGridColumns(): number {
        let columnsCount: number = 1;

        if (this.savedSettings.showAssessments === Core.PsrShowAssessmentsEnum.FMV ||
            this.savedSettings.showAssessments === Core.PsrShowAssessmentsEnum.FMVAndAlternate) {
            if (this.savedSettings.showInitialValue !== Core.PsrShowValueEnum.No) {
                columnsCount++;
            }

            if (this.savedSettings.showInitialValue === Core.PsrShowValueEnum.YesInclMetrics) {
                if (this.savedSettings.showMetrics === Core.PsrShowMetrics.AllPresent) {
                    columnsCount += 3;
                } else if (this.savedSettings.showMetrics === Core.PsrShowMetrics.MostApplicableBasedOnSiteClass &&
                    this._siteDivisorDescriptors.some(x => x.value === 3 || x.value === 4 || x.value === 5)) {
                    columnsCount++;
                }
            }

            if (this.savedSettings.showTargetValue !== Core.PsrShowValueEnum.No) {
                columnsCount++;
            }

            if (this.savedSettings.showTargetValue === Core.PsrShowValueEnum.YesInclMetrics) {
                columnsCount += this._getMetricsColumnsCount();
            }

            columnsCount++;

            if (this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange ||
                this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetrics) {
                columnsCount += this._getMetricsColumnsCount();
            }

            if (this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange) {
                columnsCount++;
            }
        }

        if (this.savedSettings.showAssessments === Core.PsrShowAssessmentsEnum.Alternate ||
            this.savedSettings.showAssessments === Core.PsrShowAssessmentsEnum.FMVAndAlternate) {
            if (this.savedSettings.showInitialValue !== Core.PsrShowValueEnum.No) {
                columnsCount++;
            }

            if (this.savedSettings.showInitialValue === Core.PsrShowValueEnum.YesInclMetrics) {
                columnsCount += this._getMetricsColumnsCount();
            }

            columnsCount++;

            if (this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetrics ||
                this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange) {
                columnsCount += this._getMetricsColumnsCount();
            }

            if (this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclYOYChange ||
                this.savedSettings.showLatestValue === Core.PsrShowLatestValueEnum.YesInclMetricsAndYOYChange) {
                columnsCount++;
            }
        }

        if (this.savedSettings.showTaxDetails !== Core.PsrShowTaxDetails.None) {
            columnsCount++;

            if (this.savedSettings.showInitialValue !== Core.PsrShowValueEnum.No && (
                this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.AllTaxesInclMetrics ||
                this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.AllTaxesNoMetrics)) {
                columnsCount++;
            }

            if (this.savedSettings.showTargetValue !== Core.PsrShowValueEnum.No && (
                this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.AllTaxesInclMetrics ||
                this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.AllTaxesNoMetrics)) {
                columnsCount++;
            }

            columnsCount++;

            if (this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.LatestTaxesInclMetrics ||
                this.savedSettings.showTaxDetails === Core.PsrShowTaxDetails.AllTaxesNoMetrics) {
                columnsCount += this._getMetricsColumnsCount();
            }
        }

        columnsCount++;

        if (this.savedSettings.showSavings === Core.PsrShowSavings.ReceivedOnly ||
            this.savedSettings.showSavings === Core.PsrShowSavings.ReceivedAndProjected) {
            columnsCount++;
        }

        if (this.savedSettings.showSavings === Core.PsrShowSavings.ProjectedOnly ||
            this.savedSettings.showSavings === Core.PsrShowSavings.ReceivedAndProjected) {
            columnsCount++;
        }

        return columnsCount;
    }

    private _getMetricsColumnsCount() {
        let result = 0;

        if (this.savedSettings.showMetrics === Core.PsrShowMetrics.AllPresent) {
            result = 3;
        } else if (this.savedSettings.showMetrics === Core.PsrShowMetrics.MostApplicableBasedOnSiteClass &&
            this._siteDivisorDescriptors.some(x => x.value === 3 || x.value === 4 || x.value === 5)) {
            result++;
        }

        return result;
    }

    private _sortSavedTemplates(): void {
        const defaultTemplates = this.templates.filter(x => x.isDefault);
        defaultTemplates.sort((x, y) => x.name.localeCompare(y.name));

        const customTemplates = this.templates.filter(x => !x.isDefault);
        customTemplates.sort((x, y) => x.name.localeCompare(y.name));

        this.templates = defaultTemplates.concat(customTemplates);
    }

    private _convertToListItem(x: Core.AppealRecommendationEmailTemplateModel): AppealRecommendationEmailTemplateListItemModel {
        const result = {
            ... x,
            ... {
                visibilityItem: {
                    isShared: x.isShared,
                    isDefault: x.isDefault,
                    name: '',
                    description: ''
                },
                canEdit: false
            }
        };

        if (result.isDefault) {
            result.visibilityItem.name = 'Default';
            result.visibilityItem.description = 'Visible to everyone';
        } else if (result.isShared) {
            result.visibilityItem.name = 'Shared';
            result.visibilityItem.description = 'Visible to everyone';
        } else {
            result.visibilityItem.name = 'Private';
            result.visibilityItem.description = 'Visible only to you';
        }

        return result;
    }

    private _checkIfExceedsPrintableArea() {
        this.canIncludePDFAsAttachment = this._canIncludePDFAsAttachment();
        if (!this.canIncludePDFAsAttachment &&
            this.savedSettings.attachChart === Core.AppealRecommendationTemplateAttachChartOptionEnum.PDF) {
            this.savedSettings.attachChart = Core.AppealRecommendationTemplateAttachChartOptionEnum.Excel;
        }

        this.attachChartOptions[1].isDisabled = !this.canIncludePDFAsAttachment;
        this.attachChartOptions[1].title = !this.canIncludePDFAsAttachment ? this._helpService.getById('appeal-recommendation.checkbox.selections-exceed-printable-area-width') : '';
    }
}
