import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../../AgGrid';
import { LinkedSitesListGridActionCellRendererComponent, ICellRendererParamsForLinkedSitesListGridAction } from './agGridActionCellRenderer.component';
import { ComplianceSiteService } from '../../site.service';
import { WeissmanModalService, IWeissmanModalComponent } from '../../../WeissmanModalService';
import { LinkedSitesAddParams, LinkedSitesAddComponent } from '../Linked-Sites-Add/linkedSitesAdd.component';
import { LinkedSitesListGridNameCellRendererComponent, LinkedSitesListGridNameCellRendererParams } from './agGridNameCellRenderer.component';
import { TimerService } from '../../../../UI-Lib/Utilities/timer.service';

interface LinkedSiteRowModel extends Core.LinkedSiteModel {
    isMaster: boolean;
}

export interface LinkedSitesListParams {
    companyId: number;
    siteId: number;
    masterSiteId: number;
    sites: Core.LinkedSiteModel[];
    editMode: boolean;
}

export interface LinkedSitesListResult {
    masterSiteId: number;
    sites: Core.LinkedSiteModel[];
}

@Component({
    selector: 'linked-sites-list',
    templateUrl: './linkedSitesList.component.html',
    styleUrls: ['./linkedSitesList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LinkedSitesListComponent implements OnInit, OnDestroy, IWeissmanModalComponent<LinkedSitesListParams, LinkedSitesListResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _siteService: ComplianceSiteService,
        private readonly _modalService: WeissmanModalService,
        private readonly _timer: TimerService) { }

    private _gridApi: GridApi;
    private _originalSites: Core.LinkedSiteModel[];

    @ViewChild('focus') focusElement: ElementRef;

    params: LinkedSitesListParams;
    result: LinkedSitesListResult;

    sites: LinkedSiteRowModel[];
    masterSite: LinkedSiteRowModel;
    gridId: System.Guid = '6C6E2165-6879-4A3E-B977-50EB5EA014D6';
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'grid-row-master': params => {
                    const site = params.data as LinkedSiteRowModel;
                    return site && site.isMaster;
                }
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    get editMode(): boolean {
        return this.params && this.params.editMode;
    }

    ngOnInit() {
        this._originalSites = _.concat(this.params.sites);

        this.sites = _.map(this.params.sites,
            (x) => {
                return _.extend(
                    {},
                    x,
                    {
                        isMaster: x.siteId === this.params.masterSiteId
                    }) as LinkedSiteRowModel;
            });

        this.masterSite = _.find(this.sites, x => x.isMaster);

        this._setRowData();
    }

    ngOnDestroy() { }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnLargeWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: LinkedSitesListGridNameCellRendererComponent,
                cellRendererParams: {
                    getName: this._getName.bind(this),
                    getLink: (params) => {
                        const site = params.data as LinkedSiteRowModel;
                        if (!site) {
                            return null;
                        }
                        return `#/site/${site.siteId}`;
                    },
                    isMaster: (params) => {
                        const site = params.data as LinkedSiteRowModel;
                        return site && site.isMaster;
                    },
                    canNavigate: !this.editMode
                } as LinkedSitesListGridNameCellRendererParams,
                filterValueGetter: this._getName.bind(this)
            },
            {
                headerName: 'Master',
                field: 'isMaster',
                width: AgGridColumns.textColumnSmallWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const site = params.data as LinkedSiteRowModel;
                    return site && site.isMaster ? 'Master' : 'Subordinate';
                },
                filterValueGetter: params => {
                    const site = params.data as LinkedSiteRowModel;
                    return site && site.isMaster ? 'Master' : 'Subordinate';
                }
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                lockPinned: true
            },
            {
                headerName: 'State',
                field: 'state',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        if (this.params.editMode) {
            columns.push({
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: LinkedSitesListGridActionCellRendererComponent,
                cellRendererParams: {
                    canRemove: this._canRemove.bind(this),
                    remove: this._remove.bind(this),
                    toggleMaster: this._toggleMaster.bind(this),
                    getMasterTitle: this._getMasterTitle.bind(this)
                } as ICellRendererParamsForLinkedSitesListGridAction
            });
        }

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    getLinkedSiteName(linkedSiteModel: Core.LinkedSiteModel) {
        return this._siteService.getLinkedSiteName(linkedSiteModel);
    }

    save(): void {
        // if the current site is the only one in the list, clear all links
        if (this.sites.length === 1 && _.find(this.sites, x => x.siteId === this.params.siteId)) {
            this.sites = [];
        }

        this.result = {
            masterSiteId: this.masterSite && this.masterSite.siteId,
            sites: this.sites
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async add(): Promise<void> {

        const params: LinkedSitesAddParams = {
            companyId: this.params.companyId,
            sites: this.sites,
            originalSites: this._originalSites,
            siteId: this.params.siteId
        };

        const result = await this._modalService.showAsync(LinkedSitesAddComponent, params, 'modal-lg');

        // restore focus to this modal
        this._timer.setTimeout(() => {
            this.focusElement.nativeElement.focus();
        }, 100);

        const newSites = _.map(result,
            (x) => {
                return _.extend(
                    {} as LinkedSiteRowModel,
                    x,
                    {
                        isMaster: this.masterSite && x.siteId === this.masterSite.siteId
                    });
            });

        _.each(newSites, x => this.sites.push(x));

        this._setRowData();

        return Promise.resolve();
    }

    private _getMasterTitle(params: ICellRendererParamsForLinkedSitesListGridAction): string {
        const site = params.data as LinkedSiteRowModel;
        if (!site) {
            return '';
        }

        return site.isMaster ? 'Remove Master' : 'Make Master';
    }

    private _toggleMaster(params: ICellRendererParamsForLinkedSitesListGridAction): void {
        const newMasterSite = params.data as LinkedSiteRowModel;
        if (!newMasterSite) {
            return;
        }

        newMasterSite.isMaster = !newMasterSite.isMaster;
        this.masterSite = newMasterSite.isMaster ? newMasterSite : null;

        _.each(this.sites,
            (site) => {
                site.masterSiteId = newMasterSite.isMaster ? newMasterSite.siteId : null;
                site.isMaster = newMasterSite.isMaster ? site.siteId === newMasterSite.siteId : false;
            });

        this._setRowData();
    }

    private _canRemove(params: ICellRendererParamsForLinkedSitesListGridAction): boolean {
        const site = params.data as LinkedSiteRowModel;
        if (!site) {
            return false;
        }

        return true;
    }

    private _remove(params: ICellRendererParamsForLinkedSitesListGridAction): void {
        const site = params.data as LinkedSiteRowModel;
        if (!site) {
            return;
        }

        site.masterSiteId = null;
        site.isMaster = false;

        if (this.masterSite) {
            if (site.siteId === this.masterSite.siteId) {
                this.masterSite = null;
            }
        }

        _.remove(this.sites, x => x.siteId === site.siteId);

        this._setRowData();
    }

    removeAll(): void {
        _.forEach(this.sites, (site) => {
            site.masterSiteId = null;
            site.isMaster = false;
        });

        this.masterSite = null;

        this.sites = [];

        this._setRowData();
    }

    private _getName(params: LinkedSitesListGridNameCellRendererParams): string {
        const site = params.data as LinkedSiteRowModel;
        if (!site) {
            return '';
        }

        return this.getLinkedSiteName(site as Core.LinkedSiteModel) + (site.siteId === this.params.siteId ? ' (this site)' : '');
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.sites)) {
            return;
        }

        const masterSites = _.filter(this.sites, x => x.isMaster);
        const subordinateSites = _.filter(this.sites, x => !x.isMaster);

        this._gridApi.setPinnedTopRowData(masterSites);
        this._gridApi.setRowData(subordinateSites);
        this._gridApi.sizeColumnsToFit();
    }
}
