import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormService } from '../form.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { NavigationService } from '../../../Layout/navigation.service';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { FORM_HELP } from '../form.help';

@Component({
    selector: 'form-page',
    templateUrl: './formPage.component.html',
    styleUrls: ['./formPage.component.scss']
})
export class FormPageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _formService: FormService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _navigationService: NavigationService,
        private readonly _helpService: HelpService) { }

    private _lockChangedSub: Subscription;

    breadcrumbs: BreadCrumb[] = [];

    get formRevision(): Compliance.FormRevisionModel {
        return this._formService.formRevision;
    }

    get isFormTypeAppeal(): boolean {
        return this._formService.isFormTypeAppeal;
    }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FORM_HELP);

        this._formService.reset();
        this._formService.loadRoles();

        if (!(this._formService.canEdit || this._formService.canView)) {
            this._routerService.go('unauthorizedAccess', {});
            return Promise.resolve();
        }

        const formRevisionId = parseInt(this._routerService.getQuerystringParam('formRevisionId'));
        const taxYear = parseInt(this._routerService.getQuerystringParam('taxYear'));
        const busyRef = this._busyIndicatorService.show({ message: 'Loading Form' });

        try {
            await this._formService.loadFormRevision(formRevisionId, taxYear);
        }
        finally {
            busyRef.hide();
        }

        this._lockChangedSub = this._mutexService.lockChanged$.subscribe((x) => {
            const isLocked = x && x[this._formService.editGroup];
            if (isLocked) {
                this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
            }
            else {
                this._navigationService.disableNavWarning();
            }
        });

        this.breadcrumbs = [
            {
                name: 'Forms',
                target: 'formList',
                options: {}
            },
            {
                name: this.formRevision.state,
                target: 'formList',
                options: {}
            }
        ];

        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._lockChangedSub && this._lockChangedSub.unsubscribe();
    }

    isHidden(componentName: string): boolean {
        return this._formService.isOtherComponentExpanded(componentName) || !this._formService.isValidForAppeal(componentName);
    }

    areHidden(componentNames: string[]): boolean {
        return _.find(componentNames,
            (componentName) => {
                return !this.isHidden(componentName);
            }) ? false : true;
    }
}
