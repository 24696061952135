import { Component } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { InstanceRights, RestrictData, RestrictService } from '../Common/Permissions/restrict.service';
import { Constants } from '../constants.new';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { InstanceRepository } from '../Entity/Instance/instance.repository';
import { DescriptorHelperService } from './descriptor.helper.service';
import { DescriptorOverridesService } from './descriptor.overrides.service';
import { DescriptorOverride } from './descriptor.model';
import { NavigationService } from '../Layout/navigation.service';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import DescriptorUsageEnum = Core.DescriptorUsageEnum;

const warningMessage = 'Editing is in progress. Are you sure you want to leave?';
@Component({
    selector: 'property-characteristics-overrides',
    templateUrl: './property.characteristics.overrides.component.html',
    styleUrls: ['./property.characteristics.overrides.component.scss']
})
export class PropertyCharacteristicsOverridesComponent {
    _assetUsages: Weissman.Model.Descriptors.DescriptorUsage[];
    _assetUsagesMinusNever: Weissman.Model.Descriptors.DescriptorUsage[] = [];
    companyId: number;
    hasWritePermission: boolean = false;
    hideComplianceFeatures: boolean = false;
    usages: Weissman.Model.Descriptors.DescriptorUsage[];
    descriptorOverrides: DescriptorOverride[];
    descriptorOverrideToEdit: DescriptorOverride;
    initialGet: boolean = false;
    addingDescriptor: boolean = false;
    serverActionSingle: object = {};
    showBody: boolean = false;
    isLoadingAssetUsage: object = {};

    constructor(private descriptorOverridesService: DescriptorOverridesService,
        private constants: Constants,
        private helperService: DescriptorHelperService,
        private upgradeNavigationService: UpgradeNavigationServiceHandler,
        private navigationService: NavigationService,
        private instanceRepository: InstanceRepository,
        private messageBoxService: MessageBoxService,
        private restrictService: RestrictService) { }

    async expandOrCollapse() {
        this.showBody = !this.showBody;

        if(this.showBody) {
            this.companyId = +this.upgradeNavigationService.getQuerystringParam('companyId');
            this._doPermissionCheck();
            this._getData();
        }
    }

    private async _doPermissionCheck(): Promise<void> {
        const restrictionData: RestrictData = new RestrictData();
        restrictionData.isCompany = true;
        restrictionData.entityId = this.companyId;
        restrictionData.flag = Core.AccessRightsEnum.Write;

        this.hasWritePermission = await this.restrictService.hasPermission(restrictionData);

        const instanceId = await lastValueFrom(this.instanceRepository.getEntityInstanceId('company', this.companyId));
        this.hideComplianceFeatures = !this.restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, instanceId);
    }

    private async _getData(): Promise<void> {
        this.initialGet = true;

        this._assetUsagesMinusNever = [];
        this.usages = await this.helperService.getUsages();
        this.descriptorOverrides = await this._getDescriptorOverrides();

        this._assetUsages = JSON.parse(JSON.stringify(this.usages));
        this._assetUsages.forEach(assetUsage => {
            if (assetUsage.name == 'Frequent') {
                assetUsage.name = 'Required';
            } else if (assetUsage.name == 'Occasional') {
                assetUsage.name = 'Optional';
            }

            if (assetUsage.name != 'Never') {
                this._assetUsagesMinusNever.push(assetUsage);
            }
        });

        if (!this.hideComplianceFeatures) {
            const assetDescriptorIdsToCheck = this.descriptorOverrides
                .filter(x => x.descriptorOverride.assetUsageID !== DescriptorUsageEnum.Never && x.canAssetUsageBeRemoved)
                .map(x => x.descriptorDTO.descriptorID);

            this.isLoadingAssetUsage = assetDescriptorIdsToCheck.reduce((acc, key) => ({...acc, [key]: 1}), {});

            this.descriptorOverridesService.getAssetUsage(this.companyId, assetDescriptorIdsToCheck).then(descriptorsInfo => {
                descriptorsInfo.forEach(x => {
                    const descriptor = this.descriptorOverrides.find(y => y.descriptorOverride.descriptorID === x.descriptorId);
                    descriptor.canAssetUsageBeRemoved = x.canAssetUsageBeRemoved;
                    delete this.isLoadingAssetUsage[x.descriptorId];
                });
            });
        }

        this.initialGet = false;
    }

    private async _getDescriptorOverrides(): Promise<DescriptorOverride[]> {
        const descriptorOverrides = await this.descriptorOverridesService.getByCompanyId(this.companyId);
        return _.sortBy(descriptorOverrides, ['categoryName', 'descriptorDTO.sequenceNumber']);
    }

    getAssetUsages(canAssetUsageBeRemoved: boolean): Weissman.Model.Descriptors.DescriptorUsage[] {
        return canAssetUsageBeRemoved ? this._assetUsages : this._assetUsagesMinusNever;
    }

    getDescriptorUsage(id: number): string {
        return _.find(this.usages, { descriptorUsageID: id  }).name;
    }

    getAssetDescriptorUsage(id: number): string {
        return _.find(this._assetUsages, { descriptorUsageID: id }).name;
    }

    isOverridden(descriptorOverride: DescriptorOverride, usageIdKey: string): boolean {
        return descriptorOverride.descriptorDTO[usageIdKey] !== descriptorOverride.descriptorOverride[usageIdKey];
    }

    isEditMode(descriptorOverride: DescriptorOverride): boolean {
        return this.descriptorOverrideToEdit && descriptorOverride.descriptorOverride.descriptorOverrideID == this.descriptorOverrideToEdit.descriptorOverride.descriptorOverrideID;
    }

    editDescriptorOverride(descriptorOverride: DescriptorOverride): void {
        this.descriptorOverrideToEdit = _.cloneDeep(descriptorOverride);
        this.navigationService.enableNavWarning(warningMessage);
    }

    cancelEdit(): void {
        this.descriptorOverrideToEdit = undefined;
        this.navigationService.disableNavWarning();
    }

    async saveDescriptorOverride(idx: number): Promise<void> {
        this.serverActionSingle[idx] = true;

        await this.descriptorOverridesService.update(this.descriptorOverrideToEdit.descriptorOverride);
        this.descriptorOverrides[idx] = this.descriptorOverrideToEdit;

        this.descriptorOverrideToEdit = undefined;
        this.navigationService.disableNavWarning();

        this.serverActionSingle[idx] = false;
    }

    async deleteDescriptorOverride(descriptorOverride: DescriptorOverride, idx: number): Promise<void> {
        const result = await this.messageBoxService.open({
            message: `Are you sure you want to delete ${descriptorOverride.descriptorName}?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (result === MessageBoxResult.OK) {
            this.serverActionSingle[idx] = true;

            await this.descriptorOverridesService.deleteById(descriptorOverride.descriptorOverride.descriptorOverrideID);

            _.remove(this.descriptorOverrides, descriptorOverride);
            this.serverActionSingle[idx] = false;
        }
    }

    async descriptorsSelected(descriptors: Core.DescriptorDTO[]): Promise<void> {
        const descriptorsToAdd = _.map(descriptors, x => {
            const descriptorToAdd: any = _.pick(x, ['descriptorID', 'siteUsageID', 'parcelUsageID', 'assetUsageID']);
            descriptorToAdd.companyID = this.companyId;

            return descriptorToAdd;
        });

        this.addingDescriptor = true;
        try {
            await this.descriptorOverridesService.createBatch(descriptorsToAdd);
            this.descriptorOverrides = await this._getDescriptorOverrides();
        } finally {
            this.addingDescriptor = false;
        }

    }
}
