import { Injectable } from "@angular/core";
import { SDHttpService } from "../../Common/Routing/sd-http.service";
import { EntityFilter, EntitySelection, GetEntitiesRequest, GetPropCharValuesRequest, SelectedEntity, GenericEntity, TopLevelCompany } from './report.manager.model';
import { EntityTypeIds } from '../../constants.new';

declare const _: any;

@Injectable()
export class ReportManagerSelectEntitiesService {

    constructor(private http: SDHttpService) {
    }

    getTopLevelCompanies(onlyFavorites: boolean, excludeInactive: boolean): Promise<TopLevelCompany[]> {
        let onlyFavoritesString: string = onlyFavorites ? 'favorite' : 'all';

        let params: object = {
            excludeInactive: excludeInactive
        }

        return this.http.get('/api/company/topcompanies/' + onlyFavoritesString, {params: params});
    }

    getEntitiesFromParent(payload): Promise<GenericEntity[]> {
        return this.http.post('/api/reportmanager/entities', payload);
    }

    getUsersWithAccessToCertainCompanies(payload, includeInternalUsers: boolean): Promise<GenericEntity[]> {
        payload.includeInternalUsers = includeInternalUsers || false;
        return this.http.post('api/ReportManager/UsersWithAccessToCertainCompanies', payload);
    }

    getPropCharsFromParent(type:string, payload): Promise<GenericEntity[]> {
        return this.http.post(`/api/reportmanager/propchars/${type}`, payload);
    }

    getPropCharValues(type:string, payload: GetPropCharValuesRequest): Promise<String[]> {
        return this.http.post(`/api/reportmanager/propchars/${type}/values`, payload);
    }

    getClientServices(payload: {sourceEntityIDs: number[], sourceEntityTypeID:number}): Promise<GenericEntity[]> {
        return this.http.post('/api/clientservice/cs/forentities', payload);
    }

    getPropertyTypes(payload: {sourceEntityIDs: number[], sourceEntityTypeID:number}): Promise<GenericEntity[]> {
        return this.http.post('/api/clientservice/propertytypes/forentities', payload);
    }

    getPayloadSourceEntityData(entitySelection: EntitySelection, i?: number, omitForCurrentEntity?: boolean): GetEntitiesRequest {
        if (i === undefined) {
            i = entitySelection.selectedEntities.length - 1;
        }
        let nonSourceEntityIDs: number[] = [EntityTypeIds.STATE, EntityTypeIds.ASSESSOR, EntityTypeIds.PROPERTYTYPE, EntityTypeIds.CLIENTSERVICE];
        let payload: GetEntitiesRequest = new GetEntitiesRequest();

        let lastSourceEntity: SelectedEntity = _.findLast(entitySelection.selectedEntities, (entity: SelectedEntity, index: number) => {
            return index <= i && entity.id != 999 && entity.id != 998 && entity.id != EntityTypeIds.USER && entity.id != EntityTypeIds.CONTACT && !_.includes(nonSourceEntityIDs, entity.id) && entity.selectedIDs.length;
        }) as SelectedEntity;

        if (lastSourceEntity) {
            payload.sourceEntityIDs = lastSourceEntity.selectedIDs as number[];
            payload.sourceEntityTypeID = lastSourceEntity.id;
            payload.sourceEntityIsTopLevelCompany = false;
        } else {
            payload.sourceEntityIDs = entitySelection.selectedTopLevelCompanyIDs,
            payload.sourceEntityTypeID = 1;
            payload.sourceEntityIsTopLevelCompany = true;
        }

        //site property chars filters
        let sitePropCharFilters = _.chain(entitySelection.selectedEntities)
            .filter((selectedEntity: SelectedEntity, index: number): boolean => {
                let isEntityIncluded = omitForCurrentEntity ? index < i : index <= i;

                return (selectedEntity.id === 999) && isEntityIncluded;
            })
            .map((entity: SelectedEntity) => {
                return _.map(entity.selectedPropCharVals, (propChar: string) => {
                    return {
                        name: entity.selectedDropdown,
                        value: propChar
                    }
                })
            })
            .flatten()
            .value() as Object[];

        if (sitePropCharFilters.length) {
            payload.sitePropCharFilters = sitePropCharFilters;
        }

        //parcel property chars filters
        let parcelCharFilters = _.chain(entitySelection.selectedEntities)
            .filter((selectedEntity: SelectedEntity, index: number): boolean => {
                let isEntityIncluded = omitForCurrentEntity ? index < i : index <= i;

                return (selectedEntity.id === 998) && isEntityIncluded;
            })
            .map((entity: SelectedEntity) => {
                return _.map(entity.selectedPropCharVals, (propChar: string) => {
                    return {
                        name: entity.selectedDropdown,
                        value: propChar
                    }
                })
            })
            .flatten()
            .value() as Object[];

        if (parcelCharFilters.length) {
            payload.parcelPropCharFilters = parcelCharFilters;
        }

        //userid filters
        let userIdFilters = _.chain(entitySelection.selectedEntities)
            .filter((selectedEntity: SelectedEntity, index: number): boolean => {
                let isEntityIncluded = omitForCurrentEntity ? index < i : index <= i;

                return selectedEntity.id === EntityTypeIds.USER && isEntityIncluded;
            })
            .map((entity: SelectedEntity) => entity.selectedIDs)
            .flatten()
            .value() as string[];

        if (userIdFilters.length) {
            payload.userIdFilters = userIdFilters;
        }

        //contact filters
        let contactIds = _.chain(entitySelection.selectedEntities)
            .filter((selectedEntity: SelectedEntity, index: number): boolean => {
                let isEntityIncluded = omitForCurrentEntity ? index < i : index <= i;

                return selectedEntity.id === EntityTypeIds.CONTACT && isEntityIncluded;
            })
            .map((entity: SelectedEntity) => entity.selectedIDs)
            .flatten()
            .value() as number[];

        if (contactIds.length) {
            payload.contactIds = contactIds;
        }

        //entity filters
        payload.entityFilters = _.chain(entitySelection.selectedEntities)
            .filter((entity: SelectedEntity, index: number) => {
                return _.includes(nonSourceEntityIDs, entity.id) && index <= i;
            })
            .map((entity: SelectedEntity) => {
                return {
                    entityTypeID: entity.id,
                    entityIDs: entity.selectedIDs
                }
            })
            .value() as EntityFilter[];

        payload.excludeInactive = entitySelection.excludeInactive;

        return payload;
    }

    getSelectedEntities(): SelectedEntity[] {
        let entities: SelectedEntity[] = [
            {
                name: 'Subsidiary Companies',
                buttonName: 'Subsidiaries',
                id: EntityTypeIds.COMPANY,
                prerequisites: [],
                blockers: [EntityTypeIds.COMPANY, EntityTypeIds.SITE, EntityTypeIds.STATE, 999, 998, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Sites',
                buttonName: 'Sites',
                id: EntityTypeIds.SITE,
                prerequisites: [],
                blockers: [EntityTypeIds.SITE, EntityTypeIds.CONTACT, EntityTypeIds.PARCEL, 998],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'States',
                buttonName: 'States',
                id: EntityTypeIds.STATE,
                prerequisites: [],
                blockers: [EntityTypeIds.SITE, EntityTypeIds.STATE, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Assessors',
                buttonName: 'Assessors',
                id: EntityTypeIds.ASSESSOR,
                prerequisites: [EntityTypeIds.STATE],
                blockers: [EntityTypeIds.ASSESSOR, EntityTypeIds.SITE, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Site Characteristic',
                buttonName: 'Site Characteristic',
                id: 999,
                prerequisites: [],
                blockers: [EntityTypeIds.PARCEL, EntityTypeIds.SITE, EntityTypeIds.CONTACT, 999, 998],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Parcel Characteristic',
                buttonName: 'Parcel Characteristic',
                id: 998,
                prerequisites: [],
                blockers: [EntityTypeIds.CONTACT, 998],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Appeal Responsibility',
                buttonName: 'Appeal Responsibility',
                id: EntityTypeIds.USER,
                prerequisites: [],
                blockers: [EntityTypeIds.USER, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false,
                isAppealResponsibility: true
            },
            {
                name: 'Users',
                buttonName: 'Users',
                id: EntityTypeIds.CONTACT,
                prerequisites: [],
                blockers: [EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Users',
                buttonName: 'Users',
                id: EntityTypeIds.USER,
                prerequisites: [],
                blockers: [EntityTypeIds.USER],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Parcels',
                buttonName: 'Parcels',
                id: EntityTypeIds.PARCEL,
                prerequisites: [],
                blockers: [EntityTypeIds.PARCEL],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Responsibilities',
                buttonName: 'Client Service',
                id: EntityTypeIds.CLIENTSERVICE,
                prerequisites: [],
                blockers: [EntityTypeIds.CLIENTSERVICE, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Property Type',
                buttonName: 'Property Type',
                id: EntityTypeIds.PROPERTYTYPE,
                prerequisites: [],
                blockers: [EntityTypeIds.PROPERTYTYPE, EntityTypeIds.CONTACT],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            },
            {
                name: 'Users',
                buttonName: 'Users',
                id: EntityTypeIds.SUPPORTUSER,
                prerequisites: [],
                blockers: [EntityTypeIds.SUPPORTUSER],
                list: [],
                selectedIDs: [],
                selectedPropCharVals: [],
                dropdownList: [],
                selectedDropdown: '',
                loading: false
            }
        ];

        return entities;
    }

    getSupportInstances(): Promise<Core.SupportInstanceInfoModel[]> {
        return this.http.get('/api/supportInstance/list/');
    }
}
