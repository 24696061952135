
<div class="card panel-flat-color primary-panel">
    <div class="panel-flat-header" style="display: flex; justify-content: space-between;">
        <h3>Parcel Info</h3>
        <div class="header-button-container">
            <div *ngIf="!editMode">
                <button type="button"
                    class="flat-button icon-button"
                    (click)="launchChangeHistoryModal()"
                    *ngIf="hasInstancePrivateView">
                        <i class="fa fa-history"></i>
                </button>
                <button class="flat-button icon-button primary-button"
                    [hidden]="!hasEditPermission"
                    (click)="edit()">
                        <i class="fa fa-pencil"></i>
                </button>
                <i *ngIf="loadingPermissions" class="fa fa-spinner fa-spin"></i>
            </div>
            <span *ngIf="editMode">
                <button class="flat-button icon-button success-button" [disabled]="serverAction" (click)="save()">
                    <i class="fa fa-save"></i>
                </button>
                <button class="flat-button icon-button warning-button" [disabled]="serverAction" (click)="cancel()">
                    <i class="fa fa-close"></i>
                </button>
            </span>
        </div>
    </div>
    <div class="panel-flat-body">
        <loading-spinner [show]="serverAction" [marginTop]="100" [size]="5"></loading-spinner>
        <div [hidden]="serverAction">
            <parcel-info-panel-view *ngIf="!editMode"
                [parcelViewDto]="parcelViewDto"
                [parcelAssessor]="parcelAssessor"
                [years]="years"
                [assessorDetailsRenderer]="assessorDetailsRenderer"
                (serverActionChange)="serverAction = $event"
                [hasEditPermission]="hasEditPermission"
                [stateAllowConsolidating]="stateAllowConsolidating">
            </parcel-info-panel-view>
            <parcel-info-panel-edit *ngIf="editMode"
                [parcel]="parcelViewDto.parcel"
                [instanceId]="instanceId"
                [assessorDetailsRenderer]="assessorDetailsRenderer"
                [reloadAnnualGrid]="reloadAnnualGrid"
                (serverActionChange)="serverAction = $event"
                [years]="years"
                [assessors]="assessors"
                [taxRateSetup]="parcelViewDto.taxRateSetup"
                [ppReturnPreparationAllowed]="parcelViewDto.ppReturnPreparationAllowed"
                [stateAllowConsolidating]="stateAllowConsolidating">
            </parcel-info-panel-edit>
        </div>
    </div>
</div>
