import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { ActionViewDisplayTypes } from '../../../../constants.new';
import { ActionViewService } from '../../../../Task/ActionView/action-view-service.upgrade';
import { UserSaveService } from '../../userSave.service';

export interface ActionViewDefaultDisplayModalParams {
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;
}

@Component({
    selector: 'action-view-default-display',
    templateUrl: './actionViewDefaultDisplay.component.html'
})
export class ActionViewDefaultDisplayComponent implements IWeissmanModalComponent<ActionViewDefaultDisplayModalParams, void>, OnInit {
    constructor(private readonly _actionViewService: ActionViewService,
                private readonly _userSaveService: UserSaveService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: ActionViewDefaultDisplayModalParams;
    result: void;

    modalTitle: string;
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;
    displayTypes = ActionViewDisplayTypes;
    selectedDisplayType: number = 0;

    ngOnInit(): void {
        this.actionView = this.params.actionView;
        this.actionViewType = this.params.actionViewType;
        this.modalTitle = `Change "${this.actionView.actionViewName}" Default Display`;
    }

    async save() {
        this.actionView.defaultDisplay = this.selectedDisplayType;

        //This is to work around a bug where no categoryId passed in clears the Id unintentionally. categoryId < 0 means "don't change"
        this.actionView.categoryId = -1;

        await this._actionViewService.patch(this.actionView, this.actionViewType);
        await this._userSaveService.refreshCurrentList();
        this.cancel();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    thatAreCompleted() {
        return this.actionView.thatAreChoice === 4;
    }
}
