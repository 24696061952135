<div class="form-horizontal further-limit-asset-detail">
    <div class="row xl">
        <div class="col-md-4">
            <div class="mb-4 row" *ngIf="modalParameters?.filingBatchId">
                <label class="col-md-3 control-label">Form:</label>
                <div class="col-md-9">
                    <select [ngModel]="report.criteria.formRevisionId" class="form-select form-select-sm"
                            [disabled]="!formRevisions?.length"
                            (ngModelChange)="onFormRevisionChange($event)">
                        <option [ngValue]="null">All Forms</option>
                        <option *ngFor="let formRevision of formRevisions" [ngValue]="formRevision.formRevisionId">{{ formRevision.formRevisionName }}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3 row">
                <label class="col-md-3 control-label">Schedule:</label>
                <div class="col-md-9">
                    <select [ngModel]="scheduleId" (ngModelChange)="assignScheduleId($event)" class="form-select form-select-sm">
                        <option *ngFor="let schedule of schedules" [ngValue]="schedule.formRevisionScheduleId">{{ schedule.name }}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3 row">
                <label class="col-md-3 control-label">Assets:</label>
                <div class="col-md-9">
                    <return-asset-status-selector
                                                  [reportDataSource]="report?.criteria?.dataSource"
                                                  [allowFilteringItemsBasedOnDataSource]="true"
                                                  [selectedValue]="priorReturnStatuses"
                                                  (selectedValueChange)="onSelectedAssetStatusFilterChange($event)"
                                                  [selectDeductions]="true">
                    </return-asset-status-selector>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="checkbox">
                <label>
                    <input type="checkbox" [ngModel]="isSystemControlled" disabled> System Controlled
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="report.isDisabled"> Disabled
                </label>
            </div>
        </div>
    </div>
</div>
