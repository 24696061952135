import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GridLayoutSpecificationRepository } from '../../../Repositories';
import { GridOptions } from 'ag-grid-community';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { UserService } from '../../../../Account/user.service';
import { RestrictService, Roles } from '../../../../Common/Permissions/restrict.service';
import { AgGridLayoutSpecificationModel, AgGridLayoutSpecificationListItemModel, AgGridLayoutSpecificationVisibilityModel } from '../models';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { AgGridLayoutSpecificationDetailsComponent, AgGridLayoutSpecificationDetailsParams } from './Details/agGridLayoutSpecificationDetails.component';
import { AG_GRID_LAYOUT_SPECIFICATION_HELP } from './agGridLayoutSpecification.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { AgGridPersistenceService } from '../../agGridPersistence.service';

import * as _ from 'lodash';
import { filter, forEach } from 'lodash/fp';

@Component({
    selector: 'ag-grid-layout-specification',
    templateUrl: './agGridLayoutSpecification.component.html',
    styleUrls: ['./agGridLayoutSpecification.component.scss']
})
export class AgGridLayoutSpecificationComponent implements OnInit {
    constructor(
        private readonly _messageModalService: MessageModalService,
        private readonly _gridLayoutSpecificationRepository: GridLayoutSpecificationRepository,
        private readonly _userService: UserService,
        private readonly _restrictService: RestrictService,
        private readonly _modalService: WeissmanModalService,
        private readonly _agGridPersistenceService: AgGridPersistenceService,
        private readonly _helpService: HelpService) { }

    @Input() set gridId(gridId: System.Guid) {
        this._gridId = gridId;
        if (this._initialized) {
            this._load();
        }
    }
    @Input() companyId: number;
    @Input() gridOptions: GridOptions & { api?: {appliedFilter?: any}};
    @Input() defaultColorizeHeaders: boolean;
    @Input() displayedColumnsChanged$: Subject<void>;

    @Output() specificationChanged: EventEmitter<AgGridLayoutSpecificationModel> = new EventEmitter<AgGridLayoutSpecificationModel>();
    @Output() loadComplete: EventEmitter<AgGridLayoutSpecificationModel> = new EventEmitter<AgGridLayoutSpecificationModel>();

    selectedSpecification: AgGridLayoutSpecificationListItemModel;
    specifications: AgGridLayoutSpecificationListItemModel[];
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    isBusy: boolean = true;
    userId: System.Guid;
    canEditShared: boolean = false;
    canEditCompany: boolean = false;
    colorizeHeaders: boolean = false;

    visibilityBuiltIn: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Default',
        visibility: Compliance.SpecificationVisibilityEnum.Private,
        isDefault: false,
        isCompany: false,
        description: 'Visible to everyone for any company',
        descriptionNoCompany: 'Visible to everyone'
    };

    visibilityPrivate: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Private',
        visibility: Compliance.SpecificationVisibilityEnum.Private,
        isDefault: false,
        isCompany: false,
        description: 'Visible only to you for any company',
        descriptionNoCompany: 'Visible only to you'
    };

    visibilityPrivateCompany: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Private Company',
        visibility: Compliance.SpecificationVisibilityEnum.Private,
        isDefault: false,
        isCompany: true,
        description: 'Visible only to you for this top-level company',
        descriptionNoCompany: 'Visible only to you'
    };

    visibilityCompany: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Company',
        visibility: Compliance.SpecificationVisibilityEnum.Company,
        isDefault: false,
        isCompany: true,
        description: 'Visible to everyone in this top-level company',
        descriptionNoCompany: 'Visible to everyone'
    };

    visibilityCompanyDefault: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Company Default',
        visibility: Compliance.SpecificationVisibilityEnum.Company,
        isDefault: true,
        isCompany: true,
        description: 'Default for everyone in this top-level company',
        descriptionNoCompany: 'Default for everyone',
        warning: 'Layout is default for top-level company'
    };

    visibilityShared: AgGridLayoutSpecificationVisibilityModel = {
        name: 'Shared',
        visibility: Compliance.SpecificationVisibilityEnum.Shared,
        isDefault: false,
        isCompany: false,
        description: 'Visible to everyone',
        descriptionNoCompany: 'Visible to everyone'
    };

    visibilities: AgGridLayoutSpecificationVisibilityModel[] = [];
    allVisibilities: AgGridLayoutSpecificationVisibilityModel[] = [
        this.visibilityPrivate,
        this.visibilityPrivateCompany,
        this.visibilityCompany,
        this.visibilityCompanyDefault,
        this.visibilityShared];

    private _builtInSpecification: AgGridLayoutSpecificationListItemModel;
    private _initialized: boolean;
    private _gridId: System.Guid;
    private _destroy$: Subject<void> = new Subject();

    get editHelpContentId(): string {
        if (!this.selectedSpecification) {
            return 'ag-grid-layout-specification.no-layout';
        }

        if (this.selectedSpecification.isDefault) {
            return 'ag-grid-layout-specification.edit-default';
        }

        if (!this.selectedSpecification.canEdit) {
            return 'app.save-disabled';
        }

        return 'app.save';
    }

    get copyHelpContentId(): string {
        if (!this.selectedSpecification) {
            return 'ag-grid-layout-specification.no-layout';
        }

        return 'ag-grid-layout-specification.copy';
    }

    get deleteHelpContentId(): string {
        if (!this.selectedSpecification) {
            return 'ag-grid-layout-specification.no-layout';
        }

        if (this.selectedSpecification.isDefault) {
            return 'ag-grid-layout-specification.delete-default';
        }

        if (!this.selectedSpecification.canEdit) {
            return 'app.delete-disabled';
        }

        return 'app.delete';
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(AG_GRID_LAYOUT_SPECIFICATION_HELP);

        await this._load();

        this.displayedColumnsChanged$.pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                if (!this._builtInSpecification.layout) {
                    const layoutModel: AgGridLayoutSpecificationModel = {
                        column: this.gridOptions.columnApi.getColumnState(),
                        filter: this.gridOptions.api.getFilterModel(),
                        sort: this.gridOptions.api.getSortModel(),
                        colorize: this.colorizeHeaders,
                        unResizedColumns: false
                    };

                    this._builtInSpecification.layout = JSON.stringify(layoutModel);
                    this._applySpecification();
                }
            });

        this._agGridPersistenceService.isHeaderGroupColorActive$(this._gridId as string)
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.colorizeHeaders = x);

        this._initialized = true;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async delete(): Promise<void> {
        if (this.selectedSpecification === this._builtInSpecification) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.confirm(
                `Are you sure you wish to delete '${this.selectedSpecification.name}'?`,
                'Confirm Delete');
        } catch (e) {
            return Promise.resolve();
        }

        const index = this.specifications.indexOf(this.selectedSpecification);

        const busyMsgId = 'deleting';
        this.busyIndicatorMessageManager.add('Deleting', busyMsgId);

        try {
            await lastValueFrom(this._gridLayoutSpecificationRepository.delete(this.selectedSpecification.gridLayoutSpecificationId));

            this.specifications.splice(index, 1);

            this.selectedSpecification = this._builtInSpecification;
            this._agGridPersistenceService.setCurrentGridLayoutApplied(this._gridId as string, this.selectedSpecification.name);

            this.onSpecificationChanged();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        return Promise.resolve();
    }

    async edit(copy: boolean): Promise<void> {
        if (!(this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi)) {
            return Promise.resolve();
        }

        const layoutModel: AgGridLayoutSpecificationModel = {
            column: this.gridOptions.columnApi.getColumnState(),
            filter: this.gridOptions.api.getFilterModel(),
            sort: this.gridOptions.api.getSortModel(),
            colorize: this.colorizeHeaders,
            unResizedColumns: false
        };

        const specification: AgGridLayoutSpecificationListItemModel = {
                ...this.selectedSpecification,
                ...{
                    gridLayoutSpecificationId: copy ? 0 : this.selectedSpecification.gridLayoutSpecificationId,
                    layout: JSON.stringify(layoutModel),
                    name: copy ? '' : this.selectedSpecification.name,
                    description: copy ? '' : this.selectedSpecification.description,
                    userId: copy ? this.userId : this.selectedSpecification.userId,
                    visibility: copy ? Compliance.SpecificationVisibilityEnum.Private : this.selectedSpecification.visibility,
                    visibilityItem: copy ? this.visibilityPrivate : this.selectedSpecification.visibilityItem,
                }
            } as AgGridLayoutSpecificationListItemModel;

        const params: AgGridLayoutSpecificationDetailsParams = {
            specification: specification,
            userId: this.userId,
            visibilities: this.visibilities,
            companyId: this.companyId
        };

        const result = await this._modalService.showAsync(AgGridLayoutSpecificationDetailsComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const spec = this._getListItem(result);

        if (copy) {
            this.specifications.push(spec);

            this.selectedSpecification = spec;
            this._agGridPersistenceService.setCurrentGridLayoutApplied(this._gridId as string, this.selectedSpecification.name);

            this.onSpecificationChanged();
        } else {
            Object.assign(this.selectedSpecification, spec);
        }

        this.specifications = this._sort(this.specifications);

        return Promise.resolve();
    }

    async onSpecificationChanged(): Promise<void> {
        this._applySpecification();
        this._agGridPersistenceService.setCurrentGridLayoutApplied(this._gridId as string, this.selectedSpecification.name);
        await this._gridLayoutSpecificationRepository.updateGridLayoutUserSetting(this._gridId, this.companyId, this.selectedSpecification.gridLayoutSpecificationId);
    }

    private async _load(): Promise<void> {
        if (!(this._gridId && this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi && this._gridId)) {
            return Promise.resolve();
        }

        this.isBusy = true;

        try {
            this.userId = this._userService.getUser().id;

            const visibilities: AgGridLayoutSpecificationVisibilityModel[] = [];

            visibilities.push(this.visibilityPrivate);

            if (this.companyId) {
                visibilities.push(this.visibilityPrivateCompany);

                this.canEditCompany = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);
                if (this.canEditCompany) {
                    visibilities.push(this.visibilityCompany);
                    visibilities.push(this.visibilityCompanyDefault);
                }
            }

            this.canEditShared = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);
            if (this.canEditShared) {
                visibilities.push(this.visibilityShared);
            }

            this.visibilities = visibilities;

            const searchModel: Compliance.GridLayoutSpecificationSearchModel = {
                gridId: this._gridId,
                companyId: this.companyId
            };

            // default represents the built-in grid configuration
            const layoutModel: AgGridLayoutSpecificationModel = {
                column: this.gridOptions.columnApi.getColumnState(),
                filter: this.gridOptions.api.getFilterModel(),
                sort: this.gridOptions.api.getSortModel(),
                colorize: this.defaultColorizeHeaders,
                unResizedColumns: false
            };

            this._builtInSpecification = {
                gridLayoutSpecificationId: 0,
                companyId: this.companyId,
                gridId: this._gridId,
                layout: (layoutModel.column.length) ? JSON.stringify(layoutModel) : null,
                isDefault: true,
                visibility: Compliance.SpecificationVisibilityEnum.Private,
                visibilityItem: this.visibilityBuiltIn,
                name: 'Default',
                description: 'Built-in layout',
                canEdit: false
            };

            const specs = await lastValueFrom(this._gridLayoutSpecificationRepository.getList(searchModel));
            const specItems = specs.data.map((x: Compliance.GridLayoutSpecificationModel) => this._getListItem(x));

            specItems.splice(0, 0, this._builtInSpecification);

            // get the last used layout for the user
            const userLayoutId = await this._gridLayoutSpecificationRepository.getGridLayoutUserSetting(this._gridId, this.companyId);
            let selectedSpec: AgGridLayoutSpecificationListItemModel = null;
            if (userLayoutId) {
                selectedSpec = specItems.find(
                    x => {
                        return x.gridLayoutSpecificationId === userLayoutId;
                    });
            }

            // if no last used layout, get the company default
            if ((!selectedSpec) && this.companyId) {
                selectedSpec = specItems.find(
                    x => {
                        return x.visibility === Compliance.SpecificationVisibilityEnum.Company &&
                            x.isDefault &&
                            x.companyId === this.companyId;
                    });
            }

            // if nothing selected, select built in
            if (!selectedSpec) {
                selectedSpec = this._builtInSpecification;
            }

            this.specifications = this._sort(specItems);
            this.selectedSpecification = selectedSpec;
            this._agGridPersistenceService.setCurrentGridLayoutApplied(this._gridId as string, this.selectedSpecification.name);
            this._applySpecification();
        } finally {
            this.loadComplete.emit();
            this.isBusy = false;
        }
    }

    private _sort(list: AgGridLayoutSpecificationListItemModel[]): AgGridLayoutSpecificationListItemModel[] {
        const sorted = _.sortBy(list.filter(x => x !== this._builtInSpecification), x => x.name);
        sorted.splice(0, 0, this._builtInSpecification);
        return sorted;
    }

    private _getListItem(specificationModel: Compliance.GridLayoutSpecificationModel): AgGridLayoutSpecificationListItemModel {
        const spec: AgGridLayoutSpecificationListItemModel = {
                ...specificationModel,
                ...{
                    visibilityItem: this.allVisibilities.find(x => x.visibility === specificationModel.visibility && x.isDefault === specificationModel.isDefault && (specificationModel.companyId ? x.isCompany : !x.isCompany)) || null,
                    changed: false,
                    canEdit: false
                }
            } as AgGridLayoutSpecificationListItemModel;

        if (!spec.visibilityItem) {
            console.warn('Did not find valid visibility item for spec.', spec);
        }

        switch (spec.visibility) {
            case Compliance.SpecificationVisibilityEnum.Private:
                spec.canEdit = specificationModel.userId === this.userId;
                break;
            case Compliance.SpecificationVisibilityEnum.Company:
                spec.canEdit = this.canEditCompany;
                break;
            case Compliance.SpecificationVisibilityEnum.Shared:
                spec.canEdit = this.canEditShared;
                break;
        }

        return spec;
    }

    private _applySpecification(): void {
        if (!(this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi && this._builtInSpecification.layout)) {
            return;
        }

        const model = JSON.parse(this.selectedSpecification.layout) as AgGridLayoutSpecificationModel;

        if (model.column) {
            const colState = this.gridOptions.columnApi.getColumnState();

            // If a column was removed from the app, we need to remove it from the saved spec
            model.column = model.column.filter(x => colState.find(y => y.colId === x.colId));

            // Check if there are missing columns that were added after the spec was saved
            const missing = colState.reduce((acc, x, i) => {
                if (!model.column.find(y => y.colId === x.colId)){
                    acc.push({col: x, index: i});
                }
                return acc;
            }, []);
            // Inject the missing columns into the spec
            missing.forEach(x => model.column.splice(x.index, 0, x.col));

            // Check to see if the column lock pinned value has changed since the last save
            _.flow([
                filter(x => {
                    const colDef = x.getColDef();
                    return colDef.lockPinned && colDef.pinned === 'left';
                }),
                forEach(x => {
                    const col = model.column.find(y => x.getColId() === y.colId);
                    if (col && !col.pinned) {
                        col.pinned = 'left';
                    }
                })
            ])(this.gridOptions.columnApi.getAllColumns());

            this.gridOptions.columnApi.setColumnState(model.column);
        }

        if (model.filter && !_.isEmpty(model.filter)) {
            model.filter = _.omitBy(model.filter, x => !x.filterValues);
            this.gridOptions.api.setFilterModel(model.filter);
        } else {
            this.gridOptions.api.setFilterModel(null);
            if (this.gridOptions.api.appliedFilter) {
                delete this.gridOptions.api.appliedFilter;
            }
        }

        if (model.sort && model.sort.length) {
            this.gridOptions.api.setSortModel(model.sort);
        } else {
            const defaultSort = this._builtInSpecification && this._builtInSpecification.layout
                ? JSON.parse(this._builtInSpecification.layout).sort : null;
            this.gridOptions.api.setSortModel(defaultSort);
        }

        this.gridOptions.api.redrawRows();

        this.specificationChanged.emit(model);
    }
}
