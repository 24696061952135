import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DataWidgetI, WidgetCache } from "../dashboard.model";
import { lastValueFrom, Observable } from "rxjs";
import { HttpParams, HttpClient } from "@angular/common/http";
import { AdvancedSearchPersistenceService } from "../../Search/Advanced/advancedSearchPersistence.service";
import { UpgradeNavigationServiceHandler } from "../../Common/Routing/upgrade-navigation-handler.service";
import { SmartSearchService } from "../../Search/Advanced/smartSearch.service";
import { ProductAnalyticsService } from "../../Common/Amplitude/productAnalytics.service";


@Component({
    selector: 'appeal-savings-widget',
    template: `
        <style>
            .appeal-savings-header {
                border: 1px solid #465D8C;
                border-radius: 10px;
                padding: 7px;
                background-color: #465D8C;
                color: white;
                box-shadow: 3px 3px 5px grey;
                font-size: 1.5vw;
            }
            .previous-year-savings {
                list-style: none;
                margin-top: 10px;
                margin-bottom: 0;
                font-size: 150%;
                display: inline-block;
                padding: 0;
                font-size: 1vw;
            }
            .smart-link {
                color: inherit;
                cursor: pointer;
            }
            .smart-link:hover {
                color: inherit
            }
        </style>
        <div class="text-center" style="padding: 1vw; height: 100%;" *ngIf="data">
            <i class="fa fa-spinner fa-pulse fa-3x" *ngIf="loading" style="position: absolute; top: 35%; left: 43%; z-index: 2;"></i>
            <div class="appeal-savings-header">
                <div>Appeal Savings YTD:</div>
                <div> <a class="smart-link" (click)="goToSmart()">{{data.yearToDateAppealSavings | currency: 'USD'}}</a></div>
            </div>
            <ul class="text-left previous-year-savings">
                <li *ngFor="let savings of data.previousYearSavings">
                    <a class="smart-link" (click)="goToSmart(savings.key)"><small>{{savings.key}}:</small><span style="margin-left: 10px;">{{savings.value | currency: 'USD'}}</span></a>
                </li>
            </ul>
        </div>
    `
})
export class AppealSavingsWidgetComponent implements OnInit, DataWidgetI {
    constructor(
        private readonly _http: HttpClient,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _advancedSearchService: SmartSearchService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @Input() data$: Observable<any>;
    @Output() onNewParams: EventEmitter<object> = new EventEmitter();

    loading: boolean;
    data: any;

    ngOnInit() {
        this.data$.subscribe((widgetCache: WidgetCache) => {
            this.data = widgetCache.data;
        });
    }

    async goToSmart(year?: string) {
        let httpParams: HttpParams = new HttpParams();
        const currentYear: number = new Date().getFullYear();

        httpParams = year ? httpParams.set('year', year) : httpParams.set('year', currentYear.toString())

        this.loading = true;

        try {
            this._advancedSearchPersistenceService.clearSearchData();

            const data = await lastValueFrom(this._http.get<any>('/api/search/Widget/Appealsavings', {params: httpParams}));
            this._advancedSearchPersistenceService.search = await this._advancedSearchService.returnSearch(data);
            this._advancedSearchPersistenceService.fromWidget = true;

            this._productAnalyticsService.logEvent('click-appeal-savings-widget', {
                'appealSavingsWidget': year ? `y-${currentYear - (+year)}` : 'ytd'
            });

            this._upgradeNavigationServiceHandler.go('search', {});
        } finally {
            this.loading = false;
        }

    }
}
