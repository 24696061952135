import {
    AceUIIcon,
    ButtonSize,
    ButtonState,
    ButtonStyle,
    IAceRouterUrl,
    IconFontStyle,
    IIconDisplay
} from '@ace/shared';
import { IAceRouterLink } from '@ace/shared/src/elements/button';
import {
    Component,
    EventEmitter,
    HostBinding,
    Input, OnInit,
    Output
} from '@angular/core';
import { UIRouter } from '@uirouter/angular';

export interface IButtonStyle {
    /** Enable block display (full width) */
    enableBlock?: boolean;

    /** (optional) The state that is portrayed, this will determine the color that is rendered.  Default: ButtonState.Action */
    state?: ButtonState;

    /** (optional) Style of button to present.  Default: ButtonStyle.Secondary */
    style?: ButtonStyle;

    /** (optional) Size of button to present.  Default: ButtonSize.Default */
    size?: ButtonSize;

    isDisabled?: boolean;
    active?: boolean;
    type?: 'button' | 'submit'; // defaults to 'button'
}

export interface IButtonInput extends IButtonStyle, IIconDisplay, IAceRouterUrl, IAceRouterLink {
    id?: number | string;

    content: string;

    isLoading?: boolean;

    /** (optional) If a url is present, open in the same tab or window. Default: false  */
    suppressNewWindow?: boolean;

    /** (optional) Display this string as a tooltip which appears on hover.  Default: no tooltip */
    info?: string;
}

export interface IButtonWCInput {
    /** style of button */
    buttonStyle?: 'primary' | 'secondary' | 'text';
    /** state/color of button */
    buttonState?: 'action' | 'danger' | 'success';
    /** size of button */
    size?: 'small' | 'default';
    /** portray a disabled state */
    disabled?: boolean;
    /** portray an active state */
    active?: boolean;
    /** full width block button */
    block?: boolean;
    /** type attribute of button */
    type?: 'button' | 'submit';
}

@Component({
    selector: 'ws-ace-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class AceButtonComponent implements OnInit {
    constructor(private readonly _router: UIRouter) {
    }


    @Input() content: string;
    @Input() icon: AceUIIcon | string;
    @Input() iconFont: IconFontStyle | IconFontStyle[] | string;
    @Input() iconSlot: string = 'icon';
    @Input() info: string;
    @Input() type: string;
    // Implement these when required
    // @Input() url: string;
    // @Input() routerLink: string;
    // @Input() routeFragment: string;
    @Input() queryParams: object;
    // @Input() routeExtensionKey: string;
    // @Input() routerLinkPathMatch: string;
    @Input() routerOutlet: string;
    // @Input() suppressNewWindow: string;

    @HostBinding('attr.size')
    @Input() size: 'default' | 'small';

    @HostBinding('attr.disabled')
    @Input() isDisabled: boolean;

    @HostBinding('attr.block')
    @Input() enableBlock: boolean;

    @HostBinding('attr.button-style')
    @Input() buttonStyle: 'primary' | 'secondary' | 'link' | 'text';

    @HostBinding('attr.button-state')
    @Input() state: 'action' | 'danger' | 'info' | 'success';

    @Output() buttonClick = new EventEmitter<MouseEvent>();

    iconFontClass: string;
    iconSize: string;

    ngOnInit(): void {
        this.iconFontClass = this.iconFont instanceof Array ? this.iconFont.join(' ') : this.iconFont as string;
        this.iconSize = this.size === 'small' ? '1.5' : '2';
    }

    buttonClicked(event: MouseEvent): void {
        if (this.routerOutlet) {
            this._router.stateService.transitionTo(this.routerOutlet, this.queryParams);
        }
        this.buttonClick.emit(event);
    }
}
