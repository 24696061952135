export interface AssetPropertyInfo {
    key: string;
    displayName: string;
    property: Compliance.AssetPropertyEnum;
    isDescriptor: boolean;
    isFurtherLimitTo?: boolean;
    isChangeFilter?: boolean;
    isSelected?: boolean;
}

export class AssetPropertyMap {
    static properties: AssetPropertyInfo[] = [
        { key: 'reportingAcqDate', isDescriptor: false, displayName: 'Acquisition Date', property: Compliance.AssetPropertyEnum.ReportingAcqDate, isFurtherLimitTo: true },
        { key: 'reportingAssetNumber', isDescriptor: false, displayName: 'Asset Number', property: Compliance.AssetPropertyEnum.ReportingAssetNumber, isFurtherLimitTo: true },
        { key: 'assetClassificationName', isDescriptor: false, displayName: 'Classification', property: Compliance.AssetPropertyEnum.AssetClassificationName, isFurtherLimitTo: true },
        { key: 'reportingCost', isDescriptor: false, displayName: 'Cost', property: Compliance.AssetPropertyEnum.ReportingCost, isFurtherLimitTo: true },
        { key: 'reportingDescription', isDescriptor: false, displayName: 'Description', property: Compliance.AssetPropertyEnum.ReportingDescription, isFurtherLimitTo: true },
        { key: 'reportingDisposedDate', isDescriptor: false, displayName: 'Disposed Date', property: Compliance.AssetPropertyEnum.ReportingDisposedDate, isFurtherLimitTo: true },
        { key: 'reportingGLAccountName', isDescriptor: false, displayName: 'G/L Account', property: Compliance.AssetPropertyEnum.ReportingGLAccountNameOrNumber, isFurtherLimitTo: true },
        { key: 'reportingGLAccountNumber', isDescriptor: false, displayName: 'G/L Account Number', property: Compliance.AssetPropertyEnum.ReportingGLAccountNumber, isFurtherLimitTo: false, isChangeFilter: false },
        { key: 'reportingSiteNameOrNumber', isDescriptor: false, displayName: 'Site', property: Compliance.AssetPropertyEnum.ReportingSiteNameOrNumber, isFurtherLimitTo: true },
        { key: 'reportingSiteName', isDescriptor: false, displayName: 'Site Name', property: Compliance.AssetPropertyEnum.ReportingSiteName, isFurtherLimitTo: false, isChangeFilter: false },
        { key: 'reportingSiteNumber', isDescriptor: false, displayName: 'Site Number', property: Compliance.AssetPropertyEnum.ReportingSiteNumber, isFurtherLimitTo: false, isChangeFilter: false },
        { key: 'reportingParcel', isDescriptor: false, displayName: 'Parcel', property: Compliance.AssetPropertyEnum.ReportingParcel, isFurtherLimitTo: true },
        { key: 'reportingT1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT1, isFurtherLimitTo: true },
        { key: 'reportingT2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT2, isFurtherLimitTo: true },
        { key: 'reportingT3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT3, isFurtherLimitTo: true },
        { key: 'reportingT4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT4, isFurtherLimitTo: true },
        { key: 'reportingT5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT5, isFurtherLimitTo: true },
        { key: 'reportingT6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT6, isFurtherLimitTo: true },
        { key: 'reportingT7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT7, isFurtherLimitTo: true },
        { key: 'reportingT8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT8, isFurtherLimitTo: true },
        { key: 'reportingT9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT9, isFurtherLimitTo: true },
        { key: 'reportingT10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT10, isFurtherLimitTo: true },
        { key: 'reportingT11', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT11, isFurtherLimitTo: true },
        { key: 'reportingT12', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT12, isFurtherLimitTo: true },
        { key: 'reportingT13', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT13, isFurtherLimitTo: true },
        { key: 'reportingT14', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT14, isFurtherLimitTo: true },
        { key: 'reportingT15', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT15, isFurtherLimitTo: true },
        { key: 'reportingT16', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT16, isFurtherLimitTo: true },
        { key: 'reportingT17', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT17, isFurtherLimitTo: true },
        { key: 'reportingT18', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT18, isFurtherLimitTo: true },
        { key: 'reportingT19', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT19, isFurtherLimitTo: true },
        { key: 'reportingT20', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT20, isFurtherLimitTo: true },
        { key: 'reportingT21', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT21, isFurtherLimitTo: true },
        { key: 'reportingT22', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT22, isFurtherLimitTo: true },
        { key: 'reportingT23', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT23, isFurtherLimitTo: true },
        { key: 'reportingT24', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT24, isFurtherLimitTo: true },
        { key: 'reportingT25', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT25, isFurtherLimitTo: true },
        { key: 'reportingT26', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT26, isFurtherLimitTo: true },
        { key: 'reportingT27', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT27, isFurtherLimitTo: true },
        { key: 'reportingT28', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT28, isFurtherLimitTo: true },
        { key: 'reportingT29', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT29, isFurtherLimitTo: true },
        { key: 'reportingT30', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingT30, isFurtherLimitTo: true },
        { key: 'reportingN1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN1, isFurtherLimitTo: true },
        { key: 'reportingN2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN2, isFurtherLimitTo: true },
        { key: 'reportingN3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN3, isFurtherLimitTo: true },
        { key: 'reportingN4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN4, isFurtherLimitTo: true },
        { key: 'reportingN5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN5, isFurtherLimitTo: true },
        { key: 'reportingN6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN6, isFurtherLimitTo: true },
        { key: 'reportingN7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN7, isFurtherLimitTo: true },
        { key: 'reportingN8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN8, isFurtherLimitTo: true },
        { key: 'reportingN9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN9, isFurtherLimitTo: true },
        { key: 'reportingN10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingN10, isFurtherLimitTo: true },
        { key: 'reportingD1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD1, isFurtherLimitTo: true },
        { key: 'reportingD2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD2, isFurtherLimitTo: true },
        { key: 'reportingD3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD3, isFurtherLimitTo: true },
        { key: 'reportingD4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD4, isFurtherLimitTo: true },
        { key: 'reportingD5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD5, isFurtherLimitTo: true },
        { key: 'reportingD6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD6, isFurtherLimitTo: true },
        { key: 'reportingD7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD7, isFurtherLimitTo: true },
        { key: 'reportingD8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD8, isFurtherLimitTo: true },
        { key: 'reportingD9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD9, isFurtherLimitTo: true },
        { key: 'reportingD10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.ReportingD10, isFurtherLimitTo: true },

        { key: 'effectiveDate', isDescriptor: false, displayName: 'EffectiveDate', property: Compliance.AssetPropertyEnum.EffectiveDate, isChangeFilter: false },
        { key: 'companyCode', isDescriptor: false, displayName: 'Company Code', property: Compliance.AssetPropertyEnum.CompanyCode, isChangeFilter: false },
        { key: 'CompanyName', isDescriptor: false, displayName: 'Company Name', property: Compliance.AssetPropertyEnum.CompanyName, isChangeFilter: false },
        { key: 'assetOwnershipType', isDescriptor: false, displayName: 'Asset Ownership Type', property: Compliance.AssetPropertyEnum.AssetOwnershipType, isChangeFilter: false },
        { key: 'assetVerificationReason', isDescriptor: false, displayName: 'Asset Verification Reason', property: Compliance.AssetPropertyEnum.AssetVerificationReason, isChangeFilter: false },
        { key: 'sourceAssetNumber', isDescriptor: false, displayName: 'Asset Number', property: Compliance.AssetPropertyEnum.SourceAssetNumber, isChangeFilter: true },
        { key: 'sourceAcqDate', isDescriptor: false, displayName: 'Acq Date', property: Compliance.AssetPropertyEnum.SourceAcqDate, isChangeFilter: true },
        { key: 'sourceDescription', isDescriptor: false, displayName: 'Description', property: Compliance.AssetPropertyEnum.SourceDescription, isChangeFilter: true },
        { key: 'sourceCost', isDescriptor: false, displayName: 'Cost', property: Compliance.AssetPropertyEnum.SourceCost, isChangeFilter: true },
        { key: 'sourceAdjustedCost', isDescriptor: false, displayName: 'Adjusted Cost', property: Compliance.AssetPropertyEnum.SourceAdjustedCost, isChangeFilter: false },
        { key: 'sourceSiteNameOrNumber', isDescriptor: false, displayName: 'Site', property: Compliance.AssetPropertyEnum.SourceSiteNameOrNumber, isChangeFilter: true },
        { key: 'sourceSiteName', isDescriptor: false, displayName: 'Site Name', property: Compliance.AssetPropertyEnum.SourceSiteName, isChangeFilter: false },
        { key: 'sourceSiteNumber', isDescriptor: false, displayName: 'Site Number', property: Compliance.AssetPropertyEnum.SourceSiteNumber, isChangeFilter: false },
        { key: 'sourceGLAccountName', isDescriptor: false, displayName: 'G/L Account', property: Compliance.AssetPropertyEnum.SourceGLAccountNameOrNumber, isChangeFilter: true },
        { key: 'sourceGLAccountNumber', isDescriptor: false, displayName: 'G/L Account Number', property: Compliance.AssetPropertyEnum.SourceGLAccountNumber, isChangeFilter: false },
        { key: 'sourceDisposedDate', isDescriptor: false, displayName: 'Disposed Date', property: Compliance.AssetPropertyEnum.SourceDisposedDate, isChangeFilter: true },
        { key: 'sourceT1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT1, isChangeFilter: true },
        { key: 'sourceT2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT2, isChangeFilter: true },
        { key: 'sourceT3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT3, isChangeFilter: true },
        { key: 'sourceT4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT4, isChangeFilter: true },
        { key: 'sourceT5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT5, isChangeFilter: true },
        { key: 'sourceT6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT6, isChangeFilter: true },
        { key: 'sourceT7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT7, isChangeFilter: true },
        { key: 'sourceT8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT8, isChangeFilter: true },
        { key: 'sourceT9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT9, isChangeFilter: true },
        { key: 'sourceT10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT10, isChangeFilter: true },
        { key: 'sourceT11', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT11, isChangeFilter: true },
        { key: 'sourceT12', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT12, isChangeFilter: true },
        { key: 'sourceT13', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT13, isChangeFilter: true },
        { key: 'sourceT14', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT14, isChangeFilter: true },
        { key: 'sourceT15', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT15, isChangeFilter: true },
        { key: 'sourceT16', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT16, isChangeFilter: true },
        { key: 'sourceT17', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT17, isChangeFilter: true },
        { key: 'sourceT18', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT18, isChangeFilter: true },
        { key: 'sourceT19', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT19, isChangeFilter: true },
        { key: 'sourceT20', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT20, isChangeFilter: true },
        { key: 'sourceT21', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT21, isChangeFilter: true },
        { key: 'sourceT22', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT22, isChangeFilter: true },
        { key: 'sourceT23', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT23, isChangeFilter: true },
        { key: 'sourceT24', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT24, isChangeFilter: true },
        { key: 'sourceT25', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT25, isChangeFilter: true },
        { key: 'sourceT26', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT26, isChangeFilter: true },
        { key: 'sourceT27', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT27, isChangeFilter: true },
        { key: 'sourceT28', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT28, isChangeFilter: true },
        { key: 'sourceT29', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT29, isChangeFilter: true },
        { key: 'sourceT30', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceT30, isChangeFilter: true },
        { key: 'sourceN1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN1, isChangeFilter: true },
        { key: 'sourceN2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN2, isChangeFilter: true },
        { key: 'sourceN3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN3, isChangeFilter: true },
        { key: 'sourceN4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN4, isChangeFilter: true },
        { key: 'sourceN5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN5, isChangeFilter: true },
        { key: 'sourceN6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN6, isChangeFilter: true },
        { key: 'sourceN7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN7, isChangeFilter: true },
        { key: 'sourceN8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN8, isChangeFilter: true },
        { key: 'sourceN9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN9, isChangeFilter: true },
        { key: 'sourceN10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceN10, isChangeFilter: true },
        { key: 'sourceD1', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD1, isChangeFilter: true },
        { key: 'sourceD2', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD2, isChangeFilter: true },
        { key: 'sourceD3', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD3, isChangeFilter: true },
        { key: 'sourceD4', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD4, isChangeFilter: true },
        { key: 'sourceD5', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD5, isChangeFilter: true },
        { key: 'sourceD6', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD6, isChangeFilter: true },
        { key: 'sourceD7', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD7, isChangeFilter: true },
        { key: 'sourceD8', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD8, isChangeFilter: true },
        { key: 'sourceD9', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD9, isChangeFilter: true },
        { key: 'sourceD10', isDescriptor: true, displayName: '', property: Compliance.AssetPropertyEnum.SourceD10, isChangeFilter: true },
        { key: 'isUserCreated', isDescriptor: false, displayName: 'User Created', property: Compliance.AssetPropertyEnum.IsUserCreated, isChangeFilter: false },
        { key: 'state', isDescriptor: false, displayName: 'State', property: Compliance.AssetPropertyEnum.State, isChangeFilter: false },
        { key: 'assessorName', isDescriptor: false, displayName: 'Assessor Name', property: Compliance.AssetPropertyEnum.AssessorName, isChangeFilter: false },
        { key: 'sourceState', isDescriptor: false, displayName: 'S. State', property: Compliance.AssetPropertyEnum.SourceState, isChangeFilter: false },
        { key: 'alternativeAcqDateDescriptorName', isDescriptor: false, displayName: 'Alternate Acq Date Source', property: Compliance.AssetPropertyEnum.AlternativeAcqDateDescriptorName, isChangeFilter: false },
        { key: 'alternativeCostDescriptorName', isDescriptor: false, displayName: 'Alternate Cost Source', property: Compliance.AssetPropertyEnum.AlternativeCostDescriptorName, isChangeFilter: false },
    ];
}
