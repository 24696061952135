import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppealFormItem } from '../appeal.application.model';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';

export interface AppealApplicationSetTopModalParams {
    appealFormList: AppealFormItem[];
}

@Component({
    selector: 'appeal-application-set-page-modal',
    templateUrl: `appealApplicationSetTopModal.component.html`
})
export class AppealApplicationSetTopModalComponent implements OnInit, IWeissmanModalComponent<AppealApplicationSetTopModalParams, number> {
    constructor(private readonly _modalRef: BsModalRef) { }

    params: AppealApplicationSetTopModalParams;
    result: number;
    topAppealFormRevisionId: number;

    private _originalTopAppealFormRevisionId: number;

    ngOnInit(): void {
        this.result = null;

        const topAppealCandidates = this.params.appealFormList.filter(a => a.isTopAppeal);
        this._originalTopAppealFormRevisionId = topAppealCandidates.length === 1 ?
            topAppealCandidates[0].appealFormRevisionId : null;
        this.topAppealFormRevisionId = this._originalTopAppealFormRevisionId;
    }

    cancel(): void {
        this._modalRef.hide();
    }

    save(): void {
        if (this.topAppealFormRevisionId !== this._originalTopAppealFormRevisionId) {
            this.result = this.topAppealFormRevisionId;
        }

        this._modalRef.hide();
    }
}
