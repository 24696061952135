import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { SnackBarModule } from '../Busy-Indicator/SnackBar/snackBar.module';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { NubComponent } from './Nub/nub.component';
import { NubService } from './Nub/nub.service';
import { NavOnboardingComponent } from './Onboarding-Modal/navOnboarding.component';
import { OnboardingBannerComponent } from './Onboarding-Modal/onboardingBanner.component';
import { SavedActionViewComponent } from './Saved-AV/savedActionView.component';
import { SavedActionViewService } from './Saved-AV/savedActionView.service';
import { SavedReportComponent } from './Saved-Report/savedReport.component';
import { SideNavComponent } from './Side-Nav/sideNav.component';
import { TopNavComponent } from './Top-Nav/topNav.component';
import { NavigationModalsModule } from './User-Save/Modals/navigationModals.module';
import { SavedSmartComponent } from './Saved-Smart/savedSmart.component';
import { SavePageComponent } from './User-Save/Save-Page/savePage.component';
import { SaveTabContentComponent } from './User-Save/Save-Tab-Content/saveTabContent.component';

import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { UserSaveService } from './User-Save/userSave.service';

@NgModule({
    imports: [
        WeissmanSharedModule,
        UIRouterUpgradeModule,
        NavigationModalsModule,
        SnackBarModule
    ],
    declarations: [
        TopNavComponent,
        SideNavComponent,
        SavePageComponent,
        SaveTabContentComponent,
        SavedSmartComponent,
        SavedActionViewComponent,
        SavedReportComponent,
        NubComponent,
        NavOnboardingComponent,
        OnboardingBannerComponent
    ],
    exports: [
        TopNavComponent,
        SideNavComponent,
        SavedSmartComponent,
        SavedActionViewComponent,
        SavedReportComponent,
        NubComponent,
        NavOnboardingComponent,
        OnboardingBannerComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // Tells Angular we  have custom tags in our template
    ]
})
export class NavigationModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('topNav', TopNavComponent);
        hybridAdapterUtility.downgradeNg2Component('sideNav', SideNavComponent);
        hybridAdapterUtility.downgradeNg2Provider('nubService', NubService);
        hybridAdapterUtility.downgradeNg2Provider('userSaveService', UserSaveService);
        hybridAdapterUtility.downgradeNg2Provider('SavedActionViewService', SavedActionViewService);
    }
}
