import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../Busy-Indicator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { AppealRecommendationRepository } from '../../appealRecommendation.repository';
import { BulkUpdateDynamicBase, BulkUpdateOption } from '../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

export interface AppealRecommendationCommandCenterBulkUpdateParams {
    selectedRowsInfo: Core.AppealRecommendationBulkUpdateRowInfoModel[],
    stateIds: number[],
    appealDeadlineStart: Date,
    appealDeadlineEnd: Date,
    assignedFilter: Core.AppealRecommendationCCAssignedFilterModel,
    descriptorInfo: Core.DescriptorInfoModel[],
    picklistInfo: Core.DescriptorPicklistModel[],
    appealRecommendationStatuses: Core.AppealRecommendationStatusModel[],
    canChangeAppealRecommendationStatus: boolean,
    canChangeIsClientApproved: boolean
    selectedSiteAnnualAssessmentIds: number[]
}

@Component({
    selector: 'appeal-recommendation-command-center-bulk-update',
    templateUrl: '../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component.html',
})
export class ARCCBulkUpdateComponent extends BulkUpdateDynamicBase<AppealRecommendationCommandCenterBulkUpdateParams, any> implements OnInit, IWeissmanModalComponent<AppealRecommendationCommandCenterBulkUpdateParams, any> {
    params: AppealRecommendationCommandCenterBulkUpdateParams;
    result: Core.AppealRecommendationBulkUpdateResultModel;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    isSaving: boolean = false;

    private _hasSelectedSites: boolean;
    private _hasSelectedParcels: boolean;

    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _appealRecommendationRepository: AppealRecommendationRepository,
        _bsModalRef: BsModalRef,
        _fb: UntypedFormBuilder
    ) { super(_bsModalRef, _fb) }

    @ViewChild('text', { static: true }) textTemplate: ElementRef;
    @ViewChild('number', { static: true }) numberTemplate: ElementRef;
    @ViewChild('currency', { static: true }) currencyTemplate: ElementRef;
    @ViewChild('date', { static: true }) dateTemplate: ElementRef;
    @ViewChild('picklist', { static: true }) picklistTemplate: ElementRef;

    ngOnInit(): void {
        this.header = `Bulk Update ${this.params.selectedRowsInfo.length} Records`;

        this._templateMap = new Map([
            [Core.DescriptorFieldTypes.Date, this.dateTemplate],
            [Core.DescriptorFieldTypes.Number, this.numberTemplate],
            [Core.DescriptorFieldTypes.Picklist, this.picklistTemplate],
            [Core.DescriptorFieldTypes.YesNo, this.picklistTemplate],
            [Core.DescriptorFieldTypes.Text, this.textTemplate],
            [Core.DescriptorFieldTypes.Currency, this.currencyTemplate],
        ]);

        this._hasSelectedSites = this.params.selectedRowsInfo.some(x => !x.parcelId);
        this._hasSelectedParcels = this.params.selectedRowsInfo.some(x => x.parcelId);

        if (this._hasSelectedSites) {
            if (this.params.canChangeAppealRecommendationStatus) {
                const appealRecommendationStatusControl = new UntypedFormControl(null);
                appealRecommendationStatusControl.disable();
                this.bulkUpdateForm.addControl('appealRecommendationStatusId', appealRecommendationStatusControl);

                this.updateOptions.push({
                    name: 'Rec Status',
                    isRequired: true,
                    template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
                    picklist: this.params.appealRecommendationStatuses.map(x => {
                        return {value: x.id, name: x.name} as Compliance.NameValuePair<number>
                    }),
                    action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                    hiddenField: Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove,
                    formControlName: 'appealRecommendationStatusId',
                    formControl: appealRecommendationStatusControl
                } as BulkUpdateOption);
            }

            if (this.params.canChangeIsClientApproved) {
                const isClientApprovedControl = new UntypedFormControl(null);
                isClientApprovedControl.disable();
                this.bulkUpdateForm.addControl('isClientApproved', isClientApprovedControl);

                this.updateOptions.push({
                    name: 'Client Approved',
                    isRequired: true,
                    template: this._templateMap.get(Core.DescriptorFieldTypes.YesNo),
                    picklist: this._getPicklist(Core.DescriptorFieldTypes.YesNo),
                    action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                    hiddenField: Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove,
                    formControlName: 'isClientApproved',
                    formControl: isClientApprovedControl
                } as BulkUpdateOption);
            }
        }

        this.params.descriptorInfo.sort((x, y) => x.name.localeCompare(y.name));
        this.params.descriptorInfo.forEach(x => {
            if (x.siteUsage !== Core.DescriptorUsageEnum.Never && this._hasSelectedSites) {
                const controlName = `s.${x.descriptorId}`;
                const control = new UntypedFormControl(null, [this._validators.get(x.fieldType)(x.validation)]);
                control.disable();
                this.bulkUpdateForm.addControl(controlName, control);

                this.updateOptions.push({
                    name: `Site ${x.name}`,
                    descriptorId: x.descriptorId,
                    isRequired: true,
                    template: this._templateMap.get(x.fieldType),
                    picklist: this._getPicklist(x.fieldType),
                    action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                    formControlName: controlName,
                    formControl: control
                } as BulkUpdateOption);
            }

            if (x.parcelUsage !== Core.DescriptorUsageEnum.Never && this._hasSelectedParcels) {
                const controlName = `p.${x.descriptorId}`;
                const control = new UntypedFormControl(null, [this._validators.get(x.fieldType)(x.validation)]);
                control.disable();
                this.bulkUpdateForm.addControl(controlName, control);

                this.updateOptions.push({
                    name: `Parcel ${x.name}`,
                    descriptorId: x.descriptorId,
                    isRequired: true,
                    template: this._templateMap.get(x.fieldType),
                    picklist: this._getPicklist(x.fieldType),
                    action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                    formControlName: controlName,
                    formControl: control
                } as BulkUpdateOption);
            }
        });

        this.isLoading = false;
    }

    async save(force: boolean = false): Promise<void> {
        this.isSaving = true;
        await this._bulkUpdate();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    private async _bulkUpdate(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        const formValues = this.bulkUpdateForm.value;
        try {
            const appealRecommendationStatusOption = this.updateOptions.find(x => x.name === 'Rec Status');
            const isClientApprovedOption = this.updateOptions.find(x => x.name === 'Client Approved');

            const model: Core.AppealRecommendationBulkUpdateModel = {
                selectedRowsInfo: this.params.selectedRowsInfo,
                stateIds: this.params.stateIds,
                selectedSiteAnnualAssessmentIds: this.params.selectedSiteAnnualAssessmentIds,
                appealDeadlineStart: this.params.appealDeadlineStart,
                appealDeadlineEnd: this.params.appealDeadlineEnd,
                assignedFilter: this.params.assignedFilter,
                appealRecommendationStatusAction: this._hasSelectedSites && this.params.canChangeAppealRecommendationStatus && appealRecommendationStatusOption.action,
                appealRecommendationStatus: this._hasSelectedSites && this.params.canChangeAppealRecommendationStatus && appealRecommendationStatusOption.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.ChangeTo ? formValues['appealRecommendationStatusId'] : null,
                isClientApprovedAction: this._hasSelectedSites && this.params.canChangeIsClientApproved && isClientApprovedOption.action,
                isClientApproved: this._hasSelectedSites && this.params.canChangeIsClientApproved && isClientApprovedOption.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.ChangeTo ? formValues['isClientApproved'] : null,
                descriptors: this.updateOptions
                    .filter(x => x.descriptorId && x.action !== Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange)
                    .map(x => {
                        return {
                            descriptorId: x.descriptorId,
                            action: x.action,
                            isSiteLevel: x.formControlName.startsWith('s.'),
                            value: x.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove
                                ? null :
                                formValues[x.formControlName],
                        } as Core.AppealRecommendationBulkUpdateDescriptorModel
                    })
            };

            this.result = await lastValueFrom(this._appealRecommendationRepository.bulkUpdate(model));

            this._bsModalRef.hide();
        } finally {
            this.isSaving = false;
            await busyRef.hide();
        }
    }

    private _getPicklist(fieldType: Core.DescriptorFieldTypes) {
        if (fieldType === Core.DescriptorFieldTypes.Picklist) {
            return this.params.picklistInfo.map(x => ({ name: x.name, value: x.name }));
        }

        if (fieldType === Core.DescriptorFieldTypes.YesNo) {
            return [
                { name: 'Yes', value: true },
                { name: 'No', value: false }
            ];
        }
    }
}
