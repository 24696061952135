import { Injectable } from '@angular/core';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { IntegrationHmac, IntegrationHmacDetail } from './manage-api-keys.models';

@Injectable()
export class ManageAPIKeysService {
    constructor(private http: SDHttpService) { }

    getPublicKeys(): Promise<IntegrationHmac[]> {
        return this.http.get('/api/ExternalAPIConfig/keys') as Promise<IntegrationHmac[]>;
    }

    getPublicKey(integrationHmacId: number): Promise<IntegrationHmacDetail> {
        return this.http.get('/api/ExternalAPIConfig/keys/' + integrationHmacId) as Promise<IntegrationHmacDetail>;
    }

    savePublicKey(integrationHmac: IntegrationHmacDetail): Promise<IntegrationHmacDetail> {
        return this.http.put('/api/ExternalAPIConfig/keys/' + integrationHmac.integrationHmacId, integrationHmac) as Promise<IntegrationHmacDetail>;
    }

    sendKeyEmail(userId: string, integrationHmac: IntegrationHmacDetail): Promise<void> {
        return this.http.post('/api/ExternalAPIConfig/KeyTransmittalRequest', {
            userId: userId,
            publicKey: integrationHmac.publicKey
        }) as Promise<void>;
    }

    createNewKey(companyId: number, keyName: string): Promise<IntegrationHmac> {
        return this.http.post('/api/ExternalAPIConfig/key', {
            companyId: companyId,
            name: keyName
        }) as Promise<IntegrationHmac>;
    }

    deleteKey(integrationHmacId): Promise<void> {
        return this.http.delete('/api/ExternalAPIConfig/keys/' + integrationHmacId);
    }
}
