export enum ReportType {
    ProjectStatus = 1,
    Budget = 2,
    Maar = 3,
    ProgressStatus = 4,
    Site = 5,
    AppealRecommendation = 6,
    ReturnScheduleSummary = 7,
    ReturnAssetDetail = 8,
    UserActivity = 9,
    BudgetVariance = 10,
    UserAccess = 11,
    CsrCommandCenter = 12,
    UserCSR = 13,
    AppealList = 14,
    SupportInstanceEscalationActivity = 15
}
