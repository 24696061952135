import { HelpContent } from '../../../../UI-Lib/Help-Tooltip';

export const ASSET_BULK_UPDATE_HELP: HelpContent[] = [
    {
        helpContentId: 'asset-bulk-update.isPerpetual',
        tooltipText: 'This field cannot be updated because the selected records do not have asset classifications which allow perpetual assets.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-bulk-update.isPerpetual-none',
        tooltipText: '',
        hasIcon: false
    },
    {
        helpContentId: 'asset-bulk-update.createNewSplitDisabled',
        tooltipText: 'New asset splits cannot be created because the selected records contain split assets.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-bulk-update.updateSplitDisabled',
        tooltipText: 'Asset splits cannot be updated because the selected records contain assets without two-way splits.',
        hasIcon: true
    }
];
