import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForFilingBatchProgressCell extends ICellRendererParams {
    resume: (params: ICellRendererParamsForFilingBatchProgressCell) => void;
}

@Component({
    selector: 'filing-batch-list-progress-status',
    template: '<filing-batch-progress [filingBatch]="filingBatch" [showStepName]="false" (click)="params.resume(params)"></filing-batch-progress>'
})
export class FilingBatchProgressCellRendererComponent implements ICellRendererAngularComp {
    filingBatch: Compliance.FilingBatchModel;
    params: ICellRendererParamsForFilingBatchProgressCell;

    agInit(params: ICellRendererParamsForFilingBatchProgressCell): void {
        this.filingBatch = params.data as Compliance.FilingBatchModel;
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
