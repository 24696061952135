import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface LongRunningProcessListGridActionCellRendererParams extends ICellRendererParams {
    updateError: (params: LongRunningProcessListGridActionCellRendererParams) => void;
    canUpdateError: (params: LongRunningProcessListGridActionCellRendererParams) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button"
            *ngIf="params && params.data && params.canUpdateError(params)"
            class="btn flat-button warning-button icon-button grid-action-button"
            (click)="params.updateError(params)"
            helpTooltip
            helpContentId="long-running-process-list.update-error"
            position="bottom">
            <i class="fa fa-stop"></i>
        </button>`
})
export class LongRunningProcessListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: LongRunningProcessListGridActionCellRendererParams;

    agInit(params: LongRunningProcessListGridActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
