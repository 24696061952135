import {of as observableOf,  Observable } from 'rxjs';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { EntityPickerService } from '../../Entity-Picker/entityPicker.service';
import { mergeMap } from 'rxjs/operators';

interface ParcelSelectorComponentChanges extends SimpleChanges {
    parcelId: SimpleChange;
    siteIds: SimpleChange;
}

@Component({
    selector: 'parcel-selector',
    templateUrl: './parcelSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ParcelSelectorComponent,
        multi: true
    }]
})
export class ParcelSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
    constructor(
        private readonly _entityPickerService: EntityPickerService
    ) { }

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() companyId;
    @Input() reportingParcelId;
    @Input() excludeParcelIds: number[];
    @Input() siteIds: number[];
    @Input() masterSiteIds: number[];
    @Input() isOverridden: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() loadParcelsOnInit: boolean = true;
    @Input() getParcels: (searchParams: any) => Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>>;
    @Input() optionFlagText: string;
    @Output() reportingParcelIdChange = new EventEmitter<number>();

    reportingParcelFilter: string = '';
    allReportingParcels: any[] = [];
    reportingParcels: any[] = [];
    selectedReportingParcelAcctNum: string = '';
    selectedReportingParcelSiteId: number;
    showOnlyParcelsUnderSite: boolean;

    // this component will be considered initialized when the reporting parcels have been loaded
    // in the meanwhile, any select reporting parcel attempts will be skipped
    isInitialized: boolean = false;

    reportingParcels$: Observable<Compliance.AssetClassificationModel[]> = (Observable
        .create((observer: any) => { observer.next(this.reportingParcelFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterReportingParcels(token)));

    ngOnInit() {
        if (this.loadParcelsOnInit) {
            this.loadParcels();
        }
    }

    ngOnChanges(changes: ParcelSelectorComponentChanges): void {
        if (changes.reportingParcelId || changes.siteIds) {
            this._selectReportingParcel(this.reportingParcelId);
        }
    }

    async loadParcels() {
        const searchParams = {
            companyId:  this.companyId,
            excludeParcelIds: this._filterInvalidExcludeParcelIds(this.excludeParcelIds)
        } as Compliance.ReportingParcelSearchModel;

        const reportingParcels = await this.getParcels(searchParams);

        this.allReportingParcels = reportingParcels.data;
        this.reportingParcels = this.allReportingParcels;

        this.isInitialized = true;
        this._selectReportingParcel(this.reportingParcelId);
    }

    writeValue(reportingParcelId: number): void {
        this._selectReportingParcel(reportingParcelId);
    }

    nge(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onReportingParcelBlur(): void {
        this._selectReportingParcel(this.reportingParcelId);
        this._onTouchedFn && this._onTouchedFn();
    }

    onReportingParcelSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const parcel = match.item as Compliance.ReportingParcelModel;
            this._selectReportingParcel(parcel.parcelId);
            this.reportingParcelIdChange.emit(parcel.parcelId);
        }
    }

    _filterInvalidExcludeParcelIds(excludeIds: number[]): number[] {
        return excludeIds ? excludeIds.filter((entry: number) => entry !== undefined && entry !== null) : [];
    }

    async openPickerModal() {
        const searchModel: Compliance.ReportingParcelSearchModel = {
            companyId: this.companyId,
            excludeParcelIds: this._filterInvalidExcludeParcelIds(this.excludeParcelIds),
            includeSiteIds: []
        };

        const entityPickerResult = await this._entityPickerService.showParcelPicker(searchModel, this.siteIds, this.masterSiteIds,
            this.selectedReportingParcelSiteId, this.getParcels, this.optionFlagText);

        if(entityPickerResult && entityPickerResult.result) {
            this._selectReportingParcel(entityPickerResult.result.parcelId);
            this.reportingParcelIdChange.emit(entityPickerResult.result.parcelId);
        }
    }

    private _filterReportingParcels(filter: string): Observable<Compliance.AssetClassificationModel[]> {
        if (filter === null || filter === undefined) filter = '';
        return observableOf(
            (this.reportingParcels || []).filter(i => i.parcelAcctNum.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _selectReportingParcel(parcelId: number): void {
        if (!this.isInitialized) return;

        const parcel = this.allReportingParcels.find(rp => rp.parcelId === parcelId);

        if (parcel) {
            this.reportingParcels = (this.siteIds || this.masterSiteIds) ? this.allReportingParcels.filter(x => (this.siteIds && this.siteIds.includes(x.siteId)) || (this.masterSiteIds && this.masterSiteIds.includes(x.siteId)) || x.parcelId === parcelId) : this.allReportingParcels;
            this.reportingParcelId = parcel.parcelId;
            this.selectedReportingParcelSiteId = parcel.siteId;
            this.reportingParcelFilter = parcel.parcelAcctNum;
            this.selectedReportingParcelAcctNum = parcel.parcelAcctNum;
            this._onChangeFn && this._onChangeFn(this.reportingParcelId);
        } else if (parcelId == null) {
            this.reportingParcelFilter = null;
            this.reportingParcels = this.siteIds ? this.allReportingParcels.filter(x => this.siteIds.includes(x.siteId)) : this.allReportingParcels;
        } else {
            this.reportingParcelFilter = this.selectedReportingParcelAcctNum;
            this.reportingParcels = this.siteIds ? this.allReportingParcels.filter(x => this.siteIds.includes(x.siteId)) : this.allReportingParcels;
        }
    }
}
