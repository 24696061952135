<ws-busy-indicator *ngIf="isBusy" message="Downloading"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Download Return Document"
                  subHeaderLabel="Select a Return document and click the download button to download a copy"
                  acceptLabel="Close"
                  [hideCancel]="true"
                  (save)="cancel()"
                  (cancel)="cancel()">
    <div class="mb-3">
        <label for="selectedReturnFormRevision">Form</label>
        <div class="input-group">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedReturnFormRevision"
                    (change)="onSelectedReturnFormRevisionChange()">
                <option *ngFor="let item of returnFormRevisions" [ngValue]="item">{{ getReturnFormRevisionDisplayName(item) }}</option>
            </select>
            <button type="button" class="btn btn-outline-secondary btn-sm" aria-label="Download" title="Download" (click)="downloadForm()" [disabled]="!selectedReturnFormRevision">
                <span class="fa fa-download"></span>
            </button>
        </div>
    </div>
    <div class="mb-3">
        <label for="selectedReturnFormRevisionReport">Report</label>
        <div class="input-group">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedReturnFormRevisionReport">
                <option *ngFor="let item of returnFormRevisionReports" [ngValue]="item">{{ getReportName(item) }}</option>
            </select>
            <button type="button" class="btn btn-outline-secondary btn-sm" aria-label="Download" title="Download" (click)="downloadReport()" [disabled]="!selectedReturnFormRevisionReport">
                <span class="fa fa-download"></span>
            </button>
        </div>
    </div>
</ws-modal-wrapper>
