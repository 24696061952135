
<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header pointer"  (click)="expandOrCollapse()" [class.pointer]="!editMode">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3 *ngIf="assessor">Jurisdiction Specialists</h3>
        <h3 *ngIf="!assessor">State Protocols</h3>
        <div class="float-end header-button-container" (click)="$event.stopPropagation()">
			<button class="flat-button icon-button primary-button"
                *ngIf="!editMode && showBody && hasEditPermission" (click)="goToEditMode()">
				<i class="fa fa-pencil"></i>
			</button>
			<div *ngIf="editMode && showBody">
				<button class="flat-button icon-button primary-button" [disabled]="exceptionToEdit" (click)="save()">
					<i class="fa fa-save"></i>
				</button>
				<button class="flat-button icon-button warning-button" (click)="cancel()">
					<i class="fa fa-close"></i>
				</button>
			</div>
		</div>
    </div>
    <div class="card-body" *ngIf="showBody">
        <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
        <table class="table table-condensed">
            <tbody>
                <tr *ngFor="let row of jurisdictionSpecialists">
                    <td class="text-end">
                        <strong>{{row.propertyTypeName}}</strong>
                    </td>
                    <td>
                        <div *ngIf="!editMode"
                            [style.color]="row.jurisdictionSpecialist.isException ? 'red' : 'initial'">
                                {{getJSDisplay(row)}}
                        </div>
                        <div *ngIf="editMode" style="display: flex;">
                            <div style="width: 400px;">
                                <user-team-picker [restrictToRyanInstance]="true"
                                    [disabled]="row.isNoneAssigned || row.disablePicker"
                                    [userId]="row.jurisdictionSpecialist.userID"
                                    [teamId]="row.jurisdictionSpecialist.teamID"
                                    (userChange)="jsUserChanged($event, row)">
                                </user-team-picker>
                            </div>
                            <div *ngIf="row.showNoneAssigned" class="checkbox" style="margin: 5px 0 0 20px;">
                                <label>
                                    <input type="checkbox"
                                        [(ngModel)]="row.isNoneAssigned"
                                        (ngModelChange)="noneAssignedChanged(row)"
                                        [disabled]="row.disablePicker"> None Assigned
                                </label>
                            </div>
                        </div>
                        <div *ngIf="row.siteClassExceptions.length" style="margin-top: 8px">
                            <div style="font-style: italic; text-decoration: underline;">Exceptions (Specific Site Class Specialists):</div>
                            <table class="exceptions-table">
                                <tr *ngFor="let exception of row.undeletedExceptions">
                                    <td>{{exception.siteClassName}}</td>
                                    <td>
                                        <span *ngIf="!isExceptionInEditMode(exception)">{{getAssigneeDisplay(exception)}}</span>
                                        <user-team-picker *ngIf="isExceptionInEditMode(exception)"
                                            [restrictToRyanInstance]="true"
                                            [userId]="exceptionToEdit.userID"
                                            [teamId]="exceptionToEdit.teamID"
                                            (userChange)="exceptionUserChanged($event, exception)"
                                            [required]="true" >
                                        </user-team-picker>
                                    </td>
                                    <td *ngIf="editMode">
                                        <button *ngIf="isExceptionInEditMode(exception)" class="flat-button icon-button warning-button" style="margin: 0;" (click)="cancelUserChange(exception, row)">
                                            <i class="fa fa-close"></i>
                                        </button>
                                        <span [class.invisible]="exceptionToEdit">
                                            <button class="flat-button icon-button primary-button" style="margin: 0;" (click)="editException(exception)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                            <button class="flat-button icon-button warning-button" style="margin: 0;" (click)="deleteException(exception, row)">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="editMode && assessor" style="margin: 15px 0; width: 400px">
                            <ws-select placeholder="Add Site Class"
                                [(ngModel)]="row.chosenSiteClass"
                                (selectedOptionChanged)="addSiteClass(row)"
                                [options]="row.availableSiteClasses"
                                [disabled]="!!exceptionToEdit || row.disablePicker"
                                labelProperty="name"
                                [returnEntireOption]="true">
                            </ws-select>
                        </div>
                    </td>
                    <td>
                        <button *ngIf="!editMode && row.propertyTypeXStateHasChildExceptions"
                            class="flat-button primary-button small-button"
                            (click)="showExceptionsModal(row)">
                                Assessor Exceptions
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
