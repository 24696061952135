<ws-modal-wrapper headerLabel="Announcing Changes to the Navigation"
                  cancelLabel="SKIP"
                  [hideCancel]="currentSlide === slides.length - 1"
                  [acceptLabel]="progressBtnLabel"
                  [useAceStyles]="true"
                  (save)="progress()"
                  (cancel)="cancel($event)">
    <ws-carousel [slides]="slides"
              [(currentSlide)]="currentSlide"
              [imgHeight]="800"
              [imgWidth]="600"
              [disableControls]="true">
    </ws-carousel>
</ws-modal-wrapper>
