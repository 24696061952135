import { Component, OnInit, OnDestroy } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../../../../../Common/Routing/upgrade-navigation-handler.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ReturnService } from '../../../../return.service';
import { FieldOverride } from '../../../../../../Common/PDFViewer/pdfViewerBase';
import { Constants } from '../../../../../../constants.new';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../../../../../WeissmanModalService';

export interface ReturnPreviewFormOverrideEditParams {
    override: FieldOverride;
    oldValue: string;
    rowVersion: number[];
    ruleResults: Compliance.ReturnFormFieldRuleResultModel[];
    canEdit: boolean;
}

export interface ReturnPreviewFormOverrideEditResult {
    deleteConfirmed: boolean;
    rowVersion: number[];
    override: FieldOverride;
}

@Component({
    selector: 'return-preview-form-override-edit',
    templateUrl: './returnPreviewFormOverrideEdit.component.html',
})
export class ReturnPreviewFormOverrideEditComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ReturnPreviewFormOverrideEditParams, ReturnPreviewFormOverrideEditResult> {
        constructor(
            private readonly _modalRef: BsModalRef,
            private readonly _returnService: ReturnService,
            private readonly _routerService: UpgradeNavigationServiceHandler,
            private readonly _constants: Constants) { }

    private _returnReadOnlyFlagSub: Subscription;

    params: ReturnPreviewFormOverrideEditParams;
    result: ReturnPreviewFormOverrideEditResult;

    canEdit: boolean = false;
    oldValue: string;
    newValue: string;
    fieldName: string;
    rowVersion: number[];
    override: FieldOverride;
    deleteConfirmed: boolean = false;
    fieldEntityTypes = this._constants.ReturnFormFieldEntityTypes;

    modalTitle: string = 'Form Field Override';

    ruleResults: Compliance.ReturnFormFieldRuleResultModel[] = [];

    ngOnInit(): void {
        if (this.params.override) {
            this.newValue = this.params.override.overrideValue;
            this.fieldName = this.params.override.fieldName;
            this.override = this.params.override;
            this.rowVersion = this.params.rowVersion;
        }

        this.oldValue = this.params.oldValue;
        this.ruleResults = this.params.ruleResults;
        this.canEdit = this.override && (this.params.canEdit || (this._returnService.canEditCompany && !this._returnService.isReturnInReadOnlyMode));

        this._returnReadOnlyFlagSub = this._returnService.isReturnInReadOnlyMode$.subscribe(x => {
            this.canEdit = this.override && (this.params.canEdit || (this._returnService.canEditCompany && !x));
        });

        if (!this.override) {
            this.modalTitle = 'Form Field';
        }
    }

    ngOnDestroy(): void {
        this._returnReadOnlyFlagSub && this._returnReadOnlyFlagSub.unsubscribe();
    }

    isFilingRuleResult(ruleResult: Compliance.ReturnFormFieldRuleResultModel): boolean {
        return ruleResult.entityType === Compliance.ReturnFormFieldEntityType.Filing ||
            ruleResult.entityType === Compliance.ReturnFormFieldEntityType.FilingBatch;
    }

    hasLink(ruleResult: Compliance.ReturnFormFieldRuleResultModel): boolean {
        return [Compliance.ReturnFormFieldEntityType.Company,
            Compliance.ReturnFormFieldEntityType.Assessor,
            Compliance.ReturnFormFieldEntityType.Parcel,
            Compliance.ReturnFormFieldEntityType.ParcelDescriptor,
            Compliance.ReturnFormFieldEntityType.GeneralReturnSetting,
            Compliance.ReturnFormFieldEntityType.Site,
            Compliance.ReturnFormFieldEntityType.SiteDescriptor].includes(ruleResult.entityType);
    }

    cancel(): void {
        this._modalRef.hide();
    }

    revert(): void {
        this.result = {
            deleteConfirmed: true,
            override: this.override,
            rowVersion: this.rowVersion
        };

        this._modalRef.hide();
    }

    getFieldEntityName(typeEnumVal : Compliance.ReturnFormFieldEntityType) : string{
        return _.find(this.fieldEntityTypes, x => x.value === typeEnumVal).name;
    }

    getEntityUrl(ruleResult: Compliance.ReturnFormFieldRuleResultModel): string {
        let url = '';

        if ([Compliance.ReturnFormFieldEntityType.Company,
            Compliance.ReturnFormFieldEntityType.GeneralReturnSetting,
            Compliance.ReturnFormFieldEntityType.SupplementalField].includes(ruleResult.entityType)) {
            url = this._routerService.getHrefFromState('company', { 'companyId': ruleResult.entityId });
        } else if (ruleResult.entityType === Compliance.ReturnFormFieldEntityType.Assessor) {
            url = this._routerService.getHrefFromState('assessorRedirect', { 'entityID': ruleResult.entityId });
        } else if (ruleResult.entityType === Compliance.ReturnFormFieldEntityType.Parcel ||
            ruleResult.entityType === Compliance.ReturnFormFieldEntityType.ParcelDescriptor) {
            url = this._routerService.getHrefFromState('parcelRedirect', { 'entityID': ruleResult.entityId });
        } else if (ruleResult.entityType === Compliance.ReturnFormFieldEntityType.Site ||
            ruleResult.entityType === Compliance.ReturnFormFieldEntityType.SiteDescriptor) {
            url = this._routerService.getHrefFromState('siteRedirect', { 'entityID': ruleResult.entityId });
        }

        return url;
    }
}
