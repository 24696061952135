import {Component, OnInit} from '@angular/core';
import {IWeissmanModalComponent} from '../../../Compliance/WeissmanModalService';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AppealApplicationService} from '../../appeal.application.service';
import {Subscription} from 'rxjs';
import {AppealFormItem} from '../../appeal.application.model';

export interface EditAppealMainAssociationsComponentParams {
    selectedAppeal: AppealFormItem;
    mainForms: Compliance.AppealMainFormAssociation[];
}

export interface AvailableMainFormEditModel extends Compliance.AppealMainFormAssociation {
    isAssociatedInitial: boolean;
    hasChanges: boolean;
    totalAppeals: number;
}

@Component({
    selector: 'edit-appeal-supplement-associations',
    templateUrl: './editAppealMainAssociations.component.html'
})
export class EditAppealMainAssociationsComponent implements IWeissmanModalComponent<EditAppealMainAssociationsComponentParams, boolean>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _appealApplicationService: AppealApplicationService
    ) {
        this.subscriptions = new Subscription();
    }

    params: EditAppealMainAssociationsComponentParams;
    result: boolean;

    isBusy: boolean;
    busyMessage: string;
    selectedApplyChangesScopeOption: Compliance.NameValuePair<string>;
    formRevisionAssociations: AvailableMainFormEditModel[] = [];
    hasChanges: boolean;

    private readonly subscriptions: Subscription;
    newMainFormRevisionId: number;

    async ngOnInit(): Promise<void> {
        let appealMainFormAssociation = this.params.mainForms.filter(m => m.formRevisionId === this.params.selectedAppeal.formRevisionId)[0];
        this.newMainFormRevisionId = appealMainFormAssociation?.formRevisionId;
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        this.busyMessage = 'Updating Form Associations';
        this.isBusy = true;

        try {
            if (!this.newMainFormRevisionId) {
                this.result = false;
                return;
            }
            const updateRequest: Compliance.AppealMainFormAssociationChangeRequest = {
                appealId: this.params.selectedAppeal.appealId,
                formRevisionId: this.newMainFormRevisionId
            };
            await this._appealApplicationService.changeMainFormAssociations(updateRequest);
            this.result = true;
        } finally {
            this.isBusy = false;
            this.cancel();
        }
    }

}
