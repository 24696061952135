(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('ErrorNotificationModalController', ErrorNotificationModalController);

    ErrorNotificationModalController.$inject = ['errors', 'warnings', 'errorMessage', 'warningMessage', '$uibModalInstance'];

    function ErrorNotificationModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(errors, warnings, errorMessage, warningMessage, $uibModalInstance) {
        var vm = this;
        vm.errors = errors;
        vm.warnings = warnings;
        if (errorMessage && !warningMessage) {
            vm.topLevelMessage = errorMessage;
        }
        else {
            vm.errorMessage = errorMessage;
            vm.warningMessage = warningMessage;
        }

        activate();

        function activate() { }

        vm.close = function () {
            $uibModalInstance.dismiss();
        }
    }
})();
