import { Component, Input } from '@angular/core';
import { ContractsInvoicesService } from './contract-invoices.service';
import { SiteContractTerm } from './contract-invoices.model';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { RestrictService, Roles } from '../Permissions/restrict.service';
import { TimerService } from '../../UI-Lib/Utilities/timer.service';
import { CommentsModalService } from '../../Comments/commentsModal.service';
import * as _ from 'lodash';

@Component({
    selector: 'contract-terms-panel-site',
    templateUrl: './contract-terms-panel-site.component.html'
})
export class ContractTermsPanelSiteComponent {
    @Input() entity: { id: number, name: string, newAllowed: boolean, type: string, hasWritePermission: boolean };

    isLoading: boolean = false;
    hasViewPermission: boolean = false;
    hasEditPermission: boolean = false;
    showBody: boolean = false;
    isEditMode: boolean = false;
    contractTermYears: number[] = [];
    selectedYear: number;
    allData: SiteContractTerm[];
    contractTerms: SiteContractTerm[];
    currentYear: number = new Date().getFullYear();

    constructor(private readonly _contractsInvoicesService: ContractsInvoicesService,
                private readonly _toastr: ToastrService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _commentsModalService: CommentsModalService,
                private readonly _restrictService: RestrictService,
                private readonly _timer: TimerService) {
        this.hasViewPermission = _restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        this.hasEditPermission = _restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
    }

    expandOrCollapse(): void {
        if(this.isEditMode) {
            return;
        }

        this.showBody = !this.showBody;

        if(this.showBody) {
            this.loadPanel();
        }
    }

    loadPanel(): void {
        this.isLoading = true;
        this._contractsInvoicesService.getContractTermsBySite(this.entity.id)
            .then((result: SiteContractTerm[]) => {
                this.populateContractTermData(result);
                this.isLoading = false;
            });

    }

    showComments(term: SiteContractTerm): void {
        this._commentsModalService.openAddCommentModal({
            title: 'Contract Term Remarks',
            commentBody: term.currentTermItem.remarks,
            allowBlank: true,
            readOnly: true,
            entityTypeName: 'site',
            entityId: this.entity.id
        });
    }

    private populateContractTermData(data: SiteContractTerm[]): void {
        this.allData = data;

        this.contractTermYears = this.populateYearsDropdown();
        this.selectedYear = this.getSelectedYear();
        this.getDataForYear();
    }

    private populateYearsDropdown(): number [] {
        return _.chain(this.allData)
            .map('taxYear')
            .uniq()
            .sortBy()
            .reverse()
            .value() as number[];
    }

    private getSelectedYear(): number {
        if (this.contractTermYears.length) {
            let selectedYear: number = _.includes(this.contractTermYears, this.selectedYear) ? this.selectedYear : null;
            let currentYear: number = _.includes(this.contractTermYears, this.currentYear) ? this.currentYear : null;

            return selectedYear || currentYear || _.maxBy(this.contractTermYears) as number;
        } else {
            return 0;
        }
    }

    getDataForYear(): void {
        this.selectedYear = Number(this.selectedYear);

        this.contractTerms = _.chain(this.allData)
            .filter((term: SiteContractTerm) => {
                return this.selectedYear === 0 || term.taxYear == this.selectedYear;
            })
            .cloneDeep()
            .map((term: SiteContractTerm) => {
                if(term.currentTermItem) {
                    term.currentTermItem = _.find(term.availableTermItems, {termDetailId: term.currentTermItem.termDetailId});
                }

                return term;
            })
            .value() as SiteContractTerm[];
    }

    goToEditMode(): void {
        this.isEditMode = true;
    }

    cancelEdit(): void {
        this.getDataForYear();

        this.isEditMode = false;
    }

    savePanel(): void {
        this.isLoading = true;
        this._contractsInvoicesService.saveSiteContractTerms(this.entity.id, this.contractTerms)
            .then((result: SiteContractTerm[]) => {
                this.populateContractTermData(result);

                this.isLoading = false;
                this.isEditMode = false;
            })
            .catch((error) => {
                this.isLoading = false;
            })

    }

    //this.selectedYear = old
    //e = new
    yearChanged(e): void {
        let oldVal = this.selectedYear;

        if (this.aContractTermHasChanged()) {
            this._messageBoxService.open({
                message: 'Discard changes for current changes and switch year selection?',
                buttons: MessageBoxButtons.OKCancel
            })
                .then((result: number) => {
                    if (result === MessageBoxResult.OK) {
                        this.getDataForYear();
                    } else {
                        this._timer.setTimeout(() => {
                            this.selectedYear = oldVal;
                        });
                    }
                })
        } else {
            this._timer.setTimeout(() => {
                this.getDataForYear();
            });
        }

    }

    private aContractTermHasChanged(): boolean {
        return _.some(this.contractTerms, (term: SiteContractTerm) => {
            let foundTerm: SiteContractTerm = _.find(this.allData, { termId: term.termId }) as SiteContractTerm;

            return !_.isEqual(foundTerm, term) as boolean;
        }) as boolean;
    }

    isTermNotSiteDefault(term: SiteContractTerm) {
        if(term.currentTermItem) {
            return term.currentTermItem.termDetailId !== term.companySiteDefaultTermId
        } else {
            return term.companySiteDefaultTermId;
        }
    }
}
