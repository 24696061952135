import { Collector } from '../../Assessor-Collector/collector.model';

export class Refund {
	annualYearID: number;
	collector: Collector;
	collectorID: number;
	efAction: string;
	expectedDate: Date;
	hasComments: boolean;
	hasAttachments: boolean;
	refundAmount: number;
	refundID: number;
	refundRowVersion: string;
	annualYear: any;
	rowVersion: any;
	accrualBegin: Date;
	accrualEnd: Date;
	originalAccrualBegin: Date;
	originalAccrualEnd: Date;
	checkNumber: string; 
	hasInProgressIntakeItems: boolean;
	annualAssessmentID: number;
		
	// Derived Values
	taskSummary: any;

	constructor(annualYearID: number, collectorID: number) {
		this.annualYearID = annualYearID;
		this.collectorID = collectorID;
	}
}
