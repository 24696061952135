import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportDetail } from '../report.manager.model';
import { ReportManagerService } from '../report.manager.service';
import { BudgetVarianceBudgetSource, BudgetVarianceFormatOutputCriteria } from '../report.manager.model';
import { Subscription } from "rxjs";
import { CompanyBudgetBasisEnum } from '../../../Budget/forecasting.budgeting.model';

@Component({
    selector: 'format-output-panel-budget-variance',
    templateUrl: './format.output.panel.budget.variance.component.html'
})
export class FormatOutputPanelBudgetVarianceComponent implements OnInit  {
    @Input() report: ReportDetail;

    CompanyBudgetBasisEnum = CompanyBudgetBasisEnum;
    outputFormatOptions: string[] = [ 'Summary Report (PDF)', 'Summary Report (Excel)'];
    detailLevelOptions: string[] = ['Site', 'Parcel', 'None'];
    varianceDetailOptions: string[] = ['Amount', 'Percent', 'Amount and Percent'];

    firstBudgetSources: BudgetVarianceBudgetSource[] = [];
    secondBudgetSources: BudgetVarianceBudgetSource[] = [];
    thirdBudgetSources: BudgetVarianceBudgetSource[] = [];
    fourthBudgetSources: BudgetVarianceBudgetSource[] = [];

    private _selectedBudgetsChangedSub: Subscription;
    private _loading: boolean = false;

    private NoSelectionBudgetSource: BudgetVarianceBudgetSource = {
        budgetId: -1,
        budgetName: "Make a selection",
        budgetBasis: Core.CompanyBudgetBasisEnum.AccrualAndCash,
        budgetPeriod: {
            periodBegin: null,
            periodEnd: null
        }
    }
    private filteredBudgetSources: BudgetVarianceBudgetSource[] = [];
    private noForecastBudgetSources: BudgetVarianceBudgetSource[] = [];


    constructor(private reportManagerService: ReportManagerService) {}

    ngOnInit() {
        // I am using a loading flag here to prevent loaded Source selections from being wiped
        // out by refreshBudgetSources() because the observable is firing on subscribe before
        // exiting this method and beforee the selectable Budget Sources have been populated.
        this._loading = true;
        this._setDefaultsBudget();

        this._selectedBudgetsChangedSub = this.reportManagerService.selectableBudgetsChanged$.subscribe(() => {
            this.refreshBudgetSources();
        });
        this._loading = false;
    }

    ngOnDestroy() {
        this._selectedBudgetsChangedSub.unsubscribe();
    }

    private _setDefaultsBudget(): void {
        this.report.criteria.bvrFormatOutput = this.report.criteria.bvrFormatOutput || new BudgetVarianceFormatOutputCriteria();
        if ( this.report.criteria.bvrFormatOutput.budgetSources )
        {
            // Prepopulate the budgetSources with NoSelection objects.
            for ( var i=this.report.criteria.bvrFormatOutput.budgetSources.length; i < 4; i++ )
            {
                this.report.criteria.bvrFormatOutput.budgetSources.push(_.cloneDeep(this.NoSelectionBudgetSource));
            }
        }
    }


    async refreshBudgetSources() : Promise<void> {
        this.filteredBudgetSources = _.filter(this.reportManagerService.selectableBudgetSources, budget => {
            // Filter to only budgets configured with a matching Budget Basis.
            switch(budget.budgetBasis) {
                case Core.CompanyBudgetBasisEnum.AccrualAndCash:
                    return true;
                case Core.CompanyBudgetBasisEnum.Accrual:
                    return this.report.criteria.bvrFormatOutput.budgetBasis == Core.CompanyBudgetBasisEnum.Accrual;
                case Core.CompanyBudgetBasisEnum.Cash:
                    return this.report.criteria.bvrFormatOutput.budgetBasis == Core.CompanyBudgetBasisEnum.Cash;
            }
        });
        this.noForecastBudgetSources = _.filter(this.filteredBudgetSources, (budget) => budget.budgetId > 0);

        // Populate the SourceBudgets arrays with appropriate selections.
        this.sourceBudgetChanged();
    }


    sourceBudgetChanged()
    {
        // Populate the SourceBudgets arrays with appropriate selections.
        let srcIndex = 0;
        this.firstBudgetSources = _.concat([_.cloneDeep(this.NoSelectionBudgetSource)], _.filter(this.filteredBudgetSources, (budget) => {
            return _.every(this.report.criteria.bvrFormatOutput.budgetSources, (sel, index) => {
                // Include our own selection, and any budget not selected for any other source.
                return index === 0 || budget.budgetId != sel.budgetId;
            });
        }));
        this.confirmSelection(0, this.firstBudgetSources);

        srcIndex++;
        this.secondBudgetSources = _.concat([_.cloneDeep(this.NoSelectionBudgetSource)], _.filter(this.noForecastBudgetSources, (budget) => {
            return _.every(this.report.criteria.bvrFormatOutput.budgetSources, (sel, index) => {
                // Include our own selection, and any budget not selected for any other source.
                return index === 1 || budget.budgetId != sel.budgetId;
            });
        }));
        this.confirmSelection(1, this.secondBudgetSources);

        srcIndex++;
        this.thirdBudgetSources =_.concat([_.cloneDeep(this.NoSelectionBudgetSource)], _.filter(this.noForecastBudgetSources, (budget) => {
            return _.every(this.report.criteria.bvrFormatOutput.budgetSources, (sel, index) => {
                // Include our own selection, and any budget not selected for any other source.
                return index === 2 || budget.budgetId != sel.budgetId;
            });
        }));
        this.confirmSelection(2, this.thirdBudgetSources);

        srcIndex++;
        this.fourthBudgetSources =_.concat([_.cloneDeep(this.NoSelectionBudgetSource)], _.filter(this.noForecastBudgetSources, (budget) => {
            return _.every(this.report.criteria.bvrFormatOutput.budgetSources, (sel, index) => {
                // Include our own selection, and any budget not selected for any other source.
                return index === 3 || budget.budgetId != sel.budgetId;
            });
        }));
        this.confirmSelection(3, this.fourthBudgetSources);
    }

    private confirmSelection(srcIndex: number, budgetSources: BudgetVarianceBudgetSource[])
    {
        if ( this._loading )
            return;

        let selectionAvailable = _.find(budgetSources, ['budgetId', this.report.criteria.bvrFormatOutput.budgetSources[srcIndex].budgetId]);
        if ( selectionAvailable )
        {
            // Save selection.
            this.report.criteria.bvrFormatOutput.budgetSources[srcIndex] = selectionAvailable;
        }
        else
        {
            // Clear selection.
            this.report.criteria.bvrFormatOutput.budgetSources[srcIndex] = _.find(budgetSources, ['budgetId', -1]);
        } 
    }
}