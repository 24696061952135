<div class="container">
	<div class="single-column-layout">
		<h1>Manage API Keys</h1>
        <span class="anchor-style" (click)="backToManage()">Back to list</span>
        <div [style.display]="loading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="!loading" style="margin-top: 1em;" class="manage-api-key-detail">
            <div class="card panel-flat-color primary-panel" [ngClass]="(inEditMode) ? 'edit-mode' : 'view-mode'">
                <div class="panel-flat-header">
                    <div class="header-icon float-start">
                        <i class="fa fa-info fa-fw"></i>
                    </div>
                    <h3>API Key</h3>
                    <div class="float-end header-button-container">
                        <button *ngIf="!inEditMode" (click)="edit_click()" type="button" class="btn flat-button icon-button primary-button">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <span *ngIf="inEditMode">
                            <button (click)="save_click()" type="button" class="btn flat-button icon-button success-button">
                                <i class="fa fa-save"></i>
                            </button>
                            <button (click)="cancel_click()" type="button" class="btn flat-button icon-button warning-button">
                                <i class="fa fa-close"></i>
                            </button>
                        </span>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="panel-flat-body">
                    <label>
                        <span class="title">Active:</span>
                        <span *ngIf="integrationHmacDetail.isActive" class="view-control">Yes</span>
                        <span *ngIf="!integrationHmacDetail.isActive" class="view-control">No</span>
                        <input type="checkbox" [(ngModel)]="integrationHmacDetail.isActive" class="edit-control" />
                    </label>
                    <label>
                        <span class="title">Name:</span>
                        <span class="view-control">{{integrationHmacDetail.name}}</span>
                        <input type="text" [(ngModel)]="integrationHmacDetail.name" class="edit-control" />
                        <span *ngIf="keyNameValidationMessage" class="validation-error">{{keyNameValidationMessage}}</span>
                    </label>
                    <label>
                        <span class="title">Public Key:</span>
                        <span class="both-control">{{integrationHmacDetail.publicKey}}</span>
                    </label>
                    <label>
                        <span class="title">Company:</span>
                        <span class="both-control">{{integrationHmacDetail.companyName}}</span>
                    </label>
                    <div>
                        <h3>Users:</h3>
                        <table class="user-table">
                            <tbody>
                                <tr *ngFor="let user of integrationHmacDetail.users; trackBy:identify">
                                    <td>{{user.lastName}}, {{user.firstName}}</td>
                                    <td>
                                        <button (click)="sendEmail(user)" type="button" class="view-control flat-button primary-button small-button">
                                            Send Private Key Email
                                        </button>
                                        <button (click)="removeUser(user)" type="button" class="edit-control btn flat-button icon-button warning-button">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <button *ngIf="!addingUser" (click)="beginAddUser_click()" class="edit-control flat-button primary-button">Add User</button>
                            <div [style.display]="addingUser ? 'inline-block' : 'none'">
                                <div style="display:inline-block; width: 15em;">
                                    <user-team-picker [user]="newUser" (userChange)="setNewUser($event)"></user-team-picker>
                                </div>
                                <button (click)="saveAddUser_click()" type="button" class="btn flat-button icon-button success-button">
                                    <i class="fa fa-save"></i>
                                </button>
                                <button (click)="cancelAddUser_click()" type="button" class="btn flat-button icon-button warning-button">
                                    <i class="fa fa-close"></i>
                                </button>
                            </div>
                            <button (click)="deleteKey_click()" class="edit-control flat-button warning-button" style="float:right;">Delete Key</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
