(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('PopupChildController', PopupChildController);

    PopupChildController.$inject = ['$scope', '$window', 'sd.PubSub.Service', 'sd.Popup.Service'];

    function PopupChildController($scope, $window, pubSub, popup) { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
        var vm = this;
        vm.event = {
            timestamp: new Date(),
            message: '(no event published yet)'
        };
        vm.title = 'PopupChildController';

        activate();

        ////////////////

        function activate() {
            popup.activatePopupChild();
            pubSub.subscribe(pubSub.topics.processing.documents.attachmentChanged, function (msg, data) {
                vm.event.timestamp = new Date();
                vm.event.message = data;
                $scope.$apply();
            });
        }

        $scope.$on('$destroy', function () {
            // Clean up subscriptions to prevent leaks
            pubSub.unsubscribe(pubSub.topics.processing.documents.attachmentChanged);
            popup.orphanThisWindow();
        });

        vm.publish = function () {
            pubSub.publish(pubSub.topics.processing.documents.attachmentChanged, pubSub.eventScopes.popup, 'Event published from child page');
        };

        vm.hasParent = function () {
            return popup.hasParent();
        };

        vm.navigate = function () {
            pubSub.publish(pubSub.topics.processing.documents.actionViewNavigate, pubSub.eventScopes.popup, '#/home');
        };
    }
})();

