<div *ngFor="let check of options">
    <div class="ws-flex-container-vertical">
        <label class="ws-flex-none small ws-font-weight-normal" [for]="check.formControlName">
            <ws-tri-state-checkbox [id]="check.formControlName"[formControl]="check.formControl" (change)="changed.emit(check)"></ws-tri-state-checkbox>
            <span class="filter-label" (click)="changedFromClick(check)">{{check.name}}</span>
            <ws-multi-select *ngIf="check.additionalSelect"
                          labelProperty="displayName"
                          valueProperty="property"
                          placeholder="Please select a value..."
                          [options]="check.additionalSelect.options"
                          [formControl]="check.additionalSelect.formControl">
            </ws-multi-select>
        </label>
    </div>
    <div *ngIf="check.children" class="advanced-filter-sub-group">
        <advanced-detail-checkbox [options]="check.children" (changed)="changed.emit($event)"></advanced-detail-checkbox>
    </div>
</div>
