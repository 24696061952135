import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { StateService } from '../../../Common/States/States.Service';
import { StateSummary } from '../../../Common/States/state.model';
import { FormRepository } from '../../Repositories';
import { FormService } from '../form.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { FormDetailsComponent, FormMode, FormType, FormDetailsParams } from '../Form-Details/formDetails.component';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { BusyIndicatorService, SnackBarService } from '../../../Busy-Indicator';
import { Constants } from '../../../constants.new';
import { FormTypeEnum } from '../Models/enums';
import { AppealLevel } from '../../../Annual-Details/Appeals/appeal.model';
import { AppealService } from '../../../Annual-Details/Appeals/appeal.service';
import { BusyIndicatorRef } from '../../../Busy-Indicator';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FormSetupUploadComponent } from '../Form-Setup-Upload/formSetupUpload.component';
import { FormSetupRepository } from '../../Repositories';
import { FormSetupDownloadComponent } from '../Form-Setup-Download/formSetupDownload.component';
import { ToastrService } from 'ngx-toastr';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { FORM_HELP } from '../form.help';
import { ExportFormListComponent } from '../Export-Form-List/exportFormList.component';
import { FormLibraryExportComponent } from '../Form-Library-Export/formLibraryExport.component';
import { FormLibraryRepository } from '../../Repositories/formLibraryRepository';
import * as _ from 'lodash';

@Component({
    selector: 'form-list',
    templateUrl: './formList.component.html',
    styleUrls: ['./formList.component.scss']
})
export class FormListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _appealService: AppealService,
        private readonly _stateService: StateService,
        private readonly _formRepository: FormRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _messageModalService: MessageModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _restrictService: RestrictService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _formService: FormService,
        private readonly _constants: Constants,
        private readonly _formSetupRepository: FormSetupRepository,
        private readonly _toastr: ToastrService,
        private readonly _helpService: HelpService,
        private readonly _formLibraryRepository: FormLibraryRepository,
        private readonly _snackBarService: SnackBarService) { }

    private _expandedFormIds: Set<number> = new Set<number>([]);
    private _collapsedRevisionIds: Set<number> = new Set<number>([]);
    private _appealLevels: AppealLevel[];
    private _formsSetupFileLongRunningProcessId: number;
    private _longRunningProcessProgressSub: Subscription;
    private _longRunningProcessStatusSub: Subscription;
    private _exportFormListLongRunningProcessId: number;
    private _formLibraryExportLongRunningProcessId: number;

    private _busyRef: BusyIndicatorRef;
    private _busyRefId = this._busyIndicatorService.generateUniqueMessageIdentifier();

    formList: Compliance.FormModel[] = [];
    FormTypeEnum = FormTypeEnum;
    formTypes = this._constants.FormTypes;
    states: StateSummary[];
    selectedState: StateSummary = null;
    selectedFormType: Compliance.NameValuePair<Compliance.FormTypeEnum> = null;
    selectFormRevisionEmitter: EventEmitter<Compliance.FormRevisionModel> = new EventEmitter();
    canEdit: boolean = false;
    canView: boolean = false;
    canExport: boolean = false;
    stateFormatter = (state: StateSummary) => (state.abbr) ? `${state.fullName} (${state.abbr})` : state.fullName;
    refreshing: boolean;
    stampLocations: Compliance.FormRevisionStampLocationModel[];

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FORM_HELP);

        this.canEdit = this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);
        this.canView = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
        this.canExport = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW) && this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

        await this.refresh();

        await this._setLastFormRevision();
    }

    ngOnDestroy(): void {
        this._longRunningProcessProgressSub && this._longRunningProcessProgressSub.unsubscribe();
        this._longRunningProcessStatusSub && this._longRunningProcessStatusSub.unsubscribe();

        this._busyRef = null;
    }

    async refresh(): Promise<void> {
        this.refreshing = true;
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const summaryData = await this._stateService.getSummary();
            this.states = summaryData.filter(item => item.countryID === 1);
            this.stampLocations = await lastValueFrom(this._formRepository.getStampLocations());
            this.criteriaChanged();
        }
        finally {
            busyRef.hide();
            this.refreshing = false;
        }
    }

    isExpanded(form: Compliance.FormModel) {
        return this._expandedFormIds.has(form.formId);
    }

    isRevisionCollapsed(revision: Compliance.FormRevisionModel) {
        return this._collapsedRevisionIds.has(revision.formRevisionId);
    }

    expandFormRevision(revision: Compliance.FormRevisionModel) {
        this._collapsedRevisionIds.delete(revision.formRevisionId);
    }

    collapseFormRevision(revision: Compliance.FormRevisionModel) {
        if (revision.supplementalForms.length > 0) {
            this._collapsedRevisionIds.add(revision.formRevisionId);
        }
    }

    expandForm(form: Compliance.FormModel) {
        this._expandedFormIds.add(form.formId);
    }

    collapseForm(form: Compliance.FormModel) {
        this._expandedFormIds.delete(form.formId);
    }

    async criteriaChanged(): Promise<void> {
        if (!this.selectedState || !this.selectedFormType) {
            this.formList = [];
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const result = await lastValueFrom<Compliance.QueryResultModel<Compliance.FormModel>>(this._formRepository.getForms(this.selectedState.stateID, this.selectedFormType.value as number, null, null));
            this._appealLevels = await this._appealService.getStateAppealLevels(this.selectedState.stateID);
            this.formList = (result && result.data) || [];
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    createNewForm(): Promise<Compliance.FormRevisionModel> {
        return new Promise(() => {
            const params: FormDetailsParams = {
                type: FormType.Form,
                mode: FormMode.Create,
                stateId: this.selectedState ? this.selectedState.stateID : null,
                formTypeId: this.selectedFormType ? this.selectedFormType.value : null,
                taxYear: new Date().getFullYear(),
                formName: '',
                formDescription: null,
                revisionId: null,
                revisionName: null,
                revisionDescription: null,
                supplementalFormName: null,
                supplementalFormDescription: null,
                canEdit: this.canEdit,
                appealLevelId: null,
                appealLevels: this._appealLevels,
                priorFormRevisionId: null,
                revisionStampLocationId: Compliance.FormRevisionStampLocationEnum.Top,
                stampLocations: this.stampLocations,
                supportsNBVReporting: false,
                supportsEstFMVReporting: false,
                electronicFilingTypeId: null,
                isFormRevisionInLockedBatch: false
            };

            this.showFormDetailsWithParams(params);
        });
    }

    async deleteForm(form: Compliance.FormModel, force: boolean = false): Promise<void> {
        if (!force) {
            try {
                await this._messageModalService.confirm(
                    `Are you sure you wish to delete "${form.name}"?`,
                    'Confirm Delete');
            } catch (e) {
                return Promise.resolve();
            }
        }

        let confirmMessage = '';

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting Form' });

        try {
            await lastValueFrom(this._formRepository.deleteForm(form.formId, force));
            this.criteriaChanged();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }
        finally {
            busyRef.hide();
        }

        if (confirmMessage) {
            try {
                await this._messageModalService.confirm(
                    confirmMessage,
                    'Confirm Delete');
            }
            catch (e3) {
                return Promise.resolve();
            }

            // force
            await this.deleteForm(form, true);
        }

        return Promise.resolve();
    }

    async deleteRevision(revision: Compliance.FormRevisionModel, typeText = 'revision', force: boolean = false): Promise<void> {
        if (!force) {
            try {
                await this._messageModalService.confirm(
                    `Are you sure you wish to delete ${typeText} "${revision.name}"?`,
                    'Confirm Delete');
            } catch (e) {
                return Promise.resolve();
            }
        }

        let confirmMessage = '';

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting Form Revision' });

        try {
            await lastValueFrom(this._formRepository.deleteRevision(revision.formRevisionId, force));
            this.criteriaChanged();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }
        finally {
            busyRef.hide();
        }

        if (confirmMessage) {
            try {
                await this._messageModalService.confirm(
                    confirmMessage,
                    'Confirm Delete');
            }
            catch (e3) {
                return Promise.resolve();
            }

            // force
            await this.deleteRevision(revision, typeText, true);
        }

        return Promise.resolve();
    }

    deleteSupplement(supplement: Compliance.FormRevisionModel): void {
        this.deleteRevision(supplement, 'revision supplement');
    }

    createRevision(form: Compliance.FormModel): Promise<Compliance.FormRevisionModel> {
        return new Promise(() => {
            const params: FormDetailsParams = {
                type: FormType.FormRevision,
                mode: FormMode.Create,
                formId: form.formId,
                formName: form.name,
                formDescription: form.description,
                stateId: form.stateId,
                formTypeId: form.formTypeId,
                canEdit: this.canEdit,
                appealLevels: this._appealLevels,
                allFormRevisions: form.formRevisions || [],
                priorFormRevisionId: _.last(_.sortBy(_.map(form.formRevisions, x => x.formRevisionId))),
                revisionStampLocationId: Compliance.FormRevisionStampLocationEnum.Top,
                stampLocations: this.stampLocations,
                electronicFilingTypeId: null,
                isFormRevisionInLockedBatch: false
            };

            this.showFormDetailsWithParams(params);
        });
    }

    configureAssociations(revision: Compliance.FormRevisionModel): void {
        this._routerService.go('formPage', { formRevisionId: revision.formRevisionId });
    }

    createSupplement(form: Compliance.FormModel, parentRevision: Compliance.FormRevisionModel): void {
        let allFormRevisions: Compliance.FormRevisionModel[] = [];
        if (parentRevision.priorFormRevisionId) {
            const priorFormRevision = _.find(form.formRevisions, x => x.formRevisionId === parentRevision.priorFormRevisionId);
            if (priorFormRevision) {
                allFormRevisions = priorFormRevision.supplementalForms || [];
            }
        }

        const params: FormDetailsParams = {
            type: FormType.SupplementalForm,
            mode: FormMode.Create,
            formId: form.formId,
            formName: form.name,
            formDescription: form.description,
            stateId: form.stateId,
            formTypeId: form.formTypeId,
            revisionId: parentRevision.formRevisionId,
            revisionName: parentRevision.name,
            revisionDescription: parentRevision.description,
            canEdit: this.canEdit,
            appealLevels: this._appealLevels,
            allFormRevisions: allFormRevisions,
            priorFormRevisionId: _.last(_.sortBy(_.map(allFormRevisions, x => x.formRevisionId))),
            revisionStampLocationId: Compliance.FormRevisionStampLocationEnum.Top,
            stampLocations: this.stampLocations,
            electronicFilingTypeId: null,
            isFormRevisionInLockedBatch: false
        };

        this.showFormDetailsWithParams(params);
    }

    editForm(form: Compliance.FormModel): Promise<Compliance.FormRevisionModel> {
        return new Promise(() => {
            const params: FormDetailsParams = {
                type: FormType.Form,
                mode: FormMode.Edit,
                formId: form.formId,
                formName: form.name,
                formDescription: form.description,
                stateId: form.stateId,
                formTypeId: form.formTypeId,
                canEdit: this.canEdit,
                revisionStampLocationId: Compliance.FormRevisionStampLocationEnum.Top,
                stampLocations: this.stampLocations,
                isFormRevisionInLockedBatch: false
            };
            this.showFormDetailsWithParams(params);
        });
    }

    editRevision(form: Compliance.FormModel, revision: Compliance.FormRevisionModel): Promise<Compliance.FormRevisionModel> {
        return new Promise(() => {
            const params: FormDetailsParams = {
                type: FormType.FormRevision,
                mode: FormMode.Edit,
                revisionId: revision.formRevisionId,
                revisionName: revision.name,
                revisionDescription: revision.description,
                formName: form.name,
                formDescription: form.description,
                stateId: form.stateId,
                formTypeId: form.formTypeId,
                canEdit: this.canEdit,
                allFormRevisions: form.formRevisions || [],
                priorFormRevisionId: revision.priorFormRevisionId,
                revisionStampLocationId: revision.formRevisionStampLocationId,
                stampLocations: this.stampLocations,
                supportsNBVReporting: revision.supportsNBVReporting,
                supportsEstFMVReporting: revision.supportsEstFMVReporting,
                electronicFilingTypeId: revision.electronicFilingTypeId,
                isFormRevisionInLockedBatch: revision.isFormRevisionInLockedBatch
            };
            this.showFormDetailsWithParams(params);
        });
    }

    editSupplement(form: Compliance.FormModel, parentRevision: Compliance.FormRevisionModel, supplement: Compliance.FormRevisionModel): Promise<Compliance.FormRevisionModel> {
        let allFormRevisions: Compliance.FormRevisionModel[] = [];
        if (parentRevision.priorFormRevisionId) {
            const priorFormRevision = _.find(form.formRevisions, x => x.formRevisionId === parentRevision.priorFormRevisionId);
            if (priorFormRevision) {
                allFormRevisions = priorFormRevision.supplementalForms || [];
            }
        }

        return new Promise(() => {
            // revisionId has to be the supplement id - a little confusing
            const params: FormDetailsParams = {
                type: FormType.SupplementalForm,
                mode: FormMode.Edit,
                revisionId: supplement.formRevisionId,
                revisionName: parentRevision.name,
                revisionDescription: parentRevision.description,
                formName: form.name,
                formDescription: form.description,
                stateId: form.stateId,
                formTypeId: form.formTypeId,
                supplementalFormName: supplement.name,
                supplementalFormDescription: supplement.description,
                canEdit: this.canEdit,
                allFormRevisions: allFormRevisions,
                priorFormRevisionId: supplement.priorFormRevisionId,
                revisionStampLocationId: supplement.formRevisionStampLocationId,
                stampLocations: this.stampLocations,
                electronicFilingTypeId: supplement.electronicFilingTypeId,
                isFormRevisionInLockedBatch: supplement.isFormRevisionInLockedBatch
            };
            this.showFormDetailsWithParams(params);
        });
    }

    async showFormDetailsWithParams(params: FormDetailsParams): Promise<void> {
        const result = await this._modalService.showAsync(FormDetailsComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        if (result.newFormRevision) {
            this.configureAssociations(result.newFormRevision);
            return Promise.resolve();
        }

        await this.criteriaChanged();
        return Promise.resolve();
    }

    async exportFormsSetup(): Promise<void> {
        const result = await this._modalService.showAsync(FormSetupDownloadComponent, null, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this._formsSetupFileLongRunningProcessId = await lastValueFrom(this._formSetupRepository.export(result));
        this._snackBarService.addById(this._formsSetupFileLongRunningProcessId, Compliance.LongRunningProcessTypeEnum.FormSetupExport);

        return Promise.resolve();
    }

    async importFormsSetup(): Promise<void> {
        const result = await this._modalService.showAsync(FormSetupUploadComponent, null, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this._formsSetupFileLongRunningProcessId = result;
        this._showBusyIndicator('', 'Importing Forms Setup', 'Forms Setup imported successfully');

        return Promise.resolve();
    }

    async exportFormList(): Promise<void> {
        const result = await this._modalService.showAsync(ExportFormListComponent, null, 'modal-md');

        if (!result) {
            return;
        }

        this._exportFormListLongRunningProcessId = result;
        this._snackBarService.addById(this._exportFormListLongRunningProcessId, Compliance.LongRunningProcessTypeEnum.ExportFormList);
    }

    async exportFormsLibrary(): Promise<void> {
        const result = await this._modalService.showAsync(FormLibraryExportComponent, null, 'modal-xl');
    }

    private async _setLastFormRevision(): Promise<void> {
        this.selectedFormType = _.find(this.formTypes, x => x.value === Compliance.FormTypeEnum.Compliance);

        if (!this._formService.lastFormRevision) {
            return Promise.resolve();
        }

        const state = this.states.find(x => x.stateID === this._formService.lastFormRevision.stateId);
        if (!state) {
            return Promise.resolve();
        }

        this.selectedState = state;

        if (!this._formService.lastFormRevision.formTypeId) {
            return Promise.resolve();
        }

        this.selectedFormType = _.find(this.formTypes, x => x.value as number === this._formService.lastFormRevision.formTypeId);

        await this.criteriaChanged();

        const form = this.formList.find(x => x.formId === this._formService.lastFormRevision.formId);
        if (!form) {
            return Promise.resolve();
        }

        this.expandForm(form);

        // if it's a form revision supplement, then expand the revision it is associated with
        if (!this._formService.lastFormRevision.parentFormRevisionId) {
            return Promise.resolve();
        }

        const formRevision = form.formRevisions.find(x => x.formRevisionId === this._formService.lastFormRevision.parentFormRevisionId);
        if (formRevision) {
            this.expandFormRevision(formRevision);
        }

        return Promise.resolve();
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', successMessage: string = null): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this._busyRefId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this._busyRefId,
            longRunningProcessId: this._formsSetupFileLongRunningProcessId,
            title: title ? title : 'Processing',
            message: message,
            canDismiss: true
        });

        this._busyRef.onDismiss().subscribe(() => this._hideBusyIndicator());

        this._busyRef.onProgressBarComplete().subscribe(success => {
            if (success && successMessage){
                this._toastr.success(successMessage);
                this.criteriaChanged();
            }
            this._hideBusyIndicator();
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
    }
}
