import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccountingPeriodsModalComponent } from './accounting.periods.modal.component';

@Component({
    selector: 'accounting-periods-link',
    template: `
            <a (click)="launchModal()" class="pointer">Accounting Periods</a>
        `
})
export class AccountingPeriodsLinkComponent {
    constructor(private modalService: BsModalService) {}

    @Input() companyId: number;
    @Input() editMode: boolean;

    launchModal() {
        const initialState = {
            companyId: this.companyId,
            canEdit: this.editMode
        };

        this.modalService.show(AccountingPeriodsModalComponent, { initialState, ignoreBackdropClick: true, class: 'modal-lg' });
    }

}
