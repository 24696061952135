<div class="container-fluid format-output-budget" *ngIf="report.criteria.summaryReportOptions">
    <div class="row">
        <div class="col col-lg-6">
            <div style="margin: 10px 0;">
                <label>Output Format:</label>
                <select [(ngModel)]="report.criteria.outputFormat" class="form-select form-select-sm">
                    <option *ngFor="let option of outputFormatOptions; let i = index;" [ngValue]="i">{{option}}</option>
                </select>
            </div>
            <div class="row" style="margin-bottom: 15px;">
                <div class="col-lg-4">
                    <label>Basis:</label>
                    <select [(ngModel)]="report.criteria.budgetBasis" class="form-select form-select-sm" (change)="budgetBasisChanged()">
                        <option [ngValue]="CompanyBudgetBasisEnum.Accrual">Accrual</option>
                        <option [ngValue]="CompanyBudgetBasisEnum.Cash">Cash</option>
                        <option [ngValue]="CompanyBudgetBasisEnum.AccrualAndCash">Accrual and Cash</option>
                    </select>
                </div>
                <div class="col-lg-4">
                    <label>Budget Year Source:</label>
                    <select [(ngModel)]="report.criteria.currCompanyBudgetID" class="form-select form-select-sm">
                        <option [ngValue]="0" style="height: 20px;">Budget Year Forecast</option>
                        <option *ngFor="let option of budgetYearSources" [ngValue]="option.companyBudgetId">{{option.budgetName}}</option>
                    </select>
                </div>
                <div class="col-lg-4">
                    <label>Prior Year Source:</label>
                    <select [(ngModel)]="report.criteria.priorCompanyBudgetID" class="form-select form-select-sm">
                        <option [ngValue]="0">Prior Year Actuals</option>
                        <option *ngFor="let option of priorYearSources" [ngValue]="option.companyBudgetId">{{option.budgetName}}</option>
                    </select>
                </div>
            </div>
            <div class="card panel-flat-color secondary-panel small-panel summary-report-options">
                <div class="panel-flat-header small-header">
                    <h3>Summary Report Options</h3>
                </div>
                <div class="container-fluid" style="padding: 15px 15px 12px 15px;">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="mb-4" style="margin-bottom: 10px;">
                                <label>Summary Cash Basis Period:</label>
                                <select [(ngModel)]="report.criteria.summaryReportOptions.summaryCashBasisPeriod" class="form-select form-select-sm intput-sm">
                                    <option *ngFor="let option of summaryCashBasisPeriodOptions; let i = index;" [ngValue]="i">{{option}}</option>
                                </select>
                            </div>
                            <div class="mb-4" style="margin-bottom: 10px;">
                                <label>Detail level</label>
                                <select [(ngModel)]="report.criteria.summaryReportOptions.detailLevel" class="form-select form-select-sm intput-sm">
                                    <option *ngFor="let option of detailLevelOptions; let i = index;" [ngValue]="i">{{option}}</option>
                                </select>
                            </div>
                            <div class="mb-4" style="margin-bottom: 0;">
                                <label>Show % Change:</label>
                                <select [(ngModel)]="report.criteria.summaryReportOptions.showPercentChange" class="form-select form-select-sm intput-sm">
                                    <option *ngFor="let option of showPercentChangeOptions; let i = index;" [ngValue]="i">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <report-order-by [(orderBy)]="report.criteria.summaryReportOptions.orderAndTotalBy"></report-order-by>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-lg-6">
            <div class="card panel-flat-color secondary-panel small-panel" style="margin-top: 10px;">
                <div class="panel-flat-header small-header">
                    <h3>Detail Report Options</h3>
                </div>
                <report-detail-options [isBudgetReport]="true" [(detailReportOptions)]="this.report.criteria.detailReportOptions"></report-detail-options>
            </div>
        </div>
    </div>
</div>
