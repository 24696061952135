import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../../Busy-Indicator';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { GLAccountService } from '../../glAccount.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GLAccountBulkUpdateIncomeStatementTypesChangedFields } from './glAccountBulkUpdateExports';
import {
    IncomeStatementCategorySelectorComponent
} from '../../../../Valuation/Income-Statement/Income-Statement-Category-Selector/incomeStatementCategorySelector.component';

export interface GLAccountBulkUpdateIncomeStatementTypesParams {
    companyId: number;
    selectedCount: number;
    glAccountListLastModifiedTimestamp: Date;
    selection: Compliance.SelectedRowsModel;
    selectionSummary: Compliance.GLAccountBulkSelectionSummaryResponse;
}

interface GLAccountIncomeStatementTypesBulkFormValues {
    accountType: Compliance.GLAccountTypeModel,
    accountTypeAction: Compliance.GLAccountBulkUpdateFieldActionEnum,
    incomeStatementCategory: Core.IncomeStatementCategoryModel,
    incomeStatementCategoryAction: Compliance.GLAccountBulkUpdateFieldActionEnum,
    isValidated: boolean
}

@Component({
    selector: 'gl-account-bulk-update-income-statement-types-modal',
    templateUrl: './glAccountBulkUpdateIncomeStatementTypes.component.html',
    styleUrls: ['./glAccountBulkUpdateIncomeStatementTypes.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GLAccountBulkUpdateIncomeStatementTypesComponent implements OnInit, OnDestroy, IWeissmanModalComponent<GLAccountBulkUpdateIncomeStatementTypesParams, GLAccountBulkUpdateIncomeStatementTypesChangedFields> {

    constructor(
        private _busyIndicatorService: BusyIndicatorService,
        private _bsModalRef: BsModalRef,
        private _messageModalService: MessageModalService,
        private _glAccountService: GLAccountService
    ) {
        this.bulkUpdateForm = new UntypedFormGroup({
            accountType: new UntypedFormControl({ value: {}, disabled: true }),
            accountTypeAction: new UntypedFormControl(Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange),
            incomeStatementCategory: new UntypedFormControl(null),
            incomeStatementCategoryAction: new UntypedFormControl(Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange),
            isValidated: new UntypedFormControl(false)
        });
    }

    @ViewChild('incomeStatementCategorySelector') incomeStatementCategorySelector: IncomeStatementCategorySelectorComponent;

    params: GLAccountBulkUpdateIncomeStatementTypesParams;
    result: GLAccountBulkUpdateIncomeStatementTypesChangedFields;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    bulkUpdateModel: Compliance.GLAccountBulkUpdateIncomeStatementTypesModel = {
        accountTypeId: null,
        accountTypeIdAction: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange,
        incomeStatementCategoryId: null,
        incomeStatementCategoryIdAction: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange
    } as Compliance.GLAccountBulkUpdateIncomeStatementTypesModel;

    accountTypes: Compliance.GLAccountTypeModel[] = this.setupAllowedAccountTypes();
    bulkUpdateForm: UntypedFormGroup;

    get showIncomeStatementCategoryChoices(): boolean { return !this.incomeStatementCategoryControl.disabled;}

    get incomeStatementCategoryActionControl(): AbstractControl {
        return this.bulkUpdateForm.get('incomeStatementCategoryAction');
    }

    accountTypePossibleActions: Compliance.NameValuePair<Compliance.GLAccountBulkUpdateFieldActionEnum>[] = [
        { name: 'No Change', value: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange },
        { name: 'Change To', value: Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo }
    ];

    categoryPossibleActions: Compliance.NameValuePair<Compliance.GLAccountBulkUpdateFieldActionEnum>[] = [
        { name: 'No Change', value: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange },
        { name: 'Set to Blank', value: Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank },
        { name: 'Change To', value: Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo }
    ];

    accountTypeActionInitValue = Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange;
    categoryActionInitValue = Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange;

    private internalSubs = new Subscription();
    private originalAccountTypeId: Compliance.GLAccountTypeEnum;

    async ngOnInit(): Promise<void> {
        if (this.params.selectionSummary.asset > 0) throw 'We don\'t update assets here';
        if (this.params.selectionSummary.equity > 0) throw 'We don\'t update equity here';
        if (this.params.selectionSummary.liability > 0) throw 'We don\'t update liability here';

        this.bulkUpdateModel.selectedRows = this.params.selection;
        this.bulkUpdateModel.lastModifiedTimestamp = this.params.glAccountListLastModifiedTimestamp;

        this.internalSubs.add(
            this.bulkUpdateForm.valueChanges
                .subscribe(() => {
                    const formValues = this.bulkUpdateForm.getRawValue() as GLAccountIncomeStatementTypesBulkFormValues;
                    if (!!formValues) {
                        this.bulkUpdateModel.accountTypeId = !!formValues.accountType ? formValues.accountType.glAccountTypeId : null;
                        this.bulkUpdateModel.accountTypeIdAction = formValues.accountTypeAction;
                        this.bulkUpdateModel.incomeStatementCategoryId = !!formValues.incomeStatementCategory ? formValues.incomeStatementCategory.incomeStatementCategoryId : null;
                        this.bulkUpdateModel.incomeStatementCategoryIdAction = formValues.incomeStatementCategoryAction;
                        this.bulkUpdateModel.validate = formValues.isValidated
                    }
                })
        );

        if (this.params.selectionSummary.validated.length !== 0) {
            this.bulkUpdateForm.get('isValidated').disable();
            this.bulkUpdateForm.get('isValidated').setValue(true);
        }
    }

    private get accountTypeControl(): AbstractControl {
        return this.bulkUpdateForm.get('accountType');
    }

    private get incomeStatementCategoryControl(): AbstractControl {
        return this.bulkUpdateForm.get('incomeStatementCategory');
    }

    ngOnDestroy(): void {
        if (!!this.internalSubs) {
            this.internalSubs.unsubscribe();
        }
    }

    setupAllowedAccountTypes(): Compliance.GLAccountTypeModel[] {
        const realTypes = this._glAccountService.startResults.glAccountTypes
                              .filter(x =>
                                  x.glAccountTypeId === Compliance.GLAccountTypeEnum.Reporting ||
                                  x.glAccountTypeId === Compliance.GLAccountTypeEnum.Expense ||
                                  x.glAccountTypeId === Compliance.GLAccountTypeEnum.Revenue);
        return [{
            name: '',
            glAccountTypeId: null
        } as Compliance.GLAccountTypeModel, ...realTypes];
    }

    onIncomeStatementCategoryChanged(incomeStatementCategory: Core.IncomeStatementCategoryModel): void {
        this.bulkUpdateForm.patchValue({ incomeStatementCategory: incomeStatementCategory });
    }

    accountTypeActionChanged(event: Compliance.GLAccountBulkUpdateFieldActionEnum): void {
        if (event === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo) {
            this.accountTypeControl.enable();
        } else {
            this.accountTypeControl.disable();
        }
        this.bulkUpdateForm.patchValue({ accountTypeAction: event });
    }

    incomeStatementCategoryActionChanged(event: Compliance.GLAccountBulkUpdateFieldActionEnum): void {
        this.bulkUpdateForm.patchValue({ incomeStatementCategoryAction: event });
    }

    isFormValid(): boolean {
        const accountTypeIsChanging = this.bulkUpdateModel.accountTypeId !== this.originalAccountTypeId;
        const accountTypeNoChangeIsValid = this.bulkUpdateModel.accountTypeIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange && !accountTypeIsChanging;
        const accountTypeChangeIsValid = this.bulkUpdateModel.accountTypeIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo && accountTypeIsChanging;

        const categoryIsSet = !!this.bulkUpdateModel.incomeStatementCategoryId;
        const categorySetToBlankIsValid = this.bulkUpdateModel.incomeStatementCategoryIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank && !categoryIsSet;
        const categoryChangeToIsValid = this.bulkUpdateModel.incomeStatementCategoryIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo && categoryIsSet;

        const accountTypeFieldValuesAreValid = accountTypeChangeIsValid || accountTypeNoChangeIsValid;
        const categoryFieldValuesAreValid = categoryChangeToIsValid || categorySetToBlankIsValid;

        return accountTypeFieldValuesAreValid && categoryFieldValuesAreValid || this.bulkUpdateModel.validate;
    }

    async save(force: boolean = false): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating' });
        let confirmMessage: string = '';

        try {
            this.bulkUpdateModel.force = force;

            await this._glAccountService.updateBulkIncomeStatementTypes(this.params.companyId, this.bulkUpdateModel);

            this.result = {
                selectedRows: this.bulkUpdateModel.selectedRows
            } as GLAccountBulkUpdateIncomeStatementTypesChangedFields;

            this.result.accountTypeId = this.bulkUpdateModel.accountTypeId;
            this.result.incomeStatementCategoryId = this.bulkUpdateModel.incomeStatementCategoryId;

            this._bsModalRef.hide();
            return Promise.resolve();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        } finally {
            await busyRef.hide();
        }

        try {
            await this._messageModalService.confirm(
                confirmMessage,
                'Confirm Update');
        } catch (e3) {
            return Promise.resolve();
        }

        // force
        await this.save(true);

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    isDisabled(action: Compliance.GLAccountBulkUpdateFieldActionEnum): boolean {
        return action !== Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo;
    }
}
