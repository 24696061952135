import { Component, OnInit, OnDestroy } from '@angular/core';
import { IReturnPartComponent } from '../../Models/returnPartServiceBase';
import { ReturnService } from '../../return.service';
import { ReturnOutputService } from './returnOutput.service';
import { ReturnRepository } from '../../../Repositories';
import { BusyIndicatorService, SnackBarService } from '../../../../Busy-Indicator';
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'return-output',
    templateUrl: './returnOutput.component.html'
})
export class ReturnOutputComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOutputService: ReturnOutputService,
        private readonly _returnRepository: ReturnRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _snackBarService: SnackBarService
    ) { }

    disableDownload: boolean;

    get packagesSelected(): boolean {
        return this._returnOutputService.sharedState &&
            this._returnOutputService.sharedState.returnOutputModel &&
            this._returnOutputService.sharedState.returnOutputModel.packageIds &&
            this._returnOutputService.sharedState.returnOutputModel.packageIds.length > 0;
    }

    get printingOptionsSelected(): boolean {
        return this._returnOutputService.sharedState &&
            this._returnOutputService.sharedState.returnOutputModel &&
            (this._returnOutputService.sharedState.returnOutputModel.includeFilingControlReport ||
                this._returnOutputService.sharedState.returnOutputModel.includeUSPSCertificateOfMailingIndividual ||
                this._returnOutputService.sharedState.returnOutputModel.includeUSPSCertificateOfMailingLog ||
                this._returnOutputService.sharedState.returnOutputModel.includeEnvelopeInserts ||
                this._returnOutputService.sharedState.returnOutputModel.includePackageCoverSheets ||
                this._returnOutputService.sharedState.returnOutputModel.includeReturns);
    }

    get returnId(): number {
        return this._returnService.sharedState &&
            this._returnService.sharedState.returns &&
            this._returnService.sharedState.returns.length > 0
            ? this._returnService.sharedState.returns[0].returnId
            : null;
    }

    get showOutput(): boolean {
        return this._returnService.isLocked ||
            this._returnService.isSigned ||
            this._returnService.isSigning ||
            this._returnService.isUnsigning ||
            this._returnService.isFinalizing ||
            this._returnService.isFinalized;
    }

    ngOnInit(): void {
        this._returnOutputService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOutputService.unsubscribeFromServiceActivationCycle(this);
    }

    onReturnPartServiceActivated(): void { }

    onReturnPartServiceDeactivated(): void { }

    async download(): Promise<void> {
        this.disableDownload = true;
        const busyRef = this._busyIndicatorService.show({ message: 'Working on it...', delay: 0 });

        try {
            const lrpId = await lastValueFrom(this._returnRepository.generateDownloadOutput(this._returnOutputService.sharedState.returnOutputModel));
            this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.GenerateReturnOutput);
            this.disableDownload = false;
        } finally {
            busyRef.hide();
        }
    }
}
