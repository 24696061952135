import { Component } from "@angular/core";
import { HelpContentComponent } from "../../../../UI-Lib/Help-Tooltip";
import FormRevisionAssessorModel = Compliance.FormRevisionAssessorModel;

export interface AssessorCertifiedTooltipParams {
    areAllAssessorsCertified: boolean;
    nonCertifiedAssessors: FormRevisionAssessorModel[];
}

@Component({
    selector: 'assessor-certified-tooltip',
    templateUrl: 'assessorCertifiedTooltip.component.html',
    styleUrls: ['assessorCertifiedTooltip.component.scss']
})
export class AssessorCertifiedTooltipComponent implements HelpContentComponent<AssessorCertifiedTooltipParams> {
    params: AssessorCertifiedTooltipParams;

    helpInit(params: AssessorCertifiedTooltipParams): void {

    }
}
