<div class="col-lg-12 ws-stretch-height advanced-search-list">
    <div class="card panel-flat-color primary-panel ws-stretch ws-flex-container-vertical">
        <div class="panel-flat-header">
            <div class="header-icon">
                <i class="fa fa-table"></i>
            </div>
            <h3 helpTooltip
                helpContentId="smartSearch.results"
                position="bottom">
                Results
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
            </h3>

        </div>
        <div class="panel-flat-body ws-flex-grow">
            <ws-ag-grid-angular [gridOptions]="gridOptions"
                                (gridReady)="onAgGridReady($event)"
                                [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                                [exportOptions]="exportOptions"
                                exportHelpContentId="smartSearch.export"
                                [defaultSizeToFit]="false"
                                [defaultSizeToContent]="true"
                                [showConfigureLayout]="false"
                                [forceShowToolPanel]="true"
                                (bulkUpdate)="bulkUpdate()"
                                (filtersEnabled)="emitAnalyticsEvent('click-filter-funnel-icon')"
                                (sizeColumnsToFit)="emitAnalyticsEvent('click-SMART-autosize')"
                                (sizeColumnsToContent)="emitAnalyticsEvent('click-SMART-autosize')"
                                [gridId]="gridId">
            </ws-ag-grid-angular>
        </div>
    </div>
</div>
