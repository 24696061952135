<div class="container-fluid format-output-budget">
    <div class="row">
        <div class="col-lg-8">
            <div style="margin: 10px 0;">
                <label>Output Format:</label>
                <select [(ngModel)]="report.criteria.bvrFormatOutput.outputFormat" class="form-select form-select-sm">
                    <option *ngFor="let option of outputFormatOptions; let i = index;" [ngValue]="i">{{option}}</option>
                </select>
            </div>
            <div style="margin: 10px 0;">
                <label>Basis:</label>
                <select [(ngModel)]="report.criteria.bvrFormatOutput.budgetBasis" class="form-select form-select-sm" (change)="refreshBudgetSources()">
                    <option [ngValue]="CompanyBudgetBasisEnum.Accrual">Accrual</option>
                    <option [ngValue]="CompanyBudgetBasisEnum.Cash">Cash</option>
                    <option [ngValue]="CompanyBudgetBasisEnum.AccrualAndCash">Accrual and Cash</option>
                </select>
            </div>
            <div class="row" style="margin-bottom: 15px;">
                <div class="col-lg-3">
                    <label>1st Source Budget:</label>
                    <select [(ngModel)]="this.report.criteria.bvrFormatOutput.budgetSources[0]" class="form-select form-select-sm" (change)="sourceBudgetChanged()">
                        <option *ngFor="let option of firstBudgetSources" [ngValue]="option">{{option.budgetName}}</option>
                    </select>
                </div>
                <div class="col-lg-3">
                    <label>2nd Source Budget:</label>
                    <select [(ngModel)]="this.report.criteria.bvrFormatOutput.budgetSources[1]" class="form-select form-select-sm" (change)="sourceBudgetChanged()">
                        <option *ngFor="let option of secondBudgetSources" [ngValue]="option">{{option.budgetName}}</option>
                    </select>
                </div>
                <div class="col-lg-3">
                    <label>3rd Source Budget:</label>
                    <select [(ngModel)]="this.report.criteria.bvrFormatOutput.budgetSources[2]" class="form-select form-select-sm" (change)="sourceBudgetChanged()">
                        <option *ngFor="let option of thirdBudgetSources" [ngValue]="option">{{option.budgetName}}</option>
                    </select>
                </div>
                <div class="col-lg-3">
                    <label>4th Source Budget:</label>
                    <select [(ngModel)]="this.report.criteria.bvrFormatOutput.budgetSources[3]" class="form-select form-select-sm" (change)="sourceBudgetChanged()">
                        <option *ngFor="let option of fourthBudgetSources" [ngValue]="option">{{option.budgetName}}</option>
                    </select>
                </div>
            </div>
            <div class="card panel-flat-color secondary-panel small-panel summary-report-options">
                <div class="panel-flat-header small-header">
                    <h3>Summary Report Options</h3>
                </div>
                <div class="container-fluid" style="padding: 15px 15px 12px 15px;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-4" style="margin-bottom: 10px;">
                                <label>Detail level</label>
                                <select [(ngModel)]="report.criteria.bvrFormatOutput.summaryReportOptions.detailLevel" class="form-select form-select-sm intput-sm">
                                    <option *ngFor="let option of detailLevelOptions; let i = index;" [ngValue]="i">{{option}}</option>
                                </select>
                            </div>
                            <div class="mb-4" style="margin-bottom: 10px;">
                                <label>Variance Detail</label>
                                <select [(ngModel)]="report.criteria.bvrFormatOutput.summaryReportOptions.varianceDetail" class="form-select form-select-sm">
                                    <option *ngFor="let option of varianceDetailOptions; let i = index;" [ngValue]="i">{{option}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <report-order-by [(orderBy)]="report.criteria.bvrFormatOutput.summaryReportOptions.orderAndTotalBy"></report-order-by>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
