import { Injectable } from '@angular/core';
import { WeissmanModalService } from '../WeissmanModalService';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EntityPicker, IEntityPickerResult } from './entityPicker';
import { EntityPickerComponent } from './entityPicker.component';
import { ParcelPicker } from './parcelPicker';

export interface IEntityPickerService {
    showParcelPicker: (searchModel: Compliance.ReportingParcelSearchModel, siteIds: number[], masterSiteIds: number[],
                       selectedReportingParcelSiteId: number,
                       getParcels: (searchParams: any) => Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>>,
                       optionFlagText: string)
        => Promise<IEntityPickerResult<Compliance.ReportingParcelModel>>;
}

@Injectable()
export class EntityPickerService implements IEntityPickerService {
    constructor(
        private readonly _weissmanModalService: WeissmanModalService,
        private readonly _modalService: BsModalService,
    ) { }

    async showParcelPicker(searchModel: Compliance.ReportingParcelSearchModel, siteIds: number[], masterSiteIds: number[] = [],
                           selectedReportingParcelSiteId: number = null,
                           getParcels: (searchParams: any) => Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>>,
                           optionFlagText: string)
        : Promise<IEntityPickerResult<Compliance.ReportingParcelModel>> {
        const picker = new ParcelPicker(searchModel, siteIds, masterSiteIds, selectedReportingParcelSiteId, getParcels, optionFlagText);
        const result = await this._showEntityPicker<Compliance.ReportingParcelModel>(picker);
        return result;
    }

    private async _showEntityPicker<T>(picker: EntityPicker<T>): Promise<IEntityPickerResult<T>> {
        const showModalFn = (): BsModalRef => {
            return this._modalService.show(EntityPickerComponent, {
                class: picker.getModalClass(),
                initialState: {
                    params: {
                        entityPicker: picker
                    }
                }
            });
        }

        let result: IEntityPickerResult<T>;
        try {
            await this._weissmanModalService.show(showModalFn.bind(this)).whenClosed.then(bsModalRef => {
                const componentResult = (bsModalRef.content as EntityPickerComponent<T>).getResult();
                result = componentResult as IEntityPickerResult<T>;
            }, () => {});
        } catch (e) {
            return null;
        }

        return result;
    }
}
