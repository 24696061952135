import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Tri-state checkbox (checked, unchecked, indeterminate)
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * Input state:
 * true - checked
 * false - unchecked
 * null - indeterminate
 *
 * Example usage:
 * <ws-tri-state-checkbox [formControl]="myCheckFormControl"></ws-tri-state-checkbox>
 *
 */
@Component({
    selector: 'ws-tri-state-checkbox',
    templateUrl: './triStateCheckbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TriStateCheckboxComponent),
            multi: true
        }
    ],
})
export class TriStateCheckboxComponent implements ControlValueAccessor {

    value: boolean;

    disabled: boolean;

    private onChange: (val: boolean) => void = (val) => { };
    private onTouched: () => void = () => { }

    writeValue(value: boolean) {
        this.value = value;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    next() {
        this.onChange(this.value = !this.value);
        this.onTouched();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

}
