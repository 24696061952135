import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FormYearNewModalComponent } from '../Form-Year-New-Modal/formYearNewModal.component';

@Component({
    selector: 'form-year-new',
    templateUrl: './formYearNew.component.html'
})
export class FormYearNewComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _formService: FormService,
        private readonly _modalService: WeissmanModalService) { }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    get canCreateTaxYear(): boolean {
        return this._formService.isMaxFormRevisionYear || this._formService.isMinFormRevisionYear;
    }
    
    ngOnInit(): void { }

    ngOnDestroy(): void { }
    
    async createTaxYear(): Promise<void> {
        const result = await this._modalService.showAsync(FormYearNewModalComponent, null, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: `Creating tax year ${result}` });

        try {
            await this._formService.createTaxYear(result);
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }
}
