import { Injectable } from '@angular/core';
import { Constants } from 'src/app/constants.new';

export enum ScriptLocations {
    GoogleMaps,
    IAMComponent,
    PDFJSLib,
    PDFJSViewer
}

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    private loadedScripts = new Set<ScriptLocations>();

    constructor(private constants: Constants) { }

    // Dynamically add a pre-determined JavaScript file to the page and return "true" if this is the first
    // time it's been loaded, "false" (and do nothing) if the script has previously been loaded.
    loadScript(scriptLocation: ScriptLocations, isModule: boolean): Promise<boolean> {
        return new Promise<boolean>(res => {
            let src: string;

            if (this.loadedScripts.has(scriptLocation)) {
                //console.log('Script already loaded', scriptLocation);
                res(false);
            }

            switch (scriptLocation) {
                case ScriptLocations.GoogleMaps:
                    src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDXD_xiLW3lNKvfl8ZnZGKPld-f2TExBuQ&callback=gmapsLoaded';
                    break;
                case ScriptLocations.IAMComponent:
                //     src = 'https://ryanaceassets.blob.core.windows.net/components/v5/latest/ace/ace.esm.js';

                    // Latest dev build:
                    //src = 'https://ryanaceassets.blob.core.windows.net/components-develop/ace/ace.esm.js';
                    break;
                case ScriptLocations.PDFJSLib:
                    src = `${this.constants.PdfJsBase}build/pdf.mjs`;
                    break;
                case ScriptLocations.PDFJSViewer:
                    src = `${this.constants.PdfJsBase}components-legacy/pdf_viewer.mjs`;
                    break;
                default:
                    throw `Unrecognized script location: ${scriptLocation}`;
            }

            if (!src) {
                res(true);
                return;
            }

            this.loadedScripts.add(scriptLocation);

            const newTag = document.createElement('script');
            if (isModule) {
                newTag.setAttribute('type', 'module');
            }
            newTag.setAttribute('src', src);
            newTag.onload = () => { res(true); };
            document.head.appendChild(newTag);
        });
    }
}
