import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { AssetClassificationRepository, FormRepository } from '../Repositories';
import * as _ from 'lodash';
import { HttpResponse } from '@angular/common/http';
import { IExpandableComponentContainer } from '../../UI-Lib/Expandable-Component/expandableComponentContainer.model';
import { IExpandableComponent } from '../../UI-Lib/Expandable-Component/expandableComponent.model';
import { AppealService } from '../../Annual-Details/Appeals/appeal.service';
import { AppealLevel } from '../../Annual-Details/Appeals/appeal.model';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';

@Injectable()
export class FormService implements IExpandableComponentContainer {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _formRepository: FormRepository,
        private readonly _assetClassificationRepository: AssetClassificationRepository,
        private readonly _appealService: AppealService,
        private readonly _messageBoxService: MessageBoxService) {
        }

    // subjects
    private _formRevisionSubject = new BehaviorSubject<Compliance.FormRevisionModel>(null);
    private _assessorSubject = new Subject<Compliance.FormRevisionAssessorModel>();
    private _assessorsSubject = new Subject<Compliance.FormRevisionAssessorModel[]>();
    private _assetClassificationMappingsSubject = new Subject<void>();
    private _formRevisionYearsSubject = new Subject<Compliance.FormRevisionYearModel[]>();
    private _formRevisionYearSubject = new Subject<Compliance.FormRevisionYearModel>();
    private _schedulesSubject = new Subject<Compliance.FormRevisionScheduleModel[]>();
    private _factorTablesSubject = new Subject<Compliance.FormFactorTableModel[]>();
    private _reportsSubject = new Subject<Compliance.FormRevisionReportModel[]>();
    private _isInDevelopmentSubject = new Subject<boolean>();
    private _isPrimarySubject = new Subject<boolean>();
    private _anyAssessorMappingsCertifiedSubject = new Subject<boolean>();

    // state
    private _canEdit: boolean = false;
    private _canView: boolean = false;
    private _defaultAssessor: Compliance.FormRevisionAssessorModel = null;
    private _factorTableAssessor: Compliance.FormRevisionAssessorModel = null;
    private _assessor: Compliance.FormRevisionAssessorModel = null;
    private _assessors: Compliance.FormRevisionAssessorModel[] = [];
    private _assetClassifications: Compliance.AssetClassificationModel[] = [];
    private _assetClassificationMappings: Compliance.FormClassificationMappingModel[] = [];
    private _formRevision: Compliance.FormRevisionModel = null;
    private _formRevisionYears: Compliance.FormRevisionYearModel[] = [];
    private _formRevisionYear: Compliance.FormRevisionYearModel;
    private _maxFormRevisionYear: Compliance.FormRevisionYearModel;
    private _minFormRevisionYear: Compliance.FormRevisionYearModel;
    private _schedules: Compliance.FormRevisionScheduleModel[] = [];
    private _factorTables: Compliance.FormFactorTableModel[] = [];
    private _allFactorTables: Compliance.FactorTableListItemModel[] = null;
    private _reports: Compliance.FormRevisionReportModel[] = [];
    private _allReports: Core.SavedSearchModel[] = null;
    private _lastFormRevision: Compliance.FormRevisionModel = null;
    private _isInitialized = false;
    private _expandedComponent: IExpandableComponent = null;
    private _validAppealPanels: string[] = ['form-assessor-list', 'form-template', 'dummy'];
    private _appealLevels: AppealLevel[] = [];
    private _anyAssessorMappingsCertified = false;
    private _templateValidationResult: Compliance.ReturnFormTemplateValidationResultModel = null;
    private _warnDefaultAssessorEdit: boolean = false;

    reset() {
        this._isInitialized = false;
        this._canEdit = false;
        this._canView = false;
        this._defaultAssessor = null;
        this._factorTableAssessor = null;
        this._assessors = [];
        this._assessor = null;
        this._assetClassifications = [];
        this._assetClassificationMappings = [];
        this._formRevision = null;
        this._formRevisionYears = [];
        this._formRevisionYear = null;
        this._maxFormRevisionYear = null;
        this._minFormRevisionYear = null;
        this._schedules = [];
        this._allFactorTables = [];
        this._factorTables = [];
        this._reports = [];
        this._allReports = [];
        this._appealLevels = [];
        this._expandedComponent = null;
        this._anyAssessorMappingsCertified = false;
        this._templateValidationResult = null;
    }

    // properties
    get editGroup(): string {
        return 'form-edit-group';
    }

    get isInitialized(): boolean {
        return this._isInitialized;
    }

    get canEdit(): boolean {
        return this._canEdit;
    }

    get canView(): boolean {
        return this._canView;
    }

    get defaultAssessor(): Compliance.FormRevisionAssessorModel {
        return this._defaultAssessor;
    }

    get defaultAssessorId(): number {
        return this._defaultAssessor && this._defaultAssessor.formRevisionYearAssessorId;
    }

    get factorTableAssessor(): Compliance.FormRevisionAssessorModel {
        return this._factorTableAssessor;
    }

    get factorTableAssessorId(): number {
        return this.factorTableAssessor && this._factorTableAssessor.formRevisionYearAssessorId;
    }

    get factorTablesAssessorName() {
        if (!this._assessor) {
            return '';
        }

        if (!this._assessor.assessorId) {
            return this._defaultAssessor && this._defaultAssessor.assessorName;
        }

        return this._assessor.isUsingDefaultTables
            ? this._defaultAssessor && this._defaultAssessor.assessorName
            : this._assessor.assessorName;
    }

    get classificationMappingsAssessorName() {
        if (!this._assessor) {
            return '';
        }

        if (!this._assessor.assessorId) {
            return this._defaultAssessor && this._defaultAssessor.assessorName;
        }

        return this._assessor.isUsingDefaultMappings
            ? this._defaultAssessor && this._defaultAssessor.assessorName
            : this._assessor.assessorName;
    }

    get assessor(): Compliance.FormRevisionAssessorModel {
        return this._assessor;
    }

    get assessorName(): string {
        return this._assessor && this._assessor.assessorName;
    }

    get assessorId(): number {
        return this._assessor && this._assessor.formRevisionYearAssessorId;
    }

    get assessors(): Compliance.FormRevisionAssessorModel[] {
        return this._assessors;
    }

    get assetClassifications(): Compliance.AssetClassificationModel[] {
        return this._assetClassifications;
    }

    get assetClassificationMappings(): Compliance.FormClassificationMappingModel[] {
        return this._assetClassificationMappings;
    }

    get formRevision(): Compliance.FormRevisionModel {
        return this._formRevision;
    }

    get formRevisionId(): number {
        return this._formRevision && this._formRevision.formRevisionId;
    }

    get formRevisionStateId(): number {
        return this._formRevision && this._formRevision.stateId;
    }

    get lastFormRevision(): Compliance.FormRevisionModel {
        return this._lastFormRevision;
    }

    get isPrimary(): boolean {
        return this._formRevisionYear && this._formRevisionYear.isPrimary;
    }

    get isInDevelopment(): boolean {
        return this._formRevisionYear && this._formRevisionYear.isInDevelopment;
    }

    get formRevisionYears(): Compliance.FormRevisionYearModel[] {
        return this._formRevisionYears;
    }

    get formRevisionYear(): Compliance.FormRevisionYearModel {
        return this._formRevisionYear;
    }

    get taxYear(): number {
        return this._formRevisionYear && this._formRevisionYear.taxYear;
    }

    get maxFormRevisionYear(): Compliance.FormRevisionYearModel {
        return this._maxFormRevisionYear;
    }

    get minFormRevisionYear(): Compliance.FormRevisionYearModel {
        return this._minFormRevisionYear;
    }

    get isMaxFormRevisionYear(): boolean {
        return this._formRevisionYear && this._formRevisionYear === this._maxFormRevisionYear;
    }

    get isMinFormRevisionYear(): boolean {
        return this._formRevisionYear && this._formRevisionYear === this._minFormRevisionYear;
    }

    get schedules(): Compliance.FormRevisionScheduleModel[] {
        return this._schedules;
    }

    get factorTables(): Compliance.FormFactorTableModel[] {
        return this._factorTables;
    }

    get assessorsAvailableForAssignment(): Compliance.FormRevisionAssessorModel[] {
        return this._assessors.filter(x => !x.formRevisionYearAssessorId);
    }

    get assessorsAssigned(): Compliance.FormRevisionAssessorModel[] {
        return this._assessors.filter(x => x.formRevisionYearAssessorId);
    }

    get reports(): Compliance.FormRevisionReportModel[] {
        return this._reports;
    }

    get allReports(): Core.SavedSearchModel[] {
        return this._allReports;
    }

    get allFactorTables(): Compliance.FactorTableListItemModel[] {
        return this._allFactorTables;
    }

    get isFormTypeAppeal(): boolean {
        return this._formRevision && this._formRevision.formTypeId === Compliance.FormTypeEnum.Appeal;
    }

    get isFormTypeCompliance(): boolean {
        return this._formRevision && this._formRevision.formTypeId === Compliance.FormTypeEnum.Compliance;
    }

    get appealLevelId(): number {
        return this._formRevision && this._formRevision.appealLevelId;
    }

    get appealLevels(): AppealLevel[] {
        return this._appealLevels;
    }

    get anyAssessorMappingsCertified(): boolean {
        return this._anyAssessorMappingsCertified;
    }

    get templateValidationResult(): Compliance.ReturnFormTemplateValidationResultModel {
        return this._templateValidationResult;
    }

    get isPlaceholderTemplate(): boolean {
        return this._formRevision && this._formRevision.isPlaceholderTemplate;
    }

    get hasTemplateValidationErrors(): boolean {
        return !!(this._templateValidationResult && this._templateValidationResult.errors && this._templateValidationResult.errors.length);
    }

    get hasTemplateValidationWarnings(): boolean {
        return !!(this._templateValidationResult && this._templateValidationResult.warnings && this._templateValidationResult.warnings.length);
    }

    // observables
    get assessors$(): Observable<Compliance.FormRevisionAssessorModel[]> {
        return this._assessorsSubject.asObservable();
    }

    get assessor$(): Observable<Compliance.FormRevisionAssessorModel> {
        return this._assessorSubject.asObservable();
    }

    get formRevision$(): Observable<Compliance.FormRevisionModel> {
        return this._formRevisionSubject.asObservable();
    }

    get formRevisionYears$(): Observable<Compliance.FormRevisionYearModel[]> {
        return this._formRevisionYearsSubject.asObservable();
    }

    get formRevisionYear$(): Observable<Compliance.FormRevisionYearModel> {
        return this._formRevisionYearSubject.asObservable();
    }

    get assetClassificationMappings$(): Observable<void> {
        return this._assetClassificationMappingsSubject.asObservable();
    }

    get schedules$(): Observable<Compliance.FormRevisionScheduleModel[]> {
        return this._schedulesSubject.asObservable();
    }

    get factorTables$(): Observable<Compliance.FormFactorTableModel[]> {
        return this._factorTablesSubject.asObservable();
    }

    get reports$(): Observable<Compliance.FormRevisionReportModel[]> {
        return this._reportsSubject.asObservable();
    }

    get isInDevelopment$(): Observable<boolean> {
        return this._isInDevelopmentSubject.asObservable();
    }

    get isPrimary$(): Observable<boolean> {
        return this._isPrimarySubject.asObservable();
    }

    get anyAssessorMappingsCertified$(): Observable<boolean> {
        return this._anyAssessorMappingsCertifiedSubject.asObservable();
    }

    // events raised by components
    setExpandedComponent(component: IExpandableComponent): void {
        this._expandedComponent = component;
    }

    isComponentExpanded(component: IExpandableComponent): boolean {
        return this._expandedComponent === component;
    }

    isExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() === componentName);
    }

    isOtherComponentExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() !== componentName);
    }

    isValidForAppeal(componentName: string): boolean {
        return this._formRevision && (!this.isFormTypeAppeal || _.includes(this._validAppealPanels, componentName));
    }

    async setAssessors(assessors: Compliance.FormRevisionAssessorModel[]): Promise<void> {
        await this._setAssessors(assessors);
        this._assessorsSubject.next(this._assessors);
    }

    async setAssessor(assessor: Compliance.FormRevisionAssessorModel) {
        await this._setAssessor(assessor);
        this._assessorSubject.next(this._assessor);
    }

    async checkEditDefaultAssessor(): Promise<boolean> {
        if (this._warnDefaultAssessorEdit) {
            const result = await this._messageBoxService.open({
                title: 'Edit default assessor',
                message: 'Are you sure you wish to edit the default assessor?',
                buttons: MessageBoxButtons.YesNo
            });
            this._warnDefaultAssessorEdit = false;
            return result === MessageBoxResult.Yes;
        }
        return true;
    }

    async loadFormRevision(formRevisionId: number, taxYear?: number): Promise<void> {
        await this._loadFormRevision(formRevisionId, taxYear);
        this._formRevisionSubject.next(this._formRevision);
    }

    async setFormRevisionYear(formRevisionYear: Compliance.FormRevisionYearModel) {
        await this._setFormRevisionYear(formRevisionYear);
        this._formRevisionYearSubject.next(this._formRevisionYear);
    }

    async setFormRevisionAppealLevel(appealLevelId: number): Promise<void> {
        await lastValueFrom(this._formRepository.updateAppealLevel(this._formRevision.formRevisionId, appealLevelId));
        this._formRevision.appealLevelId = appealLevelId;
    }

    async loadAssetClassificationMappings(): Promise<void> {
        await this._loadAssetClassificationMappings();
        this._assetClassificationMappingsSubject.next();
    }

    async exportFormClassificationMappings(): Promise<number> {
        const result = await lastValueFrom(this._formRepository.exportFormClassificationMappings(this.assessor.formRevisionYearAssessorId));
        return result;
    }

    async setSchedules(schedules: Compliance.FormRevisionScheduleModel[]): Promise<void> {
        await this._setSchedules(schedules);
        this._schedulesSubject.next(this._schedules);
    }

    async loadSchedules(): Promise<void> {
        await this._loadSchedules();
        this._schedulesSubject.next(this._schedules);
    }

    async updateSchedules(schedules: Compliance.FormRevisionScheduleModel[], force: boolean): Promise<void> {
        const result = await lastValueFrom(this._formRepository.updateSchedules(this._formRevision.formRevisionId, this._formRevisionYear.taxYear, schedules, force));

        if (result.formClassificationMappingsUpdated) {
            await this.loadAssetClassificationMappings();
        }

        await this.validateTemplate();

        await this.setSchedules(result.schedules);
    }

    async setFactorTables(factorTables: Compliance.FormFactorTableModel[]): Promise<void> {
        await this._setFactorTables(factorTables);
        this._factorTablesSubject.next(this._factorTables);
    }

    async setReports(reports: Compliance.FormRevisionReportModel[]): Promise<void> {
        await this._setReports(reports);
        this._reportsSubject.next(this._reports);
    }

    async associateAssessors(associationModel: Compliance.FormRevisionAssessorAssociationModel): Promise<void> {
        await this._associateAssessors(associationModel);
        this._assessorsSubject.next(this._assessors);
    }

    async disassociateAssessor(formRevisionYearAssessorIds: number[], force: boolean): Promise<void> {
        await this._disassociateAssessors(formRevisionYearAssessorIds, force);
        this._assessorsSubject.next(this._assessors);
    }

    async assessorChangeIsCertified(formRevisionYearAssessorId: number, isCertified: boolean): Promise<void> {
        await this._assessorChangeIsCertified(formRevisionYearAssessorId, isCertified);
        this._assessorsSubject.next(this._assessors);
    }

    async getAssessorAddresses(formRevisionYearAssessorId: number): Promise<Compliance.FormRevisionYearAssessorAddressModel[]> {
        return await lastValueFrom(this._formRepository.getAssessorAddresses(formRevisionYearAssessorId));
    }

    async updateAssessorAddress(formRevisionYearAssessorId: number, addressId: number): Promise<void> {
        await this._updateAssessorAddress(formRevisionYearAssessorId, addressId);
        this._assessorsSubject.next(this._assessors);
    }

    async assessorChangeUseDefaultTables(formRevisionYearAssessorId, useDefaultTables: boolean): Promise<void> {
        await this._assessorChangeUseDefaultTables(formRevisionYearAssessorId, useDefaultTables);
        this._assessorsSubject.next(this._assessors);
        this._schedulesSubject.next(this._schedules);
    }

    async assessorChangeUseDefaultMappings(formRevisionYearAssessorId: number, useDefaultMappings: boolean): Promise<void> {
        await this._assessorChangeUseDefaultMappings(formRevisionYearAssessorId, useDefaultMappings);
        this._assessorsSubject.next(this._assessors);
    }

    async assessorChangeUsePropertyType(formRevisionYearAssessorId: number, usePropertyType: boolean, field: string): Promise<void> {
        await this._assessorChangeUsePropertyType(formRevisionYearAssessorId, usePropertyType, field);
        this._assessorsSubject.next(this._assessors);
    }

    async updateFactorTables(formRevisionYearAssessorId: number, factorTableIds: number[], force: boolean): Promise<void> {
        const updateModel: Compliance.FormRevisionFactorTableUpdateModel = {
            force: force,
            factorTableIds: factorTableIds
        };
        await lastValueFrom(this._formRepository.updateFactorTableList(formRevisionYearAssessorId, updateModel));
        if (force) {
            const factorTables = await lastValueFrom(this._formRepository.getFactors(formRevisionYearAssessorId));
            this._setFactorTables(factorTables);
            this._factorTablesSubject.next(this._factorTables);

        }
    }

    async updateIsPrimary(isPrimary: boolean, force:boolean = false): Promise<void> {
        const result = await lastValueFrom(this._formRepository.updateIsPrimary(this._formRevisionYear.formRevisionYearId, isPrimary, force));
        this._formRevisionYear.isPrimary = result.isPrimary;
        this._isPrimarySubject.next(result.isPrimary);
    }

    async updateIsInDevelopment(isInDevelopment: boolean): Promise<void> {
        const result = await lastValueFrom(this._formRepository.updateIsInDevelopment(this._formRevisionYear.formRevisionYearId, isInDevelopment));
        this._formRevisionYear.isInDevelopment = result.isInDevelopment;
        this._isInDevelopmentSubject.next(result.isInDevelopment);
    }

    async validateTemplate(): Promise<void> {
        await this._validateTemplate();
    }

    async downloadTemplate(): Promise<void> {
        const response = await lastValueFrom(this._formRepository.getTemplate(this._formRevision.formRevisionId));
        this._saveFileAsPdf(response.body, this._getFilenameFromResponseContentDisposition(response));
    }

    async updateTemplate(file: File, fileMetadata: Compliance.ReturnFormTemplateModel): Promise<Compliance.ReturnFormTemplateValidationResultModel> {
        const result = await lastValueFrom(this._formRepository.updateTemplate(file, fileMetadata));

        // if there are any errors, the template was not updated
        if (result.errors && result.errors.length) {
            return result;
        }

        this._formRevision.isPlaceholderTemplate = false;
        this._formRevision.templateChangeDate = result.templateChangeDate;
        this._formRevision.templateChangedBy = result.templateChangedBy;
        this._templateValidationResult = result;

        return result;
    }

    async deleteTemplate(): Promise<void> {
        const result = await lastValueFrom(this._formRepository.deleteTemplate(this._formRevision.formRevisionId));

        this._formRevision.isPlaceholderTemplate = true;
        this._formRevision.hasTemplate = false;
        this._formRevision.templateChangedBy = result.templateChangedBy;
        this._formRevision.templateChangeDate = result.templateChangeDate;
        this._templateValidationResult = null;
    }

    async previewTemplateEditMetadata(data: Compliance.FormRevisionTemplateEditModel): Promise<Compliance.FormRevisionTemplateMetadataModel> {
        return await lastValueFrom(this._formRepository.previewTemplateEditMetadata(this._formRevision.formRevisionId, data));
    }

    async previewTemplateEdit(data: Compliance.FormRevisionTemplateEditModel): Promise<void> {
        const response = await lastValueFrom(this._formRepository.previewTemplateEdit(this._formRevision.formRevisionId, data));
        this._saveFileAsPdf(response.body, this._getFilenameFromResponseContentDisposition(response));
    }

    async getTemplateMetadata(): Promise<Compliance.FormRevisionTemplateMetadataModel> {
        return await lastValueFrom(this._formRepository.getTemplateMetadata(this._formRevision.formRevisionId));
    }

    async applyTemplateEdit(data: Compliance.FormRevisionTemplateEditModel): Promise<void> {
        const result = await lastValueFrom(this._formRepository.applyTemplateEdit(this._formRevision.formRevisionId, data));

        this._templateValidationResult = result;
        this._formRevision.templateChangeDate = result.templateChangeDate;
        this._formRevision.templateChangedBy = result.templateChangedBy;
    }

    async getTemplatePageCount(): Promise<number> {
        return await lastValueFrom(this._formRepository.getTemplatePageCount(this._formRevision.formRevisionId));
    }

    async createTaxYear(taxYear: number): Promise<void> {
        const result = await lastValueFrom(this._formRepository.createNextTaxYear(this._formRevision.formRevisionId, taxYear));
        await this._loadFormRevision(this._formRevision.formRevisionId);
        await this._setFormRevisionYear(_.find(this._formRevision.formRevisionYears, x => x.formRevisionYearId === result.formRevisionYearId));
        this._formRevisionSubject.next(this._formRevision);
    }

    async getByAvailableToCopyTo(): Promise<Compliance.FormRevisionYearCopyModel[]> {
        return await lastValueFrom(this._formRepository.getByAvailableToCopyTo(this._formRevisionYear.formRevisionYearId));
    }

    async copyTo(targetFormRevisionId: number): Promise<void> {
        return await lastValueFrom(this._formRepository.copyTo(this._formRevisionYear.formRevisionYearId, targetFormRevisionId));
    }

    async deleteTaxYear(): Promise<void> {
        let yearIndex = _.findIndex(this._formRevisionYears, x => x === this._formRevisionYear);
        if (yearIndex > 0) {
            yearIndex = yearIndex - 1;
        }

        await lastValueFrom(this._formRepository.deleteTaxYear(this._formRevisionYear.formRevisionYearId));
        await this._loadFormRevision(this._formRevision.formRevisionId);
        await this._setFormRevisionYear(this._formRevisionYears[yearIndex]);
        this._formRevisionSubject.next(this._formRevision);
    }

    loadRoles(): void {
        this._loadRoles();
    }

    // helper methods
    getFactorTableTypeName(factorTableType: Compliance.FactorTableTypeEnum): string {
        switch (factorTableType) {
        case Compliance.FactorTableTypeEnum.Depreciation:
            return 'Depreciation';
        case Compliance.FactorTableTypeEnum.Index:
            return 'Index';
        default:
            return '';
        }
    }

    getReportType(formRevisionReport: Compliance.FormRevisionReportModel): string {
        if (!formRevisionReport) {
            return '';
        }

        return formRevisionReport.isSystem ? 'System' : 'Custom';
    }

    getReportName(formRevisionReport: Compliance.FormRevisionReportModel): string {
        if (!formRevisionReport) {
            return '';
        }

        return `${formRevisionReport.name}${(formRevisionReport.variantName ? ` - ${formRevisionReport.variantName}` : '')}`;
    }

    getSavedSearchReportType(savedSearch: Core.SavedSearchModel): string {
        if (!savedSearch) {
            return '';
        }

        return savedSearch.isSystemSearch ? 'System' : 'Custom';
    }

    getSavedSearchReportName(savedSearch: Core.SavedSearchModel): string {
        if (!savedSearch) {
            return '';
        }

        return `${savedSearch.searchName}${(savedSearch.variantName ? ` - ${savedSearch.variantName}`: '')}`;
    }

    updateFormRevisionInfo(info: Compliance.ReturnFormTemplateValidationResultModel): void {
        if (this._formRevision) {
            this._formRevision.isFormConsolidated = info.isFormConsolidated;
            this._formRevision.templateChangeDate = info.templateChangeDate;
            this._formRevision.templateChangedBy = info.templateChangedBy;
            this._formRevision.hasTemplate = info.hasTemplate;
            this._formRevision.templateSafeFileName = info.templateSafeFileName;
            this._formRevision.isPlaceholderTemplate = info.isPlaceholderTemplate;
        }
    }

    // private
    private async _loadFormRevision(formRevisionId: number, taxYear?: number): Promise<void> {
        this._isInitialized = false;

        // load the form revision
        this._formRevision = await lastValueFrom(this._formRepository.getRevision(formRevisionId));

        // remember the form revision
        this._lastFormRevision = this._formRevision;

        // load appeal levels
        if (this.isFormTypeAppeal) {
            await this._loadAppealLevels();
        }

        // load asset classifications
        await this._loadAssetClassifications();

        // load all reports
        await this._loadAllReports();

        // set form revision reports
        await this._setReports(this._formRevision.formRevisionReports);

        // set schedules
        await this._setSchedules(this._formRevision.formRevisionSchedules);

        // set form revision years
        await this._setFormRevisionYears(this._formRevision.formRevisionYears, taxYear);

        // validate the template
        await this._validateTemplate();

        this._isInitialized = true;
    }

    private _loadRoles(): void {
        this._canEdit = this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);
        this._canView = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
    }

    private async _loadAppealLevels(): Promise<void> {
        this._appealLevels = await this._appealService.getStateAppealLevels(this.formRevision.stateId);
    }

    private async _loadAssetClassifications(): Promise<void> {
        this._assetClassifications = await lastValueFrom(this._assetClassificationRepository.getAssetClassifications());
    }

    private async _loadAssetClassificationMappings(): Promise<void> {
        let mappings: Compliance.FormClassificationMappingModel[] = [];
        if (this._assessor) {
            mappings = await lastValueFrom(this._formRepository.getFormClassificationMappings(this._assessor.formRevisionYearAssessorId));
        }

        this._assetClassificationMappings = mappings;
    }

    private async _setAssessors(assessors: Compliance.FormRevisionAssessorModel[]): Promise<void> {
        this._assessors = assessors;

        // maintain the selected assessor when the list is updated
        let selectedAssessor: Compliance.FormRevisionAssessorModel = null;
        if (this._assessor) {
            selectedAssessor = _.find(this._assessors, item => item.formRevisionYearAssessorId === this._assessor.formRevisionYearAssessorId);
        }

        // set the default assessor
        this._defaultAssessor = _.find(this._assessors,
            (x) => {
                return x.assessorId === null;
            });

        // if not found in the new list, set to default assessor
        if (!selectedAssessor) {
            selectedAssessor = this._defaultAssessor;
        }

        // set flag if any assessor has been certified
        this._anyAssessorMappingsCertified = _.find(this._assessors, item => item.isMappingCertified) ? true : false;
        this._anyAssessorMappingsCertifiedSubject.next(this._anyAssessorMappingsCertified);

        // set the assessor
        await this._setAssessor(selectedAssessor);
    }

    private async _setAssessor(assessor: Compliance.FormRevisionAssessorModel): Promise<void> {
        this._assessor = assessor;
        this._warnDefaultAssessorEdit = this._assessor && !this._assessor.assessorId;

        // set the factor table assessor
        this._factorTableAssessor = this._assessor && !this._assessor.isUsingDefaultTables
            ? this._assessor
            : this._defaultAssessor;

        // load the assessor factor tables
        let factorTables: Compliance.FormFactorTableModel[] = [];
        if (this._assessor) {
            factorTables = await lastValueFrom(this._formRepository.getFactors(this._assessor.formRevisionYearAssessorId));
        }
        await this._setFactorTables(factorTables);

        // load asset classification mappings
        await this._loadAssetClassificationMappings();
    }

    private async _setFormRevisionYears(formRevisionYears: Compliance.FormRevisionYearModel[], taxYear?: number): Promise<void> {
        this._formRevisionYears = _.orderBy(formRevisionYears, [x => x.taxYear], ['desc']);

        this._maxFormRevisionYear = _.maxBy(this._formRevisionYears, (x) => x.taxYear);
        this._minFormRevisionYear = _.minBy(this._formRevisionYears, (x) => x.taxYear);

        let formRevisionYear: Compliance.FormRevisionYearModel = null;
        const selectedYear = taxYear
            ? this._formRevisionYears.find(x => x.taxYear === taxYear)
            : this._formRevisionYear;

        // persist the selected year between form selection
        if (selectedYear) {
            formRevisionYear = this._formRevisionYears.find((x) => { return x.formRevisionYearId === selectedYear.formRevisionYearId; });
        }

        // if no year selected, attempt to select the max
        if (!formRevisionYear) {
            formRevisionYear = this._maxFormRevisionYear;
        }

        await this._setFormRevisionYear(formRevisionYear);
    }

    private async _setFormRevisionYear(formRevisionYear: Compliance.FormRevisionYearModel): Promise<void> {
        this._formRevisionYear = formRevisionYear;

        // load the assessors
        const assessors = await lastValueFrom(this._formRepository.getAssessors(this._formRevision.formRevisionId, this._formRevisionYear.taxYear));

        // load all factor tables
        await this._loadAllFactorTables();

        await this._setAssessors(assessors);
    }

    private async _setSchedules(schedules: Compliance.FormRevisionScheduleModel[]): Promise<void> {
        this._schedules = schedules;
        await Promise.resolve();
    }

    private async _loadAllFactorTables(): Promise<void> {
        const searchModel: Compliance.FactorTableSearchModel = {
            stateId: this._formRevision.stateId,
            taxYear: this._formRevisionYear.taxYear,
            tableType: null,
            companyId: null,
            includeRetiredTables: false
        };

        this._allFactorTables = await lastValueFrom(this._formRepository.getFactorTableList(searchModel));
    }

    private async _loadAllReports(): Promise<void> {
        this._allReports = await lastValueFrom(this._formRepository.getReports());
    }

    private async _loadSchedules(): Promise<void> {
        let schedules: Compliance.FormRevisionScheduleModel[] = [];
        if (this._formRevision) {
            schedules = await lastValueFrom(this._formRepository.getSchedulesForRevision(this._formRevision.formRevisionId));
        }

        await this._setSchedules(schedules);
    }

    private async _setFactorTables(factorTables: Compliance.FormFactorTableModel[]): Promise<void> {
        this._factorTables = factorTables;
        await Promise.resolve();
    }

    private async _setReports(reports: Compliance.FormRevisionReportModel[]): Promise<void> {
        this._reports = reports;
        await Promise.resolve();
    }

    private async _associateAssessors(associationModel: Compliance.FormRevisionAssessorAssociationModel): Promise<void> {
        const updatedAssessors = await lastValueFrom(this._formRepository.associateAssessors(this._formRevision.formRevisionId, associationModel));

        //  update assessors
        _.forEach(updatedAssessors, updatedAssessor => {
            const existingAssessor = _.find(this._assessors, x => x.assessorId === updatedAssessor.assessorId);

            if (existingAssessor) {
                const assessorIndex = this._assessors.indexOf(existingAssessor);
                updatedAssessor.assessorName = existingAssessor.assessorName;
                this._assessors[assessorIndex] = updatedAssessor;
            }
            else {
                this._assessors.push(updatedAssessor);
            }
        });

        await this._setAssessors(this._assessors);
    }

    private async _disassociateAssessors(formRevisionYearAssessorIds: number[], force: boolean): Promise<void> {
        // remove the association
        await lastValueFrom(this._formRepository.disassociateAssessors(this._formRevision.formRevisionId, formRevisionYearAssessorIds, force));

        // if the selected assessor is deleted, set selected to default
        if (formRevisionYearAssessorIds.find(id => this._assessor.formRevisionYearAssessorId === id)) {
            this._assessor = this._defaultAssessor;
        }

        // null the IDs
        formRevisionYearAssessorIds.forEach(id => {
            const existingAssessor = _.find(this._assessors, x => x.formRevisionYearAssessorId === id);
            if(existingAssessor) {
                existingAssessor.formRevisionYearAssessorId = null;
            }
        });

        await this._setAssessors(this._assessors);
    }

    private async _assessorChangeIsCertified(formRevisionYearAssessorId: number, isCertified: boolean): Promise<void> {
        const updatedAssessor = await lastValueFrom(this._formRepository.assessorChangeIsCertified(formRevisionYearAssessorId, isCertified));

        let assessors = this._assessors;

        // when the default assessor mapping is updated, any assessor using default mappings will also get certification updated
        if (!updatedAssessor.assessorId) {
            assessors = await lastValueFrom(this._formRepository.getAssessors(this._formRevision.formRevisionId, this._formRevisionYear.taxYear));
        }

        const existingAssessor = _.find(assessors, x => x.formRevisionYearAssessorId === formRevisionYearAssessorId);

        if (existingAssessor) {
            _.extend(existingAssessor, updatedAssessor);
        }

        await this._setAssessors(assessors);
    }

    private async _updateAssessorAddress(formRevisionYearAssessorId: number, addressId: number): Promise<void> {
        const updatedAssessor = await lastValueFrom(this._formRepository.updateAssessorAddress(formRevisionYearAssessorId, addressId));

        const existingAssessor = _.find(this._assessors, x => x.formRevisionYearAssessorId === formRevisionYearAssessorId);

        if (existingAssessor) {
            _.extend(existingAssessor, updatedAssessor);
        }

        await this._setAssessors(this._assessors);
    }

    private async _assessorChangeUseDefaultTables(formRevisionYearAssessorId: number, useDefaultTables: boolean): Promise<void> {
        const updatedAssessor = await lastValueFrom(this._formRepository.assessorChangeUseDefaultTables(formRevisionYearAssessorId, useDefaultTables));

        const existingAssessor = _.find(this._assessors, x => x.formRevisionYearAssessorId === formRevisionYearAssessorId);

        if (existingAssessor) {
            _.extend(existingAssessor, updatedAssessor);
        }

        await this._setAssessors(this._assessors);
        await this._loadSchedules();
    }

    private async _assessorChangeUseDefaultMappings(formRevisionYearAssessorId: number, useDefaultMappings: boolean): Promise<void> {
        const updatedAssessor = await lastValueFrom(this._formRepository.assessorChangeUseDefaultMappings(formRevisionYearAssessorId, useDefaultMappings));

        const existingAssessor = _.find(this._assessors, x => x.formRevisionYearAssessorId === formRevisionYearAssessorId);

        if (existingAssessor) {
            _.extend(existingAssessor, updatedAssessor);
        }

        await this._setAssessors(this._assessors);
    }

    private async _assessorChangeUsePropertyType(formRevisionYearAssessorId: number, usePropertyType: boolean, field: string): Promise<void> {
        const updatedAssessor = await lastValueFrom(this._formRepository.assessorChangeUsePropertyType(formRevisionYearAssessorId, usePropertyType, field));

        const existingAssessor = _.find(this._assessors, x => x.formRevisionYearAssessorId === formRevisionYearAssessorId);

        if (existingAssessor) {
            _.extend(existingAssessor, updatedAssessor);
        }

        await this._setAssessors(this._assessors);
    }

    private async _validateTemplate(): Promise<void> {
        this._templateValidationResult = null;

        if (!this._formRevision) {
            return Promise.resolve();
        }

        this._templateValidationResult = await lastValueFrom(this._formRepository.validateTemplate(this._formRevision.formRevisionId));
    }

    private _getFilenameFromResponseContentDisposition(response: HttpResponse<any>): string {
        const contentDisposition = response.headers.get('content-disposition') || '';
        const matches = /filename="?([^;"]+)"?/ig.exec(contentDisposition);
        return (matches[1] || 'untitled').trim();
    }

    private _saveFileAsPdf(data: Blob, filename: string): void {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }
}
