<ws-modal-wrapper headerLabel="Configure Delivery Details"
    [cancelLabel]="params.editMode ? 'Cancel' : 'Close'"
    [hideSave]="!params.editMode"
    [disableSave] = "disableSave"
    (save)="save()"
    (cancel)="close()">
        <loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
        <div *ngIf="availableFeedSpecs">
            <div style="display: flex; align-items: center; gap: 10px; margin-bottom: 15px;">
                <div style="white-space: nowrap;">Client Abbreviation:</div>
                <input type="text"
                    maxlength="50"
                    [(ngModel)]="params.clientAbbreviation"
                    class="form-control"
                    [disabled]="!params.editMode">
            </div>
            <div *ngIf="!params.feedSpecifications" class="text-center text-muted">
                <em>Click to Add Feed Specifications</em>
            </div>
            <div style="max-height: 400px; overflow: auto; margin-bottom: 20px;">
                <table class="table" *ngIf="params.feedSpecifications">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th class="text-center">Included in Email</th>
                            <th *ngIf="isBillPay">Feed Applicability</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let spec of params.feedSpecifications" (mouseenter)="hoveredSpecId = spec.feedSpecificationID" (mouseleave)="hoveredSpecId = null">
                            <td style="white-space: nowrap;">{{spec.feedSpecification.name}}</td>
                            <td class="text-center">
                                <div class="checkbox" style="margin: 0;">
                                    <label>
                                        <input type="checkbox" [(ngModel)]="spec.includedInEmail" [disabled]="!params.editMode">
                                    </label>
                                </div>
                            </td>
                            <td  *ngIf="isBillPay">
                                {{spec.feedSpecification.apFeed && spec.feedSpecification.checkFeed  ? 'AP, Check' : 
                                  (spec.feedSpecification.apFeed && !spec.feedSpecification.checkFeed ? 'AP' : 
                                  (!spec.feedSpecification.apFeed && spec.feedSpecification.checkFeed ? 'Check' : ''
                                  ))}}
                            </td>
                      
                            <td>
                                <button class="flat-button warning-button icon-button"
                                    [ngClass]="{'invisible': !params.editMode || hoveredSpecId !== spec.feedSpecificationID}"
                                    (click)="removeSpec(spec)">
                                        <i class="fa fa-trash" ng-click="vm.removeSpec(spec)"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ws-select *ngIf="params.editMode"
                    placeholder="Add Specification"
                    [(ngModel)]="chosenSpec"
                    (selectedOptionChanged)="addSpec()"
                    [options]="availableFeedSpecs"
                    labelProperty="name"
                    [returnEntireOption]="true">
            </ws-select>
        </div>
</ws-modal-wrapper>
