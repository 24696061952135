import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

export interface UpdateCompanySettingConfig {
    useTopLevelCompanyVendorCodeMapping: boolean;
    invoiceAppealSavings: any;
    fiscalYearStart: any;
    syncDXP: any;
    budgetsEnabled: boolean;
    budgetBasis: any;
    accrualsEnabled: boolean;
    defaultAccrualAdjustmentMethodId: number;
    useTopLevelCompanyCOA: boolean;
    accrualEconUnitTypeId: number;
    accrualEconUnitDescriptorId: number;
    accrualFileTypeId: number;
    excludeFromAccruals: boolean;
    accrualDescriptors: any;
    accrualExportPropertyIdentificationId: number;
    accrualExportPropIdentDescriptorId: number;
    requireClientApproval: boolean,
    enableAllocations: boolean
}

export interface AddYearConfig {
    companyId: number;
    annualYear: number;
    stateIds?: number[];
}

@Injectable()
export class CompanyRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private SERVICE_URL = '/api/company/';

    searchCompanies(searchText: string, excludeInactive: boolean = true, topLevel: boolean = true): Observable<Core.CompanyDTO[]> {
        return this.httpGet(`api/company?search=${searchText}&pageNumber=1&pageSize=50&topLevelOnly=${topLevel}&favoriteOnly=false&activeOnly=${excludeInactive}`);
    }

    searchCompanyInfo(searchModel: Core.CompanyInfoSearchModel): Observable<Compliance.QueryResultModel<Core.CompanyInfoModel>> {
        return this.httpPost('api/company/info', searchModel);
    }

    loadCompany(companyId: number, config: any): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}`, config);
    }

    loadCompanyParent(companyId: number, topLevelCompanyId: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/parent/${topLevelCompanyId}`);
    }

    getCompanyName(companyId: number): Observable<string> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/name`);
    }

    getSubsidiaries(topLevelCompanyId: number, excludeInactive: boolean = false): Observable<Core.NameIdPair[]> {
        return this.httpGet(`${this.SERVICE_URL}${topLevelCompanyId}/subsidiaries?excludeInactive=${excludeInactive ? 'true' : 'false'}`);
    }

    updateCompany(company: any): Observable<any> {
        return this.httpPut(this.SERVICE_URL, company);
    }

    isCompanyNameInUse(model: Core.CompanyNameLookupDTO): Observable<boolean> {
        return this.httpPost(`${this.SERVICE_URL}IsCompanyNameInUse`, model);
    }

    isCompanyCodeInUseAlready(config: Core.CompanyCodeLookupDTO): Observable<boolean> {
        return this.httpPost(`${this.SERVICE_URL}IsCompanyCodeInUse`, config);
    }

    search(config): Observable<any> {
        return this.httpGet(this.SERVICE_URL, config);
    }

    getPaged(pageSize: number, pageNumber: number): Observable<boolean> {
        return this.httpGet(`/api/company?pagesize=${  pageSize  }&pagenumber=${  pageNumber}`);
    }

    create(company: any): Observable<any> {
        return this.httpPost(this.SERVICE_URL, company);
    }

    getStatesWithSites(companyId: number, activeOnly: boolean, includeSubsidiaries: boolean): Observable<string[]> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/StateSites?activeOnly=${activeOnly}&includeSubsidiaries=${includeSubsidiaries}`);
    }

    getByUserPermission(userId: number, isCache: boolean): Observable<any> {
        return this.httpGet(`/api/user/${userId}/accessiblecompanies`, {cache: isCache});
    }

    setIsCompanyFavorite(companyId: number, isFavorite: boolean): Observable<void> {
        return this.httpPut(`${this.SERVICE_URL + companyId}/setFavorite`, { isFavorite: isFavorite });
    }

    updateCompanySetting(companyId: number, config: Core.CompanySettingsDTO): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL + companyId}/UpdateSettings`, config);
    }

    getCompanyVendorCodesCount(companyId: number): Observable<number> {
        return this.httpGet(`${this.SERVICE_URL + companyId}/vendorCodesCount`);
    }

    addYear(config: AddYearConfig): Observable<Core.CompanyAddYearReturnDTO> {
        return this.httpPost(`${this.SERVICE_URL}addyeartoportfolio`, config);
    }

    getCompanyYears(companyId: number): Observable<number[]> {
        return this.httpGet(`${this.SERVICE_URL + companyId}/years`);
    }

    deleteCompany(id: number): Observable<void> {
        return this.httpDelete(this.SERVICE_URL + id);
    }

    getAddYearProcessStatus(longRunningProcessId: number): Observable<Core.CompanyAddYearReturnDTO> {
        return this.httpGet(`${this.SERVICE_URL}AddYearProcessStatus/${longRunningProcessId}`);
    }

    checkAccrualsEnabled(): Observable<boolean> {
        return this.httpGet(`${this.SERVICE_URL}AccrualsEnabled`);
    }

    checkForLongRunningProcesses(companyId: number): Observable<Core.CompanyAddYearReturnDTO[]> {
        return this.httpGet(`${this.SERVICE_URL}ExistingAddYearLongRunningProcess/${companyId}`);
    }

    getCompanyFiscalYearStart(companyId: number): Observable<string> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/fiscalYearStart`);
    }

    getTopCompanyForEntity(entityTypeId: number, entityId: number): Observable<Core.CompanyRecordDTO> {
        return this.httpGet(`${this.SERVICE_URL}TopCompany/ForEntity/${entityTypeId}/${entityId}`);
    }

    checkOngoingAction(companyId: number): Observable<Compliance.LongRunningProcessStatusChangeModel> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/OngoingAction`);
    }

    getEconomicUnitTypes(): Observable<Core.GetEconomicUnitTypesResult> {
        return this.httpGet(`${this.SERVICE_URL}accrual/economicunittypes`);
    }

    getInUseCharacteristics(topLevelCompanyId: number, activeOnly: boolean): Observable<Core.CompanyInUseCharacteristicsResult> {
        return this.httpGet(`${this.SERVICE_URL}${topLevelCompanyId}/inUseCharacteristics?activeonly=${activeOnly}`);
    }

    getAllowedAccrualFileTypes(topLevelCompanyId: number): Observable<Core.AllowedAccrualFileTypesResultModel> {
        return this.httpGet(`${this.SERVICE_URL}${topLevelCompanyId}/Accrual/AllowedFileTypes`);
    }

    getInfoForCompliance(companyId: number) : Observable<Core.CompanyModel> {
        return this.httpGet(`${this.SERVICE_URL}getInfoForCompliance/${companyId}`);
    }
}
