<ws-modal-wrapper headerLabel="Further Limit To"
                  headerHelpContentId="asset-limit.header"
                  (save)="save()"
                  (cancel)="cancel()">
    <div class="ws-flex-container-vertical ws-stretch">
        <form [formGroup]="furtherLimitForm">
            <div class="ws-flex-container-vertical advanced-filter-quick-list form-inline">
                <label class="ws-flex-none ws-font-weight-normal">
                    <span class="filter-label">Quick:</span>
                    <select class="form-select form-select-sm" name="selectOptions" id="selectLabel"
                            [formControl]="selectedOption" (change)="applyFromDropdown()">
                        <option *ngFor="let option of limitOptions" [ngValue]="option">{{ option.name }}</option>
                    </select>
                </label>
            </div>
            <div class="ws-flex-container-vertical">
                <label>Field override:</label>
                <ws-multi-select labelProperty="displayName"
                              valueProperty="property"
                              placeholder="Please select a value..."
                              [options]="overrideProperties"
                              [formControl]="selectedOverrides">
                </ws-multi-select>
            </div>
            <div class="ws-flex-container-vertical">
                <label>Issue:</label>
                <div class="ws-flex-container-vertical asset-limit-details-group">
                    <label class="ws-flex-none small ws-font-weight-normal" for="invalidCostAllocations">
                        <input type="checkbox" id="invalidCostAllocations" [formControl]="invalidCostAllocations"> Split cost allocations invalid
                    </label>
                </div>
            </div>
            <div class="ws-flex-container-vertical">
                <label>Other:</label>
                <div class="ws-flex-container-vertical asset-limit-details-group">
                    <label class="ws-flex-none small ws-font-weight-normal" for="noReportingParcel">
                        <input type="checkbox" id="noReportingParcel" [formControl]="noReportingParcel"> No reporting parcel
                    </label>
                </div>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
