import { Pipe, PipeTransform } from '@angular/core'
import { Constants } from "../../../constants.new";

@Pipe({ name: 'wsFactorTableTypeName' })
export class WeissmanFactorTableTypeNamePipe implements PipeTransform {
    constructor(private _constants: Constants) { }
    transform(tableType: number): string {
        let factorTableType = this._constants.FactorTableTypes.find(x => x.value === tableType);
        return (factorTableType && factorTableType.name) || (tableType && tableType.toString());
    }
}
