<div class="command-center-view-controls">
    <form class="ws-flex-container-horizontal filter-row ws-flex-align-items-center" [formGroup]="viewControlForm">
        <label class="ws-no-margin small" for="selectedState">State:</label>
        <div class="view-control">
            <ws-select labelProperty="fullName"
                       [returnEntireOption]="true"
                       placeholder="Nothing selected"
                       [canDeselect]="true"
                       deselectLabel="Nothing selected"
                       [deselectValue]="deselectValue"
                       [valueFormatter]="stateFormatter"
                       formControlName="state"
                       searchType="state"
                       [options]="states">
            </ws-select>
        </div>
        <label class="ws-no-margin small" for="selectedState">Tax Year:</label>
        <div class="view-control">
            <ws-select [returnEntireOption]="true"
                       [isPrimitive]="true"
                       formControlName="taxYear"
                       [canSearch]="false"
                       [options]="taxYears">
            </ws-select>
        </div>
    </form>
    <form class="ws-flex-container-horizontal filter-row ws-flex-align-items-center" [formGroup]="filterForm">
        <label class="ws-no-margin small"
               for="selectedState"
               helpTooltip
               helpContentId="state-jurisdiction-command-center.highlight"
               position="bottom">Highlight Changes:</label>
        <div class="view-control">
            <ws-select labelProperty="name"
                       valueProperty="value"
                       placeholder="Nothing selected"
                       [canDeselect]="true"
                       deselectLabel="Nothing selected"
                       formControlName="recentChanges"
                       [canSearch]="false"
                       [options]="highlightDates">
            </ws-select>
        </div>
        <label class="ws-no-margin small"
               for="selectedState"
               helpTooltip
               helpContentId="state-jurisdiction-command-center.filter-verification"
               position="bottom">Verification Status:</label>
        <div class="view-control">
            <ws-select labelProperty="name"
                       valueProperty="value"
                       placeholder="Nothing selected"
                       [canDeselect]="true"
                       deselectLabel="Nothing selected"
                       formControlName="verification"
                       [canSearch]="false"
                       [options]="verificationOptions">
            </ws-select>
        </div>
    </form>
</div>
