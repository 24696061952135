<div class="certified-tooltip mt-2">
    <span *ngIf="params.areAllAssessorsCertified">All assessors are certified.</span>
    <span *ngIf="!params.areAllAssessorsCertified">
        The following assessors are not certified:
        <div class="certified-list mt-1 mx-3">
           <ul>
               <li *ngFor="let assessor of params.nonCertifiedAssessors">{{assessor.assessorName}}</li>
           </ul>
        </div>
    </span>
</div>

