import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { DragulaService } from 'ng2-dragula';
import { AgGridOverlayService } from './agGridOverlay.service';
import { AgGridOverlayContainer } from './agGridOverlayContainer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface AgGridOverlayDropEvent {
    sourceId: string;
    targetId: number;
}

@Component({
    selector: 'ag-grid-overlay',
    templateUrl: './agGridOverlay.component.html'
})
export class AgGridOverlayComponent implements OnInit, OnDestroy {
    constructor(
        private _dragulaService: DragulaService,
        private _agGridOverlayService: AgGridOverlayService
    ) { }

    private destroy$: Subject<void> = new Subject<void>();

    // keep track of the grid horizontal scroll offset changes in order to correctly position new overlay containers when they're added
    private _horizontalScrollOffset: number = 0;

    private _getDragulaTargetId(name: string, el: Element) {
        if (name !== this.bagName) return null;

        // only care about drops related to the overlay containers that this component manages
        const dropEl = el;
        if (!dropEl || dropEl.getAttribute('overlay-bag-name') !== this.bagName) return null;
        const columnId = parseInt(dropEl.getAttribute('column-id'));

        return columnId;
    }

    private _getDragulaSourceId(name: string, el: Element) {
        if (name !== this.bagName) return null;

        // get the source element and retrieve a unique identifier for it
        const sourceEl = el;
        const sourceId = sourceEl.getAttribute(this.sourceElIdAttribute);

        return sourceId;
    }

    /**
     * Controls the visibility of the overlay.
     */
    @Input() isVisible: boolean;

    /**
     * The bag name to use for the drag-and-drop containers.
     */
    @Input() bagName: string;

    /**
     * An attribute on the element being dropped that identifies it.
     */
    @Input('sourceElementIdAttribute') sourceElIdAttribute: string;

    /**
     * Event queue for when a source element is successfully dropped in an overlay container
     */
    @Output() drop: EventEmitter<AgGridOverlayDropEvent> = new EventEmitter<AgGridOverlayDropEvent>();

    /**
     * A collection of overlays to superimpose on top of the Ag Grid.
     */
    overlayContainers: AgGridOverlayContainer[] = [];

    // dummy property required by dragula
    selectedOverlayItems: any[] = [];

    ngOnInit(): void {
        this._dragulaService.drop(this.bagName).pipe(takeUntil(this.destroy$)).subscribe(value => {
            let columnId = this._getDragulaTargetId(value.name, value.target);
            let sourceId = this._getDragulaSourceId(value.name, value.el);
            if (sourceId && columnId) {
                this.drop.emit({
                    sourceId: sourceId,
                    targetId: columnId
                });
            }
        });

        this._dragulaService.over(this.bagName).pipe(takeUntil(this.destroy$)).subscribe(value => {
            let columnId = this._getDragulaTargetId(value.name, value.container);
            let container = this.overlayContainers.find(i => i.columnId === columnId);
            if (container) {
                container.hasMouseOver = true;
            }
        });

        this._dragulaService.out(this.bagName).pipe(takeUntil(this.destroy$)).subscribe(value => {
            let columnId = this._getDragulaTargetId(value.name, value.container);
            let container = this.overlayContainers.find(i => i.columnId === columnId);
            if (container) {
                container.hasMouseOver = false;
            }
        });

        this._agGridOverlayService.columnAdd$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            let container = this.overlayContainers.find(i => i.columnId === value.columnId);
            if (container) {
                return;
            }
            container = new AgGridOverlayContainer(value.columnId);
            container.left = value.left;
            container.width = value.width;
            container.horizontalScrollOffset = this._horizontalScrollOffset;
            this.overlayContainers.push(container);
        });

        this._agGridOverlayService.columnRemove$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            const container = this.overlayContainers.find(i => i.columnId === value);
            if (!container) {
                return;
            }
            this.overlayContainers.splice(this.overlayContainers.indexOf(container), 1);
        });

        this._agGridOverlayService.columnResize$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            value.columns.forEach(i => {
                const container = this.overlayContainers.find(c => c.columnId === i.columnId);
                if (container) {
                    container.left = i.left;
                    container.width = i.width;
                }
            });
        });

        this._agGridOverlayService.gridHorizontalScroll$.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this._horizontalScrollOffset = value;
            this.overlayContainers.forEach(i => i.horizontalScrollOffset = value);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
