import { Component, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager } from './busyIndicatorMessageManager';
import { BusyIndicatorRef } from './busyIndicatorRef';
import { BusyIndicatorMessageIdService } from './busyIndicatorMessageId.service';
import { WebsocketListenerService } from '../Compliance/websocketListener.service';
import { takeUntil } from 'rxjs/operators';
import { LongRunningProcessRepository } from '../Compliance/Repositories';
import { TimerService } from '../UI-Lib/Utilities';

export const BUSY_INDICATOR_ANIMATION_LENGTH: number = 200;
export const LONG_RUNNING_PROCESS_START_TIME: number = 5000;

@Component({
    selector: 'ws-busy-indicator',
    templateUrl: './busyIndicator.component.html',
    styleUrls: ['./busyIndicator.component.scss'],
    animations: [
        trigger('animateBackdrop', [
            state('inactive', style({
                opacity: 0
            })),
            state('active', style({
                opacity: 0.6
            })),
            transition('inactive <=> active', [
                animate(BUSY_INDICATOR_ANIMATION_LENGTH)
            ])
        ]),
        trigger('animateContainer', [
            state('inactive', style({
                opacity: 0
            })),
            state('active', style({
                opacity: 1
            })),
            transition('inactive <=> active', [
                animate(BUSY_INDICATOR_ANIMATION_LENGTH)
            ])
        ])
    ]
})
export class BusyIndicatorComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _elRef: ElementRef,
        private readonly _busyIndicatorMessageIdService: BusyIndicatorMessageIdService,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _timer: TimerService
    ) {
        this._messageManager = new BusyIndicatorMessageManager<BusyIndicatorRef>(true);
    }

    @Input()
    identifier: string;

    @Input()
    title: string;

    @Input()
    set message(value: string) {
        this._messageManager.reset();
        this.addMessage(value, this.identifier || this._busyIndicatorMessageIdService.generateUniqueMessageIdentifier());
    }

    @Input()
    delay: number;

    @Input()
    hasAction: boolean;

    @Input()
    canDismiss: boolean;

    @Input()
    progressPercentage: number = 0;

    @Input()
    actionMessage: string;

    @Input()
    captureFocus: boolean;

    @Input()
    restoreFocus: boolean;

    @Input()
    zIndexOverride: number;

    isDestroyed: boolean = false;

    isLongRunningProcessDelayed = false;

    manualUpdateLoading: boolean = false;

    signalRDisconnected = false;

    backdropAnimationState = 'inactive';

    containerAnimationState = 'inactive';

    progressFailure: boolean = false;

    actionTriggered = false;

    private _current: number = 0;

    private _total: number = 0;

    private _focusedEl: HTMLElement;

    private _actionSource = new Subject<any>();

    private _dismissSource = new Subject<number>();

    private _messageManager: BusyIndicatorMessageManager<BusyIndicatorRef>;

    private _progressBarSource = new Subject<boolean>();

    private _longRunningProcessId: number;

    private _statusReceived: boolean = false;

    private _longRunningProcessStartTimer: number;

    private _destroy$: Subject<void> = new Subject();

    get message(): string {
        return this._messageManager.message;
    }

    get paddingBottom(): string {
        if (this.canDismiss || this.hasAction) {
            return '6px';
        } else if (this.progressPercentage) {
            return '24px';
        } else {
            return '39px';
        }
    }

    get longRunningProcessDelayHelp(): string {
        let message = 'The system might be limiting the number of processes that can run simultaneously.  ';

        if (this.canDismiss) {
            message += 'You can continue to wait or click OK to work on something else while the process runs in the background.  ';
        }

        return message;
    }

    ngOnInit(): void {
        if (this.captureFocus) {
            this._focusedEl = this._getFocusedElement();
        }

        this._timer.setTimeout(() => { this.setAnimationState('active'); }, this.delay || 0);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();

        if (this.captureFocus && this.restoreFocus && this._focusedEl) {
            this._focusedEl.focus();
        }

        this._clearLongRunningProcessStartTimer();

        this.isDestroyed = true;
    }

    async setAnimationState(animationState: string): Promise<void> {
        return new Promise<void>((resolve) => {
            this.backdropAnimationState = animationState;
            this.containerAnimationState = animationState;

            if (animationState === 'active' && this.captureFocus) {
                const el = this._getFocusableElement();
                if (el) {
                    el.focus();
                }
            }

            this._timer.setTimeout(() => resolve(), BUSY_INDICATOR_ANIMATION_LENGTH);
        });
    }

    /**
     * Set the progress listener based on the long-running process ID
     * @param id
     */
    async setLongRunningProcessId(id: number): Promise<void> {
        if (!id) {
            return Promise.resolve();
        }

        this._longRunningProcessId = id;
        this._statusReceived = false;
        this.signalRDisconnected = this._websocketListenerService.signalRDisconnected;
        this._destroy$.next();
        this._clearLongRunningProcessStartTimer();

        this._longRunningProcessStartTimer = this._timer.setTimeout(() => {
            this._clearLongRunningProcessStartTimer();
            this.isLongRunningProcessDelayed = true;
        }, LONG_RUNNING_PROCESS_START_TIME);

        this._websocketListenerService.longRunningProcessDisconnected$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            this.signalRDisconnected = true;
        });

        this._websocketListenerService.longRunningProcessReconnected$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            this.signalRDisconnected = false;
            this.refreshFromLRPServer();
        });

        const longRunningProcess = await lastValueFrom(this._longRunningProcessRepository.get(this._longRunningProcessId));

        if (longRunningProcess.start) {
            this._clearLongRunningProcessStartTimer();
        }

        if ((!this._statusReceived) && longRunningProcess.isCompleted) {
            if (longRunningProcess.isCanceled || longRunningProcess.error) {
                this.progressFailed();
                return Promise.resolve();
            }

            this.progressSuccess();
        }

        this._websocketListenerService.longRunningProcessStatusChange$.pipe(takeUntil(this._destroy$))
            .subscribe(status => {
                if (!(this._longRunningProcessId === status.longRunningProcessId)) {
                    return;
                }

                this._clearLongRunningProcessStartTimer();

                if (!status.isCompleted) {
                    return;
                }

                this._statusReceived = true;

                if (status.isCanceled || status.isError) {
                    this.progressFailed();
                    return;
                }

                this.progressSuccess();
            });

        this._websocketListenerService.longRunningProcessProgressChange$.pipe(takeUntil(this._destroy$))
            .subscribe((progress) => {
                if (this._longRunningProcessId !== progress.longRunningProcessId) {
                    return;
                }

                this._clearLongRunningProcessStartTimer();

                this._statusReceived = true;

                this._current = progress.current;
                this._total = progress.total;

                if (this._current && this._total && !(!this.progressPercentage && this._current === this._total)) {
                    this.progressPercentage = Math.round((progress.current / (progress.total)) * 100);
                }

                this.updateMessage(progress.message || '', this.identifier);
            });
    }

    /**
     * Take busy indicator action
     */
    action(): void {
        if (!this.actionTriggered) {
            this.actionTriggered = true;
            this._actionSource.next(this._longRunningProcessId);
            this._actionSource.complete();
        }
    }

    /**
     * An observable that emits when the Action button is clicked.
     */
    onAction(): Observable<number> {
        return this._actionSource.asObservable();
    }

    /**
     * Take busy indicator action
     */
    dismiss(): void {
        this._dismissSource.next(this._longRunningProcessId);
        this._dismissSource.complete();
    }

    /**
     * An observable that emits when the Action button is clicked.
     */
    onDismiss(): Observable<number> {
        return this._dismissSource.asObservable();
    }

    /**
     * Adds a message to the busy indicator messages.
     * @param message The message to add.
     * @param id The message identifier.
     */
    addMessage(message: string, id: any): void {
        this._messageManager.add(message, id);
    }

    /**
     * Removes a message from the busy indicator messages.
     * @param id The message identifier.
     */
    removeMessage(id: any): void {
        this._messageManager.remove(id);
    }

    /**
     * Updates a busy indicator message.
     * @param message The updates messages.
     * @param id The message identifier.
     */
    updateMessage(message: string, id: any): void {
        this._messageManager.update(message, id);
    }

    /**
     * Counts the busy indicator messages.
     */
    messageCount(): number {
        return this._messageManager.count();
    }

    /**
     * Update the progress bar on success
     */
    progressSuccess(): Promise<void> {
        if (this._current && !(!this.progressPercentage && this._current === this._total)) {
            this.progressPercentage = 100;
        }

        // Allow time for progress bar to animate before closing
        return new Promise(resolve => {
            this._timer.setTimeout(() => {
                    this._progressBarSource.next(true);
                    this._progressBarSource.complete();
                    resolve();
                }, 600);
        });
    }

    /**
     * Update the progress bar in the event of a failure
     */
    progressFailed(): Promise<void> {
        this.progressFailure = true;

        if (this._current && !(!this.progressPercentage && this._current === this._total)) {
            this.progressPercentage = 100;
        }

        // Allow time for progress bar to animate before closing
        return new Promise(resolve => {
            this._timer.setTimeout(() => {
                this._progressBarSource.next(false);
                this._progressBarSource.complete();
                resolve();
            }, 600);
        });
    }

    /**
     * An observable that emits when the Action button is clicked.
     */
    onProgressBarComplete(): Observable<boolean> {
        return this._progressBarSource.asObservable();
    }

    /**
     * If SignalR is disconnected the user can manually check for updates
     */
    async refreshFromLRPServer(): Promise<void> {
        if (this.manualUpdateLoading) {
            return;
        }
        this.manualUpdateLoading = true;
        const lrp$ = this._longRunningProcessRepository.get(this._longRunningProcessId);
        const lrp = await lastValueFrom(lrp$);
        this._websocketListenerService.updateLRPManually(lrp);
        this.manualUpdateLoading = false;
    }

    private _getFocusedElement(): HTMLElement {
        return (<HTMLElement>document.activeElement === document.body) ? null : <HTMLElement>document.querySelector(':focus');
    }

    private _getFocusableElement(): HTMLElement {
        return this._elRef.nativeElement.querySelector('.ryn-busy-container #cancelButton') || this._elRef.nativeElement.querySelector('.ryn-busy-container');
    }

    private _clearLongRunningProcessStartTimer(): void {
        if (this._longRunningProcessStartTimer) {
            clearTimeout(this._longRunningProcessStartTimer);
            this._longRunningProcessStartTimer = null;
        }

        this.isLongRunningProcessDelayed = false;
    }
}
