<ws-modal-wrapper headerLabel="Browse Columns"
                  [acceptLabel]="('Add' + (columnsToAdd.length ? (' ' + columnsToAdd.length) : ''))"
                  [disableSave]="!columnsToAdd.length"
                  auxButtonLabel="Clear Selection"
                  [excludeModalStyling]="true"
                  (save)="addColumns()"
                  (cancel)="closeModal()"
                  (auxButton)="clearSelection()">
    <tabset class="column-picker-tabs">
        <tab *ngFor="let category of columnCategories" (selectTab)="tabSelected(category)" [ngClass]="{ 'browse-category-disabled': category.disabled }">
            <ng-template tabHeading>
                {{category.categoryName}}<span class="badge" *ngIf="category.totalCheckedCount">{{category.totalCheckedCount}}</span>
            </ng-template>
            <div *ngIf="category.disabled" class="ws-flex-container-horizontal ws-flex-justify-content-center browse-columns-height">
                <div *ngIf="!isTaskTypeSpecialCase">
                    <h4>This category is disabled because the following fields are already included:</h4>
                    <ul>
                        <li *ngFor="let col of offendingColumns">{{col.typeAheadName || col.displayName}}</li>
                    </ul>
                </div>
                <div *ngIf="isTaskTypeSpecialCase">
                    <h4>This category is disabled because the <strong>{{selectedTaskType.name}}</strong> task type is selected.</h4>
                </div>
            </div>
            <tabset *ngIf="!category.disabled" [vertical]="true" type="pills" class="column-subcategories">
                <tab>
                    <ng-template tabHeading>
                        General<span class="badge" [class.invisible]="!category.checkedCount">{{category.checkedCount}}</span>
                    </ng-template>
                    <div class="browse-columns-height category-column">
                        <div *ngFor="let column of category.columns" class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="isColumnChecked(column)" (change)="columnClicked(column, $event)" [disabled]="column.disabled"> {{column.displayName}}
                            </label>
                        </div>
                    </div>
                </tab>
                <tab *ngFor="let subcategory of category.subcategories"
                     [ngClass]="{ 'browse-category-disabled': subcategory.disabled }"
                     [customClass]="'text-nowrap'"
                     (selectTab)="tabSelected(subcategory)">
                    <ng-template tabHeading>
                        {{subcategory.categoryName}}<span class="badge" [class.invisible]="!subcategory.checkedCount">{{subcategory.checkedCount}}</span>
                    </ng-template>
                    <div *ngIf="subcategory.disabled" class="browse-columns-height">
                        <h4>This subcategory is disabled because the following fields are already included:</h4>
                        <ul>
                            <li *ngFor="let col of offendingColumns">{{col.typeAheadName || col.displayName}}</li>
                        </ul>
                    </div>
                    <div *ngIf="!subcategory.disabled" class="browse-columns-height category-column">
                        <div *ngFor="let column of subcategory.columns" class="checkbox">
                            <label>
                                <input type="checkbox" [checked]="isColumnChecked(column)" (change)="columnClicked(column, $event)"
                                       [disabled]="column.disabled"> {{column.displayName}}
                            </label>
                        </div>
                    </div>
                </tab>
            </tabset>
        </tab>
    </tabset>
</ws-modal-wrapper>
