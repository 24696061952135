(function () {
	'use strict';

	var VIEW_TEMPLATE_URL = 'app/User/_userSetup.html';
	var CONTROLLER_NAME = 'sd.User.Setup.Controller';

	angular
		.module('weissmanApp')
		.directive('sdUserSetup', sdUserSetup);

	sdUserSetup.$inject = [];

	function sdUserSetup() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			templateUrl: VIEW_TEMPLATE_URL,
			controller: CONTROLLER_NAME,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			scope: {
				contact: '=',
				tabSelected: '=',
				closeModal: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}
})();
