import { Injectable } from "@angular/core";
import { BaseRepository } from "../Common/Data/base.repository";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Site, SiteSummary } from "../Entity/Site/Site.Model";

@Injectable(
    { providedIn: 'root' }
)
export class SiteRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly PARCEL_URL: string = '/api/parcel/';
    private readonly SERVICE_URL = '/api/site/';

    getSite(siteId: number, config: any): Observable<any> {
        return this.httpGet(this.SERVICE_URL + siteId, config);
    }

    loadSite(siteId: number, companyId: number, navigationRequest: boolean): Observable<any> {
        const config = {
            params: {
                parentid: companyId
            },
            navigationRequest
        };

        return this.httpGet(this.SERVICE_URL + siteId, config);
    }

    getCompanyId(siteId: number): Observable<number> {
        return this.httpGet(this.SERVICE_URL + siteId + '/companyId');
    }

    getParcelIdsBySiteId(siteId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL + siteId + '/parcelids', { parentid: siteId });
    }

    updateSite(site: Site, companyId: number): Observable<any> {
        return this.httpPut(this.SERVICE_URL, site, { parentid: companyId });
    }

    deleteSite(siteId: number): Observable<any> {
        return this.httpDelete(this.SERVICE_URL + siteId)
    }

    addSite(site: Site): Observable<any> {
        const params = {
            companyID: site.companyID
        };

        return this.httpPost(this.SERVICE_URL, site, params);
    }

    getParcelsForSiteId(siteId: number): Observable<any> {
        return this.httpGet('api/parcel/forsite/' + siteId)
    }

    post(parcel: Core.ParcelModel): Observable<Core.ParcelTileDTO> {
        return this.httpPost(this.PARCEL_URL, parcel);
    }

    loadByCompanyId(companyId: number): Observable<Site[]> {
        return this.httpGet(this.PARCEL_URL, { parentid: companyId });
    };

    getSiteName(siteId: number): Observable<string> {
        return this.httpGet(this.SERVICE_URL + siteId + '/name');
    };

    getParcelsBySiteId(siteId: number): Observable<any[]> {
        return this.httpGet(this.SERVICE_URL + siteId + '/parcelids', { parentid: siteId });
    };

    getSiteListByCompanyId(companyId: number, excludeInactive: boolean): Observable<SiteSummary[]> {
        return this.httpGet('/api/company/' + companyId + '/sites?excludeInactive=' + excludeInactive);
    };

    getSiteListByCompanyIdOtherInstance(companyId: number, excludeInactive: boolean): Observable<SiteSummary[]>
    {
        return this.httpGet('/api/company/' + companyId + '/sites/allInstanceMembership?excludeInactive=' + excludeInactive);
    }

    searchSites(companyId: number, searchModel: Core.SiteSearchModel): Observable<Core.SiteModel[]> {
        return this.httpPost(this.SERVICE_URL + 'company/' + companyId + '/search', searchModel);
    };

    getReportingParcelBySiteId(siteId: number): Observable<Core.ParcelModel> {
        return this.httpGet(this.SERVICE_URL + siteId + '/reportingParcel', { parentid: siteId });
    };

    getNavigationInfo(siteId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL + siteId + '/Navigation');
    };

    update(site: Site, companyId: number): Observable<Site> {
        return this.httpPut(this.SERVICE_URL + '?parentid=' + companyId, site);
    }
}
