import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { TypeaheadSelectorComponent, HigherOrderFn, Predicate } from '../Selectors/Typeahead-Selector/typeaheadSelector.component';

export interface EntityImportEditorTaxingDistrictSelectorCellEditorParams extends ICellEditorParams {
    getTaxingDistricts(rowIndex: number): Core.TaxingDistrictModel[];
}

@Component({
    selector: 'entity-import-editor-taxing-district-selector',
    template: `<typeahead-selector #typeaheadSelector
                                  labelProperty="displayName"
                                  valueProperty="name"
                                  sortProperty="displayName"
                                  placeholder="Filter Taxing Districts..."
                                  [formControl]="taxingDistrictValue"
                                  [canReset]="true"
                                  [originalValue]="originalValue"
                                  [filterFn]="typeaheadFilter"
                                  [findFn]="typeaheadFilter"
                                  (changed)="onTaxingDistrictChanged($event)"
                                  [values]="taxingDistricts"></typeahead-selector>`
})
export class EntityImportEditorTaxingSelectorCellEditorComponent implements ICellEditorAngularComp {
    @ViewChild("typeaheadSelector", { static: true }) typeaheadSelector: TypeaheadSelectorComponent;

    gridRow: Compliance.ImportGridRowGridModel;
    taxingDistrictValue: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    taxingDistricts: Core.TaxingDistrictModel[];
    originalValue: string;
    typeaheadFilter: HigherOrderFn<Predicate> = (filter: string) => (x: Core.TaxingDistrictModel) => this._isMatch(x, filter);

    private _params: EntityImportEditorTaxingDistrictSelectorCellEditorParams;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this._formatCode(this.taxingDistrictValue.value);
    }

    isCancelAfterEnd(): boolean {
        if (this.typeaheadSelector.value && !this.typeaheadSelector.filter) {
          this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    async agInit(params: EntityImportEditorTaxingDistrictSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;

        if (this._params.data && this._params.data.originalValues) {
            this.originalValue = this._params.data.originalValues[this._params.colDef.field];
        }

        this.taxingDistrictValue.setValue(params.value);

        this.taxingDistricts = await params.getTaxingDistricts(this.gridRow.rowIndex);

        if (this.taxingDistricts && this.taxingDistricts.length) {
            this.taxingDistrictValue.enable();
        }
    }

    onTaxingDistrictChanged(value?: string): void {
        if (this.taxingDistrictValue.value !== value) {
            const formatted = this._formatCode(value);
            this.taxingDistrictValue.setValue(formatted);
            this._params.node.setDataValue(this._params.column.getColId(), formatted);
        }
    }

    private _formatCode(value: string): string {
        if (value === null) {
          return null;
        }

        let code = '';

        const taxingDistrict = this.taxingDistricts.find(i => this._isMatch(i, value));

        if (taxingDistrict) {
            const allMatches = this.taxingDistricts.filter(i => this._isMatch(i, taxingDistrict.code));
            code = taxingDistrict.code;

            if (allMatches.length > 1) {
                code = `${taxingDistrict.code}|${taxingDistrict.townshipCode}`;
            }
        } else {
            code = this._params.value;
        }

        return code;
    }

    private _isMatch(taxingDistrict: Core.TaxingDistrictModel, selectedValue: string): boolean {
        const value = (selectedValue || '').toLocaleLowerCase();
        const localTaxingDistrict = {
            taxingDistrictId: taxingDistrict.taxingDistrictId,
            code: taxingDistrict.code.toLocaleLowerCase(),
            name: taxingDistrict.name.toLocaleLowerCase(),
            townshipCode: taxingDistrict.townshipCode.toLocaleLowerCase(),
            townshipName: taxingDistrict.townshipName.toLocaleLowerCase(),
        } as Core.TaxingDistrictModel;

        let result = value === '';

        if (!result) {
            const items = value.split('|');
            result = (localTaxingDistrict.name.indexOf(items[0]) >= 0 || localTaxingDistrict.code.indexOf(items[0]) >= 0) &&
                (items.length === 1 || localTaxingDistrict.townshipName.indexOf(items[1]) >= 0 || localTaxingDistrict.townshipCode.indexOf(items[1]) >= 0);
        }

        return result;
    }
}
