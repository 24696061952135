import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AmplitudeEvent, ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { AdvancedSearchPersistenceService, AdvancedSearchResults, AdvancedSearchRouteParams, SavedSearchModel } from './advancedSearchPersistence.service';
import { BusyIndicatorService, SnackBarService } from '../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';
import { SmartSearchService } from './smartSearch.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { ColumnFilterService } from '../../Common/AV-AS/Column-Filters/columnFilter.service';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { SMART_SEARCH_HELP } from './smartSearch.help';

import * as _ from 'lodash';

@Component({
    selector: 'smart-search',
    templateUrl: './smartSearch.component.html',
    styleUrls: ['./smartSearch.component.scss']
})
export class SmartSearchComponent implements OnInit, OnDestroy {
    constructor(private readonly _navigationService: UpgradeNavigationServiceHandler,
                private readonly _restrictService: RestrictService,
                private readonly _smartSearchService: SmartSearchService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _columnFilterService: ColumnFilterService,
                private readonly _busyIndicatorService: BusyIndicatorService,
                private readonly _helpService: HelpService,
                private readonly _toastr: ToastrService,
                private readonly _snackBarService: SnackBarService,
                private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Output() columnsExpandedValueHasBeenChanged: EventEmitter<any> = new EventEmitter();

    routeParams: AdvancedSearchRouteParams;
    searchId: string;
    searchType: any;
    search: SavedSearchModel;
    searchResults: AdvancedSearchResults;

    hideCriteria: boolean;
    isSavedSearch: boolean;
    isSystemSearch: boolean;
    showPageTitle: boolean;
    columnsExpanded: boolean;
    excelDownloading: boolean;
    hasEditPermission: boolean;
    hasRyanPrivatePermission: boolean;
    haveAllSearchFields: boolean;
    invalidColumns = [];

    private readonly _REFINE_SEARCH_EVENT: AmplitudeEvent = { event: 'refine-smart-search-criteria', properties: { smartRefineCriteria: 'blue button' } };
    private _destroy$: Subject<void> = new Subject();

    get noOutputColumns(): boolean {
        return this.search && (!this.search.searchCriteria || !this.search.searchCriteria.outputColumns || !this.search.searchCriteria.outputColumns.length);
    }

    ngOnInit(): void {
        this._helpService.setContent(SMART_SEARCH_HELP);

        this.hasEditPermission = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);
        this.hasRyanPrivatePermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT) ||
            this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

        this.activate();

        this._advancedSearchPersistenceService.updateAdvancedSearch$.pipe(takeUntil(this._destroy$))
            .subscribe((data) => {
                if (this.searchId === data.searchId.toString()) {
                    this.activate();
                }
            });

        this._advancedSearchPersistenceService.deleteAdvancedSearch$.pipe(takeUntil(this._destroy$))
            .subscribe((data) => {
                if (this.searchId === data.searchId.toString()) {
                    this._advancedSearchPersistenceService.clearSearchData();
                    this._navigationService.transitionTo('search', {}, { reload: true });
                }
            });

        this._advancedSearchPersistenceService.advancedSearch$.pipe(takeUntil(this._destroy$))
            .subscribe(search => this.search = search);

        this._advancedSearchPersistenceService.showCriteria$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.hideCriteria = !x);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._smartSearchService.cancelSearch();
    }

    activate(): void {
        this.invalidColumns = [];

        this.searchId = this._navigationService.getQuerystringParam('searchId');
        this.searchType = this._navigationService.getQuerystringParam('searchType');
        const filters = this._navigationService.getQuerystringParam('filters');

        this.routeParams = {
            searchId: +this.searchId,
            searchType: this.searchType,
            filters
        };

        if (this._advancedSearchPersistenceService.columnsExpanded != null) {
            this.columnsExpanded = this._advancedSearchPersistenceService.columnsExpanded;
        } else {
            this.columnsExpanded = false;
            this._advancedSearchPersistenceService.columnsExpanded = false;
        }
    }

    showCriteria(): void {
        this._productAnalyticsService.logSequenceEvent(this._REFINE_SEARCH_EVENT.event, 'click-smart-criteria-button');
        this._smartSearchService.cancelSearch();
        this._advancedSearchPersistenceService.showCriteria(true);
        this.excelDownloading = false;
        this.columnsExpanded = this._advancedSearchPersistenceService.columnsExpanded;
        this.updateSearchResults(null);
    }

    updateSearch(search: SavedSearchModel): void {
        this.search = search;
    }

    updateSearchResults(searchResults: AdvancedSearchResults): void {
        this.searchResults = searchResults;
    }

    cancelSearch(): void {
        this._smartSearchService.cancelSearch();
    }

    async executeSearch(): Promise<any> {
        this._advancedSearchPersistenceService.setIsSearching(true);
        let uniqueID;

        const payloadCriteria = { ...this.search.searchCriteria };

        //WK-5012 - some situations have a blank frequentlyUsed, which is ignored by API, so account for that
        if(payloadCriteria.frequentlyUsed === undefined) {
            payloadCriteria.frequentlyUsed = false;
        }

        payloadCriteria.searchName = this.search.searchName;

        if (!payloadCriteria.searchName || payloadCriteria.searchName.trim() == '') {
            payloadCriteria.searchName = 'SMART Results';
        }

        else {
            payloadCriteria.searchName = payloadCriteria.searchName.replace(/[^a-z0-9_ \-]/gi, '');
        }

        // just in case if there was nothing except special characters
        if (!payloadCriteria.searchName || payloadCriteria.searchName.trim() == '') {
            payloadCriteria.searchName = 'SMART Results';
        }

        //If excel output is selected, don't hide criteria or null results, just stay where we are
        if (this.search.searchCriteria.outputFormat !== 1) {
            this._advancedSearchPersistenceService.showCriteria(false);
        }

        payloadCriteria.outputColumns = _.chain(payloadCriteria.outputColumns)
            .map('columnId')
            .union(this._advancedSearchPersistenceService.hiddenColumns)
            .value();

        this._advancedSearchPersistenceService.hiddenColumns = [];

        payloadCriteria.filters = this._columnFilterService.prepareFilters(payloadCriteria.filters);

        payloadCriteria.outputInt = payloadCriteria.outputFormat;
        payloadCriteria.savedSearchID = this.searchId || null;

        // return search result for grid search or long running process for excel
        try {
            switch (payloadCriteria.outputInt) {
                case 0:
                    const gridResult = await this._smartSearchService.executeSearch(payloadCriteria);
                    this.searchResults = gridResult;
                    this.searchResults.payloadCriteria = payloadCriteria;
                    if (this.searchResults.dataTable && (this.searchResults.dataTable as unknown as any[]).length >= 50000) {
                        this._toastr.warning('SMART Search', '50,000 records are shown; if you want to see all results please use the "Excel" output options.', { timeOut: 6000 });
                    }

                    //FOR TESTING
                    this._advancedSearchPersistenceService.results = this.searchResults;
                    this._advancedSearchPersistenceService.search = this.search;
                    this._advancedSearchPersistenceService.routeParams = this.routeParams;
                    this._advancedSearchPersistenceService.searchType = this.searchType;
                    this._advancedSearchPersistenceService.showPageTitle = !this.searchType;
                    this._advancedSearchPersistenceService.fromWidget = false;

                    break;
                case 1:
                    // saving xsls
                    const busyRef = this._busyIndicatorService.show({
                        message: 'Exporting search results'
                    });

                    this.excelDownloading = false;

                    try {
                        const lrpId = await this._smartSearchService.export(payloadCriteria);
                        this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.SmartSearchExport);
                    }
                    finally {
                        busyRef.hide();
                    }

                    break;
                case 2:
                    // saving csv
                    const result = await this._smartSearchService.executeSearch(payloadCriteria);
                    this.searchResults = null;
                    this._advancedSearchPersistenceService.showCriteria(true);
                    break;
            }
        } catch(err) {
            console.log(['Advanced search - Failure', err.message]);
            // error handler
            if (err.status === 0) {
                // $http timeout
                console.log('Advanced search - Failure - $http timeout');
            } else {
                // response error status from server
                console.log('Advanced search - Failure - response error status from server ');
                //warningService.warning("Smart Search", "The search has been cancelled", 6000);
                this.searchResults = { payloadCriteria: payloadCriteria } as AdvancedSearchResults;

                this._advancedSearchPersistenceService.showCriteria(true);
            }
            return Promise.reject();
        } finally {
            this._advancedSearchPersistenceService.setIsSearching(false);
        }
    }

    getGridData(): void {
        this._productAnalyticsService.initiateSequenceEvent(this._REFINE_SEARCH_EVENT, ['click-smart-search-button', 'click-smart-criteria-button']);
        this._productAnalyticsService.logEvent('click-SMART-search-output', { smartOutput: 'web' });
        this.search.searchCriteria.outputFormat = 0;
        this.executeSearch();
    }

    async exportToExcel(): Promise<void> {
        this._productAnalyticsService.logEvent('click-SMART-search-output', { smartOutput: 'excel' });
        this.search.searchCriteria.outputFormat = 1;
        this.excelDownloading = true;
        await this.executeSearch();
        this.excelDownloading = false;
    }
}
