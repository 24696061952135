import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../Account/user.service';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { Slide } from '../../UI-Lib/Carousel/carousel.component';
import { AccountService } from '../../User/account.service';
import { TopNavService } from '../Top-Nav/topNav.service';

@Component({
    selector: 'nav-onboarding',
    templateUrl: './navOnboarding.component.html'
})
export class NavOnboardingComponent implements IWeissmanModalComponent<void, void> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _topNavService: TopNavService,
                private readonly _accountService: AccountService,
                private readonly _userService: UserService) {}

    params: void;
    result: void;

    slides: Slide[] = [
        { imageUrl: '/images/onboarding/1.svg' },
        { imageUrl: '/images/onboarding/2.svg' },
        { imageUrl: '/images/onboarding/3.svg' }
    ];
    currentSlide: number = 0;

    get progressBtnLabel(): string {
        return this.currentSlide === this.slides.length - 1 ? 'FINISH' : 'NEXT';
    }

    progress(): void {
        if (this.currentSlide < this.slides.length - 1) {
            this.currentSlide++;
        } else {
            this.cancel();
        }
    }

    async cancel(fromHashChange: boolean = false): Promise<void> {
        if (fromHashChange) {
            return;
        }
        if (this._userService.getUser().onboardingStatus === Core.UserOnboardingStatusEnum.NoAction) {
            await this._accountService.updateOnboardingStatus(Core.UserOnboardingStatusEnum.Completed);
        }
        this._topNavService.showOnboardingBanner();
        this._bsModalRef.hide();
    }
}
