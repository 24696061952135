import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Subscription , BehaviorSubject } from 'rxjs';

export interface FormScheduleListGridInputCellRendererParams extends ICellEditorParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: FormScheduleListGridInputCellRendererParams) => boolean;
    onValueChanged: (params: FormScheduleListGridInputCellRendererParams, value: string) => void;
}

@Component({
    selector: 'form-schedule-list-grid-input',
    template: `
    <div *ngIf="params.data && !(params.canEdit(params) && editMode)" class="read-only-label" [title]="params.value">{{ params.value }}</div>
    <div *ngIf="params.data && params.canEdit(params) && editMode">
            <input #inputBox type="text" [(ngModel)]="value" (ngModelChange)="params.onValueChanged(params, $event)">
    </div>
`
})
export class FormScheduleListGridInputCellRendererComponent implements ICellEditorAngularComp {
    private _editModeSub: Subscription;

    params: FormScheduleListGridInputCellRendererParams;
    editMode: boolean = false;
    value: string;

    @ViewChild('inputBox') inputBox: ElementRef;

    agInit(params: FormScheduleListGridInputCellRendererParams): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
        this.value = params.data[params.colDef.field];
    }

    ngOnDestroy(): void {
        this._editModeSub && this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    afterGuiAttached(): void {
        if (this.inputBox) {
            this.inputBox.nativeElement.focus();
        }
    }

    getValue(): string {
        return this.value;
    }
}
