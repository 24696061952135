
<div class="modal-header">
    <h4 class="modal-title">Output Columns</h4>
    <span class="float-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </span>
</div>
<div class="modal-body exclude-modal-styling" style="padding: 35px 15px 5px 15px;">
    <div class="text-center" style="margin: 50px 0;" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading" style="overflow: auto; max-height: 700px;">
        <div *ngFor="let category of calendarWidgetFields; let i=index;" class="card panel-flat-color secondary-panel small-panel">
            <div class="panel-flat-header small-header pointer" (click)="category.showBody = !category.showBody">
                <panel-chevron [showBody]="category.showBody"></panel-chevron>
                <h3>{{category.categoryName}}</h3>
            </div>
            <div class="card-body" *ngIf="category.showBody">
                <category-column-picker [category]="category" cpMode="smart"></category-column-picker>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!loading" class="modal-footer">
    <div>
        <button class="flat-button primary-button" (click)="saveAndClose()" [disabled]="loading">Save</button>
        <button class="flat-button warning-button" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
