(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ReassignTaskModalController', ReassignTaskModalController);

	ReassignTaskModalController.$inject = ['$uibModalInstance', 'taskService', 'taskIDs', 'searchTimestamp', 'ReassignModes', 'reassignMode', 'instanceId', 'featureFlagsService'];

	function ReassignTaskModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, taskService, taskIDs, searchTimestamp, ReassignModes, reassignMode, instanceId, featureFlagsService) {
		var vm = this;

		activate();

		function activate() {
			vm.user = {};
			vm.reassignMode = reassignMode;
			vm.ReassignModes = ReassignModes;

			if(featureFlagsService.featureFlags.enableMultiUserPermissionsCheck){
				vm.entityIdsMultiselect = taskIDs;
				vm.entityType = 'Task';
			}
			else{
				vm.entityId = instanceId;
				vm.entityType = 'Instance';
			}
		}

		vm.save = function () {
		    $uibModalInstance.close(function () {
		        switch (reassignMode) {
		            case ReassignModes.Default:
                        var reassignRequest = _.cloneDeep(vm.user);
                        reassignRequest.taskIds = taskIDs;
                        reassignRequest.searchTimestamp = searchTimestamp;
                        return taskService.reassignMany(reassignRequest);
		            case ReassignModes.ChangeContact:
		                var changeRequest = {
		                    taskIds: taskIDs,
		                    searchTimestamp: searchTimestamp,
		                    userId: vm.user.userID,
                            teamId: vm.user.teamID
		                };
                        return taskService.changeContacts(changeRequest);
		            default:
		                throw new Error("Unrecognized reassign mode " + reassignMode);
		        }
		    });
		}

		vm.cancel = function () {
			$uibModalInstance.dismiss();
		}
	}
})();
