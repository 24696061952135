import { HelpContentComponent, HelpContentRendererValue } from '../../../UI-Lib/Help-Tooltip';
import { Component } from '@angular/core';

export interface ReturnBatchTaxYearAdditionalInfoComponentParams extends HelpContentRendererValue<any> {
    lienDate: Date;
    cutOffDate: Date;
    changeDetection: string;
    ages: string[];
}

@Component({
    selector: 'return-batch-tax-year-additional-info',
    template: `
        <table>
            <tr>
                <td class="info-label">Lien Date:</td>
                <td>{{ params.lienDate | weissmanDateFormat }}</td>
            </tr>
            <tr>
                <td class="info-label">Cut Off Date:</td>
                <td>{{ params.cutOffDate | weissmanDateFormat }}</td>
            </tr>
            <tr>
                <td class="info-label">Change:</td>
                <td>{{ params.changeDetection }}</td>
            </tr>
            <tr *ngFor="let age of params.ages; index as i">
                <td class="info-label">Age {{i + 1}}:</td>
                <td>{{ age }}</td>
            </tr>
        </table>
    `,
    styles: ['.info-label {font-weight: bold; padding-right: 5px;}']
})
export class ReturnBatchTaxYearAdditionalInfoComponent implements HelpContentComponent<ReturnBatchTaxYearAdditionalInfoComponentParams> {
    params: ReturnBatchTaxYearAdditionalInfoComponentParams;

    helpInit(params: ReturnBatchTaxYearAdditionalInfoComponentParams): void {
    }
}
