<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" style="display: flex; justify-content: space-between">
        <h3>Available Collectors</h3>
        <div class="header-button-container">
            <button class="flat-button icon-button primary-button" *ngIf="!editMode && hasWritePermission"
                (click)="edit()">
                <i class="fa fa-pencil"></i>
            </button>
            <span *ngIf="editMode">
                <button class="flat-button icon-button success-button" (click)="save()">
                    <i class="fa fa-save"></i>
                </button>
                <button class="flat-button icon-button warning-button" (click)="cancel();">
                    <i class="fa fa-close"></i>
                </button>
            </span>
        </div>
    </div>
    <div class="panel-flat-body">
        <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
        <div *ngIf="editMode" style="margin-bottom: 10px;">
            <div class="ws-flex-container-horizontal" style="align-items: center; justify-content: center; gap: 10px;">
                <div class="form-group-sm ws-flex-none ws-flex-container-horizontal" style="align-items: center; gap: 5px;">
                    <span class="ws-font-weight-normal ws-flex-none">State:</span>
                    <state-dropdown class="ws-flex-none" style="padding: 2px;" [stateId]="state.stateID" [filterByCountryId]="state.countryId"
                        (stateIdChange)="loadAvailableCollectors($event)" [disabled]="loadingAvailableCollectors">
                    </state-dropdown>
                </div>
                <ws-select placeholder="Add Collector" [(ngModel)]="newCollector" [isLoading]="loadingAvailableCollectors"
                    (selectedOptionChanged)="addAssociation()" [options]="filteredStateCollectors" labelProperty="abbr"
                    [returnEntireOption]="true" searchType="substring">
                </ws-select>
            </div>
        </div>
        <table class="table table-condensed table-striped">
            <thead>
                <tr>
                    <th>Default</th>
                    <th>Collector</th>
                    <th>State</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let collector of collectorAssociations">
                    <td>
                        <input type="checkbox" [disabled]="!editMode" [(ngModel)]="collector.isDefault" />
                    </td>
                    <td>
                        <a *ngIf="!editMode"
                            anchor-route
                            [sref]="{ target: 'collector', options: { stateId: state.stateID, stateCollectorId: collector.collectorID } }">
                            {{collector.collector.abbr}}
                        </a>
                        <span *ngIf="editMode">{{collector.collector.abbr}}</span>
                    </td>
                    <td>{{collector.stateAbbr}}</td>
                    <td>
                        <button *ngIf="editMode" class="flat-button icon-button warning-button" (click)="removeAssociation(collector)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
