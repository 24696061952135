(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.service('actionViewPersistenceService', actionViewPersistenceService);

	function actionViewPersistenceService() {
		var self = this;
		self.routeParams = {};
		self.filterColumns = {};
		self.outputColumns = [];
		self.detailResults = null;
		self.overviewResults = null;
		self.overviewHeaders = [];
		self.drilldownMode = false;
		self.resultsChanged = false;
		self.selectedResult = {};
		self.actionViewType = null;
		self.actionViewName = '';
		self.masterFilter = null;
		self.drilldownFilter = null;
		self.showOrs = false;
		self.organizeBySelection = 0;
		self.groupByCount = 0;
		self.sort = {};
		self.externalDrilldown = false;
		self.externalDrilldownData = null;
		self.filter = null;
		self.columnState = null;
		self.organizeTotals = null;
		self.grandTotals = null;

		self.clearSavedData = function() {
			self.routeParams = {};
			self.filterColumns = {};
			self.outputColumns = [];
			self.detailResults = null;
			self.overviewResults = null;
			self.overviewHeaders = [];
			self.drilldownMode = false;
			self.resultsChanged = false;
			self.selectedResult = {};
			self.actionViewType = null;
			self.actionViewName = '';
			self.masterFilter = null;
			self.drilldownFilter = null;
			self.showOrs = false;
			self.organizeBySelection = 0;
			self.groupByCount = 0;
			self.sort = {};
			self.externalDrilldown = false;
			self.externalDrilldownData = null;
			self.filter = null;
			self.columnState = null;
			self.organizeTotals = null;
			self.grandTotals = null;
		}
	}
})();
