import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from 'src/app/Busy-Indicator';
import { TaskProcessingActions } from 'src/app/Compliance/Return/Models/enums';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { PaymentBatchService } from '../../paymentBatch.service';

export interface PaymentBatchTaskExceptionModalParams {
    workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    paymentBatchId: number;
}

interface PBTaskExceptionOption {
    taskId: number;
    action: TaskProcessingActions;
    title: string;
    taskName: string;
    assignedUserId: System.Guid;
    assignedTeamId: number;
    // includeCompanyRepresentative: boolean;
    isAuthorized?: boolean;
    message: string;
}

@Component({
    selector: 'payment-batch-task-exception-modal',
    templateUrl: 'paymentBatchTaskExceptionModal.component.html'
})


export class PaymentBatchTaskExceptionModalComponent implements OnInit, IWeissmanModalComponent<PaymentBatchTaskExceptionModalParams, Core.PaymentBatchTaskUpdateResultModel> {
    constructor(private readonly _bsModalRef: BsModalRef,
        private readonly _paymentBatchService: PaymentBatchService) { }

    params: PaymentBatchTaskExceptionModalParams;
    result: Core.PaymentBatchTaskUpdateResultModel;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    comment: string;
    options: PBTaskExceptionOption[] = [];
    selectedOption: PBTaskExceptionOption;
    isReassignedAuthorized: boolean = true;
    reassignOptionName: string;
    isRescindAuthorized: boolean = true;
    TaskProcessingActions = TaskProcessingActions;

    ngOnInit() {
        this.options = [];
        if(this.params.workflowStatus.canReassignTask && this.params.workflowStatus.taskStatusName)
        {
            this.options.push({
                action: TaskProcessingActions.ReassignTask,
                taskId: this.params.workflowStatus.taskID,
                title: `Reassign ${this.params.workflowStatus.taskStatusName}`,
                message: 'Reassigning',
                taskName: this.params.workflowStatus.taskStatusName,
                assignedTeamId: null,
                assignedUserId: '',
                isAuthorized: !!this.params.workflowStatus.taskStatusName
            });
        }

        if(this.params.workflowStatus.canRescindTask && this.params.workflowStatus.taskRescindName) {
            this.options.push({
                action: TaskProcessingActions.RescindTask,
                taskId: this.params.workflowStatus.taskID,
                title: `Rescind ${this.params.workflowStatus.taskRescindName}`,
                message: 'Rescinding',
                taskName: 'placeholder',
                assignedTeamId: null,
                assignedUserId: '',
                isAuthorized: true
            });
        }
     }

    selectedOptionChanged(e) {
        console.log(e);
    }

    userChanged(newUser:  Core.UserTeamModel, option: PBTaskExceptionOption) {
        option.assignedTeamId = newUser.teamID;
        option.assignedUserId = newUser.userID;
    }

    async save() {
        const busyMsg = 'saving';
        this.busyIndicatorMessageManager.add(this.selectedOption.message, busyMsg);

        try {
            this.result = await this._actOnTask();

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        return Promise.resolve();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    private async _actOnTask(): Promise<Core.PaymentBatchTaskUpdateResultModel> {
        const updateModel = {
            taskId: this.params.workflowStatus.taskID,
            comment: this.comment
        };

        switch (this.selectedOption.action) {
            case TaskProcessingActions.RescindTask:
                return this._paymentBatchService.rescindTask(this.params.paymentBatchId, updateModel);
            case TaskProcessingActions.ReassignTask:
                return this._paymentBatchService.reassignTask(this.params.paymentBatchId, {
                    ...updateModel,
                    assignedTeamId: this.selectedOption.assignedTeamId,
                    assignedUserId: this.selectedOption.assignedUserId
                });
        }
    }
}