import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ColDef, ColumnApi, GridApi, GridReadyEvent, RowDataChangedEvent, RowNode } from 'ag-grid-community';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { AgGridExportOptions } from '../../../Compliance/AgGrid/ToolPanel/models';
import { takeUntil } from 'rxjs/operators';
import { SmartSearchService } from '../smartSearch.service';
import { AdvancedSearchColumnBuilder } from './advancedSearchColumnBuilder.service';
import { AdvancedSearchPersistenceService, AdvancedSearchResults } from '../advancedSearchPersistence.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { AdvancedSearchListService } from './advancedSearchList.service';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker } from '../../../Compliance/AgGrid/MultiSelectTracker';
import { AdvancedSearchBulkUpdateComponent, AdvancedSearchBulkUpdateFieldInfo, AdvancedSearchBulkUpdateParams, FieldCollection } from './Bulk-Update/advancedSearchBulkUpdate.component';
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';

@Component({
    selector: 'advanced-search-list',
    templateUrl: './advancedSearchList.component.html',
    styleUrls: ['./advancedSearchList.component.scss']
})
export class AdvancedSearchListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _smartSearchService: SmartSearchService,
        private readonly _columnBuilder: AdvancedSearchColumnBuilder,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _advancedSearchListService: AdvancedSearchListService,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastr: ToastrService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input()
    set searchResults(searchResults: AdvancedSearchResults) {
        if (searchResults && searchResults.dataTable) {
            this._tableData = searchResults.dataTable;
            this._buildTable(this._tableData);
        } else {
            this._clearTable();
        }
    }

    @Output() exportToExcel: EventEmitter<void> = new EventEmitter();

    haveAllSearchFields: boolean;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    gridTracker: AgGridMultiSelectTracker;
    columnApi: ColumnApi;
    gridId: System.Guid = '59C76BFE-ADE9-450A-88FE-F4F1B61C4443';
    gridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => {
            this.gridTracker.onGridSortChanged();
            this.emitAnalyticsEvent('click-column-sort');
        },
        getRowNodeId: (x) => x.rowId,
        rowClassRules: {
            'clickable': (params) => false,
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.rowId)
        },
        suppressScrollOnNewData: true,
        onRowDataChanged: (event: RowDataChangedEvent) => {
            if (this._advancedSearchPersistenceService.scrollLocationIndex) {
                this._gridApi.ensureIndexVisible(this._advancedSearchPersistenceService.scrollLocationIndex, 'top');
                this._advancedSearchPersistenceService.scrollLocationIndex = null;
            }
        }
    })
        .withSort()
        .withColumnResize()
        .withMultipleColumnSort()
        .withLoadingOverlay()
        .build();

    exportOptions: AgGridExportOptions = {
        onExportClick: (): void => {
            this.emitAnalyticsEvent('click-SMART-excel-export');
            this.exportToExcel.emit();
        },
        disabled: false,
        canCancel: true
    };

    private _gridApi: GridApi;
    private _tableData: any[];
    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        // we need to do this in order to preload search fields; once they are cached by service this call will return cached version
        await this._smartSearchService.getAllFields(true);
        this.haveAllSearchFields = true;

        this._advancedSearchPersistenceService.isSearching$.pipe(takeUntil(this._destroy$)).subscribe(searching => {
            if (!this._gridApi) { return; }
            if (searching) {
                this._gridApi.showLoadingOverlay();
            } else {
                this._gridApi.hideOverlay();
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.columnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            const isBulkUpdateVisible = this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
        });

        if (this._tableData) {
            this._buildTable(this._tableData);
        }
    }

    async bulkUpdate(): Promise<void> {
        const fieldInfo: AdvancedSearchBulkUpdateFieldInfo[] = [];

        // Retrieve the selected row data
        const rowIds = await this.gridTracker.getSelectedRowIds();
        const rowData = rowIds.map(x => {
            const rowNode = this._gridApi.getRowNode(`${x}`);
            return rowNode && rowNode.data;
        }).filter(x => !!x);

        // Get the search fields that match the ids provided by the data
        const rowDataIds = Object.keys(rowData[0]);
        const searchFields = rowDataIds.reduce((acc, x) => {
            const fieldId = +x.slice(1);
            if (isNaN(fieldId)) { return acc; }

            const field = this._smartSearchService.getFieldById(fieldId);
            if (!field) { return acc; }

            acc.push(field);

            return acc;
        }, []);

        // Collect the descriptor ids from columns if all required values are available
        const columns = this.columnApi.getAllColumns();
        const ptxCompanyFields = ['TopLevelCompanyAssessmentTaxFeed', 'TopLevelCompanyTaxBillTaxFeed' ];
        const ptxParcelFields = ['ParcelAssessmentTaxFeed', 'ParcelTaxBillTaxFeed' ];
        const fieldCollection = columns.reduce((acc, x) => {
            const columnId = x.getColId().slice(1);
            const field = this._smartSearchService.getFieldById(+columnId);
            if (field && field.descriptorID) {
                // Check to make sure the entity field required for update is provided
                const depthFieldId = field.advancedSearchCategory.parentCategoryID * 100;
                const depth = this._smartSearchService.getFieldById(depthFieldId);
                if(depth) {
                    if (rowDataIds.find(y => y === `n${depthFieldId}`)) {
                        acc.fields.push({ field, depth });
                        acc.fieldIds.push(+columnId);
                    }
                }
            // Bulk Update via SMART is done only for characteristics.
            // These are the only 4 fields where non characteristic fields are being bulk updated.
            // So these fields do not have a descriptor id.
            } else if (field && (ptxCompanyFields.includes(field.internalName) || ptxParcelFields.includes(field.internalName))) {
                    let depthFieldId:number;
                    if(ptxCompanyFields.includes(field.internalName)) {
                        depthFieldId = this._smartSearchService.getFieldByDisplayName('Company_ParentCompanyID').advancedSearchFieldID;
                    } else {
                        depthFieldId = this._smartSearchService.getFieldByDisplayName('ParcelID').advancedSearchFieldID;
                    }
                    const depth = this._smartSearchService.getFieldById(depthFieldId);
                    if(depth) {
                        if (rowDataIds.find(y => y === `n${depthFieldId}`)) {
                            acc.fields.push({ field, depth });
                            acc.fieldIds.push(+columnId);
                        }
                    }
            }
            return acc;
        }, {
            fields: [],
            fieldIds: []
        } as FieldCollection);

        // If not enough info at this point, bail out
        if (!fieldCollection.fieldIds.length) {
            this._toastr.warning('Site or parcel output columns must be included for bulk update.');
            this._gridApi.hideOverlay();
            this.gridTracker.clear();
            this._gridApi.redrawRows();
            return;
        }

        // Open the modal with all of the data just retrieved
        const params: AdvancedSearchBulkUpdateParams = {
            searchFields,
            selection: rowData,
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            fieldCollection,
            fieldInfo
        };

        const result = await this._modalService.showAsync(AdvancedSearchBulkUpdateComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        this._productAnalyticsService.logEvent('click-SMART-bulk-update', { smartBulkUpdatedRecords: result.length });

        // Update the rows selected with the result
        this._gridApi.updateRowData({ update: result });
        this.gridTracker.clear();
        this._gridApi.redrawRows();
    }

    emitAnalyticsEvent(event: string): void {
        this._productAnalyticsService.logEvent(event);
    }

    private _buildTable(tableData: any[]): void {
        if (!this._gridApi) { return; }

        // Important - For whatever reason, ag-grid will maintain the previous search column order if not reset
        this._gridApi.setColumnDefs([]);

        const search = this._advancedSearchPersistenceService.search;
        const columnIds = (tableData && tableData.length) ? Object.keys(tableData[0]) : [];

        // Reorder columnIds to match outputColumn order
        if (search && search.searchCriteria && search.searchCriteria.outputColumns) {
            const outputIds = search.searchCriteria.outputColumns.map(x => x.columnId);
            columnIds.sort((a, b) => {
                return outputIds.indexOf(+a.slice(1)) - outputIds.indexOf(+b.slice(1));
            });
        }

        const multiselectColDef = {
            colId: 'grid-column-multiselect',
            headerName: '',
            field: 'rowId',
            width: AgGridColumns.selectionColumnWidth,
            maxWidth: AgGridColumns.selectionColumnWidth,
            suppressSizeToFit: true,
            suppressAutoSize: true,
            suppressColumnsToolPanel: true,
            editable: false,
            pinned: 'left',
            lockPinned: true,
            lockVisible: true,
            lockPosition: true,
            suppressMovable: true,
            headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
            headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            cellRendererFramework: AgGridMultiSelectedCellRenderer,
            cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams
        } as ColDef;

        const columnProperties = columnIds.reduce((acc, x) => {
            const fieldId = +x.slice(1);
            if (isNaN(fieldId)) { return acc; }

            const field = this._smartSearchService.getFieldById(fieldId);
            if (!field) { return acc; }

            const baseColumn = this._columnBuilder.getColumnDefinition(x, field);

            acc.push(baseColumn);
            return acc;
        }, []);

        const columns = [
            multiselectColDef,
            ...columnProperties
        ];

        const tableDataWithRowIds = tableData.map((x, i) => ({ ...x, rowId: `_${i}` }));

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setRowData(tableDataWithRowIds);
    }

    private _clearTable(): void {
        if (!this._gridApi) { return; }

        this.gridTracker.clear();
        this._gridApi.setRowData([]);
    }

    private _exportToCsv(): void {
        const params = {
            fileName: `SMARTool - ${  moment().format()}`
        };

        this.gridOptions.api.exportDataAsCsv(params);
    }

    private async _exportToExcel(): Promise<void> {
        this.exportToExcel.emit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows: RowNode[] = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                return x.data.rowId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
