<div class="cwrap">
    <ngx-file-drop
        class="ws-file-drop-zone"
        (onFileDrop)="fileDropped($event)"
        dropZoneClassName="drop-zone"
        [accept]="acceptTypes"
        contentClassName="file-drop-content"
    >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <i class="fa fa-paperclip" style="padding-right: 4px;"></i>
            <span>Drag and drop a file here or&nbsp;</span>
            <button class="btn btn-link ws-no-padding" (click)="openFileSelector()">browse files.</button>
            <div class="selected-file-name" *ngIf="filename && !hideFileNames">({{ filename }})</div>
        </ng-template>
    </ngx-file-drop>
</div>
