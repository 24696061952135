import { Collector } from '../../Assessor-Collector/collector.model';
import { Bill } from './bill.model';
import { Refund } from './refund.model';

export class BillCluster {
	annualYear: number;
	annualYearID: number;
	billClusterID: number;
	bills: Bill[];
	collector: Collector;
    collectorID: number;
    taxRateAreaId: number;
    hasAvailableTaxSetup: boolean;
	efAction: string;
	rowVersion: any;
	refundRowVersion: any;
	isAccrualsAvailable: boolean;
	accrualsEnabled: boolean;

	//Derived Values
	showExtraFields: {bill: boolean, payment: boolean};
	refund: Refund;
	icon: string;
	title: string;
	active: boolean;
	taskSummariesPromise: Promise<any>;
	allPaymentsExcludedFromAccrual: boolean;
	disabled: boolean;

	constructor(annualYearID: number, collectorID: number) {
		this.billClusterID = 0;
		this.annualYearID = annualYearID;
		this.collectorID = collectorID;
		this.bills = [new Bill()];
		this.allPaymentsExcludedFromAccrual = false;
	}
}
