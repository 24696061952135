import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { ActionViewService } from '../../../../Task/ActionView/action-view-service.upgrade';
import { UserSaveService } from '../../userSave.service';

export interface ActionViewRenameModalParams {
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;
}

@Component({
    selector: 'actionView-rename',
    templateUrl: './actionViewRename.component.html'
})
export class ActionViewRenameComponent implements IWeissmanModalComponent<ActionViewRenameModalParams, void>, OnInit {
    constructor(private readonly _actionViewService: ActionViewService,
                private readonly _userSaveService: UserSaveService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: ActionViewRenameModalParams;
    result: void;

    modalTitle: string;
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;

    ngOnInit(): void {
        this.actionView = this.params.actionView;
        this.actionViewType = this.params.actionViewType;
        this.modalTitle = `Rename "${this.actionView.actionViewName}" Action View`;
    }

    async save(): Promise<void> {
        await this._actionViewService.patch(this.actionView, this.actionViewType);
        await this._userSaveService.refreshCurrentList();
        this.cancel();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
