import { Component, Input, OnInit } from '@angular/core';
import { HelpContentComponentConfig } from '../../../../UI-Lib/Help-Tooltip';
import {
    DynamicFieldBehaviorHelpComponent,
    DynamicFieldBehaviorHelpComponentParams
} from './Dynamic-Field-Behavior-Help/dynamicFieldBehaviorHelp.component';
import {
    DynamicFieldBehaviorConfigurationComponent,
    DynamicFieldBehaviorConfigurationParams,
    DynamicFieldBehaviorConfigurationFieldInfo
} from './Dynamic-Field-Behavior-Configuration/dynamicFieldBehaviorConfiguration.component';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import {
    DynamicFieldBehaviorHelpFieldInfo,
    DynamicFieldSelectedBehaviorHelpComponent, DynamicFieldSelectedBehaviorHelpComponentParams
} from './Dynamic-Field-Selected-Behavior-Help/dynamicFieldSelectedBehaviorHelp.component';
import { DynamicFieldBehaviorService } from './dynamicFieldBehavior.service';

@Component({
    selector: 'dynamic-field-behavior',
    templateUrl: './dynamicFieldBehavior.component.html',
})
export class DynamicFieldBehaviorComponent implements OnInit {
    constructor(
        private readonly _dynamicFieldBehaviorService: DynamicFieldBehaviorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyIndicatorService: BusyIndicatorService) {
    }

    @Input() importFileId: number;
    @Input() isReadOnly: boolean;
    selectedPropertyCharacteristicOptionName: string;

    availableDynamicFieldBehaviorOptions: Compliance.ImportDynamicFieldOptionModel[] = [];

    dynamicFieldBehaviorTooltip: HelpContentComponentConfig<DynamicFieldBehaviorHelpComponent, DynamicFieldBehaviorHelpComponentParams>;
    dynamicFieldSelectedBehaviorTooltip: HelpContentComponentConfig<DynamicFieldSelectedBehaviorHelpComponent, DynamicFieldSelectedBehaviorHelpComponentParams>;
    showFieldOptionsTooltip: boolean = false;

    ngOnInit(): void {
        this.availableDynamicFieldBehaviorOptions = this._dynamicFieldBehaviorService.getFileDynamicFieldBehaviorOptions();

        if (!this.isReadOnly) {
            this.dynamicFieldBehaviorTooltip = {
                component: DynamicFieldBehaviorHelpComponent,
                componentParams: {
                    options: this.availableDynamicFieldBehaviorOptions,
                }
            };
        }

        if (this.selectedImportDynamicFieldBehavior.optionId === Compliance.ImportDynamicFieldOptionEnum.Specify) {
            this.selectedPropertyCharacteristicOptionName = 'Per Characteristic';
            this.showFieldOptionsTooltip = true;

            const fieldsSettings = this._dynamicFieldBehaviorService.getSelectedDynamicFieldBehaviorOptions();
            const setAllOption = fieldsSettings.find(x => x.key == 0);
            const dynamicFields = this._getDynamicFields();

            this.dynamicFieldSelectedBehaviorTooltip = {
                component: DynamicFieldSelectedBehaviorHelpComponent,
                componentParams: {
                    fields: dynamicFields.map(x => {
                        const fieldSelectedOption = fieldsSettings.find(y => y.key === x.importFieldId);

                        let selectedOption = Compliance.ImportDynamicFieldOptionEnum.Ignore;

                        if (fieldSelectedOption) {
                            selectedOption = fieldSelectedOption.value;
                        } else if (setAllOption) {
                            selectedOption = setAllOption.value;
                        }

                        return {
                            name: x.name,
                            selectedOption: this.availableDynamicFieldBehaviorOptions.find(y => y.optionId === selectedOption).name,
                        } as DynamicFieldBehaviorHelpFieldInfo;
                    })
                }
            }
        } else {
            this.selectedPropertyCharacteristicOptionName = this.selectedImportDynamicFieldBehavior.name;
        }
    }

    get selectedImportDynamicFieldBehavior(): Compliance.ImportDynamicFieldOptionModel{
        return this._dynamicFieldBehaviorService.getSelectedDynamicFieldBehaviorOption();
    }

    get canEditDynamicFieldConfiguration(): boolean {
        return this.selectedImportDynamicFieldBehavior && this.selectedImportDynamicFieldBehavior.optionId === Compliance.ImportDynamicFieldOptionEnum.Specify;
    }

    async editDynamicFieldBehavior(): Promise<void> {
        const params: DynamicFieldBehaviorConfigurationParams = {
            importId: this.importFileId,
            options: this.availableDynamicFieldBehaviorOptions,
            selectedOptions: this._dynamicFieldBehaviorService.getSelectedDynamicFieldBehaviorOptions(),
            dynamicFields: this._getDynamicFields()
        };

        await this._modalService.showAsync(DynamicFieldBehaviorConfigurationComponent, params, 'modal-dd');
    }

    async setDynamicFieldBehavior(optionId: Compliance.ImportDynamicFieldOptionEnum): Promise<void> {
        const busyRef = this._busyIndicatorService.show({message: 'Saving Property Characteristic Behavior Selection'});

        try {
            await this._dynamicFieldBehaviorService.setSelectedDynamicFieldBehaviorOption(
                this.importFileId,
                optionId);
        } finally {
            await busyRef.hide();
        }
    }

    private _getDynamicFields() {
        const mappedFields = this._dynamicFieldBehaviorService.getFieldMappings();
        const allFields = this._dynamicFieldBehaviorService.getFields();

        return mappedFields
            .filter(x => x.importFieldId.includes('-'))
            .map(x => {
                const field = allFields.find(y => y.importFieldId == x.importFieldId);
                return {
                    importFieldId: x.importFileSpecificationFieldId,
                    name: field.displayName
                } as DynamicFieldBehaviorConfigurationFieldInfo;
            });
    }
}
