import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription ,  BehaviorSubject } from 'rxjs';

export interface ICellRendererParamsForFormReportListCheckbox extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: ICellRendererParamsForFormReportListCheckbox) => boolean;
    onValueChanged: (params: ICellRendererParamsForFormReportListCheckbox) => void;
}

@Component({
    selector: 'form-report-list-checkbox',
    template: `
        <input
            type="checkbox"
            (change)="onModelChanged($event.target)"
            [checked]="params.value"
            *ngIf="params.data"
            [disabled]="!(params.canEdit(params) && editMode)">
              `
})
export class FormReportListCheckboxCellRendererComponent implements ICellRendererAngularComp {
    private _editModeSub: Subscription;

    params: ICellRendererParamsForFormReportListCheckbox;
    editMode: boolean = false;

    agInit(params: ICellRendererParamsForFormReportListCheckbox): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChanged(eventTarget: EventTarget): void {
        const value = (eventTarget as HTMLInputElement).checked;
        this.params.value = value
        this.params.data[this.params.colDef.field] = value;
        this.params.onValueChanged(this.params);
    }
}
