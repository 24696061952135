import { Component, Input } from '@angular/core';


@Component({
    selector: 'asset-list-panel',
    templateUrl: './assetListPanel.component.html'
})
export class AssetListPanelComponent {
    @Input() companyId: number;
    @Input() parcelId: number;
    @Input() siteId: number;
    @Input() showComplianceInfo: boolean;
    @Input() company: Core.CompanyModel;

    onLoading: boolean = true;
    hasViewPermission: boolean = true;
    showBody: boolean = false;

    expandOrCollapse(): void {
        this.showBody = !this.showBody;
    }
}
