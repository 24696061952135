import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { ActionViewService } from '../../../../Task/ActionView/action-view-service.upgrade';
import { UserSaveService } from '../../userSave.service';

export interface ActionViewCategoryModalParams {
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;
}

@Component({
    selector: 'action-view-category',
    templateUrl: './navChangeCategory.component.html'
})
export class ActionViewCategoryComponent implements IWeissmanModalComponent<ActionViewCategoryModalParams, void>, OnInit {
    constructor(private readonly _actionViewService: ActionViewService,
                private readonly _userSaveService: UserSaveService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: ActionViewCategoryModalParams;
    result: void;

    modalTitle: string;
    actionView: Core.ActionViewListDTO;
    actionViewType: Core.SearchCategoryType;
    categories: any[] = [];
    selectedCategory: any;
    newCategory: string;
    creatingNewCategory = false;

    async ngOnInit(): Promise<void> {
        this.actionView = this.params.actionView;
        this.actionViewType = this.params.actionViewType;
        this.modalTitle = `Change "${this.actionView.actionViewName}" View's Category`;

        const categories = await this._actionViewService.getCategories();
        if (categories && categories.length) {
            this.categories = categories.filter(x => x.categoryType === this.actionViewType);
            this.selectedCategory = this.categories.find(x => x.categoryId === this.actionView.categoryId) || this.categories[0];
        }
    }

    async save(): Promise<void> {
        //Create new category first, then update view
        if (this.selectedCategory.categoryId === -1) {
            const category = { ...this.selectedCategory };
            delete category.categoryId;

            const result = await this._actionViewService.createCategory(category);

            this.actionView.categoryId = result.categoryId;
            this.selectedCategory.categoryId = result.categoryId;
        }

        await this._actionViewService.changeCategory(this.actionView, this.selectedCategory.categoryId, this.actionViewType);
        await this._userSaveService.refreshCurrentList();

        this._bsModalRef.hide();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    createNewCategory(): void {
        if (!this.newCategory) {
            this.creatingNewCategory = false;
            return;
        }
        if (this.categories.some(x => x.categoryId === -1)) {
            const category = this.categories.find(x => x.categoryId === -1);
            category.categoryName = this.newCategory;
            category.categoryType = this.actionViewType;
        } else {
            this.categories.push({
                categoryName: this.newCategory,
                categoryType: this.actionViewType,
                categoryId: -1
            });
        }

        this.actionView.categoryId = -1;
        this.creatingNewCategory = false;
        this.newCategory = '';
    }

    cancelCreateCategory(): void {
        this.creatingNewCategory = false;
        this.newCategory = '';
    }
}
