(function () {
	'use strict';

	var APP_SOURCE = 'sd.Parcel.Activity.Modal.Controller';

	/**
	 * @ngdoc function
	 * @name weissmanApp.controller:Parcel.Activity.Modal.Controller
	 * @description
	 * # Parcel.Activity.Modal.Controller
	 * Controller of the weissmanApp
	 */
	angular.module('weissmanApp')
		.controller(APP_SOURCE, Controller);

	Controller.$inject = [
		'$uibModalInstance',
		'params',
		'parcelYears'
	];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit($uibModalInstance, params, parcelYears) {
		var vm = this;

		vm.params = params;
		vm.years = parcelYears;

		vm.close = close

		function close() {
			$uibModalInstance.dismiss();
		}
	}
})();
