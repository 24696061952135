import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AllAppVersionInfo, VersionInfo } from './about.models';

@Injectable()
export class AboutService {
    constructor(private http: HttpClient) { }

    async getAllAppVersionInfo() {
        return await lastValueFrom(this.http.get<AllAppVersionInfo>('/api/AppInfo/getAll'));
    }

    async GetUIVersionInfo() {
        return await lastValueFrom(this.http.get<VersionInfo>('/assets/UIVersionInfo.json'));
    }

    async getJSReportVersionInfo() {
        return await lastValueFrom(this.http.get<VersionInfo>('/api/AppInfo/JSReportVersionInfo'));
    }

    async getSampleEmail() {
        const result = await lastValueFrom(this.http.get('/api/AppInfo/SampleEmail', {
            responseType: 'blob',
            observe: 'response'
        }));

        const header = result.headers.get('content-disposition');
        const matchResult = /attachment\;\s*filename\s*\=\s*([^\s].*)$/.exec(header);

        return {
            blob: result.body,
            fileName: matchResult ? matchResult[1] : 'example.eml'
        };
    }
}
