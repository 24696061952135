import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import * as _ from 'lodash';
import { AgGridOptionsBuilder, AgGridColumns } from '../../AgGrid';
import { GridOptions, GridReadyEvent, ColDef, GridApi } from 'ag-grid-community';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer } from '../../AgGrid/MultiSelectTracker';
import { Subscription } from 'rxjs';

@Component({
    selector: 'form-template-edit',
    templateUrl: './formTemplateEdit.component.html',
    styleUrls: ['./formTemplateEdit.component.scss']
})
export class FormTemplateEditComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, boolean> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formService: FormService) { }

    private _currentGridApi: GridApi;
    private _previewGridApi: GridApi;
    private _selectedRowsSub: Subscription;

    gridTracker: AgGridMultiSelectTracker;
    selectedRows: number[] = [];

    params: void;
    result: boolean;
    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    file: File;
    rotateBy: number;
    moveX: number;
    moveY: number;

    currentMetadata: Compliance.FormRevisionTemplateMetadataModel;
    previewMetadata: Compliance.FormRevisionTemplateMetadataModel;

    currentGridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FormRevisionTemplatePageMetadataModel).pageNumber)
            }
        })
        .withContext(this)
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    previewGridOptions: GridOptions = new AgGridOptionsBuilder({})
        .withContext(this)
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    async ngOnInit(): Promise<void> {
        this.busyMessageManager.add('Loading', 'loading');

        try {
            this.currentMetadata = await this._formService.getTemplateMetadata();

            this._setCurrentRowData();
            this._setPreviewRowData();
        } finally {
            this.busyMessageManager.remove('loading');
        }
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onCurrentAgGridReady(event: GridReadyEvent): void {
        this._currentGridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.currentGridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(async () => {
            this.selectedRows = await this.gridTracker.getSelectedRowIds();
            this.onOptionChanged();
        });

        const columns: ColDef[] = [
            {
                field: 'pageNumber',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Page #',
                field: 'pageNumber',
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Page Rotation',
                field: 'pageRotation',
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Field Rotation',
                field: 'fieldRotation',
                width: AgGridColumns.textColumnWidth
            }
        ];

        const defaultSortModel = [
            {
                colId: 'pageNumber',
                sort: 'asc'
            }
        ];

        this._currentGridApi.setColumnDefs(columns);
        this._currentGridApi.setSortModel(defaultSortModel);
        this._setCurrentRowData();
    }

    onPreviewAgGridReady(event: GridReadyEvent): void {
        this._previewGridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Page #',
                field: 'pageNumber',
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Page Rotation',
                field: 'pageRotation',
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Field Rotation',
                field: 'fieldRotation',
                width: AgGridColumns.textColumnWidth
            }
        ];

        const defaultSortModel = [
            {
                colId: 'pageNumber',
                sort: 'asc'
            }
        ];

        this._previewGridApi.setColumnDefs(columns);
        this._previewGridApi.setSortModel(defaultSortModel);
        this._setPreviewRowData();
    }

    onOptionChanged(): void {
        this.previewMetadata = null;
        this._setPreviewRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async preview(): Promise<void> {
        this.busyMessageManager.add('Previewing', 'previewing');

        try {
            const data = {
                pagesIn: this.selectedRows,
                rotateBy: this.rotateBy,
                moveX: this.moveX,
                moveY: this.moveY
            };

            this.previewMetadata = await this._formService.previewTemplateEditMetadata(data);
            await this._formService.previewTemplateEdit(data);

            this._setPreviewRowData();
        } finally {
            this.busyMessageManager.remove('previewing');
        }
    }

    async save(): Promise<void> {
        this.busyMessageManager.add('Saving Template', 'saving');
        try {
            const data: Compliance.FormRevisionTemplateEditModel = {
                pagesIn: this.selectedRows,
                rotateBy: this.rotateBy,
                moveX: this.moveX,
                moveY: this.moveY
            };

            await this._formService.applyTemplateEdit(data);

            this.result = true;

            this._bsModalRef.hide();

        } finally {
            this.busyMessageManager.remove('saving');
        }
    }

    private _setCurrentRowData(): void {
        if (!(this._currentGridApi && this.currentMetadata)) {
            return;
        }

        this._currentGridApi.setRowData(this.currentMetadata.pages);
    }

    private _setPreviewRowData(): void {
        if (!(this._previewGridApi)) {
            return;
        }

        this._previewGridApi.setRowData(this.previewMetadata ? this.previewMetadata.pages : []);
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._currentGridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const page = x.data as Compliance.FormRevisionTemplatePageMetadataModel;
                return page && page.pageNumber;
            })
        };

        return Promise.resolve(queryResultModel);
    }
}
