import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const COLLECTOR_HELP: HelpContent[] = [
    {
        helpContentId: 'collector.scenario-in-use',
        tooltipText: 'Scenario in Use'
    },
    {
        helpContentId: 'collector.payment-in-use',
        tooltipText: 'Payment Option in Use'
    },
    {
        helpContentId: 'collector.add-billing-scenario',
        tooltipText: 'Add Billing Scenario'
    },
    {
        helpContentId: 'collector.bill-number',
        tooltipText: 'Add up to 4 bills to this scenario',
        hasIcon: true
    },
    {
        helpContentId: 'collector.default-payment-option',
        tooltipText: 'Set as default payment option'
    },
    {
        helpContentId: 'collector.ptx-discount',
        tooltipText: 'Set as PTX discount'
    },
]
