import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LongRunningProcessService } from '../longRunningProcess.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { BusyIndicatorService } from '../../../Busy-Indicator/busyIndicator.service';
import { LongRunningProcessSyntheticModalComponent } from '../Long-Running-Process-Synthetic-Modal/longRunningProcessSyntheticModal.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_SYNTHETIC_HELP } from './longRunningProcessSynthetic.component.help';

@Component({
    selector: 'long-running-process-synthetic',
    templateUrl: './longRunningProcessSynthetic.component.html'
})
export class LongRunningProcessSyntheticComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _helpService: HelpService) { }

    @Input() canEdit: boolean;

    get quiescedStatus(): Compliance.LongRunningProcessQuiescedStatusModel {
        return this._longRunningProcessService.quiescedStatus;
    }

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_SYNTHETIC_HELP);
    }

    ngOnDestroy(): void {
    }

    async test(): Promise<void> {
        const result = await this._modalService.showAsync(LongRunningProcessSyntheticModalComponent, null, 'modal-md');

        if (!result) {
            return;
        }

        const busyRef = this._busyService.show({ message: 'Testing service' });

        try {
            await this._longRunningProcessService.test(result);
        } finally {
            busyRef.hide();
        }
    }
}
