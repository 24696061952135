<ws-modal-wrapper headerLabel="Create Tax Year"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!taxYear">
    <div class="form-year-content d-flex flex-column" *ngIf="taxYear">
        <div *ngIf="isTaxYearIndeterminate" class="d-flex align-items-center form-year-new-modal-row">
            <label class="ws-flex-none m-0">
                Tax Year:
            </label>
            <label for="priorTaxYear" class="ws-flex-none m-0 ws-font-weight-normal">
                <input id="priorTaxYear" type="radio" name="taxYear" [value]="priorTaxYear" [(ngModel)]="taxYear" />
                {{ priorTaxYear }}
            </label>
            <label for="nextTaxYear" class="ws-flex-none m-0 ws-font-weight-normal">
                <input id="nextTaxYear" type="radio" name="taxYear" [value]="nextTaxYear" [(ngModel)]="taxYear" />
                {{ nextTaxYear }}
            </label>
        </div>
        <div class="d-flex flex-column form-year-new-modal-row">
            <div class="d-flex flex-column">
                <div *ngIf="isFormTypeCompliance" class="ws-flex-auto">
                    Tax year {{ taxYear }} will be created and the following will be copied to it:
                    <ul>
                        <li>Assessors</li>
                        <li>Schedule factor tables</li>
                        <li>Factor tables</li>
                        <li>Asset class mappings</li>
                    </ul>
                </div>
                <div *ngIf="isFormTypeAppeal" class="ws-flex-auto">
                    Tax year {{ taxYear }} will be created and assessors will be copied to it.
                </div>
            </div>
        </div>
        <div class="d-flex flex-column form-year-new-modal-row">
            <div class="ws-flex-auto">
                Are you sure you wish to continue?
            </div>
        </div>
    </div>
</ws-modal-wrapper>
