import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AgGridModule } from 'ag-grid-angular'
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component'
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { ErrorLogListComponent } from './Error-Log-List/errorLogList.component';
import { ErrorLogService } from './errorLog.service';
import { ErrorLogListGridActionCellRendererComponent } from './Error-Log-List/agGridActionCellRenderer.component';
import { ErrorLogDetailsComponent } from './Error-Log-Details/errorLogDetails.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
    imports: [
        TabsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            ErrorLogListGridActionCellRendererComponent
        ])
    ],
    declarations: [
        ErrorLogListComponent,
        ErrorLogListGridActionCellRendererComponent,
        ErrorLogDetailsComponent
    ],
    providers: [
        ErrorLogService
    ],
    exports: []
})
export class ErrorLogModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('errorLogList', ErrorLogListComponent);
        hybridAdapterUtility.downgradeNg2Component('errorLogDetails', ErrorLogDetailsComponent);
    }
}
