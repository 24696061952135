import { Component, Input, OnInit } from '@angular/core';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { AssessorCollectorService } from '../../assessor.collector.service';
import { NavigationService } from '../../../Layout/navigation.service';

import * as _ from 'lodash';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'collector-protocols',
    templateUrl: './collectorProtocols.component.html'
})
export class CollectorProtocolsComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _navigationService: NavigationService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _fb: UntypedFormBuilder
    ) {}

    @Input() collector: Weissman.Model.Collectors.Collector;
    @Input() canEdit: boolean;

    isEdit: boolean;
    showBody: boolean;
    isLoading: boolean;
    hasEditPermission: boolean;
    hasViewPermission: boolean;
    saving: boolean;

    protocolForm: UntypedFormGroup;

    private readonly WARNING_MESSAGE = 'Editing is in progress. Are you sure you want to leave?';

    get originalTaxBillRequired(): UntypedFormControl {
        return this.protocolForm.get('originalTaxBillRequired') as UntypedFormControl;
    }

    get receiveByDueDate(): UntypedFormControl {
        return this.protocolForm.get('receiveByDueDate') as UntypedFormControl;
    }

    get maxItemsPerCheck(): UntypedFormControl {
        return this.protocolForm.get('maxItemsPerCheck') as UntypedFormControl;
    }

    ngOnInit(): void {
        this.hasEditPermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
        this.hasViewPermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
    }

    expandOrCollapse(): void {
        if(this.isEdit) {
            return;
        }

        this.showBody = !this.showBody;

        if (this.showBody && !this.protocolForm) {
            this.protocolForm = this._fb.group({
                originalTaxBillRequired: [{ value: this.collector?.originalTaxBillRequired, disabled: true }],
                receiveByDueDate: [{ value: this.collector?.receiveByDueDate, disabled: true }],
                maxItemsPerCheck: [this.collector?.maxItemsPerCheck || 0, [Validators.min(0), Validators.max(1000)]]
            });
        }

        this.isEdit = false;
    }

    editMode(): void {
        this.showBody = true;
        this.isEdit = true;
        this.originalTaxBillRequired.enable();
        this.receiveByDueDate.enable();
        this._navigationService.enableNavWarning(this.WARNING_MESSAGE);
    }

    cancelEdit(): void {
        this._navigationService.disableNavWarning();
        this.showBody = true;
        this.isEdit = false;
        this.originalTaxBillRequired.disable();
        this.receiveByDueDate.disable();

        this.protocolForm.reset(this.collector);
    }

    async savePanel(): Promise<void> {
        this.saving = true;

        try {
            const formValues = this.protocolForm.getRawValue();
            formValues.maxItemsPerCheck = formValues.maxItemsPerCheck > 0 ? formValues.maxItemsPerCheck : null;
            const update = {
                ..._.cloneDeep(this.collector),
                ...formValues
            };

            await this._assessorCollectorService.saveCollectorProtocols(update);

            this.collector.originalTaxBillRequired = formValues.originalTaxBillRequired;
            this.collector.receiveByDueDate = formValues.receiveByDueDate;
            this.collector.maxItemsPerCheck = formValues.maxItemsPerCheck;
        } finally {
            this._navigationService.disableNavWarning();
            this.showBody = true;
            this.isEdit = false;
            this.originalTaxBillRequired.disable();
            this.receiveByDueDate.disable();
            this.saving = false;
        }
    }
}
