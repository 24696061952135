(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('RenameReportModalController', RenameReportModalController);

    RenameReportModalController.$inject = ['$uibModalInstance', 'ReportManagerService', '$rootScope', 'report', 'reportType'];

    function RenameReportModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, ReportManagerService, $rootScope, report, reportType) {
        var vm = this;
        vm.report = report;
        vm.reportType = reportType;

        vm.save = function() {
            ReportManagerService.patchReport(vm.report, reportType)
                .then(function() {
                    $rootScope.$broadcast('updateReportMenu', vm.report);
                    $uibModalInstance.dismiss();
                });
        }

        vm.cancel = function() {
            $uibModalInstance.dismiss();
        }

        activate();

        function activate() {}
    }
})();
