import { Injectable } from '@angular/core';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { AddParcelCollectorModalComponent, AddParcelCollectorModalParams, AddParcelCollectorModalResult } from './addParcelCollectorModal.component';

@Injectable()
export class TempParcelCollectorModalService {
    constructor(private _modalService: WeissmanModalService) { }

    async launchModal(modalParams: AddParcelCollectorModalParams): Promise<AddParcelCollectorModalResult> {
        return this._modalService.showAsync(AddParcelCollectorModalComponent, modalParams, 'modal-md');
    }
    
}