import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheManagerService } from '../Common/Routing/cache-manager.service';
import { EntityTypeIds } from '../constants.new';

export interface SendTaskCommentsBody {
    taskIDs: number[];
    comment: string;
    entityYearOverrides: any[]
}

@Injectable()
export class CommentsRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _cacheManagerService: CacheManagerService
    ) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/Comment';

    fetch(filter): Observable<any> {
        return this.httpPost(this.SERVICE_URL + '/fetch', filter);
    }

    createComment(comment): Observable<any> {
        return this.httpPost(this.SERVICE_URL, comment);
    }

    editComment(comment): Observable<any> {
        return this.httpPut(this.SERVICE_URL, comment);
    }

    deleteComment(commentId: number): Observable<any> {
        return this.httpDelete(this.SERVICE_URL + '/' + commentId);
    }

    sendTaskComments(update: SendTaskCommentsBody): Observable<any> {
        return this.httpPost(this.SERVICE_URL + '/tasks', update);
    }

    getEntityDataForTasks(taskIds: number[]): Observable<any> {
        return this.httpPost(this.SERVICE_URL + '/entitydata/tasks/fetch', taskIds);
    }

    getEntityData(entityId: number, entityTypeId: EntityTypeIds): Observable<any> {
        return this.httpGet(this.SERVICE_URL + '/entitydata/entityType/' + entityTypeId + '/entity/' + entityId);
    }

    checkForComments(entityTypeId: EntityTypeIds, entityId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL + '/entitytype/' + entityTypeId + '/entity/' + entityId + '/hascomments');
    }

    getAvailableCommentTypesForEntityType(entityTypeId: EntityTypeIds): Promise<any[]> {
        return this._cacheManagerService.buildCacheGet('/api/comment/typesForEntityType/' + entityTypeId, false);
    }

    pinComment(commentID): Observable<any> {
        return this.httpPut('/api/comment/pin/'+commentID, {});
    }

    unpinComment(commentID): Observable<any> {
        return this.httpPut('/api/comment/unpin/'+commentID, {});
    }

}
