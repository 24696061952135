import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';
import { RentRollRepository } from '../rentRoll.repository';

export interface RentRollDataSourceParams {
    companyId: number;
    endDate?: Date;
    siteId?: number;
}

export class RentRollAgGridDataSource extends AgGridDataSourceBase<RentRollDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _rentRollRepository: RentRollRepository,
        protected _dataSourceParamsFn: () => RentRollDataSourceParams,
        private readonly _totalsUpdateCallback: (totals: Core.RentRollLineItemSearchTotalsModel, selectedTotals?: boolean, isLoading?: boolean) => void
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Core.RentRollLineItemPropertyEnum>[] = [
        {name: `${Core.RentRollLineItemPropertyEnum.CompanyName}`, value: Core.RentRollLineItemPropertyEnum.CompanyName },
		{name: `${Core.RentRollLineItemPropertyEnum.TopLevelCompanyName}`, value: Core.RentRollLineItemPropertyEnum.TopLevelCompanyName },
		{name: `${Core.RentRollLineItemPropertyEnum.SiteName}`, value: Core.RentRollLineItemPropertyEnum.SiteName },
		{name: `${Core.RentRollLineItemPropertyEnum.SiteNumber}`, value: Core.RentRollLineItemPropertyEnum.SiteNumber },
		{name: `${Core.RentRollLineItemPropertyEnum.Bed}`, value: Core.RentRollLineItemPropertyEnum.Bed },
		{name: `${Core.RentRollLineItemPropertyEnum.Unit}`, value: Core.RentRollLineItemPropertyEnum.Unit },
		{name: `${Core.RentRollLineItemPropertyEnum.UnitType}`, value: Core.RentRollLineItemPropertyEnum.UnitType },
		{name: `${Core.RentRollLineItemPropertyEnum.Bath}`, value: Core.RentRollLineItemPropertyEnum.Bath },
        {name: `${Core.RentRollLineItemPropertyEnum.SqFt}`, value: Core.RentRollLineItemPropertyEnum.SqFt },
        {name: `${Core.RentRollLineItemPropertyEnum.Tenant}`, value: Core.RentRollLineItemPropertyEnum.Tenant },
        {name: `${Core.RentRollLineItemPropertyEnum.RentRollLineItemStatusName}`, value: Core.RentRollLineItemPropertyEnum.RentRollLineItemStatusName },
        {name: `${Core.RentRollLineItemPropertyEnum.LeaseBegin}`, value: Core.RentRollLineItemPropertyEnum.LeaseBegin },
        {name: `${Core.RentRollLineItemPropertyEnum.LeaseEnd}`, value: Core.RentRollLineItemPropertyEnum.LeaseEnd },
        {name: `${Core.RentRollLineItemPropertyEnum.MoveIn}`, value: Core.RentRollLineItemPropertyEnum.MoveIn },
        {name: `${Core.RentRollLineItemPropertyEnum.MoveOut}`, value: Core.RentRollLineItemPropertyEnum.MoveOut },
        {name: `${Core.RentRollLineItemPropertyEnum.BaseRate}`, value: Core.RentRollLineItemPropertyEnum.BaseRate },
        {name: `${Core.RentRollLineItemPropertyEnum.PctOfBuilding}`, value: Core.RentRollLineItemPropertyEnum.PctOfBuilding },
        {name: `${Core.RentRollLineItemPropertyEnum.AnnualRent}`, value: Core.RentRollLineItemPropertyEnum.AnnualRent },
        {name: `${Core.RentRollLineItemPropertyEnum.AnnualMarketRent}`, value: Core.RentRollLineItemPropertyEnum.AnnualMarketRent },
        {name: `${Core.RentRollLineItemPropertyEnum.AnnualOther}`, value: Core.RentRollLineItemPropertyEnum.AnnualOther },
        {name: `${Core.RentRollLineItemPropertyEnum.AnnualCAM}`, value: Core.RentRollLineItemPropertyEnum.AnnualCAM },
        {name: `${Core.RentRollLineItemPropertyEnum.MonthlyRent}`, value: Core.RentRollLineItemPropertyEnum.MonthlyRent },
        {name: `${Core.RentRollLineItemPropertyEnum.MonthlyMarketRent}`, value: Core.RentRollLineItemPropertyEnum.MonthlyMarketRent },
        {name: `${Core.RentRollLineItemPropertyEnum.MonthlyOther}`, value: Core.RentRollLineItemPropertyEnum.MonthlyOther },
        {name: `${Core.RentRollLineItemPropertyEnum.MonthlyCAM}`, value: Core.RentRollLineItemPropertyEnum.MonthlyCAM },
        {name: `${Core.RentRollLineItemPropertyEnum.BuildingId}`, value: Core.RentRollLineItemPropertyEnum.BuildingId }

    ];

    private _lastFilterUsedInTotals: string;
    lastFetchedTotals: Core.RentRollLineItemSearchTotalsModel;

    protected canGetRows(): boolean {
        const canGetRows = !!this._dataSourceParams.companyId;
        if (!canGetRows) {
            this.clearTotals();
            this._totalsUpdateCallback(null);
        }
        return canGetRows;
    }

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    getSearchParamsWithoutPagination() : Core.RentRollLineItemSearchModel{
        this.refreshDataSourceParams();
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            selectedRows: null,
            endDate: this._dataSourceParams.endDate,
            siteId: this._dataSourceParams.siteId
        };
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this._gridApi.setPinnedBottomRowData([]);
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals !== searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._rentRollRepository.getRentRollList(this._dataSourceParams.companyId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        const dataSourceResult: AgGridDataSourceResult = {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };

        if (fetchTotals) {
            this._getAllRowTotals(params);
        }

        return dataSourceResult;
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Core.RentRollLineItemSearchTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selectedRows = selectedRowsModel;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const result = await lastValueFrom(this._rentRollRepository.getRentRollList(this._dataSourceParams.companyId, searchParams));

        return result.totals;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.RentRollLineItemSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            selectedRows: null,
            siteId: this._dataSourceParams.siteId,
            endDate: this._dataSourceParams.endDate
        };

        return await lastValueFrom(this._rentRollRepository.getRentRollRowIds(this._dataSourceParams.companyId, searchParams));
    }

    private async _getAllRowTotals(params: IGetRowsParams): Promise<Core.RentRollLineItemSearchTotalsModel> {
        this._totalsUpdateCallback(this.lastFetchedTotals, false, true);
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._rentRollRepository.getRentRollList(this._dataSourceParams.companyId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        return result.totals;
    }
}
