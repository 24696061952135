import { Component, OnInit } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { KeyTransmittalPublicData } from './external-api.models';
import { ExternalAPIService } from './external-api.service';

@Component({
    selector: 'external-api-key',
    templateUrl: './external-api-key.component.html'
})
export class ExternalAPIKeyComponent implements OnInit {
    constructor(private externalAPIService: ExternalAPIService,
                private navigationServiceHandler: UpgradeNavigationServiceHandler) { }

    requestCode: string;
    errorMessage: string;
    loading: boolean;
    keyTransmittalPublicData: KeyTransmittalPublicData;
    privateKey: string;

    ngOnInit() {
        this.privateKey = undefined;
        this.loading = false;
        this.requestCode = this.navigationServiceHandler.getQuerystringParam('requestCode');
        // I have a complaint in the API code about this too; Angular UI-Router dies without error message or any clues if a url
        // parameter is a UUID. I coded the API to send emails with UUIDs in the URL with the dashes removed as a workaround. A
        // better workaround would be to stop using Angular UI-Router, considering the insane amount of trouble it's given us.
        // In the meantime, check that this is a 32-character long string of hex characters and reconstruct the UUID if so.
        const rcMatch = /^([0-9a-fA-F]{8})([0-9a-fA-F]{4})([0-9a-fA-F]{4})([0-9a-fA-F]{4})([0-9a-fA-F]{12})$/.exec(this.requestCode);
        if (rcMatch) {
            this.requestCode = rcMatch.slice(1).join('-');
            this.loading = true;
            try {
                this.externalAPIService.getKeyTransmittalPublicData(this.requestCode).then(r => {
                    this.keyTransmittalPublicData = r;
                }, err => {
                    console.log(err);
                    if (err.status === 404) {
                        this.errorMessage = 'Key request not found; most likely the request either expired after 24 hours or the private key has already been obtained. A private key request can only be used one time; you will need to contact an administrator and ask for your private key again if you do not know it.';
                    }
                });
            } finally {
                this.loading = false;
            }
        }
        else {
            this.requestCode = undefined;
        }
    }

    getPrivateKey() {
        this.loading = true;
        try {
            this.externalAPIService.getPrivateKey(this.requestCode).then(k => {
                this.privateKey = k;
            }, err => {
                    console.log(err);
                    if (err.status === 404) {
                        this.errorMessage = 'Key request not found; most likely the request either expired after 24 hours or the private key has already been obtained. A private key request can only be used one time; you will need to contact an administrator and ask for your private key again if you do not know it.';
                    }
                });
        } finally {
            this.loading = false;
        }
    }

    backToHomepage_Click() {
        this.navigationServiceHandler.go('home', {});
    }
}
