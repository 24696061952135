import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AccountService, UserData } from '../User/account.service';
import { UserInstanceService } from '../User/userInstance.service';
import { RyanInstanceId } from '../constants.new';
import { UserRepository } from '../Core-Repositories';

import * as _ from 'lodash';

@Injectable(
    {providedIn: 'root'}
)
export class UserService {
    constructor(
        private readonly _accountService: AccountService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _userRepository: UserRepository
    ) {}

    private  _loginCallbacks = [];

    isSingleInstanceSelected() {
        return this._userInstanceService.isSingleInstanceSelected();
    }

    isRyanInstanceSelected(): boolean {
        return this._userInstanceService.isRyanInstanceSelected();
    }

    isSSOIntegrationEnabled(): Promise<boolean> {
        return lastValueFrom(this._userRepository.isSSOIntegrationEnabled());
    }

    getSSOIntegrationEndpoint(domain: string): any {
        return lastValueFrom(this._userRepository.getSSOIntegrationEndpoint(domain));
    }

    hasRyanInstanceMembership(): boolean {
        return this.getUser().instanceMembership.filter(i=>i.instanceId === RyanInstanceId).length > 0;
    }

    getUser(): UserData {
        return this._accountService.userData;
    }

    deleteUserSettingByNameAndID(name: string, groupID: number, id: number): void {
        _.remove(this._accountService.userData.settings, (item) => {
            return item.name === name && item.groupId === groupID && item.id === id;
        })
    }

    setCredentials(user): void {
        let callback = this._loginCallbacks.shift();
        while (callback) {
            console.log(['Executing login callback', callback]);
            callback();
            callback = this._loginCallbacks.shift();
        }
    }

    changePassword(current: string, newPassword: string): Promise<any> {
        return lastValueFrom(this._userRepository.changePassword(this._accountService.userData.username, current, newPassword));
    }

    emailPasswordReset(userName: string): Promise<any> {
        return lastValueFrom(this._userRepository.emailPasswordReset(userName));
    }

    updateUser(user: UserData): Promise<any> {
        return lastValueFrom(this._userRepository.updateUser(user));
    }

    setUserDescription(userId: number, description: string): Promise<any> {
        return lastValueFrom(this._userRepository.setUserDescription(userId, description));
    }

    sendPasswordResetToken(usernameOrEmail: string): Promise<any> {
        return lastValueFrom(this._userRepository.sendPasswordResetToken(usernameOrEmail));
    }

    resetPasswordUsingToken(tokenHash: string, newPassword: string): Promise<any> {
        return lastValueFrom(this._userRepository.resetPasswordUsingToken(tokenHash, newPassword));
    }

    homeInstanceId(): number {
        return this.getUser().instanceMembership.find(x => x.isHomeInstance).instanceId;
    }
}
