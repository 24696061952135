<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper [headerLabel]="('Bulk Update ' + selectedCount + ' Records')"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isValid">
    <div style="overflow-y: auto; max-height: calc(750px - 180px); height: calc(750px - 180px)" #bulkUpdateFieldContainer>
        <form>
            <div class="table-responsive">
                <table class="table table-striped table-condensed table-borderless-body">
                    <tbody>
                    <tr>
                        <td>Initial Allocation Amount</td>
                        <td>
                            <allocation-detail-bulk-update-action [fieldHasOverrides]="true"
                                                                  [isInitialAllocationAmount]="true"
                                                                  [(fieldAction)]="bulkUpdateModel.initialAllocationAmountAction"
                                                                  (fieldActionChange)="initialAllocationAmountActionChanged($event)">
                            </allocation-detail-bulk-update-action>
                        </td>
                        <td>
                            <ws-currency-input name="initialAllocationAmount"
                                               [(ngModel)]="bulkUpdateModel.initialAllocationAmount"
                                               [disabled]="isDisabled(bulkUpdateModel.initialAllocationAmountAction)">
                            </ws-currency-input>
                        </td>
                    </tr>
                    <tr>
                        <td>Final Allocation Amount</td>
                        <td>
                            <allocation-detail-bulk-update-action [fieldHasOverrides]="true"
                                                                  [isFinalAllocationAmount]="true"
                                                                  [(fieldAction)]="bulkUpdateModel.finalAllocationAmountAction"
                                                                  (fieldActionChange)="finalAllocationAmountActionChanged($event)">
                            </allocation-detail-bulk-update-action>
                        </td>
                        <td>
                            <ws-currency-input name="finalAllocationAmount"
                                               [(ngModel)]="bulkUpdateModel.finalAllocationAmount"
                                               [disabled]="isDisabled(bulkUpdateModel.finalAllocationAmountAction)">
                            </ws-currency-input>
                        </td>
                    </tr>
                    <tr>
                        <td>Parcel</td>
                        <td>
                            <allocation-detail-bulk-update-action [fieldHasOverrides]="true"
                                                                  [requiredField]="true"
                                                                  [(fieldAction)]="bulkUpdateModel.allocationAction"
                                                                  (fieldActionChange)="parcelActionChanged($event)">
                            </allocation-detail-bulk-update-action>
                        </td>
                        <td>
                            <div>
                                <select name="parcel"
                                        [(ngModel)]="assessmentSummary"
                                        [disabled]="isDisabled(bulkUpdateModel.allocationAction)"
                                        class="form-select form-select-sm">
                                    <option *ngFor="let option of assessmentsSummary" [ngValue]="option">{{ getParcelOptionText(option) }}</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>
                            <allocation-detail-bulk-update-action [(fieldAction)]="bulkUpdateModel.statusAction"
                                                                  (fieldActionChange)="statusActionChanged($event)">
                            </allocation-detail-bulk-update-action>
                        </td>
                        <td>
                            <div>
                                <select name="status"
                                        [(ngModel)]="bulkUpdateModel.status"
                                        [disabled]="isDisabled(bulkUpdateModel.statusAction)"
                                        class="form-select form-select-sm">
                                    <option *ngFor="let status of params.statuses" [ngValue]="status.allocationDetailStatusId">{{ status.name }}</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Reconciled</td>
                        <td>
                            <allocation-detail-bulk-update-action [requiredField]="true"
                                                                  [(fieldAction)]="bulkUpdateModel.isReconciledAction"
                                                                  (fieldActionChange)="isReconciledActionChanged($event)">
                            </allocation-detail-bulk-update-action>
                        </td>
                        <td>
                            <div>
                                <select name="isReconciled"
                                        [(ngModel)]="bulkUpdateModel.isReconciled"
                                        [disabled]="isDisabled(bulkUpdateModel.isReconciledAction)"
                                        class="form-select form-select-sm">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
