<div *ngIf="masterSite">
    <span class="title">Master Site:</span>
    <button class="btn btn-link ws-no-margin ws-no-padding editable" [disabled]="isDisabled" (click)="navigateToSite(masterSite.siteId)" title="View {{ getLinkedSiteName(masterSite) }}">
        {{ getLinkedSiteName(masterSite) }}
    </button>
</div>
<div *ngIf="(!masterSite) && linkedSites && linkedSites.length">
    <span class="title">Linked To:</span>
    <button class="btn btn-link ws-no-margin ws-no-padding editable" [disabled]="isDisabled" (click)="showLinkedSites()" title="View linked sites">
        {{ linkedSites.length|number }} Site{{ linkedSites.length > 1 ? 's' : ''}}
    </button>
</div>
