(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('GridDemoController', GridDemoController);

	GridDemoController.$inject = []

	function GridDemoController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
		var vm = this;
		vm.title = 'GridDemoController';
		vm.selectedData = '';

		var columnDefs = [
			{headerName: "Make", field: "make"},
			{headerName: "Model", field: "model"},
			{headerName: "Price", field: "price"}
		];

		var rowData = [
			{make: "Toyota", model: "Celica", price: 35000},
			{make: "Ford", model: "Mondeo", price: 32000},
			{make: "Porsche", model: "Boxter", price: 72000}
		];

		vm.gridOptions = {
			columnDefs: columnDefs,
			rowData: rowData,
			suppressContextMenu: true,
			suppressCellSelection: true,
			rowSelection: 'multiple'
		};

		vm.updateData = function() {
			vm.gridOptions.rowData[1].price = 99999;
			vm.gridOptions.api.refreshView();
		};

		vm.menuOptions = function() {

		}


	}
})();
