import { Injectable } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ReturnAssetDroppedEvent {
    element: Element;
    draggedAssetIds: number[];
};

@Injectable()
export class ReturnAssetDragAndDropService {
    constructor(private readonly _dragulaService: DragulaService) { }

    private _getAssetIdsFn: () => Promise<number[]>;
    private _getAssetsFn: () => Promise<Compliance.ReturnAssetModel[]>;

    private _activeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _draggingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _draggingOverSubject: BehaviorSubject<Element> = new BehaviorSubject(null);
    private _droppedSubject: Subject<ReturnAssetDroppedEvent> = new Subject();

    private _destroy$: Subject<void> = new Subject();

    readonly BAG_NAME = 'RETURN_ASSET_DRAG_AND_DROP';
    readonly CONTAINER_TYPE_SOURCE = 'RETURN_ASSET_DRAG_AND_DROP_CONTAINER_TYPE_SOURCE';
    readonly CONTAINER_TYPE_TARGET = 'RETURN_ASSET_DRAG_AND_DROP_CONTAINER_TYPE_TARGET';

    active$: Observable<boolean> = this._activeSubject.asObservable();

    /**
     * An observable that notifies the container being dragged.
     */
    dragging$: Observable<boolean> = this._draggingSubject.asObservable();

    /**
     * An observable that notifies the container that the mouse is being dragged over.
     */
    draggingOver$: Observable<Element> = this._draggingOverSubject.asObservable();

    /**
     * An observable that notifies the container an items gets dropped in.
     */
    dropped$: Observable<ReturnAssetDroppedEvent> = this._droppedSubject.asObservable();

    /**
     * Initializes subscriptions to the dragula service and notifies about drag/drop events.
     * Because the drag targets are in a grid with an infinity scroll model, the service does not keep track of all available drag targets.
     * It takes in a function that returns a list of all selected IDs for the drag targets. This list is made available to the drop event.
     */
    start(getAssetIdsFn: () => Promise<number[]>, getAssetsFn: () => Promise<Compliance.ReturnAssetModel[]>): void {
        if (this.isStarted()) {
            console.warn('Return Asset drag-and-drop service is already listening for events.');
            return;
        }

        this._getAssetIdsFn = getAssetIdsFn;
        this._getAssetsFn = getAssetsFn;

        this._dragulaService.createGroup(this.BAG_NAME, this.getDragulaOptions());

        this._dragulaService.drag(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(() => this._draggingSubject.next(true));

        this._dragulaService.dragend(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(() => this._draggingSubject.next(false));

        this._dragulaService.over(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(value => this._draggingOverSubject.next(value.source));

        this._dragulaService.out(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(() => this._draggingOverSubject.next(null));

        // we need to undo the drop event as we don't want the source element to actually move into the target container
        // there is currently no support from the library to do that
        // it is currently being handled via stylesheets by not displaying the dropped elements
        // TODO: cancel drop event or if a parameter gets exposed in the future to not "actually" drop the element use that
        this._dragulaService.drop(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(value => {
            // fetch the asset IDs in order to make them available to the drop event
            this._getAssetIdsFn().then((draggedAssetIds) => {
                const droppedEvent: ReturnAssetDroppedEvent = {
                    element: value.target,
                    draggedAssetIds: draggedAssetIds
                };
                this._droppedSubject.next(droppedEvent);
            }, () => {});
        });

        this._activeSubject.next(true);
    }

    /**
     * Disposes subscriptions to the dragula service.
     */
    stop(): void {
        this._activeSubject.next(false);

        this._destroy$.next();

        this._draggingSubject.next(false);
        this._draggingOverSubject.next(null);

        this._getAssetIdsFn = null;

        const isStarted = this._dragulaService.find(this.BAG_NAME);
        if (isStarted) {
            this._dragulaService.destroy(this.BAG_NAME);
        }
    }

    isStarted(): boolean {
        return !!this._dragulaService.find(this.BAG_NAME);
    }

    /**
     * Gets the assets associated with the dropped IDs
     */
    async getSelectedAssets(): Promise<Compliance.ReturnAssetModel[]> {
        return await this._getAssetsFn();
    }

    getDragulaOptions(): any {
        return {
            copy: true,
            copyItem: (item) => ({ ...item }),
            revertOnSpill: true,
            removeOnSpill: false,
            accepts: (el: Element, target: Element, source: Element, sibling: Element) => target.getAttribute('dragula-container-type') !== this.CONTAINER_TYPE_SOURCE,
            moves: (el: Element, target: Element, source: Element, sibling: Element) => target.getAttribute('dragula-container-type') === this.CONTAINER_TYPE_SOURCE
        };
    }
}
