import { NgModule } from '@angular/core';
import { AssetLienDateComponent } from './assetLienDate.component';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';

@NgModule({
    imports: [
        WeissmanSharedModule
    ],
    declarations: [
        AssetLienDateComponent
    ],
    exports: [
        AssetLienDateComponent
    ]
})
export class AssetLienDateModule {}
