import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class ReportingParcelRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getSiteByParcel(parcelId: number): Observable<Compliance.ReportingParcelSiteModel> {
        return this.httpGet(`/api/reportingparcel/${parcelId}`);
    }

    getSitesByReportingParcel(searchModel: Compliance.ReportingParcelSiteSearchModel): Observable<Compliance.QueryResultModel<Compliance.ReportingParcelSiteModel>> {
        return this.httpPost(`/api/reportingparcel/${searchModel.parcelId}/sites`, searchModel);
    }

    getSitesByAvailable(searchModel: Compliance.ReportingParcelSiteSearchModel): Observable<Compliance.QueryResultModel<Compliance.ReportingParcelSiteModel>> {
        return this.httpPost(`/api/reportingparcel/${searchModel.parcelId}/sites/available`, searchModel);
    }

    getSiteIdsByAvailable(searchModel: Compliance.ReportingParcelSiteSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/reportingparcel/${searchModel.parcelId}/sites/available/rowids`, searchModel);
    }

    getReportingParcelsByAsset(searchParams: any): Observable<Compliance.QueryResultModel<Compliance.ReportingParcelModel>> {
        return this.httpPost(`/api/reportingParcel`, searchParams);
    }

    updateSites(parcelId: number, sites: Compliance.ReportingParcelSiteUpdateModel[]): Observable<void> {
        return this.httpPut(`/api/reportingparcel/${parcelId}/sites`, sites);
    }
    
    getLinkedSite(siteId: number): Observable<Core.LinkedSiteModel> {
        return this.httpGet(`/api/site/${siteId}/linked`);
    }

    getAvailableSites(searchModel: Core.LinkedSiteSearchModel): Observable<Core.LinkedSiteModel[]> {
        return this.httpPost(`/api/site/available/linking`, searchModel);
    }
}
