import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ScriptLoaderService, ScriptLocations } from '../UI-Lib/Utilities/scriptLoader.service';
import { RouteService } from '../Common/Routing/route.service';

export interface IAuthComponentApi extends HTMLElement {
    signOut: () => Promise<void>;
    authenticate: (req: {
        redirectUrl: string,
        email: string,
        forceLogin: boolean
    }) => void;
}

@Injectable({ providedIn: 'root' })
export class AceAuthOidcWrapperService {
    constructor(
        private _scriptLoaderService: ScriptLoaderService,
        private _routeService: RouteService
    ) {
        this.authWrapperEnabled$ = new ReplaySubject<boolean>();
        this.authElement$ = new ReplaySubject<IAuthComponentApi>();
    }

    authWrapperEnabled$: ReplaySubject<boolean>;
    authElement$: ReplaySubject<IAuthComponentApi>;
    autoStartLogin: boolean;

    /* This is a hack. It doesn't seem that Microsoft's MSAL library can deal with hashes in the URL on return from login (although
     * it's worth pointing out I wasn't able to confirm this in documentation or online, it's just what I've observed). The
     * underlying problem is that the library uses the URL hash to store state information and that overrides the Angular route.
     * So remember the initial route the user entered in session storage but only if we detect that it is indeed an Angular route
     * (it would definitely be bad to remember a state string from the hash instead of a route).
     */
    async login() {
        this.autoStartLogin = true;

        this._routeService.autoSaveInitialRoute();

        this.authWrapperEnabled$.next(true);
        await this._scriptLoaderService.loadScript(ScriptLocations.IAMComponent, true);
    }

    async logout(keepCurrentLocation?: boolean) {
        this.autoStartLogin = false;
        this.authWrapperEnabled$.next(true);
        await this._scriptLoaderService.loadScript(ScriptLocations.IAMComponent, true);
        if (!keepCurrentLocation) {
            window.location.hash = '';
        }

        this.authElement$.subscribe(async el => el.signOut());
    }
}
