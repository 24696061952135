import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { StateJurisdictionCommandCenterService } from '../stateJurisdiction.service';
import { BulkUpdateDynamicBase, BulkUpdateOption } from '../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

export interface StateJurisdictionBulkUpdateParams {
    selection: Compliance.SelectedRowsModel;
    selectedCount: number;
    descriptorInfo: Core.DescriptorMetadataModel;
}

@Component({
    selector: 'state-jurisdiction-bulk-update',
    templateUrl: './stateJurisdictionBulkUpdate.component.html'
})
export class StateJurisdictionBulkUpdateComponent extends BulkUpdateDynamicBase<StateJurisdictionBulkUpdateParams, Compliance.AssessorCommandCenterBulkUpdateResultModel> implements OnInit, IWeissmanModalComponent<StateJurisdictionBulkUpdateParams, Compliance.AssessorCommandCenterBulkUpdateResultModel> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService,
        private readonly _stateJurisdictionService: StateJurisdictionCommandCenterService,
        _bsModalRef: BsModalRef,
        _fb: UntypedFormBuilder
    ) { super(_bsModalRef, _fb) }

    @ViewChild('text', { static: true }) textTemplate: ElementRef;
    @ViewChild('number', { static: true }) numberTemplate: ElementRef;
    @ViewChild('currency', { static: true }) currencyTemplate: ElementRef;
    @ViewChild('date', { static: true }) dateTemplate: ElementRef;
    @ViewChild('picklist', { static: true }) picklistTemplate: ElementRef;

    bulkUpdateForm: UntypedFormGroup = this._fb.group({
        defaultAddressCorrespondenceTypeName: [{ value: null, disabled: true }],
        ppReturnAddress: [{ value: null, disabled: true }],
        appealFilingAddress: [{ value: null, disabled: true }],
        generalAddress: [{ value: null, disabled: true }]
    });

    verificationOptions: Compliance.NameValuePair<Compliance.AssessorCommandCenterBulkUpdateActionEnum>[] = [
        { name: 'No Change', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange },
        { name: 'Verify', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.Verify },
        { name: 'QC', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.QC }
    ];

    defaultAddressType: BulkUpdateOption;
    ppAddress: BulkUpdateOption;
    appealFilingAddress: BulkUpdateOption;
    generalAddress: BulkUpdateOption;

    areSplitControlsVisible: boolean = false;

    ngOnInit(): void {
        this.header = `Bulk Update ${this.params.selectedCount} Records`;

        this._templateMap = new Map([
            [Core.DescriptorFieldTypes.Date, this.dateTemplate],
            [Core.DescriptorFieldTypes.Number, this.numberTemplate],
            [Core.DescriptorFieldTypes.Picklist, this.picklistTemplate],
            [Core.DescriptorFieldTypes.YesNo, this.picklistTemplate],
            [Core.DescriptorFieldTypes.Text, this.textTemplate],
            [Core.DescriptorFieldTypes.Currency, this.currencyTemplate]
        ]);

        this.defaultAddressType = {
            name: 'Default Address Type',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
            picklist: [
                { name: 'Appeal Filing', value: Core.CorrespondenceTypes.AppealFiling },
                { name: 'General', value: Core.CorrespondenceTypes.General },
                { name: 'PP Return', value: Core.CorrespondenceTypes.PPReturn }
            ],
            action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
            formControlName: 'defaultAddressCorrespondenceTypeName',
            formControl: this.bulkUpdateForm.get('defaultAddressCorrespondenceTypeName') as UntypedFormControl
        };

        this.ppAddress = {
            name: 'PP Return Address',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
            picklist: [
                { name: 'Use General Address', value: Core.CorrespondenceTypes.General},
                { name: 'Use Appeal Filing Address', value: Core.CorrespondenceTypes.AppealFiling}
            ],
            action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
            formControlName: 'ppReturnAddress',
            formControl: this.bulkUpdateForm.get('ppReturnAddress') as UntypedFormControl
        };

        this.appealFilingAddress = {
            name: 'Appeal Filing Address',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
            picklist: [
                { name: 'Use General Address', value: Core.CorrespondenceTypes.General },
                { name: 'Use PP Return Address', value: Core.CorrespondenceTypes.PPReturn }
            ],
            action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
            formControlName: 'appealFilingAddress',
            formControl: this.bulkUpdateForm.get('appealFilingAddress') as UntypedFormControl
        };

        this.generalAddress = {
            name: 'General Address',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
            picklist: [
                { name: 'Use Appeal Filing Address', value: Core.CorrespondenceTypes.AppealFiling },
                { name: 'Use PP Return Address', value: Core.CorrespondenceTypes.PPReturn }
            ],
            action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
            formControlName: 'generalAddress',
            formControl: this.bulkUpdateForm.get('generalAddress') as UntypedFormControl
        };

        this.updateOptions = [this.defaultAddressType, this.generalAddress, this.appealFilingAddress, this.ppAddress];

        this.params.descriptorInfo.descriptors.forEach(descriptor => {
            const control = new UntypedFormControl(null, [this._validators.get(descriptor.fieldType)(descriptor.validation)]);
            control.disable();
            this.bulkUpdateForm.addControl(`${descriptor.descriptorId}`, control);

            const option = {
                name: descriptor.name,
                descriptorId: descriptor.descriptorId,
                isRequired: true,
                template: this._templateMap.get(descriptor.fieldType),
                picklist: this._getPicklist(descriptor),
                action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                formControlName: `${descriptor.descriptorId}`,
                formControl: control,
                validation: descriptor.validation
            };
            this.updateOptions.push(option);
        });
    }

    async save(force: boolean = false): Promise<void> {
        let confirmMessage: string = '';

        const busyRef = this._busyIndicatorService.show({ message: 'Updating' });

        try {
            const formValues = this.bulkUpdateForm.value;
            const changedDescriptors: Compliance.AssessorCommandCenterDescriptorValueBulkUpdateModel[] = this
                .updateOptions
                .filter(o => o.descriptorId &&
                    !(o.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange))
                .map(o => ({
                    action: o.action,
                    descriptorId: o.descriptorId,
                    value: (o.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove
                        || o.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.QC
                        || o.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Verify)
                        ? null
                        : formValues[o.descriptorId]
                }));

            const changedAddresses: Compliance.AssessorCommandCenterAddressBulkUpdateModel[] = [
                {
                    action: this.ppAddress.action,
                    changeToCorrespondenceType: (formValues.ppReturnAddress) ? formValues.ppReturnAddress : null,
                    correspondenceType: Core.CorrespondenceTypes.PPReturn
                },
                {
                    action: this.appealFilingAddress.action,
                    changeToCorrespondenceType: (formValues.appealFilingAddress) ? formValues.appealFilingAddress : null,
                    correspondenceType: Core.CorrespondenceTypes.AppealFiling
                },
                {
                    action: this.generalAddress.action,
                    changeToCorrespondenceType: (formValues.generalAddress) ? formValues.generalAddress : null,
                    correspondenceType: Core.CorrespondenceTypes.General
                }
            ];

            const updateModel: Compliance.AssessorCommandCenterBulkUpdateModel = {
                descriptorValues: changedDescriptors,
                addresses: changedAddresses,
                defaultAddress: {
                    action: this.defaultAddressType.action,
                    correspondenceType: formValues.defaultAddressCorrespondenceTypeName
                },
                force: force,
                searchModel: this._stateJurisdictionService.currentSearchModel,
                selectedRows: this.params.selection
            };

            this.result = await this._stateJurisdictionService.bulkUpdateList(updateModel);

            this._bsModalRef.hide();
            return Promise.resolve();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        } finally {
            busyRef.hide();
        }

        try {
            await this._messageModalService.confirm(confirmMessage, 'Confirm Update');
        } catch (e3) {
            return Promise.resolve();
        }

        // force
        await this.save(true);

        return Promise.resolve();
    }

    showSplitControls(showControls: boolean): void {
        if ((showControls && this.fieldsModified) || (!showControls && this.verificationModified)) {
            if (!confirm("Switching tabs will clear all fields. Do you want to continue?")) {
                    return;
                }
        }

        this._setTabFormState(showControls);
        this.areSplitControlsVisible = showControls;
        this.fieldsModified = false;
        this.verificationModified = false;
    }

    private _setTabFormState(state: boolean): void {
        this.updateOptions.forEach(o => {
            o.action = Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange;
            if (state) {
                o.formControl.setValue(Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange);
                o.formControl.enable();
            } else {
                o.formControl.setValue(null);
                o.formControl.disable();
            }
        });
    }

    private _getPicklist(descriptor: Core.DescriptorInfoModel): Compliance.NameValuePair<any>[] {
        if (descriptor.fieldType === Core.DescriptorFieldTypes.Picklist) {
            return this.params.descriptorInfo.pickList
                .filter(p => p.groupId === descriptor.picklistGroupId)
                .map(p => ({ name: p.name, value: p.name }));
        }
        if (descriptor.fieldType === Core.DescriptorFieldTypes.YesNo) {
            return [
                { name: 'Yes', value: true },
                { name: 'No', value: false }
            ];
        }
        return null;
    }
}
