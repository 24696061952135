import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TaxFeedSettingRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private _pathPrefix = 'api/taxFeedSetting';

    getByEntity(entityId: number, entityType: Core.EntityTypes): Observable<Core.TaxFeedSettingModel> {
        return this.httpGet(`${this._pathPrefix}/${entityId}/${entityType}`);
    }
}
