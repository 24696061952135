import { IDatasource, IGetRowsParams, GridApi, RowNode } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { GridHelper } from './GridHelper';

export interface AgGridDataSourceResult {
    rows: any[];
    totalRows: number;
    lastModifiedTimestamp: Date;
}

export abstract class AgGridDataSourceBase<T> implements IDatasource {
    protected constructor(public gridApi: GridApi & { appliedFilter?: any }) {}

    protected _dataSourceParams: T;
    protected _dataSourceParamsFn?: () => T;
    private _overlayCount: number = 0;
    private _overlayTimeout: any;
    private _lastModifiedTimestamp: Date;
    private _refreshing: boolean;
    private _requestResult: any;
    private _timeout: ReturnType<typeof setTimeout>;
    private _initialRequestComplete: boolean;
    private _callQueue: IGetRowsParams[] = [];
    private _onFirstDataRendered: Subject<void> = new Subject<void>();

    get totalRows(): number {
        return this.gridApi.isMaxRowFound() ? this.gridApi.getInfiniteRowCount() : 0;
    }

    get lastModifiedTimestamp(): Date {
        return this._lastModifiedTimestamp;
    }
    set lastModifiedTimestamp(timestamp: Date) {
        this._lastModifiedTimestamp = timestamp;
    }

    get isRefreshing(): boolean {
        return this._refreshing;
    }

    // Provide a hook for the first data rendered event (emits once on first data)
    // The built-in agGrid onFirstDataRendered event does not fire for server side row models
    get onFirstDataRendered$(): Observable<void> {
        return this._onFirstDataRendered.asObservable();
    }

    async updateRow(findRow: (node: RowNode) => boolean, getRow: (node: RowNode) => Promise<any>, skipRefresh: boolean = false, flashRow: boolean = false): Promise<void> {
        let refresh = false;

        // get the row if visible
        const shownNodes = this.getShownNodes();
        const shownNode = shownNodes.find(x => findRow(x));

        // if the row is visible
        if (shownNode) {

            // get the updated row
            const updatedRow = await getRow(shownNode);

            // get the grid columns involved in sorting and filtering
            const props = this.getPageInvolvedProperties();

            // if the updated row is changing a value that would impact filtering or sorting, refresh
            if (props.find((x => updatedRow[x] && (updatedRow[x] !== shownNode.data[x])))) {
                refresh = true;
            } else {
                // update the row
                shownNode.data = {...shownNode.data, ...updatedRow};

                this.gridApi.redrawRows({ rowNodes: [shownNode] });

                if (flashRow) {
                    this.gridApi.flashCells({
                        rowNodes: [shownNode]
                    });
                }
            }
        }

        if (refresh && !skipRefresh) {
            this.refresh();
        }
    }

    getShownRows(): any[] {
        const gridStart = this.gridApi.getFirstDisplayedRow();
        const gridEnd = this.gridApi.getLastDisplayedRow();
        const result: any[] = [];
        for (let i = gridStart; i <= gridEnd; i++) {
            result.push(this.gridApi.getDisplayedRowAtIndex(i).data);
        }
        return result;
    }

    getShownNodes(): RowNode[] {
        const gridStart = this.gridApi.getFirstDisplayedRow();
        const gridEnd = this.gridApi.getLastDisplayedRow();
        const result: any[] = [];
        for (let i = gridStart; i <= gridEnd; i++) {
            result.push(this.gridApi.getDisplayedRowAtIndex(i));
        }
        return result;
    }

    getPageInvolvedProperties(): string[] {
        const props = this.gridApi.getSortModel().map(sortColumn => sortColumn.colId);
        const keys = Object.keys(this.gridApi.getFilterModel());
        return props.concat(keys);
    }

    // ag grid get rows implementation
    async getRows(params: IGetRowsParams): Promise<void> {
        this.refreshDataSourceParams();
        this._refreshing = true;

        if (!this.canGetRows()) {
            params.successCallback([], 0);
            this._showNoRowsOverlay();
            this._refreshing = false;
            return Promise.resolve();
        }

        // Only debounce if not paging down through the list
        if (params.startRow === 0) {
            return await this._getRowsFn(params);
        }
        return await this._getRows(params);
    }

    destroy?(): void { }

    refresh(): void {
        this._timeout = null;
        this.totalRows === 0 ? this.gridApi.refreshInfiniteCache() : this.gridApi.purgeInfiniteCache();
        this.gridApi.setFilterModel(this.gridApi.getFilterModel());
    }

    getLoadingMessage(updateCallback: (msg: string) => void): NodeJS.Timeout {
        let n = 0;
        return setInterval(() => {
            const msg = `LOADING${'.'.repeat(n)}`;
            n += (n === 4) ? -4 : 1;
            updateCallback(msg);
        }, 500);
    }

    protected abstract getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult>;

    protected abstract canGetRows(): boolean;

    protected refreshDataSourceParams(): void {
        if (this._dataSourceParamsFn) {
            this._dataSourceParams = this._dataSourceParamsFn();
        }
    }

    protected getSortColumns<TU>(propertyMap: Compliance.NameValuePair<TU>[]): Core.SortModel<TU>[] {
        const sortModel = this.gridApi.getSortModel();
        return GridHelper.getSortModel(sortModel, propertyMap);
    }

    protected getColumnFilters<TU>(propertyMap: Compliance.NameValuePair<TU>[]): Core.FilterModel<TU>[] {
        const filterModel = this.gridApi.appliedFilter === undefined ? this.gridApi.getFilterModel() : this.gridApi.appliedFilter;
        return GridHelper.getFilterColumns(filterModel, propertyMap);
    }


    protected getFilterType(agFilterType: string): Core.FilterTypeEnum {
        return GridHelper.getFilterType(agFilterType);
    }

    protected getSortDirection(sort: string): Core.SortDirectionEnum {
        return GridHelper.getSortDirection(sort);
    }

    protected getFilterValue(agFilter): string {
        return GridHelper.getFilterValue(agFilter);
    }

    protected getProperty<TU>(prop: string, propertyMap: Compliance.NameValuePair<TU>[]): TU {
        return GridHelper.getProperty<TU>(prop, propertyMap);
    }

    // Custom debounce function
    // Prevents agGrid making multiple data requests in succession
    private readonly _getRowsFn: (params: IGetRowsParams) => Promise<void> = async (params: IGetRowsParams): Promise<void> => {
        const later = (final: boolean = true) => {
            if (!this._initialRequestComplete) {
                clearTimeout(this._timeout);
                this._timeout = setTimeout(later, 1000);
                return;
            }
            if (this._callQueue.length) {
                const rows = this._requestResult ? this._requestResult.rows : [];
                const totalRows = this._requestResult ? this._requestResult.totalRows : 0;
                this._callQueue.forEach((p) => p.successCallback(rows, totalRows));
                this._callQueue = [];
                this._refreshing = false;
            }
            if (final) {
                clearTimeout(this._timeout);
                this._timeout = null;
                this._requestResult = null;
                this._initialRequestComplete = false;
            }
        };
        const callNow = this._timeout === null || this._timeout === undefined;
        clearTimeout(this._timeout);
        this._timeout = setTimeout(later, 1000);
        if (callNow) {
            try {
                await this._getRows(params);
            } finally {
                // This needs to happen even if the request fails, otherwise the page will be stuck in a disabled state
                this._initialRequestComplete = true;
                later(false);
            }
        } else {
            this._callQueue.push(params);
        }
    };

    private async _getRows(params: IGetRowsParams): Promise<void> {
        this._showLoadingOverlay();

        let result: AgGridDataSourceResult = null;
        try {
            result = await this.getRowsInternal(params);
            this._requestResult = result;

            this._lastModifiedTimestamp = result.lastModifiedTimestamp;
            params.successCallback(result.rows, result.totalRows);
        } catch (e) {
            // If we have no result we need to reset the values it is currently displaying
            result = {
                rows: [],
                totalRows: 0,
                lastModifiedTimestamp: new Date()
            };
            this._requestResult = result;
            this._lastModifiedTimestamp = result.lastModifiedTimestamp;
            // It seems hacky to call success when it failed but failCallback doesn't clear the data
            params.successCallback(result.rows, result.totalRows);
            params.failCallback();
            throw e;
        } finally {
            this._hideLoadingOverlay();
            // this.totalRows is not yet set so we need to check the result
            if (result && result.totalRows === 0) {
                this._showNoRowsOverlay();
            }
            // Fire and complete the onFirstDataRendered observable if there are rows
            if (!this._onFirstDataRendered.closed && result?.totalRows > 0) {
                this._onFirstDataRendered.next();
                this._onFirstDataRendered.complete();
                this._onFirstDataRendered.unsubscribe();
            }
            this._refreshing = false;
        }
    }

    private _hideLoadingOverlay(): void {
        this._overlayTimeout = setTimeout(() => {
            this._overlayCount > 0 && this._overlayCount--;
            this._overlayCount === 0 && this.gridApi.hideOverlay();
        }, 250);
    }

    private _showLoadingOverlay(): void {
        this._overlayCount > -1 && this._overlayCount++;
        this._overlayCount === 1 && this.gridApi.showLoadingOverlay();
        this._clearOverlayTimeout();
    }

    private _showNoRowsOverlay(): void {
        this._clearOverlayTimeout();
        this.gridApi.showNoRowsOverlay();
    }

    private _clearOverlayTimeout(): void {
        // if there was a timeout, then a hide operation was called
        // clear the timeout and sync up the overlay count (as it will now not be synced by the hide operation running at the end of the timeout)
        if (this._overlayTimeout) {
            clearTimeout(this._overlayTimeout);
            this._overlayCount > 0 && this._overlayCount--;
        }
        this._overlayTimeout = null;
    }
}
