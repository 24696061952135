<div class="ws-flex-container-vertical ws-flex-auto h-100 mh-100" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Appeal Recommendation Command Center
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="flat-button small-button secondary-button"
                            (click)="refresh()"
                            [disabled]="refreshing">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center mb-3" >
        <label class="m-0 small me-2"
               helpTooltip
               helpContentId="appeal-recommendation-command-center.state"
               position="bottom">State</label>
        <div class="view-control" style="width: 200px;">
            <state-dropdown [(ngModel)]="selectedStates"
                            (stateAbbrsChange)="emitStateAnalyticsEvent($event)"
                            [includePlaceholder]="true"
                            [multiple]="true"
                            [canSearch]="true"
                            [hasDeselectAll]="true"
                            [filterByCountryId]="1">
            </state-dropdown>
        </div>
        <appeal-recommendation-command-center-assigned (optionChange)="onAssignedFilterChange($event)"></appeal-recommendation-command-center-assigned>
        <label class="m-0 small ms-4 me-2"
               helpTooltip
               helpContentId="appeal-recommendation-command-center.deadline"
               position="bottom">Appeal Deadline Between</label>
        <weissman-datetime [(inputDate)]="appealDeadlineStart"
                           (inputDateChange)="emitDateAnalyticsEvent($event, 'enter-begin-date')"
                           [dateOnly]="true"
                           name="appealDeadlineStart"
                           [required]="true"
                           id="appealDeadlineStart">
        </weissman-datetime>
        <label class="m-0 small ms-2 me-2">and</label>
        <weissman-datetime [(inputDate)]="appealDeadlineEnd"
                           (inputDateChange)="emitDateAnalyticsEvent($event, 'enter-end-date')"
                           [dateOnly]="true"
                           name="appealDeadlineEnd"
                           [required]="true"
                           id="appealDeadlineEnd">
        </weissman-datetime>
        <button type="button"
                class="flat-button small-button primary-button ms-5"
                (click)="refresh()"
                position="bottom"
                [disabled]="isRefreshDisabled">
            Apply
        </button>
    </div>
    <ws-ag-grid-angular
        [gridId]="gridId"
        [gridOptions]="gridOptions"
        [exportOptions]="exportOptions"
        (filterChanged)="onAgGridFilterChanged($event)"
        (sortChanged)="onAgGridSortChanged()"
        (gridReady)="onAgGridReady($event)"
        [additionalButtons]="additionalButtons"
        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
        (bulkUpdate)="bulkUpdate()"
        (layoutChanged)="layoutChanged()">
    </ws-ag-grid-angular>
</div>
