<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Task Exception"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!(selectedOption && selectedUserTeam)">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-flex-container-vertical ws-flex-auto" *ngIf="options.length">
            <label>Action:</label>
            <div *ngFor="let option of options" class="ws-flex-container-vertical">
                <div class="exception-option ws-flex-auto ws-vertical-align-middle">
                    <label [for]="'option' + option.action + option.taskId" class="ws-font-weight-normal" helpTooltip [tooltipText]="option.title" position="bottom">
                        <input [id]="'option' + option.action + option.taskId" type="radio" name="exceptionOptions" [value]="option" [(ngModel)]="selectedOption" (ngModelChange)="selectedOptionChanged($event)" [disabled]="!option.isAuthorized" />
                        {{ option.title }}
                    </label>
                </div>
                <div class="exception-user-team ws-flex-auto">
                    <div class="input-group" *ngIf="option === selectedOption">
                        <input name="userTeam"
                               type="text"
                               placeholder="Assign to..."
                               class="form-control"
                               autocomplete="off"
                               [(ngModel)]="userTeamFilter"
                               container="return-task-exception-modal"
                               [typeahead]="userTeams$"
                               [typeaheadWaitMs]="750"
                               typeaheadOptionField="displayName"
                               [typeaheadOptionsLimit]="50"
                               [typeaheadScrollable]="true"
                               [typeaheadMinLength]="1"
                               (blur)="onUserTeamBlur()"
                               (typeaheadOnSelect)="onUserTeamSelected($event)"
                               (typeaheadLoading)="onUserTeamsLoadingChange($event)" />
                        <span class="input-group-text" [style.visibility]="userTeamsLoading ? 'visible' : 'hidden'">
                            <i class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                </div>
            </div>
            <label>Comment:</label>
            <textarea ref-textarea [(ngModel)]="comment" rows="4"></textarea>
        </div>

        <div class="ws-flex-container-vertical ws-flex-auto" *ngIf="!options.length">
            No options available
        </div>
    </div>
</ws-modal-wrapper>
