import { ColDef } from 'ag-grid-community';
import { AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectHeaderRendererParams } from 'src/app/Compliance/AgGrid/MultiSelectTracker';
import { PaymentBatchAddPaymentsModalComponent } from './addPaymentsToBatchModal.component';
import { AgGridColumns, AgGridFilterParams } from 'src/app/Compliance/AgGrid';
import { WeissmanDateFormat } from 'src/app/UI-Lib/Pipes/Date-Format/date-formatting.pipe';

export const PAYMENT_BATCH_ADD_PAYMENT_COLUMNS = (classRef: PaymentBatchAddPaymentsModalComponent): ColDef[] => [
    {
        colId: 'grid-column-multiselect',
        headerName: '',
        field: 'paymentId',
        width: AgGridColumns.selectionColumnWidth,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        editable: false,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        suppressMovable: true,
        headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
        headerComponentParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
        cellRendererFramework: AgGridMultiSelectedCellRenderer,
        cellRendererParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
        pinnedRowCellRenderer: () => {return '';}
    } as ColDef,
    {
        headerName: 'Payment Status',
        field: 'paymentStatus',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'Parcel Acct Num',
        field: 'parcelAcctNum',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'Site',
        field: 'siteName',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'State',
        field: 'state',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.stateAbbreviationColumnWidth
    },
    {
        headerName: 'Collector',
        field: 'collector',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'Due Date',
        field: 'dueDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchAddPaymentModel;
            if (!(model && model.dueDate)) {
                return '';
            }

            return WeissmanDateFormat(model.dueDate, true, 'stored');
        },
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Installment',
        field: 'installmentName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Payment Amount',
        field: 'paymentAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        valueFormatter: x => classRef.decimalPipe.transform(x.value, '1.2-2'),
        cellClass: 'text-align-right'
    },
    {
        headerName: 'Output Type',
        field: 'transmittalOutput',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'Payment Type',
        field: 'paymentType',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth
    },
    {
        headerName: 'Assessor',
        field: 'assessorName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Site Pay Res',
        field: 'sitePayRes',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Site Payment Resp',
        field: 'sitePaymentResp',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Parcel Pay Res',
        field: 'parcelPayRes',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Parcel Payment Resp',
        field: 'parcelPaymentResp',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
];
