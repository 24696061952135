import { Observable, Subject } from 'rxjs';
import { AmplitudeEvent } from '../../Common/Amplitude/productAnalytics.service';
import { SearchCategoryType } from './userSave.service';

export interface SaveListItem<T> {
    id: number;
    name: string;
    isFavorite?: boolean;
    canFavorite?: boolean;
    variantName?: string;
    changeDate?: Date;

    originalModel: T;
    amplitudeEvent?: AmplitudeEvent;

    // isSystemSeeded?: boolean;
    // sequence?: number;
    // categoryId?: number;
    // defaultDisplay?: number;
    // instanceID?: number;
    // isDisabled?: boolean;
    // isRyanInternal?: boolean;
    // isSystemSearch?: boolean;
    // reportTypeId?: number;
    // rowVersion?: number[];
    // thatAreChoice?: number;
}

export interface SaveCategory<T> {
    categoryId: number;
    categoryName: string;
    categoryType: number;
    savedItemList: SaveListItem<T>[];
}

export enum SaveTabColumnType {
    String,
    Date
}

export interface SaveTabColumn {
    name: string;
    key: string;
    type: SaveTabColumnType
}

export interface ContextMenuItem<T> {
    name: string;
    onClick: (item: SaveListItem<T>, searchType: Core.SearchCategoryType) => void;
    isDisabled: (item: SaveListItem<T>) => boolean;
}

export abstract class UserSavePage<T> {
    public user: SaveCategory<T>[] = [];
    public system: SaveCategory<T>[] = [];
    public favorite: SaveCategory<T>[] = [];

    protected _columns: SaveTabColumn[];
    protected _currentSelectedFilter: SearchCategoryType = SearchCategoryType.System;
    protected _dirty: boolean = true;

    private _currentSelectedCategory: SaveCategory<T>;

    get columns(): SaveTabColumn[] {
        return this._columns;
    }

    get categories(): SaveCategory<T>[] {
        return this[this._currentSelectedFilter];
    }

    get currentSelectedFilter(): SearchCategoryType {
        return this._currentSelectedFilter || SearchCategoryType.System;
    }

    set currentSelectedFilter(filter: SearchCategoryType) {
        this._currentSelectedFilter = filter;
    }

    get currentSelectedCategory(): SaveCategory<T> {
        return this._currentSelectedCategory;
    }

    set currentSelectedCategory(category: SaveCategory<T>) {
        this._currentSelectedCategory = category;
    }

    set dirty(isDirty: boolean) {
        this._dirty = isDirty;
    }

    abstract get tabTitle(): string;
    abstract getList(): Promise<void>;
    abstract navigateTo(listItem: SaveListItem<T>, categoryType: number): void;
    abstract contextMenu(category: SaveCategory<T>, index: number): ContextMenuItem<T>[];
    abstract toggleFavorite(search: SaveListItem<T>): Promise<void>;

    refreshLocalList(): void {
        this.user = structuredClone(this.user);
        this.system = structuredClone(this.system);
        this.favorite = structuredClone(this.favorite);
    }

    protected _separateByCategoryType(categoryList: SaveCategory<T>[]): void {
        const userCategories = categoryList
            .filter(x => {
                return x.categoryId !== null && x.categoryType === Core.SearchCategoryType.Custom;
            })
            .sort((a, b) => a.categoryName.localeCompare(b.categoryName));

        const uncategorizedUserSearches = categoryList
            .filter(x => {
                return x.categoryId === null && x.categoryType === Core.SearchCategoryType.Custom;
            });

        const systemCategories = categoryList
            .filter(x => {
                return x.categoryId !== null && x.categoryType === Core.SearchCategoryType.System;
            })
            .sort((a, b) => a.categoryName.localeCompare(b.categoryName));

        const uncategorizedSystemSearches = categoryList
            .filter(x => {
                return x.categoryId === null && x.categoryType === Core.SearchCategoryType.System;
            });

        const favoriteSearches = categoryList
            .reduce((acc, x) => {
                const favoriteList = x.savedItemList.filter(y => y.isFavorite === true);
                if (favoriteList.length > 0) {
                    acc.push({ ...x, savedItemList: favoriteList });
                }
                return acc;
            }, []);

        this.user = [ ...userCategories, ...uncategorizedUserSearches ];
        this.system = [...systemCategories, ...uncategorizedSystemSearches];
        this.favorite = this._formatFavorites(favoriteSearches);
        this._dirty = false;
    }

    protected _showEmailWindow(route: string, itemName: string, itemType: string): void {
        const subject = `Shared ${  itemType  }`;

        const url = route;
        // // Get rid of leading #
        // if (url[0] === '#') {
        //     url = url.substring(1);
        // }
        //
        // url = this._location.url(url).absUrl();

        const body = encodeURIComponent(
            'Hello.\r\n\r\n' +
            `Click on the link below to access the ${  itemType  } "${  itemName  }" which has been shared with you in PropertyPoint.\r\n\r\n` +
            `You may run it directly or, after opening it, save it as one of your own ${  itemType  }s by clicking Save As.\r\n\r\n${
                url  }\r\n\r\n`
        );

        window.open('mailto:' + '' + `?subject=${  subject  }&body=${  body  }`, '_self');
    }

    private _formatFavorites(searches: SaveCategory<T>[]): SaveCategory<T>[] {
        return [
            {
                categoryId: 0,
                categoryName: 'System Favorites',
                categoryType: Core.SearchCategoryType.System,
                savedItemList: searches.reduce((acc, x) => {
                    if (x.categoryType === Core.SearchCategoryType.System) {
                        return [ ...acc, ...x.savedItemList ];
                    }
                    return acc;
                }, [])
            },
            {
                categoryId: 1,
                categoryName: 'User Favorites',
                categoryType: Core.SearchCategoryType.Custom,
                savedItemList: searches.reduce((acc, x) => {
                    if (x.categoryType === Core.SearchCategoryType.Custom) {
                        return [ ...acc, ...x.savedItemList ];
                    }
                    return acc;
                }, [])
            }
        ];
    }
}
