<div class="has-feedback">
    <input [(ngModel)]="companyName"
        [typeahead]="dataSource"
        placeholder="Choose Company"
        (typeaheadLoading)="companiesLoading = $event"
        class="form-control"
        typeaheadOptionField="companyName"
        [typeaheadOptionsLimit]="1000"
        (typeaheadOnSelect)="typeaheadOnSelect($event)"
        [typeaheadMinLength]="2"
        [typeaheadWaitMs]="500"
        (ngModelChange)="inputChanged()" />
    <i class="fa fa-spinner fa-pulse form-control-feedback" *ngIf="companiesLoading"></i>
</div>
