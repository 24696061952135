import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { lastValueFrom, sequenceEqual, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import {
    AssetClassificationRepository,
    AssetRepository,
    CostAdjustmentRepository,
    ReportingParcelRepository
} from '../../../Repositories';
import { AssetInfoService } from './assetInfo.service';
import { AssetInfoCostEditorComponent, AssetInfoCostEditorParams } from './Cost-Editor/assetInfoCostEditor.component';
import { AssetSummary } from '../../Models/assetSummary';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { WeissmanKeyValueDisplayPipe } from '../../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { WeissmanDateFormatPipe } from '../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import {
    MessageModalButtons,
    MessageModalDisplayMode,
    MessageModalOptions,
    MessageModalResult,
    MessageModalService
} from '../../../../UI-Lib/Message-Box/messageModal.service';
import { CompanyService } from '../../../../Entity/Company/company.service';
import { CostAdjustmentHelpComponent } from '../costAdjustmentHelp.component';
import { HelpContent, HelpService } from '../../../../UI-Lib/Help-Tooltip';
import {
    ASSET_FIELDS,
    AssetInfoField,
    AssetInventoryField,
    AssetLeasedInfoField,
    AssetTemplate,
    CustomTemplateTypes,
    INVENTORY_FIELDS,
    LEASED_TO_FIELDS,
    LEASED_TO_FROM_FIELDS
} from './assetInfo.columns';

import * as _ from 'lodash';

import { ChangeHistoryModalLaunchService } from '../../../../Common/Change-History/change-history-modal-launch.service';
import { ExtendedGLAccountModel } from './extendedGLAccountModel';
import { ExtendedSiteModel } from './extendedSiteModel';
import { StateSummary } from '../../../../Common/States/state.model';
import { AssetDescriptors } from '../assetDetails.component';
import { DecimalPipe } from '@angular/common';
import { InstanceRights, RestrictService } from '../../../../Common/Permissions/restrict.service';
import { TimerService } from '../../../../UI-Lib/Utilities/timer.service';
import DescriptorFieldTypes = Core.DescriptorFieldTypes;

export interface ExtendedCostAdjustmentTypeModel extends Compliance.CostAdjustmentTypeModel {
    applyToSource: boolean
}

export interface FlattenedCostAdjustmentSource {
    typeName: string;
    costAdjustmentTypeId: number;
    applyToSource: boolean;
    sequence: number;
    states: Compliance.StateCostAdjustmentTypeModel[];
}

enum AssetInfoFieldsTab {
    Asset,
    Leasing,
    Inventory
}

@Component({
    selector: 'asset-info',
    templateUrl: './assetInfo.component.html',
    styleUrls: ['./assetInfo.component.scss']
})
export class AssetInfoComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _assetRepository: AssetRepository,
        private readonly _assetInfoService: AssetInfoService,
        private readonly _costAdjustmentRepository: CostAdjustmentRepository,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _messageModalService: MessageModalService,
        private readonly _assetClassificationRepository: AssetClassificationRepository,
        private readonly _companyService: CompanyService,
        private readonly _modalService: WeissmanModalService,
        private readonly _changeHistoryModalLaunchService: ChangeHistoryModalLaunchService,
        private readonly _elementRef: ElementRef,
        private readonly _fb: UntypedFormBuilder,
        private readonly _helpService: HelpService,
        private readonly _reportingParcelRepository: ReportingParcelRepository,
        private readonly _restrictService: RestrictService,
        private readonly _timer: TimerService
    ) { }

    @Input() assets: AssetSummary[];
    @Input() reportingAssetId: number;
    @Input() lienDate: Date;
    @Input() createNewAsset: boolean;
    @Input() newAssetCompanyId: number;
    @Input() topLevelCompanyId: number;
    @Input() assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];

    @Input() states: StateSummary[];

    @Input()
    set assetDetailModel(value: Compliance.AssetDetailModel) {
        if (value) {
            this._assetDetailModel = value;
            this._populateAltToggleFields();
        }
    };

    @Input()
    set currentAsset(currentAsset: AssetSummary) {
        if (currentAsset) {
            this._currentAsset = currentAsset;
            this._primaryAssetIsLocked = currentAsset.isLocked;
            this._configureCurrentAsset();
            this._reloadArrows();
        }
    };

    @Input()
    set isEditMode(editMode: boolean) {
        if (editMode) {
            this.enterEditState();
        } else {
            this.exitEditState();
        }
    };

    @Output() currentAssetChanged: EventEmitter<AssetSummary> = new EventEmitter();
    @Output() selectedDescriptorsChanged: EventEmitter<AssetDescriptors> = new EventEmitter();
    @Output() assetInfoReady: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('assetForm', { static: true }) assetForm;

    @ViewChild('text', { static: true }) textTemplate: ElementRef;
    @ViewChild('number', { static: true }) numberTemplate: ElementRef;
    @ViewChild('currency', { static: true }) currencyTemplate: ElementRef;
    @ViewChild('date', { static: true }) dateTemplate: ElementRef;
    @ViewChild('picklist', { static: true }) picklistTemplate: ElementRef;
    @ViewChild('yesNo', { static: true }) yesNoTemplate: ElementRef;

    @ViewChild('acqDate', { static: true }) acqDateTemplate: ElementRef;
    @ViewChild('assetClassification', { static: true }) assetClassTemplate: ElementRef;
    @ViewChild('companiesSelector', { static: true }) companiesSelector: ElementRef;
    @ViewChild('costAdjustments', { static: true }) costAdjustmentTemplate: ElementRef;
    @ViewChild('glAccount', { static: true }) glAccountTemplate: ElementRef;
    @ViewChild('parcelSelector', { static: true }) parcelSelectorTemplate: ElementRef;
    @ViewChild('sites', { static: true }) sitesTemplate: ElementRef;

    assetListForm: UntypedFormGroup;
    reportingAssetFormElement: any;
    arrowHelperElement: any;
    arrowBounds = {
        acqDateAssetDescriptors: {
            tooltip: 'asset-info.acquisition-date-selector-arrow',
            top: 0,
            left: 0,
            height: '0px',
            width: '0px',
            dPath: '',
            arrowPoints: ''
        },
        costAssetDescriptors: {
            tooltip: 'asset-info.cost-selector-arrow',
            top: 0,
            left: 0,
            height: '0px',
            width: '0px',
            dPath: '',
            arrowPoints: ''
        }
    }

    assetListFields: AssetInfoField[] = ASSET_FIELDS(this);
    leasedToFields: AssetLeasedInfoField[] = LEASED_TO_FIELDS;
    leasedToFromFields: AssetLeasedInfoField[] = LEASED_TO_FROM_FIELDS;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    editMode: boolean = false;

    costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel } = {};
    extendedCostAdjustmentTypes: ExtendedCostAdjustmentTypeModel[] = [];
    costAdjustmentControlsVisible: boolean = false;
    costFieldValue: string;
    costAssetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    costAdjustmentTypes: Compliance.CostAdjustmentTypeModel[];
    glAccountFilter: string = '';
    assetIndex: number = null;
    childCompanies: { id: number; name: string }[] = [];
    selectedCompanyId: number = null;
    topLevelCompanyName: string = '';
    ownershipTypes: Compliance.NameValuePair<number>[] = [];
    leaseTypes: Compliance.LeaseTypeModel[];
    costAdjustmentHelpDescription: string = '';
    hasOldAssetNumber: boolean = false;
    altAcqDateId: number = 0;
    altCostId: number = 0;
    canSeeHistory: boolean = false;

    customRenderer: HelpContent;

    AssetInfoFieldsTab = AssetInfoFieldsTab;

    private _currentAsset: AssetSummary;
    private _assetClassifications: Compliance.AssetClassificationModel[] = [];
    private _assetDetailModel: Compliance.AssetDetailModel;
    private _inventoryFields: AssetInventoryField[] = INVENTORY_FIELDS;

    private _templateMap: Map<Core.DescriptorFieldTypes, ElementRef>;
    private _customTemplateMap: Map<CustomTemplateTypes, ElementRef>;
    private _selectedFieldsTab: AssetInfoFieldsTab = AssetInfoFieldsTab.Asset;

    private _destroy$: Subject<void> = new Subject();

    private _primaryAssetIsLocked: boolean = false;

    get currentAsset(): AssetSummary {
        return this._currentAsset;
    }

    get costFieldHasSource() {
        return this._assetInfoService.hasSourceValue;
    }

    get assetDetailModel(): Compliance.AssetDetailModel {
        return this._assetDetailModel;
    }

    get assetCostsMatchCalculatedTotals(): boolean {
        return this._assetInfoService.assetValueMatched;
    }

    get allocatedTotal(): number {
        return this._assetInfoService.allocatedTotal;
    }

    get classRef(): AssetInfoComponent {
        return this;
    }

    async ngOnInit(): Promise<void> {
        // Set custom help tooltip
        this.customRenderer = {
            helpContentId: 'asset-info.cost-row-label',
            hasIcon: true,
            helpContentComponent: {
                component: CostAdjustmentHelpComponent,
                canHover: true,
                hasIcon: true
            }
        };
        this._helpService.updateContent([this.customRenderer]);

        // Create template maps
        this._templateMap = new Map([
            [Core.DescriptorFieldTypes.Date, this.dateTemplate],
            [Core.DescriptorFieldTypes.Number, this.numberTemplate],
            [Core.DescriptorFieldTypes.Picklist, this.picklistTemplate],
            [Core.DescriptorFieldTypes.YesNo, this.yesNoTemplate],
            [Core.DescriptorFieldTypes.Text, this.textTemplate],
            [Core.DescriptorFieldTypes.Currency, this.currencyTemplate]
        ]);

        this._customTemplateMap = new Map([
            [CustomTemplateTypes.AcqDate, this.acqDateTemplate],
            [CustomTemplateTypes.AssetClass, this.assetClassTemplate],
            [CustomTemplateTypes.Companies, this.companiesSelector],
            [CustomTemplateTypes.CostAdjustments, this.costAdjustmentTemplate],
            [CustomTemplateTypes.GlAccount, this.glAccountTemplate],
            [CustomTemplateTypes.ParcelSelector, this.parcelSelectorTemplate],
            [CustomTemplateTypes.Sites, this.sitesTemplate]
        ]);

        this.assetListForm = this._fb.group({
            acqDateAssetDescriptors: [0],
            costAssetDescriptors: [0]
        });

        this.assetListForm.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this._destroy$))
            .subscribe(x => this.selectedDescriptorsChanged.next(x));

        this.assetListForm.get('acqDateAssetDescriptors').valueChanges
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe(id => {
                if (id) {
                    this._timer.setTimeout(() => this._drawArrow(`.acqDateAssetDescriptors-${id}`, `.acqDateAssetDescriptors-0`, this.arrowBounds.acqDateAssetDescriptors));
                }
                this.altAcqDateId = id;
            });

        this.assetListForm.get('costAssetDescriptors').valueChanges
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe(id => {
                this.altCostId = id;
                this.calculateCosts();

                this.assetListFields.filter(f => f.labelTemplate && f.labelTemplate.type === CustomTemplateTypes.CostAdjustments).forEach(f => f.labelTemplate.isDoubleWidth = false);
                if (id) {
                    this.setCostAdjustmentControlsVisible(false);

                    this._timer.setTimeout(() => this._drawArrow(`.costAssetDescriptors-${id}`, `.costAssetDescriptors-0`, this.arrowBounds.costAssetDescriptors),0);
                } else {
                    this.setCostAdjustmentControlsVisible(this.hasCostAdjustments());
                }
            });

        this._assetInfoService.clearValues();

        const classifications = await lastValueFrom(this._assetClassificationRepository.getAssetClassifications());
        this._assetClassifications = [];
        classifications.forEach(x => this._flattenAssetClassifications(x, this._assetClassifications));

        this.costAdjustmentTypes = await lastValueFrom(this._costAdjustmentRepository.getTypes());

        // Add descriptors to list
        this._setDescriptors(Core.DescriptorCategoryEnum.AssetAquisitionDate, this.onAltAcqDateIdChange, 'acqDateAssetDescriptors');
        this._setCostDescriptors();
        this._setStandardDescriptors(Core.DescriptorCategoryEnum.AssetCost);
        this.costAssetDescriptors = this.assetDescriptors.filter(x => x.descriptor.category === Core.DescriptorCategoryEnum.AssetCost);

        this._getOwnershipTypes();
        this._getLeaseTypes();

        // Set up new asset
        if (this.createNewAsset) {
            this.childCompanies = (await this._companyService.getSubsidiaries(this.topLevelCompanyId, true)).sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            this.topLevelCompanyName = await this._companyService.getCompanyName(this.topLevelCompanyId);
            this.selectedCompanyId = this.newAssetCompanyId;

            this.onOwnershipTypeChange(this.ownershipTypes[0].value);

            this.enterEditState();
        }

        // Set up existing asset
        if (!this.createNewAsset) {
            // transientReportedCost should get updated by calculated cost, unless there is no source
            this._assetInfoService.calculatedCostUpdated$.pipe(takeUntil(this._destroy$)).subscribe((calculatedCost) => {
                if (!(this.assets && this.assets.length)) { return; }
                this.assets.forEach(asset => {
                    if (!this._assetInfoService.hasSourceValue) {
                        asset.transientReportedCost = null;
                    } else if (asset.isPrimary) {
                        asset.transientReportedCost = calculatedCost;
                    } else {
                        asset.transientReportedCost = 0;
                    }
                });
            });
        }

        this.canSeeHistory = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW) ||
            this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT);

        this.assetInfoReady.next(true);

        this._populateAltToggleFields();
        this._updateCostAdjustmentTypes();
        this._updateCostAdjustments(); // called inside setAssets but skipped pre initialized
        this._reloadArrows();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    add(): void {
        const asset = this.currentAsset.getModel();
        const newAsset = _.cloneDeep(asset);
        newAsset.reportingAsset.reportingAssetId = 0;
        newAsset.reportingAssetOverride = newAsset.reportingAssetOverride || {} as Compliance.ReportingAssetOverrideModel;
        newAsset.reportingAssetOverride.reportingAssetId = 0;
        newAsset.reportingAsset.isPrimary = false;

        // Remove cost overrides on split
        newAsset.reportingAssetOverride.cost = null;

        this.costAssetDescriptors.forEach((assetDescriptor: Compliance.CompanyAssetDescriptorMappingModel) =>
        {
            newAsset.reportingAssetOverride[assetDescriptor.columnName.toLowerCase()] = null;
        });

        if (!newAsset.reportingAssetOverride) {
            newAsset.reportingAssetOverride = {
                reportingAssetId: 0,
                reportingAssetOverrideId: 0,
                rowVersion: null,
                isGLAccountIdBlank: false
            };
        }

        // get the highest split number
        let splitNum = 0;

        const regex = new RegExp('_([0-9]{3})$', 'g');
        this.assets.forEach(
            x => {
                const matches = regex.exec(x.reportedAssetNumber);
                regex.lastIndex = 0;
                if (matches && matches.length === 2) {
                    const num = +matches[1];
                    if (num && num > splitNum) {
                        splitNum = num;
                    }
                }
            });

        splitNum = splitNum + 1;

        // pad left with 0s
        const padded = `000${splitNum}`;
        const suffix = padded.substr(padded.length - 3);

        newAsset.reportingAssetOverride.assetNumber = `${asset.assetNumber}_${suffix}`;

        const newAssetSummary = new AssetSummary(newAsset, this._keyValueDisplayPipe, null);

        this.assets.push(newAssetSummary);
        this.assetIndex = this.assets.length - 1;
        this.currentAsset = newAssetSummary;
        this.currentAssetChanged.next(this.currentAsset);
    }

    async delete(assetToDelete: AssetSummary): Promise<void> {
        try {
            await this._messageModalService.confirm(
                `Are you sure you wish to delete '${assetToDelete.reportedAssetNumber}'?`,
                'Confirm Delete');
        } catch (e) {
            return Promise.resolve();
        }
        // find asset index
        const assetIndex = this.assets.findIndex((asset: AssetSummary) => asset.reportingAssetId === assetToDelete.reportingAssetId);
        this.assets.splice(assetIndex, 1);

        let index = assetIndex - 1;
        if (index < 0) {
            index = 0;
        }

        this.currentAsset = this.assets[index];
        this.currentAssetChanged.next(this.currentAsset);
        return Promise.resolve();
    }

    isFormDirty(): boolean {
        return this.editMode || (this.assetForm && this.assetForm.dirty);
    }

    enterEditState(): void {
        this._populateAltToggleFields();
        this.editMode = true;
        this._configureCurrentAsset();
        this.calculateCosts();
        this._reloadArrows();
    }

    exitEditState(): void {
        this.editMode = false;
        this.assetForm.resetForm();
        this._populateAltToggleFields();
        this.calculateCosts();
        this._reloadArrows();
    }

    getTemplate(template: AssetTemplate): TemplateRef<any> {
        return (!template.isCustom)
            ? this._templateMap.get(<Core.DescriptorFieldTypes>template.type) as unknown as TemplateRef<any>
            : this._customTemplateMap.get(<CustomTemplateTypes>template.type) as unknown as TemplateRef<any>
    }

    templateValueChange(value: any, asset: AssetSummary, field: AssetInfoField): void {
        if (field.onChange) {
            field.onChange(value, asset, field);
        } else {
            asset[field.reportedParam] = value;
        }
    }

    formatValue(value: string, template: AssetTemplate): string {
        if (template.isCustom) {
            switch (template.type) {
            case CustomTemplateTypes.AcqDate:
                return value ? this._datePipe.transform(new Date(value), true) : '';
            default:
                return value;
            }
        }
        switch (template.type) {
            case Core.DescriptorFieldTypes.Date:
                return value ? this._datePipe.transform(new Date(value), true) : '';
            case Core.DescriptorFieldTypes.Currency:
                return this._decimalPipe.transform(value, '1.2-2');
            case DescriptorFieldTypes.YesNo:
                return value ? 'Yes' : 'No';
            default:
                return value;
        }
    }

    trackByFieldParam(index: number, field: AssetLeasedInfoField): string {
        return field.reportedParam;
    }

    trackByAssetId(index: number, asset: AssetSummary): number {
        return asset.assetId;
    }

    viewHistory(asset: AssetSummary): void {
        this._changeHistoryModalLaunchService.openChangeHistoryModal(
            asset.sourceAssetNumber,
            asset.reportingAssetId, Core.EntityTypes.Asset, 'ReportingAsset');
    }

    cancel(): void {
        // close outer modal
    }

    clearValue(asset: AssetSummary, field: AssetInfoField): void {
        if (field.onClear) {
            field.onClear(asset, field);
        } else {
            asset[field.reportedParam] = null;
        }
    }

    /**
     *  Ownership type logic
     */

    onOwnershipTypeChange(ownershipTypeId: number): void {
        if (!(this.assets && this.assets.length)) {
            return;
        }

        this.assets.forEach(a => {
            a.assetOwnershipTypeId = ownershipTypeId;
        });
    }

    getOwnershipTypeDescription(asset: AssetSummary): string {
        const ownershipType = this.ownershipTypes.find(x => x.value === asset.assetOwnershipTypeId);
        return ownershipType ? ownershipType.name : '';
    }

    /**
     * Company Logic
     */

    getCompanyNameFromId(asset: AssetSummary, field: AssetInfoField): string {
        if (!this.createNewAsset) {
            return asset.companyName;
        } else if (asset[field.reportedParam] === this.topLevelCompanyId) {
            return this.topLevelCompanyName;
        } else {
            const company = this.childCompanies.find(c => c.id === asset[field.reportedParam]);
            return (company) ? company.name : '';
        }
    }

    updateSelectedCompanyId(id: number, asset: AssetSummary): void {
        this._assetInfoService.selectedCompanyId = id;
        this.selectedCompanyId = id;
        if (asset) {
            this.onGlAccountSelected(null, asset);
            this.onSiteSelected(null, asset);
        }
    }

    /**
     * Acquisition Date Logic
     */

    formatAlternativeDate(): string {
        let value;
        if (this.altAcqDateId === 0) {
            value = this.currentAsset.reportedAcqDate;
        } else {
            const field = this.assetListFields.find(x => (x.descriptor && x.descriptor.companyAssetDescriptorMappingId) === this.altAcqDateId);
            if (!field) {
                return '';
            }
            value = this.currentAsset[field.reportedParam];
            if (!value) {
                return null;
            }
        }

        return (this.editMode && !this.currentAsset.isLocked) ? value : this.formatValue(value, { type: Core.DescriptorFieldTypes.Date });
    }

    onAcquisitionDateChanged(acqDate: Date): void {
        if (this.assets && this.assets.length > 0) {
            this.assets.forEach(asset => {
                asset.reportedAcqDate = acqDate;
            });
        }
    }

    onAltAcqDateIdChange(acqDate: Date, asset: AssetSummary, field: AssetInfoField): void {
        asset[field.reportedParam] = acqDate;
        if (this.assets && this.assets.length > 0 && this.currentAsset.isPrimary) {
            this.assets.forEach(x => {
                if (!x.isPrimary) {
                    x[field.reportedParam] = acqDate;
                    x.reportedAcqDate = acqDate;
                }
            });
        }
    }

    clearAcqDateOverride(): void {
        if (this.assets && this.assets.length > 0) {
            this.assets.forEach(asset => {
                asset.reportedAcqDate = null;
            });
        }
    }

    onIsPerpetualChanged(eventTarget: EventTarget, asset: AssetSummary): void {
        const isPerpetual = (eventTarget as HTMLInputElement).checked;
        asset.reportingIsPerpetual = isPerpetual;
        console.log('asset.reportingIsPerpetual', asset.reportingIsPerpetual);
    }

    /**
     * Disposed Date Logic
     */

    onDisposedDateChanged(disposedDate: Date): void {
        if (!(this.assets && this.assets.length)) {
            return;
        }

        this.assets.forEach(asset => {
            asset.reportedDisposedDate = disposedDate;
        });
    }

    clearDisposedDateOverride(): void {
        if (this.assets && this.assets.length > 0) {
            this.assets.forEach(asset => {
                asset.reportedDisposedDate = null;
            });
        }
    }

    /**
     * Cost Logic
     */

    isCostField(fieldType: Core.DescriptorFieldTypes | CustomTemplateTypes): boolean {
        return !this.altCostId && fieldType === Core.DescriptorFieldTypes.Currency;
    }

    showCostAdjustments(id: number): boolean {
        return this.altCostId === id && !this.createNewAsset && this.costFieldHasSource;
    }

    hasCostAdjustments(): boolean {
        let nonNullFound = false;

        if (!this.altCostId) {
            this.extendedCostAdjustmentTypes.forEach((x: ExtendedCostAdjustmentTypeModel) => {
                const adjustment = this.costAdjustments[x.costAdjustmentTypeId];
                if (adjustment && (adjustment.adjustmentAmount !== null || adjustment.adjustmentPercentage !== null)) {
                    nonNullFound = true;
                }
            });
        }

        return nonNullFound;
    }

    setCostAdjustmentControlsVisible(visible: boolean): void {
        this.costAdjustmentControlsVisible = visible;
        //double the width of the cell
        const costField = this.assetListFields
            .find(f => f.descriptor && f.descriptor.companyAssetDescriptorMappingId === this.altCostId && f.labelTemplate && f.labelTemplate.type === CustomTemplateTypes.CostAdjustments);
        if (costField) {
            costField.labelTemplate.isDoubleWidth = visible;
        }
    }

    formatTransientCost(asset: AssetSummary, field: AssetInfoField): number | string {
        if (this.editMode) {
            return (this.altCostId === 0) ? asset.transientReportedCost : this.getAlternativeCostOrOverride(asset);
        } else {
            if (this.altCostId > 0) {
                const value = this.getAlternativeCostOrOverride(asset);
                return this.formatValue(value, { type: Core.DescriptorFieldTypes.Currency });
            } else if (!(this.altCostId > 0) && !asset.reportedCostHasOverride) {
                return asset.isPrimary ? this.formatAlternativeCost() : '0.00';
            } else if (!(this.altCostId > 0) && asset.reportedCostHasOverride) {
                return this.formatValue(`${asset.reportedCost}`, { type: Core.DescriptorFieldTypes.Currency });
            }
        }
        return null;
    }

    formatAlternativeCost(): string {
        const output = this._assetInfoService.calculatedCost;
        return output ? output : '';
    }

    getAlternativeCostDisplay(asset: AssetSummary, field: AssetInfoField): string {
        const selectedCost = this.assetListForm.get(field.descriptorList);
        if (selectedCost && selectedCost.value !== field.descriptor.companyAssetDescriptorMappingId) {
            if (asset.reportedColumnHasOverride(field.descriptor.columnName)) {
                return this.formatValue(asset[field.reportedParam], { type: Core.DescriptorFieldTypes.Currency });
            } else {
                return null;
            }
        }

        if (this.editMode) {
            return asset[`transientReported${field.descriptor.columnName.toUpperCase()}`];
        } else {
            const value = this.getAlternativeCostOrOverride(asset);
            return this.formatValue(value, { type: Core.DescriptorFieldTypes.Currency });
        }
    }

    getAlternativeCostOrOverride(asset: AssetSummary): string {
        const target = this.assetListFields.find(f => f.descriptor && f.descriptor.companyAssetDescriptorMappingId === this.altCostId);
        if (!target) { return '0'; }

        if (asset.reportedColumnHasOverride(target.descriptor.columnName)) {
            return asset[target.reportedParam];
        }

        // Primary gets the cost, non primaries get 0 - unless there is no source
        if (this._assetInfoService.hasSourceValue) {
            return `${asset.isPrimary ? this._assetInfoService.calculatedCostValue : 0}`;
        } else {
            return null;
        }
    }

    onTransientReportedCostChange(event: any): void {
        // if this is different than the current calculated value (or 0 for split), persist as a real override
        try {
            const expectedValue = this.currentAsset.isPrimary ? this._assetInfoService.calculatedCostValue : 0;
            if (event && event.target && event.target.value != null && ((!this._assetInfoService.hasSourceValue) || parseFloat(event.target.value) !== expectedValue)) {
                this.currentAsset.reportedCost = event.target.value;
            }
        } finally { }

        this.calculateCosts();
    }

    onTransientAlternateReportedCostChange(event: any, asset: AssetSummary, field: AssetInfoField): void {
        try {
            const expectedValue = asset.isPrimary ? this._assetInfoService.calculatedCostValue : 0;
            if (event.target && event.target.value && ((!this._assetInfoService.hasSourceValue) || parseFloat(event.target.value)) !== expectedValue) {
                asset[field.reportedParam] = event.target.value;
                asset[`transientReported${field.descriptor.columnName.toUpperCase()}`] = event.target.value;
            }
        } finally { }

        this.calculateCosts();
    }

    getCostAdjustmentDescription(value: string, field: AssetInfoField): string {
        return (this.costFieldHasSource && this._assetInfoService.costAdjustmentDescription
                && (field && this.showCostAdjustments(field.descriptor.companyAssetDescriptorMappingId)))
            ? this._assetInfoService.costAdjustmentDescription
            : this.formatValue(value, { type: Core.DescriptorFieldTypes.Currency });
    }

    async showCostPercentageModal(): Promise<void> {
        const params: AssetInfoCostEditorParams = {
            assets: this.assets,
            calculatedCost: this._assetInfoService.calculatedCostValue,
            alternativeCostMappingModel: this.getCostCompanyAssetDescriptorMappingModel()
        }

        const result = await this._modalService.showAsync(AssetInfoCostEditorComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }
        const altCostMappingModel: Compliance.CompanyAssetDescriptorMappingModel = this.getCostCompanyAssetDescriptorMappingModel();
        for (let i = 0; i < this.assets.length; i++) {
            const asset: AssetSummary = this.assets[i];
            const costAmount: number = result.costAmounts[i];
            const expectedValue = asset.isPrimary ? this._assetInfoService.calculatedCostValue : 0;

            asset.transientReportedCost = costAmount;
            // now update non transient setting
            if (costAmount !== expectedValue) {
                // use override
                if (altCostMappingModel != null) {
                    const fieldName = `reported${altCostMappingModel.columnName.toUpperCase()}`;
                    asset[fieldName] = costAmount;
                } else {
                    asset.reportedCost = costAmount;
                }
            } else {
                // clear override
                if (altCostMappingModel != null) {
                    const fieldName = `reported${altCostMappingModel.columnName.toUpperCase()}`;
                    asset[fieldName] = null;
                } else {
                    asset.reportedCost = null;
                }
            }
        }

        this.calculateCosts();

        return Promise.resolve();
    }

    showAssignByPercentageButton(): boolean {
        if (this._assetInfoService.hasSourceValue && this.editMode && this.assets.length > 1) {
            return true;
        } else {
            return false;
        }
    }

    allowPerpetual(): boolean {
        const selectedAssetClassification = this._assetClassifications.find(
            x => x.assetClassificationId === this.currentAsset.reportedAssetClassificationId);
        return selectedAssetClassification && selectedAssetClassification.allowPerpetual;
    }

    calculateCosts(): void {
        if (this.currentAsset && !this.createNewAsset) {
            let sourceFieldValue;

            const altCostMappingModel = this.getCostCompanyAssetDescriptorMappingModel();

            if (!altCostMappingModel) {
                sourceFieldValue = this.currentAsset.sourceCost;
            } else {
                sourceFieldValue = this.currentAsset[`source${altCostMappingModel.columnName}`];
            }

            this._assetInfoService.calculateCostsAndCheckTotals(sourceFieldValue, this.costAdjustments, this.assets);
        }
    }

    getCostCompanyAssetDescriptorMappingModel(): Compliance.CompanyAssetDescriptorMappingModel {
        if (this.altCostId > 0) {
            const field = this.assetListFields.find(x => x.descriptor && x.descriptor.companyAssetDescriptorMappingId === this.altCostId);
            return field && field.descriptor;
        }
        return null;
    }

    /**
     * Asset Class Logic
     */

    onAssetClassificationChanged(assetClassificationId: number, asset: AssetSummary): void {
        const selectedAssetClassification = this._assetClassifications.find(x => x.assetClassificationId === assetClassificationId);

        if (selectedAssetClassification && !selectedAssetClassification.allowPerpetual) {
            asset.reportingIsPerpetual = false;
        }
    }

    getAssetClassName(asset: AssetSummary): string {
        return asset.reportedAssetClassificationDisplay;
    }

    onAssetClassificationRemoveValue(asset: AssetSummary): void {
        // when removing, default to GL account asset classification
        asset.reportedAssetClassificationId = asset.reportedGlAccountClassificationId || asset.sourceGlAccountClassificationId;
        if (!asset.reportedAssetClassificationId) { return; }

        // if the new asset class doesn't allow perpetual, set accordingly
        const selectedAssetClassification = this._assetClassifications.find(x => x.assetClassificationId === asset.reportedAssetClassificationId);

        if (selectedAssetClassification && !selectedAssetClassification.allowPerpetual) {
            asset.reportingIsPerpetual = false;
        }
    }

    /**
     * GL Account Logic
     */

    onGlAccountSelected(selected: ExtendedGLAccountModel, asset: AssetSummary): void {
        if (!selected) {
            asset.reportedGlAccount = null;
            asset.isReportedGlAccountBlank = true;
            return;
        }

        const glAccount = selected.model;
        if (!asset.reportedGlAccount || (asset.reportedGlAccount && asset.reportedGlAccount.assetClassificationId === asset.reportedAssetClassificationId)) {
            asset.reportedAssetClassificationId = glAccount.assetClassificationId;
        }

        // don't set an override if one doesn't exist and the classification matches the reported value (meaning source)
        if (!(asset.reportedGlAccount && glAccount.assetClassificationId === asset.reportedGlAccount.assetClassificationId && !asset.reportedGlAccountHasOverride)) {
            asset.reportedGlAccount = glAccount;
            asset.isReportedGlAccountBlank = false;
        }
    }

    onGlAccountRemoveOverride(asset: AssetSummary): void {
        // clear override and restore filter back to the source value if one exists
        asset.reportedGlAccount = null;
        asset.isReportedGlAccountBlank = (asset.reportedSiteHasOverride && asset.reportedSite.companyID !== asset.companyId);
    }

    /**
     * Site Logic
     */

    onSiteSelected(selected: ExtendedSiteModel, asset: AssetSummary): void {
        if (!selected || selected.model.siteID === asset.reportedSiteId) { return }

        this.onGlAccountSelected(null, asset);
        asset.reportedSite = selected ? selected.model : null;
        if (selected?.model.reportingParcelId) {
            asset.reportedParcelId = selected.model.reportingParcelId;
        }

        if (this.createNewAsset && selected && this._assetInfoService.selectedCompanyId !== selected.model.companyID) {
            this.updateSelectedCompanyId(selected.model.companyID, asset);
        }
    }

    onSiteRemoveOverride(asset: AssetSummary): void {
        // clear override and restore filter back to the source value if one exists
        asset.reportedSite = null;
        if (asset.parcelHasOverride) {
            asset.reportedParcelId = null;
        }
    }

    /**
     * Parcel Logic
     */

    getParcelName(asset: AssetSummary): string {
        return asset.reportedParcelName;
    }

    getParcels = async (searchParams: any): Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>> => {
        return await lastValueFrom(this._reportingParcelRepository.getReportingParcelsByAsset(searchParams));
    }

    /**
     * Inventory Logic
     * @returns {}
     */

    get selectedFieldsTab(): AssetInfoFieldsTab{
        return this._selectedFieldsTab;
    }

    set selectedFieldsTab(value: AssetInfoFieldsTab){
        this._selectedFieldsTab = value;
    }

    get inventoryFields(): AssetInventoryField[] {
        return this._inventoryFields;
    }

    get showInventoryFields(): boolean {
        let showInventoryFields: boolean = false;

        this.assets.forEach((asset) => {
            showInventoryFields = !!(asset.reportedInventoryJan ||
                asset.reportedInventoryFeb ||
                asset.reportedInventoryMar ||
                asset.reportedInventoryApr ||
                asset.reportedInventoryMay ||
                asset.reportedInventoryJun ||
                asset.reportedInventoryJul ||
                asset.reportedInventoryAug ||
                asset.reportedInventorySep ||
                asset.reportedInventoryOct ||
                asset.reportedInventoryNov);

            if (!showInventoryFields) {
                const assetClassificationId = asset.reportedAssetClassificationId;

                if (assetClassificationId && this._assetClassifications) {
                    const selectedAssetClassification = this._assetClassifications.find(
                        x => x.assetClassificationId === assetClassificationId);

                    showInventoryFields = selectedAssetClassification && selectedAssetClassification.isInventory;
                }
            }

            if (showInventoryFields) {
                return false;
            }
        });

        if (!showInventoryFields && this.selectedFieldsTab === AssetInfoFieldsTab.Inventory){
            this.selectedFieldsTab = AssetInfoFieldsTab.Asset;
        }

        return showInventoryFields;
    }

    overrideAll(asset: AssetSummary): void {
        this.assetListFields
            .filter(x =>
                x.reportedTemplate &&
                x.reportedParam &&
                !(x.reportedTemplate.isStatic && x.reportedTemplate.isStatic()) &&
                !((x.descriptor && x.descriptor.columnName) ? asset.reportedColumnHasOverride(x.descriptor.columnName) : asset[x.reportedOverride]) &&
                x.reportedParam !== 'reportedIsLocked')
            .forEach(x => this._overrideField(x, asset));

        this.inventoryFields.forEach(x => this._overrideField(x, asset));

        if (asset.isLeasedToOther) {
            this.leasedToFields.forEach(x => this._overrideField(x, asset));
            this.leasedToFromFields.forEach(x => this._overrideField(x, asset));
        } else if (asset.isLeasedFromOther) {
            this.leasedToFromFields.forEach(x => this._overrideField(x, asset));
        }
    }

    onDoNoPotentiallyDisposeChanged(value: boolean): void {
        if (!(this.assets && this.assets.length)) {
            return;
        }

        this.assets
            .filter(asset => !asset.isPrimary)
            .forEach(asset => {
                asset.reportedDoNotPotentiallyDispose = value;
            });
    }

    async onIsLockedChange(isLocked: boolean, asset: AssetSummary): Promise<void> {
        if (!(this.assets && this.assets.length)) {
            return;
        }

        this._primaryAssetIsLocked = isLocked;

        if (isLocked) {
            this.overrideAll(asset);
        } else {
            try {
                const options: MessageModalOptions = {
                    displayMode: MessageModalDisplayMode.SingleMessage,
                    buttons: MessageModalButtons.YesNo,
                    focusButton: MessageModalResult.Yes,
                    message: `Do you want to remove overrides?`,
                    title: 'Confirm'
                }

                await this._messageModalService.open(options);
            } catch (e) {
                return Promise.resolve();
            }

            this._removeOverrideAll(asset);
        }
    }

    getIsLockedDisplayValue(asset: AssetSummary): string {
        return asset.reportedIsLocked ? 'Yes' : 'No';
    }

    get primaryAssetIsLocked(): boolean {
        return this._primaryAssetIsLocked;
    }

    private _overrideField(x: AssetInfoField, asset: AssetSummary) {
        console.log('overrideField for ', x.reportedParam);
        switch (x.reportedParam) {
            case 'reportedGlAccountDisplay':
                asset.reportedGlAccount = asset.sourceGlAccount;
                break;
            case 'reportedCost':
                asset.reportedCost = +this.formatTransientCost(asset, x);
                break;
            case 'reportedSiteDisplay':
                asset.reportedSite = asset.sourceSite;

                if (!asset.parcelHasOverride && asset.sourceSiteReportingParcelId) {
                    asset.reportedParcelId = asset.sourceSiteReportingParcelId;
                }
                break;
            // case 'reportedParcelId':
            //     break;
            default:
                if (asset[x.sourceParam] !== null && asset[x.sourceParam] != undefined) {
                    this.templateValueChange(asset[x.sourceParam], asset, x);
                }
                break;
        }
    }

    private _setCostDescriptors(): void {
        this._setDescriptors(Core.DescriptorCategoryEnum.AssetCost, this.onTransientAlternateReportedCostChange, 'costAssetDescriptors');
        this.assetListFields.filter(f => f.descriptorList === 'costAssetDescriptors').forEach(f => {
            if (f.descriptor.companyAssetDescriptorMappingId > 0) {
                const template = { type: CustomTemplateTypes.CostAdjustments, isCustom: true };
                f.labelTemplate = template;
                f.sourceValueGetter = this.getCostAdjustmentDescription.bind(this);
                f.reportedValueGetter = this.getAlternativeCostDisplay.bind(this);
                f.isSourceCellShown = function() {
                    return !this.labelTemplate.isDoubleWidth;
                };
            }
        });
    }

    private _setDescriptors(descriptorType: Core.DescriptorCategoryEnum, onChange: Function, listName?: string): void {
        const addAtIndex = (listName) ? this.assetListFields.findIndex(f => f.descriptorList === listName) : this.assetListFields.length - 1;
        if (addAtIndex === -1) { return; }
        const primary = this.assetListFields[addAtIndex];
        primary.descriptorCount = 0;
        this.assetDescriptors
            .filter(x => x.descriptor.category === descriptorType)
            .map((d, i, a) => this._fieldFromDescriptor(d, onChange, listName, a.length))
            .forEach(f => {
                this.assetListFields.splice(addAtIndex, 0, f);
                primary.descriptorCount += 1;
            });
    }

    private _setStandardDescriptors(descriptorType: Core.DescriptorCategoryEnum): void {
        const updateByReportedParam = (value: any, asset: AssetSummary, field: AssetInfoField) => {
            asset[field.reportedParam] = value
        };
        const updateYesNo = (value: any, asset: AssetSummary, field: AssetInfoField) => {
            asset[field.reportedParam] = value ? 1 : 0
        };

        this.assetDescriptors
            .filter(x => !([Core.DescriptorCategoryEnum.AssetCost, Core.DescriptorCategoryEnum.AssetAquisitionDate] as Core.DescriptorCategoryEnum[]).includes(x.descriptor.category))
            .map(d => {
                let onChange = updateByReportedParam;

                if (d.descriptor.fieldType === DescriptorFieldTypes.YesNo) {
                    onChange = updateYesNo;
                }
                return this._fieldFromDescriptor(d, onChange, null, null);
            })
            .forEach(f => this.assetListFields.push(f));
    }

    private _fieldFromDescriptor(descriptor: Compliance.CompanyAssetDescriptorMappingModel, onChange: Function, listName: string, count: number): AssetInfoField {
        const result: AssetInfoField = {
            label: descriptor.descriptor.name,
            cellClass: `${listName}-${descriptor.companyAssetDescriptorMappingId}`,
            descriptorList: listName,
            descriptorCount: count,
            descriptorHelpContentId: `asset-info.${listName}`,
            descriptor: descriptor,
            sourceParam: `source${descriptor.columnName}`,
            reportedParam: `reported${descriptor.columnName}`,
            reportedOverride: '',
            reportedTemplate: { type: descriptor.descriptor.fieldType, maxLength: descriptor.descriptor.validation && descriptor.descriptor.validation.maxLength },
            isDisabled: (row: AssetSummary) => this.assetListForm.value[listName] >= 0 && this.assetListForm.value[listName] !== descriptor.companyAssetDescriptorMappingId || (row && !row.isPrimary && listName === 'acqDateAssetDescriptors'),
            onChange: onChange.bind(this),
            isRowShown: () => !this.createNewAsset,
            picklist: descriptor.pickList && descriptor.pickList.map(x => {return {name: x.name, value: x.name}})
        };

        if (result.picklist && result.picklist.length !== 0) {
            result.reportedValueGetter = (asset: AssetSummary, field: AssetInfoField) => {
                const selectedOption = result.picklist.find(x => x.value === asset[field.reportedParam])

                return selectedOption ? selectedOption.name : '';
            };
        }

        return result
    }

    private async _getOwnershipTypes(): Promise<void> {
        const ownershipTypes: Compliance.AssetOwnershipTypeModel[] =  await lastValueFrom<Compliance.AssetOwnershipTypeModel[]>(this._assetRepository.getOwnershipTypes());
        this.ownershipTypes = ownershipTypes.map(l => ({ name: l.description, value: l.assetOwnershipTypeId }));
        const picklistField = this.assetListFields.find(f => f.sourceParam === 'assetOwnershipTypeId');
        picklistField.picklist = this.ownershipTypes;
    }

    private async _getLeaseTypes(): Promise<void> {
        this.leaseTypes = await lastValueFrom<Compliance.LeaseTypeModel[]>(this._assetRepository.getLeaseTypes())
        let picklistField;
        if (this.createNewAsset) {
            picklistField = this.assetListFields.find(f => f.reportedParam === 'reportedLeaseTypeId');
        } else {
            picklistField = this.leasedToFromFields.find(f => f.reportedParam === 'reportedLeaseTypeId');
        }
        picklistField.picklist = this.leaseTypes.map(l => ({ name: l.leaseTypeId, value: l.leaseTypeId }));
    }

    private _flattenAssetClassifications(classification: Compliance.AssetClassificationModel, classifications: Compliance.AssetClassificationModel[]): void {
        classifications.push(classification);

        if (classification.childClassifications && classification.childClassifications.length) {
            classification.childClassifications.forEach(
                (x) => { this._flattenAssetClassifications(x, classifications) });
        }
    }

    private _updateCostAdjustments(): void {
      // need to update in component
        if (this.extendedCostAdjustmentTypes) {

            this.extendedCostAdjustmentTypes.forEach(x => {
                const costAdjustments = this.currentAsset.getModel().costAdjustments;
                const adjustment = costAdjustments && costAdjustments.find(y => y.costAdjustmentTypeId === x.costAdjustmentTypeId);
                if (adjustment) {
                    const addAdjustment = { ...adjustment };
                    if (addAdjustment.adjustmentAmount === 0) {
                        addAdjustment.adjustmentAmount = null;
                    }
                    if (addAdjustment.adjustmentPercentage === 0) {
                        addAdjustment.adjustmentPercentage = null;
                    }
                    if (addAdjustment.adjustmentPercentage !== null) {
                        addAdjustment.adjustmentPercentage = addAdjustment.adjustmentPercentage * 100;
                    }
                    this.costAdjustments[x.costAdjustmentTypeId] = addAdjustment;
                } else {
                    this.costAdjustments[x.costAdjustmentTypeId] = {
                        adjustmentAmount: null,
                        adjustmentPercentage: null,
                        assetCostAdjustmentId: null,
                        stateCostAdjustmentId: null,
                        rowVersion: null,
                        costAdjustmentTypeId: x.costAdjustmentTypeId
                    };
                }
            });

            this._assetInfoService.costAdjustments = this.costAdjustments;
            this.calculateCosts();
        }
    }

    private _updateCostAdjustmentTypes(): void {
        const stateAbbr = this.currentAsset ? this.currentAsset.state : null;
        if (!stateAbbr || !this.costAdjustmentTypes) {
            return;
        }

        const state = this.states.find(x => x.abbr === stateAbbr);
        const stateId = state && state.stateID;

        const filteredTypes = this.costAdjustmentTypes.filter(x => x.states.find(y => y.stateId === stateId) ? true : false);
        const flattenedTypes: FlattenedCostAdjustmentSource[] = filteredTypes.map(type => {
            const typeState = type.states.find(x => x.stateId === stateId);
            return {
                typeName: type.typeName,
                costAdjustmentTypeId: type.costAdjustmentTypeId,
                applyToSource: typeState.applyToSource,
                sequence: typeState.sequence,
                states: []
            };
        });

        const sequencedCostAdjustments = flattenedTypes.sort((a, b) => a.sequence - b.sequence);
        this.extendedCostAdjustmentTypes = sequencedCostAdjustments;
        this.customRenderer.componentParams = { value: sequencedCostAdjustments };
        this._assetInfoService.extendedCostAdjustmentTypes = this.extendedCostAdjustmentTypes;

    }

    private _configureCurrentAsset(): void {
        this.hasOldAssetNumber = !!(this.currentAsset.sourceOldAssetNumber || this.assets.find(x => !!x.reportedOldAssetNumber));

        this._updateCostAdjustmentTypes();
        this._updateCostAdjustments();
        this.setCostAdjustmentControlsVisible(this.hasCostAdjustments());
        this.calculateCosts();

        if (this._assetInfoService.hasSourceValue) {
            this.currentAsset.transientReportedCost = this.currentAsset.isPrimary ? this._assetInfoService.calculatedCostValue : 0;
        } else {
            this.currentAsset.transientReportedCost = null;
        }
    }

    private _populateAltToggleFields(): void {
        if (this.assetListForm && this.assetDetailModel) {
            this.assetListForm.patchValue({
                acqDateAssetDescriptors: this.assetDetailModel.alternativeAcqDateDescriptorId ? this.assetDetailModel.alternativeAcqDateDescriptorId : 0,
                costAssetDescriptors: this.assetDetailModel.alternativeCostDescriptorId ? this.assetDetailModel.alternativeCostDescriptorId : 0
            });
        }
    }

    private _reloadArrows(): void {
        if (this.altAcqDateId) {
            this._timer.setTimeout(() => this._drawArrow(`.acqDateAssetDescriptors-${this.altAcqDateId}`, `.acqDateAssetDescriptors-0`, this.arrowBounds.acqDateAssetDescriptors),0);
        }

        if (this.altCostId) {
            this._timer.setTimeout(() => this._drawArrow(`.costAssetDescriptors-${this.altCostId}`, `.costAssetDescriptors-0`, this.arrowBounds.costAssetDescriptors),0);
        }
    }

    private _drawArrow(originId: string, targetId: string, arrowDataHolder: any): void {
        const target = this._elementRef.nativeElement.querySelectorAll(targetId)[0];
        const origin = this._elementRef.nativeElement.querySelectorAll(originId)[0];

        if (origin && target) {
            const originBounds = origin.getBoundingClientRect();
            const targetBounds = target.getBoundingClientRect();

            const arrowRightDistance = 40;

            arrowDataHolder.top = (originBounds.height / 2) - 6;
            arrowDataHolder.left = originBounds.width / 2;
            arrowDataHolder.height = `${targetBounds.top - originBounds.top + 10}px`;
            arrowDataHolder.width = `${arrowRightDistance + 10}px`;

            const rightTop = arrowRightDistance - 10;
            const rightBottom = targetBounds.top - originBounds.top - 10;

            arrowDataHolder.dPath = `M0,2 H${rightTop} a10,10 0 0 1 10,10 V${rightBottom} a10,10 0 0 1 -10,10 H6`;

            const arrowOffset = targetBounds.top - originBounds.top;

            arrowDataHolder.arrowPoints = `3,${arrowOffset} 9,${arrowOffset + 3} 9,${arrowOffset - 3}`;
        }
    }

    private _removeOverrideAll(asset: AssetSummary): void {
        this.assetListFields
            .filter(x =>
                x.reportedTemplate &&
                x.reportedParam &&
                !(x.reportedTemplate.isStatic && x.reportedTemplate.isStatic()) &&
                (x.descriptor && x.descriptor.columnName ? asset.reportedColumnHasOverride(x.descriptor.columnName) : asset[x.reportedOverride]) &&
                x.reportedParam !== 'reportedIsLocked' &&
                (asset.isPrimary || x.reportedParam !== 'reportedAssetNumber' && x.reportedParam !== 'reportedCost'))
            .forEach(x => this.clearValue(asset, x));

        this.inventoryFields.forEach(x => this.clearValue(asset, x));

        if (asset.isLeasedToOther) {
            this.leasedToFields.forEach(x => this.clearValue(asset, x));
            this.leasedToFromFields.forEach(x => this.clearValue(asset, x));
        } else if (asset.isLeasedFromOther) {
            this.leasedToFromFields.forEach(x => this.clearValue(asset, x));
        }
    }
}
