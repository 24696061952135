import { Component, OnInit, OnDestroy } from '@angular/core';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { GridApi, GridReadyEvent, ColDef, GridOptions, CsvExportParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../AgGrid';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

export interface FormTemplateValidationParams {
    validationResult: Compliance.ReturnFormTemplateValidationResultModel;
    message?: string;
}

interface FormTemplateValidationRow {
    message: string;
    validationType: string;
}

@Component({
    selector: 'form-template-validation',
    templateUrl: './formTemplateValidation.component.html',
    styleUrls: ['./formTemplateValidation.component.scss']
})
export class FormTemplateValidationComponent implements OnInit, OnDestroy, IWeissmanModalComponent<FormTemplateValidationParams, void> {
    constructor(private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _validationRows: FormTemplateValidationRow[];

    gridOptions: GridOptions = new AgGridOptionsBuilder({})
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .withFirstDataRendered(x => {
           x.columnApi.autoSizeAllColumns();
        })
        .build();

    params: FormTemplateValidationParams;
    result: void;

    ngOnInit(): void {
        this._validationRows = _.union(_.map(this.params.validationResult.errors, (x) => {
            return {
                message: x,
                validationType: 'Error'
            };
        }), _.map(this.params.validationResult.warnings, (x) => {
            return {
                message: x,
                validationType: 'Warning'
            };
        }));

        this._setRowData();
    }

    ngOnDestroy(): void { }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Type',
                field: 'validationType',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams): string => {
                    const validation = params.data as FormTemplateValidationRow;
                    if (validation.validationType === 'Error') {
                        return 'cell-validation-error';
                    }

                    if (validation.validationType === 'Warning') {
                        return 'cell-validation-warning';
                    }

                    return '';
                }
            },
            {
                headerName: 'Message',
                field: 'message',
                width: AgGridColumns.textColumnLargeWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'validationType',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    export(): void {
        const params = {
            fileName: 'FormTemplateValidation.csv'
        } as CsvExportParams;
        this._gridApi.exportDataAsCsv(params);
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._validationRows)) {
            return;
        }

        this._gridApi.setRowData(this._validationRows);
    }
}
