import { Component, Input, OnChanges } from '@angular/core';

import * as _ from 'lodash';

@Component({
    selector: 'parcel-icon',
    templateUrl: './parcelIcon.component.html',
    styleUrls: ['./parcelIcon.component.scss']
})
export class ParcelIconComponent implements OnChanges {
    @Input() size: string = 'small';
    @Input() includeIcons: boolean;
    @Input() stateAllowConsolidating: boolean;
    @Input() ppReturnPreparationAllowed: boolean;
    @Input() parcel: any;
    @Input() propTypeParam: string;

    @Input()
    set isLinkedParcel(isLinked: boolean) {
        this.isLinked = isLinked;
        this._linkedOverride = true;
    };

    @Input()
    set isMasterParcel(isMaster: boolean) {
        this.isMaster = isMaster;
        this._masterOverride = true;
    };

    icons: any[] = [];

    isLinked: boolean;
    isMaster: boolean;
    parcelType: string;

    private _linkedOverride: boolean;
    private _masterOverride: boolean;

    get parcelTypeClass(): string {
        return `${this.size}${(this.isLinked || this.isMaster) ? '-with-icon' : ''} property-type-${this.parcelType}`;
    }

    get parcelIconClass(): string {
        return `${(this.isMaster) ? 'fa-star' : 'fa-chain'} property-type-${this.parcelType}`;
    }

    ngOnChanges(): void {
        this._setParcelParams();
    }

    private _setParcelParams(): void {
        this.parcelType = (this.propTypeParam) ? _.get(this.parcel, this.propTypeParam) : this.parcel.propTypeAbbr;

        if (!this._linkedOverride) {
            this.isLinked = this.parcel.isLinked;
        }
        
        if (!this._masterOverride) {
            this.isMaster = this.parcel.isMasterParcel;
        }

        if (this.includeIcons) {
            this.icons = [];
            if (this.parcel.consolidatingTypeId === 2 && this.ppReturnPreparationAllowed) {
                this.icons.push({
                    iconClass: 'fa-sitemap',
                    colorClass: `${this.size} consolidated`,
                    helpTooltip: this.stateAllowConsolidating ? 'app.state-consolidated-parcel' : 'app.site-consolidated-parcel'
                });
            }
            if (this.parcel.consolidatedParcelId && this.ppReturnPreparationAllowed && !this.parcel.consolidatingTypeId && this.parcel.activityStatusID !== Core.ActivityStatuses.Inactive) {
                this.icons.push({
                    iconClass: 'fa-sitemap',
                    colorClass: `${this.size} merged`,
                    helpTooltip: this.stateAllowConsolidating ? 'app.state-merged-parcel' : 'app.site-merged-parcel'
                });
            }
            if (this.parcel.isReportingParcel && this.ppReturnPreparationAllowed) {
                this.icons.push({
                    iconClass: 'fa-file-text-o',
                    colorClass: `${this.size} default`,
                    helpTooltip: 'app.reporting-parcel'
                });
            }
        }
    }
}
