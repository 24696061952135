<div *ngIf="initialized" class="panel-flat-body form-horizontal format-schedule-summary">
    <form [formGroup]="reportOutputForm">
        <div class="report-settings">
            <div class="col-md-3 ws-flex-container-horizontal">
                <label class="control-label">Title:</label>
                <input type="text" class="form-control" maxlength="50" formControlName="title" required>
            </div>
        </div>
        <div class="report-form-groups row">
            <div class="col col-md-4">
                <div class="report-form-group">
                    <div class="row">
                        <span class="col-md-8 section-header">
                            Report Format:
                        </span>
                    </div>
                    <div class="check-row" *ngFor="let option of formatOptions">
                        <label class="ws-checkbox-label">
                            <input type="checkbox" [formControlName]="option.formControlName">
                            <span>{{option.label}}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col col-md-4">
                <div class="report-form-group">
                    <div class="row">
                        <span class="col-md-8 section-header">
                            Optional Columns:
                        </span>
                    </div>
                    <div class="check-row" *ngFor="let optional of optionalCols">
                        <label class="ws-checkbox-label">
                            <input type="checkbox" [formControlName]="optional.formControlName">
                            <span>{{optional.label}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
