import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface PercentChangeRendererParams extends ICellRendererParams {
    getPercentage: (params: PercentChangeRendererParams) => number;
}

@Component({
    selector: 'percentage-change-renderer',
    template: `<span *ngIf="diff && diff !== 0" [ngClass]="{ 'increase': diff > 0, 'decrease': diff < 0 }">
					<i class="fa" [ngClass]="{ 'fa-arrow-up': diff > 0, 'fa-arrow-down': diff < 0 }"></i> {{diff | percent: '1.3'}}</span>`
})
export class PercentChangeRendererComponent implements ICellRendererAngularComp {

    params: PercentChangeRendererParams;

    agInit(params: PercentChangeRendererParams): void {
        this.params = params;
    }

    get diff(): number {
        return this.params.getPercentage(this.params);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
