import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ReportingParcelRepository } from '../../../../Compliance/Repositories';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer } from '../../../../Compliance/AgGrid/MultiSelectTracker';
import { lastValueFrom, Subscription } from 'rxjs';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../../AgGrid';
import { ComplianceSiteService } from '../../site.service';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

export interface LinkedSitesAddParams {
    companyId: number;
    sites: Core.LinkedSiteModel[];
    originalSites: Core.LinkedSiteModel[];
    siteId: number;
}

interface LinkedSiteModelSelectable extends Core.LinkedSiteModel {
    isSelected: boolean;
}

@Component({
    selector: 'linked-sites-add',
    templateUrl: './linkedSitesAdd.component.html',
    styleUrls: ['./linkedSitesAdd.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LinkedSitesAddComponent implements OnInit, OnDestroy, IWeissmanModalComponent<LinkedSitesAddParams, Core.LinkedSiteModel[]> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _reportingParcelRepository: ReportingParcelRepository,
        private readonly _siteService: ComplianceSiteService) {
    }

    private _gridApi: GridApi;
    private _gridTracker: AgGridMultiSelectTracker;
    private _selectedRowsSub: Subscription;
    private _availableSites: Core.LinkedSiteModel[];
    private _allSites: Core.LinkedSiteModel[];

    params: LinkedSitesAddParams;
    result: Core.LinkedSiteModel[] = [];

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this._gridTracker.isRowSelected((params.data as Core.LinkedSiteModel).siteId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    rowsSelected: boolean = false;

    async ngOnInit(): Promise<void> {
        const searchModel: Core.LinkedSiteSearchModel = {
            companyId: this.params.companyId,
            includeMasterSites: false
        };

        // get available sites from db
        const availableSites = await lastValueFrom(this._reportingParcelRepository.getAvailableSites(searchModel));

        // add sites originally linked, but not updated in db yet
        this._allSites = _.concat(availableSites, this.params.originalSites);

        // remove any sites in available already selected
        const result = _.filter(this._allSites, (as: Core.LinkedSiteModel) => !_.some(this.params.sites, (s: Core.LinkedSiteModel) => { return as.siteId === s.siteId; }));

        this._availableSites = _.map(result, (s) => { return { ...s, isSelected: false } as LinkedSiteModelSelectable });

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this._gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'siteId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const site = params.data as Core.LinkedSiteModel;
                    return this._siteService.getLinkedSiteName(site) + (site.siteId === this.params.siteId ? ' (this site)' : '');
                },
                filterValueGetter: params => {
                    const site = params.data as Core.LinkedSiteModel;
                    return this._siteService.getLinkedSiteName(site);
                }
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'State',
                field: 'state',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        const rowIds = await this._gridTracker.getSelectedRowIds();

        this.result = _.filter(this._allSites, (x) => _.some(rowIds, (y) => x.siteId === y));

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._availableSites)) {
            return;
        }

        this._gridApi.setRowData(this._availableSites);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const site = x.data as LinkedSiteModelSelectable;
                return site && site.siteId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
