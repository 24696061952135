(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('DuplicateIntakeItemModalController', DuplicateIntakeItemModalController);

    DuplicateIntakeItemModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'processingService',
        'sdAttachmentService',
        'isBulk',
        'duplicateItem',
        'optionalArgs'
    ];

    function DuplicateIntakeItemModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, $uibModalInstance, processingService, attachmentService, isBulk, duplicateItem, optionalArgs) {
        $scope.isLoading = true;
        $scope.duplicate = duplicateItem;

        activate();

        function activate() {
            getDocumentTypeList();
        }

        function getDocumentTypeList() {
            attachmentService.getAttachmentTypes().then(function (results) {
                $scope.documentTypeList = results;
                $scope.isLoading = false;
            });

        }

        $scope.resetCopyNumber = function() {
            if(!$scope.duplicate.duplicateCount || $scope.duplicate.duplicateCount <= 0) {
                $scope.duplicate.duplicateCount = 1;
            }
        }

        $scope.checkCopyNumber = function() {
            if($scope.duplicate.duplicateCount && $scope.duplicate.duplicateCount.toString().length > 2) {
                var newCount = $scope.duplicate.duplicateCount.toString();
                newCount = newCount.substring(0, 2);

                $scope.duplicate.duplicateCount = parseInt(newCount);
            }
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $scope.save = function () {
            $scope.isLoading = true;
            if(isBulk) {
                if (optionalArgs.parentCallsService) {
                    $uibModalInstance.close(function () {
                        return processingService.createDuplicateIntakeItemBulk(duplicateItem);
                    });
                }
                else {
                    processingService.createDuplicateIntakeItemBulk(duplicateItem)
                        .then(function (response) {
                            $scope.isLoading = false;
                            $uibModalInstance.close(response);
                        });
                }
            } else {
                processingService.createDuplicateIntakeItem(duplicateItem)
                    .then(function(response) {
                        $scope.isLoading = false;
                        $uibModalInstance.close(response);
                    }, function() {
                        $scope.isLoading = false;
                        $uibModalInstance.dismiss();
                    });
            }
        };
    }
})();
