import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const USER_GROUP_DETAIL_HELP: HelpContent[] = [
    {
        helpContentId: 'user-group-detail.new-company-permission',
        tooltipText: 'Add a new company permission'
    },
    {
        helpContentId: 'user-group-detail.edit-site-permissions',
        tooltipText: 'Edit company site permissions'
    },
    {
        helpContentId: 'user-group-detail.view-site-permissions',
        tooltipText: 'View company site permissions'
    }
];
