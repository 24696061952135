import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { CsrCommandCenterComponent } from './csrCommandCenter.component';
import { CsrCommandCenterService } from './csrCommandCenter.service';
import {CsrCommandCenterRepository} from './csrCommandCenter.repository';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { ReportingModule } from '../../Reporting/reporting.module';
import { CsrBulkUpdateComponent } from './Bulk-Update/csrBulkUpdate.component';
import { CsrUpdateStatusCellRendererComponent } from './agGridCsrUpdateStatusCellRenderer.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        AgGridModule.withComponents({
            CsrUpdateStatusCellRendererComponent
        }),
        AgGridExtensionsModule,
        ReportingModule,
    ],
    declarations: [
        CsrCommandCenterComponent,
        CsrBulkUpdateComponent,
        CsrUpdateStatusCellRendererComponent,
    ],
    providers: [
        CsrCommandCenterService,
        CsrCommandCenterRepository
    ],
    exports: [
        CsrCommandCenterComponent,
        CsrBulkUpdateComponent
    ]
})
export class CsrCommandCenterModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('csrCommandCenter', CsrCommandCenterComponent);
    }
}
