import { Component, OnDestroy } from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import { BusyIndicatorRef, BusyIndicatorService } from '../../../../../../Busy-Indicator';
import {BusyIndicatorMessageManager} from '../../../../../../Busy-Indicator';
import {EntityImportRepository} from '../../../../../Repositories';
import {EntityImportProcessService} from "../../../importProcess.service";
import * as _ from 'lodash';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './importBulkUpdate.component.html',
    selector: 'import-bulk-update'
})
export class ImportBulkUpdateComponent implements OnDestroy{
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _entityImportProcessService: EntityImportProcessService){
    }

    private _isGlAccountTypeDirty: boolean = false;
    private _isIncomeStatementCategoryDirty: boolean = false;
    private _busyRef: BusyIndicatorRef;
    private _bulkUpdateModel: Compliance.ImportBulkUpdateFieldModel[] = [];
    private _destroy$: Subject<void> = new Subject();

    importId: number;
    importFileId: number;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    mappedFields: FieldInfo[] = [];
    selectionModel: Compliance.ImportBulkOperationModel;
    saveCompleteCallback: (selectionModel: Compliance.ImportBulkOperationModel) => void;
    validationMessage: string;
    selectedCount: number;
    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();

    isSaving: boolean = false;

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async save(): Promise<void> {
        this.isSaving = true;

        try {
            const updateModel = {
                updateFields: this._bulkUpdateModel,
            } as Compliance.ImportBulkUpdateModel;

            _.extend(updateModel, this.selectionModel);

            this._showBusyIndicator('Bulk update', 'Updating', null);

            const longRunningProcessId = await lastValueFrom(this._entityImportRepository.bulkUpdateFieldsAsync(this.importFileId, updateModel));
            await this._busyRef.setLongRunningProcessId(longRunningProcessId);
        } catch (e2) {
            await this._hideBusyIndicator();
            return Promise.reject(e2);
        }
    }

    cancel() {
        this._bsModalRef.hide();
    }

    isDisabled(importFileSpecificationFieldId: number): boolean {
        const fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);
        return !fieldAction || fieldAction.action !== Compliance.ImportBulkUpdateFieldActionEnum.ChangeTo;
    }

    onActionChanged(mappedField: FieldInfo, action: Compliance.ImportBulkUpdateFieldActionEnum): void {
        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === mappedField.importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {
                importFileSpecificationImportFieldId: mappedField.importFileSpecificationFieldId,
            } as Compliance.ImportBulkUpdateFieldModel;
            this._bulkUpdateModel.push(fieldAction);
        }

        fieldAction.action = action;

        fieldAction.value = (action === Compliance.ImportBulkUpdateFieldActionEnum.ChangeTo) ? '' : null;

        if(mappedField.uiCellRenderer === 'IncomeStatementCategorySelector') {
            this._isIncomeStatementCategoryDirty = fieldAction.action !== Compliance.ImportBulkUpdateFieldActionEnum.NoChange;
        }

        if(mappedField.uiCellRenderer === 'GLAccountTypeSelector') {
            this._isGlAccountTypeDirty = fieldAction.action !== Compliance.ImportBulkUpdateFieldActionEnum.NoChange;
        }
    }

    onValueChanged(importFileSpecificationFieldId: number, value: string): void {
        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = value;
    }

    onAssetClassificationChanged(importFileSpecificationFieldId: number, assetClass: Compliance.AssetClassificationModel) {
        const value = assetClass ? assetClass.name : null;

        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = value;
    }

    onIncomeStatementCategoryChanged(importFileSpecificationFieldId: number, incomeStatementCategory: Core.IncomeStatementCategoryModel) {
        const value = incomeStatementCategory ? incomeStatementCategory.name : null;

        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = value;
    }

    getValue(importFileSpecificationFieldId: number): string {
        const fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        return fieldAction && fieldAction.value;
    }

    get isFormValid(): boolean{
        let isValid: boolean;

        isValid = !!this._bulkUpdateModel.find(i => i.action === Compliance.ImportBulkUpdateFieldActionEnum.ChangeTo && (i.value === '' || !!i.value))
            || !!this._bulkUpdateModel.find(i => i.action === Compliance.ImportBulkUpdateFieldActionEnum.RemoveUserValue);

        return isValid;
    }

    onAssessorChanged(importFileSpecificationFieldId: number, assessorValue?: string) {
        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = assessorValue;
    }

    onAssessmentClassChanged(importFileSpecificationFieldId: number, assessmentClassValue?: string) {
        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = assessmentClassValue;
    }

    onGLAccountChanged(importFileSpecificationFieldId: number, glAccount: string) {
        const value = glAccount ? glAccount : null;

        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = value;
    }

    onTaxingDistrictChanged(importFileSpecificationFieldId: number, value?: string) {
        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = value;
    }

    onGlAccountTypeChanged(importFileSpecificationFieldId: number, eventTarget: EventTarget) {
        const glAccountType = (eventTarget as HTMLSelectElement).value;

        let fieldAction = this._bulkUpdateModel.find(i => i.importFileSpecificationImportFieldId === importFileSpecificationFieldId);

        if (!fieldAction){
            fieldAction = {} as Compliance.ImportBulkUpdateFieldModel;
        }

        fieldAction.value = glAccountType;
    }

    isChangeToAllowed(mappedField: FieldInfo): boolean {
        const areAdditionalParamsValid = !mappedField.additionalParams || mappedField.additionalParams.length !== 0;

        switch(mappedField.uiCellRenderer) {
            case 'IncomeStatementCategorySelector':
                return areAdditionalParamsValid && !this._isGlAccountTypeDirty;
            case 'GLAccountTypeSelector':
                return areAdditionalParamsValid && !this._isIncomeStatementCategoryDirty;
            default:
                return areAdditionalParamsValid;
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: false,
            canDismiss: false
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            if (success) {
                try {
                    if (this.saveCompleteCallback) {
                        this.saveCompleteCallback(this.selectionModel);
                    }
                } finally {
                    await this._hideBusyIndicator();
                    this.isSaving = false;
                    this._bsModalRef.hide();
                }
            } else {
                await this._hideBusyIndicator();
                this.isSaving = false;
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }
}

export class FieldInfo{
    name: string;
    isMappingRequired: boolean;
    importFileSpecificationFieldId: number;
    uiCellRenderer: string;
    additionalParams: any;
}
