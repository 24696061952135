import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';
import { ErrorLogRepository } from '../errorLog.repository';

export class ErrorLogListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        readonly gridApi: GridApi,
        private readonly _errorLogRepository: ErrorLogRepository
    ) { super(gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Core.ErrorLogInfoPropertyEnum>[] = [
        { name: 'id', value: Core.ErrorLogInfoPropertyEnum.Id },
        { name: 'guid', value: Core.ErrorLogInfoPropertyEnum.Guid },
        { name: 'date', value: Core.ErrorLogInfoPropertyEnum.Date },
        { name: 'userId', value: Core.ErrorLogInfoPropertyEnum.UserId },
        { name: 'contactName', value: Core.ErrorLogInfoPropertyEnum.ContactName },
        { name: 'message', value: Core.ErrorLogInfoPropertyEnum.Message },
        { name: 'machineName', value: Core.ErrorLogInfoPropertyEnum.MachineName },
        { name: 'httpMethod', value: Core.ErrorLogInfoPropertyEnum.HttpMethod },
        { name: 'route', value: Core.ErrorLogInfoPropertyEnum.Route }
    ];

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.ErrorLogInfoSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._errorLogRepository.getList(searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
