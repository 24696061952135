import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription ,  BehaviorSubject } from 'rxjs';

export interface ICellRendererParamsForPackageListDropdownCellRenderer extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: ICellRendererParamsForPackageListDropdownCellRenderer) => boolean;
    isDisabled: (params: ICellRendererParamsForPackageListDropdownCellRenderer) => boolean;
    dropdownItems: () => any[];
    name: string;
    valueField: string;
    allowNull: boolean;
    originalValue: any;
    displayGetter: (dropdownItem: any) => string;
    change: (params: ICellRendererParamsForPackageListDropdownCellRenderer) => void;
}

@Component({
    selector: 'return-output-package-list-dropdown',
    template: `<div #outerDiv class="drop-down-on-hover-cell" [ngClass]="isHovering ? 'mouseover' : ''" (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <div class="read-only-label">{{ getDisplayValue() }}</div>
        <div class="edit-control" >
            <select [name]="params.name" [ngModel]="params.data[params.colDef.field]" (ngModelChange)="onModelChange($event)">
                <option [ngValue]="null" *ngIf="params.allowNull"></option>
                <option *ngFor="let d of params.dropdownItems()" [ngValue]="d[params.valueField]">{{params.displayGetter(d)}}</option>
            </select>
        </div>
    </div>`
})
export class ReturnOutputPackageListDropdownCellRenderer implements ICellRendererAngularComp {
    private _editModeSub: Subscription;

    params: ICellRendererParamsForPackageListDropdownCellRenderer;
    editMode: boolean = false;
    isHovering: boolean;
    @ViewChild('outerDiv', { read: ElementRef, static: true }) outerDiv: ElementRef;

    agInit(params: ICellRendererParamsForPackageListDropdownCellRenderer): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChange(value: any) {
        this.params.originalValue = this.params.data[this.params.colDef.field];
        this.params.data[this.params.colDef.field] = value;
        this.params.change(this.params);
    }

    mouseover($event) {
        this.isHovering = this.editMode && this.params.canEdit(this.params);
    }

    mouseout($event) {
        if (this.editMode && this.params.canEdit(this.params) && $event.relatedTarget) {
            // protect against clicks on inner children
            let parent = $event.relatedTarget.parentElement;
            while (parent) {
                if (parent === this.outerDiv.nativeElement) {
                    return;
                }
                parent = parent.parentElement;
            }
            this.isHovering = false;
        }
    }

    getDisplayValue(): string {
        if (!this.params.data) {
            return null;
        }
        const dropdownItem = this.params.dropdownItems().find(x => x[this.params.valueField] === this.params.data[this.params.colDef.field]);
        return (dropdownItem && this.params.displayGetter(dropdownItem)) || null;
    }
}
