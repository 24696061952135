
<div class="input-group" aria-label="Select Site">
    <input name="sites"
           type="text"
           placeholder="Filter Sites..."
           class="form-control"
           autocomplete="off"
           container="body"
           [class.overridden]="isOverridden"
           [(ngModel)]="siteFilter"
           (blur)="onBlur()"
           [typeahead]="sites$"
           (typeaheadNoResults)="onNoResults($event)"
           typeaheadOptionField="displayName"
           [typeaheadOptionsLimit]="100"
           [typeaheadWaitMs]="750"
           (typeaheadLoading)="onLoadingChange($event)"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)" />
</div>
