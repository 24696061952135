<div class="parcel-icons">
    <span class="label parcel-type"
          [ngClass]="parcelTypeClass">
        {{parcelType}}
        <i *ngIf="isLinked"
           class="fa"
           [ngClass]="parcelIconClass"
           helpTooltip
           position="bottom"
           [tooltipText]="'Parcel is ' + ((isMaster) ? 'Master' : 'Linked')">
        </i>
    </span>
    <span *ngFor="let icon of icons"
          class="label parcel-icon"
          [ngClass]="icon.colorClass"
          [class.size]="size"
          helpTooltip
          [helpContentId]="icon.helpTooltip"
          position="bottom">
        <i class="fa" [ngClass]="icon.iconClass"></i>
    </span>
</div>
