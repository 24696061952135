import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const REPORT_MANAGER_HELP: HelpContent[] = [
    {
        helpContentId: 'report-manager.groupings',
        tooltipText: 'Group the data by it\'s properties',
        hasIcon: true
    },
    {
        helpContentId: 'report-manager.save-as',
        tooltipText: 'Save As'
    },
    {
        helpContentId: 'report-manager.all-instances-selected',
        tooltipText: 'Please select a single instance to save search'
    }
];
