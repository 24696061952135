import { from as observableFrom, lastValueFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from '../../../../../constants.new';
import { ExtendedContactModel } from '../../../Models/extendedContactModel';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ReturnSettingsEntityTypeEnum, ReturnSettingsViewModelEnum } from '../../../Models/enums';
import { ReturnSettingsRepository } from '../../../../Repositories';

@Component({
    selector: 'return-settings-contact-attribute',
    templateUrl: './returnSettingsContactAttribute.component.html'
})
export class ReturnSettingsContactAttributeComponent implements OnInit{
    constructor(
        private readonly _constants: Constants,
        private readonly _returnSettingsRepository: ReturnSettingsRepository
    ) { }

    @Input() isTopLevelCompany: boolean;
    @Input() currentContact: Core.ContactModel;
    @Input() parentContact: Core.ContactModel;
    @Input() currentRepresentationId: Compliance.RepresentationTypeEnum;
    @Input() parentRepresentationId: Compliance.RepresentationTypeEnum;
    @Input() currentRepresentationValue: string;
    @Input() parentRepresentationValue: string;
    @Input() isReadonly: boolean;
    @Input() viewMode: ReturnSettingsViewModelEnum;
    @Input() currentContactId: number;
    @Input() parentContactId: number;
    @Input() entityType: ReturnSettingsEntityTypeEnum;
    @Input() entityId: ReturnSettingsEntityTypeEnum;
    @Input() parentContactNameLookupValue: string;

    @Output() contactChanged: EventEmitter<ExtendedContactModel> = new EventEmitter<ExtendedContactModel>();
    @Output() representationChanged: EventEmitter<number> = new EventEmitter<Compliance.RepresentationTypeEnum>();

    representationTypes: Compliance.NameValuePair<Compliance.RepresentationTypeEnum>[];
    contactFilter: string = '';
    contacts$: Observable<ExtendedContactModel[]> = (Observable
        .create((observer: any) => { observer.next(this.contactFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterContacts(token)));
    ViewModelEnum = ReturnSettingsViewModelEnum;
    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;

    private _hasChanges: boolean;
    private _contactNoResult: boolean = false;

    ngOnInit(): void {
        let representationTypes = this._constants.RepresentationTypes;
        if (this.entityType === ReturnSettingsEntityTypeEnum.Return){
            representationTypes = [{ name: '', value: null }].concat(representationTypes);
        }
        this.representationTypes = representationTypes;

        this._setContactFilterToCurrentValue();
    }

    get contactHasOverride(): boolean{
        return !this.isTopLevelCompany && this.currentContactId && this.currentContactId !== this.parentContactId;
    }

    get contact(): Core.ContactModel {
        return (this.isTopLevelCompany || this.contactHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return)
            ? this.currentContact
            : this.parentContact;
    }

    set contact(value: Core.ContactModel) {
        let contactId: number;

        if (value){
            if (this.parentContact && value.contactID === this.parentContact.contactID)
            {
                contactId = null;
            }
            else{
                contactId = value.contactID;
            }
        }
        else{
            contactId = null;
        }

        if (this.currentContactId !== contactId) {
            const extendedContact = new ExtendedContactModel({
                contactID: contactId,
                firstName: value ? value.firstName : '',
                lastName: value ? value.lastName : ''
            } as Core.ContactModel);
            this.contactChanged.emit(extendedContact);
        }

        this._hasChanges = true;
        this.currentContact = value;
        this.currentContactId = contactId;
    }

    get representationHasOverride(): boolean {
        // owner representation type is 0, so unable to use truthy check
        return !this.isTopLevelCompany &&
            this.currentRepresentationId !== null &&
            this.currentRepresentationId !== undefined &&
            this.currentRepresentationId !== this.parentRepresentationId;
    }

    get representationId(): Compliance.RepresentationTypeEnum {
        return (this.isTopLevelCompany || this.representationHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return)
            ? this.currentRepresentationId
            : this.parentRepresentationId;
    }

    set representationId(value: Compliance.RepresentationTypeEnum) {
        if (value === this.parentRepresentationId && this.entityType !== ReturnSettingsEntityTypeEnum.Return){
            value = null;
        }

        this._hasChanges = true;
        this.currentRepresentationId = value;

        this.representationChanged.emit(value);
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    onContactSelected(match: TypeaheadMatch): void {
        this.contact = (match.item as ExtendedContactModel).model;
        this.contactFilter = match.value;
        this._hasChanges = true;
    }

    onContactBlur(): void {
        if (this.contactFilter.trim() === ''){
            if (this.entityType === ReturnSettingsEntityTypeEnum.Return) {
                this.contact = null;
            }
            else {
                this._setContactFilterToCurrentValue();
            }
        }
        else if (this._contactNoResult) {
            this._setContactFilterToCurrentValue();
        }
    }

    get contactDisplay(): string {
        if (this.entityType === ReturnSettingsEntityTypeEnum.Return && !this.contactHasOverride){
            return this.parentContactNameLookupValue;
        }
        else {
            const contact = (this.isTopLevelCompany || this.contactHasOverride) ? this.currentContact : this.parentContact;
            return contact ? `${contact.firstName} ${contact.lastName}` : '';
        }
    }

    onRemoveContactOverride(): void {
        this.contact = null;
        this._setContactFilterToCurrentValue();
    }

    get contactInfo(): string{
        let result = this.contactDisplay;

        const representationValue = (this.representationHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return)? this.currentRepresentationValue : this.parentRepresentationValue;
        if (result && representationValue) {
            result += ` (${representationValue})`;
        }

        return result;
    }

    get representationDisplay(): string{
        let result = '';

        const representationValue = this.representationHasOverride ? this.currentRepresentationValue : this.parentRepresentationValue;
        if (this.contactDisplay && representationValue) {
            result = ` (${representationValue})`;
        }

        return result;
    }

    private _filterContacts(filter: string): Observable<ExtendedContactModel[]> {

        const searchModel: Compliance.GeneralReturnSettingsContactSearchModel = {
            filter: filter,
            entityId: this.entityId,
            entityType: this.entityType as unknown as Compliance.ReturnSettingsEntityTypeEnum,
            includeInactive: false,
            pageNumber: 1,
            pageSize: 50
        };


        return observableFrom(lastValueFrom(this._returnSettingsRepository.searchContacts(searchModel))).pipe(
            map(contacts => {
                if (contacts && contacts.length === 0){
                    this._contactNoResult = true;
                }

                contacts.unshift({contactID: -1, firstName: '[Blank]', lastName: ''} as Core.ContactModel);

                return contacts.map(contact =>
                    new ExtendedContactModel({
                        contactID: contact.contactID,
                        firstName: contact.firstName,
                        lastName: contact.lastName
                    } as Core.ContactModel));
            }));
    }

    private _setContactFilterToCurrentValue(): void {
        let result = '';

        if(this.isTopLevelCompany || this.contactHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return) {
            if (this.currentContactId === -1) {
                result = '[Blank]'
            } else if (this.currentContact) {
                result = this.contactDisplay;
            }
        } else {
            if (this.parentContactId === -1) {
                result = '[Blank]'
            } else if (this.parentContact) {
                result = this.contactDisplay;
            }
        }

        this.contactFilter = result;
    }
}
