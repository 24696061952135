import { Component, OnDestroy, OnInit } from '@angular/core';
import { AceUIIcon, IMenuAction, IPortalBrandingConfig } from '@ace/shared';
import { UIRouter } from '@uirouter/angular';
import { Subject, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { AppStates, AppStateService } from '../../Layout/appStateService';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { TimerService } from '../../UI-Lib/Utilities';
import { branding, TopNavRoles, TopNavService } from '../Top-Nav/topNav.service';

interface AnalyticsEvent {
    key: string;
    params?: { [key: string]: any };
}

interface Analytics {
    [key: string]: AnalyticsEvent;
}

@Component({
    selector: 'side-nav',
    templateUrl: './sideNav.component.html',
    styleUrls: ['./sideNav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
    constructor(private readonly _router: UIRouter,
                private readonly _topNavService: TopNavService,
                private readonly _appStateService: AppStateService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _restrictService: RestrictService,
                private readonly _timerService: TimerService,
                private readonly _featureFlagsService: FeatureFlagsService) {
        this._appStateService.appState$.pipe(
            tap(state => {
                if (state === AppStates.LoggedIn) {
                    if (!this.roles) {
                        this.roles = this._topNavService.roles();
                    }
                    this._setLoggedInMenu();
                } else {
                    this._setLoggedOutMenu();
                }
            })
        ).subscribe();
    }

    closed = true;
    show: boolean;
    hiding: boolean;

    roles: TopNavRoles;
    branding: IPortalBrandingConfig = branding;
    navActions: IMenuAction[] = [];
    currentUrl: string = '';

    private _analytics: Analytics = {
        stateJurisdictionCommandCenter: { key: 'click-manage-admin-state-juris-CC' },
        taxRateCommandCenter: { key: 'click-manage-admin-tax-rate-CC' },
        paymentBatchCommandCenter: { key: 'click-manage-payment-batch-CC' }
    };
    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.branding.footerPortalHref = this._featureFlagsService.taxDotComBase;
        this._topNavService.sideNavOpen$.pipe(takeUntil(this._destroy$)).subscribe((isOpen: boolean) => {
            const url = window.location.href.substring(window.location.origin.length);
            this.currentUrl = url.substring(2);
            if (isOpen) {
                this.show = isOpen;
                this._timerService.setTimeout(() => {
                    this.closed = !isOpen;
                }, 0);
            } else {
                this.closed = !isOpen;
                this.hiding = true;
                this._timerService.setTimeout(() => {
                    this.show = isOpen;
                    this.hiding = false;
                }, 300);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    close(): void {
        if (this.show) {
            this._topNavService.sideNavOpen = false;
        }
    }

    routeTo(target: string): void {
        this._router.stateService.transitionTo(target);
    }

    handleActionTriggered(action: CustomEvent<IMenuAction | 'home'>) {
        if (action.detail === 'home') {
            this._topNavService.sideNavOpen = false;
            this._router.stateService.transitionTo(action.detail);
            return;
        }
        switch (action.detail.key) {
            case 'newSmart':
                this._advancedSearchPersistenceService.clearSearchData();
                this._productAnalyticsService.logEvent('click-explore-SMART');
                this.routeTo('search');
                break;
            case 'newAV':
                this._actionViewPersistenceService.clearSavedData();
                this._productAnalyticsService.logEvent('click-act-new');
                this.routeTo('actionview');
                break;
            default:
                const menuAction: IMenuAction = action.detail;
                if (menuAction.routerOutlet) {
                    this.routeTo(menuAction.routerOutlet);
                }
                if (this._analytics[menuAction.key]) {
                    const event = this._analytics[menuAction.key];
                    this._productAnalyticsService.logEvent(event.key, event.params);
                }
        }
        this._topNavService.sideNavOpen = false;
    }

    private _setLoggedInMenu() {
        const nav: IMenuAction[] = [
            {
                isHeading: true,
                key: 'propertyPoint',
                content: 'PropertyPoint'
            },
            {
                key: 'smart',
                content: 'SMARTs',
                children: [
                    { key: 'newSmart', content: 'New SMART', routerOutlet: 'search', routerLink: ['#', '/search'] },
                    {
                        key: 'systemSmart',
                        content: 'System SMARTs',
                        routerOutlet: 'savedSmart.system',
                        routerLink: ['#', '/saved', '/smart', '/system']
                    },
                    {
                        key: 'savedSmart',
                        content: 'My Saved SMARTs',
                        routerOutlet: 'savedSmart.user',
                        routerLink: ['#', '/saved', '/smart', '/user']
                    }
                ]
            },
            {
                key: 'actionView',
                content: 'Action Views',
                children: [
                    {
                        key: 'newAV',
                        content: 'New Action View',
                        routerOutlet: 'actionview',
                        routerLink: ['#', '/actionview']
                    },
                    {
                        key: 'systemAV',
                        content: 'System Action Views',
                        routerOutlet: 'savedActionView.system',
                        routerLink: ['#', '/saved', '/action-view', '/system']
                    },
                    {
                        key: 'savedAV',
                        content: 'My Saved Action Views',
                        routerOutlet: 'savedActionView.user',
                        routerLink: ['#', '/saved', '/action-view', '/user']
                    }
                ]
            },
            {
                key: 'report',
                content: 'Reports',
                children: [
                    {
                        key: 'systemReport',
                        content: 'System Reports',
                        routerOutlet: 'savedReport.system',
                        routerLink: ['#', '/saved', '/report', '/system']
                    },
                    {
                        key: 'userReport',
                        content: 'My Saved Reports',
                        routerOutlet: 'savedReport.user',
                        routerLink: ['#', '/saved', '/report', '/user']
                    }
                ]
            }
        ];

        // Add Command Centers
        const commandCenters = this._getCommandCenters();
        if (commandCenters) {
            nav.push(commandCenters);
        }

        // Add Intake Documents
        if (this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT)) {
            nav.push({
                key: 'intakeDocuments',
                content: 'Intake Documents',
                routerOutlet: 'documentintake',
                routerLink: ['#', '/documentintake']
            });
        }

        // Add States Map
        nav.push({
            key: 'stateJurisdiction',
            content: 'States & Jurisdictions',
            routerOutlet: 'states',
            routerLink: ['#', '/states']
        });

        // Add Manage and/or Diagnostic
        if (!this.roles.hasAdminRole && (this.roles.hasAddNewCompanyRole || this.roles.hasAdminRole)) {
            nav.push({ key: 'manage', content: 'Manage', routerOutlet: 'manage', routerLink: ['#', '/manage'] });
        }

        if (this.roles.hasAdminRole) {
            nav.push(
                { key: 'manage', content: 'Manage', routerOutlet: 'manage', routerLink: ['#', '/manage'] },
                {
                    key: 'diagnostic',
                    content: 'Diagnostic',
                    routerOutlet: 'diagnostic',
                    routerLink: ['#', '/diagnostic']
                }
            );
        }

        // Add Snapshot
        nav.push({
                isHeading: true,
                key: 'propertyTax',
                content: 'Property Tax'
            },
            {
                key: 'snapshot',
                content: 'Snapshot',
                url: `${this._featureFlagsService.taxDotComBase}/apps/PropertyTaxSnapshot/`,
                icon: AceUIIcon.WindowRestore
            });

        this.navActions = nav;
    }

    private _setLoggedOutMenu() {
        this.navActions = [];
    }

    private _getCommandCenters(): IMenuAction {
        if (!this.roles.hasAdminRole) {
            return null;
        }

        const commandCenters: IMenuAction = {
            key: 'commandCenters',
            content: 'Command Centers',
            children: []
        };

        if (this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions) {
            commandCenters.children.push({
                key: 'appealRecommendationCommandCenter',
                content: 'Appeal Recommendation Command Center',
                routerOutlet: 'appealRecommendationCommandCenter',
                routerLink: ['#', '/appealRecommendationCommandCenter']
            });
        }

        if (this._featureFlagsService.featureFlags.enablePaymentBatch && (this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions)) {
            commandCenters.children.push({
                key: 'paymentBatchCommandCenter',
                content: 'Payment Batch Command Center',
                routerOutlet: 'paymentBatchCommandCenter',
                routerLink: ['#', '/paymentBatchCommandCenter']
            });
        }

        if (this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions) {
            commandCenters.children.push({
                key: 'stateJurisdictionCommandCenter',
                content: 'State & Jurisdiction Command Center',
                routerOutlet: 'stateJurisdictionCommandCenter',
                routerLink: ['#', '/stateJurisdictionCommandCenter']
            });
        }

        if (this.roles.hasViewRyanPrivatePermissions || this.roles.hasEditRyanPrivatePermissions) {
            commandCenters.children.push({
                key: 'taxRateCommandCenter',
                content: 'Tax Rate Command Center',
                routerOutlet: 'taxRateCommandCenter',
                routerLink: ['#', '/taxRateCommandCenter']
            });
        }

        return commandCenters.children.length ? commandCenters : null;
    }
}
