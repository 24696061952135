<ws-ace-banner *ngIf="showBanner && isLoggedIn"
               class="d-block top-banner"
               [block]="true"
               [enableDismiss]="true"
               [content]="bannerMessage?.message"
               (dismissed)="dismissBanner()">
</ws-ace-banner>
<ace-header
    [logoSrc]="branding.logoUrl"
    [logoTitle]="branding.logoTitle"
    [currentAction]="currentAction || null"
    [actions]="chromeActions"
    [enableNav]="isLoggedIn"
    (activated)="handleActionTriggered($any($event))"
    (sideNavActivated)="toggleSideNav()">
</ace-header>
<div *ngIf="isLoggedIn && showInstanceFilter"
     class="instance-filter d-flex justify-content-end pe-3">
    <ws-instance-filter></ws-instance-filter>
</div>
<snack-bar *ngIf="isLoggedIn"></snack-bar>
