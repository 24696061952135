import { MimeTypeExtension } from './attachment.model';

export const mimeTypeExtensions: MimeTypeExtension[] = [{
    type: 'application/pdf',
    exts: ['.pdf'],
    previewSupported: true
}, {
    type: 'text/plain',
    exts: ['.txt'],
    previewSupported: true
}, {
    type: 'image/png',
    exts: ['.png'],
    previewSupported: true
}, {
    type: 'image/gif',
    exts: ['.gif'],
    previewSupported: true
}, {
    type: 'image/jpeg',
    exts: ['.jpeg', '.jpg'],
    previewSupported: true
}, {
    type: 'image/tiff',
    exts: ['.tif', '.tiff'],
    previewSupported: true
}, {
    type: 'application/msword',
    exts: ['.doc', '.dot'],
    previewSupported: false
}, {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    exts: ['.docx', '.dotx'],
    previewSupported: false
}, {
    type: 'application/vnd.ms-excel',
    exts: ['.xls', '.xlt', '.xla'],
    previewSupported: false
}, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    exts: ['.xlsx'],
    previewSupported: false
}, {
    type: 'application/vnd.ms-powerpoint',
    exts: ['.ppt', '.pot', '.pps', '.ppa'],
    previewSupported: false
}, {
    type: 'application/zip',
    exts: ['.zip'],
    previewSupported: false
}];