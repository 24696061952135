import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConsultingEngagementRepository, UserRepository } from '../../Core-Repositories';
import { AccountService } from '../../User/account.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { EntityType, EntityTypeIds } from '../../constants.new';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';

import * as _ from 'lodash';
import { EntityPermissionService } from './entityPermission.service';

// todo - still needed for old js files, remove once all are upgraded
export enum PermissionFlags {
    READ = 0,
    WRITE = 1,
}

export class RestrictData {
    roles: string[];
    instanceRights: string[];
    explicitInstanceId: number;
    isCompany: boolean;
    entityId: number;
    flag: Core.AccessRightsEnum;
    rolesOrEntity?: boolean;
}

@Injectable()
export class Roles {
    // static readonly ADDNEWCOMPANY = 'AddNewCompany';
    static readonly ASSESSOREDIT = 'AssessorEdit';
    static readonly ASSESSORVIEW = 'AssessorView';
    static readonly COLLECTOREDIT = 'CollectorEdit';
    static readonly COLLECTORVIEW = 'CollectorView';
    static readonly COMMENTSEDIT = 'CommentsEdit';
    static readonly COMMENTSVIEW = 'CommentsView';
    static readonly COMPANYVIEW = 'CompanyView';
    // static readonly CONTACTEDIT = 'ContactEdit';
    // static readonly CONTACTVIEW = 'ContactView';
    //static readonly CONTACTROLEEDIT = 'ContactRoleEdit';
    static readonly CONTACTROLEVIEW = 'ContactRoleView';
    static readonly PROPCHAREDIT = 'PropCharEdit';
    static readonly PROPCHARVIEW = 'PropCharView';
    static readonly RYANPRIVATEITEMSVIEW = 'RyanPrivateItemsView';
    static readonly RYANPRIVATEITEMSEDIT = 'RyanPrivateItemsEdit';
    static readonly STATEEDIT = 'StateEdit';
    static readonly STATEVIEW = 'StateView';
    //static readonly TEAMSETUPVIEW = 'TeamSetupsView';
    // static readonly TEAMSETUPSEDIT = 'TeamSetupsEdit';
    //static readonly USERGROUPSEDIT = 'UserGroupsEdit';
    //static readonly USERGROUPSVIEW = 'UserGroupsView';
    static readonly USERSEDIT = 'UsersEdit';
    static readonly USERSVIEW = 'UsersView';
    static readonly SYSTEMSEARCHESEDIT = 'EditSystemSearches';
    // static readonly EDITCOMPANYSETUP = 'EditCompanySetups';
    static readonly FINANCIALANALYST = 'FinancialAnalyst';
    //static readonly COMPLIANCEFEATURESVIEW = 'ComplianceFeaturesView';
    static readonly COMPLIANCESETUPSVIEW = 'ComplianceSetupsView';
    static readonly COMPLIANCESETUPSEDIT = 'ComplianceSetupsEdit';
    static readonly ALLOWDATAIMPORTS = 'AllowDataImports';
    // static readonly ALLOWMOVECOPY = 'AllowMoveCopy';
    static readonly ADMINOPERATIONSVIEW = 'AdminOperationsView';
    static readonly ADMINOPERATIONSEDIT = 'AdminOperationsEdit';
    // static readonly AGENTINFORMATIONVIEW = 'AgentInformationView';
    // static readonly AGENTINFORMATIONEDIT = 'AgentInformationEdit';
    static readonly FORMSUNDERDEVELOPMENTVIEW = 'FormsUnderDevelopmentView';
    //static readonly COMPANYDATAIMPORTSVIEW = 'CompanyDataImportsView';
    static readonly USERREPORTSALLOW = 'UserReportsAllow';
    static readonly TAXRATESETUP = 'TaxRateSetup';
    static readonly INSTANCEADMIN = 'InstanceAdmin';
    static readonly ALLOWCOPYMOVEINSTANCE = 'AllowCopyMoveInstance';
    static readonly SUPPORT = 'Support';
    static readonly ALLOWSUPPORTESCALATIONREPORT = 'AllowSupportEscalationReport';
    static readonly ALLOWMANAGESYSTEM = 'AllowManageSystem';
    static readonly LICENSEDDOCUMENTSERVICES = 'LicensedDocumentServices';
    static readonly MANAGEAPIKEYS = 'ManageAPIKeys';
}

export class InstanceRights {
    static readonly ADDNEWCOMPANY = 'AddNewCompany';
    // static readonly ASSESSOREDIT = 'AssessorEdit';
    // static readonly ASSESSORVIEW = 'AssessorView';
    // static readonly COLLECTOREDIT = 'CollectorEdit';
    // static readonly COLLECTORVIEW = 'CollectorView';
    // static readonly COMMENTSEDIT = 'CommentsEdit';
    // static readonly COMMENTSVIEW = 'CommentsView';
    // static readonly COMPANYVIEW = 'CompanyView';
    static readonly CONTACTEDIT = 'ContactEdit';
    static readonly CONTACTVIEW = 'ContactView';
    static readonly MANAGECONSULTINGENGAGEMENT = 'ManageConsultingEngagement';
    // static readonly CONTACTROLEEDIT = 'ContactRoleEdit';
    // static readonly CONTACTROLEVIEW = 'ContactRoleView';
    // static readonly PROPCHAREDIT = 'PropCharEdit';
    // static readonly PROPCHARVIEW = 'PropCharView';
    // static readonly RYANPRIVATEITEMSVIEW = 'RyanPrivateItemsView';
    // static readonly RYANPRIVATEITEMSEDIT = 'RyanPrivateItemsEdit';
    // static readonly STATEEDIT = 'StateEdit';
    // static readonly STATEVIEW = 'StateView';
    static readonly TEAMSETUPSVIEW = 'TeamSetupsView';
    static readonly TEAMSETUPSEDIT = 'TeamSetupsEdit';
    // static readonly USERGROUPSEDIT = 'UserGroupsEdit';
    // static readonly USERGROUPSVIEW = 'UserGroupsView';
    // static readonly USERSEDIT = 'UsersEdit';
    // static readonly USERSVIEW = 'UsersView';
    // static readonly SYSTEMSEARCHESEDIT = 'EditSystemSearches';
    static readonly EDITCOMPANYSETUP = 'EditCompanySetups';
    // static readonly FINANCIALANALYST = 'FinancialAnalyst';
    static readonly COMPLIANCEFEATURESVIEW = 'ComplianceFeaturesView';
    // static readonly COMPLIANCESETUPSVIEW = 'ComplianceSetupsView';
    // static readonly COMPLIANCESETUPSEDIT = 'ComplianceSetupsEdit';
    // static readonly ALLOWDATAIMPORTS = 'AllowDataImports';
    static readonly ALLOWMOVECOPY = 'AllowMoveCopy';
    // static readonly ADMINOPERATIONSVIEW = 'AdminOperationsView';
    // static readonly ADMINOPERATIONSEDIT = 'AdminOperationsEdit';
    static readonly AGENTINFORMATIONVIEW = 'AgentInformationView';
    static readonly AGENTINFORMATIONEDIT = 'AgentInformationEdit';
    // static readonly FORMSUNDERDEVELOPMENTVIEW = 'FormsUnderDevelopmentView';
    static readonly COMPANYDATAIMPORTSVIEW = 'CompanyDataImportsView';
    // static readonly USERREPORTSALLOW = 'UserReportsAllow';
    // static readonly TAXRATESETUP = 'TaxRateSetup';
    static readonly PRIVATEITEMSVIEW = 'PrivateItemsView';
    static readonly PRIVATEITEMSEDIT = 'PrivateItemsEdit';
    static readonly MANAGEUSERSETUP = 'ManageUserSetup';
    static readonly VALUATIONTEMPLATEEDIT = 'ValuationTemplateEdit';
    static readonly VALUATIONTEMPLATEVIEW = 'ValuationTemplateView';
    static readonly ALLOWTAXFEEDS = 'TaxFeeds';
    static readonly INSTANCEDATAIMPORTS = 'InstanceDataImports';
}

@Injectable({ providedIn: 'root' })
export class RestrictService {
    constructor(
        private readonly _accountService: AccountService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _userRepository: UserRepository,
        private readonly _consultingEngagementRepository: ConsultingEngagementRepository,
        private readonly _navigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _entityPermissionService: EntityPermissionService
    ) {
    }

    Roles: Roles = Roles;
    InstanceRights: InstanceRights = InstanceRights;
    PermissionFlags = PermissionFlags; // todo - still needed for old js files, remove once all are upgraded
    EntityTypes = EntityType;

    private _permissionsCache: { [key in EntityTypeIds]?: Core.PermissionModel[] } = {
        [EntityTypeIds.SITE]: [],
        [EntityTypeIds.COMPANY]: []
    };

    private _newPermissionsCache: { [key in EntityTypeIds]?: Core.BulkPermissionCheckResult[] } = {
        [EntityTypeIds.SITE]: [],
        [EntityTypeIds.COMPANY]: []
    };

    // TODO - there is a newer permission check endpoint, once all the permissions are moved to the new endpoint, the second param can be removed
    async hasPermission(restrictionData: RestrictData, newEndpoint = false): Promise<boolean> {
        //This defaults to true so the logic of checking the API response will work if there are no application roles to check (aka if they skip block 1)
        let enableElement = true;

        // BLOCK #1
        //Checks the application-wide roles, if provided, and gets them from _user
        if (restrictionData.roles && restrictionData.roles.length > 0) {
            enableElement = _.some(restrictionData.roles, this.isInRole.bind(this));
        }

        // BLOCK #1A
        //Checks the instance rights, if provided, and gets them from _user
        if (restrictionData.instanceRights !== undefined && restrictionData.instanceRights.length > 0) {
            enableElement = restrictionData.instanceRights.some(right => {
                return this.hasInstanceRight(right, restrictionData.explicitInstanceId);
            });
        }

        //BLOCK #2
        //Grabs the entity specific permissions
        //Might want cache this
        if (restrictionData.entityId !== undefined && restrictionData.isCompany !== undefined) {
            try {
                const entityTypeId = restrictionData.isCompany ? EntityTypeIds.COMPANY : EntityTypeIds.SITE;
                let entityPermissionCheckResult: boolean;
                if (newEndpoint) {
                    entityPermissionCheckResult = await this._newCheckCompanyOrSitePermission(restrictionData.entityId, restrictionData.flag, entityTypeId);
                } else {
                    entityPermissionCheckResult = await this._checkCompanyOrSitePermission(restrictionData.entityId, restrictionData.flag, entityTypeId);
                }

                enableElement = restrictionData.rolesOrEntity
                    ? enableElement || entityPermissionCheckResult
                    : enableElement && entityPermissionCheckResult;
            } catch (err) {
                return Promise.reject();
            }
        }

        return enableElement;
    }

    isInRole(role: string): boolean {
        return this._accountService.userData && this._accountService.userData.globalRoles.indexOf(role) >= 0;
    }

    isInRoles(roles) {
        return roles.every(x => this.isInRole(x));
    }

    isNotInRole(role: string): boolean {
        return !this.isInRole(role);
    }

    isNotInRoles(roles: string[]): boolean {
        return roles.every(x => this.isNotInRole(x));
    }

    async isConsultantUser(companyId: number): Promise<boolean> {
        return await lastValueFrom(this._consultingEngagementRepository.isConsultantUser(companyId));
    }

    hasInstanceRight(right: string, instanceId?: number): boolean {
        const instanceIdToCheck = instanceId ? instanceId : this._userInstanceService.getSelectedInstance().instanceId;

        //all instances selected - no way to check for instance right
        if (instanceIdToCheck > -1) {
            const selectedInstance =
                this._accountService.userData &&
                this._accountService.userData.instanceRights.filter(i => i.instanceId === instanceIdToCheck);
            //make sure selected instance has rights
            if (selectedInstance.length) {
                return selectedInstance[0].rights.filter(r => r.name === right).length === 1;
            }
        }

        return false;
    }

    hasNoInstanceRight(right: string): boolean {
        return !this.hasInstanceRight(right);
    }

    hasInstanceRights(rights: string[]): boolean {
        return rights.every(x => this.hasInstanceRight(x));
    }

    hasNoInstanceRights(rights: string[]): boolean {
        return rights.every(x => this.hasNoInstanceRight(x));
    }

    hasAnyInstancePrivate(): boolean {
        if (this._userInstanceService.getSelectedInstance().instanceId === -1) {
            return this._accountService.userData.instanceMembership.every(x => {
                return (
                    this.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, x.instanceId) ||
                    this.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, x.instanceId)
                );
            });
        } else {
            return this.hasInstanceRights([InstanceRights.PRIVATEITEMSEDIT, InstanceRights.PRIVATEITEMSVIEW]);
        }
    }

    hasSitePermission(id: string | number, flag: Core.AccessRightsEnum): Promise<boolean> {
        return this._checkCompanyOrSitePermission(id, flag, EntityTypeIds.SITE);
    }

    hasCompanyPermission(id: string | number, flag: Core.AccessRightsEnum): Promise<boolean> {
        return this._checkCompanyOrSitePermission(id, flag, EntityTypeIds.COMPANY);
    }

    getRestrictionDataForEntity(entity, flag: Core.AccessRightsEnum): RestrictData {
        const restrictionData = {
            isCompany: false,
            entityId: undefined,
            flag: undefined,
            roles: undefined
        } as RestrictData;

        switch (entity.type) {
            case 'parcel':
                restrictionData.entityId = entity.id;
                restrictionData.flag = flag;
                restrictionData.entityId = +this._navigationServiceHandler.getQuerystringParam('siteId');
                break;
            case 'state':
                restrictionData.roles = [Roles.STATEEDIT, Roles.STATEVIEW];
                break;
            case 'assessor':
                restrictionData.roles = [Roles.ASSESSOREDIT, Roles.ASSESSORVIEW];
                break;
            case 'collector':
                restrictionData.roles = [Roles.COLLECTOREDIT, Roles.COLLECTORVIEW];
                break;
            case 'company':
                restrictionData.isCompany = true;
                break;
        }

        return restrictionData;
    }

    private async _newCheckCompanyOrSitePermission(id: string | number, flag: Core.AccessRightsEnum, entityTypeId: EntityTypeIds): Promise<boolean> {
        if (flag === undefined) {
            flag = Core.AccessRightsEnum.Read;
        }

        const cache = this._newPermissionsCache[entityTypeId];

        const entityId = typeof id === 'string' ? parseInt(id) : id;
        let entityPermission = cache.find(x => x.entityID === entityId);

        if (!entityPermission || this._accountService.userData === null) {
            try {
                const bulkCheckRequest = {
                    entities: [{ entityID: entityId, entityTypeID: entityTypeId }],
                    requestedAccessType: flag
                } as Core.BulkPermissionCheckRequest;

                const results = await lastValueFrom(this._userRepository.multiEntityCheck(bulkCheckRequest));
                entityPermission = results[0];

                // adding the results to the cache
                cache.push(entityPermission);

                return entityPermission.isAuthorized;
            } catch (err) {
                console.log('hasCompanyPermission - catch, returning err', err);

            }
        } else {
            return entityPermission.isAuthorized;
        }
    }

    private async _checkCompanyOrSitePermission(id: string | number, flag: Core.AccessRightsEnum, entityTypeId: EntityTypeIds): Promise<boolean> {
        if (flag === undefined) {
            flag = Core.AccessRightsEnum.Read;
        }

        let cache = this._permissionsCache[entityTypeId];

        const entityId = typeof id === 'string' ? parseInt(id) : id;
        let entityPermission = cache.find(x => x.entityID === entityId);
        let permissionUrl: string = `/api/User/${this._accountService.userData.id}`;

        switch (entityTypeId) {
            case EntityTypeIds.COMPANY:
                permissionUrl = `${permissionUrl}/company/${entityId}`;
                break;
            case EntityTypeIds.SITE:
                permissionUrl = `${permissionUrl}/checksite/${entityId}`;
                break;
        }

        if (!entityPermission || this._accountService.userData === null) {
            try {
                const results = await this._entityPermissionService.getEntityPermission(entityTypeId, entityId, permissionUrl);

                // adding the results to the cache
                cache = [...cache, ...results];

                // try to get the highest permission level here
                entityPermission = results.find(x => x.entityID === entityId && x.writeAllowed);
                if (!entityPermission) {
                    entityPermission = results.find(x => x.entityID === entityId && x.readAllowed);
                }

                return this._checkPermission(entityPermission, flag);
            } catch (err) {
                console.log('hasCompanyPermission - catch, returning err', err);

            }
        } else {
            return this._checkPermission(entityPermission, flag);
        }
    }

    // checkPermission: returns true if the given permission object
    // has the permission indicated by the requested flag
    private _checkPermission(p, flag) {
        if (!p) {
            return false;
        }
        let allowed = false;

        switch (flag) {
            case Core.AccessRightsEnum.Read: {
                allowed = p.readAllowed || p.writeAllowed;
                break;
            }
            case Core.AccessRightsEnum.Write: {
                allowed = p.writeAllowed;
                break;
            }
            // case PermissionFlags.READ_INCLUDE_ALL:
            // {
            //     allowed = p.readAllowed && p.includeAllChildRecords;
            //     break;
            // }
            // case PermissionFlags.WRITE_INCLUDE_ALL:
            // {
            //     allowed = p.writeAllowed && p.includeAllChildRecords;
            //     break;
            // }
        }

        return allowed;
    }
}
