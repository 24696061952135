import { HelpContent } from '../../../../../UI-Lib/Help-Tooltip';

export const RETURN_PREVIEW_FORM_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'return-preview-form-list.edit-reports',
        tooltipText: 'Edit reports'
    },
    {
        helpContentId: 'return-preview-form-list.forms-reports',
        tooltipText: 'Select a form or report from the list to preview it.',
        hasIcon: true
    },
    {
        helpContentId: 'return-preview-form-list.forms-reports-no-returns',
        tooltipText: 'No returns selected.',
        hasIcon: true
    },
    {
        helpContentId: 'return-preview-form-list.forms-reports-edit',
        tooltipText: 'Check or uncheck reports to be included with each form.',
        hasIcon: true
    },
    {
        helpContentId: 'return-preview-form-list.edit-reports-no-returns',
        tooltipText: 'No returns selected.'
    },
    {
        helpContentId: 'return-preview-form-list.download',
        tooltipText: 'Download forms and reports.'
    },
    {
        helpContentId: 'return-preview-form-list.edit-forms',
        tooltipText: 'Edit forms'
    },
    {
        helpContentId: 'return-preview-form-list.preview-form',
        tooltipText: 'Preview form'
    },
    {
        helpContentId: 'return-preview-form-list.preview-form-has-override',
        tooltipText: 'Preview form (contains overrides).'
    },
    {
        helpContentId: 'return-preview-form-list.preview-report',
        tooltipText: 'Preview report'
    },
    {
        helpContentId: 'return-preview-form-list.preview-excel-report',
        tooltipText: 'Preview Excel report.  The report will be displayed as PDF but will download in Excel format.'
    },
    {
        helpContentId: 'return-preview-form-list.primary',
        tooltipText: 'Primary form for the return.  When there are multiple forms, only one is primary.'
    },
    {
        helpContentId: 'return-preview-form-list.return',
        tooltipText: 'Return'
    },
    {
        helpContentId: 'return-preview-form-list.return-forms-custom',
        tooltipText: 'Return (using different forms than the default setup).'
    },
    {
        helpContentId: 'return-preview-form-list.orphan-supplemental',
        tooltipText: 'Supplemental forms cannot be chosen without their parent form revision.'
    },
    {
        helpContentId: 'return-preview-form-list.unchanged',
        tooltipText: 'No change has been made.'
    },
    {
        helpContentId: 'return-preview-form-list.view-form-setup',
        tooltipText: 'View form setup.'
    },
    {
        helpContentId: 'return-preview-form-list.priorReturnUsedAnotherForm',
        tooltipText: 'The prior year return cannot be used to detect year over year asset changes because the prior year form is different. Please configure the filing batch to use the company asset list for change detection.'
    },
    {
        helpContentId: 'return-preview-form-list.electronic-filing-is-supported',
        tooltipText: 'Electronic filing of this form is supported.'
    }
];
