import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AssessmentClassService {
    private readonly _baseUrl = 'api/assessmentclass';

    constructor(private readonly _http: HttpClient) {}

    getList(stateId: number, assessorId: number): Promise< Weissman.Model.Assessments.AssessmentClass[]> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/state/${stateId}/assessor/${assessorId}`)) as Promise<Weissman.Model.Assessments.AssessmentClass[]>
    }

    getAssessmentComponents(): Promise< Weissman.Model.Assessments.AssessmentComponent[]> {
        return lastValueFrom(this._http.get(`/api/assessmentcomponent`)) as Promise<Weissman.Model.Assessments.AssessmentComponent[]>
    }

    getParcelsAffectedByRatio(assessmentClassRatioId: number) {
        const params  = new HttpParams()
            .set('assessmentClassRatioID', assessmentClassRatioId.toString());

        return lastValueFrom(this._http.get('/api/assessmentclassratio', { params }))
    }

    create(assessmentClass: Weissman.Model.Assessments.AssessmentClass) {
        return lastValueFrom(this._http.post(this._baseUrl, assessmentClass));
    }

    update(assessmentClass: Weissman.Model.Assessments.AssessmentClass) {
        return lastValueFrom(this._http.put(this._baseUrl, assessmentClass));
    }

    deleteClass(assessmentClassId: number) {
        return lastValueFrom(this._http.delete(`${this._baseUrl}/${assessmentClassId}`));
    }
}
