(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('StyleButtonsController', StyleButtonsController);

	StyleButtonsController.$inject = [];

	function StyleButtonsController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
		var vm = this;
		vm.title = 'StyleButtonsController';

		activate();

		////////////////

		function activate() {}
	}
})();
