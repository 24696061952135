(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.factory('plainEnglishService', plainEnglishService);

	plainEnglishService.$inject = ['actionViewService', 'actionViewPersistenceService', 'searchOperators'];

	function plainEnglishService(actionViewService, actionViewPersistenceService, searchOperators) {
		var service = {
			getForFilter: getForFilter
		};
		return service;

		////////////////

		function getForFilter(filter, filterColumns) {
			var str = '';

			if (!filter.taskTypeName || filter.taskTypeName === 'All') {
				str += 'All ';
			}

			str += getShowText();
			str += getTaskTypeText();
			str += ' ' + getWhereText();
			str += ' ' + getThatAreText();

			if (filter.ThatAreChoice === 4) {
				str += getIncludeText();
				str += getCompletedDateText();
			}

			str += getDateText();
			str += getUrgencyText();

			if (filter.IncludeUnscheduled) {
				str += filter.OnlyUnscheduled ? ' AND only' : ' OR';
				str += ' Unscheduled';
			}

			if (filter.UseLastActivityFilter && (_.isNumber(filter.LastActivityTo) || _.isNumber(filter.LastActivityFrom))) {
				str += getLastActivity();
			}

			var columnsWithValue = _.filter(filterColumns, function (column) {
				return _.chain(column.or)
					.map(function(or) {
						return or.value || (or.operator && (or.operator.name === 'blank' || or.operator.name === 'notBlank' || or.operator.name === 'isNA'));
					})
					.compact()
					.value()
					.length;
			});

			if (filter.filters && filter.filters.length && actionViewPersistenceService.drilldownMode) {
				str += getFilterText(filter.filters);
			} else if (columnsWithValue.length) {
				str += getFilterText(columnsWithValue)
			}

			return str;


			// Functions for getForFilter()
			function getShowText() {
				switch (filter.ShowChoice) {
					case 1:
						return '';

					case 2:
						return 'My';

					case 3:
						return "My team's";

					case 4:
						return getUserTeamText(filter.ShowUserTeams);

					case 5:
						return getTeamText(filter.ShowTeams)

					default:
						return '';
				}
			}

			function getUserTeamText(userTeams) {
				userTeams = _.filter(userTeams, 'userID');

				if (!userTeams.length) {
					return "Specific Individual's";
				}

				return _.reduce(userTeams, function (str, userTeam, i) {

					if (userTeam.user) {
						str += userTeam.user.firstName + ' ' + userTeam.user.lastName;
					} else {
						str += userTeam.name;
					}

					if (i === userTeams.length - 2) {
						str += ' and ';
					} else if (i === userTeams.length - 1) {
						str += "'s"
					} else {
						str += ', ';
					}

					return str;
				}, '');
			}

			function getTeamText(teams) {
				var teams = _.filter(teams, 'teamID');

				if (!teams.length) {
					return "Specific team's";
				}

				return _.reduce(teams, function (str, team, i) {
					str += team.team ? team.team.name : team.name;

					if (i === teams.length - 2) {
						str += ' and ';
					} else if (i === teams.length - 1) {
						str += "'s"
					} else {
						str += ', ';
					}

					return str;
				}, '');
			}

			function getTaskTypeText() {
				if (filter.taskTypeName === 'All' || filter.taskTypeName === undefined) {
					return '';
				} else {
					return ' <strong>' + filter.taskTypeName + '</strong>'
				}
			}

			function getWhereText() {
				switch (filter.WhereChoice) {
					case 1:
						return 'assigned tasks';
					case 2:
						return 'tasks where ultimately responsible';
					case 3:
						return 'assigned tasks or tasks where ultimately responsible';
					case 4:
						return 'tasks for companies where account handler';
					case 5: 
						return 'tasks for companies where Compliance Returns PP Resp';
					default:
						return 'Tasks';
				}
			}

			function getThatAreText() {
				var str = 'that are ';
				switch (filter.ThatAreChoice) {
					case 1:
						return str + 'READY';
					case 2:
						return str + 'NOT READY';
					case 3:
						return str + 'READY or NOT READY'
					case 4:
						return str + '';
					default:
						return '';
				}
			}

			function getIncludeText() {
				if (filter.includeSkipped && filter.onlySkipped) {
					str = 'skipped';
				} else {
					str = 'completed';

					if(filter.includeSkipped) {
						str += ' OR skipped';
					}
				}

				switch (filter.IncludeChoice) {
					case 1:
						return str;

					case 2:
						return str + ' by me';

					case 3:
						return str + ' by my team';

					case 4:
						var includeUserTeams = _.filter(filter.IncludeUserTeams, 'userID');
						str += ' by ';

						if (!includeUserTeams.length) {
							return str + "a Specific Individual";
						}

						return str += _.reduce(includeUserTeams, function (str, userTeam, i) {
							if (userTeam.user) {
								str += userTeam.user.firstName + ' ' + userTeam.user.lastName;
							} else {
								str += userTeam.name;
							}

							if (i === includeUserTeams.length - 2) {
								str += ' and ';
							} else if (i !== includeUserTeams.length - 1) {
								str += ', ';
							}

							return str;
						}, '');

					case 5:
						var includeTeams = _.filter(filter.IncludeTeams, 'teamID');
						str += ' by ';

						if (!includeTeams.length) {
							return str + "a Specific Team";
						}

						return str + _.reduce(includeTeams, function (str, team, i) {
							str += team.team ? team.team.name : team.name;

							if (i === includeTeams.length - 2) {
								str += ' and ';
							} else if (i !== includeTeams.length - 1) {
								str += ', ';
							}

							return str;
						}, '');

					default:
						return '';
				}
			}

			function getCompletedDateText() {
				var str = '';

				if(filter.CompletedChoice == 0) {
					if (filter.CompletedDateFrom) {
						str += ' on or after ' + moment.utc(filter.CompletedDateFrom).format('M/D/YYYY') + ' '
					}
					if (filter.CompletedDateTo) {
						if (filter.CompletedDateFrom) {
							str += ' and';
						}
	
						str += ' by ' + moment.utc(filter.CompletedDateTo).format('M/D/YYYY');
					}
				} else if(filter.CompletedChoice == 1 ) {
					if (filter.CompletedWithinDays) {
						str += ' within the next ' + filter.CompletedWithinDays + ' days';
					}
				}

				return str;
			}

			function getDateText() {

				if (filter.OnlyUnscheduled) {
					return '';
				}

				switch (filter.DateTimeChoice) {
					case 1:
						return '';

					case 2:
						if (!filter.DueWithinDays) {
							return '';
						}

						return ' and due within the next ' + filter.DueWithinDays + ' days';

					case 3:
						var str = '';

						if (filter.DueDateFrom && moment.utc(filter.DueDateFrom).isAfter(moment.utc(new Date()).subtract(40, 'years'), 'year')) {
							str += ' and due on or after ' + moment.utc(filter.DueDateFrom).format('M/D/YYYY') + ' '
						}
						if (filter.DueDateTo && moment.utc(filter.DueDateTo).isBefore(moment.utc(new Date()).add(30, 'years'), 'year')) {
							str += ' and due by ' + moment.utc(filter.DueDateTo).format('M/D/YYYY');
						}

						return str;

					default:
						return '';
				}
			}

			function getUrgencyText() {
				if (!filter.UseUrgencyFilter) {
					return '';
				}

				var urgencies = actionViewService.getUrgencies();
				var urgency = _.find(urgencies, { id: filter.UrgencyChoice });

				return ' with an urgency of ' + urgency.display;
			}

			function getLastActivity() {
				var str = ', and last activity is ';

				if (filter.LastActivityTo === 0 && filter.LastActivityFrom === 0) {
					return str + 'today';
				}

				if (filter.LastActivityTo === null) {
					str += 'greater than ' + filter.LastActivityFrom
				} else if (filter.LastActivityFrom === null) {
					str += 'up to ' + filter.LastActivityTo;
				} else if (filter.LastActivityTo === filter.LastActivityFrom) {
					str += filter.LastActivityTo;
				} else if (filter.LastActivityTo === 9999) {
					str += filter.LastActivityFrom + '+';
				} else {
					str += filter.LastActivityFrom + ' to ' + filter.LastActivityTo;
				}

				str += ' days ago';

				return str;
			}

			function getFilterText(filters) {
				var str = '; further limited by ';
				var operators = searchOperators.displayNameMap();

				str += _.reduce(filters, function (str, column, i) {
					str += i > 0 ? ' AND ' : '';
					str += column.resultColumnName || column.resultsColumnName;

					str += _.reduce(column.or, function (str, or, i) {
						var operator;

						if (_.isObject(or.operator)) {
							operator = or.operator;
						} else {
							operator = operators[or.operator]
							operator.name = or.operator;
						}

						str += i > 0 ? ' OR ' : ' ';
						if (operator.displayName) {
							str += operator.name === 'isNA' ? _.lowerFirst(operator.displayName) : operator.displayName.toLowerCase();

							if (operator.name !== 'blank' && operator.name !== 'notBlank' && operator.name !== 'nullable' && operator.name !== 'isNA') {
								str += ' <em>';

								if(_.isDate(or.value)) {
									str += moment.utc(or.value).format('M/D/YYYY');
								} else {
									str += or.value;
								}

								if(operator.name === 'withinDate') {
									str += ' days';
								}

								str += '</em>';
							}

						}

						return str;
					}, '');

					return str;
				}, '');

				return str;
			}
		}
	}
})();
