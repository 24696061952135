import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { LogoutMessageComponent } from './logoutMessage.component';
import { ManageMenuComponent } from './ManageMenu/manageMenu.component';
import { NavigationModalsModule } from '../Navigation/User-Save/Modals/navigationModals.module';
import { TaxMenuComponent } from './Tax-Menu/taxMenu.component';
import { TempBannerComponent } from './tempBanner.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        UIRouterUpgradeModule,
        NavigationModalsModule
    ],
    declarations: [
        LogoutMessageComponent,
        ManageMenuComponent,
        TaxMenuComponent,
        TempBannerComponent
    ],
    exports: [
        LogoutMessageComponent,
        ManageMenuComponent,
        TempBannerComponent
    ]
})
export class LayoutModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('logoutMessage', LogoutMessageComponent);
        hybridAdapterUtility.downgradeNg2Component('tempBanner', TempBannerComponent);
        hybridAdapterUtility.downgradeNg2Component('manageMenu', ManageMenuComponent);
        hybridAdapterUtility.downgradeNg2Component('taxMenu', TaxMenuComponent);
    }
}
