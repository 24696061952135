import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from 'rxjs';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import * as _ from 'lodash';
import { TaxRateGridService } from '../tax.rate.grid.service';
import { CommandCenterSearchModel, QcStateCount, TaxAuthoritySelectionOptions,
         TaxAuthorityBulkUpdateRequest, TaxAuthorityUpdateResults, TaxAuthorityToUpdate, TaxRateDatatExportModel } from '../tax.rate.model';
import { Assessor } from '../../Assessor-Collector/assessor.model';
import { Collector } from '../../Assessor-Collector/collector.model';
import { HttpResponse, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TaxRateCommandCenterService extends TaxRateGridService {
    private _taxRateCommandCenterDataSubject = new BehaviorSubject<any>(false);

    constructor(private sdHttp: SDHttpService, httpClient: HttpClient) {
        super(httpClient);
    }

    get taxRateCommandCenterData$(): Observable<void> {
        return this._taxRateCommandCenterDataSubject.asObservable();
    }

    async loadTaxAuthorities(): Promise<void> {
        await this._loadTaxAuthorities();

        this._taxRateCommandCenterDataSubject.next(true);
    }

    async loadTaxRateAreas(): Promise<void> {
        await this._loadTaxRateAreas();

        this._taxRateCommandCenterDataSubject.next(true);
    }

    private async _loadTaxAuthorities(): Promise<void> {
        // this.isInitialized = false;
        // const data =  await this.getStub();
        // this.taxAuthorities = data.taxAuthorities;
        // this.taxRateAreas = data.taxRateAreas;
        // this.isInitialized = true;
    }

    private async _loadTaxRateAreas(): Promise<void> {
        // this.isInitialized = false;
        // const data =  await this.getStub();
        // this.taxAuthorities = data.taxAuthorities;
        // this.taxRateAreas = data.taxRateAreas;
        // this.isInitialized = true;
    }

    async getList(searchParams: CommandCenterSearchModel): Promise<any> {
        return this.sdHttp.post('/api/taxrate/commandcenter', searchParams);
    }

    async getPendingQcCounts(): Promise<QcStateCount[]> {
        return this.sdHttp.get('/api/TaxAuthority/GetPendingQcCountByState');
    }

    async getAssessors(stateId: number): Promise<Assessor[]> {
        return this.sdHttp.get(`/api/TaxRate/commandcenter/state/${stateId}/Assessors`);
    }

    async getCollectors(stateId: number, assessorId?: number): Promise<Collector[]> {
        let url = `/api/TaxRate/commandcenter/state/${stateId}/collectors`;

        if(assessorId) {
            url += `?assessorId=${assessorId}`
        }

        return this.sdHttp.get(url);
    }

    async getTaxAuthoritySelectionsForBulkUpdate(selectionOptions: TaxAuthoritySelectionOptions): Promise<TaxAuthorityToUpdate[]> {
        /*
        const selections: TaxAuthorityToUpdate[] = _.map(selectionOptions.selectedTaxAuthorityIds, (taId: number) : TaxAuthorityToUpdate => {
            return {
                taxAuthorityId: taId,
                name: `TaxAuthority ${taId}`,
                code: `TA-${taId}`
            }
        });
        return Promise.resolve(selections);
        */
        return this.sdHttp.post('/api/TaxAuthority/BulkUpdate/Selections', selectionOptions);
    }

    applyBulkUpdateToTaxAuthority(taxAuthorityId: number, request: TaxAuthorityBulkUpdateRequest ): Promise<TaxAuthorityUpdateResults> {
        /*
        return new Promise((resolve) => {
            setTimeout(function() {
                resolve({
                    billClusterCount: 2,
                    billCount: 4,
                    paymentCount: 6,
                    taxAuthorityCount: 1
                });
            }, 1000);
        });
        */
        return this.sdHttp.put(`/api/TaxAuthority/${taxAuthorityId}/BulkUpdate`, request);
    }

    exportTaxRateData(exportModel: TaxRateDatatExportModel): Promise<number> {
        return this.sdHttp.post('/api/taxrateservice/export', exportModel);
    }

    getTaxRateDataExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpGet(`/api/taxrateservice/export/${longRunningProcessId}`, options);
    }
}
