<div class="activity-summary-panel">
    <div class="text-center" style="margin-top: 50px;" *ngIf="dataLoading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <table style="width: 100%" *ngIf="!dataLoading">
        <thead></thead>
        <!-- Each item -->
        <tr *ngFor="let activity of activitySummaryItems" (click)="navigateToEntity(activity)" class="activity-summary-row" [ngStyle]="{'cursor': canNavigate(activity) ? 'pointer' : 'not-allowed'}">
            <!-- Icon -->
            <td>
                <span [class]="getTaskIconColor(activity.taskSeriesTypeID)">
                    <i [class]="getTaskIcon(activity.taskSeriesTypeID)"></i>
                </span>
            </td>

            <!-- Date -->
            <td>
                <span *ngIf="activity.deliverableDueDate"
                      [attr.title]="(activity.deliverableDueDateHasTime ? activity.deliverableDueDate : null) | weissmanDateTimeFormat: true">
                    {{activity.deliverableDueDate | weissmanDateFormat: true }}
                </span>
                <span *ngIf="!activity.deliverableDueDate">Pending</span>
            </td>

            <!-- Two liner at parcel level, Activity Name and Tax year+entity info -->
            <!-- Three liner at site level, add parcel number to the top -->
            <td>
                <div *ngIf="!isParcelLevel" style="font-weight: bold">{{ activity.parcelAcctNum }}</div>
                <div style="font-weight: bold">{{ activity.activityName }}</div>
                <div>{{ activity.entityText }}</div>
            </td>

            <!-- Two liner, Task status and then Assigned User -->
            <td style="white-space: nowrap;">
                <div><span style="margin-right: 24px;">Status:</span> <a style="cursor:pointer" (click)="launchTaskModal($event, activity.entityID, activity.entityTypeID)">{{ activity.taskSummary?.status }}</a></div>
                <div>
                    <span style="margin-right: 8px;">Assigned:</span>
                    <span [class.overridden]="activity.assignedUserOverridden">
                        {{ activity.deliverableTaskAssignedUser.firstName }} {{activity.deliverableTaskAssignedUser.lastName}}
                    </span>
                </div>
            </td>
        </tr>
    </table>
</div>
