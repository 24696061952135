import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { BillClusterService, RefundViewModel, TaxesViewModel } from './bill-cluster.service';
import { Refund } from './refund.model';

declare const _: any;
declare const moment: any;

const REFUND_ENTITY_TYPE_ID = 14;

@Component({
	selector: 'refund',
	templateUrl: './refund.component.html'
})
export class RefundComponent implements OnChanges {
	constructor(private billClusterService: BillClusterService,
                private taskService: TaskService,
                private toastr: ToastrService,
                private navigationEvent: AnnualDetailsNavigationEventService) {
		this.refundDeleted = new EventEmitter();
	}

	@Input() editState: AnnualDetailEditState;
	@Input() viewModel: RefundViewModel;
	@Input() isDocumentProcessing: boolean;
	@Input() taxesViewModel: TaxesViewModel;
	@Output() refundDeleted: EventEmitter<any>;

	refundEntityTypeId: number = REFUND_ENTITY_TYPE_ID;

	waitingOnServer: boolean;
	annualAssessments: Weissman.Model.Assessments.AnnualAssessment[];
	private _haveLoadedAssessments: boolean = false;

	async ngOnChanges(): Promise<void> {
		if(this.viewModel && !this._haveLoadedAssessments) {
			this._haveLoadedAssessments = true;
			await this.loadAnnualAssessmentsByAnnualYearID();
		}
	}

	async loadAnnualAssessmentsByAnnualYearID(): Promise<any> {
		this.annualAssessments = await this.billClusterService.getAnnaulAssessmentsByAnnualYearID(this.viewModel.model.annualYearID);

		//  if there is only one, make it default
		if (this.annualAssessments.length === 1 && !this.viewModel.model.annualAssessmentID){
			this.viewModel.model.annualAssessmentID = this.annualAssessments[0].annualAssessmentID;
		}
	}

	getRevisionDesc(): string {
		const yearRevision = _.find(this.annualAssessments, { annualAssessngonentID: this.viewModel.model.annualAssessmentID });

		if(yearRevision) {
			return yearRevision.revisionDesc;
		} else {
			console.warn('Cannot find year revision for the refund!');
			return '';
		}
	}

	refundAmountChanged(): void {
        this.setDirty();
		this.editState.staleAppealSavings = true;
		this.editState.refreshGrid = true;
	}


	deleteRefund(): void {
		const message: string = 'Confirm deleting refund';

		if (confirm(message)) {
			this.billClusterService.deleteRefund(this.viewModel.model.refundID)
				.then(() => {
					this.refundDeleted.emit();
                    this.navigationEvent.refreshActivityData();
				});
		}
	}

	launchTaskModal() {
		if (!this.editState.editMode || this.isDocumentProcessing) {
			this.taskService.launchTaskModal(this.viewModel.model.refundID, REFUND_ENTITY_TYPE_ID, true);

			return;
		}

		if (!this.editState.getDirty()) {
			this.launchTaskModalAndReload();

			return;
		}

		this.billClusterService.saveRefund(this.viewModel.model)
			.then((saveRefund: Refund) => {
				this.viewModel.model.refundRowVersion = saveRefund.refundRowVersion;

				if(!this.isDocumentProcessing) {
					this.taxesViewModel.resetEdit(true);
				}

				this.editState.setDirty(false);

				this.toastr.info('Refund changes autosaved');

				this.launchTaskModalAndReload();
			});
	}

	launchTaskModalAndReload() {
		this.taskService.launchTaskModal(this.viewModel.model.refundID, REFUND_ENTITY_TYPE_ID, false)
			.then(shouldReload => {
				if (!shouldReload) {
					return;
				}

                this.navigationEvent.refreshActivityData();

				this.billClusterService.getRefundViewModelByRefundID(this.viewModel.model.refundID)
					.then((refund) => {
						_.assign(this.viewModel.model, refund.model);

						this.viewModel.model.taskSummary = {};
						this.billClusterService.getEntityTaskSummaries(this.viewModel.model, REFUND_ENTITY_TYPE_ID, this.viewModel.model.refundID);

						if(!this.isDocumentProcessing) {
							this.taxesViewModel.resetEdit(true);
						}
					});
			});
	}

	makeRefundNegative() {
		this.viewModel.model.refundAmount = -Math.abs(this.viewModel.model.refundAmount);
	}

	getCommentModalData() {
		const commentModalData = new CommentModalData();
		commentModalData.entityID = this.viewModel.model.refundID;
		commentModalData.entityTypeID = REFUND_ENTITY_TYPE_ID;
		commentModalData.canEdit = this.viewModel.hasWritePermission;
		commentModalData.parcelID = this.viewModel.parcelID;
		commentModalData.parcelAcctNum = this.viewModel.parcelAcctNum;
		commentModalData.description = `${this.viewModel.year  } - ${  this.viewModel.tabTitle}`;
		commentModalData.year = this.viewModel.year.toString();

		return commentModalData;
	}

	getAttachmentModalData() {
		const attachmentModalData = new AttachmentModalData();

		attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
		attachmentModalData.belowParcelEntity.id = this.viewModel.model.refundID;
		attachmentModalData.belowParcelEntity.typeId = REFUND_ENTITY_TYPE_ID;
		attachmentModalData.belowParcelEntity.name = 'Refund';
		attachmentModalData.entityType = 'Refund';
		attachmentModalData.parentId = this.viewModel.parcelID;
		attachmentModalData.parentType = 'Parcel';
		attachmentModalData.entityName = this.viewModel.parcelAcctNum;
		attachmentModalData.entityDescription = `${this.viewModel.year  } - ${  this.viewModel.tabTitle}`;
		attachmentModalData.year = this.viewModel.year;

		return attachmentModalData;
	}

	setDirty() {
		this.editState.setDirty(true);
		this.editState.refreshGrid = true;
    }

    expectedDateChanged() {
        this.editState.refreshActivity = true;
        this.setDirty();
	}

	dateOverridden(field: string): boolean {
		const current = this.viewModel.model[field];
		const original = this.getOriginalDate(field);

		if (!current || !original) {
			return false;
		}

		return !moment(current).isSame(original);
	}

	maybeRevertToOriginalDate(field: string): void {
		if(!this.viewModel.model[field]) {
			this.viewModel.model[field] = this.getOriginalDate(field);
		}
	}

    undoDate(field: string) {
        this.viewModel.model[field] = this.getOriginalDate(field);
        this.setDirty();
    }

	private getOriginalDate(field: string): Date {
		const capitalizedField: string = field.charAt(0).toUpperCase() + field.slice(1);

		return this.viewModel.model[`original${  capitalizedField}`];
    }
}
