import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class AssessorCommandCenterRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private _baseUrl: string = '/api/assessorCommandCenter';

    getStates(): Observable<Core.StateModel[]> {
        return this.httpGet(`api/state/summary`);
    }

    getList(searchModel: Compliance.AssessorCommandCenterSearchModel): Observable<Compliance.QueryResultModel<Compliance.AssessorCommandCenterModel>> {
        return this.httpPost(`${this._baseUrl}/assessors`, searchModel);
    }

    getRowIds(searchModel: Compliance.AssessorCommandCenterSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`${this._baseUrl}/assessors/rowIds`, searchModel);
    }

    getDescriptorInfo(): Observable<Core.DescriptorMetadataModel> {
        return this.httpGet(`${this._baseUrl}/descriptorInfo`);
    }

    updateDeadline(updateModel: Compliance.AssessorCommandCenterDeadlineUpdateModel): Observable<Compliance.AssessorCommandCenterModel> {
        return this.httpPut(`${this._baseUrl}/deadline`, updateModel);
    }

    updateDescriptor(updateModel: Compliance.AssessorCommandCenterDescriptorUpdateModel): Observable<any> {
        return this.httpPut(`${this._baseUrl}/descriptor`, updateModel);
    }

    updateDefaultAddressType(updateModel: Compliance.AssessorCommandCenterDefaultAddressUpdateModel): Observable<Compliance.AssessorCommandCenterModel> {
        return this.httpPut(`${this._baseUrl}/defaultAddress`, updateModel);
    }

    refreshRow(updateModel: Compliance.AssessorCommandCenterDeadlineUpdateModel): Observable<Compliance.AssessorCommandCenterModel> {
        return this.httpPost(`${this._baseUrl}/assessor/${updateModel.assessorId}`, updateModel);
    }

    exportList(exportModel: Compliance.AssessorCommandCenterExportModel): Observable<any> {
        return this.httpPost(`${this._baseUrl}/export`, exportModel);
    }

    bulkUpdateList(updateModel: Compliance.AssessorCommandCenterBulkUpdateModel): Observable<Compliance.AssessorCommandCenterBulkUpdateResultModel> {
        return this.httpPut(`${this._baseUrl}/bulk`, updateModel);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpGet(`${this._baseUrl}/export/${longRunningProcessId}`, options);
    }
}
