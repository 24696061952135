import { Component, OnDestroy, OnInit } from '@angular/core';
import { AgGridEvent, ColumnApi, GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { AgGridOptionsBuilder } from '../Compliance/AgGrid';
import { ForecastingBudgetingService } from './forecasting.budgeting.service';
import { CompanyService } from '../Entity/Company/company.service';
import {
    BudgetTaxYearMissingSyncData,
    CompanyBudgetBasisEnum,
    ForecastBudgetAltAssessmentView,
    ForecastBudgetAvailableFiltersUI,
    ForecastBudgetBulkUpdateProgress,
    ForecastBudgetEditRequest,
    ForecastBudgetScreenModeEnum,
    ShowAssessmentsTaxesEnum
} from './forecasting.budgeting.model';
import { ForecastingBudgetingBulkUpdateModalComponent } from './forecasting.budgeting.bulk.update.modal.component';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { AddCompanyBudgetModalComponent } from './add.company.budget.modal.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../Compliance/AgGrid/ToolPanel/models';
import { InstanceRights, RestrictService } from '../Common/Permissions/restrict.service';
import { BusyIndicatorRef, BusyIndicatorService } from '../Busy-Indicator';
import { LongRunningProcessRepository } from '../Compliance/Repositories';
import { AccountService } from '../User/account.service';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { InstanceRepository } from '../Entity/Instance/instance.repository';
import { takeUntil } from 'rxjs/operators';

export type ForecastingBudgetingView = 'taxYear' | 'accrualAndCash';

@Component({
    selector: 'forecasting-budgeting-page',
    templateUrl: './forecasting.budgeting.page.component.html',
    styleUrls: ['./forecasting.budgeting.page.scss'],
    providers: [ForecastingBudgetingService]
})
export class ForecastingBudgetingPageComponent implements OnInit, OnDestroy {
    constructor(
        private modalService: BsModalService,
        private toastr: ToastrService,
        private messageBoxService: MessageBoxService,
        public forecastingBudgetingService: ForecastingBudgetingService,
        public companyService: CompanyService,
        private restrictService: RestrictService,
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private _busyIndicatorService: BusyIndicatorService,
        private longRunningProcessRepository: LongRunningProcessRepository,
        private accountService: AccountService,
        private instanceRepository: InstanceRepository
    ) {
        this._lrTypeMapping[Compliance.LongRunningProcessTypeEnum.SyncBudgets] = 'Syncing parcel budgets';
        this._lrTypeMapping[Compliance.LongRunningProcessTypeEnum.AddMissingForecastTaxYear] = 'Creating missing forecast tax years';
        this._lrTypeMapping[Compliance.LongRunningProcessTypeEnum.AddTaxYearAndAnnualBudget] = 'Creating missing budget tax years';
    }

    subscriptions: Subscription[];
    isMaximized: boolean = false;
    isInitialized: boolean = false;
    budgetsEnabled: boolean = false;
    hasEditPermission: boolean = false;
    showApplyButton: boolean = false;
    savingBudget: boolean = false;

    ForecastBudgetScreenModeEnum = ForecastBudgetScreenModeEnum;
    CompanyBudgetBasisEnum = CompanyBudgetBasisEnum;

    scopeEntityTypeId: number = Core.EntityTypes.Company;
    scopeEntityId: number = 0;

    taxYears: number[] = _.range(new Date().getFullYear() + 5, new Date().getFullYear() - 11);
    priorOptions: number[] = [1, 2, 3, 4];
    priorYears: number;

    availableBudgets: Core.AvailableCompanyBudget[] = [];

    ShowAssessmentsTaxesEnum = ShowAssessmentsTaxesEnum;
    ForecastBudgetAltAssessmentView = ForecastBudgetAltAssessmentView;

    availableFilters: ForecastBudgetAvailableFiltersUI = new ForecastBudgetAvailableFiltersUI();
    parentCompany: Core.NameIdPair;

    lrUpdateInProgress: boolean = false;

    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    bulkUpdateInProgress: boolean = false;
    bulkUpdateCanceled: boolean = false;
    bulkUpdateProgress: ForecastBudgetBulkUpdateProgress = new ForecastBudgetBulkUpdateProgress();
    bulkUpdateEntityType: string = '';

    LARGE_BULK_UPDATE_THRESHOLD: number = 100;

    filterModel: any;
    columnState: ColumnState[];
    columnApi: ColumnApi;

    gridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        singleClickEdit: true,
        suppressRowClickSelection: true,
        suppressMovableColumns: true,
        onGridColumnsChanged: () => {
            this.gridApi.sizeColumnsToFit();
        },
        onSelectionChanged: () => {
            this.forecastingBudgetingService.updateTotals();
        },
        onFilterChanged: () => {
            this.forecastingBudgetingService.updateTotals();
        },
        onCellEditingStarted: () => {
            this.forecastingBudgetingService.isEditingCell = true;
        },
        onCellEditingStopped: () => {
            this.forecastingBudgetingService.isEditingCell = false;
        }
    })
    .withContext(this)
    .withLoadingOverlay()
    .withSort()
    .withColumnResize()
    .withTextSelection()
    .withVerticalScrollBar()
    .withoutCellEditingStoppedOnGridLostFocus()
    .withColumnPinning()
    .build();

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {

            // Find the first dynamic column (which contains a number) and slice off the rest of the array.
            const dynamicColIdx = _.findIndex(columnsToReturn, col => /\d/.test(col.name));
            columnsToReturn = _.slice(columnsToReturn, 0, dynamicColIdx);

            const request = {
                columnFilters: [],
                sortColumns: [],
                columnsToReturn: columnsToReturn,
                ...this.forecastGridSearchModel
            } as any;

            const longRunningProcessId = await this.forecastingBudgetingService.exportToExcel(request);

            return { longRunningProcessId, longRunningProcessTypeId: Compliance.LongRunningProcessTypeEnum.ExportBudgetGridToExcel };
        },
        canCancel: true
    };

    private _busyRef: BusyIndicatorRef = null;
    private busyRefId: string;
    private longRunningProcessId: number;
    private _lrTypeMapping: { [key: number]: string } = {};
    private _destroy$: Subject<void> = new Subject();


    async ngOnInit() {
        this.subscriptions = [];
        this.lrUpdateInProgress = false;
        this.scopeEntityTypeId = Core.EntityTypes.Company;
        const parentCompanyId = +this.upgradeNavigationServiceHandler.getQuerystringParam('companyId');

        this.forecastingBudgetingService.tlCompany = await this.companyService.getTopCompanyForEntity(this.scopeEntityTypeId, parentCompanyId);
        console.log('tlCompany=', this.forecastingBudgetingService.tlCompany);
        console.log('isTLCompany=', this.isTLCompany);
        this.scopeEntityId = this.forecastingBudgetingService.tlCompany.companyID;

        this.forecastingBudgetingService.initServiceModel(this.scopeEntityTypeId, this.scopeEntityId);
        this.priorYears = this.filters.yearEnd - this.filters.yearBegin;

        await this.populateBudgetNameDropdown();

        const availableFilters = await this.forecastingBudgetingService.getAvailableFilters(this.scopeEntityTypeId, this.scopeEntityId);
        availableFilters.companies = _.sortBy(availableFilters.companies, company => company.id == this.forecastingBudgetingService.tlCompany.companyID || company.name.toLowerCase());
        _.assign(this.availableFilters,  availableFilters);

        this.parentCompany = _.find(this.availableFilters.companies, {id: parentCompanyId});

        const lrp = await this.companyService.checkOngoingAction(this.parentCompany.id);
        if (lrp) {
            this.longRunningProcessId = lrp.longRunningProcessId;
            this._showBusyIndicator(this._lrTypeMapping[lrp.processType], this.longRunningProcessId, this.accountService.userData.id == lrp.createdBy);
        }

        await this._setFiltersAndLoadData();

        this.isInitialized = true;
        this.subscriptions.push(this.forecastingBudgetingService.taxRatesPanelData$.subscribe((isData: boolean) => {
            if(isData) {
                this._setRowData();
            }
        }));

        const instanceId = await lastValueFrom(this.instanceRepository.getEntityInstanceId('company', this.parentCompany.id));
        this.hasEditPermission = this.restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, instanceId) &&
                                 await this.restrictService.hasCompanyPermission(this.parentCompany.id, Core.AccessRightsEnum.Write);
        this.budgetsEnabled = await this.forecastingBudgetingService.getAreBudgetsEnabled(this.scopeEntityTypeId, this.scopeEntityId);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        // I wanted to use the unsubscribe mixin, but piping destroy$ to takeUntil didn't work
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    get gridApi() {
        return this.forecastingBudgetingService.gridApi;
    }

    set gridApi(gridApi) {
        this.forecastingBudgetingService.gridApi = gridApi;
    }

    get filters() {
        return this.forecastingBudgetingService.filters;
    }

    set filters(filters) {
        this.forecastingBudgetingService.filters = filters;
    }

    get selectedBudget() {
        return this.forecastingBudgetingService.selectedBudget;
    }

    set selectedBudget(selectedBudget) {
        this.forecastingBudgetingService.selectedBudget = selectedBudget;
    }

    get forecastGridSearchModel() {
        return this.forecastingBudgetingService.forecastGridSearchModel;
    }

    set forecastGridSearchModel(forecastGridSearchModel) {
        this.forecastingBudgetingService.forecastGridSearchModel = forecastGridSearchModel;
    }

    get isTLCompany() {
        return( this.scopeEntityTypeId != Core.EntityTypes.Company ? false : this.forecastingBudgetingService.isTLCompany(this.scopeEntityId));
    }

    get missingSyncData() {
        return this.forecastingBudgetingService.missingSyncData;
    }

    get freezeButtonLabel() {
        return this.forecastingBudgetingService.freezeButtonLabel;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api;
        this.columnApi = event.columnApi;
    }

    toggleEditMode(isEdit: boolean) {
        this.forecastingBudgetingService.editMode = isEdit;
        this.refreshBulkUpdateState();
    }

    onSelectionChanged(event: AgGridEvent) {
        this.refreshBulkUpdateState();
    }

    refreshBulkUpdateState() {
        const isBulkUpdateVisible = this.forecastingBudgetingService.editMode && this.gridApi.getSelectedNodes().length > 0;
        this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
    }

    toggleMaximize(isMaximized: boolean): void {
        this.isMaximized = isMaximized;
    }

    goToParentCompany() {
        this.upgradeNavigationServiceHandler.go('company', {companyId: this.parentCompany.id});
    }

    async modeChanged(): Promise<void> {
        console.log(`New Mode: ${this.forecastingBudgetingService.selectedMode}`);
        this.forecastingBudgetingService.clearData();
        this.refreshBulkUpdateState();

        if ( this.forecastingBudgetingService.selectedMode === 'budget' ) {
            this.forecastGridSearchModel.mode = ForecastBudgetScreenModeEnum.TaxYearBudget;
            this.forecastGridSearchModel.showBudgetEntriesFor = ShowAssessmentsTaxesEnum.AssessmentsAndTaxes;
            this.priorYears = 1;
            if ( this.selectedBudget ) {
                this.filters.yearEnd = moment.utc(this.selectedBudget.fiscalPeriodBegin).year();
            }
            this.filters.yearBegin = this.filters.yearEnd - this.priorYears;

            // Switched to Budget mode.
            // Clear any old selection and populate Budget Name dropdown.

            await this.populateBudgetNameDropdown(this.selectedBudget ? this.selectedBudget.budgetName : undefined);
        } else {
            this.forecastGridSearchModel.mode = ForecastBudgetScreenModeEnum.Forecast;
            this.forecastGridSearchModel.showBudgetEntriesFor = ShowAssessmentsTaxesEnum.AssessmentsAndTaxes;
        }

        await this.loadNewColumnData();
        // TODO Re-retrieve grid content and missing/sync counts for new mode.
    }

    async populateBudgetNameDropdown(selectName?: string) : Promise<void> {
        if ( this.forecastingBudgetingService.selectedMode === 'budget' )
        {
            // Get availableBudgets for company from Get Available Company Budgets
            const availableBudgets = await this.forecastingBudgetingService.getAvailableCompanyBudgets(this.forecastingBudgetingService.tlCompany.companyID, false);
            this.availableBudgets = _.orderBy(availableBudgets, ['budgetDate', 'budgetName'], ['desc', 'asc']);

            if ( this.selectedBudget && selectName )
            {
                // Clear Budget selection if no longer present in dropdown.
                const stillPresent = _.some(this.availableBudgets, (entry) => {
                    return entry.budgetName === selectName;
                });

                if ( !stillPresent )
                {
                    this.selectedBudget = undefined;
                }
                else
                {
                    // Even though the budget is already set, we need to set it from the available budgets
                    // so that the dropdown will correctly set
                    this.selectedBudget = _.find(this.availableBudgets, {companyBudgetId: this.selectedBudget.companyBudgetId});
                }
            }
            else if ( selectName )
            {
                // Select the Budget for the given date, if present.
                this.selectedBudget = _.find(this.availableBudgets, (entry) => {
                    return entry.budgetName === selectName;
                });

                this.selectedBudgetChanged();
            }
            else if ( !selectName )
            {
                this.selectedBudget = undefined;
            }
        }
    }

    selectedBudgetChanged(): void {
        console.log(`Selected: ${this.selectedBudget.budgetName} - ${this.selectedBudget.companyBudgetId} - ${moment(this.selectedBudget.fiscalPeriodBegin).utc().format('MM/DD/YYYY')}..${moment(this.selectedBudget.fiscalPeriodEnd).utc().format('MM/DD/YYYY')}`);

        this.forecastGridSearchModel.companyBudgetId = this.selectedBudget.companyBudgetId;
        this.forecastGridSearchModel.budgetBasis = this.selectedBudget.budgetBasis;
        this.filters.yearEnd = moment.utc(this.selectedBudget.fiscalPeriodEnd).year();
        this.filters.yearBegin = this.filters.yearEnd - this.priorYears;
        if(this.selectedBudget.isFrozen) {
            this.forecastingBudgetingService.autoSyncBudgetToLatest = false;
        }

        this.taxYearsChanged();
    }

    selectedBudgetBasisChanged() {
        this.forecastGridSearchModel.budgetBasis = this.selectedBudget.budgetBasis;
        this.showApplyButton = true;
    }

    async saveCompanyBudget() {
        this.savingBudget = true;

        try {
            this.showApplyButton = false;
            await this.forecastingBudgetingService.saveCompanyBudget();
            this.toastr.info('Budget Basis Saved!');
            this.loadNewColumnData();
        } finally {
            this.savingBudget = false;
        }
    }

    addNewBudget(): void {
        const initialState = {
            tlCompany: this.forecastingBudgetingService.tlCompany
        };
        const modalRef = this.modalService.show(AddCompanyBudgetModalComponent, {initialState, class: 'modal-sd', ignoreBackdropClick: true});

        modalRef.content.onClose = this.newBudgetAdded.bind(this);
    }

    async newBudgetAdded(result?: Core.CompanyBudgetDTO) : Promise<void> {
        if ( result )
        {
            this.selectedBudget = undefined;
            console.log(`Added: ${result.budgetName} - ${result.companyBudgetId} - ${moment(result.fiscalPeriodBegin).utc().format('MM/DD/YYYY')}..${moment(result.fiscalPeriodEnd).utc().format('MM/DD/YYYY')}`);

            // Refresh availableBudgets and select added date.
            // This indirectly will trigger loadNewColumnData() to refresh grid and counts.
            await this.populateBudgetNameDropdown( result.budgetName );
        }
    }

    companiesSelected(eventTarget: EventTarget): void {
        this.availableFilters.states = this.availableFilters.propertyTypes = this.availableFilters.activityStatuses = [];
        this.filters.companyIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this._setFiltersAndLoadData();
    }

    statesSelected(eventTarget: EventTarget): void {
        this.filters.stateIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this.loadNewColumnData();
    }

    propertyTypesSelected(eventTarget: EventTarget) {
        this.filters.propertyTypeIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this.loadNewColumnData();
    }

    activityStatusesSelected(eventTarget: EventTarget) {
        this.filters.activityStatusIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this.loadNewColumnData();
    }

    async taxYearsChanged() {
        this.filters.yearBegin = this.filters.yearEnd - this.priorYears;

        this.loadNewColumnData();
    }

    async deleteBudget(): Promise<void> {
        const msgBoxResult = await this.messageBoxService.open({
            title: 'WARNING',
            message: 'Deleting the Budget will delete ALL corresponding Budget entries on Parcels, frozen or unfrozen.  Are you sure you want to delete the ENTIRE Budget?',
            buttons: MessageBoxButtons.YesNo
        });

        if (msgBoxResult === MessageBoxResult.Yes) {
            await this.forecastingBudgetingService.deleteCompanyBudget(this.selectedBudget.companyBudgetId);

            this.forecastingBudgetingService.clearData();
            await this.populateBudgetNameDropdown();
        }
    }

    async refresh() {
        this.gridApi.showLoadingOverlay();
        await this.forecastingBudgetingService.loadData();
        this.gridApi.hideOverlay();
    }

    async createMissingForecast(): Promise<void> {
        this.lrUpdateInProgress = true;
        this._showBusyIndicator('Creating missing forecast tax years', null);

        try {
            const lrp = await this.forecastingBudgetingService.startCreateMissingForecast({
                taxYear: this.filters.yearEnd,
                topLevelCompanyId: this.forecastingBudgetingService.tlCompany.companyID,
                companyIds: this.filters.companyIds,
                propertyTypeIds: this.filters.propertyTypeIds,
                stateIds: this.filters.stateIds
            });
            this.longRunningProcessId = lrp.longRunningProcessId;
            await this._busyRef.setLongRunningProcessId(this.longRunningProcessId);
        } catch (err) {
            await this._hideBusyIndicator();
            this.lrUpdateInProgress = false;

        }
    }

    async createMissingBudgetTaxYear(): Promise<void> {
        // Display a popup to confirm that the user wants to add a Budget to
        // any Pending parcels in the grid, if applicable.
        const proceed: boolean = await this.confirmActionOnPendingStatus();
        if ( !proceed )
        {
            console.log('Create Missing Budgets for Pending parcels NOT confirmed by user!');
            return;
        }

        this.lrUpdateInProgress = true;
        this._showBusyIndicator('Creating missing budget tax years', null);

        try {
            const lrp = await this.forecastingBudgetingService.startCreateMissingBudgetTaxYear({
                // TODO: Hook this up (honestly not sure what it is)
                cashPayEarlyAdjustment: 0,
                companyIds: this.filters.companyIds,
                propertyTypeIds: this.filters.propertyTypeIds,
                activityStatusIds: this.filters.activityStatusIds,
                stateIds: this.filters.stateIds,
                companyBudgetId: this.selectedBudget.companyBudgetId,
                topLevelCompanyId: this.forecastingBudgetingService.tlCompany.companyID,
                budgetBasis: this.selectedBudget.budgetBasis
            } as Core.BudgetMissingTaxYearAndAnnualBudgetRequest);
            this.longRunningProcessId = lrp.longRunningProcessId;
            await this._busyRef.setLongRunningProcessId(this.longRunningProcessId);
        } catch (err) {
            await this._hideBusyIndicator();
            this.lrUpdateInProgress = false;

        }
    }

    async syncBudgets(): Promise<void> {
        this.lrUpdateInProgress = true;
        this._showBusyIndicator('Syncing parcel budgets', null);

        try {
            const lrp = await this.forecastingBudgetingService.startSyncBudgets({
                // TODO: Hook this up (honestly not sure what it is)
                cashPayEarlyAdjustment: 0,
                companyIds: this.filters.companyIds,
                propertyTypeIds: this.filters.propertyTypeIds,
                activityStatusIds: this.filters.activityStatusIds,
                stateIds: this.filters.stateIds,
                companyBudgetId: this.selectedBudget.companyBudgetId,
                topLevelCompanyId: this.forecastingBudgetingService.tlCompany.companyID,
                budgetBasis: this.selectedBudget.budgetBasis
            } as Core.BudgetMissingTaxYearAndAnnualBudgetRequest);
            this.longRunningProcessId = lrp.longRunningProcessId;
            await this._busyRef.setLongRunningProcessId(this.longRunningProcessId);
        } catch (err) {
            await this._hideBusyIndicator();
            this.lrUpdateInProgress = false;

        }
    }

    isFreezeUnfreezeAllowed() : boolean {
        return !this.lrUpdateInProgress &&
               this.forecastingBudgetingService.selectedMode === 'budget' &&
               !!this.selectedBudget;
    }

    async freezeUnfreezeBudget() {
        if ( !this.selectedBudget ) {
            return Promise.resolve();
        }

        if ( !this.selectedBudget.isFrozen )
        {
            const counts: BudgetTaxYearMissingSyncData = await this.forecastingBudgetingService.getMissingCountsBudgetForFreeze();
            console.log('MissingCountsForFreeze=', counts);

            let confirmTitle: string = 'Confirm Budget Freeze';
            let confirmMsg: string = 'This should only be done if all active Parcels have a completed Budget entry that is frozen or ready to be frozen.  Are you sure you want to Freeze the ENTIRE portfolio Budget?';

            if ( counts.numMissingTaxYears > 0 || counts.numMissingBudgets > 0 )
            {
                confirmTitle = 'Confirm Incomplete Budget Freeze';
                confirmMsg = 'Are you sure you want to Freeze an incomplete Budget with ';
                if ( counts.numMissingTaxYears > 0 )
                {
                    confirmMsg += ` ${counts.numMissingTaxYears}  missing Tax `;
                    if ( counts.numMissingTaxYears > 1 )
                        confirmMsg += 'Years ';
                    else
                        confirmMsg += 'Year ';
                }
                if ( counts.numMissingBudgets > 0 )
                {
                    if ( counts.numMissingTaxYears > 0 )
                        confirmMsg += ' and ';
                    confirmMsg += ` ${counts.numMissingBudgets}  missing Budget `;
                    if ( counts.numMissingBudgets > 1 )
                        confirmMsg += 'entries';
                    else
                        confirmMsg += 'entry';
                }
                confirmMsg += '?';
            }

            // Confirm with the user to Freeze the entire budget.
            const answer: number = await this.messageBoxService.open({
                title: confirmTitle,
                message: confirmMsg,
                buttons: MessageBoxButtons.OKCancel
            });

            if ( answer !== MessageBoxResult.OK ) {
                // Canceled Freeze.
                console.log('Freeze Entire Budget confirmation canceled');
                return;
            }

            console.log('Freeze Entire Budget confirmed');
        }

        this.gridApi.showLoadingOverlay();

        await this.forecastingBudgetingService.freezeUnfreezeBudget();

        await this.forecastingBudgetingService.loadData();

        // Update isFrozen state of the Budget.
        this.selectedBudget.isFrozen = !this.selectedBudget.isFrozen;
        const curBudget = _.find(this.availableBudgets, (entry) => {
            return entry.budgetName === this.selectedBudget.budgetName;
        });
        if ( curBudget )
        {
            curBudget.isFrozen = this.selectedBudget.isFrozen;
        }
        if(this.selectedBudget.isFrozen) {
            this.forecastingBudgetingService.autoSyncBudgetToLatest = false;
        }

        this.gridApi.hideOverlay();
    }

    async loadNewColumnData(): Promise<void> {
        if(this.forecastingBudgetingService.selectedMode == 'budget' && !this.selectedBudget) {
            return;
        }

        this.gridApi.showLoadingOverlay();

        await this.forecastingBudgetingService.loadData();
        this.gridApi.setColumnDefs(this.forecastingBudgetingService.getGridColumns());

        this.gridApi.hideOverlay();
    }

    async confirmActionOnPendingStatus(selections?: Core.ForecastGridEntry[]): Promise<boolean> {
        let proceed: boolean = true;

        // Pending Status is selected in grid filter options, if:
        // Available Activity Statuses includes Pending
        //   AND
        // (No Activity Statuses are selected (i.e. All apply)
        //    OR
        //  Pending Activity Status is explicitly selected)
        const isPendingStatusSelected: boolean = (
            _.some(this.availableFilters.activityStatuses, {id: Core.ActivityStatuses.ActivePending}) &&    // Available Activity Statuses includes Pending
            (this.filters.activityStatusIds.length === 0 ||                                                 // No Activity Statuses selected, i.e. All
                this.filters.activityStatusIds.some(x => x === Core.ActivityStatuses.ActivePending))           // Pending Activity Status selected
        );
        const areSelectionsGiven: boolean = (selections !== undefined && selections !== null && selections.length > 0);

        let numPendingSelected: number = 0;
        if ( isPendingStatusSelected && areSelectionsGiven )
        {
            numPendingSelected = _.reduce(selections, (sum, entry): number => {
                if ( entry.activityStatus === 'Pending' ) {
                    sum++;
                }
                return sum;
            }, 0);
        }
        console.log(`isPendingStatusSelected=${isPendingStatusSelected}  areSelectionsGiven=${areSelectionsGiven}  numPendingSelected=${numPendingSelected}`);

        // If Pending Status is selected AND
        // we were not given selections OR at least one selection with Pending status,
        // then we want to display a popup to confirm that the user wants to add
        // a Budget to Pending parcels.
        if ( isPendingStatusSelected && (!areSelectionsGiven || numPendingSelected > 0) )
        {
            const msgBoxResult = await this.messageBoxService.open({
                title: 'WARNING',
                message: 'You are about to possibly create a Budget for one or more Parcels with Activity Status of "Pending", which is usually not desirable.  Are you sure you want to continue?',
                buttons: MessageBoxButtons.YesNo
            });

            if (msgBoxResult !== MessageBoxResult.Yes) {
                // User aborted request
                proceed = false;
            }
        }
        return proceed;
    }

    async bulkUpdate() {
        // Retrieve the selected Tax Authoriites.
        // This involves an API call that mirrors the grid population query, so that
        // the Tax Authorities can be retrieved for the All, and All but Given (exclusion)
        // selection use cases.
        const selections: Core.ForecastGridEntry[] = await this.gatherBulkUpdateSelections();
        if ( selections.length === 0 ) {
            return;
        }

        const initialState = {
            selectedCount: selections.length,
            yearBegin: this.filters.yearBegin,
            yearEnd: this.filters.yearEnd,
            selectedMode: this.forecastingBudgetingService.selectedMode,
            selectedView: this.forecastingBudgetingService.selectedView,
            isFrozen: this.forecastingBudgetingService.selectedMode === 'budget' && this.selectedBudget ? this.selectedBudget.isFrozen : false,
            selectedAltAsmtView: this.forecastingBudgetingService.forecastGridSearchModel.alternateAssessmentView
        };
        const modalRef = this.modalService.show(ForecastingBudgetingBulkUpdateModalComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});

        modalRef.content.onClose = async (request?: ForecastBudgetEditRequest) => {
            await this.processBulkUpdateRequest(selections, request);
        };
    }

    async gatherBulkUpdateSelections(): Promise<Core.ForecastGridEntry[]> {

        this.bulkUpdateEntityType = (this.filters.siteRollup ? 'Site' : 'Parcel');

        const selections: Core.ForecastGridEntry[] = this.gridApi.getSelectedRows();
        console.log(`Forecast/Budget ${this.bulkUpdateEntityType} Selections (${selections.length}):  `, _.map(selections, (entry) => entry.entityId));

        // If Selected count is less than threshold for "an awful lot", just proceed.
        if ( selections.length < this.LARGE_BULK_UPDATE_THRESHOLD ) {
            return selections;
        }

        // Confirm with the user to Bulk Update that many Tax Authorities.
        const answer: number = await this.messageBoxService.open({
            message: `Are you sure you want to Bulk Update ${selections.length} ${this.bulkUpdateEntityType} entries?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if ( answer === MessageBoxResult.OK ) {
            return selections;
        }

        // Canceled Bulk Update, return no selections.
        console.log('Bulk Update confirmation canceled');
        return [];
    }

    executeBulkUpdate(): void {
        // RECURSIVE function
        if ( !this.bulkUpdateInProgress || this.bulkUpdateCanceled ||
                this.isBulkUpdateDone() ) {
            // Bulk Update complete or canceled.
            if ( this.bulkUpdateProgress )
            {
                this.forecastingBudgetingService.postProcessEditResult(this.bulkUpdateProgress.request, this.bulkUpdateProgress);
            }
            return;
        }

        const curEntry = this.getCurrentBulkUpdateSelection();
        if ( this.bulkUpdateProgress.request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribTotalFMV ||
                this.bulkUpdateProgress.request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV )
        {
            this.bulkUpdateProgress.request.details.valueFMV = this.getCurrentBulkUpdateDistributedFMV();
        }

        if ( this.bulkUpdateProgress.request.details.valueFMV < 0.0 )
        {
            console.log(`SKIPPED Bulk Update of ${this.getCurrentBulkUpdateIdentity()}:  Not Applicable`);

            this.bulkUpdateProgress.currentIndex++;

            this.executeBulkUpdate();
            return;
        }

        this.forecastingBudgetingService.applyBulkUpdateToEntry(curEntry, this.bulkUpdateProgress.request)
            .then((result: Core.ForecastBudgetEditResult) => {
                console.log(`Bulk Updated ${this.getCurrentBulkUpdateIdentity()}:  ${JSON.stringify(result)}`);

                this.bulkUpdateProgress.capture(result);

                this.bulkUpdateProgress.currentIndex++;

                this.executeBulkUpdate();
            })
            .catch((err:any) => {
                if ( this.bulkUpdateInProgress )
                {
                    console.log(`Bulk Update FAILED for ${this.getCurrentBulkUpdateIdentity()}:  ${JSON.stringify(err)}`);

                    this.toastr.error(err.error.message, 'Bulk Update FAILED!');

                    this.cancelBulkUpdate();
                }
            });
    }

    cancelBulkUpdate() {
        // Switch page to Bulk Updte Canceled display
        this.bulkUpdateCanceled = true;
    }

    endBulkUpdate() {
        // Switch page back to normal display
        this.bulkUpdateCanceled = false;
        this.bulkUpdateInProgress = false;
        this.bulkUpdateProgress.reset();

        // Refresh grid with applied updates.
        this.refresh();
    }

    isBulkUpdateDone() : boolean {
        return this.bulkUpdateProgress.currentIndex >= this.bulkUpdateProgress.selections.length;
    }

    getCurrentBulkUpdateSelection() : Core.ForecastGridEntry | null {
        let cur: Core.ForecastGridEntry = null;

        if ( this.bulkUpdateProgress.currentIndex < this.bulkUpdateProgress.selections.length )
        {
            cur = this.bulkUpdateProgress.selections[this.bulkUpdateProgress.currentIndex];
        }
        return cur;
    }

    getCurrentBulkUpdateDistributedFMV() : number {
        let cur: number = 0;

        if ( this.bulkUpdateProgress.currentIndex < this.bulkUpdateProgress.distributionFMVs.length )
        {
            cur = this.bulkUpdateProgress.distributionFMVs[this.bulkUpdateProgress.currentIndex];
        }
        return cur;
    }

    getCurrentBulkUpdateIdentity() : string {
        let identity: string = '';

        const cur = this.getCurrentBulkUpdateSelection();
        if ( cur )
        {
            identity = `[${this.bulkUpdateProgress.currentIndex + 1} of ${this.bulkUpdateProgress.selections.length}]   Site:  ${cur.siteName}`;
            if ( cur.entityTypeId === Core.EntityTypes.Parcel ) {
                identity += `   Parcel:  ${cur.parcelAcctNum}`;
            }
        }
        return identity;
    }

    protected async processBulkUpdateRequest(selections: Core.ForecastGridEntry[], request?: ForecastBudgetEditRequest) : Promise<void> {
        if ( request )
        {
            let proceed: boolean = true;
            if ( request.details.editAction == Core.ForecastBudgetEditActionEnum.CreateMissingBudget )
            {
                proceed = await this.confirmActionOnPendingStatus(selections);
            }
            if ( !proceed )
            {
                console.log('Created Missing Budgets for Pending parcels NOT confirmed by user!');
                return;
            }

            request.companyBudgetId = this.forecastingBudgetingService.selectedMode == 'budget' ? this.selectedBudget.companyBudgetId : 0;
            request.filters = _.cloneDeep(this.forecastingBudgetingService.filters);
            request.autoSyncBudgetToLatest = this.forecastingBudgetingService.autoSyncBudgetToLatest;
            request.forBulkUpdate = true;
            if ( this.selectedBudget ) {
                request.budgetBasis = this.selectedBudget.budgetBasis;
            }

            // Switch page to Bulk Update Progress display
            this.bulkUpdateInProgress = true;
            this.bulkUpdateCanceled = false;
            this.bulkUpdateProgress.reset();
            this.bulkUpdateProgress.selections = selections;
            this.bulkUpdateProgress.request = request;

            if ( request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribTotalFMV ||
                    request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV )
            {
                this.bulkUpdateProgress.overallDistributionFMV = request.details.valueFMV;

                const totalFMVSelected: number = _.reduce(selections, (sum, entry): number => {
                    const assessment = _.find(entry.assessments, {annualYear: request.details.taxYear});
                    if ( !assessment )
                    {
                        return sum;
                    }
                    let fmvValue: Decimal;
                    if ( request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV )
                    {
                        fmvValue = !assessment.altFMV ? new Decimal(0) : new Decimal(assessment.altFMV);
                    }
                    else
                    {
                        fmvValue = !assessment.totalFMV ? new Decimal(0) : new Decimal(assessment.totalFMV);
                    }
                    console.log(`fmvValue=${fmvValue}`);
                    return new Decimal(sum).plus(fmvValue).toNumber();
                }, 0);
                console.log('totalFMVSelected=', totalFMVSelected);

                this.bulkUpdateProgress.distributionFMVs = _.map(selections, (entry:Core.ForecastGridEntry) : number => {
                    const assessment = _.find(entry.assessments, {annualYear: request.details.taxYear});
                    if ( !assessment )
                    {
                        return 0;
                    }
                    let fmvValue: Decimal;
                    if ( request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV )
                    {
                        fmvValue = !assessment.altFMV ? new Decimal(0) : new Decimal(assessment.altFMV);
                    }
                    else
                    {
                        fmvValue = !assessment.totalFMV ? new Decimal(0) : new Decimal(assessment.totalFMV);
                    }
                    let ratio: Decimal;
                    if ( totalFMVSelected === 0 && selections.length > 1 )
                    {
                        // Total FMV is 0 and there is more than one parcel/site selection.
                        // Ratio cannot be determined for any Parcel, so FMV
                        // cannot be distributed to any of them.
                        return -1;
                    }
                    else if ( totalFMVSelected === 0 && selections.length === 1 )
                    {
                        // Total FMV is 0 and there is only one parcel/site selection.
                        // The lone site/parcel will receive entire distribution, so
                        // Ratio needs to be 1.0 (100%).
                        ratio = new Decimal(1.0);
                    }
                    else
                    {
                        // Compute ratio of this selection's FMV and total FMV of selections.
                        ratio = fmvValue.dividedBy(totalFMVSelected);
                    }
                    console.log(`fmvValue=${fmvValue}  ratio=${ratio}`);
                    return new Decimal(this.bulkUpdateProgress.overallDistributionFMV).times(ratio).round().toNumber();
                });
            }
            console.log('distFMVs=', this.bulkUpdateProgress.distributionFMVs);

            console.log(`Forecast/Budget Bulk Updates to apply:  ${JSON.stringify(this.bulkUpdateProgress.request)}`);
            //console.log(`${this.bulkUpdateEntityType} to Bulk Update:  ${JSON.stringify(this.bulkUpdateProgress.selections)}`);

            // Execute Bulk Update
            this.executeBulkUpdate();
        }
    }

    private _setRowData(): void {
        if (!(this.gridApi && this.isInitialized)) {
            return;
        }

        this.gridApi.setRowData(this.forecastingBudgetingService.getData());

        this.forecastingBudgetingService.updateTotals();
    }

    private async _setFiltersAndLoadData() {
        _.chain(this.availableFilters.companies)
            .filter(company => !this.filters.companyIds.length || _.includes(this.filters.companyIds, company.id))
            .forEach(company => {
                this.availableFilters.states = _.chain(this.availableFilters.states)
                    .unionWith(company.states, _.isEqual)
                    .sortBy('name')
                    .value();
                this.availableFilters.propertyTypes = _.unionWith(this.availableFilters.propertyTypes, company.propertyTypes, _.isEqual);
                this.availableFilters.activityStatuses = _.unionWith(this.availableFilters.activityStatuses, company.activityStatuses, _.isEqual);
            })
            .value();

        this.filters.stateIds = _.intersectionBy(this.filters.stateIds, _.map(this.availableFilters.states, 'id'));
        this.filters.propertyTypeIds = _.intersectionBy(this.filters.propertyTypeIds, _.map(this.availableFilters.propertyTypes, 'id'));
        this.filters.activityStatusIds = _.intersectionBy(this.filters.activityStatusIds, _.map(this.availableFilters.activityStatuses, 'id'));

        await this.loadNewColumnData();
    }

    private _showBusyIndicator(message: string, lrpId: number, hasAction: boolean = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            longRunningProcessId: lrpId,
            identifier: this.busyRefId,
            title: 'Processing company update',
            message,
            hasProgressBar: false,
            hasAction,
            actionMessage: 'Cancel',
            canDismiss: true
        });

        if (hasAction) {
            this._busyRef.onAction().pipe(takeUntil(this._destroy$)).subscribe(async () => {
                await lastValueFrom(this.longRunningProcessRepository.cancel(this.longRunningProcessId));
            });
        }

        this._busyRef.onDismiss().pipe(takeUntil(this._destroy$)).subscribe(async () => {
            await this._hideBusyIndicator();
            this.goToParentCompany();
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            this.lrUpdateInProgress = false;
            if (success) {
                await this.refresh();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }
}
