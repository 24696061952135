import { Component, OnInit, OnDestroy } from '@angular/core';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { LongRunningProcessService } from '../longRunningProcess.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_PAGE_HELP } from './longRunningProcessPage.component.help';

@Component({
    selector: 'long-running-process-page',
    templateUrl: './longRunningProcessPage.component.html',
    styleUrls: ['./longRunningProcessPage.component.scss']
})
export class LongRunningProcessPageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _helpService: HelpService) { }

    isInitialized: boolean = false;
    autoRefresh: boolean = false;
    canEdit: boolean = false;
    refreshing: boolean = false;

    get quiescedStatus(): Compliance.LongRunningProcessQuiescedStatusModel {
        return this._longRunningProcessService.quiescedStatus;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(LONG_RUNNING_PROCESS_PAGE_HELP);

        if (!(this._restrictService.isInRole(Roles.ADMINOPERATIONSVIEW) ||
            this._restrictService.isInRole(Roles.ADMINOPERATIONSEDIT))) {
            this.navigateToUnauthorized();
            return;
        }

        this.canEdit = this._restrictService.isInRole(Roles.ADMINOPERATIONSEDIT);

        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            await this._longRunningProcessService.start();

            this.autoRefresh = this._longRunningProcessService.autoRefresh;
        } finally {
            busyRef.hide();
        }

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._longRunningProcessService.stop();
    }

    async refresh(): Promise<void> {
        this.refreshing = true;
        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            await this._longRunningProcessService.refresh();
        } finally {
            busyRef.hide();
        }
        this.refreshing = false;
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    onAutoRefreshChange(): void {
        this.autoRefresh = !this.autoRefresh;
        this._longRunningProcessService.setAutoRefresh(this.autoRefresh);
    }
}
