<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<div class="ag-grid-layout-specification">
    <div class="ws-flex-container-vertical ws-stretch-width">
        <div class="ws-flex-container-horizontal ws-flex-auto header">
            <div class="header-title ws-flex-auto">Layout</div>
            <div class="float-end header-button-container ws-flex-container-horizontal">
                <div class="action-button-wrapper"
                     helpTooltip
                     [helpContentId]="editHelpContentId"
                     position="bottom">
                    <button type="button"
                            class="btn flat-button icon-button primary-button"
                            [disabled]="(!(selectedSpecification && selectedSpecification.canEdit)) || isBusy"
                            (click)="edit(false)">
                        <i class="fa fa-save fa-align"></i>
                    </button>
                </div>
                <div class="action-button-wrapper"
                     helpTooltip
                     [helpContentId]="copyHelpContentId"
                     position="bottom">
                    <button type="button"
                            class="btn flat-button icon-button primary-button"
                            [disabled]="(!selectedSpecification) || isBusy"
                            (click)="edit(true)">
                        <i class="fa fa-copy fa-align"></i>
                    </button>
                </div>
                <div class="action-button-wrapper"
                     helpTooltip
                     [helpContentId]="deleteHelpContentId"
                     position="bottom">
                    <button type="button"
                            class="btn flat-button icon-button warning-button"
                            [disabled]="(!(selectedSpecification && selectedSpecification.canEdit)) || isBusy"
                            (click)="delete()">
                        <i class="fa fa-trash fa-align"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
            <select id="selectedSpecification"
                    class="form-select form-select-sm"
                    [(ngModel)]="selectedSpecification"
                    [disabled]="isBusy"
                    (change)="onSpecificationChanged()">
                <option *ngFor="let spec of specifications" [ngValue]="spec">{{ spec.name }}</option>
            </select>
            <span class="visibility-tooltip" [popover]="visibilityTooltip" placement="bottom" triggers="mouseenter:mouseleave">
                <i class="fa fa-info-circle small"></i>
            </span>
        </div>
    </div>
</div>

<ng-template #visibilityTooltip>
    <div class="ws-flex-container-vertical" *ngIf="selectedSpecification">
        <div *ngIf="selectedSpecification.description" class="ws-flex-container-vertical">
            <label>Description:</label>
            <div>
                {{ selectedSpecification.description }}
            </div>
        </div>
        <div *ngIf="selectedSpecification.visibilityItem" class="ws-flex-container-vertical">
            <label>Visibility:</label>
            <div>
                <span style="font-style:italic">{{ selectedSpecification.visibilityItem.name }}</span>
                {{ companyId ? selectedSpecification.visibilityItem.description : selectedSpecification.visibilityItem.descriptionNoCompany }}
            </div>
        </div>
        <div *ngIf="selectedSpecification.changedByName" class="ws-flex-container-vertical">
            <label>Updated By:</label>
            <div>
                {{ selectedSpecification.changedByName }}
            </div>
        </div>
    </div>
</ng-template>
