import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AnnualDetailsNavigationEventService } from 'src/app/Annual-Details/annual-details-event.service';
import { TaxRatesService } from 'src/app/Assessor-Collector/Tax-Rates/tax.rates.service';
import { ParcelService } from '../../parcel.service.upgrade';
import { ParcelCollectorTaxRateSetupModalResult } from '../../TaxRateSetup/parcelCollectorTaxRateSetupModal.component';
import { TaxAuthorityRate } from '../../TaxRateSetup/parcelTaxRateService';
import { TaxRateSetupModalLaunchService } from '../../TaxRateSetup/taxRateSetupModalLaunchService';

export interface ParcelCollectorTaxAuthorities {
    parcelCollector: Weissman.Model.Domain.ParcelCollector;
    taxAuthorities: TaxAuthorityRate[];
}

@Component({
    selector: 'collector-tax-rate-area',
    template: `
        <span *ngIf="shouldDisplay"
            class="anchor-style"
            [class.disabled]="isAddedCollector" 
            [popover]="isAddedCollector ? 'Collector must be saved prior to setting up tax rates.' : ''" 
            triggers="mouseenter:mouseleave"
            container="body"
            (click)="launchParcelCollectorTaxRateModal()">
            <span *ngIf="hasTaxRateSetup || !editMode">
                <span *ngIf="trs.taxRateAreaName || trs.taxRateAreaCode">
                    TRA: {{trs.taxRateAreaName}}
                    <span *ngIf="trs.taxRateAreaCode"> ({{trs.taxRateAreaCode}})</span>
                </span>
                <span *ngIf="!(trs.taxRateAreaName || trs.taxRateAreaCode)">
                    Rate composed of {{trs.taxAuthorityCount}} tax {{trs.taxAuthorityCount === 1 ? 'authority' : 'authorities'}}
                </span>
            </span>
            <span *ngIf="!hasTaxRateSetup && editMode">Setup tax rates</span>
        </span>
    `,
    styles: [`
        .disabled {
            color: #AAAAAA;
            cursor: default;
            text-decoration: none;
        }
    `]
})

export class CollectorTaxRateAreaComponent implements OnInit {
    @Input() trs: Weissman.Model.Domain.ParcelTaxRateSetupDTO;
    @Input() parcelCollector: Weissman.Model.Domain.ParcelCollector;
    @Input() parcel: Weissman.Model.Domain.Parcel;
    @Input() years: number[];
    @Input() editMode: boolean;

    taxAuthorities: TaxAuthorityRate[];

    constructor(private readonly _parcelService: ParcelService,
        private readonly _annualDetailsNavigationEventService: AnnualDetailsNavigationEventService,
        private readonly _taxRatesService: TaxRatesService,
        private readonly _taxRateSetupModalLaunchService: TaxRateSetupModalLaunchService) { }

    async ngOnInit() {
     }

    get hasTaxRateSetup(): boolean {
        return !!(this.trs?.taxRateAreaName || this.trs?.taxAuthorityCount > 0)
    }

    get shouldDisplay(): boolean {
        if(this.editMode) {
           // hack for when user adds new collector (hasAvailableTaxSetup is initialized on the collector) 
            return this.trs ? this.trs.hasAvailableTaxSetup : (this.parcelCollector as any).hasAvailableTaxSetup; 
        } else {
            return this.hasTaxRateSetup;
        }
    }

    get isAddedCollector(): boolean {
        return this.parcelCollector.efAction === 'add';
    }

    async launchParcelCollectorTaxRateModal(): Promise<void> {
        if(this.isAddedCollector) {
            return;
        }

        const currentCalendarYear = (new Date()).getFullYear();
        let sortedYears = this.years ? [] : null;

        if (this.years?.length > 0) {
            for (let i = _.max(this.years) + 4; i >= _.min(this.years) - 5; i--) {
                sortedYears.push(i);
            }
        }
        else if (this.years?.length === 0) {
            for (let i = currentCalendarYear + 4; i >= currentCalendarYear - 5; i--) {
                sortedYears.push(i);
            }
        }

        const parcelYear = await this._parcelService.getParcelLatestAssessmentYear(this.parcelCollector.parcelID)
        const currentYear = parcelYear.year && parcelYear.year > 0 ? parcelYear.year : currentCalendarYear;

        let title = this.parcelCollector.collectorAbbr;
        if (this.parcelCollector.collectorBillingScenarioName) {
            title = `${title} - ${this.parcelCollector.collectorBillingScenarioName}`;
        }

        this._taxRateSetupModalLaunchService.openParcelCollectorTaxRateSetupModal(
            this.parcel.parcelID,
            this.parcelCollector as any,
            title,
            sortedYears,
            currentYear,
            !this.editMode,
            this.taxAuthorities
        ).subscribe({
            next: modalResult => {
                // Reflect changes from the modal result in the parcel collector. I've noticed that sometimes the this.parcelCollector
                // reference passed in as an argument isn't necessarily the same as the one on this.parcel, so change both.
                var affectedParcelCollectors = [this.parcelCollector].concat(
                    _.filter(this.parcel.parcelCollectors, { parcelCollectorID: this.parcelCollector.parcelCollectorID })
                );

                _.forEach(affectedParcelCollectors, pc => this._applyTaxRateSetupModalResult(pc, modalResult));

                this.trs = this.trs || {} as Weissman.Model.Domain.ParcelTaxRateSetupDTO;

                this.trs.hasAvailableTaxSetup = !!(modalResult.taxRateAreaName || modalResult.taxAuthorityCount > 0);
                this.trs.taxAuthorityCount = modalResult.taxAuthorityCount;
                this.trs.taxRateAreaName = modalResult.taxRateAreaName;
                this.trs.taxRateAreaCode = modalResult.taxRateAreaCode;
                this.taxAuthorities = modalResult.taxAuthorities; 
                
                if (this.parcelCollector.parcelCollectorID) {
                    // Refresh the annual grid (tax bill amounts may have changed)
                    this._annualDetailsNavigationEventService.goToGrid();
                }
                else {
                    const parcelCollectorTAs = _.map(affectedParcelCollectors, parcelCollector => {
                        const taxAuthorities = _.map(modalResult.taxAuthorities, ta => {
                            ta.efAction = 'add';
                            return ta;
                        });

                        return {taxAuthorities, parcelCollector}
                    });


                    this._parcelService.setParcelCollectorTaxAuthorities(parcelCollectorTAs);
                }
            }
        });
    }

    private _applyTaxRateSetupModalResult(pc: any, modalResult: ParcelCollectorTaxRateSetupModalResult): void {
        pc.rowVersion = modalResult.rowVersion;
        pc.taxRateAreaId = modalResult.taxRateAreaId;
        pc.taxRateAreaName = modalResult.taxRateAreaName;
        pc.taxRateAreaCode = modalResult.taxRateAreaCode;
        pc.taxAuthorityCount = modalResult.taxAuthorityCount;
        pc.hasTaxRateSetup = !!(modalResult.taxRateAreaName || modalResult.taxAuthorityCount > 0);
    }
}