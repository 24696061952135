<div class="currency-cell-editor" [class.has-error]="error || !valid" [class.mb-4]="hasMargin">
    <!-- <span class="dollar-sign">$</span> -->
    <input type="text"
           class="form-control"
           [numbersOnly]
           currencyMask
           [options]="{ allowNegative: false, prefix: '' }"
           inputmode="numeric"
           pattern="[0-9]+([\.,][0-9]+)?"
           [class.overridden]="override"
           [class.has-adjustments]="hasAdjustments && !override"
           [disabled]="disabled"
           [class.ws-calculated]="isCalculated"
           [attr.maxlength]="maxLength"
           [attr.name]="name"
           [ngModel]="value"
           (ngModelChange)="currencyChanged($event)"/>
    <span *ngIf="error || !valid" class="help-block">The currency value entered is invalid</span>
</div>
