(function () {
    'use strict';

    var routes = null;

    angular
        .module('weissmanApp')
        // routes is built internally in configRoutes, but make the object availble to other services
        // by exposing a factory to return it.
        .factory('routes', function () { return routes; })
        .config(configRoutes);

    configRoutes.$inject = ['$urlRouterProvider', '$stateProvider', 'Roles', 'EntityTypeIDs', 'InstanceRights','ContactModalOrigin'];

    function configRoutes($urlRouterProvider, $stateProvider, Roles, EntityTypeIDs, InstanceRights, ContactModalOrigin) {
        var baseUrl = 'app/';

        routes = {
            'site': {
                url: '/company/:companyId/site/:siteId',
                views: {
                    '': {
                        templateUrl: baseUrl + 'Entity/Site/_panels.html',
                        controller: 'sd.Site.Controller',
                        controllerAs: 'vm',
                    },
                    'contacts@site': {
                        templateUrl: baseUrl + 'Entity/Site/_contactsPanel.html',
                        controller: 'sd.Site.Contacts.Panel.Controller',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    $title: function () {
                        return 'Site';
                    }
                },
            },
            'parcel': {
                url: '/company/:companyId/site/:siteId/parcel/:parcelId?annualYearID?billClusterID?refundID?annualAssessmentID?appealID?filingId',
                templateUrl: baseUrl + 'Entity/Parcel/_parcel.html',
                controller: 'ParcelCtrl',
                resolve: {
                    $title: function () {
                        return 'Parcel';
                    }
                },
            },
            'siteRedirect': {
                url: '/site/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.SITE; }
                }
            },
            'parcelRedirect': {
                url: '/parcel/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.PARCEL; }
                }
            },
            'assessmentRedirect': {
                url: '/assessment/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.ASSESSMENT; }
                }
            },
            'appealRedirect': {
                url: '/appeal/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.APPEAL; }
                }
            },
            'billClusterRedirect': {
                url: '/billcluster/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.BILL_CLUSTER; }
                }
            },
            'billRedirect': {
                url: '/bill/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.TAX_BILL; }
                }
            },
            'filingRedirect': {
                url: '/filing/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FILING; }
                }
            },
            'paymentRedirect': {
                url: '/payment/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.PAYMENT; }
                }
            },
            'refundRedirect': {
                url: '/refund/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.REFUND; }
                }
            },
            'collectorRedirect': {
                url: '/collector/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.COLLECTOR; }
                }
            },
            'assessorRedirect': {
                url: '/assessor/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.ASSESSOR; }
                }
            },
            'invoiceRedirect': {
                url: '/invoice/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.INVOICE; }
                }
            },
            'filingBatchRedirect': {
                url: '/filingbatch/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FilingBatch; }
                }
            },
            'filingBatchRedirect2': {
                url: '/filingBatch/:entityID',
                templateUrl: baseUrl + 'Entity/_redirect.html',
                controller: 'RedirectCtrl',
                resolve: {
                    entityTypeID: function () { return EntityTypeIDs.FilingBatch; }
                }
            },
            'logout': {
                onEnter: ['accountService', function (accountService) {
                    return accountService.logout().then(function () {
                        return false;
                    });
                }]
            },
            'contact': {
                url: '/contacts/:contactId',
                onEnter: ['sd.Contacts.Service.old', '$stateParams', function (contactsService, $stateParams) {
                    if ($stateParams.contactId) {
                        contactsService.openContactDialog($stateParams.contactId, ContactModalOrigin.ContactsList);
                    }
                }]
            },
            'unauthorizedAccess': {
                template: '<p class="text-warning text-center lead" style="margin-top: 50px;">You do not have permission to view this page. If you have any questions, please contact the site administrator.</p>',
                resolve: {
                    $title: function () {
                        return 'Unauthorized Access';
                    }
                }
            },
            'actionview': {
                url: '/actionview/:actionViewId?actionViewType&displayType',
                params: { actionViewId: null },
                templateUrl: baseUrl + 'Task/ActionView/_filtersOutputs.html',
                controller: 'FiltersOutputsController',
                controllerAs: 'vm',
                resolve: {
                    $title: function () {
                        return 'Action View';
                    }
                },
                onExit: ['actionViewService', function (actionViewService) {
                    actionViewService.cancelAvSearch();
                }]
            },
            'documentProcessing': {
                url: '/processing/document?taskID?parcelID?intakeItemID',
                templateUrl: baseUrl + 'Processing/Documents/_documentProcessing.html',
                controller: 'DocumentProcessingController',
                controllerAs: 'vm',
                params: {
                    selectedRows: [],
                    searchTimestamp: null
                },
                resolve: {
                    $title: function () {
                        return 'Document Processing';
                    }
                }
            },
            // TODO: Consider eliminating the document preview version of this route and moving the files
            // to more logical locations; they're the same route except for the URL
            'attachmentPopup': {
                url: '/attachmentPopup',
                resolve: {
                    $title: function () {
                        return 'Attachment Viewer';
                    }
                },
                templateUrl: baseUrl + 'Processing/Documents/Popups/_filePreview.html',
                controller: 'FilePreviewController',
                controllerAs: 'vm'
            }
        };

        // Hack to produce a resolve on every state definition
        // (see https://stackoverflow.com/a/33280010; this was needed
        // for navigation warnings)
        var originalStateFunction = $stateProvider.state;
        $stateProvider.state = function (name, definition) {
            if (!definition.resolve) {
                definition.resolve = {};
            }

            return originalStateFunction.apply(this, arguments);
        };
        // End hack

        var routeKeys = Object.keys(routes);

        for (var i = 0; i < routeKeys.length; i++) {
            var routeKey = routeKeys[i];

            $stateProvider.state(routeKey, routes[routeKey]);
        }
    }
})();

