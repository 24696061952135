<div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="assessor">
    <div class="ws-flex-container-horizontal ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="assessor.abbr"></ws-bread-crumbs>
        </div>
    </div>

    <div class="ws-section ws-flex-none">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">{{assessor.abbr}}</h4>
        </div>
    </div>

    <div class="row equal-panel" [ngClass]="{'remove-panel': isTaxRatesMaximized}">
        <div class="col-xl-4">
            <assessor-info [assessor]="assessorForInfo" (assessorSaved)="assessorChanged($event)"></assessor-info>
        </div>
        <div class="col-xl-4">
            <deadline-list-panel [stateId]="assessor.stateID"
                                 [assessorId]="assessor.assessorID"
                                 [state]="state"
                                 [assessor]="assessor"></deadline-list-panel>
        </div>
        <div class="col-xl-4">
            <assessor-available-collectors [state]="state" [assessor]="assessor"></assessor-available-collectors>
        </div>
    </div>
    <div class="row panel-container ws-flex-container-horizontal" [ngClass]="{'remove-panel': isTaxRatesMaximized}">
        <div class="col-xl-8">
            <assessment-class-list-panel [state]="state" [assessor]="assessor"></assessment-class-list-panel>
        </div>
        <div class="col-xl-4">
            <entity-property-characteristics class="ws-flex-container-vertical ws-stretch-height"></entity-property-characteristics>
        </div>
    </div>
    <div class="row" [ngClass]="{'remove-panel': isTaxRatesMaximized}">
        <div class="col-xl-12">
            <protocol-list-panel [state]="state" [assessor]="assessor"></protocol-list-panel>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12">
            <tax-rates-panel [assessorId]="assessor.assessorID" (maximizeToggled)="toggleTaxRatesMaximize($event)">
            </tax-rates-panel>
        </div>
    </div>
    <div class="row" [ngClass]="{'remove-panel': isTaxRatesMaximized}">
        <div class="col-xl-12">
            <contacts-panel *ngIf="entity" [entity]="entity"></contacts-panel>
        </div>
    </div>
</div>
