import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Invoice, RevenueShare } from '../invoice.model';
import { GridApi, GridOptions, GridReadyEvent, ColDef } from 'ag-grid-community';
import { RevenueShareGridActionCellRendererComponent, ICellRendererParamsForRevenueShareGridAction } from './agGridActionCellRendererComponent';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../../Compliance/AgGrid';
import { Engagement } from '../../../Common/Contracts-Invoices/contract-invoices.model';
import { AgGridEngagementNumberCellRenderer, ICellRendererParamsForEngagementNumber } from './agGridEngagementNumberCellRenderer';
import { AgGridCostCenterCellRenderer, ICellRendererParamsForCostCenter } from './agGridCostCenterCellRenderer';
import { AgGridPercentAllocationCellRenderer, ICellRendererParamsForPercentAllocation } from './agGridPercentAllocationCellRenderer';
import { CurrencyPipe } from '@angular/common';
import { AgGridExpenseAmountCellRenderer, ICellRendererParamsForExpenseAmount } from './agGridExpenseAmountCellRenderer';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EngagementNumberLookupModalComponent } from '../../../Common/Contracts-Invoices/engagement-number-lookup-modal.component';
import * as _ from 'lodash';
import { InvoiceService } from '../invoice.service';

@Component({
    selector: 'fee-allocation',
    templateUrl: './fee-allocation.component.html'
})
export class FeeAllocationComponent implements OnChanges {
    @Input() currentInvoice: Invoice;
    @Input() editMode: boolean;
    @Input() currentInvoiceAmount: number;

    @Output() engagementChanged: EventEmitter<Engagement> = new EventEmitter<Engagement>();

    constructor(private readonly _currencyPipe: CurrencyPipe,
        private readonly _bsModalService: BsModalService,
        private readonly invoiceService: InvoiceService) {}

    private _gridApi: GridApi;

    gridId: System.Guid = '7D485CAC-59F4-4CAE-AE56-B8948DE60508'; // need new guid
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    refreshing: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        let changedKeys = Object.keys(changes);
        // Look for changes to input properties
        if (changedKeys.indexOf('currentInvoiceAmount') >= 0) {
            this._allocationChange();
        } 
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Allocation Type',
                field: 'name',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                valueGetter: params => {
                    const rSC = params.data as RevenueShare;

                    if (rSC.isTotal) {
                        return '';
                    }
                    else if (rSC.isAccountHandler) {
                        return 'Account Handler';
                    } else {
                        return `Revenue Share ${rSC.sequence}`;
                    }
                },
                comparator: (valueA, valueB, nodeA, nodeB) => nodeA.data.sequence - nodeB.data.sequence
            },
            {
                headerName: 'Engagement #',
                field: 'engagementNum',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth,
                cellRendererFramework: AgGridEngagementNumberCellRenderer,
                cellRendererParams: {
                    editMode: () => this.editMode,
                    onClick: this._lookupEngagementNumber.bind(this)
                } as ICellRendererParamsForEngagementNumber
            },
            {
                headerName: 'Optional Identifier',
                field: 'optionalIdentifier',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Cost Center',
                field: 'costCenter',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridCostCenterCellRenderer,
                cellRendererParams: {
                    editMode: () => false //this.editMode,
                } as ICellRendererParamsForCostCenter
            },
            {
                headerName: '% Allocation',
                field: 'allocationPct',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellRendererFramework: AgGridPercentAllocationCellRenderer,
                cellRendererParams: {
                    editMode: () =>  this.editMode,
                    onBlur: params => {
                        const revenueShare = params.data as RevenueShare;
                        this._allocationChange(revenueShare);
                    }
                } as ICellRendererParamsForPercentAllocation
            },
            {
                headerName: 'Fee Amount',
                field: 'feeAmount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: params => {
                    const revenueShare = params.data as RevenueShare;
                    return this._currencyPipe.transform(revenueShare.feeAmount, 'USD', 'symbol', '1.2-2');
                }
            },
            {
                headerName: 'Expense Amount',
                field: 'expenseAmount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellRendererFramework: AgGridExpenseAmountCellRenderer,
                cellRendererParams: {
                    onClick: (params: ICellRendererParamsForExpenseAmount) => {
                        const revenueShare = params.data as RevenueShare;
                        this.currentInvoice.expenseIndex = revenueShare.sequence + 1;
                        this._calculateInvoiceAmounts()
                    },
                    editMode: () => this.editMode,
                    currentInvoice: () => this.currentInvoice
                }
            },
            {
                headerName: 'Invoice Amount',
                field: 'invoiceAmount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: params => {
                    const revenueShare = params.data as RevenueShare;
                    return this._currencyPipe.transform(revenueShare.invoiceAmount, 'USD', 'symbol', '1.2-2');
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: RevenueShareGridActionCellRendererComponent,
                cellRendererParams: {
                    canDelete: params => {
                        const revenueShare = params.data as RevenueShare;
                        if(!revenueShare) {
                            return;
                        }

                        return this.editMode && !revenueShare.isAccountHandler && revenueShare.allocationPct === 0 && !revenueShare.isTotal;
                    },
                    delete: this._delete.bind(this)
                } as ICellRendererParamsForRevenueShareGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();

        this._setRowData();
    }

    private _lookupEngagementNumber(params: ICellRendererParamsForRevenueShareGridAction): void {
        const revenueShare = params.data as RevenueShare;

        const bsModalRef = this._bsModalService.show(EngagementNumberLookupModalComponent, {class: 'modal-lg'});
        bsModalRef.content.title = 'Lookup Engagement Number';

        bsModalRef.content.onClose = (engagement: Engagement) => {
            revenueShare.engagementNum = engagement.engagementID;
            revenueShare.optionalIdentifier = engagement.optionalIdentifier;

            if (revenueShare.isAccountHandler) {
                this.engagementChanged.emit(engagement)
            }
            else if (revenueShare.sequence == 1) {
                // WK-4781: When the RS1 percentage is first set, if the AH percentage is 100%, change it to AH 30% RS1 70%
                const aHIdx = _.findIndex(this.currentInvoice.invoiceRevenueShareCodes, 'isAccountHandler')
                if (this.currentInvoice.invoiceRevenueShareCodes[aHIdx].allocationPct === 1) {
                    revenueShare.displayAllocationPct = 70;
                    this._allocationChange(revenueShare);
                }
            }
        }
    }

    private _delete(params: ICellRendererParamsForRevenueShareGridAction): void {
        const revenueShare = params.data as RevenueShare;
        if (!revenueShare) {
            return;
        }
        _.remove(this.currentInvoice.invoiceRevenueShareCodes, revenueShare);
        this._gridApi.updateRowData({remove: [revenueShare]})

        this.currentInvoice.invoiceRevenueShareCodes = _.map(this.currentInvoice.invoiceRevenueShareCodes, (x, i) => {
            x.sequence = i;
            return x;
        });

        this._allocationChange();
    }

    private _allocationChange(rs?: RevenueShare): void {
        // Validate the input allocation; it must be a number between 0 and 100 inclusive.
        if (rs && (rs.displayAllocationPct < 0 || rs.displayAllocationPct > 100)) {
            rs.displayAllocationPct = this.invoiceService.getDisplayPercent(rs.allocationPct);
            return;
        }

        if(rs) {
            this._normalizeAllocationPercent(rs);
        }

        this.currentInvoice.invoiceRevenueShareCodes = _.map(this.currentInvoice.invoiceRevenueShareCodes, x => {
            x.allocationPct = this.invoiceService.getRealPercentFromDisplay(x.displayAllocationPct);
            x.feeAmount = this._calculateFeeAmountPercent(x.allocationPct);
            return x;
        });

        this._ensureFeeAmountsAddUp(); // https://weissmandemo.atlassian.net/browse/PT-1423
        this._calculateInvoiceAmounts();

        if(this._gridApi) {
            this._gridApi.refreshCells();
            this._setTotalRow();
        }
    }

    private _setRowData() {
        this._gridApi.setRowData(this.currentInvoice.invoiceRevenueShareCodes);
        this._setTotalRow();
    }
    
    private _setTotalRow() {
        const totalRow: RevenueShare = new RevenueShare(null);
        totalRow.isTotal = true;
        totalRow.feeAmount = this.currentInvoice.totalFeeAmount;
        totalRow.invoiceAmount = this.currentInvoice.invoiceAmount;
    
        this._gridApi.setPinnedBottomRowData([totalRow])
    }

    private _normalizeAllocationPercent(rs: RevenueShare): void {
        //Clamp changed value to [0, 100]
        rs.displayAllocationPct = _.clamp(rs.displayAllocationPct, 0, 100);

        const totalAllocation = _.reduce(this.currentInvoice.invoiceRevenueShareCodes, (sum, rs) => {
            return new Decimal(sum).plus(rs.displayAllocationPct).toNumber();
        }, 0);

        if(totalAllocation === 100) {
            //We're at 100. Great!
            return;
        }

        //We've either got a surplus or deficit
        let difference: number = totalAllocation - 100;

        _.forEach(this.currentInvoice.invoiceRevenueShareCodes, x => {
            if(x.sequence == rs.sequence) {
                return;
            }

            if (difference !== 0) {
                x.displayAllocationPct -= difference;
                difference = 0;

                if (x.displayAllocationPct < 0) {
                    difference = -x.displayAllocationPct;
                    x.displayAllocationPct = 0;
                }

                if (x.displayAllocationPct > 100) {
                    difference = x.displayAllocationPct - 100;
                    x.displayAllocationPct = 100;
                }
            }
        });
    }

    private _calculateFeeAmountPercent(percentage: number): number {
        const amount = new Decimal(this.currentInvoice.feeAmount || 0).plus(this.currentInvoice.fixedFee || 0).toNumber();
        return new Decimal(percentage).times(amount).toNumber();
    }

    private _calculateInvoiceAmounts(): void {
        this.currentInvoice.invoiceRevenueShareCodes = _.map(this.currentInvoice.invoiceRevenueShareCodes, x => {
            x.invoiceAmount = x.feeAmount;
            return x;
        });

        this.currentInvoice.invoiceRevenueShareCodes[this.currentInvoice.expenseIndex - 1].invoiceAmount = new Decimal(this.currentInvoice.invoiceRevenueShareCodes[this.currentInvoice.expenseIndex - 1].invoiceAmount)
            .plus(this.currentInvoice.expenseAmount || 0)
            .toNumber();
    }

    private _ensureFeeAmountsAddUp() {
        const feeAmountSum = _.reduce(this.currentInvoice.invoiceRevenueShareCodes, (sum, rs) => {
            return new Decimal(sum).plus(rs.feeAmount).toDecimalPlaces(2).toNumber();
        }, 0);

        let feeAmountDiff = new Decimal(feeAmountSum).sub(this.currentInvoice.totalFeeAmount).toNumber();

        _.forEachRight(this.currentInvoice.invoiceRevenueShareCodes, x => {
            if(feeAmountDiff === 0) {
                return;
            }

            const feeAmountDecimal = new Decimal(x.feeAmount).toDecimalPlaces(2);

            if(feeAmountDecimal.toNumber() > 0) {
                if(feeAmountDiff > 0) {
                    x.feeAmount = feeAmountDecimal.sub(0.01).toNumber();
                    feeAmountDiff -= 0.01;
                } else {
                    x.feeAmount = feeAmountDecimal.add(0.01).toNumber();
                    feeAmountDiff += 0.01;
                }
            }
        });
    }

    addNew(): void {
        const newRevenueShare = new RevenueShare(this.currentInvoice.invoiceId);
        newRevenueShare.sequence = this.currentInvoice.invoiceRevenueShareCodes.length;
        this.currentInvoice.invoiceRevenueShareCodes.push(newRevenueShare);
        this._gridApi.updateRowData({add: [newRevenueShare]})
        this._allocationChange();
    }
}
