import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyAssetDescriptorRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getByCompanyId(companyId: number): Observable<Compliance.CompanyAssetDescriptorMappingModel[]> {
        return this.httpGet(`/api/companyassetdescriptor/company/${companyId}`);
    }

    getAssetCharacteristics(): Observable<Core.DescriptorModel[]>{
        return this.httpGet(`/api/companyassetdescriptor/assetCharacteristics`);
    }
}
