import { Pipe, PipeTransform } from '@angular/core';
import {PaymentPackageRecipient} from "./transmittal.models";

@Pipe({
    name: 'recipientType',
    pure: false
})
export class RecipientTypePipe implements PipeTransform {
    transform(items: PaymentPackageRecipient[], filter: any): PaymentPackageRecipient[] {
        if (!items || !filter) {
            return items;
        }

        return items.filter(item => item.recipientType === filter.recipientType);
    }
}