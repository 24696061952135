import { ComponentRef } from '@angular/core'
import { BusyIndicatorComponent } from './busyIndicator.component'
import { BehaviorSubject, Observable } from 'rxjs';

export class BusyIndicatorRef {
    constructor(private _componentRef: ComponentRef<BusyIndicatorComponent>) {
        if (!this._componentRef) {
             throw new Error('Component reference missing. A busy indicator reference object needs to be associated with a busy indicator component.');
        }
    }

    private _isDisposed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _messageIds: any[] = [];

    addLongRunningProcess: (id: number) => void; // defined after creation in BusyIndicatorService
    isDisposed$: Observable<boolean> = this._isDisposed.asObservable();

    get isDisposed(): boolean {
        return this._isDisposed.getValue();
    }

    /**
     * Adds a new message to the busy indicator instance.
     * @param message The message to add.
     * @param id The message identifier.
     */
    addMessage(message: string, id: any): void {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        this._componentRef.instance.addMessage(message, id);
        this._messageIds.push(id);
    }

    /**
     * Removes a message from the busy indicator instance.
     * @param id The message identifier.
     */
    removeMessage(id: any): void {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        this._componentRef.instance.removeMessage(id);
        this._messageIds.splice(this._messageIds.indexOf(id), 1);
    }

    /**
     * Updates an existing message in the busy indicator instance.
     * @param message The updated message.
     * @param id The message identifier.
     */
    updateMessage(message: string, id: any): void {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        this._componentRef.instance.updateMessage(message, id);
    }

    /**
     * Set the long running process ID to listen for
     * @param longRunningProcessId
     */
    async setLongRunningProcessId(longRunningProcessId: number): Promise<void> {
        if (this.isDisposed) {
            this._warnDisposed();
            return Promise.resolve();
        }
        this.addLongRunningProcess(longRunningProcessId);
        await this._componentRef.instance.setLongRunningProcessId(longRunningProcessId);
    }

    /**
     * Returns an observable for notifications when the action gets triggerd.
     */
    onProgressBarComplete(): Observable<boolean> {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        return this._componentRef.instance.onProgressBarComplete();
    }

    /**
     * Returns a count of messages associated with the busy indicator instance.
     */
    messageCount(): number {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        return this._componentRef.instance.messageCount();
    }

    /**
     * Returns an observable for notifications when the action gets triggerd.
     */
    onAction(): Observable<number> {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        return this._componentRef.instance.onAction();
    }

    /**
     * Returns an observable for notifications when the action gets triggerd.
     */
    onDismiss(): Observable<number> {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        return this._componentRef.instance.onDismiss();
    }

    /**
     * Hides the busy indicator instance.
     */
    async hide(): Promise<void> {
        if (this.isDisposed) {
            this._warnDisposed();
            return;
        }

        // mark as disposed
        this._isDisposed.next(true);

        // remove all messages that this reference object created from the busy component
        while (this._messageIds.length) {
            const id = this._messageIds.pop();
            this._componentRef.instance.removeMessage(id);
        }

        // dispose the component if there are no more messages
        if (this._componentRef.instance.messageCount() === 0) {
            try {
                // mark component ref instance as destroyed;
                // this will prevent future checks from using it while the destroy animation is running
                this._componentRef.instance.isDestroyed = true;
                await this._componentRef.instance.setAnimationState('inactive');
                this._componentRef.destroy();
                this._componentRef = null;
            } catch (e) {
                this._componentRef = null;
                console.error('An error occurred while trying to hide the busy indicator.', e);
            }
        }
    }

    private _warnDisposed(): void {
        console.warn('This busy indicator reference has been disposed.');
    }
}
