<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Create New Merged Parcels"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid">
    <form [formGroup]="form">
        <div class="ws-stretch" style="min-height: 150px;">
            <div class="mb-3 row">
                <div class="col-md-2 row-header">Site:</div>
                <div class="col-md-10">
                    <select name="site"
                            class="form-select form-select-sm"
                            (ngModelChange)="onSiteChanged()"
                            formControlName="site">
                        <option *ngFor="let site of sites" [value]="site.siteId">{{site.siteName}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-md-2 row-header">Assessor:</div>
                <div class="col-md-10">
                    <select name="assessor"
                            class="form-select form-select-sm"
                            (ngModelChange)="onAssessorChange()"
                            formControlName="assessor">
                        <option *ngFor="let assessor of assessors" [value]="assessor.assessorId">{{assessor.assessorName}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3 row">
                <div class="col-md-2 row-header">Collectors:</div>
                <div class="col-md-10">
                    <ws-multi-select
                        labelProperty="name"
                        valueProperty="id"
                        placeholder="Please select a value..."
                        [options]="collectors"
                        formControlName="collector">
                    </ws-multi-select>
                    <div *ngIf="form.controls['collector'].invalid && form.get('collector').errors?.collectorHasDuplicatesSelected">The same collector cannot be selected more than once.</div>
                </div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
