import {
    Component, ElementRef,
    EventEmitter, HostListener,
    Input, NgZone, OnInit,
    Output, ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { TimerService } from '../../Utilities';
import { DropdownPosition } from '../Select/select.interface';

@Component({
    selector: 'ws-ace-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AceDropdownComponent implements OnInit {
    constructor(private readonly _timer: TimerService,
                private readonly _ngZone: NgZone) { }
    @Input() icon: string = 'chevron-down';
    @Input() size: 'default' | 'small' = 'default';
    @Input() content: string;
    @Input() asyncMenu: boolean;

    @Input() enableFocus: boolean;
    @Input() autoClose: boolean | 'inside' | 'outside' = true;
    @Input() suppressAutoOpen: boolean;
    @Input() attachment: any;
    @Input() position: DropdownPosition | string;
    @Input() sameWidth: boolean;
    @Input() displayAbsolute: boolean;
    @Input() overflowVisible: boolean;
    @Input() isLoading: boolean;
    @Input() isDisabled: boolean;
    /** A selector or element that will contain the dropdown.  Default: closest(.ace-con section.content) */
    @Input() boundary?: string | HTMLElement;
    // draw a dropdown with zero padding, for colored footers
    @Input() suppressPadding?: boolean;
    /** use full-width hitbox */
    @Input() block?: boolean;
    /** Event will be raised when dropdown opens or closes */
    @Output() openChange?: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('dropdownHost', { static: true }) dropdownHost: ElementRef;
    @ViewChild('dropdownMenu', { static: true }) dropdownMenu: ElementRef;

    isOpen = false;
    placementClasses: { [key: string]: boolean };
    positionClasses: { [key: string]: boolean };
    ddPosition: { [key: string]: string } = {
        opacity: '0',
    };

    @HostListener('window:resize', [])
    resize() {
        this._calculatePosition();
    }

    ngOnInit(): void {
        this.placementClasses = { 'dropdown-wrapper': true };
        if (this.attachment) {
            this.placementClasses[`drop${this.attachment}`] = true;
        } else {
            this.placementClasses['dropdown'] = true;
        }
        this.positionClasses = { 'dropdown-menu': true };
        if (this.position) {
            this.positionClasses[`dropdown-menu-${this.position}`] = true;
        }
        if (this.sameWidth) {
            this.positionClasses['same-width'] = true;
        }
        if (this.overflowVisible) {
            this.positionClasses['overflow-visible'] = true;
        }

        window.addEventListener('scroll', this._calculatePosition.bind(this), true);
    }

    setOpenState(isOpen: boolean, location?: string): void {
        if ((!this.isDisabled || (this.isDisabled && !isOpen)) && (!location || (this.autoClose === true || this.autoClose === location))) {
            this.isOpen = isOpen;
            if (this.isOpen) {
                this._timer.setTimeout(() => {
                    this._calculatePosition();
                }, 0);
            } else {
                this.ddPosition = {
                    opacity: '0',
                };
            }
            this.openChange.emit(isOpen);
        }
    }

    private _calculatePosition(): void {
        const hostElRect = this.dropdownHost.nativeElement.getBoundingClientRect();
        const menuElRect = this.dropdownMenu.nativeElement.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        let hostElTop = hostElRect.top + hostElRect.height;

        let hostElRight = windowWidth - hostElRect.left - menuElRect.width;
        if (this.position === 'end') {
            hostElRight = window.innerWidth - hostElRect.left - hostElRect.width;
        }
        if (hostElRight < 0) {
            hostElRight = 0;
        }

        if (hostElTop + menuElRect.height > window.innerHeight) {
            this.position = 'top';
            hostElTop = hostElRect.top - menuElRect.height;
        }

        this.ddPosition = {
            position: 'fixed',
            inset: '0px 0px auto auto',
            margin: '0px',
            opacity: '1',
            transform: `translate(${-hostElRight}px, ${hostElTop}px)`
        };
    }
}
