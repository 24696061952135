import { HelpContent } from '../../../../../UI-Lib/Help-Tooltip';

export const RETURN_SETTINGS_HELP: HelpContent[] = [
    {
        helpContentId: 'return-info.return-values',
        tooltipText: 'Values that will appear on the return form. Order of precedence: Return Override, Parcel, Sub Company, Company.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.settings',
        tooltipText: 'View and edit values that will appear on the return form. Order of precedence: Return Override, Parcel, Sub Company, Company.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.legal-entity',
        tooltipText: 'Legal entity name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.business-dba',
        tooltipText: 'Business/DBA name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.taxpayer',
        tooltipText: 'Taxpayer name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.owner',
        tooltipText: 'Owner name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.accounting',
        tooltipText: 'Accounting representatives\' name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.preparer',
        tooltipText: 'Preparers\' name and address as it will appear on return form.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.signer',
        tooltipText: 'Signers\' name and address as it will appear on return form. If wet signature not required, signature will also be used from this entity.',
        hasIcon: false
    },
    {
        helpContentId: 'return-info.allow-wet-signature-override',
        tooltipText: 'Permit electronic signature in jurisdictions that require a wet signature.',
        hasIcon: false
    }
];
