
import {of as observableOf,  Observable } from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as _ from 'lodash';
import { mergeMap } from 'rxjs/operators';
import {WeissmanKeyValueDisplayPipe} from "../../../../../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe";
import {ExtendedGLAccountModel} from "../../../../../../Asset/Asset-Details/Asset-Info/extendedGLAccountModel";

@Component({
    templateUrl: './glAccountSelector.component.html',
    selector: 'glaccount-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: GLAccountSelectorComponent,
        multi: true
    }]
})
export class GLAccountSelectorComponent implements ControlValueAccessor {
    constructor(private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe){

    }
    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    private _glAccounts: ExtendedGLAccountModel[] = [];

    @Input() set values(values: Compliance.GLAccountModel[]) {
        this._glAccounts = (values || []).map(x => new ExtendedGLAccountModel(x, this._keyValueDisplayPipe))
    }
    @Input() isOverridden: boolean = false;
    @Input() isDisabled: boolean = false;

    @Output() changed = new EventEmitter<string>();

    value: string = '';
    filter: string = '';

    values$: Observable<ExtendedGLAccountModel[]> = (Observable
        .create((observer: any) => { observer.next(this.filter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filter(token)));

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(glAccount: string): void {
        this._select(glAccount);
    }

    onBlur(): void {
        this._select(this.filter === '' ? '' : this.value);
        this._onTouchedFn && this._onTouchedFn();
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const glAccount = match.item as ExtendedGLAccountModel;
            this._select(glAccount.model.accountNumber);
            this.changed.emit(glAccount.model.accountNumber);
        }
    }

    private _filter(filter: string): Observable<ExtendedGLAccountModel[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }

        return observableOf(
            (this._glAccounts || []).filter(x => filter === '' ||
                x.displayName.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _select(value: string): void {
        this.value = value;

        const glAccount = _.find(this._glAccounts, (x) => x.model.accountNumber === value);

        if (glAccount) {
            this.filter = value;
        } else {
            this.filter = null;
        }
    }
}
