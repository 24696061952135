(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('CompleteFileAppealModalController', CompleteFileAppealModalController);

    CompleteFileAppealModalController.$inject = ['taskIDs', '$uibModalInstance', 'processingService', 'searchTimestamp', 'optionalArgs', 'taskService', 'messageBoxService'];
    function CompleteFileAppealModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(taskIDs, $uibModalInstance, processingService, searchTimestamp, optionalArgs, taskService, messageBoxService) {
        var vm = this;

        vm.payload = {
            taskIDs: taskIDs,
            filingReference: null,
            searchTimestamp: searchTimestamp
        }

        activate();

        ////////////////

        async function activate() {
            vm.loading = true;
            const warning = await taskService.validateAssessorStateReqs(taskIDs);
            if (warning) {
                await messageBoxService.warning(warning);
            }
            vm.loading = false;
        }

        vm.cancel = function () {
            $uibModalInstance.dismiss();
        }

        vm.process = function () {
            if (optionalArgs.parentCallsService) {
                $uibModalInstance.close(function () {
                    return processingService.completeFileAppeal(vm.payload);
                });
            }
            else {
                vm.loading = true;

                processingService.completeFileAppeal(vm.payload).then(function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    $uibModalInstance.dismiss();
                });
            }

            //// Code to demonstrate partial success
            //console.log(['Faking out completing tasks', vm.payload]);
            //setTimeout(function () {
            //    var result = {
            //        errorResult: null
            //    };

            //    result.actionResults = _.map(vm.payload.taskIDs, function (taskID) {
            //        return {
            //            taskActionResult: {
            //                taskID: taskID,
            //                result: null, // would have value in real-world example
            //                errorMessage: null,
            //                isAuthorized: true,
            //                authorizationReason: 'Assigned User'
            //            }
            //        };
            //    });

            //    result.actionResults[0].taskActionResult = {
            //        taskID: result.actionResults[0].taskActionResult.taskID,
            //        result: null,
            //        errorMessage: 'An unexpected error has occurred: [uuid]',
            //        isAuthorized: true,
            //        authorizationReason: null
            //    };

            //    $uibModalInstance.close(result);
            //}, 500);
        }
    }
})();
