import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SupportInstanceRepository extends BaseRepository{
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private _pathPrefix = 'api/supportInstance';

    getList(): Observable<Core.SupportInstanceInfoModel[]> {
        return this.httpGet(`${this._pathPrefix}/list`);
    }

    requestAccess(request: Core.SupportInstanceRequestModel): Observable<Core.SupportInstanceInfoModel> {
        return this.httpPost(`${this._pathPrefix}/requestAccess`, request);
    }

    terminateAccess(instanceId: number): Observable<void> {
        return this.httpPost(`${this._pathPrefix}/terminateAccess`, instanceId);
    }
}
