import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const FORM_HELP: HelpContent[] = [
    {
        helpContentId: 'form.is-form-consolidated',
        tooltipText: 'Consolidated Form'
    },
    {
        helpContentId: 'form.all-assessors-certified',
        tooltipText: 'All assessors are certified.'
    },
    {
        helpContentId: 'form.not-all-assessors-certified',
        tooltipText: 'Not all assessors are certified.'
    },
    {
        helpContentId: 'form.electronic-filing-type-state',
        tooltipText: 'PropertyPoint does not currently support electronic filings for this state.'
    },
    {
        helpContentId: 'form.electronic-filing-is-supported',
        tooltipText: 'Electronic filing of this form is supported.'
    },
    {
        helpContentId: 'form.electronic-filing-type-locked-batch',
        tooltipText: 'Electronic Filing Type cannot be modified because this form revision is in use in one or more locked batches.'
    },
];
