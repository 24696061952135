import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {
    CellValueChangedEvent,
    ColDef,
    Column,
    ColumnApi,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IGetRowsParams
} from 'ag-grid-community';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { EntityImportEditorAgGridDataSource, EntityImportEditorDataSourceParams } from './agGridDataSource';
import { EntityImportProcessService } from '../../importProcess.service';
import { AssetClassificationRepository, AssetRepository, EntityImportRepository } from '../../../../Repositories';
import {
    EntityImportEditorEstimatedActionHeaderRendererComponent
} from './agGridEstimatedActionHeaderRenderer.component';
import {
    EntityImportEditorEstimatedActionFilterRendererComponent
} from './agGridEstimatedActionFilterRenderer.component';
import { EntityImportEditorEstimatedActionCellRendererComponent } from './agGridEstimatedActionCellRenderer.component';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../../../Busy-Indicator';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder, AgGridTextCellEditor } from '../../../../AgGrid';
import {
    AgGridMultiSelectCellRendererParams,
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../../AgGrid/MultiSelectTracker';
import {
    EntityImportEditorAssetClassSelectorCellEditorComponent,
    EntityImportEditorAssetClassSelectorCellEditorParams
} from './SelectorRenderers/agGridAssetClassSelectorRenderer.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FieldInfo, ImportBulkUpdateComponent } from './Import-Bulk-Update/importBulkUpdate.component';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import {
    MessageBoxButtons,
    MessageBoxOptions,
    MessageBoxResult,
    MessageBoxService
} from '../../../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import {
    EntityImportEditorAssessorSelectorCellEditorComponent,
    EntityImportEditorAssessorSelectorCellEditorParams
} from './SelectorRenderers/agGridAssessorSelectorRenderer.component';
import {
    EntityImportEditorAttachmentTypeSelectorCellEditorComponent,
    EntityImportEditorAttachmentTypeSelectorCellEditorParams
} from './SelectorRenderers/agGridAttachmentTypeSelectorRenderer.component';
import { takeUntil } from 'rxjs/operators';
import {
    EntityImportEditorLeaseTypeSelectorCellEditorComponent,
    EntityImportEditorLeaseTypeSelectorCellEditorParams
} from './SelectorRenderers/agGridLeaseTypeSelectorRenderer.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../../AgGrid/ToolPanel/models';
import {
    EntityImportEditorAssessmentClassSelectorCellEditorComponent,
    EntityImportEditorAssessmentClassSelectorCellEditorParams
} from "./SelectorRenderers/agGridAssessmentClassSelectorRenderer.component";
import {
    EntityImportEditorGLAccountSelectorCellEditorComponent,
    EntityImportEditorGLAccountSelectorCellEditorParams
} from "./SelectorRenderers/agGridGLAccountSelectorRenderer.component";
import { DecimalPipe } from "@angular/common";
import {
    EntityImportEditorTaxingDistrictSelectorCellEditorParams,
    EntityImportEditorTaxingSelectorCellEditorComponent
} from "./SelectorRenderers/agGridTaxingDistrictSelectorRenderer.component";
import {
    EntityImportEditorOneTimeVRecurringSelectorRendererCellEditorComponent
} from './SelectorRenderers/agGridOneTimeVRecurringSelectorRenderer.component';
import {
    EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent,
    EntityImportEditorIncomeStatementCategorySelectorCellEditorParams
} from './SelectorRenderers/agGridIncomeStatementCategorySelectorRenderer.component';
import {
    EntityImportEditorGLAccountTypeSelectorCellEditorComponent,
    EntityImportEditorGLAccountTypeSelectorCellEditorParams
} from './SelectorRenderers/agGridGLAccountTypeSelectorRenderer.component';
import { UtilitiesService } from 'src/app/UI-Lib/Utilities/Utilities.Service.upgrade';
import { LongRunningProcessService } from '../../../../Long-Running-Process/longRunningProcess.service';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { EntityImportColumnMappingStepService } from '../../Column-Mapping-Step/columnMappingStep.service';

const KEY_ENTER = 13;

export interface ImportGridRow extends ColDef {
    ws: Compliance.EntityImportEditingColDefMetadata
}

@Component({
    selector: 'entity-import-editor',
    templateUrl: './editor.component.html'
})
export class EntityImportEditorComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _entityImportProcessService: EntityImportProcessService,
        private readonly _assetClassificationRepository: AssetClassificationRepository,
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _assetRepository: AssetRepository,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _columnMappingStepService: EntityImportColumnMappingStepService
    ) {}

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;
    private _gridDataSource: EntityImportEditorAgGridDataSource;
    private _validationErrors: Compliance.ImportFileValidationErrorModel[];
    private _errorFilterSub: Subscription;
    private _evaluatingSub: Subscription;
    private _validationSummarySub: Subscription;
    private _transferredSub: Subscription;
    private _importFileModel: Compliance.ImportFileModel;
    private _gridMultiSelectSub: Subscription;
    private _assetClassifications: Compliance.AssetClassificationModel[];
    private _assessors: Compliance.ImportAssessorModel[];
    private _sortingColumns: Compliance.NameValuePair<string>[] = [{name: 'rowIndex_1', value: '-1'} as Compliance.NameValuePair<string>];
    private _selectionSupportBulkOperation: boolean = false;
    private _selectionSupportDeleteOperation: boolean = false;
    private _defaultSortModel = [
        {
            colId: 'rowIndex_1',
            sort: 'asc'
        }
    ];
    private _leaseTypes: Compliance.LeaseTypeModel[];
    private _isImportCompleted: boolean = false;
    private _showTotals: boolean = false;
    private _fieldNames: string[] = [];
    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject<void>();
    private _destroyIndicator$: Subject<void> = new Subject<void>();
    private _taxingDistricts: Core.TaxingDistrictModel[];
    private _glAccountTypes: Compliance.GLAccountTypeModel[];
    private _selectedRowIndex: number = -1;
    private _selectedRow: Compliance.ImportGridRowGridModel;
    private _updateCellParams: CellValueChangedEvent;
    private _notValidatedUpdates: Compliance.ImportGridNotValidatedUpdateModel[] = [];

    @Input() set importFileModel(importFile: Compliance.ImportFileModel) {
        this._importFileModel = importFile;
        this.totalRows = importFile.totalRows;
        this._isImportCompleted = this._importFileModel.processStatus === Compliance.ImportFileProcessStatusEnum.Completed;
        this._showTotals = this._importFileModel.importContentType.supportsTotals;
    }
    @Input() showTransferredRows: boolean = false;
    @Input() busyRefId: string;
    @Input() selectedRowsInfo$: BehaviorSubject<Compliance.ImportSelectedRowsInfoModel>;

    gridTracker: AgGridMultiSelectTracker;
    totalRows: number = 0;
    filteredRows: number = 0;
    totalValidCount: number = 0;
    hasError: boolean;
    gridId: System.Guid = 'F1D496E2-BB3B-4984-995D-0BA54F9C88CA';
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    importGridTotalsRow: any;
    importGridSelectedTotalsRow: any;
    selectedTotalsTimestamp: number;
    selectedTotalsLoading: boolean = true;
    showValidRowsOnly: boolean;
    showDynamicFieldConfiguration: boolean;

    get importFileModel(): Compliance.ImportFileModel {
        return this._importFileModel;
    }

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'row-transferred': params => !!(params.data && (params.data as Compliance.ImportGridRowGridModel).actualAction),
            'ag-row-selected': params => {
                const rowModel = params.data as Compliance.ImportGridRowGridModel;
                return rowModel &&
                    this.gridTracker.isRowSelected(rowModel.rowIndex) &&
                    !this._isRowSelectionDisabled(rowModel.rowIndex, rowModel) &&
                    !this._isRowSelectionHidden(rowModel.rowIndex, rowModel);
            }
        },
        suppressFieldDotNotation: true,
        suppressCellSelection: true
    })
    .withContext(this)
    .withInfiniteScroll()
    .withLoadingOverlay()
    .withColumnResize()
    .withMultipleColumnSort()
    .withTextSelection()
    .withoutCellEditingStoppedOnGridLostFocus()
    .build();

    gridColumnFilters = {
        allColumns: 'All Columns',
        mappedColumns: 'Mapped Columns',
    };

    selectedGridColumnFilter = this.gridColumnFilters.mappedColumns;

    async ngOnInit(): Promise<void> {
        this._evaluatingSub = this._entityImportProcessService.evaluating$.subscribe((promise: Promise<Compliance.ImportEvaluateResultModel>) => {
            promise.then(() => {
                this._refreshDataSource(false);
            });
        });

        this._validationSummarySub = this._entityImportProcessService.validationSummaryChanged$.subscribe((validationErrors: Compliance.ImportFileValidationErrorModel[]) => {
            this.hasError = _.some(validationErrors, (x) => { return !x.isWarning; });

            if (this.gridTracker && (this.gridTracker.getSelectedRowsModel().selectAllRows || this.gridTracker.getSelectedRowsModel().selectedRows)){
                this._refreshDataSource(false);
            }
        });

        this._transferredSub = this._entityImportProcessService.transferCompletedEventEmitter.subscribe(() => {
            this._refreshDataSource();
        });

        this.showTransferredRows = this.importFileModel.totalRows === this.importFileModel.transferredRows ||
            this._isImportCompleted;

        this.selectedRowsInfo$.pipe(takeUntil(this._destroy$)).subscribe(data => {
            const hasDataForBulkOperation = !this._isImportCompleted && data && (!!data.invalidRowsCount || !!data.validRowsCount);

            this._selectionSupportDeleteOperation = hasDataForBulkOperation;

            if (hasDataForBulkOperation) {
                const bulkUpdateFields = this._getBulkUpdateFields();
                this._selectionSupportBulkOperation = bulkUpdateFields.length !== 0;
            }

            this.isBulkDeleteVisible$.next(this._selectionSupportDeleteOperation);
            this.isBulkUpdateVisible$.next(this._selectionSupportBulkOperation);
        });

        this._entityImportProcessService.notValidatedUpdates$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            if (x === 0) {
                this._notValidatedUpdates = [];
                this._gridApi.redrawRows();
            }
        });

        this.showDynamicFieldConfiguration = this.importFileModel.importContentType.supportsDynamicFieldBehaviorConfiguration;
    }

    /**
    * Modified version of gridTracker's method, easier to externalize change in behavior. In our case we have
    * to be aware of the total valid rows as well as the selection. We don't actually need to worry about there
    * being invalid rows selected, because they can never be selected, they are just impliedly selected via a
    * select all activity when no filter is set. In our version, instead of using the grid tracker's version of
    * the total rows count, we use our own external count updated when results are fetched with no column filter
    */
    getSelectedRowsCount():number {
        if (!this.gridTracker) {
            return 0;
        }
        if (this.gridTracker.getSelectedRowsModel().selectAllRows) {
            return this.totalValidCount - this.gridTracker.getSelectedRowsModel().selectedRows.length;
        }
        return this.gridTracker.getSelectedRowsModel().selectedRows.length;

    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._destroyIndicator$.next();
        this._destroyIndicator$.complete();
        this._errorFilterSub && this._errorFilterSub.unsubscribe();
        this._evaluatingSub.unsubscribe();
        this._validationSummarySub.unsubscribe();
        this._transferredSub.unsubscribe();
        this._gridMultiSelectSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        // get API objects and start setting up the AgGrid
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        // setup AgGrid column for row selection
        const selectRowColumnDef: ImportGridRow = {
            headerName: '',
            field: 'rowIndex',
            width: AgGridColumns.selectionColumnWidth,
            suppressMovable: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
            headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            cellRendererFramework: AgGridMultiSelectedCellRenderer,
            cellRendererParams: {
                tracker: this.gridTracker,
                isCellRendererDisabledFn: this._isRowSelectionDisabled.bind(this),
                isCellRendererHiddenFn: this._isRowSelectionHidden.bind(this)
            } as AgGridMultiSelectCellRendererParams
        } as ImportGridRow;

        // setup AgGrid column for row action estimation
        const estimatedActionColumnDef: ImportGridRow = {
            field: 'estimatedAction',
            suppressMovable: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            filter: 'agTextColumnFilter',
            cellRendererFramework: EntityImportEditorEstimatedActionCellRendererComponent,
            headerComponentFramework: EntityImportEditorEstimatedActionHeaderRendererComponent,
            floatingFilterComponentFramework: EntityImportEditorEstimatedActionFilterRendererComponent,
            floatingFilterComponentParams: {
                filterValue: null,
                suppressFilterButton: true
            },
            width: AgGridColumns.rowNumberColumnWidth
        } as ImportGridRow;

        // setup AgGrid column for row #
        const rowIdColumnDef: ImportGridRow = {
            headerName: 'Row',
            field: 'rowIndex',
            width: AgGridColumns.rowNumberColumnWithSortingWidth,
            type: 'numericColumn',
            editable: false,
            suppressMovable: true,
            suppressSizeToFit: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true,
            // custom data
            ws: null
        } as ImportGridRow;

        // setup AgGrid columns for import file columns
        const fileColumnDefList: ImportGridRow[] = _
            .chain(this._importFileModel.importFileHeaders)
            .map(ifh => {
                const assignedField = this._importFileModel.assignedFields.find(af => af.value === `${ifh.importFileHeaderId}` && !af.isStatic);
                const field = assignedField && this._importFileModel.importContentType.importFields.find(f => f.importFieldId === assignedField.importFieldId);
                const headerName = field ? field.displayName : ifh.name;
                const key = field ? field.displayName : ifh.index.toString();
                this._fieldNames.push(key);
                const colDef = {
                    headerName: headerName,
                    field: key,
                    suppressMovable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    // if it's not one of the mapped columns (not mapped to a field) than do not allow editing
                    editable: (params) => {
                        const gridRow = params.data as Compliance.ImportGridRowGridModel;
                        return field && gridRow && !gridRow.actualAction && UtilitiesService.isNumeric(gridRow.rowIndex);
                    },
                    cellEditorFramework: AgGridTextCellEditor,
                    cellEditorParams: {
                        canReset: true,
                        hasOverride: (params) => params.data && params.data.originalValues && (params.data.originalValues[params.colDef.field] !== params.data[params.colDef.field] || params.data.originalValues[params.colDef.field] != params.data[params.colDef.field])
                    },
                    singleClickEdit: true,
                    // initialize as hidden, will be applying column filters after column definitions are set and that will take care of setting the visibility
                    hide: true,
                    cellClass: (params) => {
                        let result = (params.data && params.data.validationStatus && params.data.validationStatus[params.colDef.field]) || '';
                        if (params.data) {
                            const ws = (params.colDef as ImportGridRow).ws;

                            if (ws && ws.columnIndex &&
                                this._notValidatedUpdates.some(x => x.columnIndex == ws.columnIndex &&
                                x.rowIndex == params.data.rowIndex)) {
                                result = 'ws-calculated';
                            } else if (params.data.originalValues && params.data.originalValues[params.colDef.field] !== params.data[params.colDef.field]) {
                                result += ' ws-override';
                            }
                        }
                        return result;
                    },
                    onCellValueChanged: this.onAgCellValueChanged.bind(this),
                    // custom metadata
                    ws: {
                        isStatic: false,
                        fieldMappingId: (assignedField && assignedField.importFileSpecificationFieldId) || null,
                        isMappedToRequiredFields: (field && field.isMappingRequired) || false,
                        columnIndex: ifh.index
                    } as Compliance.EntityImportEditingColDefMetadata
                } as ImportGridRow;

                if (field) {
                    switch (field.uiCellRenderer) {
                        case 'AssetClassSelector':
                            colDef.cellEditorFramework = EntityImportEditorAssetClassSelectorCellEditorComponent;
                            colDef.cellEditorParams = {getAssetClassifications: this._getAssetClassifications.bind(this)} as EntityImportEditorAssetClassSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'AssessorSelector':
                            colDef.cellEditorFramework = EntityImportEditorAssessorSelectorCellEditorComponent;
                            colDef.cellEditorParams = { getAssessors: this._getAssessors.bind(this) } as EntityImportEditorAssessorSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'AttachmentTypeSelector':
                            colDef.cellEditorFramework = EntityImportEditorAttachmentTypeSelectorCellEditorComponent;
                            colDef.cellEditorParams = {getAttachmentTypes: this._getAttachmentTypes.bind(this)} as EntityImportEditorAttachmentTypeSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'LeaseTypeSelector':
                            colDef.cellEditorFramework = EntityImportEditorLeaseTypeSelectorCellEditorComponent;
                            colDef.cellEditorParams = {getLeaseTypes: this._getLeaseTypes.bind(this)} as EntityImportEditorLeaseTypeSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'AssessmentClassSelector':
                            colDef.cellEditorFramework = EntityImportEditorAssessmentClassSelectorCellEditorComponent;
                            colDef.cellEditorParams = {getAssessmentClasses: this._getAssessmentClasses.bind(this)} as EntityImportEditorAssessmentClassSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'GLAccountSelector':
                            colDef.cellEditorFramework = EntityImportEditorGLAccountSelectorCellEditorComponent;
                            colDef.cellEditorParams = {getGLAccounts: this._getGLAccounts.bind(this)} as EntityImportEditorGLAccountSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'TaxingDistrictSelector':
                            colDef.cellEditorFramework = EntityImportEditorTaxingSelectorCellEditorComponent;
                            colDef.cellEditorParams = { getTaxingDistricts: this._getTaxingDistricts.bind(this) } as EntityImportEditorTaxingDistrictSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'OneTimeVRecurringSelector':
                            colDef.cellEditorFramework = EntityImportEditorOneTimeVRecurringSelectorRendererCellEditorComponent;
                            colDef.cellEditorParams = null;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'GLAccountTypeSelector':
                            colDef.cellEditorFramework = EntityImportEditorGLAccountTypeSelectorCellEditorComponent;
                            colDef.cellEditorParams = { getGLAccountTypes: this._getGLAccountTypes.bind(this)} as EntityImportEditorGLAccountTypeSelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                        case 'IncomeStatementCategorySelector':
                            colDef.cellEditorFramework = EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent;
                            colDef.cellEditorParams = { getGlAccountTypeId: this._getGlAccountTypeId.bind(this)} as EntityImportEditorIncomeStatementCategorySelectorCellEditorParams;
                            colDef.suppressKeyboardEvent = (params) => {
                                const keyCode = params.event.keyCode;
                                return params.editing && (keyCode === KEY_ENTER);
                            };
                            break;
                    }
                }

                const header = (field && assignedField) ?
                    this._importFileModel.importFileHeaders.find(i => i.importFileHeaderId.toString() === assignedField.value) :
                    this._importFileModel.importFileHeaders.find(i => i.index.toString() === key);

                this._sortingColumns.push({name: key, value: header.index.toString()} as Compliance.NameValuePair<string>);

                return colDef;
            })
            .value();

        // setup AgGrid columns for static columns
        const staticColumnDef: ImportGridRow[] = _
            .chain(this._importFileModel.importContentType.importFields)
            .filter(f => this._importFileModel.assignedFields.filter(af => af.isStatic).map(af => af.importFieldId).includes(f.importFieldId))
            .map(i => {
                const assignedField = this._importFileModel.assignedFields.find(af => af.importFieldId === i.importFieldId);
                const field = assignedField && this._importFileModel.importContentType.importFields.find(f => f.importFieldId === assignedField.importFieldId);
                this._fieldNames.push(i.displayName);
                return {
                    headerName: i.displayName,
                    field: i.displayName,
                    suppressMovable: true,
                    editable: false,
                    sortable: false,
                    cellClass: (params) => {
                        return  'static-cell ' + ((params.data && params.data.validationStatus && params.data.validationStatus[params.colDef.field])||'');
                    },
                    // custom metadata
                    ws: {
                        isStatic: true,
                        fieldMappingId: assignedField.importFileSpecificationFieldId,
                        isMappedToRequiredFields: (field && field.isMappingRequired) || false
                    } as Compliance.EntityImportEditingColDefMetadata
                } as ImportGridRow;
            })
            .value();

        // set AgGrid columns
        this._gridApi.setColumnDefs([
            selectRowColumnDef,
            estimatedActionColumnDef,
            rowIdColumnDef,
            ...fileColumnDefList,
            ...staticColumnDef
        ]);

        // apply column filters (hiding/showing columns)
        this._applyGridColumnFilter();

        this._setDataSource();

        this._gridApi.setSortModel(this._defaultSortModel);

        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            const data: Compliance.ImportBulkOperationModel = {
                validationErrors: this._validationErrors || [],
                columnFilters: this._gridDataSource && this._gridDataSource.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams) || [],
                estimatedActionFilter: this._gridDataSource && this._gridDataSource.estimatedActionFilter(this._gridDataSource.gridParams),
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows
            };

            this._entityImportProcessService.notifySelectedRowsChanged(data, this.getSelectedRowsCount());

            if (this._showTotals) {
                await this._reloadTotals();
            }
        });

        this._errorFilterSub = this._entityImportProcessService.errorFilterChanged$.subscribe((errors) => {
            // if they're the same empty array then do nothing (preventing double loading)
            if (this._validationErrors && this._validationErrors.length === 0 && (errors || []).length === 0) return;
            // setup AgGrid data source for infinite scrolling, will be reset every time the validation changes
            this._validationErrors = errors || [];

            if (_.filter(this._validationErrors, item => !item.isWarning).length !== 0){
                this.showValidRowsOnly = false;
            }

            this._refreshDataSource();
        });
    }

    async onAgCellValueChanged(params: CellValueChangedEvent): Promise<void> {
        let newValue = params.newValue;
        const oldValue = params.oldValue;

        if (newValue === oldValue) {
            return;
        }

        this._showBusyIndicator('Update', 'Updating Value', null);

        try {
            await this._updateCell(newValue, params);

            const ws = (params.colDef as ImportGridRow).ws;
            if (!this._notValidatedUpdates.some(x => x.rowIndex == this._selectedRowIndex &&
                x.columnIndex == ws.columnIndex))
            {
                this._notValidatedUpdates.push({
                    rowIndex: this._selectedRowIndex,
                    columnIndex: ws.columnIndex});

                params.api.refreshCells({
                    columns: [params.column.getColId()],
                    rowNodes: [params.node],
                    force: true
                });
            }

            if (!newValue) {
                const colId = params.column.getColId();
                params.node.setDataValue(colId, params.node.data.originalValues[colId]);
            }
        } finally {
            await this._hideBusyIndicator();
        }
    }

    filterGridColumns(filter: string): void {
        this.selectedGridColumnFilter = filter;
        this._applyGridColumnFilter();
    }

    showTransferredRowsChange(): void {
        this._refreshDataSource();
    }

    showValidRowsOnlyChange(): void {
        this.gridTracker.clear();
        this._entityImportProcessService.notifyShowValidRowsOnlyChanged(this.showValidRowsOnly);

        if (!this.showValidRowsOnly || !this._validationErrors || this._validationErrors.length === 0){
            this._refreshDataSource();
        }
    }

    async bulkUpdate(): Promise<void> {
        const bulkUpdateFields = this._getBulkUpdateFields();

        const fields = await Promise.all(bulkUpdateFields.map(async (fieldInfo) => {
                switch (fieldInfo.uiCellRenderer) {
                    case 'AssetClassSelector':
                        fieldInfo.additionalParams = this._assetClassifications;
                        break;
                    case 'AssessorSelector':
                        fieldInfo.additionalParams = await this._getAssessors(null);
                        break;
                    case 'AssessmentClassSelector':
                        fieldInfo.additionalParams = await this._getAssessmentClasses(null);
                        break;
                    case 'GLAccountSelector':
                        fieldInfo.additionalParams = await this._getGLAccounts(null);
                        break;
                    case 'TaxingDistrictSelector':
                        fieldInfo.additionalParams = await this._getTaxingDistricts(null);
                        break;
                    case 'GLAccountTypeSelector':
                        const glAccountTypes = await this._getGLAccountTypes();
                        fieldInfo.additionalParams  = [{
                            glAccountTypeId: null,
                            name: '',
                            validForAccrual: false
                        }, ...glAccountTypes];
                        break;
                }

                return fieldInfo;
            }));

        const showModalFn = (): BsModalRef => {
            const selectionModel: Compliance.ImportBulkOperationModel = {
                validationErrors: this._validationErrors || [],
                columnFilters: this._gridDataSource.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams) || [],
                estimatedActionFilter: this._gridDataSource.estimatedActionFilter(this._gridDataSource.gridParams),
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows
            };

            return this._bsModalService.show(ImportBulkUpdateComponent, {
                ignoreBackdropClick: true,
                initialState: {
                    importFileId: this.importFileModel.importFileId,
                    mappedFields: fields,
                    selectionModel: selectionModel,
                    saveCompleteCallback: this._bulkUpdateSaveCompleteCallback.bind(this),
                    selectedCount: this.gridTracker.getSelectedRowsCount()
                }
            });
        };

        this._wsModalService.show(showModalFn.bind(this));
    }

    async bulkDelete(): Promise<void> {
        const selectedRowsCount = this.gridTracker.getSelectedRowsCount();

        const confirmOptions = {
            message: `Are you sure you want to delete row${selectedRowsCount > 1 ? 's' : ''}?`,
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {
            this._busyRef = this._busyIndicatorService.show({
                identifier: this.busyRefId,
                longRunningProcessId: null,
                title: '',
                message: 'Deleting Rows',
                hasProgressBar: false
            });

            const selectionModel: Compliance.ImportBulkOperationModel = {
                validationErrors: this._validationErrors || [],
                columnFilters: this._gridDataSource.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams) || [],
                estimatedActionFilter: this._gridDataSource.estimatedActionFilter(this._gridDataSource.gridParams),
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows
            };

            try {
                this.importFileModel = await this._entityImportProcessService.deleteStagingRows(this.importFileModel.importFileId, selectionModel);
                if (!this.importFileModel.transferredRows){
                    this._refreshDataSource();
                    this._entityImportProcessService.notifyClearErrorsWarningsSelection();
                }
                this.gridTracker.clear(false);
            }
            finally{
                await this._hideBusyIndicator();
            }

            this._entityImportProcessService.forceValidate();
        }
    }

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const selectionModel: Compliance.ImportGridSearchModel = {
                validationErrors: this._validationErrors || [],
                columnFilters: this._gridDataSource.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams) || [],
                estimatedActionFilter: this._gridDataSource.estimatedActionFilter(this._gridDataSource.gridParams),
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
                showTransferredRows: this.showTransferredRows,
                showValidRowsOnly: this.showValidRowsOnly,
                includeOnlyTotals: false,
                includeTotals: false
            };

            const exportModel = {
                searchModel: selectionModel,
                columnsToReturn: columnsToReturn,
                } as Compliance.ImportExportStagingRowsModel;
            const lrp$ = this._entityImportRepository.exportStagingRows(this.importFileModel.importFileId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportImportStagingRows };
        },
        canCancel: true
    };

    private async _updateCell(newValue: string, params: CellValueChangedEvent): Promise<void> {
        this._selectedRow = params.data as Compliance.ImportGridRowGridModel;
        this._selectedRowIndex = this._selectedRow.rowIndex;
        this._updateCellParams = params;
        const importFileSpecificationFieldId = (params.colDef['ws'] as Compliance.EntityImportEditingColDefMetadata).fieldMappingId;
        await this._entityImportProcessService.updateCell(this._importFileModel.importFileId, this._selectedRow.rowIndex, importFileSpecificationFieldId, newValue);
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: false,
            canDismiss: false
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            if (success) {
                try {
                    const longRunningProcessResult = await this._longRunningProcessService.get(this._entityImportProcessService.longRunningProcessId)
                    const result: Compliance.ImportUpdateFieldProcessResultModel = JSON.parse(longRunningProcessResult.result);

                    if (this._selectedRow.rowIndex !== result.selectedRowIndex) {
                        return;
                    }

                    const props = this._getPageInvolvedProperties();

                    if (result.moreThanOneRowsAffected || props.find(prop => prop === this._updateCellParams.column.getColId())) {
                        this._refreshDataSource(false);
                    }

                    this._selectedRow["estimatedAction"] = null;
                    const newRowData = { ...this._selectedRow };
                    newRowData.validationStatus = {};

                    newRowData.hasError = false;
                    result.rowValidationSummary.forEach(rvs => {
                        newRowData.hasError = newRowData.hasError || !rvs.isWarning && rvs.errorSource === 'Validation';
                        newRowData.validationStatus[rvs.fieldName] = rvs.isWarning ? 'cell-validation-warning' : 'cell-validation-error';
                    });

                    this._entityImportProcessService.notifyValidationSummaryChanged(result.importValidationSummary);

                    // if the row has an error, ensure it isn't selected
                    if (newRowData.hasError && this.gridTracker.isRowSelected(newRowData.rowIndex)) {
                        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
                        const index = selectedRowsModel.selectedRows.indexOf(newRowData.rowIndex);
                        if (index !== -1) {
                            selectedRowsModel.selectedRows.splice(index, 1);
                        }
                        this.gridTracker.setSelectedRowsModel(selectedRowsModel);
                    }

                    if (result.moreThanOneRowsAffected) {
                        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
                        if (selectedRowsModel.selectedRows && selectedRowsModel.selectedRows.length != 0 || selectedRowsModel.selectAllRows){
                            await this._reloadTotals();
                        }
                    }

                    if (newRowData) {
                        const hasNullValues = Object.keys(newRowData.originalValues).find(x => newRowData[x] === null);
                        if (hasNullValues) {
                            this._refreshDataSource(false);
                        } else {
                            this._updateCellParams.node.setData(newRowData);
                            this._gridApi.redrawRows({ rowNodes: [this._updateCellParams.node] });
                        }

                        if (this.importFileModel.importContentType.supportsTotals) {
                            await this._gridDataSource.forceRefreshOfTotalValidRows(this.showTransferredRows, this.showValidRowsOnly);
                        }
                    } else {
                        const colId = this._updateCellParams.column.getColId();
                        this._updateCellParams.node.setDataValue(colId, this._updateCellParams);
                    }
                } finally {
                    await this._hideBusyIndicator();
                    this._selectedRowIndex = -1;
                }
            } else {
                await this._hideBusyIndicator();
            }
        });
    }

    private _getPageInvolvedProperties(): string[] {
        const props = this._gridApi.getSortModel().map(sortColumn => sortColumn.colId);
        const keys = Object.keys(this._gridApi.getFilterModel());
        return props.concat(keys);
    }

    private _getGridColumnsAssociatedWithImportFileColumns(): Column[] {
        return this._gridColumnApi
            .getAllColumns()
            .filter((i) => {
                const metadata: Compliance.EntityImportEditingColDefMetadata = i.getColDef()['ws'];
                return metadata && !metadata.isStatic;
            });
    }

    private _getGridColumnsAssociatedWithMappedImportFileColumns(): Column[] {
        return this._getGridColumnsAssociatedWithImportFileColumns()
            .filter((i) => {
                const fieldMappingId: number = (i.getColDef()['ws'] as Compliance.EntityImportEditingColDefMetadata).fieldMappingId;
                return (fieldMappingId && fieldMappingId > 0) || false;
            });
    }

    private _getGridColumnsAssociatedWithUnmappedImportFileColumns(): Column[] {
        return this._getGridColumnsAssociatedWithImportFileColumns()
            .filter((i) => {
                const fieldMappingId: number = (i.getColDef()['ws'] as Compliance.EntityImportEditingColDefMetadata).fieldMappingId;
                const isMappedColumn = (fieldMappingId && fieldMappingId > 0) || false;
                return !isMappedColumn;
            });
    }

    private _getGridColumnsAssociatedWithStaticImportFileColumns(): Column[] {
        return this._gridColumnApi
            .getAllColumns()
            .filter((i) => {
                const metadata: Compliance.EntityImportEditingColDefMetadata = i.getColDef()['ws'];
                return metadata && metadata.isStatic;
            });
    }

    private _applyGridColumnFilter(): void {
        const mappedColumns = this._getGridColumnsAssociatedWithMappedImportFileColumns();
        const unmappedColumns = this._getGridColumnsAssociatedWithUnmappedImportFileColumns();
        const staticColumns = this._getGridColumnsAssociatedWithStaticImportFileColumns();

        const allColumns = ([] as Column[]).concat(mappedColumns).concat(unmappedColumns).concat(staticColumns);

        switch (this.selectedGridColumnFilter) {
            case this.gridColumnFilters.allColumns:
                allColumns.forEach(i => {
                    this._gridColumnApi.setColumnVisible(i, true);
                    const colDef = i.getColDef();
                    const colDefMetadata: Compliance.EntityImportEditingColDefMetadata = colDef['ws'];
                    colDef.headerClass = colDefMetadata.fieldMappingId ? `header-cell ${colDefMetadata.isMappedToRequiredFields ? 'mapping-required' : 'mapping-optional'}` : '';
                });
                this._gridApi.refreshHeader();
                break;

            case this.gridColumnFilters.mappedColumns:
                let visibleColumnsCount = 0;
                allColumns.forEach(i => {
                    // the "mapped columns" UI filter should include both mapped columns from the file and columns "mapped" as static columns
                    if (mappedColumns.indexOf(i) === -1 && staticColumns.indexOf(i) === -1) {
                        this._gridColumnApi.setColumnVisible(i, false);
                    } else {
                        this._gridColumnApi.setColumnVisible(i, true);
                        visibleColumnsCount++;
                    }

                    const colDef = i.getColDef();
                    colDef.headerClass = '';
                });
                this._gridApi.refreshHeader();
                if (visibleColumnsCount <= 8){
                    this._gridApi.sizeColumnsToFit();
                }

                break;

            default:
                console.warn('Cannot filter grid columns. An unknown filter is specified.');
                break;
        }
    }

    private _refreshDataSource(clearSelection: boolean = true): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        if (clearSelection) {
            this.gridTracker.clear();
        }

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): EntityImportEditorDataSourceParams => {
            return {
                importFileModel: this._importFileModel,
                validationSummaryFilters: this._validationErrors,
                showTransferredRows: this.showTransferredRows,
                showValidRowsOnly: this.showValidRowsOnly,
                sortingColumns: this._sortingColumns
            }
        }

        this._gridDataSource = new EntityImportEditorAgGridDataSource(
            this._gridApi,
            this._entityImportRepository,
            dataSourceParams,
            (count) => this._setFilteredRowCount(count),
            (count) => this._setTotalValidCount(count),
            this._handleTotalsUpdate,
            (updates: Compliance.ImportGridNotValidatedUpdateModel[], totalNotValidatedUpdates: number) => {
                this._notValidatedUpdates = updates;
                this._entityImportProcessService.notifyCellUpdated(totalNotValidatedUpdates);
            }
        );
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private _setFilteredRowCount(filteredRowCount) {
        this.filteredRows = filteredRowCount;
        this.totalRows = filteredRowCount;
    }

    private _setTotalValidCount(totalValidCount) {
        this.totalValidCount = totalValidCount;
    }

    private _isRowSelectionDisabled(rowIndex: number, data: Compliance.ImportGridRowGridModel): boolean {
        return false;
    }

    private _isRowSelectionHidden(rowIndex: number, data: Compliance.ImportGridRowGridModel): boolean {
        if (!this._isImportCompleted && UtilitiesService.isNumeric(rowIndex) && data) {
            return !!data.actualAction; // hide checkbox selection for transferred rows
        }

        return !UtilitiesService.isNumeric(rowIndex);
    }

    private async _bulkUpdateSaveCompleteCallback(selectionModel: Compliance.ImportBulkOperationModel): Promise<void> {
        this._refreshDataSource(false);
        this._entityImportProcessService.notifySelectedRowsChanged(selectionModel, this.getSelectedRowsCount());

        if (this._showTotals) {
            await this._reloadTotals();
        }
    }

    private async _getAssessors(rowIndex?: number): Promise<Compliance.ImportAssessorModel[]> {
        this._assessors = await lastValueFrom(this._entityImportRepository.getAssessors(this.importFileModel.importFileId,
            this._getSelectionModel(rowIndex))) as Compliance.ImportAssessorModel[];
        return this._assessors;
    }

    private async _getAttachmentTypes(row: Compliance.ImportGridRowGridModel): Promise<Compliance.AttachmentTypeViewModel[]> {
        let entityTypeId: Core.EntityTypes;

        if (this.importFileModel.importContentTypeId === Compliance.ImportContentTypeIdEnum.Attachment){
            const siteNumberAssignedField = this.importFileModel.assignedFields.find(i => i.importFieldId === `${Compliance.ImportFieldIdEnum.AttachmentSiteNumber}`);
            const siteField = siteNumberAssignedField && this._importFileModel.importContentType.importFields.find(f => f.importFieldId === siteNumberAssignedField.importFieldId);
            const parcelAssignedField = this.importFileModel.assignedFields.find(i => i.importFieldId === `${Compliance.ImportFieldIdEnum.AttachmentParcelAcctNum}`);
            const parcelField = parcelAssignedField && this._importFileModel.importContentType.importFields.find(f => f.importFieldId === parcelAssignedField.importFieldId);

            if (parcelField && (row[parcelField.displayName] || row.originalValues[parcelField.displayName])){
                entityTypeId = Core.EntityTypes.Parcel;
            } else if (siteField && (row[siteField.displayName] || row.originalValues[siteField.displayName])){
                entityTypeId = Core.EntityTypes.Site;
            } else{
                entityTypeId = Core.EntityTypes.Company;
            }
        } else{
            throw new Error(`Can't get entityTypeId for ContentType ${this.importFileModel.importContentTypeId} in _getAttachmentTypes method`);
        }

        return await lastValueFrom(this._entityImportRepository.getAvailableAttachmentTypes(entityTypeId));
    }

    private async _getLeaseTypes(): Promise<Compliance.LeaseTypeModel[]> {
        if (!this._leaseTypes){
            this._leaseTypes = await lastValueFrom(this._assetRepository.getLeaseTypes());

            this._leaseTypes.splice(0, 0, {leaseTypeId: ''} as Compliance.LeaseTypeModel);
        }

        return this._leaseTypes;
    }

    private _handleTotalsUpdate = (totals: Compliance.ImportGridTotalRow, selectedTotals:boolean = false) => {
        if (!this._showTotals || !totals || !totals.fields){
            return;
        }

        const totalsRow: any = {
            validationStatus: {},
            originalValues: {},
            hasError: false,
            rowIndex: 'TOTAL',
            actualAction: null,
            estimatedAction: null
        };

        _.each(this._fieldNames, fieldName => {
            const field = _.find(totals.fields, field => field.fieldName === fieldName);
            if (field){
                totalsRow[field.fieldName] = this._decimalPipe.transform(field.value, '1.2-2');
                totalsRow.originalValues[field.fieldName] = this._decimalPipe.transform(field.value, '1.2-2');
            } else{
                totalsRow[fieldName] = '-';
                totalsRow.originalValues[fieldName] = '-';
            }
        });

        if (selectedTotals){
            totalsRow.selectedTotalsRow = true;
            totalsRow.rowIndex = 'Selected';
            this.importGridSelectedTotalsRow = totalsRow;
        } else{
            totalsRow.totalsRow = true;
            this.importGridTotalsRow = totalsRow;
        }
        const totalRows = [this.importGridTotalsRow];
        if (this.gridTracker.hasAnythingSelected()) {
            totalRows.push(this.importGridSelectedTotalsRow);
        }
        setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        const timestamp = Date.now();
        this.selectedTotalsTimestamp = timestamp;

        this.selectedTotalsLoading = true;
        try {
            const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            if (this.selectedTotalsTimestamp === timestamp) {
                this.selectedTotalsLoading = false;
                this._handleTotalsUpdate(result, true);
            }
        }
        finally {
            this.selectedTotalsLoading = false;
        }
    }

    private async _getAssessmentClasses(rowIndex: number): Promise<string[]> {
        let assessmentClasses = await lastValueFrom(this._entityImportRepository.getAssessmentClasses(this.importFileModel.importFileId,
            this._getSelectionModel(rowIndex)));

        assessmentClasses.splice(0, 0, null);

        return assessmentClasses;
    }

    private async _getGLAccounts(rowIndex: number): Promise<Compliance.GLAccountModel[]> {
        return await lastValueFrom(this._entityImportRepository.getGLAccounts(this.importFileModel.importFileId,
            this._getSelectionModel(rowIndex)));
    }

    private _getSelectionModel(rowIndex: number) {
        const selectionModel: Compliance.ImportBulkOperationModel = {
            validationErrors: this._validationErrors || [],
            columnFilters: this._gridDataSource.columnFilters({filterModel: this._gridApi.getFilterModel()} as IGetRowsParams) || [],
            estimatedActionFilter: this._gridDataSource.estimatedActionFilter(this._gridDataSource.gridParams),
            selectAllRows: false,
            selectedRows: []
        };

        if (rowIndex) {
            selectionModel.selectAllRows = false;
            selectionModel.selectedRows = [rowIndex];
        } else {
            selectionModel.selectAllRows = this.gridTracker.getSelectedRowsModel().selectAllRows;
            selectionModel.selectedRows = this.gridTracker.getSelectedRowsModel().selectedRows;
        }
        return selectionModel;
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._entityImportProcessService.longRunningProcessId = null;
        this._destroyIndicator$.next();
    }

    private async _getTaxingDistricts(rowIndex?: number): Promise<Core.TaxingDistrictModel[]> {
        this._taxingDistricts = await lastValueFrom(this._entityImportRepository.getTaxingDistricts(this.importFileModel.importFileId,
            this._getSelectionModel(rowIndex))) as Core.TaxingDistrictModel[];
        return this._taxingDistricts;
    }

    private async _getAssetClassifications(): Promise<Compliance.AssetClassificationModel[]> {
        if (!this._assetClassifications) {
            const assetClassifications = await lastValueFrom(this._assetClassificationRepository.getAssetClassifications());
            const removeOverrideOption: Compliance.AssetClassificationModel = {
                allowPerpetual: false,
                assetClassificationId: -1,
                childClassifications: [],
                isInventory: false,
                name: 'Remove Override',
                parentAssetClassificationId: null
            };

            this._assetClassifications = [removeOverrideOption].concat(assetClassifications);
        }

        return this._assetClassifications;
    }

    private async _getGLAccountTypes(): Promise<Compliance.GLAccountTypeModel[]> {
        if(!this._glAccountTypes) {
            const searchModel: Core.GLAccountTypeSearchModel = {
                forIncomeStatement: true
            }
            this._glAccountTypes = await lastValueFrom(this._entityImportRepository.getGLAccountTypes(searchModel));
        }

        return this._glAccountTypes;
    }

    private async _getGlAccountTypeId(params: EntityImportEditorIncomeStatementCategorySelectorCellEditorParams): Promise<number> {
        if(params.data && params.data['G/L Account Type']) {
            const glAccountTypeName = params.data['G/L Account Type'];
            const glAccountTypes = await this._getGLAccountTypes();
            const glAccountType = _.find(glAccountTypes, {name: glAccountTypeName});

            return glAccountType ? glAccountType.glAccountTypeId : null;
        }

        return null;
    }

    private _getBulkUpdateFields() {
        const bulkUpdateFields = this._importFileModel.importContentType.importFields
            .filter(f => f.bulkUpdateAllowed && this._importFileModel.assignedFields.some(af => !af.isStatic && af.importFieldId === f.importFieldId))
            .map((i) => {
                const assignedField = this._importFileModel.assignedFields.find(af => af.importFieldId === i.importFieldId);
                const field = assignedField && this._importFileModel.importContentType.importFields.find(f => f.importFieldId === assignedField.importFieldId);

                const fieldInfo = {
                    name: field.displayName,
                    isMappingRequired: field.isMappingRequired,
                    importFileSpecificationFieldId: assignedField.importFileSpecificationFieldId,
                    uiCellRenderer: field.uiCellRenderer
                } as FieldInfo;

                return fieldInfo;
            });
        return bulkUpdateFields;
    }
}
