import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReturnService } from '../.././return.service';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { IMutexServiceHandler, WeissmanMutexService } from '../../../WeissmanMutexService';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ReturnTaskExceptionModalComponent } from '../Return-Task-Exception-Modal/returnTaskExceptionModal.component';
import { RETURN_TASK_EXCEPTION_HELP } from './returnTaskException.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';

@Component({
    selector: 'return-task-exception',
    templateUrl: './returnTaskException.component.html'
})
export class ReturnTaskExceptionComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService) { }

    private _tasksSub: Subscription;

    get exceptionButtonHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        if (this.isLongRunningProcessActive) {
            return 'app.disabled-busy';
        }

        return 'return-task-exception.exception-button';
    }

    get canEnterEditMode(): boolean { return this._mutexService.canAcquire(this._returnService.editGroup); }
    get isLongRunningProcessActive(): boolean { return this._returnService.isLongRunningProcessActive; }

    canEdit: boolean;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(RETURN_TASK_EXCEPTION_HELP);

        this._refreshTasks();
        this._tasksSub = this._returnService.tasks$.subscribe(() => this._refreshTasks());
    }

    ngOnDestroy(): void {
        this._tasksSub && this._tasksSub.unsubscribe();
    }

    async showException(): Promise<void> {
        await this._modalService.showAsync(ReturnTaskExceptionModalComponent, null, 'modal-md');
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _refreshTasks(): void {
        this.canEdit = (this._returnService.currentTask && this._returnService.currentTask.taskAuthorizationInfo.reassignTask) ||
            (this._returnService.priorCompletedTask && this._returnService.priorCompletedTask.taskAuthorizationInfo.rescindTask);
    }
}
