import { of as observableOf,  Observable,  Subscription } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { IncomeStatementCategoryPickerComponent, IncomeStatementCategoryPickerParams } from './incomeStatementCategoryPicker.component';
import { IncomeStatementCategoryRepository } from '../../../Compliance/Repositories';

interface IncomeStatementCategoryComponentChanges extends SimpleChanges {
    incomeStatementCategoryId: SimpleChange;
    incomeStatementCategoryName: SimpleChange;
}

@Component({
    selector: 'income-statement-category-selector',
    templateUrl: './incomeStatementCategorySelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: IncomeStatementCategorySelectorComponent,
        multi: true
    }]
})
export class IncomeStatementCategorySelectorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    constructor(
        private readonly _incomeStatementCategoryRepository: IncomeStatementCategoryRepository,
        private readonly _modalService: WeissmanModalService
    ) { }

    @Input() incomeStatementCategoryId: number;
    @Input() incomeStatementCategoryName: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() isOverridden: boolean = false;
    @Input() incomeStatementCategories: Core.IncomeStatementCategoryModel[] = null;
    @Input() glAccountTypeId: Compliance.GLAccountTypeEnum;

    @Output() incomeStatementCategoryIdChange = new EventEmitter<number>();
    @Output() incomeStatementCategoryChange = new EventEmitter<Core.IncomeStatementCategoryModel>();

    incomeStatementCategoryFilter: string = '';

    // this component will be considered initialized when the categories have been loaded
    // in the meanwhile, any selection attempts will be skipped
    isInitialized: boolean = false;

    incomeStatementCategories$: Observable<Core.IncomeStatementCategoryModel[]> = (Observable
        .create((observer: any) => { observer.next(this.incomeStatementCategoryFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterIncomeStatementCategories(token)));

    private _incomeStatementCategorySubscription: Subscription;
    private _onChangeFn: Function;
    private _onTouchedFn: Function;


    private _incomeStatementCategories: Core.IncomeStatementCategoryModel[] = [];

    ngOnInit(): void {
        if (this.incomeStatementCategories){
            this._populateIncomeStatementCategories(this.incomeStatementCategories);
        } else {
            this._incomeStatementCategorySubscription = this._incomeStatementCategoryRepository
                .getIncomeStatementCategories().pipe(
                first())
                .subscribe(data => {
                    const incomeStatementCategories: any = data;
                    this._populateIncomeStatementCategories(incomeStatementCategories);
                });
        }
    }

    ngOnDestroy(): void {
        this._incomeStatementCategorySubscription && this._incomeStatementCategorySubscription.unsubscribe();
    }

    ngOnChanges(changes: IncomeStatementCategoryComponentChanges): void {
        if (changes.incomeStatementCategoryId) {
            this._selectIncomeStatementCategory(this.incomeStatementCategoryId);
        }

        if (changes.incomeStatementCategoryName) {
            this._selectIncomeStatementCategory(this.incomeStatementCategoryId, this.incomeStatementCategoryName, true);
        }
    }

    clear(): void {
        this.writeValue(null);
    }

    writeValue(incomeStatementCategoryId: number): void {
        this._selectIncomeStatementCategory(incomeStatementCategoryId);
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onIncomeStatementCategoryBlur(match: TypeaheadMatch): void {
        if (match && match.item) {
            this.onIncomeStatementCategorySelected(match);
            return;
        }

        if (!this.isRequiredField && this.incomeStatementCategoryFilter === '') {
            this.incomeStatementCategoryId = null;
            this.incomeStatementCategoryName = null;
            this.incomeStatementCategoryChange.emit(null);
        }

        this._selectIncomeStatementCategory(this.incomeStatementCategoryId);
        this._onTouchedFn && this._onTouchedFn();
    }

    onIncomeStatementCategorySelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const incomeStatementCategory = match.item as Core.IncomeStatementCategoryModel;
            this._selectIncomeStatementCategory(incomeStatementCategory.incomeStatementCategoryId);
            this.incomeStatementCategoryIdChange.emit(incomeStatementCategory.incomeStatementCategoryId);
            this.incomeStatementCategoryChange.emit(incomeStatementCategory);
        }
    }

    async openPickerModal(): Promise<void> {
        const params: IncomeStatementCategoryPickerParams = {
            incomeStatementCategoryId: this.incomeStatementCategoryId,
            incomeStatementCategories: this.incomeStatementCategories,
            glAccountTypeId: this.glAccountTypeId
        };

        const result = await this._modalService.showAsync(IncomeStatementCategoryPickerComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this._selectIncomeStatementCategory(result.incomeStatementCategoryId);
        this.incomeStatementCategoryIdChange.emit(this.incomeStatementCategoryId);
        this.incomeStatementCategoryChange.emit(result);
    }

    private _filterIncomeStatementCategories(filter: string): Observable<Core.IncomeStatementCategoryModel[]> {
        if (filter === null || filter === undefined) filter = '';

        return observableOf(
            (this._incomeStatementCategories || []).filter(i => i.name.toLowerCase().includes(filter.toLowerCase()) && this._matchesAccountType(i))
        );
    }

    private _selectIncomeStatementCategory(incomeStatementCategoryId: number, incomeStatementCategoryName?: string, forceFindByName: boolean = false): void {
        // keep track of the value that the classification is being set to
        this.incomeStatementCategoryId = incomeStatementCategoryId;

        // if not initialized then return
        // when initialized the asset class ID that is being tracked (above) will be selected
        if (!this.isInitialized) {
            return;
        }

        const incomeStatementCategory = this._incomeStatementCategories.find(osc => {
            if (forceFindByName) {
                return osc.name === incomeStatementCategoryName;
            } else {
                return osc.incomeStatementCategoryId === incomeStatementCategoryId || osc.name === incomeStatementCategoryName;
            }
        });

        if (incomeStatementCategory) {
            this.incomeStatementCategoryId = incomeStatementCategory.incomeStatementCategoryId;
            this.incomeStatementCategoryFilter = incomeStatementCategory.name;
        } else {
            this.incomeStatementCategoryId = null;
            this.incomeStatementCategoryFilter = null;
        }

        this._onChangeFn && this._onChangeFn(this.incomeStatementCategoryId);
    }

    private _populateIncomeStatementCategories(incomeStatementCategories: any){
        if (incomeStatementCategories) {
            incomeStatementCategories.forEach(outerItem => {
                this._incomeStatementCategories.push(outerItem);
                if (outerItem.childIncomeStatementCategories) {
                    outerItem.childIncomeStatementCategories.forEach(secondLevelItem => {
                        secondLevelItem.extraPath = `${outerItem.name  } >  `;
                        this._incomeStatementCategories.push(secondLevelItem);
                        if (secondLevelItem.childIncomeStatementCategories) {
                            secondLevelItem.childIncomeStatementCategories.forEach(thirdLevelItem => {
                                thirdLevelItem.extraPath = `${secondLevelItem.extraPath + secondLevelItem.name  } > `;
                                this._incomeStatementCategories.push(thirdLevelItem);
                            });
                        }
                    });
                }
            });
        }

        this.isInitialized = true;
        this._selectIncomeStatementCategory(this.incomeStatementCategoryId, this.incomeStatementCategoryName);
    }

    private _matchesAccountType(category: Core.IncomeStatementCategoryModel): boolean {
        switch(this.glAccountTypeId) {
            case Compliance.GLAccountTypeEnum.Expense:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Expense;
            case Compliance.GLAccountTypeEnum.Revenue:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Income;
            case Compliance.GLAccountTypeEnum.Reporting:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Reporting;
            default:
                return true;
        }
    }
}
