import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class ReturnAssetRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    exportReturnAssets(filingBatchId: number, exportModel: Compliance.ReturnAssetExportModel): Observable<number> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/export`, exportModel);
    }

    getReturnAssetsExport(filingBatchId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/returnAsset/filingBatch/${filingBatchId}/export/${longRunningProcessId}`, options);
    }

    getListByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Compliance.ReturnAssetModel, Compliance.ReturnAssetSearchTotalsModel>> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}`, searchModel);
    }

    getRowIdsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/rowIds`, searchModel);
    }

    getUnassignedScheduleDetailsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetScheduleDetailsSearchModel): Observable<Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/unassignedScheduleDetails`, searchModel);
    }

    getScheduleDetailsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetScheduleDetailsSearchModel): Observable<Compliance.ReturnAssetScheduleDetailsAssignedItemModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/scheduleDetails`, searchModel);
    }

    getAssessorsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetAssessorModel): Observable<Compliance.FormRevisionAssessorModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/assessors`, searchModel);
    }

    getReportingAssetAssessorsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetAssessorModel): Observable<Compliance.ReportingAssetAssessorModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/reportingAssetAssessors`, searchModel);
    }

    getWorkingSetAssessorsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetAssessorModel): Observable<Compliance.FormRevisionAssessorModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/workingSetAssessors`, searchModel);
    }

    getWorkingSetReportingAssetAssessorsByFilingBatch(filingBatchId: number, searchModel: Compliance.ReturnAssetAssessorModel): Observable<Compliance.ReportingAssetAssessorModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/workingSetReportingAssetAssessors`, searchModel);
    }

    updateByFilingBatch(filingBatchId: number, updateModel: Compliance.ReturnAssetUpdateModel): Observable<void> {
        return this.httpPut(`/api/returnAsset/filingBatch/${filingBatchId}`, updateModel);
    }

    updateClassifications(filingBatchId: number, classificationId: number, searchModel: Compliance.ReturnAssetSearchModel) {
        return this.httpPut(`/api/returnAsset/filingBatch/${filingBatchId}/classification/${classificationId}`, searchModel);
    }

    bulkUpdate(returnAssetBulkUpdateModel: Compliance.ReturnAssetBulkUpdateModel) {
        return this.httpPost(`/api/returnAsset/filingBatch/bulkUpdate`, returnAssetBulkUpdateModel);
    }

    getBulkUpdateMetadata(returnAssetBulkUpdateModel: Compliance.ReturnAssetBulkUpdateModel): Observable<Compliance.ReturnAssetBulkUpdateMetadataModel> {
        return this.httpPost(`/api/returnAsset/filingBatch/bulkUpdateMetadata`, returnAssetBulkUpdateModel);
    }

    getAssessorFactorTables(filingBatchId: number, searchModel: Compliance.AssessorFactorTableSearchModel): Observable<Compliance.AssessorFactorTableModel[]> {
        return this.httpPost(`/api/returnAsset/filingBatch/${filingBatchId}/assessorFactorTables`, searchModel);
    }
}
