import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ActionViewOutputCustomParams {
    fromActionView: boolean;
    taskType: any;
    systemColumns: Core.AdvancedSearchField[];
    userColumns: any[];
}

@Component({
    selector: 'action-view-output-custom',
    template: `
        <ws-modal-wrapper [headerLabel]="taskTypeName"
                          [suppressKeyboardEvents]="true"
                          (save)="save()"
                          (cancel)="cancel()">
            <category-column-picker [disableUseSystemDefined]="params.fromActionView" 
                [category]="category" 
                cpMode="av" 
                [selectedTaskType]="params.taskType">
            </category-column-picker>
        </ws-modal-wrapper>
    `
})
export class ActionViewOutputCustomComponent implements OnInit, IWeissmanModalComponent<ActionViewOutputCustomParams, any[]> {
    constructor(private readonly _modalRef: BsModalRef) { }

    params: ActionViewOutputCustomParams;
    result: any[] = [];

    taskTypeName: string;
    category: any;

    ngOnInit() {
        this.taskTypeName = this.params.taskType.name == 'All' ? 'All Task Types' : this.params.taskType.name;

        const filterColumns = this.params.userColumns.length ? this.params.userColumns : this.params.systemColumns;
        this.category = {
            columns: _.chain(filterColumns)
                .map(x => {
                    x.isRemovable = true
                    return x;
                })
                .sortBy('sequence')
                .value(),
            defaultColumns: this.params.systemColumns
        }
    }

    cancel(): void {
        this.result = undefined;
        this._modalRef.hide();
    }

    save(): void {
        if(this.category.columns.length) {
            const idProperty = this.category.columns[0].columnId ? 'columnId' : 'advancedSearchFieldID'
            const useSystem = _.xorBy(this.category.columns, this.category.defaultColumns, idProperty).length === 0;

            if(!useSystem || this.params.fromActionView) {
                this.result = _.map(this.category.columns, (col: any, i) => {
                    col.sequence = i;
                    return col; 
                });
            } 
        } 

        this._modalRef.hide();
    }

}
