import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { ReportDetail, FormatOutputOptionsPsr, PsrReportCriteria, OrderAndTotalByOption, GenericEntity } from "../report.manager.model";
import * as _ from 'lodash';
import { ReportManagerService } from "../report.manager.service";
import { Subscription } from "rxjs";
import { ReportType } from "../report-type";

@Component({
    selector: 'format-output-panel-psr',
    templateUrl: './format.output.panel.psr.component.html'
})
export class FormatOutputPanelPsrComponent implements OnInit, OnDestroy {
    
    @Input() report: ReportDetail;
    orderAndTotalBySelected: OrderAndTotalByOption;
    orderAndTotalBySelection: OrderAndTotalByOption[] = [];
    orderAndTotalByOptions: OrderAndTotalByOption[];
    orderAndTotalByOptionsOriginal: OrderAndTotalByOption[];
    subscription: Subscription;
    reportTypes = ReportType;

    outputFormatOptions: string[];
    paperSizeOptions: string[];
    showAssessmentsOptions: string[];
    showMetricsOptions: string[];
    showValueOptions: string[];
    showTaxDetailsOptions: string[];
    showLatestValueOptions: string[];
    showSavingsOptions: string[];
    showSiteAppealCommentsOptions: string[];

    constructor(private reportManagerService: ReportManagerService) { 
        this.subscription = reportManagerService.currentPropChars$.subscribe(
            propChars => {
                this.filterPropChars(propChars);
            }
        )
    }

    ngOnInit() {
        this._setDefaultsBudget();
    }

    orderAndTotalByChanged(): void {
        this.report.criteria.psrFormatOutputOptions.orderGroupBy = _.cloneDeep(this.orderAndTotalBySelection);
        this.orderAndTotalByOptions = _.map(this.orderAndTotalByOptions, (option: OrderAndTotalByOption) => {
            option.groupByThisItem = false;
            return option;
        })

        if(this.orderAndTotalBySelected.id) {
            this.report.criteria.psrFormatOutputOptions.orderGroupBy.push(this.orderAndTotalBySelected);
        }
    }
    
    addNewOrderAndTotalBy(): void {
        this.orderAndTotalBySelection.push(this.orderAndTotalBySelected);

        this.orderAndTotalByOptions = _.reject(this.orderAndTotalByOptions, {id: this.orderAndTotalBySelected.id})
        this.orderAndTotalBySelected = this.orderAndTotalByOptions[0];
    }

    removeOrderAndTotalBy(field: OrderAndTotalByOption): void {
        _.remove(this.orderAndTotalBySelection, field);
        this.report.criteria.psrFormatOutputOptions.orderGroupBy = _.reject(this.report.criteria.psrFormatOutputOptions.orderGroupBy, {id: field.id});

        field.groupByThisItem = false;
        this.orderAndTotalByOptions.push(field);
        this.orderAndTotalByOptions = _.sortBy(this.orderAndTotalByOptions, ['primarySortOrder', 'name']);
    }

    groupByChanged(field: OrderAndTotalByOption): void {
        let i = _.findIndex(this.report.criteria.psrFormatOutputOptions.orderGroupBy, {id: field.id});

        if(i >= 0) {
            this.report.criteria.psrFormatOutputOptions.orderGroupBy[i].groupByThisItem = field.groupByThisItem;
        }
    }

    private _setDefaultsBudget(): void {

        this.reportManagerService.getTotalAndGroupByOptions()
            .then((result: OrderAndTotalByOption[]) => {
                let formatOutputOptions: FormatOutputOptionsPsr = new FormatOutputOptionsPsr();
                formatOutputOptions.orderAndTotalByOptions = _.sortBy(result, ['primarySortOrder', 'name']);
                formatOutputOptions.orderAndTotalByOptions.unshift({
                    id: null,
                    name: '',
                    primarySortOrder: -1000
                });
                _.assign(this, formatOutputOptions);

                this.orderAndTotalByOptionsOriginal = _.cloneDeep(this.orderAndTotalByOptions)

                this._populateOrderBy();
            });

        this.report.criteria.outputFormat = this.report.criteria.outputFormat || 0;
        this.report.criteria.psrFormatOutputOptions = this.report.criteria.psrFormatOutputOptions || new PsrReportCriteria(this.report.reportTypeId);
    }

    private _populateOrderBy() {
        if(this.report.criteria.psrFormatOutputOptions.orderGroupBy.length) {
            this.orderAndTotalBySelection = _.cloneDeep(_.initial(this.report.criteria.psrFormatOutputOptions.orderGroupBy));
            let lastOrderById: OrderAndTotalByOption = _.last(this.report.criteria.psrFormatOutputOptions.orderGroupBy);
            this.orderAndTotalBySelected = _.find(this.orderAndTotalByOptions, {id: lastOrderById.id});
            this.orderAndTotalBySelected.groupByThisItem = lastOrderById.groupByThisItem;
        } else {
            this.orderAndTotalBySelected = this.orderAndTotalByOptions[0]
        }
    }

    filterPropChars(entityPropChars: GenericEntity[]) {
        if(entityPropChars.length) {
            this.orderAndTotalByOptions = _.filter(this.orderAndTotalByOptions, (option) => {
                return _.includes(_.map(entityPropChars, 'id'), option.id) || option.id < 0 || !option.id;
            })
        } else {
            this.orderAndTotalByOptions = _.chain(this.orderAndTotalByOptionsOriginal)
                .cloneDeep()
                .reject(option => {
                    return _.some(this.report.criteria.psrFormatOutputOptions.orderGroupBy, {id: option.id});
                })
                .value();
        }
    }

    ngOnDestroy() {
        // prevent memory leak when component destroyed
        this.subscription.unsubscribe();
      }
}