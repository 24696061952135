import { Component, OnInit, Input } from '@angular/core';
import { GridOptions, GridReadyEvent, GridApi, ColDef, RowNode } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { WeissmanDateFormatPipe, WeissmanDateTimeFormatPipe } from '../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { AssetRepository } from '../../../Repositories/asset.repository';
import { AgGridOptionsBuilder } from '../../../AgGrid/agGridOptionsBuilder';
import { AgGridColumns } from '../../../AgGrid/agGridColumns';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import * as _ from 'lodash';

interface AssetActivityLogRow {
    assetLogModel: Compliance.SourceAssetVerificationLogModel;
    effectiveDate: Date;
    actionDate: Date;
    fieldChanged: string;
    action: string;
    createdByName: string;
    changedTo: string;
    changedFrom: string;
    lastInGroup: boolean;
    masterRowCount: number;
}

@Component({
    selector: 'asset-activity-log',
    templateUrl: './assetActivityLog.component.html'
})
export class AssetActivityLogComponent implements OnInit {
    constructor(
        private readonly _assetRepository: AssetRepository,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe) { }

    private _gridApi: GridApi;
    private _activityLogData: Compliance.SourceAssetVerificationLogModel[];

    @Input() reportingAssetId: number;

    gridOptions: GridOptions;

    ngOnInit(): void {
        const gridOptions = new AgGridOptionsBuilder()
            .withContext(this)
            .withColumnResize()
            .withMultipleColumnSort()
            .withLoadingOverlay()
            .withTextSelection()
            .build();
        gridOptions.getRowClass = this._getRowClass;
        gridOptions.postSort = this._postSort;

        this.gridOptions = gridOptions;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Effective Date',
                field: 'effectiveDate',
                suppressSizeToFit: true,
                width: AgGridColumns.dateColumnWidth,
                valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : '',
                comparator: this._outerDateComparator.bind(this)
            },
            {
                headerName: 'Action Date',
                field: 'actionDate',
                suppressSizeToFit: true,
                width: AgGridColumns.dateColumnWidth,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true, 'central') : '',
                comparator: this._outerDateComparator.bind(this)
            },
            {
                headerName: 'User',
                field: 'createdByName',
                sortable: false,
                width: AgGridColumns.textColumnMedWidth,
            },
            {
                headerName: 'Asset Action',
                field: 'action',
                sortable: false,
                width: AgGridColumns.textColumnMedWidth,
            },
            {
                headerName: 'Field Changed',
                field: 'fieldChanged',
                sortable: false,
                width: AgGridColumns.textColumnMedWidth,
            },
            {
                headerName: 'Old Value',
                field: 'changedFrom',
                sortable: false,
                width: AgGridColumns.textColumnMedWidth,
                valueFormatter: this._formatChangeField.bind(this, 'changedFrom'),
                cellRenderer: (params) => {
                    const data = params.data as AssetActivityLogRow;
                    let value = '';
                    if (data.fieldChanged) {
                        value =  params.value ? params.valueFormatted : `<em>blank</em>`;
                    }
                    return value;
                },
                cellClass: this._getCellClass.bind(this),
                tooltipField: 'changedFrom',
            },
            {
                headerName: 'New Value',
                field: 'changedTo',
                sortable: false,
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnWidth,
                valueFormatter: this._formatChangeField.bind(this, 'changedTo'),
                cellRenderer: (params) => {
                    const data = params.data as AssetActivityLogRow;
                    let value = '';
                    if (data.fieldChanged) {
                        value =  params.value ? params.valueFormatted : `<em>blank</em>`;
                    }
                    return value;
                },
                cellClass: this._getCellClass.bind(this),
                tooltipField: 'changedTo',
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._setData();
    }

    private _postSort(rowNodes: RowNode[]): void {
        // make a 0 based index
        let rowCount = 0;
        const newRowNodesArr: any[] = [];
        const renumbers: any = {};
        // start with the row nodes with an assetLogModel
        const masterNodesArr = rowNodes.filter(rowNode => rowNode.data && rowNode.data.assetLogModel);
        masterNodesArr.forEach(rowNode => {
            const oldRowIndex = rowNode.data.masterRowCount;
            let childrenRows = rowNodes.filter(node => (!node.data.assetLogModel) && node.data.masterRowCount === oldRowIndex);
            renumbers[oldRowIndex] = rowCount;

            childrenRows = _.sortBy(childrenRows, 'data.fieldName');
            newRowNodesArr.push(rowNode);
            newRowNodesArr.push(...childrenRows);
            rowCount++;
        });

        //resort
        newRowNodesArr.forEach(rowNode => {
            rowNode.data.masterRowCount = renumbers[rowNode.data.masterRowCount];
        });

        // post sort works by resorting original array
        rowNodes.splice(0, rowNodes.length, ...newRowNodesArr);
    }

    private _formatChangeField(field, params: ValueFormatterParams): string {
        switch (this._formattingType(params.value)) {
            case 'date':
                return params.value ? this._datePipe.transform(new Date(params.value), true) : '';
            case 'number':
                return `${Number(params.value)}`;
            default:
                return params.value;
        }
    }

    private _outerDateComparator(a, b, nodeA, nodeB, inverted): number {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();;

        if (isNaN(dateA) && isNaN(dateB)) {
            return 0;
        }
        if (isNaN(dateA)) {
            return -1;
        }
        if (isNaN(dateB)) {
            return 1;
        }
        return dateA - dateB;
    }

    private async _setData(): Promise<void> {
        this._activityLogData = await lastValueFrom(this._assetRepository.getActivityLog(this.reportingAssetId));
        const rowData = this._initializeRowData();
        const expandedRowData = this._expandRowData(rowData);
        this._gridApi.setRowData(expandedRowData);
    }

    private _getRowClass(params): string {
        let className: string = params.data.masterRowCount % 2 ? 'asset-activity-table-odd' : 'asset-activity-table-even';
        if (params.data.lastInGroup) {
            className += '-last';
        }
        return className;
    }

    private _getCellClass(params): string {
        return (this._formattingType(params.value) === 'number') ? 'text-align-right' : '';
    }

    private _initializeRowData(): AssetActivityLogRow[] {
        const rows: AssetActivityLogRow[] = [];
        this._activityLogData.forEach((row: Compliance.SourceAssetVerificationLogModel) => {
            rows.push({
                assetLogModel: row,
                effectiveDate: row.effectiveDate,
                actionDate: row.actionDate,
                action: row.action,
                createdByName: row.createdByName,
                fieldChanged: null,
                changedFrom: null,
                changedTo: null,
                lastInGroup: true,
                masterRowCount: 0
            });
        });
        return rows;
    }

    private _expandRowData(oldRows: AssetActivityLogRow[]): AssetActivityLogRow[] {
        const sourceRows: AssetActivityLogRow[] = oldRows.filter((row: AssetActivityLogRow) => row.assetLogModel);
        const expandedRows: AssetActivityLogRow[] = [];
        let rowCount: number = 0;

        sourceRows.forEach((sourceRow: AssetActivityLogRow) => {
            sourceRow.masterRowCount = rowCount;
            expandedRows.push(sourceRow);

            if (sourceRow.assetLogModel.changes && sourceRow.assetLogModel.changes.length > 0) {
                const firstChange = sourceRow.assetLogModel.changes[0];
                sourceRow.fieldChanged = firstChange.fieldName;
                sourceRow.changedFrom = firstChange.priorValue;
                sourceRow.changedTo = firstChange.currentValue;
                sourceRow.lastInGroup = false;
                sourceRow.assetLogModel.changes.forEach(
                    (changeModel: Compliance.SourceAssetVerificationFieldChangeModel, index: number) => {
                        if (index > 0) {
                            expandedRows.push({
                                assetLogModel: null,
                                effectiveDate: null,
                                actionDate: null,
                                action: null,
                                createdByName: null,
                                fieldChanged: changeModel.fieldName,
                                changedFrom: changeModel.priorValue,
                                changedTo: changeModel.currentValue,
                                lastInGroup: (index + 1 === sourceRow.assetLogModel.changes.length),
                                masterRowCount: rowCount
                            });
                        }
                    });

            } else {
                sourceRow.lastInGroup = true;
            }

            rowCount++;

        });

        return expandedRows;
    }

    private _formattingType(value: any): string {
        if (Object.prototype.toString.call(value) === '[object Date]') {
            return 'date';
        } else if (typeof value === 'number') {
            return 'number';
        } else {
            return 'string';
        }
    }
}
