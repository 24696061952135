(function () {
    'use strict';

    angular.module('weissmanApp').factory('StartupService', Service);

    Service.$inject = ['sd.Navigation.Service', '$transitions'];

    function Service(navigationService, $transitions) {
        return {
            initializeNavService: initializeNavService
        };

        // Fixes an issue where the navStart hook was not firing for the first navigation, and that's necessary for getting auth
        // data filled in before the UI is rendered.
        function initializeNavService() {
            $transitions.onStart({}, navigationService.onNavStart);
        }
    }
})();
