<div class="state-jurisdiction-list ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                State and Jurisdiction Command Center
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div><small>View:</small></div>
                    <div dropdown container="body" placement="bottom right">
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic" [disabled]="editing">
                            {{ selectedGridSourceOption.name }}
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" *ngFor="let gso of gridSourceOptions">
                                <a class="pointer" (click)="setSelectedGridSourceOption(gso)">{{ gso.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         [helpContentId]="refreshHelpContentId"
                         position="bottom">
                        <button type="button" class="flat-button small-button secondary-button" [disabled]="(!canGetRows) || editing" (click)="refresh()"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <command-view-controls></command-view-controls>

    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="gridId"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        [exportOptions]="exportOptions"
                        (bulkUpdate)="bulkUpdate()"
                        [defaultSizeToFit]="false"
                        [defaultSizeToContent]="true">
    </ws-ag-grid-angular>
</div>
