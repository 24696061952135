import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class ReturnReportRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) { super(httpClient) }


    startDownloadReport(filingBatchId: number, requestModel: Compliance.ReturnReportRequestModel): Observable<number> {
        return this.httpPost(`/api/returnReport/filingbatch/${filingBatchId}/downloadReport`, requestModel);
    }

    getDownloadReport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/returnReport/downloadReport/${longRunningProcessId}`, options);
    }
    
    getReport(filingBatchId: number, requestModel: Compliance.ReturnReportRequestModel): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpPost(`/api/returnReport/filingbatch/${filingBatchId}/report`, requestModel, options);
    }
}
