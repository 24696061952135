import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subject } from 'rxjs';
import { ReturnService } from '../../return.service';
import { ReturnUpdateLogicService } from '../../returnUpdateLogic.service';
import { FilingControlGroupBy, ReturnOverviewService } from './returnOverview.service';
import { IReturnPartComponent, ReturnPartServiceBase } from '../../Models/returnPartServiceBase';
import { ReconciliationReport } from '../../Models/reconciliationReport';
import { RETURN_OVERVIEW_COMPONENT_HELP } from './returnOverview.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'return-overview',
    templateUrl: './returnOverview.component.html'
})
export class ReturnOverviewComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _helpService: HelpService,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService
    ) {}

    selectedReconciliationReport: ReconciliationReport = null;

    hideEmptySchedules: boolean = true;

    groupBy: UntypedFormControl = new UntypedFormControl(FilingControlGroupBy.None);
    groupOptions: Compliance.NameValuePair<FilingControlGroupBy>[] = [
        { name: 'None', value: FilingControlGroupBy.None },
        { name: 'Assessor', value: FilingControlGroupBy.Assessor }
    ];

    returnSummaryReconciliationReport: ReconciliationReport;
    scheduleAdditionsDisposalsReconciliationReport: ReconciliationReport;
    filingControlReconciliationReport: ReconciliationReport;

    private _reconciliationReports: ReconciliationReport[];

    private _hasForms: boolean = false;
    private _consolidatedOption: Compliance.NameValuePair<FilingControlGroupBy> = { name: 'Consolidated Returns', value: FilingControlGroupBy.Consolidated };
    private _destroy$: Subject<void> = new Subject();

    get returnPartServiceForInterPartComponent(): ReturnPartServiceBase { return this._returnOverviewService; }

    get reconciliationReports(): ReconciliationReport[] {
        return _.filter(this._reconciliationReports, x => x.allowPrimaryForReturn || this._hasForms);
    }

    ngOnInit(): void {
        this._helpService.setContent(RETURN_OVERVIEW_COMPONENT_HELP);
        this._reconciliationReports = this._returnOverviewService.reconciliationReports;
        this.returnSummaryReconciliationReport = this._reconciliationReports[0];
        this.scheduleAdditionsDisposalsReconciliationReport = this._reconciliationReports[1];
        this.filingControlReconciliationReport = this._reconciliationReports[2];

        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnUpdateLogicService.returnOverviewReport$.pipe(takeUntil(this._destroy$)).subscribe((report) => {
            if (report) {
                this.selectedReconciliationReport = report;
                this._hasForms = !!this._returnService.getUniqueAssociatedReturnFormRevisions().length;

                this._returnOverviewService.setReconciliationReport(this.selectedReconciliationReport);
            }
        });

        this._returnService.returns$
            .pipe(takeUntil(this._destroy$))
            .subscribe((returns) => {
            if (returns) {
                if (this._returnService.isConsolidatedReturn && !this.groupOptions.includes(this._consolidatedOption)) {
                    this.groupOptions.push(this._consolidatedOption);
                }
            }
        });

        await this._returnUpdateLogicService.refreshOverview();
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onSelectedReconciliationReportChange(report: ReconciliationReport): Promise<void> {
        this.selectedReconciliationReport = report;
        this._returnOverviewService.setReconciliationReport(this.selectedReconciliationReport);
        await this._returnUpdateLogicService.refreshReturnOverview();
    }
}
