import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdvancedSearchCategoryTypes } from '../constants.new';
import { RevokeShareModel } from '../Search/Advanced/advancedSearchPersistence.service';

export interface CategorySearchItem {
    searchID: number;
    categoryID: number;
    rowVersion: number;
}

@Injectable()
export class AdvancedSearchRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly BASE_SEARCH_URL = '/api/search/';
    private readonly BASE_SMART_URL = '/api/smart/';
    private readonly SERVICE_URL = `${this.BASE_SEARCH_URL}advanced/`;
    private readonly SERVICE_URL_CUSTOM_SEARCH = `${this.BASE_SEARCH_URL}advanced/custom/`;
    private readonly SERVICE_URL_SYSTEM_SEARCH = `${this.BASE_SEARCH_URL}advanced/system/`;
    private readonly SERVICE_URL_CATEGORIES = `${this.BASE_SEARCH_URL}advanced/category/`;
    private readonly SERVICE_URL_FAVORITE = `${this.BASE_SEARCH_URL}advanced/favorite/`;
    private readonly SERVICE_URL_FAVORITE_CHECK = `${this.BASE_SEARCH_URL}advanced/system/hasfavorite/`;
    private readonly SERVICE_URL_FAVORITE_COUNT = `${this.BASE_SEARCH_URL}advanced/system/favoritecount/`;
    private readonly SERVICE_URL_SEARCH_SHARE = `${this.BASE_SEARCH_URL}share/`;
    private readonly SERVICE_URL_SEARCH_REVOKE_SHARE = `${this.BASE_SEARCH_URL}revokeshare/`;
    private readonly SMART_BULK_DESCRIPTOR_URL = `${this.BASE_SMART_URL}bulkMetadata`;
    private readonly SMART_BULK_UPDATE_URL = `${this.BASE_SMART_URL}bulk`;

    getColumnsCpMode(cpMode: string, includeTaskColumns: boolean): Observable<any> {
        const config = { params: { mode: cpMode } };
        let url = `${this.SERVICE_URL  }lookups`;
        if (includeTaskColumns) { url += '/all'; }
        return this.httpGet(url, config);
    }

    getAllCustomSearches(config): Observable<any> {
        return this.httpGet(this.SERVICE_URL_CUSTOM_SEARCH, config);
    }

    saveCustomSearch(searchItem): Observable<any> {
        return this.httpPut(this.SERVICE_URL_CUSTOM_SEARCH, searchItem);
    }

    share(searchItem: Core.AdvancedSearchList): Observable<any> {
        return this.httpPatch(this.SERVICE_URL_SEARCH_SHARE, searchItem);
    }

    revokeShare(searchItem: RevokeShareModel): Observable<any> {
        return this.httpPatch(this.SERVICE_URL_SEARCH_REVOKE_SHARE, searchItem);
    }

    createCustomSearch(searchItem): Observable<any> {
        return this.httpPost(this.SERVICE_URL_CUSTOM_SEARCH, searchItem);
    }

    saveSystemSearch(searchItem): Observable<any> {
        return this.httpPut(this.SERVICE_URL_SYSTEM_SEARCH, searchItem);
    }

    createSystemSearch(searchItem): Observable<any> {
        return this.httpPost(this.SERVICE_URL_SYSTEM_SEARCH, searchItem);
    }

    getOneSearch(searchId: number, searchType: number): Observable<any> {
        const searchEndpoint = this._getSearchEndpointByType(searchType);
        return this.httpGet(searchEndpoint + searchId);
    }

    executeSearch(extendedPath: string, payloadCriteria, config): Observable<number> {
        return this.httpPost(this.SERVICE_URL + extendedPath, payloadCriteria, config);
    }

    getSearchCategories(): Observable<Core.SavedSearchCategoryManagementDTO[]> {
        return this.httpGet(this.SERVICE_URL_CATEGORIES);
    }

    createSearchCategory(category): Observable<any> {
        return this.httpPost(this.SERVICE_URL_CATEGORIES, category);
    }

    deleteSearchCategory(categoryId: number): Observable<any> {
        return this.httpDelete(this.SERVICE_URL_CATEGORIES + categoryId);
    }

    patchSearch(searchItem: any, searchType: number): Observable<any> {
        const searchEndpoint = this._getSearchEndpointByType(searchType);
        return this.httpPatch(searchEndpoint + searchItem.searchId, searchItem);
    }

    changeSearchCategory(categorySearchItem: CategorySearchItem, searchType: number): Observable<any> {
        const searchEndpoint = this._getSearchEndpointByType(searchType);
        return this.httpPatch(searchEndpoint + categorySearchItem.searchID, categorySearchItem);
    }

    deleteSearch(searchItem: any, searchType: number): Observable<any> {
        const searchEndpoint = this._getSearchEndpointByType(searchType);
        const options = {
            body: searchItem
        };
        return this.httpDelete(searchEndpoint, options);
    }

    toggleSearchFavoriteOn(data: any): Observable<any> {
        return this.httpPost(this.SERVICE_URL_FAVORITE, data);
    }

    toggleSearchFavoriteOff(searchId: number): Observable<any> {
        return this.httpDelete(this.SERVICE_URL_FAVORITE + searchId);
    }

    searchHasFavorites(searchId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL_FAVORITE_CHECK + searchId);
    }

    getFavoriteCount(searchId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL_FAVORITE_COUNT + searchId);
    }

    getBulkUpdateDescriptors(request: Core.SmartBulkUpdateModel): Observable<Core.SmartBulkUpdateMetadataModel> {
        return this.httpPost(this.SMART_BULK_DESCRIPTOR_URL, request);
    }

    bulkUpdateList(updateModel: Core.SmartBulkUpdateModel): Observable<Core.SmartBulkUpdateResultModel> {
        return this.httpPost(this.SMART_BULK_UPDATE_URL, updateModel);
    }

    private _getSearchEndpointByType(searchType: number): string {
        let searchURL = '';

        if (searchType === AdvancedSearchCategoryTypes.CUSTOM) {
            searchURL = this.SERVICE_URL_CUSTOM_SEARCH;
        } else if (searchType === AdvancedSearchCategoryTypes.SYSTEM) {
            searchURL = this.SERVICE_URL_SYSTEM_SEARCH;
        }

        return searchURL;
    }
}
