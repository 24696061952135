// This service provides two functions; open and confirm. See the comment blocks
// over each function for instructions.

(function () {
    'use strict';

    angular.module('weissmanApp')
        .factory('messageBoxService', Service);

    Service.$inject = ['$q', '$uibModal', 'MessageBoxButtons', 'MessageBoxResult'];

    function Service($q, $uibModal, MessageBoxButtons, MessageBoxResult) {
        return {
            open: open,
            confirm: confirm,
            confirmYesNo: confirmYesNo,
            warning: warning
        };

        /* open accepts an options argument, which is an object with the following
        properties:

        title: An optional property which specifies what to display in the modal title
        message: The message to be displayed to the user
        buttons: A number specifying what buttons to display on the dialog

        For the buttons property, if you're in JavaScript you can inject the
        MessageBoxButtons property from the globals file for options. If you're in
        TypeScript, the MessageBoxButtons enumeration has the options.

        The open function returns a promise which resolves a number indicating which
        button was pressed. Similar to the buttons property, you can inject
        MessageBoxResult in JavaScript or use the MessageBoxResult enumeration in
        TypeScript to see what might result. If the modal is closed without the user
        pressing a button, a Cancel result is resolved. That means the promise will not
        be rejected; it always resolves with a button result. It also means that you
        should think about the cancel result even if you don't display a cancel button.
        To use:

        messageBoxService.open({
            title: 'Save changes?',
            message: 'Do you wish to save before leaving this page?',
            buttons: MessageBoxButtons.YesNo
        }).then(function (result) {
            switch (result) {
                case MessageBoxResult.Yes:
                    // Do something when yes is clicked
                    break;
                case MessageBoxResult.No:
                    // Do something when no is clicked
                    break;
                case MessageBoxResult.Cancel:
                    // Do something when the dialog is closed
                    break;
            }
        }); */
        function open(options, isBackdropStatic) {
            return $q(function (resolve) {
                var messageBoxModal = $uibModal.rejectableOpen({
                    templateUrl: 'app/UI-Lib/Message-Box/messageBoxModal.html',
                    controller: 'MessageBoxController',
                    controllerAs: 'vm',
//                    size: 'sm',
                    animation: false,
                    windowClass: 'modal-on-top show',
                    backdropClass: 'show',
                    openedClass: 'modal-open-nested',
                    backdrop: isBackdropStatic ? 'static' : true,
                    resolve: {
                        options: options
                    }
                });

                messageBoxModal.result.then(function (result) {
                    resolve(result);
                }, function () {
                    // If the modal is closed via escape or click outside the modal,
                    // resolve as if the cancel button was pressed
                    resolve(MessageBoxResult.Cancel);
                });
            });
        }

        /* confirm takes two arguments; first the message to display to the user in the
        modal, and second a title for the modal (optional).  The function returns a
        promise that is resolved if the user clicks OK. To use:

        messageBoxService.confirm("Are you sure you want to delete this?", "Confirm Delete").then(function() {
            // Some code here that actually takes action (such as deleting)
        });

        If you need to take action when a user clicks on Cancel, you should use the more
        general open function. Note that confirm used to reject the promise when users
        clicked on cancel, but the AngularJS upgrade altered promise behavior, making that
        a less convenient option. */
        function confirm(message, title, focusButton) {
            return $q(function (resolve, reject) {
                open({
                    message: message,
                    title: title,
                    buttons: MessageBoxButtons.OKCancel,
                    focusButton: focusButton,
                    windowClass: 'message-box-z-index show',
                    backdropClass: 'show'
                }).then(function (result) {
                    if (result !== MessageBoxResult.Cancel) {
                        resolve();
                    }
                });
            });
        }

        // This works exactly the same way as confirm but uses Yes/No buttons instead of OK/Cancel;
        // dismissing the dialog is the same as clicking "No" in this case
        function confirmYesNo(message, title) {
            return $q(function (resolve, reject) {
                open({
                    message: message,
                    title: title,
                    buttons: MessageBoxButtons.YesNo
                }).then(function (result) {
                    if (!(result === MessageBoxResult.No || result === MessageBoxResult.Cancel)) {
                        resolve();
                    }
                })
            })
        }

        // For a warning message the user needs to confirm seeing before moving on
        function warning(message, title) {
            return $q(function (resolve) {
                var messageBoxModal = $uibModal.rejectableOpen({
                    templateUrl: 'app/UI-Lib/Message-Box/messageBoxWarning.html',
                    controller: 'MessageBoxController',
                    controllerAs: 'vm',
//                    size: 'sm',
                    animation: false,
                    windowClass: 'nested-modal show',
                    backdropClass: 'show',
                    openedClass: 'modal-open-nested',
                    backdrop: true,
                    resolve: {
                        options: {
                            message: message,
                            title: title,
                            buttons: MessageBoxButtons.OK
                        }
                    }
                });

                messageBoxModal.result.then(function (result) {
                    resolve(result);
                }, function () {
                    // If the modal is closed via escape or click outside the modal,
                    // resolve as if the cancel button was pressed
                    resolve(MessageBoxResult.Cancel);
                });
            });
        }
    }
}());
