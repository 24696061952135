import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { EntityTypeIds } from '../../../../constants.new';

@Component({
    selector: "accruals-descriptors-modal",
    templateUrl: './accruals.descriptors.modal.component.html',
})
export class AccrualsDescriptorsModalComponent implements OnInit {
    //initialState
    companyId: number;
    accrualDescriptors: Core.CompanyAccrualDescriptorModel[];
    canEdit: boolean;
    flatInUseDescriptors: Core.CompanyAccrualDescriptorModel[]
    onClose: (accrualDescriptors?: Core.CompanyAccrualDescriptorModel[]) => void;

    loading: boolean = false;
    inUseDescriptors: _.Dictionary<Core.CompanyAccrualDescriptorModel[]>;
    EntityTypeIds = EntityTypeIds;

    constructor(private bsModalRef: BsModalRef) { }

    async ngOnInit() {
        this.accrualDescriptors = _.sortBy(this.accrualDescriptors, ['entityTypeId', 'descriptorName']) || [];
        this.inUseDescriptors = _.chain(this.flatInUseDescriptors)
            .reject(x => _.some(this.accrualDescriptors, {descriptorId: x.descriptorId}))
            .sortBy('descriptorName')
            .groupBy(x => x.entityTypeId == EntityTypeIds.SITE ? 'site' : 'parcel')
            .value();
    }

    descriptorSelected(descriptor: Core.CompanyAccrualDescriptorModel): void {
        this.accrualDescriptors.push(descriptor);

        if(descriptor.entityTypeId == EntityTypeIds.SITE) {
            _.remove(this.inUseDescriptors.site, descriptor);
        } else {
            _.remove(this.inUseDescriptors.parcel, descriptor);
        }
    }

    removeDescriptor(descriptor: Core.CompanyAccrualDescriptorModel): void {
        _.remove(this.accrualDescriptors, descriptor);

        if (descriptor.entityTypeId == EntityTypeIds.SITE) {
            this.inUseDescriptors.site.push(descriptor);
        } else {
            this.inUseDescriptors.parcel.push(descriptor);
        }
    }

    cancel(): void {
        this.bsModalRef.hide();
    }

    save(): void {
        this.bsModalRef.hide();
        this.onClose(this.accrualDescriptors);
    }

}
