import { Injectable } from '@angular/core';

const bufferSize = 4000;

class BufferedByteReader {
    private buffer: number[];
    private bufferIndex: number;
    private realIndex: number;
    private dataLength: number;

    constructor (private blob: Blob) {
        this.buffer = null;
        this.dataLength = this.blob.size;
        this.realIndex = 0;
    }

    private async getBlobBytes(start: number, end: number): Promise<number[]> {
        // Most browsers
        if ((this.blob as any).arrayBuffer) {
            return Array.from(new Uint8Array(await (this.blob as any).slice(start, end).arrayBuffer()));
        }
        // Internet Explorer
        else  {
            const resultReader = new FileReader();
            const resultBuffer = await new Promise((res, rej) => {
                resultReader.onload = () => { res(resultReader.result); }
                resultReader.onerror = (err) => { rej(err); }
                resultReader.readAsArrayBuffer(this.blob.slice(start, start + end));
            });

            return Array.from(new Uint8Array(resultBuffer as ArrayBuffer));
        }
    }

    needBuffer(): boolean {
        return this.buffer === null || this.bufferIndex > bufferSize;
    }

    async populateBuffer(): Promise<void> {
        let bufferEnd = this.realIndex + bufferSize + 1;
        if (bufferEnd >= this.dataLength) { bufferEnd = this.dataLength; }
        this.buffer = await this.getBlobBytes(this.realIndex, bufferEnd);
        this.bufferIndex = 0;
    }

    readNextByte(): number {
        if (this.realIndex === this.dataLength) {
            return null;
        }

        this.realIndex = this.realIndex + 1;
        this.bufferIndex = this.bufferIndex + 1;
        return this.buffer[this.bufferIndex - 1];
    }

    getIndex(): number { return this.realIndex; }
    getLength(): number { return this.dataLength; }
}

// This is theoretically "HttpResponse<Blob>" in all circumstances, but to make this
// more easily testable, just assert the properties of an HttpResponse<Blob> that are
// really needed.
export interface BinaryResponse {
    status: number,
    headers: { get: (key: string) => string },
    body: Blob
};

export interface MultiPartResponseReader {
    readNext(): Promise<{ headers: { [key: string]: string }, binary: Blob }>;
    readAll(): Promise<{ binary: Blob[], text: string[] }>;
}

class InternalMultiPartResponseReader implements MultiPartResponseReader {
    private startIndex: number;
    private status: 'init' | 'header' | 'body';
    private winNl: boolean;
    private boundaryBytes: number[];
    private boundaryIndex: number;
    private responseReader: BufferedByteReader;
    private headers: { [key: string]: string };

    constructor(
        private mixedMatch: RegExpExecArray,
        private response: BinaryResponse
    ) {
        this.startIndex = 0;
        this.status = 'init';
        this.winNl = false;
        this.boundaryBytes = ('--' + this.mixedMatch[1]).split('').map(c => c.charCodeAt(0));
        this.boundaryIndex = 0;
        this.responseReader = new BufferedByteReader(this.response.body);
        this.headers = {};
    }

    async readNext(): Promise<{ headers: { [key: string]: string }, binary: Blob }> {
        /* For multi-part downloads, the content-type header contains a boundary. The response body will have
         * several files where for each file, the boundary and headers will exist (newline-separated), then
         * there will be a double-newline, then the file contents. Something like this:
         *
         * content-type header value: multipart/mixed; boundary="3f351a3e-5c9d-4c29-a278-25b15639ff9c"
         * response body:
         * --3f351a3e-5c9d-4c29-a278-25b15639ff9c
         * Content-Type: application/pdf
         * Content-Disposition: attachment; filename=SomeFile.pdf
         * 
         * (binary content)
         * 
         * --3f351a3e-5c9d-4c29-a278-25b15639ff9c
         * Content-Type: application/pdf
         * Content-Disposition: attachment; filename=SomeOtherFile.pdf
         * 
         * (binary content)
         * --3f351a3e-5c9d-4c29-a278-25b15639ff9c--
         */

        let endIndex = null;
        let headerBuffer = [];
        await this.responseReader.populateBuffer();
        let currentByte = this.responseReader.readNextByte();
        if (currentByte === null) { return null; }
        let initTime = 0;
        let bodyTime = 0;
        let headerTime = 0;
        while (currentByte !== null) {
            // Assert that the stream starts with the boundary and read the newline character
            switch (this.status) {
                case 'init':
                    if (this.boundaryIndex === this.boundaryBytes.length) {
                        // Get the next byte after the boundary and expect either 13 then 10 for
                        // crlf (Windows-style) or just 10 for lf (Unix-style)
                        if (currentByte === 13) {
                            this.winNl = true;
                            this.boundaryBytes = this.boundaryBytes.concat([13, 10]);
                            if (this.responseReader.needBuffer()) {
                                await this.responseReader.populateBuffer();
                            }
                            currentByte = this.responseReader.readNextByte();
                            if (currentByte !== 10) {
                                throw new Error('Invalid newline in multi-part download response');
                            }
                        }
                        else if (currentByte === 10) {
                            this.winNl = false;
                            this.boundaryBytes.push(10);
                        }
                        else {
                            throw new Error('Missing newline in multi-part download response');
                        }
                        this.status = 'header';
                        console.log(`Using ${this.winNl ? 'windows' : 'unix'}-style line endings`);
                    }
                    else if (this.boundaryBytes[this.boundaryIndex] === currentByte) {
                        this.boundaryIndex++;
                    }
                    else {
                        throw new Error('Unable to read multi-part download response');
                    }
                    break;
                case 'header':
                    // If we're reading windows newlines and encounter cr, ignore it
                    if (!(this.winNl && currentByte === 13)) {
                        if (currentByte !== 10) {
                            headerBuffer.push(String.fromCharCode(currentByte));
                        }
                        else if (headerBuffer.length === 0) {
                            headerBuffer = [];
                            this.boundaryIndex = null;
                            this.status = 'body';
                            this.startIndex = this.responseReader.getIndex();
                        }
                        else {
                            const headerMatch = /^([^\:]+)\:\s*(.*)$/.exec(headerBuffer.join(''));
                            if (headerMatch) {
                                this.headers[headerMatch[1]] = headerMatch[2];
                            }
                            else {
                                console.log('Unable to read header (silently skipping)');
                                console.log(headerBuffer.map(b => b.charCodeAt(0)));
                            }
                            headerBuffer = [];
                        }
                    }
                    break;
                case 'body':
                    if (this.boundaryIndex === null) {
                        if (currentByte === 10) {
                            endIndex = this.responseReader.getIndex() - (this.winNl ? 2 : 1);
                            this.boundaryIndex = 0;
                        }
                    }
                    else if (this.boundaryBytes[this.boundaryIndex] === currentByte) {
                        this.boundaryIndex++;
                        if (this.boundaryIndex === this.boundaryBytes.length) {
                            const result = {
                                headers: this.headers,
                                binary: this.response.body.slice(this.startIndex, endIndex)
                            };
                            this.startIndex = this.responseReader.getIndex();
                            this.headers = {};
                            this.status = 'header';
                            return result;
                        }
                    }
                    else {
                        this.boundaryIndex = null;
                    }
                    break;
            }
            if (this.responseReader.needBuffer()) {
                await this.responseReader.populateBuffer();
            }
            currentByte = this.responseReader.readNextByte();
        }
        return {
            headers: this.headers,
            binary: this.response.body.slice(
                this.startIndex,
                // Remove the ending newline + boundary + "--" + newline; boundary bytes is as long as boundary + newline,
                // so after subtracting that a newline and two characters are left
                this.responseReader.getLength() - this.boundaryBytes.length - 2 - (this.winNl ? 2 : 1)
            )
        };
    }

    async readAll(): Promise<{ binary: Blob[], text: string[] }> {
        const binary: Blob[] = [];
        const text: string[] = [];

        let response = await this.readNext();
        while (response !== null) {
            // If the response contains a content-disposition header indicating this is a download, add it to the binary
            // array. An example of such a header is:
            // Content-Disposition: attachment; filename="My Form.pdf"
            let isBinary = false;
            const contentDisposition = response.headers['Content-Disposition'];
            if (contentDisposition) {
                const contentDispositionParts = contentDisposition.split(';').map(p => p.trim());
                isBinary = contentDispositionParts.some(p => p === 'attachment');
            }
            if (isBinary) {
                binary.push(response.binary);
            }
            else {
                text.push(await this.readBlobAsText(response.binary));
            }
            response = await this.readNext();
        }

        return { binary, text };
    }

    private async readBlobAsText(blob: Blob) {
        // There are more modern ways to do this, but I'm reasonably confident this works in Internet Explorer 11
        const eventResult = await new Promise<any>(res => {
            const reader = new FileReader();
            reader.addEventListener('loadend', res);
            reader.readAsText(blob);
        });
        return eventResult.srcElement.result;
    }
}

@Injectable({
    providedIn: 'root'
})
export class MultiPartResponseService {
    constructor() { }

    ReadResponse(response: BinaryResponse): {
        isMultiPartResponse: boolean,
        reader: MultiPartResponseReader
    } {
        if (response.status !== 200) {
            throw new Error(`An unexpected error has occurred. Status code: ${response.status}.`);
        }
        const contentType = response.headers.get('content-type');
        const mixedMatch = /^multipart\/mixed.*boundary\="(.*)"/.exec(contentType);

        if (mixedMatch) {
            return {
                isMultiPartResponse: true,
                reader: new InternalMultiPartResponseReader(mixedMatch, response),
            };
        }
        else {
            return { isMultiPartResponse: false, reader: null };
        }
    }
}
