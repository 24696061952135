import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from 'src/app/Compliance/AgGrid';
import { PaymentBatchService } from '../paymentBatch.service';

export interface PaymentBatchListDataSourceParams {
    companyId: number;
    canGetRows: boolean;
}

export class PaymentBatchCommandCenterAgGridDataSource extends AgGridDataSourceBase<PaymentBatchListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        protected _dataSourceParamsFn: () => PaymentBatchListDataSourceParams,
        private readonly _paymentBatchService: PaymentBatchService) {
         super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Core.PaymentBatchPropertyEnum>[] = [
        { name: 'batchNo', value: Core.PaymentBatchPropertyEnum.BatchNo },
        { name: 'description', value: Core.PaymentBatchPropertyEnum.Description },
        { name: 'earliestDueDate', value: Core.PaymentBatchPropertyEnum.EarliestDueDate },
        { name: 'fundingDueDate', value: Core.PaymentBatchPropertyEnum.FundingDueDate },
        { name: 'completedDate', value: Core.PaymentBatchPropertyEnum.CompletedDate },
        { name: 'batchStatus', value: Core.PaymentBatchPropertyEnum.BatchStatus },
        { name: 'paymentCount', value: Core.PaymentBatchPropertyEnum.PaymentCount },
        { name: 'paymentAmount', value: Core.PaymentBatchPropertyEnum.PaymentAmount },
        { name: 'fundedAmount', value: Core.PaymentBatchPropertyEnum.FundedAmount },
        { name: 'company', value: Core.PaymentBatchPropertyEnum.Company },
        { name: 'batchTypeName', value: Core.PaymentBatchPropertyEnum.BatchTypeName },
    ];

    getSearchParamsWithoutPagination() : Core.PaymentBatchSearchModel{
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            companyId: this._dataSourceParams.companyId
        };
    }

    protected canGetRows(): boolean {
        return !!this._dataSourceParams.canGetRows;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.PaymentBatchSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            companyId: this._dataSourceParams.companyId
        };

        const result = await this._paymentBatchService.getPaymentBatches(searchParams);
        if (!result) {
            return null;
        }

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
