<ws-modal-wrapper [headerLabel]="headerLabel" acceptLabel="Save" (save)="save()" (cancel)="cancel()"
        [disableSave]="!isSaveable()">
    <div class="ws-flex-container-vertical ws-stretch">
        <table class="cc-bulk-update-fields">
            <tbody>
                <tr>
                    <td>
                        <label>Category:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="false" [(fieldAction)]="taBulkUpdateRequest.categoryAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="taBulkUpdateRequest.taxAuthorityCategoryId"
                                [disabled]="isDisabled(taBulkUpdateRequest.categoryAction)">
                            <option [ngValue]="undefined"></option>
                            <option *ngFor="let category of categories" [ngValue]="category.id">{{category.label}}</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Taxable Assessment:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="true" [(fieldAction)]="taBulkUpdateRequest.taxableAssessmentTypeAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="taBulkUpdateRequest.taxableAssessmentTypeId"
                                    [disabled]="isDisabled(taBulkUpdateRequest.taxableAssessmentTypeAction)">
                                <option *ngFor="let option of taxableAssessments" [ngValue]="option.id">{{option.label}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Typical Certification Date:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="false" [(fieldAction)]="taBulkUpdateRequest.certificationDateAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taBulkUpdateRequest.certificationMonth"
                                    [disabled]="isDisabled(taBulkUpdateRequest.certificationDateAction)">
                                <option [ngValue]="undefined"></option>
                                <option *ngFor="let option of certificationMonths" [ngValue]="option">{{option}}</option>
                            </select>
                            <span>/</span>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taBulkUpdateRequest.certificationDay"
                                    [disabled]="isDisabled(taBulkUpdateRequest.certificationDateAction)">
                                <option [ngValue]="undefined"></option>
                                <option *ngFor="let option of certificationDays" [ngValue]="option">{{option}}</option>
                            </select>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taBulkUpdateRequest.certificationType"
                                    [disabled]="isDisabled(taBulkUpdateRequest.certificationDateAction)">
                                <option [ngValue]="undefined"></option>
                                <option *ngFor="let option of certificationTypes" [ngValue]="option.id">{{option.label}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Obtain In Advance:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="true" [(fieldAction)]="taBulkUpdateRequest.obtainInAdvanceAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <input type="checkbox" [(ngModel)]="taBulkUpdateRequest.obtainInAdvance"
                            [disabled]="isDisabled(taBulkUpdateRequest.obtainInAdvanceAction)">
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Default Annual Increase:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="true" [(fieldAction)]="taBulkUpdateRequest.defaultAnnualIncreaseAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <input type="number" class="form-control" style="width: auto; text-align: right;"
                            [(ngModel)]="defaultAnnualIncreaseDisplay" (blur)="defaultAnnualIncreaseBlur(defaultAnnualIncreaseDisplay)"
                            [disabled]="isDisabled(taBulkUpdateRequest.defaultAnnualIncreaseAction)">
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>YoY % Change:</label>
                    </td>
                    <td>
                        <tax-authority-bulk-update-action [requiredField]="true" [(fieldAction)]="taBulkUpdateRequest.yoyAction"></tax-authority-bulk-update-action>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taBulkUpdateRequest.taxYear"
                                    [disabled]="isDisabled(taBulkUpdateRequest.yoyAction)">
                                <option *ngFor="let option of selectableTaxYears" [ngValue]="option">{{option}}</option>
                            </select>
                            <span>  </span>
                            <input type="text" class="form-control" style="width: auto; display: inline-block; text-align: right;"
                                [(ngModel)]="yoyPercentDisplay" (blur)="yoyPercentBlur(yoyPercentDisplay)"
                                [disabled]="isDisabled(taBulkUpdateRequest.yoyAction)">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ws-modal-wrapper>
