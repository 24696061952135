import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { AdvancedSearchCategoryTypes, DefaultDisplayTypes } from '../../../constants.new';
import { SearchPageType, UserSaveService } from '../../../Navigation/User-Save/userSave.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import {
    AdvancedSearchCriteria,
    AdvancedSearchPersistenceService,
    SavedSearchModel
} from '../advancedSearchPersistence.service';
import { SmartSearchService } from '../smartSearch.service';

export interface SaveSearchModalParams {
    searchCriteria: AdvancedSearchCriteria;
    searchType: number;
    hasEditPermission: boolean;
}

export interface SaveSearchModalResult {
    search: SavedSearchModel;
    type: number;
}

@Component({
    selector: 'save-search-modal',
    templateUrl: './saveSearchModal.component.html',
    styleUrls: ['./saveSearchModal.component.scss']
})
export class SaveSearchModalComponent implements OnInit, IWeissmanModalComponent<SaveSearchModalParams, SaveSearchModalResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _fb: UntypedFormBuilder,
        private readonly _smartSearchService: SmartSearchService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _messageModalService: MessageModalService,
        private readonly _toastr: ToastrService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _userSaveService: UserSaveService
    ) {}

    @ViewChild('searchNameRef', { static: true }) searchNameRef: ElementRef;
    @ViewChild('newCategoryNameRef', { static: true }) newCategoryNameRef: ElementRef;

    params: SaveSearchModalParams;
    result: SaveSearchModalResult;

    isBusy: boolean;
    categoriesLoading: boolean = true;
    saveHelpContentId: string = 'app.save';

    searchFormGroup: UntypedFormGroup;
    hasEditPermission: boolean;
    defaultDisplayTypes = DefaultDisplayTypes;

    categoryTypes = AdvancedSearchCategoryTypes;
    categoryEntered: boolean = true;
    enteringCategory: boolean = false;
    addingNewCategory: boolean = false;
    newCategoryName: UntypedFormControl = new UntypedFormControl('');

    private _searchCategories: any[];
    private _noCategory = { categoryName: 'No Category', categoryId: null, categoryType: 1 };

    get searchCategoryOptions(): any[] {
        const form = this.searchFormGroup.value;
        return this._searchCategories ? this._searchCategories.filter(x => x.categoryType === form.type || !x.categoryId && form.type === AdvancedSearchCategoryTypes.CUSTOM) : [];
    }

    get searchName(): UntypedFormControl {
        return this.searchFormGroup.get('searchName') as UntypedFormControl;
    }

    async ngOnInit(): Promise<void> {
        this.hasEditPermission = this.params.hasEditPermission;

        this.searchFormGroup = this._fb.group({
            type: [this.params.searchType || AdvancedSearchCategoryTypes.CUSTOM],
            defaultDisplay: [this.params.searchCriteria.defaultDisplay || DefaultDisplayTypes.CRITERIA],
            categoryId: [this.params.searchCriteria.categoryId || null],
            searchName: [this.params.searchCriteria.searchName || null, { validators: [Validators.required, Validators.maxLength(50) ] }],
        }, {
            validator: (formGroup: UntypedFormGroup) => {
                this.saveHelpContentId = (this.addingNewCategory) ? 'smartSearch.new-category' : 'app.save';
                return !this.addingNewCategory && formGroup.get('categoryId').valid && formGroup.get('searchName').valid;
            }
        });

        const searchCategories = await this._smartSearchService.getSearchCategories();
        this._searchCategories = _.sortBy(searchCategories, 'categoryName');
        if (!this._searchCategories.find(x => x.categoryName === 'No Category')) {
            this._searchCategories.unshift(this._noCategory);
        }
        this.setCustomCategory();
        this.categoriesLoading = false;

        this.searchNameRef.nativeElement.focus();
    }

    toggled(): void {
        this.addingNewCategory = !this.addingNewCategory;
        if (this.addingNewCategory) {
            this.saveHelpContentId = 'smartSearch.new-category';
            this.searchFormGroup.disable();
            this.newCategoryNameRef.nativeElement.focus();
        } else {
            this.saveHelpContentId = 'app.save';
            this.searchFormGroup.enable();
            this.newCategoryName.setValue(null);
        }
    }

    setCustomCategory(): void {
        this.searchFormGroup.patchValue({ categoryId: null });
    }

    findSystemCategory(): void {
        if (!this.searchFormGroup.value.categoryId && (this._searchCategories && this._searchCategories.length)) {
            this.searchFormGroup.patchValue({ categoryId: this._searchCategories.find(x => x.categoryType === AdvancedSearchCategoryTypes.SYSTEM).categoryId });
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    createNewCategory(): void {
        if (!this.newCategoryName.value) {
            return;
        }

        this._searchCategories.push({
            categoryName: this.newCategoryName.value,
            categoryType: this.searchFormGroup.value.type,
            categoryId: 'new'
        });
        this._searchCategories = _.sortBy(this._searchCategories, 'categoryName');

        this.searchFormGroup.patchValue({ categoryId: 'new' });
        this.addingNewCategory = false;
        this.searchFormGroup.enable();

        this.searchNameRef.nativeElement.focus();
    }

    async saveSearch(): Promise<void> {
        this.isBusy = true;
        const form = this.searchFormGroup.value;
        if (form.categoryId === 'new') {
            const category = this._searchCategories.find(x => x.categoryId === 'new');

            try {
                const categoryToSubmit = { ...category };
                delete categoryToSubmit.categoryId;

                const result = await this._smartSearchService.createSearchCategory(categoryToSubmit);
                this.searchFormGroup.patchValue({ categoryId: result.categoryId });
                try {
                    const updated = await this._createOrUpdate();
                    this._productAnalyticsService.logEvent('click-SMART-save-search', {
                        smartSavedSearchType: updated.isSystemSearch ? 'System' : 'User',
                        smartSavedSearchDisplay: updated.defaultDisplay === DefaultDisplayTypes.CRITERIA ? 'Filter' : 'Detailed',
                        smartSavedSearchCategory: updated.categoryName ? updated.categoryName : 'No Category'
                    });
                    this._broadcastAndClose(updated);
                } catch (e1) {
                    this._errorToast(e1, 'Search name/category already exists');
                }
            } catch (e2) {
                this._errorToast(e2, 'Category already exists.');
            } finally {
                this.isBusy = false;
            }
        } else {
            try {
                const updated = await this._createOrUpdate();
                this._productAnalyticsService.logEvent('click-SMART-save-search', {
                    smartSavedSearchType: updated.isSystemSearch ? 'System' : 'User',
                    smartSavedSearchDisplay: updated.defaultDisplay === DefaultDisplayTypes.CRITERIA ? 'Filter' : 'Detailed',
                    smartSavedSearchCategory: updated.categoryName ? updated.categoryName : 'No Category'
                });
                this._broadcastAndClose(updated);
            } catch (e1) {
                this._errorToast(e1, 'Search name/category already exists');
            } finally {
                this.isBusy = false;
            }
        }
    }

    private _broadcastAndClose(savedSearch: any): void {
        this._advancedSearchPersistenceService.reloadingSearch = true;
        const form = this.searchFormGroup.value;
        this.result = {
            search: savedSearch,
            type: form.type
        };
        this._userSaveService.setDirty(SearchPageType.Smart);
        this._bsModalRef.hide();
    }

    private _errorToast(error: any, msg: string): void {
        if (error.status === 409) {
            this._toastr.error('Error!', msg);
        } else {
            this._toastr.error('Error!', 'Server Error');
        }
    }

    private async _createOrUpdate(): Promise<any> {
        const form = this.searchFormGroup.value;
        const search = { searchCriteria: { ...this.params.searchCriteria }, ...form } as SavedSearchModel;
        delete search.type;
        // TODO searchId doesn't exist on this model. Determine if there is a reason this code was here in the old JS code.
        // it looks as though it was used to update the values if the same save name was used, although both the old JS code
        // and the new code display an error toast in that case. Maybe this condition can be deleted
        if (search.searchId) {
            if (form.type === AdvancedSearchCategoryTypes.CUSTOM) {
                return await this._smartSearchService.saveCustomSearch(search);
            } else {
                try {
                    await this._messageModalService.confirm('Confirm Overwriting System Search', 'Confirm Overwrite');
                } catch {
                    return Promise.reject();
                }
                return await this._smartSearchService.saveSystemSearch(search);
            }
        } else {
            if (form.type === AdvancedSearchCategoryTypes.CUSTOM) {
                return await this._smartSearchService.createCustomSearch(search);
            } else {
                return await this._smartSearchService.createSystemSearch(search);
            }
        }
    }
}
