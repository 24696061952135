<ws-busy-indicator *ngIf="isBusy" message="Downloading"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Download Appeal Document"
                  subHeaderLabel="Select an Appeal document and click the download button to download a copy"
                  acceptLabel="Close"
                  [hideCancel]="true"
                  (save)="cancel()">
    <div class="mb-3 ws-flex-container-vertical">
        <label for="selectedForm">Appeal Form</label>
        <label class="ws-font-weight-normal">
            <input type="checkbox" [(ngModel)]="includeSupplementalForms">
            Include Supplemental Forms
        </label>
        <div class="input-group">
            <select class="form-select form-select-sm"
                    id="selectedForm"
                    [(ngModel)]="selectedForm">
                <option *ngFor="let appealForm of appealForms" [ngValue]="appealForm">{{ appealForm.formRevisionName }}</option>
            </select>
            <button type="button"
                    class="btn btn-outline-secondary btn-sm"
                    aria-label="Download"
                    title="Download"
                    (click)="downloadForm()"
                    [disabled]="!selectedForm">
                <span class="fa fa-download"></span>
            </button>
        </div>
    </div>
</ws-modal-wrapper>

