import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WsAgGridWrapperComponent } from './wsAgGridWrapper.component';
import { AgGridModule } from 'ag-grid-angular';
import { TaxRatesPanelTaxAuthoritiesCellRendererComponent } from '../../../Assessor-Collector/Tax-Rates/agGridTaxAuthoritiesCellRenderer.component';
import { TaxRateCommandCenterCommentsCellRendererComponent } from '../../../Tax-Rate/Command-Center/agGridCommentsCellRenderer.component';
import { TaxRateCommandCenterAttachmentsCellRendererComponent } from '../../../Tax-Rate/Command-Center/agGridAttachmentsCellRenderer.component';
import { TaxRateQcByCellRendererComponent } from '../../../Tax-Rate/agGridQcByCellRenderer.component';
import { ForecastBudgetYearCellRendererComponent } from '../../../Budget/agGridForecastBudgetYearCellRender.component';
import { ForecastBudgetYearCellEditorComponent } from '../../../Budget/agGridForecastBudgetYearCellEditor.component';
import { AgGridSwitchCellRendererComponent } from '../../../Budget/agGridSwitchCellRenderer.component';
import { TaxingDistrictListGridActionCellRendererComponent } from '../../../Assessor-Collector/Taxing-District/Taxing-District-List/agGridActionCellRenderer.component';
import { UserGroupsGridActionCellRendererComponent } from '../../../User-Group/User-Group-List/agGridActionCellRendererComponent';
import { CompanyPermissionsGridActionCellRendererComponent } from '../../../Permission/Company/Panel/agGridActionCellRenderer.component';
import { ActionViewOutputDefaultsGridActionCellRendererComponent } from '../../../Task/ActionView/Action-View-Output-Defaults/agGridActionCellRenderer.component';
import { AgGridExtensionsModule } from '../agGridExtensions.module';
import { AgGridLoadingOverlayComponent } from '../../../Common/AgGrid/agGridLoadingOverlay.component';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer } from '../MultiSelectTracker';
import { AgGridLinkCellRenderer } from '../CellRenderers/agGridLinkCellRenderer.component';
import { CommentsModule } from '../../../Comments/comments.module';
import {
    InstanceRightGridActionCellRendererComponent
} from '../../../Permission/Instance/agGridActionCellRenderer.component';
import {
    ApplicationPermissionGridActionCellRendererComponent
} from '../../../Permission/Application/agGridActionCellRenderer.component';

@NgModule({
    imports: [
        CommonModule,
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            TaxRatesPanelTaxAuthoritiesCellRendererComponent,
            TaxRateCommandCenterCommentsCellRendererComponent,
            TaxRateCommandCenterAttachmentsCellRendererComponent,
            TaxRateQcByCellRendererComponent,
            ForecastBudgetYearCellRendererComponent,
            ForecastBudgetYearCellEditorComponent,
            AgGridSwitchCellRendererComponent,
            TaxingDistrictListGridActionCellRendererComponent,
            UserGroupsGridActionCellRendererComponent,
            CompanyPermissionsGridActionCellRendererComponent,
            InstanceRightGridActionCellRendererComponent,
            ApplicationPermissionGridActionCellRendererComponent,
            ActionViewOutputDefaultsGridActionCellRendererComponent,
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            AgGridLinkCellRenderer
        ])
    ],
    declarations: [WsAgGridWrapperComponent],
    exports: [WsAgGridWrapperComponent]
})
export class WsAgGridWrapperModule {}
