<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Save Import Specification" acceptLabel="OK" (save)="saveSpecification()" (cancel)="cancel()"
                  [disableSave]="!((viewType && specificationName && saveType ==='insert') || saveType !== 'insert')">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="row">
            <div class="col-lg-12">
                <p *ngIf="!params.allowNoSave">Save the import specification by choosing one of the options below.</p>
                <p *ngIf="params.allowNoSave">You made changes after applying the import specification.  How would you like to proceed?</p>
            </div>
        </div>
        <div class="row radio" *ngIf="params.allowNoSave">
            <div class="col-lg-12">
                <label>
                    <input type="radio" name="saveType" value="nosave" [(ngModel)]="saveType">
                    Continue without saving
                </label>
            </div>
        </div>
        <div class="row radio" *ngIf="params.importFileSpecificationId">
            <div class="col-lg-12">
                <label>
                    <input type="radio" name="saveType" value="update" [(ngModel)]="saveType">
                    Update existing import specification: '{{ params.importFileSpecificationName }}'
                </label>
            </div>
        </div>
        <div class="row radio">
            <div class="col-lg-12">
                <label>
                    <input type="radio" name="saveType" value="insert" [(ngModel)]="saveType">
                    Add a new import specification
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <select name="viewType" class="form-select form-select-sm" [(ngModel)]="viewType" [disabled]="saveType!=='insert'">
                    <option value="" disabled selected>Visibility</option>
                    <option value="private">Private</option>
                    <option value="company" *ngIf="params.companyId">Company</option>
                    <option value="shared" *ngIf="canSaveSharedSpecs">Shared</option>
                </select>
            </div>
            <div class="col-lg-8">
                <input type="text" name="specificationName" class="form-control" maxlength="50" [(ngModel)]="specificationName" placeholder="Specification name" [disabled]="saveType!=='insert'" autocomplete="off">
            </div>
        </div>
        <div *ngIf="supportsDynamicFieldConfiguration" style="padding-top: 5px;">
            <small>Note: Null property characteristics will default to Ignore</small>
        </div>
    </div>
</ws-modal-wrapper>
