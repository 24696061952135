import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { IWeissmanModalComponent, WeissmanModalService } from '../../WeissmanModalService';
import { FormTemplateValidationParams, FormTemplateValidationComponent } from '../Form-Template-Validation/formTemplateValidation.component';

@Component({
    selector: 'form-template-upload',
    templateUrl: './formTemplateUpload.component.html'
})
export class FormTemplateUploadComponent implements IWeissmanModalComponent<void, Compliance.ReturnFormTemplateValidationResultModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formService: FormService,
        private readonly _modalService: WeissmanModalService
    ) { }

    params: void;
    result: Compliance.ReturnFormTemplateValidationResultModel;

    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    file: File;

    cancel(): void {
        this._bsModalRef.hide();
    }

    filePicked(files: File[]): void {
        this.file = files[0];
    }

    async upload(): Promise<void> {
        this.busyMessageManager.add('Uploading', 'uploading');

        let result: Compliance.ReturnFormTemplateValidationResultModel = null;

        try {
            const fileMetadata: Compliance.ReturnFormTemplateModel = {
                formRevisionId: this._formService.formRevisionId,
                fileName: this.file.name
            };
            
            result = await this._formService.updateTemplate(this.file, fileMetadata);
        } finally {
            this.busyMessageManager.remove('uploading');
        }

        if (!result) {
            return Promise.resolve();
        }

        const hasErrors = result.errors && result.errors.length;
        const hasWarnings = result.warnings && result.warnings.length;

        if (hasErrors || hasWarnings) {
            const params: FormTemplateValidationParams = {
                validationResult: result,
                message: hasErrors ?
                    'The template was not uploaded because it contains errors.' :
                    'The template was successfully uploaded.  Please review template errors and/or warnings.'
            };

            await this._modalService.showAsync(FormTemplateValidationComponent, params, 'modal-lg');
            
            if (hasErrors) {
                return Promise.resolve();
            }
        }

        this.result = result;
        this._bsModalRef.hide();
    }
}
