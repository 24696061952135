import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { LongRunningProcessRepository } from '../Repositories';
import { WebsocketListenerService } from '../websocketListener.service';

@Injectable()
export class LongRunningProcessService {
    constructor(
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _websocketListenerService: WebsocketListenerService) {
    }

    private _longRunningProcessQuiescedStatusChangeSub: Subscription;
    private _longRunningProcessStatusChangeSub: Subscription;

    private _startSubject: Subject<void> = new Subject();
    private _refreshSubject: Subject<void> = new Subject();
    private _longRunningProcessQuiescedStatusSubject = new Subject<Compliance.LongRunningProcessQuiescedStatusModel>();
    private _longRunningProcessStatusSubject = new Subject<Compliance.LongRunningProcessStatusChangeModel>();

    private _autoRefresh: boolean = false;
    private _quiescedStatus: Compliance.LongRunningProcessQuiescedStatusModel;
    private _queueTypes: Compliance.LongRunningProcessQueueTypeModel[];
    private _controlMessageTypes: Compliance.LongRunningProcessControlMessageTypeModel[];
    private _serviceHosts: string[];

    get quiescedStatus(): Compliance.LongRunningProcessQuiescedStatusModel { return this._quiescedStatus; }
    get queueTypes(): Compliance.LongRunningProcessQueueTypeModel[] { return this._queueTypes; }
    get controlMessageTypes(): Compliance.LongRunningProcessControlMessageTypeModel[] { return this._controlMessageTypes; }
    get serviceHosts(): string[] { return this._serviceHosts; }
    get autoRefresh(): boolean { return this._autoRefresh; }

    get start$(): Observable<void> { return this._startSubject.asObservable(); }
    get refresh$(): Observable<void> { return this._refreshSubject.asObservable(); }
    get longRunningProcessStatusChange$(): Observable<Compliance.LongRunningProcessStatusChangeModel> { return this._longRunningProcessStatusSubject.asObservable(); }
    get longRunningProcessQuiescedStatusChange$(): Observable<Compliance.LongRunningProcessQuiescedStatusModel> { return this._longRunningProcessQuiescedStatusSubject.asObservable(); }

    async start(): Promise<void> {
        await this._loadQuiescedStatus();
        await this._loadQueueTypes();
        await this._loadControlMessageTypes();
        await this._loadServiceHosts();

        this._longRunningProcessStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(
            (x) => {
                this._longRunningProcessStatusSubject.next(x);
            });

        this._longRunningProcessQuiescedStatusChangeSub = this._websocketListenerService.longRunningProcessQuiescedStatusChange$.subscribe(
            async (x) => {
                this._longRunningProcessQuiescedStatusSubject.next(x);

                await this._loadQuiescedStatus();
            });

        this._startSubject.next();
    }

    stop(): void {
        this._longRunningProcessQuiescedStatusChangeSub && this._longRunningProcessQuiescedStatusChangeSub.unsubscribe();
        this._longRunningProcessStatusChangeSub && this._longRunningProcessStatusChangeSub.unsubscribe();

        this._quiescedStatus = null;
        this._queueTypes = null;
    }

    async refresh(): Promise<void> {
        await this._loadQuiescedStatus();
        this._refreshSubject.next();
    }

    async updateQuiescedStatus(model: Compliance.LongRunningProcessQuiescedStatusModel): Promise<void> {
        this._quiescedStatus = await lastValueFrom(this._longRunningProcessRepository.updateQuiescedStatus(model));
    }

    setAutoRefresh(autoRefresh: boolean): void {
        this._autoRefresh = autoRefresh;
        if (autoRefresh) {
            this._refreshSubject.next();
        }
    }

    async test(parameters: Compliance.SyntheticLongRunningProcessParameters): Promise<void> {
        await lastValueFrom(this._longRunningProcessRepository.startSyntheticLongRunningProcess(parameters));
        this._refreshSubject.next();
    }

    async controlMessage(parameters: Compliance.ControlMessageLongRunningProcessParameters): Promise<void> {
        await lastValueFrom(this._longRunningProcessRepository.sendControlMessage(parameters));
        this._refreshSubject.next();
    }

    async get(longRunningProcessId: number): Promise<Compliance.LongRunningProcessModel> {
        return await lastValueFrom(this._longRunningProcessRepository.get(longRunningProcessId));
    }

    private async _loadQuiescedStatus(): Promise<void> {
        this._quiescedStatus = await lastValueFrom(this._longRunningProcessRepository.getQuiescedStatus());
    }

    private async _loadQueueTypes(): Promise<void> {
        this._queueTypes = await lastValueFrom(this._longRunningProcessRepository.getQueueTypes());
    }

    private async _loadControlMessageTypes(): Promise<void> {
        this._controlMessageTypes = await lastValueFrom(this._longRunningProcessRepository.getControlMessageTypes());
    }

    private async _loadServiceHosts(): Promise<void> {
        this._serviceHosts = await lastValueFrom(this._longRunningProcessRepository.getServiceHosts());
    }
}
