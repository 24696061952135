import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Transition } from '@uirouter/core/lib/transition/transition';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { CurrentEntityService } from '../Common/Entity/CurrentEntityService';
import { RestrictService } from '../Common/Permissions/restrict.service';
import { NavigationRepository } from '../Core-Repositories/navigation.repository';
import { SearchService } from '../Search/search.service';
import { MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { AccountService, GrantTypes, ILoginData } from '../User/account.service';
import { AuthStorageService } from '../User/authStorage.service';

export interface LoginFromSSOModel {
    message: string,
    grantType: GrantTypes,
    request: string | ILoginData
}

@Injectable({ providedIn: 'root' })
export class NavigationService {
    constructor(private readonly _accountService: AccountService,
                private readonly _searchService: SearchService,
                private readonly _authStorageService: AuthStorageService,
                private readonly _currentEntityService: CurrentEntityService,
                private readonly _restrictService: RestrictService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _navigationRepository: NavigationRepository,
                private readonly _router: UIRouter) {
    }

    childWindows = [];
    navWarningEnabled: boolean;
    initialUrl: string;

    private _globalEditModeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _globalEditMode$: Observable<boolean> = this._globalEditModeSubject.asObservable();

    private _loginFromSSOSubject: Subject<LoginFromSSOModel> = new Subject();
    private _loginFromSSO$: Observable<LoginFromSSOModel> = this._loginFromSSOSubject.asObservable();

    private _quickSearchListeners = [];
    private _globals = {
        defaultState: 'home',
        anonymousStates: [
            'login',
            'register',
            'resetPasswordUsingTokenFromEmail',
            'ssoLogin'
        ],
        storedState: '',
        storedParams: null
    };

    get globalEditMode$(): Observable<boolean> {
        return this._globalEditMode$;
    }

    get loginFromSSO$(): Observable<LoginFromSSOModel> {
        return this._loginFromSSO$;
    }

    set loginFromSSO(value: LoginFromSSOModel) {
        this._loginFromSSOSubject.next(value);
    }

    registerQuickSearch(callback: (string) => void): void {
        this._quickSearchListeners.push(callback);
    }

    openQuickSearch(resetCriteriaList: boolean, navigatingFrom?: string): void {
        // ToDo: This is here because the shortcut key to open quick search is activated during app load. Fix it!
        if (this._accountService.userData) {
            this._searchService.resetCriteriaList = resetCriteriaList;

            this._quickSearchListeners.forEach((callback) => {
                callback(navigatingFrom);
            });
        }
    }

    async onNavStart(transition: Transition): Promise<any> {
        this.initialUrl = window.location.href;

        const toState = transition.to();
        const toParams = transition.params();
        this._authStorageService.recordActivity('Navigation');
        // Skip navigation guard if we're going to an anonymous state (like login)
        const isRestricted = !this._globals.anonymousStates.includes(toState.name);
        if (!isRestricted) {
            return;
        }

        await this._accountService.navPromise();

        this._currentEntityService.init();

        if (!this._accountService.userData) {
            this._globals.storedState = toState.name;
            this._globals.storedParams = toParams;

            return transition.router.stateService.target('login');
            //$state.go('login');
        } else if (toState.name !== 'login') {
            // Remember last state visited except login.
            this._globals.storedState = toState.name;
            this._globals.storedParams = toParams;
        }

        // global roles check
        if (toState.data && toState.data.roles) {
            // check application permissions
            if (this._restrictService.isNotInRoles(toState.data.roles)) {
                return transition.router.stateService.target('unauthorizedAccess');
            }
        }

        // instance rights check
        if (toState.data && toState.data.instanceRights) {
            // check application permissions
            if (this._restrictService.hasNoInstanceRights(toState.data.instanceRights)) {
                return transition.router.stateService.target('unauthorizedAccess');
            }
        }
    }

    postLogin(): void {
        // Make sure the login state didn't find it's way into the storedState property.
        if (this._globals.storedState.indexOf('login') !== -1 || this._globals.storedState.length === 0) {
            this._globals.storedState = this._globals.defaultState;
        }

        if (this._globals.storedState === 'unauthorizedAccess' || this._globals.storedState === 'logout' || this._globals.storedState === 'resetPasswordUsingTokenFromEmail') {
            this._router.stateService.go('home');
        } else {
            this._router.stateService.go(this._globals.storedState, this._globals.storedParams);
        }
    }

    goToLogin(isLogout: boolean): void {
        console.log('Navigation.Service - goToLogin', isLogout);
        if (isLogout) {
            this._globals.storedState = 'home';
            this._router.stateService.go('login', { logout: true });
        } else {
            this._router.stateService.go('login');
        }
    }

    setDefaultUnload(): void {
        window.onbeforeunload = () => {
            for (let i = 0; i < this.childWindows.length; i++) {
                const childWindow = this.childWindows[i];
                if (childWindow && !childWindow.closed) {
                    childWindow.close();
                }
            }
        };
    }

    registerChildWindow(childWindow: any): void {
        this.childWindows.push(childWindow);
        if (!this.navWarningEnabled) {
            this.setDefaultUnload();
        }
    }

    enableNavWarning(message: string): void {
        this.navWarningEnabled = true;
        this._globalEditModeSubject.next(true);

        // First handle angular routing change
        this._router.transitionService.onBefore({}, async () => {
            if (this.navWarningEnabled) {
                await this._messageBoxService.confirm(message);
                this.disableNavWarning();
            }
        });

        // Now handle browser navigation changes
        // This message is ignored in Chrome, but at least it still warns the user
        window.onbeforeunload = (e) => {
            for (let i = 0; i < this.childWindows.length; i++) {
                const childWindow = this.childWindows[i];
                if (childWindow && !childWindow.closed) {
                    childWindow.close();
                }
            }
            // If we haven't been passed the event get the window.event
            e = e || window.event;

            // For IE6-8 and Firefox prior to version 4
            if (e) {
                e.returnValue = message;
            }

            // For Chrome, Safari, IE8+ and Opera 12+
            return message;
        };
    }

    disableNavWarning(): void {
        this.navWarningEnabled = false;
        this._globalEditModeSubject.next(false);
        this.setDefaultUnload();
    }

    getNavigationInfo(entityName: string, id: number): Promise<any> {
        return lastValueFrom(this._navigationRepository.getNavigationInfo(entityName, id));
    }

    getInitialUrl(): string {
        return this.initialUrl;
    }
}
