<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Site Permissions"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
