import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from "ngx-bootstrap/modal";
import { AnnualDetailsNavigationEventService } from "../annual-details-event.service";
import { NavigationState } from "../annual-details-navigation.service";
import { AnnualDetailsService } from "../annual-details.service";
import { AnnualDetailYear, AnnualDetailYearList } from "../Annual-Year/annual-year.model";

declare const _: any;

@Component({
    selector: 'add-year-modal',
    templateUrl: './add-annual-year.component.html'
})
export class AddYearModal implements OnInit {
    @Input() parcelId: number;
    @Input() yearsList: AnnualDetailYearList;
    @Input() navigationState: NavigationState;
    @ViewChild('addYearModal', { static: true }) addYearModal: ModalDirective;

    //currentYears: any;
    selectedYear: number;
    years: AnnualDetailYear[];
    saveEnabled: boolean = true;
    loading: boolean = false;

    constructor(private annualDetailsService: AnnualDetailsService,
        private navigationEvent: AnnualDetailsNavigationEventService) {
    }

    ngOnInit():void {
        this.initializeYearData();
    }

    showModalInit(): void {
        this.initializeYearData();
    }

    initializeYearData():void {
        this.years = this.yearsList.years;
        let currentYears = _.map(this.years, 'annualYear1');
        let parcelHasYears = true;

        if (currentYears.length === 0) {
            var d = new Date();
            currentYears = [d.getFullYear()];

            parcelHasYears = false;
        }

        let latestYear = _.maxBy(currentYears);
        let earliestYear = _.minBy(currentYears);
        let yearsArray = _.range(earliestYear - 5, latestYear + 5);

        // this.yearsList.options = _.filter(yearsArray, (element) => {
        //     return currentYears.indexOf(element) < 0; // || !this.parcelHasYears;
        // });

        yearsArray.sort();
        yearsArray.reverse();

        this.yearsList.options = _.map(yearsArray, function(year) {
            return {
                year: year,
                disabled: _.includes(currentYears, year) && parcelHasYears
            }
        });

        this.selectedYear = parcelHasYears ? latestYear + 1 : new Date().getFullYear();
    }

    async save(): Promise<void> {
        this.loading = true;
        
        try {
            this.saveEnabled = false;
            await this.annualDetailsService.addYear(this.parcelId, this.selectedYear, true)
            //this instead of refreshGrid because the add years is acceesible ON the grid
            this.navigationState.resetYearsHandler();
            this.addYearModal.hide();
            // Update parcel activity panel
            this.navigationEvent.refreshActivityData();
            this.saveEnabled = true;
        } finally {
            this.loading = false;
        }
    };

    cancel(): void {

    };
}
