<div class="container">
    <div class="single-column-layout">
        <h1>
            Import
            <span *ngIf="importType==='site'">Sites</span>
            <span *ngIf="importType==='parcel'">Parcels</span>
        </h1>

        <div [style.display]="(!isImporting && !userCancelled) ? 'block' : 'none'">
            <div class="mb-3">
                <label>Top Level Company:</label>
                <company-picker class="import-sites-picker"
                                [topLevelOnly]="true"
                                (companySelected)="companySelected($event)">
                </company-picker>
            </div>

            <div class="mb-3">
                <label>Comment:</label>
                <textarea class="form-control" style="width: 50%" rows="5" [(ngModel)]="payload.comment"></textarea>
            </div>

            <div class="mb-3">
                <input #fileInput type='file' (change)="fileChanged($event)" accept=".txt" style="display:inline-block">
                <span (click)="clearFilePicker()">
                    <i class="fa fa-close" *ngIf="hasFile"></i>
                </span>
            </div>

            <div style="margin-top: 15px;">
                <button class="flat-button secondary-button"
                        (click)="startImporting(true)"
                        [disabled]="!payload.topCompanyID || !fileLines">
                    Validate
                </button>
                <button class="flat-button"
                        (click)="startImporting(false)"
                        [disabled]="!payload.topCompanyID || !fileLines">
                    Import
                </button>
            </div>
        </div>

        <div *ngIf="isImporting" class="text-center">
            <h3 *ngIf="!userCancelled && initIndex < fileLines.length" class="repeating-ellipses text-start">Reading File</h3>
            <h3 *ngIf="!userCancelled && initIndex >= fileLines.length" class="text-start">Done</h3>
            <h3 *ngIf="userCancelled">Cancelled</h3>
            <div [style.display]="userCancelled ? 'none' : 'block'">
                <progressbar class="active"
                             [class.progress-striped]="initIndex < fileLines.length"
                             [max]="fileLines.length - 1"
                             [value]="initIndex - 1"
                             type="info">
                </progressbar>
            </div>
            <h3>
                {{successfulImportCount}} / {{fileLines.length - 1}}
                <span *ngIf="importType==='site'">Sites</span>
                <span *ngIf="importType==='parcel'">Parcels</span>
                 Successfully
                <span *ngIf="payload.validateOnly">Validated</span>
                <span *ngIf="!payload.validateOnly">Imported</span>
            </h3>

            <div style="margin-top: 20px;">
                <button *ngIf="!userCancelled && !userCancelling && initIndex < fileLines.length"
                        class="flat-button warning-button"
                        (click)="cancelImport()">
                    Cancel
                </button>
                <button *ngIf="userCancelled || initIndex >= fileLines.length"
                        class="flat-button"
                        (click)="initValues()">
                    OK
                </button>
            </div>
        </div>

        <div *ngIf="userCancelling" class="text-center">
            <h3>Cancelling...</h3>
            <i class="fa fa-spinner fa-pulse fa-5x" style="margin-top: 50px;"></i>
        </div>
        <div *ngIf="warnings && warnings.stringArray">
            <h3 *ngIf="warnings.stringArray.length > 0">
                Problems encountered during
                <span *ngIf="payload.validateOnly">validation:</span>
                <span *ngIf="!payload.validateOnly">import:</span>
            </h3>
            <display-String-Array-component [data]="warnings"></display-String-Array-component>
        </div>
    </div>
</div>

