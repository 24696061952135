import { of as observableOf, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ReturnAssetsService } from '../returnAssets.service';

interface ScheduleSelectorComponentChanges extends SimpleChanges {
    formRevisionScheduleId: SimpleChange
}

@Component({
    selector: 'schedule-selector',
    templateUrl: './scheduleSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: ScheduleSelectorComponent,
        multi: true
    }]
})
export class ScheduleSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
    constructor(
        private readonly _returnAssetsService: ReturnAssetsService
    ) { }

    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() formRevisionScheduleId: number;
    @Input() formRevisionScheduleName: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() isOverridden: boolean = false;
    @Input() reportingAssetIds: number[];

    @Output() formRevisionScheduleIdChange = new EventEmitter<number>();
    @Output() formRevisionScheduleChange = new EventEmitter<Compliance.ReturnAssetScheduleDetailsAssignedItemModel>();
    
    scheduleFilter: string = '';

    isInitialized: boolean = false;

    schedules$: Observable<Compliance.ReturnAssetScheduleDetailsAssignedItemModel[]> = (Observable
        .create((observer: any) => { observer.next(this.scheduleFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterSchedules(token)));

    private _schedules: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[] = [];
    private _scheduleData: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[] = [];

    async ngOnInit(): Promise<void> {
        this._scheduleData = await this._returnAssetsService.getScheduleDetails();

        // Filter out non-reportable schedules
        this._scheduleData = this._scheduleData.filter(x => x.formRevisionScheduleId !== null);

        this._scheduleData
            .forEach(i => {
                const schedule = this._schedules.find(x => x.formRevisionScheduleId === i.formRevisionScheduleId && x.depreciationTableId === i.depreciationTableId);                    

                if (!schedule) {
                    this._schedules.push(i);
                }
            });

        this.isInitialized = true;
        this._selectSchedule(this.formRevisionScheduleId, this.formRevisionScheduleName);
    }

    ngOnChanges(changes: ScheduleSelectorComponentChanges): void {
        if (changes.formRevisionScheduleId) {
            this._selectSchedule(this.formRevisionScheduleId);
        }
    }

    writeValue(formRevisionScheduleId: number): void {
        this._selectSchedule(formRevisionScheduleId);
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onScheduleBlur(): void {
        if (!this.isRequiredField && this.scheduleFilter === '') {
            this.formRevisionScheduleId = null;
            this.formRevisionScheduleName = null;
            this.formRevisionScheduleChange.emit(null);
        }

        this._selectSchedule(this.formRevisionScheduleId);
        this._onTouchedFn && this._onTouchedFn();
    }

    onScheduleSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const schedule = match.item as Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
            this._selectSchedule(schedule.formRevisionScheduleId);
            this.formRevisionScheduleIdChange.emit(schedule.formRevisionScheduleId);
            this.formRevisionScheduleChange.emit(schedule);
        }
    }

    private _filterSchedules(filter: string): Observable<Compliance.ReturnAssetScheduleDetailsAssignedItemModel[]> {
        if (filter === null || filter === undefined) filter = '';

        return observableOf(
            (this._schedules || []).filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _selectSchedule(formRevisionScheduleId: number, scheduleName?: string): void {
        // keep track of the value that the classification is being set to
        this.formRevisionScheduleId = formRevisionScheduleId;

        // if not initialized then return
        // when initialized the asset class ID that is being tracked (above) will be selected
        if (!this.isInitialized) {
            return;
        }

        const schedule = this._schedules.find(s => s.formRevisionScheduleId === formRevisionScheduleId || s.name === scheduleName);
        if (schedule) {
            this.formRevisionScheduleId = schedule.formRevisionScheduleId;
            this.scheduleFilter = schedule.name;
        } else {
            this.formRevisionScheduleId = null;
            this.scheduleFilter = null;
        }

        this._onChangeFn && this._onChangeFn(this.formRevisionScheduleId);
    }
}
