import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Assessor } from 'src/app/Assessor-Collector/assessor.model';
import { BillingScenarioService } from 'src/app/Assessor-Collector/Collector/Billing-Scenario/billingScenario.service';
import { Address } from 'src/app/Common/Models/common.model';
import { InstanceRights, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { PropertyTypeService } from 'src/app/Common/Services/propertyType.service.upgrade';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { ParcelCollectorBillInfo, ParcelCollectorBillScenarioInfo, ParcelCollectorPaymentOptionInfo } from 'src/app/Core-Repositories/billingScenario.repository';
import { HelpContentComponentConfig } from 'src/app/UI-Lib/Help-Tooltip';
import { MessageBoxButtons, MessageBoxService } from 'src/app/UI-Lib/Message-Box/messagebox.service.upgrade';
import { AssessorDetailsComponent, AssessorDetailsComponentParams } from '../../Assessor-Details/assessorDetails.component';
import { ParcelService } from '../parcel.service.upgrade';
import { ParcelTaxRateService } from '../TaxRateSetup/parcelTaxRateService';
import { AddParcelCollectorModalComponent, AddParcelCollectorModalParams } from './addParcelCollectorModal.component';
import { LegalDescriptionModalParams, LegalDescriptionModalComponent } from './legalDescriptionModal.component';
import { ActivityStatusService } from 'src/app/Common/Activity/activityStatus.service';

interface AvailablePaymentOptionsMap {
    [collectorBillScenarioID: number]: {
        collectorBillInfo: {
            [collectorBillID: number]: ParcelCollectorBillInfo;
        }
    }
}

@Component({
    selector: 'parcel-info-panel-edit',
    templateUrl: 'parcelInfoPanelEdit.component.html',
    styleUrls: ['parcelInfoPanelEdit.component.scss']
})

export class ParcelInfoPanelEditComponent implements OnInit {
    @Input() parcel: Weissman.Model.Domain.Parcel;
    @Input() stateAllowConsolidating: boolean;
    @Input() instanceId: number;
    @Input() assessorDetailsRenderer: HelpContentComponentConfig<AssessorDetailsComponent, AssessorDetailsComponentParams>;
    @Input() assessors: Assessor[];
    @Input() ppReturnPreparationAllowed: boolean;
    @Input() years: number[];
    @Input() taxRateSetup: Weissman.Model.Domain.ParcelTaxRateSetupDTO[];
    @Input() reloadAnnualGrid: boolean;
    @Output() serverActionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    acctNumTypes: Weissman.Model.Domain.AccountNumberType[];
    acctNumToAdd: Weissman.Model.Domain.AccountNumber;
    propertyTypes: Weissman.Model.Assessments.PropertyType[];
    hasMoveCopyPermission: boolean = false;
    parcelAssessor: Assessor;
    assessmentClasses: Weissman.Model.Assessments.AssessmentClass[];
    availablePaymentOptionsMap: AvailablePaymentOptionsMap = {};
    activityStatuses: {
        activityStatusId: number;
        status: string;
    } [];

    constructor(private readonly _parcelService: ParcelService,
            private readonly _restrictService: RestrictService,
            private readonly _activityStatusService: ActivityStatusService,
            private readonly _parcelTaxRateService: ParcelTaxRateService,
            private readonly _billingScenarioService: BillingScenarioService,
            private readonly _messageBoxService: MessageBoxService,
            private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
            private readonly _modalService: WeissmanModalService,
            private readonly _propertyTypeService: PropertyTypeService) { }

    async ngOnInit() {
        this.parcelAssessor = _.find(this.assessors, {assessorID: this.parcel.assessorID});
        this.hasMoveCopyPermission = this._restrictService.hasInstanceRight(InstanceRights.ALLOWMOVECOPY, this.instanceId);

        const promises = [
            this._parcelService.getAcctNumberTypes(),
            this._propertyTypeService.get(),
            this._activityStatusService.get(),
            this._billingScenarioService.getByParcelId(this.parcel.parcelID),
            this._parcelService.getParcelAssessmentClasses(this.parcel.parcelID)
        ];

        const [
            acctNumTypes,
            propertyTypes,
            activityStatuses,
            collectorBillingScenarioList,
            assessmentClasses
        ] = await Promise.all(promises);

        this.acctNumTypes = _.sortBy(acctNumTypes, 'sequence');
        this.propertyTypes = propertyTypes;
        this.activityStatuses = _.sortBy(activityStatuses, 'status');
        this.availablePaymentOptionsMap = this._mapCollectorBillingScenarioList(collectorBillingScenarioList);
        this.assessmentClasses = assessmentClasses;
        // Typescript hack. I'm not sure why we are mapping these, but they have to be the same type of assessment class
        this.parcel.parcelAssessmentClasses = this._mapParcelAssessmentClasses() as unknown as Weissman.Model.Domain.ParcelAssessmentClass[];
    }

    get showTaxingDistrict(): boolean {
        return this.parcel.propertyTypeID === Core.PropertyTypes.Personal && this.parcelAssessor?.stateID === 16; // Indiana
    }

    get nonDeletedParcelCollectors(): Weissman.Model.Domain.ParcelCollector[] {
        return _.reject(this.parcel.parcelCollectors, {efAction: 'delete'});
    }

    get address(): Address {
        return this.parcel.address as unknown as Address;
    }

    shouldDisplayAcctNum(acctNum: Weissman.Model.Domain.AccountNumber): boolean {
        if (acctNum === undefined || !acctNum.accountNumberType) {
            return false;
        }
        const desc = acctNum.accountNumberType.accountNumberDesc;
        return !(desc[0] === '!' && desc[desc.length - 1] === '!');
    }

    deleteAcctNum(acctNum: Weissman.Model.Domain.AccountNumber): void {
        _.remove(this.parcel.altAccountNumbers, acctNum);
    }

    altAcctNumAlreadyAdded(acctNumType: Weissman.Model.Domain.AccountNumberType): boolean {
        return _.some(this.parcel.altAccountNumbers, {
            accountNumberTypeID: acctNumType.accountNumberTypeID
        });
    }

    enterAltAcctNumToAdd(): void {
        const acctNumType = _.chain(this.acctNumTypes)
            .reject(type => {
                return this.altAcctNumAlreadyAdded(type);
            })
            .minBy('sequence')
            .value();

        this.acctNumToAdd = {
            accountNumberTypeID: acctNumType.accountNumberTypeID,
            acctNumber: ''
        } as Weissman.Model.Domain.AccountNumber;
    }


    cancelAddAcctNum(): void {
        this.acctNumToAdd = null;
    }

    addAltAcctNum(): void {
        this.acctNumToAdd.accountNumberType = _.find(this.acctNumTypes, {
            accountNumberTypeID: this.acctNumToAdd.accountNumberTypeID
        });

        this.parcel.altAccountNumbers.push(this.acctNumToAdd);

        this.cancelAddAcctNum();
    }

    onPropertyTypeChange(): void {
        const message = 'The following may be impacted: parcel\'s assessment class(es), assessment components, budget assessment components, tax bill projections, task responsibilities, appeal deadlines, and filing deadlines.';
        this._showWarningIfAnnualData(message);
    }

    onAssessorChanged(): void {
        this.parcelAssessor = _.find(this.assessors, {assessorID: this.parcel.assessorID});
        this.parcel.taxingDistrictId = null;
        this.reloadAnnualGrid = true;

        const message = 'The following may be impacted: parcel’s collectors, assessments, tax bills, tax rate area for parcel collector, compliance filings, tasks assigned to juris spec, and budgets.  Refunds and appeals will appear under the new assessor and/or collector.  New parcel collector(s) will need to be added.';
        this._showWarningIfAnnualData(message);
    }

    async showLegalDescriptionDialog(): Promise<void> {
        const modalParams: LegalDescriptionModalParams = {
            isEditMode: true,
            parcel: this.parcel
        };

        this._modalService.showAsync(LegalDescriptionModalComponent, modalParams, 'ace-modal-dialog modal-lg');
    }

    getCollectorPaymentOptionInfo (collector: Weissman.Model.Domain.ParcelCollector,
        option: Weissman.Model.Domain.ParcelCollectorPaymentOption): ParcelCollectorPaymentOptionInfo[] {
            return this.availablePaymentOptionsMap[collector.collectorBillScenarioID]?.collectorBillInfo[option.collectorPaymentOption.collectorBillID]?.collectorPaymentOptionInfo;
    }

    async deleteParcelCollector(collector: Weissman.Model.Domain.ParcelCollector): Promise<void> {
        try {
            const msg = `Are you sure you want to remove ${collector.collectorAbbr}? Removing a parcel collector will remove all tax bill tasks which are not currently obtained.`;
            await this._messageBoxService.confirm(msg);
        } catch (e) {
            return Promise.resolve();
        }

        _.remove(this.parcel.parcelCollectors, { collectorID: collector.collectorID });

        if (collector.efAction !== 'add') {
            collector.efAction = 'delete';
        }

        this.reloadAnnualGrid = true;
    }

    async addParcelCollector(): Promise<void> {
        const collectorList = await this._parcelTaxRateService.getCollectorsByParcelId(this.parcel.parcelID);
        const addedCollectorIds = _.chain(this.parcel.parcelCollectors)
            .reject({efAction: 'delete'})
            .map('collectorID')
            .value();

        const modalParams: AddParcelCollectorModalParams = {
            collectorList,
            addedCollectorIds,
            hasCompanyCollectors: this.parcel.hasCompanyCollectors,
            parcelId: this.parcel.parcelID
        };

        const modalResult = await this._modalService.showAsync(AddParcelCollectorModalComponent, modalParams, 'modal-md');

        if(!modalResult) {
             return;
        }

        const newCollector = modalResult.selectedCollector;

        this.serverActionChange.emit(true);
        try {
            const allResults = await Promise.all([
                this._billingScenarioService.getById(newCollector.collectorBillScenarioID),
                // TODO: This could be done more efficiently; we're making a separate API call to determine if
                // any available tax authorities exist and using that to determine if we should show a setup
                // link. Instead, we should extend an existing collector API endpoint or make a new one that
                // would return that along with collector data (similar to the parcel info API call).
                this._parcelTaxRateService.getAvailableTaxAuthoritiesByParcel(this.parcel.parcelID, newCollector.collectorID)
            ]);

            const billScenario = allResults[0];
            const bill = _.find(billScenario.collectorBills, {
                collectorBillScenarioID: newCollector.collectorBillScenarioID
            }) as any;

            const recordToAdd = {
                collectorID: newCollector.collectorID,
                collectorAbbr: newCollector.collectorAbbr,
                collectorBillScenarioID: newCollector.collectorBillScenarioID,
                collectorBillScenarioName: newCollector.collectorBillScenarioName,
                parcelID: this.parcel.parcelID,
                // collectorPaymentOptionID: newCollector.collectorPaymentOptionID,
                allTaxYears: modalResult.applyTo === 'all' ? true : false,
                taxYearForward: modalResult.applyTo === 'year' ? modalResult.year : null,
                hasAvailableTaxSetup: allResults[1].length > 0,
                parcelCollectorPaymentOptions: _.map(modalResult.parcelCollectorPaymentOptions, function (parcelCollectorPaymentOption) {
                    return {
                        collectorPaymentOptionID: parcelCollectorPaymentOption.collectorPaymentOptionID,
                        collectorPaymentOption: _.find(bill.collectorPaymentOptions, { collectorPaymentOptionID: parcelCollectorPaymentOption.collectorPaymentOptionID }),
                        efAction: 'add'
                    };
                }),
                efAction: 'add'
            } as unknown as Weissman.Model.Domain.ParcelCollector;

            this.parcel.parcelCollectors.push(recordToAdd);

            this.reloadAnnualGrid = true;
        } finally {
            this.serverActionChange.emit(false);
        }
    }

    paymentOptionChanged(option: Weissman.Model.Domain.ParcelCollectorPaymentOption) {
        option.efAction = 'update';
    }

    getTRS(collector: Weissman.Model.Domain.ParcelCollector): Weissman.Model.Domain.ParcelTaxRateSetupDTO {
        return _.find(this.taxRateSetup, { parcelCollectorId: collector.parcelCollectorID });
    }

    deleteParcel(): void {
        const message = 'Are you sure you want to delete this parcel? If you choose to proceed, all information under this parcel (assessments, appeals, tax bills etc) will also be removed and can not be recovered.';
        const title = `Parcel ${this.parcel.acctNum}`;

        this._messageBoxService.confirm(message, title).then(async () => {
            this.serverActionChange.emit(true);
            try {
                 this._parcelService.deleteParcel(this.parcel.parcelID).then(() => {

                // navigationService.disableNavWarning();
                this._upgradeNavigationServiceHandler.go('site', {
                    companyId: +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId'),
                    siteId: +this._upgradeNavigationServiceHandler.getQuerystringParam('siteId')
                });
            });

            }  finally {
                this.serverActionChange.emit(false);
            }
        });
    }

    goToMoveCopy(isMove: boolean): void {
        const state = isMove ? 'moveParcel' : 'copyParcel';

        this._upgradeNavigationServiceHandler.go(state, {
            companyID: +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId'),
            siteID: +this._upgradeNavigationServiceHandler.getQuerystringParam('siteId'),
            parcelID: +this._upgradeNavigationServiceHandler.getQuerystringParam('parcelId'),
            isInactive: this.parcel.activityStatusID === 0
        });
    }

    linkedParcelsChanged(linkedParcelInfo: Core.LinkedParcelInfoDTO): void {
        this.parcel.excludeFMV = linkedParcelInfo.excludeFmv;
        this.parcel.masterParcelAcctNum = linkedParcelInfo.masterParcelAcctNum;
        this.parcel.masterParcelId = linkedParcelInfo.masterParcelId;
        this.parcel.parcelLinkageID = linkedParcelInfo.parcelLinkageID;
        this.parcel.subParcelCount = linkedParcelInfo.subParcelCount;

        // WK-6098
        if (linkedParcelInfo.newParcelRowVersion) {
            this.parcel.rowVersion = linkedParcelInfo.newParcelRowVersion;
        }
    }

    private _mapParcelAssessmentClasses(): Weissman.Model.Assessments.AssessmentClass[] {
        return _.chain(this.parcel.parcelAssessmentClasses)
            .map(parcelAssessmentClass => {
                const found = _.find(this.assessmentClasses, {
                    assessmentClassID: parcelAssessmentClass.assessmentClass.assessmentClassID
                });

                return found || parcelAssessmentClass.assessmentClass;
            })
            .uniqBy('assessmentClassID')
            .value();
    }

    private _mapCollectorBillingScenarioList(collectorBillingScenarioList: ParcelCollectorBillScenarioInfo[]): AvailablePaymentOptionsMap {
        return _.chain(collectorBillingScenarioList)
            .groupBy('collectorBillScenarioID')
            .mapValues(x => {
                const billScenarioInfo = x[0];
                const collectorBillInfo = _.chain(billScenarioInfo.collectorBillInfo)
                    .groupBy('collectorBillID')
                    .mapValues((x: any) => {
                        x = x[0];
                        x.collectorPaymentOptionInfo = _.reject(x.collectorPaymentOptionInfo, { collectorPaymentOptionName: null });
                        return x;
                    })
                    .value() as any;

                return {collectorBillInfo};
            })
            .value();
    }

    private _showWarningIfAnnualData(message: string): void {
        if (this.years.length) {
            this._messageBoxService.open({
                title: '',
                message: message,
                buttons: MessageBoxButtons.OK
            });
        }
    }

}