import { Testability, NgZone } from '@angular/core';
import { AppealService } from '../Annual-Details/Appeals/appeal.service';
import { AnnualBudgetService } from '../Annual-Details/Budget/budget.details.service';
import { TaxRatesService } from '../Assessor-Collector/Tax-Rates/tax.rates.service';
import { CommentsService } from '../Comments/comments.service';
import { CommentsModalService } from '../Comments/commentsModal.service';
import {
    AddressDetailsModalLaunchService
} from '../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { ColumnFilterService } from '../Common/AV-AS/Column-Filters/columnFilter.service';
import { CoreLongRunningProcessService } from '../Common/Long-Running-Process/core.long.running.process.service';
import { PopUpService } from '../Common/Popup/popup.service';
import { PubSubService } from '../Common/Popup/pubsub.service';
import { CacheManagerService } from '../Common/Routing/cache-manager.service';
import { WebsocketListenerService } from '../Compliance/websocketListener.service';
import { ContactsUpgradeService } from '../Contact/contacts.service.upgrade';
import { AppStateService } from '../Layout/appStateService';
import { NavigationService } from '../Layout/navigation.service';
import { ContactRoleService } from '../Role/contact.role.service';
import { QuickFiltersService } from '../Search/Quick/Criteria/quickFilters.service';
import { SearchService } from '../Search/search.service';
import { SearchCategoriesService } from '../Search/searchCategories.service';
import { SearchFiltersService } from '../Search/searchFilters.service';
import { SearchOperatorsService } from '../Search/searchOperators.service';
import { UtilitiesService } from '../UI-Lib/Utilities';
import { HybridAdapterUtility } from './hybrid-adapter.utility';
import { AnnualDetailsNavigationEventService } from '../Annual-Details/annual-details-event.service';
import { ReportManagerService } from '../Reporting/Manager/report.manager.service';
import { ReportingService } from '../Reporting/reporting.service';
import { TransmittalService } from '../Processing/Transmittal/transmittal.service';
import { LaunchEmailTemplateModalService } from '../Common/Custom-Email-Template/launch.email.template.modal.service';
import { ClientServicesExceptionModalService } from '../Client-Services/Exceptions/client-service-exception-modal.service';
import { ChangeHistoryModalLaunchService } from '../Common/Change-History/change-history-modal-launch.service';
import { CreateInvoiceModalLaunchService } from '../Common/Invoice/create-invoice-modal-launch.service';
import { ClientServiceResponsibilityService } from '../Client-Services/clientServiceResponsibility.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../User/account.service';
import { AuthStorageService } from '../User/authStorage.service';
import { UserInstanceService } from '../User/userInstance.service';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { InstancePermissionService } from '../Permission/Instance/Instance.Permission.Service';
import { InstanceRepository } from '../Entity/Instance/instance.repository';
import { ConsultingEngagementsRepository } from '../Consulting/consultingEngagements.repository';
import { LoadPdfIframeService } from '../Common/PDFViewer/LoadPdfIframeService';
import { AdvancedSearchPersistenceService } from '../Search/Advanced/advancedSearchPersistence.service';
import { StateService } from '../Common/States/States.Service';
import { CurrentEntityService } from '../Common/Entity/CurrentEntityService';
import { UserService } from '../Account/user.service';
import { EntityPermissionService } from '../Common/Permissions/entityPermission.service';
import { RestrictService } from '../Common/Permissions/restrict.service';
import { UserSettingsService } from '../Account/userSettings.service';
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { SiteClassService } from '../Entity/Site/siteClass.service';
import { Constants } from '../constants.new';
import { AceAuthOidcWrapperService } from '../IAM/aceAuthOidcWrapper.service';

export class NG1UpgradeProviders {
    constructor (private hybridAdapterUtility: HybridAdapterUtility) { }

    setupProviders(): void {
        this.hybridAdapterUtility.downgradeNg2Provider('annualDetailsNavigationEventService', AnnualDetailsNavigationEventService);
        this.hybridAdapterUtility.downgradeNg2Provider('ReportManagerService', ReportManagerService);
        this.hybridAdapterUtility.downgradeNg2Provider('reportingService', ReportingService);
        this.hybridAdapterUtility.downgradeNg2Provider('TransmittalService', TransmittalService);
        this.hybridAdapterUtility.downgradeNg2Provider('testability', Testability);
        this.hybridAdapterUtility.downgradeNg2Provider('ngZone', NgZone);
        this.hybridAdapterUtility.downgradeNg2Provider('launchEmailTemplateModalService', LaunchEmailTemplateModalService);
        this.hybridAdapterUtility.downgradeNg2Provider('clientServicesExceptionModalService', ClientServicesExceptionModalService);
        this.hybridAdapterUtility.downgradeNg2Provider('changeHistoryModalLaunchService', ChangeHistoryModalLaunchService);
        this.hybridAdapterUtility.downgradeNg2Provider('createInvoiceModalLaunchService', CreateInvoiceModalLaunchService);
        this.hybridAdapterUtility.downgradeNg2Provider('csrService', ClientServiceResponsibilityService);
        this.hybridAdapterUtility.downgradeNg2Provider('toastr', ToastrService);
        this.hybridAdapterUtility.downgradeNg2Provider('accountService', AccountService);
        this.hybridAdapterUtility.downgradeNg2Provider('authStorageService', AuthStorageService);
        this.hybridAdapterUtility.downgradeNg2Provider('userInstanceService', UserInstanceService);
        this.hybridAdapterUtility.downgradeNg2Provider('featureFlagsService', FeatureFlagsService);
        this.hybridAdapterUtility.downgradeNg2Provider('instancePermissionService', InstancePermissionService);
        this.hybridAdapterUtility.downgradeNg2Provider('instanceRepository', InstanceRepository);
        this.hybridAdapterUtility.downgradeNg2Provider('consultingEngagementsRepository', ConsultingEngagementsRepository);
        this.hybridAdapterUtility.downgradeNg2Provider('loadPdfIframeService', LoadPdfIframeService);
        this.hybridAdapterUtility.downgradeNg2Provider('advancedSearchPersistenceService', AdvancedSearchPersistenceService);
        this.hybridAdapterUtility.downgradeNg2Provider('StatesService', StateService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Current.Entity.Service', CurrentEntityService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Account.User.Service', UserService);
        this.hybridAdapterUtility.downgradeNg2Provider('EntityPermissionService', EntityPermissionService);
        this.hybridAdapterUtility.downgradeNg2Provider('SD.Restrict.Service', RestrictService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Account.User.Settings.Service', UserSettingsService);
        this.hybridAdapterUtility.downgradeNg2Provider('productAnalyticsService', ProductAnalyticsService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Site.Class.Service', SiteClassService);
        this.hybridAdapterUtility.downgradeNg2Provider('websocketListenerService', WebsocketListenerService);
        this.hybridAdapterUtility.downgradeNg2Provider('constants', Constants);
        this.hybridAdapterUtility.downgradeNg2Provider('taxRatesService', TaxRatesService);
        this.hybridAdapterUtility.downgradeNg2Provider('popUpService', PopUpService);
        this.hybridAdapterUtility.downgradeNg2Provider('pubSubService', PubSubService); // RXJS should be used instead of this
        this.hybridAdapterUtility.downgradeNg2Provider('annualBudgetService', AnnualBudgetService);
        this.hybridAdapterUtility.downgradeNg2Provider('appealService', AppealService);
        this.hybridAdapterUtility.downgradeNg2Provider('appStateService', AppStateService);
        this.hybridAdapterUtility.downgradeNg2Provider('cacheManagerService', CacheManagerService);
        this.hybridAdapterUtility.downgradeNg2Provider('addressDetailsModalLaunchService', AddressDetailsModalLaunchService);
        this.hybridAdapterUtility.downgradeNg2Provider('coreLongRunningProcessService', CoreLongRunningProcessService);
        this.hybridAdapterUtility.downgradeNg2Provider('roleService', ContactRoleService);
        this.hybridAdapterUtility.downgradeNg2Provider('columnFilterService', ColumnFilterService);
        this.hybridAdapterUtility.downgradeNg2Provider('featureFlagsService', FeatureFlagsService);
        this.hybridAdapterUtility.downgradeNg2Provider('CommentsService', CommentsService);
        this.hybridAdapterUtility.downgradeNg2Provider('CommentsModalService', CommentsModalService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Contacts.Service', ContactsUpgradeService);
        this.hybridAdapterUtility.downgradeNg2Provider('aceAuthOidcWrapperService', AceAuthOidcWrapperService);
        this.hybridAdapterUtility.downgradeNg2Provider('searchService', SearchService);
        this.hybridAdapterUtility.downgradeNg2Provider('searchCategories', SearchCategoriesService);
        this.hybridAdapterUtility.downgradeNg2Provider('searchFilters', SearchFiltersService);
        this.hybridAdapterUtility.downgradeNg2Provider('searchOperators', SearchOperatorsService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Quick.Filters.Service', QuickFiltersService);
        this.hybridAdapterUtility.downgradeNg2Provider('sd.Navigation.Service', NavigationService);
    }
}
