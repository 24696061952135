import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { OutputColumn, OutputColumnCategory } from "./column.model";
import { TaskSeriesType } from "../../../Task/Task.Model";
import { SmartSearchService } from "../smartSearch.service";
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { BrowseColumnModalComponent, BrowseColumnModalParams } from './Browse-Column-Modal/browseColumnModal.component';

import { map, sortBy } from 'lodash/fp';
import * as _ from 'lodash';

export interface OutputColumnCategoryBrowse extends OutputColumnCategory {
    disabled?: boolean;
    checkedCount?: number;
    totalCheckedCount?: number;
    subcategories: OutputColumnCategoryBrowse[];
}

@Component({
    selector: 'column-picker',
    templateUrl: './columnPicker.component.html'
})
export class ColumnPickerComponent implements OnInit {
    constructor(
        private readonly _smartSearchService: SmartSearchService,
        private readonly _modalService: WeissmanModalService,
    ) { }

    @Input() cpMode: 'av' | 'smart';
    @Input() disableColumnsAfterLoad: boolean;
    @Input() existingColumns: OutputColumn[];
    @Input() otherColumnsThatMightDisable: OutputColumn[];
    @Input() selectedTaskType: TaskSeriesType;
    @Input() allowDuplicates: boolean;

    @Output() columnsSelected: EventEmitter<OutputColumn[]> = new EventEmitter<OutputColumn[]>();

    private _columns: OutputColumn[] = [];

    typeaheadColumnToAdd: string;
    columnsLoaded: boolean = false;
    columnCategories: OutputColumnCategoryBrowse[];
    isTaskTypeSpecialCase: boolean = false;
    taskTypeDepthValue: number;

    async ngOnInit(): Promise<void> {
        this.existingColumns = this.existingColumns || [];
        this.otherColumnsThatMightDisable = this.otherColumnsThatMightDisable || [];

        if(this.selectedTaskType) {
            this.isTaskTypeSpecialCase = !!this.selectedTaskType.depthValuesToEnable || !!this.selectedTaskType.fieldIdsToEnable;
            this.taskTypeDepthValue = this.selectedTaskType.taskSeriesTypeAdvancedSearchDepthValue;
        }

        this._columns = (await this._smartSearchService.getColumns(this.cpMode == 'av', this.cpMode)).all;
        this.columnsLoaded = true;

        if (this.disableColumnsAfterLoad) {
            if(this.isTaskTypeSpecialCase) {
                this._smartSearchService.disableColumnsFromTaskTypeOnly(this.selectedTaskType)
            } else {
                this._smartSearchService.disableColumns([...this.existingColumns, ...this.otherColumnsThatMightDisable], false, this.taskTypeDepthValue)
            }
        }
    }

    columnSelected(e: TypeaheadMatch): void {
        this.columnsSelected.emit([e.item]);
        this.typeaheadColumnToAdd = '';
    }

    async openBrowseModal(): Promise<void> {
        const columnsToExclude = this.allowDuplicates ? [] : _.cloneDeep(this.existingColumns)
        const columnCategories = this._smartSearchService.getFilteredColumnCategories(columnsToExclude);

        const params: BrowseColumnModalParams = {
            existingColumns: this.existingColumns,
            otherColumnsThatMightDisable: this.otherColumnsThatMightDisable,
            selectedTaskType: this.selectedTaskType,
            columnCategories: this._processCategories(columnCategories),
            isTaskTypeSpecialCase: this.isTaskTypeSpecialCase,
            taskTypeDepthValue: this.taskTypeDepthValue
        }

        if(!this.isTaskTypeSpecialCase) {
            this._smartSearchService.disableColumns([...this.existingColumns, ...this.otherColumnsThatMightDisable], true, this.taskTypeDepthValue)
        }

        const result = await this._modalService.showAsync(BrowseColumnModalComponent, params, 'modal-lg');

        if (!result) {
            return;
        }

        this.columnsSelected.emit(result);
    }

    filterColumns(): OutputColumn[] {
        return _.reject(this._columns, column => {
            const columnId = column.columnId || column.advancedSearchFieldID || column.id;
            const columnIdFound = _.some(this.existingColumns, { columnId: columnId });
            const idFound = _.some(this.existingColumns, { id: columnId });
            const advancedSearchFieldIdFound = _.some(this.existingColumns, { advancedSearchFieldID: columnId });

            return column.disabled || (!this.allowDuplicates && (columnIdFound || idFound || advancedSearchFieldIdFound));
        });
    }

    private _processCategories(categories: OutputColumnCategoryBrowse[]): OutputColumnCategoryBrowse[] {
        return _.flow([
                map(category => {
                    return {
                        ...category,
                        columns: _.sortBy(category.columns, 'displayName'),
                        subcategories: this._processCategories(category.subcategories),
                        checkedCount: 0,
                        disabled: category.columns.every(x => x.disabled)
                    }
                }),
                sortBy('sequence')
            ])(categories);
    }
}

