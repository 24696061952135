import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AceModule } from './ACE/ace.module';
import { CarouselComponent } from './Carousel/carousel.component';
import { CurrencyModule } from './Currency/currency.module';
import { DirectivesModule } from './Directives/directives.module';
import { SharedPipesModule } from './Pipes/shared-pipes.module';
import { HelpModule } from './Help-Tooltip/help.module';
import { LoadingSpinnerModule } from './Loading-Spinner/loadingSpinner.module';
import { SelectModule } from './Select/select.module';
import { ModalWrapperModule } from './Modal-Wrapper/modalWrapper.module';
import { TriStateCheckboxModule } from './Tri-State-Checkbox/triStateCheckbox.module';
import { WsTypeaheadModule } from './Typeahead/typeahead.module';
import { BreadCrumbsComponent } from './Bread-Crumb/breadCrumbs.component';
import { BulkUpdateActionComponent } from './Bulk-Update/Update-Action/bulkUpdateAction.component';
import { ParentheticalCurrencyComponent } from './Currency/parenthetical-currency.component';
import { FilePickerComponent } from './FilePicker/filePicker.component';
import { MultiSelectComponent } from './Multi-Select/multiSelect.component';
import { PicklistComponent } from './Picklist/picklist.component';
import { SwitchComponent } from './Switch/switch.component';
import { WeissmanDatetime } from './Weiss-Datepicker/weissman-datetime.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { WeissmanPanelComponent } from './Weissman-Panel/weissmanPanel.component';
import { PanelChevronComponent } from './Weissman-Panel/panel.chevron.component';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        NgxFileDropModule,
        CurrencyModule,
        DirectivesModule,
        SharedPipesModule,
        HelpModule,
        LoadingSpinnerModule,
        ModalWrapperModule,
        SelectModule,
        TriStateCheckboxModule,
        WsTypeaheadModule,
        AceModule
    ],
    declarations: [
        BreadCrumbsComponent,
        BulkUpdateActionComponent,
        ParentheticalCurrencyComponent,
        FilePickerComponent,
        MultiSelectComponent,
        PicklistComponent,
        SwitchComponent,
        WeissmanDatetime,
        WeissmanPanelComponent,
        PanelChevronComponent,
        CarouselComponent
    ],
    exports: [
        // Modules
        CurrencyModule,
        DirectivesModule,
        SharedPipesModule,
        HelpModule,
        LoadingSpinnerModule,
        ModalWrapperModule,
        SelectModule,
        TriStateCheckboxModule,
        WsTypeaheadModule,
        AceModule,
        // Components
        BreadCrumbsComponent,
        BulkUpdateActionComponent,
        ParentheticalCurrencyComponent,
        FilePickerComponent,
        MultiSelectComponent,
        PicklistComponent,
        SwitchComponent,
        WeissmanDatetime,
        WeissmanPanelComponent,
        PanelChevronComponent,
        CarouselComponent
    ]
})
export class UILibModule {
    static setupModule(): void {
        SelectModule.setupModule();
        HelpModule.setupModule();
        hybridAdapterUtility.downgradeNg2Component('weissmanDatetime', WeissmanDatetime);
        hybridAdapterUtility.downgradeNg2Component('weissmanPanel', WeissmanPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('wsBreadCrumbs', BreadCrumbsComponent);
    }
}
