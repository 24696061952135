import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import {
    BodyScrollEvent,
    ColDef,
    Column,
    ColumnApi,
    ColumnResizedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    IDatasource
} from 'ag-grid-community';
import { AgGridOverlayColumn, AgGridOverlayService } from './Drag-And-Drop-Overlay/agGridOverlay.service';
import { Subscription } from 'rxjs';
import { AgGridPinnedRowCellRenderer } from './agGridPinnedRowCellRenderer';
import { EntityImportEditorAgGridDataSource } from './agGridDataSource';
import * as _ from 'lodash';
import { EntityImportRepository } from '../../../Repositories';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import { AgGridOverlayDropEvent } from './Drag-And-Drop-Overlay/agGridOverlay.component';
import { AgGridMappingHeaderRowCellRendererComponent } from './agGridHeaderRowCellRenderer.component';
import { EntityImportColumnMappingStepService } from './columnMappingStep.service';
import {
    EntityImportStaticFieldsComponent,
    EntityImportStaticFieldsParams,
    EntityImportStaticMappingInfo
} from '../../Static-Fields/staticFields.component';
import { ToastrService } from 'ngx-toastr';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../AgGrid';
import { WeissmanModalService } from '../../../WeissmanModalService';
import {
    EntityImportSpecificationMappingComponent,
    ImportSpecificationMappingParams
} from './Import-Specification-Mapping/importSpecificationMapping.component';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { COLUMN_MAPPING_STEP_HELP } from './columnMappingStep.component.help';
import { ImportMappingModalComponent } from './Import-Mapping-Modal/importMappingModal.component';
import { DynamicFieldBehaviorService } from '../Dynamic-Field-Behavior/dynamicFieldBehavior.service';

@Component({
    selector: 'entity-import-column-mapping-step',
    templateUrl: './columnMappingStep.component.html',
    styleUrls: ['./columnMappingStep.component.scss']
})
export class EntityImportColumnMappingStepComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _dragulaService: DragulaService,
        private readonly _agGridOverlayService: AgGridOverlayService,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _columnMappingStepService: EntityImportColumnMappingStepService,
        private readonly _toastsManager: ToastrService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _dynamicFieldBehaviorService: DynamicFieldBehaviorService
    ) { }

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;
    private _gridDataSource: IDatasource;
    private _modelHiddenSub: Subscription;
    private _dragSub: Subscription;
    private _dragEndSub: Subscription;
    private _fieldAssignedSub: Subscription;
    private _fieldUnassignedSub: Subscription;

    private async _loadImportFileInformation(): Promise<void> {
        this.canGridInitialize = false;

        // load necessary information to figure out the state of the field/column mapping
        const busyRef = this._busyIndicatorService.show({ message: 'Loading Data Import' });

        try {
            await this._columnMappingStepService.initialize(this.importId);
        } finally {
            await busyRef.hide();
        }

        // now that we have loaded the necessary information determine which fields have been mapped and which ones have not
        for (let i = 0, l = this._columnMappingStepService.getFields().length; i < l; i++) {
            const field = this._columnMappingStepService.getFields()[i];

            // if this field is mapped, skip it
            const fieldMappingIndex = this._columnMappingStepService.getFieldMappings().findIndex((mappedField) => mappedField.importFieldId === field.importFieldId);
            if (fieldMappingIndex !== -1) continue;

            // if the field is not mapped, figure out if it is a required field or not and put it in the right collection
            if (field.isMappingRequired) {
                this.availableImportFieldsRequired.push(field);
            } else {
                this.availableImportFieldsOptional.push(field);
            }
        }

        this.showDynamicFieldConfiguration = this._dynamicFieldBehaviorService.showDynamicFieldConfiguration;
        this.canGridInitialize = true;
    }

    private _getGridColumnsAssociatedWithImportFileColumns(): Column[] {
        return this._gridColumnApi
            .getAllColumns()
            .filter((i) => {
                const metadata: Compliance.EntityImportMappingColDefMetadata = i.getColDef()['ws'];
                return metadata && !metadata.isStatic;
            });
    }

    private _getGridColumnsAssociatedWithMappedImportFileColumns(): Column[] {
        return this._getGridColumnsAssociatedWithImportFileColumns()
            .filter((i) => {
                // check if the column is currently mapped
                const headerId = (i.getColDef()['ws'] as Compliance.EntityImportMappingColDefMetadata).fileHeaderId;
                return this._columnMappingStepService.getFieldMappings().findIndex(i => !i.isStatic && i.value === headerId.toString()) !== -1;
            });
    }

    private _getGridColumnsAssociatedWithUnmappedImportFileColumns(): Column[] {
        return this._getGridColumnsAssociatedWithImportFileColumns()
            .filter((i) => {
                // check if the column is currently unmapped
                const headerId: number = (i.getColDef()['ws'] as Compliance.EntityImportMappingColDefMetadata).fileHeaderId;
                return this._columnMappingStepService.getFieldMappings().findIndex(i => !i.isStatic && i.value === headerId.toString()) === -1;
            });
    }

    private _getGridColumnsAssociatedWithStaticImportFileColumns(): Column[] {
        return this._gridColumnApi
            .getAllColumns()
            .filter((i) => {
                const metadata: Compliance.EntityImportMappingColDefMetadata = i.getColDef()['ws'];
                return metadata && metadata.isStatic;
            });
    }

    private _getDragAndDropOverlaysFromGridColumns(): AgGridOverlayColumn[] {
        return this._getGridColumnsAssociatedWithUnmappedImportFileColumns()
            .map((i) => {
                return {
                    columnId: (i.getColDef()['ws'] as Compliance.EntityImportMappingColDefMetadata).fileHeaderId,
                    left: i.getLeft(),
                    width: i.getActualWidth()
                };
            });
    }

    private _applyGridColumnFilter(): void {
        const mappedColumns = this._getGridColumnsAssociatedWithMappedImportFileColumns();
        const unmappedColumns = this._getGridColumnsAssociatedWithUnmappedImportFileColumns();
        const staticColumns = this._getGridColumnsAssociatedWithStaticImportFileColumns();

        const allColumns = ([] as Column[]).concat(mappedColumns).concat(unmappedColumns).concat(staticColumns);

        switch (this.selectedGridColumnFilter) {
            case this.gridColumnFilters.allColumns:
                allColumns.forEach(i => {
                    this._showGridColumn(i);
                    if (mappedColumns.indexOf(i) !== -1) {
                        this._removeGridOverlay(i);
                    }
                });
                break;

            case this.gridColumnFilters.mappedColumns:
                allColumns.forEach(i => {
                    if (mappedColumns.indexOf(i) === -1) {
                        this._hideGridColumn(i);
                    } else this._showGridColumn(i);
                });
                break;

            case this.gridColumnFilters.unmappedColumns:
                allColumns.forEach(i => {
                    if (unmappedColumns.indexOf(i) === -1) {
                        this._hideGridColumn(i);
                    } else this._showGridColumn(i);
                });
                break;

            case this.gridColumnFilters.staticColumns:
                allColumns.forEach(i => {
                    if (staticColumns.indexOf(i) === -1) {
                        this._hideGridColumn(i);
                    } else this._showGridColumn(i);
                });
                break;

            default:
                console.warn('Cannot filter grid columns. An unknown filter is specified.');
                break;
        }

        // notify overlay of new column sizes
        setTimeout(() => {
            this._agGridOverlayService.notifyColumnResize({ columns: this._getDragAndDropOverlaysFromGridColumns() });
        }, 500);
    }

    private _showGridColumn(col: Column): void {
        const colDef = col.getColDef();
        const colDefMetadata: Compliance.EntityImportMappingColDefMetadata = colDef['ws'];
        this._gridColumnApi.setColumnVisible(col, true);
        if (colDefMetadata.fileHeaderId) {
            if (this._columnMappingStepService.canAssignField(colDefMetadata.fileHeaderId)) {
                this._agGridOverlayService.notifyColumnAdd({
                    columnId: colDefMetadata.fileHeaderId,
                    left: col.getLeft(),
                    width: col.getActualWidth()
                });
            }
        }
    }

    private _hideGridColumn(col: Column): void {
        const colDef = col.getColDef();
        const colDefMetadata: Compliance.EntityImportMappingColDefMetadata = colDef['ws'];
        this._gridColumnApi.setColumnVisible(col, false);
        this._agGridOverlayService.notifyColumnRemove(colDefMetadata.fileHeaderId);
    }

    private _removeGridOverlay(col: Column): void {
        const colDef = col.getColDef();
        const colDefMetadata: Compliance.EntityImportMappingColDefMetadata = colDef['ws'];
        this._agGridOverlayService.notifyColumnRemove(colDefMetadata.fileHeaderId);
    }

    private async _showStaticFieldsModal(availableImportFields: Compliance.ImportFieldModel[]): Promise<EntityImportStaticMappingInfo> {
        const params: EntityImportStaticFieldsParams = {
            availableImportFields: availableImportFields
        };

        return await this._modalService.showAsync(EntityImportStaticFieldsComponent, params, 'modal-md');
    }

    private _addStaticColumnToGrid(fieldMapping: Compliance.ImportFileSpecificationImportFieldModel): void {
        const field = this._columnMappingStepService.getFields().find(i => i.importFieldId === fieldMapping.importFieldId);
        const colDef = this._prepareStaticColumnDefinition(field, fieldMapping);
        const colDefList = this._gridColumnApi.getAllColumns().map(x => x.getColDef());
        this._gridApi.setColumnDefs(colDefList.concat(colDef));
    }

    private _removeStaticColumnFromGrid(col: Column): void {
        const colDef = col.getColDef();
        const colDefList = this._gridColumnApi
            .getAllColumns()
            .filter(i => i.getColDef() !== colDef) // remove grid column
            .map(i => i.getColDef());
        this._gridApi.setColumnDefs(colDefList);
    }

    private _prepareStaticColumnDefinition(field: Compliance.ImportFieldModel, fieldMapping: Compliance.ImportFileSpecificationImportFieldModel): ColDef {
        return {
            headerName: field.displayName,
            editable: false,
            suppressMovable: true,
            pinnedRowCellRenderer: AgGridPinnedRowCellRenderer,
            headerComponentFramework: <any>AgGridMappingHeaderRowCellRendererComponent,
            valueGetter: () => fieldMapping.value,
            cellClass: 'static-column-cell',
            // custom metadata
            ws: {
                enableMappingMaintenance: true,
                isStatic: true,
                staticFieldId: fieldMapping.importFieldId,
                staticFieldMappingId: fieldMapping.importFileSpecificationFieldId
            } as Compliance.EntityImportMappingColDefMetadata
        } as ColDef;
    }

    private async _dropMappingCharacteristicModal(mappedField: Compliance.ImportFieldModel): Promise<Compliance.NameValuePair<string>[]> {
        const result = await this._modalService.showAsync(ImportMappingModalComponent, mappedField, 'modal-md');

        if (!result) {
            const field = this._columnMappingStepService.getFields().find(i => i.importFieldId === mappedField.importFieldId);
            if (field.isMappingRequired) {
                this.availableImportFieldsRequired.push(field);
            } else {
                this.availableImportFieldsOptional.push(field);
            }
            return;
        }

        return result;
    }

    @Input() importId: number;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        floatingFilter: false,
        enableFilter: false,
        enableServerSideFilter: false,
    })
    .withColumnResize()
    .withInfiniteScroll()
    .withLoadingOverlay()
    .withTextSelection()
    .build();

    gridColumnFilters = {
        allColumns: 'All Columns',
        mappedColumns: 'Mapped Columns',
        unmappedColumns: 'Columns Not Mapped',
        staticColumns: 'Static Columns'
    };

    selectedGridColumnFilter = this.gridColumnFilters.allColumns;

    // we cannot correctly initialize the grid until all the necessary import file information has loaded
    canGridInitialize: boolean = false;

    // a (dragula) bag name identifier for all the containers involved in the available field drag-and-drop
    readonly availableImportFieldsDragAndDropBagName: string = 'available-import-fields-bag';

    // an attribute put on all source containers to identify them
    // used to block the user from drag and dropping between source containers
    readonly availableImportFieldsDragAndDropSourceContainerIdentifier: string = 'available-import-fields-source-container';

    // a variable to control the visibility of the drag-and-drop containers that overlay the grid
    availableImportFieldsOverlayVisible: boolean = false;
    availableImportFieldsRequired: Compliance.ImportFieldModel[] = [];
    availableImportFieldsOptional: Compliance.ImportFieldModel[] = [];
    showDynamicFieldConfiguration: boolean = false;


    async ngOnInit(): Promise<void> {
        this._helpService.setContent(COLUMN_MAPPING_STEP_HELP);
        // make sure available fields cannot be dragged within the same container
        const bag = this._dragulaService.find(this.availableImportFieldsDragAndDropBagName);
        if (!bag) {
            this._dragulaService.createGroup(this.availableImportFieldsDragAndDropBagName, {
                revertOnSpill: true,
                removeOnSpill: false,
                accepts: (el: Element, target: Element) => {
                    return target && target.getAttribute('container-id') !== this.availableImportFieldsDragAndDropSourceContainerIdentifier;
                }
            });
        }

        this._dragSub = this._dragulaService.drag(this.availableImportFieldsDragAndDropBagName).subscribe(value => {
            if (value.name !== this.availableImportFieldsDragAndDropBagName) return;
            this.availableImportFieldsOverlayVisible = true;
        });

        this._dragEndSub = this._dragulaService.dragend(this.availableImportFieldsDragAndDropBagName).subscribe(value => {
            if (value.name !== this.availableImportFieldsDragAndDropBagName) return;
            this.availableImportFieldsOverlayVisible = false;
        });

        this._fieldAssignedSub = this._columnMappingStepService.fieldAssigned$.subscribe(fieldMapping => {
            // remove field from the available fields collection
            const field = this._columnMappingStepService.getFields().find(i => i.importFieldId === fieldMapping.importFieldId);
            if (field.isMappingRequired) {
                const index = this.availableImportFieldsRequired.indexOf(field);
                if (index !== -1) {
                    this.availableImportFieldsRequired.splice(index, 1);
                }
            } else {
                const index = this.availableImportFieldsOptional.indexOf(field);
                if (index !== -1) {
                    this.availableImportFieldsOptional.splice(index, 1);
                }
            }

            // apply grid column filters to grid columns and sync up grid overlays
            this._applyGridColumnFilter();
        });

        this._fieldUnassignedSub = this._columnMappingStepService.fieldUnassigned$.subscribe(fieldMapping => {
            // if the field mapping was for a static field, remove that field from the grid columns
            if (fieldMapping.isStatic) {
                const staticColumn = this._getGridColumnsAssociatedWithStaticImportFileColumns().filter(i => {
                    return (i.getColDef()['ws'] as Compliance.EntityImportMappingColDefMetadata).staticFieldId === fieldMapping.importFieldId;
                })[0];
                this._removeStaticColumnFromGrid(staticColumn);
            }

            // find the field associated with that mapping and add it to the right collection
            const field = this._columnMappingStepService.getFields().find(i => i.importFieldId === fieldMapping.importFieldId);
            if (field) {
                if (field.isMappingRequired) {
                    this.availableImportFieldsRequired.push(field);
                } else {
                    this.availableImportFieldsOptional.push(field);
                }
            }

            // apply grid column filters to grid columns and sync up grid overlays
            this._applyGridColumnFilter();
        });

        await this._loadImportFileInformation();
    }

    ngOnDestroy(): void {
        this._dragSub.unsubscribe();
        this._dragEndSub.unsubscribe();
        this._fieldAssignedSub.unsubscribe();
        this._fieldUnassignedSub.unsubscribe();
        this._modelHiddenSub && this._modelHiddenSub.unsubscribe();
    }

    /**
     * Called after the import model has loaded.
     * @param event Event parameters.
     */
    onAgGridReady(event: GridReadyEvent): void {
        // get API objects and start setting up the AgGrid
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;

        // setup AgGrid column for row #
        const rowIdColumnDef: ColDef = {
            headerName: 'Row',
            field: 'rowIndex',
            width: AgGridColumns.rowNumberColumnWidth,
            type: 'numericColumn',
            editable: false,
            suppressMovable: true,
            suppressSizeToFit: true,
            resizable: false,
            pinnedRowCellRenderer: AgGridPinnedRowCellRenderer,
            // custom metadata
            ws: null
        } as ColDef;

        // setup AgGrid column for import file columns
        //   fields for each row are matched to the right columns via their index in the file; i.e.
        //   columns: [ { headerName: 'FIRSTNAME', field: 1 }, { headerName: 'LASTNAME', field: 3 }]
        //   row 1: { 1: 'Adam', 3: 'Smith' }
        //   row 2: { 1: 'John', 3: 'Doe' }
        const columnDefList: ColDef[] = _
            .chain(this._columnMappingStepService.getFileHeaders())
            .sortBy(['index'])
            .map((i) => {
                return {
                    headerName: null,
                    field: i.index.toString(),
                    editable: false,
                    suppressMovable: true,
                    pinnedRowCellRenderer: AgGridPinnedRowCellRenderer,
                    headerComponentFramework: <any>AgGridMappingHeaderRowCellRendererComponent,
                    // custom metadata
                    ws: {
                        enableMappingMaintenance: true,
                        isStatic: false,
                        fileHeaderId: i.importFileHeaderId
                    } as Compliance.EntityImportMappingColDefMetadata,
                } as ColDef;
            })
            .value();

        // setup AgGrid column for static columns
        const staticColumnDefList: ColDef[] = _
            .chain(this._columnMappingStepService.getFieldMappings())
            .filter(i => i.isStatic)
            .map(i => {
                const field = this._columnMappingStepService.getFields().find(f => f.importFieldId === i.importFieldId);
                return this._prepareStaticColumnDefinition(field, i);
            })
            .value();

        // pin file headers as top row
        const headersRow: any = {};
        this._columnMappingStepService.getFileHeaders().forEach((i) => { headersRow[i.index] = i.name; });
        this._gridApi.setPinnedTopRowData([headersRow]);

        // set AgGrid columns
        this._gridApi.setColumnDefs(([] as ColDef[]).concat(rowIdColumnDef).concat(columnDefList).concat(staticColumnDefList));

        // setup AgGrid data source for infinite scrolling
        this._gridDataSource = new EntityImportEditorAgGridDataSource(this._gridApi, this._entityImportRepository, this.importId);
        this._gridApi.setDatasource(this._gridDataSource);

        // make the overlay component aware of the grid columns
        const columns = this._getDragAndDropOverlaysFromGridColumns();
        columns.forEach(i => this._agGridOverlayService.notifyColumnAdd(i));

        //make the overlay component aware of the grid column sizes
        this._agGridOverlayService.notifyColumnResize({ columns: this._getDragAndDropOverlaysFromGridColumns() });
    }

    /**
     * Called when the AgGrid columns are being resized.
     * @param event Event parameters.
     */
    onAgGridColumnResized(event: ColumnResizedEvent): void {
        if (event.finished) {
            this._agGridOverlayService.notifyColumnResize({ columns: this._getDragAndDropOverlaysFromGridColumns() });
        }
    }

    /**
     * Called when the AgGrid body is being scrolled.
     * @param event Event parameters.
     */
    onAgGridBodyScroll(event: BodyScrollEvent): void {
        if (event.direction === 'horizontal') {
            this._agGridOverlayService.notifyGridHorizontalScroll(event.left);
        }
    }

    /**
     * Called when the user drops a field on top of one of the AgGrid columns.
     * @param params Event parameters.
     */
    async onAvailableImportFieldMapped(params: AgGridOverlayDropEvent): Promise<void> {
        if (!this._columnMappingStepService.canAssignField(params.targetId)) {
            return;
        }

        const allFields = this._columnMappingStepService.getFields();
        const field = allFields.find(f => f.importFieldId === params.sourceId);
        let clarificationValues: Compliance.NameValuePair<string>[];

        if (field && field.clarificationFields && field.clarificationFields.length !== 0){
            clarificationValues = await this._dropMappingCharacteristicModal(field);
            if (!clarificationValues) {
                return;
            }
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Mapping Field'});
        try {
            await this._columnMappingStepService.assignFieldToColumn(this.importId, params.sourceId, params.targetId, clarificationValues);
            // apply grid column filters to grid columns and sync up grid overlays
            this._applyGridColumnFilter();
        } finally {
            await busyRef.hide();
        }
    }

    async addStaticColumn(): Promise<void> {
        const availableFields = ([] as Compliance.ImportFieldModel[]).concat(this.availableImportFieldsRequired).concat(this.availableImportFieldsOptional);

        let staticFieldMapping: EntityImportStaticMappingInfo;

        try {
            staticFieldMapping = await this._showStaticFieldsModal(availableFields);
        } catch (e) {
            return Promise.resolve();
        }

        if (staticFieldMapping) {
            const busyRef = this._busyIndicatorService.show({message: 'Adding Static Field'});

            try {
                const response = await this._columnMappingStepService.assignStaticField(
                    this.importId,
                    staticFieldMapping.staticColumn.importFieldId, staticFieldMapping.staticColumn.value,
                    staticFieldMapping.clarificationFieldValues);
                this._addStaticColumnToGrid(response);
                return Promise.resolve();
            } finally {
                await busyRef.hide();
            }
        }
    }

    /**
     * Sets the selected grid filter and sets the visibility of the grid columns based on that filter.
     * @param filter Selected grid filter to apply.
     */
    filterGridColumns(filter: string): void {
        this.selectedGridColumnFilter = filter;
        this._applyGridColumnFilter();
    }

    async saveImportSpecification(): Promise<void> {
        const importSpecInfo = this._columnMappingStepService.getFileSpecificationInfo();

        const params: ImportSpecificationMappingParams = {
            importFileId: this.importId,
            companyId: importSpecInfo.companyId,
            importFileSpecificationId: importSpecInfo.importFileSpecificationId,
            importFileSpecificationName: importSpecInfo.displayName,
            allowNoSave: false
        };

        const result = await this._modalService.showAsync(EntityImportSpecificationMappingComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        if (result) {
            this._toastsManager.success('Specification saved');
        }

        return Promise.resolve();
    }
}
