(function () {
	'use strict';

	angular.module('weissmanApp')
		.config(['$stateProvider', function ($stateProvider) {
			$stateProvider
				.state('styles', {
					url: '/styles',
					template: '<h1>Style Playground</h1> <ul><li><a ui-sref="modals">Modals</a></li><li><a ui-sref="buttons">Buttons</a></li><li><a ui-sref="typography">Typography</a></li><li><a ui-sref="panels">Panels</a></li><li><a ui-sref="popups">Popups</a></li></ul>'
				})
				.state('modals', {
					url: '/styles/modals',
					templateUrl: 'app/Style-playground/modals/modals.html',
					controller: 'StyleModalsController',
					controllerAs: 'vm'
				})
				.state('typography', {
					url: '/styles/typography',
					templateUrl: 'app/Style-playground/typography/typography.html',
					controller: 'StyleTypographyController',
					controllerAs: 'vm'
				})
				.state('buttons', {
					url: '/styles/buttons',
					templateUrl: 'app/Style-playground/buttons/buttons.html',
					controller: 'StyleButtonsController',
					controllerAs: 'vm'
				})
				.state('panels', {
					url: '/styles/panels',
					templateUrl: 'app/Style-playground/panels/panels.html',
					controller: 'StylePanelsController',
					controllerAs: 'vm'
				})
				.state('colors', {
					url: '/styles/colors',
					templateUrl: 'app/Style-playground/colors/colors.html',
					controller: 'ColorsController',
					controllerAs: 'vm'
				})
				.state('grid', {
					url: '/styles/grid',
					templateUrl: 'app/Style-playground/ag-grid/_grid.html',
					controller: 'GridDemoController',
					controllerAs: 'vm'
				})
		        .state('popups', {
					url: '/styles/popups',
					templateUrl: 'app/Style-playground/popups/Popup.Parent.html',
					controller: 'PopupParentController',
					controllerAs: 'vm'
				})
                .state('popupChild', {
                    url: '/styles/popupchild',
                    templateUrl: 'app/Style-playground/popups/Popup.Child.html',
                    controller: 'PopupChildController',
                    controllerAs: 'vm'
                })
                .state('panelcolors', {
                    url: '/styles/panelcolors',
                    templateUrl: 'app/Style-playground/panels/panel-colors.html',
                    controller: 'StylePanelsController',
                    controllerAs: 'vm'
                });
		}]);
})();
