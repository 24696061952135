(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdColumnPicker', sdColumnPicker);

	function sdColumnPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			restrict: 'E',
			templateUrl: 'app/Search/Advanced/Column-Picker/_columnPicker.html',
			scope: {
				columnSelectedFn: '&',
				columnsToExclude: '=',
				includeTaskColumns: '=',
				disableColumnsAfterLoad: '=',
				taskSeriesTypeAdvancedSearchDepthValue: '=',
				cpMode: '@'
			}
		};

		return directive;
	}

	Controller.$inject = ['advancedSearchService', '$timeout', '$uibModal'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(advancedSearchService, $timeout, $uibModal) {
		var vm = this;

		vm.columnToAdd = '';
		vm.columns = null;

		vm.columnSelected = columnSelected;
		vm.filterFromExclude = filterFromExclude;
		vm.openBrowseModal = openBrowseModal;

		activate();

		function activate() {

			//console.log('mode: ', vm.cpMode);

			advancedSearchService.getColumns(vm.includeTaskColumns, vm.cpMode)
				.then(function (result) {
					vm.columns = result;

					if (vm.disableColumnsAfterLoad) {
						advancedSearchService.disableColumns(vm.columnsToExclude, false, vm.taskSeriesTypeAdvancedSearchDepthValue)
					}
				});
		}

		function columnSelected($item) {
			$timeout(function () {
				vm.columnSelectedFn({
					selectedColumns: [$item]
				});

				vm.columnToAdd = '';
			});
		}

		function filterFromExclude(allColumns) {
			return _.chain(allColumns)
				.reject('disabled')
				.reject(rejectExcluded)
				.value();
		}

		function rejectExcluded(column) {
			var columnId = column.columnId || column.advancedSearchFieldID || column.id;
			var columnIdFound = _.some(vm.columnsToExclude, { columnId: columnId });
			var idFound = _.some(vm.columnsToExclude, { id: columnId });
			var advancedSearchFieldIdFound = _.some(vm.columnsToExclude, { advancedSearchFieldID: columnId });

			return columnIdFound || idFound || advancedSearchFieldIdFound;
		}

		function openBrowseModal() {
			var modalInstance = $uibModal.open({
				templateUrl: 'app/Search/Advanced/Column-Picker/_browseColumnsModal.html',
				controller: 'BrowseColumnsModalController',
				controllerAs: 'vm',
				size: 'lg',
				windowClass: 'as-add-columns-modal show',
				backdropClass: 'show',
				backdrop: 'static',
				resolve: {
					columnsToExclude: function () {
						return vm.columnsToExclude;
					},

					taskSeriesTypeAdvancedSearchDepthValue: function () {
						return vm.taskSeriesTypeAdvancedSearchDepthValue;
					}
				}
			});

			modalInstance.result
				.then(function (columnsToAdd) {
					$timeout(function () {
						vm.columnSelectedFn({
							selectedColumns: columnsToAdd
						});
					});
				});
		}
	}
})();
