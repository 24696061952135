<ws-busy-indicator message="Loading" *ngIf="isBusy"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Select Factor Tables"
                  (save)="submit()"
                  (cancel)="cancel()"
                  [disableSave]="!form.valid">
    <div class="ws-flex-container-vertical ws-flex-auto">

            <div class="ws-flex-container-vertical ws-flex-none">
                <label>
                    Select the factor tables you want to be used for this schedule.
                </label>
                <label for="showAllAvailableFactors" class="ws-font-weight-normal">
                    <input type="checkbox" id="showAllAvailableFactors" (change)="switchFactorList($event)" [formControl]="showAllAvailableFactors">
                    Show all available factor tables for this schedule
                </label>
            </div>

            <div class="ws-flex-container-vertical ws-flex-auto">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <table class="table table-condensed table-hover">
                        <thead>
                            <tr>
                                <th style="width: 20%">Assessor</th>
                                <th style="width: 40%">Depreciation</th>
                                <th style="width: 40%">Index</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="showDefaultAssessor">
                                <td>State-level factor tables</td>
                                <td>
                                    <select class="form-select form-select-sm" [name]="'defaultDep'" [formControl]="defaultDepreciationControl" [compareWith]="compareFn">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let option of (showAllAvailableFactors.value ? defaultDepreciationTables : defaultDepreciationUsedTables)" [ngValue]="option.factorTableId">{{ option.factorTableName }}: {{ option.life }} yr life {{option.companyId ? '(company)' : '(system)'}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm" [name]="'defaultIndex'" [formControl]="defaultIndexControl" [compareWith]="compareFn">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let option of (showAllAvailableFactors.value ? defaultIndexTables : defaultIndexUsedTables)" [ngValue]="option.factorTableId">{{ option.factorTableName }}: {{ option.life }} yr life {{option.companyId ? '(company)' : '(system)'}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr *ngFor="let assessor of assessors">
                                <td>{{ assessor.assessorName }}</td>
                                <td>
                                    <select class="form-select form-select-sm" [name]="'dep' + assessor.assessorId" [formControl]="assessorDepreciationControls[assessor.assessorId]" [compareWith]="compareFn">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let option of (showAllAvailableFactors.value ? assessorDepreciationTables[assessor.assessorId] : assessorDepreciationUsedTables[assessor.assessorId])" [ngValue]="option.factorTableId">{{ option.factorTableName }}: {{ option.life }} yr life {{option.companyId ? '(company)' : '(system)'}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm" [name]="'ind' + assessor.assessorId" [formControl]="assessorIndexControls[assessor.assessorId]">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let option of (showAllAvailableFactors.value ? assessorIndexTables[assessor.assessorId] : assessorIndexUsedTables[assessor.assessorId])" [ngValue]="option.factorTableId">{{ option.factorTableName }}: {{ option.life }} yr life {{option.companyId ? '(company)' : '(system)'}}</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
</ws-modal-wrapper>
