import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { FilingBatchRepository } from '../../Repositories';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';
import { lastValueFrom } from 'rxjs';

export interface FilingBatchListDataSourceParams {
    includeCompleted: boolean;
    companyId: number;
}

export class FilingBatchListAgGridDataSource extends AgGridDataSourceBase<FilingBatchListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _filingBatchRepository: FilingBatchRepository,
        protected _dataSourceParamsFn: () => FilingBatchListDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.FilingBatchPropertyEnum>[] = [
        { name: 'companyName', value: Compliance.FilingBatchPropertyEnum.Company },
        { name: 'taxYear', value: Compliance.FilingBatchPropertyEnum.TaxYear },
        { name: 'stateName', value: Compliance.FilingBatchPropertyEnum.State },
        { name: 'description', value: Compliance.FilingBatchPropertyEnum.Description },
        { name: 'filingCount', value: Compliance.FilingBatchPropertyEnum.FilingCount },
        { name: 'dueDate', value: Compliance.FilingBatchPropertyEnum.DueDate },
        { name: 'completedDate', value: Compliance.FilingBatchPropertyEnum.CompletedDate },
        { name: 'status', value: Compliance.FilingBatchPropertyEnum.Status },
        { name: 'displayId', value: Compliance.FilingBatchPropertyEnum.DisplayId }
    ];

    protected canGetRows(): boolean {
        return (this._dataSourceParams.companyId !== null);
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.FilingBatchSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeCompleted: this._dataSourceParams.includeCompleted
        };

        const result = await lastValueFrom<Compliance.QueryResultModel<Compliance.FilingBatchModel>>(this._filingBatchRepository.getList(this._dataSourceParams.companyId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<{ filingBatchId: number, isLockedOrCompleted: boolean }>> {
        const searchParams: Core.SearchModel<Compliance.FilingBatchPropertyEnum> = this.getSearchModel(startIndex, endIndex);

        return await lastValueFrom(this._filingBatchRepository.getRowIds(this._dataSourceParams.companyId, searchParams));
    }

    getSearchModel(startIndex?: number, endIndex?: number): Compliance.FilingBatchSearchModel {
        return {
            pagination: {
                skip: (startIndex) ? startIndex : 0,
                take: (endIndex) ? endIndex - startIndex + 1 : 0
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeCompleted: this._dataSourceParams.includeCompleted
        }
    }
}
