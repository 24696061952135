<div class="return-forms-error">
    <ws-modal-wrapper [headerLabel]="modalTitle"
                      [hideCancel]="!showCancel"
                      (save)="accept()"
                      (cancel)="cancel()">
        <div class="ws-flex-container-vertical ws-stretch">
            <span class="error-description">{{aggregate.label}}:</span>
            <div class="error-container">
                <div>
                    <div *ngFor="let error of aggregate.errors" (click)="toggleParcels(error)">
                        <div class="form-error">
                            <i class="fa fa-caret-right show-details" [class.open]="error.showForms"></i>
                            <span class="form-name">{{ error.assessor }}</span>
                            <span>{{ error.descriptor }}</span>
                        </div>
                        <ul class="closed" [class.open]="error.showForms">
                            <li *ngFor="let parcel of error.forms">{{ parcel }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <span class="error-description">{{ aggregate.warning }}</span>
        </div>
    </ws-modal-wrapper>
</div>
