import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class AllocationRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) {
        super(httpClient);
    }


    private _pathPrefix = 'api/allocation';

    getList(companyId: number, searchModel: Compliance.AllocationSearchModel): Observable<Compliance.QueryResultModel<Compliance.AllocationModel>> {
        return this.httpPost(`${this._pathPrefix}/company/${companyId}`, searchModel);
    }

    get(allocationId: number): Observable<Compliance.AllocationModel> {
        return this.httpGet(`${this._pathPrefix}/${allocationId}`);
    }

    getAssessmentsSummary(allocationId: number, model: Compliance.AllocationAssessmentSummarySearchModel): Observable<Compliance.AllocationAssessmentSummaryModel[]> {
        return this.httpPost(`api/allocation/${allocationId}/assessmentsSummary`, model);
    }

    create(companyId: number, model: Compliance.AllocationCreateModel): Observable<Compliance.AllocationModel> {
        return this.httpPost(`${this._pathPrefix}/company/${companyId}/create`, model);
    }

    getTaxAuthorities(allocationId: number): Observable<Compliance.TaxAuthorityModel[]> {
        return this.httpGet(`api/allocation/${allocationId}/taxAuthorities`);
    }

    addTaxAuthorities(allocationId: number, taxAuthorityIds: number[]): Observable<Compliance.AllocationAssessmentSummaryModel[]>{
        return this.httpPost(`api/allocation/${allocationId}/addTaxAuthorities`, taxAuthorityIds);
    }

    lockAllocationAssessment(allocationId: number, model: Compliance.SelectAllocationAssessmentModel): Observable<Compliance.LockAllocationAssessmentResultModel> {
        return this.httpPost(`api/allocation/${allocationId}/lockAllocationAssessment`, model);
    }

    releaseLockedAllocationAssessment(allocationId: number, model: Compliance.SelectAllocationAssessmentModel): Observable<Compliance.ReleaseLockedAllocationAssessmentResultModel> {
        return this.httpPost(`api/allocation/${allocationId}/releaseLockedAllocationAssessment`, model);
    }

    distributeAssessmentVariance(allocationId: number, model: Compliance.AllocationDistributeAssessmentVarianceModel): Observable<Compliance.AllocationAssessmentUpdateResultModel> {
        return this.httpPost(`api/allocation/${allocationId}/distributeAssessmentVariance`, model);
    }

    deleteTaxAuthority(allocationId: number, model: Compliance.DeleteTaxAuthorityModel): Observable<Compliance.AllocationAssessmentSummaryModel[]> {
        return this.httpPost(`api/allocation/${allocationId}/deleteTaxAuthority`, model);
    }

    getAvailableParcels(model: any): Observable<Compliance.QueryResultModel<Compliance.ReportingParcelModel>> {
        return this.httpPost(`api/allocation/availableParcels`, model);
    }

    delete(allocationId: number): Observable<void> {
        return this.httpDelete(`/api/allocation/${allocationId}`);
    }

    startExport(companyId: number, model: Compliance.AllocationExportModel): Observable<number> {
        return this.httpPost(`api/allocation/company/${companyId}/export`, model);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`api/allocation/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getCreateNewMergedParcelsMetadata(allocationId: number, model: Compliance.SelectedRowsModel): Observable<Compliance.AllocationGetCreateNewMergedParcelsMetadataResult> {
        return this.httpPost(`api/allocation/${allocationId}/getCreateNewMergedParcelsMetadata`, model);
    }

    createNewMergedParcels(allocationId: number, model: Compliance.AllocationCreateMergedParcelsModel): Observable<Compliance.AllocationAssessmentSummaryModel[]> {
        return this.httpPost(`api/allocation/${allocationId}/createNewMergedParcels`, model);
    }

    prepareData(allocationId: number): Observable<void> {
        return this.httpPost(`api/allocation/${allocationId}/prepareData`, null);
    }

    getCompanyAssets(allocationId: number, model: Core.SearchModel<Compliance.AllocationCompanyAssetPropertyEnum>): Observable<Compliance.QueryResultModel<Compliance.AllocationCompanyAssetModel>> {
        return this.httpPost(`api/allocation/${allocationId}/getCompanyAssets`, model);
    }

    getCompanyAssetIds(allocationId: number, model: Core.SearchModel<Compliance.AllocationCompanyAssetPropertyEnum>): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`api/allocation/${allocationId}/getCompanyAssetIds`, model);
    }

    addCompanyAssets(allocationId: number, model: Compliance.SelectedRowsModel): Observable<void> {
        return this.httpPost(`api/allocation/${allocationId}/addCompanyAssets`, model);
    }

    markAllocationReviewed(allocationId: number, model: Compliance.SelectAllocationAssessmentModel): Observable<Compliance.AllocationAssessmentUpdateResultModel> {
        return this.httpPost(`api/allocation/${allocationId}/markAllocationReviewed`, model);
    }
}
