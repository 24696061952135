import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {
    logError(e) {
        if (e.rejection && e.rejection.error) {
            console.error(e.rejection.error);
            return;
        }
        else if (e.rejection) {
            console.error(e.rejection);
            return;
        }
        
        console.error(e);
    }
}
