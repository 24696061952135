import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

const baseUrl = '/api/descriptor/';

@Injectable(
    { providedIn: 'root' }
)
export class DescriptorService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
     }

    getByCategoryId(categoryId: number, useCache?: boolean): Promise<Core.DescriptorDTO[]> {
        const url = baseUrl + categoryId;
        const config = {
            cache: useCache || false
        };

        return lastValueFrom(this.httpGet(url, config)) as Promise<Core.DescriptorDTO[]>;
    }

    update(descriptor: Core.DescriptorDTO) {
        return lastValueFrom(this.httpPut(baseUrl, descriptor));
    }

    create(descriptor: Core.DescriptorDTO): Promise<any> {
        return lastValueFrom(this.httpPost(baseUrl, descriptor));
    }

    deleteById(id: number) {
        const url = baseUrl + id;

        return lastValueFrom(this.httpDelete(url, {}));
    }

    getAvailable(companyId: number, entityTypeId: number): Promise<Core.GetAvailableDescriptorsResult> {
        return lastValueFrom(this.httpGet(`${baseUrl}available/${companyId}`, { params: { usageEntityType: entityTypeId } })) as Promise<Core.GetAvailableDescriptorsResult>;
    }

    searchAvailableOverrides(companyId: number, searchModel: Core.EntityDescriptorSearchModel): Observable<Core.DescriptorDTO[]> {
        return this.httpPost(`/api/companydescriptoroverride/company/${companyId}/available`, searchModel);
    }

    getUsedBySite(companyId: number): Promise<Core.DescriptorDTO[]> {
        return lastValueFrom(this.httpGet(`/api/companydescriptoroverride/company/${companyId}/usedBySite`));
    }
}
