import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_QUIESCE_MODAL_HELP } from './longRunningProcessQuiesceModal.component.help';

@Component({
    templateUrl: './longRunningProcessQuiesceModal.component.html',
    selector: 'long-running-process-quiesce-modal'
})
export class LongRunningProcessQuiesceModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, string> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _helpService: HelpService) {
    }

    params: void;
    result: string;

    form: UntypedFormGroup;

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_QUIESCE_MODAL_HELP);

        this.form = this._formBuilder.group({
            message: [null, [Validators.maxLength(100), Validators.required]]
        });

        this.form.setValue({
            message: 'The processing service is not available.  Please try again later.'
        });
    }

    ngOnDestroy(): void { }

    async pause(): Promise<void> {
        this.result = this.form.get('message').value;
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
