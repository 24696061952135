import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent, WeissmanModalService } from '../../../../../Compliance/WeissmanModalService';
import { ValuationTemplateService } from '../../../valuationTemplate.service';
import { BusyIndicatorMessageManager } from '../../../../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';

export interface ValuationTemplateUploadModalParams {
    valuationTemplateId: number;
    siteClassPrimaries: Core.ValuationTemplateSiteClassPrimaryModel[];
}

@Component({
    selector: 'template-upload',
    templateUrl: './upload.component.html'
})
export class UploadComponent implements IWeissmanModalComponent<ValuationTemplateUploadModalParams, Core.ValuationTemplateModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastr: ToastrService
    ) { }

    params: ValuationTemplateUploadModalParams;
    result: Core.ValuationTemplateModel;

    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    file: File;



    cancel(): void {
        this._bsModalRef.hide();
    }

    filePicked(files: File[]): void {
        this.file = files[0];
    }

    async upload(): Promise<void> {
        this.busyMessageManager.add('Uploading', 'uploading');

        try {
            this.result = await this._valuationTemplateService.updateTemplateFile(this.params.valuationTemplateId, this.file);

            this.cancel();
        } catch(err) {
            this._toastr.error(err.error.message);
        } finally {
            this.busyMessageManager.remove('uploading');
        }
    }
}
