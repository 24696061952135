import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../AgGrid/ToolPanel/models';
import { LongRunningProcessListAgGridDataSource } from './agGridDataSource';
import { LongRunningProcessRepository } from '../../Repositories';
import { WeissmanDateTimeFormatPipe } from '../../../UI-Lib/Pipes';
import { AgGridMultiSelectedCellRenderer,
         AgGridMultiSelectedHeaderRenderer,
         AgGridMultiSelectRendererParams,
         AgGridMultiSelectTracker } from '../../AgGrid/MultiSelectTracker';
import { Subscription, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { LongRunningProcessService } from '../longRunningProcess.service';
import { WeissmanTimeSpanPipe } from '../../../UI-Lib/Pipes';
import { DecimalPipe } from '@angular/common';
import { LONG_RUNNING_PROCESS_LIST_HELP } from './longRunningProcessList.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import {
    LongRunningProcessListGridActionCellRendererComponent,
    LongRunningProcessListGridActionCellRendererParams
} from './agGridActionCellRenderer.component';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import {
    ErrorLogDetailsComponent,
    ErrorLogDetailsParams
} from '../../../Diagnostics/Error-Log/Error-Log-Details/errorLogDetails.component';
import {WeissmanModalService} from '../../WeissmanModalService';
import {
    LongRunningProcessListGridErrorGuidCellRendererComponent, LongRunningProcessListGridErrorGuidCellRendererParams
} from './long-running-process-list-grid-error-guid-cell-renderer.component';

@Component({
    selector: 'long-running-process-list',
    templateUrl: './longRunningProcessList.component.html',
    styleUrls: ['./longRunningProcessList.component.scss']
})
export class LongRunningProcessListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe,
        private readonly _timeSpanPipe: WeissmanTimeSpanPipe,
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _helpService: HelpService,
        private readonly _modalService: WeissmanModalService) {
    }

    private _gridApi: GridApi;
    private _gridDataSource: LongRunningProcessListAgGridDataSource;
    private _refreshSub: Subscription;
    private _statusChangeSub: Subscription;
    private _gridInitialized: ReplaySubject<void>;

    @Input() canEdit: boolean;

    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = '3BF7E5D1-40E4-4E40-93BB-3F3652E90F10';
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.LongRunningProcessModel).longRunningProcessId)
        }
    })
        .withColumnPinning()
        .buildDefault(this);
        exportOptions: AgGridExportOptions = {
            start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
                const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();
                searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
                if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0){
                        searchParams.selectedRows.selectAllRows = true;
                }
                const exportModel: Compliance.LongRunningProcessExportModel = {
                    searchModel: searchParams,
                    columnsToReturn: columnsToReturn
                };
                const lrp$: Observable<number> =  this._longRunningProcessRepository.startLrpListExport(exportModel);
                const longRunningProcessId = await lastValueFrom(lrp$);
                const longRunningProcessTypeId = Compliance.LongRunningProcessTypeEnum.ExportLRPList;
                return { longRunningProcessId, longRunningProcessTypeId };
            },
            canCancel: true,
            showFileFormatSelection: false
        };

    ngOnInit(): void {
        this._gridInitialized = new ReplaySubject<void>();
        this._helpService.setContent(LONG_RUNNING_PROCESS_LIST_HELP);

        this._statusChangeSub = this._longRunningProcessService.longRunningProcessStatusChange$.subscribe(async (x) => {
            if (this._longRunningProcessService.autoRefresh) {
                this._refreshDataSource();
            } else {
                this._gridInitialized.subscribe(async () => {
                    await this._gridDataSource.updateRow(
                        y => {
                            const rowModel = y.data as Compliance.LongRunningProcessModel;
                            return rowModel && rowModel.longRunningProcessId === x.longRunningProcessId;
                        },
                        async () => {
                            return await this._longRunningProcessService.get(x.longRunningProcessId);
                        }, true);
                });
            }
        });

        this._refreshSub = this._longRunningProcessService.refresh$.subscribe(() => {
            this._refreshDataSource();
        });
    }

    ngOnDestroy(): void {
        this._refreshSub && this._refreshSub.unsubscribe();
        this._statusChangeSub && this._statusChangeSub.unsubscribe();
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<Compliance.LongRunningProcessModel>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'longRunningProcessId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'ID',
                field: 'longRunningProcessId',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                lockVisible: true,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Status',
                field: 'statusCode',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Created By',
                field: 'createdByName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Instance',
                field: 'instanceName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Entity ID',
                field: 'entityId',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Host Name',
                field: 'hostName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Queue Type',
                field: 'longRunningProcessQueueTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Process Type',
                field: 'longRunningProcessTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Operation',
                field: 'operationDisplayName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Parameters',
                field: 'parameters',
                width: AgGridColumns.textColumnWidth,
                hide: true
            },
            {
                headerName: 'Result',
                field: 'result',
                width: AgGridColumns.textColumnWidth,
                hide: true
            },
            {
                headerName: 'Created',
                field: 'createDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true, 'central') : '',
                hide: true
            },
            {
                headerName: 'Changed',
                field: 'changeDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true, 'central') : ''
            },
            {
                headerName: 'Start',
                field: 'start',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true, 'central') : ''
            },
            {
                headerName: 'End',
                field: 'end',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._dateTimePipe.transform(params.value, true, 'central') : ''
            },
            {
                headerName: 'Queue',
                field: 'queueMs',
                width: AgGridColumns.numericColumnWidth,
                type: 'numericColumn',
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._timeSpanPipe.transform(params.value, 2, 's') : '',
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Process',
                field: 'totalMs',
                width: AgGridColumns.numericColumnWidth,
                type: 'numericColumn',
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._timeSpanPipe.transform(params.value, 2, 's') : '',
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Time/Row',
                field: 'msPerRow',
                width: AgGridColumns.numericColumnWidth,
                type: 'numericColumn',
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => params.value || params.value === 0 ? this._timeSpanPipe.transform(params.value, 2, 's') : '',
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: '# Rows',
                field: 'totalRows',
                width: AgGridColumns.numericColumnWidth,
                type: 'numericColumn',
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._decimalPipe.transform(params.value, '1.0-0') : '',
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Error',
                field: 'error',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: 'text-danger',
                cellRendererFramework: LongRunningProcessListGridErrorGuidCellRendererComponent,
                cellRendererParams: {
                    showErrorDetails: this._showErrorDetails.bind(this)
                } as LongRunningProcessListGridErrorGuidCellRendererParams
            },
            {
                headerName: 'Canceled',
                field: 'isCanceled',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                },
                hide: true
            },
            {
                headerName: 'Paused',
                field: 'isPaused',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                },
                hide: true
            },
            {
                headerName: 'Acknowledged',
                field: 'isAcknowledged',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                },
                hide: true
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: LongRunningProcessListGridActionCellRendererComponent,
                cellRendererParams: {
                    canUpdateError: this._canUpdateError.bind(this),
                    updateError: this._updateError.bind(this)
                } as LongRunningProcessListGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'longRunningProcessId',
                sort: 'desc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
        this._gridInitialized.next();
    }

    private _canUpdateError(params: LongRunningProcessListGridActionCellRendererParams): boolean{
        const longRunningProcess = params.data as Compliance.LongRunningProcessModel;
        if (!longRunningProcess) {
            return false;
        }

        return (this.canEdit && !longRunningProcess.isCompleted);
    }


    private async _updateError(params: LongRunningProcessListGridActionCellRendererParams): Promise<void> {
        const longRunningProcess = params.data as Compliance.LongRunningProcessModel;
        if (!longRunningProcess) {
            return;
        }

        try {
            await this._messageModalService.confirm('Are you sure you wish to update the long-running process to error status?  If the process is still running, it will not be terminated.', 'Confirm');
        }
        catch (e) {
            return;
        }

        const busyRef = this._busyService.show({ message: 'Updating' });

        try {
            const errorModel: Compliance.LongRunningProcessErrorModel = {
                longRunningProcessId: longRunningProcess.longRunningProcessId,
                errorMessage: 'An unexpected error has occurred.'
            };

            const result = await lastValueFrom(this._longRunningProcessRepository.updateError(errorModel));
            Object.assign(longRunningProcess, result);

            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } finally {
            busyRef.hide();
        }
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this._gridDataSource = new LongRunningProcessListAgGridDataSource(this._gridApi, this._longRunningProcessRepository);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _showErrorDetails(errorGuid: string): Promise<void> {
        if (!errorGuid) {
            return;
        }

        const detailParams: ErrorLogDetailsParams = {
            errorLogId: null,
            errorLogGuid: errorGuid
        };

        await this._modalService.showAsync(ErrorLogDetailsComponent, detailParams, 'modal-lg');
    }

}
