import { Component, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'messageModal',
    templateUrl: './message-modal.component.html'
})
export class MessageModalComponent {
    constructor(public bsModalRef: BsModalRef) { }

    result: string = 'Cancel';  // Initialized to Cancel in case user closes modal with outside click

    title: string;

    subtitle: string;

    message: string;

    messageList: string[] = [];

    hasOK: boolean;
    hasYes: boolean;
    hasNo: boolean;
    hasCancel: boolean;
    hasContinue: boolean;

    isErrorState: boolean;

    ngOnInit() { }

    closeModal(result = 'Cancel'): void {
        this.result = result;
        this.bsModalRef.hide();
    }

    ok(result = 'OK'): void {
        this.result = result;
        this.bsModalRef.hide();
    }

    cancel(result = 'Cancel'): void {
        this.result = result;
        this.bsModalRef.hide();
    }

    yes(result = 'Yes'): void {
        this.result = result;
        this.bsModalRef.hide();
    }

    no(result = 'No'): void {
        this.result = result;
        this.bsModalRef.hide();
    }
}
