<div class="filter-group-inline-container">
    <div class="filter-label-inline">
        <label for="selectedFormRevision">Form</label>
    </div>
    <div class="filter-control-inline">
        <ws-select [options]="returnFormRevisions"
                   [canDeselect]="allowPrimaryForReturn"
                   deselectLabel="Primary for Return"
                   [placeholder]="(allowPrimaryForReturn ? 'Primary for Return' : 'Select a form...')"
                   [returnEntireOption]="true"
                   labelProperty="displayName"
                   [disabled]="!(allowPrimaryForReturn || (returnFormRevisions && returnFormRevisions.length))"
                   [ngModel]="selectedReturnFormRevision"
                   (ngModelChange)="onSelectedReturnFormRevisionChange($event)">
        </ws-select>
    </div>
</div>
