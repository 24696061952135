'use strict';

/**
 * @ngdoc directive
 * @name weissman.directive:percent
 * @description
 * # percent
 */
angular.module('weissmanApp')
    .directive('percent', ['$filter', function($filter) {
        return {
            require: 'ngModel',
            link: function(elem, $scope, attrs, ngModel) {
                var decimals = attrs.decimals === undefined ? 3 : attrs.decimals;
                ngModel.$parsers.unshift(
                    function(viewValue) {
                        console.log(viewValue)
                        return $filter('number')(parseFloat(viewValue) / 100, decimals + 2);
                    }
                );
                ngModel.$formatters.unshift(
                    function(modelValue) {
                        console.log(modelValue)
                        return $filter('number')(parseFloat(modelValue) * 100, decimals);
                    }
                );
            }
        }
    }]);
