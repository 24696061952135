import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LongRunningProcessService } from '../longRunningProcess.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { LongRunningProcessQuiesceModalComponent } from '../Long-Running-Process-Quiesce-Modal/longRunningProcessQuiesceModal.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_QUIESCE_HELP } from './longRunningProcessQuiesce.component.help';

@Component({
    selector: 'long-running-process-quiesce',
    templateUrl: './longRunningProcessQuiesce.component.html',
    styleUrls: ['./longRunningProcessQuiesce.component.scss']
})
export class LongRunningProcessQuiesceComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _modalService: WeissmanModalService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _helpService: HelpService) { }

    @Input() canEdit: boolean;

    get quiescedStatus(): Compliance.LongRunningProcessQuiescedStatusModel {
        return this._longRunningProcessService.quiescedStatus;
    }

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_QUIESCE_HELP);
    }

    ngOnDestroy(): void {
    }

    async pause(): Promise<void> {
        const result = await this._modalService.showAsync(LongRunningProcessQuiesceModalComponent, null, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        const busyRef = this._busyService.show({ message: 'Pausing service' });

        try {
            const model: Compliance.LongRunningProcessQuiescedStatusModel = {
                isQuiesced: true,
                message: result
            };

            await this._longRunningProcessService.updateQuiescedStatus(model);
        } finally {
            busyRef.hide();
        }
    }

    async resume(): Promise<void> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to resume the long-running process service?', 'Confirm');
        }
        catch (e) {
            return;
        }

        const busyRef = this._busyService.show({ message: 'Resuming service' });

        try {
            const model: Compliance.LongRunningProcessQuiescedStatusModel = {
                isQuiesced: false,
                message: null
            };

            await this._longRunningProcessService.updateQuiescedStatus(model);
        } finally {
            busyRef.hide();
        }
    }
}
