import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StampComponent } from './stamp.component';
import { StampTooltipComponent } from './stampTooltip.component';
import { HelpModule } from '../../UI-Lib/Help-Tooltip/help.module';

@NgModule({
    imports: [CommonModule, HelpModule],
    declarations: [StampComponent, StampTooltipComponent],
    exports: [StampComponent]
})
export class StampModule {

}
