import { Component, OnInit } from '@angular/core';
import { CompanyModel, CompanyService } from './company.service';
import { ParentCompanyModel } from './Info/companyInfoPanel.component';
import { CompanyEntity } from '../entity.model';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { CurrentEntityService } from '../../Common/Entity/CurrentEntityService';
import { InstanceRights, RestrictData, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { EntityTypeIds, RyanInstanceId } from '../../constants.new';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { UserInstanceService } from '../../User/userInstance.service';

@Component({
    selector: 'company-page',
    templateUrl: 'companyPage.component.html'
})

export class CompanyPageComponent implements OnInit {
    constructor(private readonly _companyService: CompanyService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _currentEntityService: CurrentEntityService,
        private readonly _restrictService: RestrictService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _userInstanceService: UserInstanceService) { }

    serverAction: boolean = true;
    company: CompanyModel;
    parentCompany: ParentCompanyModel;
    entity: CompanyEntity;
    showComplianceInfo: boolean;
    hasWritePermission: boolean;

    private _hasRyanPrivateItemsEdit: boolean;
    private _hasRyanPrivateItemsView: boolean;

    get isTopLevel(): boolean {
        return !this.company.parentId;
    }

    get hasPrivateItemsPermission(): boolean {
        return this._featureFlagService.featureFlags.enableLicensedConsultingInvoicing
            ? (this._hasRyanPrivateItemsView || this._hasRyanPrivateItemsEdit) && this._userInstanceService.getSelectedInstance().instanceId === RyanInstanceId
            : ((this._hasRyanPrivateItemsView || this._hasRyanPrivateItemsEdit) && this.company.instanceID === RyanInstanceId);
    }

    get allowInvoiceEdit(): boolean {
        return this._hasRyanPrivateItemsEdit;
    }

    async ngOnInit() {
        this._hasRyanPrivateItemsView = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW) ||
                this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
		this._hasRyanPrivateItemsEdit = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading company' });
        try {
            const companyId = +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId');
            this.company = await this._companyService.load(companyId, false, true);

            this.entity = {
                id: this.company.companyID,
                type: 'company',
                typeId: Core.EntityTypes.Company,
                newAllowed: true,
                name: this.company.companyName,
                canEditCompanySetups: this.company.canEditCompanySetups,
                ppReturnPreparationAllowed: this.company.ppReturnPreparationAllowed,
                isTopLevel: this.isTopLevel,
                canEditCompany: this.company.canEditCompany,
                isInactive: this.company.activityStatusID === Core.ActivityStatuses.Inactive
            };
            this._currentEntityService.set(this.company, EntityTypeIds.COMPANY);

            this.setShowComplianceInfo(this.entity.ppReturnPreparationAllowed);

            const restrictionData = {
                isCompany: true,
                entityId: this.company.companyID,
                flag: Core.AccessRightsEnum.Write
            } as RestrictData;

            this.entity.hasWritePermission = await this._restrictService.hasPermission(restrictionData);
            this.hasWritePermission = this.entity.hasWritePermission && this.company.canEditCompany;

            if(!this.isTopLevel) {
                this.parentCompany = await this._companyService.loadCompanyParent(this.company.companyID, this.company.parentId, );
            }
        } finally {
            busyRef.hide();
            this.serverAction = false;
        }
    }

    setShowComplianceInfo(ppReturnPreparationAllowed: boolean): void {
        this.showComplianceInfo = ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, this.company.instanceID);
    }
}
