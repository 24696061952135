(function () {
	'use strict';

	var APP_SOURCE = 'sdNewPassword';

	angular
		.module('weissmanApp')
		.directive(APP_SOURCE, Directive);

	Directive.$inject = [
		'sd.Account.User.Service',
    ];

	function Directive(userService) {
		// Usage:
		// 		<div sd-restrict enableSites="{[siteId]}" showCompanies="{[companyId]}" flags="{PermissionFlags.READ}" show-roles="['role1','role2',...]" enable-roles="['role1','role2',...]">foo</div>
		//
		// See PermissionFlags constant in constants.js for PermissionFlags values
		// See Roles constant in constants.js for Roles values
		var directive = {
			restrict: 'E',
			scope: {
                showCurrent: '=',
                form: '=',
                passwords: '=',
                alreadyUsed: '='
            },
            templateUrl: 'app/Account/newPassword.html',
			link: link
		};

		return directive;

		function link(scope, element) {
            scope.passwordRegEx = '';
            scope.username = '';

            scope.clearCurrentValidation = clearCurrentValidation;
            scope.clearNewValidation = clearNewValidation;
            scope.checkAlreadyUsed = checkAlreadyUsed;

            activate();

            function activate() {
                //   (?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                scope.passwordRegEx = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;;
                var user = userService.getUser();
                if (user) {
                    scope.username = user.username;
                }
            }

            function clearCurrentValidation(form) {
                form.current.$setValidity('incorrect', true);
            }

            function clearNewValidation(form) {
                form.new.$setValidity('noSpecialChars', true);
            }

            function checkAlreadyUsed(form) {
                var validity = scope.passwords.new !== scope.alreadyUsed;

                form.new.$setValidity('alreadyUsed', validity)
            }
		}
	}
})();
