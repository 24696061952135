import { TaxRateArea, TaxAuthority, TaxAuthorityDetail, TaxRateAreaTaxAuthorityDetail } from '../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { CommonBulkUpdateFieldActionEnum } from '../constants.new';
import { ColDef } from 'ag-grid-community';

export type TaxAuthoritiesOrderBy = 'alpha' | 'manual';

export class TaxRateAreaPayload extends TaxRateArea {
    collectors: TaxRateAreaCollector[];
}

export class TaxRateAreaCollector {
    name: string;
    collectorID: number;
    active: boolean;
    isTab: boolean;
    taxAuthorities: TaxAuthority[];
}

export class TaxAuthorityPayload extends TaxAuthority {
    taxRateAreaInclusion: TaxRateAreaYears[];
}

export class TaxRateAreaYears {
    taxRateAreaName: string;
    years: Map<string, boolean>;
}

export interface PanelProjectionsPayload {
    taxRateAreaId: number;
    collectorId: number;
    taxAuthorityId: number;
    taxAuthorityDefaultAnnualRateIncrease: number;
    details: TaxAuthorityDetail[];
    taxRateAreaTaxAuthorityDetails: TaxRateAreaTaxAuthorityDetail[];
    yearBegin: number;
    yearEnd: number;
}

export class TaxAuthorityBulkUpdateContent {
    taxAuthorityCategoryId?: number;
    taxableAssessmentTypeId: number = 1;
    certificationMonth?: number;
    certificationDay?: number;
    certificationType?: number;
    obtainInAdvance: boolean = true;
    defaultAnnualIncrease: number = 0.0;  // fraction. 0.02 for 2%
    taxYear: number = 0;
    yoyPercent: number = 0.0;             // fraction. 0.02 for 2%
}

export class TaxAuthorityBulkUpdateRequest extends TaxAuthorityBulkUpdateContent {
    categoryAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
    taxableAssessmentTypeAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
    certificationDateAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
    obtainInAdvanceAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
    defaultAnnualIncreaseAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
    yoyAction: CommonBulkUpdateFieldActionEnum = CommonBulkUpdateFieldActionEnum.NoChange;
}

export enum TaxAuthoritySelectionType {
    Given = 1,
    All = 2,
    AllButGiven = 3
}



export interface TaxAuthoritySelectionOptions {
    searchParams: CommandCenterSearchModel;
    selectionType: TaxAuthoritySelectionType;
    selectedTaxAuthorityIds: number[];
}

export class TaxAuthorityToUpdate {
    taxAuthorityId: number;
    name: string;
    code: string;
}

export class TaxAuthorityUpdateResults {
    billClusterCount: number = 0;
    billCount: number = 0;
    paymentCount: number = 0;
    taxAuthorityCount: number = 0;
}

export class TaxAuthorityBulkUpdateProgress extends TaxAuthorityUpdateResults {
    request: TaxAuthorityBulkUpdateRequest;

    currentIndex: number = 0;
    selections: TaxAuthorityToUpdate[] = [];

    reset() : void {
        this.currentIndex = 0;
        this.selections = [];
        this.billClusterCount = 0;
        this.billCount = 0;
        this.paymentCount = 0;
        this.taxAuthorityCount = 0;
    }
}

export interface CommandCenterSearchModel extends Core.SearchModel<any> {
    entityType: number;
    stateId: number;
    assessorId: number;
    collectorId: number;
    onlyPendingQC: boolean;
}

export enum CommandCenterPropertyEnum {
    // both TaxRateArea and TaxAuthority
    Name = 1,
    Code = 2,
    Disabled = 3,
    Address = 4,
    HasComments = 5,
    HasAttachment = 6,
    MayBeDeleted = 7,
    AssessorName = 8,
    CollectorName = 9,

    // TaxAuthority
    PtxId = 21,
    TaxAuthorityCategory = 22,
    TaxableAssessmentType = 23,
    ObtainInAdvance = 24,
    DefaultAnnualRateIncrease = 25,
    CertificationDate = 26,
    QcRequestTimeUtc = 29,
    QcRequestUserFullName = 30
}

export interface QcStateCount {
    item1: number //stateId
    item2: string //state name
    item3: number //QC Count
}

export interface TaxRateDatatExportModel {
    columnsToReturn: Compliance.NameValuePair<string>[];
    searchModel: Core.SearchModel<CommandCenterPropertyEnum>;
}
