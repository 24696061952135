import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class FactorTableRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    getList(searchParams: Compliance.FactorTableSearchModel): Observable<Compliance.FactorTableListItemModel[]> {
        return this.httpPost(`/api/factortable/search`, searchParams);
    }

    getStates(): Observable<Compliance.FactorTableStateModel[]> {
        return this.httpGet(`/api/factortable/states`);
    }

    get(factorTableId: number): Observable<Compliance.FactorTableModel> {
        return this.httpGet(`/api/factortable/${factorTableId}`);
    }

    delete(factorTableId: number, force: boolean): Observable<boolean> {
        return this.httpDelete(`/api/factortable/${factorTableId}?force=${force}`);
    }

    create(model: Compliance.FactorTableModel): Observable<Compliance.FactorTableModel> {
        return this.httpPost(`/api/factortable/create`, model);
    }

    update(model: Compliance.FactorTableModel): Observable<Compliance.FactorTableModel> {
        return this.httpPut(`/api/factortable/update`, model);
    }

    exportSourceAssetList(exportModel: Compliance.FactorTableExportModel): Observable<number> {
        return this.httpPost(`/api/factortable/export`, exportModel);
    }

    getAssetExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/factortable/export/${longRunningProcessId}`, options);
    }

    exportAttachments(factorTableIds: number[]): Observable<number> {
        return this.httpPut('/api/factorTable/exportAttachments', factorTableIds);
    }

    getExportAttachments(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/factorTable/exportAttachments/${longRunningProcessId}`, options);
    }
}
