import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import * as _ from 'lodash';
import { TaxRatesPanelPayload } from './tax.rates.panel.model';
import { TaxRateGridService } from '../../Tax-Rate/tax.rate.grid.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TaxRatesPanelService extends TaxRateGridService {
    constructor(private sdHttp: SDHttpService, httpClient: HttpClient) {
        super(httpClient);
    }

    private _taxRatesPanelDataSubject = new BehaviorSubject<any>(false);

    get taxRatesPanelData$(): Observable<void> {
        return this._taxRatesPanelDataSubject.asObservable();
    }

    async loadTaxRatesByCollector(collectorId: number): Promise<void> {
        await this._loadTaxRatesByCollector(collectorId);

        this._taxRatesPanelDataSubject.next(true);
    }

    async loadTaxRatesByAssessor(assessorId: number): Promise<void> {
        await this._loadTaxRatesByAssessor(assessorId);

        this._taxRatesPanelDataSubject.next(true);
    }

    private async _loadTaxRatesByCollector(collectorId: number): Promise<void> {
        this.isInitialized = false;

        const taxRatesPanelPayload: TaxRatesPanelPayload = await this.sdHttp.get(`/api/taxrate/collector/${collectorId}`);

        this.taxRateAreas = _.sortBy(taxRatesPanelPayload.taxRateAreas, 'name');
        this.taxAuthorities = _.sortBy(taxRatesPanelPayload.taxAuthorities, 'name');

        this.isInitialized = true;
    }

    private async _loadTaxRatesByAssessor(assessorId: number): Promise<void> {
        this.isInitialized = false;

        const taxRatesPanelPayload: TaxRatesPanelPayload = await this.sdHttp.get(`/api/taxrate/assessor/${assessorId}`);

        this.taxRateAreas = _.sortBy(taxRatesPanelPayload.taxRateAreas, 'name');
        this.taxAuthorities = _.sortBy(taxRatesPanelPayload.taxAuthorities, 'name');

        this.isInitialized = true;
    }
}
