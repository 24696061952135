(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('OutputDefaultsController', OutputDefaultsController);

	OutputDefaultsController.$inject = ['actionViewService', '$uibModal', 'advancedSearchService', 'sd.Account.User.Settings.Service', 'sd.Account.User.Service'];

	function OutputDefaultsController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(actionViewService, $uibModal, advancedSearchService, userSettingsService, userService) {
		var vm = this;
		var taskTypeColumns;
		var allFields;
		var userSetting;
		var customTaskTypes;

		activate();

		////////////////

		function activate() {
			actionViewService.getTaskTypes()
				.then(function (result) {
					taskTypeColumns = result.columns;

					vm.taskTypes = actionViewService.sortTaskTypes(result.taskTypes);
					 	//_.reject(taskTypeList, { 'taskSeriesTypeID': 1 });
				});

			advancedSearchService.getAllFields()
				.then(function (result) {
					allFields = result;

					userSetting = userSettingsService.getSettingsByGroup(12)[0] || {
						groupId: 12,
						name: 'Task View Output Defaults',
						value: [],
						id: 0
					};

					customTaskTypes = angular.fromJson(userSetting.value);

				})
		}

		vm.launchModal = function (clickedTaskType) {
			var customTaskType = _.find(customTaskTypes, function (taskType) {
				return taskType.taskSeriesTypeID === clickedTaskType.taskSeriesTypeID && taskType.taskTypeID === clickedTaskType.taskTypeID;
			});


			console.log('clickedTaskType', clickedTaskType);

			var modalInstance = $uibModal.open({
				templateUrl: 'app/Task/ActionView/Filters/_customOutputModal.html',
				controller: 'CustomOutputModalController',
				controllerAs: 'vm',
				windowClass: 'show',
				backdropClass: 'show',
				resolve: {
					fromActionView: function () {
						return false;
					},

					taskTypeName: function () {
						return clickedTaskType.name;
					},

					selectedTaskType: function () {
						return _.find(vm.taskTypes, {taskSeriesTypeID: clickedTaskType.taskSeriesTypeID});
					},

					systemColumns: function () {
						var systemColumns = _.map(clickedTaskType.columnIds, function (columnId) {
							var column = angular.copy(_.find(taskTypeColumns, { columnId: columnId }));

							return column;
						})

						return systemColumns;
					},

					userColumns: function () {
						if (!customTaskType) {
							return [];
						}

						var userColumns = _.map(angular.copy(customTaskType.columns), function (column) {
							var fullColumn = angular.copy(_.find(allFields, { advancedSearchFieldID: column.columnId }))
							_.assign(column, _.omit(fullColumn, 'sequence'));

							return column;
						});

						return userColumns;
					}
				}
			});

			modalInstance.result
				.then(function (fullColumns) {
					if (fullColumns.length) {
						var columnsToSave = _.map(fullColumns, function (filter, i) {
							return {
								columnId: filter.columnId,
								sequence: i
							};
						});

						if (customTaskType) {
							var i = _.findIndex(customTaskTypes, function (taskType) {
								return taskType.taskSeriesTypeID === customTaskType.taskSeriesTypeID && taskType.taskTypeID === customTaskType.taskTypeID;
							});

							customTaskTypes[i].columns = columnsToSave

						} else {
							customTaskTypes.push({
								taskSeriesTypeID: clickedTaskType.taskSeriesTypeID,
								taskTypeID: clickedTaskType.taskTypeID,
								columns: columnsToSave
							});
						}

						userSetting.value = JSON.stringify(customTaskTypes);
						userSettingsService.save(userSetting)
							.then(function () {})
					} else {
						if (customTaskType) {
							_.remove(customTaskTypes, customTaskType);

							userSetting.value = customTaskTypes;
							userSettingsService.save(userSetting)
								.then(function () {})
						}
					}
				})
		}
	}
})();
