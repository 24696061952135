<div class="ws-flex-container-vertical ws-flex-auto">
    <div class="entity-header">
        <ol class="breadcrumb float-start" *ngIf="originalSite">
            <li class="breadcrumb-item">
                <a class="pointer" (click)="navigateToCompany()">{{originalSite.company.companyName}}</a>
            </li>
            <li class="breadcrumb-item">
                <a class="pointer" (click)="navigateToOriginalSite()">{{originalSite.name}}</a>
            </li>
        </ol>
        <div class="clearfix"></div>
    </div>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">Site Map</h4>
        </div>
    </div>
    <div class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
        <div *ngIf="propertyTypes.length && siteClasses.length && originalSite" class="site-map-filters">
            <div class="ws-flex-container-vertical ws-flex-auto">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" (change)="limitToParentCompany($event)"> Limit to {{originalSite.company.companyName}}
                    </label>
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-flex-auto">
                <label class="ws-flex-none">Property types:</label>
                <select multiple class="filter-select prop-type-multi" (change)="propertyTypesSelected($event.target)" >
                    <option *ngFor="let type of propertyTypes" [value]="type.propertyTypeID" [selected]="filters.propertyTypeIds.includes(type.propertyTypeID)">
                        {{type.propTypeName}}
                    </option>
                </select>
            </div>
            <div class="ws-flex-container-vertical ws-flex-auto">
                <label class="ws-flex-none">Site classes:</label>
                <select multiple class="filter-select site-class-multi" (change)="siteClassesSelected($event.target)" >
                    <option *ngFor="let siteClass of siteClasses" [value]="siteClass.siteClassPrimaryID" [selected]="filters.siteClassPrimaryIds.includes(siteClass.siteClassPrimaryID)">
                        {{siteClass.siteClassPrimaryDesc}}
                    </option>
                </select>
            </div>
            <div class="number-filters">
                <div class="ws-flex-container-horizontal ws-flex-auto">
                    <label class="ws-flex-none">Min SqFt:</label>
                    <input type="number" class="form-control" [(ngModel)]="filters.minSqFt">
                </div>
                <div class="ws-flex-container-horizontal ws-flex-auto">
                    <label class="ws-flex-none">Max SqFt:</label>
                    <input type="number" class="form-control" [(ngModel)]="filters.maxSqFt">
                </div>
                <div class="ws-flex-container-horizontal ws-flex-auto">
                    <label class="ws-flex-none">Min Units:</label>
                    <input type="number" class="form-control" [(ngModel)]="filters.minUnits">
                </div>
                <div class="ws-flex-container-horizontal ws-flex-auto">
                    <label class="ws-flex-none">Max Units:</label>
                    <input type="number" class="form-control" [(ngModel)]="filters.maxUnits">
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-flex-auto">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="filters.onlyUnderAppeal"> Under appeal
                    </label>
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-flex-auto" style="margin-top: 30px;">
                <button type="button" class="flat-button primary-button" (click)="getNewSites$.next()">Apply</button>
            </div>
        </div>
        <div style="width: 100%; height: 100%;">
            <google-map *ngIf="originalSite"
                #map
                [center]="originalCenter"
                [zoom]="startingZoom"
                height="100%"
                width="100%"
                [options]="mapOptions"
                (idle)="mapIdle()"
                (boundsChanged)="boundsChanged()"
                (zoomChanged)="zoomChanged()">
                    <map-marker *ngIf="isOriginalSiteIncluded"
                        #origSiteMarkerElem="mapMarker"
                        [position]="originalCenter"
                        [options]="markerOptions"
                        (mapMouseover)="debouncedGetSiteInfo(originalSite.siteID, origSiteMarkerElem)"
                        (mapMouseout)="mouseOutOfPin(originalSite.siteID)">
                            <map-info-window [options]="infoWindowOptions">
                                <site-map-info-window [siteMarkerInfoArr]="siteMarkerInfoArr"
                                    (mouseenter)="mouseEnterWindow()"
                                    (mouseleave)="mouseleaveWindow()">
                                </site-map-info-window>
                            </map-info-window>
                    </map-marker>
                    <map-marker-clusterer imagePath="/images/google-maps-icons/cluster-icons/m" [minimumClusterSize]="4">
                        <map-marker *ngFor="let site of sitesToDisplay"
                            #siteMarkerElem="mapMarker"
                            [position]="site.center"
                            [options]="site.markerOptions"
                            (mapMouseover)="debouncedGetSiteInfo(site.siteId, siteMarkerElem, site.stackId)"
                            (mapMouseout)="mouseOutOfPin(site.siteId)">
                        </map-marker>
                        <map-info-window [options]="infoWindowOptions">
                            <site-map-info-window [siteMarkerInfoArr]="siteMarkerInfoArr"
                                (mouseenter)="mouseEnterWindow()"
                                (mouseleave)="mouseleaveWindow()">
                            </site-map-info-window>
                        </map-info-window>
                    </map-marker-clusterer>
            </google-map>
        </div>
    </div>
</div>
