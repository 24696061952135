import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { CompanyModule } from '../Entity/Company/company.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { BulkImportAssessmentsComponent } from './Assessments/bulk.import.assessments.component';
import { BulkImportService } from './bulk.import.service';
import { DisplayStringArrayComponent } from './Display-String-Array/display.String.Array.component';
import { BulkImportSitesComponent } from './Sites/bulk.import.sites.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        CompanyModule
    ],
    declarations: [
        BulkImportAssessmentsComponent,
        BulkImportSitesComponent,
        DisplayStringArrayComponent
    ],
    providers: [
        BulkImportService
    ],
    exports: [
        BulkImportAssessmentsComponent,
        BulkImportSitesComponent
    ]
})
export class BulkImportModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('bulkImportAssessments', BulkImportAssessmentsComponent);
        hybridAdapterUtility.downgradeNg2Component('bulkImportSites', BulkImportSitesComponent);
    }
}
