
<div class="input-group" aria-label="Select GL Account">
    <input name="glAccount"
           type="text"
           placeholder="Filter GL Accounts..."
           class="form-control"
           autocomplete="off"
           container="body"
           [class.overridden]="isOverridden"
           [(ngModel)]="glAccountFilter"
           (blur)="onGlAccountBlur()"
           [typeahead]="glAccounts$"
           (typeaheadNoResults)="onGlAccountNoResults($event)"
           typeaheadOptionField="displayName"
           [typeaheadOptionsLimit]="100"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onGlAccountSelected($event)"
           [disabled]="isLoading"/>
    <span *ngIf="isLoading" class="input-group-text visible">
        <i class="fa fa-spin fa-spinner"></i>
    </span>
</div>
