import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { AssetLimitDetailsComponent, AssetLimitDetailsParams, AssetOverrideProperty } from '../Asset-Limit-Details/assetLimitDetails.component';
import { AssetPropertyMap, AssetPropertyInfo } from '../../Models/assetPropertyMap';
import * as _ from 'lodash';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { ASSET_LIMIT_HELP } from './assetLimit.component.help';

export interface AssetLimitOption {
    overrideFilterColumns: Compliance.AssetPropertyEnum[];
    invalidCostAllocations: boolean;
    noReportingParcel: boolean;
}

@Component({
    selector: 'asset-limit',
    templateUrl: './assetLimit.component.html'
})
export class AssetLimitComponent implements OnInit {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService
    ) { }

    @Input()
    set assetDescriptors(assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[]) {
        if (assetDescriptors) {
            this._setAssetDescriptors(assetDescriptors);
        }
    };

    @Output() optionChange: EventEmitter<AssetLimitOption> = new EventEmitter<AssetLimitOption>();

    limitOptions: Compliance.NameValuePair<AssetLimitOption>[] = [
        { name: 'Nothing selected', value: { overrideFilterColumns: [], invalidCostAllocations: false, noReportingParcel: false } },
        { name: 'Any field is overridden', value: { overrideFilterColumns: [], invalidCostAllocations: false, noReportingParcel: false } },
        { name: 'Has an issue', value: { overrideFilterColumns: [], invalidCostAllocations: true, noReportingParcel: false } },
        { name: 'No default asset parcel', value: { overrideFilterColumns: [], invalidCostAllocations: false, noReportingParcel: true } },
        { name: 'Custom', value: { overrideFilterColumns: [], invalidCostAllocations: false, noReportingParcel: false } },
    ];
    overrideOptions: AssetPropertyInfo[];
    selectedOption: UntypedFormControl;

    ngOnInit(): void {
        this._helpService.setContent(ASSET_LIMIT_HELP);
        this.selectedOption = new UntypedFormControl(this.limitOptions[0]);
        this.optionChange.emit(this.limitOptions[0].value);
    }

    /**
     * Select Option change
     */
    selectedOptionChanged(): void {
        const filter = this.selectedOption.value;
        this.optionChange.emit(filter.value);
    }

    /**
     * Handle advanced filter modal
     */
    async edit(): Promise<void> {
        const params: AssetLimitDetailsParams = {
            limitOptions: this.limitOptions,
            selectedOption: this.selectedOption,
            assetDescriptors: this.overrideOptions
        };

        const result = await this._modalService.showAsync(AssetLimitDetailsComponent, params, 'modal-md');

        // trigger change detection on dropdown value
        this.selectedOption.setValue(this.selectedOption.value);

        if (!result) {
            return Promise.resolve();
        }

        if (this.selectedOption.value === this.limitOptions[this.limitOptions.length - 1]) {
            this.limitOptions[this.limitOptions.length - 1].value = result;
        }

        this.optionChange.emit(this.selectedOption.value.value);
    }

    private _setAssetDescriptors(assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[]): void {

        // get non-descriptor overridable properties
        let overrideOptions: AssetPropertyInfo[] = _.map(_.filter(AssetPropertyMap.properties, x => x.isFurtherLimitTo && !x.isDescriptor), x => x);

        _.forEach(assetDescriptors, x => {
            const descriptorKey = `reporting${x.columnName}`;
            const descriptorPropertyInfo = _.find(AssetPropertyMap.properties, (p: AssetPropertyInfo) => p.isFurtherLimitTo && p.isDescriptor && p.key === descriptorKey);

            if (descriptorPropertyInfo) {
                overrideOptions.push(_.extend(descriptorPropertyInfo, { isSelected: false, displayName: x.descriptor.name } as AssetOverrideProperty));
            }
            else {
                console.warn(`Property info not found for asset descriptor ${descriptorKey}`);
            }
        });

        this.overrideOptions = _.sortBy(overrideOptions, x => x.displayName);
        this.limitOptions[1].value.overrideFilterColumns = overrideOptions.map(o => o.property);
    }
}
