<div class="panel-flat-body form-horizontal format-asset-detail">
    <div class="report-settings">
        <div class="ws-flex-container-horizontal">
            <label class="control-label">Title:</label>
            <input type="text" class="form-control" maxlength="50" [(ngModel)]="report.criteria.title" required>
        </div>
        <div class="ws-flex-container-horizontal">
            <label class="control-label">Asset Ownership Type:</label>
            <ws-select class="stretch-input" [options]="ownershipTypes" [formControl]="assetOwnershipType"></ws-select>
        </div>
    </div>
    <div class="report-form-groups row">
        <div class="col" [ngClass]="{'col-md-3' : assetOwnershipType.value !== '0', 'col-md-4' : assetOwnershipType.value === '0'}">
            <form [formGroup]="layoutGroup" class="report-form-group">
                <div class="row">
                    <span class="col-md-8 section-header">
                        Report Layout:
                    </span>
                </div>
                <div class="row list-row">
                    <div class="col-md-6 ">
                        <label>Output Format</label>
                    </div>
                    <div class="col-md-6">
                        <select class="form-select form-select-sm" formControlName="outputFormat">
                            <option value="0">PDF</option>
                            <option value="1">Excel</option>
                        </select>
                    </div>
                </div>
                <div class="row list-row">
                    <div class="col-md-6 ">
                        <label>Paper Size</label>
                    </div>
                    <div class="col-md-6">
                        <select class="form-select form-select-sm" formControlName="legalSize">
                            <option [ngValue]="false">Letter</option>
                            <option [ngValue]="true">Legal</option>
                        </select>
                    </div>
                </div>
                <div class="row list-row">
                    <div class="col-md-6 ">
                        <label>Orientation</label>
                    </div>
                    <div class="col-md-6">
                        <select class="form-select form-select-sm" formControlName="showLandscape">
                            <option [ngValue]="true">Landscape</option>
                            <option [ngValue]="false">Portrait</option>
                        </select>
                    </div>
                </div>
                <div class="row list-row" *ngIf="layoutGroup.controls.outputFormat.value === '0' || layoutGroup.controls.outputFormat.value === 0">
                    <div class="col-md-6 ">
                        <label>Watermark</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" maxlength="25" class="form-control" formControlName="watermark">
                    </div>
                </div>
            </form>
        </div>
        <div class="col" [ngClass]="{'col-md-3' : assetOwnershipType.value !== '0', 'col-md-4' : assetOwnershipType.value === '0'}" align="left">
            <form [formGroup]="reportFormatGroup" class="report-form-group">
                <div class="row">
                    <span class="col-md-8 section-header">
                        Report Format:
                    </span>
                </div>
                <div class="check-row" *ngFor="let rf of reportFormatting">
                    <label class="ws-checkbox-label">
                        <input type="checkbox" [formControlName]="rf.formControlName">
                        <span>{{rf.label}}</span>
                    </label>
                </div>
                <div class="row list-row">
                    <div [ngClass]="{'col-md-10' : assetOwnershipType.value !== '0', 'col-md-8' : assetOwnershipType.value === '0'}">
                        <label helpTooltip helpContentId="report-manager.groupings" position="right">Groupings:</label>
                        <ws-picklist [options]="assetGroups"
                                     labelProperty="name"
                                     placeholder="Please select a grouping..."
                                     [formControl]="selectedAssetGroups">
                        </ws-picklist>
                    </div>
                </div>
            </form>
        </div>
        <div class="col" [ngClass]="{'col-md-3' : assetOwnershipType.value !== '0', 'col-md-4' : assetOwnershipType.value === '0'}">
            <form [formGroup]="optionalGroup" class="report-form-group">
                <div class="row">
                    <span class="col-md-8 section-header">
                        Optional Columns:
                    </span>
                </div>
                <div class="check-row" *ngFor="let col of optionalCols">
                    <label class="ws-checkbox-label">
                        <input type="checkbox" [formControlName]="col.formControlName">
                        <span>{{col.label}}</span>
                    </label>
                </div>
                <div class="row" *ngIf="hasMoreAvailableDescriptors">
                    <div [ngClass]="{'col-md-10' : assetOwnershipType.value !== '0', 'col-md-8' : assetOwnershipType.value === '0'}">
                        <ws-picklist [options]="additionalDescriptors"
                                     labelProperty="name"
                                     placeholder="Please select additional columns..."
                                     [canReorganize]="false"
                                     [returnEntireOption]="true"
                                     formControlName="includeAssetDescriptors">
                        </ws-picklist>
                    </div>
                </div>
            </form>
        </div>
        <div class="col col-md-3" *ngIf="assetOwnershipType.value === '-1' || assetOwnershipType.value === '1'">
            <form [formGroup]="((assetOwnershipType.value === '-1') ? leaseFromGroup : leaseToGroup)" class="report-form-group">
                <div class="row">
                    <span class="col-md-8 section-header">
                        Lease fields:
                    </span>
                </div>
                <div class="check-row" *ngFor="let col of ((assetOwnershipType.value === '-1') ? leaseFromCols : leaseToCols)">
                    <label class="ws-checkbox-label">
                        <input type="checkbox" [formControlName]="col.formControlName">
                        <span>{{col.label}}</span>
                    </label>
                </div>
            </form>
        </div>
    </div>
</div>
