(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('StyleTypographyController', StyleTypographyController);

	StyleTypographyController.$inject = [];

	function StyleTypographyController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
		var vm = this;
		vm.title = 'StyleTypographyController';

		activate();

		////////////////

		function activate() {}
	}
})();
