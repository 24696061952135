<div class="ws-flex-container-vertical ws-flex-auto ws-stretch allocation-details">
    <div class="ws-flex-container-horizontal ws-flex-none" style="justify-content: space-between;">
        <div class="ws-flex-container-horizontal ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="template?.name"></ws-bread-crumbs>
            <div class="edit-name"
                  helpTooltip
                  helpContentId="valuation-template.edit-template"
                  position="bottom">
                <button class="btn btn-link clickable" (click)="editTemplateName()">
                    <i class="fa fa-pencil"></i>
                </button>
            </div>
        </div>
        <div class="switch-panel" style="align-self: flex-end;" *ngIf="template">
            <div class="ws-flex-container-horizontal ws-flex-align-items-center" title="When enabled, this template will not be available to the valuation tool.">
                <label class="switch-label" for="template-in-development" style="margin-right: 5px;">In Development:</label>
                <ws-switch id="template-in-development"
                           [(ngModel)]="template.isInDevelopment"
                           (selectedOptionChanged)="onIsInDevelopmentChange($event)">
                </ws-switch>
            </div>
        </div>
    </div>

    <ng-container *ngIf="template">

        <div class="ws-flex-container-horizontal"
             [ngClass]="areHidden(['valuation-site-class-list', 'valuation-template-upload']) ? 'ws-flex-auto' : 'template-page-row ws-flex-none'"
             [hidden]="areHidden(['valuation-states-list', 'valuation-assessors-list'])">
            <div class="ws-flex-container-vertical valuation-flex-column" [hidden]="isHidden('valuation-states-list')">
                <valuation-states-list class="valuation-panel"></valuation-states-list>
            </div>
            <div class="ws-flex-container-vertical valuation-flex-column" [hidden]="isHidden('valuation-assessors-list')">
                <valuation-assessors-list class="valuation-panel"></valuation-assessors-list>
            </div>
        </div>

        <div class="ws-flex-container-horizontal"
             [ngClass]="areHidden(['valuation-states-list', 'valuation-assessors-list']) ? 'ws-flex-auto' : 'template-page-row ws-flex-none'"
             [hidden]="areHidden(['valuation-site-class-list', 'valuation-template-upload'])">
            <div class="ws-flex-container-vertical valuation-flex-column" [hidden]="isHidden('valuation-site-class-list')">
                <valuation-site-class-list class="valuation-panel"></valuation-site-class-list>
            </div>
            <div class="ws-flex-container-vertical valuation-flex-column" [hidden]="isHidden('valuation-template-upload')">
                <valuation-template-upload class="valuation-panel"></valuation-template-upload>
            </div>
        </div>

    </ng-container>
</div>
