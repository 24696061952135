<ws-modal-wrapper
    headerLabel="Asset import in progress"
    headerIcon="fa fa-exclamation-circle"
    [hideCancel]="true"
    (save)="close()"
    (cancel)="close()"
    acceptLabel="Continue">
    <div class="asset-import-progress" tabindex="0">
        <div>The system is still importing assets.</div>
        <div class="progress-label">Progress</div>
        <div class="progress-container active">
            <div class="progress-indicator" [ngStyle]="{ 'width': progressPercentage + '%' }"></div>
        </div>
        <div class="progress-details-container">
            <span>{{progressPercentage}}% Done</span>
            <span>Time Remaining: {{activeImportTimeRemaining}}</span>
        </div>
    </div>
</ws-modal-wrapper>
