<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Tax Authorities"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <ws-ag-grid-angular
        [gridOptions]="gridOptions"
        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
