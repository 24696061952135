<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Copy Tax Year"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isSaveEnabled">
    <form #form="ngForm">
        <div class="mb-4">
            <label for="targetFormRevisionYear">Target Form Revisions <i title="The form revision to copy to" class="fa fa-info-circle"></i></label>
            <select *ngIf="formRevisionYears?.length" name="targetFormRevisionYear" class="form-select form-select-sm" [(ngModel)]="selectedFormRevisionName">
                <option *ngFor="let formRevisionYearName of allAvailableFormRevisionNames" [ngValue]="formRevisionYearName">{{ formRevisionYearName }}</option>
            </select>
            <label for="targetFormRevisionYearList">Year <i title="The form revision year to copy to" class="fa fa-info-circle"></i></label>
            <select *ngIf="formRevisionYears?.length" name="targetFormRevisionYearList" class="form-select form-select-sm" [(ngModel)]="selectedFormRevisionTaxYear">
                <option *ngFor="let formRevisionTaxYear of allAvailableTaxYearsForFormRevision" [ngValue]="formRevisionTaxYear">{{ formRevisionTaxYear }}</option>
            </select>
            <div class="small">
                <span>Forms certified for one or more assessors and forms that are included in a return batch may not be targeted.</span>
            </div>
            <div *ngIf="busyIndicatorMessageManager.count() === 0 && !formRevisionYears?.length">
                <span class="text-warning">There are no available form revisions to copy to.</span>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
