import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldOverrideHolder } from '../../../../../../Common/PDFViewer/pdfViewerBase';
import { FieldOverrideWithRowVersion } from '../../returnPreview.service';

import { reduce, map } from 'lodash/fp';
import * as _ from 'lodash';

interface UnsavedOverride extends FieldOverrideWithRowVersion {
    index: number;
    isExisting: boolean;
}

@Component({
    selector: 'override-changes',
    templateUrl: './returnPreviewOverrideChanges.component.html',
    styleUrls: ['./returnPreviewOverrideChanges.component.scss']
})
export class ReturnPreviewOverrideChangesComponent {
    @Input() overrideHolder: FieldOverrideHolder;
    @Input() canPreview: boolean;
    @Input() set unsavedOverrides(overrides: FieldOverrideWithRowVersion[]) {
        if (overrides && overrides.length) {
            this._setUnsavedOverrides(overrides);
        } else {
            this.unsaved = [];
        }
    };

    @Output() scrollToElement: EventEmitter<Element> = new EventEmitter<Element>();
    @Output() saveClicked: EventEmitter<Element> = new EventEmitter<Element>();
    @Output() resetClicked: EventEmitter<Element> = new EventEmitter<Element>();

    unsaved: UnsavedOverride[] = [];

    private _setUnsavedOverrides(newOverrides: FieldOverrideWithRowVersion[]): void {
        const overrides = this.overrideHolder.overrides;
        const split = newOverrides.reduce((acc, x) => {
            const savedIndex = overrides.findIndex(y => y.fieldName === x.fieldName);
            if (savedIndex > -1) {
                acc.exists.push({ ...x, index: savedIndex, isExisting: true });
            } else {
                acc.newChanges.push(x);
            }
            return acc;
        }, { exists: [], newChanges: [] } as { exists: UnsavedOverride[], newChanges: FieldOverrideWithRowVersion[]});

        this.unsaved = _.flow([
            reduce((acc, x) => {
                acc.splice(x.index, 0, x);
                return acc;
            }, split.newChanges),
            map.convert({cap: false})((x, i) => ({ ...x, index: (x.index >= 0 ? x.index : i) + 1 }))
        ])(split.exists);
    }
}
