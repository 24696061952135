import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';
import { LongRunningProcessRepository } from '../../Repositories';

export class LongRunningProcessListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        readonly gridApi: GridApi,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository
    ) { super(gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.LongRunningProcessPropertyEnum>[] = [
        { name: 'longRunningProcessId', value: Compliance.LongRunningProcessPropertyEnum.LongRunningProcessId },
        { name: 'entityId', value: Compliance.LongRunningProcessPropertyEnum.EntityId },
        { name: 'hostName', value: Compliance.LongRunningProcessPropertyEnum.HostName },
        { name: 'operationName', value: Compliance.LongRunningProcessPropertyEnum.OperationName },
        { name: 'operationDisplayName', value: Compliance.LongRunningProcessPropertyEnum.OperationDisplayName },
        { name: 'longRunningProcessQueueTypeName', value: Compliance.LongRunningProcessPropertyEnum.LongRunningProcessQueueTypeName },
        { name: 'longRunningProcessTypeName', value: Compliance.LongRunningProcessPropertyEnum.LongRunningProcessTypeName },
        { name: 'createDate', value: Compliance.LongRunningProcessPropertyEnum.CreateDate },
        { name: 'changeDate', value: Compliance.LongRunningProcessPropertyEnum.ChangeDate },
        { name: 'start', value: Compliance.LongRunningProcessPropertyEnum.Start },
        { name: 'end', value: Compliance.LongRunningProcessPropertyEnum.End },
        { name: 'totalMs', value: Compliance.LongRunningProcessPropertyEnum.TotalMs },
        { name: 'queueMs', value: Compliance.LongRunningProcessPropertyEnum.QueueMs },
        { name: 'msPerRow', value: Compliance.LongRunningProcessPropertyEnum.MsPerRow },
        { name: 'error', value: Compliance.LongRunningProcessPropertyEnum.Error },
        { name: 'errorGuid', value: Compliance.LongRunningProcessPropertyEnum.ErrorGuid },
        { name: 'totalRows', value: Compliance.LongRunningProcessPropertyEnum.TotalRows },
        { name: 'statusCode', value: Compliance.LongRunningProcessPropertyEnum.StatusCode },
        { name: 'isCanceled', value: Compliance.LongRunningProcessPropertyEnum.IsCanceled },
        { name: 'isPaused', value: Compliance.LongRunningProcessPropertyEnum.IsPaused },
        { name: 'isAcknowledged', value: Compliance.LongRunningProcessPropertyEnum.IsAcknowledged },
        { name: 'createdByName', value: Compliance.LongRunningProcessPropertyEnum.CreatedByName },
        { name: 'instanceName', value: Compliance.LongRunningProcessPropertyEnum.InstanceName }
    ];

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.LongRunningProcessSearchModel = {
            notAcknowledged: false,
            allUsers: true,
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._longRunningProcessRepository.getList(searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<Compliance.LongRunningProcessModel>> {
        const searchParams: Compliance.LongRunningProcessSearchModel = {
            notAcknowledged: false,
            allUsers: true,
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        return await lastValueFrom(this._longRunningProcessRepository.getList(searchParams));
    }

    getSearchParamsWithoutPagination() : Compliance.LongRunningProcessSearchModel{
        this.refreshDataSourceParams();
        return {
            notAcknowledged: false,
            allUsers: true,
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };
    }
}
