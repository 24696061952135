import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DataWidgetI, WidgetCache } from "../../dashboard.model";
import { Observable } from "rxjs";
import { Shortcut } from "./shortcut.widget.model";
import * as _ from 'lodash';
import { UpgradeNavigationServiceHandler } from "../../../Common/Routing/upgrade-navigation-handler.service";
import { ActionViewPersistenceService } from "../../../Task/ActionView/Action.View.Persistence.Service.upgrade";
import { AdvancedSearchPersistenceService } from "../../../Search/Advanced/advancedSearchPersistence.service";
import { ProductAnalyticsService } from "../../../Common/Amplitude/productAnalytics.service";

@Component({
    selector: 'shortcut-widget',
    template: `
        <h2 class="text-center" style="font-size: 1.5vw; word-break: break-word;">
            <span *ngIf="!title">Shortcuts</span>
            <span *ngIf="title">{{title}}</span>
        </h2>
        <div style="padding: 0 1.5vw; font-size: 1vw;">
            <div *ngIf="groupByType">
                <div class="panel-flat-color small-panel" *ngFor="let group of groupedShortcuts">
                    <div class="panel-flat-header small-header" style="height: initial;">
                        <h3 style="font-size: 1.3vw;">{{group.category}}</h3>
                    </div>
                    <div>
                        <div *ngFor="let shortcut of group.shortcuts" style="padding-left: 10px;">
                            <a style="cursor: pointer;" (click)="goToShortcut(shortcut)">{{shortcut.searchName}} <span *ngIf="shortcut.variantName">- {{shortcut.variantName}}</span></a>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!groupByType">
                <div *ngFor="let shortcut of shortcuts">
                    <a style="cursor: pointer;" (click)="goToShortcut(shortcut)">{{shortcut.searchName}} <span *ngIf="shortcut.variantName">- {{shortcut.variantName}}</span></a>
                </div>
            </div>
        </div>
    `
})
export class ShortcutWidgetComponent implements OnInit, DataWidgetI {
    constructor(
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _actionViewPersistenceService: ActionViewPersistenceService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @Input() data$: Observable<any>;
    @Output() onNewParams: EventEmitter<object> = new EventEmitter();

    groupByType: boolean = false;
    shortcuts: Shortcut[];
    groupedShortcuts: {category: string, shortcuts: Shortcut[]}[];
    title: string;

    ngOnInit() {
        this.data$.subscribe((widgetCache: WidgetCache) => {
           this.groupByType = widgetCache.data.groupByType;
           this.title = widgetCache.data.title;

           if(this.groupByType) {
               this.groupedShortcuts = _.chain(widgetCache.data.shortcuts)
                   .groupBy('searchType')
                   .map((shortcuts: Shortcut[], searchType: string) => {
                        return {
                            category: this._getCategory(Number(searchType)),
                            shortcuts: _.sortBy(shortcuts, [(item: Shortcut) => item.searchName.toUpperCase()])
                        };
                   })
                   .value();
           } else {
               this.shortcuts = _.sortBy(widgetCache.data.shortcuts, [(item: Shortcut) => item.searchName.toUpperCase()]);
           }
        })
    }

    _getCategory(searchType: number): string {
        switch(searchType) {
            case 0:
                return 'SMART Searches';
            case 1:
                return 'Action Views';
            case 2:
                return 'Reports';
        }
    }

    goToShortcut(shortcut: Shortcut): void {
        let route: string,
            params: object,
            analyticsProperty: string;

        if(shortcut.searchType === 0) {
            route = 'search';
            params = {
                searchId: shortcut.savedSearchId,
                searchType: shortcut.isSystemSearch ? 0 : 1
            }
            analyticsProperty = 'smart';

            this._advancedSearchPersistenceService.clearSearchData();
        } else if(shortcut.searchType === 1) {
            route = 'actionview';
            params = {
                actionViewId: shortcut.savedSearchId,
                actionViewType: shortcut.isSystemSearch ? 0 : 1,
                displayType: shortcut.defaultDisplay
            }
            analyticsProperty = 'actionView';

            this._actionViewPersistenceService.clearSavedData();
        } else if(shortcut.searchType === 2) {
            route = 'reportmanager';
            params = {
                reportId: shortcut.savedSearchId,
                reportType: shortcut.isSystemSearch ? 0 : 1
            }
            analyticsProperty = 'reports';
        }

        this._productAnalyticsService.logEvent('click-shortcut-widget', {
            [analyticsProperty]: (shortcut.variantName) ? `${shortcut.searchName} - ${shortcut.variantName}` : shortcut.searchName
        });

        this._upgradeNavigationServiceHandler.go(route, params)
    }
}
