import { from as observableFrom, lastValueFrom, Observable } from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReturnService } from '../../return.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { TaskTypes, TaskProcessingActions } from '../../Models/enums';
import * as _ from 'lodash';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ExtendedUserTeamModel } from '../../Models/extendedContactModel';
import { ReturnRepository } from '../../../Repositories';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { ProductAnalyticsService } from "../../../../Common/Amplitude/productAnalytics.service";

interface TaskMapping {
    rescindLevels: TaskTypes[];
    rescindAction: () => Promise<void>;
    reassignAction: () => Promise<void>;
    includeCompanyRepresentative: boolean;
}

interface TaskExceptionOption {
    taskId: number;
    taskTypeId: number;
    action: TaskProcessingActions;
    title: string;
    taskName: string;
    assignedUser: ExtendedUserTeamModel;
    includeCompanyRepresentative: boolean;
    isAuthorized?: boolean;
}

@Component({
    selector: 'return-task-exception-modal',
    templateUrl: './returnTaskExceptionModal.component.html'
})
export class ReturnTaskExceptionModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, boolean> {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _returnRepository: ReturnRepository,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    private _taskMap = new Map<TaskTypes, TaskMapping>(
        [
            [TaskTypes.PPBatchReturnLoadData, { rescindLevels: [], includeCompanyRepresentative: false, rescindAction: this._unlock.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnPrepareReturns, { rescindLevels: [TaskTypes.PPBatchReturnLoadData], includeCompanyRepresentative: false, rescindAction: this._unlock.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnReviewReturns, { rescindLevels: [TaskTypes.PPBatchReturnPrepareReturns], includeCompanyRepresentative: false, rescindAction: this._unsign.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnApproveReturns, { rescindLevels: [TaskTypes.PPBatchReturnReviewReturns], includeCompanyRepresentative: true, rescindAction: this._unsign.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnDigitiallySignReturns, { rescindLevels: [TaskTypes.PPBatchReturnReviewReturns, TaskTypes.PPBatchReturnPrepareReturns], includeCompanyRepresentative: false, rescindAction: this._unsign.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnFileReturns, { rescindLevels: [TaskTypes.PPBatchReturnDigitiallySignReturns, TaskTypes.PPBatchReturnApproveReturns, TaskTypes.PPBatchReturnReviewReturns, TaskTypes.PPBatchReturnPrepareReturns], includeCompanyRepresentative: false, rescindAction: this._unsign.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPBatchReturnFinalizeReturns, { rescindLevels: [TaskTypes.PPBatchReturnFileReturns], includeCompanyRepresentative: false, rescindAction: this._rescindToTask.bind(this), reassignAction: this._reassign.bind(this) }],
            [TaskTypes.PPReturnFiling, { rescindLevels: [], includeCompanyRepresentative: false, rescindAction: this._rescindToTask.bind(this), reassignAction: this._reassign.bind(this) }]
        ]
    );

    params: void;
    result: boolean;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    options: TaskExceptionOption[] = [];
    selectedOption: TaskExceptionOption;
    comment: string;
    selectedUserTeam: ExtendedUserTeamModel;
    userTeamFilter: string = '';
    userTeamsLoading: boolean = false;
    userTeams$: Observable<ExtendedUserTeamModel[]> = (Observable
        .create((observer: any) => { observer.next(this.userTeamFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterUserTeams(token)));

    async ngOnInit(): Promise<void> {

        const options: TaskExceptionOption[] = [];

        const currentTaskMapping = this._taskMap.get(this._returnService && this._returnService.currentTask.taskTypeID);

        if (currentTaskMapping) {
            options.push({
                action: TaskProcessingActions.ReassignTask,
                taskId: this._returnService.currentTask.taskID,
                title: `Reassign ${this._returnService.currentTask.taskType.name}`,
                taskName: this._returnService.currentTask.taskType.name,
                taskTypeId: this._returnService.currentTask.taskTypeID,
                assignedUser: null,
                includeCompanyRepresentative: currentTaskMapping.includeCompanyRepresentative,
                isAuthorized: this._returnService.currentTask && this._returnService.currentTask.taskAuthorizationInfo.reassignTask
            });

            _.forEach(currentTaskMapping.rescindLevels, (x) => {
                const task = _.find(this._returnService.tasks, y => y.taskTypeID === x as number);
                if (task) {
                    const userTeamModel: Core.UserTeamModel = _.extend(
                        {} as Core.UserTeamModel,
                        task.assignedUser,
                        {
                            teamID: task.assignedTeamID,
                            teamName: task.assignedTeam && task.assignedTeam.name
                        });

                    options.push({
                        action: TaskProcessingActions.RescindTask,
                        title: `Rescind to ${task.taskType.name}`,
                        taskName: task.taskType.name,
                        taskId: task.taskID,
                        taskTypeId: task.taskTypeID,
                        assignedUser: new ExtendedUserTeamModel(userTeamModel),
                        includeCompanyRepresentative: currentTaskMapping.includeCompanyRepresentative,
                        isAuthorized: this._returnService.priorCompletedTask && this._returnService.priorCompletedTask.taskAuthorizationInfo.rescindTask
                    });
                }
            });
        }

        this.options = options;
    }

    ngOnDestroy(): void {
    }

    onUserTeamSelected(match: TypeaheadMatch): void {
        this.selectedUserTeam = match.item as ExtendedUserTeamModel;
        this.userTeamFilter = match.value;
    }

    onUserTeamBlur(): void {
        if (!this.userTeamFilter || this.userTeamFilter.trim() === '') {
            this.selectedUserTeam = this.selectedOption && this.selectedOption.assignedUser;
            this.userTeamFilter = this.selectedOption && this.selectedOption.assignedUser && this.selectedOption.assignedUser.displayName;
        }
    }

    selectedOptionChanged(option: TaskExceptionOption): void {
        this.selectedUserTeam = option && option.assignedUser;
        this.userTeamFilter = option && option.assignedUser && option.assignedUser.displayName;

        if (this.selectedUserTeam == null) {
            this.userTeamFilter = '';
        }
    }

    onUserTeamsLoadingChange(isLoading: boolean): void {
        this.userTeamsLoading = isLoading;
        if (isLoading) {
            this.selectedUserTeam = null;
        }
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        const taskMapping = this._taskMap.get(this.selectedOption.taskTypeId);
        let message: string;
        let action: () => void;

        switch (this.selectedOption.action) {
            case TaskProcessingActions.RescindTask:
                action = taskMapping.rescindAction;
                message = 'Rescinding';
                this._productAnalyticsService.logEvent('click-exception-rescind-ok', {
                    rescindTask: this.selectedOption.taskName
                })
                break;
            case TaskProcessingActions.ReassignTask:
                action = taskMapping.reassignAction;
                message = 'Reassigning';
                break;
            default:
                message = 'Saving';
                action = () => { }
                break;
        }

        this.busyIndicatorMessageManager.add(message, busyMsg);

        try {
            await action();

            this.result = true;

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private async _rescindToTask(): Promise<void> {
        const taskUpdateModel: Compliance.ReturnTaskUpdateModel = {
            taskId: this.selectedOption.taskId,
            comment: this.comment,
            assignedUserId: this.selectedUserTeam && this.selectedUserTeam.model.userID,
            assignedTeamId: this.selectedUserTeam && this.selectedUserTeam.model.teamID,
        };

        await this._returnService.rescindToTask(taskUpdateModel);
    }

    private async _unlock(): Promise<void> {
        const taskUpdateModel: Compliance.ReturnTaskUpdateModel = {
            taskId: this.selectedOption.taskId,
            comment: this.comment,
            assignedUserId: this.selectedUserTeam && this.selectedUserTeam.model.userID,
            assignedTeamId: this.selectedUserTeam && this.selectedUserTeam.model.teamID
        };

        if (this._returnService.isLocked || this._returnService.isSigned) {
            const unlockRequestModel: Compliance.ReturnUnlockRequestModel = {
                filingBatchId: this._returnService.filingBatchId,
                companyId: this._returnService.companyId,
                returnTaskUpdate: taskUpdateModel
            };

            await this._returnService.startUnlocking(unlockRequestModel);
            this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Unlocking);
        } else {
            await this._returnService.rescindToTask(taskUpdateModel);
        }
    }

    private async _unsign(): Promise<void> {
        const taskUpdateModel:  Compliance.ReturnTaskUpdateModel = {
            taskId: this.selectedOption.taskId,
            comment: this.comment,
            assignedUserId: this.selectedUserTeam && this.selectedUserTeam.model.userID,
            assignedTeamId: this.selectedUserTeam && this.selectedUserTeam.model.teamID
        };

        if (this._returnService.isSigned) {
            const unsignRequestModel: Compliance.ReturnUnsignRequestModel = {
                filingBatchId: this._returnService.filingBatchId,
                companyId: this._returnService.companyId,
                returnTaskUpdate: taskUpdateModel
            };

            await this._returnService.startUnsigning(unsignRequestModel);
            this._returnService.notifyProcessStatusChange(Compliance.FilingBatchProcessStatusEnum.Unsigning);
        } else {
            await this._returnService.rescindToTask(taskUpdateModel);
        }
    }

    private async _reassign(): Promise<void> {
        const taskUpdateModel: Compliance.ReturnTaskUpdateModel = {
            taskId: this.selectedOption.taskId,
            comment: this.comment,
            assignedUserId: this.selectedUserTeam && this.selectedUserTeam.model.userID,
            assignedTeamId: this.selectedUserTeam && this.selectedUserTeam.model.teamID
        };

        await this._returnService.reassignTask(taskUpdateModel);
    }

    private _filterUserTeams(filter: string): Observable<ExtendedUserTeamModel[]> {
        const searchModel: Compliance.ReturnTaskUserTeamSearchModel = {
            filter: filter,
            pageSize: 50,
            includeCompanyRepresentative: this.selectedOption && this.selectedOption.includeCompanyRepresentative
        };

        return observableFrom(lastValueFrom(this._returnRepository.getTaskUserTeams(
            this._returnService.filingBatchId,
            searchModel))).pipe(
            map(userTeams => {
                return userTeams.map(userTeam => {
                    return new ExtendedUserTeamModel(userTeam);
                })
            }));
    }
}
