import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForAllocationDetailListGridAction extends ICellRendererParams {
    canView: (params: ICellRendererParamsForAllocationDetailListGridAction) => boolean;
    viewDetails: (params: ICellRendererParamsForAllocationDetailListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button *ngIf="allocationDetail && canView" class="btn flat-button primary-button icon-button grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>`
})

export class AllocationDetailListGridActionCellRendererComponent implements ICellRendererAngularComp {
    constructor() { }

    private _params: ICellRendererParamsForAllocationDetailListGridAction;

    allocationDetail: Compliance.AllocationDetailModel;
    canView: boolean = false;

    agInit(params: ICellRendererParamsForAllocationDetailListGridAction): void {
        this._params = params;
        this.allocationDetail = params.data as Compliance.AllocationDetailModel;
        this.canView = params.canView(params);

        if (!this.allocationDetail) {
            return;
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
