<div class="banner"
     [ngClass]="{ 'closing': closing, 'narrow': isNarrow }"
     tabindex="0">
    <div *ngIf="displayIcon" class="icon-con">
        <ws-ace-icon [glyph]="displayIcon"></ws-ace-icon>
    </div>
    <div class="content" [class.dismissable]="enableDismiss">
        <h3 *ngIf="header">{{header}}</h3>
        <span *ngIf="content && !contentIsComponent">{{content}}</span>
        <ng-container *ngIf="content && contentIsComponent" #contentComponent></ng-container>
    </div>
    <button *ngIf="enableDismiss"
        class="close"
        type="button"
        (click)="handleClose()">
        <ws-ace-icon glyph="times"></ws-ace-icon>
    </button>
</div>
