import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { reduce, startsWith } from 'lodash';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from 'src/app/Compliance/AgGrid';
import { WeissmanDateFormat } from 'src/app/UI-Lib/Pipes/Date-Format/date-formatting.pipe';

interface PaymentModelUI extends Core.PaymentBatchCheckImportPaymentModel {
    isTotal?: boolean;
}

@Component({
    selector: 'finalize-check-import-payment-grid',
    template: `
        <h3>Payments</h3>
        <ws-ag-grid-angular class="ag-theme-balham grid grid-cell-no-focus"
                            [gridOptions]="gridOptions"
                            [forceShowToolPanel]="true"
                            [showConfigureLayout]="false"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    `
})

export class FinalizeCheckImportPaymentGridComponent {
    constructor(private readonly _currencyPipe: CurrencyPipe) { }

    @Input() checkImportPayments: PaymentModelUI[];

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'row-matched': (params) => {
                const gridRow = params.data as PaymentModelUI;
                return !!gridRow.checkNo && (!gridRow.isTotal || startsWith(gridRow.collector, 'Matched'));
            },
            'totals-row': (params) => {
                const gridRow = params.data as PaymentModelUI;
                return gridRow.isTotal;
            }
        },
        suppressScrollOnNewData: true
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    refreshing: boolean;

    private _gridApi: GridApi;

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Payee',
                field: 'collector',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellStyle: params => {
                    const row = params.data as PaymentModelUI;
                    return row.isTotal ? {textAlign: 'right', fontWeight: 'bold'} : null;
                }
            },
            {
                headerName: 'State',
                field: 'state',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.stateAbbreviationColumnWidth
            },
            {
                headerName: 'Parcel Acct Num',
                field: 'parcelAcctNum',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Payment Amount',
                field: 'paymentAmount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._formatCurrency(x.value),
                cellClass: 'text-align-right',
            },
            {
                headerName: 'Payment Due Date',
                field: 'dueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as PaymentModelUI;
                    if (!(model && model.dueDate)) {
                        return '';
                    }

                    return WeissmanDateFormat(model.dueDate, true, 'stored');
                },
            },
            {
                headerName: 'Check No',
                field: 'checkNo',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            },
            {
                headerName: 'Check Date',
                field: 'checkDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as PaymentModelUI;
                    if (!(model && model.checkDate)) {
                        return '';
                    }

                    return WeissmanDateFormat(model.checkDate, true, 'stored');
                },
            },
        ];

        const defaultSortModel = [
            {
                colId: 'collector',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();

        this.setRowData();
    }

    setRowData() {
        if (!(this._gridApi && this.checkImportPayments)) {
            return;
        }

        this._gridApi.setRowData(this.checkImportPayments);

        const totalRows = [];
        const matchedPayments = this.checkImportPayments.filter(x => !!x.checkNo);

        if(matchedPayments.length) {
            const matchedRow = this._getTotalRow(matchedPayments, true);
            totalRows.push(matchedRow);
        }

        const totalRow = this._getTotalRow(this.checkImportPayments);
        totalRows.push(totalRow);

        this._gridApi.setPinnedBottomRowData(totalRows);
    }

    private _formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this._currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    private _getTotalRow(payments: PaymentModelUI[], justMatched: boolean = false) {
        const paymentAmount = reduce(payments, (sum, payment) => {
            sum += payment.paymentAmount;
            return sum;
        }, 0);

        const totalLabel = justMatched ? 'Matched' : 'Total';

        return {
            isTotal: true,
            paymentAmount,
            collector: `${totalLabel}: ${payments.length}`
        };
    }
}