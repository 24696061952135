export type Constructor<T = {}> = new (...args: any[]) => T;

// Exported for use in AngularJS components that can't use mixin
export const getYearPickerTaxYears = () => {
    const year = new Date().getFullYear();
    const years: number[] = [];
    for (let i = (year + 5); i >= (year - 10); i--) {
        years.push(i);
    }
    return years;
};

/**
 * Mixin that provides a common way to get a defined set of tax years for a year dropdown
 * 
 * @param base
 */
export const YearPickerMixin = <T extends Constructor>(base: any = class { } as T) => {
    return class extends base {
        constructor(...args) {
            super(...args);
        }

        public readonly taxYears: number[] = getYearPickerTaxYears();
    };
};
