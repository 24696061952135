import { Injectable } from '@angular/core';
import { AssessorCommandCenterRepository } from '../Repositories';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

declare var moment: any;

export enum GridSourceOptionEnum {
    Assessor
};

export interface FilterTimeFrame {
    date: Date;
    direction: string;
}

export interface FilterVerification {
    field: string;
    value: string;
    property: Compliance.AssessorCommandCenterModelPropertyEnum;
}

export interface FilterModel {
    recentChanges: FilterTimeFrame;
    verification: FilterVerification;
}

@Injectable({
    providedIn: 'root'
})
export class StateJurisdictionCommandCenterService {
    constructor(private readonly _assessorRepo: AssessorCommandCenterRepository) { }

    private readonly _GRID_SOURCE_OPTION_ASSESSOR: Compliance.NameValuePair<GridSourceOptionEnum> = {
        name: 'Assessors',
        value: GridSourceOptionEnum.Assessor
    };
    private _gridSourceOptions: Compliance.NameValuePair<GridSourceOptionEnum>[] = [
        this._GRID_SOURCE_OPTION_ASSESSOR
    ];

    private _highlightDates: Compliance.NameValuePair<FilterTimeFrame>[] = [
        { name: 'More than 12 months ago', value: { date: this._getDate(12, 0), direction: 'gt' } },
        { name: 'More than 6 months ago', value: { date: this._getDate(6, 0), direction: 'gt' } },
        { name: 'More than 30 days ago', value: { date: this._getDate(0, 30), direction: 'gt' } },
        { name: 'More than 7 days ago', value: { date: this._getDate(0, 7), direction: 'gt' } },
        { name: 'Within the last 7 days', value: { date: this._getDate(0, 7), direction: 'lt' } },
        { name: 'Within the last 30 days', value: { date: this._getDate(0,30), direction: 'lt' } },
        { name: 'Within the last 6 months', value: { date: this._getDate(6, 0), direction: 'lt' } },
        { name: 'Within the last year', value: { date: this._getDate(12, 0), direction: 'lt' } }
    ];

    private _verificationOptions: Compliance.NameValuePair<FilterVerification>[] = [
        {
            name: 'Pending QC', value: {
                field: 'isPendingQC',
                value: 'true',
                property: Compliance.AssessorCommandCenterModelPropertyEnum.IsPendingQC
            }
        }
    ];

    private _currentFilterModel: FilterVerification;
    private _highlightChanges: FilterTimeFrame;
    private _states: Core.StateModel[];
    private _descriptorInfo: Core.DescriptorMetadataModel;

    private _selectedGridSourceOptionSubject: BehaviorSubject<Compliance.NameValuePair<GridSourceOptionEnum>> = new BehaviorSubject(this._GRID_SOURCE_OPTION_ASSESSOR);
    private _currentSearchModelSubject: BehaviorSubject<Compliance.AssessorCommandCenterSearchModel> = new BehaviorSubject(null);
    private _currentFilterModelSubject: Subject<FilterVerification> = new Subject();
    private _highlightChangesSubject: BehaviorSubject<FilterTimeFrame> = new BehaviorSubject(null);
    private _updatingValueSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    selectedGridSourceOption$: Observable<Compliance.NameValuePair<GridSourceOptionEnum>> = this._selectedGridSourceOptionSubject.asObservable();
    currentSearchModel$: Observable<Compliance.AssessorCommandCenterSearchModel> = this._currentSearchModelSubject.asObservable();
    currentFilterModel$: Observable<FilterVerification> = this._currentFilterModelSubject.asObservable();
    highlightChanges$: Observable<FilterTimeFrame> = this._highlightChangesSubject.asObservable();
    updatingValue$: Observable<boolean> = this._updatingValueSubject.asObservable();

    get editGroup(): string {
        return 'state-jurisdiction-edit-group';
    }

    get currentSearchModel(): Compliance.AssessorCommandCenterSearchModel {
        return this._currentSearchModelSubject.value;
    }

    get currentFilterModel(): FilterVerification {
        return this._currentFilterModel;
    }

    get highlightChangesModel(): FilterTimeFrame {
        return this._highlightChanges;
    }

    get highlightDates(): Compliance.NameValuePair<FilterTimeFrame>[] {
        return this._highlightDates;
    }

    get verificationOptions(): Compliance.NameValuePair<FilterVerification>[] {
        return this._verificationOptions;
    }

    get gridSourceOptions(): Compliance.NameValuePair<GridSourceOptionEnum>[] {
        return this._gridSourceOptions;
    }

    get canGetRows(): boolean {
        const model = this.currentSearchModel;
        return !!(model && (model.stateId >= 0 || model.stateId === null) && model.taxYear);
    }

    get states(): Core.StateModel[] {
        return this._states;
    }

    get descriptorInfo(): Core.DescriptorMetadataModel {
        return this._descriptorInfo;
    }

    async start(): Promise<void> {
        this._descriptorInfo = await lastValueFrom(this._assessorRepo.getDescriptorInfo());
        this._states = await lastValueFrom(this._assessorRepo.getStates());
    }

    setSelectedGridSourceOption(gso: Compliance.NameValuePair<GridSourceOptionEnum>): void {
        this._selectedGridSourceOptionSubject.next(gso);
    }

    async updateDeadline(updateModel: Compliance.AssessorCommandCenterDeadlineUpdateModel): Promise<Compliance.AssessorCommandCenterModel> {
        return await lastValueFrom(this._assessorRepo.updateDeadline(updateModel));
    }

    async updateDescriptor(updateModel: Compliance.AssessorCommandCenterDescriptorUpdateModel): Promise<Compliance.AssessorCommandCenterModel> {
        return await lastValueFrom(this._assessorRepo.updateDescriptor(updateModel));
    }

    async updateDefaultAddressType(updateModel: Compliance.AssessorCommandCenterDefaultAddressUpdateModel): Promise<Compliance.AssessorCommandCenterModel> {
        return await lastValueFrom(this._assessorRepo.updateDefaultAddressType(updateModel));
    }

    async getAssessors(searchModel: Compliance.AssessorCommandCenterSearchModel): Promise<Compliance.QueryResultModel<Compliance.AssessorCommandCenterModel>> {
        return await lastValueFrom(this._assessorRepo.getList(searchModel));
    }

    async exportList(model: Compliance.AssessorCommandCenterExportModel): Promise<any> {
        return await lastValueFrom(this._assessorRepo.exportList(model));
    }

    async getList(longRunningProcessId: number): Promise<HttpResponse<Blob>> {
        return await lastValueFrom(this._assessorRepo.getExport(longRunningProcessId));
    }

    async getRowIds(searchModel: any): Promise<Compliance.QueryResultModel<number>> {
        return await lastValueFrom(this._assessorRepo.getRowIds(searchModel));
    }

    async bulkUpdateList(updateModel: Compliance.AssessorCommandCenterBulkUpdateModel): Promise<Compliance.AssessorCommandCenterBulkUpdateResultModel> {
        return await lastValueFrom(this._assessorRepo.bulkUpdateList(updateModel));
    }

    formChanged(searchModel: Compliance.AssessorCommandCenterSearchModel): void {
        this._currentSearchModelSubject.next(searchModel);
    }

    filtersChanged(value: FilterVerification): void {
        this._currentFilterModel = value;
        this._currentFilterModelSubject.next(value);
    }

    highlightChanged(value: FilterTimeFrame): void {
        this._highlightChanges = value;
        this._highlightChangesSubject.next(value);
    }

    loading(loading: boolean): void {
        this._updatingValueSubject.next(loading);
    }

    private _getDate(months: number, days: number): Date {
        const d = new Date();
        d.setMonth(d.getMonth() - months);
        d.setDate(d.getDate() - days);
        return moment.tz({ y: d.getFullYear(), M: d.getMonth(), d: d.getDate(), h: 0, m: 0 }, 'UTC')
            .toDate();
    }
}
