import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PermissionWithOptions } from './instancePermissionPanel.component';

export interface ICellRendererParamsForInstanceRightGridAction extends ICellRendererParams {
    canEdit: () => boolean;
    permissionChanged: () => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <span *ngIf="!params.canEdit()">{{params.data.right.rights}}</span>
    <select class="form-select-sm" *ngIf="params.canEdit()"
    [(ngModel)]="params.data.right" (change)="params.permissionChanged()">
        <option *ngFor="let right of params.data.rightOptions" [ngValue]="right">{{right.rights}}</option>
    </select>
    `
})
export class InstanceRightGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForInstanceRightGridAction;
    rightOptions: PermissionWithOptions[];
    right: PermissionWithOptions;

    agInit(params: ICellRendererParamsForInstanceRightGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
