import {
    AceUIIcon,
    IAuthMenuAction,
    IMenuAction,
    IPortalBrandingConfig,
    IPortalProfile,
    ISlotMenuAction
} from '@ace/shared';
import { IAuthChildMenuAction } from '@ace/shared/src/portal/autoSyncService';
import { ComponentType } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../Account/user.service';
import { ProgressIndicator, SnackBarService } from '../../Busy-Indicator';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { ContactModalOrigin } from '../../constants.new';
import { ContactModalService } from '../../Contact/contactModal.service';
import { AppStates, AppStateService } from '../../Layout/appStateService';
import { NavigationService } from '../../Layout/navigation.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { BannerMessage, branding, TopNavService } from './topNav.service';

const defaultAccountName = 'Tax.com Account';

interface IPPMenuAction extends IAuthChildMenuAction {
    amplitudeProperty?: {
        event: string;
        properties?: any;
    };
}

interface IPPAuthMenuAction extends IAuthMenuAction {
    children: IPPMenuAction[];
}

@Component({
    selector: 'top-nav',
    templateUrl: './topNav.component.html',
    styleUrls: ['./topNav.component.scss']
})
export class TopNavComponent implements OnDestroy, OnInit {
    constructor(private readonly _router: UIRouter,
                private readonly _appStateService: AppStateService,
                private readonly _userService: UserService,
                private readonly _navigationService: NavigationService,
                private readonly _topNavService: TopNavService,
                private readonly _snackBarService: SnackBarService,
                private readonly _userInstanceService: UserInstanceService,
                private readonly _featureFlagsService: FeatureFlagsService,
                private readonly _contactModalService: ContactModalService,
                private readonly _productAnalyticsService: ProductAnalyticsService) {
    }

    currentAction: string | undefined;
    isLoggedIn: boolean;
    sideNavOpen: boolean;
    showBanner: boolean;
    bannerMessage: BannerMessage<string | ComponentType<any>>;

    branding: IPortalBrandingConfig = branding;

    chromeActions: (IMenuAction | IAuthMenuAction | ISlotMenuAction)[] = [];

    private _helpMenuOption: IMenuAction = {
        key: 'profile-help',
        icon: AceUIIcon.Question,
        info: 'Help',
        content: null,
        children: [
            {
                isHeading: true,
                key: 'support-heading',
                content: 'Support'
            },
            { key: 'help', content: 'Help Center', routerOutlet: 'help' }
            // { key: 'contact', content: 'Contact Us', routerOutlet: 'contact' },
            // { key: 'support', content: 'Support', routerOutlet: 'feedback' }
        ]
    };

    private _searchMenuOption: IMenuAction = {
        key: 'quick-search',
        icon: AceUIIcon.Search,
        info: 'Search',
        content: null
    };

    private _snackMenuOption: IMenuAction = {
        key: 'snack-bar',
        icon: AceUIIcon.SuccessOutline,
        info: 'Long Running Processes',
        content: null
    };

    private _isAttachmentPopup: boolean;
    private _profileMenu: IPPAuthMenuAction | IMenuAction;
    private _destroy$: Subject<void> = new Subject<void>();
    private _destroyRouteListener: Function;

    get showInstanceFilter(): boolean {
        return [this._userInstanceService.allInstancesItem].concat(this._userInstanceService.getUserInstanceMembership(false)).length > 2;
    }

    ngOnInit(): void {
        this._isAttachmentPopup = window.location.href.indexOf('#/attachmentPopup') >= 0;

        this._appStateService.appState$.subscribe(async (state) => {
            if (state === AppStates.LoggedIn && !this._isAttachmentPopup) {
                this._setLoggedInMenu();
                switch (this._userService.getUser().onboardingStatus) {
                    case Core.UserOnboardingStatusEnum.NoAction:
                        await this._topNavService.showOnboardingModal();
                        break;
                    case Core.UserOnboardingStatusEnum.Completed:
                        this._topNavService.showOnboardingBanner();
                        break;
                }
            } else {
                this._setLoggedOutMenu();
            }
        });

        this._topNavService.sideNavOpen$.pipe(takeUntil(this._destroy$)).subscribe(isOpen => {
            this.sideNavOpen = isOpen;
        });

        this._topNavService.bannerMessage$.pipe(takeUntil(this._destroy$)).subscribe(message => {
            this.bannerMessage = message;
            this.showBanner = true;
        });

        this._snackBarService.longRunningProcesses.pipe(takeUntil(this._destroy$)).subscribe(processes => {
            this._processLRP(processes);
        });

        this._destroyRouteListener = this._router.transitionService.onSuccess({}, (transition) => {
            const transitions = transition.entering();
            transitions.forEach(x => {
                if (x.name === 'admin' || x.name === 'diagnostic') {
                    this.currentAction = x.name;
                } else {
                    this.currentAction = null;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._destroyRouteListener();
    }

    handleActionTriggered(action: CustomEvent<IMenuAction | 'home'>) {
        if (action.detail === 'home') {
            if (!this._isAttachmentPopup) {
                this._productAnalyticsService.logEvent('click-logo');
                this._router.stateService.transitionTo(action.detail);
            }
        } else {
            switch (action.detail.key) {
                case 'quick-search':
                    this._productAnalyticsService.logEvent('initiate-quick-search', { initiateQuickSearch: 'header icon' });
                    this._navigationService.openQuickSearch(false);
                    break;
                case 'snack-bar':
                    this._snackBarService.showSnackBar();
                    break;
                case 'pp-account':
                    const user = this._userService.getUser();
                    const contactId = this._userInstanceService.isSingleInstanceSelected() ? this._userInstanceService.getInstanceContactId() : user.contactId;
                    this._productAnalyticsService.logEvent('click-manage-view-profile');
                    this._contactModalService.openContactDialog(contactId, ContactModalOrigin.ViewProfile);
                    break;
                default:
                    const menuAction: IPPMenuAction = action.detail;
                    if (menuAction.amplitudeProperty) {
                        this._productAnalyticsService.logEvent(menuAction.amplitudeProperty.event, menuAction.amplitudeProperty.properties);
                    }
                    if (menuAction.routerOutlet) {
                        this.routeTo(menuAction.routerOutlet);
                    }
                    if (menuAction.url) {
                        window.open(menuAction.url, '_blank').focus();
                    }
            }
        }
    }

    toggleSideNav(): void {
        this._topNavService.sideNavOpen = true;
    }

    dismissBanner(): void {
        this.showBanner = false;
        this.bannerMessage.onDismiss();
        this.bannerMessage = null;
    }

    routeTo(commands: string): void {
        this._router.stateService.transitionTo(commands);
    }

    private _setLoggedOutMenu(): void {
        this.chromeActions = [];
        this.isLoggedIn = false;
    }

    private _setLoggedInMenu(): void {
        const user = this._userService.getUser();
        const profile: IPortalProfile = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            sub: '',
            name: `${user.firstName} ${user.lastName}`
        };

        this._profileMenu = {
            key: 'auth',
            profile,
            info: profile.name,
            children: [
                {
                    key: 'header',
                    content: defaultAccountName,
                    isHeading: true
                },
                {
                    key: 'profile',
                    content: 'My Account',
                    profile
                },
                {
                    key: 'pp-account',
                    content: 'View Profile'
                },
                {
                    key: 'manage-account',
                    content: 'Manage Account',
                    url: `${this._featureFlagsService.taxDotComBase}/user-management/myaccount/`,
                    amplitudeProperty: {
                        event: 'PP-click-tax.com-menu-option',
                        properties: { menuOption: 'Manage Account' }
                    }
                },
                // TODO implement this once permissions are determined
                // {
                //     key: 'manage-organization',
                //     content: 'Manage Organization',
                //     url: `${this._featureFlagsService.taxDotComBase}/apps/`
                // },
                {
                    key: 'hr1',
                    content: 'hr1',
                    isDivider: true
                },
                {
                    key: 'logout',
                    content: 'Log Out',
                    routerOutlet: 'logout'
                }
            ]
        };

        this.chromeActions = [
            this._searchMenuOption,
            this._helpMenuOption,
            this._profileMenu
        ];
        this.isLoggedIn = true;
    }

    private _processLRP(processes: ProgressIndicator[]): void {
        if (processes.length) {
            const completeProcesses = processes.reduce((acc: number, p: ProgressIndicator) => (p.isComplete) ? (acc += 1) : acc, 0);
            const activeProcesses = processes.length - completeProcesses;

            if (activeProcesses) {
                this._snackMenuOption.info = `Long Running Processes (Active: ${activeProcesses}${completeProcesses ? `, Complete: ${completeProcesses}` : ''})`;
                this._snackMenuOption.icon = AceUIIcon.Spinner;
            } else {
                this._snackMenuOption.info = `Long Running Processes (Complete: ${completeProcesses})`;
                this._snackMenuOption.icon = AceUIIcon.SuccessOutline;
            }

            if (!this.chromeActions.includes(this._snackMenuOption)) {
                this.chromeActions.splice(2, 0, this._snackMenuOption);
            }
            this.chromeActions = [...this.chromeActions];
        } else {
            if (this.chromeActions.includes(this._snackMenuOption)) {
                this.chromeActions = this.chromeActions.filter(x => x.key !== 'snack-bar');
            }
        }
    }
}
