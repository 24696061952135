import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { ContactListPageComponent } from './Page/contactListPage.component';
import { ContactPanelUpgradeComponent } from './Panel/contact.panel.upgrade.component';
import { ContactPanelComponent } from './Panel/contactPanel.component';
import { ContactListComponent } from './Panel/List/contactList.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UserModule } from '../User/user.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { ContactsRepository } from '../Core-Repositories';
import { ContactPanelBodyActionsComponent } from './Panel/Body-Actions/panelBodyActions.component';
import { ContactListModalComponent } from './Panel/List/Associate-Contact/contactListModal.component';
import { EditRoleModalComponent } from './Panel/List/Edit-Role/editRoleModal.component';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        TabsModule,
        UserModule
    ],
    declarations: [
        ContactPanelComponent,
        ContactPanelUpgradeComponent,
        ContactListComponent,
        ContactListModalComponent,
        ContactListPageComponent,
        ContactPanelBodyActionsComponent,
        EditRoleModalComponent,
    ],
    providers: [
        ContactsRepository
    ],
    exports: [
        ContactPanelComponent,
        ContactListPageComponent
    ]
})
export class ContactModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('sdContactsPanel', ContactPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('sdEntityContactList', ContactListModalComponent);
        hybridAdapterUtility.downgradeNg2Component('contactListPage', ContactListPageComponent);
    }
}
