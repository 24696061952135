import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Filing, FilingType } from '../Compliance/compliance.model';
import { ComplianceService } from '../Compliance/compliance.service';

declare const _: any;

@Component({
    selector: 'add-filing-modal',
    templateUrl: './add-filing.component.html'
})
export class AddFilingModal {
    newFiling: Filing;
    @Input() filingTypes: FilingType[];
    @Input() annualYearID: number;
    @Input() isMergedParcel: boolean; 
    @Output('add') onAddFiling: EventEmitter<Filing>;
    @ViewChild('addFilingModal', { static: true }) addFilingModal: ModalDirective;

    filingTypesDropdown: FilingType[];
    creatingFiling: boolean = false;

    constructor(private complianceService: ComplianceService) {
        this.onAddFiling = new EventEmitter();
    }

    show(): void {
        this.addFilingModal.show();
    }

    showModalInit(): void {
        this.filingTypesDropdown = _.reject(this.filingTypes, x => x.filingTypeId == Core.FilingTypes.PPReturn && this.isMergedParcel)
        this.newFiling = new Filing();
        this.newFiling.annualYearID = this.annualYearID;
        this.newFiling.filingTypeId = this.filingTypesDropdown[0].filingTypeId;
    }

    save(): void {
        this.creatingFiling = true;
        this.complianceService.createFiling(this.newFiling)
            .then((filing) => {
                filing.filingType = _.find(this.filingTypesDropdown, { filingTypeId: filing.filingTypeId });
                this.onAddFiling.emit(filing);
                this.addFilingModal.hide();
            })
            .finally(() => {
                this.creatingFiling = false;
            })
    };
}
