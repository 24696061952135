import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
    selector: 'site-descriptor-selector',
    templateUrl: './siteDescriptorSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SiteDescriptorSelectorComponent,
        multi: true
    }]
})
export class SiteDescriptorSelectorComponent implements OnInit, ControlValueAccessor{
    private _onChangeFn: Function;
    private _onTouchedFn: Function;
    private _descriptorNoResult: boolean = false;

    @Input() descriptors: Compliance.NameValuePair<number>[];
    @Input() descriptorId: number;
    @Input() selectedDescriptors: number[];
    @Input() disabled: boolean;
    @Output() descriptorChange = new EventEmitter<number>();

    descriptorFilter: string = '';
    descriptorIsLoading: boolean = false;

    descriptors$: Observable<Compliance.NameValuePair<number>[]> = (Observable
        .create((observer: any) => { observer.next(this.descriptorFilter); }) as Observable<string>)
        .pipe(mergeMap((token: string) => this._filterDescriptors(token)));

    async ngOnInit(): Promise<void> {
        if (this.descriptorId) {
            const selectedDescriptor = this.descriptors.find(x => x.value === this.descriptorId);
            if (selectedDescriptor) {
                this.descriptorFilter = selectedDescriptor.name;
            }
        }
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    writeValue(descriptorId: number): void {
        this._selectDescriptor(descriptorId);
    }

    onBlur(): void {
        this._selectDescriptor(this.descriptorFilter ? this.descriptorId : null);
        this._onTouchedFn && this._onTouchedFn();
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const descriptor = match.item as Compliance.NameValuePair<number>;
            this._selectDescriptor(descriptor.value);
        }
    }

    onNoResults(event: boolean): void {
        this._descriptorNoResult = event;
    }

    onLoadingChange(isLoading: boolean): void {
        this.descriptorIsLoading = isLoading;
    }

    private _selectDescriptor(descriptorId: number): void {
        const descriptor = this.descriptors.find(x => x.value === descriptorId);

        if (descriptor) {
            this.descriptorId = descriptor.value;
            this.descriptorFilter = descriptor.name;
        } else {
            this.descriptorId = null;
            this.descriptorFilter = null;
        }

        this._onChangeFn && this._onChangeFn(this.descriptorId);
        this.descriptorChange.emit(this.descriptorId);
    }

    private _filterDescriptors(token: string): Observable<Compliance.NameValuePair<number>[]> {
        return observableOf(this.descriptors.filter(x =>
            (!this.selectedDescriptors.some(y => y === x.value) || x.value === this.descriptorId) &&
            (!token || x.name.toLowerCase().includes(token.toLowerCase()))));
    }
}
