<loading-spinner [show]="loading" [hasOverlay]="true" [size]="5"></loading-spinner>
<ws-modal-wrapper [headerLabel]="(isBelowParcelLevel ? 'Parcel: ' + entityData.parcelAcctNum : entityData.description)"
                  [subHeaderLabel]="(isBelowParcelLevel ? entityData.description : null)"
                  [isHeaderLink]="isBelowParcelLevel"
                  (onHeaderClick)="loadParentComments()"
                  [useAceStyles]="true"
                  (cancel)="done()"
                  [hideFooter]="true">
    <ng-container *ngIf="!editingComment">
        <div class="comment-modal-body">
            <div class="comment-controls ws-flex-container-horizontal ws-flex-align-items-center ws-flex-justify-content-center">
                <label style="margin: 0;">Show: </label>
                <ws-select class="show-control"
                           [options]="years"
                           [isPrimitive]="true"
                           (selectedOptionChanged)="yearChanged()"
                           [(ngModel)]="yearFilter"
                           [disabled]="loading || commentList.length === 0 || entityData.disableYearSelection">
                </ws-select>
                <ws-select class="show-control"
                           [options]="entityTypes"
                           labelProperty="text"
                           [returnEntireOption]="true"
                           (selectedOptionChanged)="entityTypeChanged()"
                           [(ngModel)]="entityTypeFilter"
                           [disabled]="loading || commentList.length === 0 || entityData.disableCommentTypeSelection">
                </ws-select>
                <div class="checkbox" *ngIf="childLabel && entityData.entityTypeID !== EntityTypeIds.COMPANY">
                    <label>
                        <input type="checkbox"
                               (click)="includeChildrenChanged()"
                               [(ngModel)]="includeChildren"
                               [disabled]="loading">
                        Include {{childLabel}}
                    </label>
                </div>
            </div>

            <!-- Pinned Comment -->
            <div *ngIf="pinnedComment && commentList.length > 0 && !loading"
                 class="pinned-comment list-group comment-container list-border"
                 [ngStyle]="{'max-height': pinnedComment ? '38vh' : '58vh' }">
                <div class="pinned-button">
                    <button
                        class="flat-button icon-only ws-no-padding"
                        title="Pinned comments will be included in PSR Report, Action Views, SMART, etc."
                        [disabled]="!entityData.canEdit"
                        (click)="unpinComment(pinnedComment)">
                        <i class="fa fa-thumb-tack"></i>
                    </button>
                </div>
                <div class="list-group-item clickable">
                    <comment-list-item [comment]="pinnedComment"
                        [pinned]="true"
                        [canEdit]="entityData.canEdit"
                        (editComment)="editComment(pinnedComment)">
                    </comment-list-item>
                </div>
            </div>

            <!-- Main Comment List -->
            <div class="list-group comment-container" [ngClass]="{'list-border': commentList.length > 0}" *ngIf="commentList.length > 0 && !loading">
                <div class="list-group-item clickable"
                     style="word-break: break-word;"
                     *ngFor="let comment of unpinnedComments; trackBy:commentTrackBy">
                    <comment-list-item [comment]="comment"
                        [canEdit]="entityData.canEdit"
                        [loading]="loading"
                        (editComment)="editComment(comment)"
                        (pinComment)="pinComment(comment)">
                    </comment-list-item>
                </div>
            </div>
            <div class="comment-list-empty" *ngIf="commentList.length === 0 && !loading">
                <div><h3>No comments found</h3></div>
            </div>
        </div>
        <div *ngIf="!editingComment" class="ace-button-container ws-button-row" style="padding-top: 15px">
            <button class="flat-button primary-button" *ngIf="entityData?.canEdit" (click)="addComment()" [disabled]="loading">Add Comment</button>
            <button class="flat-button" (click)="done()" [disabled]="loading">Done</button>
        </div>
    </ng-container>

    <ng-container *ngIf="editingComment">
        <div class="edit-comment form-horizontal">
            <div class="comment-modal-body">
                <div class="mb-4 row" *ngIf="areCommentTypesAvailable">
                    <label for="comment-type-selection" class="col-md-2 control-label">Type</label>
                    <div class="col-md-10">
                        <ws-select
                            id="comment-type-selection"
                            [(ngModel)]="updateComment.commentTypeID"
                            [options]="commentTypes"
                            labelProperty="name"
                            valueProperty="id"
                            [disabled]="!isNewComment || entityData.disableCommentTypeSelection">
                        </ws-select>
                    </div>
                </div>
                <div class="mb-4 row" *ngIf="yearIsRelevant">
                    <label for="comment-year-selection" class="col-md-2 control-label">Year</label>
                    <div class="col-md-10">
                        <ws-select id="comment-year-selection"
                                   [(ngModel)]="updateComment.year"
                                   [options]="availableYears"
                                   [isPrimitive]="true"
                                   [disabled]="yearDisabled()"
                                   [required]="updateComment.commentTypeID === 3">
                        </ws-select>
                    </div>
                </div>
                <div class="mb-4 row">
                    <label for="comment-title-input" class="col-md-2 control-label">Title</label>
                    <div class="col-md-10">
                        <input type="text"
                               required
                               maxlength="255"
                               class="form-control"
                               id="comment-title-input"
                               [(ngModel)]="updateComment.title" />
                    </div>
                </div>
                <div class="mb-4 row">
                    <label for="comment-description-input" class="col-md-2 control-label">Description</label>
                    <div class="col-md-10">
                        <textarea class="form-control"
                                  maxlength="10000"
                                  id="comment-description-input"
                                  [(ngModel)]="updateComment.description"
                                  rows="10"
                                  style="resize: none;">
                        </textarea>
                    </div>
                </div>
                <div class="mb-4 row" *ngIf="hasInstancePrivateView">
                    <label for="comment-ryan-permissions" class="col-md-12" style="font-weight: normal">
                        <input id="comment-ryan-permissions" [(ngModel)]="updateComment.isRyanInternal" type="checkbox">
                        Only visible to
                        <ng-container *ngIf="isSingleRyanInstance">Ryan</ng-container>
                        <ng-container *ngIf="!isSingleRyanInstance">Internal</ng-container>
                        personnel
                    </label>
                </div>

                <div style="margin-top:20px;" *ngIf="!isNewComment">
                    <button type="button" class="flat-button small-button warning-button" (click)="deleteComment()">Delete Comment</button>
                </div>
            </div>
            <div class="ace-button-container comment-edit-footer">
                <button type="button"
                        class="flat-button"
                        *ngIf="isNewComment"
                        [disabled]="loading"
                        (click)="submitEditForm()">
                    Create Comment
                </button>
                <button type="button"
                        class="flat-button"
                        *ngIf="!isNewComment"
                        [disabled]="loading"
                        (click)="submitEditForm()">
                    Save Comment
                </button>
                <button type="button"
                        class="flat-button warning-button"
                        (click)="cancelEdit()"
                        [disabled]="loading">
                    Cancel
                </button>
            </div>
        </div>
    </ng-container>

</ws-modal-wrapper>
