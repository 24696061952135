import { Component, Input, OnInit } from '@angular/core';
import { ContactRoleService } from '../../Role/contact.role.service';
import { ContactsUpgradeService } from '../contacts.service.upgrade';
import { RestrictData, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'contacts-panel',
    templateUrl: './contactPanel.component.html',
    styleUrls: ['./contactPanel.component.scss']
})
export class ContactPanelComponent implements OnInit {
    constructor(
        private readonly _roleService: ContactRoleService,
        private readonly _contactsService: ContactsUpgradeService,
        private readonly _restrictService: RestrictService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _navigationServiceHandler: UpgradeNavigationServiceHandler
    ) {
    }

    @Input() entity;

    isEntityCompanyBased: boolean = false;

    contacts = [];
    roles = [];
    isLoading: boolean = false;
    showBody: boolean = false;
    isEdit: boolean = false;
    hasEditPermission: boolean = false;
    hasViewOrEditPermission: boolean = false;
    isConsultantUser: boolean = false;
    isCompany: boolean = false;
    showAll: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._contactsService.contactCache$.pipe(takeUntil(this._destroy$))
            .subscribe(contacts => {
                this.contacts = contacts;
            });

        this.isEntityCompanyBased = this.entity && (this.entity.type === 'site' || this.entity.type === 'company' || this.entity.type === 'parcel');
        this.showAll = false;
        this.getViewPermission();
        this.getEditPermissionForContactAndEntity();
    }

    expandOrCollapse(): void {
        if (this.isEdit) {
            return;
        }

        this.showBody = !this.showBody;

        if (this.showBody) {
            this.load();
        }
    }

    async load(): Promise<boolean> {
        this.isLoading = true;

        if (!this.entity) {
            this.showBody = false;
            this.isLoading = false;
            return false;
        } else {
            this.isCompany = this.entity.type === 'company';
        }

        try {
            if (this.isCompany) {
                this.isConsultantUser = await this._restrictService.isConsultantUser(this.entity.id);
            }

            const contacts = await this._contactsService.getByEntity(this.entity.id, this.entity.type, this.showAll);

            const roles = await this._roleService.get();
            this.roles = roles.filter(x => !!x[this.entity.type]);

            contacts.forEach(x => {
                x.contactRoleAssociations = x.contactRoleAssociations || [];
            });

            this._contactsService.contacts = contacts;
        } catch (err) {
            this.showBody = false;
        } finally {
            this.isLoading = false;
        }
    }

    edit(): void {
        this.isEdit = true;
    }

    cancel(): void {
        this.isEdit = false;
    }

    showAllContacts(): void {
        this.showAll = !this.showAll;
        this.load();
    }

    async getEditPermissionForContactAndEntity(): Promise<void> {
        //contacts for company, site and parcel
        if (this.isEntityCompanyBased) {
            const restrictionData = {
                isCompany: this.entity.type.toLowerCase() === 'company',
                entityId: this.entity.id,
                flag: Core.AccessRightsEnum.Write
            } as RestrictData;

            if (this.entity.type === 'parcel') {
                restrictionData.entityId = +this._navigationServiceHandler.getQuerystringParam('siteId');
            }

            try {
                this.hasEditPermission = await this._restrictService.hasPermission(restrictionData, true);
            } catch (err) {
                console.warn('checkEntity - error', err);
            }
        } else {
            const key = `${this.entity.type.toUpperCase()}EDIT`;

            this.hasEditPermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT)
                && this._restrictService.isInRole(Roles[key]);
        }
    }

    async getViewPermission(): Promise<void> {
        const restrictionData = this._restrictService.getRestrictionDataForEntity(this.entity, Core.AccessRightsEnum.Read);
        try {
            const result = await this._restrictService.hasPermission(restrictionData);
            this.hasViewOrEditPermission = result && (this.isEntityCompanyBased || this._userInstanceService.isCurrentInstanceRyan());
        } catch (err) {
            console.warn('checkEntity - error', err);
        }
    }
}
