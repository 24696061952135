<div class="modal-header">
    <h4 class="modal-title">My Tasks Settings</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body exclude-modal-styling" style="padding: 35px 15px 5px 15px;">
    <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading">
        <div class="mb-4">
            <label>Show</label>
            <select [(ngModel)]="selectedMyTasksView">
                <option *ngFor="let item of myTasksViews" [ngValue]="item.viewId">{{item.name}}</option>
            </select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="flat-button primary-button" (click)="saveAndClose()" [disabled]="loading">Save</button>
        <button class="flat-button warning-button" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
