<ws-busy-indicator *ngIf="isBusy" message="Saving changes"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Forms"
                  subHeaderLabel="Check the forms you wish to add to returns and uncheck the ones you wish to remove."
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="disableSave()"
                  acceptHelpContentId="{{!this.hasChanges
                    ? 'return-preview-form-list.unchanged'
                    : this.hasOrphanedSupplementalForms()
                        ? 'return-preview-form-list.orphan-supplemental'
                        : ''}}">
    <div class="ws-flex-container-horizontal ws-flex-align-items-center">
        <div class="ws-flex-none">Apply changes to:</div>
        <div class="ws-flex-none" style="padding-left: 8px;">
            <select class="form-select form-select-sm"
                    [disabled]="applyChangesScopeOptions.length === 1"
                    [(ngModel)]="selectedApplyChangesScopeOption"
                    (change)="onSelectedApplyChangesScopeFilterChange()">
                <option *ngFor="let item of applyChangesScopeOptions" [ngValue]="item">{{ item.value }}</option>
            </select>
        </div>
    </div>

    <table class="table table-condensed">
        <tbody>
            <tr *ngFor="let item of formRevisionAssociations">
                <td class="col-lg-1">
                    <label class="small">
                        <input type="checkbox"
                               [ngModel]="item.isSelected"
                               (ngModelChange)="onFormSelectedChange(item)">
                    </label>
                </td>
                <td class="col-lg-3">{{ item.formRevisionDisplayName }}</td>
                <td class="col-lg-8" [ngClass]="{ 'text-success': item.hasChanges && item.isSelected === true, 'text-danger': item.hasChanges && item.isSelected === false }">{{ getMessage(item) }}</td>
            </tr>
        </tbody>
    </table>
</ws-modal-wrapper>
