import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { CompanyModule } from '../Entity/Company/company.module';
import { AceModule } from '../UI-Lib/ACE/ace.module';
import { AdminComponent } from './admin.component';
import { ManageAPIKeysService } from './ManageAPIKeys/manage-api-keys.service';
import { ManageAPIKeysComponent } from './ManageAPIKeys/manage-api-keys.component';
import { LoginManagerComponent } from './LoginManager/loginManager.component';
import { APIKeyDetailComponent } from './ManageAPIKeys/api-key-detail.component';
import { AboutComponent } from './About/about.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { DXPService } from './DXP/dxp.service';
import { AboutService } from './About/about.service';
import { TestDXPPageComponent } from './DXP/testDXPPage.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        AceModule,
        CommonModule,
        FormsModule,
        CompanyModule,
        UIRouterUpgradeModule
    ],
    exports: [AdminComponent],
    declarations: [
        AdminComponent,
        ManageAPIKeysComponent,
        LoginManagerComponent,
        APIKeyDetailComponent,
        AboutComponent,
        TestDXPPageComponent
    ],
    providers: [
        ManageAPIKeysService,
        DXPService,
        AboutComponent,
        AboutService
    ]
})
export class AdminModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('admin', AdminComponent);
        hybridAdapterUtility.downgradeNg2Component('manageApiKeys', ManageAPIKeysComponent);
        hybridAdapterUtility.downgradeNg2Component('loginManager', LoginManagerComponent);
        hybridAdapterUtility.downgradeNg2Component('about', AboutComponent);
        hybridAdapterUtility.downgradeNg2Component('apiKeyDetail', APIKeyDetailComponent);
        hybridAdapterUtility.downgradeNg2Component('testDxpPage', TestDXPPageComponent);
        hybridAdapterUtility.downgradeNg2Provider('manageApiKeysService', ManageAPIKeysService);
        hybridAdapterUtility.downgradeNg2Provider('dxpService', DXPService);
    }
}
