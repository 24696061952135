import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Slide {
    title?: string;
    description?: string;
    imageUrl: string;
}

@Component({
    selector: 'ws-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {
    @Input() slides: Slide[] = [];
    @Input() imgHeight: number = 600;
    @Input() imgWidth: number = 800;
    @Input() currentSlide: number = 0;
    @Input() disableControls: boolean = false;

    @Output() currentSlideChange: EventEmitter<number> = new EventEmitter();

    goToSlide(index: number): void {
        this.currentSlide = index;
        this.currentSlideChange.emit(this.currentSlide);
    }

    nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
        this.currentSlideChange.emit(this.currentSlide);
    }

    prevSlide() {
        this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
        this.currentSlideChange.emit(this.currentSlide);
    }
}
