import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { CompanyService } from 'src/app/Entity/Company/company.service';
import { TransmittalOutputType, TransmittalGroupBy } from 'src/app/Processing/Transmittal/transmittal.models';
import { ClientServiceResponsibilityService } from '../../clientServiceResponsibility.service';
import { LaunchEmailTemplateModalService } from 'src/app/Common/Custom-Email-Template/launch.email.template.modal.service';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { DeliveryDetailsConfigureParams, DeliveryDetailsConfigureModalComponent } from './deliveryDetailsConfigureModal.component';
import { TransmitDeliveryDetail } from '../../clientServices.model';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { map, sortBy } from 'lodash/fp';
import { DeliveryDetailsPaymentBatchModalComponent, DeliveryDetailsPaymentBatchParams } from './deliveryDetailsPaymentBatchModal.component';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';
import { RyanInstanceId } from '../../../constants.new';

interface DDOption {
    id: number;
    name: string;
}

interface PackageEmailTemplate {
    name: string;
    packageEmailTemplateID: number;
    bodyTemplate: string;
    subjectTemplate: string;
}

@Component({
    selector: 'delivery-details-transmit',
    templateUrl: './deliveryDetailsTransmit.component.html'
})
export class DeliveryDetailsTransmitComponent implements OnInit {
    constructor(private readonly _companyService: CompanyService,
        private readonly _launchEmailTemplateModalService: LaunchEmailTemplateModalService,
        private readonly _modalService: WeissmanModalService,
        public readonly featureFlagsService: FeatureFlagsService,
        private readonly _clientServiceResponsibilityService: ClientServiceResponsibilityService) { }

    @Input() editMode: boolean;
    @Input() currentData: TransmitDeliveryDetail;
    @Input() topLevelData: TransmitDeliveryDetail;
    @Input() entity: CompanyEntity;
    @Input() userSearchInstanceId: number;

    @Output() currentDataChange: EventEmitter<any> = new EventEmitter();
    @Output() currentPaymentBatchDataChange: EventEmitter<Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn> =  new EventEmitter();

    transmittalOutputTypes: DDOption[];
    transmittalFormats: DDOption[];
    transmittalGroupBys: DDOption[];
    deliveryDetailPerPackageLimitTypes: DDOption[];
    packageEmailTemplates: PackageEmailTemplate[];
    transmittalPackageCovers: DDOption[];
    TransmittalOutputType = TransmittalOutputType;
    currentPaymentBatchData: Core.PaymentBatchDeliveryDetail;

    ngOnInit() {
        this._setTransmittalDropdowns();
        this._setDeliveryDetailsDefaults();
        this._setCheckboxes();
        this.currentDataChange.emit(this.currentData);
    }

    get isCompany(): boolean {
        return this.entity.type === 'company';
    }

    get isSubsidiary(): boolean {
        return this.isCompany && !this._companyService.isTopLevel();
    }

    get areNoTopLevelSettings(): boolean {
        return !this.topLevelData.taxBillDeliveryDetailID;
    }

    get showSiteDescriptorsSelector(): boolean {
        return this.currentData.transmittalFormatID === 6 && (
            this.currentData.transmittalGroupByID === TransmittalGroupBy.Subsidiary_Site ||
            this.currentData.transmittalGroupByID === TransmittalGroupBy.Collector_Site);
    }

    useTopLevelChanged() {
        if (!this.currentData.useTopLevelCompanySettings) {
            this.currentData.ownerEntityID = this.entity.id;
        }
        else {
            _.assign(this.currentData, _.omit(this.topLevelData, 'useTopLevelCompanySettings', 'taxBillDeliveryDetailID'));
            this._setCheckboxes();
        }
    }

    async launchConfigureModal() {
        const params: DeliveryDetailsConfigureParams = {
            editMode: this.editMode && !this.currentData.useTopLevelCompanySettings,
            feedSpecifications: _.cloneDeep(this.currentData.feedSpecifications),
            clientAbbreviation: this.currentData.clientAbbreviation,
            entity: this.entity,
            transmittalOutputTypeID: this.currentData.transmittalOutputTypeID
        };

        const result = await this._modalService.showAsync(DeliveryDetailsConfigureModalComponent, params, 'modal-md');

        if(result) {
            this.currentData.clientAbbreviation = result.clientAbbreviation;
            this.currentData.feedSpecifications = result.feedSpecifications;
            this.currentDataChange.emit(this.currentData);
        }
    }

    maybeNullInputs(): void {
        this.currentData.maxPackageSize = this.currentData.isMaxPackageSize
            ? this.currentData.maxPackageSize
            : null;
        this.currentData.maxPaymentItemsPerTransmittal = this.currentData.isMaxPaymentItems
            ? this.currentData.maxPaymentItemsPerTransmittal
            : null;

        if (this.currentData.isOne) {
            this.currentData.deliveryDetailPerPackageLimitTypeID = this.currentData.deliveryDetailPerPackageLimitTypeID
                || this.deliveryDetailPerPackageLimitTypes[0].id;
        } else {
            this.currentData.deliveryDetailPerPackageLimitTypeID = null;
        }

        this.currentDataChange.emit(this.currentData);
    }

    onSiteDescriptorsChanged(siteDescriptorIds: number[]): void {
        this.currentData.siteDescriptors = siteDescriptorIds.filter(x => x);
        this.currentDataChange.emit(this.currentData);
    }

    maybeLaunchCustomModal(newPackageEmailTemplateID?: number) {
        let oldPackageEmailTemplateID: number;

        if(newPackageEmailTemplateID !== undefined) {
            oldPackageEmailTemplateID = this.currentData.packageEmailTemplateID;
            this.currentData.packageEmailTemplateID = newPackageEmailTemplateID;
        }

        if (this.currentData.packageEmailTemplateID) {
            return;
        }

        const customTemplateModalCloseFn = template => {
            if (template) {
                _.assign(this.currentData, template);
            }

            if (!this.currentData.packageEmailCustomTemplateBody && oldPackageEmailTemplateID) {
                this.currentData.packageEmailTemplateID = Number(oldPackageEmailTemplateID);
            }
            this.currentDataChange.emit(this.currentData);
        };

        this._launchEmailTemplateModalService.openCustomTemplateModal(customTemplateModalCloseFn, this.currentData.packageEmailCustomTemplateSubject,
            this.currentData.packageEmailCustomTemplateBody, this.packageEmailTemplates);
    }

    previewTemplate(): void {
        const selectedTemplate = _.find(this.packageEmailTemplates, { packageEmailTemplateID: this.currentData.packageEmailTemplateID });

        if (selectedTemplate) {
            this._launchEmailTemplateModalService.openPreviewTemplateModal(selectedTemplate);
        }
    }

    isPaymentBatchAvailable() : boolean {
        return this.featureFlagsService.featureFlags.enablePaymentBatch &&
               this.userSearchInstanceId === RyanInstanceId &&
               this.currentData.transmittalOutputTypeID === TransmittalOutputType.BillPay &&
               this.isCompany &&
               (!this.isSubsidiary || !this.currentData.useTopLevelCompanySettings)
    }

    async launchPaymentBatchModal(): Promise<void> {
        const params = {
            editMode: this.editMode,
            entity: this.entity,
            userSearchInstanceId: this.userSearchInstanceId,
            currentPaymentBatchData: this.currentPaymentBatchData
        };

        let paymentBatchDataToSave;
        [
            paymentBatchDataToSave,
            this.currentPaymentBatchData
        ] = await this._modalService.showAsync(DeliveryDetailsPaymentBatchModalComponent, params, 'modal-lg');

        if (paymentBatchDataToSave) {
            this.currentPaymentBatchDataChange.emit(paymentBatchDataToSave);
        }
    }

    private async _setTransmittalDropdowns(): Promise<void> {
        this.transmittalOutputTypes = [{
            id: null,
            name: ''
        }, {
            name: 'AP Feed',
            id: TransmittalOutputType.APFeed
        }, {
            name: 'Bill Pay',
            id: TransmittalOutputType.BillPay
        }, {
             name: 'Digital Transmittal',
            id: TransmittalOutputType.DigitalTransmittal
        }, {
            name: 'Paper Transmittal',
            id: TransmittalOutputType.PaperTransmittal
        }];

        const result = await this._clientServiceResponsibilityService.getAvailableTransmittalFormats(this.entity.typeId, this.entity.id);
        this.transmittalFormats = _.flow([
            map(format => {
                return {id: format.transmittalFormatID, name: format.name};
            }),
            sortBy('name')
        ])(result);

        this.transmittalGroupBys = [{
            id: TransmittalGroupBy.Collector,
            name: 'Collector / Due Date'
        }, {
            id: TransmittalGroupBy.Collector_Site,
            name: 'Collector / Site / Due Date'
        }, {
            id: TransmittalGroupBy.Subsidiary,
            name: 'Subsidiary / Collector / Due Date'
        }, {
            id: TransmittalGroupBy.Subsidiary_Site,
            name: 'Subsidiary / Collector / Site / Due Date'
        }];

        this.deliveryDetailPerPackageLimitTypes = [{
            id: null,
            name: ''
        }, {
            id: 1,
            name: 'State'
        }, {
            id: 2,
            name: 'Transmittal'
        }];

        this.packageEmailTemplates = await this._clientServiceResponsibilityService.getPackageEmailTemplates(this.entity.typeId, this.entity.id);
        this.packageEmailTemplates = _.sortBy(this.packageEmailTemplates, 'name');

        this.packageEmailTemplates.push({
            name: 'Custom',
            packageEmailTemplateID: null,
            bodyTemplate: this.currentData.packageEmailCustomTemplateBody,
            subjectTemplate: this.currentData.packageEmailCustomTemplateSubject
        });

        if (!this.currentData.packageEmailTemplateID) {
            if (this.currentData.packageEmailCustomTemplateSubject) {
                this.currentData.packageEmailTemplateID = null;
            } else {
                this.currentData.packageEmailTemplateID = 1;
            }
        }

        const packageCovers = await this._clientServiceResponsibilityService.getPackageCovers();
        this.transmittalPackageCovers = _.flow([
            map(x => { return { id: x.transmittalPackageCoverId, name: x.name }; }),
            sortBy('id')
        ])(packageCovers);

        this.currentDataChange.emit(this.currentData);
    }

    private _setDeliveryDetailsDefaults(): void {
        if (!this.currentData.taxBillDeliveryDetailID) {
            this.currentData.maxPackageSize = 50;
        }

        this.currentData.ownerEntityID = this.entity.id;
    }

    private _setCheckboxes(): void {
        this.currentData.isMaxPackageSize = !!this.currentData.maxPackageSize;
        this.currentData.isMaxPaymentItems = !!this.currentData.maxPaymentItemsPerTransmittal;
        this.currentData.isOne = !!this.currentData.deliveryDetailPerPackageLimitTypeID;
    }
}
