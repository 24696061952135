<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Factor Tables"
                  acceptLabel="Export"
                  [suppressKeyboardEvents]="true"
                  (save)="export()"
                  (cancel)="cancel()"
                  [disableSave]="!rowsSelected">
    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="''" [suffix]="''"></ag-grid-row-count>
    <div class="app-ui-view h-100 w-100">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
