import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CompanyService } from '../../Entity/Company/company.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

export interface CompanyPickerResult {
    companyID: number;
    companyName: string;
}

@Component({
    selector: 'ws-company-picker',
    templateUrl: './companyPicker.component.html'
})
export class CompanyPickerComponent implements OnInit {
    constructor(private readonly _companyService: CompanyService) {}

    @Input() companyId: number;
    @Input() companyName: string;
    @Input() appendToBody: boolean;
    @Input() topLevelOnly: boolean;
    @Input() favoritesOnly: boolean;
    @Input() activeOnly: boolean;

    @Output() companyIdChange: EventEmitter<any> = new EventEmitter();
    @Output() companyNameChange: EventEmitter<any> = new EventEmitter();
    @Output() companyChange: EventEmitter<CompanyPickerResult> = new EventEmitter();

    company: CompanyPickerResult;
    companyFilter: string = '';
    loadingCompanies: boolean;

    companies$: Observable<any[]> = (new Observable((observer: any) => { observer.next(this.companyFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._getCompanies(token)));

    private readonly PAGE_SIZE = 50;
    private readonly PAGE_NUMBER = 1;

    private _noResult: boolean;

    ngOnInit(): void {
        this.company = {
            companyID: this.companyId || null,
            companyName: this.companyName || null
        };
        this.companyFilter = this.companyName || null;
    }

    companySelected(selected: TypeaheadMatch): void {
        this.companyId = selected.item.companyID;
        this.companyIdChange.emit(this.companyId);
        this.companyName = selected.item.companyName;
        this.companyNameChange.emit(this.companyName);
        this.company = {
            companyID: this.companyId,
            companyName: this.companyName
        }
        this.companyChange.emit(this.company);
    }

    checkForBlank(): void {
        if (!this.company) {
            this.companyId = null;
            this.companyName = '';
        }
    }

    onNoResults(event: boolean): void {
        this._noResult = event;

        if (event) {
            this.companyId = null;
            this.companyName = '';
        }
    }

    onBlur(): void {
        if (!this.companyFilter) {
            this.companyIdChange.emit(null);
            this.companyNameChange.emit(null);
        }
    }

    typeaheadLoading(isLoading: boolean): void {
        this.loadingCompanies = isLoading;
    }

    private _getCompanies(searchTerm: string): Observable<string[]> {
        if (searchTerm) {
            return from(this._companyService.search(searchTerm || '', this.PAGE_SIZE, this.PAGE_NUMBER, this.topLevelOnly, this.favoritesOnly, this.activeOnly));
        } else {
            return from(Promise.resolve([]));
        }
    }
}
