import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';
import { BusyIndicatorMessageManager } from '../../../../../Busy-Indicator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EntityImportColumnMappingStepService } from '../../Column-Mapping-Step/columnMappingStep.service';
import { EntityImportProcessService } from '../../importProcess.service';
import { DynamicFieldBehaviorService } from '../dynamicFieldBehavior.service';

export interface DynamicFieldBehaviorConfigurationFieldInfo {
    importFieldId: number;
    name: string;
}

export interface DynamicFieldBehaviorConfigurationParams {
    importId: number;
    options: Compliance.ImportDynamicFieldOptionModel[];
    dynamicFields: DynamicFieldBehaviorConfigurationFieldInfo[],
    selectedOptions: System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[];
}

@Component({
    selector: 'dynamic-field-behavior-configuration',
    templateUrl: './dynamicFieldBehaviorConfiguration.component.html',
    styles: [`
        ws-select {
            display: block;
            width: 100px;
        }`]
})
export class DynamicFieldBehaviorConfigurationComponent implements OnInit, IWeissmanModalComponent<DynamicFieldBehaviorConfigurationParams, void>{
    params: DynamicFieldBehaviorConfigurationParams;
    result: void;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    availableOptions: Compliance.ImportDynamicFieldOptionModel[];
    fields: DynamicFieldBehaviorConfigurationFieldInfo[];
    selectedOptions: System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[] = [];

    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _dynamicFieldBehaviorService: DynamicFieldBehaviorService
    ) {}

    ngOnInit(): void {
        this.availableOptions = this.params.options.filter(x => !x.isFileOnlyOption);
        this.fields = this.params.dynamicFields;
        this.fields.sort((x, y) => x.name.localeCompare(y.name));
        this.selectedOptions = [... this.params.selectedOptions] || [];

        if (this.selectedOptions.length === 0) {
            this.selectedOptions.push({key: 0, value: Compliance.ImportDynamicFieldOptionEnum.Ignore});
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    getValue(importFieldId: number) {
        const selectedValue = this.selectedOptions.find(x => x.key === importFieldId);
        const setAllValue = this.selectedOptions.find(x => x.key === 0);

        return selectedValue
            ? selectedValue.value || Compliance.ImportDynamicFieldOptionEnum.Ignore
            : setAllValue
                ? setAllValue.value
                : Compliance.ImportDynamicFieldOptionEnum.Ignore;
    }

    setValue(importFieldId: number, option: Compliance.ImportDynamicFieldOptionEnum) {
        if (importFieldId === 0) {
            this.selectedOptions = [];
        }

        let selectedValue = this.selectedOptions.find(x => x.key === importFieldId);

        if (!selectedValue) {
            selectedValue = {key: importFieldId, value: option};
            this.selectedOptions.push(selectedValue);
        } else {
            selectedValue.value = option;
        }
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            const updateModel: Compliance.ImportFieldPropertyCharacteristicBehaviorUpdateModel = {
                importId: this.params.importId,
                fieldOptions: this.selectedOptions
            };

            await this._dynamicFieldBehaviorService.updateImportFieldPropertyCharacteristicBehavior(updateModel);

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }
}
