import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_CONTROL_MODAL_HELP } from './longRunningProcessControlModal.component.help';
import { LongRunningProcessService } from '../longRunningProcess.service';

@Component({
    templateUrl: './longRunningProcessControlModal.component.html',
    selector: 'long-running-process-control-modal'
})
export class LongRunningProcessControlModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, Compliance.ControlMessageLongRunningProcessParameters> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _helpService: HelpService,
        private readonly _longRunningProcessService: LongRunningProcessService) {
    }

    params: void;
    result: { controlMessage: Compliance.LongRunningProcessControlMessageTypeEnum, serviceHost: string };
    form: UntypedFormGroup;

    get serviceHosts(): string[] {
        return this._longRunningProcessService.serviceHosts;
    }

    get controlMessageTypes(): Compliance.LongRunningProcessControlMessageTypeModel[] {
        return this._longRunningProcessService.controlMessageTypes;
    }

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_CONTROL_MODAL_HELP);

        this.form = this._formBuilder.group({
            controlMessage: [null, [Validators.required,]],
            serviceHost: [null, [Validators.required,]],
        });

        this.form.setValue({
            controlMessage: Compliance.LongRunningProcessControlMessageTypeEnum.GetStatus,
            serviceHost: this.serviceHosts[0]
        });
    }

    ngOnDestroy(): void { }

    getControlMessage() {
        this.result = {
            controlMessage: this.form.get('controlMessage').value as Compliance.LongRunningProcessControlMessageTypeEnum,
            serviceHost: this.form.get('serviceHost').value as string
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
