import { ColDef, IDatasource, IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult, AgGridColumns } from '../AgGrid';
import { AgGridCheckboxCellRendererComponent, ICellRendererParamsForAgGridCheckbox } from '../AgGrid/CellRenderers/agGridCheckboxCellRender.component';

export interface IEntityPickerResult<T> {
    result: T;
}

export interface IEntityPicker<T> {
    getModalClass(): string;
    getDataSource(gridApi: GridApi): IDatasource;
    getColDefs(): ColDef[];
    getSelectedIds(): Set<string>;
    getResult(): IEntityPickerResult<T>;
    optionFlagChange(): void;
    title: string;
    optionFlagText: string;
    optionFlag: boolean;
}

class EntityPickerDataSource extends AgGridDataSourceBase<void> {
    constructor(
        gridApi: GridApi,
        private readonly _propertyMap: Compliance.NameValuePair<any>[],
        private readonly _getRowsFunction: (params: IGetRowsParams, paginatedSearchModel: any) => Promise<AgGridDataSourceResult>) {
        super(gridApi);
    }

    canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const paginatedSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        }

        const result = await this._getRowsFunction(params, paginatedSearchModel);
        this.gridApi.sizeColumnsToFit();

        return result;
    }
}

export abstract class EntityPicker<T> implements IEntityPicker<T> {
    protected constructor(protected searchModel: any) {
    }

    protected selectedIds: Set<string> = new Set();
    protected abstract propertyMap: Compliance.NameValuePair<any>[];
    protected dataSource: AgGridDataSourceBase<void>;
    protected idRowDataField: string = 'id';
    protected singleSelectColDef: ColDef = {
        field: 'isSelected',
        headerName: '',
        width: AgGridColumns.textColumnMedWidth,
        minWidth: AgGridColumns.checkboxColumnMinWidth,
        suppressSizeToFit: true,
        cellRendererFramework: AgGridCheckboxCellRendererComponent,
        cellRendererParams: {
            onValueChanged: this.onSelectEdited.bind(this),
            isVisible: this.isSelectVisible.bind(this),
            canEdit: this.isSelectEditable.bind(this),
            canEnterEditMode: this.isSelectEditable.bind(this),
        } as ICellRendererParamsForAgGridCheckbox
    };
    protected singleSelector = true;
    protected singleSelectedEntity: T;

    optionFlagText: string;
    optionFlag: boolean;
    title = 'Choose Entity';

    getModalClass() {
        return 'modal-sm';
    }

    getSelectedIds() {
        return this.selectedIds;
    }

    protected abstract getRowsInternal(params: IGetRowsParams, paginatedSearchModel: any): Promise<AgGridDataSourceResult>;

    protected addSelectedFlag(dataEntry) {
        dataEntry.isSelected = false; // later - apply pre-selected from a parameter
        return dataEntry;
    }

    protected getIdFromRow(params): string {
        if (params.data) {
            return params.data[this.idRowDataField];
        }
        return null;
    }

    protected onSelectEdited(params, selected) {
        if (selected) {
            this.storeSelection(params);
        } else {
            this.removeSelection(params);
        }
    }

    protected storeSelection(params) {
        const id = this.getIdFromRow(params);
        if (this.singleSelector) {
            // clear other selections
            if (this.dataSource) {
                this.dataSource.getShownRows().forEach((node) => {
                    const nodeId = this.getIdFromRow({ data: node });
                    node.isSelected = id === nodeId;
                });
            }
            this.singleSelectedEntity = params.data as T;
        }
        this.selectedIds.add(id);
        this.dataSource.gridApi.redrawRows();
    }

    protected removeSelection(params) {
        const id = this.getIdFromRow(params.data);
        this.selectedIds.delete(id);
        if (this.singleSelector) {
            this.singleSelectedEntity = null;
        }
        this.dataSource.gridApi.redrawRows();
    }

    protected isSelectVisible() {
        return true;
    }

    protected isSelectEditable() {
        return true;
    }

    getResult(): IEntityPickerResult<T> {
        return {
            result: this.singleSelectedEntity,
            optionFlag: this.optionFlag
        } as IEntityPickerResult<T>;
    }

    getDataSource(gridApi: GridApi): IDatasource {
        if (!this.dataSource) {
            this.dataSource = new EntityPickerDataSource(gridApi, this.propertyMap, this.getRowsInternal.bind(this));
        }
        return this.dataSource;
    }

    abstract optionFlagChange();
    abstract getColDefs();
}
