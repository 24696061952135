(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('FilePreviewController', FilePreviewController);

    FilePreviewController.$inject = [
        '$sce',
        '$scope',
        'popUpService',
        'pubSubService',
        'DocumentService',
	    'sdAttachmentService',
	    'sdNavbarTopService'
    ];

    function FilePreviewController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit(
        $sce,
        $scope,
        popUpService,
        pubSubService,
        documentService,
        attachmentService,
        navbarTopService
    ) {
        var _pubSubSubscriptions = [];

        var vm = this;
        vm.attachmentID = null;
        vm.attachmentFileName = null;
        vm.attachmentFileExtension = null;

        activate();
        // File preview doesn't have a navbar; use CSS to selectively add/remove the top content padding as appropriate
        document.body.classList.remove('show-nav');
        navbarTopService.showNavbar = false;
        navbarTopService.fullScreen = true;

        vm.previewSupported = false;

        vm.downloadAttachment = function () {
            attachmentService.downloadAttachmentByGuid(vm.attachmentID, vm.attachmentFileName, vm.attachmentFileExtension);
        };

        // clean up pub-sub subscription upon destroy
        $scope.$on('$destroy', function() {
            var i = _pubSubSubscriptions.length;
            while (i--) {
                _pubSubSubscriptions[i].unsubscribe();
            }
        });

        function activate() {
            // subscribe to pub-sub to handle "attachmentChanged" messages
            var subscription = pubSubService.subscribe(popUpService.Topics.Attachment.AttachmentChanged, function (data) {
                loadAttachment(data.attachmentInfo);
            });
            _pubSubSubscriptions.push(subscription);

            // notify the caller that the preview is ready
            pubSubService.publishParent(popUpService.Topics.Attachment.PreviewInitialized);
        }

        function loadAttachment(attachmentInfo) {
            if (attachmentInfo.attachmentID !== vm.attachmentID) {
                vm.fileInfo = attachmentService.getMimeTypeByFileExtension(attachmentInfo.fileExtension);
                vm.previewSupported = vm.fileInfo.previewSupported;
                if (vm.previewSupported) {
                    documentService.getDocumentBody(attachmentInfo.attachmentID)
                        .then(function (response) {
                            var attachmentBlob = new Blob([response.data], { type: attachmentInfo.fileExtension });
                            var attachmentBlobUrl = URL.createObjectURL(attachmentBlob);
                            vm.attachmentUrl = $sce.trustAsResourceUrl(attachmentBlobUrl);
                            vm.attachmentID = attachmentInfo.attachmentID;
                            vm.attachmentFileName = attachmentInfo.fileName;
                            vm.attachmentFileExtension = attachmentInfo.fileExtension;
                            vm.fileError = false;
                        }).catch(function () {
                            vm.attachmentUrl = null;
                            vm.attachmentID = attachmentInfo.attachmentID;
                            vm.attachmentFileName = attachmentInfo.fileName;
                            vm.attachmentFileExtension = attachmentInfo.fileExtension;
                            vm.fileError = true;
                            $scope.$apply();
                        });
                }
                else {
                    vm.attachmentUrl = null;
                    vm.attachmentID = attachmentInfo.attachmentID;
                    vm.attachmentFileName = attachmentInfo.fileName;
                    vm.attachmentFileExtension = attachmentInfo.fileExtension;
                    vm.fileError = false;
                    $scope.$apply();
                }
            }
        }
    }
})();
