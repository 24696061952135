import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { CollectorVendorCode } from './vendorCodeMapping.component';

@Injectable()
export class VendorCodeService {
    private readonly _baseUrl: string = '/api';

    constructor(private readonly _http: HttpClient) { }

    getCompanyCollectorVendorCodes(companyId: number, getAll: boolean): Promise<CollectorVendorCode[]> {
        const url = `${this._baseUrl}/collector/company/${companyId}/collectorvendor`;
        const params = new HttpParams().set('getAll', getAll.toString());

        return lastValueFrom(this._http.get(url, { params })) as Promise<CollectorVendorCode[]>;
    }

    createCollectorVendorCode(vendorCode: CollectorVendorCode): Promise<any> {
        return lastValueFrom(this._http.post(`${this._baseUrl}/vendorcode`, vendorCode)) as Promise<any>;
    }

    updateCollectorVendorCode(vendorCode: CollectorVendorCode): Promise<any> {
        return lastValueFrom(this._http.put(`${this._baseUrl}/vendorcode`, vendorCode)) as Promise<any>;
    }

    deleteCollectorVendorCode(vendorCodeId: number): Promise<any> {
        return lastValueFrom(this._http.delete(`${this._baseUrl}/vendorcode/${vendorCodeId}`)) as Promise<any>;
    }

}
