import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { AppealApplicationService } from "../appeal.application.service";
import * as _ from "lodash";
import { AppealFormSettings, AppealFormSettingLookup, AppealFormSettingLookupOption, AppealFormException } from "../appeal.application.model";
import { ExtendedContactModel } from "../../Compliance/Return/Models/extendedContactModel";
import { ToastrService } from 'ngx-toastr';
import { InstanceRepository } from "../../Entity/Instance/instance.repository";
import { EntityTypeIds } from "../../constants.new";
import { ContactsUpgradeService } from "../../Contact/contacts.service.upgrade";

@Component({
    selector: 'appeal-application-settings',
    templateUrl: './appeal.application.settings.component.html'
})
export class AppealApplicationSettingsComponent implements OnInit {
    @Input() appealFormRevisionIds: number[];
    @Input() appealId: number;
    @Output() settingsChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() multiAccountSettingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    appealFormSettings: AppealFormSettings;
    appealFormSettingLookups: AppealFormSettingLookup;
    savingSettings: boolean = false;
    agentContactOtherId: number;
    signerOtherId: number;
    instanceId: number;
    groupNumberMap: { [appealFormSettingsLookupId: number]: { title: string, settingsProp: string } };
    search:any;
    oldAgentContact: number;
    oldSigner: number;

    constructor(
        private appealApplicationService: AppealApplicationService,
        private instanceRepository : InstanceRepository,
        private toastr: ToastrService,
        private contactService: ContactsUpgradeService
    ) {}

    ngOnInit() {
        this._getData();
        this.getInstanceIdForAppeal();
        this.groupNumberMap = this.appealApplicationService.getGroupNumberMap();
    }

    private async _getData() {
        await this._getDropdowns();
        this._getSavedSettings();
    }

    private async _getDropdowns() {
        let options: AppealFormSettingLookupOption[] = await this.appealApplicationService.getApplicationFormLookups();

        this.appealFormSettingLookups = _.chain(options)
            .groupBy('groupNumber')
            .mapValues((optionArr: AppealFormSettingLookupOption[]) => {
                return {
                    options: optionArr,
                    exceptions: []
                };
            })
            .value();
    }

    private async getInstanceIdForAppeal() {
        this.instanceId = await lastValueFrom(this.instanceRepository.getEntityInstanceId('appeal', this.appealId));
        this.search = {
            find: (filter)=> {
                return this.contactService.search(this.instanceId, filter, 1, 100, true);
            }
        }
    }

    async saveSetting(groupNumber: number, isException: boolean, otherModel?: ExtendedContactModel): Promise<void> {
        this.savingSettings = true;

        try {
            let lookup = this.appealFormSettingLookups[groupNumber];

            // console.log('this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp]', this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp]);

            let payload: any = {
                appealRevisionFormIds: isException ? _.map(lookup.exceptions, 'appealFormRevisionID') : this.appealFormRevisionIds,
                groupNumber: groupNumber,
                value: this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp]
            };

            if(otherModel) {
                if(groupNumber === 3) {
                    this.appealFormSettings.settings.agentContactOtherValue = otherModel.model.contactID;
                } else {
                    this.appealFormSettings.settings.signerOtherValue = otherModel.model.contactID;
                }

                payload.otherValue = otherModel.model.contactID;
            }

            await this.appealApplicationService.saveSettings(payload);

            if (groupNumber === 9 &&  payload.value === 1) {
                // user wants to apply digital signature - check permissions etc
                //
                var checkIfSignatureMayBeAppliedResult = await this.appealApplicationService.checkIfSignatureMayBeApplied(payload);
                if (checkIfSignatureMayBeAppliedResult)
                {
                    this.toastr.error(checkIfSignatureMayBeAppliedResult);
                    this.appealFormSettings.settings[this.groupNumberMap[9].settingsProp] = 0;

                    await this.appealApplicationService.saveSettings({
                        appealRevisionFormIds: isException ? _.map(lookup.exceptions, 'appealFormRevisionID') : this.appealFormRevisionIds,
                        groupNumber: 9,
                        value: 0
                    });
                }
            }

            if (groupNumber === 8) {
                this.multiAccountSettingChanged.emit(true);
            }
            else {
                this.settingsChanged.emit(true);
            }
            // Changing a setting will clear all exceptions as long as the API call succeeds
            lookup.exceptions = [];
        } finally {
            this.savingSettings = false;
        }
    }


    async setFlagApplyDigitalSignature(groupNumber: number, isException: boolean): Promise<void> {
        if (this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp] === 1) {
            this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp] = 0;
        } else {
            this.appealFormSettings.settings[this.groupNumberMap[groupNumber].settingsProp] = 1;
        }
        await this.saveSetting(groupNumber, isException);
    }

    private async _getSavedSettings(): Promise<void> {
        this.appealFormSettings = await this.appealApplicationService.getSettings(this.appealFormRevisionIds) || new AppealFormSettings();

        _.forEach(this.appealFormSettings.exceptions, (exception: AppealFormException) => this.appealFormSettingLookups[exception.groupNumber].exceptions.push(exception));

        this.agentContactOtherId = this.appealFormSettings.settings.agentContactOtherValue || null;
        this.signerOtherId = this.appealFormSettings.settings.signerOtherValue || null;

        //console.log('this.appealFormSettings', this.appealFormSettings);
    }
}
