import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'form-year-list',
    templateUrl: './formYearList.component.html',
    styleUrls: ['formYearList.component.scss']
})
export class FormYearListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _formService: FormService
    ) {}

    get years(): Compliance.FormRevisionYearModel[] {
        return this._formService.formRevisionYears;
    }

    selectedYear: UntypedFormControl = new UntypedFormControl();

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this.selectedYear.disable();

        this._formService.formRevision$.pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // initial load
                this.selectedYear.setValue(this._formService.formRevisionYear);
            });

        this._formService.formRevisionYear$.pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // year changed
                this.selectedYear.setValue(this._formService.formRevisionYear);
            });

        this._mutexService.lockChanged$.pipe(takeUntil(this.destroy$))
            .subscribe((val) => (!val.hasOwnProperty(this._formService.editGroup))
                   ?  this.selectedYear.enable()
                   : this.selectedYear.disable());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async selectedYearChanged(year: Compliance.FormRevisionYearModel): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: `Loading ${ year && year.taxYear}` });
        try {
            await this._formService.setFormRevisionYear(year);
        }
        finally {
            busyRef.hide();
        }
        return Promise.resolve();
    }
}
