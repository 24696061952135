<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="((displayMode === DisplayModeEnum.Add || displayMode === DisplayModeEnum.CopyFrom ? 'New ' : '') + 'Factor Table')"
                  (save)="save()"
                  (cancel)="cancel()"
                  [cancelClass]="(displayMode === DisplayModeEnum.View) ? 'primary-button': 'warning-button'"
                  [hideSave]="displayMode === DisplayModeEnum.View"
                  [disableSave]="form && !form.valid">
    <form *ngIf="form" class="factor-table-details d-flex flex-column" [formGroup]="form">
        <div class="view-toggle d-flex">
            <div class="align-items-center justify-content-center"
            [class.selected]="selectedView === PanelView.Details"
            (click)="setViewSelection(PanelView.Details)">
                TABLE INFO
            </div>
            <div class="align-items-center justify-content-center"
                 [class.selected]="selectedView === PanelView.List"
                 (click)="setViewSelection(PanelView.List)">
                FACTORS
            </div>
        </div>

        <div [class.active]="selectedView === PanelView.Details" class="factor-table-panel">
            <div class="details-panel">
                <div class="row" formGroupName="coreGroup">
                    <div class="col-lg-6">
                        <div class="mb-3" [ngClass]="{ 'has-error': fCore.controls.tableName.touched && fCore.controls.tableName.errors }">
                            <label for="name">Table Name</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCore.controls.tableName.value }}</div>
                            <input *ngIf="displayMode !== DisplayModeEnum.View" type="text" class="form-control" formControlName="tableName" autocomplete="off" />
                            <div *ngIf="fCore.controls.tableName.touched && fCore.controls.tableName.errors" class="error-feedback">
                                <div class="text-danger small" *ngIf="fCore.controls.tableName.errors.required">Table name is required.</div>
                                <div class="text-danger small" *ngIf="fCore.controls.tableName.errors.maxlength">Table name is too long.</div>
                            </div>
                        </div>

                        <div class="mb-3" [ngClass]="{ 'has-error': fCore.controls.tableLife.touched && fCore.controls.tableLife.errors }">
                            <label for="life">Life</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCore.controls.tableLife.value }}</div>
                            <input *ngIf="displayMode !== DisplayModeEnum.View" type="number" class="form-control" formControlName="tableLife" />
                            <div *ngIf="fCore.controls.tableLife.touched && fCore.controls.tableLife.errors" class="error-feedback">
                                <div class="text-danger small" *ngIf="fCore.controls.tableLife.errors.required">Life is required.</div>
                            </div>
                        </div>

                        <div class="mb-3" [ngClass]="{ 'has-error': fCore.controls.tableType.touched && fCore.controls.tableType.errors }">
                            <label class="filter-label">Table Type</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCore.controls.tableType.value | wsFactorTableTypeName }}</div>
                            <div *ngIf="displayMode !== DisplayModeEnum.View">
                                <label class="radio-inline" *ngFor="let factorTableType of factorTableTypes">
                                    <input type="radio" formControlName="tableType" [value]="factorTableType.value" />{{factorTableType.name}}
                                </label>
                                <div *ngIf="fCore.controls.tableType.touched && fCore.controls.tableType.errors" class="error-feedback">
                                    <div class="text-danger small" *ngIf="fCore.controls.tableType.errors.required">Table type is required.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mb-3" formGroupName="stateGroup" [ngClass]="{ 'has-error': fCoreState.controls.stateName.touched && fCoreState.controls.stateName.errors }">
                            <label for="state">State</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCoreState.controls.stateName.value }}</div>
                            <input *ngIf="displayMode !== DisplayModeEnum.View"
                                   type="text"
                                   class="form-control"
                                   formControlName="stateName"
                                   autocomplete="on"
                                   [typeahead]="states"
                                   typeaheadOptionField="fullName"
                                   [typeaheadOptionsLimit]="50"/>
                            <div *ngIf="fCoreState.controls.stateName.touched && fCoreState.controls.stateName.errors" class="error-feedback">
                                <div class="text-danger small" *ngIf="fCoreState.controls.stateName.errors.required">State is required.</div>
                            </div>
                        </div>

                        <div *ngIf="source === GridSourceOptionEnum.Company" class="mb-3" formGroupName="companyGroup" [ngClass]="{ 'has-error': fCoreCompany.controls.companyName.touched && fCoreCompany.controls.companyName.errors }">
                            <label for="company">Company</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCoreCompany.controls.companyName.value }}</div>
                            <div *ngIf="displayMode !== DisplayModeEnum.View" class="ws-flex-auto input-group">
                                <input type="text"
                                       class="form-control"
                                       autocomplete="off"
                                       placeholder="Filter Companies..."
                                       [typeaheadWaitMs]="750"
                                       formControlName="companyName"
                                       [typeahead]="companiesDataSource$"
                                       typeaheadOptionField="companyName"
                                       [typeaheadOptionsLimit]="50"
                                       [typeaheadMinLength]="1"
                                       [typeaheadScrollable]="true"
                                       (typeaheadOnSelect)="onCompanySelected($event)"
                                       (typeaheadLoading)="onCompanyLoadingChange($event)"
                                       (blur)="onCompanyBlur()" />
                                <span class="input-group-text" *ngIf="companyEditing || !canEditCompany" [style.visibility]="companiesLoading ? 'visible' : 'hidden'">
                                    <i class="fa fa-spin fa-spinner"></i>
                                </span>
                                <button class="btn btn-outline-secondary btn-sm" title="Edit company" *ngIf="canEditCompany && !companyEditing" (click)="editCompany()">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button class="btn btn-outline-secondary btn-sm" title="Save company" *ngIf="companyEditing" (click)="saveCompany()" [disabled]="!selectedCompanyId.getRawValue()">
                                    <i class="fa fa-save"></i>
                                </button>
                            </div>
                            <div *ngIf="fCoreCompany.controls.companyName.touched && fCoreCompany.controls.companyName.errors" class="error-feedback">
                                <div class="text-danger small" *ngIf="fCoreCompany.controls.companyName.errors.required">Company is required.</div>
                            </div>
                        </div>

                        <div class="mb-3" formGroupName="assessorGroup">
                            <label for="assessor">Assessor</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCoreAssessor.controls.assessorName.value || 'None' }}</div>
                            <div *ngIf="displayMode !== DisplayModeEnum.View" class="input-group">
                                <input type="text"
                                       class="form-control"
                                       autocomplete="off"
                                       formControlName="assessorName"
                                       placeholder="Filter Assessors..."
                                       [typeahead]="assessors"
                                       typeaheadOptionField="name"
                                       [typeaheadOptionsLimit]="100"
                                       [typeaheadMinLength]="0"
                                       [typeaheadScrollable]="true"
                                       (typeaheadOnSelect)="onAssessorSelected($event)"
                                       (blur)="onAssessorBlur()" />
                                <button class="btn btn-outline-secondary" title="Clear assessor" (click)="onAssessorClear()" [disabled]="!clearAssessorEnabled">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="retiredTaxYear">Retired Tax Year</label>
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ fCore.controls.tableRetiredTaxYear.value }}</div>
                            <ws-select
                                *ngIf="displayMode !== DisplayModeEnum.View"
                                formControlName="tableRetiredTaxYear"
                                [options]="taxYears"
                                [isPrimitive]="true"
                                [canDeselect]="true"
                                deselectLabel="">
                            </ws-select>
                        </div>
                    </div>
                </div>

                <div class="ws-section ws-flex-none">
                    <div class="ws-section__header">
                        <h5 class="ws-section__header__title">Floor Values</h5>
                    </div>
                </div>

                <div class="table-responsive floor-table" style="overflow-x: auto;">
                    <table class="table table-condensed table-borderless factor-edit-table" style="width: auto; max-width: initial;">
                        <tbody formArrayName="floors">
                        <tr *ngFor="let floorGroup of fFloors.controls; let ageIndex = index" [formGroup]="$any(floorGroup)">
                            <td *ngFor="let floorControl of $any(floorGroup).controls.floors.controls; let yearIndex = index" formArrayName="floors">
                                <div *ngIf="displayMode === DisplayModeEnum.View"><span *ngIf="fCore.controls.tableType.value === 0">$</span>{{ floorControl.value }}</div>
                                <div *ngIf="displayMode !== DisplayModeEnum.View">
                                    <div class="input-group factor-input-group">
                                        <span class="input-group-text">$</span>
                                        <input type="text" class="form-control ws-text-align-right" [formControlName]="yearIndex" [attr.tabindex]="100 + (yearIndex * fAges.length) + yearIndex + (fAges.length + 1)" />
                                    </div>
                                    <div *ngIf="floorControl.dirty && floorControl.errors" class="error-feedback" style="text-align: right">
                                        <div class="text-danger small" *ngIf="floorControl.errors.min">Must be grater than 0.</div>
                                        <div class="text-danger small" *ngIf="floorControl.errors.max">Value is too big.</div>
                                        <div class="text-danger small" *ngIf="floorControl.errors.pattern">Invalid value.</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div [class.active]="selectedView === PanelView.List" class="factor-table-panel">
            <div class="ws-section ws-flex-none">
                <div class="ws-section__header">
                    <h5 class="ws-section__header__title">Factors</h5>
                    <div class="ws-section__header__actions" *ngIf="displayMode !== 2">
                        <div class="ws-section__header__actions__item">
                            <span class="anchor-style" (click)="addAge()">Add Age</span>
                        </div>
                        <div class="ws-section__header__actions__item">
                            <span class="anchor-style" (click)="addYear()">Add Year</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive factors-grid">
                <table class="table table-condensed factor-edit-table">
                    <thead>
                    <tr formArrayName="years">
                        <th></th>
                        <th *ngFor="let yearControl of fYears.controls; let yearIndex = index">
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ yearControl.value }}</div>
                            <div *ngIf="displayMode !== DisplayModeEnum.View" class="input-group" [ngClass]="{ 'has-error': yearControl.touched && yearControl.errors && yearControl.errors.required }">
                                <input type="number" class="form-control" placeholder="Enter tax year..." [formControlName]="yearIndex" [attr.tabindex]="100 + (yearIndex * fAges.length) + yearIndex + 1" />
                                <span class="input-group-text clickable">
                                    <i class="fa fa-trash fa-2x"  title="Remove tax year" (click)="removeYear(yearIndex)"></i>
                                </span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody formArrayName="ages">
                    <tr *ngFor="let ageGroup of fAges.controls; let ageIndex = index" [formGroup]="$any(ageGroup)">
                        <td>
                            <div class="age-header d-flex justify-content-between align-items-center">
                                <span>Age {{ ageIndex + 1 }}</span>
                                <i *ngIf="displayMode !== DisplayModeEnum.View" class="fa fa-trash clickable" (click)="removeAge(ageIndex)"></i>
                            </div>
                        </td>
                        <td *ngFor="let factorControl of $any(ageGroup).controls.factors.controls; let yearIndex = index" formArrayName="factors">
                            <div *ngIf="displayMode === DisplayModeEnum.View">{{ factorControl.value }}<span *ngIf="fCore.controls.tableType.value === 0">%</span></div>
                            <div *ngIf="displayMode !== DisplayModeEnum.View">
                                <div class="input-group factor-input-group">
                                    <input type="number" class="form-control" [formControlName]="yearIndex" [attr.tabindex]="100 + (yearIndex * fAges.length) + yearIndex + (ageIndex + 1)" />
                                    <span *ngIf="fCore.controls.tableType.value === 0" class="input-group-text">%</span>
                                </div>
                                <div *ngIf="factorControl.dirty && factorControl.errors" class="error-feedback" style="text-align: right">
                                    <div class="text-danger small" *ngIf="factorControl.errors.min">Must be grater than 0.</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- error feedback for the factor table grid -->
            <div class="error-feedback">
                <div class="text-danger small" *ngIf="fYearsHaveValidationErrorsRequired">Tax years are required and cannot be left empty.</div>
                <div class="text-danger small" *ngIf="fYearsHaveValidationErrorsUnique">Tax year values must be unique and cannot be duplicated.</div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
