import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class GLAccountRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    start(companyId: number): Observable<Compliance.GLAccountStartResultModel> {
        return this.httpGet(`/api/glAccount/company/${companyId}/start`);
    }

    get(glAccountId: number): Observable<Compliance.GLAccountModel> {
        return this.httpGet(`/api/glAccount/${glAccountId}`);
    }

    getList(companyId: number, searchModel: Core.SearchModel<Compliance.GLAccountPropertyEnum>): Observable<Compliance.QueryResultModel<Compliance.GLAccountModel>> {
        return this.httpPost(`/api/glAccount/company/${companyId}`, searchModel);
    }

    getRowIds(companyId: number, searchModel: Core.SearchModel<Compliance.GLAccountPropertyEnum>): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/glAccount/company/${companyId}/rowIds`, searchModel);
    }

    create(model: Compliance.GLAccountModel): Observable<Compliance.GLAccountModel> {
        return this.httpPost(`/api/glAccount/`, model);
    }

    update(model: Compliance.GLAccountModel): Observable<Compliance.GLAccountModel> {
        return this.httpPut(`/api/glAccount/${model.glAccountId}`, model);
    }

    bulkUpdate(companyId: number, updateModel: Compliance.GLAccountBulkUpdateModel): Observable<void> {
        return this.httpPut(`/api/glAccount/company/${companyId}/bulk/`, updateModel);
    }

    bulkUpdateIncomeStatementTypes(companyId: number, updateModel: Compliance.GLAccountBulkUpdateIncomeStatementTypesModel): Observable<void> {
        return this.httpPut(`/api/glAccount/company/${companyId}/bulk-income-statement-types/`, updateModel);
    }

    delete(glAccountId: number): Observable<void> {
        return this.httpDelete<void>(`/api/glAccount/${glAccountId}`);
    }

    startExport(companyId: number, exportModel: Compliance.GLAccountExportModel) : Observable<number>{
        return this.httpPost(`/api/glAccount/company/${companyId}/export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/glAccount/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    selectionSummary(companyId: number, searchModel: Core.SearchModel<Compliance.GLAccountPropertyEnum>, selectedRowsModel: Compliance.SelectedRowsModel): Observable<Compliance.GLAccountBulkSelectionSummaryResponse> {
        let request: Compliance.GLAccountBulkSelectionSummaryRequest = {
            searchModel: searchModel,
            selectedRows: selectedRowsModel
        };
        return this.httpPost(`/api/glAccount/company/${companyId}/bulk-selection`, request);
    }
}
