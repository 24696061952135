import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

export interface ISimpleTableColumnDefinition {
    /** A unique identifier for this column, which is also a dottable path into the row item to find the display value */
    field: string;

    /** Text to display in this column's header */
    headerName: string;
}

/**
 * Input for SimpleTable element
 * @template TItem The type of model used for this element's items
 */
export interface ISimpleTableInput<TItem = object> {
    /** The rows to display */
    rows: TItem[];

    /** (optional) Rows to display in the footer.  Default: no footer rows */
    footerRows?: TItem[];

    /** The columns to display */
    columns: ISimpleTableColumnDefinition[];
}

@Component({
    selector: 'ace-simple-table',
    templateUrl: './simpleTable.component.html',
    styleUrls: ['./simpleTable.component.scss']
})
export class AceSimpleTableComponent {
    @Input() input: ISimpleTableInput;
}
