import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReturnAssetDragAndDropService } from './returnAssetDragAndDrop.service';
import { AgGridMultiSelectTracker } from '../../../../AgGrid/MultiSelectTracker'
import { Subject } from 'rxjs';
import { ReturnService } from '../../../return.service';
import { takeUntil } from 'rxjs/operators';

export interface ICellRendererParamsForReturnAssetDragAndDropSource extends ICellRendererParams {
    tracker: AgGridMultiSelectTracker
}

@Component({
    selector: 'return-asset-drag-and-drop-source',
    template: `
                <div
                    *ngIf="params.data && isRowSelected && canEdit && isServiceActive && !isReadOnly"
                    title="Drag and drop assets on the desired schedule."
                    [attr.dragula-bag-name]="dragulaBagName" [attr.dragula-container-type]="dragulaContainerType"
                    [dragula]="dragulaBagName">
                    <i class="fa fa-bars drag-source-element"></i>
                </div>
              `
})
export class ReturnAssetDragAndDropSourceComponent implements ICellRendererAngularComp {
    constructor(
        private readonly _returnAssetDragAndDropService: ReturnAssetDragAndDropService,
        private readonly _returnService: ReturnService) {
        this.dragulaContainerType = this._returnAssetDragAndDropService.CONTAINER_TYPE_SOURCE;
        this.dragulaBagName = this._returnAssetDragAndDropService.BAG_NAME;
    }

    private _tracker: AgGridMultiSelectTracker;
    private _rowId: number;
    private _destroy$: Subject<void> = new Subject();

    params: ICellRendererParamsForReturnAssetDragAndDropSource;

    isServiceActive: boolean;
    isReadOnly: boolean;
    canEdit: boolean;
    dragulaContainerType: string;
    dragulaBagName: string;

    get isRowSelected(): boolean {
        return this._tracker.isRowSelected(this._rowId);
    }

    agInit(params: ICellRendererParamsForReturnAssetDragAndDropSource): void {
        this.params = params;
        this._tracker = params.tracker;
        this._rowId = params.value;

        this.canEdit = this._returnService.canEditCompany;

        this._returnAssetDragAndDropService.active$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isServiceActive = x);

        this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isReadOnly = x);
    }

    ngOnDestroy(): void { 
        this._destroy$.next();
        this._destroy$.complete();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
