(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('PopupParentController', PopupParentController);

    PopupParentController.$inject = ['$scope', '$window', 'sd.Popup.Service', 'sd.PubSub.Service'];

    function PopupParentController($scope, $window, popup, pubSub) { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
        var vm = this;
        vm.title = 'PopupParentController';
        vm.event = {
            timestamp: new Date(),
            message: '(no event published yet)'
        };

        var childWindowData = null;

        activate();

        ////////////////

        function activate() {
            pubSub.subscribe(pubSub.topics.processing.documents.attachmentChanged, function (msg, data) {
                vm.event.timestamp = new Date();
                vm.event.message = data;
                $scope.$apply();
            });
            pubSub.subscribe(pubSub.topics.processing.documents.actionViewNavigate, function (msg, data) {
                $window.location = data;
            });
        }

        $scope.$on('$destroy', function () {
            // Clean up subscriptions to prevent leaks
            pubSub.unsubscribe(pubSub.topics.processing.documents.attachmentChanged);
            pubSub.unsubscribe(pubSub.topics.processing.documents.actionViewNavigate);
            popup.orphanWindow(childWindowData);
        });

        vm.publish = function () {
            pubSub.publish(pubSub.topics.processing.documents.attachmentChanged, pubSub.eventScopes.default, 'Event published from current page');
        };

        //vm.publishMultiWindow = function () {
        //    pubSub.publish(pubSub.topics.processing.documents.attachmentChanged, pubSub.eventScopes.multiWindow, 'Event published to all windows/tabs');
        //};

        vm.publishChild = function () {
            pubSub.publish(pubSub.topics.processing.documents.attachmentChanged, pubSub.eventScopes.popupChild, 'Event published from parent page', childWindowData);
        };

        vm.openPopup = function () {
            childWindowData = popup.openPopup('#/styles/popupchild', 'popup-demo');
        };
    }
})();
