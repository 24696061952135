import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '../Directives/directives.module';
import { SharedPipesModule } from '../Pipes/shared-pipes.module';
import { AceBannerComponent } from './Banner/banner.component';
import { AceButtonGroupComponent } from './Button-Group/buttonGroup.component';
import { AceButtonComponent } from './Button/button.component';
import { AceDropdownComponent } from './Dropdown/dropdown.component';
import { AceIconComponent } from './Icon/icon.component';
import { AceIconModule } from './Icon/icon.module';
import { AceSelectComponent } from './Select/select.component';
import { AceSelectModule } from './Select/select.module';
import { AceSimpleTableComponent } from './Simple-Table/simpleTable.component';
import { AceSimpleTableModule } from './Simple-Table/simpleTable.module';
import { AceTabSetComponent } from './Tab-Set/tabSet.component';
import { AceTabSetModule } from './Tab-Set/tabSet.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DirectivesModule,
        SharedPipesModule,
        AceSelectModule,
        AceIconModule,
        AceTabSetModule,
        AceSimpleTableModule
    ],
    declarations: [
        AceBannerComponent,
        AceButtonComponent,
        AceButtonGroupComponent,
        AceDropdownComponent
    ],
    exports: [
        AceBannerComponent,
        AceButtonComponent,
        AceButtonGroupComponent,
        AceDropdownComponent,
        AceIconComponent,
        AceSelectComponent,
        AceSimpleTableComponent,
        AceTabSetComponent
    ]
})
export class AceModule {

}
