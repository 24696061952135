import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
    selector: 'entity-import-editor-estimated-action-header',
    template: `<span>
                    <i class="fa fa-database" title="Database transfer action"></i>
               </span>`
})
export class EntityImportEditorEstimatedActionHeaderRendererComponent implements IHeaderAngularComp {
    private _params: IHeaderParams;

    agInit(params: IHeaderParams): void {
        this._params = params;
    }
}
