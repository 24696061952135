<div class="ws-flex-container-vertical ws-flex-auto ws-stretch allocation-details" *ngIf="isInitialized">
    <div class="ws-flex-container-horizontal ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="allocationDescription"></ws-bread-crumbs>
        </div>
    </div>
    <div class="ws-section ws-flex-none">
        <div class="ws-section__header">
            <div class="ws-section__header__title ws-flex-align-items-center ws-flex-justify-content-space-between ws-flex-container-horizontal-wrap">
                <div class="ws-flex-container-horizontal ws-flex-grow">
                    <strong>Lien Date:</strong>
                    <div>{{ allocation.lienDate.toISOString() | slice: 0 : 10 | date : 'MM/dd/yyyy' }}</div>
                </div>
                <div class="ws-flex-container-horizontal ws-flex-grow">
                    <strong>Assessor{{assessors.length > 1 ? 's' : ''}}:</strong>
                    <div class="ws-flex-container-horizontal-wrap">
                        <ng-container *ngFor="let assessor of assessors; let last = last">
                            <a class="btn btn-link pointer"
                               anchor-route
                               [isTargetBlank]="true"
                               [sref]="assessor">{{ assessor.name }}</a>
                            <span *ngIf="!last">,&nbsp;</span>
                        </ng-container>
                    </div>
                </div>
                <div class="ws-flex-container-horizontal ws-flex-grow">
                    <strong>Site:</strong>
                    <div>{{ allocation.siteName }}</div>
                </div>
            </div>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper">
                        <button type="button" class="flat-button small-button primary-button"
                                (click)="retrieveAssets()">
                            Retrieve Assets
                        </button>
                    </div>
                    <div class="action-button-wrapper">
                        <begin-new-import [hidden]="!uploadNewImport" #newImport label="Import Work Papers"
                                          [canCloseProgressModal]="true" [isDisabled]="false" [companyId]="companyId"
                                          [allocationId]="allocationId" buttonSize="small"></begin-new-import>
                        <button *ngIf="!uploadNewImport" class="flat-button small-button primary-button"
                                position="bottom"
                                (click)="navigateToLastImport()">
                            Import Work Papers
                        </button>
                    </div>
                </div>
                <div class="action-button-wrapper">
                    <button
                        type="button" class="flat-button small-button primary-button"
                        [disabled]="!hasLockedNotReviewedAssessments"
                        (click)="markAllocationReviewed()">
                        Mark Allocation Reviewed
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <entity-attachments buttonClass="flat-button small-button primary-button"
                                        [(hasAttachments)]="allocation && allocation.hasAttachments"
                                        [modalData]="attachmentsModel"
                                        position="bottom">
                    </entity-attachments>
                </div>
                <div class="ws-section__header__actions__item">
                    <entity-comments buttonClass="flat-button small-button primary-button"
                                     [(hasComments)]="allocation && allocation.hasComments"
                                     [modalData]="commentsModel"
                                     position="bottom">
                    </entity-comments>
                </div>
                <div class="ws-section__header__actions__item">
                    <div position="bottom"
                         helpTooltip
                         helpContentId="app.refresh">
                        <button type="button" class="btn flat-button small-button secondary-button"
                                (click)="refresh()" [disabled]="refreshing">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="ws-flex-auto ws-flex-container-vertical assessments-summary-list"
             ws-resizable
             [rFlex]="true"
             [rMinWidth]="400"
             rDragAxis="x"
             rDragPosition="right">
            <div class="ws-flex-container-horizontal ws-flex-justify-content-space-between table-header">
                Assessments
                <div class="ws-flex-container-horizontal">
                    <div
                         helpTooltip
                         helpContentId="allocations.new-assessment">
                        <button type="button"
                                class="flat-button small-button primary-button form-filter"
                                (click)="addTaxAuthority()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div helpTooltip helpContentId="allocations.assessment-size-available">
                        <button type="button"
                                class="flat-button small-button secondary-button form-filter"
                                (click)="sizeToFit()">
                            <i class="fa fa-arrows-h"></i>
                        </button>
                    </div>
                    <div helpTooltip helpContentId="allocations.assessment-size-cell-content">
                        <button type="button"
                                class="flat-button small-button secondary-button form-filter"
                                (click)="sizeToContent()">
                            <i class="fa fa-text-width"></i>
                        </button>
                    </div>
                    <div helpTooltip helpContentId="allocations.assessment-filter-toggle">
                        <button type="button"
                                class="flat-button small-button secondary-button form-filter"
                                (click)="toggleAssessmentGridFilters()">
                            <i class="fa fa-filter"></i>
                        </button>
                    </div>
                </div>
            </div>
            <ws-ag-grid-angular gridClass="allocation-assessments-theme"
                                [gridOptions]="assessmentGridOptions"
                                (gridReady)="onAssessmentAgGridReady($event)">
            </ws-ag-grid-angular>
            <div class="ws-flex-container-horizontal summary-controls">
                <div class="search-filter ws-stretch-width">
                    <label class="select-search-label">
                        <input #filter
                               type="text"
                               class="form-control ws-stretch-width"
                               [ngModel]="assessmentsSummaryFilter"
                               (keyup.enter)="filterAssessmentsSummary(filter.value)"/>
                        <i class="fa fa-search"></i>
                        <i class="fa fa-times warning" (click)="filterAssessmentsSummary(null)" *ngIf="isFiltered"></i>
                    </label>
                </div>
            </div>
            <div class="ws-flex-none mb-3 filter">
                <div class="filter-header">
                    <div class="filter-title">Actions:</div>
                </div>
                <div class="input-group" style="display: flex">
                    <ws-select
                        [(ngModel)]="selectedAction"
                        [options]="assessmentActions"
                        (selectedOptionChanged)="onActionSelected($event)"
                        [disabled]="allAssessmentActionsDisabled"
                        style="flex-grow: 1">
                    </ws-select>
                </div>
            </div>
        </div>
        <div class="ws-flex-container-vertical ws-stretch allocation-table">
            <div class="ws-flex-container-horizontal ws-flex-auto table-header ws-flex-align-items-center">
                <div class="ws-flex-auto">
                    Assets
                    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
                </div>
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <div>Management Review:</div>
                    <div class="input-group" style="display: flex; padding-right: 10px; padding-left: 10px;">
                        <ws-select
                            [(ngModel)]="managementReviewSelectedOption"
                            [options]="managementReviewOptions"
                            (selectedOptionChanged)="refreshAssetsList()"
                            style="flex-grow: 1">
                        </ws-select>
                    </div>
                </div>
                <div class="ws-flex-container-horizontal form-filter checkbox">
                    <label for="hideNotReportedAssets" style="padding-right: 5px;">
                        <input type="checkbox"
                               id="hideNotReportedAssets"
                               [(ngModel)]="hideNotReportedAssets"
                               (change)="onHideNotReportedAssetsChange()"
                               [disabled]="!hasLockedAssessments">Hide not reported assets
                    </label>
                </div>
            </div>
            <ws-ag-grid-angular wrapperClass="assets-list"
                                [gridOptions]="gridOptions"
                                (gridReady)="onAgGridReady($event)"
                                [gridId]="gridId"
                                [companyId]="companyId"
                                [defaultSizeToFit]="false"
                                [defaultColorizeHeaders]="true"
                                [exportOptions]="exportOptions"
                                [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                                (bulkUpdate)="bulkUpdate()"
                                [additionalActions]="gridActions">
            </ws-ag-grid-angular>
        </div>
    </div>
</div>

