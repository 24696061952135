import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const ASSET_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'asset-list.lien-date',
        tooltipText: 'The lien date is used to determine which assets to display in the list and which source values to display for each asset. By default, any assets disposed prior to the selected lien date are not displayed in the list.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-list.asset-changes-filter',
        tooltipText: 'Filtering to identify assets with specific statuses and status changes.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-list.further-limit-to',
        tooltipText: 'Additional filtering to identify assets with specific field overrides and other miscellaneous options.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-list.new-asset',
        tooltipText: 'New asset'
    },
    {
        helpContentId: 'asset-list.reporting-values-view',
        tooltipText: 'Displays the reporting and source values for assets based on the selected lien date.'
    },
    {
        helpContentId: 'asset-list.imported-values-view',
        tooltipText: 'Displays the source values based on the lien date or the import file.'
    },
    {
        helpContentId: 'asset-list.view-last-return',
        tooltipText: 'View Filing'
    },
    {
        helpContentId: 'asset-list.apply-filters',
        tooltipText: 'Apply filters and load asset list'
    }
];
