// Combination of
// https://stackoverflow.com/a/50736992/18059803
// and
// https://stackoverflow.com/a/43589069/18059803

// This directive does two things:
// Converts a decimal to a percentage for a display (but keeps the decimal for saving)
// and
// Allows the consuming component to specify the number of decimal places to display

// Usage
// <input type="text" toPercentage [precision]="3" [(ngModel)]="percentageInDecimalForm">

import { Directive, ElementRef, Input, forwardRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    selector: '[toPercentage]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ToPercentDirective),
        multi: true
    }],
    host: {
        // "[value]": 'ngModel',
        '(blur)': 'onBlur()'
    }
})
export class ToPercentDirective implements ControlValueAccessor {
    @Input() precision: number

    constructor(private el: ElementRef) {
        this.el.nativeElement.style.textAlign = 'right'
    }

    private innerValue: string;
    private toNumber: number;
    private toPercent: number;
    private regex: RegExp = new RegExp(/^\d*\.?\d{0,3}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    public onChangeCallback: any = (_) => { console.log(_) }
    public onTouched: any = () => { /*Empty*/ }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
      // Allow Backspace, tab, end, and home keys
      if (!this.precision || this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      let current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
      if (next && !String(next).match(new RegExp("^\\d*\\.?\\d{0," + this.precision + "}$","g"))) {
        event.preventDefault();
      }
    }

    onBlur() {
        let input = this.el.nativeElement.value;
        if (!input) {
            input = 0;
        }
        this.toPercent = new Decimal(input).dividedBy(100).toNumber()
        if (input !== this.toNumber) {
            this.onChangeCallback(this.toPercent);
        }
    }

    get value(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    writeValue(val: string): void {
        // this.ngModel.ControlValueAccessor.writeValue(val);
        this.toNumber = new Decimal(val || 0).times(100).toNumber();
        if (this.toNumber !== 0) {
            this.el.nativeElement.value = this.toNumber.toFixed(this.precision);
        }
    }

    registerOnChange(fn: any): void {
        // console.log(fn);
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any): void {
        // console.log(fn);
        this.onTouched = fn;
    }
}
