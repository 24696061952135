<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<div *ngIf="viewModel">
    <add-filing-modal #AddFilingModal
                      *ngIf="currentYear"
                      [annualYearID]="currentYear.annualYearID"
                      [filingTypes]="filingTypes"
                      [isMergedParcel]="isMergedParcel"
                      (add)="addFiling($event)">
    </add-filing-modal>
    <div *ngIf="viewModel.model.length === 0" class="text-center">
        <div class="lead">
            There are presently no compliance filings for {{ currentYear.annualYear1 }}.
        </div>
        <button class="btn flat-button primary-button" *ngIf="editState.hasWritePermission" (click)="openAddFiling()">New Compliance Filing</button>
    </div>
    <button type="button" *ngIf="viewModel.showTabs && editState.editMode" (click)="openAddFiling()" style="float:right;margin-top:0.5em;" class="flat-button small-button">New Compliance Filing</button>
    <tabset *ngIf="viewModel.model.length > 0 && viewModel.showTabs" #filingTabs>
        <ng-container *ngFor="let filing of viewModel.model; let i=index; let last=last">
            <tab [active]="i === viewModel.currentFilingIndex"
                 (selectTab)="switchTabs(i)">
                <ng-template tabHeading>
                    <span helpTooltip
                          helpContentId="annual-details.phantom-filing"
                          position="top"
                          [helpDisabled]="!filing.isFromConsolidatedParcel">
                        <i class="year-linked fa fa-chain" *ngIf="filing.isFromConsolidatedParcel"></i>
                        <i title="Filing batch" class="fa fa-files-o" *ngIf="filing.filingBatchId"></i> {{ getFilingHeading(filing) }}
                    </span>
                </ng-template>
                <ng-container [ngTemplateOutlet]="filingTemplate" [ngTemplateOutletContext]="{ $implicit: filing }"></ng-container>
            </tab>
        </ng-container>
    </tabset>
    <ng-container *ngIf="viewModel.currentFiling && !viewModel.showTabs"
                  [ngTemplateOutlet]="filingTemplate"
                  [ngTemplateOutletContext]="{ $implicit: viewModel.currentFiling }">
    </ng-container>
</div>

<ng-template #filingTemplate let-filing>
    <div class="text-center" style="margin-top: 50px;" *ngIf="isLoading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>

    <div *ngIf="!isLoading">
        <div class="float-end">
            <entity-in-progress-indicator *ngIf="filing.hasInProgressIntakeItems && !isDocumentProcessing" [entityId]="filing.filingId" [entityTypeId]="filingEntityTypeId" [isDocumentProcessing]="isDocumentProcessing"></entity-in-progress-indicator>
            <entity-attachments [(hasAttachments)]="filing.hasAttachments"
                                buttonClass="icon-button"
                                [modalData]="viewModel.attachmentModalData">
            </entity-attachments>
            <entity-comments [(hasComments)]="filing.hasComments"
                             buttonClass="icon-button"
                             [modalData]="viewModel.commentModalData">
            </entity-comments>
        </div>
        <table class="annual-appeal" (change)="setDirty()">
            <tr>
                <td>Filing Type:</td>
                <td>
                    <span>{{ filing.filingType.name }}</span>
                </td>
            </tr>
            <tr>
                <td>Description:</td>
                <td>
                    <span *ngIf="(!editState.editMode) || filing.isFromConsolidatedParcel">{{ filing.description }}</span>
                    <input type="text" *ngIf="editState.editMode && !filing.isFromConsolidatedParcel" class="form-control" [(ngModel)]="filing.description" maxlength="15">
                </td>
            </tr>
            <tr class="filing-due-date">
                <td>Due Date:</td>
                <td [ngClass]="{'overridden-due-date': dueDateOverridden(filing)}" style="position: relative;">
                    <weissman-datetime [(inputDate)]="filing.dueDate"
                                       [readOnly]="(!editState.editMode) || filing.isFromConsolidatedParcel || filing.filingBatchId"
                                       [dateOnly]="true"
                                       (validationChange)="onDueDateValidationChange($event)"
                                       (change)="onTaskRelevantFieldChanged()"
                                       [required]="true"
                                       (inputDateBlur)="revertDateCheck(filing)"
                                       #dueDate>
                    </weissman-datetime>
                    <button class="flat-button icon-button warning-button"
                            style="margin: 0; position: absolute; right: -31px; top: 4px;"
                            *ngIf="editState.hasWritePermission && editState.editMode && (!filing.isFromConsolidatedParcel) && dueDateOverridden(filing)"
                            (click)="resetDueDate(filing)">
                        <i class="fa fa-undo"></i>
                    </button>
                </td>
            </tr>
            <tr *ngIf="filing.filingBatch">
                <td>Batch Due Date:</td>
                <td style="position: relative;">
                    <span>{{ filing.filingBatch.dueDate | weissmanDateFormat: true }}</span>
                </td>
            </tr>
            <tr *ngIf="filing.isFromConsolidatedParcel">
                <td>Reported Cost (Merged):</td>
                <td>
                    <span>${{(filing.mergedReportedCost || 0) |  number: '1.0-0'}}</span>
                </td>
            </tr>
            <tr *ngIf="filing.isFromConsolidatedParcel">
                <td>Filed Value (Merged):</td>
                <td>
                    <span>${{(filing.mergedReportedValue || 0) |  number: '1.0-0'}}</span>
                </td>
            </tr>
            <tr *ngIf="!filing.isFromConsolidatedParcel">
                <td>Reported Cost:</td>
                <td>
                    <span *ngIf="(!editState.editMode) || filing.filingBatchId">${{ (filing.reportedCost || 0) | number: '1.0-0'}}</span>
                    <input type="text"
                           class="form-control input-number"
                           *ngIf="editState.editMode && !filing.filingBatchId"
                           [(ngModel)]="filing.reportedCost"
                           (ngModelChange)="setDirty()"
                           currencyMask
                           [options]="{ allowNegative: false, precision: 0, prefix: '' }" />
                </td>
            </tr>
            <tr *ngIf="!filing.isFromConsolidatedParcel">
                <td>Filed Value:</td>
                <td>
                    <span *ngIf="(!editState.editMode) || filing.filingBatchId">${{ (filing.value || 0) | number: '1.0-0'}}</span>
                    <input type="text"
                           class="form-control input-number"
                           *ngIf="editState.editMode && !filing.filingBatchId"
                           [(ngModel)]="filing.value"
                           (ngModelChange)="setDirty()"
                           currencyMask
                           [options]="{ allowNegative: false, precision: 0, prefix: '' }" />
                </td>
            </tr>
            <tr>
                <td>Status:</td>
                <td>
                    <div style="font-size: 14px;" *ngIf="viewModel.currentTaskSummary">
                        <i class="fa fa-check" [hidden]="!viewModel.currentTaskSummary.SeriesIsComplete"
                           style="color: green; float: left; font-size: 16px;"></i>
                        <span class="anchor-style" (click)="launchTaskModal(filing)"
                              tooltip="{{ this.editState.validationMessage }}" placement="left"
                              triggers="click focus" #filingTaskValidation="bs-tooltip">
                            {{ viewModel.currentTaskSummary.Status }}
                            <span *ngIf="viewModel.currentTaskSummary.CompletedDateTime">
                                {{ viewModel.currentTaskSummary.CompletedDateTime | weissmanDateFormat: true: "Central" }}
                            </span>
                        </span>
                    </div>

                    <div style="font-size: 14px;" *ngIf="!viewModel.currentTaskSummary">
                        N/A - No Responsibility
                    </div>
                </td>
            </tr>
            <tr *ngIf="filing.isFromConsolidatedParcel">
                <td>Consolidated Filing:</td>
                <td>
                    <span class="btn btn-link pointer" (click)="goToFiling(filing)">{{ getFilingHeading(filing) }}</span>
                </td>
            </tr>
        </table>
        <div>
            <button class="btn flat-button small-button warning-button"
                    type="button"
                    *ngIf="(!hideDelete) && editState.editMode && (!filing.filingBatchId) && (!filing.isFromConsolidatedParcel)"
                    (click)="deleteFiling(filing)">
                Delete Filing
            </button>
            <button type="button"
                    *ngIf="canCreateFilingBatch"
                    class="btn flat-button small-button primary-button"
                    (click)="createFilingBatch(filing)">
                Begin Preparation
            </button>
            <button type="button"
                    *ngIf="filing.filingBatch && (!filing.isFromConsolidatedParcel)"
                    class="btn flat-button small-button primary-button"
                    (click)="goToReturnFilingBatch(filing)">
                {{ getFilingBatchPrepareLabel(filing) }}
            </button>
        </div>
    </div>
</ng-template>
