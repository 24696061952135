import { Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IComponentRef } from '../../Return/Models/iComponentRef';
import { ComplianceInfoRepository } from '../../Repositories/complianceInfo.repository';
import { ReturnSettingsEntityTypeEnum } from '../../Return/Models/enums';

@Component({
    selector: 'compliance-info-panel',
    templateUrl: './complianceInfoPanel.component.html'
})
export class ComplianceInfoPanelComponent {
    constructor(private readonly _complianceInfoRepository: ComplianceInfoRepository) {
    }

    private _returnSettingsComponent: IComponentRef;
    private _reportingParcelSitesComponent: IComponentRef;

    @Input() siteId: number;
    @Input() parcelId: number;
    @Input() allowEdit: boolean;

    ReturnSettingsEntityTypeEnum: typeof ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    hasChanges: boolean;

    isShowInfo: boolean = false;
    editMode: boolean = false;
    loading: boolean = true;

    expandOrCollapse(): void {
        if(this.editMode) {
            return;
        }

        this.isShowInfo = !this.isShowInfo;

        if(this.isShowInfo) {
            this.showInfo();
        }
    }

    showInfo(): void {
        this.loading = true;
    }

    async save(): Promise<void> {
        try{
            this.loading = true;

            const saveModel: Compliance.ComplianceInfoGeneralReturnSettingsReportingSitesSaveModel = {
                returnSettings: this._returnSettingsComponent.getSaveModel(),
                sites: this._reportingParcelSitesComponent.getSaveModel()
            } as Compliance.ComplianceInfoGeneralReturnSettingsReportingSitesSaveModel;

            await lastValueFrom(this._complianceInfoRepository.saveByParcel(this.parcelId, saveModel));

            this._returnSettingsComponent.cancel();
            this._reportingParcelSitesComponent.cancel();

            const promises: Promise<any>[] = [
                this._returnSettingsComponent.load(),
                this._reportingParcelSitesComponent.load()
            ];
            await Promise.all(promises);

            this.editMode = false;
            this.hasChanges = false;

        } finally{
            this.loading = false;
        }
    }

    setEditMode(): void {
        this.editMode = true;
        this._returnSettingsComponent.edit();
        this._reportingParcelSitesComponent.edit();
    }

    cancelEdit(): void {
        this._returnSettingsComponent.cancel();
        this._reportingParcelSitesComponent.cancel();
        this.editMode = false;
    }

    onReturnSettingsInitialized($ref: IComponentRef) {
        this._returnSettingsComponent = $ref;
        console.log(this._returnSettingsComponent)
        this.loading = !(this._returnSettingsComponent && this._reportingParcelSitesComponent);
    }

    onReturnSettingsChanged(value: boolean){
        this.hasChanges = value;
    }

    onReportingParcelSitesInitialized($ref: IComponentRef) {
        this._reportingParcelSitesComponent = $ref;
        console.log(this._reportingParcelSitesComponent)
        this.loading = !(this._returnSettingsComponent && this._reportingParcelSitesComponent);
    }

    onReportingParcelSitesChanged(value: boolean){
        this.hasChanges = value;
    }
}
