import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom, Subject, Subscription } from 'rxjs';
import { ReturnRepository } from '../../Repositories';
import { ReturnService } from '../return.service';
import { ReturnAssetsService } from '../Return-Parts/Assets/returnAssets.service';
import { IReturnPartComponent } from '../Models/returnPartServiceBase';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { HelpContentComponentConfig } from '../../../UI-Lib/Help-Tooltip';
import { ReturnUpdateLogicService } from '../returnUpdateLogic.service';
import { ReturnBatchTaxYearAdditionalInfoComponent, ReturnBatchTaxYearAdditionalInfoComponentParams } from './returnBatchTaxYearAdditionalInfo.component';

interface TreeNode {
    id: number;
    name: string;
    level: number;
    groupSelection: GroupingType;
    totalsInfo: Totals;
    returnInfo: Compliance.ReturnTotalsModel;
    parent: () => TreeNode;
    children: TreeNode[];
    isChecked: boolean;
    isExpanded: boolean;
    isVisible: boolean;
}

interface Totals {
    assetCount: number;
    cost: number;
    id: number;
    returnCount: number;
    value: any;
}

interface GroupingType {
    returnGroupType: Compliance.ReturnGroupType;
    idParam: string;
    nameParam: string;
}

@Component({
    selector: 'return-batch',
    templateUrl: './returnBatch.component.html',
    styleUrls: ['./returnBatch.component.scss']
})
export class ReturnBatchComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnRepository: ReturnRepository,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService
    ) { }

    @ViewChild('listRow', { static: true }) listRow: ElementRef;
    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    taxYearAdditionalInfoComponent: HelpContentComponentConfig<ReturnBatchTaxYearAdditionalInfoComponent, ReturnBatchTaxYearAdditionalInfoComponentParams>;

    private readonly _GROUP_SELECTION_NONE: Compliance.NameValuePair<GroupingType> = {
        name: 'None',
        value: { returnGroupType: null, idParam: null, nameParam: 'parcelAcctNumberDisplay' }
    };
    private readonly _GROUP_SELECTION_ASSESSOR: Compliance.NameValuePair<GroupingType> = {
        name: 'Assessor',
        value: { returnGroupType: Compliance.ReturnGroupType.Assessor, idParam: 'assessorId', nameParam: 'assessorName' }
    };
    private readonly _GROUP_SELECTION_COMPANY: Compliance.NameValuePair<GroupingType> = {
        name: 'Company',
        value: { returnGroupType: Compliance.ReturnGroupType.Company, idParam: 'companyId', nameParam: 'companyName' }
    };
    private _assetDetailsUpdatedSub: Subscription;
    private _assetMappingUpdatedSub: Subscription;
    private _root: TreeNode;
    private _destroy$: Subject<void> = new Subject<void>();
    private _parcelsChangeDate: Date = null;

    // a number that keep track of all async requests fired when zero, it means the component is not busy and the busy indicator overlay is turned off
    busyCounter: number = 0;

    filingBatch: Compliance.FilingBatchModel;

    group1Option: UntypedFormControl = new UntypedFormControl(this._GROUP_SELECTION_ASSESSOR.value);
    selectOptionsForGroup1: Compliance.NameValuePair<GroupingType>[] = [
        this._GROUP_SELECTION_ASSESSOR,
        this._GROUP_SELECTION_COMPANY
    ];

    group2Option: UntypedFormControl = new UntypedFormControl(this._GROUP_SELECTION_NONE.value);
    selectOptionsForGroup2: Compliance.NameValuePair<GroupingType>[] = [
        this._GROUP_SELECTION_NONE,
        this._GROUP_SELECTION_COMPANY
    ];

    flatTree: TreeNode[] = [];
    returns: Compliance.ReturnTotalsModel[] = [];
    widestListItem: TreeNode;
    isExpanded: boolean = false;
    automaticRefresh: boolean = true;
    returnsSelectionApplied: boolean = true;
    selectedReturnsCount: number;
    isFiltered: boolean = false;
    returnsFilter: string = null;
    isPageLoading: boolean = false;
    isInitialized: boolean = false;
    batchListWidth: number = 175;

    ngOnInit(): void {
        this._returnService.start$.pipe(takeUntil(this._destroy$))
            .subscribe((x) => this._initialize());

        this._returnService.returnBatchFilter$.pipe(takeUntil(this._destroy$))
            .subscribe(returnIds => this._applyRemoteFilter(returnIds));

        this._busyIndicatorService.indicatorActive$.pipe(takeUntil(this._destroy$))
            .subscribe(active => this.isPageLoading = active);

        this._returnAssetsService.subscribeToServiceActivationCycle(this);

        this._returnService.parcelsChanged$.pipe(takeUntil(this._destroy$)).subscribe((x) => {
            if (x) {
                if (this._parcelsChangeDate !== x){
                    this._parcelsChangeDate = x;
                    this._initialize();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._assetDetailsUpdatedSub = this._returnAssetsService.assetDetailsUpdated$
            .subscribe(() => this._updateReturnTotals());
        this._assetMappingUpdatedSub = this._returnAssetsService.assetMappingsUpdated$
            .subscribe(() => this._updateReturnTotals());

        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    onReturnPartServiceDeactivated(): void {
        this._assetDetailsUpdatedSub && this._assetDetailsUpdatedSub.unsubscribe();
        this._assetMappingUpdatedSub && this._assetMappingUpdatedSub.unsubscribe();
    }

    nodeCheckChanged(node: TreeNode): void {
        this._applyIsCheckedStatusToTreePathDown(node);
        this._applyIsCheckedStatusToTreePathUp(node);
        // update selected returns
        if (this.automaticRefresh) {
            this._setSelectedReturns(true);
        } else {
            this.returnsSelectionApplied = false;
        }
    }

    // re-apply change when auto refresh turned back on
    onAutoRefreshChanged(checked: boolean) {
        if (checked) {
            this.refreshReturns();
        }
    }

    getWorkflowStatusDisplay(): string {
        return this._returnService.getProgressStatusDisplay();
    }

    toggleNodeIsExpanded(node: TreeNode): void {
        // flip isExpanded value for current node
        node.isExpanded = !node.isExpanded;

        if (node.isExpanded) {
            node.children.forEach(x => x.isVisible = true);
        }
        if (!node.isExpanded) {
            this._collapseAndHideChildren(node);
        }

        this.flatTree = this._getFlatChildren(this._root, true);
        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    selectOptionChanged(): void {
        // all possible options for group 2
        const group2SelectOptions = [
            this._GROUP_SELECTION_NONE,
            this._GROUP_SELECTION_ASSESSOR,
            this._GROUP_SELECTION_COMPANY
        ];

        // remove the item that is selected in group 1
        group2SelectOptions.splice(group2SelectOptions.findIndex(x => x.value === this.group1Option.value), 1);

        // if the currently selected group 2 option is not available then set to empty
        if (group2SelectOptions.findIndex(x => x.value === this.group2Option.value) === -1) {
            this.group2Option.setValue(this._GROUP_SELECTION_NONE.value);
        }

        this.selectOptionsForGroup2 = group2SelectOptions;

        this._initializeTree();
    }

    refreshReturns(): void {
        this.returnsSelectionApplied = true;
        this._setSelectedReturns();
    }

    async selectAllReturns(selectPositive: boolean = true): Promise<void> {
        const nodes = this._root.children;
        nodes.forEach(node => {
            node.isChecked = selectPositive;
            this._applyIsCheckedStatusToTreePathDown(node);
        });
        if (this.automaticRefresh) {
            this._setSelectedReturns(true);
        } else {
            this.returnsSelectionApplied = false;
        }
    }

    async filterReturns(): Promise<void> {
        this.returns = await this._fetchReturnTotals();
        this._initializeTree();
        this.isFiltered = !!this.returnsFilter;
    }

    async resetReturnFilter(): Promise<void> {
        this.returnsFilter = null;
        await this.filterReturns();
    }

    trackDataNodes(index: number, item: TreeNode): number {
        return item.id;
    }

    private async _initialize(): Promise<void> {
        this.returns = this._returnService.returnGroupDetails;
        this.filingBatch = this._returnService.filingBatch;

        this._initializeTree();

        this.taxYearAdditionalInfoComponent = {
            component: ReturnBatchTaxYearAdditionalInfoComponent,
            componentParams: {
                lienDate: this._returnService.lienDate,
                cutOffDate: this._returnService.cutOffDate,
                changeDetection: this._returnService.changeDetection,
                ages: this._returnService.ages
            }
        };

        this.isInitialized = true;
    }

    private async _fetchReturnTotals(): Promise<Compliance.ReturnTotalsModel[]> {
        this.busyCounter++;
        let totals = [] as Compliance.ReturnTotalsModel[];
        try {
            const returnTotals = await lastValueFrom(this._returnRepository.getReturnTotals(this._returnService.filingBatchId, this.returnsFilter));
            totals = returnTotals.returnTotals || [];
        } finally {
            this.busyCounter--;
        }
        return totals;
    }

    private async _updateReturnTotals(): Promise<void> {
        const updatedReturns = await this._fetchReturnTotals();
        const returnTotalsMap = updatedReturns.reduce((acc, x) => {
            const total = (acc.has(x.assessorId))
                ? acc.get(x.assessorId)
                : { assetCount: 0, cost: 0, id: null, returnCount: 0, value: null };
            total.assetCount += x.reportedAssetCount;
            total.cost += x.reportedCost;
            total.returnCount += 1;
            acc.set(x.assessorId, total);
            return acc;
        }, new Map<number, Totals>());
        this.flatTree.forEach(x => {
            if (x.groupSelection === this._GROUP_SELECTION_ASSESSOR.value) {
                 x.totalsInfo = returnTotalsMap.get(x.id);
            }
        });
        this._setSelectedReturns();
    }

    private _initializeTree(): void {
        this._root = {
            level: 0,
            isChecked: false,
            isExpanded: false
        } as TreeNode;

        const levels = [this.group1Option.value, this.group2Option.value];
        if (this.group2Option.value !== this._GROUP_SELECTION_NONE) {
            levels.push(this._GROUP_SELECTION_NONE.value);
        }

        this._root.children = this._getChildren(this._root, levels);

        this.flatTree = this._getFlatChildren(this._root, true);

        // automatically select all items
        this._root.isChecked = true;
        this._applyIsCheckedStatusToTreePath(this._root);

        // expand root node
        this.toggleNodeIsExpanded(this._root);
    }

    /**
     * This is in a recursive call where we know all the returns are loaded ahead of time
     * and the return information is being used to populate the tree nodes.
     * As we keep traversing down the tree, going multiple levels deep, in the following manner:
     *      (0) root > (1) get root children > (2) for each root child get its children > (3) ...
     * we must make sure that when querying the existing "returns" for a specific level.
     * The returns that we are looking at are filtered by the node values in the path that we're traversing, ie:
     *      Imagine the following path: company > assessor > parcel
     *      We query the returns and find two companies, "company_1" and "company_2".
     *      Now, next step, for "company_1" we have to find all assessor, again, we query the returns (data already loaded there)
     *      however, we must first filter them down by only returns that match "company_1" (as that is the path being traversed).
     *
     * While this filter path can be calculated as each node has a reference to its parent. For speed purposes, it is being passed as a parameter.
     * The filters path is an array of object, each object has got a key that matches the selected option and a value which is the Id of the selected option.
     * For the example above, if we made it all the way down to the parcel by going "company_1" > "assessor_1" > ...
     * The filters, when getting the children for "assessor_1" would be (in Tuple<Compliance.ReturnType, number> fashion)
     *      [ ['company',1], ['assessor',1] ]
     */
    private _getChildren(node: TreeNode, levels: GroupingType[], filtersForPathToNode: [string, number][] = []): TreeNode[] {
        const children: TreeNode[] = [];

        // filter the returns based on the collection of filters for the path that we are traversing
        const filteredReturns = this.returns.filter(x => {
            let matchesFiltersForPathToNode: boolean = true;
            for (let i = 0; i < filtersForPathToNode.length && matchesFiltersForPathToNode; i++) {
                matchesFiltersForPathToNode = x[filtersForPathToNode[i][0]] === filtersForPathToNode[i][1];
            }
            return matchesFiltersForPathToNode;
        });

        // building the children for the specified node
        const selectedOption = levels[node.level] || this._GROUP_SELECTION_NONE.value;
        const treeNodesForReturns = filteredReturns.map(x => {
            let totals = null;
            if (selectedOption && selectedOption.returnGroupType === Compliance.ReturnGroupType.Assessor) {
                totals = this.returns.reduce((acc, y) => {
                    if (y.assessorId === x.assessorId) {
                        acc.assetCount += y.reportedAssetCount;
                        acc.cost += y.reportedCost;
                        acc.returnCount += 1;
                    }
                    return acc;
                },
                    { assetCount: 0, cost: 0, id: null, returnCount: 0, value: null });
            }

            const newNode = {
                id: selectedOption && selectedOption.idParam && x[selectedOption.idParam],
                name: selectedOption && selectedOption.nameParam && x[selectedOption.nameParam],
                level: node.level + 1,
                groupSelection: selectedOption,
                totalsInfo: totals,
                returnInfo: (!selectedOption || selectedOption.returnGroupType === null) && x,
                parent: () => node,
                children: [],
                isVisible: node.level === 0,
                isExpanded: false,
                isChecked: false
            };

            if (!this.widestListItem || (newNode.name && newNode.name.length > this.widestListItem.name.length)) {
                this.widestListItem = newNode;
            }

            return newNode;
        });
        // add returns to children array (they are filtered so add them all as they all match the filter path)
        if (!selectedOption || selectedOption.returnGroupType === null) {
            children.push(...treeNodesForReturns);
        } else {
            treeNodesForReturns.forEach(x => (children.findIndex(y => y.id === x.id) === -1) && children.push(x));
        }

        // for each child record, set it's own children collection; add to the path filters object the values for the current node
        // make sure to skip "last level" (return) records -- for those there will be no group selection
        children
            .sort((a, b) => (a.name.localeCompare(b.name, undefined, { numeric: true })))
            .filter(x => x.groupSelection && x.id)
            .forEach(x => {
                const filter = filtersForPathToNode.slice();
                filter.push([x.groupSelection.idParam, x.id]);
                x.children = this._getChildren(x, levels, filter);
            });

        return children;
    }

    private _getFlatChildren(node: TreeNode, onlyVisible: boolean): TreeNode[] {
        const flatTree: TreeNode[] = [];
        node.children.forEach(x => {
            if (onlyVisible) {
                if (x.isVisible) {
                    flatTree.push(x, ...this._getFlatChildren(x, onlyVisible));
                }
            }
            else { flatTree.push(x, ...this._getFlatChildren(x, onlyVisible)); }
        });
        return flatTree;
    }

    private _getSelectedChildren(node: TreeNode): TreeNode[] {
        const checkedNodes: TreeNode[] = [];
        if (node && Array.isArray(node.children)) {
            node.children.forEach(x => {
                // if a node is checked and has return info (only want the deepest nodes; skipping other level nodes)
                x.isChecked && x.returnInfo && checkedNodes.push(x);
                checkedNodes.push(...this._getSelectedChildren(x));
            });
        }
        return checkedNodes;
    }

    private async _setSelectedReturns(fromFilterChange: boolean = false): Promise<void> {
        const selectedNodes = this._getSelectedChildren(this._root);
        const selectedReturns = selectedNodes.map(x => x.returnInfo);
        this.selectedReturnsCount = selectedReturns.length;
        if (fromFilterChange) {
            this._returnUpdateLogicService.startLoading();
            await this._returnService.setReturnFilterChanged(selectedReturns);
            await this._returnUpdateLogicService.parcelFilterChanged();
        } else {
            this._returnService.setReturns(selectedReturns);
        }
    }

    private _applyIsCheckedStatusToTreePath(node: TreeNode): void {
        this._applyIsCheckedStatusToTreePathDown(node);
        this._applyIsCheckedStatusToTreePathUp(node);
        // update selected returns
        if (this.automaticRefresh) {
            this._setSelectedReturns();
        } else {
            this.returnsSelectionApplied = false;
        }
    }

    // using in the recursion but don't set the returns
    private _applyIsCheckedStatusToTreePathUp(node: TreeNode): void {
        if (node.parent) {
            const parent = node.parent();
            const checkedNodeCount = parent.children.reduce((acc: number, x) => (x.isChecked) ? (acc += 1) : acc, 0);
            const indeterminateNodeCount = parent.children.reduce((acc: number, x) => (x.isChecked === null) ? (acc += 1) : acc, 0);
            if (checkedNodeCount === 0 && !indeterminateNodeCount) {
                parent.isChecked = false;
            } else if (checkedNodeCount === parent.children.length) {
                parent.isChecked = true;
            } else {
                parent.isChecked = null;
            }
            this._applyIsCheckedStatusToTreePathUp(parent);
        }
    }

    // using in the recursion but don't set the returns
    private _applyIsCheckedStatusToTreePathDown(node: TreeNode): void {
        node.children.forEach(x => {
            x.isChecked = node.isChecked;
            this._applyIsCheckedStatusToTreePathDown(x);
        });
    }

    private _collapseAndHideChildren(node: TreeNode): void {
        node.children.forEach(x => {
            x.isExpanded = false;
            x.isVisible = false;
            this._collapseAndHideChildren(x);
        });
    }

    // apply a filter supplied by the return service
    private _applyRemoteFilter(returnIds: number[]): void {
        const nodes = this._root.children;
        nodes.forEach(node => {
            node.isChecked = false;
            this._applyIsCheckedStatusToTreePathDown(node);
        });
        nodes.forEach(n => this._collapseAndHideChildren(n));
        const flatNodes = nodes.reduce((acc, x) => [...acc, ...this._getFlatChildren(x, false)], []);
        returnIds.forEach(id => {
            const node: TreeNode = flatNodes.find(n => (n.returnInfo && n.returnInfo.returnId) ? n.returnInfo.returnId === id : false);
            if (node) {
                if (node.parent && !node.parent().isExpanded) {
                    this._toggleNodeIsExpandedUp(node.parent());
                }
                node.isChecked = true;
                this._applyIsCheckedStatusToTreePath(node);
            }
        });
    }

    private _toggleNodeIsExpandedUp(node: TreeNode, toggleState?: boolean): void {
        if (node.parent) {
            this._toggleNodeIsExpandedUp(node.parent(), (toggleState) ? toggleState : !node.isExpanded);
            if (!toggleState) {
                this.toggleNodeIsExpanded(node);
            } else {
                node.isExpanded = !node.isExpanded;
                node.children.forEach(x => x.isVisible = toggleState);
            }
        }
    }
}
