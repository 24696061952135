import { BaseRepository } from '../Common/Data/base.repository';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid-community';
import { AccrualsColumnFiltersUI, ColumnFiltersOptionsEnum, JournalOptionsEnum } from './accruals.page.model';
import { AgGridColumns, AgGridFilterParams } from '../Compliance/AgGrid';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { CurrencyPipe, PercentPipe, DecimalPipe } from '@angular/common';
import { WeissmanDateFormatPipe } from '../UI-Lib/Pipes';
import { OrderedColDef } from '../Common/AgGrid/ag.grid.model';
import * as _ from 'lodash';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class AccrualsPageService extends BaseRepository {
    constructor(httpClient: HttpClient,
        private currencyPipe: CurrencyPipe,
        private percentPipe: PercentPipe,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe
        )
    {
        super(httpClient);
    }

    readonly rowFields: object[] = [{
        category: 'openTaxObligations',
        fields: ['amountTax', 'amountExpensed', 'amountUnexpensed', 'amountAdjustment']
    }, {
        category: 'periodContributions',
        fields: ['amountTotalExpense', 'amountTotalPayments', 'amountBaseExpense', 'amountTrueUp', 'amountPriorPeriodExpense', 'amountBasePayments', 'amountPenalty', 'amountInterest', 'amountExpenseVariance']
    }, {
        category: 'accrualBalances',
        fields: ['amountPeriodBegin', 'amountPeriodEnd', 'amountChange', 'amountAdjustment']
    }];

    getGridColumns(searchModel: Core.AccrualsGridSearchModel, columnFilters: AccrualsColumnFiltersUI, resultsModel: Core.AccrualsGridResultsModel): ColDef[] {
        let colGroups: ColGroupDef[] = [{
            headerName: '',
            children: this._getIdentityColumns(searchModel, resultsModel)
        }];

        if (columnFilters.openTaxObligations !== ColumnFiltersOptionsEnum.Hide) {
            colGroups = [...colGroups, {
                headerName: 'Open Tax Obligations',
                children: this._getOpenTaxObligations(columnFilters.openTaxObligations),
                headerClass: 'open-tax-obligations-header'
            }];
        }

        if (columnFilters.periodContributions != ColumnFiltersOptionsEnum.Hide) {
            colGroups = [...colGroups, {
                headerName: 'Period Contributions',
                children: this._getPeriodContributions(columnFilters, searchModel.filters),
                headerClass: 'period-contributions-header'
            }];
        }

        if (columnFilters.accrualBalances != ColumnFiltersOptionsEnum.Hide) {
            colGroups = [...colGroups, {
                headerName: 'Period Accrual Balance',
                children: this._getAccrualBalances(),
                headerClass: 'accrual-balances-header'
            }];
        }

        if (columnFilters.journalImpact != JournalOptionsEnum.Hide) {
            colGroups = [...colGroups, {
                headerName: 'Period Respective Journal Impact',
                children: this._getJournalImpact(columnFilters.journalImpact, resultsModel),
                headerClass: 'journal-impact-header'
            }];
        }

        if (columnFilters.journalBalances != JournalOptionsEnum.Hide) {
            colGroups = [...colGroups, {
                headerName: 'Ending Respective Balances',
                children: this._getJournalBalances(columnFilters.journalBalances, resultsModel),
                headerClass: 'journal-balances-header'
            }];
        }

        return colGroups;
    }

    //
    // API Calls
    //
    checkValidateSetup(tlCompanyId: number): Promise<Core.AccrualsValidateSetupResultModel> {
        return lastValueFrom(this.httpGet(`/api/company/${tlCompanyId}/accruals/validatesetup`));
    }

    getAccountingPeriods(tlCompanyId: number): Promise<Core.AccrualsGridAccountingPeriodsModel> {
        return lastValueFrom(this.httpGet(`/api/company/${tlCompanyId}/accruals/accountingperiods`));
    }

    getAvailableFilters(companyId: number): Promise<Core.AccrualsGridAvailableFiltersModel> {
        return lastValueFrom(this.httpGet(`/api/company/${companyId}/accruals/availablefilters`));
    }

    checkAccrualsGridThreshold(tlCompanyId: number, request: Core.AccrualsGridSearchModel): Promise<Core.AccrualsGridCheckThresholdResultModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accruals/grid/checkthreshold`, request));
    }

    getAccrualsGridData(tlCompanyId: number, request: Core.AccrualsGridSearchModel): Promise<Core.AccrualsGridResultsModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accruals/grid`, request));
    }

    startAccrualsGridGet(tlCompanyId: number, request: Core.AccrualsGridSearchModel): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accruals/grid/start`, request));
    }

    getAccrualsGridResults(tlCompanyId: number, longRunningProcessId: number): Promise<Core.AccrualsGridResultsModel> {
        return lastValueFrom(this.httpGet(`/api/company/${tlCompanyId}/accruals/grid/results/${longRunningProcessId}`));
    }

    closeAccountingPeriod(tlCompanyId: number, accountingPeriodId: number): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/AccountingPeriod/${accountingPeriodId}/freeze`, undefined));
    }

    openAccountingPeriod(tlCompanyId: number, accountingPeriodId: number): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/AccountingPeriod/${accountingPeriodId}/unfreeze`, undefined));
    }

    exportToExcel(tlCompanyId: number, exportRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accruals/grid/export`, exportRequest));
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/company/accruals/export/${longRunningProcessId}`, options);
    }

    applyBulkUpdateToEntry(tlCompanyId: number, request: Core.AccrualsBulkUpdateRequestModel): Promise<Core.AccrualsBulkUpdateResultModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accruals/bulkupdate`, request));
    }

    createExportFile(tlCompanyId: number, accountingPeriodId: number): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this.httpPost('/api/company/Accrual/ExportFile', {
            tlCompanyId: tlCompanyId,
            accountingPeriodId: accountingPeriodId
        }));
    }

    startTransmitFile(accountingPeriodId: number, tlCompanyId: number): Promise<Compliance.LongRunningProcessModel> {
        return lastValueFrom(this.httpPost(`/api/company/${tlCompanyId}/accrual/accountingPeriod/${accountingPeriodId}/transmit`, {
            tlCompanyId: tlCompanyId,
            accountingPeriodId: accountingPeriodId
        }));
    }

    getLongRunningProcessStatus(tlCompanyId: number): Promise<Core.AccrualLongRunningProcessStatus> {
        return lastValueFrom(this.httpGet(`/api/company/${tlCompanyId}/Accrual/LongRunningProcessStatus`));
    }

    private _getIdentityColumns(searchModel: Core.AccrualsGridSearchModel, resultsModel: Core.AccrualsGridResultsModel): ColDef [] {
        let cols = [this._getCheckboxColumn(), ...this._getEntityCols(searchModel.filters.summarizeBy, resultsModel), this._getStateCol(), this._getActivityStatusColumn()];

        if (resultsModel.characteristics.length) {
            cols = [...cols, ...this._getAccrualCharacteristicsColumns(resultsModel)];
        }

        if (searchModel.mode == Core.AccrualsGridViewModeEnum.PeriodRange) {
            cols = [...cols, ...this._getPeriodRangeColumns()];
        }

        return cols;
    }

    private _getAccrualCharacteristicsColumns(resultModel: Core.AccrualsGridResultsModel): ColDef[] {
        const duplicates = resultModel.characteristics.reduce((acc, x) => {
            acc[x.descriptorName] = acc.hasOwnProperty(x.descriptorName);
            return acc;
        }, {});
        return resultModel.characteristics.map((x, i) => {
            let type = '';
            if (duplicates[x.descriptorName]) {
                if (x.entityTypeId === 5) {
                    type = 'Site';
                } else if (x.entityTypeId === 6) {
                    type = 'Parcel';
                }
            }

            let filter = 'agTextColumnFilter';
            let filterParams = AgGridFilterParams.textFilterWithBlankOptionsParams;
            let floatingFilterComponentParams = AgGridFilterParams.textFloatingFilterParams;
            let columnWidth: number = AgGridColumns.textColumnMedWidth;
            let valueFormatter: any;
            switch(x.descriptorFieldTypeId) {
                case Core.DescriptorFieldTypes.Number:
                case Core.DescriptorFieldTypes.Currency:
                    filter = 'agNumberColumnFilter';
                    filterParams = AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams;
                    floatingFilterComponentParams = AgGridFilterParams.numberFloatingFilterParams;
                    columnWidth = AgGridColumns.numericColumnWidth;
                    if ( x.descriptorFieldTypeId == Core.DescriptorFieldTypes.Currency ) {
                        valueFormatter = (params) => {
                            if (params.value === null || params.value === undefined || params.value === '') {
                                return '';
                            }
                            const numCurrency: number = parseFloat(params.value);
                            if ( isNaN(numCurrency) ) {
                                return '';
                            }
                            return this._formatCurrency(numCurrency);
                        };
                    }
                    else {
                        valueFormatter = (params) => this._decimalPipe.transform(params.value, '1.2-2');
                    }
                    break;
                case Core.DescriptorFieldTypes.Date:
                    filter = 'agDateColumnFilter';
                    filterParams = AgGridFilterParams.dateFilterParamsWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.dateFloatingFilterParams;
                    columnWidth = AgGridColumns.dateColumnWidth;
                    valueFormatter = (x) => {
                        if (!x.value) {
                            return '';
                        }

                        const d = new Date(x.value);
                        return this._datePipe.transform(d, true);
                    };
                    break;
                case Core.DescriptorFieldTypes.YesNo:
                    filterParams = AgGridFilterParams.booleanFilterWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.booleanFloatingFilterParams;
                    valueFormatter = (x) => {
                        return x.value.toLowerCase() === 'true' ? 'Yes'
                            : (x.value.toLowerCase() === 'false' ? 'No' : '');
                    };
                    break;
            }

            return {
                colId: `characteristic.${type ? `${type  }.` : ''}${x.descriptorName.replace(/\s+/g, '')}`,
                headerName: `${type} ${x.descriptorName}`,
                field: `${type}.${x.descriptorName}`,
                toolPanelClass: 'Characteristics',
                pinned: 'left',
                lockPinned: false,
                hide: i >= 3,
                filter,
                filterParams,
                floatingFilterComponentParams,
                width: columnWidth,
                minWidth: AgGridColumns.textColumnSmallWidth,
                valueFormatter: valueFormatter,
                valueGetter: (x) => {
                    const accrual = x.data as Core.AccrualsGridEntryModel;
                    return (accrual && accrual.characteristicValues) ? accrual.characteristicValues[i] : '';
                }
            };
        });
    }

    private _getCheckboxColumn(): ColDef {
        return {
            colId: 'bigCheckbox',
            field: 'stateName', // kind of a hack to use the existing AccrualsGridEntryModel model. "stateName" is not used anywhere else in the grid
            headerName: '',
            width: AgGridColumns.textColumnSmallWidth,
            minWidth: AgGridColumns.textColumnSmallWidth,
            maxWidth: AgGridColumns.textColumnSmallWidth,
            resizable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true
        };
    }

    private _getPeriodRangeColumns(): ColDef[] {
        return [{
            colId: 'accountingPeriod',
            headerName: 'Acctg Period',
            field: 'accountingPeriod',
            filter: 'agTextColumnFilter',
            pinned: 'left',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth
        }, {
            colId: 'periodDuration',
            headerName: 'Duration',
            field: 'periodDuration',
            filter: 'agTextColumnFilter',
            pinned: 'left',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth
        }];
    }

    private _getEntityCols(summarizeBy: Core.AccrualsGridSummarizeByEnum, resultsModel: Core.AccrualsGridResultsModel): ColDef[] {
        switch (summarizeBy) {
            case Core.AccrualsGridSummarizeByEnum.Bills:
                return [this._getSiteNameColumn(), this._getSiteNumberColumn(), this._getPropertyTypeColumn(), this._getParcelAcctNumColumn(), this._getCollectorColumn()];
            case Core.AccrualsGridSummarizeByEnum.Company:
                return [this._getCompanyColumn()];
            case Core.AccrualsGridSummarizeByEnum.Parcel:
                return [this._getSiteNameColumn(), this._getSiteNumberColumn(), this._getPropertyTypeColumn(), this._getParcelAcctNumColumn()];
            case Core.AccrualsGridSummarizeByEnum.Site:
                return [this._getSiteNameColumn(), this._getSiteNumberColumn()];
            case Core.AccrualsGridSummarizeByEnum.EconomicUnit:
                switch(resultsModel.economicUnitTypeId) {
                    case Core.AccrualEconomicUnitTypeEnum.SiteNumber:
                        return [this._getSiteNameColumn(), this._getSiteNumberColumn()];
                    case Core.AccrualEconomicUnitTypeEnum.SubsidiaryCompany:
                        return [this._getCompanyColumn()];
                    case Core.AccrualEconomicUnitTypeEnum.SiteCode:
                        return [this._getSiteCodeColumn()];
                    case Core.AccrualEconomicUnitTypeEnum.Parcel:
                        return [this._getSiteNameColumn(), this._getSiteNumberColumn(), this._getPropertyTypeColumn(), this._getParcelAcctNumColumn()];
                    case Core.AccrualEconomicUnitTypeEnum.Bill:
                        return [this._getSiteNameColumn(), this._getSiteNumberColumn(), this._getPropertyTypeColumn(), this._getParcelAcctNumColumn(), this._getCollectorColumn()];
                }
            default:
                return [];
        }
    }

    private _getCollectorColumn(): ColDef {
        return {
            colId: 'collectorAbbr',
            headerName: 'Collector',
            pinned: 'left',
            field: 'collectorAbbr',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnLargeWidth,
            cellRendererFramework: AgGridLinkCellRenderer,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.billClusterId) {
                        return '';
                    }
                    return `#/billcluster/${entry.billClusterId}`;
                },
                getText: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.collectorAbbr) {
                        return '';
                    }

                    const icon = entry.isRecurring ? 'refresh' : 'envelope';
                    return `<i class="fa fa-${icon}" style="margin-right: 5px;"></i> ${entry.collectorAbbr}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    return !entry || !entry.billClusterId;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getCompanyColumn(): ColDef {
        return {
            colId: 'companyName',
            headerName: 'Company',
            pinned: 'left',
            field: 'companyName',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            cellRendererFramework: AgGridLinkCellRenderer,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.companyId) {
                        return '';
                    }
                    return `#/company/${entry.companyId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    return !entry || !entry.companyId;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getSiteNameColumn(): ColDef {
        return {
            colId: 'siteName',
            headerName: 'Site Name',
            field: 'siteName',
            filter: 'agTextColumnFilter',
            pinned: 'left',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            cellRendererFramework: AgGridLinkCellRenderer,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.siteId) {
                        return '';
                    }
                    return `#/site/${entry.siteId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    return !entry || !entry.siteId;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getSiteNumberColumn(): ColDef {
        return {
            colId: 'siteNumber',
            headerName: 'Site Number',
            field: 'siteNumber',
            pinned: 'left',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            cellRendererFramework: AgGridLinkCellRenderer,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.siteId) {
                        return '';
                    }
                    return `#/site/${entry.siteId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    return !entry || !entry.siteId;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getParcelAcctNumColumn(): ColDef {
        return {
            colId: 'parcelAcctNum',
            headerName: 'Parcel Acct #',
            field: 'parcelAcctNum',
            pinned: 'left',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            cellRendererFramework: AgGridLinkCellRenderer,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    if (!entry || !entry.parcelId) {
                        return '';
                    }
                    return `#/parcel/${entry.parcelId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.AccrualsGridEntryModel;
                    return !entry || !entry.parcelId;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getPropertyTypeColumn(): ColDef {
        return {
            colId: 'propertyTypeAbbr',
            headerName: 'Prop Type',
            field: 'propertyTypeAbbr',
            filter: 'agTextColumnFilter',
            pinned: 'left',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnExtraSmallWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            maxWidth: AgGridColumns.textColumnSmallWidth
        };
    }

    private _getActivityStatusColumn(): ColDef {
        return {
            colId: 'activityStatusId',
            headerName: 'Status',
            pinned: 'left',
            field: 'activityStatusId',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            hide: true,
            valueFormatter: (params) => this._getActivityStatusValue(params.data.activityStatusId)
        };
    }

    private _getStateCol(): ColDef {
        return {
            colId: 'stateAbbr',
            headerName: 'State',
            headerClass: 'text-align-center',
            pinned: 'left',
            lockPinned: true,
            lockVisible: true,
            field: 'stateAbbr',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnExtraSmallWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            maxWidth: AgGridColumns.textColumnSmallWidth
        };
    }

    private _getSiteCodeColumn(): ColDef {
        return {
            colId: 'siteCode',
            headerName: 'Site Code',
            pinned: 'left',
            field: 'siteCode',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
        };
    }

    private _getOpenTaxObligations(openTaxObligations: ColumnFiltersOptionsEnum): ColDef[] {
        let columns: OrderedColDef[] = [{
            colId: 'openTaxObligations.taxPeriod',
            headerName: 'Tax Period',
            field: 'openTaxObligations.taxPeriod',
            toolPanelClass: 'Open Tax Obligations',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 1
        }, {
            colId: 'openTaxObligations.amountTax',
            headerName: 'Tax Amount',
            headerClass: 'text-align-right',
            field: 'openTaxObligations.amountTax',
            toolPanelClass: 'Open Tax Obligations',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: (params) => this._formatCurrency(params.data.openTaxObligations.amountTax),
            cellClass: 'text-align-right',
            sequence: 4
        }, {
            colId: 'openTaxObligations.amountUnexpensed',
            headerName: 'Unexpensed',
            headerClass: 'text-align-right',
            field: 'openTaxObligations.amountUnexpensed',
            toolPanelClass: 'Open Tax Obligations',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: (params) => this._formatCurrency(params.data.openTaxObligations.amountUnexpensed),
            cellClass: 'text-align-right',
            sequence: 6
        }];

        if (openTaxObligations == ColumnFiltersOptionsEnum.All) {
            columns = [...columns, {
                colId: 'openTaxObligations.firstAccountingPeriod',
                headerName: 'First Acctg Pd',
                field: 'openTaxObligations.firstAccountingPeriod',
                toolPanelClass: 'Open Tax Obligations',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth,
                sequence: 2
            }, {
                colId: 'openTaxObligations.isNewThisPeriod',
                headerName: 'New?',
                field: 'openTaxObligations.isNewThisPeriod',
                toolPanelClass: 'Open Tax Obligations',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnSmallWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;
                    return entry.openTaxObligations.isNewThisPeriod ? 'Y' : 'N';
                },
                sequence: 2.5
            }, {
                colId: 'openTaxObligations.paymentCount',
                headerName: 'Pmt Count',
                headerClass: 'text-align-right',
                field: 'openTaxObligations.paymentCount',
                toolPanelClass: 'Open Tax Obligations',
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                width: AgGridColumns.numericColumnWidth,
                cellClass: 'text-align-right',
                sequence: 3
            }, {
                colId: 'openTaxObligations.dueDates',
                headerName: 'Due Dates',
                field: 'openTaxObligations.dueDates',
                toolPanelClass: 'Open Tax Obligations',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnLargeWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;

                    if(!entry.openTaxObligations.dueDates) {
                        return '';
                    }

                    if(entry.openTaxObligations.dueDates.length > 4) {
                        return 'Various';
                    } else {
                        const commaSeparated = _.join(entry.openTaxObligations.dueDates, ', ');
                        return _.trimEnd(commaSeparated, ', ');
                    }
                },
                sequence: 3.5
            }, {
                colId: 'openTaxObligations.amountExpensed',
                headerName: 'Expensed',
                headerClass: 'text-align-right',
                field: 'openTaxObligations.amountExpensed',
                toolPanelClass: 'Open Tax Obligations',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.openTaxObligations.amountExpensed),
                cellClass: 'text-align-right',
                sequence: 5
            }, {
                colId: 'openTaxObligations.amountAdjustment',
                headerName: 'Adjustment',
                headerClass: 'text-align-right',
                field: 'openTaxObligations.amountAdjustment',
                toolPanelClass: 'Open Tax Obligations',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.openTaxObligations.amountAdjustment),
                cellClass: 'text-align-right',
                sequence: 5.5
            }];
        }

        return _.sortBy(columns, 'sequence');
    }

    private _getPeriodContributions(columnFilters: AccrualsColumnFiltersUI, dataFilters: Core.AccrualsGridFilterSelectionsModel): ColDef[] {
        let columns: OrderedColDef[] = [{
            colId: 'periodContributions.amountTotalExpense',
            headerName: 'Total Expense',
            headerClass: 'text-align-right',
            field: 'periodContributions.amountTotalExpense',
            toolPanelClass: 'Period Contributions',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountTotalExpense),
            cellClass: 'text-align-right',
            sequence: 3
        }, {
            colId: 'periodContributions.amountTotalPayments',
            headerName: 'Total Pmts',
            headerClass: 'text-align-right',
            field: 'periodContributions.amountTotalPayments',
            toolPanelClass: 'Period Contributions',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountTotalPayments),
            cellClass: 'text-align-right',
            sequence: 9
        }];

        if (columnFilters.periodContributions == ColumnFiltersOptionsEnum.All) {
            columns = [...columns, {
                colId: 'periodContributions.amountBaseExpense',
                headerName: 'Base Expense',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountBaseExpense',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountBaseExpense),
                cellClass: 'text-align-right',
                sequence: 1
            }, {
                colId: 'periodContributions.amountTrueUp',
                headerName: 'True Up (Dn)',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountTrueUp',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountTrueUp),
                cellClass: 'text-align-right',
                sequence: 2
            }, {
                colId: 'periodContributions.amountBasePayments',
                headerName: 'Base Pmts',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountBasePayments',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountBasePayments),
                cellClass: 'text-align-right',
                sequence: 6
            }, {
                colId: 'periodContributions.amountPenalty',
                headerName: 'Penalty',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountPenalty',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountPenalty),
                cellClass: 'text-align-right',
                sequence: 7
            }, {
                colId: 'periodContributions.amountInterest',
                headerName: 'Interest',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountInterest',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountInterest),
                cellClass: 'text-align-right',
                sequence: 8
            }];
        }

        if(dataFilters.enableChangeDetection) {
            columns = [...columns, {
                colId: 'periodContributions.amountPriorPeriodExpense',
                headerName: 'Prior Pd Expense',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountPriorPeriodExpense',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountPriorPeriodExpense),
                cellClass: 'text-align-right',
                sequence: 4
            }, {
                colId: 'periodContributions.amountExpenseVariance',
                headerName: 'Variance $',
                headerClass: 'text-align-right',
                field: 'periodContributions.amountExpenseVariance',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => this._formatCurrency(params.data.periodContributions.amountExpenseVariance),
                cellClass: 'text-align-right',
                sequence: 4.1
            }, {
                colId: 'periodContributions.percentExpenseVariance',
                headerName: 'Variance %',
                headerClass: 'text-align-right',
                field: 'periodContributions.percentExpenseVariance',
                toolPanelClass: 'Period Contributions',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;
                    return this.percentPipe.transform(entry.periodContributions.percentExpenseVariance, '1.2-2');
                },
                cellClass: 'text-align-right',
                sequence: 4.2
            }, {
                colId: 'periodContributions.changeReasons',
                headerName: 'Det Change Reason',
                field: 'periodContributions.changeReasons',
                toolPanelClass: 'Period Contributions',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnLargeWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;

                    if(!entry.periodContributions.changeReasons) {
                        return '';
                    }

                    return _.reduce(entry.periodContributions.changeReasons, (list, reason, i) => {
                        list += `${reason.description} - ${this._formatCurrency(reason.amount)}`;
                        list += i < entry.periodContributions.changeReasons.length - 1 ? ', ' : '';

                        return list;
                    }, '');
                },
                sequence: 5
            }];

            if(dataFilters.summarizeBy == Core.AccrualsGridSummarizeByEnum.EconomicUnit) {
                columns = [...columns, {
                    colId: 'periodContributions.reportedChangeReason',
                    headerName: 'Rptd Change Reason',
                    field: 'periodContributions.reportedChangeReason',
                    toolPanelClass: 'Period Contributions',
                    filter: 'agTextColumnFilter',
                    filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                    floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    width: AgGridColumns.textColumnLargeWidth,
                    sequence: 4.9
                }];
            }
        }

        return _.sortBy(columns, 'sequence');
    }

    private _getAccrualBalances(): ColDef[] {
        return [{
            colId: 'accrualBalances.amountPeriodBegin',
            headerName: 'Period Begin',
            headerClass: 'text-align-right',
            field: 'accrualBalances.amountPeriodBegin',
            toolPanelClass: 'Period Accrual Balance',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: params => this._formatCurrency(params.data.accrualBalances.amountPeriodBegin),
            cellClass: 'text-align-right',
            sequence: 1
        }, {
            colId: 'accrualBalances.amountChange',
            headerName: 'Change',
            headerClass: 'text-align-right',
            field: 'accrualBalances.amountChange',
            toolPanelClass: 'Period Accrual Balance',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: params => this._formatCurrency(params.data.accrualBalances.amountChange),
            cellClass: 'text-align-right',
            sequence: 2
        }, {
            colId: 'accrualBalances.amountAdjustment',
            headerName: 'Adjustment',
            headerClass: 'text-align-right',
            field: 'accrualBalances.amountAdjustment',
            toolPanelClass: 'Period Accrual Balance',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: params => this._formatCurrency(params.data.accrualBalances.amountAdjustment),
            cellClass: 'text-align-right',
            sequence: 3
        }, {
            colId: 'accrualBalances.amountPeriodEnd',
            headerName: 'Period End',
            headerClass: 'text-align-right',
            field: 'accrualBalances.amountPeriodEnd',
            toolPanelClass: 'Period Accrual Balance',
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: params => this._formatCurrency(params.data.accrualBalances.amountPeriodEnd),
            cellClass: 'text-align-right',
            sequence: 4
        }] as OrderedColDef[];
    }

    private _getJournalImpact(journalImpact: JournalOptionsEnum, resultsModel: Core.AccrualsGridResultsModel): ColDef[] {
        const glAccountModels = journalImpact == JournalOptionsEnum.ShowByAccountType
            ? resultsModel.glAccountsByType
            : resultsModel.glAccounts;

        return _.map(glAccountModels, (glAccount: Core.AccrualsGridGLAccountModel, i: number) => {
            let headerName = this._getGLAccountHeader(glAccount.accountAccrualType);

            if (journalImpact == JournalOptionsEnum.ShowByAccountNumber) {
                headerName += `\n ${glAccount.columnHeaderLabel}`;
            }

            return {
                colId: `journal-impact-${i}`,
                headerName: headerName,
                headerClass: 'text-align-right',
                field: `journalImpacts[${i}].glAccountId`,
                toolPanelClass: 'Period Respective Journal Impact',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;
                    const byTypeOrAcctNum = journalImpact == JournalOptionsEnum.ShowByAccountType
                        ? entry.journalImpactsByType
                        : entry.journalImpacts;

                    const checkedValue = this._getCheckedValue(byTypeOrAcctNum, i);
                    return this._formatCurrency(checkedValue);
                },
                comparator: (valueA, valueB, nodeA, nodeB) => {
                    const entryA: Core.AccrualsGridEntryModel = nodeA.data;
                    const entryB: Core.AccrualsGridEntryModel = nodeB.data;

                    const byTypeOrAcctNumProp = journalImpact == JournalOptionsEnum.ShowByAccountType
                        ? 'journalImpactsByType'
                        : 'journalImpacts';

                    return this._getCheckedValue(entryA[byTypeOrAcctNumProp], i) - this._getCheckedValue(entryB[byTypeOrAcctNumProp], i);

                },
                cellClass: 'text-align-right',
                sequence: i + 1
            } as OrderedColDef;
        });
    }

    private _getCheckedValue(arr: any[], i: number): number {
        return arr && arr[i] && arr[i].amount ? arr[i].amount : null;
    }

    private _getJournalBalances(journalBalances: JournalOptionsEnum, resultsModel: Core.AccrualsGridResultsModel): ColDef[] {
        const glAccountModels = journalBalances == JournalOptionsEnum.ShowByAccountType
            ? resultsModel.glAccountsBalanceByType
            : resultsModel.glAccountsBalance;

        return _.map(glAccountModels, (glAccount: Core.AccrualsGridGLAccountModel, i: number) => {
            let headerName = this._getGLAccountHeader(glAccount.accountAccrualType);

            if (journalBalances == JournalOptionsEnum.ShowByAccountNumber) {
                headerName += `\n ${glAccount.columnHeaderLabel}`;
            }

            return {
                colId: `journal-balance-${i}`,
                headerName: headerName,
                headerClass: 'text-align-right',
                field: `journalBalances[${i}].glAccountId`,
                toolPanelClass: 'Ending Respective Balances',
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => {
                    const entry: Core.AccrualsGridEntryModel = params.data;
                    const byTypeOrAcctNum = journalBalances == JournalOptionsEnum.ShowByAccountType
                        ? entry.journalBalancesByType
                        : entry.journalBalances;

                    const checkedValue = this._getCheckedValue(byTypeOrAcctNum, i);
                    return this._formatCurrency(checkedValue);
                },
                comparator: (valueA, valueB, nodeA: RowNode, nodeB: RowNode) => {
                    const entryA: Core.AccrualsGridEntryModel = nodeA.data;
                    const entryB: Core.AccrualsGridEntryModel = nodeB.data;

                    const byTypeOrAcctNumProp = journalBalances == JournalOptionsEnum.ShowByAccountType
                        ? 'journalBalancesByType'
                        : 'journalBalances';

                    return this._getCheckedValue(entryA[byTypeOrAcctNumProp], i) - this._getCheckedValue(entryB[byTypeOrAcctNumProp], i);
                },
                cellClass: 'text-align-right',
                sequence: i + 1
            } as OrderedColDef;
        });
    }

    private _formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this.currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    private _getActivityStatusValue(activityStatusId: Core.ActivityStatuses) {
        switch (activityStatusId) {
            case Core.ActivityStatuses.Active:
                return 'Active';
            case Core.ActivityStatuses.ActivePending:
                return 'Pending';
            case Core.ActivityStatuses.Inactive:
                return 'Inactive';
        }
    }

    private _getGLAccountHeader(accountAccrualType: Compliance.GLAccountAccrualTypeEnum) {
        switch (accountAccrualType) {
            case Compliance.GLAccountAccrualTypeEnum.CashSuspense:
                return 'Suspense';
            case Compliance.GLAccountAccrualTypeEnum.PropertyTaxExpense:
                return 'Expense';
            case Compliance.GLAccountAccrualTypeEnum.PropertyTaxPayable:
                return 'Payable';
            case Compliance.GLAccountAccrualTypeEnum.PropertyTaxPrepaid:
                return 'Prepaid';
        }
    }
}
