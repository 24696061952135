import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SupportInstanceRepository } from '../../Compliance/Repositories/supportInstance.repository';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { lastValueFrom } from 'rxjs';
import { WeissmanDateTimeFormatPipe } from '../../UI-Lib/Pipes';
import { InstanceRights, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { GLAccountDetailsComponent } from '../../Compliance/GL-Account/GL-Account-Details/glAccountDetails.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    SupportInstanceRequestAccessComponent,
    SupportInstanceRequestAccessComponentParams
} from '../Support-Instance-Request-Access/supportInstanceRequestAccess.component';
import { ToastrService } from 'ngx-toastr';

interface SupportInstanceInfo extends Core.SupportInstanceInfoModel {
    isAccessTerminating: boolean
}

@Component({
    selector: 'support-instance-list',
    templateUrl: './supportInstanceList.component.html',
    styleUrls: ['./supportInstanceList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SupportInstanceListComponent implements OnInit{
    constructor(
        private _supportInstanceRepository: SupportInstanceRepository,
        private _datePipe: WeissmanDateTimeFormatPipe,
        private _restrictService: RestrictService,
        private _routerService: UpgradeNavigationServiceHandler,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastr: ToastrService
    ){}

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    instances: SupportInstanceInfo[];

    async ngOnInit(): Promise<void> {
        if(!this._restrictService.isInRole(Roles.SUPPORT)) {
            this._routerService.go('unauthorizedAccess', {});
            return Promise.resolve();
        }

        const busyLoading = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyLoading);

        try {
            const result = await lastValueFrom(this._supportInstanceRepository.getList());
            result.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
            this.instances = result.map(x => x as SupportInstanceInfo);
        } finally {
            this.busyIndicatorMessageManager.remove(busyLoading);
        }
    }

    getStatus(instance: Core.SupportInstanceInfoModel): string {
        return instance.accessGrantedDate ? `Initiated ${this._datePipe.transform(instance.accessGrantedDate, false, 'central')}` : 'Ready for Request';
    }

    async terminateAccess(instance: SupportInstanceInfo): Promise<void> {
        const busyMsgId = 'terminating';
        this.busyIndicatorMessageManager.add('Terminating', busyMsgId);

        try {
            await lastValueFrom(this._supportInstanceRepository.terminateAccess(instance.instanceId));

            instance.isAccessTerminating = true;

            this._toastr.success(`You have initiated termination of your access to ${instance.instanceName}. Please log out and log back in to complete the process.`)
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        return Promise.resolve();
    }

    async requestAccess(instance: SupportInstanceInfo): Promise<void> {
        const params: SupportInstanceRequestAccessComponentParams = {
            instanceId: instance.instanceId
        }
        const result = await this._modalService.showAsync(SupportInstanceRequestAccessComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        let instanceInfo = this.instances.find(x => x.instanceId == result.instanceId);
        instanceInfo.accessGrantedDate = result.accessGrantedDate;

        return Promise.resolve();
    }
}
