<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Upload Template"
                  (save)="upload()"
                  (cancel)="cancel()"
                  [disableSave]="!file">
    <form>
        <div class="mb-4">
            <label>Select a file (must be .xlsx file):</label>
            <ws-file-picker (picked)="filePicked($event)" acceptTypes=".xlsx"></ws-file-picker>
        </div>
    </form>
</ws-modal-wrapper>
