<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Associate Tax Authorities with the Assessment"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <warning-message [show]="showWarning"
                     message="Existing parcel allocations will be transferred to the selected tax authorities."
                     [hasDetails]="false">
    </warning-message>
    <ws-ag-grid-angular
        [gridOptions]="gridOptions"
        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
