import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const APPEAL_RECOMMENDATION_COMMAND_CENTER_HELP: HelpContent[] = [
    {
        helpContentId: 'appeal-recommendation-command-center.state',
        tooltipText: 'At least one state must be selected in order to proceed.',
        hasIcon: true
    },
    {
        helpContentId: 'appeal-recommendation-command-center.assigned',
        tooltipText: 'Filtering to indicate appeal determination task assignments and status.',
        hasIcon: true
    },
    {
        helpContentId: 'appeal-recommendation-command-center.deadline',
        tooltipText: 'Appeal deadline may not exceed 30 days in the past or 120 days in the future.',
        hasIcon: true
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-update-empty',
        tooltipText: 'Bulk update supports accepting recommendations, client approval, and displayed characteristics.',
        hasIcon: false
    }
];
