<div class="d-flex align-items-center">
    <label class="pe-1 mt-2" for="selectedYear">Tax Year:</label>
    <div class="d-flex">
        <ws-select id="selectedYear"
                   labelProperty="taxYear"
                   [returnEntireOption]="true"
                   [options]="years"
                   [formControl]="selectedYear"
                   (selectedOptionChanged)="selectedYearChanged($event)">
        </ws-select>
    </div>
</div>
