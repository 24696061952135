'use strict';

/**
 * @ngdoc service
 * @name weissman.utils
 * @description
 * # utils
 * Factory in the weissman.
 */

angular.module('weissmanApp')
	.factory('utils', ['sdLogger', '$timeout', '$location', function (sdLogger, $timeout, $location) {
		var nonDeleted = function (list) {
			return _.reject(list, {
				efAction: 'delete'
			})
		}

		var notNullorZero = function (val) {
			if (val === null) {
				return false
			}
			if (val === 0) {
				return false
			}

			return true
		}

		var getObjValByString = function (o, s) {
			s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
			s = s.replace(/^\./, ''); // strip a leading dot
			var a = s.split('.');
			for (var i = 0, n = a.length; i < n; ++i) {
				var k = a[i];
				if (k in o) {
					o = o[k];
				} else {
					return;
				}
			}
			return o;
		}

		// Public API here
		var service = {
			getEntityName: getEntityName,
			getCurrentEntity: getCurrentEntity,
			focusOnElement: focusOnElement,
			nonDeleted: nonDeleted,
			notNullorZero: notNullorZero,
			getObjValByString: getObjValByString,
			getEntityTypeAndId: getEntityTypeAndId,
			findPropertyName: findPropertyName,
			removeArrayItem: removeArrayItem,
			compareBySequence: compareBySequence,
			showJsonUsingPopup: showJsonUsingPopup,
			unDefaultOthers: function (list, newDefault) {
				var tempVal = newDefault.isDefault;

				list = _.map(nonDeleted(list), function (item) {
					item.isDefault = false;
					return item;
				})

				newDefault.isDefault = tempVal;
			}, // Falsify all others in list, but keep checked value (true or false) of checkbox checked
			updateAction: function (thing) {
				thing.efAction = thing.efAction === null ? 'update' : thing.efAction;
			},
			logIt: function () {
				var args = [];
				for (var i = 0; i < arguments.length; i++) {
					args[i] = arguments[i];
				}
			},
			nonDeletedFilter: nonDeletedFilter,
			convertStringToDate: convertStringToDate,
            convertDateStringsToDates: convertDateStringsToDates
		};

		service.vm = {};

		return service;

		function focusOnElement(elem, delay) {
			delay = delay || 100;

			$timeout(function () {
				var element = angular.element(elem)
				element.focus();

				sdLogger.log('.focusOnElement(): ', {
					'element': element
				});
			}, delay)
		}

		function notEmpty(s) {
			if (!s)
				return false;
			if (s == '')
				return false;
			return true;
		}

		function getEntityTypeAndId(stateParams) {
			//console.log('getEntityTypeAndId - $stateParams', stateParams);

			// EntityTypeId	EntityTypeName
			// 1			Company
			// 2			State
			// 3			Assessor
			// 4			Collector


			if (stateParams.parcelId !== undefined) { //parcel}`
				return {
					type: 'parcel',
					typeId: -1,
					id: stateParams.parcelId
				}
			}
			if (stateParams.siteId !== undefined) {
				return {
					type: 'site',
					typeId: -1,
					id: stateParams.siteId
				}
			}
			if (stateParams.companyId !== undefined) {
				return {
					type: 'company',
					typeId: 1,
					id: stateParams.companyId
				}
			}
			if (notEmpty(stateParams.collectorId)) {
				return {
					type: 'collector',
					typeId: 4,
					id: stateParams.collectorId
				}
			}
			if (notEmpty(stateParams.assessorId)) {
				return {
					type: 'assessor',
					typeId: 3,
					id: stateParams.assessorId
				}
			}

			if (stateParams.stateId !== undefined) {
				return {
					type: 'state',
					typeId: 2,
					id: stateParams.stateId
				}
			}

			return {
				type: 'unknown',
				id: -1
			}
		}

		function getEntityName(id) //entityInfo
		{
			if (service.vm.currentEntity && service.vm.currentEntity.id === id) {
				return service.vm.currentEntity.name;
			}

			return '';
		}

		function getCurrentEntity(id) {
			if (service.vm.currentEntity && service.vm.currentEntity.id === id) {
				return service.vm.currentEntity;
			}

			return null;
		}

		function nonDeletedFilter(item) {
			return item.efAction !== 'delete';
		}

		function findPropertyName(object, property_value, strict) {
			var result = 'unknown property name';

			if (typeof strict === 'undefined') {
				strict = false;
			}

			Object.getOwnPropertyNames(object).forEach(function (propertyName, idx, array) {
				if ((strict && object[propertyName] === property_value) ||
					(!strict && object[propertyName] === property_value)) {
					result = propertyName;
				}
			});

			return result.toLowerCase();
		}

		function compareBySequence(a, b) {
			if (a.sequence < b.sequence)
				return -1;
			else if (a.sequence > b.sequence)
				return 1;
			else
				return 0;
		}

		function removeArrayItem(arr, item) {
			var i = arr.indexOf(item)
			if (i < 0)
				return false;
			arr.splice(i, 1);
			return true;
		}

		function convertStringToDate(value) {
            var match = dateStringMatch(value);

            if (match) {
                var newValue = getDateValue(match);
                if (newValue) {
                    return newValue;
                }
            }

            return value;
		}

		function convertDateStringsToDates(input) {
            // Ignore things that aren't objects.
            if (typeof input !== "object") return input;

            for (var key in input) {
                if (!input.hasOwnProperty(key)) { continue; }
                var value = input[key];
                var match = dateStringMatch(value);
                if (match) {
                    var newValue = getDateValue(match);
                    if (newValue) {
                        input[key] = newValue;
                    }
                } else if (typeof value === 'object') {
                    // Recurse into object
                    convertDateStringsToDates(value);
                }
            }
        }

        function dateStringMatch(value) {
            var regexIso = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(?:(?::[0-5]\d)?|(?::[0-5]\d\.\d+))?(Z|(?:[+-][0-2]\d:[0-5]\d))?$/;

            // Check for string properties which look like dates.
            if (typeof value === 'string') {
                var match = value.match(regexIso);
                if (match && !isJson(value)) {
                    return match;
                }
            }

            return undefined;
        }

		function getDateValue(match) {
            // The first capture group is the timezone specifier; if not present, assume UTC
            var momentVal = match[1] ? moment(match[0]) : moment.utc(match[0]);
            if (momentVal.isValid()) {
                 return momentVal.toDate();
            }

            return undefined;
		}

		function isJson(str) {
			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}
			return true;
		}

		function showJsonUsingPopup(data) {
			var url = 'data:text/json;charset=utf8,' + encodeURIComponent(data);
			window.open(url, '_blank');
			window.focus();
		}
	}]);
