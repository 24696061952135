<button type="button"
        [class.has-icon]="icon || iconFont"
        [class.has-text]="content"
        [disabled]="isDisabled"
        (click)="buttonClicked($event)">
    <slot *ngIf="iconSlot === 'icon'" name="icon">
        <ws-ace-icon *ngIf="icon"
                     [glyph]="icon"
                     slot="icon"
                     [size]="iconSize"
                     [attr.disabled]="isDisabled"
                     [state]="(isDisabled ? 'disabled' : state)">
        </ws-ace-icon>
        <span *ngIf="iconFontClass" class="ace-icon fa" [ngClass]="iconFontClass"></span>
    </slot>
    <span *ngIf="content" class="button-txt text-nowrap">{{ content }}</span>
    <slot *ngIf="iconSlot === 'icon-right'" name="icon-right">
        <ws-ace-icon *ngIf="icon"
                     [glyph]="icon"
                     slot="icon"
                     [size]="iconSize"
                     [attr.disabled]="isDisabled"
                     [state]="(isDisabled ? 'disabled' : state)">
        </ws-ace-icon>
        <span *ngIf="iconFontClass" class="ace-icon fa" [ngClass]="iconFontClass"></span>
    </slot>
</button>

