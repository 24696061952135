import { Component, OnDestroy, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { RestrictService, Roles } from '../../../../../Common/Permissions/restrict.service';
import { WeissmanDateFormatPipe } from '../../../../../UI-Lib/Pipes';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, RowNode } from 'ag-grid-community';
import {
    FactorTableDetailsComponent,
    FactorTableDetailsParams
} from '../../../../Factor-Table/Factor-Table-Details/factorTableDetails.component';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import {
    ICellRendererParamsForReturnAssetDragAndDropSource,
    ReturnAssetDragAndDropSourceComponent
} from '../Drag-And-Drop/returnAssetDragAndDropSource.component';
import { ReturnAssetListAgGridDataSource } from './agGridDataSource';
import { ReturnService, ReturnServiceSharedState } from '../../../return.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../returnAssets.service';
import { ReturnAssetDragAndDropService } from '../Drag-And-Drop/returnAssetDragAndDrop.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../../AgGrid/MultiSelectTracker';
import {
    ICellRendererParamsForReturnAssetListGridAction,
    ReturnAssetListGridActionCellRendererComponent
} from './agGridActionCellRenderer.component';
import { ReturnAssetChangeIconCellRendererComponent } from './agGridChangeIconCellRenderer.component';
import { AssetDetailsComponent, AssetDetailsParams } from '../../../../Asset/Asset-Details/assetDetails.component';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import {
    ReturnAssetBulkUpdateComponent,
    ReturnAssetBulkUpdateParams
} from '../Return-Asset-Bulk-Update/returnAssetBulkUpdate.component';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../../AgGrid/ToolPanel/models';
import { ReturnAssetRepository } from '../../../../Repositories';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { TimerService } from '../../../../../UI-Lib/Utilities';

import * as _ from 'lodash';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { ProductAnalyticsService } from '../../../../../Common/Amplitude/productAnalytics.service';

export interface ReturnAssetListColDefMetadata {
    isRelatedToPriorReturn: boolean;
}

@Component({
    selector: 'return-asset-list',
    templateUrl: './returnAssetList.component.html'
})
export class ReturnAssetListComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _modalService: WeissmanModalService,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnAssetDragAndDropService: ReturnAssetDragAndDropService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _restrictService: RestrictService
    ) { }

    selectedTotalsLoading: boolean = true;
    gridTotalsRow: any;
    gridSelectedTotalsRow: any;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    totalsLoading: any;

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    get companyId(): number {
        return this._returnService.companyId;
    }

    gridId: System.Guid = 'CFE5F117-024B-498C-879A-4B41A31087FD';
    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.ReportingAssetModel).reportingAssetId),
            'totals-row': (params) => params.data && params.data.totalsRow,
            'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
        }
    })
        .withColumnPinning()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();
            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0) {
                searchParams.selectedRows.selectAllRows = true;
            }
            searchParams.pagination = {
                skip: 0,
                take: this._gridDataSource.totalRows
            };
            const exportModel: Compliance.ReturnAssetExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                fileFormat
            };
            const longRunningProcessId = await this._returnAssetsService.exportAssets(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportReturnAssets };
        },
        canCancel: true,
        showFileFormatSelection: true
    };

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _gridApi: GridApi;
    private _gridDataSource: ReturnAssetListAgGridDataSource;
    private _destroy$: Subject<void> = new Subject();
    private _parcelsChangeDate: Date = null;
    private _initialized: boolean;
    private _tabActive: boolean;

    ngOnInit(): void {
        this._returnAssetsService.subscribeToServiceActivationCycle(this);
        this._returnAssetDragAndDropService.start(this._getDraggedAssetIds.bind(this), this._getDraggedAssets.bind(this));
    }

    ngOnDestroy(): void {
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
        this._returnAssetDragAndDropService.stop();
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onReturnPartServiceActivated(): Promise<void> {
        if (!this._initialized) {
            this._setDataSource();
        }
        // if the return is in read only mode then stop the drag and drop service
        if (this._returnService.isReturnInReadOnlyMode) {
            this._returnAssetDragAndDropService.stop();
        }

        this._returnAssetsService.scheduleAndFactorFilter$.pipe(takeUntil(this._destroy$))
            .subscribe(async () => await this._refreshAssets());

        // if the return goes into read-only mode then stop; otherwise start the drag-and-drop service
        this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            if (x) {
                this._returnAssetDragAndDropService.stop();
                this.isBulkUpdateVisible$.next(false);
            } else if (!this._returnAssetDragAndDropService.isStarted()) {
                this._returnAssetDragAndDropService.start(this._getDraggedAssetIds.bind(this), this._getDraggedAssets.bind(this));
            }
        });

        // if the return goes into read-only mode then stop; otherwise start the drag-and-drop service
        this._returnService.formRevisionId$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            if (!x) {
                this._returnAssetDragAndDropService.stop();
            } else if (!this._returnAssetDragAndDropService.isStarted()) {
                this._returnAssetDragAndDropService.start(this._getDraggedAssetIds.bind(this), this._getDraggedAssets.bind(this));
            }
        });

        // a different form was requested when navigating to the assets tab
        if (this._returnAssetsService.sharedState.formRevisionId && this._returnAssetsService.sharedState.formRevisionId !== this._returnService.sharedState.formRevisionId) {
            this._returnService.setFormRevisionId(this._returnAssetsService.sharedState.formRevisionId);
        }

        this._returnService.parcelsChanged$.pipe(takeUntil(this._destroy$)).subscribe((x) => {
            if (x) {
                if (this._parcelsChangeDate !== x) {
                    this._parcelsChangeDate = x;
                    this._refreshAssets(true);
                }
            }
        });

        this._setGridTracker();

        this._tabActive = true;
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
        this._tabActive = false;
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            const isBulkUpdateButtonVisible = (!this._returnService.isReturnInReadOnlyMode) && this._returnService.canEditCompany && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateButtonVisible);
            await this._reloadTotals();
        });

        this.gridTracker.isSelectingRange$.pipe(takeUntil(this._destroy$)).subscribe(loading => {
            if (!loading) {
                const isBulkUpdateButtonVisible = (!this._returnService.isReturnInReadOnlyMode) && this._returnService.canEditCompany && this.gridTracker.hasSelectedRows();
                this.isBulkUpdateVisible$.next(isBulkUpdateButtonVisible);
            } else {
                this.isBulkUpdateVisible$.next(false);
            }
        });

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'reportingAssetId',
                toolPanelClass: 'Asset',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                cellRendererFramework: ReturnAssetDragAndDropSourceComponent,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as ICellRendererParamsForReturnAssetDragAndDropSource
            },
            {
                headerName: '',
                field: 'reportingAssetId',
                toolPanelClass: 'Asset',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'Asset Number',
                field: 'assetNumber',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                lockPosition: true,
                lockPinned: true,
                pinned: 'left',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.assetNumber === rowData.sourceAssetNumber) ? null : 'overridden';
                }
            },
            {
                headerName: 'Description',
                field: 'description',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.description === rowData.sourceDescription) ? null : 'overridden';
                }
            },
            {
                headerName: 'Ownership Type',
                field: 'assetOwnershipType',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.assetOwnershipType === rowData.assetOwnershipType) ? null : 'overridden';
                }
            },
            {
                headerName: 'Company Code',
                field: 'companyCode',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'G/L Account',
                field: 'glAccount',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.glAccountId === rowData.sourceGlAccountId) ? null : 'overridden';
                },
                hide: true
            },
            {
                headerName: 'G/L Account Number',
                field: 'glAccountNumber',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.glAccountId === rowData.sourceGlAccountId) ? null : 'overridden';
                },
                hide: true
            },
            {
                headerName: 'Change Status',
                field: 'assetReturnStatus',
                toolPanelClass: 'Asset',
                cellRendererFramework: ReturnAssetChangeIconCellRendererComponent,
                width: 120,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                sortable: false
            },
            {
                headerName: 'Split Allocation %',
                field: 'splitAllocationPercentage',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                hide: true
            },
            {
                headerName: 'Classification',
                field: 'classificationName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData
                            && ((rowData.classificationId && rowData.glAccountClassificationId && rowData.classificationId === rowData.glAccountClassificationId)
                                || (!rowData.classificationId && !rowData.glAccountClassificationId))
                    ) ? null : 'overridden';
                }
            },
            {
                headerName: 'Parcel',
                field: 'parcelAccountNumber',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const asset = params.data as Compliance.ReturnAssetModel;
                        if (!asset) {
                            return '';
                        }
                        return `#/parcel/${asset.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Parcel Address (Complete)',
                field: 'parcelAddressComplete',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Linked/Sub Parcel',
                field: 'linkedParcelAccountNumber',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Parcel Desc.',
                field: 'parcelDescription',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Linked/Sub Parcel Desc.',
                field: 'linkedParcelDescription',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Assessor',
                field: 'assessorAbbr',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Linked/Sub Assessor',
                field: 'linkedParcelAssessorAbbr',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Prior Acq. Date',
                field: 'priorAcqDate',
                toolPanelClass: 'Asset',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                ws: {
                    isRelatedToPriorReturn: true
                } as ReturnAssetListColDefMetadata
            } as ColDef,
            {
                headerName: 'Acq. Date',
                field: 'acqDate',
                toolPanelClass: 'Asset',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData
                            && ((rowData.acqDate && rowData.sourceAcqDate && rowData.acqDate.toString() === rowData.sourceAcqDate.toString())
                                || (!rowData.acqDate && !rowData.sourceAcqDate))
                    ) ? null : 'overridden';
                }
            },
            {
                headerName: 'Alternate Acq. Date Source',
                field: 'alternativeCostDescriptor',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams
            },
            {
                headerName: 'Do Not Potentially Dispose',
                toolPanelClass: 'Asset',
                field: 'doNotPotentiallyDispose',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName: 'Prior Cost',
                field: 'priorCost',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                ws: {
                    isRelatedToPriorReturn: true
                } as ReturnAssetListColDefMetadata
            } as ColDef,
            {
                headerName: 'Cost',
                field: 'cost',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.cost === rowData.sourceCost) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                }
            },
            {
                headerName: 'Cost Change',
                field: 'costChange',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                ws: {
                    isRelatedToPriorReturn: true
                } as ReturnAssetListColDefMetadata
            } as ColDef,
            {
                headerName: 'Net Book Value',
                field: 'netBookValue',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => x.value || x.value === 0 ? this._decimalPipe.transform(x.value, '1.2-2') : null,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.netBookValue === rowData.sourceNetBookValue) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                }
            },
            {
                headerName: 'Site',
                field: 'site',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const rowData = params.data as Compliance.ReturnAssetModel;
                        if (!(rowData && rowData.siteId)) {
                            return '';
                        }
                        return `#/site/${rowData.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const asset = params.data as Compliance.ReturnAssetModel;
                    return asset && asset.site !== asset.sourceSite ? 'ws-override' : '';
                }
            },
            {
                headerName: 'Site Name',
                field: 'siteName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const rowData = params.data as Compliance.ReturnAssetModel;
                        if (!(rowData && rowData.siteId)) {
                            return '';
                        }
                        return `#/site/${rowData.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const asset = params.data as Compliance.ReturnAssetModel;
                    return asset && asset.site !== asset.sourceSite ? 'ws-override' : '';
                }
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const rowData = params.data as Compliance.ReturnAssetModel;
                        if (!(rowData && rowData.siteId)) {
                            return '';
                        }
                        return `#/site/${rowData.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const asset = params.data as Compliance.ReturnAssetModel;
                    return asset && asset.site !== asset.sourceSite ? 'ws-override' : '';
                }
            },
            {
                headerName: 'Site Address',
                field: 'siteAddress',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams): string => {
                    const asset = params.data as Compliance.ReturnAssetModel;
                    return asset && asset.siteAddress !== asset.sourceSiteAddress ? 'ws-override' : '';
                }
            },
            {
                headerName: 'Is Anchored',
                toolPanelClass: 'Asset',
                field: 'isAnchored',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName: 'Is Locked',
                toolPanelClass: 'Asset',
                field: 'isAssetLocked',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    }
                    else if (params.value === false) {
                        return 'No';
                    } else {
                        return '';
                    }
                }
            },
            {
                headerName: 'Prior Schedule',
                field: 'priorScheduleName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                ws: {
                    isRelatedToPriorReturn: true
                } as ReturnAssetListColDefMetadata
            } as ColDef,
            {
                headerName: 'Schedule',
                field: 'scheduleName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return rowData && rowData.formRevisionScheduleId === rowData.defaultFormScheduleId ? null : 'overridden';
                }
            },
            {
                headerName: 'Form',
                field: 'form',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Prior Dep. Table',
                field: 'priorDepreciationFactorTableName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.priorDepreciationFactorTableName) ? `${rowData.priorDepreciationFactorTableName} (${rowData.priorDepreciationFactorTableLife} year life)` : '';
                },
                ws: {
                    isRelatedToPriorReturn: true
                } as ReturnAssetListColDefMetadata
            } as ColDef,
            {
                headerName: 'Dep. Table',
                field: 'depreciationFactorTableName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'return-page.view-depreciation-table',
                    onClickAsync: async (x: AgGridLinkCellRendererParams) => {
                        const rowData = x.data as Compliance.ReturnAssetModel;
                        const params: FactorTableDetailsParams = {
                            factorTableId: rowData.depreciationFactorTableId,
                            editMode: false
                        };

                        await this._modalService.showAsync(FactorTableDetailsComponent, params, 'modal-xl');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        return !this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
                    }
                } as AgGridLinkCellRendererParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.depreciationFactorTableName) ? `${rowData.depreciationFactorTableName} (${rowData.depreciationFactorTableLife} year life)` : '';
                },
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.depreciationFactorTableId === rowData.defaultFormDepreciationTableId) ? null : 'overridden';
                }
            },
            {
                headerName: 'Index Table',
                field: 'indexFactorTableName',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'return-page.view-index-table',
                    onClickAsync: async (x: AgGridLinkCellRendererParams) => {
                        const rowData = x.data as Compliance.ReturnAssetModel;
                        const params: FactorTableDetailsParams = {
                            factorTableId: rowData.indexFactorTableId,
                            editMode: false
                        };

                        await this._modalService.showAsync(FactorTableDetailsComponent, params, 'modal-xl');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        return !this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
                    }
                } as AgGridLinkCellRendererParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.indexFactorTableName) ? `${rowData.indexFactorTableName} (${rowData.indexFactorTableLife} year life)` : '';
                },
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.indexFactorTableId === rowData.defaultFormIndexTableId) ? null : 'overridden';
                }
            },
            {
                headerName: 'Factor',
                field: 'factor',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParamsWithEqualTo,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && (x.value || x.value === 0)) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.depreciationFactorTableId === rowData.defaultFormDepreciationTableId) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                }
            },
            {
                headerName: 'Rep. Value',
                field: 'reportedValue',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                }
            },
            {
                headerName: 'Add\'l Dep.',
                field: 'additionalDepreciation',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.additionalDepreciation) ? 'ag-numeric-cell overridden' : 'ag-numeric-cell';
                }
            },
            {
                headerName: 'Est. FMV',
                field: 'estimatedFMV',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.estimatedFMVOverridden
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.estimatedFMVOverridden) ? 'ag-numeric-cell overridden' : 'ag-numeric-cell';
                }
            },
            {
                headerName: 'Acq. Year',
                field: 'acqYear',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterAndEqualToParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Age',
                field: 'age',
                toolPanelClass: 'Asset',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterAndEqualToParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Location Name',
                field: 'leaseLocationName',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseLocationName')
            },
            {
                headerName: 'Location Address',
                field: 'leaseAddress',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseAddress')
            },
            {
                headerName: 'Location City',
                field: 'leaseCity',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseCity')
            },
            {
                headerName: 'Location State',
                field: 'leaseState',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseState')
            },
            {
                headerName: 'Location Zip',
                field: 'leaseZip',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseZip')
            },
            {
                headerName: 'Lease Start Date',
                field: 'leaseStartDate',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                cellClass: this._cellClassForOverrides.bind(this, 'leaseStartDate')
            },
            {
                headerName: 'Lease End Date',
                field: 'leaseEndDate',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                cellClass: this._cellClassForOverrides.bind(this, 'leaseEndDate')
            },
            {
                headerName: 'Lease Term In Months',
                field: 'leaseTermInMonths',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseTermInMonths')
            },
            {
                headerName: 'Lease Monthly Payment',
                field: 'leaseMonthlyPayment',
                toolPanelClass: 'Leasing',
                filter: 'agNumberColumnFilter',
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseMonthlyPayment'),
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
            },
            {
                headerName: 'Lease Type',
                field: 'leaseTypeId',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseTypeId')
            },
            {
                headerName: 'Lease Asset ID',
                field: 'leaseAssetId',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseAssetId')
            },
            {
                headerName: 'Lease Number',
                field: 'leaseNumber',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseNumber')
            },
            {
                headerName: 'Billing Name',
                field: 'leaseBillingName',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseBillingName')
            },
            {
                headerName: 'Billing Address',
                field: 'leaseBillingAddress',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseBillingAddress')
            },
            {
                headerName: 'Billing City',
                field: 'leaseBillingCity',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseBillingCity')
            },
            {
                headerName: 'Billing State',
                field: 'leaseBillingState',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseBillingState')
            },
            {
                headerName: 'Billing Zip',
                field: 'leaseBillingZip',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseBillingZip')
            },
            {
                headerName: 'Customer Id',
                field: 'leaseClientId',
                toolPanelClass: 'Leasing',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                cellClass: this._cellClassForOverrides.bind(this, 'leaseClientId')
            },
            {
                headerName: 'Jan Balance',
                field: 'inventoryJan',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryJan === rowData.sourceInventoryJan) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Feb Balance',
                field: 'inventoryFeb',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryFeb === rowData.sourceInventoryFeb) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Mar Balance',
                field: 'inventoryMar',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryMar === rowData.sourceInventoryMar) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Apr Balance',
                field: 'inventoryApr',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryApr === rowData.sourceInventoryApr) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'May Balance',
                field: 'inventoryMay',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryMay === rowData.sourceInventoryMay) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Jun Balance',
                field: 'inventoryJun',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryJun === rowData.sourceInventoryJun) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Jul Balance',
                field: 'inventoryJul',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryJul === rowData.sourceInventoryJul) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Aug Balance',
                field: 'inventoryAug',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryAug === rowData.sourceInventoryAug) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Sep Balance',
                field: 'inventorySep',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventorySep === rowData.sourceInventorySep) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Oct Balance',
                field: 'inventoryOct',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryOct === rowData.sourceInventoryOct) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Nov Balance',
                field: 'inventoryNov',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryNov === rowData.sourceInventoryNov) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Dec Balance',
                field: 'inventoryDec',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryDec === rowData.sourceInventoryDec) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Avg Balance',
                field: 'inventoryAvrCalculated',
                toolPanelClass: 'Inventory',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithEqualToAndBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.inventoryAvr === rowData.sourceInventoryAvr) ? 'ag-numeric-cell' : 'ag-numeric-cell overridden';
                },
                hide: true
            },
            {
                headerName: 'Aggregated Balance',
                field: 'inventoryAggregatedString',
                toolPanelClass: 'Inventory',
                width: AgGridColumns.textColumnLargeWidth,
                sortable: false,
                cellClass: 'ag-numeric-cell',
                hide: true
            },
            {
                headerName: 'Reportable',
                field: 'isReportable',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                cellClass: 'ag-text-cell',
                valueFormatter: x => {
                    if (x.value === null || x.value === undefined) { return; }
                    return x.value ? 'Yes' : 'No';
                },
                hide: true
            },
            {
                headerName: 'Taxable',
                field: 'isTaxable',
                toolPanelClass: 'Asset',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                cellClass: 'ag-text-cell',
                valueFormatter: x => {
                    if (x.value === null || x.value === undefined) { return; }
                    return x.value ? 'Yes' : 'No';
                },
                hide: true
            }
        ];

        const assetDescriptorColumns = this._returnService.getCompanyAssetDescriptorMappingsAsColDefs(this._returnService.companyAssetDescriptorMappings);

        const actionColumn = {
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(1),
            minWidth: AgGridColumns.getActionColumnWidth(1),
            maxWidth: AgGridColumns.getActionColumnWidth(1),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            sortable: false,
            pinned: 'right',
            cellRendererFramework: ReturnAssetListGridActionCellRendererComponent,
            cellRendererParams: {
                viewDetails: this._viewAssetDetails.bind(this)
            } as ICellRendererParamsForReturnAssetListGridAction,
            pinnedRowCellRenderer: () => {return '';}
        };

        const finalColumnDefs = [...columns, ...assetDescriptorColumns, actionColumn];

        this._gridApi.setColumnDefs(finalColumnDefs);

        this._gridApi.setSortModel([{
            colId: 'assetNumber',
            sort: 'asc'
        }]);

        if (this._tabActive && !this._initialized) {
            this._setDataSource();
        }
    }

    async refresh(): Promise<void> {
        this._productAnalyticsService.logEvent('click-assets-refresh', {});
        await this._refreshDataSource();
    }

    async bulkUpdate(): Promise<void> {
        const updateReturnAssetSearchModel: Compliance.ReturnAssetSearchModel = {
            formRevisionIds: this._returnService.sharedState.formRevisionId ? [this._returnService.sharedState.formRevisionId] : [],
            formRevisionScheduleIds: this._returnAssetsService.sharedState.scheduleAndFactorFilter.formRevisionScheduleIds,
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            assessorIds: this._returnAssetsService.sharedState.assessorFactorsFilter.assessorIds || [],
            age: this._returnAssetsService.sharedState.scheduleAndFactorFilter.scheduleAge,
            priorReturnStatuses: this._returnAssetsService.sharedState.assetStatusesFilter,
            scheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            priorScheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            selectedRows: this.gridTracker.getSelectedRowsModel(),
            includeTotals: false,
            includeOnlyTotals: false,
            includeMergedParcels: this._returnService.isConsolidatedReturn,
            includeUnassigned: false,
            includeDisposed: false
        };

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyAssignedToNonReportableSchedule) {
            updateReturnAssetSearchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.NonReportable;
        }

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyAssignedToReportableSchedule) {
            updateReturnAssetSearchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.ReportableTaxableNotTaxable;
        }

        if (this._returnAssetsService.sharedState.scheduleAndFactorFilter.showOnlyNotAssignedToASchedule) {
            updateReturnAssetSearchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.OnlyNotAssignedToASchedule;
        }

        const params: ReturnAssetBulkUpdateParams = {
            filingBatchId: this._returnService.filingBatchId,
            searchModel: updateReturnAssetSearchModel,
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            showPriorScheduleField: false
        };

        const result = await this._modalService.showAsync(ReturnAssetBulkUpdateComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Working on it' });
        try {
            let updateWorkingSetReportingAssetIds: number[] = [];

            if (!updateReturnAssetSearchModel.selectedRows.selectAllRows) {
                updateWorkingSetReportingAssetIds = updateReturnAssetSearchModel.selectedRows.selectedRows;
            }
            await this._returnAssetsService.notifyAssetDetailsUpdated(updateWorkingSetReportingAssetIds, true);

            // re-load all loaded return assets
            const filingBatchId = this._returnService.filingBatchId;
            const reportingAssetIds: number[] = [];
            this._gridApi.forEachNode(x => {reportingAssetIds.push(x.data.reportingAssetId);});

            updateReturnAssetSearchModel.selectedRows = {
                selectAllRows: false,
                selectedRows: reportingAssetIds
            };
            updateReturnAssetSearchModel.includeTotals = true;

            const rowData = await lastValueFrom(this._returnAssetRepository.getListByFilingBatch(filingBatchId, updateReturnAssetSearchModel));

            await this._refreshRows(rowData);

        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    private async _refreshAssets(force: boolean = false): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localAssetFormRevisionId = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.formRevisionId;
        const sharedAssetFormRevisionId = returnAssetsServiceSharedState.formRevisionId;

        const localFormRevisionId = this._localReturnServiceSharedState && this._localReturnServiceSharedState.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localAssetStatusFilter = (this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetStatusesFilter) || [];
        const sharedAssetStatusFilter = returnAssetsServiceSharedState.assetStatusesFilter;

        const localAssessorFactors = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assessorFactorsFilter;
        const sharedAssessorFactors = returnAssetsServiceSharedState.assessorFactorsFilter;

        const localScheduleAndFactorFilter = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.scheduleAndFactorFilter;
        const sharedScheduleAndFactorFilter = returnAssetsServiceSharedState.scheduleAndFactorFilter;

        const localAssetDetailsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = this._localReturnAssetsServiceSharedState && this._localReturnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        if (
            force ||
            // check to see if the input parameters are available
            (!sharedReturnIds || !sharedFormRevisionId || !sharedAssetStatusFilter || !sharedAssessorFactors) ||
            // check to see if the input parameters have changed
            (!_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localAssetFormRevisionId, sharedAssetFormRevisionId) ||
            !_.isEqual(localAssetStatusFilter, sharedAssetStatusFilter) ||
            !_.isEqual(localAssessorFactors, sharedAssessorFactors) ||
            !_.isEqual(localScheduleAndFactorFilter, sharedScheduleAndFactorFilter) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp))) {
            await this._loadAssets();
        }
    }

    private async _loadAssets(): Promise<void> {
        // persist local state for future checks
        this._persistLocalState();
        await this._refreshDataSource();
    }

    private async _refreshRows(rowData: Compliance.QueryResultWithTotalsModel<Compliance.ReturnAssetModel, Compliance.ReturnAssetSearchTotalsModel>): Promise<void> {
        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);

        this._gridDataSource.clearTotals();

        const rowNodes: RowNode[] = [];
        this._gridApi.forEachNode(x => {
            const gridRow = x.data as Compliance.ReturnAssetModel;
            if (gridRow && gridRow.reportingAssetId) {
                const row = rowData.data.find(y => y.reportingAssetId === gridRow.reportingAssetId);
                if (row) {
                    _.extend(gridRow, row);
                    rowNodes.push(x);
                }
            }
        });

        this._gridApi.redrawRows({ rowNodes: rowNodes });
        this.handleTotalsUpdate(rowData.totals);

        this._gridDataSource.lastModifiedTimestamp = rowData.lastModifiedTimestamp;
    }

    private _persistLocalState(): void {
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();
        this._localReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);

        this._gridDataSource.clearTotals();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): void {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);

        this._gridDataSource = new ReturnAssetListAgGridDataSource(
            this._gridApi,
            this._returnService,
            this._returnAssetsService,
            this._returnAssetRepository,
            this.handleTotalsUpdate);

        this._gridApi.setDatasource(this._gridDataSource);
        this._persistLocalState();
        this._initialized = true;
    }

    private _setGridTracker(): void {
        if(!this.gridTracker) {
            return;
        }

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(async () => {
            const isBulkUpdateButtonVisible = (!this._returnService.isReturnInReadOnlyMode) && this._returnService.canEditCompany && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateButtonVisible);
            await this._reloadTotals();
        });

        this.gridTracker.isSelectingRange$.pipe(takeUntil(this._destroy$)).subscribe(loading => {
            if (!loading) {
                const isBulkUpdateButtonVisible = (!this._returnService.isReturnInReadOnlyMode) && this._returnService.canEditCompany && this.gridTracker.hasSelectedRows();
                this.isBulkUpdateVisible$.next(isBulkUpdateButtonVisible);
            } else {
                this.isBulkUpdateVisible$.next(false);
            }
        });
    }

    private async _getDraggedAssetIds(): Promise<number[]> {
        const busyRef = this._busyIndicatorService.show({ message: 'Working on it' });
        try {
            return await this.gridTracker.getSelectedRowIds();
        } finally {
            busyRef.hide();
        }
    }

    private async _getDraggedAssets(): Promise<Compliance.ReturnAssetModel[]> {
        const ids = await this._getDraggedAssetIds();
        const rowNodes = ids.reduce((acc, x) => {
            const node = this._gridApi.getRowNode(`${x}`);
            if (node) {
                acc.push(node.data as Compliance.ReturnAssetModel);
            }
            return acc;
        }, []);
        return rowNodes.length ? rowNodes : [];
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _viewAssetDetails(params: ICellRendererParamsForReturnAssetListGridAction): Promise<void> {
        const asset = params.data as Compliance.ReturnAssetModel;
        if (!asset) {
            return Promise.resolve();
        }

        const inputParams: AssetDetailsParams = {
            reportingAssetId: asset.reportingAssetId,
            assetId: asset.assetId,
            lienDate: this._returnService.lienDate,
            canEdit: this._returnService.canEditCompany && !this._returnService.isReturnInReadOnly,
            createNewAsset: false,
            topLevelCompanyId: this._returnService.companyId,
            assetDescriptors: this._returnService.companyAssetDescriptorMappings,
            newAssetCompanyId: null,
            canViewRyanPrivateItems: this._returnService.canViewRyanPrivateItems,
            fromLockedReturn: this._returnService.isReturnInReadOnly
        };

        const result = await this._modalService.showAsync(AssetDetailsComponent, inputParams, 'modal-xl');

        if (!(result && result.hasChanges)) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Working on it' });

        try {
            // update the working set
            const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();
            searchModel.selectedRows = this.gridTracker.getSelectedRowsModel();
            let updateWorkingSetReportingAssetIds: number[] = [];
            if (!searchModel.selectedRows.selectAllRows) {
                updateWorkingSetReportingAssetIds = searchModel.selectedRows.selectedRows;
            }
            await this._returnAssetsService.notifyAssetDetailsUpdated(updateWorkingSetReportingAssetIds, true);

            this._loadAssets();
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    handleTotalsUpdate = (totals: Compliance.ReturnAssetSearchTotalsModel, selectedTotals: boolean = false, isLoading: boolean = false) => {
        const assetModelRow: any = {
            cost: totals.totalCost || 0,
            reportedValue: totals.totalReportedValue || 0,
            priorCost: totals.totalPriorCost || 0,
            costChange: totals.totalCostChange || 0,
            priorReportedValue: totals.totalPriorReportedValue || 0,
            n1: totals.totalN1 || 0,
            n2: totals.totalN2 || 0,
            n3: totals.totalN3 || 0,
            n4: totals.totalN4 || 0,
            n5: totals.totalN5 || 0,
            n6: totals.totalN6 || 0,
            n7: totals.totalN7 || 0,
            n8: totals.totalN8 || 0,
            n9: totals.totalN9 || 0,
            n10: totals.totalN10 || 0,
            assetNumber: 'TOTAL',
            sourceAssetNumber: 'TOTAL',
            sourceCost: totals.totalCost || 0,
            sourceN1: totals.totalN1 || 0,
            sourceN2: totals.totalN2 || 0,
            sourceN3: totals.totalN3 || 0,
            sourceN4: totals.totalN4 || 0,
            sourceN5: totals.totalN5 || 0,
            sourceN6: totals.totalN6 || 0,
            sourceN7: totals.totalN7 || 0,
            sourceN8: totals.totalN8 || 0,
            sourceN9: totals.totalN9 || 0,
            sourceN10: totals.totalN10 || 0,
            inventoryJan: totals.totalInventoryJan || 0,
            inventoryFeb: totals.totalInventoryFeb || 0,
            inventoryMar: totals.totalInventoryMar || 0,
            inventoryApr: totals.totalInventoryApr || 0,
            inventoryMay: totals.totalInventoryMay || 0,
            inventoryJun: totals.totalInventoryJun || 0,
            inventoryJul: totals.totalInventoryJul || 0,
            inventoryAug: totals.totalInventoryAug || 0,
            inventorySep: totals.totalInventorySep || 0,
            inventoryOct: totals.totalInventoryOct || 0,
            inventoryNov: totals.totalInventoryNov || 0,
            inventoryDec: totals.totalInventoryDec || 0,
            inventoryAvr: totals.totalInventoryAvr || 0,
            estimatedFMV: totals.totalEstimatedFMV || 0
        };

        if (selectedTotals) {
            assetModelRow.selectedTotalsRow = true;
            assetModelRow.assetNumber = 'SELECTED';
            assetModelRow.sourceAssetNumber = 'SELECTED';
            this.gridSelectedTotalsRow = assetModelRow;
        } else {
            assetModelRow.totalsRow = true;
            this.gridTotalsRow = assetModelRow;
        }
        const totalRows = [this.gridTotalsRow];
        if (this.gridTracker.hasAnythingSelected()) {
            totalRows.push(this.gridSelectedTotalsRow);
        }

        if (isLoading && !this.totalsLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                assetModelRow.assetNumber = msg;
                assetModelRow.sourceAssetNumber = msg;
                this._gridApi.setPinnedBottomRowData(totalRows);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        this._timer.setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    private async _reloadTotals(): Promise<void> {
        const parcelIds = this._returnService.sharedState.returns.map(x => x.parcelId);
        const assessorIds = this._returnAssetsService.sharedState.assessorFactorsFilter.assessorIds;
        if (!this._gridDataSource || !(parcelIds || assessorIds)) {
            return;
        }

        this.handleTotalsUpdate({}, true, true);
        try {
            const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            this.handleTotalsUpdate(result || {}, true, false);
        } catch(e) {
            this.handleTotalsUpdate({}, true, false);
        }
    }

    private _cellClassForOverrides(colName, x: any) {
        if (colName.length < 1) {
            return '';
        }

        const rowData = x.data as Compliance.ReturnAssetModel;
        const colNameForSource = `source${  colName.charAt(0).toUpperCase()  }${colName.substring(1)}`;
        return (rowData && rowData[colName] && rowData[colNameForSource] && rowData[colName].toString() === rowData[colNameForSource].toString()) ? null : 'overridden';
    }
}
