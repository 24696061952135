import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { StateSummary } from "./state.model";
import { CacheManagerService } from '../Routing/cache-manager.service';
import { StateRepository } from '../../Core-Repositories';

@Injectable()
export class StateService {
    constructor(
        private readonly _cacheManagerService: CacheManagerService,
        private readonly _stateRepository: StateRepository
    ) {}

    private _navigatedFrom: string;
    private readonly BASE_URL = '/api/state/';

    set navigatedFrom(location: string) {
        this._navigatedFrom = location;
    }

    get navigatedFrom(): string {
        return this._navigatedFrom;
    }

    async getSummary(): Promise<StateSummary[]> {
        const states = await this._cacheManagerService.buildCacheGet<StateSummary[]>(`${this.BASE_URL}summary`, false);
        // This map is to support values added by the old state service that are used in the older code
        return states.map(x => ({ ...x, value: x.abbr, label: x.abbr}));
    };

    getById(stateId: number): Promise<Weissman.Model.Misc.State> {
        return lastValueFrom(this._stateRepository.getById(stateId))
    };
}
