import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const VALUATION_TEMPLATE_HELP: HelpContent[] = [
    {
        helpContentId: 'valuation-template.new-template',
        tooltipText: 'Create a new valuation template.'
    },
    {
        helpContentId: 'valuation-template.edit-template',
        tooltipText: 'Edit the template name and description.'
    }
];
