import { Component, Input } from '@angular/core';
import { chain, map, some } from 'lodash';
import { PaymentBatchService } from '../../paymentBatch.service';
import { BusyIndicatorService, SnackBarService } from 'src/app/Busy-Indicator';

@Component({
    selector: 'payment-batch-output',
    templateUrl: 'paymentBatchOutput.component.html'
})

export class PaymentBatchOutputComponent {
    constructor(private readonly _paymentBatchService: PaymentBatchService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _snackBarService: SnackBarService) {}
    @Input() paymentBatch: Core.PaymentBatchModel;

    feeds: {
        type: Core.PaymentBatchFeedTypes,
        checked: boolean;
        label: string;
    }[] = [
        {
            type: Core.PaymentBatchFeedTypes.FinalAPFeed,
            checked: false,
            label: 'AP Feed',
        },
        {
            type: Core.PaymentBatchFeedTypes.CheckFeed,
            checked: false,
            label: 'Check Feed'
        },
        {
            type: Core.PaymentBatchFeedTypes.CertificateOfMailLog,
            checked: false,
            label: 'USPS Certificate of Mailings'
        },
        {
            type: Core.PaymentBatchFeedTypes.CertificateOfMailSingle,
            checked: false,
            label: 'USPS Certificate of Mailings (Single)'
        },
        {
            type: Core.PaymentBatchFeedTypes.EnvelopeInserts,
            checked: false,
            label: 'Envelope Inserts'
        }
    ];

    get isDownloadDisabled(): boolean {
        return !some(this.feeds, 'checked');
    }

    isDisabled(feedType: Core.PaymentBatchFeedTypes) {
        if(feedType === Core.PaymentBatchFeedTypes.FinalAPFeed) {
            return false;
        } else {
            return !this.paymentBatch.hasCheckFeed || !this.paymentBatch.userHasElevatedPermission;
        }
    }

    async download() {
        const feedTypes = chain(this.feeds)
            .filter('checked')
            .map('type')
            .value();

        const busyRef = this._busyIndicatorService.show({ message: 'Setting LRP...' });

        try {
            const longRunningProcessId = await this._paymentBatchService.getOutputZip(this.paymentBatch.paymentBatchId, feedTypes);
            this._snackBarService.addById(longRunningProcessId, Compliance.LongRunningProcessTypeEnum.PaymentBatchExportFeedsAndFiles);
        } finally {
            busyRef.hide();
        }
    }
}