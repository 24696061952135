import { NgModule } from '@angular/core';
import { ScrollingModule  } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../../../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../../../UI-Lib/Pipes/shared-pipes.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BusyIndicatorModule } from '../../../../Busy-Indicator';
import { ReturnPreviewComponent } from './returnPreview.component';
import { ReturnPreviewFormListComponent } from './Form-List/returnPreviewFormList.component';
import { ReturnPreviewFormAssociationsComponent } from './Form-Associations/returnPreviewFormAssociations.component';
import { ReturnPreviewFormDownloadComponent } from './Form-Download/returnPreviewFormDownload.component';
import { ReturnPreviewFormViewerComponent } from './Form-Viewer/returnPreviewFormViewer.component';
import { ReturnPreviewFormOverrideEditComponent } from './Form-Viewer/Override-Edit/returnPreviewFormOverrideEdit.component';
import { ReturnPreviewFormOverridesComponent } from './Form-Overrides/returnPreviewFormOverrides.component';
import { ReturnPreviewFormOverrideListComponent } from './Form-Overrides-List/returnPreviewFormOverrideList.component';
import { ReturnPreviewFormOverridesListActionCellRendererComponent } from './Form-Overrides-List/agGridActionCellRenderer.component';
import { ReturnPreviewOverrideChangesComponent } from './Form-Viewer/Override-Changes/returnPreviewOverrideChanges.component';
import { ReturnPreviewService } from './returnPreview.service';
import { AgGridExtensionsModule } from '../../../AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../../../Common/AgGrid/agGridLoadingOverlay.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        TooltipModule,
        ScrollingModule,
        AgGridExtensionsModule,
        BsDropdownModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent
        ])
    ],
    declarations: [
        ReturnPreviewComponent,
        ReturnPreviewFormListComponent,
        ReturnPreviewFormAssociationsComponent,
        ReturnPreviewFormDownloadComponent,
        ReturnPreviewFormViewerComponent,
        ReturnPreviewFormOverrideEditComponent,
        ReturnPreviewFormOverridesComponent,
        ReturnPreviewFormOverrideListComponent,
        ReturnPreviewFormOverridesListActionCellRendererComponent,
        ReturnPreviewOverrideChangesComponent
    ],
    providers: [
        ReturnPreviewService
    ],
    exports: [
        ReturnPreviewComponent
    ]
})
export class ReturnPreviewModule{}
