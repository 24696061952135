import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer } from '../../../../Compliance/AgGrid/MultiSelectTracker';
import { Subscription } from 'rxjs';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../Compliance/AgGrid';
import { ParcelTypeCellRendererComponent, ParcelTypeCellRendererComponentParams } from '../../Parcel-Type-Cell-Renderer/parcelTypeCellRenderer.component';

export interface LinkedParcelAddParams {
    parcels: Core.LinkedParcelDTO[];
    allParcels: Core.LinkedParcelDTO[];
    parcelId: number;
}

interface LinkedParcelModelSelectable extends Core.LinkedParcelDTO {
    isSelected: boolean;
}

@Component({
    selector: 'linked-parcels-add',
    templateUrl: './linkedParcelsAdd.component.html',
    styleUrls: ['./linkedParcelsAdd.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LinkedParcelsAddComponent implements OnInit, OnDestroy {
    constructor(
        public readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _gridTracker: AgGridMultiSelectTracker;
    private _selectedRowsSub: Subscription;
    private _availableParcels: Core.LinkedParcelDTO[];

    params: LinkedParcelAddParams;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this._gridTracker.isRowSelected((params.data as Core.LinkedParcelDTO).parcelId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    rowsSelected: boolean = false;
    result: Core.LinkedParcelDTO[] = [];

    ngOnInit(): void {
        // remove any parcels in available already selected
        let result = _.filter(this.params.allParcels, (as: Core.LinkedParcelDTO) => !_.some(this.params.parcels, (s: Core.LinkedParcelDTO) => { return as.parcelId === s.parcelId; }));

        this._availableParcels = _.map(result, (s) => { return { ...s, isSelected: false } as LinkedParcelModelSelectable });

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this._gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'parcelId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Type',
                field: 'propertyTypeAbbr',
                width: AgGridColumns.textColumnExtraSmallWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: ParcelTypeCellRendererComponent,
                cellRendererParams: {
                    getParcelType: this._getParcelType.bind(this),
                    isLinkedParcel: this._isLinkedParcel.bind(this),
                    isMasterParcel: this._isMasterParcel.bind(this)
                } as ParcelTypeCellRendererComponentParams
            },
            {
                headerName: 'Parcel',
                field: 'acctNum',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        const rowIds = await this._gridTracker.getSelectedRowIds();
        this.result = this.params.allParcels.filter((x) => rowIds.some((y) => x.parcelId === y));
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _getParcelType(params: ParcelTypeCellRendererComponentParams): string {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return null;
        }

        return parcel.propertyTypeAbbr;
    }

    private _isLinkedParcel(params: ParcelTypeCellRendererComponentParams): boolean {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return false;
        }
        return parcel.linked;
    }

    private _isMasterParcel(params: ParcelTypeCellRendererComponentParams): boolean {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return false;
        }
        return parcel.masterParcel;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._availableParcels)) {
            return;
        }

        this._gridApi.setRowData(this._availableParcels);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const parcel = x.data as LinkedParcelModelSelectable;
                return parcel && parcel.parcelId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
