import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EntityAddress, CorrespondenceType, EntityAddressCorrespondence } from './address.model';
import { AddressDetailsModalService } from './address.details.modal.service';
import { ToastrService } from 'ngx-toastr';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { Address } from "../../Models/common.model"
import { EntityAddressDeliverabilityIssueComponent, EntityAddressDeliverabilityIssueParams } from "./address.deliverability.issue"
import { EntityAddressDeliverabilityActionEnum } from 'src/app/constants.new';

interface EntityAddressUI extends EntityAddress {
    isHovered?: boolean;
}

@Component({
    selector: 'address-details-modal',
    templateUrl: './address.details.modal.component.html'
})
export class AddressDetailsModalComponent implements OnInit {
    entityAddressesToRevertTo: EntityAddressUI[];
    private _correspondenceTypes: CorrespondenceType[];
    entityID: number;
    entityTypeID: number;
    entityAddresses: EntityAddressUI[];
    isAbleToBeEdited: boolean;
    justSelect: boolean;
    checkDeliverability: boolean;
    itemCount: number;

    isEditing: boolean = false;
    loading: boolean = false;
    onClose: any;

    constructor(public bsModalRef: BsModalRef, 
        private addressDetailsModalService: AddressDetailsModalService,
        private toastr: ToastrService,
        private readonly _modalService: WeissmanModalService) { }

    ngOnInit() {
        if(this.justSelect) {
            return;
        }
        
        this._setupCorrespondenceTypes();
    }

    private async _setupCorrespondenceTypes() {
        this.loading = true;

        try {
            this._correspondenceTypes = await this.addressDetailsModalService.getCorrespondenceTypes(this.entityTypeID);
    
            this.entityAddresses =  _.chain(this.entityAddresses)
                .map((entityAddress: EntityAddressUI) => this._addCorrespondenceTypes(entityAddress))
                .orderBy(['isDefault', 'address.address1'], ['desc', 'asc'])
                .value();
        } finally {
            this.loading = false;
        }
    }

    private _addCorrespondenceTypes(entityAddress: EntityAddressUI): EntityAddressUI {
        entityAddress.correspondenceTypes = _.chain(this._correspondenceTypes)
            .cloneDeep()
            .reject((correspondenceType: CorrespondenceType) => {
                return _.some(entityAddress.entityAddressCorrespondences, {correspondenceTypeID: correspondenceType.correspondenceTypeID})
            })
            .sortBy('name')
            .value();

        return entityAddress;
    }

    editEntityAddress(entityAddress: EntityAddress): void {
        this.entityAddressesToRevertTo = _.cloneDeep(this.entityAddresses);

        entityAddress.isEditMode = true;
        this.isEditing = true;
    }

    deleteEntityAddress(entityAddress: EntityAddress): void {
        if(entityAddress.efAction == 'add') {
            _.remove(this.entityAddresses, entityAddress)
        } else {
            entityAddress.efAction = 'delete';
        }
    }

    cancelAddEdit(entityAddress: EntityAddress): void {
        if(entityAddress.efAction == 'add') {
            _.remove(this.entityAddresses, entityAddress);
        } else {
            entityAddress.isEditMode = false;
        }

        this.entityAddresses = this.entityAddressesToRevertTo;

        this.isEditing = false;
    }

    async addNew(): Promise<void> {
        this.entityAddressesToRevertTo = _.cloneDeep(this.entityAddresses);

        let newEntityAddress: EntityAddressUI = new EntityAddress(this.entityID, this.entityTypeID);
        newEntityAddress.isEditMode = true;
        newEntityAddress.efAction = 'add';
        newEntityAddress.isDefault = !this.entityAddresses.length;
        newEntityAddress = this._addCorrespondenceTypes(newEntityAddress);

        this.entityAddresses.push(newEntityAddress);

        this.isEditing = true;
    }

    async update(entityAddress: EntityAddress): Promise<void> {
        if(!entityAddress.address.stateID) {
            this.toastr.error('State is Required!');

            return;
        }

        if(entityAddress.efAction != 'add') {
            entityAddress.efAction = 'update';
        }

        if(this.checkDeliverability){
            await this._determineEntityAddressDeliverability(entityAddress);
        } else {
            entityAddress.isEditMode = false;
            this.isEditing = false;
        }
    }

    defaultSelected(entityAddressClicked: EntityAddress): void {
        this.entityAddresses = _.map(this.entityAddresses, (entityAddress: EntityAddress) => {
            if(entityAddress.isDefault && entityAddress.entityAddressID != entityAddressClicked.entityAddressID) {
                entityAddress.isDefault = false;
                
                entityAddress.efAction = entityAddress.efAction || 'update';
            } 

            return entityAddress;
        });

        entityAddressClicked.isDefault = true;
        entityAddressClicked.efAction = entityAddressClicked.efAction || 'update';
    }

    addCorrespondence(correspondenceType: CorrespondenceType, entityAddress: EntityAddress): void {
        let entityAddressCorrespondence: EntityAddressCorrespondence = new EntityAddressCorrespondence();
        entityAddressCorrespondence.correspondenceType = correspondenceType;
        entityAddressCorrespondence.correspondenceTypeID = correspondenceType.correspondenceTypeID;
        entityAddressCorrespondence.entityAddressID - entityAddress.entityAddressID;

        entityAddress.entityAddressCorrespondences.push(entityAddressCorrespondence);

        _.remove(entityAddress.correspondenceTypes, correspondenceType);

        entityAddress.efAction = entityAddress.efAction || 'update';
    }

    removeCorrespondence(correspondence: EntityAddressCorrespondence, entityAddress: EntityAddress): void {
        _.remove(entityAddress.entityAddressCorrespondences, correspondence);

        entityAddress.correspondenceTypes.push(correspondence.correspondenceType);
        entityAddress.correspondenceTypes = _.sortBy(entityAddress.correspondenceTypes, 'name');

        entityAddress.efAction = entityAddress.efAction || 'update';
    }

    save(): void { 
        if(this.isEditing) {
            let editedAddress = _.find(this.entityAddresses, 'isEditMode');

            this.cancelAddEdit(editedAddress);
        }
        
        let entityAddresses: EntityAddress[] =  _.map(this.entityAddresses, 
            address => {
                _.omit(address, ['hover','isEditMode', 'correspondenceTypes']); 
                _.omit(address.addressDeliverabilityDecision, ['hover','isEditMode', 'correspondenceTypes']); 
                return address;
            }) as EntityAddress[];
        
        this.onClose(entityAddresses);
        this.bsModalRef.hide();
    }

    cancel(): void {
        this.bsModalRef.hide();
    }

    private _getUpmsAddressString(item: Core.UpmsAddressDto): string{
        var arr =  [item.line1, item.line2, item.city, item.state, item.zip, item.country];
        arr = arr.filter(e => e && String(e).trim());
        return arr.join(", ");
    }

    private _getAddressString(item: Address, item2: Core.UpmsAddressDto): string{
        var arr =  [item.address1, item.address2, item.city, item.abbr, item.zip, item2 && item2.country];
        arr = arr.filter(e => String(e).trim());
        return arr.join(", ");
    }

    private async _determineEntityAddressDeliverability(entityAddress: EntityAddress){
        this.loading = true;

        let upmsValidatedAddress: Core.UpmsValidatedAddressDto = null;

        try
        {
            upmsValidatedAddress = await this.addressDetailsModalService.getEntityAddressDeliverability(entityAddress, this.entityTypeID);
        } 
        finally
        {
            this.loading = false;
        }

        if(upmsValidatedAddress.deliverability == Core.UpmsEntityAddressDeliverability.Unavailable)
        {
            var params: EntityAddressDeliverabilityIssueParams = {
                entityTypeID: this.entityTypeID,
                givenAddress: this._getAddressString(entityAddress.address, upmsValidatedAddress.address),
                suggestedAddress: this._getUpmsAddressString(upmsValidatedAddress.address),
                entityID: this.entityID,
                addressID: entityAddress.address.addressID && entityAddress.address.addressID != 0 ? entityAddress.address.addressID : null,
                validated: upmsValidatedAddress.validated,
                messages: upmsValidatedAddress.messages,
                upmsServiceIsAvailable: false
            }

            const result: Core.EntityAddressDeliverabilityDecisionDTO = await this._modalService.showAsync(EntityAddressDeliverabilityIssueComponent, params, 'modal-md');
            entityAddress.addressDeliverabilityDecision = result;
        }

        if(upmsValidatedAddress.deliverability == Core.UpmsEntityAddressDeliverability.Undeliverable)
        {
            var params: EntityAddressDeliverabilityIssueParams = {
                entityTypeID: this.entityTypeID,
                givenAddress: this._getAddressString(entityAddress.address, upmsValidatedAddress.address),
                suggestedAddress: this._getUpmsAddressString(upmsValidatedAddress.address),
                entityID: this.entityID,
                addressID: entityAddress.address.addressID && entityAddress.address.addressID != 0 ? entityAddress.address.addressID : null,
                validated: upmsValidatedAddress.validated,
                messages: upmsValidatedAddress.messages,
                upmsServiceIsAvailable: true
            }

            const result: Core.EntityAddressDeliverabilityDecisionDTO = await this._modalService.showAsync(EntityAddressDeliverabilityIssueComponent, params, 'modal-md');
            entityAddress.addressDeliverabilityDecision = result;
            
            if(result.actionTakenID == EntityAddressDeliverabilityActionEnum.Revise) {
                entityAddress.isEditMode = true;
                this.isEditing = true;
                return Promise.resolve();
            }
        }

        entityAddress.isEditMode = false;
        this.isEditing = false;
        return Promise.resolve();
    }
}
