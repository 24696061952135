export interface IAddress extends Core.AddressModel {
    abbr?: string;
}

export class Address implements IAddress {
    address1: string;
    address2: string;
    addressID: number;
    city: string;
    email: string;
    ext: string;
    fax: string;
    mobilePhone: string;
    phone: string;
    rowVersion: number[];
    state: Core.StateModel;
    stateID: number;
    url: string;
    zip: string;
    abbr?: string;
}

export class UserTeamMember {
    firstName: string;
    lastName: string;
    userID: string;
    teamID: number;
    teamName: string;
}
