<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Action View Output Defaults
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="flat-button small-button secondary-button"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-flex-container-vertical ws-flex-auto">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>


