import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ColDef,
    CsvExportParams,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    ProcessCellForExportParams
} from 'ag-grid-community';
import { BusyIndicatorService, SnackBarService } from '../../../Busy-Indicator';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { CompanyRepository, FactorTableRepository } from '../../Repositories';
import {
    FactorTableListGridActionCellRendererComponent,
    ICellRendererParamsForFactorTableListGridAction
} from './agGridActionCellRenderer.component';
import { InstanceRights, RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import { UntypedFormControl } from '@angular/forms';
import { map, mergeMap } from 'rxjs/operators';
import {
    FactorTableDetailsComponent,
    FactorTableDetailsParams,
    GridSourceOptionEnum
} from '../Factor-Table-Details/factorTableDetails.component';
import { from, lastValueFrom, Observable, Subject } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { WsSelectValueFormatter } from '../../../UI-Lib/Select/select.interface';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AgGridExportOptions } from '../../AgGrid/ToolPanel/models';
import {
    FactorTableExportAttachmentsComponent
} from '../Factor-Table-Export-Attachments/factorTableExportAttachments.component';

import * as _ from 'lodash';

@Component({
    selector: 'factor-table-list',
    templateUrl: './factorTableList.component.html',
    styleUrls: ['./factorTableList.component.scss']
})
export class FactorTableListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService,
        private readonly _factorTableRepository: FactorTableRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _companyRepository: CompanyRepository,
        private readonly _snackBarService: SnackBarService
    ) {}

    private readonly _GRID_SOURCE_OPTION_SYSTEM: Compliance.NameValuePair<GridSourceOptionEnum> = {
        name: 'System',
        value: GridSourceOptionEnum.System
    };

    private readonly _GRID_SOURCE_OPTION_COMPANY: Compliance.NameValuePair<GridSourceOptionEnum> = {
        name: 'Company Specific',
        value: GridSourceOptionEnum.Company
    };

    private _gridApi: GridApi;
    private _currentYear = new Date().getFullYear();
    private _factorTables: Compliance.FactorTableListItemModel[];
    private _destroy$: Subject<void> = new Subject<void>();
    private _factorTableExportAttachmentsLongRunningProcessId: number;

    GridSourceOptionEnum = GridSourceOptionEnum;
    gridId: System.Guid = '7D885CAC-59F4-4CAE-AE56-B89D8DE60508';
    isInitialized: boolean = false;
    states: Compliance.FactorTableStateModel[];
    selectedState: UntypedFormControl = new UntypedFormControl(null);
    taxYears: number[] = [];
    selectedTaxYear: UntypedFormControl = new UntypedFormControl(null);
    tableTypes: Compliance.NameValuePair<Compliance.FactorTableTypeEnum>[];
    selectedTableType: Compliance.NameValuePair<Compliance.FactorTableTypeEnum>;
    hasCompanyWritePermission: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            suppressScrollOnNewData: true
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withColumnPinning()
        .build();

    companyFilter: string = '';
    companiesLoading: boolean = false;
    selectedCompanyId: number;
    selectedCompany: UntypedFormControl = new UntypedFormControl(null);
    refreshing: boolean;
    includeRetiredTables: boolean = false;

    companiesDataSource$: Observable<Core.CompanyDTO[]> = Observable
        .create((observer: any) => { observer.next(this.companyFilter); })
        .pipe(mergeMap((token: string) => this._filterCompanies(token)))
        .pipe(map((result: Compliance.QueryResultModel<Core.CompanyInfoModel[]>) => result.data));

    selectedGridSourceOption = this._GRID_SOURCE_OPTION_SYSTEM;
    gridSourceOptions: Compliance.NameValuePair<GridSourceOptionEnum>[] = [
        this._GRID_SOURCE_OPTION_SYSTEM,
        this._GRID_SOURCE_OPTION_COMPANY
    ];

    exportOptions: AgGridExportOptions = {
        canCancel: false,
        disabled: false,
        clientSideExport: () => {
            return {
                fileName: 'FactorTableExport.csv',
                processCellCallback: (params: ProcessCellForExportParams) => {
                    const field = params.column.getColDef().field;
                    const factorTableGridItem = params.node.data as Compliance.FactorTableListItemModel;
                    switch (field) {
                        case 'assessorName':
                            if (!factorTableGridItem) {
                                return '';
                            }
                            return factorTableGridItem.assessorName ? factorTableGridItem.assessorName : factorTableGridItem.state;
                        default:
                            return params.value;
                    }
                }
            } as CsvExportParams;
        }
    };

    formatStates: WsSelectValueFormatter = (state: Compliance.FactorTableStateModel) => `${state.stateName} (${state.stateAbbr})`;

    get canEdit(): boolean {
        if (!this.selectedState.value) {
            return false;
        }

        switch (this.selectedGridSourceOption.value) {
            case GridSourceOptionEnum.Company:
                return (
                    this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW) ||
                    this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW) ||
                    this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT)) &&
                    this.hasCompanyWritePermission;
            case GridSourceOptionEnum.System:
                return this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);
        }
    }

    get canExportAttachments(): boolean {
        switch (this.selectedGridSourceOption.value) {
            case GridSourceOptionEnum.Company:
                return (
                        this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW) ||
                        this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW) ||
                        this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT)) &&
                    this.hasCompanyWritePermission;
            case GridSourceOptionEnum.System:
                return this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);
        }
    }

    async ngOnInit(): Promise<void> {
        this.tableTypes = [
            { name: 'Depreciation', value: Compliance.FactorTableTypeEnum.Depreciation },
            { name: 'Index', value: Compliance.FactorTableTypeEnum.Index }
        ];

        this.selectedTableType = this.tableTypes[0];

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) => {
                    const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTableGridItem) {
                        return '';
                    }
                    return factorTableGridItem.assessorName ? factorTableGridItem.assessorName : factorTableGridItem.state;
                },
                cellClass: (params: ICellRendererParams): string => {
                    const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTableGridItem) {
                        return '';
                    }
                    return factorTableGridItem.assessorName ? '' : 'assessor-state';
                }
            },
            {
                headerName: 'Life',
                field: 'life',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Retired Tax Year',
                field: 'retiredTaxYear',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Latest Available Factors',
                field: 'taxYearValues',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams): string => {
                    const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTableGridItem) {
                        return '';
                    }
                    return factorTableGridItem.taxYear && factorTableGridItem.taxYear < this.selectedTaxYear.value ? 'factors-prior-year' : '';
                }
            },
            {
                headerName: 'Latest Floor Values',
                field: 'floorValues',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(5),
                minWidth: AgGridColumns.getActionColumnWidth(5),
                maxWidth: AgGridColumns.getActionColumnWidth(5),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: FactorTableListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this.viewDetails.bind(this),
                    delete: this.delete.bind(this),
                    copy: this.copy.bind(this),
                    canEdit: () => this.canEdit
                } as ICellRendererParamsForFactorTableListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();
        await this._refresh();
    }

    stateTracker(index: number, factorTableStateModel: Compliance.FactorTableStateModel): number {
        return factorTableStateModel.stateId;
    }

    async setSelectedGridSourceOption(gridSourceOption: Compliance.NameValuePair<GridSourceOptionEnum>): Promise<void> {
        if (this.selectedCompany.value) {
            this.companyFilter = '';
            this.selectedCompany.setValue(null);
            this.selectedCompanyId = null;
            this.hasCompanyWritePermission = false;
        }

        this.selectedGridSourceOption = gridSourceOption;

        await this._refresh();
    }

    async onSelectedTableTypeChange(): Promise<void> {
        await this._refresh();
    }

    async onCompanySelected(typeAheadMatch: TypeaheadMatch): Promise<void> {
        const company = typeAheadMatch.item as Core.CompanyInfoModel;
        this.selectedCompany.setValue(company);

        this.selectedCompanyId = company.companyId;
        this.hasCompanyWritePermission = company.writeAllowed;

        await this._refresh();
    }

    async onCompanyBlur(): Promise<void> {
        if (!this.companyFilter || this.companyFilter.trim() === '') {
            this.selectedCompany.setValue(null);

            this.selectedCompanyId = null;
            this.hasCompanyWritePermission = false;

            await this._refresh();
        }
    }

    onCompanyLoadingChange(isLoading: boolean): void {
        this.companiesLoading = isLoading;
    }

    async onSelectedStateChanged(): Promise<void> {
        await this._refresh();
    }

    async onSelectedTaxYearChanged(): Promise<void> {
        await this._refresh();
    }

    async addNew(): Promise<void> {
        await this._showModal();
    }

    async refresh(): Promise<void> {
        await this._refresh();
    }

    async viewDetails(params: ICellRendererParamsForFactorTableListGridAction): Promise<void> {
        await this._showModal(params.data as Compliance.FactorTableListItemModel);
    }

    async delete(params: ICellRendererParamsForFactorTableListGridAction, force: boolean = false): Promise<void> {
        if (!force) {
            try {
                await this._messageModalService.confirm(
                    'Are you sure you wish to delete the factor table?',
                    'Confirm Delete');
            } catch (e1) {
                return Promise.resolve();
            }
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting Factor Table' });
        let confirmMessage: string = '';
        const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;

        try {
            await lastValueFrom(this._factorTableRepository.delete(factorTableGridItem.factorTableId, force));
            await this._refresh();
            return Promise.resolve();
        }
        catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }
        finally {
            await busyRef.hide();
        }

        try {
            await this._messageModalService.confirm(
                confirmMessage,
                'Confirm Delete');
        }
        catch(e3) {
            return Promise.resolve();
        }

        // force
        await this.delete(params, true);

        return Promise.resolve();
    }

    async onIncludeRetiredTablesChanged(): Promise<void>{
        await this._refresh(false);
    }

    async copy(params: ICellRendererParamsForFactorTableListGridAction): Promise<void> {
        await this._showModal(params.data as Compliance.FactorTableListItemModel, true);
    }

    private async _refresh(reloadStates: boolean = true): Promise<void> {
        this.refreshing = true;
        if (reloadStates){
            const busyRef = this._busyIndicatorService.show({ message: 'Loading' });
            try {
                this.states = await lastValueFrom<Compliance.FactorTableStateModel[]>(this._factorTableRepository.getStates());

                if (this.selectedState.value) {
                    this.selectedState.setValue(_.find(this.states,
                        (x) => { return x.stateId === this.selectedState.value.stateId; }));
                }

                this._setTaxYears();
            }
            finally {
                await busyRef.hide();
            }
        }

        this._factorTables = [];

        try {
            if (this.selectedState.value &&
                this.selectedState.value.stateId &&
                this.selectedTableType.value !== null &&
                this.selectedTaxYear.value &&
                (this.selectedGridSourceOption.value === GridSourceOptionEnum.System || this.selectedCompany.value)) {

                this._gridApi && this._gridApi.showLoadingOverlay();

                const searchParams: Compliance.FactorTableSearchModel = {
                    stateId: this.selectedState.value.stateId,
                    tableType: this.selectedTableType.value,
                    taxYear: this.selectedTaxYear.value,
                    companyId: this.selectedCompany.value && this.selectedCompany.value.companyId,
                    includeRetiredTables: this.includeRetiredTables
                };

                this._factorTables = await lastValueFrom<Compliance.FactorTableListItemModel[]>(this._factorTableRepository.getList(searchParams));
            }
        } finally {
            this._gridApi && this._gridApi.hideOverlay();
        }

        this._setRowData();
        this.refreshing = false;
    }

    private _setTaxYears(): void {

        // set the list of tax years for the selected state by adding a tax year
        // for each year between the earliest factor table record and the current year

        this.taxYears = [];
        let minTaxYear = this._currentYear;
        let maxTaxYear: number = null;

        if (this.selectedState.value) {
            const selectedState = this.selectedState.value as Compliance.FactorTableStateModel;
            switch (this.selectedTableType.value) {
                case Compliance.FactorTableTypeEnum.Index:
                    if (selectedState.indexTableMinTaxYear) {
                        minTaxYear = selectedState.indexTableMinTaxYear;
                        maxTaxYear = selectedState.indexTableMaxTaxYear;
                    }
                    break;
                case Compliance.FactorTableTypeEnum.Depreciation:
                    if (selectedState.depreciationTableMinTaxYear) {
                        minTaxYear = selectedState.depreciationTableMinTaxYear;
                        maxTaxYear = selectedState.depreciationTableMaxTaxYear;
                    }
                    break;
            }
        }

        if (!maxTaxYear) {
            maxTaxYear = this._currentYear;
        }

        for (let year = maxTaxYear; year >= minTaxYear; year--) {
            this.taxYears.push(year);
        }

        // persist the selected tax year or set to current year if the selected tax year is not in the list
        if (!this.selectedTaxYear || !_.find(this.taxYears, (x) => { return x === this.selectedTaxYear.value; })) {
            this.selectedTaxYear.setValue(maxTaxYear);
        }
    }

    private _setRowData() {
        if (!(this._gridApi && this._factorTables)) {
            return;
        }

        this._gridApi.setRowData(this._factorTables);
    }

    private _filterCompanies(searchValue: string): Observable<Compliance.QueryResultModel<Core.CompanyInfoModel>> {
        const searchModel: Core.CompanyInfoSearchModel = {
            columnFilters: [{
                filterProperty: Core.CompanyInfoPropertyEnum.CompanyName,
                filterValues: [
                    {
                        filterType: Core.FilterTypeEnum.Contains,
                        filterValue: searchValue
                    }]
            }],
            topLevelOnly: true,
            preparePPReturnsOnly: true
        };

        return from(lastValueFrom(this._companyRepository.searchCompanyInfo(searchModel)));
    }

    private async _showModal(factorTableDetailModel?: Compliance.FactorTableListItemModel, isCopyFrom: boolean = false): Promise<void> {
        const params: FactorTableDetailsParams = {
            factorTableId: factorTableDetailModel && factorTableDetailModel.factorTableId,
            editMode: this.canEdit,
            newFactorTableParams: {
                source: this.selectedGridSourceOption.value,
                stateId: this.selectedState.value && this.selectedState.value.stateId,
                company: this.selectedCompany.value,
                isCopyFrom: isCopyFrom
            }
        };

        const result = await this._modalService.showAsync(FactorTableDetailsComponent, params, 'modal-xl');

        if (!(result && result.hasChanges)) {
            return Promise.resolve();
        }

        await this._refresh(); // refresh all because tax years might have changed

        return Promise.resolve();
    }

    async exportAttachments(): Promise<void> {
        const result = await this._modalService.showAsync(FactorTableExportAttachmentsComponent, null, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this._factorTableExportAttachmentsLongRunningProcessId = await lastValueFrom(this._factorTableRepository.exportAttachments(result));
        this._snackBarService.addById(this._factorTableExportAttachmentsLongRunningProcessId, Compliance.LongRunningProcessTypeEnum.FactorTableExportAttachments);

        return Promise.resolve();
    }
}
