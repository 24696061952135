<div *ngIf="moveCopyPayload">
    <button class="flat-button secondary-button small-button" [ngSwitch]="launchingEntity" (click)="backToEntity()">
        <i class="fa fa-arrow-left" style="margin-right: 5px;"></i>
        Back to <span *ngSwitchCase="entities.COMPANY">Company</span>
        <span *ngSwitchCase="entities.SITE">Site</span>
        <span *ngSwitchCase="entities.PARCEL">Parcel</span>
    </button>
    <div class="page-header as-header" style="margin-top: 0;">
        <h1 class="as-title">
            <span *ngIf="!isCopy">Move </span>
            <span *ngIf="isCopy">Copy </span>
            <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.SITE">Site<span *ngIf="launchingEntity === entities.COMPANY">s</span>
            </span>
            <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL">Parcel<span *ngIf="launchingEntity === entities.SITE">s</span>
            </span>
        </h1>
        <div class="as-header-functions" *ngIf="(launchingEntity === entities.SITE || isCopy) && (!awaitingConfirm && !loading && !isExecuting && !userCancelled)">
            <div *ngIf="launchingEntity === entities.SITE">
                <div>
                    <label class="radio-inline">
                        <input type="radio" [value]="entities.SITE" [(ngModel)]="moveCopyPayload.sourceEntityTypeID" (change)="movingSitesSelected()"> Sites
                    </label>
                </div>
                <div>
                    <label class="radio-inline">
                        <input type="radio" [value]="entities.PARCEL" [(ngModel)]="moveCopyPayload.sourceEntityTypeID" (change)="movingParcelsSelected()"> Parcels
                    </label>
                </div>
            </div>
            <div *ngIf="isCopy && years" style="margin-right: 15px;">
                <span>Beginning Year:</span>
                <select [(ngModel)]="moveCopyPayload.firstYear" class="form-select form-select-sm" style="width: auto; display: inline-block; margin-left: 5px;">
                    <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>
            </div>
            <div class="checkbox" *ngIf="isCopy">
                <label>
                    <input type="checkbox" [(ngModel)]="moveCopyPayload.includeAppeals"> Include Appeals
                </label>
                <label>
                    <input type="checkbox" [(ngModel)]="moveCopyPayload.includeAttachments"> Include Attachments
                </label>
                <label>
                    <input type="checkbox" [(ngModel)]="moveCopyPayload.includeComments"> Include Comments
                </label>
            </div>
        </div>
    </div>

    <div class="text-center" style="margin-top: 60px; margin-bottom: 60px" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!awaitingConfirm && !loading && !isExecuting && !userCancelled" class="row move-copy">
        <div class="col-lg-6">
            <div class="card panel-flat-color primary-panel">
                <div class="panel-flat-header" style="margin-bottom: 0;">
                    <div class="header-icon">
                        <i class="fa fa-arrow-circle-right" *ngIf="!isCopy"></i>
                        <i class="fa fa-clone" *ngIf="isCopy"></i>
                    </div>
                    <h3>Items to
                        <span *ngIf="!isCopy">Move</span>
                        <span *ngIf="isCopy">Copy</span>
                    </h3>
                </div>
                <div class="panel-flat-body">
                    <div class="mb-4">
                        <label>Company:</label>
                        <span>{{companyName}}</span>
                    </div>
                    <div *ngIf="moveCopyPayload.sourceEntityTypeID === entities.SITE">
                        <div class="mb-4">
                            <label>State:</label>
                            <select [(ngModel)]="selectedStateId" (change)="stateSelected()" class="form-select form-select-sm" style="width: auto; display: inline-block;"
                                [disabled]="launchingEntity !== entities.COMPANY">
                                <option value="0">All</option>
                                <option *ngFor="let state of states" [ngValue]="state.stateID">{{state.abbr}}</option>
                            </select>
                        </div>
                        <div class="mb-4 multi-select">
                            <label style="vertical-align: top;">Sites:</label>
                            <select multiple class="site-list" (change)="selectedEntitiesChanged($event.target)" [disabled]="launchingEntity !== entities.COMPANY">
                                <option [value]="site.siteID" [selected]="sourceIds.includes(site.siteID)" *ngFor="let site of stateSites">
                                    <ng-container *ngIf="!(showSiteNumber && site.siteNumber)">{{site.siteName}}</ng-container>
                                    <ng-container *ngIf="showSiteNumber && site.siteNumber">{{site.siteNumber}} - {{site.siteName}}</ng-container>
                                </option>
                            </select>
                        </div>
                        <div>
                            <div style="margin-left: 218px; display: inline-block;">
                                <button class="btn flat-button small-button primary-button" (click)="selectAll('sites')">Select All</button>
                            </div>
                            <span style="float: left;">
                                <label style="width: 127px;">
                                    <span>Exclude Inactive</span>
                                    <input type="checkbox" [(ngModel)]="excludeInactive" [disabled]="launchingEntity !== entities.COMPANY || isInactive" (ngModelChange)="siteInactiveStatusChanged()" />
                                </label>
                            </span>
                        </div>
                        <div class="mb-4">
                            <label style="width: 127px;">
                                <span>Show Site Number</span>
                                <input type="checkbox" [(ngModel)]="showSiteNumber" (ngModelChange)="showSiteNumberStatusChanged()" />
                            </label>
                        </div>
                    </div>
                    <div *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL">
                        <div class="mb-4">
                            <label>Site:</label>
                            <span>{{siteName}}</span>
                        </div>
                        <div class="mb-4 multi-select">
                            <label style="vertical-align: top;">Parcels:</label>
                            <select multiple class="site-list" (change)="selectedEntitiesChanged($event.target)" [disabled]="launchingEntity === entities.PARCEL">
                                <option [value]="parcel.parcelID" [selected]="sourceIds.includes(parcel.parcelID)" *ngFor="let parcel of parcels">{{parcel.parcelName}}</option>
                            </select>
                        </div>
                        <div>
                            <div style="margin-left: 218px; display: inline-block;">
                                <button class="btn flat-button small-button primary-button" (click)="selectAll('parcels')">Select All</button>
                            </div>
                            <span style="float: left;">
                                <label>Exclude Inactive</label>
                                <input type="checkbox" [(ngModel)]="excludeInactive" [disabled]="launchingEntity === entities.PARCEL || isInactive" (ngModelChange)="parcelInactiveStatusChanged()" />
                            </span>
                        </div>
                    </div>
                    <div *ngIf="!isCopy" >
                        <div class="mb-4">
                            <label>CSR Mode:</label>
                            <select [(ngModel)]="moveCopyPayload.csrMode" class="form-select form-select-sm" style="width: auto; display: inline-block;" >
                                <option [ngValue]="CopyMoveCSRModeEnum.AssumeDestination">Assume Destination</option>
                                <option [ngValue]="CopyMoveCSRModeEnum.PreserveOverriddenOnly">Preserve Overridden Only</option>
                                <option [ngValue]="CopyMoveCSRModeEnum.AlwaysPreserveExisting">Always Preserve Existing</option>
                            </select>
                        </div>
                    </div>
                    <div *ngIf="hasCsrErrors">
                        <i class="fa fa-info-circle"></i> There are errors that need to be corrected before this can be can be <span *ngIf="!isCopy">moved</span><span *ngIf="isCopy">copied</span>.  Check the Client Services panel.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card panel-flat-color primary-panel">
                <div class="panel-flat-header" style="margin-bottom: 0;">
                    <div class="header-icon">
                        <i class="fa fa-bullseye"></i>
                    </div>
                    <h3>Destination</h3>
                </div>
                <div class="panel-flat-body">
                    <div *ngIf="isCopy && hasInstanceAdminPermission" class="mb-4">
                        <label>Instance:</label>
                        <select [(ngModel)]="targetInstanceId" class="form-select form-select-sm" style="width: auto; display: inline-block;">
                            <option *ngFor="let instance of instances" [ngValue]="instance.instanceId">{{instance.name}}</option>
                        </select>
                    </div>
                    <div class="mb-4">
                        <label>Company:</label>
                        <company-picker style="display: inline-block; width: 75%;" [instanceIdFilter]="targetInstanceId" [company]="targetCompany" [useExplicitCrossInstanceListForPermCheck]="true" (companySelected)="targetCompanySelected($event)"></company-picker>
                    </div>
                    <div *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL && targetCompany">
                        <div class="mb-4">
                            <label>State:</label>
                            <select [(ngModel)]="selectedStateId" (change)="stateSelected(true)" class="form-select form-select-sm" style="width: auto; display: inline-block;" [disabled]="loadingSites">
                                <option value="0">All</option>
                                <option *ngFor="let state of states" [ngValue]="state.stateID">{{state.abbr}}</option>
                            </select>
                            <i *ngIf="loadingSites" class="fa fa-spinner fa-pulse"></i>
                        </div>
                        <div class="mb-4">
                            <label>Site:</label>
                            <select [(ngModel)]="moveCopyPayload.destEntityID" class="form-select form-select-sm" style="width: auto; display: inline-block;" [disabled]="loadingSites">
                                <option *ngFor="let site of stateSites" [value]="site.siteID">{{site.siteName}}</option>
                            </select>
                            <i *ngIf="loadingSites" class="fa fa-spinner fa-pulse"></i>
                        </div>
                    </div>
                    <button class="flat-button primary-button" style="position: absolute; bottom: 25px; right: 25px;" (click)="awaitingConfirm = true;" [disabled]="!sourceIds.length || !moveCopyPayload.destEntityID || hasCsrErrors">
                        <span *ngIf="!isCopy">Move</span>
                        <span *ngIf="isCopy">Copy</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="awaitingConfirm" class="row">
        <div class="col-lg-6 offset-md-3">
            <div class="card panel-flat-color primary-panel">
                <div class="panel-flat-header" style="margin-bottom: 0;">
                    <div class="header-icon">
                        <i class="fa fa-check-circle"></i>
                    </div>
                    <h3>Confirm</h3>
                </div>
                <div class="panel-flat-body">
                    <table style="margin-left: 50px;">
                        <tr>
                            <td style="vertical-align: top;">
                                <h3>
                                    The following
                                    <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.SITE">Site<span *ngIf="launchingEntity === entities.COMPANY">(s)</span>
                                    </span>
                                    <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL">Parcel<span *ngIf="launchingEntity === entities.SITE">(s)</span>
                                    </span>
                                </h3>
                                <ul>
                                    <li *ngFor="let id of sourceIds">{{getEntityName(id)}}</li>
                                </ul>
                            </td>
                            <td style="text-align: center; padding: 20px 100px; font-size: 120%;">
                                Will be <span *ngIf="!isCopy">Moved</span>
                                    <span *ngIf="isCopy">Copied</span>
                                <div style="margin-top: 10px;">
                                    <i *ngIf="!isCopy" class="fa fa-arrow-circle-right fa-2x"></i>
                                    <i *ngIf="isCopy" class="fa fa-clone fa-2x"></i>
                                </div>
                            </td>
                            <td style="vertical-align: top;">
                                <h3>
                                    To this <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.SITE">Company</span>
                                    <span *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL">Site</span>
                                </h3>
                                <ul>
                                    <li *ngIf="moveCopyPayload.sourceEntityTypeID === entities.SITE && targetCompany">{{targetCompany.companyName}}</li>
                                    <li *ngIf="moveCopyPayload.sourceEntityTypeID === entities.PARCEL && stateSites">{{getTargetSiteName(moveCopyPayload.destEntityID)}}</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                    <div style="display: flex; justify-content: flex-end; gap: 5px;">
                        <button class="flat-button success-button" (click)="executeMoveCopy()">OK</button>
                        <button class="flat-button warning-button" (click)="awaitingConfirm = false">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isExecuting" class="text-center container">
        <h3 *ngIf="sourceIdIdx < sourceIds.length" class="repeating-ellipses text-start"><span *ngIf="!isCopy">Moving</span><span *ngIf="isCopy">Copying</span></h3>
        <h3 *ngIf="sourceIdIdx >= sourceIds.length" class="text-start">Done</h3>
        <progressbar class="active" [class.progress-striped]="sourceIdIdx < sourceIds.length" [max]="sourceIds.length" [value]="sourceIdIdx" type="info"></progressbar>
        <h3>{{getEntityName(sourceIds[sourceIdIdx])}}</h3>

        <div style="margin-top: 20px;">
           <!-- <button *ngIf="sourceIdIdx < sourceIds.length" class="flat-button warning-button" (click)="cancelExecute()">Cancel</button>
            <button *ngIf="sourceIdIdx >= sourceIds.length" class="flat-button" (click)="moveCopyCompleted()">OK</button> -->
        </div>
    </div>
    <div *ngIf="userCancelled" class="text-center">
        <h3>Cancelling...</h3>
        <i class="fa fa-spinner fa-pulse fa-5x" style="margin-top: 50px;"></i>
    </div>
</div>
