<div class="form-horizontal further-limit-schedule-summary">
    <div class="row">
        <div class="col-md-5">
            <div class="mb-4 row" *ngIf="modalParameters?.filingBatchId">
                <label class="col-md-2 control-label">Form:</label>
                <div class="col-md-10">
                    <select [(ngModel)]="report.criteria.formRevisionId" class="form-select form-select-sm" [disabled]="formRevisions?.length === 1">
                        <option *ngFor="let formRevision of formRevisions" [ngValue]="formRevision.formRevisionId">{{ formRevision.formRevisionName }}</option>
                    </select>
                </div>
            </div>
            <div class="mb-4 row">
                <label class="col-md-2 control-label">Schedule:</label>
                <div class="col-md-10">
                    <select [(ngModel)]="scheduleId" class="form-select form-select-sm" [disabled]="scheduleSelectorDisabled">
                        <option *ngFor="let schedule of schedules" [ngValue]="schedule.formRevisionScheduleId">{{ schedule.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="checkbox">
                <label>
                    <input type="checkbox" [ngModel]="isSystemControlled" disabled> System Controlled
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="report.isDisabled"> Disabled
                </label>
            </div>
        </div>
    </div>
</div>
