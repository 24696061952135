import { Injectable } from "@angular/core";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ClientServicesExceptionModal} from "./client-service-exception.modal";

//This is a separate service because if it was in the normal exception service,
//there's a circular dependency which causes DI to go berserk
@Injectable()
export class ClientServicesExceptionModalService {
    exceptionsModalRef: BsModalRef;

    constructor(private modalService: BsModalService) { }

    openExceptionsModal(entityId: number, entityTypeId: number, clientServiceIds: number[], propertyTypeIds: number[], isInactive: boolean): void {
        this.exceptionsModalRef = this.modalService.show(ClientServicesExceptionModal, {class: 'responsibility-exceptions modal-lg'});
        let modalComponent: ClientServicesExceptionModal = this.exceptionsModalRef.content;
        modalComponent.entityId = entityId;
        modalComponent.entityTypeId = entityTypeId;
        modalComponent.clientServiceIds = clientServiceIds;
        modalComponent.propertyTypeIds = propertyTypeIds;
        modalComponent.isInactive = isInactive;
        modalComponent.initModal();
    }
}