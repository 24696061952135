import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { Assessor } from '../assessor.model';
import { AssessorCollectorService } from '../assessor.collector.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { BehaviorSubject } from 'rxjs';

export interface AddAssessorParams {
    state: Weissman.Model.Misc.State
}

export interface AssessorMunicipality {
    abbr: string;
    efAction: string;
    municipalityID: number;
    name: string;
}

@Component({
    selector: 'add-assessor-modal',
    template: `
        <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
        <ws-modal-wrapper headerLabel="New Assessor" acceptLabel="Save" (cancel)="close()" (save)="save()" [disableSave]="!newAssessor.abbr || !newAssessor.name">
            <div>
                <div class="form-group">
                    <label>Root Name</label>
                    <input #abbr type="text" class="form-control" [(ngModel)]="newAssessor.abbr">
                </div>
                <div class="form-group">
                    <label>Municipality</label>
                    <select [(ngModel)]="newAssessor.municipalityID" class="form-select-sm">
                        <option *ngFor="let option of municipalities" [ngValue]="option.municipalityID">{{option.abbr}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Full Name:</label>
                    <input type="text" class="form-control" [(ngModel)]="newAssessor.name">
                </div>
            </div>
        </ws-modal-wrapper>
    `,
})
export class AddAssessorModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<AddAssessorParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler
    ) { }

    @ViewChild('abbr') abbr: ElementRef;

    params: AddAssessorParams;
    result: void;
    serverAction: boolean = false;
    newAssessor: Assessor;
    municipalities: AssessorMunicipality[];

    private _$dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    async ngOnInit() {
        this.newAssessor = {
            stateID: this.params.state.stateID
        } as Assessor;

        this.serverAction = true;
        try {
            this.municipalities = await this._assessorCollectorService.getAssessorMunicipalities();
            this.newAssessor.municipalityID = this.municipalities[0]?.municipalityID;
        } finally {
            this.serverAction = false;

            if(this.municipalities) {
                this._$dataLoaded.next(true);
            }
        }
    }

    ngAfterViewInit() {
        this._$dataLoaded.subscribe(isLoaded => {
            if(isLoaded) {
                this.abbr.nativeElement.focus();
            }
        });
    }

    async save() {
        this.serverAction = true;

        try {
            const savedAssesor = await this._assessorCollectorService.createAssessor(this.newAssessor);
            this._bsModalRef.hide();
            this._upgradeNavigationServiceHandler.go('assessor', {stateId: this.params.state.stateID, id: savedAssesor.assessorID});

        } finally {
            this.serverAction = false;
        }
    }

    close() {
        this._bsModalRef.hide();
    }
}
