import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ReturnService } from '../../../../return.service';
import { ReturnAssetsService } from '../../returnAssets.service';
import { IReturnPartComponent } from '../../../../Models/returnPartServiceBase';
import { ReturnAssetFilter } from '../../Asset-Status-Selector/returnAssetStatusSelector.component';
import { ReturnAssetDisposalsListComponent, ReturnAssetDisposalsListParams } from '../../Asset-Disposals-List/returnAssetDisposalsList.component';
import { WeissmanModalService } from '../../../../../WeissmanModalService';

@Component({
    selector: 'return-asset-form-status-selector',
    templateUrl: './returnAssetFormStatusSelector.component.html'
})
export class ReturnAssetFormStatusSelectorComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor (
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _modalService: WeissmanModalService) { }

    private _assessorFactorSub: Subscription;

    get isActive(): boolean {
        return this._returnAssetsService.isActive;
    }

    selectedAssetStatusFilter: Compliance.ReturnAssetPriorReturnStatusEnum[];

    ngOnInit(): void {
        this._returnAssetsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this.selectedAssetStatusFilter = this._returnAssetsService.sharedState.assetStatusesFilter;
        this._assessorFactorSub = this._returnAssetsService.assessorFactorsFilter$.subscribe(() => this._refreshAssetStatusFilter());
    }

    onReturnPartServiceDeactivated(): void {
        this._assessorFactorSub && this._assessorFactorSub.unsubscribe();
    }

    async onSelectedAssetStatusFilterChange(filter: ReturnAssetFilter): Promise<void> {
        if (filter.isDeduction) {
            const params: ReturnAssetDisposalsListParams = {
                title: filter.name,
                priorReturnStatuses: filter.statuses,
                formRevisionScheduleId: null,
                targetNonReportableSchedule: filter.targetNonReportableSchedule === null || filter.targetNonReportableSchedule === undefined ||
                    filter.targetNonReportableSchedule,
                targetReportableSchedule: filter.targetReportableSchedule === null || filter.targetReportableSchedule === undefined ||
                    filter.targetReportableSchedule,
                isDisposalOrTransferOut: false,
                scheduleGroupType: filter.scheduleGroupType
            };

            await this._modalService.showAsync(ReturnAssetDisposalsListComponent, params, 'modal-xl');
        }
        else {
            this.selectedAssetStatusFilter = filter.statuses;
            this._returnAssetsService.setAssetStatusesFilter(this.selectedAssetStatusFilter);
        }
    }

    private async _refreshAssetStatusFilter(): Promise<void> {
        this._returnAssetsService.setAssetStatusesFilter(this.selectedAssetStatusFilter);
    }
}
