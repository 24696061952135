<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="'Bulk Update ' + params.selectedCount +' Records'"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid()">
    <form [formGroup]="bulkUpdateForm">
            <div class="table-responsive">
                <table class="table table-striped table-condensed table-borderless-body">
                    <tbody>
                        <tr>
                            <td>Account Type</td>
                            <td>
                                <gl-account-bulk-update-action [requiredField]="false"
                                                               [fieldAction]="accountTypeActionInitValue"
                                                               [possibleActions]="accountTypePossibleActions"
                                                               (fieldActionChange)="accountTypeActionChanged($event)">
                                </gl-account-bulk-update-action>
                            </td>
                            <td>
                                <select id="accountType"
                                        name="accountType"
                                        class="form-select form-select-sm"
                                        formControlName="accountType"                                        >
                                    <option *ngFor="let at of accountTypes" [ngValue]="at">{{ at.name }}</option>
                                </select>
                            </td>
                        </tr>

                        <tr *ngIf="showIncomeStatementCategoryChoices">
                            <td>Income Statement Category</td>
                            <td>
                                <gl-account-bulk-update-action [requiredField]="false"
                                                               [fieldAction]="categoryActionInitValue"
                                                               [possibleActions]="categoryPossibleActions"
                                                               (fieldActionChange)="incomeStatementCategoryActionChanged($event)">
                                </gl-account-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <income-statement-category-selector #incomeStatementCategorySelector
                                                                        [isRequiredField]="false"
                                                                        [glAccountTypeId]="bulkUpdateModel.accountTypeId"
                                                                        [isDisabled]="isDisabled(incomeStatementCategoryActionControl.value)"
                                                                        (incomeStatementCategoryChange)="onIncomeStatementCategoryChanged($event)">
                                    </income-statement-category-selector>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <label>
                                    <input
                                        type="checkbox"
                                        style="margin-right: 5px;"
                                        formControlName="isValidated">
                                    Is Validated
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <div *ngIf="params.selectionSummary.foundInIncomeStatements.length">
            <span>GL accounts found in an income statement will not be updated</span>
        </div>
        </form>
</ws-modal-wrapper>

