import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const GL_ACCOUNT_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'gl-account-list.new-gl-account',
        tooltipText: 'New G/L Account'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-found-in-accruals',
        tooltipText: 'Cannot bulk edit GL accounts found in accruals.'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-found-in-income-statements',
        tooltipText: 'Cannot bulk edit GL accounts found in income statements. Account has been validated.'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-applicable-to-accruals',
        tooltipText: 'Cannot bulk edit GL accounts applicable to accruals.'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-nothing-selected',
        tooltipText: 'Must select more than record to bulk edit.'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-mixed-types',
        tooltipText: 'Cannot mix I/S GL accounts with other types of GL accounts for bulk edit.'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-delete-mixed-types-income-statements',
        tooltipText: 'Cannot mix I/S GL accounts types for bulk edit.'
    }
];
