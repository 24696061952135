import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable, delayWhen, interval } from 'rxjs';
import { EasyAccessService } from 'src/app/Common/Services/easyAccess.service';

@Injectable()
export class LongRunningProcessRepository extends BaseRepository {
    constructor(httpClient: HttpClient, private _easyAccess: EasyAccessService) {
        super(httpClient);
    }

    get(longRunningProcessId: number): Observable<Compliance.LongRunningProcessModel> {
        const result = this.httpGet<Compliance.LongRunningProcessModel>(`/api/longrunningprocess/${longRunningProcessId}`);

        // It's possible to simulate LR process messages coming out of order by delaying this result for processes which
        // are not completed; this forces the UI to get notified of a completed process before it starts. This can happen
        // sometimes in normal usage (#244438).
        if (this._easyAccess.lrProcessIncompleteDelay)
        {
            return result.pipe(delayWhen(x => x.isCompleted ? interval(0) : interval(this._easyAccess.lrProcessIncompleteDelay)));
        }

        return result;
    }

    getList(searchModel: Compliance.LongRunningProcessSearchModel): Observable<Compliance.QueryResultModel<Compliance.LongRunningProcessModel>> {
        return this.httpPost(`/api/longrunningprocess`, searchModel);
    }

    getQuiescedStatus(): Observable<Compliance.LongRunningProcessQuiescedStatusModel> {
        return this.httpGet(`/api/longrunningprocess/quiescedStatus`);
    }

    updateQuiescedStatus(model: Compliance.LongRunningProcessQuiescedStatusModel): Observable<Compliance.LongRunningProcessQuiescedStatusModel> {
        return this.httpPut(`/api/longrunningprocess/quiescedStatus`, model);
    }

    startSyntheticLongRunningProcess(parameters: Compliance.SyntheticLongRunningProcessParameters): Observable<void> {
        return this.httpPost(`/api/longrunningprocess/synthetic`, parameters);
    }

    sendControlMessage(parameters: Compliance.ControlMessageLongRunningProcessParameters): Observable<void> {
        return this.httpPost(`/api/longrunningprocess/controlMessage`, parameters);
    }

    cancel(longRunningProcessId: number): Observable<void> {
        return this.httpPut(`/api/longrunningprocess/${longRunningProcessId}/cancel`, null);
    }

    pause(longRunningProcessId: number): Observable<void> {
        return this.httpPut(`/api/longrunningprocess/${longRunningProcessId}/pause`, null);
    }

    updateError(longRunningProcessError: Compliance.LongRunningProcessErrorModel): Observable<Compliance.LongRunningProcessModel> {
        return this.httpPost(`/api/longrunningprocess/${longRunningProcessError.longRunningProcessId}/error`, longRunningProcessError);
    }

    acknowledge(longRunningProcessId: number): Observable<Compliance.LongRunningProcessModel> {
        return this.httpPut(`/api/longrunningprocess/${longRunningProcessId}/acknowledge`, null);
    }

    getQueueTypes(): Observable<Compliance.LongRunningProcessQueueTypeModel[]> {
        return this.httpGet('/api/longrunningprocess/queueTypes');
    }

    getControlMessageTypes(): Observable<Compliance.LongRunningProcessControlMessageTypeModel[]> {
        return this.httpGet('/api/longrunningprocess/controlmessagetypes');
    }

    getServiceHosts(): Observable<string[]> {
        return this.httpGet('/api/longrunningprocess/serviceHosts');
    }

    startLrpListExport(exportModel: Compliance.LongRunningProcessExportModel): Observable<number> {
        return this.httpPost(`/api/longrunningprocess/export`, exportModel);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/longrunningprocess/export/${longRunningProcessId}`, options);
    }
}
