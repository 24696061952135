(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ColorsController', ColorsController);

	ColorsController.$inject = [];

	function ColorsController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
		var vm = this;
		vm.title = 'ColorsController';

		activate();

		////////////////

		function activate() {}
	}
})();
