import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { ComplianceInfoPanelComponent } from './Compliance-Info-Panel/complianceInfoPanel.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { ReportingParcelSiteListComponent } from './Reporting-Parcel-Site-List/reportingParcelSiteList.component';
import { FormsModule } from '@angular/forms';
import { ReturnModule } from '../Return/return.module';
import { ParcelSelectorComponent } from './Reporting-Parcel-Selector/parcelSelector.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TaxRateSetupModalLaunchService } from '../../Entity/Parcel/TaxRateSetup/taxRateSetupModalLaunchService';
import { ParcelCollectorTaxRateSetupModalComponent } from '../../Entity/Parcel/TaxRateSetup/parcelCollectorTaxRateSetupModal.component';
import { BillClusterTaxRateSetupModalComponent } from '../../Entity/Parcel/TaxRateSetup/billClusterTaxRateSetupModal.component';
import { FirstEncounterComponent } from '../../Entity/Parcel/TaxRateSetup/firstEncounter.component';
import { ParcelTaxRateService } from '../../Entity/Parcel/TaxRateSetup/parcelTaxRateService';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent
        ]),
        ReturnModule,
        BsDropdownModule,
        ModalModule,
        TypeaheadModule,
        TooltipModule
    ],
    declarations: [
        ComplianceInfoPanelComponent,
        ReportingParcelSiteListComponent,
        ParcelSelectorComponent,
        ParcelCollectorTaxRateSetupModalComponent,
        BillClusterTaxRateSetupModalComponent,
        FirstEncounterComponent
    ],
    providers: [
        TaxRateSetupModalLaunchService,
        ParcelTaxRateService
    ],
    exports: [
        ComplianceInfoPanelComponent,
        ReportingParcelSiteListComponent,
        ParcelSelectorComponent
    ]
})
export class ComplianceParcelModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('complianceInfoPanel', ComplianceInfoPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('reportingParcelSiteList', ReportingParcelSiteListComponent);
        hybridAdapterUtility.downgradeNg2Provider('taxRateSetupModalLaunchService', TaxRateSetupModalLaunchService);
        hybridAdapterUtility.downgradeNg2Provider('parcelTaxRateService', ParcelTaxRateService);
    }
}
