<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !editMode}">
        <panel-chevron [showBody]="isShowInfo"></panel-chevron>
        <h3>Return Settings</h3>
        <div class="float-end header-button-container" (click)="$event.stopPropagation()">
            <button class="flat-button icon-button" *ngIf="allowEdit && isShowInfo && !editMode" (click)="setEditMode()">
                <i class="fa fa-pencil"></i>
            </button>
            <button class="flat-button icon-button" *ngIf="isShowInfo && editMode" (click)="save()" [disabled]="!hasChanges">
                <i class="fa fa-save"></i>
            </button>
            <button class="flat-button icon-button" *ngIf="isShowInfo && editMode" (click)="cancelEdit()">
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
    <div class="card-body" *ngIf="isShowInfo">
        <loading-spinner [show]="loading" [size]="5" [marginBottom]="50"></loading-spinner>
        <div [hidden]="loading">
            <div class="ws-section">
                <div class="ws-section__header">
                    <h4 class="ws-section__header__title">Return Settings</h4>
                </div>
            </div>

            <div>
                <return-settings [entityId]="parcelId" [entityType]="ReturnSettingsEntityTypeEnum.Parcel"
                                 (onInitialized)="onReturnSettingsInitialized($event)"
                                 (changed)="onReturnSettingsChanged($event)"></return-settings>
            </div>

            <div class="ws-section">
                <div class="ws-section__header">
                    <h4 class="ws-section__header__title">Reporting Parcel Sites</h4>
                </div>
            </div>

            <div>
                <reporting-parcel-site-list [siteId]="siteId" [parcelId]="parcelId"
                                            (onInitialized)="onReportingParcelSitesInitialized($event)"
                                            (changed)="onReportingParcelSitesChanged($event)"></reporting-parcel-site-list>
            </div>
        </div>
    </div>
</div>
