import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OutputColumn } from '../column.model';
import { OutputColumnCategoryBrowse } from '../columnPicker.component';
import { SmartSearchService } from '../../smartSearch.service';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { TaskSeriesType } from '../../../../Task/Task.Model';

import { flatMap, uniq, reject } from 'lodash/fp';
import * as _ from 'lodash';

export interface BrowseColumnModalParams {
    existingColumns: OutputColumn[];
    otherColumnsThatMightDisable: OutputColumn[];
    selectedTaskType: TaskSeriesType;
    columnCategories: OutputColumnCategoryBrowse[];
    isTaskTypeSpecialCase: boolean;
    taskTypeDepthValue: number;
}

@Component({
    selector: 'browse-column-modal',
    templateUrl: './browseColumnModal.component.html',
    styleUrls: ['./browseColumnModal.component.scss']
})
export class BrowseColumnModalComponent implements OnInit, IWeissmanModalComponent<BrowseColumnModalParams, OutputColumn[]> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _smartSearchService: SmartSearchService
    ) {}

    params: BrowseColumnModalParams;
    result: OutputColumn[];

    selectedTaskType: TaskSeriesType;
    columnCategories: OutputColumnCategoryBrowse[];
    columnsToAdd: OutputColumn[] = [];
    offendingColumns: OutputColumn[];
    isTaskTypeSpecialCase: boolean = false;
    taskTypeDepthValue: number;

    ngOnInit(): void {
        this.selectedTaskType = this.params.selectedTaskType;
        this.columnCategories = this.params.columnCategories;
        this.isTaskTypeSpecialCase = this.params.isTaskTypeSpecialCase;
        this.taskTypeDepthValue = this.params.taskTypeDepthValue;

        this._calcCountAndDisabled();
    }

    clearSelection(): void {
        this.columnsToAdd = [];
        this._calcCountAndDisabled();
    }

    addColumns(): void {
        this.result = this.columnsToAdd;
        this.closeModal();
    }

    closeModal(): void {
        this._bsModalRef.hide();
    }

    columnClicked(column: OutputColumn, e): void {
        if (e.target.checked) {
            this.columnsToAdd.push(column);
        } else {
            _.pull(this.columnsToAdd, column);
        }

        this._calcCountAndDisabled();
    }

    isColumnChecked(column: OutputColumn): boolean {
        return this.columnsToAdd.some(x => x.columnId === column.columnId);
    }

    tabSelected(category: OutputColumnCategoryBrowse): void {
        if (!category.disabled || this.isTaskTypeSpecialCase) {
            return;
        }

        const invalidColumns = this._smartSearchService.getInvalidColumns([
            ...this.params.existingColumns,
            ...this.columnsToAdd,
            ...category.columns,
            ...this.params.otherColumnsThatMightDisable
        ], true);

        this.offendingColumns = _.flow([
            flatMap('offendingColumns'),
            uniq,
            reject(column => category.columns.some(x => x.columnId === column.columnId))
        ])(invalidColumns);
    }

    private _calcCountAndDisabled(): void {
        if (!this.isTaskTypeSpecialCase) {
            this._smartSearchService.disableColumns(this.columnsToAdd, true, this.taskTypeDepthValue);
        }

        this.columnCategories = this.columnCategories.map(category => {
            category.checkedCount = category.totalCheckedCount = this.columnsToAdd.filter(x => x.categoryID === category.categoryID).length;
            category.disabled = category.columns.every(x => x.disabled);

            category.subcategories = category.subcategories.map(subcategory => {
                subcategory.checkedCount = this.columnsToAdd.filter(x => x.categoryID === subcategory.categoryID).length;
                category.totalCheckedCount += subcategory.checkedCount;
                subcategory.disabled = subcategory.columns.every(x => x.disabled);

                return subcategory;
            });

            return category;
        });
    }

}
