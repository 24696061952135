import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AgGridPersistenceService } from '../../../agGridPersistence.service';

@Component({
    selector: 'ag-grid-current-layout-applied',
    template: '<div><span>Layout: {{layoutName}}</span></div>'
})
export class AgGridCurrentLayoutAppliedComponent implements OnInit, OnDestroy, OnChanges {
    constructor(private readonly _agGridPersistenceService: AgGridPersistenceService) {}

    @Input() gridId: System.Guid | string;

    layoutName: string;

    private _sub: Subscription;

    ngOnInit(): void {
        this._sub = this._agGridPersistenceService.currentGridLayoutApplied$(this.gridId as string)
            .subscribe(layoutName => this.layoutName = layoutName);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.gridId && !changes.gridId.firstChange) {
            this._sub.unsubscribe();
            this._sub = this._agGridPersistenceService.currentGridLayoutApplied$(this.gridId as string)
                .subscribe(layoutName => this.layoutName = layoutName);
        }
    }

    ngOnDestroy(): void {
        this._sub.unsubscribe();
    }
}
