export class GridHelper {
    public static getFilterColumns<T>(filterModel, propertyMap: Compliance.NameValuePair<T>[]): Core.FilterModel<T>[] {
        const columns: Core.FilterModel<T>[] = [];

        if (filterModel) {
            for (let prop in filterModel) {
                if (filterModel.hasOwnProperty(prop)) {
                    const property = this.getProperty(prop, propertyMap);
                    if (property !== null) {
                        columns.push({
                            filterProperty: property,
                            filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                            filterValues: filterModel[prop] && filterModel[prop].filterValues && filterModel[prop].filterValues.map(x => {
                                return {
                                    filterType: this.getFilterType(x.filterType.displayKey),
                                    filterValue: this.getFilterValue(x),
                                    filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                }
                            })
                        });
                    }
                }
            }
        }

        return columns;
    }

    static getSortModel<T>(sortModel: { colId: string; sort: string }[], propertyMap: Compliance.NameValuePair<T>[]) {
        const columns: Core.SortModel<T>[] = [];

        if (sortModel) {
            sortModel.forEach(x => {
                const property = this.getProperty(x.colId, propertyMap);
                if (property !== null) {
                    columns.push({
                        sortProperty: property,
                        sortDirection: this.getSortDirection(x.sort)
                    });
                }
            });
        }
        return columns;
    }

    static getFilterType(agFilterType: string): Core.FilterTypeEnum {
        switch (agFilterType) {
            case 'equals':
                return Core.FilterTypeEnum.Equals;
            case 'notEqual':
                return Core.FilterTypeEnum.NotEquals;
            case 'contains':
                return Core.FilterTypeEnum.Contains;
            case 'notContains':
                return Core.FilterTypeEnum.NotContains;
            case 'startsWith':
                return Core.FilterTypeEnum.StartsWith;
            case 'endsWith':
                return Core.FilterTypeEnum.EndsWith;
            case 'greaterThan':
                return Core.FilterTypeEnum.GreaterThan;
            case 'greaterThanOrEqualTo':
                return Core.FilterTypeEnum.GreaterThanOrEqualTo;
            case 'lessThan':
                return Core.FilterTypeEnum.LessThan;
            case 'lessThanOrEqualTo':
                return Core.FilterTypeEnum.LessThanOrEqualTo;
            case 'inRange':
                return Core.FilterTypeEnum.InRange;
            case 'blank':
            case 'Blank':
                return Core.FilterTypeEnum.Blank;
            case 'notBlank':
            case 'Not Blank':
                return Core.FilterTypeEnum.NotBlank;
            case 'yes':
                return Core.FilterTypeEnum.Yes;
            case 'no':
                return Core.FilterTypeEnum.No;
            case ' ':
                return Core.FilterTypeEnum.NoFilter;
            case 'CommaSeparated':
            case 'Comma Separated':
                return Core.FilterTypeEnum.CommaSeparated;
            case 'smartSearch':
            case 'Smart Search':
                return Core.FilterTypeEnum.SmartSearch;
            default:
                return null;
        }
    }

    static getFilterValue(agFilter): string {
        switch (agFilter.filterType) {
            case 'date':
                return agFilter.dateFrom;
            default:
                return agFilter.filterValue;
        }
    }

    static getProperty<T>(prop: string, propertyMap: Compliance.NameValuePair<T>[]) {
        const property = propertyMap.find(x => x.name === prop);

        if (!property) {
            return null;
        }

        return property.value;
    }

    static getSortDirection(sort: string): Core.SortDirectionEnum {
        return sort === 'desc' ?
            Core.SortDirectionEnum.Descending :
            Core.SortDirectionEnum.Ascending;
    }
}
