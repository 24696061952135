import {UserTeamMember} from "../../Common/Models/common.model";

export class ClientServiceExceptionRequest {
    entityId: number;
    entityTypeId: number;
    clientServiceIds: number[];
    propertyTypeIds: number[];
    includeAll: boolean;

    constructor(pEntityId: number, pEntityTypeId: number, pClientServiceIds: number[], pPropertyTypeIds: number[], pIncludeAll: boolean) {
        this.entityId = pEntityId;
        this.entityTypeId = pEntityTypeId;
        this.clientServiceIds = pClientServiceIds;
        this.propertyTypeIds = pPropertyTypeIds;
        this.includeAll = pIncludeAll;
    }
}

export class ClientServiceExceptionResponse {
    entityName: string;
    entityId: number;
    entityTypeId: number;
    parentEntityId: number;
    parentEntityTypeId: number;
    isTopLevel: boolean;
    entityResponsibilities: EntityResponsibility[];
    children: ChildEntityResponsibility[];
}

export class EntityResponsibility {
    clientServiceId: number;
    clientServiceName: string;
    propertyTypeId: number;
    propertyTypeName: string;
    workflowUserTypeId: number;
    assignee: UserTeamMember;
}

export class ChildEntityResponsibility extends EntityResponsibility{
    entityId: number;
    entityTypeId: number;
    companyId: number;
    companyName: string;
    siteId: number;
    siteName: string;
    parcelId: number;
    parcelAcctNum: string;
    state: string;
    hasExceptions: boolean;
    isSelected: boolean;
    consultingEngagementId: number;
}

export class EntityResponsibilityAssignmentChange {
    entityId: number;
    entityTypeId: number;
    clientServiceId: number;
    propertyTypeId: number;
    workflowUserTypeId: number;
    dateFrom: Date;
    assignee: Core.UserTeamModel;
    consultingEngagementId?: number;

    constructor(entityId: number, entityTypeId: number, clientServiceId: number, propertyTypeId: number, workflowUserTypeId: number, dateFrom: Date, assignee: Core.UserTeamModel, consultingEngagementId?: number) {
        this.entityId = entityId;
        this.entityTypeId = entityTypeId;
        this.clientServiceId = clientServiceId;
        this.propertyTypeId = propertyTypeId;
        this.workflowUserTypeId = workflowUserTypeId;
        this.dateFrom = dateFrom;
        this.assignee = assignee;
        this.consultingEngagementId = consultingEngagementId;
    }

}

export class BulkSaveExceptionResult {
    clientServiceId: number;
    entityId: number;
    entityTypeId: number;
    errorMessage: string;
    isAuthorized: boolean;
    isConcurrencyCheckFailed: boolean;
    propertyTypeId: number;
    warningMessage: string;
}
