import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { CompanyService } from './company.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';

export class TypeaheadCompany {
    companyID: number;
    companyName: string;
    isFavorite: boolean;
    instanceIdFilter: number;
}

//TODO: Make this async
@Component({
    selector: 'company-picker',
    templateUrl: './Company.Picker.Component.html'
})
export class CompanyPickerComponent implements OnInit, OnChanges {
    constructor(private companyService: CompanyService) {}

    @Input() company: TypeaheadCompany;
    @Input() topLevelOnly: boolean = false;
    @Input() activeOnly: boolean = false;
    @Input() instanceIdFilter: number;
    @Input() clientInstanceId: number;
    @Input() useExplicitCrossInstanceListForPermCheck: boolean = false;
    @Output() companySelected: EventEmitter<TypeaheadCompany> = new EventEmitter<TypeaheadCompany>();

    companyName: string;
    companiesLoading: boolean;
    dataSource: Observable<any>;

    ngOnInit() {
        this.dataSource = Observable.create((observer: any) => {
            if(!this.companyName) {
                return;
            }

            this.companyService.search(this.companyName, 8, 1, this.topLevelOnly, false, this.activeOnly, this.instanceIdFilter, this.clientInstanceId, this.useExplicitCrossInstanceListForPermCheck)
                .then((data) => {
                    observer.next(data);
                });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.companyName = this.company ? this.company.companyName : '';

        if(changes.instanceId) {
            this.companyName = '';
            this.company = undefined;
        }
    }

    typeaheadOnSelect(e: TypeaheadMatch) {
        this.company = e.item;
        this.companySelected.emit(e.item);
    }

    inputChanged() {
        if(!this.companyName) {
            this.companySelected.emit(new TypeaheadCompany());
        }
    }
}
