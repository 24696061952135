import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../../../Compliance/AgGrid';
import { ParcelReportsSitesListGridNameCellRendererParams, ParcelReportsSitesListGridNameCellRendererComponent } from './agGridNameCellRenderer.component';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';

export interface ParcelReportsSitesListParams {
    sites: Core.SiteNamePropertyDTO[];
    siteId: number;
}

@Component({
    selector: 'parcel-reports-sites-list',
    templateUrl: './parcelReportsSitesList.component.html',
    styleUrls: ['./parcelReportsSitesList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ParcelReportsSitesListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _routerService: UpgradeNavigationServiceHandler) { }

    private _gridApi: GridApi;

    params: ParcelReportsSitesListParams;
    reportingSites: Core.SiteNamePropertyDTO[] = [];

    gridOptions: GridOptions = new AgGridOptionsBuilder({})
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    async ngOnInit(): Promise<void> {
        this.reportingSites = _.map(this.params.sites,
            (x) => {
                return _.extend(
                    {},
                    x,
                    {
                        property: `${x.property ? x.property : '[No Site Number]'}${x.siteID === this.params.siteId ? ' (this parcel\'s parent site)': ''}`
                    });
            });

        this._setRowData();
    }

    ngOnDestroy() { }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Site Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: ParcelReportsSitesListGridNameCellRendererComponent,
                cellRendererParams: {
                    getName: (params) => {
                        const site = params.data as Core.SiteNamePropertyDTO;
                        return site && site.name;
                    },
                    navigate: (params) => {
                        const site = params.data as Core.SiteNamePropertyDTO;
                        if (!site) {
                            return;
                        }
                        this._navigateToSite(site.siteID);
                        this._bsModalRef.hide();
                    },
                    canNavigate: true
                } as ParcelReportsSitesListGridNameCellRendererParams
            },
            {
                headerName: 'Site Number',
                field: 'property',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _navigateToSite(siteId: number): void {
        this._routerService.go('siteRedirect', { 'entityID': siteId });
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.reportingSites)) {
            return;
        }

        this._gridApi.setRowData(this.reportingSites);
        this._gridApi.sizeColumnsToFit();
    }
}
