(function () {
	'use strict';

	var APP_SOURCE = 'DocumentService';
	var SERVICE_URL = '/api/DocumentIntake/';

	angular
		.module('weissmanApp')
		.factory(APP_SOURCE, Service);

	Service.$inject = ['Upload', 'authStorageService', '$http', 'sd.Http.Service', 'toastr', '$q'];

	function Service(Upload, authStorageService, $http, sdHttp, toastr, $q) {
		var service = {
			sendBatchDocuments: sendBatchDocuments,
			getDocumentBody: getDocumentBody,
			getDocumentInfo: getDocumentInfo,
			getDocumentInfoByTaskId: getDocumentInfoByTaskId,
			getTaskInfo: getTaskInfo,
            getTaskInfoByTaskID: getTaskInfoByTaskID,
			mapDocumentToEntity: mapDocumentToEntity,
			saveFile: saveFile,
			put: put
		};
		return service;

		function sendBatchDocuments(options, files) {
			var authData = authStorageService.getAuthData();

			return Upload.upload({
				url: SERVICE_URL + 'Batch',
				data: {
					intakeInfo: encodeURIComponent(JSON.stringify(options)),
					//headers: { 'Authorization': 'Bearer ' + authData.assess_token },
					file: files
				}
			});
		}

		function getDocumentBody(id) {
		    return $q(function (resolve, reject) {
                return $http({
                    url: '/api/attachment/' + id,
                    method: 'GET',
                    responseType: 'arraybuffer'
                }).then(function (result) {
                    if (!result.headers('Content-Disposition')) {
                        toastr.error("Document not found");
                        reject();
                    }
                    else {
                        resolve.apply(this, arguments);
                    }
                }, reject);
		    });
		}

		function getDocumentInfo(id) {
			return sdHttp.get(SERVICE_URL + id);
		}

		function getDocumentInfoByTaskId(id) {
		    return sdHttp.get(SERVICE_URL + 'task/' + id);
		}

		function getTaskInfo(id) {
		    return sdHttp.get(SERVICE_URL + id + '/taskInfo');
		}

		function getTaskInfoByTaskID(id) {
		    return sdHttp.get(SERVICE_URL + 'task/' + id + '/taskInfo');
        }

		function saveFile(blob, fileName) {
			var saveBlob = saveAs || navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob || navigator.msSaveBlob;

			saveBlob(blob, fileName)
		}

		function mapDocumentToEntity(id, data) {
		    return sdHttp.patch(SERVICE_URL + id, data);
		}		

		function put(data) {
		    return sdHttp.put(SERVICE_URL, data);
		}
	}
})();
