<ws-modal-wrapper [headerLabel]="modalTitle"
                  acceptLabel="Add"
                  [disableSave]="(isCompanyCollector && !stateId?.value) || !collectorForm?.valid"
                  (save)="add()"
                  (cancel)="cancel()">
    <div [formGroup]="collectorForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="mb-3">
                    <label>Abbreviation:</label>
                    <input type="text" class="form-control" required formControlName="abbr" placeholder="Enter Abbreviation">
                </div>
            </div>
            <div *ngIf="isCompanyCollector" class="col-lg-6">
                <label tabindex="-1">State:</label>
                <state-dropdown formControlName="stateID" [filterByCountryId]="1"></state-dropdown>
            </div>
        </div>
        <div class="mb-3">
            <label>Full Name:</label>
            <input type="text" class="form-control" formControlName="name" required placeholder="Enter Full Name">
        </div>
    </div>
</ws-modal-wrapper>
