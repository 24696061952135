import { Injectable } from "@angular/core";
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { RetrievalStatus, RetrievalStatusPayload } from "./retrieval-status.model";
import { BulkOperationResult } from "../../Common/Models/bulk-operation-result.model";

@Injectable()
export class RetrievalStatusService {
    constructor(private http: SDHttpService) { }

    getRetrievalStatuses(): Promise<RetrievalStatus[]> {
        return this.http.get('/api/DocumentRetrieval/RetrievalStatuses');
    }

    getRetrievalStatusIdsByTaskIds(taskIds: number[]): Promise<number[]> {
        return this.http.post('/api/DocumentRetrieval/RetrievalStatusSearch', taskIds);
    }

    setStatus(payload: RetrievalStatusPayload): Promise<BulkOperationResult[]> {
        return this.http.post('/api/DocumentRetrieval/SetDocumentRetrievalStatusBulk', payload);
    }
}
