<form [formGroup]="allocationForm">
    <ws-modal-wrapper headerLabel="New Allocation"
                      acceptLabel="Add"
                      (save)="addAllocation()"
                      (cancel)="cancel()"
                      [disableSave]="allocationForm.invalid"
                      [tabindex]="6">

        <div class="mb-3">
            <label>Tax Year:</label>
            <ws-select [returnEntireOption]="true"
                       [isPrimitive]="true"
                       formControlName="taxYear"
                       [canSearch]="false"
                       [options]="taxYears">
            </ws-select>
            <p *ngIf="allocationForm.controls.taxYear.errors && !allocationForm.controls.taxYear.pristine" class="help-block">
                Tax year is required
            </p>
        </div>

        <div class="mb-3">
            <label>Description:</label>
            <input name="acctNum" type="text" formControlName="description" class="form-control" placeholder="Enter Description" maxlength="1000" tabindex="1">
            <p *ngIf="allocationForm.controls.description.errors && !allocationForm.controls.description.pristine" class="help-block">
                Description is required
            </p>
        </div>

        <div class="mb-3">
            <label>Parcel:</label>
            <parcel-selector [companyId]="params.companyId" formControlName="parcelId" [getParcels]="getParcels">
            </parcel-selector>
            <p *ngIf="allocationForm.controls.parcelId.errors && !allocationForm.controls.parcelId.pristine" class="help-block">
                Parcel is required
            </p>
        </div>

    </ws-modal-wrapper>
</form>
