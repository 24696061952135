import { Injectable } from "@angular/core";
import { SDHttpService } from "../Common/Routing/sd-http.service";

const baseUrl = '/api/descriptorpicklist/';

@Injectable()
export class DescriptorPicklistService {
    constructor(private http: SDHttpService) { }

    getByPicklistGroupId(id: number): Promise<Weissman.Model.Descriptors.DescriptorPickList[]> {
        if (id) {
            const url = baseUrl + id;
            const config = {};

            return this.http.get(url, config);
        } else {
            return Promise.resolve([]);
        }
    }

    updateItem(item: Weissman.Model.Descriptors.DescriptorPickList) {
        return this.http.put(baseUrl, item, {});
    }

    createItem(item: Weissman.Model.Descriptors.DescriptorPickList) {
        return this.http.post(baseUrl, item, {});
    }

    deleteItemById(id: number) {
        const url = baseUrl + id;

        return this.http.delete(url, {});
    }

    canLimitToList(descriptorId: number, picklistGroupId: number) {
        const url = baseUrl + 'canlimittolist/';
        const config = {
            params: {
                descriptorID: descriptorId,
                groupID: picklistGroupId
            }
        };

        return this.http.get(url, config);
    }
}
