import { Component } from "@angular/core";
import { UpgradeNavigationServiceHandler } from "../../Common/Routing/upgrade-navigation-handler.service";
import { ProductAnalyticsService } from "../../Common/Amplitude/productAnalytics.service";
import { StateService } from "../../Common/States/States.Service";

@Component({
    selector: 'states-button-widget',
    template: `
    <div class="widget-button">
        <h2 class="text-center">States</h2>
        <div class="widget-button-content">
            <div><i class="fa fa-globe fa-5x fa-fw widget-button-icon" (click)="goToStatePage()"></i></div>
            <div class="widget-button-text">Here, you will find a list of states to which you can navigate. From there, access assessment classes, collectors, and assessors.</div>
        </div>
    </div>
    `,
    styleUrls: ['dashboard-widget.scss']
})
export class StatesButtonWidgetComponent {
    constructor(
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _stateService: StateService
    ) { }

    goToStatePage(): void {
        this._stateService.navigatedFrom = 'dashboard';
        this._upgradeNavigationServiceHandler.go('states', {});
    }
}
