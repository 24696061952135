<ws-modal-wrapper headerLabel="Site/Parcel Characteristics" [hideSave]="!canEdit" (cancel)="cancel()" (save)="save()" [cancelLabel]="canEdit ? 'Cancel' : 'Close'" [suppressKeyboardEvents]="true">
    <div class="text-center loading-all" *ngIf="!inUseDescriptors">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="inUseDescriptors">
        <div *ngIf="inUseDescriptors.site || inUseDescriptors.parcel">
            <div *ngIf="accrualDescriptors.length" style="max-height: 400px; overflow: auto;">
                <table class="table table-striped table-condensed">
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Entity</th>
                        <th *ngIf="canEdit"></th>
                    </tr>
                    <tr *ngFor="let item of accrualDescriptors">
                        <td>{{item.descriptorName}}</td>
                        <td>{{item.categoryName}}</td>
                        <td>
                            <span *ngIf="item.entityTypeId == EntityTypeIds.SITE">Site</span>
                            <span *ngIf="item.entityTypeId == EntityTypeIds.PARCEL">Parcel</span>
                        </td>
                        <td *ngIf="canEdit" style="width: 1px;">
                            <button class="flat-button icon-button warning-button" (click)="removeDescriptor(item)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div *ngIf="!accrualDescriptors.length" class="text-center">
                <em>List Empty</em>
            </div>
            <div *ngIf="canEdit" class="company-in-use-characteristics">
                <label>Add Characteristic:</label>
                <div class="mb-4">
                    <accruals-descriptor-selector
                        placeholder="Site"
                        [inUseDescriptors]="inUseDescriptors.site"
                        (onDescriptorSelected)="descriptorSelected($event)">
                    </accruals-descriptor-selector>
                </div>
                <div class="mb-4">
                    <accruals-descriptor-selector
                        placeholder="Parcel"
                        [inUseDescriptors]="inUseDescriptors.parcel"
                        (onDescriptorSelected)="descriptorSelected($event)">
                    </accruals-descriptor-selector>
                </div>
            </div>
        </div>
        <div *ngIf="!inUseDescriptors.site && !inUseDescriptors.parcel" class="text-center">
            <em>Company does not have any characteristics in use.</em>
        </div>
    </div>
</ws-modal-wrapper>
