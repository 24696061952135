import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GLAccountRepository } from '../Repositories';
import { HttpResponse } from '@angular/common/http';
import { CompanyService } from '../../Entity/Company/company.service';
import { RestrictService } from '../../Common/Permissions/restrict.service';

@Injectable()
export class GLAccountService {
    private _accrualsFeatureEnabled: boolean = false;

    constructor(
        private readonly _glAccountRepository: GLAccountRepository,
        private readonly _companyService: CompanyService,
        private readonly _restrictService: RestrictService)
    {
        this._accrualsFeatureEnabled = null;
    }

    private _company: Core.CompanyModel;
    private _tlCompany: Core.CompanyRecordDTO;
    private _canReadCompany: boolean = false;
    private _canWriteCompany: boolean = false;
    private _startResults: Compliance.GLAccountStartResultModel;

    get company(): Core.CompanyModel {
        return this._company;
    }

    get companyId(): number {
        return this._company && this._company.companyID;
    }

    get tlCompany(): Core.CompanyRecordDTO {
        return this._tlCompany;
    }

    get tlCompanyId(): number {
        return this._tlCompany && this._tlCompany.companyID;
    }

    get isTopLevelCompany(): boolean {
        return this._company && this._company.companyID === this._company.topLevelCompanyId;
    }

    get isSubsidiaryCompany(): boolean {
        return this._company && this._company.companyID !== this._company.topLevelCompanyId;
    }

    get companyName(): string {
        return this._company && this._company.companyName;
    }

    get canReadCompany(): boolean {
        return this._canReadCompany;
    }

    get canWriteCompany(): boolean {
        return this._canWriteCompany;
    }

    get startResults(): Compliance.GLAccountStartResultModel {
        return this._startResults;
    }

    get accrualsFeatureEnabled(): boolean {
        return this._accrualsFeatureEnabled;
    }

    async start(companyId: number): Promise<void> {
        this._accrualsFeatureEnabled = await this._companyService.checkAccrualsEnabled();

        this._company = await this._companyService.load(companyId, false, false);
        this._tlCompany = await this._companyService.getTopCompanyForEntity(Core.EntityTypes.Company, this._company.topLevelCompanyId);

        const read = await this._restrictService.hasCompanyPermission(this._company.topLevelCompanyId, Core.AccessRightsEnum.Read);
        this._canReadCompany = read && !!this._company;

        if (this._canReadCompany) {
            const write = await this._restrictService.hasCompanyPermission(this._company.topLevelCompanyId, Core.AccessRightsEnum.Write);
            this._canWriteCompany = write &&
                ((this._company.companyID === this._company.topLevelCompanyId) ||
                    (this._company.companyID !== this._company.topLevelCompanyId) && (!this._company.useTopLevelCompanyCOA));
        }

        // Retrieve data for G/L Account popup selections.
        this._startResults = await lastValueFrom(this._glAccountRepository.start(this._company.topLevelCompanyId));
    }

    stop(): void {
        this._company = null;
        this._canReadCompany = false;
        this._canWriteCompany = false;
        this._startResults = null;
    }

    async startExport(companyId: number, exportModel: Compliance.GLAccountExportModel): Promise<number> {
        return await lastValueFrom(this._glAccountRepository.startExport(companyId, exportModel));
    }

    async getExport(companyId: number, longRunningProcessId: number): Promise<HttpResponse<Blob>> {
        return await lastValueFrom(this._glAccountRepository.getExport(companyId, longRunningProcessId));
    }

    async get(glAccountId: number): Promise<Compliance.GLAccountModel> {
        return await lastValueFrom(this._glAccountRepository.get(glAccountId));
    }

    async create(glAccount: Compliance.GLAccountModel): Promise<Compliance.GLAccountModel> {
        return await lastValueFrom(this._glAccountRepository.create(glAccount));
    }

    async update(glAccount: Compliance.GLAccountModel): Promise<Compliance.GLAccountModel> {
        return await lastValueFrom(this._glAccountRepository.update(glAccount));
    }

    async updateBulk(companyId: number, bulkUpdateModel: Compliance.GLAccountBulkUpdateModel): Promise<void> {
        await lastValueFrom(this._glAccountRepository.bulkUpdate(companyId, bulkUpdateModel));
    }

    async updateBulkIncomeStatementTypes(companyId: number, bulkUpdateModel: Compliance.GLAccountBulkUpdateIncomeStatementTypesModel): Promise<void> {
        await lastValueFrom(this._glAccountRepository.bulkUpdateIncomeStatementTypes(companyId, bulkUpdateModel));
    }

    async delete(glAccountId: number): Promise<void> {
        return await lastValueFrom(this._glAccountRepository.delete(glAccountId));
    }

    async selectionSummary(companyId: number, searchModel: Core.SearchModel<Compliance.GLAccountPropertyEnum>, selectedRowsModel: Compliance.SelectedRowsModel) {
        return await lastValueFrom(this._glAccountRepository.selectionSummary(companyId, searchModel, selectedRowsModel));
    }
}
