import { CollectorPaymentOption } from '../../Assessor-Collector/Collector/Billing-Scenario/billingScenario.models';
import { Payment } from './payment.model';

export class Bill {
	billClusterID: number;
	billID: number;
	collectorPaymentOption: CollectorPaymentOption;
	collectorPaymentOptionID: number;
	efAction: string;
	hasAttachments: boolean;
	hasComments: boolean;
	name: string;
	payments: Payment[];
	receivedDate: Date;
	receivedDateString: string;
	tabName: string;
	status: number;
	calcProjected: boolean;
	directAsmt: number;
	annualAssessmentID: number;
	rowVersion: string;
	billClusterRowVersion: string;
	isSupplemental: boolean;
	prorationPct: number;
	displayProrationPct: number;
	showActualCheckbox: boolean;
	hasInProgressIntakeItems: boolean;	

	//Derived Values
	billAmount: number;
	collectorPaymentOptions: CollectorPaymentOption[];
	taskSummary: any;

	constructor() {
		this.billClusterID = 0;
		this.payments = [new Payment()];
	}
}
