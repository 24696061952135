import { NgModule } from '@angular/core';
import { Ng2StateDeclaration, UIRouter, UIRouterModule } from '@uirouter/angular';
import { StateDeclaration } from '@uirouter/core/lib/state/interface';
import { Transition } from '@uirouter/core/lib/transition/transition';
import { Visualizer } from '@uirouter/visualizer';
import { ChangePasswordComponent } from './Account/Change-Password/changePassword.component';
import { ExternalAPIKeyComponent } from './Account/ExternalAPI/external-api-key.component';
import { LoginComponent } from './Account/Login/login.component';
import { AccrualsPageComponent } from './Accruals/accruals.page.component';
import { AboutComponent } from './Admin/About/about.component';
import { AdminComponent } from './Admin/admin.component';
import { TestDXPPageComponent } from './Admin/DXP/testDXPPage.component';
import { LoginManagerComponent } from './Admin/LoginManager/loginManager.component';
import { APIKeyDetailComponent } from './Admin/ManageAPIKeys/api-key-detail.component';
import { ManageAPIKeysComponent } from './Admin/ManageAPIKeys/manage-api-keys.component';
import { AgentsPageComponent } from './Agents/agents.page.component';
import { AppealApplicationComponent } from './Appeal-Application/appealApplication.component';
import {
    ARCCComponent
} from './Appeal-Recommendation/ARCC/aRCC.component';
import { AssessorComponent } from './Assessor-Collector/Assessor/assessor.component';
import { CollectorComponent } from './Assessor-Collector/Collector/collector.component';
import { AssessorListComponent } from './Assessor-Collector/List/assessorList.component';
import { CollectorListComponent } from './Assessor-Collector/List/collectorList.component';
import { ForecastingBudgetingPageComponent } from './Budget/forecasting.budgeting.page.component';
import { BulkImportAssessmentsComponent } from './Bulk-Import/Assessments/bulk.import.assessments.component';
import { BulkImportSitesComponent, ImportType } from './Bulk-Import/Sites/bulk.import.sites.component';
import { CsrCommandCenterComponent } from './Client-Services/Command-Center/csrCommandCenter.component';
import { InstanceRights, Roles } from './Common/Permissions/restrict.service';
import { AllocationDetailsComponent } from './Compliance/Allocation/Allocation-Details/allocationDetails.component';
import { AssetListComponent } from './Compliance/Asset/Asset-List/assetList.component';
import { ElectronicFilingComponent } from './Compliance/Electronic-Filing/electronicFiling.component';
import { EntityImportListComponent } from './Compliance/Entity-Import/Import-List/importList.component';
import { EntityImportProcessComponent } from './Compliance/Entity-Import/Import-Process/importProcess.component';
import {
    ImportSpecificationListComponent
} from './Compliance/Entity-Import/Import-Specification-List/importSpecificationList.component';
import { FactorTableListComponent } from './Compliance/Factor-Table/Factor-Table-List/factorTableList.component';
import {
    FilingBatchDetailsComponent
} from './Compliance/Filing-Batch/Filing-Batch-Details/filingBatchDetails.component';
import { FilingBatchListComponent } from './Compliance/Filing-Batch/Filing-Batch-List/filingBatchList.component';
import { FormListComponent } from './Compliance/Form/Form-List/formList.component';
import { FormPageComponent } from './Compliance/Form/Form-Page/formPage.component';
import { GLAccountListComponent } from './Compliance/GL-Account/GL-Account-List/glAccountList.component';
import {
    LongRunningProcessPageComponent
} from './Compliance/Long-Running-Process/Long-Running-Process-Page/longRunningProcessPage.component';
import { ReturnPageComponent } from './Compliance/Return/returnPage.component';
import {
    StateJurisdictionCommandCenterComponent
} from './Compliance/State-And-Jurisdiction/stateJurisdiction.component';
import {
    SupplementalInformationSheetComponent
} from './Compliance/Supplemental-Information/Supplemental-Information-Sheet/supplementalInformationSheet.component';
import { ContactModalOrigin } from './constants.new';
import { ConsultingEngagementsComponent } from './Consulting/consultingEngagements.component';
import { ContactModalService } from './Contact/contactModal.service';
import { ContactListPageComponent } from './Contact/Page/contactListPage.component';
import { DashboardComponent } from './Dashboard/dashboard.component';
import { ProcessListComponent } from './Diagnostics/Database/Process-List/processList.component';
import { DiagnosticsComponent } from './Diagnostics/diagnostics.component';
import { ErrorLogListComponent } from './Diagnostics/Error-Log/Error-Log-List/errorLogList.component';
import { QueryInfoListComponent } from './Diagnostics/Query-Info/Query-Info-List/queryInfoList.component';
import {
    DocumentIntakeLicensedClientListComponent
} from './Documents/Document-Intake-Licensed-Clients/Document-Intake-Licensed-Client-List/documentIntakeLicensedClientList.component';
import { DocumentIntakeComponent } from './Documents/Document-Intake/document.intake.component';
import { CompanyPageComponent } from './Entity/Company/companyPage.component';
import { VendorCodeMappingComponent } from './Entity/Company/Vendor-Codes/vendorCodeMapping.component';
import { InstanceInfoPanelComponent } from './Entity/Instance/Info/instanceInfoPanel.component';
import { ManageInvitationsComponent } from './Entity/Instance/manageInvitations.component';
import { MoveCopyEntityComponent } from './Entity/move.copy.entity.component';
import { SiteMapComponent } from './Entity/site.map.component';
import { HelpComponent } from './Landing/Help/help.component';
import { LandingComponent } from './Landing/landing.component';
import { LandingSelectorComponent } from './Landing/landing.selector.component';
import { SavedActionViewComponent } from './Navigation/Saved-AV/savedActionView.component';
import { SavedReportComponent } from './Navigation/Saved-Report/savedReport.component';
import { TopNavService } from './Navigation/Top-Nav/topNav.service';
import { SaveTabContentComponent } from './Navigation/User-Save/Save-Tab-Content/saveTabContent.component';
import { SavedSmartComponent } from './Navigation/Saved-Smart/savedSmart.component';
import { SearchCategoryType, SearchPageType, UserSaveService } from './Navigation/User-Save/userSave.service';
import { PaymentBatchCommandCenterComponent } from './Payment-Batch/Command-Center/paymentBatch.component';
import { PaymentBatchDetailsComponent } from './Payment-Batch/Payment-Batch-Details/paymentBatchDetails.component';
import { ProcessInvoiceComponent } from './Processing/Invoice/invoice.component';
import {
    PaymentPackageAttachmentSummaryComponent
} from './Processing/Transmittal/payment-package-attachment-summary.component';
import { PaymentPackagesComponent } from './Processing/Transmittal/payment-packages.component';
import { PropertyCharacteristicsComponent } from './Property-Characteristics/property.characteristics.component';
import { ReportManagerComponent } from './Reporting/Manager/report.manager.component';
import { RolesListComponent } from './Role/roles.list.component';
import { SmartSearchComponent } from './Search/Advanced/smartSearch.component';
import { UnitedStatesComponent } from './State/states.component';
import { USStateComponent } from './State/us.state.component';
import { SupportInstanceListComponent } from './Support-Instances/Support-Instance-List/supportInstanceList.component';
import {
    ActionViewOutputDefaultsComponent
} from './Task/ActionView/Action-View-Output-Defaults/actionViewOutputDefaults.component';
import { TaxRateCommandCenterComponent } from './Tax-Rate/Command-Center/tax.rate.command.center.component';
import { TaxAuthorityComponent } from './Tax-Rate/Tax-Authority/tax.authority.component';
import { TaxRateAreaComponent } from './Tax-Rate/Tax-Rate-Area/tax.rate.area.component';
import { TeamsPageComponent } from './Team/teams.page.component';
import { UserGroupDetailComponent } from './User-Group/User-Group-Detail/userGroupDetail.component';
import { UserGroupListComponent } from './User-Group/User-Group-List/userGroupList.component';
import { AccountService } from './User/account.service';
import {
    IncomeStatementListComponent
} from './Valuation/Income-Statement/Income-Statement-List/incomeStatementList.component';
import { RentRollListComponent } from './Valuation/Rent-Roll/Rent-Roll-List/rentRollList.component';
import {
    ValuationTemplateDetailComponent
} from './Valuation/Templates/Template-Detail/valuationTemplateDetail.component';
import { ValuationTemplatesComponent } from './Valuation/Templates/valuationTemplates.component';
import {
    ResponsibleEntityListComponent
} from './Responsible-Entity/Responsible-Entity-List/responsibleEntityList.component';
import { SSOLoginComponent } from './Landing/ssoLogin.component';

export function config(uiRouter: UIRouter) {
    // Uncomment to enable the router visualizer for debugging
    // window['visualizer'] && window['visualizer'].dispose();
    // window['visualizer'] = uiRouter.plugin(Visualizer);
    uiRouter.urlService.rules.initial({ state: 'home' });
    uiRouter.urlService.rules.otherwise({ state: 'home' });
}

export const STATES: Ng2StateDeclaration[] = [
    {
        name: 'login',
        url: '/login?logout',
        component: LoginComponent
    },
    {
        name: 'changePassword',
        url: '/change-password',
        component: ChangePasswordComponent
    },
    {
        name: 'resetPasswordUsingTokenFromEmail',
        url: '/login/resetPassword/:tokenHash',
        component: LoginComponent
    },
    {
        name: 'home',
        url: '/home',
        component: LandingSelectorComponent
    },
    {
        name: 'dashboard',
        url: '/dashboard',
        redirectTo: 'home'
    },
    {
        name: 'landing',
        url: '/landing',
        component: LandingComponent
    },
    {
        name: 'ssoLogin',
        url: '/ssoLogin',
        component: SSOLoginComponent
    },
    // {
    //     name: 'unauthorizedAccess',
    //     component: '<p class="text-warning text-center lead" style="margin-top: 50px;">You do not have permission to view this page. If you have any questions, please contact the site administrator.</p>',
    // },
    // {
    //     name: 'logout'
    //     // todo - figure out why onEnter throws AngularJS injection error
    //     // onEnter: (trans: Transition) => {
    //     //     const accountService = trans.injector().get(AccountService);
    //     //     return accountService.logout().then(() => {
    //     //         return false;
    //     //     });
    //     // }
    // },
    {
        name: 'manageAPIKeys',
        url: '/ManageAPIKeys',
        component: ManageAPIKeysComponent
    },
    {
        name: 'apiKeyDetail',
        url: '/ManageAPIKeys/:integrationHmacId',
        component: APIKeyDetailComponent
    },
    {
        name: 'externalAPIKey',
        url: '/externalAPIKey/{requestCode:string}',
        component: ExternalAPIKeyComponent
    },
    {
        name: 'search',
        url: '/search/:searchId?searchType?filters',
        params: {
            searchId: null
        },
        component: SmartSearchComponent
    },
    {
        name: 'savedSmart',
        url: '/saved/smart',
        component: SavedSmartComponent,
        redirectTo: 'savedSmart.system'
    },
    {
        name: 'savedSmart.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Smart);
                    if (page[SearchCategoryType.System].length === 0) {
                        await service.updateList(SearchPageType.Smart);
                    } else {
                        service.updateList(SearchPageType.Smart);
                    }
                    page.currentSelectedFilter = SearchCategoryType.System;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedSmart.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Smart);
                    if (page[SearchCategoryType.User].length === 0) {
                        await service.updateList(SearchPageType.Smart);
                    } else {
                        service.updateList(SearchPageType.Smart);
                    }
                    page.currentSelectedFilter = SearchCategoryType.User;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedSmart.favorite',
        url: '/favorite',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Smart);
                    if (page[SearchCategoryType.Favorite].length === 0) {
                        await service.updateList(SearchPageType.Smart);
                    } else {
                        service.updateList(SearchPageType.Smart);
                    }
                    page.currentSelectedFilter = SearchCategoryType.Favorite;
                    return page;
                }
            }
        ]
    },
    // {
    //     name: 'actionview',
    //     url: '/actionview/:actionViewId?actionViewType&displayType',
    //     params: { actionViewId: null },
    //     controller: ActionViewController,
    //     onExit: ['actionViewService', function (actionViewService) {
    //         actionViewService.cancelAvSearch();
    //     }]
    // },
    {
        name: 'savedActionView',
        url: '/saved/action-view',
        component: SavedActionViewComponent,
        redirectTo: 'savedActionView.system'
    },
    {
        name: 'savedActionView.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.AV);
                    if (page[SearchCategoryType.System].length === 0) {
                        await service.updateList(SearchPageType.AV);
                    } else {
                        service.updateList(SearchPageType.AV);
                    }
                    page.currentSelectedFilter = SearchCategoryType.System;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedActionView.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.AV);
                    if (page[SearchCategoryType.User].length === 0) {
                        await service.updateList(SearchPageType.AV);
                    } else {
                        service.updateList(SearchPageType.AV);
                    }
                    page.currentSelectedFilter = SearchCategoryType.User;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedActionView.favorite',
        url: '/favorite',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.AV);
                    if (page[SearchCategoryType.Favorite].length === 0) {
                        await service.updateList(SearchPageType.AV);
                    } else {
                        service.updateList(SearchPageType.AV);
                    }
                    page.currentSelectedFilter = SearchCategoryType.Favorite;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedReport',
        url: '/saved/report',
        component: SavedReportComponent,
        redirectTo: 'savedReport.system'
    },
    {
        name: 'savedReport.system',
        url: '/system',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Report);
                    if (page[SearchCategoryType.System].length === 0) {
                        await service.updateList(SearchPageType.Report);
                    } else {
                        service.updateList(SearchPageType.Report);
                    }
                    page.currentSelectedFilter = SearchCategoryType.System;
                    return page;
                }
            }
        ]
    },
    {
        name: 'savedReport.user',
        url: '/user',
        component: SaveTabContentComponent,
        resolve: [
            {
                token: 'userPageConfig', deps: [UserSaveService], resolveFn: async (service: UserSaveService) => {
                    const page = service.getSavePage(SearchPageType.Report);
                    if (page[SearchCategoryType.User].length === 0) {
                        await service.updateList(SearchPageType.Report);
                    } else {
                        service.updateList(SearchPageType.Report);
                    }
                    page.currentSelectedFilter = SearchCategoryType.User;
                    return page;
                }
            }
        ]
    },
    {
        name: 'manage',
        url: '/manage',
        component: AdminComponent,
        redirectTo: (trans) => {
            const topNavService = trans.injector().get(TopNavService) as TopNavService;
            if (topNavService.roles().hasContactRoles) {
                return 'manage.contacts';
            }
        }
    },
    {
        name: 'manage.contacts',
        url: '/contacts',
        component: ContactListPageComponent,
        data: {
            instanceRights: [InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]
        }
    },
    {
        name: 'contacts',
        url: '/contacts',
        component: ContactListPageComponent,
        data: {
            instanceRights: [InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]
        }
    },
    // {
    //     name: 'contact',
    //     url: '/contacts/:contactId',
    //     params: {
    //         contactId: null
    //     },
    //     data: {
    //         instanceRights: [InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]
    //     },
    //     // todo
    //     onEnter: (trans: Transition, state: StateDeclaration) => {
    //         console.log('state', state);
    //         // return true;
    //         // if (state.params.contactId) {
    //         //     const contactModalService = trans.injector().get(ContactModalService);
    //         //     console.log(contactModalService);
    //         //     contactModalService.openContactDialog(state.params.contactId, ContactModalOrigin.ContactsList);
    //         // }
    //     }
    // },
    {
        name: 'manage.agents',
        url: '/agents',
        component: AgentsPageComponent
    },
    {
        name: 'agents',
        url: '/agents',
        component: AgentsPageComponent
    },
    {
        name: 'manage.propertyCharacteristics',
        url: '/property-characteristics',
        component: PropertyCharacteristicsComponent,
        data: {
            roles: [Roles.PROPCHARVIEW, Roles.PROPCHAREDIT]
        }
    },
    {
        name: 'propertyCharacteristics',
        url: '/property-characteristics',
        component: PropertyCharacteristicsComponent,
        data: {
            roles: [Roles.PROPCHARVIEW, Roles.PROPCHAREDIT]
        }
    },
    {
        name: 'manage.roles',
        url: '/roles',
        component: RolesListComponent,
        data: {
            roles: [Roles.CONTACTROLEVIEW]
        }
    },
    {
        name: 'roles',
        url: '/roles',
        component: RolesListComponent,
        data: {
            roles: [Roles.CONTACTROLEVIEW]
        }
    },
    {
        name: 'manage.userGroups',
        url: '/user-group',
        component: UserGroupListComponent,
        data: {
            instanceRights: [
                InstanceRights.MANAGEUSERSETUP
            ]
        }
    },
    {
        name: 'userGroupList',
        url: '/userGroup',
        component: UserGroupListComponent,
        data: {
            instanceRights: [InstanceRights.MANAGEUSERSETUP]
        }
    },
    {
        name: 'userGroupDetail',
        url: '/userGroup/:userGroupId',
        component: UserGroupDetailComponent,
        data: {
            instanceRights: [InstanceRights.MANAGEUSERSETUP]
        }
    },
    {
        name: 'manage.manageApiKeys',
        url: '/manage-API-keys',
        component: ManageAPIKeysComponent
    },
    {
        name: 'manage.manageInstanceSettings',
        url: '/instance',
        component: InstanceInfoPanelComponent,
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        }
    },
    {
        name: 'instanceInfo',
        url: '/instance',
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        },
        component: InstanceInfoPanelComponent
    },
    {
        name: 'manage.teams',
        url: '/teams',
        component: TeamsPageComponent
    },
    {
        name: 'teams',
        url: '/teams',
        component: TeamsPageComponent
    },
    {
        name: 'manage.importAssessments',
        url: '/import/assessments',
        component: BulkImportAssessmentsComponent
    },
    {
        name: 'importAssessments',
        url: '/Assessment/Bulk-Import',
        component: BulkImportAssessmentsComponent
    },
    {
        name: 'manage.importSites',
        url: '/import/sites',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Site }
        ]
    },
    {
        name: 'importSites',
        url: '/Site/Bulk-Import',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Site }
        ]
    },
    {
        name: 'manage.importParcels',
        url: '/import/parcels',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Parcel }
        ]
    },
    {
        name: 'importParcels',
        url: '/Parcel/Bulk-Import',
        component: BulkImportSitesComponent,
        resolve: [
            { token: 'type', resolveFn: () => ImportType.Parcel }
        ]
    },
    {
        name: 'manage.factorTables',
        url: '/factor-tables',
        component: FactorTableListComponent,
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'factorTableList',
        url: '/factortable',
        component: FactorTableListComponent,
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]

        }
    },
    {
        name: 'manage.forms',
        url: '/forms',
        component: FormListComponent,
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'formList',
        url: '/form',
        component: FormListComponent,
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'manage.valuationTemplates',
        url: '/valuation-templates',
        component: ValuationTemplatesComponent,
        data: {
            instanceRights: [
                InstanceRights.VALUATIONTEMPLATEEDIT
            ]
        }
    },
    {
        name: 'valuationTemplates',
        url: '/valuationTemplate',
        data: {
            instanceRights: [
                InstanceRights.VALUATIONTEMPLATEEDIT
            ]
        },
        component: ValuationTemplatesComponent
    },
    {
        name: 'manage.paymentBatchCommandCenter',
        url: '/payment-batch-command-center',
        component: PaymentBatchCommandCenterComponent,
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'manage.appealRecommendationCommandCenter',
        url: '/appeal-recommendation-command-center',
        component: ARCCComponent
    },
    {
        name: 'appealRecommendationCommandCenter',
        url: '/appealRecommendationCommandCenter',
        data: {},
        component: ARCCComponent
    },
    {
        name: 'manage.taxRateCommandCenter',
        url: '/tax-rate-command-center',
        component: TaxRateCommandCenterComponent,
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'taxRateCommandCenter',
        url: '/tax-rate-command-center',
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        },
        component: TaxRateCommandCenterComponent
    },
    {
        name: 'manage.stateJurisdictionCommandCenter',
        url: '/state-jurisdiction-command-center',
        component: StateJurisdictionCommandCenterComponent,
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        }
    },
    {
        name: 'stateJurisdictionCommandCenter',
        url: '/state-jurisdiction-command-center',
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        },
        component: StateJurisdictionCommandCenterComponent
    },
    {
        name: 'manage.dataImports',
        url: '/import',
        component: EntityImportListComponent
    },
    {
        name: 'systemImports',
        url: '/import',
        component: EntityImportListComponent
    },
    {
        name: 'companyImports',
        url: '/import/company/:companyId',
        component: EntityImportListComponent
    },
    {
        name: 'manage.manageInstances',
        url: '/manage-instances',
        component: ManageInvitationsComponent
    },
    {
        name: 'invites',
        url: '/invites',
        component: ManageInvitationsComponent
    },
    {
        name: 'manage.manageSupportInstances',
        url: '/manage-support-instances',
        component: SupportInstanceListComponent
    },
    {
        name: 'supportInstanceList',
        url: '/supportInstanceList',
        component: SupportInstanceListComponent
    },
    {
        name: 'manage.manageConsultingEngagements',
        url: '/manage-consulting-engagements',
        component: ConsultingEngagementsComponent,
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        }
    },
    {
        name: 'consulting',
        url: '/consulting',
        data: {
            instanceRights: [
                InstanceRights.MANAGECONSULTINGENGAGEMENT
            ]
        },
        component: ConsultingEngagementsComponent
    },
    {
        name: 'manage.manageLicensedClientDocuments',
        url: '/manage-licensed-client-documents',
        component: DocumentIntakeLicensedClientListComponent
    },
    {
        name: 'documentIntakeLicensedClientList',
        url: '/documentIntakeLicensedClientList',
        component: DocumentIntakeLicensedClientListComponent
    },
    {
        name: 'manage.actionViewOutputFields',
        url: '/action-view-output-fields',
        component: ActionViewOutputDefaultsComponent
    },
    {
        name: 'taskviewoutputdefaults',
        url: '/action-view-output-fields',
        component: ActionViewOutputDefaultsComponent
    },
    {
        name: 'manage.about',
        url: '/about',
        component: AboutComponent
    },
    {
        name: 'about',
        url: '/about',
        component: AboutComponent
    },
    {
        name: 'diagnostic',
        url: '/diagnostic',
        component: DiagnosticsComponent
    },
    {
        name: 'diagnostic.longRunningProcess',
        url: '/long-running-process',
        component: LongRunningProcessPageComponent
    },
    {
        name: 'longRunningProcess',
        url: '/long-running-process',
        component: LongRunningProcessPageComponent,
        data: {
            roles: [
                Roles.ADMINOPERATIONSVIEW,
                Roles.ADMINOPERATIONSEDIT
            ]
        }
    },
    {
        name: 'diagnostic.errorLog',
        url: '/error-log',
        component: ErrorLogListComponent
    },
    {
        name: 'errorLog',
        url: '/error-log',
        component: ErrorLogListComponent,
        data: {
            roles: [
                Roles.ADMINOPERATIONSVIEW,
                Roles.ADMINOPERATIONSEDIT
            ]
        }
    },
    {
        name: 'diagnostic.queryInfo',
        url: '/query-info',
        component: QueryInfoListComponent,
        data: {
            roles: [
                Roles.ADMINOPERATIONSVIEW,
                Roles.ADMINOPERATIONSEDIT
            ]
        }
    },
    {
        name: 'queryInfo',
        url: '/queryInfo',
        component: QueryInfoListComponent,
        data: {
            roles: [
                Roles.ADMINOPERATIONSVIEW,
                Roles.ADMINOPERATIONSEDIT
            ]
        }
    },
    {
        name: 'diagnostic.database',
        url: '/database',
        component: ProcessListComponent
    },
    {
        name: 'database',
        url: '/database',
        component: ProcessListComponent
    },
    {
        name: 'company',
        url: '/company/:companyId',
        component: CompanyPageComponent
    },
    // {
    //     name: 'company.site',
    //     url: '/site/:siteId',
    //     component: SitePageComponent,
    //     resolve: []
    // },
    // {
    //     name: 'company.site.parcel',
    //     url: '/parcel/:parcelId',
    //     component: ParcelPageComponent,
    //     resolve: []
    // },
    // {
    //     name: 'siteRedirect',
    //     url: '/site/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.SITE
    //     }
    // },
    // {
    //     name: 'parcelRedirect',
    //     url: '/parcel/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.PARCEL
    //     }
    // },
    // {
    //     name: 'assessmentRedirect',
    //     url: '/assessment/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.ASSESSMENT
    //     }
    // },
    // {
    //     name: 'appealRedirect',
    //     url: '/appeal/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.APPEAL
    //     }
    // },
    // {
    //     name: 'billClusterRedirect',
    //     url: '/billcluster/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.BILL_CLUSTER
    //     }
    // },
    // {
    //     name: 'billRedirect',
    //     url: '/bill/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.TAX_BILL
    //     }
    // },
    // {
    //     name: 'filingRedirect',
    //     url: '/filing/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.FILING
    //     }
    // },
    // {
    //     name: 'paymentRedirect',
    //     url: '/payment/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.PAYMENT
    //     }
    // },
    // {
    //     name: 'refundRedirect',
    //     url: '/refund/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.REFUND
    //     }
    // },
    // {
    //     name: 'collectorRedirect',
    //     url: '/collector/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.COLLECTOR
    //     }
    // },
    // {
    //     name: 'assessorRedirect',
    //     url: '/assessor/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.ASSESSOR
    //     }
    // },
    // {
    //     name: 'invoiceRedirect',
    //     url: '/invoice/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.INVOICE
    //     }
    // },
    // {
    //     name: 'filingBatchRedirect',
    //     url: '/filingbatch/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.FilingBatch
    //     }
    // },
    // {
    //     name: 'filingBatchRedirect2',
    //     url: '/filingBatch/:entityID',
    //     component: RedirectComponent,
    //     resolve: {
    //         entityTypeID: () => EntityTypeIDs.FilingBatch
    //     }
    // },
    {
        name: 'states',
        url: '/states',
        component: UnitedStatesComponent
    },
    {
        name: 'state',
        url: '/states/:stateId',
        component: USStateComponent
    },
    {
        name: 'assessors',
        url: '/states/:stateId/assessors',
        component: AssessorListComponent
    },
    {
        name: 'assessors.letter',
        url: '?:letter',
        component: AssessorListComponent
    },
    {
        name: 'assessor',
        url: '/states/:stateId/assessors/:id?:year',
        component: AssessorComponent
    },
    {
        name: 'collectors',
        url: '/states/:stateId/collectors',
        component: CollectorListComponent
    },
    {
        name: 'collectors.letter',
        url: '?:letter',
        component: CollectorListComponent
    },
    {
        name: 'companyCollectors',
        url: '/company/:companyID/collectors',
        component: CollectorListComponent
    },
    {
        name: 'companyCollectors.letter',
        url: '?:letter',
        component: CollectorListComponent
    },
    {
        name: 'companyCollector',
        url: '/company/:companyID/collector/:collectorID',
        component: CollectorComponent
    },
    {
        name: 'collector',
        url: '/states/:stateId/collectors/:stateCollectorId',
        component: CollectorComponent
    },
    {
        name: 'documentintake',
        url: '/documentintake',
        component: DocumentIntakeComponent,
        data: {
            instanceRights: [InstanceRights.PRIVATEITEMSEDIT]
        }
    },
    // {
    //     name: 'documentProcessing',
    //     url: '/processing/document?taskID?parcelID?intakeItemID',
    //     component: DocumentProcessingComponent,
    //     params: {
    //         selectedRows: [],
    //         searchTimestamp: null
    //     }
    // },
    // {
    //     name: 'attachmentPopup',
    //     url: '/attachmentPopup',
    //     component: AttachmentPopupComponent
    // },
    {
        name: 'reportmanager',
        url: '/reportmanager/:reportId?reportType',
        component: ReportManagerComponent
    },
    {
        name: 'paymentPackagesDraft',
        url: '/processing/paymentPackages/:draftID?paymentBatchId?taskId?isPrelimApFeed',
        component: PaymentPackagesComponent
    },
    {
        name: 'paymentPackageAttachmentSummary',
        url: '/processing/paymentPackages/attachmentSummary/:paymentPackageID',
        component: PaymentPackageAttachmentSummaryComponent
    },
    {
        name: 'paymentPackagesForCompany',
        url: '/processing/paymentPackages/company/:companyID',
        component: PaymentPackagesComponent
    },
    {
        name: 'paymentPackagesForPayment',
        url: '/processing/paymentPackages/payment/:paymentID',
        component: PaymentPackagesComponent
    },
    {
        name: 'paymentPackageDetail',
        url: '/processing/paymentPackages/company/:companyID/package/:paymentPackageID?paymentBatchId',
        component: PaymentPackagesComponent
    },
    {
        name: 'paymentPackageBatchDetail',
        url: '/processing/paymentPackages/company/:companyID/package/:paymentPackageID/paymentBatch/:paymentBatchId',
        component: PaymentPackagesComponent
    },
    {
        name: 'processInvoice',
        url: '/processing/invoice/:draftId',
        component: ProcessInvoiceComponent
    },
    {
        name: 'siteInvoice',
        url: '/company/:companyID/site/:siteID/invoice/:invoiceID',
        component: ProcessInvoiceComponent
    },
    {
        name: 'companyInvoice',
        url: '/company/:companyID/invoice/:invoiceID',
        component: ProcessInvoiceComponent
    },
    {
        name: 'companyVendorCodes',
        url: '/company/:companyId/vendor-codes',
        component: VendorCodeMappingComponent
    },
    {
        name: 'moveCompany',
        url: '/company/:companyID/move?isInactive',
        component: MoveCopyEntityComponent
    },
    {
        name: 'moveSite',
        url: '/company/:companyID/site/:siteID/move?isInactive',
        component: MoveCopyEntityComponent
    },
    {
        name: 'moveParcel',
        url: '/company/:companyID/site/:siteID/parcel/:parcelID/move?isInactive',
        component: MoveCopyEntityComponent
    },
    {
        name: 'copyCompany',
        url: '/company/:companyID/copy?isInactive',
        component: MoveCopyEntityComponent,
        resolve: [
            { token: 'isCopy', resolveFn: () => true }
        ]
    },
    {
        name: 'copySite',
        url: '/company/:companyID/site/:siteID/copy?isInactive',
        component: MoveCopyEntityComponent,
        resolve: [
            { token: 'isCopy', resolveFn: () => true }
        ]
    },
    {
        name: 'copyParcel',
        url: '/company/:companyID/site/:siteID/parcel/:parcelID/copy?isInactive',
        component: MoveCopyEntityComponent,
        resolve: [
            { token: 'isCopy', resolveFn: () => true }
        ]
    },
    {
        name: 'help',
        url: '/help',
        component: HelpComponent
    },
    {
        name: 'myDashboard',
        url: '/my-dashboard',
        component: DashboardComponent
    },
    {
        name: 'appealApplication',
        url: '/appeal/:appealId/appealApplication',
        component: AppealApplicationComponent
    },
    {
        name: 'appealApplicationBatch',
        url: '/appealApplicationBatch/:appealBatchId',
        component: AppealApplicationComponent
    },
    {
        name: 'processCompanyImport',
        url: '/import/company/:companyId/:importId',
        component: EntityImportProcessComponent
    },
    {
        name: 'processSystemImport',
        url: '/import/:importId',
        component: EntityImportProcessComponent
    },
    {
        name: 'assetList',
        url: '/asset/company/:companyId',
        component: AssetListComponent
    },
    {
        name: 'assetListSiteFilter',
        url: '/asset/company/:companyId/site/:siteId',
        component: AssetListComponent
    },
    {
        name: 'assetListParcelFilter',
        url: '/asset/company/:companyId/parcel/:parcelId',
        component: AssetListComponent
    },
    {
        name: 'formPage',
        url: '/form/:formRevisionId?taxYear',
        component: FormPageComponent,
        data: {
            roles: [
                Roles.COMPLIANCESETUPSVIEW,
                Roles.COMPLIANCESETUPSEDIT
            ]
        }
    },
    {
        name: 'glAccountList',
        url: '/glaccount/company/:companyId',
        component: GLAccountListComponent
    },
    {
        name: 'filingBatchList',
        url: '/filingbatch/company/:companyId',
        component: FilingBatchListComponent
    },
    {
        name: 'filingBatchDetails',
        url: '/filingbatch/company/:companyId/batch/:filingBatchId?',
        component: FilingBatchDetailsComponent
    },
    {
        name: 'returnFilingBatchPage',
        url: '/returnBatch/:filingBatchId',
        component: ReturnPageComponent
    },
    {
        name: 'supplementalInformationSheet',
        url: '/supplemental/company/:companyId',
        component: SupplementalInformationSheetComponent
    },
    {
        name: 'testdxp',
        url: '/testdxp',
        component: TestDXPPageComponent,
        data: {
            roles: [Roles.ADMINOPERATIONSEDIT]
        }
    },
    {
        name: 'taxRateArea',
        url: '/tax-rate-area/:taxRateAreaId?editMode',
        component: TaxRateAreaComponent,
        data: {
            roles: [Roles.ADMINOPERATIONSEDIT]
        }
    },
    {
        name: 'taxAuthority',
        url: '/tax-authority/:taxAuthorityId?editMode',
        component: TaxAuthorityComponent,
        data: {
            roles: [Roles.ADMINOPERATIONSEDIT]
        }
    },
    {
        name: 'importSpecificationList',
        url: '/importSpecifications',
        component: ImportSpecificationListComponent,
        data: {
            instanceRights: [
                InstanceRights.COMPANYDATAIMPORTSVIEW
            ]
        }
    },
    {
        name: 'companyImportSpecificationList',
        url: '/importSpecifications/company/:companyId',
        component: ImportSpecificationListComponent,
        data: {
            instanceRights: [
                InstanceRights.COMPANYDATAIMPORTSVIEW
            ]
        }
    },
    {
        name: 'paymentBatchCommandCenter',
        url: '/payment-batch-command-center',
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        },
        component: PaymentBatchCommandCenterComponent

    },
    {
        name: 'paymentBatchDetails',
        url: '/payment-batch-command-center/batch/:paymentBatchId',
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        },
        component: PaymentBatchDetailsComponent

    },
    {
        name: 'paymentBatchDetailsWithTabIndex',
        url: '/payment-batch-command-center/batch/:paymentBatchId/:tabIndex',
        data: {
            roles: [Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]
        },
        component: PaymentBatchDetailsComponent

    },
    {
        name: 'forecastingBudgeting',
        url: '/company/:companyId/forecasting-budgeting',
        data: {
            instanceRights: [
                InstanceRights.PRIVATEITEMSEDIT,
                InstanceRights.PRIVATEITEMSVIEW
            ]
        },
        component: ForecastingBudgetingPageComponent
    },
    {
        name: 'accruals',
        url: '/company/:companyId/accruals',
        data: {
            instanceRights: [
                InstanceRights.PRIVATEITEMSEDIT,
                InstanceRights.PRIVATEITEMSVIEW
            ]
        },
        component: AccrualsPageComponent
    },
    {
        name: 'siteMap',
        url: '/site-map?siteId?companyId',
        component: SiteMapComponent
    },
    {
        name: 'allocationDetails',
        url: '/allocationDetails/:allocationId',
        component: AllocationDetailsComponent
    },
    {
        name: 'processAllocationImport',
        url: '/import/allocation/:allocationId/:importId',
        component: EntityImportProcessComponent
    },
    {
        name: 'companyIncomeStatement',
        url: '/incomeStatement/company/:companyId',
        component: IncomeStatementListComponent
    },
    {
        name: 'siteIncomeStatement',
        url: '/incomeStatement/company/:companyId/site/:siteId',
        component: IncomeStatementListComponent
    },
    {
        name: 'csrCommandCenter',
        url: '/csrcommandcenter/entity/:entityId/type/:entityTypeId',
        component: CsrCommandCenterComponent
    },
    {
        name: 'companyRentRoll',
        url: '/rentRoll/company/:companyId',
        component: RentRollListComponent
    },
    {
        name: 'siteRentRoll',
        url: '/rentRoll/company/:companyId/site/:siteId',
        component: RentRollListComponent
    },
    {
        name: 'valuationTemplateDetail',
        url: '/valuationTemplate/:templateId',
        data: {
            instanceRights: [
                InstanceRights.VALUATIONTEMPLATEEDIT
            ]
        },
        component: ValuationTemplateDetailComponent
    },
    {
        name: 'electronicFiling',
        url: '/company/:companyId/efile',
        component: ElectronicFilingComponent
    },
    {
        name: 'loginManager',
        url: '/loginManager/:userId',
        component: LoginManagerComponent
    },
    {
        name: 'responsibleEntityList',
        url: '/responsibleEntity/company/:companyId',
        component: ResponsibleEntityListComponent
    }
];

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: STATES, config: config })
    ]
})
export class Ng2RoutesModule {
}
