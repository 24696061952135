import { Component, OnDestroy, OnInit } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { GLAccountListAgGridDataSource } from './agGridDataSource';
import {
    DisplayMode,
    GLAccountDetailsComponent,
    GLAccountDetailsParams
} from '../GL-Account-Details/glAccountDetails.component';
import { GLAccountRepository } from '../../Repositories';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../AgGrid/MultiSelectTracker';
import { BehaviorSubject, from, of, Subscription } from 'rxjs';
import {
    GLAccountBulkUpdateComponent,
    GLAccountBulkUpdateParams
} from './GL-Account-Bulk-Update/glAccountBulkUpdate.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../AgGrid/ToolPanel/models';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { GLAccountService } from '../glAccount.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { GL_ACCOUNT_LIST_HELP } from './glAccountList.component.help';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { BulkEditButtonOptions } from '../../AgGrid/ToolPanel/agGridToolPanel.component';
import {
    GLAccountBulkUpdateIncomeStatementTypesComponent,
    GLAccountBulkUpdateIncomeStatementTypesParams
} from './GL-Account-Bulk-Update/glAccountBulkUpdateIncomeStatementTypes.component';
import {
    AgGridActionCellRendererComponent,
    AgGridActionCellRendererParams
} from '../../AgGrid/CellRenderers/agGridActionCellRenderer.component';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { WeissmanDateFormat } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';


@Component({
    selector: 'gl-account-list',
    templateUrl: './glAccountList.component.html',
    styleUrls: ['./glAccountList.component.scss']
})
export class GLAccountListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: WeissmanModalService,
        private readonly _glAccountRepository: GLAccountRepository,
        private readonly _glAccountService: GLAccountService,
        private readonly _helpService: HelpService
    ) {
    }

    private _gridApi: GridApi;
    private _gridDataSource: GLAccountListAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private selectionSummary: Compliance.GLAccountBulkSelectionSummaryResponse;

    companyId: number;
    breadcrumbs: BreadCrumb[] = [];
    isInitialized: boolean = false;
    canEdit: boolean = false;
    gridId: System.Guid = 'EB1D7E96-03FD-42C8-B5FB-0273E7BAC586';
    isBulkUpdateVisible$: BehaviorSubject<boolean | BulkEditButtonOptions> = new BehaviorSubject(false);

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.GLAccountModel).glAccountId)
        }
    })
        .withColumnPinning()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchModelWithoutPagination();

            searchParams.pagination = {
                skip: 0,
                take: this._gridDataSource.totalRows
            };

            const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
            if (!selectedRowsModel.selectAllRows && selectedRowsModel.selectedRows.length === 0) {
                selectedRowsModel.selectAllRows = true;
            }

            const exportModel: Compliance.GLAccountExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                selectedRows: selectedRowsModel
            };

            const longRunningProcessId = await this._glAccountService.startExport(this.companyId, exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportGLAccounts };
        },
        canCancel: true
    };

    gridTracker: AgGridMultiSelectTracker;

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(GL_ACCOUNT_LIST_HELP);

        this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

        const busyRef = this._busyIndicatorService.show({message: 'Loading'});

        try {
            await this._glAccountService.start(this.companyId);

            if (!this._glAccountService.canReadCompany) {
                this._routerService.go('unauthorizedAccess', {});
                return Promise.resolve();
            }

            this.canEdit = this._glAccountService.canWriteCompany;

            this.breadcrumbs.push({
                name: this._glAccountService.companyName,
                target: 'company',
                options: {companyId: this._glAccountService.companyId}
            });

            this.isInitialized = true;
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    ngOnDestroy() {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._glAccountService.stop();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$
            .pipe(
                // does this need to be debounced?
                tap(() => this.isBulkUpdateVisible$.next(BulkEditButtonOptions.Create({
                    showLoading: true,
                    show: true
                }))),
                switchMap((rows) => this.getSelectionSummary()
                    .pipe(catchError(() => {
                        return of(null);
                    }))
                ),
                tap((selectionSummary: Compliance.GLAccountBulkSelectionSummaryResponse) => this.selectionSummary = selectionSummary)
            )
            .subscribe(
                (selectionSummary) => {
                    const isBulkUpdateVisible = this.canBulkUpdate(selectionSummary);
                    this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
                });

        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'glAccountId',
                width: AgGridColumns.selectionColumnWidth,
                lockVisible: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                resizable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {tracker: this.gridTracker} as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {tracker: this.gridTracker} as AgGridMultiSelectRendererParams,
            },
            {
                headerName: 'G/L Account Number',
                field: 'accountNumber',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'G/L Account Name',
                field: 'accountName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'I/S Category',
                field: 'incomeStatementCategoryName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Asset Class',
                field: 'assetClassification',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Description',
                field: 'description',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Account Type',
                field: 'accountTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Found in Assets',
                field: 'foundInAssets',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                hide: true,
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    } else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                }
            },
            {
                headerName: 'Found in Accruals',
                field: 'foundInAccruals',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                hide: true,
                suppressColumnsToolPanel: false,
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    } else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                }
            },
            {
                headerName: 'Found in I/S',
                field: 'foundInIncomeStatements',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                suppressColumnsToolPanel: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                valueFormatter: (params) => {
                    if (params.value === true) {
                        return 'Yes';
                    } else if (params.value === false) {
                        return 'No';
                    } else {
                        return null;
                    }
                }
            },
            {
                headerName: 'Created Date',
                field: 'createDate',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                suppressColumnsToolPanel: false,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => WeissmanDateFormat(params.value, false)
            },
            {
                headerName: 'Validated By',
                field: 'validatedBy',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                suppressColumnsToolPanel: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Validated Date',
                field: 'validatedDate',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                suppressColumnsToolPanel: false,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => WeissmanDateFormat(params.value, true)
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: AgGridActionCellRendererComponent,
                cellRendererParams: {
                    buttonConfigs: [
                        {
                            iconClass: 'fa-pencil',
                            onClick: this._edit.bind(this),
                            isShown: () => true,
                            helpContentId: 'app.edit'
                        },
                        {
                            iconClass: 'fa-trash',
                            onClick: this._delete.bind(this),
                            isShown: this._canDelete.bind(this),
                            buttonClass: 'warning-button',
                            helpContentId: 'app.delete'
                        }
                    ]
                } as AgGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'accountNumber',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    private canBulkUpdate(selectionSummary: Compliance.GLAccountBulkSelectionSummaryResponse) {
        if (!this.canEdit) {
            return false;
        }

        if (!selectionSummary) {
            return false;
        }

        if (selectionSummary.foundInAccruals > 0) {
            return BulkEditButtonOptions.Create({
                showLoading: false,
                show: true,
                disable: true,
                toolTipId: 'ag-grid-tool-panel.bulk-delete-found-in-accruals'
            });
        }

        if (selectionSummary.applicableToAccruals > 0) {
            return BulkEditButtonOptions.Create({
                showLoading: false,
                show: true,
                disable: true,
                toolTipId: 'ag-grid-tool-panel.bulk-delete-applicable-to-accruals'
            });
        }

        if (this._isAllSelectedAccountsFoundInISAndValidated(selectionSummary)) {
            return BulkEditButtonOptions.Create({
                showLoading: false,
                show: true,
                disable: true,
                toolTipId: 'ag-grid-tool-panel.bulk-delete-found-in-income-statements'
            });
        }

        if (selectionSummary.total < 1) {
            return BulkEditButtonOptions.Create({
                showLoading: false,
                show: true,
                disable: true,
                toolTipId: 'ag-grid-tool-panel.bulk-delete-nothing-selected'
            });
        }

        const incomeStatementTypes = selectionSummary.revenue + selectionSummary.reporting + selectionSummary.expense > 0;
        const otherTypes = selectionSummary.asset + selectionSummary.equity + selectionSummary.liability > 0;
        if (incomeStatementTypes && otherTypes) {
            return BulkEditButtonOptions.Create({
                showLoading: false,
                show: true,
                disable: true,
                toolTipId: 'ag-grid-tool-panel.bulk-delete-mixed-types'
            });
        }

        return true;
    }

    private showIncomeStatementTypesBulkUpdate() : boolean
    {
        if (!this.selectionSummary) return false;
        if (this.selectionSummary.foundInAccruals > 0) return false;
        if (this.selectionSummary.applicableToAccruals > 0) return false;
        if (this.selectionSummary.total < 1) return false;
        const incomeStatementTypes = this.selectionSummary.revenue + this.selectionSummary.reporting + this.selectionSummary.expense > 0;
        if (!incomeStatementTypes) return false;
        const otherTypes = this.selectionSummary.asset + this.selectionSummary.equity + this.selectionSummary.liability > 0;
        if (incomeStatementTypes && otherTypes) return false;
        return true;
    }

    private showBulkUpdate() : boolean
    {
        if (!this.selectionSummary) return false;
        if (this.selectionSummary.foundInAccruals > 0) return false;
        if (this.selectionSummary.applicableToAccruals > 0) return false;
        if (this.selectionSummary.foundInIncomeStatements.length > 0) return false;
        if (this.selectionSummary.total < 1) return false;
        const incomeStatementTypes = this.selectionSummary.revenue + this.selectionSummary.reporting + this.selectionSummary.expense > 0;
        if (incomeStatementTypes) return false;
        const otherTypes = this.selectionSummary.asset + this.selectionSummary.equity + this.selectionSummary.liability > 0;
        if (incomeStatementTypes && otherTypes) return false;
        return true;
    }

    private getSelectionSummary() {
        if (!this.gridTracker.hasSelectedRows()) {
            return of(null as Compliance.GLAccountBulkSelectionSummaryResponse);
        }
        const searchModel = this._gridDataSource.getSearchModelWithoutPagination();

        searchModel.pagination = {
            skip: 0,
            take: this._gridDataSource.totalRows
        };

        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
        if (!selectedRowsModel.selectAllRows && selectedRowsModel.selectedRows.length === 0) {
            selectedRowsModel.selectAllRows = true;
        }
        return from(this._glAccountService.selectionSummary(this.companyId, searchModel, selectedRowsModel));
    }

    async bulkUpdate(): Promise<void> {
        if (this.showBulkUpdate()) {
            const params: GLAccountBulkUpdateParams = {
                companyId: this.companyId,
                selection: this.gridTracker.getSelectedRowsModel(),
                selectedCount: this.gridTracker.getSelectedRowsCount(),
                glAccountListLastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
            };
            const result = await this._modalService.showAsync(GLAccountBulkUpdateComponent, params, 'modal-lg');
            if (!result) {
                return Promise.resolve();
            }
        }
        if (this.showIncomeStatementTypesBulkUpdate()) {
            const params: GLAccountBulkUpdateIncomeStatementTypesParams = {
                companyId: this.companyId,
                selection: this.gridTracker.getSelectedRowsModel(),
                selectedCount: this.gridTracker.getSelectedRowsCount(),
                glAccountListLastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
                selectionSummary: this.selectionSummary
            };
            const result = await this._modalService.showAsync(GLAccountBulkUpdateIncomeStatementTypesComponent, params, 'modal-lg');
            if (!result) {
                return Promise.resolve();
            }
        }

        this._refreshDataSource();

        return Promise.resolve();
    }

    async add(): Promise<void> {
        const params: GLAccountDetailsParams = {
            glAccountId: null,
            displayMode: DisplayMode.Add,
            companyId: this.companyId,
            foundInAssets: false,
            foundInIncomeStatements: false,
            foundInAccruals: false
        };

        const result = await this._modalService.showAsync(GLAccountDetailsComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        await this._refreshDataSource();

        return Promise.resolve();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    private async _edit(cellParams: AgGridActionCellRendererParams): Promise<void> {
        const data = cellParams.data as Compliance.GLAccountModel;

        if (data.foundInIncomeStatements) {
            try {
                await this._messageModalService.confirm('Any changes made will flow through to all income statements that contain this account. Would you like to continue?');
            } catch(err) {
                return;
            }
        }

        const params: GLAccountDetailsParams = {
            glAccountId: data.glAccountId,
            displayMode: this.canEdit ? DisplayMode.Edit : DisplayMode.View,
            companyId: this.companyId,
            foundInAssets: data.foundInAssets,
            foundInIncomeStatements: data.foundInIncomeStatements,
            foundInAccruals: data.foundInAccruals
        };

        const result = await this._modalService.showAsync(GLAccountDetailsComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        await this._gridDataSource.updateRow(y => {
                const rowModel = y.data as Compliance.GLAccountModel;
                return rowModel && rowModel.glAccountId === result.glAccountId;
            },
            () => {
                return Promise.resolve(result);
            });

        return Promise.resolve();
    }

    private async _delete(params: AgGridActionCellRendererParams): Promise<void> {
        const glAccount = params.data as Compliance.GLAccountModel;
        if (!glAccount) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to delete the G/L Account?',
                'Confirm Delete');
        } catch (e) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({message: 'Deleting G/L Account'});

        try {
            await this._glAccountService.delete(glAccount.glAccountId);
            this._refreshDataSource();
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    private _canDelete(params: AgGridActionCellRendererParams): boolean {
        if (!(params && this.canEdit)) {
            return false;
        }
        const glAccount = params.data as Compliance.GLAccountModel;
        if (!glAccount) {
            return false;
        }
        return !(glAccount.foundInAssets || glAccount.foundInAccruals || glAccount.foundInIncomeStatements);
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear();

        this._gridDataSource = new GLAccountListAgGridDataSource(
            this._gridApi,
            this._glAccountRepository,
            this.companyId);

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private _isAllSelectedAccountsFoundInISAndValidated(selectionSummary: Compliance.GLAccountBulkSelectionSummaryResponse): boolean{
        return selectionSummary.foundInIncomeStatements.length !== 0 &&
            selectionSummary.foundInIncomeStatements.length === selectionSummary.validated.length &&
            selectionSummary.foundInIncomeStatements.every(x => selectionSummary.validated.indexOf(x) !== -1);
    }
}
