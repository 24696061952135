import { Component, OnInit, OnDestroy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ColumnApi } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LinkedParcelsListGridActionCellRendererComponent, ICellRendererParamsForLinkedParcelsListGridAction } from './agGridActionCellRenderer.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../../../Compliance/AgGrid';
import { WeissmanModalService, IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { LinkedParcelAddParams, LinkedParcelsAddComponent } from '../Linked-Parcels-Add/linkedParcelsAdd.component';
import { ParcelTypeCellRendererComponent, ParcelTypeCellRendererComponentParams } from '../../Parcel-Type-Cell-Renderer/parcelTypeCellRenderer.component';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { LinkedParcelRepository } from '../linkedParcel.repository';
import { LinkedParcelsListGridNameCellRendererComponent, LinkedParcelsListGridNameCellRendererParams } from './agGridNameCellRenderer.component';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker, AgGridMultiSelectCellRendererParams } from '../../../../Compliance/AgGrid/MultiSelectTracker';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { TimerService } from '../../../../UI-Lib/Utilities/timer.service';

interface LinkedParcelRowModel extends Core.LinkedParcelDTO {
    masterParcelId?: number;
}

interface LinkedParcelRowModelSelectable extends Core.LinkedParcelDTO {
    isSelected: boolean;
}

export interface LinkedParcelsListParams {
    parcelId: number;
    parcelAcctNum: string;
    editMode: boolean;
    masterParcelAcctNum: string;
    masterParcelId: number;
    linkageTypes: Core.LinkageTypeModel[];
    linkedParcelCount: number;
}

@Component({
    selector: 'linked-parcels-list',
    templateUrl: './linkedParcelsList.component.html',
    styleUrls: ['./linkedParcelsList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LinkedParcelsListComponent implements OnInit, OnDestroy, IWeissmanModalComponent<LinkedParcelsListParams, Core.LinkedParcelInfoDTO> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _linkedParcelRepository: LinkedParcelRepository,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _timer: TimerService) { }

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;
    private _allParcels: Core.LinkedParcelDTO[];
    private _gridTracker: AgGridMultiSelectTracker;
    private _selectedRowsSub: Subscription;

    @ViewChild('focus') focusElement: ElementRef;

    params: LinkedParcelsListParams;
    result: Core.LinkedParcelInfoDTO;

    parcels: LinkedParcelRowModel[];
    masterParcel: LinkedParcelRowModel;
    selectedLinkageType: Core.LinkageTypeModel;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    isBulkDeleteAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

    companyId: number;
    agGridReady: boolean;
    gridId: System.Guid = '6C6E2165-6879-4A3E-B977-50EB5EA014D6';
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this._gridTracker.isRowSelected((params.data as LinkedParcelRowModel).parcelId),
                'grid-row-master': params => {
                    const parcel = params.data as LinkedParcelRowModel;
                    return parcel && parcel.masterParcel;
                }
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    get editMode(): boolean {
        return this.params && this.params.editMode;
    }

    get masterParcelAcctNum(): string {
        return (this.masterParcel && this.masterParcel.acctNum) || this.params.parcelAcctNum;
    }

    async ngOnInit(): Promise<void> {
        const busyMsg = 'loading';

        this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

        this.busyIndicatorMessageManager.add('Loading', busyMsg);

        try {
            const linkedParcels = await lastValueFrom(this._linkedParcelRepository.getList(this.params.parcelId, false));

            this.parcels = linkedParcels
                .filter(x => x.parcelId === this.params.parcelId || x.linked)
                .map((x) => ({...x} as LinkedParcelRowModel));

            this.masterParcel = this.parcels.find(x => x.masterParcel);

            this.selectedLinkageType = this.params.linkageTypes.find(x => x.linkageTypeId == (this.masterParcel && this.masterParcel.linkageTypeId)) || this.params.linkageTypes.length && this.params.linkageTypes[0];

            this.parcels.forEach((x) => {
                x.masterParcelId = this.masterParcel && this.masterParcel.parcelId;
            });

            this._setRowData();
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    ngOnDestroy() {
        this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        if (this._selectedRowsSub) {
            this._selectedRowsSub.unsubscribe();
        }
        this._selectedRowsSub = this._gridTracker.selectedRows$.subscribe(x => {
            this.isBulkDeleteAvailable.next(x.selectAllRows || x.selectedRows.length > 0);
        });

        const columns: ColDef[] = [
            {
                field: 'parcelId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this._gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: LinkedParcelRowModel) => data.masterParcel
                } as AgGridMultiSelectCellRendererParams,
                hide: !this.editMode
            },
            {
                headerName: 'Type',
                field: 'propertyTypeAbbr',
                width: AgGridColumns.textColumnExtraSmallWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: ParcelTypeCellRendererComponent,
                cellRendererParams: {
                    getParcelType: this._getParcelType.bind(this),
                    isLinkedParcel: this._isLinkedParcel.bind(this),
                    isMasterParcel: this._isMasterParcel.bind(this)
                } as ParcelTypeCellRendererComponentParams
            },
            {
                headerName: 'Parcel',
                field: 'acctNum',
                width: AgGridColumns.textColumnLargeWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: LinkedParcelsListGridNameCellRendererComponent,
                cellRendererParams: {
                    getName: (params) => {
                        const parcel = params.data as LinkedParcelRowModel;
                        return parcel && parcel.acctNum;
                    },
                    getLink: (params) => {
                        const parcel = params.data as LinkedParcelRowModel;
                        if (!parcel) {
                            return null;
                        }
                        return `#/parcel/${parcel.parcelId}`;
                    },
                    isMaster: (params) => {
                        const parcel = params.data as LinkedParcelRowModel;
                        return parcel && parcel.masterParcel;
                    },
                    canNavigate: !this.editMode
                } as LinkedParcelsListGridNameCellRendererParams
            },
            {
                headerName: 'Master',
                field: 'isMaster',
                width: AgGridColumns.textColumnSmallWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as LinkedParcelRowModel;
                    return parcel && parcel.masterParcel ? 'Master' : 'Subordinate';
                },
                filterValueGetter: params => {
                    const parcel = params.data as LinkedParcelRowModel;
                    return parcel && parcel.masterParcel ? 'Master' : 'Subordinate';
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                suppressMenu: true,
                sortable: false,
                cellRendererFramework: LinkedParcelsListGridActionCellRendererComponent,
                cellRendererParams: {
                    canRemove: this._canRemove.bind(this),
                    remove: this._remove.bind(this),
                    toggleMaster: this._toggleMaster.bind(this),
                    getMasterTitle: this._getMasterTitle.bind(this),
                    canToggleMaster: (params) => {
                        return false; //disable toggle master until the API supports it
                    }
                } as ICellRendererParamsForLinkedParcelsListGridAction,
                hide: !this.editMode
            }];

        this._gridApi.setColumnDefs(columns);
        this._gridColumnApi.setColumnPinned('actions', 'right');
        this.agGridReady = true;
        this._setRowData();
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        const parcelLinks = this.parcels
            .filter(x => x.parcelId !== this.params.parcelId)
            .map((x) => {
                const parcelLink: Core.ParcelLinkDTO = {
                    linked: true,
                    parcelId: x.parcelId
                };
                return parcelLink;
            });

        try {
            const model: Core.LinkedParcelInputDTO = {
                callingParcelId: this.params.parcelId,
                linkageTypeId: this.selectedLinkageType && this.selectedLinkageType.linkageTypeId,
                masterParcelId: this.masterParcel && this.masterParcel.parcelId,
                parcelLinks: parcelLinks
            };

            this.result = await lastValueFrom(this._linkedParcelRepository.createOrUpdate(model));
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async add(): Promise<void> {
        if (!this._allParcels) {
            const busyMsg = 'loading';

            this.busyIndicatorMessageManager.add('Loading', busyMsg);

            try {
                this._allParcels = await lastValueFrom(this._linkedParcelRepository.getList(this.params.parcelId, true));
            } finally {
                this.busyIndicatorMessageManager.remove(busyMsg);
            }
        }

        let addResult: Core.LinkedParcelDTO[] = [];
        try {
            const params: LinkedParcelAddParams = {
                parcels: this.parcels,
                parcelId: this.params.parcelId,
                allParcels: this._allParcels
            };

            const bsModalRef = await this._wsModalService.show(() => {
                return this._bsModalService.show(LinkedParcelsAddComponent,
                    {
                        class: 'modal-lg',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

            addResult = (bsModalRef.content as LinkedParcelsAddComponent).result;
        } catch (e) {
            return Promise.resolve();
        } finally {
            // restore focus to this modal
            this._timer.setTimeout(() => {
                this.focusElement.nativeElement.focus();
            }, 100);
        }

        const newParcels = addResult.map(
            (x) => {
                return {
                    ...x,
                    ...{
                            isMaster: this.masterParcel && x.parcelId === this.masterParcel.parcelId,
                            linked: true
                        }
                } as LinkedParcelRowModel;
            });

        newParcels.forEach(x => this.parcels.push(x));

        this._setRowData();

        return Promise.resolve();
    }

    removeAll(): void {
        this.parcels.forEach((x) => {
            x.linked = false;
            x.linkageTypeId = null;
            x.masterParcelId = null;
        });

        this.parcels = [this.masterParcel];

        this._setRowData();
    }

    async bulkDelete(): Promise<void> {
        const selectedRowIds = await this._gridTracker.getSelectedRowIds();
        const selected = this.parcels.filter(x => selectedRowIds.includes(x.parcelId));
        selected.forEach(x => {
            x.linked = false;
            x.linkageTypeId = null;
            x.masterParcelId = null;
        });

        this._gridTracker.clear();
        this.parcels = this.parcels.filter(x => !selectedRowIds.includes(x.parcelId));

        this._setRowData();
    }

    private _getParcelType(params: ParcelTypeCellRendererComponentParams): string {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return null;
        }

        return parcel.propertyTypeAbbr;
    }

    private _isLinkedParcel(params: ParcelTypeCellRendererComponentParams): boolean {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return false;
        }
        return parcel.linked;
    }

    private _isMasterParcel(params: ParcelTypeCellRendererComponentParams): boolean {
        const parcel = params.data as Core.LinkedParcelDTO;
        if (!parcel) {
            return false;
        }
        return parcel.masterParcel;
    }

    private _getMasterTitle(params: ICellRendererParamsForLinkedParcelsListGridAction): string {
        const parcel = params.data as LinkedParcelRowModel;
        if (!parcel) {
            return '';
        }

        return parcel.masterParcel ? 'Remove Master' : 'Make Master';
    }

    private _toggleMaster(params: ICellRendererParamsForLinkedParcelsListGridAction): void {
        const newMasterParcel = params.data as LinkedParcelRowModel;
        if (!newMasterParcel) {
            return;
        }

        newMasterParcel.masterParcel = !newMasterParcel.masterParcel;
        this.masterParcel = newMasterParcel.masterParcel ? newMasterParcel : null;

        this.parcels.forEach(
            (parcel) => {
                parcel.masterParcelId = newMasterParcel.masterParcel ? newMasterParcel.parcelId : null;
                parcel.masterParcel = newMasterParcel.masterParcel ? parcel.parcelId === newMasterParcel.parcelId : false;
            });

        this._setRowData();
    }

    private _canRemove(params: ICellRendererParamsForLinkedParcelsListGridAction): boolean {
        const parcel = params.data as LinkedParcelRowModel;
        if (!parcel) {
            return false;
        }

        return parcel.parcelId !== this.params.parcelId;
    }

    private _remove(params: ICellRendererParamsForLinkedParcelsListGridAction): void {
        const parcel = params.data as LinkedParcelRowModel;
        if (!parcel) {
            return;
        }

        parcel.masterParcelId = null;
        parcel.masterParcel = false;
        parcel.linked = false;

        if (this.masterParcel) {
            if (parcel.parcelId === this.masterParcel.parcelId) {
                this.masterParcel = null;
            }
        }

        _.remove(this.parcels, x => x.parcelId === parcel.parcelId);

        this._setRowData();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.parcels && this.agGridReady)) {
            return;
        }

        const masterParcels = this.parcels.filter(x => x.masterParcel);
        const subordinateParcels = this.parcels.filter(x => !x.masterParcel);

        this._gridApi.setPinnedTopRowData(masterParcels);
        this._gridApi.setRowData(subordinateParcels);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Compliance.QueryResultModel<number> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const parcel = x.data as LinkedParcelRowModelSelectable;
                return parcel && parcel.parcelId;
            })
        } as Compliance.QueryResultModel<number>;
    }
}
