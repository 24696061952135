import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'add-tax-year-modal',
    template: `
        <ws-modal-wrapper headerLabel="Add Tax Year"
                          acceptLabel="Add"
                          (save)="add()"
                          (cancel)="closeModal()">
            <div class="form-group">
                <select class="form-select-sm" [(ngModel)]="yearToAdd" style="width: auto;">
                    <option *ngFor="let year of years" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
        </ws-modal-wrapper>
    `
})
export class AddTaxYearModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    yearsAlreadyAdded: number[];
    onClose: (yearToAdd: number) => void;

    years: number[];
    yearToAdd: number;

    ngOnInit() {
        const currentYear: number = Number(moment().format('YYYY'));
        const years = _.range(currentYear - 10, currentYear + 5);

        this.years = _.chain(years)
            .without(...this.yearsAlreadyAdded)
            .reverse()
            .value();


        //console.log('this.yearToAdd', this.yearToAdd);

        if (this.yearsAlreadyAdded && _.max(this.yearsAlreadyAdded))
            this.yearToAdd = _.max(this.yearsAlreadyAdded) + 1;

        if (!this.yearToAdd)
            this.yearToAdd = currentYear;
    }

    add() {
        this.onClose(this.yearToAdd);
        this.bsModalRef.hide();

    }

    closeModal() {
        this.bsModalRef.hide();
    }
}
