import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ReturnService } from '../../../return.service';
import { ReturnFormRevisionAssociation } from '../../../Models/returnFormRevisionAssociation';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';
import { ProductAnalyticsService } from "../../../../../Common/Amplitude/productAnalytics.service";

export interface ReturnPreviewFormAssociationsParams {
    returnId?: number;
    parcelId?: number;
}

@Component({
    selector: 'return-preview-form-associations',
    templateUrl: './returnPreviewFormAssociations.component.html'
})
export class ReturnPreviewFormAssociationsComponent implements OnInit, IWeissmanModalComponent<ReturnPreviewFormAssociationsParams, boolean> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _returnService: ReturnService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    private readonly _APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_RETURNS: Compliance.NameValuePair<string> = {
        name: 'all-selected-returns',
        value: 'All selected returns'
    };

    private readonly _APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN: Compliance.NameValuePair<string> = {
        name: 'selected-return',
        value: null
    };

    params: ReturnPreviewFormAssociationsParams;
    result: boolean;

    isBusy: boolean = false;
    applyChangesScopeOptions: Compliance.NameValuePair<string>[] = [];
    selectedApplyChangesScopeOption: Compliance.NameValuePair<string>;
    formRevisionAssociations: ReturnFormRevisionAssociation[] = [];
    hasChanges: boolean = false;

    ngOnInit(): void {
        // determine if we are applying changes to multiple returns or a single one
        this.applyChangesScopeOptions.push(this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_RETURNS);

        if (this.params && this.params.returnId && this.params.parcelId) {
            const selectedReturn = this._returnService.sharedState.returns.find(x => x.returnId === this.params.returnId && x.parcelId === this.params.parcelId);
            if (selectedReturn) {
                this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN.value = selectedReturn.parcelAcctNumberDisplay;
                this.applyChangesScopeOptions.push(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN);
            }
        }

        this.selectedApplyChangesScopeOption = this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN) === -1 ?
            this.applyChangesScopeOptions[this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_RETURNS)] :
            this.applyChangesScopeOptions[this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN)];

        this._refreshReturnFormRevisions();
    }

    onSelectedApplyChangesScopeFilterChange(): void {
        this._refreshReturnFormRevisions();
    }

    onFormSelectedChange(form: ReturnFormRevisionAssociation): void {
        form.isSelected = !form.isSelected;
        form.hasChanges = this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN
            ? form.isSelected !== form.isSelectedInitial
            : (form.isSelected && form.totalReturnsAvailableToUse !== 0) || !form.isSelected;

        this.hasChanges = !!this.formRevisionAssociations.find(x => x.hasChanges);
    }

    async save(): Promise<void> {
        // create the collection of return form revision that will be Created or Removed
        const createOrUpdateReturnFormRevisions: Compliance.ReturnFormRevisionModel[] = [];

        let returnIds = this._returnService.sharedState.returns
                            .reduce((acc, x) => (acc.includes(x.returnId)) ? acc : [...acc, x.returnId], []);
        let inUseReturnFormRevisions = this._returnService.getAssociatedReturnFormRevisions();
        let canUseReturnFormRevisions = this._returnService.getNotAssociatedReturnFormRevisions();

        if (this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN) {
            returnIds = returnIds.filter(x => x === this.params.returnId);
            inUseReturnFormRevisions = inUseReturnFormRevisions.filter(x => x.returnId === this.params.returnId);
            canUseReturnFormRevisions = canUseReturnFormRevisions.filter(x => x.returnId === this.params.returnId);
        }

        this.formRevisionAssociations.forEach(formRevisionAssociation => {
            if (formRevisionAssociation.isSelected) {
                returnIds.forEach(returnId => {
                    // if currently in use then do nothing
                    const currentlyInUse = inUseReturnFormRevisions.filter(x => x.returnId === returnId && x.formRevisionId === formRevisionAssociation.formRevisionId).length > 0;
                    if (currentlyInUse) {
                        return;
                    }

                    // find all return form revisions that can be used and add them to the list (for addition)
                    canUseReturnFormRevisions
                        .filter(x => x.returnId === returnId && x.formRevisionId === formRevisionAssociation.formRevisionId)
                        .forEach(() => {
                            const rfr: Compliance.ReturnFormRevisionModel = {
                                returnFormRevisionId: 0, // leave as zero so for API to add association
                                returnId: returnId,
                                formRevisionId: formRevisionAssociation.formRevisionId,
                                formRevisionName: null
                            } as Compliance.ReturnFormRevisionModel;
                            createOrUpdateReturnFormRevisions.push(rfr);
                        });
                });
            }

            if (!formRevisionAssociation.isSelected) {
                returnIds.forEach(returnId => {
                    // if currently not in use (is available to add) the do nothing
                    const currentlyNotInUse = canUseReturnFormRevisions.filter(x => x.returnId === returnId && x.formRevisionId === formRevisionAssociation.formRevisionId).length > 0;
                    if (currentlyNotInUse) {
                        return;
                    }

                    // find all return form revisions that are currently in use and add them to the list (for removal)
                    inUseReturnFormRevisions
                        .filter(x => x.returnId === returnId && x.formRevisionId === formRevisionAssociation.formRevisionId)
                        .forEach(x => createOrUpdateReturnFormRevisions.push(x));
                });
            }
        });

        this.isBusy = true;

        if (!createOrUpdateReturnFormRevisions.length) {
            this._bsModalRef.hide();
        } else {
            try {
                await this._returnService.associateOrDisassociateReturnFormRevisions(createOrUpdateReturnFormRevisions);

                this.result = true;
                this._bsModalRef.hide();
            } finally {
                this._productAnalyticsService.logEvent('click-edit-forms-ok', {
                    updateReturnForms: this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_RETURNS ? 'all returns' : 'specific return'
                });
                this.isBusy = false;
            }
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    disableSave(): boolean{
        return !this.hasChanges || this.hasOrphanedSupplementalForms();
    }

    hasOrphanedSupplementalForms(): boolean{
        var items = this.formRevisionAssociations.filter(x=>x.isSelected);
        var parentIds = items.map(x => x.parentFormRevisionId).filter(x => x);
        var itemIds = items.map(x => x.formRevisionId);
        return !parentIds.every( x=> itemIds.includes(x));
    }

    getMessage(form: ReturnFormRevisionAssociation): string {
        let message: string;

        if (this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN) {
            if (form.isSelected) {
                message = form.hasChanges
                    ? `Adding to ${this.selectedApplyChangesScopeOption.value}`
                    : `No changes.  (added to ${this.selectedApplyChangesScopeOption.value})`;
            } else {
                message = form.hasChanges
                    ? `Removing from ${this.selectedApplyChangesScopeOption.value}`
                    : `No changes.  (not added to ${this.selectedApplyChangesScopeOption.value})`;
            }
        } else {
            if (form.isSelected) {
                message = form.hasChanges
                    ? `Adding to ${form.totalReturnsAvailableToUse} of ${form.totalReturns} selected returns`
                    : `No changes. (added to ${form.totalReturns - form.totalReturnsAvailableToUse} of ${form.totalReturns} selected returns)`;
            } else {
                message = form.hasChanges
                    ? `Removing from  ${form.totalReturns - form.totalReturnsAvailableToUse} of ${form.totalReturns} selected returns`
                    : `No changes.  (added to ${form.totalReturns - form.totalReturnsAvailableToUse} of ${form.totalReturns} selected returns)`;
            }
        }

        return message;
    }

    getReturnFormRevisionDisplayName(returnFormRevision: Compliance.ReturnFormRevisionModel): string {
        return this._returnService.getReturnFormRevisionDisplayName(returnFormRevision);
    }

    private _refreshReturnFormRevisions(): void {
        const selectedReturnIds = this._returnService.sharedState.returns
                                      .reduce((acc, x) => (acc.includes(x.returnId)) ? acc : [...acc, x.returnId], []);

        let inUseReturnFormRevisions = this._returnService.getAssociatedReturnFormRevisions();
        let canUseReturnFormRevisions = this._returnService.getNotAssociatedReturnFormRevisions();

        if (this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN) {
            inUseReturnFormRevisions = inUseReturnFormRevisions.filter(x => x.returnId === this.params.returnId);
            canUseReturnFormRevisions = canUseReturnFormRevisions.filter(x => x.returnId === this.params.returnId);
        }

        this.formRevisionAssociations = [];

        // take all return form revisions (available and not available)
        // group by the form revision ID as we need to separate at the form revision ID level
        // and then determine how many returns it is in use vs not in use by the ReturnFormRevisionId being populated
        // all in use one will have a ReturnFormRevisionId set to a number higher than zero
        const allReturnFormRevisions = inUseReturnFormRevisions.concat(canUseReturnFormRevisions);
        const returnFormRevisionsGroupedByFormRevisionId = _.groupBy(allReturnFormRevisions, x => x.formRevisionId);

        for (let key in returnFormRevisionsGroupedByFormRevisionId) {
            const returnFormRevisions = returnFormRevisionsGroupedByFormRevisionId[key];
             // they're all grouped by formRevisionID so they all share the same Id and Name; read those off the first one
            const formRevisionId = returnFormRevisions[0].formRevisionId;
            const formRevisionName = returnFormRevisions[0].formRevisionName;
            const totalReturnsAvailableToUse = returnFormRevisions.filter(x => !x.returnFormRevisionId).length;
            const allReturnsAdded = totalReturnsAvailableToUse === 0;
            const formRevisionDisplayName = this._returnService.getReturnFormRevisionDisplayName(returnFormRevisions[0]);
            const parentFormRevisionId = returnFormRevisions[0].parentFormRevisionId;

            const fra: ReturnFormRevisionAssociation = {
                formRevisionId: formRevisionId,
                formRevisionName: formRevisionName,
                isSelected: allReturnsAdded,
                isSelectedInitial: allReturnsAdded,
                hasChanges: this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_RETURN ? false : !allReturnsAdded,
                totalReturns: selectedReturnIds.length,
                totalReturnsAvailableToUse: totalReturnsAvailableToUse,
                formRevisionDisplayName: formRevisionDisplayName,
                parentFormRevisionId: parentFormRevisionId
            };

            this.formRevisionAssociations.push(fra);
        }

        //sort forms: form revision first, then its supplemental forms next
        var sortedForms = []
        var parentFormRevisionAssociations = this.formRevisionAssociations
            .filter(x=>x.parentFormRevisionId === null)
            .sort((a, b) => a.formRevisionName.localeCompare(b.formRevisionName))
        var childFormRevisionAssociations = this.formRevisionAssociations
            .filter(x=>x.parentFormRevisionId !== null)
            .sort((a, b) => a.formRevisionName.localeCompare(b.formRevisionName))
        var childDictionary: { [id: string] : ReturnFormRevisionAssociation[]; } = {};

        childFormRevisionAssociations.forEach(child => {
            if (!childDictionary[child.parentFormRevisionId]) {
                childDictionary[child.parentFormRevisionId] = [];
            }
            childDictionary[child.parentFormRevisionId].push(child);
        });

        parentFormRevisionAssociations.forEach(parent => {
            sortedForms.push(parent);
            if (childDictionary[parent.formRevisionId] != null) {
                sortedForms.push(...childDictionary[parent.formRevisionId])
            }
        });

        this.formRevisionAssociations = sortedForms;

        this.hasChanges = !!this.formRevisionAssociations.find(x => x.hasChanges);
    }
}
