import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Assessor } from 'src/app/Assessor-Collector/assessor.model';
import { AttachmentModalData } from 'src/app/Attachment/attachment.modal.model';
import { CommentModalData } from 'src/app/Comments/comments.service';
import { Address } from 'src/app/Common/Models/common.model';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { AnchorSREF } from 'src/app/UI-Lib/Directives/Anchor-Route/anchorRoute.directive';
import { HelpContentComponentConfig, HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import {
    AssessorDetailsComponent,
    AssessorDetailsComponentParams
} from '../../Assessor-Details/assessorDetails.component';
import { ParcelService } from '../parcel.service.upgrade';
import { LegalDescriptionModalComponent, LegalDescriptionModalParams } from './legalDescriptionModal.component';
import { PARCEL_INFO_PANEL_HELP } from './parcelInfoPanel.component.help';
import { EntityType } from 'src/app/constants.new';


@Component({
    selector: 'parcel-info-panel-view',
    templateUrl: 'parcelInfoPanelView.component.html',
    styleUrls: ['parcelInfoPanelView.component.scss']
})
export class ParcelInfoPanelViewComponent implements OnInit {
    constructor(private readonly _parcelService: ParcelService,
                private readonly _helpService: HelpService,
                private readonly _modalService: WeissmanModalService) {
    }

    @Input() parcelViewDto: Core.ParcelViewDTO;
    @Input() stateAllowConsolidating: boolean;
    @Input() parcelAssessor: Assessor;
    @Input() hasEditPermission: boolean;
    @Input() years: number[];
    @Input() assessorDetailsRenderer: HelpContentComponentConfig<AssessorDetailsComponent, AssessorDetailsComponentParams>;
    @Output() serverActionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    altAcctNums: Weissman.Model.Domain.AccountNumber[];
    optimusLinkInfo: { url: string };
    analyticsCategory: string = 'parcel';

    get parcel(): Weissman.Model.Domain.Parcel {
        return this.parcelViewDto.parcel;
    }

    get acctNumHasDoubleSpace(): boolean {
        return _.includes(this.parcel.acctNum, '  ');
    }

    get address(): Address {
        return this.parcel.address as unknown as Address;
    }

    get showTaxingDistrict(): boolean {
        return this.parcel.propertyTypeID === Core.PropertyTypes.Personal && this.parcelAssessor?.stateID === 16; // Indiana
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.parcel) {
            return null;
        }

        return {
            entityType: 'Parcel',
            entityName: this.parcel.acctNum,
            parentId: this.parcelViewDto.siteId,
            parentTypeId: EntityType.Site
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.parcel) {
            return null;
        }

        return {
            entityID: this.parcel.parcelID,
            entityTypeID: Core.EntityTypes.Parcel,
            description: `Parcel: ${this.parcel.acctNum}`,
            canEdit: this.hasEditPermission
        } as CommentModalData;
    }

    async ngOnInit() {
        this.altAcctNums = _.filter(this.parcel.altAccountNumbers, x => {
            if (x === undefined || !x.accountNumberType) {
                return false;
            }
            const desc = x.accountNumberType.accountNumberDesc;
            return !(desc[0] === '!' && desc[desc.length - 1] === '!');
        });

        this._helpService.setContent(PARCEL_INFO_PANEL_HELP);

        this.serverActionChange.emit(true);
        try {
            this.optimusLinkInfo = await this._parcelService.getOptimusLinkInfo(this.parcel.parcelID);
        } finally {
            this.serverActionChange.emit(false);
        }
    }

    getCollectorSref(collector: Weissman.Model.Domain.ParcelCollector): AnchorSREF {
        if (collector.isCompanyCollector) {
            return {
                target: 'companyCollector',
                options: { companyID: this.parcelViewDto.topLevelCompanyId, collectorID: collector.collectorID }
            };
        } else {
            return {
                target: 'collector',
                options: { stateId: collector.collectorStateID, stateCollectorId: collector.collectorID }
            };
        }
    }

    getTRS(collector: Weissman.Model.Domain.ParcelCollector): Weissman.Model.Domain.ParcelTaxRateSetupDTO {
        return _.find(this.parcelViewDto.taxRateSetup, { parcelCollectorId: collector.parcelCollectorID });
    }

    async showLegalDescriptionDialog(): Promise<void> {
        const modalParams: LegalDescriptionModalParams = {
            isEditMode: false,
            parcel: this.parcel
        };

        await this._modalService.showAsync(LegalDescriptionModalComponent, modalParams, 'ace-modal-dialog modal-lg');
    }
}
