import { Component, DoCheck, KeyValueDiffer, KeyValueDiffers, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { from as observableFrom, lastValueFrom, Observable, of as observableOf, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import {
    AssetRepository,
    CompanyAssetDescriptorRepository,
    CostAdjustmentRepository,
    GLAccountRepository,
    ReportingParcelRepository
} from '../../../Repositories';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ExtendedGLAccountModel } from '../../Asset-Details/Asset-Info/extendedGLAccountModel';
import { WeissmanKeyValueDisplayPipe } from '../../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../../Busy-Indicator';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { EntityPickerService } from '../../../Entity-Picker/entityPicker.service';
import { ExtendedSiteModel } from '../../Asset-Details/Asset-Info/extendedSiteModel';
import { AssetLienDate } from '../../Asset-Lien-Date/assetLienDate.component';
import { SiteRepository } from '../../../../Core-Repositories';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { AssetBulkUpdateActionComponent } from './assetBulkUpdateAction.component';
import { HelpContentComponentConfig, HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { ASSET_BULK_UPDATE_HELP } from './assetBulkUpdate.component.help';
import {
    CostAdjustmentHelpComponent,
    CostAdjustmentHelpComponentParams
} from '../../Asset-Details/costAdjustmentHelp.component';
import { FlattenedCostAdjustmentSource } from '../../Asset-Details/Asset-Info/assetInfo.component';
import { TimerService } from '../../../../UI-Lib/Utilities/timer.service';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';
import { FeatureFlagsService } from '../../../../Common/FeatureFlags/feature-flags-service';
import { ToastrService } from 'ngx-toastr';

import * as _ from 'lodash';

export class BulkUpdateStateCostAdjustment {
    stateId: number;
    state: string;
    costAdjustments: BulkUpdateCostAdjustment[];
    customHelpRenderer: HelpContentComponentConfig<CostAdjustmentHelpComponent, CostAdjustmentHelpComponentParams>;
    extendedCostAdjustmentTypes: FlattenedCostAdjustmentSource[];
}

export class BulkUpdateCostAdjustment {
    costAdjustmentTypeId: number;
    costAdjustment: string;
    sequence: number;
}

export interface AssetBulkUpdateParams {
    companyId: number;
    lienDate: AssetLienDate;
    reportingAssetIds: number[];
    selection: Compliance.SelectedRowsModel;
    selectedCount: number;
    assetListLastModifiedTimestamp: Date;
    hotkeyFocus: string;
    siteId?: number;
    parcelId?: number;
}

enum AssetBulkUpdateTabEnum{
    AssetFields,
    Splits
}

@Component({
    selector: 'asset-bulk-update-modal',
    templateUrl: './assetBulkUpdate.component.html',
    styleUrls: ['./assetBulkUpdate.component.scss'],
})
export class AssetBulkUpdateComponent implements OnInit, OnDestroy, DoCheck, IWeissmanModalComponent<AssetBulkUpdateParams,  Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _messageModalService: MessageModalService,
        private readonly _assetRepository: AssetRepository,
        private readonly _glAccountRepository: GLAccountRepository,
        private readonly _costAdjustmentRepository: CostAdjustmentRepository,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _entityPickerService: EntityPickerService,
        private readonly _siteService: SiteRepository,
        private readonly _helpService: HelpService,
        private readonly _companyAssetDescriptorRepository: CompanyAssetDescriptorRepository,
        private readonly _differs: KeyValueDiffers,
        private readonly _renderer: Renderer2,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _timerService: TimerService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _reportingParcelRepository: ReportingParcelRepository,
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _toastrService: ToastrService
    ) { }

    @ViewChild('bulkUpdateFieldContainer', { static: true }) bulkUpdateFormElement;
    @ViewChild('leaseTypeActionField') leaseTypeActionField: AssetBulkUpdateActionComponent;

    private _glAccounts: ExtendedGLAccountModel[];
    private _glAccountNoResult: boolean = false;
    private _bulkUpdateModelDiffer: KeyValueDiffer<string, any>;
    private _siteNoResult: boolean = false;

    private readonly OWNERSHIP_TYPE_OWNED = 0;

    private _busyRef: BusyIndicatorRef;
    private _closed: boolean;
    private _destroy$: Subject<void> = new Subject();

    params: AssetBulkUpdateParams;
    result: Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>;

    reportingAssetBulkUpdateModel: Compliance.ReportingAssetBulkUpdateModel = { } as Compliance.ReportingAssetBulkUpdateModel;
    glAccountFilter: string = '';
    glAccountLastMatch: string = null;
    assetClassificationId: number;
    assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    costAssetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    acqDateAssetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    bulkUpdateMetadata: Compliance.ReportingAssetBulkUpdateSelectionMetadataModel;
    costAdjustmentTypes: Compliance.CostAdjustmentTypeModel[];
    stateCostAdjustments: BulkUpdateStateCostAdjustment[] = new Array<BulkUpdateStateCostAdjustment>();
    siteIds: number[];
    isFormValid: boolean = false;
    isAssetFieldTabValid: boolean = false;
    isSplitTabValid: boolean = false;
    isLoading: boolean = false;
    isSaving: boolean = false;
    isBulkMetadataLoaded: boolean = false;

    validationMessage: string = '';
    displayPerpetualTooltip: boolean = false;
    perpetualAllowedAssetsCount: number;
    siteFilter: string = '';
    siteIsLoading: boolean = false;
    siteFieldEdited: boolean = false;

    isCreateNewSplitAllowed: boolean = false;
    isUpdateSplitAllowed: boolean = false;

    activeTab: AssetBulkUpdateTabEnum = AssetBulkUpdateTabEnum.AssetFields;

    assetBulkUpdateTabEnum = AssetBulkUpdateTabEnum;

    assetVerificationReasons: string[];

    perpetualHelpContentId: string = 'asset-bulk-update.isPerpetual-none';

    leaseTypes: Compliance.LeaseTypeModel[];
    ownershipTypes: Compliance.AssetOwnershipTypeModel[];

    glAccounts$: Observable<ExtendedGLAccountModel[]> = (Observable
        .create((observer: any) => { observer.next(this.glAccountFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterGlAccounts(token)));

    sites$: Observable<ExtendedSiteModel[]> = (Observable
        .create((observer: any) => { observer.next(this.siteFilter) }) as Observable<string>)
        .pipe(mergeMap((token: string) => this._filterSites(token)));

    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();


    get selectedCount(): string {
        return this._decimalPipe.transform(this.params.selectedCount);
    };

    ngOnInit(): void {
        this._helpService.setContent(ASSET_BULK_UPDATE_HELP);
        this._loadMetadata();

        this.initializeBulkUpdateModel();

        this._bulkUpdateModelDiffer = this._differs.find(this.reportingAssetBulkUpdateModel).create();
    }

    ngAfterViewInit(): void {
        this._focusOnFormElement(this.params.hotkeyFocus, true);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private initializeBulkUpdateModel() {
        this.reportingAssetBulkUpdateModel = { } as Compliance.ReportingAssetBulkUpdateModel;

        this.reportingAssetBulkUpdateModel.selectedRows = this.params.selection;
        this.reportingAssetBulkUpdateModel.lastModifiedTimestamp = this.params.assetListLastModifiedTimestamp;
        this.reportingAssetBulkUpdateModel.lienDate = this.params.lienDate.date;
        this.reportingAssetBulkUpdateModel.useStateLienDate = this.params.lienDate.useStateLienDate;
        this.reportingAssetBulkUpdateModel.lienDateType = this.params.lienDate.lienDateType;
        this.reportingAssetBulkUpdateModel.costAdjustmentUpdates = [];
        this.reportingAssetBulkUpdateModel.assetVerificationDate = this.params.lienDate.date;
        this.reportingAssetBulkUpdateModel.filterBySiteId = this.params.siteId;
        this.reportingAssetBulkUpdateModel.filterByParcelId = this.params.parcelId;
    }

    ngDoCheck(): void {
        const assetChanges = this._bulkUpdateModelDiffer && this._bulkUpdateModelDiffer.diff(this.reportingAssetBulkUpdateModel);

        if (assetChanges) {
            this.validateForm();
        }
    }

    onGlAccountSelected(match: TypeaheadMatch): void {
        this.reportingAssetBulkUpdateModel.glAccountId = (match.item as ExtendedGLAccountModel).model.glAccountId;
        this.glAccountFilter = match.value;
        this.glAccountLastMatch = match.value;
    }

    onGlAccountBlur(): void {
        if (this.glAccountFilter.trim() === '' || this._glAccountNoResult) {
            this.glAccountFilter = '';
            this.reportingAssetBulkUpdateModel.glAccountId = null;
        } else if (this.glAccountLastMatch && this.glAccountLastMatch !== this.glAccountFilter.trim()) {
            this.glAccountFilter = this.glAccountLastMatch;
        }
    }

    onGlAccountNoResults(event: boolean): void {
        this._glAccountNoResult = event;

        if (event) {
            this.glAccountLastMatch = null;
            this.reportingAssetBulkUpdateModel.glAccountId = null;
        }
    }

    onSiteNoResults(event: boolean): void {
        this._siteNoResult = event;
    }

    validateForm(): void {
        this.validationMessage = '';
        this.isFormValid = false;
        this.isSplitTabValid = false;

        const areSplitFieldsValid =
                (this.reportingAssetBulkUpdateModel.isBulkCreateSplitOperation
                    && this.reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage >= 0
                    && this.reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage <= 100)
                ||
                (this.reportingAssetBulkUpdateModel.isBulkUpdateSplitOperation
                    && this.reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage >= 0
                    && this.reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage <= 100);

        const areBaseFieldsValid =
                (this.reportingAssetBulkUpdateModel.assetVerificationReason != null
                    && this.reportingAssetBulkUpdateModel.assetVerificationDate != null
                    && this.reportingAssetBulkUpdateModel.assetVerificationAction ===  Compliance.ReportingAssetBulkUpdateFieldActionEnum.Verify)
                ||
                (this.reportingAssetBulkUpdateModel.ownershipTypeId != null
                    && this.reportingAssetBulkUpdateModel.ownershipTypeAction ===  Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo)
                ||
                (this.reportingAssetBulkUpdateModel.leaseTypeId
                    && this.reportingAssetBulkUpdateModel.leaseTypeAction ===  Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo)
                ||
                    (this.reportingAssetBulkUpdateModel.acqDate
                    && this.reportingAssetBulkUpdateModel.acqDateAction ===  Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.acqDateAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.classificationId
                    && this.reportingAssetBulkUpdateModel.classificationIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.classificationIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.classificationIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.cost && this.reportingAssetBulkUpdateModel.costAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.costAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (typeof this.reportingAssetBulkUpdateModel.isPerpetual !=='undefined' && this.reportingAssetBulkUpdateModel.isPerpetual !== null && this.reportingAssetBulkUpdateModel.isPerpetualAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo)
                || (this.reportingAssetBulkUpdateModel.description
                    && this.reportingAssetBulkUpdateModel.description !== ''
                    && this.reportingAssetBulkUpdateModel.descriptionAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.descriptionAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.descriptionAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.disposedDate
                    && this.reportingAssetBulkUpdateModel.disposedDateAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.disposedDateAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.glAccountId
                    && this.reportingAssetBulkUpdateModel.glAccountIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.glAccountIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.siteId
                        && this.reportingAssetBulkUpdateModel.siteIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                        || this.reportingAssetBulkUpdateModel.siteIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.reportingParcelId
                    && this.reportingAssetBulkUpdateModel.reportingParcelIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.reportingParcelIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t1
                    && this.reportingAssetBulkUpdateModel.t1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t2
                    && this.reportingAssetBulkUpdateModel.t2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t3
                    && this.reportingAssetBulkUpdateModel.t3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t4
                    && this.reportingAssetBulkUpdateModel.t4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t5
                    && this.reportingAssetBulkUpdateModel.t5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t6
                    && this.reportingAssetBulkUpdateModel.t6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t7
                    && this.reportingAssetBulkUpdateModel.t7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t8
                    && this.reportingAssetBulkUpdateModel.t8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t9
                    && this.reportingAssetBulkUpdateModel.t9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t10
                    && this.reportingAssetBulkUpdateModel.t10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t11
                    && this.reportingAssetBulkUpdateModel.t11Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t11Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t11Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t12
                    && this.reportingAssetBulkUpdateModel.t12Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t12Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t12Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t13
                    && this.reportingAssetBulkUpdateModel.t13Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t13Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t13Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t14
                    && this.reportingAssetBulkUpdateModel.t14Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t14Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t14Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t15
                    && this.reportingAssetBulkUpdateModel.t15Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t15Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t15Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t16
                    && this.reportingAssetBulkUpdateModel.t16Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t16Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t16Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t17
                    && this.reportingAssetBulkUpdateModel.t17Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t17Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t17Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t18
                    && this.reportingAssetBulkUpdateModel.t18Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t18Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t18Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t19
                    && this.reportingAssetBulkUpdateModel.t19Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t19Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t19Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t20
                    && this.reportingAssetBulkUpdateModel.t20Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t20Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t20Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t21
                    && this.reportingAssetBulkUpdateModel.t21Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t21Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t21Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t22
                    && this.reportingAssetBulkUpdateModel.t22Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t22Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t22Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t23
                    && this.reportingAssetBulkUpdateModel.t23Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t23Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t23Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t24
                    && this.reportingAssetBulkUpdateModel.t24Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t24Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t24Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t25
                    && this.reportingAssetBulkUpdateModel.t25Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t25Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t25Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t26
                    && this.reportingAssetBulkUpdateModel.t26Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t26Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t26Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t27
                    && this.reportingAssetBulkUpdateModel.t27Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t27Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t27Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t28
                    && this.reportingAssetBulkUpdateModel.t28Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t28Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t28Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t29
                    && this.reportingAssetBulkUpdateModel.t29Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t29Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t29Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.t30
                    && this.reportingAssetBulkUpdateModel.t30Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.t30Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank
                    || this.reportingAssetBulkUpdateModel.t30Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n1
                    && this.reportingAssetBulkUpdateModel.n1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n2
                    && this.reportingAssetBulkUpdateModel.n2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n3
                    && this.reportingAssetBulkUpdateModel.n3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n4
                    && this.reportingAssetBulkUpdateModel.n4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n5
                    && this.reportingAssetBulkUpdateModel.n5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n6
                    && this.reportingAssetBulkUpdateModel.n6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n7
                    && this.reportingAssetBulkUpdateModel.n7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n8
                    && this.reportingAssetBulkUpdateModel.n8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n9
                    && this.reportingAssetBulkUpdateModel.n9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.n10
                    && this.reportingAssetBulkUpdateModel.n10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.n10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d1
                    && this.reportingAssetBulkUpdateModel.d1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d1Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d2
                    && this.reportingAssetBulkUpdateModel.d2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d2Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d3
                    && this.reportingAssetBulkUpdateModel.d3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d3Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d4
                    && this.reportingAssetBulkUpdateModel.d4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d4Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d5
                    && this.reportingAssetBulkUpdateModel.d5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d5Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d6
                    && this.reportingAssetBulkUpdateModel.d6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d6Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d7
                    && this.reportingAssetBulkUpdateModel.d7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d7Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d8
                    && this.reportingAssetBulkUpdateModel.d8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d8Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d9
                    && this.reportingAssetBulkUpdateModel.d9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d9Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.d10
                    && this.reportingAssetBulkUpdateModel.d10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.d10Action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || (this.reportingAssetBulkUpdateModel.netBookValue
                    && this.reportingAssetBulkUpdateModel.netBookValueAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                    || this.reportingAssetBulkUpdateModel.netBookValueAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride)
                || ((this.reportingAssetBulkUpdateModel.isAnchored || this.reportingAssetBulkUpdateModel.isAnchored === false)
                    && this.reportingAssetBulkUpdateModel.isAnchoredAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo)
                || ((this.reportingAssetBulkUpdateModel.doNotPotentiallyDispose || this.reportingAssetBulkUpdateModel.doNotPotentiallyDispose === false)
                    && this.reportingAssetBulkUpdateModel.doNotPotentiallyDisposeAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo)
                || ((this.reportingAssetBulkUpdateModel.isLocked || this.reportingAssetBulkUpdateModel.isLocked === false)
                    && this.reportingAssetBulkUpdateModel.isLockedAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo);

        const isAlternativeAcqDateFieldRequired = this.reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo;
        const isAlternativeCostFieldRequired = this.reportingAssetBulkUpdateModel.alternativeCostDescriptorIdAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo;

        if (isAlternativeAcqDateFieldRequired || isAlternativeCostFieldRequired) {
            const isAlternativeAcqDateFieldValid = !isAlternativeAcqDateFieldRequired || (isAlternativeAcqDateFieldRequired && this.reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorId === null || this.reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorId >= 0);
            const isAlternativeCostFieldValid = !isAlternativeCostFieldRequired || (isAlternativeCostFieldRequired && this.reportingAssetBulkUpdateModel.alternativeCostDescriptorId === null || this.reportingAssetBulkUpdateModel.alternativeCostDescriptorId >= 0);

            const alternativeAcqDateAssetDescriptor = this.acqDateAssetDescriptors.find(x => x.companyAssetDescriptorMappingId == this.reportingAssetBulkUpdateModel.alternativeAcqDateDescriptorId);
            const alternativeCostAssetDescriptor = this.costAssetDescriptors.find(x => x.companyAssetDescriptorMappingId == this.reportingAssetBulkUpdateModel.alternativeCostDescriptorId);

            if (alternativeAcqDateAssetDescriptor && this.reportingAssetBulkUpdateModel[alternativeAcqDateAssetDescriptor.columnName.toLowerCase()+'Action'] === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank) {
                this.validationMessage = `${alternativeAcqDateAssetDescriptor.descriptor.name} cannot be set to blank because it is used for the Alternative Acquisition Date field.`;
                this.isFormValid = false;
                return;
            }

            if (alternativeCostAssetDescriptor && this.reportingAssetBulkUpdateModel[alternativeCostAssetDescriptor.columnName.toLowerCase()+'Action'] === Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank) {
                this.validationMessage = `${alternativeCostAssetDescriptor.descriptor.name} cannot be set to blank because it is used for the Alternative Cost field.`;
                this.isFormValid = false;
                return;
            }

            this.isFormValid = isAlternativeAcqDateFieldValid && isAlternativeCostFieldValid;
            return;
        }

        if (this.reportingAssetBulkUpdateModel.costAdjustmentUpdates) {
            for (let i = 0; i < this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.length; i++) {
                let costAdjustmentUpdate = this.reportingAssetBulkUpdateModel.costAdjustmentUpdates[i];

                if (costAdjustmentUpdate.fieldAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo && (costAdjustmentUpdate.adjustmentAmount !== 0 || costAdjustmentUpdate.adjustmentPercentage !== 0)) {
                    this.isFormValid = true;
                    this.isAssetFieldTabValid = true;
                    return;
                }
            }
        }

        if (this.reportingAssetBulkUpdateModel.isBulkCreateSplitOperation
            && (this.reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage < 0 || this.reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage > 100 || this.reportingAssetBulkUpdateModel.splitCreateCostAllocationPercentage === null))
        {
            this.validationMessage = 'Split Cost Percentage must me between 0% and 100%.';
            this.isFormValid = false;
            this.isSplitTabValid = false;
            return;
        }

        if (this.reportingAssetBulkUpdateModel.isBulkUpdateSplitOperation
            && (this.reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage < 0 || this.reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage > 100 || this.reportingAssetBulkUpdateModel.splitUpdateCostAllocationPercentage === null))
        {
            this.validationMessage = 'Split Cost Percentage must me between 0% and 100%.';
            this.isFormValid = false;
            this.isSplitTabValid = false;
            return;
        }

        this.isFormValid = areBaseFieldsValid || areSplitFieldsValid;
        this.isAssetFieldTabValid = areBaseFieldsValid;
        this.isSplitTabValid = areSplitFieldsValid;
    }

    async save(): Promise<void> {
        this.isSaving = true;
        await this._bulkUpdate();
    }

    cancel(): void {
        this._closed = true;
        this._bsModalRef.hide();
    }

    changeActiveTab(activeTab: AssetBulkUpdateTabEnum): void {
        if ((activeTab !== AssetBulkUpdateTabEnum.AssetFields && this.isAssetFieldTabValid) || (activeTab !== AssetBulkUpdateTabEnum.Splits && this.isSplitTabValid)) {
            if(!confirm("Switching tabs will clear all fields. Do you want to continue?")) {
                return;
            }

            this.validationMessage = null;
            this.initializeBulkUpdateModel();
        }

        this.activeTab = activeTab;
        this.reportingAssetBulkUpdateModel.isBulkCreateSplitOperation = activeTab === AssetBulkUpdateTabEnum.Splits && this.bulkUpdateMetadata && this.bulkUpdateMetadata.isCreateSplitEnabled;
        this.reportingAssetBulkUpdateModel.isBulkUpdateSplitOperation = activeTab === AssetBulkUpdateTabEnum.Splits && this.bulkUpdateMetadata && this.bulkUpdateMetadata.isUpdateSplitEnabled;
        this.validateForm();
    }

    getParcels = async (searchParams: any): Promise<Compliance.QueryResultModel<Compliance.ReportingParcelModel>> => {
        return await lastValueFrom(this._reportingParcelRepository.getReportingParcelsByAsset(searchParams));
    }

    get unlockAssets() : boolean {
        return this.reportingAssetBulkUpdateModel &&
            this.reportingAssetBulkUpdateModel.isLockedAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo &&
            this.reportingAssetBulkUpdateModel.isLocked === false;
    }

    async overrideAllFields(): Promise<void> {
        try {
            await this._messageModalService.confirm("Are you sure you want to override all fields?",'Confirm Update');
        } catch (e3) {
            return Promise.resolve();
        }

        await this._bulkUpdate(false, true);
    }

    isLockedChanged($event) {
        this.reportingAssetBulkUpdateModel.isLockedRemoveOverridesAction = Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo;
        this.reportingAssetBulkUpdateModel.isLockedRemoveOverrides = false;
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number,
                               canDismiss = true, hasProgressBar = true, warningMessage: string = null): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            if (success) {
                let newRowData;
                try {
                    newRowData = await lastValueFrom(this._assetRepository.getMultiple(this.params.lienDate.date, this.params.reportingAssetIds, true));

                    if (warningMessage) {
                        this._toastrService.warning(warningMessage);
                    }
                } finally {
                    await this._hideBusyIndicator();
                    this.result = newRowData;
                    this.isSaving = false;
                    this._bsModalRef.hide();
                }
            } else {
                await this._hideBusyIndicator();
                this.isSaving = false;
            }
        });
    }

    private async _loadMetadata(): Promise<void> {
        this.isLoading = true;

        try {
            // Make all requests in parallel
            const [ ownershipTypes, leaseTypes, assetVerificationReasons, glAccountResponse,
                assetDescriptorsResponse, bulkUpdateMetadata, costAdjustmentTypes ]:
            [ Compliance.AssetOwnershipTypeModel[], Compliance.LeaseTypeModel[], string[], Compliance.QueryResultModel<Compliance.GLAccountModel>,
                Compliance.CompanyAssetDescriptorMappingModel[], Compliance.ReportingAssetBulkUpdateSelectionMetadataModel, Compliance.CostAdjustmentTypeModel[] ] = await Promise.all([
                !this.ownershipTypes ? lastValueFrom(this._assetRepository.getOwnershipTypes()) : Promise.resolve(undefined),
                !this.leaseTypes ? lastValueFrom(this._assetRepository.getLeaseTypes()) : Promise.resolve(undefined),
                !this.assetVerificationReasons ? lastValueFrom(this._assetRepository.getVerificationReasons()) : Promise.resolve(undefined),
                lastValueFrom(this._glAccountRepository.getList(this.params.companyId, {} as Core.SearchModel<Compliance.GLAccountPropertyEnum>)),
                lastValueFrom(this._companyAssetDescriptorRepository.getByCompanyId(this.params.companyId)),
                this._getBulkUpdateMetaData(),
                lastValueFrom(this._costAdjustmentRepository.getTypes())
            ]);

            //Asset Ownership Types
            if (!this.ownershipTypes) {
                this.ownershipTypes = ownershipTypes;
            }

            if (!this.leaseTypes) {
                this.leaseTypes = leaseTypes;
            }

            if (!this.assetVerificationReasons) {
                this.assetVerificationReasons = assetVerificationReasons
            }

            // GL Accounts
            this._glAccounts = glAccountResponse.data
                .filter(x => x.accountType === Compliance.GLAccountTypeEnum.Asset)
                .map(i => new ExtendedGLAccountModel(i, this._keyValueDisplayPipe));

            // Asset Descriptors
            this.assetDescriptors = assetDescriptorsResponse.filter(x => x.descriptor.category !== Core.DescriptorCategoryEnum.AssetCost && x.descriptor.category !== Core.DescriptorCategoryEnum.AssetAquisitionDate);
            this.costAssetDescriptors = assetDescriptorsResponse.filter(x => x.descriptor.category == Core.DescriptorCategoryEnum.AssetCost);
            this.acqDateAssetDescriptors = assetDescriptorsResponse.filter(x => x.descriptor.category == Core.DescriptorCategoryEnum.AssetAquisitionDate);

            // Bulk Update Metadata
            this.costAdjustmentTypes = costAdjustmentTypes;

            this.bulkUpdateMetadata.states.forEach(state => {
                const filteredTypes = this.costAdjustmentTypes.filter(x => x.states.find(y => y.stateId == state.stateID));

                const bulkUpdateStateCostAdjustment = {
                    state: state.fullName,
                    stateId: state.stateID,
                    costAdjustments: filteredTypes.map(cat => ({
                        costAdjustmentTypeId: cat.costAdjustmentTypeId,
                        costAdjustment: cat.typeName,
                        sequence: _.find(cat.states, x => x.stateId === state.stateID).sequence
                    } as BulkUpdateCostAdjustment)).sort((a, b) => a.sequence - b.sequence),
                    customHelpRenderer: {
                        component: CostAdjustmentHelpComponent,
                        canHover: true,
                        hasIcon: true
                    } as HelpContentComponentConfig<CostAdjustmentHelpComponent, CostAdjustmentHelpComponentParams>
                } as BulkUpdateStateCostAdjustment;


                const flattenedTypes: FlattenedCostAdjustmentSource[] = filteredTypes.map(type => {
                    const typeState = _.find(type.states, x => x.stateId === bulkUpdateStateCostAdjustment.stateId);
                    return {
                        typeName: type.typeName,
                        costAdjustmentTypeId: type.costAdjustmentTypeId,
                        applyToSource: typeState.applyToSource,
                        sequence: typeState.sequence,
                        states: []
                    };
                });

                const sequencedCostAdjustments = flattenedTypes.sort((a, b) => a.sequence - b.sequence);
                bulkUpdateStateCostAdjustment.extendedCostAdjustmentTypes = sequencedCostAdjustments;
                bulkUpdateStateCostAdjustment.customHelpRenderer.componentParams = { value: sequencedCostAdjustments };

                this.stateCostAdjustments.push(bulkUpdateStateCostAdjustment);
            });

            this.isCreateNewSplitAllowed = this.bulkUpdateMetadata.isCreateSplitEnabled;
            this.isUpdateSplitAllowed = this.bulkUpdateMetadata.isUpdateSplitEnabled;

            this.perpetualAllowedAssetsCount = this.bulkUpdateMetadata.perpetualAllowedAssetsCount;
            this.siteIds = this.bulkUpdateMetadata.siteIds;
            this.displayPerpetualTooltip = this.perpetualAllowedAssetsCount < this.params.selectedCount;

            if(!this.displayPerpetualTooltip) {
                this.perpetualHelpContentId = 'asset-bulk-update.isPerpetual-none';
            }
            else if (this.perpetualAllowedAssetsCount > 0) {
                const helpContent = _.find(ASSET_BULK_UPDATE_HELP, x => x.helpContentId == 'asset-bulk-update.isPerpetual');
                helpContent.tooltipText = `Only ${this.perpetualAllowedAssetsCount} of the ${this.params.selectedCount} selected assets have classifications which allow perpetual assets. This field will only be updated for those assets.`;

                this.perpetualHelpContentId = 'asset-bulk-update.isPerpetual';

            } else {
                const helpContent = _.find(ASSET_BULK_UPDATE_HELP, x => x.helpContentId == 'asset-bulk-update.isPerpetual');
                helpContent.tooltipText = 'This field cannot be updated because the selected records do not have asset classifications which allow perpetual assets.';

                this.perpetualHelpContentId = 'asset-bulk-update.isPerpetual';
            }
        } finally {
            this.isLoading = false;
            this._focusOnFormElement(this.params.hotkeyFocus);
        }
    }

    private _getBulkUpdateMetaData(): Promise<Compliance.ReportingAssetBulkUpdateSelectionMetadataModel> {
        const params: Compliance.ReportingAssetBulkUpdateMetadataModel = {
            lienDate: this.params.lienDate.date,
            lienDateType: this.params.lienDate.lienDateType,
            useStateLienDate: this.params.lienDate.useStateLienDate,
            selectedRows: this.params.selection,
            filterBySiteId: this.params.siteId,
            filterByParcelId: this.params.parcelId
        };

        return lastValueFrom(this._assetRepository.getBulkUpdateMetadata(this.params.companyId, params))
            .then(bulkUpdateMetadata => {
                        this.bulkUpdateMetadata = bulkUpdateMetadata;
                        this.isBulkMetadataLoaded = true;
                        return bulkUpdateMetadata;
                    });
    }

    private _filterGlAccounts(filter: string): Observable<ExtendedGLAccountModel[]> {
        return observableOf(
            (this._glAccounts || []).filter(i => i.displayName.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _filterSites(filter: string): Observable<ExtendedSiteModel[]> {
        const searchModel: Core.SiteSearchModel = {
            excludeFromInactiveCompany: true,
            pagination: {
                skip: 0,
                take: 100
            },
            columnFilters: [
                {
                    filterProperty: Core.SitePropertyEnum.SiteNameOrNumber,
                    filterValues: [
                        {
                            filterType: Core.FilterTypeEnum.Contains,
                            filterValue: filter
                        }
                    ]
                }
            ]
        };

        return observableFrom(lastValueFrom(this._siteService.searchSites(this.params.companyId, searchModel))).pipe(
            map((sites) => sites.map(site => new ExtendedSiteModel(site, this._keyValueDisplayPipe))));
    }

    ownershipTypeChanged(ownershipTypeId?: number): void {
        if (ownershipTypeId === this.OWNERSHIP_TYPE_OWNED) {
            this.leaseTypeActionField.fieldActionChanged(Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange);
        }
    }

    isDisabled(action: Compliance.ReportingAssetBulkUpdateFieldActionEnum){
        return action !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo && action !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.Verify;
    }

    isLeaseTypeFieldDisabled() {
        return this.reportingAssetBulkUpdateModel.ownershipTypeAction !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo
                            || (this.reportingAssetBulkUpdateModel.ownershipTypeId == null || this.reportingAssetBulkUpdateModel.ownershipTypeId === this.OWNERSHIP_TYPE_OWNED);
    }

    isCostInputDisabled(stateId: number, typeId: number, isPercent: boolean): boolean {
        const otherElemSelector = isPercent ? '.costAdjustmentAmount' : '.costAdjustmentPercent';
        const otherElem = this.bulkUpdateFormElement.nativeElement.querySelector(otherElemSelector + stateId + typeId);

        const existingCostAdjustmentUpdate = this.reportingAssetBulkUpdateModel.costAdjustmentUpdates && this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.find(x => x.stateId === stateId && x.costAdjustmentTypeId === typeId);

        if (!existingCostAdjustmentUpdate) {
            return true;
        }

        if (existingCostAdjustmentUpdate.fieldAction !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo) {
            return true;
        }

        return (otherElem && otherElem.value && otherElem.value !== '0' && otherElem.value !== '');
    }

    updateCostAdjustmentAmount(stateId: number, typeId: number, event): void {
        const idx = this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.findIndex(x => x.stateId === stateId && x.costAdjustmentTypeId === typeId);

        if (idx >= 0) {
            this.reportingAssetBulkUpdateModel.costAdjustmentUpdates[idx].adjustmentPercentage = null;
            this.reportingAssetBulkUpdateModel.costAdjustmentUpdates[idx].adjustmentAmount = parseFloat(event.target.value);
            this.validateForm();
        }
    }

    updateCostAdjustmentPercent(stateId: number, typeId: number, event): void {
        const idx = this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.findIndex(x => x.stateId === stateId && x.costAdjustmentTypeId === typeId);

        if (idx >= 0) {
            this.reportingAssetBulkUpdateModel.costAdjustmentUpdates[idx].adjustmentAmount = null;
            this.reportingAssetBulkUpdateModel.costAdjustmentUpdates[idx].adjustmentPercentage = parseFloat(event.target.value) / 100;
            this.validateForm();
        }
    }

    onCostAdjustmentActionChange(action: Compliance.ReportingAssetBulkUpdateFieldActionEnum, stateId: number, costAdjustmentTypeId: number): void {
        const existingCostAdjustmentUpdate = this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.find(x => x.stateId === stateId && x.costAdjustmentTypeId === costAdjustmentTypeId);

        if (!existingCostAdjustmentUpdate) {
            const newCostAdjustmentUpdate: Compliance.ReportingAssetBulkUpdateCostAdjustmentModel = {
                stateId: stateId,
                costAdjustmentTypeId: costAdjustmentTypeId,
                fieldAction: action
            };

            this.reportingAssetBulkUpdateModel.costAdjustmentUpdates.push(newCostAdjustmentUpdate);
        } else {
            existingCostAdjustmentUpdate.fieldAction = action;
        }
    }

    onSiteBlur(): void {
        if (this.siteFilter.trim() === '' || this._siteNoResult) {
            this.siteFilter = '';
            this.reportingAssetBulkUpdateModel.siteId = null;
        }

        this.siteFieldEdited = true;
    }

    onSiteLoadingChange(isLoading: boolean): void {
        this.siteIsLoading = isLoading;
    }

    onSiteSelected(match: TypeaheadMatch): void {
        this.reportingAssetBulkUpdateModel.siteId = (match.item as ExtendedSiteModel).model.siteID;
        this.siteFilter = match.value;
    }

    onVerificationDateChanged(verificationDate: AssetLienDate) {
        this.reportingAssetBulkUpdateModel.assetVerificationDate = verificationDate.date;
    }

    private _focusOnFormElement(focus: string, firstInit?: boolean): void {
        if (!focus || this._closed) { return; }
        let target;
        switch (focus) {
            case 'r':
                this.reportingAssetBulkUpdateModel.classificationIdAction = Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo;
                target = '#selectAssetClass';
                break;
        }
        const element = target && this._renderer.selectRootElement(target);
        if (element) {
            this._timerService.setTimeout(() => {
                if (document.activeElement !== element && firstInit) {
                    element.disabled = false;
                    element.focus();
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } else if (document.activeElement === element) {
                    element.scrollIntoView({behavior: 'auto', block: 'center'});
                }
            }, 0);
        }
    }

    private async _bulkUpdate(force: boolean = false, overrideAllFields: boolean = false): Promise<void> {

        let warning: string = null;

        if (this.bulkUpdateMetadata.hasLockedAssets &&
            (this.reportingAssetBulkUpdateModel.isLockedAction !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo ||
                !this.reportingAssetBulkUpdateModel.isLocked)) {
            warning = 'Your selection contains locked assets. These assets were not updated.';
        }

        this._showBusyIndicator('Bulk update', 'Updating assets', null, false, false, warning);

        let confirmMessage: string = '';

        try {
            this.reportingAssetBulkUpdateModel.force = force;
            this.reportingAssetBulkUpdateModel.overrideAllFields = overrideAllFields;

            if (this.reportingAssetBulkUpdateModel.isLockedAction !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo ||
                this.reportingAssetBulkUpdateModel.isLockedAction === Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo &&
                    this.reportingAssetBulkUpdateModel.isLocked) {
                this.reportingAssetBulkUpdateModel.isLockedRemoveOverridesAction = undefined;
                this.reportingAssetBulkUpdateModel.isLockedRemoveOverrides = undefined;
            }

            const longRunningProcessId = await lastValueFrom(this._assetRepository
                .startBulkUpdate(this.params.companyId, this.reportingAssetBulkUpdateModel));

            await this._busyRef.setLongRunningProcessId(longRunningProcessId);
        } catch (e2) {
            await this._hideBusyIndicator();

            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }

        if (confirmMessage) {
            try {
                await this._messageModalService.confirm(confirmMessage,'Confirm Update');
            } catch (e3) {
                return Promise.resolve();
            }

            // force
            await this._bulkUpdate(true, overrideAllFields);
        }
    }
}
