import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GeneralReturnSettingLookupEnum, ReturnSettingsEntityTypeEnum, ReturnSettingsLookupType, ReturnSettingsSettingTypeEnum, ReturnSettingsViewModelEnum } from '../../../Models/enums';

@Component({
    selector: 'return-settings-entity-attribute',
    templateUrl: './returnSettingsEntityAttribute.component.html'
})
export class ReturnSettingsEntityAttributeComponent implements OnInit, OnChanges{
    @Input() lookupOptions: Compliance.GeneralReturnSettingLookupModel[];
    @Input() isTopLevelCompany: boolean;
    @Input() currentLookupId: string;
    @Input() parentLookupId: string;
    @Input() isReadonly: boolean;
    @Input() viewMode: ReturnSettingsViewModelEnum;
    @Input() currentValue: string;
    @Input() parentValue: string;
    @Input() currentSettings: Compliance.GeneralReturnSettingModel;
    @Input() parentSettings: Compliance.GeneralReturnSettingModel;
    @Input() lookupType: ReturnSettingsLookupType;
    @Input() settingType: ReturnSettingsSettingTypeEnum;
    @Input() entityType: ReturnSettingsEntityTypeEnum;
    @Input() contactName: string;
    @Input() optionSettingNameId?: GeneralReturnSettingLookupEnum;
    @Input() optionBlankId?: GeneralReturnSettingLookupEnum;

    @Output() changed: EventEmitter<string> = new EventEmitter<string>();

    ViewModelEnum = ReturnSettingsViewModelEnum;
    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    options: Compliance.GeneralReturnSettingLookupModel[];

    ngOnInit(): void {
        this.options = this.lookupOptions.map(i => ({... i}));
        this.renameSettingNameOption(this.contactName);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactName && changes.contactName.previousValue !== changes.contactName.currentValue && this.options) {
            this.renameSettingNameOption(changes.contactName.currentValue);
        }
    }

    private renameSettingNameOption(contactName: string): void{
        if (this.options) {
            const settingNameOption = this.options.find(i => i.lookupId === (+this.optionSettingNameId || 0).toLocaleString());
            if (settingNameOption){
                contactName = (contactName || '').trim();
                if (contactName === '[Blank]' || contactName === '' || !contactName || this.entityType === ReturnSettingsEntityTypeEnum.Return){
                    switch (this.settingType){
                        case ReturnSettingsSettingTypeEnum.OwnerContact:
                            contactName = 'Owner Contact';
                            break;
                        case ReturnSettingsSettingTypeEnum.AccountingContact:
                            contactName = 'Accounting Contact';
                            break;
                        case ReturnSettingsSettingTypeEnum.PreparerContact:
                            contactName = 'Preparer Contact';
                            break;
                        case ReturnSettingsSettingTypeEnum.SignerContact:
                            contactName = 'Signer Contact';
                            break;
                    }
                }

                settingNameOption.name = `${contactName}'s`;
            }
        }
    }

    private _hasChanges: boolean;

    get lookupHasOverride(): boolean{
        return !this.isTopLevelCompany && this.currentLookupId && this.currentLookupId !== this.parentLookupId;
    }

    get lookupId(): string {
        return (this.isTopLevelCompany || this.lookupHasOverride || this.entityType === ReturnSettingsEntityTypeEnum.Return) ? this.currentLookupId : this.parentLookupId;
    }

    set lookupId(value: string) {
        if (value === this.parentLookupId && this.entityType !== ReturnSettingsEntityTypeEnum.Return && !this.isTopLevelCompany){
            value = null;
        }

        this._hasChanges = true;
        this.currentLookupId = value;

        this.changed.emit(value);
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    get lookupValue(): string{
        return (this.isTopLevelCompany || this.lookupHasOverride || this.parentLookupId === (+this.optionSettingNameId || 0).toLocaleString()) ? this.currentValue : this.parentValue;
    }
}
