import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CommentTypeNames, EntityTypeIds, EntityTypeNames } from '../../constants.new';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { CommentModalData, CommentModalResult, CommentsService } from '../comments.service';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserInstanceService } from '../../User/userInstance.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { map, uniq, orderBy, filter, each, compact, sortBy, reverse, concat, uniqBy, reject } from 'lodash/fp';

export interface CommentsComponentModalParams {
    entityData: CommentModalData;
    availableCommentTypes: any[];
}

@Component({
    selector: 'comments-component',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, IWeissmanModalComponent<CommentsComponentModalParams, CommentModalResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _commentsService: CommentsService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _restrictService: RestrictService,
        private readonly _userInstanceService: UserInstanceService
    ) {}

    params: CommentsComponentModalParams;
    result: CommentModalResult;

    availableYears: string[] = [];
    years: string[] = [];
    commentTypes: any[] = [];
    allComments: any[] = [];
    commentList: any[] = [];
    entityTypes: any[] = [];
    unpinnedComments: any[] = [];
    updateComment: any;
    getAllCommentsDeferred: any;
    pinnedComment: any;
    entityData: CommentModalData;
    EntityTypeIds = EntityTypeIds;
    yearFilter: string = 'All';
    entityTypeFilter: any = {
        text: 'Comments',
        entityTypeID: null
    };
    childLabel = null;

    hasInstancePrivateView: boolean;
    isSingleRyanInstance: boolean;
    loading: boolean;
    editingComment: boolean;
    isNewComment: boolean;
    includeChildren: boolean;
    areCommentTypesAvailable: boolean;
    yearIsRelevant: boolean;
    // TODO: Figure this out based on filterTypeID
    isBelowParcelLevel: boolean;

    ngOnInit(): void {
        this.entityData = this.params.entityData;

        this.isSingleRyanInstance = this._userInstanceService.isCurrentInstanceRyan();
        this.yearIsRelevant = this._commentsService.yearIsRelevantForEntityTypeID(this.entityData.entityTypeID);

        // setup comment type dropdown
        this.areCommentTypesAvailable = this.params.availableCommentTypes && this.params.availableCommentTypes.length > 0;
        if (this.areCommentTypesAvailable) {
            this.commentTypes = [
                { id: null, name: '' }
            ].concat(this.params.availableCommentTypes.map(item => {
                return {
                    id: item.commentTypeID,
                    name: item.commentTypeName
                };
            }));
        }

        this.yearFilter = 'All';
        this.entityTypeFilter = {
            text: 'Comments',
            entityTypeID: null
        };

        // did we want to preselect a certain comment type?
        if (this.entityData.commentTypeID){
            this.entityTypeFilter.filterType = 'commentType' ;
            this.entityTypeFilter.id = this.entityData.commentTypeID;
        }

        this.isBelowParcelLevel = false;
        this.includeChildren = false;
        this.entityTypeChanged();
        this.editingComment = false;
        this.allComments = [];
        this.commentList = [];
        this.unpinnedComments = [];
        this.pinnedComment = {};
        this.entityTypes = [];
        this.years = [];

        this.loading = false;
        this._getAllComments();

        if (this.entityData.disableYearSelection) {
            this.yearFilter = this.entityData.year;
        }
    }

    displayDate(inputDate) {
        return moment(inputDate).fromNow();
    }

    // Button click handlers
    done(): void {
        if (this.editingComment) {
            this.cancelEdit();
            return;
        }

        const commentCount: number = this.entityData.disableCommentTypeSelection && this.entityData.commentTypeID
            ? this.commentList.filter(x => x.commentTypeID === this.entityData.commentTypeID).length
            : this.commentList.length;

        this.result = {
            commentCount: commentCount,
            pinnedComment: this.pinnedComment ? this.pinnedComment.description : null };
        this._bsModalRef.hide();
    }

    addComment(): void {
        this.editingComment = true;
        this.isNewComment = true;
        this.updateComment = {
            commentTypeID: null
        };

        if (this.entityData.disableCommentTypeSelection) {
            this.updateComment.commentTypeID = this.entityData.commentTypeID;
        }

        this._resolveInstancePrivate();
        this._populateYears();
    }

    editComment(comment): void {
        this.editingComment = true;
        this.isNewComment = false;
        this.updateComment = _.cloneDeep(comment);
        this._resolveInstancePrivate();
        this._populateYears();
    }

    submitEditForm(): void {
        if (this.isNewComment) {
            this._createComment();
        } else {
            this._saveEdit();
        }
    }

    async pinComment(comment): Promise<void> {
        await this._commentsService.pinComment(comment.commentID);
        this._getAllComments();
    }

    async unpinComment(comment): Promise<void> {
        await this._commentsService.unpinComment(comment.commentID);
        if (this.pinnedComment) {
            this.pinnedComment.isPinned = false;
            this.unpinnedComments.push(_.cloneDeep(this.pinnedComment));
            this.pinnedComment = null;
        }
    }

    async deleteComment(): Promise<void> {
        if (confirm(`Are you sure you want to delete the comment "${  this.updateComment.title  }"?`)) {
            await this._commentsService.deleteComment(this.updateComment.commentID);
            this.editingComment = false;
            this._getAllComments();
        }
    }

    cancelEdit(): void {
        const verb = this.isNewComment ? 'adding' : 'saving';

        if (confirm(`Are you sure you want to cancel ${  verb  } a comment?`)) {
            this.editingComment = false;
        }
    }

    yearDisabled(): boolean {
        return !!this.entityData.year ||
               this.updateComment.entityTypeID === EntityTypeIds.ASSESSMENT ||
               this.updateComment.entityTypeID === EntityTypeIds.APPEAL ||
               this.updateComment.entityTypeID === EntityTypeIds.TAX_BILL ||
               this.updateComment.entityTypeID === EntityTypeIds.REFUND;

    }
    // End of button click handlers

    yearChanged(): void {
        const filteredComments = this.allComments.filter(x => this._yearFilterPredicate(x));

        const allEntityTypeFilters = _.flow([
            uniqBy('entityTypeID'),
            map(comment => {
                return {
                    filterType: 'entityType',
                    id: comment.entityTypeID,
                    text: `${EntityTypeNames[comment.entityTypeID]  } Comments`
                };
            }),
            sortBy('text')
        ])(filteredComments);

        const allCommentTypeFilters = _.flow([
            uniqBy('commentTypeID'),
            reject({ 'commentTypeID': null }),
            map(comment => {
                return {
                    filterType: 'commentType',
                    id: comment.commentTypeID,
                    text: `${EntityTypeNames[this.entityData.entityTypeID]  } ${  CommentTypeNames[comment.commentTypeID]  } Comments`
                };
            }),
            sortBy('text')
        ])(filteredComments);

        // the "type" filter can originate from the "entity type" or the "comment type" for that entity type
        // currently presenting those two filters to the user under one dropdown, however using the "filterType" property on each filter to differentiate
        const allTypeFilters = _.flow([
            concat(allCommentTypeFilters),
            concat(allEntityTypeFilters)
        ])([]);

        // include the "Comments" filter if there are multiple "entityType" filters (because the user needs a way to view all comments across all different entity types)
        // we can ignore { filterType: "commentType" } filters as they will belong to the entity type of the entity that is being loaded
        // i.e. if we have the following three filters
        // - Site Comments (entity type Site)
        // - Site Budget Comments (entity type Site, comment type Budget)
        // - Site PSR Comments (entity type Site, comment type PSR)
        // we do not need to include the "Comments" filter as the "Site Comments" one will serve as that
        // with the current implementation when an "entityType" filter is selected, we return all comments that have or don't have a "commentType" filter for that entity type
        this.entityTypes = allEntityTypeFilters.length === 1
            ? allTypeFilters
            : [{ text: ' Comments', id: null, filterType: null }].concat(allTypeFilters);

        // if the existing entity type filter is not part of the new entity types automatically update it
        this.entityTypeFilter =
            (      this.entityTypeFilter
                   && this.entityTypes.find(x => x.id === this.entityTypeFilter.id && x.filterType === this.entityTypeFilter.filterType)
            )
            || this.entityTypes[0];

        this.entityTypeChanged();
    }

    entityTypeChanged(): void {
        this.pinnedComment = null;

        this.commentList = _.flow([
            filter(x => this._yearFilterPredicate(x) && this._typeFilterPredicate(x)),
            each(x => x.active = false),
            each(x => this._setIsPinnableCommentAttribute(x))
        ])(this.allComments);

        this.years = _.flow([
            map('year'),
                compact,
                uniq,
                sortBy([]),
                reverse
        ])(this.allComments);

        this.years.unshift('All');

        this._formatPinnedComments(this.commentList);
    }

    async includeChildrenChanged(): Promise<void> {
        if (this.getAllCommentsDeferred) {
            // Wait for any previous calls to finish (maybe we should try to cancel the old request?)
            await this.getAllCommentsDeferred();
        }
        this._getAllComments();
    }

    async loadParentComments(): Promise<void> {
        this.entityData = {
            description: `Parcel: ${  this.entityData.parcelAcctNum}`,
            entityID: this.entityData.parcelID,
            entityTypeID: EntityTypeIds.PARCEL,
            canEdit: this.entityData.canEdit
        };

        await this._getAllComments();

        this._entityTypeChanged();
    }

    commentTrackBy(index: number, comment: CommentModalData): number {
        return comment.commentID;
    }

    private _resolveInstancePrivate() {
        lastValueFrom(this._instanceRepository.getEntityIdPairInstanceId(this.entityData.entityTypeID, this.entityData.entityID))
            .then(instanceId => {
                              this.hasInstancePrivateView = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, instanceId)
                                                          || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, instanceId);
                          });
    }

    private _saveEdit() {
        this._commentsService.editComment(this.updateComment).then(() => {
            this.editingComment = false;
            this._getAllComments();
        });
    }

    private _createComment() {
        this.updateComment.entityTypeID = this.entityData.entityTypeID;
        this.updateComment.entityID = this.entityData.entityID;
        this._commentsService.createComment(this.updateComment).then(() => {
            this.editingComment = false;
            this._getAllComments();
        });
    }

    private _prepareComments(commentList: any[]): any[] {
        return commentList.map(comment => {
            const isSubEntity = [EntityTypeIds.TAX_BILL, EntityTypeIds.ASSESSMENT, EntityTypeIds.APPEAL, EntityTypeIds.REFUND].indexOf(comment.entityTypeID) >= 0;
            if (this.includeChildren) {
                if (this.entityData.entityTypeID === EntityTypeIds.SITE && isSubEntity) {
                    comment.lineOne = comment.parentEntityName;
                    comment.lineTwo = comment.entityName;
                    comment.lineThree = comment.title;
                } else {
                    comment.lineOne = comment.entityName;
                    comment.lineTwo = comment.title;
                    comment.lineThree = null;
                }
            } else {
                if (this.entityData.entityTypeID === EntityTypeIds.PARCEL && isSubEntity) {
                    comment.lineOne = comment.entityName;
                    comment.lineTwo = comment.title;
                    comment.lineThree = null;
                } else {
                    comment.lineOne = comment.title;
                    comment.lineTwo = null;
                    comment.lineThree = null;
                }
            }
            if (comment.changeDate.getYear() > 1) {
                comment.sortDate = comment.changeDate;
            } else {
                comment.sortDate = comment.createDate;
            }
            comment.isOverLimit = false;
            comment.showMore = false;
            return comment;
        });
    }

    private _formatPinnedComments(commentList: CommentModalData[]): void {
        if (this.entityTypeFilter && this.entityTypeFilter.filterType === 'commentType') {
            this.pinnedComment = commentList.find(x =>  x.isPinned && x.commentTypeID === this.entityTypeFilter.id);
        } else {
            this.pinnedComment = commentList.find(x => x.isPinned && x.commentTypeID === null);
        }

        const unpinned = _.without(commentList, this.pinnedComment);

        this.unpinnedComments = _.orderBy(unpinned, ['sortDate'], ['desc']);
    }

    private _prepareYears(): string[] {
        return _.flow([
            map(x => x.year ? x.year.toString() : null),
            uniq,
            orderBy(year => year, ['desc'])
        ])(this.allComments);
    }

    private async _getAllComments(): Promise<void> {
        this.loading = true;
        // this.includeChildren represents the state of the checkbox, and it defaults to false for most cases since things like states
        // or contacts do not have children. There is one strange example; when viewing parcel comments, we always display children
        // but do not show the user a checkbox. For that case, we should submit a true value to the API for that property.

        try {
            let includeChildren = this.includeChildren;
            if (this.entityData.entityTypeID === EntityTypeIds.PARCEL) {
                includeChildren = true;
            }
            const commentList = await this._commentsService.fetch({
                entityTypeID: this.entityData.entityTypeID,
                entityID: this.entityData.entityID,
                commentType: 'All',
                year: 'All',
                includeChildren: includeChildren
            });

            this.allComments = this._prepareComments(commentList);

            const allYears = this._prepareYears();

            // not all comments will have the year property populated
            const indexOfNullYear = allYears.indexOf(null);
            if (indexOfNullYear !== -1) {
                // remove null year
                allYears.splice(indexOfNullYear, 1);
            }

            // if only one year and no NULL year then default to that and don't show the "All" option
            this.years = (allYears.length === 1 && indexOfNullYear === -1) ? allYears : ['All'].concat(allYears);

            // if the existing year filter is not part of the new years automatically update it
            this.yearFilter = (this.yearFilter && this.years.find(y => y === this.yearFilter)) || this.years[0];

            this.yearChanged();
        } finally {
            this.loading = false;
        }

    }

    private _yearFilterPredicate(comment) {
        return this.yearFilter === 'All' || (comment.year !== null && this.yearFilter.toString() === comment.year.toString());
    }

    private _typeFilterPredicate(comment) {
        // when an entityFilter is selected (and not a commentType filter)
        // we must show all comments for that entity type and not leave out the ones that might have a commentTypeID
        return (this.entityTypeFilter.filterType === null) ||
               (this.entityTypeFilter.filterType === 'entityType' && this.entityTypeFilter.id === comment.entityTypeID) ||
               (this.entityTypeFilter.filterType === 'commentType' && this.entityTypeFilter.id === comment.commentTypeID);
    }

    private _setIsPinnableCommentAttribute(comment) {
        // a comment is pinnable
        // if it belongs to the entity type and entity type ID for the entity that the comments are being shown AND
        //   if we're not filtering by comment type then it must not belong to a comment type (commentTypeID is null) OR
        //   if we are filtering by comment type then the ID must match that of the filter
        comment.isPinnable =
            comment.entityTypeID === this.entityData.entityTypeID &&
            comment.entityID === this.entityData.entityID && (
                ((this.entityTypeFilter.filterType === null || this.entityTypeFilter.filterType === 'entityType') && !comment.commentTypeID) ||
                (this.entityTypeFilter.filterType === 'commentType' && this.entityTypeFilter.id === comment.commentTypeID)
            );
    }

    private _entityTypeChanged() {
        this.isBelowParcelLevel = this.entityData.parcelAcctNum && this.entityData.entityTypeID !== EntityTypeIds.PARCEL;
        switch (this.entityData.entityTypeID) {
            case EntityTypeIds.COMPANY:
                this.childLabel = 'Sites';
                break;
            case EntityTypeIds.SITE:
                this.childLabel = 'Parcels';
                break;
            default:
                this.childLabel = null;
                break;
        }
    }

    private _populateYears(): void {
        if (this.entityData.year) {
            this.availableYears = [this.entityData.year];

            this.updateComment.year = this.entityData.year;
        } else {
            this.availableYears = [];
            const now = new Date();
            const currentYear = now.getFullYear();

            for (let i = currentYear + 3; i >= currentYear - 10; i--) {
                this.availableYears.push(`${i}`);
            }
        }
    }
}
