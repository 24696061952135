<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0">
</ws-busy-indicator>

<ws-modal-wrapper
    headerLabel="Document Intake & Processing Services"
    acceptLabel="Save"
    (save)="save()"
    (cancel)="cancel()"
    [disableSave]="!canSave"
    [useAceStyles]="true">
    <div *ngIf="clientDetail">
        <div class="row">
            <div class="col-md-4 field-label">
                Instance:
            </div>
            <div class="col-md-8">
                {{clientDetail.instanceName}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                Client Contact:
            </div>
            <div class="col-md-8">
                {{clientContactName}}
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                Special Instructions:
            </div>
            <div class="col-md-8">
                {{clientDetail.clientSpecialInstructions}}
            </div>
        </div>
        <div class="row"></div>
        <div class="row">
            <div class="col-md-8 field-label">
                Ryan Assignments
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                User Group:
            </div>
            <div class="col-md-8">
                <select class="form-select form-select-sm" [(ngModel)]="clientDetail.ryanUserGroupId">
                    <option *ngFor="let group of groups" [ngValue]="group.groupID">{{group.name}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                Ultimately Resp:
            </div>
            <div class="col-md-8">
                <user-team-picker [parentUsers]="users" [(user)]="clientDetail.ryanUltimatelyResp" (userChange)="ultimatelyResponsibleChosen($event)" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                Assigned Entry:
            </div>
            <div class="col-md-8">
                <user-team-picker [parentUsers]="users" [(user)]="clientDetail.ryanAssignedEntry" (userChange)="assignedEntryChosen($event)" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 field-label">
                Assigned QC:
            </div>
            <div class="col-md-8">
                <user-team-picker [parentUsers]="users" [(user)]="clientDetail.ryanAssignedQC" (userChange)="assignedQCChosen($event)" [required]="true" [showInvalidState]="saveAttempted"></user-team-picker>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
