import { Pipe, PipeTransform } from '@angular/core';
import { ComponentFilter } from "../annual-details.service";
import { AnnualAssessmentDetail } from "../Assessments/assessment.model";

@Pipe({
    name: 'componentFilter',
    pure: false
})
export class AssessmentComponentExemptionPipe implements PipeTransform {
    transform(items: AnnualAssessmentDetail[], filterType: ComponentFilter): AnnualAssessmentDetail[] {
        if (!items || filterType === undefined) {
            return items;
        }

        switch (filterType as ComponentFilter) {
            case ComponentFilter.exempt:
                return items.filter(item => item.assessmentComponentTypeID !== 3 && item.assessmentComponentTypeID !== 2 && item.efAction !== 'delete');
            case ComponentFilter.nonexempt:
                return items.filter(item => item.assessmentComponentTypeID === 2 && item.efAction !== 'delete');
            case ComponentFilter.alternative:
                return items.filter(item => item.assessmentComponentTypeID === 3 && item.efAction !== 'delete');
            default:
                return items;
        }
    }
}