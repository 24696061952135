import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AccrualsBulkUpdateModalComponent } from './accruals.bulk.update.modal.component';
import { AccrualsPageComponent } from './accruals.page.component';
import { AccrualsAdjustmentsListComponent } from './Balance-Adjustments/accrualsAdjustmentsList.component';
import { AccrualsBalancesListComponent } from './Balance-Adjustments/accrualsBalancesList.component';
import {
    AccrualsAdjustmentsGridActionCellRendererComponent
} from './Balance-Adjustments/agGridActionCellRendererComponent';
import { BalanceAdjustmentsService } from './Balance-Adjustments/balanceAdjustments.service';

@NgModule({
    imports: [
        WeissmanSharedModule
    ],
    declarations: [
        AccrualsPageComponent,
        AccrualsBulkUpdateModalComponent,
        AccrualsAdjustmentsListComponent,
        AccrualsBalancesListComponent,
        AccrualsAdjustmentsListComponent,
        AccrualsAdjustmentsGridActionCellRendererComponent
    ],
    providers: [BalanceAdjustmentsService],
    exports: [
        AccrualsPageComponent,
        AccrualsAdjustmentsListComponent,
        AccrualsBalancesListComponent
    ]
})
export class AccrualsModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('accrualsPage', AccrualsPageComponent);
    }
}
