(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('StyleModalsController', StyleModalsController);

	StyleModalsController.$inject = [];

	function StyleModalsController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit() {
		var vm = this;
		vm.title = 'StyleModalsController';

		vm.items = ['item1', 'item2', 'item3'];

		vm.colors = ['red', 'blue', 'steel', 'taupe', 'gold'];

		vm.selected = {
			item: vm.items[0],
			color: vm.colors[0]
		};

		activate();

		vm.getColor = function () {
			return vm.selected.color + '-header';
		}

		////////////////

		function activate() {}
	}
})();
