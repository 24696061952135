import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
    AgGridTooltipCellRendererParams
} from '../../../../Compliance/AgGrid/CellRenderers/agGridTooltipCellRenderer.component';

@Component({
    selector: 'activity-status-cell-renderer',
    template: `
        <img *ngIf="isInactive"
             src='/images/inactive.jpg'
             alt='Inactive'
             class='status-img' />
        <img *ngIf="isActivePending"
             src='/images/active-pending.jpg'
             alt='Active (Pending)'
             class='status-img' />
    `,
    styles: [`
        .status-img {
            height: 20px;
            width: auto;
            margin-top: 2px;
            transform: rotate(0deg);
            right: unset;
        }
    `]
})
export class ActivityStatusCellRendererComponent implements ICellRendererAngularComp {

    params: AgGridTooltipCellRendererParams;
    isInactive: boolean;
    isActivePending: boolean;

    agInit(params: AgGridTooltipCellRendererParams): void {
        this.params = params;
        this.isInactive = params.value.toLowerCase() === 'inactive';
        this.isActivePending = params.value.toLowerCase() === 'active (pending)';
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
