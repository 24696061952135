import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { List } from 'lodash';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

@Component({
    selector: 'form-year-copy-list',
    templateUrl: './formYearCopyList.component.html'
})
export class FormYearCopyListComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, number> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formService: FormService) {
    }

    params: void;
    result: number;

    formRevisionYears: Compliance.FormRevisionYearCopyModel[];
    selectedFormRevisionYear: Compliance.FormRevisionYearCopyModel;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    selectedFormRevisionName: string;
    selectedFormRevisionTaxYear: number;
    
    get taxYear(): number {
        return this._formService.taxYear;
    }

    get allAvailableTaxYearsForFormRevision(): number[] {
        if (this.selectedFormRevisionName){
            return [...new Set(this.formRevisionYears.filter( x => x.formRevisionName === this.selectedFormRevisionName).map(x => x.formRevisionTaxYear))];
        }
        
        return null;
    }

    get allAvailableFormRevisionNames(): string[] {
        if (this.formRevisionYears != null){
            return [...new Set(this.formRevisionYears.map(x => x.formRevisionName))];
        }
        
        return null;
    }

    get isSaveEnabled(): boolean {
        return this.selectedFormRevisionName != null && 
            this.selectedFormRevisionName.length > 0 &&
            this.selectedFormRevisionTaxYear != null &&
            this.selectedFormRevisionTaxYear !== 0;
    }

    async ngOnInit(): Promise<void> {
        const busyMsgLoading = 'loading';

        this.busyIndicatorMessageManager.add('Loading', busyMsgLoading);

        try {
            this.formRevisionYears = await this._formService.getByAvailableToCopyTo();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgLoading);
        }
    }

    ngOnDestroy(): void { }

    cancel(): void {
        this._bsModalRef.hide();
    }

    save(): void {
        if (this.selectedFormRevisionName && this.selectedFormRevisionTaxYear){
            this.selectedFormRevisionYear = this.formRevisionYears.filter(x => x.formRevisionName === this.selectedFormRevisionName && x.formRevisionTaxYear === this.selectedFormRevisionTaxYear)[0];
        }

        this.result = this.selectedFormRevisionYear && this.selectedFormRevisionYear.formRevisionYearId;
        this._bsModalRef.hide();
    }
}
