<div class="ws-switch" [ngClass]="{ 'normal': size === 'normal' }" [class.disabled]="disabled || !active" [title]="title || ''">
    <div class="switch-wrapper" [ngStyle]="{ 'width': switchWrapper }">
        <div class="switch-container"
             [ngStyle]="{ 'min-width': switchContainer, 'transform': 'translateX(' + ((value) ? '0' : ('-' + toggleWidth) + 'px') + ')' }"
             (click)="toggleSwitch()">

            <span #optionOne class="switch-element"
                  [ngStyle]="{ 'min-width': toggleWidth + 'px', 'background-color': onColor, 'color': onTextColor }">{{onLabel}}</span>

            <span class="switch-element switch-blank"
                [ngStyle]="{ 'background-color': onColor }">&nbsp;</span>

            <span #optionTwo class="switch-element"
                  [ngStyle]="{ 'min-width': toggleWidth + 'px', 'background-color': offColor, 'color': offTextColor }">{{offLabel}}</span>

            <input class="hidden-checkbox" type="checkbox" />
        </div>
    </div>
    <span class="switch-toggle" [ngStyle]="{ 'transform': 'translateX(' + ((value) ? toggleWidth + 'px' : '0') + ')' }" (click)="toggleSwitch()">&nbsp;</span>
</div>
