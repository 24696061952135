import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom, Observable, of, Subject, Subscriber } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { EntityPropertyCharacteristicsService } from '../Entity-Property-Characteristics/entity.property.characteristics.service';
import { DescriptorService } from '../descriptor.service';
import { DescriptorEntityType, EntityDescriptorModelUI, TypeaheadDescriptorDTO } from '../descriptor.model';

import { groupBy, map as _map, toArray, sortBy } from 'lodash/fp';
import * as _ from 'lodash';

@Component({
    selector: 'property-characteristics-picker',
    templateUrl: './property.characteristics.picker.component.html'
})
export class PropertyCharacteristicsPickerComponent implements OnInit {
    constructor(private readonly _entityDescriptorService: EntityPropertyCharacteristicsService,
                private readonly _descriptorService: DescriptorService,
                private readonly _modalService: BsModalService) { }

    @Input() entityId: number;
    @Input() entityName: DescriptorEntityType;
    @Input() descriptorsToExclude: EntityDescriptorModelUI[];
    @Input() disabled: boolean = false;
    @Output() descriptorsSelected: EventEmitter<Core.DescriptorDTO[]> = new EventEmitter();
    typeaheadLoading: boolean;
    selectedDescriptorName: string;
    dataSource: Observable<TypeaheadDescriptorDTO[]>;
    descriptorsToAdd: Core.DescriptorDTO[] = [];
    descriptorCategories: any[];
    private _browseModalRef: BsModalRef;

    async ngOnInit() {
        this.dataSource = new Observable((observer: Subscriber<string>) => observer.next(this.selectedDescriptorName))
            .pipe(
                mergeMap((token) => {
                    return this.searchAvailable({
                        filter: token,
                        descriptorIdsToExclude: _.map(this.descriptorsToExclude, 'descriptorID'),
                        pageSize: 10
                    });
                }),
                map((descriptors: Core.DescriptorDTO[]) => {
                    return _.map(descriptors, x => {
                        return {
                            ...x,
                            typeaheadField: `${x.categoryName}: ${x.name}`
                        } as TypeaheadDescriptorDTO;
                    });
                })
            );
    }

    descriptorSelected(descriptor: Core.DescriptorDTO) {
        this.descriptorsSelected.emit([descriptor]);
        this.selectedDescriptorName = '';
    }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

    async openBrowseModal(template: TemplateRef<any>): Promise<void> {
        this._browseModalRef = this._modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });

        const searchModel = {
            filter: '',
            descriptorIdsToExclude: _.map(this.descriptorsToExclude, 'descriptorID'),
            pageSize: null
        };
        const availableDescriptors = await lastValueFrom(this.searchAvailable(searchModel));
        this.descriptorCategories = _.flow([
            groupBy('categoryID'),
            toArray,
            _map((descriptors: Core.DescriptorDTO[]) => {
                return {
                    name: descriptors[0].categoryName,
                    categoryId: descriptors[0].categoryID,
                    descriptors: descriptors,
                    checkedCount: 0
                };
            }),
            sortBy('name')
        ])(availableDescriptors);
    }

    descriptorClicked(descriptor: Core.DescriptorDTO, category: any, e): void {
        const categoryIdx = _.findIndex(this.descriptorCategories, {categoryId: category.categoryId});

        if (e.target.checked) {
            this.descriptorsToAdd.push(descriptor);
            this.descriptorCategories[categoryIdx].checkedCount++;
        } else {
            _.pull(this.descriptorsToAdd, descriptor);
            this.descriptorCategories[categoryIdx].checkedCount--;
        }
    }

    isDescriptorChecked(descriptor: Core.DescriptorDTO): boolean {
        return _.some(this.descriptorsToAdd, { descriptorID: descriptor.descriptorID });
    }

    clearSelection(): void {
        this.descriptorsToAdd = [];

        this.descriptorCategories = _.map(this.descriptorCategories, x => {
            x.checkedCount = 0;
            return x;
        });
    }

    addDescriptors(): void {
        this.descriptorsSelected.emit(this.descriptorsToAdd);
        this.closeModal();
    }

    closeModal(): void {
        this.descriptorsToAdd = [];
        this._browseModalRef.hide();
    }

    searchAvailable(searchModel: Core.EntityDescriptorSearchModel): Observable<Core.DescriptorDTO[]> {
        if(this.entityName == 'company') {
            return this._descriptorService.searchAvailableOverrides(this.entityId, searchModel);
        } else {
            return this._entityDescriptorService.searchAvailable(this.entityId, this.entityName, searchModel);
        }
    }

}
