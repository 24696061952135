import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';
import { QueryInfoRepository } from '../queryInfo.repository';

export class QueryInfoListAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        readonly gridApi: GridApi,
        private readonly _queryInfoRepository: QueryInfoRepository
    ) { super(gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Core.QueryInfoPropertyEnum>[] = [
        { name: 'id', value: Core.QueryInfoPropertyEnum.QueryInfoID },
        { name: 'queryTypeName', value: Core.QueryInfoPropertyEnum.QueryTypeName },
        { name: 'createDate', value: Core.QueryInfoPropertyEnum.CreateDate },
        { name: 'queryName', value: Core.QueryInfoPropertyEnum.QueryName },
        { name: 'contactName', value: Core.QueryInfoPropertyEnum.ContactName },
        { name: 'totalElapsedTimeMs', value: Core.QueryInfoPropertyEnum.TotalElapsedTimeMs },
        { name: 'queryText', value: Core.QueryInfoPropertyEnum.QueryText },
        { name: 'count', value: Core.QueryInfoPropertyEnum.Count },
        { name: 'savedSearchID', value: Core.QueryInfoPropertyEnum.SavedSearchID }
    ];

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.QueryInfoSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._queryInfoRepository.getList(searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
