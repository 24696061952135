import { Component, OnInit, OnDestroy } from '@angular/core';
import { of, pairwise, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../../../../Busy-Indicator';
import { ReturnUpdateLogicService } from '../../../../returnUpdateLogic.service';
import { ReturnAssetsService, AssessorFactorsFilter, AssessorFactorsEnum } from '../../returnAssets.service';
import { IReturnPartComponent } from '../../../../Models/returnPartServiceBase';

@Component({
    selector: 'return-asset-form-factor-selector',
    templateUrl: './returnAssetFormFactorSelector.component.html'
})
export class ReturnAssetFormFactorSelectorComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) {
    }

    assessorFilters: Compliance.FormRevisionAssessorModel[] = [];
    selectedAssessorFilter: Compliance.FormRevisionAssessorModel;

    private readonly _ANY_ASSESSOR_OPTION: Compliance.FormRevisionAssessorModel = {
        assessorName: 'Any Assessor',
        assessorId: AssessorFactorsEnum.AnyAssessor,
        isMappingCertified: null,
        isUsingDefaultMappings: null,
        isUsingDefaultTables: null
    } as Compliance.FormRevisionAssessorModel;

    private readonly _DEFAULT_ASSESSORS_OPTION: Compliance.FormRevisionAssessorModel = {
        assessorName: 'State-Provided Factor Tables',
        assessorId: AssessorFactorsEnum.AssessorsUsingDefaultFactors,
        isMappingCertified: null,
        isUsingDefaultMappings: null,
        isUsingDefaultTables: null
    } as Compliance.FormRevisionAssessorModel;

    // keep track of all the assessors for the selected form revision
    private _selectedFormRevisionAssessors: Compliance.FormRevisionAssessorModel[] = [];

    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._returnAssetsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._returnAssetsService.unsubscribeFromServiceActivationCycle(this);
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnUpdateLogicService.returnAssessors$.pipe(
            takeUntil(this._destroy$),
            pairwise(),
            switchMap(([prev, current]) => {
                return of((prev === current)
                    ? this._autoSelectAssessorFilter()
                    : this._refreshAssessors(current));
            })).subscribe();

        this._returnUpdateLogicService.loading$.pipe(takeUntil(this._destroy$))
            .subscribe((loading) => {
                if (loading) {
                    this._busyRef = this._busyIndicatorService.show({ message: 'Loading Factors' });
                } else {
                    if (this._busyRef) {
                        this._busyRef.hide();
                    }
                }
            });

        await this._returnUpdateLogicService.refreshAssessors();
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
        if (this._busyRef) {
            this._busyRef.hide();
        }
    }

    onSelectedAssessorFilterChange(): void {
        const filter = this._getAssessorFactorsFilterFromSelectedAssessor();
        this._returnAssetsService.setAssessorFactorsFilter(filter.assessorFactor, filter.assessorIds);
    }

    private _refreshAssessors(assessors:  Compliance.FormRevisionAssessorModel[]): void {
        this.selectedAssessorFilter = this._ANY_ASSESSOR_OPTION;

        if (!assessors?.length) {
            this._selectedFormRevisionAssessors = [];
            this.assessorFilters = [];
        }

        // the api brings back the FRYA record for the default assessor
        // we do not care about it here so ignoring it
        this._selectedFormRevisionAssessors = assessors.filter(x => x.assessorId !== null);

        // reset assessor filters to empty array; the following logic will populate them
        this.assessorFilters = [];

        // if no assessor then select "Any" as the default option
        // if there are assessors then check how many
        // option 1: there is only one assessor so just make available that one assessor
        //   if that assessor uses default tables then only make available the "Default assessors" option
        // option 2: there are multiple assessors then:
        //   if there is more than one assessor that uses custom tables or one with custom tables and any assessor with default make available the "Any" option
        //   make available any assessors that use custom tables
        //   if there are assessors using default tables then make available the "Default assessors" options
        if (this._selectedFormRevisionAssessors.length) {
            if (this._selectedFormRevisionAssessors.length > 1) {
                // multiple assessors, examine the collection
                const assessorsThatUseDefaultTables = this._selectedFormRevisionAssessors.filter(x => x.isUsingDefaultTables);
                const assessorsThatUseCustomTables = this._selectedFormRevisionAssessors.filter(x => !x.isUsingDefaultTables);

                // if we have more than one assessor that uses custom tables or just one but a mix of assessors that use default tables, expose "Any"
                if (assessorsThatUseCustomTables.length > 1 || (assessorsThatUseCustomTables.length === 1 && assessorsThatUseDefaultTables.length)) {
                    this.assessorFilters = this.assessorFilters.concat(this._ANY_ASSESSOR_OPTION);
                }

                // always show assessors that use custom tables
                this.assessorFilters = this.assessorFilters.concat(assessorsThatUseCustomTables);

                // if any assessor use default tables, show the default tables option
                if (assessorsThatUseDefaultTables.length) {
                    this.assessorFilters = this.assessorFilters.concat(this._DEFAULT_ASSESSORS_OPTION);
                }
            } else {
                // only one assessor; if using default tables show the default tables option, otherwise show the actual assessor
                this.assessorFilters = this._selectedFormRevisionAssessors[0].isUsingDefaultTables ?
                    this.assessorFilters.concat(this._DEFAULT_ASSESSORS_OPTION) :
                    this.assessorFilters.concat(this._selectedFormRevisionAssessors);
            }
        } else {
            // no assessors, default "Any"
            this.assessorFilters = this.assessorFilters.concat(this._ANY_ASSESSOR_OPTION);
        }

        const filter = this._returnAssetsService.sharedState.assessorFactorsFilter;
        const assessor = filter &&
            this.assessorFilters.find(x => x.assessorId === ((filter.assessorFactor < 0) ? filter.assessorFactor : filter.assessorIds[0]));

        this.selectedAssessorFilter = assessor || this.assessorFilters[0];

        this._autoSelectAssessorFilter();
    }

    private _autoSelectAssessorFilter(): void {
        if (this.selectedAssessorFilter) {
            this.onSelectedAssessorFilterChange();
        }
    }

    private _getAssessorFactorsFilterFromSelectedAssessor(): AssessorFactorsFilter {
        switch (this.selectedAssessorFilter.assessorId) {
            case AssessorFactorsEnum.AnyAssessor:
                return {
                    assessorFactor: AssessorFactorsEnum.AnyAssessor,
                    assessorIds: []
                };

            case AssessorFactorsEnum.AssessorsUsingDefaultFactors:
                return {
                    assessorFactor: AssessorFactorsEnum.AssessorsUsingDefaultFactors,
                    assessorIds: this._selectedFormRevisionAssessors.filter(x => x.isUsingDefaultTables).map(x => x.assessorId)
                };

            default:
                return {
                    assessorFactor: AssessorFactorsEnum.SpecificAssessor,
                    assessorIds: [this.selectedAssessorFilter.assessorId]
                };
        }
    }
}
