<form class="site-info-panel" name="siteForm">
    <div class="card panel-flat-color primary-panel">
        <div class="panel-flat-header">
            <h3>Site Info</h3>
            <div class="float-end header-button-container">
                <span *ngIf="showSpinner">
                    <i class="fa fa-spinner fa-spin"></i> Processing...
                </span>
                <button *ngIf="(!showSpinner) && hasInstancePrivatePermission"
                    type="button"
                    helpTooltip
                    helpContentId="site-info-panel.change-history"
                    class="btn flat-button icon-button primary-button"
                    (click)="launchChangeHistoryModal()">
                        <i class="fa fa-history"></i>
                </button>
                <button *ngIf="!showSpinner && allowUserToEditEntity && !editMode"
                    type="button"
                    helpTooltip
                    helpContentId="app.edit"
                    class="btn flat-button icon-button primary-button"
                    (click)="startEditMode()" >
                        <i class="fa fa-pencil"></i>
                </button>
                <span *ngIf="editMode">
                    <button type="submit" class="btn flat-button icon-button success-button" (click)="saveSite()">
                        <i class="fa fa-save"></i>
                    </button>
                    <button type="button" class="btn flat-button icon-button warning-button" (click)="cancelEditMode()">
                        <i class="fa fa-close"></i>
                    </button>
                </span>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="panel-flat-body">
            <loading-spinner [show]="!site"></loading-spinner>
            <div *ngIf="site && siteForm">
                <div class="float-start d-inline-block form-inline" [formGroup]="siteForm">
                    <ws-stamp [dataValue]="site"
                              [visible]="editMode"
                              [activityStatusId]="site.activityStatusID"
                              [transaction]="site.transaction">
                    </ws-stamp>
                    <div>
                        <h3 *ngIf="!editMode" style="margin: 0; max-width: initial;">{{site.name}}</h3>
                        <div *ngIf="editMode">
                            <span class="title">Site Name: </span>
                            <input formControlName="name" class="form-control" type="text" name="name" maxlength="100" style="width: 288px;" required />
                        </div>
                    </div>
                    <div formGroupName="address">
                        <div>
                            <span *ngIf="!editMode">{{site.address.address1}}</span>
                            <div *ngIf="editMode">
                                <span class="title">Address:</span>
                                <input formControlName="address1" class="form-control" type="text" name="address1" maxlength="50" style="width: 288px;" required />
                            </div>
                        </div>
                        <div>
                            <span *ngIf="!editMode && site.address.address2" style="padding-top: 0; display: inherit;">{{site.address.address2}}</span>
                            <div *ngIf="editMode">
                                <span class="title"></span>
                                <input formControlName="address2" class="form-control" type="text" name="address2" maxlength="50" style="width: 288px;"/>
                            </div>
                        </div>
                        <div>
                            <span *ngIf="!editMode">{{site.address.city}}, {{getState(site.address.stateID)}} {{site.address.zip}}</span>
                            <div *ngIf="editMode" style="display: flex; align-items: center;">
                                <span class="title"></span>
                                <input formControlName="city" class="form-control" style="width: 145px; vertical-align: top;" type="text" name="city" maxlength="50" required />
                                <span *ngIf="hasParcels()" style="margin: 0 5px;">{{getState(site.address.stateID)}}</span>
                                <ws-select *ngIf="!hasParcels()" formControlName="stateID" [options]="states" labelProperty="abbr" valueProperty="stateID" style="vertical-align: top;"></ws-select>
                                <input formControlName="zip" class="form-control" style="width: 58px; vertical-align: top;" type="text" name="zip" maxlength="10" required />
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="title" *ngIf="site.property || editMode">Site Number: </span>
                        <span class="editable">
                            <span *ngIf="site.property && !editMode">{{site.property}}</span>
                            <input *ngIf="editMode" formControlName="property" class="form-control" type="text" name="property" maxlength="100" style="width: 288px;" required />
                        </span>
                    </div>
                    <div *ngIf="!editMode">
                        <span class="title">Assessor<span *ngIf="site.assessors && site.assessors.length > 1">s</span>:</span>
                        <span class="editable">
                            <span *ngFor="let assessor of site.assessors; let last = last">
                                <a class="clickable"
                                   helpTooltip
                                   position="right"
                                   [contentComponent]="customRenderer"
                                   [contentComponentValue]="assessor"
                                   (click)="goToAssessor(assessor)">{{assessor.abbr}}  {{assessor.municipalityName}}</a>
                                <span *ngIf="!last">,</span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <span class="title">Site Class: </span>
                        <span class="editable">
                            <span *ngIf="!editMode">{{getSiteClassName(site.siteClassSecondaryID)}}</span>
                            <ws-select *ngIf="editMode" formControlName="siteClassSecondaryID" [options]="siteClasses" labelProperty="name" valueProperty="siteClassSecondaryID"></ws-select>
                        </span>
                    </div>
                    <div formGroupName="accrualsExclude" *ngIf="site.accrualsEnabled">
                        <div *ngIf="site.accrualsExclude.parentExcludedFromAccruals">
                            <span class="title">Parent Excluded From Accruals</span>
                        </div>
                        <div class="editable" *ngIf="!site.accrualsExclude.parentExcludedFromAccruals">
                            <label>
                                <input type="checkbox" formControlName="excludeFromAccruals">
                                <span style="margin-left: 2px;">Exclude From Accruals</span>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="editMode">
                        <span class="title">Status: </span>
                        <ws-select formControlName="activityStatusID" [options]="activityStatuses" labelProperty="status" valueProperty="activityStatusId" [popover]="activatedByPopover" triggers="mouseenter:mouseleave"></ws-select>
                    </div>
                    <!-- Sold Date -->
                    <div *ngIf="editMode">
                        <span class="title">Sold Date: </span>
                        <weissman-datetime [dateOnly]="true"
                                           [inputDate]="$any(siteForm).controls.transaction.controls.soldDate.value"
                                           (inputDateChange)="onSoldDateChanged($event)">
                        </weissman-datetime>
                    </div>
                    <div *ngIf="!editMode">
                        <linked-sites [site]="site" [masterSiteId]="site.masterSiteId" [sites]="site.linkedSites"></linked-sites>
                    </div>
                    <div *ngIf="editMode" style="padding-top: 20px;">
                        <button type="button" class="flat-button small-button warning-button"  helpTooltip helpContentId="app.delete" [disabled]="showSpinner" (click)="deleteSite()">Delete</button>
                        <div helpTooltip [tooltipText]="(!site.canMove) ? site.canMoveMessage : 'Move'" style="display: inline-block">
                            <button *ngIf="hasMoveCopyPermission" [disabled]="showSpinner || !site.canMove" class="flat-button small-button primary-button" (click)="goToMove()">Move</button>
                        </div>
                        <button *ngIf="hasMoveCopyPermission" helpTooltip helpContentId="site-info-panel.copy" [disabled]="showSpinner" class="flat-button small-button primary-button" (click)="goToCopy()">Copy</button>
                        <linked-sites-edit [site]="site" [isDisabled]="showSpinner"></linked-sites-edit>
                    </div>
                </div>
                <div class="float-end" style="margin-top:20px;" *ngIf="!editMode">
                    <div *ngIf="site.hasImages">
                        <entity-attachments buttonClass="icon-button-large"
                                            helpTooltip
                                            helpContentId="site-info-panel.images"
                                            position="left"
                                            [analyticsCategory]="analyticsCategory"
                                            [isNestedModal]="false"
                                            [forImages]="true"
                                            (hasImagesChange)="site.hasImages = $event"
                                            [modalData]="attachmentsModel">
                        </entity-attachments>
                    </div>
                    <div>
                        <entity-attachments buttonClass="icon-button-large"
                                            helpTooltip
                                            helpContentId="site-info-panel.attachments"
                                            position="left"
                                            [analyticsCategory]="analyticsCategory"
                                            [isNestedModal]="false"
                                            [(hasAttachments)]="site.hasAttachments"
                                            [modalData]="attachmentsModel">
                        </entity-attachments>
                    </div>
                    <div>
                        <entity-comments *ngIf="permissionsLoaded"
                                         [hasComments]="site.hasComments"
                                         [analyticsCategory]="analyticsCategory"
                                         buttonClass="icon-button-large"
                                         [modalData]="getCommentsModalData()"
                                         helpTooltip
                                         helpContentId="site-info-panel.comments"
                                         position="left">
                        </entity-comments>
                    </div>
                    <div *ngIf="site.address.latitude && this.site.address.longitude">
                        <button class="flat-button icon-button-large primary-button" (click)="launchSiteMap()" helpTooltip helpContentId="site-info-panel.map" position="left">
                            <i class="fa fa-map"></i>
                        </button>
                    </div>
                    <div *ngIf="site.canViewValuationTool">
                        <a class="btn flat-button icon-button-large primary-button"
                            style="padding-top: 7px;"
                           [href]="site.valuationToolUrl" target="_blank"
                           helpTooltip
                           position="left"
                           helpContentId="site-info-panel.cre">
                           <i class="fa fa-dollar"></i>
                        </a>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</form>

<ng-template #activatedByPopover>
    <div *ngIf="site.activatedDateTime > site.inactivatedDateTime">
        <div *ngIf="site.activatedBy">
            Activated by {{site.activatedBy}} <br>
            Date: <weissman-datetime [timeZone]="'Central'" [dateOnly]="true" [(inputDate)]="site.activatedDateTime" [readOnly]="true"></weissman-datetime>
        </div>
        <div *ngIf="site.inactivatedBy || site.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span *ngIf="site.inactivatedBy">by {{site.inactivatedBy}}</span> <br>
            Date: <weissman-datetime [timeZone]="'Central'" [dateOnly]="true" [(inputDate)]="site.inactivatedDateTime" [readOnly]="true"></weissman-datetime>
        </div>
    </div>
    <div *ngIf="site.activatedDateTime <= site.inactivatedDateTime">
        <div *ngIf="site.inactivatedBy || site.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span *ngIf="site.inactivatedBy">by {{site.inactivatedBy}}</span> <br>
            Date: <weissman-datetime [timeZone]="'Central'" [dateOnly]="true" [(inputDate)]="site.inactivatedDateTime" [readOnly]="true">
            </weissman-datetime>
        </div>
        <div *ngIf="site.activatedBy">
            Activated by {{site.activatedBy}} <br>
            Date: <weissman-datetime [timeZone]="'Central'" [dateOnly]="true" [(inputDate)]="site.activatedDateTime" [readOnly]="true">
            </weissman-datetime>
        </div>
    </div>
</ng-template>
