
export class BulkImportAssessmentsModel {
    assessorID: number;
    stateID: number;
    annualYear: number;
    comment: string;
	data: string;           // text; first line - headers
}

export class BulkImportSitesModel {
    topCompanyID: number;
    comment: string;
    data: string;               // text; first line - headers
    validateOnly: boolean;   
    totalProcessedRows : number;
}

export class BulkImportResultModel {
    numberOfProcessedRows: number;
    numberOfValidRows: number;
    validationResults: Array<string>;               
    criticalErrors: Array<string>;               
}

