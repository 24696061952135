import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { TransmittalRepository } from '../../Core-Repositories/transmittal.repository';
import { ReviewPaymentPackageModalComponent } from './Modals/payment-packages-review.modal';
import {
    PaymentPackage,
    InvoicePreviewData,
    InvoiceAttachmentInfo,
    PaymentPackageAttachmentSummary, AttachmentType
} from './transmittal.models';

@Injectable()
export class TransmittalService {
    constructor(private readonly _transmittalRepository: TransmittalRepository,
                private readonly _weissmanModalService: WeissmanModalService) {
    }

    // TODO: Create a model for bulk operation results and use it here instead of "any"
    CreatePaymentPackage(taskIDs: number[]): Promise<any> {
        return lastValueFrom(this._transmittalRepository.CreatePaymentPackage(taskIDs));
    }

    ReReview(payload: any): Promise<any> {
        return lastValueFrom(this._transmittalRepository.ReReview(payload));
    }

    CheckFinalizeLongRunningProcess(packageIds: number[]): Promise<Core.PaymentPackageFinalizeLongRunningCheck[]> {
        return lastValueFrom(this._transmittalRepository.CheckFinalizeLongRunningProcess(packageIds));
    }

    GetPaymentPackages(packageIDs: number[]): Promise<PaymentPackage[]> {
        return lastValueFrom(this._transmittalRepository.GetPaymentPackages(packageIDs));
    }

    GetPaymentPackagesByCompanyID(companyId: string, startDate: Date, endDate: Date): Promise<PaymentPackage[]> {
        return lastValueFrom(this._transmittalRepository.GetPaymentPackagesByCompanyID(companyId, startDate, endDate));
    }

    GetPaymentPackagesByPaymentID(paymentID: number): Promise<PaymentPackage[]> {
        return lastValueFrom(this._transmittalRepository.GetPaymentPackagesByPaymentID(paymentID));
    }

    GetPaymentPackageByID(paymentPackageID: number): Promise<PaymentPackage> {
        return lastValueFrom(this._transmittalRepository.GetPaymentPackageByID(paymentPackageID));
    }

    CheckForMissingAttachments(taskIDs: number[]): Promise<number[]> {
        return lastValueFrom(this._transmittalRepository.CheckForMissingAttachments(taskIDs));
    }

    CancelPaymentPackages(packageIDs: number[]): Promise<any> {
        return lastValueFrom(this._transmittalRepository.CancelPaymentPackages(packageIDs));
    }

    FinalizePaymentPackages(packages: PaymentPackage[]): Promise<any> {
        return lastValueFrom(this._transmittalRepository.FinalizePaymentPackages(packages));
    }

    FinalizePaymentPackagesLR(request: Core.PaymentPackageFinalizeRequestDTO): Promise<number> {
        return lastValueFrom(this._transmittalRepository.FinalizePaymentPackagesLR(request));
    }

    RecallPaymentPackage(packageItem: PaymentPackage): Promise<any> {
        return lastValueFrom(this._transmittalRepository.RecallPaymentPackage(packageItem));
    }

    GetLRFinalizeResults(longRunningProcessId: number) : Promise<any> {
        return lastValueFrom(this._transmittalRepository.GetLRFinalizeResults(longRunningProcessId));
    }

    GetPreviewData(currentPackage: PaymentPackage): Promise<InvoicePreviewData> {
        return lastValueFrom(this._transmittalRepository.GetPreviewData(currentPackage));
    }

    GetPreviewAttachment(currentPackage: PaymentPackage, attachmentInfo: InvoiceAttachmentInfo): Promise<any> {
        return this._transmittalRepository.GetPreviewAttachment(currentPackage, attachmentInfo);
    }

    GetPaymentPackageAttachmentSummary(paymentPackageID: number): Promise<PaymentPackageAttachmentSummary> {
        return lastValueFrom(this._transmittalRepository.GetPaymentPackageAttachmentSummary(paymentPackageID));
    }

    showPaymentPackageReviewModal(paymentPackage: PaymentPackage): Promise<void> {
        return this._weissmanModalService.showAsync(ReviewPaymentPackageModalComponent, paymentPackage, 'modal-xl');
    }
}
