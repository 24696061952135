<style>
    .available-shortcuts {
        flex: 1;
    }
    .available-shortcuts .mb-3 {
        margin-bottom: 10px;
    }
</style>
<div class="modal-header">
    <h4 class="modal-title">Shortcut Settings</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body exclude-modal-styling" style="padding: 35px 15px 5px 15px;">
    <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading">
        <div class="mb-4">
            <label>Title</label>
            <input type="text" class="form-control" maxlength="50" placeholder="Title" [(ngModel)]="settings.title">
        </div>
        <div class="checkbox">
            <label>
                <input type="checkbox" [(ngModel)]="settings.groupByType"> Group by Type
            </label>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <div class="available-shortcuts">
                <div>
                    <label>Action Views</label>
                    <div class="mb-4">
                        <select multiple class="form-select form-select-sm" [(ngModel)]="selectedActionViews" style="height: 125px;">
                            <option *ngFor="let item of actionViews" [ngValue]="item">
                                <strong *ngIf="item.isSystemSearch">System -</strong><strong *ngIf="!item.isSystemSearch">User -</strong>
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div>
                    <label>SMART Searches</label>
                    <div class="mb-4">
                        <select multiple class="form-select form-select-sm" [(ngModel)]="selectedSmarts" style="height: 125px;">
                            <option *ngFor="let item of smarts" [ngValue]="item">
                                <strong *ngIf="item.isSystemSearch">System -</strong><strong *ngIf="!item.isSystemSearch">User -</strong>
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="canViewReports">
                    <label>Reports</label>
                    <div class="mb-4">
                        <select multiple class="form-select form-select-sm" [(ngModel)]="selectedReports" style="height: 125px;">
                            <option *ngFor="let item of reports" [ngValue]="item">
                                <strong *ngIf="item.categoryId === 100">System -</strong><strong *ngIf="item.categoryId !== 100">User -</strong>
                                {{item.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <button class="icon-button flat-button" style="margin: 15px;" (click)="addShortcuts()"><i class="fa fa-arrow-right"></i></button>
            <ul class="list-group" style="flex: 1; height: 450px; border: 1px solid #ccc; border-radius: 10px; margin-bottom: 0; overflow-y: auto;">
                <li class="list-group-item" *ngFor="let item of addedShortcuts" style="display: flex;">
                    <div style="flex: 1;">{{item.name}}</div>
                    <div (click)="removeShortcut(item)"><i class="fa fa-close"></i></div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="flat-button primary-button" (click)="saveAndClose()" [disabled]="false">Save</button>
        <button class="flat-button warning-button" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
