<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<div class="container">
    <div class="single-column-layout">
        <h1>Manage Licensed Client Documents</h1>
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Instance</th>
                <th>Company</th>
                <th>Status</th>
                <th>Assignments</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let client of licensedClients">
                <td>
                    {{client.instanceName}}
                </td>
                <td>
                    {{client.topLevelCompanyName}}
                </td>
                <td>
                    {{getStatus(client)}}
                </td>
                <td>
                    <button *ngIf="!client.cancellationDate" (click)='configure(client)' class="btn small-button flat-button primary-button">Configure</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
