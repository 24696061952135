import { NgModule } from "@angular/core";
import { WeissmanSharedModule } from "../Common/weissman-shared.module";
import { HybridAdapterUtility } from "../Hybrid/hybrid-adapter.utility";
import { ConsultingEngagementModalComponent } from "./consultingEngagementModal.component";
import { CompanyContractsModalComponent } from "./CompanyContracts/companyContractsModal.component";
import { ConsultingEngagementsComponent } from "./consultingEngagements.component";
import { ConsultingEngagementsRepository } from "./consultingEngagements.repository";
import { InstanceRightsListComponent } from "./CompanyContracts/instanceRightsList.component";

@NgModule({
    imports: [
        WeissmanSharedModule
    ],
    declarations: [
        ConsultingEngagementsComponent,
        ConsultingEngagementModalComponent,
        CompanyContractsModalComponent,
        InstanceRightsListComponent,
    ],
    providers: [
        ConsultingEngagementsRepository
    ]
})

export class ConsultingModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('consultingEngagements', ConsultingEngagementsComponent);
        hybridAdapterUtility.downgradeNg2Component('consultingEngagementModal', ConsultingEngagementModalComponent);
        hybridAdapterUtility.downgradeNg2Component('instanceRightsList', InstanceRightsListComponent);
        hybridAdapterUtility.downgradeNg2Component('companyContractsModal', CompanyContractsModalComponent);
   }
}
