import { Injectable } from "@angular/core";
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { MoveCopyPayload } from "./move.copy.entity.component";

@Injectable()
export class MoveCopyEntityService {
    constructor(private http: SDHttpService) { }

    copyMoveEntity(payload: MoveCopyPayload): Promise<any> {
        return this.http.post('/api/copymoveentity', payload);
    }

    getSiteYears(siteId: number): Promise<number[]> {
        return this.http.get('/api/parcel/years/' + siteId);
    }
}
