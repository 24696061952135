import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class ReturnFormRevisionReportRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    getAssociatedWithReturns(filingBatchId: number, returnIds: number[]): Observable<Compliance.ReturnFormRevisionReportModel[]> {
        return this.httpPost(`/api/returnFormRevisionReport/filingBatch/${filingBatchId}/associated`, returnIds);
    }

    getNotAssociatedWithReturns(filingBatchId: number, returnIds: number[]): Observable<Compliance.ReturnFormRevisionReportModel[]> {
        return this.httpPost(`/api/returnFormRevisionReport/filingBatch/${filingBatchId}/notAssociated`, returnIds);
    }

    update(filingBatchId: number, models: Compliance.ReturnFormRevisionReportModel[]): Observable<void> {
        return this.httpPut(`/api/returnFormRevisionReport/filingBatch/${filingBatchId}`, models);
    }
}
