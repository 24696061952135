import { Component, Input } from '@angular/core';
import { AnchorSREF } from '../Directives/Anchor-Route/anchorRoute.directive';

export interface BreadCrumb extends AnchorSREF {
    name: string;
}

@Component({
    selector: 'ws-bread-crumbs',
    templateUrl: './breadCrumbs.component.html',
    styleUrls: ['./breadCrumbs.component.scss']
})
export class BreadCrumbsComponent {
    @Input() breadcrumbs: BreadCrumb[];
    @Input() currentPageTitle: string;
}
