import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentModalData } from 'src/app/Attachment/attachment.modal.model';
import { CommentModalData } from 'src/app/Comments/comments.service';

export interface ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors extends ICellRendererParams {
    getErrors: (params: ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors) => string[];
}

@Component({
    selector: 'grid-payment-errors-cell',
    template: `
        <div *ngIf="params.getErrors(params).length" [popover]="errorList"
            triggers="mouseenter:mouseleave"
            style="padding-left: 10px;"
            placement="left"
            container="body">
                <i style="color: #A40C00; font-size: 150%;" class="fa fa-circle-exclamation"></i>
        </div>

        <ng-template #errorList>
            <ul style="color: red;">
                <li style="padding-bottom: 8px;" *ngFor="let error of params.getErrors(params)">{{error}}</li>
            </ul>
        </ng-template>
`
})
export class PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors;
    row: Core.PaymentBatchDetailsEntryModel;
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;

    agInit(params: ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
