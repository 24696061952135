import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/main';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription,  BehaviorSubject } from 'rxjs';

export interface ICellRendererParamsForDropdowns extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: ICellRendererParamsForDropdowns) => boolean;
    isDisabled: (params: ICellRendererParamsForDropdowns) => boolean;
    dropdownItems: any[];
    name: string;
    valueField: string;
    allowNull: boolean;
    originalValue: any;
    displayGetter: (dropdownItem: any) => string;
    change: (params: ICellRendererParamsForDropdowns) => void;
}

@Component({
    selector: 'form-asset-class-mapping-cell-renderer',
    template: `<div #outerDiv class="edit-on-hover-cell" [ngClass]="isHovering ? 'mouseover' : ''" (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <div class="read-only-label" [ngClass]="{'override-cell': overridesParentValue(), 'grid-cell-readonly': params.isDisabled(params)}">{{ getDisplayValue() }}<span class="hasChildOverride" *ngIf="hasChildOverride()">**</span></div>
        <div class="edit-control">
            <select class="w-100" [name]="params.name" [ngModel]="params.data[params.colDef.field]" (ngModelChange)="onModelChange($event)">
                <option [ngValue]="null" *ngIf="params.allowNull"></option>
                <option *ngFor="let d of params.dropdownItems" [ngValue]="d[params.valueField]">{{params.displayGetter(d)}}</option>
            </select>
        </div>
    </div>`,
    styleUrls: ['./../agGridCellStyles.scss', './agGridDropdownCellRenderer.component.scss']
})
export class DropdownCellRenderer implements ICellRendererAngularComp {
    private _editModeSub: Subscription;

    params: ICellRendererParamsForDropdowns;
    editMode: boolean = false;
    isHovering: boolean;
    @ViewChild('outerDiv', { read: ElementRef, static: true }) outerDiv: ElementRef;

    agInit(params: ICellRendererParamsForDropdowns): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChange(value: any) {
        this.params.originalValue = this.params.data[this.params.colDef.field];
        this.params.data[this.params.colDef.field] = value;
        this.params.change(this.params);
    }

    overridesParentValue(): boolean {
        if (!this.params.data) { return false; }
        switch (this.params.colDef.field) {
            case 'formRevisionScheduleId': {
                return this.params.data.isScheduleOverridden;
            }
            case 'depreciationFactorTableId': {
                return this.params.data.isDepreciationOverridden;
            }
            case 'indexFactorTableId': {
                return this.params.data.isFactorOverridden;
            }
            default: return false;
        }
    }

    hasChildOverride(): boolean {
        if (!this.params.data) { return false; }
        switch (this.params.colDef.field) {
            case 'formRevisionScheduleId': {
                return this.params.data.childOverridesSchedule;
            }
            case 'depreciationFactorTableId': {
                return this.params.data.childOverridesDepreciation;
            }
            case 'indexFactorTableId': {
                return this.params.data.childOverridesFactor;
            }
            default: return false;
        }
    }

    mouseover($event) {
        this.isHovering = this.editMode && this.params.canEdit(this.params);
    }

    mouseout($event) {
        if (this.editMode && this.params.canEdit(this.params) && $event.relatedTarget) {
            // protect against clicks on inner children
            let parent = $event.relatedTarget.parentElement;
            while (parent) {
                if (parent === this.outerDiv.nativeElement) {
                    return;
                }
                parent = parent.parentElement;
            }
            this.isHovering = false;
        }
    }

    getDisplayValue(): string {
        if (!this.params.data) {
            return null;
        }
        const dropdownItem = this.params.dropdownItems.find(x => x[this.params.valueField] === this.params.data[this.params.colDef.field]);
        return (dropdownItem && this.params.displayGetter(dropdownItem)) || null;
    }
}
