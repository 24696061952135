<div *ngIf="!isEditMode">
    <span
        *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Currency">{{descriptor.value | currency: 'USD'}}</span>
    <span *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Text">
        <span *ngIf="descriptor.value || descriptor.value === 0">{{descriptor.value}}</span>
        <span *ngIf="!(descriptor.value || descriptor.value === 0)">Empty</span>
    </span>
    <span *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Number">
        <span *ngIf="descriptor.value || descriptor.value === 0">
            <span *ngIf="descriptor.descriptor.formatID == DescriptorFormats.NoSeparators">{{descriptor.value}}</span>
            <span *ngIf="descriptor.descriptor.formatID == DescriptorFormats.CommaSeparators || descriptor.descriptor.formatID == DescriptorFormats.CommaSeparatorsDupe">{{descriptor.value | number}}</span>
            <span *ngIf="descriptor.descriptor.formatID == DescriptorFormats.CommaSeparatorsPrecision2">{{descriptor.value | number: '1.2-2'}}</span>
            <span *ngIf="descriptor.descriptor.formatID == DescriptorFormats.CommaSeparatorsPrecision3">{{descriptor.value | number: '1.3-3'}}</span>
        </span>
        <span *ngIf="!(descriptor.value || descriptor.value === 0)">Empty</span>
    </span>
    <span *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Date">
        <span *ngIf="!descriptor.value">Empty</span>
        <weissman-datetime *ngIf="descriptor.value"
                           [readOnly]="true"
                           [(inputDate)]="descriptor.value"
                           [dateOnly]="true">
        </weissman-datetime>
    </span>
    <span *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.YesNo">{{descriptor.value ? 'Yes' : 'No'}}</span>
    <span *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Picklist">{{descriptor.value || 'Not Set'}}</span>
</div>

<div *ngIf="isEditMode">
    <input *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Currency"
           [(ngModel)]="descriptor.value"
           class="form-control"
           [class.ng-touched]="wasSaveAttempted"
           [class.ng-invalid]="!isValid"
           type="text"
           currencyMask
           required
           [disabled]="disabled"
           [options]="{ allowNegative: true, precision: 2 }">
    <input *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Text"
           [(ngModel)]="descriptor.value"
           class="form-control"
           [class.ng-touched]="wasSaveAttempted"
           [class.ng-invalid]="!isValid"
           required
           [disabled]="disabled"
           type="text"
           [attr.maxLength]="descriptor.descriptor.validation.maxLength > 0 ? descriptor.descriptor.validation.maxLength : null">
    <input *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Number"
           [(ngModel)]="descriptor.value"
           class="form-control"
           [class.ng-touched]="wasSaveAttempted"
           [class.ng-invalid]="!isValid"
           type="text"
           currencyMask
           required
           [disabled]="disabled"
           [attr.min]="descriptor.descriptor.validation.minValue > 0 ? descriptor.descriptor.validation.minValue : null"
           [attr.max]="descriptor.descriptor.validation.maxValue > 0 ? descriptor.descriptor.validation.maxValue : null"
           [options]="numberOptions">
    <weissman-datetime *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Date"
                       [(inputDate)]="descriptor.value"
                       [excludeValidation]="true"
                       [setInvalid]="!isValid"
                       [isDisabled]="disabled"
                       [required]="true"
                       [dateOnly]="true">
    </weissman-datetime>
    <div *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.YesNo" class="checkbox">
        <label>
            <input type="checkbox" [(ngModel)]="descriptor.value" [disabled]="disabled">
        </label>
    </div>
    <select *ngIf="descriptor.descriptor.fieldTypeID == FieldTypeEnum.Picklist"
            class="form-select form-select-sm"
            [class.ng-touched]="wasSaveAttempted"
            [class.ng-invalid]="!isValid"
            required
            [disabled]="disabled"
            [(ngModel)]="descriptor.value">
        <option *ngFor="let option of picklistOptions" [ngValue]="option.name">{{option.name}}</option>
    </select>
</div>
