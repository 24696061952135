<div class="ws-flex-none d-flex form-page-title">
    <div class="d-flex align-items-center ws-flex-auto">
        <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="formRevision?.name"></ws-bread-crumbs>
    </div>
    <div class="d-flex align-items-center ws-flex-auto">
        <form-appeal-level-list *ngIf="isFormTypeAppeal"></form-appeal-level-list>
        <form-assessor-select></form-assessor-select>
        <form-filing-batches *ngIf="canEdit && !isFormTypeAppeal"></form-filing-batches>
        <form-year-list></form-year-list>
        <form-year-new *ngIf="canEdit"></form-year-new>
        <form-year-copy *ngIf="canEdit"></form-year-copy>
        <form-year-delete *ngIf="canEdit"></form-year-delete>
        <form-switches></form-switches>
    </div>
    <div class="d-flex align-items-end ws-flex-none">
        <div class="d-flex align-items-center form-key d-flex mx-2">
            <label class="ws-flex-none pe-1 mt-1">Form Key:</label>
            <label class="d-flex value pe-1 mt-1" name="formKey">{{formRevision?.formKey}}</label>
        </div>
    </div>
    <div class="d-flex align-items-center ws-flex-none">
        <form-fields></form-fields>
        <form-comments title="Comments"></form-comments>
        <form-attachments title="Attachments"></form-attachments>
    </div>
</div>

<ng-container *ngIf="formRevision">
    <div class="d-flex" [ngClass]="areHidden(['form-report-list','form-template','form-factor-table-list','form-asset-class-mapping']) ? 'ws-flex-auto' : 'form-page-row ws-flex-none'" [hidden]="areHidden(['form-assessor-list','form-schedule-list'])">
        <div class="d-flex flex-column form-page-flex-column-left" [hidden]="isHidden('form-assessor-list')">
            <form-assessor-list></form-assessor-list>
        </div>
        <div class="d-flex flex-column form-page-flex-column-right" [hidden]="isHidden('form-schedule-list')">
            <form-schedule-list></form-schedule-list>
        </div>
    </div>

    <div class="d-flex" [ngClass]="areHidden(['form-report-list','form-template','form-assessor-list','form-schedule-list']) ? 'ws-flex-auto' : 'form-page-row ws-flex-none'" [hidden]="areHidden(['form-factor-table-list','form-asset-class-mapping'])">
        <div class="d-flex flex-column form-page-flex-column-left" [hidden]="isHidden('form-factor-table-list')">
            <form-factor-table-list></form-factor-table-list>
        </div>
        <div class="d-flex flex-column form-page-flex-column-right" [hidden]="isHidden('form-asset-class-mapping')">
            <form-asset-class-mapping></form-asset-class-mapping>
        </div>
    </div>

    <div class="d-flex" [ngClass]="areHidden(['form-factor-table-list','form-asset-class-mapping','form-assessor-list','form-schedule-list']) ? 'ws-flex-auto' : 'form-page-row ws-flex-none'" [hidden]="areHidden(['form-report-list','form-template'])">
        <div class="d-flex flex-column form-page-flex-column-left" [hidden]="isHidden('form-report-list')">
            <form-report-list></form-report-list>
        </div>
        <div class="d-flex flex-column form-page-flex-column-right" [hidden]="isHidden('form-template')">
            <form-template></form-template>
        </div>
    </div>
</ng-container>
