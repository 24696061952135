(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.factory('layoutService', Service);

	Service.$inject = ['appStateService'];

	function Service(appStateService) {
        var service = {
            showApp: false,
            showMenu: false,
            loggedOut: false,
            showQuick: false
        };

        appStateService.appState$.subscribe(function (state) {
            var checkState = function (states) { return states.indexOf(state) >= 0; };
            var appStates = appStateService.appStates;

            service.showApp = checkState([appStates.Initialized, appStates.LoggedIn]);
            service.showMenu = checkState([appStates.Initialized, appStates.LoggedIn, appStates.LoggingOut]);
            service.showQuick = checkState([appStates.LoggedIn]);
            service.loggedOut = checkState([appStates.LoggingOut, appStates.LoggedOut]);
        });

        return service;
    }
}());
