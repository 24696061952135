import { Component, OnInit, OnDestroy} from '@angular/core';
import { ReturnSummaryReconciliationReport } from '../../../Models/returnSummaryReconciliationReport';
import { ReturnService, ReturnServiceSharedState } from '../../../return.service';
import { ReturnUpdateLogicService } from '../../../returnUpdateLogic.service';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from '../../Assets/returnAssets.service';
import { ReturnOverviewService, ReturnOverviewServiceSharedState } from '../returnOverview.service';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnAssetDisposalsListParams, ReturnAssetDisposalsListComponent } from '../../Assets/Asset-Disposals-List/returnAssetDisposalsList.component';
import { Subject } from 'rxjs';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'return-summary-reconciliation-report',
    templateUrl: './returnSummary.component.html'
})
export class ReturnSummaryReconciliationReportComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnOverviewService: ReturnOverviewService,
        private readonly _modalService: WeissmanModalService,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService) {
    }
    data: ReturnSummaryReconciliationReport;
    isBusy: boolean;

    private _localReturnServiceSharedState: ReturnServiceSharedState;
    private _localReturnOverviewServiceSharedState: ReturnOverviewServiceSharedState;
    private _localReturnAssetsServiceSharedState: ReturnAssetsServiceSharedState;
    private _showEstimatedFMV: boolean = true;
    private _destroy$: Subject<void> = new Subject();

    get showEstimatedFMV(): boolean {
        return this._showEstimatedFMV;
    }

    ngOnInit(): void {
        this._returnOverviewService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._returnOverviewService.unsubscribeFromServiceActivationCycle(this);
    }

    onReturnPartServiceActivated(): void {
        this._returnUpdateLogicService.loading$.pipe(takeUntil(this._destroy$))
            .subscribe((loading) => this.isBusy = loading);

        this._returnUpdateLogicService.returnSummaryReport$.pipe(takeUntil(this._destroy$))
            .subscribe((report) => {
                this.data = report;
                this._showEstimatedFMV = this.data?.showEstimatedFMV;
            });
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    navigateToAssets(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToNoSchedule(
        filterAcquisition: boolean = false,
        filterTransferIn: boolean = false,
        filterCostChange: boolean = false
    ): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: true,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];

        if (filterAcquisition) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.New);
        if (filterTransferIn) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn);
        if (filterCostChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased);

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToNonReportableSchedule(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: true,
            showOnlyAssignedToReportableSchedule: false,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAssetsAssignedToReportableSchedule(): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: false,
            showOnlyAssignedToReportableSchedule: true,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];
        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    navigateToAdditions(
        targetNonReportableSchedule: boolean,
        targetReportableSchedule: boolean,
        filterAcquisition: boolean,
        filterTransferIn: boolean,
        filterCostChange: boolean,
        filterReportabilityChange: boolean
    ): void {
        const sharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        sharedState.scheduleAndFactorFilter = {
            showOnlyNotAssignedToASchedule: false,
            showOnlyAssignedToNonReportableSchedule: targetNonReportableSchedule,
            showOnlyAssignedToReportableSchedule: targetReportableSchedule,
            formRevisionScheduleIds: [],
            depreciationFactorId: null,
            scheduleAge: null
        };
        sharedState.assetStatusesFilter = [];

        if (filterAcquisition) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.New);
        if (filterTransferIn) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn);
        if (filterCostChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostIncreased);
        if (filterReportabilityChange) sharedState.assetStatusesFilter.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleNonReportableToReportable);

        this._returnAssetsService.setScheduleAndFactorFilter(sharedState.scheduleAndFactorFilter);
        this._returnOverviewService.notifyReturnAssetsSelected(sharedState);
    }

    async navigateToDeductions(
        title: string,
        targetReportableSchedule: boolean,
        targetNonReportableSchedule: boolean,
        filterDisposed: boolean,
        filterTransferOut: boolean,
        filterCostChange: boolean,
        filterReportabilityChange: boolean
    ): Promise<void> {

        const params: ReturnAssetDisposalsListParams = {
            title: title,
            priorReturnStatuses: [],
            formRevisionScheduleId: null,
            targetNonReportableSchedule: targetNonReportableSchedule,
            targetReportableSchedule: targetReportableSchedule,
            isDisposalOrTransferOut: filterDisposed || filterTransferOut
        };

        if (filterDisposed) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.Retired);
        if (filterTransferOut) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut);
        if (filterCostChange) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedCostDecreased);
        if (filterReportabilityChange) params.priorReturnStatuses.push(Compliance.ReturnAssetPriorReturnStatusEnum.ChangedScheduleReportableToNonReportable);

        await this._modalService.showAsync(ReturnAssetDisposalsListComponent, params, 'modal-xl');

        await this._returnUpdateLogicService.refreshReturnSummary();
    }
}
