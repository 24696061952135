import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { AddCommentModalData } from '../comments.service';

export interface AddCommentModalParams extends AddCommentModalData {
    title: string;
    commentBody: string;
    processResult: (result: AddCommentModalResult | string) => Promise<void>;
    readOnly: boolean;
    allowBlank: boolean;
    yearIsRelevant: boolean;
    showFull: boolean;
    defaultYear: string;
    entityTypeName?: string;
    entityId?: number;
}

export interface AddCommentModalResult {
    title: string;
    year: string;
    description: string;
    isRyanInternal: boolean;
}

@Component({
    selector: 'add-comment-modal',
    templateUrl: './addComment.component.html'
})
export class AddCommentComponent implements OnInit, IWeissmanModalComponent<AddCommentModalParams, AddCommentModalResult | string> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _restrictService: RestrictService,
        private readonly _userInstanceService: UserInstanceService
    ) {}

    params: AddCommentModalParams;
    result: AddCommentModalResult | string;

    availableYears = [];
    title: string;
    commentTitle: string = '';
    commentBody: string;
    processResult: (result: AddCommentModalResult | string) => Promise<void>;
    readOnly: boolean;
    isRyanInternal: boolean;
    allowBlank: boolean;
    yearIsRelevant: boolean;
    year: string;
    showFull: boolean;
    yearDisabled: boolean;
    loading: boolean;
    hasInstancePrivateView: boolean;
    isSingleRyanInstance: boolean;

    ngOnInit(): void {
        this.title = this.params.title || 'Add Comment';
        this.commentBody = this.params.commentBody || '';
        this.processResult = this.params.processResult || null;
        this.readOnly = !!this.params.readOnly;
        this.allowBlank = this.params.allowBlank || false;
        this.yearIsRelevant = this.params.yearIsRelevant || false;
        this.showFull = this.params.showFull;
        this.yearDisabled = !this.params.defaultYear;
        this.isSingleRyanInstance = this._userInstanceService.isCurrentInstanceRyan();

        this._populateYears();

        if(this.showFull) {
            this._getHasInstancePrivateView();
        }
    }

    cancel(): void {
        this.loading = false;
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        if (this.params.showFull) {
            this.result = {
                title: this.commentTitle,
                year: this.year,
                description: this.commentBody,
                isRyanInternal: this.isRyanInternal
            };
        } else {
            this.result = this.commentBody;
        }

        if (this.processResult) {
            this.loading = true;
            try {
                await this.processResult(this.result);
                this.cancel();
            } catch(err) {
                this.cancel();
            }
        } else {
            this.cancel();
        }
    }

    private _populateYears(): void {
        if (this.params.defaultYear) {
            this.availableYears = [{
                display: this.params.defaultYear,
                value: this.params.defaultYear
            }];

            this.year = this.params.defaultYear;
        } else {
            this.availableYears = [];
            const now = new Date();
            const currentYear = now.getFullYear();

            for (let i = currentYear + 3; i >= currentYear - 10; i--) {
                this.availableYears.push({
                    display: i,
                    value: i
                });
            }
        }
    }

    private async _getHasInstancePrivateView(): Promise<void> {
        if (this.params.entityTypeName && this.params.entityId) {
            const instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId(this.params.entityTypeName, this.params.entityId));

            this.hasInstancePrivateView = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, instanceId)
                                        || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, instanceId);
        } else {
            this.hasInstancePrivateView = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT)
                                        || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW);
        }
    }
}
