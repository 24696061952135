<div class="snack-bar"
     [ngClass]="{ 'small': size === snackSize.Small, 'large': size === snackSize.Large, 'open': size !== snackSize.Hidden && snacks.length }">
    <div *ngIf="size === snackSize.Large && snacks.length" class="toggle-container">
        <div class="snack-bar-toggle" (click)="hideSnackBar()" title="Close Snackbar">
            <i class="fa fa-chevron-up"></i>
        </div>
    </div>
    <snack *ngFor="let snack of snacks; trackBy:processIdTracker"
           [details]="snack"
           [size]="size"
           [signalRDisconnected]="signalRDisconnected">
    </snack>
</div>
