import { Injectable, ComponentFactoryResolver, Injector, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { TypeaheadComponent } from './typeahead.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimerService } from '../Utilities/timer.service';

export interface TypeaheadConfig {
    focused: boolean;
    values: any[];
    selectedOption: any;
    selectedOptionChanged: (option: any) => void;
    [options: string]: any;
}

@Injectable()
export class TypeaheadService {
    constructor(
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private readonly _injector: Injector,
        private readonly _timer: TimerService
    ) { }

    dropdownActive: boolean;

    private _dropdownRef: ComponentRef<TypeaheadComponent>;
    private _dropdown: HTMLElement;

    private _currentDropdown: TypeaheadConfig;

    private _targetBounds: DOMRect;
    private _closingInterval;
    private _destroy$: Subject<void> = new Subject();

    /**
     * Open the dropdown
     * @param config
     * @param position
     */
    openDropdown(config: TypeaheadConfig, position: DOMRect): void {
        if (this._currentDropdown) {
            this._hideDropdown(() => {
                this._currentDropdown = config;
                this._targetBounds = position;
                this._showDropdown();
            });
        } else {
            this._currentDropdown = config;
            this._targetBounds = position;
            this._showDropdown();
        }
    }

    /**
    * Close the dropdown
    */
    closeDropdown(config: TypeaheadConfig): void {
        if (config === this._currentDropdown) {
            this._hideDropdown(() => this._currentDropdown = null);
        }
    }

    /**
     * Update the position for scroll and resize events
     */
    update(selectConfig: TypeaheadConfig, position: DOMRect): void {
        if (this._currentDropdown === selectConfig) {
            this._dropdownRef.instance.typeaheadConfig = this._currentDropdown;
            this._dropdownRef.changeDetectorRef.detectChanges();

            this._targetBounds = position;
            this._dropdownRef.instance.targetPosition = this._targetBounds;

            this._dropdownRef.changeDetectorRef.detectChanges();
        }
    }

    /**
    * Update the dropdown with new values
    */
    private _showDropdown(): void {
        if (!this.dropdownActive) {
            if (!this._dropdown) {
                this._createDropdown();
            }
            this.dropdownActive = true;
            document.body.appendChild(this._dropdown);
        }

        // Init blank component
        this._dropdownRef.instance.typeaheadConfig = this._currentDropdown;
        this._dropdownRef.changeDetectorRef.detectChanges();

        this._dropdownRef.instance.targetPosition = this._targetBounds;
        this._dropdownRef.changeDetectorRef.detectChanges();

        this._dropdownRef.instance.isOpen = true;
        this._dropdownRef.changeDetectorRef.detectChanges();

        this._dropdownRef.instance.close
            .pipe(takeUntil(this._destroy$))
            .subscribe((config) => {
                return config === this._currentDropdown && this._hideDropdown();
            });
    }

    private _hideDropdown(callback?: Function): void {
        this._dropdownRef.instance.isOpen = false;
        this._destroy$.next();
        this._dropdownRef.changeDetectorRef.detectChanges();

        if (this._closingInterval) {
            clearTimeout(this._closingInterval);
        }
        this._closingInterval = this._timer.setTimeout(() => {
            this.resetDropdown();
            if (callback) {
                callback();
            }
            if (document.body.contains(this._dropdown)) {
                document.body.removeChild(this._dropdown);
                this.dropdownActive = false;
            }
            this._closingInterval = null;
        }, 200);
    }

    /**
    * Create the dropdown DOM element
    */
    private _createDropdown(): void {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TypeaheadComponent);
        this._dropdownRef = componentFactory.create(this._injector);

        this._dropdown = (this._dropdownRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this._dropdownRef.changeDetectorRef.detectChanges();
    }

    /**
    * Reset the dropdown component values
    */
    resetDropdown(): void {
        this._currentDropdown = null;
        this._dropdownRef.instance.targetPosition = {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            height: 0,
            width: 0,
            x: 260, // allows left pre-render for positioning calculations
            y: 0,
            toJSON: null
        };

        this._dropdownRef.changeDetectorRef.detectChanges();
    }
}
