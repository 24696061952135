export enum MyTasksView {
    myTasks = 1,
    myTeamsTasks = 2
}

export class MyTasksWidgetSettings {
    myTasksView: MyTasksView;
}

export class TaskSeriesTypeItem {
    data: TaskCountItem[];
    firstGroup: string;
    secondGroup: string;
    taskSeriesTypeID: number;
    taskSeriesName: string;
    taskTypeID: number;
    taskSeriesTypeName: string;
}

export class TaskCountItem {
    colClass: string;
    label: string;
    notReady: number;
    ready: number;
    sequence: number;
    value: number;
}