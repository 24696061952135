import { Component, Input } from '@angular/core';
import { AssessorCollectorService } from '../../assessor.collector.service';
import { NavigationService } from '../../../Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { AddressDetailsModalLaunchService } from '../../../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { EntityTypeIds } from '../../../constants.new';

import * as _ from 'lodash';
import { Address } from '../../../Common/Models/common.model';

@Component({
    selector: 'collector-details',
    templateUrl: './collectorDetails.component.html'
})
export class CollectorDetailsComponent {
    constructor(
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _navigationService: NavigationService,
        private readonly _addressDetailsModalLaunchService: AddressDetailsModalLaunchService,
        private readonly _toastr: ToastrService
    ) {}

    @Input() entityId: number;
    @Input() companyId: number;
    @Input() isCompanyCollector: boolean;
    @Input() viewOnly: boolean;
    @Input()
    set collectorValue(collector: any) {
        this.collector = collector;
        this._initializeCollectorValues();
    }

    collector: any;
    editingCollector: any;
    entityTypeId: EntityTypeIds = EntityTypeIds.COLLECTOR;
    editMode: boolean;
    defaultAddress: Address;
    addressesLength: number;
    attachmentIcon: string;

    private readonly _WARNING_MESSAGE: string = 'Editing is in progress. Are you sure you want to leave?';
    private readonly _COLLECTOR_CAPS: string = 'Collector';
    private readonly _COLLECTOR: string = 'collector';

    get attachmentsModel(): AttachmentModalData {
        if (!this.collector) {
            return null;
        }

        return {
            entityType: this._COLLECTOR_CAPS,
            entityName: this.collector.abbr,
            entityData: {
                typeId: Core.EntityTypes.Collector,
                id: this.collector.collectorID,
                name: this.collector.abbr
            },
            readOnly: this.viewOnly
        } as AttachmentModalData;
    }

    edit(): void {
        this.editMode = true;
        this.editingCollector = _.cloneDeep(this.collector);
        this._navigationService.enableNavWarning(this._WARNING_MESSAGE);
        this._setEditAddress();
    }

    async save(): Promise<void> {
        try {
            this.collector = await this._assessorCollectorService.saveCollector(this.editingCollector);
        } finally {
            this.cancel();
        }
    }

    cancel(): void {
        this._navigationService.disableNavWarning();
        this.editMode = false;
        this.editingCollector = null;
        this._setEditAddress();
    }

    getCommentsModalData = (): any => {
        const entityId = this.collector.collectorID;
        const description = `${this._COLLECTOR_CAPS  }: ${  this.collector.abbr}`;
        return {
            entityTypeID: this.entityTypeId,
            entityID: entityId,
            description: description,
            canEdit: !this.viewOnly
        };
    };

    async launchAddressDetailsModal(): Promise<void> {
        const entityId = this.collector.collectorID;
        const entityAddresses = this.editMode ? this.editingCollector.entityAddresses : this.collector.entityAddresses;

        try {
            const result = await this._addressDetailsModalLaunchService.openAddressDetailsModal(
                entityId,
                this.entityTypeId,
                _.cloneDeep(entityAddresses),
                this.editMode,
                false,
                true
            );

            if(this.editMode) {
                this.editingCollector.entityAddresses = result;
            }
        } catch(err) {
            this._toastr.error(err.error.message);
        }
    }

    private _setEditAddress(): void {
        const entityAddresses = this.editMode ? this.editingCollector.entityAddresses : this.collector.entityAddresses;

        this.defaultAddress = entityAddresses.find(x => x.isDefault).address;
        this.addressesLength = _.reject(entityAddresses, {efAction: 'delete'}).length;
    }

    private _initializeCollectorValues(): void {
        if (this.collector) {
            this._setEditAddress();
        }
    }
}
