import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Constants, CommonBulkUpdateFieldActionEnum } from '../../../constants.new';
import { TaxAuthorityToUpdate, TaxAuthorityBulkUpdateRequest } from '../../tax.rate.model';
import { Picklist } from '../../Tax-Authority/tax.authority.component';

@Component({
    selector: 'tax-authority-bulk-update-modal',
    templateUrl: './tax.authority.bulk.update.component.html',
})
export class TaxAuthorityBulkUpdateComponent implements OnInit {
    constructor(
        private bsModalRef: BsModalRef,
        public constants: Constants,
        private toastr: ToastrService
    ) { }

    onClose: (request?: TaxAuthorityBulkUpdateRequest) => void;
    selectedCount: number;
    selections: TaxAuthorityToUpdate[];

    headerLabel: string;
    taBulkUpdateRequest: TaxAuthorityBulkUpdateRequest;
    categories: Picklist[];
    taxableAssessments: Picklist[];
    certificationMonths: number[];
    certificationDays: number[];
    certificationTypes: Picklist[];
    selectableTaxYears: number[];
    defaultAnnualIncreaseDisplay: string;
    yoyPercentDisplay: string;

    ngOnInit() {
        this.taBulkUpdateRequest = new TaxAuthorityBulkUpdateRequest();

        this.headerLabel =  `Bulk Update ${this.selectedCount} Tax Authorities`;

        this.certificationMonths = _.range(1, 13);
        this.certificationDays = _.range(1,32);

        this.categories = _.map(this.constants.TaxAuthorityCategories, (names: any, id: number) => {
            return {
                label: names.displayName,
                id: Number(id)
            }
        });

        this.taxableAssessments = _.map(this.constants.TaxableAssessmentTypes, (names: any, id: number) => {
            return {
                label: `${names.displayName} ${names.extraDisplay}`,
                id: Number(id)
            }
        });

        this.certificationTypes = [{
            label: 'Prior Prior',
            id: -2
        },{
            label: 'Prior',
            id: -1
        }, {
            label: 'Current',
            id: 0
        }, {
            label: 'Next',
            id: 1
        }, {
            label: 'Next Next',
            id: 2
        }];

        const currentYear: number = Number(moment().format('YYYY'));
        let years = _.range(currentYear - 10, currentYear + 6);
        this.selectableTaxYears = _.chain(years)
            .reverse()
            .value();
        this.taBulkUpdateRequest.taxYear = currentYear;

        this.defaultAnnualIncreaseDisplay = new Decimal(this.taBulkUpdateRequest.defaultAnnualIncrease).times(100).toFixed(4);
        this.yoyPercentDisplay = new Decimal(this.taBulkUpdateRequest.yoyPercent).times(100).toFixed(4);
    }

    isSaveable(): boolean {
        return (this.taBulkUpdateRequest.categoryAction !== CommonBulkUpdateFieldActionEnum.NoChange)
               || (this.taBulkUpdateRequest.taxableAssessmentTypeAction !== CommonBulkUpdateFieldActionEnum.NoChange)
               || (this.taBulkUpdateRequest.certificationDateAction !== CommonBulkUpdateFieldActionEnum.NoChange)
               || (this.taBulkUpdateRequest.obtainInAdvanceAction !== CommonBulkUpdateFieldActionEnum.NoChange)
               || (this.taBulkUpdateRequest.defaultAnnualIncreaseAction !== CommonBulkUpdateFieldActionEnum.NoChange)
               || (this.taBulkUpdateRequest.yoyAction !== CommonBulkUpdateFieldActionEnum.NoChange);
    }

    defaultAnnualIncreaseBlur(currentValue: string): void {
        if ( !this._isValidPercentage(this.defaultAnnualIncreaseDisplay) )
        {
            this.defaultAnnualIncreaseDisplay = '0.0';
        }
        this.defaultAnnualIncreaseDisplay = new Decimal(this.defaultAnnualIncreaseDisplay).toFixed(4);
    }

    yoyPercentBlur(currentValue: string): void {
        if ( !this._isValidPercentage(this.yoyPercentDisplay) )
        {
            this.yoyPercentDisplay = '0.0';
        }
        this.yoyPercentDisplay = new Decimal(this.yoyPercentDisplay).toFixed(4);
    }


    save() {
        // Validate Category selected if ChangeTo.
        if ( this.taBulkUpdateRequest.categoryAction === CommonBulkUpdateFieldActionEnum.ChangeTo &&
            !_.some(this.categories, ['id', this.taBulkUpdateRequest.taxAuthorityCategoryId]) )
        {
            this.toastr.error("A Category must be selected for 'Change To' Action.", 'Cannot Save!');
            return;
        }
        // Validate all components of Certification Date selected if ChangeTo.
        if ( this.taBulkUpdateRequest.certificationDateAction === CommonBulkUpdateFieldActionEnum.ChangeTo &&
             (!this.taBulkUpdateRequest.certificationMonth ||
              !this.taBulkUpdateRequest.certificationDay ||
              !_.some(this.certificationTypes, ['id', this.taBulkUpdateRequest.certificationType])) )
        {
           this.toastr.error("All parts of Certification Date must be selected for 'Change To' Action.", 'Cannot Save!');
           return;
        }

        // Convert string percent values to numeric percent values.
        if ( this.taBulkUpdateRequest.defaultAnnualIncreaseAction === CommonBulkUpdateFieldActionEnum.ChangeTo )
        {
            this.taBulkUpdateRequest.defaultAnnualIncrease = new Decimal(this.defaultAnnualIncreaseDisplay).dividedBy(100).toNumber();
        }
        if ( this.taBulkUpdateRequest.yoyAction === CommonBulkUpdateFieldActionEnum.ChangeTo )
        {
            this.taBulkUpdateRequest.yoyPercent = new Decimal(this.yoyPercentDisplay).dividedBy(100).toNumber();
        }

        this.bsModalRef.hide();

        this.onClose(this.taBulkUpdateRequest);
    }

    cancel() {
        this.bsModalRef.hide();

        this.onClose();
    }

    isDisabled(action: CommonBulkUpdateFieldActionEnum){
        return action !== CommonBulkUpdateFieldActionEnum.ChangeTo;
    }

    private _isValidPercentage(value: string | number): boolean {
        if (this._isInvalidNumber(value))
            return false;

        if (isNaN(Number(value)))
            return false;

        return true;
    }


    private _isInvalidNumber(value: string | number): boolean {
        return value === null || value === undefined || value === '' || value === '.'
    }

}