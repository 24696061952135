import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GridSource } from '../tax.rate.grid.component';
import { TaxAuthority, TaxRateArea } from '../../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';

export interface ICellRendererParamsForTaxRateCommandCenterAttachments extends ICellRendererParams {
    canEdit: boolean;
    gridSofurce: GridSource;
}

@Component({
    selector: 'grid-attachments-cell',
    template:
        `
        <div (click)="$event.stopPropagation()" *ngIf="entity && entity.hasAttachments">
            <entity-attachments [(hasAttachments)]="entity && entity.hasAttachments"
                    buttonClass="btn flat-button primary-button icon-button"
                    [modalData]="attachmentModalData">
            </entity-attachments>
        </div>
        `
})
export class TaxRateCommandCenterAttachmentsCellRendererComponent implements ICellRendererAngularComp {
    entity: TaxAuthority | TaxRateArea;
    attachmentModalData: AttachmentModalData;
    canEdit: boolean = false;

    agInit(params: ICellRendererParamsForTaxRateCommandCenterAttachments): void {
        this.canEdit = params.canEdit;

        if (!params.data) {
            return;
        }

        if(params.gridSofurce == 'authorities') {
            this.entity = params.data as TaxAuthority;
            this.attachmentModalData = {
                entityType: 'TaxAuthority',
                entityName: this.entity.name,
                entityData: {
                    typeId: Core.EntityTypes.TaxAuthority,
                    id: this.entity.taxAuthorityId,
                    name: this.entity.name
                },
                readOnly: !this.canEdit
            } as AttachmentModalData;
        } else {
            this.entity = params.data as TaxRateArea;
            this.attachmentModalData = {
                entityType: 'TaxRateAuthority',
                entityName: this.entity.name,
                entityData: {
                    typeId: Core.EntityTypes.TaxRateArea,
                    id: this.entity.taxRateAreaId,
                    name: this.entity.name
                },
                readOnly: !this.canEdit
            } as AttachmentModalData;
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}