import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientServiceType } from '../constants.new';
import * as _ from 'lodash';
import { ClientServicesPanelData } from './clientServices.model';

@Injectable(
    { providedIn: 'root' }
)
export class ClientServiceResponsibilityService {
    constructor(private readonly _httpClient: HttpClient) { }

    private _clientServicesChangedSubject: Subject<void> = new Subject<void>();

    get clientServicesChange$() {
        return this._clientServicesChangedSubject.asObservable();
    }

    notifyClientServicesChange() {
        this._clientServicesChangedSubject.next();
    }

    getByEntity(entity): Promise<ClientServicesPanelData> {
        const params = new HttpParams()
            .set('entityID', entity.id)
            .set('entityType', entity.type);

        return lastValueFrom(this._httpClient.get('/api/ClientServiceResponsibility', { params: params })) as Promise<ClientServicesPanelData>;
    }

    getAll(): Promise<any> {
        return lastValueFrom(this._httpClient.get('/api/clientservice'));
    }

    async getHasComplianceResponsibility(entityID: number, entityTypeID: number): Promise<boolean> {
        const params = new HttpParams()
            .set('entityID', entityID.toString())
            .set('entityTypeID', entityTypeID.toString());

        const services = await lastValueFrom(this._httpClient.get<any[]>('/api/clientserviceresponsibility/clientservices', { params: params }));

        const complianceService = _.find(services, (x: any) =>  x.clientServiceID === ClientServiceType.ComplianceReturns ||
                x.clientServiceID === ClientServiceType.Freeport ||
                x.clientServiceID === ClientServiceType.Abatement ||
                x.clientServiceID === ClientServiceType.PollutionControl);

        return complianceService ? true : false;
    }

    async saveEntity(data: any): Promise<Core.InitiateUpdateCsrModel> {
        const result = await lastValueFrom(this._httpClient.put<Core.InitiateUpdateCsrModel>('/api/ClientServiceResponsibility', data));
        this._clientServicesChangedSubject.next();
        return result;
    }

    getAvailableTransmittalFormats(entityTypeId: number, entityId: number): Promise<{ transmittalFormatID: number, name: string }[]> {
        return lastValueFrom(this._httpClient.get<{ transmittalFormatID: number, name: string }[]>(
            `api/PaymentPackage/TransmittalFormats/EntityType/${entityTypeId}/Entity/${entityId}`
        ));
    }

    getAvailableFeedSpecs(entityTypeId: number, entityId: number): Promise<any> {
        return lastValueFrom(this._httpClient.get(`api/ClientServiceResponsibility/feedspecifications/EntityType/${entityTypeId}/Entity/${entityId}`));
    }

    //todo: refactor from this service as it doesn't appear to be related to CSR
    getPackageEmailTemplates(entityTypeId: number, entityId: number): Promise<any> {
        return lastValueFrom(this._httpClient.get(`api/PaymentPackage/PackageEmailTemplates/EntityType/${entityTypeId}/Entity/${entityId}`));
    }

    getTopLevelPropertyTypes(entityId: number, entityTypeId: number): Promise<Core.ClientServiceResponsibilitiesPropertyTypesResult> {
        return lastValueFrom(this._httpClient.get(`/api/ClientServiceResponsibility/propertytypes/${entityId}/${entityTypeId}`)) as Promise<Core.ClientServiceResponsibilitiesPropertyTypesResult>;
    }

    getPackageCovers(): Promise<Core.TransmittalPackageCoverDTO[]> {
        return lastValueFrom(this._httpClient.get('/api/PaymentPackage/transmittalPackageCovers')) as Promise<Core.TransmittalPackageCoverDTO[]>;
    }

    retryWorkflowUpdateFromQueue(req:{entityTypeId:number, entityId:number, clientServiceId: number, propertyTypeId: number}): Promise<any> {
        const params = new HttpParams()
                            .set('entityTypeId',req.entityTypeId)
                            .set('entityId',req.entityId)
                            .set('clientServiceId',req.clientServiceId)
                            .set('propertyTypeId',req.propertyTypeId);
        return firstValueFrom(this._httpClient.put('/api/clientserviceresponsibility/retry', {},{params})).then(x=>{ 
            this._clientServicesChangedSubject.next();
            return x;
        });
    }

    doesEntityHaveErrantResponsibilities(req:{entityTypeId, entityId:number}): Promise<boolean> {
        const params = new HttpParams()
                            .set('entityTypeId',req.entityTypeId)
                            .set('entityId',req.entityId);
        return firstValueFrom(this._httpClient.get<boolean>('/api/clientserviceresponsibility/anyerrant',{params}));
    }
}
