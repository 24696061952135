import { Component, OnInit } from '@angular/core';
import { AllAppVersionInfo, VersionInfo } from './about.models';
import { AboutService } from './about.service';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

declare const saveAs: (blob: Blob, fileName: string) => void;

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    loading: boolean;
    showEmailDemo: boolean;
    allAppVersionInfo: AllAppVersionInfo;
    uiVersionInfo: VersionInfo;
    reportVersionInfo: VersionInfo;
    emailButtonDisabled: boolean;

    constructor(
        private aboutService: AboutService,
        private featureFlagsService: FeatureFlagsService
    ) { }

    async ngOnInit() {
        this.loading = true;
        try {
            this.reportVersionInfo = null;
            this.showEmailDemo = this.featureFlagsService.featureFlags.enableEMLEmailDemo;
            // We should probably use promise.all to get these in parallel
            this.allAppVersionInfo = await this.aboutService.getAllAppVersionInfo();
            this.uiVersionInfo = await this.aboutService.GetUIVersionInfo();
        } finally {
            this.loading = false;
        }
    }

    async getJSReportVersionInfo() {
        this.loading = true;

        try {
            this.reportVersionInfo = await this.aboutService.getJSReportVersionInfo();
        } finally {
            this.loading = false;
        }
    }

    async runEmailDemo() {
        this.emailButtonDisabled = true;
        try {
            const emailResult = await this.aboutService.getSampleEmail();
            saveAs(emailResult.blob, emailResult.fileName);
        } finally {
            this.emailButtonDisabled = false;
        }
    }
}
