import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'import-bulk-update-action',
    templateUrl: './importBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class ImportBulkUpdateActionComponent implements OnInit{
    constructor(
    ){
        this._possibleActions = [
            { name: 'No Change', value: Compliance.ImportBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: Compliance.ImportBulkUpdateFieldActionEnum.ChangeTo },
            { name: 'Remove User Value', value: Compliance.ImportBulkUpdateFieldActionEnum.RemoveUserValue }
        ];
    }

    @Input() fieldAction: Compliance.ImportBulkUpdateFieldActionEnum;
    @Output() fieldActionChange = new EventEmitter<Compliance.ImportBulkUpdateFieldActionEnum>();
    @Input() requiredField: boolean;
    @Input() disabled: boolean;
    @Input() changeToAllowed: boolean = true;

    private _possibleActions: Compliance.NameValuePair<Compliance.ImportBulkUpdateFieldActionEnum>[];
    selectedAction: Compliance.ImportBulkUpdateFieldActionEnum = Compliance.ImportBulkUpdateFieldActionEnum.NoChange;

    ngOnInit(): void {
    }

    fieldActionChanged(action: Compliance.ImportBulkUpdateFieldActionEnum) : void{
        this.selectedAction = action;
        this.fieldActionChange.next(action);
    }

    isSelected(action: Compliance.ImportBulkUpdateFieldActionEnum): boolean{
        return action === Compliance.ImportBulkUpdateFieldActionEnum.NoChange;
    }

    get possibleActions(): Compliance.NameValuePair<Compliance.ImportBulkUpdateFieldActionEnum>[]{
        return this._possibleActions.filter(i => this.changeToAllowed || i.name != 'Change To');
    }
}
