import { Inject, Injectable } from '@angular/core';
import { StorageTokenKeys } from 'src/app/User/authStorage.service';

@Injectable(
    { providedIn: 'root' }
)
export class RouteService {
    constructor(@Inject('routes') private _routes: { [key: string]: { url: string } }) { }

    public autoSaveInitialRoute(): void {
        // To detect if the current hash would be a valid route, try to find the "base" of a route that matches the "base" of the
        // current hash. So a route might be something like /company/:companyId/site/:siteId, so the base is "company". Use this
        // regex to grab the first word after the starting /
        const routeBaseRegex = /^\#\/([^\/\?]+)([\/\?].*)?$/;

        if (routeBaseRegex.test(window.location.hash)) {
            // Get unique bases for all url properties in the route object
            const validRoutes = [...new Set(Object.keys(this._routes)
                .map(k => `#${this._routes[k].url}`)
                .filter(r => routeBaseRegex.test(r))
                .map(r => routeBaseRegex.exec(r)[1]))];

            const currentRoute = routeBaseRegex.exec(window.location.hash)[1];
            const matchedRoute = validRoutes.find(r => r === currentRoute);
            if (matchedRoute && !['home', 'login'].includes(matchedRoute)) {
                this.setInitialRoute(window.location.hash);
            }
        }
    }

    public setInitialRoute(value: string) {
        sessionStorage[StorageTokenKeys.rememberedRoute] = value;
    }

    public getInitialRoute(): string {
        return sessionStorage[StorageTokenKeys.rememberedRoute];
    }

    public clearInitialRoute(): void {
        delete sessionStorage[StorageTokenKeys.rememberedRoute];
    }
}
