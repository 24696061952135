import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { SelectComponent } from './select.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DirectivesModule } from '../Directives/directives.module';
import { SelectDropdownComponent } from './Dropdown/selectDropdown.component';
import { SelectService } from './select.service';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DirectivesModule,
        BrowserAnimationsModule
    ],
    declarations: [
        SelectComponent,
        SelectDropdownComponent
    ],
    providers: [SelectService],
    exports: [
        SelectComponent
    ]
})
export class SelectModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('wsSelect', SelectComponent);
    }
}
