import { Injectable } from "@angular/core";
import { SDHttpService } from "../Common/Routing/sd-http.service";
import { DescriptorOverride } from "./descriptor.model";

const baseUrl = '/api/descriptoroverride/';

@Injectable()
export class DescriptorOverridesService {
    constructor(private http: SDHttpService) { }

    getByCompanyId(companyId: number): Promise<DescriptorOverride[]> {
        const url = `${baseUrl}company/${companyId}`;

        return this.http.get(url);
    }

    createBatch(descriptorOverrides: DescriptorOverride[]): Promise<any> {
        return this.http.post(baseUrl, {descriptorOverrides});
    }

    update(override: any) {
        return this.http.put(baseUrl, override);
    }

    deleteById(id) {
        var url = baseUrl + id;
        var config = {};

        return this.http.delete(url, config);
    }

    getAssetUsage(companyId: number, descriptorIds: number[]): Promise<Core.EntityDescriptorOverrideAssetUsageModel[]> {
        const url = `${baseUrl}getAssetUsage/${companyId}`;

        return this.http.post(url, descriptorIds);
    }
}
