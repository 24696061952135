import { Component, KeyValueDiffers, OnInit, Renderer2, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../../Busy-Indicator'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WeissmanKeyValueDisplayPipe } from '../../../../UI-Lib/Pipes';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { EntityPickerService } from '../../../Entity-Picker/entityPicker.service';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { DecimalPipe } from '@angular/common';
import { AllocationDetailRepository } from '../../../Repositories/allocationDetail.repository';
import { AssessmentSummaryInfo } from '../assessmentSummaryInfo';
import * as _ from 'lodash';

export interface AllocationDetailBulkUpdateParams {
    allocationId: number;
    selection: Compliance.SelectedRowsModel;
    selectedCount: number;
    allocationDetailListLastModifiedTimestamp: Date;
    statuses: Compliance.AllocationDetailStatusModel[];
    assessmentsSummary: AssessmentSummaryInfo[];
    removeExistingAllocations: boolean;
}

@Component({
    selector: 'allocation-detail-bulk-update',
    templateUrl: './allocationDetailBulkUpdate.component.html',
})
export class AllocationDetailBulkUpdateComponent implements OnInit, IWeissmanModalComponent<AllocationDetailBulkUpdateParams, boolean> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _messageModalService: MessageModalService,
        private readonly _allocationDetailRepository: AllocationDetailRepository,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _entityPickerService: EntityPickerService,
        private readonly _differs: KeyValueDiffers,
        private readonly _renderer: Renderer2,
        private readonly _decimalPipe: DecimalPipe
    ) { }

    @ViewChild('bulkUpdateFieldContainer', { static: true }) bulkUpdateFormElement;

    private _assessmentsSummary: AssessmentSummaryInfo[] = null;
    private _assessmentSummaryInfo: AssessmentSummaryInfo = null;

    params: AllocationDetailBulkUpdateParams;
    result: boolean;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    bulkUpdateModel: Compliance.AllocationDetailBulkUpdateModel;

    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();

    get selectedCount(): string {
        return this._decimalPipe.transform(this.params.selectedCount);
    };

    async ngOnInit(): Promise<void> {
        const busyLoading = 'load-Metadata';
        this.busyIndicatorMessageManager.add('Loading', busyLoading);

        try {
            this._assessmentsSummary = _
                .cloneDeep(this.params.assessmentsSummary)
                .filter(x => !x.isComitted);
            _.orderBy(this._assessmentsSummary, x => x.title + `${'Z' + (x.taxAuthorityName || '')}`);

            this._assessmentsSummary.splice(0, 0,
                new AssessmentSummaryInfo({summaryType: Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned} as Compliance.AllocationAssessmentSummaryModel));

            this.bulkUpdateModel = {
                allocationId: this.params.allocationId,
                selectedRows: this.params.selection.selectedRows,
                selectAllRows: this.params.selection.selectAllRows,
                lastModifiedTimestamp: this.params.allocationDetailListLastModifiedTimestamp,
                isReconciledAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange,
                allocationAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange,
                statusAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange,
                initialAllocationAmountAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange,
                finalAllocationAmountAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange
            } as Compliance.AllocationDetailBulkUpdateModel;
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyLoading);
        }
    }

    get isValid(): boolean {
        const initialAllocationAmountSelectionValid =
            this.bulkUpdateModel.initialAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
                !!this.bulkUpdateModel.initialAllocationAmount ||
            this.bulkUpdateModel.initialAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride ||
            this.bulkUpdateModel.initialAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank ||
            this.bulkUpdateModel.initialAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.initialAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.CopyReturnValue;

        const finalAllocationAmountSelectionValid =
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
                !!this.bulkUpdateModel.finalAllocationAmount ||
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride ||
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank ||
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.CopyReturnValue ||
            this.bulkUpdateModel.finalAllocationAmountAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.DistributeVariance;

        const allocationSelectionValid =
            this.bulkUpdateModel.allocationAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
                !!this._assessmentsSummary ||
            this.bulkUpdateModel.allocationAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride ||
            this.bulkUpdateModel.allocationAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank ||
            this.bulkUpdateModel.allocationAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;

        const statusSelectionValid =
            this.bulkUpdateModel.statusAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
            !!this.bulkUpdateModel.status ||
            this.bulkUpdateModel.statusAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank ||
            this.bulkUpdateModel.statusAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;

        const isReconciledSelectionValid =
            this.bulkUpdateModel.isReconciledAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
                this.bulkUpdateModel.isReconciled !== null ||
            this.bulkUpdateModel.isReconciledAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank ||
            this.bulkUpdateModel.isReconciledAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;

        const atLeastOneItemChanged = this.bulkUpdateModel.initialAllocationAmountAction !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.finalAllocationAmountAction !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.allocationAction !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.statusAction !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange ||
            this.bulkUpdateModel.isReconciledAction !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;

        return initialAllocationAmountSelectionValid && finalAllocationAmountSelectionValid && allocationSelectionValid &&
            statusSelectionValid && isReconciledSelectionValid && atLeastOneItemChanged;
    }

    get assessmentsSummary(): AssessmentSummaryInfo[]{
        return this._assessmentsSummary;
    }

    get assessmentSummary(): AssessmentSummaryInfo{
        return this._assessmentSummaryInfo;
    }

    set assessmentSummary(value: AssessmentSummaryInfo) {
        if (value.allocationAnnualAssessmentId) {
            this._assessmentSummaryInfo = value;
            this.bulkUpdateModel.allocation = {
                lastModifiedTimestamp: this.params.allocationDetailListLastModifiedTimestamp,
                selectedRows: this.params.selection.selectedRows,
                selectAllRows: this.params.selection.selectAllRows,
                allocationAnnualAssessmentIds: [this._assessmentSummaryInfo.allocationAnnualAssessmentId]
            } as Compliance.AllocationDetailAllocateModel;
        } else {
            this._assessmentSummaryInfo = null;
            this.bulkUpdateModel.allocation = null;
        }
    }

    async save(force: boolean = false): Promise<void> {
        if (!force && this.bulkUpdateModel.allocationAction === Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride) {
            this.bulkUpdateModel.allocation = {
                allocationAnnualAssessmentIds: null,
                lastModifiedTimestamp: this.params.allocationDetailListLastModifiedTimestamp,
                selectedRows: this.params.selection.selectedRows,
                selectAllRows: this.params.selection.selectAllRows
            };
        }

        const busyLoading = 'updating';
        this.busyIndicatorMessageManager.add('Updating Assets', busyLoading);

        let confirmMessage: string = '';

        try {
            this.bulkUpdateModel.force = force;

            await lastValueFrom(this._allocationDetailRepository.bulkUpdate(this.params.allocationId, this.bulkUpdateModel));

            this.result = true;
            this._bsModalRef.hide();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        } finally{
            this.busyIndicatorMessageManager.remove(busyLoading);
        }

        if (confirmMessage) {
            try {
                await this._messageModalService.confirm(confirmMessage,'Confirm Update');
            } catch (e3) {
                return Promise.resolve();
            } finally {
                this.busyIndicatorMessageManager.remove(busyLoading);
            }

            // force
            await this.save(true);
        }

        return Promise.resolve();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    isDisabled(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum){
        return action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo &&
            action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.DistributeVariance;
    }

    initialAllocationAmountActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        if (action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo){
            this.bulkUpdateModel.initialAllocationAmount = null;
        }
    }

    finalAllocationAmountActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        if (action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo){
            this.bulkUpdateModel.finalAllocationAmount = null;
        }
    }

    parcelActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        if (action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo){
            this.bulkUpdateModel.allocation = null;
        }
    }

    statusActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        if (action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo){
            this.bulkUpdateModel.status = null;
        }
    }

    isReconciledActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        if (action !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo){
            this.bulkUpdateModel.isReconciled = null;
        }
    }

    getParcelOptionText(option: AssessmentSummaryInfo): string{
        return option.isTaxAuthority ? `\xA0\xA0${option.taxAuthorityName}` : option.title;
    }
}
