'use strict';

import { Subject } from "rxjs";

/**
 * @ngdoc function
 * @name weissman.controller:ParcelCtrl
 * @description
 * # ParcelCtrl
 * Controller of the weissman
 */

angular.module('weissmanApp')
	.controller('ParcelCtrl', [
		'$scope',
		'$rootScope',
		'sd.Http.Service',
		'$stateParams',
		'StatesService',
		'$q',
		'$state',
		'utils',
		'PermissionFlags',
		'SD.Restrict.Service',
		'ParcelService',
		'sd.Site.Service',
		'toastr',
        'csrService',
        'PropertyTypes',
        'annualDetailsNavigationEventService',
		'RyanInstanceId',
		'productAnalyticsService',
		'ActivityStatusIdMap',
		'sd.Company.Service',
		'instanceRepository',
		weissNg1Wrap(function ($scope, $rootScope, sdHttp, $stateParams, StatesService, $q, $state, utils, PermissionFlags, restrictService, parcelService, siteService, toastr, csrService, PropertyTypes, annualDetailsNavigationEventService,RyanInstanceId, productAnalyticsService, ActivityStatusIdMap, companyService, InstanceRepository) {
			//////////////////////////
			//Initialize variables  //
			//////////////////////////
            var vm = this;

            annualDetailsNavigationEventService.resetAnnualDetails();

			$scope.maximizedPanel = null;
			$scope.siteId = $stateParams.siteId;
			$scope.companyId = $stateParams.companyId;
			$scope.parcelId = parseInt($stateParams.parcelId, 10);

			$scope.prevParcelId = undefined;
			$scope.nextParcelId = undefined;

			// Resets scope.active
			function initActive() {
			    return {
			        year: false,
			        revision: false,
			        billTab: false,
			        view: false,
			        loading: false,
			        appeal: {}
			    };
			}

			$scope.siteId = $stateParams.siteId;
			$scope.companyId = $stateParams.companyId;
			$scope.permissionFlags = PermissionFlags.WRITE;

			$scope.hasWritePermission = false;
            $scope.hasComplianceResponsibility = false;
			$scope.loadingData = true;

			// For following the existence of annual bill tabs
			$scope.taxes = {
				tabs: undefined
			};

		    $scope.selected = {
		        revision: undefined
		    };

		    $scope.situs = {
		        thisVarIsDefinedWhenNotSaving: true
		    };

			$scope.$on('maximize_change', function (event, changeData) {
			    //console.log([changeData.maximized, changeData.panelName]);
                $scope.panelMaximized = changeData.maximized ? changeData.panelName : null;
			});

			resetStrikeThru();

			// Not implemented
			$scope.activity = {
				upcoming: true,
				comments: false,
				invoicing: true
			};

			$scope.maximize = false;

            var clientServicesChangeSub = csrService.clientServicesChange$.subscribe(function () {
                csrService.getHasComplianceResponsibility($scope.parcelId, 6).then(function (result) {
                    $scope.hasComplianceResponsibility = result;
                });
            });

		    $scope.$on('$destroy',
		        function() {
		            clientServicesChangeSub.unsubscribe();
		        });

			function resetStrikeThru() {
				$scope.strikeThru = {
					status: false,
					savings: false
				};
			}

			$scope.loadClientServices$ = new Subject();

			//////////////////////////
			// controller functions //
			//////////////////////////

			function doPermissionCheck(id) {
			    const restrictionData = {
			        isCompany: false,
			        entityId: parseInt($scope.siteId),
			        roles: [],
			        flag: restrictService.PermissionFlags.WRITE
			    };

				return restrictService.hasPermission(restrictionData)
					.then(function (result) {
						$scope.hasWritePermission = result;
						return result;
					})
					.catch(function (msg) {
						console.log('checkEntity - error', msg);
					});
			}

			///Returns unique components (listed to right by 'Asmts')
			function getComponents(years) {
				var components = _.chain(years)
					.map('annualGridDetails')
					.flatten()
					.map('annualGridComponents')
					.flatten()
					.uniqBy('assessmentClassComponentID')
					.value();

				return components;
			}


			// Returns an array of unique component types from the parcel's asssessment class(es)
			// Note that this same logic is needed for document processing, but it is done in the
			// backend. If this code needs to be changed, please make sure to also review the API
			// call to /api/annualassessmentview/assessment/[assessmentID]. As of this writing,
			// that code is at line 193 of roject_weissman\weissman.dataaccesslayer\assessment\annualyeardal.cs
			function getCompTypes() {
				var compTypes = [];

				angular.forEach($scope.parcel.parcelAssessmentClasses, function (aClass) {
					//console.log("parcel.js - getCompTypes - aClass.assessmentClass.assessmentClassComponents", aClass.assessmentClass.assessmentClassComponents)
					var uniqueComps = _.chain(aClass.assessmentClass.assessmentClassComponents)
						.filter({
							propertyTypeID: $scope.parcel.propertyTypeID
						})
						.uniqBy('assessmentComponentID')
						.map(function (comp) {
							comp.componentName = comp.assessmentComponent.componentName;
							if ($scope.parcel.parcelAssessmentClasses.length > 1) {
								comp.componentName = aClass.assessmentClass.name + ' - ' + comp.componentName;
							}

							return comp;
						})
						.value()

					compTypes = _.union(compTypes, uniqueComps)
				})

				//console.log("parcel.js -  getCompTypes - compTypes =  ", compTypes)

				return compTypes;
			}

			// removes <new> from dropdown list
			function removeNewRevision() {
				_.remove($scope.active.year.annualGridDetails, {
					revisionDesc: '<New>'
				})
			}

			//////////////////
			//HTTP Requests //
			//////////////////

			var calls = [
                /*companyService.getCompanyName($stateParams.companyId),
                siteService.getSiteName($stateParams.siteId),
                parcelService.get({
                    parcelId: $scope.parcelId,
                    siteId: $scope.siteId
                }),*/
                parcelService.getView($scope.parcelId, true),
                StatesService.getSummary(),
                siteService.getParcelIdsBySiteId($stateParams.siteId),
				csrService.getHasComplianceResponsibility($scope.parcelId, 6),
				doPermissionCheck($scope.parcelId),
				InstanceRepository.getEntityInstanceId('parcel', $scope.parcelId).toPromise()
            ];

		    var companyIDErrMsg = 'Company ID does not match Parcel';
		    var siteIDErrMsg = 'Site ID does not match Parcel';
		    var notFoundErrMsg = 'Parcel not found';
			// After all requests return, this function runs setting up various entities
			var initializeDeferred = $q.all(calls).then(function (result) {
                var companyId = result[0].companyId;
			    var siteId = result[0].siteId;
				const topLevelCompanyId = result[0].topLevelCompanyId;

			    if (companyId != $stateParams.companyId) {
			        throw new Error(companyIDErrMsg);
			    }
			    if (siteId != $stateParams.siteId) {
			        throw new Error(siteIDErrMsg);
				}

				$scope.ppReturnPreparationAllowed = result[0].ppReturnPreparationAllowed;
                $scope.companyName = result[0].companyName;
                $scope.topLevelCompanyId = result[0].topLevelCompanyId;
			    $scope.siteName = result[0].siteName;
                $scope.parcel = result[0].parcel;
				$scope.parcelViewDTO = result[0];
                $scope.reportsSites = result[0].reportsSites;

				$scope.hasPrivateItemsPermission = restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSVIEW) ||
    				restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSEDIT);

                $scope.showComplianceInfo = result[0].ppReturnPreparationAllowed
                    && restrictService.hasInstanceRight(restrictService.InstanceRights.COMPLIANCEFEATURESVIEW, $scope.parcel.site.company.instanceID)
                    && result[0].parcel.propertyTypeID === PropertyTypes.PP.propertyTypeID;

			    $scope.entity = {
			        id: $scope.parcel.parcelID,
			        name: $scope.parcel.acctNum,
			        // ToDo: Create a global entity enum that encapsulates type and typeid?
			        type: 'parcel',
			        typeId: 6,
			        newAllowed: false,
					canEditCompanySetups: $scope.parcel.canEditCompanySetups,
					hasWritePermission: result[4],
					isInactive: $scope.parcel.activityStatusID === 0
				};

			    $scope.compTypes = getCompTypes();
                $scope.states = result[1];
                const parcelState = result[1].find(s => s.stateID === result[0].parcel.address.stateID);
                $scope.stateAllowConsolidating = parcelState && parcelState.allowConsolidating;
                vm.parcelIds = result[2];
                $scope.hasComplianceResponsibility = result[3];
				$scope.instanceId = result[5];
			    $scope.loadingData = false;

			    identifyPrevAndNextParcelsIds(vm.parcelIds);

				const promises = [siteService.load(siteId, companyId)];

				if(companyId != topLevelCompanyId){ //No need to lookup the company again if they are the same
					promises.push(companyService.loadCompanyParent(companyId, topLevelCompanyId));
				}

				$q.all(promises).then(([site, topLevelCompany]) => {});
			}, function (ajaxError) {
			    if (ajaxError.status === 404) {
			        throw new Error(notFoundErrMsg);
			    }
			}).catch(function (err) {
			    if ([companyIDErrMsg, siteIDErrMsg, notFoundErrMsg].indexOf(err.message) >= 0) {
                    toastr.error(err.message);
			    }
			    $scope.loadingData = false;
			    throw err;
			});

			function identifyPrevAndNextParcelsIds(parcelIds) {
				if (parcelIds.length < 2) // we have no more parcels
					return;

				var i = parcelIds.indexOf($scope.parcelId);
				if (i < 0) // this parcel id can't be found
					throw ('identifyPrevAndNextParcelsIds - unable to find current parcel id in the parcelIds array');

				var prevIndex = i - 1;
				var nextIndex = i + 1;

				if (prevIndex >= 0)
					$scope.prevParcelId = parcelIds[prevIndex];

				if (nextIndex < parcelIds.length)
					$scope.nextParcelId = parcelIds[nextIndex];
			}


			$scope.getAnnualGridAndCompTypes = function (getCompTypesOnly) {
				//getting parcel here when we don't need to. Already fresh on info panel.
				//need refactor.

				parcelService.get({
						siteId: $stateParams.siteId,
						parcelId: $stateParams.parcelId
					})
					.then(function (result) {
						$scope.parcel = result;
						$scope.compTypes = getCompTypes();

						if(!getCompTypesOnly) {
							$scope.getAnnualGrid();
							csrService.getHasComplianceResponsibility($scope.parcelId, 6).then(function (result) {
								$scope.hasComplianceResponsibility = result;
							});
						}
					})
			}

			$scope.boundAnnualGridCallback = $scope.getAnnualGridAndCompTypes.bind($scope)

			// Gets annual grid data
			$scope.getAnnualGrid = function () {
				//console.log("getting annual grid");

				initializeDeferred.then(function () {

					//console.log("getting annual grid - initializeDeferred.then");

                    $scope.active = initActive();
                    $scope.reload = {
                        grid: false
                    };

                    resetStrikeThru();
                    $scope.years = undefined;

                    var aavConfig = {
                        params: {
                            parcelID: $stateParams.parcelId,
                            getAllYears: false
                        }
                    };
                    sdHttp.get('/api/annualassessmentview/', aavConfig)
                        .then(function (years) {
							$scope.annualYearData = years;
                            $scope.components = getComponents(years.years);
                            //console.log("parcel.js -  $scope.getAnnualGrid - $scope.components =  ", $scope.components);
                            $scope.years = _.map(years.years, function (year) {
                                year.collapsedAppealStatus = Date.parse(year.collapsedAppealStatus) ? moment.utc(year.collapsedAppealStatus).format('M/D/YYYY') : year.collapsedAppealStatus;

                                year.annualGridDetails = _.map(year.annualGridDetails, function (revision) {
                                    revision.appealStatus = Date.parse(revision.appealStatus) ? moment.utc(revision.appealStatus).format('M/D/YYYY') : revision.appealStatus;
                                    return revision;
                                })

                                return year;
                            });
                        });
                });
			}

			$scope.getAnnualGrid();

			$rootScope.$on('parcel:parcelInfoChanged', function (event, updatedParcel) {
				console.log('received event parcel:parcelInfoChanged ', updatedParcel);
				// todo: should we refresh the whole parcel?
				$scope.parcel.parcelAssessmentClasses = updatedParcel.parcelAssessmentClasses;
			});

			//////////////////////
			//Display Functions //
			//////////////////////

			// This function filters a list by elements where efaction !== 'delete'
			$scope.nonDeleted = utils.nonDeleted;

			////////////
			//Actions //
			////////////

			$scope.openParcel = function (parcelId) {
				if (!parcelId)
					return;

				$state.go('parcel', {
					companyId: $scope.companyId,
					siteId: $scope.siteId,
					parcelId: parcelId
				});
			}

			$scope.maximizeChanged = function (targetPanelName, maximized) {
                $scope.panelMaximized = maximized ? targetPanelName : null;
			}
		})
	]);
