import { NgModule } from '@angular/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../../UI-Lib/Pipes/shared-pipes.module';
import { GLAccountSelectorComponent } from './glAccountSelector.component';


@NgModule({
    imports: [
        WeissmanSharedModule,
        SharedPipesModule,
        TypeaheadModule
    ],
    declarations: [GLAccountSelectorComponent],
    exports: [GLAccountSelectorComponent]
})
export class GLAccountSelectorModule {
}
