<ws-modal-wrapper headerLabel="Allocate Cost By Percentage"
                  [hideCancel]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="saveDisabled()">
    <div class="asset-info-cost-status">
        <span class="source-cost">
            Calculated Source Cost: {{calculatedCost | currency: 'USD'}}
        </span>
        <span class="unattributed-percent" helpTooltip helpContentId="asset-info.unattributed-percent">
            Unattributed Source Percent: {{ unattributedPercent }}
        </span>
    </div>
    <form (change)="onUpdatePercentages()">
        <div class="mb-4">
            <div class="asset-cost-header-line">
                <div class="asset-name ws-flex-auto">Asset Number</div>
                <div>Percentage Cost</div>
                <div class="ws-flex-auto cost-amount">Cost</div>
            </div>
            <div *ngFor="let asset of assets; let i = index" class="asset-percent-edit-row ws-flex-container-horizontal ws-stretch ws-overflow-auto">
                <div class="asset-name ws-flex-auto">{{ asset.reportedAssetNumber }}</div>
                <div class="input-group  ws-flex-auto">
                    <input type="number" class="form-control ws-text-align-left" [name]="'percentControl' + i" [(ngModel)]="percentages[i]" (change)="onUpdatePercentages()" decimalNumber [decimalPrecision]="5">
                    <span class="input-group-text">%</span>
                </div>
                <div class="ws-flex-auto cost-amount">{{ costAmounts[i] | currency: 'USD'}}</div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
