import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { RevisionChangeTypes } from '../../constants.new';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { ProcessingService } from '../processing.service.upgrade';

export interface ProcessNoAppealParams {
    taskIDs: number[];
    searchTimestamp: Date;
    updateAppealRecommendation: boolean;
}

export interface ProcessNoAppealForm {
    taskIDs: number[];
    revisionChangeTypeID: number;
    newRevisionDescription: string;
    comment: string;
    searchTimestamp: Date;
    updateAppealRecommendation: boolean;
}

@Component({
    selector: 'process-no-appeal',
    templateUrl: './processNoAppeal.component.html'
})
export class ProcessNoAppealComponent implements OnInit, IWeissmanModalComponent<ProcessNoAppealParams, Core.BulkOperationResult[]> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _processingService: ProcessingService) {
    }

    @ViewChild('other') other: ElementRef;

    params: ProcessNoAppealParams;
    result: Core.BulkOperationResult[];

    loading: boolean;
    payload: ProcessNoAppealForm;
    revisionChangeTypes = RevisionChangeTypes;
    isClientApprovalRequired: boolean;

    async ngOnInit(): Promise<void> {
        this.loading = true;

        try {
            const result = await this._processingService.getAppealLevels(this.params.taskIDs);
            this.isClientApprovalRequired = result.isClientApprovalRequired;

            this.payload = {
                taskIDs: this.params.taskIDs,
                revisionChangeTypeID: this._actionViewPersistenceService.revisionChangeTypeID || 1,
                newRevisionDescription: '',
                comment: null,
                searchTimestamp: this.params.searchTimestamp,
                updateAppealRecommendation: this.params.updateAppealRecommendation
            };
        } finally {
            this.loading = false;
        }
    }

    focus(): void {
        if (this.other) {
            this.other.nativeElement.focus();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async process(): Promise<void> {
        this.loading = true;
        this._actionViewPersistenceService.revisionChangeTypeID = this.payload.revisionChangeTypeID;

        try {
            this.result = await this._processingService.processNoAppealWarranted(this.payload);
            this._bsModalRef.hide();
        } finally {
            this.loading = false;
        }
    }
}
