import { Address } from '../../Common/Models/common.model';
import { efAction } from '../../constants.new';

export interface TaxRatesPanelPayload {
    taxRateAreas: TaxRateArea[];
    taxAuthorities: TaxAuthority[];
}

export class TaxRateArea {
    constructor() {
        this.disabled = false;
        this.efAction = 'add';
    }

    taxRateAreaId: number;
    name: string;
    code: string;
    addressId: number;
    address: Address;
    disabled: boolean;
    mayBeDeleted: boolean;
    assessorId: number;
    collectorId: number;
    hasAttachments: boolean;
    hasComments: boolean;
    rowVersion: string;
    efAction: efAction;
    yearRateSummaryList: YearRateSummary[];
    details: TaxRateAreaDetail[];
}

export interface YearRateSummary {
    year: number;
    rate: number;
    rateChange: number;
    isActual: boolean;
}

export interface TaxRateAreaDetail {
    taxRateAreaTaxAuthorityDetailId: number;
    taxRateAreaId: number;
    taxAuthorityId: number;
    yearBegin: number;
    yearEnd: number;
    sequence: number;
    rowVersion: string;
    efAction: efAction;
}

export class TaxAuthority {
    constructor() {
        this.obtainInAdvance = true;
        this.disabled = false;
        this.efAction = 'add';
    }

    address: Address;
    details: TaxAuthorityDetail[];
    taxRateAreaTaxAuthorityDetails: TaxRateAreaTaxAuthorityDetail[];
    taxAuthorityId: number;
    name: string;
    code: string;
    ptxId: string;
    addressId: string;
    taxAuthorityCategoryId: number;
    taxableAssessmentTypeId: number;
    obtainInAdvance: boolean;
    disabled: boolean;
    defaultAnnualRateIncrease: number;
    defaultAnnualRateIncreaseAsPct: string;
    certificationMonth: number;
    certificationDay: number;
    certificationType: any;
    certificationDate: Date;
    collectorId: number;
    hasAttachments: boolean;
    hasComments: boolean;
    qcRequestTimeUtc: Date;
    qcRequestUserFullName: string;
    rowVersion: string;
    efAction: efAction;
}

export class TaxAuthorityDetail {
    constructor(taxAuthorityId: number) {
        this.efAction = 'add';
        this.taxAuthorityId = taxAuthorityId;
        this.rateChange = null;
        this.rate = null;
    }

    taxAuthorityDetailId: number;
    taxAuthorityId: number;
    taxYear: number;
    rate?: number;
    rateAsPct: string;
    rateAsMills: string;
    rateChange?: number;
    rateChangeAsPercent: string;
    // rateChangeAsMills: string;
    billsUtilizing: number;
    taxAuthorityStatusId: number;
    rowVersion: string;
    qcRequestTimeUtc: Date;
    qcRequestUserId: string;
    qcRequestUserFullName: string;
    efAction: efAction;
}

export class TaxRateAreaTaxAuthorityDetail {
    constructor(taxRateAreaId: number, taxAuthorityId: number) {
        this.yearBegin = null;
        this.yearEnd = null;
        this.efAction = 'add';
        this.taxRateAreaId = taxRateAreaId;
        this.taxAuthorityId = taxAuthorityId;
    }

    taxRateAreaTaxAuthorityDetailId: number;
    taxRateAreaId: number;
    taxAuthorityId: number;
    yearBegin: number;
    yearEnd: number;
    sequence: number;
    rowVersion: string;
    efAction: efAction;
    taxRateAreaName: string;
}

export class TaxRateAreaInclusions {
    constructor() {
        this.yearsIncluded = [];
    }

    taxRateAreaName: string;
    yearsIncluded: boolean[];
}
