import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ParcelListActionCellRendererParams extends ICellRendererParams {
    canSetReporting: (params: ParcelListActionCellRendererParams) => boolean;
    setReporting: (params: ParcelListActionCellRendererParams) => void;
    canRemoveReporting: (params: ParcelListActionCellRendererParams) => boolean;
    removeReporting: (params: ParcelListActionCellRendererParams) => void;
    canSetConsolidated: (params: ParcelListActionCellRendererParams) => boolean;
    setConsolidated: (params: ParcelListActionCellRendererParams) => void;
    canRemoveConsolidated: (params: ParcelListActionCellRendererParams) => boolean;
    removeConsolidated: (params: ParcelListActionCellRendererParams) => void;
    canOptOutConsolidated: (params: ParcelListActionCellRendererParams) => boolean;
    optOutConsolidated: (params: ParcelListActionCellRendererParams) => void;
    canOptInConsolidated: (params: ParcelListActionCellRendererParams) => boolean;
    optInConsolidated: (params: ParcelListActionCellRendererParams) => void;
    stateAllowConsolidating: (params: ParcelListActionCellRendererParams) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button
            *ngIf="params.canSetReporting(params)"
            class="btn flat-button primary-button icon-button grid-action-button"
            helpTooltip
            helpContentId="parcel-list-panel.set-reporting"
            position="bottom"
            (click)="params.setReporting(params)">
            <i class="fa fa-file-text-o"></i>
        </button>
        <button
            *ngIf="params.canRemoveReporting(params)"
            class="btn flat-button warning-button icon-button grid-action-button"
            helpTooltip
            helpContentId="parcel-list-panel.remove-reporting"
            position="bottom"
            (click)="params.removeReporting(params)">
            <i class="fa fa-file-text-o"></i>
        </button>
        <span *ngIf="!(params.canSetReporting(params) || params.canRemoveReporting(params))">
            <i class="fa fa-fw"></i>
        </span>
        <button
            *ngIf="params.canSetConsolidated(params)"
            class="btn flat-button primary-button icon-button grid-action-button"
            helpTooltip
            [helpContentId]="params.stateAllowConsolidating(params) ? 'parcel-list-panel.set-state-consolidated':'parcel-list-panel.set-site-consolidated'"
            position="bottom"
            (click)="params.setConsolidated(params)">
            <i class="fa fa-sitemap"></i>
        </button>
        <button
            *ngIf="params.canRemoveConsolidated(params)"
            class="btn flat-button warning-button icon-button grid-action-button"
            helpTooltip
            [helpContentId]="params.stateAllowConsolidating(params) ? 'parcel-list-panel.remove-state-consolidated':'parcel-list-panel.remove-site-consolidated'"
            position="bottom"
            (click)="params.removeConsolidated(params)">
            <i class="fa fa-sitemap"></i>
        </button>
        <button
            *ngIf="params.canOptOutConsolidated(params)"
            class="btn flat-button warning-button icon-button grid-action-button"
            helpTooltip
            helpContentId="parcel-list-panel.opt-out-consolidated"
            position="bottom"
            (click)="params.optOutConsolidated(params)">
            <i class="fa fa-sitemap"></i>
        </button>
        <button
            *ngIf="params.canOptInConsolidated(params)"
            class="btn flat-button primary-button icon-button grid-action-button"
            helpTooltip
            helpContentId="parcel-list-panel.opt-in-consolidated"
            position="bottom"
            (click)="params.optInConsolidated(params)">
            <i class="fa fa-sitemap"></i>
        </button>
        `
})
export class ParcelListActionCellRendererComponent implements ICellRendererAngularComp {
    params: ParcelListActionCellRendererParams;

    agInit(params: ParcelListActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
