import { Injectable} from '@angular/core';
import { DragulaService } from 'ng2-dragula'
import { Observable , BehaviorSubject , Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface AllocationDetailDroppedEvent {
    element: Element;
    draggedAllocationDetailIds: number[];
};

@Injectable()
export class AllocationDetailDragAndDropService {
    constructor (private readonly _dragulaService: DragulaService) { }

    private _getAllocationDetailIdsFn: () => Promise<number[]>;

    private _activeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _draggingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _draggingOverSubject: BehaviorSubject<Element> = new BehaviorSubject(null);
    private _droppedSubject: Subject<AllocationDetailDroppedEvent> = new Subject();

    private _dropComplete: boolean = true;

    private _destroy$: Subject<void> = new Subject();

    readonly BAG_NAME = 'ALLOCATION_DETAIL_DRAG_AND_DROP';
    readonly CONTAINER_TYPE_SOURCE = 'ALLOCATION_DETAIL_DRAG_AND_DROP_CONTAINER_TYPE_SOURCE';
    readonly CONTAINER_TYPE_TARGET = 'ALLOCATION_DETAIL_DRAG_AND_DROP_CONTAINER_TYPE_TARGET';

    active$: Observable<boolean> = this._activeSubject.asObservable();

    /**
     * An observable that notifies the container being dragged.
     */
    dragging$: Observable<boolean> = this._draggingSubject.asObservable();

    /**
     * An observable that notifies the container that the mouse is being dragged over.
     */
    draggingOver$: Observable<Element> = this._draggingOverSubject.asObservable();

    /**
     * An observable that notifies the container an items gets dropped in.
     */
    dropped$: Observable<AllocationDetailDroppedEvent> = this._droppedSubject.asObservable();

    /**
     * Initializes subscriptions to the dragula service and notifies about drag/drop events.
     * Because the drag targets are in a grid with an infinity scroll model, the service does not keep track of all available drag targets.
     * It takes in a function that returns a list of all selected IDs for the drag targets. This list is made available to the drop event.
     */
    start(fn: () => Promise<number[]>): void {
        const isStarted = this._dragulaService.find(this.BAG_NAME);
        if (isStarted) {
            console.warn('Allocation Detail drag-and-drop service is already listening for events.');
            return;
        }

        this._getAllocationDetailIdsFn = fn;

        this._dragulaService.createGroup(this.BAG_NAME, this.getDragulaOptions());

        // TODO: Use the new events API (https://github.com/valor-software/ng2-dragula/blob/master/MIGRATION-v2.md)
        this._dragulaService.drag(this.BAG_NAME).pipe(takeUntil(this._destroy$))
            .subscribe(() => this._draggingSubject.next(true));

        this._dragulaService.dragend(this.BAG_NAME).pipe(takeUntil(this._destroy$), filter(() => this._dropComplete))
            .subscribe(() => this._draggingSubject.next(false));

        this._dragulaService.over(this.BAG_NAME).pipe(takeUntil(this._destroy$))
            .subscribe(value => this._draggingOverSubject.next(value.source));

        this._dragulaService.out(this.BAG_NAME).pipe(takeUntil(this._destroy$))
            .subscribe(() => this._draggingOverSubject.next(null));

        // we need to undo the drop event as we don't want the source element to actually move into the target container
        // there is currently no support from the library to do that
        // it is currently being handled via stylesheets by not displaying the dropped elements
        // TODO: cancel drop event or if a parameter gets exposed in the future to not "actually" drop the element use that
        this._dragulaService.drop(this.BAG_NAME).pipe(takeUntil(this._destroy$)).subscribe(value => {
            this._dropComplete = false;
            // fetch the allocation detail IDs in order to make them available to the drop event
            this._getAllocationDetailIdsFn().then((draggedAllocationDetailIds) => {
                const droppedEvent: AllocationDetailDroppedEvent = {
                    element: value.target,
                    draggedAllocationDetailIds: draggedAllocationDetailIds
                };
                this._droppedSubject.next(droppedEvent);
                this._dropComplete = true;
                this._draggingSubject.next(false);
            }, () => {});
        });

        this._activeSubject.next(true);
    }

    /**
     * Disposes subscriptions to the dragula service.
     */
    stop(): void {
        this._activeSubject.next(false);

        this._destroy$.next();

        this._draggingSubject.next(false);
        this._draggingOverSubject.next(null);

        this._getAllocationDetailIdsFn = null;

        const isStarted = this._dragulaService.find(this.BAG_NAME);
        if (isStarted) {
            this._dragulaService.destroy(this.BAG_NAME);
        }
    }

    getDragulaOptions(): any {
        return {
            copy: true,
            copyItem: (item) => ({ ...item }),
            revertOnSpill: true,
            removeOnSpill: false,
            accepts: (el: Element, target: Element, source: Element, sibling: Element) => target.getAttribute('dragula-container-type') !== this.CONTAINER_TYPE_SOURCE,
            moves: (el: Element, target: Element, source: Element, sibling: Element) => target.getAttribute('dragula-container-type') === this.CONTAINER_TYPE_SOURCE
        };
    }
}
