import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridOptions, GridReadyEvent, GridApi, ColDef, RowNode } from 'ag-grid-community';
import { ReturnService } from '../../../return.service';
import { AgGridOptionsBuilder } from '../../../../AgGrid/agGridOptionsBuilder';
import { AgGridColumns } from '../../../../AgGrid/agGridColumns';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnRepository } from '../../../../Repositories';
import { ReturnSettingsReportAddComponent } from '../Report-List-Add/returnSettingsReportAdd.component';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import * as _ from 'lodash';
import { ReturnSettingsService } from '../returnSettings.service';
import { AgGridFilterParams } from '../../../../AgGrid/agGridFilterParams';
import { ReturnSettingsReportListGridActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { ICellRendererParamsForReturnSettingsReportListComponentGridAction } from './agGridActionCellRenderer.component';
import { Subscription, BehaviorSubject, lastValueFrom } from 'rxjs';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../../WeissmanMutexService';
import { BusyIndicatorService } from '../../../../../Busy-Indicator/busyIndicator.service';
import { ReportManagerModalService } from '../../../../../Reporting/Manager/reportManagerModal.service';
import { ReportManagerModalInputParameters } from '../../../../../Reporting/Manager/reportManagerModal.component';
import { ReturnAssetsService } from '../../Assets/returnAssets.service';
import { ProductAnalyticsService } from "../../../../../Common/Amplitude/productAnalytics.service";

export interface FilingBatchReportRowModel extends Compliance.FilingBatchReportModel{
    deleted: boolean;
    added: boolean;
    changed: boolean;
    displayName: string;
}

@Component({
    selector: 'return-settings-report-list',
    templateUrl: './returnSettingsReportList.component.html'
})
export class ReturnSettingsReportListComponent implements OnInit, OnDestroy, IReturnPartComponent, IMutexServiceHandler {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnSettingsService: ReturnSettingsService,
        private readonly _returnRepository: ReturnRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _reportManagerModalService: ReportManagerModalService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    private _gridApi: GridApi;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _reports: FilingBatchReportRowModel[];

    get filingBatchId(): number {
        return this._returnService.sharedState ? this._returnService.filingBatchId : null;
    }

    get canEdit(): boolean {
        return !this._returnService.isReturnInReadOnlyMode && this._returnService.canEditCompany;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._returnService.editGroup);
    }

    get hasChanges(): boolean {
        let hasChanges: boolean = false;

        this._gridApi.forEachNode((node: RowNode) => {
            const report = node.data as FilingBatchReportRowModel;
            if (report && (report.deleted || report.added || report.changed)) {
                hasChanges = true;
            }
        });

        return hasChanges;
    }

    isInitialized: boolean = false;

    editMode: boolean = false;

    gridId: System.Guid = 'F2B32551-A795-4A16-BE55-024408596611';

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'grid-row-deleted': params => params.data && params.data.deleted
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .build();

    ngOnInit(): void {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._returnService.editGroup);
            }
        });

        this._returnSettingsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnSettingsService.unsubscribeFromServiceActivationCycle(this);
        this._editModeSub.unsubscribe();
        this._mutexService.release(this, this._returnService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'displayName',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Type',
                field: 'isSystem',
                width: AgGridColumns.textColumnSmallWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const report = params.data as Compliance.FilingBatchReportModel;
                    return this._returnService.getReportType(report);
                },
                filterValueGetter: params => {
                    const report = params.data as Compliance.FilingBatchReportModel;
                    return this._returnService.getReportType(report);
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: ReturnSettingsReportListGridActionCellRendererComponent,
                cellRendererParams: {
                    canEdit: this._canEdit.bind(this),
                    canEnterEditMode: this._canEnterEditMode.bind(this),
                    edit: this._edit.bind(this),
                    getEditTitle: this._getEditTitle.bind(this),
                    canRemove: this._canRemove.bind(this),
                    remove: this._remove.bind(this),
                    canUndoRemove: this._canUndoRemove.bind(this),
                    undoRemove: this._undoRemove.bind(this),
                } as ICellRendererParamsForReturnSettingsReportListComponentGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'displayName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async onReturnPartServiceActivated(): Promise<void> {
        if (!this.isInitialized) {
            const reports = await lastValueFrom(this._returnRepository.getFilingBatchReports(this.filingBatchId));
            this._setReports(reports);
            this.isInitialized = true;
        }

        this._setRowData();
    }

    async onReturnPartServiceDeactivated(): Promise<void> {
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._mutexService.acquire(this, this._returnService.editGroup);
        this._editModeSubject.next(true);
    }

    cancel(): void {
        const update: RowNode[] = [];
        const remove: FilingBatchReportRowModel[] = [];

        this._gridApi.forEachNode((node: RowNode) => {

            const report = node.data as FilingBatchReportRowModel;

            if (report && !report.added) {
                update.push(node);
                report.deleted = false;
            } else { // if added, remove it
                remove.push(report);
            }
        });

        this._gridApi.updateRowData({ remove: remove });
        this._gridApi.redrawRows({ rowNodes: update });

        this._editModeSubject.next(false);
    }

    async add(): Promise<void> {
        const reports: Compliance.FilingBatchReportModel[] = [];
        this._gridApi.forEachNode(x => reports.push(x.data));

        const result = await this._modalService.showAsync(ReturnSettingsReportAddComponent, reports, 'modal-xl');
        if (!result) {
            return Promise.resolve();
        }

        const newReports = _.map(result,
            (x) => {
                return _.extend(
                    {} as Compliance.FilingBatchReportModel,
                    x,
                    {
                        filingBatchId: this.filingBatchId,
                        filingBatchReportId: 0,
                        added: true,
                        deleted: false,
                        displayName: this._returnService.getReportName(x)
                    });
            });

        this._gridApi.updateRowData({ add: newReports });

        return Promise.resolve();
    }

    async save(): Promise<void> {
        if (!this.hasChanges) {
            return Promise.resolve();
        }

        const reports: Compliance.FilingBatchReportModel[] = [];
        this._gridApi.forEachNode((node: RowNode) => {
            const reportRow = node.data as FilingBatchReportRowModel;

            if (!reportRow.deleted) {

                const report = _.extend({} as Compliance.FilingBatchReportModel, reportRow);

                reports.push(report);
            }
        });

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            const result = await lastValueFrom(this._returnRepository.updateReports(this.filingBatchId, reports));
            this._setReports(result);
            this._setRowData();
            this._editModeSubject.next(false);
            this._mutexService.release(this, this._returnService.editGroup);
        }
        finally {
            if (reports.length) {
                this._productAnalyticsService.logEvent('click-additional-reports-save', {
                    selectAdditionalReports: reports.map(x => this._returnService.getReportName(x))
                });
            }
            busyRef.hide();
        }

        this._returnService.notifyReportsUpdate(this._reports);
        return Promise.resolve();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _getEditTitle(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): string {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return '';
        }

        if (filingBatchReportRow.savedSearchCategoryId !== Core.SavedSearchCategoryEnum.ComplianceReportSetup &&
            filingBatchReportRow.savedSearchCategoryId !== Core.SavedSearchCategoryEnum.ComplianceCustomReport) {
            return 'Cannot customize this type of report';
        }

        if (filingBatchReportRow.added) {
            return 'Cannot customize until you save changes';
        }

        if (filingBatchReportRow.deleted) {
            return 'Cannot customize a deleted report';
        }

        return '';
    }

    private _canEdit(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): boolean {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return false;
        }

        return this.canEdit && this.editMode;
    }

    private _canEnterEditMode(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): boolean {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return false;
        }

        return (!(filingBatchReportRow.added || filingBatchReportRow.deleted)) &&
        (filingBatchReportRow.savedSearchCategoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup ||
         filingBatchReportRow.savedSearchCategoryId === Core.SavedSearchCategoryEnum.ComplianceCustomReport);
    }

    private async _edit(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): Promise<void> {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;

        const formRevisions: Compliance.ReturnFormRevisionModel[] = [];
        this._returnService.getAssociatedReturnFormRevisions().forEach(item => {
                if (!formRevisions.find(j => j.formRevisionId === item.formRevisionId)){
                    formRevisions.push(item);
                }
            });

        const schedules: Compliance.FormRevisionScheduleModel[] = [];

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            (await this._returnAssetsService.getScheduleDetails(true))
                .filter(item => item.isReportable && !item.isSystem)
                .map(item => ({
                    formRevisionScheduleId: item.formRevisionScheduleId,
                    formRevisionId: item.formRevisionId,
                    name: item.name
                } as Compliance.FormRevisionScheduleModel) as Compliance.FormRevisionScheduleModel)
                .forEach(item => {
                    if (!schedules.find(j => j.formRevisionScheduleId === item.formRevisionScheduleId)) {
                        schedules.push(item);
                    }
                });
        }
        finally {
            busyRef.hide();
        }

        const modalParams: ReportManagerModalInputParameters = {
            reportId: filingBatchReportRow.savedSearchId,
            reportType: filingBatchReportRow.isSystem ? 0 : 1,
            formRevisionId: null,
            filingBatchId: this.filingBatchId,
            formRevisions: formRevisions,
            schedules: schedules
        };

        try {
            const filingBatchReport = await this._reportManagerModalService.showForFilingBatch(modalParams);

            if (!filingBatchReport) {
                return;
            }

            _.extend(
                filingBatchReportRow,
                filingBatchReport,
                { changed: true, deleted: false, added: false, displayName: this._returnService.getReportName(filingBatchReport) });

            this._returnService.notifyReportsUpdate(this._reports);
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } catch (e) {
            return;
        }
    }

    private _canRemove(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): boolean {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return false;
        }

        return this.editMode && !filingBatchReportRow.deleted;
    }

    private _remove(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): void {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return;
        }

        if (!filingBatchReportRow.added) {
            filingBatchReportRow.deleted = true;
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
            this._gridApi.updateRowData({ remove: [filingBatchReportRow] });
        }
    }

    private _undoRemove(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): void {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return;
        }

        filingBatchReportRow.deleted = false;

        this._gridApi.redrawRows({ rowNodes: [params.node] });
    }

    private _canUndoRemove(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): boolean {
        const filingBatchReportRow = params.data as FilingBatchReportRowModel;
        if (!filingBatchReportRow) {
            return false;
        }

        return this.editMode && filingBatchReportRow.deleted;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.isInitialized)) {
            return;
        }

        this._gridApi.setRowData(this._reports);
        this._gridApi.sizeColumnsToFit();
    }

    private _setReports(reports: Compliance.FilingBatchReportModel[]): void {
        this._reports = _.map(reports,
            (x: Compliance.FilingBatchReportModel) => {
                return _.extend({} as FilingBatchReportRowModel,
                    x,
                    {
                        deleted: false,
                        added: false,
                        changed: false,
                        displayName: this._returnService.getReportName(x)
                    });
            });
    }
}
