<div class="ws-section ws-flex-none">
    <div class="ws-section__header">
        <h4 class="ws-section__header__title">
            Errors and Warnings
            <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
        </h4>
        <div class="ws-section__header__actions">
            <div class="ws-section__header__actions__item">
                <span class="text-danger" *ngIf="notValidatedUpdatesCount > 0"><i class="fa fa-exclamation-circle"></i> {{notValidatedUpdatesCount}} Update(s)</span>
                <span class="text-success" *ngIf="notValidatedUpdatesCount === 0"><i class="fa fa-check-circle"></i> No Update(s)</span>
            </div>
            <div class="ws-section__header__actions__item">
                <span class="text-danger" *ngIf="errorCount() > 0"><i class="fa fa-times-circle"></i> {{errorCount()}} Error(s)</span>
                <span class="text-success" *ngIf="errorCount() === 0"><i class="fa fa-check-circle"></i> No Error(s)</span>
            </div>
            <div class="ws-section__header__actions__item">
                <span class="text-warning" *ngIf="warningCount() > 0"><i class="fa fa-exclamation-circle"></i> {{warningCount()}} Warning(s)</span>
                <span class="text-success" *ngIf="warningCount() === 0"><i class="fa fa-check-circle"></i> No Warning(s)</span>
            </div>
        </div>
    </div>
    <div class="ws-section__description small">Click on an error or warning below to show associated data. To continue, all errors must be resolved.</div>
</div>
<ws-ag-grid-angular wrapperClass="validation-summary"
                    [gridOptions]="gridOptions"
                    (selectionChanged)="onAgGridSelectionChanged()"
                    (gridReady)="onAgGridReady($event)">
</ws-ag-grid-angular>
