(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('dateTimePanel', dateTimePanel);

	dateTimePanel.$inject = [];

	function dateTimePanel() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			templateUrl: 'app/Task/ActionView/Filters/_dateTimePanel.html',
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			scope: {
				masterFilter: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	Controller.$inject = ['actionViewService', '$timeout', 'productAnalyticsService'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(actionViewService, $timeout, productAnalyticsService) {
		var vm = this;

		vm.urgencies = actionViewService.getUrgencies();

		vm.dueDateFromOptions = {
			minDate: moment('2000-01-01').toDate(),
			maxDate: dateHack(vm.masterFilter.DueDateTo)
		};

		vm.dueDateToOptions = {
			minDate: dateHack(vm.masterFilter.DueDateFrom)
		};

		vm.completedDateFromOptions = {
			minDate: moment('2000-01-01').toDate(),
			maxDate: dateHack(vm.masterFilter.CompletedDateTo)
		};

		vm.completedDateToOptions = {
			minDate: dateHack(vm.masterFilter.CompletedDateFrom)
		};

		vm.dueDateFromChanged = function () {
			$timeout(function () {
				if (!vm.masterFilter.DueDateFrom) {
					vm.dueDateToOptions.minDate = null;
					vm.dueDateToOptions.initDate = null;

					return;
				}

				vm.dueDateToOptions.minDate = dateHack(vm.masterFilter.DueDateFrom);
				vm.dueDateToOptions.initDate = dateHack(vm.masterFilter.DueDateFrom);
			})
		}

		vm.dueDateToChanged = function () {
			$timeout(function () {
				if (!vm.masterFilter.DueDateTo) {
					vm.dueDateFromOptions.maxDate = null;
					vm.dueDateFromOptions.initDate = null;

					return;
				}

				vm.dueDateFromOptions.maxDate = dateHack(vm.masterFilter.DueDateTo);
				vm.dueDateFromOptions.initDate = dateHack(vm.masterFilter.DueDateTo);
			})
		}

		vm.completedDateFromChanged = function () {
			$timeout(function () {
				if (!vm.masterFilter.CompletedDateFrom) {
					vm.completedDateToOptions.minDate = null;
					vm.completedDateToOptions.initDate = null;

					return;
				}

				vm.completedDateToOptions.minDate = dateHack(vm.masterFilter.CompletedDateFrom);
				vm.completedDateToOptions.initDate = dateHack(vm.masterFilter.CompletedDateFrom);
			})
		}

		vm.completedDateToChanged = function () {
			$timeout(function () {
				if (!vm.masterFilter.CompletedDateTo) {
					vm.completedDateFromOptions.maxDate = null;
					vm.completedDateFromOptions.initDate = null;

					return;
				}

				vm.completedDateFromOptions.maxDate = dateHack(vm.masterFilter.CompletedDateTo);
				vm.completedDateFromOptions.initDate = dateHack(vm.masterFilter.CompletedDateTo);
			})
		}

		vm.includeUnscheduledChanged = function () {
			if (!vm.masterFilter.IncludeUnscheduled) {
				vm.masterFilter.OnlyUnscheduled = false;
			}
            productAnalyticsService.logEvent('click-action-view-include-unscheduled', { includeUnscheduled: vm.masterFilter.IncludeUnscheduled });
		}

        vm.includeOnlyUnscheduledChanged = function () {
            productAnalyticsService.logEvent('click-action-view-only-unscheduled', { onlyUnscheduled: vm.masterFilter.OnlyUnscheduled });
        }

		vm.includeSkippedChanged = function () {
			if (!vm.masterFilter.includeSkipped) {
				vm.masterFilter.onlySkipped = false;
			}
		}

		vm.validateLastActivityFrom = function () {
			if (!vm.masterFilter.LastActivityFrom || !vm.masterFilter.LastActivityTo) {
				return;
			}

			if (vm.masterFilter.LastActivityFrom > vm.masterFilter.LastActivityTo) {
				vm.lastActivityFromTooltip = true;
				vm.masterFilter.LastActivityFrom = null;
			} else {
				vm.lastActivityFromTooltip = false;
			}
		}

		vm.validateLastActivityTo = function () {
			if (!vm.masterFilter.LastActivityFrom || !vm.masterFilter.LastActivityTo) {
				return;
			}

			if (vm.masterFilter.LastActivityTo < vm.masterFilter.LastActivityFrom) {
				vm.lastActivityToTooltip = true;
				vm.masterFilter.LastActivityTo = null;
			} else {
				vm.lastActivityToTooltip = false;
			}
		}

        vm.whenOptionChanged = function () {
            let optionSelected = '';
            switch (vm.masterFilter.DateTimeChoice) {
                case 1:
                    optionSelected = 'All Dates';
                    break;
                case 2:
                    optionSelected = 'Within # Days';
                    break;
                case 3:
                    optionSelected = 'Due Date Range';
                    break;
            }
            productAnalyticsService.logEvent('click-action-view-task-due-date', { taskAssignmentDue: optionSelected });
        }

		vm.populateDueWithin = function () {
			vm.masterFilter.DueWithinDays = vm.masterFilter.DueWithinDays || 120;
            vm.whenOptionChanged();
		}

        vm.urgencyChanged = function () {
            productAnalyticsService.logEvent('click-action-view-urgency', { urgencyLevel: vm.urgencies.find(x => x.id === vm.masterFilter.UrgencyChoice)?.display });
        }

		vm.populateUrgency = function () {
			vm.masterFilter.UrgencyChoice = vm.masterFilter.UseUrgencyFilter ? 1 : undefined;

            if (vm.masterFilter.UrgencyChoice) {
                vm.urgencyChanged();
            }
		}

        vm.lastActivityToggled = function () {
            productAnalyticsService.logEvent('click-action-view-last-activity');
        }

	    // We reason with dates in UTC, but the angular-bootstrap datepicker is designed to work in local. We have
	    // hooks in place in our picker wrapper to deal with getting/setting dates in UTC, but the minDate/initDate
	    // code requires us to convert UTC to local. This "dateHack" function does exactly that, producing a date
        // suitable for use in those fields.
		function dateHack(inputDate) {
		    if (!inputDate) { return inputDate; }
		    var origMoment = moment.utc(inputDate);
		    if (!origMoment.isValid()) { return inputDate; }
		    return moment(origMoment.format("YYYY-MM-DD")).toDate();
		}
	}
})();
