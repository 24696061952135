import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from 'src/app/Compliance/AgGrid';
import { PaymentBatchService } from '../../paymentBatch.service';

export interface PaymentBatchAddPaymentDataSourceParams {
    paymentBatchId: number;
    fromDate?: Date;
    toDate?: Date;
    selection?: Core.PaymentBatchAddPaymentSearchModel
}

export class PaymentBatchAddPaymentAgGridDataSource extends AgGridDataSourceBase<PaymentBatchAddPaymentDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _paymentBatchService: PaymentBatchService,
        protected _dataSourceParamsFn: () => PaymentBatchAddPaymentDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Core.PaymentBatchAddPaymentEnum>[] = [
        { name: 'parcelAcctNum', value: Core.PaymentBatchAddPaymentEnum.ParcelAcctNum },
        { name: 'siteName', value: Core.PaymentBatchAddPaymentEnum.SiteName },
        { name: 'state', value: Core.PaymentBatchAddPaymentEnum.State },
        { name: 'collector', value: Core.PaymentBatchAddPaymentEnum.Collector },
        { name: 'dueDate', value: Core.PaymentBatchAddPaymentEnum.DueDate },
        { name: 'installmentName', value: Core.PaymentBatchAddPaymentEnum.InstallmentName },
        { name: 'paymentAmount', value: Core.PaymentBatchAddPaymentEnum.PaymentAmount },
        { name: 'transmittalOutput', value: Core.PaymentBatchAddPaymentEnum.TransmittalOutput },
        { name: 'paymentType', value: Core.PaymentBatchAddPaymentEnum.PaymentType },
        { name: 'paymentStatus', value: Core.PaymentBatchAddPaymentEnum.PaymentStatus },
        { name: 'assessorName', value: Core.PaymentBatchAddPaymentEnum.AssessorName},
        { name: 'sitePayRes', value: Core.PaymentBatchAddPaymentEnum.SitePayRes},
        { name: 'sitePaymentResp', value: Core.PaymentBatchAddPaymentEnum.SitePaymentResp},
        { name: 'parcelPayRes', value: Core.PaymentBatchAddPaymentEnum.ParcelPayRes},
        { name: 'parcelPaymentResp', value: Core.PaymentBatchAddPaymentEnum.ParcelPaymentResp}
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.PaymentBatchAddPaymentSearchModel = this.getSearchModel(startIndex, endIndex);

        return await this._paymentBatchService.getPaymentBatchPaymentRowIds(searchParams);
    }

    public getSearchModel(startIndex?: number, endIndex?: number): Core.PaymentBatchAddPaymentSearchModel {
        return {
            pagination: {
                skip: (startIndex) ? startIndex : 0,
                take: (endIndex) ? endIndex - startIndex : 2000000000,
                noPagination: !startIndex && !endIndex ? true : false
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            paymentBatchId: this._dataSourceParams.paymentBatchId,
            fromDate: this._dataSourceParams.fromDate,
            toDate: this._dataSourceParams.toDate
        };
    }

    getSearchParamsWithoutPagination() : Core.PaymentBatchAddPaymentSearchModel{
        this.refreshDataSourceParams();
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            fromDate: this._dataSourceParams.fromDate,
            toDate: this._dataSourceParams.toDate,
            paymentBatchId: this._dataSourceParams.paymentBatchId
        };
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Core.PaymentBatchPaymentTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selection = selectedRowsModel;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const { totals } = await this._paymentBatchService.getPaymentBatchPaymentList(searchParams);

        return totals;
    }


    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.PaymentBatchAddPaymentSearchModel = this.getSearchModel(params.startRow, params.endRow);

        const result = await this._paymentBatchService.getPaymentBatchPaymentList(searchParams);

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
