import { Component  } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription ,  BehaviorSubject } from 'rxjs';

export interface FormScheduleListGridCheckboxCellRendererParams extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: FormScheduleListGridCheckboxCellRendererParams) => boolean;
    isVisible: (params: FormScheduleListGridCheckboxCellRendererParams) => boolean;
    onValueChanged: (params: FormScheduleListGridCheckboxCellRendererParams, value: boolean) => void;
}

@Component({
    selector: 'form-schedule-list-grid-checkbox',
    template: `
        <input
                type="checkbox"
                (change)="onModelChanged($event.target)"
                [checked]="params.value"
                *ngIf="params.data && params.isVisible(params)"
                [disabled]="!(params.canEdit(params) && editMode)">
    `
})
export class FormScheduleListGridCheckboxCellRendererComponent implements ICellRendererAngularComp {
    private _editModeSub: Subscription;

    params: FormScheduleListGridCheckboxCellRendererParams;
    editMode: boolean = false;

    agInit(params: FormScheduleListGridCheckboxCellRendererParams): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChanged(eventTarget: EventTarget): void {
        const value = (eventTarget as HTMLInputElement).checked;
        this.params.onValueChanged(this.params, value);
    }
}
