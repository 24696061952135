import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    SiteDescriptorsListComponent,
    SiteDescriptorsSelectorParams
} from '../Site-Descriptors-List/siteDescriptorsList.component';
import { CompanyService } from 'src/app/Entity/Company/company.service';

@Component({
    selector: 'site-descriptors-edit',
    templateUrl: './siteDescriptorsEdit.component.html',
    styleUrls: ['./siteDescriptorsEdit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SiteDescriptorsEditComponent {

    constructor(
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _companyService: CompanyService
    ) {
    }

    @Input() isEditMode: boolean;
    @Input() companyId: number;
    @Input() siteDescriptors: number[];

    @Output() siteDescriptorsChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

    isTopLevelCompany: boolean = this._companyService.isTopLevel();

    async view(): Promise<void> {
        try {
            const params: SiteDescriptorsSelectorParams = {
                isEditMode: this.isEditMode,
                companyId: this.companyId,
                selectedDescriptors: this.siteDescriptors
            };

            const bsModalParcelsRef = await this._wsModalService.show(() => {
                return this._bsModalService.show(SiteDescriptorsListComponent,
                    {
                        class: 'modal-md',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

            const result = (bsModalParcelsRef.content as SiteDescriptorsListComponent).result;

            if (!result) {
                return Promise.resolve();
            }

            this.siteDescriptorsChanged.next(result);

        } catch (e) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }

    get title(): string {
        let result: string;
        if (this.siteDescriptors && this.siteDescriptors.length) {
            if (this.isEditMode) {
                result = 'Edit / Remove Site Characteristics';
            } else {
                result = 'View Site Characteristics';
            }
        } else {
            result = 'Site Characteristics';
        }
        return result;
    }
}
