<div class="filter-group-inline-container">
    <div class="filter-label-inline">
        <label for="selectedFormAssessorFilter">Using Factors</label>
    </div>
    <div class="filter-control-inline">
        <select
            name="selectedAssessorFilter"
            class="form-select form-select-sm"
            [disabled]="!assessorFilters.length"
            [(ngModel)]="selectedAssessorFilter"
            (change)="onSelectedAssessorFilterChange()">
            <option *ngFor="let assessorFilter of assessorFilters" [ngValue]="assessorFilter">{{ assessorFilter.assessorName }}</option>
        </select>
    </div>
</div>
