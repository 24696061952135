import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { SearchPageType, UserSaveService } from '../../Navigation/User-Save/userSave.service';
import { ReportManagerService } from '../Manager/report.manager.service';
import { ReportDetail, ReportCategory } from '../Manager/report.manager.model';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { ReportManagerModalInputParameters } from '../Manager/reportManagerModal.component';
import { FormRepository } from '../../Compliance/Repositories';
import { ReturnRepository } from '../../Compliance/Repositories';
import { TimerService } from '../../UI-Lib/Utilities';

import * as _ from 'lodash';

export interface SaveReportModalParameters {
    report: ReportDetail;
    modalParameters: ReportManagerModalInputParameters;
}

export interface SaveReportModalResult {
    report: ReportDetail;
    isSystemReport: boolean;
}

@Component({
    selector: 'save-report-modal',
    templateUrl: './save-report.component.html'
})
export class SaveReportModalComponent implements OnInit, IWeissmanModalComponent<SaveReportModalParameters, SaveReportModalResult> {
    constructor(
        private readonly _reportManagerService: ReportManagerService,
        private readonly _restrictService: RestrictService,
        private readonly _formRepository: FormRepository,
        private readonly _returnRepository: ReturnRepository,
        private readonly _userSaveService: UserSaveService,
        private readonly _timer: TimerService,
        private readonly _bsModalRef: BsModalRef) {
    }

    params: SaveReportModalParameters;
    result: SaveReportModalResult;

    model: ReportDetail;
    modalParameters?: ReportManagerModalInputParameters;
    report: ReportDetail;
    isSystemReport: boolean;
    loading: boolean;
    userReportCategories: Core.SavedReportCategoryManagementDTO[];
    systemReportCategories: Core.SavedReportCategoryManagementDTO[];
    defaultUserCategory: Core.SavedReportCategoryManagementDTO;
    defaultSystemCategory: Core.SavedReportCategoryManagementDTO;
    isComplianceReport: boolean = false;
    newCategoryName: string;
    hasEditPermission: boolean;

    async ngOnInit(): Promise<void> {
        this.model = this.params.report;
        this.modalParameters = this.params.modalParameters;
        this.hasEditPermission = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);

        // Whenever the Save As dialog is shown, make a copy of the model;
        // that way changes to things like the report name are not live updated
        // in the parent page, and changes are discarded on cancel
        this.report = Object.assign({}, this.model);
        this.isComplianceReport = this._isComplianceReport();
        // Default the name to null (could default to existing value, but that default doesn't necessarily make sense)
        this.report.variantName = null;
        this.isSystemReport = false;
        this.loading = true;

        try {
            const allCategories = await this._reportManagerService.getReportCategories();
            const categories = _.filter(allCategories, (category: ReportCategory): boolean => {
                return ![Core.SavedSearchCategoryEnum.ComplianceReportSetup,
                    Core.SavedSearchCategoryEnum.ComplianceReturnReport,
                    Core.SavedSearchCategoryEnum.CompliancePackageReport,
                    Core.SavedSearchCategoryEnum.ComplianceCustomReport].includes(category.categoryId);
            });

            this.systemReportCategories = _.filter(categories, (category: Core.SavedReportCategoryManagementDTO): boolean => {
                return category.categoryType === 0;
            });
            this.userReportCategories = _.filter(categories, (category: Core.SavedReportCategoryManagementDTO): boolean => {
                return category.categoryType === 1;
            });

            this.defaultUserCategory = _.find(this.userReportCategories, { categoryId: null }) as Core.SavedReportCategoryManagementDTO;
            if (!this.defaultUserCategory) {
                // We didn't find the "uncategorized" category; just make one up
                this.defaultUserCategory = {
                    categoryId: null,
                    categoryName: 'No Category',
                    categoryType: 1,
                    reportList: null
                };
                this.userReportCategories.unshift(this.defaultUserCategory);
            }
            this.defaultSystemCategory = _.head(this.systemReportCategories) as Core.SavedReportCategoryManagementDTO;

            // If the user is saving a system report, we initially assume what they really want
            // is to save as a user report; make the user select system if that's what they want
            if (_.some(this.systemReportCategories, { categoryId: this.report.categoryID })) {
                this.report.categoryID = null;
                this.report.categoryName = this.defaultUserCategory.categoryName;
            }
        } finally {
            this.loading = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    reportTypeClicked(): void {
        // Use setTimeout so that the model has a chance to propogate before we respond to the click event
        this._timer.setTimeout(() => {
            const defaultCategory: Core.SavedReportCategoryManagementDTO = this.isSystemReport ? this.defaultSystemCategory : this.defaultUserCategory;
            this.report.categoryID = defaultCategory.categoryId;
            this.report.categoryName = defaultCategory.categoryName;
        }, 1);
    }

    async save(): Promise<void> {
        if (this.report.categoryID == -1) {
            this.loading = true;

            try {
                const category = await this._reportManagerService.createCategory(this.newCategoryName, this.isSystemReport);
                this.report.categoryID = category.categoryId;
                this.report.categoryName = category.categoryName;
                this.createReport();
            } finally {
                this.loading = false;
            }
        }
        else {
            this.createReport();
        }
    }

    private async createReport(): Promise<void> {
        this._userSaveService.setDirty(SearchPageType.Report);
        if (!this._isComplianceReport()) {
            await this._saveReport();
        } else if (this.modalParameters && this.modalParameters.filingBatchId) {
            this.loading = true;

            try {
                const reportCopy = {...this.report};
                reportCopy.reportID = 0;
                const newReport = await lastValueFrom(this._returnRepository.saveCustomReport(this.modalParameters.filingBatchId, this.report.reportID, reportCopy));
                this._saveCompleted(newReport as any);
            } finally {
                this.loading = false;
            }
        } else if (this.modalParameters && this.modalParameters.formRevisionId) {
            this.loading = true;

            try {
                const reportCopy = {...this.report};
                reportCopy.reportID = 0;
                const newReport = await lastValueFrom(this._formRepository.saveCustomReport(this.modalParameters.formRevisionId, this.report.reportID, reportCopy));
                this._saveCompleted(newReport as any);
            } finally {
                this.loading = false;
            }
        } else {
            // regular endpoint works for non-formrev / filing batch compliance reports, but must be system not custom
            return this._saveReport(true);
        }
    }

    private async _saveReport(forceSystem: boolean = false): Promise<void> {
        this.loading = true;

        try {
            const newReport = await this._reportManagerService.createReport(this.report, forceSystem ? true : this.isSystemReport);
            this._saveCompleted(newReport);
        } finally {
            this.loading = false;
        }
    }

    private _saveCompleted(newReport: any): void{
        this.report.reportID = newReport.reportID;
        // Copy the properties back to the model the parent passed us so things
        // like the name are live-updated
        Object.assign(this.model, this.report);
        this.result = newReport;
        this._bsModalRef.hide();
    }
    private _isComplianceReport(): boolean {
        return [Core.SavedSearchCategoryEnum.ComplianceReportSetup,
            Core.SavedSearchCategoryEnum.ComplianceReturnReport,
            Core.SavedSearchCategoryEnum.CompliancePackageReport,
            Core.SavedSearchCategoryEnum.ComplianceCustomReport].includes(this.report.categoryID);
    }
}
