import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { PARCEL_BULK_UPDATE_HELP } from './parcelBulkUpdate.component.help';

export interface ParcelBulkUpdateParams {
    selectedRows: Compliance.SelectedRowsModel;
    siteId: number;
    stateAllowConsolidating: boolean;
    isConsolidatedParcelSelected: boolean;
    isNonPPParcelsSelected: boolean;
}

@Component({
    selector: 'parcel-bulk-update',
    templateUrl: './parcelBulkUpdate.component.html',
    styleUrls: ['./parcelBulkUpdate.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ParcelBulkUpdateComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ParcelBulkUpdateParams, Core.ParcelBulkUpdateModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService) { }

    private _destroy$: Subject<void> = new Subject<void>();

    params: ParcelBulkUpdateParams;
    result: Core.ParcelBulkUpdateModel;

    validationMessage: string = '';
    isFormValid: boolean = false;

    consolidatingTypes: Compliance.NameValuePair<Core.ConsolidatingTypeEnum>[] = [
        {
            name: 'Merged parcel',
            value: null
        },
        {
            name: 'Opt out of consolidation',
            value: Core.ConsolidatingTypeEnum.None
        }];

    consolidatingType: Core.ConsolidatingTypeEnum = null;
    consolidatingTypeAction: Core.ParcelBulkUpdateFieldActionEnum = Core.ParcelBulkUpdateFieldActionEnum.NoChange;

    ngOnInit(): void {
        this._helpService.setContent(PARCEL_BULK_UPDATE_HELP);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    isDisabled(action: Core.ParcelBulkUpdateFieldActionEnum): boolean {
        return action !== Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
    }

    validateForm(): void {
        this.validationMessage = '';
        this.isFormValid = false;

        if (this.consolidatingTypeAction === Core.ParcelBulkUpdateFieldActionEnum.ChangeTo && this.params.isConsolidatedParcelSelected) {
            this.validationMessage = 'The consolidated parcel cannot be updated.';
            this.isFormValid = false;
            return;
        }

        if (this.consolidatingTypeAction === Core.ParcelBulkUpdateFieldActionEnum.ChangeTo && this.params.isNonPPParcelsSelected) {
            this.validationMessage = 'Only PP parcels can be updated.';
            this.isFormValid = false;
            return;
        }

        this.isFormValid = this.consolidatingTypeAction === Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
    }

    async save(): Promise<void> {
        const bulkUpdateModel: Core.ParcelBulkUpdateModel = {
            siteId: this.params.siteId,
            force: false,
            reportingParcelAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            reportingParcelId: null,
            consolidatedParcelAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            consolidatedParcelId: null,
            optInParcelsAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            optInParcels: [],
            optOutParcelsAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            optOutParcels: []
        };

        if (this.consolidatingType === Core.ConsolidatingTypeEnum.None) {
            bulkUpdateModel.optOutParcelsAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
            bulkUpdateModel.optOutParcels = this.params.selectedRows.selectedRows;
        }

        if (!this.consolidatingType) {
            bulkUpdateModel.optInParcelsAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
            bulkUpdateModel.optInParcels = this.params.selectedRows.selectedRows;
        }

        this.result = bulkUpdateModel;

        this._bsModalRef.hide();
        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
