import { Component } from '@angular/core';
import { FormService} from '../form.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { BusyIndicatorService } from '../../../Busy-Indicator/busyIndicator.service';
import { BaseExpandableComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableComponent';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FormTemplateEditComponent } from '../Form-Template-Edit/formTemplateEdit.component';
import { FormTemplateUploadComponent } from '../Form-Template-Upload/formTemplateUpload.component';
import { FormTemplateValidationParams, FormTemplateValidationComponent } from '../Form-Template-Validation/formTemplateValidation.component';

@Component({
    selector: 'form-template',
    templateUrl: './formTemplate.component.html',
    styleUrls: ['./formTemplate.component.scss']
})
export class FormTemplateComponent extends BaseExpandableComponent {
    constructor(
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService) {
        super(_formService, 'form-template');
    }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    get formRevision(): Compliance.FormRevisionModel {
        return this._formService.formRevision;
    }

    get hasErrors(): boolean {
        return this._formService.hasTemplateValidationErrors;
    }

    async upload(): Promise<void> {
        if (!this._formService.isPlaceholderTemplate) {
            try {
                await this._messageModalService.confirm(
                    'Are you sure you wish to overwrite the form template?',
                    'Confirm Overwrite'
                );
            } catch (e) {
                return;
            }
        }

        const result = await this._modalService.showAsync(FormTemplateUploadComponent, null, 'modal-md');

        if (result) {
            this._formService.updateFormRevisionInfo(result);
        }
    }

    async edit(): Promise<void> {
        await this._modalService.showAsync(FormTemplateEditComponent, null, 'modal-md');
    }

    async download(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Downloading' });
        try {
            await this._formService.downloadTemplate();
        } finally {
            busyRef.hide();
        }
    }

    async delete(): Promise<void> {
        if (this._formService.isPlaceholderTemplate) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the form template?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await this._formService.deleteTemplate();
        } finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    async showValidations(): Promise<void> {
        const params: FormTemplateValidationParams = {
            validationResult: this._formService.templateValidationResult
        };

        await this._modalService.showAsync(FormTemplateValidationComponent, params, 'modal-lg');
    }

    async validate(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Validating' });

        try {
            await this._formService.validateTemplate();
        } finally {
            busyRef.hide();
        }

        if (this._formService.hasTemplateValidationErrors || this._formService.hasTemplateValidationWarnings) {
            const params: FormTemplateValidationParams = {
                validationResult: this._formService.templateValidationResult
            };

            await this._modalService.showAsync(FormTemplateValidationComponent, params, 'modal-lg');
        } else {
            try {
                await this._messageModalService.alert(
                    'The form template does not have any errors or warnings.',
                    'Form Template Validation'
                );
            } catch (e) {
                return Promise.resolve();
            }
        }

        return Promise.resolve();
    }
}
