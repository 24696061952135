import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Currency Input
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * Example usage:
 * <ws-currency-input [formControl]="myCheckFormControl"></ws-currency-input>
 *
 */
@Component({
    selector: 'ws-currency-input',
    templateUrl: './currencyInput.component.html',
    styleUrls: ['./currencyInput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyInputComponent),
            multi: true
        }
    ],
})
export class CurrencyInputComponent implements ControlValueAccessor {

    @Input() override: boolean;
    @Input() hasAdjustments: boolean;
    @Input() valid: boolean = true;
    @Input() isCalculated: boolean;
    @Input() name: string = null;
    // Currency lib only supports up to 999 trillion before using exponents, or rounding decimals, 22 is that max with commas
    @Input() maxLength: number = 22;
    @Input() hasMargin: boolean = true;

    @Output() valueChange: EventEmitter<number> = new EventEmitter();

    value: number;

    disabled: boolean;
    error: boolean;

    private onChange: (val: number) => void;
    private onTouched: () => void;

    writeValue(value: number) {
        this.value = value;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    next() {
        this.onChange(this.value);
        this.onTouched();
        this.valueChange.emit(this.value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    currencyChanged(value: number): void {
        this.value = value;
        this.next();
    }
}
