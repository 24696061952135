import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentIntakeLicensedClientDetailRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private _pathPrefix = 'api/documentIntakeLicensedClientDetail';

    getList(): Observable<Core.DocumentIntakeLicensedClientModel[]> {
        return this.httpGet(`${this._pathPrefix}/list`);
    }

    get(instanceId: number): Observable<Core.DocumentIntakeLicensedClientModel> {
        return this.httpGet(`${this._pathPrefix}/${instanceId}`);
    }

    getDocumentGroupList(): Observable<Core.GroupModel[]> {
        return this.httpGet(`${this._pathPrefix}/documentGroups`);
    }

    updateSettings(clientDetail: Core.DocumentIntakeLicensedClientModel): Observable<Core.DocumentIntakeLicensedClientModel> {
        return this.httpPut(`${this._pathPrefix}`, clientDetail);
    }

    getListAccepted(): Observable<Core.DocumentIntakeLicensedClientModel[]> {
        return this.httpGet(`${this._pathPrefix}/listAccepted`);
    }

    getListAcceptedForDI(): Observable<Core.DocumentIntakeLicensedClientModel[]> {
        return this.httpGet(`${this._pathPrefix}/listAcceptedForDI`);
    }
}
