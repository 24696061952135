<div class="select-wrapper" [class.disabled]="disabled || !selectConfig.searchableOptions.length">
    <span class="hidden-width">{{widestOption}}</span>
    <span class="caret-toggle" [ngClass]="selectConfig.size" (click)="openDropdown($event)">
        <i class="fa" [ngClass]="{ 'fa-angle-down': !isLoading, 'fa-spinner fa-pulse': isLoading }"></i>
    </span>
    <span class="input-result" [ngClass]="selectConfig.size" (click)="openDropdown($event)">
        <span *ngIf="!selectedOption" [title]="selectConfig.placeholder">{{selectConfig.placeholder}}</span>
        <span *ngIf="selectedOption" [title]="(selectConfig.isPrimitive) ? selectedOption : selectedOption[selectConfig.labelProperty]">
            {{(selectConfig.isPrimitive) ? selectedOption : selectedOption[selectConfig.labelProperty]}}
        </span>
    </span>
    <ng-template #wsSelectDropdown></ng-template>
</div>
