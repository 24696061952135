import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { InstancePermissionService } from '../../Permission/Instance/Instance.Permission.Service';
import { ConsultingEngagementsRepository } from '../consultingEngagements.repository';

export class CompanyContractsModalParams {
    company: CompanyModel;
}

//visual states for this modal
export enum VisualState {
    'List' = 'List',
    'Invite' = 'Invite',
    'Edit' = 'Edit'
}

interface CompanyModel extends Weissman.Model.Domain.Company {
    parentId: number;
}

@Component({
    selector: 'company-contracts-modal',
    templateUrl: './companyContractsModal.component.html',
    styleUrls: ['./companyContractsModal.component.scss']
})
export class CompanyContractsModalComponent implements IWeissmanModalComponent<CompanyContractsModalParams, void>, OnInit{
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _consultingEngagementsRepository: ConsultingEngagementsRepository,
        private readonly _messageBoxService: MessageBoxService,
        private readonly instancePermissionService: InstancePermissionService,
        private readonly _messageModalService: MessageModalService
    ) {

    }

    params: CompanyContractsModalParams;
    result: void;
    isEditMode: boolean = false;

    isLoading = false;
    consultingEngagements: Core.ConsultingEngagementResponse[]=[];
    availableConsultants: Core.Consultant[] = [];
    visualState: VisualState = VisualState.List;

    selectedEngagement: Core.ConsultingEngagementResponse;
    selectedConsultant: Core.Consultant;
    availableInstanceRights: Core.InstanceRightModel[];

    permsBeingEdited: boolean = false;

    async ngOnInit() {
        await this.reload();
    }

    async reload() {
        this.isLoading = true;
        try {
            this.consultingEngagements = await this._consultingEngagementsRepository.getEngagements(Core.EntityTypes.Company, this.params.company.companyID);

            //get available consultants and filter out consultant from companies instance - can't invite self
            const consultants = (await this._consultingEngagementsRepository.getConsultants()).filter(c => c.instanceId !== this.params.company.instanceID);
            this.availableConsultants = consultants.filter(ac => !this.consultingEngagements.some(ce => ce.instanceId === ac.instanceId));
        } finally {
            this.isLoading = false;
        }
    }

    toggleEdit() {
        this.isEditMode = !this.isEditMode;
    }

    done() {
        this._bsModalRef.hide();
    }

    getEngagementStatus(engagement: Core.ConsultingEngagementResponse ) {
        if (engagement.accepted) {
            return 'Accepted';
        }

        return 'Pending';
    }

    editModeEventHander($event: any) {
        this.permsBeingEdited = $event;
    }

    //edit existing engagement and adjust instance rights
    async editEngagement(engagement: Core.ConsultingEngagementResponse) {
        this.selectedEngagement = engagement;

        await this.setAvailableInstanceRights();

        this.visualState = VisualState.Edit;
    }

    //consultant selected to invite
    async invite(consultant: Core.Consultant) {
        this.selectedConsultant = consultant;

        await this.setAvailableInstanceRights();

        //create temporary engagement for purposes of instance rights modal
        this.selectedEngagement = {
            accepted: false,
            accountHandler: null,
            consultingEngagementId: -1,
            instanceId: consultant.instanceId,
            companyInstanceId: this.params.company.instanceID,
            name: this.selectedConsultant.consultantName,
            permissions: [],
        };
        this.visualState = VisualState.Invite;
    }

    //send invite for consultant
    async confirmInvite() {
        const updatedPermissions = this.getPermissionPayload();
        await this._consultingEngagementsRepository.invite(this.params.company.companyID, this.selectedConsultant.instanceId, updatedPermissions);
        await this.reload();
        this.selectedEngagement = null;
        this.selectedConsultant = null;
        this.visualState = VisualState.List;
    }

    //update accepted consulting engagement's permission
    async updatePermissions() {
        const updatedPermissions = this.getPermissionPayload();
        await this._consultingEngagementsRepository.updatePermissions(this.selectedEngagement.consultingEngagementId, updatedPermissions);
        await this.reload();
        this.selectedEngagement = null;
        this.visualState = VisualState.List;
    }

    //cancel accepted engagement
    async cancelEngagement(consultingEngagementId: number) {
        const engagementInfo = await this._consultingEngagementsRepository.getEngagementInfo(consultingEngagementId);

        if(engagementInfo.consultingEngagementCSRs.length > 0){
            await this._messageModalService.alert(`This consulting engagement cannot be cancelled. There are ${engagementInfo.consultingEngagementCSRs.length} CSRs assigned.`);
        }
        else {
            try {
                await this._messageBoxService.confirm('Are you sure you want to cancel this engagement?');
            } catch(e) {
                return Promise.resolve();
            }

            await this._consultingEngagementsRepository.delete(consultingEngagementId);
            await this.reload();
        }
    }

    //cancel invite/edit
    async cancel() {
        this.visualState = VisualState.List;
        this.reload();
        this.selectedEngagement = null;
        this.selectedConsultant = null;
    }

    //get permission list for sending back to the server
    private getPermissionPayload() {
        return this.selectedEngagement.permissions.map(p => p.instanceRightID);
    }

    private async setAvailableInstanceRights() {
        this.availableInstanceRights = await this.instancePermissionService.getForConsultingEngagement(this.params.company.companyID);
    }

}
