import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'return-asset-change-icon-cell',
    template: `<span class="state-icon"
                     *ngIf="!total && initialized"
                     [ngClass]="{ 'status-changed' : this.statusChanged }"
                     helpTooltip
                     [tooltipText]="toolTipTitle">
                   <i [ngClass]="iconClass" class="fa"></i>
               </span>
               <span *ngIf="total">-</span>`
})
export class ReturnAssetChangeIconCellRendererComponent implements ICellRendererAngularComp {
    asset: Compliance.AssetChangeStatusEnum[];
    params: ICellRendererParams;
    total: boolean = false;
    toolTipTitle: string;
    iconClass: string;
    statusChanged: boolean = false;
    initialized: boolean = false;

    agInit(params: ICellRendererParams): void {
        const rowModel: Compliance.ReturnAssetModel = params.data;
        if (rowModel) {
            this.statusChanged = rowModel.assetReturnStatus !== rowModel.calculatedAssetReturnStatus;

            switch (rowModel.assetReturnStatus) {
                case 'New':
                    this.iconClass = 'fa-plus';
                    this.toolTipTitle = (rowModel.calculatedAssetReturnStatus === 'TransferIn') ?
                        'Changed from Transferred In to Added' : 'Added';
                    break;
                case 'TransferIn':
                    this.iconClass = 'fa-plus-square';
                    this.toolTipTitle = (rowModel.calculatedAssetReturnStatus === 'New') ?
                        'Changed from Added to Transferred In' : 'Added';
                    break;
                case 'TransferOut':
                    this.iconClass = 'fa-minus-square';
                    this.toolTipTitle = (rowModel.calculatedAssetReturnStatus === 'Retired') ?
                        'Changed from Disposed to Transferred Out' : 'Transferred Out';
                    break;
                case 'Retired':
                    this.iconClass = 'fa-trash';
                    this.toolTipTitle = (rowModel.calculatedAssetReturnStatus === 'TransferOut') ?
                        'Changed from Transferred Out to Disposed' : 'Disposed';
                    break;
                case 'NotChanged':
                    this.iconClass = 'fa-refresh';
                    if (rowModel.calculatedAssetReturnStatus === 'New') {
                        this.toolTipTitle = 'Suppressed to Existing from Added';
                    } else if (rowModel.calculatedAssetReturnStatus === 'TransferIn') {
                        this.toolTipTitle = 'Suppressed to Existing from Transferred In';
                    } else if (rowModel.calculatedAssetReturnStatus === 'Retired') {
                        this.toolTipTitle = 'Suppressed to Present from Disposed';
                    } else if (rowModel.calculatedAssetReturnStatus === 'TransferOut') {
                        this.toolTipTitle = 'Suppressed to Existing from Transferred Out';
                    } else {
                        this.iconClass = 'fa-circle-thin';
                        this.toolTipTitle = 'Not changed';
                    }
                    break;
                case 'ChangedCostIncreased':
                case 'ChangedCostDecreased':
                case 'Changed':
                case 'ChangedScheduleNonReportableToReportable':
                    this.iconClass = 'fa-pencil';
                    this.toolTipTitle = 'Changed';
                    break;
                case 'Indefinable':
                    this.iconClass = 'fa-question';
                    this.toolTipTitle = 'The prior year return cannot be used to detect year over year asset changes because the prior year form is different. Please configure the filing batch to use the company asset list for change detection.';
                    break;
                default:
                    this.iconClass = 'fa-circle-thin';
                    this.toolTipTitle = 'Not changed';
            }

            this.total = rowModel.assetNumber === 'TOTAL' || rowModel.assetNumber === 'SELECTED';
            this.initialized = true;
        }


    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
