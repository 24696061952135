import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    templateUrl: './assessmentClassSelector.component.html',
    selector: 'assessment-class-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AssessmentClassSelectorComponent,
        multi: true
    }]
})
export class AssessmentClassSelectorComponent implements ControlValueAccessor {

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() values: string[] = [];
    @Input() isOverridden: boolean = false;
    @Input() isDisabled: boolean = false;

    @Output() changed = new EventEmitter<string>();

    value: string;

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(assessmentClass: string): void {
        this.value = assessmentClass;
    }

    select(value: string): void {
        this.value = value;
        this.changed.emit(value);
    }
}
