<div class="card panel-flat-color primary-panel flex-panel">
    <form [formGroup]="infoForm" (ngSubmit)="onSubmit()">
        <div class="panel-flat-header ws-flex-container-horizontal">
            <h3 class="ws-flex-auto ws-truncate">Info</h3>
            <div class="ws-flex-none header-button-container">
                <button *ngIf="infoForm.disabled && canEdit"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        helpTooltip
                        helpContentId="app.edit"
                        [disabled]="!canEnterEditMode"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button type="submit"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="!infoForm.disabled && canEdit"
                        helpTooltip
                        helpContentId="app.save"
                        [disabled]="infoForm.pristine || infoForm.invalid">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="!infoForm.disabled && canEdit"
                        helpTooltip
                        helpContentId="app.cancel"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
                <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="!isExpanded" (click)="expandComponent()">
                    <i class="fa fa-arrows-alt" helpTooltip helpContentId="app.expand"></i>
                </button>
                <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="isExpanded" (click)="collapseComponent()">
                    <i class="fa fa-compress" helpTooltip helpContentId="app.collapse"></i>
                </button>
            </div>
        </div>
        <div class="panel-flat-body ws-flex-container-vertical">
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3" [class.has-error]="infoForm.controls.name.invalid && infoForm.controls.name.touched">
                        <label>Name</label>
                        <input type="text" formControlName="name" class="form-control" maxlength="50">
                    </div>
                    <div class="mb-3">
                        <label>Default Company Permissions:</label>
                        <select class="form-select form-select-sm" formControlName="defaultPermission">
                            <option *ngFor="let option of permissionOptions" [value]="option">{{option}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>Description</label>
                        <textarea class="form-control" rows="4" formControlName="description" maxlength="100"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label [title]="isConsultantGroupTitle">
                            <input type="checkbox"
                                   formControlName="isConsultantGroup"
                                   style="margin-right: 5px;">
                            Consultant User Group</label>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showDocumentsGroupCheckbox">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>
                            <input type="checkbox"
                                   formControlName="isDocumentsGroup"
                                   style="margin-right: 5px;">
                            Documents User Group</label>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="showSupportGroupCheckbox">
                <div class="col-lg-6">
                    <div class="mb-3">
                        <label>
                            <input type="checkbox"
                                   formControlName="isSupportGroup"
                                   style="margin-right: 5px;">
                            Support User Group</label>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
