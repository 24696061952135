import { EntityType, ClientServiceType, PropertyType } from '../../constants.new';

export class HistoryResultWrapper {
    results: HistoryOperation[]
}

export class HistoryOperation {
    historyId: number;
    operationDate: string;
    itemId: number;
    operation: OperationType;
    userId: string;
    userFirstName: string;
    userLastName: string;
    changes: HistoryChange[];
    expanded: boolean;
}

export class HistoryChange {
    fieldName: string;
    oldValue: any;
    newValue: any;
    historyFieldType: number;
    // The actual type for property fields depends on the change type. If we want to get fancier later, we can maybe
    // make HistoryChange a generic class and use new types to represent what we expect to see in properties, but for
    // now this works OK.
    properties: any;
    newValueProperties: any;
    oldValueProperties: any;
    expanded?: boolean;
}

export class CSRChangeProperties {
    clientServiceType: ClientServiceType;
    propertyType: PropertyType;
}

export class CSRChangeValueProperties {
    relatedEntityType: EntityType;
    relatedEntityName: string;
    isTopLevel: boolean;
}

export class TaxAuthorityChangeProperties {
    taxAuthorityId: number;
    taxAuthorityName: string;
    taxAuthorityCode: string;
}

export enum HistoryFieldType {
    Hidden = 1,
    String = 2,
    Number = 3,
    DateTime = 4,
    YesNo = 5,
    Descriptor = 6,
    Currency = 7,
    Value = 8,
    ActivityStatus = 9,
    Date = 10,
    PropertyType = 11,
    ClientServiceResponsibility = 12,
    TaxAuthority = 13
}

export enum OperationType {
    Insert = 1,
    Update = 2,
    Delete = 3,
    Snapshot = 4,
    Unknown = 99
}
