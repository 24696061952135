import { Injectable } from '@angular/core';
import { EntityTypeIds } from '../../constants.new';
import * as _ from 'lodash';

export interface CurrentEntity {
    id?: string;
    type: string;
    name?: string;
    typeId?: EntityTypeIds;
}

/**
 * Global state store for the current active entity utilized on a number of pages in the app
 *
 * TODO - determine why we are handling this in this way instead of each page service handling their entities on
 * their own, which would provide much better type security.
 */
@Injectable(
    { providedIn: 'root' }
)
export class CurrentEntityService {
    currentEntity: any = {};
    currentTypeId: EntityTypeIds = null;

    /**
     * Initializes the class variables back to their initial state
     */
    init(): void {
        this.currentEntity = {};
        this.currentTypeId = null;
    }

    /**
     * Get the current entity state
     */
    get(): CurrentEntity {
        return this._getEntityData();
    };

    /**
     * Set the current entity state
     * @param entity - This could be any number of entity types
     * @param typeId - The EntityTypeIds enum value for the entity provided
     */
    set(entity: any, typeId: EntityTypeIds): void {
        this.currentEntity = _.cloneDeep(entity);
        this.currentTypeId = typeId;
    }

    private _getEntityData(): CurrentEntity {
        switch (this.currentTypeId) {
            case EntityTypeIds.PARCEL:
                return {
                    id: this.currentEntity.parcelID,
                    type: 'parcel',
                    name: this.currentEntity.acctNum,
                    typeId: EntityTypeIds.PARCEL
                };

            case EntityTypeIds.SITE:
                return {
                    id: this.currentEntity.siteID,
                    type: 'site',
                    name: this.currentEntity.name,
                    typeId: EntityTypeIds.SITE
                };

            case EntityTypeIds.COMPANY:
                return {
                    id: this.currentEntity.companyID,
                    type: 'company',
                    name: this.currentEntity.name || this.currentEntity.companyName,
                    typeId: EntityTypeIds.COMPANY
                };

            case EntityTypeIds.STATE:
                return {
                    id: this.currentEntity.stateID,
                    type: 'state',
                    name: this.currentEntity.fullName,
                    typeId: EntityTypeIds.STATE
                };

            case EntityTypeIds.ASSESSOR:
                return {
                    id: this.currentEntity.assessorID,
                    type: 'assessor',
                    name: this.currentEntity.name,
                    typeId: EntityTypeIds.ASSESSOR
                };

            case EntityTypeIds.COLLECTOR:
                return {
                    id: this.currentEntity.collectorID,
                    type: 'collector',
                    name: this.currentEntity.name,
                    typeId: EntityTypeIds.COLLECTOR
                };

            case EntityTypeIds.TAX_BILL:
                return {
                    id: this.currentEntity.billID,
                    type: 'tax_bill',
                    name: this.currentEntity.name,
                    typeId: EntityTypeIds.TAX_BILL
                };

            case EntityTypeIds.ASSESSMENT:
                return {
                    id: this.currentEntity.annualAssessmentID,
                    type: 'assessment',
                    name: this.currentEntity.revisionDesc,
                    typeId: EntityTypeIds.ASSESSMENT
                };

            default:
                return {
                    type: 'unknown'
                }
        }
    }
}
