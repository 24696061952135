import 'zone.js';
import 'reflect-metadata';
import { Decimal } from 'decimal.js';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DoBootstrap, NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { AccountModule } from './Account/account.module';
import { AccrualsModule } from './Accruals/accruals.module';
import { AgentsModule } from './Agents/agents.module';
import { AnnualDetailsModule } from './Annual-Details/annual-details.module';
import { AdminModule } from './Admin/admin.module';
import { AppComponent } from './app.component';
import { BudgetModule } from './Budget/budget.module';
import { BulkImportModule } from './Bulk-Import/bulk.import.module';
import { DiagnosticsModule } from './Diagnostics/diagnostics.module';
import { DocumentsModule } from './Documents/documents.module';
import { weissmanAppModule } from './Hybrid/ngjsStart';
import { NavigationModule } from './Navigation/navigation.module';
import { Ng2RoutesModule } from './ng2Routes.module';
import { ProcessingModule } from './Processing/processing.module';
import { HybridAdapterUtility } from './Hybrid/hybrid-adapter.utility';
import { NG1UpgradeProviders } from './Hybrid/ng1-upgrade-providers';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { DragulaModule } from 'ng2-dragula';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParcelModule } from './Entity/Parcel/parcel.module';
import { ReportingModule } from './Reporting/reporting.module';
import { WeissmanSharedModule } from './Common/weissman-shared.module';
import { RoleModule } from './Role/role.module';
import { QuickSearchModule } from './Search/Quick/quickSearch.module';
import { ActionViewModule } from './Task/ActionView/actionView.module';
import { TaskModule } from './Task/task.module';
import { LandingModule } from './Landing/landing.module';
import { ComplianceModule } from './Compliance/compliance.module';
import { DashboardModule } from './Dashboard/dashboard.module';
import { Ng1UpgradeModule } from './Hybrid/ng1Upgrade.module';
import { EntityModule } from './Entity/entity.module';
import { SnackBarModule } from './Busy-Indicator/SnackBar/snackBar.module';
import { LayoutModule } from './Layout/layout.module';
import { TeamModule } from './Team/team.module';
import { UserModule } from './User/user.module';
import { InstanceModule } from './Entity/Instance/instance.module';
import { ConsultingModule } from './Consulting/consulting.module';
import { CsrCommandCenterModule} from './Client-Services/Command-Center/csrCommandCenter.module';
import { AssessorCollectorModule } from './Assessor-Collector/assessorCollector.module';
import { SmartSearchModule } from './Search/Advanced/smartSearch.module';
import { AppealApplicationModule } from './Appeal-Application/appealApplication.module';
import { ClientServicesModule } from './Client-Services/client-services.module';
import { CommentsModule } from './Comments/comments.module';
import { StateModule } from './State/state.module';
import { AttachmentModule } from './Attachment/attachment.module';
import { ContactModule } from './Contact/contact.module';
import { LoginModule } from './Account/Login/login.module';
import { SupportInstanceModule } from './Support-Instances/supportInstance.module';
import { BlobErrorHttpInterceptor } from './UI-Lib/Interceptors/blobErrorHttp.interceptor';
import { DatabaseModule } from './Diagnostics/Database/database.module';
import { PropertyCharacteristicsModule } from './Property-Characteristics/propertyCharacteristics.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { QuillModule } from 'ngx-quill';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BusyIndicatorModule } from './Busy-Indicator';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TaxRateModule } from './Tax-Rate/tax.rate.module';
import { PermissionModule } from './Permission/permission.module';
import { UserGroupModule } from './User-Group/userGroup.module';
import { ValuationModule } from './Valuation/valuation.module';
import { WebComponentWrapperModule } from './Common/webComponentWrapper.module';
import { PaymentBatchModule } from './Payment-Batch/paymentBatch.module';
import { AppealRecommendationModule } from './Appeal-Recommendation/appealRecommendation.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import * as Quill from 'quill';
import { ResponsibleEntityModule } from './Responsible-Entity/responsibleEntity.module';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        BsDropdownModule.forRoot(),
        CarouselModule.forRoot(),
        ProgressbarModule.forRoot(),
        BusyIndicatorModule.forRoot(),
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 7500,
            positionClass: 'toast-bottom-right',
            easeTime: 100,
            onActivateTick: true
        }),
        DragulaModule.forRoot(),
        QuillModule.forRoot(),
        SortableModule.forRoot(),
        UIRouterUpgradeModule.forRoot(),
        AccountModule,
        AccrualsModule,
        AdminModule,
        AgentsModule,
        AnnualDetailsModule,
        AppealApplicationModule,
        AssessorCollectorModule,
        AttachmentModule,
        BrowserAnimationsModule,
        BrowserModule,
        BudgetModule,
        BulkImportModule,
        CommentsModule,
        ComplianceModule,
        ConsultingModule,
        ContactModule,
        CsrCommandCenterModule,
        DashboardModule,
        DatabaseModule,
        DiagnosticsModule,
        DocumentsModule,
        EntityModule,
        HttpClientModule,
        InstanceModule,
        LandingModule,
        LayoutModule,
        LoginModule,
        NavigationModule,
        Ng1UpgradeModule,
        ParcelModule,
        PermissionModule,
        ProcessingModule,
        PropertyCharacteristicsModule,
        ReportingModule,
        RoleModule,
        SmartSearchModule,
        SnackBarModule,
        StateModule,
        SupportInstanceModule,
        TaskModule,
        TaxRateModule,
        PaymentBatchModule,
        TeamModule,
        UpgradeModule,
        UserGroupModule,
        UserModule,
        ValuationModule,
        WebComponentWrapperModule,
        AppealRecommendationModule,
        QuickSearchModule,
        ActionViewModule,
        Ng2RoutesModule,
        ResponsibleEntityModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlobErrorHttpInterceptor,
            multi: true
        }
    ],
    exports: [
        AppComponent
    ]
})
export class AppModule implements DoBootstrap {
    constructor (private upgrade: UpgradeModule) {
        // TODO: This should probably be moved to an import script
        (<any>window).Decimal = Decimal;
    }

    async ngDoBootstrap() {
        // Ensure AngularJS destroys itself on hot reloads.
        if (window['$injector']) {
            window['$injector'].get('$uiRouter').dispose();
            window['$injector'].get('$rootScope').$destroy();
        }

        const ng1UpgradeProviders = new NG1UpgradeProviders(new HybridAdapterUtility());

        ng1UpgradeProviders.setupProviders();

        hybridAdapterUtility.downgradeNg2Component('appRoot', AppComponent);

        AccountModule.setupModule();
        AccrualsModule.setupModule();
        ActionViewModule.setupModule();
        AdminModule.setupModule();
        AnnualDetailsModule.setupModule();
        AgentsModule.setupModule();
        AppealApplicationModule.setupModule();
        AssessorCollectorModule.setupModule();
        AttachmentModule.setupModule();
        BudgetModule.setupModule();
        BulkImportModule.setupModule();
        ClientServicesModule.setupModule();
        CommentsModule.setupModule();
        ComplianceModule.setupModule();
        ConsultingModule.setupModule();
        ContactModule.setupModule();
        CsrCommandCenterModule.setupModule();
        DashboardModule.setupModule();
        DatabaseModule.setupModule();
        DocumentsModule.setupModule();
        EntityModule.setupModule();
        InstanceModule.setupModule();
        LandingModule.setupModule();
        LayoutModule.setupModule();
        NavigationModule.setupModule();
        ParcelModule.setupModule();
        PermissionModule.setupModule();
        ProcessingModule.setupModule();
        PropertyCharacteristicsModule.setupModule();
        ReportingModule.setupModule();
        RoleModule.setupModule();
        SmartSearchModule.setupModule();
        SnackBarModule.setupModule();
        StateModule.setupModule();
        SupportInstanceModule.setupModule();
        TaskModule.setupModule();
        TaxRateModule.setupModule();
        PaymentBatchModule.setupModule();
        TeamModule.setupModule();
        UserGroupModule.setupModule();
        UserModule.setupModule();
        ValuationModule.setupModule();
        WeissmanSharedModule.setupModule();
        AppealRecommendationModule.setupModule();
        QuickSearchModule.setupModule();

        this.upgrade.bootstrap(document.body, [weissmanAppModule.name], { strictDi: true });
        window['$injector'] = ((window as any).angular.element(document.body) as any).injector();

        this.setupQuill();
    }

    setupQuill() {
        // Configure Quill to use div instead of p (maybe this should be in a utility function?)
        // https://github.com/KillerCodeMonkey/ngx-quill/issues/18

        // Trust me TypeScript - I know what I'm doing! (I hope)
        const Parchment = (Quill as any).import('parchment');
        const Block = Parchment.query('block');

        Block.tagName = 'DIV';
        // or class NewBlock extends Block {}; NewBlock.tagName = 'DIV';
        (Quill as any).register(Block /* or NewBlock */, true);
    }
}
