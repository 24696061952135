<div class="container">
	<div class="single-column-layout manage-api-keys">
		<h1>Manage API Keys</h1>
        <div [style.display]="loading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="!loading">
            <div [style.display]="addingKey ? 'none' : 'block'">
                <button class="btn btn-primary" type="button" (click)="addNewKey()">Add New Key</button>
                <table class="table table-striped table-hover table-condensed">
                    <thead>
                        <tr>
                            <th>Public Key</th>
                            <th>Name</th>
                            <th>Company</th>
                            <th>Active</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let apiKey of apiKeys; trackBy:identify"
                            style="cursor:pointer;"
                            [style.color]="apiKey.isActive ? 'black' : '#a0a0a0'"
                            (click)="goToDetail(apiKey.integrationHmacId)">
                            <td>{{apiKey.publicKey}}</td>
                            <td>{{apiKey.name}}</td>
                            <td>{{apiKey.companyName}}</td>
                            <td *ngIf="apiKey.isActive">Yes</td>
                            <td *ngIf="!apiKey.isActive">No</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div [style.display]="addingKey ? 'block' : 'none'" class="new-key">
                <label>
                    <span class="label-text">Name:</span>
                    <input type="text" class="form-input" [(ngModel)]="newKeyName" />
                    <span *ngIf="newKeyNameValidationMessage" class="validation-error">{{newKeyNameValidationMessage}}</span>
                </label>
                <label>
                    <span class="label-text">Company:</span>
                    <span class="form-input">
                        <company-picker [topLevelOnly]="true" (companySelected)="companySelected($event)"></company-picker>
                    </span>
                    <span *ngIf="newCompanyValidationMessage" class="validation-error">{{newCompanyValidationMessage}}</span>
                </label>
                <div style="margin:1em 0em;">
                    <button class="btn flat-button primary-button" type="button" (click)="saveKey()">Save</button>
                    <button class="btn flat-button warning-button" type="button" (click)="cancelAddKey()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
