import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridExtensionsModule } from '../../AgGrid/agGridExtensions.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { BusyIndicatorModule } from '../../../Busy-Indicator';
import { ComplianceParcelModule } from '../../Parcel/parcel.module';
import { AgGridLoadingOverlayComponent } from '../../../Common/AgGrid/agGridLoadingOverlay.component';
import { AssetClassSelectorModule } from '../Asset-Class-Selector/assetClassSelector.module';
import { AssetDetailsComponent } from './assetDetails.component';
import { AssetInfoComponent } from './Asset-Info/assetInfo.component';
import { AssetReturnFilingsComponent } from './Return-Filings/assetReturnFilings.component';
import { AssetReturnFilingStatusCellRendererComponent } from './Return-Filings/agGridStatusCellRenderer.component';
import { AssetActivityLogComponent } from './Activity-Log/assetActivityLog.component';
import { AssetInfoCostEditorComponent } from './Asset-Info/Cost-Editor/assetInfoCostEditor.component';
import { CostAdjustmentsComponent } from './Asset-Info/Cost-Adjustments/costAdjustments.component';
import { GLAccountSelectorModule } from '../../GL-Account/GL-Account-Selector/glAccountSelector.module';
import { SiteSelectorModule } from '../../Site/Site-Selector/siteSelector.module';
import { AssetLienDateModule } from '../Asset-Lien-Date/assetLienDate.module';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule.forRoot(),
        ComplianceParcelModule,
        TypeaheadModule,
        CurrencyMaskModule,
        AgGridExtensionsModule,
        GLAccountSelectorModule,
        SiteSelectorModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent
        ]),
        AssetLienDateModule,
        AssetClassSelectorModule
    ],
    declarations: [
        AssetActivityLogComponent,
        AssetDetailsComponent,
        AssetInfoComponent,
        AssetInfoCostEditorComponent,
        AssetReturnFilingsComponent,
        AssetReturnFilingStatusCellRendererComponent,
        CostAdjustmentsComponent
    ],
    providers: [],
    exports: [
        AssetDetailsComponent
    ]
})
export class AssetDetailsModule {}
