import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AssessorSummary } from '../Site/Site.Model';
import { HelpContentRendererValue, HelpContentComponent, HelpService } from '../../UI-Lib/Help-Tooltip';

export interface AssessorDetailsComponentParams extends HelpContentRendererValue<AssessorSummary> {
    siteId?: number;
    parcelId?: number;
    stateId: number;
}

@Component({
    selector: 'assessor-details',
    template: `
        <h4 style="margin-top: 0;">Jurisdiction Specialist<span *ngIf="jurisdictionSpecialists.length > 1">s</span></h4>
        <loading-spinner [show]="loading" [marginTop]="0" [size]="2"></loading-spinner>
        <div *ngFor="let specialist of jurisdictionSpecialists; let first = first" [ngClass]="{ 'specialist-list-item': !first }">
            <div style="font-weight: bold;">{{specialist.firstName}} {{specialist.lastName}}  <span *ngFor="let type of specialist.propertyTypes" class="label label-primary property-type-{{propertyTypes[type-1]}}" >{{propertyTypes[type-1]}}</span></div>
            <div>{{specialist.teamName}}</div>
            <div>{{specialist.address.phone}}</div>
            <div><a href="mailto:{{specialist.address.email}}">{{specialist.address.email}}</a></div>
        </div>
        <div *ngIf="!loading && jurisdictionSpecialists && !jurisdictionSpecialists.length">
            <em>None Assigned</em>
        </div>
    `
})
export class AssessorDetailsComponent implements HelpContentComponent<AssessorDetailsComponentParams> {
    constructor(private readonly _http: HttpClient, private readonly _helpService: HelpService) { }

    jurisdictionSpecialists: any[] = [];
    propertyTypes = ['RE', 'PP', 'MN', 'CA'];
    loading: boolean = true;

    params: AssessorDetailsComponentParams;

    async helpInit(params: AssessorDetailsComponentParams): Promise<void> {
        let url = '/api/';
        url += params.siteId ? ('site/' + params.siteId) : ('parcel/' + params.parcelId);
        url += '/assessor/' + params.value.assessorID + '/jurisdictionspecialist';

        try {
            this.jurisdictionSpecialists = await lastValueFrom(this._http.get<any[]>(url));
        } catch (err) {
            console.log('Loading Jurisdiction Specialist Failed: ', err);
        } finally {
            this.loading = false;
            this._helpService.refresh();
        }
    }
}
