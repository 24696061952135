import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';

@Component({
    selector: 'form-year-delete',
    templateUrl: './formYearDelete.component.html'
})
export class FormYearDeleteComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService
    ) { }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    get canDeleteTaxYear(): boolean {
        return this._formService.isMaxFormRevisionYear || this._formService.isMinFormRevisionYear;
    }

    get taxYear(): number {
        return this._formService.taxYear;
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async deleteTaxYear(): Promise<void> {
        try {
            await this._messageModalService.prompt(`Are you sure you wish to delete tax year ${this.taxYear}?`, 'Confirm');
        } catch (e) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: `Deleting tax year ${this.taxYear}` });

        try {
            await this._formService.deleteTaxYear();
        } finally {
            busyRef.hide();
        }
    }
}
