<div class="card panel-flat-color flex-panel secondary-panel">
    <div class="panel-flat-header mb-0">
        <div class="header-icon"><i class="fa fa-filter"></i></div>
        <h3>Filter</h3>
        <div class="float-end ws-flex-container-horizontal ws-flex-align-items-center" *ngIf="showOrs !== undefined">
            <label class="mb-0 mr-5">OR:</label>
            <ws-switch [(ngModel)]="showOrs" (selectedOptionChanged)="toggleOrs()" [disabled]="!areNoOrs"></ws-switch>
        </div>
    </div>
    <div class="panel-flat-body panel-body-min">
        <p class="text-muted lead text-center add-text fs-2" *ngIf="!searchFilters.length" style="margin-top: 75px;">
            <em>Add Search Filters...</em>
        </p>
        <form class="criteria-list">
            <ws-column-filters [columnFilters]="searchFilters"
                               [showOrs]="showOrs"
                               [onlyFrequentlyUsedCompanies]="frequentlyUsed"
                               [activeOnly]="excludeInactive"
                               (emptyFilter)="disableColumns()"
                               (updateColumnFilters)="updateColumnFilters($event)">
            </ws-column-filters>
        </form>
    </div>
    <div class="panel-flat-footer">
        <div class="row ws-flex-container-horizontal ws-flex-align-items-center">
            <div class="col-8 column-picker">
                <column-picker (columnsSelected)="addFilters($event)"
                               [existingColumns]="searchFilters"
                               [otherColumnsThatMightDisable]="outputColumns"
                               [disableColumnsAfterLoad]="true"
                               [allowDuplicates]="true" cpMode="smart">
                </column-picker>
            </div>
            <div class="col-4 text-end filter-checkboxes">
                <div class="checkbox mt-0">
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="excludeInactive"
                               (ngModelChange)="persistSetting('click-exclude-inactive')"
                               [disabled]="loadSaveSettings"> <span class="filter-checkbox-label">Exclude Inactive</span>
                    </label>
                </div>
                <div class="checkbox mb-0" *ngIf="hasRyanPrivatePermission">
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="frequentlyUsed"
                               (ngModelChange)="persistSetting('click-only-frequently-used')"
                               [disabled]="loadSaveSettings">
                        <span class="filter-checkbox-label">Only Frequently Used</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

