import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class ActivityRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly APP_SOURCE: string = 'sd.Activity.Service';
    private readonly SERVICE_URL: string = '/api/site/';

    getDataByYear(siteId: number, year: number, parcelFilter, useLatestActualized: boolean): Observable<any> {
        const url = `${this.SERVICE_URL + siteId  }/analytics/`;
        const payload = {
            year: year,
            yearsToInclude: 4,
            includedParcels: parcelFilter,
            useLatestActualizedAssessmentYear: useLatestActualized || false
        };

        return this.httpPost(url, payload);
    }

    getDataForAllYears(siteId: number, parcelFilter, useLatestActualized: boolean): Observable<any> {
        const url = `${this.SERVICE_URL + siteId  }/analytics/`;
        const payload = {
            year: 0,
            yearsToInclude: 0,
            includedParcels: parcelFilter,
            useLatestActualizedAssessmentYear: useLatestActualized || false
        };

        return this.httpPost(url, payload);
    }

    getDataByParcel(siteId: number, year: number, parcelId: number, useLatestActualized: boolean): Observable<any> {
        const url = `${this.SERVICE_URL + siteId  }/analytics/`;
        const payload = {
            year: year,
            yearsToInclude: 4,
            includedParcels: [Number(parcelId)],
            parcelLevel: true,
            useLatestActualizedAssessmentYear: useLatestActualized || false
        };

        return this.httpPost(url, payload);
    }

    getDataByParcelAllYears(siteId: number, parcelId: number, useLatestActualized: boolean): Observable<any> {
        const url = `${this.SERVICE_URL + siteId  }/analytics/`;
        const payload = {
            year: 0,
            yearsToInclude: 0,
            includedParcels: [Number(parcelId)],
            parcelLevel: true,
            useLatestActualizedAssessmentYear: useLatestActualized || false
        };

        return this.httpPost(url, payload);
    }

    getDeadlineData(siteId: number, parcelId: number): Observable<any> {
        let url = `${this.SERVICE_URL + siteId  }/analytics/deadlines`;

        if (parcelId) {
            url += `/${  parcelId}`;
        }

        return this.httpGet(url);
    }

}
