import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { ValuationTemplatesComponent } from './valuationTemplates.component';
import { AddEditValuationTemplateComponent } from './Add-Edit-Tempalate/addEditValuationTemplate.component';
import { ValuationTemplateService } from './valuationTemplate.service';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ValuationTemplateRepository } from '../../Core-Repositories/valuationTemplate.repository';
import { ValuationTemplateDetailComponent } from './Template-Detail/valuationTemplateDetail.component';
import { ValuationStatesListComponent } from './Template-Detail/States-List/valuationStatesList.component';
import { AddStateComponent } from './Template-Detail/States-List/Add-State/addState.component';
import { ValuationAssessorsListComponent } from './Template-Detail/Assessor-List/valuationAssessorsList.component';
import { AddAssessorComponent } from './Template-Detail/Assessor-List/Add-Assessor/addAssessor.component';
import { ValuationSiteClassListComponent } from './Template-Detail/Site-Class-List/valuationSiteClassList.component';
import { AddSiteClassComponent } from './Template-Detail/Site-Class-List/Add-Site-Class/addSiteClass.component';
import { ValuationTemplateUploadComponent } from './Template-Detail/Upload/valuationTemplateUpload.component';
import { UploadComponent } from './Template-Detail/Upload/Template-Upload/upload.component';
import { BusyIndicatorModule } from '../../Busy-Indicator';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BusyIndicatorModule,
        WeissmanSharedModule
    ],
    declarations: [
        ValuationTemplatesComponent,
        AddEditValuationTemplateComponent,
        ValuationTemplateDetailComponent,
        ValuationStatesListComponent,
        AddStateComponent,
        ValuationAssessorsListComponent,
        AddAssessorComponent,
        ValuationSiteClassListComponent,
        AddSiteClassComponent,
        ValuationTemplateUploadComponent,
        UploadComponent
    ],
    providers: [
        ValuationTemplateService,
        ValuationTemplateRepository
    ],
    exports: [
        ValuationTemplatesComponent,
        ValuationTemplateDetailComponent
    ]
})
export class ValuationTemplatesModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('valuationTemplates', ValuationTemplatesComponent);
        hybridAdapterUtility.downgradeNg2Component('valuationTemplateDetail', ValuationTemplateDetailComponent);
    }
}
