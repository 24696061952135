(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('activitySummaryTable', activitySummaryTableDirective);

	activitySummaryTableDirective.$inject = ['$rootScope', 'ActivityPanelModes', 'productAnalyticsService'];

	function activitySummaryTableDirective($rootScope, ActivityPanelModes, productAnalyticsService) {

		var directive = {
			link: link,
			restrict: 'E',
			templateUrl: 'app/Common/Activity/_summaryTable.html',
			replace: true,
			scope: {
				annualData: '=',
				propCharacteristics: '=',
				selectedYear: '='
			}
		};
		return directive;

		function link(scope) {

			scope.ActivityPanelModes = ActivityPanelModes;
			//scope.Math = window.Math;

			function activate() {
				try {
					scope.appealStatus = '';
					getPropertyChracteristics();

					scope.currentYear = _.find(scope.annualData, { 'year': scope.selectedYear });

					scope.currentYear.totalFMV = window.Math.round(scope.currentYear.totalFMV);

					scope.fmvProjected = _.some(scope.currentYear.fmvByPropertyType, { isProjected: true });

					try {
						scope.recentSavings = _.filter(scope.annualData, function(item) {
							return item.taxSavings.savings > 0;
						});
					} catch(ex) {
						console.log('Savings not present: '+ex);
					}

					try {
						scope.totalTaxes = _.reduce(scope.currentYear.collectorTaxes, function(sum, n) {
							return new Decimal(sum).plus(n.tax).toNumber();
						}, 0);
						scope.taxProjected = _.some(scope.currentYear.collectorTaxes, { isProjected: true });
					} catch(ex) {
						console.log('Tax Collector not present: '+ex);
					}

					getAppealsStatusLabel(scope.currentYear.taxSavings.status);
				} catch(ex){
					console.log('ERROR: '+ex);
				}
			}

			function getAppealsStatusLabel(status) {
				switch(status) {
					case 'No Appeal':
						scope.appealStatus = 'No'
						break;
					case 'N/A':
						scope.appealStatus = 'N/A'
						break;
					default:
						scope.appealStatus = status;
				}
			}

			function getPropertyChracteristics() {
				scope.acres = _.find(scope.propCharacteristics, {'label': 'Land Acreage' });
				scope.impsSqFt = _.find(scope.propCharacteristics, {'label': 'Imps. SqFt' });
				scope.units = _.find(scope.propCharacteristics, {'label': 'Units' });
			}

			scope.updateChartType = function(chartType) {
                if (chartType === 'recentAppeals') {
                    productAnalyticsService.logEvent('click-activity-analysis-panel', { viewSitePortfolioAnalysis: 'Appeal Savings' });
                } else {
                    productAnalyticsService.logEvent('click-activity-analysis-panel', { viewSitePortfolioAnalysis: 'FMV' });
                }

				$rootScope.$broadcast('chartTypeChange', chartType);
			}

			scope.$watch('annualData', function(){
				activate();
			}, true);

			scope.$watch('propCharacteristics', function () {
				activate();
			}, true);


			activate();

		}
	}
})();
