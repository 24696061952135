import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const ALLOCATION_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'allocation-list.completed',
        tooltipText: 'Include allocations where all tasks have been completed.',
        hasIcon: true
    },
    {
        helpContentId: 'allocation-list.new-allocation',
        tooltipText: 'New allocation'
    }
];
