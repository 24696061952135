<div class="modal fade" bsModal #addFilingModal="bs-modal" [config]="{backdrop: true}" tabindex="-1" role="dialog" aria-hidden="true" (onShow)="showModalInit()">
    <div *ngIf="newFiling" class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">New Compliance Filing</h4>
            </div>
            <!--<sd-loading-spinner margin-bottom="50" ng-show="loading"></sd-loading-spinner>-->
            <div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-8">
                            <label>
                           <span>Filing Type:</span>
                           <select class="form-select form-select-sm" [(ngModel)]="newFiling.filingTypeId" style="width: 200px;">
                               <option *ngFor="let type of filingTypesDropdown" [ngValue]="type.filingTypeId">{{type.name}}</option>
                           </select>
                       </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <label>
                           <span>Description:</span>
                           <input class="form-control" type="text" [(ngModel)]="newFiling.description" style="width: 200px;">
                       </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn flat-button primary-button" [disabled]="!newFiling.description || creatingFiling" (click)="save()">Add</button>
                    <button class="btn flat-button warning-button" (click)="addFilingModal.hide()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
