(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('RenameActionViewModalController', RenameActionViewModalController);

	RenameActionViewModalController.$inject = ['$scope', '$uibModalInstance', 'actionViewService', '$rootScope', 'actionView', 'actionViewType'];

	function RenameActionViewModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, $uibModalInstance, actionViewService, $rootScope, actionView, actionViewType) {
		$scope.actionView = actionView;
		$scope.actionViewType = actionViewType;

		$scope.save = function() {
			$scope.actionView.defaultDisplay = $scope.actionView.defaultDisplay;
			actionViewService.patch($scope.actionView, actionViewType)
			.then(function() {
				$rootScope.$broadcast('updateActionViewMenu', $scope.actionView);
				$uibModalInstance.dismiss();
			});
		}

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		}

		activate();

		function activate() {}
	}
})();
