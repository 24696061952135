import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AgGridModule } from 'ag-grid-angular'
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component'
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { LongRunningProcessListComponent } from './Long-Running-Process-List/longRunningProcessList.component';
import { LongRunningProcessPageComponent } from './Long-Running-Process-Page/longRunningProcessPage.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { LongRunningProcessService } from './longRunningProcess.service';
import { LongRunningProcessQuiesceComponent } from './Long-Running-Process-Quiesce/longRunningProcessQuiesce.component';
import { LongRunningProcessQuiesceModalComponent } from './Long-Running-Process-Quiesce-Modal/longRunningProcessQuiesceModal.component';
import { LongRunningProcessSyntheticComponent } from './Long-Running-Process-Synthetic/longRunningProcessSynthetic.component';
import { LongRunningProcessSyntheticModalComponent } from './Long-Running-Process-Synthetic-Modal/longRunningProcessSyntheticModal.component';
import { LongRunningProcessControlComponent } from './Long-Running-Process-Control/longRunningProcessControl.component';
import { LongRunningProcessControlModalComponent } from './Long-Running-Process-Control-Modal/longRunningProcessControlModal.component';
import { LongRunningProcessListGridActionCellRendererComponent } from './Long-Running-Process-List/agGridActionCellRenderer.component';
import {
    LongRunningProcessListGridErrorGuidCellRendererComponent
} from "./Long-Running-Process-List/long-running-process-list-grid-error-guid-cell-renderer.component";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            LongRunningProcessListGridActionCellRendererComponent,
            LongRunningProcessListGridErrorGuidCellRendererComponent
        ])
    ],
    declarations: [
        LongRunningProcessPageComponent,
        LongRunningProcessListComponent,
        LongRunningProcessQuiesceComponent,
        LongRunningProcessQuiesceModalComponent,
        LongRunningProcessSyntheticComponent,
        LongRunningProcessSyntheticModalComponent,
        LongRunningProcessControlComponent,
        LongRunningProcessControlModalComponent,
        LongRunningProcessListGridActionCellRendererComponent,
        LongRunningProcessListGridErrorGuidCellRendererComponent
    ],
    providers: [
        LongRunningProcessService
    ],
    exports: []
})
export class LongRunningProcessModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessPage', LongRunningProcessPageComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessList', LongRunningProcessListComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessQuiesceComponent', LongRunningProcessQuiesceComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessQuiesceModalComponent', LongRunningProcessQuiesceModalComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessSyntheticComponent', LongRunningProcessSyntheticComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessSyntheticModalComponent', LongRunningProcessSyntheticModalComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessControlComponent', LongRunningProcessControlComponent);
        hybridAdapterUtility.downgradeNg2Component('longRunningProcessControlModalComponent', LongRunningProcessControlModalComponent);
    }
}
