import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { FormSetupRepository } from '../../Repositories';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../AgGrid/MultiSelectTracker';
import { lastValueFrom, Subscription } from 'rxjs';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import { WeissmanDateTimeFormatPipe } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';

@Component({
    selector: 'form-setup-download',
    templateUrl: './formSetupDownload.component.html',
    styleUrls: ['./formSetupDownload.component.scss']
})
export class FormSetupDownloadComponent implements IWeissmanModalComponent<void, number[]>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formSetupRepository: FormSetupRepository,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _formRevisions: Compliance.FormSetupDownloadFormRevisionModel[];

    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FormSetupDownloadFormRevisionModel).formRevisionId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    params: void;
    result: number[];

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    rowsSelected: boolean = false;

    cancel(): void {
        this._bsModalRef.hide();
    }

    async ngOnInit(): Promise<void> {
        const busyMsg = 'Loading';

        this.busyIndicatorMessageManager.add('Loading', busyMsg);
        try {
            this._formRevisions = await lastValueFrom(this._formSetupRepository.getFormRevisions());
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'formRevisionId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'State',
                field: 'stateAbbr',
                width: AgGridColumns.stateAbbreviationColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Form',
                field: 'formName',
                width: AgGridColumns.textColumnLargeWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Form Revision',
                field: 'formRevisionName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Is Supplemental',
                field: 'isSupplementalForm',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed By',
                field: 'changedBy',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed Date',
                field: 'changeDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => this._dateTimePipe.transform(x.value, true),
            }
        ];

        const defaultSortModel = [
            {
                colId: 'stateAbbr',
                sort: 'asc'
            },
            {
                colId: 'formName',
                sort: 'asc'
            },
            {
                colId: 'formRevisionName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async export(): Promise<void> {
        this.result = await this.gridTracker.getSelectedRowIds();

        this._bsModalRef.hide();
        return Promise.resolve();
    }

    private _setRowData() {
        if (!this._gridApi) {
            return;
        }

        this._gridApi.setRowData(this._formRevisions);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const formRevision = x.data as Compliance.FormSetupDownloadFormRevisionModel;
                return formRevision && formRevision.formRevisionId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
