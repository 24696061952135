import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { lastValueFrom } from 'rxjs';
import { AccountingPeriodsRequest, CompanyAccounting, NewFiscalYearRequest } from './accounting.periods.model';

@Injectable()
export class AccountingPeriodsService {

    constructor(private httpService: HttpClient) {}

    getAccountingPeriods(companyId: number): Promise<CompanyAccounting> {
        return lastValueFrom(this.httpService.get(`/api/company/${companyId}/accountingperiods`)) as Promise<CompanyAccounting>;
    }

    updateAccountingPeriods(companyId: number, request: AccountingPeriodsRequest): Promise<void> {
        return lastValueFrom(this.httpService.put<void>(`/api/company/${companyId}/accountingperiods`, request));
    }

    addNewFiscalYear(companyId: number, request: NewFiscalYearRequest): Promise<void> {
        return lastValueFrom(this.httpService.post<void>(`/api/company/${companyId}/companyfiscalyear`, request));
    }

    deleteFiscalYear(companyId: number, companyFiscalYearId: number, companyRowVersion: string): Promise<void> {
        return lastValueFrom(this.httpService.post<void>(`/api/company/${companyId}/fiscalYear/remove`, {
            companyRowVersion: companyRowVersion,
            companyFiscalYearId: companyFiscalYearId,
            accountingPeriods: null
        }));
    }
}
