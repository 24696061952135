import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular'
import { SupplementalInformationSheetComponent } from './Supplemental-Information-Sheet/supplementalInformationSheet.component';
import { DropdownCellRenderer } from './Supplemental-Information-Sheet/agGridDropdownCellRenderer.component';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';

@NgModule({
    imports: [
        WeissmanSharedModule,
        AgGridExtensionsModule,
        AgGridModule
    ],
    declarations: [
        SupplementalInformationSheetComponent,
        DropdownCellRenderer
    ]
})
export class SupplementalInformationModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('supplementalInformationSheet', SupplementalInformationSheetComponent);
    }
}
