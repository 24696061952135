export interface IAgGridOverlayContainer {
    columnId: number;
    bottom: number;
    left: number;
    width: number;
    horizontalScrollOffset: number;
    hasMouseOver: boolean;
}

/**
 * A class for managing the overlay container that is positioned on top of the AgGrid.
 */
export class AgGridOverlayContainer implements IAgGridOverlayContainer {
    constructor(private _columnId: number) { }

    private readonly _overlayContainerPadding: number = 0;

    private _left: number = 0;
    private _width: number = 0;

    /**
     * The AgGrid column ID that this container is associated with.
     */
    get columnId(): number {
        return this._columnId;
    }

    /**
     * The container bottom offset.
     * Used to adjust the container bottom in case the AgGrid has a horizontal scrollbar.
     */
    get bottom(): number {
        return 0;
    }

    /**
     * The container left offset.
     */
    get left(): number {
        return this._left + this._overlayContainerPadding - this.horizontalScrollOffset;
    }

    set left(value) {
        this._left = value;
    }

    /**
     * The container width.
     */
    get width(): number {
        return this._width - (this._overlayContainerPadding * 2);
    }

    set width(value) {
        this._width = value;
    }

    /**
     * The horizontal scroll offset of the AgGrid.
     * Used to correctly set the left offset of the container.
     */
    horizontalScrollOffset: number = 0;

    /**
     * Indicates if the drag-and-drop cursor is over the container.
     */
    hasMouseOver: boolean = false;
}
