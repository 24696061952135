<ws-modal-wrapper headerLabel="Review Payment Package"
                  [hideSave]="true"
                  [hideCancel]="true"
                  acceptLabel=""
                  (cancel)="close()"
                  [useAceStyles]="true">
    <loading-spinner [show]="loading" [size]="5"></loading-spinner>
    <div *ngIf="!loading"
         [style.display]="editingEmail ? 'block' : 'none'"
         class="mb-3 quill-height-fix"
         style="height: 56vh; text-align: left;">
        <!-- TODO: This is copied from custom-email-template-modal.component; we should create a shared component instead
            of copy/pasting the implementation -->
        <input type="text" id="template-subject"
               style="width: 662px; display: inline-block; margin-left: 10px; margin-bottom: 15px;"
               class="form-control" [(ngModel)]="emailSubjectTemplate" placeholder="Subject ...">
        <div class="btn-group" style="margin-left: -5px;" dropdown>
            <button dropdownToggle type="button" class="btn btn-default dropdown-toggle"
                    style="width: initial; padding: 4px 12px;">
                Insert Field
                <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li role="menuitem" *ngFor="let field of emailTemplateFields.subject">
                    <a style="cursor: pointer;" class="dropdown-item"
                       (click)="insertFieldInSubject(field)">{{ field.name }}</a>
                </li>
            </ul>
        </div>

        <quill-editor [(ngModel)]="emailTextTemplate"
                      (onContentChanged)="contentChange($event)"
                      [placeholder]="'Email Body ...'"
                      (onEditorCreated)="addBindingCreated($event)"
                      class="w-100">
            <div quill-editor-toolbar>
                <!-- Basic buttons -->
                <span class="ql-formats">
                                    <button class="ql-bold" [title]="'Bold'"></button>
                                    <button class="ql-italic" [title]="'Italic'"></button>
                                    <button class="ql-underline" [title]="'Underline'"></button>
                                    <button class="ql-strike" [title]="'Strike'"></button>
                                </span>

                <!-- Size -->
                <span class="ql-formats">
                                    <select class="ql-size" [title]="'Size'">
                                        <option value="small"></option>
                                        <option selected></option>
                                        <option value="large"></option>
                                        <option value="huge"></option>
                                    </select>
                                </span>
                <span class="ql-formats">
                                    <select class="ql-align" [title]="'Aligment'">
                                        <option selected></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn dropdown-toggle" style="width: initial;">
                        Insert Field
                        <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let field of emailTemplateFields?.body">
                            <a style="cursor: pointer;" class="dropdown-item"
                               (click)="insertFieldInBody(field)">{{ field.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </quill-editor>
    </div>
    <div *ngIf="!loading"
         [class.d-none]="editingEmail"
         class="d-flex flex-column">
        <div class="d-flex justify-content-end" *ngIf="availableAttachments?.length > 0" style="margin-top:-20px;">
            <select class="form-select form-select-sm w-auto" [(ngModel)]="selectedAttachment"
                    (ngModelChange)="onAttachmentChange($event)">
                <option *ngFor="let feedAttachment of availableAttachments" [ngValue]="feedAttachment">
                    {{ feedAttachment.fileName }}.{{ feedAttachment.fileExtension }}
                </option>
            </select>
        </div>
        <div style="text-align: left;">
            <!--<h3>Email Contents</h3>-->
            <div>
                To:
                <span
                    *ngFor="let recipient of package.paymentPackageRecipients | recipientType: { recipientType: RecipientType.ResponsibleParty }; let last = last; let first=first;">
                                    {{ recipient.fullName }}<span *ngIf="!last">; </span>
                                </span>
            </div>
            <div>
                                <span
                                    *ngFor="let recipient of package.paymentPackageRecipients | recipientType: { recipientType: RecipientType.Cc }; let last = last;let first=first;">
                                    <span *ngIf="first">Cc:</span>
                                    {{ recipient.fullName }}<span *ngIf="!last">; </span>
                                </span>
            </div>
            <div>
                                <span
                                    *ngFor="let recipient of package.paymentPackageRecipients | recipientType: { recipientType: RecipientType.Bcc }; let last = last;let first=first;">
                                    <span *ngIf="first">Bcc:</span>
                                    {{ recipient.fullName }}<span *ngIf="!last">; </span>
                                </span>
            </div>
            <div class="data-text-field"
                 *ngIf="emailText"
                 style="text-align: left; margin-bottom: 10px; margin-top: 4px; overflow: auto;"
                 [ngClass]="{'editable-email-subject':previewMode}">
                <span>{{ emailSubject }}</span>
            </div>
            <div *ngIf="previewMode" style="float: right; margin: 16px;">
                <i *ngIf="!loading"
                   class="fa fa-pencil"
                   style="position: absolute; cursor: pointer;"
                   (click)="beginEditEmail()">
                </i>
            </div>


            <div class="data-text-field email-preview-display"
                 *ngIf="emailText"
                 style="text-align: left; margin-bottom: 10px; margin-top: 4px; height: 100px; overflow: auto;"
                 [innerHTML]="emailText">
            </div>
        </div>
        <div style="height: 300px;" *ngIf="!attachmentSizeWarn && selectedAttachment.fileExtension === 'pdf'">
            <pdf-viewer [fileUrl]="attachmentUrl"></pdf-viewer>
        </div>
        <div *ngIf="attachmentSizeWarn">
            <h3>
                This file is large and may take a long time to preview.
                Please use the download button below or
                <a style="cursor: pointer" (click)="previewAnyway()">Click here</a> to preview the file anyway.
            </h3>
        </div>
        <div *ngIf="selectedAttachment.fileExtension !== 'pdf'">
            <h3>Preview not supported. Please use the download button below.</h3>
        </div>
    </div>
    <div class="line modal-footer">
        <div *ngIf="editingEmail">
            <button class="btn flat-button primary-button"
                    [disabled]="this.loading"
                    (click)="saveEditEmail()">
                Save
            </button>
            <button class="btn flat-button warning-button"
                    [disabled]="this.loading"
                    (click)="cancelEditEmail()">
                Cancel
            </button>
        </div>
        <div *ngIf="!editingEmail">
                        <span style="font-size:25px;margin-right:5px;" *ngIf="this.downloading">
                            <i class="fa fa-spinner fa-pulse"></i>
                        </span>
            <button class="flat-button icon-button-large primary-button"
                    *ngIf="selectedAttachment"
                    (click)="downloadAttachment()"
                    style="height: 40px; margin-right: 6px;"
                    tooltip="Download Package File"
                    [disabled]="this.loading || this.downloading">
                <i class="fa fa-download"></i>
            </button>
            <button class="btn flat-button primary-button"
                    style="margin-top:-7px;"
                    (click)="close()">
                Close
            </button>
        </div>
    </div>
</ws-modal-wrapper>
