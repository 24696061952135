import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AgGridDateFilterComponent } from '../Filters/Date/agGridDateFilter.component';
import { FilterUIModel } from '../Filters/filterUI.model';
import { AgGridFloatingFilterBase, WSFloatingFilterParams } from './agGridFloatingFilterBase';

declare let $: any;
declare let moment: any;

@Component({
    selector: 'date-floating-filter-component',
    template: `<input #picker
                      class="ag-floating-filter-input"
                      type="text"
                      [numbersOnly]="true"
                      [forDateInput]="true"
                      [class.invalid]="!isValid"
                      [hidden]="conditionApplied || type === 'blank' || type === 'notBlank'"
                      [(ngModel)]="value"/>
               <input *ngIf="conditionApplied"
                      type="text"
                      class="ag-floating-filter-input"
                      data-show-value="true"
                      data-popup-enabled="true"
                      readOnly
                      [ngModel]="dateConditionDisplay"/>
               <div *ngIf="type === 'blank' || type === 'notBlank'">
                   <span class="reset-value clickable"
                       (click)="clearFilter()">
                       <i class="fa fa-times"></i>
                   </span>
                   <span>{{type === 'blank' ? 'Blank' : 'Not Blank'}}</span>
               </div>
    `,
    styleUrls: ['./agGridFloatingFilter.scss'],
    host: { 'class': 'ag-input-wrapper' }
})
export class AgGridDateFloatingFilterComponent extends AgGridFloatingFilterBase<WSFloatingFilterParams> implements AfterViewInit {
    constructor() {
        super();
    }

    @ViewChild('picker', { static: true }) picker: ElementRef;

    dateConditionDisplay: string;
    isValid: boolean = true;

    ngAfterViewInit(): void {
        this.picker.nativeElement.focus();
        this.picker.nativeElement.select();
        $(this.picker.nativeElement).datepicker({
            dateFormat: 'mm/dd/yy',
            direction: 'down',
            onSelect: (dateText) => {
                if (document.activeElement !== this.picker.nativeElement) {
                    this.value = dateText;
                }
            }
        });
    }

    onParentModelChanged(parentModel: FilterUIModel): void {
        this.value = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterValue;
        if (parentModel && parentModel.filterConditionType) {
            this.type = this._params.filterParams['defaultOption'];
            this.dateConditionDisplay = parentModel.filterValues.map(x => `${x.filterType.displayName} "${x.filterValue}"`).join(parentModel.filterConditionType === Core.FilterConditionTypeEnum.And ? ' AND ' : ' OR ');
            this.conditionApplied = true;
        } else {
            this.dateConditionDisplay = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterValue;
            this.type = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterType.displayKey;
            this.conditionApplied = false;
        }
    }

    valueChange(submit: boolean): void {
        const type = this._params.filterParams['defaultOption'];
        const valueToUse = this.value === '' ? null : this.value;
        this.isValid = this.validateDateInput(valueToUse);
        if (this.isValid) {
            this.value = moment(valueToUse).format('MM/DD/YYYY');
            this._params.parentFilterInstance((instance) => {
                (instance as unknown as AgGridDateFilterComponent).onFloatingFilterChanged(type, valueToUse, submit);
            });
            $('#ui-datepicker-div').hide();
        }
    }

    validateDateInput(inputValue: string): boolean {
        return !inputValue ||
            moment(inputValue, 'MM-DD-YYYY', true).isValid() ||
            moment(inputValue, 'MM/DD/YYYY', true).isValid() ||
            moment(inputValue, 'M-D-YYYY', true).isValid() ||
            moment(inputValue, 'M/D/YYYY', true).isValid();
    }

}
