import { Type } from '@angular/core';
import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';

export class HybridAdapterUtility {
    downgradeNg2Provider(name: string, provider: Type<any>) {
        (<any>window).angular.module('weissmanApp').factory(name, downgradeInjectable(provider));
    }

    downgradeNg2Component(name: string, component: Type<any>) {
        (<any>window).angular.module('weissmanApp').directive(name, downgradeComponent({ component: component }));
    }

    // To upgrade an AngularJS provider, check out ng1Upgrade.module.ts.
    // I don't think we ever upgrade an AngularJS component?

    // downgradeNg2AttributeDirective(name: string, component: Type<any>) {
    //     (<any>window).angular.module('weissmanApp').directive(name, this.allowAttribute(downgradeComponent({ component: component })));
    // }

    // allowAttribute(componentFactory) {
    //     const wrapper = function($compile, $injector, $parse) {
    //         const component = componentFactory($compile, $injector, $parse);
    //         component.restrict = "AE";
    //         return component;
    //     };
    //     wrapper.$inject = ["$compile", "$injector", "$parse"];
    //     return wrapper;
    // }
}
