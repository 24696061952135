<div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="isInitialized">
    <!-- import summary and current step visualization -->
    <div class="import-summary ws-flex-none ws-flex-container-horizontal ws-stretch-width">
        <div class="ws-flex-auto">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="importFileDescription"></ws-bread-crumbs>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="assetEffectiveDate">
                <div>Asset Effective date:</div>
                <span>{{assetEffectiveDate | date : 'M/d/yyyy'}}</span>
                <div *ngIf="assetOwnershipTypeName"> Asset Ownership type:</div>
                <span *ngIf="assetOwnershipTypeName">{{assetOwnershipTypeName}}</span>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="accrualAdjustmentAmountValueType">
                <div>Adjustment Amount Value type:</div>
                <span>{{accrualAdjustmentAmountValueType}}</span>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="isSiteUpdatesOnlyImport">
                <div>Match existing sites by:</div>
                <span>{{sitesUpdatesOnlyMatchBy}}</span>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal flex-nowrap asset-info" *ngIf="isTaxPaymentsImport">
                <div>Match overall by:</div>
                <span>{{taxPaymentsMatchOverall}}</span>
                <ng-container *ngIf="!isTaxPaymentsImportMatchByPaymentSystemId">
                    <div>Match parcel using:</div>
                    <span>{{taxPaymentsMatchParcel}}</span>
                    <div>Match collector using:</div>
                    <span>{{taxPaymentsMatchCollector}}</span>
                </ng-container>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="incomeStatementsEndingDate">
                <div>Income Statements Ending date:</div>
                <span>{{incomeStatementsEndingDate | date : 'M/d/yyyy'}}</span>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="rentRollEndingDate">
                <div>Rent Roll Ending date:</div>
                <span>{{rentRollEndingDate | date : 'M/d/yyyy'}}</span>
            </div>
            <div class="breadcrumb ws-flex-container-horizontal asset-info" *ngIf="glAccountsIntendedUse">
                <div>Intended use:</div>
                <span>{{glAccountsIntendedUse}}</span>
            </div>
        </div>
        <div class="ws-flex-none ws-flex-align-self-center">
            <entity-import-progress [importFile]="importFileModel" [clickable]="false"></entity-import-progress>
        </div>
    </div>
    <div class="ws-flex-container-vertical ws-flex-auto">

        <!-- column mapping step -->
        <entity-import-column-mapping-step class="ws-flex-auto ws-stretch ws-flex-container-vertical"
                                           [importId]="importId"
                                           *ngIf="importStep === importSteps.ColumnMapping">
        </entity-import-column-mapping-step>

        <!-- editing step -->
        <entity-import-editing-step class="ws-flex-auto ws-stretch ws-flex-container-vertical"
                                    [importFileModel]="importFileModel"
                                    *ngIf="importStep === importSteps.Editing"
                                    [busyRefId]="busyRefId"
                                    [selectedRowsInfo$]="selectedRowsInfo$">
        </entity-import-editing-step>

    </div>

    <!-- buttons -->
    <div class="ws-flex-container-horizontal" style="margin: 10px 0;" *ngIf="importStep">
        <div class="ws-flex-container-horizontal ws-flex-auto">
            <div class="grid-action-button-wrapper"
                 *ngIf="isGoBackVisible"
                 helpTooltip
                 [helpContentId]="backHelpContentId"
                 position="top">
                <button type="button"
                        class="flat-button small-button primary-button"
                        [disabled]="!isGoBackEnabled"
                        (click)="goBack()">
                    <span><i class="fa fa-arrow-left" style="margin-right: 8px"></i>Back</span>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="import-process.close"
                 position="top">
                <button type="button"
                        class="flat-button small-button primary-button"
                        [disabled]="!isCloseEnabled"
                        (click)="onCloseClick()">
                    <span>Close</span>
                </button>
            </div>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none">
            <div class="action-button-wrapper"
                 *ngIf="isRevalidateVisible"
                 helpTooltip
                 helpContentId="import-process.revalidate"
                 position="top">
                <button type="button"
                        class="flat-button small-button primary-button"
                        (click)="revalidate()">
                    <span>Revalidate</span>
                </button>
            </div>
            <div class="action-button-wrapper"
                 *ngIf="isDeleteVisible"
                 helpTooltip
                 [helpContentId]="deleteHelpContentId"
                 position="top">
                <button type="button"
                        class="flat-button small-button warning-button"
                        [disabled]="!isDeleteEnabled"
                        (click)="delete()">
                    <span>Delete</span>
                </button>
            </div>
            <div class="action-button-wrapper"
                 *ngIf="isMarkAsCompletedVisible"
                 helpTooltip
                 [helpContentId]="markCompletedHelpContentId"
                 position="top">
                <button type="button"
                        class="flat-button small-button warning-button"
                        [disabled]="!isMarkAsCompletedEnabled"
                        (click)="markAsCompleted()">
                    <span>Mark as Completed</span>
                </button>
            </div>
            <div class="action-button-wrapper"
                 *ngIf="isGoNextVisible"
                 helpTooltip
                 [helpContentId]="nextHelpContentId"
                 position="top">
                <button type="button"
                        class="flat-button small-button success-button"
                        [disabled]="!isGoNextEnabled"
                        (click)="goNext()">
                    <span>{{ nextText }}<i class="fa fa-arrow-right" style="margin-left: 8px"></i></span>
                </button>
            </div>
        </div>
    </div>
</div>
