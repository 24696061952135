import {
    Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewChild, Output,
    ChangeDetectorRef
} from '@angular/core';
import { AnnualDetailComponent, AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { AnnualAssessmentDetail, Assessment } from './assessment.model';
import { AssessmentService, AssessmentViewModel } from './assessment.service';
import { AnnualDetailsService, ComponentFilter } from '../annual-details.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { CurrentEntityService } from '../../Common/Entity/CurrentEntityService';
import { DecimalPipe } from '@angular/common';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { ReorderRevisionsModal } from '../Modals/reorder-revisions.component';
import { AllocationService } from '../../Compliance/Allocation/allocation.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { Decimal } from 'decimal.js';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import * as moment from 'moment';
import * as _ from 'lodash';
import EntityTypes = Core.EntityTypes;
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { AssessmentComponentExemptionPipe } from '../Pipes/assessment-component-exemption.pipe';

const ASSESSMENT_ENTITY_TYPE_ID = EntityTypes.AnnualAssessment;

// type lite should have had our back here but no joy, so hand coding this. please don't use outside this component
interface TaskSummaryItemDTO {
    TaskSeriesID: number;
    TaskSeriesTypeID: number;
    Status: string;
    CompletedDateTime?: Date;
    SeriesIsComplete: boolean;
    EntityID: number;
    EntityTypeID: number;
    OriginalEntityID: number;
    TrackDataEnterRefundComplete: boolean;
}

// type lite should have had our back here but no joy, so hand coding this. please don't use outside this component
interface TaskSummaryItemDTOAssessment extends TaskSummaryItemDTO {
    AdjustNoticeIsReady?: boolean;
    ObtainPostDeterminationReady?: boolean;
}

@Component({
    selector: 'assessment',
    templateUrl: './assessment.component.html'
})
export class AssessmentComponent implements OnInit, OnChanges {
    constructor(
        public annualDetailsService: AnnualDetailsService,
        private _allocationService: AllocationService,
        private _assessmentService: AssessmentService,
        private _taskService: TaskService,
        private _currentEntityService: CurrentEntityService,
        private _decimalPipe: DecimalPipe,
        private _messageBox: MessageBoxService,
        private _toastr: ToastrService,
        private _changeDetector: ChangeDetectorRef,
        private _navigationEvent: AnnualDetailsNavigationEventService,
        private _navigationService: UpgradeNavigationServiceHandler,
        private _modalService: BsModalService,
        private _featureFlagService: FeatureFlagsService,
        private _exemptionPipe: AssessmentComponentExemptionPipe
    ) { }

    @Input() viewModel: AssessmentViewModel;
    @Input() currentYear?: AnnualDetailYear;
    @Input() componentTypes?: any[];
    @Input() editState?: AnnualDetailEditState;
    @Input() taskReadonly: boolean = false;
    @Input() gridNavigationHandler: () => void;
    @Input() compactMode: boolean = false;
    @Input() isDocumentProcessing: boolean = false;
    @Input() companyId: number;
    @Input() propertyType: number;
    @Output() viewModelChange: EventEmitter<AssessmentViewModel> = new EventEmitter<AssessmentViewModel>();
    @Output() AssessmentChanged: EventEmitter<Assessment> = new EventEmitter<Assessment>();
    @ViewChild('renamePopover') renamePopover: PopoverDirective;

    ComponentFilter = ComponentFilter;
    taskSummaries: TaskSummaryItemDTOAssessment;
    allocationTaskSummaries: TaskSummaryItemDTO
    originalRevisionName: string;
    revisionName: string;
    revisionOtherName: string;
    taskSummariesLoaded: boolean = false;
    allocationTaskSummariesLoaded: boolean = false;
    showPopover: boolean = false;
    popoverDirection: 'bottom' | 'right' = 'bottom';
    dateChanged: boolean = false;
    AssessmentEntityTypeId: number = ASSESSMENT_ENTITY_TYPE_ID;

    hasAllocationFeature: boolean;
    hasAllocation: boolean;
    isPPParcel: boolean;
    appealByChanged: boolean = false;

    private _busyIndicatorMsgManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager();

    ngOnInit(): void {
        this.hasAllocationFeature = this._featureFlagService.featureFlags.enableAllocationFeature;
        this.isPPParcel = this.propertyType === Core.PropertyTypes.Personal;

        this.viewModelChanged();

        if (this.viewModel) {
            this.setVariablesFromViewModel();
        }

        if (this.compactMode) {
            this.popoverDirection = 'right';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (Object.keys(changes).indexOf('viewModel') >= 0 && !changes.viewModel.firstChange) {
            this.viewModelChanged();
        }

        // This might not strictly-speaking be necessary, but for completeness make sure componentTypes is
        // always synced with the view model
        if (Object.keys(changes).indexOf('componentTypes') >= 0 && this.componentTypes && this.viewModel) {
            this.viewModel.components = this.componentTypes;
        }

        // if (Object.keys(changes).indexOf('revisionName') >= 0){
        //     this.viewModel.model.revisionDesc = ((this.viewModel.model.revisionDesc.toString() == 'Final') || (this.viewModel.model.revisionDesc.toString() == 'Original')) ? this.revisionName : this.revisionOtherName;
        // }
    }

    viewModelChanged(): void {
        if (this.viewModel) {
            this.viewModel.model.annualAssessmentDetails = this.getSortedAnnualAssessmentDetails();
            this.editState.showEditControls = this.viewModel.model !== undefined;
            this.setVariablesFromViewModel();
            this.viewModel.hasWritePermission = this.editState.hasWritePermission;
            this.viewModel.updateModalData();
            this.getTaskSummaries();
            this.getAllocationTaskSummaries(); // TODO: maybe wrap in feature flag
            if (this.componentTypes) {
                this.viewModel.components = this.componentTypes;
            }
        } else {
            this.editState.showEditControls = false;
        }
    }

    get appealByIsInPast(): boolean {
        return moment(this.viewModel.model.appealDeadline).isBefore(moment())
    }

    get appealByIsLaterThanOriginal(): boolean {
        return moment(this.viewModel.model.appealDeadline).isAfter(this.viewModel.model.originalDeadline)
    }

    get isTargetValueGreaterThanTotalFmv(): boolean {
        let typeIdsToReject = [];
        
        if(this._exemptionPipe.transform(this.viewModel.model.annualAssessmentDetails, ComponentFilter.nonexempt).length > 0) {
            typeIdsToReject = [3];
        }
        else if(this._exemptionPipe.transform(this.viewModel.model.annualAssessmentDetails, ComponentFilter.exempt).length > 0) {
            typeIdsToReject = [2, 3];
        }

        return this.viewModel.currentYear.targetValue > this.getTotal('fairMarketValue', typeIdsToReject);
    }

    setRevisionName(closeModal: boolean = false): void {
        if (this.revisionName !== 'Other') {
            this.viewModel.model.revisionDesc = this.revisionName;
        } else {
            if ((this.revisionOtherName ? this.revisionOtherName.trim() : '') !== '') {
                this.viewModel.model.revisionDesc = this.revisionOtherName;
            } else {
                this.viewModel.model.revisionDesc = this.originalRevisionName;
            }
        }

        if (closeModal) {
            this.renamePopover.hide();
        }

        this.editState.refreshGrid = true;
        this.editState.refreshActivity = true;
        this.setDirty();
    }

    validateRevisionName(): void {
        if (!this.viewModel.model.revisionDesc) {
            this.viewModel.model.revisionDesc = this.originalRevisionName;
        }
    }

    launchRevisionReorder(): void {
        this.promptAutoSave('Reordering revisions with pending changes will force a save of the current revision. Do you wish to continue?').then(() => {
            const ref = this._modalService.show(ReorderRevisionsModal);

            ref.content.initialize({
                annualYearID: this.currentYear.annualYearID,
                year: this.currentYear.annualYear1,
                assessments: this.currentYear.annualGridDetails.map(i => ({
                    id: i.annualAssessmentID,
                    description: i.revisionDesc,
                    sequence: i.revisionNum
                }))
            });

            let subscription = ref.content.assessmentsReorderedEventEmitter.subscribe(
                // next
                () => {
                    this.editState.refreshGrid = true;
                    this.refreshAssessmentData();
                },
                // error
                () => {},
                // completed
                () => {
                    subscription.unsubscribe();
                    ref.hide();
                }
            );
        });
    }

    componentRatioChange(component: AnnualAssessmentDetail): void {
        component.ratio = component.displayRatio / 100;
    }

    // We left this as "any" because this is a generic component type
    // and we don't currently have a model or understand it well enough
    getUnusedComponentTypes(annualAssessmentDetails): any {
        return _.reject(this.viewModel.components, (compType) => {
            // check if this compType has been used by any of the annualAssessmentDetails already
            const compTypeIsInUse = _.some(annualAssessmentDetails, (annualAssessmentDetail: AnnualDetailComponent) => {
                return annualAssessmentDetail.efAction !== 'delete' && annualAssessmentDetail.assessmentClassComponentID === compType.assessmentClassComponentID;
            });

            let alternateComponentAlreadyInUse = false;

            if (compType.assessmentComponent.assessmentComponentTypeID === 3) {
                alternateComponentAlreadyInUse = _.some(annualAssessmentDetails, function (annualAssessmentDetail: AnnualDetailComponent) {
                    return annualAssessmentDetail.efAction !== 'delete' && annualAssessmentDetail.assessmentComponentTypeID === 3;
                });
            }

            return compTypeIsInUse || alternateComponentAlreadyInUse || !compType.enabled;
        });
    }

    getTaskSummaries(): void {
        if (this.viewModel && this.viewModel.model && this.viewModel.model.annualAssessmentID) {
            //removed a constant value
            this._taskService.getTaskSummaryByEntity(this.viewModel.model.annualAssessmentID, EntityTypes.AnnualAssessment)
                .then((response) => {
                    this.taskSummaries = response && response.length > 0
                        ? response[0]
                        : undefined;

                    this.taskSummariesLoaded = true;

                });
        }
    }

    getAllocationTaskSummaries(): void {
        if (this.viewModel && this.viewModel.model && this.viewModel.model.annualAssessmentID) {
            // Determine annualAssessmentID to use if there are multiple revisions
            const annualAssessmentId = this.viewModel.currentYear.annualGridDetails.length > 1
                ? this.viewModel.currentYear.annualGridDetails[this.viewModel.currentYear.annualGridDetails.length - 1].annualAssessmentID
                : this.viewModel.model.annualAssessmentID;

            this._taskService.getTaskSummaryByEntity(annualAssessmentId, EntityTypes.AssessmentAllocation)
                .then((response) => {
                    this.allocationTaskSummaries = response && response.length > 0
                        ? response[0]
                        : undefined;

                    this.allocationTaskSummariesLoaded = true;
                });
        }
    }

    launchTaskModal(): void {
        // step 1: if dirty and in "full" mode aka annual details, save assessment
        // step 2: show toast if auto save is successful
        // step 3: launch modal
        // step 4: on modal close if shouldReloadTasks, refetch assessments
        if (this.editState.getDirty() && !this.compactMode) {
            this.editState.saveHandler().then(() => {
                this.editState.setDirty(false);
                this.viewModel.setEditBackup();
                this._toastr.info('Assessment changes autosaved');
                this._taskService.launchTaskModal(this.viewModel.model.annualAssessmentID, ASSESSMENT_ENTITY_TYPE_ID, this.taskReadonly || !this.editState.editMode).then(shouldReloadTasks => {
                    if (shouldReloadTasks) {
                        this.getTaskSummaries();
                        this.getAllocationTaskSummaries(); // TODO: feature flag?
                        this.refreshAssessmentData();
                        this._navigationEvent.refreshActivityData();
                        this.editState.setDirty(false);
                        this.editState.refreshGrid = true;
                    }
                });
            });
        } else {
            this._taskService.launchTaskModal(this.viewModel.model.annualAssessmentID, ASSESSMENT_ENTITY_TYPE_ID, this.taskReadonly || !this.editState.editMode).then(shouldReloadTasks => {
                if (shouldReloadTasks) {
                    this.getTaskSummaries();
                    this.getAllocationTaskSummaries(); // TODO: feature flag?
                    this.refreshAssessmentData();
                    this._navigationEvent.refreshActivityData();
                    this.editState.refreshGrid = true;
                }
            });
        }
    }

    isDeadlineOverridden(): boolean {
        return this.viewModel.model &&
               (this.viewModel.model.appealDeadline && !this.viewModel.model.originalDeadline ||
                (this.viewModel.model.appealDeadline &&
                 this.viewModel.model.originalDeadline &&
                 !this.areDeadlinesEqual()));
    }

    revertDateCheck(): void {
        if (!this.areDeadlinesEqual() && !this.viewModel.model.appealDeadline) {
            this.viewModel.model.appealDeadline = this.viewModel.model.originalDeadline;
        }
    }

    /*updateAction(revision): void {
        revision.efAction = revision.efAction === null ? 'update' : revision.efAction
    }*/

    readOnly(): boolean {
        if (this.viewModel.model === undefined) {
            return true;
        }
        return !this.editState.editMode;
    }

    changeActual(): void {
        this.viewModel.model.status = this.viewModel.model.status ? 0 : 1;
    }

    canEditTable(): boolean {
        return this.editState.editMode
               && (!this.viewModel.isActual()
                   || this._isAdjustNoticeReady()
                   || this._isObtainPostDeterminationReady())
               && !this.viewModel.currentYear.calcProjected;
    }

    showComponentDropdown(): boolean {
        //Conditions are to show if:
        //1) We are in Edit Mode
        //2) There are still more components to add
        //3) The revision is Not Actual
        //4) The revision is Not Auto Calculated

        return !(this.readOnly() ||
            this.getUnusedComponentTypes(this.viewModel.model.annualAssessmentDetails).length === 0 ||
            (this.viewModel.isActual() && !this._isAdjustNoticeReady()) ||
            this.viewModel.currentYear.calcProjected);
    }

    private _isAdjustNoticeReady(): boolean {
        return this.taskSummaries && this.taskSummaries.AdjustNoticeIsReady;
    }

    private _isObtainPostDeterminationReady(): boolean {
        return this.taskSummaries && this.taskSummaries.ObtainPostDeterminationReady;
    }

    //We need better names for this...
    calcDisabled(): string {
        if (this.disableCalc()) {
            return 'disabled';
        } else {
            return '';
        }
    }

    //We really need better names for this...
    disableCalc(): boolean {
        return !this.viewModel.currentYear.calcProjected && _.reject(this.viewModel.currentYear.annualGridDetails, {
            revisionDesc: '<New>'
        }).length > 1;
    }

    //Like, seriously we need better names
    someExem(): boolean {
        return _.some(this.viewModel.model.annualAssessmentDetails, {
            assessmentComponentTypeID: 2
        });
    }

    getTotal(column, typeIdsToReject: number[]): number {
        return this.viewModel.model.annualAssessmentDetails
                       .filter((component) => {
                           return !_.includes(typeIdsToReject, component.assessmentComponentTypeID);
                       })
                       .reduce((sum, component) => {
                           if (component.efAction === 'delete') {
                               return sum;
                           }

                           if (column === 'fairMarketValue') {
                               return new Decimal(sum).plus(this.getFairMarketValue(component)).toNumber();
                           } else {
                               return new Decimal(sum).plus(Number(component[column])).toNumber();
                           }
                       }, 0);
    }

    getFairMarketValue(component): number {
        if (component.fairMarketValue !== undefined) {
            const trimmedValue = _.trim(component.fairMarketValue, '#');
            return Number(trimmedValue);
        }
        if (!component.ratio || this.annualDetailsService.parseFMV(component.ratio) === 0) {
            return 0;
        }

        let parsedAssesedValue: number = this.annualDetailsService.parseFMV(component.assessedValue);
        let parsedRatio: number = this.annualDetailsService.parseFMV(component.ratio);

        return new Decimal(parsedAssesedValue).dividedBy(parsedRatio).toNumber();

    }

    areDeadlinesEqual(): boolean {
        const appeal = new Date(this.viewModel.model.appealDeadline);
        const original = new Date(this.viewModel.model.originalDeadline);

        if (appeal && original) {
            return appeal.getFullYear() === original.getFullYear() &&
                   appeal.getMonth() === original.getMonth() &&
                   appeal.getDate() === original.getDate();
        } else {
            return !appeal && !original;
        }
    }

    reset(annualDetail: AnnualAssessmentDetail): void {
        annualDetail.fmvOverridden = false;
        this.calculate('', annualDetail, 0);
        this.setDirty();
    }

    calculate(changed: string, component: AnnualAssessmentDetail, newValue: number = undefined): void {
        let allowNegatives: boolean = false;

        //set emitted new value, if applicable. This depends on the changed component type
        if (newValue !== undefined) {
            if (changed === 'av') {
                component.assessedValue = newValue;
            } else if (changed === 'fairMarketValue') {
                component.fairMarketValue = newValue;
            }
        }
        if (component.assessmentComponentTypeID === 2) {
            allowNegatives = true;

        }

        //If we're running calculations, something has changed, so mark it
        this.setDirty();

        //Process each of the textboxes separately
        switch (changed) {
            case 'ratio':
                component.displayRatio = component.displayRatio ? component.displayRatio : null;
                break;
            case 'av':
                let assessedValue: string;

                if (component.assessedValue) {
                    assessedValue = component.assessedValue.toString();
                } else {
                    assessedValue = '0';
                }
                component.assessedValue = +this.filterNumber(assessedValue, false, allowNegatives);
                this._changeDetector.detectChanges();
                break;
        }

        this.componentRatioChange(component);

        component.efAction = component.efAction === null ? 'update' : component.efAction;

        if ((component.fairMarketValue && component.fairMarketValue.toString().indexOf('#') !== -1) || component[changed] === '-' || component.fmvOverridden || !component.ratio) {
            return;
        }

        //Process negatives from the exemptions field
        if (component.assessmentComponentTypeID === 2) {
            if (component.fairMarketValue > 0) {
                component.fairMarketValue = -Math.abs(component.fairMarketValue);
            }
            if (component.assessedValue > 0) {
                component.assessedValue = -Math.abs(component.assessedValue);
            }
        } else {
            if (component.fairMarketValue < 0) {
                component.fairMarketValue = Math.abs(component.fairMarketValue);
            }
            if (component.assessedValue < 0) {
                component.assessedValue = Math.abs(component.assessedValue);
            }
        }

        if (changed === 'fairMarketValue') {
            component.assessedValue = new Decimal(component.fairMarketValue).times(component.ratio).round().toNumber();
        } else {
            component.fairMarketValue = new Decimal(component.assessedValue).dividedBy(component.ratio).round().toNumber();
        }

        this.viewModel.AssessmentChanged = this.AssessmentChanged;
        this.AssessmentChanged.emit(this.viewModel.model);
    }

    filterNumber(input: string, hash: boolean, negative: boolean): string {
        if (input === undefined) {
            input = '';
        }
        let clean;

        if (hash && input[0] === '#') {
            clean = input.substring(1);
            if (negative && clean[0] === '-') {
                clean = '-' + clean.substring(1).replace(/[^0-9]+/g, '');
            } else {
                clean = clean.replace(/[^0-9]+/g, '');
            }
            clean = '#' + clean;
        } else {
            if (negative && input[0] === '-') {
                clean = '-' + input.substring(1).replace(/[^0-9]+/g, '');
            } else {
                clean = input.replace(/[^0-9]+/g, '');
            }
        }

        return clean;
    }

    maybeRevertToCalcRatio(component: AnnualAssessmentDetail): void {
        if (component.ratio) {
            component.displayRatio = +this._decimalPipe.transform(component.displayRatio, '1.3-3');

            this.calculate('ratio', component);
        } else {
            this.revertToCalcRatio(component);
        }
    }

    revertToCalcRatio(component: AnnualAssessmentDetail): void {
        component.ratio = component.assessmentClassRatio.assessmentRatio;
        component.displayRatio = +this._decimalPipe.transform(component.ratio * 100, '1.3-3');

        this.calculate('ratio', component);
    }

    undoCalcRatio_Click(component: AnnualAssessmentDetail) {
        this.revertToCalcRatio(component);
        this.setDirty();
    }

    makeOverridden(component): void {
        if (component.fairMarketValue[0] === '#') {
            component.fairMarketValue = this.annualDetailsService.parseFMV(component.fairMarketValue);
            component.fmvOverridden = true;
        } else if (!component.fairMarketValue) {
            component.fmvOverridden = false;
            this.calculate('', component);
        } else {
            component.fmvOverridden = false;
        }

        this.AssessmentChanged.emit(this.viewModel.model);
    }

    addRow(component: any): void {

        // api/AnnualAssessmentDetail?annualAssessmentID=5202118&assessmentClassComponentID=292&assessmentClassID=8&assessorID=170&annualYear=2016&componentName=Other
        const params = {
            annualAssessmentID: this.viewModel.model.annualAssessmentID,
            assessmentClassComponentID: component.assessmentClassComponentID,
            assessmentClassID: component.assessmentClassID,
            assessorID: this.viewModel.assessorID,
            annualYear: this.viewModel.currentYear.annualYear1,
            componentName: component.componentName
        };

        this._assessmentService.getAssessmentComponent(params).then((result: AnnualAssessmentDetail) => {
            let newComponent: AnnualAssessmentDetail = result;

            if (!newComponent.fairMarketValue) {
                newComponent.fairMarketValue = 0;
            }

            if (this.viewModel.model.annualAssessmentID !== undefined) {
                newComponent.annualAssessmentID = this.viewModel.model.annualAssessmentID;
            }

            newComponent.efAction = 'add';
            this.viewModel.model.annualAssessmentDetails.push(newComponent);
            this.viewModel.model.annualAssessmentDetails = this.getSortedAnnualAssessmentDetails();
            this.viewModel.recalculateVariables();
            this.setDirty();
            this.viewModel.AssessmentChanged = this.AssessmentChanged;
            this.AssessmentChanged.emit(this.viewModel.model);
        });
    }

    deleteComponent(component: AnnualAssessmentDetail): void {
        _.remove(this.viewModel.model.annualAssessmentDetails, component);
        this.setDirty();
        this.viewModel.AssessmentChanged = this.AssessmentChanged;
        this.AssessmentChanged.emit(this.viewModel.model);
        this._navigationEvent.refreshActivityData();
    }

    deleteAssessment(): void {
        let message: string = 'Are you sure you want to delete this assessment?';

        if (this.viewModel.currentYear.annualGridDetails.length === 1) {
            message = 'WARNING: All related assessment, appeal, tax bill, and compliance information for ' + this.viewModel.currentYear.annualYear1 + ' will also be deleted!';
        }

        this._messageBox.open({
            title: 'WARNING',
            message: message,
            buttons: MessageBoxButtons.YesNo
        }).then((result) => {
            switch (result) {
                case MessageBoxResult.Yes:
                    this._assessmentService.deleteAssessment(this.viewModel.model.annualAssessmentID).then(() => {
                        this.editState.editMode = false;
                        this.editState.setDirty(false);
                        this.editState.refreshGrid = true;
                        this.editState.staleAppealSavings = true;
                        this._navigationEvent.refreshActivityData();
                        this.gridNavigationHandler();
                    });
                    break;
                case MessageBoxResult.No:
                    break;
            }
        });
    }

    getCommentsModalData(): any {
        const parcel = this._currentEntityService.get();

        return {
            parcelAcctNum: parcel.name,
            parcelID: parcel.id,
            entityTypeID: 7,
            entityID: this.viewModel.model.annualAssessmentID,
            description: this.viewModel.currentYear.annualYear1 + ' - ' + this.viewModel.model.revisionDesc,
            year: this.viewModel.currentYear.annualYear1,
            canEdit: true
        };
    }

    async autoCalculateRefresh(): Promise<void> {
        this.viewModel.dirtyAnnualYear = true;
        //if setting autocalculate to true, we must:
        //1) Confirm
        //2) Save the assessment, to store any name/date changes made
        //3) Save the year, which is what triggers the autocalc values to change
        //4) Refetch the assessment to get the autocalculated values
        if (this.viewModel.currentYear.calcProjected) {
            const result = await this._messageBox.open({
                title: 'WARNING',
                message: 'Enabling auto calculate will force your revision to be saved. Do you wish to continue?',
                buttons: MessageBoxButtons.YesNo
            });
            switch (result) {
                case MessageBoxResult.Yes:
                    this.editState.entityLoading = true;
                    await this._assessmentService.saveAssessmentFromViewModel(this.viewModel);
                    //this.viewModel.model = result;
                    this.annualDetailsService.saveYear(this.viewModel.currentYear).then((result: AnnualDetailYear) => {
                        this.viewModel.currentYear.rowVersion = result.rowVersion;
                        if (this.viewModel.currentYear.calcProjected) {
                            let currentYearCarryover = this.viewModel.currentYear;
                            this.refreshAssessmentData().then(() => {
                                this.viewModel.currentYear = currentYearCarryover;
                            });
                        }
                    });
                    break;
                case MessageBoxResult.No:
                    this.viewModel.currentYear.calcProjected = false;
                    break;
            }
        }
        //Save the "auto calculate" in the year, independent of the assessment
        else {
            //this.annualDetailsService.saveYear(this.viewModel.currentYear);
            this.viewModel.currentYear.calcProjected = false;
            this.setDirty();
        }
    }

    targetValueChanged(): void {
        this.viewModel.dirtyTargetValue = true;
    }

    onAppealDeadlineChanged(): void {
        this.setDirty();
        this.editState.staleAppealStatus = true;
        this.editState.refreshActivity = true;
        this.appealByChanged = true;
    }

    setDirty(): void {
        this.editState.setDirty(true);
        this.editState.staleAppealSavings = true;
        this.editState.refreshGrid = true;
    }

    blurPopover($event): void {
        $event.preventDefault();
        $event.target.blur();
    }

    getSortedAnnualAssessmentDetails(): AnnualAssessmentDetail[] {
        return _.sortBy(this.viewModel.model.annualAssessmentDetails, (component) => { return component.sequence;});
    }

    resetAppealDeadline_click(): void {
        this.viewModel.model.appealDeadline = this.viewModel.model.originalDeadline;
        this.appealByChanged = false;
        this.setDirty();
    }

    goToAllocation(): void {
        this._allocationService.navigationParcelId = this.viewModel.parcelID;

        if (!this.hasAllocation) {
            this._createAllocation();
            return;
        }

        this._navigationService.go('allocationDetails',
            {
                allocationId: this.viewModel.model.allocationId
            });
    }

    launchAllocationTaskModal() {
        // Determine the annualAssessmentID to use if there are multiple revisions
        const annualAssessmentId = this.viewModel.currentYear.annualGridDetails.length > 1
            ? this.viewModel.currentYear.annualGridDetails[this.viewModel.currentYear.annualGridDetails.length - 1].annualAssessmentID
            : this.viewModel.model.annualAssessmentID;

        // step 1: if dirty and in "full" mode aka annual details, save assessment
        // step 2: show toast if auto save is successful
        // step 3: launch modal
        // step 4: on modal close if shouldReloadTasks, refetch assessments
        if (this.editState.getDirty() && !this.compactMode) {
            this.editState.saveHandler().then(() => {
                this.editState.setDirty(false);
                this.viewModel.setEditBackup();
                this._toastr.info('Assessment Allocations changes autosaved');
                this._taskService.launchTaskModal(annualAssessmentId, EntityTypes.AssessmentAllocation, this.taskReadonly || !this.editState.editMode).then(shouldReloadTasks => {
                    if (shouldReloadTasks) {
                        this.getAllocationTaskSummaries(); // TODO: feature flag?
                        this.refreshAssessmentData();
                        this._navigationEvent.refreshActivityData();
                        this.editState.setDirty(false);
                        this.editState.refreshGrid = true;
                    }
                });
            });
        } else {
            this._taskService.launchTaskModal(annualAssessmentId, EntityTypes.AssessmentAllocation, this.taskReadonly || !this.editState.editMode).then(shouldReloadTasks => {
                if (shouldReloadTasks) {
                    this.getAllocationTaskSummaries(); // TODO: feature flag?
                    this.refreshAssessmentData();
                    this._navigationEvent.refreshActivityData();
                    this.editState.refreshGrid = true;
                }
            });
        }
    }

    private async _createAllocation(): Promise<void> {
        try {
            await this.annualDetailsService.preNavigateWarn(this.editState);
        } catch (e) {
            return;
        }

        const busyMsgId = 'loading';
        this._busyIndicatorMsgManager.add('Creating Allocation', busyMsgId);

        try {
            const model: Compliance.AllocationCreateModel = {
                annualAssessmentId: this.viewModel.model.annualAssessmentID
            };
            const result = await this._allocationService.createAllocation(this.companyId, model);
            this.viewModel.model.allocationId = result.allocationId;
            this.hasAllocation = true;
            this.goToAllocation();
        } finally {
            this._busyIndicatorMsgManager.remove(busyMsgId);
        }
    }

    private setVariablesFromViewModel() {
        //Set initial revision name for the rename modal radio button list
        if ((this.viewModel.model.revisionDesc.toString() == 'Final') || (this.viewModel.model.revisionDesc.toString() == 'Original') || (this.viewModel.model.revisionDesc.toString() == 'Forecast')) {
            this.revisionName = this.viewModel.model.revisionDesc;
        } else {
            this.revisionName = 'Other';
            this.revisionOtherName = this.viewModel.model.revisionDesc;
        }

        if (!this.viewModel.currentYear && this.currentYear) {
            this.viewModel.currentYear = this.currentYear;
        }

        this.hasAllocation = !!this.viewModel.model.allocationId;

        this.viewModel.recalculateVariables();
    }

    private promptAutoSave(message: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.editState.getDirty() && !this.compactMode) {
                return this._messageBox.open({
                    title: 'WARNING',
                    message: message,
                    buttons: MessageBoxButtons.YesNo
                }).then((result) => {
                    switch (result) {
                        case MessageBoxResult.Yes:
                            return this.editState.saveHandler().then(() => {
                                this.editState.setDirty(false);
                                this.viewModel.setEditBackup();
                                this._toastr.success('Successfully saved.');
                                resolve();
                            }, () => reject());

                        default:
                            reject();
                            break;
                    }
                });
            }

            resolve();
        });
    }

    private refreshAssessmentData(): Promise<void> {
        return this._assessmentService.getAssessmentViewModelByID(
            this.viewModel.model.annualAssessmentID,
            this.viewModel.assessorID,
            this.viewModel.parcelID,
            this.viewModel.parcelAcctNum,
            this.viewModel.currentYear)
                   .then((refreshResult: AssessmentViewModel) => {
                       _.assign(this.viewModel, refreshResult);
                       this.setVariablesFromViewModel();
                       this.editState.entityLoading = false;
                       this.viewModel.beginEdit();
                       this.AssessmentChanged.emit(this.viewModel.model);
                       this._navigationEvent.refreshAnnualYear();
                   });
    }
}
