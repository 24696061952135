import { Component } from '@angular/core';
import { TopNavService } from '../Top-Nav/topNav.service';

@Component({
    selector: 'onboarding-banner',
    template: '<span>There have been updates to the interface and navigation, <a class="clickable" (click)="openModal()">click</a> to learn more.</span>'
})
export class OnboardingBannerComponent {
    constructor(private readonly _topNavService: TopNavService) {
    }

    async openModal(): Promise<void> {
        await this._topNavService.showOnboardingModal();
    }
}
