import { UserTeamMember } from '../../Common/Models/common.model';

export class IntegrationHmac {
    integrationHmacId: number;
    name: string;
    publicKey: string;
    isActive: boolean;
    companyId: number;
    userId: string;
    companyName: string;
    rowVersion: string;
}

export class IntegrationHmacDetail {
    integrationHmacId: number;
    name: string;
    publicKey: string;
    isActive: boolean;
    companyId: number;
    userId: string;
    companyName: string;
    rowVersion: string;
    users: IntegrationHmacUserDetail[];
}

export class IntegrationHmacUserDetail {
    integratonHmacUserId: number;
    firstName: string;
    lastName: string;
    userId: string;
    rowVersion: string;

    constructor(userTeamMember?: Core.UserTeamModel) {
        if (userTeamMember) {
            this.firstName = userTeamMember.firstName;
            this.lastName = userTeamMember.lastName;
            this.userId = userTeamMember.userID as string;
            this.integratonHmacUserId = 0;
            this.rowVersion = null;
        }
    }
}
