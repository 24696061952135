<div class="ws-flex-container-horizontal ws-flex-align-items-center">
    <select name="limitOptions" id="limitOptions" class="form-select form-select-sm asset-limit-dropdown" [formControl]="selectedOption" (change)="selectedOptionChanged()">
        <option *ngFor="let li of limitOptions" [ngValue]="li">{{ li.name }}</option>
    </select>
    <button type="button"
            class="flat-button small-button primary-button"
            (click)="edit()"
            helpTooltip
            helpContentId="app.edit"
            position="bottom">
        <i class="fa fa-pencil"></i>
    </button>
</div>
