import { UserTeamMember } from "../../Common/Models/common.model";

export class RetrievalStatus {
    retrievalStatusId: number;
    name: string;
}

export class RetrievalStatusPayload {
        taskIds: number[];
        retrievalStatusId: number;
        ignoreRetrievalStatus: boolean;
        followUpDate: Date;
        ignoreFollowUpDate: boolean;
        newAssignment: Core.UserTeamModel;
        comment: string;

        constructor() {
            this.ignoreRetrievalStatus = false;
            this.ignoreFollowUpDate = true;
            this.followUpDate = null;
            this.newAssignment = {} as Core.UserTeamModel;
        }
}
