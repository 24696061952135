(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('actionListOutput', actionListOutput);

	actionListOutput.$inject = [];

	function actionListOutput() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Task/ActionView/_actionListOutput.html',
			scope: {
				outputColumns: '=',
				readOnly: '=',
				taskSeriesTypeAdvancedSearchDepthValue: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	Controller.$inject = ['searchOperators', 'advancedSearchService'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(searchOperators, advancedSearchService) {
		var vm = this;

		vm.addOutputs = addOutputs;
		vm.removeOutput = removeOutput;
		vm.columnMoved = columnMoved;

		function addOutputs(columns) {
			var addedColumns = _.map(columns, function (column) {
				var addedColumn = angular.copy(column);

				addedColumn.operators = searchOperators.getOperatorArray(addedColumn.operators)

                if(column.columnId === 101 || column.columnId === 109) {
                    addedColumn.or = [searchOperators.getCompanyOr(addedColumn.operators)];
                } else {
                    addedColumn.or = [searchOperators.getOr(addedColumn.operators)];
                }

				return addedColumn;
			});

			vm.outputColumns.push.apply(vm.outputColumns, addedColumns);

			setSequence();

			advancedSearchService.disableColumns(vm.outputColumns, false, vm.taskSeriesTypeAdvancedSearchDepthValue)
		}

		function removeOutput(column) {
			_.remove(vm.outputColumns, column);

			setSequence();
			advancedSearchService.disableColumns(vm.outputColumns, false, vm.taskSeriesTypeAdvancedSearchDepthValue);
		}

		function columnMoved(index) {
			vm.outputColumns.splice(index, 1);
			setSequence();
		}

		function setSequence() {
			vm.outputColumns = _.map(vm.outputColumns, function (column, i) {
				column.sequence = i;
				column.showClose = false;
				return column;
			})
		}
	}
})();
