import { Component, OnInit } from '@angular/core';
import { AppStateService, AppStates } from './appStateService';

@Component({
    selector: 'temp-banner',
    templateUrl: './tempBanner.html'
})
export class TempBannerComponent implements OnInit {
    show: boolean;

    constructor(private appStateService: AppStateService) {}

    ngOnInit() {
        /* TODO: This needs to be revisited later. The flow now is
        *
        *       - Page loads
        *       - Angular boots and the banner is displayed immediately (see ngjsStart.js activate function)
        *       - appComponent handles asynchronous initializing (load settings from server for instance)
        *       - "run" in ngjs is run, which re-displays the banner with the correct color now
        *       - Page continues to render with router and full access to all services
        *
        * The problem is that with IAM the PropertyPoint banner is inconsistent with the
        * tax.com banner, so you see temp banner then tax.com banner then temp banner again
        * then real banner. We either want to initialize with a banner consistent with
        * tax.com or we need to not show a banner on initialization. During the development
        * period it's less impact to the overall app to allow the back-and-forth for the
        * banner. Initially it seemed logical to use the enableIAM flag to determine this,
        * but it's not available until after UI Config is loaded from the server.
        */
        this.appStateService.appState$.subscribe(state => {
            this.show = state === AppStates.Initializing || state === AppStates.LoggedOut;
        });
    }
}
