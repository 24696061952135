'use strict';

/**
 * @ngdoc directive
 * @name weissman.directive:Highcharts
 * @description
 * # Highcharts
 */

angular.module('weissmanApp')
    .directive('sdHighchartsColumn', function () {
    	return {
    		restrict: 'E',
    		replace: false,
    		template: '<div></div>',
    		scope: {
    			config:'='
    		},
    		link: function (scope, element) {
    			scope.updated = false;
    			scope.targetElement = element[0];
    			// http://www.highcharts.com/plugin-registry/single/9/Pattern-Fill

    			drawChart(element[0], scope.config);

    			scope.$watch("config", function (newValue, oldValue) {
    				//This gets called when data changes.
    				//console.log('sdHighchartsColumn - config changed! ', newValue)
    				drawChart(element[0], newValue);
    			});
    		}
    	};
    	function drawChart(element, config) {

    		//var w = window.innerWidth;
    		//console.log('window.innerWidth', w);

    		//if (window.innerWidth <= 1280)
    		//	chartConfig.chart.width = 350;

    		if (config) {
    			//Object.keys(config).forEach(function (keyName, index) {
    			//	//if (keyName != 'title')
    			//		chartConfig[keyName] = config[keyName];
    			//});
    			//chartConfig.title.text = '';
    			var newChart = Highcharts.chart(element, config);

    		}

    	}
    });
