import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { WeissmanModalService } from '../../WeissmanModalService';
import { ReturnCompareConfirmationModalComponent } from './returnCompareConfirmationModal.component';
import { ReturnService } from '../return.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'return-compare-message',
    styleUrls: ['./returnCompareMessage.component.scss'],
    templateUrl: './returnCompareMessage.component.html'
})
export class ReturnCompareMessageComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _returnService: ReturnService) {}

    @Output() reset: EventEmitter<void> = new EventEmitter();

    customReturns: Compliance.ReturnCustomReturnModel[] = [];
    isDefault: boolean = true;
    confirmation: boolean = false;
    showMessageDetails: boolean = false;
    isReadOnlyMode: boolean;

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._setComparisonValue(this._returnService.compareFormsResult);
        this.isReadOnlyMode = this._returnService.isReturnInReadOnly;

        this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this.destroy$))
            .subscribe((isReadOnly: boolean) => this.isReadOnlyMode = isReadOnly);

        this._returnService.compareForms$.pipe(takeUntil(this.destroy$))
            .subscribe((comparison: Compliance.ReturnCompareFormsResultModel) => {
                if(comparison) {
                    this._setComparisonValue(comparison);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Toggle the reports list open and closed
     * @param form
     */
    toggleReports(form: Compliance.ReturnCustomFormModel): void {
        form['showReports'] = (form['showReports']) ? false : true;
    }

    /**
     * Toggle the confirmation panel
     */
    async showConfirmation(): Promise<void> {
        const result = await this._modalService.showAsync(ReturnCompareConfirmationModalComponent, null, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this.reset.emit();
        this.showMessageDetails = false;
    }

    /**
     * Display the correct icon
     * @param val
     */
    getIconClass(val: number): string {
        switch (val) {
            case Compliance.ReturnCompareActionEnum.None:
                return 'fa-ban';
            case Compliance.ReturnCompareActionEnum.Remove:
                return 'fa-trash';
            case Compliance.ReturnCompareActionEnum.Add:
                return 'fa-plus';
        }
    }

    /**
     * Display a tooltip
     * @param val
     */
    getIconTitle(val: number): string {
        switch (val) {
            case Compliance.ReturnCompareActionEnum.None:
                return 'No change';
            case Compliance.ReturnCompareActionEnum.Remove:
                return 'Remove from the return';
            case Compliance.ReturnCompareActionEnum.Add:
                return 'Add to the return';
        }
    }

    /**
     * Trackby to help with nested loops
     * @param index
     * @param item
     */
    trackByReturnId(index: number,
        item: Compliance.ReturnCustomReturnModel | Compliance.ReturnCustomFormModel | Compliance.ReturnCustomReportModel): number {
        return item.returnId;
    }

    private _setComparisonValue(compareResult: Compliance.ReturnCompareFormsResultModel): void {
        if (compareResult) {
            this.isDefault = compareResult.isDefault;
            this.customReturns = compareResult.customReturns;
        }
    }
}
