import { Component, Input, OnInit } from '@angular/core';
import { InstanceRights, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { InstanceRepository } from 'src/app/Entity/Instance/instance.repository';
import { cloneDeep, every, filter, find, findIndex, isEqual, map, omit, reject, remove, some, sortBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { efAction } from 'src/app/constants.new';
import { TeamService } from '../team.service';

interface UserTeamUI extends Core.UserTeamModel {
    chooseNew?: boolean
    loading?: boolean
    isLocked?: boolean
    message?: string
    efAction?: efAction
    newTeamID?: number
}

@Component({
    selector: 'team-assignment-panel',
    templateUrl: 'teamAssignmentPanel.component.html'
})

export class TeamAssignmentPanelComponent implements OnInit {
    constructor(private readonly _instanceRepository: InstanceRepository,
        private readonly _restrictService: RestrictService,
        private readonly _teamsService: TeamService,
        private readonly _toastr: ToastrService
    ) { }

    @Input() user: Weissman.Model.Contacts.Contact;

    loading: boolean = false;
    isEditMode: boolean = false;
    canEdit: boolean = false;
    newTeamForTasks: UserTeamUI;
    newTeam: Weissman.Model.Workflow.Team;
    isLrpRunning: boolean = false;

    private _assignedTeams: UserTeamUI[];
    private _cleanAssignedTeams: UserTeamUI[];
    private _allTeams: Weissman.Model.Workflow.Team[];
    private _instanceId: number;

    async ngOnInit() {
        console.log(this.user);
        this.loading = true;

        try {
            this._instanceId = await this._instanceRepository.getEntityInstanceId('company', this.user.companyID).toPromise();
            this.canEdit = this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP, this._instanceId);

            if (!this.user.userID) return;

            const contactFilter = {
                getFullInfo: true,
                includeCompanyRepresentative: true
            };

            const assignedTeams = await this._teamsService.getAllContactUserTeams(this.user.contactID, contactFilter);
            this._assignedTeams = sortBy(assignedTeams, 'teamName');
            console.log(this._assignedTeams);
            // processAssignedTeams(teams);

        } finally {
            this.loading = false;
        }
    }

    get assignedTeams(): UserTeamUI[] {
        return reject(this._assignedTeams, { efAction: 'delete' });
    }

    get assignedTeamsToChoose(): UserTeamUI[] {
        return filter(this.assignedTeams, 'isAssignable');
    }

    get allChoosableTeams(): Weissman.Model.Workflow.Team[] {
        return filter(this._allTeams, team => (
            every(this._assignedTeams, (assignedTeam) => (
                assignedTeam.teamID !== team.teamID || assignedTeam.efAction === 'delete'
            ))
        ));
    }

    get isSaveDisabled(): boolean {
        return some(this._assignedTeams, ({ loading, chooseNew }) => loading || chooseNew);
    }

    async switchToEditMode(): Promise<void> {
        this._cleanAssignedTeams = cloneDeep(this._assignedTeams);
        const allTeams = await this._teamsService.getAllTeamsForContact(false, this._instanceId, this.user.userID);
        this._allTeams = sortBy(allTeams, 'name');
        // this.allTeams = angular.copy(response);
        this.isEditMode = true;
    }

    async checkLinkedEntities(team: UserTeamUI): Promise<void> {
        if (team.isAssignable) return;

        team.loading = true;

        try {
            const { hasLinkedEntities, message } = await this._teamsService.hasLinkedEntities(this.user.userID, team.teamID);

            if (!hasLinkedEntities) return;

            if (some(this._assignedTeams, 'isAssignable')) {
                team.chooseNew = true;
                team.message = `${message} Please choose another team to which they can be assigned.`;
            } else {
                team.isAssignable = true;

                this._toastr.info(`${message} Please add another team that is assignable before removing this one.`);
            }

        } finally {
            team.loading = false;
        }
    }

    updateDefault(team: UserTeamUI): void {
        this._assignedTeams = map(this._assignedTeams, x => {
            x.isDefault = x.teamID === team.teamID;
            return x;
        });
    }

    removeTeam(team: UserTeamUI): void {
        if (team.efAction === 'add') {
            remove(this._assignedTeams, team);
        } else {
            team.efAction = 'delete';
        }
    }

    chooseNewTeam(editedTeam: UserTeamUI) {
        editedTeam.chooseNew = false;
        editedTeam.newTeamID = this.newTeamForTasks.teamID;

        this.newTeamForTasks.isLocked = true;
    }

    cancelChooseNewTeam(team: UserTeamUI) {
        team.isAssignable = true;
        team.chooseNew = false;
    }

    addTeam() {
        if(!this.newTeam) return;

        console.log(this.newTeam);

        const deletedTeamIdx = findIndex(this._assignedTeams, { teamID: this.newTeam.teamID });

        if (deletedTeamIdx !== -1) {
            this._assignedTeams[deletedTeamIdx].efAction = null;
            this._assignedTeams[deletedTeamIdx].isDefault = false;

            return;
        }

        const isDefault = this._assignedTeams.length === 0 || findIndex(this._assignedTeams, 'isDefault') === -1;
        const addedTeam = {
            isAssignable: true,
            isDefault,
            teamID: this.newTeam.teamID,
            userID: this.user.userID,
            teamName: this.newTeam.name,
            efAction: 'add',
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            numberOfClientServiceResponsibilities: 0,
            newTeamID: null
        } as UserTeamUI;

        this._assignedTeams.push(addedTeam);
    }

    async save() {
        const teamsToSave = map(this._assignedTeams, team => {
            const strippedTeam = omit(team, 'isLocked', 'loading', 'chooseNew');

            if(!team.efAction) {
                const cleanTeam = find(this._cleanAssignedTeams, {teamID: strippedTeam.teamID});

                if(!isEqual(strippedTeam, cleanTeam)) {
                    strippedTeam.efAction = 'update';
                }
            }

            return strippedTeam;
        });

        this.loading = true;
        try {
            const thing = await this._teamsService.saveTeamAssignmentTab(teamsToSave as Core.UserTeamModel[]);
            console.log(thing);
            this.isEditMode = false;
            this.isLrpRunning = true;
        } finally {
            this.loading = false;
        }
    }

    cancel() {
        this._assignedTeams = this._cleanAssignedTeams;
        this.isEditMode = false;
    }
}