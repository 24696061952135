import { NgModule } from '@angular/core';
import { ScrollingModule  } from '@angular/cdk/scrolling';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DragulaModule } from 'ng2-dragula'
import { WeissmanSharedModule} from '../../Common/weissman-shared.module';
import { SharedPipesModule} from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule} from '../../Busy-Indicator';
import { AgGridLoadingOverlayComponent} from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer } from '../AgGrid/MultiSelectTracker'
import { AgGridModule} from 'ag-grid-angular';
import { AssetClassSelectorModule } from '../Asset/Asset-Class-Selector/assetClassSelector.module';
import { ReturnService } from './return.service';
import { ReturnOverviewService } from './Return-Parts/Overview/returnOverview.service';
import { ReturnAssetsService } from './Return-Parts/Assets/returnAssets.service';
import { ReturnOutputService } from './Return-Parts/Output/returnOutput.service';
import { ReturnSettingsService } from './Return-Parts/Settings/returnSettings.service';
import { ReturnPageComponent } from './returnPage.component';
import { ReturnFormSelectorComponent } from './Return-Form-Selector/returnFormSelector.component';
import { ReturnBatchComponent } from './Return-Batch/returnBatch.component';
import { ReturnPartsComponent } from './Return-Parts/returnParts.component';
import { ReturnOverviewComponent } from './Return-Parts/Overview/returnOverview.component';
import { ReturnSummaryReconciliationReportComponent } from './Return-Parts/Overview/Return-Summary/returnSummary.component';
import { ScheduleAdditionsDisposalsReconciliationReportComponent } from './Return-Parts/Overview/Schedule-Additions-Disposals/scheduleAdditionsDisposals.component';
import { FilingControlReconciliationReportComponent } from './Return-Parts/Overview/Filing-Control/filingControl.component';
import { FilingControlListReconciliationReportComponent } from './Return-Parts/Overview/Filing-Control/Filing-Control-List/filingControlList.component';
import { FilingControlGroupedReconciliationReportComponent } from './Return-Parts/Overview/Filing-Control/Filing-Control-Grouped/filingControlGrouped.component';
import { ReturnAssetsComponent } from './Return-Parts/Assets/returnAssets.component';
import { ReturnAssetDragAndDropSourceComponent } from './Return-Parts/Assets/Drag-And-Drop/returnAssetDragAndDropSource.component';
import { ReturnAssetDragAndDropTargetComponent } from './Return-Parts/Assets/Drag-And-Drop/returnAssetDragAndDropTarget.component';
import { ReturnAssetDragAndDropService } from './Return-Parts/Assets/Drag-And-Drop/returnAssetDragAndDrop.service';
import { ReturnAssetListComponent } from './Return-Parts/Assets/Asset-List/returnAssetList.component';
import { ReturnAssetDisposalsListComponent } from './Return-Parts/Assets/Asset-Disposals-List/returnAssetDisposalsList.component';
import { ReturnAssetFormDetailsComponent } from './Return-Parts/Assets/Form-Details/returnAssetFormDetails.component';
import { ReturnAssetFormStatusSelectorComponent } from './Return-Parts/Assets/Form-Details/Form-Status-Selector/returnAssetFormStatusSelector.component';
import { ReturnAssetFormFactorSelectorComponent } from './Return-Parts/Assets/Form-Details/Form-Factor-Selector/returnAssetFormFactorSelector.component';
import { ReturnAssetFormScheduleComponent } from './Return-Parts/Assets/Form-Details/Form-Schedule/returnAssetFormSchedule.component';
import { AssessorTablePickerComponent } from './Return-Parts/Assets/Assessor-Table-Picker/assessorTablePicker.component';
import { ReturnAssetListGridActionCellRendererComponent } from './Return-Parts/Assets/Asset-List/agGridActionCellRenderer.component';
import { ReturnAssetDisposalsListGridActionCellRendererComponent } from './Return-Parts/Assets/Asset-Disposals-List/agGridActionCellRenderer.component';
import { ReturnAssetChangeIconCellRendererComponent } from './Return-Parts/Assets/Asset-List/agGridChangeIconCellRenderer.component';
import { ReturnOutputComponent } from './Return-Parts/Output/returnOutput.component';
import { ReturnSettingsComponent } from './Return-Parts/Settings/Return-Settings/returnSettings.component';
import { ReturnSettingsTabComponent } from './Return-Parts/Settings/returnSettingsTab.component';
import { ReturnLockModalComponent } from './Return-Task/Return-Lock-Modal/returnLockModal.component';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { AgGridCheckboxCellRendererComponent } from '../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { ReturnSettingsEntityAttributeComponent } from './Return-Parts/Settings/Return-Settings-Entity-Attribute/returnSettingsEntityAttribute.component';
import { ReturnSettingsContactAttributeComponent } from './Return-Parts/Settings/Return-Settings-Contact-attribute/returnSettingsContactAttribute.component';
import { FilingBatchModule } from '../Filing-Batch/filingBatch.module';
import { ReturnOutputPackageListComponent } from './Return-Parts/Output/Package-List/returnOutputPackageList.component';
import { ReturnOutputPackageListDropdownCellRenderer } from './Return-Parts/Output/Package-List/agGridDropdownCellRenderer.component';
import { ReturnSettingsReportListComponent } from './Return-Parts/Settings/Report-List/returnSettingsReportList.component';
import { ReturnSettingsReportAddComponent } from './Return-Parts/Settings/Report-List-Add/returnSettingsReportAdd.component';
import { ReturnSettingsReportListGridActionCellRendererComponent } from './Return-Parts/Settings/Report-List/agGridActionCellRenderer.component';
import { ReturnSettingsOverrideComponent } from './Return-Parts/Settings/Settings-Override/returnSettingsOverride.component';
import { ReturnOutputPrintingMailingComponent } from './Return-Parts/Output/Printing-Mailing/returnOutputPrintingMailing.component';
import { ReturnAssetStatusSelectorComponent } from './Return-Parts/Assets/Asset-Status-Selector/returnAssetStatusSelector.component';
import { FilingControlReconciliationReportAssessorRenderer } from './Return-Parts/Overview/Filing-Control/Filing-Control-Grouped/agGridAssessorCellRenderer.component';
import { ReturnTaskComponent } from './Return-Task/returnTask.component';
import { ReturnTaskExceptionComponent } from './Return-Task/Return-Task-Exception/returnTaskException.component';
import { ReturnTaskExceptionModalComponent } from './Return-Task/Return-Task-Exception-Modal/returnTaskExceptionModal.component';
import { ReturnSignModalComponent } from './Return-Task/Return-Sign-Modal/returnSignModal.component';
import { ReturnFormsErrorComponent } from './Return-Forms-Error/returnFormsError.component';
import { ReturnCompareMessageComponent } from './Return-Compare-Message/returnCompareMessage.component';
import { ReturnCompareConfirmationModalComponent } from './Return-Compare-Message/returnCompareConfirmationModal.component';
import { ReturnFinalizeModalComponent } from './Return-Task/Return-Finalize-Modal/returnFinalizeModal.component';
import { ReturnSettingsNameAttributeComponent } from './Return-Parts/Settings/Return-Settings-Name-Attribute/returnSettingsNameAttribute.component';
import { ReturnAssetBulkUpdateComponent } from './Return-Parts/Assets/Return-Asset-Bulk-Update/returnAssetBulkUpdate.component';
import { ReturnAssetBulkUpdateActionComponent } from './Return-Parts/Assets/Return-Asset-Bulk-Update/returnAssetBulkUpdateAction.component';
import { ScheduleSelectorComponent } from './Return-Parts/Assets/Schedule-Selector/scheduleSelector.component';
import { ReturnSettingsGeneralComponent } from "./Return-Parts/Settings/General/returnSettingsGeneral.component";
import { AgGridConsolidatedGroupCellRenderer } from './Return-Parts/Overview/Filing-Control/Filing-Control-Grouped/agGridConsolidatedGroupCellRenderer.component';
import { ReturnPreviewModule } from './Return-Parts/Preview/returnPreview.module';
import { ReturnBatchTaxYearAdditionalInfoComponent } from './Return-Batch/returnBatchTaxYearAdditionalInfo.component';
import {
    ReturnAssessorAddressChangedMessageComponent
} from './Return-Assessor-Address-Changed-Message/returnAssessorAddressChangedMessage.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        CurrencyMaskModule,
        SharedPipesModule,
        DragulaModule,
        TabsModule,
        TypeaheadModule,
        BsDropdownModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        FilingBatchModule,
        TooltipModule,
        ScrollingModule,
        AssetClassSelectorModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            ReturnAssetListGridActionCellRendererComponent,
            ReturnAssetDisposalsListGridActionCellRendererComponent,
            AgGridCheckboxCellRendererComponent,
            ReturnSettingsReportListGridActionCellRendererComponent,
            FilingControlReconciliationReportAssessorRenderer
        ]),
        ReturnPreviewModule
    ],
    declarations: [
        ReturnPageComponent,
        ReturnTaskComponent,
        ReturnTaskExceptionComponent,
        ReturnTaskExceptionModalComponent,
        ReturnFinalizeModalComponent,
        ReturnFormSelectorComponent,
        ReturnBatchComponent,
        ReturnLockModalComponent,
        ReturnSignModalComponent,
        ReturnFormsErrorComponent,
        ReturnCompareMessageComponent,
        ReturnCompareConfirmationModalComponent,
        // separate tabs related to the return
        ReturnPartsComponent,
        // return overview tab
        ReturnOverviewComponent,
        ReturnSummaryReconciliationReportComponent,
        ScheduleAdditionsDisposalsReconciliationReportComponent,
        FilingControlReconciliationReportComponent,
        FilingControlListReconciliationReportComponent,
        FilingControlGroupedReconciliationReportComponent,
        FilingControlReconciliationReportAssessorRenderer,
        AgGridConsolidatedGroupCellRenderer,
        // return assets tab
        ReturnAssetsComponent,
        ReturnAssetDragAndDropSourceComponent,
        ReturnAssetDragAndDropTargetComponent,
        ReturnAssetListComponent,
        ReturnAssetDisposalsListComponent,
        ReturnAssetFormDetailsComponent,
        ReturnAssetFormStatusSelectorComponent,
        ReturnAssetFormFactorSelectorComponent,
        ReturnAssetFormScheduleComponent,
        ReturnAssetChangeIconCellRendererComponent,
        AssessorTablePickerComponent,
        ReturnAssetListGridActionCellRendererComponent,
        ReturnAssetDisposalsListGridActionCellRendererComponent,
        // return output tab
        ReturnOutputComponent,
        ReturnOutputPackageListComponent,
        ReturnOutputPackageListDropdownCellRenderer,
        ReturnOutputPrintingMailingComponent,
        // return settings
        ReturnSettingsTabComponent,
        ReturnSettingsComponent,
        ReturnSettingsOverrideComponent,
        ReturnSettingsEntityAttributeComponent,
        ReturnSettingsNameAttributeComponent,
        ReturnSettingsContactAttributeComponent,
        ReturnSettingsReportListComponent,
        ReturnSettingsReportAddComponent,
        ReturnSettingsReportListGridActionCellRendererComponent,
        ReturnAssetStatusSelectorComponent,
        ReturnAssetBulkUpdateComponent,
        ReturnAssetBulkUpdateActionComponent,
        ScheduleSelectorComponent,
        ReturnSettingsGeneralComponent,
        ReturnBatchTaxYearAdditionalInfoComponent,
        ReturnAssessorAddressChangedMessageComponent
    ],
    providers: [
        ReturnService,
        ReturnOverviewService,
        ReturnAssetsService,
        ReturnOutputService,
        ReturnSettingsService,
        ReturnAssetDragAndDropService
    ],
    exports: [
        ReturnSettingsComponent,
        ReturnSettingsEntityAttributeComponent,
        ReturnSettingsNameAttributeComponent,
        ReturnSettingsContactAttributeComponent,
        ReturnAssetStatusSelectorComponent
    ]
})
export class ReturnModule{
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('returnPage', ReturnPageComponent);
        hybridAdapterUtility.downgradeNg2Component('complianceReturnSettingsComponent', ReturnSettingsComponent);
        hybridAdapterUtility.downgradeNg2Component('complianceReturnSettingsEntityAttributeComponent', ReturnSettingsEntityAttributeComponent);
        hybridAdapterUtility.downgradeNg2Component('complianceReturnSettingsNameAttributeComponent', ReturnSettingsNameAttributeComponent);
        hybridAdapterUtility.downgradeNg2Component('complianceReturnSettingsContactAttributeComponent', ReturnSettingsContactAttributeComponent);
    }
}
