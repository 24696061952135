import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { FilingBatchRepository } from '../../../Repositories';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../AgGrid';

export interface FilingBatchAddAccountsDataSourceParams {
    filingBatchId: number;
    selectedIds: number[];
}

export class FilingBatchAddAccountsAgGridDataSource extends AgGridDataSourceBase<FilingBatchAddAccountsDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _filingBatchRepository: FilingBatchRepository,
        protected _dataSourceParamsFn: () => FilingBatchAddAccountsDataSourceParams
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.FilingPropertyEnum>[] = [
        { name: 'companyName', value: Compliance.FilingPropertyEnum.Company },
        { name: 'assessorName', value: Compliance.FilingPropertyEnum.Assessor },
        { name: 'parcelAcctNumberDisplay', value: Compliance.FilingPropertyEnum.ParcelAcctNumberDisplay },
        { name: 'filingDescription', value: Compliance.FilingPropertyEnum.FilingDescription },
        { name: 'siteName', value: Compliance.FilingPropertyEnum.SiteName },
        { name: 'siteProperty', value: Compliance.FilingPropertyEnum.SiteProperty },
        { name: 'taxYear', value: Compliance.FilingPropertyEnum.TaxYear },
        { name: 'dueDate', value: Compliance.FilingPropertyEnum.DueDate }
    ];

    protected canGetRows(): boolean {
        return !!this._dataSourceParams.filingBatchId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.FilingSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            filingIdsToExclude: this._dataSourceParams.selectedIds
        };

        const result = await lastValueFrom(this._filingBatchRepository.getListByBatchAvailable(this._dataSourceParams.filingBatchId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Compliance.FilingSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            filingIdsToExclude: this._dataSourceParams.selectedIds
        };

        return await lastValueFrom(this._filingBatchRepository.getRowIdsByBatchAvailable(this._dataSourceParams.filingBatchId, searchParams));
    }

    destroy?(): void { }
}
