import { Component, OnInit } from '@angular/core';
import { UserInstanceService } from '../../../User/userInstance.service';
import { TypeaheadCompany } from '../../Company/Company.Picker.Component';
import { InstanceRepository } from '../instance.repository';
import { AccountService } from '../../../User/account.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from '../../../Layout/navigation.service';
import { lastValueFrom } from 'rxjs';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { TeamService } from 'src/app/Team/team.service';

@Component({
    selector: 'instance-info-panel',
    templateUrl: './instanceInfoPanel.component.html',
    styleUrls: ['./instanceInfoPanel.component.scss'],
}) export class InstanceInfoPanelComponent implements OnInit {

    constructor(
        private readonly _instanceRepo: InstanceRepository,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _accountService: AccountService,
        private readonly _navigationService: NavigationService,
        private toastr: ToastrService,
        private readonly _teamService: TeamService,
        private readonly _messageBoxService: MessageBoxService
    ) {

    }

    isEditSettings = false;
    isEditDocumentIntakeAndProcessing: boolean = false;
    showSpinnerSettings = false;
    showSpinnerDocumentIntakeAndProcessing: boolean = false;
    instanceId: number;
    instanceModel: Core.InstanceDTO = {} as Core.InstanceDTO;
    initialInstanceModel: Core.InstanceDTO;
    companyModel: TypeaheadCompany = null;
    users: Core.UserTeamModel[] = [];
    selectedUser: Core.UserTeamModel;
    placeholder: string = 'Search User...';
    showDocumentIntakeAndProcessingPanel: boolean = false;

    async ngOnInit() {
        this.instanceId = this._userInstanceService.getSelectedInstance().instanceId;
        await this.loadData();
    }

    async loadData() {
        this.showSpinnerSettings = true;
        this.showSpinnerDocumentIntakeAndProcessing = true;

        try {
            const responses = await Promise.all([
                lastValueFrom(this._instanceRepo.get(this.instanceId)),
                this._teamService.getAllAssignableUsers(false, false, undefined, undefined, undefined)
            ]);

            this.instanceModel = responses[0];
            this.users = responses[1];

            if(!this.instanceModel) {
                return;
            }

            this.showDocumentIntakeAndProcessingPanel = this.instanceModel.instanceId != this._userInstanceService.SystemInstanceId &&
                this.instanceModel.instanceId != this._userInstanceService.RyanInstanceId;

            this.companyModel = {
                companyID: this.instanceModel.customerCompanyId,
                companyName: this.instanceModel.customerCompanyName,
                isFavorite: false,
                instanceIdFilter: null
            };

            if (this.showDocumentIntakeAndProcessingPanel && this.instanceModel.documentIntakeAndProcessingAssignToRyan) {
                this.selectedUser = this.users.find(x => x.userID == this.instanceModel.documentIntakeAndProcessingClientUserId);
            }
        }
        finally {
            this.showSpinnerSettings = false;
            this.showSpinnerDocumentIntakeAndProcessing = false;
        }
    }

    setEditSettings() {
        this.isEditSettings = true;
        this._setEdit();
    }

    setEditDocumentIntakeAndProcessing() {
        this.isEditDocumentIntakeAndProcessing = true;
        this._setEdit();
    }

    async saveSettings() {
        this.showSpinnerSettings = true;

        const hasError = await this._save(true);

        if (!hasError) {
            this.isEditSettings = false;

            //name changed - reload window
            if(this.initialInstanceModel.instanceName !== this.instanceModel.instanceName) {
                window.location.reload();
            }
        }
    }

    cancelSettings() {
        this.isEditSettings = false;
        this._cancel();
    }

    async saveDocumentIntakeAndProcessing() {
        this.showSpinnerDocumentIntakeAndProcessing = true;

        const hasError = await this._save(false);

        if (!hasError) {
            this.isEditDocumentIntakeAndProcessing = false;
        }
    }

    get canSaveDocumentIntakeAndProcessingSettings(): boolean {
        return this.instanceModel.documentIntakeAndProcessingAssignToRyan && !!this.instanceModel.documentIntakeAndProcessingClientUserId ||
            !this.instanceModel.documentIntakeAndProcessingAssignToRyan && !this.instanceModel.documentIntakeAndProcessingClientUserId && !this.instanceModel.documentIntakeAndProcessingClientSpecialInstructions;
    }

    cancelDocumentIntakeAndProcessing() {
        this.isEditDocumentIntakeAndProcessing = false;
        this._cancel();
    }

    companySelected(company:TypeaheadCompany) {
        this.instanceModel.customerCompanyId = company.companyID;
        this.instanceModel.customerCompanyName = company.companyName;
    }

    userChosen(user: Core.UserTeamModel): void {
        this.instanceModel.documentIntakeAndProcessingClientUserId = user ? user.userID : null;
        this.instanceModel.documentIntakeAndProcessingClientTeamId = user ? user.teamID : null;
    }

    get selectedUserName(): string {
        return this.selectedUser ? `${this.selectedUser.firstName} ${this.selectedUser.lastName}` : null;
    }

    private async _save(resetRoles: boolean): Promise<boolean> {
        let hasError: boolean = false;

        try {
            await lastValueFrom(this._instanceRepo.update(this.instanceModel));

            if (resetRoles) {
                await this._accountService.resetRoles();
            }

            await this.loadData();

            this._navigationService.disableNavWarning();
        }
        catch(e){
            this.toastr.error('Error', e.error);
            hasError = true;
        }
        finally {
            this.showSpinnerSettings = false;
            this.showSpinnerDocumentIntakeAndProcessing = false;
        }

        return hasError;
    }

    private _setEdit() {
        this.initialInstanceModel = {...this.instanceModel};
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    private _cancel() {
        this.instanceModel = this.initialInstanceModel;
        this._navigationService.disableNavWarning();

        if (this.instanceModel.documentIntakeAndProcessingAssignToRyan) {
            this.selectedUser = this.users.find(x => x.userID == this.instanceModel.documentIntakeAndProcessingClientUserId);
        }
    }

    async assignToRyanChanged($event: any): Promise<void> {
        if (!$event.target.checked && this.initialInstanceModel.documentIntakeAndProcessingAssignToRyan) {
            const answer = await this._messageBoxService.open(
                {
                    message: `Are you sure? By unchecking this selection you are indicating that you wish to discontinue Ryan's document intake and processing services. Ryan will no longer perform these services on behalf of ${this.instanceModel.customerCompanyName}.`,
                    buttons: MessageBoxButtons.YesNo});
            if (answer === MessageBoxResult.Yes) {
                this.instanceModel.documentIntakeAndProcessingAssignToRyan = false;
                this.instanceModel.documentIntakeAndProcessingClientUserId = null;
                this.instanceModel.documentIntakeAndProcessingClientTeamId = null;
                this.instanceModel.documentIntakeAndProcessingClientSpecialInstructions = null;
                this.selectedUser = null;
            } else {
                $event.target.checked = true;
            }
        } else {
            this.instanceModel.documentIntakeAndProcessingAssignToRyan = $event.target.checked;
        }
    }
}
