import {HelpContent} from '../../../UI-Lib/Help-Tooltip';

export const IMPORT_PROCESS_HELP:HelpContent[] = [
    {
        helpContentId: 'import-process.next-step-validate',
        tooltipText: 'Validate imported data.'
    },
    {
        helpContentId: 'import-process.next-step-validate.disabled',
        tooltipText: 'Not available until all required fields have been mapped.'
    },
    {
        helpContentId: 'import-process.next-step-evaluate',
        tooltipText: 'Evaluates if each selected row will be created, updated or unchanged when transferred to the database.'
    },
    {
        helpContentId: 'import-process.next-step-evaluate.disabled.no-selection',
        tooltipText: 'Select rows to evaluate for transfer.'
    },
    {
        helpContentId: 'import-process.next-step-evaluate.disabled.no-nochanges',
        tooltipText: 'Selection does not have any insert/update rows.'
    },
    {
        helpContentId: 'import-process.next-step-evaluate.disabled.invalid-selection',
        tooltipText: 'Select valid rows to evaluate for transfer.'
    },
    {
        helpContentId: 'import-process.next-step-transfer',
        tooltipText: 'Transfer evaluated rows to the database.'
    },
    {
        helpContentId: 'import-process.close',
        tooltipText: 'Close data import.'
    },
    {
        helpContentId: 'import-process.back.edit',
        tooltipText: 'Go back to field mapping.'
    },
    {
        helpContentId: 'import-process.back',
        tooltipText: 'Close data import.'
    },
    {
        helpContentId: 'import-process.mark-completed',
        tooltipText: 'Mark data import as completed.'
    },
    {
        helpContentId: 'import-process.mark-completed-disabled',
        tooltipText: 'Data import cannot be marked as completed if already completed or no rows have been transferred.'
    },
    {
        helpContentId: 'import-process.revalidate',
        tooltipText: 'Revalidate selected rows or the whole file if no rows selected.'
    },
    {
        helpContentId: 'import-process.delete',
        tooltipText: 'Delete data import.'
    },
    {
        helpContentId: 'import-process.delete-disabled',
        tooltipText: 'Data import cannot be deleted if already completed or at least one row has been transferred.'
    },
    {
        helpContentId: 'import-process.has-not-validated-updates',
        tooltipText: 'Pending updates detected. Import must be revalidated.'
    }
];
