import { Injectable} from '@angular/core';
import { ReturnPartServiceBase } from '../../Models/returnPartServiceBase';

export interface ReturnSettingsServiceSharedState { }

@Injectable()
export class ReturnSettingsService extends ReturnPartServiceBase {
    constructor () { super(); }

    private _sharedState: ReturnSettingsServiceSharedState = { };

    get sharedState(): ReturnSettingsServiceSharedState { return this._sharedState; }
}
