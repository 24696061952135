<div class="d-flex flex-column">
    <div class="d-flex flex-column">
        <h4 *ngIf="slides[currentSlide].title">{{ slides[currentSlide].title }}</h4>
        <div class="d-flex align-items-center justify-content-between">
            <ws-ace-icon glyph="chevron-left"
                         size="2"
                         class="clickable pe-3"
                         (click)="prevSlide()">
            </ws-ace-icon>
            <div class="flex-grow-1">
                <img [src]="slides[currentSlide].imageUrl"
                     alt="slide"
                     class="carousel-image w-100">
            </div>
            <ws-ace-icon glyph="chevron-right"
                         size="2"
                         class="clickable ps-3"
                         (click)="nextSlide()">
            </ws-ace-icon>
        </div>
        <p *ngIf="slides[currentSlide].description">{{ slides[currentSlide].description }}</p>
    </div>
    <div class="d-flex justify-content-center w-100 py-3">
        <div class="slide-progress clickable mx-1"
                *ngFor="let slide of slides; let i = index"
                [ngClass]="{'active': i === currentSlide}"
                (click)="goToSlide(i)">
        </div>
    </div>
    <div *ngIf="!disableControls">
        <ws-ace-button (click)="prevSlide()"
                       content="Previous"
                       buttonStyle="secondary"
                       state="action">
            Prev
        </ws-ace-button>
        <ws-ace-button (click)="nextSlide()"
                       content="Previous"
                       buttonStyle="primary"
                       state="action">
            Next
        </ws-ace-button>
    </div>
</div>
