<div class="input-group" aria-label="Select Site">
    <input name="sites"
           type="text"
           placeholder="Filter Descriptors..."
           class="form-control"
           autocomplete="off"
           container="body"
           [(ngModel)]="descriptorFilter"
           (blur)="onBlur()"
           [typeahead]="descriptors$"
           (typeaheadNoResults)="onNoResults($event)"
           typeaheadOptionField="name"
           [typeaheadWaitMs]="750"
           (typeaheadLoading)="onLoadingChange($event)"
           [typeaheadMinLength]="0"
           [typeaheadOptionsLimit]="100"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)"
           [disabled]="disabled"/>
</div>
