<div class="ace-con ace-con-inline d-flex flex-column w-100 bg-white pb-3">
    <div class="blade-con d-flex flex-column pb-1">
        <div class="blade-header d-flex justify-content-between ps-4">
            <div class="d-flex align-items-center">
                <h1 class="d-inline-block mb-0 me-3">Diagnostic</h1>
                <ws-ace-select [formControl]="diagnosticControl"
                               [options]="diagnosticOptions"
                               valueProperty="route"
                               labelProperty="name"
                               placeholder="Make a selection...">
                </ws-ace-select>
            </div>
        </div>
    </div>
</div>
<div class="blade-con d-flex flex-column flex-grow-1 bg-white pb-1">
    <div class="blade-body d-flex flex-grow-1 h-100 px-4 pb-2">
        <ui-view class="ui-view-layout d-flex flex-grow-1 w-100 px-4 py-2"></ui-view>
    </div>
</div>
