<div style="height: 100%; overflow: auto;">
    <h3 *ngIf="originalRequest" class="task-widget-title">
        My <span *ngIf="originalRequest.ShowChoice == 3">Team's </span>Tasks Due Within 45 Days
    </h3>
    <p *ngIf="taskSeriesTypeList && taskSeriesTypeList.length === 0 && !actionViewTimeout">
        No current tasks
    </p>
    <p class="text-center lead" style="margin-top:20px;" *ngIf="actionViewTimeout">
        Your data is taking some time to load. To access your Action Views, <span class="anchor-style" (click)="goToActionView()">click here</span>.
    </p>
    <div class="action-view-overview">
        <table *ngIf="taskSeriesTypeList && taskSeriesTypeList.length !== 0 && !actionViewTimeout" class="task-table">
            <tr>
                <th></th>
                <th *ngFor="let headerItem of taskSeriesTypeList[0].data">{{headerItem.label}}</th>
            </tr>
            <tr *ngFor="let taskSeriesTypeItem of taskSeriesTypeList">
                <td class="task-series-row-header">{{ taskSeriesTypeItem.taskSeriesTypeName }}</td>
                <td *ngFor="let taskCountItem of taskSeriesTypeItem.data" [className]="taskCountItem.colClass">
                    <span class="wide-value-badge">
                        <span (click)="drilldownCell(taskCountItem, 'ready', taskSeriesTypeItem)" *ngIf="taskCountItem.ready > 0" >{{taskCountItem.ready}}</span>  <span (click)="drilldownCell(taskCountItem, 'not ready', taskSeriesTypeItem)" *ngIf="taskCountItem.notReady > 0">({{taskCountItem.notReady}})</span>
                    </span>
                </td>
            </tr>
        </table>
    </div>
</div>