import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { DeadlineService } from '../deadline.service';
import { AppealDeadlineModel } from '../Panel/deadlineListPanel.component';
import * as _ from 'lodash';

export interface AssessorExceptionsParams {
    deadline: AppealDeadlineModel
    stateId: number;
    selectedYear: number | string;
}

export interface AssessorException {
    abbr: string;
    assessorID: number;
    deadlineDate: string;
    deadlineMonthDay: string;
    deadlineType: string;
    deadlineDateDisplay: string;
    earliestPossible: boolean;
    municipalityName: string;
    name: string;
    propTypeName: string;
}

@Component({
    selector: 'assessor-exceptions-modal',
    template: `
        <ws-modal-wrapper [headerLabel]="headerLabel" cancelLabel="Close" [hideSave]="true" (cancel)="close()">
        <loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
            <table class="table table-striped" style="padding: 10px 0px;" *ngIf="exceptions">
                <thead>
                    <tr>
                        <th class="col-5" style="padding-left: 10px;">Assessor Abbr</th>
                        <th class="col-5" style="padding-left: 20px;">Deadline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let exception of exceptions">
                        <td class="col-5">
                            <a anchor-route [sref]="getAssessorSref(exception)">{{exception.abbr + ' ' + exception.municipalityName}}</a>
                        </td>
                        <td class="col-5">
                            <span>{{exception.deadlineDateDisplay | date:'M/d/yyyy':'utc' }}</span> <span *ngIf="exception.earliestPossible">^</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="isEarliestPossibleDeadline">^Not available. Projected as earliest possible date.</div>
        </ws-modal-wrapper>
    `,
})
export class AssessorExceptionsModalComponent implements OnInit, IWeissmanModalComponent<AssessorExceptionsParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _deadlineService: DeadlineService
    ) { }

    params: AssessorExceptionsParams;
    result: void;

    loading: boolean = false;
    exceptions: AssessorException[];
    propertyType: string;
    deadlineType: string;
    isEarliestPossibleDeadline: boolean = false;
    headerLabel: string;

    async ngOnInit() {
        this.loading = true;

        try {
            const exceptions = await this._deadlineService.getAssessorExceptions(this.params.deadline, this.params.stateId, this.params.selectedYear);

            this.exceptions = _.chain(exceptions)
                .reject(x => {
                    return _.some(exceptions, y => {
                        return (x != y) // we don't compare exception to itself
                            && (x.assessorID === y.assessorID) // we have more than one expetion for the same assessor
                            && (y.deadlineDate != null);
                    });
                })
                .map(exception => {
                    exception.deadlineDateDisplay = exception.deadlineDate
                        ? new Date(exception.deadlineDate)
                        : exception.deadlineMonthDay
                            ?  (`${exception.deadlineMonthDay  }/${  this.params.selectedYear}`)
                            : 'Unknown Date';

                    return exception;
                })
                .sortBy('name')
                .value();


            if(this.exceptions.length) {
                this.propertyType = this.exceptions[0].propTypeName;
                this.deadlineType = this.exceptions[0].deadlineType;
            }

            this.isEarliestPossibleDeadline = _.some(this.exceptions, 'earliestPossible');
            this.headerLabel = `Assessor Exceptions: ${this.params.selectedYear} ${this.propertyType} ${this.deadlineType} deadlines`;

        } finally {
            this.loading = false;
        }
    }

    getAssessorSref(exception: AssessorException) {
        return {
            target: 'assessor',
            options: {
                stateId: this.params.stateId,
                id: exception.assessorID,
                year: this.params.selectedYear
            }
        };
    }

    close() {
        this._bsModalRef.hide();
    }
}
