import { Component, Input, ViewChild } from '@angular/core';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { ReturnSettingsEntityTypeEnum } from '../../Return/Models/enums';
import { ReturnSettingsComponent } from '../../Return/Return-Parts/Settings/Return-Settings/returnSettings.component';

@Component({
    selector: 'compliance-company-return-settings-panel',
    templateUrl: './companyReturnSettingsPanel.component.html'
})
export class CompanyReturnSettingsPanelComponent {

    @Input() companyId: number;
    @Input() allowEdit: boolean;

    @ViewChild(ReturnSettingsComponent) returnSettingsComponent: ReturnSettingsComponent;

    isShowInfo: boolean = false;
    editMode: boolean = false;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    hasChanges: boolean;
    isLoading: boolean;

    expandOrCollapse(): boolean {
        if(this.editMode) {
            return true;
        }
        
        this.isShowInfo = !this.isShowInfo;

        if(this.isShowInfo) {
            this.showInfo();
        }
        return false;
    }

    showInfo(): void {
        this.isLoading = true;
    }

    async save(): Promise<void> {
        try{
            if (this.returnSettingsComponent){
                this.busyIndicatorMessageManager.add('Saving', 'saving');
                await this.returnSettingsComponent.save();
                this.busyIndicatorMessageManager.remove('saving');
            }
            this.editMode = false;
        }
        finally{
            this.busyIndicatorMessageManager.remove('saving');
        }
    }

    async setEditMode(): Promise<void> {
        this.editMode = true;
        this.returnSettingsComponent.edit();
    }

    async cancelEdit(): Promise<void> {
        this.editMode = false;
        this.returnSettingsComponent.cancel();
    }

    onReturnSettingsInitialized() {
        this.isLoading = false;
    }

    onReturnSettingsChanged(value: boolean){
        this.hasChanges = value;
    }
}
