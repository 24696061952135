<div class="return-asset-form-filters">
    <return-asset-form-status-selector></return-asset-form-status-selector>
    <return-form-selector [allowPrimaryForReturn]="true" [returnPartService]="returnPartServiceForInterPartComponent" ></return-form-selector>
    <return-asset-form-factor-selector></return-asset-form-factor-selector>
</div>

<div class="ws-flex-auto ws-flex-container-vertical ws-overflow-auto mb-3 schedules-form-group">
    <div class="ws-flex-none">
        <strong>Schedules</strong>
        <button type="button"
                class="btn btn-link btn-xs float-end"
                title="Clear all schedule and factor filters"
                *ngIf="scheduleFilterEnabled && hasFilters()"
                (click)="clearScheduleAndFactorFilter()">
            Clear schedule filters
        </button>
    </div>
    <div class="ws-flex-none ws-flex-container-horizontal form-filters">
        <div class="ws-flex-none checkbox form-filter" [ngClass]="{ 'disabled': !hasSchedules }">
            <label for="hideEmptySchedulesFilter">
                <input type="checkbox"
                       id="hideEmptySchedulesFilter"
                       [(ngModel)]="hideEmptySchedulesFilter"
                       (ngModelChange)="hideEmptySchedulesFilterChange()"
                       [disabled]="!hasSchedules"> Hide empty schedules
            </label>
        </div>
        <div class="ws-flex-none checkbox form-filter"
             [ngClass]="{ 'disabled': (!hasSchedules) || separateFactorTablesFilterDisabled }"
             [title]="separateFactorTablesFilterDisabled ? 'Factor tables can only be separated if using the state-provided factors or those for a specific assessor' : ''">
            <label for="separateFactorTablesFilter">
                <input type="checkbox"
                       id="separateFactorTablesFilter"
                       [disabled]="(!hasSchedules) || separateFactorTablesFilterDisabled"
                       [(ngModel)]="separateFactorTablesFilter"
                       (ngModelChange)="separateFactorTablesFilterChange()"> Separate factor tables
            </label>
        </div>
    </div>
    <div class="ws-flex-container-vertical ws-flex-auto ws-overflow-auto form-schedules">
        <return-asset-drag-and-drop-target *ngFor="let aggs of aggregatedSchedules"
                                           [scheduleName]="aggs.schedule.name"
                                           [scheduleDescription]="aggs.schedule.description"
                                           [depreciationTableLife]="aggs.schedule.depreciationTableLife"
                                           (assetsDropped)="onAssetsDropped($event, aggs.schedule)">
            <return-asset-form-schedule title="{{ aggs.schedule.name }} {{ aggs.schedule.description ? '(' + aggs.schedule.description + ')' : '' }} - Click to filter and show only assets associated with this schedule"
                                        [canExpand]="true"
                                        [schedule]="aggs.schedule"
                                        [scheduleDetails]="aggs.scheduleDetails"
                                        [scheduleForUnassignedAssets]="false"
                                        [scheduleForNonReportableAssets]="false"
                                        [scheduleForReportableAssets]="false"
                                        [scheduleAndFactorFilter]="scheduleAndFactorFilter"
                                        (scheduleClick)="onScheduleAndFactorFilterClick($event)"
                                        (scheduleAgeClick)="onScheduleAgeFilterClick($event)">
            </return-asset-form-schedule>
        </return-asset-drag-and-drop-target>
    </div>
</div>

<div class="ws-flex-none mb-3" *ngIf="reportableSchedule && scheduleFilterEnabled" [ngClass]="{ 'mb-3': nonReportableSchedule || unassignedSchedule?.assetCount }">
    <div>
        <strong>Reported</strong>
    </div>
    <return-asset-form-schedule title="Click to filter and show only assets that are reportable"
                                [canExpand]="false"
                                [schedule]="reportableSchedule"
                                [scheduleForUnassignedAssets]="false"
                                [scheduleForNonReportableAssets]="false"
                                [scheduleForReportableAssets]="true"
                                [scheduleAndFactorFilter]="scheduleAndFactorFilter"
                                (scheduleClick)="onScheduleAndFactorFilterClick($event)">
    </return-asset-form-schedule>
</div>

<!-- if "Not Assigned to a Schedule" group is available then add the "mb-3" class to this container -->
<div class="ws-flex-none" *ngIf="nonReportableSchedule && scheduleFilterEnabled" [ngClass]="{ 'mb-3': unassignedSchedule?.assetCount }">
    <div>
        <strong>Not Reported</strong>
    </div>
    <return-asset-drag-and-drop-target scheduleName="NR"
                                       scheduleDescription="Non-Reportable"
                                       (assetsDropped)="onAssetsDropped($event, nonReportableSchedule)">
        <return-asset-form-schedule title="Click to filter and show only assets that are non-reportable"
                                    [canExpand]="false"
                                    [schedule]="nonReportableSchedule"
                                    [scheduleForUnassignedAssets]="false"
                                    [scheduleForNonReportableAssets]="true"
                                    [scheduleForReportableAssets]="false"
                                    [scheduleAndFactorFilter]="scheduleAndFactorFilter"
                                    (scheduleClick)="onScheduleAndFactorFilterClick($event)">
        </return-asset-form-schedule>
    </return-asset-drag-and-drop-target>
</div>

<div class="ws-flex-none" *ngIf="unassignedSchedule?.assetCount && scheduleFilterEnabled">
    <div>
        <strong>Not Assigned to a Schedule</strong>
    </div>
    <return-asset-form-schedule title="Show assets that are not assigned to a schedule"
                                [canExpand]="false"
                                [schedule]="unassignedSchedule"
                                [scheduleForUnassignedAssets]="true"
                                [scheduleForNonReportableAssets]="false"
                                [scheduleForReportableAssets]="false"
                                [scheduleAndFactorFilter]="scheduleAndFactorFilter"
                                (scheduleClick)="onScheduleAndFactorFilterClick($event)">
    </return-asset-form-schedule>
</div>
