<table class="table-condensed column-filters" style="margin-bottom: 0;">
    <tr *ngFor="let filter of columnFilters; trackBy: columnFiltersTrackBy"  [ngStyle]="{display: hideRow(filter)}">
        <td class="filter-name">{{filter.resultColumnName || filter.resultsColumnName || filter.displayName}}</td>
        <td class="filter-ors">
            <table class="table-condensed">
                <tr *ngFor="let or of filter.or; let index = index">
                    <td class="or-or" *ngIf="showOrs"><span [ngClass]="{'invisible': index === 0}">OR</span></td>
                    <td class="or-operator">
                        <ws-select [(ngModel)]="or.operator"
                                   (selectedOptionChanged)="changeOperator(filter, or, or.operator?.name)"
                                   [options]="filter.operators | sortBy:'asc':'sortOrder'"
                                   labelProperty="displayName"
                                   [returnEntireOption]="true">
                        </ws-select>
                    </td>
                    <td class="or-input" [ngClass]="maybeHideValue(or.operator?.name)" [ngSwitch]="selectorType(filter, or.operator?.name)">
                        <input *ngSwitchCase="'input'"
                               type="text"
                               style="transition: initial;"
                               class="form-control"
                               [ngModel]="or.value"
                               (ngModelChange)="updateOrValue($event, or)"
                               maxlength="100">
                        <weissman-datetime *ngSwitchCase="'date'"
                                           [required]="false"
                                           [dateOnly]="true"
                                           [fillWidth]="true"
                                           [inputDate]="or.value || null"
                                           (inputDateChange)="updateOrValue($event, or)">
                        </weissman-datetime>
                        <span *ngSwitchCase="'withinDate'">
                            <select [ngModel]="or.value" (ngModelChange)="updateOrValue($event, or)" class="form-select form-select-sm">
                                <option></option>
                                <option value="30">30 Days</option>
                                <option value="60">60 Days</option>
                                <option value="90">90 Days</option>
                            </select>
                        </span>
                        <select *ngSwitchCase="'yesNo'" [ngModel]="or.value" (ngModelChange)="updateOrValue($event, or)" class="form-select form-select-sm">
                            <option value=""></option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <ws-company-picker *ngSwitchCase="'company'"
                                           [companyName]="or.value"
                                           (companyNameChange)="updateOrValue($event, or)"
                                           [appendToBody]="true"
                                           [topLevelOnly]="(filter.columnId || filter.id || filter.advancedSearchFieldID) === COMPANY_NAME_TOP_LEVEL_ID"
                                           [activeOnly]="activeOnly"
                                           [favoritesOnly]="onlyFrequentlyUsedCompanies">
                        </ws-company-picker>
                    </td>
                    <td class="or-delete" *ngIf="!hideDelete || index > 0">
                        <button type="button" class="flat-button small-button warning-button" (click)="removeOr(filter, or)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </table>
        </td>
        <td class="filter-or-button" *ngIf="showOrs">
            <button type="button" class="flat-button small-button success-button ws-no-margin" style="white-space: nowrap;" (click)="addOr(filter)">+ OR</button>
        </td>
    </tr>
</table>

