import { Component, OnDestroy } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WeissmanDateFormatPipe } from '../../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { ReturnAssetDisposalsListAgGridDataSource, ReturnAssetDisposalsListDataSourceParams } from './agGridDataSource';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../AgGrid';
import { ReturnAssetsService } from '../returnAssets.service';
import { ReturnAssetChangeIconCellRendererComponent } from '../Asset-List/agGridChangeIconCellRenderer.component';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../../AgGrid/MultiSelectTracker';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ReturnService } from '../../../return.service';
import {
    ReturnAssetBulkUpdateComponent,
    ReturnAssetBulkUpdateParams
} from '../Return-Asset-Bulk-Update/returnAssetBulkUpdate.component';
import { IWeissmanModalComponent, WeissmanModalService } from '../../../../WeissmanModalService';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../../AgGrid/ToolPanel/models';
import { AssetDetailsComponent, AssetDetailsParams } from '../../../../Asset/Asset-Details/assetDetails.component';
import {
    ICellRendererParamsForReturnAssetDisposalsListGridAction,
    ReturnAssetDisposalsListGridActionCellRendererComponent
} from './agGridActionCellRenderer.component';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../../../Busy-Indicator';
import { ReturnAssetRepository } from '../../../../Repositories';
import {
    AgGridCheckboxCellRendererComponent,
    ICellRendererParamsForAgGridCheckbox
} from '../../../../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from '../../../../../UI-Lib/Utilities/timer.service';

import * as _ from 'lodash';

export interface ReturnAssetDisposalsListParams {
    title?: string;
    priorReturnStatuses: Compliance.ReturnAssetPriorReturnStatusEnum[];
    formRevisionScheduleId: number;
    targetNonReportableSchedule: boolean;
    targetReportableSchedule: boolean;
    isDisposalOrTransferOut: boolean;
    scheduleGroupType?: Compliance.ScheduleGroupTypeEnum;
}

@Component({
    selector: 'return-asset-disposals-list',
    templateUrl: './returnAssetDisposalsList.component.html'
})
export class ReturnAssetDisposalsListComponent implements OnDestroy, IWeissmanModalComponent<ReturnAssetDisposalsListParams, boolean> {
    constructor(
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _bsModalRef: BsModalRef,
        private readonly _modalService: WeissmanModalService,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _toastr: ToastrService,
        private readonly _timer: TimerService
    ) { }

    private _gridApi: GridApi;
    private _gridDataSource: ReturnAssetDisposalsListAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _assetDetailsUpdatedSub: Subscription;
    private _refreshNeeded: boolean = false;
    private _updateOccurred: boolean;

    params: ReturnAssetDisposalsListParams;
    result: boolean;

    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    gridTotalsRow: any;
    gridId: System.Guid = '47998435-E1D5-4087-979B-F6A0F8DF93E1';
    gridTracker: AgGridMultiSelectTracker;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get title(): string {
        return this.params.title || 'Return Disposals';
    }

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.ReportingAssetModel).reportingAssetId),
            'totals-row': (params) => params.data && params.data.totalsRow
        }
    }).withContext(this)
      .withInfiniteScroll()
      .withLoadingOverlay()
      .withColumnResize()
      .withMultipleColumnSort()
      .withTextSelection()
      .withColumnPinning()
      .build();

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();
            searchParams.pagination = {
                skip: 0,
                take: this._gridDataSource.totalRows
            };

            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();

            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0) {
                searchParams.selectedRows.selectAllRows = true;
            }

            const exportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn
            } as Compliance.ReturnAssetExportModel;

            const longRunningProcessId = await this._returnAssetsService.exportAssets(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: Compliance.LongRunningProcessTypeEnum.ExportReturnAssets };
        },
        canCancel: true
    };

    ngOnDestroy() {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
    }

    cancel(): void {
        this.result = this._updateOccurred;
        this._bsModalRef.hide();
    }

    createMultiSelectSubs(): void {
        // notify the bulk update button every time the grid selection changes (if return not in read-only mode)
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(() => {
            const isClassificationUpdateButtonVisible = !this._returnService.isReturnInReadOnlyMode && this._returnService.canEditCompany && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isClassificationUpdateButtonVisible);
        });
    }

    private async _viewAssetDetails(params: ICellRendererParamsForReturnAssetDisposalsListGridAction): Promise<void> {
        const asset = params.data as Compliance.ReturnAssetModel;
        if (!asset) {
            return Promise.resolve();
        }

        const inputParams: AssetDetailsParams = {
            reportingAssetId: asset.reportingAssetId,
            assetId: asset.assetId,
            lienDate: this._returnService.lienDate,
            canEdit: this._returnService.canEditCompany && !this._returnService.isReturnInReadOnly,
            createNewAsset: false,
            topLevelCompanyId: this._returnService.companyId,
            assetDescriptors: this._returnService.companyAssetDescriptorMappings,
            newAssetCompanyId: null,
            canViewRyanPrivateItems: this._returnService.canViewRyanPrivateItems
        };

        const result = await this._modalService.showAsync(AssetDetailsComponent, inputParams, 'modal-xl');

        if (!(result && result.hasChanges)) {
            return Promise.resolve();
        }

        const initialIds = result.initialReportingAssetIds;
        const savedReportingAssetIds = result.savedModels.map((model: Compliance.AssetModel) => model.reportingAsset.reportingAssetId);
        const reportingAssetIds = Array.from(new Set(initialIds.concat(savedReportingAssetIds)));

        this.busyMessageManager.add('Working on it', 'loading');

        try {
            await this._returnAssetsService.notifyAssetDetailsUpdated(reportingAssetIds);
        } finally {
            this.busyMessageManager.remove('loading');
        }

        this._refreshDataSource();

        this._refreshNeeded = true;
        this._updateOccurred = true;

        return Promise.resolve();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.createMultiSelectSubs();

        this._assetDetailsUpdatedSub = this._returnAssetsService.assetDetailsUpdated$.subscribe(() => {
            if (this._refreshNeeded) {
                this._refreshDataSource();
                this._refreshNeeded = false;
            }
        });

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'reportingAssetId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'Asset Number',
                field: 'disposalAssetNumber',
                lockVisible: true,
                lockPosition: true,
                lockPinned: true,
                pinned: 'left',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Status',
                field: 'assetReturnStatus',
                sortable: false,
                width: AgGridColumns.textColumnExtraSmallWidth,
                minWidth: AgGridColumns.textColumnExtraSmallWidth,
                cellRendererFramework: ReturnAssetChangeIconCellRendererComponent
            },
            {
                headerName: 'Description',
                field: 'disposalDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Classification',
                field: 'disposalClassification',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Parcel Account',
                field: 'parcelAccountNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorAbbr',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Prior Site',
                field: 'priorSiteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Prior Acq. Date',
                field: 'priorAcqDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                cellClass: 'grid-cell-readonly',
                hide: true
            },
            {
                headerName: 'Acq. Date',
                field: 'acqDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                hide: true
            },
            {
                headerName: 'Disp. Date',
                field: 'disposedDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                cellClass: x => {
                    const data = x.data as Compliance.ReturnAssetModel;
                    return (data.disposedDate && !data.sourceDisposedDate)
                        || (data.sourceDisposedDate && data.disposedDate && data.sourceDisposedDate !== data.disposedDate)
                    ? 'overridden' : '';
                },
                hide: true
            },
            {
                headerName: 'Prior Cost',
                field: 'priorCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                suppressMenu: true,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
            },
            {
                headerName: 'Cost',
                field: 'cost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                suppressMenu: true,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
            },
            {
                headerName: 'Cost Change',
                field: 'costChange',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                suppressMenu: true,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
            },
            {
                headerName: 'Prior Schedule',
                field: 'priorScheduleName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Schedule',
                field: 'scheduleName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Form',
                field: 'form',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Prior Factor Table',
                field: 'priorDepreciationFactorTableName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.priorDepreciationFactorTableName) ? `${rowData.priorDepreciationFactorTableName} (${rowData.priorDepreciationFactorTableLife} year life)` : '';
                }
            },
            {
                headerName: 'Factor Table',
                field: 'depreciationFactorTableName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.depreciationFactorTableName) ? `${rowData.depreciationFactorTableName} (${rowData.depreciationFactorTableLife} year life)` : '';
                },
                hide: true
            },
            {
                headerName: 'Factor',
                field: 'factor',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                hide: true
            },
            {
                headerName: 'Details',
                field: 'calculatedAssetReturnStatus',
                width: AgGridColumns.textColumnLargeWidth,
                sortable: false,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    if (!rowData) {
                        return '';
                    }

                    switch (rowData.assetReturnStatus) {
                        case 'Retired':
                            return `The asset was disposed on ${this._datePipe.transform(rowData.disposedDate)}`;
                        case 'TransferOut':
                            return `The asset was transferred from ${rowData.priorSiteName} to ${rowData.site}`;
                        case 'Changed':
                            const changes: string[] = [];
                            if (rowData.priorAcqDate && rowData.acqDate && rowData.priorAcqDate.getTime() !== rowData.acqDate.getTime()) {
                                changes.push('the acquisition date changed');
                            }

                            if (rowData.priorCost > rowData.cost) {
                                changes.push('the cost was reduced');
                            }

                            if (rowData.priorScheduleName !== rowData.scheduleName) {
                                changes.push('the schedule was changed');
                            }

                            let changeText = '';
                            for (let i = 0; i < changes.length; i++) {
                                if (i > 0) {
                                    changeText += ', ';
                                }

                                if (changes.length - 1 === i && changes.length > 1) {
                                    changeText += 'and ';
                                }

                                changeText += changes[i];
                            }

                            return `This asset has changed since the prior return as ${changeText}`;
                    }

                    return rowData.calculatedAssetReturnStatus;
                }
            }
        ];

        const assetDescriptorColumns = this._returnService.companyAssetDescriptorMappings.map(mapping => {
            let typeSpecificAttributes;
            var cellStyle = '';
            switch (mapping.descriptor.fieldType) {
                case Core.DescriptorFieldTypes.Currency:
                    typeSpecificAttributes = {
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
                    };
                    cellStyle = 'ag-numeric-cell';
                    break;
                case Core.DescriptorFieldTypes.Date:

                    typeSpecificAttributes = {
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: x => {
                            if (!x.value) {
                                return null;
                            }

                            const d = new Date(x.value);
                            return this._datePipe.transform(d, true);
                        }
                    };
                    break;
                case Core.DescriptorFieldTypes.YesNo:
                    typeSpecificAttributes = {
                        width: AgGridColumns.textColumnMedWidth,
                        mindWidth: AgGridColumns.checkboxColumnMinWidth,
                        cellRendererFramework: AgGridCheckboxCellRendererComponent,
                        cellRendererParams: {
                            isVisible: (params: ICellRendererParamsForAgGridCheckbox) => params.value !== null,
                            canEdit: (params: ICellRendererParamsForAgGridCheckbox) => false,
                            canEnterEditMode: () => false,
                            onValueChanged: null
                        } as ICellRendererParamsForAgGridCheckbox,
                        filter: false
                    };
                    break;
                case Core.DescriptorFieldTypes.Number:
                    typeSpecificAttributes = {
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value, '1.2-5')}` : ''
                    };
                    break;
                case Core.DescriptorFieldTypes.Text:
                    typeSpecificAttributes = {
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
                    };
                    break;
            }

            return {
                headerName: mapping.descriptor.name,
                field: _.camelCase(mapping.columnName),
                ...typeSpecificAttributes,
                hide: true,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    if (mapping.descriptor.fieldType === Core.DescriptorFieldTypes.Date) {
                        return (rowData
                                && rowData[_.camelCase(mapping.columnName)] != null
                                && rowData[_.camelCase('source' + mapping.columnName)] != null
                                && rowData[_.camelCase(mapping.columnName)].getTime() === rowData[_.camelCase('source' + mapping.columnName)].getTime()) ? cellStyle : cellStyle + ' overridden';
                    } else {
                        return (rowData && rowData[_.camelCase(mapping.columnName)] === rowData[_.camelCase('source' + mapping.columnName)]) ? cellStyle : cellStyle + ' overridden';
                    }
                }
            };
        });

        const actionColumn = {
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(1),
            minWidth: AgGridColumns.getActionColumnWidth(1),
            maxWidth: AgGridColumns.getActionColumnWidth(1),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            sortable: false,
            pinned: 'right',
            cellRendererFramework: ReturnAssetDisposalsListGridActionCellRendererComponent,
            cellRendererParams: {
                viewDetails: this._viewAssetDetails.bind(this)
            } as ICellRendererParamsForReturnAssetDisposalsListGridAction,
            pinnedRowCellRenderer: () => {return '';}
        };

        const finalColumnDefs = [...columns, ...assetDescriptorColumns, actionColumn];

        this._gridApi.setColumnDefs(finalColumnDefs);

        this._gridApi.setSortModel([{
            colId: 'disposalAssetNumber',
            sort: 'asc'
        }]);

        this._setDataSource();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear(false);

        const dataSourceParams = (): ReturnAssetDisposalsListDataSourceParams => {
            return {
                priorReturnStatuses: this.params.priorReturnStatuses,
                formRevisionScheduleId: this.params.formRevisionScheduleId,
                targetNonReportableSchedule: this.params.targetNonReportableSchedule,
                targetReportableSchedule: this.params.targetReportableSchedule,
                scheduleGroupType: this.params.scheduleGroupType
            };
        };

        this._gridDataSource = new ReturnAssetDisposalsListAgGridDataSource(
            this._gridApi,
            this._returnService,
            this._returnAssetsService,
            this._returnAssetRepository,
            dataSourceParams,
            this.handleTotalsUpdate
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    handleTotalsUpdate = (totals: Compliance.ReturnAssetSearchTotalsModel) => {
        const assetModelRow: any = {
            disposalAssetNumber: 'TOTAL',
            cost: totals.totalCost,
            reportedValue: totals.totalReportedValue,
            priorCost: totals.totalPriorCost,
            costChange: totals.totalCostChange,
            assetNumber: 'TOTAL'
        };

        assetModelRow.totalsRow = true;
        this.gridTotalsRow = assetModelRow;

        const totalRows = [this.gridTotalsRow];

        this._timer.setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    async bulkUpdate(): Promise<void> {
        // form revision selection is required
        if (!this._returnService.sharedState.formRevisionId) {
            this._toastr.warning('Bulk update cannot be used with \'Primary for Return\' selected. Please select a form revision from the return overview settings panel.');
            return;
        }

        const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();
        searchModel.selectedRows = this.gridTracker.getSelectedRowsModel();

        const params: ReturnAssetBulkUpdateParams = {
            filingBatchId: this._returnService.filingBatchId,
            searchModel: searchModel,
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            showPriorScheduleField: this._returnService.filingBatch.getPriorReturnFromAssetList
        };

        const result = await this._modalService.showAsync(ReturnAssetBulkUpdateComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Working on it' });
        try {
            let updateWorkingSetReportingAssetIds: number[] = [];

            if (!searchModel.selectedRows.selectAllRows) {
                updateWorkingSetReportingAssetIds = searchModel.selectedRows.selectedRows;
            }

            await this._returnAssetsService.notifyAssetDetailsUpdated(updateWorkingSetReportingAssetIds);

            this._refreshDataSource();
            this._updateOccurred = true;
        } finally {
            busyRef.hide();
        }
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this.gridTracker.clear(false);
        this._gridDataSource.clearTotals();
        this._gridDataSource.refresh();
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }
}
