export class PortfolioAnalysisWidgetModel {
    totalCompanies: number;
    totalSites: number;
    totalParcels: number;
    taxYears: number[];                             // List of ALL available tax years across applicable Parcels
    annualData: PortfolioAnalysisAnnualData[];     // List of retrieved data for requested years
}

export class PortfolioAnalysisAnnualData {
    year: number;
    totalFMV: number;           // decimal(19,6)
    totalFMVPerSqFt: number;    // decimal(19,6)
    totalFMVPerUnit: number;    // decimal(19,6)
    fmvByPropertyType: PortfolioAnalysisFMVByPropType[];
    fmvForLandTypeComponents: PortfolioAnalysisFMVLand;
    totalTax: number;           // decimal(19,6)
    totalRefund: number;        // decimal(19,6)

    totalSitesForYear: number;
    totalSitesActualAssessments: number;
    totalSitesActualTaxes: number;
    totalSitesProjectedAssessment: number;
    totalSitesProjectedTaxes: number;
    totalSitesUnderAppeal: number;
}

export class PortfolioAnalysisFMVByPropType {
    name: string;
    fmv: number;            // decimal(19,6)
    fmvPerSqFt: number;     // decimal(19,6)
    fmvPerUnit: number;     // decimal(19,6)
}

export class PortfolioAnalysisFMVLand {
    fmv: number;            // decimal(19,6)
    landFMVPerSqFt: number; // decimal(19,6)
    landFMVPerAcre: number; // decimal(19,6)
}