import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../../Comments/comments.service';

export interface ICellRendererParamsForFactorTableListGridAction extends ICellRendererParams {
    canEdit: () => boolean;
    viewDetails: (params: ICellRendererParamsForFactorTableListGridAction) => void;
    delete: (params: ICellRendererParamsForFactorTableListGridAction) => void;
    copy: (params: ICellRendererParamsForFactorTableListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button" class="btn flat-button primary-button icon-button grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>
        <button type="button" *ngIf="canEdit" class="btn flat-button icon-button warning-button grid-action-button" title="Delete" (click)="onDeleteClicked()">
            <i class="fa fa-trash"></i>
        </button>
        <span class="icon-indicator">
            <i class="fa" [ngClass]="{'fa-commenting':factorTableGridItem && factorTableGridItem.hasComments, 'fa-fw': !(factorTableGridItem && factorTableGridItem.hasComments)}"></i>
        </span>
        <span class="icon-indicator">
            <i class="fa" [ngClass]="{'fa-files-o':factorTableGridItem && factorTableGridItem.hasAttachments, 'fa-fw': !(factorTableGridItem && factorTableGridItem.hasAttachments)}"></i>
        </span>
        <entity-comments [(hasComments)]="factorTableGridItem && factorTableGridItem.hasComments"
                            [pinnedComment]="factorTableGridItem && factorTableGridItem.pinnedComment"
                            buttonClass="btn flat-button primary-button icon-button grid-action-button"
                            [modalData]="commentModalData">
        </entity-comments>
        <entity-attachments [(hasAttachments)]="factorTableGridItem && factorTableGridItem.hasAttachments"
                            buttonClass="btn flat-button primary-button icon-button grid-action-button"
                            [modalData]="attachmentModalData">
        </entity-attachments>
        <button type="button" class="btn flat-button primary-button icon-button grid-action-button" title="Copy" (click)="onCopyClicked()">
            <i class="fa-solid fa-clipboard"></i>
        </button>`
})
export class FactorTableListGridActionCellRendererComponent implements ICellRendererAngularComp {
    private _params: ICellRendererParamsForFactorTableListGridAction;
    factorTableGridItem: Compliance.FactorTableListItemModel;
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;
    canEdit: boolean = false;

    agInit(params: ICellRendererParamsForFactorTableListGridAction): void {
        this._params = params;
        this.canEdit = params.canEdit();
        this.factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
        if (!this.factorTableGridItem) {
            return;
        }
        this.attachmentModalData = {
            entityType: 'FactorTable', //todo: make this a constant generated typescript value
            entityName: this.factorTableGridItem.name,
            year: this.factorTableGridItem.taxYear,
            disableYears: false,
            singleYear: false,
            entityData: {
                typeId: Core.EntityTypes.FactorTable,
                id: this.factorTableGridItem.factorTableId,
                name: this.factorTableGridItem.name
            },
            readOnly: !this.canEdit
        } as AttachmentModalData;

        this.commentModalData = {
            entityID: this.factorTableGridItem.factorTableId,
            entityTypeID: Core.EntityTypes.FactorTable,
            parcelAcctNum: null,
            canEdit: this.canEdit,
            description: null,
            parcelID: null,
            year: this.factorTableGridItem.taxYear ? this.factorTableGridItem.taxYear.toString() : null
        } as CommentModalData;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }

    onDeleteClicked(): void {
        this._params.delete(this._params);
    }

    onCopyClicked() {
        this._params.copy(this._params);
    }
}
