import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReturnService } from '../return.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'return-assessor-address-changed-message',
    templateUrl: './returnAssessorAddressChangedMessage.component.html',
    styleUrls: ['./returnAssessorAddressChangedMessage.component.scss'],
})
export class ReturnAssessorAddressChangedMessageComponent implements OnInit, OnDestroy {
    constructor(private readonly _returnService: ReturnService){}

    private _formRevisions: Compliance.ReturnValidateFormResultModel[];
    private _destroy$: Subject<void> = new Subject();

    canShowMessage: boolean;
    showMessageDetails: boolean = false;

    get formRevisions(): Compliance.ReturnValidateFormResultModel[]{
        return this._formRevisions;
    }

    get showWarning(): boolean {
        return this.formRevisions && !!this.formRevisions.length;
    }

    ngOnInit(): void {
        this._updateValidation();

        this._returnService.processStatus$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this._setCanShowMessage(x));

        this._returnService.validateForms$.pipe(takeUntil(this._destroy$))
            .subscribe(x => {
                this._updateValidation();
            });

        this._setCanShowMessage(this._returnService.processStatus);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private async _updateValidation(): Promise<void> {
        const warnings = this._returnService.validateFormsResult?.formWarnings || [];

        this._formRevisions = warnings.filter(x => x.errorType === +Compliance.ReturnValidateFormResultEnum.AssessorAddressChanged);
    }

    private _setCanShowMessage(status: Compliance.FilingBatchProcessStatusEnum) {
        this.canShowMessage = !(status === Compliance.FilingBatchProcessStatusEnum.None
                                || status === Compliance.FilingBatchProcessStatusEnum.NotStarted
                                || status === Compliance.FilingBatchProcessStatusEnum.Started
                                || status === Compliance.FilingBatchProcessStatusEnum.Locking);
    }
}
