import { NgModule } from "@angular/core";
import { DragAndDropDraggableDirective, DragAndDropHandleDirective, DragAndDropListDirective, DragAndDropNoDragDirective } from "./dragAndDropList.directive";
import { DragAndDropListService } from "./dragAndDropList.service";

@NgModule({
    declarations: [
       DragAndDropListDirective,
       DragAndDropDraggableDirective,
       DragAndDropNoDragDirective,
       DragAndDropHandleDirective
    ],
    providers: [ DragAndDropListService ],
    exports: [
        DragAndDropListDirective,
        DragAndDropDraggableDirective,
        DragAndDropNoDragDirective,
        DragAndDropHandleDirective
    ]
})
export class DragAndDropListModule {}
