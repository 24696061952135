import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { HelpTooltipComponent } from './help-tooltip.component';
import { HelpTooltipDirective } from './help-tooltip.directive';

const hybridAdapterUtility = new HybridAdapterUtility();
@NgModule({
    imports: [CommonModule],
    declarations: [
        HelpTooltipComponent,
        HelpTooltipDirective
    ],
    exports: [
        HelpTooltipDirective
    ]
})
export class HelpModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('help-tooltip', HelpTooltipDirective);
    }
}
