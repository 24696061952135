<div class="ag-grid-tool-panel-columns">
    <div class="ws-flex-container-vertical ws-stretch-width">
        <div class="column-sticky">
            <div class="ws-flex-container-horizontal ws-flex-none header">
                <div class="header-title ws-flex-auto">Columns <i *ngIf="hasFilterApplied"
                                                                  helpTooltip
                                                                  helpContentId="ag-grid.filter-applied"
                                                                  class="fa fa-exclamation-circle"></i>
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-flex-none settings" [formGroup]="columnSettings" [class.open]="showSettings">
                <div class="column-setting search-filter">
                    <label class="select-search-label">
                        <input type="text" class="form-control ws-stretch-width" formControlName="filter" />
                        <i class="fa fa-search"></i>
                    </label>
                </div>
                <div class="column-setting"
                           helpTooltip
                           [helpContentId]="hasGroupings ? 'ag-grid.group-by-category' : 'ag-grid.no-categories'">
                    <label [class.disabled]="!hasGroupings">
                        <input type="checkbox" formControlName="groupBy">
                        Group By Category
                    </label>
                </div>
                <div class="column-setting"
                     helpTooltip
                     [helpContentId]="hasGroupings ? 'ag-grid.show-category-headers' : 'ag-grid.no-categories'">
                    <label [class.disabled]="!hasGroupings">
                        <input type="checkbox" formControlName="showHeaders">
                        Colorize Headers
                    </label>
                </div>
            </div>
            <div *ngIf="columns" class="select-all">
                <div>
                    <label class="ws-flex-auto column-label">
                        <input type="checkbox" [ngModel]="columns.isVisible" (ngModelChange)="toggleGroup($event, columns)"> Select All
                    </label>
                </div>
                <div class="ws-flex-container-horizontal">
                    <span class="settings-toggle clickable"
                          helpTooltip
                          helpContentId="ag-grid.sort"
                          [class.active]="selectedSort !== sortDirection.None"
                          (click)="toggleSort(selectedSort)">
                        <i class="fa" [ngClass]="{ 'fa-sort': selectedSort === sortDirection.None, 'fa-sort-up': selectedSort === sortDirection.Ascending, 'fa-sort-down': selectedSort === sortDirection.Descending }"></i>
                    </span>
                    <span *ngIf="hasColumnChanges"
                          class="settings-toggle clickable"
                          helpTooltip
                          helpContentId="ag-grid.clearFilterHelpContentId"
                          (click)="undoChanges()">
                        <i class="fa fa-undo"></i>
                    </span>
                    <span class="settings-toggle clickable"
                          helpTooltip
                          helpContentId="ag-grid.layout-filter-toggle"
                          [class.active]="showSettings"
                          (click)="toggleSettings()">
                        <i class="fa fa-sliders"></i>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="columns" class="columns-container ws-flex-container-vertical ws-flex-auto">
            <ng-container *ngIf="!showGrouped">
                <div *ngFor="let c of columns.columns; trackBy: trackByColumnId" class="ungrouped-column">
                    <i *ngIf="canUserPin"
                       class="fa fa-thumb-tack pinned-icon"
                       helpTooltip
                       helpContentId="ag-grid-tool-panel.pinColumn"
                       [ngClass]="{'pinned': c.isPinned, 'disabled': !c.canChangeIsPinned }"
                       (click)="setColumnPinned(!c.isPinned, c)"></i>
                    <label class="ws-flex-auto column-label"
                           helpTooltip
                           helpContentId="ag-grid.group-by-category">
                        <input type="checkbox" [disabled]="!c.canChangeIsVisible" [ngModel]="c.isVisible" (ngModelChange)="toggleIsVisible($event, c)"> {{ c.name }}
                    </label>
                </div>
            </ng-container>
            <ng-container *ngIf="showGrouped">
                <div *ngFor="let group of columns.groups; trackBy: trackByGroupName">
                    <ng-container *ngIf="group.columns.length">
                        <div class="ws-flex-container-horizontal ws-stretch-width ws-flex-align-items-center column-group" [ngClass]="group.headerColor">
                            <label class="ws-flex-grow column-label">
                                <ws-tri-state-checkbox [ngModel]="group.isVisible" (ngModelChange)="toggleGroup($event, group)"></ws-tri-state-checkbox> {{ group.groupName }}
                            </label>
                            <span class="fa column-collapse"
                                          [ngClass]="{ 'fa-caret-down': !group.isCollapsed, 'fa-caret-up': group.isCollapsed }"
                                          (click)="toggleGroupLevelCollapse(group)">
                            </span>
                        </div>
                        <ng-container *ngIf="!group.isCollapsed">
                            <div *ngFor="let c of group.columns; trackBy: trackByColumnId" class="grouped-column">
                                <i *ngIf="canUserPin"
                                   class="fa fa-thumb-tack pinned-icon"
                                   helpTooltip
                                   helpContentId="ag-grid-tool-panel.pinColumn"
                                   [ngClass]="{'pinned': c.isPinned, 'disabled': !c.canChangeIsPinned }"
                                   (click)="setColumnPinned(!c.isPinned, c)"></i>
                                <label class="ws-flex-auto column-label">
                                    <input type="checkbox" [disabled]="!c.canChangeIsVisible" [ngModel]="c.isVisible" (ngModelChange)="toggleIsVisible($event, c)"> {{ c.name }}
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
