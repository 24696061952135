<ws-modal-wrapper [headerLabel]="headerLabel" acceptLabel="Save" (save)="save()" (cancel)="cancel()"
                  [disableSave]="!isSaveable()">
    <div class="ws-flex-container-vertical ws-stretch">
        <table class="fb-bulk-update-fields">
            <tbody>
                <tr>
                    <td>
                        <label>Action:</label>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;"
                                    [(ngModel)]="editReq.details.editAction" (change)="editActionChanged()">
                                <option *ngFor="let ea of availableEditActions" [ngValue]="ea.value">{{ea.name}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr *ngIf = "isTaxYearEnabled()">
                    <td>
                        <label>Tax Year:</label>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="editReq.details.taxYear">
                                <option *ngFor="let option of selectableTaxYears" [ngValue]="option">{{option}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr *ngIf = "isFmvValueEnabled()">
                    <td>
                        <label>{{fmvValueLabel}}:</label>
                    </td>
                    <td>
                        <div>
                            <input type="text" class="form-control input-number" style="width: auto; display: inline-block; text-align: right;"
                                   [(ngModel)]="fmvValueDisplay" (blur)="fmvValueBlur(fmvValueDisplay)">
                        </div>
                    </td>
                </tr>
                <tr *ngIf = "isYoYPercentEnabled()">
                    <td>
                        <label>{{yoyPercentLabel}}:</label>
                    </td>
                    <td>
                        <div>
                            <input type="text" class="form-control input-number" style="width: auto; display: inline-block; text-align: right;"
                                   [(ngModel)]="yoyPercentDisplay" (blur)="yoyPercentBlur(yoyPercentDisplay)">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ws-modal-wrapper>
