import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HybridAdapterUtility } from "../Hybrid/hybrid-adapter.utility";
import { WeissmanSharedModule } from "../Common/weissman-shared.module";
import { HelpComponent } from "./Help/help.component";
import { QuillModule } from "ngx-quill";
import { LandingComponent } from "./landing.component";
import { SSOLoginComponent } from "./ssoLogin.component";
import { OverviewSingleGroupingComponent } from "./Widgets/overview-single-grouping.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { LandingSelectorComponent } from './landing.selector.component';
import { DashboardModule } from '../Dashboard/dashboard.module';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        QuillModule,
        TooltipModule.forRoot(),
        DashboardModule
    ],
    declarations: [
        HelpComponent,
        SSOLoginComponent,
        LandingComponent,
        OverviewSingleGroupingComponent,
        LandingSelectorComponent
    ]
})
export class LandingModule {
    static setupModule(): void {
        //let hybridAdapterUtility = new HybridAdapterUtility();

        //Entry components must have only two words, for example: parcelActivity not parcelActivitySummary
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        hybridAdapterUtility.downgradeNg2Component('helpComponent', HelpComponent);
        hybridAdapterUtility.downgradeNg2Component('landingSelector', LandingSelectorComponent);
        hybridAdapterUtility.downgradeNg2Component('ssoLogin', SSOLoginComponent);
        hybridAdapterUtility.downgradeNg2Component('landing', LandingComponent);

    }
}
