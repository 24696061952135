<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-truncate ws-flex-auto">Printing and Mailing</h3>
    </div>
    <div class="panel-flat-body">
        <div class="ws-flex-container-vertical" *ngIf="returnOutputModel">
            <div class="ws-section">
                <div class="ws-section__header">
                    <h4 class="ws-section__header__title">
                        Files to Include
                    </h4>
                </div>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includeFilingControlReport">
                    <input type="checkbox" name="includeFilingControlReport" id="includeFilingControlReport" [(ngModel)]="returnOutputModel.includeFilingControlReport" (change)="onOptionChange()"> Filing Control Report
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includeUSPSCertificateOfMailingLog">
                    <input type="checkbox" name="includeUSPSCertificateOfMailingLog" id="includeUSPSCertificateOfMailingLog" [(ngModel)]="returnOutputModel.includeUSPSCertificateOfMailingLog" (change)="onOptionChange()"> USPS Certificate of Mailings (log)
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includeUSPSCertificateOfMailingIndividual">
                    <input type="checkbox" name="includeUSPSCertificateOfMailingIndividual" id="includeUSPSCertificateOfMailingIndividual" [(ngModel)]="returnOutputModel.includeUSPSCertificateOfMailingIndividual" (change)="onOptionChange()"> USPS Certificate of Mailings (individual)
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includeEnvelopeInserts">
                    <input type="checkbox" name="includeEnvelopeInserts" id="includeEnvelopeInserts" [(ngModel)]="returnOutputModel.includeEnvelopeInserts" (change)="onOptionChange()"> Envelope Inserts
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includePackageCoverSheets">
                    <input type="checkbox" name="includePackageCoverSheets" id="includePackageCoverSheets" [(ngModel)]="returnOutputModel.includePackageCoverSheets" (change)="onOptionChange()"> Package Cover Sheets
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="includeReturns">
                    <input type="checkbox" name="includeReturns" id="includeReturns" [(ngModel)]="returnOutputModel.includeReturns" (change)="onOptionChange()"> Returns
                </label>
            </div>
            <div class="ws-section">
                <div class="ws-section__header">
                    <h4 class="ws-section__header__title">
                        Options
                    </h4>
                </div>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="organizeForMailing">
                    <input type="checkbox" name="organizeForMailing" id="organizeForMailing" [(ngModel)]="returnOutputModel.organizeForMailing" (change)="onOptionChange()"> Organize envelope inserts and package cover sheets for mailing
                </label>
            </div>
            <div class="ws-flex-auto">
                <label class="ws-flex-none small ws-font-weight-normal" for="embedExcelReportsInPdf">
                    <input type="checkbox" name="embedExcelReportsInPdf" id="embedExcelReportsInPdf" [(ngModel)]="returnOutputModel.embedExcelReportsInPdf" (change)="onOptionChange()" [disabled]="!returnOutputModel.includeReturns"> Embed Excel reports in PDF returns
                </label>
            </div>
        </div>
    </div>
</div>
