import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject } from 'rxjs';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { DocumentIntakeLicensedClientDetailRepository } from '../documentIntakeLicensedClient.repository';
import * as _ from 'lodash';
import { TeamService } from 'src/app/Team/team.service';

export interface DocumentIntakeLicensedClientDetailComponentParams {
    client: Core.DocumentIntakeLicensedClientModel;
}

@Component({
    selector: 'document-intake-licensed-client-detail',
    templateUrl: './documentIntakeLicensedClientDetail.component.html',
    styleUrls: ['./documentIntakeLicensedClientDetail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentIntakeLicensedClientDetailComponent implements OnInit, OnDestroy, IWeissmanModalComponent<DocumentIntakeLicensedClientDetailComponentParams, Core.DocumentIntakeLicensedClientModel> {

    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _documentIntakeLicensedClientRepository: DocumentIntakeLicensedClientDetailRepository,
        private readonly _teamService: TeamService) {
    }

    params: DocumentIntakeLicensedClientDetailComponentParams;
    result: Core.DocumentIntakeLicensedClientModel;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    clientDetail: Core.DocumentIntakeLicensedClientModel;
    groups: Core.GroupModel[] = [];
    users: Core.UserTeamModel[] = [];
    saveAttempted: boolean = false;
    clientContactName: string;

    private destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        const busyLoading = 'load-Metadata';
        this.busyIndicatorMessageManager.add('Loading', busyLoading);

        try{
            const requests: Promise<any>[] = [
                this._teamService.getAllAssignableUsers(false, false, undefined, undefined, undefined),
                lastValueFrom(this._documentIntakeLicensedClientRepository.getDocumentGroupList())
            ];

            if (this.params.client.ryanUserGroupId) {
                requests.push(lastValueFrom(this._documentIntakeLicensedClientRepository.get(this.params.client.instanceId)));
            }
            const responses = await Promise.all(requests);

            this.users = responses[0];
            this.groups = responses[1];
            this.groups.splice(0, 0, {} as Core.GroupModel);

            if (this.params.client.ryanUserGroupId) {
                this.clientDetail = responses[2];
            } else {
                this.clientDetail = _.cloneDeep(this.params.client);
            }

            this.clientContactName = `${this.clientDetail.clientContact.firstName} ${this.clientDetail.clientContact.lastName}`;
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyLoading);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        try {
            this.result = await lastValueFrom(this._documentIntakeLicensedClientRepository.updateSettings(this.clientDetail));
            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    ultimatelyResponsibleChosen(user: Core.UserTeamModel): void {
        if(user) {
            this.clientDetail.ryanUltimatelyResp = _.cloneDeep(user);
        }
    }

    assignedEntryChosen(user: Core.UserTeamModel): void {
        if(user) {
            this.clientDetail.ryanAssignedEntry = _.cloneDeep(user);
        }
    }

    assignedQCChosen(user: Core.UserTeamModel): void {
        if(user) {
            this.clientDetail.ryanAssignedQC = _.cloneDeep(user);
        }
    }

    get canSave(): boolean {
        return !!this.clientDetail && !!this.clientDetail.ryanUserGroupId && !!this.clientDetail.ryanUltimatelyResp &&
            !!this.clientDetail.ryanAssignedEntry && !!this.clientDetail.ryanAssignedQC;
    }
}
