import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const PARCEL_LIST_PANEL_HELP: HelpContent[] = [
    {
        helpContentId: 'parcel-list-panel.view-switch',
        tooltipText: 'Toggle between tile and list view'
    },
    {
        helpContentId: 'parcel-list-panel.add',
        tooltipText: 'Add'
    },
    {
        helpContentId: 'parcel-list-panel.set-reporting',
        tooltipText: 'Set as site\'s default parcel for compliance assets'
    },
    {
        helpContentId: 'parcel-list-panel.remove-reporting',
        tooltipText: 'Remove as site\'s default parcel for compliance assets'
    },
    {
        helpContentId: 'parcel-list-panel.set-site-consolidated',
        tooltipText: 'Set as the site-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'parcel-list-panel.set-state-consolidated',
        tooltipText: 'Set as the state-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'parcel-list-panel.remove-site-consolidated',
        tooltipText: 'Remove as site-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'parcel-list-panel.remove-state-consolidated',
        tooltipText: 'Remove as state-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'parcel-list-panel.opt-out-consolidated',
        tooltipText: 'Opt out of consolidation for compliance returns'
    },
    {
        helpContentId: 'parcel-list-panel.opt-in-consolidated',
        tooltipText: 'Set as merged parcel for compliance returns'
    }
];
