import { Component, OnDestroy } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../../Compliance/AgGrid';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker } from '../../../../../Compliance/AgGrid/MultiSelectTracker';
import { Subscription } from 'rxjs';
import { StateService } from '../../../../../Common/States/States.Service';
import { StateSummary } from '../../../../../Common/States/state.model';
import { ValuationTemplateService } from '../../../valuationTemplate.service';

export interface AddStateModalParams {
    valuationTemplateId: number;
    statesSelected: number[];
}

@Component({
    selector: 'add-state-modal',
    templateUrl: './addState.component.html'
})
export class AddStateComponent implements OnDestroy, IWeissmanModalComponent<AddStateModalParams, Core.ValuationTemplateModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _stateService: StateService,
        private readonly _valuationTemplateService: ValuationTemplateService
    ) {}

    params: AddStateModalParams;
    result: Core.ValuationTemplateModel;

    loading: boolean = true;
    hasChanges: boolean;
    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as StateSummary).stateID)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    private _allStates: StateSummary[];
    private _selectableStates: StateSummary[];
    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;

    async ngOnInit(): Promise<void> {
        const states = await this._stateService.getSummary();
        this._allStates = states.filter(x => x.countryID === 1);
        this._selectableStates = this._allStates.filter(x => !this.params.statesSelected.includes(x.stateID));
        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.hasChanges = (x.selectAllRows && x.selectedRows.length !== this._selectableStates.length)
                              || (!x.selectAllRows && !!x.selectedRows.length);
        });

        const columns: ColDef[] = [
            {
                field: 'stateID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'State',
                field: 'fullName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            }
        ]

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        this.loading = true;
        try {
            const rowModel = await this.gridTracker.getSelectedRowsModel();
            const states = this._allStates
                                  .reduce((acc, x) => {
                                      const row = rowModel.selectedRows.find(y => x.stateID === y);
                                      if ((!rowModel.selectAllRows && row) || (rowModel.selectAllRows && !row) || this.params.statesSelected.includes(x.stateID)) {
                                          acc.push({
                                              stateId: x.stateID,
                                              valuationTemplateId: this.params.valuationTemplateId
                                          });
                                      }
                                      return acc;
                                  }, [] as Core.ValuationTemplateStateModel[]);

            this.result = await this._valuationTemplateService.updateTemplateStates(this.params.valuationTemplateId, states);
        } finally {
            this.cancel();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!this._gridApi || !this._selectableStates) {
            return;
        }

        this._gridApi.setRowData(this._selectableStates);
        this._gridApi.sizeColumnsToFit();
        this.loading = false;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map(x => {
                const state = x.data as StateSummary;
                return state && state.stateID;
            })
        } as Compliance.QueryResultModel<number>);
    }

}
