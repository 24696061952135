<div class="ws-flex-container-vertical ws-flex-auto ws-stretch">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Valuation Templates
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="valuation-template.new-template"
                         position="bottom">
                        <button type="button"
                                class="flat-button small-button primary-button"
                                (click)="createNewTemplate()">
                            New Template
                        </button>
                    </div>
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button"
                                class="flat-button small-button primary-button"
                                (click)="refreshGridData()">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-flex-container-vertical ws-flex-auto">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>

