import { Injectable } from '@angular/core';
import { SDHttpService } from '../../../Common/Routing/sd-http.service';
import {ParcelActivitySummary} from "./parcel-activity.model";

declare const _: any;

const parcelActivityEndpoint: string = '/api/DeliverableTasks/Parcel/';
const siteActivityEndpoint: string = '/api/DeliverableTasks/Site/';

@Injectable()
export class ParcelActivityService {
    constructor(private http: SDHttpService) {
    }

    getParcelActivity(parcelId: number): Promise<ParcelActivitySummary[]> {
        return this.http.get(parcelActivityEndpoint + parcelId).then((results: ParcelActivitySummary[]) => {
            for (var i = 0; i < results.length; i++) {
                results[i].deliverableDueDateHasTime = results[i].deliverableDueDate &&
                    !(results[i].deliverableDueDate.getUTCHours() === 0 &&
                    results[i].deliverableDueDate.getUTCMinutes() === 0 &&
                    results[i].deliverableDueDate.getUTCSeconds() === 0 &&
                    results[i].deliverableDueDate.getUTCMilliseconds() === 0);
            }
            return results;
        });
    }

    getParcelActivityForSite(siteId: number): Promise<ParcelActivitySummary[]> {
        return this.http.get(siteActivityEndpoint + siteId);
    }
}
