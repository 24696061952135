import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { FormSetupRepository } from '../../Repositories';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'form-setup-upload',
    templateUrl: './formSetupUpload.component.html'
})
export class FormSetupUploadComponent implements IWeissmanModalComponent<void, number> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formSetupRepository: FormSetupRepository
    ) { }

    params: void;
    result: number;

    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    file: File;
    replaceTargetFormRevision: boolean;

    cancel(): void {
        this._bsModalRef.hide();
    }

    filePicked(files: File[]): void {
        this.file = files[0];
    }

    async upload(): Promise<void> {
        this.busyMessageManager.add('Uploading', 'uploading');

        try {
            const fileMetadata = {
                fileName: this.file.name,
                replaceTargetFormRevision: this.replaceTargetFormRevision
            } as Compliance.FormSetupUploadModel;

            this.result = await lastValueFrom<number>(this._formSetupRepository.import(this.file, fileMetadata));

            this._bsModalRef.hide();
        } finally {
            this.busyMessageManager.remove('uploading');
        }
    }
}
