/* Note that originally, this project was written in AngularJS without import or require; JavaScript dependencies
were included and added their code directly to the global object. Obviously our Angular2+ code does not work that
way, so for the most part "old" AngularJS dependencies are included in the scripts array in angular.json and new
Angular dependencies are imported as needed. However, a few dependencies might be needed both places, so this file
handles importing deps and adding them to global if necessary so that any TypeScript that does for instance
import * from 'lodash'; will reference the same dependency.

IMPORTANT: This means that if you just import a dep from the angular.json scripts array, you may accidentally cause
a double-include. A good example of this is jQuery; if you import jQuery it'll double-up and potentially even break.
Instead, the best bet is to not use jQuery at all, but if you have to reference a dependency from the old world, use
a declare. For instance:

declare const $: any;
*/

import 'lodash';
// Note: this imports all timezone data moment has. It's probably pretty wasteful; we should consider building
// our own timezone file and trying to use it in place of the entire dataset (unfortunately, that probably involves
// some kind of fork).
import * as moment from 'moment-timezone';
window.moment = moment;
import * as agGrid from 'ag-grid-community/dist/ag-grid-community';
window.agGrid = agGrid;
import * as numeral from 'numeral';
window.numeral = numeral;
import { saveAs } from 'file-saver';
window.saveAs = saveAs;

// Note that we are not importing pdfjs-dist here; that is included in the scripts array
// in angular.json because those files export globals from JavaScript, and importing
// here doesn't reliably export those globals. Note also that we fork pdfJs in the
// third-party directory, so in effect we are importing pdfjs twice.
