import { UIRouter, UrlService } from '@uirouter/angular';
import './app/Hybrid/externalImports';
import './app/Hybrid/ngjsStart';
import './app/Hybrid/appImports';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, NgZone } from '@angular/core';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { weissmanAppModule } from './app/Hybrid/ngjsStart';
import { WeissmanEnvironmentConfig } from './app/weissmanEnvironmentConfig';
import { LocalStorageKeys } from './app/localStorageKeys';

const windowRef = window as any;
const refreshRegexResult = /^\#\/refresh\/(.*)$/.exec(window.location.hash);
let locationSet = false;
if (!refreshRegexResult) {
    windowRef.weissmanRefreshState = 'normal';
    if (window.location && window.location.search) {
        const parsedQueryString = /^\?([^\/\?]*)\/?$/.exec(window.location.search);
        const queryStringArguments = !parsedQueryString ? [] : parsedQueryString[1]
            .split('&')
            .map((a, i) => {
                const tokens = a.split('=');
                return {
                    key: decodeURIComponent(tokens[0]),
                    value: tokens[1] ? decodeURIComponent(tokens[1]) : null,
                    raw: a,
                    index: i
                };
            });

        const domainArgument = queryStringArguments.reverse().find(a => a.key === 'sso-domain');
        if (domainArgument) {
            localStorage[LocalStorageKeys.lastSSODomain] = domainArgument.value;
            const newQueryString = queryStringArguments.length === 1 ? '' : (`?${  queryStringArguments
                .filter(a => a.index !== domainArgument.index)
                .map(a => a.raw)
                .join('&')  }/`);
            // Refresh page leaving out sso-domain argument
            locationSet = true;
            window.location.href = `${window.location.pathname}${newQueryString}${window.location.hash}`;
        }
    }
}
else {
    windowRef.weissmanRefreshState = 'refreshed';
    const newHash = decodeURIComponent(refreshRegexResult[1]);
    console.log(`Processing refresh URL: ${  newHash}`);
    if (history.replaceState) {
        history.replaceState(null, '', newHash);
    }
    else {
        window.location.hash = newHash;
    }
}

if (!locationSet) {
    setAngularJSGlobal(windowRef.angular);

    const config = WeissmanEnvironmentConfig.initEnvironment();
    windowRef.weissmanEnvironmentConfig = config;

    if (!config.developerMode) {
        enableProdMode();
    }

    // Using AngularJS config block, call `deferIntercept()`.
    // This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
    weissmanAppModule.config([ '$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept() ]);

    platformBrowserDynamic().bootstrapModule(AppModule).then((platformRef) => {
        // Ensure Angular destroys itself on hot reloads.
        window['platformRef'] && window['platformRef'].destroy();
        window['platformRef'] = null;

        // get() UrlService from DI (this call will create all the UIRouter services)
        const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

        // Instruct UIRouter to listen to URL changes
        function startUIRouter() {
            urlService.listen();
            urlService.sync();
        }

        const ngZone: NgZone = platformRef.injector.get(NgZone);
        ngZone.run(startUIRouter);
    });
}
