import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReturnService } from '../../../return.service';
import { ReturnPreviewService } from '../returnPreview.service';
import { RETURN_PREVIEW_FORM_OVERRIDES_HELP } from './returnPreviewFormOverrides.component.help';
import { HelpService } from '../../../../../UI-Lib/Help-Tooltip';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { ReturnPreviewFormOverrideListParams, ReturnPreviewFormOverrideListComponent } from '../Form-Overrides-List/returnPreviewFormOverrideList.component';

@Component({
    selector: 'return-preview-form-overrides',
    templateUrl: './returnPreviewFormOverrides.component.html',
    styleUrls: ['./returnPreviewFormOverrides.component.scss']
})
export class ReturnPreviewFormOverridesComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnPreviewService: ReturnPreviewService,
        private readonly _helpService: HelpService,
        private readonly _modalService: WeissmanModalService) { }

    ngOnInit(): void {
        this._helpService.setContent(RETURN_PREVIEW_FORM_OVERRIDES_HELP);
    }

    ngOnDestroy(): void { }

    async openOverridesModal(): Promise<void> {
        const params: ReturnPreviewFormOverrideListParams = {
            formRevisionScheduleId: null,
            filingBatchId: this._returnService.filingBatchId,
            returnIds: this._returnService.sharedState.returns.map(x => x.returnId),
            isReadOnly: this._returnService.isReturnInReadOnlyMode || !this._returnService.canEditCompany
    }

        const result = await this._modalService.showAsync(ReturnPreviewFormOverrideListComponent, params, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this._returnPreviewService.notifyReturnFormOverrideListChanged(result);

        return Promise.resolve();
    }
}
