import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BulkSaveExceptionResult } from '../Client-Services/Exceptions/client-service-exception.models';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable()
export class ConsultingEngagementsRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }


    private _pathPrefix = 'api/ce';

    //get list of engagements for the current instance
    getList(): Promise<Core.ConsultingEngagementResponse[]> {
        return lastValueFrom(this.httpGet(`${this._pathPrefix}`)) as Promise<Core.ConsultingEngagementResponse[]>;
    }

    getAcceptedEngagements(entityTypeId: number, entityId: number): Promise<Core.ConsultingEngagementResponse[]> {
        return lastValueFrom(this.httpClient.get(`${this._pathPrefix}/${entityTypeId}/${entityId}`)) as Promise<Core.ConsultingEngagementResponse[]>;
    }

    getEngagements(entityTypeId: number, entityId: number): Promise<Core.ConsultingEngagementResponse[]> {
        return lastValueFrom(this.httpClient.get(`${this._pathPrefix}/${entityTypeId}/${entityId}?includeUnaccepted=true`)) as Promise<Core.ConsultingEngagementResponse[]>;
    }

    getEngagementInfo(consultingEngagementId: number): Promise<Core.ConsultingEngagementInfo> {
        return lastValueFrom(this.httpGet(`${this._pathPrefix}/engagementInfo/${consultingEngagementId}`)) as Promise<Core.ConsultingEngagementInfo>;
    }

    getConsultants(): Promise<Core.Consultant[]> {
        return lastValueFrom(this.httpGet(`${this._pathPrefix}/consultants`)) as Promise<Core.Consultant[]>;
    }

    //accept engagement offer
    async accept( request: Core.ConsultingEngagementDetails) {
        const result = await lastValueFrom(this.httpPost<boolean, any>(`${this._pathPrefix}/accept`, request));

        if(result === false) {
            throw 'Consulting engagement could not be accepted';
        }
        return result;
    }

    //update engagement offer
    async update( request: Core.ConsultingEngagementDetails): Promise<BulkSaveExceptionResult[]> {
        return await lastValueFrom(this.httpPut(`${this._pathPrefix}/update`, request));
    }

    async delete(consultingEngagementId: number) {
        await lastValueFrom(this.httpDelete(`${this._pathPrefix}/${consultingEngagementId}`));
    }

    async invite(companyId: number, instanceId: number, permissions: number[]) {
        const req : Core.CreateConsultingEngagementRequest =  {
            instanceId,
            companyId,
            permissions
        };
        await lastValueFrom(this.httpPost(`${this._pathPrefix}/invite`, req));
    }

    async updatePermissions(consultingEngagementId: number, updatedPermissions: number[]) {

        const request = {consultingEngagementId: consultingEngagementId, permissions: updatedPermissions};
        await lastValueFrom(this.httpPost(`${this._pathPrefix}/implicitPermissions`, request));
    }

    async getUserGroups(): Promise<Core.GroupModel[]>{
        return lastValueFrom(this.httpGet(`${this._pathPrefix}/userGroups`));
    }
}
