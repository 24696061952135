import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IDoesFilterPassParams, IFilterOptionDef } from 'ag-grid-community';
import { FilterUIModel, FilterValueModel } from '../filterUI.model';
import { AgGridFilterBase, WSTextFilterParams } from '../agGridFilterBase';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'ws-ag-grid-number-filter',
    templateUrl: './agGridNumberFilter.component.html',
    styleUrls: ['../agGridFilters.scss']
})
export class AgGridNumberFilterComponent extends AgGridFilterBase<WSTextFilterParams> {
    constructor(private toastr: ToastrService) {
        super();
    }

    @ViewChild('input', { read: ViewContainerRef }) input;
    @ViewChild('toValue', { read: ViewContainerRef }) toValue;
    @ViewChild('applyButton', { read: ViewContainerRef }) applyButton;

    selectFilterOptions: IFilterOptionDef[] = [];
    filterOptions: Array<string | IFilterOptionDef> = [];
    filterConditionType: Core.FilterConditionTypeEnum = Core.FilterConditionTypeEnum.None;
    filterValues: FilterValueModel[] = [];

    agInit(params: WSTextFilterParams): void {
        this._params = params;
        this._valueGetter = params.valueGetter;

        this._searchTypeMap = new Map([
            ['inRange', (values:any, cv) => +cv >= +values.filterValue && +cv <= +values.filterToValue],
            ['equals', (values:any, cv) => +cv === +values.filterValue],
            ['notEqual', (values:any, cv) => +cv !== +values.filterValue],
            ['greaterThan', (values:any, cv) => +cv > +values.filterValue],
            ['greaterThanOrEqualTo', (values:any, cv) => +cv >= +values.filterValue],
            ['lessThan', (values:any, cv) => +cv < +values.filterValue],
            ['lessThanOrEqualTo', (values:any, cv) => +cv <= +values.filterValue]
        ]);
        this._defaultFilterOptions = [
            'equals',
            'notEqual',
            'greaterThan',
            'lessThan',
            'inRange'
        ];

        this.filterOptions = (params.filterOptions) ? params.filterOptions : this._defaultFilterOptions;
        this.selectFilterOptions = this.filterOptions.map(this._formatOption.bind(this));

        this.filterValues = [{ filterValue: null, filterToValue: null, filterType: this._getDefaultFilterType(this.selectFilterOptions) }];
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        if (!this.filterValues[0].filterType) { return true; }

        const filterTest = x => {
            let value: number;
            if (this._filterValueGetter) {
                const filterParams = { ...params, getValue: this._valueGetter, valueGetterParams: {...this._params, ...params} } as any;
                value = (typeof this._filterValueGetter === 'string') ? this._filterValueGetter : this._filterValueGetter(filterParams);
            } else {
                value = this._valueGetter(params.node);
            }

            if (this._params.textCustomComparator) {
                return this._params.textCustomComparator(x.filterType.displayKey, +value, x.filterValue, params.node.data);
            } else {
                return x.filterType.test(x, value);
            }
        };

        return this.filterConditionType === Core.FilterConditionTypeEnum.Or
            ? this.filterValues.some(filterTest)
            : this.filterValues.every(filterTest);
    }

    setModel(model: FilterUIModel): void {
        this._appliedModel = model;

        this.filterValues = [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        this.filterConditionType = model ? model.filterConditionType : Core.FilterConditionTypeEnum.None;
        if (model && model.filterValues) {
            this.filterValues = model.filterValues.map(x => ({filterValue: x.filterValue, filterToValue: x.filterToValue, filterType: this.selectFilterOptions.find(y => y.displayKey === x.filterType.displayKey)}));
        }
    }

    getModel() {
        if (this.filterValues.length > 1) {
            this.filterValues = this.filterValues.some(x => !!(x.filterValue || x.filterType.hideFilterInput))
                ? this.filterValues.filter(x => !!(x.filterValue || x.filterType.hideFilterInput))
                : [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        }
        this.filterConditionType = this.filterValues.length > 1 ? this.filterConditionType : Core.FilterConditionTypeEnum.None;
        return ((this.filterValues.length && this.filterValues.some(x => !!x.filterValue)) || (this.filterValues[0].filterType && this.filterValues[0].filterType.hideFilterInput))
            ? {
                filterType: 'number',
                filterConditionType: this.filterConditionType,
                filterValues: this.filterValues
            }
            : null;
    }

    onToValueChange(newValue: string, index: number): void {
        if (this.filterValues[index].filterToValue !== newValue) {
            this.filterValues[index].filterToValue = newValue;
            this._params.filterModifiedCallback();
        }
    }

    enterPressed(event: Event, value: string): void {
        if (value === '-') {
            this._invalidNegative();
            event.stopPropagation();
        }
    }

    applyNumberFilter(): void {
        const isInvalidNegative = this.filterValues.some(x => x.filterValue === '-');

        if(isInvalidNegative) {
            this._invalidNegative();
        } else {
            this.applyFilter();
        }
    }

    private _invalidNegative(): void {
        this.toastr.error('Please enter a valid negative value');
    }
}
