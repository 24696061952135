(function () {
    'use strict';

    // Make sure it's been at least 60 seconds since the last time we tried to do a stale refresh
    const refreshRetryDelay = 60000;

    angular.module('weissmanApp').factory('BuildTimestampService', Service);

    Service.$inject = ['$q', 'sd.Http.Service'];

    function Service($q, http) {
        var resolve;
        var reject;

        var timestampCheck = $q(function (res, rej) {
            resolve = res;
            reject = rej;
        });

        return {
            getTimestampCheckPromise: getTimestampCheckPromise,
            startCheck: startCheck
        };

        function getTimestampCheckPromise() {
            return timestampCheck;
        }

        function startCheck() {
            timestampCheck = http.get('/app/build-timestamp.txt').then(function (ts) {
                var runtimeTimestamp = parseInt(ts, 10);
                if (window.weissmanEnvironmentConfig.developerMode) {
                    console.log('Developer mode; skipping UI build time check');
                }
                else if (runtimeTimestamp !== window.weissmanBuildTimestamp) {
                    // Looks like the UI is stale. Try to figure out the last time the page was refreshed
                    var lastRefresh = sessionStorage['StateUIRefreshTime'];
                    if (!lastRefresh || (new Date()).getTime() - parseInt(lastRefresh, 10) > refreshRetryDelay) {
                        console.log('Stale build detected; attempting refresh. Build time: ' + new Date(window.weissmanBuildTimestamp) +
                            '; runtime-detected build time: ' + new Date(runtimeTimestamp));
                        reject("Refreshing");
                        window.location.reload(true);
                        sessionStorage['StateUIRefreshTime'] = (new Date()).getTime();
                    }
                    else {
                        // We failed at failing
                        console.error('Build timestamp mismatch, and it appears a reload has already been tried. Build timestamp: ' +
                            window.weissmanBuildTimestamp + '; runtime value: ' + runtimeTimestamp);
                    }
                }
                else {
                    delete sessionStorage['StateUIRefreshTime'];
                    console.log('Verified UI build up to date (build time: ' + new Date(weissmanBuildTimestamp) + ')');
                }
                resolve();
            }).catch(function (error) {
                reject(error);
            });

            http.initializeTimestampCheck(timestampCheck);
        }
    }
})();
