import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TypeaheadCompany } from '../../Entity/Company/Company.Picker.Component';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { GetEntitiesRequest } from 'src/app/Reporting/Manager/report.manager.model';
import { ReportManagerSelectEntitiesService } from 'src/app/Reporting/Manager/select.entities.service';
import { PaymentBatchService } from '../paymentBatch.service';
import { PaymentBatchCompany } from '../paymentBatch.model';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';

interface DDOption {
    id: number;
    name: string;
}

@Component({
    selector: 'create-payment-batch-modal-component',
    templateUrl: './createPaymentBatchModal.component.html'
})
export class CreatePaymentBatchModalComponent implements OnInit {
    constructor(
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private selectEntitiesService: ReportManagerSelectEntitiesService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _toastr: ToastrService,
        private readonly _bsModalRef: BsModalRef,
        private _paymentBatchService: PaymentBatchService,
    ) { }

    @ViewChild('descriptionText') descriptionText: ElementRef;

    result: void;
    topCompanyID: number;
    companyID: number;
    serverAction: boolean = false;
    newPaymentBatchForm: UntypedFormGroup;
    companyName: string;
    companiesLoading: boolean;
    dataSource: Observable<any>;
    payload: GetEntitiesRequest;
    batchTypeOptions: Core.PaymentBatchCreationBatchTypeModel[];
    selectedBatchTypeId: Core.PaymentBatchTypeEnum;
    paymentBatch: Core.PaymentBatchModel;
    onClose: () => void;


    async ngOnInit() {
        this.payload =  new GetEntitiesRequest();
        this.payload.excludeInactive = true;
        this.payload.sourceEntityIsTopLevelCompany = true;
        this.payload.sourceEntityTypeID = 1;
        this.payload.targetEntityTypeID = 1;
        this.payload.sourceEntityIDs = [this.topCompanyID];

        this.newPaymentBatchForm = this._fb.group({
            companyName: [{value: '', disabled: true}, Validators.required],
            descriptionText: [{value: '', disabled: false}, Validators.required],
            batchType: [{value: '', disabled: false}, Validators.required]
        });
        this.dataSource = Observable.create((observer: any) => {
            this.selectEntitiesService.getEntitiesFromParent(this.payload)
                .then((data) => {
                    observer.next(data);
                });
        });
    }

    topCompanySelected(selectedCompany: TypeaheadCompany) {
        if(_.isEmpty(selectedCompany)) return;

        if (selectedCompany.companyID) {
            this.topCompanyID = selectedCompany.companyID;
            this.newPaymentBatchForm.get('companyName').enable();
            this.newPaymentBatchForm.patchValue({
                companyName: selectedCompany.companyName
            });
            this.payload.sourceEntityIDs = [this.topCompanyID];
            this.companyID = selectedCompany.companyID;
        }
        else {
            this.topCompanyID = null;
            this.newPaymentBatchForm.patchValue({
                companyName: ''
            });
            this.newPaymentBatchForm.get('companyName').disable();
        }

        this.getAllowedBatchTypes(this.companyID);
    }

    typeaheadOnSelect(e: TypeaheadMatch) {
      this.companyID = e.item.id;
      this.getAllowedBatchTypes(this.companyID);
    }

    async getAllowedBatchTypes(companyID: number): Promise<void>{
        const allowedBatchTypes = await this._paymentBatchService.getAllowedBatchTypes(companyID);
        if (!allowedBatchTypes?.batchTypes.length) {
            console.log(`No Batch Types allowed for Company ${companyID}`);
            this.batchTypeOptions = [];
            this.selectedBatchTypeId = null;
            return;
        }
        this.batchTypeOptions = _.cloneDeep(allowedBatchTypes.batchTypes);
        this.selectedBatchTypeId = this.batchTypeOptions.length == 1 ? this.batchTypeOptions[0].id : null;
    }

    async save(): Promise<void> {
        const isDescriptionNonAscii = !(/^[ -~\t\n\r]+$/.test(this.newPaymentBatchForm.controls.descriptionText.value));
        if (isDescriptionNonAscii) {
            this._toastr.error('Description contains invalid character(s)');
            return;
        }
        const paymentBatchCreation: Core.PaymentBatchCreateRequestModel = {
            companyId: this.companyID,
            description: this.newPaymentBatchForm.get('descriptionText').value,
            batchType: this.newPaymentBatchForm.get('batchType').value as Core.PaymentBatchTypeEnum
        };

        this.serverAction = true;

        try {

            this.paymentBatch =  await this._paymentBatchService.savePaymentBatch(paymentBatchCreation);
            this._bsModalRef.hide();
            this._toastr.success('Payment Batch saved successfully');
        } catch (e) {
            if (e.error) {
                this._toastr.error(e.error.message);
            }
        } finally {
            this.serverAction = false;
            if ( this.paymentBatch ) {
                this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetails', {paymentBatchId: this.paymentBatch.paymentBatchId});
            }
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
