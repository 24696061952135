<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Edit Prior Return"
                  headerHelpContentId="filing-details.prior-return"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="saveDisabled">
    <form #filingDetailsForm="ngForm">
        <div class="mb-3">
            <label for="priorReturn">Prior Return</label><br>
            <select id="priorReturn" name="priorReturn" class="form-select form-select-sm" [(ngModel)]="selectedPriorReturn"  (change)="priorReturnChanged()">
                <option [ngValue]="null">None</option>
                <option *ngFor="let pr of priorReturns" [ngValue]="pr">{{ pr.filingDescription + (pr.returnId === params.calculatedPriorReturnId ? ' (Default)' : '') }}</option>
            </select>
        </div>
        <div class="mb-3">
            <label
                class="ws-flex-none ws-font-weight-normal"
                for="isAmendment"
                helpTooltip
                helpContentId="filing-details.amendment"
                position="right">
                <input type="checkbox" id="isAmendment" name="isAmendment" [(ngModel)]="isAmendment" [disabled]="!priorReturnSelected"> Amending Prior Return
            </label>
        </div>
    </form>
</ws-modal-wrapper>
