import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const APPEAL_RECOMMENDATION_HELP: HelpContent[] = [
    {
        helpContentId: 'appeal-recommendation.checkbox.disabled',
        tooltipText: 'Please click edit pencil to modify default settings or edit comment(s).'
    },
    {
        helpContentId: 'appeal-recommendation.checkbox.selections-exceed-printable-area-width',
        tooltipText: 'Selections exceed printable area width.'
    },
    {
        helpContentId: 'appeal-recommendation-template-specification.copy',
        tooltipText: 'Save a copy of this template.'
    },
    {
        helpContentId: 'appeal-recommendation-template-specification.no-template',
        tooltipText: 'Select a template.'
    },
    {
        helpContentId: 'appeal-recommendation-template-specification.edit-default',
        tooltipText: 'The built-in default template cannot be saved.'
    },
    {
        helpContentId: 'appeal-recommendation-template-specification.delete-default',
        tooltipText: 'The built-in default template cannot be deleted.'
    }
];
