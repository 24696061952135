import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const ALLOCATIONS_HELP: HelpContent[] = [
    {
        helpContentId: 'allocations.new-assessment',
        tooltipText: 'Add Tax Authorities'
    },
    {
        helpContentId: 'allocations.assessment-size-available',
        tooltipText: 'Size columns to fit the available space'
    },
    {
        helpContentId: 'allocations.assessment-size-cell-content',
        tooltipText: 'Size columns to fit cell contents'
    },
    {
        helpContentId: 'allocations.assessment-filter-toggle',
        tooltipText: 'Show assessment grid filters'
    },
];
