import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ActivityService } from '../Common/Activity/activity.service';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { StateService } from '../Common/States/States.Service';
import { StateSummary } from '../Common/States/state.model';
import { SiteSummary } from './Site/Site.Model';
import { CompanyService } from './Company/company.service';
import { ParcelService } from './Parcel/parcel.service.upgrade';
import { TypeaheadCompany } from './Company/Company.Picker.Component';
import { MoveCopyEntityService } from './move.copy.entity.service';
import { ParcelSummary } from './Parcel/parcel.models';
import { SiteRepository } from '../Core-Repositories';
import { InstanceRepository } from './Instance/instance.repository';
import { UserInstanceService } from '../User/userInstance.service';
import { RestrictService, Roles } from '../Common/Permissions/restrict.service';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { TimerService } from '../UI-Lib/Utilities';
import { MessageModalService } from '../UI-Lib/Message-Box/messageModal.service';
import { ClientServiceResponsibilityService } from '../Client-Services/clientServiceResponsibility.service';

import * as _ from 'lodash';

export enum Entity {
    COMPANY = 1, SITE = 5, PARCEL = 6
}

export enum CopyMoveCSRModeEnum {
    AlwaysPreserveExisting = 1,
    PreserveOverriddenOnly = 2,
    AssumeDestination = 3
}

export class MoveCopyPayload {
    constructor(entityTypeID: number, actionId: number, firstYear: number) {
        this.action = actionId;
        this.firstYear = firstYear;
        this.destEntityID = null;
        this.sourceEntityTypeID = entityTypeID;
        this.sourceIDs = [];
        this.includeAttachments = false;
        this.includeAppeals = false;
        this.includeComments = false;
        this.csrMode = CopyMoveCSRModeEnum.AlwaysPreserveExisting;

    }

    action: number;
    firstYear: number;
    destEntityID: number;
    sourceEntityTypeID: number;
    sourceIDs: number[];
    includeAttachments: boolean;
    csrMode: CopyMoveCSRModeEnum;
    interInstanceCopy: boolean;
    includeComments: boolean;
    includeAppeals: boolean;
    ignorePendingReturnError: boolean;
}

@Component({
    selector: 'move-copy-entity',
    templateUrl: './move.copy.entity.component.html'
})
export class MoveCopyEntityComponent implements OnInit {
    constructor(private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private readonly _stateService: StateService,
                private readonly _siteService: SiteRepository,
                private readonly _companyService: CompanyService,
                private readonly _parcelService: ParcelService,
                private readonly _moveCopyEntityService: MoveCopyEntityService,
                private readonly _instanceRepository: InstanceRepository,
                private readonly _userInstanceService: UserInstanceService,
                private readonly _restrictService: RestrictService,
                private readonly _featureFlagService: FeatureFlagsService,
                private readonly _timer: TimerService,
                private readonly _messageModalService: MessageModalService,
                private readonly _csrService: ClientServiceResponsibilityService,
                private readonly _activityService: ActivityService
    ) {
    }

    @Input() isCopy: boolean;

    entities = Entity;
    launchingEntity: number;
    states: StateSummary[];
    sites: SiteSummary[];
    parcels: ParcelSummary[];
    allParcels: ParcelSummary[];

    companyId: string;
    siteId: string;
    parcelId: string;

    companyName: string;
    siteName: string;
    targetCompany: TypeaheadCompany;
    moveCopyPayload: MoveCopyPayload;

    selectedStateId: number;
    stateSites: SiteSummary[];

    loading: boolean = false;
    awaitingConfirm: boolean = false;
    isInactive: boolean = false;
    excludeInactive: boolean = true;
    showSiteNumber: boolean = false;
    loadingSites: boolean = false;

    years: number[];
    sourceIds: number[];
    sourceIdIdx: number;
    isExecuting: boolean = false;
    userCancelled: boolean = false;
    hasCsrErrors: boolean = false;

    CopyMoveCSRModeEnum = CopyMoveCSRModeEnum;

    hasInstanceAdminPermission: boolean = false;
    instances: Array<Core.InstanceViewModel> = [];
    targetInstanceId: number;

    async ngOnInit() {
        this.companyId = this._upgradeNavigationServiceHandler.getQuerystringParam('companyID');
        this.siteId = this._upgradeNavigationServiceHandler.getQuerystringParam('siteID');
        this.parcelId = this._upgradeNavigationServiceHandler.getQuerystringParam('parcelID');
        this.isInactive = (this._upgradeNavigationServiceHandler.getQuerystringParam('isInactive') === 'true');
        this.sourceIdIdx = 0;
        this.sourceIds = [];

        if (this.isInactive) {
            this.excludeInactive = false;
        }

        if (this.parcelId && this._areValidIds([this.parcelId, this.siteId, this.companyId])) {
            this.launchingEntity = this.entities.PARCEL;
        } else if (this.siteId && this._areValidIds([this.siteId, this.companyId])) {
            this.launchingEntity = this.entities.SITE;
        } else if (this._areValidIds([this.companyId])) {
            this.launchingEntity = this.entities.COMPANY;
        } else {
            this._goToHome();
            return;
        }
        this.loading = true;

        try {
            this.companyName = await this._companyService.getCompanyName(Number(this.companyId));
            this.hasCsrErrors = await this._csrService.doesEntityHaveErrantResponsibilities({ entityTypeId: this.launchingEntity, entityId: +(this.parcelId || this.siteId || this.companyId) });

            //get instance id for the company - search must be scoped to the single instance
            this.targetInstanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('company', parseInt(this.companyId)));

            this.hasInstanceAdminPermission = this._restrictService.isInRole(Roles.ALLOWCOPYMOVEINSTANCE);
            if (this.hasInstanceAdminPermission) {
                this.instances = [this._userInstanceService.allInstancesItem].concat(this._userInstanceService.getUserInstanceMembership());
                this.targetInstanceId = this._userInstanceService.getSelectedInstance().instanceId;
            }

            await this._setupEntity();
        } finally {
            this.loading = false;
        }

        await this._getBeginningYears();
    }

    async _getBeginningYears(): Promise<void> {
        const result = await this._getYears();

        if (!result || !result.length) {
            return;
        }

        this.years = result;

        if (!this.moveCopyPayload.firstYear || !_.includes(this.years, this.moveCopyPayload.firstYear)) {
            this.moveCopyPayload.firstYear = this.years[0];
        }
    }

    async getSitesByCompanyId(companyId: number): Promise<void> {
        //need to determine if user has selected an instance from the copy site/parcel instance selector or not
        //if so then we use a different URI
        let sites;

        if (this.targetInstanceId !== this._userInstanceService.getSelectedInstance().instanceId) {
            sites = await lastValueFrom(this._siteService.getSiteListByCompanyIdOtherInstance(Number(companyId), this.excludeInactive));
        } else {
            sites = await lastValueFrom(this._siteService.getSiteListByCompanyId(Number(companyId), this.excludeInactive));
        }

        this.sites = _.sortBy(sites, 'siteName');

        const states = await this._stateService.getSummary();
        this.states = this._getFilteredStates(states);
    }

    stateSelected(isTarget?: boolean): void {
        this.stateSites = this._getFilteredSites(isTarget);

        if (this.stateSites.length) {
            if (isTarget) {
                this.moveCopyPayload.destEntityID = this.stateSites[0].siteID;
            } else {
                this.sourceIds = [this.stateSites[0].siteID];
            }
        }
    }

    selectedEntitiesChanged(eventTarget: EventTarget): void {
        const options = (eventTarget as HTMLSelectElement).selectedOptions;
        this.sourceIds = _.map(options, option => Number(option.value));
    }

    async movingSitesSelected(): Promise<void> {
        this.loading = true;

        try {
            this.moveCopyPayload = new MoveCopyPayload(this.entities.SITE, this.isCopy ? 2 : 1, this.moveCopyPayload ? this.moveCopyPayload.firstYear : null);

            if (this.targetCompany) {
                this.moveCopyPayload.destEntityID = this.targetCompany.companyID;
            }

            await this.getSitesByCompanyId(+this.companyId);
            this.selectedStateId = _.find(this.sites, { siteID: Number(this.siteId) }).stateID;
            this.stateSelected();
            this.sourceIds = [Number(this.siteId)];
        } finally {
            this.loading = false;
        }
    }

    async movingParcelsSelected(): Promise<void> {
        this.loading = true;

        try {
            this.moveCopyPayload = new MoveCopyPayload(this.entities.PARCEL, this.isCopy ? 2 : 1, this.moveCopyPayload ? this.moveCopyPayload.firstYear : null);

            this.siteName = await lastValueFrom(this._siteService.getSiteName(Number(this.siteId)));

            const parcels = await this._parcelService.getParcelListBySiteId(Number(this.siteId), this.excludeInactive);
            this.allParcels = _.cloneDeep(parcels);
            this.parcels = _.sortBy(parcels, 'parcelName');

            if (this.parcels.length) {
                if (this.launchingEntity === this.entities.PARCEL) {
                    this.parcels = _.filter(this.parcels, { parcelID: Number(this.parcelId) });
                    this.sourceIds = [Number(this.parcelId)];
                } else {
                    this.sourceIds = [this.parcels[0].parcelID];
                }
            }

            if (this.targetCompany) {
                await this.getSitesByCompanyId(this.targetCompany.companyID);
                this.selectedStateId = 0;
                this.stateSelected(true);
            }
        } finally {
            this.loading = false;
        }
    }

    async targetCompanySelected(e: TypeaheadCompany): Promise<void> {
        if (!e || _.isEmpty(e)) {
            return;
        }

        this.targetCompany = e;

        if (this.moveCopyPayload.sourceEntityTypeID === this.entities.SITE) {
            this.moveCopyPayload.destEntityID = this.targetCompany.companyID;
        } else {
            this.loadingSites = true;

            try {
                await this.getSitesByCompanyId(this.targetCompany.companyID);
                this.selectedStateId = 0;
                this.stateSelected(true);
            } finally {
                this.loadingSites = false;
            }
        }
    }

    getEntityName(id: number): string {
        if (!id) {
            return '';
        }

        if (this.moveCopyPayload.sourceEntityTypeID === this.entities.SITE) {
            return this.getSiteNameWithNumber(_.find(this.stateSites, { siteID: id }));
        } else {
            const foundParcel = _.find(this.parcels, { parcelID: id }) as ParcelSummary;
            return foundParcel.parcelName;
        }
    }

    getTargetSiteName(id: number): string {
        return _.find(this.stateSites, { siteID: Number(id) }).siteName;
    }

    async executeMoveCopy(): Promise<void> {
        await this._executeMoveCopy(false);
    }

    cancelExecute() {
        this.isExecuting = false;
        this.userCancelled = true;
    }

    moveCopyCompleted(): void {
        this.isExecuting = false;

        if (this.isCopy) {
            return;
        }

        if (this.moveCopyPayload.sourceEntityTypeID === this.entities.SITE) {
            if (this.launchingEntity === this.entities.SITE) {
                this._timer.setTimeout(() => {
                    this._upgradeNavigationServiceHandler.transitionTo('company', { companyId: this.companyId });
                }, 1000);

                return;
            }

            this.sites = _.reject(this.sites, (site: SiteSummary) => _.includes(this.sourceIds, site.siteID));

            if (this.sites.length) {
                this.states = this._getFilteredStates(this.states);

                if (!_.includes(_.map(this.states, 'stateID'), this.selectedStateId)) {
                    this.selectedStateId = 0;
                }

                this.stateSelected();
            } else {
                this._upgradeNavigationServiceHandler.transitionTo('company', { companyId: this.companyId });
            }

        } else {
            // Remove To and From sites from session storage so that activity panel loads fresh data
            this._activityService.clearParcelFilterCacheForSite(+this.siteId);
            this._activityService.clearParcelFilterCacheForSite(this.moveCopyPayload.destEntityID);

            this.parcels = _.reject(this.parcels, parcel => _.includes(this.sourceIds, parcel.parcelID));

            if (!this.parcels.length) {

                this._upgradeNavigationServiceHandler.transitionTo('site', { companyId: this.companyId, siteId: this.siteId });
            }
        }

        this.sourceIds = [];
    }

    backToEntity(): void {
        switch (this.launchingEntity) {
            case this.entities.COMPANY:
                this._upgradeNavigationServiceHandler.transitionTo('company', { companyId: this.companyId });
                break;

            case this.entities.SITE:
                this._upgradeNavigationServiceHandler.transitionTo('site', { companyId: this.companyId, siteId: this.siteId });
                break;

            case this.entities.PARCEL:
                this._upgradeNavigationServiceHandler.transitionTo('parcel', { companyId: this.companyId, siteId: this.siteId, parcelId: this.parcelId });
                break;
        }
    }

    selectAll(entity: string): void {
        this.sourceIds = entity === 'sites' ? _.map(this.stateSites, 'siteID') : _.map(this.parcels, 'parcelID');
    }

    async siteInactiveStatusChanged(): Promise<void> {
        await this._setupEntity();
    }

    showSiteNumberStatusChanged(): void {
        this.stateSites.sort((s1, s2) => this.getSiteNameWithNumber(s1) < this.getSiteNameWithNumber(s2) ? -1 : 1);
    }

    async parcelInactiveStatusChanged(): Promise<void> {
        await this.movingParcelsSelected();
    }

    private _getFilteredStates(states: StateSummary[]): StateSummary[] {
        const siteStateIds = _.chain(this.sites)
            .map('stateID')
            .uniq()
            .value();

        return _.filter(states, (state: StateSummary) => _.includes(siteStateIds, state.stateID));
    }

    private _getFilteredSites(isTarget?: boolean): SiteSummary[] {
        if (Number(this.selectedStateId)) {
            if (this.launchingEntity === this.entities.SITE && !isTarget) {
                const launchingSite = _.find(this.sites, { siteID: Number(this.siteId) });

                return [launchingSite];
            } else {
                return _.chain(this.sites)
                    .cloneDeep()
                    .filter({ stateID: Number(this.selectedStateId) })
                    .value();
            }

        } else {
            return _.cloneDeep(this.sites);
        }
    }

    private _getYears(): Promise<number[]> {
        if (this.launchingEntity === this.entities.COMPANY) {
            const currentYear = new Date().getFullYear();

            return Promise.resolve(_.range(currentYear - 15, currentYear + 5));
        } else if (this.moveCopyPayload.sourceEntityTypeID === this.entities.SITE) {
            return this._moveCopyEntityService.getSiteYears(Number(this.siteId));
        } else {
            return this._parcelService.getParcelYears(Number(this.parcelId));
        }
    }

    private _areValidIds(args: string[]): boolean {
        return _.every(args, (arg: string) => !_.isNaN(Number(arg)));
    }

    private _goToHome(): void {
        console.warn('Invalid Id');

        this._upgradeNavigationServiceHandler.transitionTo('home', {});
    }

    private async _setupCompany(): Promise<void> {
        this.loading = true;

        try {
            this.moveCopyPayload = new MoveCopyPayload(this.entities.SITE, this.isCopy ? 2 : 1, this.moveCopyPayload ? this.moveCopyPayload.firstYear : null);
            await this.getSitesByCompanyId(+this.companyId);
            this.selectedStateId = 0;
            this.stateSelected();

            await this._getBeginningYears();
        } finally {
            this.loading = false;
        }
    }

    private async _setupEntity(): Promise<void> {
        switch (this.launchingEntity) {
            case this.entities.COMPANY:
                await this._setupCompany();
                break;
            case this.entities.SITE:
                await this.movingSitesSelected();
                break;
            case this.entities.PARCEL:
                await this.movingParcelsSelected();
                break;
        }
    }

    private getSiteNameWithNumber(site: { siteNumber: string, siteName: string }): string {
        if (this.showSiteNumber) {
            return site.siteNumber ? `${site.siteNumber} - ${site.siteName}` : site.siteName;
        } else {
            return site.siteName;
        }
    }

    private async _executeMoveCopy(force: boolean): Promise<void> {
        if (this.userCancelled) {
            this.userCancelled = false;
            return;
        }

        if (this.sourceIdIdx >= this.sourceIds.length) {
            return;
        }

        this.awaitingConfirm = false;
        this.isExecuting = true;

        for (let i = 0; i < this.sourceIds.length; i++) {
            this.moveCopyPayload.sourceIDs[i] = this.sourceIds[i];
            this.sourceIdIdx++;
        }

        this.moveCopyPayload.interInstanceCopy = this.targetInstanceId !== this._userInstanceService.getSelectedInstance().instanceId;
        this.moveCopyPayload.ignorePendingReturnError = force;

        try {
            await this._moveCopyEntityService.copyMoveEntity(this.moveCopyPayload);
            await this.executeMoveCopy();
        } catch (error) {
            if (error.status === 422) {
                try {
                    await this._messageModalService.confirm(error.data.message, 'Warning');
                } catch {
                    this.isExecuting = false;

                    return Promise.resolve();
                }
                this.sourceIdIdx = 0;
                await this._executeMoveCopy(true);
            } else {
                this.cancelExecute();
                await this._executeMoveCopy(false);
            }
        } finally {

            this.sourceIdIdx = 0;
            this.moveCopyCompleted();
        }
    }
}
