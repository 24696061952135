<slot>
    <ng-container *ngFor="let element of buttons; let first = first; let last = last">
        <ws-ace-button [class.buttongroup-first]="first"
                    [class.buttongroup-last]="last"
                    [content]="element?.content"
                    [type]="element?.type"
                    [buttonStyle]="element?.style"
                    [state]="element?.state"
                    [size]="element?.size"
                    [isDisabled]="element?.isDisabled"
                    [icon]="element?.icon"
                    [iconFont]="element?.iconFont"
                    [routerOutlet]="element?.routerOutlet"
                    [queryParams]="element?.queryParams"
                    (buttonClick)="onButtonClicked(element)"
                    grouped>
        </ws-ace-button>
    </ng-container>
</slot>
