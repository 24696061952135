<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper [headerLabel]="masterParcelAcctNum ? 'Parcels Linked to ' + masterParcelAcctNum : 'Link Parcels'"
                  [cancelLabel]="(editMode) ? 'Cancel' : 'Close'"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="(!masterParcel) || (!selectedLinkageType)">
    <div class="app-ui-view ws-flex-container-vertical ws-flex-auto ws-stretch">
        <div class="ws-flex-none" *ngIf="editMode">
            <button type="button" class="flat-button small-button warning-button float-end" (click)="removeAll()" title="Remove all parcel links" [disabled]="masterParcel && parcels && parcels.length == 1">Remove All Links</button>
            <button type="button" class="flat-button small-button primary-button float-end" (click)="add()" title="Add parcel links" #focus>Add Parcel Links</button>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none linkage-type-row">
            <div class="ws-flex-container-horizontal ws-flex-none ws-flex-align-items-center">
                <label class="ws-flex-none ws-no-margin ws-no-padding" for="selectedLinkageType">Linkage:</label>
                <select name="selectedLinkageType"
                        id="selectedLinkageType"
                        class="form-select form-select-sm"
                        [(ngModel)]="selectedLinkageType"
                        *ngIf="editMode">
                    <option *ngFor="let lt of params.linkageTypes" [ngValue]="lt" [title]="lt.description">{{ lt.type }}</option>
                </select>
                <div *ngIf="!editMode">{{ selectedLinkageType?.type }}</div>
            </div>
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <div class="linkage-type-description ws-overflow-truncate" [title]="selectedLinkageType?.description">{{ selectedLinkageType?.description }}</div>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [companyId]="companyId"
                            [gridId]="gridId"
                            [isBulkDeleteVisible$]="isBulkDeleteAvailable"
                            (bulkDelete)="bulkDelete()">
        </ws-ag-grid-angular>
        <div class="ws-flex-none ws-flex-container-vertical" *ngIf="editMode">
                <div class="text-danger" *ngIf="parcels && !masterParcel">Master parcel is required.</div>
                <div class="text-danger" *ngIf="parcels && !selectedLinkageType">Linkage is required.</div>
            </div>
        </div>
</ws-modal-wrapper>
