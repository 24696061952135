<table
    class="table">
    <thead>
        <th *ngFor="let col of input.columns">{{ col.headerName }}</th>
    </thead>
    <tbody>
        <tr *ngFor="let row of input.rows">
            <ws-ace-simple-table-cell
                *ngFor="let col of input.columns"
                [row]="row"
                [col]="col"></ws-ace-simple-table-cell>
        </tr>
    </tbody>
    <tfoot *ngIf="input.footerRows?.length">
        <tr *ngFor="let row of input.footerRows">
            <ws-ace-simple-table-cell
                *ngFor="let col of input.columns"
                [row]="row"
                [col]="col"></ws-ace-simple-table-cell>
        </tr>
    </tfoot>
</table>
