<ws-ace-dropdown *ngIf="instances.length > 1"
                 class="d-inline"
                 position="end"
                 [content]="selectedInstance.name">
    <ng-container *ngFor="let instance of instances">
        <button type="button" class="option"
                [class.disabled]="instance.inactive"
                (click)="instanceChanged(instance)">
            <span class="content">{{ instance.name }}</span>
        </button>
    </ng-container>
</ws-ace-dropdown>
