import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import * as _ from 'lodash';
import { AssessmentClassUI } from '../List/assessmentClassListPanel.component';
import { AssessmentClassService } from '../assessmentClass.service';
import { PropertyTypeService } from '../../Common/Services/propertyType.service.upgrade';
import { filter, uniqBy, map, sortBy, reject, orderBy, difference } from 'lodash/fp';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { ToastrService } from 'ngx-toastr';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';

export interface AssessmentClassModalParams {
    assessmentClass: AssessmentClassUI;
    state: Weissman.Model.Misc.State;
    assessor: Core.AssessorModel;
}

interface ACDefault {
    propertyType: Weissman.Model.Assessments.PropertyType;
    propertyTypeID: number;
    isDefault: boolean;
}

interface PropTypeComponentCombo {
    name: string;
    assessmentComponent: Weissman.Model.Assessments.AssessmentComponent;
    propertyType: Weissman.Model.Assessments.PropertyType;
    sequence: number;
}

interface EfActionEntity {
    efAction: string;
}

@Component({
    selector: 'assessment-class-modal',
    templateUrl: './assessmentClassModal.component.html',
    styles: [`
        .panel-flat-header, .table {
            margin-bottom: 0;
        }

        .panel-flat-body {
            min-height: initial;
            max-height: 300px
        }

        td, th {
            text-align: center;
        }

        .help-block {
            display: inline-block;
        }
    `]
})
export class AssessmentClassModalComponent implements OnInit, IWeissmanModalComponent<AssessmentClassModalParams, boolean> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _assessmentClassService: AssessmentClassService,
        private readonly _propertyTypeService: PropertyTypeService,
        private readonly _toastr: ToastrService,
        private readonly _messageModalService: MessageModalService,
        private readonly _restrictService: RestrictService
    ) { }
    params: AssessmentClassModalParams;
    result: boolean;

    loading: boolean = false;
    headerLabel: string;
    ac: AssessmentClassUI;
    availableDefaults: ACDefault[] = [];
    availableCombos: PropTypeComponentCombo[] = [];
    hasEditPermission: boolean = false;
    chosenComponent: PropTypeComponentCombo;
    yearToAdd: number;

    private _availableComponents: Weissman.Model.Assessments.AssessmentComponent[];
    private _availablePropertyTypes: Weissman.Model.Assessments.PropertyType[];
    private _ratioYears: number[];
    private _currentYear: number;

    get readOnly(): boolean {
        return !(this.hasEditPermission && !this.params.assessor);
    }

    get readOnlyRatio(): boolean {
        return !(this.hasEditPermission && (!this.params.assessor || this.ac.ratioSetAtAssessor));
    }

    get undeletedComponents(): Weissman.Model.Assessments.AssessmentClassComponent[] {
        return _.reject(this.ac.assessmentClassComponents, {efAction: 'delete'});
    }

    get undeletedDefaults(): Weissman.Model.Assessments.AssessmentClassDefault[] {
        return _.reject(this.ac.assessmentClassDefaults, {efAction: 'delete'});
    }

    get undeletedRatios(): Weissman.Model.Assessments.AssessmentClassRatio[] {
        return _.flow([
            reject({efAction: 'delete'}),
            orderBy(['taxYear'], ['desc'])
        ])(this.ac.assessmentClassRatios);
    }

    get availableYears(): any {
        return _.flow([
            map('taxYear'),
            difference(this._ratioYears)
        ])(this.ac.assessmentClassRatios);
    }

    async ngOnInit() {
        this.ac = this.params.assessmentClass;
        this.headerLabel = `Assessment Class for ${this.params.state.fullName}`;
        this.hasEditPermission = this._restrictService.isInRole(Roles.STATEEDIT);
        this._currentYear = new Date().getFullYear();
        this._ratioYears = _.range(this._currentYear - 10, this._currentYear + 5).reverse();

        this.loading = true;
        try {
            this._availableComponents = await this._assessmentClassService.getAssessmentComponents();
            this._availablePropertyTypes = await this._propertyTypeService.get();
            this._initDefaults();
            this._getAvailableCombos();

        } finally {
            this.loading = false;
        }
    }

    updateEfAction(entity: EfActionEntity) {
        entity.efAction = entity.efAction || 'update';
    }

    removeEntity(entityArray: EfActionEntity[], entity: EfActionEntity): void {
        if (entity.efAction === 'add') {
            _.remove(entityArray, entity);
        } else {
            entity.efAction = 'delete';
        }
    }

    toggleDefault(availableDefault: ACDefault) {
        if(availableDefault.isDefault) {
            const acDefault = {
                propertyTypeID: availableDefault.propertyTypeID,
                efAction: 'add',
                assessmentClassID: this.ac.assessmentClassID
            } as Weissman.Model.Assessments.AssessmentClassDefault;

            this.ac.assessmentClassDefaults.push(acDefault);
        } else {
            this._deleteDefault(availableDefault.propertyTypeID);
        }
    }

    deleteComponent(component: Weissman.Model.Assessments.AssessmentClassComponent) {
        if(component.inUse) {
            this._toastr.error('Component is in use.', 'Error');
        } else {
            this.removeEntity(this.ac.assessmentClassComponents, component);
            this._getAvailableCombos();
            this._initDefaults();
        }
    }

    addComponent() {
        const component = {
            propertyTypeID: this.chosenComponent.propertyType.propertyTypeID,
            assessmentComponentID: this.chosenComponent.assessmentComponent.assessmentComponentID,
            propertyType: this.chosenComponent.propertyType,
            assessmentComponent: this.chosenComponent.assessmentComponent,
            enabled: true,
            inUse: false,
            efAction: 'add',
            assessmentClassID: this.ac.assessmentClassID || undefined
        } as Weissman.Model.Assessments.AssessmentClassComponent;

        this.ac.assessmentClassComponents.push(component);
        this._getAvailableCombos();
        this._initDefaults();

        setTimeout(() => this.chosenComponent = undefined);
    }

    onEnabledChange(component: Weissman.Model.Assessments.AssessmentClassComponent): void {
        this.updateEfAction(component);
        this._initDefaults();
    }

    onStateWideRatioChange() {
        this.ac.ratioSetAtAssessor = !this.ac.ratioSetAtAssessor;
        this.updateEfAction(this.ac);
    }

    onFixedRatioChange() {
        if(this.ac.fixedRatio) {
            const latest = _.maxBy(this.undeletedRatios, 'taxYear');

            const fixed = {
                assessorID: this.params.assessor ? this.params.assessor.assessorID : null,
                assessmentRatio: latest ? latest.assessmentRatio || 1 : 1,
                taxYear: null,
                efAction: 'add'
            } as Weissman.Model.Assessments.AssessmentClassRatio;

            if (this.ac.assessmentClassID) {
                fixed.assessmentClassID = this.ac.assessmentClassID;
            }

            this.ac.assessmentClassRatios = _.flow([
                reject({efAction: 'add'}),
                map(x => {
                    x.efAction = 'delete';
                    return x;
                })
            ])(this.ac.assessmentClassRatios);

            this.ac.assessmentClassRatios.push(fixed);

        } else {
            const firstRatio = this.undeletedRatios[0];
            firstRatio.taxYear = this._currentYear;
            this.updateEfAction(firstRatio);
        }
    }

    addRatio(): void {
        const ratio = {
            assessorID: this.params.assessor ? this.params.assessor.assessorID : null,
            assessmentRatio: 0,
            taxYear: this.yearToAdd,
            efAction: 'add'
        } as Weissman.Model.Assessments.AssessmentClassRatio;

        if (this.ac.assessmentClassID !== undefined) {
            ratio.assessmentClassID = this.ac.assessmentClassID;
        }
        this.ac.assessmentClassRatios.push(ratio);

        setTimeout(() => {
            this.yearToAdd = undefined;
        });
    }

    async save(){
        const acToSave = _.omit(this.ac, 'propTypes', 'fixedRatio');

        if(!this.undeletedRatios.length) {
            this._toastr.error('Please enter at least one ratio.');

            return;
        }

        this.loading = true;
        try {
            const parcelsAffectedPromises = _.flow([
                filter({efAction: 'update'}),
                map(x => this._assessmentClassService.getParcelsAffectedByRatio(x.assessmentClassRatioID))
            ])(acToSave.assessmentClassRatios);

            const parcelsAffectedArr = await Promise.all(parcelsAffectedPromises);
            const parcelsAffected = _.sum(parcelsAffectedArr);

            if(parcelsAffected > 0) {
                try {
                    const message = `${parcelsAffected} parcels will be affected by changing this assessment class's ratio(s)`;
                    await this._messageModalService.confirm(message, 'Save Assessment Class?');
                } catch(e) {
                    return Promise.resolve();
                }
            }

            if(acToSave.assessmentClassID) {
                await this._assessmentClassService.update(acToSave as Weissman.Model.Assessments.AssessmentClass);
            } else {
                await this._assessmentClassService.create(acToSave as Weissman.Model.Assessments.AssessmentClass);
            }

            this.result = true;
            this.close();
        } finally {
            this.loading = false;
        }
    }

    close() {
        this._bsModalRef.hide();
    }

    private _initDefaults(): void {
        const newAvailableDefaults = _.flow([
            filter('enabled'),
            uniqBy('propertyTypeID'),
            map((x: Weissman.Model.Assessments.AssessmentClassComponent) => {
                return {
                    propertyType: x.propertyType,
                    propertyTypeID: x.propertyType.propertyTypeID,
                    isDefault: _.some(this.undeletedDefaults, {propertyTypeID: x.propertyTypeID})
                };
            }),
            sortBy(x => x.propertyType.sequence)
        ])(this.undeletedComponents) as ACDefault[];

        const diff = _.differenceBy(this.availableDefaults, newAvailableDefaults, 'propertyTypeID');

        if(diff.length) {
            this._deleteDefault(diff[0].propertyTypeID);
        }

        this.availableDefaults = newAvailableDefaults;
    }

    private _deleteDefault(propertyTypeId: number) {
        const acDefault = _.find(this.ac.assessmentClassDefaults, {propertyTypeID: propertyTypeId});

        if(acDefault) {
            this.removeEntity(this.ac.assessmentClassDefaults, acDefault);
        }
    }

    private _getAvailableCombos(): void {
        const availableCombos = [];

        _.forEach(this._availableComponents, x => {
            _.forEach(this._availablePropertyTypes, y => {
                const comboAlreadyAdded = _.some(this.undeletedComponents, {
                    assessmentComponentID: x.assessmentComponentID,
                    propertyTypeID: y.propertyTypeID
                });

                if(!comboAlreadyAdded) {
                    availableCombos.push({
                        name: `${y.propTypeAbbr} ${x.componentName}`,
                        assessmentComponent: x,
                        propertyType: y,
                        sequence: y.sequence
                    });
                }
            });
        });

        this.availableCombos = _.sortBy(availableCombos, ['sequence', 'name']);
    }
}
