<ws-modal-wrapper [headerLabel]="title"
                  (save)="applyAll()"
                  (cancel)="cancel()"
                  acceptLabel="Apply All"
                  [cancelLabel]="params.disableApplyAll ? 'Close' : 'Cancel'"
                  [disableSave]="params.disableApplyAll"
                  [useAceStyles]="true">
    <div class="card-container overflow-auto">
        <div class="d-flex flex-column">
            <div *ngIf="billCard">
                <idp-detail-card [detail]="billCard"></idp-detail-card>
            </div>
            <div *ngIf="payments" class="d-flex flex-wrap">
                <idp-detail-card *ngFor="let payment of payments"
                                 [detail]="payment">
                </idp-detail-card>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
