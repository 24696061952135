import { Component, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { EntityImportColumnMappingStepService } from './columnMappingStep.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ag-grid-mapping-header-row-cell-renderer',
    template: '<div class="header-cell" [ngClass]="headerClass">{{ headerName }} <button type="button" class="btn btn-link btn-sm unassign-button" *ngIf="mappingMaintenanceAllowed" (click)="unAssignField()">X</button></div>'
})
export class AgGridMappingHeaderRowCellRendererComponent implements IHeaderAngularComp, OnDestroy {
    constructor(private _columnMappingStepService: EntityImportColumnMappingStepService) { }

    private _params: IHeaderParams;

    private _fieldAssignedSub: Subscription;

    private _enableMappingMaintenance: boolean;

    // the import file header that this column is associated with
    private _headerId: number;

    // the specification link for that field mapping
    private _specificationFieldId: number;

    private _refresh(): void {
        // collect information
        let isHeaderMappedToRequiredField: boolean = false;
        let isStaticColumn = false;
        let isUnknown: boolean = false;
        let fieldName: string = '';

        const isHeaderMapped = this._specificationFieldId > 0;

        if (isHeaderMapped) {
            const fieldMapping = this._columnMappingStepService.getFieldMappings().find((i) => i.importFileSpecificationFieldId === this._specificationFieldId);
            const field = this._columnMappingStepService.getFields().find((i) => i.importFieldId === fieldMapping.importFieldId);

            if (field) {
                isHeaderMappedToRequiredField = field.isMappingRequired;
                isStaticColumn = fieldMapping.isStatic;
                fieldName = field.displayName;
            } else {
                fieldName = 'Unknown field';
                isUnknown = true;
            }
        }

        // set properties
        this.mappingMaintenanceAllowed = this._enableMappingMaintenance && (isHeaderMapped || isStaticColumn);

        if (!this._headerId) {
             this.headerName = this._params.displayName;
        }
        else if (isHeaderMapped || isStaticColumn) {
            this.headerName = fieldName;
        } else {
             this.headerName = 'Unmapped';
        }

        this.headerClass['mapping-required'] = !isUnknown && (isHeaderMapped || isStaticColumn) && isHeaderMappedToRequiredField;
        this.headerClass['mapping-optional'] = !isUnknown && (isHeaderMapped || isStaticColumn) && !isHeaderMappedToRequiredField;
        this.headerClass['unmapped'] = !isUnknown && !(isHeaderMapped || isStaticColumn);
        this.headerClass['mapping-unknown'] = isUnknown;
    }

    mappingMaintenanceAllowed: boolean = false;

    headerName: string;

    headerClass = {
        'mapping-required': false,
        'mapping-optional': false,
        'unmapped': false,
        'mapping-unknown': false
    };

    agInit(params: IHeaderParams): void {
        this._params = params;

        let colDefMetadata: Compliance.EntityImportMappingColDefMetadata = params.column.getColDef()['ws'];
        this._enableMappingMaintenance = colDefMetadata.enableMappingMaintenance;
        this._headerId = colDefMetadata.fileHeaderId;

        // when trying to figure out if a field is mapped it can be one of the following:
        // 1. a header ID is available and we can determine the mapping via that
        // 2. a field is not mapped to a header in the file but is "mapped" as a "static" field
        let fieldMapping = colDefMetadata.isStatic ?
            this._columnMappingStepService.getFieldMappings().find(i => i.isStatic && i.importFieldId === colDefMetadata.staticFieldId) :
            this._columnMappingStepService.getFieldMappings().find(i => i.value === colDefMetadata.fileHeaderId.toString());

        this._specificationFieldId = fieldMapping && fieldMapping.importFileSpecificationFieldId;

        this._refresh();

        this._fieldAssignedSub = this._columnMappingStepService.fieldAssigned$.subscribe(fieldMapping => {
            if (!fieldMapping.isStatic && fieldMapping.value) {
                let header = this._columnMappingStepService.getFileHeaders().find(i => i.importFileHeaderId.toString() === fieldMapping.value);
                if (header.importFileHeaderId === this._headerId) {
                    this._specificationFieldId = fieldMapping.importFileSpecificationFieldId;
                    this._refresh();
                }
            }
        });
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
        this._fieldAssignedSub.unsubscribe();
    }

    async unAssignField(): Promise<void> {
        await this._columnMappingStepService.unAssignField(this._specificationFieldId);
        this._specificationFieldId = null;
        this._refresh();
    }
}
