import { Component, OnInit } from '@angular/core';
import { StateService } from '../Common/States/States.Service';
import { BreadCrumb } from '../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { BusyIndicatorService } from '../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';

export interface ContactPanelEntityData {
    id: number;
    type: string;
    newAllowed: boolean;
    name: string;
}

@Component({
    selector: 'us-state',
    templateUrl: './us.state.component.html',
})
export class USStateComponent implements OnInit {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _stateService: StateService
    ) {}

    breadcrumbs: BreadCrumb[] = [];
    state: Weissman.Model.Misc.State;
    hasContactRoles: boolean = false;
    entity: ContactPanelEntityData;

    async ngOnInit() {
        this.breadcrumbs = [
            {
                name: 'States',
                target: 'states',
                options: { }
            }
        ];

        this._loadState();
    }

    private async _loadState() {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const stateId = +this._upgradeNavigationServiceHandler.getQuerystringParam('stateId');
            this.state = await this._stateService.getById(stateId);

            // For angularjs contact panel
            this.entity = {
                id: this.state.stateID,
                type: 'state',
                newAllowed: false,
                name: this.state.fullName
            };
        }
        finally {
            busyRef.hide();
        }
    }
}
