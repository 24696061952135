<div>
	<loading-spinner *ngIf="loading"></loading-spinner>
	<h4 *ngIf="!assignedTeams.length && !loading"
		style="text-align: center; margin-top: 40px">No Teams to Display</h4>
	<div style="margin-top: 20px; min-height: 100px;"
		 *ngIf="!loading">
		<div>
			<div class="buttons text-end">
				<!-- button to show form -->
				<span *ngIf="!isEditMode && canEdit">
					<button type="button"
							class="btn flat-button icon-button primary-button"
							title="{{isLrpRunning ?
						'User team changes processing!' + '\n'+
						'Please wait for the current user team changes' + '\n' +
						'to be completed prior to modifying additional team assignments.':''}}"
							(click)="switchToEditMode()">
						<i class="fa fa-pencil"></i>
					</button>
				</span>
				<!-- buttons to submit / cancel form -->
				<span *ngIf="isEditMode">
					<button type="button"
							class="flat-button icon-button primary-button"
							[disabled]="isSaveDisabled"
							(click)="save()">
						<i class="fa fa-save"></i>
					</button>
					<button type="button"
							class="flat-button icon-button warning-button"
							(click)="cancel()">
						<i class="fa fa-close"></i>
					</button>
				</span>
			</div>
			<table style="margin-left: 30px; word-break: break-word; hyphen: auto;">
				<thead>
					<th style="width: 67px;">Default</th>
					<th style="width: 90px;">Assignable</th>
					<th style="min-width: 280px;">Team Name</th>
				</thead>
				<tbody>
					<ng-container *ngFor="let team of assignedTeams">
						<tr>
							<td>
								<input type="checkbox"
									   [(ngModel)]="team.isDefault"
									   (change)="updateDefault(team)"
									   [ngStyle]="{'pointer-events': team.isDefault ? 'none' : 'initial'}"
									   [disabled]="!isEditMode || team.loading || team.chooseNew"
									   style="margin-left: 18px;">
							</td>
							<td>
								<input type="checkbox"
									   [disabled]="!isEditMode || team.loading || team.chooseNew || team.isLocked"
									   [(ngModel)]="team.isAssignable"
									   style="margin-left: 20px;"
									   (change)="checkLinkedEntities(team)" />
							</td>
							<td style="width: 58%">
								<div style="display: inline-block; max-width: 82%">{{ team.teamName}}</div>
								<div class="commandButtons float-end"
									 [ngClass]="{'invisible': !isEditMode}">
									<button class="btn flat-button icon-button warning-button"
											type="button"
											[disabled]="team.isDefault || team.loading || team.chooseNew || team.isLocked || team.isAssignable"
											(click)="removeTeam(team)">
										<i class="fa fa-trash"></i>
									</button>
	
								</div>
								<div class="clearfix"></div>
							</td>
							<td>
								<loading-spinner [size]="1"
												 [marginTop]="0"
												 *ngIf="team.loading"></loading-spinner>
							</td>
						</tr>
						<tr *ngIf="team.chooseNew">
							<td colspan="4">
								<p class="lead">
									{{team.message}}
									<!--<em>{{team.teamName}}</em> has tasks assigned. Please choose another team to which they can be assigned.-->
								</p>
								<select class="form-select form-select-lg"
										style="width:auto; display: inline-block;"
										[(ngModel)]="newTeamForTasks"
										ng-options="option as option.teamName for option in assignedTeams | orderBy:'teamName' | filter:nonDeletedFilter | filter:{isAssignable: true}">
											<option *ngFor="let team of assignedTeamsToChoose" [ngValue]="team">{{team.teamName}}</option>
								</select>
								<button class="btn flat-button icon-button primary-button"
										style="margin-left: 10px;"
										[disabled]="!newTeamForTasks"
										type="button"
										(click)="chooseNewTeam(team)">
									<i class="fa fa-check"></i>
								</button>
								<button class="btn flat-button icon-button warning-button"
										type="button"
										(click)="cancelChooseNewTeam(team)">
									<i class="fa fa-close"></i>
								</button>
							</td>
						</tr>
					</ng-container>

				</tbody>
				<tfoot *ngIf="isEditMode">
					<tr>
						<td colspan="2">
							&nbsp;
						</td>
						<td class="text-sm">
							ADD NEW
						</td>

					</tr>
					<tr>
						<td colspan="2">
							&nbsp;
						</td>
						<td>
							<select class="form-select form-select-lg"
									style="width:auto; display: inline-block;margin-right:10px;"
									[(ngModel)]="newTeam"
									(change)="addTeam()">
								<option [ngValue]="undefined">Select</option>
								<option *ngFor="let team of allChoosableTeams" [ngValue]="team">{{team.name}}</option>
							</select>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>

	</div>
</div>