<div class="modal-header">
    <h4 class="modal-title">Set Document Retrieval Status</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body retrieval-status" style="margin-top: 20px; padding: 15px;">
    <form class="form-horizontal">
        <div class="mb-3 row">
            <label class="col-md-3 control-label">Retrieval Status:</label>
            <div class="col-md-9">
                <select
                    class="form-select form-select-sm"
                    name="retrievalStatusId"
                    [(ngModel)]="retrievalStatusPayload.retrievalStatusId"
                    (change)="recreateComment()">
                    <option *ngFor="let option of retrievalStatuses" [ngValue]="option.retrievalStatusId">{{option.name}}</option>
                </select>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-md-3 control-label" style="padding-top: 10px;">Follow Up:</label>
            <div class="col-md-9">
                <div class="radio">
                    <label>
                        <input
                            type="radio"
                            [(ngModel)]="followUpChoice"
                            [value]="followUpChoices.DAYS"
                            name="followUps"
                            style="margin-top: 9px;"
                            (change)="recreateComment()"
                        >
                        <div style="display: inline-block; vertical-align: middle;">
                            In
                        </div>
                        <select class="form-select form-select-sm" style="display: inline-block; width: auto; margin: 0 10px;" (change)="setComputedDataFromSelect($event.target); recreateComment()" [disabled]="followUpChoice !== followUpChoices.DAYS">
                            <option *ngFor="let option of daysOptions" [value]="option">{{option}}</option>
                        </select>
                            weekdays which is <weissman-datetime [(inputDate)]="computedDate" [dateOnly]="true" [readOnly]="true"></weissman-datetime>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" [(ngModel)]="followUpChoice" [value]="followUpChoices.DATE" name="followUps" style="margin-top: 9px;" (change)="recreateComment()">
                        <div style="display: inline-block; vertical-align: middle;">
                            On
                        </div>
                        <div style="width: 136px; display: inline-block; vertical-align: middle; margin: 0 5px;">
                            <weissman-datetime [(inputDate)]="selectedDate" [dateOnly]="true" [isDisabled]="followUpChoice !== followUpChoices.DATE" (change)="recreateComment()" ></weissman-datetime>
                        </div>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" [(ngModel)]="followUpChoice" [value]="followUpChoices.NO_FOLLOWUP" name="followUps" (change)="recreateComment()"> No Follow Up
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio" [(ngModel)]="followUpChoice" [value]="followUpChoices.NO_CHANGE" name="followUps" (change)="recreateComment()"> No Change
                    </label>
                </div>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-md-3 control-label">Re-Assign To:</label>
            <div class="col-md-9">
                <user-team-picker
                        [user]="retrievalStatusPayload.newAssignment"
                        (userChange)="setUser($event); recreateComment()"
                        [entityIdScope]="instanceId"
                        [entityTypeScope]="'Instance'">
                </user-team-picker>
            </div>
        </div>
        <div class="mb-3 row">
            <label class="col-md-3 control-label">Comment:</label>
            <div class="col-md-9">
                <textarea name="comment" [(ngModel)]="retrievalStatusPayload.comment" class="form-control" rows="5"></textarea>
            </div>
        </div>
        <!-- <div class="mb-3">
          <div class="offset-sm-3 col-md-9">
            <button type="submit" class="btn btn-default">Sign in</button>
          </div>
        </div> -->
    </form>
</div>
<div class="modal-footer">
    <div>
        <button class="flat-button primary-button" (click)="submit()" [disabled]="this.followUpChoice === this.followUpChoices.DATE && !this.selectedDate">Save</button>
        <button class="flat-button warning-button" (click)="closeModal()">Cancel</button>
    </div>
</div>
