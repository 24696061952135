import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { NavigationState, ViewTarget } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualBudgetService } from '../Budget/budget.details.service';
import { Assessment } from '../Assessments/assessment.model';
import { FilingType } from '../Compliance/compliance.model';
import { AddRevisionModal } from '../Modals/add-revision.component';
import { AnnualDetailAssessment, AnnualDetailYear } from './annual-year.model';

declare const _: any;

@Component({
    selector: 'annual-year',
    templateUrl: './annual-year.component.html'
})
export class AnnualYearComponent implements OnInit, OnChanges {
    constructor(
        public annualDetailsService: AnnualDetailsService,
        private annualBudgetService: AnnualBudgetService,
        private http: SDHttpService,
        private messageBox: MessageBoxService,
        private modalService: BsModalService
    ) { }

    @Input() navigationState: NavigationState;
    @Input() currentYear: AnnualDetailYear;
    @Input() currentAnnualDetailAssessment: AnnualDetailAssessment;
    @Input() componentTypes: any[];
    @Input() filingTypes: FilingType[];
    @Input() budgetsEnabled: boolean;
    @Input() consolidatingTypeId: Core.ConsolidatingTypeEnum;

    currentAssessment: Assessment;
    ViewTarget = ViewTarget;
    loadingImage: boolean = false;

    get notAllowedIfConsolidated() {
        return this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated ? 'not-allowed' : 'pointer';
    }

    ngOnInit() {
        //this.currentAnnualDetailAssessment = this.navigationState.currentRevision;

        //this.selectedChangeRevision = this.currentAnnualDetailAssessment;

        if(this.navigationState.viewTarget === this.ViewTarget.assessment) {
            this.currentAssessment = this.navigationState.currentAssessmentVM.model;
        }

    }

    ngOnChanges(changes: SimpleChanges): void{
        if (Object.keys(changes).indexOf('currentYear') >= 0) {
            const currentRevision = _.find(this.currentYear.annualGridDetails, { 'annualAssessmentID': this.currentAnnualDetailAssessment.annualAssessmentID });
            if(currentRevision) {
                this.currentAnnualDetailAssessment = currentRevision;
            }
        } /*else if(Object.keys(changes).indexOf('viewTarget') >= 0 ) {
            if(this.navigationState.viewTarget === this.ViewTarget.assessment) {
                this.currentAssessment = this.navigationState.currentAssessmentVM.model;
            }
        }*/

    }

    //change event for the revision dropdown
    selectRev(newRevision?: AnnualDetailAssessment): void {
        //this.currentAnnualDetailAssessment = this.selectedChangeRevision;
        //if(this.navigationState.viewTarget === ViewTarget.assessment) {
            // If this is an event, we'll get the new revision as an arguments before Angular actually changes
            // this.currentAnnualDetailAssessment, so in that case use the argument instead (WK-2563)
        this.navigationState.goToAssessment(this.currentYear, newRevision || this.currentAnnualDetailAssessment);
        //}
    }

    activateViewTarget(viewTarget: ViewTarget): void {
        if (this.navigationState.viewTarget == viewTarget) return;

        switch(viewTarget) {
            case ViewTarget.appeal:
                if (this.consolidatingTypeId != Core.ConsolidatingTypeEnum.Consolidated)
                    this.navigationState.goToAppeals(this.currentYear, this.currentAnnualDetailAssessment);
                break;
            case ViewTarget.assessment:
                if (this.consolidatingTypeId != Core.ConsolidatingTypeEnum.Consolidated)
                    this.navigationState.goToAssessment(this.currentYear, this.currentAnnualDetailAssessment);
                break;
            case ViewTarget.tax:
                if (this.consolidatingTypeId != Core.ConsolidatingTypeEnum.Consolidated)
                    this.navigationState.goToTaxes(this.currentYear);
                break;
            case ViewTarget.compliance:
                this.navigationState.goToCompliance(this.currentYear);
                break;
            case ViewTarget.budget:
                this.navigationState.goToBudget(this.currentYear, this.currentYear.latestBudgetId);
                break;
        }
    }

    activateAddRevision(): void {
        this.promptAutoSave('Creating a new revision with pending changes will force a save of the current revision. Do you wish to continue?').then(() => {
            const ref = this.modalService.show(AddRevisionModal, {
                class: 'modal-sm'
            });

            ref.content.initialize({
                currentAssessment: this.currentAssessment,
                currentYear: this.currentYear,
                navigationState: this.navigationState
            });

            const subscription = ref.content.assessmentCreatedEventEmitter.subscribe(
                // next
                async (assessment) => {
                    this.loadingImage = true;

                    try {
                        await this.createNewAssessment(assessment);
                    } finally {
                        this.loadingImage = false;
                    }
                },
                // error
                () => {},
                // completed
                () => {
                    subscription.unsubscribe();
                    ref.hide();
                }
            );
        }, () => {});
    }

    /////////////////////////////////
    // Formatting functions
    /////////////////////////////////
    //determines if we need the strikethrough on a cell
    hasStrikeThru(): string {
        let ngClass = '';

        switch (this.navigationState.viewTarget) {
            case ViewTarget.appeal:
                /*if ($scope.strikeThru.status) {
                    ngClass += 'strike-thru-status strike-thru-savings';
                } else if ($scope.strikeThru.savings) {
                    ngClass += 'strike-thru-savings'
                }*/

                break;
            case ViewTarget.assessment:
                if (this.currentAnnualDetailAssessment) {
                    //This model does not contain efAction, where does this come from?
                    /*if (this.currentAssessment.efAction !== null) {
                        ngClass += 'strike-thru-status';
                    }*/

                    //if (revisionEdited($scope.active.revision) && $scope.active.year.totalAppealCountForYear > 0) {
                    if(this.currentYear.totalAppealCountForYear > 0 &&
                        _.some(this.currentAnnualDetailAssessment.annualGridComponents, (component) => {
                            return component.efAction !== null;
                        })) {

                        ngClass += ' strike-thru-savings';
                    }
                }
                break;
        }

        return ngClass;
    }

    notActual(): boolean {
        const latestRevision: AnnualDetailAssessment = _.maxBy(this.navigationState.currentYear.annualGridDetails, 'revisionNum');

        if (latestRevision.status === 0 || this.activeNotActual(this.currentAnnualDetailAssessment, latestRevision)) {
            return true;
        }

        return false;
    }

    activeNotActual(rev: AnnualDetailAssessment, latestRevision: AnnualDetailAssessment): boolean {
        if (rev) {
            return rev.revisionNum >= latestRevision.revisionNum && rev.status === 0;
        } else {
            return false;
        }
    }

    getComplianceFilingType(complianceId: number): string {
        const filingType: FilingType = _.find(this.filingTypes, { filingTypeId: complianceId });
        return filingType ? filingType.name : '';
    }

    // Returns the not-actual class if at least one bill for that year has not been received.
    //TODO: We don't have taxes.tabs yet. Don't know where this comes from.
    notReceived(): boolean {
        //Grant said to make this like the annual year. This is what annual year does for the
        //not-actual coloring
        if(this.currentYear.taxIsEstimated) {
            return true;
        } else {
            return false;
        }
    }

    private createNewAssessment(createdAssessment): Promise<void> {
        return this.http.get(`/api/annualassessmentview/annualyear/${  this.currentYear.annualYearID}`).then((result: AnnualDetailYear) => {
            result = this.annualDetailsService.formatYearDateDisplay([result])[0];
            _.assign(this.currentYear, result);

            const createdAnnualDetailAssessment = _.find(result.annualGridDetails, {'annualAssessmentID': createdAssessment.annualAssessmentID});

            _.assign(this.currentAssessment, createdAnnualDetailAssessment);
            this.currentAnnualDetailAssessment = createdAnnualDetailAssessment;

            this.navigationState.refreshGrid = true;
            this.navigationState.goToAssessment(this.currentYear, createdAnnualDetailAssessment).then(() => {
                this.navigationState.setEditMode(true);
            });

        });
    }

    private promptAutoSave(message: string) : Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.navigationState.getDirty()) {
                return this.messageBox.open({
                    title: 'WARNING',
                    message: message,
                    buttons: MessageBoxButtons.YesNo
                }).then((result) => {
                    switch (result) {
                        case MessageBoxResult.Yes:
                            return this.navigationState.saveHandler().then(() => {
                                this.navigationState.setDirty(false);
                                resolve();
                            });

                        default:
                            reject();
                            break;
                    }
                });
            }

            resolve();
        });
    }
}
