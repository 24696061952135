<ng-container *ngIf="form">
    Requirements:
    <ul>
        <li>Cannot be the same as your User Name.</li>
        <li>Must be at least 8 characters long.</li>
        <li>Must contain at least one number, letter and special character.</li>
        <li>Cannot match your last 3 passwords.</li>
    </ul>
    <div *ngIf="showCurrent" class="mb-4">
        <label class="control-label col-md-2" for="current">
            Current:
        </label>
        <div class="col-md-10">
            <input type="password"
                   id="current"
                   name="current"
                   class="form-control"
                   [ngClass]="{ 'isInvalid': current.touched && current.invalid }"
                   placeholder="Current Password"
                   [formControl]="current"
                   required>
            <div *ngIf="current.touched && current.errors">
                <ul>
                    <li *ngIf="current.errors.minlength">This entry is too short.</li>
                    <li *ngIf="current.errors.required">An entry is required.</li>
                    <li *ngIf="current.errors.incorrect">Incorrect password entered.</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="mb-4">
        <label class="control-label col-md-2" for="new">
            New:
        </label>
        <div class="col-md-10">
            <input type="password"
                   id="new"
                   name="new"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': newPassword.touched && newPassword.invalid }"
                   placeholder="New Password"
                   [formControl]="newPassword"
                   required>

            <div *ngIf="newPassword.touched && newPassword.invalid">
                <ul>
                    <li *ngIf="newPassword.errors.minlength">This entry is too short.</li>
                    <li *ngIf="newPassword.errors.required">An entry is required.</li>
                    <li *ngIf="newPassword.errors.pattern">Does not have at least one special character and one number.</li>
                    <li *ngIf="newPassword.errors.sdDoesNotMatch">Password cannot be the same as your user name.</li>
                    <li *ngIf="newPassword.errors.alreadyUsed">Password has already been used.</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="mb-4">
        <label class="control-label col-md-2" for="confirm">
            Confirm:
        </label>
        <div class="col-md-10">
            <input type="password"
                   id="confirm"
                   name="confirm"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': confirm.touched && confirm.invalid }"
                   placeholder="Confirm Password"
                   [formControl]="confirm"
                   required>
            <div *ngIf="confirm.touched && confirm.invalid">
                <ul>
                    <li *ngIf="confirm.errors.sdMustMatch">Does not match new password.</li>
                    <li *ngIf="confirm.errors.required">An entry is required.</li>
                </ul>
            </div>
        </div>
    </div>
    <p>
        Note: Once you change your password you will be logged out.
    </p>
</ng-container>
