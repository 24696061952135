import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ParcelActivityModalService } from '../Entity/Parcel/Info/Activity/parcel-activity-modal.service.upgrade';
import { AnnualDetailsNavigationEventService, AppealNavigationInput, AssessmentNavigationInput, TaxNavigationInput } from './annual-details-event.service';
import { AnnualDetailsNavigationService, NavigationState, ViewTarget } from './annual-details-navigation.service';
import { AnnualDetailsService } from './annual-details.service';
import { AnnualDetailAssessment, AnnualDetailComponent, AnnualDetailYear, AnnualDetailYearList, AnnualGridYearsInfo } from './Annual-Year/annual-year.model';
import { AnnualAssessmentDetail, Assessment } from './Assessments/assessment.model';
import { AssessmentViewModel } from './Assessments/assessment.service';
import { FilingType, FilingTypeSummary } from './Compliance/compliance.model';
import { ComplianceService } from './Compliance/compliance.service';
import * as _ from 'lodash';
import { EntityType } from '../constants.new';
import { ForecastingBudgetingService } from '../Budget/forecasting.budgeting.service';
import { HelpService } from '../UI-Lib/Help-Tooltip';
import { ANNUAL_DETAILS_HELP } from './annual-details.component.help';
import { TimerService } from '../UI-Lib/Utilities/timer.service';

@Component({
    selector: 'annual-details',
    templateUrl: './annual-details.component.html'
})
export class AnnualDetailsComponent implements OnInit {
    @Input() parcelId: number;
    @Input() entityTypeId: number;
    @Input() siteId: number;
    @Input() stateId: number;
    @Input() companyId: number;
    @Input() topLevelCompanyId: number;
    @Input() returnPreparationAllowed: boolean;
    @Input() assessorId: number;
    @Input() componentTypes: any[];
    @Input() hasWritePermission: boolean;
    @Input() hasCompanyCollectors: boolean;
    @Input() hasComplianceResponsibility: boolean;
    @Input() parcelAcctNum: string;
    @Input() masterParcelId: number;
    @Input() parcelLinkageTypeId: number;
    @Input() consolidatingTypeId: number;
    @Input() consolidatedParcelId: number;
    @Input() propertyType: number;
    @Input() annualYearData: AnnualGridYearsInfo;
    @Output() maximizeChanged: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('aDValidation') aDValidation: TooltipDirective;

    components: AnnualDetailComponent[];
    filingTypes: FilingType[] = [];
    filings: FilingTypeSummary[] = [];
    years: AnnualDetailYear[];
    annualYearIDs: number[];
    maximize: boolean;
    navigationState: NavigationState;
    ViewTarget = ViewTarget;
    yearList: AnnualDetailYearList;
    vanillaYears: number[]; // For assessment-taxes-details modal
    totalYears: number = 0;
    annualDetailsSaveAllowed: boolean = true;
    budgetsEnabled: boolean = false;

    // *** Navigation functions ***
    // We originally didn't have these, prefering instead to do something like:
    //     [gotoGridHandler]="navigationState.goToGrid"
    // in the bindings; however we discovered that the "this" variable in the
    // goToGrid function isn't set to the navigationState in that case, causing
    // breakage. Using these shims in the component solves the problem.
    goToGrid: () => Promise<void>;
    goToAssessment: (year: AnnualDetailYear, revision: AnnualDetailAssessment) => Promise<AssessmentViewModel>;
    // *** End navigation functions ***
    constructor(
        private readonly annualDetailsService: AnnualDetailsService,
        private readonly annualDetailsNavigationService: AnnualDetailsNavigationService,
        private readonly complianceService: ComplianceService,
        private readonly messageBox: MessageBoxService,
        private readonly parcelActivityModalService: ParcelActivityModalService,
        private readonly navigationEvent: AnnualDetailsNavigationEventService,
        private readonly forecastingBudgetingService: ForecastingBudgetingService,
        private readonly _helpService: HelpService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        public toastr: ToastrService
    ) { }

    async ngOnInit() {
        this._helpService.setContent(ANNUAL_DETAILS_HELP);
        this.maximize = false;
        this.navigationState = null;
        //this.navigationEvent.resetAnnualDetails();

        this.complianceService.getFilingTypesByParcel(this.parcelId).then((data) => {
            this.filingTypes = data;
            // It's possible that we're loading the page with a pre-selected annual year that's outside the
            // first 10-year range; if that happens, we need to default to showing all years
            const preLoadAnnualYearID = +this.getParameterValue('annualYearID');
            this.getYearData(false, preLoadAnnualYearID, this.annualYearData).then(() => {
                this.checkNavigationParameters();
            });
        });

        this.goToGrid = () => {
            return this.navigationState.goToGrid();
        };
        this.goToAssessment = (year: AnnualDetailYear, revision: AnnualDetailAssessment) => {
            return this.navigationState.goToAssessment(year, revision);
        };

        this.navigationEvent.assessmentEvent$.forEach((event: AssessmentNavigationInput) => {
            this.ensureYearLoaded(event.annualYearID).then(hasYear => {
                if (hasYear) {
                    const year = _.find(this.years, { annualYearID: event.annualYearID });
                    this.navigationState.goToGrid().then(() => {
                        const assessment = _.find(year.annualGridDetails, { annualAssessmentID: event.annualAssessmentID });
                        this.navigationState.goToAssessment(year, assessment);
                    });
                }
            });
        });

        this.navigationEvent.appealEvent$.forEach((event: AppealNavigationInput) => {
            this.ensureYearLoaded(event.annualYearID).then(hasYear => {
                if (hasYear) {
                    const year = _.find(this.years, { annualYearID: event.annualYearID });
                    this.navigationState.goToGrid().then(() => {
                        const assessment = _.find(year.annualGridDetails, { annualAssessmentID: event.annualAssessmentID });
                        this.navigationState.goToAppeals(year, assessment, event.appealID);
                    });
                }
            });
        });

        this.navigationEvent.taxEvent$.forEach((event: TaxNavigationInput) => {
            this.ensureYearLoaded(event.annualYearID).then(hasYear => {
                if (hasYear) {
                    const year = _.find(this.years, { annualYearID: event.annualYearID });
                    if (year) {
                        this.navigationState.goToGrid().then(() => {
                            this.navigationState.goToTaxes(year, event.taxID);
                        });
                    }
                }
            });
        });

        this.navigationEvent.refundEvent$.forEach((event) => {
            this.ensureYearLoaded(event.annualYearID).then(hasYear => {
                if (hasYear) {
                    const year = _.find(this.years, { annualYearID: event.annualYearID });
                    if (year) {
                        this.navigationState.goToGrid().then(() => {
                            this.navigationState.goToTaxes(year, null, event.taxID);
                        });
                    }
                }
            });
        });

        this.navigationEvent.filingEvent$.forEach((event) => {
            this.ensureYearLoaded(event.annualYearID).then(hasYear => {
                if (hasYear) {
                    const year = _.find(this.years, { annualYearID: event.annualYearID });
                    if (year) {
                        this.navigationState.goToGrid().then(() => {
                            this.navigationState.goToCompliance(year, event.filingId);
                        });
                    }
                }
            });
        });

        this.navigationEvent.refreshAnnualYearEvent$.forEach((event) => {
            const year = this.navigationState.currentYear;

            if(!year) {
                return;
            }

            const currentRevision = this.navigationState.currentRevision;
            this.getYearData(false, year.annualYearID).then(() => {
                const newYearData: AnnualDetailYear = _.find(this.years, { annualYearID: year.annualYearID });
                this.navigationState.currentYear = newYearData;
                if(this.navigationState.currentAssessmentVM) {
                    this.navigationState.currentAssessmentVM.currentYear = newYearData;
                }
                this.navigationState.currentRevision = _.find(newYearData.annualGridDetails, { annualAssessmentID: currentRevision.annualAssessmentID });
            });

        });

        this.navigationEvent.goToGridEvent$.forEach((event) => {
            this.navigationState.refreshGrid = true;
            this.navigationState.goToGrid();
        });

        this.budgetsEnabled = await this.forecastingBudgetingService.getAreBudgetsEnabled(EntityType.Parcel, this.parcelId);
    }

    annualDetailsSaveAllowedChanged(isAnnualDetailsSaveAllowed: boolean): void {
        this.annualDetailsSaveAllowed = isAnnualDetailsSaveAllowed;
    }

    ensureYearLoaded(annualYearID: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!annualYearID || _.some(this.years, y => y.annualYearID === annualYearID)) {
                resolve(true);
            }
            else if (_.some(this.annualYearIDs, i => i === annualYearID)) {
                console.log(['Reloading annual years', this.years, annualYearID]);
                this.getYearData(true).then(() => {
                    resolve(true);
                });
            }
            else {
                resolve(false);
            }
        });
    }

    maximize_click(): void {
        this.maximize = true;
        this.maximizeChanged.emit(this.maximize);
    }

    unmaximize_click(): void {
        this.maximize = false;
        this.maximizeChanged.emit(this.maximize);
    }

    checkNavigationParameters(): void {
        //Read parameters
        //Find year to load from years, by ID. If none found, do nothing
        const annualYearParamID = +this.getParameterValue('annualYearID');
        if (annualYearParamID) {
            this._setNavigationBasedOnAnnualYearId(annualYearParamID);
        }

        const taxYearParam = +this.getParameterValue('taxYear');
        if (taxYearParam) {
            this._setNavigationBasedOnTaxYear(taxYearParam);
        }
    }

    async getYearData(getAllYears: boolean, searchForYear?: number, annualYearData?: AnnualGridYearsInfo): Promise<void> {
        const annualGridYearsInfo = annualYearData || await this.annualDetailsService.getAnnualYears(this.parcelId, getAllYears);

        this.annualYearIDs = annualGridYearsInfo.annualYearIDs;
        const callback = (annualGridYearsInfo) => {
            this.totalYears = annualGridYearsInfo.totalYears;
            this.components = this.annualDetailsService.getComponentsFromYears(annualGridYearsInfo.years);
            this.filings = this.getFilingTypesFromYears(annualGridYearsInfo.years);
            this.years = this.annualDetailsService.formatYearDateDisplay(annualGridYearsInfo.years);
            this.yearList = new AnnualDetailYearList(this.years);
            this.navigationEvent.setYears(this.yearList.years.map(y => y.annualYear1));
            this.vanillaYears = _.map(this.years, 'annualYear1');
            if (!this.navigationState) {
                this.navigationState = this.annualDetailsNavigationService.createNavigationState(annualGridYearsInfo.years, this.parcelId,
                    this.masterParcelId, this.parcelLinkageTypeId, this.consolidatingTypeId, this.consolidatedParcelId, this.parcelAcctNum, this.stateId, this.assessorId, this.hasWritePermission);
            }
            this.navigationState.entityLoading = false;
            this.navigationState.resetYearsHandler = () => {
                return this.complianceService.getFilingTypesByParcel(this.parcelId).then((data) => {
                    this.filingTypes = data;
                    return this.getYearData(false);
                })
            };
            this.navigationState.staleAppealSavings = false;
            this.navigationState.staleAppealStatus = false;
        };

        // We'll go get the annual year data, and in the very rare case where we've loaded the page with a link to a year that's
        // not in the first 10 years, repeat the call and get all years before continuing (WK-4700)
        if (searchForYear && !_.some(annualGridYearsInfo.years, y => y.annualYearID === searchForYear) &&
            _.some(this.annualYearIDs, i => i === searchForYear)) {
            //console.log(['Extending annual year search', annualGridYearsInfo.years, searchForYear]);
            return this.annualDetailsService.getAnnualYears(this.parcelId, true).then(callback);
        }

        return callback(annualGridYearsInfo);
    }

    getFilingTypesFromYears(years: AnnualDetailYear[]): FilingTypeSummary[] {
        return _.chain(years)
            .map('complianceFilingTypeSummaries')
            .flatten()
            .uniqBy('filingTypeId')
            .compact()
            .sortBy('filingTypeSequence')
            .value() as FilingTypeSummary[];
    }

    toggleEdit(): void {
        this.navigationState.setEditMode(!this.navigationState.editMode);
    }

    saveEdit(): void {
        this.navigationState.validationHandler((isValid, errorMessage) => {
            if (!isValid) {
                this.navigationState.validationMessage = errorMessage;

                // HACK need setTimeout for some reason as when the 'show'
                // is called the validationMessage is not set unless we have the timeout
                this._timer.setTimeout(() => {
                    this.aDValidation.show();
                });

                return;
            }

            this.navigationState.validationMessage = '';

            if (this.navigationState.refreshGrid) {
                this.navigationState.saveHandler().then((toastMessage) => {
                    if (toastMessage) {
                        this.toastr.info(toastMessage);
                    }

                    this.getYearData(false).then(() => {
                        this.navigationState.editMode = false;
                        this.navigationState.setDirty(false);
                        this.navigationState.refreshGrid = false;
                    });
                });
            } else {
                this.navigationState.saveHandler().then((toastMessage) => {
                    if (toastMessage) {
                        this.toastr.info(toastMessage);
                    }

                    this.navigationState.editMode = false;
                    this.navigationState.setDirty(false);
                }).catch(reason => {
                    this.navigationState.editMode = true;
                });
            }
        });
    }

    cancelEdit(): void {
        const performCancel = (): void => {
            this.navigationState.cancelHandler();
            this.navigationState.editMode = false;
            this.navigationState.setDirty(false);
        };
        if (this.navigationState.isDirty) {
            this.messageBox.open({
                title: 'WARNING',
                message: 'Are you sure you wish to cancel your changes?',
                buttons: MessageBoxButtons.YesNo
            }).then((result) => {
                if (result === MessageBoxResult.Yes) {
                    performCancel();
                }
            });
        }
        else {
            performCancel();
        }
    }

    getParameterValue(name: string): any {
        const path = window.location.href;
        const parsedName = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`), results = regex.exec(path);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    assessmentModelChange(changedAssessment: Assessment): void {
        if (this.navigationState.currentRevision.annualAssessmentID === changedAssessment.annualAssessmentID) {
            //If the name has changed of the
            this.navigationState.currentRevision.revisionDesc = changedAssessment.revisionDesc;

            //Update each component by hand
            changedAssessment.annualAssessmentDetails.forEach((component: AnnualAssessmentDetail, index: number) => {
                let changedComponent: AnnualDetailComponent = _.find(this.navigationState.currentRevision.annualGridComponents, { 'assessmentClassComponentID': component.assessmentClassComponentID });

                if (changedComponent) {
                    changedComponent.fairMarketValue = component.fairMarketValue;
                }

                if (component.efAction === 'add') {
                    //If there's no existing component in navigation state, add it
                    //This goes off of componentName because there are no IDs yet since it's not saved
                    if (!changedComponent && !_.some(this.navigationState.currentRevision.annualGridComponents, { 'componentName': component.componentName })) {
                        this.navigationState.currentRevision.annualGridComponents.push(new AnnualDetailComponent(component.componentName, component.fairMarketValue, component.assessmentComponentTypeID));
                    } else {
                        changedComponent = _.find(this.navigationState.currentRevision.annualGridComponents, { 'componentName': component.componentName });
                        changedComponent.fairMarketValue = component.fairMarketValue;
                    }
                }
            });

            //Delete components missing from the input
            //Using name here in case we are deleting an un-committed component (hence no ID)
            //Reverse for loop so we can safely splice out the "current" item without upsetting the index order
            for (let i = this.navigationState.currentRevision.annualGridComponents.length - 1; i >= 0; i--) {
                const componentExists = _.some(changedAssessment.annualAssessmentDetails, { 'componentName': this.navigationState.currentRevision.annualGridComponents[i].componentName });
                if (!componentExists) {
                    this.navigationState.currentRevision.annualGridComponents.splice(i, 1);
                }
            }
        }
    }

    openParcelActivityModal(): void {
        this._productAnalyticsService.logEvent('click-parcel-analysis');
        this.parcelActivityModalService.open(this.parcelId, this.siteId, _.map(this.years, 'annualYear1') as number[]);
    }

    private _setNavigationBasedOnAnnualYearId(annualYearID: number): void {
        const year = _.find(this.years, { annualYearID: annualYearID });

        if (!year) { return; }

        if (this.getParameterValue('annualAssessmentID')) {
            const revision = _.find(year.annualGridDetails, { annualAssessmentID: +this.getParameterValue('annualAssessmentID') });

            if (this.getParameterValue('appealID')) {
                const appealID = +this.getParameterValue('appealID');

                this.navigationState.goToAppeals(year, revision, appealID);
            } else {
                this.navigationState.goToAssessment(year, revision);
            }
        } else if (this.getParameterValue('billClusterID')) {
            const billClusterID = +this.getParameterValue('billClusterID');

            this.navigationState.goToTaxes(year, billClusterID);
        } else if (this.getParameterValue('refundID')) {
            const refundID = +this.getParameterValue('refundID');

            this.navigationState.goToTaxes(year, null, refundID);
        } else if (this.getParameterValue('filingId')) {
            const filingId = +this.getParameterValue('filingId');

            this.navigationState.goToCompliance(year, filingId);
        }
    }

    private _setNavigationBasedOnTaxYear(taxYear: number): void {
        const year = _.find(this.years, { annualYear1: taxYear });

        if (!year) { return; }

        this.navigationState.goToCompliance(year);
    }
}
