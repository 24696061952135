(function () {
	'use strict';

	var SERVICE_URL = '/api/taskActionView/';

	angular
		.module('weissmanApp')
		.factory('actionViewService', actionViewService);

	actionViewService.$inject = [
		'$q',
		'sd.Http.Service',
		'ActionViewCategoryTypes',
		'actionViewPersistenceService',
		'columnFilterService',
		'cacheManagerService',
		'SD.Restrict.Service',
		'TaskSeriesTypes',
		'$stateParams',
		'busyIndicatorService',
		'longRunningProcessRepository',
		'sd.Account.User.Service',
		'userInstanceService',
		'RyanInstanceId',
		'advancedSearchService',
		'sd.Account.User.Settings.Service',
        'snackBarService',
		'$uibModal'
	];

	function actionViewService($q, sdHttp, ActionViewCategoryTypes, actionViewPersistenceService, columnFilterService, cacheManagerService, restrictService, TaskSeriesTypes, $stateParams, busyIndicatorService, longRunningProcessRepository, userService, userInstanceService, RyanInstanceId, advancedSearchService, userSettingsService, snackBarService, $uibModal) {
		var SERVICE_URL_CUSTOM_ACTION_VIEW = '/api/taskactionview/custom/';
		var SERVICE_URL_SYSTEM_ACTION_VIEW = '/api/taskactionview/system/';
		var SERVICE_URL_CATEGORIES = '/api/taskactionview/category/';
		var SERVICE_URL_SYSTEM_CATEGORIES = '/api/taskactionview/category/system/';
		var SERVICE_URL_CUSTOM_CATEGORIES = '/api/taskactionview/category/custom/';
		var SERVICE_URL_FAVORITE = '/api/taskactionview/favorite/';
		var SERVICE_URL_FAVORITE_CHECK = '/api/taskactionview/system/hasfavorite/';
		var SERVICE_URL_FAVORITE_COUNT = '/api/taskactionview/system/favoritecount/';
		var SERVICE_URL_ACTION_VIEW_SHARE = '/api/taskactionview/share/';
		var SERVICE_URL_ACTION_VIEW_REVOKE_SHARE = '/api/taskactionview/revokeshare/';

		var cacheManager = cacheManagerService.createCacheManager();
		cacheManager.registerKeyedStaticCache('taskTypes', {
			cacheKey: 'taskTypes',
			endpoint: SERVICE_URL + 'taskTypes',
			cacheDisabled: false
		});

		var vm = this;

		return {
			deleteCustomActionView: deleteCustomActionView,
			deleteSystemActionView: deleteSystemActionView,
			patch: patch,
			getCategories: getCategories,
			deleteCategory: deleteCategory,
			createCategory: createCategory,
			changeCategory: changeCategory,
			reorder: reorder,
			toggleFavorite: toggleFavorite,
			hasFavorites: hasFavorites,
			getTaskTypes: getTaskTypes,
			getTasksAssignmentReadyOptions: getTasksAssignmentReadyOptions,
			mapThatAreChoiceToDisplayValue: mapThatAreChoiceToDisplayValue,
			getUrgencies: getUrgencies,
			createCustomActionView: createCustomActionView,
			createSystemActionView: createSystemActionView,
			updateCustomActionView: updateCustomActionView,
			updateSystemActionView: updateSystemActionView,
			getCustomActionViewById: getCustomActionViewById,
			getSystemActionViewById: getSystemActionViewById,
            executeActionView: executeActionView,
            fixAPISearchRequest: fixAPISearchRequest,
            processSearchResultPlusRequest: processSearchResultPlusRequest,
            welcomeSearch: welcomeSearch,
			executeActionViewToExcel: executeActionViewToExcel,
			cancelAvSearch: cancelAvSearch,
			prepareActionViewForExecution: prepareActionViewForExecution,
			prepareOutputColumn: prepareOutputColumn,
			sortTaskTypes: sortTaskTypes,
			getFavoriteCount: getFavoriteCount,
			revokeShare: revokeShare,
			share: share,
            getUrgencyColumnStyleClass: getUrgencyColumnStyleClass,
            getUrgencyChoiceByHeaderName: getUrgencyChoiceByHeaderName,
			launchCustomOutputModal: launchCustomOutputModal
		}

		function getCategories() {
			return sdHttp.get(SERVICE_URL_CATEGORIES);
		}

		function createCategory(category) {
			return sdHttp.post(SERVICE_URL_CATEGORIES, category);
		}

		function changeCategory(actionViewItem, categoryId, actionViewType) {
			var actionViewURL = '';

			if (actionViewType === ActionViewCategoryTypes.SYSTEM) {
				actionViewURL = SERVICE_URL_SYSTEM_ACTION_VIEW;
			} else if (actionViewType === ActionViewCategoryTypes.CUSTOM) {
				actionViewURL = SERVICE_URL_CUSTOM_ACTION_VIEW;
			}

			var categoryItem = {
				actionViewID: actionViewItem.actionViewID,
				categoryID: categoryId,
				rowVersion: actionViewItem.rowVersion
			};

			return sdHttp.patch(actionViewURL + actionViewItem.actionViewID, categoryItem)
				.then(function (result) {
					return result;
				});
		}

		function reorder(categoryList, actionViewType) {
		    if (actionViewType !== ActionViewCategoryTypes.CUSTOM) {
		        throw new Error('Invalid action view type for reordering (only allowed on custom searches)');
		    }
			return sdHttp.put(SERVICE_URL_CUSTOM_CATEGORIES + 'reorder', categoryList);
		}

		function deleteCustomActionView(actionView) {
			return sdHttp.deleteByEntity(SERVICE_URL_CUSTOM_ACTION_VIEW, actionView);
		}

		function deleteSystemActionView(actionView) {
			return sdHttp.deleteByEntity(SERVICE_URL_SYSTEM_ACTION_VIEW, actionView);
		}

		function updateCustomActionView(actionViewItem, filterColumns) {
			var saveActionViewItem = prepareActionViewForSave(actionViewItem, filterColumns, true);
			return sdHttp.put(SERVICE_URL_CUSTOM_ACTION_VIEW, actionViewItem);
		}

		function updateSystemActionView(actionViewItem, filterColumns) {
			var saveActionViewItem = prepareActionViewForSave(actionViewItem, filterColumns, true);
			return sdHttp.put(SERVICE_URL_SYSTEM_ACTION_VIEW, actionViewItem);
		}

		function patch(actionViewItem, actionViewType) {
			if (actionViewType === ActionViewCategoryTypes.SYSTEM) {
				return sdHttp.patch(SERVICE_URL_SYSTEM_ACTION_VIEW + actionViewItem.actionViewID, actionViewItem);
			} else if (actionViewType === ActionViewCategoryTypes.CUSTOM) {
				return sdHttp.patch(SERVICE_URL_CUSTOM_ACTION_VIEW + actionViewItem.actionViewID, actionViewItem);
			}
		}

		function deleteCategory(category) {
			return sdHttp.delete(SERVICE_URL_CATEGORIES + category.categoryId);
		}

		function createCustomActionView(actionViewItem, filterColumns) {
			var saveActionViewItem = prepareActionViewForSave(actionViewItem, filterColumns, false);
			return sdHttp.post(SERVICE_URL_CUSTOM_ACTION_VIEW, saveActionViewItem);
		}

		function createSystemActionView(actionViewItem, filterColumns) {
			var saveActionViewItem = prepareActionViewForSave(actionViewItem, filterColumns, false);
			return sdHttp.post(SERVICE_URL_SYSTEM_ACTION_VIEW, actionViewItem);
		}

		function toggleFavorite(actionViewId, toggleValue) {
			if (toggleValue) {
				return sdHttp.post(SERVICE_URL_FAVORITE, { actionViewID: actionViewId });
			} else {
				return sdHttp.delete(SERVICE_URL_FAVORITE + actionViewId);
			}
		}

		function hasFavorites(actionViewItem) {
			return sdHttp.get(SERVICE_URL_FAVORITE_CHECK + actionViewItem.actionViewID);
		}

		function getTaskTypes() {
			return $q(function (resolve, reject) {
				cacheManager.keyedStaticGet('taskTypes').then(function (taskTypes) {
					var isInRyanInstance = userInstanceService.getSelectedInstance().instanceId === RyanInstanceId;
					var hasRyanPrivate = restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSVIEW) || restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSEDIT);
					var hasInstancePrivatePermission = restrictService.hasAnyInstancePrivate();

					taskTypes.taskTypes = _.chain(taskTypes.taskTypes)
						.filter(x => isInRyanInstance && hasRyanPrivate || x.taskSeriesTypeID !== TaskSeriesTypes.CLIENTINVOICE) // Invoice
						.filter(x => hasInstancePrivatePermission || x.taskSeriesTypeID !== TaskSeriesTypes.DOCUMENTINTAKE) // Document Intake
						.value();

					resolve(taskTypes);

				}).catch(function () {
					reject.apply(this, arguments);
				});
			});
		}

		function getCustomActionViewById(id) {
			return sdHttp.get(SERVICE_URL_CUSTOM_ACTION_VIEW + id);
		}

		function getSystemActionViewById(id) {
			return sdHttp.get(SERVICE_URL_SYSTEM_ACTION_VIEW + id);
		}

		function executeActionView(filter) {
			vm.canceler = $q.defer();
			var isCancelled = false;
			vm.canceler.promise.then(function () { isCancelled = true; });

			filter.savedSearchID = $stateParams.actionViewId || null;
			return $q(function (resolve, reject) {
			    sdHttp.post(SERVICE_URL + 'search', filter, { timeout: vm.canceler.promise }).then(resolve, function () {
			        // If the user cancelled, we want code that called executeActionView to be able to detect that, so
                    // either reject with the string "Cancelled" or reject with the original http rejection otherwise
			        if (isCancelled) {
			            reject.call(this, "Cancelled");
			        }
			        else {
			            reject.apply(this, arguments);
			        }
			    });
			});
        }

        function fixAPISearchRequest(request) {
            // There's a mismatch in casing on these properties; all properties on UI objects should be camelCase, but for some reason
            // we CamelCased a few, and it's causing a mismatch. This would be an enormous effort to correct in-place, so for now when
            // the API sends a search filter down (not in a saved search because that's not touched by the API), correct the problem
            // manually. The TODO is to completely rewrite the Action View UI.
            var propsWithBadCasing = [
                'CalculateReadyAndNotReady',
                'CustomOutput',
                'DetailedResults',
                'ShowChoice',
                'WhereChoice',
                'ThatAreChoice',
                'ShowUserTeams',
                'ShowTeams',
                'ShowMostPressingTasks',
                'IncludeUnscheduled',
                'IncludeUserTeams',
                'IncludeTeams',
                'DateTimeChoice',
                'CompletedChoice',
                'DueWithinDays',
                'IncludeChoice',
                'OrganizeBy',
                'ComposeDynamicSql',
                'LastActivityTo',
                'LastActivityFrom',
                'EntityOutputColumns',
                'TaskOutputColumns'
            ];

            if (request.output === null) {
                request.output = 'default';
            }

            for (var i = 0; i < propsWithBadCasing.length; i++) {
                var newProp = propsWithBadCasing[i];
                var oldProp = newProp[0].toLowerCase() + newProp.substring(1);
                request[newProp] = request[oldProp];
                delete request[oldProp];
            }
        }

        function processSearchResultPlusRequest(searchResults) {
            var lookup = searchResults.columnReferenceLookup;
            // For each column, find the matching lookup and combine the properties of the object from lookup
            // and the column
            searchResults.originalRequest.entityOutputColumns = _.map(
                searchResults.originalRequest.entityOutputColumns,
                function (c) { return _.assign({}, lookup[c.id], c); }
            );
            searchResults.originalRequest.taskOutputColumns = _.map(
                searchResults.originalRequest.taskOutputColumns,
                function (c) { return _.assign({}, lookup[c.id], c); }
            );
            fixAPISearchRequest(searchResults.originalRequest);
        }

        function welcomeSearch(viewMode) {
            return sdHttp.get(SERVICE_URL + 'Welcome/' + viewMode.toString()).then(function (searchResults) {
                processSearchResultPlusRequest(searchResults);
                return searchResults;
            });
        }

		function executeActionViewToExcel(filter) {
			filter.outputInt = 1;
			filter.outputFormat = 1;
			filter.outputType = 1;

			var config = {};

			// start long-running process
			const busyRef = busyIndicatorService.show({
				message: 'Exporting search results',
			});

			sdHttp.post(SERVICE_URL + 'export', filter, config).then(function (lrpId) {
                snackBarService.addById(lrpId, 35); //35 = Compliance.LongRunningProcessTypeEnum.ActionViewExport
				busyRef.hide();
			});
		}

		function cancelAvSearch() {
			if (vm.canceler)
				vm.canceler.resolve("user cancelled");
			console.log('action view search has been cancelled');
		}

		function shareSearch(searchItem) {
			return sdHttp.patch(SERVICE_URL_SEARCH_SHARE, searchItem);
		}

		function revokeActionViewShare(actionViewItem) {
			return sdHttp.patch(SERVICE_URL_ACTION_VIEW_REVOKE_SHARE, actionViewItem);
		}

		function share(actionViewItem) {
			return sdHttp.patch(SERVICE_URL_ACTION_VIEW_SHARE, actionViewItem);
		}

		function revokeShare(actionViewItem) {
			return sdHttp.patch(SERVICE_URL_ACTION_VIEW_REVOKE_SHARE, actionViewItem);
		}

		function getTasksAssignmentReadyOptions() {
			var options = {
				showOptions: {
					myTasks: {
						id: 2,
						display: 'My Tasks'
					},
					myTeamsTasks: {
						id: 3,
						display: userService.isSingleInstanceSelected() ? "My Team's Tasks" : "My Teams' Tasks"
					},
					individualsTasks: {
						id: 4,
						display: "Specific Individual's Tasks"
					},
					teamsTasks: {
						id: 5,
						display: "Specific Team's Tasks"
					},
					allTasks: {
						id: 1,
						display: 'All Tasks'
					}
				},

				whereOptions: [{
					display: 'Assigned',
					id: 1
				}, {
					display: "Ultimately Responsible",
					id: 2
				}, {
					display: "Assigned or Ultimately Responsible",
					id: 3
				}, {
					display: "Account Handler",
					id: 4
				}, {
					display: "Compliance Returns PP Resp",
					id: 5
				}],

				thatAreOptions: [{
					display: 'Ready',
					id: 1
				}, {
					display: "Not Ready",
					id: 2
				}, {
					display: "Ready or Not Ready",
					id: 3
				}, {
					display: "Completed",
					id: 4
				}],

				includeOptions: [{
					display: 'All Completed Tasks',
					id: 1
				}, {
					display: 'Tasks Completed by Me',
					id: 2
					// At the moment, we're not saving data about what team completed a task, so the backend can't execute
					// queries with a filter about completed team. We're hiding this option on the interface until/unless we
					// decide to open that option up in the future. This also affects the _taskAssignmentReadyPanel.html page.
					//}, {
					//	display: 'Tasks Completed by My Team',
					//	id: 3
				}, {
					display: 'Tasks Completed by a Specific Individual',
					id: 4
					//}, {
					//	display: 'Tasks Completed by a Specific Team',
					//	id: 5
				}]
			};

			return options;
		}

		function mapThatAreChoiceToDisplayValue(id) {
			const thatAreOptions = getTasksAssignmentReadyOptions().thatAreOptions;

			for(let i = 0; i < thatAreOptions.length; i++){
				if(thatAreOptions[i].id === id)
					return thatAreOptions[i].display;
			}
		}

		function getUrgencies() {
			return [{
				id: 1,
				display: 'Overdue (Black)'
			}, {
				id: 2,
				display: 'Critical (Red)'
			}, {
				id: 3,
				display: 'Imminent (Yellow)'
			}, {
				id: 4,
				display: 'Approaching (Green)'
			}, {
				id: 5,
				display: 'Prospective (Grey)'
			}, {
				id: 6,
				display: 'Critical Or Imminent'
			}]
		}

		function prepareCriteriaForSave(criteria) {
			var returnCriteria = {};
			returnCriteria.outputColumns = _.map(criteria, function (column) {
				return column.columnId || column.advancedSearchFieldID
			});

			returnCriteria.filters = _.map(angular.copy(criteria), function (filter) {
				filter.or = _.map(filter.or, function (or) {
					return {
						value: or.value,
						operator: or.operator.name
					}
				});

				return {
					columnId: filter.columnId || filter.advancedSearchFieldID,
					or: filter.or
				}
			});

			return returnCriteria;
		}

		function prepareActionViewForSave(actionView, filterColumns, isSave) {
			var saveActionViewItem = actionView;
			saveActionViewItem.criteria = prepareCriteriaForSave(filterColumns);

			//This moves the masterFilter to criteria, where it is saved
			saveActionViewItem.criteria.masterFilter = actionView.masterFilter;

			if (isSave) {
				saveActionViewItem.criteria = JSON.stringify(saveActionViewItem.criteria);
			}
			delete saveActionViewItem.masterFilter;

			return saveActionViewItem;
		}

		function prepareActionViewForExecution(filterColumns, masterFilter) {
			actionViewPersistenceService.filterColumns = filterColumns;

			var filterToExecute = _.mapValues(angular.copy(masterFilter), function (val) {
				if (val === undefined) {
					val = 0
				}

				// Null out any arrays with empty objects
				// - userTeams and teams - show and include
				if (_.isArray(val)) {
					val = _.reject(val, function (obj) {
						return (_.isEmpty(obj) && _.isObject(obj)) || obj.teamID === null || obj.userID === null;  // band aid fix for when these props are null.
					})

					if (!val.length) {
						val = null;
					}
				}

				return val;
			});

			filterToExecute.filters = columnFilterService.prepareFilters(filterColumns);

			filterToExecute.TaskOutputColumns = _.map(filterToExecute.TaskOutputColumns, prepareOutputColumn)
			filterToExecute.EntityOutputColumns = _.map(filterToExecute.EntityOutputColumns, prepareOutputColumn);

			return filterToExecute;
		}

		function prepareOutputColumn(column) {
			column.id = column.id || column.columnId || column.advancedSearchFieldID;

			return _.pick(column, 'id', 'sequence', 'displayName', 'depthValue', 'resultColumnName', 'canGroupTaskActionView', 'fromTaskType');
		}

		function sortTaskTypes(taskTypes) {
			return _.sortBy(taskTypes, ['taskSeriesTypeSequence', 'sequence']);
		}

		function getFavoriteCount(actionViewItem) {
			return sdHttp.get(SERVICE_URL_FAVORITE_COUNT + actionViewItem.actionViewID);
		}

        function getUrgencyColumnStyleClass(columnSequence) {
            switch (columnSequence) {
                case 0:
                    return 'urgency-overdue';
                case 1:
                    return 'urgency-critical';
                case 2:
                    return 'urgency-imminent';
                case 3:
                    return 'urgency-approaching';
                case 4:
                    return 'urgency-prospective';
                default:
                    return '';
            }
        }

        function getUrgencyChoiceByHeaderName(header) {
            var urgencies = getUrgencies();

            var urgency = _.find(urgencies, function (urgency) {
                return urgency.display.split(' ')[0].toLowerCase() === header.toLowerCase()
            });

            if (urgency === undefined) {
                console.warn('urgency id not found from header!')
            }

            return urgency.id;
        }

		// Mostly copied from  src\app\Task\ActionView\Output.Defaults.Controller.js
		function launchCustomOutputModal(clickedTaskType, taskTypeColumns) {
			var userSetting = userSettingsService.getSettingsByGroup(12)[0] || {
				groupId: 12,
				name: 'Task View Output Defaults',
				value: [],
				id: 0
			};

			var customTaskTypes = angular.fromJson(userSetting.value);

			var customTaskType = _.find(customTaskTypes, function (taskType) {
				return taskType.taskSeriesTypeID === clickedTaskType.taskSeriesTypeID && taskType.taskTypeID === clickedTaskType.taskTypeID;
			});

			return advancedSearchService.getAllFields().then(function (allFields) {
				var modalInstance = $uibModal.open({
					templateUrl: 'app/Task/ActionView/Filters/_customOutputModal.html',
					controller: 'CustomOutputModalController',
					controllerAs: 'vm',
					windowClass: 'show',
					backdropClass: 'show',
					resolve: {
						fromActionView: function () {
							return false;
						},

						taskTypeName: function () {
							return clickedTaskType.name;
						},

						selectedTaskType: function () {
							return _.find(vm.taskTypes, { taskSeriesTypeID: clickedTaskType.taskSeriesTypeID });
						},

						systemColumns: function () {
							var systemColumns = _.map(clickedTaskType.columnIds, function (columnId) {
								var column = angular.copy(_.find(taskTypeColumns, { columnId: columnId }));

								return column;
							})

							return systemColumns;
						},

						userColumns: function () {
							if (!customTaskType) {
								return [];
							}

							var userColumns = _.map(angular.copy(customTaskType.columns), function (column) {
								var fullColumn = angular.copy(_.find(allFields, { advancedSearchFieldID: column.columnId }))
								_.assign(column, _.omit(fullColumn, 'sequence'));

								return column;
							});

							return userColumns;
						}
					}
				});

				return modalInstance.result
					.then(function (fullColumns) {
						if (fullColumns.length) {
							var columnsToSave = _.map(fullColumns, function (filter, i) {
								return {
									columnId: filter.columnId,
									sequence: i
								};
							});

							if (customTaskType) {
								var i = _.findIndex(customTaskTypes, function (taskType) {
									return taskType.taskSeriesTypeID === customTaskType.taskSeriesTypeID && taskType.taskTypeID === customTaskType.taskTypeID;
								});

								customTaskTypes[i].columns = columnsToSave

							} else {
								customTaskTypes.push({
									taskSeriesTypeID: clickedTaskType.taskSeriesTypeID,
									taskTypeID: clickedTaskType.taskTypeID,
									columns: columnsToSave
								});
							}

							userSetting.value = JSON.stringify(customTaskTypes);
							return userSettingsService.save(userSetting)
								.then(function () { })
						} else {
							if (customTaskType) {
								_.remove(customTaskTypes, customTaskType);

								userSetting.value = customTaskTypes;
								return userSettingsService.save(userSetting)
									.then(function () { })
							}
						}
					})

				})

		}

	}
})();
