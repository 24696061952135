import { Component, Input, OnInit } from '@angular/core';
import * as _ from "lodash";
import {DisplayStringArrayModel} from './display.String.Array.model';

@Component({
    selector: 'display-String-Array-component',
    templateUrl:
        './display.String.Array.component.html'
})
export class DisplayStringArrayComponent implements OnInit {

    @Input() data: DisplayStringArrayModel;

    constructor() { }

    // constructor(
    //     public  data: DisplayStringArrayModel,
    // ) {
    //     this.dataToDisplay = data;
    // }

    ngOnInit(): void {  }

}