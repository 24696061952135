<ws-modal-wrapper headerLabel="Link Sites"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!selectedMasterSite">
    <h4>How should assets be reported?</h4>
    <div class="ws-flex-container-vertical option-group-container">
        <div class="ws-flex-auto">
            <input type="radio" name="reporting" id="reportingThis" [disabled]="!site" [value]="linkedSitesReportingTypes.ThisSite" [(ngModel)]="selectedReportingType" (ngModelChange)="selectedReportingTypeChanged($event)">
            <label for="reportingThis">Use this site to report another site's assets</label>
        </div>
        <div class="ws-flex-auto">
            <input type="radio" name="reporting" id="reportingAnother" [disabled]="!site" [value]="linkedSitesReportingTypes.AnotherSite" [(ngModel)]="selectedReportingType" (ngModelChange)="selectedReportingTypeChanged($event)">
            <label for="reportingAnother">Use another site to report this site's assets</label>
            <div class="input-group">
                <input name="site"
                       [disabled]="selectedReportingType !== linkedSitesReportingTypes.AnotherSite"
                       type="text"
                       placeholder="Select master site..."
                       class="form-control"
                       autocomplete="off"
                       [(ngModel)]="siteFilter"
                       [typeahead]="sites$"
                       [typeaheadWaitMs]="750"
                       typeaheadOptionField="displayName"
                       [typeaheadOptionsLimit]="50"
                       [typeaheadScrollable]="true"
                       (typeaheadLoading)="onSiteLoadingChange($event)"
                       (typeaheadOnSelect)="onSiteSelected($event)" />
                <span class="input-group-text" [style.visibility]="siteIsLoading ? 'visible' : 'hidden'">
                    <i class="fa fa-spin fa-spinner"></i>
                </span>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
