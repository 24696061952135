import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
    selector: 'ag-grid-loading-overlay',
    template: `
                <div class="loading-overlay-backdrop"></div>
                <div class="loading-overlay-container">
                    <div class="loading-overlay-container__spinner">
                        <div class="la-ball-spin-clockwise">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
              `
})
export class AgGridLoadingOverlayComponent implements ILoadingOverlayAngularComp {
    constructor() { }

    agInit(params: ILoadingOverlayParams): void { }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
