import { Injectable } from '@angular/core';
import { BillClusterService, BillViewModel } from '../../../../Annual-Details/Taxes/bill-cluster.service';
import { Payment } from '../../../../Annual-Details/Taxes/payment.model';

import * as moment from 'moment/moment';
import { cloneDeep, every, flatMap, minBy, reduce, some } from 'lodash';

import DetailsInstallmentMessageItem = Weissman.Model.Workflow.DocumentIntake.OcrResponse.Message.DetailsInstallmentMessageItem;
import { Bill } from 'src/app/Annual-Details/Taxes/bill.model';


@Injectable({ providedIn: 'root' })
export class IdpService {

    constructor(private readonly _billClusterService: BillClusterService) {}

    billAmountDoesntAddUp(billDetail: BillViewModel, idpResults: Core.TaxBillPushMessageDto): boolean {
        const billDetailCopy = cloneDeep(billDetail);

        return some(billDetailCopy.model, bill => {
            this._mapBillFromIdp(bill, idpResults);

            const grossPaymentSum = reduce(bill.payments, (sum, payment) =>
                new Decimal(sum).plus(payment.grossPayment).toNumber(),
            0);

            return bill.billAmount !== grossPaymentSum;
        });
    }

    isDueDateMismatch(billDetail: BillViewModel, installments: Core.InstallmentMessageItem[]): boolean {
        return some(billDetail.model, bill => {
            const { installment, discountIndex } = this._getInstallmentAndDiscountIdx(installments, bill);

            return some(bill.payments, (payment: Payment, pmtIdx: number) => {
                const ocrPayment = installment?.details[pmtIdx];
                const possibleDueDate = ocrPayment?.discount[discountIndex]?.dueDate || ocrPayment?.dueDate;
                // If there is a payment due date, but it's not the same as the possible one
                // Do not overwrite, and lower the confidence
                return payment.dueDate && !moment(possibleDueDate).isSame(moment(payment.dueDate));
            });
        });
    }

    isMissingPaymentDueDate(installments: Core.InstallmentMessageItem[]): boolean {
        return some(installments, installment => {
            if(!installment.details.length) {
                return true;
            }

            return some(installment.details, detail =>
                !detail.dueDate && every(detail.discount, x => !x.dueDate)
            );
        });
    }

    mapBillVMFromIDP(billVM: BillViewModel, idpResults: Core.TaxBillPushMessageDto): void {
        billVM.model.forEach(bill =>  this._mapBillFromIdp(bill, idpResults));
    }

    getDiscount(ocrPayment: DetailsInstallmentMessageItem, discountIndex: number): number {
        const discount = ocrPayment?.discount[discountIndex];

        if(discount?.discountAmount) {
            return discount?.discountAmount;
        } else if(discount?.netAmount && ocrPayment.amount) {
            return new Decimal(ocrPayment.amount).minus(discount?.netAmount ?? 0).toNumber();
        } else {
            return 0;
        }
    }

    private _mapBillFromIdp(bill: Bill, idpResults: Core.TaxBillPushMessageDto): void {
        if(bill.payments.length === 1 && !some(idpResults.installments, x => x.details.length === 1)) {
            const idpPayments = flatMap(idpResults.installments, 'details') as DetailsInstallmentMessageItem[];
            const idpPayment = minBy(idpPayments, x => new Date(x.dueDate));

            if(!idpPayment) return;

            bill.calcProjected = false;

            bill.billAmount = idpResults.totalAmount || 0;
            bill.directAsmt = idpResults.directAssessmentAmount || 0;

            bill.payments[0].dueDate = idpPayment.dueDate;
            bill.payments[0].grossPayment = idpResults.totalAmount;
            bill.payments[0].paymentAmount = this._billClusterService.paymentPropChanged(bill.payments[0]);

            return;
        }

        bill.billAmount = idpResults.totalAmount || 0;
        bill.directAsmt = idpResults.directAssessmentAmount || 0;

        const { installment, discountIndex } = this._getInstallmentAndDiscountIdx(idpResults.installments, bill);

        bill.payments.forEach((payment: Payment, pmtIdx: number) => {
            const ocrPayment = installment?.details[pmtIdx];

            if(!ocrPayment) return;

            bill.calcProjected = false;

            payment.dueDate =  ocrPayment?.discount[discountIndex]?.dueDate || ocrPayment.dueDate;
            payment.grossPayment = ocrPayment?.amount || 0;
            payment.discountAmount = -this.getDiscount(ocrPayment, discountIndex);
            payment.interestAmount = ocrPayment?.interest[discountIndex]?.interestAmount || 0;
            payment.penaltyAmount = ocrPayment?.penalty[discountIndex]?.penaltyAmount || 0;

            payment.paymentAmount = this._billClusterService.paymentPropChanged(payment);
        });
    }

    private _getInstallmentAndDiscountIdx(installments: Core.InstallmentMessageItem[], bill: Bill): {
        installment: Core.InstallmentMessageItem,
        discountIndex: number
    } {
        const installment = installments.find(x => x.details.length === bill.payments.length);
        let discountIndex = 0;

        if (bill.payments.length === 1) {
            const ocrPayment = installment?.details.find(x => moment(x.dueDate).isSame(moment(bill.payments[0].dueDate)));
            discountIndex = ocrPayment?.discount.findIndex(x => x.dueDate === bill.payments[0].dueDate) || 0;
        }

        return {installment, discountIndex };
    }

    // private _getDirectAssessment(idpResults: Core.TaxBillPushMessageDto): number {
    //     if(idpResults.directAssessmentAmount) {
    //         return idpResults.directAssessmentAmount;
    //     } else {
    //         return reduce(idpResults.taxAuthorities, (sum, ta) => {
    //             if(!ta.taxRate) {
    //                 sum += ta.taxAmount;
    //             }

    //             return sum;
    //         }, 0);
    //     }
    // }
}
