import { ICellEditorAngularComp } from "ag-grid-angular";
import { ICellEditorParams } from "ag-grid-community";
import { Component, ViewChild } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { HigherOrderFn, Predicate, TypeaheadSelectorComponent } from '../Selectors/Typeahead-Selector/typeaheadSelector.component';

export interface EntityImportEditorAssessorSelectorCellEditorParams extends ICellEditorParams {
    getAssessors(rowIndex: number): Compliance.ImportAssessorModel[];
}

@Component({
    selector: 'entity-import-editor-assessor-selector',
    template: `<typeahead-selector #typeaheadSelector
                                 labelProperty="name"
                                 valueProperty="name"
                                 placeholder="Filter Assessors..."
                                 [isRequiredField]="false"
                                 [formControl]="assessorValue"
                                 [canReset]="true"
                                 [originalValue]="originalValue"
                                 [filterFn]="typeaheadFilter"
                                 [findFn]="typeaheadFilter"
                                 (changed)="onAssessorChanged($event)"
                                 [values]="assessors"></typeahead-selector>`
})
export class EntityImportEditorAssessorSelectorCellEditorComponent implements ICellEditorAngularComp {
    @ViewChild("typeaheadSelector", { static: true }) typeaheadSelector: TypeaheadSelectorComponent;

    gridRow: Compliance.ImportGridRowGridModel;
    assessorValue: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    assessors: Compliance.ImportAssessorModel[];
    originalValue: string;
    typeaheadFilter: HigherOrderFn<Predicate> = (filter: string) => (x: Compliance.ImportAssessorModel) => this._isMatch(x, filter);

    private _params: EntityImportEditorAssessorSelectorCellEditorParams;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.assessorValue.value;
    }

    isCancelAfterEnd(): boolean {
        if (this.typeaheadSelector.value && !this.typeaheadSelector.filter) {
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    async agInit(params: EntityImportEditorAssessorSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;

        if (this._params.data && this._params.data.originalValues) {
            this.originalValue = this._params.data.originalValues[this._params.colDef.field];
        }

        this.assessorValue.setValue(params.value);

        this.assessors = await params.getAssessors(this.gridRow.rowIndex);

        if (this.assessors && this.assessors.length) {
            this.assessorValue.enable();
        }
    }

    onAssessorChanged(assessorValue?: string): void {
        if (this.assessorValue.value !== assessorValue) {
            this.assessorValue.setValue(assessorValue);
            this._params.node.setDataValue(this._params.column.getColId(), assessorValue);
        }
    }

    private _isMatch(assessor: Compliance.ImportAssessorModel, selectedValue: string): boolean {
        const value = (selectedValue || '').toLocaleLowerCase();
        const localAssessor = {
            assessorId: assessor.assessorId,
            name: assessor.name.toLocaleLowerCase(),
            primaryMatch: assessor.primaryMatch.toLocaleLowerCase(),
            additionalMatches: assessor.additionalMatches.map(i => i.toLocaleLowerCase())
        } as Compliance.ImportAssessorModel;

        let result = value === '';

        if (!result) {
            result = localAssessor.name.indexOf(value) >= 0;
        }

        if (!result) {
            result = value === localAssessor.primaryMatch ||
                value.startsWith(localAssessor.primaryMatch + ' ') ||
                value.endsWith(' ' + localAssessor.primaryMatch) ||
                value.indexOf(' ' + localAssessor.primaryMatch + ' ') !== -1;
        }

        if (!result) {
            localAssessor.additionalMatches.forEach(additionalMatch => {
                result = value.indexOf(localAssessor.primaryMatch) !== -1 && value.indexOf(additionalMatch) !== -1;

                return !result;
            });
        }

        if (!result) {
            result = localAssessor.assessorId === +value;
        }

        if (!result) {
            localAssessor.additionalMatches.forEach(additionalMatch => {
                result = value === (localAssessor.primaryMatch + ' ' + additionalMatch).toLocaleLowerCase();

                return !result;
            });
        }

        return result;
    }
}
