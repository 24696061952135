import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'ws-pdf-controls',
    templateUrl: './pdfControls.component.html',
    styleUrls: ['./pdfControls.component.scss']
})
export class PDFControlsComponent {
    @Input() canZoom: boolean = true;
    @Input() zoomScaleUpdating: boolean;
    @Input() zoomStep: number = 10;
    @Input() min: number = 10;
    @Input() max: number = 300;
    @Input()
    set zoomPercentage(pct: number) {
        this._zoomValue = pct;
    };

    @Output() zoomChanged: EventEmitter<number> = new EventEmitter();

    @HostListener('keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.submitZoomValue();
        }
        event.stopPropagation();
    }

    get zoomPercentage(): number {
        return this._zoomValue;
    }

    private _zoomValue = 100;

    zoomOut(): void {
        const newValue = this._zoomValue % 10 === 0 ? this._zoomValue - this.zoomStep : this._zoomValue;
        this.setZoomValue(Math.floor(newValue / 10) * 10);
        this.submitZoomValue();
    }

    zoomIn(): void {
        this.setZoomValue(Math.floor((this._zoomValue + this.zoomStep) / 10) * 10);
        this.submitZoomValue();
    }

    setZoomValue(value: number): void {
        this._zoomValue = value;
    }

    submitZoomValue(): void {
        if (!this._zoomValue) {
            this._zoomValue = 100;
        }
        this._zoomValue = this._zoomValue >= this.max ? this.max : this._zoomValue;
        this._zoomValue = this._zoomValue <= this.min ? this.min : this._zoomValue;
        this.zoomChanged.next(Math.floor(this._zoomValue) / 100);
    }
}
