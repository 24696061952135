import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Constants, ContractServiceType, PropertyType } from '../../constants.new';
import { InvoiceService } from '../../Processing/Invoice/invoice.service';
import { CreateAdHocInvoiceRequest } from '../../Processing/Invoice/invoice.model';

@Component({
    selector: 'create-invoice-modal',
    templateUrl: './create-invoice-modal.component.html'
})
export class InvoiceModalComponent implements OnInit {
    loading: boolean = false;
    siteId: number;
    companyId: number;
    years: number[];
    year: number;
    contractServiceTypeId: number;
    contractServiceTypes: any[];
    ContractServiceType: typeof ContractServiceType;
    propertyTypeId: number;
    propertyTypes: any[];
    onCreate: Function;
    validationMessages: any;

    constructor(public bsModalRef: BsModalRef,
                private constants: Constants,
                private invoiceService: InvoiceService) {
        this.ContractServiceType = ContractServiceType;
        this.contractServiceTypes = Object.keys(constants.ContractServiceTypes).filter(z => z !== 'HOURLY')
            .map(key => constants.ContractServiceTypes[key])
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }

                  // names must be equal
                  return 0;
            });
        //need to remove ContractServiceType.Hourly from list here
        //this.contractServiceTypes = this.contractServiceTypes.filter(z => z !== ContractServiceType.Hourly);

        this.propertyTypes = Object.keys(constants.PropertyTypes).map(key => constants.PropertyTypes[key]).filter(x => x.id);
    }

    async ngOnInit() {
        this.contractServiceTypeId = 0; // ContractServiceType.Admin;

        this.validationMessages = {};
        this.year = (new Date()).getFullYear();
        this.years = [];
        for (let i = this.year + 5; i > this.year - 11; i--) {
            this.years.push(i);
        }

        this.loading = true;

        try {
            let defaultRequest: Promise<CreateAdHocInvoiceRequest>;
            if (this.siteId) {
                defaultRequest = this.invoiceService.getSiteInvoiceDefaults(this.siteId);
            }
            else {
                defaultRequest = this.invoiceService.getCompanyInvoiceDefaults(this.companyId);
            }

            const request = await defaultRequest;

            if (request.propertyType) {
                this.propertyTypeId = request.propertyType;
            }
            else {
                this.propertyTypeId = null;
            }
        } finally {
            this.loading = false;
        }
    }

    async createInvoice(): Promise<void> {
        this.validationMessages = {};
        if (!this.contractServiceTypeId) {
            this.validationMessages = {
                contractServiceType: 'Service is required'
            };
            return;
        }
        if (this.contractServiceTypeId == ContractServiceType.Appeal && !this.propertyTypeId) {
            this.validationMessages = {
                propertyType: 'Property type is required'
            };
            return;
        }
        this.loading = true;

        try {
            let propertyType: PropertyType = null;
            if (this.contractServiceTypeId == ContractServiceType.Appeal) {
                propertyType = this.propertyTypeId;
            }

             this.invoiceService.createAdHocInvoice({
                annualYear: this.year,
                companyId: this.companyId,
                siteId: this.siteId,
               contractServiceType: this.contractServiceTypeId,
                propertyType: propertyType
            }).then(invoiceId => {
                this.bsModalRef.hide();
                this.onCreate(invoiceId);
            }
           );

        } finally {
            this.loading = false;
        }
    }

    closeModal(): void {
        this.bsModalRef.hide();
    }

    identify(item) {
        return item.id;
    }
}
