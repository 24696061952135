import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StateJurisdictionCommandCenterService, FilterTimeFrame, FilterVerification } from '../stateJurisdiction.service';
import { YearPickerMixin } from '../../../UI-Lib/Mixins';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'command-view-controls',
    templateUrl: './viewControls.component.html',
    styleUrls: ['./viewControls.component.scss']
})
export class CommandViewControlsComponent extends YearPickerMixin() implements OnInit, OnDestroy {
    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _stateJurisdictionService: StateJurisdictionCommandCenterService
    ) {
        super();
    }

    states: Core.StateModel[] = [];
    stateFormatter = (state) => (state.abbr) ? `${state.fullName} (${state.abbr})` : state.fullName;
    viewControlForm: UntypedFormGroup;
    filterForm: UntypedFormGroup;
    deselectValue;

    highlightDates: Compliance.NameValuePair<FilterTimeFrame>[] = this._stateJurisdictionService.highlightDates;
    verificationOptions: Compliance.NameValuePair<FilterVerification>[] = this._stateJurisdictionService.verificationOptions;

    private _all: Core.StateModel = {
        abbr: null,
        countryId: 0,
        fullName: 'All',
        lienDateMonthDay: '',
        lienDateYearID: 0,
        stateID: null
    };
    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        const searchModel = this._stateJurisdictionService.currentSearchModel;

        this.viewControlForm = this._fb.group({
            state: [],
            taxYear: [(searchModel) ? searchModel.taxYear : new Date().getFullYear()]
        });

        const filterModel = this._stateJurisdictionService.currentFilterModel;
        const highlightModel = this._stateJurisdictionService.highlightChangesModel;

        this.filterForm = this._fb.group({
            recentChanges: [(highlightModel) ? highlightModel : null],
            verification: [(filterModel) ? filterModel : null]
        });

        this.viewControlForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputFormChanges());

        this.filterForm.get('recentChanges').valueChanges
            .pipe(takeUntil(this.destroy$))
            .pipe(distinctUntilChanged())
            .subscribe((val) => this._outputHighlightChanges(val));

        this.filterForm.get('verification').valueChanges
            .pipe(takeUntil(this.destroy$))
            .pipe(distinctUntilChanged())
            .subscribe((val) => this._outputFilterChanges(val));

        this.states = [this._all, ...this._stateJurisdictionService.states];
    }

    ngOnDestroy(): void {
        this.viewControlForm.patchValue({state: undefined});
        this._outputFormChanges();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _outputFormChanges(): void {
        const { state, taxYear } = this.viewControlForm.value;

        let searchModel: Compliance.AssessorCommandCenterSearchModel = {
            stateId: (state) ? state.stateID : undefined,
            taxYear: taxYear
        }

        this._stateJurisdictionService.formChanged(searchModel);
    }

    private _outputHighlightChanges(value: FilterTimeFrame): void {
        this._stateJurisdictionService.highlightChanged(value);
    }

    private _outputFilterChanges(value: FilterVerification): void {
        this._stateJurisdictionService.filtersChanged(value);
    }
}
