import { Component, OnInit, Input } from '@angular/core';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';

@Component({
    selector: 'state-details',
    templateUrl: './state.details.component.html',
})
export class StateDetailsComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService
    ) { }

    @Input() state: Weissman.Model.Misc.State;

    hasWritePermission: boolean = false;

    get attachmentsModel(): AttachmentModalData {
        if (!this.state) {
            return null;
        }

        return {
            entityType: 'STATE',
            entityName: this.state.fullName,
            entityData: {
                typeId: Core.EntityTypes.State,
                id: this.state.stateID,
                name: this.state.fullName
            },
            readOnly: !this.hasWritePermission
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.state) {
            return null;
        }

        return {
            entityID: this.state.stateID,
            entityTypeID: Core.EntityTypes.State,
            description: `State: ${this.state.fullName}`,
            canEdit: this.hasWritePermission
        } as CommentModalData;
    }

    async ngOnInit() {
        this.hasWritePermission = this._restrictService.isInRole(Roles.STATEEDIT);
    }
}
