import { Component, OnInit } from '@angular/core';
import { HelpContentComponent } from '../../../../../UI-Lib/Help-Tooltip';

export interface DynamicFieldBehaviorHelpFieldInfo {
    name: string;
    selectedOption: string;
}

export interface DynamicFieldSelectedBehaviorHelpComponentParams{
    fields: DynamicFieldBehaviorHelpFieldInfo[];
}

@Component({
    selector: 'dynamic-field-selected-behavior-help',
    templateUrl: './dynamicFieldSelectedBehaviorHelp.component.html'
})
export class DynamicFieldSelectedBehaviorHelpComponent implements OnInit, HelpContentComponent<DynamicFieldSelectedBehaviorHelpComponentParams> {
    params: DynamicFieldSelectedBehaviorHelpComponentParams;
    fields: DynamicFieldBehaviorHelpFieldInfo[];

    helpInit(params: DynamicFieldSelectedBehaviorHelpComponentParams): void {
    }

    ngOnInit() {
        this.fields = this.params.fields;
        this.fields.sort((a, b) => a.name.localeCompare(b.name));
    }
}
