import { Component } from '@angular/core';
import { FormService } from '../form.service';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';

@Component({
    selector: 'form-attachments',
    templateUrl: './FormAttachments.component.html'
})
export class FormAttachmentsComponent {
    constructor(
        private readonly _formService: FormService
    ) { }

    get formRevision(): Compliance.FormRevisionModel {
        return this._formService.formRevision;
    }

    getAttachmentModalData() {
        return {
            entityType: 'FormRevision',
            entityName: this._formService.formRevision && this._formService.formRevision.name,
            entityData: {
                typeId: Core.EntityTypes.FormRevision,
                id: this._formService.formRevision && this._formService.formRevision.formRevisionId,
                name: this._formService.formRevision && this._formService.formRevision.name
            },
            readOnly: !this._formService.canEdit,
            singleYear: true,
            year: this._formService.taxYear,
            disableYears: true
        } as AttachmentModalData;
    }
}
