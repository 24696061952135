import { Component, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorService, BusyIndicatorRef } from '../../../../../Busy-Indicator';
import { FormRepository, ReturnAssetRepository } from '../../../../Repositories';
import { ReturnAssetBulkUpdateActionComponent } from './returnAssetBulkUpdateAction.component';
import { IWeissmanModalComponent, WeissmanModalService } from '../../../../WeissmanModalService';
import { ReturnAssetsService } from '../returnAssets.service';
import {
    AssessorTablePickerComponent,
    AssessorTablePickerParams,
    AssessorTablePickerResult,
    LockedAssessorAssignments
} from '../Assessor-Table-Picker/assessorTablePicker.component';
import { ReturnService } from '../../../return.service';
import ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum;
import { ProductAnalyticsService } from "../../../../../Common/Amplitude/productAnalytics.service";
import { lastValueFrom } from "rxjs";

export interface ReturnAssetBulkUpdateParams {
    filingBatchId: number;
    searchModel: Compliance.ReturnAssetSearchModel;
    selectedCount: number;
    showPriorScheduleField: boolean;
}

export interface ReturnAssetBulkUpdateResult {
    requestSchedules: boolean;
}

@Component({
    selector: 'return-asset-bulk-update',
    templateUrl: './returnAssetBulkUpdate.component.html'
})
export class ReturnAssetBulkUpdateComponent implements IWeissmanModalComponent<ReturnAssetBulkUpdateParams, ReturnAssetBulkUpdateResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _modalService: WeissmanModalService,
        private readonly _returnService: ReturnService,
        private readonly _returnAssetRepository: ReturnAssetRepository,
        private readonly _returnAssetsService: ReturnAssetsService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {
        this.possibleReturnStatuses = [
            { name: 'Suppress', value: Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged },
            { name: 'Transfer In', value: Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn },
            { name: 'Acquisition', value: Compliance.ReturnAssetPriorReturnStatusEnum.New },
            { name: 'Transfer Out', value: Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut },
            { name: 'Disposal', value: Compliance.ReturnAssetPriorReturnStatusEnum.Retired }
        ];
    }

    private _busyRef: BusyIndicatorRef;
    private _busyRefId = this._busyIndicatorService.generateUniqueMessageIdentifier();

    @ViewChild('returnStatusActionField', { static: true }) returnStatusActionField: ReturnAssetBulkUpdateActionComponent;

    params: ReturnAssetBulkUpdateParams;
    result: ReturnAssetBulkUpdateResult;

    bulkUpdateMetadata: Compliance.ReturnAssetBulkUpdateMetadataModel;
    selectedRowsReturnStatus: Compliance.ReturnAssetPriorReturnStatusEnum;
    assetReturnStatusTooltipText: string;
    isAssetReturnStatusFieldDisabled: boolean = true;
    showAssetReturnStatusTooltip: boolean = false;
    allowAssetReturnStatusRemoveOverride: boolean = false;
    classificationId?: number;
    classificationIdAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    returnStatus?: Compliance.ReturnAssetPriorReturnStatusEnum = Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged;
    returnStatusAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    scheduleAssignmentAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    formRevisionSchedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
    priorScheduleAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    possibleReturnStatuses: Compliance.NameValuePair<Compliance.ReturnAssetPriorReturnStatusEnum>[];
    assessorFactorTableAssignments: Compliance.ReturnAssetAssessorFactorTableAssignment[] = [];
    additionalDepreciationAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    additionalDepreciation?: number;
    estimatedFMVAction?: Compliance.ReturnAssetBulkUpdateFieldActionEnum = Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    estimatedFMV?: number;

    // Display value collected for Amplitude analytics
    classificationName: string;
    scheduleAssignmentName: string;

    async ngOnInit(): Promise<void> {
        await this._loadBulkUpdateMetadata();
    }

    formRevisionScheduleChange(schedule) {
        this.formRevisionSchedule = schedule;
    }

    async save(): Promise<void> {
        this._showBusyIndicator('Saving');

        try {

            if (this.priorScheduleAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo && this.formRevisionSchedule) {
                const success = await this._populateAssessorFactorTableAssignments();

                if (!success) {
                    return;
                }
            }

            const returnAssetBulkUpdateModel: Compliance.ReturnAssetBulkUpdateModel = {
                classificationId: this.classificationId,
                classificationIdAction: this.classificationIdAction,
                filingBatchId: this.params.filingBatchId,
                returnStatus: this.returnStatus,
                returnStatusAction: this.returnStatusAction,
                scheduleAssignmentAction: this.scheduleAssignmentAction,
                searchModel: this.params.searchModel,
                priorScheduleAssignmentAction: this.priorScheduleAction,
                priorScheduleId: this.formRevisionSchedule && this.formRevisionSchedule.formRevisionScheduleId,
                assessorFactorTableAssignments: this.assessorFactorTableAssignments,
                additionalDepreciation: this.additionalDepreciation,
                additionalDepreciationAction: this.additionalDepreciationAction,
                estimatedFMV: this.estimatedFMV,
                estimatedFMVAction: this.estimatedFMVAction,
                parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
                taxYear: this._returnService.taxYear,
                formRevisionId: this._returnService.sharedState.formRevisionId,
                lienDate: this._returnService.lienDate
            };

            await lastValueFrom(this._returnAssetRepository.bulkUpdate(returnAssetBulkUpdateModel));

            this.result = { requestSchedules: !!(this.scheduleAssignmentAction || this.classificationIdAction) };

            const analytics = {};
            const removeOverride = 'Remove Override';
            if (this.returnStatusAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange) {
                const assetReturnStatus = this.possibleReturnStatuses.find(x => x.value === this.returnStatus);
                analytics['assetReturnStatus'] = this.returnStatusAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo ? assetReturnStatus.name : removeOverride;
            }
            if (this.classificationIdAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange) {
                analytics['classificationName'] = this.classificationIdAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo ? this.classificationName : removeOverride;
            }
            if (this.scheduleAssignmentAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange) {
                analytics['scheduleAssignment'] = this.scheduleAssignmentAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo ? this.scheduleAssignmentName : removeOverride;
            }
            if (this.additionalDepreciationAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange) {
                analytics['additionalDepreciation'] = this.additionalDepreciationAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo ? `${this.additionalDepreciation}%` : removeOverride;
            }
            if (this.estimatedFMVAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange) {
                analytics['estimatedFMV'] = this.estimatedFMVAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo ? `$${this.estimatedFMV}` : removeOverride;
            }
            this._productAnalyticsService.logEvent('click-bulk-update-OK', analytics);

            this._bsModalRef.hide();
        } finally {
            this._hideBusyIndicator();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    validateForm(): boolean {
        const isValid = this.returnStatusAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange
                      || this.scheduleAssignmentAction !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange
                      || this.priorScheduleAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo && this.formRevisionSchedule != null
                      || this.priorScheduleAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride
                      || this.priorScheduleAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.SetToClassificationDefault
                      || this.classificationIdAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo && this.classificationId >= 0
                      || this.classificationIdAction === Compliance.ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride
                      || this.additionalDepreciationAction === ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride
                      || this.additionalDepreciationAction === ReturnAssetBulkUpdateFieldActionEnum.ChangeTo && this.additionalDepreciation >= 0.01 && this.additionalDepreciation <= 100
                      || this.estimatedFMVAction === ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride
                      || this.estimatedFMVAction === ReturnAssetBulkUpdateFieldActionEnum.ChangeTo && this.estimatedFMV >= 0.01;

        return isValid;
    }

    isDisabled(action: Compliance.ReturnAssetBulkUpdateFieldActionEnum): boolean{
        return action !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo;
    }

    setClassificationName(classification: Compliance.AssetClassificationModel): void {
        if (classification && classification.name) {
            this.classificationName = classification.name;
        }
    }

    setScheduleAssignmentName(scheduleAssignmentName: string) {
        if (scheduleAssignmentName) {
            this.scheduleAssignmentName = scheduleAssignmentName;
        }
    }

    private async _loadBulkUpdateMetadata(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        const returnAssetBulkUpdateModel: Compliance.ReturnAssetBulkUpdateModel = {
            classificationId: this.classificationId,
            classificationIdAction: this.classificationIdAction,
            filingBatchId: this.params.filingBatchId,
            returnStatus: this.returnStatus,
            returnStatusAction: this.returnStatusAction,
            scheduleAssignmentAction: this.scheduleAssignmentAction,
            searchModel: this.params.searchModel,
            priorScheduleAssignmentAction: this.priorScheduleAction,
            priorScheduleId: this.formRevisionSchedule && this.formRevisionSchedule.formRevisionScheduleId,
            assessorFactorTableAssignments: this.assessorFactorTableAssignments,
            additionalDepreciation: this.additionalDepreciation,
            additionalDepreciationAction: this.additionalDepreciationAction,
            estimatedFMV: this.estimatedFMV,
            estimatedFMVAction: this.estimatedFMVAction,
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            taxYear: this._returnService.taxYear,
            formRevisionId: this._returnService.sharedState.formRevisionId,
            lienDate: this._returnService.lienDate
        };

        try {
            this.bulkUpdateMetadata = await lastValueFrom(this._returnAssetRepository
                .getBulkUpdateMetadata(returnAssetBulkUpdateModel));
            this._validateSelectedRecords();
        } finally {
            busyRef.hide();
        }
    }

    private _validateSelectedRecords(): void {
        const validReturnStatuses = [ Compliance.ReturnAssetPriorReturnStatusEnum.New,
                                    Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn,
                                    Compliance.ReturnAssetPriorReturnStatusEnum.Retired,
                                    Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut];

        if (this.bulkUpdateMetadata.assetReturnStatuses.length === 0) {
            this.isAssetReturnStatusFieldDisabled = true;
            return;
        }

        if (this.bulkUpdateMetadata.assetReturnStatuses.length > 1) {
            this.assetReturnStatusTooltipText = 'This field cannot be updated because the selected records do not have the same Asset Return Status';
            this.isAssetReturnStatusFieldDisabled = true;
            this.showAssetReturnStatusTooltip = true;
            return;
        }

        if (this.bulkUpdateMetadata.assetReturnStatuses[0] === Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged) {
            this.isAssetReturnStatusFieldDisabled = false;
            this.allowAssetReturnStatusRemoveOverride = true;

            for(let i = 0; i < this.bulkUpdateMetadata.calculatedAssetReturnStatuses.length; i++) {
                if(!validReturnStatuses.includes(this.bulkUpdateMetadata.calculatedAssetReturnStatuses[i])) {
                    this.isAssetReturnStatusFieldDisabled = true;
                    this.allowAssetReturnStatusRemoveOverride = false;
                    this.showAssetReturnStatusTooltip = true;
                    this.assetReturnStatusTooltipText = 'This field cannot be updated because the selected records do not have an Asset Return Status which allows it';
                    break;
                }
            }

            this.returnStatusActionField.refresh(this.allowAssetReturnStatusRemoveOverride, false, false);
        } else if (!validReturnStatuses.includes(this.bulkUpdateMetadata.assetReturnStatuses[0])) {
            this.assetReturnStatusTooltipText = 'This field cannot be updated because the selected records do not have an Asset Return Status which allows it';
            this.isAssetReturnStatusFieldDisabled = true;
            this.showAssetReturnStatusTooltip = true;
        } else {
            this.isAssetReturnStatusFieldDisabled = false;
            this.allowAssetReturnStatusRemoveOverride = false;

            switch(this.bulkUpdateMetadata.assetReturnStatuses[0]) {
                case Compliance.ReturnAssetPriorReturnStatusEnum.New:
                    this.possibleReturnStatuses = this.possibleReturnStatuses.filter(i => i.value === Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn || i.value === Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged);
                    break;
                case Compliance.ReturnAssetPriorReturnStatusEnum.TransferIn:
                    this.possibleReturnStatuses = this.possibleReturnStatuses.filter(i => i.value === Compliance.ReturnAssetPriorReturnStatusEnum.New || i.value === Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged);
                    break;
                case Compliance.ReturnAssetPriorReturnStatusEnum.Retired:
                    this.possibleReturnStatuses = this.possibleReturnStatuses.filter(i => i.value === Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut || i.value === Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged);
                    break;
                case Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut:
                    this.possibleReturnStatuses = this.possibleReturnStatuses.filter(i => i.value === Compliance.ReturnAssetPriorReturnStatusEnum.Retired || i.value === Compliance.ReturnAssetPriorReturnStatusEnum.NotChanged);
                    break;
            }

            for(let i = 0; i < this.bulkUpdateMetadata.calculatedAssetReturnStatuses.length; i++) {
                if(this.bulkUpdateMetadata.assetReturnStatuses[0] !== this.bulkUpdateMetadata.calculatedAssetReturnStatuses[i]) {
                    this.returnStatusActionField.refresh(false, true, false);
                    break;
                }
            }
        }
    }

    private async _populateAssessorFactorTableAssignments(): Promise<boolean> {
        const allAssessors: Compliance.FormRevisionAssessorModel[] = await this._returnAssetsService.getWorkingSetAssessors(this.bulkUpdateMetadata.reportingAssetIds);

        const assessors = allAssessors.filter((assessor: Compliance.FormRevisionAssessorModel) => assessor.assessorId !== null);

        if (!this.formRevisionSchedule.isDepreciable) {
            // schedule is not depreciable, do not prompt for factor table
            return await this._updateNonDepreciableSchedule(assessors);
        } else if (this.formRevisionSchedule.depreciationTableId) {
            // update when schedule broken out by factor table
            return await this._updateForExpandedScheduleDetail(this.formRevisionSchedule, assessors);
        } else if (this.formRevisionSchedule.lockedAssessors && this.formRevisionSchedule.lockedAssessors.some(la => la.isLocked)) {
            // update when schedule is locked
            return await this._updateLockedSchedule(this.bulkUpdateMetadata.reportingAssetIds, this.formRevisionSchedule, assessors, allAssessors);
        } else {
            // otherwise user to select a factor table
            return await this._askForFactorAndUpdate(this.bulkUpdateMetadata.reportingAssetIds, this.formRevisionSchedule, assessors, allAssessors);
        }
    }

    private async _updateAssessorFactorTableAssignments(assessorPickerResult: AssessorTablePickerResult): Promise<boolean> {
        this.assessorFactorTableAssignments = [];

        for (let key in assessorPickerResult.selection) {
            if (assessorPickerResult.selection.hasOwnProperty(key)) {
                this.assessorFactorTableAssignments.push({
                    assessorId: parseInt(key),
                    depreciationTableId: assessorPickerResult.selection[key].depreciationTableId,
                    indexTableId: assessorPickerResult.selection[key].indexTableId,
                });
            }
        }

        return true;
    }

    private async _updateForExpandedScheduleDetail(schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel, assessors) : Promise<boolean> {
        const selection = {}; // mirrors modal structure
        assessors.forEach((assessor: Compliance.FormRevisionAssessorModel) => {
            selection[assessor.assessorId] = {
                depreciationTableId: schedule.depreciationTableId,
                indexTableId: null
            }
        });

        const result: AssessorTablePickerResult = {
            selection: selection
        };

        // save assets with all assessors associated to factor table
        return await this._updateAssessorFactorTableAssignments(result);
    }

    private async _updateNonDepreciableSchedule(assessors): Promise<boolean> {
        const selection = {}; // mirrors modal structure
        assessors.forEach((assessor: Compliance.FormRevisionAssessorModel) => {
            selection[assessor.assessorId] = {
                depreciationTableId: null,
                indexTableId: null
            }
        });

        const result: AssessorTablePickerResult = {
            selection: selection
        };

        // save assets with all assessors
        return await this._updateAssessorFactorTableAssignments(result);
    }

    private async _updateLockedSchedule(reportingAssetIds: number[], schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel, assessors, allAssessors) : Promise<boolean>{
        const reportingAssetAssessorMap = await lastValueFrom(this._returnAssetRepository.getWorkingSetReportingAssetAssessorsByFilingBatch(this._returnService.filingBatchId, {
            reportingAssetIds: reportingAssetIds,
            formRevisionId: this._returnService.formRevisionId,
            parcelIds: [],
        }));

        // check to see if all are locked.  If so, setup the update.
        const nullAssessor = schedule.lockedAssessors.find(x => x.assessorId === null);
        const areAllLocked = reportingAssetAssessorMap.every(x => {
            let assessorAssignment = schedule.lockedAssessors.find(la => la.assessorId === x.assessorId);
            if (!assessorAssignment) assessorAssignment = nullAssessor;
            return assessorAssignment.isLocked;
        });

        const selection: { [assessorId: number]: LockedAssessorAssignments } = {}; // mirrors modal structure
        reportingAssetAssessorMap.forEach((x: Compliance.ReportingAssetAssessorModel) => {
            let assessorAssignment = schedule.lockedAssessors.find(la => la.assessorId === x.assessorId);
            if (!assessorAssignment) assessorAssignment = nullAssessor;

            if (assessorAssignment.isLocked) {
                selection[x.assessorId] = {
                    depreciationTableId: assessorAssignment.depreciationFactorTableId,
                    indexTableId: null
                }
            }
        });

        const result: AssessorTablePickerResult = {
            selection: selection
        };

        if (areAllLocked) {
            // update assignments with all assessors associated to factor table
            return await this._updateAssessorFactorTableAssignments(result);
        } else {
            // open dialog, with locked assets already assigned.
            return await this._askForFactorAndUpdate(reportingAssetIds, schedule, assessors, allAssessors, selection);
        }
    }

    private async _askForFactorAndUpdate(
        reportingAssetIds: number[],
        schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel,
        assessors,
        allAssessors,
        lockedAssessorAssignments: { [assessorId: number]: LockedAssessorAssignments } = {}): Promise<boolean> {
        const model: Compliance.AssessorFactorTableSearchModel = {
            assessorIds: assessors.map(assessor => assessor.assessorId),
            formRevisionScheduleId: schedule.formRevisionScheduleId
        };

        const assessorFactorTableResult: Compliance.AssessorFactorTableModel[] = await lastValueFrom(this._returnAssetRepository.getAssessorFactorTables(this._returnService.filingBatchId, model));

        const assessorFactorTables = assessorFactorTableResult.filter(x => x.assessorId !== null && !x.isCompany);
        const defaultAssessorFactorTables = assessorFactorTableResult.find(x => x.assessorId === null && !x.isCompany);
        const defaultFactorTables = defaultAssessorFactorTables ? defaultAssessorFactorTables.factorTables : [];
        const defaultAssessor = allAssessors.find((x: Compliance.FormRevisionAssessorModel) => x.assessorId === null);
        const companyDefaultAssessorTables = assessorFactorTableResult.filter(x => x.isCompany && x.assessorId === null);
        const companyAssessorTables = assessorFactorTableResult.filter(x => x.isCompany && x.assessorId !== null);

        const params: AssessorTablePickerParams = {
            defaultAssessor: defaultAssessor,
            reportingAssetIds: reportingAssetIds,
            assessors: assessors,
            defaultFactorTables: [...companyDefaultAssessorTables.reduce((acc, x) => [...acc, ...x.factorTables], []), ...defaultFactorTables],
            assessorFactorTables: [...companyAssessorTables, ...assessorFactorTables],
            lockedAssessorAssignments: lockedAssessorAssignments
        };

        this._hideBusyIndicator();

        const result = await this._modalService.showAsync(AssessorTablePickerComponent, params, 'modal-xl');

        if (result && result.selection) {
            this._showBusyIndicator('Saving');

            return await this._updateAssessorFactorTableAssignments(result);
        } else {
            return false;
        }
    }

    private _showBusyIndicator(message: string): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this._busyRefId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this._busyRefId,
            message
        });

    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
    }
}
