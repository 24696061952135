<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<div class="modal-header">
    <h4 class="modal-title">Layout</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>

<div class="modal-body exclude-modal-styling">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.layoutName.touched && form.controls.layoutName.errors }">
                    <label for="layoutName">Name:</label>
                    <input type="text" class="form-control" id="layoutName" placeholder="Name" formControlName="layoutName" autocomplete="off" />
                    <div *ngIf="form.controls.layoutName.touched && form.controls.layoutName.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.layoutName.errors.required">Name is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.description.touched && form.controls.description.errors }">
                    <label for="description">Description:</label>
                    <input type="text"
                           class="form-control"
                           placeholder="Description"
                           autocomplete="off"
                           formControlName="description"
                           maxlength="50"/>
                    <div *ngIf="form.controls.description.touched && form.controls.description.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.description.errors.maxlength">Description max length is 50 characters.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.visibility.touched && form.controls.visibility.errors }">
                    <label for="visibility">Visibility:</label>
                    <span [popover]="visibilityTooltip" placement="auto" triggers="mouseenter:mouseleave" class="ag-grid-layout-specification-details-visibility-tooltip">
                        <i class="fa fa-info-circle small"></i>
                    </span>
                    <select id="visibility"
                            class="form-select form-select-sm"
                            formControlName="visibility">
                        <option *ngFor="let v of params.visibilities" [ngValue]="v">{{ v.name }}</option>
                    </select>
                    <div *ngIf="form.controls.visibility.touched && form.controls.visibility.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.visibility.errors.required">Visibility is required.</div>
                    </div>
                    <div class="ag-grid-layout-specification-details-visibility-description small">
                        {{ params.companyId ? form.controls.visibility.value?.description : form.controls.visibility.value?.descriptionNoCompany }}
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <div class="d-flex">
        <div class="d-flex">
            <div class="text-warning" *ngIf="form.controls.visibility.value?.warning">
                <span>
                    <i class="fa fa-warning"></i>
                </span>
                {{ form.controls.visibility.value?.warning }}
            </div>
        </div>
        <div class="d-flex">
            <div class="mx-1" helpTooltip helpContentId="app.save">
                <button type="button" class="flat-button primary-button" (click)="save()" [disabled]="!form.valid">Save</button>
            </div>
            <div class="mx-1" helpTooltip helpContentId="app.cancel">
                <button type="button" class="flat-button warning-button" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>

<ng-template #visibilityTooltip>
    <div class="ws-flex-container-vertical">
        <ul class="ag-grid-layout-specification-details-visibility-list">
            <li *ngFor="let v of params.visibilities">
                <strong>{{ v.name }}</strong>: {{ params.companyId ? v.description : v.descriptionNoCompany }}
            </li>
        </ul>
    </div>
</ng-template>
