import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AssetRepository } from '../../Repositories';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AssetLienDateDetailsComponent, AssetLienDateDetailsParams } from '../Asset-Lien-Date-Details/assetLienDateDetails.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ASSET_LIEN_DATE_HELP } from './assetLienDate.component.help';
import { WeissmanDateFormat } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';

import * as _ from 'lodash';

export interface AssetLienDate {
    date: Date;
    useStateLienDate: boolean;
    lienDateType: Compliance.AssetLienDateTypeEnum;
}

export interface LienDateItem {
    date: Date;
    isCustom: boolean;
    isCurrent: boolean;
    isMarked?: boolean;
}

@Component({
    selector: 'asset-lien-date',
    templateUrl: './assetLienDate.component.html'
})
export class AssetLienDateComponent implements OnInit, OnChanges {
    constructor(
        private readonly _assetRepository: AssetRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService
    ) { }

    @Input() providedLienDateInfo: Compliance.AssetLienDateModel;
    @Input() useSavedLienDate: boolean = false;
    @Input() saveLienDate: boolean = false;
    @Input() useCompanyStateLienDate: boolean = false;
    @Input() companyId: number;
    @Input() showFilters: boolean = false;
    @Input() allowNonStandardLienDate: boolean = false;
    @Input() isDisabled: boolean = false;
    @Input() selectedLienDate: Date;
    @Input() markedDates: Date[] = [];
    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() size: string = 'sm';
    @Output() dateChange: EventEmitter<AssetLienDate> = new EventEmitter<AssetLienDate>();

    lienDateInfo: Compliance.AssetLienDateModel;
    lienDate: AssetLienDate;
    lienDateItem: LienDateItem;
    lienDateItems: LienDateItem[] = [];
    utcStateLienDates: Compliance.StateLienDateModel[];
    isLoading: boolean;

    private _currentYear: number;
    private _yearsBack: number = 3;

    formatDateOption = (ldi: LienDateItem) => {
        return `${ldi.isCustom  ? 'Non-Standard' : WeissmanDateFormat(ldi.date)}${ldi.isMarked ? ' *' : ''}`;
    };

    currentLienDate = (ldi: LienDateItem) => {
        return ldi.isCurrent ? 'asset-lien-date-current' : null;
    };

    private _sortByDate = (a: LienDateItem, b: LienDateItem) => (a.date < b.date || b.isCustom) ? 1 : ((b.date < a.date) ? -1 : 0);

    get isLienDateCustom(): boolean {
        if (!this.lienDateInfo || !this.lienDateItem || !this.lienDateItem.isCustom) {
            return false;
        }

        return !!this._findStandardLienDate(this.lienDateItems, this.lienDate.date);
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ASSET_LIEN_DATE_HELP);
        this._currentYear = new Date().getFullYear();

        try {
            this.isLoading = true;

            if (this.providedLienDateInfo) {
                this.lienDateInfo = this.providedLienDateInfo;
            } else {
                const requestModel: Compliance.AssetLienDateRequestModel = {
                    companyId: this.companyId,
                    useSavedLienDate: this.useSavedLienDate,
                    useCompanyStateLienDate: this.useCompanyStateLienDate
                };

                this.lienDateInfo = await lastValueFrom(this._assetRepository.getLienDates(requestModel));
            }

            // convert state lien dates to get utc values for comparison
            this.utcStateLienDates = this.lienDateInfo.stateLienDates.map(x => {
                const utcDate = this._createDateFromStateLienDate(new Date().getFullYear(), x);
                return { month: utcDate.getMonth() + 1, day: utcDate.getDate(), states: x.states };
            });

            // Check if the previously selected date is before the default year offset value and
            // add the number of years needed to include it
            const minYear = new Date().getFullYear() - this._yearsBack;
            if ((this.selectedLienDate && this.selectedLienDate.getFullYear() < minYear)
                || (this.lienDateInfo.lienDateSetting && this.lienDateInfo.lienDateSetting.getFullYear() < minYear)) {
                const year = this.selectedLienDate || this.lienDateInfo.lienDateSetting;
                this._yearsBack = new Date().getFullYear() - year.getFullYear();
            }

            this.lienDateItems = this._createLienDateList();

            // set the current lien date
            const currentDate = new Date();
            const currentLienDateItem = this.lienDateItems.find(x => (!x.isCustom) && x.date.getTime() <= currentDate.getTime());
            const customLienDateItem = this.lienDateItems.find(x => x.isCustom);

            if (currentLienDateItem) {
                currentLienDateItem.isCurrent = true;
                if (customLienDateItem && !this.lienDateInfo.lienDateSetting) {
                    customLienDateItem.date = currentLienDateItem.date;
                }
            }

            if (this.selectedLienDate) {
                this._selectLienDate();
            } else {
                // set the user selected lien date
                let selectedDate: Date = currentLienDateItem.date;
                if (this.lienDateInfo.lienDateSetting) {
                    const standardDateItem = this._findStandardLienDate(this.lienDateItems, this.lienDateInfo.lienDateSetting);
                    selectedDate = standardDateItem.date;
                    if (customLienDateItem && standardDateItem) {
                        customLienDateItem.date = selectedDate;
                    }
                    this.lienDateItem = standardDateItem || customLienDateItem;
                } else {
                    this.lienDateItem = currentLienDateItem || customLienDateItem;
                }

                // set lien date
                this.lienDate = {
                    date: selectedDate,
                    useStateLienDate: false,
                    lienDateType: Compliance.AssetLienDateTypeEnum.Prior
                };

                this.dateChange.emit(this.lienDate);
            }

            this._markDates();

            this._filterDates();
        }
        finally {
            this.isLoading = false;
        }
    }

    trackByFn(index: number, item: LienDateItem) {
        return item.date.getTime();
    }

    async lienDateChanged(lienDateItem: LienDateItem): Promise<void> {
        this.lienDateItem = lienDateItem;
        this.lienDate.date = lienDateItem.date;

        if (!lienDateItem.isCustom) {
            const customLienDate = AssetLienDateComponent._findCustomLienDate(this.lienDateItems);
            if (customLienDate) {
                customLienDate.date = lienDateItem.date;
            }
        }

        this.dateChange.emit(this.lienDate);

        if (this.saveLienDate) {
            await this._assetRepository.updateLienDateUserSetting(this.lienDate.date);
        }
    }

    async customLienDateChanged(closeObject: any): Promise<void> {
        const holder: any = closeObject ? closeObject.obj : null;

        if (holder) {
            holder.input.blur();

            this.lienDate.date = new Date(Date.UTC(holder.currentYear, holder.currentMonth, holder.currentDay));

            const standardLienDate = this._findStandardLienDate(this.lienDateItems, this.lienDate.date);

            let customLienDate: LienDateItem;
            if (!standardLienDate) {
                customLienDate = AssetLienDateComponent._findCustomLienDate(this.lienDateItems);
                if (customLienDate) {
                    customLienDate.date = this.lienDate.date;
                }
            }

            this.lienDateItem = standardLienDate || customLienDate;

            await this.lienDateChanged(this.lienDateItem);
        }
    }

    async edit(): Promise<void> {
        const params: AssetLienDateDetailsParams = {
            lienDateInfo: _.cloneDeep(this.lienDateInfo),
            lienDateItems: _.cloneDeep(this.lienDateItems),
            lienDateItem: null,
            lienDate: _.clone(this.lienDate),
            utcStateLienDates: this.utcStateLienDates,
            stateLienDates: this.lienDateInfo.stateLienDates,
            allowNonStandardLienDate: this.allowNonStandardLienDate
        };

        const standardLienDate = this._findStandardLienDate(params.lienDateItems, this.lienDateItem.date);

        let customLienDate: LienDateItem;
        if (!standardLienDate) {
            customLienDate = AssetLienDateComponent._findCustomLienDate(params.lienDateItems);
        }

        params.lienDateItem = standardLienDate || customLienDate;

        const result = await this._modalService.showAsync(AssetLienDateDetailsComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this.lienDate = result;

        // Check if the selected date is before the available year values and
        // add the number of years needed to include it
        if (result.date && result.date.getFullYear() < (new Date().getFullYear() - this._yearsBack)) {
            this._yearsBack = new Date().getFullYear() - result.date.getFullYear();
            this.lienDateItems = this._createLienDateList();
        }

        const standardLienDateResult = this._findStandardLienDate(this.lienDateItems, result.date);

        let customLienDateResult: LienDateItem;
        if (!standardLienDateResult) {
            customLienDateResult = this.lienDateItems.find( x => x.isCustom);
            if (customLienDateResult) {
                customLienDateResult.date = result.date;
            }
        }

        this.lienDateItem = standardLienDateResult || customLienDateResult;
        await this.lienDateChanged(this.lienDateItem);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.markedDates && !changes.markedDates.firstChange) {
            this.markedDates = changes.markedDates.currentValue;
            this._markDates();
        }

        if (changes.selectedLienDate && !changes.selectedLienDate.firstChange) {
            this.selectedLienDate = changes.selectedLienDate.currentValue;
            this._selectLienDate();
        }

        if (changes.minDate && !changes.minDate.firstChange) {
            this.minDate = changes.minDate.currentValue;
            this._filterDates();
        }

        if (changes.maxDate && !changes.maxDate.firstChange) {
            this.maxDate = changes.maxDate.currentValue;
            this._filterDates();
        }
    }

    showMoreClicked(): void {
        if (!(this.minDate && (new Date().getFullYear() - this._yearsBack) <= this.minDate.getFullYear())) {
            this._yearsBack++;
        }
        this.lienDateItems = this._createLienDateList();
    }

    private _createLienDateList(): LienDateItem[] {
        // add custom lien date
        const customLienDateItem: LienDateItem = {
            isCustom: true,
            isCurrent: false,
            date: (this.lienDateInfo.lienDateSetting) ? this.lienDateInfo.lienDateSetting : new Date()
        };

        let lienDateItems = this.lienDateInfo.stateLienDates
                                .reduce((acc, x) => {
                                    const currentYear = new Date().getFullYear();
                                    for (let i = -this._yearsBack; i <= 1; i++) {
                                        const date = this._createDateFromStateLienDate(currentYear + i, x);
                                        if (date < this.minDate) {
                                            continue;
                                        }
                                        let lienDateItem: LienDateItem = this.lienDateItems.find(x => x.date.getTime() == date.getTime());
                                        if (!lienDateItem) {
                                            lienDateItem = {
                                                isCustom: false,
                                                isCurrent: false,
                                                date
                                            };
                                        }
                                        acc.push(lienDateItem);
                                    }
                                    return acc;
                                }, [customLienDateItem])
                                .sort(this._sortByDate);

        if (!this.allowNonStandardLienDate) {
            lienDateItems = lienDateItems.filter(i => !i.isCustom);
        }

        return lienDateItems;
    }

    private _filterDates(): void {
        if (this.lienDateItems) {
            if (this.minDate) {
                this.lienDateItems = this.lienDateItems.filter(x => x.date.getTime() >= this.minDate.getTime());
            }

            if (this.maxDate) {
                this.lienDateItems = this.lienDateItems.filter(x => x.date.getTime() <= this.maxDate.getTime());
            }

            if (this.selectedLienDate && !this.lienDateItems.length) {
                this.lienDateItems = [{date: this.selectedLienDate, isCustom: false, isCurrent: false }];
                this._selectLienDate();
                this.isDisabled = true;
            } else if (this.selectedLienDate && this.lienDateItems.length && !this.lienDateItems.find(x => x.date.getTime() === this.selectedLienDate.getTime())) {
                this.selectedLienDate = this.lienDateItems.find(x => x.date.getTime() === Math.max.apply(Math, this.lienDateItems.map(y => y.date.getTime()))).date;

                this._selectLienDate();
            }
        }
    }

    private _findStandardLienDate(lienDateItems: LienDateItem[], date: Date): LienDateItem {
        const lienDate = lienDateItems.find(x => (!x.isCustom) && x.date.getFullYear() === date.getFullYear() &&
            x.date.getMonth() === date.getMonth() && x.date.getDay() === date.getDay());
        if (lienDate) {
            return lienDate;
        }
        const hasMonthDay = this.utcStateLienDates.findIndex(x => x.month === (date.getMonth() + 1) && x.day === date.getDate());
        if (hasMonthDay === -1) {
            const addLienDate: LienDateItem = {
                isCustom: false,
                isCurrent: false,
                date: this._createDateFromStateLienDate(date.getFullYear(), {day: date.getDate(), month: date.getMonth() + 1, states: []} as Compliance.StateLienDateModel)
            };

            lienDateItems.push(addLienDate);
            lienDateItems.sort(this._sortByDate);
            return addLienDate;
        }
    }

    private static _findCustomLienDate(lienDateItems: LienDateItem[]): LienDateItem {
        return lienDateItems.find(x => x.isCustom);
    }

    private _createDateFromStateLienDate(year: number, lienDate: Compliance.StateLienDateModel): Date {
        return new Date(Date.UTC(year, lienDate.month - 1, lienDate.day));
    }

    private _markDates() {
        if (this.markedDates) {
            this.lienDateItems.forEach(x => x.isMarked = !!this.markedDates.find(y => y.getTime() === x.date.getTime()));
        }
    }

    private _selectLienDate() {
        this.lienDateItem = this._findStandardLienDate(this.lienDateItems, this.selectedLienDate);

        this.lienDate = {
            date: this.selectedLienDate,
            useStateLienDate: false,
            lienDateType: Compliance.AssetLienDateTypeEnum.Prior
        };
    }
}
