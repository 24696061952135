import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class LinkedParcelRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }
    
    getList(parcelId: number, includeUnlinked: boolean = false): Observable<Core.LinkedParcelDTO[]> {
        return this.httpGet(`/api/parcelLinkage/parcel/${parcelId}?includeUnlinked=${includeUnlinked}`);
    }

    getLinkageTypes(): Observable<Core.LinkageTypeModel[]> {
        return this.httpGet('/api/parcelLinkage/types');
    }

    createOrUpdate(model: Core.LinkedParcelInputDTO): Observable<Core.LinkedParcelInfoDTO> {
        return this.httpPost(`/api/parcelLinkage/parcels`, model);
    }
}
