import { Component, OnInit, Input } from '@angular/core';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { DeadlineService } from '../deadline.service';
import * as _ from 'lodash';
import { PropertyTypeService } from '../../Common/Services/propertyType.service.upgrade';
import { NavigationService } from '../../Layout/navigation.service';
import { efAction } from '../../constants.new';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AssessorExceptionsParams, AssessorExceptionsModalComponent } from '../Assessor-Exceptions/assessorExceptionsModal.component';
import { StateRepository } from '../../Core-Repositories';
import { Assessor } from '../../Assessor-Collector/assessor.model';

interface AppealDeadlineType {
    appealDeadlineTypeID: number;
    deadlineType: string;
    sequence: number;
}

export interface AppealDeadlineModel extends Core.AppealDeadlineModel {
    efAction?: efAction;
    fromDate?: Date;
}

interface DeadlinesByDeadlineType {
    appealDeadlineTypeID: number;
    deadlineType: string;
    deadlines: AppealDeadlineModel[];
}

interface LienDateTooltipInfo {
    showTooltip: boolean;
    tooltipDate?: string;
}

export interface AppealDeadlinePayload {
    deadline: AppealDeadlineModel;
    doUpdate: boolean;
    fromDate: string;
}

export interface UpdateDeadlineResponseConfirmation {
    skipped: number;
    total: number;
}

interface UnassignedDeadlinePropType  {
    display: string;
    deadlineType: AppealDeadlineType;
    propertyType: Weissman.Model.Assessments.PropertyType;
}

@Component({
    selector: 'deadline-list-panel',
    templateUrl: './deadlineListPanel.component.html',
    styleUrls: ['./deadlineListPanel.component.scss']
})
export class DeadlineListPanelComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _deadlineService: DeadlineService,
        private readonly _propertTypeService: PropertyTypeService,
        private readonly _navigationService: NavigationService,
        private readonly _toastrService: ToastrService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: WeissmanModalService,
        private readonly _stateRepository: StateRepository
    ) {}

    @Input() stateId: number;
    @Input() assessorId: number;
    @Input() assessor: Assessor;
    @Input() state: Weissman.Model.Misc.State;


    hasEditPermission: boolean = false;
    years: (number | string)[];
    selectedYear: number | string;
    deadlinesByDeadlineType: DeadlinesByDeadlineType[];
    editMode: boolean = false;
    serverAction: boolean = false;
    checkUseEarliestPossible: boolean = false;
    unassignedDeadlinePropTypes: UnassignedDeadlinePropType[];
    chosenDeadlineProptype: UnassignedDeadlinePropType;
    readonly YEAR_TYPES: {id: number, name: string}[] = [{ id: -1, name: 'Prior' }, { id: 0, name: 'Current' }, { id: 1, name: 'Next' }, { id: 2, name: 'Next Next' }];

    private _propertyTypes: Weissman.Model.Assessments.PropertyType[];
    private _availableDeadlineTypes: AppealDeadlineType[];
    private _flatDeadlines: AppealDeadlineModel[];
    private _orignalSelectedYear: number;
    private _originalDeadlines: AppealDeadlineModel[];
    private _doUpdate = false;
    private _impactedEntities: UpdateDeadlineResponseConfirmation;

    private get _entityString (): 'state' | 'assessor' {
        return this.isStatePage ? 'state' : 'assessor';
    }
    private get _undeletedFlatDeadlines(): AppealDeadlineModel[] {
        return _.reject(this._flatDeadlines, {efAction: 'delete'});
    }
    private get _entityId(): number {
        return this.isStatePage ? this.stateId : this.assessorId;
    }
    private get _isOnDefault(): boolean {
        return this.selectedYear === 'Default' || this.isStatePage;
    }
    get isStatePage(): boolean {
        return !this.assessorId;
    }

    async ngOnInit() {
        await this._loadData();

        this.hasEditPermission = this._restrictService.isInRole(Roles.STATEEDIT);
		// this.hasContactRoles = !this._restrictService.isNotInRoles([Roles.RYANPRIVATEITEMSVIEW, Roles.RYANPRIVATEITEMSEDIT]);
    }

    getDeadlineDateStr(deadline: AppealDeadlineModel) {
        return `${deadline.deadlineMonthDay}/${deadline.deadlineYearID + (this.selectedYear as number)}`;
    }

    setDirty(deadline: AppealDeadlineModel) {
        if (deadline.efAction !== 'add') {
            deadline.efAction = 'update';
        }
    }

    yearChanged() {
        if(!this.isStatePage) {
            this._getDeadlinesByYear();
        }
    }

    deadlineHasExceptions(deadline: AppealDeadlineModel) {
        return deadline.hasDefaultExceptions
            || (deadline.yearsWithNonDefaultExceptions && deadline.yearsWithNonDefaultExceptions.includes(this.selectedYear as number));
    }

    edit() {
        this._orignalSelectedYear = this.selectedYear as number;
        this._originalDeadlines = _.cloneDeep(this._flatDeadlines);

        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');

        this._getDeadlinesByYear();

        if(!this.isStatePage) {
            this._updateYears();
        }
    }

    cancel() {
        this._flatDeadlines = this._originalDeadlines;

        this.editMode = false;
        this._navigationService.disableNavWarning();

        this._getDeadlinesByYear();

        if(!this.isStatePage) {
            this._updateYears();
        }
    }

    delete(deadline: AppealDeadlineModel) {
        if (deadline.efAction === 'add') {
            _.remove(this._flatDeadlines, deadline);
        } else {
            deadline.efAction = 'delete';
        }
    }

    addDeadline(): void {
        const newDeadline: AppealDeadlineModel = {
            efAction: 'add',
            appealDeadlineID: 0,
            appealDeadlineTypeID: this.chosenDeadlineProptype.deadlineType.appealDeadlineTypeID,
            deadlineDate: null,
            deadlineMonthDay: this._isOnDefault ? '' : null,
            deadlineYearID: this._isOnDefault ? 0 : null,
            taxYear: this._isOnDefault ? null : Number(this.selectedYear),
            deadlineType: this.chosenDeadlineProptype.deadlineType.deadlineType,
            propertyTypeAbbr: this.chosenDeadlineProptype.propertyType.propTypeAbbr,
            propertyTypeID: this.chosenDeadlineProptype.propertyType.propertyTypeID,
            propertyTypeName: this.chosenDeadlineProptype.propertyType.propTypeName,
            earliestPossible: false,
            yearsWithNonDefaultExceptions: [],
            hasDefaultExceptions: false,
            entityID: this._entityId,
            entityTypeID: this.isStatePage ? Core.EntityTypes.State : Core.EntityTypes.Assessor
        };

        this._flatDeadlines.push(newDeadline);

        setTimeout(() => {
            this.chosenDeadlineProptype = undefined;
        });

        this._getDeadlinesByYear();
    }

    async openAssessorExceptions(deadline: AppealDeadlineModel): Promise<void> {
        const params: AssessorExceptionsParams = {
            deadline,
            stateId: this.stateId,
            selectedYear: this.selectedYear
        };

        await this._modalService.showAsync(AssessorExceptionsModalComponent, params, 'modal-md');
    }

    async save () {
        const isMissingFromDate = _.some(this._undeletedFlatDeadlines, x => {
            return x.appealDeadlineTypeID > 0 && x.efAction && !x.fromDate;
        });

        if(isMissingFromDate) {
            this._toastrService.error('Missing From Date');
            return;
        }

        const invalidDateDeadline = _.find(this._undeletedFlatDeadlines, x => {
            if (x.deadlineMonthDay !== null) {
                return !moment(x.deadlineMonthDay, 'MM/DD').isValid();
            }

            return false;
        });

        if (invalidDateDeadline) {
            this._toastrService.error(`Invalid date entered: ${  invalidDateDeadline.deadlineMonthDay}`);
            return;
        }

        this.serverAction = true;
        try {
            const responses = await this._sendRequests();
            this.serverAction = false;

            if(!responses.length) {
                this.editMode = false;
                this._navigationService.disableNavWarning();

                return;
            }

            const message = `${this._impactedEntities.total} parcel deadline(s) will be affected. ${this._impactedEntities.skipped} will be skipped.`;

            try {
                await this._messageModalService.confirm(message);
            } catch (e3) {
                return Promise.resolve();
            }

            this._doUpdate = true;
            this.serverAction = true;
            await this._sendRequests();
            await this._loadData();

            this.editMode = false;
            this._navigationService.disableNavWarning();

            if(!this.isStatePage) {
                this._updateYears();
            }
        } finally {
            this._doUpdate = false;
            this.serverAction = false;
        }
    }

    private async _sendRequests(): Promise<any[]> {
        const deadlinePromises = [];
        this._impactedEntities = {
            skipped: 0,
            total: 0
        };

        if (this.isStatePage){
            const payload = _.chain(this._undeletedFlatDeadlines)
                .filter('efAction')
                .map(this._getDeadlinePayload.bind(this))
                .value();

            if(payload.length) {
                const statePromise = this._deadlineService.saveForState(this.stateId, payload)
                    .then(this.handleCRUDResult.bind(this));

                deadlinePromises.push(statePromise);
            }

            const deletedStateDeadlines = _.filter(this._flatDeadlines, {efAction: 'delete'});
            _.forEach(deletedStateDeadlines, deadline => {
                const promise = this._deadlineService.remove(this._entityString, this._entityId, deadline.appealDeadlineID, this._doUpdate)
                    .then(this.handleCRUDResult.bind(this));

                deadlinePromises.push(promise);
            });
        }
        else {
            _.forEach(this._flatDeadlines, deadline => {
                const payload = this._getDeadlinePayload(deadline);
                let promise;

                switch (deadline.efAction) {
                    case 'update':
                        promise = this._deadlineService.update(this._entityString, this._entityId, payload)
                            .then(this.handleCRUDResult.bind(this));
                        break;
                    case 'add':
                        promise = this._deadlineService.create(this._entityString, this._entityId, payload)
                            .then(this.handleCRUDResult.bind(this));
                        break;
                    case 'delete':
                        promise = this._deadlineService.remove(this._entityString, this._entityId, deadline.appealDeadlineID, this._doUpdate)
                            .then(this.handleCRUDResult.bind(this));
                        break;
                }

                deadlinePromises.push(promise);
            });
        }

        return Promise.all(deadlinePromises);
    }

    private handleCRUDResult(result) {
        if(this._doUpdate) return;

        const { skipped, total } = result as UpdateDeadlineResponseConfirmation;
        this._impactedEntities.skipped += skipped;
        this._impactedEntities.total += total;
    }

    private _getDeadlinePayload(deadline: AppealDeadlineModel): AppealDeadlinePayload {
        return {
            deadline: _.omit(deadline, 'fromDate') as AppealDeadlineModel,
            doUpdate: this._doUpdate,
            fromDate: moment(deadline.fromDate).utc().format('MM/DD/YYYY')
        };
    }

    private async _loadData() {
        this.serverAction = true;

        try {
            const years = this.isStatePage
                ? await this._deadlineService.getAvailableStateYears(this.stateId)
                : await this._deadlineService.getAvailableAssessorYears(this.assessorId);

            const currentYear = new Date().getFullYear();

            this.years = _.chain(years)
                .map('years')
                .union(_.range(currentYear - 5, currentYear + 4))
                .uniq()
                .orderBy([], ['desc'])
                .value();

            this.selectedYear = currentYear;

            this._propertyTypes = await this._propertTypeService.get();
            this._availableDeadlineTypes = await this._deadlineService.getAppealDeadlineTypes();
            this._flatDeadlines = await this._deadlineService.getStateDeadlines(this.stateId) as AppealDeadlineModel[];

            if(this.isStatePage) {
                this._availableDeadlineTypes.push({appealDeadlineTypeID: -1, deadlineType: 'Fiscal Dates', sequence: 1000});
            } else {
                const assessorDeadlines = await this._deadlineService.getAssessorDeadlines(this.assessorId) as AppealDeadlineModel[];
                this._flatDeadlines = _.union(this._flatDeadlines, assessorDeadlines);
            }

            this.checkUseEarliestPossible = _.some(this._flatDeadlines, 'earliestPossible');

            this._getDeadlinesByYear();
        }
        finally {
            this.serverAction = false;
        }
    }

    private _getDeadlinesByYear() {
        this.deadlinesByDeadlineType = _.chain(this._flatDeadlines)
            .groupBy(x => {
                return x.appealDeadlineTypeID < 0 ? -1 : x.appealDeadlineTypeID;
            })
            .map(x => this._getDeadlinesByType(x))
            .sortBy(deadlinesByDeadlineType => {
                const deadlineType = _.find(this._availableDeadlineTypes, {appealDeadlineTypeID: deadlinesByDeadlineType.appealDeadlineTypeID});
                return deadlineType ? deadlineType.sequence : 99;
            })
            .value();

        this.unassignedDeadlinePropTypes = _.reduce(this._availableDeadlineTypes, (unassigned, deadlineType) => {
            if(deadlineType.appealDeadlineTypeID < 0) {
                return unassigned;
            }

            const usedDeadlineType = _.find(this.deadlinesByDeadlineType, {appealDeadlineTypeID: deadlineType.appealDeadlineTypeID});
            const unassignedForDeadlineType = _.chain(this._propertyTypes)
                .reject(x => {
                    return usedDeadlineType && _.some(usedDeadlineType.deadlines, {propertyTypeID: x.propertyTypeID});
                })
                .map(x => {
                    return {
                        display: `${deadlineType.deadlineType} (${x.propTypeAbbr})`,
                        deadlineType: deadlineType,
                        propertyType: x
                    };
                })
                .value();

            return [...unassigned, ...unassignedForDeadlineType];
        }, []);
    }

    private _getDeadlinesByType(deadlinesByDeadlineType: Core.AppealDeadlineModel[]): DeadlinesByDeadlineType {
        const deadlines = _.chain(deadlinesByDeadlineType)
            .groupBy('propertyTypeID')
            .map((deadlineArr) => {
                if(this.isStatePage) {
                    return deadlineArr;
                } else {
                    if(this.editMode) {
                        const assessorTaxYearDeadline = _.find(deadlineArr, {taxYear: this.selectedYear});
                        const assessorDefaultDeadline = _.find(deadlineArr, {taxYear: null, entityID: this.assessorId});

                        return assessorTaxYearDeadline || (this.selectedYear === 'Default' && assessorDefaultDeadline);

                    } else {
                        const assessorTaxYearDeadline = _.find(deadlineArr, {taxYear: this.selectedYear});
                        const assessorDefaultDeadline = _.find(deadlineArr, {taxYear: null, entityID: this.assessorId});
                        const stateDefaultDeadline = _.find(deadlineArr, {taxYear: null, entityID: this.stateId});
                        const fiscalDatesDeadlines = _.filter(deadlineArr, {appealDeadlineID: 0});

                        return assessorTaxYearDeadline || assessorDefaultDeadline || stateDefaultDeadline || fiscalDatesDeadlines;
                    }
                }
            })
            .flatten()
            .compact()
            .sortBy(deadline => {
                const propertyType = _.find(this._propertyTypes, {propertyTypeID: deadline.propertyTypeID});
                return propertyType ? propertyType.sequence : 99;
            })
            .value();

        return {
            appealDeadlineTypeID: deadlinesByDeadlineType[0].appealDeadlineTypeID,
            deadlineType: deadlinesByDeadlineType[0].deadlineType,
            deadlines: deadlines as AppealDeadlineModel[]
        };
    }

    private _updateYears() {
        if(this.editMode) {
            this.years.unshift('Default');
        } else {
            if(this.selectedYear === 'Default') {
                this.selectedYear = this._orignalSelectedYear;
            }

            _.remove(this.years, x => x === 'Default');
        }
    }

    getHelpTooltip(appealDeadlineTypeID: number): string {
        const tooltipInfo = this._getTooltipInfo(appealDeadlineTypeID);

        return tooltipInfo.showTooltip
            ? `Personal Property Lien Date ${tooltipInfo.tooltipDate}`
            : null;
    }

    showTooltipIcon(appealDeadlineTypeID: number) {
        return this._getTooltipInfo(appealDeadlineTypeID).showTooltip;
    }

    private _getTooltipInfo(appealDeadlineTypeID: number): LienDateTooltipInfo {
        const tooltipInfo: LienDateTooltipInfo = {
            showTooltip: false
        };

        if (!this.isStatePage && appealDeadlineTypeID === -1) {
            if (this.state.ppLienDateDisplayMonthDay !== this.assessor.ppLienDateDisplayMonthDay && this.assessor.ppLienDateDisplayMonthDay) {
                tooltipInfo.showTooltip = true;
                tooltipInfo.tooltipDate = `${this.assessor.ppLienDateDisplayMonthDay  }/${  +this.selectedYear + this.assessor.ppLienDateYearOffset}`;
            } else if (this.state.lienDateMonthDay !== this.state.ppLienDateDisplayMonthDay && this.state.personalPropTaxable) {
                tooltipInfo.showTooltip = true;
                tooltipInfo.tooltipDate = `${this.state.ppLienDateDisplayMonthDay  }/${  +this.selectedYear + this.state.ppLienDateYearOffset}`;
            }
        }

        return tooltipInfo;
    }
}
