<ws-modal-wrapper headerLabel="Transfer"
                  acceptLabel="Transfer"
                  (save)="ok()"
                  (cancel)="cancel()">
    <div>
        Are you sure you want to start the transfer process?<br />
        The following are the summary of actions:<br />
        Insert: {{params.evaluatedInsertCount | number}} rows<br />
        Update: {{params.evaluatedUpdateCount | number}} rows<br />
        Delete: {{params.evaluatedDeleteCount | number}} rows<br />
        <span *ngIf="isAssetsImport">Unchanged: {{params.evaluatedNoChangeCount | number}} rows <span *ngIf="params.evaluatedNoChangeCount">(recording assets appeared on import)</span></span><br />
        <br />
        <span style="font-weight: bold" *ngIf="params.effectiveDate">Effective date: {{params.effectiveDate|date:'M/d/yyyy'}}</span><br />
        <span style="font-weight: bold" *ngIf="params.assetOwnershipType">Asset Ownership type: {{params.assetOwnershipType}}</span><br />
        <span style="font-weight: bold" *ngIf="params.accrualAdjustmentAmountValueType">Amount Value type: {{params.accrualAdjustmentAmountValueType}}</span>
    </div>
</ws-modal-wrapper>
