import { Component, Input } from '@angular/core'

@Component({
    selector: 'panel-chevron',
    template: `
        <div style="margin-right: 8px; display: inline-block;">
            <i class="fa" style="vertical-align: text-top;" [ngClass]="{'fa-chevron-right': !showBody, 'fa-chevron-down': showBody}"></i>
        </div>
    `
})
export class PanelChevronComponent {
    @Input() showBody: boolean;

    constructor() {}
}