import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface QueryInfoListGridActionCellRendererParams extends ICellRendererParams {
    viewDetails: (params: QueryInfoListGridActionCellRendererParams) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button"
            *ngIf="queryInfo"
            class="btn flat-button primary-button icon-button grid-action-button"
            (click)="params.viewDetails(params)"
            helpTooltip
            helpContentId="app.view"
            position="bottom">
            <i class="fa fa-pencil"></i>
        </button>`
})
export class QueryInfoListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: QueryInfoListGridActionCellRendererParams;
    queryInfo: Core.QueryInfoModel;

    agInit(params: QueryInfoListGridActionCellRendererParams): void {
        this.params = params;
        this.queryInfo = params.data as Core.QueryInfoModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
