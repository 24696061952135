import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

export interface IAgGridOverlayService {
    notifyColumnAdd: (params: AgGridOverlayColumn) => void;
    notifyColumnRemove: (params: number) => void;
    notifyColumnResize: (params: AgGridOverlayColumnsResizedEvent) => void;
    notifyGridHorizontalScroll: (params: number) => void;
    columnAdd$: Observable<AgGridOverlayColumn>;
    columnRemove$: Observable<number>;
    columnResize$: Observable<AgGridOverlayColumnsResizedEvent>;
    gridHorizontalScroll$: Observable<number>;
}

export interface AgGridOverlayColumnsResizedEvent {
    columns: AgGridOverlayColumn[]
}

export interface AgGridOverlayColumn {
    columnId: number;
    left: number;
    width: number;
}

@Injectable()
export class AgGridOverlayService implements IAgGridOverlayService {

    private _columnAddEmitter = new EventEmitter<AgGridOverlayColumn>();

    private _columnRemoveEmitter = new EventEmitter<number>();

    private _columnResizeEmitter = new EventEmitter<AgGridOverlayColumnsResizedEvent>();

    private _gridHorizontalScrollEmitter = new EventEmitter<number>();

    notifyColumnAdd(params: AgGridOverlayColumn): void {
        this._columnAddEmitter.emit(params);
    }

    notifyColumnRemove(params: number): void {
        this._columnRemoveEmitter.emit(params);
    }

    notifyColumnResize(params: AgGridOverlayColumnsResizedEvent): void {
        this._columnResizeEmitter.emit(params);
    }

    notifyGridHorizontalScroll(params: number): void {
        this._gridHorizontalScrollEmitter.emit(params);
    }

    get columnAdd$(): Observable<AgGridOverlayColumn> {
        return this._columnAddEmitter.asObservable();
    }

    get columnRemove$(): Observable<number> {
        return this._columnRemoveEmitter.asObservable();
    }

    get columnResize$(): Observable<AgGridOverlayColumnsResizedEvent> {
        return this._columnResizeEmitter.asObservable();
    }

    get gridHorizontalScroll$(): Observable<number> {
        return this._gridHorizontalScrollEmitter.asObservable();
    }
}
