<div class="input-group" style="width: 100%" aria-label="Select GL Account">
    <input id="selectGLAccount"
           type="text"
           placeholder="Filter GL Accounts..."
           class="form-control exclude-modal-styling"
           [ngClass]="{ 'overridden': isOverridden }"
           autocomplete="off"
           (blur)="onBlur()"
           [(ngModel)]="filter"
           container="body"
           [typeahead]="values$"
           typeaheadOptionField="displayName"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)"
           [typeaheadOptionsLimit]="1000"
           [disabled]="isDisabled"/>
</div>
