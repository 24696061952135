import { Injectable } from '@angular/core';
import { ValuationTemplateRepository } from '../../Core-Repositories/valuationTemplate.repository';
import { IExpandableComponent } from '../../UI-Lib/Expandable-Component/expandableComponent.model';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { IExpandableComponentContainer } from '../../UI-Lib/Expandable-Component/expandableComponentContainer.model';
import { DownloadFileService } from '../../Common/Download/downloadFileService';

@Injectable()
export class ValuationTemplateService implements IExpandableComponentContainer {
    constructor(private readonly _valuationTemplateRepository: ValuationTemplateRepository,
                private readonly _downloadService: DownloadFileService) {}

    private _expandedComponent: IExpandableComponent;

    private _activeTemplateSubject: BehaviorSubject<Core.ValuationTemplateModel> = new BehaviorSubject(null);
    private _activeTemplate$: Observable<Core.ValuationTemplateModel> = this._activeTemplateSubject.asObservable();

    set template(template: Core.ValuationTemplateModel) {
        this._activeTemplateSubject.next(template ? { ...template } : null);
    }

    get template$(): Observable<Core.ValuationTemplateModel> {
        return this._activeTemplate$;
    }

    get editGroup(): string {
        return 'valuation-template-group';
    }

    // events raised by components
    setExpandedComponent(component: IExpandableComponent): void {
        this._expandedComponent = component;
    }

    isComponentExpanded(component: IExpandableComponent): boolean {
        return this._expandedComponent === component;
    }

    isExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() === componentName);
    }

    isOtherComponentExpanded(componentName: string): boolean {
        return this._expandedComponent && (this._expandedComponent.getName() !== componentName);
    }

    async getTemplateList(): Promise<any[]> {
        return await lastValueFrom(this._valuationTemplateRepository.getTemplateList());
    }

    async addOrUpdateTemplate(template: Core.ValuationTemplateModel): Promise<Core.ValuationTemplateModel> {
        return await lastValueFrom(this._valuationTemplateRepository.addOrUpdateTemplate(template));
    }

    async updateIsInDevelopment(templateId: number, isInDevelopment: boolean): Promise<Core.ValuationTemplateModel> {
        var result = await lastValueFrom(this._valuationTemplateRepository.updateIsInDevelopment(templateId, isInDevelopment));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async getTemplateNamesWithDuplicateSettings(templateId: number): Promise<string[]> {
        return await lastValueFrom(this._valuationTemplateRepository.getTemplateNamesWithDuplicateSettings(templateId));
    }

    async updateTemplateStates(templateId: number, states: Core.ValuationTemplateStateModel[]): Promise<Core.ValuationTemplateModel> {
        const result = await lastValueFrom(this._valuationTemplateRepository.updateTemplateStates(templateId, states));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async updateTemplateAssessors(templateId: number, assessors: Core.ValuationTemplateAssessorModel[]): Promise<Core.ValuationTemplateModel> {
        const result = await lastValueFrom(this._valuationTemplateRepository.updateTemplateAssessors(templateId, assessors));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async getSiteClassOptions(){
        const result = await lastValueFrom(this._valuationTemplateRepository.getSiteClassOptions());
        return result;
    }

    async updateTemplateSiteClasses(templateId: number, siteClasses: Core.ValuationTemplateSiteClassPrimaryModel[]): Promise<Core.ValuationTemplateModel> {
        const result = await lastValueFrom(this._valuationTemplateRepository.updateTemplateSiteClasses(templateId, siteClasses));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async updateTemplateFile(templateId: number, file: File): Promise<Core.ValuationTemplateModel> {
        const result = await lastValueFrom(this._valuationTemplateRepository.updateTemplateFile(templateId, file));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async deleteTemplateFile(templateId: number): Promise<Core.ValuationTemplateModel> {
        const result = await lastValueFrom(this._valuationTemplateRepository.deleteTemplateFile(templateId));
        this._activeTemplateSubject.next(result);
        return result;
    }

    async downloadTemplateFile(templateId: number): Promise<void> {
        const response = await lastValueFrom(this._valuationTemplateRepository.downloadTemplateFile(templateId));
        await this._downloadService.downloadResponse(response);
    }

    getTemplateById(templateId: number): Promise<Core.ValuationTemplateModel> {
        return lastValueFrom(this._valuationTemplateRepository.getTemplateById(templateId));
    }

    deleteTemplate(templateId: number): Promise<void> {
        return lastValueFrom(this._valuationTemplateRepository.deleteTemplate(templateId));
    }

}
