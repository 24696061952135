import { Component, ViewEncapsulation } from '@angular/core';
import { HelpContentComponent, HelpService } from '../../UI-Lib/Help-Tooltip';

export interface AppealRecommendationTemplateLastSentHelpComponentParams{
    sentInfo: Core.AppealRecommendationEmailSentInfo[];
    totalParcelsCount: number;
}

@Component({
    selector: 'last-sent-tooltip',
    templateUrl: './appealRecommendationTemplateLastSentHelp.component.html',
    styleUrls: ['./appealRecommendationTemplate.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppealRecommendationTemplateLastSentHelpComponent implements HelpContentComponent<AppealRecommendationTemplateLastSentHelpComponentParams> {
    constructor(private readonly _helpService: HelpService) { }

    params: AppealRecommendationTemplateLastSentHelpComponentParams;
    sentInfos: Core.AppealRecommendationEmailSentInfo[];
    isSameDateSamePerson: boolean;

    async helpInit(params: AppealRecommendationTemplateLastSentHelpComponentParams): Promise<void> {
        if (params) {
            this.sentInfos = params.sentInfo;
        }

        const uniqueInfo = new Set(this.sentInfos.map(x => `${x.userId}${x.sentDate.getDate()}`));
        this.isSameDateSamePerson = uniqueInfo.size === 1 && this.sentInfos.length === this.params.totalParcelsCount;

        this._helpService.refresh();

    }

    getValue(sentInfo: Core.AppealRecommendationEmailSentInfo, fieldName: string, maxLength: number): string {
        return this._trimValue(sentInfo[fieldName], maxLength);
    }

    getUserName(sentInfo: Core.AppealRecommendationEmailSentInfo, maxLength: number): string {
        return this._trimValue(`${sentInfo.userLastName} ${sentInfo.userFirstName}`, maxLength);
    }

    private _trimValue(value: string, maxLength: number): string {
        let result = value;

        if (result.length >= maxLength) {
            result = result.substring(0, maxLength) + '...';
        }

        return result;
    }
}
