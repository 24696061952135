<ws-modal-wrapper headerLabel="Add Parcel Collector" [disableSave]="serverAction || collectors?.length === 0" acceptLabel="Add" (save)="add()" (cancel)="close()">
    <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
    <div>
        <!-- has company collectors -->
        <div *ngIf="params.hasCompanyCollectors" class="collectors-choice">
            <div class="radio">
                <label>
                    <input type="radio"
                        [value]="true"
                        name="is-state"
                        [(ngModel)]="isStateCollector"
                        (change)="governmentalCollectorsSelected()"> Governmental Collectors
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio"
                        [value]="false"
                        name="is-state"
                        [(ngModel)]="isStateCollector"
                        (change)="companyCollectorsSelected()"> Company Collectors
                </label>
            </div>
        </div>

        <!-- has no collectors -->
        <div *ngIf="collectors?.length === 0">
            <h3 class="text-center">No Collectors found for this parcel.</h3>
        </div>

        <!-- has collectors -->
        <div *ngIf="collectors?.length !== 0">
            <div class="mb-4">
                <label>Collector:</label>
                <select class="form-select form-select-sm" [(ngModel)]="selectedCollector" (change)="collectorChanged()">
                    <option *ngFor="let option of collectors" [ngValue]="option">{{option.title}}</option>
                </select>
            </div>

            <div class="form-inline" *ngIf="selectedCollector" style="padding-bottom: 10px;">
                <label>Payment Options:</label>
                <div *ngFor="let collectorBill of selectedCollector.collectorBillInfo; let i=index;">
                    <span>{{collectorBill.collectorBillName}} </span>Pmt Option:
                    <select class="form-select form-select-sm"
                        *ngIf="collectorBill.displayPaymentOptions.length"
                        style="min-width: 200px; margin-left: 10px; margin-bottom: 4px;"
                        [(ngModel)]="selectedPaymentOptions[i]">
                            <option *ngFor="let option of collectorBill.displayPaymentOptions" [ngValue]="option">
                                {{option.collectorPaymentOptionName}}
                            </option>
                    </select>
                    <span *ngIf="!collectorBill.displayPaymentOptions.length">Only single option available</span>
                </div>
            </div>

            <div class="mb-4">
                <label>Apply to:</label>
                <div class="radio">
                    <label>
                        <input type="radio"
                            [checked]="applyTo === 'all'"
                            (change)="applyToChange($event)"
                            name="apply-to"
                            value="all"> All Tax Years
                    </label>
                </div>
                <div class="radio">
                    <label style="display: flex; gap: 10px; align-items: center;">
                        <input type="radio"
                            [checked]="applyTo === 'year'"
                            (change)="applyToChange($event)"
                            name="apply-to"
                            value="year"> Tax year
                        <select class="form-select form-select-sm"
                            style="width: auto;"
                            [(ngModel)]="taxYear"
                            [disabled]="applyTo !== 'year'">
                                <option *ngFor="let year of taxYears" [ngValue]="year">{{year}}</option>
                        </select>
                        <span>and forward</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
