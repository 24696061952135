import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AssetRepository } from '../../Repositories';
import { AssetSummary } from '../Models/assetSummary';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';
import { AssetLienDate } from '../Asset-Lien-Date/assetLienDate.component';
import { AssetLimitOption } from './Asset-Limit/assetLimit.component';

export interface AssetListDataSourceParams {
    companyId: number;
    lienDate: AssetLienDate;
    importId: number;
    limitOption: AssetLimitOption;
    advancedFilters: Compliance.AdvancedAssetFilters;
    fetchSourceAssets: boolean;
    parcelId?: number;
    siteId?: number;
}

export class AssetListAgGridDataSource extends AgGridDataSourceBase<AssetListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _assetRepository: AssetRepository,
        protected _dataSourceParamsFn: () => AssetListDataSourceParams,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _totalsUpdateCallback: (totals: Compliance.AssetSearchTotalsModel, selectedTotals?: boolean, isLoading?: boolean) => void,
        private readonly _autoUpdateTotalsCallback: () => boolean
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.AssetPropertyEnum>[] = [
        // Originally, these were like this:
        //{ name: `${Compliance.AssetPropertyEnum.ReportingAssetNumber.toString()}`, value: Compliance.AssetPropertyEnum.ReportingAssetNumber },
        // However, TypeScript has had a really annoying bug for years where this sometimes breaks during transpilation.
        // Eventually, we'll upgrade to a version of TypeScript where this bug is fixed (supposedly 3.3+), but until then
        // just use string interpolation. https://github.com/angular/angular-cli/issues/13842
        { name: `${Compliance.AssetPropertyEnum.ReportingAssetNumber}`, value: Compliance.AssetPropertyEnum.ReportingAssetNumber },
        { name: `${Compliance.AssetPropertyEnum.ReportingAcqDate}`, value: Compliance.AssetPropertyEnum.ReportingAcqDate },
        { name: `${Compliance.AssetPropertyEnum.ReportingCost}`, value: Compliance.AssetPropertyEnum.ReportingCost },
        { name: `${Compliance.AssetPropertyEnum.ReportingDescription}`, value: Compliance.AssetPropertyEnum.ReportingDescription },
        { name: `${Compliance.AssetPropertyEnum.ReportingDisposedDate}`, value: Compliance.AssetPropertyEnum.ReportingDisposedDate },
        { name: `${Compliance.AssetPropertyEnum.ReportingGLAccountNameOrNumber}`, value: Compliance.AssetPropertyEnum.ReportingGLAccountNameOrNumber },
        { name: `${Compliance.AssetPropertyEnum.ReportingGLAccountNumber}`, value: Compliance.AssetPropertyEnum.ReportingGLAccountNumber },
        { name: `${Compliance.AssetPropertyEnum.ReportingSiteNameOrNumber}`, value: Compliance.AssetPropertyEnum.ReportingSiteNameOrNumber },
        { name: `${Compliance.AssetPropertyEnum.ReportingSiteName}`, value: Compliance.AssetPropertyEnum.ReportingSiteName },
        { name: `${Compliance.AssetPropertyEnum.ReportingSiteNumber}`, value: Compliance.AssetPropertyEnum.ReportingSiteNumber },
        { name: `${Compliance.AssetPropertyEnum.AssetClassificationName}`, value: Compliance.AssetPropertyEnum.AssetClassificationName },
        { name: `${Compliance.AssetPropertyEnum.SourceAssetNumber}`, value: Compliance.AssetPropertyEnum.SourceAssetNumber },
        { name: `${Compliance.AssetPropertyEnum.SourceAcqDate}`, value: Compliance.AssetPropertyEnum.SourceAcqDate },
        { name: `${Compliance.AssetPropertyEnum.SourceCost}`, value: Compliance.AssetPropertyEnum.SourceCost },
        { name: `${Compliance.AssetPropertyEnum.SourceDescription}`, value: Compliance.AssetPropertyEnum.SourceDescription },
        { name: `${Compliance.AssetPropertyEnum.SourceDisposedDate}`, value: Compliance.AssetPropertyEnum.SourceDisposedDate },
        { name: `${Compliance.AssetPropertyEnum.SourceGLAccountNameOrNumber}`, value: Compliance.AssetPropertyEnum.SourceGLAccountNameOrNumber },
        { name: `${Compliance.AssetPropertyEnum.SourceGLAccountNumber}`, value: Compliance.AssetPropertyEnum.SourceGLAccountNumber },
        { name: `${Compliance.AssetPropertyEnum.SourceSiteNameOrNumber}`, value: Compliance.AssetPropertyEnum.SourceSiteNameOrNumber },
        { name: `${Compliance.AssetPropertyEnum.SourceSiteName}`, value: Compliance.AssetPropertyEnum.SourceSiteName },
        { name: `${Compliance.AssetPropertyEnum.SourceSiteNumber}`, value: Compliance.AssetPropertyEnum.SourceSiteNumber },
        { name: `${Compliance.AssetPropertyEnum.EffectiveDate}`, value: Compliance.AssetPropertyEnum.EffectiveDate },
        { name: `${Compliance.AssetPropertyEnum.ReportingParcel}`, value: Compliance.AssetPropertyEnum.ReportingParcel },
        { name: `${Compliance.AssetPropertyEnum.CompanyCode}`, value: Compliance.AssetPropertyEnum.CompanyCode },
        { name: `${Compliance.AssetPropertyEnum.CompanyName}`, value: Compliance.AssetPropertyEnum.CompanyName },
        { name: `${Compliance.AssetPropertyEnum.AssessorName}`, value: Compliance.AssetPropertyEnum.AssessorName },
        { name: `${Compliance.AssetPropertyEnum.SourceState}`, value: Compliance.AssetPropertyEnum.SourceState},
        { name: `${Compliance.AssetPropertyEnum.State}`, value: Compliance.AssetPropertyEnum.State },
        { name: `${Compliance.AssetPropertyEnum.SourceAdjustedCost}`, value: Compliance.AssetPropertyEnum.SourceAdjustedCost },
        { name: `${Compliance.AssetPropertyEnum.ReportingAreSplitAllocationsValid}`, value: Compliance.AssetPropertyEnum.ReportingAreSplitAllocationsValid },
        { name: 'assetVerificationReason', value: Compliance.AssetPropertyEnum.AssetVerificationReason },
        { name: 'reportingIsPerpetual', value: Compliance.AssetPropertyEnum.ReportingIsPerpetual },
        { name: 'isUserCreated', value: Compliance.AssetPropertyEnum.IsUserCreated },
        { name: 'reportedT1', value: Compliance.AssetPropertyEnum.ReportingT1 },
        { name: 'reportedT2', value: Compliance.AssetPropertyEnum.ReportingT2 },
        { name: 'reportedT3', value: Compliance.AssetPropertyEnum.ReportingT3 },
        { name: 'reportedT4', value: Compliance.AssetPropertyEnum.ReportingT4 },
        { name: 'reportedT5', value: Compliance.AssetPropertyEnum.ReportingT5 },
        { name: 'reportedT6', value: Compliance.AssetPropertyEnum.ReportingT6 },
        { name: 'reportedT7', value: Compliance.AssetPropertyEnum.ReportingT7 },
        { name: 'reportedT8', value: Compliance.AssetPropertyEnum.ReportingT8 },
        { name: 'reportedT9', value: Compliance.AssetPropertyEnum.ReportingT9 },
        { name: 'reportedT10', value: Compliance.AssetPropertyEnum.ReportingT10 },
        { name: 'reportedT11', value: Compliance.AssetPropertyEnum.ReportingT11 },
        { name: 'reportedT12', value: Compliance.AssetPropertyEnum.ReportingT12 },
        { name: 'reportedT13', value: Compliance.AssetPropertyEnum.ReportingT13 },
        { name: 'reportedT14', value: Compliance.AssetPropertyEnum.ReportingT14 },
        { name: 'reportedT15', value: Compliance.AssetPropertyEnum.ReportingT15 },
        { name: 'reportedT16', value: Compliance.AssetPropertyEnum.ReportingT16 },
        { name: 'reportedT17', value: Compliance.AssetPropertyEnum.ReportingT17 },
        { name: 'reportedT18', value: Compliance.AssetPropertyEnum.ReportingT18 },
        { name: 'reportedT19', value: Compliance.AssetPropertyEnum.ReportingT19 },
        { name: 'reportedT20', value: Compliance.AssetPropertyEnum.ReportingT20 },
        { name: 'reportedT20', value: Compliance.AssetPropertyEnum.ReportingT20 },
        { name: 'reportedT21', value: Compliance.AssetPropertyEnum.ReportingT21 },
        { name: 'reportedT22', value: Compliance.AssetPropertyEnum.ReportingT22 },
        { name: 'reportedT23', value: Compliance.AssetPropertyEnum.ReportingT23 },
        { name: 'reportedT24', value: Compliance.AssetPropertyEnum.ReportingT24 },
        { name: 'reportedT25', value: Compliance.AssetPropertyEnum.ReportingT25 },
        { name: 'reportedT26', value: Compliance.AssetPropertyEnum.ReportingT26 },
        { name: 'reportedT27', value: Compliance.AssetPropertyEnum.ReportingT27 },
        { name: 'reportedT28', value: Compliance.AssetPropertyEnum.ReportingT28 },
        { name: 'reportedT29', value: Compliance.AssetPropertyEnum.ReportingT29 },
        { name: 'reportedT30', value: Compliance.AssetPropertyEnum.ReportingT30 },
        { name: 'reportedN1', value: Compliance.AssetPropertyEnum.ReportingN1 },
        { name: 'reportedN2', value: Compliance.AssetPropertyEnum.ReportingN2 },
        { name: 'reportedN3', value: Compliance.AssetPropertyEnum.ReportingN3 },
        { name: 'reportedN4', value: Compliance.AssetPropertyEnum.ReportingN4 },
        { name: 'reportedN5', value: Compliance.AssetPropertyEnum.ReportingN5 },
        { name: 'reportedN6', value: Compliance.AssetPropertyEnum.ReportingN6 },
        { name: 'reportedN7', value: Compliance.AssetPropertyEnum.ReportingN7 },
        { name: 'reportedN8', value: Compliance.AssetPropertyEnum.ReportingN8 },
        { name: 'reportedN9', value: Compliance.AssetPropertyEnum.ReportingN9 },
        { name: 'reportedN10', value: Compliance.AssetPropertyEnum.ReportingN10 },
        { name: 'reportedD1', value: Compliance.AssetPropertyEnum.ReportingD1 },
        { name: 'reportedD2', value: Compliance.AssetPropertyEnum.ReportingD2 },
        { name: 'reportedD3', value: Compliance.AssetPropertyEnum.ReportingD3 },
        { name: 'reportedD4', value: Compliance.AssetPropertyEnum.ReportingD4 },
        { name: 'reportedD5', value: Compliance.AssetPropertyEnum.ReportingD5 },
        { name: 'reportedD6', value: Compliance.AssetPropertyEnum.ReportingD6 },
        { name: 'reportedD7', value: Compliance.AssetPropertyEnum.ReportingD7 },
        { name: 'reportedD8', value: Compliance.AssetPropertyEnum.ReportingD8 },
        { name: 'reportedD9', value: Compliance.AssetPropertyEnum.ReportingD9 },
        { name: 'reportedD10', value: Compliance.AssetPropertyEnum.ReportingD10 },
        { name: 'sourceT1', value: Compliance.AssetPropertyEnum.SourceT1 },
        { name: 'sourceT2', value: Compliance.AssetPropertyEnum.SourceT2 },
        { name: 'sourceT3', value: Compliance.AssetPropertyEnum.SourceT3 },
        { name: 'sourceT4', value: Compliance.AssetPropertyEnum.SourceT4 },
        { name: 'sourceT5', value: Compliance.AssetPropertyEnum.SourceT5 },
        { name: 'sourceT6', value: Compliance.AssetPropertyEnum.SourceT6 },
        { name: 'sourceT7', value: Compliance.AssetPropertyEnum.SourceT7 },
        { name: 'sourceT8', value: Compliance.AssetPropertyEnum.SourceT8 },
        { name: 'sourceT9', value: Compliance.AssetPropertyEnum.SourceT9 },
        { name: 'sourceT10', value: Compliance.AssetPropertyEnum.SourceT10 },
        { name: 'sourceT11', value: Compliance.AssetPropertyEnum.SourceT11 },
        { name: 'sourceT12', value: Compliance.AssetPropertyEnum.SourceT12 },
        { name: 'sourceT13', value: Compliance.AssetPropertyEnum.SourceT13 },
        { name: 'sourceT14', value: Compliance.AssetPropertyEnum.SourceT14 },
        { name: 'sourceT15', value: Compliance.AssetPropertyEnum.SourceT15 },
        { name: 'sourceT16', value: Compliance.AssetPropertyEnum.SourceT16 },
        { name: 'sourceT17', value: Compliance.AssetPropertyEnum.SourceT17 },
        { name: 'sourceT18', value: Compliance.AssetPropertyEnum.SourceT18 },
        { name: 'sourceT19', value: Compliance.AssetPropertyEnum.SourceT19 },
        { name: 'sourceT20', value: Compliance.AssetPropertyEnum.SourceT20 },
        { name: 'sourceT20', value: Compliance.AssetPropertyEnum.SourceT20 },
        { name: 'sourceT21', value: Compliance.AssetPropertyEnum.SourceT21 },
        { name: 'sourceT22', value: Compliance.AssetPropertyEnum.SourceT22 },
        { name: 'sourceT23', value: Compliance.AssetPropertyEnum.SourceT23 },
        { name: 'sourceT24', value: Compliance.AssetPropertyEnum.SourceT24 },
        { name: 'sourceT25', value: Compliance.AssetPropertyEnum.SourceT25 },
        { name: 'sourceT26', value: Compliance.AssetPropertyEnum.SourceT26 },
        { name: 'sourceT27', value: Compliance.AssetPropertyEnum.SourceT27 },
        { name: 'sourceT28', value: Compliance.AssetPropertyEnum.SourceT28 },
        { name: 'sourceT29', value: Compliance.AssetPropertyEnum.SourceT29 },
        { name: 'sourceT30', value: Compliance.AssetPropertyEnum.SourceT30 },
        { name: 'sourceN1', value: Compliance.AssetPropertyEnum.SourceN1 },
        { name: 'sourceN2', value: Compliance.AssetPropertyEnum.SourceN2 },
        { name: 'sourceN3', value: Compliance.AssetPropertyEnum.SourceN3 },
        { name: 'sourceN4', value: Compliance.AssetPropertyEnum.SourceN4 },
        { name: 'sourceN5', value: Compliance.AssetPropertyEnum.SourceN5 },
        { name: 'sourceN6', value: Compliance.AssetPropertyEnum.SourceN6 },
        { name: 'sourceN7', value: Compliance.AssetPropertyEnum.SourceN7 },
        { name: 'sourceN8', value: Compliance.AssetPropertyEnum.SourceN8 },
        { name: 'sourceN9', value: Compliance.AssetPropertyEnum.SourceN9 },
        { name: 'sourceN10', value: Compliance.AssetPropertyEnum.SourceN10 },
        { name: 'sourceD1', value: Compliance.AssetPropertyEnum.SourceD1 },
        { name: 'sourceD2', value: Compliance.AssetPropertyEnum.SourceD2 },
        { name: 'sourceD3', value: Compliance.AssetPropertyEnum.SourceD3 },
        { name: 'sourceD4', value: Compliance.AssetPropertyEnum.SourceD4 },
        { name: 'sourceD5', value: Compliance.AssetPropertyEnum.SourceD5 },
        { name: 'sourceD5', value: Compliance.AssetPropertyEnum.SourceD5 },
        { name: 'sourceD6', value: Compliance.AssetPropertyEnum.SourceD6 },
        { name: 'sourceD7', value: Compliance.AssetPropertyEnum.SourceD7 },
        { name: 'sourceD8', value: Compliance.AssetPropertyEnum.SourceD8 },
        { name: 'sourceD9', value: Compliance.AssetPropertyEnum.SourceD9 },
        { name: 'sourceD10', value: Compliance.AssetPropertyEnum.SourceD10 },
        { name: 'reportedCostAdjustmentAmount1', value: Compliance.AssetPropertyEnum.ReportingCostAdjustmentAmount1 },
        { name: 'reportedCostAdjustmentAmount2', value: Compliance.AssetPropertyEnum.ReportingCostAdjustmentAmount2 },
        { name: 'reportedCostAdjustmentAmount3', value: Compliance.AssetPropertyEnum.ReportingCostAdjustmentAmount3 },
        { name: 'reportedCostAdjustmentAmount4', value: Compliance.AssetPropertyEnum.ReportingCostAdjustmentAmount4 },
        { name: 'sourceLeaseLocationName', value: Compliance.AssetPropertyEnum.SourceLeaseLocationName },
        { name: 'sourceLeaseAddress', value: Compliance.AssetPropertyEnum.SourceLeaseAddress },
        { name: 'sourceLeaseCity', value: Compliance.AssetPropertyEnum.SourceLeaseCity },
        { name: 'sourceLeaseState', value: Compliance.AssetPropertyEnum.SourceLeaseState },
        { name: 'sourceLeaseZip', value: Compliance.AssetPropertyEnum.SourceLeaseZip },
        { name: 'sourceLeaseTermInMonths', value: Compliance.AssetPropertyEnum.SourceLeaseTermInMonths },
        { name: 'sourceLeaseMonthlyPayment', value: Compliance.AssetPropertyEnum.SourceLeaseMonthlyPayment },
        { name: 'sourceLeaseTypeId', value: Compliance.AssetPropertyEnum.SourceLeaseTypeId },
        { name: 'sourceLeaseAssetId', value: Compliance.AssetPropertyEnum.SourceLeaseAssetId },
        { name: 'sourceLeaseNumber', value: Compliance.AssetPropertyEnum.SourceLeaseNumber },
        { name: 'sourceLeaseBillingName', value: Compliance.AssetPropertyEnum.SourceLeaseBillingName },
        { name: 'sourceLeaseBillingAddress', value: Compliance.AssetPropertyEnum.SourceLeaseBillingAddress },
        { name: 'sourceLeaseBillingCity', value: Compliance.AssetPropertyEnum.SourceLeaseBillingCity },
        { name: 'sourceLeaseBillingState', value: Compliance.AssetPropertyEnum.SourceLeaseBillingState },
        { name: 'sourceLeaseBillingZip', value: Compliance.AssetPropertyEnum.SourceLeaseBillingZip },
        { name: 'sourceLeaseStartDate', value: Compliance.AssetPropertyEnum.SourceLeaseStartDate },
        { name: 'sourceLeaseEndDate', value: Compliance.AssetPropertyEnum.SourceLeaseEndDate },
        { name: 'sourceLeaseClientId', value: Compliance.AssetPropertyEnum.SourceLeaseClientId },
        { name: 'reportedLeaseLocationName', value: Compliance.AssetPropertyEnum.ReportingLeaseLocationName },
        { name: 'reportedLeaseAddress', value: Compliance.AssetPropertyEnum.ReportingLeaseAddress },
        { name: 'reportedLeaseCity', value: Compliance.AssetPropertyEnum.ReportingLeaseCity },
        { name: 'reportedLeaseState', value: Compliance.AssetPropertyEnum.ReportingLeaseState },
        { name: 'reportedLeaseZip', value: Compliance.AssetPropertyEnum.ReportingLeaseZip },
        { name: 'reportedLeaseTermInMonths', value: Compliance.AssetPropertyEnum.ReportingLeaseTermInMonths },
        { name: 'reportedLeaseMonthlyPayment', value: Compliance.AssetPropertyEnum.ReportingLeaseMonthlyPayment },
        { name: 'reportedLeaseTypeId', value: Compliance.AssetPropertyEnum.ReportingLeaseTypeId },
        { name: 'reportedLeaseAssetId', value: Compliance.AssetPropertyEnum.ReportingLeaseAssetId },
        { name: 'reportedLeaseNumber', value: Compliance.AssetPropertyEnum.ReportingLeaseNumber },
        { name: 'reportedLeaseBillingName', value: Compliance.AssetPropertyEnum.ReportingLeaseBillingName },
        { name: 'reportedLeaseBillingAddress', value: Compliance.AssetPropertyEnum.ReportingLeaseBillingAddress },
        { name: 'reportedLeaseBillingCity', value: Compliance.AssetPropertyEnum.ReportingLeaseBillingCity },
        { name: 'reportedLeaseBillingState', value: Compliance.AssetPropertyEnum.ReportingLeaseBillingState },
        { name: 'reportedLeaseBillingZip', value: Compliance.AssetPropertyEnum.ReportingLeaseBillingZip },
        { name: 'reportedLeaseStartDate', value: Compliance.AssetPropertyEnum.ReportingLeaseStartDate },
        { name: 'reportedLeaseEndDate', value: Compliance.AssetPropertyEnum.ReportingLeaseEndDate },
        { name: 'reportedLeaseClientId', value: Compliance.AssetPropertyEnum.ReportingLeaseClientId },
        { name: 'assetOwnershipType', value: Compliance.AssetPropertyEnum.AssetOwnershipType },
        { name: `${Compliance.AssetPropertyEnum.ReportingSplitAllocationPercentage}`, value: Compliance.AssetPropertyEnum.ReportingSplitAllocationPercentage },
        { name: `${Compliance.AssetPropertyEnum.ReportingOldAssetNumber}`, value: Compliance.AssetPropertyEnum.ReportingOldAssetNumber },
        { name: `${Compliance.AssetPropertyEnum.SourceOldAssetNumber}`, value: Compliance.AssetPropertyEnum.SourceOldAssetNumber },
        { name: 'sourceInventoryJan', value: Compliance.AssetPropertyEnum.SourceInventoryJan },
        { name: 'sourceInventoryFeb', value: Compliance.AssetPropertyEnum.SourceInventoryFeb },
        { name: 'sourceInventoryMar', value: Compliance.AssetPropertyEnum.SourceInventoryMar },
        { name: 'sourceInventoryApr', value: Compliance.AssetPropertyEnum.SourceInventoryApr },
        { name: 'sourceInventoryMay', value: Compliance.AssetPropertyEnum.SourceInventoryMay },
        { name: 'sourceInventoryJun', value: Compliance.AssetPropertyEnum.SourceInventoryJun },
        { name: 'sourceInventoryJul', value: Compliance.AssetPropertyEnum.SourceInventoryJul },
        { name: 'sourceInventoryAug', value: Compliance.AssetPropertyEnum.SourceInventoryAug },
        { name: 'sourceInventorySep', value: Compliance.AssetPropertyEnum.SourceInventorySep },
        { name: 'sourceInventoryOct', value: Compliance.AssetPropertyEnum.SourceInventoryOct },
        { name: 'sourceInventoryNov', value: Compliance.AssetPropertyEnum.SourceInventoryNov },
        { name: 'sourceInventoryDec', value: Compliance.AssetPropertyEnum.SourceInventoryDec },
        { name: 'sourceInventoryAvr', value: Compliance.AssetPropertyEnum.SourceInventoryAvr },
        { name: 'reportedInventoryJan', value: Compliance.AssetPropertyEnum.ReportingInventoryJan },
        { name: 'reportedInventoryFeb', value: Compliance.AssetPropertyEnum.ReportingInventoryFeb },
        { name: 'reportedInventoryMar', value: Compliance.AssetPropertyEnum.ReportingInventoryMar },
        { name: 'reportedInventoryApr', value: Compliance.AssetPropertyEnum.ReportingInventoryApr },
        { name: 'reportedInventoryMay', value: Compliance.AssetPropertyEnum.ReportingInventoryMay },
        { name: 'reportedInventoryJun', value: Compliance.AssetPropertyEnum.ReportingInventoryJun },
        { name: 'reportedInventoryJul', value: Compliance.AssetPropertyEnum.ReportingInventoryJul },
        { name: 'reportedInventoryAug', value: Compliance.AssetPropertyEnum.ReportingInventoryAug },
        { name: 'reportedInventorySep', value: Compliance.AssetPropertyEnum.ReportingInventorySep },
        { name: 'reportedInventoryOct', value: Compliance.AssetPropertyEnum.ReportingInventoryOct },
        { name: 'reportedInventoryNov', value: Compliance.AssetPropertyEnum.ReportingInventoryNov },
        { name: 'reportedInventoryDec', value: Compliance.AssetPropertyEnum.ReportingInventoryDec },
        { name: 'reportedInventoryAvr', value: Compliance.AssetPropertyEnum.ReportingInventoryAvr },
        { name: 'sourceNetBookValue', value: Compliance.AssetPropertyEnum.SourceNetBookValue },
        { name: 'reportedNetBookValue', value: Compliance.AssetPropertyEnum.ReportingNetBookValue },
        { name: `${Compliance.AssetPropertyEnum.AlternativeAcqDateDescriptorName}`, value: Compliance.AssetPropertyEnum.AlternativeAcqDateDescriptorName },
        { name: `${Compliance.AssetPropertyEnum.AlternativeCostDescriptorName}`, value: Compliance.AssetPropertyEnum.AlternativeCostDescriptorName },
        { name: `${Compliance.AssetPropertyEnum.ReportingIsAnchored}`, value: Compliance.AssetPropertyEnum.ReportingIsAnchored },
        { name: `${Compliance.AssetPropertyEnum.ReportingDoNotPotentiallyDispose}`, value: Compliance.AssetPropertyEnum.ReportingDoNotPotentiallyDispose },
        { name: `${Compliance.AssetPropertyEnum.ReportingIsLocked}`, value: Compliance.AssetPropertyEnum.ReportingIsLocked },
        { name: `${Compliance.AssetPropertyEnum.LastReturnDescription}`, value: Compliance.AssetPropertyEnum.LastReturnDescription },
        { name: `${Compliance.AssetPropertyEnum.LastReturnId}`, value: Compliance.AssetPropertyEnum.LastReturnId },
        { name: `${Compliance.AssetPropertyEnum.SourceSiteAddress}`, value: Compliance.AssetPropertyEnum.SourceSiteAddress },
        { name: `${Compliance.AssetPropertyEnum.SiteAddress}`, value: Compliance.AssetPropertyEnum.SiteAddress },
        { name: `${Compliance.AssetPropertyEnum.SourcePriorCost}`, value: Compliance.AssetPropertyEnum.SourcePriorCost },
        { name: `${Compliance.AssetPropertyEnum.PriorCost}`, value: Compliance.AssetPropertyEnum.PriorCost },
        { name: `${Compliance.AssetPropertyEnum.SourceCostChange}`, value: Compliance.AssetPropertyEnum.SourceCostChange },
        { name: `${Compliance.AssetPropertyEnum.CostChange}`, value: Compliance.AssetPropertyEnum.CostChange },
        { name: `${Compliance.AssetPropertyEnum.ParcelSystemId}`, value: Compliance.AssetPropertyEnum.ParcelSystemId },
        { name: `${Compliance.AssetPropertyEnum.AssessorId}`, value: Compliance.AssetPropertyEnum.AssessorId }
    ];

    private _lastFilterUsedInTotals: string;

    hasLatestTotals: boolean;
    lastFetchedTotals: Compliance.AssetSearchTotalsModel;

    protected canGetRows(): boolean {
        const canGetRows = !!((((this._dataSourceParams.lienDate && this._dataSourceParams.lienDate.date) && this._dataSourceParams.limitOption) || this._dataSourceParams.importId) && this._dataSourceParams.companyId);
        if (!canGetRows) {
            this.clearTotals();
            this._totalsUpdateCallback(null);
        }
        return canGetRows;
    }

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    clearFilters(): void {
        this.gridApi.appliedFilter = null;
        this.gridApi.setFilterModel(null);
    }

    clearSort(): void {
        this.gridApi.setSortModel(null);
    }

    getSearchParamsWithoutPagination() : Compliance.AssetSearchModel{
        this.refreshDataSourceParams();
        const lienDate = this._dataSourceParams.lienDate;
        const limitOption = this._dataSourceParams.limitOption;
        return {
            advancedAssetFilters: this._dataSourceParams.advancedFilters,
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeTotals: false,
            includeOnlyTotals: false,
            selectedRows: null,
            importId: this._dataSourceParams.importId,
            lienDate: lienDate && lienDate.date,
            useStateLienDate: lienDate && lienDate.useStateLienDate,
            lienDateType: lienDate && lienDate.lienDateType,
            overrideFilterColumns: limitOption && limitOption.overrideFilterColumns,
            noReportingParcel: limitOption && limitOption.noReportingParcel,
            invalidCostAllocations: limitOption && limitOption.invalidCostAllocations,
            parcelId: this._dataSourceParams.parcelId,
            siteId: this._dataSourceParams.siteId
        };
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this._gridApi.setPinnedBottomRowData([]);
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals !== searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = this._dataSourceParams.fetchSourceAssets ?
            await lastValueFrom(this._assetRepository.getSourceAssetList(this._dataSourceParams.companyId, searchParams)) :
            await lastValueFrom(this._assetRepository.getReportingAssetList(this._dataSourceParams.companyId, searchParams));


        this.hasLatestTotals = false;
        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(null);

        const dataSourceResult: AgGridDataSourceResult = {
            rows: result.data.map((x, idx) => new AssetSummary(x, this._keyValueDisplayPipe, idx)),
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };

        if (fetchTotals && this._autoUpdateTotalsCallback()) {
            this.getAllRowTotals(params);
        }

        return dataSourceResult;
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.AssetSearchTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selectedRowProperty = this._dataSourceParams.fetchSourceAssets ? Compliance.AssetPropertyEnum.SourceAssetNumber : Compliance.AssetPropertyEnum.ReportingAssetNumber;
        searchParams.selectedRows = selectedRowsModel;
        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const result = this._dataSourceParams.fetchSourceAssets ?
            await lastValueFrom(this._assetRepository.getSourceAssetList(this._dataSourceParams.companyId, searchParams)) :
            await lastValueFrom(this._assetRepository.getReportingAssetList(this._dataSourceParams.companyId, searchParams));

        return result.totals;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const lienDate = this._dataSourceParams.lienDate;
        const limitOption = this._dataSourceParams.limitOption;
        const searchParams: Compliance.AssetSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            advancedAssetFilters: this._dataSourceParams.advancedFilters,
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeTotals: false,
            includeOnlyTotals: false,
            selectedRows: null,
            importId: this._dataSourceParams.importId,
            lienDate: lienDate && lienDate.date,
            useStateLienDate: lienDate && lienDate.useStateLienDate,
            lienDateType: lienDate && lienDate.lienDateType,
            overrideFilterColumns: limitOption && limitOption.overrideFilterColumns,
            noReportingParcel: limitOption && limitOption.noReportingParcel,
            invalidCostAllocations: limitOption && limitOption.invalidCostAllocations,
            parcelId: this._dataSourceParams.parcelId,
            siteId: this._dataSourceParams.siteId
        };

        return await lastValueFrom(this._assetRepository.getReportingAssetRowIds(this._dataSourceParams.companyId, searchParams));
    }

    async getAllRowTotals(params: IGetRowsParams, force?: boolean): Promise<Compliance.AssetSearchTotalsModel> {
        if (!force && !this._autoUpdateTotalsCallback()) {return;}

        this._totalsUpdateCallback(this.lastFetchedTotals, false, true);
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = this._dataSourceParams.fetchSourceAssets ?
            await lastValueFrom(this._assetRepository.getSourceAssetList(this._dataSourceParams.companyId, searchParams)) :
            await lastValueFrom(this._assetRepository.getReportingAssetList(this._dataSourceParams.companyId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);
        this.hasLatestTotals = true;

        return result.totals;
    }
}
