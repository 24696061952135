<div>
    <table class="forecast-create-missing-table">
        <tr>
            <td>
                <label style="margin-bottom: 0;">Missing Parcel {{taxYear}} Tax Years:</label>
            </td>
            <td></td>
            <td>
                {{numMissingTaxYears}}
            </td>
            <td></td>
            <td>
                <button *ngIf="editMode" [disabled]="!isMissingCreateAllowed()" class="flat-button small-button primary-button" (click)="createMissing()">Create</button>
            </td>
        </tr>
    </table>
</div>
