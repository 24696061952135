<div class="input-group" aria-label="Select Taxing District">
    <input id="selectTaxingDistrict"
           type="text"
           placeholder="Filter Taxing Districts..."
           class="form-control exclude-modal-styling"
           [ngClass]="{ 'overridden': isOverridden }"
           autocomplete="off"
           (blur)="onBlur()"
           [(ngModel)]="filter"
           container="import-bulk-update"
           [typeahead]="typeaheadValues"
           typeaheadOptionField="displayName"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)"
           [typeaheadOptionsLimit]="100"
           [disabled]="isDisabled" />
</div>
