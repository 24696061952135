import { efAction } from '../../../../constants.new';

export class CompanyAccounting {
    companyId: number;
    defaultFiscalYearStart: string;
    defaultAccountingDurationTypeId: number;
    companyRowVersion: string;
    // companyFiscalYears can be empty (UI must account for that)
    companyFiscalYears: CompanyFiscalYear[];
}

export class CompanyFiscalYear {
    companyFiscalYearId: number;
    companyId: number;
    name: string;
    startDate: Date;
    endDate: Date;
    accountingDurationTypeId: number;
    accountingPeriods: AccountingPeriod[];
}

export class AccountingPeriod {
    accountingPeriodId: number;
    companyFiscalYearId: number;
    name: string;
    startDate: Date;
    endDate: Date;
    isFrozen: boolean;
    efAction: efAction;
    durationText: string;
}

export class AccountingPeriodsRequest {
    companyRowVersion: string;
    companyFiscalYearId: number;
    fiscalYearName: string;
    efAction: efAction;
    accountingPeriods: AccountingPeriod[];
}

export class NewFiscalYearRequest {
    companyRowVersion: string;
    name: string;
    startDate: Date;
    endDate: Date;
    accountingDurationTypeId: number;

}
