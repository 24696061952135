<span [popover]="invoiceRecipientDetails" triggers="mouseenter:mouseleave" placement="right">
    <select [(ngModel)]="selectedRecipient" (change)="recipientChanged()" class="form-select form-select-sm" [disabled]="!recipients.length || !editMode">
        <option [ngValue]="undefined"></option>
        <option *ngFor="let recipient of recipients" [ngValue]="recipient">{{recipient.name}}</option>
    </select>
</span>

<ng-template #invoiceRecipientDetails>
    <em *ngIf="!selectedRecipient">No recipient selected</em>
    <address *ngIf="selectedRecipient">
        {{selectedRecipient.clientName}}
        <br> {{selectedRecipient.name}}
        <br> {{selectedRecipient.title}}
        <br> {{selectedRecipient.address1}}
        <br> {{selectedRecipient.address2}}
        <br *ngIf="selectedRecipient.address2"> {{selectedRecipient.city}}
        <span *ngIf="selectedRecipient.city">,</span> {{selectedRecipient.state}} {{selectedRecipient.postalCode}}
    </address>
</ng-template>
