import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimerService } from '../../../../UI-Lib/Utilities';

@Component({
    selector: 'auto-populate-input',
    templateUrl: './autoPopulateInput.component.html',
    styleUrls: ['./autoPopulateInput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutoPopulateInputComponent),
            multi: true
        }
    ]
})
export class AutoPopulateInputComponent implements ControlValueAccessor {
    constructor(private readonly _timerService: TimerService) {
    }

    @Input() inputClass: string;
    @Input() isDateInput: boolean;
    @Input() idpResults: string[] = [];
    @Input() currentDocument: Core.IntakeItemInfo;

    @ViewChild('testEl') testEl: ElementRef;

    value: string;
    disabled: boolean;

    showIdpMenu: boolean;
    idpButtonHovered: boolean;
    idpMenuHovered: boolean;

    inputMask = (value: string): string => this.isDateInput ? '0000' : '';

    // ControlValueAccessor methods
    onChange: (val: string) => void;
    onTouched: () => void;

    writeValue(value: string): void {
        this.value = value;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    next(): void {
        this.onChange(this.value);
        this.onTouched();
    }

    registerOnChange(fn: (val: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setSingleIdpValue(): void {
        if (this.idpResults.length === 1) {
            this.writeValue(this.idpResults[0]);
            this.next();
        }
    }

    inputValueChanged(value: string): void {
        this.writeValue(value);
        this.next();
    }

    buttonHoverOut(): void {
        this.idpButtonHovered = false;
        this._closeMenu();
    }

    menuHoverOut(): void {
        this.idpMenuHovered = false;
        this._closeMenu();
    }

    menuFocusIn(): void {
        this.showIdpMenu = true;
    }

    menuFocusOut(): void {
        this.showIdpMenu = false;
        this.idpButtonHovered = false;
        this.idpMenuHovered = false;
    }

    keyedSelection(event: KeyboardEvent): void {
        event.stopPropagation();
        switch (event.key) {
            case 'ArrowDown':
                const nextIndex = this.idpResults.findIndex(x => x === this.value) + 1;
                if (nextIndex < this.idpResults.length) {
                    this.writeValue(this.idpResults[nextIndex]);
                }
                break;
            case 'ArrowUp':
                const previousIndex = this.idpResults.findIndex(x => x === this.value) - 1;
                if (previousIndex >= 0) {
                    this.writeValue(this.idpResults[previousIndex]);
                    this.testEl.nativeElement.querySelector(`#idpValue-${previousIndex}`).focus();
                }
                break;
            case 'Enter':
                event.preventDefault();
                this.menuFocusOut();
                this.writeValue(this.value);
                this.next();
                break;
            case 'Escape':
                this.menuFocusOut();
                break;
        }
    }

    private _closeMenu(): void {
        this._timerService.setTimeout(() => {
            if (!this.idpButtonHovered && !this.idpMenuHovered) {
                this.showIdpMenu = false;
                this.idpButtonHovered = false;
                this.idpMenuHovered = false;
            }
        });
    }
}
