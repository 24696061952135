import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { BusyIndicatorRef, BusyIndicatorService, IBusyIndicatorConfig, SnackBarService } from '../../../Busy-Indicator';
import { LongRunningProcessRepository } from '../../Repositories';
import { takeUntil } from 'rxjs/operators';
import {
    AgGridExportOptions,
    AgGridLayoutSpecificationModel,
    AgGridToolPanelAction,
    AgGridToolPanelButton
} from './models';
import { AG_GRID_TOOL_PANEL_HELP } from './agGridToolPanel.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import ExportFileFormatEnum = Compliance.ExportFileFormatEnum;

export class BulkEditButtonOptions {
    constructor(
        public show: boolean,
        public showLoading: boolean,
        public disable: boolean,
        public toolTipId: string = 'ag-grid-tool-panel.bulk-delete') {
    }

    static Create( o: Partial<BulkEditButtonOptions>): BulkEditButtonOptions
    {
        if (!o){
            return new BulkEditButtonOptions(false, false, false);
        }
        return new BulkEditButtonOptions(
            o.show || false,
            o.showLoading || false,
            o.disable || false,
            o.toolTipId || 'ag-grid-tool-panel.bulk-delete'
        );
    }
}

@Component({
    selector: 'ag-grid-tool-panel',
    templateUrl: './agGridToolPanel.component.html',
    styleUrls: ['./agGridToolPanel.component.scss']
})
export class AgGridToolPanelComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _snackBarService: SnackBarService,
        private readonly _helpService: HelpService) { }

    @Input() companyId: number;
    @Input() gridId: string | System.Guid;
    @Input() gridOptions: GridOptions & { api?: {appliedFilter?: any}};
    @Input() isBulkUpdateVisible$: BehaviorSubject<boolean | BulkEditButtonOptions>;
    @Input() isBulkDeleteVisible$: BehaviorSubject<boolean>;
    @Input() isCustomButtonVisible$: BehaviorSubject<boolean>;
    @Input() exportOptions: AgGridExportOptions;
    @Input() showConfigureLayout: boolean = true;
    @Input() showResizeColumns: boolean = true;
    @Input() defaultSizeToFit: boolean = true;
    @Input() defaultSizeToContent: boolean = false;
    @Input() defaultColorizeHeaders: boolean = false;
    @Input() gridReady: boolean = false;
    @Input() exportIcon: string = 'fa-file-excel-o';
    @Input() exportHelpContentId: string = 'ag-grid-tool-panel.export';
    @Input() additionalActions: AgGridToolPanelAction[];
    @Input() additionalButtons: AgGridToolPanelButton[];

    @Output() bulkUpdate = new EventEmitter<void>();
    @Output() bulkDelete = new EventEmitter<void>();
    @Output() customButtonClick = new EventEmitter<void>();
    @Output() toolPanelReady = new EventEmitter<void>();
    @Output() filtersEnabled = new EventEmitter<boolean>();
    @Output() sizeColumnsToFit = new EventEmitter<void>();
    @Output() sizeColumnsToContent = new EventEmitter<void>();
    @Output() layoutSpecificationChanged = new EventEmitter<AgGridLayoutSpecificationModel>();

    showExport: boolean = false;
    showBulkUpdate: boolean = false;
    showBulkUpdateLoading: boolean = false;
    disableBulkUpdate: boolean = false;
    bulkUpdateButtonHelpId: string;

    showBulkDelete: boolean = false;
    showFileFormatSelection: boolean = false;
    showAdditionalActions: boolean = false;
    showCustom: boolean = false;
    isExpanded: boolean = false;
    isInitialized: boolean = false;

    fileFormats = {
        excel: Compliance.ExportFileFormatEnum.Excel,
        tabDelimited: Compliance.ExportFileFormatEnum.TabDelimited,
        pdf: Compliance.ExportFileFormatEnum.PDF
    };
    displayedColumnsChanged$: Subject<void> = new Subject();
    specification: AgGridLayoutSpecificationModel;

    private _busyRef: BusyIndicatorRef;
    private _busyRefId = this._busyIndicatorService.generateUniqueMessageIdentifier();
    private _longRunningProcessId: number;
    private _destroy$: Subject<void> = new Subject<void>();
    private _processDestroy$: Subject<void> = new Subject<void>();

    private readonly defaultBulkUpdateButtonHelpId = 'ag-grid-tool-panel.bulk-update';

    get configureLayoutHelpContentId(): string {
        return this.isExpanded ?
            'ag-grid-tool-panel.hide-configure' :
            'ag-grid-tool-panel.show-configure';
    }

    get filtersHelpContentId(): string {
        return this.isFilterVisible ?
            'ag-grid-tool-panel.hide-filters' :
            'ag-grid-tool-panel.show-filters';
    }

    get isFilterVisible(): boolean {
        return this.gridOptions && this.gridOptions.floatingFilter;
    }

    get isFiltered(): boolean {
        return this.gridOptions && this.gridOptions.api && this.gridOptions.api.isAnyFilterPresent();
    }

    get disableExport(): boolean {
        let disabled = this.exportOptions?.disabled;
        if (this.exportOptions?.getDisabled) {
            disabled = this.exportOptions.getDisabled();
        }
        return disabled;
    }

    get additionalActionsVisible(): boolean {
        return this.additionalActions.some(x => !x.disabled());
    }

    ngOnInit(): void {
        this._helpService.setContent(AG_GRID_TOOL_PANEL_HELP);
        this.bulkUpdateButtonHelpId = this.defaultBulkUpdateButtonHelpId;

        this.gridOptions.onGridReady = () => this.isInitialized = true;

        this.gridOptions.onFirstDataRendered = () => {
            if (this.defaultSizeToFit) {
                this.sizeToFit();
            }

            if (this.defaultSizeToContent) {
                this.sizeToContent();
            }
        };

        this.gridOptions.onDisplayedColumnsChanged = () => {
            this.displayedColumnsChanged$.next();
        };

        this.showExport = !!this.exportOptions;

        if (this.isBulkUpdateVisible$) {
            this.isBulkUpdateVisible$.pipe(takeUntil(this._destroy$)).subscribe(x => {
                if (x instanceof(BulkEditButtonOptions))
                {
                    this.showBulkUpdateLoading = x.showLoading;
                    this.showBulkUpdate = x.show || x.showLoading;
                    this.disableBulkUpdate = x.disable;
                    this.bulkUpdateButtonHelpId = x.toolTipId;
                }
                else {
                    this.showBulkUpdate = this.bulkUpdate.observers.length > 0 && x;
                    this.showBulkUpdateLoading = false;
                    this.disableBulkUpdate = false;
                    this.bulkUpdateButtonHelpId = this.defaultBulkUpdateButtonHelpId;
                }
            });
        }

        if (this.isBulkDeleteVisible$) {
            this.isBulkDeleteVisible$.pipe(takeUntil(this._destroy$)).subscribe(x => {
                this.showBulkDelete = this.bulkDelete.observers.length > 0 && x;
            });
        }

        if (this.isCustomButtonVisible$) {
            this.isCustomButtonVisible$.pipe(takeUntil(this._destroy$)).subscribe(x => {
                this.showCustom = this.customButtonClick.observers.length > 0 && x;
            });
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._processDestroy$.next();
        this._processDestroy$.complete();
    }

    onExpandClick(): void {
        this.isExpanded = !this.isExpanded;
    }

    onFilterClick(): void {
        if (!(this.gridOptions && this.gridOptions.api)) {
            return;
        }

        this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
        this.gridOptions.api.refreshHeader();

        // clear filter when hiding
        if (!this.gridOptions.floatingFilter && this.isFiltered) {
            this.gridOptions.api.setFilterModel(null);
            this.gridOptions.api.appliedFilter = null;
            this.gridOptions.api.onFilterChanged();
        }

        this.filtersEnabled.emit(this.gridOptions.floatingFilter);
    }

    onClearFiltersClick(): void {
        if (!(this.gridOptions && this.gridOptions.api)) {
            return;
        }

        this.gridOptions.api.setFilterModel(null);
        this.gridOptions.api.appliedFilter = null;
        this.gridOptions.api.setSortModel(null);
        this.gridOptions.api.onFilterChanged();
    }

    sizeToFit(): void {
        if (!(this.gridOptions && this.gridOptions.api)) {
            return;
        }

        this.gridOptions.api.sizeColumnsToFit();
        this.sizeColumnsToFit.emit();
    }

    sizeToContent(): void {
        if (!(this.gridOptions && this.gridOptions.api && this.gridOptions.columnApi)) {
            return;
        }

        this.gridOptions.columnApi.autoSizeAllColumns();
        this.sizeColumnsToContent.emit();
    }

    onExportClick(): void {
        if (this.disableExport || !(this.gridOptions && this.gridOptions.columnApi)) {
            return;
        }

        if (this.exportOptions.showFileFormatSelection) {
            this.showFileFormatSelection = !this.showFileFormatSelection;
        } else {
            this._exportFile(Compliance.ExportFileFormatEnum.Excel);
        }
    }

    onExportOptionSelected(type: Compliance.ExportFileFormatEnum): void {
        this.showFileFormatSelection = false;
        this._exportFile(type);
    }

    onCustomExportOptionSelected(value: number): void {
        this.showFileFormatSelection = false;
        this._exportFile(ExportFileFormatEnum.Excel, value);
    }

    hideFileFormatSelection(): void {
        this.showFileFormatSelection = false;
    }

    onAdditionalActionClick(): void {
        this.showAdditionalActions = true;
    }

    hideAdditionalActions(): void {
        this.showAdditionalActions = false;
    }

    onAdditionalActionSelected(action: AgGridToolPanelAction): void {
        if (!action.disabled()) {
            action.onClickCallback();
            this.hideAdditionalActions();
        }
    }

    onBulkUpdateClick() {
        this.bulkUpdate.emit(null);
    }

    onBulkDeleteClick() {
        this.bulkDelete.emit(null);
    }

    specificationLoadComplete(): void {
        this.toolPanelReady.emit();
    }

    specificationChanged(specification: AgGridLayoutSpecificationModel): void {
        this.specification = specification;

        // Show filter floating filters if the selected specification has applied filters
        if (Object.keys(specification.filter).length) {
            this.gridOptions.floatingFilter = true;
            this.gridOptions.api.refreshHeader();
        }

        this.layoutSpecificationChanged.emit(this.specification);
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...'): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this._busyRefId);
            return;
        }

        const config: IBusyIndicatorConfig = {
            identifier: this._busyRefId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: true
        };

        if (this.exportOptions.canCancel) {
            config.hasAction = true;
            config.actionMessage = 'Cancel';
        }

        this._busyRef = this._busyIndicatorService.show(config);

        this._busyRef.onAction().pipe(takeUntil(this._processDestroy$)).subscribe(async () => {
            this._showBusyIndicator(title, 'Attempting to cancel export');
            await lastValueFrom(this._longRunningProcessRepository.cancel(this._longRunningProcessId));
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._processDestroy$)).subscribe(() => this._hideBusyIndicator());
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._processDestroy$.next();
    }

    private async _exportFile(exportType: Compliance.ExportFileFormatEnum, customExportOption?: number): Promise<void> {
        if (!(this.gridOptions && this.gridOptions.columnApi)) {
            return;
        }

        if (this.exportOptions.start) { // server-side
            const columnsToReturn: Compliance.NameValuePair<string>[] = [];
            const displayedColumns = this.gridOptions.columnApi.getAllDisplayedColumns();

            displayedColumns.forEach(item => {
                // Don't add if headerName is empty.
                if (item.getColDef().headerName && item.getColDef().headerName.trim()) {
                    columnsToReturn.push({ name: item.getColDef().field || item.getColDef().colId, value: item.getColDef().headerName });
                }
            });

            const lrp = await this.exportOptions.start(columnsToReturn, exportType, customExportOption);
            this._snackBarService.addById(lrp.longRunningProcessId, lrp.longRunningProcessTypeId);
        }

        if (this.exportOptions.clientSideExport) {
            const params = this.exportOptions.clientSideExport();
            this.gridOptions.api.exportDataAsCsv(params);
        }

        if (this.exportOptions.onExportClick) {
            this.exportOptions.onExportClick();
        }
    }

    showFileFormat(fileFormat: Compliance.ExportFileFormatEnum) {
        return (!this.exportOptions || !this.exportOptions.availableFileFormats) && fileFormat !== Compliance.ExportFileFormatEnum.PDF ||
            this.exportOptions && this.exportOptions.availableFileFormats && this.exportOptions.availableFileFormats.indexOf(fileFormat) >= 0;
    }
}
