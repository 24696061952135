import { Injectable } from '@angular/core';
import { Constants } from '../../constants.new';

@Injectable({
    providedIn: 'root'
})
export class LoadPdfIframeService {
    constructor(private constants:Constants) { }

    initializePdfJs(iframe: HTMLIFrameElement, file: string, readOnly?: boolean): void {
        this.updatePdfJs(iframe, file);
        // NOTE: Don't convert this to an arrow function; this is referenced and will have a different
        // meaning if this uses an arrow function instead
        iframe.addEventListener('load', function () {
            // https://stackoverflow.com/a/72229532/18059803
            this.contentWindow.onbeforeunload = function (event) {
                event.stopImmediatePropagation();
            };

            const doc = this.contentWindow.document;
            const styleElement = doc.createElement('style');
            styleElement.type = 'text/css';
            let styleText = '#outerContainer #openFile,#openFile { display: none; }';
            if(readOnly) {
                styleText += '\n .pdfViewer { pointer-events: none }';
            }
            styleElement.appendChild(doc.createTextNode(styleText));
            doc.head.appendChild(styleElement);
        });
    }

    updatePdfJs(iframe: HTMLElement, file: string): void {
        iframe.setAttribute('src', `${this.constants.PdfJsBase}web/viewer.html?file=${encodeURIComponent(file)}`);
        iframe.classList.add('preview-window');
    }
}
