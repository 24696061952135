import { GridsterItem } from "angular-gridster2";
import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

export class Dashboard {
    firstName: string;
    userWidgets: UserWidget[];
    availableWidgets: Widget[];

    constructor() {
        this.userWidgets = [];
    }
}

export class UserWidget implements GridsterItem {
    userWidgetId: number;
    widget: Widget;
    x: number;
    y: number;
    rows: number;
    cols: number;
    minItemCols: number;
    minItemRows: number;
}

export class Widget {
    widgetId: number;
    name: string;
    title: string;
    refreshPolicy: number;
    isMultiInstance: boolean;
    canRefresh: boolean;
    hasSettings: boolean;
    height: number;
    width: number;
}

export interface DataWidgetI {
    data$?: Observable<any>;
    onNewParams?: EventEmitter<object>;
}

export class WidgetCache {
    data: any;
    params: any;
}

export enum WidgetIds {
    AppealSavings = 1,
    QuickSearchButton = 2,
    StatesButton = 3,
    Calendar = 4,
    Shortcut = 5,
    PortfolioAnalysis = 6,
    PortfolioAnalysisSummary = 7,
    MyTasks = 8
}

export enum RefreshPolicies {
    NoData = 1,
    NoCache = 2, 
    InMemoryUI = 3
}

export interface CalendarWidgetFieldIds {
    categories: CalendarWidgetFieldIdCategory[];
}

export interface CalendarWidgetFieldIdCategory {
    type: number;
    columnIds: number[];
    availableDepths?: number[];
    columnsToKeep?: number[];
}


