import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ImportSpecificationRepository } from '../../../Repositories';

@Injectable()
export class DynamicFieldBehaviorService {
    private _showDynamicFieldConfiguration: boolean = false;
    private _selectedDynamicFieldBehaviorOption: Compliance.ImportDynamicFieldOptionModel;
    private _selectedDynamicFieldBehaviorOptions: System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[] = [];
    private _dynamicFieldBehaviorOptions: Compliance.ImportDynamicFieldOptionModel[] = [];
    private _fields: Compliance.ImportFieldModel[] = [];
    private _fieldMappings: Compliance.ImportFileSpecificationImportFieldModel[] = [];

    constructor(
        private readonly _entityImportSpecificationRepository: ImportSpecificationRepository)
    {}

    async initialize(importContentType: Compliance.ImportContentTypeModel,
                     importDynamicFieldBehavior: Compliance.ImportDynamicFieldOptionEnum,
                     setAllFields: Compliance.ImportDynamicFieldOptionEnum, dynamicFieldsBehavior: System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[],
                     fields: Compliance.ImportFieldModel[], fieldMappings: Compliance.ImportFileSpecificationImportFieldModel[]): Promise<void> {
        this._fields = fields;
        this._fieldMappings = fieldMappings;
        this._showDynamicFieldConfiguration = importContentType.supportsDynamicFieldBehaviorConfiguration;

        if (this._showDynamicFieldConfiguration) {
            this._dynamicFieldBehaviorOptions = await lastValueFrom(this._entityImportSpecificationRepository.getDynamicFieldBehaviorOptions())
            this._dynamicFieldBehaviorOptions.sort((x, y) => x.displayOrder - y.displayOrder);

            this._selectedDynamicFieldBehaviorOption = this._dynamicFieldBehaviorOptions.find(x => x.optionId === (importDynamicFieldBehavior || Compliance.ImportDynamicFieldOptionEnum.Ignore));

            this._selectedDynamicFieldBehaviorOptions = dynamicFieldsBehavior;
            this._selectedDynamicFieldBehaviorOptions.unshift({key: 0, value: setAllFields || Compliance.ImportDynamicFieldOptionEnum.Ignore});
        }
    }

    getFileDynamicFieldBehaviorOptions(): Compliance.ImportDynamicFieldOptionModel[] {
        return this._dynamicFieldBehaviorOptions;
    }

    get showDynamicFieldConfiguration(): boolean {
        return this._showDynamicFieldConfiguration;
    }

    getSelectedDynamicFieldBehaviorOption(): Compliance.ImportDynamicFieldOptionModel {
        return this._selectedDynamicFieldBehaviorOption;
    }

    getSelectedDynamicFieldBehaviorOptions(): System.Collections.Generic.KeyValuePair<number, Compliance.ImportDynamicFieldOptionEnum>[] {
        return this._selectedDynamicFieldBehaviorOptions || [];
    }

    async setSelectedDynamicFieldBehaviorOption(importId: number, optionId: Compliance.ImportDynamicFieldOptionEnum): Promise<void> {
        await lastValueFrom(this._entityImportSpecificationRepository.updateImportPropertyCharacteristicBehavior(importId, optionId));

        this._selectedDynamicFieldBehaviorOption = this._dynamicFieldBehaviorOptions.find(x => x.optionId === optionId);
        if (optionId !== Compliance.ImportDynamicFieldOptionEnum.Specify) {
            this._selectedDynamicFieldBehaviorOptions = [];
        }
    }

    getFieldMappings(): Compliance.ImportFileSpecificationImportFieldModel[] {
        return this._fieldMappings;
    }

    getFields(): Compliance.ImportFieldModel[] {
        return this._fields;
    }

    async updateImportFieldPropertyCharacteristicBehavior(model: Compliance.ImportFieldPropertyCharacteristicBehaviorUpdateModel): Promise<void> {
        this._selectedDynamicFieldBehaviorOptions = await lastValueFrom(this._entityImportSpecificationRepository.updateImportFieldPropertyCharacteristicBehavior(model));
    }
}
