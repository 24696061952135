(function () {

	// http://stackoverflow.com/questions/14995884/select-text-on-input-focus
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdSelectOnFocus', sdSelectOnFocus);

	sdSelectOnFocus.$inject = ['$window'];

	function sdSelectOnFocus($window) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			link: link,
			restrict: 'A'
		};
		return directive;

		function link(scope, element, attrs) {
			element.on('click', function () {
				if (!$window.getSelection().toString()) {
					// Required for mobile Safari
					this.setSelectionRange(0, this.value.length)
				}
			});
		}
	}
})();
