<div class="container">
    <div class="single-column-layout help-page">
        <h1>Help</h1>

        <div class="row" *ngIf="hasViewPermission">
            <i class="fa fa-question fa-5x fa-fw help-icon"></i>
            <div class="help-panel-content">
                <h2>Help Desk</h2>
                <ul>
                    <li>Click <a style="cursor: pointer;" href="https://softwaresupport.ryan.com/" target="_blank">here</a> to open the
                    PropertyPoint Help Desk where you can find a growing knowledge base with detailed training articles and additional
                    Support.</li>
                    <li>Click <a style="cursor: pointer;" href="https://app.smartsheet.com/b/form/46f9196e0f5741ea826e089958feefce" target="_blank">here</a> to submit a PropertyPoint User Setup/User Change request</li>
                    <li *ngIf="hasViewPermission">Click <a href="https://app.smartsheet.com/b/form/7163bb4e33e0483ca29cdc004233ca1a" target="_blank"
                        style="cursor: pointer;">here</a> to access an Appeal Application Request Form</li>
                    <li *ngIf="hasViewPermission">Click <a href="https://app.smartsheet.com/b/form/16c916bea257464cb276042890500d35" target="_blank"
                                                           style="cursor: pointer;">here</a> to access a Compliance Form Request Form</li>
                    <li *ngIf="hasViewPermission">Click <a href="https://app.smartsheet.com/b/form/18ecda301c384eea9e9b7e828c2187ca"
                            target="_blank" style="cursor: pointer;">here</a> to access a form to share an Assessment Release Date</li>
                    <li *ngIf="hasViewPermission">Click <a href="https://app.smartsheet.com/b/form/18fb351e31f847d58ed0fafc26d487d2"
                            target="_blank" style="cursor: pointer;">here</a> to access a form to share a Tax Bill Release Date</li>
                </ul>
            </div>
        </div>

        <div class="row" *ngIf="hasViewPermission">
            <i class="fa fa-thumbs-up fa-5x fa-fw help-icon"></i>
            <div class="help-panel-content">
                <h2>Acknowledgements</h2>
                <ul>
                    <li>Maps Icons Collection, <a href="https://mapicons.mapsmarker.com">https://mapicons.mapsmarker.com</a></li>
                </ul>
            </div>
        </div>

        <div class="row" *ngIf="!hasViewPermission">
            <i class="fa fa-info-circle fa-5x fa-fw help-icon"></i>
            <div class="help-panel-content" style="margin-top:0;">
                <p>Reach out to your Ryan contact or Account Handler if you need assistance with client support or navigation issues.</p>
                <p>For system issues, click here to open the <a style="cursor: pointer;" href="https://softwaresupport.ryan.com/" target="_blank">PropertyPoint Help Desk</a></p>
                <p>For the best browsing experience we recommend using Google Chrome.</p>
            </div>
            <div class="clearfix"></div>
        </div>


    </div>
</div>
