<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.Text)">
    <input [ngClass]="{ 'overridden': isOverridden }" [(ngModel)]="textValue" type="text" [name]="descriptor.columnName" (ngModelChange)="notifyTextChange()" class="form-control" [disabled]="isDisabled" [attr.maxlength]="getMaxLength()">
</div>
<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.Number)">
    <input [ngClass]="{ 'overridden': isOverridden }" [(ngModel)]="numberValue" type="number" [name]="descriptor.columnName" (ngModelChange)="notifyNumberChange()" class="form-control" [disabled]="isDisabled">
</div>
<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.Date)">
    <weissman-datetime class="float-start"
                       [dateOnly]="true"
                       (change)="notifyDateChange()"
                       [(inputDate)]="dateValue"
                       [required]="false"
                       [isDisabled]="isDisabled"
                       [isOverridden]="isOverridden">
    </weissman-datetime>
</div>
<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.YesNo)">
    <input [ngClass]="{ 'overridden': isOverridden }" [(ngModel)]="booleanValue" type="checkbox" (ngModelChange)="notifyBooleanChange()" [disabled]="isDisabled">
</div>
<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.Currency)">
    <ws-currency-input [override]="isOverridden"
                       [(ngModel)]="numberValue"
                       [name]="descriptor.columnName"
                       (ngModelChange)="notifyNumberChange()"
                       [disabled]="isDisabled">
    </ws-currency-input>
</div>
<div *ngIf="descriptorTypesEq(descriptor.descriptor.fieldType, fieldTypes.Picklist)">
    <select [ngClass]="{ 'overridden': isOverridden }" [(ngModel)]="textValue" (ngModelChange)="notifyTextChange()" [name]="descriptor.columnName" [disabled]="isDisabled" class="form-select form-select-sm">
        <option [ngValue]="null"></option>
        <option *ngFor="let pl of pickList" [ngValue]="pl">{{ pl }}</option>
    </select>
</div>
