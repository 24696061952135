import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { FilingControlReconciliationReport, ReturnFilingControlRow, ReturnFilingControlAssetFilter } from '../../../../Models/filingControlReconciliationReport';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../../../../AgGrid';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode, ICellRendererParams } from 'ag-grid-community';
import { AgGridLinkCellRenderer } from '../../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridLinkCellRendererParams } from '../../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { DecimalPipe } from '@angular/common';
import { FilingControlReconciliationReportAssessorRenderer, ICellRendererParamsForFilingControlReconciliationReportAssessorRenderer } from './agGridAssessorCellRenderer.component';
import { FilingControlGroupBy } from '../../returnOverview.service';
import { AgGridConsolidatedGroupCellRenderer, ICellRendererParamsForConsolidatedGroupCellRenderer } from './agGridConsolidatedGroupCellRenderer.component';
import { ReturnService } from '../../../../return.service';
import { FilingControlComponentChanges } from '../filingControl.component';

@Component({
    selector: 'filing-control-grouped-reconciliation-report',
    templateUrl: './filingControlGrouped.component.html',
    styleUrls: ['./filingControlGrouped.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilingControlGroupedReconciliationReportComponent implements OnChanges {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _decimalPipe: DecimalPipe) { }

    @Input()
    set groupBy(groupBy: FilingControlGroupBy) {
        this._groupBy = groupBy;
        this._setRowData();
    }

    private _gridApi: GridApi;
    private _groupBy: FilingControlGroupBy;

    gridId: System.Guid = 'E19FB903-3CE1-445C-82EB-5756B9F7C594';
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'totals-row': (params) => {
                const filingControl = params.data as ReturnFilingControlRow;
                if (!filingControl) {
                    return false;
                }
                return filingControl.isSubTotalRow || filingControl.isTotalRow;
            },
            'group-row': (params) => {
                const filingControl = params.data as ReturnFilingControlRow;
                if (!filingControl) {
                    return false;
                }
                return filingControl.isGroupRow;
            }
        }
    })
    .withContext(this)
    .withColumnResize()
    .withFullWidthCell(
        this._isFullWidthRow.bind(this),
        FilingControlReconciliationReportAssessorRenderer,
        {} as ICellRendererParamsForFilingControlReconciliationReportAssessorRenderer)
    .withRowHeight((params) => {
        const filingControl = params.data as ReturnFilingControlRow;
        return this._groupBy === FilingControlGroupBy.Assessor && filingControl && filingControl.isExpanded ? 112 : 26;
    })
    .withTextSelection()
    .withLoadingOverlay()
    .build();

    @Input() formRevisionId: number;
    @Input() data: FilingControlReconciliationReport;
    @Input() refreshing: boolean;

    @Output() navigateToAssets: EventEmitter<ReturnFilingControlAssetFilter> = new EventEmitter<ReturnFilingControlAssetFilter>();
    @Output() navigateToPreview: EventEmitter<ReturnFilingControlRow> = new EventEmitter<ReturnFilingControlRow>();
    @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                width: (!this._returnService.isConsolidatedReturn) ? AgGridColumns.textColumnWidth : AgGridColumns.textColumnLargeWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    if (!filingControl) {
                        return '';
                    }

                    return filingControl.parcelAcctNumberDisplay;
                },
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                cellRendererFramework: AgGridConsolidatedGroupCellRenderer,
                cellRendererParams: {
                    toggleShowMergedParcels: this._toggleShowMergedParcels.bind(this)
                } as ICellRendererParamsForConsolidatedGroupCellRenderer
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/site/${filingControl.siteId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return '-';
                        }

                        return filingControl.siteName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/company/${filingControl.companyId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return '-';
                        }

                        return filingControl.companyName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Prior Return',
                field: 'priorFilingDescription',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl && filingControl.priorFilingId) {
                            return '';
                        }

                        return `#/filing/${filingControl.priorFilingId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return '-';
                        }

                        return filingControl.priorFilingDescription;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams): string => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && (!(filingControl.isSubTotalRow || filingControl.isTotalRow)) && filingControl.priorFilingId !== filingControl.calculatedPriorFilingId) ? 'ws-override' : null;
                }
            },
            {
                headerName: 'Return Form',
                field: 'primaryFormRevisionName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return-form',
                    newWindow: true,
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        this.navigateToPreview.emit(filingControl);
                    },
                    getButtonLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return '-';
                        }

                        return filingControl.primaryFormRevisionName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return true;
                        }

                        const formRevisionId = this.formRevisionId || filingControl.primaryFormRevisionId;
                        if (!formRevisionId) {
                            return true;
                        }

                        const returnFormRevision = filingControl.returnFormRevisions.find(x => x.formRevisionId === formRevisionId);
                        if (!returnFormRevision) {
                            return true;
                        }
                    }
                } as AgGridLinkCellRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return filingControl && (!(filingControl.isSubTotalRow || filingControl.isTotalRow)) && !filingControl.primaryFormRevisionId ? 'cell-validation-error' : '';
                }
            },
            {
                headerName: 'Total Cost',
                field: 'cost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: false,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.cost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.cost) || 0;
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Reported Cost',
                field: 'reportableCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: true,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.reportableCost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.cost) || 0;
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Depreciated Value',
                field: 'depreciatedCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: false,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.depreciatedCost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.depreciatedCost) || 0;
                },
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'Est. Tax',
                field: 'taxLiability',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.taxLiability) || 0;
                },
                cellClass: 'ag-numeric-cell',
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2')
            },
            {
                headerName: 'Est. FMV',
                field: 'estimatedFMV',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: true,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.estimatedFMV) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.cost) || 0;
                },
                cellClass: 'ag-numeric-cell'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    ngOnChanges(changes: FilingControlComponentChanges): void {
        if (changes.data) {
            this._setRowData();
        }

        if (changes.refreshing) {
            if (changes.refreshing.currentValue) {
                this._gridApi && this._gridApi.showLoadingOverlay();
            } else {
                this._gridApi && this._gridApi.hideOverlay();
            }
        }
    }

    refresh(): void {
        this.refreshData.emit();
    }

    private _isFullWidthRow(rowNode: RowNode): boolean {
        const filingControl = rowNode.data as ReturnFilingControlRow;
        if (!filingControl) {
            return false;
        }
        return this._groupBy === FilingControlGroupBy.Assessor && filingControl.isGroupRow;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.data)) {
            return;
        }

        const data = (this._groupBy === FilingControlGroupBy.Consolidated)
            ? this.data.consolidatedReturnGroups.filter(x => x.isGroupRow || x.isExpanded || x.isSubTotalRow || x.isTotalRow)
            : this.data.groupedReturns;

        this._gridApi.setRowData(data.filter(x => !x.isTotalRow));
        this._gridApi.setPinnedBottomRowData(data.filter(x => x.isTotalRow));
        this._gridApi.sizeColumnsToFit();
    }

    private _toggleShowMergedParcels(params: ICellRendererParamsForConsolidatedGroupCellRenderer): void {
        const filingControl = params.data as ReturnFilingControlRow;
        if (!filingControl) {
            return;
        }
        const isShown = !filingControl.isExpanded;
        this.data.consolidatedReturnGroups.forEach(x => {
            if (x.returnId === filingControl.returnId) {
                x.isExpanded = isShown;
            }
        });

        this._setRowData();
    }
}
