'use strict';

/**
 * @ngdoc directive
 * @name weissman.directive:disableAnimation
 * @description
 * # disableAnimation
 */
angular.module('weissmanApp')
    .directive('disableAnimation', function($animate) {
        return {
            restrict: 'A',
            link: function($scope, $element, $attrs) {
                $attrs.$observe('disableAnimation', function(value) {
                    $animate.enabled(!value, $element);
                });
            }
        };
    });
