import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AceIconModule } from '../Icon/icon.module';
import { AceTabComponent } from './Tab/tab.component';
import { AceTabsComponent } from './Tabs/tabs.component';
import { AceTabSetComponent } from './tabSet.component';

@NgModule({
    imports: [
        CommonModule,
        AceIconModule,
        UIRouterUpgradeModule
    ],
    declarations: [
        AceTabComponent,
        AceTabsComponent,
        AceTabSetComponent
    ],
    exports: [
        AceTabSetComponent
    ]
})
export class AceTabSetModule {}
