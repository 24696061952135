import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'return-asset-bulk-update-action',
    templateUrl: './returnAssetBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class ReturnAssetBulkUpdateActionComponent implements OnInit{
    constructor(
    ){
        this.possibleActions = [
            { name: 'No Change', value: Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: Compliance.ReturnAssetBulkUpdateFieldActionEnum.ChangeTo },
            { name: 'Remove Override', value: Compliance.ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride },
            { name: 'Set To Classification Default', value: Compliance.ReturnAssetBulkUpdateFieldActionEnum.SetToClassificationDefault }
        ];
    }

    @Input() fieldAction: Compliance.ReturnAssetBulkUpdateFieldActionEnum;
    @Output() fieldActionChange = new EventEmitter<Compliance.ReturnAssetBulkUpdateFieldActionEnum>();
    @Input() disabled: boolean;
    @Input() fieldHasOverrides: boolean = false;
    @Input() onlyAllowRemoveOverrides: boolean = false;
    @Input() fieldHasClassificationDefault: boolean = false;
    @Output() fieldActionNameChange: EventEmitter<string> = new EventEmitter();

    possibleActions: Compliance.NameValuePair<Compliance.ReturnAssetBulkUpdateFieldActionEnum>[];
    enabledActions: Compliance.NameValuePair<Compliance.ReturnAssetBulkUpdateFieldActionEnum>[];
    selectedAction: Compliance.NameValuePair<Compliance.ReturnAssetBulkUpdateFieldActionEnum>;

    ngOnInit(): void {
        this.selectedAction = this.possibleActions[0];
        this.refresh(this.onlyAllowRemoveOverrides, this.fieldHasOverrides, this.fieldHasClassificationDefault);
    }

    refresh(onlyAllowRemoveOverrides: boolean, fieldHasOverrides: boolean, fieldHasClassificationDefault: boolean): void {
        if(onlyAllowRemoveOverrides) {
            this.enabledActions = this.possibleActions.filter(i => i.value === Compliance.ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride || i.value === Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange);
        } else if (!fieldHasOverrides) {
            this.enabledActions = this.possibleActions.filter(i => i.value !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.RemoveOverride);
        } else {
            this.enabledActions = this.possibleActions;
        }

        if (!fieldHasClassificationDefault) {
            this.enabledActions = this.enabledActions.filter(i => i.value !== Compliance.ReturnAssetBulkUpdateFieldActionEnum.SetToClassificationDefault);
        }
    }

    fieldActionChanged(action: Compliance.NameValuePair<Compliance.ReturnAssetBulkUpdateFieldActionEnum>) : void{
        this.selectedAction = action;
        this.fieldActionChange.next(action.value);
        this.fieldActionNameChange.next(action.name);
    }

    isSelected(action: Compliance.ReturnAssetBulkUpdateFieldActionEnum): boolean{
        return action === Compliance.ReturnAssetBulkUpdateFieldActionEnum.NoChange;
    }
}
