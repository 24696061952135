<div class="ws-section">
    <div class="ws-section__header">
        <h4 class="ws-section__header__title">
            Import Data
            <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
        </h4>
        <div class="ws-section__header__actions">
            <dynamic-field-behavior [importFileId]="importId" [isReadOnly]="false" *ngIf="showDynamicFieldConfiguration"></dynamic-field-behavior>
            <div class="ws-section__header__actions__item">
                <div><small>View:</small></div>
                <div dropdown container="body" placement="bottom right">
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                        {{ selectedGridColumnFilter }}
                        <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.mappedColumns)">{{ gridColumnFilters.mappedColumns }}</a>
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.staticColumns)">{{ gridColumnFilters.staticColumns }}</a>
                        </li>
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.unmappedColumns)">{{ gridColumnFilters.unmappedColumns }}</a>
                        </li>
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem">
                            <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="filterGridColumns(gridColumnFilters.allColumns)">{{ gridColumnFilters.allColumns }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="ws-section__header__actions__item">
                <button type="button"
                        class="flat-button small-button secondary-button float-start"
                        (click)="saveImportSpecification()"
                        helpTooltip
                        helpContentId="column-mapping-step.save-import-specification"
                        position="bottom">
                    <i class="fa fa-save"></i> Save Import Specification
                </button>
            </div>
            <div class="ws-section__header__actions__item">
                <button type="button"
                        class="flat-button small-button secondary-button"
                        [disabled]="!availableImportFieldsRequired.length && !availableImportFieldsOptional.length"
                        (click)="addStaticColumn()"
                        helpTooltip
                        helpContentId="column-mapping-step.add-static-column"
                        position="bottom">
                    Add Static Column
                </button>
            </div>
        </div>
    </div>
    <div class="ws-section__description small">To map a field to a column, drag and drop it to the table below.</div>
</div>

<!-- available fields -->
<div class="available-fields">
    <div class="ws-flex-container-horizontal ws-flex-align-items-center">
        <div class="ws-flex-none"><i>Required Fields:</i></div>
        <div class="field-pills-wrapper ws-flex-auto"
             [attr.container-id]="availableImportFieldsDragAndDropSourceContainerIdentifier"
             [dragula]="availableImportFieldsDragAndDropBagName"
             [(dragulaModel)]="availableImportFieldsRequired">
            <div class="field-pill field-pill-required" *ngFor="let field of availableImportFieldsRequired" [attr.field-id]="field.importFieldId">{{ field.displayName }}</div>
            <i *ngIf="availableImportFieldsRequired.length === 0">None</i>
        </div>
    </div>
    <div class="ws-flex-container-horizontal">
        <div class="ws-flex-none field-pill-label"><i>Optional Fields:</i></div>
        <div class="field-pills-wrapper ws-flex-auto"
             [attr.container-id]="availableImportFieldsDragAndDropSourceContainerIdentifier"
             [dragula]="availableImportFieldsDragAndDropBagName"
             [(dragulaModel)]="availableImportFieldsOptional">
            <div class="field-pill field-pill-optional" *ngFor="let field of availableImportFieldsOptional" [attr.field-id]="field.importFieldId">{{ field.displayName }}</div>
            <i *ngIf="availableImportFieldsOptional.length === 0">None</i>
        </div>
    </div>
</div>

<!-- agGrid && agGrid overlay for handling drag-and-drop -->
<div class="grid-wrapper ws-flex-auto" *ngIf="canGridInitialize">
    <ag-grid-overlay
        [isVisible]="availableImportFieldsOverlayVisible"
        [bagName]="availableImportFieldsDragAndDropBagName"
        [sourceElementIdAttribute]="'field-id'"
        (drop)="onAvailableImportFieldMapped($event)">
    </ag-grid-overlay>

    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        (columnResized)="onAgGridColumnResized($event)"
                        (bodyScroll)="onAgGridBodyScroll($event)">
    </ws-ag-grid-angular>
</div>
