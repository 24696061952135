import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../AgGrid';
import { ElectronicFilingRepository } from '../Repositories';

export interface ElectronicFilingDataSourceParams {
    companyId: number;
    stateId: number;
    electronicFilingTypeId: number,
    taxYear: number;
}

export class ElectronicFilingAgGridDataSource extends AgGridDataSourceBase<ElectronicFilingDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _electronicFilingRepository: ElectronicFilingRepository,
        protected _dataSourceParamsFn: () => ElectronicFilingDataSourceParams) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ElectronicFilingPropertyEnum>[] = [
        { name: 'assessorAbbr', value: Compliance.ElectronicFilingPropertyEnum.AssessorAbbr },
        { name: 'parcelAccountNumber', value: Compliance.ElectronicFilingPropertyEnum.ParcelAccountNumber },
        { name: 'filingBatchNumber', value: Compliance.ElectronicFilingPropertyEnum.FilingBatchNumber },
        { name: 'returnDueDate', value: Compliance.ElectronicFilingPropertyEnum.ReturnDueDate },
        { name: 'status', value: Compliance.ElectronicFilingPropertyEnum.Status },
        { name: 'siteName', value: Compliance.ElectronicFilingPropertyEnum.SiteName },
        { name: 'ppeFilingAvailability', value: Compliance.ElectronicFilingPropertyEnum.PPEFilingAvailability },
        { name: 'cost', value: Compliance.ElectronicFilingPropertyEnum.Cost },
        { name: 'reportedCost', value: Compliance.ElectronicFilingPropertyEnum.ReportedCost },
        { name: 'reportedValue', value: Compliance.ElectronicFilingPropertyEnum.ReportedValue },
        { name: 'taxLiability', value: Compliance.ElectronicFilingPropertyEnum.TaxLiability },
        { name: 'estimatedFMV', value: Compliance.ElectronicFilingPropertyEnum.EstimatedFMV },
        { name: 'filingBatchName', value: Compliance.ElectronicFilingPropertyEnum.FilingBatchName },
        { name: 'assessorCity', value: Compliance.ElectronicFilingPropertyEnum.AssessorCity },
        { name: 'companyName', value: Compliance.ElectronicFilingPropertyEnum.CompanyName },
        { name: 'downloadedDate', value: Compliance.ElectronicFilingPropertyEnum.DownloadedDate },
        { name: 'isAmended', value: Compliance.ElectronicFilingPropertyEnum.IsAmended },
        { name: 'isConsolidatedReturn', value: Compliance.ElectronicFilingPropertyEnum.IsConsolidatedReturn },
        { name: 'notReportedAssetCount', value: Compliance.ElectronicFilingPropertyEnum.NotReportedAssetCount },
        { name: 'ppeFilingRemarks', value: Compliance.ElectronicFilingPropertyEnum.PPEFilingRemarks },
        { name: 'ppeFilingUrl', value: Compliance.ElectronicFilingPropertyEnum.PPEFilingUrl },
        { name: 'priorReturnName', value: Compliance.ElectronicFilingPropertyEnum.PriorReturnName },
        { name: 'reportedAssetCount', value: Compliance.ElectronicFilingPropertyEnum.ReportedAssetCount },
        { name: 'siteNumber', value: Compliance.ElectronicFilingPropertyEnum.SiteNumber },
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Compliance.ElectronicFilingSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: this._dataSourceParams.stateId,
            electronicFilingTypeId: this._dataSourceParams.electronicFilingTypeId,
            taxYear: this._dataSourceParams.taxYear,
            selectedRows: {selectAllRows: true} as Compliance.SelectedRowsModel
        };

        return await lastValueFrom(this._electronicFilingRepository.getRowIds(this._dataSourceParams.companyId, searchParams));
    }

    getSearchModel(startIndex?: number, endIndex?: number): Compliance.ElectronicFilingSearchModel {
        return {
            pagination: {
                skip: (startIndex) ? startIndex : 0,
                take: (endIndex) ? endIndex - startIndex + 1 : 0
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: this._dataSourceParams.stateId,
            taxYear: this._dataSourceParams.taxYear,
            electronicFilingTypeId: this._dataSourceParams.electronicFilingTypeId,
            selectedRows: {selectAllRows: true} as Compliance.SelectedRowsModel
        };
    }

    protected canGetRows(): boolean {
        return (!!(this._dataSourceParams.stateId && this._dataSourceParams.electronicFilingTypeId && this._dataSourceParams.companyId));
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.ElectronicFilingSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: this._dataSourceParams.stateId,
            electronicFilingTypeId: this._dataSourceParams.electronicFilingTypeId,
            taxYear: this._dataSourceParams.taxYear,
            selectedRows: {selectAllRows: true} as Compliance.SelectedRowsModel
        };

        const result = await lastValueFrom(this._electronicFilingRepository.getList(this._dataSourceParams.companyId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
