import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HybridAdapterUtility } from '../../../Hybrid/hybrid-adapter.utility';
import { AceIconModule } from '../Icon/icon.module';
import { AceSelectDropdownComponent } from './Select-Dropdown/dropdown.component';
import { AceSelectComponent } from './select.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DirectivesModule } from '../../Directives/directives.module';
import { AceSelectService } from './select.service';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DirectivesModule,
        BrowserAnimationsModule,
        AceIconModule
    ],
    declarations: [
        AceSelectComponent,
        AceSelectDropdownComponent
    ],
    providers: [AceSelectService],
    exports: [
        AceSelectComponent,
        AceSelectDropdownComponent
    ]
})
export class AceSelectModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('aceSelect', AceSelectComponent);
    }
}
