<ws-modal-wrapper headerLabel="Create a New Company Budget" acceptLabel="Save" (save)="save()" (cancel)="cancel()"
                  [disableSave]="!isSaveable()">
    <div class="text-center loading-all" *ngIf="addInProgress">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div class="ws-flex-container-vertical ws-stretch" *ngIf="!addInProgress">
        <table class="add-company-budget-fields">
            <tbody>
                <tr>
                    <td>
                        <label>Budget Name:</label>
                    </td>
                    <td>
                        <div>
                            <input type="text" class="form-control" style="width: auto; display: inline-block;"
                                   [(ngModel)]="budgetName" maxlength="100">
                        </div>
                        <div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Fiscal Period:</label>
                    </td>
                    <td>
                        <div>
                            <select class="form-select form-select-sm" style="width: auto;" [(ngModel)]="fiscalStartYear">
                                <option *ngFor="let fp of availableFiscalPeriods" [ngValue]="fp.year">{{fp.period}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <label>Budget Date:</label>
                    </td>
                    <td>
                        <weissman-datetime [readOnly]="false" [(inputDate)]="budgetDate" [dateOnly]="true"></weissman-datetime>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ws-modal-wrapper>
