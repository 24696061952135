import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { TaxRatesPanelModule } from '../Tax-Rates/taxRatesPanel.module';
import { CommentsModule } from '../../Comments/comments.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { TaxingDistrictModule } from '../Taxing-District/taxingDistrict.module';
import { StateAssessorSharedModule } from '../../State/stateAssessorShared.module';
import { AssessorInfoComponent } from './Assessor-Info/assessor.info.component';
import { AssessorAvailableCollectorsComponent } from './Assessor-Available-Collectors/assessor.available.collectors.component';
import { ContactModule } from '../../Contact/contact.module';
import { PropertyCharacteristicsModule } from '../../Property-Characteristics/propertyCharacteristics.module';
import { AssessorComponent } from './assessor.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        CommentsModule,
        TaxingDistrictModule,
        StateAssessorSharedModule,
        ContactModule,
        PropertyCharacteristicsModule,
        TaxRatesPanelModule
    ],
    declarations: [
        AssessorComponent,
        AssessorInfoComponent,
        AssessorAvailableCollectorsComponent
    ]
})
export class AssessorModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('assessor', AssessorComponent);
    }
}
