import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BalanceAdjustmentsService } from './balanceAdjustments.service';
import { NavigationService } from '../../Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';

interface AccrualBalanceAdjustmentModelUI extends Core.AccrualBalanceAdjustmentModel {
    isEdit: boolean;
    isHovered: boolean;
}

@Component({
    selector: 'accruals-adjustments-list',
    templateUrl: './accrualsAdjustmentsList.component.html',
    styles: [`
        select.form-control {
            display: inline-block;
            width: auto;
        }
        td {
            vertical-align: middle !important;
            height: 42px;
        }
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            max-height: 100%;
            width: 100%;
        }
    `]
})
export class AccrualsAdjustmentsListComponent implements OnInit {
    constructor(
        private readonly _navigationService: NavigationService,
        private readonly _balanceAdjustmentsService: BalanceAdjustmentsService,
        private readonly _toastr: ToastrService,
        private readonly _messageModalService: MessageModalService
    ) { }

    @Input() parcelId: number;
    @Input() editMode: boolean;
    @Input() hasEditPermission: boolean;
    @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    adjustments: AccrualBalanceAdjustmentModelUI[];
    selectionData: Core.AccrualBalanceAdjustmentSelectionDataModel;
    serverAction: boolean = false;

    private _originalAdjustment: AccrualBalanceAdjustmentModelUI;

    async ngOnInit(): Promise<void> {
        this.serverAction = true;

        try {
            this.selectionData = await this._balanceAdjustmentsService.getSelectionData(this.parcelId);
            this.adjustments = (await this._balanceAdjustmentsService.getAdjustments(this.parcelId)).adjustments as AccrualBalanceAdjustmentModelUI[];
        }
        finally {
            this.serverAction = false;
        }
    }

    getAcctPeriodName(accountingPeriodId: number): string {
        const acctPeriod = _.find(this.selectionData.accountingPeriods, { accountingPeriodId });

        return acctPeriod ? acctPeriod.name : '';
    }

    getCollectorName(collectorId: number): string {
        const collector = _.find(this.selectionData.collectors, { collectorId });

        return collector ? collector.name : '';
    }

    getImpactAmount(impacts: Core.AccrualBalanceAdjustmentJournalImpactModel[], glAccountId: number): number {
        const impact = _.find(impacts, { glAccountId });

        return impact ? impact.amount : null;
    }

    setImpactAmount(impacts: Core.AccrualBalanceAdjustmentJournalImpactModel[], glAccountId: number, amount: number): void {
        const impactIdx = _.findIndex(impacts, { glAccountId });

        if(impactIdx >= 0) {
            impacts[impactIdx].amount = amount;
        } else {
            impacts.push({ glAccountId, amount } as Core.AccrualBalanceAdjustmentJournalImpactModel);
        }
    }

    writeJournalChanged(adjustment: AccrualBalanceAdjustmentModelUI): void {
        if(!adjustment.writeJournal) {
            adjustment.journalImpacts = [];
        }
    }

    edit(adjustment: AccrualBalanceAdjustmentModelUI) {
        this._originalAdjustment = _.cloneDeep(adjustment);
        adjustment.isEdit = true;

        this._setEditMode(true);
    }

    cancel(adjustment: AccrualBalanceAdjustmentModelUI) {
        if(adjustment.accrualBalanceAdjustmentId) {
            adjustment.isEdit = false;
            _.assign(adjustment, this._originalAdjustment);
        } else {
            _.remove(this.adjustments, adjustment);
        }

        this._setEditMode(false);
    }

    async delete(adjustment: AccrualBalanceAdjustmentModelUI): Promise<void> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete this adjustment?', 'Confirm Delete');
        }
        catch {
            return Promise.resolve();
        }

        this.serverAction = true;
        try {
            await this._balanceAdjustmentsService.deleteAdjustment(this.parcelId, adjustment.accrualBalanceAdjustmentId);
            _.remove(this.adjustments, adjustment);
        }
        finally {
            this.serverAction = false;
        }
    }

    async save(adjustment: AccrualBalanceAdjustmentModelUI) {
        if(this._invalidData(adjustment)) {
            return;
        }

        this.serverAction = true;
        try {
            adjustment.journalImpacts = _.reject(adjustment.journalImpacts, {amount: null});
            const savedAdjustment = await this._balanceAdjustmentsService.updateAdjustment(this.parcelId, adjustment);
            _.assign(adjustment, savedAdjustment);

            adjustment.isEdit = false;
            this._setEditMode(false);
        }
        finally {
            this.serverAction = false;
        }

    }

    addRow(): void {
        const firstOpenAccountingPeriod = _.find(this.selectionData.accountingPeriods, {isClosed: false});

        if(!firstOpenAccountingPeriod) {
            this._toastr.error('Cannot Add. No open account periods available.');
            return;
        }

        const newRow = {
            parcelId: this.parcelId,
            accountingPeriodId: firstOpenAccountingPeriod.accountingPeriodId,
            collectorId: this.selectionData.collectors[0].collectorId,
            description: '',
            writeJournal: false,
            journalImpacts: [],
            isPeriodClosed: false,
            rowVersion: [],
            isEdit: true
        } as AccrualBalanceAdjustmentModelUI;

        this.adjustments.push(newRow);
        this._setEditMode(true);
    }

    private _setEditMode(editMode: boolean): void {
        this.editMode = editMode;
        this.editModeChange.emit(this.editMode);

        if(this.editMode) {
            this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
        } else {
            this._navigationService.disableNavWarning();
        }
    }

    private _isAccrualInvalid(adjustment: AccrualBalanceAdjustmentModelUI): boolean {
        const noValue = adjustment.accrualAmount === null || adjustment.accrualAmount === undefined;
        const noJournalImpacts = !(adjustment && adjustment.writeJournal && !!adjustment.journalImpacts.length);

        return noValue && noJournalImpacts;
    }

    private _rowDoesNotBalance(adjustment: AccrualBalanceAdjustmentModelUI): boolean {
        if(!adjustment.writeJournal) {
            return false;
        }

        const impactsSum = _.chain(adjustment.journalImpacts)
            .map('amount')
            .compact()
            .sum()
            .value();

        return (adjustment.accrualAmount || 0) + impactsSum !== 0;
    }

    private _invalidData(adjustment: AccrualBalanceAdjustmentModelUI): boolean {
        if(!adjustment.description) {
            this._toastr.error('Description is Required');
            return true;
        }

        if(this._isAccrualInvalid(adjustment)) {
            this._toastr.error('Accrual Amount is required if no GL Account amounts are entered');
            return true;
        }

        if(this._rowDoesNotBalance(adjustment)) {
            this._toastr.error('Row Amounts do not balance');
            return true;
        }

        return false;
    }
}
