import { Injectable } from '@angular/core';
import { SDHttpService } from '../Routing/sd-http.service';
import { AssessmentTaxesGridData, AssessmentTaxesCriteria } from './assessment-taxes-details.models';

@Injectable(
    { providedIn: 'root' }
)
export class AssessmentTaxesDetailsService {
    constructor(private http: SDHttpService) { }

    getSiteYears(siteId: number): Promise<number[]> {
        return this.http.get(`/api/parcel/years/${  siteId}`);
    }

    getSiteSummary(siteId: number, payload: AssessmentTaxesCriteria): Promise<AssessmentTaxesGridData> {
        return this.http.post(`/api/taxSummary/site/${  siteId}`, payload);
    }

    getParcelSummary(parcelId: number, payload: AssessmentTaxesCriteria): Promise<AssessmentTaxesGridData> {
        return this.http.post(`/api/taxSummary/parcel/${  parcelId}`, payload);
    }
}
