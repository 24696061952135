import { environment } from '../environments/environment';

/* Here the term "environment" refers to the location deployed to (dev, qa, prod, etc.). To simplify deploys, there is
no environment-specific code in the UI. Angular's built-in concept of "environment" is used to distinguish
locally-hosted UIs from deployed UIs, and the solution detects that it is on a production environment with the
hard-coded prod URL, but all other configuration comes from the API. Here are the environment-specific settings:

developerMode - If Angular and AngularJS should be running in developer mode or production mode. Also referenced
from time to time for things like navigation entries.
    Use developerMode when the environment config says to, otherwise false
signalRMessageBrokerUrl - Where the UI should connect to SignalR
    Retrieved from API
weissman2Url - Where the UI should connect to the Weissman 2 service (which replaces SignalRBroker when present)
    Retrieved from API
signalRUseUnsafeLogin - Set when SignalR should come from a different host using CORS
amplitudeApiKey - amplitude.com
runtimeEnvironment - What color banner should be displayed on the navbar
    Are we in developerMode? Use the local banner. Is this production? Use the prod banner. Otherwise,
    use the test banner unless overridden by API later.
*/
export class WeissmanEnvironmentConfig {
    developerMode: boolean;
    signalRMessageBrokerUrl: string;
    weissman2Url: string;
    signalRUseUnsafeLogin: boolean;
    amplitudeApiKey: string;
    runtimeEnvironment: 'prod' | 'uat' | 'test' | 'local';

    static initEnvironment(): WeissmanEnvironmentConfig {
        const config = new WeissmanEnvironmentConfig();
        config.developerMode = !environment.production;
        config.runtimeEnvironment = config.developerMode ? 'local' : 'test';
        const urlMatch = /^(https?:\/\/[^\/\?]+)([\/\?].*)?$/.exec(window.location.href);
        if (urlMatch && urlMatch[1].toLowerCase() === 'https://propertypoint.tax') {
            config.runtimeEnvironment = 'prod';
        } else if (urlMatch && urlMatch[1].toLowerCase() === 'https://propertypoint-uat.ryanplatform.com') {
            config.runtimeEnvironment = 'uat';
        }
        return config;
    }
}
