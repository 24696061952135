import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface LinkedSitesListGridNameCellRendererParams extends ICellRendererParams {
    getName: (params: LinkedSitesListGridNameCellRendererParams) => string;
    getLink: (params: LinkedSitesListGridNameCellRendererParams) => string;
    isMaster: (params: LinkedSitesListGridNameCellRendererParams) => boolean;
    canNavigate: boolean;
}

@Component({
    selector: 'linked-sites-list-grid-name-cell',
    template: `
    <span *ngIf="params.isMaster(params)"
        class="icon-master"
        helpTooltip
        helpContentId="linked-sites.master-site"
        position="bottom">
        <i class="fa fa-star"></i>
    </span>
    <span *ngIf="!params.canNavigate">
        {{ params.getName(params) }}
    </span>
    <a *ngIf="params.canNavigate"
       class="grid-link-cell"
       [href]="params.getLink(params)"
       target="_blank"
       rel="noopener noreferrer"
       helpTooltip
       helpContentId="app.view-site"
       position="bottom">
        {{ params.getName(params) }}
    </a>
    `
})
export class LinkedSitesListGridNameCellRendererComponent implements ICellRendererAngularComp {
    params: LinkedSitesListGridNameCellRendererParams;

    agInit(params: LinkedSitesListGridNameCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
