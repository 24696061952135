<ws-busy-indicator message="Creating Amended Returns" *ngIf="isSaving"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Amend Returns"
                  headerHelpContentId="amend-returns.header"
                  (save)="submit()"
                  (cancel)="cancel()"
                  [disableSave]="!selectedRows.length">
    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
    <div class="panel-flat-body d-flex flex-column h-100 m-0">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                         (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
