<ws-modal-wrapper headerLabel="Parcel Bulk Update"
                  headerHelpContentId="parcel-bulk-update.header"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid">
    <div class="ws-flex-container-vertical ws-stretch parcel-bulk-update">
        <form>
            <div class="table-responsive">
                <table class="table table-striped table-condensed table-borderless-body">
                    <tbody>
                        <tr>
                            <td>Consolidation</td>
                            <td>
                                <parcel-bulk-update-action [(fieldAction)]="consolidatingTypeAction" (fieldActionChange)="validateForm()"></parcel-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <select name="consolidatingType"
                                            [(ngModel)]="consolidatingType"
                                            [disabled]="isDisabled(consolidatingTypeAction)"
                                            class="form-select form-select-sm">
                                        <option *ngFor="let ct of consolidatingTypes" [ngValue]="ct.value">{{ ct.name }}</option>
                                    </select>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <p *ngIf="validationMessage" class="text-danger">{{ validationMessage }}</p>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
