import { Component, OnInit, OnDestroy } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ImportMappingModalParams {
    header: string;
    message: string;
    options: Compliance.NameValuePair<any>[];
}

@Component({
    selector: 'import-mapping-modal',
    templateUrl: './importMappingModal.component.html'
})
export class ImportMappingModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<Compliance.ImportFieldModel, Compliance.NameValuePair<string>[]> {
    constructor(private readonly _bsModalRef: BsModalRef) {}

    params: Compliance.ImportFieldModel;
    result: Compliance.NameValuePair<string>[];

    clarificationField: Compliance.ImportFieldModel;
    options: string[];
    selectedOption: UntypedFormControl = new UntypedFormControl();

    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.clarificationField = this.params.clarificationFields[0];
        this.options = this.clarificationField.possibleOptions;
        this.selectedOption.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
            this.result = [];
            this.result.push({name: this.clarificationField.importFieldId, value: x} as Compliance.NameValuePair<string>);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onAccept(): void {
        this._bsModalRef.hide();
    }

    onCancel(): void {
        this.result = null;
        this._bsModalRef.hide();
    }
}
