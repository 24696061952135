<div class="modal-header">
    <h4 class="modal-title float-start">
        Parcel Collector Tax Rate Setup
        <span *ngIf="title"> - {{title}}</span>
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body exclude-modal-styling tax-rate-setup">
    <div class="text-center loading-all" *ngIf="loadingAll">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="model && !loadingAll" class="row content-container">
        <div *ngIf="readOnly" class="top-actions">
            <span style="font-weight:bold;">Tax Rate Area:</span>
            <span>{{model.name || '(none)'}}</span>
        </div>
        <div *ngIf="!readOnly" class="top-actions">
            <label>Tax Rate Area:</label>
            <input
                #taxRateAreaInput
                name="taxRateArea"
                type="text"
                placeholder="Tax Rate Area..."
                class="form-control"
                autocomplete="off"
                [ngModel]="model.name"
                (blur)="onTaxRateAreaTextChange()"
                [disabled]="loading"
                [typeahead]="availableTaxRateAreas"
                typeaheadOptionField="name"
                [typeaheadScrollable]="true"
                [typeaheadMinLength]="0"
                [typeaheadOptionsLimit]="99999"
                (typeaheadOnSelect)="onTaxRateAreaIdChange($event)" />
            <div class="clear-tax-rate-container">
                <button
                    *ngIf="model.taxRateAreaId"
                    [disabled]="loading"
                    type="button"
                    class="edit-control small-button flat-button icon-button warning-button"
                    (click)="clearSelectedTaxRateAreaId()"
                >
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <!-- <button type="button" class="flat-button info-button small-button search" [disabled]="loading">Search</button> -->
        </div>
        <hr />
        <label *ngIf="!loadingAll && !isDocumentProcessing" class="year-label">
            Show Rates for Tax Year:
            <select
                [disabled]="loading"
                [ngModel]="displayYear"
                (ngModelChange)="onDisplayYearChange($event)"
                class="form-select form-select-sm"
            >
                <option *ngFor="let year of availableYears">{{year}}</option>
            </select>
        </label>
        <div class="table-container">
            <table class="tax-rate-setup">
                <tr>
                    <th>Code</th>
                    <th>Tax Authority</th>
                    <th>Category</th>
                    <th>Assessment Source</th>
                    <th class="right-align" style="width:4em">Rate</th>
                    <th></th>
                </tr>
                <ng-container *ngFor="let group of currentTaxAuthorityGroups; index as i">
                    <tr *ngIf="groupByCategory && i !== 0" class="grouping-spacer"><td colspan="5"></td></tr>
                    <tr *ngFor="let taxAuthority of group.taxAuthorities">
                        <td>{{taxAuthority.code}}</td>
                        <td>{{taxAuthority.name}}</td>
                        <td>{{taxAuthorityCategoryName(taxAuthority.taxAuthorityCategoryId)}}</td>
                        <td>{{taxableAssessmentTypeName(taxAuthority.taxableAssessmentTypeId)}}</td>
                        <td [class]="'right-align ' + rateClass(taxAuthority)" [tooltip]="taTooltip(taxAuthority)" container="body">
                            {{taxAuthority.rate * 100 | number:"1.6-6"}}
                        </td>
                        <td>
                            <button
                                *ngIf="!model.taxRateAreaId && !readOnly"
                                [disabled]="loading"
                                type="button"
                                class="edit-control small-button flat-button icon-button warning-button remove-authority"
                                (click)="removeTaxAuthority(taxAuthority)"
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <tr class="no-hover">
                    <td colspan="3">
                        <select
                            *ngIf="model && !model.taxRateAreaId && filteredAvailableTaxAuthorities.length > 0 && !readOnly"
                            [ngModel]="newTaxAuthorityId"
                            (ngModelChange)="onNewTaxAuthorityChange($event)"
                            [disabled]="loading"
                            class="form-select form-select-sm new-tax-auth"
                        >
                            <option value="">Add new tax authority</option>
                            <option *ngFor="let availableTaxAuthority of filteredAvailableTaxAuthorities" [value]="availableTaxAuthority.taxAuthorityId">
                                {{availableTaxAuthority.name}}
                            </option>
                        </select>
                        <span *ngIf="model && !model.taxRateAreaId && filteredAvailableTaxAuthorities.length <= 0">
                            (no more available tax authorities to add)
                        </span>
                    </td>
                    <ng-container *ngIf="model && model.taxAuthorities && model.taxAuthorities.length > 0">
                        <td class="total-label">Total:</td>
                        <td [class]="totalEstimated ? 'total-rate estimated' : 'total-rate'">{{totalRate * 100 | number:"1.6-6"}}</td>
                        <td></td>
                    </ng-container>
                    <ng-container *ngIf="!(model && model.taxAuthorities && model.taxAuthorities.length > 0)">
                        <td colspan="3"></td>
                    </ng-container>
                </tr>
            </table>
        </div>
        <hr />
    </div>
</div>
<div class="modal-footer tax-rate-setup">
    <div *ngIf="!loadingAll" class="group-by-category">
        <label>
            <input type="checkbox" [ngModel]="groupByCategory" (ngModelChange)="onGroupByCategoryChanged($event)" />
            Group by Category
        </label>
    </div>
    <div *ngIf="!readOnly" class="action-buttons">
        <button class="flat-button success-button" *ngIf="!isDocumentProcessing" [disabled]="loading || loadingAll" (click)="save()">Save</button>
        <button class="flat-button primary-button" *ngIf="isDocumentProcessing" [disabled]="loading || loadingAll" (click)="save()">OK</button>
        <button class="flat-button warning-button" (click)="closeModal()">Cancel</button>
    </div>
    <div *ngIf="readOnly" class="action-buttons">
        <button class="flat-button info-button" (click)="closeModal()">Done</button>
    </div>
</div>
