import { Component, OnInit, OnDestroy} from '@angular/core';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnService } from '../../../return.service';
import { ReturnOutputService } from '..//returnOutput.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'return-output-printing-mailing',
    templateUrl: './returnOutputPrintingMailing.component.html'
})
export class ReturnOutputPrintingMailingComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnOutputService: ReturnOutputService
    ) { }

    private _downloadStatusChangeSub: Subscription;
    private _downloadGenerateFilesProgressChangeSub: Subscription;
    private _downloadArchivingFilesProgressChangeSub: Subscription;

    get returnOutputModel(): Compliance.ReturnOutputModel {
        return this._returnOutputService.sharedState && this._returnOutputService.sharedState.returnOutputModel;
    }

    ngOnInit(): void {
        this._returnOutputService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnOutputService.unsubscribeFromServiceActivationCycle(this);
        this._downloadStatusChangeSub && this._downloadStatusChangeSub.unsubscribe();
        this._downloadGenerateFilesProgressChangeSub && this._downloadGenerateFilesProgressChangeSub.unsubscribe();
        this._downloadArchivingFilesProgressChangeSub && this._downloadArchivingFilesProgressChangeSub.unsubscribe();
    }

    async onReturnPartServiceActivated(): Promise<void> {
        await this._returnOutputService.loadSharedState(this._returnService.filingBatchId);
    }

    onReturnPartServiceDeactivated(): void { }

    async onOptionChange(): Promise<void> {
        if (!this._returnOutputService.sharedState.returnOutputModel.includeReturns) {
            this._returnOutputService.sharedState.returnOutputModel.embedExcelReportsInPdf = false;
        }

        await this._returnOutputService.saveSharedState();
    }
}
