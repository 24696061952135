import { IFilterAngularComp } from 'ag-grid-angular';
import { HostListener, Directive } from '@angular/core';
import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterOptionDef, IFilterParams, ISimpleFilterParams, ITextFilterParams, RowNode, ValueGetterParams, ColumnApi } from 'ag-grid-community';
import { FilterUIModel, FilterValueModel } from './filterUI.model';
import { AgGridFilterParams } from '../agGridFilterParams';
import * as _ from 'lodash';

export interface WSTextFilterParams extends ITextFilterParams {
    textCustomComparator: (filter: string, gridValue: any, filterText: string, rowData?: any) => boolean;
}

@Directive()
export abstract class AgGridFilterBase<T extends WSTextFilterParams | ISimpleFilterParams | IFilterParams> implements IFilterAngularComp {
    protected constructor() {}

    @HostListener('keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this._filterChanged();
            this._hideFilter();
        }
        event.stopPropagation();
    }

    selectFilterOptions: IFilterOptionDef[] = [];
    filterOptions: Array<string | IFilterOptionDef> = [];
    filterConditionType: Core.FilterConditionTypeEnum = Core.FilterConditionTypeEnum.None;
    filterValues: FilterValueModel[] = [];

    protected _params: T;
    protected _valueGetter: (rowNode: RowNode) => any;
    protected _filterValueGetter: string | ((params: ValueGetterParams) => any);
    protected _hideFilter: Function;
    protected _searchTypeMap: Map<string, (filterValue: string, cellValue: string) => boolean>;
    protected _defaultFilterOptions: string[];
    protected _appliedModel: FilterUIModel;

    abstract agInit(params: IFilterParams): void;
    abstract doesFilterPass(params: IDoesFilterPassParams): boolean;
    abstract setModel(model: FilterUIModel): void;
    abstract getModel(): FilterUIModel;

    isFilterActive(): boolean {
        return this.filterValues.some(x => x.filterValue !== null && x.filterValue !== ''
            || (x.filterType
                    && (x.filterType.displayKey === AgGridFilterParams.blankFilterOptionDef.displayKey
                        || x.filterType.displayKey === AgGridFilterParams.notBlankFilterOptionDef.displayKey)));
    }

    afterGuiAttached(params: IAfterGuiAttachedParams): void {
        this._hideFilter = params.hidePopup;
    }

    onFloatingFilterChanged(type: string, value: string, submit: boolean): void {
        this.onChange(value, 0, type);
        if (submit) {
            this._filterChanged();
        }
    }

    onChange(newValue: string, index: number, newType?: string): void {
        let changed = false;
        if (this.filterValues[index].filterValue !== newValue) {
            this.filterValues[index].filterValue = (newValue && newValue.trim()) || null;
            changed = true;
        }
        if (newType !== undefined && (!this.filterValues[index].filterType || this.filterValues[index].filterType && this.filterValues[index].filterType.displayKey !== newType)) {
            this.filterValues[index].filterType = this.selectFilterOptions.find(o => o.displayKey === newType) || this.selectFilterOptions[0];
            changed = true;
        }
        if (changed) {
            this._params.filterModifiedCallback();
        }
    }

    applyFilter(): void {
        this._filterChanged();
        this._hideFilter();
    }

    clearFilter(): void {
        this._resetFilter();
        this._filterChanged();
        this._hideFilter();
    }

    clearInput(index: number): void {
        this.onChange(null, index);
    }

    addFilterValue(): void {
        this.filterValues.push({filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)});
    }

    removeFilterValue(index: number): void {
        this.filterValues.splice(index, 1);
        if (this.filterValues.length === 1) {
            this.filterConditionType = Core.FilterConditionTypeEnum.None;
        }
    }

    trackByFn(index: number) {
        return index;
    }

    setFilterType(filterType: IFilterOptionDef): void {
        this.filterValues[0].filterType = filterType;
    }

    setConditionType(type: Core.FilterConditionTypeEnum): void {
        if (this.filterConditionType === type) {
            this._resetFilter();
            return;
        }
        this.filterConditionType = type;
        if (this.filterValues.length === 1 && (this.filterValues[0].filterValue || this.filterValues[0].filterType.hideFilterInput)) {
            this.addFilterValue();
        }
    }

    protected _resetFilter(): void {
        this.filterConditionType = Core.FilterConditionTypeEnum.None;
        this.filterValues = [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
    }

    protected _formatOption(o: string | IFilterOptionDef): IFilterOptionDef {
        if (typeof o !== 'string') {
            return o;
        }
        const split = o.replace(/([A-Z])/g, ' $1');
        return {
            displayKey: o,
            displayName: split.charAt(0).toUpperCase() + split.slice(1),
            hideFilterInput: false,
            test: this._searchTypeMap.get(o)
        };
    }

    protected _getDefaultFilterType(options: IFilterOptionDef[]): IFilterOptionDef {
        const localParams = this._params as { defaultOption: string };
        return localParams.defaultOption ? options.find(o => o.displayKey === localParams.defaultOption) : options[0];
    }

    private _filterChanged(): void {
        (this._params.api as any).appliedFilter  = _.cloneDeep(this._params.api.getFilterModel());
        this._params.filterChangedCallback();
    }
}
