import { Injectable } from '@angular/core';
import { HubConnectionBuilder, HubConnection, HttpTransportType } from '@microsoft/signalr'
import { TimerService } from '../../UI-Lib/Utilities/timer.service';
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import { AuthStorageService } from 'src/app/User/authStorage.service';
import { AppStateService, AppStates } from 'src/app/Layout/appStateService';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../Settings/settings.service';

export interface ISignalrCallback {
    (data:any):void
}

@Injectable({
    providedIn: 'root'
})
export class SignalRMessageBrokerClientService {
    private _hubConnection: HubConnection | undefined;
    private _hubUrl: string = "";
    private _initialGroup: string | undefined;
    private _callbacks: { [hook: string]: any[] } = {} ;
    private _startedPromise:Promise<void>;
    private _joinedGroups: Array<string> = [];
    private _retryInterval: number = 5000;
    private _appState: AppStates;

    private _connectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    connectionStatus$: Observable<boolean> = this._connectionStatusSubject.asObservable();

    constructor(
        private _timer: TimerService,
        private readonly _http: HttpClient,
        private readonly _authStorageService: AuthStorageService,
        private readonly _appStateService: AppStateService,
        private readonly _settingsService: SettingsService
    ) { }

    public setup(hubUrl: string, initialGroup: string) {
        this._appStateService.appState$.subscribe(s => this._appState = s);
        this._hubUrl = hubUrl;
        this._initialGroup = initialGroup;
        if (this._initialGroup) {
            this._joinedGroups.push(this._initialGroup);
        }

        return this;
    }

    public notifyWhen(messageType: string, containingComponent: any, action: ISignalrCallback) {
        if (!this._hubUrl) {
            throw Error('Hub needs to be setup before callbacks can be added');
        }

        if (!this._hubConnection) {
            this._startedPromise = this.initializeHubConnection(null);
        }

        this._callbacks[messageType] = this._callbacks[messageType] || [];
        this._callbacks[messageType].push(action);

        this.addDestroyHooks(containingComponent, messageType, action);

        return this;
    }

    public setRetryInterval(retryInMilliseconds) {
        this._retryInterval = retryInMilliseconds;
        return this;
    }

    public joinGroup(groupName: string) {
        if (this._startedPromise) {
            this._joinedGroups.push(groupName);
            this._startedPromise.then(() => {
                this._hubConnection.invoke('joinGroup', groupName);
            });
        } else {
            throw Error('Hub needs to be setup before groups can be joined');
        }

        return this;
    }

    public leaveGroup(groupName: string) {
        if (this._startedPromise) {
            this._joinedGroups.push(groupName);
            this._startedPromise.then(() => {
                this._hubConnection.invoke('leaveGroup', groupName);
            });
        } else {
            throw Error('Hub needs to be setup before groups can be left');
        }

        return this;
    }

    private initializeHubConnection(postConnectLogic) {
        this._hubConnection = new HubConnectionBuilder()
            // SignalR has three transport types that it will try in order until it finds one that works:
            // Web Sockets, Server Side Events (SSE), and Long Polling. When we moved to Azure we discovered
            // that the application gateway does not support SSE but the SignalR client isn't automatically
            // making the failover. Some clients appear unable to connect to Web Sockets (probably a firewall
            // setting or something), so SignalR breaks on SSE and stops there. As a temporary workaround,
            // only enable Web Sockets and Long Polling. When we switch to Azure SignalR, SSE should work again.
            // You can demonstrate this behavior locally in FireFox by navigating to about:config and setting
            // network.websocket.max-connections to 0.
            // TODO: Once we've switched to Azure SignalR we need to remove the options object
            .withUrl(`${this._hubUrl}?groupName=${encodeURIComponent(this._initialGroup)}`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
                accessTokenFactory: () => this._authStorageService.getAuthData().weissman2_token
            })
            .build();

        //Dispatch received messages
        this._hubConnection.on("receive", (data) => {
            if (this._callbacks[data.messageType]) {
                for (let c of this._callbacks[data.messageType]) {
                    let jsonBody;
                    try {
                        jsonBody = JSON.parse(data.body);
                    } catch {
                        jsonBody = data.body;
                    }
                    c(jsonBody);
                }
            }
        });

        this._hubConnection.onclose((reason:Error)=>{
            this._callbacks['disconnected'].forEach(x => x());
            this.connectionClosed(reason);
        });

        //Start hub, and place hooks to retry upon failure
        return (async () => {
            if (!this._settingsService.getEnvironmentConfig().signalRUseUnsafeLogin) {
                // Set a login cookie for SignalR. Authorization headers can't be used, so we're falling
                // back to cookies. The documented workaround sends the token in GET parameters, which
                // is not secure. Documentation here:
                // https://learn.microsoft.com/en-us/aspnet/core/signalr/authn-and-authz?view=aspnetcore-7.0
                await lastValueFrom(this._http.post<void>('/api/token/SignalRLogin', {}));
            }

            return this._hubConnection.start().then(()=>{
                this._connectionStatusSubject.next(true);
                this._callbacks['reconnected'].forEach(x => x());
                if (postConnectLogic) {
                    postConnectLogic();
                }
            }).catch(() => {
                this._callbacks['disconnected'].forEach(x => x());
                if (this._retryInterval) {
                    this._timer.setTimeout(() => { return this.retryConnect(postConnectLogic); }, this._retryInterval);
                }
            });
        })();
    }

    private retryConnect(reconnectLogic=null) {
        if (this._appState === AppStates.LoggedIn) {
            return this.initializeHubConnection(reconnectLogic);
        }
    }

    //When the connection gets closed, presumedly from a connectivity or server availability issue, attempt to reconnect
    private connectionClosed(reason:Error) {
        const reconnectLogic = () => {
            this._joinedGroups.forEach((groupName)=> {
                this._hubConnection.invoke('joinGroup', groupName);
            });
            this._callbacks['reconnected'].forEach(x => x());
        }

        const retryPromise = () => { return this.retryConnect(reconnectLogic) };
        if (this._retryInterval) {
            this._timer.setTimeout(() => { retryPromise() }, this._retryInterval);
        }
    }

    private addDestroyHooks(component, message, action) {
        if (!component) return;
        //Stop listening for message when component is removed.
        const origDestroy = component.ngOnDestroy || (() => {});
        component.ngOnDestroy = () => {
            if (this._callbacks[message]) {
                const idx = this._callbacks[message].indexOf(action);
                if (idx >= 0) {
                    this._callbacks[message].splice(idx,1);
                    if (!this._callbacks[message].length) {
                        delete this._callbacks[message];
                        if (!Object.keys(this._callbacks).length) {
                            this._hubConnection.stop().then(()=>{
                                this._hubConnection = undefined;
                            })
                        }
                    }
                }
            }
            origDestroy();
        };
    }
}
