import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BillingScenarioRepository, ParcelCollectorBillScenarioInfo } from '../../../Core-Repositories/billingScenario.repository';

@Injectable()
export class BillingScenarioService {
    constructor(private readonly _billingScenarioRepository: BillingScenarioRepository) {}

    getById(id: number): Promise<any> {
        return lastValueFrom(this._billingScenarioRepository.getById(id));
    }

    getByParcelId(id: number): Promise<ParcelCollectorBillScenarioInfo[]> {
        return lastValueFrom(this._billingScenarioRepository.getByParcelId(id));
    }

    getInUse(id: number): Promise<any> {
        return lastValueFrom(this._billingScenarioRepository.getInUse(id));
    }

    addScenario(scenario: any): Promise<any> {
        return lastValueFrom(this._billingScenarioRepository.addScenario(scenario));
    }

    updateScenario(scenario: any): Promise<any> {
        return lastValueFrom(this._billingScenarioRepository.updateScenario(scenario));
    }

    deleteScenario(id: number): Promise<any> {
        return lastValueFrom(this._billingScenarioRepository.deleteScenario(id));
    }
}
