import { Component } from '@angular/core';
import { FilterUIModel } from "../Filters/filterUI.model";
import { WSFloatingFilterParams } from "./agGridFloatingFilterBase";
import { AgGridTextFilterComponent } from '../Filters/Text/agGridTextFilter.component';
import { IFilterOptionDef } from 'ag-grid-community';

@Component({
    selector: 'yes-no-floating-filter-component',
    template: `
        <select *ngIf="!conditionApplied"
                [ngModel]="value"
                (ngModelChange)="valueChange($event)">
            <option *ngFor="let option of filterOptions" [ngValue]="option">{{option.displayName}}</option>
        </select>
        <div *ngIf="conditionApplied">
            <span>{{ conditionValues }}</span>
        </div>
    `,
    styleUrls: ['./agGridFloatingFilter.scss'],
    host: {'class': 'ag-input-wrapper'}
})
export class AgGridYesNoFloatingFilterComponent {
    constructor() {}

    value: IFilterOptionDef;
    type: string;
    conditionApplied: boolean;
    filterOptions: IFilterOptionDef[] = [];
    conditionValues: string;

    protected _params: WSFloatingFilterParams;

    agInit(params: WSFloatingFilterParams): void {
        this._params = params;
        this.value = params.filterParams['defaultOption'];

        // This property exists but isn't on the model for some reason
        // Bracket notation bypasses the typechecking
        this.filterOptions = params.filterParams['filterOptions'];

        this._params.parentFilterInstance((instance) => this.onParentModelChanged((instance as unknown as AgGridTextFilterComponent).getModel()));
    };

    valueChange(filterType: IFilterOptionDef): void {
        this.value = filterType;
        const type = this.value.displayKey;
        this._params.parentFilterInstance((instance) => (instance as unknown as AgGridTextFilterComponent).onFloatingFilterChanged(type, '', true));
    }

    onParentModelChanged(parentModel: FilterUIModel): void {
        this.value = null;
        this.type = this._params.filterParams['defaultOption'];
        if (parentModel && parentModel.filterConditionType) {
            this.conditionValues = parentModel.filterValues.map(x => `${x.filterType.displayName}`)
                                    .join(parentModel.filterConditionType === Core.FilterConditionTypeEnum.And ? ' AND ' : ' OR ');
            this.conditionApplied = true;
        } else {
            this.value = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterType;
            this.type = parentModel && parentModel.filterValues[0] && parentModel.filterValues[0].filterType.displayKey;
            this.conditionApplied = false;
        }
    };

}
