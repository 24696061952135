import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidatorService {
    private guidRegexp = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

    isGuid(value: string) : boolean {
        return this.guidRegexp.test(value);
    }
}
