<div class="return-part-content-area ws-flex-container-vertical ws-stretch">
    <div class="ws-section ws-flex-none ws-flex-container-vertical" *ngIf="showGeneralReturnSettings">
        <return-settings-general></return-settings-general>
    </div>

    <div class="ws-section ws-flex-none ws-flex-container-vertical">
        <return-settings-override></return-settings-override>
    </div>

    <div class="ws-section ws-flex-auto ws-flex-container-vertical">
        <return-settings-report-list></return-settings-report-list>
    </div>
</div>
