import { Component, OnInit } from '@angular/core';
import { AuthStorageService, StorageTokenKeys } from '../User/authStorage.service';
import { NavigationService } from './navigation.service';
import { AppStateService, AppStates } from './appStateService';
import { filter } from 'rxjs/operators';
import { AceAuthOidcWrapperService } from '../IAM/aceAuthOidcWrapper.service';
import { RouteService } from '../Common/Routing/route.service';

@Component({
    selector: 'logout-message',
    template: `
<div class="col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 login-panel">
    <div *ngIf="loggingOut">
        <p>Logging out...</p>
    </div>
    <div *ngIf="!loggingOut">
        <p>{{logoutMessage}}</p>
        <span *ngIf="!authStorageService.setNoRelogin && !authStorageService.showIAMLogoutButton" class="anchor-style" (click)="loginClick()">Click here to log back in</span>
        <span *ngIf="authStorageService.showIAMLogoutButton" class="anchor-style" (click)="iamLogoutClick()">Log out and try again</span>
    </div>
</div>`
})
export class LogoutMessageComponent implements OnInit {
    logoutMessage: string;
    loggingOut: boolean;
    constructor(
        public authStorageService: AuthStorageService,
        private _appStateService: AppStateService,
        private _navigationService: NavigationService,
        private _aceAuthOidcWrapperService: AceAuthOidcWrapperService,
        private _routeService: RouteService
    ) { }

    ngOnInit() {
        this.loggingOut = false;
        this._appStateService.appState$.pipe(filter(x => x === AppStates.LoggingOut)).subscribe(() => {
            this.loggingOut = true;
        });
        this.authStorageService.logoutMessage$.subscribe(message => {
            if (message) {
                this.loggingOut = false;
                // Remove all modals (note that they won't work again until page refresh)
                const modals = document.getElementsByClassName('modal');
                for (let i = 0; i < modals.length; i++) {
                    modals[i].remove();
                }
                const backdrops = document.getElementsByClassName('modal-backdrop');
                for (let i = 0; i < backdrops.length; i++) {
                    backdrops[i].remove();
                }
                document.getElementsByTagName('body')[0].classList.remove('modal-open');
                // Disable navigation
                this._navigationService.disableNavWarning();
            }
            this.logoutMessage = message;
            // WK-7628 We originally had the UI clear stored refresh token data any time it detected that the user
            // had been forcibly logged out, but actually that's not what we want. When the user refreshes the page,
            // if they really have invalid login data it'll detect that case and show the login screen. However, it's
            // equally possible that between when this current session was terminated and the time the user clicks the
            // log back in button, they've re-authenticated, so don't clear that auth data here.
            //this.authStorageService.clearAuthData();
        });
    }

    async loginClick() {
        if (this.authStorageService.resetPathOnLogout) {
            window.location.hash = '';
        }

        if (localStorage[StorageTokenKeys.idleTimeoutStatus]) {
            delete localStorage[StorageTokenKeys.idleTimeoutStatus];
            this._routeService.setInitialRoute(window.location.href);
            await this._aceAuthOidcWrapperService.logout();
            return;
        }

        // Apparently force reload is deprecated? And maybe Edge does not clear the cache in this case any more?
        // If that's true it's immensely stupid. For now just force TypeScript to complie this, but perhaps we
        // need to investigate yet more ridiculous hacks to work around broken browser caching behavior.
        (<any>window.location).reload(true);
    }

    async iamLogoutClick() {
        await this._aceAuthOidcWrapperService.logout();
    }
}
