import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { DraggableItemService } from 'ngx-bootstrap/sortable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TransmittalModule } from '../Processing/Transmittal/transmittal.module';
import { SharedPipesModule } from '../UI-Lib/Pipes/shared-pipes.module';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AnnualDetailsNavigationEventService } from './annual-details-event.service';
import { AnnualDetailsNavigationService } from './annual-details-navigation.service';
import { AnnualDetailsComponent } from './annual-details.component';
import { AnnualDetailsService } from './annual-details.service';
import { AnnualYearComponent } from './Annual-Year/annual-year.component';
import { AppealComponent } from './Appeals/appeal.component';
import { AppealService } from './Appeals/appeal.service';
import { FmvInputComponent } from './Assessments/assessment-input.component';
import { AssessmentComponent } from './Assessments/assessment.component';
import { AssessmentService } from './Assessments/assessment.service';
import { ComplianceComponent } from './Compliance/compliance.component';
import { ComplianceReturnAddComponent } from './Compliance/Return-Add/return-add.component';
import { ComplianceService } from './Compliance/compliance.service';
import { AnnualDetailsGridComponent } from './Grid/annual-details-grid.component';
import { AddYearModal } from './Modals/add-annual-year.component';
import { AddAppealModal } from './Modals/add-appeal.component';
import { AddFilingModal } from './Modals/add-filing.component';
import { AddRevisionModal } from './Modals/add-revision.component';
import { PaymentPackagesModal } from './Modals/payment-package-modal.component';
import { PaymentStubModal } from './Modals/payment-stub.component';
import { ReorderRevisionsModal } from './Modals/reorder-revisions.component';
import { AssessmentComponentExemptionPipe } from './Pipes/assessment-component-exemption.pipe';
import { BillClusterService } from './Taxes/bill-cluster.service';
import { BillsComponent } from './Taxes/bills.component';
import { RefundComponent } from './Taxes/refund.component';
import { TaxesComponent } from './Taxes/taxes.component';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { AnnualBudgetService } from './Budget/budget.details.service';
import { AnnualBudgetComponent } from './Budget/budget.details.component';
import { AccrualDetailModalComponent } from './Modals/accrual-detail.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BsDropdownModule,
        TabsModule,
        ModalModule,
        SortableModule,
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        CurrencyMaskModule,
        TransmittalModule,
        BusyIndicatorModule.forRoot()
    ],
    declarations: [
        AnnualDetailsComponent,
        AnnualDetailsGridComponent,
        AppealComponent,
        TaxesComponent,
        AnnualYearComponent,
        AssessmentComponent,
        FmvInputComponent,
        AddRevisionModal,
        AddYearModal,
        ReorderRevisionsModal,
        AddAppealModal,
        AddFilingModal,
        AssessmentComponentExemptionPipe,
        BillsComponent,
        RefundComponent,
        ComplianceComponent,
        ComplianceReturnAddComponent,
        PaymentStubModal,
        PaymentPackagesModal,
        AnnualBudgetComponent,
        AccrualDetailModalComponent
    ],
    providers: [
        AnnualDetailsService,
        AnnualDetailsNavigationService,
        AnnualDetailsNavigationEventService,
        AssessmentService,
        AppealService,
        BillClusterService,
        ComplianceService,
        DecimalPipe,
        DraggableItemService,
        AnnualBudgetService,
        AssessmentComponentExemptionPipe
    ],
    //Needed for other modules that reference these components (e.g. entity edit)
    exports: [
        AppealComponent,
        TaxesComponent,
        AnnualYearComponent,
        AssessmentComponent,
        BillsComponent,
        RefundComponent,
        ComplianceComponent,
        ComplianceReturnAddComponent
    ]
})
export class AnnualDetailsModule {
    static setupModule(): void {
        //We only need to downgrade the entry components
        //Entry components must have only two words, for example: annualDetails not annualDetailsComponent
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('annualDetails', AnnualDetailsComponent);
        hybridAdapterUtility.downgradeNg2Provider('billClusterService', BillClusterService);
    }
}
