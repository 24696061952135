import {of as observableOf, Observable} from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import { mergeMap } from 'rxjs/operators';

interface AssessorSelectorComponentChanges extends SimpleChanges {
    assessorValue: SimpleChange
}

@Component({
    templateUrl: './taxingDistrictSelector.component.html',
    selector: 'taxing-district-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TaxingDistrictSelectorComponent,
        multi: true
    }]
})
export class TaxingDistrictSelectorComponent implements ControlValueAccessor {

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() values: Core.TaxingDistrictModel[] = [];
    @Input() isDisabled: boolean = false;
    @Input() isOverridden: boolean = false;

    @Output() changed = new EventEmitter<string>();

    value: string;
    filter: string = '';

    typeaheadValues: Observable<Core.TaxingDistrictModel[]> = (Observable
        .create((observer: any) => { observer.next(this.filter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filter(token)));

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(value: string): void {
        this.value = value;
        this.filter = value;
    }

    onBlur(): void {
        if (this.filter === '') {
            this.changed.emit(null);
        }

        this._select(this.value);
        this._onTouchedFn && this._onTouchedFn();
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const taxingDistrict = match.item as Core.TaxingDistrictModel;

            const allMatches = this.values.filter(i => this._isMatch(i, taxingDistrict.code));
            let code = taxingDistrict.code;

            if (allMatches.length > 1) {
                code = `${taxingDistrict.code}|${taxingDistrict.townshipCode}`;
            }

            this._select(code);
            this.changed.emit(code);
        }
    }

    private _filter(filter: string): Observable<Core.TaxingDistrictModel[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }

        const filtered = (this.values || []).filter(i => this._isMatch(i, filter));

        return observableOf(
            filtered.sort((a, b) => a.displayName.localeCompare(b.displayName))
        );
    }

    private _select(value: string): void {
        const taxingDistricts = this.values && this.values.filter(a => this._isMatch(a, value));

        if (taxingDistricts && taxingDistricts.length === 1) {
            this.filter = value;
        } else {
            this.filter = null;
        }
    }

    private _isMatch(taxingDistrict: Core.TaxingDistrictModel, selectedValue: string): boolean {
        const value = (selectedValue || '').toLocaleLowerCase();
        const localTaxingDistrict = {
            taxingDistrictId: taxingDistrict.taxingDistrictId,
            code: taxingDistrict.code.toLocaleLowerCase(),
            name: taxingDistrict.name.toLocaleLowerCase(),
            townshipCode: taxingDistrict.townshipCode.toLocaleLowerCase(),
            townshipName: taxingDistrict.townshipName.toLocaleLowerCase(),
        } as Core.TaxingDistrictModel;

        let result = value === '';

        if (!result) {
            const items = value.split('|');
            result = (localTaxingDistrict.name.indexOf(items[0]) >= 0 || localTaxingDistrict.code.indexOf(items[0]) >= 0) &&
                (items.length === 1 || localTaxingDistrict.townshipName.indexOf(items[1]) >= 0 || localTaxingDistrict.townshipCode.indexOf(items[1]) >= 0);
        }

        return result;
    }
}
