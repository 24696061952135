import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AnnualDetailsService } from '../annual-details.service';

declare const _: any;

export interface IReorderRevisionsConfig {
    annualYearID: number;
    year: number;
    assessments: {
        id: number;
        description: string;
        sequence: number;
    }[]
}

@Component({
    selector: 'reorder-revisions-modal',
    templateUrl: './reorder-revisions.component.html'
})
export class ReorderRevisionsModal implements OnDestroy {
    constructor(
        public modalRef: BsModalRef,
        private _annualDetailsService: AnnualDetailsService
    ) { }

    assessmentsReorderedEventEmitter: EventEmitter<void> = new EventEmitter();

    config: IReorderRevisionsConfig;

    isBusy: boolean = false;

    ngOnDestroy(): void {
        this.assessmentsReorderedEventEmitter.complete();
    }

    initialize(config: IReorderRevisionsConfig): void {
        // the sortable compoenent reorganizes items in the array based on their index as the user drag-and-drops
        // sorting revisions so that the first revision matches the first index in the array
        config.assessments = _.orderBy(config.assessments, ['sequence'], ['asc']);
        this.config = config;
    }

    save(): void {
        this.isBusy = true;

        let orderedAssessmentIDs = this.config.assessments.map(i => i.id);

        this._annualDetailsService.orderRevisions(this.config.annualYearID, orderedAssessmentIDs).then(() => {
            this.isBusy = false;
            this.assessmentsReorderedEventEmitter.emit();
            this.modalRef.hide();
        }, () => {
            this.isBusy = false;
        })
    }
}