<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="title"
                  [hideCancel]="true"
                  [suppressKeyboardEvents]="true"
                  (save)="cancel()"
                  (cancel)="cancel()"
                  acceptLabel="Close">
    <ag-grid-row-count [gridOptions]="gridOptions" [gridTracker]="gridTracker" [prefix]="null" [suffix]="null"></ag-grid-row-count>
    <ws-ag-grid-angular wrapperClass="app-ui-view"
                        [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [exportOptions]="exportOptions"
                        [gridId]="gridId"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        (bulkUpdate)="bulkUpdate()">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
