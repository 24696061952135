import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ws-bulk-update-action',
    template: `
        <ws-select valueProperty="value"
                   labelProperty="name"
                   [formControl]="selectedAction"
                   [options]="possibleActions">
        </ws-select>
    `,
    styles: ['select {width:120px}']
})
export class BulkUpdateActionComponent implements OnInit, OnDestroy {

    @Input() fieldAction: Compliance.AssessorCommandCenterBulkUpdateActionEnum;

    @Input() requiredField: boolean;
    @Input()
    set disabled(disabled: boolean) {
        if (disabled) {
            this.selectedAction.disable();
        } else {
            this.selectedAction.enable();
        }
    };

    @Input()
     set hideField(hiddenField : Compliance.AssessorCommandCenterBulkUpdateActionEnum) {
        if(hiddenField) {
            this.possibleActions.forEach( (item, index) => {
                if(item.value === hiddenField) {
                    this.possibleActions.splice(index,1);
                } 
              });
        }
    }

    @Output() fieldActionChange = new EventEmitter<Compliance.AssessorCommandCenterBulkUpdateActionEnum>();

    possibleActions: Compliance.NameValuePair<Compliance.AssessorCommandCenterBulkUpdateActionEnum>[] = [
        { name: 'No Change', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange },
        { name: 'Change To', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.ChangeTo },
        { name: 'Remove', value: Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove }
    ];
    selectedAction: UntypedFormControl = new UntypedFormControl(Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange);
    private _formSub: Subscription;

    ngOnInit(): void {
        this._formSub = this.selectedAction.valueChanges
            .subscribe((action: Compliance.AssessorCommandCenterBulkUpdateActionEnum) => this.fieldActionChange.next(action));
    }

    ngOnDestroy(): void {
        this._formSub.unsubscribe();
    }

    fieldActionChanged(action): void {
        this.selectedAction = action;
        this.fieldActionChange.next(action);
    }

    isSelected(action: Compliance.AssessorCommandCenterBulkUpdateActionEnum): boolean {
        return action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange;
    }
}
