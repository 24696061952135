import { Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { UtilitiesService } from '../../UI-Lib/Utilities';
import { UserService } from '../user.service';


@Component({
    selector: 'change-password',
    templateUrl: './changePassword.component.html'
})
export class ChangePasswordComponent {
    constructor(private readonly _router: UIRouter,
                private readonly _userService: UserService,
                private readonly _utils: UtilitiesService) {
    }

    alreadyUsed: string = '';
    passwordIncorrect: boolean;
    passwordsValid: boolean;
    password = {
        current: '',
        'new': '',
        confirm: ''
    };
    disableSave = false;

    async changePassword(): Promise<void> {
        this.disableSave = true;
        this.passwordIncorrect = false;
        this.alreadyUsed = '';
        try {
            await this._userService.changePassword(this.password.current, this.password.new);
            this._router.stateService.go('logout');
        } catch(error) {
                console.log('changePassword - error', error);
                if (error.status === 400) {
                    const message = error.data.modelState[''][0];
                    switch (message) {
                        case 'Could not validate user.':
                            this.passwordIncorrect = true;
                            this._utils.focusOnElement('[name=current]', 300);
                            break;
                        case 'Password must contain at least one numeral and one letter.':
                            this._setNewValidation();
                            console.log('changePassword - error', message);
                            break;
                        case 'Passwords cannot have been used within last 3 password changes.':
                            this.alreadyUsed = this.password.new;
                            this._setNewValidation();
                            break;
                        default:
                            break;
                    }
                }
            } finally {
                this.disableSave = false;
            }
    }

    goToHome(): void {
        this._router.stateService.go('home');
    }

    private _setNewValidation(): void {
        this._utils.focusOnElement('[name=new]', 100);
        this.password.confirm = '';
    }
}
