<div class="cost-adjustment-display">
    <div>
        <button helpTooltip
                helpContentId="asset-info.add-cost-adjustment"
                *ngIf="!costAdjustmentControlsVisible && (extendedCostAdjustmentTypes && extendedCostAdjustmentTypes.length > 0)"
                class="cost-adjustment-button btn small-button flat-button"
                (click)="showCostAdjustments()">
            <i class="fa fa-plus"></i> Cost Adjustments
        </button>
    </div>
    <table *ngIf="costAdjustmentControlsVisible && (extendedCostAdjustmentTypes && extendedCostAdjustmentTypes.length > 0)" class="cost-adjustment-table">
        <!-- <tr>
            <td>Unadjusted Value</td>
            <td></td>
            <td class="calculated-cost">{{ _getCostFieldSourceValue() }}</td>
        </tr> -->
        <tr *ngFor="let ca of extendedCostAdjustmentTypes">
            <td>{{ ca.typeName }}</td>
            <td>
                <div *ngIf="costAdjustments[ca.costAdjustmentTypeId]">
                    <div class="adjustment-input-holder">
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input type="number"
                                   [name]="'costAdjustmentAmount' + [ca.costAdjustmentTypeId]"
                                   class="form-control"
                                   [ngClass]="'costAdjustmentAmount' + [ca.costAdjustmentTypeId]"
                                   [(ngModel)]="costAdjustments[ca.costAdjustmentTypeId].adjustmentAmount"
                                   [disabled]="isCostInputDisabled([ca.costAdjustmentTypeId], false)"
                                   (change)="calculateCosts()">
                        </div>
                    </div>
                    <div class="adjustment-input-holder">
                        <div class="input-group">
                            <div class="input-group-text">%</div>
                            <input type="number"
                                   [name]="'costAdjustmentPercent' + [ca.costAdjustmentTypeId]"
                                   class="form-control"
                                   [ngClass]="'costAdjustmentPercent' + [ca.costAdjustmentTypeId]"
                                   [(ngModel)]="costAdjustments[ca.costAdjustmentTypeId].adjustmentPercentage"
                                   [disabled]="isCostInputDisabled([ca.costAdjustmentTypeId], true)"
                                   (change)="calculateCosts()">
                        </div>
                    </div>
                </div>
            </td>
            <td class="calculated-cost">{{ costAdjustmentEmpty(ca) ? '' : '(' + costAdjustmentCalculations[ca.costAdjustmentTypeId] + ')'}}</td>
        </tr>
        <tr>
            <td colspan="2" helpTooltip
                helpContentId="asset-info.adjusted-source-cost">
                Adj. Source Cost
            </td>
            <td class="calculated-cost-total">{{ calculatedCost }}</td>
        </tr>
    </table>
</div>
