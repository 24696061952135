import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ExtendedGLAccountModel } from '../../../../../Asset/Asset-Details/Asset-Info/extendedGLAccountModel';
import { HigherOrderFn, Predicate, TypeaheadSelectorComponent } from '../Selectors/Typeahead-Selector/typeaheadSelector.component';
import { WeissmanKeyValueDisplayPipe } from '../../../../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';

export interface EntityImportEditorGLAccountSelectorCellEditorParams extends ICellEditorParams {
    getGLAccounts(rowIndex: number): Compliance.GLAccountModel[];
}

@Component({
    selector: 'entity-import-editor-glaccount-selector',
    template: `
       <typeahead-selector #typeaheadSelector
                           labelProperty="displayName"
                           valueProperty="model.accountNumber"
                           placeholder="Filter GL Accounts..."
                           [formControl]="glAccount"
                           [canReset]="true"
                           [originalValue]="originalValue"
                           [filterFn]="typeaheadFilter"
                           (changed)="onChanged($event)"
                           [values]="values"></typeahead-selector>`
})
export class EntityImportEditorGLAccountSelectorCellEditorComponent implements ICellEditorAngularComp {
    constructor(private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe) { }

    @ViewChild("typeaheadSelector", { static: true }) typeaheadSelector: TypeaheadSelectorComponent;

    gridRow: Compliance.ImportGridRowGridModel;
    glAccount: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    values: ExtendedGLAccountModel[];
    originalValue: string;
    typeaheadFilter: HigherOrderFn<Predicate> = (filter: string) => (x: ExtendedGLAccountModel) => filter === '' ||
        x.displayName.toLowerCase().includes(filter.toLowerCase());

    private _params: EntityImportEditorGLAccountSelectorCellEditorParams;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.glAccount.value;
    }

    isCancelAfterEnd(): boolean {
        if (this.typeaheadSelector.value && !this.typeaheadSelector.filter) {
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    async agInit(params: EntityImportEditorGLAccountSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;

        if (this._params.data && this._params.data.originalValues) {
            this.originalValue = this._params.data.originalValues[this._params.colDef.field];
        }

        this.glAccount.setValue(params.value);

        const glAccounts = await params.getGLAccounts(this.gridRow.rowIndex);
        this.values = glAccounts.map(x => new ExtendedGLAccountModel(x, this._keyValueDisplayPipe));

        if (this.values && this.values.length) {
            this.glAccount.enable();
        }
    }

    onChanged(value?: string) {
        if (this.glAccount.value !== value) {
            this.glAccount.setValue(value);
            this._params.node.setDataValue(this._params.column.getColId(), value);
        }
    }
}
