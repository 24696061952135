import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AgGridModule } from 'ag-grid-angular';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { FactorTableModule } from '../Factor-Table/factorTable.module';
import {
    AssessorAddressTooltipComponent
} from './Form-Assessor-List/Assessor-Address-Tooltip/assessorAddressTooltip.component';
import { FormPageComponent } from './Form-Page/formPage.component';
import { FormListComponent } from './Form-List/formList.component';
import { FormScheduleListComponent } from './Form-Schedule-List/formScheduleList.component';
import { FormCommentsComponent } from './Form-Comments/formComments.component';
import { FormAttachmentsComponent } from './Form-Attachments/formAttachments.component';
import { FormDetailsComponent } from './Form-Details/formDetails.component';
import { FormYearListComponent } from './Form-Year-List/formYearList.component';
import { FormAssessorAddComponent } from './Form-Assessor-Add/formAssessorAdd.component';
import { FormAssessorListComponent } from './Form-Assessor-List/formAssessorList.component';
import { FormAssessorsPanelGridActionCellRendererComponent } from './Form-Assessor-List/agGridActionCellRenderer.component';
import { FormService } from './form.service';
import { FormFactorTableListComponent } from './Form-Factor-Table-List/formFactorTableList.component';
import { FormFactorTableAddComponent} from './Form-Factor-Table-Add/formFactorTableAdd.component';
import { FormAssetClassMappingComponent } from './Form-Asset-Class-Mapping/formAssetClassMapping.component';
import { DropdownCellRenderer} from './Form-Asset-Class-Mapping/agGridDropdownCellRenderer.component';
import { FormFieldListComponent } from './Form-Field-List/formFieldList.component';
import { FormFieldsComponent } from './Form-Fields/formFields.component';
import { FormTemplateComponent } from './Form-Template/formTemplate.component';
import { FormTemplateUploadComponent } from './Form-Template-Upload/formTemplateUpload.component';
import { FormTemplateEditComponent } from './Form-Template-Edit/formTemplateEdit.component';
import { FormScheduleListGridCheckboxCellRendererComponent } from './Form-Schedule-List/agGridCheckboxCellRender.component';
import { FormScheduleListGridActionCellRendererComponent } from './Form-Schedule-List/agGridActionCellRenderer.component';
import { FormScheduleListGridFactorTableDropdownCellRendererComponent } from './Form-Schedule-List/agGridFactorTableDropdownCellRenderer.component';
import { FormScheduleListGridInputCellRendererComponent } from './Form-Schedule-List/agGridInputCellRender.component';
import { FormScheduleListGridReportDropdownCellRendererComponent } from './Form-Schedule-List/agGridReportDropdownCellRenderer.component';
import { FormFactorTableListGridActionCellRendererComponent } from './Form-Factor-Table-List/agGridActionCellRenderer.component';
import { FormReportListGridActionCellRendererComponent } from './Form-Report-List/agGridActionCellRenderer.component';
import { FormReportListComponent } from './Form-Report-List/formReportList.component';
import { FormReportListCheckboxCellRendererComponent } from './Form-Report-List/agGridCheckboxCellRender.component';
import { FormReportAddComponent } from './Form-Report-Add/formReportAdd.component';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { FormAppealLevelListComponent } from './Form-Appeal-Level-List/formAppealLevelList.component';
import { FormYearNewComponent } from './Form-Year-New/formYearNew.component';
import { FormYearCopyComponent } from './Form-Year-Copy/formYearCopy.component';
import { FormYearCopyListComponent } from './Form-Year-Copy-List/formYearCopyList.component';
import { FormYearDeleteComponent } from './Form-Year-Delete/formYearDelete.component';
import { FormAssessorListAddressCellRendererComponent } from './Form-Assessor-List/agGridAddressCellRenderer.component';
import { ReportingModule } from '../../Reporting/reporting.module';
import { FormSetupUploadComponent } from './Form-Setup-Upload/formSetupUpload.component';
import { FormAssessorSelectComponent } from './Form-Assessor-Select/formAssessorSelect.component';
import { FormYearNewModalComponent } from './Form-Year-New-Modal/formYearNewModal.component';
import { FormAssessorAddressPickerComponent } from './Form-Assessor-Address-Picker/formAssessorAddressPicker.component';
import { FormAssetClassMappingListGridActionCellRendererComponent } from './Form-Asset-Class-Mapping/agGridActionCellRenderer.component';
import { FormSwitchesComponent } from './Form-Switches/formSwitches.component';
import { FormSetupDownloadComponent } from './Form-Setup-Download/formSetupDownload.component';
import { FormTemplateValidationComponent } from './Form-Template-Validation/formTemplateValidation.component';
import { FormFilingBatchesComponent } from './Form-FilingBatches/formFilingBatches.component';
import { FormFilingBatchListComponent } from './Form-Filing-Batch-List/formFilingBatchList.component';
import { ExportFormListComponent } from './Export-Form-List/exportFormList.component';
import {FormLibraryExportComponent} from './Form-Library-Export/formLibraryExport.component';
import {
    AssessorCertifiedTooltipComponent
} from './Form-Assessor-List/Assessor-Certified-Tooltip/assessorCertifiedTooltip.component';
import {
    ExpandCellHeaderRendererComponent
} from '../../Common/AgGrid/agGridExpandCellHeaderRenderer.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        SharedPipesModule,
        BsDropdownModule,
        TabsModule,
        ModalModule,
        TypeaheadModule,
        SortableModule,
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        CurrencyMaskModule,
        ReportingModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            FormAssessorsPanelGridActionCellRendererComponent,
            ExpandCellHeaderRendererComponent,
            FormAssetClassMappingListGridActionCellRendererComponent,
            FormScheduleListGridCheckboxCellRendererComponent,
            FormScheduleListGridActionCellRendererComponent,
            FormScheduleListGridFactorTableDropdownCellRendererComponent,
            FormScheduleListGridInputCellRendererComponent,
            FormScheduleListGridReportDropdownCellRendererComponent,
            FormFactorTableListGridActionCellRendererComponent,
            FormReportListGridActionCellRendererComponent,
            FormReportListCheckboxCellRendererComponent,
            FormAssessorListAddressCellRendererComponent
        ]),
        FactorTableModule
    ],
    declarations: [
        FormPageComponent,
        FormScheduleListComponent,
        FormPageComponent,
        FormListComponent,
        FormDetailsComponent,
        FormYearListComponent,
        FormYearNewComponent,
        FormYearNewModalComponent,
        FormYearCopyComponent,
        FormYearCopyListComponent,
        FormYearDeleteComponent,
        FormAssessorAddComponent,
        FormAssessorListComponent,
        FormAssessorsPanelGridActionCellRendererComponent,
        FormCommentsComponent,
        FormAttachmentsComponent,
        FormDetailsComponent,
        FormFactorTableListComponent,
        FormFactorTableAddComponent,
        FormAssetClassMappingComponent,
        FormAssetClassMappingListGridActionCellRendererComponent,
        DropdownCellRenderer,
        FormFieldListComponent,
        FormFieldsComponent,
        FormTemplateComponent,
        FormTemplateUploadComponent,
        FormTemplateEditComponent,
        FormTemplateValidationComponent,
        FormScheduleListGridCheckboxCellRendererComponent,
        FormScheduleListGridActionCellRendererComponent,
        FormScheduleListGridFactorTableDropdownCellRendererComponent,
        FormScheduleListGridInputCellRendererComponent,
        FormScheduleListGridReportDropdownCellRendererComponent,
        FormFactorTableListGridActionCellRendererComponent,
        FormReportListComponent,
        FormReportListGridActionCellRendererComponent,
        FormReportListCheckboxCellRendererComponent,
        FormReportAddComponent,
        FormSwitchesComponent,
        FormAppealLevelListComponent,
        FormAssessorListAddressCellRendererComponent,
        FormSetupUploadComponent,
        FormAssessorSelectComponent,
        FormAssessorAddressPickerComponent,
        FormSetupDownloadComponent,
        FormFilingBatchesComponent,
        FormFilingBatchListComponent,
        ExportFormListComponent,
        FormLibraryExportComponent,
        AssessorCertifiedTooltipComponent,
        AssessorAddressTooltipComponent
    ],
    providers: [
        FormService
    ],
    exports: []
})
export class FormModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('formPage', FormPageComponent);
        hybridAdapterUtility.downgradeNg2Component('formList', FormListComponent);
        hybridAdapterUtility.downgradeNg2Component('formAttachments', FormAttachmentsComponent);
        hybridAdapterUtility.downgradeNg2Component('formComments', FormCommentsComponent);
    }
}
