<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Form Library"
                  [suppressKeyboardEvents]="true"
                  (cancel)="cancel()"
                  cancelLabel="Close"
                  [hideSave]="true">
    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="''" [suffix]="''"></ag-grid-row-count>
    <div class="app-ui-view w-100">
        <ws-ag-grid-angular
            [gridId]="gridId"
            [gridOptions]="gridOptions"
            (gridReady)="onAgGridReady($event)"
            [exportOptions]="exportOptions"
            exportIcon="fa-file-export">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
