export abstract class DocumentServiceUpgrade {
    abstract sendBatchDocuments(options, files);

    abstract getDocumentBody(id: any): Promise<any>;

    abstract getDocumentInfo(id): Promise<any>;

    abstract getDocumentInfoByTaskId(id): Promise<any>;

    abstract getTaskInfo(id): Promise<any>;

    abstract getTaskInfoByTaskID(id): Promise<any>;

    abstract saveFile(blob, fileName): Promise<any>;

    abstract mapDocumentToEntity(id, data): Promise<any>;

    abstract put(data): Promise<any>;
}
