import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridMultiSelectTracker } from '../../../AgGrid/MultiSelectTracker'
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllocationDetailDragAndDropService } from './allocationDetailDragAndDrop.service';

export interface ICellRendererParamsForAllocationDetailDragAndDropSource extends ICellRendererParams {
    tracker: AgGridMultiSelectTracker
}

@Component({
    selector: 'allocation-detail-drag-and-drop-source',
    template: `
                <div
                    *ngIf="params.data && isRowSelected() && isServiceActive && !(params.data.totalsRow || params.data.selectedTotalsRow)"
                    title="Drag and Drop assets on the desired parcel."
                    [attr.dragula-bag-name]="dragulaBagName" [attr.dragula-container-type]="dragulaContainerType"
                    [dragula]="dragulaBagName">
                    <i class="fa fa-bars drag-source-element"></i>
                </div>
              `
})
export class AllocationDetailDragAndDropSourceComponent implements ICellRendererAngularComp {
    constructor(
        private readonly _allocationDetailDragAndDropService: AllocationDetailDragAndDropService
    ) {
        this.dragulaContainerType = this._allocationDetailDragAndDropService.CONTAINER_TYPE_SOURCE;
        this.dragulaBagName = this._allocationDetailDragAndDropService.BAG_NAME;
    }

    private _tracker: AgGridMultiSelectTracker;
    private _rowId: number;
    private _destroy$: Subject<void> = new Subject();

    isServiceActive: boolean;

    params: ICellRendererParamsForAllocationDetailDragAndDropSource;

    dragulaContainerType: string;
    dragulaBagName: string;

    agInit(params: ICellRendererParamsForAllocationDetailDragAndDropSource): void {
        this.params = params;
        this._tracker = params.tracker;
        this._rowId = params.value;

        this._allocationDetailDragAndDropService.active$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isServiceActive = x);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    isRowSelected(): boolean {
        return this._tracker.isRowSelected(this._rowId);
    }
}
