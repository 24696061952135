import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class EntityPropertyCharacteristicsService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get(entityId: number, entityName: string): Promise<Core.EntityDescriptorModel[]> {
        return lastValueFrom(this.httpGet(`api/${entityName}characteristic/${entityName}/${entityId}`));
    }

    searchAvailable(entityId: number, entityName: string, searchModel: Core.EntityDescriptorSearchModel): Observable<Core.DescriptorDTO[]> {
        return this.httpPost(`api/${entityName}characteristic/${entityName}/${entityId}/available`, searchModel);
    }

    update(entityId: number, entityName: string, descriptors: Core.EntityDescriptorModel[]): Promise<Core.EntityDescriptorModel[]> {
        return lastValueFrom(this.httpPut(`api/${entityName}characteristic/${entityName}/${entityId}`, descriptors));
    }

}
