<ws-modal-wrapper headerLabel="Form Fields"
                  cancelHelpContentId="app.cancel"
                  [suppressKeyboardEvents]="true"
                  [hideSave]="true"
                  cancelClass="primary-button"
                  (cancel)="cancel()"
                  cancelLabel="Close"
                  cancelHelpContentId="app.close">
    <div class="app-ui-view d-flex flex-column w-100">
        <div class="d-flex align-items-center justify-content-between">
            <ag-grid-row-count [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
            <button title="Export"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    (click)="export()"
                    *ngIf="canExport"
                    [disabled]="isBusy">
                <i class="fa fa-file-excel-o fa-align"></i>
            </button>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
