<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ng-template #lienDate let-field="field" let-classRef="classRef">
    <asset-lien-date [companyId]="params.companyId" [showFilters]="false" (dateChange)="classRef.onEffectiveDateChange($event, field)"></asset-lien-date>
</ng-template>

<ng-template #select let-field="field" let-classRef="classRef">
    <select class="form-select form-select-sm" [(ngModel)]="fileFieldsSelectedValues[field.importFieldId]" (ngModelChange)="classRef.selectedOptionChanged()">
        <option *ngFor="let option of field.selectOptions" [ngValue]="option.value">{{option.name}}</option>
    </select>
</ng-template>

<ng-template #date let-field="field" let-classRef="classRef">
    <weissman-datetime [(inputDate)]="fileFieldsSelectedValues[field.importFieldId]" [dateOnly]="true"></weissman-datetime>
</ng-template>

<ws-modal-wrapper headerLabel="New Data Import"
                  acceptLabel="Upload"
                  (save)="uploadFile()"
                  (cancel)="cancel()"
                  [disableSave]="!isReadyForUpload">
    <form name="importUploadForm" style="max-height: 715px;">

        <div class="mb-3 import-file-info-question clearfix">
            <label for="selectedFileSpecification">Apply existing file import specification?</label>
            <div class="radio clearfix" style="margin-top: 0;">
                <div class="col-lg-12">
                    <label>
                        <input type="radio" name="fileSpecificationRadioGroup" value="no" [(ngModel)]="useFileSpecification" (ngModelChange)="useFileSpecificationChanged()"> No
                    </label>
                </div>
            </div>
            <div class="radio clearfix">
                <div class="col-lg-2">
                    <label>
                        <input type="radio" name="fileSpecificationRadioGroup" value="yes" [(ngModel)]="useFileSpecification" (ngModelChange)="useFileSpecificationChanged()"> Yes
                    </label>
                </div>
                <div class="col-lg-4">
                    <select name="viewType" class="form-select form-select-sm" *ngIf="useFileSpecification==='yes'" [(ngModel)]="viewType" (ngModelChange)="getSpecifications()">
                        <option value="" disabled selected>View Type</option>
                        <option value="private">Private</option>
                        <option value="company" *ngIf="params.companyId">Company</option>
                        <option value="shared">Shared</option> <!--Check if system user-->
                    </select>
                </div>
                <div class="col-lg-6">
                    <select name="specificationName" class="form-select form-select-sm" *ngIf="useFileSpecification==='yes'" [(ngModel)]="selectedFileSpecification" (change)="specChosen()">
                        <option *ngFor="let spec of fileSpecifications" [ngValue]="spec">{{spec.displayName}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mb-3" *ngIf="showImportContentTypeSelections">
            <label for="selectedContentType">What kind of data are you importing?</label>
            <select class="form-select form-select-sm" id="selectedContentType" name="selectedContentType" [(ngModel)]="selectedContentType" (ngModelChange)="selectedContentTypeChanged()" [disabled]="disableSpecDerivedFields()">
                <option [ngValue]="null">-- Select --</option>
                <option *ngFor="let contentType of contentTypes" [ngValue]="contentType">{{ contentType.name }}</option>
            </select>
        </div>

        <div class="mb-3 ws-flex-container-vertical" *ngFor="let fileField of visibleFileFields; trackBy:trackByItem">
            <label>{{fileField.label}}</label>
            <ng-container
                [ngTemplateOutlet]="getTemplate(fileField)"
                [ngTemplateOutletContext]="{ field: fileField, classRef: classRef }"></ng-container>
        </div>

        <div class="mb-3">
            <label>Select a file:</label>
            <ws-file-picker (picked)="filePicked($event)"></ws-file-picker>
        </div>

        <div class="mb-3" *ngIf="selectedContentType?.supportAttachments">
            <label>Select an Attachment zip file:</label>
            <ws-file-picker (picked)="attachmentFilePicked($event)"></ws-file-picker>
        </div>

        <div class="mb-3">
            <label for="selectedFileType">What type of file are you uploading?</label>
            <select class="form-select form-select-sm" id="selectedFileType" name="selectedFileType" [(ngModel)]="selectedFileType" [disabled]="disableSpecDerivedFields()">
                <option [ngValue]="null">-- Select --</option>
                <option *ngFor="let fileType of fileTypes" [ngValue]="fileType">{{ fileType.name }}</option>
            </select>
        </div>

        <div class="mb-3" *ngIf="isFileTypeDelimited(selectedFileType)">
            <label>What's the delimiter between columns?</label>
            <div class="form-inline">
                <div class="mb-3">
                    <select class="form-select form-select-sm" id="selectedFileDelimiter" name="selectedFileDelimiter" [(ngModel)]="selectedFileDelimiter">
                        <option [ngValue]="null">-- Select --</option>
                        <option *ngFor="let fileDelimiter of fileDelimiters" [ngValue]="fileDelimiter">{{ fileDelimiter.name }}</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="selectedFileDelimiter?.name === 'Other'">
                    <input class="form-control exclude-modal-styling" type="text" name="fileDelimiter" [(ngModel)]="selectedFileDelimiter.value" maxlength="5" placeholder="Enter delimiter..." [disabled]="disableSpecDerivedFields()"/>
                </div>
            </div>
        </div>

        <div class="mb-3" *ngIf="isFileTypeDelimited(selectedFileType)">
            <label for="fileHasHeaders">Does the file have a header row?</label>
            <div class="radio clearfix" style="margin-top: 0;">
                <div class="col-lg-12">
                    <label>
                        <input type="radio" name="headerRowRadioGroup" [value]="1" [(ngModel)]="headerRowIndex" [disabled]="disableSpecDerivedFields()"> Yes
                    </label>
                </div>
            </div>
            <div class="radio clearfix">
                <div class="col-lg-12">
                    <label>
                        <input type="radio" name="headerRowRadioGroup" [value]="0" [(ngModel)]="headerRowIndex" [disabled]="disableSpecDerivedFields()"> No
                    </label>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label for="selectedCompanyId">Provide a name for the import:</label>
            <input class="form-control exclude-modal-styling"
                   type="text"
                   autocomplete="off"
                   name="importDisplayName"
                   maxlength="100"
                   [ngModel]="importDisplayName"
                   (ngModelChange)="importDisplayNameChanged($event)"/>
        </div>
    </form>
</ws-modal-wrapper>
