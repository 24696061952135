<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Excel Preview"
                  acceptLabel="Continue"
                  footerLabel="* Showing up to 25 rows from the worksheet."
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <div class="ws-flex-container-vertical ws-stretch form-inline">
        <div class="ws-flex-container-horizontal ws-flex-none row">
            <div class="ws-flex-container-horizontal ws-flex-none ws-flex-align-items-center col-auto">
                <label class="ws-flex-none ws-no-margin ws-no-padding" for="headersRowIndex">Header Row Index:</label>
                <input id="headersRowIndex" name="headersRowIndex" [(ngModel)]="headerRowIndex" type="number" class="form-control" min="0" max="999">
            </div>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none row">
            <div class="ws-flex-container-horizontal ws-flex-none ws-flex-align-items-center">
                <label class="ws-flex-none ws-no-margin ws-no-padding" for="selectedWorksheet">Show worksheet:</label>
                <select class="form-select form-select-sm" id="selectedWorksheet" name="selectedWorksheet" [(ngModel)]="selectedWorksheetName" (ngModelChange)="selectedWorksheetChanged()">
                    <option *ngFor="let worksheet of previewInfo?.worksheetsInfo" [ngValue]="worksheet.worksheetName">{{ worksheet.worksheetName }}</option>
                </select>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
