<h3 *ngIf="filter" class="mt-2 mb-1" style="text-align: center; font-family: 'Oswald'; font-size: 28px; text-transform: uppercase;">My <span *ngIf="filter.ShowChoice==3">Team's </span>Tasks Due Within 45 Days</h3>
<div class="action-view-overview">
    <div style="margin: auto; padding: 20px; text-align: center;" *ngIf="isLoading && !actionViewTimeout">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!isLoading && !actionViewTimeout && (!tableData || tableData.length === 0)">
        <p class="text-center lead" style="margin-top: 20px;"><em>No current tasks</em></p>
    </div>
    <div>
        <p class="text-center lead" style="margin-top:20px;" *ngIf="actionViewTimeout">
            Your data is taking some time to load. To access your Action Views, <span class="anchor-style" (click)="goToActionView()">click here</span>.
        </p>
    </div>
    <table class="task-table" style="margin: auto;" *ngIf="!isLoading && !actionViewTimeout && tableData && tableData.length > 0">
        <thead>
            <tr>
                <th class="name-header"></th>
                <th *ngFor="let name of headerNames">
                    <span>{{name}}</span>
                </th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let task of tableData">
                <td class="task-series-row-header">
                    {{task.taskSeriesTypeName}}
                </td>
                <td *ngFor="let item of task.data" [className]="item.colClass">
                    <span class="wide-value-badge" [tooltip]="'Ready: '+item.ready+', Not Ready: '+ item.notReady">
                        <span (click)="drilldownCell(item, 'ready', task)" *ngIf="item.ready > 0" >{{item.ready}}</span>  <span *ngIf="item.notReady > 0" (click)="drilldownCell(item, 'not ready', task)">({{item.notReady}})</span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

</div>
