import { Component, OnInit } from '@angular/core';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { AnchorSREF } from '../../UI-Lib/Directives/Anchor-Route/anchorRoute.directive';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

interface AmplitudeProperty {
    event: string;
    properties?: any;
}
interface TaxMenuItem {
    name: string;
    amplitudeProperty?: AmplitudeProperty;
    href?: string;
    anchor?: AnchorSREF;
}

@Component({
    selector: 'tax-menu',
    templateUrl: './taxMenu.component.html',
    styleUrls: ['./taxMenu.component.scss']
})
export class TaxMenuComponent implements OnInit {
    constructor(
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    menuItems: TaxMenuItem[];

    logout: TaxMenuItem = {
        name: 'Logout',
        anchor: { target: 'logout', options: {} },
        amplitudeProperty: { event: 'PP-click-logout' }
    };

    ngOnInit(): void {
        this.menuItems = [
            {
                name: 'Manage Account',
                href: `${this._featureFlagsService.taxDotComBase}/user-management/myaccount/`,
                amplitudeProperty: {
                    event: 'PP-click-tax.com-menu-option',
                    properties: { menuOption: 'Manage Account' }
                }
            },
            {
                name: 'Application Launcher',
                href: `${this._featureFlagsService.taxDotComBase}/apps/`,
                amplitudeProperty: {
                    event: 'PP-click-tax.com-menu-option',
                    properties: { menuOption: 'Application Launcher' }
                }
            }
        ];

        if (this._featureFlagsService.featureFlags.enableVisualizations) {
            this.menuItems.push({
                name: 'Property Tax Snapshot',
                href: `${this._featureFlagsService.taxDotComBase}/apps/PropertyTaxSnapshot/`,
                amplitudeProperty: {
                    event: 'PP-click-tax.com-menu-option',
                    properties: { menuOption: 'Snapshot' }
                }
            });
        }
    }

    triggerAmplitudeEvent(amplitudeProperty: AmplitudeProperty): void {
        if (amplitudeProperty) {
            this._productAnalyticsService.logEvent(amplitudeProperty.event, amplitudeProperty.properties);
        }
    }
}
