<ul #dropdown
    *ngIf="config"
    [@dropdownVisible]="open ? 'show' : 'hide'"
    class="typeahead-dropdown"
    clickOutside
    (onClickOutside)="closeDropdown()"
    [class.active]="open"
    [style.top]="dropdownPosition?.top"
    [style.left]="dropdownPosition?.left"
    [style.min-width]="dropdownPosition?.width">
    <li *ngIf="config && config.canDeselect"
        class="dropdown-option"
        (click)="optionSelected(config.deselectValue)">
        <span class="dropdown-value">
            {{config.deselectLabel}}
        </span>
    </li>
    <li *ngFor="let option of config.values"
        class="dropdown-option"
        [class.disabled]="option[config.disabledProperty]"
        (click)="optionSelected(option)">
        <span class="dropdown-value" [title]="((config.isPrimitive) ? option : option[config.typeaheadOptionField])">
            {{((config.isPrimitive) ? option : option[config.typeaheadOptionField])}}
        </span>
    </li>
</ul>
