import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { FormAssessorsPanelGridActionCellRendererComponent, ICellRendererParamsForFormAssessorsPanelGridAction } from './agGridActionCellRenderer.component';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { AgGridCheckboxCellRendererComponent, ICellRendererParamsForAgGridCheckbox } from '../../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { FormAssessorAddParams, FormAssessorAddComponent } from '../Form-Assessor-Add/formAssessorAdd.component';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { IMutexServiceHandler, WeissmanMutexService } from '../../WeissmanMutexService';
import { BaseExpandableAgGridComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import * as _ from 'lodash';
import { FormAssessorListAddressCellRendererComponentParams, FormAssessorListAddressCellRendererComponent } from './agGridAddressCellRenderer.component';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { FormAssessorAddressPickerComponent, FormAssessorAddressPickerParams } from '../Form-Assessor-Address-Picker/formAssessorAddressPicker.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectCellRendererParams, AgGridMultiSelectTracker, AgGridMultiSelectHeaderRendererParams } from '../../AgGrid/MultiSelectTracker';
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { HelpContentComponentConfig } from "../../../UI-Lib/Help-Tooltip";
import {
    AssessorCertifiedTooltipComponent,
    AssessorCertifiedTooltipParams
} from "./Assessor-Certified-Tooltip/assessorCertifiedTooltip.component";

@Component({
    selector: 'form-assessor-list',
    templateUrl: './formAssessorList.component.html',
    styleUrls: ['./formAssessorList.component.scss']
})
export class FormAssessorListComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService) {
        super(_formService, 'form-assessor-list');
    }

    private _gridApi: GridApi;
    private _gridTracker: AgGridMultiSelectTracker;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _destroy$: Subject<void> = new Subject();

    get assessors(): Compliance.FormRevisionAssessorModel[] {
        return this._formService.assessors;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get taxYear(): number {
        return this._formService.taxYear;
    }

    get hasSelectedRows(): boolean {
        return this._gridTracker && (this._gridTracker.hasSelectedRows() && !!this.selectedRows.length);
    }

    get selectedRows(): number[] {
        const rowModel = this._gridTracker && this._gridTracker.getSelectedRowsModel();
        const selected: number[] = [];
        this._gridApi.forEachNodeAfterFilterAndSort(node => {
            const data = node.data as Compliance.FormRevisionAssessorModel;
            if (!(data === this._formService.defaultAssessor || data.isMappingCertified)
                && (!rowModel.selectAllRows || !rowModel.selectedRows.find(d => d === data.formRevisionYearAssessorId))) {
                selected.push(data.formRevisionYearAssessorId);
            }
        });
        return selected;
    }

    areAllCertified: boolean;
    editMode: boolean;
    gridId: System.Guid = 'AFF812F3-C66D-46CE-9E50-7D6696219C06';

    gridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => {
                    const data = params.data as Compliance.FormRevisionAssessorModel;
                    return data && this._gridTracker.isRowSelected(data.formRevisionYearAssessorId) && !(data === this._formService.defaultAssessor || data.isMappingCertified);
                }
            },
            onFilterChanged: () => this._gridTracker.onGridFilterChanged(),
            onSortChanged: () => this._gridTracker.onGridSortChanged()
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withRowId(x => {
            const assessor = x as Compliance.FormRevisionAssessorModel;
            return assessor && assessor.assessorId.toString();
        })
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    certifiedTooltip: HelpContentComponentConfig<AssessorCertifiedTooltipComponent, AssessorCertifiedTooltipParams> = {
        component: AssessorCertifiedTooltipComponent,
        canHover: true
    };

    ngOnInit(): void {
        this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (this.gridOptions && this.gridOptions.columnApi) {
                this.gridOptions.columnApi.setColumnsVisible(['formRevisionYearAssessorId', 'actions'], this.editMode);
                this.gridOptions.api.sizeColumnsToFit();
                this._gridApi.redrawRows();
            }
            if (!this.editMode) {
                this._mutexService.release(this, this._formService.editGroup);
            }
        });

        this._formService.formRevision$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // initial load
            this._setRowData();
        });

        this._formService.formRevisionYear$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // year changed
            this._setRowData();
        });

        this._formService.assessors$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // assessors changed
            this._setRowData();
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._mutexService.release(this, this._formService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = [
            {
                field: 'formRevisionYearAssessorId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                hide: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this._gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.FormRevisionAssessorModel) => {
                        let result = true;
                        this._gridApi.forEachNodeAfterFilterAndSort(node => {
                            const data = node.data as Compliance.FormRevisionAssessorModel;
                            if (!(data === this._formService.defaultAssessor || data.isMappingCertified)) {
                                result = false;
                            }
                        });
                        return result;
                    }
                } as AgGridMultiSelectHeaderRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.FormRevisionAssessorModel) => data === this._formService.defaultAssessor || data.isMappingCertified
                } as AgGridMultiSelectCellRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                lockVisible: true,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const assessor = params.data as Compliance.FormRevisionAssessorModel;
                        if (!(assessor && assessor.assessorId)) {
                            return '';
                        }
                        return `#/assessor/${assessor.assessorId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const assessor = params.data as Compliance.FormRevisionAssessorModel;
                        return !(assessor && assessor.assessorId);
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Def. Tables',
                field: 'isUsingDefaultTables',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditDefaults.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'Def. Mappings',
                field: 'isUsingDefaultMappings',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditDefaults.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'Address',
                field: 'isValid',
                width: AgGridColumns.textColumnSmallWidth,
                minWidth: AgGridColumns.textColumnSmallWidth,
                cellRendererFramework: FormAssessorListAddressCellRendererComponent,
                cellRendererParams: {
                    edit: this._editAddress.bind(this),
                } as FormAssessorListAddressCellRendererComponentParams
            },
            {
                headerName: 'Certified',
                field: 'isMappingCertified',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    isVisible: (params: ICellRendererParamsForAgGridCheckbox) => true,
                    canEdit: this._canCertifyAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'RE',
                field: 'useForRE',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: !this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'PP',
                field: 'useForPP',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: !this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'CA',
                field: 'useForCA',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: !this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: 'MN',
                field: 'useForMN',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                hide: !this._formService.isFormTypeAppeal,
                cellRendererParams: {
                    isVisible: this._isVisibleAssessor.bind(this),
                    canEdit: this._canEditAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                hide: true,
                cellRendererFramework: FormAssessorsPanelGridActionCellRendererComponent,
                cellRendererParams: {
                    canEdit: this._canViewAssessor.bind(this),
                    canEnterEditMode: () => this.editMode || this.canEnterEditMode,
                    canRemove: this._canRemoveAssessor.bind(this),
                    remove: this._onRemove.bind(this)
                } as ICellRendererParamsForFormAssessorsPanelGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._mutexService.acquire(this, this._formService.editGroup);
        this._editModeSubject.next(true);
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    cancel(): void {
        this._gridTracker.clear();
        this._editModeSubject.next(false);
    }

    async addNewAssessor(): Promise<void> {
        const params: FormAssessorAddParams = {
            formRevisionId: this._formService.formRevisionId,
            taxYear: this._formService.taxYear
        };

        await this._modalService.showAsync(FormAssessorAddComponent, params, 'modal-md');
    }

    async bulkRemove(): Promise<void> {
        const rowModel = this._gridTracker.getSelectedRowsModel();
        const selected = (rowModel.selectAllRows) ? this.selectedRows : rowModel.selectedRows;
        if (!selected.length) {
            return Promise.resolve();
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Removing Assessors' });

        try {
            await this._formService.disassociateAssessor(selected, false);
        } catch (e) {
            if (e && e.status !== 422) {
                return Promise.reject(e);
            }
            busyRef.hide();

            try {
                await this._messageModalService.confirm(
                    e.error.message,
                    'Confirm Remove');
            } catch (e2) {
                return Promise.resolve();
            }

            busyRef = this._busyIndicatorService.show({ message: 'Removing Assessors' });
            await this._formService.disassociateAssessor(selected, true);

            this._gridTracker.clear();
        } finally {
            busyRef.hide();
        }
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        const assessors = _.filter(this._formService.assessorsAssigned, x => x !== this._formService.defaultAssessor);

        const update: RowNode[] = [];
        const remove: Compliance.FormRevisionAssessorModel[] = [];
        const add: Compliance.FormRevisionAssessorModel[] = [];

        _.forEach(assessors,
            x => {
                const row = this._gridApi.getRowNode(x.assessorId.toString());
                if (row) {
                    const assessor = row.data as Compliance.FormRevisionAssessorModel;
                    if (assessor) {
                        _.extend(assessor, x);
                        update.push(row);
                    }
                } else {
                    add.push(x);
                }
            });

        this._gridApi.forEachNode((node: RowNode) => {
            const assessor = node.data as Compliance.FormRevisionAssessorModel;
            if (assessor) {
                if (!_.find(assessors, x =>  x.assessorId === assessor.assessorId)) {
                    remove.push(assessor);
                }
            }
        });

        this._gridApi.setPinnedTopRowData([this._formService.defaultAssessor]);
        this._gridApi.updateRowData({ remove: remove, add: add });
        this._gridApi.redrawRows({ rowNodes: update });

        this._areAllAssessorsCertified();
    }

    private async _editAddress(params: FormAssessorListAddressCellRendererComponentParams): Promise<void> {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;

        if (!(this.editMode && assessor && !assessor.isMappingCertified)) {
            return;
        }

        const addressParams: FormAssessorAddressPickerParams = {
            formRevisionYearAssessorId: assessor.formRevisionYearAssessorId
        };

        await this._modalService.showAsync(FormAssessorAddressPickerComponent, addressParams, 'modal-lg');
    }

    private _canViewAssessor(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        // for compliance forms do not allow editing when the assessor is using defaults
        if (!this._formService.isFormTypeAppeal && assessor.assessorId && assessor.isUsingDefaultMappings && assessor.isUsingDefaultTables) {
            return false;
        }

        return true;
    }

    private _canEditAssessor(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        // for compliance forms do not allow editing when the assessor is using defaults
        if (!this._formService.isFormTypeAppeal && assessor.assessorId && assessor.isUsingDefaultMappings && assessor.isUsingDefaultTables) {
            return false;
        }

        return this.editMode && this.canEdit && !assessor.isMappingCertified;
    }

    private _canRemoveAssessor(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        return this.editMode && this.canEdit && assessor.assessorId !== null && !assessor.isMappingCertified;
    }

    private _canEditDefaults(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        return this.editMode && this.canEdit && assessor.assessorId !== null;
    }

    private _canCertifyAssessor(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        // for compliance forms do not allow editing when the assessor is using defaults
        if (!this._formService.isFormTypeAppeal && assessor.assessorId && assessor.isUsingDefaultMappings && assessor.isUsingDefaultTables) {
            return false;
        }

        return this.editMode && this.canEdit;
    }

    private _isVisibleAssessor(params: ICellRendererParamsForFormAssessorsPanelGridAction): boolean {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return false;
        }

        return assessor.assessorId !== null;
    }

    private async _onRemove(params: ICellRendererParamsForFormAssessorsPanelGridAction): Promise<void> {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;
        if (!assessor) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.confirm(
                `Are you sure you wish to remove '${assessor.assessorName}'?`,
                'Confirm Remove');
        } catch (e) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Removing Assessor' });

        try {
            await this._formService.disassociateAssessor([assessor.formRevisionYearAssessorId], true);
        } finally {
            busyRef.hide();
        }

        this._areAllAssessorsCertified();
    }

    private async _onValueChanged(params: ICellRendererParamsForAgGridCheckbox, newValue: any): Promise<void> {
        const assessor = params.data as Compliance.FormRevisionAssessorModel;

        if (newValue) {
            if (params.colDef.field === 'isUsingDefaultTables') {
                try {
                    await this._messageModalService.confirm(
                        'Are you sure you wish to use default factor tables? This will revert factor tables to default.',
                        'Confirm Action');
                } catch (e1) {
                    params.value = !newValue;
                    assessor.isUsingDefaultTables = !newValue;
                    return Promise.resolve();
                }

                const busyRef = this._busyIndicatorService.show({ message: 'Setting factor tables to default' });

                try {
                    await this._formService.assessorChangeUseDefaultTables(assessor.formRevisionYearAssessorId, newValue);
                } catch (e2) {
                    params.value = !newValue;
                    assessor.isUsingDefaultTables = !newValue;
                    await Promise.reject(e2);
                }
                finally {
                    busyRef.hide();
                }

            } else if (params.colDef.field === 'isUsingDefaultMappings') {
                try {
                    await this._messageModalService.confirm(
                        'Are you sure you wish to use default mappings? This will revert factor tables and asset class mappings to default values.',
                        'Confirm Action');
                } catch (e3) {
                    params.value = !newValue;
                    assessor.isUsingDefaultMappings = !newValue;
                    return Promise.resolve();
                }

                const busyRef = this._busyIndicatorService.show({ message: 'Setting factor tables and asset class mappings to default' });

                try {
                    await this._formService.assessorChangeUseDefaultMappings(assessor.formRevisionYearAssessorId, newValue);
                } catch (e4) {
                    params.value = !newValue;
                    assessor.isUsingDefaultMappings = !newValue;
                    await Promise.reject(e4);
                } finally {
                    busyRef.hide();
                }
            } else if (_.startsWith(params.colDef.field, 'useFor')) {
                const busyRef = this._busyIndicatorService.show({ message: `Setting Property Type ${params.colDef.headerName} for ${assessor.assessorName}` });

                try {
                    await this._formService.assessorChangeUsePropertyType(assessor.formRevisionYearAssessorId, newValue, params.colDef.field);
                } catch (e5) {
                    params.value = !newValue;
                    assessor[params.colDef.field] = !newValue;
                    await Promise.reject(e5);
                } finally {
                    busyRef.hide();
                }
            } else if (params.colDef.field === 'isMappingCertified') {
                if (!assessor.assessorId) {
                    try {
                        await this._messageModalService.confirm(
                            'Are you sure you wish to update the certification for all assessors using default mappings?',
                            'Confirm Action');
                    } catch (e6) {
                        params.value = !newValue;;
                        assessor.isMappingCertified = !newValue;
                        return Promise.resolve();
                    }
                }

                const busyRef = this._busyIndicatorService.show({ message: 'Setting assessor certification' });

                try {
                    await this._formService.assessorChangeIsCertified(assessor.formRevisionYearAssessorId, newValue);
                } catch (e7) {
                    params.value = !newValue;
                    assessor.isMappingCertified = !newValue;
                    await Promise.reject(e7);
                } finally {
                    busyRef.hide();
                }
            }
        } else {
            if (params.colDef.field === 'isUsingDefaultTables') {
                const busyRef = this._busyIndicatorService.show({ message: 'Copying factor table default settings.' });

                try {
                    await this._formService.assessorChangeUseDefaultTables(assessor.formRevisionYearAssessorId, newValue);
                } finally {
                    busyRef.hide();
                }

            } else if (params.colDef.field === 'isUsingDefaultMappings') {
                const busyRef = this._busyIndicatorService.show({ message: 'Copying factor table and asset class mapping default settings.' });

                try {
                    await this._formService.assessorChangeUseDefaultMappings(assessor.formRevisionYearAssessorId, newValue);
                } finally {
                    busyRef.hide();
                }
            } else if (_.startsWith(params.colDef.field, 'useFor')) {
                const busyRef = this._busyIndicatorService.show({ message: `Unsetting Property Type ${params.colDef.headerName} for ${assessor.assessorName}` });

                try {
                    await this._formService.assessorChangeUsePropertyType(assessor.formRevisionYearAssessorId, newValue, params.colDef.field);
                } finally {
                    busyRef.hide();
                }
            } else if (params.colDef.field === 'isMappingCertified') {
                if (!assessor.assessorId) {
                    try {
                        await this._messageModalService.confirm(
                            'Are you sure you wish to update the certification for all assessors using default mappings?',
                            'Confirm Action');
                    } catch (e6) {
                        params.value = !newValue;;
                        assessor.isMappingCertified = !newValue;
                        return Promise.resolve();
                    }
                }

                const busyRef = this._busyIndicatorService.show({ message: 'Setting assessor certification' });

                try {
                    await this._formService.assessorChangeIsCertified(assessor.formRevisionYearAssessorId, newValue);
                } catch (e9) {
                    params.value = !newValue;
                    assessor.isMappingCertified = !newValue;
                    await Promise.reject(e9);
                } finally {
                    busyRef.hide();
                }
            }
        }

        this._areAllAssessorsCertified();
    }

    private _areAllAssessorsCertified(): void {
        this.areAllCertified = this._formService.assessorsAssigned.every(x => x.isMappingCertified);
        let nonCertifiedAssessors = [];
        if (!this.areAllCertified) {
            nonCertifiedAssessors = this._formService.assessorsAssigned.filter(x => {
                return x !== this._formService.defaultAssessor && !x.isMappingCertified;
            });
        }

        this.certifiedTooltip.componentParams = {
            areAllAssessorsCertified: this.areAllCertified,
            nonCertifiedAssessors
        }
    }

    private _getGridRowIds(skip, take): Compliance.QueryResultModel<number> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const assessor = x.data as Compliance.FormRevisionAssessorModel;
                return assessor && assessor.formRevisionYearAssessorId;
            })
        } as Compliance.QueryResultModel<number>;
    }
}
