<div *ngIf="config">
    <div class="modal-header">
        <h4 class="modal-title">{{ config.year }} Revisions</h4>
        <button type="button" class="btn-close" (click)="modalRef.hide()" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="text-center" *ngIf="isBusy">
            <i class="fa fa-spinner fa-spin fa-2x"></i> Savings changes...
        </div>
        <div *ngIf="!isBusy">
            <h5>Drag & drop the revisions below to order them from the earliest (top) to the most recent (bottom).</h5>
            <bs-sortable [(ngModel)]="config.assessments" [itemTemplate]="itemTemplate" itemClass="wsSortable__item" itemActiveClass="wsSortable__item--active"
                wrapperClass="wsSortable">
            </bs-sortable>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn flat-button primary-button" [disabled]="isBusy" (click)="save()">Save</button>
        <button type="button" class="btn flat-button warning-button" [disabled]="isBusy" (click)="modalRef.hide()">Cancel</button>
    </div>
</div>

<ng-template #itemTemplate let-item="item" let-index="index">
    <span class="label label-success">REVISION {{ index + 1 }}</span>
    <div style="padding-left: 8px; display: inline-block; vertical-align: middle;">
        <strong>{{ item.value.description }}</strong>
    </div>
</ng-template>
