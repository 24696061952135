import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { AssetClassSelectorComponent } from './assetClassSelector.component';

@NgModule({
    imports: [WeissmanSharedModule],
    declarations: [AssetClassSelectorComponent],
    exports: [AssetClassSelectorComponent]
})
export class AssetClassSelectorModule {
}
