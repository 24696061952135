import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { HelpContentComponentConfig } from '../../../UI-Lib/Help-Tooltip';
import {
    AssessorAddressTooltipComponent,
    AssessorAddressTooltipComponentParams
} from './Assessor-Address-Tooltip/assessorAddressTooltip.component';

export interface FormAssessorListAddressCellRendererComponentParams extends ICellRendererParams {
    edit: (params: FormAssessorListAddressCellRendererComponentParams) => void;
}

@Component({
    selector: 'assessor-address',
    template: `
        <span class="pointer" *ngIf="assessor"
              (click)="params.edit(params)"
              helpTooltip
              [contentComponent]="contentComponent"
              position="right"
              [ngClass]="{'assessor-certified address-disabled': assessor.isMappingCertified}">
        <i class="fa fa-address-card-o" *ngIf="assessor.assessorId"></i>
        <i class="fa fa-exclamation ms-1" *ngIf="!assessor.isValid"></i>
    </span>
    `
})
export class FormAssessorListAddressCellRendererComponent implements ICellRendererAngularComp {
    params: FormAssessorListAddressCellRendererComponentParams;
    assessor: Compliance.FormRevisionAssessorModel;

    contentComponent: HelpContentComponentConfig<AssessorAddressTooltipComponent, AssessorAddressTooltipComponentParams> = {
        component: AssessorAddressTooltipComponent,
        componentParams: {
            address: null
        },
        canHover: false
    };

    agInit(params: FormAssessorListAddressCellRendererComponentParams): void {
        this.params = params;
        this.assessor = params.data as Compliance.FormRevisionAssessorModel;
        this.contentComponent.componentParams.address = this.assessor.address;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
