import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { TaxRatesPanelModule } from '../Tax-Rates/taxRatesPanel.module';
import { CollectorDetailsComponent } from './Collector-Details/collectorDetails.component';
import { CollectorComponent } from './collector.component';
import { CommentsModule } from '../../Comments/comments.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BillingScenarioModalComponent } from './Billing-Scenario/New-Scenario/billingScenarioModal.component';
import { BillingScenarioService } from './Billing-Scenario/billingScenario.service';
import { BillingScenarioRepository } from '../../Core-Repositories/billingScenario.repository';
import { BillingScenarioComponent } from './Billing-Scenario/billingScenario.component';
import { HelpModule } from '../../UI-Lib/Help-Tooltip/help.module';
import { CollectorProtocolsComponent } from './Protocols/collectorProtocols.component';
import { ContactModule } from '../../Contact/contact.module';
import { PropertyCharacteristicsModule } from '../../Property-Characteristics/propertyCharacteristics.module';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        CommentsModule,
        SharedPipesModule,
        HelpModule,
        ContactModule,
        TaxRatesPanelModule,
        PropertyCharacteristicsModule
    ],
    declarations: [
        CollectorComponent,
        CollectorDetailsComponent,
        BillingScenarioComponent,
        BillingScenarioModalComponent,
        CollectorProtocolsComponent
    ],
    providers: [
        BillingScenarioService,
        BillingScenarioRepository
    ],
    exports: [
        CollectorComponent,
        BillingScenarioComponent,
        CollectorProtocolsComponent
    ]
})
export class CollectorModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('collector', CollectorComponent);
        hybridAdapterUtility.downgradeNg2Component('billingScenario', BillingScenarioComponent);
        hybridAdapterUtility.downgradeNg2Component('collectorProtocols', CollectorProtocolsComponent);
        hybridAdapterUtility.downgradeNg2Provider('sd.Bill.Scenario.Service', BillingScenarioService);
    }
}
