import { Component, Inject, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { AnnualDetailsNavigationEventService } from 'src/app/Annual-Details/annual-details-event.service';
import { Assessor } from 'src/app/Assessor-Collector/assessor.model';
import { AssessorService } from 'src/app/Assessor-Collector/Assessor/assessor-service';
import { ChangeHistoryModalLaunchService } from 'src/app/Common/Change-History/change-history-modal-launch.service';
import { CurrentEntityService } from 'src/app/Common/Entity/CurrentEntityService';
import { InstanceRights, RestrictData, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { EntityTypeIds, RyanInstanceId } from 'src/app/constants.new';
import { NavigationService } from 'src/app/Layout/navigation.service';
import { TaskService } from 'src/app/Task/task.service.upgrade';
import { HelpContentComponentConfig } from 'src/app/UI-Lib/Help-Tooltip';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from 'src/app/UI-Lib/Message-Box/messagebox.service.upgrade';
import { ActivityService } from '../../../Common/Activity/activity.service';
import {
    AssessorDetailsComponent,
    AssessorDetailsComponentParams
} from '../../Assessor-Details/assessorDetails.component';
import { InstanceRepository } from '../../Instance/instance.repository';
import { ParcelService } from '../parcel.service.upgrade';
import { ParcelTaxRateService } from '../TaxRateSetup/parcelTaxRateService';
import { ParcelCollectorTaxAuthorities } from './Tax-Rates/collectorTaxRateArea.component';

@Component({
    selector: 'parcel-info-panel-new',
    templateUrl: 'parcelInfoPanel.component.html'
})

export class ParcelInfoPanelComponent implements OnInit {
    constructor(
        private readonly _instanceRepository: InstanceRepository,
        private readonly _annualDetailsNavigationEventService: AnnualDetailsNavigationEventService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _assessorService: AssessorService,
        private readonly _toastr: ToastrService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _taskService: TaskService,
        @Inject('$rootScope') private _rootScope: any,
        private readonly _parcelService: ParcelService,
        private readonly _currentEntityService: CurrentEntityService,
        private readonly _parcelTaxRateService: ParcelTaxRateService,
        private readonly _changeHistoryModalLaunchService: ChangeHistoryModalLaunchService,
        private readonly _navigationService: NavigationService,
        private readonly _restrictService: RestrictService,
        private readonly _activityService: ActivityService
    ) {
    }

    @Input() parcelViewDto: Core.ParcelViewDTO;
    @Input() stateAllowConsolidating: boolean;
    @Input() loadClientServices$: Subject<void>;
    @Input() getAnnualGridCallback: (getCompTypesOnly: boolean) => void;

    editMode: boolean = false;
    loadingPermissions: boolean = false;
    serverAction: boolean = false;
    hasEditPermission: boolean = false;
    hasInstancePrivateView: boolean = false;
    assessors: Assessor[];
    parcelAssessor: Assessor;
    instanceId: number;
    assessorDetailsRenderer: HelpContentComponentConfig<AssessorDetailsComponent, AssessorDetailsComponentParams>;
    years: number[];
    reloadAnnualGrid: boolean = false;

    private _originalParcel: Weissman.Model.Domain.Parcel;
    private _parcelCollectorTaxAuthorities: ParcelCollectorTaxAuthorities[];
    private _siteId: number;


    async ngOnInit() {
        this._initParcel();
        this._loadPermissions();
        this._loadData();
    }

    get parcel(): Weissman.Model.Domain.Parcel {
        return this.parcelViewDto.parcel;
    }

    edit(): void {
        this._originalParcel = _.cloneDeep(this.parcel);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
        this.editMode = true;
    }

    async save(): Promise<void> {
        if (!this.parcel.acctNum) {
            this._toastr.error('Please enter Parcel Acct Num');
            return;
        }

        const missingAcctNum = _.find(this.parcel.altAccountNumbers, { acctNumber: '' });
        if (missingAcctNum?.accountNumberType?.accountNumberDesc) {
            this._toastr.error(`Please enter ${missingAcctNum.accountNumberType.accountNumberDesc} or delete it`);
            return;
        }

        const isParcelAcctNumNonAscii = this.parcel.acctNum && !(/^[ -~\t\n\r]+$/.test(this.parcel.acctNum));
        if (isParcelAcctNumNonAscii) {
            this._toastr.error('Parcel Acct Num contains invalid character(s)');
            return;
        }

        this.reloadAnnualGrid = this._shouldReloadGridAfterSave() || this.reloadAnnualGrid;

        if (this.parcel.activityStatusID !== this._originalParcel.activityStatusID) {

            if (this.parcel.activityStatusID === 0) {
                // wk-2181
                // deactivating parcel - checking if user really can and want to do it
                //  If there are any incomplete appeal pursuit or refund task series tasks then inform the user
                //	"This parcel's status cannot be changed to Inactive because there are open appeals or refunds.".
                //	Change the value in the dropdown back to its previous value.
                //
                const checkIfEntityMayBeDeactivatedResult = await this._taskService.checkIfEntityMayBeDeactivated(this.parcel.parcelID, EntityTypeIds.PARCEL);

                if (checkIfEntityMayBeDeactivatedResult.canBeDeactivated === false) {
                    await this._messageBoxService.open({
                        title: '',
                        message: 'This parcel\'s status cannot be changed to Inactive because there are open appeals or refunds.',
                        buttons: MessageBoxButtons.OK
                    });

                    //	Change the value in the dropdown back to its previous value.
                    this.parcel.activityStatusID = this._originalParcel.activityStatusID;

                } else {
                    if (checkIfEntityMayBeDeactivatedResult.openPaymentBatchTaskCount > 0) {
                        await this._messageBoxService.open({
                            title: '',
                            message: 'This parcel\'s status cannot be changed to Inactive because at least some of its batches have open payments.',
                            buttons: MessageBoxButtons.OK
                        });
                        //	Change the value in the dropdown back to its previous value.
                        this.parcel.activityStatusID = this._originalParcel.activityStatusID;
                    }
                    else {
                    // this parcel doesn't have any open appeals or refunds, confirm with user
                    let msg = 'Changing the parcel\'s status to Inactive will result in all client service responsibilities being changed to N/A. If the parcel is merged in a consolidated relationship, this will be removed. ';
                    msg += ` This will result in ${checkIfEntityMayBeDeactivatedResult.openFilingTaskCount} filing task(s) `;
                    msg += ` and ${checkIfEntityMayBeDeactivatedResult.openAssessmentTaskCount} assessment task(s) `;
                    msg += ` and ${checkIfEntityMayBeDeactivatedResult.openTaxBillTaskCount} tax bill task(s) to no longer be scheduled. `;
                    msg += ' NO FURTHER WORK OF ANY KIND WILL BE SCHEDULED FOR THIS ENTITY.';

                    msg += ' Are you sure you want to proceed?';

                    const result = await this._messageBoxService.open({
                        title: 'Changing parcel\'s status to Inactive',
                        message: msg,
                        buttons: MessageBoxButtons.YesNo
                    });

                    if (result === MessageBoxResult.Yes) {
                        this.parcel.statusDate = new Date();
                        this.reloadAnnualGrid = true;
                        return this._saveParcelToAPI();
                    } else {
                        // user wants to cancel
                        //	Change the value in the dropdown back to its previous value.
                        this.parcel.activityStatusID = this._originalParcel.activityStatusID;

                    }
                }
                }
            } else {
                // activity status has been changed, but parcel is not deactivated
                this.parcel.statusDate = new Date();
                return this._saveParcelToAPI();
            }
        } else {
            // activity status has not been changed; just saving
            return this._saveParcelToAPI();
        }
    }

    async cancel(): Promise<void> {
        this.serverAction = true;

        try {
            this.parcelViewDto = await this._parcelService.getView(this.parcel.parcelID, false);
            this._navigationService.disableNavWarning();
            this.editMode = false;
        } finally {
            this.serverAction = false;
        }
    }

    launchChangeHistoryModal(): void {
        this._changeHistoryModalLaunchService.openChangeHistoryModal(this.parcel.acctNum, this.parcel.parcelID, EntityTypeIds.PARCEL, null);
    }

    private async _saveParcelToAPI(): Promise<void> {
        this.serverAction = true;

        try {
            const savedParcelDTO = await this._parcelService.update(this.parcel);

            await Promise.all(_.map(this._parcelCollectorTaxAuthorities, pc => {
                const savedParcelCollector = _.find(savedParcelDTO.parcel.parcelCollectors, {
                    'collectorBillScenarioID': pc.parcelCollector.collectorBillScenarioID
                }) as any;

                return this._parcelTaxRateService.saveTaxRateSetup(
                    savedParcelCollector.parcelCollectorID,
                    savedParcelCollector.rowVersion,
                    null,
                    _.map(pc.taxAuthorities, 'taxAuthorityId')
                ).then(saveTaxResult => {
                    savedParcelCollector.rowVersion = saveTaxResult.parcelCollectorRowVersion;
                    savedParcelCollector.taxAuthorityCount = pc.taxAuthorities.length;
                    savedParcelCollector.hasTaxRateSetup = true;
                    savedParcelCollector.taxAuthorities = _.map(pc.taxAuthorities, ta => _.omit(ta, 'efAction'));
                });
            }));

            this.parcelViewDto = savedParcelDTO;
            this.parcelAssessor = _.find(this.assessors, { assessorID: this.parcel.assessorID });

            if (this.reloadAnnualGrid) {
                this.reloadAnnualGrid = false;
                this._annualDetailsNavigationEventService.refreshActivityData();
                this.loadClientServices$.next();
                this._annualDetailsNavigationEventService.goToGrid();
                this.getAnnualGridCallback(false);
            }

            this._navigationService.disableNavWarning();
            this.editMode = false;

            this._rootScope.$emit('parcel:parcelInfoChanged', this.parcel);

        } finally {
            this.serverAction = false;
            this._activityService.clearParcelFilterCacheForSite(this._siteId);
        }
    }

    private _initParcel(): void {
        this._siteId = +this._upgradeNavigationServiceHandler.getQuerystringParam('siteId');

        this._currentEntityService.set(this.parcel, EntityTypeIds.PARCEL);
        this._annualDetailsNavigationEventService.yearsChangedEvent$.subscribe(years => this.years = years);

        this.parcel.parcelCollectors = _.map(this.parcel.parcelCollectors, pc => {
            pc.parcelCollectorPaymentOptions = _.sortBy(pc.parcelCollectorPaymentOptions, 'collectorBillName');
            return pc;
        });

        this.parcel.transaction = this.parcel.transaction || {
            soldDate: null
        } as Weissman.Model.Domain.Transaction;

        this._parcelService.parcelCollectorTaxAuthorities$.subscribe(x => this._parcelCollectorTaxAuthorities = x);
    }

    private async _loadPermissions(): Promise<void> {
        this.loadingPermissions = true;

        try {
            this.instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('parcel', this.parcel.parcelID));
            this.hasInstancePrivateView = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, this.instanceId)
                || this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, this.instanceId);

            this.assessorDetailsRenderer = this.instanceId === RyanInstanceId ? {
                component: AssessorDetailsComponent,
                componentParams: {
                    stateId: this.parcel.address.stateID,
                    parcelId: this.parcel.parcelID
                },
                canHover: true
            } : null;

            const restrictionData = {
                isCompany: false,
                entityId: this._siteId,
                roles: [],
                flag: Core.AccessRightsEnum.Write
            } as RestrictData;

            this.hasEditPermission = await this._restrictService.hasPermission(restrictionData);
        } finally {
            this.loadingPermissions = false;
        }
    }

    private async _loadData(): Promise<void> {
        this.serverAction = true;

        try {
            const assessors = await this._assessorService.getByStateId(this.parcel.address.stateID);
            this.assessors = _.sortBy(assessors, 'abbr');
            this.parcelAssessor = _.find(this.assessors, { assessorID: this.parcel.assessorID });
        } finally {
            this.serverAction = false;
        }
    }

    private _shouldReloadGridAfterSave(): boolean {
        const originalIds = _.map(this._originalParcel.parcelAssessmentClasses, 'assessmentClassID');
        const editIds = _.map(this.parcel.parcelAssessmentClasses, 'assessmentClassID');

        const haveAssessmentClassesChanged = !!_.xor(originalIds, editIds).length;

        return haveAssessmentClassesChanged
            || this.parcel.activityStatusID !== this._originalParcel.activityStatusID
            || this.parcel.propertyTypeID !== this._originalParcel.propertyTypeID
            || this.parcel.accrualsExclude.excludeFromAccruals !== this._originalParcel.accrualsExclude.excludeFromAccruals;
    }
}
