import * as _ from 'lodash';

export interface IReturnPartComponent {
    onReturnPartServiceActivated: () => void;
    onReturnPartServiceDeactivated: () => void;
}

/**
 * A base class that all return part services must extend.
 * Gives each return part service an opportunity to notify subscribed components when that service is activated/deactivate.
 */
export abstract class ReturnPartServiceBase {
    private _subscribers: IReturnPartComponent[] = [];
    private _isActive: boolean = false;

    /**
     * Retrieves the shared state that is tracked in the service.
     */
    protected abstract get sharedState(): any;

    get isActive(): boolean {
        return this._isActive;
    }

    /**
     * Returns a clone of the shared state.
     */
    getSharedStateClone(): any {
        return _.cloneDeep(this.sharedState);
    }

    /**
     * Compares the specified state with the current shared state to determinie equality.
     * @param state The state to compare the current shared state to.
     * @param paths The paths to narrow down the comparison for.
     */
    hasSharedStateChanged(state: any, paths?: string[]): boolean {
        const o1 = paths ? _.pick(state, paths) : state;
        const o2 = paths ? _.pick(this.sharedState, paths) : this.sharedState;
        return _.isEqual(o1, o2);
    }

    /**
     * Subscribes a component to the service activation lifecycle.
     * @param handler The component that will handle service activation lifecycle events.
     */
    subscribeToServiceActivationCycle(handler: IReturnPartComponent) {
        this._subscribers.push(handler);
        // if they subscribe after the service is activate then notify activation immediately
        if (this._isActive) handler.onReturnPartServiceActivated();
    }

    /**
     * Unsubscribes a component from handling any events related to the lifecycle of the service.
     * @param handler The component that will stop handling service activation lifecycle events.
     */
    unsubscribeFromServiceActivationCycle(handler: IReturnPartComponent) {
        const index = this._subscribers.indexOf(handler);
        this._subscribers.splice(index, 1);
        handler.onReturnPartServiceDeactivated();
    }

    /**
     * Notifies that the service life cyle status has changes.
     * @param isActive Indicates if the service is active or not.
     */
    notifyServiceActivationChange(isActive: boolean): void {
        this._isActive = isActive;
        this._subscribers.forEach(x => this._isActive
            ? x.onReturnPartServiceActivated()
            : x.onReturnPartServiceDeactivated());
    }

     /**
     * Enables the service to initialize state
     */
    initialize(): void {
    }
}
