import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetPropertyInfo } from '../../Models/assetPropertyMap';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { ASSET_ADVANCED_HELP } from '../Asset-Advanced/assetAdvanced.component.help';
import { AssetRepository } from '../../../Repositories';

import * as _ from 'lodash';

export enum AdvancedFormElements {
    AllAssets = 'allAssetsAtLienDate',
    AnyChanged = 'anyField',
    AnyImpactful = 'includeAnyReturnImpactfulFieldChange',
    Changed = 'includeChanged',
    Disposed = 'disposed',
    DisposedLien = 'includeDisposedSinceLastLienDate',
    DisposedPeriod = 'includeDisposed',
    FieldChanged = 'fieldChangedColumns',
    NewAddition = 'includeNewAddition',
    NotChanged = 'includeNotChanged',
    PotentiallyDisposed = 'includePotentiallyDisposed',
    SpecificField = 'specificField',
    Transferred = 'includeTransferred'
}

export interface DynamicFormControls {
    [key: string]: UntypedFormControl;
}

export interface CheckboxSubSelect {
    formControlName: AdvancedFormElements;
    formControl: UntypedFormControl;
    isOutputControl: boolean;
    options: AssetPropertyInfo[];
}

export interface FilterCheckbox {
    name: string;
    formControl: UntypedFormControl,
    formControlName: AdvancedFormElements;
    isOutputControl: boolean;
    children?: FilterCheckbox[];
    additionalSelect?: CheckboxSubSelect;
}

export interface AssetAdvancedDetailsParams {
    selectOptions: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>[];
    selectControl: UntypedFormControl;
    fieldSelectOptions: AssetPropertyInfo[];
}

@Component({
    selector: 'asset-advanced-filter',
    templateUrl: './assetAdvancedDetails.component.html'
})
export class AssetAdvancedDetailsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _assetRepository: AssetRepository,
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService
    ) { }

    private _destroy$: Subject<void> = new Subject<void>();


    fieldSelect: CheckboxSubSelect = {
        formControlName: AdvancedFormElements.FieldChanged,
        formControl: new UntypedFormControl({ value: [], disabled: true }),
        isOutputControl: true,
        options: []
    };

    assetFilters: FilterCheckbox[] = [
        {
            name: 'All assets at lien date',
            formControl: new UntypedFormControl(false),
            formControlName: AdvancedFormElements.AllAssets,
            isOutputControl: true,
            children: [
                {
                    name: 'Changed',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.Changed,
                    isOutputControl: true,
                    children: [
                        {
                            name: 'Any return-impactful field change (excluding transfers)',
                            formControl: new UntypedFormControl(false),
                            formControlName: AdvancedFormElements.AnyImpactful,
                            isOutputControl: true
                        },
                        {
                            name: 'Field change',
                            formControl: new UntypedFormControl(false),
                            formControlName: AdvancedFormElements.SpecificField,
                            additionalSelect: this.fieldSelect,
                            isOutputControl: false
                        },
                        {
                            name: 'Transferred (site change)',
                            formControl: new UntypedFormControl(false),
                            formControlName: AdvancedFormElements.Transferred,
                            isOutputControl: true
                        }
                    ]
                },
                {
                    name: 'New Addition',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.NewAddition,
                    isOutputControl: true
                },
                {
                    name: 'Not Changed',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.NotChanged,
                    isOutputControl: true
                },
                {
                    name: 'Potentially disposed',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.PotentiallyDisposed,
                    isOutputControl: true
                }
            ]
        },
        {
            name: 'Disposed assets',
            formControl: new UntypedFormControl(false),
            formControlName: AdvancedFormElements.Disposed,
            isOutputControl: false,
            children: [
                {
                    name: 'Disposed. Period.',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.DisposedPeriod,
                    isOutputControl: true
                },
                {
                    name: 'Disposed since comparison date',
                    formControl: new UntypedFormControl(false),
                    formControlName: AdvancedFormElements.DisposedLien,
                    isOutputControl: true
                }
            ]
        }
    ];

    params: AssetAdvancedDetailsParams;
    result: Compliance.AdvancedAssetFilters;

    selectOptions: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>[];
    selectedOption: UntypedFormControl;
    advancedFilterForm: UntypedFormGroup;
    helperFormGroup: UntypedFormGroup;
    disableSubmit: boolean = false;

    lienDate: Date;
    comparisonLienDate: Date;
    comparisonLienDateTypeOption: UntypedFormControl;
    comparisonLienDateDisabled: boolean = true;


    comparisonLienDateTypeOptions: Compliance.NameValuePair<Compliance.ComparisonLienDateTypeEnum>[] = [
        {
            name: 'Lien Date One Year Ago', value: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
        },
        {
            name: 'Nearest prior lien date', value: Compliance.ComparisonLienDateTypeEnum.NearestPriorLienDate
        },
        {
            name: 'Custom', value: Compliance.ComparisonLienDateTypeEnum.Custom
        }
    ];

    private _dropdownOnOpen: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>;

    ngOnInit(): void {
        this._helpService.setContent(ASSET_ADVANCED_HELP);
        // set select options
        this.selectOptions = this.params.selectOptions;
        this.selectedOption = this.params.selectControl;
        this._dropdownOnOpen = this.selectedOption.value;
        this.fieldSelect.options = this.params.fieldSelectOptions;

        this.lienDate = this._assetRepository.getLienDateUserSetting();

        let comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum;

        comparisonLienDateType = this._assetRepository.getComparisonDateTypeUserSetting();

        this.comparisonLienDate = this._assetRepository.getComparisonDateUserSetting();

        if (!comparisonLienDateType) {
            comparisonLienDateType = Compliance.ComparisonLienDateTypeEnum.OneYearAgo;
        }

        this.comparisonLienDateTypeOption = new UntypedFormControl(_.find(this.comparisonLienDateTypeOptions, { value: comparisonLienDateType }));

        if (comparisonLienDateType === Compliance.ComparisonLienDateTypeEnum.Custom) {
            this.comparisonLienDateDisabled = false;
        }

        // build form
        this._buildForm();
        this.applyFromDropdown();
        this._registerFormListeners();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * Save the form values
     */
    save(): void {
        this.result = this._createOutputModel();

        if (_.isEqual(this.result, this._dropdownOnOpen.value)) {
            this.result = null;
            this.cancel();
            return;
        }

        this._assetRepository.updateComparisonDateUserSetting(this.result.comparisonLienDate);
        this._assetRepository.updateComparisonDateTypeUserSetting(this.result.comparisonLienDateType);

        this._bsModalRef.hide();
    }

    /**
     * Cancel and reset to initial state
     */
    cancel(): void {
        this.selectedOption.setValue(this._dropdownOnOpen);
        this.applyFromDropdown();
        this._bsModalRef.hide();
    }

    /**
     * Apply from the initial set value or from quick list select
     */
    applyFromDropdown(): void {
        const formValue = this.selectedOption.value;
        const filter = <Compliance.AdvancedAssetFilters>{ ...formValue.value };
        filter.fieldChangedColumns = (!filter.fieldChangedColumns) ? [] : filter.fieldChangedColumns;
        this.advancedFilterForm.patchValue(filter);
        this.helperFormGroup.get(AdvancedFormElements.SpecificField)
            .setValue(!!(filter.fieldChangedColumns && filter.fieldChangedColumns.length));
        this._checkChildChanges(this.assetFilters);
    }

    async comparisonLienDateTypeOptionChanged(): Promise<void> {
        const filter: Compliance.NameValuePair<Compliance.ComparisonLienDateTypeEnum> = this.comparisonLienDateTypeOption.value;

        if (filter.value === Compliance.ComparisonLienDateTypeEnum.Custom) {
            this.comparisonLienDateDisabled = false;
        } else {
            this.comparisonLienDate = null;
            this.comparisonLienDateDisabled = true;
        }

        this._validateComparisonDate();
    }

    onComparisonLienDateChanged(value: Date): void {
        this._validateComparisonDate();
    }

    /**
     * Update the form on selected changes
     * @param selected
     */
    updateForm(selected: FilterCheckbox): void {
        this._updateFormChildren(selected);
        this._checkQuickListMatch();
    }

    /**
     * Update the selected form element and it's children
     * @param selected
     * @param value
     */
    private _updateFormChildren(selected: FilterCheckbox, value?: boolean): void {
        if (selected.children) {
            if (value === null || value === undefined) {
                value = selected.formControl.value;
            }
            selected.children.forEach(
                child => {
                    child.formControl.setValue(value);

                    if (child.additionalSelect) {
                        //handle the field change select if it's a child
                        const select = child.additionalSelect;
                        select.formControl.setValue((value) ? select.options.map(o => o.property) : []);
                    }

                    this._updateFormChildren(child, value);
                });
        } else {
            this._checkChildChanges(this.assetFilters);
        }
    }

    /**
     * Check to see if a change to a child checkbox effects the parent
     * @param filters
     */
    private _checkChildChanges(filters: FilterCheckbox[]): boolean {
        let filterActiveCount: number = 0;
        let filterIndeterminateCount: number = 0;
        _.forEach(filters,
            (filter: FilterCheckbox) => {
                if (filter.children) {
                    filter.formControl.setValue(this._checkChildChanges(filter.children));
                }
                const value: boolean = filter.formControl.value;
                if (value) {
                    filterActiveCount++;
                } else if (value === null) {
                    filterIndeterminateCount++;
                }
            });
        if (filterActiveCount === filters.length) {
            return true;
        } else if (filterActiveCount === 0 && !filterIndeterminateCount) {
            return false;
        } else {
            return null;
        }
    }

    /**
     * Check if the options selected match a value from the quick list, otherwise set custom
     */
    private _checkQuickListMatch(): void {
        const allFilters = { ...this.advancedFilterForm.value, ...this.helperFormGroup.value };
        const filtersApplied: number = _.reduce(Object.keys(allFilters),
            (acc: number, key: string) => ((allFilters[key] === true) ? (acc += 1) : acc), 0);
        if (!filtersApplied) {
            this.selectedOption.setValue(this._dropdownOnOpen);
            return;
        }
        const outputModel: Compliance.AdvancedAssetFilters = this._createOutputModel();
        const option: Compliance.NameValuePair<Compliance.AdvancedAssetFilters> = _.find(this.selectOptions,
            (option) => _.isEqual(outputModel, option.value));
        this.selectedOption.setValue((option) ? option : this.selectOptions[this.selectOptions.length - 1]);
    }

    /**
     * Flatten the form controls for form groups
     * @param filterList
     * @param accumulator
     * @param isOutputControl
     */
    private _createFormControls(filterList: FilterCheckbox[], accumulator: DynamicFormControls, isOutputControl: boolean): DynamicFormControls {
        const result: DynamicFormControls = _.reduce(filterList, (acc, filter) => {
            if (filter.isOutputControl === isOutputControl) {
                acc[filter.formControlName] = filter.formControl;
            }
            if (filter.additionalSelect && filter.additionalSelect.isOutputControl === isOutputControl) {
                acc[filter.additionalSelect.formControlName] = filter.additionalSelect.formControl;
            }
            if (filter.children) {
                this._createFormControls(filter.children, acc, isOutputControl);
            }
            return acc;
        }, accumulator);
        return result;
    }

    /**
     * Format the output
     */
    private _createOutputModel(): Compliance.AdvancedAssetFilters {
        const formResults = this.advancedFilterForm.getRawValue() as Compliance.AdvancedAssetFilters;
        const fieldSelect = this.advancedFilterForm.get(AdvancedFormElements.FieldChanged);
        formResults[AdvancedFormElements.Changed] = !!formResults[AdvancedFormElements.Changed];
        formResults[AdvancedFormElements.FieldChanged] =
            (fieldSelect.disabled || (fieldSelect.enabled && (!fieldSelect.value || (fieldSelect.value && !fieldSelect.value.length))))
                ? null
                : this.advancedFilterForm.get(AdvancedFormElements.FieldChanged).value;

        const comparisonLienDateTypeFilter: Compliance.NameValuePair<Compliance.ComparisonLienDateTypeEnum> = this.comparisonLienDateTypeOption.value;
        formResults.comparisonLienDateType = comparisonLienDateTypeFilter.value;
        formResults.comparisonLienDate = this.comparisonLienDate;
        formResults.allAssetsAtLienDate = !!formResults.allAssetsAtLienDate;
        return formResults;
    }

    /**
     * Build the form groups
     */
    private _buildForm(): void {
        const helperFormControls: DynamicFormControls = this._createFormControls(this.assetFilters, {}, false);
        this.helperFormGroup = new UntypedFormGroup(helperFormControls);
        const advancedFormControls: DynamicFormControls = this._createFormControls(this.assetFilters, {}, true);
        this.advancedFilterForm = new UntypedFormGroup(advancedFormControls, { validators: this._validateForm() });
        this.helperFormGroup.get(AdvancedFormElements.SpecificField).valueChanges.pipe(takeUntil(this._destroy$)).subscribe(
            (checked: boolean) => {
                if (checked) {
                    this.fieldSelect.formControl.enable();
                } else {
                    this.fieldSelect.formControl.disable();
                    this.fieldSelect.formControl.updateValueAndValidity();
                }
            });
    }

    /**
     * Listen to child dropdown for changes
     */
    private _registerFormListeners(): void {
        this.fieldSelect.formControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(
            () => this._checkQuickListMatch());
    }

    /**
     * Validate that all form fields aren't filled out
     * */
    private _validateForm(): ValidatorFn {
        return (control: UntypedFormGroup): ValidationErrors | null => {
            const form = control.value;
            const active = Object.keys(form)
                                 .reduce((acc: number, key: string) => (form[key] === true || (Array.isArray(form[key]) && form[key].length)) ? (acc += 1) : acc, 0);
            const fieldSelectChecked = this.helperFormGroup.get(AdvancedFormElements.SpecificField).value;
            const fieldSelected = !fieldSelectChecked || (form[AdvancedFormElements.FieldChanged] && form[AdvancedFormElements.FieldChanged].length);
            this.disableSubmit = !(fieldSelected && active);
            this._helpService.updateContent([{
                helpContentId: 'asset-advanced.accept',
                tooltipText: (this.disableSubmit) ? 'Field change is checked but no fields were selected' : 'OK'
            }]);
            return (fieldSelected && active) ? null : { 'no-selection': true };
        };
    }

    private _validateComparisonDate() {
        const comparisonLienDateTypeFilter: Compliance.NameValuePair<Compliance.ComparisonLienDateTypeEnum> = this.comparisonLienDateTypeOption.value;
        var comparisonLienDateType = comparisonLienDateTypeFilter.value;

        if (comparisonLienDateType === Compliance.ComparisonLienDateTypeEnum.Custom && (!this.comparisonLienDate || (this.comparisonLienDate > this.lienDate))) {
            this.disableSubmit = true;
            this._helpService.updateContent([{
                helpContentId: 'asset-advanced.accept',
                tooltipText: 'Comparison date must be earlier than lien date'
            }]);
        } else {
            this.disableSubmit = false;
            this._helpService.updateContent([{ helpContentId: 'asset-advanced.accept', tooltipText: 'OK' }]);
        }
    }

}
