import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { BusyIndicatorService } from "../../../../Busy-Indicator";
import { BsModalRef } from "ngx-bootstrap/modal";
import { MessageModalService } from "../../../../UI-Lib/Message-Box/messageModal.service";
import { IWeissmanModalComponent } from "../../../../Compliance/WeissmanModalService";
import { BulkUpdateDynamicBase } from "../../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component";
import { AdvancedSearchListService } from "../advancedSearchList.service";
import { ToastrService } from 'ngx-toastr';

export interface AdvancedSearchBulkUpdateFieldInfo {
    fieldId: number;
    field: Core.AdvancedSearchField;
    depth: Core.AdvancedSearchField;
    descriptorPickList: Core.DescriptorPickListModel[];
    descriptor: Core.DescriptorDTO;
}

export interface FieldCollection {
    fields: { field: Core.AdvancedSearchField, depth: Core.AdvancedSearchField }[];
    fieldIds: number[];
}

export interface AdvancedSearchBulkUpdateParams {
    searchFields: Core.AdvancedSearchField[];
    selection: any[];
    selectedCount: number;
    fieldCollection: FieldCollection;
    fieldInfo: AdvancedSearchBulkUpdateFieldInfo[];
}

@Component({
    selector: 'advanced-search-bulk-update',
    templateUrl: '../../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component.html'
})
export class AdvancedSearchBulkUpdateComponent extends BulkUpdateDynamicBase<AdvancedSearchBulkUpdateParams, any> implements OnInit, IWeissmanModalComponent<AdvancedSearchBulkUpdateParams, any> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService,
        private readonly _advancedSearchListService: AdvancedSearchListService,
        private readonly _toastr: ToastrService,
        _bsModalRef: BsModalRef,
        _fb: UntypedFormBuilder,
    ) { super(_bsModalRef, _fb) }

    @ViewChild('text', { static: true }) textTemplate: ElementRef;
    @ViewChild('number', { static: true }) numberTemplate: ElementRef;
    @ViewChild('currency', { static: true }) currencyTemplate: ElementRef;
    @ViewChild('date', { static: true }) dateTemplate: ElementRef;
    @ViewChild('picklist', { static: true }) picklistTemplate: ElementRef;

    ngOnInit(): void {
        this.header = `Bulk Update ${this.params.selectedCount} Records`;

        this._templateMap = new Map([
            [Core.DescriptorFieldTypes.Date, this.dateTemplate],
            [Core.DescriptorFieldTypes.Number, this.numberTemplate],
            [Core.DescriptorFieldTypes.Picklist, this.picklistTemplate],
            [Core.DescriptorFieldTypes.YesNo, this.picklistTemplate],
            [Core.DescriptorFieldTypes.Text, this.textTemplate],
            [Core.DescriptorFieldTypes.Currency, this.currencyTemplate],
        ]);

        this.getUpdatableFields();
    }

    async save(force: boolean = false): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating' });

        const formValues = this.bulkUpdateForm.value;
        const changedDescriptors: Core.SmartDescriptorValueBulkUpdateModel[] = this.updateOptions
                .filter(x => x.descriptorId &&
                    !(x.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange))
                .reduce((acc, x) => {
                    const fieldInfo = this.params.fieldInfo.find(y => y.fieldId === +x.formControlName);
                    const entityIds = this.params.selection.reduce((acc, y) => {
                        const id = y[`n${fieldInfo.depth.advancedSearchFieldID}`];
                        if (acc.indexOf(id) === -1) {
                            acc.push(id);
                        }
                        return acc;
                    }, []);

                    acc.push({
                        action: x.action,
                        advancedSearchFieldId: fieldInfo.field.advancedSearchFieldID,
                        descriptorId: x.descriptorId,
                        entityIds,
                        value: (x.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove)
                            ? null
                            : formValues[x.formControlName]
                    });
                    return acc;
                }, [] as Core.SmartDescriptorValueBulkUpdateModel[]);

        const updateModel: Core.SmartBulkUpdateModel = {
            advancedSearchFieldIds: this.params.fieldInfo.map(x => x.descriptor.descriptorID),
            descriptorValues: changedDescriptors
        };

        try {
            await this._advancedSearchListService.bulkUpdateList(updateModel);

            this.result = this.params.selection.map(x => {
                const row = { ...x };
                changedDescriptors.forEach(y => {
                    row[`f${y.advancedSearchFieldId}`] = y.value;
                });
                return row;
            });

            this._bsModalRef.hide();
            return;
        } catch (err) {
            console.error(err.message);
            return Promise.reject();
        } finally {
            busyRef.hide();
        }
    }

    private async getUpdatableFields(): Promise<void> {
        this.isLoading = true;
        const ptxFields = ['TopLevelCompanyAssessmentTaxFeed','TopLevelCompanyTaxBillTaxFeed',
                            'ParcelAssessmentTaxFeed','ParcelTaxBillTaxFeed' ];
        // Retrieve the descriptors from the back end and populate a model with all the data aggregated into one place
        let descriptors: Core.SmartBulkUpdateMetadataModel;
        try {
            descriptors = await this._advancedSearchListService.getBulkUpdateDescriptors(this.params.fieldCollection.fieldIds);
            let dummyDescriptor: Core.DescriptorDTO = {
                descriptorID: -1,
                fieldTypeID: Core.DescriptorFieldTypes.YesNo,
                assessorUsageID: 0,
                collectorUsageID: 0,
                assetUsageID: 0,
                categoryID: 0,
                enabled: false,
                isSystemControlled: false,
                name: "",
                notes: "",
                parcelUsageID: 0,
                sequenceNumber: 0,
                siteUsageID: 0,
                validation: undefined,
                changeDate: undefined,
                changedBy: undefined,
                efAction: ""
            };
            this.params.fieldCollection.fields.forEach((x, i) => {
                if(ptxFields.includes(x.field.internalName)) {
                        this.params.fieldInfo.push({
                            fieldId: this.params.fieldCollection.fieldIds[i],
                            field: x.field,
                            depth: x.depth,
                            descriptor: dummyDescriptor,
                            descriptorPickList: null
                        });
                    } else {
                // Some descriptors are shared between parcel and site
                const descriptor = descriptors.descriptors.find(y => y.descriptorID === x.field.descriptorID);
                const descriptorPickList = descriptors.descriptorPickLists.filter(y => y.groupID === descriptor.pickListGroupID);
                this.params.fieldInfo.push({
                    fieldId: this.params.fieldCollection.fieldIds[i],
                    field: x.field,
                    depth: x.depth,
                    descriptor,
                    descriptorPickList
                });
            }});
        } catch (err) {
            console.error(err.message);
            this._toastr.error('There was an issue requesting the data required for bulk update');
            this.cancel();
            return;
        }

        this.params.fieldInfo.forEach(x => {
            const control = new UntypedFormControl(null, [this._validators.get(x.descriptor.fieldTypeID)(x.descriptor.validation)]);
            control.disable();
            this.bulkUpdateForm.addControl(`${x.fieldId}`, control);

            const option = {
                name: x.field.resultsColumnName,
                descriptorId: x.descriptor.descriptorID,
                isRequired: true,
                template: this._templateMap.get(x.descriptor.fieldTypeID),
                picklist: this._getPicklist(x),
                action: Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange,
                formControlName: `${x.fieldId}`,
                formControl: control,
                validation: x.descriptor.validation,
                hiddenField: x.descriptor.descriptorID === -1 ? Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove: null
            };
            this.updateOptions.push(option);
        });

        this.isLoading = false;
    }

    private _getPicklist(fieldInfo: AdvancedSearchBulkUpdateFieldInfo): Compliance.NameValuePair<any>[] {
        if (fieldInfo.descriptor.fieldTypeID === Core.DescriptorFieldTypes.Picklist) {
            return fieldInfo.descriptorPickList.map(p => ({ name: p.name, value: p.name }));
        }
        if (fieldInfo.descriptor.fieldTypeID === Core.DescriptorFieldTypes.YesNo) {
            return [
                { name: 'Yes', value: true },
                { name: 'No', value: false }
            ];
        }
    }
}
