<div class="input-group" aria-label="Select Attachment Type">
    <input id="selectAttachmentType"
           type="text"
           placeholder="Filter Attachment Types..."
           class="form-control exclude-modal-styling"
           [ngClass]="{ 'overridden': isOverridden }"
           autocomplete="off"
           (blur)="onBlur()"
           [(ngModel)]="filter"
           container="body"
           [typeahead]="values$"
           typeaheadOptionField="attachmentType"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)"
           [typeaheadOptionsLimit]="1000"/>
</div>
