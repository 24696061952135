import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver } from "@angular/core";
import { DashboardDataService } from "../dashboard.data.service";
import { UserWidget, RefreshPolicies } from "../dashboard.model";
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from "../../UI-Lib/Message-Box/messagebox.service.upgrade";
import { Subject, Observable } from "rxjs";
import { BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { WidgetComponentRegistryService } from "../widget.component.registry.service";
import { UnsubscribeMixin } from '../../UI-Lib/Mixins/unsubscribe.mixin';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'user-widget',
    template: `
        <div (mouseenter)="hovered=true" (mouseleave)="hovered=false" class="widget-container" [class.widget-design-mode]="designMode">
            <button *ngIf="designMode && userWidget.widget.hasSettings"
                class="icon-button flat-button"
                (click)="goToSettings()"
                tooltip="Settings"
                container="body"
                [delay]="500"
                style="position: absolute; left: 7px; top: 9px; z-index: 2;">
                <i class="fa fa-gear fa-lg"></i>
            </button>
            <button *ngIf="designMode"
                class="icon-button flat-button warning-button"
                (click)="confirmRemove()"
                tooltip="Remove"
                container="body"
                [delay]="500"
                style="position: absolute; right: 7px; top: 9px; z-index: 2;">
                <i class="fa fa-close fa-lg"></i>
            </button>
            <button *ngIf="hovered && !designMode && userWidget.widget.refreshPolicy !== RefreshPolicies.NoData"
                class="icon-button flat-button"
                style="position: absolute;"
                (click)="getWidgetData(widgetParams)"
                tooltip="Refresh"
                container="body"
                [delay]="500">
                <i class="fa fa-refresh"></i>
            </button>
            <i class="fa fa-spinner fa-pulse fa-3x" *ngIf="loading" style="position: absolute; top: 35%; left: 43%; z-index: 2;"></i>
            <div class="user-widget-wrapper" [class.opaque-widget]="loading || designMode" style="height: 100%;">
                <ng-template #widgetContainer></ng-template>
            </div>
        </div>
    `,
    styleUrls: ['dashboard-widget.scss']
})
export class UserWidgetComponent extends UnsubscribeMixin() implements OnInit {
    @Input() userWidget: UserWidget;
    @Input() designMode: boolean;
    @Output() onWidgetRemove: EventEmitter<number> = new EventEmitter();

    @ViewChild('widgetContainer', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;

    RefreshPolicies = RefreshPolicies;
    hovered: boolean = false;
    loading: boolean = false;
    widgetDataSource: Subject<any> = new Subject<any>();
    widgetParams: object = {};

    constructor(
        private messageBoxService: MessageBoxService,
        private dashboardDataService: DashboardDataService,
        private widgetComponentRegistryService: WidgetComponentRegistryService,
        private resolver: ComponentFactoryResolver,
        private modalService: BsModalService
    ) {
        super();
    }

    ngOnInit() {
        this.entry.clear();
        const factory = this.resolver.resolveComponentFactory<{
            data$: Observable<any>,
            onNewParams: EventEmitter<object>,
            userWidget: UserWidget
        }>(this.widgetComponentRegistryService.getWidgetComponentById(this.userWidget.widget.widgetId));
        const componentRef = this.entry.createComponent(factory);

        if(this.userWidget.widget.refreshPolicy !== RefreshPolicies.NoData) {
            componentRef.instance.data$ = this.widgetDataSource.asObservable();

            componentRef.instance.onNewParams.pipe(takeUntil(this.destroy$)).subscribe((widgetParams: object) => {
                this.getWidgetData(widgetParams);
            });

            componentRef.instance.userWidget = this.userWidget;
            this.getWidgetData();
        }
    }

    async getWidgetData(params?: object) {
        this.loading = true;

        try {
            const widgetCache = await this.dashboardDataService.getWidgetData(this.userWidget, params);

            this.widgetParams = widgetCache.params || {};
            this.widgetDataSource.next(widgetCache);
        } finally {
            this.loading = false;
        }

    }

    async confirmRemove(): Promise<void> {
        const result = await this.messageBoxService.open({
            message: 'Are you sure you want to remove this widget?',
            buttons: MessageBoxButtons.OKCancel
        })

        if (result === MessageBoxResult.OK) {
            this.onWidgetRemove.emit(this.userWidget.userWidgetId);
        }
    }

    goToSettings(): void {
        const registeredWidget = this.widgetComponentRegistryService.getRegisteredWidgetById(this.userWidget.widget.widgetId);
        if(!registeredWidget) {
            console.warn('could not find registered widget!');
            return;
        }

        let params: ModalOptions = {
            initialState: { userWidgetId: this.userWidget.userWidgetId }
        }

        if(!registeredWidget.smallerSettingsModal) {
            params.class = 'modal-lg';
        }

        const settingsModalRef = this.modalService.show(registeredWidget.settings, params);
        settingsModalRef.content.onClose = (shouldReload: boolean) => {
            if(shouldReload) {
                this.getWidgetData(this.widgetParams);
            }
        }
    }

}
