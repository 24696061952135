'use strict';

/**
 * @ngdoc directive
 * @name weissmanApp.directive:searchResultSite
 * @description
 * # searchResultSite
 */
angular.module('weissmanApp')
	.directive('searchResultSite', function () {
		return {
			templateUrl: 'app/Search/Quick/_quickResultSite.html',
			restrict: 'E',
			scope: {
				result: '='
			},
			link: function postLink(scope, element, attrs) {}
		};
	});
