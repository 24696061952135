import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactsRepository } from '../Core-Repositories';
import { BehaviorSubject, lastValueFrom, Observable, Subject } from 'rxjs';

import * as _ from 'lodash';

export interface ContactListSearchModel {
    entityId: number;
    entityType: string;
    excludeInactive: boolean;
    instanceId: number;
}

@Injectable(
    { providedIn: 'root' }
)
export class ContactsUpgradeService {
    constructor(
        private readonly _contactsRepository: ContactsRepository
    ) {}

    private _contactChangedSubject: Subject<any> = new Subject<any>();
    private _contactChanged$: Observable<any> = this._contactChangedSubject.asObservable();

    private _contactCacheSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private _contactCache$: Observable<any[]> = this._contactCacheSubject.asObservable();

    private _contacts: Weissman.Model.Contacts.Contact[] = [];
    private _currentSearchModel: ContactListSearchModel;
    private _dialogMode: boolean;

    set currentSearchModel(model: ContactListSearchModel) {
        this._currentSearchModel = model;
    }

    get currentSearchModel(): ContactListSearchModel {
        return this._currentSearchModel;
    }

    set dialogMode(isDialogMode: boolean) {
        this._dialogMode = isDialogMode;
    }

    get dialogMode(): boolean {
        return this._dialogMode;
    }

    get canGetRows(): boolean {
        const model = this.currentSearchModel;
        return !!model;
    }

    set contacts(contacts) {
        this._contacts = _.cloneDeep(contacts);
        this._contactCacheSubject.next(this._contacts);
    }

    get contacts() {
        return this._contacts;
    }

    get contactChanged$(): Observable<any> {
        return this._contactChanged$;
    }

    get contactCache$(): Observable<any[]> {
        return this._contactCache$;
    }

    contactChanged(contact: any): void {
        this._contactChangedSubject.next(contact);
    }

    get(pageNumber: number, pageSize: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.get(pageNumber, pageSize));
    }

    async getRowIds(searchModel: any): Promise<Compliance.QueryResultModel<number>> {
        return //await lastValueFrom(this._contactsRepository.getRowIds(searchModel));
    }

    getById(contactId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.getById(contactId));
    }

    getByIdScoped(contactId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.getByIdScoped(contactId));
    }

    getForSetup(contactId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.getForSetup(contactId));
    }

    getForSetupScoped(contactId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.getForSetupScoped(contactId));
    }

    getUserById(userId: System.Guid): Promise<any> {
        return lastValueFrom(this._contactsRepository.getUserById(userId));
    }

    getList(searchModel: Core.ContactSearchModel): Promise<Core.ContactLongDTO[]> {
        return lastValueFrom(this._contactsRepository.getList(searchModel));
    }

    search(instanceId: number, searchText: string, pageNumber: number, pageSize: number, excludeInactive: boolean): Promise<Core.ContactLongDTO[]> {
        let searchFragment = '';
        if (searchText || searchText.trim() !== '') {
            searchFragment = `&search=${encodeURIComponent(searchText)}`;
        }

        //endpoint supports sending specific instanceId or resolving selected instance(s) from the headers
        let instanceFragment = '';
        if (instanceId) {
            instanceFragment = `&instanceId=${instanceId}`;
        }

        return lastValueFrom(this._contactsRepository.search(pageNumber, pageSize, excludeInactive, searchFragment, instanceFragment));
    }

    searchScoped(entityId: number, entityType: string, searchText: string, pageNumber: number, pageSize: number, excludeInactive: boolean): Promise<Core.ContactLongDTO[]> {
        let searchFragment = '';
        if (searchText || searchText.trim() !== '') {
            searchFragment = `&search=${encodeURIComponent(searchText)}`;
        }
        return lastValueFrom(this._contactsRepository.searchScoped(entityId, entityType, pageNumber, pageSize, excludeInactive, searchFragment));
    }

    searchRyan(searchText: string, pageNumber: number, pageSize: number, excludeInactive: boolean): Promise<Core.ContactLongDTO[]> {
        let searchFragment = '';
        if (searchText || searchText.trim() !== '') {
            searchFragment = `&search=${encodeURIComponent(searchText)}`;
        }
        return lastValueFrom(this._contactsRepository.searchRyan(pageNumber, pageSize, excludeInactive, searchFragment));
    }

    searchForSignatureDelegates(contactId: number, searchText: string): Promise<any[]> {
        if (searchText || searchText.trim() !== '') {
            const searchFragment = `search=${encodeURIComponent(searchText)}`;
            return lastValueFrom(this._contactsRepository.searchForSignatureDelegates(contactId, searchFragment));
        }
        return Promise.resolve([]);
    }

    async getByEntity(entityId: number, entityType: string, showAll: boolean): Promise<Weissman.Model.Contacts.Contact[]> {
        this.contacts = await lastValueFrom(this._contactsRepository.getByEntity(entityId, entityType, showAll));
        return this._contacts;
    }

    //uses instance right for security check
    update(contact): Promise<Core.ContactLongDTO> {
        return lastValueFrom(this._contactsRepository.update(contact));
    }

    //uses entity permission for security check
    updateScoped(contact): Promise<Core.ContactLongDTO> {
        return lastValueFrom(this._contactsRepository.updateScoped(contact));
    }

    //uses instance right for security check
    async deleteContact(contactId: number): Promise<void> {
        try {
            await lastValueFrom(this._contactsRepository.deleteContact(contactId));
            this._contacts = this._contacts.filter(x => x.contactID !== contactId);
            this._contactCacheSubject.next(this._contacts);
        } catch(err) {
            throw err;
        }
    }

    //uses entity permission for security check
    deleteContactScoped(contactId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.deleteContactScoped(contactId));
    }

    async create(contact): Promise<any> {
        const newContact = await lastValueFrom(this._contactsRepository.create(contact));
        this._contacts.push(newContact);
        this.contacts = this._contacts;
        return newContact;
    }

    getContactSignature(userId: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.getContactSignature(userId));
    }

    saveContactSignature(contactId: number, signatureBase64: string): Promise<any> {
        return lastValueFrom(this._contactsRepository.saveContactSignature(contactId, signatureBase64));
    }

    getAvailableRoles(roles, contact, entityInfo) {
        // using local data - no trips to the server.
        return roles.filter(role => {
            if (!role[entityInfo.type]) {
                return false;
            }

            if (!contact.contactRoleAssociations) {
                contact.contactRoleAssociations = [];
            }
            // This used to filter out the roles already being used but now that is handled in the picklist component
            // so we need to keep all of them for this entityInfo type
            return true;
        });
    }

    getCached() {
        return this._contacts;
    }

    addToCache(contact): void {
        this._contacts.push(contact);
        this.contacts = this._contacts;
    }

    updateContactInCache(contact): void {
        const index = this._contacts.findIndex(x => x.contactID === contact.contactID);
        if (index !== -1) {
            this._contacts[index] = contact;
        }
        this.contacts = this._contacts;
    }

    createUser(contactId: number, user): Promise<any> {
        return lastValueFrom(this._contactsRepository.createUser(contactId, user));
    }

    addContactRoleAssociation(data): Promise<any> {
        return lastValueFrom(this._contactsRepository.addContactRoleAssociation(data));
    }

    deleteContactRoleAssociation(id: number): Promise<any> {
        return lastValueFrom(this._contactsRepository.deleteContactRoleAssociation(id));
    }

    exportList(searchModel: Core.ContactsExportModel): Promise<number> {
        return lastValueFrom(this._contactsRepository.exportList(searchModel));
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        return this._contactsRepository.getExport(longRunningProcessId);
    }
}
