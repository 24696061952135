import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';
import { ImportSpecificationRepository } from '../../Repositories';

export interface ImportSpecificationListDataSourceParams {
    excludeReadOnly: boolean;
    includeDisabled: boolean;
    companyId: number;
}

export class ImportSpecificationListAgGridDataSource extends AgGridDataSourceBase<ImportSpecificationListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _importSpecificationRepository: ImportSpecificationRepository,
        protected _dataSourceParamsFn: () => ImportSpecificationListDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ImportFileSpecificationPropertyEnum>[] = [
        { name: 'displayName', value: Compliance.ImportFileSpecificationPropertyEnum.DisplayName },
        { name: 'companyName', value: Compliance.ImportFileSpecificationPropertyEnum.CompanyName },
        { name: 'visibility', value: Compliance.ImportFileSpecificationPropertyEnum.Visibility },
        { name: 'importContentTypeName', value: Compliance.ImportFileSpecificationPropertyEnum.ImportContentTypeName },
        { name: 'ownerName', value: Compliance.ImportFileSpecificationPropertyEnum.OwnerName },
        { name: 'hasHeaders', value: Compliance.ImportFileSpecificationPropertyEnum.HasHeaders },
        { name: 'delimiterName', value: Compliance.ImportFileSpecificationPropertyEnum.DelimiterName },
        { name: 'isDisabled', value: Compliance.ImportFileSpecificationPropertyEnum.IsDisabled },
        { name: 'fileType', value: Compliance.ImportFileSpecificationPropertyEnum.FileType }
    ];

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.ImportFileSpecificationSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            excludeReadOnly: this._dataSourceParams.excludeReadOnly,
            includeDisabled: this._dataSourceParams.includeDisabled,
            companyId: this._dataSourceParams.companyId
        };

        let result = await lastValueFrom(this._importSpecificationRepository.getList(searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}

