import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { of as observableOf, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

export type Predicate = (...args: any) => boolean;
export type HigherOrderFn<T> = (...args: any) => T;

@Component({
    selector: 'typeahead-selector',
    templateUrl: './typeaheadSelector.component.html',
    styleUrls: ['./typeaheadSelector.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TypeaheadSelectorComponent,
        multi: true
    }]
})
export class TypeaheadSelectorComponent implements ControlValueAccessor {

    @Input() set values(values: any[]) {
        this._allValues = values || [];
        this._select(this.value);
    }
    @Input() labelProperty: string = 'label';
    @Input() valueProperty: string = 'value';
    @Input() sortProperty: string;
    @Input() placeholder: string = '';
    @Input() isOverridden: boolean = false;
    @Input() canReset: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() originalValue: string;
    @Input() filterFn: HigherOrderFn<Predicate>;
    @Input() findFn: HigherOrderFn<Predicate>;

    @Output() changed = new EventEmitter<string>();

    disabled: boolean;
    value: string = '';
    filter: string = '';

    private _allValues: any[] = [];

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    get showReset(): boolean {
        return this.canReset && !this.disabled && (this.originalValue && this.filter !== this.originalValue) || this.isOverridden;
    }

    values$: Observable<any[]> = (Observable
        .create((observer: any) => { observer.next(this.filter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filter(token)));

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    writeValue(value: string): void {
        this._select(value);
    }

    onBlur(): void {
        this._select(this.filter);
        this._onTouchedFn && this._onTouchedFn();
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const selected = match.item;
            const value = _.get(selected, this.valueProperty);
            this._select(value);
            this.changed.emit(value);
        }
    }

    resetToOriginalValue(): void {
        this.value = null;
        this.filter = this.originalValue;
        this.changed.emit(null);
    }

    private _filter(filter: string): Observable<any[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }
        const filterFn = this.filterFn(filter);
        const result = (this._allValues || []).filter(filterFn);
        if (this.sortProperty) {
            result.sort((a, b) => `${a[this.sortProperty]}`.localeCompare(`${b[this.sortProperty]}`));
        }
        return observableOf(result);
    }

    private _select(value: string): void {
        this.value = value;

        let item;
        if (this.findFn) {
            const findFn = this.findFn(value);
            item = this._allValues.find(findFn);
        } else {
            item = this._allValues.find((x) => _.get(x, this.valueProperty) === value);
        }

        if (item) {
            this.filter = value;
        } else if (value === '') {
            this.filter = null;
        } else {
            this.filter = value;
        }
    }
}
