<div class="border d-flex flex-nowrap h-100 rounded w-100">
    <div class="side-bar d-flex flex-column border-end">
        <div class="header px-2 border-bottom d-flex align-items-center">
            <span class="text-nowrap m-0">{{ userPageConfig?.tabTitle }} ({{ categoryTotal }})</span>
        </div>
        <div class="scroll-container d-flex flex-column flex-grow-1">
            <div class="d-flex" *ngFor="let category of categories">
                <div class="option" [class.active]="category === selectedCategory"
                        (click)="selectCategory(category)">
                    <span class="content">{{ category.categoryName }} ({{ category.savedItemList.length }})</span>
                </div>
                <ws-ace-dropdown *ngIf="!(selectedFilter === searchCategoryTypes.Favorite) && category.savedItemList.length === 0"
                                 class="d-inline"
                                 size="small"
                                 icon="more-outline">
                    <ng-container *ngFor="let option of categoryMenuOptions">
                        <button type="button"
                                class="option"
                                (click)="option.onClick(category)">
                            <span class="content">{{option.name}}</span>
                        </button>
                    </ng-container>
                </ws-ace-dropdown>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column w-100">
        <div class="scroll-container d-flex flex-column flex-grow-1">
            <table class="table">
                <thead>
                    <th *ngFor="let col of columns" class="px-4 border-bottom ws-vertical-align-middle">
                        <span class="m-0 p-0">{{ col.name }}</span>
                    </th>
                    <th class="border-bottom"></th>
                </thead>
                <tbody>
                <tr *ngFor="let row of selectedCategory?.savedItemList; index as i" (mouseenter)="hoveredRow = row" (mouseleave)="hoveredRow = null">
                    <td *ngFor="let col of columns"
                        (click)="selectRow(row)"
                        class="px-4 py-0 border-0 ws-vertical-align-middle clickable">
                        <div class="cell-contents d-flex align-items-center">
                            <span *ngIf="col.type === columnType.String">{{ row[col.key] }}</span>
                            <span *ngIf="col.type === columnType.Date">{{ row[col.key] | weissmanDateFormat}}</span>
                        </div>
                    </td>
                    <td class="px-4 py-0 border-0">
                        <div class="row-actions">
                            <ws-ace-button *ngIf="row.canFavorite"
                                           [iconFont]="row.isFavorite ? 'fa-solid fa-star' : 'fa-regular fa-star'"
                                           buttonStyle="text"
                                           state="action"
                                           size="small"
                                           [isDisabled]="hoveredRow !== row"
                                           (buttonClick)="toggleFavorite(row)">
                            </ws-ace-button>
                            <ws-ace-dropdown *ngIf="hasMenuOptions(selectedCategory, i, row)"
                                             class="d-inline"
                                             [icon]="(!loadingRowMenu ? 'more-outline' : 'spinner')"
                                             size="small"
                                             [isLoading]="loadingRowMenu"
                                             [isDisabled]="hoveredRow !== row || loadingRowMenu"
                                             (openChange)="getMenuOptions($event, selectedCategory, i)">
                                <ng-container *ngFor="let option of rowMenuOptions">
                                    <button *ngIf="!option.isDisabled(row)"
                                            type="button"
                                            class="option"
                                            (click)="option.onClick(row, selectedCategory.categoryType)">
                                        <span class="content">{{option.name}}</span>
                                    </button>
                                </ng-container>
                            </ws-ace-dropdown>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

