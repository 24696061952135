import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddressDetailsModalComponent } from "./address.details.modal.component";
import { AddressDetailsModalService } from "./address.details.modal.service";
import { EntityAddress } from "./address.model";

@Injectable(
    {providedIn: 'root'}
)
export class AddressDetailsModalLaunchService {
    customTemplateModalRef: BsModalRef;

    constructor(
        private modalService: BsModalService,
        private readonly addressDetailsModalService: AddressDetailsModalService
    ) { }

    openAddressDetailsModal(entityID: number, entityTypeID: number, entityAddresses: EntityAddress[], isAbleToBeEdited: boolean, justSelect?: boolean, checkDeliverability?: boolean): Promise<EntityAddress[]> {
        let promise = new Promise<EntityAddress[]>((resolve) => {
            const initialState = {
                entityID: entityID,
                entityTypeID: entityTypeID,
                entityAddresses: entityAddresses,
                isAbleToBeEdited: isAbleToBeEdited,
                justSelect: justSelect,
                checkDeliverability: checkDeliverability
            };

            let modalRef = this.modalService.show(AddressDetailsModalComponent, {initialState, class: 'modal-change-history'})

            modalRef.content.onClose = (entityAddresses: EntityAddress[]) => {
                resolve(entityAddresses);
            }
        })

        return promise;
    }
}
