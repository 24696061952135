import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AddCompanyBudgetModalComponent } from './add.company.budget.modal.component';
import { ForecastBudgetYearCellEditorComponent } from './agGridForecastBudgetYearCellEditor.component';
import { ForecastBudgetYearCellRendererComponent } from './agGridForecastBudgetYearCellRender.component';
import { AgGridSwitchCellRendererComponent } from './agGridSwitchCellRenderer.component';
import { BudgetTaxYearCreateMissingComponent } from './budget.taxyear.create.missing.component';
import { BudgetTaxYearSyncBudgetsComponent } from './budget.taxyear.sync.budgets.component';
import { ForecastCreateMissingComponent } from './forecast.create.missing.component';
import { ForecastingBudgetingBulkUpdateModalComponent } from './forecasting.budgeting.bulk.update.modal.component';
import { ForecastingBudgetingPageComponent } from './forecasting.budgeting.page.component';

@NgModule({
    imports: [
        WeissmanSharedModule
    ],
    declarations: [
        ForecastingBudgetingPageComponent,
        ForecastingBudgetingBulkUpdateModalComponent,
        ForecastCreateMissingComponent,
        BudgetTaxYearCreateMissingComponent,
        BudgetTaxYearSyncBudgetsComponent,
        AddCompanyBudgetModalComponent,
        ForecastBudgetYearCellRendererComponent,
        ForecastBudgetYearCellEditorComponent,
        AgGridSwitchCellRendererComponent
    ],
    exports: [
        ForecastingBudgetingPageComponent
    ]
})
export class BudgetModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('forecastingBudgetingPage', ForecastingBudgetingPageComponent);
    }
}
