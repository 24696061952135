import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { MessageModalService } from '../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { ConsultingEngagementModalComponent } from './consultingEngagementModal.component';
import { ConsultingEngagementsRepository } from './consultingEngagements.repository';

export interface ConsultingEngagementModalParams {
    engagement: Core.ConsultingEngagementResponse
}

@Component({
    selector: 'consulting-engagements',
    templateUrl: './consultingEngagements.component.html',
    styleUrls: ['./consultingEngagements.component.scss']
})

export class ConsultingEngagementsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _consultingEngagementsRepository: ConsultingEngagementsRepository,
        private readonly _modalService : WeissmanModalService,
        private readonly _messageModalService : MessageModalService,
        private _toastrService: ToastrService) {
    }

    params: ConsultingEngagementModalParams;
    result: any;
    isLoading: boolean = true;

    engagements: Core.ConsultingEngagementResponse[] = [];
    changesMade: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        this.engagements = await this._consultingEngagementsRepository.getList();

        this.engagements.sort((x, y) => x.name.localeCompare(y.name));

        this.isLoading = false;
    }

    async openEngagementModal(engagement) {
        const params: ConsultingEngagementModalParams = {engagement: engagement};
        await this._modalService.showAsync(ConsultingEngagementModalComponent, params, 'modal-md');
        this.engagements = await this._consultingEngagementsRepository.getList();
    }

    async rejectInvitation(engagement): Promise<void> {

        try {
            await this._messageModalService.confirm(`Are you sure you want to reject the consulting engagement invitation from ${engagement.name}?`);
        }
        catch (e) {
            return Promise.resolve();
        }
        this.isLoading = true;

        await this._consultingEngagementsRepository.delete(engagement.consultingEngagementId);
        this._toastrService.success(`Consulting engagement invitation from ${engagement.name} rejected.`);
        this.engagements = await this._consultingEngagementsRepository.getList();

        this.isLoading = false;
    }

    async cancelEngagement(engagement): Promise<void> {

        try {
            await this._messageModalService.confirm(`Are you sure you want to cancel the consulting engagement with  ${engagement.name}?`);
        }
        catch (e) {
            return Promise.resolve();
        }
        this.isLoading = true;

        try {
            await this._consultingEngagementsRepository.delete(engagement.consultingEngagementId);
            this._toastrService.success(`Consulting engagement with  ${engagement.name} cancelled.`);
            this.engagements = await this._consultingEngagementsRepository.getList();
        } finally {
            this.isLoading = false;
        }
    }


    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    // async acceptInvitation(contactId: number): Promise<void> {
    //     const busyRef = this._busyIndicatorService.show({ message: 'Accepting Invitation' });
    //     try {
    //         var success = await this._instanceRepository.acceptInvite(contactId);
    //         if(success)
    //             this._toastrService.success(`Invitation accepted.`);
    //             this.statuses = await this._instanceRepository.getCurrentUsersContactInviteStatuses();
    //             this.changesMade = true;
    //     } finally {
    //         busyRef.hide();
    //     }
    // }
}
