import { Injectable } from '@angular/core';
import { BaseRepository } from '../Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ActivityStatusService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get(): Promise<any> {
        return lastValueFrom(this.httpGet('/api/activitystatus/'));
    }

}

