import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class ContactsRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/contact/';
    private readonly ASSOCIATION_SERVICE_URL = '/api/ContactRoleAssociation/';

    get(pageNumber: number, pageSize: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    }

    getById(contactId: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${contactId}`);
    }

    getByIdScoped(contactId: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${contactId}/scoped`);
    }

    getForSetup(contactId: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${contactId}/setup`);
    }

    getForSetupScoped(contactId: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${contactId}/setup/scoped`);
    }

    getUserById(userid: System.Guid): Observable<Core.ContactModel> {
        return this.httpGet(this.SERVICE_URL, { userid });
    }

    getList(searchModel: Core.ContactSearchModel): Observable<Core.ContactLongDTO[]> {
        return this.httpPost(`${this.SERVICE_URL}contacts`, searchModel);
    }

    search(pageNumber: number, pageSize: number, excludeInactive: boolean, searchFragment: string, instanceFragment: string): Observable<Core.ContactLongDTO[]> {
        return this.httpGet(`${this.SERVICE_URL}?pageNumber=${pageNumber}&pageSize=${pageSize}&excludeInactive=${excludeInactive}${searchFragment}${instanceFragment}`);
    }

    searchScoped(entityId: number, entityType, pageNumber: number, pageSize: number, excludeInactive: boolean, searchFragment: string): Observable<Core.ContactLongDTO[]> {
        return this.httpGet(`${this.SERVICE_URL}${entityType}/${entityId}/scoped?pageNumber=${pageNumber}&pageSize=${pageSize}&excludeInactive=${excludeInactive}${searchFragment}`);
    }

    searchRyan(pageNumber: number, pageSize: number, excludeInactive: boolean, searchFragment: string): Observable<Core.ContactLongDTO[]> {
        return this.httpGet(`${this.SERVICE_URL}ryan?pageNumber=${pageNumber}&pageSize=${pageSize}&excludeInactive=${excludeInactive}${searchFragment}`);
    }

    searchForSignatureDelegates(contactId: number, searchFragment: string): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${contactId}/signature/search-for-delegates?${searchFragment}`);
    }

    getByEntity(entityId: number, entityType, showAll: boolean): Observable<any> {
        if (showAll) {
            return this.httpGet(`${this.SERVICE_URL}${entityType}/${entityId}`);
        } else {
            //This is really just for company and allows us to hide unassoicated contacts
            //using a different URI for this, which will call the same logic in the api
            //just using a bool to control that bit
            return this.httpGet(`${this.SERVICE_URL}${entityType}/${entityId}/filtered`);
        }
    }

    //uses instance right for security check
    update(contact): Observable<any> {
        return this.httpPut(this.SERVICE_URL, contact);
    }

    //uses entity permission for security check
    updateScoped(contact): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL}scoped`, contact);
    }


    //uses instance right for security check
    deleteContact(contactId: number): Observable<any> {
        return this.httpDelete(`${this.SERVICE_URL}${contactId}`);
    }

    //uses entity permission for security check
    deleteContactScoped(contactId: number): Observable<any> {
        return this.httpDelete(`${this.SERVICE_URL}${contactId}/scoped`);
    }

    create(contact): Observable<any> {
        return this.httpPost(this.SERVICE_URL, contact);
    }

    getContactSignature(userId: number): Observable<any> {
        return this.httpGet(this.SERVICE_URL + userId + '/Signature');
    }

    saveContactSignature(contactId: number, signatureBase64): Observable<any> {
        const dto = {
            contactId: contactId,
            signatureBase64 : signatureBase64
        }
        return this.httpPost(this.SERVICE_URL + 'Signature/', dto);
    }

    createUser(contactId: number, user): Observable<any> {
        return this.httpPost(`${this.SERVICE_URL}${contactId}/user`,user);
    }

    addContactRoleAssociation(data): Observable<any> {
        return this.httpPost(this.ASSOCIATION_SERVICE_URL, data);
    }

    deleteContactRoleAssociation(id: number): Observable<any> {
        return this.httpDelete(`${this.ASSOCIATION_SERVICE_URL}${id}`);
    }

    exportList(model: Core.ContactsExportModel): Observable<number> {
        return this.httpPost(`${this.SERVICE_URL}export`, model);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this.SERVICE_URL}export/${longRunningProcessId}`, options);
    }
}
