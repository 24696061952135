import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportDetail } from '../../Reporting/Manager/report.manager.model';

class FormSearchModelImpl implements Compliance.FormSearchModel {
    stateId: number;
    formTypeId: number;
    skip: number;
    take: number;

    constructor(private readonly _stateId: number, private readonly _formTypeId) {
        this.stateId = this._stateId;
        if(_formTypeId) {
            this.formTypeId = _formTypeId;
        }
    }
}

@Injectable()
export class FormRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    createNextTaxYear(formRevisionId: number, taxYear: number): Observable<Compliance.FormRevisionYearModel>{
        return this.httpPost(`/api/formRevision/${formRevisionId}/year/${taxYear}`, null);
    }

    getByAvailableToCopyTo(formRevisionYearId: number): Observable<Compliance.FormRevisionYearCopyModel[]> {
        return this.httpGet(`/api/formRevision/year/${formRevisionYearId}/copy`, null);
    }

    copyTo(sourceFormRevisionYearId: number, targetFormRevisionYearId: number): Observable<void> {
        return this.httpPost(`/api/formRevision/year/${sourceFormRevisionYearId}/copy/${targetFormRevisionYearId}`, null);
    }

    deleteTaxYear(formRevisionYearId: number): Observable<void> {
        return this.httpDelete(`/api/formRevision/year/${formRevisionYearId}`);
    }

    getFormClassificationMappings(formRevisionYearAssessorId: number): Observable<Compliance.FormClassificationMappingModel[]> {
        return this.httpGet(`/api/formclassificationmapping/frya/${formRevisionYearAssessorId}`);
    }

    exportFormClassificationMappings(formRevisionYearAssessorId: number): Observable<number> {
        return this.httpPost(`/api/formclassificationmapping/frya/${formRevisionYearAssessorId}/export`, null);
    }

    getFormClassificationMappingsExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpGet(`/api/formclassificationmapping/export/${longRunningProcessId}`, options);
    }

    getAssessors(formRevisionId: number, taxYear: number): Observable<Compliance.FormRevisionAssessorModel[]> {
        const searchModel = { taxYear: taxYear } as Compliance.FormRevisionAssessorSearchModel;

        return this.httpPost(`/api/formRevision/${formRevisionId}/assessors`, searchModel).pipe(
            map((response: Compliance.QueryResultModel<Compliance.FormRevisionAssessorModel>) => {
                return response.data;
            }));
    }

    getForms(stateId, formTypeId, skip, take): Observable<Compliance.QueryResultModel<Compliance.FormModel>> {
        const formSearchModel: Compliance.FormSearchModel = new FormSearchModelImpl(stateId, formTypeId);

        if (skip) {
            formSearchModel.pagination = { skip: skip, take: take } as Core.PaginationModel;
        }
        return this.httpPost(`/api/form/search`, formSearchModel);
    }

    createForm(model: Compliance.FormCreateModel): Observable<Compliance.FormModel> {
        return this.httpPost(`/api/form`, model);
    }

    deleteForm(formId: number, force: boolean): Observable<any> {
        return this.httpDelete(`/api/form/${formId}?force=${force}`);
    }

    getRevision(formRevisionId: number): Observable<Compliance.FormRevisionModel> {
        return this.httpGet(`/api/formRevision/${formRevisionId}`);
    }

    deleteRevision(revisionId: number, force: boolean): Observable<any> {
        return this.httpDelete(`/api/formRevision/${revisionId}?force=${force}`);
    }

    createRevisionSupplementalForm(model: Compliance.FormRevisionCreateModel): Observable<Compliance.FormRevisionModel> {
        return this.httpPost(`/api/formRevision`, model);
    }

    updateForm(id: number, model: Compliance.FormUpdateModel): Observable<void> {
        return this.httpPut(`/api/form/${id}`, model);
    }

    updateFormRevision(id: number, model: Compliance.FormRevisionUpdateModel, force: boolean): Observable<void> {
        return this.httpPut(`/api/formRevision/${id}?force=${force}`, model);
    }

    associateAssessors(formRevisionId: number, model: Compliance.FormRevisionAssessorAssociationModel): Observable<Compliance.FormRevisionAssessorModel[]> {
        return this.httpPost(`/api/formRevision/${formRevisionId}/assessors/associate`, model);
    }

    disassociateAssessors(formRevisionId, formRevisionYearAssessorIds: number[], force: boolean): Observable<void> {
        return this.httpPost(`/api/formRevision/${formRevisionId}/disassociate?force=${force}`, formRevisionYearAssessorIds);
    }

    assessorChangeIsCertified(formRevisionYearAssessorId: number, isCertified: boolean): Observable<Compliance.FormRevisionAssessorModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearAssessorId}/iscertified`, `"${isCertified}"`);
    }

    assessorChangeUseDefaultTables(formRevisionYearAssessorId: number, useDefaultTables: boolean): Observable<Compliance.FormRevisionAssessorModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearAssessorId}/defaulttables`, `"${useDefaultTables}"`);
    }

    assessorChangeUseDefaultMappings(formRevisionYearAssessorId: number, useDefaultMappings: boolean): Observable<Compliance.FormRevisionAssessorModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearAssessorId}/defaultmappings`, `"${useDefaultMappings}"`);
    }

    assessorChangeUsePropertyType(formRevisionYearAssessorId: number, usePropertyType: boolean, field: string): Observable<Compliance.FormRevisionAssessorModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearAssessorId}/${field}`, `"${usePropertyType}"`);
    }

    updateIsPrimary(formRevisionYearId: number, isPrimary: boolean, force: boolean): Observable<Compliance.FormRevisionYearModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearId}/primary?force=${force}`, `"${isPrimary}"`);
    }

    updateIsInDevelopment(formRevisionYearId: number, isInDevelopment: boolean): Observable<Compliance.FormRevisionYearModel> {
        return this.httpPatch(`/api/formRevision/${formRevisionYearId}/inDevelopment`, `"${isInDevelopment}"`);
    }

    getSchedulesForRevision(formRevisionId: number): Observable<Compliance.FormRevisionScheduleModel[]> {
        return this.httpGet(`/api/formRevisionSchedule/revision/${formRevisionId}`);
    }

    updateSchedules(formRevisionId: number, taxYear: number, schedules: Compliance.FormRevisionScheduleModel[], force: boolean): Observable<Compliance.FormRevisionScheduleUpdateResultModel> {
        const data: Compliance.FormRevisionScheduleUpdateModel = {
            formRevisionId,
            taxYear,
            schedules,
            force
        };

        return this.httpPost('/api/formRevisionSchedule/bulk', data);
    }

    getFactors(formRevisionYearAssessorId: number): Observable<Compliance.FormFactorTableModel[]> {
        return this.httpGet(`/api/formfactortable/frya/${formRevisionYearAssessorId}`);
    }

    getFactorTableList(searchModel: Compliance.FactorTableSearchModel): Observable<Compliance.FactorTableListItemModel[]> {
        return this.httpPost(`/api/factortable/search`, searchModel);
    }

    updateFactorTableList(formRevisionYearAssessorId: number, updateModel: Compliance.FormRevisionFactorTableUpdateModel): Observable<any> {
        return this.httpPut(`/api/formFactorTable/frya/${formRevisionYearAssessorId}`, updateModel);
    }

    saveClassificationMappings(formRevisionYearAssessorId:number, mappings: Compliance.FormClassificationMappingModel[]):Observable<Compliance.FormClassificationMappingModel> {
        return this.httpPut(`/api/formclassificationmapping/frya/${formRevisionYearAssessorId}`, mappings);
    }

    getFilingBatches(formRevisionYearAssessorId: number): Observable<Compliance.FilingBatchInfoModel[]> {
        return this.httpGet(`/api/formRevision/${formRevisionYearAssessorId}/filingBatches`);
    }

    getDataSourceFieldInfo(): Observable<Compliance.ReturnFormFieldInfoModel[]> {
        return this.httpGet(`/api/formRevision/fields`);
    }

    getReports(): Observable<Core.SavedSearchModel[]> {
        return this.httpGet('/api/complianceReport');
    }

    updateReports(formRevisionId: number, reports: Compliance.FormRevisionReportModel[]): Observable<Compliance.FormRevisionReportModel[]> {
        return this.httpPost(`/api/formRevisionReport/revision/${formRevisionId}`, reports);
    }

    updateAppealLevel(formRevisionId: number, appealLevelId: number): Observable<void> {
        return this.httpPatch(`/api/formRevision/${formRevisionId}/appeallevel`, appealLevelId);
    }

    saveCustomReport(formRevisionId: number, reportId: number, report: ReportDetail): Observable<Compliance.FormRevisionReportModel>{
        const reportObject = {... report} as any;
        reportObject['criteria'] = JSON.stringify(report.criteria);
        return this.httpPost(`/api/formRevisionReport/revision/${formRevisionId}/saveCustomReport/${reportId}`,
            reportObject);
    }

    updateTemplate(file: File, metadata: Compliance.ReturnFormTemplateModel): Observable<Compliance.ReturnFormTemplateValidationResultModel> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileMetadata', JSON.stringify(metadata));

        return this.httpPut(`/api/formRevision/${metadata.formRevisionId}/template`, formData, {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        });
    }

    getTemplate(formRevisionId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/formRevision/${formRevisionId}/template`, options);
    }

    previewTemplateEdit(formRevisionId: number, data: Compliance.FormRevisionTemplateEditModel): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpPost(`/api/formRevision/${formRevisionId}/template/previewEdit`, data, options);
    }

    previewTemplateEditMetadata(formRevisionId: number, data: Compliance.FormRevisionTemplateEditModel): Observable<Compliance.FormRevisionTemplateMetadataModel> {
        return this.httpPost(`/api/formRevision/${formRevisionId}/template/previewEditMetaData`, data, null);
    }

    applyTemplateEdit(formRevisionId: number, data: Compliance.FormRevisionTemplateEditModel): Observable<Compliance.ReturnFormTemplateValidationResultModel> {
        return this.httpPost(`/api/formRevision/${formRevisionId}/template/applyEdit`, data, null);
    }

    getTemplateMetadata(formRevisionId: number): Observable<Compliance.FormRevisionTemplateMetadataModel> {
        return this.httpGet(`/api/formRevision/${formRevisionId}/template/metadata`);
    }

    getTemplatePageCount(formRevisionId: number): Observable<number> {
        return this.httpGet(`/api/formRevision/${formRevisionId}/template/pageCount`);
    }

    validateTemplate(formRevisionId: number): Observable<Compliance.ReturnFormTemplateValidationResultModel> {
        return this.httpGet(`/api/formRevision/${formRevisionId}/template/validate`);
    }

    deleteTemplate(formRevisionId: number): Observable<Compliance.FormRevisionModel> {
        return this.httpDelete(`/api/formRevision/${formRevisionId}/template`);
    }

    getAssessorAddresses(formRevisionYearAssessorId: number): Observable<Compliance.FormRevisionYearAssessorAddressModel[]> {
        return this.httpGet(`/api/formRevision/${formRevisionYearAssessorId}/assessorAddresses`);
    }

    updateAssessorAddress(formRevisionYearAssessorId: number, addressId: number): Observable<Compliance.FormRevisionAssessorModel> {
        return this.httpPut(`/api/formRevision/${formRevisionYearAssessorId}/assessoraddress`, addressId);
    }

    getStampLocations(): Observable<Compliance.FormRevisionStampLocationModel[]> {
        return this.httpGet('/api/formRevision/stampLocations');
    }

    exportFormList(model: Compliance.ExportFormListModel): Observable<number> {
        return this.httpPost('/api/formRevision/exportFormList', model);
    }

    getExportFormList(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/formRevision/exportFormList/${longRunningProcessId}`, options);
    }

    exportFormLibrary(formRevisionIds: number[]): Observable<number> {
        return this.httpPut('/api/formRevision/export', formRevisionIds);
    }
}
