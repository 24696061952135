import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { ActionViewCategoryComponent } from './Category/actionViewCategory.component';
import { ReportCategoryComponent } from './Category/reportCategory.component';
import { SearchCategoryComponent } from './Category/searchCategory.component';
import { ActionViewDefaultDisplayComponent } from './Default-Display/actionViewDefaultDisplay.component';
import { SearchDefaultDisplayComponent } from './Default-Display/searchDefaultDisplay.component';
import { ActionViewRenameComponent } from './Rename/actionViewRename.component';
import { ReportRenameComponent } from './Rename/reportRename.component';
import { SearchRenameComponent } from './Rename/searchRename.component';

@NgModule({
    imports: [WeissmanSharedModule],
    declarations: [
        ActionViewRenameComponent,
        ReportRenameComponent,
        SearchRenameComponent,
        ActionViewDefaultDisplayComponent,
        SearchDefaultDisplayComponent,
        ActionViewCategoryComponent,
        ReportCategoryComponent,
        SearchCategoryComponent
    ],
    exports: [
        ActionViewRenameComponent,
        ReportRenameComponent,
        SearchRenameComponent,
        ActionViewDefaultDisplayComponent,
        SearchDefaultDisplayComponent,
        ActionViewCategoryComponent,
        ReportCategoryComponent,
        SearchCategoryComponent
    ]
})
export class NavigationModalsModule {
}
