<ws-modal-wrapper headerLabel="Site Links"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!rowsSelected">
    <div class="app-ui-view ws-flex-container-vertical ws-flex-auto ws-stretch">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                         (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
