<div class="modal fade" bsModal #addAppealModal="bs-modal" [config]="{backdrop: true}"
     tabindex="-1" role="dialog" aria-hidden="true" (onShow)="showModalInit()">
    <div *ngIf="newAppeal" class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add Appeal</h4>
            </div>
            <div>
                <div class="text-center" style="margin-top: 60px; margin-bottom: 60px" [hidden]="!loading">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div class="modal-body" [hidden]="loading">
                    <div class="row">
                        <div class="col-8">
                            <label>
                                <span>Appeal Level:</span>
                                <select class="form-select form-select-sm" [(ngModel)]="newAppeal.appealLevelID" style="width: 200px;">
                                    <option *ngFor="let level of appealLevels" [ngValue]="level.appealLevelID">{{level.abbr}}</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <label>
                                <span>Revision Appealed:</span>
                                <select class="form-select form-select-sm" [(ngModel)]="newAppeal.annualAssessmentID" style="width: 200px;">
                                    <option *ngFor="let assessment of assessments"
                                            [ngValue]="assessment.annualAssessmentID">{{assessment.revisionDesc}}</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn flat-button primary-button" (click)="save()">Add</button>
                    <button class="btn flat-button warning-button" (click)="addAppealModal.hide()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
