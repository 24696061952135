<div class="card panel-flat-color secondary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-flex-auto ws-truncate">
            <span *ngIf="userSetup">Additional</span> {{userInstanceService.getSelectedInstance().name}} Permissions
        </h3>
        <div class="ws-flex-none header-button-container">
                <button *ngIf="!editMode && canEdit"
                        helpTooltip
                        helpContentId="app.edit"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        [disabled]="!canEnterEditMode"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button helpTooltip
                        helpContentId="app.save"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="save()"
                        [disabled]="!gridIsDirty"
                        *ngIf="editMode && canEdit">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button helpTooltip
                        helpContentId="app.cancel"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode && canEdit"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
                <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle>
                <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="!isExpanded" (click)="expandCollapse(true)">
                    <i class="fa fa-arrows-alt" helpTooltip helpContentId="expand"></i>
                </button>
                <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="isExpanded" (click)="expandCollapse(false)">
                    <i class="fa fa-compress" helpTooltip helpContentId="collapse"></i>
                </button>
            </div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
