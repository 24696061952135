import { Injectable } from '@angular/core';
import { CommentsComponent, CommentsComponentModalParams } from './Modal/comments.component';
import { AddCommentComponent, AddCommentModalParams } from './Add-Comment-Modal/addComment.component';
import { CommentModalData, CommentsService } from './comments.service';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';

/**
 * Service used to open the comments modal
 * This had to be split from the CommentsService during the upgrade from angularjs to prevent a circular dependency
 */
@Injectable()
export class CommentsModalService {
    constructor(
        private readonly _weissmanModalService: WeissmanModalService,
        private readonly _commentsService: CommentsService
    ) {}

    async openCommentModal(modalData: CommentModalData): Promise<any> {
        const modalParams = {
            entityData: modalData,
            availableCommentTypes: await this._commentsService.getAvailableCommentTypesForEntityType(modalData.entityTypeID)
        } as CommentsComponentModalParams;

        return await this._weissmanModalService.showAsync(CommentsComponent, modalParams, 'modal-lg ace-modal-dialog');
    }

    openAddCommentModal(modalData: any): Promise<any> {
        const params = {
            ...modalData
        } as AddCommentModalParams;

        return this._weissmanModalService.showAsync(AddCommentComponent, params, 'modal-lg');
    }
}
