(function () {
    'use strict';

    angular.module('weissmanApp')
        .factory('eventAdapterService', Service);

    Service.$inject = ['$rootScope'];

    function Service($rootScope) {
        return {
            broadcast: broadcast
        };

        function broadcast(name, args) {
            return $rootScope.$broadcast.apply($rootScope, arguments);
        }
    }
}());
