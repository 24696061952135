<div class="contacts-panel-body-actions">
    <button class="btn flat-button small-button primary-button"
            *ngIf="newAllowed && hasNewContactPermission && !isConsultantUser"
            (click)="newContact()">
        New Contact
    </button>
    <button class="btn flat-button small-button primary-button"
            *ngIf="hasAssociateExistingPermission"
            (click)="associateExisting()">
        Associate Existing
    </button>
</div>
