<div class="filter-group-inline-container">
    <div class="filter-label-inline">
        <label for="selectedAssetStatusFilter">Showing</label>
    </div>
    <div class="filter-control-inline">
        <return-asset-status-selector [selectedValue]="selectedAssetStatusFilter"
                                      (selectedValueChange)="onSelectedAssetStatusFilterChange($event)"
                                      [selectDeductions]="false"
                                      *ngIf="isActive">
        </return-asset-status-selector>
    </div>
</div>
