import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const NUB_HELP: HelpContent[] = [
    {
        helpContentId: 'nub.action-view',
        tooltipText: 'Show Action View Results'
    },
    {
        helpContentId: 'nub.advanced-search',
        tooltipText: 'SMARTool Results'
    },
    {
        helpContentId: 'nub.quick-search',
        tooltipText: 'Show Search Results'
    }
];
