<div class="modal-header">
    <h4 class="modal-title ws-text-align-left" *ngIf="title">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body exclude-modal-styling px-4" style="max-height: 500px; overflow: auto;">
    <p *ngIf="message" style="white-space: pre-line;">{{ message }}</p>
    <div *ngIf="messageList.length">
        <p *ngIf="subtitle"><strong>{{ subtitle }}</strong></p>
        <ul [class.bulk-error-list]="isErrorState">
            <li *ngFor="let message of messageList" class="has-error" style="padding: 4px 0;">{{ message }}</li>
        </ul>
    </div>
</div>
<div class="modal-footer">
    <div *ngIf="hasOK" helpTooltip helpContentId="app.ok">
        <button class="btn flat-button primary-button" (click)="ok()">OK</button>
    </div>
    <div *ngIf="hasYes" helpTooltip helpContentId="app.yes">
        <button class="btn flat-button primary-button" (click)="yes()">Yes</button>
    </div>
    <div *ngIf="hasNo" helpTooltip helpContentId="app.no">
        <button class="btn flat-button warning-button" (click)="no()">No</button>
    </div>
    <div *ngIf="hasCancel" helpTooltip helpContentId="app.cancel">
        <button class="btn flat-button warning-button" (click)="cancel()">Cancel</button>
    </div>
    <div *ngIf="hasContinue" helpTooltip helpContentId="app.continue">
        <button class="btn flat-button primary-button" (click)="ok()">Continue</button>
    </div>
</div>
