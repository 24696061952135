import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const SITE_INFO_PANEL_HELP: HelpContent[] = [
    {
        helpContentId: 'site-info-panel.attachments',
        tooltipText: 'Attachments'
    },
    {
        helpContentId: 'site-info-panel.change-history',
        tooltipText: 'Change history'
    },
    {
        helpContentId: 'site-info-panel.comments',
        tooltipText: 'Comments'
    },
    {
        helpContentId: 'site-info-panel.copy',
        tooltipText: 'Copy'
    },
    {
        helpContentId: 'site-info-panel.images',
        tooltipText: 'Images'
    },
    {
        helpContentId: 'site-info-panel.cre',
        tooltipText: 'Valuation Tool'
    },
    {
        helpContentId: 'site-info-panel.map',
        tooltipText: 'Site Map'
    }
]
