import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StepProgressIndicatorStep } from '../../../Common/Step-Progress-Indicator/stepProgressIndicator.component';
import { FilingBatchService } from '../filingBatch.service';

@Component({
    selector: 'filing-batch-progress',
    template: '<step-progress-indicator *ngIf="filingBatch" [clickable]="clickable" [showStepName]="showStepName" [steps]="steps"></step-progress-indicator>'
})
export class FilingBatchProgressComponent {
    constructor(
        private readonly _filingBatchService: FilingBatchService
    ) { }

    @Input() filingBatch: Compliance.FilingBatchModel;
    @Input() showStepName: boolean = true;
    @Input() clickable: boolean = true;
    @Output() click: EventEmitter<Compliance.FilingBatchModel> = new EventEmitter<Compliance.FilingBatchModel>();
    
    steps: StepProgressIndicatorStep[] = [
        {
            name: 'BEGIN',
            title: this._getBeginTitle.bind(this),
            isError: this._beginIsError.bind(this),
            isCurrent: this._beginIsCurrent.bind(this),
            isWorking: this._beginIsWorking.bind(this),
            isComplete: this._beginIsComplete.bind(this),
            click: this._click.bind(this)
        },
        {
            name: 'LOCK',
            title: this._getLockTitle.bind(this),
            isError: this._lockIsError.bind(this),
            isCurrent: this._lockIsCurrent.bind(this),
            isWorking: this._lockIsWorking.bind(this),
            isComplete: this._lockIsComplete.bind(this),
            click: this._click.bind(this)
        },
        {
            name: 'FINALIZE',
            title: this._getFinalizeTitle.bind(this),
            isError: this._finalizeIsError.bind(this),
            isCurrent: this._finalizeIsCurrent.bind(this),
            isWorking: this._finalizeIsWorking.bind(this),
            isComplete: this._finalizeIsComplete.bind(this),
            click: this._click.bind(this)
        }
    ];

    private _getBeginTitle(): string {
        return 'Begin preparation';
    }

    private _beginIsError(): boolean {
        return false;
    }

    private _beginIsCurrent(): boolean {
        return this.filingBatch && this._filingBatchService.isNotStarted(this.filingBatch.processStatus);
    }

    private _beginIsWorking(): boolean {
        return false;
    }

    private _beginIsComplete(): boolean {
        return this.filingBatch && !this._filingBatchService.isNotStarted(this.filingBatch.processStatus);
    }

    private _getLockTitle(): string {
        return this.filingBatch && this._filingBatchService.hasLockError(this.filingBatch) ?
            this.filingBatch.longRunningProcessError :
            'Lock';
    }

    private _lockIsError(): boolean {
        return this.filingBatch && this._filingBatchService.hasLockError(this.filingBatch);
    }

    private _lockIsCurrent(): boolean {
        return this.filingBatch && this._filingBatchService.isStarted(this.filingBatch.processStatus);
    }

    private _lockIsWorking(): boolean {
        return this.filingBatch &&
            (this._filingBatchService.isLocking(this.filingBatch.processStatus) ||
            this._filingBatchService.isUnlocking(this.filingBatch.processStatus) ||
            this._filingBatchService.isSigning(this.filingBatch.processStatus) ||
            this._filingBatchService.isUnsigning(this.filingBatch.processStatus));
    }

    private _lockIsComplete(): boolean {
        return this.filingBatch &&
            (this._filingBatchService.isLocked(this.filingBatch.processStatus) ||
            this._filingBatchService.isSigned(this.filingBatch.processStatus) ||
            this._filingBatchService.isFinalizing(this.filingBatch.processStatus) ||
            this._filingBatchService.isFinalized(this.filingBatch.processStatus));
    }

    private _getFinalizeTitle(): string {
        return this.filingBatch && this._filingBatchService.hasFinalizeError(this.filingBatch) ?
            this.filingBatch.longRunningProcessError :
            'Finalize';
    }

    private _finalizeIsError(): boolean {
        return this.filingBatch && this._filingBatchService.hasFinalizeError(this.filingBatch);
    }

    private _finalizeIsCurrent(): boolean {
        return this.filingBatch && (this._filingBatchService.isLocked(this.filingBatch.processStatus) || this._filingBatchService.isSigned(this.filingBatch.processStatus));
    }

    private _finalizeIsWorking(): boolean {
        return this.filingBatch && this._filingBatchService.isFinalizing(this.filingBatch.processStatus);
    }

    private _finalizeIsComplete(): boolean {
        return this.filingBatch && this._filingBatchService.isFinalized(this.filingBatch.processStatus);
    }

    private _click(): void {
        if (!this.filingBatch) {
            return;
        }

        this.click.emit(this.filingBatch);
    }
}
