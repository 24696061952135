import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { EntityTypeIds, EntityTypeLevels } from 'src/app/constants.new';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmptyGuid } from '../../constants.new';

import * as _ from 'lodash';
import { AttachmentService } from '../attachment.service';
import { Attachment } from '../attachment.model';

@Component({
    selector: 'attachment-form',
    templateUrl: './attachmentForm.component.html'
})
export class AttachmentFormComponent implements OnInit {
    constructor(
        private readonly _attachmentService: AttachmentService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _ngZone: NgZone,
        private readonly _toastr: ToastrService
    ) {}

    @Input() attachment;
    @Input() currentEntity;
    @Input() defaultYear: number;
    @Input() disableYears: boolean;
    @Input() disableAttachmentType: boolean;
    @Input() attachmentTypeId: Core.AttachmentTypes;

    @Output() attachmentFormUpdated: EventEmitter<Attachment> = new EventEmitter();
    @Output() attachmentFormCreated: EventEmitter<any> = new EventEmitter();
    @Output() attachmentFormDeleted: EventEmitter<any> = new EventEmitter();
    @Output() attachmentFormCancelled: EventEmitter<void> = new EventEmitter();

    attachmentForm: UntypedFormGroup;

    years: Compliance.NameValuePair<number>[] = [];
    attachmentTypes: Core.AttachmentTypeModel[] = [];
    submitButtonLabel: string = 'Save';
    isSaving: boolean;
    noYear: boolean;
    isDeleting: boolean;
    loading: boolean;

    private _fetchedCategories: any;
    private _fetchedTypes: any;
    private _destroy$: Subject<void> = new Subject();

    get type(): AbstractControl {
        return this.attachmentForm.get('type');
    }

    get year(): AbstractControl {
        return this.attachmentForm.get('year');
    }

    get file(): AbstractControl {
        return this.attachmentForm.get('file');
    }

    async ngOnInit() {// populate years
        this.populateYears();

        // disable year dropdown for an attachment at a level below parcel
        this.noYear = EntityTypeLevels[this.currentEntity.typeId] < EntityTypeLevels[EntityTypeIds.PARCEL];

        this.attachmentForm = this._fb.group({
            type: [null, Validators.required],
            year: [{ value: this.defaultYear, disabled: this.noYear || this.disableYears }],
            description: [''],
            file: [null, Validators.required]
        });

        this.type.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(type => {
            const validator = type.taxYearRequired ? [Validators.required] : [];
            this.year.setValidators(validator);
            this.year.updateValueAndValidity();
        });

        if (this.attachment) {
            // initialize view model for editing attachment
            this.attachmentForm.patchValue({
                year: this.attachment.year,
                description: this.attachment.description
            });

            this.file.setValidators([]);
            this.file.updateValueAndValidity();
        }

        // populate attachment categories and types from cache
        if (this._fetchedCategories) {
            this.populateTypes(this._fetchedTypes);
        } else {
            this.loading = true;

            try {
                // populate attachment categories and types from db
                this._fetchedCategories = {};
                const categories = await this._attachmentService.getAttachmentCategories();
                categories.forEach((category) => {
                    this._fetchedCategories[category.attachmentCategoryID] = category.category;
                });

                // populate attachmentTypes
                const types = await this._attachmentService.getAttachmentTypes();
                this.populateTypes(types);
                // cache the result
                this._fetchedTypes = types;
            } finally {
                this.loading = false;
            }
        }

        if (this.attachmentTypeId) {
            this.type.setValue(this.attachmentTypes.find(x => x.attachmentTypeID === this.attachmentTypeId));
        }

        if (this.disableAttachmentType) {
            this.type.disable();
        }
    }

    populateTypes(types: Core.AttachmentTypeModel[]): void {
        types.forEach((type) => {
            if (this.allowType(type)) {
                type['displayName'] = `${this._fetchedCategories[type.attachmentCategoryID]  } - ${  type.attachmentTypeName}`;
                this.attachmentTypes.push(type);
                // determine editing attachment type
                if (this.attachment && this.attachment.attachmentTypeID === type.attachmentTypeID) {
                    this.attachmentForm.patchValue({ type });
                    if (type.taxYearRequired) {
                        this.year.setValidators([Validators.required]);
                        this.year.updateValueAndValidity();
                    }
                }
            }
        });
    }

    allowType(type) {
        switch (this.currentEntity.typeId) {
            case EntityTypeIds.SITE:
                return type.allowAtSite;
            case EntityTypeIds.PARCEL:
                return type.allowAtParcel;
            case EntityTypeIds.TAX_BILL:
                return type.allowAtTaxBill;
            case EntityTypeIds.COMPANY:
                return type.allowAtCompany;
            case EntityTypeIds.ASSESSMENT:
                return type.allowAtAssessment;
            case EntityTypeIds.APPEAL:
                return type.allowAtAppeal;
            case EntityTypeIds.REFUND:
                return type.allowAtRefund;
            case EntityTypeIds.FILING:
                return type.allowAtPPReturn;
            case EntityTypeIds.INVOICE:
                return type.allowAtInvoice;
            case EntityTypeIds.STATE:
                return type.allowAtState;
            case EntityTypeIds.COLLECTOR:
                return type.allowAtCollector;
            case EntityTypeIds.ASSESSOR:
                return type.allowAtAssessor;
            case EntityTypeIds.FORM:
                return type.allowAtForm;
            case EntityTypeIds.FORMREVISION:
                return type.allowAtFormRevision;
            case EntityTypeIds.FACTORTABLE:
                return type.allowAtFactorTable;
            case EntityTypeIds.FILINGBATCH:
                return type.allowAtFilingBatch;
            case EntityTypeIds.TAXAUTHORITY:
                return type.allowAtTaxAuthority;
            case EntityTypeIds.TAXRATEAREA:
                return type.allowAtTaxRateArea;
            case EntityTypeIds.BUDGET:
                return type.allowAtBudget;
            case EntityTypeIds.ACCOUNTINGPERIOD:
                return type.allowAtAccountingPeriod;
            case EntityTypeIds.ALLOCATION:
                return type.allowAtAllocation;
            case EntityTypeIds.PAYMENT_BATCH:
                return type.allowAtPaymentBatch;
            case EntityTypeIds.PAYMENT:
                return type.allowAtTaxPayment;
        }
        return false;
    }

    populateYears(): void {
        const now = new Date();
        const currentYear = now.getFullYear();

        this.years.push({
            name: '',
            value: null
        });

        // WK-9315 We typically would use currentYear - 10 as the cutoff, but we received an oddball
        // request to allow 2010 as an option at the beginning of 2021. As a quick fix we just added
        // a year to the available list, but perhaps in the long term we need to add a "more" option
        // to the bottom of the list and allow the user to enter a year outside the range.
        for (let i = currentYear + 3; i >= currentYear - 11; i--) {
            this.years.push({
                name: `${i}`,
                value: i
            });
        }
    }

    async save(): Promise<void> {
        this.submitButtonLabel = 'Saving...';
        this.isSaving = true;

        await this.updateOrCreate();

        this.isSaving = false;
        this.submitButtonLabel = 'Save';
    }

    async updateOrCreate(): Promise<void> {
        if (this.attachment) {
            await this.update();
        } else {
            await this.create();
        }
    }

    async update(): Promise<void> {
        const attachmentToUpdate = _.cloneDeep(this.attachment);
        const formValues = this.attachmentForm.getRawValue();

        const update = {
            ...attachmentToUpdate,
            ...{
                attachmentCategoryID: formValues.type.attachmentCategoryID,
                attachmentTypeID: formValues.type.attachmentTypeID,
                description: formValues.description,
                year: formValues.year !== 'All' ? parseInt(formValues.year) : null
            }
        };

        try {
            const result =  await this._attachmentService.update(update);
            this.attachmentFormUpdated.emit(result);
        } catch(err) {
            this._toastr.error('There was an error creating this attachment.');

        }
    }

    async create() {
        const formValues = this.attachmentForm.getRawValue();

        const attachmentData = {
            externalID: '1',
            entityID: this.currentEntity.id,
            entityTypeID: this.currentEntity.typeId,
            fileName: formValues.file.name,
            fileExtension: formValues.file.name.substring(formValues.file.name.lastIndexOf('.')),
            description: formValues.description,
            year: formValues.year !== 'All' ? parseInt(formValues.year) : null,
            attachmentCategoryID: formValues.type.attachmentCategoryID,
            attachmentTypeID: formValues.type.attachmentTypeID,
            ownerType: 0,
            ownerName: null,
            secondaryOwnerName: null,
            size: formValues.file.size,
            numberOfPages: 0,
            metaData: 'done',
            changedBy: EmptyGuid,
            changeDate: '2015-12-04T08:33:15.193',
            efAction: null
        } as any;

        try {
            const result = await this._attachmentService.createAttachment([formValues.file], attachmentData);

            // TODO: the result from server should include the generated attachment id so that
            // it can be set on attachmentData. e.g. attachmentData.attachmentID = result.attachmentID
            this.attachmentFormCreated.emit(result[0]);
        } catch(err) {
            this._toastr.error('There was an error creating this attachment.');

        }
    }

    cancel(): void {
        this.attachmentFormCancelled.emit();
    }

    attachmentFilePicked(files: File[]): void {
        this._ngZone.run(() => {
            this.attachmentForm.patchValue({file: files[0]});
        });
    }

    async deleteAttachment(): Promise<void> {

        // Removing for now, because this window is appear behind the attachment modal
        // Need to straigten out our z-indices before adding back in
        //
        // try {
        //     await this._messageModalService.confirm('Confirm Delete Attachment.');
        // } catch(err) {
        //     return;
        // }

        if(confirm('Confirm Delete Attachment.')) {
            this.isDeleting = true;

            try {
                await this._attachmentService.deleteAttachment(this.attachment);

                this.isDeleting = false;
                this.attachmentFormDeleted.emit(this.attachment);
            } finally {
                this.isDeleting = false;
            }
        }

    }
}
