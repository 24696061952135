<ws-modal-wrapper headerLabel="Responsible Entity"
                  acceptLabel="OK"
                  [disableSave]="!entityForm?.valid"
                  [hideCancel]="!canEdit || !editing"
                  (save)="save()"
                  (cancel)="cancel()">
    <ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

    <div style="display: flex; flex-direction: row-reverse; margin-top: -20px; margin-right: -30px;">
        <entity-comments
            *ngIf="entity"
            [(hasComments)]="entity.hasComments"
            buttonClass="icon-button icon-only dark-icon"
            [modalData]="commentModalData"
            helpTooltip
            helpContentId="app.comments"
            position="bottom">
        </entity-comments>
        <span (click)="edit()" class="clickable" *ngIf="canEdit && !editing">
            <i class="fa fa-pencil"></i>
        </span>
    </div>
    <div [formGroup]="entityForm" *ngIf="!loading">
        <div class="row">
            <div class="col-lg-2">
                Entity Name:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.name}}</span>
                <input *ngIf="editing" type="text" class="form-control"
                       [ngClass]="{ 'has-error': entityForm.controls.name.errors && entityForm.controls.name.errors.required }"
                       formControlName="name" required maxlength="100">
            </div>
            <div class="col-lg-2">
                Contact:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.contact}}</span>
                <input *ngIf="editing" type="text" class="form-control" formControlName="contact" maxlength="50">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                Entity Number:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.number}}</span>
                <input *ngIf="editing" type="number" class="form-control"
                       [ngClass]="{ 'has-error': entityForm.controls.number.errors }"
                       [max]="2147483647"
                       formControlName="number" required>
            </div>
            <div class="col-lg-2">
                Phone:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.phone}}</span>
                <input *ngIf="editing" type="text" class="form-control" formControlName="phone" maxlength="18">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                Entity Type:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.responsibleEntityType}}</span>
                <ws-select *ngIf="editing"
                           [options]="entityTypes"
                           labelProperty="name"
                           valueProperty="id"
                           formControlName="responsibleEntityTypeId">
                </ws-select>
            </div>
            <div class="col-lg-2">
                Email:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.email}}</span>
                <input *ngIf="editing" type="text" class="form-control" formControlName="email" maxlength="100">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">
                Address:
            </div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.address1}}</span>
                <input *ngIf="editing" type="text" class="form-control" formControlName="address1" maxlength="50">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.address2}}</span>
                <input *ngIf="editing" type="text" class="form-control" formControlName="address2" maxlength="50">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2"></div>
            <div *ngIf="!editing" class="col-lg-4 ws-flex-container-horizontal">
                <span>{{entity.city}}</span>
                <span>{{entity.state}}</span>
                <span>{{entity.zip}}</span>
            </div>
            <div *ngIf="editing" class="col-lg-4 ws-flex-container-horizontal">
                <input type="text" class="form-control" formControlName="city" maxlength="50">
                <state-dropdown formControlName="stateId" [filterByCountryId]="1"></state-dropdown>
                <input type="text" class="form-control" formControlName="zip" maxlength="10" style="width: 70px;">
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">Alloc Debit Account:</div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.allocationDebitAccount}}</span>
                <input *ngIf="editing" type="text" class="form-control"
                       [ngClass]="{ 'has-error': allocationDebitAccount.errors && allocationDebitAccount.errors.required }"
                       formControlName="allocationDebitAccount" maxlength="100">
            </div>
            <div class="col-lg-4">
                <label class="ws-checkbox-label">
                    <input type="checkbox" formControlName="allocationsExport">Allocations Export
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">Alloc Credit Account:</div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.allocationCreditAccount}}</span>
                <input *ngIf="editing" type="text" class="form-control"
                       [ngClass]="{ 'has-error': allocationCreditAccount.errors && allocationCreditAccount.errors.required }"
                       formControlName="allocationCreditAccount" maxlength="100">
            </div>
            <div class="col-lg-3">
                <label class="ws-checkbox-label">
                    <input type="checkbox" formControlName="accrualsExport">Accruals Export
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-2">Alloc Receivable Account:</div>
            <div class="col-lg-4">
                <span *ngIf="!editing">{{entity.allocationReceivableAccount}}</span>
                <input *ngIf="editing" type="text" class="form-control"
                       [ngClass]="{ 'has-error': allocationReceivableAccount.errors && allocationReceivableAccount.errors.required }"
                       formControlName="allocationReceivableAccount" maxlength="100">
            </div>
        </div>
    </div>
</ws-modal-wrapper>
