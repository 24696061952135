<div style="display: flex; justify-content: space-between;" *ngIf="isEntityInRyanInstance">
    <div>
        <span style="font-weight: bold;">DR Team Retrieves</span>
        <fieldset [disabled]="currentData.useTopLevelCompanySettings || entity.type !== 'company' || !editMode">
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="currentData.drTeamRetrievesRE"> RE
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="currentData.drTeamRetrievesPP"> PP
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="currentData.drTeamRetrievesMN"> MN
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="currentData.drTeamRetrievesCA"> CA
                </label>
            </div>
        </fieldset>
    </div>
    <div class="checkbox" *ngIf="isSubsidiary">
        <label>
            <input type="checkbox"
                [(ngModel)]="currentData.useTopLevelCompanySettings"
                [disabled]="!topLevelData || !editMode"
                (change)="useTopLevelChanged()"> Use Top Level Company Settings
        </label>
    </div>
</div>
<div style="display: flex; gap: 10px; align-items: center; margin-bottom: 10px;" *ngIf="showTaxFeedToggle">
    <span style="font-weight: bold;">TaxFeed:</span>
    <ws-switch [(ngModel)]="taxFeedIsEnabled"
               [disabled]="taxFeedToggleDisabled"
               (selectedOptionChanged)="taxFeedEnabledChanged($event)">
    </ws-switch>
</div>
