import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
    selector: 'accruals-bulk-update-modal',
    templateUrl: './accruals.bulk.update.modal.component.html',
})
export class AccrualsBulkUpdateModalComponent implements OnInit {
    constructor(
        private bsModalRef: BsModalRef
    ) { }

    onClose: (request?: Core.AccrualsBulkUpdateRequestModel) => void;
    selectedCount: number;
    showOverrideChangeReason: boolean;

    headerLabel: string;
    editReq: Core.AccrualsBulkUpdateRequestModel = {} as Core.AccrualsBulkUpdateRequestModel;

    editOptions: Compliance.NameValuePair<Core.AccrualsBulkUpdateOperationEnum>[] =
        [
            { name: '--select an Action--', value: Core.AccrualsBulkUpdateOperationEnum.None },
            { name: 'Convert True Up to "Before" Adjustment', value: Core.AccrualsBulkUpdateOperationEnum.ConvertTrueUpToBeforeAdjustment },
            { name: 'Remove "Before" Adjustment', value: Core.AccrualsBulkUpdateOperationEnum.RemoveBeforeAdjustment },
            { name: 'Remove "After" Adjustment', value: Core.AccrualsBulkUpdateOperationEnum.RemoveAfterAdjustment },
        ];
    OverrideReportedChangeReason: Core.AccrualsBulkUpdateOperationEnum = Core.AccrualsBulkUpdateOperationEnum.OverrideReportedChangeReason;
    ConvertTrueUpToBeforeAdjustment: Core.AccrualsBulkUpdateOperationEnum = Core.AccrualsBulkUpdateOperationEnum.ConvertTrueUpToBeforeAdjustment;

    commonBulkUpdateFieldActions: Compliance.NameValuePair<Core.CommonBulkUpdateFieldActionEnum>[] =
        [
            { name: 'No Change', value: Core.CommonBulkUpdateFieldActionEnum.NoChange },
            { name: 'Set to Blank', value: Core.CommonBulkUpdateFieldActionEnum.SetToBlank },
            { name: 'Remove Override', value: Core.CommonBulkUpdateFieldActionEnum.RemoveOverride },
            { name: 'Change To', value: Core.CommonBulkUpdateFieldActionEnum.ChangeTo },
        ];
    ChangeTo: Core.CommonBulkUpdateFieldActionEnum = Core.CommonBulkUpdateFieldActionEnum.ChangeTo;


    async ngOnInit() {
        this.editReq.operation = Core.AccrualsBulkUpdateOperationEnum.None;
        this.editReq.reportedChangeReasonAction = Core.CommonBulkUpdateFieldActionEnum.NoChange;

        if (this.showOverrideChangeReason) {
            this.editOptions.push({ name: 'Override Reported Change Reason', value: Core.AccrualsBulkUpdateOperationEnum.OverrideReportedChangeReason });
        }

        this.headerLabel = `Bulk Update ${this.selectedCount} Accruals entries`;
    }

    isSaveable(): boolean {
        if (this.editReq.operation === Core.AccrualsBulkUpdateOperationEnum.None) {
            return false;
        }

        if(this.editReq.operation == Core.AccrualsBulkUpdateOperationEnum.OverrideReportedChangeReason) {
            return this.editReq.reportedChangeReasonAction != Core.CommonBulkUpdateFieldActionEnum.NoChange;
        }

        if(this.editReq.operation == Core.AccrualsBulkUpdateOperationEnum.ConvertTrueUpToBeforeAdjustment) {
            return !!this.editReq.adjustmentDescription;
        }

        return true;
    }

    async save(): Promise<void> {
        this.editReq.reportedChangeReason = _.trim(this.editReq.reportedChangeReason);

        this.bsModalRef.hide();
        this.onClose(this.editReq);
    }

    cancel() {
        this.bsModalRef.hide();
        this.onClose();
    }
}
