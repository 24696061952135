import * as _ from 'lodash';

export interface ReturnFormRevisionInfo {
    returnFormRevisionId: number;
    formRevisionId: number;
}

export interface ReturnFilingControlRow extends Partial<Compliance.ReturnFilingControlModel> {
    isGroupRow: boolean;
    isTotalRow: boolean;
    isSubTotalRow: boolean;
    isMerged?: boolean;
    returnCount: number;
    isExpanded: boolean;
    returnFormRevisions?: ReturnFormRevisionInfo[];
    reportableCost: number;
    cost: number;
    depreciatedCost: number;
    taxLiability: number;
    reportedAssetCount?: number;
    notReportedAssetCount?: number;
    priorYearReportedCost: number;
    priorYearDepreciatedValue: number;
    isSelectedTotalsRow: boolean;
}

export interface ReturnFilingControlAssetFilter {
    primaryFormRevisionId: number;
    showOnlyAssignedToReportableSchedule: boolean;
    returnIds: number[];
}

export class FilingControlReconciliationReport {
    constructor(
        private readonly _returnFilingControlSummary: Compliance.ReturnFilingControlSummaryModel,
        private readonly _returnFormRevisions: Compliance.ReturnFormRevisionModel[],
        private readonly _returnTotals: Compliance.ReturnTotalsModel[]) {
        this._initialize();
    }

    returns: ReturnFilingControlRow[];
    groupedReturns: ReturnFilingControlRow[];
    consolidatedReturnGroups: ReturnFilingControlRow[];

    private _initialize(): void {
        // Group revisions by return ID
        const revisionsByReturn: { [returnId: string]: ReturnFormRevisionInfo[] } = this._returnFormRevisions.reduce((acc, x) => {
            if (!acc[x.returnId]) {
                acc[x.returnId] = [];
            }
            acc[x.returnId].push({
                formRevisionId: x.formRevisionId,
                returnFormRevisionId: x.returnFormRevisionId
            } as ReturnFormRevisionInfo);
            return acc;
        }, {});

        // Group filings by parcelId
        const filingControlMap: { [parcelId: string]: Compliance.ReturnFilingControlModel } = this._returnFilingControlSummary.returns.reduce((acc, x) => {
            acc[x.parcelId] = x;
            return acc;
        }, {});

        const returns: ReturnFilingControlRow[] = _.orderBy(this._returnFilingControlSummary.returns, x => x.parcelAcctNumber)
            .map(x => ({
                    isGroupRow: false,
                    isSubTotalRow: false,
                    isTotalRow: false,
                    isExpanded: false,
                    returnCount: 1,
                    returnFormRevisions: revisionsByReturn[x.returnId],
                    ...x
            } as ReturnFilingControlRow));

        returns.push(this._getTotalRow(returns));
        this.returns = returns;

        this.consolidatedReturnGroups = this._returnTotals
            .map(x => ({
                ...x,
                isGroupRow: !x.isMergedParcel,
                isSubTotalRow: false,
                isTotalRow: false,
                isExpanded: false,
                isMerged: x.isMergedParcel,
                returnCount: 1,
                returnFormRevisions: revisionsByReturn[x.returnId],
                ...filingControlMap[x.parcelId]
            } as ReturnFilingControlRow))
            .sort((x, y) => y.returnId - x.returnId ||
                    (y.isMerged === x.isMerged ? 0 : x.isMerged ? 1 : -1) ||
                    y.parcelAcctNumberDisplay.localeCompare(x.parcelAcctNumberDisplay, undefined, { numeric: true }))
            .reduce((acc, x, i, ar) => {
                // Create subtotal rows
                if (!x.isMerged) {
                    if (i !== 0) {
                        acc.list.push(acc.subtotal);
                    }
                    acc.subtotal = this._getBlankSubtotalRow();
                }
                this._addToSubtotalRow(acc.subtotal, x);
                acc.list.push(x);
                if (i === ar.length - 1) {
                    acc.list.push(acc.subtotal);
                    acc.list.push(this._getTotalRow(acc.list));
                }
                return acc;
            }, { subtotal: this._getBlankSubtotalRow(), list: [] as ReturnFilingControlRow[] }).list;

        const groups = _.chain(this._returnFilingControlSummary.returns)
            .map(x => ({
                isGroupRow: false,
                isSubTotalRow: false,
                isTotalRow: false,
                isExpanded: false,
                returnCount: 1,
                returnFormRevisions: revisionsByReturn[x.returnId],
                ...x
            } as ReturnFilingControlRow))
            .groupBy(x => x.assessorId)
            .orderBy((values: ReturnFilingControlRow[]) => values[0].assessorName)
            .map((values: ReturnFilingControlRow[], key: number) => ({ assessorId: key, rows: _.orderBy(values, x => x.parcelAcctNumber) }))
            .value();

        const groupedRows: ReturnFilingControlRow[] = [];

        // create a flat list of rows with an assessor group row at the start of each assessor
        groups.forEach(x => {
                groupedRows.push(_.extend({} as ReturnFilingControlRow, x.rows[0],
                    {
                        isGroupRow: true,
                        returnCount: x.rows.length
                    }));
                groupedRows.push.apply(groupedRows, x.rows);

                groupedRows.push(
                    {
                        isGroupRow: false,
                        isSubTotalRow: true,
                        isTotalRow: false,
                        isExpanded: false,
                        returnCount: x.rows.length,
                        reportableCost: x.rows.reduce((acc, y) => (acc += y.reportableCost || 0), 0),
                        cost: x.rows.reduce((acc, y) => (acc += y.cost || 0), 0),
                        depreciatedCost: x.rows.reduce((acc, y) => (acc += y.depreciatedCost || 0), 0),
                        taxLiability: x.rows.reduce((acc, y) => (acc += y.taxLiability || 0), 0),
                        estimatedFMV: x.rows.reduce((acc, y) => (acc += y.estimatedFMV || 0), 0),
                        priorYearReportedCost: x.rows.reduce((acc, y) => (acc += y.priorYearReportedCost || 0), 0),
                        priorYearDepreciatedValue: x.rows.reduce((acc, y) => (acc += y.priorYearDepreciatedValue || 0), 0)
                    } as ReturnFilingControlRow
                );
            });

        groupedRows.push(
            {
                isGroupRow: false,
                isSubTotalRow: false,
                isTotalRow: true,
                isExpanded: false,
                returnCount: groupedRows.length,
                reportableCost: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.reportableCost || 0), 0),
                cost: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.cost || 0), 0),
                depreciatedCost: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.depreciatedCost || 0), 0),
                taxLiability: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.taxLiability || 0), 0),
                estimatedFMV: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.estimatedFMV || 0), 0),
                priorYearReportedCost: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.priorYearReportedCost || 0), 0),
                priorYearDepreciatedValue: groupedRows.filter(x => !(x.isSubTotalRow || x.isTotalRow || x.isGroupRow)).reduce((acc, x) => (acc += x.priorYearDepreciatedValue || 0), 0)
            } as ReturnFilingControlRow
        );

        this.groupedReturns = groupedRows;
    }

    private _getTotalRow(data: ReturnFilingControlRow[]): ReturnFilingControlRow {
        return {
            isGroupRow: false,
            isSubTotalRow: false,
            isTotalRow: true,
            isExpanded: false,
            returnCount: data.length,
            reportableCost: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.reportableCost || 0 : 0), 0),
            cost: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.cost || 0 : 0), 0),
            depreciatedCost: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.depreciatedCost || 0 : 0), 0),
            taxLiability: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.taxLiability || 0 : 0), 0),
            estimatedFMV: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.estimatedFMV || 0 : 0), 0),
            reportedAssetCount: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.reportedAssetCount || 0 : 0), 0),
            notReportedAssetCount: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.notReportedAssetCount || 0 : 0), 0),
            priorYearReportedCost: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.priorYearReportedCost || 0 : 0), 0),
            priorYearDepreciatedValue: data.reduce((acc, x) => (acc += (!x.isSubTotalRow) ? x.priorYearDepreciatedValue || 0 : 0), 0),
            isSelectedTotalsRow: false
        };
    }

    private _getBlankSubtotalRow(): ReturnFilingControlRow {
        return {
            isGroupRow: false,
            isSubTotalRow: true,
            isTotalRow: false,
            isExpanded: false,
            isMerged: false,
            returnCount: 0,
            reportableCost: 0,
            cost: 0,
            depreciatedCost: 0,
            taxLiability: 0,
            estimatedFMV: 0,
            priorYearReportedCost: 0,
            priorYearDepreciatedValue: 0,
            isSelectedTotalsRow: false
        }
    }

    private _addToSubtotalRow(subtotal: ReturnFilingControlRow, row: ReturnFilingControlRow): void {
        subtotal.returnCount += row.returnCount;
        subtotal.reportableCost += row.reportableCost;
        subtotal.cost += row.cost;
        subtotal.depreciatedCost += row.depreciatedCost;
        subtotal.taxLiability += row.taxLiability;
        subtotal.estimatedFMV += row.estimatedFMV;
        subtotal.priorYearReportedCost += row.priorYearReportedCost;
        subtotal.priorYearDepreciatedValue += row.priorYearDepreciatedValue;
    }
}
