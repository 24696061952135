import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FormRepository } from '../../Repositories/form.repository';
import * as _ from 'lodash';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent, CsvExportParams } from 'ag-grid-community';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { FormService } from '../form.service';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';

@Component({
    selector: 'form-filing-batch-list',
    templateUrl: './formFilingBatchList.component.html',
    styleUrls: ['./formFilingBatchList.component.scss']
})
export class FormFilingBatchListComponent implements OnInit, IWeissmanModalComponent<void, void> {
    constructor(
        private readonly _formRepository: FormRepository,
        private readonly _formService: FormService,
        private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _filingBatches: Compliance.FilingBatchInfoModel[];

    params: void;
    result: void;

    isBusy: boolean = false;

    get headerLabel(): string {
        return `In-Use Filing Batches for ${this._formService.assessorName}`;
    }

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .build();

    async ngOnInit(): Promise<void> {
        this.isBusy = true;

        try {
            this._filingBatches = await lastValueFrom(this._formRepository.getFilingBatches(this._formService.assessorId));
            this._setRowData();
        }
        finally {
            this.isBusy = false;
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Batch #',
                field: 'displayId',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing-batch',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingBatch = params.data as Compliance.FormRevisionFilingBatchInfoModel;
                        if (!(filingBatch && filingBatch.filingBatchId)) {
                            return '';
                        }
                        return `#/returnBatch/${filingBatch.filingBatchId}`;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Progress Status',
                field: 'statusCode',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            ,
            {
                headerName: 'Assessor',
                field: 'assessorName',
                lockVisible: true,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingBatch = params.data as Compliance.FormRevisionFilingBatchInfoModel;
                        if (!(filingBatch && filingBatch.assessorId)) {
                            return '';
                        }
                        return `#/assessor/${filingBatch.assessorId}`;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingBatch = params.data as Compliance.FormRevisionFilingBatchInfoModel;
                        return !(filingBatch && filingBatch.assessorId);
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'displayId',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    export(): void {
        const params = {
            fileName: 'FormFilingBatches.csv'
        } as CsvExportParams;
        this._gridApi.exportDataAsCsv(params);
    }

    private _setRowData() {
        if (!(this._gridApi && this._filingBatches)) {
            return;
        }

        this._gridApi.setRowData(this._filingBatches);
        this._gridApi.sizeColumnsToFit();
    }
}
