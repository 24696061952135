import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Constants } from '../constants.new';
import { MessageBoxService, MessageBoxButtons } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ForecastingBudgetingService } from './forecasting.budgeting.service';

class FiscalPeriodEntry {
    year: number;
    period: string;
}

@Component({
    selector: 'add-company-budget-modal',
    templateUrl: './add.company.budget.modal.component.html'
})
export class AddCompanyBudgetModalComponent implements OnInit {
    constructor(
        private bsModalRef: BsModalRef,
        public constants: Constants,
        private messageBoxService: MessageBoxService,
        private toastr: ToastrService,
        private budgetingService: ForecastingBudgetingService
    ) { }

    onClose: (result?: Core.CompanyBudgetDTO) => void;

    tlCompany: Core.CompanyRecordDTO;

    budgetName: string;
    fiscalStartYear: number;
    budgetDate: Date;

    addInProgress: boolean = false;
    availableFiscalPeriods: FiscalPeriodEntry[];
    existingBudgets: Core.AvailableCompanyBudget[] = [];


    async ngOnInit() {
        const currentYear: number = Number(moment().format('YYYY'));

        this.budgetName = '';

        this.fiscalStartYear = currentYear;

        const years = _.range(2019, currentYear + 6);
        this.availableFiscalPeriods = _.map(years, (year) => {
            const strFYBegin = `${this.tlCompany.fiscalYearStart  }/${  year}`;
            console.log(this.tlCompany);
            const momFYStart = moment(strFYBegin).startOf('day').format('MM/DD/YYYY');
            const momFYEnd = moment(momFYStart).add(1, 'year').subtract(1, 'day').format('MM/DD/YYYY');
            return {
                year: year,
                period: `${momFYStart} - ${momFYEnd}`
            };
        }).reverse();

        // Retrieve existing Budget Names for tlCompanyId for validation
        console.log('tlCompanyId', this.tlCompany.companyID);
        this.existingBudgets = await this.budgetingService.getAvailableCompanyBudgets(this.tlCompany.companyID, false);
        console.log('existingBudgets', this.existingBudgets);
    }

    isSaveable(): boolean {
        return (this.budgetName != undefined && this.budgetName != null && _.trim(this.budgetName) !== '')
               && (this.fiscalStartYear >= 2019);
    }

    isBudgetNameUnique(): boolean {
        return _.some(this.existingBudgets, (entry) => {
            return entry.budgetName === _.trim(this.budgetName);
        });
    }


    async save(): Promise<void> {
        this.budgetName = _.trim(this.budgetName);
        // Verify Budget Name doesn't exist for tlCompany
        const exists: boolean = this.isBudgetNameUnique();
        if ( exists )
        {
            console.error(`Company Budget ${this.budgetName} already exists!`);

            this.messageBoxService.open({
                title: 'Budget Already Exists!',
                message: 'You must enter a Budget Name that does NOT already exist for the Company.',
                buttons: MessageBoxButtons.OK
            });

            return;
        }

        let createdBudget: Core.CompanyBudgetDTO;
        this.addInProgress = true;
        // Add Company Budget
        try {
            createdBudget = await this.budgetingService.createCompanyBudget(this.tlCompany.companyID, this.budgetName, this.fiscalStartYear, this.budgetDate);
        }
        catch(err)
        {
            this.bsModalRef.hide();
            this.addInProgress = false;
            console.error(`ERROR adding Company Budget - ${err.message}`);
            this.toastr.error(`ERROR adding Company Budget - ${err.message}`);
            return this.onClose();
        }

        this.bsModalRef.hide();
        this.addInProgress = false;

        this.onClose(createdBudget);
    }

    cancel() {
        this.bsModalRef.hide();

        this.onClose();
    }
}
