import { Component, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import { AssetSummary } from '../../../Models/assetSummary';
import { ExtendedCostAdjustmentTypeModel } from '../assetInfo.component';
import { AssetInfoService } from '../assetInfo.service';
import * as _ from 'lodash';

@Component({
    selector: 'cost-adjustments',
    templateUrl: './costAdjustments.component.html',
})
export class CostAdjustmentsComponent {
    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _assetInfoService: AssetInfoService) {
    }

    @Input() assetSummary: AssetSummary;
    @Input() assets: AssetSummary[];
    @Input() costCompanyAssetDescriptorMappingModel: Compliance.CompanyAssetDescriptorMappingModel;
    @Input() extendedCostAdjustmentTypes: ExtendedCostAdjustmentTypeModel[];

    @Input()
    set costAdjustments(value: { [s: string]: Compliance.AssetCostAdjustmentModel; }) {
        this._costAdjustments = value;
    }

    @Input() 
    set costAdjustmentControlsVisible(value: boolean) {
        this._costAdjustmentControlsVisible = value;
    }

    @Output() costAdjustmentControlsVisibleChange = new EventEmitter<boolean>();
    @Output() costAdjustmentsChange = new EventEmitter<{ [s: string]: Compliance.AssetCostAdjustmentModel; }>();

    costFieldName: string;

    private _costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel; } = {};
    private _costAdjustmentControlsVisible: boolean;

    get costAdjustments(): { [s: string]: Compliance.AssetCostAdjustmentModel } {
        return this._costAdjustments;
    }

    get costAdjustmentControlsVisible(): boolean {
        return this._costAdjustmentControlsVisible;
    }

    get costAdjustmentCalculations(): { [s: string]: string; } {
        return this._assetInfoService.costAdjustmentCalculations;
    };

    get calculatedCost(): string {
        return this._assetInfoService.calculatedCost;
    }

    showCostAdjustments(): void {
        this._costAdjustmentControlsVisible = true;
        this.costAdjustmentControlsVisibleChange.emit(this._costAdjustmentControlsVisible);
    }

    isCostInputDisabled(typeId, isPercent): boolean {
        const otherElemSelector = isPercent ? '.costAdjustmentAmount' : '.costAdjustmentPercent';
        const otherElem = this._elementRef.nativeElement.querySelector(otherElemSelector + typeId);

        if (otherElem && otherElem.value !== null && otherElem.value !== '' && otherElem.value !== 0 && otherElem !== '0') {
            return true;
        } else {
            return false;
        }
    }

    calculateCosts(): void {
        this._assetInfoService.calculateCostsAndCheckTotals(this._getCostFieldSourceValue(), this.costAdjustments, this.assets);
        this.costAdjustmentsChange.next(this.costAdjustments);
    }

    costAdjustmentEmpty(adjustmentType: ExtendedCostAdjustmentTypeModel): boolean {
        const adjustment = this.costAdjustments[adjustmentType.costAdjustmentTypeId];
        if (adjustment && ((adjustment.adjustmentAmount !== null && adjustment.adjustmentAmount !== 0) || (adjustment.adjustmentPercentage !== null && adjustment.adjustmentPercentage !== 0))) {
            return false;
        } else {
            return true;
        }
    }

    private _getCostFieldSourceValue(): number {
        if (this.assetSummary) {
            const altCostMappingModel = this.costCompanyAssetDescriptorMappingModel;
            if (!altCostMappingModel) {
                return this.assetSummary.sourceCost;
            } else {
                return this.assetSummary[`source${altCostMappingModel.columnName}`];
            }
        } else {
            return null;
        }
    }
}
