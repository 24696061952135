<div class="ws-section__header__actions__item">
    <div class="ws-flex-container-horizontal">
        <div class="action-button-wrapper"
             helpTooltip
             [helpContentId]="exceptionButtonHelpContentId"
             position="bottom">
            <button type="button"
                    class="flat-button warning-button small-button"
                    [disabled]="(!canEnterEditMode) || (!canEdit) || isLongRunningProcessActive"
                    (click)="showException()">
                Exception
            </button>
        </div>
    </div>
</div>
