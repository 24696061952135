import { Injectable } from '@angular/core';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { CompanyModel } from '../company.service';
import { AddCompanyModalParams, AddCompanyModalComponent } from './addCompanyModal.component';

@Injectable()
export class LaunchAddCompanyModalService {
    constructor(private _modalService: WeissmanModalService) { }

    async addCompany(parentCompany?: CompanyModel): Promise<void> {
        const params: AddCompanyModalParams = { parentCompany };
        return this._modalService.showAsync(AddCompanyModalComponent, params, 'modal-md');
    }
}