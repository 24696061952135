<style>
    .month-table {
        width: 101%;
        height: 100%;
        table-layout: fixed;
        text-align: center;
    }
    .month-table th {
        text-align: center;
    }
    .month-table .no-day {
        pointer-events: none;
    }
    .month-table .actual-day {
        border: 1px solid lightgoldenrodyellow;
        cursor: pointer;
    }
    .month-table .actual-day:first-child {
        border-left: none;
    }
    .month-table .actual-day:hover, 
    .month-table .clicked-day {
        background-color: #465D8C;
        color: white;
    }
    .month-table .first-week {
        border-top: 0;
    }
    .month-table .today {
        border-radius: 20px;
        background-color: #465D8C;
        color: white;
        padding: .2vw;
    }

    .event-type-block {
        border-radius: 5px;
        padding: 7px;
        margin-bottom: 5px;
        color: white;
        font-size: 90%;
    }
    .widget-month {
        background-color: #97ADAB;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        font-size: 1vw;
    }
    .opaque-widget {
        opacity: 0.5;
    }
    .month-wrapper {
        flex: 1;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     .month-wrapper {
         height: 100%;
     }
    }
    
</style>
<div *ngIf="month" class="widget-month">
    <div style="display: flex; justify-content: space-between; align-items: center; background-color: #677D7B; color: white;">
        <div class="invisible">d</div>
        <div style="text-transform: uppercase; font-size: 120%;">{{month.name}} <span *ngIf="month.year !== now.year()">({{month.year}})</span></div>
        <div style="cursor: pointer; user-select: none; margin-right: 5px;">
            <i class="fa fa-caret-left fa-2x" (click)="previousMonth()"></i>
            <i class="fa fa-caret-right fa-2x" (click)="nextMonth()" style="margin-left: 5px;"></i>
        </div>
    </div>
    <div class="month-wrapper">
        <table class="month-table">
            <thead>
                <tr style="background-color: lightgoldenrodyellow;"><th>S</th><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th></tr>
            </thead>
            <tbody>
                <tr *ngFor="let week of month.weeks; let i = index;">
                    <td *ngFor="let day of week.days; let j = index;" 
                        [class.actual-day]="day.monthDay"
                        [class.no-day]="!day.monthDay" 
                        [class.first-week]="i === 0" 
                        [class.clicked-day]="clickedDay && clickedDay.monthDay && day.monthDay === clickedDay.monthDay" 
                        [popover]="eventsPopover" 
                        container="body" 
                        [placement]="getPopoverPlacement(i,j)"
                        [outsideClick]="true" 
                        (onHidden)="clickedDay = undefined"
                        (click)="dayClicked(day)">
                        <div>
                            <strong [class.today]="isDayToday(day)">{{day.monthDay}}</strong>
                        </div> 
                        <div>
                            <span *ngFor="let iconClass of day.icons"><i [class]="iconClass"></i> </span>
                            <span *ngIf="!day.eventTypeBlocks" class="invisible"><i class="fa fa-gavel small-icon-badge"></i></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="margin-top: 5px;">
        <a *ngFor="let legendBlock of legendBlocks" 
            style="padding: 1px 5px; margin-right: 5px; display: inline-block; cursor: pointer; min-width: 12em;" 
            [class]="getBackgroundColor(legendBlock.dashboardCalendarEventType)"
            (click)="legendClicked(legendBlock)"
            (onHidden)="clickedDay = undefined"
            [popover]="eventsPopover" 
            container="body" 
            placement="top"
            [outsideClick]="true">
            <i [class]="getIconClass(legendBlock.dashboardCalendarEventType)"></i> {{legendBlock.count | number}} {{legendBlock.title}}<span *ngIf="legendBlock.count > 1">s</span>
        </a>
    </div>
</div>

<ng-template #eventsPopover>
    <div *ngIf="clickedDay">
        <i class="fa fa-spinner fa-pulse fa-3x text-center" [hidden]="!loading"></i>
        <div style="display: flex;" [hidden]="loading">
            <div *ngIf="clickedDay.monthDay" class="text-center" style="width: 30px;">
                <div><strong>{{clickedDay.weekdayAbbr}}</strong></div>
                <div style="font-weight: bold;">{{clickedDay.monthDay}}</div>
            </div>
            <div style="padding-left: 15px; width: 100%;">
                <div style="display: flex;" *ngFor="let eventTypeBlock of clickedDay.eventTypeBlocks" [class]="getBackgroundColor(eventTypeBlock.dashboardCalendarEventType)">
                    <div><i [class]="getIconClass(eventTypeBlock.dashboardCalendarEventType)"></i></div>
                    <div style="padding-left: 5px;">
                        <a  style="color: white; cursor: pointer;" (click)="goToSmartFromPopover(eventTypeBlock)">
                            <span>{{eventTypeBlock.totalCount | number}}</span>
                            <span>{{getEventTypeTitle(eventTypeBlock.dashboardCalendarEventType, true)}}<span *ngIf="eventTypeBlock.totalCount > 1">s</span></span>
                        </a>
                        <div>
                            (<span *ngFor="let event of eventTypeBlock.events; let i=index;" (click)="goToSmartFromPopover(eventTypeBlock, event.stateAbbr)"><a style="color: white; cursor: pointer;">{{event.count | number}} {{event.stateAbbr}}</a><span *ngIf="i < eventTypeBlock.events.length -1">, </span></span>)
                        </div>
                    </div>
                </div>
                <div *ngIf="!clickedDay.eventTypeBlocks">
                    <em>No events this day</em>
                </div>
            </div>
        </div>
    </div>
</ng-template>
