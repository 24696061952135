import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LongRunningProcessService } from '../longRunningProcess.service';
import { WeissmanModalService } from '../../WeissmanModalService';
import { BusyIndicatorService } from '../../../Busy-Indicator/busyIndicator.service';
import { LongRunningProcessControlModalComponent } from '../Long-Running-Process-Control-Modal/longRunningProcessControlModal.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_CONTROL_HELP } from './longRunningProcessControl.component.help';

@Component({
    selector: 'long-running-process-control',
    templateUrl: './longRunningProcessControl.component.html'
})
export class LongRunningProcessControlComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _longRunningProcessService: LongRunningProcessService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _helpService: HelpService) { }

    @Input() canEdit: boolean;

    get serviceHosts(): string[] {
        return this._longRunningProcessService.serviceHosts;
    }

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_CONTROL_HELP);
    }

    ngOnDestroy(): void {
    }

    async sendMessage(): Promise<void> {
        const result = await this._modalService.showAsync(LongRunningProcessControlModalComponent, null, 'modal-md');

        if (!result) {
            return;
        }

        const busyRef = this._busyService.show({ message: 'Sending control message' });

        try {
            await this._longRunningProcessService.controlMessage(result);
        } finally {
            busyRef.hide();
        }
    }
}
