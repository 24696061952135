import { Component } from '@angular/core';
import { FormService } from '../form.service';
import { CommentModalData } from '../../../Comments/comments.service';

@Component({
    selector: 'form-comments',
    templateUrl: './FormComments.component.html'
})
export class FormCommentsComponent {
    constructor(
        private readonly _formService: FormService
    ){ }

    get formRevision(): Compliance.FormRevisionModel {
        return this._formService.formRevision;
    }

    getCommentModalData() {
        return {
            entityID: this.formRevision.formRevisionId,
            entityTypeID: Core.EntityTypes.FormRevision,
            description: this._formService.formRevision && this._formService.formRevision.name,
            year: this._formService.taxYear ? this._formService.taxYear.toString() : null,
            canEdit: this._formService.canEdit
        } as CommentModalData;
    }
}
