<warning-message [show]="canShowMessage && showWarning"
                 [(showDetails)]="showMessageDetails"
                 message="Certified return form address has changed."
                 [hasDetails]="true">
    <div class="error-body">
        <div class="ws-flex-container-vertical ws-stretch">
            <span class="error-description">Unlock the batch to update affected return forms with the correct certified form address:</span>
            <div class="error-container">
                <div *ngFor="let formRevision of formRevisions">
                    <div class="form-error">
                        <span class="form-name">{{ formRevision.formRevisionName }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</warning-message>
