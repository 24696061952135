import { UIRouter } from '@uirouter/angular';
import { AmplitudeEvent } from '../../Common/Amplitude/productAnalytics.service';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ReportManagerService } from '../../Reporting/Manager/report.manager.service';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { ReportCategoryComponent } from '../User-Save/Modals/Category/reportCategory.component';
import { ReportRenameComponent } from '../User-Save/Modals/Rename/reportRename.component';
import {
    ContextMenuItem,
    SaveCategory,
    SaveListItem,
    SaveTabColumn, SaveTabColumnType,
    UserSavePage
} from '../User-Save/userSavePage.model';

export class SavedReportService extends UserSavePage<Core.ReportListDTO> {
    constructor(private readonly _reportService: ReportManagerService,
                private readonly _messageModalService: MessageModalService,
                private readonly _modalService: WeissmanModalService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _router: UIRouter,
                private readonly _restrictService: RestrictService) {
        super();
    }

    protected _columns: SaveTabColumn[] = [
        {
            name: 'Name',
            key: 'name',
            type: SaveTabColumnType.String
        },
        {
            name: 'Variant',
            key: 'variantName',
            type: SaveTabColumnType.String
        },
        {
            name: 'Change Date',
            key: 'changeDate',
            type: SaveTabColumnType.Date
        }
    ];

    private _baseValues: Core.SavedReportCategoryManagementDTO[] = [];

    get tabTitle(): string {
        return 'Saved Reports';
    }

    contextMenu(category: SaveCategory<Core.ReportListDTO>, index: number): ContextMenuItem<Core.ReportListDTO>[] {
        return category.categoryType === Core.SearchCategoryType.System
            ? this._systemReportMenuWithPermissions(category, index) : this._customReportContextMenu(category, index);
    }

    async getList(): Promise<void> {
        if (!this._dirty) {
            return;
        }
        this._baseValues = await this._reportService.getReportCategories();

        let categoryList: SaveCategory<Core.ReportListDTO>[] = this._baseValues.map(x => {
            const list: SaveListItem<Core.ReportListDTO>[] = x.reportList.map(y => {
                return {
                    id: y.reportID,
                    name: y.reportName,
                    variantName: y.variantName,
                    changeDate: y.changeDate,
                    canFavorite: false,
                    originalModel: y,
                    amplitudeEvent: this._getAmplitudeEvent(x, y)
                };
            });
            return {
                categoryId: x.categoryId,
                categoryName: x.categoryName,
                categoryType: x.categoryType,
                savedItemList: list
            };
        });

        categoryList = categoryList.filter(x => x.categoryId !== Core.SavedSearchCategoryEnum.ComplianceReturnReport
            && x.categoryId !== Core.SavedSearchCategoryEnum.CompliancePackageReport
            && x.categoryId !== Core.SavedSearchCategoryEnum.ComplianceCustomReport);

        this._separateByCategoryType(categoryList);
    }

    navigateTo(listItem: SaveListItem<Core.ReportListDTO>, categoryType: number): void {
        const params = {
            reportId: listItem.id,
            reportType: categoryType
        };
        this._router.stateService.transitionTo('reportmanager', params);
    }

    async toggleFavorite(search: SaveListItem<Core.ReportListDTO>): Promise<void> {
        // Do nothing
    }

    private async _shareReport(reportItem: SaveListItem<Core.ReportListDTO>, searchType: Core.SearchCategoryType): Promise<void> {
        // Go update the search to mark it as "Shared" if this is a custom search
        if (searchType === Core.SearchCategoryType.Custom) {
            await this._reportService.share(reportItem.originalModel);
        }
        await this.getList();
        this._advancedSearchPersistenceService.clearSearchData();
        const params = {
            reportId: reportItem.id,
            reportType: searchType
        };
        const route = this._router.stateService.href('reportmanager', params, { absolute: true });
        const name = reportItem.variantName ? `${reportItem.name} - ${reportItem.variantName}` : reportItem.name;
        this._showEmailWindow(route, name, 'Report');
    }

    private _systemReportMenuWithPermissions(category: SaveCategory<Core.ReportListDTO>, index: number): ContextMenuItem<Core.ReportListDTO>[] {
        if (!this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT)) {
            return [];
        }
        return [
            {
                name: 'Share',
                onClick: (item, searchType) => {
                    this._shareReport(item, searchType);
                },
                isDisabled: (item) => {
                    return item.originalModel.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup;
                }
            },
            {
                name: 'Rename',
                onClick: (item, reportType) => {
                    this._modalService.showAsync(ReportRenameComponent, { report: item.originalModel, reportType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded;
                }
            },
            {
                name: 'Change Category',
                onClick: (item, reportType) => {
                    this._modalService.showAsync(ReportCategoryComponent, { report: item.originalModel, reportType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || category.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup;
                }
            },
            {
                name: 'Delete',
                onClick: async(item) => {
                    try {
                        let currentName = item.name;
                        if (item.variantName) {
                            currentName += ` - ${  item.variantName}`;
                        }
                        await this._messageModalService.confirm(`Are you sure you want to delete ${  currentName  }?`);
                    } catch(e) {
                        return;
                    }
                    this.dirty = true;
                    await this._reportService.deleteReport(item.originalModel, Core.SearchCategoryType.Custom);
                    category.savedItemList.splice(index, 1);
                    await this.getList();
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded;
                }
            }
        ];
    }

    private _customReportContextMenu(category: SaveCategory<Core.ReportListDTO>, index: number): ContextMenuItem<Core.ReportListDTO>[] {
        return [
            {
                name: 'Share',
                onClick: (item, searchType) => {
                    this._shareReport(item, searchType);
                },
                isDisabled: (item) => {
                    return item.originalModel.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup;
                }
            },
            {
                name: 'Rename',
                onClick: (item, reportType) => {
                    this._modalService.showAsync(ReportRenameComponent, { report: item.originalModel, reportType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded;
                }
            },
            {
                name: 'Change Category',
                onClick: (item, reportType) => {
                    this._modalService.showAsync(ReportCategoryComponent, { report: item.originalModel, reportType }, 'modal-lg');
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded
                        || category.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup;
                }
            },
            {
                name: 'Delete',
                onClick: async(item) => {
                    try {
                        let currentName = item.name;
                        if (item.variantName) {
                            currentName += ` - ${  item.variantName}`;
                        }
                        await this._messageModalService.confirm(`Are you sure you want to delete ${  currentName  }?`);
                    } catch(e) {
                        return;
                    }
                    this.dirty = true;
                    await this._reportService.deleteReport(item.originalModel, Core.SearchCategoryType.Custom);
                    category.savedItemList.splice(index, 1);
                    await this.getList();
                },
                isDisabled: (item) => {
                    return item.originalModel.isSystemSeeded;
                }
            }
        ];
    }

    private _getAmplitudeEvent(category: Core.SavedReportCategoryManagementDTO, report: Core.ReportListDTO): AmplitudeEvent {
        let categoryType = category.categoryType ? 'saved' : 'system';
        if (category.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup) {
            categoryType = 'compliance';
        }
        const event = `click-report-${categoryType}`;
        let properties: { [key: string]: string };
        if (category.categoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup) {
            properties = { actComplianceReport: report.reportName };
        } else if (category.categoryType === Core.SearchCategoryType.System) {
            properties = { actSystemReport: report.reportName };
        }
        return { event, properties };
    }
}
