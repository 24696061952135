import { Component, OnInit, OnDestroy } from '@angular/core';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FormFilingBatchListComponent } from '../Form-Filing-Batch-List/formFilingBatchList.component';
import { FormService } from '../form.service';

@Component({
    selector: 'form-filing-batches',
    templateUrl: './formFilingBatches.component.html'
})
export class FormFilingBatchesComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _formService: FormService) { }

    get helpContent(): string {
            return `In-Use Filing Batches for ${this._formService.assessorName}`
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async show(): Promise<void> {
        await this._modalService.showAsync(FormFilingBatchListComponent, null, 'modal-xl');
    }
}
