import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const PAYMENT_BATCH_DETAILS_HELP: HelpContent[] = [
    {
        helpContentId: 'payment-batch-details.edit-funded-amount',
        tooltipText: 'Funded Amount must be less than or equal to payment amount.',
        hasIcon: true
    },
    {
        helpContentId: 'payment-batch-details.add-payments-modal',
        tooltipText: ' List of payments that are available to add to this payment batch. The payment must be in the same company and may not already be included in another payment batch or already submitted for payment.',
        hasIcon: true
    },
    {
        helpContentId: 'payment-batch-details.add-payments-button',
        tooltipText: 'Add Payments'
    },
    {
        helpContentId: 'payment-batch-details.show-payment-errors',
        tooltipText: 'Show Errors'
    }
];
