import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface TaxingDistrictListGridActionCellRendererParams extends ICellRendererParams {
    canDelete: (params: TaxingDistrictListGridActionCellRendererParams) => boolean;
    delete: (params: TaxingDistrictListGridActionCellRendererParams) => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button class="btn flat-button icon-button warning-button grid-action-button"
        title="Delete"
        [disabled]="!params.canDelete(params)"
        (click)="params.delete(params)"
        helpTooltip
        helpContentId="app.delete"
        position="bottom">
        <i class="fa fa-trash"></i>
    </button>
    `
})
export class TaxingDistrictListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: TaxingDistrictListGridActionCellRendererParams;

    agInit(params: TaxingDistrictListGridActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
