import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const LONG_RUNNING_PROCESS_QUIESCE_HELP: HelpContent[] = [
    {
        helpContentId: 'long-running-process-quiesce.pause',
        tooltipText: 'Pauses the long-running process service to allow existing processes to finish while preventing new ones from starting.  Note that this DOES NOT pause the actual services running on the server.'
    },
    {
        helpContentId: 'long-running-process-quiesce.resume',
        tooltipText: 'Resumes the long-running process service.'
    }
];
