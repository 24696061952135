import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/main';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'asset-return-filings-status',
    template: `
            {{assetReturnModel.status}}
            <span *ngIf="assetReturnModel.completionDate" style="color: gray;">
                {{assetReturnModel.completionDate.toLocaleDateString()}}
            </span>
          `
})
export class AssetReturnFilingStatusCellRendererComponent implements ICellRendererAngularComp {
    assetReturnModel: Compliance.AssetReturnModel;

    agInit(params: ICellRendererParams): void {
        this.assetReturnModel = params.data && params.data as Compliance.AssetReturnModel;
    }

    refresh(params: any): boolean {
        return false;
    }
}
