import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteClassPrimary } from './siteClass.repository';

@Injectable()
export class ValuationTemplateRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly BASE_URL: string = '/api/valuationTemplate/';

    getTemplateList(): Observable<any[]> {
        let params = new HttpParams().set('includeInDevelopment', true);
        return this.httpGet(this.BASE_URL, {params: params});
    }

    addOrUpdateTemplate(template: Core.ValuationTemplateModel): Observable<Core.ValuationTemplateModel> {
        return this.httpPost(this.BASE_URL, template);
    }

    updateIsInDevelopment(templateId: number, isInDevelopment: boolean): Observable<Core.ValuationTemplateModel>{
        let params = new HttpParams().set('isInDevelopment', isInDevelopment);
        return this.httpPut(`${this.BASE_URL}${templateId}/development`, {}, {params: params});
    }

    getTemplateNamesWithDuplicateSettings(templateId: number): Observable<string[]>{
        return this.httpGet(`${this.BASE_URL}${templateId}/duplicates`);
    }

    updateTemplateStates(templateId: number, states: Core.ValuationTemplateStateModel[]): Observable<Core.ValuationTemplateModel> {
        return this.httpPut(`${this.BASE_URL}${templateId}/states`, states);
    }

    updateTemplateAssessors(templateId: number, assessors: Core.ValuationTemplateAssessorModel[]): Observable<Core.ValuationTemplateModel> {
        return this.httpPut(`${this.BASE_URL}${templateId}/assessors`, assessors);
    }

    getSiteClassOptions(): Observable<SiteClassPrimary[]> {
        return this.httpGet(`${this.BASE_URL}/siteClassPrimaries`);
    }

    updateTemplateSiteClasses(templateId: number, siteClasses: Core.ValuationTemplateSiteClassPrimaryModel[]): Observable<Core.ValuationTemplateModel> {
        return this.httpPut(`${this.BASE_URL}${templateId}/siteClassPrimaries`, siteClasses);
    }

    getTemplateById(templateId: number): Observable<Core.ValuationTemplateModel> {
        return this.httpGet(`${this.BASE_URL}${templateId}`);
    }

    updateTemplateFile(templateId: number, file: File): Observable<Core.ValuationTemplateModel> {
        const formData = new FormData();
        formData.append('file', file);

        return this.httpPost(`${this.BASE_URL}${templateId}/file`, formData, {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        });
    }

    downloadTemplateFile(templateId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this.BASE_URL}${templateId}/file`, options);
    }

    deleteTemplateFile(templateId: number): Observable<Core.ValuationTemplateModel> {
        return this.httpDelete(`${this.BASE_URL}${templateId}/file`);
    }

    deleteTemplate(templateId: number): Observable<void> {
        return this.httpDelete(`${this.BASE_URL}${templateId}`);
    }
}
