import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

export interface FilingBatchMoveResultModalParams {
    targetFilingBatch: Compliance.FilingBatchModel;
    moveCount: number;
}

@Component({
    selector: 'filing-batch-move-result-modal',
    template: `
        <ws-modal-wrapper headerLabel="Move Filings"
                          acceptLabel="OK"
                          (save)="cancel()"
                          (cancel)="cancel()"
                          [hideCancel]="true">
            <span>
                {{ params.moveCount }} filing(s) were successfully moved to <span class="btn btn-link pointer" (click)="navigate()">{{ params.targetFilingBatch.description }}</span>
            </span>
        </ws-modal-wrapper>`
})
export class FilingBatchMoveResultModalComponent implements IWeissmanModalComponent<FilingBatchMoveResultModalParams, boolean> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _routerService: UpgradeNavigationServiceHandler
    ) { }

    params: FilingBatchMoveResultModalParams;
    result: boolean;

    navigate(): void {
        this._routerService.go('filingBatchDetails', { companyId: this.params.targetFilingBatch.companyId, filingBatchId: this.params.targetFilingBatch.filingBatchId});
        this._bsModalRef.hide();
    }

    cancel(): void {
        this.result = true;
        this._bsModalRef.hide();
    }
}
