import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { FactorTableRepository } from '../../Repositories';
import { StateService } from '../../../Common/States/States.Service';
import { StateSummary } from '../../../Common/States/state.model';
import { AssessorService } from '../../../Assessor-Collector/Assessor/assessor-service';
import { Constants } from '../../../constants.new';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { WsUniqueYearValidator } from './validators';
import { Subscription, Observable, lastValueFrom } from 'rxjs';
import { CompanyRepository } from '../../Repositories';
import { mergeMap, map } from 'rxjs/operators';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { YearPickerMixin } from '../../../UI-Lib/Mixins';
import { BaseExpandableComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableComponent';

export interface FactorTableDetailsParams {
    factorTableId?: number;
    editMode: boolean;
    companies?: Core.CompanyDTO[];
    newFactorTableParams?: {
        source: GridSourceOptionEnum;
        stateId: number;
        company: Core.CompanyInfoModel;
        isCopyFrom: boolean;
    }
}

export interface FactorTableDetailsResult {
    hasChanges: boolean;
}

export enum GridSourceOptionEnum {
    System,
    Company
}

enum DisplayMode {
    Add = 0,
    Edit = 1,
    View = 2,
    CopyFrom = 3
}

enum PanelView {
    Details = 0,
    List = 1
}

@Component({
    selector: 'factor-table-details',
    templateUrl: './factorTableDetails.component.html',
    styleUrls: ['./factorTableDetails.component.scss']
})
export class FactorTableDetailsComponent extends YearPickerMixin(BaseExpandableComponent) implements OnInit, OnDestroy, IWeissmanModalComponent<FactorTableDetailsParams, FactorTableDetailsResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _constants: Constants,
        private readonly _stateService: StateService,
        private readonly _assessorService: AssessorService,
        private readonly _factorTableRepository: FactorTableRepository,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _companyRepository: CompanyRepository,
        private readonly _cdRef: ChangeDetectorRef
    ) {
        super();
    }

    params: FactorTableDetailsParams;
    result: FactorTableDetailsResult = {
        hasChanges: false
    };

    selectedView: PanelView = PanelView.Details;
    clearAssessorEnabled = true;
    form: UntypedFormGroup;
    source: GridSourceOptionEnum;
    GridSourceOptionEnum = GridSourceOptionEnum;

    states: StateSummary[] = [];

    companyFilter: string = '';
    companiesLoading: boolean = false;
    selectedCompany: Core.CompanyInfoModel;
    selectedCompanyId: UntypedFormControl = new UntypedFormControl(null);
    canEditCompany: boolean = false;
    companyEditing: boolean = false;

    companiesDataSource$: Observable<Core.CompanyInfoModel[]> = Observable
        .create((observer: any) => { observer.next(this.companyFilter); })
        .pipe(mergeMap((token: string) => this._filterCompanies(token)))
        .pipe(map((result: Compliance.QueryResultModel<Core.CompanyInfoModel[]>) => result.data));

    assessors: Core.AssessorModel[] = [];

    factorTableTypes: Compliance.NameValuePair<Compliance.FactorTableTypeEnum>[] = this._constants.FactorTableTypes;

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    protected readonly PanelView = PanelView;
    private _availableCompanies: Core.CompanyInfoModel[] = [];
    private _yearControlValueChangedSub: Subscription;
    private _factorTableDetailModel: Compliance.FactorTableModel = <Compliance.FactorTableModel>{};
    private _displayMode: DisplayMode = DisplayMode.View;

    // reactive form and helper methods for accessing parts of that form
    get f() { return this.form; }
    get fCore() { return (this.form.get('coreGroup') as UntypedFormGroup); }
    get fCoreState() { return (this.form.get('coreGroup.stateGroup') as UntypedFormGroup); }
    get fCoreCompany() { return (this.form.get('coreGroup.companyGroup') as UntypedFormGroup); }
    get fCoreAssessor() { return (this.form.get('coreGroup.assessorGroup') as UntypedFormGroup); }
    get fYears() { return (this.form.get('years') as UntypedFormArray); }
    get fAges() { return (this.form.get('ages') as UntypedFormArray); }
    get fFloors() { return (this.form.get('floors') as UntypedFormArray); }

    // reactive form helper methods for accession validation related to a collection of form controls
    get fYearsHaveValidationErrorsRequired() { return !!this.fYears.controls.find(i => i.errors && i.errors.required); }
    get fYearsHaveValidationErrorsUnique() { return !!this.fYears.controls.find(i => i.errors && i.errors.wsUniqueYear); }

    get displayMode(): DisplayMode{
        return this._displayMode;
    }

    set displayMode(value: DisplayMode){
        this._displayMode = value;
        switch (this._displayMode) {
            case DisplayMode.Edit:
                this.fCore.get('tableType').disable();
                if (this._factorTableDetailModel !== null && !this._factorTableDetailModel.usedInReturn) {
                    this.fCoreAssessor.get('assessorName').enable();
                    this.clearAssessorEnabled = true;
                } else {
                    this.fCoreAssessor.get('assessorName').disable();
                    this.clearAssessorEnabled = false;
                }
                break;
            case DisplayMode.View:
                this.fCore.get('tableType').disable();
                this.fCoreAssessor.get('assessorName').disable();
                this.clearAssessorEnabled = false;
                break;
            case DisplayMode.Add:
                this.fCoreAssessor.get('assessorName').enable();
                this.clearAssessorEnabled = true;
                break;
            case DisplayMode.CopyFrom:
                this.fCore.get('tableType').disable();
                this.fCoreAssessor.get('assessorName').enable();
                this.clearAssessorEnabled = true;
                break;
        }
    }

    get DisplayModeEnum(){
        return DisplayMode;
    }

    async ngOnInit(): Promise<void> {
        if (!this.params.factorTableId) {
            if (!this.params.newFactorTableParams) {
                return Promise.reject('Missing parameters for adding new factor table.');
            }
            if (!(this.params.newFactorTableParams.stateId || this.params.newFactorTableParams.company)) {
                return Promise.reject('Missing state or company parameter for adding new factor table.');
            }
        }

        this.source = (this.params.newFactorTableParams) ? this.params.newFactorTableParams.source : null;

        this.form = this._formBuilder.group({
            coreGroup: this._formBuilder.group({
                tableName: [null, [Validators.required, Validators.maxLength(100)]],
                tableLife: [null, Validators.required],
                tableType: [Compliance.FactorTableTypeEnum.Depreciation, [Validators.required]],
                tableRetiredTaxYear: [null],
                stateGroup: this._formBuilder.group({
                    state: null,
                    stateName: [{ value: null, disabled: true }, [Validators.required]]
                }),
                companyGroup: this._formBuilder.group({
                    company: null,
                    companyName: [{ value: null, disabled: true }, [Validators.required]]
                }),
                assessorGroup: this._formBuilder.group({
                    assessor: null,
                    assessorName: null
                })
            }),
            years: this._formBuilder.array([]),
            ages: this._formBuilder.array([]),
            floors: this._formBuilder.array([])
        });

        this._yearControlValueChangedSub = this.fYears.valueChanges.subscribe(() => {
            this._refreshYearControls();
        });

        this.form.get('coreGroup.companyGroup.companyName').valueChanges.subscribe((companyFilter) => {
            this.companyFilter = companyFilter;
        });

        const busyMsgInitializing = 'initializing';
        this.busyIndicatorMessageManager.add('Initializing', busyMsgInitializing);

        try {
            const tableParams = this.params.newFactorTableParams;

            if (this.params.factorTableId) {
                this._factorTableDetailModel = await lastValueFrom(this._factorTableRepository.get(this.params.factorTableId));

                if (this.params.newFactorTableParams && this.params.newFactorTableParams.isCopyFrom) {
                    this.displayMode = DisplayMode.CopyFrom;

                    this._factorTableDetailModel.factorTableId = 0;
                    this._factorTableDetailModel.name = null;
                    this._factorTableDetailModel.life = null;
                    this._factorTableDetailModel.changedBy = undefined;
                    this._factorTableDetailModel.changeDate = undefined;
                    this._factorTableDetailModel.hasAttachments = false;
                    this._factorTableDetailModel.hasComments = false;
                    this._factorTableDetailModel.rowVersion = [];
                    this._factorTableDetailModel.usedInReturn = false;
                    this._factorTableDetailModel.transferId = null;
                } else {
                    this.displayMode = this.params.editMode ? DisplayMode.Edit : DisplayMode.View;
                }

            } else {
                this._factorTableDetailModel = {
                    factorTableId: 0,
                    stateId: tableParams.stateId,
                    assessorId: null,
                    companyId: (tableParams && tableParams.company) ? tableParams.company.companyId : null,
                    name: null,
                    tableType: Compliance.FactorTableTypeEnum.Depreciation,
                    life: null,
                    taxYears: [],
                    taxYearValues: [],
                    hasAttachments: false,
                    hasComments: false,
                    rowVersion: [],
                    usedInReturn: false,
                    taxYearFloors: [],
                    retiredTaxYear: null
                };

                this.displayMode = DisplayMode.Add;
            }

            this.selectedCompany = tableParams && tableParams.company;
            this.canEditCompany = tableParams && tableParams.isCopyFrom && !!tableParams.company;

            if (this.canEditCompany) {
                this.editCompany();
            }

            if (this.selectedCompany) {
                this.selectedCompanyId.setValue(this.selectedCompany.companyId);
            }

            // load states
            this.states = await this._stateService.getSummary();
            const stateId = this.states.find(i => i.stateID === this._factorTableDetailModel.stateId).stateID;

            // load assessors
            this.assessors = await this._assessorService.getByStateId(stateId);

            // setup form
            this._updateFormFromModel(this._factorTableDetailModel);

            // default first year and age if creating a new factor table
            if (this.displayMode === DisplayMode.Add) {
                this.addYear();
                this.addAge();
            }
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgInitializing);
        }

        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._yearControlValueChangedSub && this._yearControlValueChangedSub.unsubscribe();
    }

    onAssessorSelected(match: TypeaheadMatch): void {
        const assessor = match.item as Core.AssessorModel;
        this.fCoreAssessor.patchValue({
            'assessor': assessor,
            'assessorName': assessor.name
        });
    }

    onAssessorClear(): void {
        this.fCoreAssessor.patchValue({
            'assessor': null,
            'assessorName': null
        });
    }

    onAssessorBlur(): void {
        const assessor: Core.AssessorModel = this.fCoreAssessor.get('assessor').value;
        this.fCoreAssessor.patchValue({
            'assessorName': assessor && assessor.name
        });
    }

    addYear(year?: number): void {
        // get current year and default that as the year that will be added
        year = year || (new Date()).getFullYear();

        this.fYears.insert(0, this._createYearControl(year));

        // update ages and floors with new year value
        this.fAges.controls.forEach(x => (x.get('factors') as UntypedFormArray).insert(0, this._createFactorControl(null)));
        this.fFloors.controls.forEach(x => (x.get('floors') as UntypedFormArray).insert(0, this._createFloorControl(null)));

        // refresh custom validators
        this._refreshYearControls();
        this._cdRef.detectChanges();
    }

    removeYear(yearIndex: number): void {
        this.fYears.removeAt(yearIndex);

        // update ages and floors by removing year value
        this.fAges.controls.forEach(x => (x.get('factors') as UntypedFormArray).removeAt(yearIndex));
        this.fFloors.controls.forEach(x => (x.get('floors') as UntypedFormArray).removeAt(yearIndex));

        // refresh custom validators
        this._refreshYearControls();
    }

    addAge(factors: number[] = []): void {
        // array of factor controls for the new age
        const factorArray = this._formBuilder.array([]);

        // populate that array for each available year
        this.fYears.controls.forEach((x, i) => {
            factorArray.push(this._createFactorControl(factors[i] || (factors[i] === 0 ? 0 : null)));
        });

        // assign the factor controls to the age group
        const ageGroup = this._formBuilder.group({
            ageIndex: this.fAges.length,
            factors: factorArray
        });

        // add age group to ages control array
        this.fAges.push(ageGroup);
    }

    addFloors(floors: number[] = []): void {
        // reverse the floors to align with the years
        const floorsByYear = [...floors].reverse();

        // array of factor controls for the new age
        const floorArray = this._formBuilder.array([]);

        // populate that array for each available year
        this.fYears.controls.forEach((x, i) => {
            floorArray.push(this._createFloorControl(floorsByYear[i] || null));
        });

        // assign the factor controls to the age group
        const floorGroup = this._formBuilder.group({
            floorIndex: this.fFloors.length,
            floors: floorArray
        });

        // add age group to ages control array
        this.fFloors.push(floorGroup);
    }

    removeAge(ageIndex: number): void {
        this.fAges.removeAt(ageIndex);
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onCompanySelected(typeAheadMatch: TypeaheadMatch): void {
        const company = typeAheadMatch.item as Core.CompanyInfoModel;
        this.selectedCompanyId.setValue(company.companyId);
        this.fCoreCompany.patchValue({
            'company': company,
            'companyName': company.companyName
        });
    }

    onCompanyBlur(): void {
        if (!this.companyFilter || this.companyFilter.trim() === '') {
            this.selectedCompanyId.setValue(null);
            this.fCoreCompany.patchValue({
                'company': null,
                'companyName': null
            });
        } else {
            if (!this._availableCompanies.find(x => x.companyName === this.companyFilter)) {
                this.selectedCompanyId.setValue(null);
                this.fCoreCompany.patchValue({
                    'company': null,
                    'companyName': null
                });
            }
        }
    }

    onCompanyLoadingChange(isLoading: boolean): void {
        this.companiesLoading = isLoading;
    }

    setViewSelection(selectedView: PanelView): void {
        this.selectedView = selectedView;
    }

    async save(): Promise<void> {
        this._updateModelFromForm(this._factorTableDetailModel);

        const busyMsgSaving = 'saving';
        this.busyIndicatorMessageManager.add('Saving Changes', busyMsgSaving);

        const promise = this.displayMode === DisplayMode.Add || this.displayMode === DisplayMode.CopyFrom
            ? lastValueFrom(this._factorTableRepository.create(this._factorTableDetailModel))
            : lastValueFrom(this._factorTableRepository.update(this._factorTableDetailModel));

        try {
            await promise.then((model: Compliance.FactorTableModel) => {
                this.result.hasChanges = true;

                // close modal window
                this._bsModalRef.hide();
            });
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyMsgSaving);
        }
    }

    private _filterCompanies(searchValue: string): Observable<Compliance.QueryResultModel<Core.CompanyInfoModel>> {
        const searchModel = {
            columnFilters: [{
                filterProperty: Core.CompanyInfoPropertyEnum.CompanyName,
                filterValues: [
                    {
                        filterType: Core.FilterTypeEnum.Contains,
                        filterValue: searchValue
                    }
                ]
            }],
            topLevelOnly: true,
            preparePPReturnsOnly: true
        } as Core.CompanyInfoSearchModel;

        return this._companyRepository.searchCompanyInfo(searchModel).pipe(map((result: Compliance.QueryResultModel<Core.CompanyInfoModel>) => {
            this._availableCompanies = result.data;
            return result;
        }));
    }

    private _updateFormFromModel(model: Compliance.FactorTableModel): void {
        const state = this.states.find(i => i.stateID === model.stateId);
        const assessor = this.assessors.find(i => i.assessorID === model.assessorId) || null;

        (this.form.get('years') as UntypedFormArray).clear();
        (this.form.get('ages') as UntypedFormArray).clear();
        (this.form.get('floors') as UntypedFormArray).clear();

        this.form.patchValue({
            coreGroup: {
                tableName: model.name,
                tableLife: model.life,
                tableType: model.tableType,
                tableRetiredTaxYear: model.retiredTaxYear,
                stateGroup: {
                    state: state || null,
                    stateName: (state && state.fullName) ? state.fullName : null
                },
                companyGroup: {
                    company: this.selectedCompany || null,
                    companyName: (this.selectedCompany && this.selectedCompany.companyName) ? this.selectedCompany.companyName : null
                },
                assessorGroup: {
                    assessor: assessor,
                    assessorName: assessor && assessor.name
                }
            }
        });

        // populate years (before populating ages)
        model.taxYears.forEach(x => this.addYear(x));

        // populate ages
        // get the longest set of values for any year in order to figure out the max age
        // max age determines how many rows the factor table will have
        let maxAge = 0;

        const taxYearValues = [...model.taxYearValues].reverse();
        taxYearValues.forEach(i => maxAge = i.length > maxAge ? i.length : maxAge);

        for (let i = 0; i < maxAge; i++) {
            // for the age that is being added, get the list of the factors for each year
            const factors = taxYearValues.map(x => x[i]).filter(x => x !== null);

            // if the table is of type depreciation, the numbers represent percentages; convert decimal values to whole numbers
            // using toFixed: 0.55 * 100 = 55.00000000000001 ???
            if (model.tableType === Compliance.FactorTableTypeEnum.Depreciation) {
                // factors.forEach((x, i) => factors[i] = parseInt((x * 100).toFixed(2)));
                factors.forEach((x, i) => factors[i] = Math.round(x * 10000000) / 100000);
            }

            this.addAge(factors);
        }

        // it is possible that we do not have any factor values yet, so let's create an empty Age 1 to not break the layout
        if (this.displayMode !== DisplayMode.Add && maxAge === 0){
            const factors = model.taxYears.map(() => null);
            this.addAge(factors);
        }

        this.addFloors(model.taxYearFloors);
    }

    private _updateModelFromForm(model: Compliance.FactorTableModel): void {
        const formValues = this.form.getRawValue();
        const coreValues = formValues.coreGroup;

        model.name = coreValues.tableName;
        model.life = coreValues.tableLife;
        model.tableType = coreValues.tableType;
        model.retiredTaxYear = coreValues.tableRetiredTaxYear;

        const assessor: Core.AssessorModel = this.fCoreAssessor.get('assessor').value;
        model.assessorId = (assessor && assessor.assessorID) || null;

        const company: Core.CompanyInfoModel = this.fCoreCompany.get('company').value;
        model.companyId = (company && company.companyId) || null;

        model.taxYears = [...formValues.years].reverse();

        // for each year
        // look at all the ages in the grid
        // and for each age get the factors matching the year index
        // filter out factors that are not populated
        model.taxYearValues = formValues.years.map((x, i) => formValues.ages.map(y => y.factors[i]).filter(z => z !== null)).reverse();
        // model.taxYearValues = this.fYears.controls.map((x, i) => this.fAges.controls.map(y => (y.get('factors') as FormArray).controls[i].value).filter(z => z !== null));

        // if the table is of type depreciation, the numbers represent percentages; convert whole numbers to decimal values
        if (model.tableType === Compliance.FactorTableTypeEnum.Depreciation) {
            model.taxYearValues.forEach(x => x.forEach((y, i) => x[i] = Math.round(y * 100000) / 10000000));
        }

        model.taxYearFloors = [...formValues.floors[0].floors].reverse();
    }

    private _createYearControl(year: number): UntypedFormControl {
        return this._formBuilder.control(year, [Validators.required, WsUniqueYearValidator(this.fYears.controls)]);
    }

    private _createFactorControl(factor: number): UntypedFormControl {
        return this._formBuilder.control(factor, [Validators.min(0)]);
    }

    private _createFloorControl(floor: number): UntypedFormControl {
        return this._formBuilder.control(floor, [Validators.min(0), Validators.max(1000000), Validators.pattern('-?\\d+(\\.\\d{1,2})?')]);
    }

    private _refreshYearControls(): void {
        this.fYears.controls.forEach(x => x.updateValueAndValidity({ onlySelf: true, emitEvent: false }));
        this.fYears.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }

    editCompany() {
        this.companyEditing = true;
        this.fCoreCompany.get('companyName').enable();
    }

    saveCompany() {
        this.companyEditing = false;
        if (this._factorTableDetailModel.companyId) {
            this.fCoreCompany.get('companyName').disable();
        }
    }
}
