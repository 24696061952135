import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtendedCostAdjustmentTypeModel} from './assetInfo.component';
import { DecimalPipe } from '@angular/common';
import { AssetSummary } from '../../Models/assetSummary';
import { Decimal } from 'decimal.js';

import * as _ from 'lodash';

@Injectable()
export class AssetInfoService {
    constructor( private _decimalPipe: DecimalPipe ) {
    }
    get calculatedCostUpdated$(): Observable<number> { return this._calculatedCostEventEmitter.asObservable(); }

    allocatedTotal: number;
    assetValueMatched: boolean;
    calculatedCost: string;
    calculatedCostValue: number;
    costAdjustmentDescription: string;

    costCompanyAssetDescriptorMappingModel: Compliance.CompanyAssetDescriptorMappingModel;
    extendedCostAdjustmentTypes: ExtendedCostAdjustmentTypeModel[] = [];
    hasSourceValue: boolean;
    hasAdjustments: boolean;
    //sourceFieldValue: number;
    costAdjustmentCalculations;

    private _costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel; } = {};
    private _selectedCompanyId: number;
    private _calculatedCostEventEmitter = new EventEmitter<number>();

    set costAdjustments(costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel; }) {
        this._costAdjustments = costAdjustments;
    }

    get costAdjustments(): { [s: string]: Compliance.AssetCostAdjustmentModel; } {
        return this._costAdjustments;
    }

    set selectedCompanyId(id: number) {
        this._selectedCompanyId = id;
    }

    get selectedCompanyId(): number {
        return this._selectedCompanyId;
    }

    clearValues(): void {
        this.calculatedCost = '';
        this.costAdjustmentDescription = '';
        this.costAdjustmentCalculations = {};
    }

    calculateCostsAndCheckTotals(sourceFieldValue: number, costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel }, assets: AssetSummary[]): void {
        this._calculateCosts(sourceFieldValue, costAdjustments);
        this._checkAssetsMatchCalculatedSourceCost(assets);
    }

    private _calculateCosts(sourceFieldValue: number, costAdjustments: { [s: string]: Compliance.AssetCostAdjustmentModel }): void {

        let runningTotal = sourceFieldValue ? sourceFieldValue : 0;
        // sets flag to understand if we have a source
        this.hasSourceValue = !!sourceFieldValue;

        const adjustmentsFound: string[] = [];

        this.extendedCostAdjustmentTypes.forEach(x => {
            const adjustment: Compliance.AssetCostAdjustmentModel = costAdjustments[x.costAdjustmentTypeId];

            if (adjustment) {
                if (adjustment.adjustmentAmount !== null && adjustment.adjustmentAmount !== undefined && adjustment.adjustmentAmount !== 0) {
                    runningTotal += adjustment.adjustmentAmount;
                    adjustmentsFound.push(`$${this._decimalPipe.transform(adjustment.adjustmentAmount, '1.2-2')} ${x.typeName}`);
                } else if (adjustment.adjustmentPercentage !== null && adjustment.adjustmentPercentage !== undefined && adjustment.adjustmentPercentage !== 0) {

                    const percentCalcRh = x.applyToSource ? sourceFieldValue : runningTotal;
                    const adjustmentAmountFromPercentage = _.round(adjustment.adjustmentPercentage * percentCalcRh / 100, 2);

                    runningTotal += adjustmentAmountFromPercentage;
                    adjustmentsFound.push(adjustment.adjustmentPercentage + '%' + ' ($' + this._decimalPipe.transform(adjustmentAmountFromPercentage, '1.2-2') + ') ' + x.typeName);
                }
                this.costAdjustmentCalculations[x.costAdjustmentTypeId] = `$${this._decimalPipe.transform(runningTotal, '1.2-2')}`;
            }
        });

        this.calculatedCostValue = parseFloat(runningTotal.toFixed(2));
        this._calculatedCostEventEmitter.next(this.calculatedCostValue);
        this.calculatedCost = `$ ${this._decimalPipe.transform(runningTotal, '1.2-2')}`;

        let adjustmentDescription = '';
        if (adjustmentsFound.length === 0) {
            this.hasAdjustments = false;
        } else {
            this.hasAdjustments = true;
            adjustmentDescription = '* Adjusted source ' + this.calculatedCost + ' using ';
            for (let i = 0; i < adjustmentsFound.length; i++) {
                adjustmentDescription += adjustmentsFound[i];
                if (i === adjustmentsFound.length - 1) {
                    adjustmentDescription += '.';
                } else {
                    adjustmentDescription += ((i < adjustmentsFound.length - 2) ? ', ' : ' and ');
                }
            }
        }
        this.costAdjustmentDescription = adjustmentDescription;
    }

    private _checkAssetsMatchCalculatedSourceCost(assets: AssetSummary[]): void {
        const expectedTotal = this.calculatedCostValue;
        let allocatedTotal = new Decimal(0);
        assets.forEach((asset: AssetSummary) => {
            if (asset.transientReportedCost != null) {
                allocatedTotal = allocatedTotal.add(asset.transientReportedCost);
            }
        });
        this.allocatedTotal = allocatedTotal.toDecimalPlaces(2).toNumber();
        this.assetValueMatched = (expectedTotal == this.allocatedTotal);
    }

}
