import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { DefaultDisplayTypes } from '../../../../constants.new';
import { SmartSearchService } from '../../../../Search/Advanced/smartSearch.service';

export interface SearchDefaultDisplayModalParams {
    search: Core.AdvancedSearchList;
    searchType: Core.SearchCategoryType;
}

@Component({
    selector: 'search-default-display',
    templateUrl: './searchDefaultDisplay.component.html'
})
export class SearchDefaultDisplayComponent implements IWeissmanModalComponent<SearchDefaultDisplayModalParams, void>, OnInit {
    constructor(private readonly _smartSearchService: SmartSearchService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: SearchDefaultDisplayModalParams;
    result: void;

    modalTitle: string;
    search: Core.AdvancedSearchList;
    searchType: number;
    defaultDisplayTypes = DefaultDisplayTypes;

    ngOnInit(): void {
        this.search = this.params.search;
        this.searchType = this.params.searchType;
        this.modalTitle = `Change "${this.search.searchName}" Default Display`;
    }

    async save() {
        const result = await this._smartSearchService.patchSearch(this.search, this.searchType);
        this.search.rowVersion = result.rowVersion;
        this.cancel();
    }

    cancel() {
        this._bsModalRef.hide();
    }
}
