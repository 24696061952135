<div class="panel-flat-body format-psr">
    <div class="row">
        <div class="col col-lg-6 psr-output-col1">
            <div style="width: 56%; margin: 0 auto;">
                <div class="mb-4 form-group">
                    <label>Output Format:</label>
                    <select [(ngModel)]="report.criteria.outputFormat" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of outputFormatOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-4 form-group">
                    <label>Paper Size:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.paperSize" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of paperSizeOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showAcqInfo"> Show
                        Acquisition Info
                    </label>
                </div>
                <div class="checkbox" *ngIf="report.reportTypeId === reportTypes.AppealRecommendation">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showYearBuiltInfo">
                        Show Year Built Info
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showAppealDeadlines"> Show
                        Appeal Deadlines
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showAppealDetails"> Show
                        Appeal Details
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showComments"> Show Comments
                    </label>
                </div>

                <div class="mb-4" *ngIf="report.reportTypeId === reportTypes.AppealRecommendation">
                    <label>Show Site Appeal Comments:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showSiteAppealCommentsValue" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showSiteAppealCommentsOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>

                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showParcelList"> Show Parcel List
                    </label>
                </div>
                <div class="checkbox">
                    <label style="vertical-align: middle;">
                        <input type="checkbox" [(ngModel)]="report.criteria.psrFormatOutputOptions.showParcelsAddedSinceCheckbox"> Show Parcels Added Since
                    </label>
                    <div [ngClass]="{'invisible': !report.criteria.psrFormatOutputOptions.showParcelsAddedSinceCheckbox}"
                         style="display: inline-block; vertical-align: middle;">
                        <weissman-datetime
                            [(inputDate)]="report.criteria.psrFormatOutputOptions.showParcelsAddedSince"
                            [dateOnly]="true"
                            [timeZone]="'Central'">
                        </weissman-datetime>
                    </div>
                </div>
                <table style="width: 100%">
                    <tr>
                        <th>Order By</th>
                        <th *ngIf="orderAndTotalBySelected && orderAndTotalBySelected.id || orderAndTotalBySelection.length" style="text-align: center;">Group By</th>
                        <th style="text-align: center;"></th>
                    </tr>
                    <tr *ngFor="let field of orderAndTotalBySelection">
                        <td>{{field.name}}</td>
                        <td style="text-align: center;"><input type="checkbox" [(ngModel)]="field.groupByThisItem" (change)="groupByChanged(field)"></td>
                        <td style="text-align: center;"><i class="fa fa-close" (click)="removeOrderAndTotalBy(field)"></i></td>
                    </tr>
                    <tr>
                        <td>
                            <select class="form-select form-select-sm order-by" [(ngModel)]="orderAndTotalBySelected" (change)="orderAndTotalByChanged()">
                                <option *ngFor="let option of orderAndTotalByOptions" [ngValue]="option">{{option.name}}</option>
                            </select>
                        </td>
                        <td style="text-align: center;" *ngIf="orderAndTotalBySelected && orderAndTotalBySelected.id || orderAndTotalBySelection.length"><input *ngIf="orderAndTotalBySelected && orderAndTotalBySelected.id" type="checkbox" [(ngModel)]="orderAndTotalBySelected.groupByThisItem"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <button class="flat-button small-button primary-button" [class.invisible]="orderAndTotalBySelected && !orderAndTotalBySelected.id" (click)="addNewOrderAndTotalBy()">Add More</button>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col col-lg-6 psr-output-col2">
            <div style="width: 93%; margin: 0 auto;">
                <div class="mb-4">
                    <label>Show Assessments:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showAssessments" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showAssessmentsOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label>Show Metrics:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showMetrics" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showMetricsOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label>Show Initial Value:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showInitValue" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showValueOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-4">
                    <label>Show Target Value:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showTargetValue" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showValueOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label>Show Latest Value:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showLatestValue" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showLatestValueOptions; let i = index;" [ngValue]="i+1">{{option}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label>Show Tax Details:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showTaxDetails" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showTaxDetailsOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label>Show Savings:</label>
                    <select [(ngModel)]="report.criteria.psrFormatOutputOptions.showSavings" class="form-select form-select-sm intput-sm">
                        <option *ngFor="let option of showSavingsOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
