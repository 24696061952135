<div *ngIf="showNub"
     class="search-minimized"
     helpTooltip
     [helpContentId]="searchTooltip"
     position="left"
     [forcePosition]="true">
    <button [ngClass]="searchType"
            (click)="toggleSearch()">
        <i class="fa fa-search fa-lg"></i>
    </button>
</div>
