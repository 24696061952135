import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ParcelReportsSitesListGridNameCellRendererParams extends ICellRendererParams {
    getName: (params: ParcelReportsSitesListGridNameCellRendererParams) => string;
    navigate: (params: ParcelReportsSitesListGridNameCellRendererParams) => void;
    canNavigate: boolean;
}

@Component({
    selector: 'parcel-reports-sites-list-grid-name-cell',
    template: `
<span *ngIf="!params.canNavigate" [title]="params.getName(params)">{{ params.getName(params) }}</span>
<button *ngIf="params.canNavigate" class="btn btn-link ws-no-padding ws-no-margin" [title]="params.getName(params)" (click)="params.navigate(params)">{{ params.getName(params) }}</button>
    `
})
export class ParcelReportsSitesListGridNameCellRendererComponent implements ICellRendererAngularComp {
    params: ParcelReportsSitesListGridNameCellRendererParams;

    agInit(params: ParcelReportsSitesListGridNameCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
