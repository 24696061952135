<!-- Inactive Image -->
<img *ngIf="!visible && activityStatusId !==2"
     [src]="getInactiveURL()"
     class="status-img img-responsive float-end"
     [ngClass]="{ 'from-top': offsetFrom === 'top', 'from-bottom': offsetFrom === 'bottom' }"
     [ngStyle]="{ 'right': rightOffset + 'px', 'bottom': inactiveOffset }"
     helpTooltip
     [contentComponent]="helpContentComponent"
     [contentComponentValue]="dataValue">

<!-- Sold Image -->
<img *ngIf="!visible && transaction && transaction.soldDate"
     src="/images/sold.png"
     alt="Sold"
     class="sold-img img-responsive float-end"
     [ngClass]="{ 'from-top': offsetFrom === 'top', 'from-bottom': offsetFrom === 'bottom' }"
     [ngStyle]="{ 'right': rightOffset + 'px', 'top': soldOffset }"
     helpTooltip
     [tooltipText]="(transaction.soldDate ? (transaction.soldDate | date:'MM/dd/yyyy':'utc') : null)">
