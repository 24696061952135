<div class="ws-section__header__actions__item" *ngIf="currentTask">
    <div class="ws-flex-container-horizontal">
        <div class="action-button-wrapper"
             helpTooltip
             [tooltipText]="currentTaskTooltip"
             position="bottom">
            <button type="button"
                    class="flat-button primary-button small-button"
                    [disabled]="(!canEnterEditMode) || (!canEdit) || isLongRunningProcessActive"
                    (click)="completeCurrentTask()">
                {{ currentTask.title }}
            </button>
        </div>
    </div>
</div>
