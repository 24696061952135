import { SavedActionViewService } from '../../Navigation/Saved-AV/savedActionView.service';

(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('SaveActionViewModalController', SaveActionViewModalController);

	SaveActionViewModalController.$inject = [
        '$uibModalInstance',
        'actionViewService',
        'ActionViewCategoryTypes',
        'ActionViewDisplayTypes',
        'actionViewObj',
        'filterColumns',
        'utils',
        '$rootScope',
        'hasEditPermission',
        '$state',
        'toastr',
        'userSaveService'
	];

	function SaveActionViewModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, actionViewService, ActionViewCategoryTypes, ActionViewDisplayTypes, actionViewObj, filterColumns, utils, $rootScope, hasEditPermission, $state, toastr, userSaveService) {
		var vm = this;
		vm.selectedDisplayType = 0;

		vm.actionView = actionViewObj
		vm.actionViewCategories = [];
		vm.displayTypes = ActionViewDisplayTypes;
		vm.hasEditPermission = hasEditPermission;

		vm.categoryTypes = ActionViewCategoryTypes;
		vm.actionViewTypeFilter = actionViewTypeFilter;
		vm.cancel = cancel;
		vm.saveActionView = saveActionView;
		vm.toggled = toggled;
		vm.createNewCategory = createNewCategory;
		vm.findSystemCategory = findSystemCategory;
		vm.thatAreCompleted = thatAreCompleted;
		vm.actionViewName = '';

		if (vm.actionView.defaultDisplay) {
			vm.selectedDisplayType = vm.actionView.defaultDisplay;
		}


		activate();

		////////////////

		function activate() {
			vm.actionView.type = vm.actionView.type || ActionViewCategoryTypes.CUSTOM;
			vm.actionView.categoryId = vm.actionView.categoryId || null;

			actionViewService.getCategories()
				.then(function (result) {

					vm.actionViewCategories = _.sortBy(result, x => x.categoryName.toLowerCase());
				});

			utils.focusOnElement('[ng-model="vm.actionView.actionViewName"]')
		}

		function actionViewTypeFilter(category) {
			return category.categoryType === vm.actionView.type || (!category.categoryId && vm.actionView.type === ActionViewCategoryTypes.CUSTOM);
		}

		function toggled(open) {
			if (open) {
				utils.focusOnElement('#new-action-view-category');
			}
		}

		function findSystemCategory() {
			if (!vm.actionView.categoryId) {
				var sysCategory = _.find(vm.actionViewCategories, { categoryType: ActionViewCategoryTypes.SYSTEM });
				if (sysCategory) {
					vm.actionView.categoryId = sysCategory.categoryId;
				}

			}
		}

		function cancel() {
			$uibModalInstance.dismiss();
		}

		function createNewCategory() {
			if (!vm.newCategory) {
				return;
			}

			vm.actionViewCategories.push({
				categoryName: vm.newCategory,
				categoryType: vm.actionView.type,
				categoryId: 'new'
			});

			vm.actionViewCategories = _.sortBy(vm.actionViewCategories, x => x.categoryName.toLowerCase())

			vm.actionView.categoryId = 'new';

			vm.categoryEntered = true;
			vm.enteringCategory = false;

			utils.focusOnElement('[ng-model="vm.actionView.actionViewName"]')
		}

		function saveActionView() {
			var category = {};

			if (vm.actionView.categoryId === 'new') {
				category = _.find(vm.actionViewCategories, { categoryId: 'new' });

				actionViewService.createCategory(_.omit(category, 'categoryId'))
					.then(function (result) {
						vm.actionView.categoryId = result.categoryId;

						createOrUpdate().then(broadcastAndClose)
							.catch(errorToast);
					})
					.catch(function (error) {
						if (error.status === 409) {
							toastr.error('Error!', 'Category already exists.');
						} else {
							toastr.error('Error!', 'Server Error')
						}
					})
			} else {
				createOrUpdate().then(broadcastAndClose)
					.catch(errorToast);
			}
		}

		function broadcastAndClose(savedActionView) {
            userSaveService.setDirty(1); //SearchPageType.AV
			$rootScope.$broadcast('updateActionViewMenu');

			$state.transitionTo('actionview', { actionViewId: savedActionView.actionViewId, actionViewType: vm.actionView.type }, { reload: true })
			$uibModalInstance.close();
		}

		function errorToast(error) {
			if (error.status === 409) {
				toastr.error('Error!', 'Action View name/category already exists');
			} else {
				toastr.error('Error!', 'Server Error')
			}
		}

		function createOrUpdate() {
			var selectedCategory = _.find(vm.actionViewCategories, { categoryId: vm.actionView.categoryId});
			var newSequence = (selectedCategory && selectedCategory.actionViewList.length > 0) ? selectedCategory.actionViewList.length : 0;

			vm.actionView.sequence = newSequence;
			vm.actionView.defaultDisplay = vm.selectedDisplayType;


			if (vm.actionView.type === ActionViewCategoryTypes.CUSTOM) {
				return actionViewService.createCustomActionView(_.omit(vm.actionView, 'type'), filterColumns);
			} else {
				return actionViewService.createSystemActionView(_.omit(vm.actionView, 'type'), filterColumns);
			}

		}

		function thatAreCompleted() {
			return actionViewObj.masterFilter.ThatAreChoice === 4;
		}
	}
})();
