<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="title"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!rowsSelected">
    <ng-container *ngIf="entityPicker">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </ng-container>
    <div class="ws-flex-container-horizontal modal-options" *ngIf="entityPicker.optionFlagText">
        <div class="checkbox">
            <label>
                <input type="checkbox" [(ngModel)]="entityPicker.optionFlag" (change)="entityPicker.optionFlagChange()">{{ entityPicker.optionFlagText }}
            </label>
        </div>
    </div>
</ws-modal-wrapper>
