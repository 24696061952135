import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

@Injectable(
    { providedIn: 'root' }
)
export class InstancePermissionService {

	CONTACT_API_FRAGMENT: string = '/api/contact/';
	GROUP_API_FRAGMENT: string = '/api/usergroup/';
	INSTANCE_RIGHTS_API_FRAGMENT: string = '/api/InstanceRight/';

    constructor(private http: HttpClient) {
    }

    async getAllByContactId(contactId: number) {
        return await lastValueFrom(this.http.get<Core.InstanceRightModel[]>(
            `${this.CONTACT_API_FRAGMENT}${contactId}/rights-available`
        ));
    }

    async getByContactId(contactId: number) {
        return await lastValueFrom(this.http.get<Core.InstanceRightModel[]>(
            `${this.CONTACT_API_FRAGMENT}${contactId}/rights`
        ));
    }

    async getAllByGroup(groupId: number) {
        return await lastValueFrom(this.http.get<Core.InstanceRightModel[]>(
            `${this.GROUP_API_FRAGMENT}${groupId}/rights-available`
        ));
    }

    async getByGroupId(groupId: number) {
        return await lastValueFrom(this.http.get<Core.InstanceRightModel[]>(
            `${this.GROUP_API_FRAGMENT}${groupId}/rights`
        ));
    }

    async getForConsultingEngagement(companyId: number) {
        return await lastValueFrom(this.http.get<Core.InstanceRightModel[]>(
            `${this.INSTANCE_RIGHTS_API_FRAGMENT}company/${companyId}/ForConsultingEngagement`
        ));
    }
}
