import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { CommentsModule } from '../Comments/comments.module';
import { UnitedStatesComponent } from './states.component';
import { USStateComponent } from './us.state.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { StateService } from '../Common/States/States.Service';
import { StateAssessorSharedModule } from './stateAssessorShared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { StateDetailsComponent } from './State-Details/state.details.component';
import { ContactModule } from '../Contact/contact.module';

@NgModule({
    imports: [
        WeissmanSharedModule,
        CommentsModule,
        StateAssessorSharedModule,
        ContactModule,
        TypeaheadModule
    ],
    declarations: [
        UnitedStatesComponent,
        USStateComponent,
        StateDetailsComponent
    ],
    providers: [StateService]
})
export class StateModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('usState', USStateComponent);
        hybridAdapterUtility.downgradeNg2Component('unitedStates', UnitedStatesComponent);
    }
}
