import { Component, Input } from "@angular/core";
import { EntityDescriptorModelUI } from '../descriptor.model';

@Component({
    selector: 'entity-property-characteristics-list',
    template: `
        <table class="entity-descriptor-table" [class.edit-mode-table]="isEditMode">
            <tr #formRow *ngFor="let descriptor of descriptors">
                <td>
                    <label [tooltip]="descriptor.descriptor.notes" 
                        placement="left" 
                        container="body" 
                        style="white-space: nowrap;" 
                        [class.text-muted]="descriptor.isDeleted">
                            {{descriptor.descriptor.name}}:
                    </label>
                </td>
                <td>
                    <entity-property-characteristics-input 
                         [descriptor]="descriptor" 
                         [isEditMode]="isEditMode" 
                         [saveAttempted]="saveAttempted" 
                         [disabled]="descriptor.isDeleted">
                    </entity-property-characteristics-input>
                </td>
                <td style="padding-left: 10px;">
                    <div [class.invisible]="!isEditMode">
                        <button *ngIf="!descriptor.isDeleted"
                            type="button"
                            class="flat-button icon-button warning-button"
                            style="margin: 0;"
                            [disabled]="!descriptor.allowDeleteFromEntity && descriptor.rowVersion.length"
                            (click)="descriptor.isDeleted = true">
                                <i class="fa fa-trash"></i>
                        </button>
                        <button *ngIf="descriptor.isDeleted" type="button" class="flat-button icon-button primary-button" style="margin: 0;"
                            (click)="descriptor.isDeleted = false">
                            <i class="fa fa-undo"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    `,
    styles: [`
        .entity-descriptor-table td {
            vertical-align: middle;
        }

        .entity-descriptor-table td:first-child {
            padding-right: 15px;
        }

        .entity-descriptor-table label {
            margin: 0;
        }

        .edit-mode-table td {
            padding-bottom: 3px;
        }
    `]
})
export class EntityPropertyCharacteristicsListComponent  {
    @Input() descriptors: EntityDescriptorModelUI[];
    @Input() isEditMode: boolean;
    @Input() saveAttempted: boolean;

    constructor() { }
}