import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { AdvancedSearchPersistenceService } from '../../Search/Advanced/advancedSearchPersistence.service';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { NUB_HELP } from './nub.help';
import { NubService } from './nub.service';

@Component({
    selector: 'nub',
    templateUrl: './nub.component.html',
    styleUrls: ['./nub.component.scss']
})
export class NubComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _nubService: NubService,
        private readonly _helpService: HelpService,
        private readonly _actionViewPersistenceService: ActionViewPersistenceService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _uiRouter: UIRouter
    ) {
    }

    showNub: boolean;
    searchTooltip: string;
    searchType: 'quick-search' | 'advanced-search' | 'action-view';

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._helpService.setContent(NUB_HELP);
        this._nubService.activeNub$.pipe(takeUntil(this._destroy$))
            .subscribe((nub) => {
                this.showNub = nub.showNub;
                this.searchType = nub?.searchType;
                this.searchTooltip = nub.searchTooltip;
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    toggleSearch(): void {
        switch (this.searchType) {
            case 'quick-search':
                this._nubService.showQuickSearch();
                break;
            case 'advanced-search':
                this._productAnalyticsService.logEvent('click-smart-results-tab');
                this._uiRouter.stateService.transitionTo('search', this._advancedSearchPersistenceService.routeParams);
                break;
            case 'action-view':
                this._productAnalyticsService.logEvent('click-action-view-results-tab');
                this._uiRouter.stateService.transitionTo('actionview', this._actionViewPersistenceService.routeParams);
                break;
        }
        this._nubService.hideNub();
    }
}
