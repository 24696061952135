import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BudgetTaxYearMissingSyncData} from './forecasting.budgeting.model';

@Component({
    selector: 'budget-taxyear-create-missing',
    templateUrl: './budget.taxyear.create.missing.component.html',
    styleUrls: ['./forecasting.budgeting.page.scss']
})
export class BudgetTaxYearCreateMissingComponent {
    @Input() missingSyncData: BudgetTaxYearMissingSyncData;
    @Input() lrUpdateInProgress: boolean = false;
    @Input() taxYear: number;
    @Input() editMode: boolean;
    @Input() isBudgetFrozen: boolean;
    @Output() createClick = new EventEmitter<void>();

    createMissing() : void {
        this.createClick.next();
    }

    isMissingCreateAllowed() : boolean {
        return !this.lrUpdateInProgress &&
               (this.missingSyncData.numMissingTaxYears > 0 || this.missingSyncData.numMissingBudgets > 0) &&
               !this.isBudgetFrozen;
    }
}