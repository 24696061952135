<div class="card panel-flat-color secondary-panel" *ngIf="hasViewOrEditPermission">
    <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !isEdit}">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Contacts</h3>

        <div class="float-end header-button-container" (click)="$event.stopPropagation()">

            <label *ngIf="!isEdit && showBody && hasEditPermission && isCompany" style="margin-right: 15px;">
                <input type="checkbox" [ngModel]="showAll" (change)="showAllContacts()"> Show All
            </label>

            <button class="btn flat-button icon-button primary-button" *ngIf="!isEdit && showBody && (hasEditPermission || isConsultantUser)" (click)="edit()">
                <i class="fa fa-pencil"></i>
            </button>
            <button class="btn flat-button icon-button warning-button" *ngIf="isEdit && showBody" (click)="cancel()">
                <i class="fa fa-close"></i>
            </button>
        </div>
    </div>
    <div class="card-body ws-flex-container-vertical" *ngIf="showBody" style="height: 300px">
        <loading-spinner [show]="isLoading" [size]="3" [marginTop]="0"></loading-spinner>
        <contacts-panel-body-actions *ngIf="isEdit"
                                     [entity]="entity"
                                     [contacts]="contacts"
                                     [roles]="roles"
                                     [isConsultantUser]="isConsultantUser"
                                     [hasAssociateExistingPermission]="hasEditPermission || isConsultantUser"
                                     style="margin-bottom: 10px;">
        </contacts-panel-body-actions>
        <entity-contact-list class="ws-stretch"
                             *ngIf="!isLoading && contacts.length > 0"
                             [entity]="entity"
                             [contacts]="contacts"
                             [hasEditPermission]="hasEditPermission || isConsultantUser"
                             [hasViewPermission]="hasViewOrEditPermission"
                             [editMode]="isEdit"
                             [roles]="roles"
                             (contactsUpdated)="load()">
        </entity-contact-list>
        <div *ngIf="!isLoading && contacts.length === 0">
            <h4>No contacts.</h4>
        </div>
    </div>
</div>
