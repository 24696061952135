import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface AgGridActionButtonConfig {
    iconClass: string;
    onClick: (params: AgGridActionCellRendererParams) => void;
    isShown: (params?: AgGridActionCellRendererParams) => boolean;
    buttonClass?: string;
    helpContentId?: string;
    disabled?: () => boolean;
}

export interface AgGridActionCellRendererParams extends ICellRendererParams {
    buttonConfigs: AgGridActionButtonConfig[];
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<ng-container *ngFor="let button of params.buttonConfigs">
            <button type="button"
                    *ngIf="button && button.isShown(params)"
                    class="btn flat-button icon-button grid-action-button"
                    [ngClass]="(button.buttonClass ? button.buttonClass : 'primary-button')"
                    (click)="button.onClick(params)"
                    helpTooltip
                    [helpContentId]="button.helpContentId"
                    position="bottom"
                    [disabled]="(button.disabled ? button.disabled() : false)">
                <i class="fa" [ngClass]="button.iconClass"></i>
            </button>
        </ng-container>
        `
})
export class AgGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: AgGridActionCellRendererParams;

    agInit(params: AgGridActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
