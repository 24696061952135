<h4>{{engagement.name}} Permissions</h4>
<div style="margin-top:20px;"></div>

<!--list of assigned permisisons -->
<div *ngFor="let permission of engagement.permissions">
    <!-- select list of permissions for screen type -->
    <select class="form-select form-select-sm" style="width: 100px; display: inline-block;"
        [(ngModel)]="permission.instanceRightID"
        (change)="rightSelected(permission)"
        *ngIf="userHasInstanceRight(permission)">
        <option *ngFor="let permission of availablePermissionRights(permission.screenTypeID)" [ngValue]="permission.instanceRightID">
            {{permission.rights}}
        </option>
    </select>
    <!-- label if right is assigned but user does not have it-->
    <span *ngIf="!userHasInstanceRight(permission)" style="width: 100px; display: inline-block;" class="application-permission-label">{{permission.rights}}</span>

    <!--screen type label -->
    <span style="margin-left:14px;">{{permission.screenType}}</span>

    <!-- delete button-->
    <div class="float-end" *ngIf="userHasInstanceRight(permission)" style="margin-top: 4px;">
        <button type="button" class="btn flat-button icon-button warning-button" (click)="deletePermission(permission)"><i class="fa fa-trash"></i></button>
    </div>
    <div class="clearfix"></div>
</div>

<!-- add new permission section-->
<div style="margin-top: 10px;">
    <!-- add new right button-->
    <div *ngIf="!editMode">
        <button type="button" class="btn flat-button small-button primary-button" (click)="selectPermissionToAdd()">Add Instance Right</button>
    </div>

    <div *ngIf="editMode">
        <div *ngIf="getUniqueAvailableScreenTypes().length > 0">
            <!-- permission selection-->
            <select class="form-select form-select-sm" style="width:100px; display: inline-block;" [(ngModel)]="permissionToAdd.permission" [disabled]="permissionToAdd.screenTypeID === null">
                <option *ngFor="let permission of screenTypePermissions" [ngValue]="permission.rightsID">
                    {{permission.rights}}
                </option>
            </select>

            <!-- screen type-->
            <select class="form-select form-select-sm" style="width:auto; display: inline-block;" (change)="screenTypeSelected()" [(ngModel)]="permissionToAdd.screenTypeID">
                <option *ngFor="let type of getUniqueAvailableScreenTypes()" [ngValue]="type.screenTypeID">
                    {{type.screenType}}
                </option>
            </select>

            <span>
                <!-- save button-->
                <button class="btn flat-button icon-button success-button" style="margin-left: 10px;" type="button" (click)="addPermission()" [disabled]="permissionToAdd.permission === null"><i class="fa fa-save"></i></button>
                <!-- close button-->
                <button class="btn flat-button icon-button warning-button" type="button" (click)="closeAddPermission()"><i class="fa fa-close"></i></button>
            </span>
        </div>
        <div  *ngIf="getUniqueAvailableScreenTypes().length === 0">
            No additional permissions to add <a class="btn btn-default btn-xs" (click)='closeAddPermission()'>close</a>
        </div>
    </div>
</div>
