import { Injectable } from '@angular/core';
import { SDHttpService } from '../Routing/sd-http.service';


@Injectable(
    { providedIn: 'root' }
)
export class CoreLongRunningProcessService {
    constructor(private sdHttp: SDHttpService) {}

    cancel(longRunningProcessId: number): Promise<void> {
        return this.sdHttp.put(`/api/longrunningprocess/${longRunningProcessId}/cancel`);
    }

    pause(longRunningProcessId: number): Promise<void> {
        return this.sdHttp.put(`/api/longrunningprocess/${longRunningProcessId}/pause`);
    }

    resume(longRunningProcessId: number): Promise<number> {
        return this.sdHttp.put(`/api/longrunningprocess/${longRunningProcessId}/resume`);
    }
}
