import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { FormService } from '../form.service';
import { FormFactorTableAddComponent } from '../Form-Factor-Table-Add/formFactorTableAdd.component';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { ColDef, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { AgGridOptionsBuilder } from '../../AgGrid';
import { FormFactorTableListGridActionCellRendererComponent, FormFactorTableListGridActionCellRendererParams } from './agGridActionCellRenderer.component';
import { IMutexServiceHandler, WeissmanMutexService } from '../../WeissmanMutexService';
import { BaseExpandableAgGridComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { BehaviorSubject, Subject } from 'rxjs';
import { FactorTableDetailsComponent, FactorTableDetailsParams } from '../../Factor-Table/Factor-Table-Details/factorTableDetails.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AgGridMultiSelectCellRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectHeaderRendererParams, AgGridMultiSelectTracker } from '../../AgGrid/MultiSelectTracker';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import * as _ from 'lodash';
import { FormRevisionReportRowModel } from '../Form-Report-List/formReportList.component';

export interface FormFactorTableRowModel extends Compliance.FormFactorTableModel {
    deleted: boolean;
    added: boolean;
    originalValue: Compliance.FormRevisionReportModel;
    displayName: string;
}

@Component({
    selector: 'form-factor-table-list',
    templateUrl: './formFactorTableList.component.html',
    styleUrls: ['./formFactorTableList.component.scss']
})
export class FormFactorTableListComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService) {
        super(_formService, 'form-factor-table-list');
    }

    private _gridApi: GridApi;
    private _gridTracker: AgGridMultiSelectTracker;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _destroy$: Subject<void> = new Subject();

    get assessor(): Compliance.FormRevisionAssessorModel {
        return this._formService.assessor;
    }

    get factorTablesAssessorName(): string {
        return this._formService.factorTablesAssessorName;
    }

    get canEnterEditMode(): boolean {
        return this._formService.assessor &&
            this._mutexService.canAcquire(this._formService.editGroup) &&
            (!this._formService.assessor.isMappingCertified &&
                (!this._formService.assessor.isUsingDefaultTables ||
                    (this._formService.assessor && !this._formService.assessor.assessorId)));
    }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get taxYear(): number {
        return this._formService.taxYear;
    }

    get hasSelectedRows(): boolean {
        return this._gridTracker && this._gridTracker.hasSelectedRows();
    }

    get hasChanges(): boolean {
        let hasChanges: boolean = false;

        if (this._gridApi) {
            this._gridApi.forEachNode((node: RowNode) => {
                const report = node.data as FormRevisionReportRowModel;
                if (report && (report.deleted || report.added)) {
                    hasChanges = true;
                }
            });
        }

        return hasChanges;
    }

    editMode: boolean = false;
    canRemove: boolean = false;
    canUndoRemove: boolean = false;
    hasRemovedSelected: boolean = false;
    gridId: System.Guid = '5DAD3F51-DA76-4A6B-B937-2CD709963E11';

    gridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'ag-row-selected': (params) => {
                    const data = params.data as Compliance.FormFactorTableModel;
                    return data && this._gridTracker.isRowSelected(data.formFactorTableId);
                },
                'grid-row-deleted': params => params.data && params.data.deleted
            },
            onFilterChanged: () => this._gridTracker.onGridFilterChanged(),
            onSortChanged: () => this._gridTracker.onGridSortChanged()
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    ngOnInit(): void {
        this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (this.gridOptions && this.gridOptions.columnApi) {
                this.gridOptions.columnApi.setColumnVisible('formFactorTableId', this.editMode);
                this.gridOptions.api.sizeColumnsToFit();
            }
            if (!this.editMode) {
                this._mutexService.release(this, this._formService.editGroup);
            }
        });

        this._formService.formRevision$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // initial load
            this._setRowData();
        });

        this._formService.formRevisionYear$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // year changed
            this._setRowData();
        });

        this._formService.assessor$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // assessor selected
            this._setRowData();
        });

        this._formService.assessors$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // assessors changed (add/remove/change defaults)
            this._setRowData();
        });

        this._formService.factorTables$.pipe(takeUntil(this._destroy$)).subscribe(() => {
            // factor tables changed
            this._setRowData();
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._mutexService.release(this, this._formService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        super.setGridApi(event.api);
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            const rows = this._getRowNodesFromSelectModel(x);
            this.hasRemovedSelected = !!rows.find(x => x.data.deleted);
        });

        const columns: ColDef[] = [
            {
                field: 'formFactorTableId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                hide: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this._gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.FormFactorTableModel) => !this._canEditFactorTable()
                } as AgGridMultiSelectHeaderRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker,
                    isCellRendererDisabledFn: (rowId: number, data: Compliance.FormFactorTableModel) => !this._canEditFactorTable()
                } as AgGridMultiSelectCellRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessor',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                suppressMenu: true,
                valueFormatter: (params) => {
                    const formFactorTable = params.data as Compliance.FormFactorTableModel;
                    if (!formFactorTable) {
                        return '';
                    }
                    return formFactorTable.assessor ? formFactorTable.assessor : formFactorTable.state;
                },
                filterValueGetter: params => {
                    const formFactorTable = params.data as Compliance.FormFactorTableModel;
                    if (!formFactorTable) {
                        return '';
                    }
                    return formFactorTable.assessor ? formFactorTable.assessor : formFactorTable.state;
                },
                cellClass: params => {
                    const formFactorTable = params.data as Compliance.FormFactorTableModel;
                    if (!formFactorTable) {
                        return '';
                    }
                    return formFactorTable.assessor ? '' : 'assessor-state';
                }
            },
            {
                headerName: 'Name',
                field: 'factorTableName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            },
            {
                headerName: 'Life',
                field: 'life',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                lockPinned: true,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                suppressMenu: true
            },
            {
                headerName: 'Type',
                field: 'TableType',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const formFactorTable = params.data as Compliance.FormFactorTableModel;
                    if (!formFactorTable) {
                        return '';
                    }
                    return this._formService.getFactorTableTypeName(formFactorTable.tableType);
                },
                filterValueGetter: params => {
                    const formFactorTable = params.data as Compliance.FormFactorTableModel;
                    if (!formFactorTable) {
                        return '';
                    }

                    return this._formService.getFactorTableTypeName(formFactorTable.tableType);
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                suppressMenu: true,
                sortable: false,
                cellRendererFramework: FormFactorTableListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this.viewDetails.bind(this),
                    canEdit: this._canEditFactorTable.bind(this),
                    canEnterEditMode: (params: FormFactorTableListGridActionCellRendererParams) => this.canEnterEditMode,
                    canRemove: this._canRemove.bind(this),
                    remove: this._remove.bind(this),
                    canUndoRemove: this._canUndoRemove.bind(this),
                    undoRemove: this._undoRemove.bind(this)
                } as FormFactorTableListGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessor',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async edit(): Promise<void> {
        if (!this.canEdit) {
            return;
        }
        const res = await this._formService.checkEditDefaultAssessor();
        if (res) {
            this._mutexService.acquire(this, this._formService.editGroup);
            this._editModeSubject.next(true);
        }
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    cancel(): void {
        const update: RowNode[] = [];
        const remove: FormRevisionReportRowModel[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const report = node.data as FormRevisionReportRowModel;

            if (report && !report.added) {
                update.push(node);

                _.extend(report, report.originalValue, { changed: false, deleted: false });

            } else { // if added, remove it
                remove.push(report);
            }
        });

        this._gridApi.updateRowData({ remove: remove });
        this._gridApi.redrawRows({ rowNodes: update });

        this._editModeSubject.next(false);
    }

    async add(): Promise<void> {
        const result = await this._modalService.showAsync(FormFactorTableAddComponent, null, 'modal-xl');

        if (!result) {
            return;
        }

        const newReports = result.map((x) => {
                return {
                    ...x,
                    ...{
                        formFactorTableId: x.factorTableId,
                        assessor: x.assessorName,
                        factorTableName: x.name,
                        isFrequentlyUsed: true,
                        isIncluded: false,
                        added: true,
                        deleted: false,
                        originalValue: null
                    }
                };
            });

        this._gridApi.updateRowData({ add: newReports });

        return Promise.resolve();
    }

    async save(force: boolean): Promise<void> {
        // Clear grid selections
        const rowModel = this._gridTracker.getSelectedRowsModel();
        const selected = this._getRowNodesFromSelectModel(rowModel);
        this._gridTracker.clear();
        this._gridApi.redrawRows({ rowNodes: selected });

        let associations = 0;
        let disassociations = 0;
        const update = [];

        this._gridApi.forEachNode(x => {
            const ft = x.data as FormFactorTableRowModel;
            associations += ft.added ? 1 : 0;
            disassociations += ft.deleted ? 1 : 0;
            if (!ft.deleted) {
                update.push(ft.factorTableId);
            }
        });

        if (!disassociations) {
            force = true;
        }

        if (!force) {
            try {
                await this._messageModalService.confirm(
                    `Are you sure you wish to ${associations ? `add ${  associations  } factor table${  associations > 1 ? 's' : ''}` : ''} ${associations && disassociations ? 'and' : ''} ${disassociations ? `remove ${  disassociations  } factor table${  disassociations > 1 ? 's' : ''}` : ''}?`,
                    'Confirm Save'
                );
            } catch (e1) {
                return;
            }
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Updating Factor Tables' });
        let confirmMessage: string = '';
        try {
            await this._formService.updateFactorTables(this._formService.assessorId, update, force);
            if (force) {
                this._editModeSubject.next(false);
                this._mutexService.release(this, this._formService.editGroup);
                this._setRowData();
                return;
            }
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }
        finally {
            await busyRef.hide();
        }

        if (confirmMessage) {
            try {
                await this._messageModalService.confirm(
                    confirmMessage,
                    'Confirm Update'
                );
            }
            catch (e3) {
                return Promise.resolve();
            }
        }

        // force
        await this.save(true);

        return Promise.resolve();
    }

    bulkRemove(): void {
        const rowModel = this._gridTracker.getSelectedRowsModel();
        const selected = this._getRowNodesFromSelectModel(rowModel);

        if (!selected.length) {
            return;
        }

        selected.forEach(x => {
            const ft = x.data as FormFactorTableRowModel;
            if (ft.added) {
                this._gridApi.updateRowData({ remove: [ft] });
            } else {
                ft.deleted = true;
            }
        });

        this._gridTracker.clear();
        this._gridApi.redrawRows({ rowNodes: selected });
    }

    bulkUndoRemove(): void {
        const rowModel = this._gridTracker.getSelectedRowsModel();
        const selected = this._getRowNodesFromSelectModel(rowModel);

        if (!selected.length) {
            return;
        }

        selected.forEach(x => {
            const ft = x.data as FormFactorTableRowModel;
            ft.deleted = false;
        });

        this._gridTracker.clear();
        this._gridApi.redrawRows({ rowNodes: selected });
    }

    async viewDetails(params: FormFactorTableListGridActionCellRendererParams): Promise<void> {
        const formFactorTable = params.data as Compliance.FormFactorTableModel;
        if (!formFactorTable) {
            return Promise.resolve();
        }

        const tableParams: FactorTableDetailsParams = {
            editMode: false,
            factorTableId: formFactorTable.factorTableId
        };

        await this._modalService.showAsync(FactorTableDetailsComponent, tableParams, 'modal-lg');

        return Promise.resolve();
    }

    private _canEditFactorTable(params?: FormFactorTableListGridActionCellRendererParams): boolean {
        // do not allow editing when the assessor is using defaults
        if (this._formService.assessor && this._formService.assessor.assessorId && this._formService.assessor.isUsingDefaultTables) {
            return false;
        }

        return this.canEdit;
    }

    private _setRowData() {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        this._gridApi.setRowData(this._formService.factorTables);
    }

    private _getGridRowIds(skip, take): Compliance.QueryResultModel<number> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const factorTable = x.data as Compliance.FormFactorTableModel;
                return factorTable && factorTable.formFactorTableId;
            })
        } as Compliance.QueryResultModel<number>;
    }

    private _remove(params: FormFactorTableListGridActionCellRendererParams): void {
        const ft = params.data as FormFactorTableRowModel;
        if (!ft) {
            return;
        }

        if (!ft.added) {
            ft.deleted = true;
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
            this._gridApi.updateRowData({ remove: [ft] });
        }
    }

    private _undoRemove(params: FormFactorTableListGridActionCellRendererParams): void {
        const ft = params.data as FormFactorTableRowModel;
        if (!ft) {
            return;
        }

        ft.deleted = false;

        this._gridApi.redrawRows({ rowNodes: [params.node] });
    }

    private _canRemove(params: FormFactorTableListGridActionCellRendererParams): boolean {
        const ft = params.data as FormFactorTableRowModel;
        if (!ft) {
            return false;
        }

        return this.editMode && !ft.deleted;
    }

    private _canUndoRemove(params: FormFactorTableListGridActionCellRendererParams): boolean {
        const ft = params.data as FormFactorTableRowModel;
        if (!ft) {
            return false;
        }

        return this.editMode && ft.deleted;
    }

    private _getRowNodesFromSelectModel(selectModel: Compliance.SelectedRowsModel): RowNode[] {
        const nodes = [];
        this._gridApi.forEachNode(x => {
            const ft = x.data as FormFactorTableRowModel;
            if ((!selectModel.selectAllRows && selectModel.selectedRows.find(y => ft.formFactorTableId === y))
                || (selectModel.selectAllRows && !selectModel.selectedRows.find(y => ft.formFactorTableId === y))) {
                nodes.push(x);
            }
        });
        return nodes;
    }
}
