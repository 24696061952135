import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ParcelCollectorTaxRateSetupModalComponent, ParcelCollectorTaxRateSetupModalResult } from './parcelCollectorTaxRateSetupModal.component';
import { BillClusterTaxRateSetupModalComponent, BillClusterTaxRateSetupModalResult } from './billClusterTaxRateSetupModal.component';
import { Observable } from 'rxjs';
import { TaxAuthorityRate, TaxSetupMinimumDTO, FirstEncounterSaveResult } from './parcelTaxRateService';
import { Bill } from '../../../Annual-Details/Taxes/bill.model';

@Injectable()
export class TaxRateSetupModalLaunchService {
    constructor(private modalService: BsModalService) { }

    openDocumentProcessingParcelTaxSetupModal(
        parcelId: number,
        parcelCollector: {
            parcelCollectorID?: number,
            rowVersion?: string,
            collectorID: number
        },
        overrideSetup: TaxSetupMinimumDTO,
        title: string,
        currentYear: number,
        readOnly: boolean
    ) {
        return new Observable<ParcelCollectorTaxRateSetupModalResult>(subscriber => {
            const initialState = {
                parcelId,
                parcelCollectorId: parcelCollector.parcelCollectorID,
                parcelCollectorRowVersion: parcelCollector.rowVersion,
                collectorId: parcelCollector.collectorID,
                displayYear: currentYear,
                availableYears: [currentYear],
                title,
                overrideSetup,
                modalResultSubscriber: subscriber,
                readOnly: !!readOnly,
                isDocumentProcessing: true
            };

            this.modalService.show(ParcelCollectorTaxRateSetupModalComponent, {
                initialState,
                ignoreBackdropClick: !readOnly,
                keyboard: !!readOnly,
                class: 'modal-lg'
            });
        });
    }

    openParcelCollectorTaxRateSetupModal(
        parcelId: number,
        parcelCollector: {
            parcelCollectorID?: number,
            rowVersion?: string,
            collectorID: number,
            taxRateAreaId?: number
        },
        title: string,
        availableYears: number[],
        currentYear: number,
        readOnly?: boolean,
        taxAuthorities?: TaxAuthorityRate[],
    ) {
        return new Observable<ParcelCollectorTaxRateSetupModalResult>(subscriber => {
            const initialState = {
                parcelId,
                parcelCollectorId: parcelCollector.parcelCollectorID,
                parcelCollectorRowVersion: parcelCollector.rowVersion,
                collectorId: parcelCollector.collectorID,
                originalTaxAuthorities: taxAuthorities,
                originalTaxRateAreaId: parcelCollector.taxRateAreaId,
                displayYear: currentYear,
                availableYears,
                title,
                modalResultSubscriber: subscriber,
                readOnly: !!readOnly
            };

            this.modalService.show(ParcelCollectorTaxRateSetupModalComponent, {
                initialState,
                ignoreBackdropClick: !readOnly,
                keyboard: !!readOnly,
                class: 'modal-lg'
            });
        });
    }

    openBillClusterTaxRateDetailsModal(
        billClusterId: number,
        billClusterRowVersion: string,
        title: string,
        billEstimated: boolean,
        readOnly?: boolean,
        isDocumentProcessing?: boolean,
        overrideSetup?: BillClusterTaxRateSetupModalResult,
        processingParcelTaxSetup?: TaxSetupMinimumDTO,
        processingBill?: Bill,
        intakeAttachmentId?: string,
        calcProjected?: boolean,
        hasProcessingChanges?: boolean,
        firstEncounterSavedCallback?: (result: FirstEncounterSaveResult) => Promise<void>
    ) {
        return new Observable<BillClusterTaxRateSetupModalResult>(subscriber => {
            const initialState = {
                billClusterId,
                billClusterRowVersion,
                title,
                billEstimated,
                modalResultSubscriber: subscriber,
                readOnly: !!readOnly,
                isDocumentProcessing: !!isDocumentProcessing,
                overrideSetup,
                processingParcelTaxSetup,
                processingBill,
                intakeAttachmentId,
                calcProjected,
                hasProcessingChanges,
                firstEncounterSavedCallback
            };
            this.modalService.show(BillClusterTaxRateSetupModalComponent, {
                initialState,
                ignoreBackdropClick: !readOnly,
                keyboard: !!readOnly,
                class: 'modal-xl'
            });
        });
    }
}
