import { Component, OnDestroy, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetImportProgressService } from './assetImportProgress.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface AssetImportProgressParams {
    assetImportProgressService: AssetImportProgressService
}

@Component({
    selector: 'asset-import-progress',
    templateUrl: './assetImportProgress.component.html',
    styleUrls: ['./assetImportProgress.component.scss']
})
export class AssetImportProgressComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AssetImportProgressParams, void>{
    constructor(
        private readonly _bsModalRef: BsModalRef) {
    }

    params: AssetImportProgressParams;
    result: void;

    activeImportSelectedRowsCount: number;
    activeImportProcessedRowsCount: number;
    activeImportTimeRemaining: string;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.params.assetImportProgressService.importStarted$.pipe(takeUntil(this._destroy$))
            .subscribe(async x => {
                if (!x){
                    return;
                }

                this.activeImportSelectedRowsCount = x.selectedRowsCount || this.activeImportSelectedRowsCount
                });

        this.params.assetImportProgressService.importFinished$.pipe(takeUntil(this._destroy$))
            .subscribe(() => this.close());

        this.params.assetImportProgressService.processedRows$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportProcessedRowsCount = x);

        this.params.assetImportProgressService.timeRemaining$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportTimeRemaining = x);

        this.params.assetImportProgressService.selectedRowsCountLoaded$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.activeImportSelectedRowsCount = this.activeImportSelectedRowsCount || x);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    close(): void {
        this._bsModalRef.hide();
    }

    get progressPercentage(): number {
        return Math.round(this.activeImportProcessedRowsCount / this.activeImportSelectedRowsCount * 100);
    }
}
