import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { AppealFormItem } from '../appeal.application.model';
import { AppealApplicationService } from '../appeal.application.service';
import { Subscription } from 'rxjs';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { DownloadAppealFormComponent, DownloadAppealFormModalParams } from './Download-Appeal-Form/downloadAppealForm.component';
import { EditAppealSupplementAssociationsComponent, EditAppealAssociationsComponentParams } from './Edit-Appeal-Associations/editAppealSupplementAssociations.component';
import { HelpContentComponentConfig, HelpContentRendererValue } from '../../UI-Lib/Help-Tooltip';
import { AdditionalAccountTooltipComponent } from './Additional-Account-Tooltip/additionalAccountTooltip.component';
import * as _ from 'lodash';
import {FeatureFlagsService} from '../../Common/FeatureFlags/feature-flags-service';
import {
    EditAppealMainAssociationsComponent,
    EditAppealMainAssociationsComponentParams
} from './Edit-Appeal-Associations/editAppealMainAssociations.component';

@Component({
    selector: 'appeal-application-list',
    templateUrl: './appealApplicationList.component.html',
    styleUrls: [
        '../../Compliance/Return/Return-Parts/Preview/Form-List/returnPreviewFormList.component.scss',
        'appealApplicationList.component.scss'
    ]
})
export class AppealApplicationListComponent implements OnInit {
    constructor(private readonly _appealApplicationService: AppealApplicationService,
                private readonly _modalService: WeissmanModalService,
                private readonly _featureFlagService: FeatureFlagsService) {}

    @Input() appealIds: number[];
    @Input() defaultSelectedAppealId: number;
    @Output() formSelected: EventEmitter<AppealFormItem> = new EventEmitter();
    @Output() appealFormListFetched: EventEmitter<AppealFormItem[]> = new EventEmitter();

    @ViewChild('appealTemplate', { static: true }) appealTemplate: TemplateRef<any>;
    @ViewChild('supplementalTemplate', { static: true }) supplementalTemplate: TemplateRef<any>;

    canEdit: boolean = true;
    editMode: boolean;
    selectedAppealFormRevisionId: number;
    appealFormList: AppealFormItem[];
    subscription: Subscription;
    additionalAccountsTooltip: HelpContentComponentConfig<AdditionalAccountTooltipComponent, HelpContentRendererValue<AppealFormItem>> = {
        component: AdditionalAccountTooltipComponent,
        componentParams: {}
    };
    hoveredAppealFormRevisionId: number;

    private _availableSupplementalForms: Compliance.AppealSupplementalFormAssociation[];
    private _availableMainForms: Compliance.AppealMainFormAssociation[];

    async ngOnInit(): Promise<void> {
        await this._appealApplicationService.correctApplicationFormRevisions(this.appealIds);
        this.subscription = this._appealApplicationService.overrideChange$.subscribe(
            overrideChange => {
                const changedItem: AppealFormItem = this.appealFormList.find(x => x.appealFormRevisionId === overrideChange.appealFormRevisionId);

                if (changedItem) {
                    changedItem.hasOverrides = overrideChange.overridden;

                    if (this._appealApplicationService.shouldApplyEditsToAll) {
                        this.appealFormList = this.appealFormList.map(item => {
                            item.hasOverrides = overrideChange.overridden;

                            return item;
                        });
                    }
                }
            }
        );
        await this._getAppealList();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    formSelectedOnList(item: AppealFormItem): void {
        if (this.selectedAppealFormRevisionId !== item.appealFormRevisionId) {
            this.selectedAppealFormRevisionId = item.appealFormRevisionId;

            this.formSelected.emit(item);
        }
    }

    async downloadAppealDocument(parcelAcctNum: string): Promise<void> {
        const appealForms = this.appealFormList.filter(x => x.parcelAcctNum === parcelAcctNum && !x.parentFormRevisionId);
        const supplementalForms = this.appealFormList.filter(x => !!x.parentFormRevisionId)
                                      .reduce((acc, x) => {
                                         if (!acc.hasOwnProperty(x.parentFormRevisionId)) {
                                             acc[x.parentFormRevisionId] = [];
                                         }
                                         acc[x.parentFormRevisionId].push();
                                         return acc;
                                     }, {});

        const params: DownloadAppealFormModalParams = {
            appealForms,
            supplementalForms
        };

        await this._modalService.showAsync(DownloadAppealFormComponent, params, 'modal-md');
    }

    async editAppealSupplements(appeal: AppealFormItem): Promise<void> {
        const params: EditAppealAssociationsComponentParams = {
            selectedAppeal: appeal,
            supplementalForms: this._availableSupplementalForms,
            formRevisionId: appeal.formRevisionId,
            parentAppeals: this.appealFormList.filter(x => !x.parentFormRevisionId && !x.additionalAccounts.length)
        };

        const result = await this._modalService.showAsync(EditAppealSupplementAssociationsComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        await this._getAppealList();
    }

    async editAppealMainForms(appeal: AppealFormItem) : Promise<void>
    {
        const params : EditAppealMainAssociationsComponentParams = {
            selectedAppeal: appeal,
            mainForms: this._availableNonMultiAccountMainFormsFor(appeal)
        };
        if (!this.canChangeMainForm(appeal))
        {
            return Promise.resolve();
        }
        const result = await this._modalService.showAsync(EditAppealMainAssociationsComponent, params, 'modal-md');

        if (!result){
            return Promise.resolve();
        }

        await this._getAppealList();
    }

   private async _getAppealList(): Promise<void> {
        const appealFormList = await this._appealApplicationService.getAppealList(this.appealIds);
        const getAllAppealForms = () => appealFormList.concat(_.flatMap(appealFormList, afl => afl.additionalAccounts));
        const appealsNeedingCaching = appealFormList.filter(a => a.useCache);
        if (appealsNeedingCaching.length > 0) {
            await this._appealApplicationService.cacheForms(appealsNeedingCaching.map(i => this._appealApplicationService.toAppealFormReference(i)));
        }

        this.appealFormListFetched.emit(getAllAppealForms());

        // Assign the templates
        appealFormList.forEach(x => {
            if (!x.parentFormRevisionId) {
                x.template = this.appealTemplate;
            } else {
                x.template = this.supplementalTemplate;
            }
        });
        console.log('assign list');
        this.appealFormList = appealFormList;

        // Set default form
        let defaultForm = this.appealFormList[0];
        if (this.defaultSelectedAppealId !== null) {
            defaultForm = this.appealFormList.find(a => a.appealId == this.defaultSelectedAppealId);
            if (!defaultForm) {
                defaultForm = this.appealFormList[0];
            }
        }
        this.formSelectedOnList(defaultForm);

        // Get supplemental forms
        await this._getAvailableSupplementalForms();
        await this._getAvailableMainForms();
    }

    toggleHovered(appeal: AppealFormItem): void {
        this.hoveredAppealFormRevisionId = appeal && appeal.appealFormRevisionId;
    }

    canChangeSupplements(appeal: AppealFormItem): boolean {
        if (!appeal || appeal.isMultiAccount) {
            return false;
        }
        const supplements = (this._availableSupplementalForms ?? []).filter(x => x.appealId === appeal.appealId && x.parentFormRevisionId === appeal.formRevisionId);
        if (!supplements || supplements.length < 1) {
            return false;
        }
        return true;
    }

    canChangeMainForm(appeal: AppealFormItem): boolean {
        if (!appeal || appeal.isMultiAccount) {
            return false;
        }

        const mainForms = this._availableNonMultiAccountMainFormsFor(appeal);

        if (!mainForms || mainForms.length < 1) {
            return false;
        }

        const otherMainForms = mainForms.filter(x => !x.isAssociated);

        if (otherMainForms.length < 1) {
            return false;
        }
        return true;
    }

    private async _getAvailableSupplementalForms(): Promise<void> {
        const appealIds = this.appealFormList.filter(x => !x.parentFormRevisionId).map(x => x.appealId);
        this._availableSupplementalForms = await this._appealApplicationService.getAvailableSupplementForms(appealIds);
    }

    private async _getAvailableMainForms(): Promise<void> {
        const appealIds = this.appealFormList.filter(x => !x.parentFormRevisionId).map(x => x.appealId);
        this._availableMainForms = await this._appealApplicationService.getAvailableMainForms(appealIds);
    }

    private _availableNonMultiAccountMainFormsFor(appeal: AppealFormItem) {
        const mainForms = this._availableMainForms ?? [];
        return mainForms.filter(x => x.appealId === appeal.appealId && !x.isMultiAccount);
    }
}
