import { Component, Input, OnInit, OnDestroy, } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { ParcelReportsSitesListParams, ParcelReportsSitesListComponent } from './Reports-Sites-List/parcelReportsSitesList.component';
import * as _ from 'lodash';

@Component({
    selector: 'parcel-reports-sites',
    templateUrl: './parcelReportsSites.component.html'
})
export class ParcelReportsSitesComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService) { }

    @Input() sites: Core.SiteNamePropertyDTO[];
    @Input() siteId: number;

    get siteNames(): string {
        return _.join(_.map(this.sites, x => x.name), ', ');
    }

    get siteNumbers(): string {
        return _.join(_.map(this.sites, x => `${x.property ? x.property : '[No Site Number]'}${x.siteID === this.siteId ? ' (this parcel\'s parent site)' : ''}`), ', ');
    }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async showSites(): Promise<void> {
        try {
            const params: ParcelReportsSitesListParams = {
                sites: this.sites,
                siteId: this.siteId
            };
            
            await this._wsModalService.show(() => {
                return this._bsModalService.show(ParcelReportsSitesListComponent,
                    {
                        class: 'modal-md',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

        } catch (e) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }
}
