import { Component, OnInit, ViewChild } from '@angular/core';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { AccountService } from '../../User/account.service';
import { UserGroupsService } from '../userGroup.service';
import { CompanyPermissionPanelComponent } from '../../Permission/Company/Panel/companyPermissionPanel.component';
import { InstancePermissionPanelComponent } from '../../Permission/Instance/instancePermissionPanel.component';
import { ApplicationPermissionPanelComponent } from '../../Permission/Application/applicationPermissionPanel.component';

export type UserGroupPanel = 'userGroupInfo' | 'applicationPermission' | 'companyPermission' | 'instancePermission';

@Component({
    selector: 'user-group-detail',
    templateUrl: './userGroupDetail.component.html',
    styles: [`
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
    `]
})
export class UserGroupDetailComponent implements OnInit {
    constructor(
        private readonly _userGroupsService: UserGroupsService,
        private readonly _restrictService: RestrictService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _accountService: AccountService
    ) {}

    @ViewChild(CompanyPermissionPanelComponent) companyPermissionPanel: CompanyPermissionPanelComponent;
    @ViewChild(InstancePermissionPanelComponent) instancePermissionPanel: InstancePermissionPanelComponent;
    @ViewChild(ApplicationPermissionPanelComponent) applicationPermissionPanel: ApplicationPermissionPanelComponent;

    userGroup: Core.GroupModel;
    canEdit: boolean = false;
    breadcrumbs: BreadCrumb[] = [];
    expandedPanel: UserGroupPanel;
    isPanelEdit: boolean;
    isLicensedUser: boolean = false;

    async ngOnInit() {
        this.breadcrumbs = [
            {
                name: 'User Groups',
                target: 'userGroupList',
                options: { }
            }
        ];

        this.isLicensedUser = this._accountService.userData.isLicensedUser;

        this.canEdit = this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP);
        this._loadUserGroup();

        this._userGroupsService.panelEditChange$.subscribe(x => this.isPanelEdit = x);
    }

    refresh() {
        this._loadUserGroup();
        this.companyPermissionPanel.refresh();
        this.instancePermissionPanel.setDataSource();
        this.applicationPermissionPanel.setDataSource();
    }

    expandClicked(panel: UserGroupPanel) {
        this.expandedPanel = this.expandedPanel == panel ? undefined : panel;
    }

    private async _loadUserGroup() {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            const userGroupId = +this._upgradeNavigationServiceHandler.getQuerystringParam('userGroupId');
            this.userGroup = await this._userGroupsService.getGroup(userGroupId);
        }
        finally {
            busyRef.hide();
        }
    }
}
