<div class="d-flex align-items-center">
    <label class="ws-flex-none pe-1 mt-2" for="appealLevel">Appeal Level:</label>
    <div class="d-flex">
        <ws-select [formControl]="appealLevelId"
                   [options]="appealLevels"
                   labelProperty="abbr"
                   valueProperty="appealLevelID"
                   placeholder="--Select--"
                   [canDeselect]="true"
                   deselectLabel="--Select--"
                   (selectedOptionChanged)="onSelectedAppealLevelChange($event)">
        </ws-select>
    </div>
</div>
