import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForAgGridCheckbox extends ICellRendererParams {
    isVisible: (params: ICellRendererParamsForAgGridCheckbox) => boolean;
    canEdit: (params: ICellRendererParamsForAgGridCheckbox) => boolean;
    canEnterEditMode: () => boolean;
    onValueChanged: (params: ICellRendererParamsForAgGridCheckbox, newValue: boolean) => void;
    valueGetter: (params: ICellRendererParamsForAgGridCheckbox) => any;
}

@Component({
    selector: 'ag-grid-grid-checkbox',
    template: `
        <input
            type="checkbox"
            (change)="onModelChanged($event.target)"
            [checked]="params.valueGetter ? params.valueGetter(params) : params.value"
            *ngIf="params.isVisible(params)"
            [disabled]="(!params.canEdit(params)) || (!params.canEnterEditMode())">
    `
})
export class AgGridCheckboxCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForAgGridCheckbox;

    agInit(params: ICellRendererParamsForAgGridCheckbox): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChanged(eventTarget: EventTarget): void {
        const value = (eventTarget as HTMLInputElement).checked;
        this.params.value = value;
        this.params.data[this.params.colDef.field] = value;
        this.params.onValueChanged(this.params, value);
    }
}
