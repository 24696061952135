import {
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { ClientServicesExceptionService } from './client-services-exception.service';
import {
    ChildEntityResponsibility,
    ClientServiceExceptionRequest,
    ClientServiceExceptionResponse,
    EntityResponsibilityAssignmentChange,
    BulkSaveExceptionResult,
    EntityResponsibility
} from './client-service-exception.models';
import { ToastrService } from 'ngx-toastr';
import { Constants, EntityType } from '../../constants.new';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { InstanceRepository} from '../../Entity/Instance/instance.repository';
import { UserInstanceService } from '../../User/userInstance.service';
import { ConsultingEngagementsRepository } from '../../Consulting/consultingEngagements.repository';
import { RestrictService, InstanceRights } from '../../Common/Permissions/restrict.service';

@Component({
    selector: 'client-services-exception-modal',
    templateUrl: './client-service-exception.modal.html',
    styleUrls: ['./client-service-exception.modal.scss']
})
export class ClientServicesExceptionModal implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private clientServicesExceptionService: ClientServicesExceptionService,
        private Constants: Constants,
        private NavigationService: UpgradeNavigationServiceHandler,
        private toastr: ToastrService,
        private instanceRepository: InstanceRepository,
        private userInstanceService : UserInstanceService,
        private consultingEngagementsRepository: ConsultingEngagementsRepository,
        private restrictService: RestrictService,
    ) { }

    @ViewChild('assignmentPopover') assignmentPopover: PopoverDirective;

    public entityId: number = 0;
    public entityTypeId: number = 0;
    public clientServiceIds: number[] = [];
    public propertyTypeIds: number[] = [];
    public isInactive: boolean = false;

    propertyTypes: any[];
    clientServicesTypes: any[];
    workflowUserTypes: any[];
    EntityTypes = EntityType;
    WorkflowUserTypes = this.Constants.WorkflowUserTypes;
    updateUser: Core.UserTeamModel;
    updateWorkflowType: number;

    enableSaveButton: boolean = false;
    selectedCount: number = 0;
    isLoading: boolean = true;
    instanceId: number;
    includeAll: boolean = false;
    showDatePicker: boolean = false;
    setDateFrom: Date;
    showPopover: boolean = false;
    isRyanInstance: boolean = false;
    clientServiceExceptions: ClientServiceExceptionResponse;

    isCurrentInstanceRyan: boolean = false;
    consultingEngagements: Core.ConsultingEngagementResponse[] = [];
    consultantsEngagement: Core.ConsultingEngagementResponse = null;
    consultantsInstanceId: number = null;
    consultingEngagementId: number = null;
    CONSULTING_ENGAGEMENT = -1;
    isConsultantPerspective = false;
    canEditCompanySetup = false;
    assignmentSaved: boolean = false;

    ngOnInit(): void {
        this.propertyTypes = _.toArray(this.Constants.PropertyTypes);
        this.clientServicesTypes = _.toArray(this.Constants.ClientServiceTypes);
        this.workflowUserTypes = _.toArray(this.Constants.WorkflowUserTypes);
        this.isRyanInstance = this.userInstanceService.isCurrentInstanceRyan();
    }

    public initModal(): void {
        this.isLoading = true;
        this.selectedCount = 0;
        this.clientServiceExceptions = null;

        const exceptionInput: ClientServiceExceptionRequest = new ClientServiceExceptionRequest(
            this.entityId,
            this.entityTypeId,
            this.clientServiceIds,
            this.propertyTypeIds,
            this.includeAll
        );

        Promise.all([
            //csr exceptions for entity
            this.clientServicesExceptionService
                .getClientServiceExceptionsForEntity(exceptionInput)
                .then(response => {
                    this.clientServiceExceptions = response;
                    this.clientServiceExceptions.children = _.sortBy(this.clientServiceExceptions.children, ['companyName', 'siteName', 'parcelAcctNum']);
                }),
            //entity instance id
            lastValueFrom(this.instanceRepository.getEntityInstanceId(this.EntityTypes[this.entityTypeId], this.entityId)).then(i => {
                this.instanceId = i;
                if (i === 1) {
                    this.isRyanInstance = true;
                }
            }),
            //consulting engagements for entity
            this.loadConsultingEngagements()
        ]).then(() => {
            this.isLoading = false;
            this.isConsultantPerspective = this.userInstanceService.isCrossInstancePerspective(this.instanceId);
        });

        this.canEditCompanySetup = this.restrictService.hasInstanceRight(InstanceRights.EDITCOMPANYSETUP);
    }

    changeConsultingEngagementFromSelect(eventTarget: EventTarget) {
        const consultingEngagementId = Number((eventTarget as HTMLSelectElement).selectedOptions[0].value);
        this.changeConsultingEngagement(consultingEngagementId);
    }

    changeConsultingEngagement(consultingEngagementId: number) {
        const ce = this.consultingEngagements.find(e => e.consultingEngagementId === consultingEngagementId);
        this.updateUser = {
            userID: ce.accountHandler.userId as string,
            teamID: ce.accountHandler.teamId,
            firstName: ce.accountHandler.firstName,
            lastName: ce.accountHandler.lastName,
            teamName: ce.accountHandler.teamName
        } as unknown as Core.UserTeamModel;

        this.consultingEngagementId = ce.consultingEngagementId;
        this.changeAssignmentSelection();
    }

    changeAssignmentSelection(){
        this.determineShowDatepicker();
        this.assignmentSaved = false;
    }

    isConsultingClient():boolean {
        return this.consultingEngagements.length && this.instanceId && !this.userInstanceService.isInstanceImplicit(this.instanceId);
    }

    propertyTypesChanged(eventTarget: EventTarget): void {
        // TODO: Why are we calling ".value" here? Is selectedOptions not really a string[] type?
        this.propertyTypeIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value)) as number[];
    }

    clientServicesChanged(eventTarget: EventTarget): void {
        this.clientServiceIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value)) as number[];
    }

    getAssigneeLabelForChildResponsibility(exception: ChildEntityResponsibility ): string {
        if(exception.assignee && exception.workflowUserTypeId === this.Constants.WorkflowUserTypes['USER'].id) {
            return `${exception.assignee.firstName  } ${  exception.assignee.lastName  } (${  exception.assignee.teamName  })`;
        }
        else if(exception.consultingEngagementId) {
            return 'Consultant';
        }
        else {
            const userValue = _.find(this.workflowUserTypes, (user) => user.id === exception.workflowUserTypeId);
            return userValue ? userValue.name : '';
        }
    }

    getAssigneeLabelForResponsibility(exception: EntityResponsibility ): string {
        if(exception.assignee && exception.workflowUserTypeId === this.Constants.WorkflowUserTypes['USER'].id) {
            return `${exception.assignee.firstName  } ${  exception.assignee.lastName  } (${  exception.assignee.teamName  })`;
        }
        else {
            const userValue = _.find(this.workflowUserTypes, (user) => user.id === exception.workflowUserTypeId);
            return userValue ? userValue.name : '';
        }
    }

    changeEntityContext(entityId: number, entityTypeId: number): void {
        this.entityId = entityId;
        this.entityTypeId = entityTypeId;
        this.initModal();
    }

    toggleResponsibilitySelection(responsibility: ChildEntityResponsibility) {
        responsibility.isSelected = !responsibility.isSelected;
        this.selectedCount = _.filter(this.clientServiceExceptions.children, item => item.isSelected).length;

        this.enableSaveButton = this.selectedCount === 0 ? false : true;
        this.determineShowDatepicker();

        event.stopPropagation();
    }

    toggleAllResponsibilitySelections(): void {
        let updateValue: boolean;
        updateValue = this.selectedCount !== this.clientServiceExceptions.children.length;

        this.clientServiceExceptions.children = _.map(this.clientServiceExceptions.children, (exception: ChildEntityResponsibility) => {
            exception.isSelected = updateValue;
            return exception;
        });

        this.selectedCount = _.filter(this.clientServiceExceptions.children, item => item.isSelected).length;

        this.enableSaveButton = this.selectedCount !== 0;
        this.determineShowDatepicker();
    }

    assignUserValue(): void {
        this.updateWorkflowType = this.WorkflowUserTypes['USER'].id;
    }

    async applyChanges(): Promise<void> {
        const updatedExceptions: EntityResponsibilityAssignmentChange[] = new Array<EntityResponsibilityAssignmentChange>();
        const dateToSave = this.showDatePicker ? this.setDateFrom : null;
        const selectedExceptions = _.filter(this.clientServiceExceptions.children, { isSelected: true });

        //reset the consulting engagementId if final selection is not a CE or changes being made by a non-consultant
        if(this.updateWorkflowType !== this.CONSULTING_ENGAGEMENT && !this.isConsultantPerspective)  {
            this.consultingEngagementId = null;
        }

        //if CE then mark workflow user type as direct User assignment
        if (this.updateWorkflowType === this.CONSULTING_ENGAGEMENT) {
            this.updateWorkflowType = this.WorkflowUserTypes['USER'].id;
        }

        const assignee = this.updateWorkflowType === this.WorkflowUserTypes['USER'].id ? this.updateUser : null;

        //if consultant selects account handler then need to set workflow user type to Consultant, otherwise leave it alone
        const workflowUserType = this.isConsultantPerspective && this.updateWorkflowType === this.WorkflowUserTypes['ACCOUNTHANDLER'].id ? this.WorkflowUserTypes['CONSULTANT'].id : this.updateWorkflowType;
        const enagagementId = this.isConsultantPerspective ? this.consultantsEngagement.consultingEngagementId : this.consultingEngagementId;


        _.forEach(selectedExceptions, (exception: ChildEntityResponsibility) => {
            let changeData: EntityResponsibilityAssignmentChange;
            changeData = new EntityResponsibilityAssignmentChange(exception.entityId,
                exception.entityTypeId,
                exception.clientServiceId,
                exception.propertyTypeId,
                workflowUserType,
                dateToSave,
                assignee,
                enagagementId);

            updatedExceptions.push(changeData);
        });

        this.isLoading = true;
        try {
            const result = await this.clientServicesExceptionService.saveClientServiceExceptions(updatedExceptions);
            const bulkExceptionSetResponse = result.results;
            const areNotAuthorized = (x: BulkSaveExceptionResult) => !x?.isAuthorized;
            const haveErrors = (x: BulkSaveExceptionResult) => !!x?.errorMessage;

            if (_.every(bulkExceptionSetResponse, areNotAuthorized)) {
                this.toastr.error('You are not authorized to perform this operation');
            }
            else if (_.some(bulkExceptionSetResponse, areNotAuthorized)) {
                this.toastr.error('Some exceptions could not be saved because you are not authorized to perform this operation');
            }

            if (_.every(bulkExceptionSetResponse, haveErrors)) {
                this.toastr.error('There was an unexpected error processing your request');
            }
            else if (_.some(bulkExceptionSetResponse, haveErrors)) {
                this.toastr.error('Some exceptions could not be saved because there was an error processing your request');
            }

            this.setDateFrom = null;
            this.initModal();
        } catch(err) {
            this.isLoading = false;
        }

        this.assignmentSaved = true;
    }

    determineShowDatepicker(): void {
        const selectedExceptions: ChildEntityResponsibility[] = _.filter(this.clientServiceExceptions.children, { isSelected: true });
        const existingNA: boolean = _.some(selectedExceptions, { 'workflowUserTypeId': this.WorkflowUserTypes['NA'].id });

        if(existingNA && this.updateWorkflowType !== this.WorkflowUserTypes['NA'].id) {
            this.showDatePicker = true;
        } else {
            this.showDatePicker = false;
            this.setDateFrom = null;
        }
    }

    setUser($event: any): void {
        this.updateUser = $event;
    }

    navigateToEntity(exception: ChildEntityResponsibility, entityTypeId): void {
        switch (entityTypeId) {
            case EntityType.Company:
                this.NavigationService.go('company', {companyId: exception.companyId});
                break;
            case EntityType.Site:
                this.NavigationService.go('siteRedirect', {entityID: exception.siteId});
                break;
            case EntityType.Parcel:
                this.NavigationService.go('parcelRedirect', {entityID: exception.parcelId});
        }

        this.bsModalRef.hide();
    }

    //consultants are defined by implicit instance membership
    shouldUseConsultingEngagementInstanceId():boolean {
        return this.userInstanceService.isInstanceImplicit(this.instanceId);
    }

    private async loadConsultingEngagements() {
        this.consultingEngagements = await this.consultingEngagementsRepository.getAcceptedEngagements(this.entityTypeId, this.entityId);

        if(this.shouldUseConsultingEngagementInstanceId()) {
            this.consultantsEngagement = this.consultingEngagements.find(ce => this.userInstanceService.isInstanceExplicit(ce.instanceId));
        }
    }
}
