import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class NavigationRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getNavigationInfo(entityName: string, id: number): Observable<any> {
        return this.httpGet(`/api/${  entityName  }/${  id  }/Navigation`);
    }
}
