import { AssetInfoComponent } from './assetInfo.component';
import { AssetSummary } from '../../Models/assetSummary';
import { Observable } from 'rxjs';

export enum CustomTemplateTypes {
    AcqDate,
    AssetClass,
    CostAdjustments,
    GlAccount,
    ParcelSelector,
    Sites,
    Companies
}

export interface TypeaheadParams {
    placeholder: string;
    valueParam: string;
    filterValue: string;
    values$: Observable<string[]>;
    onBlur: () => void;
    onNoResults: () => void;
    onSelect: () => void;
}

export interface AssetTemplate {
    type: Core.DescriptorFieldTypes | CustomTemplateTypes;
    modelParam?: string;
    isCustom?: boolean;
    isDoubleWidth?: boolean;
    maxLength?: number;
    isDisabled?: (value: number) => boolean;
    isStatic?: (value?: number) => boolean;
}

export interface AssetInfoFieldBase {
    label: string;
    sourceParam: string;
    reportedParam: string;
    reportedOverride: string;
    reportedTemplate: AssetTemplate;
}

export interface AssetInfoField extends AssetInfoFieldBase {
    cellClass?: string;
    labelHelpContentId?: string;
    labelTemplate?: AssetTemplate;
    sourceTemplate?: AssetTemplate;
    descriptorList?: string;
    descriptorCount?: number;
    descriptorHelpContentId?: string;
    descriptor?: Compliance.CompanyAssetDescriptorMappingModel;
    picklist?: Compliance.NameValuePair<any>[];
    isCalculated?: string // added to fix the assetInfo.comnponent.html error
    reportedOverrideWithBlank?: any // added to fix the assetInfo.comnponent.html error
    isDisabled?: (value: any) => boolean;
    onChange?: (value: any, asset: AssetSummary, field: AssetInfoField) => void;
    onClear?: (asset: AssetSummary, field: AssetInfoField) => void;
    sourceValueGetter?: (value: string, field: AssetInfoField) => any;
    reportedValueGetter?: (asset: AssetSummary, field: AssetInfoField) => any;
    isRowShown?: () => boolean;
    isSourceCellShown?: (asset?: AssetSummary, field?: AssetInfoField) => any;
    isReportedCellShown?: (asset?: AssetSummary, field?: AssetInfoField) => any;
}

export interface AssetLeasedInfoField extends AssetInfoFieldBase {
    picklist?: Compliance.NameValuePair<any>[];
    maxLength?: number;
}

export interface AssetInventoryField extends AssetInfoFieldBase {
    month: number;
    isCalculated?: string;
}

export const ASSET_FIELDS: (classRef: AssetInfoComponent) => AssetInfoField[] = (classRef) => [
    {
        label: 'Is Locked',
        sourceParam: null,
        sourceTemplate: null,
        sourceValueGetter: () => null,
        picklist: [{name: 'Yes', value: true}, {name: 'No', value: false}],
        reportedParam: 'reportedIsLocked',
        reportedOverride: '',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Picklist },
        onChange: classRef.onIsLockedChange.bind(classRef),
        reportedValueGetter: classRef.getIsLockedDisplayValue.bind(classRef),
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Ownership Type',
        sourceParam: 'assetOwnershipTypeId',
        sourceTemplate: { type: Core.DescriptorFieldTypes.Picklist },
        sourceValueGetter: () => null,
        picklist: [],
        reportedParam: 'assetOwnershipTypeId',
        reportedOverride: '',
        reportedTemplate: { type: null, isStatic: () => true },
        onChange: classRef.onOwnershipTypeChange.bind(classRef),
        reportedValueGetter: classRef.getOwnershipTypeDescription.bind(classRef)
    },
    {
        label: 'Company',
        sourceParam: 'sourceCompanyName',
        reportedParam: 'companyId',
        reportedOverride: 'reportedCompanyNameHasOverride',
        reportedTemplate: {
            type: CustomTemplateTypes.Companies,
            isCustom: true,
            isStatic: () => !classRef.createNewAsset
        },
        reportedValueGetter: classRef.getCompanyNameFromId.bind(classRef),
        isCalculated: 'isCompanyCalculated'
    },
    {
        label: 'Asset Number',
        sourceParam: 'sourceAssetNumber',
        reportedParam: 'reportedAssetNumber',
        reportedOverride: 'reportedAssetNumberHasOverride',
        reportedOverrideWithBlank: 'reportedAssetNumberHasOverrideWithBlank',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Old Asset Number',
        sourceParam: 'sourceOldAssetNumber',
        reportedParam: 'reportedOldAssetNumber',
        reportedOverride: 'reportedOldAssetNumberHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text },
        isRowShown: () => classRef.editMode || classRef.hasOldAssetNumber
    },
    {
        label: 'State',
        sourceParam: '',
        reportedParam: 'state',
        reportedOverride: '',
        reportedTemplate: { type: null, isStatic: () => true },
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Assessor',
        sourceParam: '',
        reportedParam: 'assessorName',
        reportedOverride: '',
        reportedTemplate: { type: null, isStatic: () => true },
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Acquisition Date',
        labelHelpContentId: 'asset-info.acquisition-date-field',
        cellClass: 'acqDateAssetDescriptors-0',
        descriptorList: 'acqDateAssetDescriptors',
        descriptorHelpContentId: 'asset-info.acqDateAssetDescriptors',
        descriptor: {
            columnName: null,
            companyAssetDescriptorMappingId: 0,
            companyId: null,
            descriptor: null,
            pickList: null
        },
        sourceParam: 'sourceAcqDate',
        reportedParam: 'reportedAcqDate',
        reportedOverride: 'reportedAcqDateHasOverride',
        reportedTemplate: { type: CustomTemplateTypes.AcqDate, isCustom: true },
        isDisabled: () => classRef.assetListForm.value.acqDateAssetDescriptors > 0,
        onChange: classRef.onAcquisitionDateChanged.bind(classRef),
        onClear: classRef.clearAcqDateOverride.bind(classRef),
        reportedValueGetter: classRef.formatAlternativeDate.bind(classRef)
    },
    {
        label: 'Disposed Date',
        labelHelpContentId: 'asset-info.disposed-date-field',
        sourceParam: 'sourceDisposedDate',
        reportedParam: 'reportedDisposedDate',
        reportedOverride: 'reportedDisposedDateHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Date },
        onChange: classRef.onDisposedDateChanged.bind(classRef),
        onClear: classRef.clearDisposedDateOverride.bind(classRef),
        isDisabled: (row) => row && !row.isPrimary,
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Description',
        sourceParam: 'sourceDescription',
        reportedParam: 'reportedDescription',
        reportedOverride: 'reportedDescriptionHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text },
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Cost',
        labelHelpContentId: 'asset-info.cost-row-label',
        cellClass: 'costAssetDescriptors-0',
        labelTemplate: { type: CustomTemplateTypes.CostAdjustments, isCustom: true, isDoubleWidth: false },
        descriptorList: 'costAssetDescriptors',
        descriptorHelpContentId: 'asset-info.costAssetDescriptors',
        descriptor: {
            columnName: null,
            companyAssetDescriptorMappingId: 0,
            companyId: null,
            descriptor: null,
            pickList: null
        },
        sourceParam: 'sourceCost',
        reportedParam: 'reportedCost',
        reportedOverride: 'reportedCostHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        reportedValueGetter: classRef.formatTransientCost.bind(classRef),
        isDisabled: () => classRef.assetListForm.value.costAssetDescriptors > 0,
        isSourceCellShown: function() {
            return !this.labelTemplate.isDoubleWidth;
        },
        onChange: classRef.onTransientReportedCostChange.bind(classRef)
    },
    {
        label: 'Net Book Value',
        labelHelpContentId: 'asset-info.net-book-value-row-label',
        sourceParam: 'sourceNetBookValue',
        reportedParam: 'reportedNetBookValue',
        reportedOverride: 'reportedNetBookValueHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'G/L Account',
        sourceParam: 'sourceGlAccountDisplay',
        reportedParam: 'reportedGlAccountDisplay',
        reportedOverride: 'reportedGlAccountHasOverride',
        reportedTemplate: { type: CustomTemplateTypes.GlAccount, isCustom: true },
        isRowShown: () => !classRef.createNewAsset,
        onClear: classRef.onGlAccountRemoveOverride.bind(classRef)
    },
    {
        label: 'Site',
        sourceParam: 'sourceSiteDisplay',
        reportedParam: 'reportedSiteDisplay',
        reportedOverride: 'reportedSiteHasOverride',
        reportedTemplate: { type: CustomTemplateTypes.Sites, isCustom: true },
        onClear: classRef.onSiteRemoveOverride.bind(classRef)
    },
    {
        label: 'Lease Type',
        sourceParam: 'sourceLeaseTypeDisplay',
        reportedParam: 'reportedLeaseTypeId',
        reportedOverride: 'reportedLeaseTypeHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Picklist },
        picklist: [],
        isRowShown: () => classRef.createNewAsset && classRef.currentAsset.isLeasedAsset
    },
    {
        label: 'Asset Class',
        sourceParam: 'sourceAssetClassificationDisplay',
        reportedParam: 'reportedAssetClassificationId',
        reportedOverride: 'assetClassificationHasOverride',
        reportedTemplate: { type: CustomTemplateTypes.AssetClass, isCustom: true },
        reportedValueGetter: classRef.getAssetClassName.bind(classRef),
        onClear: classRef.onAssetClassificationRemoveValue.bind(classRef),
        isRowShown: () => !classRef.createNewAsset
    },
    {
        label: 'Parcel',
        labelHelpContentId: 'asset-info.site-calculated-parcel',
        sourceParam: 'sourceParcelName',
        reportedParam: 'reportedParcelId',
        reportedOverride: 'reportedParcelHasOverride',
        reportedTemplate: { type: CustomTemplateTypes.ParcelSelector, isCustom: true },
        reportedValueGetter: classRef.getParcelName.bind(classRef),
        isRowShown: () => !classRef.createNewAsset
    }
];

export const LEASED_TO_FIELDS: AssetLeasedInfoField[] = [
    {
        label: 'Lease Customer ID',
        sourceParam: 'sourceLeaseClientId',
        reportedParam: 'reportedLeaseClientId',
        reportedOverride: 'reportedLeaseClientIdHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Location Name',
        sourceParam: 'sourceLeaseLocationName',
        reportedParam: 'reportedLeaseLocationName',
        reportedOverride: 'reportedLeaseLocationNameHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Location Address',
        sourceParam: 'sourceLeaseAddress',
        reportedParam: 'reportedLeaseAddress',
        reportedOverride: 'reportedLeaseAddressHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Location City',
        sourceParam: 'sourceLeaseCity',
        reportedParam: 'reportedLeaseCity',
        reportedOverride: 'reportedLeaseCityHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Location State',
        sourceParam: 'sourceLeaseState',
        reportedParam: 'reportedLeaseState',
        reportedOverride: 'reportedLeaseStateHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text, maxLength: 2 }
    },
    {
        label: 'Location Zip',
        sourceParam: 'sourceLeaseZip',
        reportedParam: 'reportedLeaseZip',
        reportedOverride: 'reportedLeaseZipHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    }
];

export const LEASED_TO_FROM_FIELDS: AssetLeasedInfoField[] = [
    {
        label: 'Lease Asset ID',
        sourceParam: 'sourceLeaseAssetId',
        reportedParam: 'reportedLeaseAssetId',
        reportedOverride: 'reportedLeaseAssetIdHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Lease Start Date',
        sourceParam: 'sourceLeaseStartDate',
        reportedParam: 'reportedLeaseStartDate',
        reportedOverride: 'reportedLeaseStartDateHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Date }
    },
    {
        label: 'Lease End Date',
        sourceParam: 'sourceLeaseEndDate',
        reportedParam: 'reportedLeaseEndDate',
        reportedOverride: 'reportedLeaseEndDateHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Date }
    },
    {
        label: 'Lease Term In Months',
        sourceParam: 'sourceLeaseTermInMonths',
        reportedParam: 'reportedLeaseTermInMonths',
        reportedOverride: 'reportedLeaseTermInMonthsHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Number }
    },
    {
        label: 'Lease Monthly Payment',
        sourceParam: 'sourceLeaseMonthlyPayment',
        reportedParam: 'reportedLeaseMonthlyPayment',
        reportedOverride: 'reportedLeaseMonthlyPaymentHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency }
    },
    {
        label: 'Lease Type',
        sourceParam: 'sourceLeaseTypeId',
        reportedParam: 'reportedLeaseTypeId',
        reportedOverride: 'reportedLeaseTypeIdHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Picklist },
        picklist: []
    },
    {
        label: 'Lease Number',
        sourceParam: 'sourceLeaseNumber',
        reportedParam: 'reportedLeaseNumber',
        reportedOverride: 'reportedLeaseNumberHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Billing Name',
        sourceParam: 'sourceLeaseBillingName',
        reportedParam: 'reportedLeaseBillingName',
        reportedOverride: 'reportedLeaseBillingNameHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Billing Address',
        sourceParam: 'sourceLeaseBillingAddress',
        reportedParam: 'reportedLeaseBillingAddress',
        reportedOverride: 'reportedLeaseBillingAddressHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Billing City',
        sourceParam: 'sourceLeaseBillingCity',
        reportedParam: 'reportedLeaseBillingCity',
        reportedOverride: 'reportedLeaseBillingCityHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    },
    {
        label: 'Billing State',
        sourceParam: 'sourceLeaseBillingState',
        reportedParam: 'reportedLeaseBillingState',
        reportedOverride: 'reportedLeaseBillingStateHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text, maxLength: 2 }
    },
    {
        label: 'Billing Zip',
        sourceParam: 'sourceLeaseBillingZip',
        reportedParam: 'reportedLeaseBillingZip',
        reportedOverride: 'reportedLeaseBillingZipHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Text }
    }
];

export const INVENTORY_FIELDS: AssetInventoryField[] = [
    {
        label: 'Jan Balance',
        sourceParam: 'sourceInventoryJan',
        reportedParam: 'reportedInventoryJan',
        reportedOverride: 'reportedInventoryJanHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 1
    },
    {
        label: 'Feb Balance',
        sourceParam: 'sourceInventoryFeb',
        reportedParam: 'reportedInventoryFeb',
        reportedOverride: 'reportedInventoryFebHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 2
    },
    {
        label: 'Mar Balance',
        sourceParam: 'sourceInventoryMar',
        reportedParam: 'reportedInventoryMar',
        reportedOverride: 'reportedInventoryMarHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 3
    },
    {
        label: 'Apr Balance',
        sourceParam: 'sourceInventoryApr',
        reportedParam: 'reportedInventoryApr',
        reportedOverride: 'reportedInventoryAprHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 4
    },
    {
        label: 'May Balance',
        sourceParam: 'sourceInventoryMay',
        reportedParam: 'reportedInventoryMay',
        reportedOverride: 'reportedInventoryMayHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 5
    },
    {
        label: 'Jun Balance',
        sourceParam: 'sourceInventoryJun',
        reportedParam: 'reportedInventoryJun',
        reportedOverride: 'reportedInventoryJunHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 6
    },
    {
        label: 'Jul Balance',
        sourceParam: 'sourceInventoryJul',
        reportedParam: 'reportedInventoryJul',
        reportedOverride: 'reportedInventoryJulHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 7
    },
    {
        label: 'Aug Balance',
        sourceParam: 'sourceInventoryAug',
        reportedParam: 'reportedInventoryAug',
        reportedOverride: 'reportedInventoryAugHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 8
    },
    {
        label: 'Sep Balance',
        sourceParam: 'sourceInventorySep',
        reportedParam: 'reportedInventorySep',
        reportedOverride: 'reportedInventorySepHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 9
    },
    {
        label: 'Oct Balance',
        sourceParam: 'sourceInventoryOct',
        reportedParam: 'reportedInventoryOct',
        reportedOverride: 'reportedInventoryOctHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 10
    },
    {
        label: 'Nov Balance',
        sourceParam: 'sourceInventoryNov',
        reportedParam: 'reportedInventoryNov',
        reportedOverride: 'reportedInventoryNovHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 11
    },
    {
        label: 'Dec Balance',
        sourceParam: 'sourceInventoryDec',
        reportedParam: 'reportedInventoryDec',
        reportedOverride: 'reportedInventoryDecHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: 12
    },
    {
        label: 'Avg Balance',
        sourceParam: 'sourceInventoryAvr',
        reportedParam: 'reportedInventoryAvr',
        reportedOverride: 'reportedInventoryAvrHasOverride',
        reportedTemplate: { type: Core.DescriptorFieldTypes.Currency },
        month: -1,
        isCalculated: 'reportedInventoryAvrIsCalculated'
    }
];
