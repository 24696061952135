import { Component, Input } from '@angular/core';
import { ContractTerm, TermItem } from './contract-invoices.model';

@Component({
    selector: 'term-rates-and-fees',
    template: `
        <span *ngIf="term.contingencyPct">{{usePercent ? (term.contingencyPct | percent: '1.1-2') : (term.contingencyPct)}}<span *ngIf="!usePercent">%</span> contingency fee
        <span *ngIf="term.contingencyCap!== undefined && term.contingencyCap !== null">capped at {{term.contingencyCap | currency:'USD'}}</span><span *ngIf="term.flatFee || term.hourlyRate">;</span></span>
        <span *ngIf="term.flatFee">{{term.flatFee | currency:'USD'}} fixed fee<span *ngIf="term.hourlyRate">;</span></span>
        <span *ngIf="term.hourlyRate">{{term.hourlyRate | currency:'USD'}} hourly rate</span>
    `
})
export class TermRatesAndFeesComponent {
    @Input() term: ContractTerm | TermItem;
    @Input() usePercent: boolean;

    constructor(){}
}