import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { ChangePasswordComponent } from './Change-Password/changePassword.component';
import { ExternalAPIKeyComponent } from './ExternalAPI/external-api-key.component';
import { NewPasswordComponent } from './New-Password/newPassword.component';

@NgModule({
    imports: [ WeissmanSharedModule ],
    declarations: [
        ExternalAPIKeyComponent,
        NewPasswordComponent,
        ChangePasswordComponent
    ],
    exports: [
        ExternalAPIKeyComponent,
        NewPasswordComponent,
        ChangePasswordComponent
    ]
})
export class AccountModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('externalApiKey', ExternalAPIKeyComponent);
        hybridAdapterUtility.downgradeNg2Component('externalApiKey', ExternalAPIKeyComponent);
    }
}
