import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { FilingBatchRepository } from '../../Repositories';
import { RestrictService, InstanceRights } from '../../../Common/Permissions/restrict.service';
import { Subscription, Subject, lastValueFrom } from 'rxjs';
import { FilingBatchService } from '../filingBatch.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { NavigationService } from '../../../Layout/navigation.service';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../../Comments/comments.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { FILING_BATCH_DETAILS_HELP } from './filingBatchDetails.component.help';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { FilingsChangeModel } from './Filing-Batch-Accounts/filingBatchAccounts.component';
import { AmendReturnsComponent, AmendReturnsModalParams } from './Amend-Returns-Modal/amendReturns.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FilingBatchDetailsService } from './filingBatchDetails.service';

import * as _ from 'lodash';
import { ReturnService } from '../../Return/return.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';

@Component({
    selector: 'filing-batch-details',
    templateUrl: './filingBatchDetails.component.html',
    styleUrls: ['./filingBatchDetails.component.scss'],
    providers: [FilingBatchDetailsService]
})
export class FilingBatchDetailsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyService: BusyIndicatorService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _companyService: CompanyService,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _restrictService: RestrictService,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _navigationService: NavigationService,
        private readonly _modalService: WeissmanModalService,
        private readonly _filingDetailService: FilingBatchDetailsService,
        private readonly _helpService: HelpService,
        private readonly _returnService: ReturnService,
        private readonly _productAnalyticsService: ProductAnalyticsService
        ) { }

    showChangeDetection: boolean = false;
    isInitialized: boolean = false;
    breadcrumbs: BreadCrumb[] = [];
    filingBatchId: number;
    companyId: number;
    companyName: string;
    filingBatch: Compliance.FilingBatchModel;
    addMode: boolean;
    canEditCompany: boolean = false;
    refreshing: boolean;
    refresh$: Subject<void> = new Subject<void>();

    private _filingBatchChangeSub: Subscription;
    private _lockChangedSub: Subscription;


    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._filingBatchService.editGroup);
    }

    get isNotStarted(): boolean {
        return this._filingBatchService.isNotStarted(this.filingBatch && this.filingBatch.processStatus);
    }

    get isStarted(): boolean {
        return this._filingBatchService.isStarted(this.filingBatch && this.filingBatch.processStatus);
    }

    get isReadOnly(): boolean {
        return this._filingBatchService.isReadOnly(this.filingBatch && this.filingBatch.processStatus);
    }

    get isFinalized(): boolean {
        return this._filingBatchService.isFinalized(this.filingBatch && this.filingBatch.processStatus);
    }

    get canEdit(): boolean {
        return this.canEditCompany && !this._filingBatchService.isReadOnly(this.filingBatch && this.filingBatch.processStatus);
    }

    get hasFilings(): boolean {
        return this.filingBatch && this.filingBatch.filingCount ? true : false;
    }

    get beginPreparationHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        if (!this.hasFilings) {
            return 'filing-batch-details.begin-preparation-no-filings';
        }

        return 'filing-batch-details.begin-preparation';
    }

    get continuePreparationHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        if (!this.hasFilings) {
            return 'filing-batch-details.continue-preparation-no-filings';
        }

        return 'filing-batch-details.continue-preparation';
    }

    get viewReturnsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'filing-batch-details.view-returns';
    }

    get attachmentsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.attachments';
    }

    get commentsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.comments';
    }

    get refreshHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.refresh';
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.filingBatch) {
            return null;
        }

        return {
            entityType: 'FilingBatch',
            entityName: this.filingBatch.description,
            entityData: {
                typeId: Core.EntityTypes.FilingBatch,
                id: this.filingBatch.filingBatchId,
                name: this.filingBatch.description
            },
            singleYear: true,
            year: this.filingBatch.taxYear,
            disableYears: true,
            readOnly: !this.canEditCompany
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.filingBatch) {
            return null;
        }

        return {
            entityID: this.filingBatch.filingBatchId,
            entityTypeID: Core.EntityTypes.FilingBatch,
            description: this.filingBatch.description,
            year: this.filingBatch.taxYear.toString(),
            canEdit: this.canEditCompany,
            parcelAcctNum: undefined,
            parcelID: undefined,
        } as CommentModalData;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FILING_BATCH_DETAILS_HELP);

        this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        this.filingBatchId = parseInt(this._routerService.getQuerystringParam('filingBatchId')) || 0;
        this.addMode = this.filingBatchId === 0;

        const busyRef = this._busyService.show({ message: 'Loading' });

        const responses = await Promise.all([
            this._companyService.getInfoForCompliance(this.companyId),
            this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write)
        ]);

        const company = responses[0];

        if (!(company.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, company.instanceID))) {
            return await this.navigateToUnauthorized();
        }

        if (company.companyID !== company.topLevelCompanyId) {
            return await this.navigateToUnauthorized();
        }

        if (this.addMode) {
            this.filingBatch = {
                companyId: this.companyId,
                filingBatchId: this.filingBatchId,
                processStatus: Compliance.FilingBatchProcessStatusEnum.NotStarted,
                description: null,
                dueDate: null,
                filingTypeId: null,
                stateId: null,
                taxYear: null,
                rowVersion: null,
                getPriorReturnFromAssetList: false,
                reportNBVInsteadOfFactoredCost: false,
                returnCostReportingDisplayId: Compliance.ReturnCostReportingDisplayEnum.Both
            };
        } else {
            this.filingBatch = await lastValueFrom<Compliance.FilingBatchModel>(this._filingBatchRepository.get(this.filingBatchId));

            if (company.companyID !== this.filingBatch.companyId) {
                return await this.navigateToUnauthorized();
            }
        }

        this.companyName = company.companyName;
        this.canEditCompany = responses[1];

        this.breadcrumbs = [
            {
                name: company.companyName,
                target: 'company',
                options: { companyId: company.companyID }
            },
            {
                name: 'Filing Batches',
                target: 'filingBatchList',
                options: { companyId: this.filingBatch.companyId }
            }
        ];

        this._filingBatchChangeSub = this._filingBatchService.filingBatchChange$.subscribe(async x => {
            if (this.filingBatch && x && this.filingBatch.filingBatchId === x.filingBatchId) {
                _.extend(this.filingBatch, x);
            }
        });

        this._lockChangedSub = this._mutexService.lockChanged$.subscribe((x) => {
            const isLocked = x && x[this._filingBatchService.editGroup];
            if (isLocked) {
                this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
            }
            else {
                this._navigationService.disableNavWarning();
            }
        });

        try {
            if (!this.addMode) {
                await this._filingBatchService.start(this.filingBatchId);
            }

            this.isInitialized = true;

        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._filingBatchChangeSub && this._filingBatchChangeSub.unsubscribe();
        this._lockChangedSub && this._lockChangedSub.unsubscribe();
        this._filingBatchService.stop();
    }

    onFilingBatchCreated(filingBatchModel: Compliance.FilingBatchModel): void {
        this.filingBatch = filingBatchModel;
        if (this.addMode) {
            this._productAnalyticsService.logEvent('access-filing-batch', {
                returnAccessPath: 'new filing batch'
            });
            this._navigationService.disableNavWarning();
            this.navigateToFilingBatchDetails();
        }
    }

    onFilingBatchUpdated(filingBatchModel: Compliance.FilingBatchModel): void {
        this.filingBatch = filingBatchModel;
    }

    onFilingBatchDeleted(): void {
        this._navigationService.disableNavWarning();
        this.navigateToFilingBatchList();
    }

    onFilingBatchChangesCancelled(): void {
        if (this.addMode) {
            this._navigationService.disableNavWarning();
            this.navigateToFilingBatchList();
        }
    }

    async amendReturns(): Promise<void> {
        const params: AmendReturnsModalParams = {
            companyId: this.companyId,
            filingBatch: this.filingBatch,
            filings: this._filingDetailService.currentFilings
        };

        const result = await this._modalService.showAsync(AmendReturnsComponent, params, 'modal-lg');

        if (!result) {
            return;
        }

        this._routerService.go('filingBatchDetails', { companyId: result.companyId, filingBatchId: result.filingBatchId });
    }

    navigateToCompany(): void {
        this._routerService.go('company', { 'companyId': this.filingBatch.companyId });
    }

    navigateToFilingBatchList(): void {
        this._routerService.go('filingBatchList',
            {
                'companyId': this.filingBatch.companyId
            });
    }

    navigateToFilingBatchDetails(): void {
        this._routerService.go('filingBatchDetails',
            {
                'companyId': this.filingBatch.companyId,
                'filingBatchId': this.filingBatch.filingBatchId
            });
    }

    async navigateToReturnBatch(): Promise<void> {
        this._routerService.go('returnFilingBatchPage',
            {
                'filingBatchId': this.filingBatch.filingBatchId,
            });
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    async refresh(): Promise<void> {
        this.refreshing = true;

        try {
            this.refresh$.next();

            this.filingBatch = await lastValueFrom<Compliance.FilingBatchModel>(this._filingBatchRepository.get(this.filingBatchId));
        } finally {
            this.refreshing = false;
        }

        return Promise.resolve();
    }

    isHidden(componentName: string): boolean {
        return this._filingBatchService.isOtherComponentExpanded(componentName);
    }

    onFilingsChanged(changes: FilingsChangeModel): void {
        this.showChangeDetection = (!this.addMode) && changes.count && (!changes.hasPriorReturns);
    }
}
