<ng-template #customItemTemplate let-model="item" >
    <span>{{model.fullName}}</span>
</ng-template>
<div #stateContainer class="d-flex flex-column h-100">
    <div class="d-flex justify-content-center mt-4" style="z-index: 1">
        <input [(ngModel)]="selected"
               style="width: 600px;"
               [typeahead]="states"
               typeaheadOptionField="typeaheadVal"
               placeholder="Enter State"
               class="form-control"
               [disabled]="!states"
               [typeaheadItemTemplate]="customItemTemplate"
               (typeaheadOnSelect)="onStateSelect($event)">
    </div>
    <div #unitedStates class="h-100 mx-auto"></div>
</div>
<div class="state-downloads">
    <div>
        <a class="pointer" (click)="downloadAssessorExtract()"
           [ngClass]="{'disabled': loadingAssessorList || loadingCollectorList || disableCollectorList}">Assessor List Extract</a>
        <i class="fa fa-spinner fa-pulse ms-1"
           [ngClass]="{'invisible': !loadingAssessorList, 'disabled': loadingAssessorList || loadingCollectorList || disableCollectorList}">
        </i>
    </div>
    <div>
        <a class="pointer" (click)="downloadCollectorExtract()"
           [ngClass]="{'disabled': loadingAssessorList || loadingCollectorList || disableAssessorList}">Collector List Extract</a>
        <i class="fa fa-spinner fa-pulse ms-1"
           [ngClass]="{'invisible': !loadingCollectorList, 'disabled': loadingAssessorList || loadingCollectorList || disableAssessorList}">
        </i>
    </div>
</div>
