<div class="mega-dropdown-menu manage-menu ws-flex-container-vertical row" [class.admin]="parent.hasAdminRole">
    <h4>PROPERTYPOINT</h4>
    <div class="ws-flex-container-horizontal ws-stretch-width">
        <ul *ngIf="parent.hasAdminRole">
            <li class="dropdown-header">Admin</li>
            <ng-container *ngFor="let item of adminMenuItems">
                <li *ngIf="item.isShown()" [class.disabled]="item.isDisabled && item.isDisabled()">
                    <a anchor-route [sref]="item.anchor" (click)="triggerAmplitudeEvent(item.amplitudeProperty)">
                        {{item.name}}
                    </a>
                </li>
            </ng-container>
        </ul>

        <div class="ws-flex-container-vertical">
            <ul *ngIf="parent.hasAdminRole">
                <li class="dropdown-header">Diagnostics</li>
                <ng-container *ngFor="let item of diagnosticsMenuItems">
                    <li *ngIf="item.isShown()" [class.disabled]="item.isDisabled && item.isDisabled()">
                        <a anchor-route [sref]="item.anchor" (click)="triggerAmplitudeEvent(item.amplitudeProperty)">
                            {{item.name}}
                        </a>
                    </li>
                </ng-container>
            </ul>

            <ul>
                <li class="dropdown-header">{{userText}}</li>
                <li *ngIf="contactID && parent.hasProfileViewPermissions">
                    <p class="profile-link" (click)="openContactModal()" >
                        View Profile
                    </p>
                </li>
                <ng-container *ngFor="let item of profileMenuItems">
                    <li *ngIf="item.isShown()">
                        <a anchor-route [sref]="item.anchor" (click)="triggerAmplitudeEvent(item.amplitudeProperty)">
                            {{item.name}}
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>


    </div>

    <div class="account ws-flex-container-vertical ws-stretch-width">
        <div class="ws-flex-container-horizontal">
            <button *ngIf="parent.hasAddNewCompanyRole"
                    class="add-company flat-button small-button primary-button"
                    (click)="parent.addCompany()">
                NEW COMPANY
            </button>
            <button *ngIf="parent.hasAddNewInstanceRole"
                    class="add-company flat-button small-button primary-button"
                    (click)="addInstance()">
                NEW INSTANCE
            </button>
        </div>
        <div  class="text-center" style="margin-top:20px;" *ngIf="!isSingleInstanceSelected">
            <strong><i class="fa fa-info-circle fa-fw"></i>&nbsp; Some features are only available if you have a single instance selected</strong>
        </div>
    </div>
</div>
