import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { reduce, startsWith } from 'lodash';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from 'src/app/Compliance/AgGrid';
import { WeissmanDateFormat } from 'src/app/UI-Lib/Pipes/Date-Format/date-formatting.pipe';

interface CheckModelUI extends Core.PaymentBatchCheckImportCheckModel {
    isTotal?: boolean
}

@Component({
    selector: 'finalize-check-import-checks-grid',
    template: `
        <h3>Imported Checks</h3>
        <ws-ag-grid-angular class="ag-theme-balham grid grid-cell-no-focus"
                            [gridOptions]="gridOptions"
                            [forceShowToolPanel]="true"
                            [showConfigureLayout]="false"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    `
})

export class FinalizeCheckImportChecksGridComponent {
    constructor(private readonly _currencyPipe: CurrencyPipe) { }

    @Input() checkImportChecks: CheckModelUI[];

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        rowClassRules: {
            'row-matched': (params) => {
                const gridRow = params.data as CheckModelUI;
                return !!gridRow.paymentCount && (!gridRow.isTotal || startsWith(gridRow.collector, 'Matched'));
            },
            'totals-row': (params) => {
                const gridRow = params.data as CheckModelUI;
                return gridRow.isTotal;
            }
        },
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    refreshing: boolean;

    private _gridApi: GridApi;

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Payee',
                field: 'collector',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellStyle: params => {
                    const row = params.data as CheckModelUI;
                    return row.isTotal ? {textAlign: 'right', fontWeight: 'bold'} : null;
                }
            },
            {
                headerName: 'Check Amount',
                field: 'checkAmount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._formatCurrency(x.value),
                cellClass: 'text-align-right',
            },
            {
                headerName: 'Check No',
                field: 'checkNo',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            },
            {
                headerName: 'Check Date',
                field: 'checkDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const model = params.data as CheckModelUI;
                    if (!(model && model.checkDate)) {
                        return '';
                    }

                    return WeissmanDateFormat(model.checkDate, true, 'stored');
                },
            },
            {
                headerName: 'Payment Count',
                field: 'paymentCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            },
            {
                headerName: 'Unmatched Reason',
                field: 'unmatchedReason',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnLargeWidth
            }
        ];

        const defaultSortModel = [
            {
                colId: 'collector',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();

        this.setRowData();
    }

    setRowData() {
        if (!(this._gridApi && this.checkImportChecks)) {
            return;
        }

        this._gridApi.setRowData(this.checkImportChecks);

        const totalRows = [];
        const matchedPayments = this.checkImportChecks.filter(x => !!x.paymentCount);

        if(matchedPayments.length) {
            const matchedRow = this._getTotalRow(matchedPayments, true);
            totalRows.push(matchedRow);
        }

        const totalRow = this._getTotalRow(this.checkImportChecks);
        totalRows.push(totalRow);

        this._gridApi.setPinnedBottomRowData(totalRows);
    }

    private _formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this._currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    private _getTotalRow(payments: CheckModelUI[], justMatched: boolean = false) {
        const checkAmount = reduce(payments, (sum, payment) => {
            sum += payment.checkAmount;
            return sum;
        }, 0);

        const paymentCount = reduce(payments, (sum, payment) => {
            sum += payment.paymentCount;
            return sum;
        }, 0);

        const totalLabel = justMatched ? 'Matched' : 'Total';
        return {
            isTotal: true,
            checkAmount,
            paymentCount,
            collector: `${totalLabel}: ${payments.length}`
        };
    }
}