
<ng-template #assetClassSelectorTemplate  let-model="item" let-index="index">
    <span *ngIf="model.extraPath" class="tree-selector-path">{{model.extraPath}}</span>{{model.name}}
</ng-template>

<div class="input-group" aria-label="Select Asset Class">
    <input id="selectAssetClass"
        type="text"
        placeholder="Filter Asset Classifications..."
        class="form-control exclude-modal-styling"
        [ngClass]="{ 'overridden': isOverridden }"
        autocomplete="off"
        (typeaheadOnBlur)="onAssetClassificationBlur()"
        [(ngModel)]="assetClassificationFilter"
        [typeahead]="assetClassifications$"
        typeaheadOptionField="name"
        container="body"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        (typeaheadOnSelect)="onAssetClassificationSelected($event)"
        [typeaheadOptionsLimit]="1000"
        [typeaheadItemTemplate]="assetClassSelectorTemplate"
        [disabled]="isDisabled || !isInitialized"/>
    <button
        class="btn btn-outline-secondary btn-sm"
        type="button"
        title="View all asset classifications"
        (click)="openPickerModal()"
        [disabled]="isDisabled || !isInitialized">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
</div>
