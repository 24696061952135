import { Injectable } from '@angular/core';
import { CollectorPaymentOption } from '../../Assessor-Collector/Collector/Billing-Scenario/billingScenario.models';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailAssessment, AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { BillCluster } from './bill-cluster.model';
import { Bill } from './bill.model';
import { Payment } from './payment.model';
import { Refund } from './refund.model';
import { Decimal } from 'decimal.js';
import { BillClusterTaxRateSetupModalResult } from '../../Entity/Parcel/TaxRateSetup/billClusterTaxRateSetupModal.component';

declare const _: any;
declare const moment: any;

const PAYMENT_ENTITY_TYPE_ID = 11;
const TAXBILL_ENTITY_TYPE_ID = 9;
const REFUND_ENTITY_TYPE_ID = 14;

export class BillViewDTO {
    annualYearView: AnnualDetailYear;
    bill: Bill;
    hasAvailableTaxSetup: boolean;
}

@Injectable()
export class BillClusterService {
    constructor(private http: SDHttpService, private taskService: TaskService) { }

    getRefundViewByID(refundID: number): Promise<Refund> {
        return this.http.get(`/api/refund/${  refundID}`);
    }


    paymentPropChanged(payment: Payment): number {
		const possibleAmount = this.calcPaymentAmount(payment);
		return possibleAmount < 0 ? 0 : possibleAmount;

		//This recalcuates totals for the annual year
		// this.calcTaxTotals();
	}

    calcPaymentAmount(payment: Payment): number {
		payment.grossPayment = payment.grossPayment || 0;

		return new Decimal(payment.grossPayment)
			.plus(payment.discountAmount || 0)
			.plus(payment.penaltyAmount || 0)
			.plus(payment.interestAmount || 0)
			.toDecimalPlaces(2)
			.toNumber();
	}

    saveRefund(refund: Refund): Promise<Refund> {
        return this.http.put('/api/refund', refund);
    }

    deleteBillCluster(billClusterID: number): Promise<any> {
        return this.http.delete(`/api/billcluster/${  billClusterID}`);
    }

    deleteBill(billID: number): Promise<any> {
        return this.http.delete(`/api/bill/${  billID}`);
    }

    deleteRefund(refundID: number): Promise<any> {
        return this.http.delete(`/api/refund/${  refundID}`);
    }

    getAnnaulAssessmentsByAnnualYearID(annualYearId: number): Promise<any> {
        return this.http.get(`/api/refund/${ annualYearId}/annualassessments`);
    }   

    savePaymentAttachment(attachmentID: string, paymentID: number): Promise<any> {
        return this.http.patch('api/payment', {
            paymentID: paymentID,
            attachmentID: attachmentID || null
        });
    }

    getAccrualDetails(billClusterId: number): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.get(`/api/billcluster/${billClusterId}/accrualdetail`);
    }

    updateAccrualDetails(billClusterId: number, request: Core.BillClusterAccrualUpdateRequest): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.put(`/api/billcluster/${billClusterId}/accrualdetail`, request);
    }

    addAccrualAdjustment(billClusterId: number, request: Core.BillClusterAccrualAdjustmentRequest): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.post(`/api/billcluster/${billClusterId}/accrualadjustment`, request);
    }

    updateAccrualAdjustment(billClusterId: number, request: Core.BillClusterAccrualAdjustmentRequest): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.put(`/api/billcluster/${billClusterId}/accrualadjustment`, request);
    }

    deleteAccrualAdjustment(billClusterId: number, request: Core.BillClusterAccrualAdjustmentRemoveRequest): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.post(`/api/billcluster/${billClusterId}/accrualadjustment/remove`, request);
    }

    overrideAccrualTrueUp(billClusterId: number, request: Core.BillClusterAccrualOverrideRequest): Promise<Core.BillClusterAccrualDetailDTO> {
        return this.http.put(`/api/billcluster/${billClusterId}/accrualdetail/trueup`, request);
    }

    getOneBill(billID: number): Promise<Bill> {
        return this.getBillViewByID(billID)
            .then((billViewDTO: BillViewDTO) => {
                return billViewDTO.bill;
            });
    }

    getTabFromBillCluster(billCluster: BillCluster): BillCluster {
        billCluster.bills = _.chain(billCluster.bills)
            .map((bill: Bill): Bill => {
                return this.getBillForView(bill);
            })
            .sortBy((bill: Bill) => {
                if (bill.payments.length > 0) {
                    return bill.payments[0].dueDate !== null ? bill.payments[0].dueDate.getTime() : null;
                }

                return null;
            })
            .value();

        billCluster.showExtraFields = this.getShowExtraFields(billCluster.bills);
        billCluster.icon = billCluster.bills[0].collectorPaymentOptionID ? 'fa-refresh' : 'fa-envelope';
        billCluster.allPaymentsExcludedFromAccrual = this.areAllPaymentsExcludedFromAccruals(billCluster.bills);

        return billCluster;
    }

    getShowExtraFields(bills: Bill[]): { bill: boolean, payment: boolean } {
        const clusterPayments = _.chain(bills)
            .map('payments')
            .flatten()
            .value();

        const showPaymentFields = _.some(clusterPayments, (payment: Payment) => {
            return payment.discountAmount !== 0 || payment.interestAmount !== 0 || payment.penaltyAmount !== 0 || payment.checkDate || payment.checkNumber || payment.postDate ||
                (payment.accrualBegin && payment.originalAccrualBegin && !moment(payment.accrualBegin).isSame(payment.originalAccrualBegin)) ||
                (payment.accrualEnd && payment.originalAccrualEnd && !moment(payment.accrualEnd).isSame(payment.originalAccrualEnd));
        });

        const showBillFields = _.some(bills, (bill: Bill) => {
            return bill.directAsmt !== 0;
        });

        return {
            bill: showBillFields,
            payment: showPaymentFields
        };
    }

    areAllPaymentsExcludedFromAccruals(bills: Bill[]): boolean {
        const clusterPayments = _.chain(bills)
            .map('payments')
            .flatten()
            .value();

        // If any Payment is not excluded from Accruals, false is returned.
        return _.every(clusterPayments, (payment: Payment) => payment.accrualsExclude.excludeFromAccruals);
    }

    getBillForView(bill: Bill): Bill {
        bill.payments = _.chain(bill.payments)
            .sortBy([(payment: Payment) => {
                return payment.dueDate !== null ? payment.dueDate.getTime() : null;
            }, 'paymentID'])
            .map((payment: Payment, i: number) => {
                payment.discountAmount = payment.discountAmount || 0;
                payment.penaltyAmount = payment.penaltyAmount || 0;
                payment.interestAmount = payment.interestAmount || 0;
                payment.grossPayment = new Decimal(payment.paymentAmount)
                    .minus(payment.discountAmount)
                    .minus(payment.interestAmount)
                    .minus(payment.penaltyAmount)
                    .toNumber();

                if (payment.collectorPayment) {
                    payment.name = payment.collectorPayment.name;
                } else {
                    payment.name = 'Payment';

                    if (bill.payments.length > 1) {
                        payment.name += ` ${  i + 1  } of ${  bill.payments.length}`;
                    }
                }

                return payment;
            })
            .value();

        bill.billAmount = _.reduce(_.map(bill.payments, 'grossPayment'), (sum, payment) => {
            return sum + payment;
        });

        bill.displayProrationPct = bill.prorationPct == null ? null : new Decimal(bill.prorationPct).times(100).toNumber();

        this.getPaymentOptionsForView(bill)
            .then((options: CollectorPaymentOption[]) => {
                bill.collectorPaymentOptions = options;
            });

        return bill;
    }

    getTaxesForAnnualYear(yearID: number): Promise<BillCluster[]> {
        return Promise.all([this.getBillClustersForAnnualYear(yearID), this.getRefundsForAnnualYear(yearID)])
            .then(result => {
                const billTabs = _.chain(result[0])
                    .map((billCluster: BillCluster) => {
                        return this.getTabFromBillCluster(billCluster);
                    })
                    .sortBy([(billCluster: BillCluster) => {
                        if (billCluster.bills.length) {
                            return billCluster.bills[0].collectorPaymentOptionID;
                        }

                        return null;
                    },
                    (billCluster: BillCluster) => {
                        if (billCluster.bills.length > 0) {
                            const bill = billCluster.bills[0];

                            if (bill.payments.length > 0) {
                                return bill.payments[0].dueDate !== null ? bill.payments[0].dueDate.getTime() : null;
                            }
                        }

                        return null;
                    }])
                    .value();

                const refundTabs = _.map(result[1], (refund: Refund) => {
                    return {
                        title: `${refund.collector.abbr  }- Refund`,
                        bills: [],
                        refund: refund,
                        icon: 'fa-money'
                    };
                });

                const tabs: BillCluster[] = _.chain(billTabs)
                    .union(refundTabs)
                    .map((tab: BillCluster): BillCluster => {
                        tab.taskSummariesPromise = this.getTaskSummariesForTab(tab);

                        return tab;

                    })
                    .value();

                return tabs;

            });
    }

    getOneTab(billClusterID: number): Promise<BillCluster> {
        return this.getBillClusterByID(billClusterID)
            .then((result: BillCluster) => {
                const tab: BillCluster = this.getTabFromBillCluster(result);
                tab.taskSummariesPromise = this.getTaskSummariesForTab(tab);

                return tab;
            });
    }

    saveTab(tab: BillCluster, newBill: boolean): Promise<any> {
        if (tab.refund) {

            if (tab.refund.refundAmount) {
                tab.refund.refundAmount = -Math.abs(tab.refund.refundAmount);
            }

            if (tab.refund.refundID) {
                return this.saveRefund(tab.refund);
            } else {
                return this.createRefund(tab.refund);
            }

        } else {
            if (tab.billClusterID || newBill) {
                const billCluster = _.pick(tab, 'annualYearID', 'billClusterID', 'bills', 'calcProjected', 'collector', 'collectorID', 'taxRateAreaId', 'rowVersion');

                return this.saveBillCluster(billCluster);
            } else {
                return this.saveOneBill(tab.bills[0]);
            }
        }
    }

    saveOneBill(bill: Bill): Promise<Bill> {
        bill.collectorPaymentOption = null;

        return this.saveBill(bill);
    }

    getTaskSummariesForTab(tab: BillCluster): Promise<any> {
        if (tab.refund) {
            tab.refund.taskSummary = {};

            return this.getEntityTaskSummaries(tab.refund, REFUND_ENTITY_TYPE_ID, tab.refund.refundID);
        }

        const promises: Promise<any>[] = [];

        _.forEach(tab.bills, (bill: Bill): void => {
            // TODO: This is copied to buildBillViewModel; this should probably be refactored
            const promise = this.getTaskSummariesForBill(bill)
                .then(() => {
                    bill.showActualCheckbox = bill.payments[0].taskSummary && !_.some(bill.payments, (payment: Payment) => {
                        return !_.isEmpty(payment.taskSummary);
                    });
                });

            promises.push(promise);
        });

        return Promise.all(promises);
    }

    getTaskSummariesForBill(bill: Bill): Promise<any> {
        bill.taskSummary = {};
        const promises: Promise<any>[] = [];

        _.forEach(bill.payments, (payment: Payment): void => {
            const promise = this.getEntityTaskSummaries(payment, PAYMENT_ENTITY_TYPE_ID, payment.paymentID)
                .then((response) => {
                    payment.taskSummary = {};

                    //Filter out bill data
                    if (!bill.taskSummary || _.isEmpty(bill.taskSummary)) {
                        bill.taskSummary = _.find(response, {
                            EntityID: bill.billID,
                            EntityTypeID: TAXBILL_ENTITY_TYPE_ID
                        }) || {};
                    }

                    //Filter out payment data
                    let paymentTask = _.find(response, {
                        EntityID: payment.paymentID,
                        EntityTypeID: PAYMENT_ENTITY_TYPE_ID
                    });

                    if (!paymentTask) {
                        paymentTask = _.find(response, {
                            OriginalEntityID: payment.paymentID,
                            EntityTypeID: PAYMENT_ENTITY_TYPE_ID
                        });
                    }

                    if (paymentTask) {
                        payment.taskSummary = paymentTask;
                        if (!payment.taskSummary.Status || payment.taskSummary.Status.trim() === '') {
                            payment.taskSummary.Status = bill.taskSummary.Status;
                        }
                    } else if (bill.taskSummary.Status) {
                        payment.taskSummary = {
                            Status: bill.taskSummary.Status
                        };
                    }
                });

            promises.push(promise);
        });

        return Promise.all(promises);
    }

    getEntityTaskSummaries(entity, entityTypeID, entityID) {
        return this.taskService.getTaskSummaryByEntity(entityID, entityTypeID)
            .then((taskSummaries) => {
                if (taskSummaries && taskSummaries.length > 0) {
                    entity.taskSummary = taskSummaries[0];
                }
                return taskSummaries;
            });
    }

    setTabIndex(viewModel: TaxesViewModel, tabIndex: number): void {
        if (viewModel.currentTabIndex !== tabIndex) {
            viewModel.currentTabIndex = tabIndex;
            viewModel.currentTab = viewModel.model[tabIndex];
            viewModel.model[tabIndex].active = true;
            viewModel.resetEdit();
        }
    }

    cancelTaxesEdit(viewModel: TaxesViewModel): void {
        viewModel.cancelEdit();
    }

    saveTabFromViewModel(viewModel: TaxesViewModel): Promise<any> {
        return this.saveTab(viewModel.currentTab, false);
    }

    toggleTaxesEdit(viewModel: TaxesViewModel, editMode: boolean): void {
        if (editMode) {
            viewModel.beginEdit();
        }
    }

    setCurrentTab(viewModel: TaxesViewModel, billClusterID: number): void {
        const tabIndex = _.findIndex(viewModel.model, (billCluster: BillCluster) => {
            return billCluster.billClusterID === billClusterID;
        });

        if (tabIndex >= 0 && billClusterID) {
            this.setTabIndex(viewModel, tabIndex);
        } else {
            this.setTabIndex(viewModel, 0);
        }
    }

    setCurrentTabByRefundID(viewModel: TaxesViewModel, refundID: number): void {
        const tabIndex = _.findIndex(viewModel.model, (billCluster: BillCluster) => {
            return billCluster.refund && billCluster.refund.refundID === refundID;
        });

        if (tabIndex >= 0 && refundID) {
            this.setTabIndex(viewModel, tabIndex);
        } else {
            this.setTabIndex(viewModel, 0);
        }
    }

    getTaxesViewModelByYear(annualYearModel: AnnualDetailYear, parcelID: number, parcelAcctNum: string): Promise<TaxesViewModel> {
        return this.getTaxesForAnnualYear(annualYearModel.annualYearID)
            .then((result: BillCluster[]) => {
                const viewModel = this.buildTaxesViewModel(result, annualYearModel, parcelID, parcelAcctNum);

                return viewModel;
            });
    }

    getBillViewModelByBillID(billID: number): Promise<BillViewModel> {
        return this.getBillViewByID(billID)
            .then((billViewDTO: BillViewDTO) => {
                const viewModel = this.buildBillViewModel(billViewDTO);

                return viewModel;
            });
    }

    getRefundViewModelByRefundID(refundID: number): Promise<RefundViewModel> {
        return this.getRefundViewByID(refundID)
            .then((refund: Refund) => {
                const viewModel = this.buildRefundViewModel(refund);

                return viewModel;
            });
    }

    // A static function here isn't ideal; perhaps instead of having validate be a member
    // of the ViewModel, we could require code requiring validation to invoke the BillCluster
    // instead.
    static validateBills(bills: Bill[]): string {
        let errorMessage: string = '';

        _.forEach(bills, (bill: Bill) => {
            if(bill.prorationPct > 1) {
                errorMessage = 'Proration % must be less than 100';
                return false;
            }

            _.forEach(bill.payments, (payment: Payment) => {
                if (!payment.dueDate) {
                    errorMessage = 'Missing payment due date!';
                    return false;
                }

                if (moment(payment.accrualEnd).isBefore(payment.accrualBegin)) {
                    errorMessage = 'Accrual End is before Accrual Begin!';
                    return false;
                }

                if (moment(payment.accrualEnd).subtract(1, 'years').isAfter(payment.accrualBegin)) {
                    errorMessage = 'Accrual date range exceeds one year!';
                    return false;
                }

            });
        });

        return errorMessage;
    }

    static validateTaxes(currentTab: BillCluster): string {
        const errorMessage: string = BillClusterService.validateBills(currentTab.bills);

        if (errorMessage) {
            return errorMessage;
        }

        if (!currentTab.refund) {
            return '';
        }

        if (moment(currentTab.refund.accrualEnd).isBefore(currentTab.refund.accrualBegin)) {
            return 'Accrual End is before Accrual Begin!';
        }

        if (moment(currentTab.refund.accrualEnd).subtract(1, 'years').isAfter(currentTab.refund.accrualBegin)) {
            return 'Accrual date range exceeds one year!';
        }

        return '';
    }

    //******************** API Calls ********************
    private getBillClustersForAnnualYear(yearID: number): Promise<BillCluster[]> {
        return this.http.get('/api/billcluster/', { params: { annualYearID: yearID } });
    }

    private getRefundsForAnnualYear(yearID: number): Promise<Refund[]> {
        return this.http.get('/api/refund', { params: { annualyearid: yearID } });
    }

    private getBillClusterByID(billClusterID: number): Promise<BillCluster> {
        return this.http.get(`/api/billcluster/${  billClusterID}`);
    }

    private getBillViewByID(billID: number): Promise<BillViewDTO> {
        return this.http.get(`/api/annualassessmentview/bill/${  billID}`);
    }

    private getCollectorPaymentOptionsForParcel(collectorBillID: number, collectorBillScenarioID: number): Promise<CollectorPaymentOption[]> {
        return this.http.get('/api/collectorpaymentoption/parcel', {
            params: {
                collectorBillID: collectorBillID,
                collectorBillScenarioID: collectorBillScenarioID
            }
        });
    }
    private saveBillCluster(billCluster: BillCluster): Promise<any> {
        return this.http.put('/api/billcluster/', billCluster);
    }

    private createRefund(refund: Refund): Promise<any> {
        return this.http.post('/api/refund', refund);
    }

    private saveBill(bill: Bill): Promise<Bill> {
        return this.http.put('/api/bill', bill);
    }

    //****************** End API Calls ******************

    private getPaymentOptionsForView(bill: Bill): Promise<CollectorPaymentOption[]> {
        if (bill.collectorPaymentOption) {
            return this.getCollectorPaymentOptionsForParcel(bill.collectorPaymentOption.collectorBillID, bill.collectorPaymentOption.collectorBillScenarioID)
                .then((result: CollectorPaymentOption[]) => {
                    const option = _.chain(result)
                        .map((option: CollectorPaymentOption, i) => {
                            option.name = option.name || 'N/A';

                            return option;
                        })
                        .orderBy(['isDefault', 'name'], ['desc', 'asc'])
                        .value();

                    return option;
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve([]);
            });
        }
    }

    //*************** ViewModel factories ***************
    private buildTaxesViewModel(taxesTabs: BillCluster[], annualYearModel: AnnualDetailYear, parcelID: number, parcelAcctNum: string): TaxesViewModel {
        const viewModel = new TaxesViewModel();

        viewModel.annualYearModel = annualYearModel;
        viewModel.model = taxesTabs;
        viewModel.parcelID = parcelID;
        viewModel.parcelAcctNum = parcelAcctNum;

        if (viewModel.model.length < 1) {
            viewModel.currentTabIndex = -1;
            viewModel.currentTab = null;
        } else {
            this.setTabIndex(viewModel, 0);
        }

        return viewModel;
    }

    private buildBillViewModel(billViewDTO: BillViewDTO): BillViewModel {
        const viewModel = new BillViewModel();

        const billForView: Bill = this.getBillForView(billViewDTO.bill);
        //this.getTaskSummariesForBill(billForView);
        // TODO: This is copied from getTaskSummariesForTab; this should probably be refactored
        viewModel.taskSummariesPromise = this.getTaskSummariesForBill(billForView)
            .then(() => {
                billForView.showActualCheckbox = billForView.payments[0].taskSummary && !_.some(billForView.payments, (payment: Payment) => {
                    return !_.isEmpty(payment.taskSummary);
                });
            });

        viewModel.year = billViewDTO.annualYearView.annualYear1;
        viewModel.yearRevisions = billViewDTO.annualYearView.annualGridDetails;
        viewModel.model[0] = billForView;
        viewModel.hasAvailableTaxSetup = billViewDTO.hasAvailableTaxSetup;

        return viewModel;
    }

    private buildRefundViewModel(refund: Refund) {
        const viewModel = new RefundViewModel();

        refund.taskSummary = {};
        this.getEntityTaskSummaries(refund, REFUND_ENTITY_TYPE_ID, refund.refundID);

        viewModel.year = refund.annualYear.annualYear1;
        viewModel.model = refund;

        return viewModel;
    }
}


export class TaxesViewModel {
    model: BillCluster[];
    annualYearModel: AnnualDetailYear;
    currentTabIndex: number;
    currentTab: BillCluster;
    preEditModelBackup: BillCluster;
    parcelID: number;
    parcelAcctNum: string;
    hasWritePermission: boolean;

    private isDifferentTab() {
        if (!this.currentTab || !this.preEditModelBackup) {
            return false;
        }

        if (this.currentTab.refund && this.preEditModelBackup.refund) {
            return this.currentTab.refund.refundID !== this.preEditModelBackup.refund.refundID;
        } else {
            return this.currentTab.billClusterID !== this.preEditModelBackup.billClusterID;
        }
    }

    resetEdit(force: boolean = false): void {
        if (force || this.isDifferentTab()) {
            this.preEditModelBackup = _.cloneDeep(this.currentTab) as BillCluster;
        }
    }

    beginEdit(): void {
        if (this.currentTab) {
            this.preEditModelBackup = _.cloneDeep(this.currentTab) as BillCluster;
        } else {
            this.preEditModelBackup = undefined;
        }
    }

    cancelEdit(): void {
        const tempTab = _.cloneDeep(this.currentTab) as BillCluster;
       // _.assign(this.currentTab, this.preEditModelBackup);

        this.currentTab.bills = _.map(this.currentTab.bills, (bill: Bill, i: number) => {
            bill.taskSummary = tempTab.bills[i].taskSummary;
            bill.payments = _.map(bill.payments, (payment: Payment, j: number) => {
                payment.taskSummary = tempTab.bills[i].payments[j].taskSummary;
                return payment;
            });

            return bill;
        });

        if (this.currentTab.refund) {
            if (tempTab.refund?.taskSummary)
            this.currentTab.refund.taskSummary = tempTab.refund.taskSummary;
        }
    }

    validate(callback: (boolean, string) => void): void {
        const errorMessage: string = BillClusterService.validateTaxes(this.currentTab);

        const isValid: boolean = !errorMessage;
        callback(isValid, errorMessage);
    }
}

export class BillViewModel {
    model: Bill[];
    hasWritePermission: boolean;
    parcelID: number;
    parcelAcctNum: string;
    tabTitle: string;
    year: number;
    yearRevisions: AnnualDetailAssessment[];
    hasAvailableTaxSetup: boolean;
    taskSummariesPromise: Promise<any>;
    processingTaxSetupResult?: BillClusterTaxRateSetupModalResult;
    intakeAttachmentId?: string;
    hasProcessingParcelChanges?: boolean;

    constructor() {
        this.model = [];
    }

    assignFromExistingBill(bill: Bill): void {
        if (!this.model.length) {
            throw new Error('Invalid attempt to load bill from existing data when no current bill is set');
        }

        if (this.model[0].billID !== bill.billID) {
            throw new Error(`Invalid attempt to build bill with billID ${  bill.billID
                } into bill with billID ${  this.model[0].billID}`);
        }

        _.assign(this.model[0], bill);
    }

    validate(callback: (boolean, string) => void): void {
        const errorMessage: string = BillClusterService.validateBills(this.model);

        callback(!errorMessage, errorMessage);
    }
}

export class RefundViewModel {
    model: Refund;
    hasWritePermission: boolean;
    parcelID: number;
    parcelAcctNum: string;
    tabTitle: string;
    year: number;

    constructor() {
        this.model = null;
    }

    assignFromExistingRefund(refund: Refund): void {
        if (!this.model) {
            throw new Error('Invalid attempt to load refund from existing data when no current refund is set');
        }

        if (this.model.refundID !== refund.refundID) {
            throw new Error(`Invalid attempt to build refund with refundID ${  refund.refundID
                } into refund with refundID ${  this.model.refundID}`);
        }

        _.assign(this.model, refund);
    }
}
