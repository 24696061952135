<ws-modal-wrapper headerLabel="Reports"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!rowsSelected">
    <div class="app-ui-view d-flex flex-column w-100">
        <div class="d-flex align-items-center">
            <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
