import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../../AgGrid';
import { ReturnPreviewService } from '../returnPreview.service';

export interface FormOverridesListDataSourceParams {
    filingBatchId: number;
    returnIds: number[];
}

export class FormOverridesListAgGridDataSource extends AgGridDataSourceBase<FormOverridesListDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _returnPreviewService: ReturnPreviewService,
        protected _dataSourceParamsFn: () => FormOverridesListDataSourceParams
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ReturnFormOverridePropertyEnum>[] = [
        { name: 'fieldName', value: Compliance.ReturnFormOverridePropertyEnum.FieldName },
        { name: 'fieldNameDisplay', value: Compliance.ReturnFormOverridePropertyEnum.FieldNameDisplay },
        { name: 'formRevisionId', value: Compliance.ReturnFormOverridePropertyEnum.FormRevisionId },
        { name: 'value', value: Compliance.ReturnFormOverridePropertyEnum.Value },
        { name: 'formRevisionName', value: Compliance.ReturnFormOverridePropertyEnum.FormRevisionName },
        { name: 'parcelAcctNumberDisplay', value: Compliance.ReturnFormOverridePropertyEnum.ParcelAcctNumberDisplay},
        { name: 'parcelAssessorName', value: Compliance.ReturnFormOverridePropertyEnum.AssessorName}
    ];

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const paginationModel = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const sortModel = this.getSortColumns(this._propertyMap);

        const result: Compliance.QueryResultModel<Compliance.ReturnFormOverrideModel> = await this._returnPreviewService.getFormFieldOverrides(this._dataSourceParams.filingBatchId, paginationModel, sortModel, this._dataSourceParams.returnIds);
        if (result) {
            // If a form is currently displayed, match the ordering of the overrides with what is displayed on the form
            const overrides = this._returnPreviewService.fieldOverrideHolder.overrides;
            if (overrides && overrides.length) {
                result.data.sort((a, b) => {
                    return overrides.findIndex(x => x.fieldName === a.fieldName) - overrides.findIndex(x => x.fieldName === b.fieldName);
                })
            }

            return { rows: result.data, totalRows: result.totalRows } as AgGridDataSourceResult;
        }

        return { rows: [], totalRows: 0 } as AgGridDataSourceResult;
    }
}
