import { Component } from '@angular/core';
import { IFloatingFilterComp } from 'ag-grid-angular';
import { IFloatingFilterParams } from 'ag-grid-community';
import { AgGridTextFilterComponent } from '../../../../AgGrid/Filters/Text/agGridTextFilter.component';

@Component({
    selector: 'entity-import-editor-estimated-action-filter',
    template: `<select style="min-height: 20px; font-weight: 900; font: 'var(--fa-font-regular)'" [(ngModel)]="selectedEstimatedAction" (ngModelChange)="selectedEstimatedActionChange()">
                   <option value=""></option>
                   <option value="insert" title="Insert">&#xf067;</option>
                   <option value="update" title="Update">&#xf040;</option>
                   <option value="delete" title="Delete">&#xf1f8;</option>
                   <option value="no change" title="No change">&#xf05e;</option>
                   <option value="not evaluated" title="Not evaluated">&#xf128;</option>
               </select>`,
    styles: [`
        option {
            font-weight: 900
        }

    `]
})
export class EntityImportEditorEstimatedActionFilterRendererComponent implements IFloatingFilterComp  {
    private _params: IFloatingFilterParams;

    selectedEstimatedAction: string = '';

    agInit(params: IFloatingFilterParams): void {
        this._params = params;
    }

    destroy?(): void;

    onParentModelChanged(parentModel: any): void {
        if (!parentModel || !parentModel.filterValues.length) {
            this.selectedEstimatedAction = '';
            return;
        }
        this.selectedEstimatedAction = parentModel.filterValues[0].filterValue;
    }

    selectedEstimatedActionChange(): void {
        this._params.parentFilterInstance(x => {
            const filterInstance = x as unknown as AgGridTextFilterComponent;
            filterInstance.onFloatingFilterChanged('equals', this.selectedEstimatedAction, true);
        });
    }
}
