import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SetDocumentRetrievalStatusModalComponent } from './set-document-retrieval-status.modal.component';
import { BulkOperationResult } from '../../Common/Models/bulk-operation-result.model';
import { RetrievalStatus } from "./retrieval-status.model";

@Injectable()
export class RetrievalStatusModalService {
    setDocumentRetrievalStatusModalRef: BsModalRef;

    constructor(private modalService: BsModalService) { }

    launchSetDocumentRetrievalStatusModal(taskIds: number[], instanceId: number): Promise<() => void> {
        return new Promise<() => Promise<BulkOperationResult[]>>((resolve, reject) => {
            const initialState = {
                taskIds: taskIds,
                instanceId: instanceId,
                callback: (result: () => Promise<BulkOperationResult[]>) => {
                    resolve(result);
                }
            };

            this.setDocumentRetrievalStatusModalRef = this.modalService.show(SetDocumentRetrievalStatusModalComponent, {initialState});
        });
    }
}
