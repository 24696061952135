export class AgGridColumns {
    static textColumnWidth: number = 180;
    static textColumnSmallWidth: number = 90;
    static textColumnExtraSmallWidth: number = 60;
    static textColumnMedWidth: number = 120;
    static textColumnLargeWidth: number = 300;
    static numericColumnWidth: number = 120;
    static dateColumnWidth: number = 180;
    static selectionColumnWidth: number = 40;
    static actionColumnPerButtonWidth: number = 30;
    static rowNumberColumnWidth: number = 65;
    static rowNumberColumnWithSortingWidth: number = 85;
    static stateAbbreviationColumnWidth: number = 75;
    static checkboxColumnMinWidth: number = 75;

    static getActionColumnWidth(numButtons: number): number {
        return AgGridColumns.actionColumnPerButtonWidth * numButtons;
    }
}
