<select
    #selectedAssetStatusFilterEl
    name="selectedAssetStatusFilter"
    class="form-select form-select-sm"
    [ngModel]="selectedFilterValue"
    (ngModelChange)="onSelectedAssetStatusFilterChange($event)"
    [disabled]="!assetStatusFilters.length || isDisabled">
    <option [ngValue]="allAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''"
    >{{ allAssetStatusFilter.name }}</option>
    <option [ngValue]="changedAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ changedAssetStatusFilter.name }}</option>
    <option [ngValue]="notChangedAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ notChangedAssetStatusFilter.name }}</option>
    <optgroup label="Additions">
        <option [ngValue]="newAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ newAssetStatusFilter.name }}</option>
        <option [ngValue]="transferInAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ transferInAssetStatusFilter.name }}</option>
        <option [ngValue]="newOrTransferInAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ newOrTransferInAssetStatusFilter.name }}</option>
        <option [ngValue]="changedCostIncreasedAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ changedCostIncreasedAssetStatusFilter.name }}</option>
        <option [ngValue]="changedToReportedFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ changedToReportedFilter.name }}</option>
        <option [ngValue]="allAdditionsAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ allAdditionsAssetStatusFilter.name }}</option>
    </optgroup>
    <optgroup label="Deductions">
        <option [ngValue]="disposedAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts ? 'Option not applicable for the selected report' : ''"
        >{{ disposedAssetStatusFilter.name }}</option>
        <option
            [ngValue]="transferOutAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts ? 'Option not applicable for the selected report' : ''">{{ transferOutAssetStatusFilter.name }}</option>
        <option
            [ngValue]="disposedOrTransferOutAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsDisposalsOrTransferOuts ? 'Option not applicable for the selected report' : ''">{{ disposedOrTransferOutAssetStatusFilter.name }}</option>
        <option [ngValue]="changedCostDecreasedAssetStatusFilter"
                [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
                [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
                [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ changedCostDecreasedAssetStatusFilter.name }}</option>
        <option
            [ngValue]="changedToNotReportedFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions"
            [ngClass]="{
                    'text-muted' :allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions ? 'Option not applicable for the selected report' : ''">{{ changedToNotReportedFilter.name }}</option>
        <option
            [ngValue]="allDeductionsAssetStatusFilter"
            [disabled]="allowFilteringItemsBasedOnDataSource && !disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions && !disableDeductionsDisposalsOrTransferOuts"
            [ngClass]="{
                    'text-muted' : allowFilteringItemsBasedOnDataSource && !disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions && !disableDeductionsDisposalsOrTransferOuts
                    }"
            [title]="allowFilteringItemsBasedOnDataSource && !disableDeductionsOtherThanDisposalsAndTransferOutOrTotalDeductions && !disableDeductionsDisposalsOrTransferOuts ? 'Option not applicable for the selected report' : ''">{{ allDeductionsAssetStatusFilter.name }}</option>
    </optgroup>
</select>
