// For now we're upgrading the sd.http service for use in Angular 2+; if we later want to create a new Angular 2+ http wrapper,
// we should call it http.service instead of sd-http.service (I think)

export abstract class SDHttpService {
    abstract get(url: string, config?: {}) : Promise<any>;
    abstract post(url: string, data?: any, config?: {}) : Promise<any>;
    abstract put(url: string, data?: any, config?: {}) : Promise<any>;
    abstract delete(url: string, config?: {}) : Promise<any>;
    abstract deleteByEntity(url: string, data: any, config?: {}) : Promise<any>;
    abstract patch(url: string, data?: any, config?: {}) : Promise<any>;
    abstract initializeTimestampCheck(checkPromise: Promise<void>): void;
}
