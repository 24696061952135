<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="collector">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="collector.name"></ws-bread-crumbs>
    <div *ngIf="collector" class="row equal-panel" [ngClass]="{ 'remove-panel': isTaxRatesMaximized }">
        <div class="col-xl-4">
            <collector-details [entityId]="entityId"
                               [companyId]="companyId"
                               [collectorValue]="collector"
                               [viewOnly]="viewOnly"
                               [isCompanyCollector]="isCompanyCollector">
            </collector-details>
        </div>
        <div class="col-xl-8">
            <billing-scenario [collector]="collector"
                              (collectorUpdated)="refreshCollector()">
            </billing-scenario>
        </div>
    </div>

    <div class="row" *ngIf="!isCompanyCollector">

        <div class="col-xl-12">
            <tax-rates-panel [collectorId]="collector.collectorID"
                             [annualRateIncrease]="collector.annualRateIncrease"
                             [assessorId]="collector.assessorID"
                             (maximizeToggled)="toggleTaxRatesMaximize($event)">
            </tax-rates-panel>
        </div>
    </div>


    <div *ngIf="(!isCompanyCollector || !viewOnly)" class="row" [ngClass]="{ 'remove-panel': isTaxRatesMaximized }">
        <div class="col-xl-12">
            <collector-protocols [collector]="collector"
                                 [canEdit]="!viewOnly">
            </collector-protocols>
        </div>
    </div>
    <div *ngIf="!isCompanyCollector && entityProperties" class="row" [ngClass]="{ 'remove-panel': isTaxRatesMaximized }">
        <div class="col-xl-12">
            <contacts-panel [entity]="entityProperties"></contacts-panel>
        </div>
    </div>
</div>
