(function () {
    'use strict';

    var APP_SOURCE = 'sdNavbarTopService';

    angular
        .module('weissmanApp')
        .factory(APP_SOURCE, Service);

    Service.$inject = [
        '$location',
        '$window'
    ];

    function Service($location, $window) {
        var showNavbar = true;
        var fullScreen = false;

        var service = {
            showNavbar: showNavbar,
            fullScreen: fullScreen
        };

        service.showEmailWindow = function(route, itemName, itemType){
            var subject = 'Shared ' + itemType;

            var url = route;
            // Get rid of leading #
            if (url[0] === '#') {
                url = url.substr(1);
            }

            url = $location.url(url).absUrl();

            var body = encodeURIComponent(
                'Hello.\r\n\r\n' +
                'Click on the link below to access the ' + itemType + ' "' + itemName + '" which has been shared with you in PropertyPoint.\r\n\r\n' +
                'You may run it directly or, after opening it, save it as one of your own ' + itemType + 's by clicking Save As.\r\n\r\n' +
                url + '\r\n\r\n'
            );

            $window.open("mailto:" + '' + "?subject=" + subject + "&body=" + body, "_self");
        }

        return service;
    }
})();
