import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { AllocationRepository, CompanyAssetDescriptorRepository } from '../../Repositories';
import { BeginNewImportComponent } from '../../../Common/import/beginNewImport.component';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import {
    AgGridMultiSelectCellRendererParams,
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectHeaderRendererParams,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../AgGrid/MultiSelectTracker';
import {
    CellValueChangedEvent,
    ColDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams
} from 'ag-grid-community';
import {
    AgGridColumns,
    AgGridDropdownCellEditor,
    AgGridFilterParams,
    AgGridNumberCellEditor,
    AgGridOptionsBuilder,
    AgGridTextCellEditor
} from '../../AgGrid';
import { AgGridExportOptions, AgGridExportStartLRP, AgGridToolPanelAction } from '../../AgGrid/ToolPanel/models';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import {
    AgGridCheckboxCellRendererComponent,
    ICellRendererParamsForAgGridCheckbox
} from '../../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { AllocationDetailsListAgGridDataSource, AllocationDetailsListDataSourceParams } from './agGridDataSource';
import { AllocationDetailRepository } from '../../Repositories/allocationDetail.repository';
import { AllocationDetailGridRowModel } from './AllocationDetailGridRowModel';
import { IMutexServiceHandler, WeissmanMutexService } from '../../WeissmanMutexService';
import { NavigationService } from '../../../Layout/navigation.service';
import {
    AllocationDetailDragAndDropSourceComponent,
    ICellRendererParamsForAllocationDetailDragAndDropSource
} from './Drag-And-Drop/allocationDetailDragAndDropSource.component';
import { AllocationDetailDragAndDropService } from './Drag-And-Drop/allocationDetailDragAndDrop.service';
import { AllocationDetailsService } from './allocationDetails.service';
import { AssessmentSummaryInfo } from './assessmentSummaryInfo';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AddTaxAuthorityComponent, AddTaxAuthorityParams } from './Add-Tax-Authority/addTaxAuthority.component';
import {
    AllocationDetailBulkUpdateComponent,
    AllocationDetailBulkUpdateParams
} from './Allocation-Detail-Bulk-Update/allocationDetailBulkUpdate.component';
import { IsColumnFuncParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
    ReturnAssetChangeIconCellRendererComponent
} from '../../Return/Return-Parts/Assets/Asset-List/agGridChangeIconCellRenderer.component';
import { WeissmanDateFormatPipe } from '../../../UI-Lib/Pipes';
import { ReturnService } from '../../Return/return.service';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../../Comments/comments.service';
import { InstanceRights, RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import {
    CreateNewMergedParcelsComponent,
    CreateNewMergedParcelsParams
} from './Create-New-Merged-Parcels/create-new-merged-parcels.component';
import { AssetDetailsComponent, AssetDetailsParams } from '../../Asset/Asset-Details/assetDetails.component';
import {
    AllocationDetailListGridActionCellRendererComponent,
    ICellRendererParamsForAllocationDetailListGridAction
} from './agGridActionCellRenderer.component';
import {
    AllocationDetailDragAndDropCellRendererComponent,
    DragAndDropCellRendererParams
} from './Drag-And-Drop/allocationDetailDragAndDropCellRenderer.component';
import {
    AgGridAssessmentSummaryActionCellRendererComponent,
    ICellRendererParamsForAssessmentSummaryCellRenderer
} from './agGridAssessmentSummaryActionCellRenderer.component';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import {
    AgGridAssessmentSummaryActionHeaderRendererComponent,
    ICellRendererParamsForAssessmentSummaryHeaderRenderer
} from './agGridAssessmentSummaryActionHeaderRenderer.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ALLOCATIONS_HELP } from './allocationDetails.help';
import { takeUntil } from 'rxjs/operators';

import { filter, sortBy } from 'lodash/fp';
import * as _ from 'lodash';
import { AllocationService } from '../allocation.service';
import { RetrieveAssetsComponent, RetrieveAssetsParams } from './Retrieve-Assets/retrieveAssets.component';
import { SiteService } from '../../../Entity/Site/Site.Service.upgrade';
import {
    AssetsInAnotherAllocationsComponent,
    AssetsInAnotherAllocationsParams
} from './Assets-In-Another-Allocations/assetsInAnotherAllocations.component';
import {
    SelectTaxAuthoritiesParams,
    SelectTaxAuthorityComponent
} from './Select-Tax-Authority/selectTaxAuthority.component';
import {
    MessageModalButtons,
    MessageModalDisplayMode,
    MessageModalOptions,
    MessageModalResult,
    MessageModalService
} from '../../../UI-Lib/Message-Box/messageModal.service';
import AllocationAssessmentSummaryTypeEnum = Compliance.AllocationAssessmentSummaryTypeEnum;
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

enum AssessmentActions {
    LockAllocation,
    ReleaseLockedAllocation,
    DistributeVariance,
    CreateNewMergedParcels
}

@Component({
    selector: 'allocation-details',
    templateUrl: './allocationDetails.component.html',
    styleUrls: ['./allocationDetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AllocationDetailsComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _allocationRepository: AllocationRepository,
        private readonly _allocationDetailRepository: AllocationDetailRepository,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _navigationService: NavigationService,
        private readonly _allocationDetailDragAndDropService: AllocationDetailDragAndDropService,
        private readonly _allocationDetailService: AllocationDetailsService,
        private readonly _modalService: WeissmanModalService,
        private readonly _companyService: CompanyService,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _returnService: ReturnService,
        private readonly _companyAssetDescriptorRepository: CompanyAssetDescriptorRepository,
        private readonly _restrictService: RestrictService,
        private readonly _helpService: HelpService,
        private readonly _allocationService: AllocationService,
        private readonly _siteService: SiteService,
        private readonly _messageModalService: MessageModalService) {
    }

    private _assessmentGridApi: GridApi;
    private _gridApi: GridApi;
    private _gridDataSource: AllocationDetailsListAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _editGroup: string = 'allocation-details-edit-group';
    private _statuses: Compliance.AllocationDetailStatusModel[];
    private _totalAssessmentsSummary: AssessmentSummaryInfo;
    private _unassignedAssessmentsSummary: AssessmentSummaryInfo;
    private _assessmentsSummary: AssessmentSummaryInfo[];
    private _dragging: boolean;
    private _companyAssetDescriptorMappings: Compliance.CompanyAssetDescriptorMappingModel[];
    private _allocationDetailSelectionInfo: Compliance.AllocationDetailSelectionInfo = {
        hasNotMatchedAssets: false,
        hasNewParcels: false
    };

    private _destroy$: Subject<void> = new Subject();

    @ViewChild('newImport') newImport: BeginNewImportComponent;
    allocation: Compliance.AllocationModel;

    allocationId: number;
    companyId: number;
    isInitialized: boolean = false;
    breadcrumbs: BreadCrumb[] = [];
    assessmentGridTracker: AgGridMultiSelectTracker;
    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = '111F9E56-B96B-4BAB-AB6F-856B067062B8';
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    assetGridTotalsRow: any;
    assetGridSelectedTotalsRow: any;
    selectedTotalsLoading: boolean = true;
    totalsLoading: any;
    editing: boolean = false;
    hideLockedAssessments: boolean = false;
    assessmentsSummaryFilter: string;
    hideNotReportedAssets: boolean = false;
    hasSelectedRows: boolean = false;
    canEditCompany: boolean = false;
    selectedAction: any;
    canViewRyanPrivateItems: boolean = false;
    assessors: BreadCrumb[];
    managementReviewSelectedOption: Compliance.AllocationDetailManagementReviewOptionEnum;
    assessmentActions: any[] = [
        {
            label: 'Lock Allocation',
            value: AssessmentActions.LockAllocation,
            disabled: true
        },
        {
            label: 'Release Locked Allocation',
            value: AssessmentActions.ReleaseLockedAllocation,
            disabled: true
        },
        {
            label: 'Distribute Variance',
            value: AssessmentActions.DistributeVariance,
            disabled: true
        },
        {
            label: 'Create new parcels and assessment for unallocated',
            value: AssessmentActions.CreateNewMergedParcels,
            disabled: true
        }
    ];

    managementReviewOptions: any[] = [
        {
            label: '',
            value: null,
        },
        {
            label: 'Final Assessed Allocated Value > Cost',
            value: Compliance.AllocationDetailManagementReviewOptionEnum.FinalAssessedAllocatedValueGreaterThanCost
        },
        {
            label: 'Final Assessed Allocated Value > Prior Yr Final Assessed Allocated Value',
            value: Compliance.AllocationDetailManagementReviewOptionEnum.FinalAssessedAllocatedValueGreaterThanPriorYrFinalAssessedAllocatedValue
        },
        {
            label: 'Prior Yr Factor = Current Yr Factor',
            value: Compliance.AllocationDetailManagementReviewOptionEnum.PriorYrFactorEqualsCurrentYrFactor
        },
        {
            label: 'Return Value <> Final Assessed Allocated Value',
            value: Compliance.AllocationDetailManagementReviewOptionEnum.ReturnValueNotEqualsFinalAssessedAllocatedValue
        }
    ];

    assessmentGridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'phantom-summary': (params) => params.data && params.data.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom,
            'estimated-summary': (params) => params.data && params.data.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal && !params.data.isActual && !params.data.hasActiveAppeal,
            'has-active-appeal': (params) => params.data && params.data.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal && params.data.hasActiveAppeal,
            'dragging': (params) => this._dragging
        },
        rowHeight: 39
    }).withContext(this)
      .withColumnResize()
      .build();

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as AllocationDetailGridRowModel).allocationDetailId),
            'totals-row': (params) => params.data && params.data.totalsRow,
            'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow,
            'not-matched': (params) => {
                const allocationDetail = params.data as AllocationDetailGridRowModel;
                return allocationDetail && !allocationDetail.isMatched;
            }
        },
        singleClickEdit: true
    })
        .withCellEditingStarted(() => {
            this.startEditMode();
        })
        .withCellEditingStopped(() => {
            this.endEditMode();
        })
        .withoutCellEditingStoppedOnGridLostFocus()
        .withColumnPinning()
        .buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();
            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0) {
                searchParams.selectedRows.selectAllRows = true;
            }
            const exportModel: Compliance.AllocationDetailExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                fileFormat
            };

            const lrp$ = this._allocationDetailRepository.startExport(this.allocationId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportAllocationDetails };
        },
        canCancel: true,
        showFileFormatSelection: true
    };

    gridActions: AgGridToolPanelAction[] = [
        {
            name: 'Match to existing non-allocated assets',
            helpContentId: '',
            disabled: () => !this._allocationDetailSelectionInfo.hasNotMatchedAssets,
            onClickCallback: this.matchToExistingNonAllocatedAssets.bind(this)
        },
        {
            name: 'Create assets not matched from work papers',
            helpContentId: '',
            disabled: () => !this._allocationDetailSelectionInfo.hasNotMatchedAssets,
            onClickCallback: this.createAssetsNotMatchedFromWorkPapers.bind(this)
        },
        {
            name: 'Create new parcels and assessments for unallocated',
            helpContentId: '',
            disabled: () => !this._allocationDetailSelectionInfo.hasNewParcels,
            onClickCallback: this.createNewParcelsFromDetails.bind(this)
        }
    ];

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ALLOCATIONS_HELP);

        this.allocationId = parseInt(this._routerService.getQuerystringParam('allocationId'));
        this.canViewRyanPrivateItems = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading Allocation Details' });

        try {
            await lastValueFrom(this._allocationRepository.prepareData(this.allocationId));

            this.allocation = await lastValueFrom(this._allocationRepository.get(this.allocationId));
            this.companyId = this.allocation.companyId;

            const responses = await Promise.all([
                lastValueFrom(this._allocationDetailRepository.getStatuses(this.allocationId)),
                lastValueFrom(this._allocationRepository.getAssessmentsSummary(this.allocationId, {} as Compliance.AllocationAssessmentSummarySearchModel)),
                lastValueFrom(this._companyAssetDescriptorRepository.getByCompanyId(this.allocation.companyId)),
                this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write),
                this._companyService.load(this.companyId, false, false)
            ]);

            this._statuses = responses[0] as Compliance.AllocationDetailStatusModel[];
            _.orderBy(this._statuses, i => i.displayOrder);

            this._statuses.splice(0, 0, {} as Compliance.AllocationDetailStatusModel);

            this._setAssessmentsSummary(this._convertToAssessmentSummaryInfo(responses[1] as Compliance.AllocationAssessmentSummaryModel[]));

            this._companyAssetDescriptorMappings = responses[2] as Compliance.CompanyAssetDescriptorMappingModel[];

            this.canEditCompany = responses[3];

            const company = responses[4];
            if (!(company.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, company.instanceID))) {
                this.navigateToUnauthorized();
            }
        } finally {
            busyRef.hide();
        }

        const navigationProperties = await this._siteService.getNavigationInfo(this.allocation.siteId);
        const companyName = await this._companyService.getCompanyName(navigationProperties.companyID);
        this.breadcrumbs = [
            {
                name: companyName,
                target: 'company',
                options: { companyId: navigationProperties.companyID }
            },
            {
                name: this.allocation.siteName,
                target: 'site',
                options: { companyId: navigationProperties.companyID, siteId: navigationProperties.siteID }
            }
        ];

        this.assessors = this.allocation.assessors
            .sort((x, y) => x.assessorName.localeCompare(y.assessorName))
            .map(x => { return {
                name: x.assessorName,
                target: 'assessor',
                options: {stateId: x.stateId, id: x.assessorId}
            } as BreadCrumb;});

        this._allocationDetailDragAndDropService.start(this._getDraggedAllocationDetailIds.bind(this));
        this._allocationDetailDragAndDropService.dragging$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            if (x) {
                this._setAssessmentDraggingColumns();
            } else {
                this._setAssessmentGridColumns();
            }
        });

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._allocationDetailDragAndDropService.stop();
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._allocationService.navigationParcelId = null;
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    get uploadNewImport(): boolean {
        return !this.allocation || !this.allocation.lastImportFile || this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed;
    }

    async navigateToLastImport(): Promise<void> {
        if (this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Processing ||
            this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferring ||
            this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Reverting ||
            this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Validating) {
            await this.newImport.showImportFileIsProcessingMessage(this.allocation.lastImportFile);
        } else if (this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Uploaded ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.ShowPreview) {
            const resumeResult = await this.newImport.resumeImport(this.allocation.lastImportFile);
            if (resumeResult) {
                this._routerService.go('processAllocationImport', {
                    allocationId: this.allocationId,
                    importId: this.allocation.lastImportFileId
                });
            }
        } else if (this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingError ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingCompleted ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferred ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferError ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferCompleted ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.RevertingError ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed ||
                   this.allocation.lastImportFile.processStatus === Compliance.ImportFileProcessStatusEnum.Validated) {
            this._routerService.go('processAllocationImport', {
                allocationId: this.allocationId,
                importId: this.allocation.lastImportFileId
            });
        }
    }

    onAssessmentAgGridReady(event: GridReadyEvent): void {
        this._assessmentGridApi = event.api;

        this.assessmentGridTracker = new AgGridMultiSelectTracker(this.assessmentGridOptions, this._getAssessmentGridRowIds.bind(this));

        this._setAssessmentGridColumns();
        this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._setGridColumns();

        const defaultSortModel = [
            {
                colId: 'assetNumber',
                sort: 'asc'
            }
        ];

        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            const isBulkUpdateVisible = this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this.hasSelectedRows = isBulkUpdateVisible;

            const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
            const model: Compliance.SelectedRowsModel = {
                selectAllRows: selectedRowsModel.selectAllRows,
                selectedRows: selectedRowsModel.selectedRows
            };

            lastValueFrom(this._allocationDetailRepository.getSelectedRowsInfo(this.allocationId, model)).then(x => this._allocationDetailSelectionInfo = x);
            await this._reloadTotals();
        });
    }

    startEditMode(): void {
        this.editing = true;
        this._mutexService.acquire(this, this._editGroup);
        this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
    }

    endEditMode(): void {
        this.editing = false;
        this._gridApi.stopEditing();
        this._mutexService.release(this, this._editGroup);
        this._navigationService.disableNavWarning();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    handleTotalsUpdate = (totals: Compliance.AllocationDetailTotalsModel, selectedTotals: boolean = false, isLoading: boolean = false) => {
        const totalsRow: any = {
            reportedCost: totals.totalReportedCost,
            returnValue: totals.totalReturnValue,
            initialAllocationAmount: totals.totalInitialAllocationValue,
            finalAllocationAmount: totals.totalFinalAllocationValue,
            estimatedFMV: totals.totalEstimatedFMV,
            inventoryApr: totals.totalInventoryApr,
            inventoryAug: totals.totalInventoryAug,
            inventoryAvr: totals.totalInventoryAvr,
            inventoryAvrCalculated: totals.totalInventoryAvrCalculated,
            inventoryDec: totals.totalInventoryDec,
            inventoryFeb: totals.totalInventoryFeb,
            inventoryJan: totals.totalInventoryJan,
            inventoryJul: totals.totalInventoryJul,
            inventoryJun: totals.totalInventoryJun,
            inventoryMar: totals.totalInventoryMar,
            inventoryMay: totals.totalInventoryMay,
            inventoryNov: totals.totalInventoryNov,
            inventoryOct: totals.totalInventoryOct,
            inventorySep: totals.totalInventorySep,
            leaseMonthlyPayment: totals.totalLeaseMonthlyPayment,
            n1: totals.totalN1,
            n10: totals.totalN10,
            n2: totals.totalN2,
            n3: totals.totalN3,
            n4: totals.totalN4,
            n5: totals.totalN5,
            n6: totals.totalN6,
            n7: totals.totalN7,
            n8: totals.totalN8,
            n9: totals.totalN9,
            netBookValue: totals.totalNetBookValue,
            workPaperCost: totals.totalWorkPaperCost,
            varianceDollar: totals.totalVarianceDollar,
            priorYearFinalAllocationAmount: totals.totalPriorYearFinalAllocationAmount,
            taxVarianceDollar: totals.totalTaxVarianceDollar
        };

        if (isLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                totalsRow.assetNumber = msg;
                this._gridApi.setPinnedBottomRowData([totalsRow]);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        if (selectedTotals) {
            totalsRow.selectedTotalsRow = true;
            totalsRow.assetNumber = 'SELECTED';
            this.assetGridSelectedTotalsRow = totalsRow;
        } else {
            totalsRow.totalsRow = true;
            totalsRow.assetNumber = 'TOTAL';
            this.assetGridTotalsRow = totalsRow;
        }
        const totalRows = [this.assetGridTotalsRow];
        if (this.gridTracker.hasAnythingSelected()) {
            totalRows.push(this.assetGridSelectedTotalsRow);
        }
        setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    refresh(): void {
        this.refreshAssetsList();
        this._refreshAssessmentsSummary();
    }

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    get hasLockedAssessments(): boolean {
        return !!_.find(this._assessmentsSummary, i => i.isLocked && i.isSelected && !i.isComitted);
    }

    get hasLockedNotReviewedAssessments(): boolean {
        return !!_.find(this._assessmentsSummary, i => i.isLocked && !i.isMarkedAsReviewed && i.isSelected);
    }

    async onAllocationDetailsDropped(allocationDetailIds: number[], summary: AssessmentSummaryInfo): Promise<void> {
        let allocationAnnualAssessmentIds: number[];

        if (!summary.isTaxAuthority && summary.taxAuthoritiesCount != 0) {
            const parcelTaxAuthorities = this._assessmentsSummary
                .filter(x => x.annualAssessmentId === summary.annualAssessmentId && x.isTaxAuthority);

            const params: SelectTaxAuthoritiesParams = {
                allocationId: this.allocationId,
                selectedAllocationDetailIds: allocationDetailIds,
                lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
                taxAuthorities: parcelTaxAuthorities
            };

            const result = await this._modalService.showAsync(SelectTaxAuthorityComponent, params, 'modal-md');

            if (!result) {
                return Promise.resolve();
            }

            allocationAnnualAssessmentIds = result.selectedAssessmentSummaries;
        } else if (summary.allocationAnnualAssessmentId) {
            allocationAnnualAssessmentIds = [summary.allocationAnnualAssessmentId];
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Allocating Assets' });

        try {
            const model: Compliance.AllocationDetailAllocateModel = {
                allocationAnnualAssessmentIds: allocationAnnualAssessmentIds,
                selectAllRows: false,
                selectedRows: allocationDetailIds,
                lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
            };

            await lastValueFrom(this._allocationDetailRepository.allocate(this.allocationId, model));

            const assessmentsSummary = await lastValueFrom(this._allocationRepository.getAssessmentsSummary(this.allocationId,
                { hideLockedAssessment: this.hideLockedAssessments } as Compliance.AllocationAssessmentSummarySearchModel));
            this._setAssessmentsSummary(this._convertToAssessmentSummaryInfo(assessmentsSummary));
            this.refreshAssetsList();
        } finally {
            this.gridTracker.clear();
            busyRef.hide();
        }
    }

    onAllocationSummaryFilterClick(value: AssessmentSummaryInfo): void {
        this._allocationDetailService.allocationDetailsFilter = value;

        this.refreshAssetsList();
    }

    clearAssessmentFilter(): void {
        this._allocationDetailService.allocationDetailsFilter = null;
        this._assessmentGridApi.deselectAll();

        this.refreshAssetsList();
    }

    async addTaxAuthority(): Promise<void> {
        const params: AddTaxAuthorityParams = {
            allocationId: this.allocationId,
            showWarning: this._assessmentsSummary.some(x =>
                x.summaryType === AllocationAssessmentSummaryTypeEnum.Normal && x.annualAssessmentId &&
                !x.isTaxAuthority && !x.isLocked && x.assetsCount && x.taxAuthoritiesCount === 0)
        };

        const result = await this._modalService.showAsync(AddTaxAuthorityComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        result.newSummaries.forEach(x => this._assessmentsSummary.push(new AssessmentSummaryInfo(x)));
        this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);

        return Promise.resolve();
    }

    async onLockReleaseLockedAssessmentClick(assessmentSummaryInfo: AssessmentSummaryInfo, lock: boolean): Promise<void> {
        const model = {
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        } as Compliance.SelectAllocationAssessmentModel;

        if (this.isUnchecked()) {
            model.selectedRows = [assessmentSummaryInfo.allocationAnnualAssessmentId];
        } else {
            model.selectedRows = _(this._assessmentsSummary)
                .filter(i => i.isSelected && (lock && i.canLock || !lock && i.canReleaseLock))
                .map(i => i.allocationAnnualAssessmentId)
                .value();
            if (!_.find(model.selectedRows, i => i === assessmentSummaryInfo.allocationAnnualAssessmentId)) {
                model.selectedRows.push(assessmentSummaryInfo.allocationAnnualAssessmentId);
            }
        }
        if (lock) {
            await this._lockAssessments(model);
        } else{
            await this._releaseLockedAssessments(model);
        }
    }

    async bulkUpdate(): Promise<void> {
        const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();

        const params: AllocationDetailBulkUpdateParams = {
            allocationId: this.allocationId,
            selection: this.gridTracker.getSelectedRowsModel(),
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            allocationDetailListLastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
            statuses: this._statuses,
            assessmentsSummary: this._assessmentsSummary,
            removeExistingAllocations: true
        };

        if (params.selection.selectAllRows && searchModel.summaryFilter) {
            const selectedRowIds = await this.gridTracker.getSelectedRowIds();
            params.selection.selectAllRows = false;
            params.selection.selectedRows = selectedRowIds;
        }

        const result = await this._modalService.showAsync(AllocationDetailBulkUpdateComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const assessmentsSummary = await lastValueFrom(this._allocationRepository.getAssessmentsSummary(this.allocationId,
            { hideLockedAssessment: this.hideLockedAssessments } as Compliance.AllocationAssessmentSummarySearchModel));
        this._setAssessmentsSummary(this._convertToAssessmentSummaryInfo(assessmentsSummary));

        this.gridTracker.clear();
        await this.refreshAssetsList();
        await this._reloadTotals();
    }

    isChecked(): boolean {
        const totalAssessmentsCount = this._assessmentsSummary.length;
        const checkedCount = _.filter(this._assessmentsSummary, i => i.isSelected).length;

        return totalAssessmentsCount !== 0 && totalAssessmentsCount === checkedCount;
    }

    isUnchecked(): boolean {
        const totalAssessmentsCount = this._assessmentsSummary.length;
        const unCheckedCount = _.filter(this._assessmentsSummary, i => !i.isSelected).length;

        return totalAssessmentsCount === unCheckedCount;
    }

    isIndeterminate(): boolean {
        const totalAssessmentsCount = this._assessmentsSummary.length;
        const checkedCount = _.filter(this._assessmentsSummary, i => i.isSelected).length;

        return totalAssessmentsCount !== checkedCount && !!checkedCount;
    }

    async onDeletTaxAuthorityClick(assessmentSummaryInfo: AssessmentSummaryInfo): Promise<void> {
        const model = {} as Compliance.DeleteTaxAuthorityModel;

        if (this.isUnchecked()) {
            model.selectedRows = [assessmentSummaryInfo.allocationAnnualAssessmentId];
        } else if (this.isIndeterminate()) {
            model.selectedRows = _(this._assessmentsSummary)
                .filter(i => i.isSelected && i.canDelete)
                .map(i => i.allocationAnnualAssessmentId)
                .value();
            if (!_.find(model.selectedRows, i => i === assessmentSummaryInfo.allocationAnnualAssessmentId)) {
                model.selectedRows.push(assessmentSummaryInfo.allocationAnnualAssessmentId);
            }
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting Tax Authorities' });

        try {
            const affectedAssessmentSummaries = await lastValueFrom(this._allocationRepository.deleteTaxAuthority(this.allocationId, model));

            this._assessmentsSummary = this._assessmentsSummary.filter(x => _.find(model.selectedRows, j => j !== x.allocationAnnualAssessmentId));
            affectedAssessmentSummaries.forEach(x => {
                const index = this._assessmentsSummary.findIndex(y => y.allocationAnnualAssessmentId === x.allocationAnnualAssessmentId);
                if (index >= 0) {
                    this._assessmentsSummary[index] = new AssessmentSummaryInfo(x);
                }
            });
            this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);
        } finally {
            busyRef.hide();
        }
    }

    sizeToFit(): void {
        this.assessmentGridOptions.api.sizeColumnsToFit();
    }

    sizeToContent(): void {
        this.assessmentGridOptions.columnApi.autoSizeAllColumns();
    }

    toggleAssessmentGridFilters(): void {
        this.assessmentGridOptions.floatingFilter = !this.assessmentGridOptions.floatingFilter;
        this.assessmentGridOptions.api.refreshHeader();

        // clear filter when hiding
        if (!this.assessmentGridOptions.floatingFilter) {
            this.assessmentGridOptions.api.setFilterModel(null);
            this.assessmentGridOptions.api.onFilterChanged();
        }
    }

    async filterAssessmentsSummary(filter: string): Promise<void> {
        if (filter !== this.assessmentsSummaryFilter) {
            this.assessmentsSummaryFilter = filter;
            await this._refreshAssessmentsSummary();
        }
    }

    get isFiltered(): boolean {
        return !!this.assessmentsSummaryFilter && this.assessmentsSummaryFilter !== '';
    }

    get hasReadyToLockAllocations(): boolean {
        return !!_.find(this._assessmentsSummary, i =>
            i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal &&
            !i.isTaxAuthority &&
            !i.isLocked &&
            i.isActual &&
            i.FMV &&
            i.allocationAmount &&
            i.variance === 0 &&
            i.isSelected);
    }

    get canDistributeVariance(): boolean {
        return !!_.find(this._assessmentsSummary, i =>
            i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal &&
            !i.isTaxAuthority &&
            !i.isLocked &&
            i.FMV &&
            i.variance !== 0 &&
            i.isSelected);
    }

    async lockAllocation(): Promise<void> {
        const model = {
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        } as Compliance.SelectAllocationAssessmentModel;

        model.selectedRows = _(this._assessmentsSummary)
            .filter(i => i.variance === 0 && !i.isLocked && (this.isChecked() || i.isSelected))
            .map(i => i.allocationAnnualAssessmentId)
            .value();

        await this._lockAssessments(model);
    }

    async releaseLockedAllocation(): Promise<void> {
        const model = {
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        } as Compliance.SelectAllocationAssessmentModel;

        model.selectedRows = _(this._assessmentsSummary)
            .filter(i => i.isLocked && (this.isChecked() || i.isSelected))
            .map(i => i.allocationAnnualAssessmentId)
            .value();

        await this._releaseLockedAssessments(model);
    }

    async distributeVariance(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Distributing Variance' });

        const selectedRows = this.isChecked()
            ? []
            : _(this._assessmentsSummary)
                .filter(i => i.variance !== 0 && !i.isLocked && this.isChecked() ? !i.isSelected : i.isSelected)
                .map(i => i.allocationAnnualAssessmentId)
                .value();

        const model = {
            selectAllRows: this.isChecked(),
            selectedRows: selectedRows,
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        } as Compliance.AllocationDistributeAssessmentVarianceModel;

        try {
            const result = await lastValueFrom(this._allocationRepository.distributeAssessmentVariance(this.allocationId, model));

            _.forEach(result.assessmentsSummary, u => {
                const existingSummaryIndex = _.findIndex(this._assessmentsSummary,
                    e => e.allocationAnnualAssessmentId === u.allocationAnnualAssessmentId);
                this._assessmentsSummary[existingSummaryIndex] = new AssessmentSummaryInfo(u);
            });

            _(this._assessmentsSummary)
                .filter(i => i.isSelected)
                .forEach(i => (i.isSelected = false) || true);
            this.refreshAssetsList();
        } finally {
            busyRef.hide();
        }
    }

    async onHideNotReportedAssetsChange(): Promise<void> {
        this.refreshAssetsList();
    }

    async createAssetsNotMatchedFromWorkPapers(): Promise<void> {
        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
        const model: Compliance.AllocationDetailCreateAssetsNotMatchedFromWorkPapersModel = {
            selectedRows: selectedRowsModel.selectedRows,
            selectAllRows: selectedRowsModel.selectAllRows,
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        };

        const busyRef = this._busyIndicatorService.show({ message: 'Creating Assets' });
        try {
            await lastValueFrom(this._allocationDetailRepository.createAssetsNotMatchedFromWorkPapers(this.allocationId, model));
        } finally {
            busyRef.hide();
        }

        this.refreshAssetsList();
    }

    async matchToExistingNonAllocatedAssets(): Promise<void> {
        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
        const model: Compliance.AllocationDetailCreateAssetsNotMatchedFromWorkPapersModel = {
            selectedRows: selectedRowsModel.selectedRows,
            selectAllRows: selectedRowsModel.selectAllRows,
            lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
        };

        const busyRef = this._busyIndicatorService.show({ message: 'Matching to existing non-allocated Assets' });
        try {
            await lastValueFrom(this._allocationDetailRepository.matchToExistingNonAllocatedAssets(this.allocationId, model));
        } finally {
            busyRef.hide();
        }

        this.refreshAssetsList();
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.allocation) {
            return null;
        }

        return {
            entityType: 'Allocation',
            entityName: this.allocation.description,
            entityData: {
                typeId: Core.EntityTypes.Allocation,
                id: this.allocation.allocationId,
                name: this.allocation.description
            },
            singleYear: true,
            year: this.allocation.taxYear,
            disableYears: true,
            readOnly: !this.canEditCompany
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.allocation) {
            return null;
        }

        return {
            entityID: this.allocation.allocationId,
            entityTypeID: Core.EntityTypes.Allocation,
            description: this.allocation.description,
            year: this.allocation.taxYear.toString(),
            canEdit: this.canEditCompany,
            parcelAcctNum: undefined,
            parcelID: undefined
        } as CommentModalData;
    }

    get allocationDescription(): string {
        let result = this.allocation.description;

        if (result.length > 50) {
            result = `${result.substring(0, 47)  }...`;
        }

        return result;
    }

    get allAssessmentActionsDisabled(): boolean {
        return this.assessmentActions.every(x => x.disabled);
    }

    async onActionSelected(action): Promise<void> {
        switch (action.value as AssessmentActions) {
            case AssessmentActions.LockAllocation:
                await this.lockAllocation();
                break;
            case AssessmentActions.ReleaseLockedAllocation:
                await this.releaseLockedAllocation();
                break;
            case AssessmentActions.DistributeVariance:
                await this.distributeVariance();
                break;
            case AssessmentActions.CreateNewMergedParcels:
                await this.createNewParcelsFromAssessmentSummary();
                break;
            default:
                throw Error(`Unknown action: [${action.value}]`);
        }

        this.selectedAction = null;
    }

    async createNewParcelsFromDetails(): Promise<void> {
        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
        const params: CreateNewMergedParcelsParams = {
            allocationId: this.allocationId,
            allocationDetailSelectRowsModel: {
                selectedRows: selectedRowsModel.selectedRows,
                selectAllRows: selectedRowsModel.selectAllRows
            }
        };

        await this.createNewParcels(params);
    }

    async createNewParcelsFromAssessmentSummary(): Promise<void> {
        const selectedAllocationAssessmentSummaryIds = this._assessmentsSummary
                                                           .filter(i => i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom && i.isSelected)
                                                           .map(i => i.allocationAnnualAssessmentId);
        const params: CreateNewMergedParcelsParams = {
            allocationId: this.allocationId,
            selectedAssessmentSummaryIds: selectedAllocationAssessmentSummaryIds
        };

        await this.createNewParcels(params);
    }

    async createNewParcels(params: CreateNewMergedParcelsParams): Promise<void> {
        const result = await this._modalService.showAsync(CreateNewMergedParcelsComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        _.forEach(result.assessmentsSummary, u => {
            const existingSummaryIndex = _.findIndex(this._assessmentsSummary,
                e => e.allocationAnnualAssessmentId === u.allocationAnnualAssessmentId);
            this._assessmentsSummary[existingSummaryIndex] = new AssessmentSummaryInfo(u);
        });

        this.refreshAssetsList();

        return Promise.resolve();
    }

    get canCreateMergedParcels(): boolean {
        return !!_.find(this._assessmentsSummary,
            i => i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom && i.isSelected);
    }

    async retrieveAssets(): Promise<void> {
        const params: RetrieveAssetsParams = {
            allocationId: this.allocationId
        };

        const result = await this._modalService.showAsync(RetrieveAssetsComponent, params, 'modal-xl');

        if (!result) {
            return Promise.resolve();
        }

        this.refresh();

        return Promise.resolve();
    }

    async markAllocationReviewed(): Promise<void> {
            const model = {
                lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp
            } as Compliance.SelectAllocationAssessmentModel;

            model.selectedRows = _(this._assessmentsSummary)
                .filter(i => i.isLocked && (this.isChecked() || i.isSelected))
                .map(i => i.allocationAnnualAssessmentId)
                .value();

        const busyRef = this._busyIndicatorService.show({ message: 'Marking Allocation Reviewed' });

        let result: Compliance.AllocationAssessmentUpdateResultModel;

        try {
            result = await lastValueFrom(this._allocationRepository.markAllocationReviewed(this.allocationId, model));

            _.forEach(result.assessmentsSummary, u => {
                const existingSummaryIndex = _.findIndex(this._assessmentsSummary,
                    e => e.allocationAnnualAssessmentId === u.allocationAnnualAssessmentId);
                this._assessmentsSummary[existingSummaryIndex] = new AssessmentSummaryInfo(u);
            });

            this.assessmentGridTracker.clear();
            this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);
        } finally {
            if (!result) {
                _.forEach(model.selectedRows, x => {
                    const assessmentSummary = _.find(this._assessmentsSummary,
                        e => e.allocationAnnualAssessmentId === x);
                    assessmentSummary.isLocked = false;
                });
            }

            busyRef.hide();
        }

        if (result && result.assessmentsSummary.length) {
            try {
                const options: MessageModalOptions = {
                    displayMode: MessageModalDisplayMode.SingleMessage,
                    buttons: MessageModalButtons.OK,
                    focusButton: MessageModalResult.OK,
                    message: 'Selected Locked Allocations Reviewed',
                    title: 'Mark Allocation Reviewed'
                };

                await this._messageModalService.open(options);
            } catch (e) {
                return Promise.resolve();
            }
        }
    }

    private _setAssessmentGridColumns(): void {
        if (!this._assessmentGridApi) { return; }
        this._dragging = false;
        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'allocationAnnualAssessmentId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.assessmentGridTracker,
                    updateAllRowValues: (params, checked) => {
                        this._assessmentsSummary.forEach(x => x.isSelected = checked);
                        this._updateAssessmentActions();
                    }
                } as AgGridMultiSelectHeaderRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.assessmentGridTracker,
                    updateRowValue: (params, checked) => {
                        const assessment = params.data as AssessmentSummaryInfo;
                        assessment.isSelected = checked;
                        this._updateAssessmentActions();
                    },
                    isCellRendererHiddenFn: (rowId, data) => {
                        const summary = data as AssessmentSummaryInfo;
                        return summary && summary.isTaxAuthority;
                    }
                } as AgGridMultiSelectCellRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'Assessment',
                field: 'title',
                width: AgGridColumns.textColumnLargeWidth,
                lockVisible: true,
                lockPosition: true,
                pinned: 'left',
                lockPinned: true,
                suppressMovable: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    newWindow: true,
                    isHelpDisabled: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const summary = params.data as AssessmentSummaryInfo;
                        if (summary && !summary.isTaxAuthority) {
                            return this._routerService.getHrefFromState('parcel', {
                                companyId: summary.companyId,
                                siteId: summary.siteId,
                                parcelId: summary.parcelId,
                                annualYearID: summary.annualYearId,
                                annualAssessmentID: summary.annualAssessmentId
                            });
                        }

                        return null;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const summary = params.data as AssessmentSummaryInfo;
                        return summary.isTaxAuthority ? summary.taxAuthorityName : summary.title;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const summary = params.data as AssessmentSummaryInfo;
                        return !(summary && summary.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal
                            && !summary.isTaxAuthority);
                    },
                    getStyle: (params: AgGridLinkCellRendererParams) => {
                        const assessmentSummary = params.data as AssessmentSummaryInfo;
                        return assessmentSummary && assessmentSummary.taxAuthorityName
                            ? {'width': '100%', 'text-align': 'right'}
                            : null;
                    }
                } as AgGridLinkCellRendererParams,
                valueFormatter: (x) => {
                    const assessmentSummary = x.data as AssessmentSummaryInfo;
                    return assessmentSummary.taxAuthorityName || assessmentSummary.title;
                }
            },
            {
                headerName: 'FMV',
                field: 'FMV',
                cellClass: 'ag-numeric-cell',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (x) => {
                    const assessmentSummary = x.data as AssessmentSummaryInfo;
                    return !assessmentSummary.taxAuthorityName ? this._decimalPipe.transform(x.value, '1.2-2') : '';
                },
            },
            {
                headerName: 'Alloc',
                field: 'allocationAmount',
                cellClass: 'ag-numeric-cell',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
            },
            {
                headerName: 'Count',
                field: 'assetsCount',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Var',
                field: 'variance',
                cellClass: 'ag-numeric-cell',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (x) => {
                    const assessmentSummary = x.data as AssessmentSummaryInfo;
                    return !assessmentSummary.taxAuthorityName ? this._decimalPipe.transform(x.value, '1.2-2') : '';
                },
            },
            {
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(3),
                minWidth: AgGridColumns.getActionColumnWidth(3),
                maxWidth: AgGridColumns.getActionColumnWidth(3),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                pinned: 'right',
                headerComponentFramework: AgGridAssessmentSummaryActionHeaderRendererComponent,
                headerComponentParams: {
                    showLockedAssessmentsChange: (show: boolean) => {
                        this.hideLockedAssessments = !show;
                        this._refreshAssessmentsSummary();
                    },
                    clearFilter: this.clearAssessmentFilter.bind(this)
                } as ICellRendererParamsForAssessmentSummaryHeaderRenderer,
                cellRendererFramework: AgGridAssessmentSummaryActionCellRendererComponent,
                cellRendererParams: {
                    deleteAssessmentClicked: this.onDeletTaxAuthorityClick.bind(this),
                    lockReleaseLockedAssessmentClicked: this.onLockReleaseLockedAssessmentClick.bind(this),
                    filterClicked: this.onAllocationSummaryFilterClick.bind(this),
                    clearFilterClicked: this.clearAssessmentFilter.bind(this)
                } as ICellRendererParamsForAssessmentSummaryCellRenderer
            }
        ];
        this._assessmentGridApi.setColumnDefs(columns);
        this._assessmentGridApi.sizeColumnsToFit();
    }

    private _setAssessmentDraggingColumns(): void {
        this._dragging = true;
        const dropZoneColumn = {
            headerName: 'Assessment',
            field: 'allocationAnnualAssessmentId',
            lockVisible: true,
            lockPosition: true,
            suppressMovable: true,
            cellRendererFramework: AllocationDetailDragAndDropCellRendererComponent,
            cellRendererParams: {
                allocationDetailsDropped: this.onAllocationDetailsDropped.bind(this)
            } as DragAndDropCellRendererParams,
        };
        this._assessmentGridApi.setColumnDefs([dropZoneColumn]);
        this._assessmentGridApi.sizeColumnsToFit();
        this._assessmentGridApi.redrawRows();
    }

    private _setGridColumns(): void {
        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'allocationDetailId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                cellRendererFramework: AllocationDetailDragAndDropSourceComponent,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as ICellRendererParamsForAllocationDetailDragAndDropSource
            },
            {
                headerName: '',
                field: 'allocationDetailId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'Asset Number',
                field: 'assetNumber',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                lockPosition: true,
                pinned: 'left',
                lockPinned: true,
                suppressMovable: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                toolPanelClass: 'Asset',
                editable: (params) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return this._isRowEditable(params) &&
                        (allocationDetail && !allocationDetail.returnAssetId && !allocationDetail.assetId);
                },
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.assetNumber && !params.data.selectedTotalsRow &&
                            !params.data.totalsRow &&
                            (!allocationDetail.returnAssetId && !allocationDetail.assetId ||
                                !!allocationDetail.parcelSiteId && allocationDetail.parcelSiteId !== this.allocation.siteId)
                        ? ' ws-override'
                        : '';
                },
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    canReset: true,
                    hasOverride: (params) => {
                        const allocationDetail = params.data as AllocationDetailGridRowModel;
                        return allocationDetail && allocationDetail.assetNumber &&
                            !allocationDetail.returnAssetId && !allocationDetail.assetId;
                    }
                },
                onCellValueChanged: this._onValueChanged.bind(this)
            },
            {
                headerName: 'Description',
                field: 'description',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Description (WP)',
                field: 'workPaperDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Company Code',
                field: 'companyCode',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'G/L Account',
                field: 'glAccount',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'G/L Account Number',
                field: 'glAccountNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Change Status',
                field: 'assetReturnStatus',
                cellRendererFramework: ReturnAssetChangeIconCellRendererComponent,
                width: 120,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                sortable: false,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Split Allocation %',
                field: 'splitAllocationPercentage',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => params.value ? this._decimalPipe.transform(params.value, '1.2-2') : null,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Classification',
                field: 'classificationName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Parcel Address (Complete)',
                field: 'parcelAddressComplete',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Linked/Sub Parcel',
                field: 'linkedParcelAccountNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: false,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.parcelIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Parcel',
                field: 'parcel',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.parcelIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Parcel Desc.',
                field: 'parcelDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.parcelIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Linked/Sub Parcel Desc.',
                field: 'linkedParcelDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Assessor',
                field: 'assessorAbbr',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            }, {
                headerName: 'Assessor (WP)',
                field: 'workPaperAssessor',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Linked/Sub Assessor',
                field: 'linkedParcelAssessorAbbr',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Prior Acq. Date',
                field: 'priorAcqDate',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                toolPanelClass: 'Asset'
            } as ColDef,
            {
                headerName: 'Acq. Date',
                field: 'acqDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Acq. Date (WP)',
                field: 'workPaperAcqDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Acq. Year (WP)',
                field: 'workPaperAcqYear',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Prior Cost',
                field: 'priorCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Asset'
            } as ColDef,
            {
                headerName: 'Cost (Reported)',
                field: 'reportedCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Cost (WP)',
                field: 'workPaperCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Cost Change',
                field: 'costChange',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Asset'
            } as ColDef,
            {
                headerName: 'Return Value',
                field: 'returnValue',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return !allocationDetail
                        ? null
                        : allocationDetail.returnValue || allocationDetail.returnValue === 0
                            ? this._decimalPipe.transform(allocationDetail.returnValue, '1.2-2')
                            : 'N/A';
                },
                cellClass: 'ag-numeric-cell',
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Init Alloc Value',
                field: 'initialAllocationAmount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    let additionalClass = '';
                    if (allocationDetail) {
                        if (allocationDetail.initialAllocationAmountOverridden) {
                            additionalClass = ' ws-override';
                        } else if (allocationDetail.initialAllocationAmountCalculated) {
                            additionalClass = ' ws-calculated';
                        }
                    }
                    return `ag-numeric-cell${additionalClass}`;
                },
                editable: (params) => this._isRowEditable(params),
                cellEditorFramework: AgGridNumberCellEditor,
                cellEditorParams: {
                    canReset: true,
                    hasOverride: (params) => {
                        const allocationDetail = params.data as AllocationDetailGridRowModel;
                        return allocationDetail && allocationDetail.initialAllocationAmountOverridden;
                    }
                },
                toolPanelClass: 'Work Papers',
                onCellValueChanged: this._onValueChanged.bind(this),
            } as ColDef,
            {
                headerName: 'Final Alloc Value',
                field: 'finalAllocationAmount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return `ag-numeric-cell${  allocationDetail && allocationDetail.finalAllocationAmountOverridden ? ' ws-override' : ''}`;
                },
                editable: (params) => this._isRowEditable(params),
                cellEditorFramework: AgGridNumberCellEditor,
                cellEditorParams: {
                    canReset: true,
                    hasOverride: (params) => {
                        const allocationDetail = params.data as AllocationDetailGridRowModel;
                        return allocationDetail && allocationDetail.finalAllocationAmountOverridden;
                    }
                },
                toolPanelClass: 'Work Papers',
                onCellValueChanged: this._onValueChanged.bind(this),
            },
            {
                headerName: 'Val Variance ($)',
                field: 'varianceDollar',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Val Variance (%)',
                field: 'variancePercentage',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => (params.value || params.value === 0)
                    ? this._decimalPipe.transform(params.value * 100, '1.2-2') : '',
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'PY Avg ETR (%)',
                field: 'priorYearEffectiveTaxRate',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-6'),
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Tax Variance ($)',
                field: 'taxVarianceDollar',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'PY Value',
                field: 'priorYearFinalAllocationAmount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Prior Yr Factor',
                field: 'priorYearFactor',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Net Book Value',
                field: 'netBookValue',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => x.value || x.value === 0 ? this._decimalPipe.transform(x.value, '1.2-2') : null,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Site',
                field: 'site',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.siteIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.siteIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Site Name',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset',
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.siteIsDifferent ? ' ws-override' : '';
                }
            },
            {
                headerName: 'Prior Schedule',
                field: 'priorScheduleName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                toolPanelClass: 'Asset'
            } as ColDef,
            {
                headerName: 'Schedule',
                field: 'scheduleName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Schedule (WP)',
                field: 'workPaperSchedule',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Prior Factor Table',
                field: 'priorDepreciationFactorTableName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.priorDepreciationFactorTableName) ? `${rowData.priorDepreciationFactorTableName} (${rowData.priorDepreciationFactorTableLife} year life)` : '';
                },
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Factor Table',
                field: 'depreciationFactorTableName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return (rowData && rowData.depreciationFactorTableName)
                        ? `${rowData.depreciationFactorTableName} (${rowData.depreciationFactorTableLife} year life)` : '';
                },
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Factor',
                field: 'factor',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Factor (WP)',
                field: 'workPaperFactor',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                colId: 'calculatedParcel',
                headerName: 'Parcel (WP)',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail && allocationDetail.isWorkPaperParcelOverridden ? ' ws-override' : '';
                },
                editable: (params) => this._isRowEditable(params),
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => {
                        const items = _(this._assessmentsSummary)
                            .orderBy(x => x.sortByName)
                            .filter(i => {
                                return !i.isTaxAuthority && !i.isLocked ||
                                    i.isTaxAuthority && !this._assessmentsSummary.find(x => x.annualAssessmentId === i.annualAssessmentId && !x.isTaxAuthority).isLocked;})
                            .map(i => {
                                return {
                                    name: !i.isTaxAuthority ? i.title : `\xA0\xA0${i.taxAuthorityName}`,
                                    value: i.allocationAnnualAssessmentId
                                };
                            })
                            .value();

                        items.splice(0, 0, { name: '', value: 0 });

                        return items;
                    },
                    cellFocusLost: (params, updatedValue) => {
                        const allocationDetail = params.data as AllocationDetailGridRowModel;
                        let summaryModel = {} as AssessmentSummaryInfo;

                        if (!updatedValue) {
                            summaryModel = new AssessmentSummaryInfo({
                                summaryType: Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned,
                                parcelAccountNumber: null
                            } as Compliance.AllocationAssessmentSummaryModel);
                        } else {
                            const existingAllocationSummary = _.find(this._assessmentsSummary,
                                i => i.allocationAnnualAssessmentId === +updatedValue);
                            if (existingAllocationSummary) {
                                summaryModel = existingAllocationSummary;
                            }
                        }

                        this.onAllocationDetailsDropped([allocationDetail.allocationDetailId], summaryModel);
                    }
                },
                valueFormatter: (params) => {
                    const allocationDetail = params.data as AllocationDetailGridRowModel;
                    return allocationDetail ? allocationDetail.calculatedParcel : null;
                },
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Status',
                field: 'statusId',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (params) => this._isRowEditable(params),
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => _.map(this._statuses, i => {
                        return {
                            name: i.name,
                            value: i.allocationDetailStatusId
                        };
                    }),
                },
                valueFormatter: (params) => {
                    const gridRow = params.data as AllocationDetailGridRowModel;
                    const status = gridRow ? this._statuses.find(i => i.allocationDetailStatusId === +gridRow.statusId) : null;
                    return status ? status.name : '';
                },
                onCellValueChanged: this._onValueChanged.bind(this),
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Reconciled',
                field: 'isReconciled',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    isVisible: (params) => params.data && !params.data.selectedTotalsRow && !params.data.totalsRow,
                    canEdit: (params) => this._canChangeReconciliationStatus(params),
                    canEnterEditMode: () => true,
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForAgGridCheckbox,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Add\'l Dep.',
                field: 'additionalDepreciation',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Add\'l Dep. (WP)',
                field: 'workPaperAddlDep',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value * 100, '1.2-5')}%` : '',
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Est. FMV',
                field: 'estimatedFMV',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    return !rowData
                        ? null
                        : x.value || x.value === 0 || rowData.estimatedFMVOverridden
                            ? this._decimalPipe.transform(x.value, '1.2-2')
                            : 'N/A';
                },
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Acq. Year',
                field: 'acqYear',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Age',
                field: 'age',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Location Name',
                field: 'leaseLocationName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Location Address',
                field: 'leaseAddress',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Location City',
                field: 'leaseCity',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Location State',
                field: 'leaseState',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Location Zip',
                field: 'leaseZip',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Start Date',
                field: 'leaseStartDate',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease End Date',
                field: 'leaseEndDate',
                width: AgGridColumns.dateColumnWidth,
                hide: true,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Term In Months',
                field: 'leaseTermInMonths',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Monthly Payment',
                field: 'leaseMonthlyPayment',
                filter: 'agNumberColumnFilter',
                hide: true,
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Type',
                field: 'leaseTypeId',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Asset ID',
                field: 'leaseAssetId',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Lease Number',
                field: 'leaseNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Billing Name',
                field: 'leaseBillingName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Billing Address',
                field: 'leaseBillingAddress',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Billing City',
                field: 'leaseBillingCity',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Billing State',
                field: 'leaseBillingState',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Billing Zip',
                field: 'leaseBillingZip',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Customer Id',
                field: 'leaseClientId',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Leasing'
            },
            {
                headerName: 'Jan Balance',
                field: 'inventoryJan',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Feb Balance',
                field: 'inventoryFeb',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Mar Balance',
                field: 'inventoryMar',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Apr Balance',
                field: 'inventoryApr',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'May Balance',
                field: 'inventoryMay',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Jun Balance',
                field: 'inventoryJun',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Jul Balance',
                field: 'inventoryJul',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Aug Balance',
                field: 'inventoryAug',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Sep Balance',
                field: 'inventorySep',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Oct Balance',
                field: 'inventoryOct',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Nov Balance',
                field: 'inventoryNov',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Dec Balance',
                field: 'inventoryDec',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Avg Balance',
                field: 'inventoryAvrCalculated',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2'),
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Aggregated Balance',
                field: 'inventoryAggregatedString',
                width: AgGridColumns.textColumnLargeWidth,
                sortable: false,
                cellClass: 'ag-numeric-cell',
                hide: true,
                toolPanelClass: 'Inventory'
            },
            {
                headerName: 'Reportable',
                field: 'isReportable',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                cellClass: 'ag-text-cell',
                valueFormatter: x => x.value ? 'Yes' : 'No',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Taxable',
                field: 'isTaxable',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.booleanFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
                cellClass: 'ag-text-cell',
                valueFormatter: x => x.value ? 'Yes' : 'No',
                hide: true,
                toolPanelClass: 'Asset'
            },
            {
                headerName: 'Taxing Unit (WP)',
                field: 'workPaperTaxingUnit',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Physical Address (WP)',
                field: 'workPaperPhysicalAddress',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Lessee Name (WP)',
                field: 'workPaperLesseeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Note (WP)',
                field: 'workPaperNote',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc',
                field: 'workPaperMisc',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc1 (WP)',
                field: 'workPaperMisc1',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc2 (WP)',
                field: 'workPaperMisc2',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc3 (WP)',
                field: 'workPaperMisc3',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc4 (WP)',
                field: 'workPaperMisc4',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            },
            {
                headerName: 'Misc5 (WP)',
                field: 'workPaperMisc5',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true,
                toolPanelClass: 'Work Papers'
            }
        ];

        const descriptorColumns = this._returnService.getCompanyAssetDescriptorMappingsAsColDefs(this._companyAssetDescriptorMappings);

        const actionColumn: ColDef = {
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(1),
            minWidth: AgGridColumns.getActionColumnWidth(1),
            maxWidth: AgGridColumns.getActionColumnWidth(1),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            sortable: false,
            pinned: 'right',
            cellRendererFramework: AllocationDetailListGridActionCellRendererComponent,
            cellRendererParams: {
                canView: this._canViewAssetDetails.bind(this),
                viewDetails: this._viewAssetDetails.bind(this)
            } as ICellRendererParamsForAllocationDetailListGridAction,
            pinnedRowCellRenderer: () => {return '';}
        };

        this._gridApi.setColumnDefs([...columns, ...descriptorColumns, actionColumn]);
    }

    private _canViewAssetDetails(params: ICellRendererParamsForAllocationDetailListGridAction): boolean {
        const allocationDetail = params.data as AllocationDetailGridRowModel;
        return !!allocationDetail && !!allocationDetail.calculatedAssetId;
    }

    private async _viewAssetDetails(params: ICellRendererParamsForAllocationDetailListGridAction): Promise<void> {
        const allocationDetail = params.data as AllocationDetailGridRowModel;
        if (!allocationDetail) {
            return Promise.resolve();
        }

        const inputParams: AssetDetailsParams = {
            reportingAssetId: allocationDetail.calculatedReportingAssetId,
            assetId: allocationDetail.calculatedAssetId,
            lienDate: this.allocation.lienDate,
            canEdit: this._returnService.canEditCompany && false,
            createNewAsset: false,
            topLevelCompanyId: this.companyId,
            assetDescriptors: null,
            newAssetCompanyId: null,
            canViewRyanPrivateItems: this._returnService.canViewRyanPrivateItems,
            fromLockedReturn: false,
            fromLockedAllocation: true
        };

        await this._modalService.showAsync(AssetDetailsComponent, inputParams, 'modal-xl');
    }

    private _isRowEditable(params: IsColumnFuncParams): boolean {
        const allocationDetailGridModel = params.data as AllocationDetailGridRowModel;

        return !params.data.selectedTotalsRow && !params.data.totalsRow && allocationDetailGridModel && !allocationDetailGridModel.isReconciled;
    }

    private _canChangeReconciliationStatus(params: ICellRendererParamsForAgGridCheckbox): boolean {
        const allocationDetailGridModel = params.data as AllocationDetailGridRowModel;

        let result = !params.data.selectedTotalsRow && !params.data.totalsRow && !!allocationDetailGridModel;

        if (result) {
            if (allocationDetailGridModel.isReconciled) {
                _.forEach(this._assessmentsSummary,
                    i => {
                        const lockedAssessmentSummary = _.find(
                            allocationDetailGridModel.assignedAllocationAssessmentIds,
                            j =>
                                i.allocationAnnualAssessmentId === j && i.isLocked);

                        if (lockedAssessmentSummary) {
                            result = false;
                            return true;
                        }
                    });
            } else {
                result = allocationDetailGridModel.canBeReconciled;
            }
        }

        return result;
    }

    private async _onValueChanged(params: CellValueChangedEvent): Promise<void> {
        if (params.newValue === params.oldValue) {
            return;
        }

        const newValue = params.newValue;
        const allocationDetailGridModel = params.data as AllocationDetailGridRowModel;
        let reloadData: boolean;
        let reloadTotals: boolean;
        const updateModel = {
            rowVersion: allocationDetailGridModel.rowVersion
        } as Compliance.AllocationDetailUpdateModel;

        switch (params.colDef.field) {
            case 'initialAllocationAmount':
                updateModel.initialAllocationAmount = newValue;
                updateModel.updatedField = Compliance.AllocationDetailPropertyEnum.InitialAllocationAmount;
                reloadTotals = true;
                break;
            case 'finalAllocationAmount':
                updateModel.finalAllocationAmount = newValue;
                updateModel.updatedField = Compliance.AllocationDetailPropertyEnum.FinalAllocationAmount;
                reloadTotals = true;
                break;
            case 'statusId':
                updateModel.statusId = +newValue;
                updateModel.updatedField = Compliance.AllocationDetailPropertyEnum.AllocationDetailStatusId;
                reloadTotals = false;
                break;
            case 'isReconciled':
                updateModel.isReconciled = newValue;
                updateModel.updatedField = Compliance.AllocationDetailPropertyEnum.IsReconciled;
                reloadTotals = false;
                break;
            case 'assetNumber':
                updateModel.assetNumber = newValue;
                updateModel.updatedField = Compliance.AllocationDetailPropertyEnum.AssetNumber;
                break;
        }

        reloadData = this._gridDataSource.reloadDataAfterUpdate(params.colDef.field);
        const busyRef = this._busyIndicatorService.show({ message: 'Updating Value' });
        try {
            const updateResult = await lastValueFrom(this._allocationDetailRepository.update(this.allocationId, allocationDetailGridModel.allocationDetailId, updateModel));

            if (!reloadData) {
                const updatedGridRow = new AllocationDetailGridRowModel(updateResult.updatedAllocationDetailModel);
                params.node.setData(updatedGridRow);
                this._gridApi.redrawRows({ rowNodes: [params.node] });

                if (updateResult.affectedAssessmentSummaries.length !== 0) {
                    const updatedTotalSummary = _.find(updateResult.affectedAssessmentSummaries, i =>
                        i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total);

                    if (updatedTotalSummary) {
                        this._totalAssessmentsSummary = new AssessmentSummaryInfo(updatedTotalSummary);
                    }

                    const updatedUnassignedSummary = _.find(updateResult.affectedAssessmentSummaries, i =>
                        i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned);

                    if (updatedTotalSummary) {
                        this._unassignedAssessmentsSummary = new AssessmentSummaryInfo(updatedUnassignedSummary);
                    }

                    _(updateResult.affectedAssessmentSummaries)
                        .filter(i => i.summaryType !== Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned &&
                                     i.summaryType !== Compliance.AllocationAssessmentSummaryTypeEnum.Total)
                        .each(i => {
                            const updatedSummary = new AssessmentSummaryInfo(i);
                            const existingSummaryIndex = this._assessmentsSummary.findIndex(j => AssessmentSummaryInfo.isEqual(j, updatedSummary));
                            this._assessmentsSummary[existingSummaryIndex] = updatedSummary;
                        });

                    this._setAssessmentsSummary([ ...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary ]);
                }
            }
        } finally {
            busyRef.hide();
        }

        if (reloadData) {
            this.refreshAssetsList();
        } else if (reloadTotals) {
            await this._reloadTotals();
        }

        return Promise.resolve();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.hasSelectedRows = false;
        this._allocationDetailSelectionInfo = {
            hasNotMatchedAssets: false,
            hasNewParcels: false
        };

        const dataSourceParams = (): AllocationDetailsListDataSourceParams => {
            return {
                allocationId: this.allocationId,
                hideNotReportedAssets: this.hideNotReportedAssets,
                managementReviewOption: this.managementReviewSelectedOption
            };
        };

        this._gridDataSource = new AllocationDetailsListAgGridDataSource(
            this._gridApi,
            this._allocationDetailRepository,
            this._allocationDetailService,
            dataSourceParams,
            this.handleTotalsUpdate
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _getAssessmentGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._assessmentGridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const assessment = x.data as AssessmentSummaryInfo;
                return assessment && assessment.allocationAnnualAssessmentId;
            })
        } as Compliance.QueryResultModel<number>;
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        this.selectedTotalsLoading = true;

        try {
            const totals:[Compliance.AllocationDetailTotalsModel, Compliance.AllocationDetailTotalsModel] =
                await Promise.all([this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel()),
                    this._gridDataSource.getSelectedRowTotals(null)]);
            // const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            this.selectedTotalsLoading = false;
            this.handleTotalsUpdate(totals[0], true);
            this.handleTotalsUpdate(totals[1], false);
        } finally {
            this.selectedTotalsLoading = false;
        }
    }

    refreshAssetsList() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.clearTotals();
        this._gridDataSource.refresh();
    }

    private async _getDraggedAllocationDetailIds(): Promise<number[]> {
        const busyRef = this._busyIndicatorService.show({ message: 'Working on it' });
        try {
            return await this.gridTracker.getSelectedRowIds();
        } finally {
            busyRef.hide();
        }
    }

    private _convertToAssessmentSummaryInfo(assessmentsSummary: Compliance.AllocationAssessmentSummaryModel[]): AssessmentSummaryInfo[] {
        return _.map(assessmentsSummary, i => new AssessmentSummaryInfo(i));
    }

    private _setAssessmentsSummary(assessmentsSummary: AssessmentSummaryInfo[]) {
        this._assessmentsSummary = _.flow([
            filter(i => i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal ||
                         i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom),
            sortBy(x => x.sortByName)
        ])(assessmentsSummary);

        this._totalAssessmentsSummary = _.find(assessmentsSummary, i => i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total);
        this._unassignedAssessmentsSummary = _.find(assessmentsSummary, i => i.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned);

        this.filterAssessmentsSummary(this.assessmentsSummaryFilter);

        if (this._assessmentGridApi) {
            this._assessmentGridApi.setRowData(this._assessmentsSummary);
            this._assessmentGridApi.setPinnedBottomRowData([this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);
        }
    }

    private async _lockAssessments(model: Compliance.SelectAllocationAssessmentModel): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Locking Assessment' });

        let result: Compliance.LockAllocationAssessmentResultModel;

        try {
            result = await lastValueFrom(this._allocationRepository.lockAllocationAssessment(this.allocationId, model));

            _.forEach(result.assessmentsSummary, u => {
                const existingSummaryIndex = _.findIndex(this._assessmentsSummary,
                    e => e.allocationAnnualAssessmentId === u.allocationAnnualAssessmentId);
                this._assessmentsSummary[existingSummaryIndex] = new AssessmentSummaryInfo(u);
            });

            this.assessmentGridTracker.clear();
            this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);

            if (result.reloadAllocationDetails) {
                this.refreshAssetsList();
            }
        } finally {
            if (!result) {
                _.forEach(model.selectedRows, x => {
                    const assessmentSummary = _.find(this._assessmentsSummary,
                        e => e.allocationAnnualAssessmentId === x);
                    assessmentSummary.isLocked = false;
                });
            }

            busyRef.hide();
        }

        if (result && result.assetsInAnotherAllocations && result.assetsInAnotherAllocations.length) {
            const params: AssetsInAnotherAllocationsParams = {
                assets: result.assetsInAnotherAllocations
            };

            await this._modalService.showAsync(AssetsInAnotherAllocationsComponent, params, 'modal-xl');
        }
    }

    private async _releaseLockedAssessments(model: Compliance.SelectAllocationAssessmentModel): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Un-Locking Assessment' });

        try {
            const result = await lastValueFrom(this._allocationRepository.releaseLockedAllocationAssessment(this.allocationId, model));

            _.forEach(result.assessmentsSummary, u => {
                const existingSummaryIndex = _.findIndex(this._assessmentsSummary,
                    e => e.allocationAnnualAssessmentId === u.allocationAnnualAssessmentId);
                this._assessmentsSummary[existingSummaryIndex] = new AssessmentSummaryInfo(u);
            });

            this.assessmentGridTracker.clear();
            this._setAssessmentsSummary([...this._assessmentsSummary, this._totalAssessmentsSummary, this._unassignedAssessmentsSummary]);

            if (result.reloadAllocationDetails) {
                this.refreshAssetsList();
            }
        } finally {
            busyRef.hide();
        }
    }

    private _updateAssessmentActions(): void {
        // Lock Allocation
        this.assessmentActions[0].disabled = !this.hasReadyToLockAllocations;
        // Release Locked Allocation
        this.assessmentActions[1].disabled = !this.hasLockedAssessments;
        // Distribute Variance
        this.assessmentActions[2].disabled = !this.canDistributeVariance;
        // Create new parcels and assessment for unallocated
        this.assessmentActions[3].disabled = !this.canCreateMergedParcels;
        // Copy list to trigger change detection
        this.assessmentActions = [...this.assessmentActions];
    }

    private async _refreshAssessmentsSummary() {
        const busyRef = this._busyIndicatorService.show({ message: 'Filtering...' });
        try {
            const model = {
                hideLockedAssessment: this.hideLockedAssessments,
                filter: this.assessmentsSummaryFilter
            } as Compliance.AllocationAssessmentSummarySearchModel;
            const assessmentsSummary = await lastValueFrom(this._allocationRepository.getAssessmentsSummary(this.allocationId, model));
            this._setAssessmentsSummary(this._convertToAssessmentSummaryInfo(assessmentsSummary));
            this.refreshAssetsList();
        } finally {
            busyRef.hide();
        }
    }
}
