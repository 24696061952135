import { Component, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../AgGrid/MultiSelectTracker';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../AgGrid';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { AssessmentSummaryInfo } from '../assessmentSummaryInfo';

export interface SelectTaxAuthoritiesParams{
    allocationId: number,
    selectedAllocationDetailIds: number[],
    lastModifiedTimestamp: Date,
    taxAuthorities: AssessmentSummaryInfo[]
}

export interface SelectTaxAuthoritiesResult {
    selectedAssessmentSummaries: number[]
}

@Component({
    selector: 'select-tax-authority',
    templateUrl: './selectTaxAuthority.component.html'
})
export class SelectTaxAuthorityComponent implements IWeissmanModalComponent<SelectTaxAuthoritiesParams, SelectTaxAuthoritiesResult>, OnDestroy {
    constructor(private readonly _modalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _selectedRows: number[] = [];

    params: SelectTaxAuthoritiesParams;
    result: SelectTaxAuthoritiesResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged()
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withSort()
        .withTextSelection()
        .withFloatingFilter()
        .build();

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe((selected: Compliance.SelectedRowsModel) => {
            this._selectedRows = (selected.selectAllRows) ? this.params.taxAuthorities.map(f => f.allocationAnnualAssessmentId) : selected.selectedRows;
        });

        const columns: ColDef[] = [
            {
                field: 'allocationAnnualAssessmentId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
            },
            {
                headerName: 'Tax Authority',
                field: 'taxAuthorityName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'taxAuthorityName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async save(): Promise<void> {
        this.result = {selectedAssessmentSummaries: this._selectedRows};
        this._modalRef.hide();
    }

    cancel(): void {
        this._modalRef.hide();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const taxAuthority = x.data as AssessmentSummaryInfo;
                return taxAuthority && taxAuthority.allocationAnnualAssessmentId;
            })
        };

        return Promise.resolve(queryResultModel);
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.params.taxAuthorities)) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this.params.taxAuthorities);
    }
}
