export class ExtendedModelBase<T> {
    constructor(model: T) {
        this._model = model;
    }

    private _model: T;

    get model(): T {
        return this._model;
    }
}