import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridPersistenceService } from '../agGridPersistence.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface AgGridTextWithTotalsUpdateCellRendererParams extends ICellRendererParams {
    getTooltipText: (params: AgGridTextWithTotalsUpdateCellRendererParams) => string;
    totalsNeedRefresh: () => boolean;
    onUpdateTotalsClicked: () => void;
}

@Component({
    selector: 'ag-grid-text-with-selected-row-cell-renderer',
    template: `
        <div *ngIf="!isTotalsRow"
             helpTooltip
             [tooltipText]="tooltipText"
             [tooltipDelay]="0"
             [helpDisabled]="!tooltipText">
            <span class="cell-text">{{cellText}}</span>
        </div>
        <div *ngIf="isTotalsRow" class="cell-total">
            <label *ngIf="!totalsNeedRefresh" class="ws-flex-container-horizontal ws-flex-align-items-center" for="autoUpdate">
                <span>TOTAL (</span>
                <input id="autoUpdate" type="checkbox" [ngModel]="autoUpdateChecked" (ngModelChange)="onAutomaticUpdatesClicked($event)">
                <span>Automatic)</span>
            </label>
            <span *ngIf="totalsNeedRefresh" class="btn btn-link btn-sm clickable" (click)="params.onUpdateTotalsClicked()"><i class="fa fa-refresh"></i> Calculate Totals</span>
        </div>
    `,
    styles: [
        '#autoUpdate { margin: 0 3px }',
        'label { font-weight: normal }'
    ]
})
export class AgGridTextWithTotalsUpdateCellRendererComponent implements ICellRendererAngularComp {
    constructor(private _agGridPersistenceService: AgGridPersistenceService) {}

    params: AgGridTextWithTotalsUpdateCellRendererParams;
    tooltipText: string;
    cellText: string;
    isTotalsRow: boolean;
    autoUpdateChecked: boolean;

    private _destroy$: Subject<void> = new Subject();

    get totalsNeedRefresh(): boolean {
        return !this.autoUpdateChecked && this.params.totalsNeedRefresh();
    }

    agInit(params: AgGridTextWithTotalsUpdateCellRendererParams): void {
        this.params = params;
        this.tooltipText = params.getTooltipText(params);
        this.cellText = params.valueFormatted || params.value;
        this.isTotalsRow = this.cellText === 'TOTAL';

        this._agGridPersistenceService.totalsRowAutomaticUpdate$(this.params.context.gridId)
            .pipe(takeUntil(this._destroy$))
            .subscribe(autoUpdate => {
                this.autoUpdateChecked = autoUpdate;
            });
    }

    onAutomaticUpdatesClicked(checked: boolean): void {
        this._agGridPersistenceService.toggleTotalsRowAutomaticUpdate(this.params.context.gridId, checked);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
