import {
    AceUIColorStyle,
    AceUINeutralColorStyle,
    AceUIState,
    IIconDisplay,
    AceUIExtendedColorStyle,
    isString,
    AceUIIcon,
    ButtonState,
    BannerState
} from '@ace/shared';
import { getThemeVariable, RGB } from './themeHelper';

export interface IAceColorModel {
    key: string;
    fgWhite?: boolean;
    icon?: AceUIIcon;
}

export interface IAceColorModelWithKey {
    colorType: AceColorType;
    key: string;
    fgWhite?: boolean;
    icon?: AceUIIcon;
}

export type AceColorType = AceUIColorStyle | ButtonState | BannerState | string;

export const aceColorLookup = new Map<AceColorType, IAceColorModel>([
    [AceUINeutralColorStyle.Neutral050, { key: 'neutral-050' }],
    [AceUINeutralColorStyle.Neutral100, { key: 'neutral-100' }],
    [AceUINeutralColorStyle.Neutral200, { key: 'neutral-200' }],
    [AceUINeutralColorStyle.Neutral300, { key: 'neutral-300' }],
    [AceUINeutralColorStyle.Neutral400, { key: 'neutral-400', fgWhite: true }],
    [AceUINeutralColorStyle.Neutral500, { key: 'neutral-500', fgWhite: true }],
    [AceUINeutralColorStyle.Neutral600, { key: 'neutral-600', fgWhite: true }],
    [AceUINeutralColorStyle.Neutral700, { key: 'neutral-700', fgWhite: true }],
    [AceUINeutralColorStyle.Neutral800, { key: 'neutral-800', fgWhite: true }],
    [AceUINeutralColorStyle.Neutral900, { key: 'neutral-900', fgWhite: true }],
    [AceUINeutralColorStyle.White, { key: 'neutral-white' }],
    [AceUINeutralColorStyle.Black, { key: 'neutral-black', fgWhite: true }],

    [AceUIState.Inactive, { key: 'neutral-300', icon: AceUIIcon.Exclamation }],
    [AceUIState.Primary, { key: 'action', fgWhite: true, icon: AceUIIcon.InfoCircle }],
    [AceUIState.Info, { key: 'info', fgWhite: true, icon: AceUIIcon.InfoCircle }],
    [AceUIState.Secondary, { key: 'info', fgWhite: true, icon: AceUIIcon.InfoCircle }],
    [AceUIState.Success, { key: 'success', fgWhite: true, icon: AceUIIcon.SuccessOutline }],
    [AceUIState.Error, { key: 'danger', fgWhite: true, icon: AceUIIcon.TimesCircle }],

    [AceUIExtendedColorStyle.HoverHighlight, { key: 'hover-highlight' }],
    [AceUIExtendedColorStyle.Highlight, { key: 'highlight' }],

    [ButtonState.Action, { key: 'action', fgWhite: true, icon: AceUIIcon.InfoCircle }],
    [ButtonState.Success, { key: 'success', fgWhite: true, icon: AceUIIcon.Check }],
    [ButtonState.Danger, { key: 'danger', fgWhite: true, icon: AceUIIcon.TimesCircle }],

    [BannerState.Info, { key: 'success', fgWhite: true, icon: AceUIIcon.InfoCircle }],
    [BannerState.Success, { key: 'success', fgWhite: true, icon: AceUIIcon.SuccessOutline }],
    [BannerState.Warning, { key: 'warning', fgWhite: true, icon: AceUIIcon.ExclamationTriangle }],
    [BannerState.Danger, { key: 'danger', fgWhite: true, icon: AceUIIcon.TimesCircle }]
]);

export function getColorModel(colorType: AceColorType): IAceColorModelWithKey | undefined {
    const colorModel = aceColorLookup.get(colorType);
    if (colorModel) {
        return { ...colorModel, colorType };
    }
    return undefined;
}

export function colorStyleToCssClass(prefix: string, colorStyle?: AceColorType, enableForegroud?: boolean): object | undefined {
    const model = aceColorLookup.get(colorStyle);
    if (model) {
        const result = {};
        result[`${prefix}${model.key}`] = true;
        if (enableForegroud) {
            if (model.fgWhite) {
                result['text-white'] = true;
            }
        }
        return result;
    }
    return undefined;
}

export function stateToColorVar(state?: AceColorType): string | undefined {
    if (state) {
        const colorObj = colorStyleToCssClass('', state, false) || {};
        const colorStyle = Object.keys(colorObj);
        if (colorStyle.length > 0) {
            return `var(--ace-${colorStyle[0]})`;
        } else if (isString(state)) {
            return state;
        }
    }
    return undefined;
}

export function stateToColor(state?: AceColorType): string | undefined {
    if (state) {
        const colorObj = colorStyleToCssClass('', state, false) || {};
        const colorStyle = Object.keys(colorObj);
        if (colorStyle.length > 0) {
            return getThemeVariable(colorStyle[0]);
        }
    }
    return undefined;
}

export function forEachAceColor(callback: (colorStyle: IAceColorModel, colorType: AceColorType) => void) {
    [AceUINeutralColorStyle, AceUIExtendedColorStyle, AceUIState].forEach(colorEnum => {
        Object.keys(colorEnum)
            .filter(k => isNaN(Number(k)))
            .forEach((key: string) => {
                const colorType = colorEnum[key];
                if (colorType) {
                    const colorStyle = aceColorLookup.get(colorEnum[key]);
                    if (colorStyle) {
                        callback(colorStyle, colorType);
                    }
                }
            });
    });
}

export function colorStyleToIcon(colorStyle?: AceColorType): IIconDisplay | undefined {
    const model = aceColorLookup.get(colorStyle);
    if (model?.icon) {
        return { icon: model.icon };
    }
    return undefined;
}

export function rgbToHex(rgb) {
    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    function hex(x) {
        return (`0${  parseInt(x, 10).toString(16)}`).slice(-2);
    }
    return `#${  hex(rgb[1])  }${hex(rgb[2])  }${hex(rgb[3])}`;
}

// https://css-tricks.com/converting-color-spaces-in-javascript/
export function hexToHsl(H) {
    // Convert hex to RGB first
    let { r, g, b } = hexToRGB(H);

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let h = 0;
    let s = 0;
    let l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}

/** Convert hex to RGB  */
export function hexToRGB(H) {
    let r: any = 0;
    let g: any = 0;
    let b: any = 0;
    if (H.length === 4) {
        r = `0x${  H[1]  }${H[1]}`;
        g = `0x${  H[2]  }${H[2]}`;
        b = `0x${  H[3]  }${H[3]}`;
    } else if (H.length === 7) {
        r = `0x${  H[1]  }${H[2]}`;
        g = `0x${  H[3]  }${H[4]}`;
        b = `0x${  H[5]  }${H[6]}`;
    }

    return { r, g, b };
}

/** This function will return an equivalent opaque RGB color for a given partially transparent RGB color against a given background.
 *  Default is a white background.
 */
export function rgbOpaqueFromAlpha(color: RGB, alpha: number, backgroundColor: RGB = { r: 255, g: 255, b: 255 }): RGB {
    const r = Math.round(backgroundColor.r - alpha * (backgroundColor.r - color.r));
    const g = Math.round(backgroundColor.g - alpha * (backgroundColor.g - color.g));
    const b = Math.round(backgroundColor.b - alpha * (backgroundColor.b - color.b));

    return { r, g, b };
}
