import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { RolesListComponent } from './roles.list.component';

@NgModule({
    imports: [ WeissmanSharedModule ],
    declarations: [ RolesListComponent ],
    exports: [ RolesListComponent ]
})
export class RoleModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('rolesList', RolesListComponent);
    }
}
