<div class="select-wrapper" [class.disabled]="disabled || !selectConfig.searchableOptions.length">
    <span class="toggle" (click)="openDropdown($event)" [ngClass]="{ 'is-open': isOpen }">
        <span class="input" [ngClass]="selectConfig.size">
            <span *ngIf="!selectedOption" class="ace-placeholder" [title]="selectConfig.placeholder">{{selectConfig.placeholder}}</span>
            <span *ngIf="selectedOption" class="ace-placeholder" [title]="(selectConfig.isPrimitive) ? selectedOption : selectedOption[selectConfig.labelProperty]">
                {{(selectConfig.isPrimitive) ? selectedOption : selectedOption[selectConfig.labelProperty]}}
            </span>
        </span>
        <span class="input measure">
            <span class="ace-placeholder">{{widestOption}}</span>
        </span>
        <span class="tools">
            <span class="tool-btn pointer-ignore pending-hide">
                <ws-ace-icon glyph="chevron-down" slot="icon"></ws-ace-icon>
            </span>
        </span>
    </span>
    <ng-template #wsSelectDropdown></ng-template>
</div>
