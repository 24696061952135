import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class ElectronicFilingRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private _baseUrl: string = '/api/electronicFiling/';

    getList(companyId: number, searchParams: Compliance.ElectronicFilingSearchModel): Observable<Compliance.QueryResultModel<Compliance.ElectronicFilingModel>> {
        return this.httpPost(`${this._baseUrl}company/${companyId}`, searchParams);
    }

    getRowIds(companyId: number, searchParams: Compliance.ElectronicFilingSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`${this._baseUrl}company/${companyId}/rowIds`, searchParams);
    }

    startExport(topLevelCompanyId: number, exportModel: Compliance.ElectronicFilingExportModel): Observable<number> {
        return this.httpPost(`${this._baseUrl}export/${topLevelCompanyId}`, exportModel);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this._baseUrl}export/${longRunningProcessId}`, options);
    }

    getElectronicFilingTypes(stateId: number): Observable<Compliance.ElectronicFilingTypeModel[]> {
        return this.httpGet(`${this._baseUrl}types/${stateId}`);
    }

    getElectronicFilingInfo(topLevelCompanyId: number): Observable<Compliance.ElectronicFilingInfoModel> {
        return this.httpGet(`${this._baseUrl}info/${topLevelCompanyId}`);
    }

    generateElectronicFilingOutput(outputModel: Compliance.ElectronicFilingOutputModel): Observable<number> {
        return this.httpPost(`${this._baseUrl}download`, outputModel);
    }

    getElectronicFilingOutput(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this._baseUrl}download/${longRunningProcessId}`, options);
    }
}
