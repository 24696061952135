import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { lastValueFrom, Subject } from 'rxjs';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { EntityImportRepository, LongRunningProcessRepository } from '../../Repositories';
import { WebsocketListenerService } from '../../websocketListener.service';
import { InstanceRights, RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { EntityImportListDataSourceParams, EntityImportListGridDataSource } from './agGridDataSource';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode, RowRenderer } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import {
    EntityImportListGridProgressCellRendererComponent,
    ICellRendererParamsForProgressCell
} from './agGridProgressCellRenderer.component';
import {
    EntityImportListGridActionCellRendererComponent,
    ICellRendererParamsForImportListGridAction
} from './agGridActionCellRenderer.component';
import { WeissmanDateTimeFormatPipe } from '../../../UI-Lib/Pipes';
import { BeginNewImportComponent } from '../../../Common/import/beginNewImport.component';
import { takeUntil } from 'rxjs/operators';
import { IMPORT_LIST_HELP } from './importList.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ToastrService } from 'ngx-toastr';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { EntityImportProcessService } from '../Import-Process/importProcess.service';
import { InstanceRepository } from '../../../Entity/Instance/instance.repository';

@Component({
    selector: 'entity-import-list',
    templateUrl: './importList.component.html',
    styleUrls: ['./importList.component.scss']
})
export class EntityImportListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _companyService: CompanyService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _restrictService: RestrictService,
        private readonly _datetimePipe: WeissmanDateTimeFormatPipe,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _helpService: HelpService,
        private readonly _toastr: ToastrService,
        private readonly _importService: EntityImportProcessService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository) { }

    @ViewChild('newImport') newImport: BeginNewImportComponent;

    private _gridApi: GridApi;
    private _gridDataSource: EntityImportListGridDataSource;
    private _hasComplianceFeaturePermission: boolean;
    private _destroy$: Subject<void> = new Subject<void>();

    breadcrumbs: BreadCrumb[] = [];
    isCompanyImport: boolean = false;
    isInitialized: boolean = false;
    canEdit: boolean = false;
    companyId?: number = null;
    companyName: string;
    gridId: System.Guid = 'F056E620-C711-47E0-A059-BD70FBC078AE';
    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withColumnPinning()
        .buildDefault(this);
    includeCompleted: boolean = false;

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    get dataImportSpecificationsHelpContentId(): string {
        return this.companyId ?
            'import-list.data-import-specifications-company' :
            'import-list.data-import-specifications';
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    async ngOnInit(): Promise<void> {
        this.isCompanyImport = !!this._routerService.getQuerystringParam('companyId');

        this._helpService.setContent(IMPORT_LIST_HELP);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            if (this.isCompanyImport) {
                this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
                this.canEdit = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);
                const instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('company', this.companyId));

                this._hasComplianceFeaturePermission = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, instanceId);

                if(!this._restrictService.hasInstanceRight(InstanceRights.COMPANYDATAIMPORTSVIEW, instanceId)) {
                    this.navigateToUnauthorized();
                }

                const company = await this._companyService.load(this.companyId, false, false);

                if (company.companyID !== company.topLevelCompanyId) {
                    this._toastr.error('Data imports are not allowed on subsidiary companies.');
                    this.navigateToCompany();
                    return;
                }

                this.companyName = company.companyName;

                this.breadcrumbs.push({
                    name: company.companyName,
                    target: 'company',
                    options: { companyId: company.companyID }
                });
            } else {
                if(!this._restrictService.isInRole(Roles.ALLOWDATAIMPORTS) && !this._restrictService.isInRole(Roles.TAXRATESETUP) &&
                    !this._restrictService.hasInstanceRight(InstanceRights.INSTANCEDATAIMPORTS)) {
                    this.navigateToUnauthorized();
                }

                this.canEdit = true;
                this.isInitialized = true;
            }
            this.isInitialized = true;
        } finally {
            await busyRef.hide();
        }

        this._websocketListenerService.longRunningProcessStatusChange$.pipe(takeUntil(this._destroy$)).subscribe(
            async (x) => {
                if (x.processType !== Compliance.LongRunningProcessTypeEnum.ImportFile) {
                    return;
                }

                if(!x.isCompleted) {
                    return;
                }

                if (!this._gridDataSource) {
                    return;
                }

                const rowNode: RowNode = this._gridDataSource.getShownNodes().find(r => {
                    const importFile = r.data as Compliance.ImportFileModel;
                    return importFile && importFile.importFileId === x.entityId;
                });

                if(!rowNode) {
                    return;
                }

                await this._updateRowModel(x.entityId);
            });

        this._websocketListenerService.longRunningProcessProgressChange$.pipe(takeUntil(this._destroy$)).subscribe(
            async (x) => {
                if (x.processType !== Compliance.LongRunningProcessTypeEnum.ImportFile) {
                    return;
                }

                if(!this._gridDataSource) {
                    return;
                }

                const rowNode: RowNode = this._gridDataSource.getShownNodes().find(r => {
                    const importFile = r.data as Compliance.ImportFileModel;
                    return importFile && importFile.importFileId === x.entityId;
                });

                if(!rowNode) {
                    return;
                }

                await this._gridDataSource.updateRow(y => {
                        const rowModel = y.data as Compliance.ImportFileModel;
                        return rowModel && rowModel.importFileId === x.entityId;
                    },
                    async y => {
                        const rowModel = y.data as Compliance.ImportFileModel;

                        if (rowModel.processStatus === Compliance.ImportFileProcessStatusEnum.Transferring) {
                            rowModel.transferredRows = x.current;
                        }

                        if(rowModel.processStatus === Compliance.ImportFileProcessStatusEnum.Processing || rowModel.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingCompleted) {
                            rowModel.totalRows = x.total;
                        }

                        return Promise.resolve(rowModel);
                    });
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'displayName',
                width: AgGridColumns.textColumnLargeWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Progress',
                field: 'displayStatus',
                width: 175,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                sortable: false,
                cellRendererFramework: EntityImportListGridProgressCellRendererComponent,
                cellRendererParams: {
                    resume: this._onProgressResume.bind(this)
                } as ICellRendererParamsForProgressCell
            },
            {
                headerName: 'Type',
                field: 'importContentType.name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            },
            {
                headerName: 'Filename',
                field: 'fileName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            },
            {
                headerName: 'Created',
                field: 'createDate',
                valueFormatter: (params) => {
                    return this._datetimePipe.transform(params.value, true, 'central');
                },
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            },
            {
                headerName: 'Owner',
                field: 'ownerName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Transferred / Total',
                field: 'transferredRows',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    return params.data
                        ? `${this._decimalPipe.transform(params.data.transferredRows, '1.0-0')} / ${this._decimalPipe
                            .transform(params.data.totalRows, '1.0-0')}`
                        : '';
                },
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            },
            {
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(4),
                minWidth: AgGridColumns.getActionColumnWidth(4),
                maxWidth: AgGridColumns.getActionColumnWidth(4),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'right',
                lockPinned: true,
                sortable: false,
                cellRendererFramework: EntityImportListGridActionCellRendererComponent,
                cellRendererParams: {
                    resume: this._onResume.bind(this),
                    delete: this._onDelete.bind(this),
                    canDelete: this._canDelete.bind(this),
                    revert: this._onRevert.bind(this),
                    canRevert: this._canRevert.bind(this),
                    complete: this._onComplete.bind(this),
                    canComplete: this._canComplete.bind(this)
                } as ICellRendererParamsForImportListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'createDate',
                sort: 'desc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    navigateToCompany(): void {
        this._routerService.go('company', { 'companyId': this.companyId });
    }

    async onIncludeCompletedChange(): Promise<void> {
        this._refreshDataSource();
    }

    navigateToImportSpecifications(): void {
        if (this.companyId) {
            this._routerService.go('companyImportSpecificationList', { 'companyId': this.companyId });
        } else {
            this._routerService.go('importSpecificationList', {});
        }
    }

    private _canDelete(params: ICellRendererParamsForImportListGridAction): boolean {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile) {
            return false;
        }

        return this._importService.canDelete(importFile);
    }

    private _onDelete(params: ICellRendererParamsForImportListGridAction) {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile) {
            return;
        }

        this._deleteImport(importFile.importFileId);
    }

    private async _deleteImport(importFileId: number): Promise<boolean> {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to delete the data import?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await lastValueFrom(this._entityImportRepository.deleteImport(importFileId));
            this._refreshDataSource();
            return Promise.resolve(true);
        } finally {
            await busyRef.hide();
        }
    }

    private _onProgressResume(params: ICellRendererParamsForProgressCell) {
        this._resumeImport(params.data as Compliance.ImportFileModel);
    }

    private _onResume(params: ICellRendererParamsForImportListGridAction) {
        this._resumeImport(params.data as Compliance.ImportFileModel);
    }

    private async _resumeImport(importFile: Compliance.ImportFileModel): Promise<void> {
        if (!importFile) {
            return;
        }

        let message = '';

        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingError) {
            message = importFile.error || 'An error occurred while processing the file.  Delete it and try again.';
        }

        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Reverting) {
            message = 'The transferred data is currently being reverted .  You\'ll be able to resume working on the data import when it\'s done.';
        }

        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Processing ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferring) {
            this._showImportProgress(importFile);
            return;
        }

        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Uploaded ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ShowPreview) {
            const resumeResult = await this.newImport.resumeImport(importFile);
            if (resumeResult) {
                this._gridDataSource.updateRow(
                    x => {
                        const rowData = x.data as Compliance.ImportFileModel;
                        return rowData && rowData.importFileId === resumeResult.importFileId;
                    },
                    () => Promise.resolve(resumeResult),
                false);
            }
            return;
        }

        if (message) {
            this._messageModalService.alert(message);
        } else {
            if (importFile.companyId) {
                this._routerService.go('processCompanyImport', { companyId: importFile.companyId, importId: importFile.importFileId });
            } else {
                this._routerService.go('processSystemImport', { importId: importFile.importFileId });
            }
        }
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }
        const dataSourceParams = (): EntityImportListDataSourceParams => {
            return {
                isCompanyImport: this.isCompanyImport,
                includeCompleted: this.includeCompleted,
                companyId: this.companyId
            };
        };

        this._gridDataSource = new EntityImportListGridDataSource(this._gridApi, this._entityImportRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _onRevert(params: ICellRendererParamsForImportListGridAction) {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile) {
            return;
        }

        this._revertImport(importFile.importFileId);
    }

    private async _revertImport(importFileId: number): Promise<boolean> {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to revert the data import? Any parcel override updates were set during the import will not be removed.',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const messageId = 'reverting';
        const message = 'Reverting Data Import';

        const busyRef = this._busyIndicatorService.show({ message: message, identifier: messageId });
        try {
            await lastValueFrom(this._entityImportRepository.revertImport(importFileId));
        } catch (e) {
            if (e && e.status === 423) {
                try {
                    busyRef.hide();
                    await this._messageModalService.confirm(
                        `${e.error.message  }  Are you sure you wish to take ownership?`,
                        'Confirm Take Ownership');
                } catch (e) {
                    return Promise.resolve(false);
                }

                busyRef.updateMessage('Taking ownership of the Data Import', messageId);

                try{
                    await lastValueFrom(this._entityImportRepository.takeOwnership(importFileId));
                }
                finally{
                    busyRef.updateMessage(message, messageId);

                    try {
                        await lastValueFrom(this._entityImportRepository.revertImport(importFileId));
                    } catch (e) {
                        // The ownership updated but the revert failed. Refresh the table row to reflect the new owner
                        await this._updateRowModel(importFileId);
                        throw e;
                    }

                    return Promise.resolve(true);
                }
            } else {
                throw e;
            }
        }
        finally {
            await busyRef.hide();
        }

        return Promise.resolve(true);
    }

    private async _updateRowModel(importFileId: number): Promise<void> {
        await this._gridDataSource.updateRow(
            y => {
                const rowModel = y.data as Compliance.ImportFileModel;
                return rowModel && rowModel.importFileId === importFileId;
            },
            async (y) => {
                const importFile = await lastValueFrom(this._entityImportRepository.getImport(importFileId, true));
                const rowModel = y.data as Compliance.ImportFileModel;
                importFile.createDate = rowModel.createDate; // todo: grid is sorted by create date by default, date comparison always returns false
                return importFile;
            });
    }

    private _canRevert(params: ICellRendererParamsForImportListGridAction): boolean {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile) {
            return false;
        }

        return this._hasComplianceFeaturePermission && importFile.canBeReverted && (
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferred ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferCompleted ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.RevertingError ||
            importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferError && importFile.transferredRows !== 0);
    }

    private async _showImportProgress(importFile: Compliance.ImportFileModel): Promise<void> {
        await this.newImport.showImportFileIsProcessingMessage(importFile);
    }

    private _onComplete(params: ICellRendererParamsForImportListGridAction) {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile || importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed) {
            return;
        }

        this._completeImport(importFile.importFileId);
    }

    private async _completeImport(importFileId: number): Promise<boolean> {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to mark the data import as completed?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Completing Data Import' });
        try {
            await lastValueFrom(this._entityImportRepository.markAsCompleted(importFileId));
            this._refreshDataSource();
            return Promise.resolve(true);
        } finally {
            busyRef.hide();
        }
    }

    private _canComplete(params: ICellRendererParamsForImportListGridAction): boolean {
        const importFile = params.data as Compliance.ImportFileModel;
        if (!importFile) {
            return false;
        }

        return this._hasComplianceFeaturePermission && importFile.processStatus !== Compliance.ImportFileProcessStatusEnum.Completed && importFile.transferredRows !== 0;
    }
}
