<ws-modal-wrapper headerLabel="Allocation May Not Be Locked. Asset(s) Allocated to Multiple Assessments."
                  headerIcon="fa-exclamation-triangle"
                  [suppressKeyboardEvents]="true"
                  acceptLabel="Close"
                  (save)="ok()"
                  (cancel)="ok()"
                  headerHelpContentId="retrieve-assets.title"
                  [hideCancel]="true">
    <ws-ag-grid-angular
        [gridOptions]="gridOptions"
        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
