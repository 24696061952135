import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'

@Injectable()
export class ReturnSettingsRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    getByCompany(companyId: number): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpGet(`/api/generalReturnSettings/company/${companyId}`);
    }

    getByParcel(parcelId: number): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpGet(`/api/generalReturnSettings/parcel/${parcelId}`);
    }

    getByReturn(returnId: number): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpGet(`/api/generalReturnSettings/return/${returnId}`);
    }

    saveByCompany(companyId: number, model: Compliance.GeneralReturnSettingsEntityModel): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpPost(`/api/generalReturnSettings/company/${companyId}`, model);
    }

    saveByParcel(parcelId: number, model: Compliance.GeneralReturnSettingsEntityModel): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpPost(`/api/generalReturnSettings/parcel/${parcelId}`, model);
    }

    saveByReturn(returnId: number, model: Compliance.GeneralReturnSettingsEntityModel): Observable<Compliance.GeneralReturnSettingsEntityModel> {
        return this.httpPost(`/api/generalReturnSettings/return/${returnId}`, model);
    }

    getLookupOptions(model: Compliance.GeneralReturnSettingsGetOptionsModel): Observable<Compliance.GeneralReturnSettingLookupModel[]> {
        return this.httpPost(`/api/generalReturnSettings/lookupOptions`, model);
    }

    searchContacts(searchModel: Compliance.GeneralReturnSettingsContactSearchModel): Observable<Core.ContactModel[]> {
        return this.httpPost('/api/generalReturnSettings/contacts', searchModel);
    }
}
