import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { DatabaseRepository } from './database.repository';
import { ProcessListComponent } from './Process-List/processList.component';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import {
    ErrorLogListGridActionCellRendererComponent
} from '../Error-Log/Error-Log-List/agGridActionCellRenderer.component';
import {
    DatabaseProcessListGridActionCellRendererComponent
} from './Process-List/agGridActionCellRenderer.component';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            ErrorLogListGridActionCellRendererComponent
        ])
    ],
    declarations: [
        ProcessListComponent,
        DatabaseProcessListGridActionCellRendererComponent
    ],
    providers: [
        DatabaseRepository
    ],
    exports: []
})
export class DatabaseModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('databaseProcessList', ProcessListComponent);
    }
}
