<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">
    <div style="margin-top: 20px;">
        <input id="engagementSearch" type="text" class="form-control" [(ngModel)]="searchTerm" style="display: inline-block; width: 90%; margin-bottom: 15px;"
            (keyup.enter)="search()">
        <button class="flat-button icon-button primary-button" [disabled]="!searchTerm">
            <i class="fa fa-search" (click)="search()"></i>
        </button>
    </div>
    <div class="text-center" style="margin-top: 50px;" *ngIf="isLoading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!isLoading && !engagementResults.length && initialSearch">
        <em class="lead">No results</em>
    </div>
    <div *ngIf="!isLoading && engagementResults.length" class="fixed-table-container hidden-head">
        <div class="header-background"> </div>
        <div class="fixed-table-container-inner">
            <table class="table table-condensed table-hover">
                <thead>
                    <tr>
                        <th>
                            <div class="th-inner">
                                Engagement #
                            </div>
                        </th>
                        <th>
                            <div class="th-inner">
                                Client Name
                            </div>
                        </th>
                        <th>
                            <div class="th-inner">
                                Description
                            </div>
                        </th>
                    </tr>
                    <tr class="hidden-header">
                        <th>
                            <div class="th-inner">
                                Engagement #
                            </div>
                        </th>
                        <th>
                            <div class="th-inner">
                                Client Name
                            </div>
                        </th>
                        <th>
                            <div class="th-inner">
                                Description
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody style="overflow-y: scroll; height: 300px;">
                    <tr *ngFor="let engagement of engagementResults" style="cursor: pointer;" (click)="selectEngagement(engagement)">
                        <td>{{engagement.engagementID}}</td>
                        <td style="max-width: 400px;">{{engagement.clientName}}</td>
                        <td>{{engagement.engagementDesc}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
