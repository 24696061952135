import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { StepProgressIndicatorComponent } from './stepProgressIndicator.component';
import { HelpModule } from '../../UI-Lib/Help-Tooltip/help.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        BusyIndicatorModule.forRoot(),
        ModalModule,
        HelpModule
    ],
    declarations: [
        StepProgressIndicatorComponent
    ],
    exports: [
        StepProgressIndicatorComponent
    ]
})
export class StepProgressIndicatorModule {
}
