import { Injectable } from "@angular/core";
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { BulkImportAssessmentsModel, BulkImportSitesModel, BulkImportResultModel } from "./bulk.import.model";

@Injectable()
export class BulkImportService {
    constructor(private http: SDHttpService) { }

    importAssessmentData(payload: BulkImportAssessmentsModel): Promise<number> {
        return this.http.post('/api/AnnualAssessment/BulkImport', payload);
    }

    importSiteData(payload: BulkImportSitesModel): Promise<BulkImportResultModel> {
        return this.http.post('/api/Site/BulkImport', payload);
    }

    importParcelData(payload: BulkImportSitesModel): Promise<BulkImportResultModel> {
        return this.http.post('/api/Parcel/BulkImport', payload);
    }
}
