import { Component, OnInit } from '@angular/core';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { lastValueFrom } from 'rxjs';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { DocumentIntakeLicensedClientDetailRepository } from '../documentIntakeLicensedClient.repository';
import {
    DocumentIntakeLicensedClientDetailComponent,
    DocumentIntakeLicensedClientDetailComponentParams
} from '../Document-Intake-Licensed-Client-Detail/documentIntakeLicensedClientDetail.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';

@Component({
    selector: 'document-intake-licensed-client-list',
    templateUrl: './documentIntakeLicensedClientList.component.html',
    styleUrls: ['./documentIntakeLicensedClientList.component.scss']
})
export class DocumentIntakeLicensedClientListComponent implements OnInit {

    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _documentIntakeLicensedClientDetailRepository: DocumentIntakeLicensedClientDetailRepository,
        private readonly _modalService: WeissmanModalService,) {
    }

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    licensedClients: Core.DocumentIntakeLicensedClientModel[];

    async ngOnInit(): Promise<void> {
        if(!this._restrictService.isInRole(Roles.LICENSEDDOCUMENTSERVICES)) {
            this._routerService.go('unauthorizedAccess', {});
            return Promise.resolve();
        }

        const busyLoading = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyLoading);

        try {
            this.licensedClients = await lastValueFrom(this._documentIntakeLicensedClientDetailRepository.getList());
            this.licensedClients.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
        } finally {
            this.busyIndicatorMessageManager.remove(busyLoading);
        }
    }

    getStatus(client: Core.DocumentIntakeLicensedClientModel) : string {
        let status = '';

        if (client.ryanUserGroupId) {
            status = 'Invite Accepted';
        } else if (client.cancellationDate) {
            status = 'Discontinued';
        } else {
            status = 'Invited';
        }

        return status;
    }

    async configure(client: Core.DocumentIntakeLicensedClientModel) : Promise<void> {
        const params: DocumentIntakeLicensedClientDetailComponentParams = {
            client: client
        };
        const result = await this._modalService.showAsync(DocumentIntakeLicensedClientDetailComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const licensedClient = this.licensedClients.find(x => x.instanceId == result.instanceId);
        licensedClient.ryanUserGroupId = result.ryanUserGroupId;

        return Promise.resolve();
    }
}
