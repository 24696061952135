import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AssessorCollectorRepository } from '../Core-Repositories';
import { Assessor, AssessorActiveParcelCount } from './assessor.model';
import { AssessorMunicipality } from './List/addAssessorModal.component';
import { CollectorAssociation } from './Assessor/Assessor-Available-Collectors/assessor.available.collectors.component';
import { Collector } from './collector.model';

@Injectable()
export class AssessorCollectorService {
    constructor(private readonly _assessorCollectorRepository: AssessorCollectorRepository) {}

    selectedCollectorLetter: string;

    private readonly COLLECTOR: string = 'collector';
    private readonly ASSESSOR: string = 'assessor';

    getNavigationInfo(entityType: string, entityId: number): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.getNavigationInfo(entityType, entityId));
    }

    getCompanyCollectorsByParcel(parcelId: number): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.getCompanyCollectorsByParcel(parcelId));
    }

    getAssessorExtract(): Promise<Blob> {
        return lastValueFrom(this._assessorCollectorRepository.getAssessorExtract());
    }

    getCollectorExtract(): Promise<Blob> {
        return lastValueFrom(this._assessorCollectorRepository.getCollectorExtract());
    }

    getUpdatedAssessor(entityId: number, extraParam?: string): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.getUpdatedAssessorCollector(this.ASSESSOR, entityId, extraParam));
    }

    getUpdatedCollector(entityId: number, extraParam?: string): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.getUpdatedAssessorCollector(this.COLLECTOR, entityId, extraParam));
    }

    saveAssessor(assessor: Assessor): Promise<Assessor> {
        return lastValueFrom(this._assessorCollectorRepository.saveAssessorCollector(this.ASSESSOR, assessor)) as Promise<Assessor>;
    }

    saveCollector(collector: any): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.saveAssessorCollector(this.COLLECTOR, collector));
    }

    getAssessorCollectorList(stateId: number, assessorId: number): Promise<any[]> {
        return lastValueFrom(this._assessorCollectorRepository.getAssessorCollectorList(stateId, assessorId));
    }

    getStateCollectorList(stateId:number): Promise<any[]> {
        return lastValueFrom(this._assessorCollectorRepository.getStateCollectorList(stateId));
    }

    getCompanyCollectorList(companyId: number): Promise<any[]> {
        return lastValueFrom(this._assessorCollectorRepository.getCompanyCollectorList(companyId));
    }

    getCollectorAssociations(assesorId: number): Promise<CollectorAssociation[]> {
        return lastValueFrom(this._assessorCollectorRepository.getCollectorAssociations(assesorId));
    }

    saveAssociations(assessorId: number, associations: CollectorAssociation[]): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.saveAssociations(assessorId, associations));
    }

    getAssessorList(stateId: number): Promise<Assessor[]> {
        return lastValueFrom(this._assessorCollectorRepository.getStateAssessorList(stateId));
    }

    getAssessorActiveParcelCounts(stateId: number): Promise<AssessorActiveParcelCount[]> {
        return lastValueFrom(this._assessorCollectorRepository.getAssessorActiveParcelCounts(stateId));
    }

    getAssessorMunicipalities(): Promise<AssessorMunicipality[]> {
        return lastValueFrom(this._assessorCollectorRepository.getAssessorMunicipalities());
    }

    createAssessor(assessor: Assessor): Promise<Assessor> {
        return lastValueFrom(this._assessorCollectorRepository.createAssessor(assessor));
    }

    createCollector(collector: Collector): Promise<Collector> {
        return lastValueFrom(this._assessorCollectorRepository.createCollector(collector));
    }

    getAssessor(assessorId: number): Promise<Assessor> {
        return lastValueFrom(this._assessorCollectorRepository.getAssessor(assessorId));
    }

    saveCollectorProtocols(collectorProtocols: Weissman.Model.Collectors.Collector): Promise<any> {
        return lastValueFrom(this._assessorCollectorRepository.saveCollectorProtocols(collectorProtocols));
    }
}
