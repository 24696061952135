import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScheduleAndFactorFilter } from '../../returnAssets.service'
import * as _ from 'lodash';

@Component({
    selector: 'return-asset-form-schedule',
    templateUrl: './returnAssetFormSchedule.component.html'
})
export class ReturnAssetFormScheduleComponent implements OnInit {
    @Input() canExpand: boolean;
    @Input() schedule: Compliance.ReturnAssetScheduleDetailsAssignedItemModel;
    @Input() scheduleDetails: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[];
    @Input() scheduleForUnassignedAssets: boolean = false;
    @Input() scheduleForNonReportableAssets: boolean = false;
    @Input() scheduleForReportableAssets: boolean = false;
    @Input() scheduleAndFactorFilter: ScheduleAndFactorFilter;

    @Output() scheduleClick: EventEmitter<ScheduleAndFactorFilter> = new EventEmitter();
    @Output() scheduleAgeClick: EventEmitter<ScheduleAndFactorFilter> = new EventEmitter();

    isBusy: boolean = false;
    isExpanded: boolean = false;
    scheduleFilterId: number;

    ngOnInit(): void {
        if (this.scheduleForUnassignedAssets || this.scheduleForNonReportableAssets || this.scheduleForReportableAssets) {
            return;
        }

        // group schedule detail information by schedule and year (it will be coming in in a more granular form)
        const groupedScheduleDetails: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[] = [];
        this.scheduleDetails
            .filter(x => x.assetCount > 0)
            .forEach(x => {
                const schedule = groupedScheduleDetails.find(y => x.formRevisionScheduleId === y.formRevisionScheduleId && x.year === y.year);
                if (schedule) {
                    schedule.assetCount += x.assetCount;
                    schedule.priorReportedValue += x.priorReportedValue;
                    schedule.priorCost += x.priorCost
                    schedule.additions += x.additions;
                    schedule.disposals += x.disposals;
                    schedule.cost += x.cost;
                    schedule.reportedValue += x.reportedValue;
                } else {
                    groupedScheduleDetails.push({
                        formRevisionId: x.formRevisionId,
                        formRevisionScheduleId: x.formRevisionScheduleId,
                        abbr: x.abbr,
                        name: x.name,
                        description: x.description,
                        year: x.year,
                        age: x.age,
                        isTaxable: x.isTaxable,
                        isReportable: x.isReportable,
                        isDepreciable: x.isDepreciable,
                        isSystem: x.isSystem,
                        depreciationTableId: x.depreciationTableId,
                        depreciationTableLife: x.depreciationTableLife,
                        depreciationTableName: x.depreciationTableName,
                        depreciationFactor: x.depreciationFactor,
                        assetCount: x.assetCount,
                        priorCost: x.priorCost,
                        priorReportedValue: x.priorReportedValue,
                        additions: x.additions,
                        disposals: x.disposals,
                        cost: x.cost,
                        exemptCost: x.exemptCost,
                        reportedValue: x.reportedValue,
                        lockedAssessors: x.lockedAssessors
                    });
                }
            })
        this.scheduleDetails = groupedScheduleDetails;
    }

    isScheduleActive(): boolean {
        return (this.scheduleForUnassignedAssets && this.scheduleAndFactorFilter.showOnlyNotAssignedToASchedule) ||
            (this.scheduleForNonReportableAssets && this.scheduleAndFactorFilter.showOnlyAssignedToNonReportableSchedule) ||
            (this.scheduleForReportableAssets && this.scheduleAndFactorFilter.showOnlyAssignedToReportableSchedule) ||
            (
                !this.scheduleForUnassignedAssets &&
                !this.scheduleForNonReportableAssets &&
                !this.scheduleForReportableAssets &&
                _.isEqual(this.scheduleAndFactorFilter.formRevisionScheduleIds, this.schedule.formRevisionScheduleId ? [this.schedule.formRevisionScheduleId] : []) &&
                this.scheduleAndFactorFilter.depreciationFactorId === (this.schedule && this.schedule.depreciationTableId)
            );
    }

    isScheduleDetailActive(scheduleDetail: Compliance.ReturnAssetScheduleDetailsAssignedItemModel): boolean {
        return this.isScheduleActive() && this.scheduleAndFactorFilter.scheduleAge === scheduleDetail.age;
    }

    toggleIsExpanded(event: Event): void {
        event.stopPropagation();
        this.isExpanded = !this.isExpanded;
    }

    onScheduleClicked(event: Event): void {
        event.stopPropagation();
        this.scheduleClick.emit({
            showOnlyNotAssignedToASchedule: this.scheduleForUnassignedAssets,
            showOnlyAssignedToNonReportableSchedule: this.scheduleForNonReportableAssets,
            showOnlyAssignedToReportableSchedule: this.scheduleForReportableAssets,
            formRevisionScheduleIds: this.schedule.formRevisionScheduleId ? [this.schedule.formRevisionScheduleId] : [],
            depreciationFactorId: this.schedule.depreciationTableId,
            scheduleAge: null,
            userSelectedEmptySchedule: !this.schedule.assetCount
        });
    }

    onScheduleDetailClicked(event: Event, scheduleDetail: Compliance.ReturnAssetScheduleDetailsAssignedItemModel): void {
        event.stopPropagation();
        this.scheduleAgeClick.emit({
            showOnlyNotAssignedToASchedule: this.scheduleForUnassignedAssets,
            showOnlyAssignedToNonReportableSchedule: this.scheduleForNonReportableAssets,
            showOnlyAssignedToReportableSchedule: this.scheduleForReportableAssets,
            formRevisionScheduleIds: this.schedule.formRevisionScheduleId ? [this.schedule.formRevisionScheduleId] : [],
            depreciationFactorId: this.schedule.depreciationTableId,
            scheduleAge: scheduleDetail.age,
            userSelectedEmptySchedule: !this.schedule.assetCount
        });
    }
}
