import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../../AgGrid';
import { ReturnAssetsService } from '../returnAssets.service';
import { ReturnService } from '../../../return.service';
import { ReturnAssetRepository } from "../../../../Repositories";

export interface ReturnAssetDisposalsListDataSourceParams {
    priorReturnStatuses: Compliance.ReturnAssetPriorReturnStatusEnum[];
    formRevisionScheduleId: number;
    targetNonReportableSchedule: boolean;
    targetReportableSchedule: boolean;
    scheduleGroupType?: Compliance.ScheduleGroupTypeEnum;
}

export class ReturnAssetDisposalsListAgGridDataSource extends AgGridDataSourceBase<ReturnAssetDisposalsListDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _returnService: ReturnService,
        private _returnAssetsService: ReturnAssetsService,
        private _returnAssetRepository: ReturnAssetRepository,
        protected _dataSourceParamsFn: () => ReturnAssetDisposalsListDataSourceParams,
        private _totalsUpdateCallback: (totals: Compliance.ReturnAssetSearchTotalsModel) => void
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ReturnAssetPropertyEnum>[] = [
        { name: 'assetNumber', value: Compliance.ReturnAssetPropertyEnum.AssetNumber },
        { name: 'description', value: Compliance.ReturnAssetPropertyEnum.Description },
        { name: 'classificationName', value: Compliance.ReturnAssetPropertyEnum.ClassificationName },
        { name: 'parcelAccountNumber', value: Compliance.ReturnAssetPropertyEnum.ParcelAccountNumber },
        { name: 'acqDate', value: Compliance.ReturnAssetPropertyEnum.AcqDate },
        { name: 'priorAcqDate', value: Compliance.ReturnAssetPropertyEnum.PriorAcqDate },
        { name: 'cost', value: Compliance.ReturnAssetPropertyEnum.Cost },
        { name: 'priorCost', value: Compliance.ReturnAssetPropertyEnum.PriorCost },
        { name: 'costChange', value: Compliance.ReturnAssetPropertyEnum.CostChange },
        { name: 'scheduleName', value: Compliance.ReturnAssetPropertyEnum.Schedule },
        { name: 'priorScheduleName', value: Compliance.ReturnAssetPropertyEnum.PriorSchedule },
        { name: 'depreciationFactorTableName', value: Compliance.ReturnAssetPropertyEnum.DepreciationFactorTableName },
        { name: 'priorDepreciationFactorTableName', value: Compliance.ReturnAssetPropertyEnum.PriorDepreciationFactorTableName},
        { name: 'factor', value: Compliance.ReturnAssetPropertyEnum.Factor },
        { name: 'reportedValue', value: Compliance.ReturnAssetPropertyEnum.DepreciationCost },
        { name: 'disposedDate', value: Compliance.ReturnAssetPropertyEnum.DisposedDate },
        { name: 'site', value: Compliance.ReturnAssetPropertyEnum.Site },
        { name: 'siteName', value: Compliance.ReturnAssetPropertyEnum.SiteName },
        { name: 'siteProperty', value: Compliance.ReturnAssetPropertyEnum.SiteProperty },
        { name: 'priorSiteProperty', value: Compliance.ReturnAssetPropertyEnum.PriorSiteProperty },
        { name: 'priorSiteName', value: Compliance.ReturnAssetPropertyEnum.PriorSiteName },
        { name: 'disposalAssetNumber', value: Compliance.ReturnAssetPropertyEnum.DisposalAssetNumber },
        { name: 'disposalDescription', value: Compliance.ReturnAssetPropertyEnum.DisposalDescription },
        { name: 'disposalClassification', value: Compliance.ReturnAssetPropertyEnum.DisposalClassification },
        { name: 'assessorAbbr', value: Compliance.ReturnAssetPropertyEnum.AssessorAbbr }
    ];

    private _lastFilterUsedInTotals: string;
    lastFetchedTotals: Compliance.ReturnAssetSearchTotalsModel;

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals != searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        if (fetchTotals) {
            searchParams.includeTotals = true;
        }

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._returnAssetRepository.getListByFilingBatch(this._returnService.filingBatchId, searchParams));

        if (fetchTotals) {
            this.lastFetchedTotals = result.totals;
            this._totalsUpdateCallback(this.lastFetchedTotals);
        }

        return {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };
    }

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    getSearchParamsWithoutPagination(): Compliance.ReturnAssetSearchModel {
        const getPriorReturnFromAssetList = this._returnService.filingBatch.getPriorReturnFromAssetList;

        let searchModel: Compliance.ReturnAssetSearchModel = {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            formRevisionIds: this._returnService.sharedState.formRevisionId ? [this._returnService.sharedState.formRevisionId] : [],
            parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
            assessorIds: getPriorReturnFromAssetList ? [] : this._returnAssetsService.sharedState.assessorFactorsFilter.assessorIds || [],
            formRevisionScheduleIds: this._dataSourceParams.formRevisionScheduleId ? [this._dataSourceParams.formRevisionScheduleId] : [],
            formFactorTableId: null,
            age: null,
            priorReturnStatuses: this._dataSourceParams.priorReturnStatuses || [],
            scheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            priorScheduleGroupType: Compliance.ScheduleGroupTypeEnum.Unrestricted,
            selectedRows: null,
            includeTotals: false,
            includeOnlyTotals: false,
            includeMergedParcels: this._returnService.isConsolidatedReturn,
            includeUnassigned: false,
            includeDisposed: true
        };

        if (this._dataSourceParams.priorReturnStatuses.findIndex(x => x === Compliance.ReturnAssetPriorReturnStatusEnum.Retired) !== -1 ||
            this._dataSourceParams.priorReturnStatuses.findIndex(x => x === Compliance.ReturnAssetPriorReturnStatusEnum.TransferOut) !== -1) {
            if (this._dataSourceParams.targetNonReportableSchedule && this._dataSourceParams.targetReportableSchedule) {
                searchModel.priorScheduleGroupType = Compliance.ScheduleGroupTypeEnum.Unrestricted;
            }
            else if (this._dataSourceParams.targetNonReportableSchedule) {
                searchModel.priorScheduleGroupType = Compliance.ScheduleGroupTypeEnum.NonReportable;
            } else if (this._dataSourceParams.targetReportableSchedule) {
                searchModel.priorScheduleGroupType = Compliance.ScheduleGroupTypeEnum.ReportableTaxableNotTaxable;
            } else {
                searchModel.priorScheduleGroupType = Compliance.ScheduleGroupTypeEnum.OnlyNotAssignedToASchedule;
            }
        } else {
            if (this._dataSourceParams.scheduleGroupType >= 0) {
                searchModel.scheduleGroupType = this._dataSourceParams.scheduleGroupType
            } else {
                if (this._dataSourceParams.targetNonReportableSchedule) {
                    searchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.NonReportable;
                }

                if (this._dataSourceParams.targetReportableSchedule) {
                    searchModel.scheduleGroupType = Compliance.ScheduleGroupTypeEnum.ReportableTaxableNotTaxable;
                }
            }
        }

        return searchModel;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.pagination = {
            skip: startIndex,
            take: endIndex - startIndex + 1
        };

        return await lastValueFrom(this._returnAssetRepository.getRowIdsByFilingBatch(this._returnService.filingBatchId, searchParams));
    }
}
