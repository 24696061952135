import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const ASSET_LIEN_DATE_HELP: HelpContent[] = [
    {
        helpContentId: 'asset-lien-date.prior-lien-date',
        tooltipText: 'Only show assets that were present on or carried forward to the selected lien date.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-lien-date.exact-lien-date',
        tooltipText: 'Only show assets that were present or confirmed present on the selected lien date.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-lien-date.standard-lien-date',
        tooltipText: 'Only show assets from states that utilize the selected standard lien date.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-lien-date.standard-lien-date-disabled',
        tooltipText: 'Not available when a non-standard lien date is selected.',
        hasIcon: true
    }
];
