(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdColumnCheckboxes', sdColumnCheckboxes);

	sdColumnCheckboxes.$inject = [];

	function sdColumnCheckboxes() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Search/Advanced/Column-Picker/_columnCheckboxes.html',
			scope: {
				category: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit() {

	}
})();
