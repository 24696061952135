import { NgModule } from '@angular/core';
import { SupportInstanceListComponent } from './Support-Instance-List/supportInstanceList.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { SupportInstanceRepository } from '../Compliance/Repositories/supportInstance.repository';
import { BusyIndicatorModule } from '../Busy-Indicator';
import {
    SupportInstanceRequestAccessComponent
} from './Support-Instance-Request-Access/supportInstanceRequestAccess.component';

@NgModule({
    imports:[
        WeissmanSharedModule,
        BusyIndicatorModule
    ],
    declarations:[
        SupportInstanceListComponent,
        SupportInstanceRequestAccessComponent
    ],
    providers:[
        SupportInstanceRepository
    ]
})
export class SupportInstanceModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('supportInstanceList', SupportInstanceListComponent);
    }
}
