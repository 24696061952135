<div *ngIf="isInitialized" class="ws-section">
    <div class="ws-section__header">
        <h4 class="ws-section__header__title">
            Database Processes
            <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
        </h4>
        <div class="ws-section__header__actions">
            <div class="ws-section__header__actions__item">
                <button type="button"
                        class="flat-button small-button primary-button"
                        (click)="clearCache()"
                        [disabled]="loadingData"
                        helpTooltip
                        helpContentId="app.refresh"
                        position="bottom">
                    Clear Cache
                </button>
                <button type="button"
                        class="flat-button small-button secondary-button"
                        (click)="refresh()"
                        [disabled]="loadingData"
                        helpTooltip
                        helpContentId="app.refresh"
                        position="bottom">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<ws-ag-grid-angular [gridOptions]="gridOptions"
                    (gridReady)="onAgGridReady($event)"
                    [gridId]="gridId"
                    [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                    (bulkDelete)="bulkKill()">
</ws-ag-grid-angular>
