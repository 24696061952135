import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DashboardDataService } from '../../dashboard.data.service';
import { CalendarWidgetFieldIdCategory, CalendarWidgetFieldIds } from "../../dashboard.model";
import { DashboardCalendarEventTypes } from "./calendar.widget.model";
import { OutputColumn } from "../../../Search/Advanced/Column-Picker/column.model";
import { ColumnCategory, DraggableOutputColumn } from "../../../Common/AV-AS/category.column.picker.component";
import { SmartSearchService } from "../../../Search/Advanced/smartSearch.service";

import * as _ from 'lodash';

@Component({
    selector: 'calendar-widget-settings',
    templateUrl: './calendar.widget.settings.component.html'
})
export class CalendarWidgetSettingsComponent implements OnInit {
    loading: boolean;
    calendarWidgetIds: CalendarWidgetFieldIds;
    calendarWidgetIdsDefaults: CalendarWidgetFieldIds
    calendarWidgetFields: ColumnCategory[];
    private allOutputColumns: OutputColumn[];
    onClose: (shouldReload: boolean) => void;

    constructor(
        public bsModalRef: BsModalRef,
        private dashboardDataService: DashboardDataService,
        private asService: SmartSearchService
    ) { }

    async ngOnInit() {
        this.loading = true;

        try {
            this.calendarWidgetIds = await this.dashboardDataService.getSmartAvFields();
            this.calendarWidgetIdsDefaults = await this.dashboardDataService.getSmartAvFieldsDefault();

            this.allOutputColumns = (await this.asService.getColumns(true,'smart')).all

            this.calendarWidgetFields = _.chain(this.calendarWidgetIds.categories)
                .reject(category => this._isDummyComplianceCategory(category.type))
                .map((category, idx) => {
                    const defaultCategory = this.calendarWidgetIdsDefaults.categories[idx];

                    return {
                        categoryName: this.dashboardDataService.eventTypeTitleMap.get(category.type),
                        columns: this._mapColIds(category),
                        defaultColumns: this._mapColIds(defaultCategory)
                    }
                })
                .value();
        } finally {
            this.loading = false;
        }

    }

    async saveAndClose(): Promise<void> {
        const masterComplianceCategoryIdx = _.findIndex(this.calendarWidgetIds.categories, {type: DashboardCalendarEventTypes.AbatementComplianceDeadline});
        const masterComplianceCategory = this.calendarWidgetFields[masterComplianceCategoryIdx];

        const calendarWidgetFieldsIds: CalendarWidgetFieldIds = {
            categories: _.map(this.calendarWidgetIds.categories, (category, idx) => {
                const columnsToMap = this._isDummyComplianceCategory(category.type)
                    ? masterComplianceCategory.columns
                    : this.calendarWidgetFields[idx].columns

                category.columnIds = _.map(columnsToMap, 'columnId')
                return category;
            })
        };

        this.loading = true;

        try {
            await this.dashboardDataService.saveSmartAvFields(calendarWidgetFieldsIds);

            this.onClose(false);
            this.bsModalRef.hide();
        } finally {
            this.loading = false;
        }
    }

    private _mapColIds(category: CalendarWidgetFieldIdCategory): DraggableOutputColumn[] {
        return _.map(category.columnIds, colId => {
            const column = _.find(this.allOutputColumns, { columnId: colId });
            if(!column) {
                console.warn('Column cannot be found from ID!');
                return;
            }

            return { ...column, isRemovable: !_.includes(category.columnsToKeep, column.columnId) }
        })
    }

    private _isDummyComplianceCategory(categoryType: DashboardCalendarEventTypes): boolean {
        switch (categoryType) {
            case DashboardCalendarEventTypes.FreeportComplianceDeadline:
            case DashboardCalendarEventTypes.MiscFilingComplianceDeadline:
            case DashboardCalendarEventTypes.PPReturnComplianceDeadline:
            case DashboardCalendarEventTypes.PollutionControlComplianceDeadline:
                return true;
            default:
                return false;
        }
    }
}

