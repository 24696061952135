<div class="card panel-flat-color secondary-panel valuation-panel">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Valuation</h3>
    </div>
    <div class="card-body" *ngIf="showBody" style="padding-top: 0;height: 490px;">
        <tabset>
            <tab heading="Income Statements" (selectTab)="incomeStatementSelected()">
                <div class="text-center" style="margin-top: 50px;" *ngIf="onIncomeStatementLoading">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div [hidden]="onIncomeStatementLoading" class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
                    <income-statement-list *ngIf="showIncomeStatement" [companyIdFilter]="companyId" [parcelIdFilter]="parcelId" [siteIdFilter]="siteId" [isEmbededMode]="true" [(onLoading)]="onIncomeStatementLoading"></income-statement-list>
                </div>
            </tab>
            <tab heading="Rent Rolls" (selectTab)="rentRollSelected()">
                <div class="text-center" style="margin-top: 50px;" *ngIf="onRentRollLoading">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div [hidden]="onRentRollLoading" class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
                    <rent-roll-list *ngIf="showRentRoll" [companyIdFilter]="companyId" [parcelIdFilter]="parcelId" [siteIdFilter]="siteId" [isEmbededMode]="true" [(onLoading)]="onRentRollLoading"></rent-roll-list>
                </div>
            </tab>
        </tabset>
    </div>
</div>
