import { ColDef } from 'ag-grid-community';
import { WeissmanDateFormat } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { AgGridColumns, AgGridFilterParams } from '../../../Compliance/AgGrid';
import { PaymentBatchPaymentsGridComponent as PaymentBatchPaymentsGridComponent } from './paymentBatchPaymentsGrid.component';
import { AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer } from 'src/app/Compliance/AgGrid/MultiSelectTracker';
import { AgGridLinkCellRenderer, AgGridLinkCellRendererParams } from 'src/app/Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { ICellRendererParamsForPaymentBatchPaymentsGridAction, PaymentBatchPaymentsGridActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { PaymentPackage } from '../../../Processing/Transmittal/transmittal.models';
import { ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors, PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent } from './agGridPaymentErrorsCellRenderer.component';

export const PAYMENT_BATCH_PAYMENTS_COLUMNS = (classRef: PaymentBatchPaymentsGridComponent): ColDef[] => [
    {
        colId: 'grid-column-multiselect',
        headerName: '',
        field: 'paymentId',
        width: AgGridColumns.selectionColumnWidth,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressColumnsToolPanel: true,
        editable: false,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        suppressMovable: true,
        headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
        headerComponentParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
        cellRendererFramework: AgGridMultiSelectedCellRenderer,
        cellRendererParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
        pinnedRowCellRenderer: () => {return '';}
    } as ColDef,
    {
        headerName: 'Payment Status',
        field: 'paymentStatus',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Payee',
        field: 'collector',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-collector',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/states/${model.stateId}/collectors/${model.collectorId}`;
            }
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'State',
        field: 'state',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.stateAbbreviationColumnWidth,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-state',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/states/${model.stateId}`;
            }
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'Parcel Acct Num',
        field: 'parcelAcctNum',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/parcel/${model.parcelId}`;
            }
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'Installment',
        field: 'installmentName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Payment Amount',
        field: 'paymentAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-payment',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/payment/${model.paymentId}`;
            },
            isDisabled: (params: AgGridLinkCellRendererParams) => params.data?.selectedTotalsRow
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'Payment Due Date',
        field: 'dueDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchDetailsEntryModel;
            if (!(model && model.dueDate)) {
                return '';
            }

            return WeissmanDateFormat(model.dueDate, true, 'stored');
        },
    },
    {
        headerName: 'Payment Notes',
        field: 'paymentNotes',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Payment Type',
        field: 'paymentType',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Bill Amount',
        field: 'billAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Base Payment Amount',
        field: 'basePaymentAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Discount Amount',
        field: 'discountAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Interest',
        field: 'interestAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Penalty',
        field: 'penaltyAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Exemption Amount',
        field: 'exemptionAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Check No',
        field: 'checkNo',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true,
    },
    {
        headerName: 'Check Date',
        field: 'checkDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        hide: true,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchDetailsEntryModel;
            if (!(model && model.checkDate)) {
                return '';
            }

            return WeissmanDateFormat(model.checkDate, true, 'stored');
        },
    },
    {
        headerName: 'Payment Post Date',
        field: 'postDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        hide: true,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchDetailsEntryModel;
            if (!(model && model.postDate)) {
                return '';
            }

            return WeissmanDateFormat(model.postDate, true, 'stored');
        },
    },
    {
        headerName: 'Tax Year',
        field: 'taxYear',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Company',
        field: 'companyName',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth,
        hide: true,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/company/${model.companyId}`;
            }
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'Site',
        field: 'siteName',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnWidth,
        hide: true,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/site/${model.siteId}`;
            }
        } as AgGridLinkCellRendererParams,
    },
    {
        headerName: 'Assessor',
        field: 'assessorName',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        cellRendererFramework: AgGridLinkCellRenderer,
        cellRendererParams: {
            getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
            newWindow: true,
            getLink: (params: AgGridLinkCellRendererParams) => {
                const model = params.data as Core.PaymentBatchDetailsEntryModel;
                if (!model) {
                    return '';
                }
                return `#/states/${model.stateId}/assessors/${model.assessorId}`;
            }
        } as AgGridLinkCellRendererParams,
        // hide: true
    },
    {
        headerName: 'Site Pay Res',
        field: 'sitePayRes',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Site Payment Resp',
        field: 'sitePaymentResp',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Parcel Pay Res',
        field: 'parcelPayRes',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: 'Parcel Payment Resp',
        field: 'parcelPaymentResp',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        hide: true
    },
    {
        headerName: '',
        field: 'paymentErrors',
        width: AgGridColumns.checkboxColumnMinWidth,
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        sortable: false,
        cellRendererFramework: PaymentBatchPaymentsGridPaymentErrorsCellRendererComponent,
        cellRendererParams: {
            getErrors: classRef.getErrors.bind(classRef)
        } as ICellRendererParamsForPaymentBatchPaymentsGridPaymentErrors
    },
    {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        width: AgGridColumns.getActionColumnWidth(3),
        minWidth: AgGridColumns.getActionColumnWidth(3),
        maxWidth: AgGridColumns.getActionColumnWidth(3),
        lockPinned: true,
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        sortable: false,
        cellRendererFramework: PaymentBatchPaymentsGridActionCellRendererComponent,
        cellRendererParams: {
            delete: classRef.deleteRow.bind(classRef),
            canDelete: classRef.canDelete.bind(classRef),
            canEdit: classRef.canEdit.bind(classRef)
        } as ICellRendererParamsForPaymentBatchPaymentsGridAction
    }
];

export const TRANSMITTAL_PACKAGE_COLUMN = (classRef: PaymentBatchPaymentsGridComponent): ColDef =>  ({
    headerName: 'Transmittal Package',
    field: 'transmittalPackageNum',
    width: AgGridColumns.numericColumnWidth,
    filter: 'agNumberColumnFilter',
    filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
    hide: true,
    valueFormatter: (params) => {
        const model = params.data as Core.PaymentBatchDetailsEntryModel;
        if (!model) {
            return;
        }

        return classRef.paymentBatch.batchType !== Core.PaymentBatchTypeEnum.Transmittal ? ' ' : model.transmittalPackageNum;
    },
    cellRendererFramework: AgGridLinkCellRenderer,
    cellRendererParams: {
        getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-transmittal',
        onClickAsync: async (params: AgGridLinkCellRendererParams) => {
            const model = params.data as Core.PaymentBatchDetailsEntryModel;
            if (!model) {
                return;
            }

            const busyRef = classRef.busyIndicatorService.show({message: 'Loading Transmittal...'});
            let paymentPackage: PaymentPackage = null;
            try {
                paymentPackage = await classRef.transmittalService.GetPaymentPackageByID(model.paymentPackageId);
                if (paymentPackage) {
                    classRef.transmittalService.showPaymentPackageReviewModal(paymentPackage);
                }
            } finally {
                busyRef.hide();
            }
        }
    }  as AgGridLinkCellRendererParams,
});
