import { Component, Input, HostListener, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { HelpContent } from './help.interface';

interface TooltipPosition {
    tooltipLeft: string;
    tooltipTop: string;
    pointerLeft: string;
    pointerTop: string;
}

export interface TooltipTargetPosition {
    position: string;
    forcePosition?: boolean;
    boundingBox: DOMRect;
    edgeBuffer?: string;
}

@Component({
    selector: 'help-tooltip',
    template: `
    <div #tooltip class="help-tooltip"
        [ngClass]="position"
        [class.closing]="opacity === 0"
        [style.opacity]="opacity"
        [style.top]="tooltipPosition?.tooltipTop"
        [style.left]="tooltipPosition?.tooltipLeft">
        <div class="help-tooltip-body">
            <p class="help-text m-0 p-0" *ngIf="helpContent?.tooltipText">{{ helpContent?.tooltipText }}</p>
            <div class="help-link" *ngIf="helpContent?.url">
                <a [attr.href]="helpContent.url" target="_blank">
                    <span class="link-icon"><i class="fa fa-external-link"></i></span>
                    <span class="link-text">Learn More</span>
                </a>
            </div>
            <div #componentTarget></div>
            <i class="pointer" [style.top]="tooltipPosition?.pointerTop" [style.left]="tooltipPosition?.pointerLeft"></i>
        </div>
    </div>
    `,
    styleUrls: ['./help-tooltip.component.scss']
})
export class HelpTooltipComponent {
    constructor(private readonly _renderer: Renderer2, private readonly _changDetectorRef: ChangeDetectorRef) { }

    @Input() helpContent: HelpContent;
    @Input() opacity: number = 0;

    @Input()
    set targetPosition(target: TooltipTargetPosition) {
        this.position = target.position;
        this._targetPosition = target.boundingBox;
        this._forcePosition = target.forcePosition;
        this._edgeBuffer = this._getEdgeBuffer(target.edgeBuffer);
        this.updateTooltipPosition();
    }

    @Input()
    set componentToRender(component: HTMLElement) {
        if (component) {
            this.removeComponent();
            this.providedComponent = component;
            this._renderer.appendChild(this.componentTarget.nativeElement, component);
        }
    }

    @Output() isHovered: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('tooltip', { static: true }) tooltip: ElementRef;

    @ViewChild('componentTarget', { static: true }) componentTarget: ElementRef;

    @HostListener('mouseenter') onMouseEnter() {
        this.isHovered.emit(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.isHovered.emit(false);
    }

    @HostListener('window:scroll') onWindowScroll(): void {
        this.tooltipPosition = this._setTooltipPosition(this.position);
    }

    position: string;
    tooltipPosition: TooltipPosition;
    providedComponent: HTMLElement;

    private _edgeBuffer = 10;
    private _targetPosition: DOMRect;
    private _forcePosition: boolean;


    removeComponent(): void {
        if (this.providedComponent) {
            this._renderer.removeChild(this.componentTarget.nativeElement, this.providedComponent);
            this.providedComponent = null;
        }
    }

    updateTooltipPosition() {
        this.tooltipPosition = this._setTooltipPosition(this.position);
        this._changDetectorRef.detectChanges();
    }

    /**
     * Set the tooltip position relative to the container
     * @param position
     */
    private _setTooltipPosition(position: string): TooltipPosition {
        switch (position) {
            case 'bottom':
                return this._calculateBottom();
            case 'top':
                return this._calculateTop();
            case 'left':
                return this._calculateLeft();
            case 'right':
                return this._calculateRight();
        }
    }

    /**
     * Calculate bottom positioning
     * @param width
     * @param height
     */
    private _calculateBottom(): TooltipPosition {
        this._preRender('bottom');

        const boundingBox = this.tooltip.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY + this._targetPosition.height - 1;
        const left = this._targetPosition.x - Math.floor((boundingBox.width - this._targetPosition.width) / 2);

        const buffer = this.getBuffer(boundingBox, top, left);

        if (buffer.topBuffer < 0 && !this._forcePosition) {
            this.position = 'top';
            return this._setTooltipPosition('top');
        }

        const pointerLeft = Math.floor(boundingBox.width / 2) - buffer.pointerXBuffer;

        return {
            tooltipLeft: `${left + buffer.leftBuffer}px`,
            tooltipTop: `${top}px`,
            pointerLeft: `${pointerLeft}px`,
            pointerTop: null
        };
    }

    /**
     * Calculate top positioning
     * @param width
     * @param height
     */
    private _calculateTop(): TooltipPosition {
        this._preRender('top');

        const boundingBox = this.tooltip.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY - boundingBox.height;
        const left = this._targetPosition.x - Math.floor((boundingBox.width - this._targetPosition.width) / 2);

        const buffer = this.getBuffer(boundingBox, top, left);

        if (buffer.topBuffer > 0 && !this._forcePosition) {
            this.position = 'bottom';
            return this._setTooltipPosition('bottom');
        }

        const pointerLeft = Math.floor(boundingBox.width / 2) - buffer.pointerXBuffer;
        return {
            tooltipLeft: `${left + buffer.leftBuffer}px`,
            tooltipTop: `${top}px`,
            pointerLeft: `${pointerLeft}px`,
            pointerTop: null
        };
    }

    /**
     * Calculate left positioning
     * @param width
     * @param height
     */
    private _calculateLeft(): TooltipPosition {
        this._preRender('left');

        const boundingBox = this.tooltip.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY - Math.floor((boundingBox.height - this._targetPosition.height) / 2);
        const left = this._targetPosition.x - boundingBox.width;

        const buffer = this.getBuffer(boundingBox, top, left);

        if (buffer.leftBuffer > 0 && !this._forcePosition) {
            this.position = 'right';
            return this._setTooltipPosition('right');
        }

        const pointerTop = (boundingBox.height / 2) - buffer.pointerYBuffer;
        return {
            tooltipLeft: `${left}px`,
            tooltipTop: `${top + buffer.topBuffer}px`,
            pointerLeft: null,
            pointerTop: `${pointerTop}px`
        };
    }

    /**
     * Calculate right positioning
     * @param width
     * @param height
     */
    private _calculateRight(): TooltipPosition {
        this._preRender('right');

        const boundingBox = this.tooltip.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + window.scrollY - Math.floor((boundingBox.height - this._targetPosition.height) / 2);
        const left = this._targetPosition.x + this._targetPosition.width;

        const buffer = this.getBuffer(boundingBox, top, left);

        if (buffer.leftBuffer < 0 && !this._forcePosition) {
            this.position = 'left';
            return this._setTooltipPosition('left');
        }

        const pointerTop = (boundingBox.height / 2) - buffer.pointerYBuffer;

        return {
            tooltipLeft: `${left}px`,
            tooltipTop: `${top + buffer.topBuffer}px`,
            pointerLeft: null,
            pointerTop: `${pointerTop}px`
        };
    }

    /**
     * Check distance to edge of screen
     * @param position
     * @param axisToTest true = x, false = y
     * @param offset
     */
    private getBuffer(element: DOMRect, idealTop: number, idealLeft: number): any {
        const wY = window.innerHeight;
        const wX = window.innerWidth;

        const closestXEdgeDistance = (idealLeft < (wX - (idealLeft + element.width))) ? idealLeft : (wX - (idealLeft + element.width));
        const closestYEdgeDistance = (idealTop < (wY - (idealTop + element.height))) ? idealTop : (wY - (idealTop + element.height));
        const isXAdditive = idealLeft < (wX - (idealLeft + element.width));
        const isYAdditive = idealTop < (wY - (idealTop + element.height));

        let xBuffer = 0;
        if (closestXEdgeDistance < this._edgeBuffer) {
            xBuffer = this._edgeBuffer - closestXEdgeDistance;
            xBuffer = (isXAdditive) ? xBuffer : -xBuffer;
        }
        let yBuffer = 0;
        if (Math.abs(closestYEdgeDistance) < this._edgeBuffer) {
            yBuffer = this._edgeBuffer - closestYEdgeDistance;
            yBuffer = (isYAdditive) ? yBuffer : -yBuffer;
        }

        return {
            leftBuffer: xBuffer,
            topBuffer: yBuffer,
            pointerXBuffer: xBuffer + 12,
            pointerYBuffer: yBuffer + 12,
        };
    }

    /**
     * Get position calculations correct when close to the right edge of the screen
     */
    private _preRender(position: string): TooltipPosition {
        this.tooltipPosition = {
            tooltipLeft: `${window.innerWidth / 2 - 130}px`,
            tooltipTop: '10px',
            pointerLeft: (position === 'top' || position === 'bottom') ? '12px' : null,
            pointerTop: (position === 'left' || position === 'right') ? '12px' : null
        };
        this._changDetectorRef.detectChanges();
        return this.tooltipPosition;
    }

    private _getEdgeBuffer(edgeBuffer: string): number {
        if (edgeBuffer.includes('%')) {
            return Math.floor(window.innerWidth * (parseInt(edgeBuffer.replace('%', ''), 10) / 100));
        }
        return parseInt(edgeBuffer, 10);
    }
}
