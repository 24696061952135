import { BaseRepository } from '../Common/Data/base.repository';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class AppealRecommendationRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly _baseUrl: string = 'api/appealRecommendation/';

    getTemplate(model: Core.AppealRecommendationTemplateRefreshModel): Observable<Core.AppealRecommendationTemplateModel> {
        return this.httpPost(`${this._baseUrl}template`, model);
    }

    getFields(): Observable<Core.TemplateFieldDTO[]> {
        return this.httpGet(`${this._baseUrl}fields`);
    }

    refresh(model: Core.AppealRecommendationTemplateRefreshModel): Observable<Core.AppealRecommendationTemplateRefreshResultModel> {
        return this.httpPost(`${this._baseUrl}refresh`, model);
    }

    getAvailableRecipients(taskIds: number[]): Observable<Core.AppealRecipientModel[]> {
        return this.httpPost(`${this._baseUrl}availableRecipients`, taskIds);
    }

    download(model: Core.AppealRecommendationEmailModel): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpPost(`${this._baseUrl}download`, model, options);
    }

    async send(model: Core.AppealRecommendationEmailModel): Promise<void> {
        return lastValueFrom(this.httpPost(`${this._baseUrl}send`, model));
    }

    getList(model: Core.AppealRecommendationCCSearchModel): Observable<Core.AppealRecommendationGridDataModel> {
        return this.httpPost(`${this._baseUrl}list`, model);
    }
    getDescriptorMetadata(): Observable<Core.DescriptorMetadataModel> {
        return this.httpGet(`${this._baseUrl}descriptorMetadata`);
    }

    updateDescriptor(updateModel: Core.AppealRecommendationCommandCenterUpdateDescriptorModel): Observable<Core.AppealRecommendationCommandCenterUpdateDescriptorResultModel> {
        return this.httpPost(`${this._baseUrl}updateDescriptor`, updateModel);
    }

    updateTargetValue(updateModel: Core.AppealRecommendationCommandCenterUpdateTargetValueModel): Observable<void> {
        return this.httpPost(`${this._baseUrl}updateTargetValue`, updateModel);
    }

    startExport(model: Core.AppealRecommendationCCExportModel): Observable<number> {
        return this.httpPost(`${this._baseUrl}export`, model);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this._baseUrl}export/${longRunningProcessId}`, options);
    }

    getAppealRecommendationStatuses(): Observable<Core.AppealRecommendationStatusModel[]> {
        return this.httpGet(`${this._baseUrl}appealRecommendationStatuses`);
    }

    updateAppealRecommendationStatus(updateModel: Core.UpdateAppealRecommendationStatusModel): Observable<void> {
        return this.httpPost(`${this._baseUrl}updateAppealRecommendationStatus`, updateModel);
    }

    updateSiteAppealRecommendationIsClientApproved(updateModel: Core.UpdateSiteAppealRecommendationIsClientApprovedModel): Observable<void> {
        return this.httpPost(`${this._baseUrl}updateSiteAppealRecommendationIsClientApproved`, updateModel);
    }

    getTemplatesAsync(taskIds: number[]): Observable<Core.AppealRecommendationEmailTemplateModel[]> {
        return this.httpPost(`${this._baseUrl}emailTemplates`, taskIds);
    }

    saveTemplate(saveTemplateModel: Core.AppealRecommendationEmailTemplateUpdateModel): Observable<Core.AppealRecommendationEmailTemplateModel> {
        return this.httpPost(`${this._baseUrl}saveTemplate`, saveTemplateModel);
    }

    deleteTemplate(appealRecommendationEmailTemplateId: number): Observable<void> {
        return this.httpDelete(`${this._baseUrl}deleteTemplate/${appealRecommendationEmailTemplateId}`);
    }

    bulkUpdate(model: Core.AppealRecommendationBulkUpdateModel): Observable<Core.AppealRecommendationBulkUpdateResultModel> {
        return this.httpPost(`${this._baseUrl}bulkUpdate`, model);
    }
}
