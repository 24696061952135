<warning-message [show]="!isDefault && !isReadOnlyMode" [(showDetails)]="showMessageDetails" message="Some returns are using different forms than the default setup" [hasDetails]="true">
    <div class="error-body">
        <div class="ws-flex-container-vertical ws-stretch">
            <span class="error-description">If you reset to default, the following changes will be applied:</span>
            <div class="error-container">
                <div *ngFor="let customReturn of customReturns; trackBy:trackByReturnId">
                    <div class="form-error">
                        <span class="form-name">{{ customReturn.parcelAcctNum }}:</span>
                    </div>
                    <ul>
                        <li *ngFor="let customForm of customReturn.customForms; trackBy:trackByReturnId" (click)="toggleReports(customForm)">
                            <div class="form-error" [class.can-dropdown]="customForm.customReports.length">
                                <i *ngIf="customForm.customReports.length" class="fa fa-caret-right show-details" [class.open]="customForm['showReports']"></i>
                                <span class="form-name">
                                    <i class="state-icon fa"
                                       [ngClass]="getIconClass(customForm.action)"
                                       helpTooltip
                                       [tooltipText]="getIconTitle(customForm.action)"
                                       position="bottom"></i>
                                    <i class="fa fa-file-pdf-o"></i>
                                    {{ customForm.name }}
                                </span>
                            </div>
                            <div *ngIf="customForm.customReports.length" class="report-dropdown" [class.open]="customForm['showReports']">
                                <ul>
                                    <li *ngFor="let customReport of customForm.customReports; trackBy:trackByReturnId">
                                        <span>
                                            <i class="state-icon fa"
                                               [ngClass]="getIconClass(customReport.action)"
                                               helpTooltip
                                               [tooltipText]="getIconTitle(customReport.action)"
                                               position="bottom"></i>
                                            <i class="fa fa-file-text-o"></i>
                                            {{ customReport.name + ' ' + customForm.name }}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="reset-default">
                <button class="flat-button primary-button" (click)="showConfirmation()">Reset to Default</button>
            </div>
        </div>
    </div>
</warning-message>
