import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const ATTACHMENT_MODAL_HELP: HelpContent[] = [
    {
        helpContentId: 'attachment-modal.show-list',
        tooltipText: 'Show Attachment List'
    },
    {
        helpContentId: 'attachment-modal.hide-list',
        tooltipText: 'Hide Attachment List'
    }
];
