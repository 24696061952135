import { AceUIIcon } from '@ace/shared';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { iconSvgs } from './iconLib';

@Component({
    selector: 'ws-ace-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AceIconComponent implements OnInit, AfterViewInit {
    @Input() slot: string; // used for styling from ace styles
    @Input() size: string;
    @Input() iconFont: string;

    @HostBinding('attr.color')
    @Input() state: string;

    @HostBinding('attr.glyph')
    @Input() glyph: AceUIIcon | string;

    @ViewChild('glyphContainer') glyphContainer: ElementRef;

    style: any;
    private _aceDxpIconMap = {
        'arrow-left': 'Arrow-Data-Back',
        'arrow-right': 'Arrow-Data-Next',
        'arrow-up': 'Arrow-Data-Up',
        'arrow-up-down': 'Arrow-Data-No_Effect',
        'arrow-down': 'Arrow-Data-Down',
        'arrow-outline-up': 'Arrow-Reorder-Up',
        'arrow-outline-down': 'Arrow-Reorder-Down',
        'atom': 'Labs-Molecule',
        'bars': 'Interface-Hamburger_Menu',
        'bell': 'Alerts-Notification_Bell',
        'bell-filled': 'Alerts-Notification_Full',
        'briefcase': 'Office-Briefcase',
        'cog': 'Interface-Cog',
        'calendar': 'Interface-Calendar',
        'camera': 'Devices-Camera',
        'chart': 'Project-Default',
        'caret-down': 'Caret-Down',
        'caret-up': 'Caret-Up',
        'chevron-left': 'Chevron-Left',
        'chevron-right': 'Chevron-Right',
        'chevron-down': 'Chevron-Down',
        'check': 'Success-Check',
        'circle-empty': 'To-Do',
        'circle-filled': 'Completed',
        'clock': 'Clock',
        'columns': 'Interface-Layout_Column',
        'crop': 'Graphics-Crop',
        'database': 'Devices-Database',
        'do-not-enter': 'Danger-Null',
        'dollar-sign': 'Currency-Dollar',
        'download': 'Interface-Download',
        'duplicate': 'Duplicate',
        'earth': 'Earth',
        'error-filled': 'Error-Filled',
        'exclamation': 'Alert',
        'exclamation-triangle': 'Alerts-Warning_Outline',
        'eye-slash': 'Visibility-Hide',
        'file': 'File-Info',
        'filter': 'Filter',
        'generic': 'Generic-Info',
        'grid': 'Interface-Grid',
        'grip-vertical': 'Interface-Drag_Drop',
        'hands': 'Interface-Cog_Hand',
        'hammer': 'Interface-Hammer',
        'home': 'Interface-Home',
        'info-circle': 'Alerts-Information',
        'link': 'Interface-Link_Default',
        'mail': 'Mail',
        'mobile': 'Devices-Mobile_Device',
        'minus-circle': 'Add_Remove-Subtract_Circle',
        'minus-thick': 'Minus-Thick',
        'minus-thin': 'Minus-Thin',
        'more-outline': 'Interface-More_Meatballs',
        'more-vertical': 'Interface-More_Vertical',
        'network': 'Devices-Server',
        'none': 'Danger-Null',
        'paint-brush': 'Graphics-Paintbrush',
        'palette': 'Graphics-Palette',
        'paste': 'Interface-Copy_Paste',
        'pen': 'Interface-Pencil',
        'plug': 'Devices-Charger',
        'plus': 'Add_Remove-Plus_Thick',
        'print': 'Office-Print',
        'question': 'Alerts-Help',
        'redo': 'Interface-Redo',
        'search': 'Interface-Search',
        'show': 'Show',
        'sign-out': 'User-Logout',
        'sparkles': 'Labs-Diamond',
        'spinner': 'Status-Loading',
        'success-filled': 'Success-Filled',
        'success-outline': 'Success-Outline',
        'sync': 'Interface-Refresh',
        'thumbs-down': 'Social-Dislike',
        'thumbs-up': 'Social-Like',
        'times': 'Danger-Close_X',
        'times-circle': 'Danger-Error_Outline',
        'tools': 'Interface-Tools',
        'trash': 'Interface-Trash',
        'undo': 'Interface-Undo',
        'upload': 'Interface-Upload',
        'user': 'User-Info',
        'user-circle': 'User-Account_Avatar',
        'vial': 'Labs-Lab_Flask',
        'window-restore': 'Interface-Expand'
    };

    ngOnInit(): void {
        this.style = this.size ? { '--icon-size': `${this.size}rem` } : {};
    }

    ngAfterViewInit() {
        if (this.glyph) {
            this._renderAceSvgIcon(this.glyph);
        }
    }

    private _renderAceSvgIcon(glyph: AceUIIcon | string): void {
        const key: string = this._aceDxpIconMap[glyph];
        this.glyphContainer.nativeElement.innerHTML = key ? this._addRandomId(iconSvgs[key]) : '';
    }

    private _addRandomId(svgStr?: string): string {
        const uniqueKey: string = `${Math.random()  }_`;
        const randomizedIdStr = (svgStr || '').replace(/ id="([^"]*)"/g, ([, group]) => ` id="${  uniqueKey  }${group  }"`);
        return randomizedIdStr.replace(/href="#([^"]*)"/g, ([, group]) => `href="#${  uniqueKey  }${group  }"`);
    }
}
