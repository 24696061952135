<ws-busy-indicator *ngIf="isBusy" [message]="busyMessage"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Appeal Forms"
                  subHeaderLabel="Check the forms you wish to add to appeals and uncheck the ones you wish to remove."
                  acceptLabel="OK"
                  (cancel)="cancel()"
                  (save)="save()"
                  [disableSave]="!hasChanges">

    <div class="ws-flex-container-horizontal ws-flex-align-items-center" style="margin-bottom: 10px;">
        <div class="ws-flex-none">Apply changes to:</div>
        <div class="ws-flex-none" style="padding-left: 8px;">
            <select class="form-select form-select-sm"
                    [(ngModel)]="selectedApplyChangesScopeOption"
                    (change)="onSelectedApplyChangesScopeFilterChange()">
                <option *ngFor="let item of applyChangesScopeOptions" [ngValue]="item">{{ item.value }}</option>
            </select>
        </div>
    </div>
    <span *ngIf="!formRevisionAssociations.length && !isBusy">There are no supplemental appeals available.</span>
    <table *ngIf="formRevisionAssociations.length" class="table table-condensed">
        <tbody>
        <tr *ngFor="let item of formRevisionAssociations">
            <td class="col-lg-1">
                <label class="small">
                    <input type="checkbox"
                           [ngModel]="item.isAssociated"
                           (ngModelChange)="onFormSelectedChange(item)">
                </label>
            </td>
            <td class="col-lg-1">{{ item.name }}</td>
            <td class="col-lg-8" [ngClass]="{ 'text-success': item.hasChanges && item.isSelected === true, 'text-danger': item.hasChanges && item.isSelected === false }">{{ getMessage(item) }}</td>
        </tr>
        </tbody>
    </table>
</ws-modal-wrapper>
