import { Injectable } from "@angular/core";
import { SDHttpService } from "../../Routing/sd-http.service";
import { EntityAddress, CorrespondenceType } from "./address.model";

@Injectable(
    { providedIn: 'root' }
)
export class AddressDetailsModalService {
    constructor(private http: SDHttpService) { }

    getEntityAddresses(entityID: number, entityTypeID: number): Promise<EntityAddress[]> {
        let entityString: string = this.getEntityString(entityTypeID);

        return this.http.get(`/api/${entityString}/EntityAddresses/${entityID}`);
    }

    getCorrespondenceTypes(entityTypeId: number): Promise<CorrespondenceType[]> {
        return this.http.get(`/api/correspondenceType/entityType/${entityTypeId}`)
    }

    updateEntityAddresses(entityAddresses: EntityAddress[], entityTypeID: number): Promise<EntityAddress[]> {
        let entityString: string = this.getEntityString(entityTypeID);

        return this.http.put(`/api/${entityString}/EntityAddresses`, entityAddresses)
    }

    getEntityAddressDeliverability(entityAddress: EntityAddress, entityTypeID: number): Promise<Core.UpmsValidatedAddressDto> {
        let entityString: string = this.getEntityString(entityTypeID);

        return this.http.post(`/api/${entityString}/EntityAddress/Deliverability`, entityAddress)
    }

    private getEntityString(entityTypeID: number): string {
        switch(entityTypeID) {
            case 1:
                return 'company';
            case 3:
                return 'assessor';
            case 4:
                return 'collector';
        }
    }
}
