<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" style="cursor: pointer;" (click)="expandOrCollapse()" [ngClass]="{'disable-pointer-events': !hasViewPermission}">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Invoices</h3>
    </div>
    <div class="card-body" *ngIf="showBody" style="padding-top: 0;">
        <div style="float: right;" *ngIf="!siteId">
            <label style="position: relative; margin-right: 4px;">RIBS Due Date Range: </label>
            <div class="input-group" style="display:inline-block; width: 138px; margin-top: 12px;">
                <weissman-datetime [(inputDate)]="fromDate"
                                   [dateOnly]="true"
                                   [timeZone]="'Central'"
                                   #startDatePicker>
                </weissman-datetime>
            </div>
            <span style="margin: 0 6px; position: relative;">to</span>
            <div class="input-group" style="display:inline-block; width: 138px; margin-top: 12px;">
                <weissman-datetime [(inputDate)]="toDate"
                                   [dateOnly]="true"
                                   [timeZone]="'Central'"
                                   [rangeEnd]="true"
                                   #endDatePicker>
                </weissman-datetime>
            </div>
            <button class="flat-button small-button primary-button"
                    (click)="loadPanel()">
                Refresh
            </button>
        </div>
        <div class="text-center" style="margin-top: 50px;" *ngIf="isLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="!isLoading" style="clear: right;">
            <!--Dima: scrollable table, implemented based on samples from http://salzerdesign.com/test/fixedTable.html-->
            <div class="fixed-table-container hidden-head">
                <div class="header-background"> </div>
                <div class="fixed-table-container-inner">
                    <table class="table table-condensed table-hover">
                        <thead>
                        <tr>
                            <th>
                                <div class="th-inner">RIBS Due Date</div>
                            </th>
                            <th *ngIf="!siteId && showSiteColumn">
                                <div class="th-inner">Site</div>
                            </th>
                            <th>
                                <div class="th-inner">Contract Type</div>
                            </th>
                            <th>
                                <div class="th-inner">Tax Year</div>
                            </th>
                            <th>
                                <div class="th-inner">RIBS Request #</div>
                            </th>
                            <th>
                                <div class="th-inner">Invoice Amt</div>
                            </th>
                            <th>
                                <div class="th-inner">Status</div>
                            </th>
                        </tr>
                        <tr class="hidden-header">
                            <th>
                                <div class="th-inner">RIBS Due Date</div>
                            </th>
                            <th *ngIf="!siteId && showSiteColumn">
                                <div class="th-inner">Site</div>
                            </th>
                            <th>
                                <div class="th-inner">Contract Type</div>
                            </th>
                            <th>
                                <div class="th-inner">Tax Year</div>
                            </th>
                            <th>
                                <div class="th-inner">RIBS Request #</div>
                            </th>
                            <th>
                                <div class="th-inner">Invoice Amt</div>
                            </th>
                            <th>
                                <div class="th-inner">Status</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody style="overflow-y: scroll">
                        <tr *ngFor="let invoice of this.invoices" class="pointer" (click)="rowSelected(invoice)">
                            <td *ngIf="!invoice.dueDate"></td>
                            <td *ngIf="invoice.dueDate">{{invoice.dueDate | weissmanDateFormat}}</td>
                            <td *ngIf="!siteId && showSiteColumn">{{invoice.siteName}}</td>
                            <td>
                                {{invoiceService.contractServiceName(invoice.contractServiceType)}}
                                <span *ngIf="invoice.contractServiceType == ContractServiceType.Appeal && invoice.isAdHoc"> (ad hoc)</span>
                            </td>
                            <td>{{invoice.annualYear}}</td>
                            <td>{{invoice.invoiceNumber ? invoice.invoiceNumber : 'Draft'}}</td>
                            <td>{{invoice.invoiceAmount | currency:'USD'}}</td>
                            <td><div><a style="cursor:pointer" (click)="launchTaskModal($event, invoice)">{{ invoice.taskSummary[0] ? invoice.taskSummary[0].status : ''}}</a></div></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style="display: flex; gap: 5px;">
                <button *ngIf="!creatingAdminInvoices && allowEdit" class="flat-button" (click)="createAdHocInvoice()">Create Invoice</button>
                <button *ngIf="siteId && allowEdit && parcelsReadyForInvoiceCount" class="flat-button" (click)="createInvoices()">Invoice {{parcelsReadyForInvoiceCount}} Parcel Invoice Appeal Savings Task(s)</button>
                <button *ngIf="!siteId && allowEdit && !creatingAdminInvoices && sitesReadyForInvoiceCount" class="flat-button" (click)="startCreateAdminInvoices()">Create Site Admin Fee Invoices</button>
            </div>
            <div *ngIf="creatingAdminInvoices">
                <label style="margin-right:1em;margin-left:0.5em;">
                    <span>Annual Year:</span>
                    <select [(ngModel)]="annualYear">
                        <option *ngFor="let item of years">{{item}}</option>
                    </select>
                </label>
                <button class="flat-button small-button primary-button" (click)="createAdminInvoices()">Create</button>
                <button class="flat-button small-button warning-button" (click)="cancelCreateAdminInvoices()">Cancel</button>
            </div>
        </div>
    </div>
</div>
