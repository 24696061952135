import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

export interface LongRunningProcessListGridErrorGuidCellRendererParams extends ICellRendererParams {
    showErrorDetails: (errorGuid: string) => void;
}
@Component({
    selector: 'grid-error-guid-cell',
    template:
        `<a
            *ngIf="showLink"
            class="grid-link-cell"
            (click)="params.showErrorDetails(errorGuid)"
        >{{error}}</a>
        <span *ngIf="!showLink">{{error}}</span>`
})
export class LongRunningProcessListGridErrorGuidCellRendererComponent implements ICellRendererAngularComp {
    params: LongRunningProcessListGridErrorGuidCellRendererParams;
    showLink: boolean;
    error: string;
    errorGuid: string;
    agInit(params: LongRunningProcessListGridErrorGuidCellRendererParams): void {
        this.params = params;
        this.error = params?.data?.error;
        this.errorGuid = params?.data?.errorGuid;
        this.showLink = !! this.errorGuid
    }

    refresh(params: ICellRendererParams): boolean {
        return false; // tells grid to handle refreshing
    }
}
