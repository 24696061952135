import { lastValueFrom } from 'rxjs';
import { EntityImportRepository } from '../../Repositories';
import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';

export interface EntityImportListDataSourceParams {
    isCompanyImport: boolean;
    includeCompleted: boolean;
    companyId: number;
}

export class EntityImportListGridDataSource extends AgGridDataSourceBase<EntityImportListDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _entityImportRepository: EntityImportRepository,
        protected _dataSourceParamsFn: () => EntityImportListDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.ImportFilePropertyEnum>[] = [
        { name: 'displayName', value: Compliance.ImportFilePropertyEnum.DisplayName },
        { name: 'fileName', value: Compliance.ImportFilePropertyEnum.FileName },
        { name: 'createDate', value: Compliance.ImportFilePropertyEnum.CreateDate },
        { name: 'importContentType.name', value: Compliance.ImportFilePropertyEnum.ImportContentTypeName },
        { name: 'transferredRows', value: Compliance.ImportFilePropertyEnum.TransferredRows },
        { name: 'ownerName', value: Compliance.ImportFilePropertyEnum.OwnerName }
    ];

    protected canGetRows(): boolean {
        return !this._dataSourceParams.isCompanyImport || !!this._dataSourceParams.companyId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.ImportSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeCompleted: this._dataSourceParams.includeCompleted
        };

        let result: Compliance.QueryResultModel<Compliance.ImportFileModel>;

        if (this._dataSourceParams.isCompanyImport){
            result = await lastValueFrom(this._entityImportRepository.getByCompany(this._dataSourceParams.companyId, searchParams));
        } else{
            result = await lastValueFrom(this._entityImportRepository.getSystem(searchParams));
        }

        return {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };
    }
}
