import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { DescriptorService } from './descriptor.service';
import * as _ from 'lodash';

@Component({
    selector: 'descriptor-picker',
    template: `
        <input [(ngModel)]="selectedDescriptorName"
            [typeahead]="descriptors"
            typeaheadOptionField="descriptorName"
            typeaheadGroupField="categoryName"
            (typeaheadOnSelect)="onSelect($event)"
            [disabled]="disabled"
            class="form-control">
    `
})
export class DescriptorPickerComponent implements OnInit {
    @Input() companyId: number;
    @Input() entityTypeId: number;
    @Input() descriptorId: number;
    @Input() disabled: boolean;
    @Output() selected: EventEmitter<Core.AvailableDescriptorModel> = new EventEmitter();
    descriptors: Core.AvailableDescriptorModel[];
    selectedDescriptorName: string;

    constructor(private descriptorService: DescriptorService) {}

    async ngOnInit() {
        this.descriptors = (await this.descriptorService.getAvailable(this.companyId, this.entityTypeId)).descriptors;

        if(this.descriptorId) {
            const selectedDescriptor = _.find(this.descriptors, {descriptorId: this.descriptorId});
            if(selectedDescriptor) {
                this.selectedDescriptorName = selectedDescriptor.descriptorName;
            } else {
                console.warn('Descriptor not found!')
            }
        }
    }

    onSelect(e: TypeaheadMatch) {
        const descriptor = e.item as Core.AvailableDescriptorModel;
        this.selected.emit(descriptor);
    }

}
