import { forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { FormsModule } from '@angular/forms';
import { CompanyReturnSettingsPanelComponent } from './Company-Return-Settings-Panel/companyReturnSettingsPanel.component';
import { ReturnModule } from '../Return/return.module';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent
        ]),
        ReturnModule
    ],
    declarations: [
        CompanyReturnSettingsPanelComponent
    ],
    providers: [],
    exports: [
        CompanyReturnSettingsPanelComponent
    ]
})
export class ComplianceCompanyModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('complianceCompanyReturnSettingsPanel', CompanyReturnSettingsPanelComponent);
    }
}
