const confirmMessage: string = 'Are you sure you want to cancel and discard all changes?';

export abstract class ModalComponentBase {
    protected abstract isDirty(): boolean;
    protected abstract close(): void;
    public closeWithDirtyCheck(): void {
        if (this.isDirty()) {
            if (window.confirm(confirmMessage)) {
                this.close();
            }
        } else {
            this.close();
        }
    }
}