import { HelpContentComponent, HelpContentRendererValue } from '../../../../UI-Lib/Help-Tooltip';
import { Component } from '@angular/core';
import { AssessmentSummaryInfo } from '../assessmentSummaryInfo';
import { Decimal } from 'decimal.js';
import * as _ from 'lodash';

export interface AllocationAssessmentSummaryComponentParams extends HelpContentRendererValue<AssessmentSummaryInfo> {
    summary: AssessmentSummaryInfo,
    components: Compliance.AllocationAssessmentSummaryComponentModel[];
}

@Component({
    selector: 'allocation-assessment-summary-components',
    template: `
        <div class="ws-flex-auto ws-flex-container-vertical">
            <div class="parcel-info">
                <table>
                    <tr *ngIf="params.summary.parcelAccountNumber2">
                        <td class="title">Parcel #2:</td>
                        <td class="info">{{params.summary.parcelAccountNumber2}}</td>
                    </tr>
                    <tr *ngIf="params.summary.description">
                        <td class="title">Desc:</td>
                        <td class="info">{{params.summary.description}}</td>
                    </tr>
                    <tr *ngIf="situsLinesNumber">
                        <td [attr.rowspan]="situsLinesNumber" class="title">Situs:</td>
                        <td class="info">{{situsLine1}}</td>
                    </tr>
                    <tr>
                        <td class="info">{{situsLine2}}</td>
                    </tr>
                    <tr>
                        <td class="info">{{situsLine3}}</td>
                    </tr>
                </table>
            </div>
            <div class="assessments" style="padding-right: 10px;">
                <div class="assess">
                    <table class="assess-table table-striped">
                        <thead>
                        <tr>
                            <th>
                                <span>Component</span>
                            </th>
                            <th>
                                <span>AV</span>
                            </th>
                            <th>
                                <span>Ratio (%)</span>
                            </th>
                            <th>
                                <span>FMV</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let component of params.components" class="annual-assessment-row">
                            <td>{{component.name}}</td>
                            <td class="assessed-value">{{component.av | number:"1.0-0"}}</td>
                            <td class="assessment-ration">{{component.ratio * 100 | number:"1.3-3"}}%</td>
                            <td>{{component.fmv | number:"1.0-0"}}</td>
                        </tr>
                        <tr class="total">
                            <td>Total</td>
                            <td>{{getTotal('av') | number:'1.0-0'}}</td>
                            <td>&nbsp;</td>
                            <td>{{getTotal('fmv') | number:'1.0-0'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
`
})

export class AllocationAssessmentSummaryComponentComponent implements HelpContentComponent<AllocationAssessmentSummaryComponentParams>{
    constructor() {
    }

    params: AllocationAssessmentSummaryComponentParams;

    helpInit(params: AllocationAssessmentSummaryComponentParams): void {
    }

    getTotal (column): number {
        return _.reduce(this.params.components, (sum, component) => new Decimal(sum).plus(Number(component[column])).toNumber(), 0);
    }

    get situsLinesNumber(): number {
        let result = 0;

        if (this.params.summary.parcelAddress1) {
            result += 1;
        }

        if (this.params.summary.parcelAddress2) {
            result += 1;
        }

        if (this.params.summary.parcelCity || this.params.summary.parcelZip) {
            result += 1;
        }

        return result;
    }

    get situsLine3(): string {
        let result = '';

        if (this.params.summary.parcelCity){
            result = this.params.summary.parcelCity;
        }

        if (this.params.summary.parcelZip){
            result += `${result ? ', ' : ''}${this.params.summary.parcelState} ${this.params.summary.parcelZip}`;
        }

        return result;
    }

    get situsLine1(): string {
        return this.params.summary.parcelAddress1 || this.params.summary.parcelAddress2 || this.situsLine3;
    }

    get situsLine2(): string {
        return this.params.summary.parcelAddress2 || this.situsLine3;
    }
}
