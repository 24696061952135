import { Component, Input, OnInit } from '@angular/core';
import { ContractsInvoicesService } from './contract-invoices.service';
import { InvoiceRecipient } from './contract-invoices.model';
import { Invoice } from '../../Processing/Invoice/invoice.model';
import * as _ from 'lodash';

@Component({
    selector: 'invoice-recipient-lookup',
    templateUrl: './invoice-recipient-lookup.component.html'
})
export class InvoiceRecipientLookupComponent implements OnInit {
    @Input() invoice: Invoice;
    @Input() editMode: boolean;

    recipients: InvoiceRecipient[] = [];
    selectedRecipient: InvoiceRecipient;

    constructor(private contractsInvoicesService: ContractsInvoicesService) { }

    ngOnInit() {
        if(this.invoice.invoiceRevenueShareCodes[0] && this.invoice.invoiceRevenueShareCodes[0].engagementNum) {
            this.getRecipientList();
        }
    }

    getRecipientList(): void {
        this.contractsInvoicesService.getInvoiceRecipientsByEngagement(this.invoice.invoiceRevenueShareCodes[0].engagementNum)
            .then((result: InvoiceRecipient[]) => {
                this.recipients = result;

                this.setSelectedRecipient();
                this.recipientChanged();
            });
    }

    recipientChanged(): void {
        if(!this.selectedRecipient) {
            this.invoice.recipientName = '';

            return;
        }
        
        this.invoice.recipientName = this.selectedRecipient.name;

        let recipientMailingAddress: string = this.selectedRecipient.address1 + ', ';
        recipientMailingAddress += this.selectedRecipient.address2 ? (this.selectedRecipient.address2 + ', ') : '';
        recipientMailingAddress += this.selectedRecipient.city + ', ' + this.selectedRecipient.state + '  ' + this.selectedRecipient.postalCode;

        this.invoice.recipientMailingAddress = recipientMailingAddress;
        this.invoice.recipientCdsContactId = this.selectedRecipient.cdsBillingContactID;
    }

    setSelectedRecipient(): void {
        this.selectedRecipient = _.find(this.recipients, { name: this.invoice.recipientName }) as InvoiceRecipient
    }
}