import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ParcelTypeCellRendererComponentParams extends ICellRendererParams {
    getParcelType: (params: ParcelTypeCellRendererComponentParams) => string;
    isLinkedParcel: (params: ParcelTypeCellRendererComponentParams) => boolean;
    isMasterParcel: (params: ParcelTypeCellRendererComponentParams) => boolean;
    isReportingParcel?: (params: ParcelTypeCellRendererComponentParams) => boolean;
    isConsolidatedParcel?: (params: ParcelTypeCellRendererComponentParams) => boolean;
    stateAllowConsolidating?: (params: ParcelTypeCellRendererComponentParams) => boolean;
    ppReturnPreparationAllowed?: (params: ParcelTypeCellRendererComponentParams) => boolean;
    isMergedParcel?: (params: ParcelTypeCellRendererComponentParams) => boolean;
}

@Component({
    selector: 'parcel-type-cell',
    template: `
    <parcel-icon [parcel]="parcel"
                 [includeIcons]="true"
                 [ppReturnPreparationAllowed]="ppReturnPreparationAllowed"
                 [stateAllowConsolidating]="stateAllowConsolidating">
    </parcel-icon>
    `,
    styleUrls: ['./parcelTypeCellRenderer.component.scss']
})
export class ParcelTypeCellRendererComponent implements ICellRendererAngularComp {
    params: ParcelTypeCellRendererComponentParams;
    isReportingParcel: boolean = false;
    isConsolidatedParcel: boolean = false;
    stateAllowConsolidating: boolean = false;
    ppReturnPreparationAllowed: boolean = false;
    isMergedParcel: boolean = false;

    parcel: Core.ParcelTileDTO;

    agInit(params: ParcelTypeCellRendererComponentParams): void {
        this.params = params;
        this.parcel = params.data as Core.ParcelTileDTO;

        if (params.getParcelType) {
            this.parcel.propTypeAbbr = params.getParcelType(params);
        }

        if (params.isLinkedParcel) {
            this.parcel.isLinked = params.isLinkedParcel(params);
        }
        
        if (params.isMasterParcel) {
            this.parcel.isMasterParcel = params.isMasterParcel(params);
        }

        if (params.isReportingParcel) {
            this.isReportingParcel = params.isReportingParcel(params);
        }
        if (params.isConsolidatedParcel) {
            this.isConsolidatedParcel = params.isConsolidatedParcel(params);
        }
        if (params.stateAllowConsolidating) {
            this.stateAllowConsolidating = params.stateAllowConsolidating(params);
        }
        if (params.ppReturnPreparationAllowed) {
            this.ppReturnPreparationAllowed = params.ppReturnPreparationAllowed(params);
        }
        if (params.isMergedParcel) {
            this.isMergedParcel = params.isMergedParcel(params);
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
