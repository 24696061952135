(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('multiUserTeamPicker', multiUserTeamPicker);

	multiUserTeamPicker.$inject = [];

	function multiUserTeamPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Task/ActionView/Filters/_multiUserTeamPicker.html',
			scope: {
				toBePickedArray: '=',
				teamOnly: '=',
                userOnly: '=?',
				includeInactiveContacts: '=?'
			}
		};
		return directive;

		function link(scope, element, attrs, vm) {
		    if (attrs.userOnly === "") {
		        vm.userOnly = true;
		    }
		}
	}

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit() {
		var vm = this;

		vm.addItem = function () {
			vm.toBePickedArray.push({});
		}

		vm.removeItem = function (item) {
			_.remove(vm.toBePickedArray, item);
		}
	}
})();
