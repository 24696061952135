import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { AnnualDetailEditState } from './annual-details-navigation.service';
import { AnnualDetailAssessment, AnnualDetailComponent, AnnualDetailYear, AnnualGridYearsInfo } from './Annual-Year/annual-year.model';
import { Assessment } from "./Assessments/assessment.model";
import { Decimal } from 'decimal.js';
import { TimerService } from '../UI-Lib/Utilities/timer.service';

import { map, flatten, uniqBy } from 'lodash/fp';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FilingTypeSummary } from './Compliance/compliance.model';

@Injectable()
export class AnnualDetailsService {

    constructor(private readonly _http: SDHttpService,
                private readonly _numberPipe: DecimalPipe,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _currencyPipe: CurrencyPipe,
                private readonly _timer: TimerService) {
    }

    getAnnualYears(parcelId: number, getAllYears: boolean): Promise<AnnualGridYearsInfo>{
        let config = {
            params: {
                parcelID: parcelId,
                getAllYears: getAllYears
            }
        };
        return this._http.get('/api/annualassessmentview/', config);
    }

    getAnnualYearByID(annualYearID: number): Promise<AnnualDetailYear> {
        const url = '/api/AnnualYear/' + annualYearID;

        return this._http.get(url);
    }

    getComplianceDisplay(filingTypeId: number, yearSummaries: FilingTypeSummary[]) {
        let summary = _.find(yearSummaries, { filingTypeId: filingTypeId });

        if (!summary) {
            return '----';
        }

        if (summary.moreThanOneFilingOfThisType) {
            return '<span class="anchor-style">Details</span>'
        }

        if(!summary.filingHasTaskSeries) {
            return summary.value || summary.value === 0 ? `$${this._numberPipe.transform(summary.value, '1.0-0')}` : moment.utc(summary.dueDate).format('M/D/YYYY')
        }
        else{
            if (summary.allTasksCompleted) {
                return summary.value || summary.value === 0 ? `$${this._numberPipe.transform(summary.value, '1.0-0')}` : 'Filed';
            } else {
                return moment.utc(summary.dueDate).format('M/D/YYYY')
            }
        }
    }

    getComponentsFromYears(years: AnnualDetailYear[]): Array<AnnualDetailComponent> {
        return _.flow([
            map('annualGridDetails'),
            flatten,
            map('annualGridComponents'),
            flatten,
            uniqBy('assessmentClassComponentID'),
        ])(years);
    }

    getRevision(revisionId): Promise<Assessment> {
        let serviceUrl = '/api/annualassessment/';
        return this._http.get(serviceUrl + revisionId);
    }

    saveYear(year: AnnualDetailYear): Promise<any> {
        return this._http.put('/api/annualassessmentview', _.omit(year, 'annualGridDetails'));
    }

    addYear(parcelId: number, year: number, calcProjected: boolean) {
        const data = {
            parcelID: parcelId,
            annualYear1: year,
            calcProjected: calcProjected
        }

        return this._http.post('/api/AnnualYear/', data)
    }

    orderRevisions(annualYearId: number, orderedAnnualAssessmentIDs: number[]): Promise<void> {
        // project the array into a dictionary where the IDs are the keys and the order is the value
        let data = {};
        for (let i = 0; i < orderedAnnualAssessmentIDs.length; i++) {
            data[orderedAnnualAssessmentIDs[i]] = i;
        }
        return this._http.put(`/api/AnnualYear/${annualYearId}/AssessmentNumbers`, data);
    }

    formatYearDateDisplay(years: AnnualDetailYear[]): AnnualDetailYear[] {
        _.map(years, function (year) {
            year.collapsedAppealStatus = Date.parse(year.collapsedAppealStatus) ? moment.utc(year.collapsedAppealStatus).format('M/D/YYYY') : year.collapsedAppealStatus;

            year.annualGridDetails = _.map(year.annualGridDetails, function (revision) {
                revision.appealStatus = Date.parse(revision.appealStatus) ? moment.utc(revision.appealStatus).format('M/D/YYYY') : revision.appealStatus;
                return revision;
            });

            return year;
        });

        return years;
    }

    // Filter out component type 3
    nonCapComponents(components: AnnualDetailComponent[], componentList: any): AnnualDetailComponent[] {
        let nonCapComponents: AnnualDetailComponent[] = _.reject(components, {
            assessmentComponentTypeID: 3
        });

        if (componentList) {
            nonCapComponents = _.sortBy(nonCapComponents, (component) => {
                if (component.sequence >= 0){
                    return component.sequence;
                }
                else{
                    return _.findIndex(componentList, {
                        assessmentClassComponentID: component.assessmentClassComponentID
                    })
                }
            });
        }

        return nonCapComponents;
    }

    // Filter out everything except component type 3
    capComponents(components: AnnualDetailComponent[], componentList: any): AnnualDetailComponent[] {
        let capComponents: AnnualDetailComponent[] = _.filter(components, {
            assessmentComponentTypeID: 3
        });

        if (componentList) {
            capComponents = _.sortBy(capComponents, (component) => {
                if (component.sequence >= 0){
                    return component.sequence;
                }
                else{
                    return _.findIndex(componentList, {
                        assessmentClassComponentID: component.assessmentClassComponentID})
                }
            });
        }

        return capComponents;
    }

    //Get the component name from the type, or from the component itself?
    getComponentName(component: AnnualDetailComponent, componentTypes?: any[]): string {
        const compFromCompTypes = _.find(componentTypes, {
            assessmentClassComponentID: component.assessmentClassComponentID
        });

        if (compFromCompTypes) {
            return compFromCompTypes.componentName;
        } else {
            return component.componentName;
        }
    }

    // Return FMV value or null display characters
    getDisplayAssessment(component: AnnualDetailComponent, components: AnnualDetailComponent[]): string {
        const found = _.find(components, {
            'assessmentClassComponentID': component.assessmentClassComponentID
        });

        return found ? this._numberPipe.transform(found.fairMarketValue, '1.0-0') : '----';
    }

    //Format the display of the savings section
    formatSavings(savings: string): string {
        const extraChars = ' + ?';
        let num = _.trimEnd(savings, extraChars);

        if (num && num !== '-') {
            num = this._currencyPipe.transform(num, 'USD', 'symbol', '1.2-2');
            if (_.endsWith(savings, extraChars)) {
                return num + extraChars;
            } else {
                return num;
            }
        } else {
            if (savings) {
                return savings;
            } else {
                return '0';
            }
        }
    }

    //Format the total FMV value
    getTotalFMV(components: AnnualDetailComponent[]): number {
        let nonDeletedComponents = _.reject(components, {
            efAction: 'delete'
        });

        return _.reduce(nonDeletedComponents, (sum, comp) => {
            if (comp.assessmentComponentTypeID === 3) {
                return sum;
            }
            let roundedFMV: Decimal = new Decimal(this.parseFMV(`${comp.fairMarketValue}`)).round();
            return new Decimal(sum).plus(roundedFMV).toNumber()
        }, 0);
    }

    // Parse FMV value as a number from a string, removing special characters
    parseFMV(fmv: string): number {
        let parseFMV = fmv;

        if (parseFMV) {
            parseFMV = parseFMV.toString();
        }

        //Return 0 if null
        if (parseFMV === '' || parseFMV === '#' || !parseFMV)
            return 0;

        //Manually parse out a # symbol from the string
        if (parseFMV[0] === '#')
            parseFMV = parseFMV.substring(1, parseFMV.length);

        return parseFloat(parseFMV);
    }


    // This is a bit messy; we need a function to call that pops up a yes/no/cancel modal if
    // the edit state is dirty. This is needed by the annual details navigation service
    // for generally navigating between assessments, appeals, and bills, but it is also needed
    // within appeals and bills for going between tabs. Having this function sit in
    // AnnualDetailsService allows us to access it anywhere it's needed, but maybe this should
    // be refactored so that it's in the navigation service code somehow.
    preNavigateWarn(editState: AnnualDetailEditState): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!editState.getDirty()) {
                resolve();
            }
            else {
                this._messageBoxService.open({
                    message: 'Do you want to save your changes?',
                    buttons: MessageBoxButtons.YesNoCancel
                }).then((result) => {
                    switch (result) {
                        case MessageBoxResult.Yes:
                            editState.entityLoading = true;
                            editState.validationHandler((isValid, errorMessage) => {
                                if (!isValid) {
                                    editState.validationMessage = errorMessage;

                                    // HACK need setTimeout for some reason as when the 'show'
                                    // is called the validationMessage is not set unless we have the timeout
                                    this._timer.setTimeout(() => {
                                        // TODO this validation tooltip is not available in the
                                        // annual details service. Need to figure out how to set it
                                        //
                                        // editState.aDValidation.show();
                                        //
                                        // For now, console.warn:
                                        console.warn(errorMessage);
                                    })
                                    return;
                                }

                                editState.validationMessage = '';

                                editState.saveHandler().then(() => {
                                    editState.setDirty(false);
                                    editState.entityLoading = false;
                                    resolve();
                                });
                            })
                            break;
                        case MessageBoxResult.No:
                            editState.setDirty(false);
                            editState.cancelHandler();
                            resolve();
                            break;
                        case MessageBoxResult.Cancel:
                            reject();
                            break;
                    }
                });
            }
        });
    }
}

export enum ComponentFilter { exempt, nonexempt, alternative }
