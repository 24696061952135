<ws-modal-wrapper headerLabel="Pause Service"
                  (save)="pause()"
                  (cancel)="cancel()"
                  [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.message.touched && form.controls.message.errors }">
                    <label for="message"
                           helpTooltip
                           helpContentId="long-running-process-quiesce-modal.message"
                           position="bottom">
                        Message
                    </label>
                    <input type="text" class="form-control" id="message" formControlName="message" autocomplete="off" />
                    <div *ngIf="form.controls.message.touched && form.controls.message.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.message.errors.maxlength">Message max length is 100 characters.</div>
                        <div class="text-danger small" *ngIf="form.controls.message.errors.required">Message is required.</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
