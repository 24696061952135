(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .controller('ChangeReportCategoryModalController', ChangeReportCategoryModalController);

    ChangeReportCategoryModalController.$inject = [
        '$uibModalInstance',
        'ReportManagerService',
        '$rootScope',
        'report',
        'reportType',
        'SavedSearchCategoryEnum' 
    ];

    function ChangeReportCategoryModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, ReportManagerService, $rootScope, report, reportType, SavedSearchCategoryEnum) {
        var vm = this;

        vm.save = function() {
            var category = {};

            //Create new category first, then update view
            if (vm.report.categoryId === 'new') {
                category = _.find(vm.reportCategories, { categoryId: 'new' });

                ReportManagerService.createCategory(category.categoryName, true)
                    .then(function (result) {
                        vm.report.categoryId = result.categoryId;

                        ReportManagerService.changeCategory(reportType, report, vm.report.categoryId )
                            .then(function() {
                                $rootScope.$broadcast('updateReportMenu');
                                $uibModalInstance.close();
                            });
                    })
            }
            //Just update search, category already exists
            else {
                ReportManagerService.changeCategory(reportType, report, vm.report.categoryId)
                    .then(function() {
                        $rootScope.$broadcast('updateReportMenu');
                        $uibModalInstance.close();
                    });
            }
        }

        vm.cancel = function() {
            $uibModalInstance.dismiss();
        }

        vm.createNewCategory = function() {
            if (!vm.newCategory) {
                return;
            }

            vm.reportCategories.push({
                categoryName: vm.newCategory,
                categoryType: vm.report.type,
                categoryId: 'new'
            });

            vm.report.categoryId = 'new';

            vm.categoryEntered = true;
            vm.enteringCategory = false;
        }

        activate();

        function activate() {
            vm.report = report;
            vm.report.type = reportType;

            ReportManagerService.getReportCategories()
                .then(function (results) {
                    vm.reportCategories = _.filter(results, function(item) {
                        return (item.categoryId != SavedSearchCategoryEnum.ComplianceReportSetup &&
                            item.categoryId != SavedSearchCategoryEnum.ComplianceReturnReport &&
                            item.categoryId != SavedSearchCategoryEnum.CompliancePackageReport &&
                            item.categoryId != SavedSearchCategoryEnum.ComplianceCustomReport); 
                    });
                })
        }
    }
})();
