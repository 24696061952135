import { Observable, from, lastValueFrom } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ExtendedSiteModel } from '../../Asset/Asset-Details/Asset-Info/extendedSiteModel';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { SiteRepository } from '../../../Core-Repositories';

@Component({
    selector: 'site-selector',
    templateUrl: './siteSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SiteSelectorComponent,
        multi: true
    }]
})
export class SiteSelectorComponent implements ControlValueAccessor {
    constructor(
        private readonly _siteRepository: SiteRepository,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe
    ) { }

    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() siteId: number;
    @Input() companyId: number;
    @Input() isDisabled: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() isOverridden: boolean = false;
    @Input()
    set siteName(siteName: string) {
        this._siteName = siteName;
        this.siteFilter = siteName;
    };

    @Output() siteIdChange = new EventEmitter<number>();
    @Output() siteChange = new EventEmitter<ExtendedSiteModel>();

    siteFilter: string = '';
    siteIsLoading: boolean = false;
    siteFieldEdited: boolean = false;

    // this component will be considered initialized when the gl accounts have been loaded
    // in the meanwhile, any select gl account attempts will be skipped
    isInitialized: boolean = false;

    sites$: Observable<ExtendedSiteModel[]> = (Observable
            .create((observer: any) => { observer.next(this.siteFilter); }) as Observable<string>)
        .pipe(mergeMap((token: string) => this._filterSites(token)));

    private _siteName: string;
    private _siteNoResult: boolean = false;

    writeValue(siteId: number): void {
        this.siteId = siteId;
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onBlur(): void {
        if (!this.isRequiredField && this.siteFilter.trim() === '') {
            this.siteId = null;
            this._siteName = null;
            this.siteChange.emit(null);
        }

        this._onTouchedFn && this._onTouchedFn();
    }

    onNoResults(event: boolean): void {
        this._siteNoResult = event;
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const site = match.item as ExtendedSiteModel;
            this._selectsite(site);
            this.siteIdChange.emit(site.model.siteID);
            this.siteChange.emit(site);

            this.siteFilter = match.value;
        }
    }

    onLoadingChange(isLoading: boolean): void {
        this.siteIsLoading = isLoading;
    }

    private _filterSites(filter: string): Observable<ExtendedSiteModel[]> {
        const searchModel: Core.SiteSearchModel = {
            excludeFromInactiveCompany: true,
            pagination: {
                skip: 0,
                take: 100
            },
            columnFilters: [
                {
                    filterProperty: Core.SitePropertyEnum.SiteNameOrNumber,
                    filterValues: [
                        {
                            filterType: Core.FilterTypeEnum.Contains,
                            filterValue: filter
                        }
                    ]
                }
            ],
        };

        return from(lastValueFrom(this._siteRepository.searchSites(this.companyId, searchModel))).pipe(
            map((sites) => sites.map(site => new ExtendedSiteModel(site, this._keyValueDisplayPipe))));
    }

    private _selectsite(site: ExtendedSiteModel): void {
        if (site) {
            this.siteId = site.model.siteID;
            this.siteFilter = site.displayName;
        } else {
            this.siteId = null;
            this.siteFilter = null;
        }

        this._onChangeFn && this._onChangeFn(this.siteId);
    }
}
