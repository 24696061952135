import { Component, ElementRef, ViewChild, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReturnService } from '../../../return.service';
import { ReturnAssetDragAndDropService } from './returnAssetDragAndDrop.service'

@Component({
    selector: 'return-asset-drag-and-drop-target',
    template:   `
                <div *ngIf="!isReadOnly && isServiceActive; else innerContent"
                    #dragulaEl
                    [attr.dragula-bag-name]="dragulaBagName"
                    [attr.dragula-container-type]="dragulaContainerType"
                    [dragula]="dragulaBagName" >
                    <div *ngIf="dragging" class="dragging-overlay" [ngClass]="{'dragging-overlay--over': draggingOver}">
                        <div class="title">{{ getOverlayTitle() }}</div>
                    </div>
                    <ng-container *ngTemplateOutlet="innerContent"></ng-container>
                </div>
                <ng-template #innerContent><ng-content></ng-content></ng-template>
                `
})
export class ReturnAssetDragAndDropTargetComponent implements OnInit, OnDestroy {
    constructor(
        private _returnAssetDragAndDropService: ReturnAssetDragAndDropService,
        private _returnService: ReturnService
    ) {
        this.dragulaContainerType = this._returnAssetDragAndDropService.CONTAINER_TYPE_TARGET;
        this.dragulaBagName = this._returnAssetDragAndDropService.BAG_NAME;
    }

    @ViewChild('dragulaEl') dragulaElRef: ElementRef;

    @Input() scheduleName: string;
    @Input() scheduleDescription: string;
    @Input() depreciationTableLife: number;

    @Output() assetsDropped: EventEmitter<number[]> = new EventEmitter();

    private _destroy$: Subject<void> = new Subject();

    isReadOnly: boolean;
    isServiceActive: boolean;

    dragulaContainerType: string;
    dragulaBagName: string;

    dragging: boolean = false;
    draggingOver: boolean = false;

    ngOnInit(): void {
        this._returnAssetDragAndDropService.dragging$.pipe(takeUntil(this._destroy$)).subscribe(x => this.dragging = x);
        this._returnAssetDragAndDropService.draggingOver$.pipe(takeUntil(this._destroy$)).subscribe(x => this.draggingOver = this.dragulaElRef && (this.dragulaElRef.nativeElement === x));
        this._returnAssetDragAndDropService.dropped$.pipe(takeUntil(this._destroy$)).subscribe(x => {
            if (!this.dragulaElRef) { return; }
            const droppedOnMe = this.dragulaElRef.nativeElement === x.element;
            if (droppedOnMe) {
                this.assetsDropped.emit(x.draggedAssetIds);
            }
        });

        this._returnAssetDragAndDropService.active$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isServiceActive = x);

        this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this._destroy$))
            .subscribe(x => this.isReadOnly = x);

        this.isReadOnly = this._returnService.isReturnInReadOnlyMode;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    getOverlayTitle(): string {
        const description = this.scheduleDescription ? `(${this.scheduleDescription})` : ''
        if (this.depreciationTableLife) {
            return `${this.scheduleName} ${description}: ${this.depreciationTableLife} year life`;
        }
        return `${this.scheduleName} ${description}`;
    }
}
