import { HelpContent } from "../../UI-Lib/Help-Tooltip";

export const SMART_SEARCH_HELP: HelpContent[] = [
    {
        helpContentId: 'smartSearch.export',
        tooltipText: 'Export the search in Excel format'
    },
    {
        helpContentId: 'smartSearch.results',
        tooltipText: 'SMART search results'
    },
    {
        helpContentId: 'smartSearch.save-header',
        tooltipText: 'Save Search'
    },
    {
        helpContentId: 'smartSearch.new-category',
        tooltipText: 'Finish or cancel adding a new category to save'
    },
    {
        helpContentId: 'smartSearch.show-criteria',
        tooltipText: 'Show filter criteria'
    },
    {
        helpContentId: 'smartSearch.clear-criteria',
        tooltipText: 'Reset'
    },
    {
        helpContentId: 'smartSearch.stop-sharing',
        tooltipText: 'Stop sharing'
    },
    {
        helpContentId: 'smartSearch.all-instances-selected',
        tooltipText: 'Please select a single instance to save search'
    }
];
