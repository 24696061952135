import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForImportListGridAction extends ICellRendererParams {
    resume: (params: ICellRendererParamsForImportListGridAction) => void;
    delete: (params: ICellRendererParamsForImportListGridAction) => void;
    canDelete: (params: ICellRendererParamsForImportListGridAction) => boolean;
    revert: (params: ICellRendererParamsForImportListGridAction) => void;
    canRevert: (params: ICellRendererParamsForImportListGridAction) => boolean;
    complete: (params: ICellRendererParamsForImportListGridAction) => void;
    canComplete: (params: ICellRendererParamsForImportListGridAction) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button type="button"
        class="btn flat-button primary-button icon-button grid-action-button"
        (click)="params.resume(params)"
        helpTooltip
        helpContentId="app.view"
        position="bottom">
        <i class="fa fa-pencil"></i>
    </button>
    <div class="grid-action-button-wrapper"
        helpTooltip
        [helpContentId]="deleteHelpContentId"
        position="bottom">
        <button type="button"
            class="btn flat-button icon-button warning-button grid-action-button"
            [disabled]="!params.canDelete(params)"
            (click)="params.delete(params)">
            <i class="fa fa-trash"></i>
        </button>
    </div>
    <div class="grid-action-button-wrapper"
        helpTooltip
        [helpContentId]="revertHelpContentId"
        position="bottom">
        <button type="button"
            class="btn flat-button warning-button icon-button grid-action-button"
            (click)="params.revert(params)"
            [disabled]="!params.canRevert(params)">
            <i class="fa fa-undo"></i>
        </button>
    </div>
    <div class="grid-action-button-wrapper"
        helpTooltip
        [helpContentId]="markCompletedHelpContentId"
        position="bottom">
        <button type="button"
            class="btn flat-button warning-button icon-button grid-action-button"
            (click)="params.complete(params)"
            [disabled]="!params.canComplete(params)">
            <i class="fa fa-check"></i>
        </button>
    </div>
    `
})
export class EntityImportListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForImportListGridAction;
    importFile: Compliance.ImportFileModel;

    get deleteHelpContentId(): string {
        return this.params.canDelete(this.params) ?
            'app.delete' :
            'import-list.delete-disabled';
    }

    get revertHelpContentId(): string {
        return this.params.canRevert(this.params) ?
            'import-list.revert' :
            'import-list.revert-disabled';
    }

    get markCompletedHelpContentId(): string {
        return this.params.canComplete(this.params) ?
            'import-list.mark-completed' :
            'import-list.mark-completed-disabled';
    }

    agInit(params: ICellRendererParamsForImportListGridAction): void {
        this.params = params;
        this.importFile = params.data as Compliance.ImportFileModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
