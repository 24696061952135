(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ChangeActionViewCategoryModalController', ChangeActionViewCategoryModalController);

	ChangeActionViewCategoryModalController.$inject = [
        '$uibModalInstance',
        'actionViewService',
        '$rootScope',
        'view',
        'actionViewType'
	];

	function ChangeActionViewCategoryModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, actionViewService, $rootScope, view, actionViewType) {
		var vm = this;

		vm.save = function() {
			var category = {};

			//Create new category first, then update view
			if (vm.view.categoryId === 'new') {
				category = _.find(vm.actionViewCategories, { categoryId: 'new' });

				actionViewService.createCategory(_.omit(category, 'categoryId'))
					.then(function (result) {
						vm.view.categoryId = result.categoryId;

						actionViewService.changeCategory(view, vm.view.categoryId, actionViewType)
							.then(function() {
							$rootScope.$broadcast('updateActionViewMenu');
							$uibModalInstance.close();
						});
					})
			}
			//Just update search, category already exists
			else {
				actionViewService.changeCategory(view, vm.view.categoryId, actionViewType)
					.then(function() {
					$rootScope.$broadcast('updateActionViewMenu');
					$uibModalInstance.close();
				});
			}
		}

		vm.cancel = function() {
			$uibModalInstance.dismiss();
		}

		vm.createNewCategory = function() {
			if (!vm.newCategory) {
				return;
			}

			vm.actionViewCategories.push({
				categoryName: vm.newCategory,
				categoryType: vm.view.type,
				categoryId: 'new'
			});

			vm.view.categoryId = 'new';

			vm.categoryEntered = true;
			vm.enteringCategory = false;
		}

		activate();

		function activate() {
			vm.view = view;
			vm.view.type = actionViewType;

			actionViewService.getCategories()
				.then(function (result) {
					vm.actionViewCategories = result;
					//vm.actionViewCategories.unshift({ categoryName: '[No Category]' });
					console.log(result)
				})
		}
	}
})();
