import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const COMPANY_INFO_PANEL_HELP: HelpContent[] = [
    {
        helpContentId: 'company-info-panel.attachments',
        tooltipText: 'Attachments'
    },
    {
        helpContentId: 'company-info-panel.comments',
        tooltipText: 'Comments'
    },
    {
        helpContentId: 'company-info-panel.images',
        tooltipText: 'Images'
    },
    {
        helpContentId: 'company-info-panel.favorite',
        tooltipText: 'Favorite'
    }, 
]
