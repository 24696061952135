import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { MultiPartResponseService, BinaryResponse } from './MultiPartResponseService';

@Injectable({
    providedIn: 'root'
})
export class DownloadFileService {
    constructor(private multiPartResponseService: MultiPartResponseService) { }

    async downloadResponse(response: BinaryResponse) {
        if (response.status === 204) {
            console.log('NOTE: A duplicate download was detected (silently skipping)');
            return;
        }
        const processedResponse = this.multiPartResponseService.ReadResponse(response);
        if (processedResponse.isMultiPartResponse) {
            let downloadCount = 0;
            let currentPart = await processedResponse.reader.readNext();
            while (currentPart !== null) {
                this.saveBlob(currentPart.headers['Content-Disposition'], currentPart.binary);
                downloadCount++;
                if (downloadCount > 10) { throw new Error('Download overflow'); }

                currentPart = await processedResponse.reader.readNext();
            }
        }
        else {
            this.saveBlob(response.headers.get('content-disposition'), response.body);
        }
    }

    private saveBlob(contentDisposition: string, blob: Blob) {
        const fileNameMatch = /filename="?([^;"]+)"?/ig.exec(contentDisposition || '');
        const fileName = ((fileNameMatch && fileNameMatch[1]) || 'untitled').trim();
        saveAs(blob, fileName);
    }
}
