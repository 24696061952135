import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsAbsoluteUrl' })
export class WeissmanAbsoluteUrlPipe implements PipeTransform {
    constructor() { }
    transform(url: string): string {
        var startingUrl = "http://";
        var httpsStartingUrl = "https://";
        if(url && (url.indexOf(startingUrl) === 0 || url.indexOf(httpsStartingUrl) === 0)){
            return url;
        }
        else {
            return startingUrl + url;
        }
    }
}