import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ReportingParcelRepository } from '../../../Repositories';
import { BusyIndicatorService } from '../../../../Busy-Indicator/busyIndicator.service';
import { lastValueFrom, Observable } from 'rxjs';
import * as _ from 'lodash';
import { ComplianceSiteService } from '../../site.service';
import { mergeMap } from 'rxjs/operators';

enum LinkedSitesReportingType {
    ThisSite = 1,
    AnotherSite = 2
}

export interface LinkedSitesReportingParams {
    siteId: number;
    companyId: number;
}

export interface LinkedSitesReportingResult {
    masterSite: Core.LinkedSiteModel;
    site: Core.LinkedSiteModel;
}

export interface LinkedSiteTypeAheadModel extends Core.LinkedSiteModel {
    siteDisplayName: string;
}

@Component({
    selector: 'linked-sites-reporting',
    templateUrl: './linkedSitesReporting.component.html',
    styleUrls: ['./linkedSitesReporting.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LinkedSitesReportingComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _reportingParcelRepository: ReportingParcelRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _siteService: ComplianceSiteService) {
    }

    linkedSitesReportingTypes = LinkedSitesReportingType;
    selectedReportingType: LinkedSitesReportingType = LinkedSitesReportingType.ThisSite;
    selectedMasterSite: Core.LinkedSiteModel;
    site: Core.LinkedSiteModel;
    siteFilter: string = '';
    siteIsLoading: boolean = false;
    params: LinkedSitesReportingParams;
    result: LinkedSitesReportingResult;

    sites$: Observable<Core.LinkedSiteModel[]> = (Observable
        .create((observer: any) => { observer.next(this.siteFilter) }) as Observable<string>)
        .pipe(mergeMap(async (token: string) => await this._filterSites(token)));

    async ngOnInit(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.site = await lastValueFrom(this._reportingParcelRepository.getLinkedSite(this.params.siteId));
            this.selectedMasterSite = this.site;
        } finally {
            busyRef.hide();
        }
    }

    ngOnDestroy(): void { }

    selectedReportingTypeChanged(reportingType: LinkedSitesReportingType): void {
        if (reportingType === LinkedSitesReportingType.ThisSite) {
            this.selectedMasterSite = this.site;
            this.siteFilter = '';
        } else {
            this.selectedMasterSite = null;
        }
    }

    async save(): Promise<void> {
        this.result = {
            masterSite: this.selectedMasterSite,
            site: this.site
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onSiteLoadingChange(isLoading: boolean): void {
        this.siteIsLoading = isLoading;
    }

    onSiteSelected(match: TypeaheadMatch): void {
        this.selectedMasterSite = (match.item as Core.LinkedSiteModel);
        this.siteFilter = match.value;
    }

    private async _filterSites(filter: string): Promise<Core.LinkedSiteModel[]> {
        const searchModel: Core.LinkedSiteSearchModel = {
            companyId: this.params.companyId,
            includeMasterSites: true,
            columnFilters: [{
                filterProperty: Core.SitePropertyEnum.SiteNameOrNumber,
                filterValues: [
                    {
                        filterValue: filter,
                        filterType: Core.FilterTypeEnum.Contains
                    }
                ]
            }]
        };

        const sites = await lastValueFrom(this._reportingParcelRepository.getAvailableSites(searchModel));
        return _.filter(_.map(sites, (x: Core.LinkedSiteModel) => {
            return _.extend(
                {} as LinkedSiteTypeAheadModel,
                x,
                {
                    displayName: this._siteService.getLinkedSiteName(x)
                });
        }), (x: LinkedSiteTypeAheadModel) => x.siteId !== this.site.siteId);
    }
}
