import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StepProgressIndicatorStep } from '../../../Common/Step-Progress-Indicator/stepProgressIndicator.component';
import { DecimalPipe } from '@angular/common';

@Component({
    selector: 'entity-import-progress',
    template: '<step-progress-indicator *ngIf="importFile" [steps]="steps" [showStepName]="showStepName" [clickable]="clickable"></step-progress-indicator>'
})
export class EntityImportProgressComponent {
    constructor(
        private readonly _decimalPipe: DecimalPipe) { }

    @Input() importFile: Compliance.ImportFileModel;
    @Input() showStepName: boolean = true;
    @Input() clickable: boolean = true;
    @Output() click: EventEmitter<Compliance.ImportFileModel> = new EventEmitter<Compliance.ImportFileModel>();

    steps: StepProgressIndicatorStep[] = [
        {
            name: 'UPLOAD',
            title: this._getUploadTitle.bind(this),
            isError: this._uploadIsError.bind(this),
            isCurrent: this._uploadIsCurrent.bind(this),
            isWorking: this._uploadIsWorking.bind(this),
            isComplete: this._uploadIsComplete.bind(this),
            click: this._onClick.bind(this)
        },
        {
            name: 'MAP',
            title: this._getMapTitle.bind(this),
            isError: this._mapIsError.bind(this),
            isCurrent: this._mapIsCurrent.bind(this),
            isWorking: this._mapIsWorking.bind(this),
            isComplete: this._mapIsComplete.bind(this),
            click: this._onClick.bind(this)
        },
        {
            name: 'XFER',
            title: this._getTransferTitle.bind(this),
            isError: this._transferIsError.bind(this),
            isCurrent: this._transferIsCurrent.bind(this),
            isWorking: this._transferIsWorking.bind(this),
            isComplete: this._transferIsComplete.bind(this),
            click: this._onClick.bind(this)
        }
    ];

    private _getUploadTitle(): string {
        if (!this.importFile) {
            return 'Upload';
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingError) {
            return `${(this.importFile.error ? this.importFile.error : 'An error has occurred.')}  Delete the import and try again.`;
        }
        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Uploaded) {
            return 'Upload complete. Please click to resume the import.';
        }
        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Processing) {
            return 'The import is being processed by the server.';
        }
        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ShowPreview) {
            return 'Upload complete. Please click to provide some additional information.';
        }
        if (this.importFile.processStatus >= Compliance.ImportFileProcessStatusEnum.ProcessingCompleted) {
            return 'Upload complete.';
        }

        return 'Upload';
    }

    private _uploadIsError(): boolean {
        return this.importFile && this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingError;
    }

    private _uploadIsCurrent(): boolean {
        return this.importFile && this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Uploaded;
    }

    private _uploadIsWorking(): boolean {
        return this.importFile && (this.importFile && this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Processing);
    }

    private _uploadIsComplete(): boolean {
        return this.importFile &&
            this.importFile.processStatus >= Compliance.ImportFileProcessStatusEnum.ProcessingCompleted &&
            this.importFile.processStatus !== Compliance.ImportFileProcessStatusEnum.ShowPreview;
    }

    private _getMapTitle(): string {
        if (this.importFile && this.importFile.requiredFieldsAssigned) {
            return 'All required fields have been mapped.';
        }
        return 'Map required fields';
    }

    private _mapIsError(): boolean {
        return false;
    }

    private _mapIsCurrent(): boolean {
        return (this.importFile && this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ProcessingCompleted && !this.importFile.requiredFieldsAssigned);
    }

    private _mapIsWorking(): boolean {
        return false;
    }

    private _mapIsComplete(): boolean {
        return this.importFile && (this.importFile.requiredFieldsAssigned || this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed);
    }

    private _getTransferTitle(): string {
        if (!this.importFile) {
            return 'Transfer data.';
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferring) {
            let message = 'Transferring data: ';
            if (this.importFile.lastTransferInfo){
                const current = this.importFile.lastTransferInfo.insertedCount +
                    this.importFile.lastTransferInfo.updatedCount +
                    this.importFile.lastTransferInfo.unchangedCount;

                if (current !== 0)
                {
                    message += `transferred ${this._decimalPipe.transform(current || 0, '1.0-0')} of ${this._decimalPipe.transform(this.importFile.lastTransferInfo.selectedCount || 0, '1.0-0')} rows`;
                } else{
                    message += 'preparing for transfer';
                }
            } else{
                message += 'preparing for transfer';
            }

            message += '.';
            return message;
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferCompleted) {
            return 'Transfer completed.';
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferError ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.RevertingError) {
            return `${(this.importFile.error ? this.importFile.error : 'An error has occurred.')}`;
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Reverting) {
            return 'Reverting transferred data.';
        }

        if (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Validating) {
            return 'Validating.';
        }

        return 'Transfer data.';
    }

    private _transferIsError(): boolean {
        return this.importFile && (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferError ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.RevertingError);
    }

    private _transferIsCurrent(): boolean {
        return this.importFile && (this.importFile.requiredFieldsAssigned &&
            this.importFile.processStatus !== Compliance.ImportFileProcessStatusEnum.TransferCompleted &&
            (this.importFile.processStatus !== Compliance.ImportFileProcessStatusEnum.RevertingError || this.importFile.totalRows != this.importFile.transferredRows));
    }

    private _transferIsWorking(): boolean {
        return this.importFile && (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Transferring ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Reverting ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Validating);
    }

    private _transferIsComplete(): boolean {
        return this.importFile && (this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Completed ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.TransferCompleted ||
            this.importFile.processStatus === Compliance.ImportFileProcessStatusEnum.RevertingError && this.importFile.totalRows === this.importFile.transferredRows);
    }

    private _onClick(): void {
        if (!this.importFile) {
            return;
        }

        this.click.emit(this.importFile);
    }
}
