import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class InstanceRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) {
        super(httpClient);
    }


    private _pathPrefix = 'api/instance';
    private _entityInstanceCache:any = {};
    private _entityInstanceCacheById:any = {};


    //create new instance
    create(model: any): any {
        return this.httpPost(`${this._pathPrefix}`, model);
    }

    get (instanceId: number): Observable<Core.InstanceDTO> {
        return this.httpGet(`${this._pathPrefix}/${instanceId}`);
    }

    update(model: Core.InstanceDTO) {
        let response = this.httpPut(this._pathPrefix, model);
        return response;
    }

    //check for duplicate instance name
    isInstanceNameInUse(model):Observable<boolean> {
        return this.httpPost(`${this._pathPrefix}/IsInstanceNameInUse`, model);
    }

    //look up instanceId for an entity by type and entity id
    getEntityInstanceId(entityType: string, entityId:number): Observable<number> {
        const resourceUri = `${entityType}/${entityId}`;
        if (this._entityInstanceCache[resourceUri]) {
            return new Observable(s=> {
                s.next(+this._entityInstanceCache[resourceUri]);
                s.complete();
            });
        }
        return new Observable(s=> {
            this.httpGet(`${this._pathPrefix}/resolve/${resourceUri}`).subscribe(r=>{
                this._entityInstanceCache[resourceUri] = r;
                s.next(+r);
                s.complete();
            });
        });
    }


    //look up instanceId for an entity by typeId and entityId
    getEntityIdPairInstanceId(entityTypeId: number, entityId:number): Observable<number> {
        const resourceUri = `${entityTypeId}/${entityId}`;
        if (this._entityInstanceCacheById[resourceUri]) {
            return new Observable(s=> {
                s.next(+this._entityInstanceCacheById[resourceUri]);
                s.complete();
            });
        }
        return new Observable(s=> {
            this.httpGet(`${this._pathPrefix}/resolveById/${resourceUri}`).subscribe(r=>{
                this._entityInstanceCacheById[resourceUri] = r;
                s.next(+r);
                s.complete();
            });
        });
    }

    userEndpointUrlPrefix : string = "/api/user/";
    contactEndpointUrlPrefix: string = "/api/contact/"

    //get list of current user's instance contacts and statuses
    async getCurrentUsersContactInviteStatuses():Promise<Core.ContactStatusDTO[]> {
        const url = `${this.userEndpointUrlPrefix}contactsstatus`;
        const statuses : Core.ContactStatusDTO[] = await lastValueFrom(this.httpGet<Core.ContactStatusDTO[]>(url));
        return statuses;
    }

    //get list of current user's instance contacts and statuses
    async getUserContactInviteStatuses(userId):Promise<Core.ContactStatusDTO[]> {
        const url = `${this.userEndpointUrlPrefix}contactsstatus/${userId}`;
        const statuses : Core.ContactStatusDTO[] = await lastValueFrom(this.httpGet<Core.ContactStatusDTO[]>(url));
        return statuses;
    }

    //accept pending instance invite
    async acceptInvite(contactId: number) {
        const url = `${this.contactEndpointUrlPrefix}invite/accept/${contactId}`;
        var result = await lastValueFrom(this.httpPut<boolean, any>(url, null));
        if(result === false) {
            throw "Invitation could not be accepted"
        }

        return result;
    }

    //reject pending instance invite
    async rejectInvite(contactId: number) {
        const url = `${this.contactEndpointUrlPrefix}invite/reject/${contactId}`;
        var result = await lastValueFrom(this.httpPut<boolean, any>(url, null));
        if(result === false) {
            throw "Invitation could not be rejected"
        }
        return result;
    }

    //toggle instance visibility
    async toggleInstance(contactId: number) {
        const url = `${this.contactEndpointUrlPrefix}instance/togglevisibility/${contactId}`;
        var result = await lastValueFrom(this.httpPut<boolean, any>(url, null));
        if(result === false) {
            throw "Instance visibility could not be toggled"
        }

        return result;
    }
}
