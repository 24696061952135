<ws-modal-wrapper headerLabel="Set Top Account"
                  acceptLabel="Save"
                  acceptHelpContentId="app.save"
                  cancelHelpContentId="app.cancel"
                  (save)="save()"
                  (cancel)="cancel()">
    <ul>
        <li *ngFor="let item of params.appealFormList">
            <label>
                <input type="radio" name="formRevisionGroup" [(ngModel)]="topAppealFormRevisionId" [value]="item.appealFormRevisionId" />
                <span>{{item.parcelAcctNum}}</span>
            </label>
        </li>
    </ul>
</ws-modal-wrapper>
