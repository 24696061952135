import { AssessmentSummaryInfo } from './assessmentSummaryInfo';
import { Injectable } from "@angular/core";

@Injectable()
export class AllocationDetailsService {
    constructor() {
    }

    allocationDetailsFilter: AssessmentSummaryInfo = null;
}
