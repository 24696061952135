<ws-modal-wrapper [headerLabel]="masterSite ? 'Sites Linked to ' + masterSite.siteName : 'Link Sites'"
                  [cancelLabel]="(editMode) ? 'Cancel' : 'Close'"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="((!masterSite) && (sites && sites.length > 1) || (sites && sites.length === 1))">
        <div class="app-ui-view ws-flex-container-vertical ws-flex-auto ws-stretch">
            <div class="ws-flex-none" *ngIf="editMode">
                <button type="button" class="flat-button small-button warning-button float-end" (click)="removeAll()" title="Remove all site links" [disabled]="sites && !sites.length">Remove All Links</button>
                <button type="button" class="flat-button small-button primary-button float-end" (click)="add()" title="Add site links" #focus>Add Site Links</button>
            </div>
            <ws-ag-grid-angular [gridOptions]="gridOptions"
                                (gridReady)="onAgGridReady($event)">
            </ws-ag-grid-angular>
            <div class="ws-flex-none ws-flex-container-vertical" *ngIf="editMode">
                <div class="text-danger" *ngIf="(!masterSite) && (sites && sites.length > 1)">Master site is required.</div>
                <div class="text-danger" *ngIf="(sites && sites.length === 1)">At least 2 linked sites are required.</div>
                <div class="text-warning" *ngIf="masterSite && ((!masterSite.reportingParcelId) && (!masterSite.hasActivePPParcel))">The master site doesn't have 1 active PP parcel so the reporting parcel cannot be set automatically</div>
            </div>
        </div>
</ws-modal-wrapper>
