import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridOptions, GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams } from '../../../AgGrid/MultiSelectTracker';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../AgGrid';
import {
    FilingBatchAddAccountsAgGridDataSource,
    FilingBatchAddAccountsDataSourceParams
} from './filingBatchAddAccountsAgGridDataSource';
import { FilingBatchRepository } from '../../../Repositories';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridFilterParams } from '../../../AgGrid';
import { ActiveStatuses } from '../../../../constants.new';
import { WeissmanDateFormatPipe } from '../../../../UI-Lib/Pipes';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { FILING_BATCH_ADD_ACCOUNTS_HELP } from './filingBatchAddAccounts.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../../Busy-Indicator';

export interface FilingBatchAddAccountsParams {
    filingBatchId: number;
    companyId: number;
    addedFilingIds: number[];
}

export interface FilingBatchAddAccountsResult {
    totalRows: number;
    selectedRowIds: number[];
}

@Component({
    selector: 'filing-batch-add-accounts',
    templateUrl: './filingBatchAddAccounts.component.html',
    styleUrls: ['./filingBatchAddAccounts.component.scss']
})
export class FilingBatchAddAccountsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<FilingBatchAddAccountsParams, FilingBatchAddAccountsResult> {
    constructor(
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _modalRef: BsModalRef,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _helpService: HelpService,
        private _busyIndicatorService: BusyIndicatorService
    ) { }

    private _gridApi: GridApi;
    private _gridDataSource: FilingBatchAddAccountsAgGridDataSource;

    params: FilingBatchAddAccountsParams;
    result: FilingBatchAddAccountsResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FilingModel).filingId)
        }
    })
        .withContext(this)
        .withInfiniteScroll()
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    ngOnInit(): void {
        this._helpService.setContent(FILING_BATCH_ADD_ACCOUNTS_HELP);
    }

    ngOnDestroy(): void { }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'filingId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Filing',
                field: 'filingDescription',
                width: AgGridColumns.textColumnWidth,
                valueFormatter: (params) => {
                    const filing = params.data as Compliance.FilingModel;
                    if (!filing) {
                        return '';
                    }
                    let result = filing.filingDescription;
                    if (filing.parcelActivityStatusId === ActiveStatuses.Inactve) {
                        result += ' (inactive)';
                    }
                    return result;
                },
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    async save(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Adding' });

        try {
            this.result = {
                selectedRowIds: await this.gridTracker.getSelectedRowIds(),
                totalRows: this.gridTracker.getTotalRowsCount()
            } as FilingBatchAddAccountsResult;

            this._modalRef.hide();
        }
        finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this.gridTracker.setSelectedRowsModel({
            selectAllRows: false,
            selectedRows: []
        });

        this._modalRef.hide();
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private _setDataSource(): void {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear();

        const dataSourceParams = (): FilingBatchAddAccountsDataSourceParams => {
            return {
                filingBatchId: this.params.filingBatchId,
                selectedIds: this.params.addedFilingIds
            }
        }

        this._gridDataSource = new FilingBatchAddAccountsAgGridDataSource(this._gridApi, this._filingBatchRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
    }
}
