import { HelpContent } from '../../../../UI-Lib/Help-Tooltip';

export const FILING_BATCH_ACCOUNTS_HELP: HelpContent[] = [
    {
        helpContentId: 'filing-batch-accounts.panel',
        tooltipText: 'List of filings in this batch',
        hasIcon: true
    },
    {
        helpContentId: 'filing-batch-accounts.move',
        tooltipText: 'Move selected filings to another batch.'
    },
    {
        helpContentId: 'filing-batch-accounts.move-no-accounts',
        tooltipText: 'Select filings to move to another batch.'
    },
    {
        helpContentId: 'filing-batch-accounts.add',
        tooltipText: 'Add filings to this batch.'
    },
    {
        helpContentId: 'filing-batch-accounts.edit',
        tooltipText: 'Edit to add/remove filings or to change the prior return for a filing.'
    },
    {
        helpContentId: 'filing-batch-accounts.edit-filing',
        tooltipText: 'Edit prior return.'
    }
];
