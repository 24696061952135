import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

const SERVICE_URL = '/api/TaskActionView/';

@Injectable({
    providedIn: 'root'
})
export class ActionViewIntegrationRepository extends BaseRepository {
    constructor (http : HttpClient) { super(http); }

    downloadSearchResults(processId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpPost(SERVICE_URL + `export/${processId}`, processId, options);
    }
}
