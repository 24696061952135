import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormService } from '../form.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'form-assessor-select',
    templateUrl: './formAssessorSelect.component.html',
    styleUrls: ['./formAssessorSelect.component.scss']
})
export class FormAssessorSelectComponent implements OnInit, OnDestroy {

    constructor(
        private readonly _formService: FormService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) {}

    assessors: Compliance.FormRevisionAssessorModel[];
    selectedAssessor: UntypedFormControl = new UntypedFormControl();

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit() {
        this.selectedAssessor.disable();

        this._formService.formRevision$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // initial load
            this.selectedAssessor.setValue(this._formService.assessor);
            this._setAssessors();
        });

        this._formService.formRevisionYear$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // year changed
            this.selectedAssessor.setValue(this._formService.assessor);
            this._setAssessors();
        });

        this._formService.assessors$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // assessors changed
            this.selectedAssessor.setValue(this._formService.assessor);
            this._setAssessors();
        });

        this._formService.assessor$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // assessors changed
            this.selectedAssessor.setValue(this._formService.assessor);
            this._setAssessors();
        });

        this._mutexService.lockChanged$.pipe(takeUntil(this.destroy$))
            .subscribe((val) => (!val.hasOwnProperty(this._formService.editGroup))
                ? this.selectedAssessor.enable()
                : this.selectedAssessor.disable());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    
    async selectedAssessorChanged(assessor: Compliance.FormRevisionAssessorModel): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: `Loading ${assessor && assessor.assessorName}` });
        try {
            await this._formService.setAssessor(assessor);
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    private _setAssessors(): void {
        this.assessors = this._formService.assessorsAssigned;
    }
}
