import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportDetail } from '../report.manager.model';
import { Constants, EntityType } from '../../../constants.new';
import { CompanyService } from '../../../Entity/Company/company.service';
import { ReportManagerService } from '../report.manager.service';
import * as moment from 'moment';

@Component({
    selector: 'further-limit-panel-budget',
    template: `
        <div class="form-inline further-limit-budget">
            <div class="form-group">
                <label>Budget Year: </label>
                <select [(ngModel)]="report.criteria.budgetYear" class="form-select-sm" (change)="yearSelected()">
                    <option *ngFor="let year of yearsToDisplay" [ngValue]="year[0]">{{fiscalYearStart}}/{{year[0]}} - {{fiscalYearEnd}}/{{year[1]}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Minimum Detail Years Shown: </label>
                <select [(ngModel)]="report.criteria.minimumDetailYearsShown" class="form-select-sm">
                    <option *ngFor="let num of numYears" [ngValue]="num">{{ num }}</option>
                </select>
            </div>
            <div class="form-group" style="margin-left: 20px;">
                <label style="vertical-align: top; margin-top: 5px;">Property Type: </label>
                <select multiple [(ngModel)]="report.criteria.propertyTypeIDs" class="form-select-sm">
                    <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeId">{{ propType.name }}</option>
                </select>
            </div>
            <div style="display: inline-block; vertical-align: top; margin-left: 50px;" *ngIf="hasRyanPrivatePermission">
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeDisclaimer"></ws-switch> Include Disclaimer
                </label>
            </div>
        </div>
    `
})
export class FurtherLimitPanelBudgetComponent implements OnInit {
    @Input() report: ReportDetail;
    @Input() availableYears: number[];
    @Input() hasRyanPrivatePermission: boolean;
    numYears: number[];
    propertyTypes: any[];

    fiscalYearStart: string = '1/1';
    fiscalYearEnd: string = '12/31';

    yearsToDisplay: any[];

    constructor(private Constants: Constants, private companyService: CompanyService, private reportManagerService: ReportManagerService) { }

    ngOnInit() {
        let yearsToDisplay = [];

        let count = 0;
        this.availableYears.forEach(function(year) {
            yearsToDisplay[count] = new Array<number>();
            yearsToDisplay[count][0] = year;
            yearsToDisplay[count][1] = year;
            count++;
        });

        this.reportManagerService.topLevelCompanies$.subscribe(async topLevelCompaniesObj => {
            yearsToDisplay = [];
            if(topLevelCompaniesObj.topLevelCompanyIds.length == 1) {
                const fiscalYearStart = await this.companyService.getCompanyFiscalYearStart(topLevelCompaniesObj.topLevelCompanyIds[0]);

                if(fiscalYearStart) {
                    this.fiscalYearStart = fiscalYearStart;
                    const fiscalYearStartDate = new Date(`${fiscalYearStart}/${new Date().getFullYear()}`);
                    this.fiscalYearEnd = `${moment(fiscalYearStartDate).subtract(1, 'days').month() + 1}/${moment(fiscalYearStartDate).subtract(1, 'days').date()}`;

                    count = 0;
                    this.availableYears.forEach(function(year) {
                        yearsToDisplay[count] = new Array<number>();

                        // if the month is not January, fiscalYearEnd will be in the following year
                        if (moment(fiscalYearStartDate).month() != 0){
                            yearsToDisplay[count][0] = year;
                            yearsToDisplay[count][1] = year + 1;
                        } else {
                            yearsToDisplay[count][0] = year;
                            yearsToDisplay[count][1] = year;
                        }
                        count++;
                    });
                    this.yearsToDisplay = yearsToDisplay;
                    this.yearSelected();
                }
            }
        });

        this.propertyTypes = _.map(this.Constants.PropertyTypeNames, (name: string, id: number) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.report.criteria.propertyTypeIDs = this.report.criteria.propertyTypeIDs || [1, 2, 3, 4];
        if ([null, undefined].indexOf(this.report.criteria.includeDisclaimer) !== -1) {
            this.report.criteria.includeDisclaimer = true;
        }

        this.numYears = [2, 3, 4, 5, 6];
        this.yearsToDisplay = yearsToDisplay;

        const defaultValues: object = {
            minimumDetailYearsShown: 2,
            includePPPropertyType: true,
            budgetYear: new Date().getFullYear() + 1
        };

        _.defaults(this.report.criteria, defaultValues);

        this.yearSelected();
    }

    yearSelected() {
        const fiscalYearStartDate = new Date(`${this.fiscalYearStart}/${new Date().getFullYear()}`);
        let endBudgetYear = this.report.criteria.budgetYear;
        if (moment(fiscalYearStartDate).month() != 0){
            endBudgetYear = endBudgetYear + 1;
        }
        this.report.criteria.budgetYearString = `${this.fiscalYearStart}/${this.report.criteria.budgetYear} - ${this.fiscalYearEnd}/${endBudgetYear}`;
    }
}
