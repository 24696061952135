import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { GridSource } from '../tax.rate.grid.component';
import { TaxAuthority, TaxRateArea } from '../../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { CommentModalData } from '../../Comments/comments.service';

export interface ICellRendererParamsForTaxRateCommandCenterComments extends ICellRendererParams {
    canEdit: boolean;
    gridSofurce: GridSource;
}

@Component({
    selector: 'grid-comments-cell',
    template:
        `
        <div (click)="$event.stopPropagation()" *ngIf="entity && entity.hasComments">
            <entity-comments [(hasComments)]="entity && entity.hasComments"
                                    buttonClass="btn flat-button primary-button icon-button"
                                    [modalData]="commentModalData">
            </entity-comments>
        </div>
        `
})
export class TaxRateCommandCenterCommentsCellRendererComponent implements ICellRendererAngularComp {
    entity: TaxAuthority | TaxRateArea;
    commentModalData: CommentModalData;
    canEdit: boolean = false;

    agInit(params: ICellRendererParamsForTaxRateCommandCenterComments): void {
        this.canEdit = params.canEdit;

        if (!params.data) {
            return;
        }

        if(params.gridSofurce == 'authorities') {
            this.entity = params.data as TaxAuthority;
            this.commentModalData = {
                entityID: this.entity.taxAuthorityId,
                entityTypeID: Core.EntityTypes.TaxAuthority,
                description: this.entity.name,
                canEdit: this.canEdit
            } as CommentModalData
        } else {
            this.entity = params.data as TaxRateArea;
            this.commentModalData = {
                entityID: this.entity.taxRateAreaId,
                entityTypeID: Core.EntityTypes.TaxRateArea,
                description: this.entity.name,
                canEdit: this.canEdit
            } as CommentModalData
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
