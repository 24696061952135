import { Component, Input, OnInit } from '@angular/core';
import { ProgressIndicator } from '../../Models/progressIndicator.model';
import { SnackSize } from '../snackBar.service';
import { TimerService } from "../../../UI-Lib/Utilities";
import { WebsocketListenerService } from "../../../Compliance/websocketListener.service";
import { LongRunningProcessRepository } from "../../../Compliance/Repositories";
import { lastValueFrom } from "rxjs";

@Component({
    selector: 'snack',
    templateUrl: './snack.component.html',
    styleUrls: ['../../busyIndicator.component.scss', './snack.component.scss']
})
export class SnackComponent implements OnInit {
    constructor(private readonly _timerService: TimerService,
                private readonly _longRunningProcessRepository: LongRunningProcessRepository,
                private readonly _websocketListenerService: WebsocketListenerService
    ) { }

    @Input() details: ProgressIndicator;
    @Input() size: SnackSize;
    @Input() signalRDisconnected: boolean;

    snackSize = SnackSize;

    loadingWaitComplete: boolean;
    manualUpdateLoading: boolean;

    ngOnInit(): void {
        this._timerService.setTimeout(x => this.loadingWaitComplete = true, 3000);
    }

    get cancelDisabled(): boolean {
        return this.details.cancelling || (this.details.canDownload && !this.details.downloadWithCancel) ? !this.details.isComplete : false;
    }

    get showCancel(): boolean {
        return this.details.canCancel || (this.details.isComplete && this.details.canNavigate) || (this.details.isComplete && this.details.canDownload);
    }

    /**
     * If SignalR is disconnected the user can manually check for updates
     */
    async refreshFromLRPServer(): Promise<void> {
        if (this.manualUpdateLoading) {
            return;
        }
        this.manualUpdateLoading = true;
        const lrp = await lastValueFrom(this._longRunningProcessRepository.get(this.details.longRunningProcessId));
        this._websocketListenerService.updateLRPManually(lrp);
        this.manualUpdateLoading = false;
    }

}
