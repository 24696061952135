(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ChangeSearchCategoryModalController', ChangeSearchCategoryModalController);

	ChangeSearchCategoryModalController.$inject = ['$uibModalInstance', 'advancedSearchService', '$rootScope', 'search', 'searchType'];

	function ChangeSearchCategoryModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, advancedSearchService, $rootScope, search, searchType) {
		var vm = this;

		vm.save = function() {
			var category = {};

			//Create new category first, then update search
			if (vm.search.categoryId === 'new') {
				category = _.find(vm.searchCategories, { categoryId: 'new' });

				advancedSearchService.createSearchCategory(_.omit(category, 'categoryId'))
					.then(function (result) {
						vm.search.categoryId = result.categoryId;

						advancedSearchService.changeSearchCategory(search, vm.search.categoryId, searchType)
							.then(function() {
							$rootScope.$broadcast('updateAdvancedSearchMenu');
							$uibModalInstance.close();
						});
					})
			}
			//Just update search, category already exists
			else {
				advancedSearchService.changeSearchCategory(search, vm.search.categoryId, searchType)
					.then(function() {
					$rootScope.$broadcast('updateAdvancedSearchMenu');
					$uibModalInstance.close();
				});
			}
		}

		vm.cancel = function() {
			$uibModalInstance.dismiss();
		}

		vm.createNewCategory = function() {
			if (!vm.newCategory) {
				return;
			}

			vm.searchCategories.push({
				categoryName: vm.newCategory,
				categoryType: vm.search.type,
				categoryId: 'new'
			});

			vm.search.categoryId = 'new';

			vm.categoryEntered = true;
			vm.enteringCategory = false;
		}

		activate();

		function activate() {
			vm.search = search;
			vm.search.type = searchType;

			advancedSearchService.getSearchCategories()
				.then(function (result) {
					vm.searchCategories = result;
					vm.searchCategories.unshift({ categoryName: '[No Category]' });
					console.log(result)
				})
		}
	}
})();
