import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class EntityImportRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getByCompany(companyId: number, searchModel: Compliance.ImportSearchModel): Observable<Compliance.QueryResultModel<Compliance.ImportFileModel>> {
        return this.httpPost(`/api/import/company/${companyId}`, searchModel);
    }

    getByCompanyTransferred(companyId: number, importContentTypes: Compliance.ImportContentTypeIdEnum[]): Observable<Compliance.QueryResultModel<Compliance.ImportFileInfoModel>> {
        const importContentTypeIds = importContentTypes.map(i => i as number);
        return this.httpPost(`/api/import/company/${companyId}/transferred`, importContentTypeIds);
    }

    /**
     * Uploads a file and pushes a notification into the upload completed queue.
     * Returns the ID associated with the file that was just uploaded.
     * @param metadata Metadata about the file.
     * @param importFile The file to be uploaded.
     * @param attachmentFile optional attachment file to be uploaded.
     */
    upload(metadata: Compliance.ImportFileMetadata, importFile: File, attachmentFile?: File): Observable<Compliance.ImportFileModel> {
        const formData = new FormData();
        formData.append('importFile', importFile);
        formData.append('fileMetadata', JSON.stringify(metadata));

        if (attachmentFile != null){
            formData.append('attachmentFile', attachmentFile);
        }

        return this.httpPost('api/import', formData, {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        });
    }

    getFileTypes(): Observable<Compliance.ImportFileTypeModel[]> {
        return this.httpGet('/api/importFileType');
    }

    getFileType(fileTypeId: number): Observable<Compliance.ImportFileTypeModel> {
        return this.httpGet(`/api/importFileType/${fileTypeId}`);
    }

    getContentTypes(companyId: number): Observable<Compliance.ImportContentTypeModel[]> {
        let url = '/api/importContentType';
        if (companyId){
            url += `?companyId=${companyId}`;
        }
        return this.httpGet(url);
    }

    getContentType(contentTypeId: number, importId: number): Observable<Compliance.ImportContentTypeModel> {
        return this.httpGet(`/api/importContentType/${contentTypeId}?importId=${importId}`);
    }

    getImport(importFileId: number, ignoreOwnership: boolean = false): Observable<Compliance.ImportFileModel> {
        return this.httpGet(`/api/import/${importFileId}?ignoreOwnership=${ignoreOwnership}`);
    }

    deleteImport(importFileId: number): Observable<void> {
        return this.httpDelete(`/api/import/${importFileId}`);
    }

    getImportRows(importFileId: number, searchModel: Compliance.ImportGridSearchModel): Observable<Compliance.ImportGridGetRowsResult> {
        return this.httpPost(`/api/import/${importFileId}/rows`, searchModel);
    }

    getImportSelectedRowIds(importFileId: number, searchModel: Compliance.ImportGridSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/import/${importFileId}/rowids`, searchModel);
    }

    updateImportFieldValue(importFileId: number, rowIndex: number, importFileSpecificationImportFieldId: number, value: string): Observable<number> {
        const data: Compliance.ImportFileUpdateFieldModel = {
            rowIndex,
            importFileSpecificationImportFieldId,
            value
        };

        return this.httpPut(`/api/import/${importFileId}/update`, data);
    }

    validate(importFileId: number, model?: Compliance.ImportValidateModel): Observable<Compliance.LongRunningProcessModel> {
        return this.httpPut(`/api/import/${importFileId}/validate`, model);
    }

    evaluate(importFileId: number, selectedRowsModel: Compliance.SelectedRowsModel): Observable<Compliance.ImportEvaluateResultModel> {
        return this.httpPut(`/api/import/${importFileId}/evaluate`, selectedRowsModel);
    }

    transfer(importFileId: number, selectedRowsModel: Compliance.SelectedRowsModel): Observable<Compliance.LongRunningProcessModel> {
        return this.httpPut(`/api/import/${importFileId}/transfer`, selectedRowsModel);
    }

    takeOwnership(importFileId: number): Observable<void>{
        return this.httpPut(`/api/Import/${importFileId}/takeOwnership`, {});
    }

    getValidationSummary(importFileId: number): Observable<Compliance.ImportFileValidationErrorModel[]> {
        return this.httpGet(`/api/import/${importFileId}/validationSummary`);
    }

    bulkUpdateFieldsAsync(importFileId: number, updateInfo: Compliance.ImportBulkUpdateModel): Observable<number> {
        return this.httpPut(`/api/import/${importFileId}/bulkUpdate`, updateInfo);
    }

    getSelectedRowsInfo(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<Compliance.ImportSelectedRowsInfoModel> {
        return this.httpPut(`/api/import/${importFileId}/getSelectedRowsInfo`, selectedRowsModel);
    }

    deleteStagingRows(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<Compliance.ImportFileModel>{
        return this.httpPut(`/api/import/${importFileId}/deleteStagingRows`, selectedRowsModel);
    }

    getAssessors(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<Compliance.ImportAssessorModel[]>{
        return this.httpPut(`/api/import/${importFileId}/assessors`, selectedRowsModel);
    }

    exportStagingRows(importFileId: number, exportModel: Compliance.ImportExportStagingRowsModel): Observable<number> {
        return this.httpPost(`/api/import/${importFileId}/exportStagingRows`, exportModel);
    }

    getExport(importFileId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/import/${importFileId}/export/${longRunningProcessId}`, options);
    }

    revertImport(importFileId: number): Observable<Compliance.LongRunningProcessModel> {
        return this.httpPut(`/api/import/${importFileId}/revert`, null);
    }

    getAvailableAttachmentTypes(entityTypeId: Core.EntityTypes): Observable<Compliance.AttachmentTypeViewModel[]>{
        return this.httpGet(`/api/import/${entityTypeId}/availableAttachmentTypes`);
    }

    getSystem(searchModel: Core.SearchModel<Compliance.ImportFilePropertyEnum>): Observable<Compliance.QueryResultModel<Compliance.ImportFileModel>> {
        return this.httpPost(`/api/import/system`, searchModel);
    }

    getExcelPreviewAsync(importFileId: number): Observable<Compliance.ExcelImportPreviewModel> {
        return this.httpGet(`/api/import/${importFileId}/getExcelPreview`);
    }

    updateExcelOptions(importFileId: number, model: Compliance.ImportFileExcelOptionsModel): Observable<Compliance.ImportFileModel> {
        return this.httpPut(`/api/import/${importFileId}/excelOptions`, model);
    }

    markAsCompleted(importFileId: number): Observable<Compliance.ImportFileModel>{
        return this.httpPost(`/api/import/${importFileId}/markAsCompleted`, null);
    }

    getAssessmentClasses(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<string[]>{
        return this.httpPut(`/api/import/${importFileId}/assessmentClasses`, selectedRowsModel);
    }

    getGLAccounts(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<Compliance.GLAccountModel[]>{
        return this.httpPut(`/api/import/${importFileId}/glAccounts`, selectedRowsModel);
    }

    getTaxingDistricts(importFileId: number, selectedRowsModel: Compliance.ImportBulkOperationModel): Observable<Core.TaxingDistrictModel[]>{
        return this.httpPut(`/api/import/${importFileId}/taxingDistricts`, selectedRowsModel);
    }

    startProcess(importFileId: number): Observable<Compliance.ImportFileModel> {
        return this.httpPost(`/api/import/${importFileId}/process`, null);
    }

    getGLAccountTypes(searchModel: Core.GLAccountTypeSearchModel): Observable<Compliance.GLAccountTypeModel[]> {
        return this.httpPost(`/api/glAccountType`, searchModel);
    }

    getAssetImportInProgressMetadataByLongRunningProcessId(longRunningProcessId: number): Observable<Compliance.AssetImportInProgressMetadataModel> {
        return this.httpGet(`/api/import/${longRunningProcessId}/getAssetImportInProgressMetadataByLongRunningProcessId`);
    }

    getAssetImportInProgressMetadataByCompanyId(companyId: number): Observable<Compliance.AssetImportInProgressMetadataModel> {
        return this.httpGet(`/api/import/${companyId}/getAssetImportInProgressMetadataByCompanyId`);
    }
}
