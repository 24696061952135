import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { TaxRateModule } from '../../Tax-Rate/tax.rate.module';
import { TaxRatesPanelTaxAuthoritiesCellRendererComponent } from './agGridTaxAuthoritiesCellRenderer.component';
import { TaxRatesPanelComponent } from './tax.rates.panel.component';
import { TaxRatesPanelService } from './tax.rates.panel.service';
import { TaxRatesService } from './tax.rates.service';

@NgModule({
    imports: [
        WeissmanSharedModule,
        TaxRateModule
    ],
    declarations: [
        TaxRatesPanelComponent,
        TaxRatesPanelTaxAuthoritiesCellRendererComponent,
    ],
    providers: [
        TaxRatesPanelService,
        TaxRatesService
    ],
    exports: [TaxRatesPanelComponent]
})
export class TaxRatesPanelModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('taxRatesPanel', TaxRatesPanelComponent);
    }
}
