import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { ExtendedContactModelCore } from '../../Common/User-Pickers/user.picker.component';
import { UserSettingsService } from '../../Account/userSettings.service';
import { ContactsUpgradeService } from '../../Contact/contacts.service.upgrade';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import SignaturePad from 'signature_pad';
import { AccountService } from '../account.service';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';

enum SignatureType {
    Create,
    Upload
}

@Component({
    selector: 'signature-panel',
    templateUrl: './signature-panel.component.html',
    styleUrls: ['./signature-panel.component.scss']
})
export class SignaturePanelComponent {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _userService: UserSettingsService,
        private readonly _contactService: ContactsUpgradeService,
        private readonly _accountService: AccountService,
        private readonly _cdRef: ChangeDetectorRef,
        private readonly _instanceRepository: InstanceRepository

    ) { }

    @Input() contact: Core.ContactModel;
    @Input() search: any;

    @ViewChild('signatureCanvas')
    set canvas(el: ElementRef) {
        if (el && !this._signaturePad) {
            this._canvas = el;
            this._signaturePad = new SignaturePad(this._canvas.nativeElement);
            this._signaturePad.onEnd = () => this.signatureChanged = true;
            this._cdRef.detectChanges();
        }
    }

    contactId = null;
    userId = null;
    instanceId: number = null;
    canEdit: boolean;
    isSignatureEditMode: boolean;
    isAuthorizedEditMode: boolean;
    addNewPerson: boolean;
    signatureLoading: boolean;
    usersAuthorizedLoading: boolean;

    signatureOptions: Compliance.NameValuePair<SignatureType>[] = [
        { name: 'Create (Preferred)', value: SignatureType.Create },
        { name: 'Upload', value: SignatureType.Upload }
    ];
    signatureType: SignatureType = SignatureType.Create;
    signatureTypes = SignatureType;
    signatureChanged: boolean;
    contactSignatureImage: string = '';
    newContactSignatureImage: string;
    userToAdd: Core.ContactShortModel;
    authorizedUsers: Core.ContactShortModel[] = [];
    authorizedUsersChanges: Core.ContactShortModel[] = [];

    imageFile: any;
    selectedFileType: string;
    canvasRotation: number = 0;
    uploadTypeError: boolean;
    transform: ImageTransform = {};

    private _signaturePad: SignaturePad;
    private _canvas: ElementRef;

    get canAddUser(): boolean {
        return !this.authorizedUsers.find(user => user.userID === this.userToAdd.userID);
    }

    get authorizedUsersChanged(): boolean {
        const original = this.authorizedUsers.map(u => u.userID).sort();
        const updated = this.authorizedUsersChanges.map(u => u.userID).sort();
        return !(updated.toString() === original.toString());
    }

    async ngOnInit(): Promise<void> {
        this.signatureLoading = true;
        this.usersAuthorizedLoading = true;

        try {
            this.contactId = this.contact.contactID;
            this.userId = this.contact.userID;

            this.instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('company', this.contact.companyID));

            this.canEdit = this._accountService.userData.id === this.contact.userID ||
                this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP, this.instanceId);

            await this._getUserSettings();
            await this._getContactSig();
        } finally {
            this.signatureLoading = false;
            this.usersAuthorizedLoading = false;
        }
    }

    // Signature

    beginSigEditMode(): void {
        if (!this.canEdit) { return; }
        this.isSignatureEditMode = true;
        this.newContactSignatureImage = null;
        this.signatureChanged = !!this.contactSignatureImage;
    }

    clearSignature(): void {
        this.imageFile = null;
        this.selectedFileType = null;
        if (!this._signaturePad) { return; }
        this._signaturePad.clear();
        this.newContactSignatureImage = null;
        this.signatureChanged = !!this.contactSignatureImage;
    }

    async saveSignature(): Promise<void> {
        this.isSignatureEditMode = false;
        this.signatureChanged = false;

        if (this.signatureType === SignatureType.Create) {
            this.newContactSignatureImage = (!this._signaturePad.isEmpty()) ? this._signaturePad.toDataURL() : null;
        }

        this.contactSignatureImage = this.newContactSignatureImage;
        this.signatureType = SignatureType.Create;

        this.signatureLoading = true;

        try {
            await this._contactService.saveContactSignature(this.contact.contactID, this.contactSignatureImage);

            this.imageFile = null;
            this.selectedFileType = null;
            this.newContactSignatureImage = null;
            this._signaturePad = null;

        } finally {
            this.signatureLoading = false;
        }
    }

    cancelSignature(): void {
        this.clearSignature();
        this.signatureType = SignatureType.Create;
        this.isSignatureEditMode = false;
        this._signaturePad = null;
        this.imageFile = null;
        this.selectedFileType = null;
        this.canvasRotation = 0;
    }

    signatureTypeChanged(type: Compliance.NameValuePair<SignatureType>): void {
        this.clearSignature();
        if (type.value === SignatureType.Upload) {
            this._signaturePad = null;
        }
    }

    // Upload

    filePicked(files: File[]): void {
        const { name } = files[0];
        const index = name.lastIndexOf('.');
        if (index !== 0) {
            const fileExtension = name.substr(index + 1).toLowerCase();
            if (fileExtension === 'gif' || fileExtension === 'png') {
                this.selectedFileType = fileExtension;
                this.imageFile = { target: { files: files } };
                this.uploadTypeError = false;
                this.signatureChanged = true;
                this._cdRef.detectChanges();
            } else {
                this.selectedFileType = null;
                this.imageFile = null;
                this.uploadTypeError = true;
            }
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.newContactSignatureImage = event.base64;
        this.signatureChanged = true;
    }

    loadImageFailed(): void {
        this.imageFile = null;
        this.selectedFileType = null;
        throw Error('There was an error uploading the ');
    }

    rotateImageLeft(): void {
        this.canvasRotation -= (this.canvasRotation === 0) ? -3 : 1;
    }

    rotateImageRight(): void {
        this.canvasRotation += (this.canvasRotation === 3) ? -3 : 1;
    }

    // Authorized users

    beginAuthEditMode(): void {
        this.isAuthorizedEditMode = true;
    }

    cancelAuthorized(): void {
        this.authorizedUsersChanges = [...this.authorizedUsers];
        this.isAuthorizedEditMode = false;
    }

    async saveAuthorized(): Promise<void> {
        this.isAuthorizedEditMode = false;

        if (this.authorizedUsersChanged) {

            const authUserModel = {
                groupId: 21,
                name: 'Users-Who-Are-Granted-Authorization-To-Apply-My-Digital-Signature',
                userId: this.userId,
                value: this.authorizedUsersChanges
            };

            this.usersAuthorizedLoading = true;

            try {
                const { value } = await this._userService.save(authUserModel);
                this.authorizedUsers = value;
                this.authorizedUsersChanges = [...value];
            } finally {
                this.usersAuthorizedLoading = false;
            }
        }
    }

    addUser(): void {
        this.addNewPerson = true;
    }

    removeUser(user: Core.ContactShortModel): void {
        this.cancelAddUser();
        const index = this.authorizedUsersChanges.indexOf(user);
        if (index > -1) {
            this.authorizedUsersChanges.splice(index, 1);
        }
    }

    cancelAddUser(): void {
        this.userToAdd = null;
        this.addNewPerson = false;
    }

    assignUser(user: ExtendedContactModelCore): void {
        const { userID, firstName, lastName } = user.model;
        this.userToAdd = { userID, firstName, lastName };
    }

    saveUser(): void {
        if (!this.userToAdd) {
            return;
        }

        this.authorizedUsersChanges.push(this.userToAdd);
        this.cancelAddUser();
    }

    private async _getUserSettings(): Promise<void> {
        const settings = await this._userService.getSettingsByGroupAndUserId(21, this.userId);

        if (settings && settings.length) {
            const user = settings[0];
            this.authorizedUsers = user.value;
            this.authorizedUsersChanges = [...user.value];
        }
    }

    private async _getContactSig(): Promise<void> {
        const sig = await this._contactService.getContactSignature(this.contactId);

        if (sig) {
            this.contactSignatureImage = sig.signatureBase64;
        }
    }
}
