export class ReturnSummaryReconciliationReportRow {
    reportableCost: number = 0;
    nonReportableCost: number = 0;
    unassignedCost: number = 0;

    get cost(): number {
        return this.reportableCost + this.nonReportableCost + this.unassignedCost;
    }
}

export class ReturnSummaryReconciliationReportAdditions {
    acquisitions: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    transferIns: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    costChanges: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    reportabilityChanges: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    reportableCost: number;
    nonReportableCost: number;
    unassignedCost: number;

    get cost(): number {
        return this.reportableCost + this.nonReportableCost + this.unassignedCost;
    }
}

export class ReturnSummaryReconciliationReportDeductions {
    disposals: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    transferOuts: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    costChanges: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    reportabilityChanges: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    suppressedAssets: ReturnSummaryReconciliationReportRow = new ReturnSummaryReconciliationReportRow();
    reportableCost: number;
    nonReportableCost: number;
    unassignedCost: number;
    estimatedFMV: number;

    get cost(): number {
        return this.reportableCost + this.nonReportableCost + this.unassignedCost;
    }
}

export class ReturnSummaryReconciliationReport {
    constructor(private _returnSummaryResult: Compliance.ReturnSummaryModel) {
        this._initialize();
    }

    priorReturnCostForward: ReturnSummaryReconciliationReportRow;
    existingOverridesTrueUp: ReturnSummaryReconciliationReportRow;
    existingDeductionsTrueUp: ReturnSummaryReconciliationReportRow;
    priorReturn: ReturnSummaryReconciliationReportRow;
    currentReturn: ReturnSummaryReconciliationReportRow;
    additions: ReturnSummaryReconciliationReportAdditions;
    deductions: ReturnSummaryReconciliationReportDeductions;
    notAssignedToAScheduleCost: number = 0;
    priorReportedValue: number = 0;
    currentReportedValue: number = 0;
    currentEstimatedFMV: number = 0;
    priorEstimatedFMV: number = 0;
    showEstimatedFMV: boolean = false;

    private _initialize(): void {
        // get reportable vs non-reportable data from the return summary
        const reportableSummary = this._returnSummaryResult.addsAndDeletes.find(x => x.scheduleType === 'Reported');
        const nonReportableSummary = this._returnSummaryResult.addsAndDeletes.find(x => x.scheduleType === 'Unreported');
        const unassignedSummary = this._returnSummaryResult.addsAndDeletes.find(x => x.scheduleType === 'Unassigned');

        // additions
        this.additions = new ReturnSummaryReconciliationReportAdditions();

        this.additions.acquisitions.reportableCost = reportableSummary.addAcquisitionsCost || 0;
        this.additions.acquisitions.nonReportableCost = nonReportableSummary.addAcquisitionsCost || 0;
        this.additions.acquisitions.unassignedCost = unassignedSummary.addAcquisitionsCost || 0;

        this.additions.transferIns.reportableCost = reportableSummary.addTransfersInCost || 0;
        this.additions.transferIns.nonReportableCost = nonReportableSummary.addTransfersInCost || 0;
        this.additions.transferIns.unassignedCost = unassignedSummary.addTransfersInCost || 0;

        this.additions.costChanges.reportableCost = reportableSummary.addCostChangesCost || 0;
        this.additions.costChanges.nonReportableCost = nonReportableSummary.addCostChangesCost || 0;
        this.additions.costChanges.unassignedCost = unassignedSummary.addCostChangesCost || 0;

        this.additions.reportabilityChanges.reportableCost = reportableSummary.addReportabilityChangesCost || 0;
        this.additions.reportabilityChanges.nonReportableCost = nonReportableSummary.addReportabilityChangesCost || 0;
        this.additions.reportabilityChanges.unassignedCost = unassignedSummary.addReportabilityChangesCost || 0;

        this.additions.reportableCost = reportableSummary.addCost || 0;
        this.additions.nonReportableCost = nonReportableSummary.addCost || 0;
        this.additions.unassignedCost = unassignedSummary.addCost || 0;

        // deductions
        this.deductions = new ReturnSummaryReconciliationReportDeductions();

        this.deductions.disposals.reportableCost = reportableSummary.deductionDisposalCost || 0;
        this.deductions.disposals.nonReportableCost = nonReportableSummary.deductionDisposalCost || 0;
        this.deductions.disposals.unassignedCost = unassignedSummary.deductionDisposalCost || 0;

        this.deductions.transferOuts.reportableCost = reportableSummary.deductionTransferOutCost || 0;
        this.deductions.transferOuts.nonReportableCost = nonReportableSummary.deductionTransferOutCost || 0;
        this.deductions.transferOuts.unassignedCost = unassignedSummary.deductionTransferOutCost || 0;

        this.deductions.costChanges.reportableCost = reportableSummary.deductionCostChangesCost || 0;
        this.deductions.costChanges.nonReportableCost = nonReportableSummary.deductionCostChangesCost || 0;
        this.deductions.costChanges.unassignedCost = unassignedSummary.deductionCostChangesCost || 0;

        this.deductions.reportabilityChanges.reportableCost = reportableSummary.deductionReportabilityChangesCost || 0;
        this.deductions.reportabilityChanges.nonReportableCost = nonReportableSummary.deductionReportabilityChangesCost || 0;
        this.deductions.reportabilityChanges.unassignedCost = unassignedSummary.deductionReportabilityChangesCost || 0;

        this.deductions.suppressedAssets.reportableCost = reportableSummary.suppressedAssetsCost || 0;
        this.deductions.suppressedAssets.nonReportableCost = nonReportableSummary.suppressedAssetsCost || 0;
        this.deductions.suppressedAssets.unassignedCost = unassignedSummary.suppressedAssetsCost || 0;

        this.deductions.reportableCost = reportableSummary.deductionCost || 0;
        this.deductions.nonReportableCost = nonReportableSummary.deductionCost || 0;
        this.deductions.unassignedCost = unassignedSummary.deductionCost || 0;

        // current return
        this.currentReturn = new ReturnSummaryReconciliationReportRow();
        this.currentReturn.reportableCost = reportableSummary.currentCost;
        this.currentReturn.nonReportableCost = nonReportableSummary.currentCost;
        this.currentReturn.unassignedCost = unassignedSummary.currentCost;

        // prior return cost forward
        this.priorReturnCostForward = new ReturnSummaryReconciliationReportRow();
        this.priorReturnCostForward.reportableCost = reportableSummary.priorCost;
        this.priorReturnCostForward.nonReportableCost = nonReportableSummary.priorCost;
        this.priorReturnCostForward.unassignedCost = unassignedSummary.priorCost;

        // existing overrides true up
        this.existingOverridesTrueUp = new ReturnSummaryReconciliationReportRow();
        this.existingOverridesTrueUp.reportableCost = reportableSummary.existingOverridesTrueUpCost;
        this.existingOverridesTrueUp.nonReportableCost = nonReportableSummary.existingOverridesTrueUpCost;
        this.existingOverridesTrueUp.unassignedCost = unassignedSummary.existingOverridesTrueUpCost;

        // existing disposals true up
        this.existingDeductionsTrueUp = new ReturnSummaryReconciliationReportRow();
        this.existingDeductionsTrueUp.reportableCost = reportableSummary.existingDeductionsTrueUpCost;
        this.existingDeductionsTrueUp.nonReportableCost = nonReportableSummary.existingDeductionsTrueUpCost;
        this.existingDeductionsTrueUp.unassignedCost = unassignedSummary.existingDeductionsTrueUpCost;

        // prior return
        this.priorReturn = new ReturnSummaryReconciliationReportRow();
        this.priorReturn.reportableCost = reportableSummary.priorCost + reportableSummary.existingOverridesTrueUpCost + reportableSummary.existingDeductionsTrueUpCost;
        this.priorReturn.nonReportableCost = nonReportableSummary.priorCost + nonReportableSummary.existingOverridesTrueUpCost + nonReportableSummary.existingDeductionsTrueUpCost;
        this.priorReturn.unassignedCost = unassignedSummary.priorCost + unassignedSummary.existingOverridesTrueUpCost + unassignedSummary.existingDeductionsTrueUpCost;

        // remaining values
        this.priorReportedValue = this._returnSummaryResult.priorRenderedValue;
        this.currentEstimatedFMV = this._returnSummaryResult.currentEstimatedFMV;
        this.priorEstimatedFMV = this._returnSummaryResult.priorEstimatedFMV;
        this.currentReportedValue = this._returnSummaryResult.currentRenderedValue;
        this.notAssignedToAScheduleCost = this._returnSummaryResult.unAssignedCost;
        this.showEstimatedFMV = this._returnSummaryResult.showEstimatedFMV;
    }
}
