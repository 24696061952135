(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('actionViewSortingDropdowns', actionViewSortingDropdowns);

	actionViewSortingDropdowns.$inject = [];

	function actionViewSortingDropdowns() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Task/ActionView/_actionViewSortingDropdowns.html',
			scope: {
				organizeBySelection: '=',
				groupBySelection: '=',
				groupBySelectionSecondary: '=',
				filterColumns: '=',
				masterFilter: '=',
				filterChanged: '=',
				pageLoading: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	Controller.$inject = [
        '$scope',
        'ActionViewGroupByTypes',
        '$rootScope',
        'productAnalyticsService'
	];

    function Controller() {
        weissNg1WrapFunc(init, arguments, this);
    }

	function init($scope, ActionViewGroupByTypes, $rootScope, productAnalyticsService) {
		var vm = this;

		if(vm.groupBySelection) {
			vm.groupBySelection =  Number(vm.groupBySelection)
		}

		vm.organizeByItems = [{ id: 1, name: 'Urgency' }, { id: 2, name: 'Due Date Range' }, { id: 3, name: 'Last Action Date Range' }];

		calculateGroupByOptions();
		$rootScope.$broadcast('actionViewFilterChanged', vm.masterFilter.ShowMostPressingTasks, vm.masterFilter.CalculateReadyAndNotReady, vm.masterFilter.showTotals);

		function calculateGroupByOptions() {
			var allGroupByOptions = angular.copy(ActionViewGroupByTypes);
			var groupByOptions = [];

			_.forEach(vm.filterColumns, function (value, key) {
				if(value.canGroupTaskActionView) {
					groupByOptions.push({
						id: value.columnId || value.advancedSearchFieldID || value.id,
						name: value.resultColumnName || value.resultsColumnName || value.displayName
					});
				}
			});

			_.forEach(vm.masterFilter.TaskOutputColumns, function (value, key) {
				if (value.canGroupTaskActionView) {
					groupByOptions.push({
						id: value.id || value.columnId || value.advancedSearchFieldID,
						name: value.resultColumnName || value.resultsColumnName || value.displayName
					});
				}
			});

			vm.groupByOptions = groupByOptions;
		}

		$scope.$watch('vm.filterColumns', function () {
			calculateGroupByOptions();
		});

		$scope.$watch('vm.masterFilter.TaskOutputColumns', function () {
			calculateGroupByOptions();
		})

        vm.changeOrganizeSelect = function () {
            productAnalyticsService.logEvent('select-action-view-organize-option', {
                actionViewOrganizeBy: vm.organizeByItems.find(x => x.id === vm.organizeBySelection)?.name
            });
        }

        vm.changeGroupSelect = function () {
            productAnalyticsService.logEvent('select-action-view-group-by-option', {
                actionViewGroupBy: vm.groupByOptions.find(x => x.id === vm.groupBySelection)?.name
            });
        }

		vm.filterChange = function (eventName) {
			$rootScope.$broadcast('actionViewFilterChanged', vm.masterFilter.ShowMostPressingTasks, vm.masterFilter.CalculateReadyAndNotReady, vm.masterFilter.showTotals);
            if (eventName) {
                productAnalyticsService.logEvent(eventName);
            }
		}
	}
})();
