import { Component, OnInit, Input } from '@angular/core';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import * as _ from 'lodash';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AssessmentClassService } from '../assessmentClass.service';
import { map, sortBy, filter, groupBy, join } from 'lodash/fp';
import { AssessmentClassModalParams, AssessmentClassModalComponent } from '../View/assessmentClassModal.component';

export interface AssessmentClassUI extends Weissman.Model.Assessments.AssessmentClass{
    propTypes: {
        abbr: string;
        componentsString: string;
    } [];
    isVisible?: boolean;
    fixedRatio: boolean;
    isHovered?: boolean;
}

@Component({
    selector: 'assessment-class-list-panel',
    templateUrl: './assessmentClassListPanel.component.html'
})
export class AssessmentClassListPanelComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _assessmentClassService: AssessmentClassService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: WeissmanModalService
    ) {}

    @Input() state: Weissman.Model.Misc.State;
    @Input() assessor: Core.AssessorModel;

    assessmentClasses: AssessmentClassUI[];
    serverAction: boolean = false;
    showStateClasses: boolean = false;
    hasEditPermission: boolean = false;

    async ngOnInit(): Promise<void> {
        this.hasEditPermission = this._restrictService.isInRole(Roles.STATEEDIT);

        await this._getAssessmentClasses();
    }

    onShowUnusedChanged(): void {
        this.assessmentClasses = _.map(this.assessmentClasses, this._setClassVisibility.bind(this));
    }

    async launchClassModal(assessmentClass?: AssessmentClassUI) {
        if(!assessmentClass) {
            const lastClass = _.maxBy(this.assessmentClasses, 'sequence');
            const sequence = lastClass ? lastClass.sequence + 1 : 0;
            assessmentClass = {
                stateID: this.state.stateID,
                name: '',
                description: '',
                ratioSetAtAssessor: true,
                sequence: sequence,
                fixedRatio: true,
                assessmentClassComponents: [],
                assessmentClassDefaults: [],
                assessmentClassRatios: [{
                    assessmentRatio: 1,
                    taxYear: null,
                    assessorID: this.assessor ? this.assessor.assessorID : null,
                    efAction: 'add'
                } as Weissman.Model.Assessments.AssessmentClassRatio],
                efAction: 'add'
            } as AssessmentClassUI;
        }
        const params: AssessmentClassModalParams = {
            assessmentClass: _.cloneDeep(assessmentClass),
            state: this.state,
            assessor: this.assessor
        };

        const reloadClasses = await this._modalService.showAsync(AssessmentClassModalComponent, params, 'modal-lg');

        if(reloadClasses) {
            await this._getAssessmentClasses();
        }
    }

    async deleteClass(assessmentClass: AssessmentClassUI) {
        try {
            await this._messageModalService.confirm(`Delete ${assessmentClass.name}?`);
        } catch(e) {
            return Promise.resolve();
        }

        this.serverAction = true;
        try {
            await this._assessmentClassService.deleteClass(assessmentClass.assessmentClassID);
            await this._getAssessmentClasses();
        } finally {
            this.serverAction = false;
        }
    }
    private async _getAssessmentClasses(): Promise<void> {
        this.serverAction = true;
        try {
            const assessorId = this.assessor ? this.assessor.assessorID : 0;
            const assessmentClasses = await this._assessmentClassService.getList(this.state.stateID, assessorId);
            this.assessmentClasses = _.flow([
                map(ac => {
                    const propTypes = _.flow([
                        filter('enabled'),
                        groupBy('propertyTypeID'),
                        map(components => {
                            const componentsString = _.flow([
                                map('assessmentComponent'),
                                map('componentName'),
                                join(', ')
                            ])(components);

                            return {
                                abbr: components[0].propertyType.propTypeAbbr,
                                componentsString
                            };
                        })
                    ])(ac.assessmentClassComponents);

                    if (this.assessor) {
                        ac.assessmentClassRatios = _.filter(ac.assessmentClassRatios, ratio => {
                            if (ac.ratioSetAtAssessor) {
                                return ratio.assessorID === this.assessor.assessorID;
                            }

                            return true;
                        });
                    }

                    ac.assessmentClassRatios = _.orderBy(ac.assessmentClassRatios, ['taxYear'], ['desc']);
                    const fixedRatio = !_.every(ac.assessmentClassRatios, 'taxYear');

                    return { ...ac, propTypes, fixedRatio };
                }),
                sortBy('sequence'),
                map(this._setClassVisibility.bind(this))
            ])(assessmentClasses);
        } finally {
            this.serverAction = false;
        }
    }

    private _setClassVisibility(ac: AssessmentClassUI) {
        if (this.assessor) {
            if (this.showStateClasses) {
                ac.isVisible = true;
            } else {
                ac.isVisible = !ac.ratioSetAtAssessor
                    || (ac.ratioSetAtAssessor
                        && _.some(ac.assessmentClassRatios, { assessorID: this.assessor.assessorID })
                    );
            }
        } else {
            ac.isVisible = true;
        }

        return ac;
    }

}
