<ng-template #text let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.textInvalid">
        <input type="text"
               autocomplete="off"
               class="form-control"
               [formControlName]="row.formControlName"
               [maxLength]="((row && row.validation && row.validation.maxLength) ? row.validation.maxLength : 100)" />
        <span *ngIf="row.formControl.errors?.textInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.textInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #number let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.numberInvalid">
        <input type="number"
               autocomplete="off"
               class="form-control"
               [formControlName]="row.formControlName"
               maxLength="100" />
        <span *ngIf="row.formControl.errors?.numberInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.numberInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #currency let-row="data" let-form="form">
    <div class="currency-cell-editor" [formGroup]="form">
        <span class="dollar-sign">$</span>
        <input type="number" class="form-control" min="0.00" step="0.01" [formControlName]="row.formControlName" />
    </div>
</ng-template>

<ng-template #date let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.dateInvalid">
        <weissman-datetime class="ws-stretch-width"
                           [inputDate]="row.formControl.value"
                           (inputDateChange)="dateValueChange($event, row)"
                           [isDisabled]="row.formControl.disabled"
                           [dateOnly]="true">
        </weissman-datetime>
        <span *ngIf="row.formControl.errors?.dateInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.dateInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #picklist let-row="data" let-form="form">
    <div [formGroup]="form">
        <ws-select labelProperty="name"
                   valueProperty="value"
                   [options]="row.picklist"
                   [formControlName]="row.formControlName">
        </ws-select>
    </div>
</ng-template>

<ws-modal-wrapper [headerLabel]="header"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  [disableSave]="!isFormValid"
                  [hideCancelX]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <div>
        <ul class="nav nav-tabs">
            <li class="uib-tab tab-item" [ngClass]="{'active' : !areSplitControlsVisible}"><a class="nav-link" (click)="showSplitControls(false)">Update Fields</a></li>
            <li class="uib-tab tab-item" [ngClass]="{'active' : areSplitControlsVisible}"><a class="nav-link" (click)="showSplitControls(true)">Verification</a></li>
        </ul>
    </div>
    <form [formGroup]="bulkUpdateForm">
        <div *ngIf="!areSplitControlsVisible">
            <table class="table table-striped table-condensed table-borderless-body">
                <tbody>
                <tr *ngFor="let row of updateOptions">
                    <td>{{row.name}}</td>
                    <td>
                        <ws-bulk-update-action [requiredField]="row.isRequired" [(fieldAction)]="row.action" (fieldActionChange)="rowActionChanged(row)"></ws-bulk-update-action>
                    </td>
                    <td>
                        <ng-container *ngIf="row.template" [ngTemplateOutlet]="row.template" [ngTemplateOutletContext]="{ data: row, form: bulkUpdateForm }"></ng-container>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="areSplitControlsVisible">
            <table class="table table-striped table-condensed table-borderless-body">
                <tbody>
                <tr *ngFor="let row of updateOptions">
                    <td>{{row.name}}</td>
                    <td>
                        <ws-select labelProperty="name"
                                   valueProperty="value"
                                   [options]="verificationOptions"
                                   [formControlName]="row.formControlName"
                                   (selectedOptionChanged)="validationActionChanged($event, row)">
                        </ws-select>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </form>
</ws-modal-wrapper>
