import { Component, Input, OnInit } from '@angular/core';
import { IEntityPicker } from './entityPicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridOptionsBuilder } from '../AgGrid';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';

export interface EntityPickerComponentParams<T> {
    entityPicker: IEntityPicker<T>;
    titleOverride?: string;
    optionFlagText?: string;
    optionFlagValue?: boolean;
}

@Component({
    selector: 'entity-picker',
    templateUrl: './entityPicker.component.html'
})
export class EntityPickerComponent<T> implements OnInit {
    constructor(private readonly _bsModalRef: BsModalRef) { }

    @Input() params: EntityPickerComponentParams<T>;
    title: string;

    private _gridApi: GridApi;

    entityPicker: IEntityPicker<T>;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && params.data.isSelected
            }
        })
        .withContext(this)
        .withInfiniteScroll()
        .withSingleRowSelect()
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withFloatingFilter()
        .withTextSelection()
        .build();

    get rowsSelected(): boolean {
        return (this.entityPicker && this.entityPicker.getSelectedIds().size > 0);
    }

    ngOnInit(): void {
        if (this.params) {
            this.entityPicker = this.params.entityPicker;
            this.title = this.params.titleOverride ? this.params.titleOverride : this.entityPicker.title;

            if (this.params.optionFlagValue != null) {
                this.entityPicker.optionFlag = this.params.optionFlagValue;
            }

        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = this.entityPicker.getColDefs();

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setDatasource(this.entityPicker.getDataSource(this._gridApi));
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    save(): void {
        this._bsModalRef.hide();
    }

    getResult(): any {
        return this.entityPicker.getResult();
    }
}
