<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="('Bulk Update ' + selectedCount + ' Records')"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid || isSaving">
    <div style="overflow-y: auto; max-height: calc(750px - 180px); height: calc(750px - 180px)">
            <form>
                <div class="table-responsive">
                    <table class="table table-striped table-condensed table-borderless-body">
                        <tbody>
                            <tr *ngFor="let mappedField of mappedFields">
                                <td>{{mappedField.name}}</td>
                                <td>
                                    <import-bulk-update-action [requiredField]="mappedField.isMappingRequired"
                                                               (fieldActionChange)="onActionChanged(mappedField, $event)"
                                                               [changeToAllowed]="isChangeToAllowed(mappedField)"></import-bulk-update-action>
                                </td>
                                <td>
                                    <div *ngIf="!mappedField.uiCellRenderer">
                                        <input [ngModel]="getValue(mappedField.importFileSpecificationFieldId)"
                                               (ngModelChange)="onValueChanged(mappedField.importFileSpecificationFieldId, $event)"
                                               type="text" [name]="mappedField.name" class="form-control"
                                               [disabled]="isDisabled(mappedField.importFileSpecificationFieldId)">
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'AssetClassSelector'">
                                        <asset-class-selector [isRequiredField]="false"
                                                              (assetClassificationChange)="onAssetClassificationChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                              [assetClassifications]="mappedField.additionalParams"
                                                              [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></asset-class-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'AssessorSelector'">
                                        <assessor-selector [isRequiredField]="false"
                                                           (assessorChanged)="onAssessorChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                           [assessors]="mappedField.additionalParams"
                                                           [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></assessor-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'AssessmentClassSelector'">
                                        <assessment-class-selector (changed)="onAssessmentClassChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                                   [values]="mappedField.additionalParams"
                                                                   [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></assessment-class-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'GLAccountSelector'">
                                        <glaccount-selector (changed)="onGLAccountChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                            [values]="mappedField.additionalParams"
                                                            [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></glaccount-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'TaxingDistrictSelector'">
                                        <taxing-district-selector (changed)="onTaxingDistrictChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                            [values]="mappedField.additionalParams"
                                                            [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></taxing-district-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'IncomeStatementCategorySelector'">
                                        <income-statement-category-selector [isRequiredField]="false"
                                                            (incomeStatementCategoryChange)="onIncomeStatementCategoryChanged(mappedField.importFileSpecificationFieldId, $event)"
                                                            [incomeStatementCategories]="mappedField.additionalParams"
                                                            [incomeStatementCategoryName]="getValue(mappedField.importFileSpecificationFieldId)"
                                                            [isDisabled]="isDisabled(mappedField.importFileSpecificationFieldId)"></income-statement-category-selector>
                                    </div>
                                    <div *ngIf="mappedField.uiCellRenderer === 'GLAccountTypeSelector'">
                                        <select class="form-select form-select-sm"
                                            (change)="onGlAccountTypeChanged(mappedField.importFileSpecificationFieldId, $event.target)"
                                            [disabled]="isDisabled(mappedField.importFileSpecificationFieldId)">
                                                <option *ngFor="let option of mappedField.additionalParams" [value]="option.name" [selected]="option.name === getValue(mappedField.importFileSpecificationFieldId)">{{option.name}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <p *ngIf="validationMessage" class="text-danger">{{validationMessage}}</p>
                </div>
            </form>
        </div>
</ws-modal-wrapper>
