<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="File Parameters"
                  acceptLabel="Export"
                  [suppressKeyboardEvents]="true"
                  (save)="export()"
                  (cancel)="cancel()">
    <div class="app-ui-view w-100 h-100" *ngIf="isInitialized">
        <div class="mb-4 row">
            <label for="fileType" class="col-md-5">File Type</label>
            <div class="col-md-5">
                <ws-select name="fileType"
                           labelProperty="name"
                           valueProperty="value"
                           [returnEntireOption]="false"
                           [(ngModel)]="fileType"
                           [options]="fileTypes">
                </ws-select>
            </div>
        </div>
        <div class="mb-4 row">
            <label for="status" class="col-md-5">Status</label>
            <div class="col-md-5">
                <ws-select name="status"
                           labelProperty="name"
                           valueProperty="value"
                           [returnEntireOption]="false"
                           [(ngModel)]="status"
                           [options]="statuses">
                </ws-select>
            </div>
        </div>
        <div class="mb-4 row">
            <label for="taxYear" class="col-md-5">Tax Year</label>
            <div class="col-md-5">
                <ws-select name="taxYear"
                           [isPrimitive]="true"
                           [returnEntireOption]="true"
                           [(ngModel)]="taxYear"
                           [options]="taxYears">
                </ws-select>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
