import { Component } from '@angular/core';
import { HelpContentComponent, HelpContentRendererValue } from '../../../UI-Lib/Help-Tooltip';
import { AppealFormItem } from '../../appeal.application.model';

@Component({
    selector: 'additional-account-tooltip',
    template: `
        <div style="text-align:left">
            <div><b>{{ appeal.siteName }} -
                {{ appeal.annualYear }}
                {{ appeal.propTypeAbbr }}
                {{ appeal.appealLevel }}</b></div>
            <b>Accounts:</b>
            <ul style="padding: 10px 15px 0 25px">
                <li>{{appeal.parcelAcctNum}}</li>
                <li *ngFor="let account of appeal.additionalAccounts">
                    {{account.parcelAcctNum}}
                </li>
            </ul>
        </div>
    `
})
export class AdditionalAccountTooltipComponent implements HelpContentComponent<HelpContentRendererValue<AppealFormItem>>{
    params: HelpContentRendererValue<AppealFormItem>;
    appeal: AppealFormItem;

    helpInit(): void {
        this.appeal = this.params.value;
    }
}
