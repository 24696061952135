<div *ngIf="userId" class="signature-panel ws-flex-container-vertical">

    <div class="signature-container">
        <div class="card panel-flat-color primary-panel">
            <div class="panel-flat-header ws-flex-container-horizontal">
                <h3>{{isSignatureEditMode ? 'Create your' : ''}} Signature</h3>
                <div class="ws-flex-grow" style="margin-left: 15px">
                    <ws-select *ngIf="isSignatureEditMode"
                               [options]="signatureOptions"
                               labelProperty="name"
                               valueProperty="value"
                               size="sm"
                               [(ngModel)]="signatureType"
                               (selectedOptionChanged)="signatureTypeChanged($event)">
                    </ws-select>
                </div>
                <div class="header-button-container float-end">
                    <button title="Clear Signature"
                            type="button"
                            class="btn flat-button icon-button primary-button"
                            (click)="clearSignature()"
                            *ngIf="isSignatureEditMode">
                        <i class="fa fa-trash fa-align"></i>
                    </button>
                    <button title="Save Signature"
                            type="button"
                            class="btn flat-button icon-button primary-button"
                            (click)="saveSignature()"
                            [disabled]="!signatureChanged"
                            *ngIf="isSignatureEditMode">
                        <i class="fa fa-save fa-align"></i>
                    </button>
                    <button title="Cancel"
                            type="button"
                            class="btn flat-button icon-button primary-button"
                            (click)="cancelSignature()"
                            *ngIf="isSignatureEditMode">
                        <i class="fa fa-times fa-align"></i>
                    </button>
                    <button *ngIf="canEdit && !isSignatureEditMode && !signatureLoading"
                            type="button"
                            class="btn flat-button icon-button primary-button"
                            (click)="beginSigEditMode()">
                        <i class="fa fa-pencil"></i>
                    </button>
                </div>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical">
                <loading-spinner [show]="signatureLoading" [size]="2" [hasOverlay]="true"></loading-spinner>
                <div *ngIf="!contactSignatureImage && !signatureLoading && !isSignatureEditMode" class="no-data-message no-signature-message">
                    <span (click)="beginSigEditMode()">It doesn't look like you have a signature. Click edit to create one.</span>
                    <span class="inline-text-button" (click)="beginSigEditMode()"><i class="fa fa-pencil"></i></span>
                </div>
                <div *ngIf="signatureType === signatureTypes.Create && !signatureLoading && (contactSignatureImage || isSignatureEditMode)"
                     class="signature-canvas"
                     [class.editing]="isSignatureEditMode">
                    <div *ngIf="contactSignatureImage || isSignatureEditMode" class="signing-line">
                        <span class="sig-x">X</span>
                        <div class="line">
                            <span *ngIf="isSignatureEditMode">Sign Above</span>
                        </div>
                    </div>
                    <canvas *ngIf="isSignatureEditMode" #signatureCanvas width="600" height="200"></canvas>
                    <img *ngIf="contactSignatureImage && !isSignatureEditMode" [attr.src]="contactSignatureImage" />
                </div>
                <div *ngIf="signatureType === signatureTypes.Upload && !signatureLoading && (contactSignatureImage || isSignatureEditMode)"
                     class="signature-upload"
                     [class.cropping]="imageFile && selectedFileType">
                    <div *ngIf="!imageFile || !selectedFileType" class="mb-4 ws-stretch-width">
                        <span>Upload Requirements</span>
                        <ul>
                            <li>File type must be *.gif or *.png</li>
                            <li>Use a single color transparency</li>
                            <li>Minimum 600 pixels wide by 200 pixels tall</li>
                            <li>Signature centered horizontally and vertically</li>
                        </ul>
                        <label>Select a file:</label>
                        <ws-file-picker (picked)="filePicked($event)" acceptTypes=".gif,.png"></ws-file-picker>
                        <span *ngIf="uploadTypeError" style="color: red;">The image must be of type .gif or .png</span>
                    </div>
                    <div class="upload-canvas ws-flex-container-vertical ws-flex-align-items-center"
                         [class.editing]="imageFile && selectedFileType">

                        <image-cropper [imageChangedEvent]="imageFile"
                                       [maintainAspectRatio]="true"
                                       [containWithinAspectRatio]="true"
                                       [aspectRatio]="3 / 1"
                                       [resizeToWidth]="600"
                                       [cropperMinWidth]="128"
                                       [onlyScaleDown]="true"
                                       [canvasRotation]="canvasRotation"
                                       [transform]="transform"
                                       [alignImage]="'center'"
                                       [style.display]="(imageFile && selectedFileType) ? null : 'none'"
                                       format="png"
                                       (imageCropped)="imageCropped($event)"
                                       (loadImageFailed)="loadImageFailed()">
                        </image-cropper>
                        <div *ngIf="imageFile && selectedFileType" class="ws-flex-container-horizontal ws-flex-justify-content-center">
                            <button title="Rotate Left 90"
                                    type="button"
                                    class="btn flat-button icon-button primary-button"
                                    (click)="rotateImageLeft()">
                                <i class="fa fa-undo fa-align"></i>
                            </button>
                            <button title="Save Signature"
                                    type="button"
                                    class="btn flat-button icon-button primary-button"
                                    (click)="saveSignature()">
                                <i class="fa fa-save fa-align"></i>
                            </button>
                            <button title="Rotate Right 90"
                                    type="button"
                                    class="btn flat-button icon-button primary-button"
                                    (click)="rotateImageRight()">
                                <i class="fa fa-repeat fa-align"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="authorized-container card panel-flat-color primary-panel">
        <div class="panel-flat-header">
            <h3>Users Authorized</h3>
            <div class="header-button-container float-end">
                <button title="Add User"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="addUser()"
                        *ngIf="isAuthorizedEditMode">
                    <i class="fa fa-plus fa-align"></i>
                </button>
                <button title="Save Signature"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="saveAuthorized()"
                        [disabled]="!authorizedUsersChanged"
                        *ngIf="isAuthorizedEditMode">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button title="Cancel"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="cancelAuthorized()"
                        *ngIf="isAuthorizedEditMode">
                    <i class="fa fa-times fa-align"></i>
                </button>
                <button *ngIf="contactSignatureImage && canEdit && !isAuthorizedEditMode && !usersAuthorizedLoading"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="beginAuthEditMode()">
                    <i class="fa fa-pencil"></i>
                </button>
            </div>
        </div>
        <div class="panel-flat-body ws-flex-container-vertical">
            <loading-spinner [show]="usersAuthorizedLoading" [size]="2" [hasOverlay]="true"></loading-spinner>
            <div *ngIf="!contactSignatureImage && !usersAuthorizedLoading" class="no-data-message no-signature-message">
                <span (click)="beginSigEditMode()">A signature is required to add authorized users.</span>
            </div>
            <div *ngIf="contactSignatureImage && !authorizedUsersChanges.length && !addNewPerson" class="no-data-message">
                <span>There are no additional authorized users for this signature.</span>
            </div>
            <div *ngIf="isAuthorizedEditMode && addNewPerson" class="ws-flex-container-horizontal ws-flex-align-self-center ws-flex-align-items-center">
                <user-picker [search]="search" (contactChanged)="assignUser($event)"></user-picker>
                <button class="btn flat-button small-button primary-button" (click)="saveUser()" [disabled]="!(userToAdd && canAddUser)">OK</button>
                <button class="btn flat-button small-button warning-button" (click)="cancelAddUser()">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div *ngIf="contactSignatureImage && authorizedUsersChanges.length">
                <table class="table table-sm mb-0 caption-top">
                    <caption>Users who are granted authorization to apply the digital signature</caption>
                    <tbody>
                        <tr *ngFor="let user of authorizedUsersChanges">
                            <td>
                                {{ user.lastName }}, {{ user.firstName }}
                            </td>
                            <td style="width: 40px">
                                <button *ngIf="isAuthorizedEditMode" class="btn flat-button icon-button warning-button" type="button" (click)="removeUser(user)" style="margin: 0px">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
