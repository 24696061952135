import { Component, ChangeDetectorRef, Input, Output, ViewChild, ElementRef, EventEmitter, HostListener } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import { DropdownPosition } from '../Select/select.interface';
import { Subject } from 'rxjs';
import { TypeaheadConfig } from './typeahead.service';

@Component({
    selector: 'ws-typeahead-component',
    templateUrl: './typeahead.component.html',
    styleUrls: ['./typeahead.component.scss'],
    animations: [
        trigger('dropdownVisible', [
            state('show', style({
                height: '*'
            })),
            state('hide', style({
                height: '0'
            })),
            transition('show => hide', animate('200ms ease')),
            transition('hide => show', animate('200ms ease'))
        ])
    ]
})
export class TypeaheadComponent {
    constructor(private readonly _changDetectorRef: ChangeDetectorRef) { }

    @Input()
    set typeaheadConfig(config: TypeaheadConfig) {
        this.config = config;
    };

    @Input()
    set targetPosition(target: DOMRect) {
        this._targetPosition = target;
        this.updateDropdownPosition();
    };

    @Input()
    set isOpen(open: boolean) {
        this.open = open;
    };

    @Output() close = new EventEmitter<any>();

    @ViewChild('dropdown') dropdown: ElementRef;

    @HostListener('window:scroll') onWindowScroll(): void {
        this.updateDropdownPosition();
    }

    @HostListener('window:resize') onResize(): void {
        this.updateDropdownPosition();
    }

    config: TypeaheadConfig;
    open: boolean;
    searchValue: string;
    dropdownPosition: DropdownPosition;
    listValues: any[] = [];

    private _targetPosition: DOMRect;
    private _edgeBuffer = 10;
    private _destroy$: Subject<void> = new Subject();

    /**
     * Update the position of the dropdown
     */
    updateDropdownPosition() {
        // Pre-render to calculate right edge detection accurately
        this.dropdownPosition = {
            left: `${window.innerWidth / 2 - 130}px`,
            top: `10px`,
            width: `${this._targetPosition.width}px`
        };
        this._changDetectorRef.detectChanges();

        // Calculate actual position
        const boundingBox = this.dropdown.nativeElement.getBoundingClientRect();

        const top = this._targetPosition.y + this._targetPosition.height + window.scrollY;
        const left = this._targetPosition.x;

        const buffer = this._getBuffer(boundingBox, top, left);

        if (buffer.topBuffer < 0) {
            // TODO handle bottom up
        }

        this.dropdownPosition = {
            left: `${left + buffer.leftBuffer}px`,
            top: `${top}px`,
            width: `${this._targetPosition.width}px`
        };
        this._changDetectorRef.detectChanges();
    }

    /**
     * Emit selected option
     * @param option
     */
    optionSelected(option: any): boolean {
        if (option && option[this.config.disabledProperty]) {
            return false;
        }

        if (this.config.selectedOption !== option) {
            this.config.selectedOption = option;
            this.config.selectedOptionChanged(option);
        }

        this._destroy$.next();
        this.close.emit(this.config);
        // prevent event propagation
        return false;
    }

    /**
     * Close the dropdown
     */
    closeDropdown(): void {
        if (!this.config.focused) {
            this._destroy$.next();
            this.close.emit(this.config);
        }
    }

    /**
     * Check distance to edge of screen
     * @param element
     * @param idealTop
     * @param idealLeft
     */
    private _getBuffer(element: DOMRect, idealTop: number, idealLeft: number): any {
        const wY = window.innerHeight;
        const wX = window.innerWidth;
        const closestXEdgeDistance = (idealLeft < (wX - (idealLeft + element.width))) ? idealLeft : (wX - (idealLeft + element.width));
        const closestYEdgeDistance = (idealTop < (wY - (idealTop + element.height))) ? idealTop : (wY - (idealTop + element.height));
        const isXAdditive = idealLeft < (wX - (idealLeft + element.width));
        const isYAdditive = idealTop < (wY - (idealTop + element.height));

        let xBuffer = 0;
        if (closestXEdgeDistance < this._edgeBuffer) {
            xBuffer = this._edgeBuffer - closestXEdgeDistance;
            xBuffer = (isXAdditive) ? xBuffer : -xBuffer;
        }
        let yBuffer = 0;
        if (closestYEdgeDistance < this._edgeBuffer) {
            yBuffer = this._edgeBuffer - closestYEdgeDistance;
            yBuffer = (isYAdditive) ? yBuffer : -yBuffer;
        }

        return {
            leftBuffer: xBuffer,
            topBuffer: yBuffer,
            pointerXBuffer: xBuffer + 12,
            pointerYBuffer: yBuffer + 12,
        };
    }
}
