import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReturnFilingControlRow } from '../../../../Models/filingControlReconciliationReport';

export interface ICellRendererParamsForConsolidatedGroupCellRenderer extends ICellRendererParams {
    toggleShowMergedParcels: (params: ICellRendererParamsForConsolidatedGroupCellRenderer) => void;
}

@Component({
    selector: 'ws-ag-grid-consolidated-group',
    template: `
        <span class="ws-expand-button ws-display-inline-block">
            <button *ngIf="filingControl.isGroupRow"
                    class="ws-flex-none"
                    (click)="params.toggleShowMergedParcels(params)">
                <i class="fa" [ngClass]="filingControl.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
            </button>
        </span>
        <span *ngIf="filingControl.isSubTotalRow || filingControl.isTotalRow">Total</span>
        <a *ngIf="!(filingControl.isSubTotalRow || filingControl.isTotalRow)"
            class="grid-link-cell"
            [ngClass]="{ 'child-row': filingControl.isMergedParcel }"
            [href]="link"
            target="_blank"
            rel="noopener noreferrer"
            position="bottom"
            helpTooltip
            helpContentId="app.view-parcel">
            {{filingControl.parcelAcctNumberDisplay}}
        </a>
    `
})
export class AgGridConsolidatedGroupCellRenderer implements ICellRendererAngularComp  {
    params: ICellRendererParamsForConsolidatedGroupCellRenderer;
    filingControl: ReturnFilingControlRow;
    link: string;

    agInit(params: ICellRendererParamsForConsolidatedGroupCellRenderer): void {
        this.params = params;
        this.filingControl = params.data as ReturnFilingControlRow;
        this.link = `#/parcel/${this.filingControl.parcelId}`;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
