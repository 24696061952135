import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AssessmentClassSelectorComponent } from "../Selectors/AssessmentClass-Selector/assessmentClassSelector.component";

export interface EntityImportEditorAssessmentClassSelectorCellEditorParams extends ICellEditorParams {
    getAssessmentClasses(rowIndex: number): string[];
}

@Component({
    selector: 'entity-import-editor-assessment-class-selector',
    template: `
        <assessment-class-selector #assessmentClassSelector
                                  [formControl]="assessmentClass"
                                  (changed)="onChanged($event)"
                                  [values]="values"></assessment-class-selector>`
})
export class EntityImportEditorAssessmentClassSelectorCellEditorComponent implements ICellEditorAngularComp{
    gridRow: Compliance.ImportGridRowGridModel;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.assessmentClass.value;
    }

    isCancelAfterEnd(): boolean {
        if (this.assessmentClassSelector.value === ''){
            this._params.node.setDataValue(this._params.column.getColId(), null);
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    assessmentClass: UntypedFormControl = new UntypedFormControl();
    values: string[];

    @ViewChild("assessmentClassSelector", { static: true }) assessmentClassSelector: AssessmentClassSelectorComponent;

    private _params: EntityImportEditorAssessmentClassSelectorCellEditorParams;

    async agInit(params: EntityImportEditorAssessmentClassSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;
        this.assessmentClass.setValue(params.value);
        this.values = await params.getAssessmentClasses(this.gridRow.rowIndex);
    }

    onChanged(value: string) {
        if (this.assessmentClass.value !== value) {
            this.assessmentClass.setValue(value);
            this._params.node.setDataValue(this._params.column.getColId(), value);
        }
    }
}
