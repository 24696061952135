import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcelIconComponent } from './parcelIcon.component';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../../Hybrid/hybrid-adapter.utility';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule
    ],
    declarations: [
        ParcelIconComponent
    ],
    exports: [
        ParcelIconComponent
    ]
})
export class ParcelIconModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('parcelIcon', ParcelIconComponent);
    }
}
