import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DirectivesModule } from '../Directives/directives.module';
import { CurrencyInputComponent } from './currencyInput.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        CurrencyMaskModule,
        DirectivesModule
    ],
    declarations: [
        CurrencyInputComponent
    ],
    exports: [
        CurrencyInputComponent
    ]
})
export class CurrencyModule { }
