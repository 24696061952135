import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, BehaviorSubject, lastValueFrom } from 'rxjs';
import { FormRepository, FilingBatchRepository, ReturnRepository, ReturnFormRevisionRepository } from '../Repositories';
import { WebsocketListenerService } from '../websocketListener.service';
import { ReturnPartServiceBase } from './Models/returnPartServiceBase';
import { HttpResponse } from '@angular/common/http';
import { FilingBatchService } from '../Filing-Batch/filingBatch.service';
import * as _ from 'lodash';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { AgGridColumns, AgGridFilterParams } from '../AgGrid';
import {
    AgGridCheckboxCellRendererComponent,
    ICellRendererParamsForAgGridCheckbox
} from '../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { ColDef } from 'ag-grid-community';
import { DecimalPipe } from '@angular/common';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';

export interface ReturnServiceSharedState {
    returns: Compliance.ReturnTotalsModel[];
    reports?: Compliance.FilingBatchReportModel[];
    formRevisionId: number;
    returnFormRevisionsUpdatedTimestamp: number;
}

@Injectable()
export class ReturnService extends ReturnPartServiceBase {
    constructor(
        private readonly _formRepository: FormRepository,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _returnRepository: ReturnRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _returnFormRevisionRepository: ReturnFormRevisionRepository,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _restrictService: RestrictService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _datePipe: WeissmanDateFormatPipe
    ) { super(); }

    private _sharedState: ReturnServiceSharedState = {
        returns: [],
        reports: [],
        formRevisionId: null,
        returnFormRevisionsUpdatedTimestamp: null
    };

    // a list of available form revision for each assessor in the selected returns
    private _associatedReturnFormRevisions: Compliance.ReturnFormRevisionModel[] = [];
    private _notAssociatedReturnFormRevisions: Compliance.ReturnFormRevisionModel[] = [];

    // a dictionary of key/value pairs tracking the latest selected form revision for each filing or filing batch
    // the filing (or filing batch) ID is the key and the form revision ID is the value
    private _lastSelectedFormRevisionIds = {
        filingBatch: {}
    };

    private _filingBatch: Compliance.FilingBatchModel;
    private _lienDate: Date;
    private _tasks: Core.TaskModel[] = [];
    private _priorCompletedTask: Core.TaskModel;
    private _currentTask: Core.TaskModel;
    private _nextTask: Core.TaskModel;
    private _company: Core.CompanyModel;
    private _canEditCompany: boolean;
    private _canViewRyanPrivateItems: boolean;
    private _companyAssetDescriptorMappings: Compliance.CompanyAssetDescriptorMappingModel[];
    private _formRevisionId: number;
    private _validateFormsResult: Compliance.ReturnValidateFormsResultModel;
    private _compareFormsResult: Compliance.ReturnCompareFormsResultModel;
    private _returnSummary: Compliance.ReturnSummaryModel;
    private _isReturnInReadOnly: boolean = true;
    private _returnGroupDetails: Compliance.ReturnTotalsModel[] = [];
    private _consolidatedReturns: Set<number> = new Set();
    private _isConsolidatedReturn: boolean;
    private _cutOffDate: Date;
    private _changeDetection: string;
    private _ages: string[];

    private _startSubject: Subject<void> = new Subject();
    private _returnsSubject: BehaviorSubject<Compliance.ReturnTotalsModel[]> = new BehaviorSubject(null);
    private _formRevisionIdSubject: Subject<number> = new Subject();
    private _filingBatchProcessStatusSubject: Subject<Compliance.FilingBatchProcessStatusEnum> = new Subject();
    private _filingBatchProgressSubject: Subject<Compliance.LongRunningProcessProgressChangeModel> = new Subject();
    private _isReturnInReadOnlyModeSubject: BehaviorSubject<boolean> = new BehaviorSubject(this.isReturnInReadOnly);
    private _tasksSubject: Subject<void> = new Subject();
    private _compareFormsSubject: BehaviorSubject<Compliance.ReturnCompareFormsResultModel> = new BehaviorSubject(null);
    private _validateFormsSubject: BehaviorSubject<Compliance.ReturnValidateFormsResultModel> = new BehaviorSubject(null);
    private _returnBatchFilterSubject: Subject<number[]> = new Subject();
    private _longRunningProcessIdSubject: BehaviorSubject<number> = new BehaviorSubject(null);
    private _parcelsChangedSubject: BehaviorSubject<Date> = new BehaviorSubject(null);

    // subscriptions
    private _filingBatchStatusChangeSub: Subscription;
    private _filingBatchProgressChangeSub: Subscription;

    get sharedState(): ReturnServiceSharedState { return this._sharedState; }
    get editGroup(): string { return 'return-edit-group'; }
    get company(): Core.CompanyModel { return this._company; }
    get companyId(): number { return this._company && this._company.companyID; }
    get canEditCompany(): boolean { return this._canEditCompany; }
    get canViewRyanPrivateItems(): boolean { return this._canViewRyanPrivateItems; }
    get companyAssetDescriptorMappings(): Compliance.CompanyAssetDescriptorMappingModel[] { return this._companyAssetDescriptorMappings; }
    get filingBatch(): Compliance.FilingBatchModel { return this._filingBatch; }
    get filingBatchId(): number { return this._filingBatch ? this.filingBatch.filingBatchId : null; }
    get longRunningProcessId(): number { return this._filingBatch && this._filingBatch.longRunningProcessId; }
    get tasks(): Core.TaskModel[] { return this._tasks; }
    get priorCompletedTask(): Core.TaskModel { return this._priorCompletedTask; }
    get currentTask(): Core.TaskModel { return this._currentTask; }
    get nextTask(): Core.TaskModel { return this._nextTask; }
    get taxYear(): number { return this._filingBatch ? this.filingBatch.taxYear : null; }
    get stateId(): number { return this._filingBatch ? this.filingBatch.stateId : null; }
    get lienDate(): Date { return this._lienDate; }
    get formRevisionId(): number { return this._formRevisionId; }
    get processStatus(): Compliance.FilingBatchProcessStatusEnum { return this._filingBatch && this._filingBatch.processStatus; }
    get processStatusError(): string { return this._filingBatch && this._filingBatch.longRunningProcessError; }
    get isReturnInReadOnlyMode(): boolean { return this._isReturnInReadOnlyMode(); }
    get isReturnInReadOnlyMode$(): Observable<boolean> { return this._isReturnInReadOnlyModeSubject.asObservable(); }
    get isLongRunningProcessActive(): boolean { return this._filingBatchService.isLongRunningProcessActive(this._filingBatch && this._filingBatch.processStatus); }
    get isNotStarted(): boolean { return this._filingBatchService.isNotStarted(this._filingBatch && this._filingBatch.processStatus); }
    get isLocking(): boolean { return this._filingBatchService.isLocking(this._filingBatch && this._filingBatch.processStatus); }
    get isUnlocking(): boolean { return this._filingBatchService.isUnlocking(this._filingBatch && this._filingBatch.processStatus); }
    get isLocked(): boolean { return this._filingBatchService.isLocked(this._filingBatch && this._filingBatch.processStatus); }
    get isFinalizing(): boolean { return this._filingBatchService.isFinalizing(this._filingBatch && this._filingBatch.processStatus); };
    get isFinalized(): boolean { return this._filingBatchService.isFinalized(this._filingBatch && this._filingBatch.processStatus); };
    get isSigning(): boolean { return this._filingBatchService.isSigning(this._filingBatch && this._filingBatch.processStatus); };
    get isUnsigning(): boolean { return this._filingBatchService.isUnsigning(this._filingBatch && this._filingBatch.processStatus); };
    get isSigned(): boolean { return this._filingBatchService.isSigned(this._filingBatch && this._filingBatch.processStatus); };
    get parcelName(): string { return this._sharedState.returns.length && this._sharedState.returns[0].parcelAcctNumber; }
    get parcelId(): number { return this._sharedState.returns.length && this._sharedState.returns[0].parcelId; }
    get returnGroupDetails(): Compliance.ReturnTotalsModel[] { return this._returnGroupDetails }
    get validateFormsResult(): Compliance.ReturnValidateFormsResultModel { return this._validateFormsResult; }
    get compareFormsResult(): Compliance.ReturnCompareFormsResultModel { return this._compareFormsResult; }
    get returnSummary(): Compliance.ReturnSummaryModel { return this._returnSummary; }
    get isReturnInReadOnly(): boolean { return this._isReturnInReadOnly; }
    get isConsolidatedReturn(): boolean { return this._isConsolidatedReturn; } //todo: a batch has many returns and could be a mix of consolidated and not consolidated, so it doesn't make sense to have a single flag here

    get start$(): Observable<void> { return this._startSubject.asObservable(); }
    get returns$(): Observable<Compliance.ReturnTotalsModel[]> { return this._returnsSubject.asObservable() }
    get formRevisionId$(): Observable<number> { return this._formRevisionIdSubject.asObservable(); }
    get processStatus$(): Observable<Compliance.FilingBatchProcessStatusEnum> { return this._filingBatchProcessStatusSubject.asObservable(); }
    get progress$(): Observable<Compliance.LongRunningProcessProgressChangeModel> { return this._filingBatchProgressSubject.asObservable(); }
    get tasks$(): Observable<void> { return this._tasksSubject.asObservable(); }
    get compareForms$(): Observable<Compliance.ReturnCompareFormsResultModel> { return this._compareFormsSubject.asObservable(); }
    get validateForms$(): Observable<Compliance.ReturnValidateFormsResultModel> { return this._validateFormsSubject.asObservable(); }
    get returnBatchFilter$(): Observable<number[]> { return this._returnBatchFilterSubject.asObservable(); };
    get longRunningProcessId$(): Observable<number> { return this._longRunningProcessIdSubject.asObservable(); }
    get parcelsChanged$(): Observable<Date> { return this._parcelsChangedSubject.asObservable(); }

    async prepareData(filingBatchId: number, onlyReloadFilingBatchDetails: boolean = false): Promise<void> {
        this._sharedState.returns = [];
        this._sharedState.reports = [];

        const startRequest: Compliance.ReturnPrepareDataRequestModel = {
            filingBatchId: filingBatchId,
            forceCurrent: false,
            formRevisionId: this._getLastSelectedFormRevisionId(filingBatchId)
        };

        const result = await lastValueFrom(this._returnRepository.prepareData(startRequest));
        this._setData(result, onlyReloadFilingBatchDetails);
    }

    async startPrepareData(filingBatchId: number): Promise<void> {
        this._sharedState.returns = [];
        this._sharedState.reports = [];
        this._filingBatch.processStatus = Compliance.FilingBatchProcessStatusEnum.PreparingData;

        const startRequest: Compliance.ReturnPrepareDataRequestModel = {
            filingBatchId: filingBatchId,
            forceCurrent: false,
            formRevisionId: this._getLastSelectedFormRevisionId(filingBatchId)
        };

        const result = await lastValueFrom(this._returnRepository.startPrepareData(startRequest));

        this._filingBatch.longRunningProcessId = result.longRunningProcessId;
        this._filingBatch.processStatus = result.filingBatchProcessStatus;
        this._longRunningProcessIdSubject.next(result.longRunningProcessId);
        this.notifyProcessStatusChange(result.filingBatchProcessStatus);
    }

    async loadPreparedData(filingBatchId: number, onlyReloadFilingBatchDetails: boolean = false): Promise<void> {
        this._sharedState.returns = [];
        this._sharedState.reports = [];

        const result = await lastValueFrom(this._returnRepository.getPreparedData(filingBatchId));

        this._setData(result, onlyReloadFilingBatchDetails);
    }

    stop(): void {
        this._sharedState = {
            returns: [],
            reports: [],
            formRevisionId: null,
            returnFormRevisionsUpdatedTimestamp: null
        };
        this._associatedReturnFormRevisions = [];
        this._notAssociatedReturnFormRevisions = [];
        this._filingBatch = null;
        this._lienDate = null;
        this._tasks = [];
        this._priorCompletedTask = null;
        this._currentTask = null;
        this._nextTask = null;
        this._company = null;
        this._canEditCompany = false
        this._canViewRyanPrivateItems = false;
        this._companyAssetDescriptorMappings = null;
        this._formRevisionId = null;
        this._validateFormsResult = null;
        this._compareFormsResult = null;
        this._returnSummary = null;
        this._isReturnInReadOnly = true;
        this._returnGroupDetails = [];
        this._consolidatedReturns = new Set();
        this._isConsolidatedReturn = false;

        this._returnsSubject.next(null);
        this._isReturnInReadOnlyModeSubject.next(this._isReturnInReadOnly);
        this._compareFormsSubject.next(null);
        this._validateFormsSubject.next(null);
        this._longRunningProcessIdSubject.next(null);
        this._parcelsChangedSubject.next(null);

        this._filingBatchStatusChangeSub && this._filingBatchStatusChangeSub.unsubscribe();
        this._filingBatchProgressChangeSub && this._filingBatchProgressChangeSub.unsubscribe();
    }

    /**
     * Sets the collection of returns that the user has selected as part of the working set.
     * Loads all the available forms for the assessors on the selected returns.
     * @param returns Returns
     */
    setReturns(returns: Compliance.ReturnTotalsModel[]): void {
        this._sharedState.returns = returns || [];

        // get the last used form revision
        const lastSelectedFormRevisionId = this._getLastSelectedFormRevisionId(this._filingBatch.filingBatchId);
        this._setFormRevisionId(lastSelectedFormRevisionId);
        returns.forEach(x => {
            if(x.isMergedParcel|| x.isConsolidatedParcel) {
                this._consolidatedReturns.add(x.returnId)
            }
        });

        this._isConsolidatedReturn = returns.some(x => x.isMergedParcel);

        this._sharedState.returnFormRevisionsUpdatedTimestamp = Date.now();

        this._returnsSubject.next(this._sharedState.returns);
    }


    /**
     * Basically the same as setReturns but makes a request to update the return forms
     * This is only used when the user changes the checkboxes in the return batch list
     * @param returns
     */
    async setReturnFilterChanged(returns: Compliance.ReturnTotalsModel[]): Promise<void> {
        this._sharedState.returns = returns || [];

        // get the last used form revision
        const lastSelectedFormRevisionId = this._getLastSelectedFormRevisionId(this._filingBatch.filingBatchId);
        this._setFormRevisionId(lastSelectedFormRevisionId);
        returns.forEach(x => {
            if(x.isMergedParcel|| x.isConsolidatedParcel) {
                this._consolidatedReturns.add(x.returnId)
            }
        });
        this._isConsolidatedReturn = returns.some(x => x.isMergedParcel);

        this._sharedState.returnFormRevisionsUpdatedTimestamp = Date.now();

        await this.loadReturnFormRevisions();
        this._returnsSubject.next(this._sharedState.returns);
    }

    setReturnTotals(returnDetails: Compliance.ReturnTotalsModel[]): void {
        this._returnGroupDetails = returnDetails;
    }

    setFirstFormRevisionId(saveToSharedState = false): void {
        const primaryFormCount = this._associatedReturnFormRevisions.reduce((acc, x) => {
            if (!acc[x.formRevisionId]) {
                acc.count += x.isPrimary ? 1 : 0;
                acc[x.formRevisionId] = true;
            }
            return acc;
        }, { count: 0 }).count;
        const firstFormRevision = _.find(this._associatedReturnFormRevisions);
        const formRevisionId = (primaryFormCount <= 1 && firstFormRevision) ? firstFormRevision.formRevisionId : null;
        if (saveToSharedState) {
            this.setFormRevisionId(formRevisionId);
        } else {
            this._formRevisionId = formRevisionId;
            this._formRevisionIdSubject.next(this._formRevisionId);
        }
    }

    /**
     * Sets the form revision that the user has selected for the specified working set.
     * @param formRevisionId The form revision ID.
     */
    setFormRevisionId(formRevisionId: number): void {
        this._formRevisionId = formRevisionId;
        this._setFormRevisionId(formRevisionId);
        this._formRevisionIdSubject.next(formRevisionId);
    }

    getDefaultFactorTables(): Promise<Compliance.FactorTableListItemModel[]> {
        return lastValueFrom(this._formRepository.getFactorTableList({
            taxYear: this.taxYear,
            stateId: this.stateId,
            tableType: null,
            includeRetiredTables: false
        } as Compliance.FactorTableSearchModel))
            .then(x => x.filter(item => item.assessorName === null));
    }

    /**
     * Returns all the form revisions that are associated with the selected returns.
     */
    getAssociatedReturnFormRevisions(): Compliance.ReturnFormRevisionModel[] {
        return this._associatedReturnFormRevisions;
    }

    getUniqueAssociatedReturnFormRevisions(): Compliance.ReturnFormRevisionModel[] {
        return _.uniqBy(this._associatedReturnFormRevisions, x => x.formRevisionId);
    }

    /**
     * Gets all the form revisions that are available to be associated with the selected returns.
     */
    getNotAssociatedReturnFormRevisions(): Compliance.ReturnFormRevisionModel[] {
        return this._notAssociatedReturnFormRevisions;
    }

    notifyReportsUpdate(reports: Compliance.FilingBatchReportModel[]): void {
        this._sharedState.reports = reports;
    }

    notifyProcessStatusChange(processStatus: Compliance.FilingBatchProcessStatusEnum): void {
        this._filingBatchProcessStatusSubject.next(processStatus);
    }

    isReturnConsolidated(returnId: number): boolean {
        return this._consolidatedReturns.has(returnId);
    }

    /**
     * Associates new Return Form Revisions to a Return (ReturnFormRevisionId of zero).
     * Disassociated existing Return Form Revisions from a Return (ReturnFormRevisionId greater than zero).
     * @param models The Return Form Revision models.
     */
    async associateOrDisassociateReturnFormRevisions(models: Compliance.ReturnFormRevisionModel[]): Promise<void> {
        await lastValueFrom(this._returnFormRevisionRepository.update(this.filingBatchId, models));
        await this.loadReturnFormRevisions();
        await lastValueFrom(this._returnRepository.updateWorkingSet(this._filingBatch.filingBatchId, []));
    }

    async startLocking(lockRequestModel: Compliance.ReturnLockRequestModel): Promise<void> {
        this._filingBatch.longRunningProcessId = await lastValueFrom(this._returnRepository.lockReturn(lockRequestModel));
        this._longRunningProcessIdSubject.next(this.longRunningProcessId);
    }

    async startUnlocking(unlockRequestModel: Compliance.ReturnUnlockRequestModel): Promise<void> {
        this._filingBatch.longRunningProcessId = await lastValueFrom(this._returnRepository.unlockReturn(unlockRequestModel));
    }

    async startSigning(signRequestModel: Compliance.ReturnSignRequestModel): Promise<void> {
        this._filingBatch.longRunningProcessId = await lastValueFrom(this._returnRepository.signReturn(signRequestModel));
    }

    async startUnsigning(unsignRequestModel: Compliance.ReturnUnsignRequestModel): Promise<void> {
        this._filingBatch.longRunningProcessId = await lastValueFrom(this._returnRepository.unsignReturn(unsignRequestModel));
    }

    async startFinalizing(finalizeRequestModel: Compliance.ReturnFinalizeRequestModel): Promise<void> {
        this._filingBatch.longRunningProcessId = await lastValueFrom(this._returnRepository.finalizeReturn(finalizeRequestModel));
    }

    async viewTasks(): Promise<void> {
        await this._filingBatchService.viewTasks(this._filingBatch);
    }

    async completeTask(taskUpdateModel: Compliance.ReturnTaskUpdateModel): Promise<void> {
        await lastValueFrom(this._returnRepository.completeTask(this.filingBatchId, taskUpdateModel));
        await this._loadTasks();
        this._filingBatch = await lastValueFrom(this._filingBatchRepository.get(this.filingBatchId));
    }

    async rescindToTask(taskUpdateModel: Compliance.ReturnTaskUpdateModel): Promise<void> {
        await lastValueFrom(this._returnRepository.rescindToTask(this.filingBatchId, taskUpdateModel));
        await this._loadTasks();
        this._filingBatch = await lastValueFrom(this._filingBatchRepository.get(this.filingBatchId));
    }

    async skipTask(taskUpdateModel: Compliance.ReturnTaskUpdateModel): Promise<void> {
        await lastValueFrom(this._returnRepository.skipTask(this.filingBatchId, taskUpdateModel));
        await this._loadTasks();
        this._filingBatch = await lastValueFrom(this._filingBatchRepository.get(this.filingBatchId));
    }

    async reassignTask(taskUpdateModel: Compliance.ReturnTaskUpdateModel): Promise<void> {
        await lastValueFrom(this._returnRepository.reassignTask(this.filingBatchId, taskUpdateModel));
        await this._loadTasks();
        this._filingBatch = await lastValueFrom(this._filingBatchRepository.get(this.filingBatchId));
    }

    async resetForms(filingBatchId: number): Promise<void> {
        const startResult = await lastValueFrom(this._returnRepository.resetForms(filingBatchId));
        await this._start(startResult);
    }

    async validateForms(filingBatchId: number): Promise<void> {
        const compareResult = await lastValueFrom(this._returnRepository.compareForms(filingBatchId));
        this._setCompareFormsResult(compareResult);
    }

    async nextYearInfo(filingBatchId: number): Promise<Compliance.FilingBatchCreateModel> {
        return await lastValueFrom(this._filingBatchRepository.getNextYearInfo(filingBatchId));
    }

    async getFinalizeSyncOptions(filingBatchId: number): Promise<Compliance.ReturnFinalizeSyncValueOptionEnum[]> {
        return await lastValueFrom(this._returnRepository.getFinalizeSyncOptions(filingBatchId));
    }

    getFilenameFromResponseContentDisposition(response: HttpResponse<any>): string {
        const contentDisposition = response.headers.get('content-disposition') || '';
        const matches = /filename="?([^;"]+)"?/ig.exec(contentDisposition);
        const filename = (matches[1] || 'untitled').trim();
        return filename;
    }

    saveFileAsPdf(data: Blob, filename: string): void {
        const blob = new Blob([data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    }

    getReturnFormRevisionDisplayName(returnFormRevision: Compliance.ReturnFormRevisionModel): string {
        return `${returnFormRevision.formRevisionName}${returnFormRevision.isInDevelopment ? ' (DEV)' : ''}`;
    }

    getProgressStatusDisplay(): string {
        return this._filingBatchService.getProgressStatusDisplay(this._filingBatch && this._filingBatch.processStatus);
    }

    getRepresentationType(representationType: Compliance.RepresentationTypeEnum): string {
        switch (representationType) {
            case Compliance.RepresentationTypeEnum.Agent: return 'Agent';
            case Compliance.RepresentationTypeEnum.Appraiser: return 'Appraiser';
            case Compliance.RepresentationTypeEnum.Attorney: return 'Attorney';
            case Compliance.RepresentationTypeEnum.Broker: return 'Broker';
            case Compliance.RepresentationTypeEnum.CPA: return 'CPA';
            case Compliance.RepresentationTypeEnum.Employee: return 'Employee';
            case Compliance.RepresentationTypeEnum.Fiduciary: return 'Fiduciary';
            case Compliance.RepresentationTypeEnum.Other: return 'Other';
            case Compliance.RepresentationTypeEnum.Owner: return 'Owner';
            case Compliance.RepresentationTypeEnum.SecuredParty: return 'Secured Party';
            default: return '';
        }
    }

    getReportType(filingBatchReport: Compliance.FilingBatchReportModel): string {
        if (!filingBatchReport) {
            return '';
        }

        return filingBatchReport.isSystem ? 'System' : 'Custom';
    }

    getReportName(filingBatchReport: Compliance.FilingBatchReportModel): string {
        if (!filingBatchReport) {
            return '';
        }

        return `${filingBatchReport.name}${(filingBatchReport.variantName ? ` - ${filingBatchReport.variantName}` : '')}`;
    }

    getSavedSearchReportType(savedSearch: Core.SavedSearchModel): string {
        if (!savedSearch) {
            return '';
        }

        return savedSearch.isSystemSearch ? 'System ' : 'Custom';
    }

    getSavedSearchReportName(savedSearch: Core.SavedSearchModel): string {
        if (!savedSearch) {
            return '';
        }

        return `${savedSearch.searchName}${(savedSearch.variantName ? ` - ${savedSearch.variantName}` : '')}`;
    }

    getReturnReportName(returnFormRevisionReport: Compliance.ReturnFormRevisionReportModel): string {
        if (!returnFormRevisionReport) {
            return '';
        }

        return `${returnFormRevisionReport.name}${(returnFormRevisionReport.variantName ? ` - ${returnFormRevisionReport.variantName}` : '')}`;
    }

    applyRemoteReturnBatchFilter(returnIds: number[]): void {
        this._returnBatchFilterSubject.next(returnIds);
    }

    exportReturnFilingControlSummary(searchParams: Core.SearchModel<string>, columnsToReturn: Compliance.NameValuePair<string>[]): Observable<number> {
        const exportModel: Compliance.ReturnFilingControlSearchModel = {
            searchModel: searchParams,
            columnsToReturn: columnsToReturn,
            formRevisionId: this.sharedState.formRevisionId,
            parcelIds: this.sharedState.returns.map(x => x.parcelId)
        };

        return this._returnRepository.exportReturnFilingControlSummary(this.filingBatchId, exportModel);
    }

    getExportedReturnFilingControlSummary(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        return this._returnRepository.getExportedReturnFilingControlSummary(this.filingBatchId, longRunningProcessId);
    }

    getFilingBatchGeneralReturnSettings(): Observable<Compliance.FilingBatchGeneralReturnSettings> {
        return this._returnRepository.getFilingBatchGeneralReturnSettings(this.filingBatchId);
    }

    updateFilingBatchGeneralReturnSettings(updateModel: Compliance.FilingBatchGeneralReturnSettings): Observable<Compliance.FilingBatchGeneralReturnSettings> {
        return this._returnRepository.updateFilingBatchGeneralReturnSettings(this.filingBatchId, updateModel);
    }

    getCompanyAssetDescriptorMappingsAsColDefs(mappings: Compliance.CompanyAssetDescriptorMappingModel[]): ColDef[] {
        return mappings.map(mapping => {
            let typeSpecificAttributes;
            let cellStyle = '';
            switch (mapping.descriptor.fieldType) {
                case Core.DescriptorFieldTypes.Currency:
                    typeSpecificAttributes = {
                        type: 'numericColumn',
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: x => {
                            if (!x.value) {
                                return null;
                            }

                            return this._decimalPipe.transform(x.value, '1.2-2')
                        }
                    };
                    cellStyle = 'ag-numeric-cell';
                    break;
                case Core.DescriptorFieldTypes.Date:
                    typeSpecificAttributes = {
                        width: AgGridColumns.dateColumnWidth,
                        filter: 'agDateColumnFilter',
                        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                        valueFormatter: x => {
                            if (!x.value) {
                                return null;
                            }

                            const d = new Date(x.value);
                            return this._datePipe.transform(d, true);
                        }
                    };
                    break;
                case Core.DescriptorFieldTypes.YesNo:
                    typeSpecificAttributes = {
                        width: AgGridColumns.textColumnMedWidth,
                        mindWidth: AgGridColumns.checkboxColumnMinWidth,
                        cellRendererFramework: AgGridCheckboxCellRendererComponent,
                        cellRendererParams: {
                            isVisible: (params: ICellRendererParamsForAgGridCheckbox) => params.value !== null,
                            canEdit: (params: ICellRendererParamsForAgGridCheckbox) => false,
                            canEnterEditMode: () => false,
                            onValueChanged: null,
                        } as ICellRendererParamsForAgGridCheckbox,
                        filter: false,
                    };
                    break;
                case Core.DescriptorFieldTypes.Number:
                    typeSpecificAttributes = {
                        width: AgGridColumns.numericColumnWidth,
                        filter: 'agNumberColumnFilter',
                        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                        valueFormatter: x => (x && x.value) ? `${this._decimalPipe.transform(x.value, '1.2-5')}` : ''
                    };
                    break;
                case Core.DescriptorFieldTypes.Text:
                    typeSpecificAttributes = {
                        width: AgGridColumns.textColumnWidth,
                        filter: 'agTextColumnFilter',
                        filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    };
                    break;
            }

            return {
                headerName: mapping.descriptor.name,
                field: _.camelCase(mapping.columnName),
                toolPanelClass: 'Asset',
                ...typeSpecificAttributes,
                cellClass: x => {
                    const rowData = x.data as Compliance.ReturnAssetModel;
                    if (mapping.descriptor.fieldType === Core.DescriptorFieldTypes.Date) {
                        return (rowData
                            && rowData[_.camelCase(mapping.columnName)] != null
                            && rowData[_.camelCase('source' + mapping.columnName)] != null
                            && rowData[_.camelCase(mapping.columnName)].getTime() === rowData[_.camelCase('source' + mapping.columnName)].getTime()) ? cellStyle : cellStyle + ' overridden';
                    } else {
                        return (rowData && rowData[_.camelCase(mapping.columnName)] === rowData[_.camelCase('source' + mapping.columnName)]) ? cellStyle : cellStyle + ' overridden';
                    }
                },
                hide: mapping.descriptor.assetUsage !== Core.DescriptorUsageEnum.Frequent
            }
        })
    }

    notifyParcelsChanged(): void {
        this._parcelsChangedSubject.next(new Date());
    }

    get cutOffDate(): Date {
        return this._cutOffDate;
    }

    get changeDetection(): string {
        return this._changeDetection;
    }

    get ages(): string[] {
        return this._ages;
    }

    async loadFilingBatchInfo(filingBatchId: number): Promise<void> {
        this._filingBatch = await this._filingBatchService.getInfo(filingBatchId);
    }

    private _getLastSelectedFormRevisionId(filingBatchId: number): number {
        const lastSelectedFormRevisionId = this._lastSelectedFormRevisionIds.filingBatch[filingBatchId];

        const lastSelectedFormRevision = this._associatedReturnFormRevisions.find(x => x.formRevisionId === lastSelectedFormRevisionId);

        // if we have the last used form revision set it to that one
        // if last used form revision, set it to the first available one from the list
        // if cannot set the form revision, set it to NULL
        return lastSelectedFormRevision && lastSelectedFormRevision.formRevisionId;
    }

    /**
     * Sets the form revision ID without notifying via the Observable.
     * @param value The form revision ID.
     */
    private _setFormRevisionId(value: number): void {
        this._sharedState.formRevisionId = value;
        // store the selected form revision ID as the last selected one for that filing (or filing batch)
        this._lastSelectedFormRevisionIds.filingBatch[this._filingBatch.filingBatchId] = this._sharedState.formRevisionId;
    }

    private async _loadTasks(): Promise<void> {
        const tasksByEntity = await lastValueFrom(this._filingBatchRepository.getTasks(this.filingBatch.filingBatchId));

        this._setTasks(tasksByEntity.tasks);
    }

    private _setCompareFormsResult(formResult: Compliance.ReturnCompareFormsResultModel): void {
        this._compareFormsResult = formResult;
        this._compareFormsSubject.next(formResult);
    }

    private _setTasks(tasks: Core.TaskModel[]): void {
        this._tasks = _.sortBy(tasks, x => x.sequence);

        // set the current task
        this._currentTask = _.find(this.tasks, x => x.isReady);

        // set the prior completed task
        this._priorCompletedTask = _.find(_.orderBy(this._tasks, x => x.sequence, 'desc'), x => x.completedDateTime && this._currentTask && x.sequence < this._currentTask.sequence);

        // set the next task
        this._nextTask = _.find(this.tasks, x => this._currentTask && x.sequence > this._currentTask.sequence);

        this._tasksSubject.next();
    }

    /**
     * Updates the the internal copy of return form revision that are associated or can be associated with a Return.
     */
    async loadReturnFormRevisions(): Promise<void> {
        const returnIds = this._sharedState.returns.reduce((acc, x) => acc.includes(x.returnId) ? acc : [...acc, x.returnId], []);

        const response = await Promise.all([
            lastValueFrom(this._returnFormRevisionRepository.getByAssociatedWithReturns(this.filingBatchId, returnIds)),
            lastValueFrom(this._returnFormRevisionRepository.getByNotAssociatedWithReturns(this.filingBatchId, returnIds))
        ]);

        this._associatedReturnFormRevisions = response[0];
        this._notAssociatedReturnFormRevisions = response[1];

        this._sharedState.returnFormRevisionsUpdatedTimestamp = Date.now();
    }

    private _isReturnInReadOnlyMode(): boolean {
        return this._filingBatchService.isReadOnly(this._filingBatch && this._filingBatch.processStatus);
    }

    /**
     * Handles WebSocket message related to filing batch status changes.
     * Notifies with the updated status if the filing batch that was changed matches that of the return.
     * @param filingBatchId The ID of the filing batch that was changed.
     */
    private async _wsHandleFilingBatchStatusChange(statusChange: Compliance.LongRunningProcessStatusChangeModel): Promise<void> {
        if (!(this._filingBatch && this._filingBatch.longRunningProcessId === statusChange.longRunningProcessId && statusChange.isCompleted)) {
            return;
        }

        // reload filing batch
        this._filingBatch = await lastValueFrom(this._filingBatchRepository.get(this._filingBatch.filingBatchId));

        if (this._filingBatch.processStatus === Compliance.FilingBatchProcessStatusEnum.Locked) {
            this._validateFormsResult = this._filingBatch.validateFormsResult;
            this._validateFormsSubject.next(this._validateFormsResult);
        }

        await this._loadTasks();

        // determine status
        this._filingBatchProcessStatusSubject.next(this._filingBatch.processStatus);

        this._isReturnInReadOnly = this._isReturnInReadOnlyMode();
        this._isReturnInReadOnlyModeSubject.next(this._isReturnInReadOnly);
    }

    /**
     * Handles WebSocket message related to filing batch status change progress changes.
     * Notifies with the updated progress if the filing batch that was changed matches that of the return.
     * @param data The websocket message payload.
     */
    private async _wsHandleFilingBatchProgressChange(progressChange: Compliance.LongRunningProcessProgressChangeModel): Promise<void> {
        if (!(this._filingBatch && this._filingBatch.longRunningProcessId === progressChange.longRunningProcessId)) {
            return;
        }

        this._filingBatchProgressSubject.next(progressChange);
    }

    private _start(startResult: Compliance.ReturnPrepareDataResultModel): void {
        this._sharedState.returns = [];
        this._sharedState.reports = [];
        this._filingBatch = startResult.filingBatch;
        this._lienDate = startResult.lienDate;
        this._company = startResult.company;
        this._canEditCompany = startResult.canEditCompany;
        this._companyAssetDescriptorMappings = startResult.companyAssetDescriptors;
        this._associatedReturnFormRevisions = startResult.formsAssociatedWithReturns;
        this._notAssociatedReturnFormRevisions = startResult.formsNotAssociatedWithReturns;
        this._returnGroupDetails = startResult.returnTotalsDetails;
        this._sharedState.returnFormRevisionsUpdatedTimestamp = Date.now();
        this._validateFormsResult = startResult.validateFormsResult;
        this._validateFormsSubject.next(this._validateFormsResult);
        this._setCompareFormsResult(startResult.compareFormsResult);
        this._setTasks(startResult.tasks);
        this._isReturnInReadOnly = this._isReturnInReadOnlyMode();
        this._isReturnInReadOnlyModeSubject.next(this.isReturnInReadOnly);
        this._filingBatchStatusChangeSub && this._filingBatchStatusChangeSub.unsubscribe();
        this._filingBatchProgressChangeSub && this._filingBatchProgressChangeSub.unsubscribe();
        this._filingBatchStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(x => this._wsHandleFilingBatchStatusChange(x));
        this._filingBatchProgressChangeSub = this._websocketListenerService.longRunningProcessProgressChange$.subscribe(x => this._wsHandleFilingBatchProgressChange(x));
        this._startSubject.next();
    }

    private _setData(result: Compliance.ReturnPrepareDataResultModel, onlyReloadFilingBatchDetails: boolean) {
        this._filingBatch = result.filingBatch;
        this._lienDate = result.lienDate;
        this._company = result.company;
        this._canEditCompany = result.canEditCompany;
        this._canViewRyanPrivateItems = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        this._companyAssetDescriptorMappings = result.companyAssetDescriptors;
        this._validateFormsResult = result.validateFormsResult;
        this._validateFormsSubject.next(this._validateFormsResult);
        this._associatedReturnFormRevisions = result.formsAssociatedWithReturns;
        this._notAssociatedReturnFormRevisions = result.formsNotAssociatedWithReturns;
        this._sharedState.returnFormRevisionsUpdatedTimestamp = Date.now();
        this._returnGroupDetails = result.returnTotalsDetails;
        this._returnSummary = result.returnSummary;
        this._cutOffDate = result.cutOffDate;
        this._changeDetection = result.changeDetection;
        this._ages = result.ages;

        if (!onlyReloadFilingBatchDetails) {
            this._setCompareFormsResult(result.compareFormsResult);
            this._setTasks(result.tasks);

            this._isReturnInReadOnly = this._isReturnInReadOnlyMode();
            this._isReturnInReadOnlyModeSubject.next(this.isReturnInReadOnly);

            this._filingBatchStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(x => this._wsHandleFilingBatchStatusChange(x));
            this._filingBatchProgressChangeSub = this._websocketListenerService.longRunningProcessProgressChange$.subscribe(x => this._wsHandleFilingBatchProgressChange(x));

            this.setFirstFormRevisionId();
            this._startSubject.next();
        }
    }
}
