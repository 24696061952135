import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ResponsibleEntityListComponent } from './Responsible-Entity-List/responsibleEntityList.component';
import { ResponsibleEntityRepository } from './responsbileEntity.repository';
import {
    ResponsibleEntityListGridActionCellRendererComponent
} from './Responsible-Entity-List/agGridActionCellRenderer.component';
import { ResponsibleEntityDetailsComponent } from './Responsible-Entity-Details/responsibleEntityDetails.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [
        ResponsibleEntityListComponent,
        ResponsibleEntityListGridActionCellRendererComponent,
        ResponsibleEntityDetailsComponent
    ],
    providers: [
        ResponsibleEntityRepository
    ],
    exports: [
        ResponsibleEntityListComponent
    ]
})
export class ResponsibleEntityModule{

}
