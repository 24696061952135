export class Filing {
    filingTypeId: number;
    filingId: number;
    annualYearID: number;
    description: string;
    dueDate: Date;
    value: number;
    filingType: FilingType;
    filingDeadline: Date;
    rowVersion: any;
    filingBatchId?: number;
    reportedCost: number;
    isFromConsolidatedParcel: boolean;
    filingBatch?: Compliance.FilingBatchModel;
    allocatedReportedValue?: number;
    allocatedReportedCost?: number;
    allocation: Compliance.AllocationModel;
    assessorName: string;
}

export class FilingType {
    filingTypeAppId: number;
    filingTypeId: number;
    filingTypeName: string;
    name: string;
    sequence: number;
}

export class FilingTypeSummary {
    allTasksCompleted: boolean;
    dueDate: string;
    filingHasTaskSeries: boolean;
    filingTypeId: number;
    isFromConsolidatedParcel: boolean;
    isMissing: boolean;
    moreThanOneFilingOfThisType: boolean;
    value: number;
    filingTypeName: string;
    filingTypeSequence: number;
}
