import { Component, Input } from '@angular/core';
import { IAddress } from '../../Models/common.model';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
    selector: 'address-view-edit',
    templateUrl: './address.view.edit.component.html'
})
export class AddressViewEditComponent  {
    @Input() address: IAddress;
    @Input() isEditMode: boolean;
    @Input() shouldIncludeDetails: boolean;
    @Input() shouldRestrictExtended: boolean;
    @Input() isSmall: boolean;
    @Input() readOnlyState: boolean;
    @Input() removeBottomMargin: boolean;
}
