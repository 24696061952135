import { Injectable, NgZone } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { SignalRMessageBrokerClientService } from '../Common/SignalR/signal-r-message-broker-client.service';
import { ToastrService } from 'ngx-toastr';
import { AppStates, AppStateService } from '../Layout/appStateService';
import { TimerService } from '../UI-Lib/Utilities';
import { WeissmanEnvironmentConfig } from '../weissmanEnvironmentConfig';

@Injectable({ providedIn: 'root' })
export class WebsocketListenerService {
    constructor(
        private readonly _signalRMessageBrokerClient: SignalRMessageBrokerClientService,
        private readonly _appStateService: AppStateService,
        private readonly _toastr: ToastrService,
        private readonly _ngZone: NgZone,
        private readonly _timer: TimerService
    ) {
        this._launchClient();
    }

    private _signalRDisconnected = true;

    private _longRunningProcessQuiescedStatusChangeSubject = new Subject<Compliance.LongRunningProcessQuiescedStatusModel>();
    private _longRunningProcessStatusChangeSubject = new Subject<Compliance.LongRunningProcessStatusChangeModel>();
    private _longRunningProcessProgressChangeSubject = new Subject<Compliance.LongRunningProcessProgressChangeModel>();
    private _longRunningProcessAcknowledgedSubject = new Subject<{ longRunningProcessId: number, userId: string }>();
    private _longRunningProcessDisconnectedSubject = new Subject<void>();
    private _longRunningProcessReconnectedSubject = new Subject<void>();

    get signalRDisconnected(): boolean {
        return this._signalRDisconnected;
    }
    get longRunningProcessStatusChange$(): Observable<Compliance.LongRunningProcessStatusChangeModel> { return this._longRunningProcessStatusChangeSubject.asObservable(); }
    get longRunningProcessProgressChange$(): Observable<Compliance.LongRunningProcessProgressChangeModel> { return this._longRunningProcessProgressChangeSubject.asObservable(); }
    get longRunningProcessQuiescedStatusChange$(): Observable<Compliance.LongRunningProcessQuiescedStatusModel> { return this._longRunningProcessQuiescedStatusChangeSubject.asObservable(); }
    get longRunningProcessAcknowledged$(): Observable<{ longRunningProcessId: number, userId: string }> { return this._longRunningProcessAcknowledgedSubject.asObservable(); }
    get longRunningProcessDisconnected$(): Observable<void> { return this._longRunningProcessDisconnectedSubject.asObservable(); }
    get longRunningProcessReconnected$(): Observable<void> { return this._longRunningProcessReconnectedSubject.asObservable(); }

    updateLRPManually(lrp: Compliance.LongRunningProcessModel): void {
        this._longRunningProcessStatusChangeSubject.next(lrp as any as Compliance.LongRunningProcessStatusChangeModel);
    }

    private _launchClient = () => {
        this._appStateService.appState$.subscribe(s => {
            if(s === AppStates.LoggedIn) {
                this._setupSignalRClient();
            }
        })
    }

    private _setupSignalRClient = () => {
        const environmentConfig = <WeissmanEnvironmentConfig>window['weissmanEnvironmentConfig'];

        const signalRUrl = environmentConfig.weissman2Url ?
            `${environmentConfig.weissman2Url}/messages` :
            environmentConfig.signalRMessageBrokerUrl;

        if (!signalRUrl) {
            this._toastr.error('The real-time update service does not appear to be available for this environment. Functionality may be degraded.');
            return;
        } else {
            this._signalRDisconnected = false;
        }

        this._ngZone.runOutsideAngular(() => {
            this._signalRMessageBrokerClient
                .setup(signalRUrl, 'myUserId')
                .setRetryInterval(window['weissmanEnvironmentConfig'].signalRMessageBrokerRetryInterval || 5000);

            const groups = ['Weissman.FilingBatch', 'Weissman.LongRunningProcess'];
            this._timer.setTimeout(() => {
                groups.forEach(g => this._signalRMessageBrokerClient.joinGroup(g));
            }, 0);

            // Stop listening for SignalR messages on logout
            this._appStateService.appState$.subscribe(s => {
                if (s === AppStates.LoggedOut) {
                    groups.forEach(g => this._signalRMessageBrokerClient.leaveGroup(g));
                }
            });

            this._signalRMessageBrokerClient.notifyWhen('ProcessStatusChange', this, data => {
                this._ngZone.run(() => this._longRunningProcessStatusChangeSubject.next(data));
            });
            this._signalRMessageBrokerClient.notifyWhen('ProgressChange', this, data => {
                this._ngZone.run(() => this._longRunningProcessProgressChangeSubject.next(data));
            });
            this._signalRMessageBrokerClient.notifyWhen('QuiescedStatusChange', this, data => {
                this._ngZone.run(() => this._longRunningProcessQuiescedStatusChangeSubject.next(data));
            });
            this._signalRMessageBrokerClient.notifyWhen('Acknowledged', this,  data => {
                this._ngZone.run(() => this._longRunningProcessAcknowledgedSubject.next(data));
            });
            this._signalRMessageBrokerClient.notifyWhen('disconnected', this,  () => {
                this._ngZone.run(() => {
                    this._signalRDisconnected = true;
                    this._longRunningProcessDisconnectedSubject.next();
                });
            });
            this._signalRMessageBrokerClient.notifyWhen('reconnected', this,  () => {
                this._signalRDisconnected = false;
                this._ngZone.run(() => this._longRunningProcessReconnectedSubject.next());
            });
        });
    }
}
