<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Move Filings"
                  headerHelpContentId="filing-batch-move-modal.header"
                  (save)="submit()"
                  (cancel)="cancel()"
                  [disableSave]="newGroup?.invalid">
    <div class="d-flex flex-column w-100 h-100 move-filings-modal">
        <form>
            <div class="d-flex flex-column">
                <label [title]="((filingBatches && !filingBatches.length) ? 'There are no available filing batches' : 'Move to')">
                    <input type="radio" id="move" [value]="RadioOptions.Move" [formControl]="radioOption"> Existing Filing Batch
                </label>
                <div class="d-flex flex-column form-fields-container mb-3">
                    <ws-select labelProperty="description"
                               [returnEntireOption]="true"
                               [options]="filingBatches"
                               [formControl]="moveControl"></ws-select>
                </div>
            </div>
            <div class="d-flex flex-column" [formGroup]="newGroup">
                <label>
                    <input type="radio" id="createNew" [value]="RadioOptions.Create" [formControl]="radioOption"> New Filing Batch
                </label>
                <div class="d-flex flex-column form-fields-container">
                    <div class="mb-3" [ngClass]="{ 'has-error': newGroup.controls.description.touched && newGroup.controls.description.errors }">
                        <label class="ws-flex-none small ws-font-weight-normal control-label" for="description">Description: </label>
                        <input type="text" class="form-control" id="description" placeholder="Name" formControlName="description">
                        <div *ngIf="newGroup.controls.description.touched && newGroup.controls.description.errors" class="error-feedback">
                            <div *ngIf="newGroup.controls.description.errors?.required" class="text-danger small">Please provide a filing batch description.</div>
                        </div>
                    </div>
                    <div class="mb-3" [ngClass]="{ 'has-error': newGroup.controls.dueDate.errors }">
                        <label for="dueDate" class="ws-flex-none small ws-font-weight-normal  control-label">
                            Due Date: <span class="form-control-static"></span>
                            <weissman-datetime [dateOnly]="true"
                                               [isDisabled]="newGroup.disabled"
                                               [inputDate]="newGroup.controls.dueDate.value"
                                               (change)="onDueDateChanged($event)"
                                               helpTooltip
                                               helpContentId="app.date-picker"
                                               position="bottom">
                            </weissman-datetime>
                        </label>
                        <div *ngIf="newGroup.controls.dueDate.errors" class="error-feedback">
                            <div class="text-danger small">The date must be greater than {{ minDate | weissmanDateFormat: true }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ws-modal-wrapper>
