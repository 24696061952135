import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssessorCollectorService } from '../../assessor.collector.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Collector } from '../../collector.model';

export interface AddCollectorModalParams {
    state: Weissman.Model.Misc.State,
    isCompanyCollector: boolean;
    companyID: number;
    companyName: string;
}

@Component({
    selector: 'add-collector-modal',
    templateUrl: './addCollectorModal.component.html'
})
export class AddCollectorModalComponent implements IWeissmanModalComponent<AddCollectorModalParams, Collector>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _toastr: ToastrService
    ) {}

    params: AddCollectorModalParams;
    result: Collector;

    collectorForm: UntypedFormGroup;
    modalTitle: string;
    isCompanyCollector: boolean;

    get stateId(): UntypedFormControl {
        return this.collectorForm.get('stateID') as UntypedFormControl;
    }

    ngOnInit() {
        this.isCompanyCollector = this.params.isCompanyCollector || false;
        this.modalTitle = `Add ${this.isCompanyCollector ? this.params.companyName : this.params.state.fullName} Collector`;

        this.collectorForm = this._fb.group({
            abbr: ['', [Validators.required]],
            name: ['', [Validators.required]],
            companyID: [(this.isCompanyCollector ? this.params.companyID : null)],
            stateID: [(!this.isCompanyCollector ? this.params.state.stateID : null)],
            enabled: [true],
            isCompanyCollector: this.isCompanyCollector
        });
    }

    async add(): Promise<void> {
        const collector = this.collectorForm.getRawValue();
        try {
            this.result = await this._assessorCollectorService.createCollector(collector);
            this._bsModalRef.hide();
        } catch(err) {
            this._toastr.error('There was an error creating this collector');
        }
    }

    cancel() {
        this._bsModalRef.hide();
    }
}
