import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DashboardDataService } from "../../dashboard.data.service";
import { ReportManagerService } from "../../../Reporting/Manager/report.manager.service";
import { ActionViewService } from "../../../Task/ActionView/action-view-service.upgrade";
import { ShortcutWidgetSettings } from "./shortcut.widget.model";
import { RestrictService, Roles } from "../../../Common/Permissions/restrict.service";
import { SavedSearchCategoryEnum } from '../../../constants.new';
import { SmartSearchService } from "../../../Search/Advanced/smartSearch.service";

import * as _ from "lodash";

@Component({
    selector: 'shortcut-widget-settings',
    templateUrl: './shortcut.widget.settings.component.html'
})
export class ShortcutWidgetSettingsComponent implements OnInit {
    userWidgetId: number;
    loading: boolean;
    settings: ShortcutWidgetSettings;
    actionViews: any[] = [];
    smarts: any[] = [];
    reports: any[] = [];
    selectedActionViews: any[] =[];
    selectedSmarts: any[] =[];
    selectedReports: any[] =[];
    addedShortcuts: any[] = [];
    onClose: any;
    canViewReports: boolean;

    constructor(public bsModalRef: BsModalRef,
        private actionViewService: ActionViewService,
        private advancedSearchService: SmartSearchService,
        private reportManagerService: ReportManagerService,
        private dashboardDataService: DashboardDataService,
        private restrictService: RestrictService) { }

    async ngOnInit() {
        this.canViewReports = this.restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW) || this.restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
        this.loading = true;

        try {
            const result = await Promise.all([
                 this.actionViewService.getCategories(),
                 this.advancedSearchService.getSearchCategories(),
                 this.reportManagerService.getReportCategories()
             ]);

             this._compileShortcutLists(result);

             this.settings = await this.dashboardDataService.getWidgetSettings(this.userWidgetId) as ShortcutWidgetSettings;

             this.addedShortcuts = _.chain(this.settings.savedSearchIds)
                 .map((id: number)=> {
                     let av = _.find(this.actionViews, {actionViewID: id});
                     let smart = _.find(this.smarts, {searchId: id});
                     let report = _.find(this.reports, {reportID: id});

                     return av || smart || report;
                 })
                 .filter(item => item)
                 .sortBy([(item) => item.name.toUpperCase()])
                 .value()

             this._removeAddedShortcutsFromLists();
        } finally {
            this.loading = false;
        }
    }

    private _compileShortcutLists(result): void {
        this.actionViews = _.chain(result[0])
            .map('actionViewList')
            .flatten()
            .compact()
            .map((item: any) => {
                item.name = item.actionViewName;
                return item;
            })
            .sortBy(['isSystemSearch', (item) => item.name.toUpperCase()])
            .reverse()
            .value()

        this.smarts = _.chain(result[1])
            .map('searchList')
            .flatten()
            .compact()
            .map((item: any) => {
                item.name = item.searchName;
                return item;
            })
            .sortBy(['isSystemSearch', (item) => item.name.toUpperCase()])
            .reverse()
            .value();

        this.reports = _.chain(result[2])
            .map('reportList')
            .flatten()
            .compact()
            .filter((item: any) => !([
                SavedSearchCategoryEnum.ComplianceReport,
                SavedSearchCategoryEnum.ComplianceReturnReport,
                SavedSearchCategoryEnum.CompliancePackageReport,
                SavedSearchCategoryEnum.ComplianceCustomReport
            ].indexOf(item.categoryId) >= 0))
            .map((item: any) => {
                item.name = item.reportName;
                item.name+= item.variantName ? ' - ' + item.variantName : '';
                return item;
            })
            .sortBy([(item)=> item.categoryId !== 100, (item) => item.name.toUpperCase()])
            .value();
    }

    addShortcuts(): void {
        this.addedShortcuts = _.chain(this.addedShortcuts)
            .union(this.selectedActionViews)
            .union(this.selectedSmarts)
            .union(this.selectedReports)
            .sortBy([(item) => item.name.toUpperCase()])
            .value();

        this._removeAddedShortcutsFromLists();

        this.selectedActionViews = this.selectedReports = this.selectedSmarts = [];
    }

    private _removeAddedShortcutsFromLists(): void {
        _.forEach(this.addedShortcuts, item => {
            if(item.actionViewID) {
                _.remove(this.actionViews, item);
            }

            if(item.reportID) {
                _.remove(this.reports, item);
            }

            if(item.searchId) {
                _.remove(this.smarts, item);
            }
        });
    }

    removeShortcut(item): void {
        _.remove(this.addedShortcuts, item);

        if(item.searchId) {
            this.smarts.push(item);
            this.smarts = _.chain(this.smarts)
                .sortBy(['isSystemSearch', (item) => item.name.toUpperCase()])
                .reverse()
                .value();
        } else if(item.reportID) {
            this.reports.push(item);
            this.reports = _.sortBy(this.reports, [(item)=> item.categoryId !== 100, (item) => item.name.toUpperCase()])
        } else if(item.actionViewID) {
            this.actionViews.push(item);
            this.actionViews = _.chain(this.actionViews)
                .sortBy(['isSystemSearch', (item) => item.name.toUpperCase()])
                .reverse()
                .value();
        }
    }

    saveAndClose(): void {
        this.settings.savedSearchIds = _.map(this.addedShortcuts, item => {
            return item.searchId || item.reportID || item.actionViewID;
        });

        this.dashboardDataService.saveWidgetSettings(this.userWidgetId, this.settings)
            .then(() => {
                this.onClose(true);
                this.bsModalRef.hide();
            })
    }
}
