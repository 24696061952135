<div style="padding: 1vw; height: 100%;">
	<div *ngIf="currentYear" class="current-year-info" [style.width]="userWidget.widget.widgetId == WidgetIds.PortfolioAnalysis ? '40%' : '100%' ">
		<div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
			<div class="ws-flex-container-horizontal ws-flex-align-items-center tax-year-selection">
				<label class="ws-no-margin" style="margin-right: 15px;">Tax Year:</label>
				<select class="form-select form-select-sm"
						[(ngModel)]="selectedYear"
						(change)="getDataByYear(selectedYear)"
						style="width:auto;">
					<option *ngFor="let year of availableYears" [ngValue]="year">{{ year }}</option>
				</select>
			</div>
			<div class="summary-detail-panel" style="margin-top: 0; overflow-y: auto;" [class.summary-only]="userWidget.widget.widgetId != WidgetIds.PortfolioAnalysis">
				<div class="summary-detail-table" >
					<div class="summary-main-item" (click)="lineItemClicked('totalFMV')">
						Total FMV:  <span class="data-value">{{ (currentYear.totalFMV || 0) | currency:'USD':'':'1.0-0' }}</span>
					</div>
					<div class="summary-sub-item" *ngIf="currentYear.totalFMVPerSqFt > 0" (click)="lineItemClicked('totalFMVPerSqFt')">
						Total $/SqFt: <span class="data-value">{{ currentYear.totalFMVPerSqFt | currency }}</span>
					</div>
					<div class="summary-sub-item" *ngIf="currentYear.totalFMVPerUnit > 0" (click)="lineItemClicked('totalFMVPerUnit')">
						Total $/Unit:   <span class="data-value">{{ currentYear.totalFMVPerUnit | currency }} </span>
					</div>
					<div class="summary-sub-item land-fmv-per-sqft" *ngIf="currentYear.fmvForLandTypeComponents && currentYear.fmvForLandTypeComponents.landFMVPerSqFt > 0" (click)="lineItemClicked('landFMVPerSqFt')">
						Land $/SqFt:  <span class="data-value">{{ currentYear.fmvForLandTypeComponents.landFMVPerSqFt | currency }}</span>
					</div>
					<div class="summary-sub-item land-fmv-per-acre" *ngIf="currentYear.fmvForLandTypeComponents && currentYear.fmvForLandTypeComponents.landFMVPerAcre > 0" (click)="lineItemClicked('landFMVPerAcre')">
						Land $/Acre:  <span class="data-value">{{ currentYear.fmvForLandTypeComponents.landFMVPerAcre | currency }}</span>
					</div>
				</div>

				<div class="summary-total-tax" style="margin-top: 0;" (click)="lineItemClicked('totalTaxesUngrouped')">
					Total Tax: <span class="data-value">{{ (currentYear.totalTax || 0) | currency }}</span>
				</div>
			</div>
			<div class="estimated-sites">
				<div>Sites Under Appeal: {{currentYear.totalSitesUnderAppeal || 0}}/{{currentYear.totalSitesForYear || 0}}</div>
				<div>Sites with Actual Assessments: {{currentYear.totalSitesActualAssessments || 0}}/{{currentYear.totalSitesForYear || 0}}</div>
				<div>Sites with Actual Taxes: {{currentYear.totalSitesActualTaxes || 0}}/{{currentYear.totalSitesForYear || 0}}</div>
			</div>
		</div>
	</div>
	<div *ngIf="userWidget.widget.widgetId == WidgetIds.PortfolioAnalysis" style="display: inline-block; width: 59%; height: 100%;">
		<portfolio-analysis-chart-table [data]="data" [resize$]="resize$" (showAllYearsClicked)="getDataByYear(0)" (graphSelected)="getDataByYear(selectedYear)"></portfolio-analysis-chart-table>
	</div>
</div>
