import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'allocation-detail-bulk-update-action',
    templateUrl: './allocationDetailBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class AllocationDetailBulkUpdateActionComponent implements OnInit{
    constructor(
    ){
        this.possibleActions = [
            { name: 'No Change', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.ChangeTo },
            { name: 'Set to Blank', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank },
            { name: 'Remove Override', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride },
            { name: 'Copy Return/Reported Value', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.CopyReturnValue },
            { name: 'Distribute Variance', value: Compliance.AllocationDetailBulkUpdateFieldActionEnum.DistributeVariance }
        ];
    }

    @Input()
    set fieldAction(fieldAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum) {
        this.fieldActionChanged(fieldAction);
    };

    @Output() fieldActionChange = new EventEmitter<Compliance.AllocationDetailBulkUpdateFieldActionEnum>();
    @Input() requiredField: boolean = false;
    @Input() fieldHasOverrides: boolean = false;
    @Input() verifyField: boolean = false;
    @Input() disabled: boolean;
    @Input() isInitialAllocationAmount: boolean;
    @Input() isFinalAllocationAmount: boolean;

    action: Compliance.AllocationDetailBulkUpdateFieldActionEnum;
    possibleActions: Compliance.NameValuePair<Compliance.AllocationDetailBulkUpdateFieldActionEnum>[];
    selectedAction: Compliance.AllocationDetailBulkUpdateFieldActionEnum = Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;

    ngOnInit(): void {
        if (this.requiredField){
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.SetToBlank);
        }

        if (!this.fieldHasOverrides){
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.RemoveOverride);
        }

        if (this.isInitialAllocationAmount) {
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.DistributeVariance);
        } else if (!this.isFinalAllocationAmount) {
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.CopyReturnValue &&
                i.value !== Compliance.AllocationDetailBulkUpdateFieldActionEnum.DistributeVariance);
        }
    }

    fieldActionChanged(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum): void {
        if (action || action === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange) {
            this.selectedAction = action;
            this.fieldActionChange.next(action);
        } else {
            this.selectedAction = Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;
        }
    }

    isSelected(action: Compliance.AllocationDetailBulkUpdateFieldActionEnum): boolean{
        return action === Compliance.AllocationDetailBulkUpdateFieldActionEnum.NoChange;
    }
}
