import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class CsrCommandCenterRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private readonly _baseUrl = 'api/clientserviceresponsibility/cc/';

    exportList(exportModel: Core.ClientServiceResponsibilityCommandCenterExportModel): Observable<any> {
        return this.httpPost(`${this._baseUrl}export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`${this._baseUrl}company/${companyId}/export/${longRunningProcessId}`, options);
    }
}
