import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { SmartSearchService } from '../../../../Search/Advanced/smartSearch.service';
import { UserSaveService } from '../../userSave.service';

export interface SearchCategoryModalParams {
    search: Core.AdvancedSearchList;
    searchType: Core.SearchCategoryType;
}

@Component({
    selector: 'search-category',
    templateUrl: './navChangeCategory.component.html'
})
export class SearchCategoryComponent implements IWeissmanModalComponent<SearchCategoryModalParams, void>, OnInit {
    constructor(private readonly _smartSearchService: SmartSearchService,
                private readonly _userSaveService: UserSaveService,
                private readonly _bsModalRef: BsModalRef) {
    }

    params: SearchCategoryModalParams;
    result: void;

    modalTitle: string;
    search: Core.AdvancedSearchList;
    searchType: Core.SearchCategoryType;
    categories: any[] = [];
    selectedCategory: any;
    newCategory: string;
    creatingNewCategory = false;

    async ngOnInit(): Promise<void> {
        this.search = this.params.search;
        this.searchType = this.params.searchType;
        this.modalTitle = `Change "${this.search.searchName}" View's Category`;

        const categories = await this._smartSearchService.getSearchCategories();

        if (categories && categories.length) {
            this.categories = categories.filter(x => x.categoryType === this.searchType);
            this.selectedCategory = this.categories.find(x => x.categoryId === this.search.categoryId) || this.categories[0];
        }
    }

    async save(): Promise<void> {
        //Create new category first, then update view
        if (this.selectedCategory.categoryId === -1) {
            const category = { ...this.selectedCategory };
            delete category.categoryId;

            const result = await this._smartSearchService.createSearchCategory(category);

            this.search.categoryId = result.categoryId;
            this.selectedCategory.categoryId = result.categoryId;
        }

        await this._smartSearchService.changeSearchCategory(this.search, this.selectedCategory.categoryId, this.searchType);
        await this._userSaveService.refreshCurrentList();

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    createNewCategory(): void {
        if (!this.newCategory) {
            this.creatingNewCategory = false;
            return;
        }

        let category;
        if (this.categories.some(x => x.categoryId === -1)) {
            category = this.categories.find(x => x.categoryId === -1);
            category.categoryName = this.newCategory;
            category.categoryType = this.searchType;
        } else {
            category = {
                categoryName: this.newCategory,
                categoryType: this.searchType,
                categoryId: -1
            };
            this.categories.push(category);
        }

        this.selectedCategory = category;
        this.search.categoryId = -1;
        this.creatingNewCategory = false;
        this.newCategory = '';
    }

    cancelCreateCategory(): void {
        this.creatingNewCategory = false;
        this.newCategory = '';
    }
}
