import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeaheadSelectorComponent, HigherOrderFn, Predicate } from '../Selectors/Typeahead-Selector/typeaheadSelector.component';

export interface EntityImportEditorAttachmentTypeSelectorCellEditorParams extends ICellEditorParams {
    getAttachmentTypes(row: Compliance.ImportGridRowGridModel): Compliance.AttachmentTypeViewModel[];
}

@Component({
    selector: 'entity-import-editor-attachment-type-selector',
    template: `
       <typeahead-selector #typeaheadSelector
                           labelProperty="attachmentType"
                           valueProperty="attachmentType"
                           placeholder="Filter Attachment Types..."
                           [formControl]="attachmentType"
                           [canReset]="true"
                           [originalValue]="originalValue"
                           [filterFn]="typeaheadFilter"
                           (changed)="onChanged($event)"
                           [values]="values"></typeahead-selector>`
})
export class EntityImportEditorAttachmentTypeSelectorCellEditorComponent implements ICellEditorAngularComp {
    @ViewChild("typeaheadSelector", { static: true }) typeaheadSelector: TypeaheadSelectorComponent;

    gridRow: Compliance.ImportGridRowGridModel;
    attachmentType: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    values: Compliance.AttachmentTypeViewModel[];
    originalValue: string;
    typeaheadFilter: HigherOrderFn<Predicate> = (filter: string) => (x: Compliance.AttachmentTypeViewModel) =>
        x.attachmentType.toLowerCase().includes(filter.toLowerCase());

    private _params: EntityImportEditorAttachmentTypeSelectorCellEditorParams;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.attachmentType.value;
    }

    isCancelAfterEnd(): boolean {
        if (this.typeaheadSelector.value && !this.typeaheadSelector.filter) {
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    async agInit(params: EntityImportEditorAttachmentTypeSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;

        if (this._params.data && this._params.data.originalValues) {
            this.originalValue = this._params.data.originalValues[this._params.colDef.field];
        }

        this.attachmentType.setValue(params.value);

        this.values = await params.getAttachmentTypes(this.gridRow);

        if (this.values && this.values.length) {
            this.attachmentType.enable();
        }
    }

    onChanged(value?: string) {
        if (this.attachmentType.value !== value) {
            this.attachmentType.setValue(value);
            this._params.node.setDataValue(this._params.column.getColId(), value);
        }
    }
}
