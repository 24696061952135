<loading-spinner [show]="loading" [hasOverlay]="true" [size]="3"></loading-spinner>
<ws-modal-wrapper headerLabel="States"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!hasChanges || loading">
    <div class="ws-flex-container-horizontal ws-flex-align-items-center">
        <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
