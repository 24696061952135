(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('CustomOutputModalController', CustomOutputModalController);

	CustomOutputModalController.$inject = [
        '$uibModalInstance',
        'taskTypeName',
        'systemColumns',
        'userColumns',
        'fromActionView',
		'selectedTaskType'
	];

	function CustomOutputModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit($uibModalInstance, taskTypeName, systemColumns, userColumns, fromActionView, selectedTaskType) {
		var vm = this;

		activate();

		////////////////

		function activate() {
			vm.fromActionView = fromActionView;
			vm.taskTypeName = taskTypeName === 'All' ? 'All Task Types': taskTypeName;
			vm.selectedTaskType = selectedTaskType;

			var filterColumns = userColumns.length ? userColumns : systemColumns;
			vm.category = {
				columns: _.chain(filterColumns)
					.map(function(column) {
						column.isRemovable = true
						return column;
					})
					.sortBy('sequence')
					.value(),
				defaultColumns: systemColumns
			}
		}

		vm.cancel = function () {
			$uibModalInstance.dismiss();
		}

		vm.save = function () {
			if(!vm.category.columns.length) {
				$uibModalInstance.close([]);
				return;
			}

			var idProperty = vm.category.columns[0].columnId ? 'columnId' : 'advancedSearchFieldID'
			var useSystem = _.xorBy(vm.category.columns, vm.category.defaultColumns, idProperty).length == 0;

			vm.category.columns = _.map(vm.category.columns, function(col, i) {
				col.sequence = i;
				return col;
			})

			var columns = useSystem && !vm.fromActionView ? [] : vm.category.columns;
			$uibModalInstance.close(columns)
		}
	}
})();
