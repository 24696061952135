import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const PARCEL_INFO_PANEL_HELP: HelpContent[] = [
    {
        helpContentId: 'parcel-info-panel.attachments',
        tooltipText: 'Attachments'
    },
    {
        helpContentId: 'parcel-info-panel.comments',
        tooltipText: 'Comments'
    },
    {
        helpContentId: 'parcel-info-panel.images',
        tooltipText: 'Images'
    },
    {
        helpContentId: 'parcel-info-panel.optimus',
        tooltipText: 'Research Parcel in Optimus™'
    }
]
