import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { WeissmanModalService } from '../../../../Compliance/WeissmanModalService';
import { LinkedParcelsListParams, LinkedParcelsListComponent } from '../Linked-Parcels-List/linkedParcelsList.component';
import { LinkedParcelRepository } from '../linkedParcel.repository';
import { BusyIndicatorService } from '../../../../Busy-Indicator';

@Component({
    selector: 'linked-parcels-edit',
    templateUrl: './linkedParcelsEdit.component.html'
})
export class LinkedParcelsEditComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _linkedParcelRepository: LinkedParcelRepository,
        private readonly _busyIndicatorService: BusyIndicatorService) {
    }

    private _linkageTypes: Core.LinkageTypeModel[];

    @Input() parcelId: number;
    @Input() parcelAcctNum: string;
    @Input() linkedParcelCount: number;
    @Input() masterParcelId: number;
    @Input() masterParcelAcctNum: string;
    @Input() isDisabled: boolean;

    @Output() linkedParcelsChanged: EventEmitter<Core.LinkedParcelInfoDTO> = new EventEmitter<Core.LinkedParcelInfoDTO>();

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async edit(): Promise<void> {
        if (!this._linkageTypes) {
            const busyRef = this._busyIndicatorService.show({ message: 'Loading Linkages' });

            try {
                this._linkageTypes = await lastValueFrom(this._linkedParcelRepository.getLinkageTypes());
            }
            finally {
                busyRef.hide();
            }
        }

        try {
            const params: LinkedParcelsListParams = {
                parcelId: this.parcelId,
                parcelAcctNum: this.parcelAcctNum,
                masterParcelId: this.masterParcelId,
                masterParcelAcctNum: this.masterParcelAcctNum,
                linkageTypes: this._linkageTypes,
                linkedParcelCount: this.linkedParcelCount,
                editMode: true
            };

            const bsModalParcelsRef = await this._wsModalService.show(() => {
                return this._bsModalService.show(LinkedParcelsListComponent,
                    {
                        class: 'modal-lg',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

            const result = (bsModalParcelsRef.content as LinkedParcelsListComponent).result;

            if (!result) {
                return Promise.resolve();
            }

            this.linkedParcelsChanged.next(result);

        } catch (e) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }
}
