<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Add Assets to Allocation"
                  [suppressKeyboardEvents]="true"
                  [disableSave]="disableSave"
                  (save)="save()"
                  (cancel)="cancel()"
                  headerHelpContentId="retrieve-assets.title">
    <ws-ag-grid-angular
        [gridOptions]="gridOptions"
        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
