import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class AccountRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly BASE_URL: string = '/api/Account';

    updateOnboardingStatus(status: Core.UserOnboardingStatusEnum): Observable<void> {
        const params: Core.UserOnboardingStatusChangeRequest = {
            onboardingStatus: status
        };
        return this.httpPut(`${this.BASE_URL}/UpdateOnboardingStatus`, params);
    }
}
