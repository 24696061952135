import {ICellEditorAngularComp} from "ag-grid-angular";
import {ICellEditorParams} from "ag-grid-community";
import {Component, ViewChild} from "@angular/core";
import {AssetClassSelectorComponent} from "../../../../../Asset/Asset-Class-Selector/assetClassSelector.component";

export interface EntityImportEditorAssetClassSelectorCellEditorParams extends ICellEditorParams {
    getAssetClassifications(): Compliance.AssetClassificationModel[];
}

@Component({
    selector: 'entity-import-editor-asset-class-selector',
    template: `<asset-class-selector #assetClassSelector [isRequiredField]="false"
                                     [assetClassificationName]="assetClassificationName"
                                     (assetClassificationChange)="onAssetClassificationChanged($event)"
                                     [assetClassifications]="assetClassifications"
                                     *ngIf="assetClassifications"></asset-class-selector>`
})
export class EntityImportEditorAssetClassSelectorCellEditorComponent implements ICellEditorAngularComp {
    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.assetClassificationName;
    }

    isCancelAfterEnd(): boolean {
        if (this.assetClassSelector.assetClassificationName && !this.assetClassSelector.assetClassificationFilter){
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    assetClassificationName: string;
    assetClassifications: Compliance.AssetClassificationModel[];

    @ViewChild("assetClassSelector") assetClassSelector: AssetClassSelectorComponent;

    private _params: EntityImportEditorAssetClassSelectorCellEditorParams;

    async agInit(params: EntityImportEditorAssetClassSelectorCellEditorParams): Promise<void> {
        this._params = params;
        this.assetClassificationName = params.value;
        this.assetClassifications = await params.getAssetClassifications();
    }

    onAssetClassificationChanged(assetClass: Compliance.AssetClassificationModel) {
        this.assetClassificationName = assetClass ? assetClass.name : null;
        this._params.node.setDataValue(this._params.column.getColId(), assetClass ? assetClass.name : null);
    }
}
