import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({ providedIn: 'root' })
export class ConsultingEngagementRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/ce/';

    isConsultantUser(companyId: number): Observable<boolean> {
        return this.httpGet(`${this.SERVICE_URL}${companyId}/isConsultantUser`);
    }
}
