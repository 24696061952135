import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const AG_GRID_HELP: HelpContent[] = [
    {
        helpContentId: 'ag-grid.sort',
        tooltipText: 'Sort'
    },
    {
        helpContentId: 'ag-grid.layout-filter-toggle',
        tooltipText: 'Filter and group the column list'
    },
    {
        helpContentId: 'ag-grid.group-by-category',
        tooltipText: 'Display the columns by category'
    },
    {
        helpContentId: 'ag-grid.no-categories',
        tooltipText: 'There are no categories for this table'
    },
    {
        helpContentId: 'ag-grid.show-category-headers',
        tooltipText: 'Show category headers on the table'
    },
    {
        helpContentId: 'ag-grid.filter-applied',
        tooltipText: 'With a filter, sort, or grouping applied, the order of the columns in the list will no longer reflect the order they appear in the table'
    },
    {
        helpContentId: 'ag-grid.applyFilter',
        tooltipText: 'Apply filter'
    },
    {
        helpContentId: 'ag-grid.clearFilterHelpContentId',
        tooltipText: 'Clear filter'
    },
    {
        helpContentId: 'ag-grid.clearField',
        tooltipText: 'Clear the field'
    },
    {
        helpContentId: 'ag-grid.addFilter',
        tooltipText: 'Add another filter value'
    },
    {
        helpContentId: 'ag-grid.removeFilter',
        tooltipText: 'Remove filter value'
    }
];
