<form [formGroup]="parcelForm">
    <ws-modal-wrapper headerLabel="New Parcel"
                      acceptLabel="Add"
                      (save)="addParcel()"
                      (cancel)="cancel()"
                      [disableSave]="parcelForm.invalid"
                      [tabindex]="6">
        <div class="mb-4">
            <label>Parcel Acct Num:</label>
            <input id="new-parcel-acct-num" name="acctNum" type="text" formControlName="acctNum" class="form-control" placeholder="Enter Account Number" maxlength="50" tabindex="1">
            <p id="acctHelp" *ngIf="parcelForm.controls.acctNum.errors && !parcelForm.controls.acctNum.pristine" class="help-block">
                Account number is required
            </p>
        </div>
        <div class="mb-4">
            <label>Property Type:</label>
            <ws-select [options]="propertyTypes"
                       formControlName="propertyTypeID"
                       labelProperty="propTypeName"
                       valueProperty="propertyTypeID"
                       placeholder="Select property type..."
                       [tabindex]="2"
                       style="display: block;">
            </ws-select>
        </div>
        <div class="mb-4">
            <label>State/Province:</label>
            <input type="text" formControlName="state" class="form-control" tabindex="3">
        </div>
        <div class="mb-4 scrollable-typeahead">
            <label>Assessor:</label>
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   placeholder="Search Assessors..."
                   tabindex="4"
                   [formControl]="selectedAssessor"
                   [typeahead]="assessors"
                   typeaheadOptionField="name"
                   [typeaheadOptionsLimit]="100"
                   [typeaheadMinLength]="1"
                   [typeaheadScrollable]="true"
                   (typeaheadOnSelect)="onAssessorSelected($event)"
                   (blur)="onAssessorBlur()" />
        </div>
        <div class="checkbox">
            <label>
                <input type="checkbox" formControlName="useSiteAddress" tabindex="5"> Parcel address same as site
            </label>
        </div>
        <div class="checkbox" *ngIf="masterParcel && canLinkMaster">
            <label>
                <input type="checkbox" formControlName="linkMasterParcel" tabindex="6"> Link parcel to site's master parcel (Linkage: {{masterParcel.linkageTypeName}})
            </label>
        </div>
    </ws-modal-wrapper>
</form>
