<ws-modal-wrapper headerLabel="Lock Returns"
                  (save)="save()"
                  (cancel)="cancel()">
    <h4>Package Organization:</h4>
    <div class="ws-flex-container-vertical lock-option-group-container">
        <div class="ws-flex-auto" *ngFor="let packageStrategy of packageStrategies">
            <input type="radio" name="packageStrategyRadioGroup" [id]="packageStrategy.name" [value]="packageStrategy" [(ngModel)]="selectedPackageStrategy" (change)="packageStrategyChanged()">
            <label [for]="packageStrategy.name">{{packageStrategy.label}}</label>
        </div>
    </div>
    <h4>Additional Options:</h4>
    <div class="ws-flex-container-vertical lock-option-group-container">
        <div class="ws-flex-auto">
            <label for="returnInOwnPackageThresholdSelected">
                <input type="checkbox" name="returnInOwnPackageThresholdSelected" id="returnInOwnPackageThresholdSelected" [(ngModel)]="returnInOwnPackageThresholdSelected" [disabled]="selectedPackageStrategy == packageStrategies[2]"> Separately Package Returns >=
            </label>

            <label for="returnInOwnPackageThreshold">Tax Liability</label>
            <input type="text"
                   id="returnInOwnPackageThreshold"
                   name="returnInOwnPackageThreshold"
                   class=""
                   currencyMask
                   [options]="{ allowNegative: false, precision: 2, prefix: '' }"
                   maxLength="12"
                   [disabled]="!returnInOwnPackageThresholdSelected"
                   [(ngModel)]="returnInOwnPackageThreshold">
        </div>
        <div class="ws-flex-auto">
            <label for="certifiedMailPackageThresholdSelected">
                <input type="checkbox" name="certifiedMailPackageThresholdSelected" id="certifiedMailPackageThresholdSelected" [(ngModel)]="certifiedMailPackageThresholdSelected"> Send Package Certified Mail >=
            </label>

            <label for="certifiedMailPackageThreshold">Tax Liability</label>
            <input type="text"
                   name="certifiedMailPackageThreshold"
                   id="certifiedMailPackageThreshold"
                   class=""
                   currencyMask
                   [options]="{ allowNegative: false, precision: 2, prefix: '' }"
                   maxLength="12"
                   [disabled]="!certifiedMailPackageThresholdSelected"
                   [(ngModel)]="certifiedMailPackageThreshold">
        </div>
    </div>
</ws-modal-wrapper>
