import { NgModule } from '@angular/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { WeissmanSharedModule } from '../../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../../UI-Lib/Pipes/shared-pipes.module';
import { SiteSelectorComponent } from './siteSelector.component';


@NgModule({
    imports: [
        WeissmanSharedModule,
        SharedPipesModule,
        TypeaheadModule
    ],
    declarations: [SiteSelectorComponent],
    exports: [SiteSelectorComponent]
})
export class SiteSelectorModule {
}
