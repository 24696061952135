import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReturnFilingControlRow } from '../../../../Models/filingControlReconciliationReport';

export interface ICellRendererParamsForFilingControlReconciliationReportAssessorRenderer extends ICellRendererParams {
}

@Component({
    selector: 'filing-control-reconciliation-report-assessor',
    templateUrl: './agGridAssessorCellRenderer.component.html'

})
export class FilingControlReconciliationReportAssessorRenderer implements ICellRendererAngularComp {
    constructor(
    ) { }

    filingControl: ReturnFilingControlRow;

    private _params: ICellRendererParamsForFilingControlReconciliationReportAssessorRenderer;
    
    agInit(params: ICellRendererParamsForFilingControlReconciliationReportAssessorRenderer): void {
        this._params = params;
        this.filingControl = params.data as ReturnFilingControlRow;
    }
    
    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    toggleExpanded(): void {
        this.filingControl.isExpanded = !this.filingControl.isExpanded;
        this._params.api.resetRowHeights();
    }
}