import {tap} from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { TimerService } from '../../../UI-Lib/Utilities/timer.service';

// Trust me, angular exists on window (this lets us hook into AngularJS)
declare const angular: any;

@Injectable()
export class Angular1CallbackInterceptor implements HttpInterceptor {
    constructor(private readonly _timer: TimerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(() => {
            /* Sometimes the new Angular http client appears to not hook AngularJS and kick off a digest cycle;
             * one such example is WK-6566, where the client services panel will load but the loading spinner doesn't
             * immediately disappear. If the user takes any action that AngularJS can tell might have altered the state,
             * everything will work again, but most of the time when the user is looking at a loading spinner, they're not
             * doing anything except waiting. Zone.js should be taking care of this so that any asynchronous event gets
             * hooked and kicks off a digest, but for whatever reason under some circumstances it fails. It also appears to
             * be a timing bug of some sort, as there is no series of steps we've ever found that reproduces the bug
             * reliably. For now, any time we make an HTTP call, go reach into Angular and tell it a change might have
             * happened. */
            this._timer.setTimeout(() => angular.element(document.body).injector().get('$rootScope').$apply(), 1);
        }));
    }
}
