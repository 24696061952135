import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AllocationRepository } from '../Repositories';

@Injectable()
export class AllocationService {
    constructor(private _allocationRepository: AllocationRepository) { }

    private _parcelId: number;

    set navigationParcelId(parcelId: number) {
        this._parcelId = parcelId;
    }

    get navigationParcelId(): number {
        return this._parcelId;
    }

    createAllocation(companyId: number, createModel: Compliance.AllocationCreateModel): Promise<Compliance.AllocationModel> {
        return lastValueFrom(this._allocationRepository.create(companyId, createModel));
    }
}
