import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LONG_RUNNING_PROCESS_SYNTHETIC_MODAL_HELP } from './longRunningProcessSyntheticModal.component.help';
import { LongRunningProcessService } from '../longRunningProcess.service';

@Component({
    templateUrl: './longRunningProcessSyntheticModal.component.html',
    selector: 'long-running-process-synthetic-modal'
})
export class LongRunningProcessSyntheticModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<void, Compliance.SyntheticLongRunningProcessParameters> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _helpService: HelpService,
        private readonly _longRunningProcessService: LongRunningProcessService) {
    }

    params: void;
    result: Compliance.SyntheticLongRunningProcessParameters;
    form: UntypedFormGroup;

    get queueTypes(): Compliance.LongRunningProcessQueueTypeModel[] {
        return this._longRunningProcessService.queueTypes
            .filter(t => t.longRunningProcessQueueType !== Compliance.LongRunningProcessQueueTypeEnum.Control);
    }

    ngOnInit(): void {
        this._helpService.setContent(LONG_RUNNING_PROCESS_SYNTHETIC_MODAL_HELP);

        this.form = this._formBuilder.group({
            queueType: [null, [Validators.required,]],
            processes: [null, [Validators.required, Validators.min(1)]],
            duration: [null, [Validators.required, Validators.min(0)]],
            wait: [null, [Validators.required, Validators.min(0)]],
            throwException: [null, []],
            publishProgressChangeMessage: [null, []]
        });

        this.form.setValue({
            queueType: Compliance.LongRunningProcessQueueTypeEnum.Normal,
            processes: 1,
            duration: 0,
            wait: 0,
            throwException: false,
            publishProgressChangeMessage: false
        });
    }

    setInstantsDefault(): void {
        this.form.setValue({
            queueType: Compliance.LongRunningProcessQueueTypeEnum.Normal,
            processes: 1,
            duration: 0,
            wait: 0,
            throwException: false,
            publishProgressChangeMessage: false
        });
    }

    setWithDurationDefault(): void {
        this.form.setValue({
            queueType: Compliance.LongRunningProcessQueueTypeEnum.Normal,
            processes: 1,
            duration: 3000,
            wait: 0,
            throwException: false,
            publishProgressChangeMessage: true
        });
    }

    setMultipleDefault(): void {
        this.form.setValue({
            queueType: Compliance.LongRunningProcessQueueTypeEnum.Normal,
            processes: 4,
            duration: 2000,
            wait: 0,
            throwException: false,
            publishProgressChangeMessage: true
        });
    }

    setOverTimeDefault(): void {
        this.form.setValue({
            queueType: Compliance.LongRunningProcessQueueTypeEnum.Normal,
            processes: 4,
            duration: 2000,
            wait: 500,
            throwException: false,
            publishProgressChangeMessage: true
        });
    }

    ngOnDestroy(): void { }

    async test(): Promise<void> {
        this.result = {
            longRunningProcessQueueType: this.form.get("queueType").value as Compliance.LongRunningProcessQueueTypeEnum,
            numberOfProcesses: this.form.get('processes').value as number,
            duration: this.form.get('duration').value as number,
            throwException: this.form.get('throwException').value as boolean,
            publishProgressChangeMessage: this.form.get('publishProgressChangeMessage').value as boolean,
            cancel: false,
            wait: this.form.get('wait').value as number
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
