<div class="container">
    <div class="single-column-layout">
        <h1>Manage My Instances</h1>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th style="width: 30px;">&nbsp;</th>
                    <th>Instance</th>
                    <th>Company</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let instanceInviteStatus of statuses">
                    <td style="width: 30px;" [innerHTML]="getHomeIndicator(instanceInviteStatus)">
                    </td>
                    <td>
                        {{instanceInviteStatus.instanceName}}
                    </td>
                    <td>
                        {{instanceInviteStatus.companyName}}
                    </td>
                    <td>
                        {{getStatus(instanceInviteStatus)}}
                    </td>
                    <td>
                        <span *ngIf="instanceInviteStatus.active === true">
                            <button *ngIf="instanceInviteStatus.invite === false" (click)='acceptInvitation(instanceInviteStatus.contactId)' class="btn small-button flat-button success-button">Accept Invitation</button> 
                            <button *ngIf="instanceInviteStatus.invite === false" (click)='rejectInvitation(instanceInviteStatus.contactId)' class="btn small-button flat-button warning-button">Reject Invitation</button> 
                            <button *ngIf="instanceInviteStatus.invite && instanceInviteStatus.hidden === false && instanceInviteStatus.isHomeInstance === false" (click)='toggleVisibility(instanceInviteStatus.contactId)' class="btn small-button flat-button primary-button">Hide Instance</button> 
                            <button *ngIf="instanceInviteStatus.invite && instanceInviteStatus.hidden === true" (click)='toggleVisibility(instanceInviteStatus.contactId)' class="btn small-button flat-button success-button">Show Instance</button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="changesMade"><i class="fa fa-warning text-danger"></i> You will need to <strong>logout for your changes to take effect</strong> in the application</div>
    </div>
</div>  
