<ws-modal-wrapper headerLabel="Payment Batch Workflow"
    headerHelpContentId="delivery-details.payment-batch-modal"
    [cancelLabel]="params.editMode ? 'Cancel' : 'Close'"
    [hideSave]="!params.editMode"
    [disableSave]="!canSave()"
    [suppressKeyboardEvents]="true"
    (save)="save()"
    (cancel)="close()">
        <loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
        <table class="workflows" *ngIf="!loading && selectedWorkflow">
            <tr>
                <td>Workflow:</td>
                <td>
                    <select class="form-control input-sm workflow-dropdown"
                        [(ngModel)]="selectedWorkflow" 
                        (change)="workflowChanged()"
                        [disabled]="!params.editMode">
                        <option *ngFor="let workflow of taskSeriesList" [ngValue]="workflow">{{workflow.seriesOptionName}}</option>
                    </select>
                </td>
            </tr>
            <tr *ngFor="let task of selectedWorkflow.availableTasks">
                <td>{{task.taskName}}:</td>
                <td *ngIf="params.editMode" class="pbw-user-picker">
                    <user-team-picker
                        [userId]="currentData[prefixMap[task.taskTypeId] + 'UserId']"
                        [teamId]="currentData[prefixMap[task.taskTypeId] + 'TeamId']"
                        [entityIdScope]="params.entity.id"
                        [entityTypeScope]="params.entity.type"
                        [instanceIdScope]="params.userSearchInstanceId"
                        (userChange)="userChanged($event, prefixMap[task.taskTypeId], task.isRequired)"
                        [parentUsers]="parentUsers"
                        [required]="task.isRequired"
                        [disabled]="!params.editMode">
                    </user-team-picker>
                    <button *ngIf="!task.isRequired" 
                        class="flat-button icon-button warning-button" 
                        (click)="userChanged(undefined, prefixMap[task.taskTypeId], false)"
                        style="margin:0;">
                            <i class="fa fa-trash"></i>
                    </button>
                </td>
                <td *ngIf="currentData[prefixMap[task.taskTypeId] + 'User'] && (!params.editMode || !params.entity.isTopLevel)">
                    {{getDisplayName(currentData[prefixMap[task.taskTypeId] + 'User'], currentData[prefixMap[task.taskTypeId] + 'Team'])}}
                </td>
            </tr>
        </table>

</ws-modal-wrapper>