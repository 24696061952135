import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';


@Component({
    selector: 'valuation-panel',
    templateUrl: './valuationPanel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuationPanelComponent {
    @Input() companyId: number;
    @Input() parcelId: number;
    @Input() siteId: number;
    @Input() company: Core.CompanyModel;

    onIncomeStatementLoading: boolean = true;
    onRentRollLoading: boolean = true;
    hasViewPermission: boolean = true;
    showBody: boolean = false;
    showIncomeStatement: boolean = true;
    showRentRoll: boolean = false;

    constructor(public featureFlagService: FeatureFlagsService) {}

    expandOrCollapse(): void {
        this.showBody = !this.showBody;
    }

    incomeStatementSelected() {
        this.showRentRoll = false;
        this.showIncomeStatement = true;
    }

    rentRollSelected() {
        this.showIncomeStatement = false;
        this.showRentRoll = true;
    }
}
