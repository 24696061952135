import { Injectable } from '@angular/core';

@Injectable()
export class EntityImportService {
    private _taxPaymentsMatchOverallOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Parcel Information', value: 'parcel_information' },
        { name: 'Payment System ID', value: 'payment_system_id' }
    ];

    private _taxPaymentsMatchParcelOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Parcel Acct Num + State (assessor inferred from collector)', value: 'parcel_acct_num_state' },
        { name: 'Parcel System ID', value: 'parcel_system_id' },
        { name: 'Parcel PTX ID', value: 'parcel_ptx_id' }
    ];

    private _taxPaymentsMatchCollectorOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Collector Name', value: 'collector_name' },
        { name: 'Collector System ID', value: 'collector_system_id' },
        { name: 'Collector PTX ID', value: 'collector_ptx_id' }
    ];

    private _assessmentsMatchOverallOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Parcel Information', value: 'parcel_information' },
        { name: 'Assessment System ID', value: 'assessment_system_id' }
    ];

    private _assessmentsMatchParcelOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Parcel Acct Num + State', value: 'parcel_acct_num_state' },
        { name: 'Parcel System ID', value: 'parcel_system_id' },
        { name: 'Parcel PTX ID', value: 'parcel_ptx_id' }
    ];

    private _assessmentsMatchAssessorOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Assessor Name', value: 'assessor_name' },
        { name: 'Assessor System ID', value: 'assessor_system_id' },
        { name: 'Assessor PTX ID', value: 'assessor_ptx_id' }
    ];

    private _glAccountIntendedUseOptions: Compliance.NameValuePair<string>[] = [
        { name: 'Compliance', value: 'compliance' },
        { name: 'Income Statements', value: 'income_statement' }
    ];

    get taxPaymentsMatchOverallOptions(): Compliance.NameValuePair<string>[] {
        return this._taxPaymentsMatchOverallOptions;
    }

    get taxPaymentsMatchParcelOptions(): Compliance.NameValuePair<string>[] {
        return this._taxPaymentsMatchParcelOptions;
    }

    get taxPaymentsMatchCollectorOptions(): Compliance.NameValuePair<string>[] {
        return this._taxPaymentsMatchCollectorOptions;
    }

    get assessmentsMatchOverallOptions(): Compliance.NameValuePair<string>[] {
        return this._assessmentsMatchOverallOptions;
    }

    get assessmentsMatchParcelOptions(): Compliance.NameValuePair<string>[] {
        return this._assessmentsMatchParcelOptions;
    }

    get assessmentsMatchAssessorOptions(): Compliance.NameValuePair<string>[] {
        return this._assessmentsMatchAssessorOptions;
    }

    get glAccountIntendedUseOptions(): Compliance.NameValuePair<string>[] {
        return this._glAccountIntendedUseOptions;
    }
}
