import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormFieldListComponent } from '../Form-Field-List/formFieldList.component';
import { WeissmanModalService } from '../../WeissmanModalService';

@Component({
    selector: 'form-fields',
    templateUrl: './formFields.component.html'
})
export class FormFieldsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService) { }

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async show(): Promise<void> {
        await this._modalService.showAsync(FormFieldListComponent, null, 'modal-xl');
    }
}
