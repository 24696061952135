import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ColDef,
    ColumnVisibleEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams
} from 'ag-grid-community';
import * as _ from 'lodash';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import {
    AgGridColumns,
    AgGridCurrencyCellEditor,
    AgGridDatepickerCellEditor,
    AgGridDropdownCellEditor,
    AgGridFilterParams,
    AgGridNumberCellEditor,
    AgGridOptionsBuilder,
    AgGridTextCellEditor
} from '../../Compliance/AgGrid';
import {
    AgGridLinkCellRenderer,
    AgGridLinkCellRendererParams
} from '../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../Compliance/AgGrid/MultiSelectTracker';
import { GridSourceOptionEnum } from '../../Compliance/State-And-Jurisdiction/stateJurisdiction.service';
import { ProcessingService } from '../../Processing/processing.service.upgrade';
import { CsrCommandCenterService } from './csrCommandCenter.service';
import { CsrBulkUpdateComponent } from './Bulk-Update/csrBulkUpdate.component';
import { CsrListAgGridDataSource, CsrListDataSourceParams } from './agGridDataSource';
import {
    EntityFilter,
    EntitySelection,
    ReportDetail,
    ReportDetailCriteria,
    SelectedEntity
} from '../../Reporting/Manager/report.manager.model';
import { ReportType } from '../../Reporting/Manager/report-type';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { ReportManagerSelectEntitiesService } from '../../Reporting/Manager/select.entities.service';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../Compliance/AgGrid/ToolPanel/models';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { EntityType } from '../../constants.new';
import { AgGridTooltipCellRenderer } from '../../Compliance/AgGrid/CellRenderers/agGridTooltipCellRenderer.component';
import { CellEditorValidationResult } from '../../Compliance/AgGrid/CellEditors/validator.interface';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { ToastrService } from 'ngx-toastr';
import { CSR_COMMAND_CENTER_HELP } from './csrCommandCenter.component.help';
import { WebsocketListenerService } from 'src/app/Compliance/websocketListener.service';
import { BusyIndicatorRef, BusyIndicatorService } from 'src/app/Busy-Indicator';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { CsrUpdateStatusCellRendererComponent } from './agGridCsrUpdateStatusCellRenderer.component';
import { ClientServiceResponsibilityService } from '../clientServiceResponsibility.service';

export interface BulkUpdateCsrIdentifier {
    entityId: number,
    entityTypeId: number,
    clientServiceId: number,
    propertyTypeId: number,
}

export interface BulkUpdateParams {
    csrs: BulkUpdateCsrIdentifier[];
    entityId: number;
    entityTypeId: number;
    topLevelCompanyId: number;
    longRunningProcessId?: number;
}

@Component({
    selector: 'csr-command-center',
    templateUrl: './csrCommandCenter.component.html',
    styles: [`
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
    `]
})
export class CsrCommandCenterComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _helpService: HelpService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _processingService: ProcessingService,
        private readonly _ccService : CsrCommandCenterService,
        private readonly _selectEntitiesService : ReportManagerSelectEntitiesService,
        private readonly _modalService: WeissmanModalService,
        private readonly _messageModalService : MessageModalService,
        private readonly _toastrService: ToastrService,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _csrService: ClientServiceResponsibilityService,
    ) {
     }

    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isRemoveExceptionsVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    selectedRows: number[] = [];
    gridId: System.Guid = '6FBDE227-1CFA-475E-B525-5992C1C368DB';
    breadcrumbs: BreadCrumb[] = [];
    isInitialized: boolean = false;
    isLoading: boolean = false;
    hasEditPermission: boolean = false;
    refreshing: boolean;
    showGrid: boolean = false;
    longRunningUpdateUnderway: boolean = false;
    isMaximized: boolean = false;
    isEmbededMode: boolean = false;
    exceptionsOnly: boolean = false;
    report: ReportDetail = new ReportDetail();
    entitySelection: EntitySelection = new EntitySelection();
    entityTree: any;

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.id)
        },
        rowModelType: 'infinite'
    }).withInfiniteScroll(100)
    .withLoadingOverlay()
    .withColumnResize()
    .withMultipleColumnSort()
    .withColumnPinning()
    .build();

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();

            const exportModel: Core.ClientServiceResponsibilityCommandCenterExportModel = {
                columnsToReturn: columnsToReturn,
                searchModel: searchModel,
                companyId: this.entityTree.subsidiaryCompany.entityId
            };

            const longRunningProcessId = await this._ccService.exportList(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportClientServiceResponsibilityCommandCenter };
        },
        disabled: false,
        canCancel: true
    };


    debouncedEntitySelectionChanged;

    private _gridApi: GridApi;
    private _gridMultiSelectSub: Subscription;
    private _destroy$: Subject<void> = new Subject();
    private _busyRef: BusyIndicatorRef;
    private _gridDataSource: CsrListAgGridDataSource;

    get refreshingGrid(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    async ngOnInit(): Promise<void> {

        this._helpService.setContent(CSR_COMMAND_CENTER_HELP);

        this.report.criteria = new ReportDetailCriteria();
        this.report.criteria.frequentlyUsed = false;
        this.report.criteria.reportType = ReportType.CsrCommandCenter;
        this.report.reportTypeId = 12;
        this.report.isOwnedByUser = true;
        this.report.criteria.includeInternalUsers = true;

        this.debouncedEntitySelectionChanged = _.debounce(this.onEntitySelectionChanged, 500);

        await this.loadEntity();
        await this.loadEntityCharacteristics();

        this.configureBreadcrumbs();

        this.gridOptions.onColumnVisible = (event: ColumnVisibleEvent) => {
            const columns = event.columnApi.getAllDisplayedColumns();

            const initialDescriptors = [...this._gridDataSource.descriptorIds];
            this._gridDataSource.descriptorIds = columns.filter(c => c.getColId().startsWith('d.')).map(c => +c.getColId().substring(2));
            if (!this._gridDataSource.descriptorIds.every(e => initialDescriptors.includes(e))) {
                this._gridDataSource.refresh();
            }
            return true;
        };

        const validProcessTypes = [Compliance.LongRunningProcessTypeEnum.UpdateCsrBulk, Compliance.LongRunningProcessTypeEnum.UpdateCsr];
        this._websocketListenerService.longRunningProcessStatusChange$.pipe(takeUntil(this._destroy$)).subscribe(
            async (x) => {
                if ((!validProcessTypes.includes(x.processType)) || x.entityId !== this.entitySelection.selectedTopLevelCompanyIDs[0]) {
                    return;
                }
                if (x.isCanceled || x.isError || x.isCompleted) {
                    this.longRunningUpdateUnderway = false;
                    if (this._busyRef) {
                        this._busyRef.hide();
                        this._busyRef = null;
                    }
                    this.refresh();
                } else {
                    this.longRunningUpdateUnderway = true;
                    this.isBulkUpdateVisible$.next(false);
                    this.isRemoveExceptionsVisible$.next(false);
                }
            });

        this.isInitialized = true;
        return Promise.resolve();
    }

    private configureBreadcrumbs(): void {

        if(this.entityTree.topLevelCompany) {
            this.breadcrumbs.push({
                name: this.entityTree.topLevelCompany.text,
                target: 'company',
                options: { companyId: this.entityTree.topLevelCompany.entityId}
            });
        }

        this.breadcrumbs.push({
            name: this.entityTree.subsidiaryCompany.text,
            target: 'company',
            options: { companyId: this.entityTree.subsidiaryCompany.entityId }
        });

        if(this.entityTree.site) {
            this.breadcrumbs.push({
                name: this.entityTree.site.text,
                target: 'site',
                options: { companyId: this.entityTree.subsidiaryCompany.entityId, siteId: this.entityTree.site.entityId }
            });
        }

        if(this.entityTree.parcel) {
            this.breadcrumbs.push({
                name: this.entityTree.parcel.text,
                target: 'parcel',
                options: { companyId: this.entityTree.subsidiaryCompany.entityId, siteId: this.entityTree.site.entityId, parcelId: this.entityTree.parcel.entityId }
            });
        }
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);
        this.isBulkUpdateVisible$.next(false);
        this.isRemoveExceptionsVisible$.next(false);

        const dataSourceParams = (): CsrListDataSourceParams => {
            return {
                rootEntityId: this.entityTree.topLevelCompany ? this.entityTree.topLevelCompany.entityId : this.rootEntityId,
                rootEntityType: this.entityTree.topLevelCompany ? this.entityTree.topLevelCompany.entityTypeId : this.rootEntityTypeId,
                clientServiceIds: this.selectedServicesIds,
                propertyTypeIds: this.selectedPropertyTypeIds,
                exceptionsOnly: this.exceptionsOnly,
                entityFilters: this.entityFilters,
                sitePropCharFilters: this.sitePropCharFilters,
                parcelPropCharFilters: this.parcelPropCharFilters,
                sourceEntityIDs: this.sourceEntityIds,
                sourceEntityTypeID: this.sourceEntityTypeId,
                sourceEntityIsTopLevelCompany: this.sourceEntityIsTopLevelCompany,
                excludeInactiveCompanies: this.excludeInactiveCompanies,
                };
        };

        this._gridDataSource = new CsrListAgGridDataSource(
            this._gridApi,
            this._ccService,
            dataSourceParams,
            null
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _setGridColumns(): void {
        this._gridApi.setColumnDefs([]);

        //get standard columns
        const columns = this._getGridColumnsBySource(GridSourceOptionEnum.Assessor);

        const pickList = this.entityCharacteristics.pickList;

        //add dynamic columns based on the site/parcel chars
        this.entityCharacteristics.descriptors.reduce((acc, descriptor) => {
            const column: ColDef = BASE_DYNAMIC_DEFINITIONS[descriptor.fieldType](descriptor, pickList);
            column.cellRendererParams = {
                getTooltipText: (params) => {
                    return '';
                }
            };
            acc.push(column);

            return acc;
        }, columns);

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setFilterModel(null);
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }


    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._setGridColumns();

        this._gridApi.sizeColumnsToFit();
        if(this.showGrid)
         this._setDataSource();

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            if (!this.longRunningUpdateUnderway) {
                this.isBulkUpdateVisible$.next(this.gridTracker.hasSelectedRows());
                this.isRemoveExceptionsVisible$.next(this.gridTracker.hasSelectedRows());
            }
        });
    }

    async bulkUpdate(): Promise<void> {

        this.isLoading = true;
        let selectedRowIds ;
        try {
            selectedRowIds = await this.gridTracker.getSelectedRowIds();
        }
        finally {
            this.isLoading = false;
        }

        const csrs = selectedRowIds.map((sri:any) => this.parseCsrGridId(sri));

        const params:BulkUpdateParams = {csrs: csrs, entityId: this.rootEntityId, entityTypeId: this.rootEntityTypeId, topLevelCompanyId: this._gridApi.getRenderedNodes()[0].data.topLevelCompanyId };

        const result = await this._modalService.showAsync(CsrBulkUpdateComponent, params, 'modal-lg');

        if (params.longRunningProcessId) {
            this._busyRef = this._busyService.show({
                longRunningProcessId: params.longRunningProcessId,
                title: 'Updating Client Service Responsibility',
                canDismiss: true,
                message: '',
                captureFocus: true,
                restoreFocus: true,
                hasProgressBar: true,
            });

            this._busyRef.onDismiss().pipe(takeUntil(this._destroy$)).subscribe(() => {
                this._busyRef.hide();
                this._destroy$.next();
                this._busyRef = null;
            });
        }


        if (!result) {
            return Promise.resolve();
        }

        this.refresh();
        return Promise.resolve();
    }

    async removeExceptions(): Promise<void> {

        this.isLoading = true;
        let selectedRowIds ;
        try {
            const searchModel = this._gridDataSource.getSearchParamsWithoutPagination();
            searchModel.exceptionsOnly = true;
            const exceptionRows = await this._ccService.searchRowIds(searchModel);
            const ids = await this.gridTracker.getSelectedRowIds();
            selectedRowIds = exceptionRows.data.filter(er => ids.includes(er));
        }
        finally {
            this.isLoading = false;
        }

        const possibleExceptions = [...new Set(selectedRowIds.map((sri) => this.parseCsrGridId(sri)).map(sri => sri.clientServiceResponsibilityId))] as number[];

        try {
            await this._messageModalService.confirm('Are you sure you want to remove client service responsibility exceptions from the selected items?', 'Remove Exceptions');

            this.isLoading = true;
            const params: Core.ClientServiceExceptionRemoveRequest = {responsibilitiesToRemove: possibleExceptions, topLevelCompanyId: this.rootEntityId };
            await this._ccService.removeExceptions(params);

            this._toastrService.success('Selected client service responsibility exception(s) removed');

            this.refresh();
        }
        catch(e){
            return Promise.resolve();
        }
        finally {
            this.isLoading = false;
        }

    }

    private parseCsrGridId(sri) {
        //This routine is pulling the different pieces of various ids that were crammed into a guid server-side.  Because Microsoft is encoding the guids, parse it as "variant 2"
        //https://en.wikipedia.org/wiki/Universally_unique_identifier

        const hexRepresentation = sri.replaceAll('-', '');
        const firstNumStr = hexRepresentation.substr(0, 8);
        const firstNum = parseInt(firstNumStr, 16);
        const secondNumStr = `${  hexRepresentation.substr(12, 4)  }${hexRepresentation.substr(8, 4)}`;
        const secondNum = parseInt(secondNumStr, 16);
        const thirdNumStr = `${  hexRepresentation.substr(22, 2)  }${hexRepresentation.substr(20, 2)  }${hexRepresentation.substr(18, 2)  }${hexRepresentation.substr(16, 2)}`;
        const thirdNum = parseInt(thirdNumStr, 16);

        const fourthNumStr = hexRepresentation.substr(30, 2) +
                            hexRepresentation.substr(28, 2) +
                            hexRepresentation.substr(26, 2) +
                            hexRepresentation.substr(24, 2);

        const fourthNum = parseInt(fourthNumStr, 16);


        const entityId = firstNum;
        const entityTypeId = secondNum;
        const clientServicePropertyTypeInt = thirdNum;
        const clientServiceResponsibilityId = fourthNum;

        const propertyTypeId = clientServicePropertyTypeInt >> 16;
        const clientServiceId = clientServicePropertyTypeInt % Math.pow(2, 16);

        return {
            entityId,
            entityTypeId,
            propertyTypeId,
            clientServiceId,
            clientServiceResponsibilityId,
        };
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this.showGrid = true;
    this.gridTracker.clear(false);
        this.isBulkUpdateVisible$.next(false);
        this.isRemoveExceptionsVisible$.next(false);
        this._gridDataSource.refresh();
    }

    refresh() {
        this._refreshDataSource();
    }

    private _getGridColumnsBySource(gridSourceOption: GridSourceOptionEnum): ColDef[] {
        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'id',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            } as ColDef,

            {
                headerName: 'Top Level Company',
                field: 'topLevelCompanyName',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const row = params.data as Core.ClientServiceResponsibilityCommandCenterModel;
                        if (!row || !row.topLevelCompanyId) {
                            return '';
                        }
                        return `#/company/${row.topLevelCompanyId}`;
                    }
                } as AgGridLinkCellRendererParams

            },
            {
                headerName: 'Subsidiary',
                field: 'companyName',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const row = params.data as Core.ClientServiceResponsibilityCommandCenterModel;
                        if (!row || !row.companyId) {
                            return '';
                        }
                        return `#/company/${row.companyId}`;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Company Code',
                field: 'companyCode',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Site',
                field: 'siteName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellClass: params => params.data && params.data.siteId === null ? 'barely-gray' : '',
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const row = params.data as Core.ClientServiceResponsibilityCommandCenterModel;
                        if (!row || !row.siteId) {
                            return '';
                        }
                        return `#/site/${row.siteId}`;
                    }
                } as AgGridLinkCellRendererParams

            },
            {
                headerName: 'Site Number',
                field: 'siteNumber',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Parcel',
                field: 'parcelAccountNumber',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellClass: params => params.data && params.data.parcelId === null ? 'barely-gray' : '',
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const row = params.data as Core.ClientServiceResponsibilityCommandCenterModel;
                        if (!row || !row.parcelId) {
                            return '';
                        }
                        return `#/parcel/${row.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams

            },
            {
                headerName: 'State',
                field: 'state',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Client Service',
                field: 'clientServiceName',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'PropertyType',
                field: 'propertyTypeName',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Responsibility',
                field: 'responsibility',
                lockVisible: false,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: (params: ICellRendererParams): string => {
                    const row = params.data as Core.ClientServiceResponsibilityCommandCenterModel;
                    return row && row.isException ? 'overridden' : '';
                },
                width: AgGridColumns.textColumnWidth
            },
            {
                colId: 'grid-csr-update-status',
                headerName: '',
                field: 'csrUpdateStatus',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                pinned: 'right',
                lockVisible: true,
                sortable: false,
                cellRendererFramework: CsrUpdateStatusCellRendererComponent,
                cellRendererParams: {
                    retryAction: this.retryResponsibilityUpdate.bind(this)
                },
                pinnedRowCellRenderer: () => {return '';}
            }
        ];

        return columns;
    }

    private rootEntityId: number;
    private rootEntityTypeId: number;
    private entityCharacteristics: Core.ClientServiceResponsibilityCommandCenterDescriptorInfoModel;
    private selectedPropertyTypeIds: number[] = [];
    private selectedServicesIds: number[] = [];
    private entityFilters: EntityFilter[] = [];
    private excludeInactiveCompanies: boolean = false;
    private sourceEntityIds: number[] = [];
    private sourceEntityTypeId: number;
    private sourceEntityIsTopLevelCompany: boolean = false;
    private sitePropCharFilters: Compliance.NameValuePair<any>[] = [];
    private parcelPropCharFilters: Compliance.NameValuePair<any>[] = [];

    private async loadEntity() {
        this.rootEntityId = +this._upgradeNavigationServiceHandler.getQuerystringParam('entityId');
        this.rootEntityTypeId = +this._upgradeNavigationServiceHandler.getQuerystringParam('entityTypeId');
        this.entityTree = await this._processingService.getEntityTree(this.rootEntityTypeId, this.rootEntityId, false);
        this.entitySelection.selectedTopLevelCompanyIDs[0] = this.entityTree.topLevelCompany ?  this.entityTree.topLevelCompany.entityId : this.entityTree.subsidiaryCompany.entityId;

        //top level company not set - must be top level company
        this.sourceEntityIsTopLevelCompany = this.entityTree.topLevelCompany === null && this.rootEntityTypeId === EntityType.Company;

        //determine entity filter that is set based on the type of entity passed in on route as long as not top company
        const selectedFilters:SelectedEntity[] = [];

        //show subsidiary filter with selection
        if(this.entityTree.subsidiaryCompany && this.entityTree.topLevelCompany !== null) {
            const selectedSubsidiaryFilter = this._selectEntitiesService.getSelectedEntities().filter(f => f.id === EntityType.Company)[0];
            selectedSubsidiaryFilter.selectedIDs = [this.entityTree.subsidiaryCompany.entityId];
            selectedFilters.push(selectedSubsidiaryFilter);
        }

        //show site filter with selection
        if(this.entityTree.site) {
            const selectedSiteFilter = this._selectEntitiesService.getSelectedEntities().filter(f => f.id === EntityType.Site)[0];
            selectedSiteFilter.selectedIDs = [this.entityTree.site.entityId];
            selectedFilters.push(selectedSiteFilter);
        }

        //show parcel filter with selection
        if(this.entityTree.parcel) {
            const selectedParcelFilter = this._selectEntitiesService.getSelectedEntities().filter(f => f.id === EntityType.Parcel)[0];
            selectedParcelFilter.selectedIDs = [this.entityTree.parcel.entityId];
            selectedFilters.push(selectedParcelFilter);
        }

        //filters that appear in faceted entity selector
        this.entitySelection.selectedEntities = selectedFilters;
    }

    private async loadEntityCharacteristics() {
        this.entityCharacteristics = await this._ccService.getEntityDescriptors(this.rootEntityId, this.rootEntityTypeId);
    }

    onEntitySelectionChanged(event) {
        const payload = this._selectEntitiesService.getPayloadSourceEntityData(this.entitySelection);
        this.entityFilters = payload.entityFilters;
        this.sourceEntityIds = payload.sourceEntityIDs;
        this.sourceEntityTypeId = payload.sourceEntityTypeID;
        this.sourceEntityIsTopLevelCompany = payload.sourceEntityIsTopLevelCompany || false;
        this.sitePropCharFilters = payload.sitePropCharFilters as Compliance.NameValuePair<any>[];
        this.parcelPropCharFilters = payload.parcelPropCharFilters as Compliance.NameValuePair<any>[];
        this.excludeInactiveCompanies = payload.excludeInactive;
        this._refreshDataSource();
        this.showGrid = true;
    }

    toggleMaximize(isMaximized: boolean): void {
        this.isMaximized = isMaximized;
    }

    private async retryResponsibilityUpdate(params: ICellRendererParams): Promise<void> {
        const respData = params.data as Core.ClientServiceResponsibilityCommandCenterModel;

        await this._csrService.retryWorkflowUpdateFromQueue({
            entityId: respData.parcelId ?? respData.siteId ?? respData.companyId ?? respData.topLevelCompanyId,
            entityTypeId: respData.parcelId ? 6 : respData.siteId ? 5 : 1,
            clientServiceId: respData.clientServiceId,
            propertyTypeId: respData.propertyTypeId
        });
    }
}

export interface EntityDescriptorColumnDefinitions {
    [key: number]: (descriptor:  Core.ClientServiceResponsibilityCommandCenterDescriptorModel, pickList?: Core.ClientServiceResponsibilityCommandCenterDescriptorPicklistModel[]) => ColDef;
}

export const BASE_DYNAMIC_DEFINITIONS: EntityDescriptorColumnDefinitions = {
    [Core.DescriptorFieldTypes.Currency]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            sortable: false,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.numericColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridCurrencyCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { }
            },
            hide: true,
            filter: 'agNumberColumnFilter',
            filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            valueFormatter: (params) => (+params.value) ? `$ ${(+params.value).toFixed(2)}` : '',
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Date]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            sortable: false,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.dateColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDatepickerCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: Date): CellEditorValidationResult => {
                    const year = value.getFullYear();
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.minValue ? year >= validation.minValue : true)
                        && (validation.maxValue ? year <= validation.maxValue : true);
                    let message = 'Year must be ';
                    message += (validation.minValue) && `> ${validation.minValue - 1}`;
                    message += (validation.minValue && validation.maxValue) && ' and ';
                    message += (validation.maxValue) && `< ${validation.maxValue + 1}`;
                    return { isValid, validationMessage: message };
                }
            },
            hide: true,
            filter: 'agDateColumnFilter',
            filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            valueFormatter: (params) => WeissmanDateFormat(params.value, true),
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Number]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            sortable: false,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.numericColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridNumberCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: number): CellEditorValidationResult => {
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.minValue ? value >= validation.minValue : true)
                        && (validation.maxValue ? value <= validation.maxValue : true);
                    let message = 'Value must be ';
                    message += (validation.minValue) && `> ${validation.minValue - 1}`;
                    message += (validation.minValue && validation.maxValue) && ' and ';
                    message += (validation.maxValue) && `< ${ validation.maxValue + 1 }`;
                    return { isValid, validationMessage: message};
                }
            },
            hide: true,
            filter: 'agNumberColumnFilter',
            filterParams: AgGridFilterParams.numberFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Picklist]: (descriptor, picklist) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.selectionColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDropdownCellEditor,
            cellEditorParams: {
                getOptions: () => {
                    return picklist.reduce((acc, x) => {
                        if (x.groupId === getDescriptor().picklistGroupId) {
                            acc.push({ name: x.name, value: x.name });
                        }
                        return acc;
                    }, []);
                },
                cellFocusLost: () => { }
            },
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.Text]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.textColumnWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridTextCellEditor,
            cellEditorParams: {
                cellFocusLost: () => { },
                validator: (value: string): CellEditorValidationResult => {
                    const validation = getDescriptor().validation;
                    const isValid = validation
                        && (validation.maxLength ? value.length <= validation.maxLength : true);
                    const message = `Max characters: ${validation.maxLength}`;
                    return { isValid, validationMessage: message };
                },
                maxLength: descriptor.validation && descriptor.validation.maxLength
            },
            hide: true,
            sortable: false,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            cellClass: () => ''
        };
    },
    [Core.DescriptorFieldTypes.YesNo]: (descriptor) => {
        const getDescriptor = () => descriptor; // create closure for later access
        return {
            headerName: descriptor.name,
            field: `d.${descriptor.descriptorId}`,
            width: AgGridColumns.checkboxColumnMinWidth,
            cellRendererFramework: AgGridTooltipCellRenderer,
            cellRendererParams: {},
            cellEditorFramework: AgGridDropdownCellEditor,
            cellEditorParams: {
                getOptions: () => [{ name: 'Yes', value: true }, {name: 'No', value: false}],
                cellFocusLost: () => { }
            },
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
            valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
            valueFormatter: (params) => {
                if (params.value || params.value === false) {
                    return (params.value) ? 'Yes' : 'No';
                } else {
                    return '';
                }
            },
            cellClass: () => ''
        };
    }
};


const getDescriptorValue =
    (model: any, descriptor: Core.ClientServiceResponsibilityCommandCenterDescriptorModel): any => {
        if (model && model.siteDescriptors && model.siteDescriptors.length) {
            const found = model.siteDescriptors.find(val => val.descriptorID === descriptor.descriptorId);
            return found && found.value;
        }
        if (model && model.parcelDescriptors && model.parcelDescriptors.length) {
            const found = model.parcelDescriptors.find(val => val.descriptorID === descriptor.descriptorId);
            return found && found.value;
        }
    };
