(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ChangeActionViewDisplayTypeModalController', ChangeActionViewDisplayTypeModalController);

	ChangeActionViewDisplayTypeModalController.$inject = [
        '$scope',
        '$uibModalInstance',
        'actionViewService',
        '$rootScope',
        'actionView',
        'actionViewType',
        'ActionViewDisplayTypes'
	];

	function ChangeActionViewDisplayTypeModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, $uibModalInstance, actionViewService, $rootScope, actionView, actionViewType, ActionViewDisplayTypes) {
		$scope.selectedDisplayType = '0';

		$scope.actionView = actionView;
		$scope.selectedDisplayType = $scope.actionView.defaultDisplay;
		$scope.displayTypes = ActionViewDisplayTypes;

		console.log($scope.actionView)

		$scope.save = function () {
			$scope.actionView.defaultDisplay = $scope.selectedDisplayType;

			//This is to work around a bug where no categoryId passed in clears the Id unintentionally. categoryId < 0 means "don't change"
			$scope.actionView.categoryId = -1;

			actionViewService.patch($scope.actionView, actionViewType)
				.then(function () {
					$rootScope.$broadcast('updateActionViewMenu', $scope.actionView);
					$uibModalInstance.dismiss();
				});
		}

		$scope.cancel = function () {
			$uibModalInstance.dismiss();
		}

		$scope.thatAreCompleted = function () {
			return actionView.thatAreChoice === 4;
		}

		activate();

		function activate() {}
	}
})();
