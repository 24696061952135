<div class="card panel-flat-color secondary-panel accruals-panel">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Accruals</h3>
    </div>
    <div class="card-body" *ngIf="showBody" style="padding-top: 0;height: 490px;">
        <tabset>
            <tab heading="Adjustments" (selectTab)="showBalance = false">
                <div class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
                    <accruals-adjustments-list *ngIf="!showBalance" [parcelId]="parcelId" [(editMode)]="adjustmentsEditMode" [hasEditPermission]="hasEditPermission"></accruals-adjustments-list>
                </div>
            </tab>
            <tab heading="Balance" [disabled]="adjustmentsEditMode" (selectTab)="showBalance = true">
                <accruals-balances-list *ngIf="showBalance" [parcelId]="parcelId"></accruals-balances-list>
            </tab>
        </tabset>
    </div>
</div>
