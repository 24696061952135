import { TasksByEntityDTO } from './Task.Model';
import { EntityDeactivationValidation } from './Task.Model';

// This only contains some functionality from the task service; please add more to the definition as needed
export abstract class TaskService {
    // At some point we should define this so it doesn't have to be "any" (for what it's worth, this isn't well-defined on the server either)
    abstract getTaskSummaryByEntity(entityID: number, entityTypeID: number): Promise<any>;
    abstract launchTaskModal(entityID: number, entityTypeID: number, readonly?: boolean, toastMessage?: string, editonly?:boolean): Promise<boolean>;
    abstract getByEntity(entityID: number, entityTypeID: number): Promise<TasksByEntityDTO>;
    abstract showErrorNotificationModal(errors: string[], warnings: string[], errorMessage: string, warningMessage?: string): void;
    abstract checkIfEntityMayBeDeactivated(entityID: number, entityTypeID: number): Promise<EntityDeactivationValidation>;
}
