import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class StateRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly BASE_URL = '/api/state/';

    getById(id: number): Observable<Weissman.Model.Misc.State> {
        return this.httpGet(`${this.BASE_URL}${id}`);
    }
}
