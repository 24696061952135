import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';

export interface LegalDescriptionModalParams {
    isEditMode: boolean;
    parcel: Weissman.Model.Domain.Parcel
}

@Component({
    selector: 'legal-description-modal',
    template: `
        <ws-modal-wrapper [headerLabel]="headerLabel" 
            [hideSave]="!params.isEditMode" 
            (save)="save()" 
            saveLabel="Save"
            (cancel)="close()" 
            [cancelLabel]="params.isEditMode ? 'Cancel' : 'Close'"
            [useAceStyles]="true">
            <textarea class="form-control" 
                maxlength="8000" 
                [(ngModel)]="descToEdit" 
                rows="30" 
                [readonly]="!params.isEditMode" 
                style="resize: none; background-color: white">
            </textarea>
        </ws-modal-wrapper>
    `
})

export class LegalDescriptionModalComponent implements OnInit, IWeissmanModalComponent<LegalDescriptionModalParams, void> {
    params: LegalDescriptionModalParams;
    result: void;

    headerLabel: string;
    descToEdit: string;

    constructor(private readonly _bsModalRef: BsModalRef) { }

    ngOnInit() {
        this.headerLabel = `Legal description for parcel: ${this.params.parcel.acctNum}`;
        this.descToEdit = _.cloneDeep(this.params.parcel.legalDescription);
    }

    save() {
        this.params.parcel.legalDescription = this.descToEdit;
        this.close();
    }

    close() {
        this._bsModalRef.hide();
    }
}