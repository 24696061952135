<div class="ws-flex-container-vertical ws-stretch">
    <filing-control-list-reconciliation-report (navigateToAssets)="navigateToAssets($event)"
                                               (navigateToPreview)="navigateToPreview($event)"
                                               [data]="data"
                                               [formRevisionId]="formRevisionId"
                                               [refreshing]="refreshing"
                                               (refreshData)="refresh()"
                                               *ngIf="groupBy === filingControlGroupBy.None">
    </filing-control-list-reconciliation-report>
    <filing-control-grouped-reconciliation-report (navigateToAssets)="navigateToAssets($event)"
                                                  (navigateToPreview)="navigateToPreview($event)"
                                                  [data]="data"
                                                  [formRevisionId]="formRevisionId"
                                                  [groupBy]="groupBy"
                                                  [refreshing]="refreshing"
                                                  (refreshData)="refresh()"
                                                  *ngIf="(groupBy === filingControlGroupBy.Assessor || groupBy === filingControlGroupBy.Consolidated)">
    </filing-control-grouped-reconciliation-report>
</div>
