export class ScheduleAdditionsDisposalReconciliationReportRow {
    constructor(formRevisionScheduleId: number, scheduleName: string) {
        this.formRevisionScheduleId = formRevisionScheduleId;
        this.scheduleName = scheduleName;
    }

    formRevisionScheduleId: number = null;
    scheduleName: string = null;

    assetsCount: number = 0;

    cost: number = 0;
    priorCost: number = 0;
    additions: number = 0;
    disposals: number = 0;

    disposalsCost: number = 0;
    disposedAssetCount: number = 0;

    get netChange(): number {
        return (this.cost - this.priorCost) || 0;
    }

    get percentChange(): number {
        return this.priorCost === 0 ? null : (this.cost - this.priorCost) / this.priorCost;
    };
}

export class ScheduleAdditionsDisposalsReconciliationReport {
    constructor(
        private _assignedScheduleDetails: Compliance.ReturnAssetScheduleDetailsAssignedItemModel[],
        private _unassignedScheduleDetails: Compliance.ReturnAssetScheduleDetailsUnassignedItemModel[]
    ) {
        this._initialize();
    }

    reportableSchedules: ScheduleAdditionsDisposalReconciliationReportRow[] = [];
    totalReportable: ScheduleAdditionsDisposalReconciliationReportRow;
    totalNonReportable: ScheduleAdditionsDisposalReconciliationReportRow;
    total: ScheduleAdditionsDisposalReconciliationReportRow;
    notAssignedToASchedule: ScheduleAdditionsDisposalReconciliationReportRow;

    private _initialize(): void {
        this.notAssignedToASchedule = new ScheduleAdditionsDisposalReconciliationReportRow(null, null);

        this._unassignedScheduleDetails.forEach(scheduleDetail => {
            this.notAssignedToASchedule.cost += scheduleDetail.cost;
            this.notAssignedToASchedule.assetsCount += scheduleDetail.assetCount;
            this.notAssignedToASchedule.disposalsCost += scheduleDetail.disposalsCost;
            this.notAssignedToASchedule.disposedAssetCount += scheduleDetail.disposedAssetCount;
        });

        // for each schedule detail update the report model
        this._assignedScheduleDetails.forEach(scheduleDetail => {
            const scheduleData = new ScheduleAdditionsDisposalReconciliationReportRow(scheduleDetail.formRevisionScheduleId, scheduleDetail.name);
            scheduleData.assetsCount = scheduleDetail.assetCount;
            scheduleData.cost = scheduleDetail.cost;
            scheduleData.priorCost = scheduleDetail.priorCost;
            scheduleData.additions = scheduleDetail.additions;
            scheduleData.disposals = scheduleDetail.disposals;

            // differentiate between the reportable and non-reportable schedules (tracked separately in the model)
            scheduleDetail.isReportable ? this.reportableSchedules.push(scheduleData) : this.totalNonReportable = scheduleData;
        });

        // populate totals for the reported schedules
        this.totalReportable = new ScheduleAdditionsDisposalReconciliationReportRow(null, null);
        this.reportableSchedules.forEach(x => {
            this.totalReportable.assetsCount += x.assetsCount;
            this.totalReportable.cost += x.cost;
            this.totalReportable.priorCost += x.priorCost;
            this.totalReportable.additions += x.additions;
            this.totalReportable.disposals += x.disposals;
        });

        // populate totals for all schedules
        this.total = new ScheduleAdditionsDisposalReconciliationReportRow(null, null);
        this.total.assetsCount = this.totalReportable.assetsCount + this.totalNonReportable.assetsCount;
        this.total.priorCost = this.totalReportable.priorCost + this.totalNonReportable.priorCost,
        this.total.additions = this.totalReportable.additions + this.totalNonReportable.additions,
        this.total.disposals = this.totalReportable.disposals + this.totalNonReportable.disposals,
        this.total.cost = this.totalReportable.cost + this.totalNonReportable.cost;
    }
}
