import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { CompanyService } from 'src/app/Entity/Company/company.service';
import { DRTeamRetrievesDeliveryDetail } from '../../clientServices.model';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { Toast, ToastrService } from 'ngx-toastr';
import EntityTypes = Core.EntityTypes;
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'delivery-details-team-retrieves',
    templateUrl: './deliveryDetailsTeamRetrieves.component.html'
})
export class DeliveryDetailsTeamRetrievesComponent implements OnInit{
    @Input() editMode: boolean;
    @Input() topLevelData: DRTeamRetrievesDeliveryDetail;
    @Input() currentData: DRTeamRetrievesDeliveryDetail = {} as DRTeamRetrievesDeliveryDetail;
    @Input() entity: CompanyEntity;
    @Input() taxFeedIsEnabled: boolean;
    @Input() taxFeedEnablingMessage: string;
    @Input() taxFeedDisablingMessage: string;
    @Input() taxFeedToggleVisible: boolean;
    @Input() taxFeedToggleEnabled: boolean;
    @Input() isEntityInRyanInstance: boolean;

    @Output() currentDataChange: EventEmitter<any> = new EventEmitter();

    @Output() taxFeedIsEnabledChange: EventEmitter<boolean> = new EventEmitter();

    showTaxFeedToggle: boolean = false;

    constructor(
        private readonly _companyService: CompanyService,
        private readonly _toasr: ToastrService,
        private readonly _featureFlagsService: FeatureFlagsService) { }

    ngOnInit(): void {
        this.showTaxFeedToggle = this.taxFeedToggleVisible;
    }

    get isCompany(): boolean {
        return this.entity.type === 'company';
    }

    get isSubsidiary(): boolean {
        return this.isCompany && !this._companyService.isTopLevel();
    }

    useTopLevelChanged(): void {
        if (!this.currentData?.useTopLevelCompanySettings) {
            this.currentData.ownerEntityID = this.entity.id;
        }
        else {
            // This covers both Assessments and Tax bills using this one directive.
            _.assign(this.currentData, _.omit(this.topLevelData, 'useTopLevelCompanySettings', 'deliveryDetailAssessmentID', 'deliveryDetailsBillID'));
        }
        this.currentDataChange.emit(this.currentData);
    }

    taxFeedEnabledChanged(value) {
        this._toasr.success(value ? this.taxFeedEnablingMessage : this.taxFeedDisablingMessage);
        this.taxFeedIsEnabledChange.emit(value);
    }

    get taxFeedToggleDisabled(): boolean {
        return !this.editMode || !this.taxFeedToggleEnabled;
    }
}
