<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<div class="container">
    <div class="single-column-layout">
        <h1>Manage Support Instances</h1>
        <table class="table table-hover">
            <thead>
            <tr>
                <th>Instance</th>
                <th>Company</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let instance of instances">
                <td>
                    {{instance.instanceName}}
                </td>
                <td>
                    {{instance.topLevelCompanyName}}
                </td>
                <td>
                    {{getStatus(instance)}}
                </td>
                <td>
                    <button *ngIf="instance.accessGrantedDate" (click)='terminateAccess(instance)' [attr.disabled]="instance.isAccessTerminating" class="btn small-button flat-button warning-button">Terminate Access</button>
                    <button *ngIf="!instance.accessGrantedDate" (click)='requestAccess(instance)' class="btn small-button flat-button primary-button">Access Instance</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
