import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchPageType, UserSaveService } from '../../../Navigation/User-Save/userSave.service';
import { MessageBoxService } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ToastrService } from 'ngx-toastr';
import { AdvancedSearchPersistenceService, AdvancedSearchResults, AdvancedSearchRouteParams, SavedSearchModel, SearchOr } from '../advancedSearchPersistence.service';
import { SmartSearchService } from '../smartSearch.service';
import { UtilitiesService } from '../../../UI-Lib/Utilities';
import { SaveSearchModalComponent, SaveSearchModalParams } from '../Save-Search-Modal/saveSearchModal.component';
import { AdvancedSearchCategoryTypes, DefaultDisplayTypes } from '../../../constants.new';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { TimerService } from '../../../UI-Lib/Utilities';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';

import * as _ from 'lodash';
import { UserInstanceService } from 'src/app/User/userInstance.service';

@Component({
    selector: 'advanced-search',
    templateUrl: './advancedSearch.component.html',
    styleUrls: ['./advancedSearch.component.scss']
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _smartSearchService: SmartSearchService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _toastr: ToastrService,
        private readonly _utils: UtilitiesService,
        private readonly _modalService: WeissmanModalService,
        private readonly _navigationService: UpgradeNavigationServiceHandler,
        private readonly _timer: TimerService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _userSaveService: UserSaveService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input() search: SavedSearchModel;
    @Input() hasEditPermission: boolean;
    @Input() routeParams: AdvancedSearchRouteParams;

    @Output() executeSearch: EventEmitter<void> = new EventEmitter();
    @Output() updateSearch: EventEmitter<SavedSearchModel> = new EventEmitter();
    @Output() updateSearchResults: EventEmitter<AdvancedSearchResults> = new EventEmitter();

    showPageTitle: boolean;
    searchResults;
    isSystemSearch: boolean;
    isSavedSearch: boolean;
    isShown: boolean;
    isSaveEnabled: boolean = false;
    isSingleInstanceSelected: boolean = false;

    invalidColumns = [];

    private _updateAdvancedSearchMenu: Subject<void> = new Subject();
    private _destroy$: Subject<void> = new Subject();

    get hideRevokeShareButton(): boolean {
        return this.search && (!this.search.isOwnedByUser || !this.search.isShared);
    }

    get hideSaveButton(): boolean {
        //Hide the button if:
        //It's a system search and the user does not have edit permissions
        //It's from a widget that can never be edited
        //or if it's a custom search but it's not saved or not owned by the user

        return (this.isSystemSearch && !this.hasEditPermission)
               || (this.search && this.search.searchId < 1000)
               || (!this.isSystemSearch && (!this.isSavedSearch || !this.search.isOwnedByUser));
    }

    ngOnInit(): void {
        this.isSingleInstanceSelected = this._userInstanceService.isSingleInstanceSelected();
        this._advancedSearchPersistenceService.showCriteria$.pipe(takeUntil(this._destroy$)).subscribe(x => this.isShown = x);

        this._populateSearch().then(() => {
            this._smartSearchService.disableColumns(_.union(this.search.searchCriteria.filters, this.search.searchCriteria.outputColumns));

            this._utils.convertDateStringsToDates(this.search.searchCriteria.filters);
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async favoriteSearchToggle(status): Promise<void> {
        this.search.isFavorite = status;
        await this._smartSearchService.toggleSearchFavorite(this.search, status);
        this._updateAdvancedSearchMenu.next();
    }

    async revokeSearchShare(): Promise<void> {
        await this._messageBoxService.confirm('Sharing with other users will be revoked. Click OK to confirm.', 'Search');
        const result = await this._smartSearchService.revokeShare(this.search);
        this.search.isShared = result.savedSearchDTO.isShared;
        this._toastr.success('Share was revoked!');
    }

    reloadSearch(): void {
        this._productAnalyticsService.logEvent('click-SMART-refresh');
        this.executeSearch.emit();
    }

    showCriteria(): void {
        this._productAnalyticsService.logEvent('refine-smart-search-criteria');
        this._advancedSearchPersistenceService.showCriteria(true);
    }

    clearCriteria(): void {
        if (!this.showPageTitle) {
            this._advancedSearchPersistenceService.resetSearchCriteria();
        } else {
            this._initSearch(false);
        }
        this._smartSearchService.disableColumns([ ...this.search.searchCriteria.filters, ...this.search.searchCriteria.outputColumns ]);
        this._productAnalyticsService.logEvent('click-reset');
    }

    userChangedSettings(): void {
        this.isSaveEnabled = true;
    }

    async saveSearch(): Promise<void> {
        const search = { ...this.search };
        if (this.isSystemSearch) {
            if (confirm('Confirm Overwriting System Search')) {
                const result = await this._smartSearchService.saveSystemSearch(search);
                this.search.rowVersion = result.rowVersion;
                this._toastr.success('System Search Saved!');

                this._userSaveService.updateList(SearchPageType.Smart);
            }
        } else {
            const result = await this._smartSearchService.saveCustomSearch(search);
            this.search.rowVersion = result.rowVersion;
            this._toastr.success('User Search Saved!');

            this._userSaveService.updateList(SearchPageType.Smart);
        }
        this._productAnalyticsService.logEvent('click-SMART-save-search', {
            smartSavedSearchType: search.isSystemSearch ? 'System' : 'User',
            smartSavedSearchDisplay: search.defaultDisplay === DefaultDisplayTypes.CRITERIA ? 'Filter' : 'Detailed',
            smartSavedSearchCategory: search.categoryName ? search.categoryName : 'No Category'
        });
        this._advancedSearchPersistenceService.loadedSearchCriteriaCache = _.cloneDeep(this.search.searchCriteria);
        this.isSaveEnabled = false;
    }

    async saveSearchAs(): Promise<void> {
        const searchCriteria = _.cloneDeep(this.search.searchCriteria);
        searchCriteria.searchName = this.search.searchName;
        searchCriteria.defaultDisplay = this.search.defaultDisplay;

        const params: SaveSearchModalParams = {
            searchCriteria: searchCriteria,
            searchType: this.search.type,
            hasEditPermission: this.hasEditPermission
        };
        const result = await this._modalService.showAsync(SaveSearchModalComponent, params, 'modal-md');

        if (!result) { return; }

        this.search.rowVersion = result.search.rowVersion;
        this._userSaveService.updateList(SearchPageType.Smart);
        this._advancedSearchPersistenceService.clearSearchData();
        this._navigationService.go('search', { searchId: result.search.searchId, searchType: result.type });
    }

    private async _populateSearch(): Promise<void> {
        // This condition is for when users click the nub to return to a search
        // OR
        // When drilling down from the calendar widget
        if (this._advancedSearchPersistenceService.search) {
            this.showPageTitle = this._advancedSearchPersistenceService.showPageTitle;
            this.isSavedSearch = !!(this._advancedSearchPersistenceService.routeParams && this._advancedSearchPersistenceService.routeParams.searchId);
            this.isSystemSearch = this._advancedSearchPersistenceService.routeParams && parseInt(this._advancedSearchPersistenceService.routeParams.searchType) === AdvancedSearchCategoryTypes.SYSTEM;

            this.search = this._advancedSearchPersistenceService.search;
            this.searchResults = this._advancedSearchPersistenceService.results;

            if (!this._advancedSearchPersistenceService.fromWidget) {
                this._timer.setTimeout(() => {
                    //TODO
                    // this.gridOptions.api.setSortModel(this._advancedSearchPersistenceService.sort);
                });
            }

            this.updateSearch.emit(this.search);
            this.updateSearchResults.emit(this.searchResults);
            this._advancedSearchPersistenceService.showCriteria(false);

            if (this._advancedSearchPersistenceService.fromWidget) {
                this.executeSearch.emit();
            }
        } else if (this.routeParams.searchId && this.routeParams.searchType) {
            this.showPageTitle = false;
            this.isSavedSearch = true;
            this.isSystemSearch = (parseInt(this.routeParams.searchType) === AdvancedSearchCategoryTypes.SYSTEM);

            await this._loadSearch(+this.routeParams.searchId, +this.routeParams.searchType);
            if (this.search.defaultDisplay && !this._advancedSearchPersistenceService.reloadingSearch) {
                this.executeSearch.emit();
            } else {
                this._advancedSearchPersistenceService.reloadingSearch = false;
            }
        } else {
            this._initSearch();
            this.showPageTitle = true;
        }
    }

    private async _loadSearch(searchId, searchType): Promise<void> {
        const result = await this._smartSearchService.getOneSearch(searchId, searchType);
        if (result.searchId) {
            if (result.searchCriteria) {
                this.invalidColumns = this._smartSearchService.getInvalidColumns(_.cloneDeep(result.searchCriteria.outputColumns));
            }
            result.type = searchType;
            this.search = result;
            this._advancedSearchPersistenceService.search = this.search;
            this._advancedSearchPersistenceService.loadedSearchCriteriaCache = _.cloneDeep(this.search.searchCriteria);

            if(this.routeParams.filters) {
                this._populateFilters();
            }

            this.updateSearch.emit(this.search);
        } else {
            this._toastr.error('Error retrieving search');
            this._initSearch();
        }
    }

    private _initSearch(transitionToNewSearch: boolean = true): void {
        if (transitionToNewSearch) {
            this._navigationService.transitionTo('search', { searchId: '', searchType: '' });
        }

        this.search = {
            ...this.search,
            searchCriteria: {
                searchName: null,
                outputColumns: [],
                filters: [],
                excludeInactive: true,
                groupByBatches: false,
                outputFormat: 0,
                showOrs: false,
                frequentlyUsed: false  // WK-7166 is reversing WK-4681
            }
        };

        this.search.searchCriteria = { ...this.search.searchCriteria, ...this._smartSearchService.getSavedSettings() };

        this._advancedSearchPersistenceService.search = this.search;
        this._advancedSearchPersistenceService.setSearchCriteria(this.search.searchCriteria);
        this._smartSearchService.resetCategoryState();
        this.updateSearch.emit(this.search);
    }

    private _populateFilters() {
        this.search.searchCriteria.showOrs = true;
        const filterValues = JSON.parse(this.routeParams.filters);

        _.forEach(filterValues, filterValue => {
            const filterToUpdate = _.find(this.search.searchCriteria.filters, filter => {
                if (filter.columnId === filterValue.id) {
                    if(filterValue.operatorName) {
                        return filterValue.operatorName === filter.or[0].operator.name;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            });

            if(filterToUpdate) {
                filterToUpdate.or = _.map(filterValue.values, x => {
                    return {
                        ...filterToUpdate.or[0],
                        value: x
                    } as SearchOr;
                });
            }
        });
    }
}
