import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class FormSetupRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    export(formRevisionIds: number[]): Observable<number> {
        return this.httpPut('/api/formSetup/export', formRevisionIds);
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/formSetup/${longRunningProcessId}`, options);
    }

    import(file: File, metadata: Compliance.FormSetupUploadModel): Observable<number> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileMetadata', JSON.stringify(metadata));

        return this.httpPut(`/api/formSetup/import`, formData, {
            headers: new HttpHeaders({
                'Content-Type': [undefined]
            })
        });
    }

    getFormRevisions(): Observable<Compliance.FormSetupDownloadFormRevisionModel[]> {
        return this.httpGet('/api/formSetup/formRevisions');
    }
}
