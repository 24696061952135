import { Component, OnInit, OnDestroy } from '@angular/core';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { RestrictService, InstanceRights } from '../../../Common/Permissions/restrict.service';
import { FilingBatchRepository, LongRunningProcessRepository } from '../../Repositories';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { WeissmanDateFormatPipe } from '../../../UI-Lib/Pipes';
import { FilingBatchService } from '../filingBatch.service';
import { FilingBatchListGridActionCellRendererComponent, ICellRendererParamsForFilingBatchListGridAction } from './agGridActionCellRenderer.component';
import { FilingBatchListAgGridDataSource, FilingBatchListDataSourceParams } from './agGridDataSource';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { FilingBatchProgressCellRendererComponent, ICellRendererParamsForFilingBatchProgressCell } from './agGridProgressCellRenderer.component';
import { Subscription, lastValueFrom } from 'rxjs';
import { WebsocketListenerService } from '../../websocketListener.service';
import { AgGridLinkCellRenderer } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridLinkCellRendererParams } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { DecimalPipe } from '@angular/common';
import { FILING_BATCH_LIST_HELP } from './filingBatchList.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectTracker, AgGridMultiSelectCellRendererParams } from '../../AgGrid/MultiSelectTracker';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { ReturnRepository } from '../../Repositories';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';

@Component({
    templateUrl: './filingBatchList.component.html',
    selector: 'filing-batch-list',
    styleUrls: ['./filingBatchList.component.scss']
})
export class FilingBatchListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService,
        private readonly _companyService: CompanyService,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _restrictService: RestrictService,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _helpService: HelpService,
        private readonly _returnRepository: ReturnRepository,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository
    ) { }

    // this component needs to load initialization data before any UI can be rendered
    // it will be considered initialized when all ngOnInit calls have finished executing
    isInitialized: boolean = false;
    breadcrumbs: BreadCrumb[] = [];
    companyId: number;
    companyName: string;
    includeCompleted: boolean = false;
    canEdit: boolean = false;
    gridId: System.Guid = '083E55EF-BB1A-4D93-9782-B725C8FE3C8B';
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => {
                    const gridRow = params.data as Compliance.FilingBatchModel;
                    return gridRow && this.gridTracker.isRowSelected(gridRow.filingBatchId);
                },
                'row-completed': (params) => {
                    const gridRow = params.data as Compliance.FilingBatchModel;
                    return gridRow && gridRow.completedDate !== null;
                }
            }
        })
        .withColumnPinning()
        .buildDefault(this);

    gridTracker: AgGridMultiSelectTracker;

    private _gridApi: GridApi;
    private _gridDataSource: FilingBatchListAgGridDataSource;
    private _filingBatchStatusChangeSub: Subscription;
    private _selectedRowModel: { filingBatchId: number, isLockedOrCompleted: boolean; }[] = [];


    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    get reportExportHelp(): string {
        return (this.rowsSelected) ? 'filing-batch-list.reconciliation-report' : 'filing-batch-list.reconciliation-report-select';
    }

    get rowsSelected(): boolean {
        return this.gridTracker && this.gridTracker.getSelectedRowsCount() > 0;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FILING_BATCH_LIST_HELP);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

            const responses = await Promise.all([
                this._companyService.getInfoForCompliance(this.companyId),
                this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write)
            ]);

            const company = responses[0];

            if (!(company.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, company.instanceID))) {
                this.navigateToUnauthorized();
                return Promise.resolve();
            }

            if (company.companyID !== company.topLevelCompanyId) {
                this.navigateToUnauthorized();
                return Promise.resolve();
            }

            this.companyName = company.companyName;
            this.canEdit = responses[1];

            this.breadcrumbs.push({
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });

            this._filingBatchStatusChangeSub = this._websocketListenerService.longRunningProcessStatusChange$.subscribe(async (x) => {
                if (x.processType !== Compliance.LongRunningProcessTypeEnum.Return) {
                    return;
                }

                if (!this._gridDataSource) {
                    return;
                }

                const rowNode: RowNode = this._gridDataSource.getShownNodes().find(r => {
                    const filingBatch = r.data as Compliance.FilingBatchModel;
                    return filingBatch && filingBatch.filingBatchId === x.entityId;
                });

                if(!rowNode) {
                    return;
                }

                await this._gridDataSource.updateRow(y => {
                    const rowModel = y.data as Compliance.FilingBatchModel;
                    return rowModel && rowModel.filingBatchId === x.entityId;
                    },
                    async (y) => {
                        const filingBatch: Compliance.FilingBatchModel = await lastValueFrom(this._filingBatchRepository.get(x.entityId));
                        filingBatch.dueDate = y.data.dueDate; // todo: grid is sorted by due date by default, date comparison always returns false
                        return filingBatch;
                    });
            });

            this.isInitialized = true;
        }
        finally {
            await busyRef.hide();
        }

        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._filingBatchStatusChangeSub && this._filingBatchStatusChangeSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'filingBatchId',
                width: AgGridColumns.selectionColumnWidth,
                lockVisible: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                resizable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectCellRendererParams
            },
            {
                headerName: 'Batch #',
                field: 'displayId',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Description',
                field: 'description',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnLargeWidth
            },
            {
                headerName: 'Progress',
                field: 'statusCode',
                width: 175,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                sortable: false,
                cellRendererFramework: FilingBatchProgressCellRendererComponent,
                cellRendererParams: {
                    resume: this._onProgressViewDetails.bind(this)
                } as ICellRendererParamsForFilingBatchProgressCell
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Tax Year',
                field: 'taxYear',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell'
            },
            {
                headerName: 'State',
                field: 'stateName',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: '# Filings',
                field: 'filingCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell',
                valueFormatter: (params) => {
                    return this._decimalPipe.transform(params.value, '1.0-0');
                }
            },
            {
                headerName: 'Due',
                field: 'dueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    return this._datePipe.transform(params.value, true);
                }
            },
            {
                headerName: 'Completed Date',
                field: 'completedDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => {
                    return params.value ? this._datePipe.transform(params.value, true) : null;
                }
            },
            {
                headerName: 'Status',
                field: 'status',
                width: AgGridColumns.textColumnWidth,
                sortable: false,
                cellRendererFramework: AgGridLinkCellRenderer,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-task-series',
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        return null;
                    },
                    onClick: this._viewTasks.bind(this)
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Progress Error',
                field: 'longRunningProcessError',
                width: AgGridColumns.textColumnWidth,
                sortable: false,
                hide: true
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(4),
                minWidth: AgGridColumns.getActionColumnWidth(4),
                maxWidth: AgGridColumns.getActionColumnWidth(4),
                lockPinned: true,
                suppressColumnsToolPanel: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                sortable: false,
                cellRendererFramework: FilingBatchListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this._onViewDetails.bind(this),
                    delete: this._delete.bind(this),
                    canDelete: this._canDelete.bind(this),
                    canEdit: this.canEdit
                } as ICellRendererParamsForFilingBatchListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'dueDate',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    addNew(): void {
        this._routerService.go('filingBatchDetails', {
            'companyId': this.companyId,
            'filingBatchId': null
        });
    }

    async exportReconciliationReport(): Promise<void> {
        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();
        const gridModel = this._gridDataSource.getSearchModel();

        const searchModel: Compliance.ReturnReconciliationSearchModel = {
            includeCompleted: this.includeCompleted,
            columnFilters: gridModel.columnFilters,
            pagination: gridModel.pagination,
            range: gridModel.range,
            sortColumns: gridModel.sortColumns,
            selectAllRows: selectedRowsModel.selectAllRows,
            selectedRows: selectedRowsModel.selectedRows
        };

        const busyRef = this._busyIndicatorService.show({ message: 'Exporting' });

        try {
            await lastValueFrom(this._returnRepository.exportReconciliation(this.companyId, searchModel));
        } finally {
            busyRef.hide();
        }
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    refresh(): void {
        this._refreshDataSource();
    }

    onIncludeCompletedChange(): void {
        this.refresh();
    }

    private _canDelete(params: ICellRendererParamsForFilingBatchListGridAction): boolean {
        const filingBatch = params.data as Compliance.FilingBatchModel;
        if (!filingBatch) {
            return false;
        }

        return this.canEdit && !this._filingBatchService.isReadOnly(filingBatch.processStatus);
    }

    private async _delete(params: ICellRendererParamsForFilingBatchListGridAction): Promise<void> {
        const filingBatch = params.data as Compliance.FilingBatchModel;
        if (!filingBatch) {
            return;
        }

        const deleted = await this._deleteFilingBatch(filingBatch);
        if (deleted) {
            this._refreshDataSource();
        }
    }

    private _onProgressViewDetails(params: ICellRendererParamsForFilingBatchProgressCell): void {
        const filingBatch = params.data as Compliance.FilingBatchModel;
        this._navigateToFilingBatchDetails(filingBatch);
    }

    private _onViewDetails(params: ICellRendererParamsForFilingBatchListGridAction) {
        const filingBatch = params.data as Compliance.FilingBatchModel;
        this._navigateToFilingBatchDetails(filingBatch);
    }

    private _navigateToFilingBatchDetails(filingBatch: Compliance.FilingBatchModel): void {
        if (!filingBatch) {
            return;
        }
        this._productAnalyticsService.logEvent('access-filing-batch', {
            returnAccessPath: 'view return filing'
        });
        this._routerService.go('filingBatchDetails',
            {
                'companyId': filingBatch.companyId,
                'filingBatchId': filingBatch.filingBatchId
            });
    }

    private async _viewTasks(params: ICellRendererParamsForFilingBatchListGridAction): Promise<void> {
        const filingBatch = params.data as Compliance.FilingBatchModel;
        if (!filingBatch) {
            return;
        }
        await this._filingBatchService.viewTasks(filingBatch);
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear();
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear();

        const dataSourceParams = (): FilingBatchListDataSourceParams => {
            return {
                includeCompleted: this.includeCompleted,
                companyId: this.companyId
            };
        };

        this._gridDataSource = new FilingBatchListAgGridDataSource(this._gridApi, this._filingBatchRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _deleteFilingBatch(filingBatch: Compliance.FilingBatchModel): Promise<boolean> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the filing batch?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

        try {
            await lastValueFrom(this._filingBatchRepository.delete(filingBatch.filingBatchId, false));
        }
        catch (e2) {
            // service returns a 422 and a message if user confirmation needed to force delete
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            busyRef.hide();

            try {
                await this._messageModalService.confirm(e2.error.message, 'Confirm Delete');
            }
            catch (e3) {
                return Promise.resolve(false);
            }

            busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

            await lastValueFrom(this._filingBatchRepository.delete(filingBatch.filingBatchId, true));
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        const result = await this._gridDataSource.getRowIdsInternal(skip, take);
        this._selectedRowModel = [...result.data];
        return { ...result, data: this._selectedRowModel.map(r => r.filingBatchId) };
    }
}
