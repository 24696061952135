import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ReportDetail, ReturnAssetDetailIncludeAssetDescriptorModel } from '../report.manager.model';
import { ReportManagerModalInputParameters } from '../reportManagerModal.component';
import { ReturnRepository } from '../../../Compliance/Repositories';
import { CompanyAssetDescriptorRepository } from '../../../Compliance/Repositories';
import { takeUntil } from 'rxjs/operators';
import { lastValueFrom, Subject } from 'rxjs';

interface FormConfig {
    label: string;
    formControlName: string;
}

interface LeaseFormConfig extends FormConfig {
    leaseType: Compliance.AssetOwnershipTypeEnum;
}

@Component({
    selector: 'format-output-panel-asset-detail',
    templateUrl: './format.output.panel.asset.detail.component.html',
    styleUrls: ['./format.output.panel.scss']
})
export class FormatOutputPanelAssetDetailComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _returnRepository: ReturnRepository,
        private readonly _companyAssetDescriptorRepository: CompanyAssetDescriptorRepository,
        private readonly _fb: UntypedFormBuilder
    ) {
    }

    @Input() report: ReportDetail;
    @Input() modalParameters?: ReportManagerModalInputParameters;

    @Input()
    set scheduleName(scheduleName: string) {
        this._previousScheduleName = this._scheduleName;
        this._scheduleName = scheduleName;
        this._setTitle();
    };

    @Input()
    set priorReturnStatusName(priorReturnStatusName: string) {
        this._priorReturnStatusName = priorReturnStatusName;
        this._setTitle();
    };

    @Output() reportChange: EventEmitter<ReportDetail> = new EventEmitter();

    selectedAssetGroups: UntypedFormControl = new UntypedFormControl();
    assetGroups: Compliance.NameValuePair<string[]>[] = [
        { name: 'Acquisition Year', value: ['groupByAcqYear'] },
        { name: 'Asset Classification', value: ['groupByAssetClass'] },
        { name: 'Factor Table', value: ['splitOutFactors'] },
        { name: 'Linked/Sub Parcel Acct Num', value: ['groupByLinkedSubParcelAcctNum'] },
        { name: 'Linked/Sub Parcel Assessor', value: ['groupByLinkedSubAssessorAbbr']},
        { name: 'Linked/Sub Parcel Desc', value: ['groupByLinkedSubParcelDescription'] },
        { name: 'Schedule', value: ['groupBySchedule'] },
        { name: 'Schedule + Factor Table', value: ['groupBySchedule', 'splitOutFactors'] },
        { name: 'Site Address', value: ['groupBySiteAddress'] },
        { name: 'Site Name', value: ['groupBySiteName'] },
        { name: 'Site Number', value: ['groupBySiteNumber'] },
        { name: 'Cost Change', value: ['groupByCostChange'] }
    ];

    additionalDescriptors: ReturnAssetDetailIncludeAssetDescriptorModel[] = [];
    assetDescriptors: Core.DescriptorModel[] = [];

    assetOwnershipType: UntypedFormControl = new UntypedFormControl();
    ownershipTypes: {label: string, value: string}[] = [
        { label: 'Owned', value: `${+Compliance.AssetOwnershipTypeEnum.Owned}` },
        { label: 'Leased to Other', value: `${+Compliance.AssetOwnershipTypeEnum.LeasedToOther}` },
        { label: 'Leased from Other', value: `${+Compliance.AssetOwnershipTypeEnum.LeasedFromOther}` }
    ];

    layoutGroup: UntypedFormGroup;
    reportFormatGroup: UntypedFormGroup;
    leaseFromGroup: UntypedFormGroup;
    leaseToGroup: UntypedFormGroup;
    optionalGroup: UntypedFormGroup = this._fb.group({
        includeAssetClass: [false],
        includeAcqYear: [false],
        includeDisposedDate: [false],
        includeGLAccount: [false],
        includeScheduleName: [false],
        includeFactorTableName: [false],
        includeFactorAndDepreciatedValue: [false],
        //includeRatioAndAssessedValue: [false], // WR-4487
        includeAdditionalDepreciation: [false],
        includeEstimatedFMV: [false],
        includeCost: [false],
        includeCostChange: [false],
        includeAssetDescriptors: [[]]
    });

    reportFormatting: FormConfig[] = [
        {
            label: 'Show Taxpayer Name',
            formControlName: 'showTaxpayerName'
        },
        {
            label: 'Show Transfer Details',
            formControlName: 'showTransferDetails'
        },
        {
            label: 'Show Change Details',
            formControlName: 'showChangeDetails'
        },
        {
            label: 'Show Grand Total',
            formControlName: 'showGrandTotal'
        }
    ];
    optionalCols: FormConfig[] = [
        {
            label: 'Cost',
            formControlName: 'includeCost'
        },
        {
            label: 'Cost Change',
            formControlName: 'includeCostChange'
        },
        {
            label: 'Asset Class',
            formControlName: 'includeAssetClass'
        },
        {
            label: 'Acq. Year',
            formControlName: 'includeAcqYear'
        },
        {
            label: 'Disposed Date',
            formControlName: 'includeDisposedDate'
        },
        {
            label: 'G/L Account',
            formControlName: 'includeGLAccount'
        },
        {
            label: 'Schedule Name',
            formControlName: 'includeScheduleName'
        },
        {
            label: 'Factor Table Name',
            formControlName: 'includeFactorTableName'
        },
        {
            label: 'Factor and Depreciated Value',
            formControlName: 'includeFactorAndDepreciatedValue'
        },
        {
            label: 'Additional Depreciation',
            formControlName: 'includeAdditionalDepreciation'
        },
        {
            label: 'Estimated FMV',
            formControlName: 'includeEstimatedFMV'
        }
    ];
    leaseToCols: FormConfig[] = [
        {
            label: 'Lease Customer ID',
            formControlName: 'includeLeaseClientId'
        },
        {
            label: 'Location Name',
            formControlName: 'includeLeaseLocationName'
        },
        {
            label: 'Location Address',
            formControlName: 'includeLeaseLocationAddress'
        }
    ];
    leaseFromCols: FormConfig[] = [
        {
            label: 'Lease Start Date',
            formControlName: 'includeLeaseStartDate'
        },
        {
            label: 'Lease End Date',
            formControlName: 'includeLeaseEndDate'
        },
        {
            label: 'Lease Term In Months',
            formControlName: 'includeLeaseTerm'
        },
        {
            label: 'Lease Monthly Payment',
            formControlName: 'includeLeaseMonthlyPayments'
        },
        {
            label: 'Lease Type',
            formControlName: 'includeLeaseType'
        },
        {
            label: 'Lease Asset ID',
            formControlName: 'includeLeaseAssestId'
        },
        {
            label: 'Lease Number',
            formControlName: 'includeLeaseNumber'
        },
        {
            label: 'Billing Info',
            formControlName: 'includeLeaseBillingInfo'
        },
        {
            label: 'Linked/Sub Parcel Acct Number',
            formControlName: 'includeLinkedSubParcelAcctNumber'
        },
        {
            label: 'Linked/Sub Parcel Description',
            formControlName: 'includeLinkedSubParcelDescription'
        },
        {
            label: 'Linked/Sub Assessor Abbr',
            formControlName: 'includeLinkedSubAssessorAbbr'
        }
    ];

    get hasMoreAvailableDescriptors(): boolean {
        return this.additionalDescriptors.length > 0;
    }

    private _previousScheduleName?: string;
    private _scheduleName?: string;
    private _priorReturnStatusName?: string;
    private destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        // Setup form groups
        this.layoutGroup = this._createLayoutGroup();
        this.reportFormatGroup = this._createFormatGroup();
        this.leaseFromGroup = this._createLeaseFormGroup();
        this.leaseToGroup = this._createLeaseFormGroup();

        this.leaseToCols = [...this.leaseToCols, ...this.leaseFromCols];

        this.assetOwnershipType.setValue((!this.report.criteria.assetType) ? '0' : this.report.criteria.assetType);

        await this._setAssetDescriptors();
        this._initializeAdditionalColumns();

        if (this.assetOwnershipType.value === `${+Compliance.AssetOwnershipTypeEnum.LeasedToOther}`) {
            this.leaseToGroup.patchValue(this._getLeaseValues());
        } else if (this.assetOwnershipType.value === `${+Compliance.AssetOwnershipTypeEnum.LeasedFromOther}`) {
            this.leaseFromGroup.patchValue(this._getLeaseValues());
        }
        this.optionalGroup.patchValue(this._getOptionalValues());

        this._initializeSelectedGroups();
        this.selectedAssetGroups.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._applyGroupingChanges());

        // Listen to group changes
        this.assetOwnershipType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
        this.leaseFromGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
        this.leaseToGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
        this.optionalGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
        this.layoutGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
        this.reportFormatGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._outputReport());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _getOptionalValues() {
        const { criteria } = this.report;
        return {
            includeAssetClass: !!criteria.includeAssetClass,
            includeAcqYear: !!criteria.includeAcqYear,
            includeDisposedDate: !!criteria.includeDisposedDate,
            includeGLAccount: !!criteria.includeGLAccount,
            includeScheduleName: !!criteria.includeScheduleName,
            includeFactorTableName: !!criteria.includeFactorTableName,
            includeFactorAndDepreciatedValue: !!criteria.includeFactorAndDepreciatedValue,
            //includeRatioAndAssessedValue: !!criteria.includeRatioAndAssessedValue, //WR-4487
            includeAdditionalDepreciation: !!criteria.includeAdditionalDepreciation,
            includeEstimatedFMV: !!criteria.includeEstimatedFMV,
            includeCost: !!criteria.includeCost,
            includeCostChange: !!criteria.includeCostChange
        };
    }

    private _getLeaseValues() {
        const { criteria } = this.report;
        return {
            includeLeaseClientId: !!criteria.includeLeaseClientId,
            includeLeaseLocationName: !!criteria.includeLeaseLocationName,
            includeLeaseLocationAddress: !!criteria.includeLeaseLocationAddress,
            includeLeaseStartDate: !!criteria.includeLeaseStartDate,
            includeLeaseEndDate: !!criteria.includeLeaseEndDate,
            includeLeaseTerm: !!criteria.includeLeaseTerm,
            includeLeaseMonthlyPayments: !!criteria.includeLeaseMonthlyPayments,
            includeLeaseType: !!criteria.includeLeaseType,
            includeLeaseAssestId: !!criteria.includeLeaseAssestId,
            includeLeaseNumber: !!criteria.includeLeaseNumber,
            includeLeaseBillingInfo: !!criteria.includeLeaseBillingInfo,
            includeLinkedSubParcelAcctNumber: !!criteria.includeLinkedSubParcelAcctNumber,
            includeLinkedSubParcelDescription: !!criteria.includeLinkedSubParcelDescription,
            includeLinkedSubAssessorAbbr: !!criteria.includeLinkedSubAssessorAbbr
        };
    }

    private _setTitle(){
        const previousExpectedName = FormatOutputPanelAssetDetailComponent._getTitle(this._previousScheduleName, this._priorReturnStatusName);
        const newExpectedName = FormatOutputPanelAssetDetailComponent._getTitle(this._scheduleName, this.priorReturnStatusName);

        if (!this.report.criteria.title || this.report.criteria.title === previousExpectedName){
            this.report.criteria.title = newExpectedName;
        }
    }

    private static _getTitle(scheduleName?: string, priorReturnStatusName?: string): string{
        let result: string;

        if (!scheduleName || scheduleName === 'All Reportable'){
            result = 'Detailed Asset List';
        } else if (scheduleName === 'All Non-Reportable') {
            result = 'Detailed Asset List (Non-Reportable)';
        } else{
            result = scheduleName;
        }

        if (priorReturnStatusName && priorReturnStatusName !== 'All assets at lien date'){
            result += ` - ${priorReturnStatusName}`;
        }
        return result;
    }

    private async _setAssetDescriptors(): Promise<void> {
        this.report.criteria.includeAssetDescriptors = this.report.criteria.includeAssetDescriptors || [];

        if (this.modalParameters && this.modalParameters.filingBatchId) {
            this.assetDescriptors = await lastValueFrom(this._returnRepository.getAssetCharacteristics(this.modalParameters.filingBatchId));
        } else {
            this.assetDescriptors = await lastValueFrom(this._companyAssetDescriptorRepository.getAssetCharacteristics());
        }

        this.additionalDescriptors = this.assetDescriptors.map(a => {
            return {
                descriptorId: a.descriptorID,
                name: a.name,
                include: true
            }
        });
    }

    private _initializeAdditionalColumns(): void {
        const includeAssetDescriptors = this.report.criteria.includeAssetDescriptors.map(item => {
            const assetDescriptor = this.assetDescriptors.find(i => i.descriptorID === item.descriptorId);
            const model: ReturnAssetDetailIncludeAssetDescriptorModel = {
                descriptorId: item.descriptorId,
                name: assetDescriptor ? assetDescriptor.name : 'Unknown Descriptor',
                include: item.include
            };
            return model;
        }).sort((a, b) => a.name.localeCompare(b.name));

        this.optionalGroup.patchValue({ includeAssetDescriptors });
    }

    private _initializeSelectedGroups(): void {
        if (!this.report.criteria.groupBy) { return; }
        let skipNext = false;
        // convert to values that match select list value format
        const groupBy = this.report.criteria.groupBy.reduce((acc, g, i, src) => {
            if (skipNext) {
                skipNext = false;
                return acc;
            }
            if (g === 'groupBySchedule' && src[i + 1] === 'splitOutFactors') {
                // handle the only current option that sets two values
                acc.push([g, src[i + 1]]);
                skipNext = true;
            } else {
                acc.push([g]);
            }
            return acc;
        }, []);
        // get matching values to assign
        const groups = groupBy.reduce((acc, group) => {
            const assetGroup = this.assetGroups.find(g => g.value.toString() === group.toString());
            if (assetGroup) {
                acc.push(assetGroup.value);
            }
            return acc;
        }, []);
        this.selectedAssetGroups.setValue(groups);
        this._applyGroupingChanges();
    }

    private _applyGroupingChanges(): void {
        const selectedGroups = this.selectedAssetGroups.value as string[][];
        const unique = selectedGroups.reduce((acc, val) => {
            val.forEach(v => acc.add(v));
            return acc;
        }, new Set());
        this.reportFormatGroup.patchValue({groupBy: Array.from(unique)});
        this._outputReport();
    }

    private _createLeaseFormGroup(): UntypedFormGroup {
        return this._fb.group({
            includeLeaseClientId: [false],
            includeLeaseLocationName: [false],
            includeLeaseLocationAddress: [false],
            includeLeaseStartDate: [false],
            includeLeaseEndDate: [false],
            includeLeaseTerm: [false],
            includeLeaseMonthlyPayments: [false],
            includeLeaseType: [false],
            includeLeaseAssestId: [false],
            includeLeaseNumber: [false],
            includeLeaseBillingInfo: [false],
            includeLinkedSubParcelAcctNumber: [false],
            includeLinkedSubParcelDescription: [false],
            includeLinkedSubAssessorAbbr: [false]
        });
    }

    private _createLayoutGroup(): UntypedFormGroup {
        const { criteria } = this.report;
        const group = this._fb.group({
            outputFormat: [criteria.outputFormat],
            legalSize: [!!criteria.legalSize],
            showLandscape: [!!criteria.showLandscape],
            watermark: [criteria.watermark]
        });

        group.get('outputFormat').valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe(() => group.get('watermark').setValue(''));

        return group;
    }

    private _createFormatGroup(): UntypedFormGroup {
        const { criteria } = this.report;
        return this._fb.group({
            showTaxpayerName: [!!criteria.showTaxpayerName],
            showTransferDetails: [!!criteria.showTransferDetails],
            showChangeDetails: [!!criteria.showChangeDetails],
            showGrandTotal: [!!criteria.showGrandTotal],
            groupBy:[]
        });
    }

    /**
     * Combine all the form values into the report output
     */
    private _outputReport(): void {
        let leaseOutput = {};
        if (this.assetOwnershipType.value === `${+Compliance.AssetOwnershipTypeEnum.LeasedToOther}`) {
            leaseOutput = this.leaseToGroup.value;
        } else if (this.assetOwnershipType.value === `${+Compliance.AssetOwnershipTypeEnum.LeasedFromOther}`) {
            leaseOutput = this.leaseFromGroup.value;
        }
        const newCriteria = {
            ...this.report.criteria,
            ...this.layoutGroup.value,
            ...this.reportFormatGroup.value,
            ...this.optionalGroup.value,
            ...leaseOutput,
            assetType: this.assetOwnershipType.value
        };
        newCriteria.includeAssetDescriptors = this.optionalGroup.value.includeAssetDescriptors.filter(d => d.include);

        const combined: ReportDetail = { ...this.report, ...{ criteria: newCriteria } };
        this.reportChange.emit(combined);
    }
}
