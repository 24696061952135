import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ManageAPIKeysService } from './manage-api-keys.service';
import { IntegrationHmac } from './manage-api-keys.models';
import { TypeaheadCompany } from '../../Entity/Company/Company.Picker.Component';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';

@Component({
    selector: 'manage-api-keys',
    templateUrl: './manage-api-keys.component.html',
    styleUrls: ['./manage-api-keys.component.scss']
})
export class ManageAPIKeysComponent implements OnInit {
    loading: boolean;
    apiKeys: IntegrationHmac[];
    addingKey: boolean;
    // If we end up with more fields on the create form, consider making a class for the new properties instead of having them
    // as invididual variables
    newCompanyId: number;
    newKeyName: string;
    newCompanyValidationMessage: string;
    newKeyNameValidationMessage: string;

    constructor(private toastr: ToastrService,
        private manageAPIKeysService: ManageAPIKeysService,
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler) { }

    ngOnInit() {
        this.addingKey = false;
        this.loadKeys();
    }

    async loadKeys(): Promise<void> {
        this.loading = true;

        try {
            this.apiKeys = await this.manageAPIKeysService.getPublicKeys();

            console.log(this.apiKeys);
        } finally {
            this.loading = false;
        }
    }

    goToDetail(integrationHmacId: number) {
        this.upgradeNavigationServiceHandler.go('apiKeyDetail', {
            integrationHmacId: integrationHmacId
        });
    }

    addNewKey(): void {
        this.addingKey = true;
        this.newKeyName = null;
        this.newCompanyId = null;
        this.newCompanyValidationMessage = null;
        this.newKeyNameValidationMessage = null;
    }

    async saveKey(): Promise<void> {
        let valid = true;
        this.newCompanyValidationMessage = null;
        this.newKeyNameValidationMessage = null;
        if (!this.newCompanyId) {
            valid = false;
            this.newCompanyValidationMessage = 'Company is required';
        }
        if (!this.newKeyName) {
            valid = false;
            this.newKeyNameValidationMessage = 'Name is required';
        }
        else if (this.newKeyName.length > 50) {
            valid = false;
            this.newKeyNameValidationMessage = 'Name cannot be longer than 50 characters';
        }

        if (valid) {
            this.loading = true;

            try {
                await this.manageAPIKeysService.createNewKey(this.newCompanyId, this.newKeyName);
                this.toastr.success('New API Key Created');
                this.addingKey = false;
                await this.loadKeys();
            } finally {
                this.loading = false;
            }
        }
    }

    companySelected(company: TypeaheadCompany): void {
        this.newCompanyId = company.companyID;
    }

    cancelAddKey(): void {
        this.addingKey = false;
    }

    identify(apiKey) {
        return apiKey.integrationHmacId;
    }
}
