<ng-template #listRow let-node="data" let-classRef="classRef">
    <tr class="return-row">
        <td>
            <div class="checkbox ws-no-margin ws-flex-container-horizontal ws-flex-align-items-center" [ngClass]="('node-level-' + (node.level-1))">
                <div class="ws-expand-button">
                    <!-- if there is return information available then that is the "deepest" node and cannot expand/collapse any further -->
                    <button class="ws-flex-none"
                            *ngIf="!node.returnInfo"
                            (click)="classRef.toggleNodeIsExpanded(node)"
                            title="Toggle expand/collapse">
                        <i class="fa" [ngClass]="node.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                    </button>
                </div>
                <label class="ws-flex-auto ws-truncate">
                    <ws-tri-state-checkbox [(ngModel)]="node.isChecked" (ngModelChange)="classRef.nodeCheckChanged(node)"></ws-tri-state-checkbox>
                    <span class="node-label" [title]="node.name">{{ node.name }}</span>
                </label>
            </div>
        </td>
        <td class="text-muted" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ node?.totalsInfo?.returnCount | number }}</span></td>
        <td class="text-muted" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ node?.totalsInfo?.assetCount | number }}</span></td>
        <td class="text-muted ws-text-align-right" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ node?.totalsInfo?.cost | currency: 'USD' : 'symbol' }}</span></td>
    </tr>
</ng-template>

<ws-busy-indicator *ngIf="busyCounter > 0 && !isPageLoading"></ws-busy-indicator>
<div class="small ws-flex-auto ws-flex-container-vertical" *ngIf="isInitialized">
    <div class="ws-flex-none">
        <div class="ws-expand-button float-end">
            <button *ngIf="isExpanded"
                    type="button"
                    title="Hide details"
                    class="ws-flex-none"
                    (click)="isExpanded = !isExpanded">
                <i class="fa fa-arrow-left"></i>
            </button>
        </div>
        <div>
            <div class="ws-flex-container-horizontal">
                <div class="ws-flex-none filing-batch-info-label">
                    <strong>Batch #:</strong>
                </div>
                <div class="ws-flex-auto">{{ filingBatch.displayId }}</div>
            </div>
            <div class="ws-flex-container-horizontal">
                <div class="ws-flex-none filing-batch-info-label">
                    <strong>State:</strong>
                </div>
                <div class="ws-flex-auto">{{ filingBatch.stateName }}</div>
            </div>
            <div class="ws-flex-container-horizontal">
                <div class="ws-flex-none filing-batch-info-label">
                    <strong>Tax Year:</strong>
                </div>
                <div
                    class="ws-flex-auto"
                    helpTooltip
                    [contentComponent]="taxYearAdditionalInfoComponent"
                    position="right"
                    [hasIcon]="true">{{ filingBatch.taxYear }}</div>
            </div>
            <div class="ws-flex-container-horizontal">
                <div class="ws-flex-none filing-batch-info-label">
                    <strong>Due Date:</strong>
                </div>
                <div class="ws-flex-auto">{{ filingBatch.dueDate | weissmanDateFormat: true }}</div>
            </div>
        </div>
    </div>

    <div class="small ws-flex-auto ws-flex-container-vertical batch-parcel-list-container">
        <p class="text-muted" style="margin-top:8px">Showing {{ selectedReturnsCount | number }} of {{ returns.length | number }} return(s)</p>
        <div class="select-all-container">
            <a class="select-all" (click)="selectAllReturns(true)">Select all</a>
            <a class="select-all" (click)="selectAllReturns(false)">Deselect all</a>
            <a class="select-all" (click)="resetReturnFilter()" *ngIf="isFiltered">Clear filter</a>
            <a *ngIf="!isExpanded" class="float-end clickable" (click)="isExpanded = !isExpanded">More details...</a>
        </div>

        <!-- The virtual scroll list is not able to control the panel width properly so this hidden table with the single widest element will control the width-->
        <div class="hidden-table">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th></th>
                        <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>Returns</span></th>
                        <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>Assets</span></th>
                        <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'" class="ws-text-align-right"><span>Cost</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="return-row">
                        <td>
                            <div class="checkbox ws-no-margin ws-flex-container-horizontal ws-flex-align-items-center" [style.padding-left.px]="(widestListItem ? widestListItem.level-1 : 0) * 20">
                                <span class="hidden-node-label node-label">{{ widestListItem?.name }}</span>
                            </div>
                        </td>
                        <td class="text-muted" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ widestListItem?.totalsInfo?.returnCount | number }}</span></td>
                        <td class="text-muted" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ widestListItem?.totalsInfo?.assetCount | number }}</span></td>
                        <td class="text-muted ws-text-align-right" [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>{{ widestListItem?.totalsInfo?.cost | currency: 'USD' : 'symbol' }}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Virtual scroll list -->
        <div class="ws-position-relative ws-flex-grow">
            <cdk-virtual-scroll-viewport itemSize="31"
                                         class="ws-flex-auto parcel-groups">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th></th>
                            <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>Returns</span></th>
                            <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'"><span>Assets</span></th>
                            <th [ngClass]="isExpanded ? 'col-expand' : 'col-hide'" class="ws-text-align-right"><span>Cost</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *cdkVirtualFor="let node of flatTree; templateCacheSize: 0; trackBy: trackDataNodes">
                            <ng-container [ngTemplateOutlet]="listRow"
                                          [ngTemplateOutletContext]="{ data: node, classRef: this }">
                            </ng-container>
                        </ng-container>
                    </tbody>

                </table>
            </cdk-virtual-scroll-viewport>
            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none"></div>
        </div>

        <div class="ws-flex-none">
            <div class="mb-3">
                <label>Filter:</label>
                <div class="input-group">
                    <input class="form-control" type="text" [(ngModel)]="returnsFilter" (keyup.enter)="filterReturns()">
                    <button class="btn btn-outline-secondary btn-sm" title="Search" type="button" (click)="filterReturns()">
                        <i aria-hidden="true" class="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label>Group by:</label><br>
                <ws-select [formControl]="group1Option"
                           [options]="selectOptionsForGroup1"
                           labelProperty="name"
                           valueProperty="value"
                           (selectedOptionChanged)="selectOptionChanged()">
                </ws-select>
            </div>
            <div class="mb-3 ws-no-margin">
                <label>Then group by:</label><br>
                <ws-select [formControl]="group2Option"
                           [options]="selectOptionsForGroup2"
                           labelProperty="name"
                           valueProperty="value"
                           (selectedOptionChanged)="selectOptionChanged()">
                </ws-select>
            </div>
            <div class="auto-filter-check">
                <div class="checkbox ws-no-margin">
                    <label>
                        <input type="checkbox" (ngModelChange)="onAutoRefreshChanged($event)" [(ngModel)]="automaticRefresh" title="Automatically filter batch for the selected accounts">Auto Filter
                    </label>
                </div>
            </div>
            <div class="apply-filter-div">
                <button type="button" *ngIf="!automaticRefresh" class="flat-button small-button float-end" (click)="refreshReturns()" [attr.disabled]="returnsSelectionApplied ? '' : null">Apply</button>
            </div>
        </div>
    </div>
</div>
