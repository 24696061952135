<ws-modal-wrapper headerLabel="Form Template Validation"
                  [suppressKeyboardEvents]="true"
                  (cancel)="cancel()"
                  [cancelLabel]="'Close'"
                  [hideSave]="true"
                  [cancelClass]="'primary-button'">
    <h4 *ngIf="params.message">{{ params.message }}</h4>
    <div class="d-flex align-items-center justify-content-between">
        <ag-grid-row-count [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
        <button title="Export"
                type="button"
                class="btn flat-button icon-button primary-button"
                (click)="export()"
                helpTooltip
                helpContentId="app.export"
                position="bottom">
            <i class="fa fa-file-excel-o fa-align"></i>
        </button>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
