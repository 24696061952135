import { Component, OnInit } from '@angular/core';
import { Payment } from 'src/app/Annual-Details/Taxes/payment.model';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { AttachmentService } from '../attachment.service';
import { EntityType } from 'src/app/constants.new';
import { find, map } from 'lodash';
import { Attachment } from '../attachment.model';
import { DocumentServiceUpgrade } from 'src/app/Documents/document.service.upgrade';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export interface BillImageModalParams {
    billId: number;
    payment: Payment;
}

export interface BillImageModalResult {
    saved: boolean;
    attachmentId: string;
}

@Component({
    selector: 'bill-image-modal',
    template: `
        <ws-busy-indicator *ngIf="serverAction"></ws-busy-indicator>
        <ws-modal-wrapper headerLabel="Bill Image" (save)="save()" (cancel)="cancel()">
            <div style="height: 500px; text-align: center;" *ngIf="!file">
                <h3 style="padding-top: 200px;" *ngIf="!fileError"><em>None Selected</em></h3>
                <h3 class="text-danger" style="padding-top: 200px;" *ngIf="fileError"><em>Error Loading Attachment</em></h3>
            </div>
            <div style="display: flex;">
                <file-viewer [file]="file"
                    *ngIf="!fileError && file"
                    style="width:100%; height: 500px;">
                </file-viewer>
            </div>
            <select class="form-select form-select-sm" style="margin-top: 25px;" [(ngModel)]="selectedAttachmentId" (change)="loadAttachment()">
                <option *ngFor="let attachment of attachments" [ngValue]="attachment.attachmentID">{{attachment.description}}</option>
            </select>
        </ws-modal-wrapper>
    `
})

export class BillImageModalComponent implements OnInit, IWeissmanModalComponent<BillImageModalParams, BillImageModalResult> {
    constructor(private readonly _attachmentService: AttachmentService,
        private _modalRef: BsModalRef,
        private _http: HttpClient,
        private readonly _documentService: DocumentServiceUpgrade) { }

    params: BillImageModalParams;
    result: BillImageModalResult = {
        saved: false,
        attachmentId: ''
    };

    selectedAttachmentId: string;
    file: File;
    attachments: Attachment[];
    fileError: boolean;
    serverAction: boolean = false;

    async ngOnInit() {
        this.selectedAttachmentId = this.params.payment.attachmentID ? this.params.payment.attachmentID.toString() : '';

        this.serverAction = true;

        try {
            let attachments = await this._attachmentService.getAll(EntityType.TaxBill, this.params.billId, false);

            attachments = map(attachments, attachment => {
                attachment.attachmentID = attachment.attachmentID.toString();
                attachment.description = attachment.description || 'No Description';
                return attachment;
            });

            attachments.unshift({
                attachmentID: '',
                description: ''
            } as Attachment);

            this.attachments = attachments;
            await this.loadAttachment();
        } finally {
            this.serverAction = false;
        }
     }

    async loadAttachment() {
        const selectedAttachment = find(this.attachments, {attachmentID: this.selectedAttachmentId});

        if(!selectedAttachment?.attachmentID) {
            this.file = undefined;
            return;
        }

        const { previewSupported, type } = this._attachmentService.getMimeTypeByFileExtension(selectedAttachment?.fileExtension);

        try {
            const response = await this._documentService.getDocumentBody(selectedAttachment.attachmentID);
            const fileName = this._attachmentService.getFileNameFromHttpResponse(response, true);
            const fileNameType = fileName.split('.')[1].substring(0, 3);
            const blob = new Blob([response.data], { type: fileNameType });

            if (previewSupported) {
                this.file = new File([blob], fileName, { type });
            } else {
                await this._documentService.saveFile(blob, fileName);
            }
        } catch {
            this.fileError = true;
        }

    }

    async save() {
        this.serverAction = true;

        try {
            const request = this._http.patch('api/payment', {
                paymentID: this.params.payment.paymentID,
                attachmentID: this.selectedAttachmentId || null
            });

            await lastValueFrom(request);
            this.result = {
                saved: true,
                attachmentId: this.selectedAttachmentId
            };
            this._modalRef.hide();
        } finally {
            this.serverAction = false;
        }
    }

    cancel() {
        this._modalRef.hide();
    }
}
