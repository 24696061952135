import { Component, ViewChild, ElementRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription,  BehaviorSubject } from 'rxjs';
import { FormRevisionScheduleRowModel } from './formScheduleList.component';

export interface FormScheduleListGridFactorTableDropdownCellRendererComponentParams extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit: (params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams) => boolean;
    onValueChanged: (params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams, value: Compliance.FormFactorTableModel) => void;
    factorTables$: BehaviorSubject<Compliance.FormFactorTableModel[]>;
}

@Component({
    selector: 'form-schedule-list-grid-factor-table-dropdown',
    template: `
    <div #outerDiv class="edit-on-hover-cell" [ngClass]="isHovering ? 'mouseover' : ''" (mouseover)="mouseover($event)" (mouseout)="mouseout($event)">
        <div class="read-only-label">
            <span *ngIf="schedule && schedule.defaultFactorTable" [title]="schedule.defaultFactorTable.factorTableName + ' (' + schedule.defaultFactorTable.life + ' yr life)'">
                {{ schedule.defaultFactorTable.factorTableName }} ({{ schedule.defaultFactorTable.life }} yr life)
            </span>
        </div>
        <div class="edit-control" *ngIf="editMode && params.canEdit(params)">
            <select [ngModel]="params.data[params.colDef.field]" (ngModelChange)="onModelChange($event)">
                <option [ngValue]="null"></option>
                <option *ngFor="let ft of factorTables" [ngValue]="ft">{{ ft.factorTableName }} ({{ ft.life }} yr life)</option>
            </select>
        </div>
    </div>`,
    styleUrls: ['./../agGridCellStyles.scss']
})
export class FormScheduleListGridFactorTableDropdownCellRendererComponent implements ICellRendererAngularComp {
    private _editModeSub: Subscription;
    private _factorTablesSub: Subscription;

    params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams;
    schedule: FormRevisionScheduleRowModel;
    editMode: boolean = false;
    isHovering: boolean = false;
    factorTables: Compliance.FormFactorTableModel[];

    @ViewChild('outerDiv', { read: ElementRef, static: true }) outerDiv: ElementRef;

    agInit(params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams): void {
        this.params = params;
        this.schedule = params.data as FormRevisionScheduleRowModel;

        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
        this._factorTablesSub = this.params.factorTables$.subscribe(x => {
            this.factorTables = x;
        });
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
        this._factorTablesSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChange(value: Compliance.FormFactorTableModel) {
        this.params.onValueChanged(this.params, value);
    }

    mouseover($event) {
        this.isHovering = this.editMode && this.params.canEdit(this.params);
    }

    mouseout($event) {
        if (this.isHovering && $event.relatedTarget) {
            // protect against clicks on inner children
            let parent = $event.relatedTarget.parentElement;
            while (parent) {
                if (parent === this.outerDiv.nativeElement) {
                    return;
                }
                parent = parent.parentElement;
            }
            this.isHovering = false;
        }
    }
}
