<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Upload Forms Setup file"
                  acceptLabel="Upload"
                  (save)="upload()"
                  (cancel)="cancel()"
                  [disableSave]="!file">
    <form name="importUploadForm">
        <div class="mb-4">
            <label>Select a file (must be .zip file):</label>
            <ws-file-picker (picked)="filePicked($event)"></ws-file-picker>
        </div>
        <div class="mb-4">
            <input type="checkbox" id="replaceTargetFormRevision" name="replaceTargetFormRevision" [(ngModel)]="replaceTargetFormRevision">
            <label for="replaceTargetFormRevision">Replace target form revision?</label>
        </div>
    </form>
</ws-modal-wrapper>
