import { IFilterOptionDef } from 'ag-grid-community';

export class AgGridFilterParams {
    static blankFilterOptionDef: IFilterOptionDef = {
        displayKey: 'blank',
        displayName: 'Blank',
        hideFilterInput: true,
        test: (filterValue: any, cellValue: any) => {
            return !cellValue;
        }
    };

    static notBlankFilterOptionDef: IFilterOptionDef = {
        displayKey: 'notBlank',
        displayName: 'Not Blank',
        hideFilterInput: true,
        test: (filterValue: any, cellValue: any) => {
            return cellValue;
        }
    };

    static commaSeparatedFilterOptionDef: IFilterOptionDef = {
        displayKey: 'Comma Separated',
        displayName: 'Comma Separated',
        hideFilterInput: false,
        test: (filterValue: any, cellValue: any) => {
            const cellValues = cellValue.trim().split(',');
            return filterValue.split(',').some(x => cellValues.includes(x.trim()));
        }
    };

    static yesOptionDef: IFilterOptionDef = {
        displayKey: 'yes',
        displayName: 'Yes',
        hideFilterInput: true,
        test: (filterValue: any, cellValue: any) => {
            return cellValue && (cellValue.toLowerCase() === 'yes'|| cellValue.toLowerCase() === 'true');
        }
    };

    static noOptionDef: IFilterOptionDef = {
        displayKey: 'no',
        displayName: 'No',
        hideFilterInput: true,
        test: (filterValue: any, cellValue: any) => {
            return cellValue && (cellValue.toLowerCase() === 'no'|| cellValue.toLowerCase() === 'false');
        }
    };

    static noFilterOptionDef: IFilterOptionDef = {
        displayKey: ' ',
        displayName: ' ',
        hideFilterInput: true,
        test: (filterValue: any, cellValue: any) => {
            return true;
        }
    };

    static textFilterParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith'],
        defaultOption: 'contains',
        newRowsAction: 'keep'
    };

    static get textFilterParamsSmartSearch(): any {
        const params = { ...AgGridFilterParams.textFilterParams };
        params.filterOptions = [ ...params.filterOptions, 'smartSearch' ];
        params.defaultOption = 'smartSearch';
        return params;
    }

    static textFilterParamsStartsWith: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith'],
        defaultOption: 'startsWith',
        newRowsAction: 'keep'
    };

    static textFilterWithCommaParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith',
            AgGridFilterParams.commaSeparatedFilterOptionDef],
        defaultOption: 'contains',
        newRowsAction: 'keep'
    };

    static textFilterWithBlankOptionsParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith',
            AgGridFilterParams.blankFilterOptionDef,
            AgGridFilterParams.notBlankFilterOptionDef],
        defaultOption: 'contains',
        newRowsAction: 'keep'
    };

    static textFilterWithBlankOptionsAndCommaParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith',
            AgGridFilterParams.blankFilterOptionDef,
            AgGridFilterParams.notBlankFilterOptionDef,
            AgGridFilterParams.commaSeparatedFilterOptionDef],
        defaultOption: 'contains',
        newRowsAction: 'keep'
    };

    static textFloatingFilterParams: any = {
    };

    static dateFilterParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
        browserDatePicker: false,
        comparator(filterLocalDateAtMidnight, cellValue) {
            const dateAsString = cellValue;
            const dateParts = dateAsString.split('/');
            const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return -1;
        },
        newRowsAction: 'keep'
    };

    static dateFilterParamsWithBlankOptionsParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        browserDatePicker: false,
        comparator(filterLocalDateAtMidnight, cellValue) {
            const dateAsString = cellValue;
            const dateParts = dateAsString.split('/');
            const cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return -1;
        },
        newRowsAction: 'keep'
    };

    static dateFloatingFilterParams: any = {
    };

    static numberFilterParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],
        newRowsAction: 'keep'
    };

    static numberFilterParamsWithEqualTo: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo'],
        newRowsAction: 'keep'
    };

    static numberFilterWithBlankOptionsParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        newRowsAction: 'keep'
    };

    static numberFilterWithEqualToAndBlankOptionsParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        newRowsAction: 'keep'
    };

    static numberWithRangeFilterParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan', 'inRange'],
        newRowsAction: 'keep'
    };

    static numberWithRangeFilterAndEqualToParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo', 'inRange'],
        newRowsAction: 'keep'
    };

    static numberWithRangeAndBlankOptionsFilterParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan', 'inRange', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        newRowsAction: 'keep'
    };

    static numberWithRangeAndEqualToAndBlankFilterParams: any = {
        clearButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', 'greaterThan', 'greaterThanOrEqualTo', 'lessThan', 'lessThanOrEqualTo', 'inRange', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        newRowsAction: 'keep'
    };

    static numberFloatingFilterParams: any = {
    };

    static booleanFilterParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual'],
        defaultOption: 'equals',
        newRowsAction: 'keep'
    };

    static booleanFilterWithBlankOptionsParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: ['equals', 'notEqual', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        defaultOption: 'equals',
        newRowsAction: 'keep'
    };

    static yesNoFilterWithBlankOptionsParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: [AgGridFilterParams.noFilterOptionDef, AgGridFilterParams.yesOptionDef, AgGridFilterParams.noOptionDef, AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
        defaultOption: AgGridFilterParams.noFilterOptionDef.displayKey,
        newRowsAction: 'keep'
    };

    static yesNoFilterParams: any = {
        clearButton: true,
        applyButton: true,
        suppressAndOrCondition: true,
        filterOptions: [AgGridFilterParams.noFilterOptionDef, AgGridFilterParams.yesOptionDef, AgGridFilterParams.noOptionDef],
        defaultOption: AgGridFilterParams.noFilterOptionDef.displayKey,
        newRowsAction: 'keep'
    };

    static booleanFloatingFilterParams: any = {
    };
}
