import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { QueryInfoRepository } from '../queryInfo.repository';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';

export interface QueryInfoDetailsParams {
    queryInfoId: number;
}

@Component({
    selector: 'query-info-details',
    templateUrl: './queryInfoDetails.component.html'
})
export class QueryInfoDetailsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<QueryInfoDetailsParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _queryInfoRepository: QueryInfoRepository) { }

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    params: QueryInfoDetailsParams;
    result: void;

    queryInfo: Core.QueryInfoModel;

    async ngOnInit(): Promise<void> {

        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);

        try {
            this.queryInfo = await lastValueFrom(this._queryInfoRepository.get(this.params.queryInfoId));
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    ngOnDestroy(): void { }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
