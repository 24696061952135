import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { UserInstanceService } from 'src/app/User/userInstance.service';
import { CompanyModel, CompanyService } from '../company.service';

export interface AddCompanyModalParams {
    parentCompany: any;
}

@Component({
    selector: 'add-company-modal',
    templateUrl: 'addCompanyModal.component.html'
})

export class AddCompanyModalComponent implements OnInit,  AfterViewInit, IWeissmanModalComponent<AddCompanyModalParams, void> {
    @ViewChild('companyName') companyName: ElementRef;

    params: AddCompanyModalParams;
    result: void;

    serverAction: boolean = false;
    addCompanyForm: UntypedFormGroup;
    entityIdScope: number;
    entityTypeScope: 'instance';

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _toastr: ToastrService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _companyService: CompanyService,
        private readonly _bsModalRef: BsModalRef
    ) { }

    async ngOnInit() {
        this.entityIdScope = this._userInstanceService.getSelectedInstance().instanceId;

        const companyCodeControl: any[] = [''];
        if (this.params.parentCompany?.ppReturnPreparationAllowed) {
            companyCodeControl.push(Validators.required);
        }

        this.addCompanyForm = this._fb.group({
            companyName: ['', Validators.required],
            companyCode: companyCodeControl,
            accountHandlerUserID: [null, Validators.required],
            accountHandlerTeamID: [null, Validators.required]
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.companyName.nativeElement.focus();
        });
    }

    accountHandlerSelected(newAccountHandler: Core.UserTeamModel) {
        this.addCompanyForm.controls.accountHandlerUserID.patchValue(newAccountHandler?.userID);
        this.addCompanyForm.controls.accountHandlerTeamID.patchValue(newAccountHandler?.teamID);

        this.addCompanyForm.controls.accountHandlerUserID.markAsTouched();
    }

    async save(): Promise<void> {
        const isCompanyNameNonAscii = !(/^[ -~\t\n\r]+$/.test(this.addCompanyForm.controls.companyName.value));
        if (isCompanyNameNonAscii) {
            this._toastr.error('Company Name contains invalid character(s)');
            return;
        }

        const companyToAdd = {
            activityStatusID: 2,
            invoiceAppealSavings: true
        } as CompanyModel;

        _.assign(companyToAdd, this.addCompanyForm.value);

        this.serverAction = true;

        try {
            const topLevelCompanyId = this.params.parentCompany ? this.params.parentCompany.topLevelCompanyId : null;
            const ppReturnPreparationAllowed = this.params.parentCompany ? this.params.parentCompany.ppReturnPreparationAllowed : false;

            const validationPromises = [
                this._companyService.isCompanyNameInUseAlready(companyToAdd.companyName, topLevelCompanyId, null),
                this._companyService.isCompanyCodeInUseAlready(companyToAdd.companyCode, topLevelCompanyId, null, ppReturnPreparationAllowed)
            ];

            const [isCompanyNameInUse, isCompanyCodeInUse] = await Promise.all(validationPromises);

            if(isCompanyNameInUse || isCompanyCodeInUse) {
                if(isCompanyNameInUse) {
                    this._toastr.error('Company Name must be unique, please enter another value');
                }
                if(isCompanyCodeInUse) {
                    this._toastr.error('Company Code must be unique, please enter another value');
                }

                return;
            }

            // TL Company Id msut be set to 0 when adding a TL Company.
            // TL Company Id must set to the parent Company if adding a Subsidiary.
            companyToAdd.topLevelCompanyId = 0;
            if (this.params.parentCompany) {
                // Adding a Subsidiary
                companyToAdd.topLevelCompanyId = this.params.parentCompany.topLevelCompanyId;
                companyToAdd.useTopLevelCompanyCOA = true;
                companyToAdd.enableAllocations = this.params.parentCompany.enableAllocations;
            }

            const newCompany = await this._companyService.create(companyToAdd);

            this._upgradeNavigationServiceHandler.go('company', {
                companyId: newCompany.companyID
            }, true);

        } finally {
            this.serverAction = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
