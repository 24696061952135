import { Component, OnInit } from '@angular/core';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator/busyIndicatorMessageManager'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilingBatchRepository } from '../../../Repositories/filingBatch.repository';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { FILING_DETAILS_HELP } from './filingDetails.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { lastValueFrom } from "rxjs";

export interface FilingDetailsParams {
    filingId: number;
    calculatedPriorReturnId: number;
    priorReturnId: number;
    isAmendment: boolean;
}

export interface FilingDetailsResult {
    priorReturnId: number;
    priorReturnDescription: string;
    isAmendment: boolean;
}

@Component({
    selector: 'filing-details',
    templateUrl: './filingDetails.component.html'
})
export class FilingDetailsComponent implements OnInit, IWeissmanModalComponent<FilingDetailsParams, FilingDetailsResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _helpService: HelpService) {
    }

    params: FilingDetailsParams;
    result: FilingDetailsResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    priorReturns: Compliance.FilingModel[] = [];
    selectedPriorReturn: Compliance.FilingModel;
    isAmendment: boolean;
    priorReturnSelected: boolean;

    private _initialPriorReturn: Compliance.FilingModel;
    private _initialIsAmendment: boolean;

    get saveDisabled(): boolean {
        return this._initialIsAmendment === this.isAmendment && this._initialPriorReturn === this.selectedPriorReturn;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FILING_DETAILS_HELP);

        const busyMsg = 'load-priorReturns';
        this.busyIndicatorMessageManager.add('Loading', busyMsg);

        try {
            this.priorReturns = await lastValueFrom(this._filingBatchRepository.getListByPriorReturnAvailable(this.params.filingId));
            this.selectedPriorReturn = _.find(this.priorReturns, (x) => x.returnId === this.params.priorReturnId) || null;
            this.priorReturnSelected = this.selectedPriorReturn !== null;
            this.isAmendment = this.priorReturnSelected ? this.params.isAmendment : false;

            this._initialPriorReturn = this.selectedPriorReturn;
            this._initialIsAmendment = this.isAmendment;
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    async save(): Promise<void> {
        this.result = {
            priorReturnId: this.selectedPriorReturn && this.selectedPriorReturn.returnId,
            priorReturnDescription: this.selectedPriorReturn && this.selectedPriorReturn.filingDescription,
            isAmendment: this.isAmendment
        }

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    priorReturnChanged(): void {
        this.priorReturnSelected = this.selectedPriorReturn !== null;

        if(!this.priorReturnSelected) {
            this.isAmendment = false;
        }
    }
}
