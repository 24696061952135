<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-flex-container-horizontal ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Vendor Code Mapping"></ws-bread-crumbs>
        </div>
    </div>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Vendor Code Mapping
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <span>Show All Collectors</span>
                    <ws-switch  size="small"
                                [(ngModel)]="showAllCollectors"
                                style="margin-left: 10px;"
                                [disabled]="refreshing"
                                (selectedOptionChanged)="refresh()">
                    </ws-switch>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="flat-button small-button secondary-button"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-flex-container-vertical ws-flex-auto">
        <ws-ag-grid-angular class="ag-theme-balham grid grid-cell-no-focus"
                         [gridOptions]="gridOptions"
                         (gridReady)="onAgGridReady($event)"
                         [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>


