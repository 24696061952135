import { Component, AfterViewInit, HostListener, ViewChild, ElementRef, OnInit, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '../Common/States/States.Service';
import { AssessorCollectorService } from '../Assessor-Collector/assessor.collector.service';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { StateSummary } from '../Common/States/state.model';
import { UserService } from '../Account/user.service';
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { TimerService } from '../UI-Lib/Utilities';

declare const saveAs: any;
declare const Datamap: any;

export interface StateTypeahead extends StateSummary {
    typeaheadVal: string;
}

@Component({
    selector: 'united-states',
    templateUrl: './states.component.html',
    styleUrls: ['./states.component.scss']
})
export class UnitedStatesComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly _stateService: StateService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _upgradeNavigationService: UpgradeNavigationServiceHandler,
        private readonly _userService: UserService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _timerService: TimerService
    ) {
    }

    @ViewChild('stateContainer', { static: true }) stateContainer: ElementRef;
    @ViewChild('unitedStates', { static: true }) unitedStates: ElementRef;

    states: StateTypeahead[];
    loadingCollectorList: boolean = false;
    loadingAssessorList: boolean = false;
    disableCollectorList: boolean = false;
    disableAssessorList: boolean = false;
    selected: string;

    private _map: any;
    private _mapHeight: any;
    private _navigatedFrom: string;
    private readonly _NAVIGATED_DASHBOARD: string = 'dashboard';

    @HostListener('window:resize', ['$event']) onResize(event) {
        this._resizeMap();
    }

    ngOnInit(): void {
        if (this._userService.getUser().isDemoUser){
            this.disableCollectorList = true;
            this.disableAssessorList = true;
        }

        this._navigatedFrom = this._stateService.navigatedFrom;
        this._stateService.navigatedFrom = null;

        this._getStates();
    }

    ngAfterViewInit(): void {
        this._timerService.setTimeout(() => {
            this._resizeMap();
        }, 100);
    }

    async downloadAssessorExtract(): Promise<void> {
        this.loadingAssessorList = true;

        try {
            const result: Blob = await this._assessorCollectorService.getAssessorExtract();
            this._downloadFile(result, 'Assessor');
            if (this._navigatedFrom === this._NAVIGATED_DASHBOARD) {
                this._productAnalyticsService.logEvent('click-state-widget', { state: 'Assessor Extract' });
            }
        } finally {
            this.loadingAssessorList = false;
        }
    }

    async downloadCollectorExtract(): Promise<void> {
        this.loadingCollectorList = true;

        try {
            const result: Blob = await this._assessorCollectorService.getCollectorExtract();
            this._downloadFile(result, 'Collector');
            if (this._navigatedFrom === this._NAVIGATED_DASHBOARD) {
                this._productAnalyticsService.logEvent('click-state-widget', { state: 'Collector Extract' });
            }
        } finally {
            this.loadingCollectorList = false;
        }
    }

    onStateSelect(e: any): void {
        this._upgradeNavigationService.go('state', { stateId: e.item.stateID });
    }

    private async _getStates(): Promise<void> {
        const states = await this._stateService.getSummary();
        this.states = _.map(states, (state: StateTypeahead) => {
            // Hack since ngx-bootstrap doesn't currently support multiple typeaheadOptionFields
            state.typeaheadVal = `${state.fullName} ${state.abbr}`;
            return state;
        });
    }

    private _downloadFile(result: Blob, entity: string): void {
        const blob = new Blob([result], { type: 'text/csv' });

        saveAs(blob, `${entity  } List Extract.csv`);
    }

    private _resizeMap(): void {
        const map = this.unitedStates.nativeElement;

        if (map) {
            map.innerHTML = '';
            this._mapHeight = this.unitedStates.nativeElement.clientHeight;
            this._updateMap();
        }
    }

    private _updateMap(): void {
        let width = window.innerWidth - 32; // 16px padding on each side
        let height = width * 0.55;
        if (height > this._mapHeight) {
            // set width and height to 11:6 aspect ratio
            width = this._mapHeight * 1.83;
            height = this._mapHeight;
        }

        this._map = new Datamap({
            scope: 'usa',
            element: this.unitedStates.nativeElement,
            geographyConfig: {
                popupOnHover: false,
                highlightFillColor: '#0a8287',
                highlightBorderColor: '#FFFFFF',
                highlightBorderWidth: 1
            },
            fills: {
                defaultFill: '#A3A4B2'
            },
            labelSize: 14,
            width,
            height,
            responsive: false,
            done: (datamap) => {
                datamap.svg.selectAll('.datamaps-subunit')
                    .on('click', (geography) => {
                        const state: StateTypeahead = _.find(this.states, {
                            abbr: geography.id
                        });

                        if(state) {
                            if (this._navigatedFrom === this._NAVIGATED_DASHBOARD) {
                                this._productAnalyticsService.logEvent('click-state-widget', { state: state.abbr });
                            }
                            this._upgradeNavigationService.go('state', { stateId: state.stateID });
                        } else {
                            console.warn('State not found!');
                        }
                    });
            }
        });

        this._map.labels({ labelColor: '#3E4050', fontSize: 14 });
    }


}
