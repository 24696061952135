import { HelpContent } from '../UI-Lib/Help-Tooltip';

export const ANNUAL_DETAILS_HELP: HelpContent[] = [
    {
        helpContentId: 'annual-details.phantom-year',
        tooltipText: 'This tax year contains filing(s) from a consolidated parcel.'
    },
    {
        helpContentId: 'annual-details.phantom-filing',
        tooltipText: 'This filing is from a consolidated parcel.'
    }
];
