import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, ColDef } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../../Compliance/AgGrid';
import { ActionViewOutputDefaultsGridActionCellRendererComponent, ICellRendererParamsForActionViewOutputDefaultsGridAction } from './agGridActionCellRenderer.component';
import { ActionViewService } from '../action-view-service.upgrade';
import { ActionViewOutputCustomParams, ActionViewOutputCustomComponent } from '../Action-View-Output-Custom/actionViewOutputCustom.component';
import * as _ from 'lodash';
import { UserSettingsService } from '../../../Account/userSettings.service';
import { SmartSearchService } from '../../../Search/Advanced/smartSearch.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { BusyIndicatorService } from '../../../Busy-Indicator';

@Component({
    selector: 'action-view-output-defaults',
    templateUrl: './actionViewOutputDefaults.component.html',
    styleUrls: ['./actionViewOutputDefaults.component.scss']
})
export class ActionViewOutputDefaultsComponent implements OnInit {
    constructor(
        private readonly _actionViewService: ActionViewService,
        private readonly _userSettingService: UserSettingsService,
        private readonly _smartSearchService: SmartSearchService,
        private readonly _modalService: WeissmanModalService,
        private readonly _busyIndicatorService: BusyIndicatorService) { }

    private _gridApi: GridApi;
    private _taskTypes: any[];
    private _taskTypeColumns: any[];

    gridId: System.Guid = '7D885CAC-55F4-4CAE-AE56-B8948DE60508'; // need new guid
    isInitialized: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withTextSelection()
        .build();

    refreshing: boolean;

    hasEditPermission: boolean = true;

    async ngOnInit(): Promise<void> {
        this.isInitialized = true;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Task Type',
                field: 'name',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                sortable: false,
                width: AgGridColumns.textColumnWidth,
                cellStyle: params => {
                    const taskType = params.data;

                    return taskType.taskTypeID ? {marginLeft: '15px'} : {fontWeight: 'bold'};
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: ActionViewOutputDefaultsGridActionCellRendererComponent,
                cellRendererParams: {
                    hasEditPermission: () => this.hasEditPermission,
                    viewDetails: this._viewDetails.bind(this),
                } as ICellRendererParamsForActionViewOutputDefaultsGridAction
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._refresh();
    }

    private async _viewDetails(params: ICellRendererParamsForActionViewOutputDefaultsGridAction): Promise<void> {
        const selectedTaskType = params.data as any;

        const userSetting = this._userSettingService.getSettingsByGroup(12)[0] || {
            groupId: 12,
            name: 'Task View Output Defaults',
            value: '[]',
            id: 0
        };
        const customTaskTypes = typeof userSetting.value === 'string' ? JSON.parse(userSetting.value) : userSetting.value;
        const customTaskType = _.find(customTaskTypes, x => {
            return x.taskSeriesTypeID === selectedTaskType.taskSeriesTypeID && x.taskTypeID === selectedTaskType.taskTypeID;
        });

        const modalParams: ActionViewOutputCustomParams = {
            fromActionView: false,
            taskType: selectedTaskType,
            systemColumns: _.map(selectedTaskType.columnIds, x => {
                const column = _.find(this._taskTypeColumns, { columnId: x });

                return _.cloneDeep(column);
            }),
            userColumns: await this._getUserColumns(customTaskType)
        };

        const columns = await this._modalService.showAsync(ActionViewOutputCustomComponent, modalParams, '');

        if(!columns) {
             return;
        }

        if (columns.length) {
            const columnsToSave = _.map(columns, (filter, i) => {
                return {
                    columnId: filter.columnId,
                    sequence: i
                };
            });

            if (customTaskType) {
                const i = _.findIndex(customTaskTypes, (taskType: any) => {
                    return taskType.taskSeriesTypeID === customTaskType.taskSeriesTypeID && taskType.taskTypeID === customTaskType.taskTypeID;
                });

                customTaskTypes[i].columns = columnsToSave;

            } else {
                customTaskTypes.push({
                    taskSeriesTypeID: selectedTaskType.taskSeriesTypeID,
                    taskTypeID: selectedTaskType.taskTypeID,
                    columns: columnsToSave
                });
            }

            userSetting.value = JSON.stringify(customTaskTypes);
            this._saveUserSetting(userSetting);
        } else if (customTaskType) {
            _.remove(customTaskTypes, customTaskType);

            userSetting.value = customTaskTypes;
            this._saveUserSetting(userSetting);
        }

    }

    private async _getUserColumns(customTaskType): Promise<any[]> {
        let userColumns = [];

        if (customTaskType) {
            const allFields = await this._smartSearchService.getAllFields(false);

            userColumns = _.map(_.cloneDeep(customTaskType.columns), (column: any) => {
                const fullColumn = _.cloneDeep(_.find(allFields, { advancedSearchFieldID: column.columnId }));
                _.assign(column, _.omit(fullColumn, 'sequence'));

                return column;
            });
        }

        return userColumns;
    }

    private async _saveUserSetting(userSetting): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating user setting' });

        try {
            await this._userSettingService.save(userSetting);
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
        }
        finally {
            await busyRef.hide();
        }
    }

    async refresh(): Promise<void> {
        await this._refresh();
    }

    private async _refresh(): Promise<void> {
        this.refreshing = true;

        this._taskTypes = [];

        try {
            this._gridApi && this._gridApi.showLoadingOverlay();

            const result = await this._actionViewService.getTaskTypes();
            this._taskTypes = this._actionViewService.sortTaskTypes(result.taskTypes);
            this._taskTypeColumns = result.columns;

        } finally {
            this._gridApi && this._gridApi.hideOverlay();
        }

        this._setRowData();
        this.refreshing = false;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._taskTypes)) {
            return;
        }

        this._gridApi.setRowData(this._taskTypes);
    }
}
