import { Component, OnInit, Input } from '@angular/core';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AddInstanceModalComponent } from '../../Entity/Instance/Add/addInstanceModal.component';
import { AnchorSREF } from '../../UI-Lib/Directives/Anchor-Route/anchorRoute.directive';
import { ContactModalOrigin } from '../../constants.new';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { ContactModalService } from '../../Contact/contactModal.service';
import { UserService } from 'src/app/Account/user.service';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';

interface ManageMenuItem {
    name: string;
    isShown: () => boolean;
    isDisabled?: () => boolean;
    anchor: AnchorSREF;
    amplitudeProperty?: { event: string, properties?: any };
}

@Component({
    selector: 'manage-menu',
    templateUrl: './manageMenu.component.html',
    styleUrls: ['./manageMenu.component.scss']
})
export class ManageMenuComponent implements OnInit {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _contactModalService: ContactModalService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _modalService: WeissmanModalService,
        private readonly _instanceRepository : InstanceRepository,
        private readonly _userService: UserService,
        private readonly _restrictService : RestrictService,
        private readonly _featureFlagsService: FeatureFlagsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @Input() parent;

    userText: string;
    contactID: number;
    developerMode: boolean;
    isSingleInstanceSelected: boolean = false;
    showManageInstancesMenuItem: boolean = false;
    get showManageConsultingMenuItem(): boolean {
        return this.isSingleInstanceSelected && this._userInstanceService.getSelectedInstance().offersConsultingServices && this._restrictService.hasInstanceRight(InstanceRights.MANAGECONSULTINGENGAGEMENT, this._userInstanceService.getSelectedInstance().instanceId);
    }

    adminMenuItems: ManageMenuItem[] = [
        {
            name: 'Contacts',
            isShown: () => this.parent && this.parent.hasContactRoles,
            anchor: { target: 'contacts', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-contacts' }
        },
        {
            name: 'Agents',
            isShown: () => this.parent && this.parent.hasAgentInfo,
            anchor: { target: 'agents', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-agents' }
        },
        {
            name: 'Property Characteristics',
            isShown: () => this.parent && this.parent.hasPropCharsRoles,
            anchor: { target: 'propertyCharacteristics', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-property-characteristics' }
        },
        {
            name: 'Roles',
            isShown: () => this.parent && this.parent.hasContactRoleRoles,
            anchor: { target: 'roles', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-roles' }
        },
        {
            name: 'User Groups',
            isShown: () => this.parent && this.parent.hasUserGroupViewOrEdit,
            isDisabled: () => this.parent && !this.parent.hasUserGroupEdit,
            anchor: { target: 'userGroupList', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-user-groups' }
        },
        {
            name: 'Manage API Keys',
            isShown: () => this.parent && this.parent.hasManageAPIKeys,
            anchor: { target: 'manageAPIKeys', options: {} }
        },
        {
            name: 'Manage Instance Settings',
            isShown: () => this.parent && this.parent.hasManageConsultingEngagements,
            anchor: { target: 'instanceInfo', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-instance-settings' }
        },
        {
            name: 'Teams',
            isShown: () => this.parent && this.parent.hasTeamViewOrEdit,
            anchor: { target: 'teams', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-teams' }
        },
        {
            name: 'Import Assessments',
            isShown: () => this.parent && this.parent.hasAllowDataImports,
            anchor: { target: 'importAssessments', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'assessments' } }
        },
        {
            name: 'Import Sites',
            isShown: () => this.parent && this.parent.hasAllowDataImports,
            anchor: { target: 'importSites', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'sites' } }
        },
        {
            name: 'Import Parcels',
            isShown: () => this.parent && this.parent.hasAllowDataImports,
            anchor: { target: 'importParcels', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'parcels' } }
        },
        {
            name: 'Factor Tables',
            isShown: () => this.parent && this.parent.hasComplianceSetupsView || this.parent.hasComplianceSetupsEdit,
            anchor: { target: 'factorTableList', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-factor-tables' }
        },
        {
            name: 'Forms',
            isShown: () => this.parent && this.parent.hasComplianceSetupsView || this.parent.hasComplianceSetupsEdit,
            anchor: { target: 'formList', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-forms' }
        },
        {
            name: 'Valuation Templates',
            isShown: () => this.parent && this.parent.hasValuationTemplateEdit,
            anchor: { target: 'valuationTemplates', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-valuation-templates' }
        },
        {
            name: 'Payment Batch Command Center',
            isShown: () => this.parent && this.parent.enablePaymentBatch &&
                (this.parent.hasViewRyanPrivatePermissions || this.parent.hasEditRyanPrivatePermissions),
            anchor: { target: 'paymentBatchCommandCenter', options: {} }
        },
        {
            name: 'Appeal Recommendation Command Center',
            isShown: () => this.parent && (this.parent.hasViewRyanPrivatePermissions || this.parent.hasEditRyanPrivatePermissions),
            anchor: { target: 'appealRecommendationCommandCenter', options: {} },
            // amplitudeProperty: { event: 'click-manage-admin-valuation-templates' }
        },
        {
            name: 'Tax Rate Command Center',
            isShown: () => this.parent && this.parent.hasViewRyanPrivatePermissions || this.parent.hasEditRyanPrivatePermissions,
            anchor: { target: 'taxRateCommandCenter', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-tax-rate-CC' }
        },
        {
            name: 'State & Jurisdiction Command Center',
            isShown: () => this.parent && this.parent.hasViewRyanPrivatePermissions || this.parent.hasEditRyanPrivatePermissions,
            anchor: { target: 'stateJurisdictionCommandCenter', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-state-juris-CC' }
        },
        {
            name: 'Data Imports',
            isShown: () => this.parent && this.parent.hasAllowDataImports,
            anchor: { target: 'systemImports', options: {} },
            amplitudeProperty: { event: 'click-manage-admin-import', properties: { manageAdminImport: 'data' } }
        },
        {
            name: 'About',
            isShown: () => this.parent && this.parent.hasAdminOperationsView || this.parent.hasAdminOperationsEdit,
            anchor: { target: 'about', options: {} }
        }
    ];

    profileMenuItems: ManageMenuItem[] = [
        {
            name: 'Manage My Instances',
            isShown: () => this.showManageInstancesMenuItem,
            anchor: { target: 'invites', options: {} },
            amplitudeProperty: { event: 'click-manage-my-instances' }
        },
        {
            name: 'Manage Support Instances',
            isShown: () => this.parent && this.parent.hasSupportRole,
            anchor: { target: 'supportInstanceList', options: {} }
        },
        {
            name: 'Manage Consulting Engagements',
            isShown: () => this.showManageConsultingMenuItem,
            anchor: { target: 'consulting', options: {} }
        },
        {
            name: 'Manage Licensed Client Documents',
            isShown: () => this.parent && this.parent.hasLicensedDocumentServices && this.parent.enableLicensedDocumentIntakeAndProcessing,
            anchor: { target: 'documentIntakeLicensedClientList', options: {} }
        },
        {
            name: 'Action View Output Fields',
            isShown: () => true,
            anchor: { target: 'taskviewoutputdefaults', options: {} },
            amplitudeProperty: { event: 'click-manage-AV-output' }
        }
    ];

    diagnosticsMenuItems: ManageMenuItem[] = [
        {
            name: 'Long-Running Processes',
            isShown: () => this.parent && this.parent.hasAdminOperationsView || this.parent.hasAdminOperationsEdit,
            anchor: { target: 'longRunningProcess', options: {} }
        },
        {
            name: 'Error Log',
            isShown: () => this.parent && this.parent.hasAdminOperationsView || this.parent.hasAdminOperationsEdit,
            anchor: { target: 'errorLog', options: {} }
        },
        {
            name: 'SMART, AV, report queries',
            isShown: () => this.parent && this.parent.hasAdminOperationsView || this.parent.hasAdminOperationsEdit,
            anchor: { target: 'queryInfo', options: {} }
        },
        {
            name: 'Database',
            isShown: () => this.parent && this.parent.hasAllowManageSystem,
            anchor: { target: 'database', options: {} }
        },
        {
            name: 'Playground',
            isShown: () => this.developerMode,
            anchor: { target: 'playground', options: {} }
        }
    ];

    changePassword: ManageMenuItem = {
        name: 'Change Password',
        isShown: () => true,
        anchor: { target: 'changePassword', options: {} }
    };

    logout: ManageMenuItem = {
        name: 'Logout',
        isShown: () => true,
        anchor: { target: 'logout', options: {} }
    };

    changePasswordUrl: string;
    IAMactive: boolean;
    paymentBatchFlag: boolean;

    ngOnInit(): void {
        this.getUserInfo();
        if (window['weissmanEnvironmentConfig']) {
            this.developerMode = window['weissmanEnvironmentConfig'].developerMode;
        }

        this.changePasswordUrl = `${this._featureFlagsService.taxDotComBase}/user-management/myaccount/`;

        this.IAMactive = this._featureFlagsService.featureFlags.enableIAM;
        if (!this.IAMactive) {
            this.profileMenuItems.push(this.changePassword);
            this.profileMenuItems.push(this.logout);
        }
    }

    async getUserInfo(): Promise<void> {
        const user = this._userService.getUser();
        this.userText = `${user.firstName} ${user.lastName}` || user.username;
        this.isSingleInstanceSelected = this._userInstanceService.isSingleInstanceSelected();
        this.contactID = this.isSingleInstanceSelected ? this._userInstanceService.getInstanceContactId() : user.contactId;
        this.showManageInstancesMenuItem = (await this._instanceRepository.getCurrentUsersContactInviteStatuses()).length > 1;
    }

    openContactModal(): void {
        this._contactModalService.openContactDialog(this.contactID, ContactModalOrigin.ViewProfile);
        this.triggerAmplitudeEvent({ event: 'click-manage-view-profile' });
    }

    navigateTo(page: string): void {
        this._routerService.go(page, {});
    }

    async addInstance(): Promise<void> {
        const result = await this._modalService.showAsync(AddInstanceModalComponent, {}, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }
    }

    async manageMyInstances(): Promise<void> {
        await this._userInstanceService.showManageMyInstances();
    }

    triggerAmplitudeEvent(amplitudeProperty: { event: string, properties?: any }): void {
        if (amplitudeProperty) {
            this._productAnalyticsService.logEvent(amplitudeProperty.event, amplitudeProperty.properties);
        }
    }

}

