(function () {
    'use strict';

    angular
        .module('weissmanApp')
        .factory('ParcelActivityModalService', Service);

    Service.$inject = ['$uibModal'];

    function Service($uibModal) {
        return {
            open: open
        };

        function open(parcelID, siteID, years) {
            $uibModal.open({
                templateUrl: 'app/Entity/Parcel/Info/Activity/_parcelActivityModal.html',
                controller: 'sd.Parcel.Activity.Modal.Controller',
                controllerAs: 'vm',
                size: 'lg',
                windowClass: 'show',
                backdropClass: 'show',
                resolve: {
                    params: function () {
                        return {
                            parcelId: parcelID,
                            siteId: siteID
                        }
                    },
                    parcelYears: function () {
                        return years;
                    }
                }
            });
        }
    }
})();
