import { HttpClient } from "@angular/common/http";
import { Injectable, Injector, ProviderToken } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { AccountService } from "src/app/User/account.service";
import { StorageTokenKeys } from "src/app/User/authStorage.service";

// This service will be attached to the window object for easy access from a JavaScript console. It's primarily
// used for debugging and troubleshooting.
@Injectable({ providedIn: 'root' })
export class EasyAccessService {
    http: any;
    lrProcessIncompleteDelay: number = null;

    constructor(
        http: HttpClient,
        private _injector: Injector,
        public accountService: AccountService
    ) {
        // Promise-based version of Angular HTTP library
        this.http = {
            delete: (...args) => { return lastValueFrom(http.delete.apply(http, args)); },
            get: (...args) => { return lastValueFrom(http.get.apply(http, args)); },
            head: (...args) => { return lastValueFrom(http.head.apply(http, args)); },
            options: (...args) => { return lastValueFrom(http.options.apply(http, args)); },
            patch: (...args) => { return lastValueFrom(http.patch.apply(http, args)); },
            post: (...args) => { return lastValueFrom(http.post.apply(http, args)); },
            put: (...args) => { return lastValueFrom(http.put.apply(http, args)); },
            request: (...args) => { return lastValueFrom(http.request.apply(http, args)); }
        }
    }

    getNgjsService(serviceName: string): any {
        return (<any>window).angular.element(document.body).injector().get(serviceName);
    }

    helpDigestDebug(): void {
        console.log(`To enable digest debugging, type easyAccess.enableDigestDebugging(); in a javaScript console 
and refresh the page. Alternatively, specify flags separated by pipes instead of 'true'. Recognized flags: timeout, 
interval, and disableanim. Example: easyAccess.enableDigestDebugging('timeout|interval');`);
    }

    enableDigestDebugging(flags: string): void {
        sessionStorage['debugDigest'] = flags ? flags : 'true';
    }

    disableDigestDebugging(): void {
        delete sessionStorage['debugDigest'];
    }

    enablePauseOnIAM(): void {
        sessionStorage[StorageTokenKeys.pauseOnIAM] = true;
    }

    disablePauseOnIAM(): void {
        delete sessionStorage[StorageTokenKeys.pauseOnIAM];
    }

    listAngularServices(): string[] {
        return this._getInjectorRecords().filter(r => r.name).map(r => r.name);
    }

    getAngularService<T>(serviceName: string): T {
        const targetClasses = this._getInjectorRecords<T>().filter(r => r.name === serviceName);
        if (targetClasses.length > 1) { throw new Error(`Too many services found for ${serviceName}`); }
        if (targetClasses.length !== 1) { throw new Error(`No service found for ${serviceName}`); }
        const targetClass = targetClasses[0];

        return this._injector.get<T>(targetClass);
    }

    copyDirectApiToken(): Promise<void> {
        // The "copy" object isn't available after a promise callback (some kind of zone.js thing I think),
        // so grab a reference to it before kicking off the promise.
        const copy = (<any>window).copy;
        return this.accountService.getDirectAPIToken().then(token => {
            copy(token);
            console.log('Token copied to clipboard');
        });
    }

    private _getInjectorRecords<T>() {
        // records is a private property on Injector, so cast to any to sneak in and grab it
        const records: Map<ProviderToken<T> & { name: string }, unknown> = (<any>this._injector).records;
        return Array.from(records.keys());
    }
}
