import { ButtonSize, ButtonState, ButtonStyle } from '@ace/shared';
import { Component } from '@angular/core';
import { IButtonGroupInput } from '../../UI-Lib/ACE/Button-Group/buttonGroup.component';
import { SearchCategoryType } from '../User-Save/userSave.service';

@Component({
    selector: 'saved-report',
    templateUrl: './savedReport.component.html',
    styleUrls: ['./savedReport.component.scss']
})
export class SavedReportComponent {

    tabSetConfig: IButtonGroupInput = {
        buttons: [
            {
                id: SearchCategoryType.System,
                content: 'SYSTEM REPORTS',
                routerOutlet: 'savedReport.system',
                size: ButtonSize.Small
            },
            {
                id: SearchCategoryType.User,
                content: 'MY SAVED REPORTS',
                routerOutlet: 'savedReport.user',
                size: ButtonSize.Small
            }
        ],
        singleSelect: {
            activeStyle: ButtonStyle.Primary,
            inactiveStyle: null,
            activeState: ButtonState.Info,
            inactiveState: ButtonState.Info
        }
    };
}
