<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="isShowInfo"></panel-chevron>
        <h3>Compliance Info</h3>
    </div>
    <div class="card-body" *ngIf="isShowInfo">
        <loading-spinner [show]="isLoading" [size]="5" [marginBottom]="50"></loading-spinner>
        <div *ngIf="!isLoading">
            <div class="setting no-border">
                <a anchor-route [sref]="{ target: 'supplementalInformationSheet', options: {companyId: company.companyID} }">Supplemental Information</a>
            </div>
            <div class="setting no-border">
                <a anchor-route [sref]="{ target: 'assetList', options: {companyId: ((isTopLevelCompany) ? company.companyID : company.topLevelCompanyId)} }">Assets <span *ngIf="!isTopLevelCompany">({{parentCompany.companyName}} and its subsidiary companies)</span></a>
            </div>
            <div class="setting no-border ws-stretch-width">
                <a anchor-route [sref]="{ target: 'filingBatchList', options: {companyId: ((isTopLevelCompany) ? company.companyID : company.topLevelCompanyId)} }">Filing Batches <span *ngIf="!isTopLevelCompany">({{parentCompany.companyName}} and its subsidiary companies)</span></a>
            </div>
            <div class="setting no-border ws-stretch-width">
                <a anchor-route [sref]="{ target: 'electronicFiling', options: {companyId: ((isTopLevelCompany) ? company.companyID : company.topLevelCompanyId)} }">Electronic Filings <span *ngIf="!isTopLevelCompany">({{parentCompany.companyName}} and its subsidiary companies)</span></a>
            </div>
        </div>
    </div>
</div>
