import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription,  BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { FormService } from '../form.service';
import { BusyIndicatorService } from '../../../Busy-Indicator/busyIndicator.service';
import { ToastrService } from 'ngx-toastr';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode, CsvExportParams, ProcessCellForExportParams, ICellRendererParams, StartEditingCellParams } from 'ag-grid-community';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { FormScheduleListGridCheckboxCellRendererComponent, FormScheduleListGridCheckboxCellRendererParams } from './agGridCheckboxCellRender.component';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { FormScheduleListGridActionCellRendererComponent, ICellRendererParamsForFormScheduleListGridAction } from './agGridActionCellRenderer.component';
import { FormScheduleListGridFactorTableDropdownCellRendererComponent, FormScheduleListGridFactorTableDropdownCellRendererComponentParams } from './agGridFactorTableDropdownCellRenderer.component';
import { FormScheduleListGridInputCellRendererComponent, FormScheduleListGridInputCellRendererParams } from './agGridInputCellRender.component';
import { WeissmanMutexService, IMutexServiceHandler } from '../../WeissmanMutexService';
import { BaseExpandableAgGridComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { FormScheduleListGridReportDropdownCellRendererComponent, FormScheduleListGridReportDropdownCellRendererParams } from './agGridReportDropdownCellRenderer.component';

export interface FormRevisionScheduleRowModel extends Compliance.FormRevisionScheduleModel {
    defaultFactorTable: Compliance.FormFactorTableModel;
    savedSearch: Core.SavedSearchModel;
    isFactorTableLocked: boolean;
    scheduleFactorTables: Compliance.FormRevisionScheduleFactorTableModel[];
    changed: boolean;
    deleted: boolean;
    added: boolean;
    originalValue: Compliance.FormRevisionScheduleModel;
}

@Component({
    selector: 'form-schedule-list',
    templateUrl: './formScheduleList.component.html',
    styleUrls: ['formScheduleList.component.scss']
})
export class FormScheduleListComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _formService: FormService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _toastsManager: ToastrService) {
            super(_formService, 'form-schedule-list');
    }

    private _formRevisionSub: Subscription;
    private _formRevisionYearSub: Subscription;
    private _schedulesSub: Subscription;
    private _assessorSub: Subscription;
    private _assessorsSub: Subscription;
    private _reportsSub: Subscription;
    private _reportsSubject: BehaviorSubject<Core.SavedSearchModel[]> = new BehaviorSubject<Core.SavedSearchModel[]>([]);
    private _factorTablesSub: Subscription;
    private _factorTablesSubject: BehaviorSubject<Compliance.FormFactorTableModel[]> = new BehaviorSubject<Compliance.FormFactorTableModel[]>([]);
    private _gridApi: GridApi;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;

    get assessor(): Compliance.FormRevisionAssessorModel {
        return this._formService.assessor;
    }

    get factorTablesAssessorName(): string {
        return this._formService.factorTablesAssessorName;
    }

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._formService.assessor &&
            this._mutexService.canAcquire(this._formService.editGroup) &&
            ((!this._formService.assessor.isMappingCertified) &&
                ((!this._formService.assessor.isUsingDefaultTables) || (!this._formService.assessor.assessorId)));
    }

    get taxYear(): number {
        return this._formService.taxYear;
    }

    editMode: boolean = false;
    factorTables: Compliance.FormFactorTableModel[] = null;
    reports: Core.SavedSearchModel[] = null;
    gridId: System.Guid = '1508C9A3-CB71-4C28-98A7-ACE9E3BD1BBD';

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'grid-row-readonly': params => params.data && params.data.isSystem,
                'grid-row-deleted': params => params.data && params.data.deleted
            },
            singleClickEdit: true
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .withoutCellEditingStoppedOnGridLostFocus()
        .build();

    ngOnInit(): void {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._formService.editGroup);
            }
        });

        this._formRevisionSub = this._formService.formRevision$.subscribe(() => {
            // initial load
            this._setRowData();
        });

        this._assessorSub = this._formService.assessor$.subscribe(() => {
            // assessor changed
            this._setRowData();
        });

        this._assessorsSub = this._formService.assessors$.subscribe(() => {
            // assessors changed (add/remove/change defaults)
            this._setRowData();
        });

        this._formRevisionYearSub = this._formService.formRevisionYear$.subscribe(() => {
            // year changed
            this._setRowData();
        });

        this._schedulesSub = this._formService.schedules$.subscribe(() => {
            // schedules changed
            this._setRowData();
        });

        this._factorTablesSub = this._formService.factorTables$.subscribe(() => {
            // factor tables changed
            this._setRowData();
        });
    }

    ngOnDestroy(): void {
        this._editModeSub && this._editModeSub.unsubscribe();
        this._formRevisionSub && this._formRevisionSub.unsubscribe();
        this._schedulesSub && this._schedulesSub.unsubscribe();
        this._assessorSub && this._assessorSub.unsubscribe();
        this._assessorsSub && this._assessorsSub.unsubscribe();
        this._factorTablesSub && this._factorTablesSub.unsubscribe();
        this._reportsSub && this._reportsSub.unsubscribe();
        this._formRevisionYearSub && this._formRevisionYearSub.unsubscribe();
        this._mutexService.release(this, this._formService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        super.setGridApi(event.api);

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth,
                editable: () => this._canEditSchedule.bind(this),
                cellEditorFramework: FormScheduleListGridInputCellRendererComponent,
                cellEditorParams: {
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    onValueChanged: (params: FormScheduleListGridInputCellRendererParams, value: string) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.name = value;
                        schedule.changed = true;
                    }
                } as FormScheduleListGridInputCellRendererParams
            },
            {
                headerName: 'Abbr',
                field: 'abbr',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                editable: () => this._canEditSchedule.bind(this),
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellEditorFramework: FormScheduleListGridInputCellRendererComponent,
                cellEditorParams: {
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    onValueChanged: (params: FormScheduleListGridInputCellRendererParams, value: string) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.abbr = value;
                        schedule.changed = true;
                    }
                } as FormScheduleListGridInputCellRendererParams
            },
            {
                headerName: 'Description',
                field: 'description',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                editable: () => this._canEditSchedule.bind(this),
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellEditorFramework: FormScheduleListGridInputCellRendererComponent,
                cellEditorParams: {
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    onValueChanged: (params: FormScheduleListGridInputCellRendererParams, value: string) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.description = value;
                        schedule.changed = true;
                    }
                } as FormScheduleListGridInputCellRendererParams

            },
            {
                headerName: 'Taxable',
                field: 'isTaxable',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: FormScheduleListGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: (params: FormScheduleListGridCheckboxCellRendererParams, value: boolean) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.isTaxable = value;
                        schedule.changed = true;

                        if (!schedule.isTaxable && schedule.isDepreciable) {
                            schedule.isDepreciable = false;

                            // remove any schedule factor tables
                            schedule.scheduleFactorTables = [];
                            schedule.defaultFactorTable = null;
                            schedule.isFactorTableLocked = false;

                            this._gridApi.redrawRows({ rowNodes: [params.node] });
                        }
                    },
                    isVisible: (params: FormScheduleListGridCheckboxCellRendererParams) => true,
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable()
                } as FormScheduleListGridCheckboxCellRendererParams,
            },
            {
                headerName: 'Depreciable',
                field: 'isDepreciable',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: FormScheduleListGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: (params: FormScheduleListGridCheckboxCellRendererParams, value: boolean) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.isDepreciable = value;
                        schedule.changed = true;

                        if ((!schedule.isDepreciable) && schedule.defaultFactorTable) {
                            // if changing to non-depreciable, remove schedule factor tables
                            schedule.scheduleFactorTables = [];
                            this._setDefaultFactorTable(schedule);
                            this._gridApi.redrawRows({ rowNodes: [params.node] });
                        }
                    },
                    isVisible: (params: FormScheduleListGridCheckboxCellRendererParams) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return false;
                        }
                        return schedule.isTaxable;
                    },
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable()
                } as FormScheduleListGridCheckboxCellRendererParams,
            },
            {
                headerName: 'Dep. Table',
                field: 'defaultFactorTable',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const schedule = params.node.data as FormRevisionScheduleRowModel;
                    if (!(schedule && schedule.defaultFactorTable)) {
                        return '';
                    }

                    return `${schedule.defaultFactorTable.factorTableName}  (${schedule.defaultFactorTable.life} yr life)`;
                },
                cellRendererFramework: FormScheduleListGridFactorTableDropdownCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: (params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams, value: Compliance.FormFactorTableModel) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.defaultFactorTable = value;
                        schedule.changed = true;

                        this._defaultFactorTableChanged(schedule);
                    },
                    canEdit: (params: FormScheduleListGridFactorTableDropdownCellRendererComponentParams) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return false;
                        }
                        if (schedule.isFactorTableLocked) {
                            return false;
                        }

                        return this._canEditSchedule(params) && schedule.isDepreciable;
                    },
                    editMode$: this._editModeSubject.asObservable(),
                    factorTables$: this._factorTablesSubject.asObservable()
                } as FormScheduleListGridFactorTableDropdownCellRendererComponentParams,
            },
            {
                headerName: 'Table Locked',
                field: 'isFactorTableLocked',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: FormScheduleListGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: (params: FormScheduleListGridCheckboxCellRendererParams, value: boolean) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.isFactorTableLocked = value;
                        schedule.changed = true;
                        this._defaultFactorTableChanged(schedule);
                    },
                    isVisible: this._isLockVisible.bind(this),
                    canEdit: this._canEditSchedule.bind(this),
                    editMode$: this._editModeSubject.asObservable()
                } as FormScheduleListGridCheckboxCellRendererParams,
            },
            {
                headerName: 'Overflow Report',
                field: 'savedSearch',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const schedule = params.node.data as FormRevisionScheduleRowModel;
                    if (!schedule) {
                        return '';
                    }

                    return this._formService.getSavedSearchReportName(schedule.savedSearch);
                },
                cellRendererFramework: FormScheduleListGridReportDropdownCellRendererComponent,
                cellRendererParams: {
                    onValueChanged: (params: FormScheduleListGridReportDropdownCellRendererParams, value: Core.SavedSearchModel) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return;
                        }

                        schedule.savedSearch = value;
                        schedule.savedSearchId = value && value.savedSearchID;

                        schedule.changed = true;
                    },
                    canEdit: (params: FormScheduleListGridReportDropdownCellRendererParams) => {
                        const schedule = params.data as FormRevisionScheduleRowModel;
                        if (!schedule) {
                            return false;
                        }

                        return this._canEditSchedule(params);
                    },
                    editMode$: this._editModeSubject.asObservable(),
                    reports$: this._reportsSubject.asObservable()
                } as FormScheduleListGridReportDropdownCellRendererParams
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: FormScheduleListGridActionCellRendererComponent,
                cellRendererParams: {
                    canDelete: (params: ICellRendererParamsForFormScheduleListGridAction) => {
                        const schedule = params.node.data as FormRevisionScheduleRowModel;
                        return this.canEdit && this.editMode && schedule && !schedule.isSystem && !schedule.deleted;
                    },
                    canUndoDelete: (params: ICellRendererParamsForFormScheduleListGridAction) => {
                        const schedule = params.node.data as FormRevisionScheduleRowModel;
                        return this.canEdit && this.editMode && schedule && !schedule.isSystem && schedule.deleted;
                    },
                    delete: this.delete.bind(this),
                    undoDelete: this.undoDelete.bind(this),
                } as ICellRendererParamsForFormScheduleListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    async save(force?: boolean): Promise<void> {
        if (!this.hasChanges()) {
            return Promise.resolve();
        }

        const schedules: Compliance.FormRevisionScheduleModel[] = [];

        let scheduleChangedToNonDepreciable = false;
        const abbrInvalid: string[] = [];
        const regexp = new RegExp('^[a-zA-Z0-9_]+$');

        this._gridApi.forEachNode((node: RowNode) => {
            const scheduleRow = node.data as FormRevisionScheduleRowModel;

            if (!scheduleRow.deleted) {
                // changed to non-depreciable?
                if (scheduleRow.originalValue && scheduleRow.originalValue.isDepreciable && !scheduleRow.isDepreciable) {
                    scheduleChangedToNonDepreciable = true;
                }

                // abbr validation
                if (!regexp.test(scheduleRow.abbr)) {
                    abbrInvalid.push(scheduleRow.abbr);
                }

                // map schedule entry
                const schedule = {
                    formRevisionScheduleId: scheduleRow.formRevisionScheduleId,
                    formRevisionId: scheduleRow.formRevisionId,
                    name: scheduleRow.name,
                    description: scheduleRow.description,
                    isTaxable: scheduleRow.isTaxable,
                    isReportable: scheduleRow.isReportable,
                    isDepreciable: scheduleRow.isDepreciable,
                    isSystem: false,
                    abbr: scheduleRow.abbr,
                    savedSearchId: scheduleRow.savedSearchId,
                    scheduleFactorTables: _.cloneDeep(scheduleRow.scheduleFactorTables)
                } as Compliance.FormRevisionScheduleModel;

                schedules.push(schedule);
            }
        });

        if (abbrInvalid.length > 0) {
            this._toastsManager.error(`Abbreviation must be alphanumeric or underscore: '${_.join(abbrInvalid, ',')}'.`);
            return Promise.resolve();
        }

        const abbrDupes = _.map(_.filter(_.groupBy(_.map(schedules, x => x.abbr.toLowerCase())), x => x.length > 1), x => x[0]);
        if (abbrDupes.length > 0) {
            this._toastsManager.error(`Abbreviation must be unique: '${_.join(abbrDupes, ',')}'.`);
            return Promise.resolve();
        }

        if (scheduleChangedToNonDepreciable) {
            try {
                await this._messageModalService.prompt('Changing a schedule to non-depreciable will result in depreciation and index tables being removed from all asset classifications mapped to that schedule. Are you sure you want to continue?');
            }
            catch (ex) {
                return Promise.resolve();
            }
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Saving Schedules' });
        let confirmMessage: string = '';

        try {
            await this._formService.updateSchedules(schedules, force);

            this._editModeSubject.next(false);
            this._mutexService.release(this, this._formService.editGroup);

            return Promise.resolve();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        }
        finally {
            busyRef.hide();
        }

        try {
            await this._messageModalService.confirm(
                confirmMessage,
                'Confirm'
            );
        }
        catch (e3) {
            return Promise.resolve();
        }

        // force
        await this.save(true);

        return Promise.resolve();
    }

    cancel(): void {
        const update: RowNode[] = [];
        const remove: FormRevisionScheduleRowModel[] = [];

        this._gridApi.forEachNode((node: RowNode) => {

            const schedule = node.data as FormRevisionScheduleRowModel;

            if (schedule && !schedule.added) {
                update.push(node);

                schedule.abbr = schedule.originalValue.abbr;
                schedule.isDepreciable = schedule.originalValue.isDepreciable;
                schedule.formRevisionId = schedule.originalValue.formRevisionId;
                schedule.formRevisionScheduleId = schedule.originalValue.formRevisionScheduleId;
                schedule.description = schedule.originalValue.description;
                schedule.isReportable = schedule.originalValue.isReportable;
                schedule.isTaxable = schedule.originalValue.isTaxable;
                schedule.name = schedule.originalValue.name;
                schedule.savedSearchId = schedule.originalValue.savedSearchId;
                schedule.scheduleFactorTables = _.cloneDeep(schedule.originalValue.scheduleFactorTables);
                schedule.changed = false;
                schedule.deleted = false;

                this._setDefaultFactorTable(schedule);
                this._setSavedSearch(schedule);

            } else { // if added, remove it
                remove.push(schedule);
            }
        });

        this._gridApi.updateRowData({ remove: remove });
        this._gridApi.redrawRows({ rowNodes: update });

        this._editModeSubject.next(false);
    }

    async edit(): Promise<void> {
        if (!this.canEdit) {
            return;
        }
        const res = await this._formService.checkEditDefaultAssessor();
        if (res) {
            this._mutexService.acquire(this, this._formService.editGroup);
            this._editModeSubject.next(true);
        }
    }

    add(): void {
        const newSchedule: FormRevisionScheduleRowModel = {
            description: '',
            name: '',
            isTaxable: true,
            isReportable: true,
            formRevisionId: this._formService.formRevisionId,
            formRevisionScheduleId: 0,
            isDepreciable: true,
            isSystem: false,
            abbr: '',
            defaultFactorTable: null,
            savedSearchId: null,
            savedSearch: null,
            isFactorTableLocked: false,
            scheduleFactorTables: [],
            changed: false,
            deleted: false,
            added: true,
            originalValue: null
        };

        this._gridApi.updateRowData({ add: [newSchedule] });

        const params: StartEditingCellParams = {
            rowIndex: 0,
            colKey: 'name'
        };

        this._gridApi.startEditingCell(params);
    }

    async delete(params: ICellRendererParamsForFormScheduleListGridAction): Promise<void> {
        const schedule = params.data as FormRevisionScheduleRowModel;
        if (!schedule) {
            return Promise.resolve();
        }

        if (!schedule.added) {
            schedule.deleted = true;
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
            this._gridApi.updateRowData({ remove: [schedule] });
        }

        return Promise.resolve();
    }

    undoDelete(params: ICellRendererParamsForFormScheduleListGridAction): void {
        const schedule = params.data as FormRevisionScheduleRowModel;
        if (!schedule) {
            return;
        }

        schedule.deleted = false;

        this._gridApi.redrawRows({ rowNodes: [params.node] });
    }

    hasChanges(): boolean {
        let hasChanges: boolean = false;

        this._gridApi.forEachNode((node: RowNode) => {
            const schedule = node.data as FormRevisionScheduleRowModel;
            if (schedule && (schedule.changed || schedule.deleted || schedule.added)) {
                hasChanges = true;
            }
        });

        return hasChanges;
    }

    export(): void {
        const params = {
            fileName: 'Schedules.csv',
            processCellCallback: (params: ProcessCellForExportParams) => {
                const field = params.column.getColDef().field;
                const schedule = params.node.data as FormRevisionScheduleRowModel;
                switch (field) {
                    case 'defaultFactorTable':
                        if (!(schedule && schedule.defaultFactorTable)) {
                            return '';
                        }
                        return `${schedule.defaultFactorTable.factorTableName}  (${schedule.defaultFactorTable.life} yr life)`;
                    case 'savedSearch':
                        if (!(schedule && schedule.savedSearch)) {
                            return '';
                        }
                        return this._formService.getSavedSearchReportName(schedule.savedSearch);
                    case 'isTaxable':
                        return schedule.isTaxable ? 'Yes' : 'No';
                    case 'isDepreciable':
                        return schedule.isDepreciable ? 'Yes' : 'No';
                    case 'isFactorTableLocked':
                        return schedule.isFactorTableLocked ? 'Yes' : 'No';
                    default:
                        return params.value;
                }
            }
        } as CsvExportParams;
        this._gridApi.exportDataAsCsv(params);
    }

    private _setFactorTables(): void {
        this.factorTables = _.filter(this._formService.factorTables, (t) => t.tableType === Compliance.FactorTableTypeEnum.Depreciation);
        this._factorTablesSubject.next(this.factorTables);
    }

    private _setReports(): void {
        this.reports = _.filter(this._formService.allReports, (x) => x.searchName === 'Return Asset Detail');
        this._reportsSubject.next(this.reports);
    }

    private _canEditSchedule(params: ICellRendererParams): boolean {
        const schedule = params.data as FormRevisionScheduleRowModel;
        if (!schedule) {
            return false;
        }

        return this.canEdit && !schedule.isSystem && !schedule.deleted;
    }

    private _isLockVisible(params: FormScheduleListGridCheckboxCellRendererParams): boolean {
        const schedule = params.data as FormRevisionScheduleRowModel;
        if (!schedule) {
            return false;
        }

        return schedule.defaultFactorTable !== null;
    }

    private _defaultFactorTableChanged(scheduleRow: FormRevisionScheduleRowModel): void {
        // if a default factor table is set, add or update schedule factor table record
        if (scheduleRow.defaultFactorTable) {

            // is there a default factor table record already?
            let scheduleFactorTable = _.find(scheduleRow.scheduleFactorTables,
                (x) => {
                    return x.formRevisionYearAssessorId === this._formService.factorTableAssessorId;
                });

            // update record
            if (scheduleFactorTable) {
                scheduleFactorTable.formFactorTableId = scheduleRow.defaultFactorTable.formFactorTableId;
                scheduleFactorTable.isLocked = scheduleRow.isFactorTableLocked;
            } else {
                // add record
                scheduleFactorTable = {
                    formRevisionScheduleFactorTableId: 0,
                    formFactorTableId: scheduleRow.defaultFactorTable.formFactorTableId,
                    formRevisionScheduleId: scheduleRow.formRevisionScheduleId,
                    formRevisionYearAssessorId: this._formService.factorTableAssessorId,
                    isLocked: scheduleRow.isFactorTableLocked
                } as Compliance.FormRevisionScheduleFactorTableModel;
                scheduleRow.scheduleFactorTables.push(scheduleFactorTable);
            }
        } else { // no default factor table, remove record
            _.remove(scheduleRow.scheduleFactorTables,
                (x) => {
                    return x.formRevisionYearAssessorId === this._formService.factorTableAssessorId;
                });
        }
    }

    private _setSavedSearch(schedule: FormRevisionScheduleRowModel): void {
        if (!schedule.savedSearchId) {
            return;
        }

        schedule.savedSearch = _.find(this._formService.allReports, x => x.savedSearchID === schedule.savedSearchId);
        if (schedule.savedSearch === null) {
            console.warn(`Could not find saved search ID ${schedule.savedSearchId}`);
        }
    }

    private _setDefaultFactorTable(schedule: FormRevisionScheduleRowModel): void {
        let isLocked: boolean = false;
        let defaultFactorTable: Compliance.FormFactorTableModel = null;

        const scheduleFactorTable = _.find(schedule.scheduleFactorTables,
            (x) => {
                return x.formRevisionYearAssessorId === this._formService.factorTableAssessorId;
            });

        if (scheduleFactorTable) {
            isLocked = scheduleFactorTable.isLocked;
            defaultFactorTable = _.find(this.factorTables,
                (x) => {
                    return x.formFactorTableId === scheduleFactorTable.formFactorTableId;
                });
        }

        schedule.isFactorTableLocked = isLocked;
        schedule.defaultFactorTable = defaultFactorTable;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        this._setFactorTables();
        this._setReports();

        const formSchedules = _.map(_.filter(this._formService.schedules, (x) => !x.isSystem),
            (schedule) => {
                const rowModel: FormRevisionScheduleRowModel = {
                    abbr: schedule.abbr,
                    isDepreciable: schedule.isDepreciable,
                    formRevisionId: schedule.formRevisionId,
                    formRevisionScheduleId: schedule.formRevisionScheduleId,
                    description: schedule.description,
                    isReportable: schedule.isReportable,
                    isTaxable: schedule.isTaxable,
                    isSystem: false,
                    name: schedule.name,
                    defaultFactorTable: null,
                    isFactorTableLocked: false,
                    savedSearchId: schedule.savedSearchId,
                    savedSearch: null,
                    scheduleFactorTables: _.cloneDeep(schedule.scheduleFactorTables),
                    changed: false,
                    deleted: false,
                    added: false,
                    originalValue: schedule,
                };

                this._setDefaultFactorTable(rowModel);
                this._setSavedSearch(rowModel);

                return rowModel;
            });

        this._gridApi.setRowData(formSchedules);
    }
}
