import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { WeissmanMutexService } from '../../WeissmanMutexService';
import { AppealLevel } from '../../../Annual-Details/Appeals/appeal.model';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'form-appeal-level-list',
    templateUrl: './formAppealLevelList.component.html',
    styleUrls: ['./formAppealLevelList.component.scss']
})
export class FormAppealLevelListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _formService: FormService,
        private readonly _toastsManager: ToastrService
    ) { }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    appealLevels: AppealLevel[] = [];

    appealLevelId: UntypedFormControl = new UntypedFormControl({ disabled: !this.canEnterEditMode });

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._formService.formRevision$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // initial load
            this.appealLevels = [...this._formService.appealLevels];
            if (this.appealLevels.length) {
                this.appealLevelId.setValue(this._formService.appealLevelId);
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async onSelectedAppealLevelChange(appealLevel: AppealLevel): Promise<void> {
        if (!appealLevel) {
            return;
        }
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });
        try {
            await this._formService.setFormRevisionAppealLevel(appealLevel.appealLevelID);
            this._toastsManager.success('Appeal level successfully set');
        }
        finally {
            busyRef.hide();
        }
    }
}
