<div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="state">
    <div class="ws-flex-container-horizontal ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="state.fullName"></ws-bread-crumbs>
        </div>
    </div>

    <div class="ws-section ws-flex-none">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">{{state.fullName}}</h4>
        </div>
    </div>

    <div class="row equal-panel">
        <div class="col-xl-6">
           <state-details [state]="state"></state-details>
        </div>
        <div class="col-xl-6">
            <deadline-list-panel [stateId]="state.stateID"></deadline-list-panel>
        </div>
    </div>
    <div class="row">
		<div class="col-xl-12">
			<assessment-class-list-panel [state]="state"></assessment-class-list-panel>
		</div>
	</div>
    <div class="row">
		<div class="col-xl-12">
			<protocol-list-panel [state]="state"></protocol-list-panel>
		</div>
	</div>
    <div class="row">
		<div class="col-xl-12">
			<contacts-panel *ngIf="entity" [entity]="entity"></contacts-panel>
		</div>
	</div>
</div>

