import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AssessmentSummaryInfo } from './assessmentSummaryInfo';
import { AllocationDetailsService } from './allocationDetails.service';

export interface ICellRendererParamsForAssessmentSummaryHeaderRenderer extends ICellRendererParams {
    showLockedAssessmentsChange: (show: boolean) => void;
    clearFilter: () => void;
}

@Component({
    selector: 'grid-action-cell-header',
    template: `
        <span class="summary-action-button lock-button clickable"
              id="lockAssessment"
              (click)="lockAssessmentClicked()"
              helpTooltip
              tooltipText="Hide locked assessments">
            <i class="fa" [ngClass]="{ 'fa-eye': showLockedAssessments, 'fa-eye-slash': !showLockedAssessments}"></i>
        </span>
        <span class="summary-action-button info-button clickable"
              [class.active]="filterActive"
              [class.disabled]="!filterActive"
              (click)="clearFilter()"
              helpTooltip
              position="bottom"
              helpContentId="app.clear-filter">
            <i class="fa fa-filter"></i>
        </span>
        <div style="width: 16px; height: 20px;"></div>
    `
})
export class AgGridAssessmentSummaryActionHeaderRendererComponent implements ICellRendererAngularComp {
    constructor(private readonly _allocationDetailService: AllocationDetailsService) { }

    private _params: ICellRendererParamsForAssessmentSummaryHeaderRenderer;

    summary: AssessmentSummaryInfo;
    showLockedAssessments: boolean = true;

    get filterActive(): boolean {
        return !!this._allocationDetailService.allocationDetailsFilter;
    }

    agInit(params: ICellRendererParamsForAssessmentSummaryHeaderRenderer): void {
        this._params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    lockAssessmentClicked(): void {
        this.showLockedAssessments = !this.showLockedAssessments;
        this._params.showLockedAssessmentsChange(this.showLockedAssessments);
    }

    clearFilter(): void {
        if (this.filterActive) {
            this._params.clearFilter();
        }
    }
}
