import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';;
import { AccrualsDescriptorsModalComponent } from './accruals.descriptors.modal.component';
import * as _ from 'lodash';

@Component({
    selector: "accruals-descriptors-link",
    template: `
            <a (click)="launchModal()" class="pointer">Site/Parcel Characteristics</a>
        `
})
export class AccrualsDescriptorsLinkComponent {
    @Input() companyId: number;
    @Input() options: any;
    @Input() editMode: boolean;
    @Input() flatInUseDescriptors: Core.CompanyAccrualDescriptorModel[]

    constructor(private modalService: BsModalService) { }

    launchModal() {
        const initialState = {
            companyId: this.companyId,
            accrualDescriptors: _.cloneDeep(this.options.accrualDescriptors),
            canEdit: this.editMode,
            flatInUseDescriptors: this.flatInUseDescriptors
        };

        const modalRef = this.modalService.show(AccrualsDescriptorsModalComponent, { initialState, ignoreBackdropClick: true });

        modalRef.content.onClose = (descriptors: Core.CompanyAccrualDescriptorModel[]) => {
            console.log(descriptors)
            this.options.accrualDescriptors = descriptors
        }
    }

}
