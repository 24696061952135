import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager } from '../../../../../Busy-Indicator';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GridLayoutSpecificationRepository } from '../../../../Repositories/gridLayoutSpecificationRepository';
import * as _ from 'lodash';
import { AgGridLayoutSpecificationListItemModel, AgGridLayoutSpecificationVisibilityModel } from '../../models';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';

export interface AgGridLayoutSpecificationDetailsParams {
    specification: AgGridLayoutSpecificationListItemModel;
    userId: System.Guid;
    visibilities: AgGridLayoutSpecificationVisibilityModel[];
    companyId: number;
}

@Component({
    templateUrl: './agGridLayoutSpecificationDetails.component.html',
    selector: 'ag-grid-layout-specification-details',
    styleUrls: ['./agGridLayoutSpecificationDetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AgGridLayoutSpecificationDetailsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AgGridLayoutSpecificationDetailsParams, Compliance.GridLayoutSpecificationModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _gridLayoutSpecificationRepository: GridLayoutSpecificationRepository) {
    }

    params: AgGridLayoutSpecificationDetailsParams;
    result: Compliance.GridLayoutSpecificationModel;

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    form: UntypedFormGroup;

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            layoutName: [null, [Validators.required]],
            visibility: [null, [Validators.required]],
            description: [null, [Validators.maxLength(50)]],
        });

        this.form.setValue({
            layoutName: this.params.specification.name,
            description: this.params.specification.description,
            visibility: this.params.specification.visibilityItem
        });
    }

    ngOnDestroy(): void { }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        try {
            const visibilityItem = this.form.get('visibility').value;

            const updateModel: Compliance.GridLayoutSpecificationModel = _.extend(
                {} as Compliance.GridLayoutSpecificationModel,
                this.params.specification,
                {
                    name: this.form.get('layoutName').value,
                    description: this.form.get('description').value,
                    visibility: visibilityItem.visibility,
                    isDefault: visibilityItem.isDefault,
                    companyId: visibilityItem.isCompany ? this.params.companyId : null,
                    userId: visibilityItem.visibility === Compliance.SpecificationVisibilityEnum.Private ? this.params.userId: null
                });

            if (updateModel.gridLayoutSpecificationId === 0) {
                this.result = await lastValueFrom(this._gridLayoutSpecificationRepository.create(updateModel));
            } else {
                this.result = await lastValueFrom(this._gridLayoutSpecificationRepository.update(updateModel));
            }

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
