<div class="d-flex align-items-center">
    <label class="pe-1 mt-2" for="selectedAssessor">Assessor:</label>
    <div class="d-flex">
        <div class="form-container">
            <ws-select labelProperty="assessorName"
                       [returnEntireOption]="true"
                       [options]="assessors"
                       [formControl]="selectedAssessor"
                       id="selectedAssessor"
                       searchType="substring"
                       (selectedOptionChanged)="selectedAssessorChanged($event)">
            </ws-select>
        </div>
    </div>
</div>
