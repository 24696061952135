export enum AccrualsGridSummarizeByEnum {
    Bills = 0,
    Parcel = 1,
    Site = 2,
    Company = 3,
    EconomicUnit = 4,
    State = 5
}

export enum AccrualsGridViewModeEnum {
    SinglePeriod = 1,
    PeriodRange = 2
}

export enum AccrualsGridBillRecurTypeEnum {
    Both = 0,
    Recurring = 1,
    OneTime = 2
}

export enum ColumnFiltersOptionsEnum {
    Basics,
    All,
    Hide
}

export enum JournalOptionsEnum {
    Hide,
    ShowByAccountType,
    ShowByAccountNumber
}

export class AccrualsGridAvailableFiltersModelUI implements Core.AccrualsGridAvailableFiltersModel {
    constructor() {
        this.companies = [];
        this.states = [];
        this.propertyTypes = [];
    }
    companies: Core.AccrualsGridCompanyFilterModel[];      // TL Company & Subsidiaries OR given Subsidiary OR parent Company of Site
    states?: Core.NameIdPair[];         // States available for given Site, or all Sites within the Companies.
    propertyTypes?: Core.NameIdPair[];  // The 4 Property Types (RE, PP, MN, CA)
}

export class AccrualsColumnFiltersUI {
    constructor() {
        this.openTaxObligations = ColumnFiltersOptionsEnum.Basics;
        this.periodContributions = ColumnFiltersOptionsEnum.Basics;
        this.accrualBalances = ColumnFiltersOptionsEnum.All;
        this.journalImpact = JournalOptionsEnum.ShowByAccountType;
        this.journalBalances = JournalOptionsEnum.ShowByAccountType;
        this.changeDetection = ColumnFiltersOptionsEnum.Hide;
    }

    openTaxObligations: ColumnFiltersOptionsEnum;
    periodContributions: ColumnFiltersOptionsEnum;
    accrualBalances: ColumnFiltersOptionsEnum;
    journalImpact: JournalOptionsEnum;
    journalBalances: JournalOptionsEnum;
    changeDetection: ColumnFiltersOptionsEnum;

}