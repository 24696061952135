import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { ValuationTemplateService } from '../valuationTemplate.service';
import { AddEditValuationTemplateComponent } from '../Add-Edit-Tempalate/addEditValuationTemplate.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { VALUATION_TEMPLATE_HELP } from '../valuationTemplate.help';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'valuation-template-detail',
    templateUrl: './valuationTemplateDetail.component.html',
    styleUrls: ['./valuationTemplateDetail.component.scss']
})
export class ValuationTemplateDetailComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _helpService: HelpService,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastsManager: ToastrService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) {}

    breadcrumbs: BreadCrumb[] = [
        {
            name: 'Templates',
            target: 'valuationTemplates',
            options: {}
        }
    ];
    template: Core.ValuationTemplateModel;

    private _templateId: number;
    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._helpService.setContent(VALUATION_TEMPLATE_HELP);
        this._templateId = +this._routerService.getQuerystringParam('templateId');

        this._valuationTemplateService.template$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.template = x);

        this.refresh();
    }

    ngOnDestroy(): void {
        this._valuationTemplateService.template = null;
        this._valuationTemplateService.setExpandedComponent(null);
        this._destroy$.next();
        this._destroy$.complete();
    }

    async refresh(): Promise<void> {
        try {
            this._valuationTemplateService.template = await this._valuationTemplateService.getTemplateById(this._templateId);
        } catch(err) {}
    }

    async editTemplateName(): Promise<void> {
        try {
            const params = {
                template: this.template
            }

            const result = await this._modalService.showAsync(AddEditValuationTemplateComponent, params, 'modal-md');

            if (result) {
                this.template = result;
            }
        } catch(err) {}
    }

    async onIsInDevelopmentChange(isInDevelopmentInput: boolean): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            var result = await this._valuationTemplateService.updateIsInDevelopment(this.template.valuationTemplateId, isInDevelopmentInput);
            
            if (result)
            {
                this.template = result;
                if(result.isInDevelopment == isInDevelopmentInput)
                {
                    this._toastsManager.success(`Template set as ${this.template.isInDevelopment ? '' : 'not '}in development`);
                }
                else
                {
                    var duplicates = await this._valuationTemplateService.getTemplateNamesWithDuplicateSettings(this.template.valuationTemplateId);
                    if(duplicates)
                    {
                        duplicates = duplicates.map(x=> x =`"${x}"`);
                        this._toastsManager.error(`Template already exists for this configuration in ${duplicates.join(', ')}.`);
                    }
                    else
                    {
                        this._toastsManager.error(`Unable to update development settings for template.`);
                    }
                }
            }
        }
        finally {
            busyRef.hide();
        }
    }

    isHidden(componentName: string): boolean {
        return this._valuationTemplateService.isOtherComponentExpanded(componentName);
    }

    areHidden(componentNames: string[]): boolean {
        return componentNames.every(x => this.isHidden(x));
    }
}
