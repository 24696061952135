import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const IMPORT_LIST_HELP: HelpContent[] = [
    {
        helpContentId: 'import-list.completed',
        tooltipText: 'Include data imports where all rows have been transferred or the data import has been marked completed.',
        hasIcon: true
    },
    {
        helpContentId: 'import-list.new-data-import',
        tooltipText: 'New data import'
    },
    {
        helpContentId: 'import-list.data-import-specifications',
        tooltipText: 'View and edit data import specifications.'
    },
    {
        helpContentId: 'import-list.data-import-specifications-company',
        tooltipText: 'View and edit data import specifications used in this company.'
    },
    {
        helpContentId: 'import-list.resume',
        tooltipText: 'Resume data import'
    },
    {
        helpContentId: 'import-list.revert',
        tooltipText: 'Revert database changes made by data import.'
    },
    {
        helpContentId: 'import-list.revert-disabled',
        tooltipText: 'Data import cannot be reverted.'
    },
    {
        helpContentId: 'import-list.mark-completed',
        tooltipText: 'Mark data import as completed.'
    },
    {
        helpContentId: 'import-list.mark-completed-disabled',
        tooltipText: 'Data import cannot be marked as completed if already completed or no rows have been transferred.'
    },
    {
        helpContentId: 'import-list.delete-disabled',
        tooltipText: 'Data import cannot be deleted if it is being processed or any rows have been transferred.'
    }
];
