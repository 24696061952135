import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'forecast-create-missing',
    templateUrl: './forecast.create.missing.component.html',
    styleUrls: ['./forecasting.budgeting.page.scss']
})
export class ForecastCreateMissingComponent {
    @Input() numMissingTaxYears: number;
    @Input() lrUpdateInProgress: boolean = false;
    @Input() taxYear: number;
    @Input() editMode: boolean;
    @Output() createClick = new EventEmitter<void>();

    createMissing() : void {
        this.createClick.next();
    }

    isMissingCreateAllowed() : boolean {
        return !this.lrUpdateInProgress &&
               this.numMissingTaxYears > 0;
    }
}