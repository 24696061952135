
import {empty as observableEmpty,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CachedRepository } from '../../Common/Data/cached.repository';
import { HttpClient } from '@angular/common/http';
import { DataCache } from '../../Common/Data/dataCache';

const  cacheAreaName = 'IncomeStatementCatgories';

@Injectable()
export class IncomeStatementCategoryRepository extends CachedRepository<Core.IncomeStatementCategoryModel> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
        this.dataCache = new DataCache(httpClient, cacheAreaName);
    }

    getIncomeStatementCategories(): Observable<Core.IncomeStatementCategoryModel[]> {
        return this.getCached<Core.IncomeStatementCategoryModel[]>(`/api/incomeStatementCategory`);
    }
    
    // getIncomeStatementCategoryById(id: number): Observable<Core.IncomeStatementCategoryModel> {
    //     if (id == null) {
    //         return observableEmpty();
    //     }
    //     return this.getCached<Core.IncomeStatementCategoryModel[]>(`/api/incomeStatementCategory`).pipe(map(result => {
    //         const classification = this._findClassificationById(id, result);
    //         return classification;
    //     }));
    // }

    // private _findClassificationById(id: number, classifications: Core.IncomeStatementCategoryModel[]): Core.IncomeStatementCategoryModel {
    //     if (classifications.length === 0) {
    //         return null;
    //     }
        
    //     const foundClassification = classifications.find(ac => ac.incomeStatementCategoryId === id);
    //     if (foundClassification) {
    //         return foundClassification;
    //     }
        
    //     let childClassifications = [];
    //     classifications.forEach(ac => {
    //         if (ac.childClassifications) {
    //             childClassifications = childClassifications.concat(ac.childClassifications);
    //         }
    //     });
    //     return this._findClassificationById(id, childClassifications);
    // }
}
