import { forwardRef, NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AgGridModule } from 'ag-grid-angular'
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component'
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { ValuationModule } from '../../Valuation/valuation.module';
import { AssetClassSelectorModule } from '../Asset/Asset-Class-Selector/assetClassSelector.module';
import { GLAccountListComponent } from './GL-Account-List/glAccountList.component';
import { GLAccountDetailsComponent } from './GL-Account-Details/glAccountDetails.component';
import { AssetModule } from '../Asset/asset.module'
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { GLAccountBulkUpdateComponent } from './GL-Account-List/GL-Account-Bulk-Update/glAccountBulkUpdate.component';
import { GLAccountBulkUpdateActionComponent } from './GL-Account-List/GL-Account-Bulk-Update/glAccountBulkUpdateAction.component';
import { GLAccountService } from './glAccount.service';
import { GLAccountBulkUpdateIncomeStatementTypesComponent } from "./GL-Account-List/GL-Account-Bulk-Update/glAccountBulkUpdateIncomeStatementTypes.component";


@NgModule({
    imports: [
        WeissmanSharedModule,
        SharedPipesModule,
        AssetModule,
        AssetClassSelectorModule,
        ValuationModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
        ])
    ],
    declarations: [
        GLAccountListComponent,
        GLAccountDetailsComponent,
        GLAccountBulkUpdateComponent,
        GLAccountBulkUpdateActionComponent,
        GLAccountBulkUpdateIncomeStatementTypesComponent
    ],
    providers: [
        GLAccountService
    ],
    exports: [
        GLAccountListComponent,
        GLAccountDetailsComponent
    ]
})
export class GLAccountModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('glAccountList', GLAccountListComponent);
        hybridAdapterUtility.downgradeNg2Component('glAccountDetails', GLAccountDetailsComponent);
    }
}
