import { AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';
import { IDoesFilterPassParams, IFilterOptionDef } from 'ag-grid-community';
import { FilterUIModel, FilterValueModel } from "../filterUI.model";
import { AgGridFilterBase, WSTextFilterParams } from "../agGridFilterBase";

@Component({
    selector: 'ws-ag-grid-text-filter',
    templateUrl: './agGridTextFilter.component.html',
    styleUrls: ['../agGridFilters.scss']
})
export class AgGridTextFilterComponent extends AgGridFilterBase<WSTextFilterParams> implements AfterViewInit {
    constructor(private _el: ElementRef) {
        super();
    }

    @ViewChild('applyButton', { read: ViewContainerRef }) applyButton;

    selectFilterOptions: IFilterOptionDef[] = [];
    filterOptions: Array<string | IFilterOptionDef> = [];
    filterConditionType: Core.FilterConditionTypeEnum = Core.FilterConditionTypeEnum.None;
    filterValues: FilterValueModel[] = [];
    maxHeight: string = '500px';

    agInit(params: WSTextFilterParams): void {
        this._params = params;
        this._valueGetter = params.valueGetter;
        this._filterValueGetter = params.colDef.filterValueGetter;

        this._searchTypeMap = new Map([
            ['startsWith', (fv, cv) => cv.startsWith(fv)],
            ['equals', (fv, cv) => cv === fv],
            ['notEqual', (fv, cv) => cv !== fv],
            ['contains', (fv, cv) => cv.indexOf(fv) >= 0],
            ['notContains', (fv, cv) => cv.indexOf(fv) === -1],
            ['endsWith', (fv, cv) => cv.endsWith(fv)]
        ]);
        this._defaultFilterOptions = [
            'startsWith',
            'equals',
            'notEqual',
            'contains',
            'notContains',
            'endsWith'
        ];

        this.filterOptions = (params.filterOptions) ? params.filterOptions : this._defaultFilterOptions;
        this.selectFilterOptions = this.filterOptions.map(this._formatOption.bind(this));

        this.filterValues = [{ filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions) }];
    }

    ngAfterViewInit(): void {
        const grid = this._el.nativeElement.closest('.grid-wrapper');
        if (grid && grid.offsetHeight < 500) {
            this.maxHeight = `${grid.offsetHeight - 70}px`;
        }
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        if (!this.filterValues[0].filterType) { return true; }
        const filterTest = x => {
            let filter: string[];
            if (x.filterType.displayKey === 'equals' ||
                x.filterType.displayKey === 'notEqual' ||
                x.filterType.displayKey === 'startsWith' ||
                x.filterType.displayKey === 'endsWith' ||
                x.filterType.displayKey === 'Comma Separated' ||
                x.filterType.displayKey === 'notContains') {
                filter = [(x.filterValue || '').toLowerCase()];
            } else {
                filter =(x.filterValue || '').toLowerCase().split(' ');
            }
            return filter
                .every((filterValue: string) => {
                    let value = '';
                    if (this._filterValueGetter) {
                        const filterParams = { ...params, getValue: this._valueGetter, valueGetterParams: {...this._params, ...params} } as any;
                        value = (typeof this._filterValueGetter === 'string') ? this._filterValueGetter : this._filterValueGetter(filterParams);
                    } else {
                        value = this._valueGetter(params.node);
                    }

                    const cellValue = (value) ? value.toString().toLowerCase() : '';
                    if (this._params.textCustomComparator) {
                        return this._params.textCustomComparator(x.filterType.displayKey, cellValue, filterValue, params.node.data);
                    } else {
                        return x.filterType.test(filterValue, cellValue);
                    }
                });
        }
        return this.filterConditionType === Core.FilterConditionTypeEnum.Or
            ? this.filterValues.some(filterTest) : this.filterValues.every(filterTest);

    }

    getModel(): FilterUIModel {
        if (this.filterValues.length > 1) {
            this.filterValues = this.filterValues.some(x => !!(x.filterValue || x.filterType.hideFilterInput))
                ? this.filterValues.filter(x => !!(x.filterValue || x.filterType.hideFilterInput))
                : [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        }
        this.filterConditionType = this.filterValues.length > 1 ? this.filterConditionType : Core.FilterConditionTypeEnum.None;
        return ((this.filterValues.length && this.filterValues.some(x => !!x.filterValue)) || (this.filterValues[0].filterType && this.filterValues[0].filterType.hideFilterInput))
            ? {
                filterType: 'text',
                filterConditionType: this.filterConditionType,
                filterValues: this.filterValues
            }
            : null;
    }

    setModel(model: FilterUIModel): void {
        this._appliedModel = model;

        this.filterValues = [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        this.filterConditionType = model ? model.filterConditionType : Core.FilterConditionTypeEnum.None;
        if (!model) { return; }
        if (model && model.filterValues) {
            this.filterValues = model.filterValues.map(x => ({filterValue: x.filterValue, filterType: this.selectFilterOptions.find(y => y.displayKey === x.filterType.displayKey)}));
        }
    }
}
