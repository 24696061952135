<div class="card panel-flat-color primary-panel">
    <div class="panel-flat-header">
        <div style="display: inline-block; vertical-align: middle;">
            <h3 class="panel-title">Collector Details</h3>
        </div>
        <div class="float-end header-button-container">
            <button class="btn flat-button icon-button primary-button"
                    *ngIf="!(editMode || viewOnly)"
                    (click)="edit()">
                <i class="fa fa-pencil"></i>
            </button>
            <span *ngIf="editMode" class="float-end">
                <button class="btn flat-button icon-button success-button" (click)="save()">
                    <i class="fa fa-save"></i>
                </button>
                <button class="btn flat-button icon-button warning-button" (click)="cancel();">
                    <i class="fa fa-close"></i>
                </button>
            </span>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="panel-flat-body">
        <dl class="row" *ngIf="collector">
            <dt class="col-4 text-end">Abbr:</dt>
            <dd class="col-8">
                <div *ngIf="!editMode">
                    {{collector.abbr}} {{collector.municipalityName}}
                </div>
                <div *ngIf="editMode">
                    <input type="text" class="form-control" [(ngModel)]="editingCollector.abbr">
                </div>
            </dd>
            <dt class="col-4 text-end">Full Name:</dt>
            <dd class="col-8">
                <div *ngIf="!editMode">
                    {{collector.name}}
                </div>
                <div *ngIf="editMode">
                    <input type="text" class="form-control" [(ngModel)]="editingCollector.name">
                </div>
            </dd>
            <dt class="col-4 text-end">Website:</dt>
            <dd class="col-8">
                <div *ngIf="!editMode">
                    <a [href]="collector.website | wsAbsoluteUrl" target="_blank" >{{collector.website}}</a>
                </div>
                <div *ngIf="editMode">
                    <input type="text" class="form-control" [(ngModel)]="editingCollector.website">
                </div>
            </dd>
            <dt class="col-4 text-end">Annual Rate Increase:</dt>
            <dd class="col-8">
                <div *ngIf="!editMode">
                    {{collector.annualRateIncrease | percent: '1.0-3'}}
                </div>
                <div *ngIf="editMode">
                    <input type="number"
                           class="form-control"
                           [(ngModel)]="editingCollector.annualRateIncrease"
                           [numbersOnly]>
                </div>
            </dd>
            <dt class="col-4 text-end">PTX ID:</dt>
            <dd class="col-8">
                <div *ngIf="!editMode">
                    {{collector.ptxId}}
                </div>
                <div *ngIf="editMode">
                    <input type="text" class="form-control" [(ngModel)]="editingCollector.ptxId">
                </div>
            </dd>
            <dt class="col-4 text-end">Default Address:</dt>
            <dd class="col-8">
                <address-view-edit [address]="defaultAddress" [isEditMode]="false"></address-view-edit>
            </dd>
            <dt class="col-4 text-end">Addresses:</dt>
            <dd class="col-8">
                <a class="btn btn-link pointer p-0" (click)="launchAddressDetailsModal()">
                    Addresses ({{ addressesLength }})
                </a>
            </dd>
        </dl>
        <div class="text-end ws-button-row" style="justify-content: end;" *ngIf="!editMode">
            <entity-comments [hasComments]="collector.hasComments"
                             buttonClass="icon-button-large"
                             [getModalData]="getCommentsModalData">
            </entity-comments>
            <entity-attachments buttonClass="icon-button-large"
                [(hasAttachments)]="collector.hasAttachments"
                [modalData]="attachmentsModel">
            </entity-attachments>
        </div>
    </div>
</div>
