'use strict';

angular.module('weissmanApp')
    .filter('weissmanStringContains', function(){
        return function (input, filter) {
            if (!filter || (filter.trim().length === 0)) return input;
            if (!input) return null;

            return input.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ? input : null;
        }
    })
    .filter('weissmanArrayContains', function(){
        return function (input, filter, index) {
            if (!filter || (filter.trim().length === 0)) return input;
            if (!input) return [];

            var filtered = [];
            var i = input.length;

            if (index) {
                while (i--) {
                    if (input[i] && input[i][index] && input[i][index].toLowerCase().indexOf(filter.toLowerCase()) !== -1) filtered.push(input[i]);
                }
            } else {
                while (i--) {
                    if (input[i].toLowerCase().indexOf(filter.toLowerCase()) !== -1) filtered.push(input[i]);
                }
            }

            return filtered;
        }
    });
