<loading-spinner [show]="loading" [size]="2" [hasOverlay]="true"></loading-spinner>
<table class="table table-striped" style="width: 500px;">
    <thead>
        <tr>
            <th>Acct. Period</th>
            <th style="text-align: right;">Adjustments</th>
            <th style="text-align: right;">Accrual Balance</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of balances">
            <td>{{row.accountingPeriodName}}</td>
            <td style="text-align: right;"><parenthetical-currency [value]="row.adjustmentsTotal"></parenthetical-currency></td>
            <td style="text-align: right;"><parenthetical-currency [value]="row.accrualBalance"></parenthetical-currency></td>
        </tr>
    </tbody>
</table>
