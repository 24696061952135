import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { CommandViewControlsComponent } from './Command-View-Controls/viewControls.component';
import { StateJurisdictionCommandCenterComponent } from './stateJurisdiction.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { StateJurisdictionBulkUpdateComponent } from './Bulk-Update/stateJurisdictionBulkUpdate.component';
import { BusyIndicatorModule } from '../../Busy-Indicator';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        WeissmanSharedModule,
        AgGridExtensionsModule,
        AgGridModule,
        BusyIndicatorModule.forRoot()
    ],
    declarations: [
        CommandViewControlsComponent,
        StateJurisdictionCommandCenterComponent,
        StateJurisdictionBulkUpdateComponent
    ],
    exports: [StateJurisdictionCommandCenterComponent]
})
export class StateJurisdictionModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('stateJurisdictionCommandCenter', StateJurisdictionCommandCenterComponent);
    }
}
