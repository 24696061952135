import { Component } from '@angular/core';
import { HelpContentComponent, HelpContentRendererValue } from '../../UI-Lib/Help-Tooltip';

export interface StampTooltipComponentParams extends HelpContentRendererValue<any> {}

@Component({
    selector: 'stamp-tooltip',
    template: `
        <div *ngIf="data.activatedDateTime > data.inactivatedDateTime">
            <div *ngIf="data.activatedBy">
                Activated by {{data.activatedBy}} <br>
                Date: {{data.activatedDateTime | date:'M/d/yyyy'}}
            </div>
            <div *ngIf="data.inactivatedBy || data.inactivatedDateTime" style="margin-top: 10px;">
                Inactivated <span *ngIf="data.inactivatedBy">by {{data.inactivatedBy}}</span> <br>
                Date: {{data.inactivatedDateTime | date:'M/d/yyyy'}}
            </div>
        </div>
        <div *ngIf="data.activatedDateTime <= data.inactivatedDateTime">
            <div *ngIf="data.inactivatedBy || data.inactivatedDateTime" style="margin-top: 10px;">
                Inactivated <span *ngIf="data.inactivatedBy">by {{data.inactivatedBy}}</span> <br>
                Date: {{data.inactivatedDateTime | date:'M/d/yyyy'}}
            </div>
            <div *ngIf="data.activatedBy">
                Activated by {{data.activatedBy}} <br>
                Date: {{data.activatedDateTime | date:'M/d/yyyy'}}
            </div>
        </div>
    `
})
export class StampTooltipComponent implements  HelpContentComponent<StampTooltipComponentParams> {

    params: StampTooltipComponentParams;
    data: any;

    helpInit(params: StampTooltipComponentParams) {
        this.data = params.value;
    }
}
