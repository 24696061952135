import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DXPService {
    constructor(private httpClient: HttpClient) { }

    async getDXPMode() {
        return await lastValueFrom(this.httpClient.get('/api/DXPState/mode')) as Promise<Core.DXPSyncModes>;
    }

    async testDXPSync() {
        return await lastValueFrom(this.httpClient.post('/api/DXPState/testsync', null)) as Promise<void>;
    }

    async IsSyncDxpLocked(companyId) {
        return await lastValueFrom(this.httpClient.get(`/api/company/${companyId}/SyncDxpLocked`)) as Promise<boolean>;
    }
}
