import { Injectable } from '@angular/core';
import { AppealFormItem, AppealFormOverrideModel, AppealFormSettingLookupOption, AppealFormSettings } from './appeal.application.model';
import { Subject, Observable, lastValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AppealApplicationRepository } from '../Core-Repositories';
import AppealsDownloadRequest = Core.AppealsDownloadRequest;
import AppealFormReference = Compliance.AppealFormReference;

@Injectable()
export class AppealApplicationService {
    constructor(private _appealApplicationRepository: AppealApplicationRepository) { }

    private overrideChangeSource = new Subject<{ appealFormRevisionId: number, overridden: boolean }>();
    private applyEditsToAll: boolean = false;

    overrideChange$ = this.overrideChangeSource.asObservable();

    get shouldApplyEditsToAll(): boolean {
        return this.applyEditsToAll;
    }

    cacheForms(appealReferences: AppealFormReference[]): Promise<void> {
        return lastValueFrom(this._appealApplicationRepository.cacheForms(appealReferences));
    }

    setApplyEditsToAll(applyEditsToAll: boolean): void {
        this.applyEditsToAll = applyEditsToAll;
    }

    createNewApplication(appealId: number): Promise<number> {
        return lastValueFrom(this._appealApplicationRepository.createNewApplication(appealId));
    }

    createNewApplicationBulk(taskIds: number[]): Promise<Compliance.CreateAppealApplicationFormBulkResult[]> {
        return lastValueFrom(this._appealApplicationRepository.createNewApplicationBulk(taskIds));
    }

    getMultiAccountFormTemplate(multiAccountAppealFormReference: AppealFormReference): Promise<Blob> {
        return lastValueFrom(this._appealApplicationRepository.getMultiAccountFormTemplate(multiAccountAppealFormReference));
    }

    getFormTemplate(reference: AppealFormReference): Promise<Blob> {
        return lastValueFrom(this._appealApplicationRepository.getFormTemplate(reference));
    }

    getMultiAccountFormDefaultValues(multiAccountAppealFormReference: AppealFormReference): Promise<Compliance.ReturnFormResultModel> {
        return lastValueFrom(this._appealApplicationRepository.getMultiAccountFormDefaultValues(multiAccountAppealFormReference));
    }

    getFormDefaultValues(reference: AppealFormReference): Promise<Compliance.ReturnFormResultModel> {
        return lastValueFrom(this._appealApplicationRepository.getFormDefaultValues(reference));
    }

    getMultiAccountFormOverrides(references: AppealFormReference): Promise<AppealFormOverrideModel[]> {
        return lastValueFrom(this._appealApplicationRepository.getMultiAccountFormOverrides(references));
    }

    getFormOverrides(reference: AppealFormReference): Promise<Compliance.AppealFormOverrideModel[]> {
        return lastValueFrom(this._appealApplicationRepository.getFormOverrides(reference));
    }

    correctApplicationFormRevisions(appealIds: number[]): Promise<void> {
        return lastValueFrom(this._appealApplicationRepository.correctApplicationFormRevisions(appealIds));
    }

    createUpdateOverride(override: Compliance.AppealFormOverrideRequestModel): Promise<AppealFormOverrideModel[]> {
        return lastValueFrom(this._appealApplicationRepository.createUpdateOverride(override));
    }

    deleteOverride(override: Compliance.AppealFormOverrideRequestModel): Promise<void> {
        return lastValueFrom(this._appealApplicationRepository.deleteOverride(override));
    }

    searchByTaskIds(taskIds: number[]): Promise<Compliance.AppealApplicationTaskSearchResult[]> {
        return lastValueFrom(this._appealApplicationRepository.searchByTaskIds(taskIds));
    }

    getAppealList(appealIds: number[]): Promise<AppealFormItem[]> {
        return lastValueFrom(this._appealApplicationRepository.getAppealList(appealIds));
    }

    getAvailableSupplementForms(appealIds: number[]): Promise<Compliance.AppealSupplementalFormAssociation[]> {
        return lastValueFrom(this._appealApplicationRepository.getAvailableSupplementForms(appealIds));
    }

    getAvailableMainForms(appealIds: number[]): Promise<Compliance.AppealMainFormAssociation[]> {
        return lastValueFrom(this._appealApplicationRepository.getAvailableMainForms(appealIds));
    }

    changeSupplementalFormAssociations(request: Compliance.AppealSupplementalFormsAssociationChangeRequest): Promise<Compliance.AppealSupplementFormAssociatedChangeResponse> {
        return lastValueFrom(this._appealApplicationRepository.changeSupplementalFormAssociations(request));
    }

    changeMainFormAssociations(request: Compliance.AppealMainFormAssociationChangeRequest): Promise<Compliance.AppealMainFormAssociatedChangeResponse> {
        return lastValueFrom(this._appealApplicationRepository.changeMainFormAssociations(request));
    }

    setOverrideChange(appealFormRevisionId: number, overridden: boolean): void {
        this.overrideChangeSource.next({ appealFormRevisionId: appealFormRevisionId, overridden: overridden });
    }

    generateOutputFile(appealIds: number[]): Promise<number> {
        return lastValueFrom(this._appealApplicationRepository.generateOutputFile(appealIds));
    }

    getOutputFile(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        return this._appealApplicationRepository.getOutputFile(longRunningProcessId);
    }

    getApplicationFormLookups(): Promise<AppealFormSettingLookupOption[]> {
        return lastValueFrom(this._appealApplicationRepository.getApplicationFormLookups());
    }

    getSettings(appealIds: number[]): Promise<AppealFormSettings> {
        return lastValueFrom(this._appealApplicationRepository.getSettings(appealIds));
    }

    saveSettings(payload: any): Promise<any> {
        return lastValueFrom(this._appealApplicationRepository.saveSettings(payload));
    }

    checkIfSignatureMayBeApplied(payload: any): Promise<any> {
        return lastValueFrom(this._appealApplicationRepository.checkIfSignatureMayBeApplied(payload));
    }


    completeAppealTasks(appealIds: number[]): Promise<void> {
        return lastValueFrom(this._appealApplicationRepository.completeAppealTasks(appealIds));
    }

    setTopAppeal(request: Compliance.AppealFormSetTopAppealRequestModel): Promise<void> {
        return lastValueFrom(this._appealApplicationRepository.setTopAppeal(request));
    }

    getGroupNumberMap(): { [appealFormSettingsLookupId: number]: { title: string, settingsProp: string } } {
        return {
            1: {
                title: 'Taxpayer Name',
                settingsProp: 'ownerName'
            },
            2: {
                title: 'Taxpayer Address',
                settingsProp: 'ownerAddress'
            },
            3: {
                title: 'Agent Contact',
                settingsProp: 'agentContact'
            },
            4: {
                title: 'Agent Address',
                settingsProp: 'agentAddress'
            },
            5: {
                title: 'Agent Phone',
                settingsProp: 'agentPhone'
            },
            6: {
                title: 'Signer',
                settingsProp: 'signer'
            },
            7: {
                title: 'Signer Type',
                settingsProp: 'signerType'
            },
            8: {
                title: 'Multiple Account Mode',
                settingsProp: 'multiAccount'
            },
            9: {
                title: 'Apply Digital Signature',
                settingsProp: 'applyDigitalSignature'
            }
        };
    }

    download(request: AppealsDownloadRequest): Promise<HttpResponse<Blob>> {
        return lastValueFrom(this._appealApplicationRepository.download(request));
    }

    flattenAndDownload(request: AppealsDownloadRequest): Promise<HttpResponse<Blob>> {
        return lastValueFrom(this._appealApplicationRepository.flattenAndDownload(request));
    }

    toAppealFormReference(item: AppealFormItem): AppealFormReference | null
    {
        if (!item){
            return null;
        }
        return {
            appealId : item.appealId,
            appealFormRevisionId : item.appealFormRevisionId,
            additionalAccounts: this.hasAdditionAccounts(item) ? item.additionalAccounts.map(i => this.toAppealFormReference(i)).filter(i => !!i) : [],

        }
    }

    allAppealIds(r: AppealFormReference): Array<number>
    {
        if (!r)
        {
            return [];
        }
        return [r.appealId].concat(r.additionalAccounts.filter(aa => !!aa).map(aa => aa.appealId));
    }

    allAppealFormRevisionIds(r: AppealFormReference): Array<number>
    {
        if (!r)
        {
            return [];
        }
        return [r.appealFormRevisionId].concat(r.additionalAccounts.filter(aa => !!aa).map(aa => aa.appealFormRevisionId));
    }

    hasAdditionAccounts(item: AppealFormItem) : boolean{
        return !!item.additionalAccounts && item.additionalAccounts.length > 0
    }
}
