import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EntityCommentsButtonComponent } from './Entity-Comments-Button/entityCommentsButton.component';
import { AddCommentComponent } from './Add-Comment-Modal/addComment.component';
import { CommentsComponent } from './Modal/comments.component';
import { CommentsModalService } from './commentsModal.service';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { DirectivesModule } from '../UI-Lib/Directives/directives.module';
import { CommentsRepository } from '../Core-Repositories';
import { ModalWrapperModule } from '../UI-Lib/Modal-Wrapper/modalWrapper.module';
import { HelpModule } from '../UI-Lib/Help-Tooltip/help.module';
import { SelectModule } from '../UI-Lib/Select/select.module';
import { LoadingSpinnerModule } from '../UI-Lib/Loading-Spinner/loadingSpinner.module';
import { SharedPipesModule } from '../UI-Lib/Pipes/shared-pipes.module';
import { CommentListItemComponent } from './Modal/commentListItem.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalWrapperModule,
        HelpModule,
        SelectModule,
        LoadingSpinnerModule,
        DirectivesModule,
        SharedPipesModule
    ],
    declarations: [
        EntityCommentsButtonComponent,
        CommentsComponent,
        AddCommentComponent,
        CommentListItemComponent
    ],
    providers: [
        CommentsRepository,
        CommentsModalService
    ],
    exports: [
        CommentsComponent,
        EntityCommentsButtonComponent
    ]
})
export class CommentsModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('entityComments', EntityCommentsButtonComponent);
    }
}
