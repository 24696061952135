<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ng-template #text let-row="data" let-field="field" let-override="override" let-template="template">
    <div class="ws-flex-grow">
        <input type="text"
               autocomplete="off"
               class="form-control"
               [class.overridden]="override"
               [(ngModel)]="row[field.reportedParam]"
               [maxLength]="(template.maxLength ? template.maxLength : 1000)" />
    </div>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #number let-row="data" let-field="field" let-override="override">
    <div class="ws-flex-grow">
        <input type="number"
               autocomplete="off"
               class="form-control"
               [class.overridden]="override"
               [(ngModel)]="row[field.reportedParam]"
               maxLength="100" />
    </div>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #currency let-row="data" let-field="field" let-override="override" let-adjustments="adjustments"  let-isCalculated="isCalculated" let-classRef="classRef">
    <div style="width: 50%;">
        <ws-currency-input [override]="override"
                           [hasAdjustments]="adjustments"
                           [isCalculated]="isCalculated"
                           [ngModel]="(field.reportedValueGetter) ? field.reportedValueGetter(row, field) : row[field.reportedParam]"
                           (ngModelChange)="row[field.reportedParam] = $event"
                           [disabled]="field.isDisabled && field.isDisabled()"
                           (valueChange)="classRef.templateValueChange($event, row, field)">
        </ws-currency-input>
    </div>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #date let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <div class="ws-flex-container-vertical">
        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
            <div class="ws-flex-grow">
                <weissman-datetime class="ws-stretch-width"
                                   [dateOnly]="true"
                                   [inputDate]="(field.reportedValueGetter) ? field.reportedValueGetter(row, field) : row[field.reportedParam]"
                                   (inputDateChange)="classRef.templateValueChange($event, row, field)"
                                   [isDisabled]="field.isDisabled && field.isDisabled(row)"
                                   [isOverridden]="override">
                </weissman-datetime>
            </div>
            <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <label *ngIf="field.label === 'Disposed Date' && !classRef.createNewAsset">Do not potentially dispose
            <input type="checkbox" style="margin-left: 10px;" [(ngModel)]="row.reportedDoNotPotentiallyDispose"
                   (ngModelChange)="onDoNoPotentiallyDisposeChanged($event)" [disabled]="!row.isPrimary"></label>
    </div>
</ng-template>

<ng-template #picklist let-row="data" let-field="field" let-override="override" let-template="template">
    <div class="ws-flex-grow">
        <ws-select labelProperty="name"
                   valueProperty="value"
                   class="ws-stretch-width"
                   [options]="field.picklist"
                   [(ngModel)]="row[(template.modelParam ? template.modelParam : field.reportedParam)]"
                   (selectedValueChanged)="classRef.templateValueChange($event, row, field)">
        </ws-select>
    </div>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #yesNo let-row="data" let-field="field" let-override="override" let-template="template">
    <div class="ws-flex-grow">
        <input type="checkbox"
               [class.overridden]="override"
               [ngModel]="row[field.reportedParam]"
               (ngModelChange)="classRef.templateValueChange($event, row, field)">
    </div>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<!-- Custom Templates -->

<ng-template #acqDate let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <div class="ws-flex-container-vertical ws-stretch-width">
        <div class="ws-flex-grow">
            <weissman-datetime [dateOnly]="true"
                               [excludeValidation]="true"
                               [inputDate]="field.reportedValueGetter(row, field)"
                               (inputDateChange)="classRef.templateValueChange($event, row, field)"
                               [isDisabled]="field.isDisabled && field.isDisabled() || !row.isPrimary"
                               [isOverridden]="override">
            </weissman-datetime>
            <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none ws-flex-align-items-center acq-perpetual" *ngIf="classRef.allowPerpetual()">
            <label class="ws-flex-none small ws-font-weight-normal">
                <input type="checkbox" name="isPerpetual" [checked]="row.reportingIsPerpetual" (change)="classRef.onIsPerpetualChanged($event.target, row)" [disabled]="!classRef.editMode"> Perpetual
            </label>
            <i class="fa fa-info-circle" title="When checked, the acquisition date will automatically assume an acquisition date at the end of the current reporting period relative to the lien date.  This will most often be used for reporting supplies or other assets which do not have entries coming directly from a client's fixed asset records."></i>
        </div>
    </div>
</ng-template>

<ng-template #glAccount let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <gl-account-selector [companyId]="(row.reportedSite && row.reportedSite.companyID ? row.reportedSite.companyID : row.companyId)"
                         [isOverridden]="override"
                         [isRequiredField]="false"
                         [ngModel]="row.reportedGlAccount"
                         [glAccountName]="row[field.reportedParam]"
                         (glAccountChange)="classRef.onGlAccountSelected($event, row)">
    </gl-account-selector>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #assetClassification let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <asset-class-selector [isOverridden]="override"
                          [(assetClassificationId)]="row[field.reportedParam]"
                          (assetClassificationIdChange)="classRef.onAssetClassificationChanged($event, row)">
    </asset-class-selector>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #parcelSelector let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <parcel-selector [companyId]="row.companyId"
                     [(reportingParcelId)]="row[field.reportedParam]"
                     [siteIds]="[ row.reportedSite.siteID ]"
                     [masterSiteIds]="[ row.reportedSite.masterSiteId ]"
                     [isOverridden]="override"
                     [loadParcelsOnInit]="true"
                     [getParcels]="getParcels"
                     optionFlagText="Show only parcels under site"></parcel-selector>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #companiesSelector let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <select class="form-select form-select-sm" id="companyId" name="companyId" [(ngModel)]="classRef.selectedCompanyId" (ngModelChange)="classRef.updateSelectedCompanyId($event, row)">
        <option [ngValue]="classRef.topLevelCompanyId">{{classRef.topLevelCompanyName}}</option>
        <optgroup label="Subsidiaries" *ngIf="classRef.childCompanies && classRef.childCompanies.length">
            <option *ngFor="let childCompany of classRef.childCompanies" [ngValue]="childCompany.id">
                {{childCompany.name}}
            </option>
        </optgroup>
    </select>
    <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
        <i class="fa fa-times"></i>
    </button>
</ng-template>

<ng-template #costAdjustments let-row="data" let-field="field" let-override="override" let-classRef="classRef" let-editMode="editMode">
    <span *ngIf="editMode && !row.isLocked && row.isPrimary && classRef.showAssignByPercentageButton() && classRef.showCostAdjustments(field.descriptor.companyAssetDescriptorMappingId)"
          [class.costMismatch]="!classRef.assetCostsMatchCalculatedTotals">
        <button type="button" class="btn flat-button icon-button clear-override-button" helpTooltip
                helpContentId="asset-info.allocate-cost-by-percentage" (click)="classRef.showCostPercentageModal()">
            <i class="fa fa-calculator fa-fw"></i>
        </button>
    </span>
    <span *ngIf="classRef.assets.length > 1 && !classRef.assetCostsMatchCalculatedTotals && !classRef.createNewAsset && (classRef.assetListForm && classRef.assetListForm.controls.costAssetDescriptors.value === field.descriptor.companyAssetDescriptorMappingId)" class="percentageWarning" title="Source adjusted cost does not match allocated total of {{ classRef.allocatedTotal }}">
        <i class="fa fa-exclamation-circle"></i>
    </span>
    <ng-container *ngIf="classRef.showCostAdjustments(field.descriptor.companyAssetDescriptorMappingId)">
        <span *ngIf="editMode && classRef.costAdjustmentControlsVisible" class="float-end">
            {{(field.sourceValueGetter) ? field.sourceValueGetter(row[field.reportedParam], field) : formatValue(row[field.sourceParam], field.reportedTemplate)}}
        </span>
        <cost-adjustments *ngIf="editMode && !row.isLocked"
                          [extendedCostAdjustmentTypes]="classRef.extendedCostAdjustmentTypes"
                          [assetSummary]="row"
                          [(costAdjustments)]="classRef.costAdjustments"
                          [costCompanyAssetDescriptorMappingModel]="classRef.getCostCompanyAssetDescriptorMappingModel()"
                          [costAdjustmentControlsVisible]="classRef.costAdjustmentControlsVisible"
                          (costAdjustmentControlsVisibleChange)="classRef.setCostAdjustmentControlsVisible($event)"
                          [assets]="classRef.assets">
        </cost-adjustments>
        <div *ngIf="!editMode" class="cost-adjustment-description">{{ classRef.getCostAdjustmentDescription() }}</div>
    </ng-container>
</ng-template>

<ng-template #sites let-row="data" let-field="field" let-override="override" let-classRef="classRef">
    <div class="ws-flex-container-vertical">
        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
            <site-selector [companyId]="((!classRef.createNewAsset) ? row.companyId : classRef.selectedCompanyId)"
                           [isOverridden]="override"
                           [siteName]="row[field.reportedParam]"
                           (siteChange)="classRef.onSiteSelected($event, row)">
            </site-selector>
            <button *ngIf="override" type="button" class="btn flat-button icon-button warning-button clear-override-button" title="Clear override value" (click)="classRef.clearValue(row, field)">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <label *ngIf="!classRef.createNewAsset">Is Anchored?<input type="checkbox" style="margin-left: 10px;" [(ngModel)]="row.reportedIsAnchored"></label>
    </div>
</ng-template>

<div class="leasing-field-selector" *ngIf="currentAsset && !createNewAsset">
    <ul class="nav nav-tabs">
        <li class="uib-tab tab-item" [ngClass]="{ 'active' : selectedFieldsTab === AssetInfoFieldsTab.Asset }"><a class="nav-link" (click)="selectedFieldsTab = AssetInfoFieldsTab.Asset">Asset Fields</a></li>
        <li *ngIf="currentAsset.isLeasedAsset" class="uib-tab tab-item" [ngClass]="{ 'active' : selectedFieldsTab === AssetInfoFieldsTab.Leasing }"><a class="nav-link" (click)="selectedFieldsTab = AssetInfoFieldsTab.Leasing">Leasing Fields</a></li>
        <li *ngIf="showInventoryFields" class="uib-tab tab-item" [ngClass]="{ 'active' : selectedFieldsTab === AssetInfoFieldsTab.Inventory }"><a class="nav-link" (click)="selectedFieldsTab = AssetInfoFieldsTab.Inventory">Inventory/Supply Fields</a></li>
    </ul>
</div>
<div class="asset-info-holder">
    <form class="ws-stretch-width" #assetForm="ngForm">
        <div class="asset-detail-list" [ngClass]="{ 'asset-section-hidden' : selectedFieldsTab !== AssetInfoFieldsTab.Asset }" [formGroup]="assetListForm">
            <table *ngIf="currentAsset">
                <thead>
                    <tr class="detail-row">
                        <th class="header-cell sticky-col">Field</th>
                        <th class="header-cell sticky-col">
                            <span *ngIf="currentAsset.hasImportedSource" helpTooltip helpContentId="asset-info.source-column">Source Value</span>
                        </th>
                        <th class="header-cell" *ngFor="let asset of assets; let i = index">
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center ws-flex-space-between">
                                <span class="ws-flex-grow text-nowrap" helpTooltip helpContentId="asset-info.reporting-column">
                                    Reporting Value <span *ngIf="assets.length > 1" class="text-muted">(split {{(i + 1)}} of {{assets.length}})</span>
                                </span>
                                <div class="split-controls">
                                    <div class="btn-group float-end ws-flex-container-horizontal" role="group">
                                        <button type="button"
                                                *ngIf="editMode && !primaryAssetIsLocked && !createNewAsset && asset.hasImportedSource"
                                                class="btn small-button flat-button primary-button"
                                                (click)="overrideAll(asset)"
                                                helpTooltip
                                                helpContentId="asset-info.override-all">
                                            Override All
                                        </button>
                                        <button type="button"
                                                *ngIf="editMode && !primaryAssetIsLocked && asset.isPrimary && !createNewAsset"
                                                class="btn small-button flat-button primary-button"
                                                (click)="add()"
                                                helpTooltip
                                                helpContentId="asset-info.new-split">
                                            New Split
                                        </button>
                                        <button type="button"
                                                *ngIf="editMode && !asset.isPrimary"
                                                class="btn small-button flat-button warning-button"
                                                (click)="delete(asset)"
                                                helpTooltip
                                                helpContentId="asset-info.delete-split">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                        <button *ngIf="!createNewAsset && canSeeHistory"
                                                title="History"
                                                type="button"
                                                class="btn small-button flat-button primary-button"
                                                (click)="viewHistory(asset)">
                                            <i class="fa fa-history fa-align"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let field of assetListFields; trackBy:trackByFieldParam">
                        <tr class="detail-row" *ngIf="!field.isRowShown || field.isRowShown()">
                            <td class="detail-cell sticky-col" [attr.colspan]="(field.labelTemplate && field.labelTemplate.isDoubleWidth ? 2 : 1)">
                                <input *ngIf="editMode && !createNewAsset && field.descriptorList && field.descriptorCount"
                                       type="radio"
                                       [formControlName]="field.descriptorList"
                                       [name]="field.descriptorList"
                                       [value]="field.descriptor.companyAssetDescriptorMappingId"
                                       helpTooltip
                                       [helpContentId]="field.descriptorHelpContentId"/>
                                <span helpTooltip [helpContentId]="field.labelHelpContentId">{{field.label}}</span>
                                <ng-container *ngIf="field.labelTemplate !== null && field.labelTemplate !== undefined"
                                              [ngTemplateOutlet]="getTemplate(field.labelTemplate)"
                                              [ngTemplateOutletContext]="{ data: currentAsset, field: field, override: null, template: field.labelTemplate, classRef: classRef, editMode: editMode }">
                                </ng-container>
                            </td>
                            <td *ngIf="!field.sourceTemplate && (!field.isSourceCellShown || field.isSourceCellShown())" class="detail-cell sticky-col">
                                <ng-container *ngIf="currentAsset.hasImportedSource">
                                    {{(field.sourceValueGetter) ? field.sourceValueGetter(currentAsset[field.sourceParam], field) : formatValue(currentAsset[field.sourceParam], field.reportedTemplate)}}
                                </ng-container>
                            </td>
                            <td *ngIf="field.sourceTemplate && (!field.isSourceCellShown || field.isSourceCellShown())" class="detail-cell sticky-col">
                                <div class="text-overflow" *ngIf="!editMode && currentAsset.hasImportedSource">
                                    <span>{{(field.sourceValueGetter) ? field.sourceValueGetter(currentAsset[field.sourceParam], field) : formatValue(currentAsset[field.sourceParam], field.reportedTemplate)}}</span>
                                </div>
                                <ng-container *ngIf="editMode && (!currentAsset.isLocked || field.reportedParam === 'reportedIsLocked') && field.sourceTemplate !== null && field.sourceTemplate !== undefined"
                                              [ngTemplateOutlet]="getTemplate(field.sourceTemplate)"
                                              [ngTemplateOutletContext]="{ data: currentAsset, field: field, override: null, template: field.sourceTemplate, classRef: classRef }">
                                </ng-container>
                            </td>
                            <ng-container *ngFor="let asset of assets; trackBy:trackByAssetId">
                                <td *ngIf="!field.isReportedCellShown || field.isReportedCellShown()" class="detail-cell reporting-cell" [ngClass]="field.cellClass">
                                    <div class="reported-cell-container">
                                        <div class="text-overflow ws-flex-container-vertical" *ngIf="!editMode || (editMode && asset.isLocked && field.reportedParam !== 'reportedIsLocked') || (editMode && (!asset.isLocked || field.reportedParam === 'reportedIsLocked') && field.reportedTemplate && field.reportedTemplate.isStatic && field.reportedTemplate.isStatic())">
                                            <span [ngClass]="{ 'ws-calculated': asset[field.isCalculated], 'overridden': (field.descriptor && field.descriptor.columnName ? asset.reportedColumnHasOverride(field.descriptor.columnName) : asset[field.reportedOverride]), 'blank': asset[field.reportedOverrideWithBlank], 'has-adjustments': (field.descriptor && !(field.descriptor.columnName ? asset.reportedColumnHasOverride(field.descriptor.columnName) : asset[field.reportedOverride]) && isCostField(field.reportedTemplate.type) && hasCostAdjustments()) }">
                                                {{(field.reportedValueGetter) ? field.reportedValueGetter(asset, field) : formatValue(asset[field.reportedParam], field.reportedTemplate)}}
                                            </span>
                                            <span *ngIf="field.label === 'Site'">{{asset.reportedIsAnchored ? '' : 'Not'}} Anchored</span>
                                            <span *ngIf="field.label === 'Disposed Date' && asset.reportedDoNotPotentiallyDispose">Do not potentially dispose</span>
                                        </div>
                                        <div *ngIf="editMode && (!asset.isLocked || field.reportedParam === 'reportedIsLocked') && field.reportedTemplate && !(field.reportedTemplate.isStatic && field.reportedTemplate.isStatic())" class="split-controls ws-flex-container-horizontal ws-flex-align-items-center">
                                            <ng-container [ngTemplateOutlet]="getTemplate(field.reportedTemplate)"
                                                          [ngTemplateOutletContext]="{ data: asset, field: field, override: (field.descriptor && field.descriptor.columnName ? asset.reportedColumnHasOverride(field.descriptor.columnName) : asset[field.reportedOverride]), adjustments: hasCostAdjustments(), template: field.reportedTemplate, classRef: classRef }">
                                            </ng-container>
                                        </div>
                                        <div class="alternate-cost-field-arrow"
                                             *ngIf="assetListForm.get(field.descriptorList) && assetListForm.get(field.descriptorList).value > 0 && assetListForm.get(field.descriptorList).value === field.descriptor.companyAssetDescriptorMappingId && (asset.reportedColumnHasOverride(field.descriptor.columnName) || asset[field.sourceParam])"
                                             [ngStyle]="{ 'top': arrowBounds[field.descriptorList].top + 'px', left: arrowBounds[field.descriptorList].left + 15 + 'px' }">
                                            <svg helpTooltip
                                                 [helpContentId]="arrowBounds[field.descriptorList].tooltip" [attr.height]="arrowBounds[field.descriptorList].height" [attr.width]="arrowBounds[field.descriptorList].width">
                                                <path [attr.d]="arrowBounds[field.descriptorList].dPath" />
                                                <polygon [attr.points]="arrowBounds[field.descriptorList].arrowPoints"></polygon>
                                            </svg>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <div class="asset-detail-list" [ngClass]="{ 'asset-section-hidden' : selectedFieldsTab !== AssetInfoFieldsTab.Leasing }">
            <table *ngIf="currentAsset && currentAsset.isLeasedAsset">
                <thead>
                    <tr class="detail-row">
                        <th class="header-cell sticky-col">Field</th>
                        <th class="header-cell sticky-col" *ngIf="currentAsset.hasImportedSource" helpTooltip
                            helpContentId="asset-info.source-column">Source Value</th>
                        <th class="header-cell" *ngFor="let asset of assets; let i = index">
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center ws-flex-space-between">
                                <span class="ws-flex-grow" helpTooltip helpContentId="asset-info.reporting-column">
                                    Reporting Value <span *ngIf="assets.length > 1" class="text-muted">(split {{(i + 1)}} of {{assets.length}})</span>
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- LEASED TO OTHERS EXCLUSIVE FIELDS-->
                    <ng-container *ngIf="currentAsset && currentAsset.isLeasedToOther">
                        <tr class="detail-row" *ngFor="let field of leasedToFields; trackBy:trackByFieldParam">
                            <td class="detail-cell sticky-col">{{field.label}}</td>
                            <td *ngIf="currentAsset.hasImportedSource" class="detail-cell sticky-col">{{formatValue(currentAsset[field.sourceParam], field.reportedTemplate)}}</td>
                            <ng-container *ngFor="let asset of assets; trackBy:trackByAssetId">
                                <td class="detail-cell reporting-cell">
                                    <div class="text-overflow" *ngIf="!editMode || currentAsset.isLocked">
                                        <span [ngClass]="{ 'overridden': asset[field.reportedOverride] }">
                                            {{formatValue(asset[field.reportedParam], field.reportedTemplate)}}
                                        </span>
                                    </div>
                                    <div class="split-controls ws-flex-container-horizontal ws-flex-align-items-center" *ngIf="editMode && field.reportedTemplate && !currentAsset.isLocked">
                                        <ng-container [ngTemplateOutlet]="getTemplate(field.reportedTemplate)"
                                                      [ngTemplateOutletContext]="{ data: asset, field: field, override: asset[field.reportedOverride], template: field.reportedTemplate, classRef: classRef }"></ng-container>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                    <!-- LEASED TO OR FROM OTHERS FIELDS-->
                    <ng-container *ngIf="currentAsset && currentAsset.isLeasedAsset">
                        <tr class="detail-row" *ngFor="let field of leasedToFromFields; trackBy:trackByFieldParam">
                            <td class="detail-cell sticky-col">{{field.label}}</td>
                            <td *ngIf="currentAsset.hasImportedSource" class="detail-cell sticky-col">{{formatValue(currentAsset[field.sourceParam], field.reportedTemplate)}}</td>
                            <ng-container *ngFor="let asset of assets; trackBy:trackByAssetId">
                                <td class="detail-cell reporting-cell">
                                    <div class="text-overflow" *ngIf="!editMode || currentAsset.isLocked">
                                        <span [ngClass]="{ 'overridden': asset[field.reportedOverride] }">
                                            {{formatValue(asset[field.reportedParam], field.reportedTemplate)}}
                                        </span>
                                    </div>
                                    <div class="split-controls ws-flex-container-horizontal ws-flex-align-items-center" *ngIf="editMode && field.reportedTemplate && !currentAsset.isLocked">
                                        <ng-container [ngTemplateOutlet]="getTemplate(field.reportedTemplate)" [ngTemplateOutletContext]="{ data: asset, field: field, override: asset[field.reportedOverride], template: field.reportedTemplate, classRef: classRef }"></ng-container>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
            </table>

        </div>

        <div class="asset-detail-list" [ngClass]="{ 'asset-section-hidden' : selectedFieldsTab !== AssetInfoFieldsTab.Inventory }">
            <table *ngIf="currentAsset && selectedFieldsTab === AssetInfoFieldsTab.Inventory">
                <thead>
                    <tr class="detail-row">
                        <th class="header-cell sticky-col">Field</th>
                        <th class="header-cell sticky-col" *ngIf="currentAsset.hasImportedSource" helpTooltip
                            helpContentId="asset-info.source-column">Source Value</th>
                        <th class="header-cell" *ngFor="let asset of assets; let i = index">
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center ws-flex-space-between">
                                <span class="ws-flex-grow" helpTooltip helpContentId="asset-info.reporting-column">
                                    Reporting Value <span *ngIf="assets.length > 1" class="text-muted">(split {{(i + 1)}} of {{assets.length}})</span>
                                </span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="detail-row" *ngFor="let field of inventoryFields; trackBy:trackByFieldParam">
                        <td class="detail-cell sticky-col">{{field.label}}</td>
                        <td *ngIf="currentAsset.hasImportedSource" class="detail-cell sticky-col">{{formatValue(currentAsset[field.sourceParam], field.reportedTemplate)}}</td>
                        <ng-container *ngFor="let asset of assets; trackBy:trackByAssetId">
                            <td class="detail-cell reporting-cell">
                                <div class="cost text-overflow" *ngIf="!editMode || currentAsset.isLocked">
                                    <span [ngClass]="{ 'overridden': asset[field.reportedOverride], 'ws-calculated': asset[field.isCalculated] }">
                                        {{formatValue(asset[field.reportedParam], field.reportedTemplate)}}
                                    </span>
                                </div>
                                <div class="split-controls ws-flex-container-horizontal ws-flex-align-items-center" *ngIf="editMode && field.reportedTemplate && !currentAsset.isLocked">
                                    <ng-container [ngTemplateOutlet]="getTemplate(field.reportedTemplate)" [ngTemplateOutletContext]="{ data: asset, field: field, override: asset[field.reportedOverride], isCalculated: asset[field.isCalculated], template: field.reportedTemplate, classRef: classRef }"></ng-container>
                                </div>
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table>
        </div>

    </form>
</div>
