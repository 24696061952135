import { IExpandableComponent } from './expandableComponent.model';
import { IExpandableComponentContainer } from './expandableComponentContainer.model';

export class BaseExpandableComponent implements IExpandableComponent {
    protected constructor(
        protected expandableComponentContainer: IExpandableComponentContainer,
        protected name: string) {
    }

    getName(): string {
        return this.name;
    }

    get isExpanded(): boolean {
        return this.expandableComponentContainer.isComponentExpanded(this);
    }

    expandComponent():void {
        this.expandableComponentContainer.setExpandedComponent(this);
    }

    collapseComponent():void {
        this.expandableComponentContainer.setExpandedComponent(null);
    }
}
