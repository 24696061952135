import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import * as _ from 'lodash';

declare const moment: any;

enum SupportInstanceEscalationActivityReportLimitByEscalationDateEnum {
    Period = Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.Period as number,
    DateRange = Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.DateRange as number,
    AnyValidDate = Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.AnyValidDate as number
}

@Component({
    selector: 'further-limit-panel-support-instance-escalation-activity',
    template: `
        <div class="col-md-6">
            <div class="form-inline">
                <input type="radio" name="limit-by-escalation-date" [(ngModel)]="report.criteria.limitByEscalationDate"
                       [value]="SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.Period"
                       (change)="escalationDateChoiceChanged()">
                <span>Escalation Period </span>

                <select class="form-select-sm" [(ngModel)]="report.criteria.escalationDatePeriod"
                        [disabled]="report.criteria.limitByEscalationDate !== SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.Period"
                        (change)="escalationDateChoiceChanged()">
                    <option *ngFor="let option of periodOptions" [ngValue]="option.val">{{option.label}}</option>
                </select>
            </div>
            <div class="form-inline" style="margin-top: 10px;">
                <input type="radio" name="limit-by-escalation-date" [(ngModel)]="report.criteria.limitByEscalationDate"
                       [value]="SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.DateRange"
                       (change)="escalationDateChoiceChanged()">
                <span>Escalation Date Range </span>
                <weissman-datetime [(inputDate)]="escalationDateBeginUI"
                                   [dateOnly]="true"
                                   [timeZone]="'Central'"
                                   [isDisabled]="report.criteria.limitByEscalationDate !== SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.DateRange"
                                   (change)="escalationDateChoiceChanged()">
                </weissman-datetime>
                to
                <weissman-datetime [(inputDate)]="escalationDateEndUI"
                                   [dateOnly]="true"
                                   [timeZone]="'Central'"
                                   [rangeEnd]="true"
                                   [isDisabled]="report.criteria.limitByEscalationDate !== SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.DateRange"
                                   (change)="escalationDateChoiceChanged()">
                </weissman-datetime>
            </div>
            <div class="form-inline" style="margin-top: 10px;">
                <input type="radio" name="limit-by-escalation-date" [(ngModel)]="report.criteria.limitByEscalationDate"
                       [value]="SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.AnyValidDate"
                       (change)="escalationDateChoiceChanged()">
                <span>Any Escalation Date</span>
            </div>
        </div>
    `
})
export class FurtherLimitPanelSupportInstanceEscalationActivityComponent implements OnInit{
    @Input() report: ReportDetail;

    SupportInstanceEscalationActivityReportLimitByEscalationDateEnum = SupportInstanceEscalationActivityReportLimitByEscalationDateEnum;
    escalationDateBeginUI: Date;
    escalationDateEndUI: Date;
    periodOptions: {val: number, label: string}[] = [{
        val: 90,
        label: '90  Days'
    }, {
        val: 60,
        label: '60  Days'
    }, {
        val: 30,
        label: '30  Days'
    }];

    ngOnInit() {
        if(_.isUndefined(this.report.criteria.limitByEscalationDate)) {
            this.report.criteria.limitByEscalationDate = Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.Period;
        }
        this.report.criteria.escalationDatePeriod = this.report.criteria.escalationDatePeriod || 90;
        this.escalationDateBeginUI = this.report.criteria.escalationDateBegin;
        this.escalationDateEndUI = this.report.criteria.escalationDateEnd;
        this.escalationDateChoiceChanged();
    }

    escalationDateChoiceChanged(): void {
        switch(this.report.criteria.limitByEscalationDate) {
            case Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.Period:
                this.report.criteria.escalationDateBegin = moment().tz('America/Chicago').subtract(this.report.criteria.escalationDatePeriod, 'days').toDate();
                this.report.criteria.escalationDateEnd = moment().tz('America/Chicago').toDate();
                this.report.criteria.anyEscalationDates = false;
                break;
            case Core.SupportInstanceEscalationActivityReportLimitByEscalationDateEnum.DateRange:
                this.report.criteria.escalationDateBegin = this.escalationDateBeginUI;
                this.report.criteria.escalationDateEnd = this.escalationDateEndUI;
                this.report.criteria.anyEscalationDates = false;
                break;
            default:
                this.report.criteria.escalationDateBegin = null;
                this.report.criteria.escalationDateEnd = null;
                this.report.criteria.anyEscalationDates = true;
                break;
        }
    }
}
