import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild } from "@angular/core";
import { DataWidgetI, WidgetCache, WidgetIds, UserWidget } from "../../dashboard.model";
import { Observable, Subject } from "rxjs";
import { PortfolioAnalysisWidgetModel, PortfolioAnalysisAnnualData } from './portfolio.analysis.widget.model';
import * as _ from 'lodash';
import { PortfolioAnalysisChartTableComponent } from './portfolio.analysis.chart.table.component';
import { DashboardDataService } from '../../dashboard.data.service';
import { ProductAnalyticsService } from "../../../Common/Amplitude/productAnalytics.service";

@Component({
    selector: 'portfolio-analysis-widget',
    templateUrl: './portfolio.analysis.widget.component.html',
    styleUrls: ['./portfolio.analysis.widget.component.scss']
})
export class PortfolioAnalysisWidgetComponent implements DataWidgetI, AfterViewInit {
    constructor(
        private readonly _dashboardDataService: DashboardDataService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @ViewChild(PortfolioAnalysisChartTableComponent)
    chartTableComponent: PortfolioAnalysisChartTableComponent;

    @Input() data$: Observable<any>;
    @Input() userWidget: UserWidget;
    @Output() onNewParams: EventEmitter<object> = new EventEmitter();

    resize$: Subject<void>;
    availableYears: number[];
    selectedYear: number;

    data: PortfolioAnalysisWidgetModel;
    chartType: string;

    currentYear: PortfolioAnalysisAnnualData;
    acres: string;
    impsSqFt: string;
    units: string;

    WidgetIds = WidgetIds;

    get isChartWidget(): boolean {
        return this.userWidget.widget.widgetId === WidgetIds.PortfolioAnalysis;
    }

    ngOnInit(): void {
        // Initialize selected year to current year upon first display.
        this.selectedYear = new Date().getFullYear();

        this.resize$ = new Subject<void>();
        // WK-8048 - The chart doesn't get automatically updated when we resize the widget for some reason;
        // "kick" the widget when resized in design mode to make it work correctly
        this._dashboardDataService.userWidgetResize$.subscribe(() => this.resize$.next())
    }

    ngAfterViewInit(): void {
        this.data$.subscribe((widgetCache: WidgetCache) => {
            this.data = widgetCache.data;
            this.availableYears = _.reverse(this.data.taxYears);

            this.currentYear = _.findLast(this.data.annualData, x => x.year <= this.selectedYear);
            if ( !this.currentYear )
            {
                // Didn't find selected year or an earlier year.
                // Look for a later year than selected.
                this.currentYear = _.find(this.data.annualData, x => x.year > this.selectedYear);
                if ( !this.currentYear )
                {
                    // Didn't find any years.
                    // Create currentYear with default data for selected year.
                    this.currentYear = new PortfolioAnalysisAnnualData();
                    this.currentYear.year = this.selectedYear;
                }
            }
            if ( this.currentYear.year != this.selectedYear )
            {
                // Didn't find selected year, but found an earlier/later year.
                this.selectedYear = this.currentYear.year;
            }

            this.currentYear.totalFMV = Math.round(this.currentYear.totalFMV);

            /*
            this.acres = _.find(this.data.propertyCharacteristics, {label: 'Land Acreage' });
            this.impsSqFt = _.find(this.data.propertyCharacteristics, {label: 'Imps. SqFt' });
            this.units = _.find(this.data.propertyCharacteristics, {label: 'Units' });
            */

            this.lineItemClicked('totalFMV', false);
        })
    }

    getDataByYear(year: number): void {
        const event = this.isChartWidget ? 'click-portfolio-widget' : 'click-portfolio-summary-widget';
        this._productAnalyticsService.logEvent(event, { taxYear: year });
        this.onNewParams.emit({year: year})
    }

    lineItemClicked(chartType: string, fromClick: boolean = true): void {
        if(!this.isChartWidget || chartType === this.chartType) {
            return;
        }

        this.chartType = chartType;
        this.chartTableComponent.updateChartType(this.chartType, this.data);

        if(fromClick) {
            let analyticsProperty: string, analyticsValue: string;
            switch (chartType) {
                case 'totalFMV':
                    analyticsProperty = 'totalFMV';
                    analyticsValue = `$${this.currentYear.totalFMV}`;
                    break;
                case 'totalFMVPerSqFt':
                    analyticsProperty = 'total$Sqft';
                    analyticsValue = `$${this.currentYear.totalFMVPerSqFt}`;
                    break;
                case 'totalFMVPerUnit':
                    analyticsProperty = 'total$Unit';
                    analyticsValue = `$${this.currentYear.totalFMVPerUnit}`;
                    break;
                case 'landFMVPerSqFt':
                    analyticsProperty = 'land$Sqft';
                    analyticsValue = `$${this.currentYear.fmvForLandTypeComponents.landFMVPerSqFt}`;
                    break;
                case 'landFMVPerAcre':
                    analyticsProperty = 'land$Acre';
                    analyticsValue = `$${this.currentYear.fmvForLandTypeComponents.landFMVPerAcre}`;
                    break;
                case 'totalTaxesUngrouped':
                    analyticsProperty = 'totalTax';
                    analyticsValue = `$${this.currentYear.totalTax || 0}`;
            }
            this._productAnalyticsService.logEvent('click-portfolio-widget', {
                [analyticsProperty]: analyticsValue
            });
        }
    }
}
