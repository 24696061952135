import { AgGridNumberFilterComponent } from "../Filters/Number/agGridNumberFilter.component";
import { AgGridTextFilterComponent } from "../Filters/Text/agGridTextFilter.component";
import { IFloatingFilter, IFloatingFilterParams } from "ag-grid-community";
import { AgFrameworkComponent } from "ag-grid-angular";
import { HostListener, Directive } from "@angular/core";
import { FilterUIModel } from "../Filters/filterUI.model";

export interface WSFloatingFilterParams extends IFloatingFilterParams {
    defaultValue?: string;
}

@Directive()
export abstract class AgGridFloatingFilterBase<T extends WSFloatingFilterParams> implements IFloatingFilter, AgFrameworkComponent<WSFloatingFilterParams> {

    @HostListener('keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.valueChange(true);
        }
        event.stopPropagation();
    }

    value: string;
    type: string;
    conditionApplied: boolean;

    protected _params: T;

    abstract onParentModelChanged(parentModel: FilterUIModel): void;

    agInit(params: T): void {
        this._params = params;
        this.value = (params.defaultValue) ? params.defaultValue : '';
        this._params.parentFilterInstance((instance) => this.onParentModelChanged((instance as unknown as AgGridTextFilterComponent).getModel()));
    };

    valueChange(submit: boolean): void {
        const type = this._params.filterParams['defaultOption'];
        this.value = this.value === null ? null : this.value.trim();
        const valueToUse = this.value === '' ? null : this.value;
        this._params.parentFilterInstance((instance) => (instance as unknown as AgGridTextFilterComponent).onFloatingFilterChanged(type, valueToUse, submit));
    }
 
    clearFilter(): void {
        const type = this._params.filterParams['defaultOption'] || null;
        this._params.parentFilterInstance((instance) => (instance as unknown as AgGridTextFilterComponent).onFloatingFilterChanged(type, null, true));
    }
}
