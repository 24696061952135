import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { SmartSearchService } from '../../../../Search/Advanced/smartSearch.service';
import { UserSaveService } from '../../userSave.service';

export interface searchRenameModalParams {
    search: Core.AdvancedSearchList;
    searchType: Core.SearchCategoryType;
}

@Component({
    selector: 'search-rename',
    templateUrl: './searchRename.component.html'
})
export class SearchRenameComponent implements IWeissmanModalComponent<searchRenameModalParams, void>, OnInit {
    constructor(private readonly _smartSearchService: SmartSearchService,
                private readonly _bsModalRef: BsModalRef,
                private readonly _userSaveService: UserSaveService) {
    }

    params: searchRenameModalParams;
    result: void;

    modalTitle: string;
    search: Core.AdvancedSearchList;
    searchType: number;

    ngOnInit(): void {
        this.search = this.params.search;
        this.searchType = this.params.searchType;
        this.modalTitle = `Rename "${this.search.searchName}" Search`;
    }

    async save() {
        const result = await this._smartSearchService.patchSearch(this.search, this.searchType);
        this.search.rowVersion = result.rowVersion;
        await this._userSaveService.refreshCurrentList();
        this.cancel();
    }

    cancel() {
        this._bsModalRef.hide();
    }
}
