import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { Observable } from 'rxjs';
import { Settings, UserData } from '../User/account.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

export interface UserSettingPayload {
    folderID: number;
    groupID: number;
    value: string;
    name: string;
    groupName: string;
    folderName: string;
    settingID?: number;
    userID?: number;
}

@Injectable(
    {providedIn: 'root'}
)
export class UserRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/account/';
    private readonly SSO_INTEGRATION_URL = '/api/sso-integration/';
    private readonly USER_SETTINGS_URL = '/api/account/usersettings/';

    isSSOIntegrationEnabled(): Observable<boolean> {
        return this.httpGet(this.SSO_INTEGRATION_URL + 'enabled');
    }

    getSSOIntegrationEndpoint(domain: string): Observable<any> {
        return this.httpGet(this.SSO_INTEGRATION_URL + encodeURIComponent(domain) + '/endpoint');
    }

    changePassword(userName: string, current: string, newPassword: string): Observable<any> {
        return this.httpPut(this.SERVICE_URL + 'changepassword', {
            userName,
            newPassword,
            currentPassword: current
        });
    }

    emailPasswordReset(username: string): Observable<any> {
        const data = {
            username
        }
        return this.httpPut(this.SERVICE_URL + 'resetpassword', data);
    }

    updateUser(user: UserData): Observable<any> {
        return this.httpPut(this.SERVICE_URL, user);
    }

    setUserDescription(userID, description): Observable<any> {
        return this.httpPut(this.SERVICE_URL + userID + '/description', {
            description: description
        });
    }

    sendPasswordResetToken(usernameOrEmail: string): Observable<any> {
        return this.httpPost('/api/accountrecovery/SendPasswordResetToken', JSON.stringify(usernameOrEmail));
    }

    resetPasswordUsingToken(tokenHash: string, newPassword: string): Observable<any> {
        const data = {
            tokenHash,
            newPassword
        }
        return this.httpPost('/api/accountrecovery/ResetPasswordUsingToken', data);
    }

    // This is used by the entity permission service but is only ever called from the user service
    getEntityPermission(url: string): Observable<Core.PermissionModel[]> {
        return this.httpGet(url);
    }

    multiEntityCheck(bulkPermissionCheckRequest: Core.BulkPermissionCheckRequest): Observable<Core.BulkPermissionCheckResult[]> {
        return this.httpPost('/api/permission/multiEntityCheck', bulkPermissionCheckRequest);
    }

    // User Settings Service
    getSettingsByGroupAndUserId(groupId: number, userId: number): Observable<Settings[]> {
        return this.httpGet(this.USER_SETTINGS_URL + 'settinggroup/' + groupId + '/' + userId);
    }

    saveUserSettings(setting: UserSettingPayload): Observable<Settings> {
        return this.httpPost(this.USER_SETTINGS_URL  + 'addOrUpdateSingletonSettingGroupAndUser', setting);
    }
}
