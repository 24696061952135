<div class="modal fade" bsModal #changeDueDateModal="bs-modal" [config]="{backdrop: true}"
     tabindex="-1" role="dialog" aria-hidden="true" (onShow)="showModalInit()">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Change Due Dates</h4>
            </div>
            <div>
                <!-- TODO: Consider breaking this out into an Angular2+ component or upgrading the AngularJS Loading.Spinner directive -->
                <div *ngIf="loading" class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div *ngIf="!loading" class="modal-body exclude-modal-styling">
                    <label class="change-due-dates-date">
                        <span>{{dueDateText}}</span>
                        <weissman-datetime [(inputDate)]="dueDate"
                                           [readOnly]="false"
                                           [dateOnly]="dateOnly">
                        </weissman-datetime>
                    </label>
                </div>
                <div class="modal-footer">
                    <button class="btn flat-button primary-button" [disabled]="loading || !dueDate" (click)="save()">Save</button>
                    <button class="btn flat-button warning-button" [disabled]="loading" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
