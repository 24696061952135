import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface FMVChangeRendererParams extends ICellRendererParams {
    getText: (params: FMVChangeRendererParams) => number;
}

@Component({
    selector: 'fmv-change-renderer',
    template: `<span *ngIf="change && change !== 0" [ngClass]="{ 'increase': change > 0, 'decrease': change < 0 }">
					<i class="fa" [ngClass]="{ 'fa-arrow-up': change > 0, 'fa-arrow-down': change < 0 }"></i> {{change | number}}</span>`
})
export class FMVChangeRendererComponent implements ICellRendererAngularComp {

    params: FMVChangeRendererParams;

    agInit(params: FMVChangeRendererParams): void {
        this.params = params;
    }

    get change(): number {
        return this.params.getText(this.params);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
