import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeaheadSelectorComponent, HigherOrderFn, Predicate } from '../Selectors/Typeahead-Selector/typeaheadSelector.component';

export interface EntityImportEditorGLAccountTypeSelectorCellEditorParams extends ICellEditorParams {
    getGLAccountTypes(row: Compliance.ImportGridRowGridModel): Compliance.GLAccountTypeModel[];
}

@Component({
    selector: 'entity-import-editor-gl-account-type-selector',
    template: `
       <typeahead-selector #typeaheadSelector
                           labelProperty="name"
                           valueProperty="name"
                           placeholder="Filter Attachment Types..."
                           [formControl]="glAccountType"
                           [canReset]="true"
                           [originalValue]="originalValue"
                           [filterFn]="typeaheadFilter"
                           (changed)="onChanged($event)"
                           [values]="values"></typeahead-selector>`
})
export class EntityImportEditorGLAccountTypeSelectorCellEditorComponent implements ICellEditorAngularComp {
    @ViewChild("typeaheadSelector", { static: true }) typeaheadSelector: TypeaheadSelectorComponent;

    gridRow: Compliance.ImportGridRowGridModel;
    glAccountType: UntypedFormControl = new UntypedFormControl({ value: null, disabled: true });
    values: Compliance.GLAccountTypeModel[];
    originalValue: string;
    typeaheadFilter: HigherOrderFn<Predicate> = (filter: string) => (x: Compliance.GLAccountTypeModel) =>
        x.name.toLowerCase().includes(filter.toLowerCase());

    private _params: EntityImportEditorGLAccountTypeSelectorCellEditorParams;

    focusIn(): void {
    }

    focusOut(): void {
    }

    getValue(): any {
        return this.glAccountType.value;
    }

    isCancelAfterEnd(): boolean {
        if (this.typeaheadSelector.value && !this.typeaheadSelector.filter) {
            this._params.node.setDataValue(this._params.column.getColId(), '');
            return true;
        }
        return false;
    }

    isCancelBeforeStart(): boolean {
        return false;
    }

    isPopup(): boolean {
        return false;
    }

    async agInit(params: EntityImportEditorGLAccountTypeSelectorCellEditorParams): Promise<void> {
        this.gridRow = params.data as Compliance.ImportGridRowGridModel;

        this._params = params;

        if (this._params.data && this._params.data.originalValues) {
            this.originalValue = this._params.data.originalValues[this._params.colDef.field];
        }

        this.glAccountType.setValue(params.value);

        this.values = await params.getGLAccountTypes(this.gridRow);

        if (this.values && this.values.length) {
            this.glAccountType.enable();
        }
    }

    onChanged(value?: string) {
        if (this.glAccountType.value !== value) {
            this.glAccountType.setValue(value);
            this._params.node.setDataValue(this._params.column.getColId(), value);
        }
    }
}
