import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../Layout/navigation.service';
import { AccountService, GrantTypes } from '../User/account.service';
import { AuthStorageService } from '../User/authStorage.service';

const ssoSessionKey = 'ssoResponse';

@Component({
    selector: 'ssoLogin',
    template: `
<div class="col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 login-panel">
    <div class="text-center" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <p class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}</p>
</div>`
})
export class SSOLoginComponent implements OnInit {
    loading: boolean;
    errorMessage: string;

    constructor(
        private accountService: AccountService,
        private navigationService: NavigationService,
        private authStorageService: AuthStorageService
    ) { }

    async ngOnInit() {
        const ssoResponse = sessionStorage[ssoSessionKey];
        if (ssoResponse) {
            this.loading = true;
            let keepLoading = false;
            try {
                await this.accountService.login(GrantTypes.saml20, ssoResponse, true, true);
                if (this.authStorageService.isMigrationLogin) {
                    keepLoading = true;
                    return;
                }
            }
            catch (err) {
                console.error(err);
                if (err && err.error && err.error.error_description) {
                    this.errorMessage = err.error.error_description;
                }
                else {
                    this.errorMessage = 'Error logging in';
                }
                return;
            }
            finally {
                if (!keepLoading) {
                    this.loading = false;
                }
                delete sessionStorage[ssoSessionKey];
            }
            this.navigationService.postLogin();
        }
        else {
            this.errorMessage = 'Error logging in: no information from SSO provider found.';
        }
    }
}
