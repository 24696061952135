import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export interface BulkUpdateOption {
    name: string;
    descriptorId?: number;
    isRequired: boolean;
    template: any;
    picklist?: Compliance.NameValuePair<any>[];
    action: number;
    formControlName: string;
    formControl: UntypedFormControl;
    validation?: Core.DescriptorValidationDTO;
    hiddenField?: any;
}

export abstract class BulkUpdateDynamicBase<T, U> {
    constructor(
        protected readonly _bsModalRef: BsModalRef,
        protected readonly _fb: UntypedFormBuilder
    ) {}

    params: T;
    result: U;

    bulkUpdateForm: UntypedFormGroup = this._fb.group({});

    header: string;
    updateOptions: BulkUpdateOption[] = [];

    isLoading: boolean;
    fieldsModified: boolean = false;
    verificationModified: boolean = false;

    protected _templateMap: Map<Core.DescriptorFieldTypes, any>;

    protected _validators: Map<Core.DescriptorFieldTypes, (validation: Core.DescriptorValidationDTO) => ValidatorFn> = new Map([
        [Core.DescriptorFieldTypes.Date, (validation) => (control) => {
            if (!control.value || this._isValidation(control.value) || !validation) { return null; }
            const year = control.value.getFullYear();
            const isValid = (validation.minValue ? year >= validation.minValue : true)
                && (validation.maxValue ? year <= validation.maxValue : true);
            let message = 'Year must be ';
            message += (validation.minValue) ? `> ${validation.minValue - 1}` : '';
            message += (validation.minValue && validation.maxValue) ? ' and ' : '';
            message += (validation.maxValue) ? `< ${validation.maxValue + 1}` : '';
            return (!isValid) ? { 'dateInvalid': message } : null;
        }],
        [Core.DescriptorFieldTypes.Number, (validation) => (control) => {
            if (!control.value || this._isValidation(control.value) || !validation) { return null; }
            const isValid = (validation.minValue ? control.value >= validation.minValue : true)
                && (validation.maxValue ? control.value <= validation.maxValue : true);
            let message = 'Value must be ';
            message += (validation.minValue) ? `> ${validation.minValue - 1}` : '';
            message += (validation.minValue && validation.maxValue) ? ' and ' : '';
            message += (validation.maxValue) ? `< ${validation.maxValue + 1}` : '';
            return (!isValid) ? { 'numberInvalid': message } : null;
        }],
        [Core.DescriptorFieldTypes.Picklist, (validation) => (control) => null],
        [Core.DescriptorFieldTypes.YesNo, (validation) => (control) => null],
        [Core.DescriptorFieldTypes.Text, (validation) => (control) => {
            if (!control.value || this._isValidation(control.value) || !validation) { return null; }
            const isValid = validation.maxLength ? control.value.length <= validation.maxLength : true;
            const message = `Max characters: ${validation.maxLength}`;
            return (!isValid) ? { 'textInvalid': message } : null;
        }],
        [Core.DescriptorFieldTypes.Currency, (validation) => (control) => null]
    ]);

    get isFormValid(): boolean {return (this.bulkUpdateForm.valid || this.bulkUpdateForm.disabled) && (this.verificationModified || this.fieldsModified); }

    async save(force: boolean = false): Promise<void> {}

    cancel(): void {
        this._bsModalRef.hide();
    }

    rowActionChanged(row: BulkUpdateOption): void {
        if (row.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.ChangeTo) {
            row.formControl.enable();
            this.fieldsModified = true;
            return;
        } else if (row.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Remove) {
            row.formControl.setValue(null);
            this.fieldsModified = true;
        } else {
            this.fieldsModified = this._actionModified();
        }
        row.formControl.disable();
    }

    validationActionChanged(action: Compliance.NameValuePair<Compliance.AssessorCommandCenterBulkUpdateActionEnum>, row: BulkUpdateOption): void {
        row.action = action.value;
        this.verificationModified = this._actionModified();
    }

    dateValueChange(date: Date, row: BulkUpdateOption): void {
        row.formControl.setValue(date);
        row.formControl.markAsDirty();
    }

    protected _actionModified(): boolean {
        return this.updateOptions.findIndex(o => !(o.action === Compliance.AssessorCommandCenterBulkUpdateActionEnum.NoChange)) !== -1;
    }

    protected _isValidation(value): boolean {
        return value === Compliance.AssessorCommandCenterBulkUpdateActionEnum.Verify ||
            value === Compliance.AssessorCommandCenterBulkUpdateActionEnum.QC;
    }
}
