<ws-busy-indicator *ngIf="busyMessageManager.count() > 0" [message]="busyMessageManager.message"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Edit Template"
                  acceptLabel="Save"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!previewMetadata">
    <div class="app-ui-view d-flex flex-column ws-flex-auto w-100 h-100">
        <div class="d-flex flex-column row-edit-template edit-grid">
            <div>
                <label>Pages:</label>
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="currentGridOptions" [cssClass]="null"></ag-grid-row-count>
            </div>
            <ws-ag-grid-angular [gridOptions]="currentGridOptions"
                                (gridReady)="onCurrentAgGridReady($event)">
            </ws-ag-grid-angular>
        </div>
        <div class="d-flex flex-column row-edit-template">
            <label for="rotate">Rotate:</label>
            <select id="rotate"
                    [(ngModel)]="rotateBy"
                    (ngModelChange)="onOptionChanged()"
                    class="form-select form-select-sm">
                <option value="0">None</option>
                <option value="90">Clockwise</option>
                <option value="180">Flip</option>
                <option value="270">Counter Clockwise</option>
            </select>
        </div>
        <div class="d-flex flex-column row-edit-template">
            <label for="moveX">Horizontal Move:</label>
            <input id="moveX"
                   type="text"
                   placeholder=" "
                   class="form-control exclude-modal-styling"
                   [(ngModel)]="moveX"
                   (ngModelChange)="onOptionChanged()"/>
        </div>
        <div class="d-flex flex-column row-edit-template">
            <label for="moveY">Vertical Move:</label>
            <input id="moveY"
                   type="text"
                   placeholder=" "
                   class="form-control exclude-modal-styling"
                   [(ngModel)]="moveY"
                   (ngModelChange)="onOptionChanged()"/>
        </div>
        <div class="d-flex flex-column row-edit-template edit-grid">
            <div class="d-flex align-items-center">
                <div class="ws-flex-auto">
                    <label>Result:</label>
                </div>
                <button type="button"
                        class="flat-button small-button primary-button ws-flex-none"
                        helpTooltip
                        tooltipText="Preview"
                        position="top"
                        (click)="preview()"
                        [disabled]="!(selectedRows && selectedRows.length)">
                    Preview
                </button>
            </div>
            <ws-ag-grid-angular [gridOptions]="previewGridOptions"
                                (gridReady)="onPreviewAgGridReady($event)">
            </ws-ag-grid-angular>
        </div>
    </div>
</ws-modal-wrapper>
