<button class="flat-button icon-button fa fa-plus-circle"
        (click)="addYearModal.show()"
        style=""
        tooltip="Add Year">
</button>

<div class="modal fade" bsModal #addYearModal="bs-modal" [config]="{backdrop: true}"
     tabindex="-1" role="dialog" aria-hidden="true" (onShow)="showModalInit()">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add Year</h4>
            </div>
            <div class="text-center" style="margin-top: 0px; padding-bottom: 20px" [hidden]="!loading">
                <i class="fa fa-spinner fa-pulse fa-5x"></i>
            </div>
            <div [hidden]="loading">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-3">
                            <select class="form-select form-select-sm" [(ngModel)]="selectedYear" *ngIf="yearsList?.options" style="width: 200px;">
                                <option *ngFor="let option of yearsList.options" [disabled]="option.disabled" [ngValue]="option.year">{{ option.year }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn flat-button primary-button" (click)="save()" [disabled]="!saveEnabled">Add</button>
                    <button class="btn flat-button warning-button" (click)="addYearModal.hide()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
