import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { BaseRepository } from "src/app/Common/Data/base.repository";

@Injectable(
    { providedIn: 'root' }
)
export class LoginManagerService extends BaseRepository {
    constructor(_http: HttpClient) {
        super(_http);
    }

    loadUser(userId: System.Guid): Promise<Core.UserLoginInfoModel> {
        return lastValueFrom(this.httpGet<Core.UserLoginInfoModel>(`/api/admin/account/${userId}`));
    }

    getIAMUserData(userId: System.Guid): Promise<Core.IAMUserDTO> {
        return lastValueFrom(this.httpGet<Core.IAMUserDTO>(`/api/admin/account/${userId}/IAMUserInfo`));
    }

    updateLockedFields(userId: System.Guid, request: Core.UpdateUserLockedFieldsRequest): Promise<void> {
        return lastValueFrom(this.httpPut(`/api/admin/account/${userId}/UpdateLockedFields`, request));
    }

    unmigrateUser(userId: System.Guid): Promise<void> {
        return lastValueFrom(this.httpPost(`/api/admin/account/${userId}/UnmigrateUser`, null));
    }

    getIAMTenantData(userId: System.Guid): Promise<Core.IAMTenantDTO> {
        return lastValueFrom(this.httpGet<Core.IAMTenantDTO>(`/api/admin/account/${userId}/IAMTenantInfo`));
    }

    removeUserIAMTenant(userId: System.Guid): Promise<void> {
        return lastValueFrom(this.httpDelete(`/api/admin/account/${userId}/IAMTenantId`));
    }

    updateUserIAMTenantId(userId: System.Guid, tenantId: System.Guid): Promise<void> {
        return lastValueFrom(this.httpPut(`/api/admin/account/${userId}/UpdateIAMTenantId/${tenantId}`, null));
    }
}
