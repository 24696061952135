import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Constants } from '../../../constants.new';
import {
    PaymentPackage,
    TransmittalOutputType,
    TransmittalOutputTypeButtonLabel,
    TransmittalOutputTypeOption
} from '../transmittal.models';
import { TransmittalService } from '../transmittal.service';
import { AttachmentInfo } from '../../../Attachment/attachment.model';
import { ToastrService } from 'ngx-toastr';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { PaymentBatchService } from 'src/app/Payment-Batch/paymentBatch.service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';
import { WebsocketListenerService } from 'src/app/Compliance/websocketListener.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { LongRunningProcessRepository } from 'src/app/Compliance/Repositories';
import { UtilitiesService } from 'src/app/UI-Lib/Utilities/Utilities.Service.upgrade';


declare const _: any;

@Component({
    selector: 'finalize-payment-package-modal',
    templateUrl: './payment-packages-finalize.modal.html'
})
export class FinalizePaymentPackageModal implements OnInit, OnDestroy {
    @ViewChild('FinalizePaymentPackageModal', { static: true }) finalizePaymentPackageModal: ModalDirective;
    @Input() packageList: PaymentPackage[];
    @Input() setParentLoading: (loading: boolean, message: string, hideCancelHyperlink?: boolean) => void;
    @Input() isFromPaymentBatch: boolean;
    @Input() paymentBatchId: number;
    @Input() taskId: number;
    @Output() hasPackageFinalized: EventEmitter<boolean> = new EventEmitter<boolean>();
    selectedPackages: PaymentPackage[];
    originalSelectedPackages: PaymentPackage[];
    tooManyOutputsError: boolean = false;
    outputTypeOptions: TransmittalOutputTypeOption[];
    buttonLabel: string;
    selectedOutputType: TransmittalOutputType;
    loading: boolean = false;
    addButtonLabel: string = 'Add';
    @Input() cancelExecutionLoop: boolean = false;
    finalizeHasErrors: boolean = false;
    private statusChangeSubscription: Subscription = null;

    constructor(private constants: Constants,
                private transmittalService: TransmittalService,
                private attachmentService: AttachmentService,
                private paymentBatchService: PaymentBatchService,
                private readonly busyIndicatorService: BusyIndicatorService,
                private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private toastr: ToastrService,
                private websocketListenerService: WebsocketListenerService,
                private featureFlagsService: FeatureFlagsService,
                private utilities: UtilitiesService,
                private longRunningProcessRepository: LongRunningProcessRepository) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.statusChangeSubscription && this.statusChangeSubscription.unsubscribe();
    }

    close(): void {
        this.tooManyOutputsError = false;
        this.finalizePaymentPackageModal.hide();
    }

    show(): void {
        this.setupModal(true);
    }

    setupModal(showModal: boolean) {
        this.loading = false;
        this.cancelExecutionLoop = false;
        this.originalSelectedPackages = this.selectedPackages;
        if (showModal) {
            this.finalizePaymentPackageModal.show();
        }
        this.prepareOutputTypes();
    }

    prepareOutputTypes(): void {
        let outputType: TransmittalOutputType;

        //clear old value, if any, and initialize
        this.outputTypeOptions = [];

        //load selected packages
        this.selectedPackages = _.filter(this.packageList, (packageItem: PaymentPackage) => {
            return packageItem.isSelected === true;
        });

        //check if there are multiple output types in this selection
        const outputTypes: TransmittalOutputType[] = _.uniq(_.map(this.selectedPackages, 'transmittalOutputType'));
        if(outputTypes.length > 1) {
            this.tooManyOutputsError = true;
            return;
        } else if(outputTypes.length === 0) {
            //throw error?
            return;
        }

        //should only be one item by now
        outputType = outputTypes[0];
        this.selectedOutputType = outputType;
        this.selectedOutputChanged(this.selectedOutputType);

        //generate dropdown options based on current default output types
        if(parseInt(outputType.toString()) === TransmittalOutputType.PaperTransmittal ||
            parseInt(outputType.toString()) === TransmittalOutputType.DigitalTransmittal) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.PaperTransmittal, 'Paper Transmittal'));
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.DigitalTransmittal, 'Digital Transmittal'));
        }

        if(parseInt(outputType.toString()) === TransmittalOutputType.APFeed) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.APFeed, 'AP Feed'));
        }

        if(parseInt(outputType.toString()) === TransmittalOutputType.BillPay) {
            this.outputTypeOptions.push(new TransmittalOutputTypeOption(TransmittalOutputType.BillPay, 'Bill Pay'));
        }
    }

    /* The save function used to work by setting the modal's loading flag while processing, but for WK-4736 we determined that
    * we don't want the modal to be dismissable while processing. As a more robust solution the loading indicator was moved to the
    * parent page, however that now means the code is in a bit of a strange ordering. If this becomes a maintanence headache, we
    * should consider moving all the code that deals with finalizing into payment-packages.component and altering
    * payment-packages-finalize.modal so that the show function returns a promise.
    */
    save(): void {
        this.finalizeHasErrors = false;
        this.cancelExecutionLoop = false;
        this.finalizePaymentPackageModal.hide();

        this.originalSelectedPackages = _.cloneDeep(this.selectedPackages);
        this.selectedPackages = _.map(this.selectedPackages, (packageItem: PaymentPackage) => {
            packageItem.transmittalOutputType = this.selectedOutputType;
            return packageItem;
        });

        // TODO: Re-arrange logic so this doesn't have to be duplicated between the finalize modal and the main component
        const successMessage = 'Payment package(s) finalized.';
        const warningMessaage = 'One or more payment packages could not be processed. Please try again.';

        if (this.isFromPaymentBatch) {
            const busyRef = this.busyIndicatorService.show({ message: 'Finalizing package ...' });

            this.paymentBatchService.finalizeTransmittalPackage(this.paymentBatchId, this.taskId, this.originalSelectedPackages).then(() => {
                this.setParentLoading(false, null);
                this.toastr.success(successMessage);
                this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetailsWithTabIndex', {paymentBatchId: this.paymentBatchId, tabIndex: 1});
            }).catch(() => {
                this.finalizeHasErrors = true;
                this.toastr.warning(warningMessaage);
            }).finally(() => {
                busyRef.hide();
            });
        }
        else {
            this.transmitPackages().then(() => {
                this.setParentLoading(false, null);

                if(this.finalizeHasErrors) {
                    this.toastr.warning(warningMessaage);
                } else {
                    this.toastr.success(successMessage);
                }

                this.hasPackageFinalized.emit(true);
            }).catch(() => {
                //restore original packages since they weren't changed, but we altered them for transmittal
                this.selectedPackages = _.cloneDeep(this.originalSelectedPackages);
                this.setParentLoading(false, null);
            });
        }
    }

    transmitPackages(): Promise<void> {
        const itemCount = this.selectedPackages.flatMap(pp => pp.paymentTransmittals).flatMap(pt => pt.paymentTransmittalItems).length;
        const threshold = this.featureFlagsService.featureFlags.paymentPackageFinalizeLongRunningThreshold;

        // A 0 or negative threshold means LR processing is disabled. Otherwise check if the count is over or equal to the threshold.
        if (threshold > 0 && itemCount >= threshold) {
            return this.transmitPackagesLR();
        }

        // Not using LR process. Note that technically the finalize API endpoint can handle multiple packages at once, but this loops
        // through them one at a time to prevent any one API call from getting too long.
        const vm = this;

        return new Promise<void>((resolve) => {
            const paymentPackagesToFinalize = _.cloneDeep(this.selectedPackages);

            const executionLoop = function () {
                if (paymentPackagesToFinalize.length > 0 && vm.cancelExecutionLoop === false) {
                    const nextPackage: PaymentPackage = paymentPackagesToFinalize.shift();

                    vm.setParentLoading(true, `Finalizing Draft #${  nextPackage.packageNumber  }...`, paymentPackagesToFinalize.length === 0);

                    vm.transmitPackage(nextPackage).then(function () {
                        executionLoop();
                    }).catch(() => {
                        vm.finalizeHasErrors = true;
                        executionLoop();
                    });
                }
                else {
                    resolve();
                }
            };

            executionLoop();
        });
    }

    transmitPackagesLR(): Promise<void> {
        return new Promise(async resolve => {
            this.setParentLoading(true, 'Sending transmittal request...', true);

            const lrId = await this.transmittalService.FinalizePaymentPackagesLR({
                originalRequestRoute: window.location.hash,
                // TODO: Sort out models
                // Payment package functionality predates TypeLite integration, so none of the original code uses the exported types and instead
                // uses custom-built types. The LR finalize process is newer and uses a TypeLite model for the request, but it's incompatible
                // with the existing type. For now, just cast the existing type as "any" so it compiles, but eventually we need to upgrade all
                // the transmittal-related models to TypeLite models and adjust the UI accordingly.
                paymentPackages: <any>this.selectedPackages
            });

            this.subscribeLR(lrId, this.selectedPackages.length > 1, resolve);
        });
    }

    subscribeLR(lrId: number, isPlural: boolean, callback: () => unknown) {
        this.setParentLoading(true, `Finalizing selected draft${isPlural ? 's' : ''}...`, true);

        this.statusChangeSubscription && this.statusChangeSubscription.unsubscribe();
        this.statusChangeSubscription = this.websocketListenerService.longRunningProcessStatusChange$.subscribe(async status => {
            if (status.longRunningProcessId === lrId && status.isCompleted) {
                const lrProcess = await lastValueFrom(this.longRunningProcessRepository.get(lrId));
                if (lrProcess.error) {
                    this.finalizeHasErrors = true;
                    // If there are multiple packages there may have been a partial success; go check before finishing
                    if (isPlural) {
                        const finalizeResults = await this.transmittalService.GetLRFinalizeResults(lrId);
                        if (finalizeResults.length > 0) {
                            console.log('Partial success detected', finalizeResults);
                            this.processTransmitResult(finalizeResults);
                        }
                    }
                }
                else {
                    const lrResultObject = JSON.parse(lrProcess.result);

                    // Replicate what would have been done if this had been a conventional API return (see DateConvertInterceptor)
                    this.utilities.convertDateStringsToDates(lrResultObject);

                    this.processTransmitResult(lrResultObject.result);
                }
                this.statusChangeSubscription.unsubscribe();
                this.statusChangeSubscription = null;
                callback();
            }
        });
    }

    processTransmitResult(result: PaymentPackage[]): void {
        if(parseInt(this.selectedOutputType.toString()) === TransmittalOutputType.PaperTransmittal) {
            this.downloadAttachments(result);
        }

        //Old way of mapping the response to the bound items. This is necessary for now because it returns an array
        //but the logic is much simpler in theory
        _.map(this.packageList, (packageItem: PaymentPackage) => {

            const newPackage: PaymentPackage = _.find(result, { paymentPackageID: packageItem.paymentPackageID });

            if(newPackage) {
                //todo map items
                packageItem.completedDateTime = newPackage.completedDateTime;
                packageItem.completedByUserID = newPackage.completedByUserID;
                packageItem.packageNumber = newPackage.packageNumber;
                packageItem.emailText = newPackage.emailText;
                packageItem.emailSubject = newPackage.emailSubject;
                packageItem.attachments = newPackage.attachments;
                packageItem.apFeedAttachments = newPackage.apFeedAttachments;
            }

            packageItem.isSelected = false;
            packageItem.isFinalized = true;

            return packageItem;
        });
    }

    // Function to save a specific package and set up and map the return object so the actual package items are preserved.
    // Part of this is so we don't have to refetch the entire package list to get the new data as that's a heavy call
    transmitPackage(packageToFinalize: PaymentPackage): Promise<any> {
        return this.transmittalService.FinalizePaymentPackages([packageToFinalize]).then(result => {
            this.processTransmitResult(result);
        });
    }

    downloadAttachments(packageList: PaymentPackage[]): void {
        _.forEach(packageList, (packageItem: PaymentPackage) => {
            const attachmentInfo: AttachmentInfo = {
                attachmentID: null,
                entityTypeID: 19,
                entityID: packageItem.paymentPackageID,
                fileName: null,
                fileExtension: null
            };

            if (packageItem.attachments) {
                attachmentInfo.attachmentID = packageItem.attachments[0].attachmentID;
                attachmentInfo.fileName = packageItem.attachments[0].fileName;
                attachmentInfo.fileExtension = packageItem.attachments[0].fileExtension;
            }

            this.attachmentService.downloadAttachmentFile(attachmentInfo, true).subscribe();
        });
    }

    selectedOutputChanged(event) {

        switch(Number(event)){
            case TransmittalOutputType.PaperTransmittal:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.PaperTransmittal;
                break;
            case TransmittalOutputType.DigitalTransmittal:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.DigitalTransmittal;
                break;
            case TransmittalOutputType.APFeed:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.APFeed;
                break;
            case TransmittalOutputType.BillPay:
                this.addButtonLabel = TransmittalOutputTypeButtonLabel.BillPay;
                break;
            default:
                this.addButtonLabel = 'Add';
                break;
        }
    }
}
