import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import * as _ from 'lodash';
import { ToastrService } from "ngx-toastr";
import { DescriptorHelperService } from "./descriptor.helper.service";
import { DescriptorPicklistService } from "./descriptor.picklist.service";
import { TimerService } from '../UI-Lib/Utilities/timer.service';

export enum DescriptorFieldTypes {
    Text = 2,
    Number = 3,
    Date = 4,
    Picklist = 5,
    YesNo = 6,
    Currency = 7
}

@Component({
    selector: 'descriptor-validation',
    templateUrl: './descriptor.validation.component.html'
})
export class DescriptorValidationComponent implements OnInit {
    @Input() descriptor: Core.DescriptorDTO;
    @ViewChild('itemToAdd') itemToAdd: ElementRef;
    canLimitToList: boolean = false;
    picklist: Weissman.Model.Descriptors.DescriptorPickList[];
    formats: Weissman.Model.Descriptors.DescriptorFormat[];
    FieldTypes = DescriptorFieldTypes;
    picklistLoading: boolean = false;
    picklistSaving: boolean = false;
    itemToAddText: string = '';
    addingItem: boolean = false;

    constructor(private readonly _picklistService: DescriptorPicklistService,
                private readonly _toastr: ToastrService,
                private readonly _helperService: DescriptorHelperService,
                private readonly _timer: TimerService) { }

    async ngOnInit() {
        this.descriptor.validation = this.descriptor.validation || {} as Core.DescriptorValidationDTO;

        if (this.descriptor.pickListGroupID) {
            this.picklistLoading = true;
            this.canLimitToList = await this._picklistService.canLimitToList(this.descriptor.descriptorID, this.descriptor.pickListGroupID);
            const picklist = await this._picklistService.getByPicklistGroupId(this.descriptor.pickListGroupID);
            this.picklist = _.sortBy(picklist, 'name');
            this.picklistLoading = false;
        }

        if(this.descriptor.fieldTypeID == Core.DescriptorFieldTypes.Number) {
            this.formats = await this._helperService.getFormats();
        }
    }

    async savePicklistItem(item: Weissman.Model.Descriptors.DescriptorPickList): Promise<void> {
        this.picklistSaving = true;
        await this._picklistService.updateItem(item);
        this.picklistSaving = false;
    }

    async deletePicklistItem(item: Weissman.Model.Descriptors.DescriptorPickList): Promise<void> {
        const result = await this._picklistService.deleteItemById(item.descriptorPickListID);

        if (result) {
            item.disabled = true;
        } else {
            _.remove(this.picklist, item);
        }
    }

    showPicklistItemInput(): void {
        this.addingItem = true;

        this._timer.setTimeout(() => this.itemToAdd.nativeElement.focus());
    }

    async addPicklistItem(): Promise<void> {
        if(!_.trim(this.itemToAddText)) {
            this._toastr.error('Picklist item must not be empty!');
            return;
        }

        const picklistItemToAdd = {
            disabled: false,
            name: this.itemToAddText,
            groupID: this.descriptor.pickListGroupID || 0
        } as Weissman.Model.Descriptors.DescriptorPickList

        this.picklistSaving = true;
        const result = await this._picklistService.createItem(picklistItemToAdd);

        this.descriptor.pickListGroupID = this.descriptor.pickListGroupID || result.groupID;

        this.picklist = this.picklist || [];
        this.picklist.push(result);
        this.itemToAddText = '';
        this.addingItem = false;
        this.picklistSaving = false;
    }

}
