<div class="modal fade" bsModal #paymentPackagesModal="bs-modal" [config]="{backdrop: true}" tabindex="-1" role="dialog" aria-hidden="true" (onShow)="showModalInit()">
    <div class="modal-dialog modal-lg" style="width: 80%; height: 90%">
        <div class="modal-content" style="height: 100%; overflow: auto;">
            <div class="modal-body exclude-modal-styling">
                <payment-packages #paymentPackages [isModal]="true" [paymentID]="paymentID">
                </payment-packages>
            </div>
            <div class="modal-footer">
                <button class="flat-button primary-button" (click)="paymentPackageModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
