import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AgGridModule } from 'ag-grid-angular';
import { DragulaModule } from 'ng2-dragula';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BusyIndicatorModule } from '../../Busy-Indicator'
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { ValuationModule } from '../../Valuation/valuation.module';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer} from '../AgGrid/MultiSelectTracker';
import { AssetClassSelectorModule } from '../Asset/Asset-Class-Selector/assetClassSelector.module';
import { EntityImportProcessComponent } from './Import-Process/importProcess.component';
import { EntityImportProcessService } from './Import-Process/importProcess.service';
import { AgGridMappingHeaderRowCellRendererComponent } from './Import-Process/Column-Mapping-Step/agGridHeaderRowCellRenderer.component';
import { EntityImportColumnMappingStepComponent } from './Import-Process/Column-Mapping-Step/columnMappingStep.component';
import { EntityImportColumnMappingStepService } from './Import-Process/Column-Mapping-Step/columnMappingStep.service';
import { EntityImportEditingStepComponent } from './Import-Process/Editing-Step/editingStep.component';
import { EntityImportEditorComponent } from './Import-Process/Editing-Step/Editor/editor.component';
import { EntityImportValidationSummaryComponent } from './Import-Process/Editing-Step/Validation-Summary/validationSummary.component';
import { EntityImportEditorEstimatedActionHeaderRendererComponent } from './Import-Process/Editing-Step/Editor/agGridEstimatedActionHeaderRenderer.component';
import { EntityImportEditorEstimatedActionFilterRendererComponent } from './Import-Process/Editing-Step/Editor/agGridEstimatedActionFilterRenderer.component';
import { EntityImportEditorEstimatedActionCellRendererComponent } from './Import-Process/Editing-Step/Editor/agGridEstimatedActionCellRenderer.component';
import { AgGridOverlayComponent } from './Import-Process/Column-Mapping-Step/Drag-And-Drop-Overlay/agGridOverlay.component';
import { AgGridOverlayService } from './Import-Process/Column-Mapping-Step/Drag-And-Drop-Overlay/agGridOverlay.service';
import { EntityImportUploadComponent } from './Upload/upload.component';
import { EntityImportStaticFieldsComponent } from './Static-Fields/staticFields.component';
import { EntityImportListComponent } from './Import-List/importList.component';
import { EntityImportListGridActionCellRendererComponent } from './Import-List/agGridActionCellRenderer.component';
import { EntityImportListGridProgressCellRendererComponent } from './Import-List/agGridProgressCellRenderer.component';
import { StepProgressIndicatorComponent } from '../../Common/Step-Progress-Indicator/stepProgressIndicator.component';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { MessageModalComponent } from '../../UI-Lib/Message-Box/message-modal.component';
import { EntityImportSpecificationMappingComponent } from './Import-Process/Column-Mapping-Step/Import-Specification-Mapping/importSpecificationMapping.component';
import { AgGridDateFilterComponent } from '../AgGrid';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { StepProgressIndicatorModule } from '../../Common/Step-Progress-Indicator/stepProgressIndicator.module';
import { EntityImportEditorAssetClassSelectorCellEditorComponent } from './Import-Process/Editing-Step/Editor/SelectorRenderers/agGridAssetClassSelectorRenderer.component';
import { AssetModule } from '../Asset/asset.module';
import { EntityImportProgressComponent } from './Import-Progress/entityImportProgress.component';
import { ImportBulkUpdateComponent } from './Import-Process/Editing-Step/Editor/Import-Bulk-Update/importBulkUpdate.component';
import { ImportBulkUpdateActionComponent } from './Import-Process/Editing-Step/Editor/Import-Bulk-Update/importBulkUpdateAction.component';
import { AssessorSelectorComponent } from './Import-Process/Editing-Step/Editor/Selectors/Assessor-Selector/assessorSelector.component';
import { EntityImportEditorAssessorSelectorCellEditorComponent } from './Import-Process/Editing-Step/Editor/SelectorRenderers/agGridAssessorSelectorRenderer.component';
import { ImportSpecificationListComponent } from './Import-Specification-List/importSpecificationList.component';
import { ImportSpecificationListGridActionCellRendererComponent } from './Import-Specification-List/agGridActionCellRenderer.component';
import { TransferConfirmComponent } from "./Import-Process/Transfer-Confirm/transferConfirm.component";
import { EntityImportSpecificationEditComponent } from './Import-Specification-List/Import-Specification-Edit/importSpecificationEdit.component';
import { AttachmentTypeSelectorComponent } from "./Import-Process/Editing-Step/Editor/Selectors/Attachment-Type-Selector/attachmentTypeSelector.component";
import { EntityImportEditorAttachmentTypeSelectorCellEditorComponent } from "./Import-Process/Editing-Step/Editor/SelectorRenderers/agGridAttachmentTypeSelectorRenderer.component";
import { EntityImportEditorLeaseTypeSelectorCellEditorComponent } from "./Import-Process/Editing-Step/Editor/SelectorRenderers/agGridLeaseTypeSelectorRenderer.component";
import { LeaseTypeSelectorComponent } from "./Import-Process/Editing-Step/Editor/Selectors/Lease-Type-Selector/leaseTypeSelector.component";
import { ExcelPreviewComponent } from "./Excel-Preview/excelPreview.component";
import { AssessmentClassSelectorComponent } from "./Import-Process/Editing-Step/Editor/Selectors/AssessmentClass-Selector/assessmentClassSelector.component";
import { EntityImportEditorAssessmentClassSelectorCellEditorComponent } from "./Import-Process/Editing-Step/Editor/SelectorRenderers/agGridAssessmentClassSelectorRenderer.component";
import { GLAccountSelectorComponent } from "./Import-Process/Editing-Step/Editor/Selectors/GLAccount-Selector/glAccountSelector.component";
import { EntityImportEditorGLAccountSelectorCellEditorComponent } from "./Import-Process/Editing-Step/Editor/SelectorRenderers/agGridGLAccountSelectorRenderer.component";
import { ImportMappingModalComponent } from './Import-Process/Column-Mapping-Step/Import-Mapping-Modal/importMappingModal.component';
import { TaxingDistrictSelectorComponent } from "./Import-Process/Editing-Step/Editor/Selectors/TaxingDistrict-Selector/taxingDistrictSelector.component";
import { EntityImportEditorTaxingSelectorCellEditorComponent } from "./Import-Process/Editing-Step/Editor/SelectorRenderers/agGridTaxingDistrictSelectorRenderer.component";
import { TypeaheadSelectorComponent } from './Import-Process/Editing-Step/Editor/Selectors/Typeahead-Selector/typeaheadSelector.component';
import { EntityImportEditorOneTimeVRecurringSelectorRendererCellEditorComponent } from './Import-Process/Editing-Step/Editor/SelectorRenderers/agGridOneTimeVRecurringSelectorRenderer.component';
import { OneTimeVRecurringSelectorComponent } from './Import-Process/Editing-Step/Editor/Selectors/OneTimeVRecurring-Selector/oneTimeVRecurringSelector.component';
import { EntityImportService } from './entityImport.service';
import { AssetLienDateModule } from '../Asset/Asset-Lien-Date/assetLienDate.module';
import { EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent } from './Import-Process/Editing-Step/Editor/SelectorRenderers/agGridIncomeStatementCategorySelectorRenderer.component'
import { EntityImportEditorGLAccountTypeSelectorCellEditorComponent } from './Import-Process/Editing-Step/Editor/SelectorRenderers/agGridGLAccountTypeSelectorRenderer.component';
import {
    DynamicFieldBehaviorHelpComponent
} from './Import-Process/Dynamic-Field-Behavior/Dynamic-Field-Behavior-Help/dynamicFieldBehaviorHelp.component';
import {
    DynamicFieldBehaviorConfigurationComponent
} from './Import-Process/Dynamic-Field-Behavior/Dynamic-Field-Behavior-Configuration/dynamicFieldBehaviorConfiguration.component';
import { DynamicFieldBehaviorComponent } from './Import-Process/Dynamic-Field-Behavior/dynamicFieldBehavior.component';
import {
    DynamicFieldSelectedBehaviorHelpComponent
} from './Import-Process/Dynamic-Field-Behavior/Dynamic-Field-Selected-Behavior-Help/dynamicFieldSelectedBehaviorHelp.component';
import { DynamicFieldBehaviorService } from './Import-Process/Dynamic-Field-Behavior/dynamicFieldBehavior.service';

@NgModule({
    imports: [
        WeissmanSharedModule,
        SharedPipesModule,
        BsDropdownModule,
        TabsModule,
        ModalModule,
        SortableModule,
        ButtonsModule,
        TooltipModule,
        CurrencyMaskModule,
        DragulaModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        StepProgressIndicatorModule,
        AssetModule,
        TypeaheadModule,
        AssetClassSelectorModule,
        ValuationModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            AgGridDateFilterComponent,
            // import list
            EntityImportListGridActionCellRendererComponent,
            EntityImportListGridProgressCellRendererComponent,
            // mapping
            AgGridMappingHeaderRowCellRendererComponent,
            // editing
            EntityImportEditorEstimatedActionHeaderRendererComponent,
            EntityImportEditorEstimatedActionFilterRendererComponent,
            EntityImportEditorEstimatedActionCellRendererComponent,
            EntityImportEditorAssessorSelectorCellEditorComponent,
            AttachmentTypeSelectorComponent,
            EntityImportEditorAttachmentTypeSelectorCellEditorComponent,
            LeaseTypeSelectorComponent,
            EntityImportEditorLeaseTypeSelectorCellEditorComponent,
            AssessmentClassSelectorComponent,
            GLAccountSelectorComponent,
            EntityImportEditorGLAccountSelectorCellEditorComponent,
            EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent,
            EntityImportEditorGLAccountTypeSelectorCellEditorComponent,
            // specification
            ImportSpecificationListGridActionCellRendererComponent,
            EntityImportEditorAssessmentClassSelectorCellEditorComponent,
            EntityImportEditorTaxingSelectorCellEditorComponent,
            EntityImportEditorOneTimeVRecurringSelectorRendererCellEditorComponent,
            OneTimeVRecurringSelectorComponent
        ]),
        AssetLienDateModule
    ],
    declarations: [
        AgGridMappingHeaderRowCellRendererComponent,
        AgGridOverlayComponent,
        EntityImportStaticFieldsComponent,
        EntityImportEditorComponent,
        EntityImportProcessComponent,
        EntityImportUploadComponent,
        EntityImportColumnMappingStepComponent,
        EntityImportEditorComponent,
        EntityImportListComponent,
        EntityImportValidationSummaryComponent,
        EntityImportListGridActionCellRendererComponent,
        EntityImportListGridProgressCellRendererComponent,
        EntityImportEditorEstimatedActionHeaderRendererComponent,
        EntityImportEditorEstimatedActionFilterRendererComponent,
        EntityImportEditorEstimatedActionCellRendererComponent,
        MessageModalComponent,
        EntityImportEditingStepComponent,
        EntityImportSpecificationMappingComponent,
        EntityImportEditorAssetClassSelectorCellEditorComponent,
        EntityImportProgressComponent,
        ImportBulkUpdateComponent,
        ImportBulkUpdateActionComponent,
        EntityImportEditorAssessorSelectorCellEditorComponent,
        AssessorSelectorComponent,
        ImportSpecificationListComponent,
        ImportSpecificationListGridActionCellRendererComponent,
        EntityImportSpecificationEditComponent,
        TransferConfirmComponent,
        AttachmentTypeSelectorComponent,
        EntityImportEditorAttachmentTypeSelectorCellEditorComponent,
        LeaseTypeSelectorComponent,
        EntityImportEditorLeaseTypeSelectorCellEditorComponent,
        ExcelPreviewComponent,
        AssessmentClassSelectorComponent,
        EntityImportEditorAssessmentClassSelectorCellEditorComponent,
        GLAccountSelectorComponent,
        EntityImportEditorGLAccountSelectorCellEditorComponent,
        EntityImportEditorGLAccountTypeSelectorCellEditorComponent,
        ImportMappingModalComponent,
        TaxingDistrictSelectorComponent,
        EntityImportEditorTaxingSelectorCellEditorComponent,
        TypeaheadSelectorComponent,
        EntityImportEditorOneTimeVRecurringSelectorRendererCellEditorComponent,
        OneTimeVRecurringSelectorComponent,
        EntityImportEditorIncomeStatementCategorySelectorCellEditorComponent,
        DynamicFieldBehaviorComponent,
        DynamicFieldBehaviorHelpComponent,
        DynamicFieldBehaviorConfigurationComponent,
        DynamicFieldSelectedBehaviorHelpComponent
    ],
    providers: [
        AgGridOverlayService,
        EntityImportProcessService,
        EntityImportColumnMappingStepService,
        MessageModalService,
        EntityImportService,
        DynamicFieldBehaviorService
    ],
    exports: []
})
export class EntityImportModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('entityImportList', EntityImportListComponent);
        hybridAdapterUtility.downgradeNg2Component('entityImportProcess', EntityImportProcessComponent);
        hybridAdapterUtility.downgradeNg2Component('importSpecificationList', ImportSpecificationListComponent);
    }
}
