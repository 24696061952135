<ws-modal-wrapper headerLabel="Income Statement Categories"
                  [hideFooter]="true"
                  (cancel)="cancel()">
    <div class="ws-picker-tree">
        <ul *ngIf="incomeStatementCategories">
            <li *ngFor="let topLevel of incomeStatementCategories; index as i">
                <div [ngClass]="i % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                    <span class="node-collapsed" [ngClass]="topLevel.childIncomeStatementCategories.length > 0 ? '' : 'empty-tree-node'" *ngIf="!isExpanded(topLevel); else collapse" (click)="expandCategory(topLevel)"></span>
                    <ng-template #collapse>
                        <span class="node-expanded" [ngClass]="topLevel.childIncomeStatementCategories.length > 0 ? '' : 'empty-tree-node'" (click)="collapseCategory(topLevel)"></span>
                    </ng-template>
                    <span class="entity-link" [ngClass]="isSelected(topLevel) ? 'active-entity-link' : ''" (click)="selectIncomeStatementCategory(topLevel)" [title]="topLevel.name">{{ topLevel.name }}</span>
                </div>
                <ul *ngIf="isExpanded(topLevel)" [@pickerEnter]>
                    <li *ngFor="let secondLevel of topLevel.childIncomeStatementCategories; index as j">
                        <div class="tree-line-level-2" [ngClass]="(i + j + 1) % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                            <span class="node-collapsed" [ngClass]="secondLevel.childIncomeStatementCategories.length > 0 ? '' : 'empty-tree-node'" *ngIf="!isExpanded(secondLevel); else collapseSecondLevel" (click)="expandCategory(secondLevel)"></span>
                            <ng-template #collapseSecondLevel>
                                <span class="node-expanded" [ngClass]="secondLevel.childIncomeStatementCategories.length > 0 ? '' : 'empty-tree-node'" (click)="collapseCategory(secondLevel)"></span>
                            </ng-template>
                            <span class="entity-link" [ngClass]="isSelected(secondLevel) ? 'active-entity-link' : ''" (click)="selectIncomeStatementCategory(secondLevel)" [title]="secondLevel.name">{{ secondLevel.name }}</span>
                        </div>
                        <ul *ngIf="isExpanded(secondLevel)" [@pickerEnter]>
                            <li *ngFor="let thirdLevel of secondLevel.childIncomeStatementCategories; index as k">
                                <div class="tree-line-level-3" [ngClass]="(i + j + k) % 2 ? 'tree-line-holder-odd' : 'tree-line-holder-even'">
                                    <span class="entity-link" [ngClass]="isSelected(thirdLevel) ? 'active-entity-link' : ''" (click)="selectIncomeStatementCategory(thirdLevel)" [title]="thirdLevel.name">{{ thirdLevel.name }}</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</ws-modal-wrapper>
