import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomeEmailTemplateService } from './custom-email-template.service';
import {PackageEmailTemplate} from "../../Processing/Transmittal/transmittal.models";
import {MessageBoxService} from "../../UI-Lib/Message-Box/messagebox.service.upgrade";
import { TimerService } from '../../UI-Lib/Utilities/timer.service';

declare const $: any;

@Component({
    selector: 'custom-email-template-modal',
    templateUrl: './custom-email-template-modal.component.html'
})
export class CustomEmailTemplateModalComponent implements OnInit {
    onClose: Function;
    template: any;
    packageEmailTemplates: PackageEmailTemplate[];
    selectedEmailTemplate: PackageEmailTemplate;
    emailTemplateFields: any = {};
    editorInstance: any;

    constructor(public bsModalRef: BsModalRef,
                private readonly _customEmailTemplateService: CustomeEmailTemplateService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _timer: TimerService) { }

    ngOnInit() {
        this._customEmailTemplateService.getEmailTemplateFields()
            .then((result) => {
                let sortedResults = _.sortBy(result, 'name');

                this.emailTemplateFields.body = sortedResults;
                this.emailTemplateFields.subject = _.filter(sortedResults, 'validInSubject');
            });

        this._timer.setTimeout(() => {
            $('#custom-template-subject').focus();
        });
    }

    addBindingCreated(quillInstance): void {
        this.editorInstance = quillInstance;
    }

    insertFieldInBody(field): void {
        let cursorPosition: number = this.editorInstance.getSelection(true).index;
        let fieldName: string = '{{ ' + field.name + ' }}';

        this.editorInstance.insertText(cursorPosition, fieldName);
    }

    // http://jsfiddle.net/4abr7jc5/2/
    insertFieldInSubject(field): void {
        var cursorPosStart = $('#custom-template-subject').prop('selectionStart');
        var cursorPosEnd = $('#custom-template-subject').prop('selectionEnd');
        var v = $('#custom-template-subject').val();
        var textBefore = v.substring(0, cursorPosStart);
        var textAfter = v.substring(cursorPosEnd, v.length);
        var fieldName = '{{ ' + field.name + ' }}';

        $('#custom-template-subject').val(textBefore + fieldName + textAfter);
        this.template.packageEmailCustomTemplateSubject = $('#custom-template-subject').val();

        $('#custom-template-subject').focus();
    }

    baseTemplateChange(event): void {
        let oldSelectedTemplate: PackageEmailTemplate = _.cloneDeep(this.selectedEmailTemplate);

        if (this.template.packageEmailCustomTemplateBody || this.template.packageEmailCustomTemplateSubject) {
            if (confirm("Changing the base template will overwrite any current values. Do you wish to continue?")) {
                this.selectedEmailTemplate = event;
                this.copySelectedTemplateFields();
            } else {
                this.selectedEmailTemplate = _.cloneDeep(oldSelectedTemplate);
            }
        } else {
            this.selectedEmailTemplate = event;
            this.copySelectedTemplateFields();
        }
    }

    copySelectedTemplateFields(): void {
        this.template.packageEmailCustomTemplateSubject = this.selectedEmailTemplate.subjectTemplate;
        this.template.packageEmailCustomTemplateBody = this.selectedEmailTemplate.bodyTemplate;
    }

    closeModal(template?): void {
        this.onClose(template);
        this.bsModalRef.hide();
    }
}
