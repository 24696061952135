import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { SearchPageType, UserSaveService } from '../../Navigation/User-Save/userSave.service';
import {
    BudgetVarianceBudgetSource,
    EntitySelection,
    GetEntitiesRequest,
    ReportDetail,
    SelectedEntity
} from './report.manager.model';
import { ReportManagerService } from './report.manager.service';
import { ReportManagerSelectEntitiesService } from './select.entities.service';
import { ReportManagerModalInputParameters } from './reportManagerModal.component';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { SaveReportModalComponent } from '../Modals/save-report.component';
import { InstanceRights, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { ToastrService } from 'ngx-toastr';
import {
    BudgetRequest,
    BudgetVarianceReportingRequest,
    MaarRequest,
    ProgressStatusRequest,
    PsrRequest,
    ReportingService,
    SiteRequest, SupportInstanceEscalationActivityReportRequest,
    UserAccessRequest,
    UserActivityRequest,
    UserCSRRequest
} from '../reporting.service';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ReportType } from './report-type';
import { FormRepository, ReturnRepository } from '../../Compliance/Repositories';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { REPORT_MANAGER_HELP } from './report.manager.component.help';
import { ContactsUpgradeService } from '../../Contact/contacts.service.upgrade';
import { ExtendedContactModelCore } from '../../Common/User-Pickers/user.picker.component';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';

import SearchType = Core.SearchType;
import { SnackBarService } from '../../Busy-Indicator';
import { EntityTypeIds } from '../../constants.new';
import { lastValueFrom } from 'rxjs';
import { UserInstanceService } from 'src/app/User/userInstance.service';

declare const _: any;

enum StartWithEnum {
    Company = Core.UserAccessReportStartWithEnum.Company as number,
    SingleUser = Core.UserAccessReportStartWithEnum.SingleUser as number
}

@Component({
    selector: 'report-manager',
    templateUrl: './report.manager.component.html'
})
export class ReportManagerComponent implements OnInit {
    constructor(
        private readonly _reportManagerService: ReportManagerService,
        private readonly _selectEntitiesService: ReportManagerSelectEntitiesService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _restrictService: RestrictService,
        private readonly _toastr: ToastrService,
        private readonly _reportingService: ReportingService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _formRepository: FormRepository,
        private readonly _returnRepository: ReturnRepository,
        private readonly _helpService: HelpService,
        private readonly _contactsService: ContactsUpgradeService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _snackBarService: SnackBarService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _weissmanModalService: WeissmanModalService,
        private readonly _userSaveService: UserSaveService
    ) {
        this.saveModalClosed = new EventEmitter();
    }

    @Input() modalParameters?: ReportManagerModalInputParameters;
    @Output() saveModalClosed: EventEmitter<any>;

    report: ReportDetail;
    loading: boolean;
    initializingReport: boolean;
    isSystemReport: boolean;
    hasSystemReportPermission: boolean;
    hasRyanPrivatePermission: boolean;
    saveVisible: boolean;
    saveAsVisible: boolean;
    reportTypes = ReportType;
    availableYears: number[];
    isInModal: boolean = false;
    userSearch: any;
    isSingleInstanceSelected: boolean = false;
    StartWithEnum = StartWithEnum;

    get showEntitiesSelector(): boolean {
        return this.report.reportTypeId !== this.reportTypes.ReturnScheduleSummary &&
            this.report.reportTypeId !== this.reportTypes.ReturnAssetDetail &&
            this.report.reportTypeId !== this.reportTypes.UserCSR &&
            this.report.criteria.startWith === Core.UserAccessReportStartWithEnum.Company;
    }

    get requestButtonIsDisabled(): boolean {
        if(this.initializingReport) {
            return true;
        }

        if(this.report.criteria.startWith === Core.UserAccessReportStartWithEnum.SingleUser || this.report.reportTypeId === this.reportTypes.UserCSR) {
            return !this.report.criteria.selectedContact;
        } else if (this.report.reportTypeId === this.reportTypes.SupportInstanceEscalationActivity) {
            return !(!!this.report.criteria.escalationDateBegin && !!this.report.criteria.escalationDateEnd || this.report.criteria.anyEscalationDates);
        } else {
            return !this.companiesSelected;
        }
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(REPORT_MANAGER_HELP);

        this.hasSystemReportPermission = this._restrictService.isInRole(Roles.SYSTEMSEARCHESEDIT);
        this.hasRyanPrivatePermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
        this.isSingleInstanceSelected = this._userInstanceService.isSingleInstanceSelected();

        // parameters passed via route or via input
        let reportID: number;
        if (this.modalParameters) {
            reportID = this.modalParameters.reportId;
            this.isSystemReport = this.modalParameters.reportType === 0;
            this.isInModal = true;
        } else {
            reportID = Number(this._upgradeNavigationServiceHandler.getQuerystringParam('reportId'));
            this.isSystemReport = Number(this._upgradeNavigationServiceHandler.getQuerystringParam('reportType')) === 0;
        }
        await this.refresh(reportID);

        this.availableYears = this._reportManagerService.getAvailableYears();

        this.userSearch = {
            find: (filter) => {
                return this._contactsService.search(null, filter, 1, 100, this.report.reportTypeId !== this.reportTypes.UserCSR);
            }
        };
    }

    async refresh(reportID?: number): Promise<void> {
        if (reportID === undefined) {
            reportID = this.report.reportID;
        }

        this.loading = true;

        try {
            this.saveVisible = false;
            this.saveAsVisible = true;
            this.report = null;
            if (this.modalParameters && this.modalParameters.filingBatchId) {
                this.report = await this._returnRepository.getFilingBatchReport(this.modalParameters.filingBatchId, reportID, SearchType.Report);
            } else {
                this.report = await this._reportManagerService.getReport(reportID);
            }

            if (this.hasRestrictedAccess(this.report)) {
                this._upgradeNavigationServiceHandler.go('unauthorizedAccess', {});

                return;
            }

            this.report.criteria.entitySelection = this.report.criteria.entitySelection || new EntitySelection();
            if(_.isUndefined(this.report.criteria.startWith)) {
                this.report.criteria.startWith = Core.UserAccessReportStartWithEnum.Company;
            }
            this.updateSaveVisible();
        } finally {
            this.loading = false;
        }
    }

    async saveReport(): Promise<void> {
        if (!this._isComplianceReport()) {
            this._saveSystemReport();
        } else if (this._isComplianceReport() && this.modalParameters && this.modalParameters.filingBatchId) {
            const updatedReport = await lastValueFrom(this._returnRepository.saveCustomReport(this.modalParameters.filingBatchId, this.report.reportID, this.report));
            this.handleSaveModalClose(updatedReport);
        } else if (this._isComplianceReport() && this.modalParameters && this.modalParameters.formRevisionId) {
            const updatedReport = await lastValueFrom(this._formRepository.saveCustomReport(this.modalParameters.formRevisionId, this.report.reportID, this.report));
            this.handleSaveModalClose(updatedReport);
        } else {
            // regular endpoint works for non-formrev / filing batch compliance reports
            this._saveSystemReport();
        }
    }

    async saveReportAs(): Promise<void> {
        const params = {
            report: this.report,
            isSystemReport: this.isSystemReport,
            modalParameters: this.modalParameters
        };
        const result = await this._weissmanModalService.showAsync(SaveReportModalComponent, params, 'modal-lg');

        if (!result) {
            return;
        }

        this.handleSaveModalClose(result);

        if (!this._isComplianceReport()) {
            this._upgradeNavigationServiceHandler.transitionTo(this._upgradeNavigationServiceHandler.getCurrentState(), {
                reportId: this.report.reportID,
                reportType: result.isSystemReport ? 0 : 1
            }, {
                reload: true
            });
        }
        this._userSaveService.updateList(SearchPageType.Report);

        if (!this.modalParameters) {
            this._toastr.success('Report saved');
        }
    }

    handleSaveModalClose(report: any) {
        this._userSaveService.updateList(SearchPageType.Report);
        this.saveModalClosed.emit(report);
    }

    async requestReport() {
        this.initializingReport = true;

        try {
            let lrpId: number;
            let lrpTypeId: number;
            switch (this.report.reportTypeId) {
                case this.reportTypes.ProjectStatus:
                    lrpId = await this.requestPsrReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.PsrReport;
                    break;
                case this.reportTypes.AppealRecommendation:
                    lrpId = await this.requestPsrReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.AppealListReport;
                    break;
                case this.reportTypes.Maar:
                    lrpId = await this.requestMaarReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.MaarReport;
                    break;
                case this.reportTypes.Budget:
                    lrpId = await this.requestBudgetReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.BudgetReport;
                    break;
                case this.reportTypes.BudgetVariance:
                    lrpId = await this.requestBudgetVarianceReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.BudgetVarianceReport;
                    break;
                case this.reportTypes.ProgressStatus:
                    lrpId = await this.requestProgressStatusReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.PseReport;
                    break;
                case this.reportTypes.Site:
                    lrpId = await this.requestSiteReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.SiteReport;
                    break;
                case this.reportTypes.UserActivity:
                    lrpId = await this.requestUserActivity();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.UserActivityReport;
                    break;
                case this.reportTypes.UserAccess:
                    lrpId = await this.requestUserAccess();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.UserAccessReport;
                    break;
                case this.reportTypes.UserCSR:
                    lrpId = await this.requestUserCSR();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.UserCsrReport;
                    break;
                case this.reportTypes.SupportInstanceEscalationActivity:
                    lrpId = await this.requestSupportInstanceEscalationActivityReport();
                    lrpTypeId = Compliance.LongRunningProcessTypeEnum.UserCsrReport;
                    break;
            }
            if (lrpId) {
                this._snackBarService.addById(lrpId, lrpTypeId);
            }
        } finally {
            this.initializingReport = false;
        }
    }

    showRevokeReportButton(): boolean {
        return this.report.isOwnedByUser && this.report.isShared;
    }

    revokeReportShare(): void {
        this._messageBoxService.confirm('Sharing with other users will be revoked. Click OK to confirm.', 'Reports')
            .then(() => {
                this._reportManagerService.revokeShare(this.report).then((result: any) => {
                    this.report.isShared = result.isShared;
                    this._toastr.success('Share was revoked');
                });
            });
    }

    updateFavoriteStatus(isFavorite: boolean): void {
        this.report.criteria.frequentlyUsed = isFavorite;
    }

    showRequestReportButton(): boolean {
        return this.report.reportTypeId !== this.reportTypes.ReturnScheduleSummary &&
            this.report.reportTypeId !== this.reportTypes.ReturnAssetDetail;
    }

    setSelectedContact(contact: ExtendedContactModelCore) {
        this.report.criteria.selectedContact = contact.model;
    }

    private hasRestrictedAccess(report: ReportDetail): boolean {
        if (report.categoryID === Core.SavedSearchCategoryEnum.ComplianceReportSetup || report.categoryID === Core.SavedSearchCategoryEnum.ComplianceCustomReport) {
            return !this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW);
        }

        if (report.reportTypeId === this.reportTypes.UserActivity) {
            return !this._restrictService.isInRole(Roles.USERREPORTSALLOW);
        }

        if (report.reportTypeId == this.reportTypes.Maar) {
            return !this.hasRyanPrivatePermission;
        }

        if (report.reportTypeId === this.reportTypes.SupportInstanceEscalationActivity) {
            return !this._restrictService.isInRole(Roles.ALLOWSUPPORTESCALATIONREPORT);
        }

        return false;
    }

    private updateSaveVisible(): void {
        if (this.isInModal) {
            // for our compliance reports, in the modal we never save system, only create custom ones based
            // on them. Save as on custom would take an extra workflow
            this.saveVisible = !this.isSystemReport;
            this.saveAsVisible = this.isSystemReport;
        } else {
            const specialCompliancePermission: boolean = this.hasSystemReportPermission && this._isComplianceReport();
            this.saveVisible = (specialCompliancePermission && !this.isSystemReport) || (this.report.variantName && this.report.isOwnedByUser && (!this.isSystemReport || this.hasSystemReportPermission));
        }
    }

    private _saveSystemReport(): void {
        this._reportManagerService.saveReport(this.report, this.isSystemReport).then((result: any) => {
            this.report.rowVersion = result.rowVersion;
            this._userSaveService.updateList(SearchPageType.Report);

            if (!this.modalParameters) {
                this._toastr.success('Report saved');
            }
        });
    }

    private _isComplianceReport(): boolean {
        return [Core.SavedSearchCategoryEnum.ComplianceReportSetup,
            Core.SavedSearchCategoryEnum.ComplianceReturnReport,
            Core.SavedSearchCategoryEnum.CompliancePackageReport,
            Core.SavedSearchCategoryEnum.ComplianceCustomReport].includes(this.report.categoryID);
    }

    private async requestBudgetReport(): Promise<number> {
        if (this.report.criteria.entitySelection.selectedTopLevelCompanyIDs.length > 1) {
            this._toastr.error('Select only one Top Level Company for this report');

            return null;
        }

        const reportRequest = new BudgetRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);

        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = this.report.variantName || this.report.displayName;
        reportRequest.budgetYear = this.report.criteria.budgetYear;
        reportRequest.budgetYearString = this.report.criteria.budgetYearString;
        reportRequest.outputFormat = this.report.criteria.outputFormat;
        //reportRequest.includePPPropertyType = this.report.criteria.includePPPropertyType;
        reportRequest.propertyTypes = this.report.criteria.propertyTypeIDs;
        reportRequest.minimumDetailYearsShown = this.report.criteria.minimumDetailYearsShown;
        reportRequest.currCompanyBudgetID = this.report.criteria.currCompanyBudgetID;
        reportRequest.priorCompanyBudgetID = this.report.criteria.priorCompanyBudgetID;
        reportRequest.budgetBasis = this.report.criteria.budgetBasis;
        reportRequest.includeDisclaimer = this.report.criteria.includeDisclaimer;
        reportRequest.detailReportOptions = this.report.criteria.detailReportOptions;
        reportRequest.summaryReportOptions = this.report.criteria.summaryReportOptions;

        return await this._reportingService.runBudgetReport(reportRequest);
    }

    private async requestBudgetVarianceReport(): Promise<number> {
        if (this.report.criteria.entitySelection.selectedTopLevelCompanyIDs.length > 1) {
            this._toastr.error('Select only one Top Level Company for this report');

            return null;
        }
        if (this.report.criteria.bvrFormatOutput.budgetSources[0].budgetId < 0 ||
            this.report.criteria.bvrFormatOutput.budgetSources[1].budgetId < 0) {
            this._toastr.error('A selection must be made for the 1st and 2nd Source Budgets');
            return null;
        }
        if (this.report.criteria.bvrFormatOutput.budgetSources[3].budgetId > 0 &&
            this.report.criteria.bvrFormatOutput.budgetSources[2].budgetId < 0) {
            this._toastr.error('A 3rd Source Budget must be selected if a 4th is selected');
            return null;
        }
        if (this.report.criteria.bvrFormatOutput.budgetSources[1].budgetPeriod.periodBegin > this.report.criteria.bvrFormatOutput.budgetSources[0].budgetPeriod.periodBegin ||
            (this.report.criteria.bvrFormatOutput.budgetSources[2].budgetId > 0 &&
             this.report.criteria.bvrFormatOutput.budgetSources[2].budgetPeriod.periodBegin > this.report.criteria.bvrFormatOutput.budgetSources[1].budgetPeriod.periodBegin) ||
            (this.report.criteria.bvrFormatOutput.budgetSources[3].budgetId > 0 &&
             this.report.criteria.bvrFormatOutput.budgetSources[3].budgetPeriod.periodBegin > this.report.criteria.bvrFormatOutput.budgetSources[2].budgetPeriod.periodBegin)) {
            this._toastr.error('Each Source Budget must be in the same Budget Period as, or an earlier Budget Period than, the prior Source Budget (1st >= 2nd >= 3rd >= 4th)');
            return null;
        }

        const reportRequest = new BudgetVarianceReportingRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);

        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = this.report.variantName || this.report.displayName;
        reportRequest.includeDisclaimer = this.report.criteria.includeDisclaimer;
        reportRequest.formatOutput = _.cloneDeep(this.report.criteria.bvrFormatOutput);
        // Only copy the selected Budget Sources to the request.
        reportRequest.formatOutput.budgetSources = _.filter(this.report.criteria.bvrFormatOutput.budgetSources, (budget: BudgetVarianceBudgetSource) => {
            return budget.budgetId >= 0;
        });
        console.log('Requesting Budget Variance Report', reportRequest);

        return await this._reportingService.runBudgetVarianceReport(reportRequest);
    }

    private requestPsrReport(): Promise<number> {
        if (this.report.criteria.psrFormatOutputOptions.showParcelsAddedSinceCheckbox && !this.report.criteria.psrFormatOutputOptions.showParcelsAddedSince) {
            this._toastr.error('Please select a date for Show Parcels Added Since');

            return null;
        }
        const reportRequest = new PsrRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);
        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = this.report.variantName || this.report.displayName;
        reportRequest.startYearValue = this.report.criteria.taxYearBegin;
        reportRequest.endYearValue = this.report.criteria.taxYearEnd;
        reportRequest.includeEstAssessment = this.report.criteria.includeEstimatedAssessments;
        reportRequest.includeOnlySitesUnderAppeal = this.report.criteria.includeOnlySitesUnderAppeal;
        reportRequest.propertyTypes = this.report.criteria.propertyTypeIDs;
        reportRequest.outputFormat = this.report.criteria.outputFormat;
        reportRequest.includeDisclaimer = this.report.criteria.includeDisclaimer;
        reportRequest.psrReportType = this.report.reportTypeId === this.reportTypes.ProjectStatus ? 0 : 1;
        _.assign(reportRequest, this.report.criteria.psrFormatOutputOptions);

        return this._reportingService.runPsrReport(reportRequest, this.report.criteria.entitySelection.selectedTopLevelCompanyIDs.length > 1);
    }

    private requestMaarReport(): Promise<number> {
        const reportRequest = new MaarRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);
        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = 'MAAR Extract';
        reportRequest.YearValue = this.report.criteria.taxYear;
        // Might need to uncomment to fix a future bug?
        reportRequest.propertyTypes = this.report.criteria.propertyTypeIDs;
        // reportRequest.statusID = this.report.criteria.statusID;
        reportRequest.includeAllCompanies = this.report.criteria.includeAllCompanies;
        if(reportRequest.includeAllCompanies) {
            reportRequest.sourceEntityIDs = [];
        }

        return this._reportingService.runMaarReport(reportRequest);
    }

    private requestProgressStatusReport(): Promise<number> {
        const reportRequest = new ProgressStatusRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);
        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = 'Project Status Extract';
        reportRequest.startYearValue = this.report.criteria.taxYearBegin;
        reportRequest.endYearValue = this.report.criteria.taxYearEnd;
        reportRequest.siteStatus = this.report.criteria.siteStatus;
        reportRequest.propertyTypes = this.report.criteria.propertyTypeIDs;

        return this._reportingService.runProgressStatusExtract(reportRequest);
    }

    private requestSiteReport(): Promise<number> {
        if (this.report.criteria.entitySelection.selectedTopLevelCompanyIDs.length > 1) {
            this._toastr.error('Select only one Top Level Company for this report');

            return null;
        }

        const reportRequest = new SiteRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);

        Object.assign(reportRequest, basePayload);

        reportRequest.reportTitle = this.report.variantName || this.report.displayName;
        reportRequest.orderBy = this.report.criteria.orderBy;
        reportRequest.startYearValue = this.report.criteria.taxYearBegin;
        reportRequest.endYearValue = this.report.criteria.taxYearEnd;
        reportRequest.numberOfYearsToInclude = Number(this.report.criteria.numberOfYearsToInclude);
        reportRequest.propertyTypes = this.report.criteria.propertyTypeIDs;
        reportRequest.includeDisclaimer = this.report.criteria.includeDisclaimer;
        reportRequest.detailReportOptions = _.merge(this.report.criteria.detailReportOptions, this.report.criteria.siteFormatOutputOptions);

        return this._reportingService.runSiteReport(reportRequest);
    }

    private requestUserActivity(): Promise<number> {
        const reportRequest = new UserActivityRequest();
        const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);
        Object.assign(reportRequest, basePayload);
        reportRequest.reportTitle = 'User Activity';
        reportRequest.outputFormat = this.report.criteria.outputFormat;
        reportRequest.loginDateBegin = this.report.criteria.loginDateBegin;
        reportRequest.loginDateEnd = this.report.criteria.loginDateEnd;
        reportRequest.onlyActiveUsers = this.report.criteria.onlyActiveUsers;
        reportRequest.includeUsersWithoutLogin = this.report.criteria.includeUsersWithoutLogin;
        reportRequest.includeInternalUsers = this.report.criteria.includeInternalUsers;

        _.assign(reportRequest, this.report.criteria.userActivityFormatOutputOptions);

        return this._reportingService.runUserActivityReport(reportRequest);		// this.report.variantName
    }

    private requestUserAccess(): Promise<number> {
        const reportRequest = new UserAccessRequest();

        if(this.report.criteria.startWith === Core.UserAccessReportStartWithEnum.SingleUser) {
            reportRequest.selectedUserId = this.report.criteria.selectedContact.userID;
        } else {
            const basePayload: GetEntitiesRequest = this._selectEntitiesService.getPayloadSourceEntityData(this.report.criteria.entitySelection);
            Object.assign(reportRequest, basePayload);
        }

        reportRequest.reportTitle = 'User Access';
        reportRequest.outputFormat = this.report.criteria.outputFormat;
        reportRequest.loginDateBegin = this.report.criteria.loginDateBegin;
        reportRequest.loginDateEnd = this.report.criteria.loginDateEnd;
        reportRequest.onlyActiveUsers = this.report.criteria.onlyActiveUsers;
        reportRequest.includeUsersWithoutLogin = this.report.criteria.includeUsersWithoutLogin;
        reportRequest.includeInternalUsers = this.report.criteria.includeInternalUsers;
        reportRequest.userAccessReportStartWith = this.report.criteria.startWith;
        reportRequest.userAccessReportLimitByLoginDate = this.report.criteria.limitByLoginDate;

        _.assign(reportRequest, this.report.criteria.userActivityFormatOutputOptions);

        return this._reportingService.runUserAccessReport(reportRequest);		// this.report.variantName
    }

    private requestUserCSR(): Promise<number> {
        const reportRequest = new UserCSRRequest();

        reportRequest.selectedUserId = this.report.criteria.selectedContact.userID;
        reportRequest.reportTitle = 'User CSR Extract';

        return this._reportingService.runUserCSRExtract(reportRequest);		// this.report.variantName
    }

    private get companiesSelected(): boolean {
        return this.report.criteria.entitySelection.selectedTopLevelCompanyIDs.length || this.report.criteria.includeAllCompanies;
    }

    private requestSupportInstanceEscalationActivityReport(): Promise<number> {
        const reportRequest = new SupportInstanceEscalationActivityReportRequest();
        reportRequest.outputFormat = this.report.criteria.outputFormat;
        reportRequest.escalationDateBegin = this.report.criteria.escalationDateBegin;
        reportRequest.escalationDateEnd = this.report.criteria.escalationDateEnd;
        reportRequest.anyEscalationDates = this.report.criteria.anyEscalationDates;
        reportRequest.escalationGroupBy = this.report.criteria.escalationGroupBy;
        reportRequest.instanceIds = this.report.criteria.entitySelection.selectedSupportInstanceIds;

        reportRequest.contactIds = _.chain(this.report.criteria.entitySelection.selectedEntities)
            .filter((selectedEntity: SelectedEntity): boolean => {
                return selectedEntity.id === EntityTypeIds.SUPPORTUSER;
            })
            .map((entity: SelectedEntity) => entity.selectedIDs)
            .flatten()
            .value() as number[];

        this._productAnalyticsService.logEvent('Run Report', {
            reportName: 'Support Instance Escalation Activity',
            isSystemReport: this.isSystemReport,
            totalEntityFilterLevelsApplied: this.report.criteria.entitySelection.selectedEntities.length + 1
        });

        return this._reportingService.runSupportInstanceEscalationActivityReport(reportRequest);
    }
}
