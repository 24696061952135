<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Query Info"
                  cancelHelpContentId="app.cancel"
                  cancelClass="primary-button"
                  [hideSave]="true"
                  (cancel)="cancel()">
    <div class="ws-flex-container-vertical ws-stretch" *ngIf="queryInfo">
        <div class="ws-flex-container-vertical">
            <label>JSON:</label>
            <textarea class="form-control" style="width: 100%" rows="5" [(ngModel)]="queryInfo.queryJSON"></textarea>
        </div>
        <div class="ws-flex-container-vertical ws-flex-auto">
            <label>Query Text:</label>
            <textarea class="form-control" style="width: 100%" rows="5" [(ngModel)]="queryInfo.queryText"></textarea>
        </div>
        
        <div class="ws-flex-container-vertical ws-flex-auto">
            <label>Error:</label>
            <textarea class="form-control" style="width: 100%" rows="5" [(ngModel)]="queryInfo.error"></textarea>
        </div>
    </div>
</ws-modal-wrapper>
