import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccrualsModule } from '../../Accruals/accruals.module';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { ParcelActivitySummaryComponent } from './Activity/parcel-activity-summary.component';
import { ParcelActivityService } from './Activity/parcel-activity.service';
import { AnnualDetailsNavigationEventService } from '../../Annual-Details/annual-details-event.service';
import { ComplianceParcelModule } from '../../Compliance/Parcel/parcel.module';
import { LinkedParcelsComponent } from './Linked-Parcels/linkedParcels.component';
import { LinkedParcelsEditComponent } from './Linked-Parcels/Linked-Parcels-Edit/linkedParcelsEdit.component';
import { LinkedParcelsListComponent } from './Linked-Parcels/Linked-Parcels-List/linkedParcelsList.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { LinkedParcelsListGridActionCellRendererComponent } from './Linked-Parcels/Linked-Parcels-List/agGridActionCellRenderer.component';
import { LinkedParcelRepository } from './Linked-Parcels/linkedParcel.repository';
import { LinkedParcelsAddComponent } from './Linked-Parcels/Linked-Parcels-Add/linkedParcelsAdd.component';
import { ParcelTypeCellRendererComponent } from './Parcel-Type-Cell-Renderer/parcelTypeCellRenderer.component';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { ParcelReportsSitesComponent } from './Reports-Sites/parcelReportsSites.component';
import { ParcelReportsSitesListComponent } from './Reports-Sites/Reports-Sites-List/parcelReportsSitesList.component';
import { LinkedParcelsListGridNameCellRendererComponent } from './Linked-Parcels/Linked-Parcels-List/agGridNameCellRenderer.component';
import { ParcelReportsSitesListGridNameCellRendererComponent } from './Reports-Sites/Reports-Sites-List/agGridNameCellRenderer.component';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer } from '../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { ParcelIconModule } from './Icon/parcelIcon.module';
import { ParcelService } from "./parcel.service.upgrade";
import { ParcelRepository } from "../../Core-Repositories";
import { AccrualsPanelComponent } from './Accruals/accrualsPanel.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AddParcelCollectorModalComponent } from './Info/addParcelCollectorModal.component';
import { TempParcelCollectorModalService } from './Info/tempParcelCollectorModal.service';
import { ParcelInfoPanelComponent } from './Info/parcelInfoPanel.component';
import { ParcelInfoPanelViewComponent } from './Info/parcelInfoPanelView.component';
import { CollectorTaxRateAreaComponent } from './Info/Tax-Rates/collectorTaxRateArea.component';
import { ParcelInfoPanelEditComponent } from './Info/parcelInfoPanelEdit.component';
import { LegalDescriptionModalComponent } from './Info/legalDescriptionModal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        SharedPipesModule,
        ComplianceParcelModule,
        AgGridExtensionsModule,
        ParcelIconModule,
        AccrualsModule,
        BusyIndicatorModule.forRoot(),
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            LinkedParcelsListGridActionCellRendererComponent,
            ParcelTypeCellRendererComponent,
            LinkedParcelsListGridNameCellRendererComponent,
            ParcelReportsSitesListGridNameCellRendererComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer
        ]),
        TabsModule
    ],
    declarations: [
        ParcelActivitySummaryComponent,
        LinkedParcelsComponent,
        LinkedParcelsEditComponent,
        LinkedParcelsListComponent,
        LinkedParcelsListGridActionCellRendererComponent,
        LinkedParcelsAddComponent,
        ParcelTypeCellRendererComponent,
        ParcelReportsSitesComponent,
        ParcelReportsSitesListComponent,
        LinkedParcelsListGridNameCellRendererComponent,
        ParcelReportsSitesListGridNameCellRendererComponent,
        AccrualsPanelComponent,
        AddParcelCollectorModalComponent,
        ParcelInfoPanelComponent,
        ParcelInfoPanelViewComponent,
        ParcelInfoPanelEditComponent,
        CollectorTaxRateAreaComponent,
        LegalDescriptionModalComponent
    ],
    providers: [
        ParcelActivityService,
        AnnualDetailsNavigationEventService,
        LinkedParcelRepository,
        ParcelService,
        ParcelRepository,
        TempParcelCollectorModalService
    ]
})
export class ParcelModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        //Entry components must have only two words, for example: parcelActivity not parcelActivitySummary
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        hybridAdapterUtility.downgradeNg2Provider('ParcelService', ParcelService);
        hybridAdapterUtility.downgradeNg2Provider('tempParcelCollectorModalService', TempParcelCollectorModalService);
        hybridAdapterUtility.downgradeNg2Component('parcelActivity', ParcelActivitySummaryComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedParcels', LinkedParcelsComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedParcelsEdit', LinkedParcelsEditComponent);
        hybridAdapterUtility.downgradeNg2Component('linkedParcelsAdd', LinkedParcelsAddComponent);
        hybridAdapterUtility.downgradeNg2Component('parcelReportsSites', ParcelReportsSitesComponent);
        hybridAdapterUtility.downgradeNg2Component('accrualsPanel', AccrualsPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('parcelInfoPanelNew', ParcelInfoPanelComponent);
    }
}
