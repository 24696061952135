import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AceModule } from '../ACE/ace.module';
import { ModalWrapperComponent } from './modalWrapper.component';
import { DirectivesModule } from '../Directives/directives.module';
import { HelpModule } from '../Help-Tooltip/help.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        HelpModule,
        AceModule
    ],
    declarations: [
        ModalWrapperComponent
    ],
    exports: [
        ModalWrapperComponent
    ]
})
export class ModalWrapperModule {}
