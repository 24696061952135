import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AssessorService } from '../../../../Assessor-Collector/Assessor/assessor-service';
import { ActivityService } from '../../../../Common/Activity/activity.service';
import { PropertyTypeService } from '../../../../Common/Services/propertyType.service.upgrade';
import { AssessorSummary } from '../../Site.Model';
import { Subscription } from 'rxjs';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { MessageBoxService, MessageBoxButtons } from '../../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { SiteService } from '../../Site.Service.upgrade';
import * as _ from 'lodash';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import { ClientServiceResponsibilityService } from '../../../../Client-Services/clientServiceResponsibility.service';
import { EntityTypeIds } from '../../../../constants.new';
import { ToastrService } from 'ngx-toastr';

export interface AddParcelModalParams {
    siteId: number;
    siteAddress: Weissman.Model.Misc.Address;
    stateId: number;
    stateName: string;
    siteAssessor: AssessorSummary;
    masterParcel: Core.ParcelTileDTO;
}

@Component({
    selector: 'add-parcel-modal',
    templateUrl: './addParcelModal.component.html'
})
export class AddParcelModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AddParcelModalParams, Core.ParcelTileDTO> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _assessorService: AssessorService,
        private readonly _propertyTypeService: PropertyTypeService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _siteService: SiteService,
        private readonly _toastrService: ToastrService,
        private readonly _clientServiceResponsibilityService: ClientServiceResponsibilityService,
        private readonly _activityService: ActivityService
    ) { }

    params: AddParcelModalParams;
    result: Core.ParcelTileDTO;

    parcelForm: UntypedFormGroup;
    propertyTypes: Core.PropertyTypeModel[] = [];
    assessors: Core.AssessorModel[] = [];
    selectedAssessor: UntypedFormControl = new UntypedFormControl('');
    masterParcel: Core.ParcelTileDTO;
    canLinkMaster: boolean = true;

    private _formSub: Subscription;
    private _validPropertyTypes: Core.ClientServiceResponsibilitiesPropertyTypesResult;

    async ngOnInit(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.masterParcel = this.params.masterParcel;

            this.parcelForm = new UntypedFormGroup({
                acctNum: new UntypedFormControl('', Validators.required),
                propertyTypeID: new UntypedFormControl(null, Validators.required),
                state: new UntypedFormControl({ value: this.params.stateName, disabled: true }),
                assessorID: new UntypedFormControl('', Validators.required),
                useSiteAddress: new UntypedFormControl(true),
                linkMasterParcel: new UntypedFormControl(!!this.masterParcel)
            });

            this.propertyTypes = await this._propertyTypeService.get();

            this.assessors = await this._assessorService.getByStateId(this.params.stateId);
            this._setDefaultAssessor();

            this._validPropertyTypes = await this._clientServiceResponsibilityService.getTopLevelPropertyTypes(this.params.siteId, EntityTypeIds.SITE);

            this._formSub = this.parcelForm.get('propertyTypeID').valueChanges.subscribe(id => {
                if (this.masterParcel) {
                    this.canLinkMaster = !(id === 1 && this.masterParcel.linkageTypeId === 5); //todo: there is no linkage type id 5; use Core.LinkageType enum
                    this.parcelForm.get('linkMasterParcel').setValue(this.canLinkMaster);
                }
            });
        }
        finally {
            await busyRef.hide();
        }
    }

    ngOnDestroy(): void {
        this._formSub && this._formSub.unsubscribe();
    }

    async addParcel(): Promise<void> {
        const isParcelAcctNumNonAscii = this.parcelForm.value.acctNum && !(/^[ -~\t\n\r]+$/.test(this.parcelForm.value.acctNum));
        if (isParcelAcctNumNonAscii) {
            this._toastrService.error('Parcel Acct Num contains invalid character(s)');
            return Promise.resolve();
        }

        if (! _.some(this._validPropertyTypes.propertyTypes, { id: this.parcelForm.value.propertyTypeID })) {
            await this._messageBoxService.open({
                message: 'Parcel cannot be added.  The selected property type has not been configured for this site.',
                buttons: MessageBoxButtons.OK
            });

            return Promise.resolve();
        }

        const address: Core.AddressModel = (this.parcelForm.value.useSiteAddress)
            ? { ...this.params.siteAddress, ...{ addressID: 0 } }
            : {
                addressID: 0,
                address1: null,
                address2: null,
                city: null,
                email: null,
                ext: null,
                fax: null,
                mobilePhone: null,
                phone: null,
                rowVersion: null,
                state: null,
                stateID: this.params.stateId,
                url: null,
                zip: null
            };

        const request: Core.ParcelModel = {
            siteID: this.params.siteId,
            acctNum: this.parcelForm.value.acctNum,
            address,
            addressID: (this.parcelForm.value.useSiteAddress) ? 0 : null,
            activityStatusID: Core.ActivityStatuses.Active,
            assessorID: this.parcelForm.value.assessorID,
            propertyTypeID: this.parcelForm.value.propertyTypeID,
            assessor: null,
            description: null,
            parcelID: 0,
            parcelLinkageID: (this.params.masterParcel && this.parcelForm.value.linkMasterParcel) ? this.params.masterParcel.parcelLinkageId : null
        };

        const busyRef = this._busyIndicatorService.show({ message: 'Adding Parcel' });

        try {
            this.result = await this._siteService.post(request);

            this._activityService.clearParcelFilterCacheForSite(this.params.siteId);
            this._bsModalRef.hide();
        } finally {
            await busyRef.hide();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onAssessorSelected(match: TypeaheadMatch): void {
        const assessor = match.item as Core.AssessorModel;
        this.parcelForm.patchValue({
            assessorID: assessor.assessorID
        });
    }

    onAssessorBlur(): void {
        const assessorName = this.selectedAssessor.value as string;
        const assessor = this.assessors.find(a => a.name.toLowerCase() === assessorName.toLowerCase()) as Core.AssessorModel;
        if (assessor) {
            this.selectedAssessor.setValue(assessor.name);
        }
        this.parcelForm.patchValue({
            assessorID: (assessor && assessor.assessorID) ? assessor.assessorID : null
        });
    }

    private _setDefaultAssessor(): void {
        if (this.params.siteAssessor) {
            const assessor = this.assessors.find(a => a.assessorID === this.params.siteAssessor.assessorID);
            if (assessor) {
                this.parcelForm.get('assessorID').setValue(assessor.assessorID);
                this.selectedAssessor.setValue(assessor.name);
            }
        }
    }
}
