import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { InstanceRights, RestrictService } from '../../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { CompanyService } from '../../../Entity/Company/company.service';
import { ColDef, GridApi, GridReadyEvent, GridOptions, CellValueChangedEvent, ITooltipParams } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridTextCellEditor } from '../../AgGrid';
import { StateService } from '../../../Common/States/States.Service';
import { SupplementalInformationRepository } from '../../Repositories';
import { AgGridFilterParams } from '../../AgGrid';
import { DropdownCellRenderer, ICellRendererParamsForDropdowns } from './agGridDropdownCellRenderer.component';
import { NavigationService } from '../../../Layout/navigation.service';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { TextCellEditorParams } from '../../AgGrid/CellEditors/agGridTextCellEditor.component';

@Component({
    selector: 'supplemental-information-sheet',
    templateUrl: './supplementalInformationSheet.component.html',
    styleUrls: ['./supplementalInformationSheet.component.scss']
})
export class SupplementalInformationSheetComponent implements OnInit {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _companyService: CompanyService,
        private readonly _restrictService: RestrictService,
        private readonly _supplementalInformationRepository: SupplementalInformationRepository,
        private readonly _stateService: StateService,
        private readonly _navigationService: NavigationService
    ) { }

    private _companyId: number;
    private _gridApi: GridApi;
    private _supplementaryInformationOptions: Compliance.SupplementalInformationLookupModel[];

    breadcrumbs: BreadCrumb[] = [];
    stateFilterType: string = 'ALL';
    isInitialized: boolean = false;
    canEdit: boolean = false;
    editMode: boolean = false;
    isDirty: boolean = false;
    refreshing: boolean;
    company: Weissman.Model.Domain.Company;
    selectedCompanyId: number;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            onCellValueChanged: this._onAgCellValueChanged.bind(this),
            singleClickEdit: true
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withoutCellEditingStoppedOnGridLostFocus()
        .build();

    fieldMap = [
        {'fieldName': 'fein', 'labelName': 'FEIN', 'isSelected': true, 'optionType': null, mask: '00-0000000'},
        {'fieldName': 'stin', 'labelName': 'STIN', 'isSelected': true, 'optionType': null},
        {'fieldName': 'naics', 'labelName': 'NAICS', 'isSelected': true, 'optionType': null},
        {'fieldName': 'salesTaxNumber', 'labelName': 'Sales Tax Number', 'isSelected': false, 'optionType': null},
        {'fieldName': 'inventoryCostMethodId', 'labelName': 'Inventory Cost Method', 'isSelected': false, 'optionType': Compliance.SupplementalInformationLookupTypeEnum.InventoryCostMethod},
        {'fieldName': 'inventoryAccountingMethodId', 'labelName': 'Inventory Accounting Method', 'isSelected': false, 'optionType': Compliance.SupplementalInformationLookupTypeEnum.InventoryAccountingMethod},
        {'fieldName': 'organizationTypeId', 'labelName': 'Organization Type', 'isSelected': true, 'optionType': Compliance.SupplementalInformationLookupTypeEnum.Organization}
    ];

    async ngOnInit(): Promise<void> {
        this._companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.company = await this._companyService.load(this._companyId, false, false);

            if (!(this.company.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, this.company.instanceID))) {
                this._routerService.go('unauthorizedAccess', {});
                return Promise.resolve();
            }

            this.breadcrumbs.push({
                name: this.company.companyName,
                target: 'company',
                options: { companyId: this.company.companyID }
            });

            const result = await Promise.all([
                this._restrictService.hasCompanyPermission(this._companyId, Core.AccessRightsEnum.Write),
                lastValueFrom(this._supplementalInformationRepository.getOptions())
            ]);

            this._supplementaryInformationOptions = result[1];

            this.canEdit = result[0];

            this.isInitialized = true;
            this.selectedCompanyId = this._companyId;
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this._setColumns();
        this._setRowData();
        this._gridApi.sizeColumnsToFit();
    }

    supplementalDataChanged(params: ICellRendererParamsForDropdowns): void {
        params.data.isDirty = true;
        this.isDirty = true;
    }

    async stateFilterTypeChanged(): Promise<void> {
        this._setRowData();
    }

    async selectedCompanyIdChanged(): Promise<void> {
        await this._setRowData();
    }

    fieldMapChanged(): void {
        this._setColumns();
        this._gridApi.sizeColumnsToFit();
    }

    edit(): void {
        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    async refresh(): Promise<void> {
        this._setRowData();
    }

    async save(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            const rowsToSave = [];
            const topRow = this._gridApi.getFloatingTopRow(0);
            if (topRow && topRow.data.isDirty) {
                rowsToSave.push(topRow.data);
            }
            this._gridApi.getModel().forEachNode(r => {
                if (r.data.isDirty) {
                    rowsToSave.push(r.data);
                }
            });

            await lastValueFrom(this._supplementalInformationRepository.update(this.selectedCompanyId, rowsToSave));

            await this._setRowData();
            this._navigationService.disableNavWarning();
        } finally {
            busyRef.hide();
        }
    }

    async cancel(): Promise<void> {
        this.editMode = false;
        await this._setRowData();
        this._navigationService.disableNavWarning();
    }

    private _onAgCellValueChanged(event: CellValueChangedEvent) {
        this.isDirty = true;
        event.data.isDirty = true;
        if (event.newValue === '') {
            event.newValue = null;
        }
    }

    private _setColumns() {
        const editableColumns: ColDef[] = this.fieldMap.filter((fm) => fm.isSelected).map((fm) => {
            const columnDef: ColDef = {
                headerName: fm.labelName,
                field: fm.fieldName,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                tooltipValueGetter: (params: ITooltipParams) => {
                    let tip = '';
                    if (params.data && params.colDef) {
                        const sourceType = params.data[`${params.colDef.field  }Source`];
                        if (sourceType === Compliance.SupplementalInformationValueSource.CompanyDefault) {
                            tip = 'Saved value found from this company\'s default setting';
                        } else if (sourceType === Compliance.SupplementalInformationValueSource.ParentCompanyDefault) {
                            tip = 'Saved value found from parent company\'s default setting';
                        } else if (sourceType === Compliance.SupplementalInformationValueSource.CompanyStateSpecific) {
                            tip = 'Saved value found from this company\'s state-specific setting';
                        } else if (sourceType === Compliance.SupplementalInformationValueSource.ParentCompanyStateSpecific) {
                            tip = 'Saved value found from parent company\'s state-specific setting';
                        } else {
                            tip = 'No value currently saved for this.';
                        }
                    }
                    return tip;
                }
            };
            if (fm.optionType) {
                columnDef.cellRendererFramework = DropdownCellRenderer;
                columnDef.cellRendererParams = {
                    canEdit: (x) => this.editMode,
                    isDisabled: (x) => false,
                    dropdownItems: this._supplementaryInformationOptions.filter(x => x.supplementalInformationLookupType === fm.optionType),
                    name: 'schedule',
                    valueField: 'supplementalInformationLookupId',
                    allowNull: true,
                    change: this.supplementalDataChanged.bind(this),
                    displayGetter: (item: Compliance.FormRevisionScheduleModel) => `${item.name}`
                } as ICellRendererParamsForDropdowns;
            } else {
                columnDef.cellClass = (params) => {
                    if (params.data && !params.data.stateId) {
                        return '';
                    }
                    if (params.data.originalValues[params.colDef.field] !== params.data[params.colDef.field]) {
                        return 'value-override';
                    } else if (params.data && params.data[`${fm.fieldName  }Source`]) {
                        const sourceType = params.data[`${fm.fieldName  }Source`];
                        //copied from dropdown renderer
                        if (sourceType === Compliance.SupplementalInformationValueSource.ParentCompanyDefault ||
                            (sourceType !== Compliance.SupplementalInformationValueSource.CompanyStateSpecific &&
                            params.data['state'] !== 'Default')) {
                            return 'value-inherited';
                        } else {
                            return 'value-override';
                        }
                    }
                    return '';
                };
                columnDef.cellEditorFramework = AgGridTextCellEditor;
                columnDef.cellEditorParams = {
                    showOverrideDuringEdit: true,
                    hasOverride: (params: TextCellEditorParams, currentValue: string) => {
                        if (params.data && !params.data.stateId) {
                            return false;
                        }
                        return (params.data.originalValues[params.colDef.field] !== params.data[params.colDef.field] || params.data.originalValues[params.colDef.field] !== currentValue);
                    }
                };
                if (fm.mask) {
                    columnDef.cellEditorParams = {
                        ...columnDef.cellEditorParams,
                        ...{ inputMask: () => fm.mask }
                    };
                }
                columnDef.editable = () => this.editMode;
            }
            return columnDef;
        });
        const columns : ColDef[]= [
            {
                headerName: 'State',
                field: 'state',
                width: 50,
            },
            ...editableColumns
        ];
        this._gridApi.setColumnDefs(columns);
    }

    private async _getData(): Promise<any> {
        this.refreshing = true;
        this.editMode = false;
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        try {
            const supplementalList = await lastValueFrom(this._supplementalInformationRepository.getList(this.selectedCompanyId));

            let result = [];
            const defaultRow = supplementalList.find(s => s.stateId === null);
            if (this.stateFilterType === 'ALL') {
                result = (await this._stateService.getSummary()).map(state => {
                    const stateRow = supplementalList.find(s => s.stateId === state.stateID);
                    if (stateRow) {
                        return stateRow;
                    }

                    return { ...defaultRow, stateId: state.stateID, state: state.abbr};
                });

            } else if (this.stateFilterType === 'OVERRIDES') {
                result = supplementalList.filter(s => s.stateId !== null);
            } else if (this.stateFilterType === 'SITES') {
                const activeStates = await this._companyService.getStatesWithSites(this._companyId, true, false);
                result = (await this._stateService.getSummary()).filter(state => activeStates.includes(state.abbr)).map(state => {
                    const stateRow=supplementalList.find(s => s.stateId === state.stateID);
                    if (stateRow) {
                        return stateRow;
                    }

                    return { ...defaultRow, stateId: state.stateID, state: state.abbr};
                });
            }

            // Store original values for override comparison
            result = result.map(x => ({...x, ...{ originalValues: {...x} }}));

            let defRowToShow: any;
            if (defaultRow) {
                defRowToShow = defaultRow;
            } else {
                defRowToShow = {
                    stateId: null,
                    state: 'Default'
                };
            }
            this._gridApi.setRowData(result);
            this._gridApi.setPinnedTopRowData([ defRowToShow ]);
        } finally {
            busyRef.hide();
            this.refreshing = false;
        }
    }

    private async _setRowData(): Promise<void> {
        if (!this._gridApi) {
            return;
        }
        await this._getData();
        this.isDirty = false;
    }
}
