import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { EntityAddressDeliverabilityActionEnum } from 'src/app/constants.new';

export interface EntityAddressDeliverabilityIssueParams {
    entityTypeID: Core.EntityTypes;
    givenAddress: string;
    suggestedAddress: string;
    entityID: number;
    addressID?: number;
    messages: string[];
    validated: boolean;
    upmsServiceIsAvailable: boolean;
}

@Component({
    selector: 'assets-deliverability-issue-modal',
    template: `
        <ws-modal-wrapper headerLabel="Address Deliverability Issue Detected"
            headerIcon="fa-exclamation-triangle"
            [suppressKeyboardEvents]="true"
            acceptLabel="Revise Address"
            cancelLabel="Save As Entered"
            (save)="revise()"
            (cancel)="params.upmsServiceIsAvailable ? ignore() : unavailable()"
            [disableSave]="!params.upmsServiceIsAvailable"
            >
            <ul *ngIf="params && params.messages && params.messages.length > 0">
                <li *ngFor="let message of params.messages">{{message}}</li>
            </ul>
        </ws-modal-wrapper>
    `
})

export class EntityAddressDeliverabilityIssueComponent implements IWeissmanModalComponent<EntityAddressDeliverabilityIssueParams, Core.EntityAddressDeliverabilityDecisionDTO>
{
    params: EntityAddressDeliverabilityIssueParams;
    result: Core.EntityAddressDeliverabilityDecisionDTO;

    constructor(
        private readonly _modalRef: BsModalRef
    ) {}

    async revise(): Promise<void> {
        let decision: Core.EntityAddressDeliverabilityDecisionDTO = {
            actionTakenID: EntityAddressDeliverabilityActionEnum.Revise,
            givenAddress: this.params.givenAddress,
            suggestedAddress: this.params.suggestedAddress,
            addressID: this.params.addressID,
            entityTypeID: this.params.entityTypeID,
            entityID: this.params.entityID,
            actionTakenDate: new Date()
        }
        
        this.result = decision
        this._modalRef.hide();
    }

    async ignore(): Promise<void> {
        let decision: Core.EntityAddressDeliverabilityDecisionDTO = {
            actionTakenID: EntityAddressDeliverabilityActionEnum.Ignore,
            givenAddress: this.params.givenAddress,
            suggestedAddress: this.params.suggestedAddress,
            addressID: this.params.addressID,
            entityTypeID: this.params.entityTypeID,
            entityID: this.params.entityID,
            actionTakenDate: new Date()
        }

        this.result = decision;
        this._modalRef.hide();
    }

    async unavailable(): Promise<void> {
        let decision: Core.EntityAddressDeliverabilityDecisionDTO = {
            actionTakenID: EntityAddressDeliverabilityActionEnum.Unavailable,
            givenAddress: this.params.givenAddress,
            suggestedAddress: null,
            addressID: this.params.addressID,
            entityTypeID: this.params.entityTypeID,
            entityID: this.params.entityID,
            actionTakenDate: new Date()
        }

        this.result = decision;
        this._modalRef.hide();
    }
}
