<div *ngIf="entity" class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !editMode}">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Client Services</h3>
        <div *ngIf="showBody" class="float-end header-button-container" style="display: flex;" (click)="$event.stopPropagation()">
            <div *ngIf="isLongRunningProcessRunning" style="padding-right:15px;" class="float-start">
                <i class="fa fa-info-circle" style="padding-right: 5px;"></i>Update in progress
            </div>
            <button class="flat-button icon-button primary-button"
                *ngIf="canLaunchHistory"
                (click)="launchHistoryModal()">
                <i class="fa fa-history"></i>
            </button>
            <button class="flat-button icon-button primary-button"
                *ngIf="canEdit"
                (click)="goToEditMode()"
                [disabled]="enteringEditMode">
                <i class="fa fa-pencil"></i>
            </button>
            <div *ngIf="canSave">
                <button class="flat-button icon-button primary-button" (click)="launchHistoryModal()"
                    [disabled]="serverAction" *ngIf="isExplicitView">
                    <i class="fa fa-history"></i>
                </button>
                <button class="flat-button icon-button success-button" (click)="savePanel()"
                    *ngIf="!isLongRunningProcessRunning"
                    [disabled]="serverAction">
                    <i class="fa fa-save"></i>
                </button>
                <button class="flat-button icon-button warning-button" (click)="cancelEdit()">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="showBody">
        <ws-busy-indicator *ngIf="serverAction && !panelLoading" message="Saving Panel"></ws-busy-indicator>
        <loading-spinner [show]="panelLoading" [size]="5"></loading-spinner>
        <div *ngIf="!panelLoading && panelData">
            <div style="display: flex; justify-content: space-between;">
                <div style="display: flex; gap: 10px; align-items: center;">
                    <strong>Account Handler:</strong>
                    <div *ngIf="!editMode || !isCompany || !isExplicitView"
                        [ngClass]="{'overridden': isAccountHandlerOverridden}">
                            {{accountHandlerTitle}}
                    </div>
                    <user-team-picker *ngIf="editMode && isCompany && isExplicitView"
                        style="width: 500px;"
                        [entityTypeScope]="entity.type"
                        [entityIdScope]="entity.id"
                        [userId]="panelData.accountHandlerUserID"
                        [teamId]="panelData.accountHandlerTeamID"
                        [parentUsers]="ahAvailableUsers"
                        (userChange)="accountHandlerSelected($event)">
                    </user-team-picker>
                    <span *ngIf='!isExplicitView && editMode'>
                        <i class="fa fa-info-circle"
                            title="Changes to Consultant's Account Handler are made from the Manage Consulting Engagements menu">
                        </i>
                    </span>
                </div>
                <a *ngIf="!editMode && entity.canEditCompanySetups"
                    anchor-route
                    [sref]="{ target: 'csrCommandCenter', options: { entityId: entity.id, entityTypeId: entity.typeId } }"
                    [isTargetBlank]="true"
                    style="margin-right:7px;">
                        Command Center
                </a>
            </div>
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-5">
                    <h4>Responsibilities:</h4>
                </div>
                <div class="col-lg-7">
                    <h4>Delivery Details:</h4>
                </div>
            </div>

            <div *ngFor="let service of panelData.entityClientServices" class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                <div class="panel-flat-header small-header">
                    <h3>{{service.name}}</h3>
                </div>
                <div class="card-body" style="padding: 0 15px;">
                    <div class="row" style="display: flex;">
                        <div class="col-lg-5"
                            style="border-right: 2px solid #C1C9C9; padding-top: 15px; padding-bottom: 15px; white-space: nowrap;">
                            <!--responsibilities list -->
                            <div *ngFor="let responsibility of service.responsibilities" style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                                <div style="display: flex; align-items: center; column-gap: 15px; flex-wrap: wrap;">
                                    <!-- property type abbreviation colored square-->
                                    <div class="label label-primary property-type-{{responsibility.propTypeAbbr}}"
                                        style="font-size: 110%; min-width: 40px;">
                                            {{responsibility.propTypeAbbr}}
                                    </div>
                                    <div *ngIf="!canEditResponsibility(responsibility)" [ngClass]="{'overridden': responsibility.isException}">
                                        {{(responsibility.placeholderText) ? responsibility.placeholderText : responsibility.assigneeDisplay}}
                                    </div>
                                    <div *ngIf="showResponsibilityUpdatingInfoIcon(responsibility.clientServiceResponsibilityUpdateStatus)" title="This responsibility is updating in the background.  Changes can't be made until they are finished">
                                        <i class="fa fa-info-circle"></i>
                                    </div>
                                    <select class="form-select form-select-sm"
                                        [ngModel]="responsibility.translatedWorkflowUserTypeId"
                                        (ngModelChange)="userTypeChanged($event, responsibility)"
                                        style="width: auto;"
                                        *ngIf="canEditResponsibility(responsibility) && responsibility.disabledWorkflowUserTypes">
                                        <ng-container *ngFor="let type of workflowUserTypes" >
                                            <option [ngValue]="type.value"
                                                [disabled]="responsibility.disabledWorkflowUserTypes.includes(type.value)"
                                                *ngIf="showWorkflowUserTypeOption(type.value)">
                                                {{ type.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                                        <user-team-picker *ngIf="showSpecificIndividualUserTeamPicker(responsibility)"
                                                          style="min-width: 300px; margin-top: 3px;"
                                                          [entityTypeScope]="entity.type"
                                                          [instanceIdScope]="currentInstanceId"
                                                          [entityIdScope]="entity.id"
                                                          [userId]="responsibility.userID"
                                                          [teamId]="responsibility.teamID"
                                                          [revertIfEmptyOnBlur]="true"
                                                          [parentUsers]="csrAvailableUsers"
                                                          [placeholder]="responsibility.placeholderText"
                                                          (userChange)="specificIndividualSelected($event, responsibility)">
                                        </user-team-picker>
                                        <!--calendar-->
                                        <div *ngIf="canEditResponsibility(responsibility) && responsibility.showDatePicker" style="display:flex; align-items: center; gap: 5px; margin: 3px 0 0 8px;">
                                            <label>From Date:</label>
                                            <weissman-datetime [(inputDate)]="responsibility.dateFrom" [dateOnly]="true"></weissman-datetime>
                                        </div>
                                    </div>
                                    <!-- consulting engagements -->
                                    <div *ngIf="showConsultingEngagementsList(responsibility)">
                                        <div *ngIf="consultingEngagements.length === 1"> - {{consultingEngagements[0].name}}</div>
                                        <select *ngIf="consultingEngagements.length > 1"
                                            class="form-select form-select-sm" style="width: auto;"
                                            [(ngModel)]="responsibility.consultingEngagementId"
                                            (change)="changeConsultingEngagement(responsibility)">
                                                <option *ngFor="let consultingEngagement of consultingEngagements" [ngValue]="consultingEngagement.consultingEngagementId">
                                                    {{consultingEngagement.name}}
                                                </option>
                                        </select>
                                    </div>
                                    <button class="flat-button icon-button" style="top:5px;" (click)="retryResponsibilityUpdate(responsibility)"
                                        *ngIf="showRetryResponsibilityUpdateIcon(responsibility.clientServiceResponsibilityUpdateStatus)" title="This responsibility didn't update correctly.  Push this button to retry the updates.  Some changes can't be made until the updates succeed.">
                                        <i class="fa fa-repeat"></i>
                                    </button>
                                </div>
                                <!--Exceptions link-->
                                <div *ngIf="showExceptionsLink(responsibility.clientServiceResponsibilityUpdateStatus)">
                                    <span class="anchor-style"
                                        *ngIf="responsibility.customerHasChildExceptions"
                                        (click)="openExceptionsModal(responsibility, service)">
                                            Exceptions
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7" style="padding-top: 15px; padding-bottom: 15px;;">
                            <delivery-details-compliance-returns *ngIf="service.clientServiceID === ClientServiceType.ComplianceReturns && hasComplianceFeatureView && entity.isTopLevel"
                                [editMode]="editMode && isCompany && !isLicensedDIUser"
                                [entity]="entity"
                                [(currentData)]="service.deliveryDetailPPReturnBatchCurrent"
                                [service]="service"
                                [ces]="consultingEngagements"
                                [entityInstanceId]="entityInstanceId"
                                [isConsultantPerspective]="isConsultantPerspective"
                                [userSearchInstanceId]="currentInstanceId">
                            </delivery-details-compliance-returns>
                            <delivery-details-compliance-returns *ngIf="service.clientServiceID === ClientServiceType.ComplianceReturns && hasComplianceFeatureView && !entity.isTopLevel"
                                [editMode]="editMode && isCompany && !isConsultantPerspective"
                                [entity]="entity"
                                [(currentData)]="service.deliveryDetailPPReturnBatchTop"
                                [service]="service"
                                [ces]="consultingEngagements"
                                [entityInstanceId]="entityInstanceId"
                                [isConsultantPerspective]="isConsultantPerspective"
                                [userSearchInstanceId]="currentInstanceId">
                            </delivery-details-compliance-returns>
                            <delivery-details-team-retrieves *ngIf="service.clientServiceID === ClientServiceType.Assessments"
                                [editMode]="editMode && (!isConsultantPerspective || isLicensedDIUser)"
                                [(currentData)]="service.deliveryDetailAssessmentCurrent"
                                [topLevelData]="service.deliveryDetailAssessmentTop"
                                [entity]="entity"
                                [(taxFeedIsEnabled)]="taxFeedSettings.assessmentsTaxFeedsEnabled"
                                [taxFeedEnablingMessage]="isParcel
                                    ? 'Parcel assessments will be updated via PTX tax feed.'
                                    : 'All company parcel assessments will be updated via PTX tax feed.'"
                                [taxFeedDisablingMessage]="isParcel
                                    ? 'Parcel assessments will not be updated via PTX tax feed.'
                                    : 'All company parcel assessments will not be updated via PTX tax feed.'"
                                [taxFeedToggleEnabled]="isTaxFeedToggleEnabled(topLevelCompanyTaxFeedSettings?.assessmentsTaxFeedsEnabled)"
                                [taxFeedToggleVisible]="isTopLevelCompany || isParcel"
                                [isEntityInRyanInstance]="isEntityInRyanInstance || isLicensedDIUser">
                            </delivery-details-team-retrieves>
                            <delivery-details-team-retrieves *ngIf="service.clientServiceID === ClientServiceType.TaxBills"
                                [editMode]="editMode && (!isConsultantPerspective || isLicensedDIUser)"
                                [(currentData)]="service.deliveryDetailBillCurrent"
                                [topLevelData]="service.deliveryDetailBillTop"
                                [entity]="entity"
                                [(taxFeedIsEnabled)]="taxFeedSettings.taxBillsTaxFeedsEnabled"
                                [taxFeedEnablingMessage]="isParcel
                                    ? 'Parcel tax bills will be updated via PTX tax feed.'
                                    : 'All company parcel tax bills will be updated via PTX tax feed.'"
                                [taxFeedDisablingMessage]="isParcel
                                    ? 'Parcel tax bills will not be updated via PTX tax feed.'
                                    : 'All company parcel tax bills will not be updated via PTX tax feed.'"
                                [taxFeedToggleEnabled]="isTaxFeedToggleEnabled(topLevelCompanyTaxFeedSettings?.taxBillsTaxFeedsEnabled)"
                                [taxFeedToggleVisible]="isTopLevelCompany || isParcel"
                                [isEntityInRyanInstance]="isEntityInRyanInstance || isLicensedDIUser">
                            </delivery-details-team-retrieves>
                            <delivery-details-transmit *ngIf="service.clientServiceID === ClientServiceType.TaxBillTransmit"
                                [currentData]="service.taxBillDeliveryDetailsCurrent"
                                [topLevelData]="service.taxBillDeliveryDetailsTop"
                                [editMode]="editMode && !isConsultantPerspective && !isLicensedDIUser"
                                [userSearchInstanceId]="currentInstanceId"
                                (currentPaymentBatchDataChange)="paymentBatchData = $event"
                                [entity]="entity">
                            </delivery-details-transmit>
                            <delivery-details-assessment-allocation *ngIf="service.clientServiceID === ClientServiceType.Allocation"
                                [(currentData)]="service.deliveryDetailAssessmentAllocationTop"
                                [editMode]="editMode && !isConsultantPerspective"
                                [entity]="entity"
                                [ces]="consultingEngagements"
                                [service]="service"
                                [entityInstanceId]="entityInstanceId"
                                [isConsultantPerspective]="isConsultantPerspective"
                                [userSearchInstanceId]="currentInstanceId">
                            </delivery-details-assessment-allocation>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="isTopLevelCompany && editMode && isExplicitView" style="margin-top: 15px; display: flex; gap: 15px;">
                <ws-select placeholder="Add Service" [(ngModel)]="newClientService" [isLoading]="loadingDropdownData"
                    (selectedOptionChanged)="addClientService()" [options]="availableClientServices" labelProperty="name"
                    [returnEntireOption]="true" searchType="substring">
                </ws-select>
                <ws-select placeholder="Add Property Type" [(ngModel)]="newPropertyType" [isLoading]="loadingDropdownData"
                    (selectedOptionChanged)="addPropertyType()" [options]="availablePropertyTypes" labelProperty="propTypeName"
                    [returnEntireOption]="true" searchType="substring">
                </ws-select>
            </div>
        </div>
    </div>
</div>
