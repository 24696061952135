import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { UntypedFormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

export interface EntityImportStaticFieldsParams {
    availableImportFields: Compliance.ImportFieldModel[];
}

export interface EntityImportStaticMappingInfo {
    staticColumn: Compliance.StaticImportFieldModel;
    clarificationFieldValues?: Compliance.NameValuePair<string>[];
}

@Component({
    selector: 'entity-import-static-fields',
    templateUrl: './staticFields.component.html'
})
export class EntityImportStaticFieldsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<EntityImportStaticFieldsParams, EntityImportStaticMappingInfo> {
    constructor(private _modalRef: BsModalRef) { }

    params: EntityImportStaticFieldsParams;
    result: EntityImportStaticMappingInfo;
    staticValue: string;
    clarificationColumn: Compliance.ImportFieldModel;
    clarificationFieldControl: UntypedFormControl = new UntypedFormControl();

    private _staticColumn: Compliance.ImportFieldModel = null;
    private _clarificationFieldValue: string;
    private destroy$: Subject<void> = new Subject();
    private _availableImportFields: Compliance.ImportFieldModel[];

    ngOnInit(): void {
        this.clarificationFieldControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(x => {
            this._clarificationFieldValue = x;
        });

        this._availableImportFields = this.params.availableImportFields.filter(x => x.importFieldId !== Compliance.ImportFieldIdEnum.TaxPaymentsPaymentDueDate.toString());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get staticColumn(): Compliance.ImportFieldModel{
        return this._staticColumn;
    }

    set staticColumn(value: Compliance.ImportFieldModel) {
        this._staticColumn = value;

        if (this._staticColumn.clarificationFields && this._staticColumn.clarificationFields.length){
            this.clarificationColumn = this._staticColumn.clarificationFields[0];
        } else{
            this.clarificationColumn = null;
        }

        this._clarificationFieldValue = null;
    }

    cancel() {
        this._modalRef.hide();
    }

    save() {
        const staticColumn = this.staticColumn as Compliance.StaticImportFieldModel;
        if (!staticColumn) {
            return;
        }

        staticColumn.value = this.staticValue;
        this.result = {
            staticColumn: staticColumn,
            clarificationFieldValues: null
        } as EntityImportStaticMappingInfo;

        if (this._clarificationFieldValue){
            this.result.clarificationFieldValues = [];
            this.result.clarificationFieldValues.push({
                name: this.clarificationColumn.importFieldId,
                value: this._clarificationFieldValue} as Compliance.NameValuePair<string>);
        }

        this._modalRef.hide();
    }

    get isSaveDisabled(): boolean {
        return !(this.staticColumn && this.staticValue && (!this.clarificationColumn || this._clarificationFieldValue));
    }

    get availableImportFields(): Compliance.ImportFieldModel[] {
        return this._availableImportFields;
    }
}
