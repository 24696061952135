import { Injectable, NgZone } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimerService {
    constructor(private readonly _ngZone: NgZone) {}

    /**
    * Timeout that prevents issues with E2E testing
    * @param method
    * @param timeout
    *
    */
    setTimeout(method: any, timeout: number = 0): number {
        return this._ngZone.runOutsideAngular(() => {
            return window.setTimeout(() => {
                this._ngZone.run(() => {
                    method();
                });
            }, timeout);
        });
    }

    /**
    * Interval that prevents issues with E2E testing
    * @param method
    * @param interval
    *
    */
    setInterval(method: any, interval: number): number {
        return this._ngZone.runOutsideAngular(() => {
            return window.setInterval(() => {
                this._ngZone.run(() => {
                    method();
                });
            }, interval);
        });
    }

    /**
     * Debounce method
     *
     * Returns a function that can be called to trigger the callback defined in the debounce;
     *
     * Example usage:
     *
     * constructor(private _timer: TimerService) {
     *    this.debounceClicks = this._timer.debounce(() => {
     *        // do exciting things
     *    }, 500, true);
     * }
     *
     * onDomElementClick(): void {
     *    debounceClicks();
     * }
     *
     * @param callback
     * @param wait
     * @param immediate Call the callback at the front of the delay period
     */
    debounce(callback: Function, wait: number, immediate?: boolean): Function {
        let timeout: number;
        const self = this;
        return function () {
            const args = arguments;
            const later = () => {
                timeout = null;
                if (!immediate) {
                    callback.apply(null, args);
                }
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = self.setTimeout(later, wait);
            if (callNow) {
                callback.apply(null, args);
            }
        };
    }

    /**
     * Helper method to allow using async/await to delay execution
     * Found used in ACE library so adding here to keep some consistency
     * @param time
     */
    whenDoneTransitioning(time: number): Promise<void> {
        return new Promise((resolve) => {
            this.setTimeout(() => {
                resolve();
            }, time);
        });
    }
}
