import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignaturePanelComponent } from './Signature/signature-panel.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule,
        ImageCropperModule
    ],
    declarations: [
        SignaturePanelComponent
    ],
    exports: [SignaturePanelComponent]
})
export class UserModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('signaturePanel', SignaturePanelComponent);
    }
}
