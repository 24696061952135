import { of as observableOf,  Subscription ,  Observable ,  BehaviorSubject, lastValueFrom } from 'rxjs';
import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../../Busy-Indicator';
import { StateService } from '../../../../Common/States/States.Service';
import { StateSummary } from '../../../../Common/States/state.model';
import { Constants } from '../../../../constants.new';
import { FilingBatchService } from '../../filingBatch.service';
import { FilingBatchRepository } from '../../../Repositories/filingBatch.repository';
import { ComplianceInfoRepository } from '../../../Repositories/complianceInfo.repository';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../WeissmanMutexService';
import { BaseExpandableComponent } from '../../../../UI-Lib/Expandable-Component/baseExpandableComponent';
import { mergeMap } from 'rxjs/operators';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { FILING_BATCH_INFO_HELP } from './filingBatchInfo.component.help';
import * as moment from 'moment-timezone'
import { YearPickerMixin } from '../../../../UI-Lib/Mixins';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';

interface FilingBatchInfoComponentChanges extends SimpleChanges {
    filingBatch: SimpleChange
}

@Component({
    selector: 'filing-batch-info',
    templateUrl: './filingBatchInfo.component.html',
    styleUrls: ['./filingBatchInfo.component.scss']
})
export class FilingBatchInfoComponent extends YearPickerMixin(BaseExpandableComponent) implements OnInit, OnDestroy, OnChanges, IMutexServiceHandler {
    constructor(
        private readonly _stateService: StateService,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _constants: Constants,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _complianceInfoRepository: ComplianceInfoRepository,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _helpService: HelpService
    ) {
        super(_filingBatchService, 'filing-batch-info');
    }

    private _isInitialized: boolean = false;
    private _filingBatchChangeSub: Subscription;
    private _editModeSubject = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _stateDeadlineMap: Map<number, Compliance.StateDeadlinesModel> = new Map<number, Compliance.StateDeadlinesModel>();

    @Input() filingBatch: Compliance.FilingBatchModel;
    @Input() canEdit: boolean = false;
    @Input() showChangeDetection: boolean = false;

    @Output() onFilingBatchUpdated: EventEmitter<Compliance.FilingBatchModel> = new EventEmitter();
    @Output() onFilingBatchDeleted: EventEmitter<Compliance.FilingBatchModel> = new EventEmitter();
    @Output() onFilingBatchChangesCancelled: EventEmitter<Compliance.FilingBatchModel> = new EventEmitter();
    @Output() onFilingBatchCreated: EventEmitter<Compliance.FilingBatchModel> = new EventEmitter();

    busyMessageManager = new BusyIndicatorMessageManager<string>();

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._filingBatchService.editGroup);
    }

    editMode: boolean = false;
    addMode: boolean = false;
    states: StateSummary[] = [];
    statesTypeAheadDataSource: Observable<StateSummary[]>;
    statesTypeAheadAsyncSelected: any;
    customStateSearch = (values: any[], searchValue: string) => {
        const pattern = searchValue.split('').reduce((a, b) => (a + '[^' + b + ']*' + b));
        const regex = new RegExp(pattern);
        return values.filter(o => {
            return regex.test(o.fullName.toLowerCase()) || regex.test(o.abbr.toLowerCase());
        }).sort((a, b) => {
            // Sort by state abbreviation exact match first
            const aMatch = a.abbr.toLowerCase() === searchValue.toLowerCase();
            const bMatch = b.abbr.toLowerCase() === searchValue.toLowerCase();
            if (!aMatch && bMatch) {
                return 1;
            } else if (aMatch && !bMatch) {
                return -1;
            }
            return a.fullName.localeCompare(b.fullName);
        });
    };

    // currently only supporting 'PP Return' filing type
    filingTypes: Compliance.NameValuePair<Core.FilingTypes>[] = [
        this._constants.FilingTypes.find(x => x.value === Core.FilingTypes.PPReturn)
    ];

    changeDetectionOptions: Compliance.NameValuePair<boolean>[] = [
        {name: 'Company Asset List', value: true},
        {name: 'Prior Return', value: false}
    ];

    form: UntypedFormGroup = this._formBuilder.group({
        filingType: [null, [Validators.required]],
        taxYear: [null, [Validators.required]],
        description: [null, [Validators.required, Validators.maxLength(4000)]],
        dueDate: [null, Validators.required],
        changeDetection: [false],
        stateGroup: this._formBuilder.group({
            state: [null, [Validators.required]],
            stateName: [null]
        })
    });

    get f() { return this.form; }
    get fState() { return (this.form.get('stateGroup') as UntypedFormGroup); }

    get changeDetection(): string {
        return this.form.get('changeDetection').value ? 'Company Assets' : 'Prior Return';
    }

    get isReadOnly(): boolean {
        return this._filingBatchService.isReadOnly(this.filingBatch && this.filingBatch.processStatus);
    }

    get editHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'app.edit';
    }

    get tasksHelpContentId(): string {
        return this.editMode ?
            'app.edit-task-series' :
            'app.view-task-series';
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FILING_BATCH_INFO_HELP);

        this.states = await this._stateService.getSummary();

        this._setModel();

        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._filingBatchService.editGroup);
            }
        });

        this._filingBatchChangeSub = this._filingBatchService.filingBatchChange$.subscribe(x => {
            if (this.filingBatch && x && this.filingBatch.filingBatchId === x.filingBatchId) {
                this.filingBatch.filingCount = x.filingCount;
                this.filingBatch.rowVersion = x.rowVersion;
                this.filingBatch.processStatus = x.processStatus;
                this.filingBatch.status = x.status;
                this.filingBatch.statusDate = x.statusDate;
                this.filingBatch.getPriorReturnFromAssetList = x.getPriorReturnFromAssetList;

                this._updateFormFromModel();
            }
        });

        this.statesTypeAheadDataSource = (Observable.create((observer: any) => {
            observer.next(this.statesTypeAheadAsyncSelected);
        }) as Observable<string>).pipe(mergeMap((token: string) => this.getStatesAsObservable(token)));

        if (this.filingBatch && this.filingBatch.filingBatchId === 0) {
            this.edit();
        }

        const deadlines = await lastValueFrom(this._complianceInfoRepository.getDeadlines());
        this.states.forEach((state: StateSummary) => {
            const stateDeadlines = deadlines.find((deadline: Compliance.StateDeadlinesModel) => state.stateID === deadline.stateId);
            if (stateDeadlines) {
                this._stateDeadlineMap.set(state.stateID, stateDeadlines);
            }
        });

        this._isInitialized = true;
    }

    private _isSecondDeadlineEarlierThanFirst(firstDeadline: Core.AppealDeadlineModel, secondDeadline: Core.AppealDeadlineModel, taxYear: number): boolean {
        const firstDate = (firstDeadline.deadlineMonthDay !== null) ? this._getDateFromDeadlineAndTaxYear(firstDeadline, taxYear) : firstDeadline.deadlineDate;
        const secondDate = (secondDeadline.deadlineMonthDay !== null) ? this._getDateFromDeadlineAndTaxYear(secondDeadline, taxYear) : secondDeadline.deadlineDate;
        return (secondDate < firstDate);
    }

    async ngOnDestroy(): Promise<void> {
        this._filingBatchChangeSub && this._filingBatchChangeSub.unsubscribe();
        this._editModeSub && this._editModeSub.unsubscribe();
        this._mutexService.release(this, this._filingBatchService.editGroup);
    }

    getProgressStatusDisplay(): string {
        return this._filingBatchService.getProgressStatusDisplay(this.filingBatch && this.filingBatch.processStatus);
    }

    getStatesAsObservable(token: string): Observable<StateSummary[]> {
        const query = new RegExp(token, 'ig');

        return observableOf(
            this.states.filter((state: StateSummary) => {
                return query.test(state.abbr) || query.test(state.fullName);
            })
        );
    }

    ngOnChanges(changes: FilingBatchInfoComponentChanges): void {
        if (changes && changes.filingBatch && this._isInitialized) {
            this._setModel();
        }
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._mutexService.acquire(this, this._filingBatchService.editGroup);
        this._editModeSubject.next(true);
    }

    async save(): Promise<void> {
        const busyMessageId = 'saving';
        this.busyMessageManager.add('Saving', busyMessageId);

        try {
            await this._saveModel();
        }
        finally {
            this.busyMessageManager.remove(busyMessageId);
        }
    }

    cancel(): void {
        this._updateFormFromModel();
        this._editModeSubject.next(false);
        this.onFilingBatchChangesCancelled.emit(this.filingBatch);
    }

    onStateSelected(state: StateSummary): void {
        console.log(state)
        this.fState.patchValue({
            'state': state,
            'stateName': (state && state.fullName) || null
        });
        this.updateDeadlineForStateAndTaxYear();
    }

    onStateBlur(): void {
        if (this.fState.controls.stateName.value) {
            const state = this.fState.controls.state.value as Core.StateModel;
            this.fState.patchValue({
                'stateName': (state && state.fullName) || null
            });
            return;
        }
        this.fState.patchValue({
            'state': null
        });
    }

    onDueDateChanged(value: Date | Event): void {
        this.f.controls.dueDate.markAsTouched();
        if (!(value instanceof Date)) {
            return;
        }

        this.f.patchValue({
            'dueDate': value
        });
    }

    updateDeadlineForStateAndTaxYear(): void {
        const taxYear = this.f.get('taxYear').value;
        const state: StateSummary = this.fState.get('state').value;
        if (taxYear && state) {
            const stateId = state.stateID;
            const deadline = this._findEarliestDeadlineForStateAndTaxYear(stateId, taxYear);

            if (deadline) {
                const day = deadline.deadlineMonthDay;
                if (day) {
                    const dueDate = this._getDateFromDeadlineAndTaxYear(deadline, taxYear);
                    this.f.controls.dueDate.setValue(dueDate);
                } else if (deadline.deadlineDate) {
                    this.f.controls.dueDate.setValue(deadline.deadlineDate);
                }
            }
        }
    }

    private _findEarliestDeadlineForStateAndTaxYear(stateId: number, taxYear: number): Core.AppealDeadlineModel {
        let earliestFoundDeadline = null;
        const stateDeadlines = this._stateDeadlineMap.get(stateId);
        if (stateDeadlines != null) {
            earliestFoundDeadline = stateDeadlines.stateDeadline;
            // find any earlier ones
            if (stateDeadlines.exceptionDeadlines !== null) {
                const exceptionsApplyingToThisYear = stateDeadlines.exceptionDeadlines.filter((ed: Core.AppealDeadlineModel) => ed.taxYear === null || ed.taxYear === taxYear);
                exceptionsApplyingToThisYear.forEach((ad: Core.AppealDeadlineModel) => {
                    if (this._isSecondDeadlineEarlierThanFirst(earliestFoundDeadline, ad, taxYear)) {
                        earliestFoundDeadline = ad;
                    }
                });
            }
        }
        return earliestFoundDeadline;
    }

    private _getDateFromDeadlineAndTaxYear(deadline: Core.AppealDeadlineModel, taxYear: number) {
        const day = deadline ? deadline.deadlineMonthDay : null;
        if (day && taxYear) {
            const dueDate = moment.utc(taxYear + deadline.deadlineYearID + '/' + day, 'YYYY-MM-DD').toDate();
            return dueDate;
        } else {
            return null;
        }
    }

    async delete(): Promise<void> {
        const deleted = await this._deleteFilingBatch(this.filingBatch);
        if (deleted) {
            this.onFilingBatchDeleted.emit(this.filingBatch);
        }
    }

    async viewTasks(): Promise<void> {
        await this._filingBatchService.viewTasks(this.filingBatch, this.editMode);
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private async _setModel(): Promise<void> {
        if (!this.filingBatch) {
            return;
        }

        this.addMode = this.filingBatch.filingBatchId === 0;

        if (this.addMode) {
            this.filingBatch = {
                filingBatchId: 0,
                displayId: null,
                filingTypeId: Core.FilingTypes.PPReturn,
                companyId: this.filingBatch.companyId,
                stateId: null,
                taxYear: new Date().getUTCFullYear(),
                dueDate: new Date(),
                description: null,
                rowVersion: null,
                getPriorReturnFromAssetList: false,
                reportNBVInsteadOfFactoredCost: false,
                returnCostReportingDisplayId: Compliance.ReturnCostReportingDisplayEnum.Both
            };
            this.editMode = true;
        }

        // when not in add mode, model is being passed in as input parameter so use that, otherwise fetch from API

        this._updateFormFromModel();

        this._setReadOnlyFormFields();
    }

    private async _saveModel(): Promise<void> {
        this._updateModelFromForm();

        const promise: Promise<Compliance.FilingBatchModel> = this.addMode ?
            lastValueFrom(this._filingBatchRepository.create(this.filingBatch)) :
            lastValueFrom(this._filingBatchRepository.update(this.filingBatch));

        const event = this.addMode ?
            this.onFilingBatchCreated :
            this.onFilingBatchUpdated;

        this.filingBatch = await promise;

        this._editModeSubject.next(false);

        event.emit(this.filingBatch);

        this.addMode = false;

        this._updateFormFromModel();

        this.form.markAsPristine();
        this.form.markAsUntouched();
    }

    private _setReadOnlyFormFields(): void {
        // this one is always disabled as we're only allowing one filing type (PP Return) for now
        this.f.get('filingType').disable();

        if (!this.addMode) {
            this.f.get('taxYear').disable();
            this.fState.disable();
        }
    }

    private _updateFormFromModel(): void {
        const state = this.states.find(x => x.stateID === this.filingBatch.stateId) || null;

        this.statesTypeAheadAsyncSelected = state && state.fullName;

        const filingType = this.filingTypes.find(x => x.value === this.filingBatch.filingTypeId) || null;

        this.form.setValue({
            filingType: filingType,
            taxYear: this.filingBatch.taxYear,
            description: this.filingBatch.description,
            dueDate: this.filingBatch.dueDate,
            changeDetection: this.filingBatch.getPriorReturnFromAssetList,
            stateGroup: {
                state: state,
                stateName: state && state.fullName
            }
        });
    }

    private _updateModelFromForm(): void {
        this.filingBatch.filingTypeId = this.f.get('filingType').value.value; // bound to a <name, value> object
        this.filingBatch.taxYear = this.f.get('taxYear').value;
        this.filingBatch.description = this.f.get('description').value;
        this.filingBatch.dueDate = this.f.get('dueDate').value;
        this.filingBatch.getPriorReturnFromAssetList = this.f.get('changeDetection').value;

        const state: StateSummary = this.fState.get('state').value;
        this.filingBatch.stateId = state.stateID;
        this.filingBatch.stateName = state.fullName;
    }

    private async _deleteFilingBatch(filingBatch: Compliance.FilingBatchModel): Promise<boolean> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the filing batch?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

        try {
            await lastValueFrom(this._filingBatchRepository.delete(filingBatch.filingBatchId, false));
        }
        catch (e2) {
            // service returns a 422 and a message if user confirmation needed to force delete
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);;
            }
            busyRef.hide();

            try {
                await this._messageModalService.confirm(e2.error.message, 'Confirm Delete');
            }
            catch (e3) {
                return Promise.resolve(false);
            }

            busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

            await lastValueFrom<boolean>(this._filingBatchRepository.delete(filingBatch.filingBatchId, true));
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }
}
