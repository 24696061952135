<div class="container">
    <div class="single-column-layout">
        <h1>Property Characteristics</h1>
        <div class="form-inline" style="margin-bottom: 20px;">
            <div class="mb-4">
                <label>Category:</label>
                <select class="form-select form-select-sm" style="margin-left: 5px;" (change)="getDescriptors()" [(ngModel)]="selectedCategoryId">
                    <option *ngFor="let category of categories" [ngValue]="category.descriptorCategoryID">{{category.name}}</option>
                </select>
            </div>
        </div>
        <div [style.display]="dataLoading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div [hidden]="dataLoading">
            <table class="table table-striped">
                <tr>
                    <th *ngFor="let column of columns; let last=last; let i=index;" [class.text-center]="i > (columns.length - 3)">{{column}}</th>
                    <th></th>
                </tr>
                <tbody>
                    <ng-container *ngFor="let descriptor of descriptors; let i=index;">
                        <tr *ngIf="!(descriptorToEdit && descriptor.descriptorID == descriptorToEdit.descriptorID)">
                            <td>{{descriptor.name}}</td>
                            <td>{{getDescriptorUsage(descriptor.siteUsageID)}}</td>
                            <td>{{getDescriptorUsage(descriptor.parcelUsageID)}}</td>
                            <td>{{getDescriptorUsage(descriptor.assessorUsageID)}}</td>
                            <td *ngIf="hasComplianceFeatures">{{getAssetDescriptorUsage(descriptor.assetUsageID)}}</td>
                            <td>
                                {{getDescriptorFieldType(descriptor.fieldTypeID)}}
                                <span *ngIf="descriptor.fieldTypeID === FieldTypeEnum.Picklist">(<a class="pointer" (click)="showPicklistValuesModal(descriptor)">Values...</a>)</span>
                            </td>
                            <td class="text-center">
                                <i *ngIf="descriptor.notes && descriptor.notes.trim()" class="fa fa-commenting" [popover]="descriptor.notes" triggers="mouseenter:mouseleave"></i>
                            </td>
                            <td class="property-chars-enabled text-center">
                                <input type="checkbox" [(ngModel)]="descriptor.enabled" disabled>
                            </td>
                            <td style="white-space: nowrap">
                                <div class="buttons" style="display: inline-block;">
                                    <button type="button" class="btn flat-button icon-button primary-button"
                                        (click)="editDescriptor(descriptor)"
                                        [disabled]="shouldDisableEditButtons(descriptor)"
                                        [hidden]="!canEdit">
                                        <i class="fa fa-pencil"></i>
                                    </button>
                                    <button type="button" class="btn flat-button icon-button warning-button"
                                        (click)="deleteDescriptor(descriptor)"
                                        [disabled]="shouldDisableEditButtons(descriptor)"
                                        [hidden]="!canEdit">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                                <i [class.invisible]="descriptor.descriptorID !== deletingDescriptorId" class="fa fa-spinner fa-pulse"></i>
                            </td>
                        </tr>
                        <tr #formRow *ngIf="descriptorToEdit && descriptor.descriptorID == descriptorToEdit.descriptorID">
                            <td>
                                <input class="form-control" type="text" [(ngModel)]="descriptorToEdit.name" maxlength="25">
                            </td>
                            <td>
                                <select class="form-select form-select-sm" [(ngModel)]="descriptorToEdit.siteUsageID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select form-select-sm" [(ngModel)]="descriptorToEdit.parcelUsageID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select form-select-sm" [(ngModel)]="descriptorToEdit.assessorUsageID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                                </select>
                            </td>
                          <td>
                                <select class="form-select form-select-sm input-sm" [(ngModel)]="descriptorToEdit.collectorUsageID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let usage of usages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                                </select>
                            </td>

                            <td *ngIf="hasComplianceFeatures">
                                <select class="form-select form-select-sm" [(ngModel)]="descriptorToEdit.assetUsageID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let usage of assetUsages" [ngValue]="usage.descriptorUsageID">{{usage.name}}</option>
                                </select>
                            </td>
                            <td>
                                <select class="form-select form-select-sm" [(ngModel)]="descriptorToEdit.fieldTypeID" style="display: inline-block; width: auto;">
                                    <option *ngFor="let fieldType of fieldTypes" [ngValue]="fieldType.descriptorFieldTypeID">{{fieldType.name}}</option>
                                </select>
                                <descriptor-validation [descriptor]="descriptorToEdit"></descriptor-validation>
                            </td>
                            <td>
                                <textarea class="form-control" [(ngModel)]="descriptorToEdit.notes" cols="20" rows="2"></textarea>
                            </td>
                            <td class="property-chars-enabled text-center">
                                <input type="checkbox" [(ngModel)]="descriptorToEdit.enabled">
                            </td>
                            <td style="white-space: nowrap">
                                <div class="buttons" style="display: inline-block;">
                                    <button type="button" [disabled]="descriptorIsSaving" (click)="saveDescriptor(i)"
                                        class="btn flat-button icon-button primary-button">
                                        <i class="fa fa-save"></i>
                                    </button>
                                    <button type="button" [disabled]="descriptorIsSaving"
                                        (click)="cancelEdit(descriptor)"
                                        class="btn flat-button icon-button warning-button">
                                        <i class="fa fa-close"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
            <button type="button" class="btn flat-button primary-button" (click)="addDescriptor()"
                [hidden]="!canEdit || (descriptorToEdit && !descriptorToEdit.descriptorID)">Add Characteristic</button>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #picklistValuesModal="bs-modal" tabindex="-1">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Picklist Items</h4>
                <button type="button" class="btn-close" aria-label="Close" (click)="picklistValuesModal.hide()"></button>
            </div>
            <div *ngIf="!dataLoading && picklist" class="modal-body exclude-modal-styling">
                <ul class="list-group" *ngIf="!dataLoading && picklist.length" style="max-height: 500px; overflow-y: auto;">
                    <li class="list-group-item" *ngFor="let item of picklist">{{item.name}}</li>
                </ul>
                <span class="lead text-muted" *ngIf="!picklist.length"><em>Empty</em></span>
            </div>
        </div>
    </div>
</div>
