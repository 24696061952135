import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { DecimalPipe } from '@angular/common';

export enum YearColumnType{
    BudgetFmv,
    BudgetFmvVariance,
    Fmv,
    FmvChange,
    BudgetAltFmv,
    BudgetAltFmvVariance,
    Fmv_A,
    FmvChange_A,
    BudgetTax,
    BudgetTaxVariance,
    Tax,
    TaxChange
}

export interface ForecastBudgetYearCellRendererParams extends ICellRendererParams {
    year: number;
    yearColumnType: number;
}

@Component({
    selector: 'forecast-budget-year-cell',
    template: `
    <div *ngIf="assessment">
        <div *ngIf="params.yearColumnType === YearColumnType.Fmv" [popover]="exemptionsPopover" triggers="mouseenter:mouseleave" container="body" placement="auto">{{ assessment.totalFMV | number:'1.0-0' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.FmvChange">{{ assessment.yoyPctChg | percent:'1.3-3' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.Fmv_A" [popover]="exemptionsPopover" triggers="mouseenter:mouseleave" container="body" placement="auto">{{ assessment.altFMV | number:'1.0-0' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.FmvChange_A">{{ assessment.altYoyPctChg | percent:'1.3-3' }}</div>
    </div>
    <div *ngIf="taxYear">
        <div *ngIf="params.yearColumnType === YearColumnType.Tax">{{ taxYear.totalTax | currency: 'USD':'symbol-narrow' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.TaxChange">{{ taxYear.yoyPctChg | percent:'1.3-3' }}</div>
    </div>
    <div *ngIf="budget">
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetFmv">{{ budget.budgetFmv | number:'1.0-0'}}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetFmvVariance && !isInvalidNumber(budget.budgetFmvVariance)">{{ budget.budgetFmvVariance | number:'1.0-0' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetAltFmv">{{ budget.budgetAltFmv | number:'1.0-0'}}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetAltFmvVariance && !isInvalidNumber(budget.budgetAltFmvVariance)">{{ budget.budgetAltFmvVariance | number:'1.0-0' }}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetTax">{{ budget.budgetTax | currency: 'USD':'symbol-narrow'}}</div>
        <div *ngIf="params.yearColumnType === YearColumnType.BudgetTaxVariance && !isInvalidNumber(budget.budgetTaxVariance)">{{ budget.budgetTaxVariance | currency: 'USD':'symbol-narrow' }}</div>
    </div>
`
})
export class ForecastBudgetYearCellRendererComponent implements ICellRendererAngularComp {
    constructor(private decimalPipe: DecimalPipe) {}

    params: ForecastBudgetYearCellRendererParams;
    YearColumnType = YearColumnType;

    assessment: Core.ForecastGridEntryAssessment;
    taxYear: Core.ForecastGridEntryTax;
    budget: Core.ForecastGridEntryBudget;

    exemptionsPopover: string;

    agInit(params: ForecastBudgetYearCellRendererParams): void {
        this.params = params;

        this._getYear();
    }

    isInvalidNumber(value: string | number): boolean {
        return value === null || value === undefined || value === '' || value === '.';
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    private _getYear() {
        switch(this.params.yearColumnType) {
            case YearColumnType.Fmv:
            case YearColumnType.FmvChange:
            case YearColumnType.Fmv_A:
            case YearColumnType.FmvChange_A:
                this.assessment = _.find(this.params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: this.params.year});
                if(this.assessment && this.assessment.hasExemptions) {
                    this.exemptionsPopover = `Exemptions: ${this.decimalPipe.transform(Math.abs(this.assessment.exemptions), '1.0-0',)}`;
                }
                break;
            case YearColumnType.Tax:
            case YearColumnType.TaxChange:
                this.taxYear = _.find(this.params.data.taxes as Core.ForecastGridEntryTax[], {annualYear: this.params.year});
                break;
            case YearColumnType.BudgetFmv:
            case YearColumnType.BudgetFmvVariance:
            case YearColumnType.BudgetAltFmv:
            case YearColumnType.BudgetAltFmvVariance:
            case YearColumnType.BudgetTax:
            case YearColumnType.BudgetTaxVariance:
                this.budget = _.find(this.params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: this.params.year});
                break;
        }
    }
}
