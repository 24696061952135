import { Injectable } from '@angular/core';
import { WeissmanModalService } from '../Compliance/WeissmanModalService';
import { ContactsUpgradeService } from './contacts.service.upgrade';
import { ContactRoleService } from '../Role/contact.role.service';
import { BsModalService } from 'ngx-bootstrap/modal';

// todo reimplement this as a service once the user modal update is implemented

// Needed to be its own service to prevent circular dependency
// @Injectable()
// export class ContactModalService {
//     constructor(
//         private readonly _weissmanModalService: WeissmanModalService,
//         private readonly _contactsService: ContactsUpgradeService,
//         private readonly _roleService: ContactRoleService,
//         private readonly _modalService: BsModalService
//     ) {}
//
//     async openContactDialog(contactId: number, origin, entityInfo?, roles?) {
//         // problem: this dialog can be called from contact entity panel.
//         // (contact list has real contacts) or from contacts view (we have list of contactDto objects)
//         // ToDo: Re-factor contact detail dialog to better handle problem described above.
//         const params =  {
//             contactId,
//             entityInfo: entityInfo || null,
//             roles: roles || null,
//             origin
//         }
//
//         // const contact = await this._weissmanModalService.showAsync(ContactViewModalComponent, params, 'modal-lg');
//
//         if (contact) {
//             const i = this._contactsService.contacts.findIndex(x => x.contactID === contact.contactID);
//
//             if (i < 0) {
//                 // this was a new contact, lets insert it
//                 this._contactsService.contacts.push(contact);
//             }
//             else {
//                 // updating existing one
//
//                 // todo: what is going on here ???
//
//                 // array element might have 2 custom properties used by Contacts page (if loaded in dialog mode)
//                 // transferring these properties
//                 //
//                 contact.isVisible = this._contactsService.contacts[i].isVisible;
//                 if (this._contactsService.contacts[i].availableRoles)
//                     contact.availableRoles = this._contactsService.contacts[i].availableRoles;
//                 else
//                 {
//                     // this is actually a new contact, but it has been inserted already
//                     // todo - refactor this mess
//                     //
//                     contact.availableRoles = await this._roleService.getAvailableRolesForContactEntityCombinationAPI(contact.contactID, entityInfo);
//                     this._copyContactProperties(contact, this._contactsService.contacts[i]);
//                 }
//                 this._copyContactProperties(contact, this._contactsService.contacts[i]);
//             }
//         }
//
//         return contact;
//     }
//
//     private _copyContactProperties(c, r) {
//         // we'll have a mix between real contact and Dto
//         r.contactID = c.contactID;
//         r.entityID = c.entityID;
//         r.entityName = c.entityName;
//         r.firstName = c.firstName;
//         r.lastName = c.lastName;
//         r.title = c.title;
//         r.address = c.address;
//         if (c.address) {
//             r.phone = c.address.phone;
//             r.ext = c.address.ext;
//             r.mobilePhone = c.address.mobilePhone;
//         }
//     }
// }

export abstract class ContactModalService {
    abstract openContactDialog(contactId: number, origin, contacts?, entityInfo?, roles?, onTop?: boolean): Promise<any>;
}
