<ws-busy-indicator *ngIf="isBusy" [message]="busyMessage"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Appeal Forms"
                  subHeaderLabel="Select the main form you wish to associate with this appeal."
                  acceptLabel="OK"
                  (cancel)="cancel()"
                  (save)="save()"
                  [disableSave]="!newMainFormRevisionId">

    <div class="ws-flex-container-horizontal ws-flex-align-items-center" style="margin-bottom: 10px;">
        <div class="ws-flex-none">Set appeal main form to:</div>
        <div class="ws-flex-none" style="padding-left: 8px;">
            <select class="form-select form-select-sm"
                    [(ngModel)]="newMainFormRevisionId">
                <option *ngFor="let item of params.mainForms" [ngValue]="item.formRevisionId">{{ item.name }}</option>
            </select>
        </div>
    </div>
    <span *ngIf="!params.mainForms.length && !isBusy">There are no main forms available.</span>

</ws-modal-wrapper>
