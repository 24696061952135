import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { lastValueFrom, Observable } from 'rxjs'
import { ReportDetail } from '../../Reporting/Manager/report.manager.model';

@Injectable()
export class ReturnRepository extends BaseRepository {
    constructor(httpClient: HttpClient) { super(httpClient) }

    getReturnSignInfo(filingBatchId: number): Observable<Compliance.ReturnFormSignInfoModel[]> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/returns/signInfo`);
    }

    getReturnTotals(filingBatchId: number, returnsFilter?: string): Observable<Compliance.ReturnTotalsSummaryModel> {
        const searchModel: Compliance.ReturnTotalsSearchModel = {
            filter: returnsFilter
        };

        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/returns/totals`, searchModel);
    }

    lockReturn(lockRequestModel: Compliance.ReturnLockRequestModel): Observable<number> {
        return this.httpPost(`/api/return/lockBatch/${lockRequestModel.filingBatchId}`, lockRequestModel);
    }

    unlockReturn(unlockRequestModel: Compliance.ReturnUnlockRequestModel): Observable<number> {
        return this.httpPost(`/api/return/unlockBatch/${unlockRequestModel.filingBatchId}`, unlockRequestModel);
    }

    signReturn(signRequestModel: Compliance.ReturnSignRequestModel): Observable<number> {
        return this.httpPost(`/api/return/signBatch/${signRequestModel.filingBatchId}`, signRequestModel);
    }

    unsignReturn(unsignRequestModel: Compliance.ReturnUnsignRequestModel): Observable<number> {
        return this.httpPost(`/api/return/unsignBatch/${unsignRequestModel.filingBatchId}`, unsignRequestModel);
    }

    finalizeReturn(finalizeRequestModel: Compliance.ReturnFinalizeRequestModel): Observable<number> {
        return this.httpPost(`/api/return/finalizeBatch/${finalizeRequestModel.filingBatchId}`, finalizeRequestModel);
    }

    getReturnSummary(filingBatchId: number, formRevisionId: number, parcelIds: number[]): Observable<Compliance.ReturnSummaryModel> {
        const searchModel: Compliance.ReturnAssetSummarySearchModel = {
            formRevisionId: formRevisionId,
            parcelIds: parcelIds
        };
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/returnSummary`, searchModel);
    }

    getReturnFilingControlSummary(filingBatchId: number, formRevisionId: number, parcelIds: number[]): Observable<Compliance.ReturnFilingControlSummaryModel> {
        const searchModel: Compliance.ReturnFilingControlSearchModel = {
            formRevisionId: formRevisionId,
            parcelIds: parcelIds,
            searchModel: null,
            columnsToReturn: null
        };
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/returnFilingControlSummary`, searchModel);
    }

    updateWorkingSet(filingBatchId: number, reportingAssetIds: number[]): Observable<void> {
        return this.httpPost(`/api/return/updateWorkingSet/${filingBatchId}`, reportingAssetIds);
    }

    generateDownloadOutput(model: Compliance.ReturnOutputModel) : Observable<number>{
        return this.httpPost(`/api/Return/download`, model);
    }

    getDownloadOutput(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/return/download/${longRunningProcessId}`, options);
    }

    getPackages(filingBatchId: number): Observable<Compliance.ReturnPackageModel[]> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/packages`);
    }

    getMailingTypes(): Observable<Compliance.ReturnMailingTypeModel[]> {
        return this.httpGet(`/api/return/mailingTypes`);
    }

    updatePackageMailingType(packageId: number, newMailTypeId: number) {
        return this.httpPut(`/api/return/packages/${packageId}/mailingType/${newMailTypeId}`, null);
    }

    getFilingBatchReports(filingBatchId: number): Observable<Compliance.FilingBatchReportModel[]> {
        return this.httpGet(`/api/filingBatchReport/filingBatch/${filingBatchId}`);
    }

    getFilingBatchReport(filingBatchId: number, savedSearchId: number, searchType: Core.SearchType): Promise<ReportDetail> {
        return lastValueFrom(this.httpGet(`/api/complianceReport?filingBatchId=${filingBatchId}&savedSearchId=${savedSearchId}&searchType=${searchType}`))
            .then(x =>
            {
                x['criteria'] = JSON.parse(x['criteria'])

                return x as ReportDetail;
            });
    }

    getReports(): Observable<Core.SavedSearchModel[]> {
        return this.httpGet('/api/complianceReport');
    }

    updateReports(filingBatchId: number, reports: Compliance.FilingBatchReportModel[]): Observable<Compliance.FilingBatchReportModel[]> {
        return this.httpPost(`/api/filingBatchReport/filingBatch/${filingBatchId}`, reports);
    }

    saveCustomReport(filingBatchId: number, reportId: number, report: ReportDetail): Observable<Compliance.FilingBatchReportModel>{
        const reportObject = {... report} as any;
        reportObject["criteria"] = JSON.stringify(report.criteria);
        return this.httpPost(`/api/filingBatchReport/filingBatch/${filingBatchId}/saveCustomReport/${reportId}`, reportObject)
    }

    getAssetCharacteristics(filingBatchId: number): Observable<Core.DescriptorModel[]>{
        return this.httpGet(`/api/filingBatchReport/filingBatch/${filingBatchId}/assetCharacteristics`);
    }

    completeTask(filingBatchId: number, taskUpdateModel: Compliance.ReturnTaskUpdateModel): Observable<Core.TasksByEntityModel> {
        return this.httpPut(`/api/return/filingBatch/${filingBatchId}/tasks/${taskUpdateModel.taskId}/complete`, taskUpdateModel);
    }

    rescindToTask(filingBatchId: number, taskUpdateModel: Compliance.ReturnTaskUpdateModel): Observable<Core.TasksByEntityModel> {
        return this.httpPut(`/api/return/filingBatch/${filingBatchId}/tasks/${taskUpdateModel.taskId}/rescindTo`, taskUpdateModel);
    }

    reassignTask(filingBatchId: number, taskUpdateModel: Compliance.ReturnTaskUpdateModel): Observable<Core.TasksByEntityModel> {
        return this.httpPut(`/api/return/filingBatch/${filingBatchId}/tasks/${taskUpdateModel.taskId}/reassign`, taskUpdateModel);
    }

    skipTask(filingBatchId: number, taskUpdateModel: Compliance.ReturnTaskUpdateModel): Observable<Core.TasksByEntityModel> {
        return this.httpPut(`/api/return/filingBatch/${filingBatchId}/tasks/${taskUpdateModel.taskId}/skip`, taskUpdateModel);
    }

    getTaskUserTeams(filingBatchId: number, searchModel: Compliance.ReturnTaskUserTeamSearchModel): Observable<Core.UserTeamModel[]> {
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/taskUserTeams`, searchModel);
    }

    prepareData(startRequest: Compliance.ReturnPrepareDataRequestModel): Observable<Compliance.ReturnPrepareDataResultModel> {
        return this.httpPost(`/api/return/filingBatch/${startRequest.filingBatchId}/prepareData`, startRequest);
    }

    startPrepareData(startRequest: Compliance.ReturnPrepareDataRequestModel): Observable<Core.ReturnPrepareDataStartResultModel> {
        return this.httpPost(`/api/return/filingBatch/${startRequest.filingBatchId}/startPrepareData`, startRequest);
    }

    getPreparedData(filingBatchId: number): Observable<Compliance.ReturnPrepareDataResultModel> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/getPreparedData`);
    }

    compareForms(filingBatchId: number): Observable<Compliance.ReturnCompareFormsResultModel> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/compareForms`);
    }

    resetForms(filingBatchId: number): Observable<Compliance.ReturnPrepareDataResultModel> {
        return this.httpPut(`/api/return/filingBatch/${filingBatchId}/resetForms`, null);
    }

    exportReturnFilingControlSummary(filingBatchId: number, searchModel: Compliance.ReturnFilingControlSearchModel): Observable<number> {
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/exportReturnFilingControlSummary`, searchModel);
    }

    getExportedReturnFilingControlSummary(filingBatchId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/exportReturnFilingControlSummary/${longRunningProcessId}`, options);
    }

    getFilingBatchGeneralReturnSettings(filingBatchId: number): Observable<Compliance.FilingBatchGeneralReturnSettings> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/generalReturnSettings`);
    }

    updateFilingBatchGeneralReturnSettings(filingBatchId: number, updateModel: Compliance.FilingBatchGeneralReturnSettings): Observable<Compliance.FilingBatchGeneralReturnSettings> {
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/generalReturnSettings`, updateModel);
    }

    exportReconciliation(companyId: number, model: Compliance.ReturnReconciliationSearchModel): Observable<any> {
        return this.httpPost(`/api/return/company/${companyId}/exportReconciliation`, model);
    }

    getReconciliationReport(companyId: number, longRunningProcessId: number): Observable<any> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.httpGet(`/api/return/company/${companyId}/exportReconciliation/${longRunningProcessId}`, options);
    }

    removeFilings(filingBatchId: number, model: Compliance.SelectedRowsModel): Observable<void> {
        return this.httpPost(`/api/return/filingBatch/${filingBatchId}/removeFilings`, model);
    }

    getFinalizeSyncOptions(filingBatchId: number): Observable<Compliance.ReturnFinalizeSyncValueOptionEnum[]> {
        return this.httpGet(`/api/return/filingBatch/${filingBatchId}/finalizeSyncOptions`);
    }
}
