<div class="ws-flex-container-vertical ws-flex-auto">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                <!-- <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count> -->
            </h4>
            <div class="ws-section__header__actions accruals-adjustments-header-actions">
                <div class="ws-section__header__actions__item" *ngIf="hasEditPermission">
                    <button type="button"
                        class="flat-button primary-button small-button"
                        (click)="addRow()"
                        position="bottom"
                        [disabled]="editMode">
                            Add New
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div>
        <loading-spinner [show]="serverAction" [size]="2" [hasOverlay]="true"></loading-spinner>
        <table class="table table-condensed table-striped" *ngIf="selectionData">
            <thead>
                <tr>
                    <th>Acct. Period</th>
                    <th>Collector</th>
                    <th>Description</th>
                    <th style="text-align: right;">Accrual</th>
                    <th style="text-align: center;">Write Journal</th>
                    <th *ngFor="let account of selectionData.glAccounts" style="text-align: right;">
                        <div>{{account.accrualAccountTypeName}}</div>
                        <div>{{account.accountNumber}}</div>
                    </th>
                    <th *ngIf="hasEditPermission"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of adjustments" (mouseenter)="row.isHovered = true" (mouseleave)="row.isHovered = false;">
                    <td style="width: 125px;">
                        <span *ngIf="!row.isEdit || row.isPeriodClosed">{{getAcctPeriodName(row.accountingPeriodId)}}</span>
                        <select class="form-select form-select-sm" [(ngModel)]="row.accountingPeriodId" *ngIf="row.isEdit && !row.isPeriodClosed">
                            <option *ngFor="let x of selectionData.accountingPeriods"
                                [ngValue]="x.accountingPeriodId"
                                [class.not-actual-no-hover]="!x.isClosed"
                                [disabled]="x.isClosed">
                                    {{x.name}}
                            </option>
                        </select>
                    </td>
                    <td style="width: 225px;">
                        <span *ngIf="!row.isEdit">{{getCollectorName(row.collectorId)}}</span>
                        <select class="form-select form-select-sm" [(ngModel)]="row.collectorId" *ngIf="row.isEdit">
                            <option *ngFor="let x of selectionData.collectors" [ngValue]="x.collectorId">{{x.name}}</option>
                        </select>
                    </td>
                    <td>
                        <span *ngIf="!row.isEdit">{{row.description}}</span>
                        <input type="text" [(ngModel)]="row.description" class="form-control" maxlength="100" *ngIf="row.isEdit">
                    </td>
                    <td style="text-align: right; width: 125px;">
                        <parenthetical-currency *ngIf="!row.isEdit" [value]="row.accrualAmount"></parenthetical-currency>
                        <input type="text" class="form-control text-end" style="display: inline-block; width: 100px"
                            *ngIf="row.isEdit" [(ngModel)]="row.accrualAmount" currencyMask [options]="{ allowNegative: true, precision: 2 }">
                    </td>
                    <td style="text-align: center; width: 125px;">
                        <span *ngIf="!row.isEdit || row.isPeriodClosed">
                            <span *ngIf="row.writeJournal">Yes</span>
                            <span *ngIf="!row.writeJournal">No</span>
                        </span>
                        <select class="form-select form-select-sm" [(ngModel)]="row.writeJournal" *ngIf="row.isEdit && !row.isPeriodClosed" (change)="writeJournalChanged(row)">
                            <option [ngValue]="true">Yes</option>
                            <option [ngValue]="false">No</option>
                        </select>
                    </td>
                    <td *ngFor="let account of selectionData.glAccounts" style="text-align: right; width: 125px;">
                        <parenthetical-currency *ngIf="!(row.isEdit && row.writeJournal)" [value]="getImpactAmount(row.journalImpacts, account.glAccountId)"></parenthetical-currency>
                        <input type="text" class="form-control text-end" style="display: inline-block; width: 100px"
                            *ngIf="row.isEdit && row.writeJournal" [ngModel]="getImpactAmount(row.journalImpacts, account.glAccountId)" (ngModelChange)="setImpactAmount(row.journalImpacts, account.glAccountId, $event)"
                            currencyMask [options]="{ allowNegative: true, precision: 2 }">
                    </td>
                    <td *ngIf="hasEditPermission" style="width: 1px;">
                        <div style="white-space: nowrap;" *ngIf="row.isEdit">
                            <button class="flat-button small-button"
                                style="margin: 0;"
                                (click)="save(row)">
                                    <i class="fa fa-save"></i>
                            </button>
                            <button class="flat-button small-button warning-button"
                                style="margin: 0;"
                                (click)="cancel(row)">
                                    <i class="fa fa-close"></i>
                            </button>
                        </div>
                        <div style="white-space: nowrap;" *ngIf="!row.isEdit && !editMode && !(row.isPeriodClosed && row.writeJournal)"
                            [class.invisible]="!row.isHovered">
                            <button class="flat-button small-button" style="margin: 0;" (click)="edit(row)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button class="flat-button small-button warning-button" style="margin: 0;" (click)="delete(row)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
