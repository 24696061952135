import { Component, Input, OnInit, OnDestroy, SimpleChanges, SimpleChange } from '@angular/core';
import * as _ from 'lodash';
import { ComplianceSiteService } from '../site.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WeissmanModalService } from '../../WeissmanModalService';
import { LinkedSitesListParams, LinkedSitesListComponent } from './Linked-Sites-List/linkedSitesList.component';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LINKED_SITES_HELP } from './linkedSites.component.help';
import { Site } from 'src/app/Entity/Site/Site.Model';

interface ComponentChanges extends SimpleChanges {
    sites: SimpleChange;
    siteId: SimpleChange;
    masterSiteId: SimpleChange;
}

@Component({
    selector: 'linked-sites',
    templateUrl: './linkedSites.component.html'
})
export class LinkedSitesComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _siteService: ComplianceSiteService,
        private readonly _bsModalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _helpService: HelpService) { }

    @Input() site: Site;
    @Input() masterSiteId: number;
    @Input() sites: Core.LinkedSiteModel[];
    @Input() isDisabled: boolean;

    masterSite: Core.LinkedSiteModel;
    linkedSites: Core.LinkedSiteModel[];

    ngOnInit(): void {
        this._helpService.setContent(LINKED_SITES_HELP);
    }

    ngOnDestroy(): void { }

    ngOnChanges(changes: ComponentChanges): void {
        this.masterSite = _.find(this.sites, x => this.site.siteID !== this.masterSiteId && x.siteId === this.masterSiteId);
        this.linkedSites = _.filter(this.sites, x => x.siteId !== this.masterSiteId);
    }

    getLinkedSiteName(linkedSiteModel: Core.LinkedSiteModel) {
        return this._siteService.getLinkedSiteName(linkedSiteModel);
    }

    async showLinkedSites(): Promise<void> {
        try {
            const params: LinkedSitesListParams = {
                companyId: this.site.companyID,
                siteId: this.site.siteID,
                sites: _.map(this.site.linkedSites, _.clone),
                masterSiteId: this.site.masterSiteId,
                editMode: false
            };

            await this._wsModalService.show(() => {
                return this._bsModalService.show(LinkedSitesListComponent,
                    {
                        class: 'modal-lg',
                        initialState: {
                            params: params
                        },
                        ignoreBackdropClick: true
                    });
            }).whenClosed;

        } catch (e) {
            return Promise.resolve();
        }

        return Promise.resolve();
    }

    navigateToSite(siteId: number): void {
        this._routerService.go('siteRedirect', { 'entityID': siteId });
    }
}
