<div class="ag-grid-overlay" *ngIf="isVisible">
    <div
        class="ag-grid-overlay__container"
        [ngClass]="{ 'ag-grid-overlay__container--over': container.hasMouseOver }"
        *ngFor="let container of overlayContainers"
        [attr.overlay-bag-name]="bagName"
        [attr.column-id]="container.columnId"
        [style.bottom.px]="container.bottom"
        [style.left.px]="container.left"
        [style.width.px]="container.width"
        [dragula]="bagName"
        [(dragulaModel)]="selectedOverlayItems">
        <div class="ag-grid-overlay__container__icon">
            <i class="fa fa-map-marker fa-4x"></i>
            <div>Drop Here</div>
        </div>
    </div>
</div>