<ws-modal-wrapper headerLabel="Add Static Column"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="isSaveDisabled">
    <form name="addStaticFieldImport" class="form">
            <div class="row">
                <div class="col-lg-12">
                    <label for="staticColumn">What field does this column map to?</label>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <select class="form-select form-select-sm" [(ngModel)]="staticColumn" name="staticColumn">
                        <option [ngValue]="null">-- Select --</option>
                        <option *ngFor="let field of availableImportFields" [ngValue]="field" title="{{field.description}}">{{field.displayName}}</option>
                    </select>
                </div>
            </div>
            <div class="row values-container">
                <div class="col-lg-12">
                    <label for="staticValue">What values should all of the rows be given?</label>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <input type="text" [(ngModel)]="staticValue" class="form-control" name="staticValue" maxlength="100">
                </div>
            </div>
            <div class="row" *ngIf="clarificationColumn">
                <div class="col-lg-12">
                    <label for="staticValue">{{clarificationColumn.displayName}}</label>
                </div>
            </div>
            <div class="row" *ngIf="clarificationColumn">
                <div class="col-lg-12">
                    <ws-select [formControl]="clarificationFieldControl"
                               [options]="clarificationColumn.possibleOptions"
                               [isPrimitive]="true">
                    </ws-select>
                </div>
            </div>
        </form>
</ws-modal-wrapper>
