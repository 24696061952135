<div class="modal-body exclude-modal-styling" *ngIf="taxAuthority">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-section">
            <div class="ws-section__header">
                <h4 class="ws-section__header__title">Tax Authority</h4>
                <div class="ws-section__header__actions">
                    <div class="ws-section__header__actions__item" *ngIf="canEdit">
                        <button *ngIf="!editMode" type="button" class="flat-button small-button primary-button" (click)="goToEditMode()"
                            title="Edit"><i class="fa fa-pencil"></i></button>
                        <button *ngIf="!editMode" type="button" class="flat-button small-button warning-button" (click)="delete()"
                            title="Delete"><i class="fa fa-trash"></i></button>
                        <button *ngIf="editMode && taxAuthority.taxAuthorityId" type="button" class="flat-button small-button warning-button" (click)="cancel()"
                            title="Cancel" [disabled]="saving"><i class="fa fa-close"></i></button>
                    </div>
                    <div class="ws-section__header__actions__item">
                        <entity-attachments buttonClass="flat-button small-button primary-button"
                            [isNestedModal]="true"
                            [(hasAttachments)]="taxAuthority.hasAttachments" [modalData]="attachmentsModel">
                        </entity-attachments>
                        <entity-comments buttonClass="flat-button small-button primary-button"
                            [(hasComments)]="taxAuthority.hasComments"
                            [isNestedModal]="true"
                            [modalData]="commentsModel">
                        </entity-comments>
                    </div>
                </div>
            </div>
        </div>

        <div class="ws-flex-container-horizontal ws-stretch">
            <div class="ws-flex-container-vertical ws-stretch" style="height: initial;">
                <div class="card panel-flat-color primary-panel" style="padding-bottom: 0; width: initial; height: 100%;">
                    <div class="panel-flat-header">
                        <h3>Info</h3>
                    </div>
                    <div class="panel-flat-body">
                        <table class="tax-authority-info">
                            <tr>
                                <td>Name:</td>
                                <td>
                                    <span *ngIf="!editMode">{{taxAuthority.name}}</span>
                                    <input class="form-control" type="text" *ngIf="editMode" [(ngModel)]="taxAuthority.name" maxlength="100">
                                </td>
                            </tr>
                            <tr>
                                <td>Code:</td>
                                <td>
                                    <span *ngIf="!editMode">{{taxAuthority.code}}</span>
                                    <input class="form-control" type="text" *ngIf="editMode" [(ngModel)]="taxAuthority.code" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <td>PTX ID:</td>
                                <td>
                                    <span *ngIf="!editMode">{{taxAuthority.ptxId}}</span>
                                    <input class="form-control" type="text" *ngIf="editMode" [(ngModel)]="taxAuthority.ptxId" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top; padding-top: 7px;">Address:</td>
                                <td>
                                    <address-view-edit [address]="taxAuthority.address" [isSmall]="true" [isEditMode]="editMode" [readOnlyState]="true"></address-view-edit>
                                </td>
                            </tr>
                            <tr>
                                <td>Category:</td>
                                <td>
                                    <span *ngIf="!editMode">{{getCategory(taxAuthority.taxAuthorityCategoryId)}}</span>
                                    <select *ngIf="editMode" class="form-select form-select-sm" style="width: auto;" [(ngModel)]="taxAuthority.taxAuthorityCategoryId">
                                        <option [ngValue]="undefined"></option>
                                        <option *ngFor="let category of categories" [ngValue]="category.id">{{category.label}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Taxable Asssessment:</td>
                                <td>
                                    <span *ngIf="!editMode">{{getTaxableAssessmentLabel(taxAuthority.taxableAssessmentTypeId)}}</span>
                                    <select *ngIf="editMode" class="form-select form-select-sm" style="width: auto;" [(ngModel)]="taxAuthority.taxableAssessmentTypeId">
                                        <option *ngFor="let option of taxableAssessments" [ngValue]="option.id">{{option.label}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Typical Certification Date:</td>
                                <td>
                                    <span *ngIf="!editMode && taxAuthority.certificationMonth && taxAuthority.certificationDay">{{taxAuthority.certificationMonth}}/{{taxAuthority.certificationDay}}  {{getCertificationType()}}</span>
                                    <div *ngIf="editMode">
                                        <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taxAuthority.certificationMonth">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let option of certificationMonths" [ngValue]="option">{{option}}</option>
                                        </select>
                                        <span>/</span>
                                        <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taxAuthority.certificationDay">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let option of certificationDays" [ngValue]="option">{{option}}</option>
                                        </select>
                                        <select class="form-select form-select-sm" style="width: auto; display: inline-block;" [(ngModel)]="taxAuthority.certificationType">
                                            <option [ngValue]="null"></option>
                                            <option *ngFor="let option of certificationTypes" [ngValue]="option.id">{{option.label}}</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="taxAuthority.obtainInAdvance" [disabled]="!editMode"> Obtain in advance of first encounter
                            </label >
                        </div>
                        <div style="margin-top: 20px;">
                            <div *ngIf="taxAuthority.disabled" class="checkbox">
                                <label>
                                    <input
                                         type="checkbox"
                                        [(ngModel)]="taxAuthority.disabled"
                                        [disabled]="!editMode">
                                        Disabled
                                </label>
                            </div>
                            <button *ngIf="editMode && !taxAuthority.disabled"
                                class="btn flat-button warning-button small-button"
                                (click)="disableTaxAuthority()"
                                style="margin: 0;">

                                Disable

                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ws-flex-container-vertical ws-stretch">
                <div class="card panel-flat-color secondary-panel flex-panel" style="padding-bottom: 0; width: initial;">
                    <div class="panel-flat-header" style="margin-bottom: 0;">
                        <h3>Rates</h3>
                    </div>
                    <div class="panel-flat-body ws-flex-container-vertical" style="justify-content: space-between; ">
                        <div>
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center tax-rates-form-items">
                                <label style="margin-bottom: 0;">Display as:</label>
                                <div class="radio">
                                    <label>
                                        <input type="radio" name="displayAsPctOrMillsRadioButton" value="rate" [(ngModel)]="displayAs"> Rate
                                    </label>
                                </div>
                                <div class="radio" style="margin-top: 10px;">
                                    <label>
                                        <input type="radio" name="displayAsPctOrMillsRadioButton" value="mills" [(ngModel)]="displayAs"> Mills
                                    </label>
                                </div>
                            </div>
                            <div class="ws-flex-container-horizontal ws-flex-align-items-center tax-rates-form-items">
                                <label>Default Annual Rate Increase (%):</label>
                                <span *ngIf="!editMode">{{taxAuthority.defaultAnnualRateIncreaseAsPct}}</span>
                                <input type="number"
                                    style="width: 100px; text-align: right;"
                                    *ngIf="editMode"
                                    class="form-control"
                                    [(ngModel)]="taxAuthority.defaultAnnualRateIncreaseAsPct"
                                    (ngModelChange)="syncDefaultAnnualRateIncrease()"
                                    (blur)="defaultAnnualRateIncreaseAsPctBlur()">
                            </div>
                        </div>
                        <div style="height: 158px; overflow: auto; margin: 20px 0;">
                            <table class="table table-condensed tax-authority-details">
                                <thead>
                                    <tr>
                                        <th>Tax Year *</th>
                                        <th style="text-align: right;" *ngIf="displayAs === 'rate'">Rate</th>
                                        <th style="text-align: right;" *ngIf="displayAs === 'mills'">Mills</th>
                                        <th style="text-align: right;">YoY % Change</th>
                                        <th>Status</th>
                                        <th style="text-align: right;">Bills Utilizing</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let yearDetail of taxAuthority.details; let i=index">
                                        <tr *ngIf="yearDetail.efAction !== 'delete'">
                                            <td>{{yearDetail.taxYear}}</td>
                                            <td [ngClass]="getShadingFromStatus(yearDetail)" style="text-align: right;" [tooltip]="getQcInfo(yearDetail)" container="body">
                                                <div *ngIf="!editMode">
                                                    <span *ngIf="displayAs === 'rate'">{{yearDetail.rateAsPct}}</span>
                                                    <span *ngIf="displayAs === 'mills'">{{yearDetail.rateAsMills}}</span>
                                                </div>
                                                <input type="text"
                                                    *ngIf="editMode && displayAs === 'mills'"
                                                    [ngClass]="'form-control mills' + i"
                                                    style="text-align: right;"
                                                    [(ngModel)]="yearDetail.rateAsMills"
                                                    (ngModelChange)="syncRateFromMillsToPct(yearDetail)"
                                                    (focus)="captureVal(yearDetail, 'rateAsMills')"
                                                    (blur)="rateAsMillsBlur(yearDetail)">
                                                <input type="text"
                                                    *ngIf="editMode && displayAs === 'rate'"
                                                    [ngClass]="'form-control rate' + i"
                                                    style="text-align: right;"
                                                    [(ngModel)]="yearDetail.rateAsPct"
                                                    (ngModelChange)="syncRateFromPctToMills(yearDetail)"
                                                    (focus)="captureVal(yearDetail, 'rateAsPct')"
                                                    (blur)="rateAsPctBlur(yearDetail)">
                                            </td>
                                            <td [ngClass]="getShadingFromStatus(yearDetail)" style="text-align: right;">
                                                <div *ngIf="!editMode">
                                                    <span *ngIf="yearDetail.rateChangeAsPercent !== null && yearDetail.rateChangeAsPercent !== undefined">{{yearDetail.rateChangeAsPercent}}</span>
                                                    <span *ngIf="yearDetail.rateChangeAsPercent === null || yearDetail.rateChangeAsPercent === undefined">N/A</span>
                                                </div>
                                                <input type="text"
                                                    *ngIf="editMode && taxAuthority.details[i + 1]"
                                                    class="form-control"
                                                    style="text-align: right;"
                                                    [(ngModel)]="yearDetail.rateChangeAsPercent"
                                                    (ngModelChange)="syncRateChange(yearDetail)"
                                                    (focus)="captureVal(yearDetail, 'rateChangeAsPercent')"
                                                    (blur)="rateChangeAsPercentBlur(yearDetail)">
                                            </td>
                                            <td>
                                                <span *ngIf="!editMode">{{getStatus(yearDetail.taxAuthorityStatusId)}}</span>
                                                <select *ngIf="editMode"
                                                        class="form-select form-select-sm d-inline-block"
                                                        [(ngModel)]="yearDetail.taxAuthorityStatusId">
                                                    <option *ngFor="let option of statuses" [ngValue]="option.id">{{option.label}}</option>
                                                </select>
                                            </td>
                                            <td style="text-align: right;">{{yearDetail.billsUtilizing}}</td>
                                            <td>
                                                <button [class.invisible]="!editMode"
                                                    class="btn flat-button warning-button small-button m-0"
                                                    (click)="removeYearDetail(yearDetail)">
                                                        <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <div *ngIf="editMode">
                            <button type="button" class="btn flat-button primary-button small-button" (click)="launchAddTaxYearModal()">Add Tax Year</button>
                        </div>

                        <p style="margin: 0;">* When calculating bill tax amounts, taxes for years not listed with project using the default annual rate increase.</p>
                    </div>
                </div>
                <div class="card panel-flat-color secondary-panel flex-panel" style="width: initial;">
                    <div class="panel-flat-header">
                        <h3>Tax Rate Area Inclusion</h3>
                    </div>
                    <div class="panel-flat-body ws-flex-container-vertical" style="height: 172px; overflow: auto; margin: 20px 0;">
                        <table class="table table-condensed">
                            <thead>
                                <tr>
                                    <th>Tax Rate Area</th>
                                    <th *ngFor="let yearDetail of taxAuthority.details" style="text-align: center;">{{yearDetail.taxYear}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let traInclusion of taxRateAreaInclusions">
                                    <td>{{traInclusion.taxRateAreaName}}</td>
                                    <td *ngFor="let yearIncluded of traInclusion.yearsIncluded" style="text-align: center;">
                                        <i class="fa fa-check" *ngIf="yearIncluded"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer" *ngIf="taxAuthority">
    <i *ngIf="saving" class="fa fa-spinner fa-pulse fa-2x" style="margin-right: 20px;"></i>
    <button class="btn flat-button primary-button" *ngIf="editMode" (click)="save()" [disabled]="saving || !taxAuthority.name">Save</button>
    <button class="btn flat-button warning-button" (click)="closeModal()">Close</button>
</div>
