<div class="container">
	<div class="single-column-layout">
		<h1>Payment Package Downloads</h1>
        <div [style.display]="(!summary || downloading) ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="summary">
            <div [style.display]="downloading ? 'none' : 'block'">
                <h2>Package Name: {{summary.packageName}}</h2>
                <p>
                    <span class="anchor-style" (click)="goToPackage(summary)">Go to package details</span>
                </p>
                <h2>Attachments</h2>
                <div *ngFor="let attachment of summary.attachments">
                    <span class="anchor-style" (click)="downloadAttachment(attachment)">{{attachment.fileName}}.{{attachment.fileExtension}}</span>
                </div>
            </div>
        </div>
    </div>
</div>