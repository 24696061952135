import { NgModule } from '@angular/core';
import { AttachmentModule } from '../Attachment/attachment.module';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { CompanyModule } from '../Entity/Company/company.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { DocumentIntakeComponent } from './Document-Intake/document.intake.component';
import { DocumentIntakeService } from './Document-Intake/document.intake.service';
import { DocumentIntakeLicensedClientListComponent } from './Document-Intake-Licensed-Clients/Document-Intake-Licensed-Client-List/documentIntakeLicensedClientList.component';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { DocumentIntakeLicensedClientDetailRepository } from './Document-Intake-Licensed-Clients/documentIntakeLicensedClient.repository';
import { DocumentIntakeLicensedClientDetailComponent } from './Document-Intake-Licensed-Clients/Document-Intake-Licensed-Client-Detail/documentIntakeLicensedClientDetail.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        CompanyModule,
        AttachmentModule,
        BusyIndicatorModule
    ],
    declarations: [
        DocumentIntakeComponent,
        DocumentIntakeLicensedClientListComponent,
        DocumentIntakeLicensedClientDetailComponent],
    providers: [
        DocumentIntakeService,
        DocumentIntakeLicensedClientDetailRepository],
    exports: [DocumentIntakeComponent]
})
export class DocumentsModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('documentIntake', DocumentIntakeComponent);
        hybridAdapterUtility.downgradeNg2Component('documentIntakeLicensedClientList', DocumentIntakeLicensedClientListComponent);
    }
}
