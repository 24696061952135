import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular/main';
import { AgGridMultiSelectCellRendererParams } from './agGridMultiSelectTracker';
import { AgGridMultiSelectTracker } from './agGridMultiSelectTracker';

@Component({
    selector: 'ag-grid-multi-select-cell',
    template: `
                <span *ngIf="isPendingRangeSelection()" class="fa fa-spinner fa-spin"></span>
                <span
                    role="presentation"
                    class="ag-selection-checkbox"
                    helpTooltip
                    [tooltipText]="getCheckboxTitle()"
                    position="right"
                    *ngIf="!isPendingRangeSelection() && params.data && !isHidden()"
                    (click)="toggleChecked($event)">
                    <span [class]="getCheckboxClass()"></span>
                </span>
              `
})
export class AgGridMultiSelectedCellRenderer implements ICellRendererAngularComp {
    params: AgGridMultiSelectCellRendererParams;

    private _tracker: AgGridMultiSelectTracker;
    private _rowId: number;

    agInit(params: AgGridMultiSelectCellRendererParams): void {
        this.params = params;
        this._tracker = params.tracker;
        this._rowId = params.value;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    isDisabled(): boolean {
        return this.params && this.params.isCellRendererDisabledFn && this.params.isCellRendererDisabledFn(this._rowId, this.params.data);
    }

    isHidden(): boolean {
        return this.params && this.params.isCellRendererHiddenFn && this.params.isCellRendererHiddenFn(this._rowId, this.params.data);
    }

    isSelected(): boolean {
        return !this.isDisabled() && this._rowId && this._tracker.isRowSelected(this._rowId);
    }

    isPendingRangeSelection(): boolean {
        return this._tracker.isRowPendingRangeSelection(this._rowId, this.params.node);
    }

    /**
     * Determines the class to be applied to the checkbox based on the state that is being tracked.
     */
    getCheckboxClass(): string {
        const isDisabled = this.isDisabled();
        const isSelected = this.isSelected();

        let className = 'ag-icon ag-icon-checkbox';

        // if is selected and disabled then mark it as indeterminate
        // a disabled checkbox could be selected only because of the "select all" header checkbox, nothing we can do in that case (the API has to take care of it)
        if (isSelected) {
            // if disabled it should probably be "checked-readonly"
            // a parameter should be specified if read-only items should be visually shown as "checked" or forced to always "unchecked"
            className += isDisabled ? '-unchecked-readonly' : '-checked';
        } else {
            className += isDisabled ? '-unchecked-readonly' : '-unchecked';
        }
        return className;
    }

    /**
     * Determines the title of the checkbox.
     */
    getCheckboxTitle() {
        if (this.params.tooltipText && this.params.tooltipText(this.params)) {
            return this.params.tooltipText(this.params);
        } else {
            return this._rowId ? 'Toggle selection' : '';
        }
    }

    /**
     * Toggle checked status.
     * @param event Event.
     */
    toggleChecked(event: Event): void {
        if (this.isDisabled()) return;

        if (event instanceof MouseEvent) {
            this._tracker.toggleRowChecked(this._rowId, this.params.node, (event as MouseEvent).shiftKey);
        } else {
            this._tracker.toggleRowChecked(this._rowId, this.params.node, false);
        }

        if (this.params && this.params.updateRowValue) {
            this.params.updateRowValue(this.params, this.isSelected());
        }
    }
}
