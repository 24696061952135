import { ReportType } from './report-type';
import { UserTeamMember } from '../../Common/Models/common.model';

export class ReturnAssetDetailIncludeAssetDescriptorModel{
    descriptorId: number;
    include: boolean;
    name: string;
}

export class ReportDetailCriteria {
    reportType: ReportType;
    entitySelection: EntitySelection;
    frequentlyUsed: boolean;
    hideTopLevelCompanyPicker: boolean;
    showSupportInstancePicker: boolean;

    // Further Limit Panel
    // PSR
    taxYearBegin: number;
    taxYearEnd: number;
    includeEstimatedAssessments: boolean;
    includeOnlySitesUnderAppeal: boolean;

    // Budget
    budgetYear: number;
    budgetYearString: string; // calculate field for jsreport
    minimumDetailYearsShown: number;
    includePPPropertyType: boolean;
    //Budget Variance
    budgetPeriods: BudgetPeriodSelection[];
    // MAAR
    propertyTypeID: number;
    taxYear: number;
    //Project Status
    siteStatus: number;
    propertyTypeIDs: number[];
    //Site
    numberOfYearsToInclude: number;
    yearChoice: string;
    //Return Schedule Summary
    //Return Asset Detail
    formRevisionId: number;
    scheduleId: number;
    scheduleName?: string;
    //Return Asset Detail
    priorReturnStatuses: Compliance.ReturnAssetPriorReturnStatusEnum[];
    priorReturnStatusName?: string;
    //User Activity
    loginDatePeriod: number;
    loginDateBegin: Date;
    loginDateEnd: Date;
    onlyActiveUsers: boolean;
    includeUsersWithoutLogin: boolean;

    // Format Output Panel
    // PSR
    psrFormatOutputOptions: PsrReportCriteria;

    // Budget
    summaryReportOptions: SummaryReportCriteria;
    detailReportOptions: ExtraReportDetailCriteria;
    currCompanyBudgetID: number;
    priorCompanyBudgetID: number;
    budgetBasis: Core.CompanyBudgetBasisEnum;
    // Budget Variance
    bvrFormatOutput: BudgetVarianceFormatOutputCriteria;
    // Site
    orderBy: number[];
    siteFormatOutputOptions: SiteReportCriteria;
    //Return Schedule Summary
    title: string;
    showTaxpayerName: boolean;
    splitOutFactors: boolean;
    suppressZeroCost: boolean;
    showGrandTotal: boolean;
    includePriorCostAddsDeletes: boolean;
    includeFactorAndDepreciatedValue: boolean;
    includeRatioAndAssessedValue: boolean;
    //Return Asset Detail
    groupBy: string[];
    showChangeDetails: boolean;
    showTransferDetails: boolean;
    showLandscape: boolean;
    includeAcqYear: boolean;
    includeGLAccount: boolean;
    includeScheduleName: boolean;
    includeFactorTableName: boolean;
    includeAssetDescriptors: ReturnAssetDetailIncludeAssetDescriptorModel[];
    watermark: string;
    includeAdditionalDepreciation: boolean;
    includeEstimatedFMV: boolean;
    includeCost: boolean;
    includeDisposedDate: boolean;
    includeCostChange: boolean;
    scheduleFilterType: Compliance.ReportScheduleFilterTypeEnum;
    //User Activity
    userActivityFormatOutputOptions: UserActivityReportCriteria;

    //Lease
    includeLeaseClientId: boolean;
    includeLeaseLocationName: boolean;
    includeLeaseLocationAddress: boolean;
    includeLeaseStartDate: boolean;
    includeLeaseEndDate: boolean;
    includeLeaseTerm: boolean;
    includeLeaseMonthlyPayments: boolean;
    includeLeaseType: boolean;
    includeLeaseAssestId: boolean;
    includeLeaseNumber: boolean;
    includeLeaseBillingInfo: boolean;
    includeLinkedSubParcelAcctNumber: boolean;
    includeLinkedSubParcelDescription: boolean;
    includeLinkedSubAssessorAbbr: boolean;
    groupByLinkedSubParcelAcctNum: boolean;
    groupByLinkedSubAssessorAbbr: boolean;
    groupByLinkedSubParcelDescription: boolean;

    //User Access
    selectedContact: Core.ContactModel
    startWith: number;
    limitByLoginDate: number;
    includeInternalUsers;

    //Maar
    includeAllCompanies

    // All
    outputFormat: number;
    includeAssetClass: boolean;
    assetType: number;
    groupByAssetClass: boolean;
    legalSize: boolean;
    includeDisclaimer: boolean;

    dataSource: string;

    // Support Instance Escalation Activity
    limitByEscalationDate: number;
    escalationDatePeriod: number;
    escalationDateBegin?: Date;
    escalationDateEnd?: Date;
    anyEscalationDates: boolean;
    escalationGroupBy: Core.SupportInstanceEscalationActivityReportGroupByEnum;
}

// I'm not crazy about this name; I think we should consider renaming ReportDetailCriteria
// to something like "ReportCriteria", since it's really the owner object for all other criteria,
// then we could name this DetailReportCriteria to match the name of the panel (if we named it
// that now we'd have a ReportDetailCriteria and DetailReportCritera class, and I'm not doing that).
export class ExtraReportDetailCriteria {
    showLienDate: boolean;
    valueDetails: number;
    includeAssessedValueInfo: number;
    includeTargetValue: number;
    includeValuationMetrics: number;
    breakOutTaxPaymentDetails: number;
    showPercentChange: number;
    includePaymentDueDates: boolean;
    includeBudgetComments: boolean;
    includeAlternateAssessments: boolean;

    constructor() {
        this.showLienDate = true;
        this.valueDetails = 1;
        this.includeAssessedValueInfo = 1;
        this.includeTargetValue = 2;
        this.includeValuationMetrics = 1;
        this.breakOutTaxPaymentDetails = 1;
        this.showPercentChange = 0;
        this.includePaymentDueDates = false;
        this.includeBudgetComments = true;
        this.includeAlternateAssessments = true;
    }
}

export class SiteReportCriteria {
    includeParcelAssessmentGrid: boolean;
    includeCompanyNameDetails: number;
    includeFmvChart: boolean;
    includeSiteAppealComments: boolean;
    includePinnedParcelComment: boolean;

    constructor() {
        this.includeParcelAssessmentGrid = false;
        this.includeCompanyNameDetails = 0;
        this.includeFmvChart = true;
        this.includeSiteAppealComments = false;
        this.includePinnedParcelComment = false;
    }
}

export class UserActivityReportCriteria {
    showUserName: boolean;
    showContactTitle: boolean;
    showLoginDates: boolean;
    showLoginDayCount: boolean;
    showLastPasswordChange: boolean;

    constructor() {
        this.showUserName = false;
        this.showContactTitle = false;
        this.showLoginDates = true;
        this.showLoginDayCount = true;
        this.showLastPasswordChange = false;
    }
}

export class PsrReportCriteria {
    paperSize: number;
    showAcqInfo: boolean;
    showYearBuiltInfo: boolean;
    showAppealDeadlines: boolean;
    showAssessments: number;
    showMetrics: number;
    showInitValue: number;
    showTargetValue: number;
    showLatestValue: number;
    showTaxDetails: number;
    showAppealDetails: boolean;
    showComments: boolean;
    showParcelList: boolean;
    showSiteAppealCommentsValue : number;
    showSavings: number;
    orderGroupBy: OrderAndTotalByOption[];
    showParcelsAddedSince: Date;
    showParcelsAddedSinceCheckbox: boolean;

    constructor(reportTypeId: number) {
        if(reportTypeId == ReportType.AppealRecommendation) {
            this.paperSize = 1;
            this.showAcqInfo = true;
            this.showYearBuiltInfo = false;
            this.showAppealDeadlines = true;
            this.showAssessments = 0;
            this.showMetrics = 1;
            this.showInitValue = 2;
            this.showTargetValue = 0;
            this.showLatestValue = 1;
            this.showTaxDetails = 2;
            this.showAppealDetails = true;
            this.showComments = false;
            this.showParcelList = false;
            this.showSiteAppealCommentsValue = 2;
            this.showSavings = 1;
            this.orderGroupBy = [];
        } else {
            this.paperSize = 1;
            this.showAcqInfo = false;
            this.showYearBuiltInfo = false;
            this.showAppealDeadlines = false;
            this.showAssessments = 0;
            this.showMetrics = 1;
            this.showInitValue = 2;
            this.showTargetValue = 0;
            this.showLatestValue = 1;
            this.showTaxDetails = 2;
            this.showAppealDetails = true;
            this.showComments = true;
            this.showParcelList = false;
            this.showSiteAppealCommentsValue = 0;
            this.showSavings = 0;
            this.orderGroupBy = [];
        }
    }
}

export class FormatOutputOptionsPsr {
    outputFormatOptions: string[];
    paperSizeOptions: string[];
    showAssessmentsOptions: string[]
    showMetricsOptions: string[];
    showValueOptions: string[];
    showTaxDetailsOptions: string[];
    orderAndTotalByOptions: OrderAndTotalByOption[];
    showSiteAppealCommentsOptions: string[];
    showLatestValueOptions: string[];
    showSavingsOptions: string[];

    constructor() {
        this.outputFormatOptions = ['PDF', 'Excel'];
        this.paperSizeOptions = ['Letter', 'Legal', 'A4', 'A3', 'A5', 'Tabloid'];
        this.showAssessmentsOptions = ['FMV', 'Alternate', 'FMV and Alternate']
        this.showMetricsOptions = ['None', 'Most applicable based on site class', 'All present'];
        this.showValueOptions = ['No', 'Yes (Incl. Metrics)', 'Yes (No Metrics)'];
        this.showTaxDetailsOptions = ['None', 'Latest Taxes (Incl. Metrics)', 'Latest Taxes (No Metrics)', 'All Taxes (Incl. Metrics)', 'All Taxes (No Metrics)'];
        this.showSiteAppealCommentsOptions = ["No", "Latest Year", "All Years"];
        this.showLatestValueOptions = ["Yes (Incl. Metrics and YOY % Change)", "Yes (Incl. Metrics)", "Yes (Incl. YOY % Change )", "Yes (No Metrics or YOY % Change)"];
        this.showSavingsOptions = ["Received and Projected", "Received Only", "Projected Only", "No"];
    }
}

export class OrderAndTotalByOption {
    id: number;
    name: string;
    primarySortOrder: number;
    groupByThisItem?: boolean;
}

export class FormatOutputOptionsBudget {
    outputFormatOptions: string[];
    summaryCashBasisPeriodOptions: string[];
    showPercentChangeOptions: string[];
    detailLevelOptions: string[];

    constructor() {
        this.outputFormatOptions = [ 'Summary Report (PDF)', 'Summary Report (Excel)', 'Summary + Details Report (PDF)', 'Summary (Excel) + Details (PDF)', 'Details Report (PDF)'];
        this.summaryCashBasisPeriodOptions = ['Annual', 'Quarterly', 'Monthly', 'None'];
        this.showPercentChangeOptions = ['Yes with Details', 'Yes, no Details', 'No'];
        this.detailLevelOptions = ['Site', 'Parcel', 'None'];
    }
}

export class SummaryReportCriteria {
    summaryCashBasisPeriod: number;
    detailLevel: number;
    includeBudgetComments: boolean;
    showPercentChange: number;
    orderAndTotalBy: number[];

    constructor() {
        this.summaryCashBasisPeriod = 0;
        this.detailLevel = 0;
        this.includeBudgetComments = false;   // needs to be flipped to true when comments are added back in
        this.showPercentChange = 0;
        this.orderAndTotalBy = [];
    }
}

export class BudgetVarianceFormatOutputCriteria {
    outputFormat: number;                           // 0=Summary Report (PDF), 1=Summary Report (Excel)
    budgetBasis: Core.CompanyBudgetBasisEnum;
    budgetSources: BudgetVarianceBudgetSource[];    // 1st period >= 2nd period [ >= 3rd period [ >= 4th period ] ] (newest-to-oldest)
    summaryReportOptions: BudgetVarianceSummaryReportCriteria;

    constructor() {
        this.outputFormat = 0;
        this.budgetBasis = Core.CompanyBudgetBasisEnum.AccrualAndCash;
        this.budgetSources = [];
        this.summaryReportOptions = new BudgetVarianceSummaryReportCriteria();
    }
}

export class BudgetVarianceSummaryReportCriteria {
    detailLevel: number;                            // 0=Site, 1=Parcel, 2=None
    varianceDetail: Core.BudgetVarianceDetailEnum;
    orderAndTotalBy: number[];

    constructor() {
        this.detailLevel = 0;
        this.varianceDetail = Core.BudgetVarianceDetailEnum.AmountAndPercent;
        this.orderAndTotalBy = [];
    }
}

export class BudgetVarianceBudgetSource {
    budgetId: number;             // 1st: 0 = Current Forecast  OR  > 0,
                                  // 2nd: > 0
                                  // 3rd: -1 = None OR > 0
                                  // 4th: -1 = None or > 0; Must be -1 if 3rd = -1
    budgetName: string;
    budgetBasis: Core.CompanyBudgetBasisEnum;
    budgetPeriod: Core.TimePeriod;

    constructor()
    {
        this.budgetId = -1;
        this.budgetBasis = Core.CompanyBudgetBasisEnum.AccrualAndCash;
        this.budgetPeriod = {
            periodBegin: new Date,
            periodEnd: new Date
        }
    }
}

export class BudgetPeriodSelection implements Core.TimePeriod  {
    periodBegin: Date;
    periodEnd: Date;

    displayName: string;

    constructor()
    {
        this.displayName = "";
        this.periodBegin = new Date;
        this.periodEnd = new Date;
    }
}

export class EntitySelection {
    excludeInactive: boolean;
    selectedTopLevelCompanyIDs: number[];
    selectedEntities: SelectedEntity[];
    selectedSupportInstanceIds: number[];

    constructor() {
        this.excludeInactive = true;
        this.selectedTopLevelCompanyIDs = [];
        this.selectedEntities = [];
        this.selectedSupportInstanceIds = [];
    }
}

export class ReportCategory {
    categoryId: number;
    categoryName: string;
    categoryType: number;
    reportList: ReportSummary[];
}

export class ReportSummary {
    reportID: number;
    reportName: string;
    sequence: number;
    variantName: string;
    displayName: string;
}

export class ReportDetail extends ReportSummary {
    categoryID: number;
    categoryName: string;
    criteria: ReportDetailCriteria;
    isOwnedByUser: boolean;
    isShared: boolean;
    isFavorite: boolean;
    reportTypeId: number;
    rowVersion: any;
    isDisabled: boolean;
    isSystemReport: boolean;

    dataSource: string;
}

export class TopLevelCompany {
	companyName: string;
	companyID: number;
	isFavorite: boolean;
}

export class GenericEntity {
	id: number;
	name: string;
}

export interface SelectedEntity {
	name: string;
	buttonName: string;
	id: number;
	prerequisites: number[];
	blockers: number[];
	list: GenericEntity[];
    selectedIDs: number[];
    selectedPropCharVals: String[];
    dropdownList: string[];
    selectedDropdown: string;
    loading: boolean;
    isAppealResponsibility?: boolean;
}

export class EntityFilter {
    entityTypeID: number;
    entityIDs: number[];
}

export class GetEntitiesRequest {
    sourceEntityIDs: number[];
    userIdFilters: string[]
    sourceEntityTypeID: number;
    sourceEntityIsTopLevelCompany: boolean;
    sitePropCharFilters: object[];
    parcelPropCharFilters: object[];
    entityFilters: EntityFilter[];
    targetEntityTypeID: number;
    excludeInactive: boolean;
    contactIds: number[];
}

export class GetPropCharValuesRequest extends GetEntitiesRequest {
    selectedPropCharName: string;
}

export class SiteStatus {
    name: string;
    id: number;
}
