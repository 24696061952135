<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Income Statements" *ngIf="!isEmbededMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                <span *ngIf="!isEmbededMode">Income Statements</span>  <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <!-- <div class="ws-section__header__actions__item" *ngIf="canEdit && !isEmbededMode">
                    <button type="button" class="flat-button primary-button small-button" (click)="addAsset()" helpTooltip helpContentId="asset-list.new-asset" position="bottom" [disabled]="!lienDate">New Asset</button>
                </div> -->
                <div class="ws-section__header__actions__item" *ngIf="isEmbededMode">
                    <a [href]="filterHref">View Full Income Statements</a>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="flat-button small-button secondary-button" (click)="refresh()" helpTooltip helpContentId="app.refresh" position="bottom" [disabled]="refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group">
                <label>End Date:</label>
                <select class="form-select form-select-sm"
                        [(ngModel)]="selectedEndDate"
                        (change)="onEndDateChange()">
                    <option *ngFor="let date of endDates" [ngValue]="date">{{ date | weissmanDateFormat }}</option>
                </select>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridId]="gridId"
                        [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                        [exportOptions]="exportOptions"
                        (bulkDelete)="bulkDelete()">
    </ws-ag-grid-angular>
</div>
