import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export type Constructor<T = {}> = new (...args: any[]) => T;
interface UnsubscribeMixin { destroy$: Subject<void>; };

/**
 * Mixin for auto unsubscribing from Component subscriptions
 *
 * Example Usage:
 *
 * export class MyComponent extends UnsubscribeMixin() implements OnInit {
 *   constructor(private readonly myService: MyService) {super()}
 *
 *   ngOnInit():void {
 *      this.myService.mySubscription$.pipe(takeUntil(this.destroy$)).subscribe(x => console.log(x));
 *   }
 * }
 *
 * The mixin provides a subject that can be used in the takeUntil pipe that will be called when the component is destroyed
 *
 * If you need to do additional functionality in OnDestroy within your component, place the following line at the top of ngOnDestroy
 *
 * ngOnDestroy(): void {
 *   super['ngOnDestroy'] && super['ngOnDestroy']();
 *   // Your code
 * }
 * 
 * @param base
 */
export const UnsubscribeMixin = <T extends Constructor>(base: T = class { } as T): Constructor<UnsubscribeMixin> => {
    return class extends base implements OnDestroy {
        public readonly destroy$: Subject<void> = new Subject<void>();

        ngOnDestroy(): void {
            this.destroy$.next();
            this.destroy$.complete();
        }
    };
}
