import { Component, Input, OnInit } from '@angular/core';
import { getByPath } from '@ace/shared';
import { ISimpleTableColumnDefinition } from '../simpleTable.component';

@Component({
    selector: 'ws-ace-simple-table-cell',
    templateUrl: './simpleTableCell.component.html',
    styleUrls: ['./simpleTableCell.component.scss']
})
export class AceSimpleTableCellComponent implements OnInit {
    @Input() row: object;
    @Input() col: ISimpleTableColumnDefinition;

    content: string;

    ngOnInit(): void {
        this.content = getByPath(this.row, this.col.field);
    }
}
