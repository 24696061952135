import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidationErrors, Validators, AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { FILING_BATCH_MOVE_MODAL_HELP } from './filingBatchMoveModal.component.help';
import { FilingBatchService } from '../../filingBatch.service';
import { BusyIndicatorService, BusyIndicatorMessageManager } from '../../../../Busy-Indicator';

enum RadioOptions {
    Move,
    Create
}

export interface FilingBatchMoveModalParams {
    availableTargets: Compliance.FilingBatchModel[];
    filingIds: number[];
    batchInfo: Compliance.FilingBatchModel;
}

@Component({
    selector: 'filing-batch-move-modal',
    templateUrl: './filingBatchMoveModal.component.html',
    styleUrls: ['./filingBatchMoveModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FilingBatchMoveModalComponent implements OnInit, OnDestroy, IWeissmanModalComponent<FilingBatchMoveModalParams, Compliance.FilingBatchModel> {
    constructor(
        private readonly _filingBatchService: FilingBatchService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService,
        private _busyIndicatorService: BusyIndicatorService) { }

    moveControl: UntypedFormControl = new UntypedFormControl();
    newGroup: UntypedFormGroup;
    radioOption: UntypedFormControl = new UntypedFormControl(RadioOptions.Move);

    params: FilingBatchMoveModalParams;
    result: Compliance.FilingBatchModel;

    filingBatches: Compliance.FilingBatchModel[] = [];
    taxYears: number[] = [];
    minDate: Date;
    RadioOptions = RadioOptions;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    // Validations
    dateValidation = (control: UntypedFormControl): ValidationErrors | null => (control.value && control.value >= this.minDate) ? null : { dateInvalid: true };
    formValidation = (control: UntypedFormGroup): ValidationErrors | null => (this.radioOption.value === RadioOptions.Move || (control.get('description').valid && control.get('dueDate').valid)) ? null : { formInvalid: true };

    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this._helpService.setContent(FILING_BATCH_MOVE_MODAL_HELP);
        this.minDate = new Date(this.params.batchInfo.taxYear, 0, 1);

        this.newGroup = new UntypedFormGroup({
            description: new UntypedFormControl({ value: this.params.batchInfo.description, disabled: true }, Validators.required),
            dueDate: new UntypedFormControl({ value: this.params.batchInfo.dueDate, disabled: true }, this.dateValidation)
        }, this.formValidation);

        this.radioOption.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: RadioOptions) => {
                if (val === RadioOptions.Move) {
                    this.moveControl.enable();
                    this.newGroup.disable();
                } else {
                    this.moveControl.disable();
                    this.newGroup.enable();
                }
            });

        if (this.params && this.params.availableTargets.length) {
            this.filingBatches = this.params.availableTargets;
            this.moveControl.setValue(this.filingBatches[0]);
        } else {
            this.radioOption.setValue(RadioOptions.Create);
            this.radioOption.disable();
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async submit(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Moving Filings' });

        try {
            const moveTo = this.moveControl.value;
            const moveModel: Compliance.FilingBatchFilingsMoveModel = {
                filingIds: this.params.filingIds,
                sourceBatchRowVersion: this.params.batchInfo.rowVersion,
                targetBatchRowVersion: (moveTo) ? moveTo.rowVersion : [],
                targetFilingBatch: (this.radioOption.value === RadioOptions.Create) ? this.newGroup.value : null,
                targetFilingBatchId: (this.radioOption.value === RadioOptions.Move) ? moveTo.filingBatchId : null
            };

            const targetFilingBatch = await this._filingBatchService.moveFilings(moveModel);

            this.result = targetFilingBatch;
            this._bsModalRef.hide();
        }
        finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onDueDateChanged(date: Date) {
        this.newGroup.get('dueDate').setValue(date);
    }
}
