import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DragAndDropListService } from "./dragAndDropList.service";
import { Observable, Subscription } from "rxjs";
import { TimerService } from '../../Utilities/timer.service';

@Directive({
    selector: '[ws-dnd-draggable]'
})
export class DragAndDropDraggableDirective implements OnInit, OnDestroy {
    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2,
        private readonly _dndState: DragAndDropListService,
        private readonly _timer: TimerService
    ) {}

    @Input() dndDraggable;
    @Input() dndEffectAllowed;
    @Input() dndType;
    @Input() dndDisableIf: Observable<any>;

    @Output() dndDragstart: EventEmitter<any> = new EventEmitter();
    @Output() dndMoved: EventEmitter<any> = new EventEmitter();
    @Output() dndCopied: EventEmitter<any> = new EventEmitter();
    @Output() dndLinked: EventEmitter<any> = new EventEmitter();
    @Output() dndCanceled: EventEmitter<any> = new EventEmitter();
    @Output() dndDragend: EventEmitter<any> = new EventEmitter();
    @Output() dndSelected: EventEmitter<any> = new EventEmitter();
    @Output() dndCallback: EventEmitter<any> = new EventEmitter();

    @HostListener('dragstart', ['$event'])
    dragStart(event) {
        event = event.originalEvent || event;

        // Check whether the element is draggable, since dragstart might be triggered on a child.
        if (this._elementRef.nativeElement['draggable'] == 'false') return true;

        // Initialize global state.
        this._dndState.isDragging = true;
        this._dndState.itemType = this.dndType && this.dndType.toLowerCase();

        // Set the allowed drop effects. See below for special IE handling.
        this._dndState.dropEffect = "none";
        this._dndState.effectAllowed = this.dndEffectAllowed || this._dndState.ALL_EFFECTS[0];
        event.dataTransfer.effectAllowed = this._dndState.effectAllowed;

        // Internet Explorer and Microsoft Edge don't support custom mime types
        const item = this.dndDraggable;
        const mimeType = this._dndState.MIME_TYPE + (this._dndState.itemType ? ('-' + this._dndState.itemType) : '');
        try {
            event.dataTransfer.setData(mimeType, JSON.stringify(item));
        } catch (e) {
            // Setting a custom MIME type did not work, we are probably in IE or Edge.
            const data = JSON.stringify({ item: item, type: this._dndState.itemType });
            try {
                event.dataTransfer.setData(this._dndState.EDGE_MIME_TYPE, data);
            } catch (e) {
                // We are in Internet Explorer and can only use the Text MIME type. Also note that IE
                // does not allow changing the cursor in the dragover event, therefore we have to choose
                // the one we want to display now by setting effectAllowed.
                let effectsAllowed
                if (this._dndState.effectAllowed == 'all') {
                    effectsAllowed = this._dndState.ALL_EFFECTS;
                } else {
                    effectsAllowed = this._dndState.ALL_EFFECTS.filter((effect) => {
                        return this._dndState.effectAllowed.toLowerCase().indexOf(effect) != -1;
                    });
                }
                event.dataTransfer.effectAllowed = effectsAllowed[0];
                event.dataTransfer.setData(this._dndState.MSIE_MIME_TYPE, data);
            }
        }

        // Add CSS classes. See documentation above.
        this._renderer.addClass(this._elementRef.nativeElement, "dndDragging");
        this._timer.setTimeout(() => { this._renderer.addClass(this._elementRef.nativeElement, "dndDraggingSource"); }, 0);
        this._renderer.removeClass(this._elementRef.nativeElement, 'ws-cursor-grab');
        this._renderer.addClass(this._elementRef.nativeElement, 'ws-cursor-grabbing');

        // Try setting a proper drag image if triggered on a dnd-handle (won't work in IE).
        if (event._dndHandle && event.dataTransfer.setDragImage) {
            event.dataTransfer.setDragImage(this._elementRef.nativeElement, 0, 0);
        }

        // Invoke dragstart callback and prepare extra callback for dropzone.
        this.dndDragstart.emit(event);
        if (this.dndCallback) {
            this._dndState.callback = (params) => { this.dndCallback.emit(params || {}); };
        }

        event.stopPropagation();
    }

    /**
     * The dragend event is triggered when the element was dropped or when the drag
     * operation was aborted (e.g. hit escape button). Depending on the executed action
     * we will invoke the callbacks specified with the dnd-moved or dnd-copied attribute.
     */
    @HostListener('dragend', ['$event'])
    dragEnd(event) {
        event = event.originalEvent || event;

        // Invoke callbacks. Usually we would use event.dataTransfer.dropEffect to determine
        // the used effect, but Chrome has not implemented that field correctly. On Windows
        // it always sets it to 'none', while Chrome on Linux sometimes sets it to something
        // else when it's supposed to send 'none' (drag operation aborted).
        const dropEffect = this._dndState.dropEffect;
        const cb = {copy: 'dndCopied', link: 'dndLinked', move: 'dndMoved', none: 'dndCanceled'};
        this[cb[dropEffect]].emit(event);
        this.dndDragend.emit({event: event, dropEffect: dropEffect});

        // Clean up
        this._dndState.isDragging = false;
        this._dndState.callback = undefined;
        this._renderer.removeClass(this._elementRef.nativeElement, 'dndDragging');
        this._renderer.removeClass(this._elementRef.nativeElement, 'dndDraggingSource');
        this._renderer.removeClass(this._elementRef.nativeElement, 'ws-cursor-grabbing');
        this._renderer.addClass(this._elementRef.nativeElement, 'ws-cursor-grab');
        event.stopPropagation();

        // In IE9 it is possible that the timeout from dragstart triggers after the dragend handler.
        this._timer.setTimeout(() => { this._renderer.removeClass(this._elementRef.nativeElement, 'dndDraggingSource'); }, 0);
    };

    /**
     * When the element is clicked we invoke the callback function
     * specified with the dnd-selected attribute.
     */
    @HostListener('click', ['$event'])
    click(event) {
        if (!this.dndSelected) return;

        event = event.originalEvent || event;

        this.dndSelected.emit(event);

        // Prevent triggering dndSelected in parent elements.
        event.stopPropagation();
    };

    private _disableIfSub: Subscription;

    ngOnInit(): void {
        this._setDraggableAttr();

        if (this.dndDisableIf) {
            this._disableIfSub = this.dndDisableIf.subscribe(disabled => {
                this._renderer.setAttribute(this._elementRef.nativeElement, 'draggable', `${disabled}`);
            });
        }
    }

    ngOnDestroy(): void {
        if (this._disableIfSub) {
            this._disableIfSub.unsubscribe();
        }
    }

    private _setDraggableAttr(): void {
        this._renderer.setAttribute(this._elementRef.nativeElement, 'draggable', 'true');
        this._renderer.addClass(this._elementRef.nativeElement, 'ws-cursor-grab');
    }
}

@Directive({
    selector: '[ws-dnd-list]'
})
export class DragAndDropListDirective implements OnInit {
    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2,
        private readonly _dndState: DragAndDropListService
    ) {}

    @Input() dndList: any[];
    @Input() dndAllowedTypes: string[];
    @Input() dndEffectAllowed: string;
    @Input() dndDisableIf: Observable<any>;
    @Input() dndHorizontalList: boolean;
    @Input() dndExternalSources;
    @Input() dndListItemIdGetter: (value: any) => string;

    // Callbacks
    @Input() dndListChange: EventEmitter<any[]> = new EventEmitter();
    @Input() dndDragover: Function;
    @Input() dndDrop: Function;
    @Input() dndInserted: Function;

    /**
     * The dragenter event is fired when a dragged element or text selection enters a valid drop
     * target. According to the spec, we either need to have a dropzone attribute or listen on
     * dragenter events and call preventDefault(). It should be noted though that no browser seems
     * to enforce this behaviour.
     */
    @HostListener('dragenter', ['$event'])
    dragenter(event) {
        event = event.originalEvent || event;
        this._hoverCount++

        // Calculate list properties, so that we don't have to repeat this on every dragover event.
        const types = this.dndAllowedTypes;
        this._listSettings = {
            allowedTypes: Array.isArray(types) && types.join('|').toLowerCase().split('|'),
            disabled: this.dndDisableIf,
            externalSources: this.dndExternalSources,
            horizontal: this.dndHorizontalList
        };

        const mimeType = this._getMimeType(event.dataTransfer.types);
        if (!mimeType || !this._isDropAllowed(this._getItemType(mimeType))) return true;
        event.preventDefault();
    };

    /**
     * The dragover event is triggered "every few hundred milliseconds" while an element
     * is being dragged over our list, or over an child element.
     */
    @HostListener('dragover', ['$event'])
    dragover(event) {
        event = event.originalEvent || event;

        // Check whether the drop is allowed and determine mime type.
        const mimeType = this._getMimeType(event.dataTransfer.types);
        const itemType = this._getItemType(mimeType);
        if (!mimeType || !this._isDropAllowed(itemType)) return true;

        // Make sure the placeholder is shown, which is especially important if the list is empty.
        if (this._placeholderNode.parentNode != this._listNode) {
            this._elementRef.nativeElement.append(this._placeholder);
        }

        if (event.target != this._listNode) {
            // Try to find the node direct directly below the list node.
            let listItemNode = event.target;
            while (listItemNode.parentNode != this._listNode && listItemNode.parentNode) {
                listItemNode = listItemNode.parentNode;
            }

            if (listItemNode.parentNode == this._listNode && listItemNode != this._placeholderNode) {
                // If the mouse pointer is in the upper half of the list item element,
                // we position the placeholder before the list item, otherwise after it.
                const rect = listItemNode.getBoundingClientRect();
                let isFirstHalf;
                if (this._listSettings.horizontal) {
                    isFirstHalf = event.clientX < rect.left + rect.width / 2;
                } else {
                    isFirstHalf = event.clientY < rect.top + rect.height / 2;
                }
                this._listNode.insertBefore(this._placeholderNode,
                    isFirstHalf ? listItemNode : listItemNode.nextSibling);
            }
        }

        // In IE we set a fake effectAllowed in dragstart to get the correct cursor, we therefore
        // ignore the effectAllowed passed in dataTransfer. We must also not access dataTransfer for
        // drops from external sources, as that throws an exception.
        const ignoreDataTransfer = mimeType == this._dndState.MSIE_MIME_TYPE;
        const dropEffect = this._getDropEffect(event, ignoreDataTransfer);
        if (dropEffect == 'none') return this._stopDragover();

        // At this point we invoke the callback, which still can disallow the drop.
        // We can't do this earlier because we want to pass the index of the placeholder.
        if (this.dndDragover && !this._invokeCallback(this.dndDragover, event, dropEffect, itemType)) {
            return this._stopDragover();
        }

        // Set dropEffect to modify the cursor shown by the browser, unless we're in IE, where this
        // is not supported. This must be done after preventDefault in Firefox.
        event.preventDefault();
        if (!ignoreDataTransfer) {
            event.dataTransfer.dropEffect = dropEffect;
        }

        this._renderer.addClass(this._elementRef.nativeElement, "dndDragover");
        event.stopPropagation();
        return false;
    };

    /**
     * When the element is dropped, we use the position of the placeholder element as the
     * position where we insert the transferred data. This assumes that the list has exactly
     * one child element per array element.
     */
    @HostListener('drop', ['$event'])
    drop(event) {
        event = event.originalEvent || event;

        // Check whether the drop is allowed and determine mime type.
        const mimeType = this._getMimeType(event.dataTransfer.types);
        let itemType = this._getItemType(mimeType);
        if (!mimeType || !this._isDropAllowed(itemType)) return true;

        // The default behavior in Firefox is to interpret the dropped element as URL and
        // forward to it. We want to prevent that even if our drop is aborted.
        event.preventDefault();

        // Unserialize the data that was serialized in dragstart.
        let data;
        const index = this._getPlaceholderIndex();
        try {
            const droppedItem = JSON.parse(event.dataTransfer.getData(mimeType));
            data = this.dndList.find((x, i) => this.dndListItemIdGetter(x) === this.dndListItemIdGetter(droppedItem));
            if (!data) {
                this._stopDragover();
            }
        } catch(e) {
            return this._stopDragover();
        }

        // Drops with invalid types from external sources might not have been filtered out yet.
        if (mimeType == this._dndState.MSIE_MIME_TYPE || mimeType == this._dndState.EDGE_MIME_TYPE) {
            itemType = data.type || undefined;
            data = data.item;
            if (!this._isDropAllowed(itemType)) return this._stopDragover();
        }

        // Special handling for internal IE drops, see dragover handler.
        const ignoreDataTransfer = mimeType == this._dndState.MSIE_MIME_TYPE;
        const dropEffect = this._getDropEffect(event, ignoreDataTransfer);
        if (dropEffect == 'none') return this._stopDragover();

        // Invoke the callback, which can transform the transferredObject and even abort the drop.
        if (this.dndDrop) {
            data = this._invokeCallback(this.dndDrop, event, dropEffect, itemType, index, data);
            if (!data) return this._stopDragover();
        }

        // The drop is definitely going to happen now, store the dropEffect.
        this._dndState.dropEffect = dropEffect;
        if (!ignoreDataTransfer) {
            event.dataTransfer.dropEffect = dropEffect;
        }

        // Insert the object into the array, unless dnd-drop took care of that (returned true).
        if (data !== true) {
            switch (dropEffect) {
                case 'move':
                    this.dndList.splice(index, 0, data);
                    const startIndex = this.dndList.findIndex((x, i) => i !== index && this.dndListItemIdGetter(x) === this.dndListItemIdGetter(data))
                    this.dndList.splice(startIndex, 1);
                    break;
                case 'copy':
                    this.dndList.splice(index, 0, data);
                    break;
                case 'link':

            }
        }
        this.dndList = [...this.dndList]; // Since we mutated we need to copy the array to trigger change detection
        this.dndListChange.emit(this.dndList);
        this._invokeCallback(this.dndInserted, event, dropEffect, itemType, index, data);

        // Clean up
        this._stopDragover();
        event.stopPropagation();
        return false;
    };

    /**
     * We have to remove the placeholder when the element is no longer dragged over our list. The
     * problem is that the dragleave event is not only fired when the element leaves our list,
     * but also when it leaves a child element. Therefore, we determine whether the mouse cursor
     * is still pointing to an element inside the list or not.
     */
    @HostListener('dragleave', ['$event'])
    dragleave(event) {
        event = event.originalEvent || event;
        this._hoverCount--;

        const newTarget = document.elementFromPoint(event.clientX, event.clientY);
        if (this._listNode && this._listNode.contains(newTarget) && !event._dndPhShown) {
            // Signalize to potential parent lists that a placeholder is already shown.
            event._dndPhShown = true;
        }

        if (this._hoverCount === 0) {
            this._stopDragover();
        }
    };

    private _placeholder;
    private _listSettings;
    private _placeholderNode;
    private _listNode;
    private _hoverCount: number = 0;

    ngOnInit(): void {
        this._placeholder = this._getPlaceholderElement();
        this._placeholder.remove();

        this._placeholderNode = this._placeholder;
        this._listNode = this._elementRef.nativeElement;
        this._listSettings = {};
    }

    /**
     * Given the types array from the DataTransfer object, returns the first valid mime type.
     * A type is valid if it starts with MIME_TYPE, or it equals MSIE_MIME_TYPE or EDGE_MIME_TYPE.
     */
    private _getMimeType(types) {
        if (!types) return this._dndState.MSIE_MIME_TYPE; // IE 9 workaround.
        for (let i = 0; i < types.length; i++) {
            if (types[i] == this._dndState.MSIE_MIME_TYPE || types[i] == this._dndState.EDGE_MIME_TYPE ||
                types[i].substr(0, this._dndState.MIME_TYPE.length) == this._dndState.MIME_TYPE) {
                return types[i];
            }
        }
        return null;
    }

    /**
     * Determines the type of the item from the dndState, or from the mime type for items from
     * external sources. Returns undefined if no item type was set and null if the item type could
     * not be determined.
     */
    private _getItemType(mimeType) {
        if (this._dndState.isDragging) return this._dndState.itemType || undefined;
        if (mimeType == this._dndState.MSIE_MIME_TYPE || mimeType == this._dndState.EDGE_MIME_TYPE) return null;
        return (mimeType && mimeType.substr(this._dndState.MIME_TYPE.length + 1)) || undefined;
    }

    /**
     * Checks various conditions that must be fulfilled for a drop to be allowed, including the
     * dnd-allowed-types attribute. If the item Type is unknown (null), the drop will be allowed.
     */
    private _isDropAllowed(itemType) {
        if (this._listSettings.disabled) return false;
        if (!this._listSettings.externalSources && !this._dndState.isDragging) return false;
        if (!this._listSettings.allowedTypes || itemType === null) return true;
        return itemType && this._listSettings.allowedTypes.indexOf(itemType) != -1;
    }

    /**
     * Determines which drop effect to use for the given event. In Internet Explorer we have to
     * ignore the effectAllowed field on dataTransfer, since we set a fake value in dragstart.
     * In those cases we rely on dndState to filter effects.
     */
    private _getDropEffect(event, ignoreDataTransfer) {
        let effects = this._dndState.ALL_EFFECTS;
        if (!ignoreDataTransfer) {
            effects = this._filterEffects(effects, event.dataTransfer.effectAllowed);
        }
        if (this._dndState.isDragging) {
            effects = this._filterEffects(effects, this._dndState.effectAllowed);
        }
        if (this.dndEffectAllowed) {
            effects = this._filterEffects(effects, this.dndEffectAllowed);
        }
        // MacOS automatically filters dataTransfer.effectAllowed depending on the modifier keys,
        // therefore the following modifier keys will only affect other operating systems.
        if (!effects.length) {
            return 'none';
        } else if (event.ctrlKey && effects.indexOf('copy') != -1) {
            return 'copy';
        } else if (event.altKey && effects.indexOf('link') != -1) {
            return 'link';
        } else {
            return effects[0];
        }
    }

    /**
     * Small helper function that cleans up if we aborted a drop.
     */
    private _stopDragover() {
        this._hoverCount = 0;
        this._placeholder.remove();
        this._renderer.removeClass(this._elementRef.nativeElement, 'dndDragover');
        return true;
    }

    /**
     * Invokes a callback with some interesting parameters and returns the callbacks return value.
     */
    private _invokeCallback(expression, event, dropEffect, itemType, index?, item?) {
        if (!expression) { return; }
        return expression({
            callback: this._dndState.callback,
            dropEffect: dropEffect,
            event: event,
            external: !this._dndState.isDragging,
            index: index !== undefined ? index : this._getPlaceholderIndex(),
            item: item || undefined,
            type: itemType
        });
    }

    /**
     * We use the position of the placeholder node to determine at which position of the array the
     * object needs to be inserted
     */
    private _getPlaceholderIndex(): number {
        let children = this._listNode.children;
        if (HTMLCollection.prototype.isPrototypeOf(children)) {
            children = Array.from(children);
        }
        return children.indexOf(this._placeholderNode);
    }

    /**
     * Tries to find a child element that has the dndPlaceholder class set. If none was found, a
     * new li element is created.
     */
    private _getPlaceholderElement() {
        let placeholder;
        if (this._elementRef.nativeElement.children && this._elementRef.nativeElement.children.length) {
            this._elementRef.nativeElement.children.forEach(childNode => {
                const child = childNode;
                if (child.hasClass('dndPlaceholder')) {
                    placeholder = child;
                }
            });
        }
        if (!placeholder) {
            const li = this._renderer.createElement('li');
            this._renderer.addClass(li, 'dndPlaceholder');
            placeholder = li;
        }
        return placeholder;
    }

    /**
     * Filters an array of drop effects using a HTML5 effectAllowed string.
     */
    private _filterEffects(effects, effectAllowed) {
        if (effectAllowed == 'all') return effects;
        return effects.filter(effect => {
            return effectAllowed.toLowerCase().indexOf(effect) != -1;
        });
    }
}

/**
 * Use the dnd-nodrag attribute inside of dnd-draggable elements to prevent them from starting
 * drag operations. This is especially useful if you want to use input elements inside of
 * dnd-draggable elements or create specific handle elements. Note: This directive does not work
 * in Internet Explorer 9.
 */
@Directive({
    selector: '[ws-dnd-no-drag]'
})
export class DragAndDropNoDragDirective implements OnInit {
    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2
    ) {}

    /**
     * Since the element is draggable, the browser's default operation is to drag it on dragstart.
     * We will prevent that and also stop the event from bubbling up.
     */
    @HostListener('dragstart', ['$event'])
    dragstart(event) {
        event = event.originalEvent || event;

        if (!event._dndHandle) {
            // If a child element already reacted to dragstart and set a dataTransfer object, we will
            // allow that. For example, this is the case for user selections inside of input elements.
            if (!(event.dataTransfer.types && event.dataTransfer.types.length)) {
                event.preventDefault();
            }
            event.stopPropagation();
        }
    };

    /**
     * Stop propagation of dragend events, otherwise dnd-moved might be triggered and the element
     * would be removed.
     */
    @HostListener('dragend', ['$event'])
    dragend(event) {
        event = event.originalEvent || event;
        if (!event._dndHandle) {
            event.stopPropagation();
        }
    };

    ngOnInit(): void {
        this._setDraggableAttr();
    }

    private _setDraggableAttr(): void {
        this._renderer.setAttribute(this._elementRef.nativeElement, 'draggable', 'true');
    }
}

/**
 * Use the dnd-handle directive within a dnd-nodrag element in order to allow dragging with that
 * element after all. Therefore, by combining dnd-nodrag and dnd-handle you can allow
 * dnd-draggable elements to only be dragged via specific "handle" elements. Note that Internet
 * Explorer will show the handle element as drag image instead of the dnd-draggable element. You
 * can work around this by styling the handle element differently when it is being dragged. Use
 * the CSS selector .dndDragging:not(.dndDraggingSource) [dnd-handle] for that.
 */
@Directive({
    selector: '[ws-dnd-no-drag]'
})
export class DragAndDropHandleDirective implements OnInit {
    constructor(
        private readonly _elementRef: ElementRef,
        private readonly _renderer: Renderer2
    ) {}

    @HostListener('dragstart', ['$event'])
    dragstart(event) {
        event = event.originalEvent || event;
        event._dndHandle = true;
    };

    @HostListener('dragend', ['$event'])
    dragend(event) {
        event = event.originalEvent || event;
        event._dndHandle = true;
    };

    ngOnInit(): void {
        this._setDraggableAttr();
    }

    private _setDraggableAttr(): void {
        this._renderer.setAttribute(this._elementRef.nativeElement, 'draggable', 'true');
    }
}


