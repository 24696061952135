import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { PaymentBatchService } from 'src/app/Payment-Batch/paymentBatch.service';
import { FinalizeCheckImportPaymentGridComponent } from './finalizeCheckImportPaymentGrid.componet';
import { FinalizeCheckImportChecksGridComponent } from './finalizeCheckImportChecksGrid.component';
import { every } from 'lodash';
import { MessageModalService } from 'src/app/UI-Lib/Message-Box/messageModal.service';

export interface FinalizeCheckImportModalParams {
    paymentBatchId: number;
}

@Component({
    selector: 'finalize-check-import-modal',
    template: `
        <ws-busy-indicator *ngIf="isBusy" [message]="busyMessage"></ws-busy-indicator>
        <ws-modal-wrapper headerLabel="Finalize Check Import"
            acceptLabel="Finalize"
            cancelLabel="Cancel"
            auxButtonLabel="Discard"
            [disableSave]="disableFinalize"
            (save)="finalize()"
            (auxButton)="discard()"
            (cancel)="cancel()">
            <div>
                <div style="display: flex; justify-content: end">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [(ngModel)]="excludeMatchedItems" (change)="filterItems()" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Exclude Matched Items
                        </label>
                    </div>
                </div>
                <div>
                    <div *ngIf="checkImportModel" class="finalize-check-import-grids">
                        <finalize-check-import-payment-grid
                            #finalizeCheckImportPaymentGrid
                            [checkImportPayments]="payments">
                        </finalize-check-import-payment-grid>
                        <finalize-check-import-checks-grid
                            #finalizeCheckImportChecksGrid
                            [checkImportChecks]="checks">
                        </finalize-check-import-checks-grid>
                    </div>
                    <div *ngIf="isBusy" style="height: 600px;"></div>
                </div>
            </div>
        </ws-modal-wrapper>
    `
})

export class FinalizeCheckImportModalComponent implements OnInit,
IWeissmanModalComponent<FinalizeCheckImportModalParams, any> {
    constructor(private readonly _paymentBatchService: PaymentBatchService,
        private readonly _messageModalService: MessageModalService,
        private readonly _bsModalRef: BsModalRef
    ) { }

    @ViewChild('finalizeCheckImportPaymentGrid') finalizeCheckImportPaymentGrid: FinalizeCheckImportPaymentGridComponent;
    @ViewChild('finalizeCheckImportChecksGrid') finalizeCheckImportChecksGrid: FinalizeCheckImportChecksGridComponent;

    params: FinalizeCheckImportModalParams;
    result: boolean = false;

    checkImportModel: Core.PaymentBatchCheckImportModel;
    excludeMatchedItems: boolean = false;
    disableFinalize: boolean = true;
    isBusy: boolean = false;
    busyMessage: string = '';

    get checks() {
        return this.checkImportModel?.checks.filter(x => {
            return this.excludeMatchedItems ? !x.paymentCount : true;
        });
    }

    get payments() {
        return this.checkImportModel?.payments.filter(x => {
            return this.excludeMatchedItems ? !x.checkNo : true;
        });
    }

    async ngOnInit() {
        this.busyMessage = 'Loading Check Import';
        this.isBusy = true;
        try {
            this.checkImportModel = await this._paymentBatchService.getPaymentBatchCheckImport(this.params.paymentBatchId);
            this.disableFinalize = every(this.checkImportModel.payments, x => !x.checkNo);
        } finally {
            this.isBusy = false;
        }
    }

    filterItems() {
        setTimeout(() => {
            this.finalizeCheckImportPaymentGrid.setRowData();
            this.finalizeCheckImportChecksGrid.setRowData();
        });
    }

    async finalize() {
        this.busyMessage = 'Saving Import';
        this.isBusy = true;

        try {
            await this._paymentBatchService.savePaymentBatchCheckImport(
                this.checkImportModel.paymentBatchId,
                this.checkImportModel.paymentBatchCheckImportId
            );

            this.result = true;
            this._bsModalRef.hide();
        } finally {
            this.isBusy = false;
        }
    }

    async discard() {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to discard this import?',
                'Confirm Discard');
        } catch (e) {
            return Promise.resolve();
        }

        this.busyMessage = 'Discarding Import';
        this.isBusy = true;

        try {
            await this._paymentBatchService.discardPaymentBatchCheckImport(
                this.checkImportModel.paymentBatchId,
                this.checkImportModel.paymentBatchCheckImportId
            );
            this._bsModalRef.hide();
        } finally {
            this.isBusy = false;
        }
    }

    cancel() {
        this._bsModalRef.hide();
    }
}