<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header d-flex">
        <h3 class="ws-flex-auto ws-truncate">
            Schedules<span *ngIf="assessor"> [All Tax Years - All Assessors - {{taxYear}} {{ factorTablesAssessorName }} Tables]</span>
            <ag-grid-row-count [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
        </h3>
        <div class="ws-flex-none header-button-container">
            <button *ngIf="!editMode"
                    title="Export"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    (click)="export()">
                <i class="fa fa fa-file-excel-o fa-align"></i>
            </button>
            <button *ngIf="!editMode && canEdit"
                    title="Edit"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    [disabled]="!canEnterEditMode"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button title="Add Schedule"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    *ngIf="editMode"
                    (click)="add()">
                <i class="fa fa-plus-circle fa-align"></i>
            </button>
            <button title="Save"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    *ngIf="editMode"
                    [disabled]="!hasChanges()"
                    (click)="save()">
                <i class="fa fa-save fa-align"></i>
            </button>
            <button title="Cancel"
                    type="button"
                    class="btn flat-button icon-button primary-button"
                    *ngIf="editMode"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
            <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle>
            <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="!isExpanded" (click)="expandComponent()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button" class="btn flat-button icon-button secondary-button" *ngIf="isExpanded" (click)="collapseComponent()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body d-flex flex-column">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
