import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadComponent } from './typeahead.component';
import { TypeaheadDirective } from './typeahead.directive';
import { TypeaheadService } from './typeahead.service';
import { DirectivesModule } from '../Directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        DirectivesModule
    ],
    declarations: [
        TypeaheadComponent,
        TypeaheadDirective
    ],
    providers: [TypeaheadService],
    exports: [TypeaheadDirective]
})
export class WsTypeaheadModule { }
