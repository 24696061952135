import { Component, Input } from '@angular/core';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ProductAnalyticsService } from '../Amplitude/productAnalytics.service';
import {
    AssessmentTaxesDetailsModalComponent,
    AssessmentTaxesDetailsModalParams
} from './assessment-taxes-details-modal.component';

@Component({
    selector: 'assessment-taxes-details-icon',
    template: `
        <i class="fa fa-th" style="cursor: pointer;" (click)="showModal()"></i>
    `
})
export class AssessmentTaxesDetailsIconComponent {
    constructor(private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _weissmanModalService: WeissmanModalService) { }

    @Input() siteId?: number;
    @Input() parcelId?: number;
    @Input() selectableYears?: number[];
    @Input() analyticsCategory?: string;

    showModal(): void {
        if (this.analyticsCategory) {
            this._productAnalyticsService.logEvent(`click-${this.analyticsCategory}-asmt-details-taxes`);
        }

        const params: AssessmentTaxesDetailsModalParams = {
            siteId: this.siteId,
            parcelId: this.parcelId,
            selectableYears: this.selectableYears,
            analyticsCategory: this.analyticsCategory
        };

        this._weissmanModalService.showAsync(AssessmentTaxesDetailsModalComponent, params, 'modal-lg');
    }
}
