import { Component,  OnInit, OnDestroy, Input } from '@angular/core';
import { TaxingDistrictListParams, TaxingDistrictListComponent } from './Taxing-District-List/taxingDistrictList.component';
import * as _ from 'lodash';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';

@Component({
    selector: 'taxing-district',
    templateUrl: './taxingDistrict.component.html'
})
export class TaxingDistrictComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService) { }

    @Input() assessor: Core.AssessorModel;
    @Input() editMode: boolean;
    
    ngOnInit(): void { }

    ngOnDestroy(): void { }

    async showTaxingDistricts(): Promise<void> {
        const params: TaxingDistrictListParams = {
            assessorId: this.assessor.assessorID,
            assessorName: this.assessor.name,
            taxingDistricts: _.map(this.assessor.taxingDistrictModels, _.clone),
            editMode: this.editMode
        };

        const result = await this._modalService.showAsync(TaxingDistrictListComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        this.assessor.taxingDistrictModels = result.taxingDistricts;

        return Promise.resolve();
    }
}
