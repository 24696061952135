import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, FilterChangedEvent, IFilterOptionDef, RowNode } from 'ag-grid-community';
import { BusyIndicatorService, SnackBarService } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { FormRepository } from '../../Repositories';
import { DropdownCellRenderer, ICellRendererParamsForDropdowns } from './agGridDropdownCellRenderer.component';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../AgGrid';
import { WeissmanMutexService, IMutexServiceHandler } from '../../WeissmanMutexService';
import { BaseExpandableAgGridComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { FormAssetClassMappingListGridActionCellRendererComponent, FormAssetClassMappingListGridActionCellRendererParams } from './agGridActionCellRenderer.component';
import { Subscription, BehaviorSubject, lastValueFrom } from 'rxjs';
import { FactorTableDetailsComponent, FactorTableDetailsParams } from '../../Factor-Table/Factor-Table-Details/factorTableDetails.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AgGridCheckboxCellRendererComponent, ICellRendererParamsForAgGridCheckbox } from '../../AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import * as _ from 'lodash';
import {
    ExpandCellRendererComponent,
    ICellRendererParamsForExpandCellRenderer
} from '../../../Common/AgGrid/agGridExpandCellRenderer.component';
import {
    ExpandCellHeaderRendererComponent,
    ICellRendererParamsForExpandCellHeaderParams
} from '../../../Common/AgGrid/agGridExpandCellHeaderRenderer.component';

export class FormClassificationMappingTreeItemModel implements Compliance.FormClassificationMappingModel {
    formClassificationMappingId: number;
    assetClassificationId: number;
    depreciationFactorTableId?: number;
    isDepreciationFactorTableLocked: boolean;
    ownedFormRevisionScheduleId?: number;
    leasedToFormRevisionScheduleId?: number;
    leasedFromFormRevisionScheduleId?: number;
    indexFactorTableId?: number;
    level: number;
    isScheduleOverridden?: boolean;
    isDepreciationOverridden?: boolean;
    isIndexOverridden?: boolean;
    childOverridesSchedule?: boolean;
    childOverridesDepreciation?: boolean;
    childOverridesIndex?: boolean;
    assetClassName: string;
    assetClassAllowPerpetual: boolean;
    parentTreeItem?: FormClassificationMappingTreeItemModel;
    isFormRevisionScheduleReportable: boolean;
    isExempt: boolean;
}

interface ScheduleFactorTableModel {
    factorTableId: number;
    isLocked: boolean;
    isReportable: boolean;
}

@Component({
    selector: 'form-asset-class-mapping',
    templateUrl: './formAssetClassMapping.component.html',
    styleUrls: ['./formAssetClassMapping.component.scss']
})
export class FormAssetClassMappingComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    // The asset class mapping is a grid for associating schedules and factor tables to classifications. It uses a grid structure, but has nodes which can be
    // expanded and collapsed and which will automatically expand during search to show matching children. A later change also happened to allow the user to
    // select a node where children should be filtered out, and exclude the children from the current filter, to allow a more interactive navigation.
    // The grid itself is fed _mappingViewData, a getter which creates a view from _flattenedData.
    // The complications around filtering are handled in two main ways - first, there is an onAgGridFilterChanged event which uses the various filter options
    // to determine if nodes should be expanded to find the inner children. Second the filter itself has to, for schedule and factor table, use the wider data
    // around those by feeding in the assetClassName as the filter value, then doing a lookup with that in the filterMappingLookup method to look inside _flattened data.

    constructor(
        private readonly _formService: FormService,
        private readonly _formRepository: FormRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _modalService: WeissmanModalService,
        private readonly _snackBarService: SnackBarService
        ) {
        super(_formService, 'form-asset-class-mapping');
    }

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;
    private _formRevisionSub: Subscription;
    private _formRevisionYearSub: Subscription;
    private _schedulesSub: Subscription;
    private _factorTableSub: Subscription;
    private _assessorSub: Subscription;
    private _assessorsSub: Subscription;
    private _editModeSubject = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _classificationMappingsUpdatedSub: Subscription;
    private _assetClassificationsNameMap: { [s: string]: Compliance.AssetClassificationModel } = {};
    private _flattenedData: FormClassificationMappingTreeItemModel[] = [];
    private _flattenedAssetClassificationsFullList: Compliance.AssetClassificationModel[] = [];
    private _childrenAssetIdsToShowDespiteFilter: number[] = [];
    private _expandedClassifications = new Set<number>();
    private _collapsedClassifications = new Set<number>();
    private _indexFactorTables: Compliance.FormFactorTableModel[] = [];
    private _depreciationFactorTables: Compliance.FormFactorTableModel[] = [];
    private _originalMappingViewData: FormClassificationMappingTreeItemModel[];
    private _canExpandAllRows: boolean = true;
    private _filteredOutChildrenMap: Map<number, Compliance.AssetClassificationModel[]> = new Map();
    private _parentRequiredFilterSet: Set<number> = new Set();

    editMode: boolean = false;

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    formRevisionSchedules: Compliance.FormRevisionScheduleModel[] = [];

    get canEnterEditMode(): boolean {
        return this._formService.assessor &&
            this._mutexService.canAcquire(this._formService.editGroup) &&
            ((!this._formService.assessor.isMappingCertified) &&
            ((!this._formService.assessor.isUsingDefaultMappings) ||
                (this._formService.assessor && !this._formService.assessor.assessorId)));
    }

    get factorTablesAssessorName(): string {
        return this._formService.factorTablesAssessorName;
    }

    get classificationMappingsAssessorName(): string {
        return this._formService.classificationMappingsAssessorName;
    }

    get assessor(): Compliance.FormRevisionAssessorModel {
        return this._formService.assessor;
    }

    get taxYear(): number {
        return this._formService.taxYear;
    }

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'no-filter-applies': (params) => params.data && this._parentRequiredFilterSet.has(params.data.assetClassificationId)
}
        })
        .withColumnResize()
        .withLoadingOverlay()
        .withContext(this)
        .withTextSelection()
        .build();

    async ngOnInit(): Promise<void> {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._formService.editGroup);
            }
        });

        this._formRevisionSub = this._formService.formRevision$.subscribe(() => {
            this._setAssetClassifications();

            // initial load
            this._setRowData();
        });

        this._assessorSub = this._formService.assessor$.subscribe(() => {
            // assessor changed
            this._setRowData();
        });

        this._assessorsSub = this._formService.assessors$.subscribe(() => {
            // assessors changed (add/remove/change defaults)
            this._setRowData();
        });

        this._formRevisionYearSub = this._formService.formRevisionYear$.subscribe(() => {
            // year changed
            this._setRowData();
        });

        this._schedulesSub = this._formService.schedules$.subscribe(() => {
            // schedules changed
            this._setRowData();
        });

        this._factorTableSub = this._formService.factorTables$.subscribe(() => {
            // factor tables changed
            this._setRowData();
        });

        this._classificationMappingsUpdatedSub = this._formService.assetClassificationMappings$.subscribe(() => {
            // mappings updated
            this._setRowData();
        });
    }

    ngOnDestroy(): void {
        this._formRevisionSub.unsubscribe();
        this._editModeSub.unsubscribe();
        this._schedulesSub.unsubscribe();
        this._factorTableSub.unsubscribe();
        this._assessorSub.unsubscribe();
        this._assessorsSub.unsubscribe();
        this._classificationMappingsUpdatedSub.unsubscribe();
        this._formRevisionYearSub.unsubscribe();
        this._mutexService.release(this, this._formService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        // get API objects and start setting up the AgGrid
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;
        super.setGridApi(event.api);

        const columns: ColDef[] = [
            {
                headerName: 'Asset Class',
                field: 'assetClassName',
                lockPinned: true,
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith'],
                    textCustomComparator: this._filterName.bind(this),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                suppressMenu: true,
                cellClass: (params) => {
                    if (params.data && params.data.level) {
                        return `tree-level-${params.data.level}`;
                    }
                    return '';
                },
                cellRendererFramework: ExpandCellRendererComponent,
                cellRendererParams: {
                    expand: this._expand.bind(this),
                    collapse: this._collapse.bind(this),
                    canExpand: this._canExpand.bind(this),
                    canCollapse: this._canCollapse.bind(this),
                    canShowHiddenChildren: this._canShowHiddenChildren.bind(this),
                    showHiddenChildren: this._showHiddenChildren.bind(this),
                    getName: this._getName.bind(this),
                    getTooltip: this._getTooltip.bind(this)
                } as ICellRendererParamsForExpandCellRenderer,
                headerComponentFramework: ExpandCellHeaderRendererComponent,
                headerComponentParams: {
                    expandAll: this._expandAll.bind(this),
                    collapseAll: this._collapseAll.bind(this),
                    canExpandAll: this._canExpandAll.bind(this),
                    canCollapseAll: this._canCollapseAll.bind(this),
                    headerText: 'Asset Class'
                }
            },
            {
                headerName: 'Owned Schedule',
                field: 'ownedFormRevisionScheduleId',
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: [
                        'startsWith',
                        'equals',
                        'notEqual',
                        'contains',
                        'notContains',
                        'endsWith',
                        AgGridFilterParams.blankFilterOptionDef,
                        AgGridFilterParams.notBlankFilterOptionDef],
                    textCustomComparator: this._filterMappingLookup.bind(this, 'ownedFormRevisionScheduleId', 'formRevisionScheduleId', this.formRevisionSchedules, x => `${x.name} (${x.abbr})`),
                    newRowsAction: 'keep',
                    suppressAndOrCondition: true,
                    defaultOption: 'contains'
                },
                cellRendererFramework: DropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => this.canEdit,
                    editMode$: this._editModeSubject.asObservable(),
                    isDisabled: (x) => false,
                    dropdownItems: this.formRevisionSchedules,
                    name: 'schedule',
                    valueField: 'formRevisionScheduleId',
                    allowNull: true,
                    change: this.scheduleChanged.bind(this),
                    displayGetter: (item: Compliance.FormRevisionScheduleModel) => `${item.name} (${item.abbr})`
                }
            },
            {
                headerName: 'Dep. Table',
                field: 'depreciationFactorTableId',
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
                    textCustomComparator: this._filterMappingLookup.bind(this, 'depreciationFactorTableId', 'factorTableId', this._depreciationFactorTables, this.factorAssessorTableRenderer),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                cellRendererFramework: DropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => {
                        const row = x.data as FormClassificationMappingTreeItemModel;
                        return this.canEdit &&
                            row &&
                            (row.isFormRevisionScheduleReportable && !row.isDepreciationFactorTableLocked);
                    },
                    isDisabled: (x) => false,
                    editMode$: this._editModeSubject.asObservable(),
                    dropdownItems: this._depreciationFactorTables,
                    name: 'depreciationFactorTable',
                    valueField: 'factorTableId',
                    allowNull: true,
                    change: this.depreciationTableChanged.bind(this),
                    displayGetter: this.factorAssessorTableRenderer
                }
            },
            {
                headerName: 'Leased To Schedule',
                field: 'leasedToFormRevisionScheduleId',
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: [
                        'startsWith',
                        'equals',
                        'notEqual',
                        'contains',
                        'notContains',
                        'endsWith',
                        AgGridFilterParams.blankFilterOptionDef,
                        AgGridFilterParams.notBlankFilterOptionDef],
                    textCustomComparator: this._filterMappingLookup.bind(this, 'leasedToFormRevisionScheduleId', 'formRevisionScheduleId', this.formRevisionSchedules, x => `${x.name} (${x.abbr})`),
                    newRowsAction: 'keep',
                    suppressAndOrCondition: true,
                    defaultOption: 'contains'
                },
                cellRendererFramework: DropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => this.canEdit,
                    editMode$: this._editModeSubject.asObservable(),
                    isDisabled: (x) => false,
                    dropdownItems: this.formRevisionSchedules,
                    name: 'schedule',
                    valueField: 'formRevisionScheduleId',
                    allowNull: true,
                    change: this.leasedToScheduleChanged.bind(this),
                    displayGetter: (item: Compliance.FormRevisionScheduleModel) => `${item.name} (${item.abbr})`
                }
            },
            {
                headerName: 'Leased From Schedule',
                field: 'leasedFromFormRevisionScheduleId',
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: [
                        'startsWith',
                        'equals',
                        'notEqual',
                        'contains',
                        'notContains',
                        'endsWith',
                        AgGridFilterParams.blankFilterOptionDef,
                        AgGridFilterParams.notBlankFilterOptionDef],
                    textCustomComparator: this._filterMappingLookup.bind(this, 'leasedFromFormRevisionScheduleId', 'formRevisionScheduleId', this.formRevisionSchedules, x => `${x.name} (${x.abbr})`),
                    newRowsAction: 'keep',
                    suppressAndOrCondition: true,
                    defaultOption: 'contains'
                },
                cellRendererFramework: DropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => this.canEdit,
                    editMode$: this._editModeSubject.asObservable(),
                    isDisabled: (x) => false,
                    dropdownItems: this.formRevisionSchedules,
                    name: 'schedule',
                    valueField: 'formRevisionScheduleId',
                    allowNull: true,
                    change: this.leasedFromScheduleChanged.bind(this),
                    displayGetter: (item: Compliance.FormRevisionScheduleModel) => `${item.name} (${item.abbr})`
                }
            },
            {
                headerName: 'Index Table',
                field: 'indexFactorTableId',
                filter: 'agTextColumnFilter',
                filterValueGetter: x => (x.data ? `${x.data['assetClassName']}@${x.data['assetClassificationId']}` : ''),
                filterParams: {
                    filterOptions: ['startsWith', 'equals', 'notEqual', 'contains', 'notContains', 'endsWith', AgGridFilterParams.blankFilterOptionDef, AgGridFilterParams.notBlankFilterOptionDef],
                    textCustomComparator: this._filterMappingLookup.bind(this, 'indexFactorTableId', 'factorTableId', this._indexFactorTables, this.factorAssessorTableRenderer),
                    newRowsAction: 'keep',
                    defaultOption: 'contains',
                    suppressAndOrCondition: true
                },
                cellRendererFramework: DropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => {
                        const row = x.data as FormClassificationMappingTreeItemModel;
                        return this.canEdit &&
                            row &&
                            (row.isFormRevisionScheduleReportable);
                    },
                    isDisabled: (x) => false,
                    editMode$: this._editModeSubject.asObservable(),
                    dropdownItems: this._indexFactorTables,
                    name: 'indexFactorTables',
                    valueField: 'factorTableId',
                    allowNull: true,
                    change: this.factorTableChanged.bind(this),
                    displayGetter: this.factorAssessorTableRenderer
                }
            },
            {
                headerName: 'Exempt',
                field: 'isExempt',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridCheckboxCellRendererComponent,
                cellRendererParams: {
                    valueGetter: (params: ICellRendererParamsForAgGridCheckbox) => {
                        const assetClass = params.data as FormClassificationMappingTreeItemModel;
                        if (!assetClass) {
                            return;
                        }
                        return assetClass.isExempt;
                    },
                    onValueChanged: this.exemptChanged.bind(this),
                    isVisible: (params: ICellRendererParamsForAgGridCheckbox) => true,
                    canEdit: (params: ICellRendererParamsForAgGridCheckbox) => {
                        const assetClass = params.data as FormClassificationMappingTreeItemModel;
                        if (!assetClass) {
                            return;
                        }

                        return !!assetClass.ownedFormRevisionScheduleId;
                    },
                    canEnterEditMode: (params: ICellRendererParamsForAgGridCheckbox) => this._editModeSubject.getValue()
                } as ICellRendererParamsForAgGridCheckbox
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                suppressMenu: true,
                sortable: false,
                cellRendererFramework: FormAssetClassMappingListGridActionCellRendererComponent,
                cellRendererParams: {
                    canViewDepreciationTable: this._canViewDepreciationTable.bind(this),
                    viewDepreciationTable: this._viewDepreciationTable.bind(this),
                } as FormAssetClassMappingListGridActionCellRendererParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridColumnApi.setColumnPinned('actions', 'right');
        this._setRowData();
    }

    async wsMutexRelease(groupId: string): Promise<void> {
        await Promise.resolve();
    }

    factorAssessorTableRenderer(item: Compliance.FormFactorTableModel) {
        return `${item.factorTableName} (${item.life} yr life)`;
    }

    onAgGridFilterChanged(event: FilterChangedEvent) {
        const filters = event.api.getFilterModel();
        let numberExpanded = 0;

        this._collapsedClassifications.forEach((assetClassificationId: number) => {
            const matchingRow = this._flattenedData.find((dataRow: FormClassificationMappingTreeItemModel) => {
                return dataRow.assetClassificationId === assetClassificationId;
            });
            let filteredOut:boolean = false;
            if (filters.assetClassName) {
                const filter = filters.assetClassName.filterValues[0];
                const filterType = filter.filterType.displayKey;
                const assetClassNameString = filter.filterValue;
                if (!this._filterName(filterType, `${matchingRow.assetClassName}@${matchingRow.assetClassificationId}`, assetClassNameString)) {
                    filteredOut = true;
                }
            }

            if (filters.formRevisionScheduleId) {
                const filter = filters.formRevisionScheduleId.filterValues[0];
                if (filter.filterType.displayKey === AgGridFilterParams.blankFilterOptionDef.displayKey || filter.filterType.displayKey === AgGridFilterParams.notBlankFilterOptionDef.displayKey) {
                    // This seems to be taken care of by the nature of those filters, which explicitly account for children
                } else {
                    const scheduleFilterType = filter.filterType.displayKey;
                    const scheduleFilterValue = filter.filterValue;
                    if (!this._filterMappingLookup('formRevisionScheduleId', 'formRevisionScheduleId', this.formRevisionSchedules, x => `${x.name} (${x.abbr})`, scheduleFilterType, `${matchingRow['assetClassName']}@${matchingRow['assetClassificationId']}`, scheduleFilterValue) ) {
                        filteredOut = true;
                    }
                }
            }

            if (filters.depreciationFactorTableId) {
                const filter = filters.depreciationFactorTableId.filterValues[0];
                const scheduleFilterType = filter.filterType.displayKey;
                const scheduleFilterValue = filter.filterValue;
                if (!this._filterMappingLookup('depreciationFactorTableId', 'factorTableId', this._depreciationFactorTables, this.factorAssessorTableRenderer, scheduleFilterType, `${matchingRow['assetClassName']}@${matchingRow['assetClassificationId']}`, scheduleFilterValue) ) {
                    filteredOut = true;
                }
            }

            if (!filteredOut) {
                this._collapsedClassifications.delete(assetClassificationId);
                this._expandedClassifications.add(assetClassificationId);
                numberExpanded++;
            }
        });
        if (numberExpanded > 0) {
            this._setRowData(false);
        }

        this._childrenAssetIdsToShowDespiteFilter = [];
        this.mapNodesWithFilteredOutChildren();
        // redraw to apply css classes, does not happen automatically on filter change
        this._setRowData(false);
    }

    // does this classification have children but which are missing from the filtered row data
    mapNodesWithFilteredOutChildren(): void {
        const filteredOutChildrenMap: Map<number, Compliance.AssetClassificationModel[]> = new Map();
        const idsInGrid: number[] = [];
        this._gridApi.forEachNodeAfterFilter((rowNode) => {
            if (rowNode && rowNode.data) {
                const ac = rowNode.data as FormClassificationMappingTreeItemModel;
                idsInGrid.push(ac.assetClassificationId);
            }
        });

        this._gridApi.forEachNodeAfterFilter((rowNode) => {
            if (rowNode && rowNode.data) {
                const treeModel = rowNode.data as FormClassificationMappingTreeItemModel;
                const assetClassification: Compliance.AssetClassificationModel =
                    this._flattenedAssetClassificationsFullList.find(ac => ac.assetClassificationId ===
                        treeModel.assetClassificationId);
                if (assetClassification &&
                    assetClassification.childClassifications &&
                    assetClassification.childClassifications.length > 0) {
                    const filteredOutChildren =
                        assetClassification.childClassifications.filter(
                            child => idsInGrid.indexOf(child.assetClassificationId) === -1);
                    if (filteredOutChildren.length > 0) {
                        filteredOutChildrenMap.set(assetClassification.assetClassificationId, filteredOutChildren);
                    }
                }
            }
        });

        this._filteredOutChildrenMap = filteredOutChildrenMap;
    }

    getRootDataNode(node: FormClassificationMappingTreeItemModel): FormClassificationMappingTreeItemModel {
        if (node.parentTreeItem) {
            return this.getRootDataNode(node.parentTreeItem);
        } else {
            return node;
        }
    }

    getAssetClassificationById(assetClassificationId: number): Compliance.AssetClassificationModel {
        return this._formService.assetClassifications.find(ac => ac.assetClassificationId === assetClassificationId);
    }

    exemptChanged(params: ICellRendererParamsForAgGridCheckbox, value: boolean): void {
        const assetClass = params.data as FormClassificationMappingTreeItemModel;
        if (!assetClass) { return; }

        this._setExempt(assetClass, value);
    }

    scheduleChanged(params: ICellRendererParamsForDropdowns) {
        const row = params.data as FormClassificationMappingTreeItemModel;
        const originalValue = params.originalValue as number;
        this._setFormRevisionScheduleId(row.assetClassificationId, originalValue, row.ownedFormRevisionScheduleId);
        this._setExempt(row, row.isExempt && !!row.ownedFormRevisionScheduleId);
        this._setDepreciationTables(row);
        this._populateOverrides(this.getAssetClassificationById(this.getRootDataNode(params.data).assetClassificationId));
    }

    leasedToScheduleChanged(params: ICellRendererParamsForDropdowns) {
        const row = params.data as FormClassificationMappingTreeItemModel;
        const originalValue = params.originalValue as number;
        this._setLeasedToFormRevisionScheduleId(row.assetClassificationId, originalValue, row.leasedToFormRevisionScheduleId);
        this._populateOverrides(this.getAssetClassificationById(this.getRootDataNode(params.data).assetClassificationId));
    }

    leasedFromScheduleChanged(params: ICellRendererParamsForDropdowns) {
        const row = params.data as FormClassificationMappingTreeItemModel;
        const originalValue = params.originalValue as number;
        this._setLeasedFromFormRevisionScheduleId(row.assetClassificationId, originalValue, row.leasedFromFormRevisionScheduleId);
        this._populateOverrides(this.getAssetClassificationById(this.getRootDataNode(params.data).assetClassificationId));
    }

    private _setFormRevisionScheduleId(parentAssetClassificationId: number, oldFormRevisionScheduleId, newFormRevisionScheduleId: number): void {
        // get children where the old value is the same
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === parentAssetClassificationId && (!x.ownedFormRevisionScheduleId || (x.ownedFormRevisionScheduleId === oldFormRevisionScheduleId)));
        children.forEach(x => {
            x.ownedFormRevisionScheduleId = newFormRevisionScheduleId;
            this._setFormRevisionScheduleId(x.assetClassificationId, oldFormRevisionScheduleId, newFormRevisionScheduleId);
        });
    }

    private _setLeasedToFormRevisionScheduleId(parentAssetClassificationId: number, oldFormRevisionScheduleId, newFormRevisionScheduleId: number): void {
        // get children where the old value is the same
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === parentAssetClassificationId && (!x.leasedToFormRevisionScheduleId || (x.leasedToFormRevisionScheduleId === oldFormRevisionScheduleId)));
        children.forEach(x => {
            x.leasedToFormRevisionScheduleId = newFormRevisionScheduleId;
            this._setLeasedToFormRevisionScheduleId(x.assetClassificationId, oldFormRevisionScheduleId, newFormRevisionScheduleId);
        });
    }

    private _setLeasedFromFormRevisionScheduleId(parentAssetClassificationId: number, oldFormRevisionScheduleId, newFormRevisionScheduleId: number): void {
        // get children where the old value is the same
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === parentAssetClassificationId && (!x.leasedFromFormRevisionScheduleId || (x.leasedFromFormRevisionScheduleId === oldFormRevisionScheduleId)));
        children.forEach(x => {
            x.leasedFromFormRevisionScheduleId = newFormRevisionScheduleId;
            this._setLeasedFromFormRevisionScheduleId(x.assetClassificationId, oldFormRevisionScheduleId, newFormRevisionScheduleId);
        });
    }

    depreciationTableChanged(params: ICellRendererParamsForDropdowns) {
        const row = params.data as FormClassificationMappingTreeItemModel;
        const originalValue = params.originalValue as number;
        this._setDepreciationTableId(row.assetClassificationId, originalValue, row.depreciationFactorTableId);
        this._populateOverrides(this.getAssetClassificationById(this.getRootDataNode(params.data).assetClassificationId));
    }

    factorTableChanged(params: ICellRendererParamsForDropdowns) {
        const row = params.data as FormClassificationMappingTreeItemModel;
        const originalValue = params.originalValue as number;
        this._setIndexFactorTableId(row.assetClassificationId, originalValue, row.indexFactorTableId);
        this._populateOverrides(this.getAssetClassificationById(this.getRootDataNode(params.data).assetClassificationId));
    }

    async export(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Exporting' });

        try {
            const lrpId = await this._formService.exportFormClassificationMappings();
            this._snackBarService.addById(lrpId, Compliance.LongRunningProcessTypeEnum.FormClassificationMappingExport);
        } finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this._flattenedData = _.cloneDeep(this._originalMappingViewData);
        this._setRowData();
        this._editModeSubject.next(false);
    }

    async edit(): Promise<void> {
        const res = await this._formService.checkEditDefaultAssessor();
        if (res) {
            this._originalMappingViewData = _.cloneDeep(this._flattenedData);
            this._mutexService.acquire(this, this._formService.editGroup);
            this._editModeSubject.next(true);
        }
    }

    async save(): Promise<void> {
        if (!this.okToSave()) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            const mappings = this._flattenedData.map((mvd: FormClassificationMappingTreeItemModel) => {
                const mappingModel: Compliance.FormClassificationMappingModel = {
                    formClassificationMappingId: mvd.formClassificationMappingId,
                    assetClassificationId: mvd.assetClassificationId,
                    depreciationFactorTableId: mvd.depreciationFactorTableId,
                    indexFactorTableId: mvd.indexFactorTableId,
                    ownedFormRevisionScheduleId: mvd.ownedFormRevisionScheduleId,
                    leasedFromFormRevisionScheduleId: mvd.leasedFromFormRevisionScheduleId,
                    leasedToFormRevisionScheduleId: mvd.leasedToFormRevisionScheduleId,
                    transferId: null,
                    isExempt: mvd.isExempt
                };

                return mappingModel;
            }).filter((c) => c.ownedFormRevisionScheduleId
                            || c.leasedToFormRevisionScheduleId
                            || c.leasedFromFormRevisionScheduleId
                            || c.indexFactorTableId
                            || c.depreciationFactorTableId
                            || c.isExempt);

            await lastValueFrom(this._formRepository.saveClassificationMappings(this._formService.assessor.formRevisionYearAssessorId, mappings));

            await this._formService.loadAssetClassificationMappings();

            this._editModeSubject.next(false);

        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    okToSave() {
        return this._flattenedData.every(
            (c: FormClassificationMappingTreeItemModel) => {
                return (
                    !!(c.ownedFormRevisionScheduleId && (c.depreciationFactorTableId || !c.indexFactorTableId)) ||
                        (!c.ownedFormRevisionScheduleId && !c.indexFactorTableId && !c.depreciationFactorTableId && !c.leasedFromFormRevisionScheduleId && !c.leasedToFormRevisionScheduleId));
            });
    }

    private _setExempt(assetClass: FormClassificationMappingTreeItemModel, isExempt: boolean): void {
        assetClass.isExempt = isExempt;

        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === assetClass.assetClassificationId);
        children.forEach(x => {
            x.isExempt = isExempt;
            this._setExempt(x, isExempt);
        });
    }

    private _canViewDepreciationTable(params: FormAssetClassMappingListGridActionCellRendererParams): boolean {
        const mapping = params.data as FormClassificationMappingTreeItemModel;
        return mapping && mapping.depreciationFactorTableId ? true : false;
    }

    private async _viewDepreciationTable(params: FormAssetClassMappingListGridActionCellRendererParams): Promise<void> {
        const mapping = params.data as FormClassificationMappingTreeItemModel;
        if (!(mapping && mapping.depreciationFactorTableId)) {
            return Promise.resolve();
        }

        const tableParams: FactorTableDetailsParams = {
            editMode: false,
            factorTableId: mapping.depreciationFactorTableId
        };

        await this._modalService.showAsync(FactorTableDetailsComponent, tableParams, 'modal-lg');

        return Promise.resolve();
    }

    private _setDepreciationTableId(parentAssetClassificationId: number, oldDepreciationTableId, newDepreciationTableId: number): void {
        // get children where the old value is the same
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === parentAssetClassificationId && x.depreciationFactorTableId === oldDepreciationTableId);
        children.forEach(x => {
            x.depreciationFactorTableId = newDepreciationTableId;
            this._setDepreciationTableId(x.assetClassificationId, oldDepreciationTableId, newDepreciationTableId);
        });
    }

    private _setIndexFactorTableId(parentAssetClassificationId: number, oldIndexTableId, newIndexTableId: number): void {
        // get children where the old value is the same
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === parentAssetClassificationId && x.indexFactorTableId === oldIndexTableId);
        children.forEach(x => {
            x.indexFactorTableId = newIndexTableId;
            this._setIndexFactorTableId(x.assetClassificationId, oldIndexTableId, newIndexTableId);
        });
    }

    private _setDepreciationTables(row: FormClassificationMappingTreeItemModel) {
        const scheduleFactorTable = this._getScheduleFactorTable(row);
        this._setDepreciationTable(row, scheduleFactorTable.factorTableId, scheduleFactorTable.isLocked, scheduleFactorTable.isReportable);
    }

    private _setDepreciationTable(row: FormClassificationMappingTreeItemModel, defaultFactorTableId: number, isLocked: boolean, isReportable: boolean) {
        // set factor table when the schedule is locked or has a default factor table
        row.isDepreciationFactorTableLocked = isLocked;
        // WR-5132 Removing the locked condition. Leaving code here in-case this causes regressions. Remove post Epic 75
        //if (row.isDepreciationFactorTableLocked || !row.depreciationFactorTableId) {
            row.depreciationFactorTableId = defaultFactorTableId;
        //}

        // remove factor table when the schedule is not reportable
        row.isFormRevisionScheduleReportable = isReportable;
        if (!row.isFormRevisionScheduleReportable) {
            row.depreciationFactorTableId = null;
            row.indexFactorTableId = null;
        }

        // get children using the schedule that have no factor table or get all children using the schedule if factor table is locked to the schedule
        const children = this._flattenedData.filter(x => x.parentTreeItem && x.parentTreeItem.assetClassificationId === row.assetClassificationId && x.ownedFormRevisionScheduleId === row.ownedFormRevisionScheduleId && (isLocked || !x.depreciationFactorTableId));
        children.forEach(x => {
            this._setDepreciationTable(x, defaultFactorTableId, isLocked, isReportable);
        });
    }

    private _setAssetClassifications(): void {
        this._assetClassificationsNameMap = {};
        this._flattenedAssetClassificationsFullList = [];
        this._formService.assetClassifications.forEach((ac) => {
            this._mapClassification(ac);
        });
    }

    private _setSchedules(): void {
        const sorted = this._formService.schedules.sort((x, y) => x.name.localeCompare(y.name));
        this.formRevisionSchedules.length = 0;
        this.formRevisionSchedules.push.apply(this.formRevisionSchedules, sorted);
    }

    private _setFactorTables(): void {
        this._indexFactorTables.length = 0;  //Using this method to update so that the column definition still references the same array.
        this._indexFactorTables.push.apply(this._indexFactorTables, this._formService.factorTables.filter((t) => t.tableType === Compliance.FactorTableTypeEnum.Index));

        this._depreciationFactorTables.length = 0;
        this._depreciationFactorTables.push.apply(this._depreciationFactorTables, this._formService.factorTables.filter((t) => t.tableType === Compliance.FactorTableTypeEnum.Depreciation));
    }

    private async _setAssessorMappings(): Promise<void> {
        const flattenedData: FormClassificationMappingTreeItemModel[] = [];

        this._formService.assetClassifications
            .forEach(item => {
                this._generateAndPushTreeItem(flattenedData, item, 1, null, this._formService.assetClassificationMappings);
            });

        this._flattenedData = flattenedData;

        this._formService.assetClassifications.forEach(ac => {
            this._populateOverrides(ac);
        });
    }

    // resetAssessorMappings resets the underlying data - expand/collapse/filter must call with false
    private _setRowData(resetAssessorMappings: boolean = true): void {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        this._setSchedules();
        this._setFactorTables();

        if (resetAssessorMappings) {
            this._setAssessorMappings();
        }

        this._gridApi.setRowData(this._mappingViewData);
        this._gridApi.sizeColumnsToFit();
    }

    private _filterName(filter: string, gridValue: any, filterText: string, rowData?: Compliance.AssetClassificationModel): boolean {
        const currentAssetClassification = this._assetClassificationsNameMap[gridValue.toLowerCase()];
        if (currentAssetClassification && this._childrenAssetIdsToShowDespiteFilter.includes(currentAssetClassification.assetClassificationId)) {
            return true; // shortcut show via user control
        }

        if (currentAssetClassification.name === 'Computer Equipment') {
            debugger;
        }

        const filterPassed = this._filterCheckValue(filter, currentAssetClassification.name, filterText);
        const someChildrenPass = currentAssetClassification.childClassifications.some((ac: Compliance.AssetClassificationModel) => this._filterName(filter, `${ac.name}@${ac.assetClassificationId}`, filterText));

        if (rowData && !filterPassed && someChildrenPass) {
            this._parentRequiredFilterSet.add(rowData.assetClassificationId);
        } else if (rowData && this._parentRequiredFilterSet.has(rowData.assetClassificationId)) {
            this._parentRequiredFilterSet.delete(rowData.assetClassificationId);
        }

        return filterPassed || someChildrenPass;
    }

    // this function is designed to match the 3 filter values of the ag-grid custom text filter, with extra
    // parameters bound at the start. lookupNameFieldRenderer is a function called to inspect the provided lookup table
    private _filterMappingLookup(mappingFieldName: string, lookupFieldName: string, lookupTable: any[], lookupNameFieldRenderer: Function, filter: string, gridValue: any, filterText: string, rowData?: Compliance.AssetClassificationModel): boolean {
        if (!gridValue) { return !filter; }
        const currentAssetClassification = this._assetClassificationsNameMap[gridValue.toLowerCase()];
        if (currentAssetClassification && this._childrenAssetIdsToShowDespiteFilter.includes(currentAssetClassification.assetClassificationId)) {
            return true; // shortcut show via user control
        }

        const mappingForClassification = this._flattenedData.find(mapping => mapping.assetClassificationId === currentAssetClassification.assetClassificationId);

        let lookupName = '';
        if (mappingForClassification && mappingForClassification[mappingFieldName]) {
            const lookup = lookupTable.find(row => row[lookupFieldName] === mappingForClassification[mappingFieldName]);
            if (lookup) {
                lookupName = lookupNameFieldRenderer.call(this, lookup);
            }
        }

        const filterPassed = this._filterCheckValue(filter, lookupName, filterText);
        const someChildrenPass = currentAssetClassification.childClassifications.some((ac: Compliance.AssetClassificationModel) => this._filterMappingLookup(mappingFieldName, lookupFieldName, lookupTable, lookupNameFieldRenderer, filter, `${ac.name}@${ac.assetClassificationId}`, filterText, ac));

        if (rowData && !filterPassed && someChildrenPass) {
            this._parentRequiredFilterSet.add(rowData.assetClassificationId);
        } else if (rowData && this._parentRequiredFilterSet.has(rowData.assetClassificationId)) {
            this._parentRequiredFilterSet.delete(rowData.assetClassificationId);
        }

        return filterPassed || someChildrenPass;
    }

    private _filterCheckValue(method: string, sourceString: string, matchString: string): boolean {
        const sourceCompare = (sourceString || '').toLowerCase();
        const matchCompare = (matchString || '').toLowerCase();

        switch (method) {
            case 'startsWith':
                return sourceCompare.startsWith(matchCompare);
            case 'equals':
                return sourceCompare === matchCompare;
            case 'notEqual':
                return sourceCompare !== matchCompare;
            case 'contains':
                return sourceCompare.includes(matchCompare);
            case 'notContains':
                return !sourceCompare.includes(matchCompare);
            case 'endsWith':
                return sourceCompare.endsWith(matchCompare);
            case AgGridFilterParams.blankFilterOptionDef.displayKey:
                return sourceString.length === 0;
            case AgGridFilterParams.notBlankFilterOptionDef.displayKey:
                return sourceString.length > 0;

        }
        return false;
    }

    private get _mappingViewData() {
        const mappingViewData: FormClassificationMappingTreeItemModel[] = [];

        this._formService.assetClassifications.forEach((topLevel: Compliance.AssetClassificationModel) => {
            const dataNode: FormClassificationMappingTreeItemModel = this._flattenedData.find(row => row.assetClassificationId === topLevel.assetClassificationId);
            mappingViewData.push(dataNode);
            if (this._expandedClassifications.has(topLevel.assetClassificationId) && topLevel.childClassifications && topLevel.childClassifications.length > 0) {
                topLevel.childClassifications.forEach((secondLevel: Compliance.AssetClassificationModel) => {
                    mappingViewData.push(this._flattenedData.find(row => row.assetClassificationId === secondLevel.assetClassificationId));
                    if (this._expandedClassifications.has(secondLevel.assetClassificationId) && secondLevel.childClassifications && secondLevel.childClassifications.length > 0) {
                        secondLevel.childClassifications.forEach((thirdLevel: Compliance.AssetClassificationModel) => {
                            mappingViewData.push(this._flattenedData.find(row => row.assetClassificationId ===
                                thirdLevel.assetClassificationId));
                        });
                    }
                });
            }
        });

        return mappingViewData;
    }

    private _mapClassification(classifications: Compliance.AssetClassificationModel) {
        this._flattenedAssetClassificationsFullList.push(classifications);
        const name = `${classifications.name && classifications.name.toLowerCase()}@${classifications.assetClassificationId}`;
        this._assetClassificationsNameMap[name] = classifications;
        if (classifications.childClassifications && classifications.childClassifications.length > 0) {
            this._collapsedClassifications.add(classifications.assetClassificationId);
        }
        classifications.childClassifications.forEach(ac => {
                this._mapClassification(ac);
            });
    }

    private _generateAndPushTreeItem(
        flattenedData: FormClassificationMappingTreeItemModel[],
        classificationItem: Compliance.AssetClassificationModel,
        level: number,
        parentTreeItem: FormClassificationMappingTreeItemModel,
        assessorMappings: Compliance.FormClassificationMappingModel[]): void {

        const result = {
            assetClassificationId: classificationItem.assetClassificationId,
            assetClassName: classificationItem.name,
            assetClassAllowPerpetual: classificationItem.allowPerpetual,
            level: level,
            parentTreeItem: parentTreeItem,
            ownedFormRevisionScheduleId: null,
            depreciationFactorTableId: null,
            isDepreciationFactorTableLocked: false,
            indexFactorTableId: null,
            formClassificationMappingId: 0,
            isFormRevisionScheduleReportable: true,
            isExempt: false
    } as FormClassificationMappingTreeItemModel;

        const mapping = assessorMappings.find((item => item.assetClassificationId === result.assetClassificationId));

        if (mapping) {
            result.formClassificationMappingId = mapping.formClassificationMappingId;
            result.ownedFormRevisionScheduleId = mapping.ownedFormRevisionScheduleId;
            result.leasedToFormRevisionScheduleId = mapping.leasedToFormRevisionScheduleId;
            result.leasedFromFormRevisionScheduleId = mapping.leasedFromFormRevisionScheduleId;
            result.depreciationFactorTableId = mapping.depreciationFactorTableId;
            result.indexFactorTableId = mapping.indexFactorTableId;
            result.isExempt = mapping.isExempt;

            const scheduleFactorTable = this._getScheduleFactorTable(result);
            result.isDepreciationFactorTableLocked = scheduleFactorTable && scheduleFactorTable.isLocked;
            result.isFormRevisionScheduleReportable = scheduleFactorTable && scheduleFactorTable.isReportable;
        }

        flattenedData.push(result);

        if (classificationItem.childClassifications) {
            classificationItem.childClassifications
                .forEach(item => this._generateAndPushTreeItem(flattenedData, item, level + 1, result, assessorMappings));
        }
    }

    private _getScheduleFactorTable(row: FormClassificationMappingTreeItemModel): ScheduleFactorTableModel {
        const result: ScheduleFactorTableModel = {
            factorTableId: null,
            isLocked: false,
            isReportable: true
        };

        if (row.ownedFormRevisionScheduleId) {
            // get the schedule
            const schedule = this.formRevisionSchedules.find(x => {
                    return x.formRevisionScheduleId === row.ownedFormRevisionScheduleId;
                });

            if (!schedule) {
                return null;
            }

            result.isReportable = schedule.isReportable;

            // get schedule factor table for the assessor
            const scheduleFactorTable = schedule.scheduleFactorTables.find(x => {
                    return x.formRevisionYearAssessorId === this._formService.factorTableAssessorId;
                });

            // get the depreciation factor table
            if (scheduleFactorTable) {
                result.isLocked = scheduleFactorTable.isLocked;
                const depreciationTable = this._depreciationFactorTables.find(x => {
                        return x.formFactorTableId === scheduleFactorTable.formFactorTableId;
                    });
                if (depreciationTable) {
                    result.factorTableId = depreciationTable.factorTableId;
                }
            }
        }

        return result;
    }

    private _populateOverrides(parentAssetClassificationNode: Compliance.AssetClassificationModel) {
        const parentDataRow = this._flattenedData.find((dataRow: FormClassificationMappingTreeItemModel) => {
            return dataRow.assetClassificationId === parentAssetClassificationNode.assetClassificationId;
        });
        if (parentAssetClassificationNode.childClassifications) {
            parentDataRow.childOverridesSchedule = false;
            parentDataRow.childOverridesDepreciation = false;
            parentDataRow.childOverridesIndex = false; // set false before checking children
            parentAssetClassificationNode.childClassifications.forEach((childClassification: Compliance.AssetClassificationModel) => {
                const childDataRow = this._flattenedData.find((dataRow: FormClassificationMappingTreeItemModel) => {
                    return dataRow.assetClassificationId === childClassification.assetClassificationId;
                });
                childDataRow.isScheduleOverridden = this._isPropertyOverridden(parentDataRow.ownedFormRevisionScheduleId, childDataRow.ownedFormRevisionScheduleId);
                childDataRow.isDepreciationOverridden = this._isPropertyOverridden(parentDataRow.depreciationFactorTableId, childDataRow.depreciationFactorTableId);
                childDataRow.isIndexOverridden = this._isPropertyOverridden(parentDataRow.indexFactorTableId, childDataRow.indexFactorTableId);

                this._populateOverrides(childClassification);
                if (childDataRow.isScheduleOverridden) {
                    parentDataRow.childOverridesSchedule = true;
                }
                if (childDataRow.isDepreciationOverridden) {
                    parentDataRow.childOverridesDepreciation = true;
                }
                if (childDataRow.isIndexOverridden) {
                    parentDataRow.childOverridesIndex = true;
                }
            });
        }
    }

    private _isPropertyOverridden(parentValue, itemValue): boolean {
        let result: boolean = false;

        if (parentValue && !itemValue) {
            result = true;
        } else if (!parentValue && itemValue) {
            result = true;
        } else if (parentValue !== itemValue) {
            result = true;
        }

        return result;
    }

    private _expand(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return;
        }

        if (this._filteredOutChildrenMap.has(item.assetClassificationId)) {
            this._showHiddenChildren(params);
        } else {
            this._expandedClassifications.add(item.assetClassificationId);
            this._collapsedClassifications.delete(item.assetClassificationId);
            this._setRowData(false);
        }
    }

    private _collapse(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return;
        }

        this._expandedClassifications.delete(item.assetClassificationId);
        this._collapsedClassifications.add(item.assetClassificationId);
        this._setRowData(false);
    }

    private _showHiddenChildren(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return;
        }

        const childrenToShowDespiteFilter = this._filteredOutChildrenMap.get(item.assetClassificationId);
        this._childrenAssetIdsToShowDespiteFilter = this._childrenAssetIdsToShowDespiteFilter.concat(childrenToShowDespiteFilter.map(ac => ac.assetClassificationId ));
        this._filteredOutChildrenMap.delete(item.assetClassificationId); // no longer hidden

        // expand node if collapsed
        if (this._collapsedClassifications.has(item.assetClassificationId)) {
            this._expandedClassifications.add(item.assetClassificationId);
            this._collapsedClassifications.delete(item.assetClassificationId);
        }

        this._setRowData(false);
    }

    private _canExpand(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return false;
        }

        return this._collapsedClassifications.has(item.assetClassificationId);
    }

    private _canCollapse(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return false;
        }

        return this._expandedClassifications.has(item.assetClassificationId);
    }

    private _canShowHiddenChildren(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item || this._collapsedClassifications.has(item.assetClassificationId)) {
            return false;
        }
        return this._filteredOutChildrenMap.has(item.assetClassificationId);
    }

    private _getName(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return '';
        }

        return item.assetClassName;
    }

    private _getTooltip(params: ICellRendererParamsForExpandCellRenderer) {
        const item = params.data as FormClassificationMappingTreeItemModel;
        if (!item) {
            return '';
        }

        return `${item.assetClassName}${item.assetClassAllowPerpetual ? ' (Allowed to be perpetual)' : ''}`;
    }

    private _canExpandAll(params: ICellRendererParamsForExpandCellHeaderParams): boolean {
        return this._canExpandAllRows;
    }

    private _canCollapseAll(params: ICellRendererParamsForExpandCellHeaderParams): boolean {
        return !this._canExpandAllRows;
    }

    private _expandAll(params: ICellRendererParamsForExpandCellHeaderParams): void {
        this._flattenedData.forEach(x => {
                if (x.parentTreeItem && this._collapsedClassifications.has(x.parentTreeItem.assetClassificationId)) {
                    this._expandedClassifications.add(x.parentTreeItem.assetClassificationId);
                    this._collapsedClassifications.delete(x.parentTreeItem.assetClassificationId);
                }
            });

        this._setRowData(false);

        this._canExpandAllRows = false;
    }

    private _collapseAll(params: ICellRendererParamsForExpandCellHeaderParams): void {
        this._flattenedData.forEach(x => {
                if (x.parentTreeItem && this._expandedClassifications.has(x.parentTreeItem.assetClassificationId)) {
                    this._expandedClassifications.delete(x.parentTreeItem.assetClassificationId);
                    this._collapsedClassifications.add(x.parentTreeItem.assetClassificationId);
                }
            });

        this._setRowData(false);

        this._canExpandAllRows = true;
    }

    private _blankFilterScheduleTest = (filterValue: any, cellValue: any) => {
        const currentAssetClassification = this._assetClassificationsNameMap[cellValue.toLowerCase()];
        if (currentAssetClassification && this._childrenAssetIdsToShowDespiteFilter.includes(currentAssetClassification.assetClassificationId)) {
            return true; // short cut show via user control
        }
        const mappingForClassification = this._mappingViewData.find(mapping => mapping.assetClassificationId === currentAssetClassification.assetClassificationId);
        if (!(mappingForClassification && (mappingForClassification.ownedFormRevisionScheduleId))
            || currentAssetClassification.childClassifications.some((ac: Compliance.AssetClassificationModel) => this._blankFilterScheduleTest(filterValue, `${ac.name}@${ac.assetClassificationId}`))) {
            return true;
        } else {
            return false;
        }
    };


    private _notBlankFilterScheduleTest = (filterValue: any, cellValue: any) => {
        const currentAssetClassification = this._assetClassificationsNameMap[cellValue.toLowerCase()];
        if (currentAssetClassification && this._childrenAssetIdsToShowDespiteFilter.includes(currentAssetClassification.assetClassificationId)) {
            return true; // short cut show via user control
        }
        const mappingForClassification = this._mappingViewData.find(mapping => mapping.assetClassificationId === currentAssetClassification.assetClassificationId);
        if ((mappingForClassification && (mappingForClassification.ownedFormRevisionScheduleId))
            || currentAssetClassification.childClassifications.some((ac: Compliance.AssetClassificationModel) => this._notBlankFilterScheduleTest(filterValue, `${ac.name}@${ac.assetClassificationId}`))) {
                return true;
        } else {
            return false;
        }
    };
}
