import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppealApplicationService } from '../../appeal.application.service';
import { Subscription } from 'rxjs';
import { AppealFormItem } from '../../appeal.application.model';

export interface EditAppealAssociationsComponentParams {
    selectedAppeal: AppealFormItem;
    supplementalForms: Compliance.AppealSupplementalFormAssociation[];
    formRevisionId: number;
    parentAppeals: AppealFormItem[];
}

export interface AvailableSupplementalFormEditModel extends Compliance.AppealSupplementalFormAssociation {
    isAssociatedInitial: boolean;
    hasChanges: boolean;
    totalAppeals: number;
    canAddToAppealTotal: number;
    canRemoveFromAppealTotal: number;
    isSelected: boolean;
}

@Component({
    selector: 'edit-appeal-supplement-associations',
    templateUrl: './editAppealSupplementAssociations.component.html'
})
export class EditAppealSupplementAssociationsComponent implements IWeissmanModalComponent<EditAppealAssociationsComponentParams, boolean>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _appealApplicationService: AppealApplicationService
    ) {
        this.subscriptions = new Subscription();
    }

    params: EditAppealAssociationsComponentParams;
    result: boolean;

    isBusy: boolean;
    busyMessage: string;
    applyChangesScopeOptions: Compliance.NameValuePair<string>[] = [];
    selectedApplyChangesScopeOption: Compliance.NameValuePair<string>;
    formRevisionAssociations: AvailableSupplementalFormEditModel[] = [];
    hasChanges: boolean;

    private readonly subscriptions: Subscription;
    private readonly _APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_APPEALS: Compliance.NameValuePair<string> = {
        name: 'all-appeals',
        value: 'All appeals'
    };

    private _APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL: Compliance.NameValuePair<string> = {
        name: 'selected-appeal',
        value: null
    };

    async ngOnInit(): Promise<void> {
        this.applyChangesScopeOptions.push(this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_APPEALS);

        if (this.params.selectedAppeal) {
            this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL.value = this.params.selectedAppeal.parcelAcctNum;
            this.applyChangesScopeOptions.push(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL);
        }

        this.selectedApplyChangesScopeOption = this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL) === -1 ?
            this.applyChangesScopeOptions[this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_APPEALS)] :
            this.applyChangesScopeOptions[this.applyChangesScopeOptions.indexOf(this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL)];

        this._refreshAppealFormRevisions();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    onSelectedApplyChangesScopeFilterChange(): void {
        this._refreshAppealFormRevisions();
    }

    onFormSelectedChange(form: AvailableSupplementalFormEditModel): void {
        form.isAssociated = !form.isAssociated;
        form.hasChanges = this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL
            ? form.isAssociatedInitial !== form.isAssociated
            : (form.isAssociated && form.canAddToAppealTotal !== 0) || !form.isAssociated;

        this.hasChanges = !!this.formRevisionAssociations.find(x => x.hasChanges);
    }

    async save(): Promise<void> {
        this.busyMessage = 'Updating Form Associations';
        this.isBusy = true;

        try {
            const appealIds = this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_ALL_SELECTED_APPEALS
                ? this.params.parentAppeals.map(x => x.appealId)
                : [this.params.selectedAppeal.appealId]

            const updates: Compliance.AppealSupplementalFormsAssociationChangeRequest = {
                appealIds,
                changes: this.formRevisionAssociations.filter(x => x.hasChanges)
                    .map(x => (
                        {
                            formRevisionId: x.supplementalFormRevisionId,
                            parentFormRevisionId: x.parentFormRevisionId,
                            associate: x.isAssociated
                        }))
            };
            await this._appealApplicationService.changeSupplementalFormAssociations(updates);
            this.result = true;
        } finally {
            this.isBusy = false;
            this.cancel();
        }
    }

    getMessage(form: AvailableSupplementalFormEditModel): string {
        let message: string;

        if (this.selectedApplyChangesScopeOption === this._APPLY_CHANGES_SCOPE_OPTION_SELECTED_APPEAL) {
            if (form.isAssociated) {
                message = form.hasChanges
                    ? `Adding to ${this.selectedApplyChangesScopeOption.value}`
                    : `No changes.  (added to ${this.selectedApplyChangesScopeOption.value})`;
            } else {
                message = form.hasChanges
                    ? `Removing from ${this.selectedApplyChangesScopeOption.value}`
                    : `No changes.  (not added to ${this.selectedApplyChangesScopeOption.value})`;
            }
        } else {
            if (form.isAssociated) {
                message = form.hasChanges
                    ? `Adding to ${form.canAddToAppealTotal} of ${form.totalAppeals} available appeals`
                    : `No changes. (added to ${form.totalAppeals - form.canAddToAppealTotal} of ${form.totalAppeals} available appeals)`;
            } else {
                message = form.hasChanges
                    ? `Removing from  ${form.canRemoveFromAppealTotal} of ${form.totalAppeals} available appeals`
                    : `No changes.  (added to ${form.totalAppeals - form.canRemoveFromAppealTotal} of ${form.totalAppeals} available appeals)`;
            }
        }

        return message;
    }

    private async _refreshAppealFormRevisions(): Promise<void> {
        this.busyMessage = 'Loading';
        this.isBusy = true;
        try {
            let allPossibleSupplementAssociations = this.params.supplementalForms;
            let possibleAssociationsForSelectedAppeal = allPossibleSupplementAssociations.filter(x => x.appealId === this.params.selectedAppeal.appealId);

            this.formRevisionAssociations = [];

            const totalAppeals = this.params.parentAppeals.length;

            possibleAssociationsForSelectedAppeal.forEach(possibleAssociation => {
                let canAddToAppealTotal = allPossibleSupplementAssociations.filter(x => possibleAssociation.supplementalFormRevisionId === x.supplementalFormRevisionId
                    && !x.isAssociated).length;
                let canRemoveFromAppealTotal = allPossibleSupplementAssociations.filter(x => possibleAssociation.supplementalFormRevisionId === x.supplementalFormRevisionId
                    && x.isAssociated).length;
                const supplemental = {
                    ...possibleAssociation,
                    isAssociatedInitial: possibleAssociation.isAssociated,
                    hasChanges: false,
                    totalAppeals,
                    canAddToAppealTotal: canAddToAppealTotal,
                    canRemoveFromAppealTotal: canRemoveFromAppealTotal
                } as AvailableSupplementalFormEditModel;

                this.formRevisionAssociations.push(supplemental)
            })


            this.hasChanges = !!this.formRevisionAssociations.find(x => x.hasChanges);
        } finally {
            this.isBusy = false;
        }

    }
}
