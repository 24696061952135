import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AgGridModule } from 'ag-grid-angular'
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component'
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { FactorTableListComponent } from './Factor-Table-List/factorTableList.component';
import { FactorTableDetailsComponent } from './Factor-Table-Details/factorTableDetails.component';
import { FactorTableListGridActionCellRendererComponent } from './Factor-Table-List/agGridActionCellRenderer.component';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { FactorTableExportAttachmentsComponent } from "./Factor-Table-Export-Attachments/factorTableExportAttachments.component";
import { CommentsModule } from '../../Comments/comments.module';
import { WeissmanFactorTableTypeNamePipe } from "./Factor-Table-Details/factorTableTypeName-pipe";

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TypeaheadModule,
        PopoverModule,
        CurrencyMaskModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BsDropdownModule,
        CommentsModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            FactorTableListGridActionCellRendererComponent
        ])
    ],
    declarations: [
        FactorTableListComponent,
        FactorTableListGridActionCellRendererComponent,
        FactorTableDetailsComponent,
        FactorTableExportAttachmentsComponent,
        WeissmanFactorTableTypeNamePipe
    ],
    providers: [],
    exports: []
})
export class FactorTableModule {
    static setupModule(): void {
        let hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('factorTableList', FactorTableListComponent);
    }
}
