import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../AgGrid';
import { StateJurisdictionCommandCenterService } from './stateJurisdiction.service';

export class StateJurisdictionCommandCenterAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _stateJurisdictionService: StateJurisdictionCommandCenterService) {
         super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.AssessorCommandCenterModelPropertyEnum>[] = [
        { name: 'assessorName', value: Compliance.AssessorCommandCenterModelPropertyEnum.AssessorName },
        { name: 'assessorAbbr', value: Compliance.AssessorCommandCenterModelPropertyEnum.AssessorAbbr },
        { name: 'stateAbbr', value: Compliance.AssessorCommandCenterModelPropertyEnum.StateAbbr },
        { name: 'ppReturnForms', value: Compliance.AssessorCommandCenterModelPropertyEnum.PPReturnForms },
        { name: 'ppReturnAddress', value: Compliance.AssessorCommandCenterModelPropertyEnum.PPReturnAddress },
        { name: 'ppReturnAddressVerification', value: Compliance.AssessorCommandCenterModelPropertyEnum.PPReturnAddressVerification },
        { name: 'appealFilingAddress', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealFilingAddress },
        { name: 'appealFilingAddressVerification', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealFilingAddressVerification },
        { name: 'generalAddress', value: Compliance.AssessorCommandCenterModelPropertyEnum.GeneralAddress },
        { name: 'generalAddressVerification', value: Compliance.AssessorCommandCenterModelPropertyEnum.GeneralAddressVerification },
        { name: 'compliancePPDeadline', value: Compliance.AssessorCommandCenterModelPropertyEnum.CompliancePPDeadline },
        { name: 'appealPPDeadline', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealPPDeadline },
        { name: 'appealREDeadline', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealREDeadline },
        { name: 'appealCADeadline', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealCADeadline },
        { name: 'appealMNDeadline', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealMNDeadline },
        { name: 'isPendingQC', value: Compliance.AssessorCommandCenterModelPropertyEnum.IsPendingQC },
        { name: 'minVerifiedDate', value: Compliance.AssessorCommandCenterModelPropertyEnum.MinVerifiedDate },
        { name: 'appealForms', value: Compliance.AssessorCommandCenterModelPropertyEnum.AppealForms },
        { name: 'availableCollectors', value: Compliance.AssessorCommandCenterModelPropertyEnum.AvailableCollectors },
        { name: 'defaultCollectors', value: Compliance.AssessorCommandCenterModelPropertyEnum.DefaultCollectors },
        { name: 'defaultAddressCorrespondenceTypeName', value: Compliance.AssessorCommandCenterModelPropertyEnum.DefaultAddressCorrespondenceTypeName },
        { name: 'defaultAddress', value: Compliance.AssessorCommandCenterModelPropertyEnum.DefaultAddress },
        { name: 'assessorWebsite', value: Compliance.AssessorCommandCenterModelPropertyEnum.AssessorWebsite },
        { name: 'assessorEmail', value: Compliance.AssessorCommandCenterModelPropertyEnum.AssessorEmail },
        { name: 'stateName', value: Compliance.AssessorCommandCenterModelPropertyEnum.StateName },
    ];
    private readonly _filterSortMap: Map<number, Compliance.AssessorCommandCenterModelPropertyEnum> = new Map([
        [Core.DescriptorFieldTypes.Number, Compliance.AssessorCommandCenterModelPropertyEnum.NumberDescriptor],
        [Core.DescriptorFieldTypes.Currency, Compliance.AssessorCommandCenterModelPropertyEnum.NumberDescriptor],
        [Core.DescriptorFieldTypes.Date, Compliance.AssessorCommandCenterModelPropertyEnum.DateDescriptor],
        [Core.DescriptorFieldTypes.YesNo, Compliance.AssessorCommandCenterModelPropertyEnum.BooleanDescriptor],
        [Core.DescriptorFieldTypes.Picklist, Compliance.AssessorCommandCenterModelPropertyEnum.StringDescriptor],
        [Core.DescriptorFieldTypes.Text, Compliance.AssessorCommandCenterModelPropertyEnum.StringDescriptor]
    ]);

    protected canGetRows(): boolean {
        return this._stateJurisdictionService.canGetRows;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const model = this._stateJurisdictionService.currentSearchModel;

        const searchParams: Compliance.AssessorCommandCenterSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: model && model.stateId,
            taxYear: model && model.taxYear
        };

        const result = await this._stateJurisdictionService.getAssessors(searchParams);
        if (!result) {
            return null;
        }

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    protected getSortColumns<T>(propertyMap: Compliance.NameValuePair<T>[]) {
        const columns: Core.SortModel<any>[] = [];

        const sortModel = this.gridApi.getSortModel();

        if (sortModel) {
            sortModel.forEach(x => {
                const property = this.getProperty(x.colId, propertyMap);
                if (property !== null) {
                    columns.push({
                        sortProperty: property,
                        sortDirection: this.getSortDirection(x.sort)
                    });
                } else {
                    const descriptor = this._stateJurisdictionService.descriptorInfo.descriptors.find(d => `d.${d.descriptorId}` === x.colId);
                    if (descriptor) {
                        columns.push({
                            sortProperty: this._filterSortMap.get(descriptor.fieldType),
                            sortDirection: this.getSortDirection(x.sort),
                            sortParameter: x.colId.split('.')[1]
                        });
                    }

                    const verification = this._stateJurisdictionService.descriptorInfo.descriptors.find(d => `v.${d.descriptorId}` === x.colId);
                    if (verification) {
                        columns.push({
                            sortProperty: Compliance.AssessorCommandCenterModelPropertyEnum.DescriptorVerification,
                            sortDirection: this.getSortDirection(x.sort),
                            sortParameter: x.colId.split('.')[1]
                        });
                    }
                }
            });
        }

        return columns;
    }

    getSearchParamsWithoutPagination() : Compliance.AssessorCommandCenterSearchModel{
        const model = this._stateJurisdictionService.currentSearchModel;

        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: model && model.stateId,
            taxYear: model && model.taxYear
        };
    }

    protected getColumnFilters<T>(propertyMap: Compliance.NameValuePair<T>[]) {
        const columns: Core.FilterModel<any>[] = [];
        const filterModel = this.gridApi.getFilterModel();

        if (filterModel) {
            for (let prop in filterModel) {
                if (filterModel.hasOwnProperty(prop)) {
                    const property = this.getProperty(prop, propertyMap);
                    if (property !== null) {
                        columns.push({
                            filterProperty: property,
                            filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                            filterValues: filterModel[prop] && filterModel[prop].filterValues.map(x => {
                                return {
                                    filterType: this.getFilterType(x.filterType.displayKey),
                                    filterValue: this.getFilterValue(x),
                                    filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                }
                            })
                        });
                    } else {
                        const descriptor = this._stateJurisdictionService.descriptorInfo.descriptors.find(d => `d.${d.descriptorId}` === prop);
                        if (descriptor) {
                            columns.push({
                                filterProperty: this._filterSortMap.get(descriptor.fieldType),
                                filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                                filterValues: filterModel[prop] && filterModel[prop].filterValues.map(x => {
                                    return {
                                        filterParameter: prop.split('.')[1],
                                        filterType: this.getFilterType(x.filterType.displayKey),
                                        filterValue: this.getFilterValue(x),
                                        filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                    }
                                })
                            });
                        }

                        const verification = this._stateJurisdictionService.descriptorInfo.descriptors.find(d => `v.${d.descriptorId}` === prop);
                        if (verification) {
                            columns.push({
                                filterProperty: Compliance.AssessorCommandCenterModelPropertyEnum.DescriptorVerification,
                                filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                                filterValues: filterModel[prop] && filterModel[prop].filterValues.map(x => {
                                    return {
                                        filterParameter: prop.split('.')[1],
                                        filterType: this.getFilterType(x.filterType.displayKey),
                                        filterValue: this.getFilterValue(x),
                                        filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                    }
                                })
                            });
                        }
                    }
                }
            }
        }

        // handle verification dropdown
        const verification = this._stateJurisdictionService.currentFilterModel;
        if (verification) {
            if (!columns.find(x => x.filterValues.find(y => y.filterParameter === verification.field) ? true: false)) {
                columns.push({
                    filterProperty: verification.property,
                    filterConditionType: Core.FilterConditionTypeEnum.None,
                    filterValues: [
                        {
                            filterParameter: verification.field,
                            filterType: Core.FilterTypeEnum.Equals,
                            filterValue: verification.value,
                            filterToValue: null
                        }
                    ]
                });
            }
        }

        return columns;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const model = this._stateJurisdictionService.currentSearchModel;

        const searchParams: Compliance.AssessorCommandCenterSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            stateId: model && model.stateId,
            taxYear: model && model.taxYear
        };

        return await this._stateJurisdictionService.getRowIds(searchParams);
    }
}
