import { Injectable } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceModalComponent } from './create-invoice-modal.component';

@Injectable(
    { providedIn: 'root' }
)
export class CreateInvoiceModalLaunchService {
    customTemplateModalRef: BsModalRef;

    constructor(private modalService: BsModalService) { }

    openCreateInvoiceModal(onCreate: Function, companyId: number, siteId: number) {
        const initialState = {
            companyId: companyId,
            siteId: siteId,
            onCreate: onCreate
        };

        this.modalService.show(InvoiceModalComponent, { initialState, class: 'modal-create-invoice' });
    }
}
