import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AceSimpleTableCellComponent } from './Simple-Table-Cell/simpleTableCell.component';
import { AceSimpleTableComponent } from './simpleTable.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AceSimpleTableCellComponent,
        AceSimpleTableComponent
    ],
    exports: [
        AceSimpleTableComponent
    ]
})
export class AceSimpleTableModule {

}
