import { Component, Input } from '@angular/core';

export class StepProgressIndicatorStep {
    name: string;
    title: () => string;
    isError: () => boolean;
    isCurrent: () => boolean;
    isComplete: () => boolean;
    isWorking: () => boolean;
    click?: () => void;
}

@Component({
    selector: 'step-progress-indicator',
    templateUrl: './stepProgressIndicator.component.html',
    styleUrls: ['./stepProgressIndicator.component.scss']
})
export class StepProgressIndicatorComponent {
    @Input() steps: StepProgressIndicatorStep[];
    @Input() clickable: boolean = true;
    @Input() showStepName: boolean = true;

    click(step: StepProgressIndicatorStep): void {
        if (!this.clickable) {
            return;
        }

        if (!step.isCurrent()) {
            return;
        }

        if (!step.click) {
            return;
        }

        step.click();
    }

    getClass(step: StepProgressIndicatorStep): string {
        let classString = 'step';

        if (step.isError()) {
            classString += ' error';
        }

        if (step.isCurrent()) {
            classString += ' current';

            if (this.clickable && step.click) {
                classString += ' clickable';
            }
        }

        if (step.isWorking()) {
            classString += ' working';
        }

        if (step.isComplete()) {
            classString += ' complete';
        }

        return classString;
    }
}
