import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { animate, trigger, transition, style } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { IncomeStatementCategoryRepository } from '../../../Compliance/Repositories/incomeStatementCategory.repository';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';

export interface IncomeStatementCategoryPickerParams {
    incomeStatementCategoryId: number;
    incomeStatementCategories: Core.IncomeStatementCategoryModel[];
    glAccountTypeId: Compliance.GLAccountTypeEnum;
}

@Component({
    selector: 'income-statement-category-picker-modal',
    templateUrl: './incomeStatementCategoryPicker.component.html',
    animations: [
        trigger('pickerEnter', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-2%)' }),
                animate(125, style({ opacity: 1, transform: 'translateX(0)' }))
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0, transform: 'translateX(-2%)' }))
            ])
        ])
    ]
})
export class IncomeStatementCategoryPickerComponent implements OnInit, IWeissmanModalComponent<IncomeStatementCategoryPickerParams, Core.IncomeStatementCategoryModel> {
    constructor(
        private _modalRef: BsModalRef,
        private _incomeStatementCategoryRepository: IncomeStatementCategoryRepository,
        private _elem: ElementRef
    ) { }

    @ViewChild('.active-category-picker') selectedIncomeStatementCategory: ElementRef;

    params: IncomeStatementCategoryPickerParams;
    result: Core.IncomeStatementCategoryModel;
    incomeStatementCategories: Core.IncomeStatementCategoryModel[];

    private _expandedIncomeStatementCategories: Set<number> = new Set<number>();

    async ngOnInit(): Promise<void> {
        const result = this.params.incomeStatementCategories
            || await lastValueFrom(this._incomeStatementCategoryRepository.getIncomeStatementCategories());

        this.incomeStatementCategories = result.filter(x => this._matchesAccountType(x));
        if (this.params.incomeStatementCategoryId) {
            this.incomeStatementCategories.forEach(topLevel => {
                if (topLevel.childIncomeStatementCategories) {
                    topLevel.childIncomeStatementCategories.forEach(secondLevel => {
                        if (secondLevel.incomeStatementCategoryId === this.params.incomeStatementCategoryId) {
                            this._expandedIncomeStatementCategories.add(topLevel.incomeStatementCategoryId);
                        } else if (secondLevel.childIncomeStatementCategories &&
                            secondLevel.childIncomeStatementCategories.find(ac => ac.incomeStatementCategoryId === this.params.incomeStatementCategoryId)) {
                            this._expandedIncomeStatementCategories.add(topLevel.incomeStatementCategoryId);
                            this._expandedIncomeStatementCategories.add(secondLevel.incomeStatementCategoryId);
                        }
                    });
                }
            });
        }

        setTimeout(() => {
            const elem = this._elem.nativeElement.querySelector('.active-category-link');
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }

    selectIncomeStatementCategory(incomeStatementCategory: Core.IncomeStatementCategoryModel): void {
        this.result = incomeStatementCategory;
        this._modalRef.hide();
    }

    isSelected(incomeStatementCategory: Core.IncomeStatementCategoryModel): boolean {
        return incomeStatementCategory.incomeStatementCategoryId === this.params.incomeStatementCategoryId;
    }

    cancel(): void {
        this._modalRef.hide();
    }

    isExpanded(category: Core.IncomeStatementCategoryModel): boolean {
        return this._expandedIncomeStatementCategories.has(category.incomeStatementCategoryId);
    }

    expandCategory(category: Core.IncomeStatementCategoryModel): void {
        this._expandedIncomeStatementCategories.add(category.incomeStatementCategoryId);
    }

    collapseCategory(category: Core.IncomeStatementCategoryModel): void {
        this._expandedIncomeStatementCategories.delete(category.incomeStatementCategoryId);
    }

    private _matchesAccountType(category: Core.IncomeStatementCategoryModel): boolean {
        switch(this.params.glAccountTypeId) {
            case Compliance.GLAccountTypeEnum.Expense:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Expense;
            case Compliance.GLAccountTypeEnum.Revenue:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Income;
            case Compliance.GLAccountTypeEnum.Reporting:
                return category.incomeStatementCategoryTypeId == Core.IncomeStatementCategoryTypeEnum.Reporting;
            default:
                return true;
        }
    }
}
