(function () {
	'use strict';

	var APP_SOURCE = 'sd.Permission.Company.Add.Controller';

	/**
	 * @ngdoc function
	 * @name weissmanApp.Permission.Company.Add.Controller
	 * @description
	 * # Permission.Company.Add.Controller
	 * Controller of the weissmanApp
	 */
	angular.module('weissmanApp')
		.controller(APP_SOURCE, Controller);

	Controller.$inject = [
		'$uibModalInstance',
		'sd.Company.Permission.Service',
		'modifiedEntityIds',
		'userId',
		'groupId',
		'sdModalService',
		'sd.Company.Service'
	];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit($uibModalInstance, companyPermissionService, modifiedEntityIds, userId, groupId, modalService, companyService) {
		var PAGE_SIZE = 100;

		var OPT_HIDE = 'Hide';
		var OPT_VIEW_ALL = 'View All Sites';
		var OPT_VIEW_EDIT_SOME = 'View/Edit Some Sites';
		var OPT_EDIT_ALL = 'Edit All Sites';

		var pageNumber = 1;

		var vm = this;

		vm.PermissionTypes = [
			{
				id: 2,
				Name: OPT_VIEW_ALL
			},
			{
				id: 3,
				Name: OPT_VIEW_EDIT_SOME
			},
			{
				id: 4,
				Name: OPT_EDIT_ALL
			}
		];
		vm.selectedPermissionType = vm.PermissionTypes[0];

		vm.isBusy = false;
		vm.hasLoadedAllCompanies = false;

		vm.companyNameFilter = null;
		vm.companyNameFilterChanged = companyNameFilterChanged;

		vm.selectedCompanies = [];
		vm.companies = [];
	
		vm.toggleCompanySelection = toggleCompanySelection;
		vm.loadCompanies = loadCompanies;
		vm.cancel = cancel;
		vm.save = save;

		function activate() {
			vm.isNewUserBeingCreated = !groupId && !userId;
			
			loadCompanies();
		}

		function loadCompanies() {
			var getPermissionsPromise;
			
			if (groupId) {
				getPermissionsPromise = companyPermissionService.getCompaniesThatAreMissingPermissionsForGroup(groupId, pageNumber, PAGE_SIZE, vm.companyNameFilter, modifiedEntityIds);
			} else if (userId) {
				getPermissionsPromise = companyPermissionService.getCompaniesThatAreMissingPermissionsForUser(userId, pageNumber, PAGE_SIZE, vm.companyNameFilter, modifiedEntityIds);
			} else if (vm.companyNameFilter) {  // no group or user id means new user is being created.  Only search if there's a search term
				getPermissionsPromise = companyService.search(vm.companyNameFilter, PAGE_SIZE, pageNumber, false, false, false);
			} else {
				return;
			}
			
			pageNumber += 1;

			vm.isBusy = true;

			// for the specified group or user, load all companies that have no permissions associated with them
			// exclude any of the modifiedEntityIds (this comes into play for newly added permissions that have not been saved yet; we don't wanna show those in the list)
			getPermissionsPromise.then(function(data) {
				if (data) {
					// update selected property for any company that has been already selected
					var newCompaniesPage = data.map(function(i) {
						// || is for different result model returned from companyService.search
						return {
							name: i.entityName || i.companyName,
							id: i.entityID || i.companyID,
							selected: _.some(vm.selectedCompanies, function(company) {
								return company.id === (i.entityID || i.companyID)
							})
						};
					});

					vm.companies = vm.companies.concat(newCompaniesPage);
				}

				// if the count of all companies is less than that we've asked the service to load, that means we're on the last page
				if (vm.companies.length < (pageNumber * PAGE_SIZE)) {
					vm.hasLoadedAllCompanies = true;
				}

				vm.isBusy = false;
			});
		}

		function save() {
			$uibModalInstance.close({
				companies: vm.selectedCompanies,
				Permission: vm.selectedPermissionType
			});
		}

		function cancel(form) {
			modalService.cancel(form.$dirty, $uibModalInstance.dismiss);
		}

		function companyNameFilterChanged() {
			// reset pagination data, load companies for new company name filter
			pageNumber = 1;
			vm.companies = [];
			vm.hasLoadedAllCompanies = false;
			loadCompanies();
		}

		function toggleCompanySelection(company) {
			company.selected = !company.selected;

			if (company.selected) {
				vm.selectedCompanies.push({
					id: company.id,
					name: company.name
				});
			} else {
				_.remove(vm.selectedCompanies, function(i) {
					return i.id === company.id;
				});
			}
		}

		activate();
	}
})();
