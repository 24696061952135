import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateConvertInterceptor } from './dateConvertInterceptor';
import { Angular1CallbackInterceptor } from './angular1CallbackInterceptor';
import { AuthInterceptor } from './authInterceptor';
import { CacheBusterInterceptor } from './cacheBusterInterceptor';
import { InstanceHeaderInterceptor } from './instanceHeaderInterceptor';

/** Http interceptor providers in outside-in order. */
export const httpInterceptorProviders = [
    // Go tell AngularJS an event happened on every HTTP call; let's keep this interceptor first so that it fires last.
    { provide: HTTP_INTERCEPTORS, useClass: Angular1CallbackInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InstanceHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: CacheBusterInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: DateConvertInterceptor, multi: true }
];
