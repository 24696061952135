<div class="card panel-flat-color primary-panel">
    <div class="panel-flat-header" style="display: flex; justify-content: space-between">
        <h3>Details</h3>
        <div class="header-button-container">
            <button class="flat-button icon-button primary-button"
                *ngIf="!editMode && hasWritePermission"
                (click)="edit()">
                    <i class="fa fa-pencil"></i>
            </button>
            <span *ngIf="editMode">
                <button class="flat-button icon-button success-button" (click)="save()">
                    <i class="fa fa-save"></i>
                </button>
                <button class="flat-button icon-button warning-button" (click)="cancel();">
                    <i class="fa fa-close"></i>
                </button>
            </span>
        </div>
    </div>
    <div class="panel-flat-body">
        <table class="table table-condensed assessor-info" style="margin-bottom: 0;">
            <tbody>
                <tr>
                    <td class="p-1">Abbr:</td>
                    <td class="p-1">
                        <div *ngIf="!editMode">{{assessor.abbr}} {{assessor.municipalityName}}</div>
                        <div *ngIf="editMode" style="display: flex; gap: 10px;">
                            <input type="text" class="form-control" [(ngModel)]="assessor.abbr" maxlength="100">
                            <select class="form-select form-select-sm" [(ngModel)]="assessor.municipalityID" (change)="updateMunicipality()">
                                <option *ngFor="let item of municipalities" [ngValue]="item.municipalityID">{{item.name}}</option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="p-1">Full Name:</td>
                    <td class="p-1">
                        <div *ngIf="!editMode">{{assessor.name}}</div>
                        <input *ngIf="editMode" type="text" class="form-control" [(ngModel)]="assessor.name" maxlength="50">
                    </td>
                </tr>
                <tr>
                    <td class="p-1">Website:</td>
                    <td class="p-1">
                        <a *ngIf="!editMode" [href]="assessor.website | wsAbsoluteUrl" target="_blank">{{assessor.website}}</a>
                        <input *ngIf="editMode" type="text" class="form-control" [(ngModel)]="assessor.website" maxlength="100">
                    </td>
                </tr>
                <tr>
                    <td class="p-1">Default Parcel # Format:</td>
                    <td class="p-1">
                        <div *ngIf="!editMode">{{assessor.defaultParcelNumberFormat}}</div>
                        <input *ngIf="editMode" type="text" class="form-control" [(ngModel)]="assessor.defaultParcelNumberFormat" maxlength="35">
                    </td>
                </tr>
                <tr>
                    <td class="p-1">PTX ID:</td>
                    <td class="p-1">
                        <div *ngIf="!editMode">{{assessor.ptxId}}</div>
                        <input *ngIf="editMode" type="text" class="form-control" [(ngModel)]="assessor.ptxId" maxlength="50">
                    </td>
                </tr>
                <tr>
                    <td class="p-1 ws-vertical-align-top">Default Address:</td>
                    <td class="p-1">
                        <address-view-edit [address]="defaultAddress" [isEditMode]="false" [removeBottomMargin]="true">
                        </address-view-edit>
                    </td>
                </tr>
                <tr>
                    <td class="p-1">Addresses</td>
                    <td class="p-1">
                        <a class="btn btn-link pointer p-0" (click)="launchAddressDetailsModal()">Addresses ({{ nonDeletedEntityAddresses.length }})</a>
                    </td>
                </tr>
                <tr *ngIf="allowTaxingDistricts">
                    <td class="p-1">Taxing Districts:</td>
                    <td class="p-1">
                        <taxing-district [assessor]="assessor" [editMode]="editMode"></taxing-district>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="display: flex; justify-content: end; gap: 10px;">
            <entity-attachments buttonClass="flat-button icon-button-large primary-button"
                [isNestedModal]="false"
                [(hasAttachments)]="assessor.hasAttachments"
                [modalData]="attachmentsModel">
            </entity-attachments>
            <entity-comments [(hasComments)]="assessor.hasComments"
                [isNestedModal]="false"
                buttonClass="flat-button icon-button-large primary-button"
                [modalData]="commentsModel">
            </entity-comments>
        </div>
    </div>
</div>
