import { Injectable, OnDestroy } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

export interface HotkeyParams {
    element?: any;
    keys?: string;
};

export interface Hotkey {
    element: any;
    keys: string;
    listen: Observable<KeyboardEvent>;
    destroy: () => void;
}

/**
 * HotKeys Service
 *
 * Intended to be an instance service provided on a component level to
 * Kills event listeners on component destruction
 * This means you do not need to worry about unsubscribing from key events created in this service
 */
@Injectable()
export class HotkeyService implements OnDestroy {
    constructor(private readonly _eventManager: EventManager) { }

    private _lib: Hotkey[] = [];
    private _default: HotkeyParams = {
        element: document
    };

    ngOnDestroy(): void {
        this._lib.forEach(hotkey => hotkey.destroy());
    }

    add(options: HotkeyParams): Observable<KeyboardEvent> {
        const merged = { ...this._default, ...options };
        const event = `keydown.${merged.keys}`;

        const subject = new Subject<KeyboardEvent>();

        const handler = (e: KeyboardEvent) => {
            e.preventDefault();
            subject.next(e);
        };

        const dispose = this._eventManager.addEventListener(
            merged.element, event, handler
        );

        const hotkey = {
            element: merged.element,
            keys: merged.keys,
            listen: subject.asObservable(),
            destroy: () => {
                subject.complete();
                dispose();
            }
        }

        this._lib.push(hotkey);

        return hotkey.listen;
    }
}
