<div class="snack"
     [ngClass]=" { 'small': size === snackSize.Small, 'large': size === snackSize.Large, 'd-none': size === snackSize.Hidden }">
    <div class="snack-content"
         [class.spinner]="!details.progress"
         [ngClass]="{ 'complete': details.isComplete && (!details.canDownload || (details.canDownload && !details.isOwner)) && !details.progressFailure, 'active': !details.isComplete, 'error': details.progressFailure }">
        <div class="details large"
             [class.has-actions]="!details.cancelling && !details.downloading && (details.progressFailure || details.canCancel || details.isComplete)"
             [class.disconnected]="signalRDisconnected">
            <div class="icon-body">
                <div class="icon-container">
                    <span class="help-spinner" *ngIf="!details.isComplete || (details.isComplete && details.downloading)">
                        <div class="la-ball-spin-clockwise la-oval">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </span>
                    <span *ngIf="details.isComplete && (!details.canDownload || (details.canDownload && details.isOwner && !details.downloading)) && !details.progressFailure"
                          class="snack-progress complete">
                        <i class="fa fa-check-circle-o"></i>
                    </span>
                    <span *ngIf="details.progressFailure"
                          class="snack-progress failed">
                        <i class="fa fa-times-circle-o"></i>
                    </span>
                    <span *ngIf="details.isComplete && details.canDownload && !details.progressFailure && !details.isOwner && !details.downloading"
                          class="snack-progress download"
                          (click)="details.performDownload()">
                        <i class="fa fa-download"></i>
                    </span>
                </div>
                <div class="action-icons">
                    <span *ngIf="details.isComplete && (!details.canDownload || (details.canDownload && details.isOwner && !details.downloading)) && !details.progressFailure"
                          class="snack-progress complete">
                        <i class="fa fa-check-circle-o"></i>
                    </span>
                    <span *ngIf="showCancel && !details.isComplete"
                          class="snack-progress">
                        <i class="fa fa-trash"></i>
                    </span>
                    <span *ngIf="details.isComplete && details.canDownload && !details.progressFailure && !details.isOwner"
                          class="snack-progress download"
                          (click)="details.performDownload()">
                        <i class="fa fa-download"></i>
                    </span>
                    <span *ngIf="details.progressFailure"
                          class="snack-progress failed">
                        <i class="fa fa-times-circle-o"></i>
                    </span>
                </div>
            </div>

            <div class="snack-body">
                <div class="snack-progress-container" [class.active]="details.progress && loadingWaitComplete && !signalRDisconnected">
                    <div class="snack-progress-bar"
                         [class.failed]="details.progressFailure"
                         [class.complete]="details.isComplete || details.isCancelled"
                         [ngStyle]="{ 'transform': 'skew(-20deg) translateX(' + (-(100 - details.progress)) + '%)' }"></div>
                </div>
                <div class="snack-body-content ws-flex-container-horizontal">
                    <div class="text-container">
                        <span *ngIf="!signalRDisconnected || details.isComplete" class="primary-text">{{details.title}}</span>
                        <span *ngIf="signalRDisconnected && !details.isComplete" class="primary-text">Status not detected</span>
                        <span *ngIf="details.message && (!signalRDisconnected || details.isComplete)" class="secondary-text">{{details.message}}</span>
                    </div>
                    <div class="action-container ws-flex-container-vertical">
                        <span *ngIf="details.isComplete && details.canDownload && !details.progressFailure && !details.isOwner"
                              (click)="details.performDownload()">
                            Download
                        </span>
                        <span *ngIf="showCancel && !details.isComplete"
                              class="cancel"
                              [class.disabled]="cancelDisabled"
                              (click)="details.performCancel()">
                            Cancel
                        </span>
                        <span *ngIf="details.canNavigate && details.isComplete && !details.progressFailure"
                              class="navigate"
                              (click)="details.performNavigate()">
                            Navigate
                        </span>
                        <span *ngIf="(!details.canDownload && details.isComplete) || details.progressFailure || details.isCancelled"
                              (click)="details.performDismiss()">
                            Click to dismiss
                        </span>
                        <span *ngIf="!details.isComplete && signalRDisconnected"
                              (click)="refreshFromLRPServer()">
                            Refresh <i *ngIf="manualUpdateLoading" class="fa fa-spinner fa-pulse lrp-check-spin"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="details small"
             [ngClass]="{ 'complete': details.isComplete && (!details.canDownload || (details.canDownload && details.isOwner && !details.downloading)) && !details.progressFailure, 'active': (!details.progress && !details.isComplete) || (details.isComplete && details.downloading), 'error': details.progressFailure }">
            <span *ngIf="!details.progress && !details.isComplete" class="snack-progress"><i class="fa fa-spinner fa-spin"></i></span>
            <span *ngIf="details.progress && !details.isComplete" class="snack-progress percent">{{details.progress}}%</span>
            <span *ngIf="details.isComplete" class="snack-progress complete">
                <i *ngIf="!details.progressFailure" class="fa fa-check-circle-o"></i>
                <i *ngIf="details.progressFailure" class="fa fa-times-circle-o failed"></i>
            </span>
            <div class="text-container">
                <span class="primary-text">{{details.title}}</span>
                <span *ngIf="details.message" class="secondary-text">{{details.message}}</span>
            </div>
        </div>
    </div>
</div>

