import { Injectable } from '@angular/core';

/**
 * State persistence service between drag item and drop zone
 */
@Injectable()
export class DragAndDropListService {
    readonly ALL_EFFECTS = ['move', 'copy', 'link'];
    readonly MIME_TYPE = 'application/x-dnd';
    readonly EDGE_MIME_TYPE = 'application/json';
    readonly MSIE_MIME_TYPE = 'Text';

    private _isDragging: boolean;
    private _itemType: string;
    private _dropEffect: string;
    private _effectAllowed: string;
    private _callback: Function;

    set isDragging(isDragging: boolean) {
        this._isDragging = isDragging;
    }

    set itemType(itemType: string) {
        this._itemType = itemType;
    }

    set dropEffect(dropEffect: string) {
        this._dropEffect = dropEffect;
    }

    set effectAllowed(effectAllowed: string) {
        this._effectAllowed = effectAllowed;
    }

    set callback(isDragging: Function) {
        this._callback = isDragging;
    }

    get isDragging(): boolean {
        return this._isDragging;
    }

    get itemType(): string {
        return this._itemType;
    }

    get dropEffect(): string {
        return this._dropEffect;
    }

    get effectAllowed(): string {
        return this._effectAllowed;
    }

    get callback(): Function {
        return this._callback;
    }
}
