(function () {
	'use strict';
	
	var PAGE_SIZE = 8;
	var PAGE_NUMBER = 1;

	angular
		.module('weissmanApp')
		.directive('sdCompanyPicker', sdCompanyPicker);

	function sdCompanyPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Entity/Company/Picker/_companyPicker.html',
			scope: {
				companyId: '=?',
				companyName: '=?',
				appendToBody: '=?',
				topLevelOnly: '=?',
				favoritesOnly: '=?',
				activeOnly: '=?'
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	Controller.$inject = [
		'sd.Company.Service'
	];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(companyService) {
		var vm = this;

		vm.company = {
			companyID: vm.companyID || null,
			companyName: vm.companyName || null
		};

		vm.getCompanies = function(searchTerm) {
			return companyService.search(searchTerm, PAGE_SIZE, PAGE_NUMBER, vm.topLevelOnly, vm.favoritesOnly, vm.activeOnly);
		}

		vm.companySelected = function() {
			vm.companyId = vm.company.companyID;
			vm.companyName = vm.company.companyName;
		}

		vm.checkForBlank = function() {
			if(!vm.company) {
				vm.companyId = null;
				vm.companyName = '';
			}
		}
	}
})();
