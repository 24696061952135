import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserSettingsService } from '../Account/userSettings.service';
import { SearchRepository } from '../Core-Repositories/search.repository';
import { TimerService } from '../UI-Lib/Utilities';
import { Settings } from '../User/account.service';
import {
    AdvancedCriteria,
    QuickCriteria,
    QuickCriteriaFilter,
    QuickResultType
} from './Quick/Criteria/quickCriteria.model';
import { QuickFiltersService } from './Quick/Criteria/quickFilters.service';
import { SearchOperatorsService } from './searchOperators.service';
import { SearchFilter, SearchFiltersService } from './searchFilters.service';

@Injectable({ providedIn: 'root' })
export class SearchService {
    constructor(private readonly _timerService: TimerService,
                private readonly _userSettingsService: UserSettingsService,
                private readonly _quickFilters: QuickFiltersService,
                private readonly _searchFilters: SearchFiltersService,
                private readonly _searchOperators: SearchOperatorsService,
                private readonly _searchRepository: SearchRepository) {
        this.resultTypes = this.initializeResultTypes();
    }

    resultTypes: QuickResultType[] = [];
    criteria: QuickCriteria;
    resetCriteriaList: boolean;

    private _criteriaChangedListeners: ((msg: string) => void)[] = [];
    private _resetListeners: ((msg: string) => void)[] = [];
    private _clickedOnResultIndex: number;
    private _savedCriteria: Settings;
    private _criteriaSettings: Settings[];
    private _criteriaToSave: Settings[];
    private _activeAdvancedCriteria: AdvancedCriteria;

    private DEFAULT_CRITERIA_NAME: string = 'Search-Quick-Saved-Criteria';

    resetCriteria(): void {
        this.resultTypes = this.initializeResultTypes();
        this.criteria = this._savedCriteria ? this.getSavedDefaultCriteria() : this.getDefaultCriteria();
    }

    resetCriteriaToDefault(): void {
        this.criteria = this.getDefaultCriteria();
    }

    resetActiveFilterValues(): void {
        this.criteria.activeFilters = this.criteria.activeFilters.map(filter => {
            filter.text = '';

            return filter;
        });
    }

    initializeResultTypes(): QuickResultType[] {
        return [{
            name: 'Companies',
            type: 'company',
            sortBy: 'site.company.companyName',
            id: 1
        }, {
            name: 'Sites',
            type: 'site',
            sortBy: 'site.name',
            id: 5
        }, {
            name: 'Parcels',
            type: 'parcel',
            sortBy: 'acctNum',
            id: 6
        }];
    }

    convertToCompanyField(filter: SearchFilter): SearchFilter {
        const vals = filter.field.split('.');
        filter.field = vals[vals.length - 1];
        return filter;
    }

    setCriteria(incomingCriteria: QuickCriteria, triggerUpdate: boolean): void {
        this.criteria = incomingCriteria;

        if(triggerUpdate) {
            this.criteriaChanged();
        }
    }

    getCriteria(): QuickCriteria {
        return structuredClone(this.criteria);
    }

    getResultTypes(): QuickResultType[] {
        return this.resultTypes;
    }

    setClickedOnResultIndex(index: number): void {
        this._clickedOnResultIndex = index;
    }

    getClickedOnResultIndex(): number {
        return this._clickedOnResultIndex;
    }

    async searchElastic(pageNumber: number, numberToGet: number): Promise<{ results: QuickCriteria }> {
        const resultTypeObj = this.resultTypes.find(x => x.type === this.criteria.resultType);

        const queryParams = {
            resultsAs: resultTypeObj.id,
            activeOnly: this.criteria.activeOnly,
            primaryAcctNumOnly: this.criteria.primaryAcctNumOnly,
            pageNumber: pageNumber,
            pageSize: numberToGet,
            excludeSubsidiarySites: this.criteria.excludeSubsidiarySites
        };

        const query = this.criteria.activeFilters
            .filter(x => x.text)
            .reduce((result, filter) => {
                result[filter.field] = filter.text;
                return result;
            }, {...queryParams});

        const response = await lastValueFrom(this._searchRepository.search(query));
        return {
            results: response
        };
    }

    buildSortObj(entityType: string) {
        switch (entityType) {
            case 'company':
                return {
                    'companyName': true
                };
            case 'site':
                return {
                    'name': true,
                    'city': true,
                    'stateAbbr': true,
                    'company.companyName': true
                };
            case 'parcel':
                return {
                    'acctNum': true,
                    'site.city': true,
                    'site.stateAbbr': true,
                    'site.company.companyName': true
                };
        }
    }

    registerCriteriaChanged(callback: (msg: string) => void): void {
        this._criteriaChangedListeners.push(callback);
    }

    criteriaChanged(): void {
        this._criteriaChangedListeners.forEach(callback => {
            callback('Criteria changed.');
        });
    }

    registerReset(callback: (msg: string) => void): void {
        this._resetListeners.push(callback);
    }

    reset(): void {
        this._resetListeners.forEach(callback => {
            callback('Reset!.');
        });
    }

    clearCriteria(): void {
        this._criteriaChangedListeners.splice(1, this._criteriaChangedListeners.length - 1);
    }

    focusOnResult(index: number): void {
        this.focusOnElement(`.search-scroll a.list-group-item.search-result-${  index}`);
    }

    focusOnFilter(index: number): void {
        this.focusOnElement(`.search-filter .search-filter-${  index}`);
    }

    focusOnElement(elem: string): void {
        this._timerService.setTimeout(() => {
            const element: HTMLElement = document.querySelector(elem);
            if (element) {element.focus();}
        }, 100);
    }

    loadSavedCriteria(): void {
        this._savedCriteria = {
            id: 0,
            name: '',
            value: {},
            groupId: 0,
            groupName: '',
            folderId: 0,
            folderName: '',
        };

        this._criteriaSettings = this._userSettingsService.getSettingsByGroup(11);

        if (this._criteriaSettings.length > 0) {
            this._savedCriteria = structuredClone(this._criteriaSettings[0]);
        } else {
            this._savedCriteria = {
                id: 0,
                name: this.DEFAULT_CRITERIA_NAME,
                value: this.getDefaultCriteria(),
                groupId: 11,
                groupName: this.DEFAULT_CRITERIA_NAME,
                folderId: 20,
                folderName: this.DEFAULT_CRITERIA_NAME,
            };
        }
    }

    getDefaultCriteria(): QuickCriteria {
        return {
            activeFilters: this._quickFilters.getActive(),
            inactiveFilters: this._quickFilters.getInactive(),
            resultType: this.resultTypes[0].type,
            activeOnly: true,
            primaryAcctNumOnly: true
        };
    }

    getSavedDefaultCriteria() {
        // ToDo: Get by name instead of index.
        const defaultCriteria = this._savedCriteria.value;

        return structuredClone(defaultCriteria);
    }

    async saveCriteria(newCriteria: QuickCriteria): Promise<Settings> {
        //const newCriteriaToSave = newCriteria || this.criteria;
        this._criteriaToSave = this._userSettingsService.getSettingsByGroup(11);

        if (this._criteriaToSave.length > 0) {
            this._savedCriteria = structuredClone(this._criteriaToSave[0]);
            this._savedCriteria.value = structuredClone(this.criteria);

        } else {
            this._savedCriteria = {
                id: 0,
                name: this.DEFAULT_CRITERIA_NAME,
                value: this.criteria,
                groupId: 11,
                groupName: this.DEFAULT_CRITERIA_NAME,
                folderId: 20,
                folderName: this.DEFAULT_CRITERIA_NAME,
            };
        }
        if(this._savedCriteria.value.excludeSubsidiarySites !== undefined) {
            delete this._savedCriteria.value.excludeSubsidiarySites;
        }

        return await this._userSettingsService.save(this._savedCriteria);
    }

    getDefaultAdvancedCriteria(): AdvancedCriteria {
        return {
            activeFilters: this._searchFilters.getActive(true),
            filters: this._searchFilters.getAll(true),
            activeOnly: true,
            primaryAcctNumOnly: true
        };
    }

    resetAdvancedCriteria(): void {
        this._activeAdvancedCriteria = this.getDefaultAdvancedCriteria();
    }

    sortByCategory(filter: QuickCriteriaFilter): 1| 2| 3 {
        switch (filter.associatedWith) {
            case 'company':
                return 1;
            case 'site':
                return 2;
            case 'parcel':
                return 3;
        }
    }

    isStateFilter(filter: QuickCriteriaFilter): boolean {
        const vals = filter.field.split('.');
        return vals[vals.length - 1] === 'stateAbbr';
    }

    buildAdvancedQuery(entityType: string): string {
        const filters: SearchFilter[] = entityType === 'company'
            ? this._activeAdvancedCriteria.activeFilters.map(this.convertToCompanyField.bind(this))
            : this._activeAdvancedCriteria.activeFilters;

        const shoulds = filters
            .filter(filter => filter.ors.some(x => x.text))
            .map(filter => {
                return filter.ors
                    .filter(x => x.text)
                    .map(x => {
                        const operator = this._searchOperators.getById(x.operatorId);
                        return operator.should(filter.field, x.text);
                    });
            });

        const query = {
            bool: {
                should: shoulds,
                must_not: []
            }
        };

        if (this._activeAdvancedCriteria.activeOnly) {
            query.bool.must_not = [{
                match: {
                    activityStatusID: '0'
                }
            }];
        }

        return encodeURIComponent(JSON.stringify(query));
    }

    setActiveAdvancedCriteria(incomingCriteria: AdvancedCriteria): void {
        this._activeAdvancedCriteria = incomingCriteria;
    }

    searchAdvanced(startFrom: number, numberToGet: number) {
        const entityType = this.getEntityType();

        const searchTerms = {
            query: this.buildAdvancedQuery(entityType),
            filter: 'ignoring filters for now',
            entityType: entityType, // which 'table' you want to search
            //sortByField: resultTypeObj.sort,           // not in use anymore
            //sortAsc: 'true',                           // not in use anymore
            sortByFields: this.buildSortObj(entityType),
            startFromRecord: startFrom,
            numberOfRecordsToGrab: numberToGet
        };

        return lastValueFrom(this._searchRepository.searchAdvanced(searchTerms))
            .then((response) => {
                return {
                    results: JSON.parse(response.resultJson),
                    totalCount: response.totalNumberOfRecords
                };
            });
    }

    getEntityType(): 'parcel' | 'site' | 'company' {
        const filtersWithInput = this._activeAdvancedCriteria.activeFilters.filter((filter) => {
            return filter.ors.some(x => x.text);
        });

        if (filtersWithInput.some(x => x.associatedWith === 'parcel')) {
            return 'parcel';
        } else if (filtersWithInput.some(x => x.associatedWith === 'site')) {
            return 'site';
        } else if (filtersWithInput.some(x => x.associatedWith === 'company')) {
            return 'company';
        } else {
            // must be state only
            return 'parcel';
        }
    }

    getActiveAdvancedCriteria() {
        return this._activeAdvancedCriteria;
    }

    // saveAdvancedCriteria(criteria: AdvancedCriteria) {
    //     return this._userSettingsService.save(this.criteria).then((result) => {
    //         this._activeAdvancedCriteria = result.value;
    //     });
    // }

    deleteAdvancedCriteria(criteria) {
        // return this._userSettingsService.delete(this._userService.getUser().id, this.criteria.id);
    }
}
