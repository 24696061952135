<div class="return-part-content-area">
    <return-summary-reconciliation-report
        *ngIf="selectedReconciliationReport === returnSummaryReconciliationReport">
    </return-summary-reconciliation-report>

    <schedule-additions-disposals-reconciliation-report
        [hideEmptySchedules]="hideEmptySchedules"
        *ngIf="selectedReconciliationReport === scheduleAdditionsDisposalsReconciliationReport">
    </schedule-additions-disposals-reconciliation-report>

    <filing-control-reconciliation-report
        [groupBy]="groupBy?.value"
        *ngIf="selectedReconciliationReport === filingControlReconciliationReport">
    </filing-control-reconciliation-report>
</div>

<div class="return-part-interaction-area overview-report-selection">

    <div class="filter-group-inline-container">
        <div class="filter-label-inline">
            <label for="selectedReconciliationReport">Showing</label>
        </div>
        <div class="filter-control-inline">
            <ws-select [options]="reconciliationReports"
                       [ngModel]="selectedReconciliationReport"
                       [disabled]="!reconciliationReports.length"
                       (ngModelChange)="onSelectedReconciliationReportChange($event)"
                       labelProperty="name"
                       [returnEntireOption]="true">
            </ws-select>
        </div>
    </div>

    <return-form-selector [returnPartService]="returnPartServiceForInterPartComponent"
                          [allowPrimaryForReturn]="selectedReconciliationReport && selectedReconciliationReport.allowPrimaryForReturn">
    </return-form-selector>

    <div class="filter-group-inline-container" *ngIf="selectedReconciliationReport === filingControlReconciliationReport">
        <div class="filter-label-inline">
            <label for="selectedReconciliationReport">Group By</label>
        </div>
        <div class="filter-control-inline">
            <ws-select [options]="groupOptions"
                       [formControl]="groupBy"
                       labelProperty="name">
            </ws-select>
        </div>
    </div>

    <div class="filter-group-inline-container" *ngIf="selectedReconciliationReport === scheduleAdditionsDisposalsReconciliationReport">
        <div class="filter-label-inline"></div>
        <div class="filter-control-inline">
            <div class="ws-flex-none checkbox form-filter ws-no-margin ws-flex-align-items-center">
                <label class="small ws-font-weight-normal">
                    <input id="hideEmptySchedules" type="checkbox" [(ngModel)]="hideEmptySchedules"> Hide empty schedules
                </label>
            </div>
        </div>
    </div>

</div>
