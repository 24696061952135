import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface Nub {
    showNub: boolean;
    searchTooltip?: string;
    searchType?: 'quick-search' | 'advanced-search' | 'action-view';
}

@Injectable({ providedIn: 'root' })
export class NubService {
    private _activeNub: BehaviorSubject<Nub> = new BehaviorSubject({ showNub: false });
    private _activeNub$ = this._activeNub.asObservable();

    private _showQuickSearch: Subject<void> = new Subject();
    private _showQuickSearch$ = this._showQuickSearch.asObservable();

    private _actionViewNub: Nub = {
        showNub: true,
        searchType: 'action-view',
        searchTooltip: 'nub.action-view'
    };

    private _advancedSearchNub: Nub = {
        showNub: true,
        searchType: 'advanced-search',
        searchTooltip: 'nub.advanced-search'
    };

    private _quickSearchNub: Nub = {
        showNub: true,
        searchType: 'quick-search',
        searchTooltip: 'nub.quick-search'
    };

    get activeNub$(): Observable<Nub> {
        return this._activeNub$;
    }

    get showQuickSearch$(): Observable<void> {
        return this._showQuickSearch$;
    }

    hideNub(): void {
        this._activeNub.next({ showNub: false });
    }

    showActionViewNub(): void {
        this._activeNub.next(this._actionViewNub);
    }

    showAdvancedSearchNub(): void {
        this._activeNub.next(this._advancedSearchNub);
    }

    showQuickSearchNub(): void {
        console.log('showQuickSearchNub');
        this._activeNub.next(this._quickSearchNub);
    }

    showQuickSearch(): void {
        this._showQuickSearch.next();
    }
}
