<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<div class="return-settings-general">
    <div class="card panel-flat-color primary-panel flex-panel">
        <div class="panel-flat-header ws-flex-container-horizontal">
            <h3 class="ws-truncate ws-flex-auto">
                Batch Settings
            </h3>
            <div class="ws-flex-none header-button-container">
                <button *ngIf="!editMode && canEdit"
                        title="Edit"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button title="Save"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        (click)="save()">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button title="Cancel"
                        type="button"
                        class="btn flat-button icon-button primary-button"
                        *ngIf="editMode"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
            </div>
        </div>
        <div class="panel-flat-body">
            <div class="col-md-6">
                <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                    <div class="panel-flat-header small-header">
                        <h3>Override Collectors Protocol</h3>
                    </div>
                    <div *ngIf="settings" class="card-body">
                        <div class="form-check">
                            <input class="form-check-input" 
                                [(ngModel)]="settings.collectorOverrideScope" 
                                type="radio" 
                                [value]="allPayees" 
                                [disabled]="!editMode"
                                id="allPayees">
                            <label class="form-check-label" for="allPayees">
                                Apply to All Payees
                            </label>
                            <div class="form-inline">
                                <label *ngIf="editMode" class="ws-font-weight-normal flex">
                                    Maximum  <input class="form-control input-sm"
                                                style="width: 50px; margin: 0 5px;"
                                                type="text"
                                                [(ngModel)]="settings.maxItemsPerCheck"
                                                currencyMask
                                                [options]="{ allowNegative: false, precision: 0, prefix: ''}"
                                                [min]="1"
                                                [max]="MAX_PAYMENT_ITEMS"
                                                [disabled]="!isAppliedToAll"> payment item{{settings.maxItemsPerCheck > 1 || !settings.maxItemsPerCheck ? 's' : ''}} per check
                                </label>
                                <span *ngIf="!editMode">Maximum <strong>{{settings.maxItemsPerCheck ?? 'unlimited'}}</strong> payment item{{(settings.maxItemsPerCheck > 1 || !settings.maxItemsPerCheck ? 's' : '')}} per check</span>
                            </div>
                        </div>
                        <div class="form-check" style="margin-top: 25px;">
                            <input class="form-check-input" 
                                [(ngModel)]="settings.collectorOverrideScope" 
                                type="radio" 
                                [value]="individualPayees" 
                                [disabled]="!editMode"
                                id="individualPayees">
                            <label class="form-check-label" for="individualPayees">
                                Apply to Individual Payee
                            </label>
                            <table class="table table-striped table-sm" style="margin-top: 15px;">
                                <thead>
                                    <tr>
                                        <th>Payee</th>
                                        <th class="text-end">Max Payment Items Per check</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payee of collectorOverridesUI">
                                        <td style="vertical-align: middle;">{{payee.collectorName}}</td>
                                        <td style="vertical-align: middle;" class="text-end">
                                            <span *ngIf="!editMode">{{payee.inputValue ?? 'Unlimited'}}</span>
                                            <input *ngIf="editMode" 
                                                class="form-control input-sm"
                                                type="text"
                                                style="max-width: 175px; display: inline-block;"
                                                [(ngModel)]="payee.inputValue"
                                                currencyMask
                                                [options]="{ allowNegative: false, precision: 0, prefix: ''}"
                                                [min]="0"
                                                [max]="payee.maxItemsPerCheckCollector ?? MAX_PAYMENT_ITEMS"
                                                [disabled]="isAppliedToAll">
                                        </td>
                                        <td class="text-center" style="vertical-align: middle;">
                                            <button class="flat-button icon-button" 
                                                style="margin: 0;"
                                                [disabled]="!editMode"
                                                (click)="revertOverride(payee)"
                                                [class.invisible]="payee.inputValue === payee.maxItemsPerCheckCollector">
                                                    <i class="fa fa-undo"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
