import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForActionViewOutputDefaultsGridAction extends ICellRendererParams {
    hasEditPermission: () => boolean;
    viewDetails: (params: ICellRendererParamsForActionViewOutputDefaultsGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button" class="btn flat-button primary-button icon-button grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i *ngIf="hasEditPermission" class="fa fa-pencil"></i>
            <i *ngIf="!hasEditPermission" class="fa fa-eye"></i>
        </button>`
})
export class ActionViewOutputDefaultsGridActionCellRendererComponent implements ICellRendererAngularComp {
    private _params: ICellRendererParamsForActionViewOutputDefaultsGridAction;
    userGroupGridItem: Core.GroupModel;
    hasEditPermission: boolean;

    agInit(params: ICellRendererParamsForActionViewOutputDefaultsGridAction): void {
        this._params = params;
        this.hasEditPermission = params.hasEditPermission();
        this.userGroupGridItem = params.data as Core.GroupModel;
        if (!this.userGroupGridItem) {
            return;
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
