import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CostAdjustmentRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    getTypes(): Observable<Compliance.CostAdjustmentTypeModel[]> {
        return this.httpGet(`/api/costadjustmenttype`);
    }
}
