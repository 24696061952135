<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="'Bulk Update ' + params.selectedCount +' Records'"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!isFormValid()">
    <form>
        <div class="table-responsive">
            <table class="table table-striped table-condensed table-borderless-body">
                <tbody>
                    <tr>
                        <td>Description</td>
                        <td>
                            <gl-account-bulk-update-action [requiredField]="false"
                                                           [possibleActions]="possibleActions"
                                                           [(fieldAction)]="glAccountBulkUpdateModel.descriptionAction">

                            </gl-account-bulk-update-action>
                        </td>
                        <td>
                            <input name="description"
                                   type="text"
                                   autocomplete="off"
                                   class="form-control"
                                   [(ngModel)]="glAccountBulkUpdateModel.description"
                                   maxLength="100"
                                   [disabled]="isDisabled(glAccountBulkUpdateModel.descriptionAction)" />
                        </td>
                    </tr>
                    <tr>
                        <td>Asset Classification</td>
                        <td>
                            <gl-account-bulk-update-action [requiredField]="false"
                                                           [fieldAction]="fieldActionInitValue"
                                                           [possibleActions]="possibleActions"
                                                           [(fieldAction)]="glAccountBulkUpdateModel.classificationIdAction"></gl-account-bulk-update-action>
                        </td>
                        <td>
                            <div>
                                <asset-class-selector [(assetClassificationId)]="glAccountBulkUpdateModel.classificationId"
                                                      [isDisabled]="isDisabled(glAccountBulkUpdateModel.classificationIdAction)"
                                                      (assetClassificationChange)="newClassification($event)">

                                </asset-class-selector>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </form>
</ws-modal-wrapper>

