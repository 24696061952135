<ws-modal-wrapper headerLabel="Advanced"
                  headerHelpContentId="asset-advanced.header"
                  acceptHelpContentId="asset-advanced.accept"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="disableSubmit">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-flex-container-vertical advanced-filter-quick-list form-inline">
            <label class="ws-flex-none ws-font-weight-normal">
                <span class="filter-label">Quick:</span>
                <select class="form-select form-select-sm" name="selectOptions" id="selectLabel"
                        [formControl]="selectedOption" (change)="applyFromDropdown()">
                    <option *ngFor="let option of selectOptions" [ngValue]="option">{{ option.name }}</option>
                </select>
            </label>
        </div>
        <div class="ws-flex-container-vertical">
            <form [formGroup]="advancedFilterForm">
                <advanced-detail-checkbox [options]="assetFilters" (changed)="updateForm($event)"></advanced-detail-checkbox>
            </form>
        </div>
        <div class="ws-flex-container-vertical advanced-filter-quick-list form-inline">
            <div class="ws-flex-container-vertical">
                <label class="ws-flex-container-horizontal-wrap ws-font-weight-normal">
                    <span class="filter-label">Comparison Date:</span>
                    <weissman-datetime [(inputDate)]="comparisonLienDate" [isDisabled]="comparisonLienDateDisabled" [dateOnly]="true" [required]="true" (inputDateChange)="onComparisonLienDateChanged($event)"></weissman-datetime>
                    <select name="comparisonLienDateTypeOption" id="comparisonLienDateTypeSelectLabel" class="form-select form-select-sm" [formControl]="comparisonLienDateTypeOption" (change)="comparisonLienDateTypeOptionChanged()">
                        <option *ngFor="let option of comparisonLienDateTypeOptions" [ngValue]="option">{{ option.name }}</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
