<div class="input-group import-selector"
     style="width: 100%"
     [attr.aria-label]="placeholder">
    <input id="typeaheadSelector"
           type="text"
           [placeholder]="placeholder"
           class="form-control exclude-modal-styling"
           [ngClass]="{ 'overridden': isOverridden }"
           [class.has-reset]="showReset"
           autocomplete="off"
           (blur)="onBlur()"
           [(ngModel)]="filter"
           container=".import-grid"
           [typeahead]="values$"
           [typeaheadOptionField]="labelProperty"
           [typeaheadMinLength]="0"
           [typeaheadScrollable]="true"
           (typeaheadOnSelect)="onSelected($event)"
           [typeaheadOptionsLimit]="1000"
           [adaptivePosition]="true"
           [disabled]="disabled" />
    <span class="reset-value clickable"
          [class.showReset]="showReset"
          helpTooltip
          tooltipText="Reset to the original value"
          position="right"
          (click)="resetToOriginalValue()">
        <i class="fa fa-times"></i>
    </span>
</div>
