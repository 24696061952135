import { Component, ElementRef, ViewChild, Output, EventEmitter, Input, ApplicationRef } from '@angular/core'
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
    selector: 'ws-file-picker',
    templateUrl: './filePicker.component.html'
})
export class FilePickerComponent {
    constructor(private _applicationRef: ApplicationRef) {}

    private _generateFileName(files: File[]): void {
        if (files.length === 0) {
            this.filename = null;
        }
        if (files.length === 1) {
            this.filename = files[0].name;
        }
        if (files.length > 1) {
            this.filename = `${files.length} files selected`;
        }
    }

    /**
     * Allow selecting multiple files.
     */
    @Input() multiple: boolean;

    /**
     * Limit the types of files that can be picked
     * ex: ".xls,.xlsx"
     */
    @Input() acceptTypes: string = null;

    /**
     * Hides name of files loaded
     */
    @Input() hideFileNames: boolean = false;

    /**
     * Event emitter for when the file selection changes.
     */
    @Output() picked: EventEmitter<File[]> = new EventEmitter<File[]>();

    /**
     * The name of the selected file or a display message for how many files are selected (if multiple allowed).
     */
    filename: string;

    /**
     * Gets called when the user changes the selected file (via drag-and-drop or browse).
     * @param uploadFiles The event from ngx-file-drop
     */
    fileDropped(uploadFiles: NgxFileDropEntry[]): void {
        const limit = this.multiple ? uploadFiles.length : 1;
        const files: File[] = [];

        for (var i = 0; i < limit; i++) {
            const file = uploadFiles[i];
            if (file && file.fileEntry.isFile) {
                const fileEntry = file.fileEntry as FileSystemFileEntry;
                fileEntry.file((f: File) => {
                    files.push(f);
                    // check if the last file was retrieved
                    if (files.length === limit) {
                        this._generateFileName(files);
                        this.picked.emit(files);
                        // let angular know that the file has changed
                        this._applicationRef.tick();
                    }
                });
            }
        }
    }
}
