import { Directive, ElementRef, Input, NgZone, Renderer2, HostListener } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { switchMap, takeUntil, map } from 'rxjs/operators';
import { TimerService } from '../../Utilities/timer.service';

interface Vector2 {
    x: number;
    y: number;
}

export enum DraggableElementSearchDirection {
    Up,
    Down
}

/**
 * Allows you to drag an element around the view
 *
 * Example usage:
 * <div draggable dragHandle=".handle">
 *    <span class="handle">Grab Here</span>
 * </div>
 */
@Directive({
    selector: '[draggable]'
})
export class DraggableDirective {
    constructor(
        private readonly _renderer: Renderer2,
        private readonly _elementRef: ElementRef,
        private readonly _zone: NgZone,
        private readonly _timer: TimerService
    ) { }

    @Input() dragHandle: string;
    @Input() dragHandleDirection: DraggableElementSearchDirection = DraggableElementSearchDirection.Down;
    @Input() dragTarget: string;
    @Input() dragTargetDirection: DraggableElementSearchDirection = DraggableElementSearchDirection.Down;

    @HostListener('window:resize', ['$event'])
    onResize(e) {
        this._checkOutsideBounds();
    }

    private _target: HTMLElement;
    private _handle: HTMLElement;
    private _centeringButton: HTMLElement;

    private _delta: Vector2 = { x: 0, y: 0 };
    private _offset: Vector2 = { x: 0, y: 0 };

    private _boundingThreshold = 20;
    private _buttonVisible: boolean = false;
    private _dragInProgress: boolean = false;

    private destroy$ = new Subject<void>();

    ngAfterViewInit(): void {
        this._addButton();

        this._handle = this._findElement(this.dragHandle, this.dragHandleDirection);
        this._target = this._findElement(this.dragTarget, this.dragTargetDirection);

        this._renderer.addClass(this._handle, 'ws-cursor-grab');
        this._renderer.addClass(this._target, 'ws-drag-target');

        this._setupEvents();
    }

    ngOnDestroy(): void {
        this._toggleCenteringButton(false);
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setupEvents() {
        this._zone.runOutsideAngular(() => {
            const mousedown$ = fromEvent(this._handle, 'mousedown');
            const mousemove$ = fromEvent(document, 'mousemove');
            const mouseup$ = fromEvent(document, 'mouseup');

            mousedown$.pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this._cursorChange(true);
                });

            mousedown$
                .pipe(switchMap((event: MouseEvent) => {
                    event.preventDefault();
                    this._cursorChange(true);
                    const startX = event.clientX;
                    const startY = event.clientY;

                    return mousemove$
                        .pipe(map((event: MouseEvent) => {
                            event.preventDefault();
                            this._delta = {
                                x: event.clientX - startX,
                                y: event.clientY - startY
                            };
                        }))
                        .pipe(takeUntil(mouseup$));
                }))
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    if (this._delta.x === 0 && this._delta.y === 0) {
                        return;
                    }

                    this._translate();
                });

            mouseup$.pipe(takeUntil(this.destroy$)).subscribe((event: MouseEvent) => {
                // We don't want this event handler to steal mouse up events from
                // other controls if we don't have a drag in progress.
                // A "select multiple" within the draggable div was not getting
                // events consistently without this short-circuit.
                if ( !this._dragInProgress )
                    return;
                event.preventDefault();
                this._checkOutsideBounds();

                this._offset.x += this._delta.x;
                this._offset.y += this._delta.y;
                this._delta = { x: 0, y: 0 };
                this._cursorChange(false);
            });
        });
    }

    private _translate(): void {
        this._toggleCenteringButton(!!(this._delta.x || this._delta.y));
        requestAnimationFrame(() => {
            this._target.style.transform = `
                translate(${this._offset.x + this._delta.x}px,
                          ${this._offset.y + this._delta.y}px)
            `;
        });
    }

    private _checkOutsideBounds(): void {
        const handleBounds = this._handle.getBoundingClientRect();
        const documentWidth = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        const documentHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

        const top = handleBounds.bottom < this._boundingThreshold;
        const right = handleBounds.left > (documentWidth - this._boundingThreshold);
        const bottom = handleBounds.top > (documentHeight - this._boundingThreshold);
        const left = handleBounds.right < this._boundingThreshold;

        if (top || right || bottom || left) {
            this._centerTarget();
        }
    }

    private _centerTarget(): void {
        this._target.style.transition = 'transform 300ms ease';
        this._offset = { x: 0, y: 0 };
        this._delta = { x: 0, y: 0 };
        this._translate();
        this._timer.setTimeout(() => {
            this._target.style.transition = null;
            this._toggleCenteringButton(false);
        }, 300);
    }

    private _findElement(target: string, direction: DraggableElementSearchDirection): HTMLElement {
        if (target) {
            switch (direction) {
                case DraggableElementSearchDirection.Up:
                    const parentElement = this._elementRef.nativeElement.closest(target);
                    if (parentElement) { return parentElement; }
                case DraggableElementSearchDirection.Down:
                    const childElement = this._elementRef.nativeElement.querySelector(target);
                    if (childElement) { return childElement; }
                default:
                    return this._elementRef.nativeElement;
            }
        } else {
            return this._elementRef.nativeElement;
        }
    }

    /**
     * Change the cursor from grab to grabbing
     * @param grabbing
     */
    private _cursorChange(grabbing: boolean): void {
        this._dragInProgress = grabbing;
        requestAnimationFrame(() => {
            if (grabbing) {
                this._renderer.removeClass(this._handle, 'ws-cursor-grab');
                this._renderer.addClass(this._handle, 'ws-cursor-grabbing');
            } else {
                this._renderer.removeClass(this._handle, 'ws-cursor-grabbing');
                this._renderer.addClass(this._handle, 'ws-cursor-grab');
            }
        });
    }

    /**
     * Show/hide the centering button
     * @param translated
     */
    private _toggleCenteringButton(translated: boolean): void {
        if (translated && !this._buttonVisible) {
            this._renderer.appendChild(document.body, this._centeringButton);
            this._buttonVisible = true;
        } else if (!translated && this._buttonVisible) {
            this._renderer.removeChild(document.body, this._centeringButton);
            this._buttonVisible = false;
        }
    }

    /**
     * Add the centering button to the container element
     */
    private _addButton(): void {
        if (!this._centeringButton) {
            this._centeringButton = this._renderer.createElement('button');

            this._renderer.listen(this._centeringButton, 'click', () => this._centerTarget());

            this._renderer.setAttribute(this._centeringButton, 'class', 'flat-button icon-button');
            this._renderer.setAttribute(this._centeringButton, 'title', 'Bring the modal back to center');
            this._renderer.setStyle(this._centeringButton, 'background-color', '#0a8287');
            this._renderer.setStyle(this._centeringButton, 'position', 'absolute');
            this._renderer.setStyle(this._centeringButton, 'top', '20px');
            this._renderer.setStyle(this._centeringButton, 'left', '10px');
            this._renderer.setStyle(this._centeringButton, 'zIndex', '10000');
            this._renderer.setStyle(this._centeringButton, 'border-radius', '0.25rem');

            const icon = this._renderer.createElement('i');

            this._renderer.addClass(icon, 'fa');
            this._renderer.addClass(icon, 'fa-bullseye');

            this._centeringButton.appendChild(icon);
        }
    }
}
