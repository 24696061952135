import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { SiteClassService } from '../../Site/siteClass.service';
import * as _ from 'lodash';
import { map, sortBy } from 'lodash/fp';
import { ToastrService } from 'ngx-toastr';
import { SiteService } from '../../Site/Site.Service.upgrade';
import { Site } from '../../Site/Site.Model';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';

export interface AddSiteModalParams {
    companyId: number;
}

interface SiteClassUI extends Weissman.Model.Domain.SiteClassSecondary {
    fullName: string;
}

@Component({
    selector: 'add-site-modal',
    templateUrl: 'addSiteModal.component.html'
})

export class AddSiteModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<AddSiteModalParams, void> {
    @ViewChild('siteName') siteName: ElementRef;

    params: AddSiteModalParams;
    result: void;

    serverAction: boolean = false;
    addSiteForm: UntypedFormGroup;
    siteClasses: SiteClassUI[];

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _siteClassService: SiteClassService,
        private readonly _toastr: ToastrService,
        private readonly _siteService: SiteService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _bsModalRef: BsModalRef
    ) { }

    async ngOnInit() {
        this.addSiteForm = this._fb.group({
            name: ['', Validators.required],
            siteNumber: [''],
            siteClassSecondaryID: [this._siteService.memoryStorage.siteClassSecondaryID, Validators.required],
            stateID: [this._siteService.memoryStorage.stateID, Validators.required]
        });

        this.serverAction = true;

        try {
            const siteClasses = await this._siteClassService.getSecondaries();
            this.siteClasses = _.flow([
                map((x: Weissman.Model.Domain.SiteClassSecondary) => {
                    const fullName = `${x.siteClassPrimary.siteClassPrimaryDesc} - ${x.siteClassDesc}`
                    return {fullName, ...x};
                }),
                sortBy('fullName')
            ])(siteClasses);


        } finally {
            this.serverAction = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.siteName.nativeElement.focus();
        })
    }

    async save(): Promise<void> {
        const isSiteNameNonAscii = !(/^[ -~\t\n\r]+$/.test(this.addSiteForm.controls.name.value));
        if (isSiteNameNonAscii) {
            this._toastr.error('Site Name contains invalid character(s)');
            return;
        }

        const siteToAdd = {
            siteID: 0,
            name: this.addSiteForm.value.name,
			companyID: this.params.companyId,
            property: this.addSiteForm.value.siteNumber,
            siteClassSecondaryID: this.addSiteForm.value.siteClassSecondaryID,
			activityStatusID: 2, // should new site be active or inactive?
			deleted: false,
			address: {
                stateID: this.addSiteForm.value.stateID
            }
        }

        this.serverAction = true;

        try {
            const newSite = await this._siteService.add(siteToAdd as Site);

            this._siteService.memoryStorage = {
                siteClassSecondaryID: siteToAdd.siteClassSecondaryID,
                stateID: siteToAdd.address.stateID
            }

            this._upgradeNavigationServiceHandler.go('site', {
                siteId: newSite.siteID,
                companyId: newSite.companyID
            }, true)

        } finally {
            this.serverAction = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
