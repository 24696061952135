import {HelpContent} from '../../../../UI-Lib/Help-Tooltip';

export const COLUMN_MAPPING_STEP_HELP:HelpContent[] = [
    {
        helpContentId: 'column-mapping-step.save-import-specification',
        tooltipText: 'Save these mappings so that it is easier the next time you import this type of file.'
    },
    {
        helpContentId: 'column-mapping-step.add-static-column',
        tooltipText: 'Static columns are values that are applied to all the rows of the import.  You can set them up here.'
    },
];
