import { HelpContent } from '../../UI-Lib/Help-Tooltip';

export const PAYMENT_BATCH_COMMAND_CENTER_HELP: HelpContent[] = [
    {
        helpContentId: 'payment-batch-command-center.filter-disabled',
        tooltipText: 'Not available until a valid filter is selected'
    },
    {
        helpContentId: 'payment-batch-command-center.company',
        tooltipText: 'Filter grid by top level company.',
        hasIcon: true
    },
    {
        helpContentId: 'payment-batch-detail.status',
        tooltipText: 'How many payments at a status.',
        hasIcon: true
    }
];
