import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserSettingsService } from 'src/app/Account/userSettings.service';
import { RestrictData, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { CompanyModel, CompanyService } from '../company.service';
import * as _ from 'lodash';
import { NavigationService } from 'src/app/Layout/navigation.service';
import { AddSiteModalComponent, AddSiteModalParams } from './addSiteModal.component';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { Settings } from 'src/app/User/account.service';
import { UserInstanceService } from 'src/app/User/userInstance.service';

@Component({
    selector: 'company-exploration',
    templateUrl: 'companyExploration.component.html'
})

export class CompanyExplorationComponent implements OnInit {
    @Input() company: CompanyModel;

    private readonly _COMPANY_VIEW_USER_SETTINGS_GROUP = 23;
    private readonly _COMPANY_VIEW_USER_SETTINGS_NAME = 'Company-View-Settings';

    hasWritePermission: boolean = false;
    activeOnly: boolean = true;
    serverAction: boolean = false;
    states: string[];
    refreshingSites: boolean;
    includeSubsidiarySites: boolean = false;
    isConsultantPerspective: boolean = false;

    constructor(@Inject('$rootScope') private _rootScope: any,
                private _userSettingsService: UserSettingsService,
                private _restrictService: RestrictService,
                private _nagivationService: NavigationService,
                private _modalService: WeissmanModalService,
                private readonly _userInstanceService: UserInstanceService,
                private _companyService: CompanyService,
                private readonly _productAnalyticsService: ProductAnalyticsService) {
    }

    async ngOnInit() {
        this._retrieveUserSettings();

        this.serverAction = true;
        try {
            const dataPromises: [Promise<boolean>, Promise<string[]>] = [
                this._getWritePermission(),
                this._companyService.getStatesWithSites(this.company.companyID, this.activeOnly, this.includeSubsidiarySites)
            ];

            [this.hasWritePermission, this.states] = await Promise.all(dataPromises);

            this.isConsultantPerspective = this._userInstanceService.isCrossInstancePerspective(this.company.instanceID);
        } finally {
            this.serverAction = false;
        }
    }

    get sitesTitle(): string {
        const str = 'Sites by State';
        return this.activeOnly ? `Active ${str}` : str;
    }

    get isTopLevel(): boolean {
        return !this.company.parentId;
    }

    get companyHasSubsidiaries(): boolean {
        return this.company.childCompanies?.length > 0;
    }

    doQuickSearch(state: string): void {
        let includeSubsidiaryFlag: boolean = true;
        if (this.isTopLevel || this.companyHasSubsidiaries) {
            includeSubsidiaryFlag = this.includeSubsidiarySites;
        }
        this._productAnalyticsService.logEvent('click-state-tile');
        this._nagivationService.openQuickSearch(false);
        this._rootScope.$broadcast('quickSearch:getSitesForCompany', state, this.company, this.activeOnly, includeSubsidiaryFlag);
    }

    async addSite(): Promise<void> {
        const params: AddSiteModalParams = { companyId: this.company.companyID };
        await this._modalService.showAsync(AddSiteModalComponent, params, 'modal-md');
    }

    async includeSubsidiarySitesChanged() {
        const companyViewSettings = this._userSettingsService.getSettingsByGroup(this._COMPANY_VIEW_USER_SETTINGS_GROUP);
        let settings = _.find(companyViewSettings, { name: this._COMPANY_VIEW_USER_SETTINGS_NAME });

        if (settings) {
            // Update user's settings.
            settings.value = { includeSubsidiarySites: this.includeSubsidiarySites };
        } else {
            // First time saving for user.
            settings = this._getNewUserSettings();
        }

        this._userSettingsService.save(settings);

        return this.getStatesWithSites();
    }

    async getStatesWithSites() {
        this.serverAction = true;
        try {
            this.states = await this._companyService.getStatesWithSites(this.company.companyID, this.activeOnly, this.includeSubsidiarySites);
        } finally {
            this.serverAction = false;
        }
    }

    private _retrieveUserSettings() {
        const companyViewSettings = this._userSettingsService.getSettingsByGroup(this._COMPANY_VIEW_USER_SETTINGS_GROUP);
        const settings = _.find(companyViewSettings, { name: this._COMPANY_VIEW_USER_SETTINGS_NAME });

        if (settings?.value) {
            const userSettings = _.clone(settings.value);
            this.includeSubsidiarySites = userSettings.includeSubsidiarySites === true;
        } else {
            // Save default for user when user doesn't have setting
            this._userSettingsService.save(this._getNewUserSettings());
        }
    }

    private _getNewUserSettings(): Settings {
        return {
            id: 0,
            name: this._COMPANY_VIEW_USER_SETTINGS_NAME,
            value: _.cloneDeep({ includeSubsidiarySites: this.includeSubsidiarySites }),
            groupId: this._COMPANY_VIEW_USER_SETTINGS_GROUP,
            groupName: this._COMPANY_VIEW_USER_SETTINGS_NAME,
            folderId: 20,
            folderName: this._COMPANY_VIEW_USER_SETTINGS_NAME
        };
    }

    private async _getWritePermission(): Promise<boolean> {
        const restrictionData = {
            isCompany: true,
            entityId: this.company.companyID,
            flag: Core.AccessRightsEnum.Write,
            roles: []
        } as RestrictData;

        return this._restrictService.hasPermission(restrictionData);
    }
}
