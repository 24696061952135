<ul class="stepProgress">
    <li *ngFor="let step of steps"
        [class]="getClass(step)"
        helpTooltip
        [tooltipText]="step.title()"
        position="bottom"
        (click)="click(step)">
        <span *ngIf="showStepName">{{ step.name }}</span>
    </li>
</ul>
