import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ModalWrapperModule } from '../../UI-Lib/Modal-Wrapper/modalWrapper.module';
import { AssessmentTaxesDetailsIconComponent } from './assessment-taxes-details-icon.component';
import { AssessmentTaxesDetailsModalComponent } from './assessment-taxes-details-modal.component';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalWrapperModule
    ],
    declarations: [
        AssessmentTaxesDetailsModalComponent,
        AssessmentTaxesDetailsIconComponent
    ],
    exports: [AssessmentTaxesDetailsIconComponent]
})
export class AssessmentTaxesDetailsModule {
    static setupModule() {
        hybridAdapterUtility.downgradeNg2Component('assessmentTaxesDetailsIcon', AssessmentTaxesDetailsIconComponent);
    }
}
