import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { Subscription,  BehaviorSubject, lastValueFrom } from 'rxjs';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { ColDef, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AgGridFilterParams, AgGridColumns } from '../../AgGrid';
import { AgGridOptionsBuilder } from '../../AgGrid';
import { FormReportListGridActionCellRendererComponent, ICellRendererParamsForFormReportListGridAction } from './agGridActionCellRenderer.component';
import { WeissmanMutexService, IMutexServiceHandler } from '../../WeissmanMutexService';
import { FormReportListCheckboxCellRendererComponent } from './agGridCheckboxCellRender.component';
import { ICellRendererParamsForFormReportListCheckbox } from './agGridCheckboxCellRender.component';
import { FormReportAddComponent } from '../Form-Report-Add/formReportAdd.component';
import { WeissmanModalService } from '../../WeissmanModalService';
import { FormRepository } from '../../Repositories';
import { BaseExpandableAgGridComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { ReportManagerModalService } from '../../../Reporting/Manager/reportManagerModal.service';
import { ReportManagerModalInputParameters } from '../../../Reporting/Manager/reportManagerModal.component';

import * as _ from 'lodash';

export interface FormRevisionReportRowModel extends Compliance.FormRevisionReportModel {
    deleted: boolean;
    added: boolean;
    changed: boolean;
    originalValue: Compliance.FormRevisionReportModel;
    displayName: string;
}

@Component({
    selector: 'form-report-list',
    templateUrl: './formReportList.component.html',
    styleUrls: ['./formReportList.component.scss']
})
export class FormReportListComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _formService: FormService,
        private readonly _formRepository: FormRepository,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _reportManagerModalService: ReportManagerModalService
    ) {
        super(_formService, 'form-report-list');
     }

    private _gridApi: GridApi;
    private _formRevisionSub: Subscription;
    private _reportsSub: Subscription;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;

    get canEdit(): boolean {
        return this._formService.canEdit;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._formService.editGroup);
    }

    get hasChanges(): boolean {
        let hasChanges: boolean = false;

        if (this._gridApi) {
            this._gridApi.forEachNode((node: RowNode) => {
                const report = node.data as FormRevisionReportRowModel;
                if (report && (report.deleted || report.added || report.changed)) {
                    hasChanges = true;
                }
            });
        }

        return hasChanges;
    }

    editMode: boolean = false;

    gridId: System.Guid = '4CEF3CDA-E95B-4382-8371-D601D7E959F6';

    gridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            rowClassRules: {
                'grid-row-deleted': params => params.data && params.data.deleted
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withRowId(x => {
            const report = x as FormRevisionReportRowModel;
            return report && report.formRevisionReportId.toString();
        })
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    ngOnInit(): void {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._formService.editGroup);
            }
        });

        this._formRevisionSub = this._formService.formRevision$.subscribe(() => {
            // initial load
            this._setRowData();
        });

        this._reportsSub = this._formService.reports$.subscribe(() => {
            // reports changed
            this._setRowData();
        });
    }

    ngOnDestroy(): void {
        this._editModeSub && this._editModeSub.unsubscribe();
        this._formRevisionSub && this._formRevisionSub.unsubscribe();
        this._reportsSub && this._reportsSub.unsubscribe();
        this._mutexService.release(this, this._formService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        super.setGridApi(event.api);

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'displayName',
                width: AgGridColumns.textColumnWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Type',
                field: 'isSystem',
                width: AgGridColumns.textColumnSmallWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const formRevisionReport = params.data as Compliance.FormRevisionReportModel;
                    return this._formService.getReportType(formRevisionReport);
                },
                filterValueGetter: params => {
                    const formRevisionReport = params.data as Compliance.FormRevisionReportModel;
                    return this._formService.getReportType(formRevisionReport);
                }
            },
            {
                headerName: 'Included',
                field: 'isIncluded',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: FormReportListCheckboxCellRendererComponent,
                cellRendererParams: {
                    canEdit: this._canEditSettings.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForFormReportListCheckbox
            },
            {
                headerName: 'Freq. Used',
                field: 'isFrequentlyUsed',
                width: AgGridColumns.textColumnMedWidth,
                minWidth: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: FormReportListCheckboxCellRendererComponent,
                cellRendererParams: {
                    canEdit: this._canEditSettings.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    onValueChanged: this._onValueChanged.bind(this)
                } as ICellRendererParamsForFormReportListCheckbox
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: FormReportListGridActionCellRendererComponent,
                cellRendererParams: {
                    canEdit: this._canEdit.bind(this),
                    canEnterEditMode: this._canEnterEditMode.bind(this),
                    edit: this._edit.bind(this),
                    getEditTitle: this._getEditTitle.bind(this),
                    canRemove: this._canRemove.bind(this),
                    remove: this._remove.bind(this),
                    canUndoRemove: this._canUndoRemove.bind(this),
                    undoRemove: this._undoRemove.bind(this),
                } as ICellRendererParamsForFormReportListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'displayName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async edit(): Promise<void> {
        if (!this.canEdit) {
            return;
        }
        const res = await this._formService.checkEditDefaultAssessor();
        if (res) {
            this._mutexService.acquire(this, this._formService.editGroup);
            this._editModeSubject.next(true);
        }
    }

    cancel(): void {
        const update: RowNode[] = [];
        const remove: FormRevisionReportRowModel[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const report = node.data as FormRevisionReportRowModel;

            if (report && !report.added) {
                update.push(node);

                _.extend(report, report.originalValue, { changed: false, deleted: false });

            } else { // if added, remove it
                remove.push(report);
            }
        });

        this._gridApi.updateRowData({ remove: remove });
        this._gridApi.redrawRows({ rowNodes: update });

        this._editModeSubject.next(false);
    }

    async add(): Promise<void> {
        const reports: Compliance.FormRevisionReportModel[] = [];
        this._gridApi.forEachNode(x => reports.push(x.data));

        const result = await this._modalService.showAsync(FormReportAddComponent, reports, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        const newReports = _.map(result,
            (x) => {
                return _.extend(
                    {} as FormRevisionReportRowModel,
                    x,
                    {
                        formRevisionId: this._formService.formRevisionId,
                        isFrequentlyUsed: true,
                        isIncluded: false,
                        added: true,
                        deleted: false,
                        changed: false,
                        originalValue: null,
                        displayName: this._formService.getReportName(x)
                    });
            });

        this._gridApi.updateRowData({ add: newReports });

        return Promise.resolve();
    }

    async save(): Promise<void> {
        if (!this.hasChanges) {
            return Promise.resolve();
        }

        const reports: Compliance.FormRevisionReportModel[] = [];
        this._gridApi.forEachNode((node: RowNode) => {
            const reportRow = node.data as FormRevisionReportRowModel;

            if (!reportRow.deleted) {
                const report = _.extend({} as Compliance.FormRevisionReportModel, reportRow);
                reports.push(report);
            }
        });

        const busyRef = this._busyIndicatorService.show({ message: 'Saving Reports' });

        try {
            const result = await lastValueFrom<Compliance.FormRevisionReportModel[]>(this._formRepository.updateReports(this._formService.formRevisionId, reports));
            this._formService.setReports(result);
            this._editModeSubject.next(false);
            this._mutexService.release(this, this._formService.editGroup);
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _getEditTitle(params: ICellRendererParamsForFormReportListGridAction): string {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return '';
        }

        if (formRevisionReportRow.savedSearchCategoryId !== Core.SavedSearchCategoryEnum.ComplianceReportSetup &&
            formRevisionReportRow.savedSearchCategoryId !== Core.SavedSearchCategoryEnum.ComplianceCustomReport) {
            return 'Cannot customize this type of report';
        }

        if (formRevisionReportRow.added) {
            return 'Cannot customize until you save changes';
        }

        if (formRevisionReportRow.deleted) {
            return 'Cannot customize a deleted report';
        }

        return '';
    }

    private _canEdit(params: ICellRendererParamsForFormReportListGridAction): boolean {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return false;
        }
        return this.canEdit && this.editMode;
    }

    private _canEnterEditMode(params: ICellRendererParamsForFormReportListGridAction): boolean {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return false;
        }

        return (!(formRevisionReportRow.added || formRevisionReportRow.deleted)) &&
            (formRevisionReportRow.savedSearchCategoryId === Core.SavedSearchCategoryEnum.ComplianceReportSetup ||
                formRevisionReportRow.savedSearchCategoryId === Core.SavedSearchCategoryEnum.ComplianceCustomReport);
    }

    private async _edit(params: ICellRendererParamsForFormReportListGridAction): Promise<void> {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return;
        }

        const modalParams: ReportManagerModalInputParameters = {
            reportId: formRevisionReportRow.savedSearchId,
            reportType: formRevisionReportRow.isSystem ? 0 : 1,
            formRevisionId: formRevisionReportRow.formRevisionId,
            formRevisions: [],
            schedules: this._formService.formRevision.formRevisionSchedules
        };

        try {
            const formRevisionReport = await this._reportManagerModalService.showForFormRevision(modalParams);

            if (!formRevisionReport) {
                return;
            }

            _.extend(
                formRevisionReportRow,
                formRevisionReport,
                { changed: true, deleted: false, added: false, originalValue: formRevisionReport,
                    displayName: this._formService.getReportName(formRevisionReport) });

            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } catch (e) {

        }
    }

    private _canEditSettings(params: ICellRendererParamsForFormReportListCheckbox): boolean {
        const report = params.data as FormRevisionReportRowModel;
        if (!report) {
            return false;
        }
        return this.canEdit && !report.deleted;
    }

    private _canUndoRemove(params: ICellRendererParamsForFormReportListGridAction): boolean {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return false;
        }

        return this.editMode && formRevisionReportRow.deleted;
    }

    private _canRemove(params: ICellRendererParamsForFormReportListGridAction): boolean {
        const formRevisionReportRow = params.data as FormRevisionReportRowModel;
        if (!formRevisionReportRow) {
            return false;
        }

        return this.editMode && !formRevisionReportRow.deleted;
    }

    private _remove(params: ICellRendererParamsForFormReportListGridAction): void {
        const report = params.data as FormRevisionReportRowModel;
        if (!report) {
            return;
        }

        if (!report.added) {
            report.deleted = true;
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
            this._gridApi.updateRowData({ remove: [report] });
        }
    }

    private _undoRemove(params: ICellRendererParamsForFormReportListGridAction): void {
        const report = params.data as FormRevisionReportRowModel;
        if (!report) {
            return;
        }

        report.deleted = false;

        this._gridApi.redrawRows({ rowNodes: [params.node] });
    }

    private _onValueChanged(params: ICellRendererParamsForFormReportListCheckbox): void {
        const report = params.data as FormRevisionReportRowModel;
        if (!report) {
            return;
        }

        report.changed = true;
    }

    private _setRowData() {
        if (!(this._gridApi && this._formService.isInitialized)) {
            return;
        }

        const reports = _.map(this._formService.reports,
            (x) => {
                return _.extend(
                    {} as FormRevisionReportRowModel,
                    x,
                    {
                        changed: false,
                        deleted: false,
                        added: false,
                        originalValue: x,
                        displayName: this._formService.getReportName(x)
                    });
            });

        this._gridApi.setRowData(reports);
    }
}
