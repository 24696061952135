<div *ngIf="viewMode === ViewModelEnum.ReturnValues" [ngClass]="{ 'overridden': lookupHasOverride }">
{{lookupValue}}
</div>
<select
    name="lookup"
    class="form-select form-select-sm"
    [ngClass]="{ 'overridden': lookupHasOverride }"
    [(ngModel)]="lookupId"
    [disabled]="isReadonly"
    *ngIf="viewMode === ViewModelEnum.Settings">
    <option *ngFor="let lo of options" [ngValue]="lo.lookupId" [ngStyle]="{ 'color': !isTopLevelCompany && lo.lookupId !== parentLookupId ? 'red' : 'black' }">
        {{ lo.name }}
    </option>
</select>
