'use strict';

angular.module('weissmanApp').controller('RedirectCtrl', [
    '$state',
    '$stateParams',
    'processingService',
    'sd.Assessor.Collector.Service',
    'entityTypeID',
    'EntityTypeIDs',
    'EntityTypeNames',
    'sd.Navigation.Service',
    'billClusterService',
    weissNg1Wrap(function (
        $state,
        $stateParams,
        processingService,
        assessorCollectorService,
        entityTypeID,
        EntityTypeIDs,
        EntityTypeNames,
        navigationService,
        billClusterService
    ) {

        navigate(entityTypeID, $stateParams.entityID);

        function navigate(entityTypeID, entityID) {
            if (entityTypeID === EntityTypeIDs.ASSESSOR) {
                assessorCollectorService.getNavigationInfo('assessor', entityID).then(function (info) {
                    $state.transitionTo('assessor', {
                        stateId: info.stateID,
                        id: info.assessorID
                    }, { location: 'replace' });
                });
            }
            else if (entityTypeID === EntityTypeIDs.COLLECTOR) {
                assessorCollectorService.getNavigationInfo('collector', entityID).then(function (info) {
                    $state.transitionTo('collector', {
                        stateId: info.stateID,
                        stateCollectorId: info.collectorID
                    }, { location: 'replace' });
                });
            }
            else if (entityTypeID === EntityTypeIDs.INVOICE) {
                navigationService.getNavigationInfo(EntityTypeNames[EntityTypeIDs.INVOICE], entityID).then(function (info) {
                    var route = 'companyInvoice';
                    var config = {
                        companyID: info.companyID,
                        invoiceID: entityID
                    };
                    if (info.siteID) {
                        route = 'siteInvoice';
                        config.siteID = info.siteID
                    }
                    $state.transitionTo(route, config, { location: 'replace' });
                });
            }
            else if (entityTypeID === EntityTypeIDs.FilingBatch) {
                navigationService.getNavigationInfo('filingBatch', entityID).then(function (info) {
                    const config = {
                        companyId: info.companyID,
                        filingBatchId: entityID
                    };
                    $state.transitionTo('filingBatchDetails', config, { location: 'replace' });
                });
            }
            else if (entityTypeID === EntityTypeIDs.BILL_CLUSTER) {
                billClusterService.getBillClusterByID(entityID).then(function (billCluster) {
                    navigate(EntityTypeIDs.TAX_BILL, billCluster.bills[0].billID);
                });
            }
            else {
                processingService.getEntityTree(entityTypeID, entityID).then(function (tree) {
                    var transitionState = {
                        companyId: tree.subsidiaryCompany.entityId,
                        siteId: tree.site.entityId
                    };
                    switch (entityTypeID) {
                        case EntityTypeIDs.SITE:
                            $state.transitionTo('site', transitionState, { location: 'replace' });
                            break;
                        case EntityTypeIDs.PARCEL:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId
                            }), { location: 'replace' });
                            break;
                        case EntityTypeIDs.ASSESSMENT:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId,
                                annualYearID: tree.annualYear.entityId,
                                annualAssessmentID: tree.revision.entityId
                            }), { location: 'replace' });
                            break;
                        case EntityTypeIDs.APPEAL:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId,
                                annualYearID: tree.annualYear.entityId,
                                annualAssessmentID: tree.revision.entityId,
                                appealID: tree.appeal.entityId
                            }), { location: 'replace' });
                            break;
                        case EntityTypeIDs.TAX_BILL:
                        case EntityTypeIDs.PAYMENT:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId,
                                annualYearID: tree.annualYear.entityId,
                                billClusterID: tree.billCluster.entityId
                            }), { location: 'replace' });
                            break;
                        case EntityTypeIDs.REFUND:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId,
                                annualYearID: tree.annualYear.entityId,
                                refundID: tree.refund.entityId
                            }), { location: 'replace' });
                            break;
                        case EntityTypeIDs.FILING:
                            $state.transitionTo('parcel', _.extend({}, transitionState, {
                                parcelId: tree.parcel.entityId,
                                annualYearID: tree.annualYear.entityId,
                                filingId: tree.filing.entityId
                            }), { location: 'replace' });
                            break;
                        default:
                            throw new Error('Entity type ID invalid or missing (got: ' + entityTypeID + ')');
                    }
                });
            }
        }
    })
]);
