<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Additional Reports"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!rowsSelected">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
            <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</ws-modal-wrapper>
