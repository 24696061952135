<ws-busy-indicator *ngIf="isBusy && !isPageLoading" [zIndexOverride]="1040" title="Preview" message="Working on it..."></ws-busy-indicator>
<div class="ws-flex-none ws-flex-container-horizontal">
    <div *ngIf="canPreview && !returnForm.returnFormRevisionReportId" class="ws-flex-none ws-flex-container-horizontal ws-flex-align-items-center form-actions">
        <div *ngIf="!isReadOnly" class="ws-flex-none ws-flex-align-self-center align-action-text">
            <small>Apply form overrides to:</small>
        </div>
        <div *ngIf="!isReadOnly"
             class="ws-flex-none ws-flex-align-self-center"
             dropdown
             container="body"
             placement="bottom left"
             helpTooltip
             helpContentId="return-preview-form-viewer.apply-override"
             position="left">
            <button id="button-basic" dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle ws-no-margin"
                    aria-controls="dropdown-basic">
                {{ !applyEditsToAll ? 'This form' : 'All selected forms' }}
                <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem">
                    <a class="pointer" (click)="onApplyEditsToAllChange(false)">This form</a>
                </li>
                <li role="menuitem">
                    <a class="pointer" (click)="onApplyEditsToAllChange(true)">All selected forms</a>
                </li>
            </ul>
        </div>
        <div *ngIf="canShowFieldLocations" class="ws-flex-none ws-flex-align-self-center standard-control-separation">
            <label class="ws-flex-none small ws-font-weight-normal ws-checkbox-label"
                   helpTooltip
                   helpContentId="return-preview-form-viewer.show-field-locations"
                   position="bottom">
                <input type="checkbox" [ngModel]="showFieldLocation" (ngModelChange)="setShowFieldLocation($event)"><span>Show Field Locations</span>
            </label>
        </div>
    </div>
    <div class="ws-flex-auto ws-flex-container-horizontal ws-flex-justify-content-flex-end">
        <ws-pdf-controls *ngIf="returnForm && !isError && !isReportEmpty && !isExcelReport"
                         style="border-right: 1px solid #ccc; padding-right: 4px; margin-right: 8px"
                         [zoomPercentage]="zoomPercentage"
                         [zoomScaleUpdating]="zoomScaleUpdating"
                         (zoomChanged)="updateZoomScale($event)">
        </ws-pdf-controls>
        <button type="button"
                class="btn flat-button secondary-button icon-button"
                (click)="download()"
                helpTooltip
                helpContentId="app.download"
                position="bottom"
                *ngIf="canPreview">
            <i class="fa fa-download"></i>
        </button>
        <button type="button"
                class="btn flat-button secondary-button icon-button"
                (click)="refresh()"
                [disabled]="isBusy"
                helpTooltip
                helpContentId="app.refresh"
                position="bottom">
            <i class="fa fa-refresh"></i>
        </button>
    </div>
</div>

<!-- no return form selected, cannot preview PDF -->
<div class="pdf-host-empty" *ngIf="!returnForm">
    <i class="fa fa-file-pdf-o fa-5x"></i>
    <br />
    <br />
    <p class="text-muted">Select a form or report to preview it.</p>
</div>

<!-- error previewing  -->
<div class="pdf-host-empty" *ngIf="isError">
    <i class="fa fa-warning fa-5x"></i>
    <br />
    <br />
    <p class="text-muted">{{(hasTooManyRows ? 'Please change the report output to Excel' : 'An error occurred.')}}</p>
</div>

<!-- return form selected, but the report is empty -->
<div class="pdf-host-empty" *ngIf="isReportEmpty && !isError">
    <i class="fa fa-file-pdf-o fa-5x"></i>
    <br />
    <br />
    <p class="text-muted">The report doesn't contain any data.</p>
</div>

<!-- return form selected, but it is excel -->
<div class="pdf-host-empty" *ngIf="isExcelReport && !isError">
    <i class="fa fa-file-excel-o fa-5x"></i>
    <br />
    <br />
    <p class="text-muted">
        Excel reports can't be previewed.
        <a class="btn btn-link pointer" helpTooltip helpContentId="app.download" position="bottom" (click)="download()">Click here</a> to download the report.
    </p>
</div>

<override-changes [overrideHolder]="overrideHolder"
                  [unsavedOverrides]="formChanges?.overrides"
                  [canPreview]="canPreview"
                  (scrollToElement)="scrollToElement($event)"
                  (saveClicked)="submitPdfChanges()"
                  (resetClicked)="removeUnsavedOverrides()">
</override-changes>

<!-- return form selected; show the PDF preview -->
<div class="pdf-host-render" [hidden]="!canPreview">
    <div class="form-output-wrapper" [ngClass]="{ 'hide-field-location' : !showFieldLocation }">
        <button [ngClass]="{'scroll-button-hidden' : !showPreviousPagesVisible}" id="showPreviousPagesButton" (click)="showPreviousPages()">Loading Previous Pages...</button>
        <div id="return-output" (change)="onPdfFieldChange($event)"></div>
        <button [ngClass]="{'scroll-button-hidden' : !showNextPagesVisible}" id="showNextPagesButton" (click)="showNextPages()">Loading Next Pages...</button>
    </div>
</div>
