import {Injectable} from "@angular/core";
import {BaseRepository} from "../../Common/Data/base.repository";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class ComplianceInfoRepository extends BaseRepository{
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    saveByParcel(parcelId: number, model: Compliance.ComplianceInfoGeneralReturnSettingsReportingSitesSaveModel): Observable<void> {
        return this.httpPost(`/api/ComplianceInfo/Parcel/${parcelId}`, model);
    }

    getDeadlines(): Observable<Compliance.StateDeadlinesModel[]> {
        return this.httpGet('api/ComplianceInfo/Deadlines');
    }
}
