import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Collector } from '../../Assessor-Collector/collector.model';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AccrualDetailModalComponent } from '../Modals/accrual-detail.component';
import { BillCluster } from './bill-cluster.model';
import { BillClusterService, BillViewModel, RefundViewModel, TaxesViewModel } from './bill-cluster.service';
import { Refund } from './refund.model';

declare const _: any;

const TAXBILL_ENTITY_TYPE_ID = 9;
const REFUND_ENTITY_TYPE_ID = 14;

@Component({
	selector: 'taxes',
	templateUrl: './taxes.component.html'
})
export class TaxesComponent implements OnInit {
	@Input() currentYear?: AnnualDetailYear;
	@Input() editState: AnnualDetailEditState;
	@Input() hasCompanyCollectors: boolean;
	@Input() viewModel: TaxesViewModel;
	@Input() parcelID: number;
	@Input() gridNavigationHandler: () => void;
	@Output() AnnualDetailsSaveAllowedChangedX: EventEmitter<boolean> = new EventEmitter<boolean>();

	waitingOnServer: boolean;
	isAddBillModalShown: boolean;
	isStateCollectorsShown: boolean = true;
	parcelCollectors: Collector[] = [];
	companyCollectors: Collector[];
	billOrRefund: any;
	@ViewChild('addBillModal') addBillModal: ModalDirective;

	constructor(private billClusterService: BillClusterService,
                private http: SDHttpService,
                private annualDetailsService: AnnualDetailsService,
				private navigationEvent: AnnualDetailsNavigationEventService,
				private modalService: BsModalService,
				private toastr: ToastrService) {
		this.isAddBillModalShown = false;
		this.billOrRefund = {};
	}

	ngOnInit(): void {
		this.viewModelChanged();

        this.navigationEvent.refreshAccrualDetailsLinkEvent$.forEach((event: boolean) => {
			// This will toggle the visibility of the Accrual Details link
			// based on whether all Payments are excluded from Accrual or not.
			this.viewModel.currentTab.allPaymentsExcludedFromAccrual = event;
        });
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Look for changes to the viewModel input property
		if (Object.keys(changes).indexOf('viewModel') >= 0) {
			this.viewModelChanged();
		}
	}

    annualDetailsSaveAllowedChanged(isAnnualDetailsSaveAllowed: boolean): void {
		this.AnnualDetailsSaveAllowedChangedX.emit(isAnnualDetailsSaveAllowed);
    }

	viewModelChanged(): void {
		this.editState.showEditControls = this.viewModel && this.viewModel.model.length > 0;
	}

	getBillVM(tab: BillCluster): BillViewModel {
		const billVM = new BillViewModel();

		billVM.model = tab.bills;
		billVM.parcelID = this.viewModel.parcelID;
		billVM.parcelAcctNum = this.viewModel.parcelAcctNum;
		billVM.tabTitle = tab.title || tab.collector.abbr;
		billVM.hasWritePermission = this.editState.hasWritePermission;
		billVM.year = this.viewModel.annualYearModel.annualYear1;
		billVM.yearRevisions = this.currentYear.annualGridDetails;
        billVM.taskSummariesPromise = tab.taskSummariesPromise;
        billVM.hasAvailableTaxSetup = tab.bills[0].collectorPaymentOptionID && tab.hasAvailableTaxSetup;
        billVM.model.forEach(m => m.billClusterRowVersion = tab.rowVersion);

		return billVM;
	}

	getRefundVM(tab: BillCluster) {
		const refundVM = new RefundViewModel();

		refundVM.model = tab.refund;
		refundVM.parcelID = this.viewModel.parcelID;
		refundVM.parcelAcctNum = this.viewModel.parcelAcctNum;
		refundVM.tabTitle = tab.title;
		refundVM.hasWritePermission = this.editState.hasWritePermission;
		refundVM.year = this.viewModel.annualYearModel.annualYear1;

		return refundVM;
	}

	// Need to catch this event in Angular 2

	// scope.$on('entityTaskUpdated', function () {
	// 	if (scope.active.billTab) {
	// 		getTaskSummaries(scope.active.billTab);
	// 	}
	// });


	// scope.$watch('active.billTab', function (newVal, oldVal) {
	// 	function maybeBill() {
	// 		if (oldVal.bills > 0) {
	// 			return oldVal.calcProjected === newVal.calcProjected && oldVal.bills[0].collectorPaymentOptions
	// 		}
	// 		return true;
	// 	}

	// 	if (oldVal && newVal && oldVal.billClusterID === newVal.billClusterID && oldVal.updated === newVal.updated && maybeBill()) {
	// 		scope.active.billTab.updated = true;
	// 	}
	// }, true)

	async addBill(): Promise<void> {
		this.parcelCollectors = await this.getStateCollectors();

		if(this.parcelCollectors.length) {
			this.billOrRefund.collectorID = this.parcelCollectors[0].collectorID;
		} else {
			this.isStateCollectorsShown = false;
			this.companyCollectors = await this.getCompanyCollectors();

			if(this.companyCollectors.length) {
				this.billOrRefund.collectorID = this.companyCollectors[0].collectorID;
			} else {
				this.toastr.warning('This parcel has no associated governmental or company collectors.', 'Cannot Add Bill');
				return Promise.resolve();
			}
		}

		this.billOrRefund.type = 'bill';
		this.isAddBillModalShown = true;
	}

    async getCollectorList(): Promise<void> {
	    if(!this.isStateCollectorsShown) {
	        this.companyCollectors = await this.getCompanyCollectors();
			if(this.companyCollectors.length) {
				this.billOrRefund.collectorID = this.companyCollectors[0].collectorID;
			}
        } else {
	        this.parcelCollectors = await this.getStateCollectors();
			if(this.parcelCollectors.length) {
				this.billOrRefund.collectorID = this.parcelCollectors[0].collectorID;
			}
        }
    }

    async getCompanyCollectors(): Promise<Collector[]> {
        const results = await this.http.get(`/api/collector/AnnualYear/${  this.currentYear.annualYearID}`);
		return _.sortBy(results, (collector) => collector.abbr.toLowerCase());
    }

    async getStateCollectors(): Promise<Collector[]> {
        const results = await this.http.get('/api/collectorassociation', { params: { parcelid: this.parcelID } });
		return _.sortBy(results, (collector) =>  collector.collector.abbr.toLowerCase());
    }

	onHidden(): void {
		this.isAddBillModalShown = false;
	}

	saveBillOrRefund() {
		const newBillCluster = new BillCluster(this.currentYear.annualYearID, this.billOrRefund.collectorID);

		if (this.billOrRefund.type == 'refund') {
			newBillCluster.refund = new Refund(this.currentYear.annualYearID, this.billOrRefund.collectorID);
		}

		// Weird to do a PUT for a new bill cluster, but here we are.
		// Consider refactoring
		this.waitingOnServer = true;
		this.billClusterService.saveTab(newBillCluster, true)
			.then((tab) => {
				this.addBillModal.hide();
				this.navigationEvent.refreshActivityData();

				this.reloadVM(tab.billClusterID);
			})
			.catch(() => {
				this.waitingOnServer = false;
			});
	}

	reloadVM(billClusterID?: number) {
		this.waitingOnServer = true;

		this.billClusterService.getTaxesViewModelByYear(this.currentYear, this.viewModel.parcelID, this.viewModel.parcelAcctNum)
			.then((viewModel: TaxesViewModel) => {
				_.assign(this.viewModel, viewModel);

				let index: number = this.viewModel.model.length -1;

				if(billClusterID) {
					index = _.findIndex(this.viewModel.model, {billClusterID: billClusterID});
				}

				this.billClusterService.setTabIndex(this.viewModel, index);

				this.waitingOnServer = false;
				this.editState.entityLoading = false;
			})
			.catch(() => {
				this.waitingOnServer = false;
                this.editState.entityLoading = false;
			});
	}

	cancelAddBillOrRefund() {
		this.addBillModal.hide();
	}

	switchTabs(viewModel: TaxesViewModel, i: number) {
		if (i !== viewModel.currentTabIndex) {
			this.annualDetailsService.preNavigateWarn(this.editState).then(() => {
				this.billClusterService.setTabIndex(viewModel, i);
			}).catch(() => {
				this.viewModel.model.forEach((t, index) => {
					t.active = index === viewModel.currentTabIndex;
				});
			});
		}
	}

	async launchAccrualDetailModal(billCluster: BillCluster) {
		if (this.editState.editMode && this.editState.getDirty()) {
			await this.saveBillCluster();
		}

		const initialState = {
			editMode: this.editState.editMode,
			billClusterId: billCluster.billClusterID
        };

        const modalRef = this.modalService.show(AccrualDetailModalComponent, {initialState, class: 'modal-xl', ignoreBackdropClick: true});
	}

	async saveBillCluster(callback?: Function) {
		this.editState.entityLoading = true;
		const result: BillCluster = await this.billClusterService.saveTabFromViewModel(this.viewModel);

		this.toastr.info('Bill changes autosaved');

		if (this.viewModel.currentTab.refund) {
			this.viewModel.currentTab.refund.refundRowVersion = result.refundRowVersion;
		}
		else if (this.viewModel.currentTab.billClusterID) {
			this.viewModel.currentTab.rowVersion = result.rowVersion;
			this.viewModel.currentTab.bills = _.map(this.viewModel.currentTab.bills, bill => {
				const savedBill = _.find(result.bills, {billID: bill.billID});

				if(savedBill) {
					bill.rowVersion = savedBill.rowVersion;
				}

				return bill;
			});
		}

		this.navigationEvent.refreshActivityData();
		this.navigationEvent.refreshAnnualYear();
		this.editState.setDirty(false);
		this.viewModel.resetEdit(true);

		this.editState.entityLoading = false;

		if(callback) {
			callback();
		}
	}
}
