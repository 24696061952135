import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ValuationTemplateService } from '../valuationTemplate.service';
import { UserInstanceService } from '../../../User/userInstance.service';

export interface AddEditValuationTemplateModalParams {
    template?: Core.ValuationTemplateModel;
}

@Component({
    selector: 'add-edit-valuation-template',
    templateUrl: './addEditValuationTemplate.component.html'
})
export class AddEditValuationTemplateComponent implements OnInit, IWeissmanModalComponent<AddEditValuationTemplateModalParams, Core.ValuationTemplateModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _fb: UntypedFormBuilder,
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _userInstanceService: UserInstanceService
    ) {}

    params: AddEditValuationTemplateModalParams;
    result: Core.ValuationTemplateModel;

    isBusy: boolean;
    templateForm: UntypedFormGroup;
    errorMessage: string;

    ngOnInit(): void {
        this.templateForm = this._fb.group({
            name: ['', Validators.required],
            description: ['']
        });

        if (this.params.template) {
            this.templateForm.patchValue({
                name: this.params.template.name,
                description: this.params.template.description
            });
        }
    }

    async save(): Promise<void> {
        this.isBusy = true;

        try {
            const {name, description} = this.templateForm.getRawValue();

            let model: any = {};
            if (this.params.template) {
                model = { ...this.params.template };
            }

            model.name = name;
            model.description = description || null;
            model.instanceId = this._userInstanceService.getSelectedInstance().instanceId;

            this.result = await this._valuationTemplateService.addOrUpdateTemplate(model);

            if (this.params.template) {
                this._valuationTemplateService.template = this.result;
            }

            this.cancel();
        } catch(err) {
            this.errorMessage = err.error.message;
        } finally {
            this.isBusy = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
