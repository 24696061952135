<ws-modal-wrapper headerLabel="Additional information is required to properly map {{params.displayName}} field"
                  (save)="onAccept()"
                  (cancel)="onCancel()"
                  [disableSave]="!result">
    <div>
        <span>{{params.clarificationFields[0].displayName}}</span>
        <ws-select [formControl]="selectedOption"
                   [options]="options"
                   [isPrimitive]="true">
        </ws-select>
    </div>
</ws-modal-wrapper>
