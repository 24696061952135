import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { SmartSearchService } from '../../../Search/Advanced/smartSearch.service';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { SearchCategoryType, UserSaveService } from '../userSave.service';
import {
    ContextMenuItem,
    SaveCategory,
    SaveListItem,
    SaveTabColumn,
    SaveTabColumnType,
    UserSavePage
} from '../userSavePage.model';

interface CategoryContextMenuItem {
    name: string;
    onClick: (category: SaveCategory<any>) => void;
}

@Component({
    selector: 'save-tab-content',
    templateUrl: './saveTabContent.component.html',
    styleUrls: ['./saveTabContent.component.scss']
})
export class SaveTabContentComponent implements OnInit, OnDestroy {
    constructor(private readonly _userSaveService: UserSaveService,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _smartSearchService: SmartSearchService,
                private readonly _messageModalService: MessageModalService) {
    }

    @Input() userPageConfig: UserSavePage<any>;

    tabTitle: string;
    columns: SaveTabColumn[] = [];
    columnType = SaveTabColumnType;

    selectedFilter: SearchCategoryType;
    searchCategoryTypes = SearchCategoryType;
    selectedCategory: SaveCategory<any>;
    categories: SaveCategory<any>[] = [];
    loadingRowMenu: boolean;
    categoryMenuOptions: CategoryContextMenuItem[] = [
        { name: 'Delete', onClick: this._deleteCategory.bind(this) }
    ];
    rowMenuOptions: ContextMenuItem<any>[] = [];
    hoveredRow: SaveListItem<any>;

    private _destroy$: Subject<void> = new Subject<void>();

    get categoryTotal(): number {
        return this.categories?.reduce((acc, curr) => acc + curr.savedItemList.length, 0);
    }

    ngOnInit(): void {
        this.selectedFilter = this.userPageConfig.currentSelectedFilter;
        this.categories = this.userPageConfig.categories;
        this.columns = this.userPageConfig.columns;

        if (!this.selectedCategory) {
            this._checkSelectedCategory();
        }

        this._userSaveService.selectedUserSavePage$.pipe(takeUntil(this._destroy$)).subscribe((page) => {
            if (!this.userPageConfig) {
                this.userPageConfig = page;
            }

            if (page) {
                this.selectedFilter = page.currentSelectedFilter;
                this.categories = page.categories;
                this._checkSelectedCategory();
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    selectCategory(category: SaveCategory<any>): void {
        this.selectedCategory = category;
        // Save to service for returning to page
        this.userPageConfig.currentSelectedCategory = category;
    }

    selectRow(row: SaveListItem<any>): void {
        if (row.amplitudeEvent) {
            this._productAnalyticsService.logEvent(row.amplitudeEvent.event, row.amplitudeEvent.properties);
        }
        this.userPageConfig.navigateTo(row, this.selectedCategory.categoryType);
    }

    async toggleFavorite(row: SaveListItem<any>): Promise<void> {
        row.isFavorite = !row.isFavorite;
        this.userPageConfig.refreshLocalList();
        this.categories = this.userPageConfig.categories;
        this._checkSelectedCategory();
        await this.userPageConfig.toggleFavorite(row);
        await this._userSaveService.refreshCurrentList();
    }

    hasMenuOptions(category: SaveCategory<any>, index: number, row: SaveListItem<any>): boolean {
        return this.userPageConfig.contextMenu(category, index)
            .filter(x => !x.isDisabled(row)).length > 0;
    }

    getMenuOptions(isOpen: boolean, category: SaveCategory<any>, index: number): void {
        if (isOpen) {
            this.loadingRowMenu = true;
            this.rowMenuOptions = this.userPageConfig.contextMenu(category, index);
            this.loadingRowMenu = false;
        }
    }

    private _checkSelectedCategory(): void {
        if (this.userPageConfig.currentSelectedCategory) {
            const categoryId = this.userPageConfig.currentSelectedCategory.categoryId;
            const category = this.categories.find((c) => c.categoryId === categoryId);
            if (category) {
                this.selectCategory(category);
            } else {
                this.selectCategory(this.categories[0]);
            }
        } else {
            this.selectCategory(this.categories[0]);
        }
    }

    private async _deleteCategory(category: SaveCategory<any>): Promise<void> {
        try {
            await this._messageModalService.confirm(`Are you sure you want to delete ${  category.categoryName  }?`);
        } catch (e) {
            return;
        }

        if (category.savedItemList.length > 0) {
            await this._messageModalService.alert(`The search category ${  category.categoryName  } is currently not empty. Please make sure the category is empty and try again.`);
            return;
        }

        await this._smartSearchService.deleteSearchCategory(category);
        await this._userSaveService.refreshCurrentList();
    }
}
