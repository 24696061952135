<table *ngIf="!isSameDateSamePerson">
    <tr *ngFor="let sentInfo of sentInfos">
        <td class="header">Site:</td>
        <td style="white-space: nowrap">{{getValue(sentInfo, 'siteName', 20)}},</td>
        <td class="header">Parcel:</td>
        <td>{{getValue(sentInfo, 'parcelAccountNumber', 20)}},</td>
        <td class="header">By:</td>
        <td>{{getUserName(sentInfo, 15)}},</td>
        <td class="header">Sent:</td>
        <td>{{sentInfo.sentDate | date: 'MM/dd/yyyy'}}</td>
    </tr>
</table>
<div *ngIf="isSameDateSamePerson">
    All parcels sent by this user on date indicated.
</div>
