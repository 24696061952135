<ws-modal-wrapper [headerLabel]="headerLabel" acceptLabel="Save" (save)="save()" (cancel)="cancel()"
    [disableSave]="!isSaveable()">
    <div class="ws-flex-container-vertical ws-stretch">
        <table>
            <tbody>
                <tr>
                    <td>
                        <label>Action:</label>
                    </td>
                    <td style="padding: 10px">
                        <div>
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;"
                                [(ngModel)]="editReq.operation">
                                <option *ngFor="let options of editOptions" [ngValue]="options.value">{{options.name}}
                                </option>
                            </select>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="editReq.operation == ConvertTrueUpToBeforeAdjustment">
                    <td></td>
                    <td style="padding: 10px">
                        <div style="display: flex;">
                            <input type="text"
                                class="form-control"
                                [(ngModel)]="editReq.adjustmentDescription"
                                style="width: auto;min-width:250px;"
                                placeholder="Enter Adjustment Description...">
                        </div>
                    </td>
                </tr>
                <tr *ngIf="editReq.operation == OverrideReportedChangeReason">
                    <td></td>
                    <td style="padding: 10px">
                        <div style="display: flex;">
                            <select class="form-select form-select-sm" style="width: auto; display: inline-block;"
                                [(ngModel)]="editReq.reportedChangeReasonAction">
                                <option *ngFor="let option of commonBulkUpdateFieldActions" [ngValue]="option.value">{{option.name}}</option>
                            </select>
                            <input *ngIf="editReq.reportedChangeReasonAction == ChangeTo"
                                type="text"
                                class="form-control"
                                [(ngModel)]="editReq.reportedChangeReason"
                                style="width: 300px; margin-left: 25px;"
                                placeholder="Enter Change Reason...">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</ws-modal-wrapper>
