import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

export function WsUniqueYearValidator(yearControls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean }| null => {
        const values: number[] = yearControls.map(x => x.value);
        if (_.uniq(values).length !== values.length) {
            return { 'wsUniqueYear': true };
        }
        return null;
    }
}
