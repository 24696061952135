
import {debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

/**
 * An interface for all handlers acquiring the lock for a resource group.
 */
export interface IMutexServiceHandler {
    /**
     * A method that each handler must implement in order to support releasing the lock for a resource group when requested.
     */
    wsMutexRelease: (groupId: string) => Promise<void>;
}

export interface IWeissmanMutexService {
    lockChanged$: Observable<{ [groupId: string]: boolean }>;
    acquire: (handler: IMutexServiceHandler, groupId: string) => Promise<boolean>;
    canAcquire: (groupId: string) => boolean;
    release: (handler: IMutexServiceHandler, groupId: string) => void;
}

/**
 * A services used as a mutex to ensure access to a resource group one handler at a time.
 */
@Injectable()
export class WeissmanMutexService implements IWeissmanMutexService {
    constructor() {}

    private _registrations: { [groupId: string]: IMutexServiceHandler } = {};

    private _lockChangedSubject = new BehaviorSubject<{ [groupId: string]: boolean }>({});

    /**
     * An observable notifying the state of the locks.
     * It publishes an object containing the group names as the keys and a boolean value for each key indicating if a lock for that group is acquired or not.
     */
    get lockChanged$(): Observable<{ [groupId: string]: boolean }> {
        return this._lockChangedSubject.pipe(debounceTime(250));
    }

    /**
     * Tries to acquire a resource group under for the specified handler.
     * If the resource group is locked under another handler, the handler currently holding the lock will have to decide whether to unlock the resource or not.
     * @param handler The handler that is trying to acquire the lock.
     * @param groupId The name of the resource group that the handler is trying to lock.
     */
    async acquire(handler: IMutexServiceHandler, groupId: string): Promise<boolean> {
        const existingHandler = this._registrations[groupId];
        if (existingHandler) {
            try {
                await existingHandler.wsMutexRelease(groupId);
            } catch(e) {
                return false;
            }
        }
        this._registrations[groupId] = handler;
        this._publishLockState();
        return true;
    }

    /**
     * Checks to see if a resource group is unlocked and a lock can be acquired for it.
     * @param groupId The name of the resource group to check.
     */
    canAcquire(groupId: string): boolean {
        const existingHandler = this._registrations[groupId];
        return existingHandler === undefined || existingHandler === null;
    }

    /**
     * Releases a resource group.
     * @param handler The handler of the resource group.
     * @param groupId The name of the resource group to release.
     */
    release(handler: IMutexServiceHandler, groupId: string): void {
        const existingHandler = this._registrations[groupId];
        if (handler === existingHandler) {
            delete this._registrations[groupId];
        }
        this._publishLockState();
    }

    private _publishLockState(): void {
        const lockState = {};
        Object.keys(this._registrations).forEach(x => lockState[x] = !!this._registrations[x]);
        this._lockChangedSubject.next(lockState);
    }
}