<ws-modal-wrapper [headerLabel]="modalHeader"
                  (save)="save()"
                  (cancel)="cancel()">
    <span>Roles:</span>
    <ws-picklist labelProperty="roleDesc"
                 valueProperty="contactRoleID"
                 placeholder="Please select a role..."
                 [canReorganize]="false"
                 [canRemove]="!isLastAssociatedRole"
                 [options]="availableRoles"
                 [(ngModel)]="associatedRoles">
    </ws-picklist>
</ws-modal-wrapper>
