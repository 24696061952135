import { Component, OnInit } from '@angular/core';
import { HelpContentComponent, HelpService } from '../../../../../UI-Lib/Help-Tooltip';

export interface DynamicFieldBehaviorHelpComponentParams {
    options: Compliance.ImportDynamicFieldOptionModel[];
}

@Component({
    selector: 'dynamic-field-behavior-help',
    templateUrl: './dynamicFieldBehaviorHelp.component.html',
})
export class DynamicFieldBehaviorHelpComponent implements HelpContentComponent<DynamicFieldBehaviorHelpComponentParams> {
    constructor(private readonly _helpService: HelpService) { }

    params: DynamicFieldBehaviorHelpComponentParams;
    options: Compliance.ImportDynamicFieldOptionModel[];

    helpInit(params: DynamicFieldBehaviorHelpComponentParams): void {
        if (params) {
            this.options = params.options;
        }

        this._helpService.refresh();
    }
}
