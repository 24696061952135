export class UserTeam {
    userTeamID: number;
    userID: string;
    teamID: number;
    isDefault: boolean;
    isAssignable: boolean;
    numberOfClientServiceResponsibilities: number;
}

export class Team {
    teamID: number;
    name: string;
    practiceArea: string;
    costCenter: string;
    teamMembers: UserTeam[];

    constructor() {
        this.practiceArea = '';
    }
}
