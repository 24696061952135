<ws-modal-wrapper headerLabel="Expense Detail" cancelLabel="Close" [hideSave]="true" (cancel)="close()">
    <div class="text-center loading-all" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading && accrualDetail && accrualDetail.accrualDetailRows.length === 0" class="h2" style="text-align: center;">
        No accrual details to show
    </div>
    <div *ngIf="!loading && accrualDetail && accrualDetail.accrualDetailRows.length > 0" class="accrual-detail">
        <div style="display: flex; justify-content: space-between;">
            <div class="first-column">
                <div>
                    <label>Collector:</label>
                    <span>{{accrualDetail.collector}}</span>
                </div>
                <div>
                    <label>Bill Type:</label>
                    <span>{{accrualDetail.isRecurring ? 'Recurring' : 'One Time'}}</span>
                </div>
                <div>
                    <label>Amount:</label>
                    <span>{{accrualDetail.taxAmount | currency: 'USD':'symbol-narrow'}}</span>
                </div>
            </div>
            <div class="second-column">
                <div style="display: flex;">
                    <label>Tax Period:</label>
                    <weissman-datetime [readOnly]="true" [(inputDate)]="accrualDetail.taxPeriodBeginDate" [dateOnly]="true">
                    </weissman-datetime> - <weissman-datetime [readOnly]="true" [(inputDate)]="accrualDetail.taxPeriodEndDate"
                        [dateOnly]="true"></weissman-datetime>
                </div>
                <div *ngIf="accrualDetail.accrualsBeginDate" style="display: flex;">
                    <label>Accruals Begin:</label>
                    <weissman-datetime [(inputDate)]="accrualDetail.accrualsBeginDate" [dateOnly]="true" [readOnly]="true"></weissman-datetime>
                </div>
            </div>
        </div>

        <div class="overflow-x-auto">
            <table class="table table-condensed accrual-detail-table" *ngIf="accrualDetail">
                <thead>
                <tr>
                    <th style="text-align: left;">Acct. Period</th>
                    <th style="text-align: center;">Duration</th>
                    <th>Tax Amount</th>
                    <th>Base Expense</th>
                    <th style="width: 111px;">True Up (Dn)</th>
                    <th>Period Expense</th>
                    <th>Total Expensed</th>
                    <th>Remaining</th>
                    <th>Payment Amt</th>
                    <th *ngIf="showBalances">Prepaid Balance</th>
                    <th *ngIf="showBalances">Payable Balance</th>
                    <th *ngIf="editMode"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of accrualDetail.accrualDetailRows; let i=index" [class.not-actual-no-hover]="row.isProjected"
                    (mouseenter)="row.isHovered = true"
                    (mouseleave)="row.isHovered = false;">
                    <td style="text-align: left;">{{row.accountingPeriodName}}</td>
                    <!-- regular row -->
                    <td style="text-align: center;" *ngIf="!row.isReconcileRow" helpTooltip [tooltipText]="getDateRange(row)" position="top">{{row.durationText}}</td>
                    <td *ngIf="!row.isReconcileRow"><parenthetical-currency [value]="row.taxAmount"></parenthetical-currency></td>
                    <td *ngIf="!row.isReconcileRow"><parenthetical-currency [value]="row.baseExpense"></parenthetical-currency></td>
                    <!-- ----- -->
                    <!-- adjustment row -->
                    <td colspan="3" *ngIf="row.isReconcileRow" style="text-align: left;">
                        <span *ngIf="!(row.isEditAdjustment || row.isAddAdjustment)">Adjustment - {{row.reconcileDescription}}</span>
                        <input type="text" class="form-control" *ngIf="row.isEditAdjustment || row.isAddAdjustment" maxlength="100"
                               [(ngModel)]="row.reconcileDescription" placeholder="Adjustment Description">
                    </td>
                    <!-- ----- -->
                    <td [class.overridden]="row.trueUpOverridden" style="position: relative; width: 111px;">
                        <parenthetical-currency *ngIf="!(row.isEditAdjustment || row.isAddAdjustment || row.isEditOverridden)" [value]="row.trueUp"></parenthetical-currency>
                        <button class="flat-button icon-button warning-button" (click)="revertTrueUp(row)"
                                style="margin: 0; position:absolute; top: 5px; right: -25px; bottom: 5px;"
                                *ngIf="row.isHovered && row.trueUpOverridden && !currentlyEditing && editMode">
                            <i class="fa fa-undo"></i>
                        </button>
                        <input type="text" class="form-control text-end" style="display: inline-block; width: 100px"
                               *ngIf="row.isEditAdjustment || row.isAddAdjustment || row.isEditOverridden" [(ngModel)]="row.trueUp" [min]="0"
                               currencyMask [options]="{ allowNegative: true, precision: 2 }">
                    </td>
                    <td><parenthetical-currency [value]="row.periodExpense"></parenthetical-currency></td>
                    <td><parenthetical-currency [value]="row.totalExpensed"></parenthetical-currency></td>
                    <td><parenthetical-currency [value]="row.remaining"></parenthetical-currency></td>
                    <td><parenthetical-currency [value]="row.paymentAmount"></parenthetical-currency></td>
                    <td *ngIf="showBalances"><parenthetical-currency [value]="row.prepaidBalance"></parenthetical-currency></td>
                    <td *ngIf="showBalances"><parenthetical-currency [value]="row.payableBalance"></parenthetical-currency></td>
                    <td *ngIf="editMode">
                        <div class="btn-group" *ngIf="!row.isReconcileRow && !row.isEditOverridden" dropdown
                             [class.invisible]="!row.isHovered || currentlyEditing">
                            <button id="button-basic" dropdownToggle type="button" class="flat-button small-button dropdown-toggle"
                                    style="margin: 0;" (click)="setupRowMenu(row, i)"
                                    aria-controls="dropdown-basic">
                                <i class="fa fa-ellipsis-h"></i>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem" *ngFor="let item of rowMenuItems" [class.disabled]="item.value == AccrualRowActionEnum.NoAction"><a
                                    class="dropdown-item pointer" (click)="performRowAction(row, item, i)">{{item.name}}</a></li>
                            </ul>
                        </div>
                        <div style="white-space: nowrap;" *ngIf="row.isEditAdjustment || row.isAddAdjustment || row.isEditOverridden">
                            <button class="flat-button small-button"
                                    [disabled]="row.trueUp == null || (row.isReconcileRow && !row.reconcileDescription)"
                                    style="margin: 0;"
                                    (click)="saveAdjustment(row, i)">
                                <i class="fa fa-save"></i>
                            </button>
                            <button class="flat-button small-button warning-button"
                                    style="margin: 0;"
                                    (click)="cancelAdjustment(row)">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                        <div style="white-space: nowrap;" *ngIf="row.isReconcileRow && !currentlyEditing" [class.invisible]="!row.isHovered">
                            <button class="flat-button small-button" style="margin: 0;" (click)="editAdjustment(row)">
                                <i class="fa fa-pencil"></i>
                            </button>
                            <button class="flat-button small-button warning-button" style="margin: 0;" (click)="deleteAdjustment(row, i)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; align-items: center;">
                <label style="margin-bottom: 0; margin-right: 5px;">Adjustments:</label>
                <select class="form-select form-select-sm" [(ngModel)]="accrualDetail.accrualAdjustmentMethod" (change)="updateAccrual()" [disabled]="!editMode">
                    <option [ngValue]="2">Amortize</option>
                    <option [ngValue]="1">Single Period</option>
                </select>
            </div>
            <div style="display: flex; flex-direction: column;">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="accrualDetail.fullyExpenseNext" (change)="updateAccrual()" [disabled]="!editMode"> Fully expense in next
                        available accounting period
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="showBalances" (change)="updateShowBalances()">
                        Show Accrual Balances
                    </label>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
