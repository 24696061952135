<table class="table table-responsive grid annual-details-side-panel" *ngIf="currentAnnualDetailAssessment !== undefined" style="max-width: 500px;">
    <thead class="years">
        <tr style="height: 35px;">
            <th colspan="4">{{currentYear?.annualYear1}}</th><!--[data-sd-active-annual-yecurrentYeardYear.annualYear1'>-->
        </tr>
    </thead>
    <tbody class="details">
        <!-- Controls Row -->
        <tr *ngIf="consolidatingTypeId !== 2">
            <td colspan="3">
                <button
                    class="flat-button small-button"
                    *ngIf="navigationState.editMode && navigationState.viewTarget == ViewTarget.assessment && (currentAnnualDetailAssessment.status === 1 || !currentYear.calcProjected || !currentAnnualDetailAssessment.annualAssessmentID)"
                    (click)="activateAddRevision()">Add Revision</button>

                <select class="form-select form-select-sm" [disabled]="navigationState.editMode" [ngModel]="currentAnnualDetailAssessment" (ngModelChange)="selectRev($event)" style="float:right; max-width: 160px; margin-top:3px; margin-right: -7px;">
                    <!-- ng-options="rev as rev.revisionDesc for rev in active.year.annualGridDetails"-->
                    <option *ngFor="let rev of currentYear.annualGridDetails" [ngValue]="rev">{{ rev.revisionDesc }}</option>
                </select>
            </td>
        </tr>
        <!-- Compliance Row -->
        <tr *ngIf="currentYear.complianceFilingTypeSummaries && currentYear.complianceFilingTypeSummaries.length > 0">
            <td class="heading" style="border:none;">
                Comp
            </td>
            <td class="heading" style="border:none;">
                <div style="white-space: nowrap" *ngFor="let filingType of currentYear.complianceFilingTypeSummaries">
                    {{ getComplianceFilingType(filingType.filingTypeId) }}
                </div>

            </td>
            <td class="clickable" style="padding-right: 8px;border:none;">
                <div class="lines-active">
                    <div (click)="activateViewTarget(ViewTarget.compliance)">
                        <div *ngFor="let filingType of currentYear.complianceFilingTypeSummaries" [innerHtml]="annualDetailsService.getComplianceDisplay(filingType.filingTypeId, currentYear.complianceFilingTypeSummaries)">
                        </div>
                    </div>
                </div>
            </td>
        </tr>

        <!-- Assessment Row -->
        <tr>
            <td class="heading" [class.not-actual-heading]="notActual()">
                Asmts
            </td>
            <td class="heading" [class.not-actual-heading]="notActual()">
                <div *ngFor="let comp of annualDetailsService.nonCapComponents(currentAnnualDetailAssessment?.annualGridComponents, componentTypes)">{{annualDetailsService.getComponentName(comp)}}<!-- | orderBy:compPos | filter:{efAction:'!delete'}" data-sd-assessment-component-name="{{getCompName(comp)}}">{{getCompName(comp)}}</div>--></div>
                <div class="sum-label">Total FMV:</div>
                <div class="cap-component" *ngFor="let comp of annualDetailsService.capComponents(currentAnnualDetailAssessment.annualGridComponents, componentTypes)">{{ annualDetailsService.getComponentName(comp) }}</div>
            </td>
            <td class="clickable" [class.not-actual-heading]="notActual()" style="padding-right: 8px;" [style.cursor]="notAllowedIfConsolidated">
                <div class="lines-active" [style.cursor]="notAllowedIfConsolidated">
                    <div (click)="activateViewTarget(ViewTarget.assessment)">
                        <div *ngFor="let comp of annualDetailsService.nonCapComponents(currentAnnualDetailAssessment?.annualGridComponents, componentTypes)"><!-- | orderBy:compPos | filter:{efAction:'!delete'}" data-sd-assessment-component-name="{{getCompName(comp)}}" data-sd-assessment-component-fmv>-->
                            {{comp.fairMarketValue | number:'1.0-0'}}
                        </div>
                        <div class="sum-figure">{{
                            annualDetailsService.getTotalFMV(currentAnnualDetailAssessment?.annualGridComponents) |
                            number:'1.0-0'}}</div><!-- [data-sd-annual-year-fmv] -->
                        <div class="cap-component" *ngFor="let comp of annualDetailsService.capComponents(currentAnnualDetailAssessment?.annualGridComponents, componentTypes)">{{ comp.fairMarketValue | number: '1.0-0'}}</div>
                    </div>
                </div>
            </td>
        </tr>

        <!-- Appeal Row -->
        <tr>
            <td class="heading">
                Appeals
            </td>
            <td class="heading">
                <div>Resolution:</div>
                <div>Savings:</div>
            </td>
            <td class="clickable" [style.cursor]="notAllowedIfConsolidated">
                <div class="active.year-appeal-data"
                         (click)="activateViewTarget(ViewTarget.appeal)"
                         [class.strike-thru-savings]="navigationState.staleAppealSavings"
                         [class.strike-thru-status]="navigationState.staleAppealStatus">
                    <div *ngIf="currentYear.annualGridDetails.length < 2" class="revision" [style.cursor]="notAllowedIfConsolidated">
                        <div class="appeals-status">
                            <span *ngIf="currentYear.annualGridDetails[0].appealStatus !== 'Details'">{{currentYear.recalculatedStatus || currentYear.annualGridDetails[0].appealStatus}}</span>
                            <a *ngIf="currentYear.annualGridDetails[0].appealStatus === 'Details'" style="cursor: pointer">{{currentYear.recalculatedStatus || currentYear.annualGridDetails[0].appealStatus}}</a>
                        </div>
                        <div class="appeals-savings" [class.invisible]="!currentYear.annualGridDetails[0].savings">{{annualDetailsService.formatSavings(currentYear.recalculatedSavings) || annualDetailsService.formatSavings(currentYear.annualGridDetails[0].savings)}}</div>
                    </div>
                    <div *ngIf="currentYear.annualGridDetails.length >= 2" class="revision" [style.cursor]="notAllowedIfConsolidated">
                        <div class="appeals-status">
                            <span *ngIf="currentYear.collapsedAppealStatus !== 'Details'">{{currentYear.recalculatedStatus || currentYear.collapsedAppealStatus}}</span>
                            <a *ngIf="currentYear.collapsedAppealStatus === 'Details'" style="cursor: pointer">{{currentYear.recalculatedStatus || currentYear.collapsedAppealStatus}}</a>
                        </div>
                        <div class="appeals-savings" [class.invisible]="!currentYear.collapsedSavings">
                            <span *ngIf="currentYear.recalculatedSavings">{{annualDetailsService.formatSavings(currentYear.recalculatedSavings)}}</span>
                            <span *ngIf="!currentYear.recalculatedSavings && currentYear.collapsedSavings">{{ annualDetailsService.formatSavings(currentYear.collapsedSavings)}}</span>
                        </div>
                    </div>
                </div>
            </td>
        </tr>

        <!-- Tax Row -->
        <tr>
            <td class="heading" [class.not-actual]="notReceived()">
                Taxes
            </td>
            <td class="heading" [class.not-actual]="notReceived()" style="padding-top:16px;">
                <div>Total:</div>
                <div>Eff. Rate:</div>
            </td>
            <td class="clickable" [class.not-actual]="notReceived()" style="padding-right: 0px; padding-top: 16px; padding-bottom: 8px;" [style.cursor]="notAllowedIfConsolidated">
                <div class="revision" (click)="activateViewTarget(ViewTarget.tax)" style="padding-top: 0; padding-bottom: 0;" [style.cursor]="notAllowedIfConsolidated">
                    <div>{{currentYear.totalTaxes | currency: 'USD'}}</div>
                    <div>{{currentYear.effectiveRate | number: '1.6-6'}}%</div>
                </div>
            </td>
        </tr>

        <!-- Budget Row -->
        <tr *ngIf="budgetsEnabled">
            <td class="heading">
                Budget
            </td>
            <td class="heading" style="padding-top:16px;"></td>
            <td class="clickable" style="padding-right: 0px; padding-top: 16px; padding-bottom: 8px;" (click)="activateViewTarget(ViewTarget.budget)">
                <div class="revision" *ngIf="currentYear.latestBudgetId > 0" style="padding-top: 0; padding-bottom: 0;">
                    <div>{{currentYear.latestBudgetAmount | currency: 'USD'}}</div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
