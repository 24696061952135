import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-spinner',
    template: `
        <div *ngIf="show" class="text-center"
             [ngClass]="{ spinnerbg: hasOverlay, dark: darkOverlay }"
             [ngStyle]="{ 'margin-top': !hasOverlay && top ,'margin-bottom': !hasOverlay && bottom }">
            <i class="fa fa-spinner fa-spin-pulse" [ngClass]="faSize"></i>
        </div>
    `,
    styleUrls: ['loadingSpinner.component.scss']
})
export class LoadingSpinnerComponent {
    @Input() show: boolean = true;
    @Input() hasOverlay: boolean = false; // Parent container must be display relative
    @Input() darkOverlay: boolean = true;

    @Input()
    set marginTop(marginTop: number) {
        this.top = `${marginTop}px`;
    }

    @Input()
    set marginBottom(marginBottom: number) {
        this.bottom = `${marginBottom}px`;
    }

    @Input()
    set size(size: number) {
        this.faSize = `fa-${size}x`;
    }

    top: string = '50px';
    bottom: string = '1px';
    faSize: string;
}
