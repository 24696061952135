import { lastValueFrom, Observable, of as observableOf, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ExtendedGLAccountModel } from '../../Asset/Asset-Details/Asset-Info/extendedGLAccountModel';
import { GLAccountRepository } from '../../Repositories';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import GLAccountPropertyEnum = Compliance.GLAccountPropertyEnum;
import FilterTypeEnum = Core.FilterTypeEnum;

@Component({
    selector: 'gl-account-selector',
    templateUrl: './glAccountSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: GLAccountSelectorComponent,
        multi: true
    }]
})
export class GLAccountSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
    constructor(
        private readonly _glAccountRepository: GLAccountRepository,
    private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe
    ) { }

    private _glAccountSubscription: Subscription;
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() glAccountId: number;
    @Input() glAccountName: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() isOverridden: boolean = false;
    @Input() glAccounts: Compliance.GLAccountModel[] = null;
    @Input() set companyId(companyId: number) {
        this._companyId = companyId;
        if (this.isInitialized) {
            this._getGlAccounts();
        }
    };

    @Output() glAccountIdChange = new EventEmitter<number>();
    @Output() glAccountChange = new EventEmitter<ExtendedGLAccountModel>();

    glAccountFilter: string = '';
    isLoading: boolean;

    // this component will be considered initialized when the gl accounts have been loaded
    // in the meanwhile, any select gl account attempts will be skipped
    isInitialized: boolean = false;

    glAccounts$: Observable<ExtendedGLAccountModel[]> = (Observable
        .create((observer: any) => { observer.next(this.glAccountFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterGlAccounts(token)));

    private _glAccounts: ExtendedGLAccountModel[] = [];
    private _glAccountNoResult: boolean = false;
    private _companyId: number;

    ngOnInit(): void {
        if (this.glAccounts){
            this._populateGlAccounts(this.glAccounts);
        } else {
            this._getGlAccounts();
        }
    }

    ngOnDestroy(): void {
        this._glAccountSubscription && this._glAccountSubscription.unsubscribe();
    }

    writeValue(glAccount: Compliance.GLAccountModel): void {
        this._selectGlAccount(glAccount && glAccount.glAccountId);
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onGlAccountBlur(): void {
        if (!this.isRequiredField && (!this.glAccountFilter || this.glAccountFilter.trim() === '')) {
            this.glAccountId = null;
            this.glAccountName = null;
            this.glAccountChange.emit(null);
        }

        this._selectGlAccount(this.glAccountId);
        this._onTouchedFn && this._onTouchedFn();
    }

    onGlAccountNoResults(event: boolean): void {
        this._glAccountNoResult = event;
    }

    onGlAccountSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const glAccount = match.item as ExtendedGLAccountModel;
            this._selectGlAccount(glAccount.model.glAccountId);
            this.glAccountIdChange.emit(glAccount.model.glAccountId);
            this.glAccountChange.emit(glAccount);

            this.glAccountFilter = match.value;
        }
    }

    private _filterGlAccounts(filter: string): Observable<ExtendedGLAccountModel[]> {
        return observableOf(
            (this._glAccounts || []).filter(x => x.displayName.toLowerCase().includes(filter && filter.toLowerCase()))
        );
    }

    private _selectGlAccount(glAccountId: number, glAccountName?: string): void {
        // keep track of the value that the classification is being set to
        this.glAccountId = glAccountId;
        // if not initialized then return
        // when initialized the asset class ID that is being tracked (above) will be selected
        if (!this.isInitialized) {
            return;
        }

        const glAccount = this._glAccounts.find(gl => gl.model.glAccountId === glAccountId || gl.displayName === glAccountName);
        if (glAccount) {
            this.glAccountId = glAccount.model.glAccountId;
            this.glAccountFilter = glAccount.displayName;
        } else {
            this.glAccountId = null;
            this.glAccountFilter = null;
        }

        this._onChangeFn && this._onChangeFn(this.glAccountId);
    }

    private async _getGlAccounts(): Promise<void> {
        this.isLoading = true;
        // get the list of gl accounts
        const searchModel: Core.SearchModel<Compliance.GLAccountPropertyEnum> = {
            columnFilters: [
                {
                    filterProperty: GLAccountPropertyEnum.AccountType,
                    filterValues: [
                        {
                            filterType: FilterTypeEnum.Equals,
                            filterValue: 'Asset'
                        }]
                }]
        };
        const response = await lastValueFrom(this._glAccountRepository
            .getList(this._companyId, searchModel));
        this._populateGlAccounts(response.data);
    }

    private _populateGlAccounts(glAccounts: Compliance.GLAccountModel[]){
        if (glAccounts) {
            this._glAccounts = glAccounts.map(x => new ExtendedGLAccountModel(x, this._keyValueDisplayPipe));
        }

        this.isInitialized = true;
        this._selectGlAccount(this.glAccountId, this.glAccountName);
        this.isLoading = false;
    }
}
