import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';
import { BaseRepository } from '../../Common/Data/base.repository';

@Injectable()
export class AllocationDetailRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) {
        super(httpClient);
    }

    getList(allocationId: number, searchParams: Compliance.AllocationDetailSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Compliance.AllocationDetailModel, Compliance.AllocationDetailTotalsModel>> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/list`, searchParams);
    }

    getRowIds(allocationId: number, searchParams: Compliance.AllocationDetailSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/rowIds`, searchParams);
    }

    update(allocationId: number, allocationDetailId: number, updateModel: Compliance.AllocationDetailUpdateModel): Observable<Compliance.AllocationDetailUpdateResultModel> {
        return this.httpPut(`api/allocation/${allocationId}/allocationDetail/${allocationDetailId}`, updateModel);
    }

    getStatuses(allocationId: number): Observable<Compliance.AllocationDetailStatusModel[]> {
        return this.httpGet(`api/allocation/${allocationId}/allocationDetail/statuses`);
    }

    allocate(allocationId: number, model: Compliance.AllocationDetailAllocateModel): Observable<void> {
        return this.httpPut(`api/allocation/${allocationId}/allocationDetail/allocate`, model);
    }

    bulkUpdate(allocationId: number, model: Compliance.AllocationDetailBulkUpdateModel): Observable<void> {
        return this.httpPut(`api/allocation/${allocationId}/allocationDetail/bulkUpdate`, model);
    }

    startExport(allocationId: number, model: Compliance.AllocationDetailExportModel): Observable<number> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/export`, model);
    }

    getExport(allocationId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`api/allocation/${allocationId}/allocationDetail/export/${longRunningProcessId}`, options);
    }

    createAssetsNotMatchedFromWorkPapers(allocationId: number, model: Compliance.AllocationDetailCreateAssetsNotMatchedFromWorkPapersModel): Observable<void> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/createAssetsNotMatchedFromWorkPapers/`, model);
    }

    matchToExistingNonAllocatedAssets(allocationId: number, model: Compliance.AllocationDetailCreateAssetsNotMatchedFromWorkPapersModel): Observable<void> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/matchToExistingNonAllocatedAssets/`, model);
    }

    getSelectedRowsInfo(allocationId: number, searchParams: Compliance.SelectedRowsModel): Observable<Compliance.AllocationDetailSelectionInfo> {
        return this.httpPost(`api/allocation/${allocationId}/allocationDetail/getSelectedRowsInfo`, searchParams);
    }
}
