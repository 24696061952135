import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ElectronicFilingComponent } from './electronicFiling.component';

@NgModule({
    imports: [WeissmanSharedModule],
    declarations: [ElectronicFilingComponent]
})
export class ElectronicFilingModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('electronicFiling', ElectronicFilingComponent);
    }
}
