import {
    GridOptions,
    ColDef,
    RowNode,
    FilterChangedEvent,
    GetRowNodeIdFunc,
    FirstDataRenderedEvent,
    CellValueChangedEvent,
    CellEditingStartedEvent,
    CellEditingStoppedEvent
} from 'ag-grid-community';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { AgGridDateFilterComponent } from './Filters/Date/agGridDateFilter.component';
import { AgGridTextFilterComponent } from './Filters/Text/agGridTextFilter.component';
import { AgGridTextFloatingFilterComponent } from './FloatingFilters/agGridTextFloatingFilter.component';
import { AgGridNumberFilterComponent } from './Filters/Number/agGridNumberFilter.component';
import { AgGridNumberFloatingFilterComponent } from './FloatingFilters/agGridNumberFloatingFilter.component';
import { AgGridDateFloatingFilterComponent } from './FloatingFilters/agGridDateFloatingFilter.component';
import { AgGridHeaderCellRendererComponent } from './ColumnHeader/agGridHeaderCellRenderer.component';
import { AgGridYesNoFilterComponent } from './Filters/YesNo/agGridYesNoFilter.component';

export const AG_GRID_PAGE_SIZE: number = 500;

export class AgGridOptionsBuilder {
    constructor(gridOptions?: GridOptions) {
        this._gridOptions = gridOptions ? gridOptions : {} as GridOptions;
        this._gridOptions.defaultColDef = {
            lockPinned: true,
            suppressMenu: true
        } as ColDef;
        this._gridOptions.stopEditingWhenGridLosesFocus = true;
        this._gridOptions.frameworkComponents = {
            agColumnHeader: AgGridHeaderCellRendererComponent,
            agDateColumnFilter: AgGridDateFilterComponent,
            agDateColumnFloatingFilter: AgGridDateFloatingFilterComponent,
            agTextColumnFilter: AgGridTextFilterComponent,
            agTextColumnFloatingFilter: AgGridTextFloatingFilterComponent,
            agNumberColumnFilter: AgGridNumberFilterComponent,
            agNumberColumnFloatingFilter: AgGridNumberFloatingFilterComponent,
            agYesNoColumnFilter: AgGridYesNoFilterComponent
        };
    }

    private readonly _gridOptions: GridOptions;

    withInfiniteScroll(pageSize?:number): AgGridOptionsBuilder {
        this._gridOptions.rowModelType = 'infinite';
        this._gridOptions.paginationPageSize = pageSize ?? AG_GRID_PAGE_SIZE;
        this._gridOptions.cacheBlockSize = pageSize ?? AG_GRID_PAGE_SIZE;
        this._gridOptions.infiniteInitialRowCount = 0;
        this._gridOptions.blockLoadDebounceMillis = 250;
        this._gridOptions.cacheOverflowSize = 1;
        this._gridOptions.maxConcurrentDatasourceRequests = 2;
        this._gridOptions.maxBlocksInCache = 2;
        this._gridOptions.rowBuffer = 0;
        return this;
    }

    withColumnMenu(): AgGridOptionsBuilder {
        this._gridOptions.defaultColDef.suppressMenu = false;
        return this;
    }

    withColumnPinning(): AgGridOptionsBuilder {
        this._gridOptions.defaultColDef.lockPinned = false;
        return this;
    }

    withLoadingOverlay(): AgGridOptionsBuilder {
        this._gridOptions.loadingOverlayComponentFramework = AgGridLoadingOverlayComponent;
        return this;
    }

    withSort(): AgGridOptionsBuilder {
        this._gridOptions.defaultColDef.sortable = true;
        this._gridOptions.suppressMultiSort = true;
        return this;
    }

    withMultipleColumnSort(key: string = 'ctrl'): AgGridOptionsBuilder {
        this._gridOptions.defaultColDef.sortable = true;
        this._gridOptions.suppressMultiSort = false;
        this._gridOptions.multiSortKey = key;
        return this;
    }

    withFloatingFilter(): AgGridOptionsBuilder {
        this._gridOptions.floatingFilter = true;
        return this;
    }

    withContext(context?: any): AgGridOptionsBuilder {
        this._gridOptions.context = context;
        return this;
    }

    withColumnResize(): AgGridOptionsBuilder {
        this._gridOptions.defaultColDef.resizable = true;
        return this;
    }

    withSingleRowSelect(): AgGridOptionsBuilder {
        this._gridOptions.rowSelection = 'single';
        this._gridOptions.suppressRowClickSelection = true;
        return this;
    }

    withMultipleRowSelect(): AgGridOptionsBuilder {
        this._gridOptions.rowSelection = 'multiple';
        this._gridOptions.suppressRowClickSelection = true;
        return this;
    }

    withFullWidthCell(
        isFullWidthCell: (rowNode: RowNode) => boolean,
        fullWidthCellRendererFramework: any,
        fullWidthCellRendererParams: any = null): AgGridOptionsBuilder {
        this._gridOptions.isFullWidthCell = isFullWidthCell;
        this._gridOptions.fullWidthCellRendererFramework = fullWidthCellRendererFramework;
        this._gridOptions.fullWidthCellRendererParams = fullWidthCellRendererParams;
        return this;
    }

    withRowHeight(rowHeight: (params: any) => number): AgGridOptionsBuilder {
        this._gridOptions.getRowHeight = rowHeight;
        return this;
    }

    withFilterChanged(filterChanged: (event: FilterChangedEvent) => void): AgGridOptionsBuilder {
        this._gridOptions.onFilterChanged = filterChanged;
        return this;
    }

    withTextSelection(): AgGridOptionsBuilder {
        this._gridOptions.enableCellTextSelection = true;
        return this;
    }

    withVerticalScrollBar(): AgGridOptionsBuilder {
        this._gridOptions.alwaysShowVerticalScroll = true;
        return this;
    }

    withRowId(getRowId: GetRowNodeIdFunc): AgGridOptionsBuilder {
        this._gridOptions.getRowNodeId = getRowId;
        return this;
    }

    withFirstDataRendered(firstDataRendered: (event: FirstDataRenderedEvent) => void): AgGridOptionsBuilder {
        this._gridOptions.onFirstDataRendered = firstDataRendered;
        return this;
    }

    withCellValueChanged(cellValueChanged: (event: CellValueChangedEvent) => void): AgGridOptionsBuilder {
        this._gridOptions.onCellValueChanged = cellValueChanged;
        return this;
    }

    withCellEditingStarted(cellEditingStarted: (event: CellEditingStartedEvent) => void): AgGridOptionsBuilder {
        this._gridOptions.onCellEditingStarted = cellEditingStarted;
        return this;
    }

    withCellEditingStopped(cellEditingStopped: (event: CellEditingStoppedEvent) => void): AgGridOptionsBuilder {
        this._gridOptions.onCellEditingStopped = cellEditingStopped;
        return this;
    }

    withoutCellEditingStoppedOnGridLostFocus(): AgGridOptionsBuilder {
        this._gridOptions.stopEditingWhenGridLosesFocus = false;
        return this;
    }

    withDefault(context?: any): AgGridOptionsBuilder {
        return this.withContext(context)
            .withInfiniteScroll()
            .withLoadingOverlay()
            .withMultipleColumnSort()
            .withColumnResize()
            .withTextSelection();
    }

    buildDefault(context?: any): GridOptions {
        return this.withDefault(context).build();
    }

    build(): GridOptions {
        return this._gridOptions;
    }
}
