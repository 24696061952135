<div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="userGroup">
    <div class="ws-flex-container-horizontal ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="userGroup.name"></ws-bread-crumbs>
        </div>
    </div>

    <div class="ws-section ws-flex-none">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">User Group</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button"
                                class="flat-button small-button secondary-button"
                                (click)="refresh()"
                                [disabled]="isPanelEdit">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="userGroup" class="user-group-panels" [class.user-group-panel-expanded]="expandedPanel">
        <user-group-info
            [class.remove-panel]="expandedPanel && expandedPanel !== 'userGroupInfo'"
            [userGroup]="userGroup"
            [canEdit]="canEdit"
            (expandClicked)="expandClicked($event)">
        </user-group-info>
        <ng-container *ngIf="!isLicensedUser">
            <application-permission-panel
                [class.remove-panel]="expandedPanel && expandedPanel !== 'applicationPermission'"
                [userGroup]="userGroup"
                [canEdit]="canEdit"
                (expandClicked)="expandClicked($event)">
            </application-permission-panel>
            <company-permission-panel
                [class.remove-panel]="expandedPanel && expandedPanel !== 'companyPermission'"
                [userGroup]="userGroup"
                [canEdit]="canEdit"
                (expandClicked)="expandClicked($event)">
            </company-permission-panel>
            <instance-permission-panel
                [class.remove-panel]="expandedPanel && expandedPanel !== 'instancePermission'"
                [userGroup]="userGroup"
                [canEdit]="canEdit"
                (expandClicked)="expandClicked($event)">
            </instance-permission-panel>
        </ng-container>
        <ng-container *ngIf="isLicensedUser">
            <instance-permission-panel
                [class.remove-panel]="expandedPanel && expandedPanel !== 'instancePermission'"
                [userGroup]="userGroup"
                [canEdit]="canEdit"
                (expandClicked)="expandClicked($event)">
            </instance-permission-panel>
            <div></div>
            <company-permission-panel
                [class.remove-panel]="expandedPanel && expandedPanel !== 'companyPermission'"
                [userGroup]="userGroup"
                [canEdit]="canEdit"
                (expandClicked)="expandClicked($event)">
            </company-permission-panel>
        </ng-container>
    </div>
</div>

