<div class="modal-header">
    <h2 class="main-header">{{ template.name }} Template</h2>
</div>
<div class="modal-body quill-height-fix">
    <label>
        Template Subject
    </label>
    <div>{{ template.subjectTemplate }}</div>

    <label style="margin-top:30px;">
        Template Body
    </label>
    <quill-editor [(ngModel)]="template.bodyTemplate"
                  [placeholder]="'Email Body ...'"
                  [readOnly]="true">
        <div quill-editor-toolbar>
            <!-- Basic buttons -->
            <span class="ql-formats">
                <button class="ql-bold" [title]="'Bold'"></button>
                <button class="ql-italic" [title]="'Italic'"></button>
                <button class="ql-underline" [title]="'Underline'"></button>
                <button class="ql-strike" [title]="'Strike'"></button>
            </span>

            <!-- Size -->
            <span class="ql-formats">
                <select class="ql-size" [title]="'Size'">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
            </span>
            <span class="ql-formats">
                <select class="ql-align" [title]="'Aligment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>
        </div>
    </quill-editor>
</div>
<div class="modal-footer">
    <div>
        <button class="flat-button warning-button" (click)="modalRef.hide()">Close</button>
    </div>
</div>