
import {of as observableOf,  Observable } from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import * as _ from 'lodash';
import { mergeMap } from 'rxjs/operators';

@Component({
    templateUrl: './attachmentTypeSelector.component.html',
    selector: 'attachment-type-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AttachmentTypeSelectorComponent,
        multi: true
    }]
})
export class AttachmentTypeSelectorComponent implements ControlValueAccessor {

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() values: Compliance.AttachmentTypeViewModel[] = [];
    @Input() isOverridden: boolean = false;

    @Output() changed = new EventEmitter<string>();

    value: string;
    disabled: boolean;
    filter: string = '';

    values$: Observable<Compliance.AttachmentTypeViewModel[]> = (Observable
        .create((observer: any) => { observer.next(this.filter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filter(token)));

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(attachmentType: string): void {
        this._select(attachmentType);
    }

    onBlur(): void {
        this._select(this.value);
        this._onTouchedFn && this._onTouchedFn();
    }

    onSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const attachmentType = match.item as Compliance.AttachmentTypeViewModel;
            this._select(attachmentType.attachmentType);
            this.changed.emit(attachmentType.attachmentType);
        }
    }

    private _filter(filter: string): Observable<Compliance.AttachmentTypeViewModel[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }

        return observableOf(
            (this.values || []).filter(x => x.attachmentType.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _select(value: string): void {
        this.value = value;

        const attachmentType = _.find(this.values, (x) => x.attachmentType === value);

        if (attachmentType) {
            this.filter = value;
        } else {
            this.filter = null;
        }
    }
}
