<div style="word-break: break-word;">
    <table style="border-collapse: separate; border-spacing: 0px 8px; width: 100%;">
        <tbody>
            <tr *ngFor="let item of data.stringArray">
                <td>
                    {{ item }}
                </td>
            </tr>
        </tbody>
    </table>
</div>
