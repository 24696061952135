'use strict';

/**
 * @ngdoc directive
 * @name weissman.directive:loadingSpinner
 * @description
 * # loadingSpinner
 */
angular.module('weissmanApp')
    .directive('loadingSpinner', function() {
        return {
            scope: {
                waitingFor: '='
            },
            template: '<div ng-if="waitingFor!==undefined && waitingFor !== 0" ng-transclude></div><div class="text-center" ng-if="waitingFor===undefined || waitingFor === 0" style="margin-top: 50px;"><i class="fa fa-spinner fa-spin fa-5x"></i></div>',
            restrict: 'A',
            transclude: true
        };
    });
