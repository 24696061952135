import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ReportManagerModalInputParameters {
    reportId: number;
    reportType?: number;
    formRevisionId?: number;
    filingBatchId?: number;
    formRevisions?: Compliance.ReturnFormRevisionModel[];
    schedules?: Compliance.FormRevisionScheduleModel[];
}

@Component({
    selector: 'report-manager-modal-component',
    templateUrl: './reportManagerModal.component.html'
})
export class ReportManagerModalComponent implements OnInit {
    loading: boolean = false;
    onClose: any;
    params: ReportManagerModalInputParameters;
    savedReport: any;
    result: any;

    constructor(private readonly _bsModalRef: BsModalRef) { }

    ngOnInit() { }

    handleSaveModalClose(report: any) {
        this.savedReport = report;
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}