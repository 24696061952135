import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class BalanceAdjustmentsService {
    constructor(private readonly _http: HttpClient) {}

    private readonly _baseUrl = 'api/parcel';

    getSelectionData(parcelId: number): Promise<Core.AccrualBalanceAdjustmentSelectionDataModel> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/${parcelId}/Accrual/BalanceAdjustmentSelectionData`)) as Promise<Core.AccrualBalanceAdjustmentSelectionDataModel>;
    }

    getAdjustments(parcelId: number): Promise<Core.AccrualBalanceAdjustmentsModel> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/${parcelId}/Accrual/BalanceAdjustments`)) as Promise<Core.AccrualBalanceAdjustmentsModel>;
    }

    updateAdjustment(parcelId: number, adjustment: Core.AccrualBalanceAdjustmentModel): Promise<Core.AccrualBalanceAdjustmentModel> {
        return lastValueFrom(this._http.put(`${this._baseUrl}/${parcelId}/Accrual/BalanceAdjustment`, adjustment)) as Promise<Core.AccrualBalanceAdjustmentModel>;
    }

    deleteAdjustment(parcelId: number, accrualBalanceAdjustmentId: number): Promise<any> {
        return lastValueFrom(this._http.delete(`${this._baseUrl}/${parcelId}/Accrual/BalanceAdjustment/${accrualBalanceAdjustmentId}`)) as Promise<any>;
    }

    getBalances(parcelId: number): Promise<Core.ParcelAccrualBalancesModel> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/${parcelId}/Accrual/Balances`)) as Promise<Core.ParcelAccrualBalancesModel>;
    }
}
