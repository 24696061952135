import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalWrapperModule } from '../../../UI-Lib/Modal-Wrapper/modalWrapper.module';
import { ColumnPickerComponent } from './columnPicker.component';
import { BrowseColumnModalComponent } from './Browse-Column-Modal/browseColumnModal.component';

import { HybridAdapterUtility } from '../../../Hybrid/hybrid-adapter.utility';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TypeaheadModule,
        ModalWrapperModule,
        TabsModule
    ],
    declarations: [
        ColumnPickerComponent,
        BrowseColumnModalComponent
    ],
    providers: [],
    exports: [ColumnPickerComponent]
})
export class ColumnPickerModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Component('columnPicker', ColumnPickerComponent);
    }
}
