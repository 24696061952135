<div class="ace-con d-flex flex-column w-100 bg-white pb-3">
    <div class="blade-con d-flex flex-column pb-1">
        <div class="blade-header d-flex justify-content-between ps-4">
            <div class="d-flex align-items-center">
                <h1 class="d-inline-block mb-0 me-3">{{ pageTitle }}</h1>
            </div>
        </div>
        <div class="blade-body d-flex flex-column flex-grow-1">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <ws-ace-button-group [input]="tabSetConfig"></ws-ace-button-group>
                <ws-ace-button *ngIf="newButtonLabel"
                            icon="plus"
                            [content]="newButtonLabel"
                            buttonStyle="text"
                            state="action"
                            (click)="newButtonClicked.emit()">
                </ws-ace-button>
            </div>
            <div class="d-flex flex-grow-1">
                <ui-view id="save-page-output"></ui-view>
            </div>
        </div>
    </div>
</div>
