<div class="asset-classification-picker-container">
    <ws-modal-wrapper [headerLabel]="'Bulk Update ' + params.selectedCount + ' Records'"
                      subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                      (save)="save()"
                      (cancel)="cancel()"
                      [disableSave]="!validateForm()">
        <form>
            <div class="table-responsive">
                <table class="table table-striped table-condensed table-borderless-body">
                    <tbody>
                        <tr>
                            <td>
                                Asset Return Status <i *ngIf="showAssetReturnStatusTooltip"
                                                        class="fa fa-info-circle"
                                                        [tooltip]="assetReturnStatusTooltipText"
                                                        container="body"
                                                        placement="right">
                                                    </i>
                            </td>
                            <td>
                                <return-asset-bulk-update-action #returnStatusActionField [(fieldAction)]="returnStatusAction" [onlyAllowRemoveOverrides]="allowAssetReturnStatusRemoveOverride" [disabled]="isAssetReturnStatusFieldDisabled && !allowAssetReturnStatusRemoveOverride"></return-asset-bulk-update-action>
                            </td>
                            <td>
                                <select class="form-select form-select-sm" name="assetReturnStatus" [(ngModel)]="returnStatus" [disabled]="isDisabled(returnStatusAction) || isAssetReturnStatusFieldDisabled">
                                    <option *ngFor="let pr of possibleReturnStatuses" [ngValue]="pr.value">{{ pr.name }}</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Classification</td>
                            <td>
                                <return-asset-bulk-update-action [fieldHasOverrides]="true" [(fieldAction)]="classificationIdAction"></return-asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <asset-class-selector [(assetClassificationId)]="classificationId"
                                                          [isDisabled]="isDisabled(classificationIdAction)"
                                                          (assetClassificationChange)="setClassificationName($event)">
                                    </asset-class-selector>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Schedule Assignment</td>
                            <td>
                                <return-asset-bulk-update-action [onlyAllowRemoveOverrides]="true" [(fieldAction)]="scheduleAssignmentAction" (fieldActionNameChange)="setScheduleAssignmentName($event)"></return-asset-bulk-update-action>
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngIf="params.showPriorScheduleField">
                            <td>Prior Schedule</td>
                            <td>
                                <return-asset-bulk-update-action [fieldHasOverrides]="true" [fieldHasClassificationDefault]="true" [(fieldAction)]="priorScheduleAction"></return-asset-bulk-update-action>
                            </td>
                            <td>
                                <schedule-selector (formRevisionScheduleChange)="formRevisionScheduleChange($event)" [isRequiredField]="false" [isDisabled]="isDisabled(priorScheduleAction)"></schedule-selector>
                            </td>
                        </tr>
                        <tr>
                            <td>Additional Depreciation</td>
                            <td>
                                <return-asset-bulk-update-action [fieldHasOverrides]="true" [(fieldAction)]="additionalDepreciationAction"></return-asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <input name="additionalDepreciation"
                                           id="additionalDepreciation"
                                           type="number"
                                           min="0.01"
                                           max="100"
                                           class="form-control"
                                           autocomplete="off"
                                           [(ngModel)]="additionalDepreciation"
                                           [disabled]="isDisabled(additionalDepreciationAction)"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Estimated FMV</td>
                            <td>
                                <return-asset-bulk-update-action [fieldHasOverrides]="true" [(fieldAction)]="estimatedFMVAction"></return-asset-bulk-update-action>
                            </td>
                            <td>
                                <div>
                                    <input name="estimatedFMV"
                                           id="estimatedFMV"
                                           type="number"
                                           min="0.01"
                                           class="form-control"
                                           autocomplete="off"
                                           [(ngModel)]="estimatedFMV"
                                           [disabled]="isDisabled(estimatedFMVAction)"/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </ws-modal-wrapper>
</div>
