import { BehaviorSubject, interval as observableInterval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ColumnApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { Assessor } from '../../Assessor-Collector/assessor.model';
import { Collector } from '../../Assessor-Collector/collector.model';
import { TaxRateCommandCenterService } from './tax.rate.command.center.service';
import { TaxAuthority, TaxRateArea } from '../../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { CommonBulkUpdateFieldActionEnum, Constants, EntityType } from '../../constants.new';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { GridSource, TaxRateGridComponent } from '../tax.rate.grid.component';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../Compliance/AgGrid/MultiSelectTracker';
import { TaxAuthorityBulkUpdateComponent } from './Tax-Authority-Bulk-Update/tax.authority.bulk.update.component';
import {
    QcStateCount,
    TaxAuthorityBulkUpdateProgress,
    TaxAuthorityBulkUpdateRequest,
    TaxAuthoritySelectionOptions,
    TaxAuthoritySelectionType,
    TaxAuthorityToUpdate,
    TaxAuthorityUpdateResults,
    TaxRateDatatExportModel
} from '../tax.rate.model';
import { TaxRateCommandCenterAgGridDataSource, TaxRateDataSourceParams } from './agGridDataSource';
import { ToastrService } from 'ngx-toastr';
import {
    ICellRendererParamsForTaxRateCommandCenterComments,
    TaxRateCommandCenterCommentsCellRendererComponent
} from './agGridCommentsCellRenderer.component';
import {
    ICellRendererParamsForTaxRateCommandCenterAttachments,
    TaxRateCommandCenterAttachmentsCellRendererComponent
} from './agGridAttachmentsCellRenderer.component';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../Compliance/AgGrid/ToolPanel/models';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { OrderedColDef } from '../../Common/AgGrid/ag.grid.model';
import * as _ from 'lodash';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

@Component({
    selector: 'tax-rate-command-center',
    templateUrl: './tax.rate.command.center.component.html',
    styleUrls: ['./tax.rate.command.center.component.scss']
})
export class TaxRateCommandCenterComponent extends TaxRateGridComponent implements OnInit, OnDestroy {
    isMaximized: boolean = false;
    hasDataImportPermission: boolean = false;

    stateAssessors: Compliance.NameValuePair<number>[] = [];
    stateCollectors: Compliance.NameValuePair<number>[] = [];
    assessorCollectors: Compliance.NameValuePair<number>[] = [];
    assessorsCollectorsLoading: boolean = false;

    selectedStateId: number;
    selectedAssessorId: number;
    selectedCollectorId: number;
    onlyPendingQC: boolean;

    gridTracker: AgGridMultiSelectTracker;
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    pendingQcStateCounts: QcStateCount[] = [];
    qcCountTotal: number;
    qcCountsUpdating: boolean = false;

    bulkUpdateInProgress: boolean = false;
    bulkUpdateCanceled: boolean = false;
    bulkUpdateProgress: TaxAuthorityBulkUpdateProgress = new TaxAuthorityBulkUpdateProgress();
    showBulkUpdateRecalcSummary: boolean = false;

    LARGE_BULK_UPDATE_THRESHOLD: number = 100;

    selectedNode: RowNode;
    columnApi: ColumnApi;

    constructor(
        public taxRateCommandCenterService: TaxRateCommandCenterService,
        private modalService: BsModalService,
        private constants: Constants,
        private messageBoxService: MessageBoxService,
        private toastr: ToastrService,
        private _datePipe: WeissmanDateFormatPipe,
        private restrictService: RestrictService) {
            super(taxRateCommandCenterService, restrictService, modalService);
            this.hasDataImportPermission = restrictService.isInRole(Roles.ALLOWDATAIMPORTS);
        }

    private _abbrSort = (a, b) => (a.abbr > b.abbr) ? 1 : -1;
    private _gridMultiSelectSub: Subscription;
    private _gridDataSource: TaxRateCommandCenterAgGridDataSource;
    private _qcCountSub: Subscription;

    gridOptions = new AgGridOptionsBuilder(
        {
            suppressScrollOnNewData: true,
            onRowClicked: (params) => {
                if(!params.data || this.qcCountsUpdating) {
                    return;
                }

                this.selectedNode = params.node;

                if(this.selectedGridSourceOption == 'areas') {
                    this.launchTaxRateAreaModal(params.data.taxRateAreaId, false);
                } else {
                    this.launchTaxAuthorityModal(params.data.taxAuthorityId, false);
                }
            },
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            onColumnVisible: () => {
                this.columnApi.autoSizeAllColumns();
            }

            // rowClassRules: {
            //     'ag-row-selected': (params) => {
            //         if(!params.data) {
            //             return false;
            //         }

            //         const entityId = this.selectedGridSourceOption == 'areas' ? params.data.taxRateAreaId : params.data.taxAuthorityId;
            //         return this.gridTracker.isRowSelected(entityId)
            //     }
            // }
        })
        .withColumnPinning()
        .withDefault(this).withSort().build();

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchModelWithoutPagination();
            searchParams.pagination = {
                skip: 0,
                take: this._gridDataSource.totalRows
            };
            const exportModel = { searchModel: searchParams, columnsToReturn: columnsToReturn } as TaxRateDatatExportModel;
            const longRunningProcessId = await this.taxRateCommandCenterService.exportTaxRateData(exportModel);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportTaxRates };
        },
        canCancel: true
    };

    async ngOnInit() {
        this._qcCountSub = observableInterval(60000).pipe(
            startWith(0),
            switchMap(() => {
                this.qcCountsUpdating = true;
                return this.taxRateCommandCenterService.getPendingQcCounts();
            }))
            .subscribe(data => {
                this.qcCountsUpdating = false;
                this.pendingQcStateCounts = data;

                this._getQcCountTotal();
            });
    }

    ngOnDestroy() {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._qcCountSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
        this.columnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(() => {
            const isBulkUpdateVisible = this.selectedGridSourceOption == 'authorities' && this.gridTracker.getSelectedRowsCount() > 1;
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
        });

        this.gridApi.setColumnDefs(this.getGridColumns());
        this._setDataSource();
    }

    toggleMaximize(isMaximized: boolean): void {
        this.isMaximized = isMaximized;
    }

    gridSourceSelected(gridSource: GridSource): void {
        this._changeGridSource(gridSource);
        this._refreshDataSource();
    }

    private _changeGridSource(gridSource: GridSource): void {
        this.selectedGridSourceOption = gridSource;
        this.gridTracker.clear();

        this.gridApi.setColumnDefs(this.getGridColumns());
    }

    filterGridByStateAndQc(stateId:number) {
        this.selectedStateId = stateId;
        this.onlyPendingQC = true;

        if(this.selectedGridSourceOption == 'areas') {
            this._changeGridSource('authorities');
        }

        this.stateSelected();
    }

    async refreshQcCounts() {
        this.qcCountsUpdating = true;
        this.gridApi.showLoadingOverlay();

        this.pendingQcStateCounts = await this.taxRateCommandCenterService.getPendingQcCounts();
        this._getQcCountTotal();

        this.gridApi.hideOverlay();
        this.qcCountsUpdating = false;
    }

    private _getQcCountTotal(): void {
        this.qcCountTotal = this.pendingQcStateCounts.reduce((total, state) => {
            return total + state.item3;
        }, 0);
    }

    async stateSelected(): Promise<void> {
        if(!this.selectedStateId) {
            this.stateAssessors = this.stateCollectors = [];
        } else {
            this.assessorsCollectorsLoading = true;

            Promise.all(
                [
                    this.taxRateCommandCenterService.getAssessors(this.selectedStateId),
                    this.taxRateCommandCenterService.getCollectors(this.selectedStateId)
                ]
            ).then(([assessors, collectors]) => {
                this.stateAssessors = assessors
                    .sort(this._abbrSort)
                    .map((a) => ({ name: `${a.abbr} ${(a.municipalityName) ? a.municipalityName : ''}`, value: a.assessorID }));

                this.stateCollectors = collectors
                    .sort(this._abbrSort)
                    .map((a) => ({ name: `${a.abbr}`, value: a.collectorID }));

                this.assessorsCollectorsLoading = false;
            });
        }

        this.selectedAssessorId = null;
        this.selectedCollectorId = null;

        this._refreshDataSource();
    }

    async assessorSelected(): Promise<void> {
        this.selectedCollectorId = null;
        this._refreshDataSource();

        if(!this.selectedAssessorId) {
            this.assessorCollectors = [];

            return;
        }
        this.assessorsCollectorsLoading = true;

        const collectorAssociations = await this.taxRateCommandCenterService.getCollectors(this.selectedStateId, this.selectedAssessorId);

        this.assessorCollectors = collectorAssociations
            .sort(this._abbrSort)
            .map((a) => ({ name: `${a.abbr}`, value: a.collectorID }));

        this.assessorsCollectorsLoading = false;
    }

    collectorSelected() {
        this._refreshDataSource();
    }

    showTaxYearChangedCC() {
        this.showTaxYearChanged();
        this._refreshDataSource();
    }

    onlyPendingQCChanged() {
        this._refreshDataSource();
    }

    async bulkUpdate() {
        // Retrieve the selected Tax Authoriites.
        // This involves an API call that mirrors the grid population query, so that
        // the Tax Authorities can be retrieved for the All, and All but Given (exclusion)
        // selection use cases.
        const selections: TaxAuthorityToUpdate[] = await this.gatherBulkUpdateSelections();
        if ( selections.length === 0 ) {
            return;
        }

        const initialState = {
            selectedCount: selections.length
        };
        const modalRef = this.modalService.show(TaxAuthorityBulkUpdateComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});

        modalRef.content.onClose = (request?: TaxAuthorityBulkUpdateRequest) => {
            this.processBulkUpdateRequest(selections, request);
        };
    }

    async gatherBulkUpdateSelections(): Promise<TaxAuthorityToUpdate[]> {
        // Retrieve list of selected Tax Authority identities to be Bulk Updated.
        const rowsSelectionModel: any = this.gridTracker.getSelectedRowsModel();
        const selectionOptions: TaxAuthoritySelectionOptions = {
            searchParams: this._gridDataSource.getSearchModelForBulkUpdate(),
            selectionType: rowsSelectionModel.selectAllRows
                                ? (rowsSelectionModel.selectedRows.length > 0
                                    ? TaxAuthoritySelectionType.AllButGiven
                                    : TaxAuthoritySelectionType.All)
                                : TaxAuthoritySelectionType.Given,
            selectedTaxAuthorityIds: rowsSelectionModel.selectedRows
        };
        console.log(`Gathering Tax Authority Selections (${selectionOptions.selectedTaxAuthorityIds.length}):  ${JSON.stringify(selectionOptions)}`);

        const selections: TaxAuthorityToUpdate[] = await this.taxRateCommandCenterService.getTaxAuthoritySelectionsForBulkUpdate(selectionOptions);
        console.log(`Tax Authority Selections (${selections.length}):  ${JSON.stringify(selections)}`);

        // If Selected count is less than threshold for "an awful lot", just proceed.
        if ( selections.length < this.LARGE_BULK_UPDATE_THRESHOLD ) {
            return selections;
        }

        // Confirm with the user to Builk Update that many Tax Authorities.
        const answer: number = await this.messageBoxService.open({
            message: `Are you sure you want to Bulk Update ${selections.length} Tax Authorities?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if ( answer === MessageBoxResult.OK ) {
            return selections;
        }

        // Canceled Bulk Update, return no selections.
        console.log('Bulk Update confirmation canceled');
        return [];
    }

    protected processBulkUpdateRequest(selections: TaxAuthorityToUpdate[], request?: TaxAuthorityBulkUpdateRequest) : void {
        if ( request )
        {
            // Only show bill recalc summary if a field that affects it is changed.
            this.showBulkUpdateRecalcSummary = (request.taxableAssessmentTypeAction == CommonBulkUpdateFieldActionEnum.ChangeTo ||
                                                request.defaultAnnualIncreaseAction == CommonBulkUpdateFieldActionEnum.ChangeTo ||
                                                request.yoyAction == CommonBulkUpdateFieldActionEnum.ChangeTo);

            // Switch page to Bulk Update Progress display
            this.bulkUpdateInProgress = true;
            this.bulkUpdateCanceled = false;
            this.bulkUpdateProgress.reset();
            this.bulkUpdateProgress.selections = selections;
            this.bulkUpdateProgress.request = request;

            console.log(`Tax Authority Bulk Updates to apply:  ${JSON.stringify(this.bulkUpdateProgress.request)}`);
            console.log(`Tax Authorities to Bulk Update:  ${JSON.stringify(this.bulkUpdateProgress.selections)}`);

            // Execute Bulk Update
            this.executeBulkUpdate();
        }
    }

    executeBulkUpdate(): void {
        // RECURSIVE function
        if ( !this.bulkUpdateInProgress || this.bulkUpdateCanceled ||
             this.isBulkUpdateDone() ) {
            // Bulk Update complete or canceled.
            return;
        }

        const cur = this.getCurrentTABulkUpdateSelection();
        this.taxRateCommandCenterService.applyBulkUpdateToTaxAuthority(cur.taxAuthorityId, this.bulkUpdateProgress.request)
            .then((results: TaxAuthorityUpdateResults) => {
                console.log(`Bulk Updated Tax Authority ${this.getCurrentTABulkUpdateIdentity()}:  ${JSON.stringify(results)}`);

                this.bulkUpdateProgress.billClusterCount += results.billClusterCount;
                this.bulkUpdateProgress.billCount += results.billCount;
                this.bulkUpdateProgress.paymentCount += results.paymentCount;

                this.bulkUpdateProgress.currentIndex++;

                this.executeBulkUpdate();
            })
            .catch((err:any) => {
                if ( this.bulkUpdateInProgress )
                {
                    console.log(`Bulk Update FAILED for Tax Authority ${this.getCurrentTABulkUpdateIdentity()}:  ${JSON.stringify(err)}`);

                    this.toastr.error(err.message, 'Bulk Update FAILED!');

                    this.cancelBulkUpdate();
                }
            });
    }

    cancelBulkUpdate() {
        // Switch page to Bulk Updte Canceled display
        this.bulkUpdateCanceled = true;
    }

    endBulkUpdate() {
        // Switch page back to normal Command Center display
        this.bulkUpdateCanceled = false;
        this.bulkUpdateInProgress = false;
        this.bulkUpdateProgress.reset();

        // Refresh grid with applied updates.
        this.refresh();
    }

    isBulkUpdateDone() : boolean {
        return this.bulkUpdateProgress.currentIndex >= this.bulkUpdateProgress.selections.length;
    }

    getCurrentTABulkUpdateSelection() : TaxAuthorityToUpdate | null {
        let cur: TaxAuthorityToUpdate = null;

        if ( this.bulkUpdateProgress.currentIndex < this.bulkUpdateProgress.selections.length )
        {
            cur = this.bulkUpdateProgress.selections[this.bulkUpdateProgress.currentIndex];
        }
        return cur;
    }

    getCurrentTABulkUpdateIdentity() : string {
        let identity: string = '';

        const cur = this.getCurrentTABulkUpdateSelection();
        if ( cur )
        {
            identity = `[${this.bulkUpdateProgress.currentIndex + 1} of ${this.bulkUpdateProgress.selections.length}]  ${cur.name}  (${cur.code})`;
        }
        return identity;
    }


    protected loadPanel(entity: TaxRateArea | TaxAuthority): void {
        if(entity) {
            // this.selectedNode.setData(entity);
            this.refresh();
        }
    }

    protected getSharedColumns(): OrderedColDef[] {
        return [{
            colId: 'grid-column-multiselect',
            headerName: '',
            field: this.selectedGridSourceOption == 'areas' ? 'taxRateAreaId' : 'taxAuthorityId',
            width: AgGridColumns.selectionColumnWidth,
            lockVisible: true,
            suppressSizeToFit: true,
            suppressAutoSize: true,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true,
            editable: false,
            resizable: false,
            headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
            headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            cellRendererFramework: AgGridMultiSelectedCellRenderer,
            cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            sequence: 0
        }, {
            headerName: 'Code',
            field: 'code',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 2
        }, {
            headerName: 'Address',
            field: 'address',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            valueFormatter: (params) => {
                const entity = params.data as TaxAuthority | TaxRateArea;
                if(!entity || !entity.address) {
                    return '';
                }

                const address = entity.address;
                let addressString: string = '';

                addressString += address.address1 ? `${address.address1},` : '';
                addressString += address.address2 ? ` ${address.address2},` : '';
                addressString += address.city ? ` ${address.city},` : '';
                addressString += address.abbr ? ` ${address.abbr}` : '';
                addressString += address.zip ? ` ${address.zip}` : '';

                return addressString;
            },
            sequence: 3
        }, {
            headerName: 'Assessor',
            field: 'assessorName',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 8.1
        }, {
            headerName: 'Collector',
            field: 'collectorName',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 8.2
        }, {
            headerName: '',
            field: 'hasAttachments',
            filter: true,
            filterParams: AgGridFilterParams.booleanFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth,
            cellRendererFramework: TaxRateCommandCenterAttachmentsCellRendererComponent,
                cellRendererParams: {
                    gridSofurce: this.selectedGridSourceOption,
                    canEdit: true
                } as ICellRendererParamsForTaxRateCommandCenterAttachments,
            sequence: 9
        }, {
            headerName: '',
            field: 'hasComments',
            filter: true,
            filterParams: AgGridFilterParams.booleanFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth,
            cellRendererFramework: TaxRateCommandCenterCommentsCellRendererComponent,
                cellRendererParams: {
                    gridSofurce: this.selectedGridSourceOption,
                    canEdit: true
                } as ICellRendererParamsForTaxRateCommandCenterComments,
            sequence: 10
        }];
    }

    protected getAuthorityColumns(): OrderedColDef[] {
        return [{
            headerName: 'Authority Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 1
        }, {
            headerName: 'PTX ID',
            field: 'ptxId',
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 2.1
        }, {
            headerName: 'Category',
            field: 'categoryName',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            valueGetter: (params) => {
                const taxAuthority: TaxAuthority = params.data;

                if(!taxAuthority || taxAuthority.taxAuthorityCategoryId == null) {
                    return '';
                }
                return this.constants.TaxAuthorityCategories[taxAuthority.taxAuthorityCategoryId].displayName;
            },
            sequence: 4
        }, {
            headerName: 'Taxable Assessment',
            field: 'taxableAssessmentType',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            valueGetter: (params) => {
                const taxAuthority: TaxAuthority = params.data;

                if(!taxAuthority || taxAuthority.taxableAssessmentTypeId == null) {
                    return '';
                }
                return this.constants.TaxableAssessmentTypes[taxAuthority.taxableAssessmentTypeId].displayName;
            },
            sequence: 5
        }, {
            headerName: 'Obtain in Advance',
            // headerClass: 'ws-text-align-center',
            field: 'obtainInAdvance',
            filter: true,
            filterParams: AgGridFilterParams.booleanFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth,
            cellRenderer: (params) => {
                const entity = params.data as TaxAuthority;

                if(!entity) {
                    return '';
                }

                if(entity.obtainInAdvance) {
                    return '<i class="fa fa-check"></i>';
                } else {
                    return '';
                }
            },
            sequence: 7
            // cellClass: 'ws-text-align-center'
        }, {
            headerName: 'Default Annual Rate Increase',
            headerClass: 'text-align-right',
            field: 'defaultAnnualRateIncrease',
            filter: 'agNumberColumnFilter',
            filterParams: AgGridFilterParams.numberFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
            width: AgGridColumns.numericColumnWidth,
            valueFormatter: (params) => {
                if(!params.data) {
                    return;
                }

                return  new Decimal(params.data.defaultAnnualRateIncrease).times(100).toFixed(2);
            },
            cellClass: 'text-align-right',
            sequence: 8
        }, {
            headerName: 'QC By',
            field: 'qcRequestUserFullName',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 5.1
        }, {
            headerName: 'QC Request Date',
            field: 'qcRequestTimeUtc',
            width: AgGridColumns.dateColumnWidth,
            filter: 'agDateColumnFilter',
            filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            valueFormatter: (params) => params.value ? this._datePipe.transform(params.value, true) : '',
            sequence: 5.2
        }, {
            headerName: 'Certification Date',
            field: 'certificationDate',
            width: AgGridColumns.dateColumnWidth,
            filter: 'agDateColumnFilter',
            filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
            valueFormatter: (params) => {
                const taxAuthority: TaxAuthority = params.data;
                if (!taxAuthority) {
                    return '';
                }
                return this._datePipe.transform(taxAuthority.certificationDate, true);
            },
            sequence: 6
        }];
    }

    refresh() {
        this._refreshDataSource();

        this._refreshAssessorCollectors();
    }

    private async _refreshAssessorCollectors() {
        if(!this.selectedStateId) {
            return;
        }

        this.assessorsCollectorsLoading = true;
        const promises: Promise<any>[] = [this.taxRateCommandCenterService.getAssessors(this.selectedStateId)];

        if(this.selectedAssessorId) {
            promises.push(this.taxRateCommandCenterService.getCollectors(this.selectedStateId, this.selectedAssessorId));
        } else {
            promises.push(this.taxRateCommandCenterService.getCollectors(this.selectedStateId));
        }

        const [assessors, collectors] = await Promise.all(promises);
        this.stateAssessors = assessors
            .sort(this._abbrSort)
            .map((a) => ({ name: `${a.abbr} ${(a.municipalityName) ? a.municipalityName : ''}`, value: a.assessorID }));

        if(this.selectedAssessorId) {
            if(!this.stateAssessors.some(x => x.value === this.selectedAssessorId)) {
                this.selectedAssessorId = null;
            }

            this.assessorCollectors = _.orderBy(collectors, 'abbr');

            if(this.selectedCollectorId && !this.assessorCollectors.some((x) => x.value === this.selectedCollectorId)) {
                this.selectedCollectorId = null;
            }
        } else {
            this.stateCollectors = collectors
                .sort(this._abbrSort)
                .map((a) => ({ name: `${a.abbr}`, value: a.collectorID }));

            if(this.selectedCollectorId && !this.stateCollectors.some(x => x.value === this.selectedCollectorId)) {
                this.selectedCollectorId = null;
            }
        }

        this.assessorsCollectorsLoading = false;
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this.gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): TaxRateDataSourceParams => {
            return {
                entityType: this.selectedGridSourceOption == 'areas' ? EntityType.TaxRateArea : EntityType.TaxAuthority,
                stateId: this.selectedStateId,
                assessorId: this.selectedAssessorId,
                collectorId: this.selectedCollectorId,
                onlyPendingQC: this.onlyPendingQC,
                taxYearBegin: this.taxRateCommandCenterService.taxYearBegin - this.taxRateCommandCenterService.taxYearsPrior,
                taxYearEnd: this.taxRateCommandCenterService.taxYearBegin
            };
        };

        this._gridDataSource = new TaxRateCommandCenterAgGridDataSource(
            this.gridApi,
            this.taxRateCommandCenterService,
            dataSourceParams
        );

        this.gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    protected getAreaColumns(): OrderedColDef[] {
        return [{
            headerName: 'Rate Area Name',
            field: 'name',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            sequence: 1
        }];
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        // Retrieve list of currently selected Tax Authority Ids (primarily used for Select All).
        const rowsSelectionModel: any = this.gridTracker.getSelectedRowsModel();
        const selectionOptions: TaxAuthoritySelectionOptions = {
            searchParams: this._gridDataSource.getSearchModelForBulkUpdate(),
            selectionType: this.gridTracker.isSelectAllChecked()
                                ? (rowsSelectionModel.selectedRows.length > 0
                                    ? TaxAuthoritySelectionType.AllButGiven
                                    : TaxAuthoritySelectionType.All)
                                : TaxAuthoritySelectionType.Given,
            selectedTaxAuthorityIds: rowsSelectionModel.selectedRows
        };

        const selections: TaxAuthorityToUpdate[] = await this.taxRateCommandCenterService.getTaxAuthoritySelectionsForBulkUpdate(selectionOptions);

        return Promise.resolve({
            data: selections.map(entry => entry.taxAuthorityId),
    		totalRows: selections.length,
    		lastModifiedTimestamp: new Date(),
    		totalValidRows: 0
        });
    }

    /*
    private async peekGrid() {
        const selection: any = this.gridTracker.getSelectedRowsModel();
        console.log(`selectAllRows=${selection.selectAllRows},  selectedRows.length=${selection.selectedRows.length}`);
        if ( selection.selectedRows.length > 0 ) console.log(`selectedRows=${JSON.stringify(selection.selectedRows)}`);
        const selIds: number[] = await this.gridTracker.getSelectedRowIds();
        console.log(`SelectedRowIds.length=${selIds.length}`);
        if ( selIds.length > 0 ) console.log(`SelectedRowIds=${JSON.stringify(selIds)}`);
        console.log(`hasFilteredRows=${this.gridTracker.hasFilteredRows()}  hasSelectedAllFilteredRows=${this.gridTracker.hasSelectedAllFilteredRows()}`);
        console.log(`hasSelectedRows=${this.gridTracker.hasSelectedRows()}`);
        console.log(`hasAnythingSelected=${this.gridTracker.hasAnythingSelected()}  SelectedRowsCount=${this.gridTracker.getSelectedRowsCount()}`);
        console.log(`TotalRowsCount=${this.gridTracker.getTotalRowsCount()}`);
        const selectedRows: any = this.gridApi.getSelectedRows();
        if ( selectedRows ) console.log(`SelectedRows=${JSON.stringify(selectedRows)}`);
        const selectedNodes: any = this.gridApi.getSelectedNodes();
        if ( selectedNodes ) console.log(`SelectedNodes=${JSON.stringify(selectedNodes)}`);
        selection.selectedRows.forEach((rowId) => {
            const rowNode = this.gridApi.getRowNode(rowId);
            if ( rowNode ) console.log(`rowNode[${rowId}].data=${JSON.stringify(rowNode.data)}`);
        });
        //this.gridApi.forEachNode((rowNode: RowNode, index: number) => {
        //    console.log(`rowNode[${index}].id=${rowNode.id}`);
        //    console.log(`rowNode[${index}].data=${JSON.stringify(rowNode.data)}`);
        //});
        this.gridApi.forEachNode((rowNode: RowNode, index: number) => {
            if ( selection.selectedRows.some((selId: number) => selId === rowNode.data.taxAuthorityId) ) {
                console.log(`rowNode[${index}].id=${rowNode.id}`);
                console.log(`rowNode[${index}].isSelected=${rowNode.isSelected()}`);
                console.log(`rowNode[${index}].data.taxAuthorityId=${rowNode.data.taxAuthorityId}`);
                console.log(`rowNode[${index}].data.name=${rowNode.data.name}`);
                console.log(`rowNode[${index}].data.code=${rowNode.data.code}`);
            }
        });
        const model: any = this.gridApi.getModel();
        return;
    }
    */
}
