import { OnInit, Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { BulkImportSitesModel, BulkImportResultModel } from '../bulk.import.model';
import { BulkImportService } from '../bulk.import.service';
import { TypeaheadCompany } from '../../Entity/Company/Company.Picker.Component';
import { DisplayStringArrayComponent } from '../Display-String-Array/display.String.Array.component';
import { DisplayStringArrayModel } from '../Display-String-Array/display.String.Array.model';

export enum ImportType { Site = 'site', Parcel = 'parcel' }

@Component({
    selector: 'bulk-import-sites',
    templateUrl: './bulk.import.sites.component.html',
    styleUrls: ['./bulk.import.sites.component.scss']
})
export class BulkImportSitesComponent implements OnInit {
    isImporting: boolean;
    initIndex: number;
    userCancelling: boolean;
    userCancelled: boolean;
    successfulImportCount: number;
    payload: BulkImportSitesModel;
    warnings: DisplayStringArrayModel;
    fileLines: string[];
    // TODO: We should probably rename this component and folders since it's now importing sites or parcels
    @Input('type')
    importType: ImportType;
    @ViewChild('fileInput', { static: true })
    fileInput: ElementRef;
    hasFile: boolean;
    outputFile: string = null;

    constructor(
        private bulkImportService: BulkImportService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.payload = new BulkImportSitesModel();
        this.payload.topCompanyID = null;
        this.hasFile = false;
        this.initValues();
    }

    initValues() {
        this.isImporting = false;
        this.userCancelled = false;
        this.userCancelling = false;
        this.initIndex = 1;
        this.successfulImportCount = 0;
        this.warnings = new DisplayStringArrayModel();
        this.warnings.stringArray = [];
    }

    clearFilePicker() {
        this.fileInput.nativeElement.value = '';
        this.fileLines = undefined;
        this.hasFile = false;
    }

    companySelected(selectedCompany: TypeaheadCompany) {
        if (selectedCompany.companyID)
            this.payload.topCompanyID = selectedCompany.companyID;
        else
            this.payload.topCompanyID = null;
    }

    fileChanged(e): void {
        const file: File = e.target.files[0];

        if (!file) {
            this.fileLines = undefined;
            this.hasFile = false;

            return;
        }
        this.hasFile = true;

        const fileReader: FileReader = new FileReader();

        fileReader.onload = (e) => {
            // get lines from file into array

            const fileText = fileReader.result;
            let lines: string[];
            if ((fileText as string).indexOf('\r') >= 0) {
                lines = (fileText as string).split('\r\n');
            }
            else {
                lines = (fileText as string).split('\n');
            }

            // Remove trailing blank lines
            let blankIndex = lines.length;
            while (lines[blankIndex - 1] === '') {
                blankIndex--;
            }
            lines = lines.slice(0, blankIndex);
            this.fileLines = lines;
        };

        fileReader.readAsText(file, 'CP1252');
    }

    startImporting(validateOnly): void {
        if (this.userCancelling) {
            this.userCancelling = false;
            this.userCancelled = true;
            return;
        }

        if (this.initIndex >= this.fileLines.length) {
            if(this.warnings.stringArray.length) {
                this._createOutputFile();
            }

            return;
        }

        this.isImporting = true;
        this.payload.validateOnly = validateOnly;
        // get first line (headers)
        this.payload.data = `${this.fileLines[0]  }\r\n`;

        // add more lines to the chink
        for (var i = this.initIndex; i < (50 + this.initIndex) && i < this.fileLines.length; i++) {
            this.payload.data += `${this.fileLines[i]  }\r\n`;
        }

        this.payload.totalProcessedRows = this.initIndex - 1;

        this.performImport(this.payload).then((bulkImportResult) => {
            // we are getting an object now, let's get number of lines etc
            this.warnings.stringArray = this.warnings.stringArray.concat(bulkImportResult.criticalErrors);
            this.warnings.stringArray = this.warnings.stringArray.concat(bulkImportResult.validationResults);

            this.successfulImportCount += bulkImportResult.numberOfValidRows;

            this.initIndex = i;

            this.startImporting(validateOnly);
        }).catch(() => {
            this.warnings.stringArray = this.warnings.stringArray.concat('A serious error was encountered which has caused the import to abort while in progress');
            this.cancelImport();
            this.startImporting(validateOnly);
        });
    }

    // https://stackoverflow.com/questions/21012580/is-it-possible-to-write-data-to-file-using-only-javascript
    private _createOutputFile() {
        const stringLines: string = _.reduce(this.warnings.stringArray, (finalString: string, line: string) => {
            finalString += `${line  }\r\n`;

            return finalString;
        }, '');

        const blob = new Blob([stringLines], { type: 'text/plain' });

        if(this.outputFile !== null) {
            window.URL.revokeObjectURL(this.outputFile);
        }

        this.outputFile= window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.setAttribute('download', 'import-warnings.txt');
        link.href = this.outputFile;
        document.body.appendChild(link);

        // wait for the link to be added to the document
        window.requestAnimationFrame(function () {
            const event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });
    }

    performImport(payload: BulkImportSitesModel): Promise<BulkImportResultModel> {
        switch (this.importType) {
            case ImportType.Parcel:
                return this.bulkImportService.importParcelData(payload);
            case ImportType.Site:
                return this.bulkImportService.importSiteData(payload);
            default:
                throw new Error(`Unrecognized import type: ${  this.importType}`);
        }
    }

    cancelImport(): void {
        this.userCancelling = true;
    }
}
