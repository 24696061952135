(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdUserPicker', sdUserPicker);

	// sdUserPicker.$inject = [];

	function sdUserPicker() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Team/User-Picker/_userPicker.html',
			scope: {
				userId: '=',
				assignee: '=?',
				closeFn: '&',
				clickFn: '&',
				pickerSm: '=?',
				appendToBody: '=?',
				isReassign: '=?',
				required: '=?',
				name: '=?'
			}
		};
		return directive;

		function link(scope, element, attrs, vm) {
		    // In this case we have a required attribute with no value, which means it is required
		    if (attrs.required === "") {
		        vm.required = true;
		    }
		}
	}

	Controller.$inject = ['teamsService', '$timeout'];

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit(teamsService, $timeout) {
		var vm = this;

		vm.users = [];
		vm.selectedUser = {};
		vm.assignee = vm.assignee || {};

		vm.formatInput = formatInput;
		vm.userSelected = userSelected;
		vm.filterUser = filterUser;
		vm.orderFn = orderFn;
		vm.changed = changed;
		vm.revertLabel = revertLabel;

		activate();

		function activate() {
			teamsService.getAllAssignableUsers(vm.isReassign)
				.then(function (result) {
				    console.log(["UserPicker get assignable users", _.cloneDeep(result)]);
					vm.users = result;

					vm.selectedUser = _.find(vm.users, { userID: vm.userId }) || {};
				})
		}

		function formatInput(model) {
			if (_.isEmpty(model)) {
				return;
			}

			return model.lastName + ', ' + model.firstName;
		}

		function userSelected($item) {
			vm.userId = $item.userID;
			vm.assignee = _.pick($item, 'userID', 'firstName', 'lastName');

			$timeout(vm.closeFn);
		}

		function filterUser(val) {
		    return _.uniqBy(_.filter(vm.users, function (user) {
                return _.includes(user.lastName.toLowerCase(), val.toLowerCase()) ||
                    _.includes(user.firstName.toLowerCase(), val.toLowerCase());
            }), function (user) {
                return user.userID;
            });
		}

		function orderFn(user) {
			return user.lastName + user.firstName;
		}

		function changed() {
			if (!vm.selectedUser) {
				vm.userId = null;
				vm.assignee = {};
			}
		}

		function revertLabel() {
			vm.selectedUser = _.find(vm.users, { userID: vm.userId }) || {};
		}
	}

})();
