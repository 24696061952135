import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, lastValueFrom } from 'rxjs';
import { ReturnFormRepository, ReturnFormRevisionReportRepository, ReturnReportRepository } from '../../../Repositories';
import { ReturnService } from '../../return.service';
import { ReturnPartServiceBase } from '../../Models/returnPartServiceBase';
import { TaskTypes } from '../../Models/enums';
import * as _ from 'lodash';
import { ReturnPreviewFormOverrideListResult } from './Form-Overrides-List/returnPreviewFormOverrideList.component';
import { FieldOverride, FieldOverrideHolder } from '../../../../Common/PDFViewer/pdfViewerBase';

export interface ReturnFormModel {
    mergeParcelId: number;
    returnId: number;
    returnFormRevisionId: number;
    returnFormRevisionReportId: number;
    formRevisionId: number;
    reportId: number; // saved search ID
    isExcel: boolean;
}

export interface ReturnPreviewServiceSharedState {
    returnForm: ReturnFormModel;
    navigateToOverride: Compliance.ReturnFormOverrideDetailModel;
}

export interface FieldOverrideWithRowVersion extends FieldOverride {
    rowVersion: number[];
}

export interface FormChanges {
    formRevisionId: number;
    returnMergedParcelIds: Compliance.ReturnMergedParcelIdModel[];
    overrides: FieldOverrideWithRowVersion[];
}

class HTTPStatusError extends Error {
    constructor(statusCode: number, message: string, ...params) {
        super(...params);

        // V8 only so cast as any
        if ((Error as any).captureStackTrace) {
            (Error as any).captureStackTrace(this, HTTPStatusError);
        }

        this.status = statusCode;
        this.message = message;
    }

    status: number;
    message: string;
}

@Injectable()
export class ReturnPreviewService extends ReturnPartServiceBase {
    constructor(
        private readonly _returnFormRepository: ReturnFormRepository,
        private readonly _returnFormRevisionReportRepository: ReturnFormRevisionReportRepository,
        private readonly _returnReportRepository: ReturnReportRepository,
        private readonly _returnService: ReturnService
    ) { super(); }

    private _sharedState: ReturnPreviewServiceSharedState = {
        returnForm: null
    } as ReturnPreviewServiceSharedState;

    private _formOverridesSubject: BehaviorSubject<Compliance.ReturnFormRevisionModel[]> = new BehaviorSubject<Compliance.ReturnFormRevisionModel[]>([]);
    private _returnFormSubject: BehaviorSubject<ReturnFormModel> = new BehaviorSubject(this._sharedState.returnForm);
    private _openPreviewByOverrideSubject: Subject<Compliance.ReturnFormOverrideDetailModel> = new Subject<Compliance.ReturnFormOverrideDetailModel>();
    private _returnFormOverrideListSubject: Subject<ReturnPreviewFormOverrideListResult> = new Subject<ReturnPreviewFormOverrideListResult>();
    private _fieldOverrideHolder: FieldOverrideHolder;

    set fieldOverrideHolder(fieldOverrideHolder: FieldOverrideHolder) {
        this._fieldOverrideHolder = fieldOverrideHolder;
    }

    get fieldOverrideHolder(): FieldOverrideHolder { return this._fieldOverrideHolder; }
    get sharedState(): ReturnPreviewServiceSharedState { return this._sharedState; }
    get returnForm$(): Observable<ReturnFormModel> { return this._returnFormSubject.asObservable(); }
    get formOverrides$(): Observable<Compliance.ReturnFormRevisionModel[]> { return this._formOverridesSubject.asObservable(); }
    get returnFormOverrideList$(): Observable<ReturnPreviewFormOverrideListResult> { return this._returnFormOverrideListSubject.asObservable(); }
    get openPreviewByOverrides$(): Observable<Compliance.ReturnFormOverrideDetailModel> { return this._openPreviewByOverrideSubject.asObservable(); }

    initialize(): void {
        this._sharedState = {
            returnForm: null,
            navigateToOverride: null
        };
    }

    notifyServiceActivationChange(isActive: boolean, state?: ReturnPreviewServiceSharedState): void {
        if (state) {
            this._sharedState = _.cloneDeep(state);
        }
        super.notifyServiceActivationChange(isActive);
    }

    notifyReturnFormOverrideListChanged(result: ReturnPreviewFormOverrideListResult) {
        this._returnFormOverrideListSubject.next(result);
    }

    setReturnForm(returnForm: ReturnFormModel, navigateToOverride: Compliance.ReturnFormOverrideDetailModel = null): void {
        this._sharedState.returnForm = returnForm;
        this._sharedState.navigateToOverride = navigateToOverride;
        this._returnFormSubject.next(this._sharedState.returnForm);
    }

    openPreviewByOverrides(overrideModel: Compliance.ReturnFormOverrideDetailModel) {
        this._openPreviewByOverrideSubject.next(overrideModel);
    }

    async getFormData(forceGetFieldData: boolean): Promise<Compliance.ReturnFormResultModel> {
        const request: Compliance.ReturnFormRequestModel = {
            mergeParcelId: this._returnService.isConsolidatedReturn ? this.sharedState.returnForm.mergeParcelId : null,
            returnId: this.sharedState.returnForm.returnId,
            formRevisionId: this.sharedState.returnForm.formRevisionId,
            reportOptions: null,
            requestType: Compliance.ReturnFormRequestTypeEnum.FieldData,
            flattenFields: false,
            forceGetFieldData: this._getFieldDataType(forceGetFieldData)
        };

        return await lastValueFrom(this._returnFormRepository.getFormData(this._returnService.filingBatchId, request));
    }

    async getFormTemplateAndData(forceGetFieldData: boolean): Promise<{ form: Blob, data: Compliance.ReturnFormResultModel }> {
        const request: Compliance.ReturnFormRequestModel = {
            mergeParcelId: this._returnService.isConsolidatedReturn ? this.sharedState.returnForm.mergeParcelId : null,
            returnId: this.sharedState.returnForm.returnId,
            formRevisionId: this.sharedState.returnForm.formRevisionId,
            reportOptions: null,
            requestType: (this.isFormInReviewMode()) ? Compliance.ReturnFormRequestTypeEnum.FieldData : Compliance.ReturnFormRequestTypeEnum.TemplateAndOverflowReports,
            flattenFields: false,
            forceGetFieldData: this._getFieldDataType(forceGetFieldData)
        };

        return await lastValueFrom(this._returnFormRepository.getFormAndData(this._returnService.filingBatchId, request));
    }

    async getReturnFormReport(returnId: number, formRevisionId: number, reportId: number): Promise<Blob> {
        try {
            const request: Compliance.ReturnReportRequestModel = {
                returnId: returnId,
                formRevisionId: formRevisionId,
                reportId: reportId
            };

            const response = await lastValueFrom(this._returnReportRepository.getReport(this._returnService.filingBatchId, request));
            return response.body;
        }
        catch (e) {
            throw new HTTPStatusError(e.status, e.error.message);
        }
    }

    isFormDataNeededOnPdf(): boolean {
        const status: Compliance.FilingBatchProcessStatusEnum = this._returnService.processStatus;
        const liveDataStatuses = [
            Compliance.FilingBatchProcessStatusEnum.NotStarted,
            Compliance.FilingBatchProcessStatusEnum.Started,
            Compliance.FilingBatchProcessStatusEnum.Locking
            ];

        return liveDataStatuses.includes(status) || this.isFormInReviewMode();
    }

    isFormInReviewMode(): boolean {
        const taskStatus: Core.TaskModel = this._returnService.currentTask;
        return (taskStatus && taskStatus.taskTypeID === TaskTypes.PPBatchReturnReviewReturns);
    }

    async updateFormFieldOverrides({formRevisionId, overrides, returnMergedParcelIds}: FormChanges): Promise<Compliance.ReturnFormOverrideModel[]> {
        const update: Compliance.ReturnFormOverridesRequestModel = {
            formRevisionId,
            overrides: overrides.map(({fieldName, overrideValue, rowVersion}: FieldOverrideWithRowVersion) => ({ fieldName, value: overrideValue, rowVersion })),
            returnMergedParcelIds
        }
        const result = await lastValueFrom(this._returnFormRepository.updateFormFieldOverrides(this._returnService.filingBatchId, update));
        await this.loadFormOverrides();
        return result;
    }

    async deleteFormFieldOverrides(formOverrideRequestModel: Compliance.ReturnFormOverrideRequestModel): Promise<void> {
        await lastValueFrom(this._returnFormRepository.deleteFormFieldOverrides(this._returnService.filingBatchId, formOverrideRequestModel));
        await this.loadFormOverrides();
    }

    async getFormFieldOverrides(formRevisionId, paginationModel: Core.PaginationModel, sortModel: Core.SortModel<Compliance.ReturnFormOverridePropertyEnum>[], returnIds: number[]): Promise<Compliance.QueryResultModel<Compliance.ReturnFormOverrideModel>> {
        const searchModel: Compliance.ReturnFormOverrideSearchModel = {
            formRevisionId: formRevisionId,
            returnIds: returnIds,
            pagination: paginationModel,
            sortColumns: sortModel
        }

        return await lastValueFrom(this._returnFormRepository.getFormFieldOverrides(this._returnService.filingBatchId, searchModel));
    }

    async loadFormOverrides(): Promise<void> {
        const overrides: Compliance.ReturnFormRevisionModel[] = await lastValueFrom(this._returnFormRepository.getFormOverrides(this._returnService.filingBatchId));

        this._formOverridesSubject.next(overrides);
    }

    getAssociatedReportsForReturns(): Observable<Compliance.ReturnFormRevisionReportModel[]> {
        const returnIds = _.map(_.filter(this._returnService.sharedState.returns, x => !!x.returnId), x => x.returnId);
        return this._returnFormRevisionReportRepository.getAssociatedWithReturns(this._returnService.filingBatchId, returnIds);
    }

    getNotAssociatedReportsForReturns(): Observable<Compliance.ReturnFormRevisionReportModel[]> {
        const returnIds = _.map(_.filter(this._returnService.sharedState.returns, x => !!x.returnId), x => x.returnId);
        return this._returnFormRevisionReportRepository.getNotAssociatedWithReturns(this._returnService.filingBatchId, returnIds);
    }

    async startDownloadForm(returnId: number, formRevisionId: number, mergeParcelId: number): Promise<number> {
        const request: Compliance.ReturnFormRequestModel = {
            mergeParcelId: mergeParcelId,
            returnId: returnId,
            formRevisionId: formRevisionId,
            reportOptions: null,
            requestType: Compliance.ReturnFormRequestTypeEnum.FormAndOverflowReports,
            flattenFields: this._returnService.isReturnInReadOnlyMode,
            forceGetFieldData: this._getFieldDataType(false)
        };

        return await lastValueFrom(this._returnFormRepository.startDownloadForm(this._returnService.filingBatchId, request));
    }

    async startDownloadReport(returnId: number, formRevisionId: number, reportId: number): Promise<number> {
        const request: Compliance.ReturnReportRequestModel = {
            returnId: returnId,
            formRevisionId: formRevisionId,
            reportId: reportId
        };

        return await lastValueFrom(this._returnReportRepository.startDownloadReport(this._returnService.filingBatchId, request));
    }

    private _getFieldDataType(force: boolean): Compliance.ReturnFormRequestDataTypeEnum {
        if (force) {
            return Compliance.ReturnFormRequestDataTypeEnum.FieldData;
        } else if (this.isFormInReviewMode()) {
            return Compliance.ReturnFormRequestDataTypeEnum.Overrides;
        } else {
            return Compliance.ReturnFormRequestDataTypeEnum.None;
        }
    }
}
