(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('ChangeSearchDefaultDisplayModalController', ChangeSearchDefaultDisplayModalController);

	ChangeSearchDefaultDisplayModalController.$inject = ['$uibModalInstance', 'advancedSearchService', 'search', 'searchType', 'DefaultDisplayTypes'];

	function ChangeSearchDefaultDisplayModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, advancedSearchService, search, searchType, DefaultDisplayTypes) {
        var vm = this;
        vm.defaultDisplayTypes = DefaultDisplayTypes;
        
        activate();

		function activate() {
            vm.search = search;
            vm.search.defaultDisplay = vm.search.defaultDisplay || DefaultDisplayTypes.CRITERIA;
		}

		vm.save = function() {
            advancedSearchService.patchSearch(vm.search, searchType)
                .then(function(result) {
                    vm.search.rowVersion = result.rowVersion;
                    $uibModalInstance.dismiss();
                });
		}

		vm.cancel = function() {
			$uibModalInstance.dismiss();
		}
	}
})();
