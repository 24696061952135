import { Component, Input } from '@angular/core';
import { BalanceAdjustmentsService } from './balanceAdjustments.service';

@Component({
    selector: 'accruals-balances-list',
    templateUrl: './accrualsBalancesList.component.html'
})
export class AccrualsBalancesListComponent {
    constructor(
        private readonly _balanceAdjustmentsService: BalanceAdjustmentsService
    ) { }

    @Input() parcelId: number;

    balances: Core.ParcelAccrualBalanceModel [];
    loading: boolean = false;

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.balances = (await this._balanceAdjustmentsService.getBalances(this.parcelId)).balances;
        } finally {
            this.loading = false;
        }
    }
}
