import { WeissmanKeyValueDisplayPipe } from '../../../../UI-Lib/Pipes/Key-Value-Display/keyValueDisplay-pipe';
import { ExtendedModelBase } from '../../../Models/extendedModelBase';

export class ExtendedSiteModel extends ExtendedModelBase<Core.SiteModel>  {
    constructor(model: Core.SiteModel, keyValueDisplayPipe: WeissmanKeyValueDisplayPipe) {
        super(model);
        this._keyValueDisplayPipe = keyValueDisplayPipe;
    }

    private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe;

    get displayName(): string {
        return this._keyValueDisplayPipe.transform(this.model, this.model.property, this.model.name);
    }
}
