import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TriStateCheckboxComponent } from './triStateCheckbox.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [
        TriStateCheckboxComponent
    ],
    exports: [
        TriStateCheckboxComponent
    ]
})
export class TriStateCheckboxModule {
}
