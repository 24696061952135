(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('BrowseColumnsModalController', BrowseColumnsModalController);

	BrowseColumnsModalController.$inject = ['$scope', 'columnsToExclude', '$uibModalInstance', 'advancedSearchService'];

	function BrowseColumnsModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, columnsToExclude, $uibModalInstance, advancedSearchService, taskSeriesTypeId) {
		var vm = this;

		vm.columnsToAdd = 0;

		vm.cancel = cancel;
		vm.addColumns = addColumns;
		vm.allColumnsDisabled = allColumnsDisabled;
		vm.toggleCategory = toggleCategory;
		vm.clearSelection = clearSelection;

		activate();

		////////////////

		function activate() {
			vm.title = columnsToExclude ? 'Add Output Columns' : 'Add Filter Columns';

			vm.columnCategories = advancedSearchService.getFilteredColumnCategories(columnsToExclude);

			var i = 0;

			$scope.$watch('vm.columnCategories', function () {
				// Hack to skip the first two watches (false positives)
				if (i++ < 2) {
					return;
				}

				
				var allColumns = advancedSearchService.getAllColumns(vm.columnCategories);
				
				vm.columnsToAdd = _.filter(allColumns, 'toAdd');

				_.forEach(vm.columnCategories, function(category) {
					category.selectedCount = _.filter(vm.columnsToAdd, {categoryID: category.categoryID}).length;

					_.forEach(category.subcategories, function(subcategory) {
						subcategory.selectedCount = _.filter(vm.columnsToAdd, {categoryID: subcategory.categoryID}).length;
						category.selectedCount += subcategory.selectedCount;
					});
				});

				// disable certain columns
				advancedSearchService.disableColumns(vm.columnsToAdd, true, taskSeriesTypeId);

			}, true)

		}

		function allColumnsDisabled(category) {
			var disableAllColumns = category.columns.length && _.every(category.columns, 'disabled');

			if (disableAllColumns) {
				category.isOpen = false;
			}

			return disableAllColumns;
		}

		function cancel() {
			clearSelection();
			$uibModalInstance.dismiss();
		}
		
		function addColumns() {
			$uibModalInstance.close(vm.columnsToAdd);
		}
		
		function toggleCategory(category) {
			if (!vm.allColumnsDisabled(category)) {
				category.isOpen = !category.isOpen;
			}
		}
		
		function clearSelection() {
			var allColumns = advancedSearchService.getAllColumns(vm.columnCategories);

			allColumns = _.map(allColumns, function (column) {
				column.toAdd = false;

				return column;
			});
		}
	}
})();
