<ws-modal-wrapper [headerLabel]="modalTitle"
                  acceptLabel="Remove Override"
                  [cancelLabel]="(override ? 'Cancel' : 'OK')"
                  [cancelHelpContentId]="(override ? 'app.cancel' : 'app.ok')"
                  (save)="revert()"
                  (cancel)="cancel()"
                  cancelClass="primary-button"
                  acceptClass="warning-button"
                  [hideSave]="!canEdit">
        <div *ngIf="ruleResults" class="rule-result-container">
            System-determined value: <strong>{{ oldValue !== "" ? oldValue : "not found" }}</strong>
            <div class="{{result.match ? 'rule-result' : 'rule-result-not-found'}}" *ngFor="let result of ruleResults; let i = index" [ngClass]="'rule-result' + (i + 1)">
                <i class="fa fa-angle-right"></i> {{ result.match ? 'Used value found in' : 'Tried to use'}}
                <span *ngIf="!isFilingRuleResult(result); else filing_rule_result">
                    <i>{{ getFieldEntityName(result.entityType) }} {{ result.entityField }}</i> field of
                    <a *ngIf="hasLink(result)" [href]="getEntityUrl(result)" target="_blank">{{ result.entityName }}</a>
                    <span *ngIf="!hasLink(result)">{{ result.entityName }}</span>.
                </span>
                <ng-template #filing_rule_result>
                    <i>{{ result.entityField }}</i> return settings.
                </ng-template>
            </div>
        </div>

        <div *ngIf="override" class="override-container">
            This field has been overridden.<br>
            <span class="override-label">System Value:</span> {{ oldValue }}<br>
            <span class="override-label">Override Value:</span> {{ newValue }}
        </div>
</ws-modal-wrapper>
