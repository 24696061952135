import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageModalComponent } from './message-modal.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { Observable } from 'rxjs';

export enum MessageModalButtons {
    OK = 1,
    OKCancel = 2,
    YesNo = 3,
    YesNoCancel = 4,
    Continue = 5
}

export enum MessageModalResult {
    OK = 1,
    Cancel = 2,
    Yes = 3,
    No = 4
}

export enum MessageModalDisplayMode {
    /**
     * Displays a single message.
     */
    SingleMessage = 1,
    /**
     * Displays a collection of messages.
     */
    MessageList = 2
}

export interface MessageModalOptions {
    displayMode: MessageModalDisplayMode;
    title?: string,
    subtitle?: string,
    message: string | string[],
    buttons: MessageModalButtons,
    focusButton?: MessageModalResult
    isErrorState?: boolean;
}

@Injectable()
export class MessageModalService {
    constructor(
        private _modalService: BsModalService,
        private _wsModalService: WeissmanModalService) { }

    alert(message: string, title?: string, isErrorState?: boolean): Promise<void> {
        const options: MessageModalOptions = {
            displayMode: MessageModalDisplayMode.SingleMessage,
            title: title,
            buttons: MessageModalButtons.OK,
            focusButton: MessageModalResult.OK,
            message: message,
            isErrorState
        };
        return this.open(options);
    }

    alertList(messages: string[], title?: string, subtitle?: string, isErrorState?: boolean): Promise<void> {
        const options: MessageModalOptions = {
            displayMode: MessageModalDisplayMode.MessageList,
            title: title,
            subtitle: subtitle,
            buttons: MessageModalButtons.OK,
            focusButton: MessageModalResult.OK,
            message: messages,
            isErrorState
        };
        return this.open(options);
    }

    confirm(message: string, title?: string, focusButton?: MessageModalResult): Promise<void> {
        const options: MessageModalOptions = {
            displayMode: MessageModalDisplayMode.SingleMessage,
            buttons: MessageModalButtons.OKCancel,
            focusButton: focusButton || MessageModalResult.OK,
            message: message,
            title: title
        };
        return this.open(options);
    }

    prompt(message: string, title?: string, focusButton?: MessageModalResult): Promise<void> {
        const options: MessageModalOptions = {
            displayMode: MessageModalDisplayMode.SingleMessage,
            buttons: MessageModalButtons.YesNo,
            focusButton: focusButton || MessageModalResult.Yes,
            message: message,
            title: title
        };
        return this.open(options);
    }

    async open(options: MessageModalOptions): Promise<void> {
        const componentState: any = {
            title: options.title,
            isErrorState: options.isErrorState
        };

        switch (options.displayMode) {
            case MessageModalDisplayMode.SingleMessage:
                componentState.message = options.message;
                break;

            case MessageModalDisplayMode.MessageList:
                componentState.subtitle = options.subtitle;
                componentState.messageList = options.message;
                break;

            default:
                throw new Error(`Unrecognized message modal display mode: ${options.displayMode}`);
        }

        switch (options.buttons || MessageModalButtons.OKCancel) {
            case MessageModalButtons.YesNoCancel:
                componentState.hasCancel = true;
                componentState.hasYes = true;
                componentState.hasNo = true;
                break;

            case MessageModalButtons.YesNo:
                componentState.hasYes = true;
                componentState.hasNo = true;
                break;

            case MessageModalButtons.OKCancel:
                componentState.hasCancel = true;
                componentState.hasOK = true;
                break;

            case MessageModalButtons.OK:
                componentState.hasOK = true;
                break;

            case MessageModalButtons.Continue:
                componentState.hasContinue = true;
                break;

            default:
                throw new Error(`Unrecognized button constant: ${options.buttons}`);
        }

        const showModalFn = (): BsModalRef => {
            return this._modalService.show(MessageModalComponent, {
                initialState: componentState,
                class: '',
                ignoreBackdropClick: true
            });
        };

        const bsModalRef = await this._wsModalService.show(showModalFn).whenClosed;

        const dialogAction: string = bsModalRef.content.result;
        if ([
            MessageModalResult[MessageModalResult.Cancel],
            MessageModalResult[MessageModalResult.No]
        ].includes(dialogAction)) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
}
