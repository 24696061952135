import { HelpContent } from '../../../../UI-Lib/Help-Tooltip';

export const FILING_BATCH_INFO_HELP: HelpContent[] = [
    {
        helpContentId: 'filing-batch-info.panel',
        tooltipText: 'Info that is shared by all filings in the batch.',
        hasIcon: true
    },
    {
        helpContentId: 'filing-batch-info.change-detection',
        tooltipText: 'When no prior return is selected for a filing, determine disposals and transfers out by comparing assets at the prior year lien date.',
        hasIcon: true
    }
];
