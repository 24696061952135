import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';

@Injectable()
export class GridLayoutSpecificationRepository extends BaseRepository {
    constructor(httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService) {
        super(httpClient);
    }

    private readonly _gridLayoutSettingName: string = 'Grid-Default-Layout';

    getList(searchModel: Compliance.GridLayoutSpecificationSearchModel): Observable<Compliance.QueryResultModel<Compliance.GridLayoutSpecificationModel>> {
        return this.httpPost(`/api/gridlayoutspecification/grid/${searchModel.gridId}`, searchModel);
    }

    update(specification: Compliance.GridLayoutSpecificationModel): Observable<Compliance.GridLayoutSpecificationModel> {
        return this.httpPut(`/api/gridlayoutspecification/${specification.gridLayoutSpecificationId}`, specification);
    }

    create(specification: Compliance.GridLayoutSpecificationModel): Observable<Compliance.GridLayoutSpecificationModel> {
        return this.httpPost('/api/gridlayoutspecification', specification);
    }

    delete(gridLayoutSpecificationId: number): Observable<void> {
        return this.httpDelete(`/api/gridlayoutspecification/${gridLayoutSpecificationId}`);
    }

    getGridLayoutUserSetting(gridId: System.Guid, companyId: number): Promise<number> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.GridLayouts);
        const setting = settings.find((x: any) => x.name === this._gridLayoutSettingName);
        if (!setting) {
            return Promise.resolve(null);
        }

        const gridSetting = (setting.value as Array<any>).find(x => x.gridId === gridId && ((!companyId) || x.companyId === companyId));

        if (!gridSetting) {
            return Promise.resolve(null);
        }

        return Promise.resolve(gridSetting.gridLayoutSpecificationId);
    }

    async updateGridLayoutUserSetting(gridId: System.Guid, companyId: number, gridLayoutSpecificationId: number): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.GridLayouts);
        let setting = settings.find(x => x.name === this._gridLayoutSettingName);
        if (!setting) {
            setting = {
                id:  0,
                name: this._gridLayoutSettingName,
                value: [],
                groupId: Core.UserSettingGroup.GridLayouts,
                folderId: Core.UserSettingFolder.Default
            };
        }

        const gridSetting = (setting.value as Array<any>).find(x => x.gridId === gridId && ((!companyId) || x.companyId === companyId));
        if (gridSetting) {
            gridSetting.gridLayoutSpecificationId = gridLayoutSpecificationId;
        } else {
            setting.value.push({
                gridId: gridId,
                companyId: companyId,
                gridLayoutSpecificationId: gridLayoutSpecificationId
            });
        }

        await this._userSettingsService.save(setting);
    }
}
