import { Component, Input } from '@angular/core'

@Component({
    selector: 'parenthetical-currency',
    template: `
        <span *ngIf="value >= 0">{{value | currency: 'USD':'symbol-narrow'}}</span>
        <span *ngIf="value < 0">({{math.abs(value) | currency: 'USD':'symbol-narrow'}})</span>
    `
})
export class ParentheticalCurrencyComponent {
    @Input() value: number;
    math = Math;

    constructor() { }
}