import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { TaxingDistrictComponent } from './taxingDistrict.component';
import { TaxingDistrictListComponent } from './Taxing-District-List/taxingDistrictList.component';
import { TaxingDistrictListGridActionCellRendererComponent } from './Taxing-District-List/agGridActionCellRenderer.component';

@NgModule({
    imports: [
        CommonModule,
        WeissmanSharedModule
    ],
    declarations: [
        TaxingDistrictComponent,
        TaxingDistrictListComponent,
        TaxingDistrictListGridActionCellRendererComponent
    ],
    exports: [
        TaxingDistrictComponent,
        TaxingDistrictListComponent
    ]
})
export class TaxingDistrictModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('taxingDistrict', TaxingDistrictComponent);
        hybridAdapterUtility.downgradeNg2Component('taxingDistrictList', TaxingDistrictListComponent);
    }
}
