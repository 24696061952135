import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../Compliance/WeissmanModalService';
import { ProtocolService, StateAssessorException } from './protocol.service';

export interface StateAssessorExceptionsParams {
    stateId: number;
    propertyTypeId: number;
    propertyTypeName: string;
}

@Component({
    selector: 'assessor-exceptions-modal',
    template: `
        <ws-modal-wrapper [headerLabel]="headerLabel" cancelLabel="Close" [hideSave]="true" (cancel)="close()">
        <loading-spinner [show]="loading" [size]="4" [hasOverlay]="false"></loading-spinner>
            <div style="height: 500px; overflow-y: auto;">
                <table class="table table-striped" style="padding: 10px 0px;" *ngIf="exceptions">
                    <thead>
                        <tr>
                            <th class="col-5" style="padding-left: 10px;">Assessor Abbr</th>
                            <th class="col-5" style="padding-left: 20px;">Assignee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let exception of exceptions">
                            <td class="col-5">
                                <a anchor-route [sref]="getAssessorSref(exception)">{{exception.assessor.abbr + ' ' + exception.assessor.municipalityName}}</a>
                            </td>
                            <td class="col-6">
                                <span *ngIf="!exception.siteClassSecondaryID"> {{exception.user.firstName}} {{exception.user.lastName}} ({{exception.team.name}}) </span>
                                <span *ngIf="exception.hasSiteClassExceptions">*</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="showFootnote" style="margin-top: 20px;">
                * Additional Site Class assignees exist (not shown)
            </div>
        </ws-modal-wrapper>
    `,
})
export class StateAssessorExceptionsModalComponent implements OnInit, IWeissmanModalComponent<StateAssessorExceptionsParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _protocolService: ProtocolService
    ) { }

    params: StateAssessorExceptionsParams;
    result: void;

    loading: boolean = false;
    exceptions: StateAssessorException[];
    propertyType: string;
    deadlineType: string;
    isEarliestPossibleDeadline: boolean = false;
    headerLabel: string;

    showFootnote: boolean = false;

    async ngOnInit() {
        this.headerLabel = `Assessor Exceptions - ${this.params.propertyTypeName}`;
        this.loading = true;

        try {
            this.exceptions = await this._protocolService.getExceptionsByStateXPropertyType(this.params.stateId, this.params.propertyTypeId);
            this.showFootnote = _.some(this.exceptions, 'hasSiteClassExceptions');

        } finally {
            this.loading = false;
        }
    }

    getAssessorSref(exception: StateAssessorException) {
        return {
            target: 'assessor',
            options: {
                stateId: this.params.stateId,
                id: exception.assessorID,
            }
        };
    }

    close() {
        this._bsModalRef.hide();
    }
}
