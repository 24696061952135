import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';


@Component({
    selector: 'add-user-group-modal',
    template: `
        <ws-modal-wrapper headerLabel="Add User Group"
                  (save)="save()"
                  acceptLabel="Save"
                  [disableSave]="!name"
                  (cancel)="cancel()">

                  <div class="form-group">
                      <label>Name</label>
                      <input #nameInput type="text" class="form-control" [(ngModel)]="name">
                  </div>
                  <div class="form-group">
                      <label>Description</label>
                      <textarea class="form-control" rows="3" [(ngModel)]="description"></textarea>
                  </div>

        </ws-modal-wrapper>
    `,
})
export class AddUserGroupModalComponent implements AfterViewInit, IWeissmanModalComponent<void, Core.GroupModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef
    ) { }

    @ViewChild('nameInput', { static: true }) nameInput: ElementRef;

    name: string;
    description: string;

    params: void;
    result: Core.GroupModel;

    ngAfterViewInit() {
        setTimeout(() => {
            this.nameInput.nativeElement.focus();
        });
    }

    save() {
        this.result = {
            name: this.name,
            description: this.description
        } as Core.GroupModel;

        this._bsModalRef.hide();
    }

    cancel() {
        this._bsModalRef.hide();
    }
}
