<tabset *ngIf="isInitialized" #tabset class="ws-tabset-flex return-parts">
    <tab [heading]="overviewTab.heading" (deselect)="tabDeselected($event)" (selectTab)="tabSelected($event)" [disabled]="!canEnterEditMode">
        <return-overview class="return-part"></return-overview>
    </tab>
    <tab [heading]="assetsTab.heading" (deselect)="tabDeselected($event)" (selectTab)="tabSelected($event)" [disabled]="!canEnterEditMode">
        <return-assets class="return-part"></return-assets>
    </tab>
    <tab [heading]="previewTab.heading" (deselect)="tabDeselected($event)" (selectTab)="tabSelected($event)" [disabled]="!canEnterEditMode">
        <return-preview class="return-part"></return-preview>
    </tab>
    <tab [heading]="settingsTab.heading" (deselect)="tabDeselected($event)" (selectTab)="tabSelected($event)" [disabled]="!canEnterEditMode">
        <return-settings-tab class="return-part"></return-settings-tab>
    </tab>
    <tab [heading]="outputTab.heading" (deselect)="tabDeselected($event)" (selectTab)="tabSelected($event)" [disabled]="!canEnterEditMode">
        <return-output class="return-part"></return-output>
    </tab>
</tabset>
