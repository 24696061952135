import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from "../../AgGrid/MultiSelectTracker";
import { lastValueFrom, Subscription } from "rxjs";
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from "../../AgGrid";
import { WeissmanDateTimeFormatPipe } from "../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe";
import { FactorTableRepository } from "../../Repositories";
import {
    FactorTableListGridActionCellRendererComponent,
    ICellRendererParamsForFactorTableListGridAction
} from "../Factor-Table-List/agGridActionCellRenderer.component";

@Component({
    selector: 'form-library-export',
    templateUrl: './factorTableExportAttachments.component.html',
    styleUrls: ['./factorTableExportAttachments.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FactorTableExportAttachmentsComponent implements IWeissmanModalComponent<void, number[]>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _factorTableRepository: FactorTableRepository,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _factorTables: Compliance.FactorTableListItemModel[];

    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data &&
                this.gridTracker.isRowSelected((params.data as Compliance.FactorTableListItemModel).factorTableId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    params: void;
    result: number[];

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    rowsSelected: boolean = false;

    cancel(): void {
        this._bsModalRef.hide();
    }

    async ngOnInit(): Promise<void> {
        const busyMsg = "Loading";

        this.busyIndicatorMessageManager.add('Loading', busyMsg);
        try {
            const searchModel: Compliance.FactorTableSearchModel = {
                includeRetiredTables: false,
                hasAttachments: true
            };
            this._factorTables = await lastValueFrom(this._factorTableRepository.getList(searchModel));
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'factorTableId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'State',
                field: 'state',
                width: AgGridColumns.stateAbbreviationColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) => {
                    const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTableGridItem) {
                        return '';
                    }
                    return factorTableGridItem.assessorName ? factorTableGridItem.assessorName : factorTableGridItem.state;
                },
                cellClass: (params: ICellRendererParams): string => {
                    const factorTableGridItem = params.data as Compliance.FactorTableListItemModel;
                    if (!factorTableGridItem) {
                        return '';
                    }
                    return factorTableGridItem.assessorName ? '' : 'assessor-state';
                }
            },
            {
                headerName: 'Type',
                field: 'typeName',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Table Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed By',
                field: 'changedByUserName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed Date',
                field: 'changeDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._dateTimePipe.transform(x.value, true)
            }
        ];

        const defaultSortModel = [
            {
                colId: 'state',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async export(): Promise<void> {
        this.result = await this.gridTracker.getSelectedRowIds();

        this._bsModalRef.hide();
        return Promise.resolve();
    }

    private _setRowData() {
        if (!this._gridApi) {
            return;
        }

        this._gridApi.setRowData(this._factorTables);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const formRevision = x.data as Compliance.FactorTableListItemModel;
                return formRevision && formRevision.factorTableId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
