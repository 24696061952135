export interface ReportingParcelTile extends Core.ParcelTileDTO {
    isExternalReportingParcel?: boolean;
    isReportingParcel?: boolean;
}

export interface ParcelListControlRow extends ReportingParcelTile {
    isTotalRow: boolean;
    returnCount: number;
    totalFMV: number;
    totalTaxes: number;
    totalComponents: {[componentName: string]: number};
    siteID?: number;
    companyID?: number;
    originalIsReportingParcel?: boolean;
    originalConsolidatingTypeId?: number;
    originalConsolidatedParcelId?: number;
}

export class ParcelList {
    constructor(
        private readonly _incomingParcels: Core.ParcelTileDTO[]) {
        this._initialize();
    }

    private _tiles: ParcelListControlRow[] = [];
    private _parcels: ParcelListControlRow[] = [];

    private _parcelsRepository: ParcelListControlRow[] = [];

    get tiles(): ParcelListControlRow[] {
        return this._tiles;
    }

    get parcels(): ParcelListControlRow[] {
        return this._parcels;
    }

    filterInactive(show: boolean): void {
        if (show) {
            const filtered = this._parcelsRepository.filter((p) => p.activityStatusID !== 0);
            this._tiles = filtered;
            this._parcels = [...filtered, this._getTotalsRow(filtered)];
        } else {
            this._tiles = [...this._parcelsRepository];
            this._parcels = [...this._parcelsRepository, this._getTotalsRow(this._parcelsRepository)];
        }
    }

    private _initialize() {
        const parcels = this._incomingParcels.map(parcel => {
            return {
                ...parcel,
                ...{
                    isTotalRow: false,
                    returnCount: 1,
                    originalConsolidatingTypeId: parcel.consolidatingTypeId,
                    originalConsolidatedParcelId: parcel.consolidatedParcelId
                }
            } as ParcelListControlRow;
        }).sort((i1, i2) => {
            return i1.acctNum.localeCompare(i2.acctNum);
        });

        this._parcelsRepository = parcels;
        this._tiles = parcels;
        this._parcels = [
            ...parcels,
            this._getTotalsRow(parcels)
        ];
    }

    private _getTotalsRow(parcels: ParcelListControlRow[]): ParcelListControlRow {
        const totalComponents = {};
        parcels.forEach(p => {
            if (p.components && p.components.length) {
                p.components.forEach(c => {
                    if (!totalComponents[c.componentName]) {
                        totalComponents[c.componentName] = 0;
                    }
                    totalComponents[c.componentName] += c.fairMarketValue;
                });
            }
        });
        return {
            isFmvInclude: true,
            isTotalRow: true,
            returnCount: parcels.length,
            totalFMV: parcels.reduce(
                (acc: number, x: ParcelListControlRow) => (x.totalFMV && x.isFmvInclude) ? (acc += x.totalFMV) : acc,
                0),
            totalTaxes: parcels.reduce(
                (acc: number, x: ParcelListControlRow) => (x.totalTaxes) ? (acc += x.totalTaxes) : acc,
                0),
            totalComponents
        } as ParcelListControlRow;
    }
}
