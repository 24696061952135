import { ButtonSize, ButtonState, ButtonStyle } from '@ace/shared';
import { Component } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { IButtonGroupInput } from '../../UI-Lib/ACE/Button-Group/buttonGroup.component';
import { SearchCategoryType } from '../User-Save/userSave.service';

@Component({
    selector: 'saved-action-view',
    templateUrl: './savedActionView.component.html',
    styleUrls: ['./savedActionView.component.scss']
})
export class SavedActionViewComponent {
    constructor(private readonly _router: UIRouter,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService) {
    }

    tabSetConfig: IButtonGroupInput = {
        buttons: [
            {
                id: SearchCategoryType.System,
                content: 'SYSTEM ACTION VIEWS',
                routerOutlet: 'savedActionView.system',
                size: ButtonSize.Small
            },
            {
                id: SearchCategoryType.User,
                content: 'MY SAVED ACTION VIEWS',
                routerOutlet: 'savedActionView.user',
                size: ButtonSize.Small
            },
            {
                id: SearchCategoryType.Favorite,
                content: 'FAVORITES',
                routerOutlet: 'savedActionView.favorite',
                size: ButtonSize.Small
            }
        ],
        singleSelect: {
            activeStyle: ButtonStyle.Primary,
            inactiveStyle: null,
            activeState: ButtonState.Info,
            inactiveState: ButtonState.Info
        }
    };

    newActionView(): void {
        this._actionViewPersistenceService.clearSavedData();
        this._productAnalyticsService.logEvent('click-act-new');
        this._router.stateService.transitionTo('actionview');
    }
}
