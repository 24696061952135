import { Component, OnInit } from '@angular/core';
import { BusyIndicatorService } from '../../Busy-Indicator/busyIndicator.service';
import { DXPService } from './dxp.service';

@Component({
    selector: 'test-dxp-page',
    templateUrl: './testDXPPage.component.html'
})
export class TestDXPPageComponent implements OnInit {
    message: string;
    isDisabled: boolean;

    constructor(
        private readonly _busyService: BusyIndicatorService,
        private readonly _dxpService: DXPService
    ) { }
    
    async ngOnInit(): Promise<void> {
        const busyRef = this._busyService.show({ message: 'Loading' });
        this.isDisabled = false;

        try {
            const dxpMode = await this._dxpService.getDXPMode();
            if (dxpMode === Core.DXPSyncModes.Disabled) {
                this.message = "DXP Sync Test disabled";
                this.isDisabled = true;
            }
        } finally {
            busyRef.hide();
        }
    }

    async sync() {
        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            await this._dxpService.testDXPSync();
        } finally {
            busyRef.hide();
        }

    }
}