import { NgModule } from '@angular/core';
import { PermissionModule } from '../Permission/permission.module';
import { UserGroupsGridActionCellRendererComponent } from './User-Group-List/agGridActionCellRendererComponent';
import { UserGroupListComponent } from './User-Group-List/userGroupList.component';
import { UserGroupDetailComponent } from './User-Group-Detail/userGroupDetail.component';
import { UserGroupInfoComponent } from './User-Group-Detail/User-Group-Info/userGroupInfo.component';
import { AddUserGroupModalComponent } from './User-Group-List/addUserGroupModal.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';

@NgModule({
    imports: [
        WeissmanSharedModule,
        PermissionModule
    ],
    declarations: [
        UserGroupsGridActionCellRendererComponent,
        UserGroupListComponent,
        UserGroupDetailComponent,
        UserGroupInfoComponent,
        AddUserGroupModalComponent
    ],
    exports: [
        UserGroupListComponent,
        UserGroupDetailComponent
    ]
})
export class UserGroupModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('userGroupList', UserGroupListComponent);
        hybridAdapterUtility.downgradeNg2Component('userGroupDetail', UserGroupDetailComponent);
    }
}
