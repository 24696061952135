import { HelpContent } from './UI-Lib/Help-Tooltip';

export const APP_HELP: HelpContent[] = [
    {
        helpContentId: 'app.attachments',
        tooltipText: 'Attachments'
    },
    {
        helpContentId: 'app.cancel',
        tooltipText: 'Cancel'
    },
    {
        helpContentId: 'app.clear-filter',
        tooltipText: 'Clear Filter'
    },
    {
        helpContentId: 'app.close',
        tooltipText: 'Close'
    },
    {
        helpContentId: 'app.collapse',
        tooltipText: 'Collapse'
    },
    {
        helpContentId: 'app.comments',
        tooltipText: 'Comments'
    },
    {
        helpContentId: 'app.continue',
        tooltipText: 'Continue'
    },
    {
        helpContentId: 'app.date-picker',
        tooltipText: 'Select date'
    },
    {
        helpContentId: 'app.disabled-edit-mode',
        tooltipText: 'Not available in edit mode.  Save changes or cancel.'
    },
    {
        helpContentId: 'app.disabled-busy',
        tooltipText: 'Not available because a process is busy.'
    },
    {
        helpContentId: 'app.delete',
        tooltipText: 'Delete'
    },
    {
        helpContentId: 'app.delete-disabled',
        tooltipText: 'Not available to delete'
    },
    {
        helpContentId: 'app.download',
        tooltipText: 'Download'
    },
    {
        helpContentId: 'app.edit',
        tooltipText: 'Edit'
    },
    {
        helpContentId: 'app.edit-disabled',
        tooltipText: 'Not available to edit'
    },
    {
        helpContentId: 'app.edit-end',
        tooltipText: 'End edit mode'
    },
    {
        helpContentId: 'app.edit-task-series',
        tooltipText: 'View or edit tasks'
    },
    {
        helpContentId: 'app.expand',
        tooltipText: 'Expand'
    },
    {
        helpContentId: 'app.export',
        tooltipText: 'Export'
    },
    {
        helpContentId: 'app.filter',
        tooltipText: 'Filter'
    },
    {
        helpContentId: 'app.form',
        tooltipText: 'Form'
    },
    {
        helpContentId: 'app.navigate',
        tooltipText: 'Navigate'
    },
    {
        helpContentId: 'app.new-data-import',
        tooltipText: 'New data import'
    },
    {
        helpContentId: 'app.ok',
        tooltipText: 'OK'
    },
    {
        helpContentId: 'app.refresh',
        tooltipText: 'Refresh'
    },
    {
        helpContentId: 'app.remove',
        tooltipText: 'Remove'
    },
    {
        helpContentId: 'app.report',
        tooltipText: 'Report'
    },
    {
        helpContentId: 'app.reporting-parcel',
        tooltipText: 'Site\'s default parcel for compliance assets'
    },
    {
        helpContentId: 'app.site-consolidated-parcel',
        tooltipText: 'Site-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'app.state-consolidated-parcel',
        tooltipText: 'State-consolidated parcel for compliance returns'
    },
    {
        helpContentId: 'app.site-merged-parcel',
        tooltipText: 'Merged parcel (site consolidated) for compliance returns'
    },
    {
        helpContentId: 'app.state-merged-parcel',
        tooltipText: 'Merged parcel (state consolidated) for compliance returns'
    },
    {
        helpContentId: 'app.save',
        tooltipText: 'Save changes'
    },
    {
        helpContentId: 'app.save-as',
        tooltipText: 'Save As'
    },
    {
        helpContentId: 'app.save-disabled',
        tooltipText: 'Not available to save'
    },
    {
        helpContentId: 'app.undo-remove',
        tooltipText: 'Undo remove'
    },
    {
        helpContentId: 'app.upload',
        tooltipText: 'Upload'
    },
    {
        helpContentId: 'app.validate',
        tooltipText: 'Validate'
    },
    {
        helpContentId: 'app.view',
        tooltipText: 'View'
    },
    {
        helpContentId: 'app.view-assessor',
        tooltipText: 'View assessor'
    },
    {
        helpContentId: 'app.view-assets',
        tooltipText: 'View assets'
    },
    {
        helpContentId: 'app.view-company',
        tooltipText: 'View company'
    },
    {
        helpContentId: 'app.view-contact',
        tooltipText: 'View contact'
    },
    {
        helpContentId: 'app.view-filing',
        tooltipText: 'View filing'
    },
    {
        helpContentId: 'app.view-filing-batch',
        tooltipText: 'View return batch'
    },
    {
        helpContentId: 'app.view-overrides',
        tooltipText: 'View overrides'
    },
    {
        helpContentId: 'app.view-parcel',
        tooltipText: 'View parcel'
    },
    {
        helpContentId: 'app.view-payment',
        tooltipText: 'View payment'
    },
    {
        helpContentId: 'app.view-collector',
        tooltipText: 'View collector'
    },
    {
        helpContentId: 'app.view-transmittal',
        tooltipText: 'View transmittal'
    },
    {
        helpContentId: 'app.view-state',
        tooltipText: 'View state'
    },
    {
        helpContentId: 'app.view-return',
        tooltipText: 'View return'
    },
    {
        helpContentId: 'app.view-return-form',
        tooltipText: 'View return form'
    },
    {
        helpContentId: 'app.view-site',
        tooltipText: 'View site'
    },
    {
        helpContentId: 'app.view-task-series',
        tooltipText: 'View tasks'
    },
    {
        helpContentId: 'app.view-assessor-website',
        tooltipText: 'View assessor website'
    },
    {
        helpContentId: 'app.send-email-assessor',
        tooltipText: 'Send email to assessor'
    },
];
