import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AgentsPageComponent } from './agents.page.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';

@NgModule({
    imports: [ WeissmanSharedModule ],
    declarations: [ AgentsPageComponent ],
    exports: [ AgentsPageComponent ]
})
export class AgentsModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('agentsPage', AgentsPageComponent);
    }
}
