import { Component, OnInit, Renderer2 } from '@angular/core';
import { ContractsInvoicesService } from './contract-invoices.service';
import { Engagement } from './contract-invoices.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { TimerService } from '../../UI-Lib/Utilities/timer.service';

@Component({
    selector: 'engagement-number-lookup-modal',
    templateUrl: './engagement-number-lookup-modal.component.html'
})
export class EngagementNumberLookupModalComponent implements OnInit {
    isLoading: boolean = false;
    searchTerm: string = '';
    engagementResults: Engagement[] = [];
    selectedEngagement: Engagement;
    onClose: any;
    initialSearch: boolean = false;
    title: string;

    constructor(private readonly _contractsInvoicesService: ContractsInvoicesService,
                public bsModalRef: BsModalRef,
                private readonly _modalService: BsModalService,
                private readonly _renderer2: Renderer2,
                private readonly _toastr: ToastrService,
                private readonly _timer: TimerService) { }

        ngOnInit() {
            this._timer.setTimeout(() => {
                let onElement = this._renderer2.selectRootElement('#engagementSearch');
                onElement.focus();
            });
        }

        search(): void {
            if(!this.searchTerm) {
                return;
            }

            this.initialSearch = true;
            this.isLoading = true;
            this._contractsInvoicesService.searchEngagements(this.searchTerm)
                .then((result: Engagement[]) => {
                    if(result.length === 100) {
                        this._toastr.warning('Please refine search to see more.', 'Only 100 records displayed!')
                    }

                    this.engagementResults = _.sortBy(result, 'engagementID');
                    this.isLoading = false;
                });
        }

        selectEngagement(engagement: Engagement) {
            this.onClose(engagement)
            this.bsModalRef.hide();
        }
}
