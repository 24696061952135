<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Add Company Permissions"
                  headerHelpContentId="filing-batch-add-accounts.panel"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!gridTracker?.getSelectedRowsCount()">
    <div style="display: flex; align-items: center; justify-content: end; margin: 0;" class="checkbox">
        <label>
            <input type="checkbox" [(ngModel)]="excludeInactive" (change)="onExcludeInactive()"> Exclude Inactive
        </label>
    </div>
    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
    <br />
    <div class="mb-3" *ngIf="gridTracker?.getSelectedRowsCount()">
        <label for="permission">Company Permissions:</label><br>
        <select id="permission" name="permission" class="form-select form-select-sm" [(ngModel)]="selectedPermissionType">
            <option *ngFor="let p of permissionTypes;" [value]="p">
                {{p}}
            </option>
        </select>
    </div>
</ws-modal-wrapper>
