import { PaymentBatchCommandCenterComponent } from './paymentBatch.component';
import { ColDef } from 'ag-grid-community';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { AgGridColumns, AgGridFilterParams } from '../../Compliance/AgGrid';
import { ICellRendererParamsForPaymentBatchListGridAction, PaymentBatchListGridActionCellRendererComponent } from './agGridActionCellRenderer.component';

// const getTooltipText = (params: AgGridTooltipCellRendererParams, id: number): string => {
//     const model = params.data as Compliance.AssessorCommandCenterModel;
//     if (model && model.verifications && model.verifications.length) {
//         const found = model.verifications.find(val => val.elementId === id);
//         return found && found.verifiedByName;
//     }
// };

export const PAYMENT_BATCH_LIST_COLUMNS = (classRef: PaymentBatchCommandCenterComponent): ColDef[] => [
    // {
    //     colId: 'grid-column-multiselect',
    //     headerName: '',
    //     field: 'assessorId',
    //     width: AgGridColumns.selectionColumnWidth,
    //     suppressSizeToFit: true,
    //     suppressAutoSize: true,
    //     suppressColumnsToolPanel: true,
    //     editable: false,
    //     pinned: 'left',
    //     lockPinned: true,
    //     lockVisible: true,
    //     lockPosition: true,
    //     suppressMovable: true,
    //     headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
    //     headerComponentParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
    //     cellRendererFramework: AgGridMultiSelectedCellRenderer,
    //     cellRendererParams: { tracker: classRef.gridTracker } as AgGridMultiSelectRendererParams,
    // } as ColDef,
    {
        headerName: 'Batch No',
        field: 'batchNo',
        width: AgGridColumns.textColumnWidth,
        pinned: 'left',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        suppressMovable: true,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Batch Status',
        field: 'batchStatus',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Payment Count',
        field: 'paymentCount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFilterWithBlankOptionsParams
    },
    {
        headerName: 'Company',
        field: 'company',
        width: AgGridColumns.textColumnWidth,
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
    },
    {
        headerName: 'Description',
        field: 'description',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnLargeWidth
    },
    {
        headerName: 'Batch Type',
        field: 'batchTypeName',
        filter: 'agTextColumnFilter',
        filterParams: AgGridFilterParams.textFilterParams,
        floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
        width: AgGridColumns.textColumnLargeWidth
    },
    {
        headerName: 'Earliest Due Date',
        field: 'earliestDueDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchModel;
            if (!(model && model.earliestDueDate)) {
                return '';
            }

            return WeissmanDateFormat(model.earliestDueDate, true, 'stored');
        }
    },
    {
        headerName: 'Funding Due Date',
        field: 'fundingDueDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchModel;
            if (!(model && model.fundingDueDate)) {
                return '';
            }

            return WeissmanDateFormat(model.fundingDueDate, true, 'stored');
        }
    },
    {
        headerName: 'Amount',
        field: 'paymentAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Funded Amount',
        field: 'fundedAmount',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
        valueFormatter: x => classRef.formatCurrency(x.value),
        cellClass: 'text-align-right',
    },
    {
        headerName: 'Export Num',
        field: 'totalChecksExported',
        width: AgGridColumns.numericColumnWidth,
        filter: 'agNumberColumnFilter',
        filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.numberFilterWithBlankOptionsParams
    },
    {
        headerName: 'Completed Date',
        field: 'completedDate',
        width: AgGridColumns.dateColumnWidth,
        filter: 'agDateColumnFilter',
        filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
        floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
        valueFormatter: (params) => {
            const model = params.data as Core.PaymentBatchModel;
            if (!(model && model.completedDate)) {
                return '';
            }

            return WeissmanDateFormat(model.completedDate, true, 'stored');
        }
    },
    {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        width: AgGridColumns.getActionColumnWidth(4),
        minWidth: AgGridColumns.getActionColumnWidth(4),
        maxWidth: AgGridColumns.getActionColumnWidth(4),
        lockPinned: true,
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        resizable: false,
        sortable: false,
        cellRendererFramework: PaymentBatchListGridActionCellRendererComponent,
        cellRendererParams: {
            viewDetails: classRef.onViewDetails.bind(classRef),
            delete: classRef.delete.bind(classRef),
            canDelete: classRef.canDelete.bind(classRef),
            canEdit: classRef.canEdit
        } as ICellRendererParamsForPaymentBatchListGridAction
    }
];
