import * as _ from 'lodash';
import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { EntityImportRepository } from '../../../Repositories';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../AgGrid';

export class EntityImportEditorAgGridDataSource extends AgGridDataSourceBase<void> {
    constructor(
        private _gridApi: GridApi,
        private _entityImportRepository: EntityImportRepository,
        private _importId: number
    ) { super(_gridApi); }

    protected canGetRows(): boolean {
        return !!this._importId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const skip = params.startRow;
        const take = params.endRow - params.startRow;
        const searchModel: Compliance.ImportGridSearchModel = {
            pagination: {skip: skip, take: take} as Core.PaginationModel,
            validationErrors: [],
            columnFilters: [],
            showTransferredRows: false,
            showValidRowsOnly: false,
            estimatedActionFilter: null,
            sortColumns: [],
            includeTotals: false,
            includeOnlyTotals: false
        }

        const result = await lastValueFrom(this._entityImportRepository.getImportRows(this._importId, searchModel));

        const rows = _.sortBy(result.data, ['rowIndex']);

        // for each row we are given a collection of fields with their index and their value
        // for the grid, we will flatten all those fields into one object where
        // the key will be the field index, the value will be the current value
        const gridRows: Compliance.ImportGridRow[] = rows.map((i: Compliance.ImportGridRow) => {
            const gridRow: Compliance.ImportGridRow = {} as Compliance.ImportGridRow;

            // if the current values are null then load them from the original values
            i.fields.forEach((f) => { gridRow[f.fieldIndex] = f.currentValue === null ? f.originalValue : f.currentValue; });

            // set the rowIndex property for each row
            gridRow.rowIndex = i.rowIndex;
            return gridRow;
        });

        return {
            rows: gridRows,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
