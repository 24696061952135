import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { IncomeStatementCategoryPickerComponent } from './Income-Statement/Income-Statement-Category-Selector/incomeStatementCategoryPicker.component';
import { IncomeStatementCategorySelectorComponent } from './Income-Statement/Income-Statement-Category-Selector/incomeStatementCategorySelector.component';
import { IncomeStatementListComponent } from './Income-Statement/Income-Statement-List/incomeStatementList.component';
import { RentRollGridActionCellRendererComponent } from './Rent-Roll/Rent-Roll-List/agGridActionCellRendererComponent';
import { RentRollListComponent } from './Rent-Roll/Rent-Roll-List/rentRollList.component';
import { ValuationTemplatesModule } from './Templates/valuationTemplates.module';

@NgModule({
    imports: [
        WeissmanSharedModule,
        ValuationTemplatesModule
    ],
    declarations: [
        IncomeStatementCategorySelectorComponent,
        IncomeStatementCategoryPickerComponent,
        IncomeStatementListComponent,
        RentRollGridActionCellRendererComponent,
        RentRollListComponent
    ],
    exports: [
        ValuationTemplatesModule,
        IncomeStatementCategorySelectorComponent,
        IncomeStatementListComponent,
        RentRollListComponent
    ]
})
export class ValuationModule {
    static setupModule() {
        ValuationTemplatesModule.setupModule();

        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('incomeStatementList', IncomeStatementListComponent);
        hybridAdapterUtility.downgradeNg2Component('rentRollList', RentRollListComponent);
    }
}
