import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BusyIndicatorService } from 'src/app/Busy-Indicator';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { BreadCrumb } from 'src/app/UI-Lib/Bread-Crumb/breadCrumbs.component';
import { MessageModalService } from 'src/app/UI-Lib/Message-Box/messageModal.service';
import { UserInstanceService } from 'src/app/User/userInstance.service';
import { LoginManagerService } from './loginManager.service';

@Component({
    selector: 'login-manager',
    templateUrl: './loginManager.component.html'
})
export class LoginManagerComponent implements OnInit {
    userId: string;
    user: Core.UserLoginInfoModel;
    contact: Core.ContactLoginInfoModel;
    homeInstanceName: string;
    pageTitle: string = 'Login Manager';
    instances: { name: string, id: number }[];
    selectedInstance: { name: string, id: number };
    breadcrumbs: BreadCrumb[] = [{
        name: 'Contacts',
        target: 'contacts',
        options: {}
    }];
    userActionState: 'None' | 'ManageIAMUser' | 'ManageIAMTenant' | 'UpdateLockedFields';
    iamUserData: Core.IAMUserDTO;
    iamTenantData: Core.IAMTenantDTO;
    updatingUserIAMId: boolean;
    newIAMId: System.Guid;
    updatingUserTenantId: boolean;
    newIAMTenantId: System.Guid;

    // Locked fields
    newFirstName: string;
    newLastName: string;
    newEmail: string;
    newIsMigrated: boolean;

    constructor(
        private _busyIndicatorService: BusyIndicatorService,
        private _userInstanceService: UserInstanceService,
        private _toastr: ToastrService,
        private _routerService: UpgradeNavigationServiceHandler,
        private _messageModalService: MessageModalService,
        private _loginManagerService: LoginManagerService) {
    }

    async ngOnInit() {
        if (!this._userInstanceService.isCurrentInstanceSystem()) {
            this._toastr.error('You must be in the support instance to use this page.');
            return;
        }

        this.userId = this._routerService.getQuerystringParam('userId');

        if (!/^[\da-fA-F]{8}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{4}-[\da-fA-F]{12}$/.test(this.userId)) {
            this._toastr.error('Invalid userId.');
            return;
        }

        this.selectedInstance = null;

        await this.loadUser();
    }

    selectedInstanceChanged() {
        this.loadContact();
    }

    async loadUser() {
        this.cancelUserAction();

        const busyIndicator = this._busyIndicatorService.show({ message: 'Loading User' });
        try {
            this.user = await this._loginManagerService.loadUser(this.userId);

            // Look for the user home instance in the list of contacts to get the instance name, default to the instance ID itself if not found
            this.homeInstanceName = this.user.contacts.find(c => c.instanceId == this.user.instanceId)?.instanceName ?? `${this.user.instanceId}`;

            this.instances = this.user.contacts.map(c => ({ name: c.instanceName, id: c.instanceId }));

            // Either keep the last selected instance or default to the user's home instance
            const instanceId = this.selectedInstance?.id ?? this.user.instanceId;
            this.selectedInstance = this.instances.find(i => i.id == instanceId) ?? this.instances[0];

            this.loadContact();

            this.breadcrumbs = [
                this.breadcrumbs[0],
                {
                    name: `${this.user.firstName} ${this.user.lastName}`,
                    target: 'contacts',
                    options: {
                        contactId: this.user.contacts.find(c => c.instanceId === this._userInstanceService.SystemInstanceId).contactID
                    }
                }];
        } finally {
            busyIndicator.hide();
        }
    }

    loadContact() {
        this.contact = this.user.contacts.find(c => c.instanceId == this.selectedInstance.id);
    }

    cancelUserAction() {
        this.userActionState = 'None';
    }

    startManageIAMUser() {
        this.iamUserData = null;
        this.updatingUserIAMId = false;
        this.userActionState = 'ManageIAMUser';
    }

    startManageIAMTenant() {
        this.iamTenantData = null;
        this.updatingUserTenantId = false;
        this.userActionState = 'ManageIAMTenant';
    }

    startUpdateLockedFields() {
        this.newFirstName = this.user.firstName;
        this.newLastName = this.user.lastName;
        this.newEmail = this.user.email;
        this.newIsMigrated = this.user.iamIsMigrated;
        this.userActionState = 'UpdateLockedFields';
    }

    async getIAMUserData() {
        const busyIndicator = this._busyIndicatorService.show({ message: 'Loading User' });
        try {
            this.iamUserData = await this._loginManagerService.getIAMUserData(this.userId);
        } finally {
            busyIndicator.hide();
        }
    }

    startUpdateUserIAMId() {
        this.updatingUserIAMId = true;
        this.newIAMId = this.user.iamId;
    }

    async saveUserIAMId() {
        await this.performUserAction('Saving user', async () => {
            await this._loginManagerService.updateLockedFields(this.userId, {
                iamId: this.newIAMId,
                email: null,
                firstName: null,
                lastName: null,
                iamIsMigrated: null
            });
        });
    }

    cancelUpdateUserIAMId() {
        this.updatingUserIAMId = false;
    }

    async unMigrateUser() {
        try {
            await this._messageModalService.confirm('Are you sure you want to un-migrate this user?', 'Confirm');
        }
        catch (e) {
            return;
        }

        await this.performUserAction('Unmigrating user', async () => {
            await this._loginManagerService.unmigrateUser(this.userId);
        });
    }

    async getIAMTenantData() {
        const busyIndicator = this._busyIndicatorService.show({ message: 'Loading Tenant' });
        try {
            this.iamTenantData = await this._loginManagerService.getIAMTenantData(this.userId);
        } finally {
            busyIndicator.hide();
        }
    }

    startUpdateUserIAMTenantId() {
        this.newIAMTenantId = this.user.iamTenantId;
        this.updatingUserTenantId = true;
    }

    async removeUserIAMTenant() {
        try {
            await this._messageModalService.confirm('Are you sure you want to remove the IAM Tenant Id from this user?', 'Confirm');
        }
        catch (e) {
            return;
        }

        await this.performUserAction('Updating user', async () => {
            await this._loginManagerService.removeUserIAMTenant(this.userId);
        });
    }

    async saveUserIAMTenantId() {
        await this.performUserAction('Saving user', async () => {
            await this._loginManagerService.updateUserIAMTenantId(this.userId, this.newIAMTenantId);
        });
    }

    cancelUpdateUserIAMTenantId() {
        this.updatingUserTenantId = false;
    }

    toggleNewIsMigrated() {
        this.newIsMigrated = !this.newIsMigrated;
    }

    async saveLockedFields() {
        await this.performUserAction('Saving user', async () => {
            await this._loginManagerService.updateLockedFields(this.userId, {
                firstName: this.user.firstName === this.newFirstName ? null : this.newFirstName,
                lastName: this.user.lastName === this.newLastName ? null : this.newLastName,
                email: this.user.email === this.newEmail ? null : this.newEmail,
                iamId: null,
                iamIsMigrated: this.user.iamIsMigrated === this.newIsMigrated ? null : this.newIsMigrated
            });
        });
    }

    async performUserAction(message: string, action: () => Promise<void>) {
        const busyIndicator = this._busyIndicatorService.show({ message: message });
        try {
            await action();

            await this.loadUser();
            this.updatingUserIAMId = false;
            this.updatingUserTenantId = false;
            this._toastr.success('Update complete.');
        } finally {
            busyIndicator.hide();
        }
    }
}
