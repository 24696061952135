<ws-modal-wrapper [headerLabel]="modalTitle"
                  (save)="save()"
                  (cancel)="cancel()"
                  [useAceStyles]="true">
    <div class="row">
        <div class="d-flex justify-content-center align-items-center">
            <label class="d-inline-block me-2 mb-0">Name:</label>
            <input style="width: 285px;"
                   type="text"
                   [(ngModel)]="actionView.actionViewName"
                   maxlength="100"
                   class="form-control">
        </div>
    </div>

    <div class="row" *ngIf="actionViewType === 0">
        <div class="d-flex justify-content-center align-items-center">
            <label>
                <input [(ngModel)]="actionView.isRyanInternal" type="checkbox"> Only visible to Ryan personnel
            </label>
        </div>
    </div>
</ws-modal-wrapper>
