(function () {
	'use strict';

	var VIEW_TEMPLATE_URL = 'app/Team/Assignment/_teamAssignmentPanel.html';
	//	var CONTROLLER_NAME = 'sdTeamAssignmentPanelController';

	angular
		.module('weissmanApp')
		.directive('sdTeamAssignmentPanel', sdTeamAssignmentPanel);

	sdTeamAssignmentPanel.$inject = ['teamsService', 'toastr', 'utils','SD.Restrict.Service','InstanceRights','instanceRepository','EntityTypeIDs', 'messageBoxService'];

	function sdTeamAssignmentPanel(teamsService, toastr, utils, restrictService, InstanceRights,instanceRepository,EntityTypeIDs, messageBoxService) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			templateUrl: VIEW_TEMPLATE_URL,
			// controllerAs: 'vm',
			link: link,
			restrict: 'E',
			scope: {
				user: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {
			var cleanAssignedTeams = [];

			scope.unaddedTeams = [];
			scope.loading = false;
			scope.newTeam = {};
			scope.newTeamForTasks = {};
			scope.originalDefault = {};
			scope.canEdit = false;
			scope.isEditMode = false;

			scope.addTeam = addTeam;
			scope.updateDefault = updateDefault;
			scope.checkLinkedEntities = checkLinkedEntities;
			scope.chooseNewTeam = chooseNewTeam;
			scope.cancelChooseNewTeam = cancelChooseNewTeam;
			scope.nonDeletedFilter = utils.nonDeletedFilter;
			scope.addedTeams = addedTeams;
			scope.removeTeam = removeTeam;
			scope.save = save;
			scope.isSaveDisabled = isSaveDisabled;
			scope.addTeamRow = addTeamRow;
			scope.isLrpRunning = false;

			scope.switchToEditMode = function() {
				scope.isEditMode = true;
			}

			scope.cancel = function() {
				scope.assignedTeams = angular.copy(cleanAssignedTeams);
				scope.isEditMode = false;
			}

			activate();

			function addedTeams(team) {
				return _.every(scope.assignedTeams, function(assignedTeam) {
					return assignedTeam.teamID !== team.teamID || assignedTeam.efAction === 'delete';
				})
			}

			async function activate() {
				//get instance id
				scope.loading = true;

				try {
					const instanceId = await instanceRepository.getEntityInstanceId('company', scope.user.companyID).toPromise();
					scope.canEdit = restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP, instanceId);
	
					if (scope.user.userID) {
						var filter = {
							getFullInfo: true,
							includeCompanyRepresentative: true
						};
	
						const teams =  await teamsService.getAllContactUserTeams(scope.user.contactID, filter);
						processAssignedTeams(teams);
	
						const response = await teamsService.getAllTeamsForContact(false, instanceId, scope.user.userID);
						scope.allTeams = angular.copy(response);
					}
				} finally {
					scope.loading = false;
				}
			}

			function processAssignedTeams(teams) {
				scope.assignedTeams = teams;
				cleanAssignedTeams = angular.copy(teams);
				scope.originalDefault = _.find(teams, { isDefault: true });
			}

			function updateDefault(team) {
				_.each(scope.assignedTeams, function (item) {
					item.isDefault = (item.teamID === team.teamID);
				});
			}

			function checkLinkedEntities(team) {
				if(!team.isAssignable) {
					team.loading = true;
					teamsService.hasLinkedEntities(scope.user.userID, team.teamID)
						.then(function(result) {
							//console.log(result);
							if(result.hasLinkedEntities) {
								if(_.some(scope.assignedTeams, 'isAssignable')) {
									team.chooseNew = true;
									team.message = result.message + ' Please choose another team to which they can be assigned.';
								} else {
									team.isAssignable = true;

									toastr.info(result.message + ' Please add another team that is assignable before removing this one.');
								}
							}

							team.loading = false;
						})
				}
			}

			function cancelChooseNewTeam(team) {
				team.isAssignable = true;
				team.chooseNew = false;
			}

			function chooseNewTeam(editedTeam) {
				var chosenTeam = scope.newTeamForTasks.team;
				editedTeam.chooseNew = false;
				editedTeam.newTeamID = chosenTeam.teamID;

				chosenTeam.isLocked = true;
			}

			function removeTeam(team) {
				if(team.efAction === 'add') {
					_.remove(scope.assignedTeams, team);
				} else {
					team.efAction = 'delete';
				}
			}

			function addTeam(team) {
				var isDefault = false;
				var team = scope.newTeam.team;
				if (scope.assignedTeams.length === 0 ||
					_.findIndex(scope.assignedTeams, { 'isDefault': true }) === -1) {
					isDefault = true;
				}
                if(team) {
				var deletedTeamIdx = _.findIndex(scope.assignedTeams, {teamID: team.teamID});

				if(deletedTeamIdx !== -1) {
					scope.assignedTeams[deletedTeamIdx].efAction = null;
					scope.assignedTeams[deletedTeamIdx].isDefault = false;

					return;
				} 

				var addedTeam = {
					isAssignable: true,
					isDefault: isDefault,
					teamID: team.teamID,
					userID: scope.user.userID,
					teamName: team.name,
					efAction: 'add',
					firstName: scope.user.firstName,
					lastName: scope.user.lastName,
					numberOfClientServiceResponsibilities: 0,
					newTeamID: null
				};

				scope.assignedTeams.push(addedTeam);

				if (isDefault) {
					scope.originalDefault = addedTeam;
				}
			}
				scope.addNewTeam = false;
			}

			function addTeamRow() {
				scope.addNewTeam = true;
				return;
			}

			scope.$on('modal.closing', function (event) {
				if(scope.isEditMode) {
					event.preventDefault();
	
					messageBoxService.confirm('Editing is in progress. Are you sure you want to leave?').then(function() {
						scope.isEditMode = false;
						event.targetScope.$dismiss();
					});
				}
			});

			function save() {
				var teamsToSave = _.map(angular.copy(scope.assignedTeams), function(team) {
					var strippedTeam = _.omit(team, 'isLocked', 'loading', 'chooseNew');

					if(!team.efAction) {
						var cleanTeam = _.find(cleanAssignedTeams, {teamID: strippedTeam.teamID});

						if(!_.isEqual(strippedTeam, cleanTeam)) {
							strippedTeam.efAction = 'update';
						}
					} 

					return strippedTeam;
				});

				scope.loading = true;
				teamsService.saveTeamAssignmentTab(teamsToSave) 
					.then(processAssignedTeams(teamsToSave))
					.finally(function() {
						scope.isEditMode = false;
						scope.loading = false;
						scope.isLrpRunning = true;
					})
			}

			function isSaveDisabled() {
				return _.some(scope.assignedTeams, function(team) {
					return team.loading || team.chooseNew;
				})
			}
		}
	}
})();
