<ws-busy-indicator *ngIf="serverAction"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Add Company" [disableSave]="addCompanyForm.invalid" (save)="save()" (cancel)="cancel()">
    <form [formGroup]="addCompanyForm">
        <div class="mb-4" [class.has-error]="addCompanyForm.controls.companyName.invalid && addCompanyForm.controls.companyName.touched">
            <label>Company Name</label>
            <input #companyName type="text" formControlName="companyName" maxlength="100" class="form-control" required>
        </div>
        <div class="mb-4" [class.has-error]="addCompanyForm.controls.companyCode.invalid && addCompanyForm.controls.companyCode.touched">
            <label>Company Code</label>
            <input type="text" formControlName="companyCode" maxlength="100" class="form-control">
        </div>
        <div class="mb-4" [class.has-error]="addCompanyForm.controls.accountHandlerUserID.invalid && addCompanyForm.controls.accountHandlerUserID.touched">
            <label>Account Handler</label>
            <user-team-picker style="width: 500px;"
                [entityTypeScope]="entityTypeScope"
                [entityIdScope]="entityIdScope"
                (userChange)="accountHandlerSelected($event)">
            </user-team-picker>
        </div>
    </form>
</ws-modal-wrapper>
