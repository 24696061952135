import { HelpContent } from '../../../../UI-Lib/Help-Tooltip';

export const AG_GRID_LAYOUT_SPECIFICATION_HELP: HelpContent[] = [
    {
        helpContentId: 'ag-grid-layout-specification.copy',
        tooltipText: 'Save a copy of this layout.'
    },
    {
        helpContentId: 'ag-grid-layout-specification.no-layout',
        tooltipText: 'Select a layout.'
    },
    {
        helpContentId: 'ag-grid-layout-specification.edit-default',
        tooltipText: 'The built-in default layout cannot be saved.'
    },
    {
        helpContentId: 'ag-grid-layout-specification.delete-default',
        tooltipText: 'The built-in default layout cannot be deleted.'
    }
];
