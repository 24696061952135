<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="Add Filings"
                  headerHelpContentId="filing-batch-add-accounts.panel"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="!gridTracker?.getSelectedRowsCount()">
    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [prefix]="null" [suffix]="null"></ag-grid-row-count>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
