import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import { ReportType } from '../report-type';
import KeyValuePair = System.Collections.Generic.KeyValuePair;

enum SupportInstanceEscalationActivityReportGroupByEnum {
    SupportInstance = Core.SupportInstanceEscalationActivityReportGroupByEnum.SupportInstance as number,
    User = Core.SupportInstanceEscalationActivityReportGroupByEnum.User as number
}
@Component({
    selector: 'format-output-panel-support-instance-escalation-activity',
    template: `
        <div class="panel-flat-body report-form-groups">
            <div class="report-form-group">
                <div class="row list-row">
                    <div class="col-md-1"><label>Output Format:</label></div>
                    <div class="col-md-2">
                        <select [(ngModel)]="report.criteria.outputFormat" class="form-select-sm">
                            <option *ngFor="let option of outputFormatOptions;" [ngValue]="option.key">{{option.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="row list-row">
                    <div class="col-md-1"><label>Group By</label></div>
                    <div class="col-md-2">
                        <select [(ngModel)]="report.criteria.escalationGroupBy" class="form-select-sm">
                            <option [ngValue]="SupportInstanceEscalationActivityReportGroupByEnum.SupportInstance">Support Instance</option>
                            <option [ngValue]="SupportInstanceEscalationActivityReportGroupByEnum.User">User</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./format.output.panel.scss']
})
export class FormatOutputPanelSupportInstanceEscalationActivityComponent implements OnInit{
    @Input() report: ReportDetail;
    outputFormatOptions: KeyValuePair<number, string>[];
    reportTypes = ReportType;

    SupportInstanceEscalationActivityReportGroupByEnum = SupportInstanceEscalationActivityReportGroupByEnum;

    ngOnInit() {
        this.outputFormatOptions = [
            {
                key: Core.SupportInstanceEscalationActivityReportOutputFormatEnum.PDF,
                value: 'PDF'
            },
            {
                key: Core.SupportInstanceEscalationActivityReportOutputFormatEnum.Excel,
                value: 'Excel'
            }];
        this.report.criteria.outputFormat = this.report.criteria.outputFormat || Core.SupportInstanceEscalationActivityReportOutputFormatEnum.PDF;
        this.report.criteria.escalationGroupBy = Core.SupportInstanceEscalationActivityReportGroupByEnum.SupportInstance;
    }
}
