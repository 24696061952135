import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { BillingScenarioModalComponent, BillingScenarioModalParams } from './New-Scenario/billingScenarioModal.component';
import { YearPickerMixin } from '../../../UI-Lib/Mixins';

@Component({
    selector: 'billing-scenario',
    templateUrl: './billingScenario.component.html',
    styleUrls: ['./billingScenario.component.scss']
})
export class BillingScenarioComponent extends YearPickerMixin() implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _weissmanModalService: WeissmanModalService
    ) {
        super();
    }

    @Input() collector;
    @Output() collectorUpdated: EventEmitter<void> = new EventEmitter();

    selectedYear: number;
    viewOnly: boolean;
    showDisabled: boolean;
    showYearWarning: boolean;

    paymentOrder = (payment): number => {
        const dt = payment.dueMonthDay.split('/');
        const monthDay = parseInt(dt[0] + '.' + dt[1]);
        return (payment.dueYearID * 100) + monthDay;
    };

    ngOnInit(): void {
        this.viewOnly = this._restrictService.isNotInRoles([Roles.COLLECTOREDIT]) && !this.collector.isCompanyCollector;
        this.selectedYear = new Date().getFullYear();
    }

    toWords(id: number): number {
        switch (id) {
            case -1:
                return this.selectedYear - 1;
            case 0:
                return this.selectedYear;
            case 1:
                return this.selectedYear + 1;
            case 2:
                return this.selectedYear + 2
        }
    };

    async openScenario(scenario?: any): Promise<void> {
        try {
            const params = {
                collector: this.collector,
                inputScenario: scenario
            } as BillingScenarioModalParams;

            const result = await this._weissmanModalService.showAsync(BillingScenarioModalComponent, params, 'modal-xl');

            if (result) {
                this.collectorUpdated.emit();
            }
        } catch(err) {}
    };

    yearChanged(year: string): void {
        this.selectedYear = +year;
        this.showYearWarning = +year < new Date().getFullYear();
    }
}
