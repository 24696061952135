<ws-modal-wrapper [headerLabel]="title"
                  [disableSave]="loading || (!allowBlank && !commentBody)"
                  [disableCancel]="loading"
                  [suppressKeyboardEvents]="true"
                  [useAceStyles]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [marginTop]="0"></loading-spinner>
    <div class="form-horizontal" *ngIf="!loading">
        <div *ngIf="showFull">
            <div class="row mb-4 align-items-center" *ngIf="yearIsRelevant">
                <label for="comment-year-selection" class="col-md-2 control-label">Year</label>
                <div class="col-md-10">
                    <select id="comment-year-selection"
                            name="taxYear"
                            class="form-select form-select-sm"
                            [(ngModel)]="year"
                            [disabled]="yearDisabled">
                        <option *ngFor="let year of availableYears" [value]="year.value">{{year.display}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-4 align-items-center">
                <label for="comment-title-input" class="col-md-2 control-label">Title</label>
                <div class="col-md-10">
                    <input type="text"
                           required
                           maxlength="255"
                           class="form-control"
                           id="comment-title-input"
                           [(ngModel)]="commentTitle"/>
                </div>
            </div>
            <div class="row mb-4">
                <label for="comment-description-input" class="col-md-2 control-label">Description</label>
                <div class="col-md-10">
                <textarea class="form-control"
                          maxlength="10000"
                          id="comment-description-input"
                          [(ngModel)]="commentBody"
                          [readonly]="readOnly"
                          rows="10"
                          style="resize: none;">
                </textarea>
                </div>
            </div>
            <div class="row mb-4" *ngIf="hasInstancePrivateView">
                <label for="comment-ryan-permissions" class="col-12" style="font-weight: normal">
                    <input id="comment-ryan-permissions" [(ngModel)]="isRyanInternal" type="checkbox">
                    Only visible to
                    <span *ngIf="isSingleRyanInstance">Ryan</span>
                    <span *ngIf="!isSingleRyanInstance">Internal</span>
                    personnel
                </label>
            </div>
        </div>
        <div *ngIf="!showFull">
            <textarea class="form-control" cols="40" rows="5" [(ngModel)]="commentBody" autofocus [readonly]="readOnly"></textarea>
        </div>
    </div>
</ws-modal-wrapper>

