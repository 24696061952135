(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('TasksModalController', TasksModalController);

	TasksModalController.$inject = ['$uibModalInstance', 'tasks', 'taskService', '$scope'];

	function TasksModalController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($uibModalInstance, tasks, taskService, $scope) {
		var vm = this;
		var heights = {
			sm: 50,
			md: 75,
			lg: 100,
			xl: 125
		};

		vm.chevronWidth = 244;
		vm.chevronPadding = 10;
		vm.taskSeriesPadding = 10;
		vm.waitingOnServer = false;

		vm.closeModal = closeModal;
		vm.updateData = updateData;
		vm.getPlacement = getPlacement;

		activate();

		////////////////

		$scope.$on('modal.closing', function (event, result, closed) {
			if (vm.waitingOnServer) {
				event.preventDefault();
			}
		});

		function activate() {
			vm.taskGroupsBySequence = _.map(tasks, getTaskGroupHeights);
		}

		function getTaskGroupHeights(taskGroupCollection) {
			taskGroupCollection.taskGroups = _.chain(taskGroupCollection.taskGroups)
				.sortBy([function (taskGroup) {
					var maxDueDate = _.chain(taskGroup.tasks)
						.map(function (task) {
							return task.dueDate ? moment(task.dueDate).startOf('day').toDate().getTime() : null;
						})
						.compact()
						.max()
						.value();

					return maxDueDate === -Infinity ? null : maxDueDate;
				}, function (taskGroup) {
					return taskGroup.tasks[0].entityId;
				}])
				.map(function (taskGroup) {
					taskGroup.height = _.reduce(taskGroup.tasks, function (result, task) {
						return result + getHeight(task);
					}, 0);
					taskGroup.height += vm.chevronPadding;
					taskGroup.height += _.last(taskGroup.tasks).pointsTo.length ? vm.chevronPadding : heights.lg;

					return taskGroup;
				})
				.value();

			var maxHeight = _.maxBy(taskGroupCollection.taskGroups, 'height').height;

			taskGroupCollection.taskGroups = _.map(taskGroupCollection.taskGroups, function (taskGroup) {
				taskGroup.maxHeight = maxHeight;
				return taskGroup;
			});

			return taskGroupCollection;
		}

		function updateData(data) {
			vm.taskGroupsBySequence = _.map(data, getTaskGroupHeights);
		}

		function getHeight(task) {
			if (task.isReady) {
				return isDeliverable(task) ? heights.xl : heights.lg;
			} else if (isDeliverable(task)) {
				return heights.md;
			} else {
				return heights.sm;
			}
		}

		function isDeliverable(task) {
			return task.deliverableName || task.dueDate;
		}

		function closeModal() {
			$uibModalInstance.dismiss();
		}

		function getPlacement(index) {
			if (index < 2 && vm.taskGroupsBySequence && taskService.getMaxTasksWide(vm.taskGroupsBySequence) > 2) {
				return 'right';
			} else {
				return 'left'
			}
		}
	}
})();
