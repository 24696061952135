<ws-modal-wrapper headerLabel="Lien Date"
                  (save)="save()"
                  (cancel)="cancel()">
    <div class="ws-flex-container-horizontal">
        <label class="ws-flex-none" [for]="params.lienDateItem.isCustom ? 'customLienDate' : 'standardLienDate'">Lien Date:</label>
        <div class="ws-flex-container-horizontal ws-flex-align-items-center">
<!--            <select name="standardLienDate" id="standardLienDate" class="form-control asset-lien-date-dropdown" [(ngModel)]="params.lienDateItem" (ngModelChange)="standardLienDateChanged($event)">-->
<!--                <option *ngFor="let ldi of params.lienDateItems;trackBy: trackByFn" [ngClass]="{ 'asset-lien-date-current' : ldi.isCurrent }" [ngValue]="ldi">{{ ldi.isCustom  ? 'Non-Standard' : (ldi.date | weissmanDateFormat) }}</option>-->
<!--            </select>-->
            <asset-lien-date id="standardLienDate"
                             size="md"
                             style="margin-left: 8px"
                             [providedLienDateInfo]="params.lienDateInfo"
                             [selectedLienDate]="params.lienDateItem.date"
                             (dateChange)="standardLienDateChanged($event)">
            </asset-lien-date>
            <div class="ws-flex-container-horizontal">
                <weissman-datetime [(inputDate)]="lienDate.date"
                                   [dateOnly]="true"
                                   (datePickerClosed)="customLienDateChanged($event)"
                                   *ngIf="params.lienDateItem.isCustom"
                                   name="customLienDate"
                                   [required]="true"
                                   id="customLienDate"
                                   [className]="isLienDateCustom ? 'asset-lien-date-custom': ''">
                </weissman-datetime>
            </div>
        </div>
    </div>
    <div class="ws-flex-container-vertical">
        <div class="ws-flex-container-vertical">
            <label for="priorLienDateType"
                   class="ws-font-weight-normal"
                   helpTooltip
                   helpContentId="asset-lien-date.prior-lien-date"
                   position="bottom">
                <input id="priorLienDateType" type="radio" name="lienDateType" [value]="lienDateTypes.Prior" [(ngModel)]="lienDate.lienDateType" />
                Assets present on or carried forward to lien date
            </label>
            <label for="exactLienDateType"
                   class="ws-font-weight-normal"
                   helpTooltip
                   helpContentId="asset-lien-date.exact-lien-date"
                   position="bottom">
                <input id="exactLienDateType" type="radio" name="lienDateType" [value]="lienDateTypes.Exact" [(ngModel)]="lienDate.lienDateType" />
                Assets present or confirmed present on lien date
            </label>
        </div>
        <div class="ws-flex-container-vertical">
            <label class="ws-flex-none ws-font-weight-normal"
                   helpTooltip
                   [helpContentId]="stateLienDateHelpContentId"
                   position="bottom">
                <input type="checkbox" [disabled]="params.lienDateItem.isCustom" [(ngModel)]="lienDate.useStateLienDate"> Consider only states utilizing {{ params.lienDateItem.isCustom ? 'standard' : (lienDate.date | weissmanMonthDayDateFormat) }} lien date
            </label>
        </div>
    </div>
</ws-modal-wrapper>
