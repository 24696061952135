<div class="card panel-flat-color primary-panel select-entity">
    <div class="panel-flat-header" style="margin-bottom: 0;">
        <div class="header-icon"><i class="fa fa-building-o"></i></div>
        <h3>{{headerText}}</h3>
        <div class="float-end checkbox" style="margin: 1px 0 0 0;" [class.disabled]="entitySelection.selectedEntities.length > 0" *ngIf="hasInactiveEntities">
            <label>
                <input type="checkbox" [(ngModel)]="entitySelection.excludeInactive"  [disabled]="entitySelection.selectedEntities.length > 0"> Exclude Inactive
            </label>
        </div>
    </div>
    <div class="panel-flat-body">
        <div *ngIf="report.reportTypeId === reportTypes.Maar" class="checkbox" style="margin-top: 0; margin-bottom: 20px">
            <label>
                <input type="checkbox" [(ngModel)]="report.criteria.includeAllCompanies"> Include All Companies
            </label>
        </div>
        <div [hidden]="report.criteria.includeAllCompanies" class="row">
            <div class="col-lg-4 col-xl-3 entity-panel" *ngIf="showSupportInstancePicker">
                <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                    <!-- Default panel contents -->
                    <div class="panel-flat-header small-header">
                        <h3>Support Instances</h3>
                    </div>
                    <i class="fa fa-spinner fa-pulse fa-3x" style="position: absolute; top: 35%; left: 43%; z-index: 2;" *ngIf="loadingSupportInstances"></i>
                    <select multiple id="supportInstances" class="entity-list"
                            [class.loading-list]="loadingSupportInstances"
                            (change)="selectedInstanceChanged($event)">
                        <option [selected]="entitySelection.selectedSupportInstanceIds.includes(instance.instanceId)" [value]="instance.instanceId" *ngFor="let instance of supportInstances">{{instance.instanceName}}</option>
                    </select>
                </div>
                <div class="float-end" *ngIf="supportInstances.length">
                    <button *ngIf="entitySelection.selectedSupportInstanceIds.length != supportInstances.length" class="btn flat-button small-button primary-button" (click)="selectAllSupportInstances()">Select All</button>
                    <button *ngIf="entitySelection.selectedSupportInstanceIds.length == supportInstances.length" class="btn flat-button small-button primary-button" (click)="unselectAllSupportInstances()">Unselect All</button>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3 entity-panel" *ngIf="!hideTopLevelCompanyPicker">
                <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                    <!-- Default panel contents -->
                    <div class="panel-flat-header small-header">
                        <h3>Top Level Companies</h3>
                    </div>
                    <i class="fa fa-spinner fa-pulse fa-3x" style="position: absolute; top: 35%; left: 43%; z-index: 2;" *ngIf="loadingTopLevelCompanies"></i>
                    <select multiple id="top-level" class="entity-list"
                        [class.loading-list]="loadingTopLevelCompanies"
                        (change)="selectedTopLevelCompaniesChanged($event)"
                        [disabled]="isAppealResponsibilitySelected()">
                        <option [selected]="entitySelection.selectedTopLevelCompanyIDs.includes(company.companyID)" [value]="company.companyID" *ngFor="let company of topLevelCompanies">{{company.companyName}}</option>
                    </select>
                </div>
                <div *ngIf="hasInstancePrivatePermission" class="checkbox" style="display: inline-block; vertical-align: middle;">
                    <label>
                        <input type="checkbox"
                               [(ngModel)]="report.criteria.frequentlyUsed"
                               (change)="getTopLevelCompanies()">
                        Only Frequently Used
                    </label>
                </div>
                <div class="float-end" *ngIf="topLevelCompanies.length">
                    <button *ngIf="entitySelection.selectedTopLevelCompanyIDs.length != topLevelCompanies.length" class="btn flat-button small-button primary-button" (click)="selectAllTopLevelCompanies()">Select All</button>
                    <button *ngIf="entitySelection.selectedTopLevelCompanyIDs.length == topLevelCompanies.length" class="btn flat-button small-button primary-button" (click)="unselectAllTopLevelCompanies()">Unselect All</button>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3 entity-panel" *ngFor="let selectedEntity of entitySelection.selectedEntities; let i = index; let last = last;">
                <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0;">
                    <!-- Default panel contents -->
                    <div class="panel-flat-header small-header" style="position: relative;">
                        <h3>{{selectedEntity.name}}</h3>
                        <div style="position: absolute; top: 2px; right: 0;">
                            <select class="form-select form-select-sm entity-dropdown" *ngIf="selectedEntity.dropdownList.length" [(ngModel)]="selectedEntity.selectedDropdown"
                                (change)="propCharChanged(selectedEntity, i)">
                                <option [value]="option" *ngFor="let option of selectedEntity.dropdownList">{{option}}</option>
                            </select>
                            <em *ngIf="!selectedEntity.dropdownList.length && (selectedEntity.id === 999 || selectedEntity.id === 998)" style="margin-right: 10px;">Empty</em>
                            <i class="fa fa-close" *ngIf="last" style="margin: 3px 5px 0 3px;" (click)="removeLast()"></i>
                        </div>
                    </div>
                    <i class="fa fa-spinner fa-pulse fa-3x" style="position: absolute; top: 35%; left: 43%; z-index: 2;" *ngIf="selectedEntity.loading"></i>
                    <select *ngIf="selectedEntity.id !== 999  && selectedEntity.id !== 998" multiple class="entity-list" (change)="selectedSubEntitiesChanged($event.target, selectedEntity, i)" >
                        <option [selected]="selectedEntity.selectedIDs.includes(entity.id)" [class.loading-list]="selectedEntity.loading" [value]="entity.id" *ngFor="let entity of selectedEntity.list">{{entity.name}}</option>
                    </select>
                    <select *ngIf="selectedEntity.id === 999 || selectedEntity.id === 998" multiple class="entity-list" [class.loading-list]="selectedEntity.loading" (change)="selectedSubEntitiesChanged($event.target, selectedEntity, i)" >
                        <option [selected]="selectedEntity.selectedPropCharVals.includes(propCharVal.name)" [value]="propCharVal.name" *ngFor="let propCharVal of selectedEntity.list">{{propCharVal.name}}</option>
                    </select>
                </div>
                <div class="float-end" *ngIf="selectedEntity.list.length">
                    <button *ngIf="!areAllSelected(selectedEntity)" class="btn flat-button small-button primary-button" (click)="selectAll(selectedEntity, i)">Select All</button>
                    <button *ngIf="areAllSelected(selectedEntity)" class="btn flat-button small-button primary-button" (click)="unselectAll(selectedEntity, i)">Unselect All</button>
                </div>
            </div>
            <div class="col-lg-2 additional-selections" *ngIf="showAdditionalSelections()">
                <div>
                    <strong>Additional Selections</strong>
                </div>
                <div *ngFor="let entity of additionalSelections; let i=index;">
                    <button class="btn flat-button primary-button large-button" [disabled]="noItemsSelected()" (click)="selectionButtonClicked(entity.id)" *ngIf="i < 3 || additionalSelections.length == 4 || showMoreSelections">{{entity.buttonName}}</button>
                </div>
                <div>
                    <button class="btn flat-button primary-button large-button" [disabled]="noItemsSelected()" (click)="showMoreSelections = true;" *ngIf="additionalSelections.length > 4 && !showMoreSelections">More...</button>
                </div>
            </div>
        </div>
        <button [hidden]="report.criteria.includeAllCompanies || report.reportTypeId === reportTypes.SupportInstanceEscalationActivity" type="button" style="float: right; width: 70px; line-height: 25px;" class="flat-button small-button primary-button" [disabled]="!dataLoaded"  title="Apply" (click)="apply()">
            Apply
        </button>
    </div>
</div>
