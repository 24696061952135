(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdTaskSeriesArrows', sdTaskSeriesArrows);

	sdTaskSeriesArrows.$inject = [];

	function sdTaskSeriesArrows() {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			bindToController: true,
			controller: Controller,
			controllerAs: 'vm',
			link: link,
			restrict: 'E',
			templateUrl: 'app/Task/_taskSeriesArrows.html',
			scope: {
				manyToOne: '=',
				chevronWidth: '=',
				padding: '=',
				manyLength: '='
			}
		};
		return directive;

		function link(scope, element, attrs) {}
	}

	function Controller() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
	function ng1ControllerInit() {
		var vm = this;

		vm.startingPos = (vm.chevronWidth + (vm.padding * 2)) / 2;
		vm.lineWidth = vm.chevronWidth + (vm.padding * 3);
		vm.extraSeries = _.range(vm.manyLength - 1)

	}
})();
