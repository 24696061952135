import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ResponsibleEntityRepository } from '../responsbileEntity.repository';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommentModalData } from '../../Comments/comments.service';

export interface ResponsibleEntityDetailsParams {
    companyId: number,
    data?: Core.ResponsibleEntityModel,
    canEdit: boolean
}

@Component({
    selector: 'responsible-entity-details',
    templateUrl: './responsibleEntityDetails.component.html',
    styleUrls: ['./responsibleEntityDetails.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ResponsibleEntityDetailsComponent implements OnInit, IWeissmanModalComponent<ResponsibleEntityDetailsParams, Core.ResponsibleEntityModel> {
    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _bsModalRef: BsModalRef,
        private readonly _responsibleEntityRepository: ResponsibleEntityRepository)
    {}

    params: ResponsibleEntityDetailsParams;
    result: Core.ResponsibleEntityModel;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    entityForm: UntypedFormGroup;
    entity: Core.ResponsibleEntityModel;
    entityTypes: Core.ResponsibleEntityTypeModel[];
    loading: boolean = false;
    canEdit: boolean = false;
    editing: boolean = false;
    commentModalData: CommentModalData;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.entity = this.params.data;
        this.canEdit = this.params.canEdit || !this.params.data;
        this.editing = !this.params.data;

        const busyMsgLoading = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgLoading);
        this.loading = true;

        try {
            this.entityTypes = await lastValueFrom(this._responsibleEntityRepository.getTypes());
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgLoading);
        }

        this.entityForm = this._fb.group({
            name: [null, [Validators.required]],
            number: [null, [Validators.required]],
            responsibleEntityTypeId: [this.entityTypes[0].id, [Validators.required]],
            address1: [null],
            address2: [null],
            city: [null],
            stateId: [null],
            zip: [null],
            allocationDebitAccount: [null],
            allocationCreditAccount: [null],
            allocationReceivableAccount: [null],
            contact: [null],
            phone: [null],
            email: [null],
            allocationsExport: [{value: false, disabled: !this.editing}],
            accrualsExport: [{ value: false, disabled: !this.editing}]
        });

        if (this.entity) {
            this.entityForm.patchValue({
                name: this.entity.name,
                number: this.entity.number,
                responsibleEntityTypeId: this.entity.responsibleEntityTypeId,
                address1: this.entity.address1,
                address2: this.entity.address2,
                city: this.entity.city,
                stateId: this.entity.stateId,
                zip: this.entity.zip,
                allocationDebitAccount: this.entity.allocationDebitAccount,
                allocationCreditAccount: this.entity.allocationCreditAccount,
                allocationReceivableAccount: this.entity.allocationReceivableAccount,
                contact: this.entity.contact,
                phone: this.entity.phone,
                email: this.entity.email,
                allocationsExport: this.entity.allocationsExport,
                accrualsExport: this.entity.accrualsExport
            });

            this._setAllocationOptionalValidators(this.entity.allocationsExport);

            this.commentModalData = {
                entityID: this.entity.responsibleEntityId,
                entityTypeID: Core.EntityTypes.ResponsibleEntity,
                entityName: this.entity.name,
                parcelAcctNum: null,
                canEdit: this.canEdit,
                description: null,
                parcelID: null,
            } as CommentModalData;
        }

        this.allocationsExport.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(allocationsExport => {
            this._setAllocationOptionalValidators(allocationsExport);
        });

        this.loading = false;
    }

    get accrualsExport(): AbstractControl {
        return this.entityForm.get('accrualsExport');
    }

    get allocationsExport(): AbstractControl {
        return this.entityForm.get('allocationsExport');
    }

    get allocationDebitAccount(): AbstractControl {
        return this.entityForm.get('allocationDebitAccount');
    }

    get allocationCreditAccount(): AbstractControl {
        return this.entityForm.get('allocationCreditAccount');
    }

    get allocationReceivableAccount(): AbstractControl {
        return this.entityForm.get('allocationReceivableAccount');
    }

    async save(): Promise<void> {
        if (!this.editing) {
            this.cancel();
            return;
        }

        const busyMsgLoading = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgLoading);

        try {
            if (this.entity) {
                const model: Core.ResponsibleEntityUpdateModel = this.entityForm.getRawValue();
                model.companyId = this.entity.companyId;
                model.responsibleEntityId = this.entity.responsibleEntityId;

                this.result = await lastValueFrom(this._responsibleEntityRepository.update(this.params.companyId, model));
            } else {
                const model: Core.ResponsibleEntityCreateModel = this.entityForm.getRawValue();
                model.companyId = this.params.companyId;

                this.result = await lastValueFrom(this._responsibleEntityRepository.create(this.params.companyId, model));
            }

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgLoading);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    edit(): void {
        this.editing = true;

        this.allocationsExport.enable();
        this.accrualsExport.enable();
    }

    private _setAllocationOptionalValidators(allocationsExport: boolean): void {
        const validator = allocationsExport ? [Validators.required] : [];
        this.allocationDebitAccount.setValidators(validator);
        this.allocationDebitAccount.updateValueAndValidity();
        this.allocationCreditAccount.setValidators(validator);
        this.allocationCreditAccount.updateValueAndValidity();
        this.allocationReceivableAccount.setValidators(validator);
        this.allocationReceivableAccount.updateValueAndValidity();
    }
}
