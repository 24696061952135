import { Injectable } from "@angular/core";
import { BaseRepository } from "../Common/Data/base.repository";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ParcelRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly BASE_URL = '/api/parcel/';

    getView(parcelId, navigationRequest: boolean): Observable<any> {
        let config;
        if (navigationRequest) {
            config = { navigationRequest: true };
        }
        return this.httpGet(this.BASE_URL + parcelId + "/view", config);
    }

    get(callParams: any): Observable<any> {
        const url = this.BASE_URL + callParams.parcelId;

        const config = {
            params: {
                parentid: callParams.siteId
            }
        };

        return this.httpGet(url, config);
    }

    getContactParcel(parcelId: number, config: any): Observable<any> {
        return this.httpGet(this.BASE_URL + parcelId, config);
    }

    getAvailableYears(siteId: number): Observable<any> {
        return this.httpGet(this.BASE_URL + 'years/' + siteId);
    }

    getParcelLatestAssessmentYear(parcelId): Observable<any> {
        return this.httpGet(this.BASE_URL + parcelId + '/year/latest/assessed');
    }

    // yearValue will be 0 for the most recent one
    //
    getParcelsInfoForSingleYear(siteId: number, yearValue: number): Observable<any> {
        return this.httpGet(this.BASE_URL + 'tile/' + siteId + '/' + yearValue);
    }

    update(parcel: Weissman.Model.Domain.Parcel): Observable<any> {
        return this.httpPut(this.BASE_URL, parcel);
    }

    post(parcel: Core.ParcelModel): Observable<any> {
        return this.httpPost(this.BASE_URL, parcel);
    }

    getNavigationInfo(parcelId: number): Observable<any> {
        return this.httpGet(this.BASE_URL + parcelId + '/Navigation');
    }

    getParcelLinkageTypes(): Observable<any> {
        return this.httpGet('/api/parcellinkage/types');
    }

    getLinkedParcels(parcelId: number, includeUnlinked: boolean): Observable<any> {
        const params = {
            includeUnlinked: includeUnlinked
        };

        return this.httpGet('/api/parcellinkage/parcel/' + parcelId, { params: params });
    }

    addNewLinks(payload: any): Observable<any> {
        return this.httpPost('/api/parcellinkage/parcels', payload);
    }

    updateLinks(payload: any): Observable<any> {
        return this.httpPut('/api/parcellinkage/parcels', payload);
    }

    getParcelListBySiteId(siteId: number, excludeInactive: boolean): Observable<any> {
        return this.httpGet('/api/site/'+siteId+'/parcels?excludeInactive='+excludeInactive);
    }

    getParcelYears(parcelId: number): Observable<any> {
        return this.httpGet('/api/parcel/'+parcelId+'/years');
    }

    getOptimusLinkInfo(parcelId: number): Observable<any> {
        return this.httpGet(`/api/parcel/${parcelId}/OptimusLink`);
    }

    bulkUpdate(bulkUpdateModel: Core.ParcelBulkUpdateModel): Observable<any> {
        return this.httpPut('/api/parcel/bulkUpdate', bulkUpdateModel);
    }
    
    getParcelAssessmentClasses(parcelId: number): Observable<any> {
        return this.httpGet(`api/assessmentclass/parcel/${parcelId}`);
    }

    deleteParcel(parcelId: number): Observable<any> {
        return this.httpDelete(`api/parcel/${parcelId}`);
    }

    getAssessorJurisdictionSpecialists(parcelId: number, assessorId: number): Observable<any> {
        return this.httpGet(`api/parcel/${parcelId}/assessor/${assessorId}/jurisdictionspecialist`)
    }

    getAcctNumberTypes(): Observable<Weissman.Model.Domain.AccountNumberType[]> {
        return this.httpGet(`api/accountnumbertype`);
    }
}
