import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { AssessmentTaxesDetailsService } from './assessment-taxes-details-modal.service';
import { UserSettingsService } from '../../Account/userSettings.service';
import { AssessmentTaxesGridData, AssessmentTaxesCriteria, AssessmentTaxesOptions } from './assessment-taxes-details.models';
import { Settings } from '../../User/account.service';

export enum DataTypes {
    date,
    number,
    number4,
    money,
    percent,
    percent6,
    text
}

export interface AssessmentTaxesDetailsModalParams {
    siteId?: number;
    parcelId?: number;
    selectableYears?: number[];
    analyticsCategory?: string;
}

@Component({
    selector: 'assessment-taxes-details-modal',
    templateUrl: './assessment-taxes-details-modal.component.html',
    styleUrls: ['./assessment-taxes-details-modal.component.scss']
})
export class AssessmentTaxesDetailsModalComponent implements OnInit, IWeissmanModalComponent<AssessmentTaxesDetailsModalParams, void> {
    constructor(private readonly _assessmentTaxesDetailsService: AssessmentTaxesDetailsService,
                private readonly _userSettingsService: UserSettingsService,
                private readonly _bsModalRef: BsModalRef) { }

    params: AssessmentTaxesDetailsModalParams;
    result: void;

    isLoading: boolean = false;
    savingSettings: boolean = false;
    dataTypes = DataTypes;
    criteria: AssessmentTaxesCriteria;

    // selections
    valueDetailsOptions: string[];
    includeAssessedValueInfoOptions: string[];
    includeTargetValueOptions: string[];
    includeValuationMetricsOptions: string[];
    breakOutTaxPaymentDetailsOptions: string[];
    showPercentChangeOptions: string[];

    gridData: AssessmentTaxesGridData = new AssessmentTaxesGridData();


    ngOnInit(): void {
        this.setOptions();
        this.criteria = this.getSavedCriteria() || new AssessmentTaxesCriteria();

        this.maybeGetSelectableYears()
            .then(() => {
                this.setYearDefaults();
                this.loadGrid();
            });
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    loadGrid(): void {
        this.isLoading = true;

        this.getGridData()
            .then((result: AssessmentTaxesGridData) => {
                this.gridData = result;
                this.isLoading = false;
            });
    }

    getSelectableYears(fromYear: boolean): number[] {
        if(fromYear) {
            return _.filter(this.params.selectableYears, (year) => {
                return year <= this.criteria.toYear;
            });
        } else {
            return _.filter(this.params.selectableYears, (year) => {
                return year >= this.criteria.fromYear;
            });
        }
    }

    async saveSettings(): Promise<void> {
        this.savingSettings = true;

        try {
            let criteriaSetting: Settings = {
                id: 0,
                name: 'Assessment-Taxes-Criteria',
                value: this.criteria,
                groupId: 14,
                groupName: 'Assessment-Taxes-Criteria',
                folderId: 20,
                folderName: 'Assessment-Taxes-Criteria',
            };

            const existingCriteriaSettings = this._userSettingsService.getSettingsByGroup(14);

            if (existingCriteriaSettings.length) {
                criteriaSetting = _.cloneDeep(existingCriteriaSettings[0]);
                criteriaSetting.value = _.cloneDeep(this.criteria);
            }

            await this._userSettingsService.save(criteriaSetting);
        } finally {
            this.savingSettings = false;
        }
    }

    private maybeGetSelectableYears(): Promise<void> {
        if (!this.params.selectableYears && this.params.siteId) {
            return this._assessmentTaxesDetailsService.getSiteYears(this.params.siteId)
                .then((result) => {
                    this.params.selectableYears = result.reverse();
                });
        } else {
            return Promise.resolve();
        }
    }

    private getGridData(): Promise<AssessmentTaxesGridData> {
        if (this.params.siteId) {
            return this._assessmentTaxesDetailsService.getSiteSummary(this.params.siteId, this.criteria);
        } else if (this.params.parcelId) {
            return this._assessmentTaxesDetailsService.getParcelSummary(this.params.parcelId, this.criteria);
        } else {
            console.warn('We don\'t have an entity ID!');
            return Promise.resolve(new AssessmentTaxesGridData());
        }
    }

    private setYearDefaults(): void {
        if(!this.criteria.toYear || this.criteria.toYear > _.first(this.params.selectableYears)) {
            this.criteria.toYear = _.max(this.params.selectableYears);
        }
        if(!this.criteria.fromYear || this.criteria.fromYear < _.last(this.params.selectableYears)) {
            this.criteria.fromYear = this.params.selectableYears.length >= 4 ? this.params.selectableYears[3] : _.last(this.params.selectableYears);
        }
    }

    private setOptions(): void {
        _.assign(this, new AssessmentTaxesOptions());
    }

    private getSavedCriteria(): AssessmentTaxesCriteria {
        const existingCriteriaSettings = this._userSettingsService.getSettingsByGroup(14);

        if (existingCriteriaSettings.length) {
            return _.cloneDeep(existingCriteriaSettings[0].value);
        } else {
            return null;
        }
    }
}
