import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const AG_GRID_TOOL_PANEL_HELP: HelpContent[] = [
    {
        helpContentId: 'ag-grid-tool-panel.show-configure',
        tooltipText: 'Configure layout'
    },
    {
        helpContentId: 'ag-grid-tool-panel.hide-configure',
        tooltipText: 'Hide configure layout'
    },
    {
        helpContentId: 'ag-grid-tool-panel.show-filters',
        tooltipText: 'Show filters'
    },
    {
        helpContentId: 'ag-grid-tool-panel.hide-filters',
        tooltipText: 'Hide filters'
    },
    {
        helpContentId: 'ag-grid-tool-panel.export',
        tooltipText: 'Export data'
    },
    {
        helpContentId: 'ag-grid-tool-panel.export-tab-delimited',
        tooltipText: 'Export data to tab-delimited format which does not have formatting but exports faster'
    },
    {
        helpContentId: 'ag-grid-tool-panel.export-pdf',
        tooltipText: 'Archive and Export pdf files'
    },
    {
        helpContentId: 'ag-grid-tool-panel.export-excel',
        tooltipText: 'Export data to Excel'
    },
    {
        helpContentId: 'ag-grid-tool-panel.size-to-fit',
        tooltipText: 'Size columns to fit the available space'
    },
    {
        helpContentId: 'ag-grid-tool-panel.size-to-content',
        tooltipText: 'Size columns to fit cell contents'
    },
    {
        helpContentId: 'ag-grid-tool-panel.bulk-update',
        tooltipText: 'Bulk update selected rows'
    },
    {
        helpContentId: 'ag-grid-tool-panel.clearFiltersHelpContentId',
        tooltipText: 'Clear filters'
    },
    {
        helpContentId: 'ag-grid-tool-panel.pinColumn',
        tooltipText: 'Pin column left'
    },
    {
        helpContentId: 'ag-grid-tool-panel.additional-actions',
        tooltipText: 'Additional actions are available'
    }
];
