
import {of as observableOf, Observable} from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChange,
    SimpleChanges
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import * as _ from "lodash";
import { mergeMap } from 'rxjs/operators';

interface AssessorSelectorComponentChanges extends SimpleChanges {
    assessorValue: SimpleChange
}

@Component({
    templateUrl: './assessorSelector.component.html',
    selector: 'assessor-selector',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AssessorSelectorComponent,
        multi: true
    }]
})
export class AssessorSelectorComponent implements ControlValueAccessor {

    // reactive forms functions
    private _onChangeFn: Function;
    private _onTouchedFn: Function;

    @Input() assessors: Compliance.ImportAssessorModel[] = [];
    @Input() isRequiredField: boolean = true;
    @Input() isDisabled: boolean = false;
    @Input() isOverridden: boolean = false;

    @Output() assessorChanged = new EventEmitter<string>();

    value: string;
    assessorFilter: string = '';

    assessors$: Observable<Compliance.ImportAssessorModel[]> = (Observable
        .create((observer: any) => { observer.next(this.assessorFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterAssessors(token)));

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(assessorValue: string): void {
        this.value = assessorValue;
        this.assessorFilter = assessorValue;
    }

    onAssessorBlur(): void {
        if (!this.isRequiredField && this.assessorFilter === '') {
            this.assessorChanged.emit(null);
        }

        this._selectAssessor(this.value);
        this._onTouchedFn && this._onTouchedFn();
    }

    onAssessorSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const assessor = match.item as Compliance.ImportAssessorModel;
            this.assessorFilter = assessor.name;
            this.assessorChanged.emit(assessor.name);
        }
    }

    private _filterAssessors(filter: string): Observable<Compliance.ImportAssessorModel[]> {
        if (filter === null || filter === undefined) {
            filter = '';
        }

        return observableOf(
            _.filter(this.assessors, i => this._isMatch(i, filter))
        );
    }

    private _selectAssessor(assessorValue: string): void {
        const assessors = this.assessors && this.assessors.filter(a => this._isMatch(a, assessorValue));

        if (assessors && assessors.length == 1) {
            this.assessorFilter = assessorValue;
        } else {
            this.assessorFilter = null;
        }
    }

    private _isMatch(assessor: Compliance.ImportAssessorModel, selectedValue: string): boolean {
        const value = (selectedValue || '').toLocaleLowerCase();
        const localAssessor = {
            assessorId: assessor.assessorId,
            name: assessor.name.toLocaleLowerCase(),
            primaryMatch: assessor.primaryMatch.toLocaleLowerCase(),
            additionalMatches: assessor.additionalMatches.map(i => i.toLocaleLowerCase())
        } as Compliance.ImportAssessorModel;

        let result = value === '';

        if (!result){
            result = localAssessor.name.indexOf(value) >= 0;
        }

        if (!result) {
            result = value === localAssessor.primaryMatch ||
                value.startsWith(localAssessor.primaryMatch + ' ') ||
                value.endsWith(' ' + localAssessor.primaryMatch) ||
                value.indexOf(' ' + localAssessor.primaryMatch + ' ') !== -1;
        }

        if (!result){
            _.forEach(localAssessor.additionalMatches, additionalMatch => {
                result = value.indexOf(localAssessor.primaryMatch) !== -1 && value.indexOf(additionalMatch) !== -1;

                return !result;
            });
        }

        if (!result) {
            result = localAssessor.assessorId === +value;
        }

        if (!result){
            _.forEach(localAssessor.additionalMatches, additionalMatch => {
                result = value === (localAssessor.primaryMatch + ' ' + additionalMatch).toLocaleLowerCase();

                return !result;
            });
        }

        return result;
    }
}
