export interface IBusyIndicatorConfig {
    identifier?: any;
    longRunningProcessId?: number;
    title?: string;
    message?: string;
    delay?: number;
    hasAction?: boolean;
    canDismiss?: boolean;
    hasProgressBar?: boolean;
    actionMessage?: string;
    captureFocus?: boolean;
    restoreFocus?: boolean;
    cancelledNotificationMessage?: string;
}

export class BusyIndicatorConfig implements IBusyIndicatorConfig {
    constructor(public identifier?: any, public title?: string, public message?: string) { }

    public delay = 0;
    public hasAction = false;
    public canDismiss = false;
    public hasProgressBar = false;
    public actionMessage = null;
    public captureFocus = true;
    public restoreFocus = true;
}
