import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormControl } from '@angular/forms';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { GLAccountService } from '../glAccount.service';

export enum DisplayMode {
    Add = 0,
    Edit = 1,
    View = 2
}

export interface GLAccountDetailsParams {
    glAccountId: number;
    displayMode: DisplayMode;
    companyId: number;
    foundInAssets: boolean;
    foundInIncomeStatements: boolean;
    foundInAccruals: boolean;
}

@Component({
    selector: 'gl-account-details',
    templateUrl: './glAccountDetails.component.html'
})
export class GLAccountDetailsComponent implements OnInit, IWeissmanModalComponent<GLAccountDetailsParams, Compliance.GLAccountModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _glAccountService: GLAccountService
    ) {}

    params: GLAccountDetailsParams;
    result: Compliance.GLAccountModel;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    DisplayMode = DisplayMode;

    // reactive form helper methods for accessing part of that form
    form: UntypedFormGroup;
    glAccount: Compliance.GLAccountModel = null;
    currAccountType: Compliance.GLAccountTypeModel = { glAccountTypeId: 0, name: '', validForAccrual: false };
    currAccountAccrualTypes: Compliance.GLAccountAccrualTypeModel[] = [];
    accrualsFeatureEnabled: boolean = false;

    PropertyTaxPayableType: Compliance.GLAccountAccrualTypeEnum = Compliance.GLAccountAccrualTypeEnum.PropertyTaxPayable;

    accountTypes: Compliance.GLAccountTypeModel[];

    get f() { return this.form; }

    get fPropTypes() { return (this.form.get('forPropertyTypes') as UntypedFormArray); }

    get fPmtTypes() { return (this.form.get('forPaymentTypes') as UntypedFormArray); }

    get accountAccrualTypes(): Compliance.GLAccountAccrualTypeModel[] {
        return this._glAccountService.startResults.glAccountAccrualTypes;
    }

    get propertyTypes(): Core.PropertyTypeModel[] {
        return this._glAccountService.startResults.propertyTypes;
    }

    get paymentTypes(): Core.PaymentTypeModel[] {
        return this._glAccountService.startResults.paymentTypes;
    }

    get accrualScopeCompanies(): Core.NameIdPair[] {
        return this._glAccountService.startResults.companies;
    }

    get isTopLevelCompany(): boolean {
        return this._glAccountService.isTopLevelCompany;
    }

    async ngOnInit(): Promise<void> {
        // Create the form before any async operation so that it exists before Angular references it.
        this.form = this._formBuilder.group({
            accountNumber: [null, [Validators.required, Validators.maxLength(100)]],
            accountName: [null, [Validators.required, Validators.maxLength(100)]],
            description: [null, [Validators.maxLength(500)]],
            assetClassificationId: [null, []],
            assetClassificationName: [null, []],
            selectedAccountType: [null, [Validators.required]],
            applicableToAccruals: [{value: false, disabled: this.params.displayMode === 2}, []],
            payableIncludesPrepaid: [{value: false}, []],
            selectedAccountAccrualType: [null, []],
            forAllCompanies: [{value: true, disabled: this.params.displayMode === 2}, []],
            forPropertyTypes: this._formBuilder.array(this.propertyTypes.map(t => ({ value: false, disabled: this.params.displayMode === 2}))),
            forPaymentTypes: this._formBuilder.array(this.paymentTypes.map(t => ({ value: false, disabled: this.params.displayMode === 2}))),
            forCompanies: [[], []],
            incomeStatementCategoryName: [null, []],
            incomeStatementCategoryId: [null, []],
            isValidated: [null, []],
        });

        this.accrualsFeatureEnabled = this._glAccountService.accrualsFeatureEnabled &&
                                      this._glAccountService.tlCompany.accrualsEnabled;

        if (this.params.foundInAccruals) {
            this.form.get('applicableToAccruals').disable();
            this.form.get('payableIncludesPrepaid').disable();
            this.form.get('forPropertyTypes').disable();
            this.form.get('forPaymentTypes').disable();
            this.form.get('forAllCompanies').disable();
            this.form.get('forCompanies').disable();
        }

        await this._loadGLAccount();
        this.accountTypes = this.setupAllowedAccountTypes();
        // setup form
        this._updateFormFromModel();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        this._updateModelFromForm();

        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        try {

            const promise = this.params.displayMode === DisplayMode.Add ?
                this._glAccountService.create(this.glAccount) :
                this._glAccountService.update(this.glAccount);

            this.glAccount = await promise;

            // reset form
            this._updateFormFromModel();
            this.form.markAsPristine();
            this.form.markAsUntouched();

            this.result = this.glAccount;

            // close modal window
            this._bsModalRef.hide();
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    onAssetClassificationChanged(assetClass: Compliance.AssetClassificationModel): void {
        this.form.patchValue({ assetClassificationName: assetClass && assetClass.name });
    }

    onIncomeStatementCategoryChanged(incomeStatementCategory: Core.IncomeStatementCategoryModel): void {
        this.form.patchValue({ incomeStatementCategoryName: incomeStatementCategory && incomeStatementCategory.name });
    }

    async accountTypeChanged(event: any): Promise<void> {
        this.currAccountType = this.form.controls.selectedAccountType.value;
        if ( !this.currAccountType.validForAccrual && this.form.controls.applicableToAccruals.value )
        {
            this.form.patchValue({ applicableToAccruals: false });
            this.initializeScopeSelections();
        }
        this.repopulateAccountAccrualTypes();
        this.form.patchValue({ incomeStatementCategoryName: '' });
        this.form.patchValue({ incomeStatementCategoryId: null });
    }

    setupAllowedAccountTypes(): Compliance.GLAccountTypeModel[] {
        if (this.currAccountType && this.params?.foundInAssets) {
            return this._glAccountService.startResults.glAccountTypes
                .filter(x => this.currAccountType.glAccountTypeId === x.glAccountTypeId);
        }
        if (this.params?.foundInIncomeStatements) {
            return this._glAccountService.startResults.glAccountTypes
                .filter(x => x.glAccountTypeId === Compliance.GLAccountTypeEnum.Revenue
                          || x.glAccountTypeId === Compliance.GLAccountTypeEnum.Expense
                          || x.glAccountTypeId === Compliance.GLAccountTypeEnum.Reporting);
        }
        return this._glAccountService.startResults.glAccountTypes;
    }

    switchApplicableToAccruals(event: any): void {
        this.repopulateAccountAccrualTypes();
        this.initializeScopeSelections();

        if(!this.form.controls.applicableToAccruals.value) {
            this.form.patchValue({ payableIncludesPrepaid: false });
        }
    }

    accountAccrualTypeChanged(): void {
        if (this.form.controls.selectedAccountAccrualType.value.glAccountAccrualTypeId !== this.PropertyTaxPayableType) {
            this.form.patchValue({ payableIncludesPrepaid: false });
        }
    }

    get showAssetClassification(): boolean {
        return this.currAccountType && this.currAccountType.glAccountTypeId === Compliance.GLAccountTypeEnum.Asset;
    }

    get showIncomeStatementCategory(): boolean {
        const allowedAccountTypes = [
            Compliance.GLAccountTypeEnum.Expense,
            Compliance.GLAccountTypeEnum.Revenue,
            Compliance.GLAccountTypeEnum.Reporting];
        return this.currAccountType
            && allowedAccountTypes.indexOf(this.currAccountType.glAccountTypeId) > -1;
    }

    private initializeScopeSelections(): void {
        // Switching to Applicable/Non-applicable to Accruals.
        // Select all Property Types and Payment Types.
        const arrPropTypeSel = this.propertyTypes.map(t => !!(this.accrualsFeatureEnabled && this.form.controls.applicableToAccruals.value));
        const arrPmtTypeSel = this.paymentTypes.map(t => !!(this.accrualsFeatureEnabled && this.form.controls.applicableToAccruals.value));

        // Always initialze to For All Companies.
        this.form.patchValue({
            forPropertyTypes: arrPropTypeSel,
            forPaymentTypes: arrPmtTypeSel,
            forAllCompanies: true,
            forCompanies: []
        });
    }

    private repopulateAccountAccrualTypes(): void {
        const repopulate = (this.accrualsFeatureEnabled && this.form.controls.applicableToAccruals.value);

        this.currAccountAccrualTypes = repopulate ? this.accountAccrualTypes.filter(x => x.glAccountTypeId === this.currAccountType.glAccountTypeId) : [];
        this.form.patchValue({ selectedAccountAccrualType: repopulate ? this.currAccountAccrualTypes[0] : null });
    }

    private async _loadGLAccount(): Promise<void> {
        if (this.params.displayMode === DisplayMode.Add) {
            // Default to adding a new non-Accrual Asset G/L Account.
            this.currAccountType = this._glAccountService.startResults.glAccountTypes.find(at => at.glAccountTypeId === Compliance.GLAccountTypeEnum.Asset);
            this.currAccountAccrualTypes = [];

            this.glAccount = {
                glAccountId: 0,
                companyId: this.params.companyId,
                accountNumber: null,
                accountName: null,
                assetClassificationId: null,
                assetClassification: null,
                description: null,
                accountType: this.currAccountType.glAccountTypeId,
                accountTypeName: this.currAccountType.name,
                applicableToAccruals: false,
                payableIncludesPrepaid: false,
                accountAccrualType: null,
                forAllCompanies: true,
                forPropertyTypes: [],
                forPaymentTypes: [],
                forCompanies: [],
                incomeStatementCategoryName: null,
                incomeStatementCategoryId: null
            } as Compliance.GLAccountModel;

            return;
        }

        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);

        try {
            this.glAccount = await this._glAccountService.get(this.params.glAccountId);
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        this.currAccountType = this._glAccountService.startResults.glAccountTypes.find(at => at.glAccountTypeId == this.glAccount.accountType);
    }

    private _updateFormFromModel(): void {
        let arrPropTypeSel = this.propertyTypes.map(t => {
            return this.accrualsFeatureEnabled &&
                   (this.glAccount.forPropertyTypes.length > 0) &&
                   this.glAccount.forPropertyTypes.some(x => x.propertyTypeId === t.propertyTypeId);
        });
        let arrPmtTypeSel = this.paymentTypes.map(t => {
            return this.accrualsFeatureEnabled &&
                   (this.glAccount.forPaymentTypes.length > 0) &&
                   this.glAccount.forPaymentTypes.some(x => x.paymentTypeId === t.paymentTypeId);
        });

        let arrCompanySel: number[] = [];
        if ( this.accrualsFeatureEnabled )
        {
            if ( !this.isTopLevelCompany )
            {
                // G/L Account is for a Subsidiary Company.
                // We don't provide Companies selection, and we default
                // to Specific companies with just the Subsidiary selected.
                if ( this.glAccount.forAllCompanies )
                    this.glAccount.forAllCompanies = false;
                if ( this.glAccount.forCompanies.length != 1 )
                {
                    this.glAccount.forCompanies = [{
                        glAccountId: this.glAccount.glAccountId,
                        companyId: this._glAccountService.companyId
                    }];
                }
                arrCompanySel.push(this._glAccountService.companyId);
            }
            else
            {
                // Select the stored companies that are valid for selection.
                arrCompanySel = this.accrualScopeCompanies
                    .filter((c) => this.glAccount.forCompanies.some(x => x.companyId === c.id))
                    .map((fc) => fc.id);
            }
        }
        else
        {
            // Accruals Feature not enabled
            this.glAccount.applicableToAccruals = false;
            this.glAccount.forAllCompanies = true;
        }
        this.form.patchValue({
            accountNumber: this.glAccount.accountNumber,
            accountName: this.glAccount.accountName,
            description: this.glAccount.description,
            assetClassificationId: this.glAccount.assetClassificationId,
            assetClassificationName: this.glAccount.assetClassification,
            selectedAccountType: this.currAccountType,
            applicableToAccruals: this.glAccount.applicableToAccruals,
            payableIncludesPrepaid: this.glAccount.payableIncludesPrepaid,
            selectedAccountAccrualType: null,
            forAllCompanies: this.glAccount.forAllCompanies,
            forPropertyTypes: arrPropTypeSel,
            forPaymentTypes: arrPmtTypeSel,
            forCompanies: arrCompanySel,
            incomeStatementCategoryName: this.glAccount.incomeStatementCategoryName,
            incomeStatementCategoryId: this.glAccount.incomeStatementCategoryId,
            isValidated: this.glAccount.isValidated
        });

        this.repopulateAccountAccrualTypes();
        this.form.patchValue({ selectedAccountAccrualType: this.glAccount.accountAccrualType
            ? this.currAccountAccrualTypes.find(x => x.glAccountAccrualTypeId === this.glAccount.accountAccrualType)
            : null });
    }

    private _updateModelFromForm(): void {
        this.glAccount.glAccountId = !!this.params.glAccountId ? this.params.glAccountId : 0;
        this.glAccount.accountNumber = this.form.get('accountNumber').value;
        this.glAccount.accountName = this.form.get('accountName').value;
        this.glAccount.description = this.form.get('description').value;
        this.glAccount.accountType = this.form.get('selectedAccountType').value.glAccountTypeId;
        this.glAccount.applicableToAccruals = this.form.get('applicableToAccruals').value;
        this.glAccount.payableIncludesPrepaid = this.form.get('payableIncludesPrepaid').value;
        this.glAccount.accountAccrualType = this.form.get('selectedAccountAccrualType').value ? this.form.get('selectedAccountAccrualType').value.glAccountAccrualTypeId : null;
        this.glAccount.forAllCompanies = this.form.get('forAllCompanies').value;
        this.glAccount.forPropertyTypes = [];
        this.glAccount.forPaymentTypes = [];
        this.glAccount.forCompanies = [];
        this.glAccount.isValidated = this.form.get('isValidated').value;

        if (this.currAccountType.glAccountTypeId === Compliance.GLAccountTypeEnum.Asset){
            this.glAccount.assetClassificationId = this.form.get('assetClassificationId').value;
            this.glAccount.assetClassification = this.form.get('assetClassificationName').value;
        }

        if (this.currAccountType.glAccountTypeId === Compliance.GLAccountTypeEnum.Expense ||
            this.currAccountType.glAccountTypeId === Compliance.GLAccountTypeEnum.Revenue ||
            this.currAccountType.glAccountTypeId === Compliance.GLAccountTypeEnum.Reporting) {
            this.glAccount.incomeStatementCategoryId = this.form.get('incomeStatementCategoryId').value;
            this.glAccount.incomeStatementCategoryName = this.form.get('incomeStatementCategoryName').value;
        }

        if ( this.accrualsFeatureEnabled && this.glAccount.applicableToAccruals )
        {
            this.fPropTypes.controls.forEach((ft, ndx) => {
                if ( ft.value )
                {
                    this.glAccount.forPropertyTypes.push({
                        glAccountId: this.glAccount.glAccountId,
                        propertyTypeId: this.propertyTypes[ndx].propertyTypeId
                    });
                }
            });

            this.fPmtTypes.controls.forEach((ft, ndx) => {
                if ( ft.value )
                {
                    this.glAccount.forPaymentTypes.push({
                        glAccountId: this.glAccount.glAccountId,
                        paymentTypeId: this.paymentTypes[ndx].paymentTypeId
                    });
                }
            });

            if ( !this.glAccount.forAllCompanies && this.form.get('forCompanies').value.length > 0 )
            {
                this.form.get('forCompanies').value.forEach((cid) => {
                    this.glAccount.forCompanies.push({
                        glAccountId: this.glAccount.glAccountId,
                        companyId: cid
                    });
                });
            }
        }
    }
}
