import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { FormService } from '../form.service';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { AgGridMultiSelectTracker } from '../../AgGrid/MultiSelectTracker/agGridMultiSelectTracker';
import { Subscription } from 'rxjs';
import { AgGridColumns } from '../../AgGrid/agGridColumns';
import { AgGridMultiSelectedHeaderRenderer } from '../../AgGrid/MultiSelectTracker/agGridMultiSelectHeaderRenderer.component';
import { AgGridMultiSelectedCellRenderer } from '../../AgGrid/MultiSelectTracker/agGridMultiSelectCellRenderer.component';
import { AgGridMultiSelectRendererParams } from '../../AgGrid/MultiSelectTracker/agGridMultiSelectTracker';
import { AgGridFilterParams } from '../../AgGrid/agGridFilterParams';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

export interface FormAssessorAddParams {
    formRevisionId: number;
    taxYear: number;
}

interface FormRevisionAssessorSelectableModel extends Compliance.FormRevisionAssessorModel {
    isSelected: boolean;
}

@Component({
    templateUrl: './FormAssessorAdd.component.html',
    selector: 'form-assessor-add',
    styleUrls: ['./formAssessorAdd.component.scss']
})
export class FormAssessorAddComponent implements OnInit, OnDestroy, IWeissmanModalComponent<FormAssessorAddParams, boolean> {
    constructor(
        private readonly _formService: FormService,
        private readonly _bsModalRef: BsModalRef) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;

    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as FormRevisionAssessorSelectableModel).assessorId)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    params: FormAssessorAddParams;
    result: boolean;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    allAssessors: FormRevisionAssessorSelectableModel[];
    filteredAssessors: FormRevisionAssessorSelectableModel[];
    rowsSelected: boolean = false;

    async ngOnInit(): Promise<void> {
        const assessors = this._formService.assessorsAvailableForAssignment;
        this.allAssessors = _.map(assessors.filter(item => !item.formRevisionYearAssessorId), (item) => {
            return { ...item, isSelected: false } as FormRevisionAssessorSelectableModel;
        }
        ) || [];

        this.filteredAssessors = this.allAssessors;

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'assessorId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Name',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        const busyMsg = 'saving';

        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            const assessorIds = await this.gridTracker.getSelectedRowIds();

            await this._formService.associateAssessors(
                {
                    taxYear: this.params.taxYear,
                    assessorIds: assessorIds
                } as Compliance.FormRevisionAssessorAssociationModel);

            this.result = true;

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        return Promise.resolve();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!this._gridApi) {
            return;
        }

        this._gridApi.setRowData(this.filteredAssessors);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const assessor = x.data as FormRevisionAssessorSelectableModel;
                return assessor && assessor.assessorId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
