import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { CurrencyMaskConfig } from "ng2-currency-mask";
import { EntityDescriptorModelUI } from '../../descriptor.model';
import { DescriptorPicklistService } from "../../descriptor.picklist.service";

import * as _ from 'lodash';
import { filter, sortBy } from 'lodash/fp';
import * as moment from 'moment';

enum DescriptorFieldTypes {
    Text = Core.DescriptorFieldTypes.Text as number,
    Number = Core.DescriptorFieldTypes.Number as number,
    Date = Core.DescriptorFieldTypes.Date as number,
    Picklist = Core.DescriptorFieldTypes.Picklist as number,
    YesNo = Core.DescriptorFieldTypes.YesNo as number,
    Currency = Core.DescriptorFieldTypes.Currency as number,
}

enum DescriptorFormats {
    NoSeparators = 1,
    CommaSeparators = 2,
    CommaSeparatorsDupe = 3,
    CommaSeparatorsPrecision2 = 4,
    CommaSeparatorsPrecision3 = 5
}

@Component({
    selector: 'entity-property-characteristics-input',
    templateUrl: './entity.property.characteristics.input.component.html',
    styles: [`
        .ng-invalid.ng-touched {
            border-color: #a94442;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        }
    `]
})
export class EntityPropertyCharacteristicsInputComponent implements OnInit, OnChanges {
    constructor( private readonly _picklistService: DescriptorPicklistService) { }

    @Input() descriptor: EntityDescriptorModelUI;
    @Input() isEditMode: boolean;
    @Input() disabled: boolean = false;
    @Input() set saveAttempted(attempted: boolean) {
        this.wasSaveAttempted = attempted;
        if (attempted) {
            this._validate();
        }
    };

    wasSaveAttempted: boolean = false;
    FieldTypeEnum: typeof DescriptorFieldTypes = DescriptorFieldTypes;
    picklistOptions: Core.DescriptorPickListModel[] = [];
    numberOptions: CurrencyMaskConfig;
    DescriptorFormats = DescriptorFormats;
    isValid: boolean = true;

    ngOnInit() {
        this.descriptor.descriptor.validation = this.descriptor.descriptor.validation || {};

        switch (this.descriptor.descriptor.fieldTypeID) {
            case Core.DescriptorFieldTypes.Date:
                const dateMatch = /(\d\d\d\d)-(\d\d)-(\d\d)/.exec(this.descriptor.value);
                if (dateMatch) {
                    this.descriptor.value = moment.utc([+dateMatch[1], +dateMatch[2] - 1, +dateMatch[3]]).toDate() as unknown as string;  // hack to get around descriptor.value having multiple types
                }

                break;

            case Core.DescriptorFieldTypes.YesNo:
                if (typeof this.descriptor.value === 'string') {
                    if (this.descriptor.value.toLowerCase() === 'true') {
                        this.descriptor.value = true as unknown as string;
                    } else {
                        this.descriptor.value = false as unknown as string;
                    }
                } else {
                    this.descriptor.value = false as unknown as string;
                }

                break;

            case Core.DescriptorFieldTypes.Number:
            case Core.DescriptorFieldTypes.Currency:
                if(typeof this.descriptor.value === 'string') {
                    this.descriptor.value = +this.descriptor.value as unknown as string;
                }

                break;
        }

        if(this.descriptor.descriptor.fieldTypeID == Core.DescriptorFieldTypes.Number) {
            this.numberOptions = {
                allowNegative: true,
                align: 'right',
                decimal: '.',
                precision: 0,
                prefix: '',
                suffix: '',
                thousands: ','
            }

            switch(this.descriptor.descriptor.formatID) {
                case DescriptorFormats.NoSeparators:
                    this.numberOptions.thousands = '';
                    break;
                case DescriptorFormats.CommaSeparatorsPrecision2:
                    this.numberOptions.precision = 2;
                    break;
                case DescriptorFormats.CommaSeparatorsPrecision3:
                    this.numberOptions.precision = 3;
                    break;
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if(this.isEditMode 
                && (changes.descriptor || changes.isEditMode) 
                && this.descriptor.descriptor.fieldTypeID == Core.DescriptorFieldTypes.Picklist) {
            const picklistOptions = await this._picklistService.getByPicklistGroupId(this.descriptor.descriptor.pickListGroupID)
            this.picklistOptions = _.flow([
                    filter((x: any) => !x.disabled || x.name == this.descriptor.value),
                    sortBy('name')
                ])(picklistOptions);
        }
    }

    private _validate(): boolean {
        const validation = this.descriptor.descriptor.validation;
        if (!validation) {
            return;
        }

        switch(this.descriptor.descriptor.fieldTypeID) {
            case Core.DescriptorFieldTypes.Text:
                this.isValid = this.descriptor.value
                    && (!validation.maxLength || this.descriptor.value.length <= validation.maxLength);
                break;
            case Core.DescriptorFieldTypes.Number:
                this.isValid = this.descriptor.value
                    && (!validation.minValue || this.descriptor.value >= validation.minValue)
                    && (!validation.maxValue || this.descriptor.value <= validation.maxValue);
                break;
            case Core.DescriptorFieldTypes.Date:
                this.isValid = this.descriptor.value && moment(this.descriptor.value).isValid()
                    && (!validation.minValue || moment(this.descriptor.value).year() >= validation.minValue)
                    && (!validation.maxValue || moment(this.descriptor.value).year() <= validation.maxValue);
                break;
            case Core.DescriptorFieldTypes.Picklist:
                this.isValid = this.descriptor.value;
                break;
            case Core.DescriptorFieldTypes.Currency:
                this.isValid = this.descriptor.value
                    && (!validation.minValue || this.descriptor.value >= validation.minValue)
                    && (!validation.maxValue || this.descriptor.value <= validation.maxValue);
                break;
        }
    }

}
