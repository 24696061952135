import {Component, OnInit} from '@angular/core';
import {BusyIndicatorMessageManager} from '../../../../Busy-Indicator/busyIndicatorMessageManager'
import {BusyIndicatorService} from '../../../../Busy-Indicator';
import {MessageModalService} from '../../../../UI-Lib/Message-Box/messageModal.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {IWeissmanModalComponent} from '../../../WeissmanModalService';
import {GLAccountService} from '../../glAccount.service';
import {GLAccountBulkUpdateChangedFields} from "./glAccountBulkUpdateExports";
import GLAccountBulkUpdateFieldActionEnum = Compliance.GLAccountBulkUpdateFieldActionEnum;

export interface GLAccountBulkUpdateParams {
    companyId: number;
    selection: Compliance.SelectedRowsModel;
    selectedCount: number;
    glAccountListLastModifiedTimestamp: Date;
};

@Component({
    selector: 'gl-account-bulk-update-modal',
    templateUrl: './glAccountBulkUpdate.component.html',
})
export class GLAccountBulkUpdateComponent implements OnInit, IWeissmanModalComponent<GLAccountBulkUpdateParams, GLAccountBulkUpdateChangedFields> {
    constructor(
        private _busyIndicatorService: BusyIndicatorService,
        private _bsModalRef: BsModalRef,
        private _messageModalService: MessageModalService,
        private _glAccountService: GLAccountService
    ) { }

    params: GLAccountBulkUpdateParams;
    result: GLAccountBulkUpdateChangedFields;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    glAccountBulkUpdateModel: Compliance.GLAccountBulkUpdateModel = { } as Compliance.GLAccountBulkUpdateModel;
    assetClassificationId: number;

    possibleActions =  [
        { name: 'No Change', value: Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange },
        { name: 'Change To', value: Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo },
        { name: 'Set to Blank', value: Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank },
    ];

    fieldActionInitValue = Compliance.GLAccountBulkUpdateFieldActionEnum.NoChange;

    private _newClassificationName: string;

    newClassification(value: Compliance.AssetClassificationModel) {
        this._newClassificationName = value && value.name;
    }

    async ngOnInit(): Promise<void> {
        this.glAccountBulkUpdateModel.selectedRows = this.params.selection;
        this.glAccountBulkUpdateModel.lastModifiedTimestamp = this.params.glAccountListLastModifiedTimestamp;
        this.glAccountBulkUpdateModel.descriptionAction = GLAccountBulkUpdateFieldActionEnum.NoChange;
        this.glAccountBulkUpdateModel.classificationIdAction = GLAccountBulkUpdateFieldActionEnum.NoChange;
    }

    isFormValid(): boolean {
        return (this.glAccountBulkUpdateModel.classificationId
                    && this.glAccountBulkUpdateModel.classificationIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo
                    || this.glAccountBulkUpdateModel.classificationIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank)
                || (this.glAccountBulkUpdateModel.description
                    && this.glAccountBulkUpdateModel.description !== ''
                    && this.glAccountBulkUpdateModel.descriptionAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo
                    || this.glAccountBulkUpdateModel.descriptionAction === Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank);
    }

    async save(force: boolean = false): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating' });
        let confirmMessage: string = '';

        try {
            this.glAccountBulkUpdateModel.force = force;

            await this._glAccountService.updateBulk(this.params.companyId, this.glAccountBulkUpdateModel);

            this.result = {
                selectedRows: this.glAccountBulkUpdateModel.selectedRows
            } as GLAccountBulkUpdateChangedFields;

            if (this.glAccountBulkUpdateModel.classificationIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank){
                this.result.assetClassification = "";
                this.result.assetClassificationId = null;
            } else if (this.glAccountBulkUpdateModel.classificationIdAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo){
                this.result.assetClassification = this._newClassificationName;
                this.result.assetClassificationId = this.glAccountBulkUpdateModel.classificationId;
            }

            if (this.glAccountBulkUpdateModel.descriptionAction === Compliance.GLAccountBulkUpdateFieldActionEnum.SetToBlank){
                this.result.description = null;
            } else if (this.glAccountBulkUpdateModel.descriptionAction === Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo){
                this.result.description = this.glAccountBulkUpdateModel.description;
            }

            this._bsModalRef.hide();
            return Promise.resolve();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            confirmMessage = e2.error.message;
        } finally {
            busyRef.hide();
        }

        try {
            await this._messageModalService.confirm(
                confirmMessage,
                'Confirm Update');
        } catch (e3) {
            return Promise.resolve();
        }

        // force
        await this.save(true);

        return Promise.resolve();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    isDisabled(action: Compliance.GLAccountBulkUpdateFieldActionEnum){
        return action !== Compliance.GLAccountBulkUpdateFieldActionEnum.ChangeTo;
    }
}
