import { Directive, HostListener, Input, Renderer2, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { HelpService } from './help.service';
import { HelpContent, HelpContentComponentConfig } from './help.interface';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TooltipTargetPosition } from './help-tooltip.component';

/**
 * Help Tooltip
 *
 * Renders a tooltip based on a service ID and tooltip key provided
 *
 * IMPORTANT: The naming convention for the helpContentId provides scoping information
 * The naming convention is as follows:
 *   <component-name>.<uniqueId>
 * where:
 *   component-name = the name of the component delineated with dashes
 *   uniqueId = any unique string value for that component scope
 *
 * Example usage (example file name filingBatchList.component.help.ts):
 *
 * import { HelpContent } from '../../../Common/Help-Tooltip';
 *
 * export const HELP_SERVICE_CONTENT: HelpContent[] = [
 *         {
 *             helpContentId: 'filing-batch-list.include-completed',
 *             tooltipText: 'When checked, the list will include batches where are tasks have been completed.',
 *             hasIcon: true
 *         }
 * ];
 *
 * Component:
 *
 * import { HelpService } from '../../../Common/Help-Tooltip';
 * import { HELP_SERVICE_CONTENT } from './filingBatchList.component.help';
 *
 * constructor(private readonly _helpFactory: HelpFactory){}
 *
 * private _helpService: HelpService;
 * helpServiceId: string;
 *
 * ngOnInit() {
 *   this._helpFactory.setContent(HELP_SERVICE_CONTENT);
 * }
 *
 * onSomeChange() {
 *  // update content
 *  this._helpService.updateContent(SOME_NEW_CONTENT);
 * }
 *
 * DOM:
 * <label helpTooltip
 *        helpContentId="filing-batch-list.include-completed"
 *        position="bottom">Awesome Form Control</label>
 *
 */
@Directive({
    selector: '[helpTooltip]'
})
export class HelpTooltipDirective implements OnInit, OnDestroy {
    constructor(
        private readonly _renderer: Renderer2,
        private readonly _elementRef: ElementRef,
        private readonly _helpService: HelpService
    ) { }

    @Input()
    set helpContentId(helpContentId: string) {
        if (helpContentId !== this._helpContentId) {
            this._helpContentId = helpContentId;
            this._retrieveHelpContent();
        }
    }

    @Input()
    set tooltipText(tooltipText: string) {
        if (!(this._helpContent && tooltipText === this._helpContent.tooltipText)) {
            this._helpContent = {
                delay: this._delay,
                helpContentId: this._helpService.getUniqueId(),
                tooltipText
            };
        }
    }

    @Input()
    set contentComponent(helpContentComponent: HelpContentComponentConfig<any, any>) {
        if (!helpContentComponent || (this._helpContent && helpContentComponent === this._helpContent.helpContentComponent)) {
            return;
        }
        this._helpContent = {
            delay: this._delay,
            helpContentId: this._helpService.getUniqueId(),
            hasIcon: helpContentComponent.hasIcon,
            helpContentComponent
        };
        this._setIcon();
    }

    @Input() contentComponentValue: any;
    @Input() helpDisabled: boolean;
    @Input() edgeBuffer: string;

    @Input()
    set helpContent(helpContent: HelpContent) {
        this._helpContent = helpContent;
        this._setIcon();
    }

    @Input()
    set externalTooltipTrigger(show: boolean) {
        if (show) {
            this._helpService.setHoverIn(this._helpContent,
                this.contentComponentValue, this._getPosition());
        } else {
            this._helpService.setHoverOut();
        }
    }

    @Input()
    set tooltipDelay(delay: number) {
        if (this._helpContent && delay || +delay === 0) {
            this._delay = +delay;
            this._helpContent.delay = this._delay;
        }
    }

    @Input() position: string = 'bottom';
    @Input() forcePosition: boolean = false;

    @Input() hasIcon: boolean;

    @HostListener('mouseenter') onMouseEnter() {
        if (this.helpDisabled || (this._helpContent && this._helpContent.disabled)) { return; }
        this._helpService.setHoverIn(this._helpContent,
            this.contentComponentValue, this._getPosition());
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this._helpContent && this._helpContent.disabled) { return; }
        this._helpService.setHoverOut();
    }

    private _delay: number;
    private _helpContent: HelpContent;
    private _helpContentId: string;
    private _helpContentSub: Subscription;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    // DOM
    private _icon: HTMLElement;

    ngOnInit(): void {
        this._setIcon();
    }

    ngOnDestroy() {
        this._helpService.removeTooltip();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    /**
     * Retrieve the content from the HelpService
     */
    private _retrieveHelpContent(): void {

        if (this._helpContentSub) {
            this._helpContentSub.unsubscribe();
        }

        this._helpContentSub = this._helpService.getContent(this._helpContentId).pipe(takeUntil(this.destroy$))
            .subscribe(c => {
                this._helpContent = c;
                this._setIcon();
            });
    }

    /**
     * Setup help DOM on container element
     */
    private _setIcon(): void {
        if ((this._helpContent && this._helpContent.hasIcon) || this.hasIcon) {
            this._addIcon();
        } else {
            this._removeIcon();
        }
    }

    /**
     * Get the positioning information of the target element
     */
    private _getPosition(): TooltipTargetPosition {
        return {
            position: this.position,
            forcePosition: this.forcePosition,
            edgeBuffer: this.edgeBuffer || '10px',
            boundingBox: this._elementRef.nativeElement.getBoundingClientRect()
        };
    }

    /**
     * Add the icon to the container element
     */
    private _addIcon(): void {
        if (!this._icon) {
            this._icon = this._renderer.createElement('i');

            this._renderer.addClass(this._icon, 'fa');
            this._renderer.addClass(this._icon, 'fa-info-circle');

            this._renderer.setStyle(this._icon, 'padding', '0px 5px');
            this._renderer.setStyle(this._icon, 'cursor', 'help');

            this._renderer.appendChild(this._elementRef.nativeElement, this._icon);
        }
    }

    /**
     * Remove the icon from the container element
     */
    private _removeIcon(): void {
        if (this._icon) {
            this._renderer.removeChild(this._elementRef.nativeElement, this._icon);
            this._icon = null;
        }
    }
}
