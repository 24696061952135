import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';
import { IncomeStatementRepository } from '../incomeStatement.repository';

export interface IncomeStatementDataSourceParams {
    companyId: number;
    endDate?: Date;
    siteId?: number;
}

export class IncomeStatementAgGridDataSource extends AgGridDataSourceBase<IncomeStatementDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _incomeStatementRepository: IncomeStatementRepository,
        protected _dataSourceParamsFn: () => IncomeStatementDataSourceParams,
        private readonly _totalsUpdateCallback: (totals: Core.IncomeStatementLineSearchTotalsModel, selectedTotals?: boolean, isLoading?: boolean) => void
    ) { super(_gridApi); }

    lastFetchedTotals: Core.IncomeStatementLineSearchTotalsModel;

    private readonly _propertyMap: Compliance.NameValuePair<Core.IncomeStatementLinePropertyEnum>[] = [
        {name: `${Core.IncomeStatementLinePropertyEnum.CompanyName}`, value: Core.IncomeStatementLinePropertyEnum.CompanyName },
		{name: `${Core.IncomeStatementLinePropertyEnum.TopLevelCompanyName}`, value: Core.IncomeStatementLinePropertyEnum.TopLevelCompanyName },
		{name: `${Core.IncomeStatementLinePropertyEnum.SiteName}`, value: Core.IncomeStatementLinePropertyEnum.SiteName },
		{name: `${Core.IncomeStatementLinePropertyEnum.SiteNumber}`, value: Core.IncomeStatementLinePropertyEnum.SiteNumber },
		{name: `${Core.IncomeStatementLinePropertyEnum.GLAccountNumber}`, value: Core.IncomeStatementLinePropertyEnum.GLAccountNumber },
		{name: `${Core.IncomeStatementLinePropertyEnum.GLAccountName}`, value: Core.IncomeStatementLinePropertyEnum.GLAccountName },
		{name: `${Core.IncomeStatementLinePropertyEnum.GLAccountDescription}`, value: Core.IncomeStatementLinePropertyEnum.GLAccountDescription },
		{name: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryName}`, value: Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryName },
		{name: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryTypeName}`, value: Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryTypeName },
		{name: `${Core.IncomeStatementLinePropertyEnum.Balance}`, value: Core.IncomeStatementLinePropertyEnum.Balance },
        {name: `${Core.IncomeStatementLinePropertyEnum.AccountHierarchyDescription}`, value: Core.IncomeStatementLinePropertyEnum.AccountHierarchyDescription },

    ];
    private _lastFilterUsedInTotals: string;

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    getSearchParamsWithoutPagination() : Core.IncomeStatementLineSearchModel{
        this.refreshDataSourceParams();
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            selectedRows: null,
            endDate: this._dataSourceParams.endDate,
            siteId: this._dataSourceParams.siteId
        };
    }

    public async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Core.IncomeStatementLineSearchTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selectedRows = selectedRowsModel;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const { totals } = await lastValueFrom(this._incomeStatementRepository.getIncomeStatementList(this._dataSourceParams.companyId, searchParams));

        return totals;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.IncomeStatementLineSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            selectedRows: null,
            siteId: this._dataSourceParams.siteId,
            endDate: this._dataSourceParams.endDate
        };

        return await lastValueFrom(this._incomeStatementRepository.getIncomeStatementRowIds(this._dataSourceParams.companyId, searchParams));
    }

    protected canGetRows(): boolean {
        const canGetRows = !!this._dataSourceParams.companyId;
        if (!canGetRows) {
            this.clearTotals();
            this._totalsUpdateCallback(null);
        }
        return canGetRows;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this._gridApi.setPinnedBottomRowData([]);
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals !== searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const {
            totals,
            data: rows,
            totalRows,
            lastModifiedTimestamp
        } = await lastValueFrom(this._incomeStatementRepository.getIncomeStatementList(this._dataSourceParams.companyId, searchParams));

        this.lastFetchedTotals = totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        const dataSourceResult: AgGridDataSourceResult = { rows, totalRows, lastModifiedTimestamp };

        if (fetchTotals) {
            this._getAllRowTotals(params);
        }

        return dataSourceResult;
    }

    private async _getAllRowTotals(params: IGetRowsParams): Promise<Core.IncomeStatementLineSearchTotalsModel> {
        this._totalsUpdateCallback(this.lastFetchedTotals, false, true);
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._incomeStatementRepository.getIncomeStatementList(this._dataSourceParams.companyId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        return result.totals;
    }
}
