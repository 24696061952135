import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../AgGrid/MultiSelectTracker';
import { lastValueFrom, Subscription } from 'rxjs';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import { WeissmanDateTimeFormatPipe } from '../../../UI-Lib/Pipes';
import { FormLibraryRepository } from '../../Repositories/formLibraryRepository';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../AgGrid/ToolPanel/models';
import { GridHelper } from '../../AgGrid/GridHelper';

@Component({
    selector: 'form-library-export',
    templateUrl: './formLibraryExport.component.html',
    styleUrls: ['./formLibraryExport.component.scss']
})
export class FormLibraryExportComponent implements IWeissmanModalComponent<void, number[]>, OnInit, OnDestroy {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formLibraryRepository: FormLibraryRepository,
        private readonly _dateTimePipe: WeissmanDateTimeFormatPipe) {
    }

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _formRevisions: Compliance.FormRevisionForLibraryModel[];
    private readonly _propertyMap: Compliance.NameValuePair<Compliance.FormRevisionForLibraryPropertyEnum>[] = [
        { name: 'formRevisionId', value: Compliance.FormRevisionForLibraryPropertyEnum.FormRevisionId },
        { name: 'formTypeName', value: Compliance.FormRevisionForLibraryPropertyEnum.FormTypeName },
        { name: 'stateAbbr', value: Compliance.FormRevisionForLibraryPropertyEnum.StateAbbr },
        { name: 'taxYearsRange', value: Compliance.FormRevisionForLibraryPropertyEnum.TaxYearsRange },
        { name: 'formName', value: Compliance.FormRevisionForLibraryPropertyEnum.FormName },
        { name: 'formRevisionName', value: Compliance.FormRevisionForLibraryPropertyEnum.FormRevisionName },
        { name: 'isSupplementalForm', value: Compliance.FormRevisionForLibraryPropertyEnum.IsSupplementalForm },
        { name: 'changedBy', value: Compliance.FormRevisionForLibraryPropertyEnum.ChangedBy },
        { name: 'changeDate', value: Compliance.FormRevisionForLibraryPropertyEnum.ChangeDate }
    ];

    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = '8EE28900-B379-4A8E-A9F4-B753C8A75BAA';
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FormSetupDownloadFormRevisionModel).formRevisionId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const selectedRowIds = await this.gridTracker.getSelectedRowIds();
            const sortModel = this._gridApi.getSortModel();

            const model: Compliance.FormLibraryExportModel = {
                formRevisionIds: selectedRowIds,
                columnsToReturn: columnsToReturn.map(x => {
                    return {name: x.value, value: GridHelper.getProperty(x.name, this._propertyMap)};
                }),
                exportFileType: fileFormat,
                sortColumns: GridHelper.getSortModel(sortModel, this._propertyMap)
            };

            const longRunningProcessId = await lastValueFrom(this._formLibraryRepository.export(model));

            const longRunningProcessTypeId = Compliance.LongRunningProcessTypeEnum.FormLibraryExport;

            this.cancel();

            return { longRunningProcessId, longRunningProcessTypeId };
        },
        canCancel: true,
        showFileFormatSelection: true,
        availableFileFormats: [Compliance.ExportFileFormatEnum.Excel, Compliance.ExportFileFormatEnum.PDF],
        getDisabled: () => !this.rowsSelected
    };

    params: void;
    result: number[];

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    rowsSelected: boolean = false;

    cancel(): void {
        this._bsModalRef.hide();
    }

    async ngOnInit(): Promise<void> {
        const busyMsg = 'Loading';

        this.busyIndicatorMessageManager.add('Loading', busyMsg);
        try {
            this._formRevisions = await lastValueFrom(this._formLibraryRepository.getFormRevisions());
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyMsg);
        }

        this._setRowData();
    }

    ngOnDestroy() {
        this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.rowsSelected = x.selectAllRows || x.selectedRows.length > 0;
        });

        const columns: ColDef[] = [
            {
                field: 'formRevisionId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Form Type',
                field: 'formTypeName',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'State',
                field: 'stateAbbr',
                width: AgGridColumns.stateAbbreviationColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Tax Years',
                field: 'taxYearsRange',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: (params) => {
                    console.log('params', params);
                    const formRevision = params.data as Compliance.FormRevisionForLibraryModel;
                    return formRevision != null ? formRevision.allTaxYears : null;
                }
            },
            {
                headerName: 'Form Name',
                field: 'formName',
                width: AgGridColumns.textColumnLargeWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Revision Name',
                field: 'formRevisionName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Is Supplemental',
                field: 'isSupplementalForm',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed By',
                field: 'changedBy',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Changed Date',
                field: 'changeDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._dateTimePipe.transform(x.value, true),
            }
        ];

        const defaultSortModel = [
            {
                colId: 'stateAbbr',
                sort: 'asc'
            },
            {
                colId: 'taxYearsRange',
                sort: 'asc'
            },
            {
                colId: 'formName',
                sort: 'asc'
            },
            {
                colId: 'formRevisionName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async export(): Promise<void> {
        this.result = await this.gridTracker.getSelectedRowIds();

        this._bsModalRef.hide();
        return Promise.resolve();
    }

    private _setRowData() {
        if (!this._gridApi) {
            return;
        }

        this._gridApi.setRowData(this._formRevisions);
        this._gridApi.sizeColumnsToFit();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const formRevision = x.data as Compliance.FormRevisionForLibraryModel;
                return formRevision && formRevision.formRevisionId;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
