<ws-modal-wrapper headerLabel="New Revision"
                  (save)="save()"
                  (cancel)="modalRef.hide()">
    <div *ngIf="config">
        <div class="text-center" style="margin-top: 20px;" *ngIf="isBusy">
            <i class="fa fa-spinner fa-pulse fa-3x"></i>
        </div>
        <div class="row" *ngIf="!isBusy">
            <div class="col-lg-6">
                <div class="radio" [class.disabled]="config.navigationState.currentAssessmentVM.isActual()">
                    <label>
                        <input type="radio"
                               [(ngModel)]="newAssessment.revisionDesc"
                               name="optionsRadios"
                               value="Forecast"
                               [disabled]="config.navigationState.currentAssessmentVM.isActual()">Forecast
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               [(ngModel)]="newAssessment.revisionDesc"
                               name="optionsRadios"
                               value="Original">Original
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               [(ngModel)]="newAssessment.revisionDesc"
                               name="optionsRadios"
                               value="Final">Final
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               [(ngModel)]="newAssessment.revisionDesc"
                               name="optionsRadios"
                               value="other">Other
                        <input type="text"
                               [(ngModel)]="other"
                               name="revisionDesc"
                               maxlength="10"
                               [disabled]="newAssessment.revisionDesc !== 'other'"
                               (keydown)="checkForEnter($event)">
                    </label>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
