<div *ngIf="isInitialized" class="ws-flex-container-vertical ws-flex-auto min-height-0">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Electronic Filings"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Electronic Filings
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <button class="flat-button small-button secondary-button" (click)="refresh()" [disabled]="!canRefresh">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <form class="d-flex align-items-center mb-3" [formGroup]="viewControlForm">
        <label class="m-0 small me-2">State:</label>
        <div class="view-control">
            <ws-select [returnEntireOption]="true"
                       formControlName="electronicFilingType"
                       labelProperty="displayName"
                       valueProperty="electronicFilingTypeId"
                       [canSearch]="true"
                       [options]="electronicFilingTypes"
                       placeholder="Nothing selected"
                       (selectedOptionChanged)="onElectronicFilingTypeChanged($event)">
            </ws-select>
        </div>
        <label class="m-0 small ms-4 me-2">Tax Year:</label>
        <div class="view-control">
            <ws-select [returnEntireOption]="true"
                       [isPrimitive]="true"
                       formControlName="taxYear"
                       [canSearch]="false"
                       [options]="taxYears">
            </ws-select>
        </div>
    </form>

    <div class="d-flex flex-grow-1 overflow-hidden">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [gridId]="gridId"
                            [companyId]="companyId"
                            [exportOptions]="exportOptions">
        </ws-ag-grid-angular>
    </div>

    <form class="d-flex justify-content-around m-4" [formGroup]="downloadControlForm" [hidden]="!isCASdr">
        <div class="d-flex flex-column">
            <label
                helpTooltip
                helpContentId="electronic-filing.options"
                position="left"
                class="m-0 mb-2 d-block w-fit-content">Output Options</label>
            <label class="m-1 fw-normal"><input type="radio" name="bundleAssessors" formControlName="bundleAssessors" [value]="false"> Separate by Assessor</label>
            <label class="m-1 fw-normal"><input type="radio" name="bundleAssessors" formControlName="bundleAssessors" [value]="true"> Combine Assessors</label>
            <span class="mt-2">Files created in accordance with state and assessor specifications and regulations.</span>
        </div>
        <div class="d-flex flex-column">
            <label class="m-0 mb-2">CA SDR / e-SDR</label>
            <span *ngIf="sdrTestUrl" class="m-1">Submit a test file with the CA SDR / e-SDR: <a class="clickable" [href]="sdrTestUrl" target="_blank"> here</a></span>
            <span *ngIf="sdrUrl" class="m-1">File an electronic return at CA SDR / e-SDR: <a class="clickable" [href]="sdrUrl" target="_blank"> here</a></span>
        </div>
    </form>

    <div class="d-flex justify-content-end">
        <button class="btn btn-primary"
                (click)="downloadElectronicFilings()"
                [disabled]="!hasRowsSelected">
            Download Electronic Filings
        </button>
    </div>
</div>

