import { Injectable, EventEmitter } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { EntityImportRepository } from '../../../Repositories';
import { ImportSpecificationRepository } from '../../../Repositories';
import { DynamicFieldBehaviorService } from '../Dynamic-Field-Behavior/dynamicFieldBehavior.service';

export interface ImportFileSpecificationInfo {
    importFileSpecificationId: number;
    displayName: string;
    companyId: number;
}

@Injectable()
export class EntityImportColumnMappingStepService {
    constructor(
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _entityImportSpecificationRepository: ImportSpecificationRepository,
        private readonly _dynamicFieldBehaviorService: DynamicFieldBehaviorService) { }

    private _headers: Compliance.ImportFileHeaderModel[] = [];
    private _fields: Compliance.ImportFieldModel[] = [];
    private _fieldMappings: Compliance.ImportFileSpecificationImportFieldModel[] = [];
    private _fileSpecificationId: number;
    private _fileSpecificationName: string;
    private _companyId: number;
    private _fieldAssigned: EventEmitter<Compliance.ImportFileSpecificationImportFieldModel> = new EventEmitter();
    private _fieldUnassigned: EventEmitter<Compliance.ImportFileSpecificationImportFieldModel> = new EventEmitter();

    async initialize(importId: number): Promise<void> {
        const fileModel = await lastValueFrom(this._entityImportRepository.getImport(importId));
        const contentTypeModel = await lastValueFrom(this._entityImportRepository.getContentType(fileModel.importContentTypeId, fileModel.importFileId));

        this._headers = fileModel.importFileHeaders;
        this._fields = contentTypeModel.importFields;
        this._fieldMappings = fileModel.assignedFields;
        this._fileSpecificationId = fileModel.importFileSpecificationId;
        this._fileSpecificationName = fileModel.importFileSpecificationName;
        this._companyId = fileModel.companyId;

        await this._dynamicFieldBehaviorService.initialize(contentTypeModel, fileModel.dynamicFieldBehavior, fileModel.setAllDynamicFieldBehavior,
            fileModel.dynamicFieldsBehavior, this._fields, this._fieldMappings);
    }

    canAssignField(targetId: number): boolean {
        return this._fieldMappings.findIndex(m => <any>(m.value === targetId.toString())) === -1;
    }

    getFileHeaders(): Compliance.ImportFileHeaderModel[] {
        return this._headers;
    }

    getFields(): Compliance.ImportFieldModel[] {
        return this._fields;
    }

    getFieldMappings(): Compliance.ImportFileSpecificationImportFieldModel[] {
        return this._fieldMappings;
    }

    getFileSpecificationInfo(): ImportFileSpecificationInfo {
        const specificationInfo: ImportFileSpecificationInfo = {
            importFileSpecificationId: this._fileSpecificationId,
            displayName: this._fileSpecificationName,
            companyId: this._companyId,
        };

        return specificationInfo;
    }

    async assignFieldToColumn(importId: number, fieldId: string, headerIndex: number, clarificationValues: Compliance.NameValuePair<string>[]): Promise<Compliance.ImportFileSpecificationImportFieldModel> {
        const response = await lastValueFrom(this._entityImportSpecificationRepository.assignField(importId, fieldId, headerIndex, clarificationValues));
        this._fieldMappings.push(response);
        this._fieldAssigned.emit(response);
        return response;
    }

    async assignStaticField(importId: number, fieldId: string, value: string, clarificationValues: Compliance.NameValuePair<string>[]): Promise<Compliance.ImportFileSpecificationImportFieldModel> {
        const response = await lastValueFrom(this._entityImportSpecificationRepository.assignStaticField(importId, fieldId, value, clarificationValues));
        this._fieldMappings.push(response);
        this._fieldAssigned.emit(response);
        return response;
    }

    async unAssignField(specificationFieldId: number): Promise<void> {
        await lastValueFrom(this._entityImportSpecificationRepository.unAssignField(specificationFieldId));
        const fieldMapping = this._fieldMappings.find(i => i.importFileSpecificationFieldId === specificationFieldId);
        this._fieldMappings.splice(this._fieldMappings.indexOf(fieldMapping), 1);
        this._fieldUnassigned.emit(fieldMapping);
    }

    get fieldAssigned$(): Observable<Compliance.ImportFileSpecificationImportFieldModel> {
        return this._fieldAssigned.asObservable();
    }

    get fieldUnassigned$(): Observable<Compliance.ImportFileSpecificationImportFieldModel> {
        return this._fieldUnassigned.asObservable();
    }
}
