<div class="ws-flex-container-vertical ws-flex-auto">
    <div class="ws-flex-container-horizontal assessor-summary ws-flex-auto ws-stretch ws-flex-align-items-center">
        <div class="ws-flex-container-horizontal ws-flex-auto" (click)="toggleExpanded()" style="cursor:pointer;" title="View assessor details">
            <div class="ws-expand-button">
                <button class="ws-flex-none">
                    <i class="fa" style="color:#fff" [ngClass]="filingControl.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                </button>
            </div>
            <div>{{ filingControl.assessorName }}</div>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none">
            {{ filingControl.returnCount }} return{{ filingControl.returnCount == 1 ? '' : 's' }}
        </div>
    </div>
    <div class="ws-flex-container-horizontal ws-flex-auto assessor-details" *ngIf="filingControl.isExpanded">
        <div class="ws-flex-container-vertical ws-flex-auto ws-vertical-align-top">
            <label>Address</label>
            <div class="ws-flex-none">{{ filingControl.assessorAddress1 }}</div>
            <div class="ws-flex-none">{{ filingControl.assessorAddress2 }}</div>
            <div class="ws-flex-container-horizontal ws-flex-auto" style="padding-right:4px">
                <div class="address-line-item ws-flex-none">{{ filingControl.assessorCity }}{{filingControl.assessorCity ? ', ': ''}} </div>
                <div class="address-line-item ws-flex-none">{{ filingControl.assessorState }} </div>
                <div class="address-line-item ws-flex-none">{{ filingControl.assessorZip }} </div>
            </div>
        </div>
        <div class="ws-flex-container-vertical ws-flex-auto ws-vertical-align-top assessor-protocols">
            <label>Protocols</label>
            <div class="ws-flex-container-horizontal assessor-protocol">
                <div class="ws-flex-auto">{{ 'Postmark ' + (filingControl.assessorPostmarkAccepted ? '' : 'Not ') + 'Accepted' }}</div>
            </div>
            <div class="ws-flex-container-horizontal assessor-protocol">
                <div class="ws-flex-auto">{{ 'Original Forms ' + (filingControl.assessorOriginalFormsRequired ? '' : 'Not ') + 'Required' }}</div>
            </div>
            <div class="ws-flex-container-horizontal assessor-protocol">
                <div class="ws-flex-auto">{{ 'Wet Signature ' + (filingControl.assessorWetSignatureRequired ? '' : 'Not ') + 'Required' }}</div>
            </div>
        </div>
    </div>
</div>
